export const dataJeo = {
  type: "FeatureCollection",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { gid: 1, code: "AD01", ten_tinh: "An Giang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.11524187400002, 10.955660896000017],
              [105.11462924600005, 10.946923054000111],
              [105.1038041100001, 10.932225292000018],
              [105.09732006500006, 10.922679236000119],
              [105.10156493000001, 10.920812300000103],
              [105.107523583, 10.919060767000042],
              [105.12843635500008, 10.923566429000074],
              [105.12933904900007, 10.923760866000086],
              [105.14527860200006, 10.924344837000087],
              [105.15753557100008, 10.923044120000023],
              [105.17210817900003, 10.915172694000129],
              [105.18066661600002, 10.913087368000072],
              [105.18712386600005, 10.913173943000041],
              [105.18718762100009, 10.912704181000075],
              [105.18761207900003, 10.909576617000114],
              [105.18900435200004, 10.896518163000033],
              [105.1891992920001, 10.888406492000067],
              [105.18879436500006, 10.883439919000091],
              [105.18798963600001, 10.87356902500005],
              [105.18801891700004, 10.87277383800007],
              [105.18838303600006, 10.862885270000076],
              [105.18878089100011, 10.85932383600001],
              [105.19057887200002, 10.855563706000025],
              [105.19457602799999, 10.850021600000035],
              [105.19583230400011, 10.84911673300004],
              [105.19677536100006, 10.848437460000058],
              [105.20177523500004, 10.847049331000045],
              [105.20937353500007, 10.842889556000033],
              [105.21756940799999, 10.835563683000069],
              [105.21822120900001, 10.834352793000066],
              [105.21916743600005, 10.832594903000102],
              [105.22036264400001, 10.825867391000086],
              [105.22195972900008, 10.821711542000044],
              [105.22539336600005, 10.815917907000115],
              [105.22955048600001, 10.80884618700013],
              [105.232149201, 10.80706365600008],
              [105.23654836200011, 10.805873260000086],
              [105.24094811599998, 10.805474174000016],
              [105.24475101800004, 10.805471205000037],
              [105.25114827600001, 10.805466056000055],
              [105.2534776750001, 10.804695864000012],
              [105.2538941430001, 10.804558162000076],
              [105.25474732800008, 10.804276059000093],
              [105.25714550700008, 10.802097797000075],
              [105.25910594700011, 10.798591053000093],
              [105.25981161500005, 10.796400536000037],
              [105.25982439000005, 10.794064998000062],
              [105.25910675400009, 10.792234939000076],
              [105.25678601200009, 10.784394211000057],
              [105.25730046200002, 10.779318927000057],
              [105.25848468000004, 10.774252419000073],
              [105.26030027400007, 10.772066581000049],
              [105.26427127000001, 10.768638977000043],
              [105.26449463600002, 10.768510295000054],
              [105.26774142200007, 10.766639740000093],
              [105.27110494100002, 10.765578730000056],
              [105.27580207700008, 10.764976875000086],
              [105.28585142700005, 10.764886094000035],
              [105.29211128000001, 10.764304073000106],
              [105.29680832500007, 10.763701758000098],
              [105.29861179600007, 10.762399270000065],
              [105.3006442970001, 10.760657813000039],
              [105.30178958700002, 10.758463047000076],
              [105.30205638000007, 10.755152258000054],
              [105.30076630900008, 10.751379576000096],
              [105.29701487300011, 10.748017123000063],
              [105.28438689300003, 10.740562990000056],
              [105.27730808700008, 10.735610578000097],
              [105.27467048500006, 10.732483175000061],
              [105.27447952900008, 10.73005035600003],
              [105.2745205890001, 10.726957748000119],
              [105.27633559300008, 10.724771927000097],
              [105.2806146300001, 10.7219552290001],
              [105.28953155300007, 10.718705271000049],
              [105.29918443300002, 10.718880937000018],
              [105.30853872500005, 10.720547641000076],
              [105.31921511200002, 10.723556438000069],
              [105.32968009200002, 10.725678257000125],
              [105.3350343160001, 10.725967240000054],
              [105.34108107600009, 10.724497360000028],
              [105.34490422200005, 10.722336392000019],
              [105.34754429900001, 10.722543839000041],
              [105.34878106400002, 10.720096339000039],
              [105.34930392500011, 10.717997499000107],
              [105.34966376400004, 10.716925280000032],
              [105.34655420700007, 10.704676345000051],
              [105.34579512600006, 10.701686034000078],
              [105.34439367500009, 10.695852762000067],
              [105.34387696100006, 10.6937019630001],
              [105.343817223, 10.693453301000131],
              [105.34313501400004, 10.690613550000093],
              [105.33879060800004, 10.679781041000068],
              [105.33782367300006, 10.675971180000053],
              [105.33733838400005, 10.672398979000073],
              [105.33792967900003, 10.662990174000074],
              [105.33948004000005, 10.650364864000037],
              [105.34091219900007, 10.639764222000013],
              [105.34378812600008, 10.629161985000033],
              [105.34683773100008, 10.620910864000075],
              [105.34726677800005, 10.619749972000124],
              [105.34855244600001, 10.616977257000125],
              [105.35074740200008, 10.61224337900004],
              [105.356508628, 10.599794127000093],
              [105.35686807100008, 10.599017371000039],
              [105.36310755400004, 10.584957494000056],
              [105.36585413900009, 10.579503681000089],
              [105.36634808800002, 10.578522833000113],
              [105.36863023300002, 10.575542897000021],
              [105.37139510400007, 10.573872381000092],
              [105.37367990600004, 10.573155138000077],
              [105.38366374300008, 10.572428608000093],
              [105.38691087600009, 10.571710094000062],
              [105.39075842700002, 10.570157174000093],
              [105.39388371300006, 10.568247849000032],
              [105.39700833200004, 10.565862103000077],
              [105.39953114400006, 10.563238936000106],
              [105.40702949899999, 10.556003054000128],
              [105.40818128299999, 10.554891526000086],
              [105.410344455, 10.553340535000098],
              [105.41262853400008, 10.552265742000076],
              [105.414913068, 10.551548212000037],
              [105.417799524, 10.551187133],
              [105.42116793400008, 10.551420849000069],
              [105.43079285400002, 10.552717881000094],
              [105.43861315800004, 10.553778941000045],
              [105.44354502200008, 10.553772058],
              [105.44510809200008, 10.553293506000026],
              [105.45291956199999, 10.548280627000038],
              [105.463616551, 10.542548804000072],
              [105.46702147100008, 10.540995385000061],
              [105.478280974, 10.53585795400007],
              [105.47862544700007, 10.535914264000022],
              [105.47972471800009, 10.536093953000057],
              [105.48225397600001, 10.538233719000088],
              [105.48574963400004, 10.542991966000058],
              [105.49137622700002, 10.544771168000098],
              [105.49886733200006, 10.547139679000049],
              [105.50560275500001, 10.546771716000068],
              [105.50836813800004, 10.546052752],
              [105.51005566500004, 10.548193642000035],
              [105.51197945200005, 10.547714181000066],
              [105.5248401310001, 10.541857744000092],
              [105.52784478100003, 10.540423677000097],
              [105.53301037200004, 10.536604168000084],
              [105.53495439100004, 10.535002514000078],
              [105.54285924000003, 10.528489406000039],
              [105.5471806250001, 10.523599338000087],
              [105.54963902800002, 10.519905818000018],
              [105.5518523800001, 10.516580345000113],
              [105.55233871300001, 10.51584963500005],
              [105.55509479800006, 10.511100073000089],
              [105.55593580700001, 10.509650719000073],
              [105.55612809300003, 10.509226856000085],
              [105.55808470600009, 10.504913853000096],
              [105.56204527800006, 10.496182960000054],
              [105.56815248700008, 10.481762381000069],
              [105.5693764280001, 10.478157563000055],
              [105.57090361900006, 10.473659441000025],
              [105.57161446800009, 10.467822816000092],
              [105.57279363400004, 10.455078197000132],
              [105.57398301200004, 10.447930737000098],
              [105.57397864400012, 10.445548970000027],
              [105.57168789500003, 10.442218591000072],
              [105.57096230100004, 10.439957199000014],
              [105.57131846400002, 10.437455701000074],
              [105.57208441200007, 10.430849497000059],
              [105.57154668200008, 10.430769160000073],
              [105.56974645700006, 10.430499660000068],
              [105.56803829900005, 10.430244970000045],
              [105.56752026300008, 10.430316584000108],
              [105.56387629100003, 10.430820293000041],
              [105.55983053900003, 10.432207598000074],
              [105.552045445, 10.435671544000067],
              [105.55071782500002, 10.433874365000049],
              [105.54958770500004, 10.431374119000035],
              [105.54774221700011, 10.427090432000073],
              [105.54691798000003, 10.424985455000078],
              [105.54473900500004, 10.422529353000074],
              [105.54432842000008, 10.421351162000024],
              [105.54484095100004, 10.421105720000055],
              [105.54595094100004, 10.420615835000111],
              [105.54858950600004, 10.420143819000048],
              [105.55020601700004, 10.41991126700008],
              [105.55055669000009, 10.418993428000077],
              [105.55031191900009, 10.418152433000037],
              [105.54947769900008, 10.416885504000016],
              [105.54753854200004, 10.415689452],
              [105.54441253000003, 10.414311875000058],
              [105.54280207800009, 10.414041578000075],
              [105.540080663, 10.414344919000127],
              [105.53761600300008, 10.414483616000057],
              [105.53499758000005, 10.413279475000079],
              [105.53154410600004, 10.41089211900008],
              [105.53003673800002, 10.409114322000066],
              [105.52970915900001, 10.40810472700011],
              [105.52947758400003, 10.406174337000083],
              [105.52908323100009, 10.403655355000028],
              [105.527949682, 10.401607479000051],
              [105.52704020300007, 10.401269346000051],
              [105.52630799000005, 10.401235779000027],
              [105.52315841400005, 10.401993048000067],
              [105.52256906200004, 10.401567029000079],
              [105.52223648000006, 10.400976431000062],
              [105.52242146500001, 10.399721458000053],
              [105.52365870900006, 10.395713188000054],
              [105.52493325400009, 10.388604444000025],
              [105.52698093600004, 10.380750622000077],
              [105.5270051360001, 10.378739528000034],
              [105.527023285, 10.377231203000024],
              [105.52577595300009, 10.37503750600006],
              [105.52341685500002, 10.373501133000039],
              [105.52071313200005, 10.372379690000065],
              [105.519616489, 10.371780070000101],
              [105.51878062300003, 10.370680693000088],
              [105.51854413300001, 10.369169383],
              [105.51847440700001, 10.367911464000111],
              [105.51875237100009, 10.365987216000059],
              [105.51910577300002, 10.364345199000089],
              [105.51427259400006, 10.363754343000052],
              [105.50764754000009, 10.36027550400007],
              [105.49936490000002, 10.355925829000066],
              [105.49782874000002, 10.355251681000064],
              [105.49193947800008, 10.349716206],
              [105.48954241600006, 10.347282883000018],
              [105.48867878700003, 10.345742326000094],
              [105.48850819000003, 10.343493208000075],
              [105.48881335300005, 10.343186464],
              [105.48935762000005, 10.341069465000084],
              [105.48914898600003, 10.339840943000043],
              [105.48583479700008, 10.335545034000047],
              [105.49295568300005, 10.327878608000061],
              [105.48885216000006, 10.326643327000083],
              [105.48543043400011, 10.324816554000014],
              [105.48518959200007, 10.324635208000108],
              [105.4849655460001, 10.324206065000039],
              [105.48443016100003, 10.322439368000056],
              [105.48417719800005, 10.321558569],
              [105.48394646400007, 10.320755194000062],
              [105.48342865800004, 10.319236253000062],
              [105.48289409500003, 10.317998147000116],
              [105.48248056600002, 10.317255422000036],
              [105.48208474500004, 10.316843048000111],
              [105.48115581100009, 10.31615064000002],
              [105.47947014000006, 10.314996842000122],
              [105.46697268800004, 10.31175677500009],
              [105.42489701300008, 10.304089424000027],
              [105.43722751000006, 10.276210798000116],
              [105.43966488300002, 10.270699193000075],
              [105.42666503500007, 10.2658453380001],
              [105.42572899400008, 10.265469678000015],
              [105.41286612700002, 10.260330089000112],
              [105.39954782300002, 10.255054597000067],
              [105.39877107500006, 10.25474548400007],
              [105.39773728900005, 10.254383828000078],
              [105.38663386300007, 10.251069518000032],
              [105.36426507500006, 10.244390550000036],
              [105.33551807300006, 10.235579297000095],
              [105.33457724300003, 10.235287631000032],
              [105.32930042400005, 10.243963112000108],
              [105.32916668100009, 10.244181472000056],
              [105.31047980600007, 10.275280968000098],
              [105.30461316300006, 10.269867017000054],
              [105.29940895100003, 10.278135496000028],
              [105.28546448200009, 10.263581877000137],
              [105.28722006600006, 10.260789708000038],
              [105.265125674, 10.239503141000037],
              [105.25902676600003, 10.246886346000037],
              [105.25146852500004, 10.239631832000027],
              [105.25114221100003, 10.239318615000046],
              [105.24439136300005, 10.230307351000077],
              [105.23140797900004, 10.21231108500009],
              [105.22981427900008, 10.210101822000082],
              [105.225802985, 10.214216642000016],
              [105.22538675000003, 10.213643534000115],
              [105.22407829600003, 10.211787916000057],
              [105.22239867200005, 10.209474841000075],
              [105.22075995900011, 10.207176030000118],
              [105.21951458100008, 10.205385471000026],
              [105.21706420000002, 10.202148101000128],
              [105.21491004400005, 10.199210231000054],
              [105.212362, 10.19573566300007],
              [105.21177790900006, 10.194859840000079],
              [105.20844657400002, 10.190264944000125],
              [105.20618273000012, 10.187201187000115],
              [105.20534878300003, 10.186090465000051],
              [105.20419283500006, 10.184550853000117],
              [105.16362466100003, 10.219665236000084],
              [105.1620776390001, 10.221004220000028],
              [105.15841411300003, 10.224174983000099],
              [105.14225787700005, 10.238156394000073],
              [105.13996583900004, 10.239455725000111],
              [105.13382333600002, 10.245200425000077],
              [105.12447345100001, 10.253943936],
              [105.11367438600008, 10.260119106000063],
              [105.10153746600005, 10.266900980000047],
              [105.09360398600009, 10.271482155000021],
              [105.09134432300007, 10.272786904000062],
              [105.08005520100002, 10.279154191000115],
              [105.06917009400009, 10.285786345000057],
              [105.06821663900007, 10.286367235000069],
              [105.05496911800006, 10.294093919000067],
              [105.03904118600006, 10.303043888000028],
              [105.01542241200009, 10.316792146000074],
              [105.015081159, 10.316990756000076],
              [105.01593693800004, 10.318217758000079],
              [105.003716341, 10.322314384000018],
              [105.00000007200001, 10.323559993000051],
              [104.9782272910001, 10.32851336400009],
              [104.97787798500005, 10.328593266000039],
              [104.97607014400005, 10.329006800000061],
              [104.901471101, 10.346056128000034],
              [104.90001582200003, 10.346388436000085],
              [104.87435225300007, 10.351401878000031],
              [104.86339214600005, 10.356093277000065],
              [104.86267044300003, 10.357026120000066],
              [104.83659835200011, 10.390542064000028],
              [104.83450641600004, 10.39342227600004],
              [104.82784064700007, 10.401776654000104],
              [104.82560253600008, 10.404581560000013],
              [104.82775850500011, 10.4071223960001],
              [104.81748282100006, 10.424548644000085],
              [104.810410258, 10.436541057000069],
              [104.80726014500004, 10.441882031000114],
              [104.79506916200006, 10.468670007000112],
              [104.78936352100004, 10.474810584000059],
              [104.77653037400005, 10.488620295000127],
              [104.78146532200006, 10.507431992000081],
              [104.77922018600002, 10.509671686000031],
              [104.77777983200004, 10.512624414000092],
              [104.77669154000006, 10.520283274000073],
              [104.86165923500002, 10.522413302000098],
              [104.86529076600003, 10.522467603000061],
              [104.86889099200002, 10.524576427000019],
              [104.88533131100004, 10.538774697000084],
              [104.89552690200004, 10.547578391000021],
              [104.901110327, 10.555881531000109],
              [104.917480567, 10.588178109000077],
              [104.92026542300002, 10.593671331000019],
              [104.93343656500011, 10.615445025],
              [104.94410862300001, 10.628446490000075],
              [104.95179372200002, 10.637807835000055],
              [104.96048890700004, 10.646669529000084],
              [104.99086792200006, 10.664064929000093],
              [104.99723776000006, 10.667484047000107],
              [105.00168762900009, 10.669872418000102],
              [105.01425607200005, 10.677081774000017],
              [105.06158110100003, 10.704217202000075],
              [105.07208904400002, 10.710240023000049],
              [105.08815998500003, 10.720676123000048],
              [105.091087789, 10.722175667000043],
              [105.09252071800007, 10.72511342800002],
              [105.08769438400006, 10.753496762000035],
              [105.08316732600004, 10.760729045000071],
              [105.07820595900004, 10.764863456000027],
              [105.060777966, 10.779384522000067],
              [105.05993616000008, 10.786668159000088],
              [105.05943050200007, 10.799833157000103],
              [105.058759782, 10.817293374000057],
              [105.04433827100011, 10.84554512900011],
              [105.02989166900002, 10.875256081000105],
              [105.02824727500003, 10.886907237000097],
              [105.02964876900009, 10.892034683000061],
              [105.03367212000009, 10.896760459000097],
              [105.03464324600006, 10.89756939300006],
              [105.03770277300008, 10.900117869000068],
              [105.03837019600005, 10.905261457000035],
              [105.03788295600006, 10.91332266500009],
              [105.04114598700004, 10.918502941000073],
              [105.04912414400006, 10.931084295000025],
              [105.0586707570001, 10.937819574000061],
              [105.06937242900004, 10.941636611000137],
              [105.074522304, 10.944642386000057],
              [105.07694078499999, 10.959641337000024],
              [105.07854667200009, 10.958811612000117],
              [105.08067723000006, 10.957174201000084],
              [105.09024866100002, 10.954588736000035],
              [105.09613181700007, 10.954670143000021],
              [105.108541266, 10.961388451000074],
              [105.11220812800009, 10.962166354000134],
              [105.1129640230001, 10.960721814000072],
              [105.11524187400002, 10.955660896000017],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 2, code: "AD01", ten_tinh: "Bà Rịa - Vũng Tàu" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.08109697800005, 8.577536049000043],
              [106.08069337600004, 8.577514605000134],
              [106.08016888700008, 8.577575652000034],
              [106.07978092400005, 8.577688732000064],
              [106.07952471600007, 8.577801530000071],
              [106.0792566010001, 8.577958807000117],
              [106.07897318300009, 8.578175957000054],
              [106.07864098800005, 8.578523244000012],
              [106.07846010100009, 8.578775291000083],
              [106.07832666800007, 8.57902046900003],
              [106.07816277100009, 8.579454937],
              [106.07808402000006, 8.579818021000028],
              [106.07805954900007, 8.580149314000083],
              [106.07807131900007, 8.580441849000041],
              [106.07811371300004, 8.580717489000021],
              [106.07821624500008, 8.581074642000084],
              [106.078377308, 8.581443770000073],
              [106.07852294500006, 8.581681981000065],
              [106.07873353800007, 8.58195052700008],
              [106.07897584700004, 8.582191120000092],
              [106.07930079600001, 8.582430490000091],
              [106.0796559990001, 8.582622621],
              [106.08000753500005, 8.582747572000036],
              [106.08043454400004, 8.582830955000096],
              [106.08080732600006, 8.58285150200002],
              [106.08108650900007, 8.582827982000117],
              [106.08139251600008, 8.582771508000063],
              [106.08166021100001, 8.582689249000095],
              [106.08202750200003, 8.582521140000042],
              [106.08236777700003, 8.582303887000096],
              [106.08258170300006, 8.582123834000031],
              [106.08277481600004, 8.581921809000013],
              [106.08294655900005, 8.581701498000081],
              [106.08309335800003, 8.581463992000058],
              [106.08321543900009, 8.581213014000076],
              [106.08330954000006, 8.580950362000104],
              [106.08339418600005, 8.580556911000057],
              [106.08342377200009, 8.580186565000057],
              [106.08340512100006, 8.579877359000053],
              [106.08334517400007, 8.579541964000013],
              [106.08326448100006, 8.579274934000035],
              [106.08315524300008, 8.579018157000105],
              [106.08296835800007, 8.578696545000051],
              [106.08276172000004, 8.578424991000054],
              [106.08256628100003, 8.578225205000043],
              [106.08235166600012, 8.578045968000046],
              [106.08209169300001, 8.577875686000079],
              [106.08172774900001, 8.577700504000061],
              [106.08140275000002, 8.577593719000019],
              [106.08109697800005, 8.577536049000043],
            ],
          ],
          [
            [
              [106.15006414200005, 8.607187813000026],
              [106.14963432300002, 8.607163593000065],
              [106.14918466100002, 8.607264529000068],
              [106.14878428200011, 8.607655339000074],
              [106.14842903400003, 8.608103855000058],
              [106.14838548600007, 8.608856440000066],
              [106.14846446100003, 8.609533211000018],
              [106.14893367200003, 8.609966514000076],
              [106.149290804, 8.61027022100007],
              [106.14997707900004, 8.610496613000061],
              [106.15065915100007, 8.610305466000055],
              [106.151124114, 8.609915448000026],
              [106.15144983900004, 8.609277642000011],
              [106.1515482290001, 8.608586179000087],
              [106.15122654900003, 8.607970579000044],
              [106.15093782300004, 8.607758942000119],
              [106.15054375000003, 8.607445586000038],
              [106.15006414200005, 8.607187813000026],
            ],
          ],
          [
            [
              [106.13765204500007, 8.610997118000084],
              [106.13730973200008, 8.610996696000049],
              [106.13693995300012, 8.611047836000106],
              [106.13660932400003, 8.611136050000122],
              [106.13632185000004, 8.611254831000091],
              [106.13602303100001, 8.611421029000075],
              [106.13579591600005, 8.611584350000088],
              [106.13556643500007, 8.611793646000086],
              [106.13532445700012, 8.61207659800005],
              [106.13513871000004, 8.612362763000101],
              [106.13498254100008, 8.61270016100004],
              [106.13484172400007, 8.613206770000124],
              [106.13480072700004, 8.613629587000025],
              [106.13481258100005, 8.613922142000073],
              [106.13485505600001, 8.614197790000054],
              [106.13494579700006, 8.614526289000056],
              [106.13507661400004, 8.614841118000081],
              [106.13528421100008, 8.615186430000053],
              [106.13553798500003, 8.615499702000038],
              [106.13578957200006, 8.615730734000058],
              [106.13606790500009, 8.615929067000126],
              [106.13639870000002, 8.616101321000079],
              [106.13677953100009, 8.616236553000077],
              [106.1372198120001, 8.616319339000087],
              [106.13754969500003, 8.616331216000093],
              [106.13782891300005, 8.616307626],
              [106.13816498700005, 8.616242831000063],
              [106.138489615, 8.616134713000088],
              [106.13879833700005, 8.615987512000077],
              [106.13915782800004, 8.615743077000014],
              [106.13947502600008, 8.615446504000078],
              [106.13973911500008, 8.615102099000092],
              [106.13996687700011, 8.614662169000019],
              [106.14009788300011, 8.614249119000037],
              [106.14015906300001, 8.613820179000074],
              [106.140153481, 8.613417807000088],
              [106.14007712200008, 8.612991294000059],
              [106.139935591, 8.61258160300009],
              [106.13979449300005, 8.612305476000069],
              [106.13962020800003, 8.612048807000059],
              [106.13932874500006, 8.61172702400008],
              [106.13901693700011, 8.611470851000112],
              [106.13872387100011, 8.611294804000112],
              [106.13841119600008, 8.611156135],
              [106.13805239200006, 8.611053492000041],
              [106.13765204500007, 8.610997118000084],
            ],
          ],
          [
            [
              [106.55609560800006, 8.627951585000025],
              [106.55576291100007, 8.627870207000042],
              [106.5553682510001, 8.627871814000086],
              [106.554994514, 8.62791468600008],
              [106.55445561800009, 8.628206411000059],
              [106.55422839500002, 8.628517570000048],
              [106.55414666600011, 8.62884881300001],
              [106.55423169700003, 8.629324149000082],
              [106.55469046000007, 8.629756623000072],
              [106.55508649100005, 8.630085920000051],
              [106.55539234800005, 8.630291508000097],
              [106.55564819000008, 8.6302904680001],
              [106.55606338000007, 8.630226735000067],
              [106.55647755300005, 8.629914823000105],
              [106.55660117200006, 8.629666139000046],
              [106.55662033600005, 8.629273102000033],
              [106.5566188120001, 8.628900835000051],
              [106.55657590300011, 8.628570097000042],
              [106.55636699500005, 8.628281402000047],
              [106.55609560800006, 8.627951585000025],
            ],
          ],
          [
            [
              [106.62876869100009, 8.632611428000113],
              [106.62794179600007, 8.632353326000073],
              [106.627603983, 8.632354759000103],
              [106.62737956700009, 8.632542571000011],
              [106.62697197100003, 8.633777611000058],
              [106.62653035400008, 8.635834991000111],
              [106.6265327510001, 8.636395569000042],
              [106.62660943000006, 8.636768974000029],
              [106.62711457100002, 8.637460785000071],
              [106.62759512800008, 8.639044535000016],
              [106.62789621400003, 8.639230127000095],
              [106.62827157200009, 8.639228527000052],
              [106.62857154600005, 8.639152503000068],
              [106.62894529600005, 8.6387771870001],
              [106.62954474600009, 8.638513028000093],
              [106.63010761800004, 8.638473262000089],
              [106.633294291, 8.63756275100007],
              [106.63378129400006, 8.637336428000085],
              [106.63385524000006, 8.637074514000085],
              [106.63374133600004, 8.636776018000079],
              [106.63328994900009, 8.63655370300007],
              [106.63208592400012, 8.635886116000078],
              [106.63140804300008, 8.635365787000103],
              [106.63072887200011, 8.63454647400008],
              [106.62940823800008, 8.632945057000095],
              [106.62876869100009, 8.632611428000113],
            ],
          ],
          [
            [
              [106.61697276400011, 8.63475423700004],
              [106.61640957700006, 8.634719229000075],
              [106.61592161200009, 8.634721285000067],
              [106.61543508300008, 8.63505970000009],
              [106.61502345200003, 8.635360424000014],
              [106.61494950100004, 8.635622340000014],
              [106.61511690900004, 8.636011602000069],
              [106.61533058900005, 8.636966163000091],
              [106.61552461700002, 8.63846027400008],
              [106.616014987, 8.639018800000107],
              [106.61642915000007, 8.63931604000001],
              [106.61676729800008, 8.639389356000036],
              [106.61736660000003, 8.639087845000047],
              [106.61818888100002, 8.638262164000119],
              [106.61833695200002, 8.637775688000103],
              [106.61829528000003, 8.636804173000099],
              [106.61810408800002, 8.635982773000082],
              [106.61765047600005, 8.635237226000035],
              [106.61734891700007, 8.634939512000093],
              [106.61697276400011, 8.63475423700004],
            ],
          ],
          [
            [
              [106.55273303900003, 8.657498014000085],
              [106.55323602400007, 8.656570958000119],
              [106.55433092400007, 8.655809675000102],
              [106.55542790600006, 8.655552947000087],
              [106.55584952500004, 8.655383051000063],
              [106.55635318700007, 8.654624175000068],
              [106.55643419900011, 8.653782936000097],
              [106.55643006000003, 8.652773849000093],
              [106.55659517900001, 8.651848166000063],
              [106.55785829300007, 8.650918023000108],
              [106.5587011710001, 8.650494129000029],
              [106.5592927440001, 8.650575808000037],
              [106.55988640000001, 8.651162031000046],
              [106.56031181800005, 8.651917115000083],
              [106.5609899340001, 8.652503001000065],
              [106.56175008200003, 8.652499898000075],
              [106.56385814800009, 8.65165037100004],
              [106.56554215100007, 8.650382108000086],
              [106.56764845300009, 8.649112125000078],
              [106.57000846000005, 8.647925184000103],
              [106.57279214900007, 8.647072842000101],
              [106.57363535900004, 8.64673301200005],
              [106.57388663900004, 8.646227432000051],
              [106.57396830500005, 8.645554372000051],
              [106.57345875200008, 8.644883734000073],
              [106.57235694900004, 8.643963259000119],
              [106.571336821, 8.642369708000073],
              [106.56981065700003, 8.640946415000082],
              [106.5678642880001, 8.640029396000058],
              [106.56516092100007, 8.639872263000113],
              [106.56338626200005, 8.639627230000089],
              [106.56109690000008, 8.637450190000083],
              [106.55905885200011, 8.634767549000076],
              [106.55820703600006, 8.633005093000088],
              [106.55819978500008, 8.631239188000082],
              [106.55777439000006, 8.630484097000045],
              [106.55726697500009, 8.630317982000067],
              [106.55675988600005, 8.63023594900003],
              [106.55617041000004, 8.630658797000111],
              [106.55591979300009, 8.631332541000079],
              [106.5555892, 8.63309981600011],
              [106.55542304100001, 8.63377322000005],
              [106.55415825100009, 8.634282906000033],
              [106.55196338000012, 8.634544068000059],
              [106.550952975, 8.635304980000045],
              [106.55078646800008, 8.635894301000045],
              [106.55095985000004, 8.636986790000021],
              [106.55163724200004, 8.637404511000034],
              [106.55222982000004, 8.637738478000044],
              [106.55231703300007, 8.638410864000077],
              [106.55181578200006, 8.639758366000104],
              [106.55165993600005, 8.642954480000052],
              [106.55175608500011, 8.645813208000062],
              [106.55142306500008, 8.646991850000061],
              [106.55100419600006, 8.647834456000057],
              [106.55032851600002, 8.647837198000017],
              [106.54973557000008, 8.647419141000066],
              [106.54914125300012, 8.646664722000084],
              [106.54837698100002, 8.645658708000068],
              [106.5478637090001, 8.644063034000025],
              [106.54701738600006, 8.643645990000069],
              [106.54634205000005, 8.643732813000058],
              [106.54583837700004, 8.644491674000053],
              [106.54584318000008, 8.645668950000117],
              [106.54533950000005, 8.646427804000021],
              [106.54373680500005, 8.646938829000115],
              [106.54255435100001, 8.646943589000065],
              [106.54128538300009, 8.646444146000041],
              [106.54044043300007, 8.646363453000026],
              [106.53993571900006, 8.646870038000108],
              [106.53935030600012, 8.648301966000103],
              [106.53855036, 8.651053007000085],
              [106.53780926900006, 8.653605975000021],
              [106.53781780200006, 8.655708242000058],
              [106.53808314900007, 8.658650409000044],
              [106.53834507199998, 8.660751667000087],
              [106.53893940000005, 8.66150610300007],
              [106.53978403800002, 8.661502699000055],
              [106.54164157800007, 8.661327031000086],
              [106.54282201900004, 8.660817719000045],
              [106.5439166140001, 8.659972369000089],
              [106.544923313, 8.658286466000041],
              [106.54568004800008, 8.657442490000021],
              [106.54618648200005, 8.657356344000087],
              [106.54669394600003, 8.657522487000067],
              [106.54788536400011, 8.659704049000093],
              [106.54823560400006, 8.662729941000078],
              [106.54824145300009, 8.664159476000053],
              [106.54858137500005, 8.664662650000119],
              [106.54942602600005, 8.664659234000014],
              [106.5504389000001, 8.664486938000058],
              [106.55136664000003, 8.66414680100007],
              [106.55203959700003, 8.663471337000049],
              [106.55229022900006, 8.662797578000079],
              [106.55265927000005, 8.660105149000062],
              [106.55273303900003, 8.657498014000085],
            ],
          ],
          [
            [
              [106.67872582600008, 8.669424991000073],
              [106.67919402200009, 8.669236140000079],
              [106.67956966100009, 8.669327881000031],
              [106.68088000900005, 8.671011980000019],
              [106.68315507900009, 8.673981661000061],
              [106.68391054200006, 8.675099027000053],
              [106.68437999300001, 8.675190343000059],
              [106.68494158500002, 8.67490768800012],
              [106.68643583900003, 8.673406834000017],
              [106.68857920400009, 8.670222062000031],
              [106.68904573700006, 8.669659657000038],
              [106.68979451700001, 8.669282774000029],
              [106.69045157600002, 8.669373264000056],
              [106.69111113900006, 8.670024081000081],
              [106.69215052800008, 8.671700505000056],
              [106.69338380500008, 8.674776937000049],
              [106.69367946700002, 8.67795090400003],
              [106.69368532300007, 8.679258356000029],
              [106.69387503900003, 8.679724472000048],
              [106.6945317010001, 8.679721551000073],
              [106.69858246000007, 8.678494343000052],
              [106.70073692400007, 8.676629197000095],
              [106.70163712800004, 8.67436666600012],
              [106.70153742400005, 8.673059643000068],
              [106.70136480100007, 8.67180993200005],
              [106.70180497300007, 8.669976573000058],
              [106.70256627400009, 8.669348415000043],
              [106.70349262300009, 8.669782261000057],
              [106.70490307600008, 8.670523093000069],
              [106.70621635100011, 8.670517224000028],
              [106.70743455100011, 8.670231604000021],
              [106.70818161800001, 8.6694811410001],
              [106.70901910100011, 8.667983153000057],
              [106.70864008900001, 8.667144334000019],
              [106.7044407060001, 8.664176206000105],
              [106.70361063200008, 8.663517592000057],
              [106.70299459600008, 8.663340549000063],
              [106.70252599100004, 8.663436027000058],
              [106.70178060400009, 8.664560032000066],
              [106.70112564900009, 8.664936512000041],
              [106.69990620400009, 8.664941934000046],
              [106.69934170000001, 8.664570885000076],
              [106.69649212500005, 8.662108848000026],
              [106.69477729300009, 8.660946865000073],
              [106.69404637900001, 8.660629158000075],
              [106.69341879100004, 8.66074060700006],
              [106.69126366800006, 8.662178564000021],
              [106.68995459200002, 8.663118257000056],
              [106.689298385, 8.663214561000119],
              [106.68864174800002, 8.663217465000093],
              [106.68779625400006, 8.662941022000046],
              [106.68657972000003, 8.663600129000047],
              [106.68461814000008, 8.665476610000054],
              [106.68293753900012, 8.667258443000046],
              [106.68222048300004, 8.667924700000105],
              [106.68118841799999, 8.668371796000061],
              [106.68004849400009, 8.668238619000055],
              [106.67871670200003, 8.667370415000073],
              [106.67798992800003, 8.666283066000078],
              [106.67692811500009, 8.662873916000089],
              [106.67685712600009, 8.659910035000083],
              [106.6765179990001, 8.656851772000083],
              [106.67637398900008, 8.656105278000046],
              [106.67571695099998, 8.656014764000107],
              [106.67496651800008, 8.656018047000112],
              [106.67278643600008, 8.657116591000056],
              [106.66963291500011, 8.659029918],
              [106.66823449100004, 8.660997244000066],
              [106.66731002000002, 8.664083212000049],
              [106.66619504900004, 8.6665162720001],
              [106.66479618600003, 8.668390217000075],
              [106.66274104600008, 8.670360409000109],
              [106.66133845300001, 8.671393835000083],
              [106.65993667000004, 8.672614026000057],
              [106.65937875100005, 8.673737163000082],
              [106.65920424600009, 8.676726472000061],
              [106.65945436200009, 8.680274299000066],
              [106.65992751699999, 8.681206163000075],
              [106.66124414500004, 8.681947570000135],
              [106.66613398700005, 8.681794831000021],
              [106.66855510100001, 8.681742025000093],
              [106.67014887000008, 8.681510142000091],
              [106.67174032900003, 8.680756032000135],
              [106.67407562799998, 8.678504396000085],
              [106.67574969100012, 8.675228336000094],
              [106.67779604500006, 8.671296900000057],
              [106.67872582600008, 8.669424991000073],
            ],
          ],
          [
            [
              [106.5576449480001, 8.683714571000055],
              [106.55678055100007, 8.683409615000047],
              [106.55532237900005, 8.683652197000045],
              [106.55400722400003, 8.683783995000026],
              [106.55336582200007, 8.684147843000021],
              [106.55330676100007, 8.685087756000074],
              [106.5532931610001, 8.685604628000119],
              [106.55315351100009, 8.68607503300005],
              [106.55287266300003, 8.686639975000075],
              [106.55278283200006, 8.687110160000076],
              [106.55311342700006, 8.687589095000091],
              [106.55402963200001, 8.688718184000075],
              [106.55601419599999, 8.689289540000099],
              [106.5565006790001, 8.689239850000073],
              [106.55689845400006, 8.688693959000114],
              [106.55681761400004, 8.687922529000057],
              [106.55689929800005, 8.687249451000055],
              [106.55790598700003, 8.685563512],
              [106.55807249600012, 8.684974195000093],
              [106.55790043300001, 8.684218077000059],
              [106.5576449480001, 8.683714571000055],
            ],
          ],
          [
            [
              [106.73923802300004, 8.695382747000034],
              [106.73941977800007, 8.695030863000051],
              [106.74002610400005, 8.694985966000067],
              [106.74036437100006, 8.694928245000087],
              [106.74092534500002, 8.694223589000048],
              [106.74140141900007, 8.693463149000101],
              [106.74137138500006, 8.693070107],
              [106.74119824300004, 8.692228386000076],
              [106.7412353660001, 8.691104863000108],
              [106.74134132700007, 8.689615937000015],
              [106.741336147, 8.688492606000063],
              [106.74116416600006, 8.687903630000092],
              [106.74056825600006, 8.687148084000016],
              [106.74002514900006, 8.685605951000051],
              [106.73947971400005, 8.683558316000093],
              [106.73888420500008, 8.682887017000061],
              [106.73826114000003, 8.682356261000022],
              [106.73796499900004, 8.682371656000052],
              [106.73764491600012, 8.683313923000034],
              [106.73745081200005, 8.684044993000038],
              [106.7374386120001, 8.684773779000031],
              [106.73749797700003, 8.685097938000029],
              [106.73767131000008, 8.685981785000061],
              [106.73767266600001, 8.686276657000034],
              [106.73746283400007, 8.686656754000042],
              [106.73695719300005, 8.687122437000115],
              [106.73628190200006, 8.687504648000031],
              [106.73467702300005, 8.688185973000094],
              [106.73366301000007, 8.688527607000117],
              [106.73315502700007, 8.688487781000019],
              [106.73205097100001, 8.687650282000091],
              [106.73141565400003, 8.687526794000087],
              [106.73099376000002, 8.687802527000075],
              [106.73095328500006, 8.688202907000074],
              [106.73125413300008, 8.689212569000036],
              [106.73172501100004, 8.69038996300011],
              [106.73193852100003, 8.690810246000067],
              [106.73227898300004, 8.691229960000067],
              [106.73270388400003, 8.691607160000013],
              [106.73354133600004, 8.692747428000034],
              [106.7339402690001, 8.693609533000045],
              [106.734198819, 8.69461936900011],
              [106.73448337900001, 8.695151673000099],
              [106.73468201600011, 8.695403522000065],
              [106.73500649900004, 8.695416087000039],
              [106.73552743000006, 8.69520308300009],
              [106.73583852500005, 8.695370171000059],
              [106.73589622900002, 8.695650751000093],
              [106.73620991500005, 8.696379504000083],
              [106.73706270900009, 8.697779809000037],
              [106.73743268600006, 8.69848021900008],
              [106.73765940800007, 8.69870385000011],
              [106.73808308600005, 8.698814247000096],
              [106.73853458900001, 8.698840269000117],
              [106.73892863800002, 8.698641884000045],
              [106.73932190400005, 8.698274994000064],
              [106.73951795000002, 8.697965182000099],
              [106.73954434700008, 8.697571883000064],
              [106.73949982700003, 8.697094658000102],
              [106.73927064300007, 8.696337438000066],
              [106.73923802300004, 8.695382747000034],
            ],
          ],
          [
            [
              [106.54434791600008, 8.708715863000103],
              [106.54495454000009, 8.708713396000077],
              [106.54561653100006, 8.708765614000045],
              [106.54649821300009, 8.708597329000066],
              [106.54699363600005, 8.708375724000026],
              [106.54710213200006, 8.707936101000067],
              [106.54649009400005, 8.706621050000061],
              [106.54538017700006, 8.704923738000046],
              [106.54482532600005, 8.704102531000045],
              [106.54438324700007, 8.703884738000054],
              [106.54377596100001, 8.703722505000131],
              [106.54184717000003, 8.704059715000055],
              [106.54024881100003, 8.704285774000107],
              [106.53865089200006, 8.704621632000096],
              [106.53760287800003, 8.704570972000079],
              [106.53655530700004, 8.704630100000086],
              [106.53534363600006, 8.705019279000046],
              [106.53468366500009, 8.705461125000101],
              [106.53434342900009, 8.706035616000028],
              [106.53419650800001, 8.707713900000067],
              [106.53419852100009, 8.708207973000091],
              [106.53442113200003, 8.708701156000041],
              [106.5346988900001, 8.709194115000061],
              [106.53541737500004, 8.709575496000086],
              [106.53685255900004, 8.709899093000038],
              [106.53817565600009, 8.709783945000103],
              [106.53905734800003, 8.709615683000036],
              [106.54010380600002, 8.709282055000054],
              [106.54093079300006, 8.709223806000031],
              [106.54186830200008, 8.709220006000072],
              [106.54363167300006, 8.708883465000026],
              [106.54434791600008, 8.708715863000103],
            ],
          ],
          [
            [
              [106.58635813600004, 8.735319939000073],
              [106.58531627700009, 8.735033334000073],
              [106.58434691800008, 8.735045884000046],
              [106.58305786800001, 8.735145419000022],
              [106.58215329700003, 8.73531512600003],
              [106.58181442400007, 8.73564980500001],
              [106.5817773210001, 8.7359675630001],
              [106.58189574800005, 8.736200442],
              [106.58215812100008, 8.736459430000119],
              [106.58257770500006, 8.73682178300008],
              [106.583520494, 8.737337986000069],
              [106.58420069700006, 8.737543207000085],
              [106.58519421000003, 8.73769509500004],
              [106.58603073600005, 8.737795625],
              [106.58660532700007, 8.737741206000036],
              [106.58691776000008, 8.737479823000081],
              [106.58707298100003, 8.7371150770001],
              [106.587070345, 8.736490905000059],
              [106.58699194300009, 8.735887579000055],
              [106.58635813600004, 8.735319939000073],
            ],
          ],
          [
            [
              [106.66288349900005, 8.760570532000054],
              [106.662954778, 8.759905240000075],
              [106.66280237799999, 8.759019262000043],
              [106.66250183600009, 8.758207840000052],
              [106.66249921000005, 8.757616762000085],
              [106.66301719200001, 8.75724504900006],
              [106.66375951200006, 8.757241781000056],
              [106.66524252000002, 8.756865822000036],
              [106.66627979100004, 8.756417931000014],
              [106.66723954900004, 8.755231519000082],
              [106.66812472700011, 8.753971548000015],
              [106.66819369200003, 8.752789066000053],
              [106.66789150100007, 8.751608216000049],
              [106.66721783400003, 8.750355122000107],
              [106.66580383500009, 8.749548589000076],
              [106.66379304, 8.748079717000044],
              [106.66200888700007, 8.747496460000102],
              [106.65963351500008, 8.747506877000076],
              [106.65896805500009, 8.748100890000019],
              [106.6583787910001, 8.749137878000022],
              [106.65801548800006, 8.75091274900006],
              [106.65750534600004, 8.753057702000076],
              [106.65721037800006, 8.753502311000093],
              [106.65676630000009, 8.753799807000105],
              [106.65602430800007, 8.753876944000092],
              [106.65231497100011, 8.754187006000079],
              [106.65209034, 8.753968024000079],
              [106.651790799, 8.753378238000041],
              [106.65073982500007, 8.750722902000073],
              [106.65066234100006, 8.749984369000073],
              [106.65095536200005, 8.749096445000058],
              [106.65095080500004, 8.748062051000092],
              [106.65087168900006, 8.74695409800008],
              [106.65071932500007, 8.746068108000047],
              [106.65023291800006, 8.745183588000048],
              [106.6502312960001, 8.744814156000034],
              [106.65060211500005, 8.744738658000093],
              [106.65164362200007, 8.745251318000056],
              [106.65290586400006, 8.745319701000119],
              [106.65357394100009, 8.745316782000055],
              [106.65409191700004, 8.744945093000032],
              [106.65460642000008, 8.744286592000082],
              [106.65524891200003, 8.743874355000079],
              [106.65638815200006, 8.743826759000049],
              [106.658613745, 8.743521470000102],
              [106.65957840400006, 8.743443352000034],
              [106.66061368900009, 8.742552181000105],
              [106.662018813, 8.741363823000102],
              [106.662682289, 8.740326508000072],
              [106.662751934, 8.739291790000079],
              [106.66274636500007, 8.738035746000071],
              [106.66166518200005, 8.733557945000124],
              [106.66115236700008, 8.732866290000095],
              [106.65939169500007, 8.730769799000091],
              [106.65883270400008, 8.730255037000044],
              [106.65534272200007, 8.729974734000033],
              [106.64993813500008, 8.730062065000064],
              [106.64770055000005, 8.73074687600006],
              [106.64636737200004, 8.731417652000049],
              [106.64547890200008, 8.731938716000085],
              [106.64473661900004, 8.731941937000086],
              [106.64264671300005, 8.731250893000018],
              [106.6404972240001, 8.73003923000004],
              [106.63829029400002, 8.727564493],
              [106.63706277300005, 8.725473051000064],
              [106.63598323000008, 8.722614418000061],
              [106.63514523700012, 8.719791969000122],
              [106.63498843000011, 8.717871565000033],
              [106.6350526890001, 8.715580775000118],
              [106.63504563100005, 8.713955269000094],
              [106.63548583900005, 8.712771175000087],
              [106.63755737400004, 8.711210619000019],
              [106.64062510800002, 8.709490224000136],
              [106.64370385800002, 8.707531213000051],
              [106.64922478200003, 8.702616057000066],
              [106.65375460500012, 8.699459705000033],
              [106.65720468000004, 8.696888080000063],
              [106.65811646100006, 8.695826258000048],
              [106.65841174600007, 8.694857348000083],
              [106.65808418900005, 8.693540119000074],
              [106.65774727500008, 8.692876601000064],
              [106.656487156, 8.693251520000088],
              [106.65522671800007, 8.693552548000037],
              [106.65314628900006, 8.69304437500009],
              [106.65113717300008, 8.691870900000074],
              [106.65039238200011, 8.691283028000017],
              [106.65002129000004, 8.691284634000061],
              [106.64905868600006, 8.691806016000035],
              [106.64869146700005, 8.692694248000075],
              [106.64814050300004, 8.69399640000008],
              [106.64625639900001, 8.69595581800011],
              [106.642778, 8.698261363000091],
              [106.64062878700005, 8.699009496000086],
              [106.637291702, 8.699688854000074],
              [106.63432667000006, 8.700588255000062],
              [106.63317600600007, 8.700543230000067],
              [106.63153949300002, 8.699713553000075],
              [106.63010631600011, 8.698525152000068],
              [106.62835187300007, 8.696568691000016],
              [106.62758946300005, 8.69500066700004],
              [106.62727821200005, 8.692638564000086],
              [106.62741964000003, 8.691012411000097],
              [106.62637163000009, 8.688948034000086],
              [106.6255472780001, 8.687878499000055],
              [106.62517806000001, 8.68754924800008],
              [106.62414183900007, 8.688218661000045],
              [106.62293070200005, 8.690342803000116],
              [106.62207765400008, 8.691478530000028],
              [106.6216345500001, 8.69199763500008],
              [106.62104046800006, 8.691926274000126],
              [106.62048384100009, 8.691687643000099],
              [106.61832429200004, 8.690685111000017],
              [106.61547541700004, 8.688726203000028],
              [106.61285335200007, 8.686864152000061],
              [106.61181718000005, 8.685839219000078],
              [106.61021450700005, 8.684220449000078],
              [106.60842473400001, 8.682233006000038],
              [106.60745767500003, 8.681719864000035],
              [106.60619374300008, 8.681207959000046],
              [106.60307119, 8.679964990000054],
              [106.60031693800002, 8.678055438000063],
              [106.59829520500007, 8.676311925000045],
              [106.59732846000001, 8.674999555000083],
              [106.59624849800011, 8.672697814000062],
              [106.59561149800007, 8.671055690000083],
              [106.59564174900008, 8.669430000000132],
              [106.59563831800006, 8.668617231000029],
              [106.59630065500006, 8.667284463000067],
              [106.59740736400006, 8.665728155000084],
              [106.59807344400011, 8.66528204000012],
              [106.59918481700005, 8.664834062000091],
              [106.59977604800011, 8.664240469000093],
              [106.60080694800006, 8.662315035000033],
              [106.60241999500003, 8.657653268000052],
              [106.60343039700005, 8.656405567000112],
              [106.60682942300009, 8.652399036000078],
              [106.6083966800001, 8.649886296000096],
              [106.61038567100009, 8.645462093000054],
              [106.61166828100008, 8.642171662000029],
              [106.61181262100011, 8.641210503000083],
              [106.61181042500002, 8.6406932940001],
              [106.61129528800004, 8.640286398000095],
              [106.60982927300006, 8.639770927000077],
              [106.60758618900009, 8.639920088000073],
              [106.60441458800004, 8.640542624000068],
              [106.59956614600007, 8.642734951000096],
              [106.5951052560001, 8.644437173000073],
              [106.58943263300006, 8.645862339000042],
              [106.58754143699998, 8.646165745000099],
              [106.58687320700002, 8.646094628000077],
              [106.58613015200009, 8.64587603600002],
              [106.58531040500004, 8.645066641000108],
              [106.5841226900001, 8.644997663000085],
              [106.58159943900004, 8.64500809600006],
              [106.57778865300004, 8.645123704000033],
              [106.57581294400008, 8.645549185000066],
              [106.574924224, 8.645996182000102],
              [106.57477764100004, 8.646440123000064],
              [106.57500181900011, 8.646808655000092],
              [106.575448018, 8.647028482000073],
              [106.57641249400004, 8.646950623000025],
              [106.57759991300006, 8.646945732000065],
              [106.5782681420001, 8.647016870000089],
              [106.57879040800006, 8.647679726000133],
              [106.57879317900002, 8.648344724000088],
              [106.57820223900006, 8.649012168000068],
              [106.57450288600005, 8.651761337000073],
              [106.56962521200005, 8.656732006000121],
              [106.56771089000003, 8.660434372000035],
              [106.56391346100006, 8.666435042000041],
              [106.56177975300008, 8.670951076000042],
              [106.559647829, 8.675910454000045],
              [106.559088742, 8.678241954000042],
              [106.55926127300008, 8.681158249000108],
              [106.56030677600006, 8.682705665000066],
              [106.56253922900004, 8.684100439000096],
              [106.56410431000003, 8.685645726000056],
              [106.56529768300001, 8.68704473100008],
              [106.56619294800001, 8.688149411],
              [106.56664503100004, 8.689773129000054],
              [106.56664963700005, 8.690881461000098],
              [106.56676587900009, 8.692063229],
              [106.56706461100002, 8.692505330000076],
              [106.56765900600008, 8.692650674000022],
              [106.57017798000004, 8.691531945000095],
              [106.5723276670001, 8.690858073000117],
              [106.57277330600002, 8.690930124000127],
              [106.57322049200008, 8.691371609000063],
              [106.57389836700003, 8.69373328900007],
              [106.57547794100007, 8.698477069000086],
              [106.57593317800008, 8.700650633000072],
              [106.57635093200001, 8.703959039000074],
              [106.57682146900004, 8.705996785000101],
              [106.57730702900007, 8.706733668000053],
              [106.57767939000006, 8.707027681000044],
              [106.57834742600008, 8.707024905000081],
              [106.57930832600002, 8.706060355000121],
              [106.58056674300003, 8.705242343000029],
              [106.58145589500006, 8.704869203000063],
              [106.58227299100008, 8.705013584000108],
              [106.5843525340001, 8.705300478000043],
              [106.58665505700002, 8.705660323000062],
              [106.58754793600008, 8.706173832],
              [106.58814454100006, 8.706836350000023],
              [106.58814953600003, 8.70801855400005],
              [106.58830639900005, 8.710012913000041],
              [106.58690639600012, 8.712457103000036],
              [106.58669212600006, 8.714453011000066],
              [106.58699825100007, 8.71657025800001],
              [106.58788944400001, 8.71955196600007],
              [106.58993596400005, 8.72297925300005],
              [106.59081660000008, 8.724483696000094],
              [106.59250623, 8.725185148000046],
              [106.59450993000004, 8.725065900000038],
              [106.59684812500007, 8.725056075000024],
              [106.59829134000002, 8.724052504000033],
              [106.59906978800004, 8.723827555000073],
              [106.59951751800004, 8.724379830000116],
              [106.59996619100004, 8.725153779000026],
              [106.59996949300005, 8.725929594000091],
              [106.60030635700012, 8.726593172000017],
              [106.60755881000004, 8.730109164000053],
              [106.60856517900001, 8.731102401000078],
              [106.60968811900001, 8.733314284000036],
              [106.61069211500003, 8.733753350000127],
              [106.61191690300004, 8.733748149000052],
              [106.61369508500007, 8.732964761000071],
              [106.61562084900008, 8.731447109000088],
              [106.61709800600009, 8.730253345000081],
              [106.61776607200002, 8.730250497000023],
              [106.61873358600006, 8.730837469000095],
              [106.62007385600002, 8.731792290000049],
              [106.62119462900003, 8.733486937000111],
              [106.62246706600006, 8.735919793000056],
              [106.62277101400008, 8.737544023000064],
              [106.62240369900006, 8.738432260000105],
              [106.62195991600002, 8.738803591000098],
              [106.62129184, 8.738806453000068],
              [106.62054986200006, 8.738883514000049],
              [106.62010830200003, 8.739772061000027],
              [106.619963033, 8.740511557000017],
              [106.6199675070001, 8.741545980000074],
              [106.62026826000003, 8.742431345000055],
              [106.62056806200005, 8.743095055000081],
              [106.62220402100006, 8.743753040000078],
              [106.62614564400005, 8.745435569000025],
              [106.627333338, 8.745430465000046],
              [106.6278510380001, 8.74498490300004],
              [106.628515264, 8.744095399000072],
              [106.62888641400006, 8.744093805000118],
              [106.62985271000008, 8.744385193000069],
              [106.63141477000001, 8.745117351000074],
              [106.632830638, 8.746367335000025],
              [106.63439625600006, 8.747912225000041],
              [106.63581827600002, 8.75056602100006],
              [106.63686204500006, 8.751595939000099],
              [106.63782996900002, 8.752256733000062],
              [106.63950278300007, 8.752840594000054],
              [106.64202765400007, 8.75305130400008],
              [106.64418006400008, 8.752968070000032],
              [106.64566438000006, 8.752887727000045],
              [106.64715065000011, 8.753250696000057],
              [106.64952997700007, 8.754126977000086],
              [106.65064607200004, 8.754713206000096],
              [106.65139458400007, 8.756113786000073],
              [106.65132686900006, 8.757591819000064],
              [106.65222941200004, 8.760247808000086],
              [106.65304793500009, 8.760687549000055],
              [106.65468074000009, 8.760606523000101],
              [106.65594107400001, 8.760231560000074],
              [106.65712913099999, 8.760300243000073],
              [106.65913604300005, 8.760882539000113],
              [106.66054779000007, 8.761171885000072],
              [106.66140244800008, 8.761389798000073],
              [106.66221803400006, 8.761164545000087],
              [106.66288349900005, 8.760570532000054],
            ],
          ],
          [
            [
              [106.71524550000005, 8.782073572000025],
              [106.71379881899999, 8.781101239000043],
              [106.71263488200006, 8.781383087000092],
              [106.71194976500004, 8.78253498000001],
              [106.71136087800008, 8.783783814000044],
              [106.71154855300006, 8.784748417000097],
              [106.71164060100003, 8.785519741000057],
              [106.71221867800008, 8.786005028000064],
              [106.71289480600007, 8.786298228000076],
              [106.71386208300008, 8.786496874000081],
              [106.71483354100008, 8.786021139000065],
              [106.71522692900002, 8.78506011400013],
              [106.71542899700005, 8.783712550000098],
              [106.71524550000005, 8.782073572000025],
            ],
          ],
          [
            [
              [107.07515342700003, 10.376313310000016],
              [107.07487643700003, 10.376180305000025],
              [107.07376376600007, 10.376321727000091],
              [107.07246460000005, 10.376718397000049],
              [107.07164548200004, 10.377519618000084],
              [107.07135969900003, 10.379237776000048],
              [107.07143020100008, 10.380042938000054],
              [107.07105555000008, 10.380919287],
              [107.07023575600002, 10.381866831000062],
              [107.069489414, 10.382961035000092],
              [107.06948444500003, 10.384058538000128],
              [107.0702228480001, 10.384720324000083],
              [107.07155533300005, 10.385165236000125],
              [107.07220354500004, 10.385277853000028],
              [107.07311387600004, 10.384806292000091],
              [107.07458485100007, 10.383312844000068],
              [107.07611419400004, 10.381197719000033],
              [107.07667597400007, 10.379919768000077],
              [107.07682900700003, 10.378859512000103],
              [107.07646408900004, 10.377577476000075],
              [107.07602289000003, 10.376770696000024],
              [107.07515342700003, 10.376313310000016],
            ],
          ],
          [
            [
              [107.09486328400006, 10.395583927000105],
              [107.09367895000003, 10.39513979600004],
              [107.09271091600004, 10.396086818000059],
              [107.09270413200002, 10.397623344000094],
              [107.09328629800004, 10.400113636000084],
              [107.09416053100009, 10.403629570000115],
              [107.09519017700009, 10.405536450000062],
              [107.09622434800005, 10.406418954000072],
              [107.09733460399998, 10.406862759000111],
              [107.09770661200012, 10.406571678000097],
              [107.09741742000008, 10.404887515000015],
              [107.09720205800002, 10.403276836000062],
              [107.09721073600004, 10.401301285000029],
              [107.09699312900005, 10.40020280100009],
              [107.09677809700003, 10.398518965000035],
              [107.09611789700003, 10.396906394000014],
              [107.09486328400006, 10.395583927000105],
            ],
          ],
          [
            [
              [107.10235924400006, 10.410835555000107],
              [107.10147331400003, 10.409953710000062],
              [107.100359838, 10.410241620000081],
              [107.09939175400011, 10.411188687000015],
              [107.09931344500002, 10.412139583000089],
              [107.10056530500003, 10.41412057900004],
              [107.10137836800007, 10.41470943600009],
              [107.10293325000004, 10.415228293000032],
              [107.1033065510001, 10.414644518000047],
              [107.10338420500004, 10.413839966000092],
              [107.10294506800003, 10.412521005000118],
              [107.10235924400006, 10.410835555000107],
            ],
          ],
          [
            [
              [107.15537585300004, 10.44863363600008],
              [107.15473523500006, 10.448552008000076],
              [107.1536922440001, 10.44890353500003],
              [107.15248461100001, 10.450321582000031],
              [107.15131652399999, 10.451858380000088],
              [107.15091234000003, 10.452726317000048],
              [107.15086933800004, 10.453437613000103],
              [107.15158731900001, 10.454191510000042],
              [107.15298614900003, 10.454987692000104],
              [107.15366554500011, 10.455385700000084],
              [107.15438711300004, 10.455270025000106],
              [107.15495006400008, 10.454758454000055],
              [107.15531564400004, 10.453534615000047],
              [107.15572153600004, 10.452245831000083],
              [107.1560098050001, 10.450335798000053],
              [107.15601321500003, 10.449505770000121],
              [107.15585508900008, 10.448991298000097],
              [107.15537585300004, 10.44863363600008],
            ],
          ],
          [
            [
              [107.14922755400008, 10.455113692000049],
              [107.14890731100004, 10.452809549000095],
              [107.14904160500006, 10.451007845000058],
              [107.14861762200006, 10.44925396800007],
              [107.14793744900001, 10.44809976600005],
              [107.146303522, 10.444488701000052],
              [107.14527969100001, 10.440579728],
              [107.144501296, 10.432566814000042],
              [107.14351753900006, 10.42515388800007],
              [107.14271546600004, 10.422847860000049],
              [107.14195870700004, 10.421843567000087],
              [107.139527899, 10.42083241100009],
              [107.13709459200001, 10.420421940000056],
              [107.13242754100004, 10.420402684000061],
              [107.125730903, 10.420474988000093],
              [107.12400612800012, 10.420467791000119],
              [107.12177619900008, 10.419957876000028],
              [107.11914301899999, 10.418845522000096],
              [107.11742341700008, 10.417636860000099],
              [107.11640971600006, 10.417432361000055],
              [107.11539170700003, 10.418229020000068],
              [107.11508086600007, 10.41972948900005],
              [107.11475234700006, 10.425334795000117],
              [107.11392339900004, 10.429336102000073],
              [107.11268769800003, 10.433535966000116],
              [107.11227405700001, 10.435336398000048],
              [107.11237292200011, 10.43593754500005],
              [107.11378911800003, 10.436944755000075],
              [107.1158132640001, 10.438154759],
              [107.11976059200002, 10.440474173000068],
              [107.121880291, 10.443086253],
              [107.12248098000005, 10.444991105000055],
              [107.12267494300002, 10.447094501],
              [107.12256662700003, 10.448696027],
              [107.12154292200006, 10.450794339000067],
              [107.11991164200001, 10.452589740000027],
              [107.11894335300008, 10.453586923000023],
              [107.11843211200006, 10.454485894000063],
              [107.11842867600004, 10.455286878000079],
              [107.11944042100001, 10.455991998000068],
              [107.12563126400002, 10.45581764600003],
              [107.130739551, 10.455422478000019],
              [107.13620586400002, 10.454314080000058],
              [107.13791815900004, 10.453866018000022],
              [107.13882974400005, 10.454270266000075],
              [107.14014513500008, 10.455176783000029],
              [107.14105297200007, 10.456482129000031],
              [107.14185724900008, 10.45828766800012],
              [107.14265986900006, 10.460493711000128],
              [107.14231836900004, 10.463295836000073],
              [107.14202777000007, 10.465056762000112],
              [107.14235845400007, 10.465849218000043],
              [107.14313987600008, 10.466062576000111],
              [107.14558190300006, 10.465511934000025],
              [107.14774375600005, 10.46421908500008],
              [107.14825260000006, 10.463870713000032],
              [107.14878618200007, 10.463113142000056],
              [107.14920392400008, 10.460820735000096],
              [107.1494446770001, 10.458886473000105],
              [107.14956960200004, 10.457740707000072],
              [107.14954968600009, 10.456967311000119],
              [107.14922755400008, 10.455113692000049],
            ],
          ],
          [
            [
              [107.47813099100006, 10.776886134000028],
              [107.48113507200003, 10.772807943000105],
              [107.48505559200004, 10.770547814000063],
              [107.49012461300006, 10.769425293],
              [107.49564966300005, 10.770119575000068],
              [107.49910222600009, 10.770808778000072],
              [107.50385350100001, 10.772695093],
              [107.50415967000004, 10.77272379200007],
              [107.50324325300005, 10.76196851200004],
              [107.50210202400004, 10.748573443000085],
              [107.50522994400004, 10.747603002000082],
              [107.52143877, 10.733610799000102],
              [107.52781887400006, 10.727325327000068],
              [107.53440197200003, 10.719071473000062],
              [107.53819280200007, 10.713961274000015],
              [107.54064004500005, 10.708608779000082],
              [107.54180298799999, 10.700582328000062],
              [107.54102852400004, 10.691131248000117],
              [107.54088592900005, 10.68253579100009],
              [107.54119729300005, 10.677438291000028],
              [107.54174779900004, 10.673688965000048],
              [107.54934710900007, 10.654413876000122],
              [107.55784875700006, 10.638263228000076],
              [107.5613411570001, 10.632266822000068],
              [107.56564346, 10.620920916000019],
              [107.56603980700002, 10.61987562300004],
              [107.56613253700006, 10.616036091000057],
              [107.5661555520001, 10.615682180000084],
              [107.56625348100003, 10.612839104000036],
              [107.56650728000007, 10.612021592000074],
              [107.56817711100003, 10.594816657000104],
              [107.56791507300002, 10.588835367000103],
              [107.56792193500006, 10.585573175000105],
              [107.56820854500006, 10.579864946000049],
              [107.57011157000004, 10.570545664000084],
              [107.56544113500004, 10.567994259000011],
              [107.56033079600007, 10.565007815000092],
              [107.55504609100007, 10.561064288000031],
              [107.548336083, 10.55409901000006],
              [107.54117025900004, 10.544883996000108],
              [107.53606415100006, 10.535955551000063],
              [107.51473510000001, 10.507007073000095],
              [107.51199689200003, 10.503908901000079],
              [107.51006107200008, 10.502624271000103],
              [107.50760715600009, 10.50221035100005],
              [107.50288065000008, 10.502160991000052],
              [107.49649342600006, 10.50238461400008],
              [107.48720841800009, 10.501417523000088],
              [107.47942086200003, 10.501651779000019],
              [107.47460193400011, 10.500996057000032],
              [107.46836827400008, 10.498531287000022],
              [107.46502479100012, 10.496303943000028],
              [107.45986296500007, 10.491013986000029],
              [107.45350451900003, 10.483783142000023],
              [107.44633564200009, 10.473611661000069],
              [107.44359767600012, 10.470426004],
              [107.44263031800003, 10.469826751000076],
              [107.44148794200001, 10.469228844000076],
              [107.4400871, 10.469152916000011],
              [107.41806438100011, 10.467544205000044],
              [107.41307624400004, 10.467582047000066],
              [107.40406722600008, 10.468256873000124],
              [107.39374808000008, 10.469287997],
              [107.38569619900008, 10.469261769000047],
              [107.37755410300008, 10.46888931000005],
              [107.37128981400011, 10.468409611000061],
              [107.36711511100002, 10.467088103000053],
              [107.36413971900004, 10.465772592000098],
              [107.36099834400009, 10.464619854000111],
              [107.35785903600005, 10.46281381500007],
              [107.35571137200007, 10.461500735000049],
              [107.3535637260001, 10.460187627000026],
              [107.35083757800004, 10.458627743000102],
              [107.34860646200009, 10.457559297000072],
              [107.34711733800007, 10.457391350000121],
              [107.34670043300004, 10.458369923000086],
              [107.34661481300006, 10.459267879000043],
              [107.34710929500002, 10.45992265900013],
              [107.34999888600002, 10.46213635100006],
              [107.35429419200005, 10.464762630000022],
              [107.35693845600008, 10.466077264000083],
              [107.35784678700001, 10.466733303000096],
              [107.35809354900009, 10.467223996000106],
              [107.35784398000004, 10.467631520000074],
              [107.35756481400004, 10.467785331000099],
              [107.35651748800007, 10.46836237200006],
              [107.35461369800002, 10.468438173000026],
              [107.35329052100005, 10.468107468000108],
              [107.35159635900004, 10.467155791000058],
              [107.34825134900002, 10.465152226000017],
              [107.34626767600002, 10.464329480000066],
              [107.34436574300005, 10.463833608000071],
              [107.34188317900008, 10.463744174000043],
              [107.33907142700005, 10.463082079000062],
              [107.33750292800002, 10.461852286000093],
              [107.33601905900008, 10.460051159000015],
              [107.33519516700004, 10.458905368000069],
              [107.33486703600008, 10.458006106000093],
              [107.33470416200001, 10.457189035000079],
              [107.33478956900005, 10.456372753000041],
              [107.33553464500007, 10.456293457000053],
              [107.33718742500012, 10.45703359],
              [107.33933750600004, 10.458423946000027],
              [107.34041127600005, 10.459080574],
              [107.34206125200006, 10.459825234000062],
              [107.34297238900004, 10.459583115000013],
              [107.34305697200003, 10.459011790000082],
              [107.34240196100009, 10.456805031000014],
              [107.34131383600004, 10.455364319000092],
              [107.33800880200005, 10.453720804000087],
              [107.33335793800009, 10.452357242000064],
              [107.32917937500011, 10.451047283000035],
              [107.32777386700009, 10.450634515000077],
              [107.32283039100007, 10.448101289000087],
              [107.31179414000006, 10.440944066000045],
              [107.30839809100009, 10.437998057000096],
              [107.29911110000005, 10.42994112700004],
              [107.28567406600008, 10.417426037000064],
              [107.278171883, 10.40863846500004],
              [107.26961071400007, 10.398557942000062],
              [107.25857275900009, 10.384680667000088],
              [107.2553985970001, 10.381322522000056],
              [107.25460794500005, 10.381008581000104],
              [107.25346807100006, 10.380555968000047],
              [107.24809251699999, 10.379469818000063],
              [107.24199171300005, 10.378642700000016],
              [107.24102862700003, 10.378562752000063],
              [107.24032927100008, 10.378654304000088],
              [107.23962990500003, 10.378745863000026],
              [107.23780540400006, 10.380578856000076],
              [107.23459051500006, 10.38448244600003],
              [107.23116620200007, 10.388296261000017],
              [107.22630177900002, 10.3933575170001],
              [107.22174009300007, 10.398106754000123],
              [107.22002147000006, 10.400482281000032],
              [107.21875705100007, 10.402412881000076],
              [107.21728807600003, 10.405235931000066],
              [107.21602814500005, 10.405975580000069],
              [107.21121374200006, 10.406767466000042],
              [107.20377685200006, 10.406992092000065],
              [107.19817031300006, 10.4061638050001],
              [107.188088792, 10.40363237000008],
              [107.17923248500006, 10.401092262000054],
              [107.17686798900009, 10.400848293000118],
              [107.17620494700003, 10.400953940000067],
              [107.17558208900002, 10.401199534000048],
              [107.17547353400009, 10.401777871000116],
              [107.1754716340001, 10.402251395000038],
              [107.17578782200007, 10.402884016000067],
              [107.17764134800004, 10.404311898000083],
              [107.18172409500008, 10.406222011000031],
              [107.18395183800001, 10.40707254400006],
              [107.18548818900004, 10.408130822000048],
              [107.186440003, 10.409239437000027],
              [107.18707310400008, 10.410346823000081],
              [107.18727975500012, 10.411820859],
              [107.18732496800004, 10.413820426000074],
              [107.18731976600006, 10.415135809000018],
              [107.18667041600006, 10.418132411000068],
              [107.18628999900002, 10.420288199000092],
              [107.18628562400002, 10.421393133000032],
              [107.18627812300011, 10.423287298000082],
              [107.18637583600011, 10.425444967000084],
              [107.18647480100005, 10.427286943000079],
              [107.18652410400003, 10.428120777000059],
              [107.18689394900008, 10.428870266000077],
              [107.18711036000005, 10.42935562700009],
              [107.18735598500004, 10.429716988000058],
              [107.18739754800001, 10.429969132000048],
              [107.18705601500008, 10.430241145000084],
              [107.18646390400002, 10.430659560000064],
              [107.18582236100006, 10.430623743000032],
              [107.1849553930001, 10.430245438000084],
              [107.18446118400006, 10.429671952000062],
              [107.18320355900001, 10.428273952000032],
              [107.17866618800011, 10.420363716000034],
              [107.17297915600003, 10.41313827200012],
              [107.170520653, 10.411492163000078],
              [107.16850923500002, 10.409695265000083],
              [107.16693308400008, 10.407334968000013],
              [107.16218349600003, 10.397673872000011],
              [107.16045209300003, 10.395072445000062],
              [107.15908357800005, 10.392802132000066],
              [107.15747462300006, 10.389926880000109],
              [107.15632090700008, 10.389060064000065],
              [107.15030374000004, 10.387088939000042],
              [107.14118355400004, 10.383214342000089],
              [107.13427183500005, 10.379499668000046],
              [107.12602892400005, 10.374744134000123],
              [107.12110181700007, 10.370709864000014],
              [107.11248681900003, 10.36307018500011],
              [107.10484913000002, 10.356298402000073],
              [107.09839683500003, 10.34851285200009],
              [107.09251372500007, 10.339979993],
              [107.08813681000004, 10.332224365000096],
              [107.08540497600004, 10.326250966000126],
              [107.08424533600001, 10.324421770000034],
              [107.08362455800008, 10.323988474000066],
              [107.08274126800009, 10.323906719000052],
              [107.07167976300003, 10.333248860000042],
              [107.07062792100005, 10.334392626000087],
              [107.06992972900011, 10.335621579000074],
              [107.06985577800002, 10.337808593000107],
              [107.06986666500003, 10.339470297000018],
              [107.07084687300005, 10.340863387000086],
              [107.07209077700003, 10.342079852000017],
              [107.07271331100003, 10.342775544000025],
              [107.07272536600004, 10.34461216000002],
              [107.07177038200007, 10.347067234000027],
              [107.0691386500001, 10.34962054500008],
              [107.0647868450001, 10.35240353300013],
              [107.06021510500003, 10.355319089000135],
              [107.05890230700003, 10.35707673500008],
              [107.05864544400001, 10.35830285400001],
              [107.058478022, 10.359703326000067],
              [107.05920484000009, 10.362847306000054],
              [107.060820248, 10.366860220000083],
              [107.06096497600007, 10.368739726000074],
              [107.05991413599999, 10.370058401000025],
              [107.05411637100008, 10.374556101000019],
              [107.05368407, 10.375958265000039],
              [107.053700044, 10.378407116000064],
              [107.0544377570001, 10.383212825000053],
              [107.05613870100007, 10.386787889000091],
              [107.05760379000006, 10.388046694000062],
              [107.05875214100004, 10.388126799000117],
              [107.05945903400008, 10.38820971500005],
              [107.06069395300003, 10.388026861000109],
              [107.062892033, 10.386613352000039],
              [107.06709719300004, 10.38160095400009],
              [107.07032703500002, 10.375982599000098],
              [107.07128955800007, 10.374664471000104],
              [107.07305066800005, 10.373953422000069],
              [107.07428437000007, 10.37359561400004],
              [107.07552037100002, 10.37358762800009],
              [107.07871423600005, 10.375928424000056],
              [107.07951400600005, 10.376710400000098],
              [107.08138363100002, 10.379059738000061],
              [107.0813888430001, 10.379846853000084],
              [107.07825556600002, 10.386689119],
              [107.07826771000005, 10.388525730000085],
              [107.07853893000002, 10.38948605000005],
              [107.07987369900006, 10.391051691000095],
              [107.08084778600001, 10.391482682000051],
              [107.08217213700007, 10.39147408600005],
              [107.08503663200003, 10.39071205200006],
              [107.08917753200008, 10.389373197000038],
              [107.09006043500003, 10.389367448000096],
              [107.09165196200009, 10.389706916000117],
              [107.09732346600003, 10.392818454000084],
              [107.09998034100005, 10.394025506000036],
              [107.10139940900004, 10.394978264000086],
              [107.10273956400007, 10.397330891000054],
              [107.10410024600004, 10.402744472000068],
              [107.10625152600005, 10.407540619000065],
              [107.10749527400002, 10.408669407000025],
              [107.11139249500008, 10.410979381000105],
              [107.11223811700005, 10.411480591],
              [107.11392276900008, 10.412518966000038],
              [107.11515830300007, 10.41242334600012],
              [107.11621370500009, 10.411804167000048],
              [107.1172667340001, 10.410835151000073],
              [107.11787712100006, 10.40969414600008],
              [107.11821789100001, 10.407855256000074],
              [107.118619219, 10.407496890000088],
              [107.11901915800004, 10.407333659000082],
              [107.11927549800004, 10.407304208000044],
              [107.11974528700009, 10.407250233000108],
              [107.12051055600008, 10.407402784000029],
              [107.12193032300004, 10.408442885000023],
              [107.12264317600005, 10.409400204000095],
              [107.12266151900006, 10.412111299000058],
              [107.123286686, 10.413156663000043],
              [107.12417672300002, 10.41420026100006],
              [107.12541699400006, 10.414804240000118],
              [107.12780153000008, 10.414875853000092],
              [107.13468668100009, 10.41456762200008],
              [107.13884065000006, 10.415152082000121],
              [107.14407209500011, 10.418353009000024],
              [107.14549498300006, 10.419830243000126],
              [107.14718994200007, 10.422355135000048],
              [107.14773230400009, 10.424188103000096],
              [107.14886687100008, 10.43511263],
              [107.14904800900005, 10.43716736400002],
              [107.14913207500008, 10.437486168000108],
              [107.14939782600004, 10.438493982000095],
              [107.14991791700001, 10.441549757000066],
              [107.15059183500007, 10.444205799000093],
              [107.15132759800011, 10.445131525000038],
              [107.152137705, 10.445535299000051],
              [107.15338259700006, 10.445187334000126],
              [107.1540186130001, 10.444739407000013],
              [107.15523347000007, 10.444374672000032],
              [107.16174776400004, 10.445652407000059],
              [107.16458323000008, 10.44706544600009],
              [107.16518676700009, 10.448369458000071],
              [107.16470215600006, 10.449018275000109],
              [107.16382904100003, 10.449294833000076],
              [107.15841464600004, 10.450029256000091],
              [107.157771278, 10.450619566000084],
              [107.15694826600003, 10.453042331000082],
              [107.15622849800008, 10.455342298000046],
              [107.15510444700006, 10.457240147000045],
              [107.15377412300003, 10.459938168000081],
              [107.15295528900005, 10.461636997000044],
              [107.15294992300005, 10.462938615],
              [107.15325106300001, 10.463740831000033],
              [107.15446427900011, 10.464847114000085],
              [107.1558788240001, 10.466354712000035],
              [107.15829753600011, 10.470469635000066],
              [107.15993445000001, 10.472724892000061],
              [107.16132852200008, 10.473785973000103],
              [107.16423907400011, 10.475549752000022],
              [107.16504461600006, 10.476171469000038],
              [107.16563056700005, 10.476865207000062],
              [107.16572960900007, 10.477466369000064],
              [107.16532286000007, 10.477894429000044],
              [107.16465958900008, 10.478204900000064],
              [107.16379420400007, 10.478318804000073],
              [107.16273093800002, 10.478297345000074],
              [107.16106033000008, 10.478026427000129],
              [107.15954115900007, 10.477523893000027],
              [107.15775991700004, 10.476771475000122],
              [107.15627521900004, 10.475808676000041],
              [107.15463098800005, 10.473658961000075],
              [107.153522497, 10.471752066000038],
              [107.152584265, 10.469420595000132],
              [107.1516742310001, 10.468615900000076],
              [107.1501113300001, 10.468383942000068],
              [107.148689969, 10.468528363000116],
              [107.14399268000003, 10.469410467],
              [107.14003028300009, 10.470495647000087],
              [107.13911693700001, 10.470491901000043],
              [107.13820612000006, 10.469887399000031],
              [107.13790629000003, 10.468784769000104],
              [107.13790881100006, 10.468184020000047],
              [107.13801281700005, 10.467583688000024],
              [107.13865652700011, 10.465333414000087],
              [107.13884103000001, 10.463682160000063],
              [107.13885278000001, 10.460878685000095],
              [107.13774030300006, 10.459972986000119],
              [107.13723333300004, 10.4598707700001],
              [107.13642067400012, 10.46006767900005],
              [107.13591034500008, 10.460766460000047],
              [107.13580296800008, 10.462167778],
              [107.13579959900007, 10.462968764000012],
              [107.13551486100005, 10.464319222000105],
              [107.134460136, 10.467769313000106],
              [107.13242248300007, 10.469663307000053],
              [107.13029051000007, 10.469854726000081],
              [107.12745199000003, 10.469142052000089],
              [107.12593572300008, 10.467733962000112],
              [107.12482540700005, 10.466327559000097],
              [107.12482967200005, 10.465326309000121],
              [107.12523985400006, 10.464326762000018],
              [107.12595787100004, 10.462527481000093],
              [107.12585937800006, 10.461826194000066],
              [107.12494905600006, 10.461121513000114],
              [107.12393385200008, 10.461217398000054],
              [107.12179640500007, 10.462710352000036],
              [107.12016674700006, 10.464105287000079],
              [107.118939122, 10.466403038000037],
              [107.11669277200012, 10.469597653000038],
              [107.11536747700006, 10.470993848000097],
              [107.11434099900005, 10.473692962000046],
              [107.11422828700005, 10.476295810000112],
              [107.11422181300004, 10.477797704000034],
              [107.11370834100011, 10.479197334000022],
              [107.11157581800005, 10.479488697000059],
              [107.11114328600006, 10.479498394000062],
              [107.10782037800001, 10.479572891000098],
              [107.104779256, 10.478758903000072],
              [107.10295643500008, 10.477849961000095],
              [107.10133791600002, 10.476641486000025],
              [107.10062357800007, 10.476032079000051],
              [107.10042804500006, 10.475865267000104],
              [107.09921463000011, 10.474830077000073],
              [107.09840934500009, 10.47332470300004],
              [107.09699209300007, 10.47251758300005],
              [107.096163668, 10.472047909000091],
              [107.09526951800009, 10.471909396000088],
              [107.09303468300001, 10.472400375000033],
              [107.090089005, 10.472988381000013],
              [107.08803881300001, 10.473180205000075],
              [107.08684153300007, 10.472974268000089],
              [107.086540203, 10.472272067000075],
              [107.08684731800001, 10.471672640000039],
              [107.08888274700011, 10.470379833],
              [107.09173044500001, 10.468990404000065],
              [107.09518302200004, 10.468504690000112],
              [107.09842999400011, 10.46861880500005],
              [107.10015429600006, 10.468826457000089],
              [107.10116779600006, 10.469131183000078],
              [107.10247918900004, 10.470939093000096],
              [107.1042962720001, 10.473149667000124],
              [107.10672186700006, 10.475462950000034],
              [107.10793707600007, 10.476068889000077],
              [107.10895149200007, 10.476173335000015],
              [107.10976554200002, 10.475676149],
              [107.11027644000009, 10.474877292000071],
              [107.11038225500006, 10.47387645800004],
              [107.11018882700004, 10.471672829000054],
              [107.10999280000007, 10.470069967000127],
              [107.11009862300004, 10.469069142000096],
              [107.110611228, 10.467869796000072],
              [107.11157834400008, 10.467172931000055],
              [107.112596178, 10.466476358000063],
              [107.11422590600003, 10.465081476000114],
              [107.11473676000001, 10.464282621000097],
              [107.1148429790001, 10.463181681000043],
              [107.11413997100001, 10.461476574000093],
              [107.11293347600011, 10.458868203000103],
              [107.11233456400004, 10.456562792000044],
              [107.11234191700002, 10.454860701000078],
              [107.11235273000003, 10.452357634000096],
              [107.11307297900002, 10.450057828000057],
              [107.11470516200006, 10.448062250000055],
              [107.1159292790001, 10.44656555500003],
              [107.11593186300004, 10.445964827000063],
              [107.11552899900002, 10.44526227000002],
              [107.11461620600008, 10.445158290000032],
              [107.11197972200007, 10.444746655000079],
              [107.10639845500003, 10.444823068000096],
              [107.10436949800003, 10.444714293000066],
              [107.10284963600004, 10.444207180000118],
              [107.10204269400006, 10.4431023740001],
              [107.10113648700005, 10.441496535000015],
              [107.10083733600007, 10.440293776000063],
              [107.10084566000006, 10.438391487000052],
              [107.10075164600009, 10.436689011000054],
              [107.10014592200007, 10.435985565000026],
              [107.09597100700005, 10.434585662000105],
              [107.09077468800007, 10.433244735000089],
              [107.08727546100009, 10.432282118000023],
              [107.08351707500006, 10.431523595000057],
              [107.08093813800011, 10.4315123090001],
              [107.07846007400008, 10.432010388000011],
              [107.07448738800008, 10.433170617000085],
              [107.07184435500007, 10.434260272000094],
              [107.06807609500004, 10.437347334000078],
              [107.06217097100003, 10.441726397000116],
              [107.05560787800002, 10.445701865000066],
              [107.05265555100009, 10.447791149000095],
              [107.04929126399999, 10.451180139000103],
              [107.04755230200008, 10.454175987],
              [107.04110709800007, 10.465360682000034],
              [107.04079612000001, 10.466761043000082],
              [107.04160329299999, 10.467765996000098],
              [107.04342482000007, 10.468875709000079],
              [107.04504107600005, 10.470485113000015],
              [107.04635059000003, 10.472593745000045],
              [107.04713680400008, 10.474810683000067],
              [107.04718620600008, 10.477017425000044],
              [107.0468460490001, 10.477657452000065],
              [107.046447765, 10.478300481000067],
              [107.04609295700004, 10.478900673000078],
              [107.04601219000001, 10.479901624000119],
              [107.04611864700003, 10.480308306000017],
              [107.04654497400004, 10.481853166000031],
              [107.04893120200003, 10.485121607000012],
              [107.05034650000007, 10.48632957400004],
              [107.05166402600003, 10.4867360600001],
              [107.05341358500002, 10.486994253000057],
              [107.05426461600008, 10.486796284000109],
              [107.05507607700008, 10.486900068000081],
              [107.05549321900004, 10.487203890000083],
              [107.05564338100004, 10.487655146000028],
              [107.05580648200002, 10.487940768000035],
              [107.05552741300002, 10.489087522000061],
              [107.05558903000006, 10.490573465000084],
              [107.05622873800006, 10.492872375000049],
              [107.05649509200006, 10.494494309000045],
              [107.05618911800006, 10.494622054000045],
              [107.05553492600004, 10.494895179000084],
              [107.05416841100003, 10.494348770000082],
              [107.05252935100003, 10.493531],
              [107.04693965800001, 10.488508421000107],
              [107.04367113100002, 10.484846923000111],
              [107.04191607300004, 10.479571611000081],
              [107.04029231900006, 10.475512438000044],
              [107.03980004000002, 10.475042008000022],
              [107.03920038000004, 10.474832156000067],
              [107.03804152900008, 10.474857431000116],
              [107.03440299200003, 10.476160701000124],
              [107.02850659400004, 10.478294405],
              [107.02752918500008, 10.478728814000069],
              [107.02664212600004, 10.47815070300002],
              [107.02547544400007, 10.475052463000088],
              [107.025074391, 10.47302473500009],
              [107.02484391700003, 10.471254413000073],
              [107.02388958100003, 10.470439630000071],
              [107.02197063700007, 10.470970923000065],
              [107.01965201500006, 10.472783367000103],
              [107.01831902900008, 10.474391431000106],
              [106.99246503000009, 10.472732898000102],
              [106.99591674200005, 10.500000205000058],
              [106.99612139000001, 10.501616656000044],
              [106.99643071100003, 10.503607182000072],
              [106.99715443600005, 10.505477912000048],
              [106.99799565800002, 10.507172073000069],
              [106.99912851600006, 10.508102574000054],
              [107.00000001500004, 10.508585538000135],
              [107.00035069300002, 10.508722219000076],
              [107.00210022500008, 10.509703216000064],
              [107.00336929000011, 10.510749238000104],
              [107.00548628900003, 10.512781930000068],
              [107.00748470600008, 10.515063353000096],
              [107.00912448800005, 10.517657053000027],
              [107.01066497900005, 10.52347544300005],
              [107.01103126900009, 10.524341144000092],
              [107.01145401000007, 10.525016540000108],
              [107.01248357400007, 10.525819960000058],
              [107.01435748100005, 10.527420098000027],
              [107.01895578700001, 10.531978933000106],
              [107.02210100200001, 10.534934920000081],
              [107.02434257000003, 10.537586639000017],
              [107.02538070300004, 10.54006004100003],
              [107.02551066800004, 10.541476691000094],
              [107.02549820100008, 10.541731917000073],
              [107.02540421600003, 10.543655959000107],
              [107.02517024800004, 10.544649483000066],
              [107.02457478500006, 10.545521306000081],
              [107.02319700700011, 10.546274139000021],
              [107.02073838200008, 10.547219880000023],
              [107.01629715500005, 10.548364251000029],
              [107.01389940700007, 10.549433566000042],
              [107.01180463100012, 10.550872938000031],
              [107.00965816000007, 10.553614666000042],
              [107.00924781300004, 10.555229312000057],
              [107.00926030100001, 10.557151288000098],
              [107.00938577000008, 10.557956508000109],
              [107.00963470000008, 10.559256960000061],
              [107.0100663960001, 10.560928257000056],
              [107.01079870300002, 10.562597642000069],
              [107.01237913800004, 10.565253632000092],
              [107.01819909900011, 10.572656628000042],
              [107.02269508000005, 10.579819971000058],
              [107.023669216, 10.581673748000052],
              [107.02440490400004, 10.583839059000065],
              [107.02465596800002, 10.585449477000084],
              [107.02478314100007, 10.586502676000096],
              [107.02478924600004, 10.587432647000037],
              [107.0247352330001, 10.5883630170001],
              [107.02420948000012, 10.592643483000062],
              [107.02186220200007, 10.597408787000083],
              [107.01744137300003, 10.604129711000086],
              [107.01528307800007, 10.607081152000129],
              [107.01210768800006, 10.609776072000086],
              [107.01098832700008, 10.610726035000082],
              [107.00655018200004, 10.613774035000032],
              [107.00220605500004, 10.616976826],
              [106.99874117000007, 10.619683453000038],
              [106.99618887700009, 10.62398289700006],
              [106.9952495380001, 10.627836208000117],
              [106.99545968500007, 10.631921971000045],
              [106.99797665100004, 10.634884126000086],
              [107.00325306800008, 10.638313257000041],
              [107.00694066300011, 10.637196067000064],
              [107.00971425500009, 10.634712906000042],
              [107.01294259100004, 10.633366521000108],
              [107.01524058100001, 10.634058118000043],
              [107.01476617700011, 10.63700602300006],
              [107.01219763100003, 10.644709729000022],
              [107.01593512500001, 10.6462864970001],
              [107.02253334900008, 10.649069925],
              [107.03447415100005, 10.654798667000074],
              [107.03883531900004, 10.65731502800009],
              [107.04412249300007, 10.658700819000087],
              [107.04987530699999, 10.658953887000065],
              [107.05540007000006, 10.658751881000049],
              [107.06093001100002, 10.657415054000106],
              [107.06554443300008, 10.654939308000076],
              [107.07107009800004, 10.654509960000079],
              [107.0846499580001, 10.654342694000094],
              [107.09385995700008, 10.653474906000058],
              [107.09569315900006, 10.655298325000027],
              [107.09843903000007, 10.658941178000079],
              [107.10210364500004, 10.663041841000085],
              [107.10600351800005, 10.666008746000076],
              [107.11173894200004, 10.670345035000127],
              [107.11678799000001, 10.673770515000045],
              [107.12894185300011, 10.684487854000045],
              [107.13123600400004, 10.686312960000064],
              [107.13304299200007, 10.694490692000079],
              [107.13554265200004, 10.702217452000083],
              [107.13712450800008, 10.70925962400011],
              [107.13726870700003, 10.716909615000068],
              [107.13732174700007, 10.719723356000086],
              [107.137443288, 10.729473547000088],
              [107.13705067700002, 10.737603583000064],
              [107.13655479200003, 10.74228366300005],
              [107.13568097900003, 10.744452138000058],
              [107.13483453100007, 10.746999806000089],
              [107.14030785200005, 10.746515677000088],
              [107.15180578600007, 10.745494296000045],
              [107.16565174300011, 10.755103654000029],
              [107.17168138500003, 10.760239411000059],
              [107.17947786600003, 10.768441190000109],
              [107.18430018600006, 10.77186466600004],
              [107.18659850400006, 10.773008485000089],
              [107.19604012900002, 10.773044851000078],
              [107.19972023500006, 10.77419381500002],
              [107.20477771200009, 10.776482835000055],
              [107.20504653600004, 10.761682906000123],
              [107.20507040900006, 10.760368489000081],
              [107.21152317700007, 10.759030964000081],
              [107.21619364600006, 10.758680258000107],
              [107.21728160400002, 10.758598562000097],
              [107.22119615200009, 10.758613120000058],
              [107.22372566200004, 10.759530396000104],
              [107.22533068700005, 10.76135212600011],
              [107.22601379200009, 10.763397424000052],
              [107.22658659300005, 10.764728448000048],
              [107.22669863000009, 10.764988788],
              [107.22767764200009, 10.764979862000066],
              [107.22795620300005, 10.764993416000033],
              [107.22922620500005, 10.765054972000064],
              [107.23430446200003, 10.763200853000042],
              [107.23776276100008, 10.762078553],
              [107.2421437830001, 10.760505590000056],
              [107.26011954800001, 10.756484160000033],
              [107.29857695900009, 10.755708285000052],
              [107.29882195200001, 10.75139662],
              [107.29999647200003, 10.744591411000036],
              [107.30233215500002, 10.734839567000074],
              [107.30350415300009, 10.728715397000064],
              [107.30447353600009, 10.714420022000047],
              [107.30080550000008, 10.709868662000053],
              [107.29943893600009, 10.70555192300011],
              [107.29944822900005, 10.702828488000103],
              [107.29742764400002, 10.687842854000083],
              [107.30066496600008, 10.683541637000092],
              [107.30459269300009, 10.67924267900011],
              [107.30990663800003, 10.673359628000066],
              [107.31267630100004, 10.671099273],
              [107.31544136300003, 10.670200534000072],
              [107.31831709800011, 10.669664013000089],
              [107.31872961200006, 10.67002004900006],
              [107.32312362800008, 10.673812309000128],
              [107.32877722600006, 10.677865769000048],
              [107.33443810100002, 10.67974625700012],
              [107.34105055300002, 10.679456712000055],
              [107.35025245300004, 10.67902023900009],
              [107.34902343900005, 10.68215995900008],
              [107.34731608, 10.688256886000056],
              [107.34673105900001, 10.69528195],
              [107.3461471750001, 10.70193724800008],
              [107.34613598600006, 10.705450724000086],
              [107.34725141400007, 10.708597878000068],
              [107.35117704100007, 10.712863285000116],
              [107.3536109090001, 10.714350183000041],
              [107.35548206300011, 10.715835339000089],
              [107.35753620000007, 10.718800433000107],
              [107.35808855300003, 10.723760496000077],
              [107.35829006700001, 10.725065593000059],
              [107.35909949100001, 10.728791273000036],
              [107.36134140700005, 10.731756969000104],
              [107.36567324700009, 10.734027583000037],
              [107.37255880700005, 10.737081527000035],
              [107.37487046500009, 10.738776504000034],
              [107.37687784800008, 10.741165643],
              [107.37762860900003, 10.74290173300003],
              [107.37827684300002, 10.747481087000031],
              [107.38037440000002, 10.75080807200006],
              [107.38313692700007, 10.753413322000059],
              [107.39202443800011, 10.759772974000013],
              [107.39410095500006, 10.762332888000079],
              [107.39552530900004, 10.764091634000026],
              [107.39704439100005, 10.766066140000062],
              [107.39711666300008, 10.766311560000117],
              [107.39741694600005, 10.766585450000042],
              [107.40046549600005, 10.770996215000126],
              [107.40347246900006, 10.773240648000113],
              [107.40704668600004, 10.774741350000026],
              [107.40911849000004, 10.774747191000065],
              [107.4123235480001, 10.77363824900006],
              [107.41609202600007, 10.773089800000067],
              [107.41778764500003, 10.77290818500005],
              [107.41872777600003, 10.773469753000063],
              [107.42248260400001, 10.777765549000049],
              [107.43148459500001, 10.791764525000056],
              [107.435178261, 10.797650161000137],
              [107.43917938400004, 10.802033301000067],
              [107.44199784500009, 10.804649447000079],
              [107.44425630200008, 10.805400760000071],
              [107.44727028000005, 10.805408715000018],
              [107.45012225500005, 10.804946231000121],
              [107.45226960700009, 10.802696506000052],
              [107.47813099100006, 10.776886134000028],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 3, code: "AD01", ten_tinh: "Bắc Giang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.16542412100004, 21.620223499000076],
              [106.16924689100006, 21.615698135000017],
              [106.17089778100005, 21.612420612000079],
              [106.17362134200008, 21.609911377000032],
              [106.179046881, 21.607171117000057],
              [106.18304070100005, 21.605302993000031],
              [106.18034172500003, 21.603165132000065],
              [106.17642831900005, 21.60060674800004],
              [106.17436530100008, 21.599618147000044],
              [106.170026636, 21.598222111],
              [106.16858165399999, 21.59762721000007],
              [106.16755109900008, 21.597035712000064],
              [106.16714400800007, 21.596255029000041],
              [106.16715134600004, 21.595477766000037],
              [106.16778581200005, 21.594122683000094],
              [106.16905288500008, 21.59160682700012],
              [106.16906754500005, 21.590052287000013],
              [106.16888050700003, 21.587913085000118],
              [106.16624744900004, 21.581478300000079],
              [106.16543887300001, 21.579333916000017],
              [106.16546457700011, 21.576613412000079],
              [106.16693324300012, 21.574682172000067],
              [106.16778033200004, 21.57274577500008],
              [106.16780050200002, 21.570608214000053],
              [106.16702863200001, 21.564577271000118],
              [106.16602945800003, 21.560682172000064],
              [106.16610653600002, 21.552520332000121],
              [106.16515885100003, 21.543183807000062],
              [106.16580784200008, 21.540273928000083],
              [106.16728906600007, 21.53698220000004],
              [106.16793067200004, 21.534849624000074],
              [106.16794900300005, 21.532906251000036],
              [106.16754755500006, 21.531542460000082],
              [106.16653936900003, 21.528618825000123],
              [106.16615260800009, 21.525700298000046],
              [106.16581725300006, 21.51734017200004],
              [106.16625902100002, 21.514428443],
              [106.16710035400008, 21.513074874],
              [106.16960228700006, 21.511346289000066],
              [106.17417535900007, 21.509634728000023],
              [106.177696365, 21.509663680000109],
              [106.18142630300005, 21.50949992000006],
              [106.18558136800004, 21.508173420000084],
              [106.18932577200005, 21.506454759000079],
              [106.19161850600004, 21.504918561000075],
              [106.19391481700006, 21.502993642000057],
              [106.19599855600006, 21.501650049000084],
              [106.19974094200009, 21.500125539000067],
              [106.20514010800008, 21.498614375],
              [106.21157293400005, 21.497305784000083],
              [106.21965002500008, 21.497370759000134],
              [106.22462766100008, 21.496633193000036],
              [106.22835908100009, 21.496274292000095],
              [106.23229405800006, 21.496305686000113],
              [106.23498817500004, 21.49613277600011],
              [106.23623785900006, 21.495365254000106],
              [106.23696003400003, 21.493721118000053],
              [106.23763554000007, 21.491392911000062],
              [106.23844189100008, 21.489065722000063],
              [106.239381307, 21.486493941000084],
              [106.24110364000005, 21.484173992000066],
              [106.24202521800001, 21.483567196000038],
              [106.24254865700006, 21.483571344000026],
              [106.24359221300007, 21.483948076000026],
              [106.2555927920001, 21.488341529000095],
              [106.25702788000009, 21.488844091000111],
              [106.25780535500003, 21.489709939000079],
              [106.2589644280001, 21.491806987000039],
              [106.260396251, 21.492677966000073],
              [106.26209531700007, 21.49293691800009],
              [106.27084808200006, 21.494724799000053],
              [106.27202480200008, 21.49485679400005],
              [106.27386241600007, 21.494257003000072],
              [106.27728020800006, 21.492564097000063],
              [106.27898799900005, 21.491840436000054],
              [106.28108511500002, 21.491488238000045],
              [106.28317571100007, 21.491872889000071],
              [106.28474176900005, 21.492376290000067],
              [106.28591956800008, 21.492385395000021],
              [106.28605259800007, 21.492140780000035],
              [106.28618780500007, 21.491650549000063],
              [106.28593473700009, 21.49066603000005],
              [106.28387669200006, 21.48659703],
              [106.28258978000008, 21.484130650000033],
              [106.28207937000003, 21.482652828000056],
              [106.28144027500009, 21.480928374000108],
              [106.280557958, 21.477114026000073],
              [106.28018821800006, 21.474531868000035],
              [106.28045536400005, 21.473919818000105],
              [106.28098203700002, 21.473555429000065],
              [106.2824225090001, 21.473443769000063],
              [106.28321197200005, 21.472958579000064],
              [106.2838760050001, 21.471858302000065],
              [106.28455086400007, 21.469529860000058],
              [106.28456821200008, 21.467564789000093],
              [106.28392915400005, 21.465840286000045],
              [106.28239153700011, 21.462143603000072],
              [106.2816336110001, 21.45906705700007],
              [106.28113413799998, 21.456360986000078],
              [106.28091509200007, 21.453965624000126],
              [106.28228357700006, 21.454571446000067],
              [106.28463747700005, 21.456097628000045],
              [106.28623540300001, 21.457062381000114],
              [106.28724923100005, 21.457149572000041],
              [106.28795085600009, 21.456993547000103],
              [106.28860962300007, 21.456287054000086],
              [106.28895338800001, 21.455654771000084],
              [106.2889255860001, 21.454855062],
              [106.28776924300008, 21.453088769000047],
              [106.28676464900011, 21.451638222000021],
              [106.28666225400006, 21.450923139000011],
              [106.28727483000003, 21.449879252000059],
              [106.28916563800007, 21.447958845000059],
              [106.29203861000011, 21.44599937600011],
              [106.2936744830001, 21.445133752000075],
              [106.29660093300004, 21.44414607100007],
              [106.29993069300011, 21.44373184300008],
              [106.30243056900009, 21.443750995000066],
              [106.30372109900003, 21.444200655000053],
              [106.30421377100002, 21.4448141270001],
              [106.30398910900009, 21.445697939000041],
              [106.30247629500006, 21.447269554000044],
              [106.30204859800003, 21.448428469000099],
              [106.3023639840001, 21.449655382000095],
              [106.30452538100005, 21.451100504000031],
              [106.308097194, 21.452862478000064],
              [106.30918414700005, 21.452870768000039],
              [106.31016861700006, 21.452163983],
              [106.31104615100001, 21.451252306000065],
              [106.31271288700007, 21.447081290000099],
              [106.31370351200006, 21.445660240000031],
              [106.31414004900003, 21.445459467000113],
              [106.3149008840001, 21.445465250000062],
              [106.31544168500008, 21.44577549200006],
              [106.31627138700007, 21.447403261000098],
              [106.31694392600012, 21.448031822000054],
              [106.3179195120001, 21.448345357000065],
              [106.32193497400007, 21.449090066000039],
              [106.32353740100005, 21.449439238000068],
              [106.32440068799998, 21.44931796500007],
              [106.32642845300008, 21.448353458000021],
              [106.32683583100004, 21.448004642000029],
              [106.32727850800006, 21.44708960500008],
              [106.32707868400006, 21.445047264000024],
              [106.32698229300007, 21.443617938000067],
              [106.32718186700006, 21.442834995000034],
              [106.32728003000001, 21.442290221000079],
              [106.32752718800003, 21.43959224500005],
              [106.32863328500008, 21.438182434000019],
              [106.32985915200007, 21.437521987000082],
              [106.33042204300001, 21.436669672000086],
              [106.33037879000008, 21.435642679000111],
              [106.3299185540001, 21.434661436000013],
              [106.32909003200002, 21.434117413000116],
              [106.32794699400004, 21.433815455000094],
              [106.32716676600006, 21.433711795000043],
              [106.32680521300007, 21.433366843],
              [106.32629966500008, 21.431603013000085],
              [106.32610106500007, 21.430477063000097],
              [106.32605825700008, 21.429401169000073],
              [106.32617081800008, 21.428424231000065],
              [106.32617460600004, 21.427984257000077],
              [106.32612632600004, 21.427543881000055],
              [106.32488525300002, 21.420494360000042],
              [106.32469004400004, 21.418977286000107],
              [106.32453974100012, 21.418291684000046],
              [106.32407745900007, 21.417554830000093],
              [106.32309958300007, 21.416227393000042],
              [106.3223270230001, 21.415243736],
              [106.32207217700005, 21.414606230000018],
              [106.32207850700004, 21.413872913000098],
              [106.32235444800006, 21.412066041000074],
              [106.32262321200008, 21.411090248000072],
              [106.32511941300004, 21.408382839000062],
              [106.32878464300008, 21.405628606000079],
              [106.33025249800002, 21.4044662700001],
              [106.33685512300009, 21.400125653000046],
              [106.3399734920001, 21.399210146000058],
              [106.34375832400008, 21.398299575000031],
              [106.34515896700006, 21.397922317000081],
              [106.34865749900007, 21.396979962],
              [106.35332648500003, 21.396597415000059],
              [106.35787820400006, 21.396944138000059],
              [106.36077193900003, 21.397710414000045],
              [106.36408577900004, 21.399886143000032],
              [106.36553693800002, 21.400679124000099],
              [106.36679628600004, 21.399515],
              [106.3678490230001, 21.398153776000051],
              [106.36785887300003, 21.396980438000043],
              [106.36890665700004, 21.396205869000084],
              [106.37120553900009, 21.395244929000064],
              [106.37475221300009, 21.394488688000109],
              [106.37724949400004, 21.39470254600004],
              [106.38264428200009, 21.397088812000042],
              [106.38679134200004, 21.39927028000006],
              [106.38885599300006, 21.401436536000062],
              [106.3905041830001, 21.40359974900008],
              [106.39194738900005, 21.405370312000024],
              [106.39360535200009, 21.406360167000038],
              [106.39589131300005, 21.406963412000032],
              [106.39880690300006, 21.406984490000092],
              [106.40089268200006, 21.406608413000058],
              [106.40277501600012, 21.405644159000033],
              [106.40527568000007, 21.405466595000085],
              [106.40777312600004, 21.405680114000077],
              [106.41068230200005, 21.406483258],
              [106.41295715200006, 21.4084552],
              [106.41398567300007, 21.410027077000038],
              [106.41438783800004, 21.411790021000066],
              [106.41437029200002, 21.413941095000055],
              [106.41393142500003, 21.416675826000109],
              [106.41246078500004, 21.418229793000073],
              [106.41036850700002, 21.419388166000083],
              [106.40862252100007, 21.420605422000079],
              [106.40634061800009, 21.422438800000073],
              [106.40484665300002, 21.424750306000107],
              [106.40330717100009, 21.427902144000122],
              [106.40353111400006, 21.429111435000081],
              [106.40507192300001, 21.430301418000042],
              [106.40797823400004, 21.431495654000088],
              [106.41067631599999, 21.432688348000092],
              [106.41233140300007, 21.43406909800003],
              [106.4137734390001, 21.436034967000062],
              [106.41605025900002, 21.437811238000037],
              [106.41978515700005, 21.439597868000071],
              [106.42269489100002, 21.440400805000067],
              [106.42445310700001, 21.440720586000062],
              [106.43457351799999, 21.443743275000045],
              [106.43599906200009, 21.444155750000071],
              [106.43699921300006, 21.444162803000033],
              [106.43800475400003, 21.443499202000012],
              [106.43930250200003, 21.442032837000014],
              [106.44046596100002, 21.439492417000046],
              [106.44133611800007, 21.437888876000066],
              [106.44234161200005, 21.437225257000044],
              [106.44334280100001, 21.437098142000096],
              [106.44620454800001, 21.436581667],
              [106.44734753600008, 21.436589673000036],
              [106.44848623200006, 21.437134193000063],
              [106.44947350600003, 21.438750760000055],
              [106.45003751900008, 21.439693668000089],
              [106.45132125600006, 21.439970911000025],
              [106.45346545200002, 21.439851740000023],
              [106.4563272230001, 21.439335127000092],
              [106.45767467000003, 21.438751267],
              [106.45876460000007, 21.438278981000011],
              [106.46033940800007, 21.437887512000131],
              [106.46319687900007, 21.437907335000084],
              [106.46476425900008, 21.43845474900008],
              [106.4666152770001, 21.439272377000052],
              [106.48025679600002, 21.448889981],
              [106.48068229600005, 21.44929530800006],
              [106.48153015700005, 21.450508329000058],
              [106.48234136900003, 21.456415779000082],
              [106.48610739100005, 21.468245135000075],
              [106.48694701400007, 21.470531078000121],
              [106.48807140900001, 21.472953079000057],
              [106.49200183200006, 21.482100566000014],
              [106.49256512700003, 21.483177411000028],
              [106.49355924100004, 21.483988928000116],
              [106.49498520600008, 21.484400986000104],
              [106.49826691600001, 21.48509386500006],
              [106.50126385900009, 21.485650637000028],
              [106.50496918100006, 21.487016902],
              [106.50781913900006, 21.488109100000052],
              [106.50952378100006, 21.489461823000063],
              [106.51435100700004, 21.49365206800011],
              [106.51661921800005, 21.49608147700004],
              [106.51888134100008, 21.49931555200002],
              [106.51901608500003, 21.500389434000056],
              [106.51886702600005, 21.501193176000029],
              [106.51540003700008, 21.505998382000065],
              [106.51278838900008, 21.511077477000029],
              [106.5110485750001, 21.514284669000091],
              [106.50916886500009, 21.517088538000095],
              [106.50656926800012, 21.520558125000022],
              [106.505842219, 21.522162645],
              [106.505545, 21.523635929000037],
              [106.50567761300006, 21.524977989000057],
              [106.50780108800008, 21.527674613000066],
              [106.51049534500007, 21.530509141000032],
              [106.51262716300006, 21.53213282800008],
              [106.51376557500002, 21.532811026000061],
              [106.51505104400006, 21.532953746000061],
              [106.51676227500008, 21.533501656000098],
              [106.51775674800002, 21.53431299099999],
              [106.5196017470001, 21.536068787000048],
              [106.520247813, 21.537225063000065],
              [106.520729993, 21.538088018000053],
              [106.52185929200002, 21.539973132000064],
              [106.52284158000002, 21.542393699000058],
              [106.52410368000008, 21.545620785],
              [106.52451827600001, 21.547501086000054],
              [106.52549859900003, 21.550189823000032],
              [106.52562934200002, 21.551800010000122],
              [106.52562323300005, 21.552604632000083],
              [106.52504324800002, 21.553673655000068],
              [106.52460622000009, 21.554743626000047],
              [106.52459908900003, 21.555682335000093],
              [106.52515667600008, 21.557563569000045],
              [106.52684066600006, 21.561732099000075],
              [106.52811413900008, 21.563483939000072],
              [106.52924873700007, 21.564698424000106],
              [106.52981857900006, 21.564970420000051],
              [106.53096231600003, 21.564977994000053],
              [106.53268096700003, 21.564587038000035],
              [106.53396666, 21.564729641000021],
              [106.53567317700006, 21.565947867000098],
              [106.53794350000007, 21.568242641000076],
              [106.54006889400006, 21.570804646000049],
              [106.54034677000004, 21.571879316000064],
              [106.54728823000009, 21.580507516000047],
              [106.54927491500007, 21.582532043000043],
              [106.55083168500009, 21.584687847000069],
              [106.55096666400007, 21.585761543],
              [106.55066768800002, 21.587502912000019],
              [106.55022369700006, 21.589511528000038],
              [106.55050265700004, 21.59045205500005],
              [106.55135058000003, 21.591798592000089],
              [106.55362438700006, 21.593690821000067],
              [106.55547526300008, 21.594775670000033],
              [106.57000097100006, 21.602915618000026],
              [106.57142895800008, 21.603193004000062],
              [106.57386185600004, 21.602940456000042],
              [106.58045340000007, 21.601105339000078],
              [106.58475203100002, 21.599925907000014],
              [106.5865993190001, 21.599941073000117],
              [106.59104375500004, 21.599965932000131],
              [106.59432776100007, 21.60065724400004],
              [106.59946587700003, 21.602030695000082],
              [106.60617125500008, 21.604218453],
              [106.61058592600004, 21.606793959000115],
              [106.61300637300012, 21.608284156000046],
              [106.61429050000005, 21.608694460000066],
              [106.6165785060001, 21.60870873],
              [106.61872637099999, 21.608319821000066],
              [106.62159303100003, 21.60739896700008],
              [106.62259878600003, 21.606734726000099],
              [106.62303253800005, 21.606066933000086],
              [106.62304014600005, 21.604994209000061],
              [106.62333184300012, 21.604191445000076],
              [106.62405158600006, 21.603525419000036],
              [106.62605543100004, 21.603269639000096],
              [106.63163891000008, 21.602365447000068],
              [106.63364273200006, 21.602109598000041],
              [106.63549884800007, 21.602523303000076],
              [106.63820730400008, 21.603746807000057],
              [106.640771855, 21.605103489000044],
              [106.64176815100009, 21.605780072000066],
              [106.64233264500008, 21.606856294000032],
              [106.64332989200008, 21.607398771000064],
              [106.64447294800007, 21.607539857000098],
              [106.6454748730001, 21.607411878000079],
              [106.64906479000004, 21.605288216000048],
              [106.65121627900007, 21.604362622000025],
              [106.65236026200003, 21.604369570000067],
              [106.65393229799999, 21.604513203000053],
              [106.65549598800004, 21.605863641000056],
              [106.65756332000004, 21.60743515000005],
              [106.65948327600009, 21.608301479000055],
              [106.66233773100001, 21.609123273000108],
              [106.66747932300008, 21.610092863000126],
              [106.66876909500004, 21.609698319000081],
              [106.66977561900009, 21.608899778000087],
              [106.67308754200003, 21.605567205000042],
              [106.674237944, 21.604635403000124],
              [106.67610331500006, 21.603707859000032],
              [106.67825100500005, 21.603318371000043],
              [106.68225946100006, 21.60267171400001],
              [106.68541082500003, 21.601885815000088],
              [106.6879910870001, 21.600962408000065],
              [106.69688738500005, 21.596455488000018],
              [106.70004576200009, 21.594596625000051],
              [106.70090908600011, 21.593797084000066],
              [106.70091536700009, 21.592858425000117],
              [106.69950703200001, 21.58963178700013],
              [106.69908975300004, 21.587886044000101],
              [106.69840449600008, 21.583456694000077],
              [106.69772195900001, 21.578625009000071],
              [106.69717523500007, 21.574866914000076],
              [106.69718333300003, 21.573660029000052],
              [106.69776063100002, 21.572858790000012],
              [106.699772153, 21.571395440000082],
              [106.70206868900004, 21.570067838000014],
              [106.70322052200004, 21.568867627000095],
              [106.70466723400001, 21.566328067000114],
              [106.70596825600011, 21.564189961000046],
              [106.70798230300004, 21.562324212000036],
              [106.70941639800003, 21.56166202800005],
              [106.71142326700009, 21.560869046000072],
              [106.7148606480001, 21.559950228000076],
              [106.71658065600003, 21.559289651000043],
              [106.71987679600009, 21.558101722000075],
              [106.72288431900006, 21.557314402000124],
              [106.72417538900004, 21.556651285000058],
              [106.72575942, 21.554916985000091],
              [106.72720484400006, 21.55251132500009],
              [106.72980268000006, 21.548771141000067],
              [106.73196366500008, 21.546235409000047],
              [106.73339846300004, 21.54543893700005],
              [106.73640835200004, 21.544249101000013],
              [106.74084860000008, 21.542933233000028],
              [106.74399701400007, 21.542414636000018],
              [106.74843371200008, 21.54163503400008],
              [106.75057798300004, 21.541647118000107],
              [106.75214959300004, 21.541790082000119],
              [106.75346861100012, 21.542313246000063],
              [106.75457030600005, 21.543278904000118],
              [106.75551742400008, 21.544189627000065],
              [106.75801063100005, 21.550837832000092],
              [106.75906895300004, 21.553363062000038],
              [106.75962164300003, 21.55473551500009],
              [106.75978502600005, 21.555526444000073],
              [106.75961222500004, 21.556210153000066],
              [106.75788631700006, 21.556154209000056],
              [106.75648226500006, 21.556835874000058],
              [106.75581265500008, 21.557866407000056],
              [106.75529080600006, 21.558828808000015],
              [106.75417033300009, 21.561235842000045],
              [106.75380170700005, 21.562756484000097],
              [106.7537337090001, 21.563036983000039],
              [106.75370669500009, 21.56349917900009],
              [106.75373262700002, 21.563960129000037],
              [106.75384144000006, 21.565017870000069],
              [106.75406548400011, 21.56613045800011],
              [106.75429005200004, 21.567161738],
              [106.75454596499999, 21.567813717000028],
              [106.75485983400003, 21.568438906000061],
              [106.75549142000006, 21.569092981],
              [106.75724378300001, 21.570729133000022],
              [106.75938553000003, 21.572489410000095],
              [106.76013411100006, 21.572927284000087],
              [106.76243976600006, 21.573915948],
              [106.76313264000001, 21.57402823300005],
              [106.76394146100004, 21.574086950000023],
              [106.76486759000002, 21.573875261000097],
              [106.76677902900008, 21.573235364000084],
              [106.76799413500008, 21.57302526700002],
              [106.76961313700009, 21.572925826000052],
              [106.77123041300007, 21.573097420000039],
              [106.7727885300001, 21.573485511000086],
              [106.7751661990001, 21.574444530000036],
              [106.77578645500009, 21.574694706000038],
              [106.77953511000001, 21.576016410000065],
              [106.78311007900004, 21.577391296000044],
              [106.78432146000009, 21.57777741000011],
              [106.78487088600008, 21.577726208000044],
              [106.78524996800009, 21.577186175000037],
              [106.78531895100011, 21.575397607000113],
              [106.78559107400007, 21.573474644000079],
              [106.78606023000006, 21.572392993000022],
              [106.78757038600006, 21.571208605000081],
              [106.78966052100006, 21.569702108000044],
              [106.79163367600007, 21.568411788000041],
              [106.79546230100004, 21.566155729000066],
              [106.79650798600005, 21.565294019000092],
              [106.79732315100009, 21.564322629000095],
              [106.797677295, 21.563131887000075],
              [106.7982592610001, 21.562484501000064],
              [106.79999776500006, 21.561734944000044],
              [106.80451434500002, 21.560349805],
              [106.80601762800005, 21.560249473000056],
              [106.80844227800003, 21.560696206000074],
              [106.81051951000003, 21.561249465000017],
              [106.81317205000003, 21.562239481000042],
              [106.81767137499999, 21.56367300800008],
              [106.82009872900001, 21.563685927000087],
              [106.82333586500008, 21.563594708],
              [106.82761459500006, 21.563292080000096],
              [106.8305069020001, 21.562873642000021],
              [106.83259009600008, 21.562450922000039],
              [106.83513952600006, 21.561380080000077],
              [106.83623572300009, 21.561144983000077],
              [106.83745451300003, 21.560850095000106],
              [106.83953571700002, 21.56075256300003],
              [106.84115393300006, 21.560761019000019],
              [106.84219550799999, 21.560549615000113],
              [106.84266107800005, 21.560009918000041],
              [106.84441035600003, 21.557416854000074],
              [106.84616280900006, 21.554281649000103],
              [106.84710227100008, 21.55179275100005],
              [106.84815600900005, 21.549521285000047],
              [106.84827801700001, 21.548437655],
              [106.84828573300004, 21.547136575000088],
              [106.84818173100004, 21.545184353000124],
              [106.848420597, 21.543884473000041],
              [106.84912241700007, 21.542478555000066],
              [106.85028458400004, 21.54140031100011],
              [106.8514435430001, 21.54086417000007],
              [106.85514449300007, 21.540449590000051],
              [106.85676509400005, 21.540024230000121],
              [106.85873558900002, 21.539058521000065],
              [106.86221677900002, 21.536690980000017],
              [106.8639814320001, 21.534909171000024],
              [106.85786255300007, 21.524091160000047],
              [106.85651605400005, 21.523433234000052],
              [106.85593823100002, 21.523430255000072],
              [106.85501051500009, 21.52396762100004],
              [106.85361542100003, 21.525370024000075],
              [106.85222094800012, 21.526663986000116],
              [106.85094971900001, 21.526657406000105],
              [106.85002710900005, 21.526327331000061],
              [106.84934013700006, 21.525239460000108],
              [106.848884298, 21.524152785000084],
              [106.84865960800006, 21.523067323000063],
              [106.84866732100008, 21.521766183000054],
              [106.848906797, 21.520357818000058],
              [106.84891322200008, 21.519273530000049],
              [106.84880151800007, 21.518622352000079],
              [106.84822756700002, 21.517968782000096],
              [106.84661872000004, 21.516442373000096],
              [106.84489241700003, 21.515240629000054],
              [106.84339336500007, 21.514690658000021],
              [106.84212221500006, 21.514684027],
              [106.83981167600011, 21.514563522000074],
              [106.83842691400011, 21.514230973000089],
              [106.83785301100005, 21.513577372000015],
              [106.83728754900007, 21.511514172000098],
              [106.83683635500005, 21.509668433000058],
              [106.83684089900007, 21.508909420000052],
              [106.83719392400005, 21.508002981000018],
              [106.83764370300005, 21.507377539000046],
              [106.83818219100002, 21.507092535000091],
              [106.83920977400004, 21.506548667000033],
              [106.84066557900006, 21.505509933000063],
              [106.84111783800009, 21.504465948000103],
              [106.8421388300001, 21.501541496000073],
              [106.84214444200003, 21.500599806000054],
              [106.84192455300004, 21.500075488000014],
              [106.84115154200003, 21.498815817000057],
              [106.84071365700004, 21.497453262000121],
              [106.84072302200011, 21.495883773000024],
              [106.84150850700009, 21.49505078700011],
              [106.84251448300009, 21.494637484000023],
              [106.84307197100003, 21.494640392000086],
              [106.85454267500002, 21.497001923000077],
              [106.86579859199999, 21.498001486000092],
              [106.86724809000002, 21.498008893000083],
              [106.86959019800001, 21.497916212000057],
              [106.8746119300001, 21.497209289000097],
              [106.87651044600007, 21.496695731000059],
              [106.87829926800002, 21.495867696000026],
              [106.88402711400002, 21.493124103000035],
              [106.88748284600004, 21.491567330000102],
              [106.888066397, 21.491177934000035],
              [106.88822457100012, 21.490969999000065],
              [106.88850289600006, 21.490487901000051],
              [106.88862404000005, 21.490005655000033],
              [106.88851769800004, 21.489492357000103],
              [106.88804828800002, 21.488772147000113],
              [106.88721856400011, 21.487366445000063],
              [106.88685825100005, 21.486680963000104],
              [106.88675289400004, 21.485996749000066],
              [106.88679441400009, 21.485108179000086],
              [106.88690779000007, 21.48439087600007],
              [106.88817738500003, 21.482124001],
              [106.88979639100006, 21.479260630000049],
              [106.8911161780001, 21.477763110000048],
              [106.89243125800002, 21.477085995000067],
              [106.89432830700004, 21.476548507000089],
              [106.89724746800007, 21.47560586600008],
              [106.89885621300004, 21.474519949000026],
              [106.89973652100005, 21.473430406000112],
              [106.900471129, 21.472340134000056],
              [106.90047809300003, 21.471109517000016],
              [106.90004800900007, 21.46987672100007],
              [106.89817818799999, 21.465628486000107],
              [106.89716542200004, 21.464392786000097],
              [106.89673226900008, 21.463706919000103],
              [106.895862865, 21.462882142000069],
              [106.89310124600003, 21.461774440000077],
              [106.89164838699999, 21.461083478000106],
              [106.89092391700009, 21.460396140000078],
              [106.89020335700008, 21.459025109000059],
              [106.88949061100007, 21.456286680000055],
              [106.88935822700005, 21.45396137800013],
              [106.88950936400002, 21.453004922000041],
              [106.89039275000002, 21.45136842000008],
              [106.89435768200008, 21.445781664000037],
              [106.89655505800008, 21.443604670000035],
              [106.89874618500009, 21.442521590000041],
              [106.90108450200003, 21.441165722000029],
              [106.90269448400002, 21.43980622200008],
              [106.90460654400003, 21.436533739000119],
              [106.90783097100011, 21.432994181000083],
              [106.90973368300007, 21.431362554000088],
              [106.91060912600003, 21.43109335800002],
              [106.9113389180001, 21.430823444000069],
              [106.91264979500002, 21.430829877000065],
              [106.91381424900001, 21.430972329000042],
              [106.91526696200003, 21.431663193000091],
              [106.91700260500002, 21.433859663000106],
              [106.92047325100003, 21.438389275000077],
              [106.92235543900007, 21.440449638000111],
              [106.92395088200001, 21.441688114],
              [106.92496899400004, 21.441966546000032],
              [106.925552393, 21.441832622000057],
              [106.92686635600009, 21.441291998000089],
              [106.92833201000003, 21.439658120000082],
              [106.92907537100008, 21.436926767000038],
              [106.92894177400004, 21.434738149000054],
              [106.92822933500007, 21.431862975000016],
              [106.92722031800007, 21.429943603000098],
              [106.92678941000003, 21.42884751800009],
              [106.92679394100006, 21.42802704000006],
              [106.9272323930001, 21.427755661000077],
              [106.92796366700003, 21.427212197000053],
              [106.92941639300005, 21.427902973000101],
              [106.93159137500007, 21.42969120900009],
              [106.93579730600008, 21.432993422000024],
              [106.93825998500009, 21.435466704000113],
              [106.93971356400002, 21.436020652000117],
              [106.94146143900004, 21.436029004000098],
              [106.94364777200011, 21.435765934000095],
              [106.94496238500008, 21.435088456000081],
              [106.94569660400008, 21.433997961],
              [106.94673026000007, 21.431404638000018],
              [106.94806112400005, 21.42771871700004],
              [106.94909176700006, 21.425672344000098],
              [106.95026138600011, 21.424857380000084],
              [106.95157442800004, 21.424453345000046],
              [106.953176554, 21.424460915000054],
              [106.95448665400004, 21.424603844000067],
              [106.95666696900005, 21.425434620000061],
              [106.95913346900002, 21.42722397400005],
              [106.96116160700005, 21.429284732000028],
              [106.96289774800009, 21.431480852000014],
              [106.96549839900005, 21.435458719000074],
              [106.96680204000009, 21.43683227800004],
              [106.96869195000006, 21.437524811000053],
              [106.97291024300003, 21.438638372000085],
              [106.9774206180001, 21.439616455000099],
              [106.98135126500007, 21.440044775000011],
              [106.98382748200005, 21.440056137000063],
              [106.98528550900008, 21.43978932000001],
              [106.98674708600004, 21.438838777000058],
              [106.98864777000009, 21.43747998000007],
              [106.989236089, 21.436388680000015],
              [106.98968656700006, 21.433792521000058],
              [106.99012921700009, 21.432700547000039],
              [106.99042549200001, 21.43174464900008],
              [106.99130225500008, 21.431201643000094],
              [106.99246960400004, 21.430796708000074],
              [106.99378472200004, 21.429982184],
              [106.99685471100003, 21.427808099000067],
              [107.00065638700008, 21.42495350500004],
              [107.00168084300003, 21.424000869000089],
              [107.0019770480001, 21.423044941000043],
              [107.00198336700001, 21.421814212000086],
              [107.00140570200008, 21.42085435400007],
              [107.00009911900007, 21.420027955000087],
              [106.996169541, 21.419463189000076],
              [106.99326161900009, 21.418492742000069],
              [106.992100018, 21.417803698000078],
              [106.99093913500005, 21.416977902000056],
              [106.98919925100006, 21.41546569900008],
              [106.98905788000009, 21.41464453499999],
              [106.98906356900002, 21.413550534000066],
              [106.989654656, 21.411912184000045],
              [106.990242892, 21.410820832000077],
              [106.990247154, 21.410000326000045],
              [106.98996013900005, 21.409178491000077],
              [106.98851374200009, 21.407257329000018],
              [106.9859051510001, 21.40478381500008],
              [106.98488928800006, 21.404095391000055],
              [106.98431175900006, 21.403135450000029],
              [106.98432604900009, 21.40040038700004],
              [106.98478436400009, 21.396299770000034],
              [106.98494855200008, 21.39274481000006],
              [106.98495640600002, 21.391240504000031],
              [106.9855446060001, 21.390149124000054],
              [106.98697485100004, 21.388730359000085],
              [106.98774033100005, 21.387971011000047],
              [106.98891311700001, 21.386472009],
              [106.99082321100005, 21.383198462000053],
              [106.9912714070001, 21.381012324],
              [106.99113998600006, 21.378276491000037],
              [106.99015123300005, 21.372391184000072],
              [106.98930245499999, 21.36760057700009],
              [106.98858154500003, 21.366229640000078],
              [106.98742311200002, 21.364993459000026],
              [106.9858278940001, 21.363755280000071],
              [106.98554382900004, 21.362386322000049],
              [106.98554953700007, 21.361292220000024],
              [106.98628324000002, 21.360201447000101],
              [106.98759862700005, 21.359250090000081],
              [106.99051628500006, 21.35816927400009],
              [106.99459728400004, 21.357367225000075],
              [106.99663917300005, 21.356692639000038],
              [106.99955890100004, 21.355201375000021],
              [107.00789183400011, 21.349794890000062],
              [107.01482445100004, 21.344689430000052],
              [107.01772123900001, 21.340917551000068],
              [107.01975464700003, 21.337141793000036],
              [107.021508393, 21.331742698000063],
              [107.02526537500009, 21.328515121000088],
              [107.02959771700009, 21.325289992000052],
              [107.03104852600003, 21.322863216000073],
              [107.03221157399999, 21.320435163000084],
              [107.03222636900006, 21.317461384000055],
              [107.03080659600001, 21.313670259000084],
              [107.02766598000002, 21.308790135000066],
              [107.02165389100006, 21.30281583100011],
              [107.01735059600007, 21.300363571000069],
              [107.00839726700011, 21.29608109400003],
              [107.00499738900008, 21.294999127000033],
              [107.0027304610001, 21.294348901000056],
              [107.00083935700003, 21.29419814900011],
              [107.00000007500005, 21.294518128000028],
              [106.99894904300004, 21.295861981000108],
              [106.99847760600002, 21.296212902],
              [106.99781968400004, 21.296298189000048],
              [106.99678698900003, 21.296205252000085],
              [106.993505144, 21.295131190000035],
              [106.99153551000011, 21.294592653000066],
              [106.99059673400005, 21.294500114000044],
              [106.99003320000006, 21.294497545000098],
              [106.98890475700003, 21.294757187000087],
              [106.9882463770001, 21.294930707000056],
              [106.9871193070001, 21.294925556000074],
              [106.98505576500007, 21.294386523000064],
              [106.98149595100006, 21.292604898],
              [106.97821981400006, 21.290471446000048],
              [106.97494236300004, 21.288602742000059],
              [106.97195045700002, 21.28602917500011],
              [106.97129675800002, 21.28532001400005],
              [106.97130002500003, 21.284702168000088],
              [106.97177474900005, 21.283733441000052],
              [106.97262698800004, 21.282413403000078],
              [106.97319794900002, 21.281003790000064],
              [106.9733007090001, 21.279327206000076],
              [106.97321704700002, 21.277384946000041],
              [106.97294555000011, 21.275441808000025],
              [106.97314176, 21.273853898000091],
              [106.97390236800005, 21.272092075000067],
              [106.97550816200004, 21.270334147000099],
              [106.97673457500008, 21.269280594000058],
              [106.97824178100009, 21.26840487300008],
              [106.97965043100002, 21.268411367000084],
              [106.98030825700002, 21.268326130000048],
              [106.98096840300011, 21.267799556000085],
              [106.98153878900004, 21.26647815000009],
              [106.98287151500008, 21.263041788000066],
              [106.98354085700005, 21.26074985200006],
              [106.98355468800004, 21.258101823000075],
              [106.98220668100006, 21.255491674000027],
              [106.981847499, 21.254894030000045],
              [106.98071717100001, 21.253013245000126],
              [106.97885301100004, 21.250356528000047],
              [106.97718969099999, 21.245229100000081],
              [106.97589647800002, 21.24116260000006],
              [106.97590855400004, 21.238867569000064],
              [106.97707983700005, 21.230398719000029],
              [106.97690226200011, 21.228455863000036],
              [106.97634356200007, 21.227570538000059],
              [106.97522153000004, 21.226682594000046],
              [106.97259820400006, 21.225611144000119],
              [106.97072134800001, 21.225425870000066],
              [106.96884356900009, 21.225417132000054],
              [106.96790560700001, 21.225236214000098],
              [106.967157306, 21.224703073000036],
              [106.96641463500002, 21.223110657000085],
              [106.96474811900006, 21.218689084000083],
              [106.96233061100006, 21.214263964000054],
              [106.96084260200004, 21.21160867800009],
              [106.95972073200002, 21.210720641000066],
              [106.95784592200008, 21.210182158000102],
              [106.95597491899998, 21.208937457000054],
              [106.95504083900006, 21.208050277000012],
              [106.95448703800007, 21.206282104000081],
              [106.95450505300005, 21.202927620000089],
              [106.95434290200004, 21.198159802000035],
              [106.95437040100009, 21.193039717000062],
              [106.95418739700006, 21.192156056000044],
              [106.95344116000004, 21.191269722000072],
              [106.95213073700006, 21.190557281000011],
              [106.94819096200004, 21.190008911000021],
              [106.94650221500002, 21.189824322000028],
              [106.94556827900003, 21.188937073000069],
              [106.94538818800004, 21.187523726],
              [106.94429524800003, 21.181338824000036],
              [106.94299930300009, 21.177977926000047],
              [106.94113830600004, 21.174967432000088],
              [106.93889517000007, 21.173191031000016],
              [106.934965441, 21.170876790000115],
              [106.93346749900005, 21.170163310000056],
              [106.93234115300002, 21.170157879000051],
              [106.9302703550001, 21.171207284000012],
              [106.92574935100004, 21.174010441000043],
              [106.92103167100005, 21.178401622000109],
              [106.91724854200004, 21.183503494],
              [106.91555201400008, 21.184731129000056],
              [106.91461135400006, 21.185079638000026],
              [106.91310943500004, 21.185072268000035],
              [106.90466713600007, 21.183971282000066],
              [106.89791755000002, 21.182348705000017],
              [106.89173329900009, 21.180375626000014],
              [106.89079462900006, 21.180370924000066],
              [106.88947847700004, 21.180717456000053],
              [106.88608610800003, 21.182995731000084],
              [106.88326194900003, 21.184394002000047],
              [106.88138457000004, 21.184384522000066],
              [106.87932249300005, 21.183844410000049],
              [106.87764099900009, 21.18242337700007],
              [106.87558914900005, 21.180117639000073],
              [106.87465864400006, 21.17870039700005],
              [106.87356554900003, 21.176473314000077],
              [106.87197250700004, 21.175294895],
              [106.86942262399999, 21.175142406000091],
              [106.86209394600007, 21.176340808000035],
              [106.85889942299998, 21.17685405900005],
              [106.85795455700006, 21.177908579000082],
              [106.85719741300002, 21.178964053000101],
              [106.85661244200003, 21.182668878000044],
              [106.85621206700003, 21.186904321],
              [106.855640545, 21.188313861000019],
              [106.85469664400006, 21.189191793000091],
              [106.85300383300006, 21.18971270400003],
              [106.8503754230001, 21.189699069000056],
              [106.84662578700009, 21.188796758000095],
              [106.84381593900004, 21.187722734000047],
              [106.84212732100004, 21.187537339000073],
              [106.83949999400008, 21.187347020000047],
              [106.83536968400001, 21.187325326000028],
              [106.82973320600003, 21.188001845000045],
              [106.82728938200006, 21.188518599000083],
              [106.82635066400012, 21.188513623000027],
              [106.82560076300007, 21.188333087000011],
              [106.82466738500001, 21.187445317000098],
              [106.82430363200002, 21.18550119800009],
              [106.82442302900004, 21.165726477000035],
              [106.82462353800003, 21.163608717000045],
              [106.82595246400003, 21.161143793000022],
              [106.82954241100006, 21.157278256000019],
              [106.83162313700009, 21.154640678000099],
              [106.83162843400008, 21.153757849000066],
              [106.8312572560001, 21.153049600000081],
              [106.83031978000007, 21.152868068000075],
              [106.827311138, 21.153735013000038],
              [106.82072529100007, 21.156348593000104],
              [106.81846847900005, 21.157042840000081],
              [106.81696678000004, 21.157034817000039],
              [106.81284142100003, 21.15630644],
              [106.80965465100006, 21.155583036000088],
              [106.80383563099998, 21.155551659000068],
              [106.80195961100002, 21.155364942000077],
              [106.799528095, 21.153939189000056],
              [106.79578920000009, 21.151446855000074],
              [106.7931667820001, 21.150549706],
              [106.79129192600004, 21.15018632300005],
              [106.78960257600006, 21.150177089000088],
              [106.7875345300001, 21.150695489000078],
              [106.78283526700004, 21.151729135000075],
              [106.77719852900003, 21.15258092100003],
              [106.77025002800004, 21.153072155000082],
              [106.76912154400001, 21.153419022],
              [106.76761541500009, 21.154116928000022],
              [106.76629026300006, 21.155875269000113],
              [106.7649684560001, 21.15710389900007],
              [106.76308909400004, 21.157446541000091],
              [106.75914827200003, 21.157247911000084],
              [106.75558626600009, 21.156521624000035],
              [106.75239518000002, 21.156503657000059],
              [106.74713586500008, 21.157003661000125],
              [106.74112339500007, 21.157852395000099],
              [106.73604255800005, 21.159765718000045],
              [106.73340422500007, 21.161339774000076],
              [106.73190020700007, 21.161684297000072],
              [106.73058619100006, 21.16167676400007],
              [106.72871019200008, 21.161489428000024],
              [106.72439966800003, 21.160405197000017],
              [106.72233597300009, 21.160216724000058],
              [106.72045882600003, 21.160205878000049],
              [106.71801621300007, 21.160544888000032],
              [106.71350523500004, 21.161401561000034],
              [106.71262121000008, 21.161987752000044],
              [106.71086553000004, 21.163151905000085],
              [106.70786307200004, 21.166030679000066],
              [106.70564012300005, 21.168593296],
              [106.70495173600003, 21.169161623000043],
              [106.70449440100002, 21.169302039000023],
              [106.70358162600007, 21.169296694000117],
              [106.70183740000009, 21.168499482000058],
              [106.70016542300004, 21.16827503300005],
              [106.69841595000005, 21.168264751],
              [106.69727116100002, 21.16883037000008],
              [106.69619907900004, 21.169897222000067],
              [106.69528054900007, 21.170750346000133],
              [106.69451798400004, 21.171032026000077],
              [106.69368078900004, 21.171098628],
              [106.69208630900002, 21.170659951000026],
              [106.69003736700003, 21.169932388000042],
              [106.68760860700007, 21.169131011000083],
              [106.68479712900006, 21.168685051000047],
              [106.68122405700009, 21.1683776120001],
              [106.68000703100003, 21.168370356000118],
              [106.67962477600003, 21.168654254000074],
              [106.678781761, 21.169579304000052],
              [106.67816595900003, 21.17064879200008],
              [106.67763010600001, 21.171146401000037],
              [106.67709522100007, 21.171500922000064],
              [106.67587817500001, 21.17149364400008],
              [106.67458555800008, 21.171414365000096],
              [106.67154295400005, 21.171396132],
              [106.66948821500003, 21.17152688200008],
              [106.66613791800003, 21.172007526000058],
              [106.6629367940001, 21.172918278000097],
              [106.65942840900007, 21.174256398000054],
              [106.65744624800003, 21.174888273000114],
              [106.655770316, 21.17523582600009],
              [106.65473431900008, 21.175375544000104],
              [106.65424750700004, 21.175441195000033],
              [106.65074843700008, 21.175419892000043],
              [106.6493016900001, 21.175625701000023],
              [106.64754569300003, 21.176545049000062],
              [106.64383227200008, 21.179992185000081],
              [106.6423059200001, 21.18069826100006],
              [106.63971558499999, 21.181254709000093],
              [106.63865062200007, 21.181248161],
              [106.6371362510001, 21.180237236000018],
              [106.63683898800011, 21.17923379900013],
              [106.63730041000009, 21.178521204000056],
              [106.63837836500002, 21.176667699000085],
              [106.6385435010001, 21.174808578000089],
              [106.63855150500004, 21.173663919000063],
              [106.63825625600003, 21.172374309000119],
              [106.63765674100009, 21.17108282300002],
              [106.636903096, 21.170076560000069],
              [106.63614546000009, 21.169642622000048],
              [106.63568907800008, 21.169639809000081],
              [106.63446301400006, 21.170920057000053],
              [106.63277853600005, 21.172483631000105],
              [106.63140734800001, 21.172761332000078],
              [106.63003716200006, 21.172895947000114],
              [106.62578051700004, 21.172440290000068],
              [106.614522847, 21.172370103000034],
              [106.60920133800006, 21.171907458000035],
              [106.60783216700001, 21.171898850000034],
              [106.60737168600011, 21.172468305000081],
              [106.60721238600007, 21.173468922],
              [106.60641896100006, 21.178042745000077],
              [106.60564701700005, 21.179611839000053],
              [106.60472907000003, 21.180321490000061],
              [106.60366206100004, 21.180600933000093],
              [106.60168325100008, 21.180731522000059],
              [106.59605727000009, 21.180266654000057],
              [106.58967270900003, 21.179510628000031],
              [106.58800688000004, 21.179274964000072],
              [106.58707153200001, 21.179794667000081],
              [106.58650752300007, 21.180491964000048],
              [106.58649734700002, 21.181893693000028],
              [106.58760503300002, 21.183302554000072],
              [106.58853023400009, 21.184184566000042],
              [106.58870891500004, 21.185237049000079],
              [106.58813982300003, 21.186635193000122],
              [106.58663150700002, 21.189078682000059],
              [106.58457057700011, 21.190642501000042],
              [106.58120411500002, 21.192373156000066],
              [106.57896189200004, 21.193234879000045],
              [106.57709870300006, 21.193222910000117],
              [106.57356378100006, 21.192499275000017],
              [106.57003402300003, 21.191074732000104],
              [106.566503036, 21.18982534400002],
              [106.56520140300005, 21.189466471000081],
              [106.56408351500001, 21.189459222000018],
              [106.56333437200001, 21.189980024000121],
              [106.56145438500008, 21.192245686],
              [106.55995476200007, 21.193462488000044],
              [106.55677825000006, 21.194668341000074],
              [106.55342187700009, 21.194996869000079],
              [106.54970199500005, 21.194096423000097],
              [106.54617238200005, 21.192671512000061],
              [106.54449814, 21.19231006299999],
              [106.54226232400001, 21.192295350000023],
              [106.54019836700012, 21.194209175000097],
              [106.53943863500005, 21.196131585000089],
              [106.53848597800001, 21.198928811000073],
              [106.53678009, 21.202772346000032],
              [106.53431165600003, 21.208888605000098],
              [106.53146113400008, 21.216228745000087],
              [106.52976157300006, 21.219196089000022],
              [106.52787560300007, 21.222162166000125],
              [106.52656189900009, 21.223379909000073],
              [106.524687801, 21.224769122000083],
              [106.52300267700011, 21.225809160000075],
              [106.52094883500008, 21.226321080000062],
              [106.51740684800004, 21.226472594000079],
              [106.51517331500006, 21.226107209000041],
              [106.51313015600002, 21.225217439000055],
              [106.51146372500008, 21.223804557000037],
              [106.50960561400008, 21.223091209000074],
              [106.50625270400003, 21.222893398000053],
              [106.50251632300007, 21.224094630000053],
              [106.49952394500009, 21.225476055000044],
              [106.49707975700001, 21.228262853000054],
              [106.49539581900004, 21.229127471000098],
              [106.49315687200006, 21.229462661000028],
              [106.49110291300011, 21.229974301000084],
              [106.48960120100003, 21.231365741000019],
              [106.48920946000004, 21.233815995000072],
              [106.48918906000003, 21.236443982000083],
              [106.48843273200006, 21.237840484000081],
              [106.48731183700005, 21.238183240000104],
              [106.48656639700002, 21.238178146000038],
              [106.48564003900002, 21.237470991000059],
              [106.48546322000004, 21.236243318000092],
              [106.48512050000002, 21.232386392000087],
              [106.48364194000007, 21.230799381000054],
              [106.481412522, 21.229908049000109],
              [106.47545469300007, 21.229166264000106],
              [106.46577125200008, 21.228223329000073],
              [106.46260189300004, 21.228376557000047],
              [106.46073420900005, 21.228889211000102],
              [106.45699466399999, 21.230440068000078],
              [106.45530357700005, 21.232180381000099],
              [106.45398934100011, 21.233397672000095],
              [106.45249709700001, 21.233562450000051],
              [106.44988949499999, 21.233369007000036],
              [106.44486626300004, 21.232282521000023],
              [106.44264261299999, 21.230690009000064],
              [106.44022703000006, 21.229796951000068],
              [106.43706043000006, 21.229599407000066],
              [106.43501054800001, 21.229584928000023],
              [106.43333196400003, 21.229748269000126],
              [106.43126936300008, 21.231310560000068],
              [106.42846556400008, 21.23234193300005],
              [106.42697329800006, 21.232506548000082],
              [106.42473703000005, 21.232490649000013],
              [106.42306267000006, 21.23212830600005],
              [106.42157892600005, 21.231241681000057],
              [106.41954610000008, 21.229124654000131],
              [106.41732981600011, 21.226655870000052],
              [106.41567406000007, 21.224015850000022],
              [106.41457880300004, 21.221204613000033],
              [106.41331581100007, 21.216114365000038],
              [106.41241840200007, 21.21190276100009],
              [106.41187226700001, 21.210321904000025],
              [106.41057936500006, 21.208910891000073],
              [106.4089095300001, 21.208022808000045],
              [106.40425840900011, 21.207113250000106],
              [106.39979366100009, 21.206204931000094],
              [106.39551672100006, 21.205122661000075],
              [106.39254268400001, 21.204224991000054],
              [106.39124997300007, 21.202813859000088],
              [106.38884657199999, 21.200518527000064],
              [106.38588425700003, 21.198219093000056],
              [106.382914736, 21.196795656000056],
              [106.38067888200001, 21.196779311000036],
              [106.37806892500006, 21.196935426000074],
              [106.37639057500012, 21.197098336000053],
              [106.37396839600007, 21.197080556000088],
              [106.37303971300005, 21.196723296000108],
              [106.37230029500007, 21.196016976000109],
              [106.36882920000002, 21.187755985000045],
              [106.368100102, 21.185823150000083],
              [106.36607274300005, 21.183179827000039],
              [106.36440922000007, 21.181590520000078],
              [106.36330316900003, 21.180180539000034],
              [106.36312275600002, 21.17947830100011],
              [106.36332231700001, 21.177902746000044],
              [106.36427149900004, 21.175807041000013],
              [106.36446368800004, 21.17510755200005],
              [106.36409697500008, 21.174403931000093],
              [106.36279581800007, 21.174043859000058],
              [106.35664942500009, 21.173823077000087],
              [106.34677111400005, 21.174275190000102],
              [106.34397513100008, 21.174429518000082],
              [106.34304363900002, 21.174422546000038],
              [106.34248921200006, 21.173892713000072],
              [106.34249667000006, 21.173016627000059],
              [106.34531794700007, 21.169883627],
              [106.34588873300005, 21.168486059000102],
              [106.34589617600004, 21.16760997300009],
              [106.34515547700005, 21.1670787400001],
              [106.34384993400002, 21.167244206000113],
              [106.34178728700007, 21.168805830000089],
              [106.33410575400008, 21.173829770000019],
              [106.33167185800005, 21.175213259000088],
              [106.3299786270001, 21.177127988000066],
              [106.3245170640001, 21.18392051500004],
              [106.32226324500006, 21.186006140000032],
              [106.322069384, 21.186880785000106],
              [106.32206183300009, 21.187756850000113],
              [106.32297075900007, 21.190392089000021],
              [106.32314651100005, 21.191619979],
              [106.32313896600004, 21.192496037000105],
              [106.32221663300002, 21.193519908],
              [106.32143383800009, 21.193427282000066],
              [106.31978653900003, 21.193414784000097],
              [106.31727012500002, 21.193640284000104],
              [106.31457536500008, 21.194435163000058],
              [106.31309013800002, 21.194696763000117],
              [106.31129572500005, 21.194510737000094],
              [106.31020500200005, 21.193329296000037],
              [106.30910824500008, 21.191813261000014],
              [106.30832432100004, 21.189777724000081],
              [106.30754797500003, 21.186872414000035],
              [106.30741878900008, 21.184001040000041],
              [106.30781392000009, 21.181104666000024],
              [106.30996051400007, 21.175322227000045],
              [106.3127164640001, 21.17035621400003],
              [106.31746610300007, 21.162969715000109],
              [106.31934560500005, 21.159504589000022],
              [106.32067371500008, 21.155629320000102],
              [106.32107359100004, 21.152152903000051],
              [106.32110058600006, 21.14902159300005],
              [106.31966615200011, 21.143791467000113],
              [106.31712223700004, 21.1385528590001],
              [106.31310011700005, 21.133186915000032],
              [106.31077491300005, 21.131197419],
              [106.30819615200001, 21.130017856000016],
              [106.30267091000009, 21.127423838000055],
              [106.29862915700006, 21.124377133000038],
              [106.29741229400001, 21.122746178000092],
              [106.29630472200003, 21.124696363000069],
              [106.29530202000002, 21.126341663000048],
              [106.29297618200005, 21.128596633000107],
              [106.28988165000007, 21.130845630000024],
              [106.28536042100009, 21.13298020800007],
              [106.28250083900009, 21.13347459200012],
              [106.279865414, 21.133454120000025],
              [106.27558834200005, 21.132800949000071],
              [106.27383139799998, 21.132787249],
              [106.27218150900008, 21.133084311000069],
              [106.270747575, 21.133796294],
              [106.26963295900006, 21.135647211000027],
              [106.26862078200007, 21.138325405000025],
              [106.26771564800005, 21.141314356000059],
              [106.26637397400006, 21.143989934000018],
              [106.26392949200005, 21.14717339400007],
              [106.26193250200001, 21.149430543000044],
              [106.26060637800006, 21.150349906000088],
              [106.25604462900002, 21.152316320000075],
              [106.25451188100003, 21.154277651000086],
              [106.25390655100003, 21.156794459000047],
              [106.25352643500003, 21.160190092000072],
              [106.25397886500006, 21.161728523000086],
              [106.25582204400007, 21.164154968000034],
              [106.25627548300011, 21.16558376000004],
              [106.25579651300009, 21.167005201000052],
              [106.25342515900007, 21.171481395000043],
              [106.25213222400004, 21.172677129000036],
              [106.25061208000002, 21.17321326900008],
              [106.24862964700004, 21.173307230000127],
              [106.24583625200003, 21.172846578000112],
              [106.24235343400007, 21.171284114000066],
              [106.237712082, 21.168944952000054],
              [106.23435787900003, 21.166067791000032],
              [106.2299757550001, 21.160880060000054],
              [106.2274306460001, 21.1587766600001],
              [106.22464751300004, 21.157219480000045],
              [106.22092804800005, 21.156093277000117],
              [106.21619145300008, 21.155920085000091],
              [106.21323805500006, 21.15581204600003],
              [106.20822358200012, 21.156100411000089],
              [106.20635582000003, 21.156414180000027],
              [106.20587953900005, 21.157506652000087],
              [106.20598800500007, 21.158384604000076],
              [106.20725387000009, 21.160148996000046],
              [106.21383174500006, 21.167328296000051],
              [106.21891391700009, 21.172412297000065],
              [106.2191339930001, 21.173839269],
              [106.21900339900004, 21.175373046000075],
              [106.21817446000003, 21.176791568000027],
              [106.21630050000005, 21.177763137000035],
              [106.19573746200005, 21.182858680000024],
              [106.19317107300007, 21.183057010000091],
              [106.18979282500007, 21.182810150000094],
              [106.18746568500006, 21.182352582000071],
              [106.18607613700003, 21.181354540000093],
              [106.18469581100004, 21.179369893000022],
              [106.18389114600008, 21.178157361000061],
              [106.18308035300005, 21.177602560000075],
              [106.18214890300007, 21.177485273000052],
              [106.18075020800008, 21.177473781000074],
              [106.17794870600009, 21.177889262000079],
              [106.17362165100009, 21.179388418000045],
              [106.17034356900002, 21.180896160000096],
              [106.16852193900012, 21.182470716000076],
              [106.16343329700007, 21.19054106100009],
              [106.15775515700003, 21.19926403],
              [106.15101378000001, 21.20940296800002],
              [106.14919161000006, 21.211532488],
              [106.14897766500003, 21.21169050000011],
              [106.14677943600005, 21.213314019000059],
              [106.14239044900008, 21.215086024000037],
              [106.13408907100005, 21.217537548000017],
              [106.13163559600008, 21.218064967000046],
              [106.13000344000004, 21.218051186000061],
              [106.12825892700003, 21.217597955000045],
              [106.12570889800007, 21.216041659000062],
              [106.12374921500002, 21.213722972000056],
              [106.12295217600007, 21.211742980000011],
              [106.12252286600007, 21.207902491000041],
              [106.12256303900001, 21.203737084000096],
              [106.12212106700004, 21.201211939000061],
              [106.121317758, 21.199889617000075],
              [106.11992525800008, 21.199220032000099],
              [106.11747620700004, 21.199308844000072],
              [106.10322358700009, 21.202366334000097],
              [106.093990741, 21.204698790000087],
              [106.08896407300003, 21.206080583000109],
              [106.08439500500008, 21.208343223],
              [106.083451596, 21.209431298000055],
              [106.08343864600008, 21.210746682],
              [106.08468757100003, 21.214155834000081],
              [106.08467462900003, 21.215471207000022],
              [106.08407661400003, 21.217000750000103],
              [106.08101199700012, 21.220372516000019],
              [106.07983751700002, 21.221239307000062],
              [106.078438514, 21.221227169000123],
              [106.0740365190001, 21.218338736000049],
              [106.07299162400007, 21.217891145000053],
              [106.07159156000004, 21.217988576000032],
              [106.0707689700001, 21.218639153000041],
              [106.06817043300003, 21.222014794000096],
              [106.066404242, 21.223753329000061],
              [106.06382301700005, 21.225375098000043],
              [106.05960733700003, 21.227201756000014],
              [106.05364274699998, 21.229012961000038],
              [106.05201051400012, 21.228998607000094],
              [106.0500339970001, 21.228433105000107],
              [106.048648132, 21.227105438000038],
              [106.04427068600009, 21.221804961000053],
              [106.04151568100005, 21.217505311000103],
              [106.04026418900007, 21.214424784000038],
              [106.04028515600002, 21.212342106000023],
              [106.04123984100005, 21.210158069000059],
              [106.04413589200004, 21.206673970000089],
              [106.04458247800005, 21.205585532],
              [106.04444756800008, 21.204560226000076],
              [106.04352311400007, 21.20338149400007],
              [106.04189660000004, 21.202818985000071],
              [106.04082872100005, 21.202732227000084],
              [106.04038226300007, 21.202695959000017],
              [106.03862373000004, 21.203667014000068],
              [106.03708838800003, 21.205626666000029],
              [106.03389106100006, 21.210531421000042],
              [106.03305287100008, 21.212716470000089],
              [106.03219359800003, 21.216984157000063],
              [106.03216700300005, 21.219614881000034],
              [106.03246356600008, 21.224879391000059],
              [106.03286565600004, 21.231241003],
              [106.03380090800006, 21.242540136000088],
              [106.03390895900003, 21.245067749000057],
              [106.03366135700006, 21.246490585000096],
              [106.03211312800003, 21.248006044000078],
              [106.02893941300007, 21.250499011000024],
              [106.02428139500006, 21.253144571000071],
              [106.01348478700007, 21.257589779000071],
              [106.00765583400006, 21.259956276000125],
              [106.00707234100001, 21.260194760000047],
              [106.00487136100006, 21.26108881200004],
              [106.00105173600004, 21.262321346000057],
              [105.99325652700006, 21.264842777000048],
              [105.99298412800007, 21.26484030800011],
              [105.99230252100008, 21.26483413000004],
              [105.99137293100009, 21.264299489000038],
              [105.9876782100001, 21.259880815000095],
              [105.98418336600011, 21.25423601400005],
              [105.98439215600007, 21.252133023000042],
              [105.98573922500006, 21.248461702000029],
              [105.987835992, 21.244621745000103],
              [105.98936992199999, 21.240952053000051],
              [105.98938441800004, 21.239548884000037],
              [105.98865824600009, 21.237437344000028],
              [105.98199616400007, 21.230711006000021],
              [105.97645085699999, 21.224520845000058],
              [105.97551967900004, 21.224161499000083],
              [105.97346306700005, 21.224142684000036],
              [105.95792774900011, 21.227368366000029],
              [105.95595538700007, 21.228041552000043],
              [105.95471508100005, 21.22918240500011],
              [105.95296287800001, 21.232277430000046],
              [105.95133707900006, 21.235027896000069],
              [105.94997630700009, 21.235937142000068],
              [105.94886966700008, 21.23604212000005],
              [105.94397616100012, 21.234153060000068],
              [105.94288699500007, 21.23367968700007],
              [105.94103693000002, 21.233319139000081],
              [105.93955818100005, 21.233766309000046],
              [105.936587215, 21.235927998000115],
              [105.93410259300003, 21.238555091000052],
              [105.93397243100002, 21.23924525100005],
              [105.93388946699999, 21.239679631000016],
              [105.93395041500006, 21.241319156000046],
              [105.93527585200003, 21.243751306000021],
              [105.93556722500007, 21.244376096000025],
              [105.93638104200008, 21.246121137000081],
              [105.93650940500004, 21.247459568000068],
              [105.93583385500001, 21.249587546000015],
              [105.93410053300005, 21.251710949000113],
              [105.93109788000002, 21.255456326000029],
              [105.92174931600003, 21.269908493000052],
              [105.92065254900007, 21.273032270000058],
              [105.92053659200006, 21.283882267000109],
              [105.92106270000006, 21.286212418000083],
              [105.92289101500009, 21.288963474000063],
              [105.92706546100007, 21.291694121000049],
              [105.92898012700005, 21.293003795000054],
              [105.92905599300011, 21.293623075000042],
              [105.92923361800005, 21.295072977000103],
              [105.9286716340001, 21.296101135000086],
              [105.92646492900012, 21.296338833000036],
              [105.91710366200006, 21.295734306000021],
              [105.91544239600009, 21.296493729000062],
              [105.91488033500003, 21.297521840000044],
              [105.91485818500007, 21.299588438000058],
              [105.91563288800005, 21.3044667220001],
              [105.91423517000004, 21.306299627000058],
              [105.91146761900002, 21.305725094000039],
              [105.90655430700011, 21.303127],
              [105.90407755899999, 21.302845239000057],
              [105.90093022300007, 21.304327890000074],
              [105.89714809800005, 21.306654780000045],
              [105.895464304, 21.309480598000086],
              [105.89543915100005, 21.311805462000066],
              [105.89621540800006, 21.31646302000005],
              [105.89736093000008, 21.323974726000117],
              [105.89715483000008, 21.325806618000072],
              [105.89665473200009, 21.326800790000085],
              [105.89611424600004, 21.327281273000054],
              [105.89502475200004, 21.327552115000081],
              [105.89195379700006, 21.328306137000119],
              [105.88724999400006, 21.33006969100002],
              [105.88502880900005, 21.331598517000124],
              [105.88359262600005, 21.332820202000043],
              [105.88039108300011, 21.335760821000079],
              [105.87893738900009, 21.338272439000058],
              [105.87908451600001, 21.339313765000064],
              [105.87970379800008, 21.34065673200007],
              [105.88407778700005, 21.346492331],
              [105.88490846200004, 21.347060569000043],
              [105.88517899800004, 21.347245626000074],
              [105.88644514800009, 21.347406267000046],
              [105.88772098100004, 21.346675642000079],
              [105.89186696500008, 21.344338204000032],
              [105.89424559600006, 21.344212261000095],
              [105.89772867100004, 21.344542447000059],
              [105.90151588800003, 21.346063932000092],
              [105.90434272900008, 21.348467606000021],
              [105.90652934800008, 21.351459413000029],
              [105.90651815500004, 21.352499200000082],
              [105.90587625700005, 21.353235917000021],
              [105.90507907600008, 21.353674048000038],
              [105.90301889500006, 21.353654562000109],
              [105.8978132820001, 21.351376897000037],
              [105.89580621900004, 21.350976025000087],
              [105.89483035600003, 21.351210510000037],
              [105.89416678300002, 21.351490823000049],
              [105.89320466900008, 21.352521580000072],
              [105.89380640600004, 21.354043207000117],
              [105.89404835800008, 21.354426239000098],
              [105.8940626880001, 21.354691264000088],
              [105.89734600800008, 21.357462629000068],
              [105.89946011300003, 21.360222852],
              [105.90118252800008, 21.363162],
              [105.90327134100008, 21.366988540000072],
              [105.90651156300002, 21.375086255000042],
              [105.90786014000005, 21.37656036700006],
              [105.90902560900003, 21.376936711000027],
              [105.91009061000004, 21.376946756000059],
              [105.91097463200001, 21.376955091000035],
              [105.91566009600007, 21.376268522000075],
              [105.93480501900007, 21.372246274000069],
              [105.93616929100006, 21.372258983000059],
              [105.93791562300004, 21.373005925000044],
              [105.94041252900004, 21.376499927000033],
              [105.94153860600005, 21.379861025000046],
              [105.94249777100001, 21.389219994000094],
              [105.94201878800008, 21.39162387600004],
              [105.94001760600005, 21.395005253000043],
              [105.93863606900005, 21.396975717000096],
              [105.93823858200003, 21.398800331000075],
              [105.93841763500011, 21.399872552000062],
              [105.93919569500008, 21.401230842000096],
              [105.94397096700004, 21.402817124000073],
              [105.9480775480001, 21.404854810000096],
              [105.94957437400004, 21.40628528700006],
              [105.95106225400005, 21.408565633000087],
              [105.95298283900007, 21.412833207000048],
              [105.95459367500003, 21.417806163000108],
              [105.95650549500003, 21.422923507000036],
              [105.95836384500005, 21.433139897000061],
              [105.95879364000008, 21.435410334000068],
              [105.95866242300006, 21.437648565000075],
              [105.9611135690001, 21.438120795000067],
              [105.96150691200005, 21.437759486000019],
              [105.96279004500008, 21.436129081000033],
              [105.96408462300008, 21.433403984000066],
              [105.96657439100002, 21.428135264000048],
              [105.96995464400001, 21.421506104000088],
              [105.97083955800005, 21.42069308800005],
              [105.97191252700001, 21.420520428000067],
              [105.97298645500005, 21.420256538000082],
              [105.97377111200005, 21.419716295000057],
              [105.97496235800003, 21.417537522000082],
              [105.97644948200005, 21.414996493000068],
              [105.97694011500006, 21.414636021],
              [105.98055976000006, 21.413026769000076],
              [105.98291418100008, 21.412204950000024],
              [105.98368588500009, 21.412051624000036],
              [105.98431448100004, 21.412523736000111],
              [105.98514887700007, 21.413822229000075],
              [105.9854626740001, 21.41422228100005],
              [105.98620345600001, 21.414328316000116],
              [105.98854205200007, 21.413654442000052],
              [105.98897108300004, 21.41316182900006],
              [105.98898957200007, 21.411374589000062],
              [105.98931877900003, 21.410285264],
              [105.99136198000006, 21.407424044000045],
              [105.99232800600006, 21.406241170000051],
              [105.9936037210001, 21.405855515000027],
              [105.99540519000003, 21.405871812000044],
              [105.99836513900006, 21.406593674000085],
              [106.00079833400012, 21.407012836000035],
              [106.00557179600003, 21.407250399000063],
              [106.0065206670001, 21.407064370000036],
              [106.00865631500005, 21.405494734000015],
              [106.00982908500002, 21.404810159000043],
              [106.01121173900002, 21.404326056000045],
              [106.01311916000002, 21.404343160000039],
              [106.01439280300005, 21.404155969],
              [106.01609436100007, 21.403575393000018],
              [106.01684423500004, 21.40278767900012],
              [106.01792212200004, 21.401009882000018],
              [106.01815429600006, 21.39902590000009],
              [106.01816846700008, 21.397635780000087],
              [106.01870739700009, 21.396746867000083],
              [106.0207297960001, 21.395871203000105],
              [106.02285178500007, 21.395819148000058],
              [106.02390763600003, 21.396533799000039],
              [106.0248196580001, 21.39779643100006],
              [106.02622880800007, 21.40070398800011],
              [106.02843851500002, 21.405741610000106],
              [106.02924100399999, 21.407678717000067],
              [106.030158989, 21.408362365000052],
              [106.03118168400006, 21.408853941000046],
              [106.0327172070001, 21.409446563000053],
              [106.03312335400005, 21.409932657000041],
              [106.033119449, 21.41031861400009],
              [106.03290416500003, 21.411281682000016],
              [106.03215652400007, 21.414073476000084],
              [106.03120137900001, 21.417056413000068],
              [106.03098216800008, 21.418405419000038],
              [106.03094992300007, 21.421589510000061],
              [106.03062109600005, 21.42361300200006],
              [106.02988022000002, 21.42572932100002],
              [106.02894160699999, 21.427071911000084],
              [106.02779843000009, 21.428316179000092],
              [106.02520666100006, 21.430512487],
              [106.02447943000001, 21.431277964000017],
              [106.02447256500005, 21.431953363000034],
              [106.02477107400007, 21.432920964000068],
              [106.02527512200002, 21.433890394000073],
              [106.02720526700003, 21.436126941000033],
              [106.02995274700008, 21.438853182000095],
              [106.03097463500008, 21.439441217000073],
              [106.03185378100008, 21.439780448000079],
              [106.03251038900004, 21.440033807000077],
              [106.03333259600004, 21.440041102000059],
              [106.03425952100008, 21.439856336000048],
              [106.03622004100004, 21.439101765000053],
              [106.03693946900005, 21.439108135000048],
              [106.03765401700011, 21.439596925000082],
              [106.03922091800007, 21.440925502000049],
              [106.03998613700006, 21.441487098000067],
              [106.04095813900003, 21.441929909000024],
              [106.04185523500009, 21.442154934000079],
              [106.04285560600007, 21.442332635000035],
              [106.04379574600006, 21.442496848000033],
              [106.04468653700005, 21.444550852000077],
              [106.0454934150001, 21.447503035000089],
              [106.04561806900003, 21.447830140000065],
              [106.04620661700005, 21.449374524000042],
              [106.04878624000006, 21.452833128000037],
              [106.0496080020001, 21.454312868000081],
              [106.049488657, 21.455784333000061],
              [106.04920568800003, 21.456417998000084],
              [106.04883272200009, 21.457276377000056],
              [106.04898793700005, 21.457930593000093],
              [106.04911172099999, 21.458736243000054],
              [106.04929366300003, 21.460316024000022],
              [106.04938354700001, 21.46121420700009],
              [106.04922821500003, 21.461909089000031],
              [106.0487824260001, 21.461982527000067],
              [106.04818938000005, 21.461946370000092],
              [106.04728615200003, 21.461863745000095],
              [106.04620301200002, 21.462216594000068],
              [106.04431801500002, 21.463402917000082],
              [106.04305356100011, 21.464292927000095],
              [106.04263013800008, 21.464590961000063],
              [106.04183222600004, 21.465509253000015],
              [106.039622503, 21.469561173000073],
              [106.03911281400011, 21.471222243000021],
              [106.03909974200009, 21.472517581000083],
              [106.03918711700004, 21.473628735000091],
              [106.03937867700007, 21.474185615000074],
              [106.03986783800005, 21.474560065000055],
              [106.04085456100006, 21.474476259000049],
              [106.041840361, 21.474484965000059],
              [106.04301679100008, 21.475143067000069],
              [106.04506835100001, 21.477011787000052],
              [106.04623922500008, 21.478225010000074],
              [106.04730596900006, 21.479992497000033],
              [106.04729574100001, 21.481010241000043],
              [106.046391747, 21.482667822000053],
              [106.04529616000004, 21.483768531000088],
              [106.04291335800005, 21.485413061000088],
              [106.04033428300009, 21.486963295000102],
              [106.03874476400003, 21.48815213000006],
              [106.03694403700007, 21.490727016000029],
              [106.03634409500008, 21.491554458000017],
              [106.036335679, 21.492387144000077],
              [106.03662302500007, 21.493222447000093],
              [106.03749911400007, 21.494340541000057],
              [106.04019648000006, 21.498181160000037],
              [106.04116980700003, 21.499438878000099],
              [106.04136185400003, 21.499949474000069],
              [106.04150368700004, 21.500552155000015],
              [106.04153759000009, 21.502079148000064],
              [106.04171892600009, 21.503653708000044],
              [106.04200584500002, 21.504535243000063],
              [106.04253553400005, 21.505789024000066],
              [106.04321498500005, 21.506859074000047],
              [106.04345355300008, 21.507647647000013],
              [106.04358700300001, 21.509082979000112],
              [106.04356836800008, 21.510933328000093],
              [106.04334229000003, 21.513799615000018],
              [106.04328880100003, 21.514215502000056],
              [106.04298880300009, 21.514629223],
              [106.04224557800005, 21.514992765000059],
              [106.04086097700007, 21.515396900000013],
              [106.04016844100005, 21.515622096000087],
              [106.03976984300002, 21.516034932000046],
              [106.03961726600001, 21.51649620900011],
              [106.03961166400008, 21.517051303000031],
              [106.03970513200008, 21.51756102100002],
              [106.04014463900003, 21.517981260000063],
              [106.04085908300009, 21.518621706000118],
              [106.04161154000008, 21.519197032000115],
              [106.04200128200009, 21.51966308500004],
              [106.04252774900004, 21.521240640000094],
              [106.04314536400007, 21.523559180000085],
              [106.04362162100004, 21.525228794000029],
              [106.04395090200011, 21.526804590000026],
              [106.04519739900003, 21.530331437000072],
              [106.04688721300005, 21.533908406000087],
              [106.04708210900002, 21.534141427000094],
              [106.04737654800005, 21.534282799000067],
              [106.04786866500008, 21.534379653000087],
              [106.04816402500009, 21.534428509000044],
              [106.048802671, 21.534665432000018],
              [106.04934131300007, 21.535040254000037],
              [106.04987901900006, 21.535507590000066],
              [106.05070282400001, 21.536948902000042],
              [106.05142709000008, 21.538481865000044],
              [106.05186203000008, 21.539364631000076],
              [106.0518569270001, 21.53987344800008],
              [106.05159740300005, 21.541166461000039],
              [106.05058247800008, 21.544025669000085],
              [106.04993080400008, 21.54508391500007],
              [106.049379125, 21.546004267000015],
              [106.04937540800005, 21.546374313000079],
              [106.04947077300002, 21.546698973000034],
              [106.05005503900006, 21.54744426300006],
              [106.05147012500008, 21.548937001000112],
              [106.05247221800003, 21.550431877000072],
              [106.05364712100008, 21.551908268000105],
              [106.05135709400005, 21.552522970000076],
              [106.04920218400011, 21.553869990000059],
              [106.04815906700003, 21.555387462000027],
              [106.04736398900006, 21.557790952000033],
              [106.04658263900008, 21.55882862],
              [106.04537464200004, 21.559701824000044],
              [106.04270813900004, 21.560803209000042],
              [106.03970224100001, 21.561580159000115],
              [106.03601272600011, 21.562190326000064],
              [106.034474248, 21.561855310000055],
              [106.03353778000005, 21.56128457100008],
              [106.032856635, 21.560876793000091],
              [106.03225789700011, 21.560791138000113],
              [106.03131165700007, 21.561184487000062],
              [106.0295847730001, 21.562535094000076],
              [106.02906108300002, 21.563494625000068],
              [106.02939797800009, 21.564060058000045],
              [106.0312701170001, 21.565281899000063],
              [106.03160703, 21.565847327000043],
              [106.03168372600004, 21.566731837000042],
              [106.03166336600003, 21.568740357000046],
              [106.0316478930001, 21.570266825000047],
              [106.03124121500011, 21.571377026000071],
              [106.03290874200005, 21.57318219600004],
              [106.03444889400004, 21.573921623000025],
              [106.03816184000001, 21.575589545000092],
              [106.04433434300003, 21.579188579000075],
              [106.04728063, 21.58149315100006],
              [106.04833999400009, 21.583527906000107],
              [106.05044352700006, 21.589116324000109],
              [106.05358130100005, 21.599270846000081],
              [106.05409076700002, 21.602313375000023],
              [106.05542788300008, 21.603590947000036],
              [106.05704004500006, 21.604364577000098],
              [106.06189178000004, 21.605166493000105],
              [106.06431640100001, 21.605693987000052],
              [106.06592609300006, 21.6067207],
              [106.06832051600003, 21.610285891000025],
              [106.070432657, 21.612707958000051],
              [106.07232482100005, 21.614877693000075],
              [106.07553192100002, 21.618196648000065],
              [106.07713926800005, 21.619476392000081],
              [106.08494631500004, 21.621822332000114],
              [106.09087107500007, 21.623392323000076],
              [106.09518345000001, 21.624188848000081],
              [106.09815333600007, 21.624214309000052],
              [106.10140312500002, 21.623229485000103],
              [106.1087174890001, 21.620760301000082],
              [106.11657899600006, 21.617536026000025],
              [106.12279590400001, 21.616829189000072],
              [106.12738556900007, 21.616867951000081],
              [106.1311579800001, 21.617659232],
              [106.13411073900006, 21.619456207],
              [106.13759381400004, 21.622270226000047],
              [106.13920403200004, 21.623296368000041],
              [106.14189423000005, 21.624331546000072],
              [106.14890192400007, 21.62565588300005],
              [106.15752967800002, 21.626993436000049],
              [106.15996682900006, 21.626254179000078],
              [106.16542412100004, 21.620223499000076],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 4, code: "AD01", ten_tinh: "Bắc Kạn" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.74420109500001, 22.735194665000066],
              [105.74623693200006, 22.731415669000029],
              [105.74819975900002, 22.729477314000057],
              [105.74988139600006, 22.728777364000052],
              [105.75310615700008, 22.728212391000021],
              [105.75504657800005, 22.727395526000024],
              [105.75661058700004, 22.725738526000058],
              [105.75716021600005, 22.722757090000108],
              [105.75717552000006, 22.721442985000095],
              [105.75654729200005, 22.720122426000074],
              [105.75400522300004, 22.717348903000101],
              [105.75184096400002, 22.715296009000063],
              [105.75108548600004, 22.713854651000084],
              [105.750839247, 22.712896332000106],
              [105.75126022000002, 22.709913533000048],
              [105.752851999, 22.705867138000087],
              [105.75456544800002, 22.702419327000101],
              [105.7569097680001, 22.700053165000078],
              [105.75963732900001, 22.69792977700007],
              [105.76377625300007, 22.696178911000061],
              [105.77052394500006, 22.694439992000071],
              [105.77306110100002, 22.693877908000012],
              [105.77449291500002, 22.69315796700004],
              [105.77450818200003, 22.691836574000043],
              [105.77078234200005, 22.685779041000039],
              [105.76891356300005, 22.683264110000053],
              [105.76610452600008, 22.680005553000093],
              [105.76265963100005, 22.677034266000092],
              [105.7527811360001, 22.66973984600007],
              [105.74918708700008, 22.666032540000103],
              [105.74577534500006, 22.660271105000085],
              [105.74190839700003, 22.652889585000047],
              [105.73868106800008, 22.64492691800006],
              [105.73758810700005, 22.643741037],
              [105.73598880200007, 22.642816746000079],
              [105.73276336300009, 22.642450786],
              [105.73049608800008, 22.641983409000055],
              [105.72835628400004, 22.64085073400004],
              [105.72622830200001, 22.638718286000078],
              [105.72583340000003, 22.636921130000061],
              [105.72635897700007, 22.636644091000058],
              [105.73132611600002, 22.633424418000025],
              [105.73589682600002, 22.630636628000019],
              [105.73726920900005, 22.62951102400006],
              [105.73846958000007, 22.628082873000018],
              [105.74499320300001, 22.61884688100011],
              [105.74965126800004, 22.611880947000081],
              [105.75148049800003, 22.609415226000081],
              [105.75173655700009, 22.608505747000059],
              [105.75166981800007, 22.607213001000034],
              [105.75135323000008, 22.606297759000071],
              [105.75095573400006, 22.605305689000055],
              [105.75007006500005, 22.604080697000128],
              [105.74836725200002, 22.602771459000067],
              [105.74552809500003, 22.600690693000033],
              [105.74480164800005, 22.599847298000064],
              [105.74407964500008, 22.598623918],
              [105.74352304000004, 22.597250186000025],
              [105.74328564300004, 22.596563731000074],
              [105.74333856600006, 22.595538180000041],
              [105.74391829200006, 22.594935973000041],
              [105.75120279300006, 22.587636640000049],
              [105.75177715200007, 22.587490399000032],
              [105.75243153900004, 22.587496979000043],
              [105.76010200200008, 22.5891701400001],
              [105.76263158800005, 22.589727522000125],
              [105.76344869600007, 22.589811703000073],
              [105.76393993300012, 22.589778615000085],
              [105.76436397700003, 22.589594717000061],
              [105.76619620400001, 22.587662483000059],
              [105.77001232900002, 22.583194089000088],
              [105.77213785700012, 22.580928389000029],
              [105.77433183600002, 22.57899965000005],
              [105.77558016400009, 22.577465056000086],
              [105.77653818100005, 22.575994816000033],
              [105.77705569700005, 22.57505828799999],
              [105.77713505700005, 22.574453713000061],
              [105.77714668900003, 22.573444888000083],
              [105.77693421100004, 22.573039198000089],
              [105.77406855700008, 22.57045468300003],
              [105.769548419, 22.566979193000059],
              [105.76876394900002, 22.56596240600004],
              [105.76784719200002, 22.563868066000033],
              [105.766249574, 22.558067345000055],
              [105.76626319500005, 22.557800555000092],
              [105.76622417500002, 22.556754560000059],
              [105.76658763200007, 22.556282922000022],
              [105.76864847800006, 22.554972734000067],
              [105.77168911300002, 22.552959380000104],
              [105.77357507200001, 22.551798479000063],
              [105.77457462800002, 22.551087016000082],
              [105.77514204699999, 22.550664915000063],
              [105.77644930100007, 22.550017160000024],
              [105.77741536100007, 22.549877377000094],
              [105.7787876890001, 22.549908231000067],
              [105.77975566400008, 22.550166913000069],
              [105.78025990500005, 22.550335579000077],
              [105.78097755500001, 22.551075365000095],
              [105.78166485600003, 22.551878924000057],
              [105.78200205300004, 22.552164609000066],
              [105.78264323200007, 22.552320363000078],
              [105.783159799, 22.552341914000081],
              [105.78375931200004, 22.552260096000062],
              [105.78440279000009, 22.552216682000022],
              [105.78525527000001, 22.551763448000095],
              [105.7877006570001, 22.548851419000115],
              [105.78895283700007, 22.547120963000019],
              [105.79103711400003, 22.542908895000032],
              [105.79181792200006, 22.540227601000048],
              [105.79244326700002, 22.538159162000056],
              [105.79284943200005, 22.537303560000055],
              [105.79335221700009, 22.536663819000054],
              [105.79482434800003, 22.535020522000067],
              [105.79636251700003, 22.533377871000049],
              [105.79813955000007, 22.53106213100002],
              [105.79917981900007, 22.529629442000051],
              [105.80253567700001, 22.524583222000061],
              [105.81197160600003, 22.522952],
              [105.81571137600011, 22.522205388000046],
              [105.81800184200009, 22.521892120000061],
              [105.82016562700007, 22.522137007000076],
              [105.82232058300011, 22.52316498000004],
              [105.82842198600009, 22.526469011000039],
              [105.82962300600008, 22.526704446000068],
              [105.83082654600001, 22.526716133000058],
              [105.83179567300009, 22.526166119000024],
              [105.83241756000002, 22.524382002000017],
              [105.83210931300002, 22.51967984500007],
              [105.83211811600003, 22.518896733000112],
              [105.83285781200004, 22.517337501000064],
              [105.83370402300007, 22.517010047000063],
              [105.83695968700005, 22.516482157000084],
              [105.84020532000001, 22.516849215000079],
              [105.84715427800006, 22.519713422],
              [105.85110837500011, 22.521317882000069],
              [105.854713921, 22.521800064000082],
              [105.86302186600004, 22.521544019000125],
              [105.86845991800004, 22.519582028000059],
              [105.87342510200007, 22.516832198000088],
              [105.87898415100004, 22.514837959000111],
              [105.88577718700006, 22.512577109000098],
              [105.8919875820001, 22.509763430000085],
              [105.89332358800007, 22.508681788000025],
              [105.89660190200007, 22.504882828000028],
              [105.90048193600002, 22.499858403000061],
              [105.9016796440001, 22.497954700000022],
              [105.90271980200001, 22.49700697800003],
              [105.90375875300008, 22.496727800000116],
              [105.90419533600004, 22.496610483],
              [105.90742427800005, 22.497324663000114],
              [105.91020894600007, 22.498308209000051],
              [105.91210211300003, 22.50010408400005],
              [105.91817592200005, 22.509872198000025],
              [105.92033696100006, 22.514132470000092],
              [105.92151983900006, 22.518448739000085],
              [105.92260809200003, 22.521587974000035],
              [105.92466287900005, 22.524289152000065],
              [105.92911079900001, 22.528055502000122],
              [105.93118333700008, 22.52911771200008],
              [105.93230374700005, 22.529277093000033],
              [105.94272241000003, 22.529373257000081],
              [105.94448874400007, 22.529091500000042],
              [105.94755956400004, 22.526735688000073],
              [105.95046687000008, 22.524676328000034],
              [105.95255213300004, 22.524546442000123],
              [105.95495322100003, 22.524866436000046],
              [105.962315765, 22.528577812],
              [105.96363845200003, 22.529456516000046],
              [105.96677742500003, 22.534932549000075],
              [105.96874916300001, 22.537426582000109],
              [105.96994134800008, 22.538056438000083],
              [105.97193658400005, 22.538322162000043],
              [105.97646244100007, 22.538610797000032],
              [105.978853379, 22.53925145500002],
              [105.98110335700009, 22.540633632000073],
              [105.98373612600005, 22.543628680000083],
              [105.98479132300005, 22.544628613000036],
              [105.98585300500007, 22.545009594000121],
              [105.99488706700005, 22.547319265000084],
              [105.998471792, 22.548465611000076],
              [106.000328853, 22.549225048000032],
              [106.00085009100005, 22.550343919000056],
              [106.00147887300002, 22.553939749000037],
              [106.00226781600003, 22.554937205000058],
              [106.00598209800003, 22.556455960000044],
              [106.01094346200003, 22.558465262000112],
              [106.01787171700005, 22.56123628400006],
              [106.01917865500009, 22.561669527000028],
              [106.02071939800003, 22.561659752000104],
              [106.02243181400004, 22.560821005000072],
              [106.02396439200002, 22.559707428000038],
              [106.02491171500007, 22.559591009000087],
              [106.02669109100003, 22.559800421000098],
              [106.03251564300004, 22.562081147000065],
              [106.03608273700004, 22.563603545],
              [106.03918078100004, 22.56579118300008],
              [106.04049693600005, 22.567438397000039],
              [106.04241736300006, 22.570627021000078],
              [106.04362838800006, 22.574040993000018],
              [106.0446024030001, 22.577456497000092],
              [106.04532024800008, 22.578334876000028],
              [106.04591540100003, 22.578662148000014],
              [106.04650806500004, 22.578658295000054],
              [106.04922510500005, 22.577426375000087],
              [106.0532341680001, 22.574640610000074],
              [106.05712876600003, 22.572407411000128],
              [106.06055927100002, 22.571501765000065],
              [106.06341295600005, 22.570533086000033],
              [106.06458149300001, 22.570481739000044],
              [106.06582896100011, 22.570859801000061],
              [106.06964304400003, 22.57359398800007],
              [106.07213722000007, 22.574239654000024],
              [106.07735926900006, 22.575087773000035],
              [106.07925571100002, 22.575075052000045],
              [106.08043410800009, 22.574184081000048],
              [106.080897873, 22.572856398000063],
              [106.08088066300009, 22.570648904000024],
              [106.08075440100004, 22.56965632000005],
              [106.08027171000006, 22.568555758000031],
              [106.07967222300002, 22.567676744000039],
              [106.07598264200001, 22.565714628000052],
              [106.07407094000011, 22.563740555000052],
              [106.07274589100007, 22.5609898740001],
              [106.07249947200003, 22.559777326000052],
              [106.07176109500004, 22.556250062000061],
              [106.07187533399998, 22.555697389000073],
              [106.07293852600003, 22.555248774000077],
              [106.07707697500005, 22.554006924000092],
              [106.08063054600009, 22.553762323000015],
              [106.08371177600003, 22.553741591000076],
              [106.08572298100009, 22.553286496000077],
              [106.09196831800008, 22.548718607000048],
              [106.09326578200006, 22.547937130000051],
              [106.09385046700002, 22.546939727000037],
              [106.09384264100001, 22.545946354000087],
              [106.09324143700009, 22.544846636000045],
              [106.0909690940001, 22.542212918000089],
              [106.09054436500004, 22.540946421000015],
              [106.090648125, 22.539069245000043],
              [106.09157527100007, 22.536413812000063],
              [106.0920683790001, 22.535812347000039],
              [106.09322028200002, 22.535468227000074],
              [106.09483056200003, 22.53555950500008],
              [106.09696917600006, 22.535896051000037],
              [106.09832855700009, 22.536257477],
              [106.09880253000004, 22.536254242],
              [106.09915364100006, 22.535699935000061],
              [106.09914751900008, 22.534927314000086],
              [106.0984295800001, 22.534049170000081],
              [106.09640564100006, 22.53284879000006],
              [106.09390689700008, 22.531541225000048],
              [106.09294941800007, 22.530333536000043],
              [106.09282224400005, 22.52923059200009],
              [106.09429396000004, 22.526343745000062],
              [106.09670929400005, 22.521425411000081],
              [106.09786262900008, 22.519671322000079],
              [106.09878219000005, 22.51870384200005],
              [106.0992517390001, 22.518148730000043],
              [106.10031193100005, 22.517368819000083],
              [106.10315101400005, 22.516797490000059],
              [106.11118977700002, 22.514534600000054],
              [106.11479572900004, 22.513571446000036],
              [106.11644992700003, 22.513008083000052],
              [106.11739238800003, 22.512339259000044],
              [106.11762223500004, 22.511454618000037],
              [106.11767354800003, 22.510430519000032],
              [106.11776574500001, 22.50859045300011],
              [106.11771247500005, 22.504203314000023],
              [106.11754900100001, 22.502635598000076],
              [106.11729483500004, 22.500198071000099],
              [106.11717016300003, 22.499426267000011],
              [106.11668656700007, 22.49821543800001],
              [106.1152420490001, 22.495355554000085],
              [106.11450831000005, 22.49249072500001],
              [106.11364379100011, 22.48808146300005],
              [106.11350149300006, 22.4851021630001],
              [106.11389443100006, 22.482395113000031],
              [106.11575951400002, 22.478629262000105],
              [106.11751048300003, 22.475416086000074],
              [106.11761387500006, 22.473538887000096],
              [106.11736990600004, 22.472657531],
              [106.10325515000004, 22.455204062000078],
              [106.10068592500009, 22.45229649300002],
              [106.09758784100009, 22.449889189000061],
              [106.09365920000002, 22.447266694000056],
              [106.0927049320001, 22.446390095000091],
              [106.09222002500005, 22.444958415000116],
              [106.09221311400009, 22.444075407000064],
              [106.09244043300006, 22.442859663000014],
              [106.09432034400001, 22.440970434000107],
              [106.09832117500009, 22.437742192000073],
              [106.09973252200008, 22.436518388000067],
              [106.10044832400007, 22.434461821000085],
              [106.10080575300006, 22.429073315000011],
              [106.10073315500009, 22.426140430000082],
              [106.10027545000005, 22.422772120000012],
              [106.09930046500003, 22.419246528000066],
              [106.09797644800008, 22.416495976000036],
              [106.09784850600008, 22.415282642000101],
              [106.0981557640001, 22.413927453000056],
              [106.10010515900009, 22.409615134000127],
              [106.10138442700007, 22.405992791000081],
              [106.10319600700004, 22.402773034000042],
              [106.10389930400005, 22.401885171000032],
              [106.10543038200008, 22.400881271000017],
              [106.10720081500001, 22.400206866000083],
              [106.1092123600001, 22.400082690000112],
              [106.11087314400001, 22.400512820000081],
              [106.11444030800003, 22.402475145],
              [106.11764362400004, 22.403336087000092],
              [106.11882741700002, 22.403327894000121],
              [106.12133817800002, 22.402866269000029],
              [106.124584181, 22.401917132000015],
              [106.12811831000003, 22.400704064000095],
              [106.12927858800002, 22.400109347000097],
              [106.13139954500009, 22.3988803230001],
              [106.13292411400002, 22.397103543000085],
              [106.13445402600004, 22.395988996000057],
              [106.13658114500001, 22.39553253600004],
              [106.13823926300006, 22.395631252000037],
              [106.14097624100003, 22.397378062000058],
              [106.145387715, 22.401210222000053],
              [106.15003711300002, 22.405150933000058],
              [106.152901908, 22.408000427000019],
              [106.15457841200008, 22.410306452000036],
              [106.15576498300003, 22.410629115000091],
              [106.16331606000006, 22.411102800000023],
              [106.16495127700009, 22.410486864000042],
              [106.16588437200002, 22.410185412000061],
              [106.16642791500004, 22.409908071000118],
              [106.16852740500003, 22.40856775600006],
              [106.17103441600008, 22.40627110400002],
              [106.17505922300002, 22.402736439000037],
              [106.17557151700004, 22.402197672000042],
              [106.17564788800006, 22.401630945000051],
              [106.17556231100008, 22.401103128000074],
              [106.17480387100004, 22.397891775000019],
              [106.17505562300008, 22.39569903500005],
              [106.17587307900004, 22.394368534000094],
              [106.17775398000006, 22.392809515000067],
              [106.17982124000004, 22.392297739000085],
              [106.18229950600001, 22.391396592000042],
              [106.18299756500004, 22.389956541000089],
              [106.18298356200003, 22.388300943000061],
              [106.18588559799998, 22.381546496000034],
              [106.18764129900003, 22.379215623000093],
              [106.19023106600008, 22.377540888000077],
              [106.19389159200009, 22.376520500000119],
              [106.19708053900007, 22.375724274000049],
              [106.20002800700004, 22.374377897000031],
              [106.20555917900002, 22.370694242000063],
              [106.21440114300002, 22.359866112000049],
              [106.215572084, 22.358422389000054],
              [106.21673629200005, 22.356206051000079],
              [106.21812753000005, 22.352884193000122],
              [106.21835171200004, 22.35144756800009],
              [106.21833923500007, 22.350012726000031],
              [106.21821516300011, 22.349351380000115],
              [106.21737820900005, 22.34836425200006],
              [106.21464038100001, 22.346508356000072],
              [106.21261162900001, 22.344536719000097],
              [106.20951037800009, 22.341690023000105],
              [106.20651618900007, 22.337517897000048],
              [106.20447440800007, 22.334000916000015],
              [106.2017569380001, 22.327563833000049],
              [106.20007224500006, 22.324264895000034],
              [106.19970788000003, 22.323163777000055],
              [106.19957730900006, 22.321729797000067],
              [106.19907201400008, 22.317980594000097],
              [106.19845128100006, 22.314563387000064],
              [106.19843243800001, 22.312355904000114],
              [106.19947921500003, 22.310250928000031],
              [106.20193039900006, 22.306369449000037],
              [106.20303197700008, 22.303767338000036],
              [106.20513851500003, 22.301102563000086],
              [106.20839176500009, 22.294234754000094],
              [106.20990760700001, 22.291684702000047],
              [106.21143195100009, 22.290128],
              [106.21261094000005, 22.28967766500007],
              [106.21427067800005, 22.290106779000048],
              [106.21652946299999, 22.291414436000068],
              [106.21795361700005, 22.291955660000085],
              [106.2190181600001, 22.291947663000045],
              [106.23428229700008, 22.279028192000091],
              [106.23627735900004, 22.277246930000061],
              [106.23938375500003, 22.27407741600009],
              [106.24277834600005, 22.270077786000094],
              [106.24359155700002, 22.268415864000076],
              [106.24369712500004, 22.266980113000031],
              [106.24355156300008, 22.263890582000023],
              [106.24341770400011, 22.262125524000083],
              [106.241001141, 22.249089385000076],
              [106.24050303700008, 22.246749747000116],
              [106.24049234900009, 22.245535648000022],
              [106.2401007010001, 22.241344171000051],
              [106.23947165100002, 22.237044128000072],
              [106.23800629600001, 22.231757013000042],
              [106.23654977600002, 22.227463251000046],
              [106.23557108900005, 22.223717738000026],
              [106.234969339, 22.222508113000039],
              [106.23305497300005, 22.221218016000051],
              [106.22646602100005, 22.216777422000078],
              [106.22468125299999, 22.215466296000038],
              [106.22313007100007, 22.213822239000081],
              [106.22097831500011, 22.211078855000018],
              [106.21989912200009, 22.209320843000043],
              [106.21981570000003, 22.208418354000067],
              [106.21976182800009, 22.207114239000077],
              [106.21974271100002, 22.206721438000088],
              [106.21975038200003, 22.205789750000093],
              [106.21955433000007, 22.203120379000026],
              [106.21945609500003, 22.19935450900001],
              [106.21967982000005, 22.197622043000109],
              [106.22130887800002, 22.194629525000039],
              [106.22445433800004, 22.189307582000062],
              [106.22685481700006, 22.186695550000124],
              [106.22873342100007, 22.185246427000038],
              [106.231086674, 22.184014464000064],
              [106.23202450400005, 22.183124316000047],
              [106.23248377900003, 22.181575500000058],
              [106.23163242000004, 22.178822401000083],
              [106.23019305300005, 22.176404879000074],
              [106.2282819600001, 22.17410127600008],
              [106.22542740500009, 22.172025509000015],
              [106.20655868800003, 22.156105640000057],
              [106.20322276200005, 22.152929185000048],
              [106.19821027200001, 22.147115786000079],
              [106.196780205, 22.145691308000082],
              [106.19535203800004, 22.144487572000045],
              [106.19452026500005, 22.143941748000096],
              [106.19357499900009, 22.143948670000043],
              [106.19192541500003, 22.144512641000077],
              [106.19110109600004, 22.144849806000089],
              [106.19039213300009, 22.144854983000073],
              [106.18932499600008, 22.144421239000067],
              [106.17910611200003, 22.13053187600007],
              [106.17898244800008, 22.129870465000053],
              [106.17956491400005, 22.128872806000018],
              [106.18203034700012, 22.126978446000045],
              [106.18343057200008, 22.12487101700011],
              [106.18423912600009, 22.122657478000107],
              [106.18422621600008, 22.121112195000059],
              [106.18361892400003, 22.119129691000069],
              [106.18301993800003, 22.11814057400003],
              [106.18230743500004, 22.117704201000073],
              [106.17529158600007, 22.112235648000045],
              [106.17481447700003, 22.111687164000045],
              [106.17422107200004, 22.111360274000091],
              [106.1735122800001, 22.111365371000069],
              [106.17222285300008, 22.112588858000045],
              [106.17069532900008, 22.11359326700002],
              [106.16904144000003, 22.113605118000024],
              [106.16797460100004, 22.11317121000009],
              [106.16702044200005, 22.112074193000097],
              [106.16086475200009, 22.103342483000077],
              [106.16013883800008, 22.101250330000077],
              [106.15963116299999, 22.096948927000071],
              [106.15767461200002, 22.088794330000013],
              [106.15598507600002, 22.084390886000094],
              [106.15514573500003, 22.082851420000082],
              [106.154432582, 22.082304536000038],
              [106.1536040280001, 22.082089612000019],
              [106.14994265400003, 22.082115401000024],
              [106.14392175500004, 22.082488797000011],
              [106.13991222500002, 22.083289498000092],
              [106.12777224800008, 22.08657487600005],
              [106.12387013299998, 22.086049821000039],
              [106.11979216900008, 22.085691452000056],
              [106.11872393100006, 22.085036448000039],
              [106.11717804000006, 22.083722383000023],
              [106.11633737900004, 22.081961951000025],
              [106.11548373600003, 22.078545800000079],
              [106.11428361400003, 22.076125486000109],
              [106.11273354500003, 22.074259473000076],
              [106.11202232700006, 22.073933151000077],
              [106.11131371000005, 22.073937961000034],
              [106.11025335900007, 22.074276323000049],
              [106.10919559100003, 22.074945824000025],
              [106.10837316400003, 22.075503327000057],
              [106.10754986900011, 22.075950451000011],
              [106.1067231330001, 22.075956045000034],
              [106.10542139700004, 22.075633697000093],
              [106.09914045000002, 22.072916359000061],
              [106.098306053, 22.071928479000036],
              [106.09758208300005, 22.069946379000079],
              [106.09756844, 22.068180286000057],
              [106.09777121900007, 22.056201949000084],
              [106.09799376300001, 22.054434264000022],
              [106.09891629500004, 22.051558004000071],
              [106.10019388900005, 22.048789753000086],
              [106.10065086000003, 22.046799713],
              [106.10063462800011, 22.044702466000032],
              [106.10050801900012, 22.043599444000073],
              [106.09942740400005, 22.041288578],
              [106.09764177100001, 22.039423977000091],
              [106.09550535600009, 22.038003250000045],
              [106.094438446, 22.037458452000088],
              [106.09407913700002, 22.03679853000002],
              [106.09406470900008, 22.0349220350001],
              [106.094406195, 22.033263943000044],
              [106.09510104400003, 22.031493100000077],
              [106.09709801300005, 22.030155093000033],
              [106.09974389600004, 22.028757512000041],
              [106.10302753100004, 22.025865365000108],
              [106.10595256100002, 22.022423644000085],
              [106.10793213900004, 22.018877874000076],
              [106.10885341000005, 22.015891189000044],
              [106.10906632700009, 22.012909295000057],
              [106.10903283800008, 22.00860442900003],
              [106.1086391970001, 22.003529267000133],
              [106.10714356400007, 22.00000021699999],
              [106.10599788400005, 21.998927427000048],
              [106.10580056900007, 21.998549812000064],
              [106.10600523900004, 21.998175617000101],
              [106.10660914400009, 21.998086762000035],
              [106.10872418400002, 21.997634821000034],
              [106.11023806700005, 21.996989788000043],
              [106.11165602300005, 21.995874008],
              [106.11236774800005, 21.995034183000058],
              [106.11247740000006, 21.994095265000091],
              [106.11229290600008, 21.992401970000053],
              [106.11193858300007, 21.987511703000081],
              [106.11156962100009, 21.984125054000089],
              [106.11142822000002, 21.978014672000064],
              [106.11155254200001, 21.97557203700002],
              [106.11188057700006, 21.972849153000062],
              [106.11201222200003, 21.969654632000065],
              [106.11173643600003, 21.967020565000084],
              [106.11145424000004, 21.965044370000086],
              [106.11116196600003, 21.964101987],
              [106.11076647100008, 21.963440696000092],
              [106.10996447200006, 21.963245905000051],
              [106.10785072800005, 21.963603906000095],
              [106.10533598299999, 21.963864482000069],
              [106.10322958900005, 21.963470577000074],
              [106.09922332700005, 21.962120526000014],
              [106.09752442200008, 21.961166099000096],
              [106.09672891200007, 21.960313377000062],
              [106.09624129900004, 21.958805346000076],
              [106.09617686400004, 21.95513910600004],
              [106.09629673500011, 21.953166294000056],
              [106.09670510100005, 21.952511839000088],
              [106.09731163900005, 21.952141056000052],
              [106.09801311200002, 21.952335038000022],
              [106.09931188600009, 21.953098075000099],
              [106.10021062200011, 21.953669704000056],
              [106.10061159200006, 21.953767119000034],
              [106.10181728700009, 21.953777407000082],
              [106.10604365500011, 21.953155471000052],
              [106.10846053600005, 21.952612074000029],
              [106.10947170300005, 21.951962710000124],
              [106.10988001200009, 21.951308229000063],
              [106.109886433, 21.950650334000073],
              [106.10959602400004, 21.94951995100007],
              [106.10870279600003, 21.948384443000087],
              [106.10790636100003, 21.947625725000108],
              [106.10531617500004, 21.945347838000103],
              [106.10102637500002, 21.942209453000046],
              [106.09892946000006, 21.940875623000089],
              [106.09678380700008, 21.93920705000005],
              [106.09647135600008, 21.939320880000054],
              [106.09209884300004, 21.94086863800004],
              [106.08457955700007, 21.942389232],
              [106.07609417900009, 21.943674761000054],
              [106.07101102400006, 21.943630753000051],
              [106.06665629400008, 21.943366503000036],
              [106.05940372000006, 21.942397546000066],
              [106.05673658800001, 21.942827181],
              [106.05430695200005, 21.943711773000096],
              [106.05236140200002, 21.944600575],
              [106.05138406300006, 21.94549784800008],
              [106.05062826300005, 21.948435144000108],
              [106.05010994800007, 21.951827406000035],
              [106.04887452400008, 21.954307546000074],
              [106.04691742300007, 21.956328443000039],
              [106.04300767700005, 21.959917296000064],
              [106.03861592400003, 21.963275361000086],
              [106.03594156900007, 21.964384019000072],
              [106.03010859500009, 21.966596928000023],
              [106.02853855800004, 21.967232996000071],
              [106.02792062700007, 21.967483334000093],
              [106.02550439300008, 21.967009018000013],
              [106.02333951300001, 21.965631124000048],
              [106.02167501300005, 21.962672513000122],
              [106.01907959200007, 21.95871048700004],
              [106.01714231700001, 21.955325091000063],
              [106.01688001000005, 21.954709882000053],
              [106.01658359200005, 21.953709696000075],
              [106.01595604800008, 21.948766674000098],
              [106.01521561500002, 21.946512262000084],
              [106.013601045, 21.943592147000054],
              [106.01193948400001, 21.941515473],
              [106.01021180700006, 21.940080635000037],
              [106.00824751400008, 21.938909995000074],
              [106.00480827800008, 21.937034274000041],
              [106.0027217300001, 21.935747177000039],
              [106.00088341200006, 21.934346974],
              [105.99990131500007, 21.933761608000069],
              [105.99878500500007, 21.934212806000097],
              [105.99778861200005, 21.935010989000055],
              [105.99592409100006, 21.936147278000107],
              [105.994185444, 21.93705406800008],
              [105.99170572900009, 21.937954165000036],
              [105.974630287, 21.940797354],
              [105.97302345600009, 21.940898025000074],
              [105.97142387000008, 21.940306915000107],
              [105.97012249800005, 21.939007216000036],
              [105.96855168700003, 21.936128333000049],
              [105.96791734600004, 21.933008881000049],
              [105.96793825200007, 21.931016318000026],
              [105.96808994200009, 21.92927403900002],
              [105.96865226100009, 21.926539111000039],
              [105.968658792, 21.925916426000036],
              [105.96839853100005, 21.925291312000091],
              [105.96746999700005, 21.924784634],
              [105.96494074500004, 21.924263315000033],
              [105.96363335000004, 21.924204380000042],
              [105.96147381200007, 21.924107026000073],
              [105.96014249500004, 21.923845733000036],
              [105.95934737400006, 21.923340242000044],
              [105.95882561800006, 21.922214506000067],
              [105.9587079730001, 21.920718832000126],
              [105.95846875300002, 21.918101088000071],
              [105.95810666199999, 21.91448578799999],
              [105.95666567400005, 21.911981521000087],
              [105.95429103900004, 21.909468656000094],
              [105.94991969200001, 21.90656369100008],
              [105.94634215000004, 21.90428872500005],
              [105.94421599900006, 21.903521768000097],
              [105.94115753800006, 21.902621598000046],
              [105.93783101000005, 21.901843463000056],
              [105.9362384360001, 21.901081362000063],
              [105.93174347900006, 21.897302957000072],
              [105.92988952800005, 21.896040155000037],
              [105.92762749400003, 21.895520865000059],
              [105.92469327100008, 21.895493509000019],
              [105.922691329, 21.895599379000075],
              [105.91854736300004, 21.896432537000088],
              [105.91453674800003, 21.897266858000108],
              [105.91133843400007, 21.896987746000015],
              [105.90828290800005, 21.895838024000014],
              [105.90497818700007, 21.89306668400004],
              [105.89826808599999, 21.884533437000094],
              [105.89616127700002, 21.882022339000073],
              [105.89232756500007, 21.878872046000097],
              [105.89087413500008, 21.877612657000078],
              [105.887861134, 21.872601614000061],
              [105.88630431200006, 21.868600826000062],
              [105.88119607500001, 21.860081875000056],
              [105.88000955700007, 21.858824919000064],
              [105.8787464860001, 21.858274441000077],
              [105.873016326, 21.857168038000019],
              [105.87189281600004, 21.856946945000018],
              [105.87087842100004, 21.857042403000015],
              [105.86929534900005, 21.857658251000075],
              [105.86713892300007, 21.859215091000102],
              [105.86464703600004, 21.860558351000044],
              [105.86227233700002, 21.861482042000091],
              [105.861596838, 21.861475548000087],
              [105.86103623300006, 21.861259815],
              [105.8609282940001, 21.860838111000099],
              [105.86105016100007, 21.85999795100004],
              [105.86175235200007, 21.857585850000035],
              [105.86234425800011, 21.854962340000036],
              [105.86236629700007, 21.852964355000012],
              [105.86182545, 21.850960942000071],
              [105.85960873699999, 21.847784537000067],
              [105.85860367900005, 21.847038676000082],
              [105.85714485100004, 21.846603946000087],
              [105.84995284000006, 21.845377647000049],
              [105.84725222, 21.845246339000113],
              [105.84218050100002, 21.84572303700012],
              [105.82415082000001, 21.847124892000025],
              [105.81964779600003, 21.847080783000102],
              [105.81837759100009, 21.846820564000069],
              [105.81695191100007, 21.846528485000057],
              [105.81470988800002, 21.845665096000033],
              [105.81280679600003, 21.844699848000104],
              [105.80985269900003, 21.842094087000063],
              [105.80496739500003, 21.836051126000108],
              [105.80331104100003, 21.833195106000055],
              [105.80287746200004, 21.831718399000088],
              [105.80256122200008, 21.829822155000045],
              [105.80236113600004, 21.827611532000013],
              [105.80105131400003, 21.819026819000072],
              [105.79881296300002, 21.807961099000025],
              [105.79848366400003, 21.807221587000036],
              [105.79747906200004, 21.806475392000024],
              [105.79627888700004, 21.806038417000103],
              [105.7944461390001, 21.80591942300012],
              [105.78825580800003, 21.805857918000093],
              [105.78499421900008, 21.805615087000071],
              [105.78375014300009, 21.806128585000103],
              [105.78181630600004, 21.807897326000081],
              [105.77897478600003, 21.810288048000089],
              [105.77693192900003, 21.811740114000067],
              [105.77557523100003, 21.812252426],
              [105.77128967900011, 21.812945738000074],
              [105.76813328000006, 21.813334787000116],
              [105.76598502800005, 21.814154620000089],
              [105.76349059300006, 21.815707190000033],
              [105.75827040000007, 21.819335787000057],
              [105.75758651600006, 21.820065108],
              [105.75757921700007, 21.82069608500008],
              [105.75801730100008, 21.821752261000071],
              [105.75934492700006, 21.823763972000073],
              [105.75978058800005, 21.825030463000061],
              [105.75975870900001, 21.826923392000097],
              [105.75917279500004, 21.828915794000103],
              [105.75848159400005, 21.83027608700003],
              [105.75745511500003, 21.831422648000043],
              [105.75632094000005, 21.832147415000058],
              [105.75608971500003, 21.832670954000029],
              [105.75608484500006, 21.83309159500002],
              [105.75618401700009, 21.834249502000077],
              [105.75650224600005, 21.835935476000046],
              [105.75714602100003, 21.838676453000083],
              [105.75867701100005, 21.842583237000035],
              [105.75973812000009, 21.844435707000095],
              [105.76315637700003, 21.849489090000063],
              [105.76593253400006, 21.855421610000022],
              [105.76763161700003, 21.858833790000084],
              [105.76776753500009, 21.860754127000078],
              [105.76775053200001, 21.862230079000078],
              [105.76661034500009, 21.865170872000036],
              [105.76658482700002, 21.867384779000076],
              [105.76672074099999, 21.869305085000065],
              [105.76779804200004, 21.871825267000041],
              [105.768794751, 21.873649867000019],
              [105.76950068900005, 21.87494215000001],
              [105.77058824700006, 21.876576740000075],
              [105.77089073400005, 21.877760641000066],
              [105.77087715000008, 21.87894136300006],
              [105.77022461800009, 21.880706117000059],
              [105.76878358400005, 21.882315342000069],
              [105.76845730500006, 21.883197702000068],
              [105.76861728200005, 21.8849113200001],
              [105.76892245600008, 21.885696886000083],
              [105.76935474000005, 21.886809637000049],
              [105.77048270700004, 21.887766398000075],
              [105.77237536100004, 21.888258088000079],
              [105.7773009150001, 21.889134630000036],
              [105.77856090400007, 21.889619941000049],
              [105.77868066900004, 21.890212044000101],
              [105.77816365900007, 21.891152326000096],
              [105.77662889300009, 21.892555165000083],
              [105.77202852100004, 21.8964090730001],
              [105.77036031500003, 21.898401407],
              [105.7698350810001, 21.900050650000047],
              [105.76972897100002, 21.909267453000055],
              [105.76933434800006, 21.910563434],
              [105.76856685400006, 21.91126479900003],
              [105.76678010300002, 21.912546802000051],
              [105.76346647500003, 21.914522510000012],
              [105.76282413500007, 21.915343276000065],
              [105.76300642100006, 21.915819244000097],
              [105.76437071600006, 21.917681309000066],
              [105.76857018200006, 21.922621579000086],
              [105.772273908, 21.927649177000049],
              [105.77451539, 21.930721315000042],
              [105.7753892420001, 21.9322086990001],
              [105.77567663100008, 21.933043304000051],
              [105.77564223300007, 21.934025425000044],
              [105.77561969300008, 21.935987952000069],
              [105.77418736500005, 21.946277953000056],
              [105.77306142200005, 21.952768122000066],
              [105.77252452200004, 21.953744094000079],
              [105.77128683100004, 21.95449642800007],
              [105.76958541500005, 21.955269274000067],
              [105.76854110300006, 21.956136477000051],
              [105.76748867800002, 21.957705710000042],
              [105.76613697800009, 21.960763945000039],
              [105.76508654400004, 21.962157650000066],
              [105.76242047300008, 21.965027102000079],
              [105.75889693800003, 21.968853286000112],
              [105.75848065500006, 21.970043458000085],
              [105.75846091700002, 21.971748455000025],
              [105.75925704200007, 21.975822685000068],
              [105.75969442100003, 21.97749948000007],
              [105.76092812500005, 21.980200814000028],
              [105.76153929800009, 21.982043296000064],
              [105.76166846500011, 21.983028337000029],
              [105.76163283300009, 21.986110367000045],
              [105.76148471, 21.9867647],
              [105.76077442600004, 21.987413378000028],
              [105.75878865600005, 21.98896736800009],
              [105.75651720500005, 21.990911950000047],
              [105.75446108600001, 21.992465173000042],
              [105.754103641, 21.992986221000073],
              [105.75386772000012, 21.995148036000067],
              [105.75333167000008, 21.998946325000098],
              [105.75294675500004, 22.000000165000074],
              [105.75196044500005, 22.00122702700007],
              [105.75128795800001, 22.002896033000049],
              [105.75076632400001, 22.005632961000018],
              [105.75036545600005, 22.012027327000091],
              [105.74969646000004, 22.013391642],
              [105.7462341630001, 22.016251102000069],
              [105.74098645800004, 22.018178349000017],
              [105.73853206600009, 22.018610449000064],
              [105.73510695100002, 22.018270955000041],
              [105.73152928400003, 22.017015799000106],
              [105.73006164600011, 22.016848496000115],
              [105.72907846000003, 22.01714315500007],
              [105.72675869600002, 22.020013946000105],
              [105.72461109500006, 22.022124770000048],
              [105.72148738400006, 22.023920887000067],
              [105.71985105000002, 22.02420881600009],
              [105.7182182920001, 22.024192088000035],
              [105.71561308700002, 22.023556019000075],
              [105.71284461000002, 22.022918232000066],
              [105.70938972400006, 22.023044602000041],
              [105.70643130600007, 22.023635878000022],
              [105.70384839200005, 22.024675089000119],
              [105.70163885000002, 22.026339863000111],
              [105.698264721, 22.029857754000126],
              [105.69613547100005, 22.032766734000027],
              [105.69457471900004, 22.035599507000114],
              [105.69331328200003, 22.037888940000094],
              [105.69167895900009, 22.03920425500003],
              [105.68870433200007, 22.041127368000076],
              [105.68038114400011, 22.044415833000031],
              [105.6727209220001, 22.047977358000104],
              [105.67042953600004, 22.04848628200002],
              [105.66937904600007, 22.048741741000107],
              [105.66814134900008, 22.048728783000016],
              [105.66700421200005, 22.048272790000034],
              [105.6659655, 22.04755138900012],
              [105.66184291600004, 22.042001536000022],
              [105.66109737800008, 22.040661459000077],
              [105.66083001800007, 22.039148766000061],
              [105.66039050000003, 22.036124355000105],
              [105.66006123600006, 22.031857627000036],
              [105.65963950400007, 22.030964811000061],
              [105.65829701900002, 22.029769235000082],
              [105.65326038800001, 22.027353283000117],
              [105.65055917500007, 22.026312111000031],
              [105.64802863300012, 22.02611660700002],
              [105.64495335400005, 22.026084099000087],
              [105.63978229400003, 22.027295199000015],
              [105.63896824500009, 22.0272865680001],
              [105.63806579800011, 22.027108211],
              [105.63717057200003, 22.026339200000052],
              [105.63584474800011, 22.0237934],
              [105.63388582800012, 22.021240851000101],
              [105.63244899500008, 22.020381657000065],
              [105.63166302200005, 22.020095245000078],
              [105.62859171900004, 22.01988331400004],
              [105.61966708100005, 22.019160750000118],
              [105.61871102500008, 22.018792060000081],
              [105.61766664, 22.017795096000015],
              [105.61663331000003, 22.015902060000059],
              [105.61540570100007, 22.014186170000102],
              [105.61406882200004, 22.013544516000039],
              [105.60812408400002, 22.012674163000057],
              [105.60571967800004, 22.012917168000087],
              [105.60099690000007, 22.014210604000084],
              [105.60032459000004, 22.014203358000103],
              [105.59984549100002, 22.014108574000076],
              [105.59936971700009, 22.013744974000105],
              [105.59861470800006, 22.012661409000067],
              [105.59757379700008, 22.011395514000043],
              [105.59633303400001, 22.01075478800005],
              [105.5910741380001, 22.008815947000038],
              [105.58839503200002, 22.007980361000087],
              [105.58563891200006, 22.005620373000042],
              [105.58422515500007, 22.003454154000046],
              [105.5839560540001, 22.001927689000041],
              [105.58398177400009, 22.000000162000077],
              [105.58360625900011, 21.998860207000078],
              [105.5828095860001, 21.996993383000074],
              [105.58086765900001, 21.993037303000037],
              [105.57984500100011, 21.990512157000097],
              [105.57778881600007, 21.98633614000007],
              [105.57768673900006, 21.985132649000057],
              [105.57781342800007, 21.984368879000016],
              [105.57852569000002, 21.98361147800005],
              [105.57958929500009, 21.982857896000048],
              [105.58006594600002, 21.982207235000125],
              [105.58007550900001, 21.981442186000066],
              [105.57996249200006, 21.981113030000095],
              [105.57717912000007, 21.978896579000029],
              [105.57636892000001, 21.978122599000116],
              [105.57591688700009, 21.976805976000065],
              [105.57605178700005, 21.975386422000028],
              [105.57665922200005, 21.973644086000022],
              [105.578111022, 21.969943348000065],
              [105.57847737300003, 21.968744916000027],
              [105.57850744300002, 21.966340406000114],
              [105.5778212220001, 21.96502119900012],
              [105.57735690300004, 21.964688216000106],
              [105.57665429100008, 21.964680566000091],
              [105.57571061699998, 21.965216842000054],
              [105.57452452100009, 21.966406345000067],
              [105.57334115100005, 21.967377245000108],
              [105.57216599100006, 21.967692364000065],
              [105.57134625100009, 21.967683420000036],
              [105.57076484000004, 21.967349138000088],
              [105.57077032500005, 21.966911950000068],
              [105.57091074000003, 21.96505519],
              [105.57289753100011, 21.959502536000031],
              [105.57290278800011, 21.959083075000052],
              [105.5727943750001, 21.958767251000033],
              [105.57201186600001, 21.958444082000113],
              [105.56211132200009, 21.956979164000053],
              [105.56056927900005, 21.956955503000053],
              [105.55865668800007, 21.957144284000037],
              [105.55516060700009, 21.958154707000062],
              [105.55377108600004, 21.958964590000015],
              [105.55185737400006, 21.961684296000072],
              [105.55049056900005, 21.963137559000053],
              [105.54913437200001, 21.96375189700008],
              [105.54665329400007, 21.964458713000035],
              [105.54350073000009, 21.964948328000041],
              [105.54057552400008, 21.965230648000045],
              [105.53832577200009, 21.965415536000066],
              [105.53663244900005, 21.966026065000044],
              [105.53380488600004, 21.96746305100001],
              [105.53157914400001, 21.96842995800008],
              [105.53097994400008, 21.968690256000031],
              [105.53018275200003, 21.969520434000067],
              [105.52926382300011, 21.971083401000065],
              [105.52513049000007, 21.977959341000108],
              [105.52362687000009, 21.98129863200009],
              [105.52271185700005, 21.982546953000082],
              [105.52146108400004, 21.983686656000053],
              [105.51478040500007, 21.987597392000069],
              [105.50866939700009, 21.990885040000038],
              [105.50415165800004, 21.9926172730001],
              [105.50335559400007, 21.993342451000061],
              [105.50312003300003, 21.994178793000074],
              [105.503338677, 22.000000158000077],
              [105.50369320400003, 22.006687063000108],
              [105.50366602800008, 22.008794403000117],
              [105.50294831100004, 22.009708441000072],
              [105.50096349300003, 22.010344802000056],
              [105.49870483900003, 22.010319436000088],
              [105.49453448300002, 22.010636955000074],
              [105.49418240700003, 22.010663756000071],
              [105.49275198100007, 22.012096640000053],
              [105.49202054800003, 22.014064299000054],
              [105.49184354100004, 22.016828536000062],
              [105.49151243900009, 22.022193511000054],
              [105.49130736700009, 22.025516285],
              [105.49057586900005, 22.027483879000087],
              [105.49002736800006, 22.028477043000102],
              [105.48927451200011, 22.029840210000096],
              [105.48810575800005, 22.032856624000097],
              [105.48748459800007, 22.037196384000076],
              [105.48744527300002, 22.040225493000065],
              [105.48755055500001, 22.042992779000059],
              [105.48766268900006, 22.045233248000073],
              [105.48607707100005, 22.047717990000045],
              [105.48392664200003, 22.050196318000111],
              [105.47784188, 22.055472480000034],
              [105.47495791700003, 22.058325808000042],
              [105.47143096600006, 22.06301880900012],
              [105.46889241200007, 22.067838378000104],
              [105.46738009200008, 22.069899063000072],
              [105.46199324200006, 22.074801529000077],
              [105.46136230399999, 22.075717822000037],
              [105.46135321700004, 22.076410335000048],
              [105.46195841900003, 22.077456160000075],
              [105.46538759400008, 22.080265652],
              [105.46636110900006, 22.081546511000028],
              [105.4669572870001, 22.083284815000056],
              [105.46693008700007, 22.085362320000087],
              [105.46639721600012, 22.088242091000062],
              [105.46635620400011, 22.089649827000102],
              [105.46692988500004, 22.090941337000025],
              [105.46896425600006, 22.093442652000036],
              [105.47245220700009, 22.097704329000045],
              [105.472442614, 22.098438481000038],
              [105.47183889100006, 22.099441249000051],
              [105.46993044800007, 22.102356642000039],
              [105.46873135000007, 22.103719758000025],
              [105.4678419260001, 22.103984999000126],
              [105.46695489200008, 22.104066700000047],
              [105.46409936400006, 22.104125989000067],
              [105.46330354300002, 22.104759411000074],
              [105.46228317500007, 22.10750125],
              [105.46176454200005, 22.109514543000053],
              [105.46162276200005, 22.112817050000011],
              [105.46198046300007, 22.116456259000117],
              [105.46273253300004, 22.118774927000025],
              [105.46426223900004, 22.121467193000029],
              [105.46798970700007, 22.125643408000073],
              [105.46863535400003, 22.126137076000063],
              [105.47006342400006, 22.126639627000074],
              [105.47279393500003, 22.127278527],
              [105.47382604900008, 22.128141307000071],
              [105.47419979000009, 22.129482946000053],
              [105.47417597599998, 22.131306390000113],
              [105.47373871700005, 22.134827279000021],
              [105.47317896, 22.137738845000051],
              [105.47329666800006, 22.138712817000084],
              [105.47393443300007, 22.139814262000087],
              [105.4808800640001, 22.147309160000091],
              [105.484369691, 22.149780123000085],
              [105.49008443800007, 22.151668202000074],
              [105.49073022700003, 22.152161779000053],
              [105.491112027, 22.152895534000017],
              [105.49122351700008, 22.154355698000039],
              [105.49106628900003, 22.156420708000013],
              [105.49104263100003, 22.158244055000061],
              [105.48998503400001, 22.159326316000083],
              [105.48657977000008, 22.160382104000043],
              [105.47596135400009, 22.16439559100003],
              [105.47465389500009, 22.164623925000093],
              [105.47335438600004, 22.164244477000047],
              [105.4694669940001, 22.162255203000122],
              [105.46820216900007, 22.161704490000119],
              [105.4661146880001, 22.163802725],
              [105.46269315000006, 22.168411877000068],
              [105.460588296, 22.171823409000076],
              [105.45980155400008, 22.173936361000109],
              [105.45967589100007, 22.175248494000051],
              [105.45977548000006, 22.177768158000077],
              [105.45990953300006, 22.178801461000127],
              [105.46012190400003, 22.179491731000041],
              [105.46084818900009, 22.180394210000109],
              [105.46179319100003, 22.181505532000045],
              [105.46193718900004, 22.181782316000032],
              [105.46200376600004, 22.182333352000057],
              [105.46177061800003, 22.183224896000105],
              [105.45881269000007, 22.189244201000058],
              [105.45820595400005, 22.190475387000049],
              [105.45738865500002, 22.19087876900003],
              [105.45679819000001, 22.190872033000097],
              [105.45635715100011, 22.190729436000112],
              [105.45533199600003, 22.190098679000059],
              [105.45423847900005, 22.189054440000078],
              [105.45372544600005, 22.188773440000098],
              [105.45320880000003, 22.18876753800005],
              [105.45231677800004, 22.189238836000058],
              [105.45075504800005, 22.190115168000091],
              [105.44926426600006, 22.191197317000011],
              [105.44733454900009, 22.193418187000091],
              [105.44552210000006, 22.196348678000085],
              [105.44293547400008, 22.200332697000036],
              [105.43921341800005, 22.204182569000075],
              [105.43782661800006, 22.205616929000094],
              [105.43398223000003, 22.209402257000121],
              [105.43098986700006, 22.212509959000037],
              [105.42990081200003, 22.215148594000041],
              [105.42987209200004, 22.217308481000053],
              [105.43007633000006, 22.217801790000102],
              [105.4314410970001, 22.218210282000058],
              [105.43227895300006, 22.218612696000051],
              [105.43269266200009, 22.219206605000075],
              [105.43310376500008, 22.219996873000092],
              [105.43329888800007, 22.221177404000066],
              [105.43386342500006, 22.22628978900012],
              [105.4339518670001, 22.227567266000037],
              [105.43414569900006, 22.228845961000054],
              [105.43497317400005, 22.230033746000096],
              [105.43611944400003, 22.231028814000098],
              [105.43822074700007, 22.231543917000081],
              [105.43905867500004, 22.231946295000071],
              [105.43937094400003, 22.232244446000045],
              [105.43946462900006, 22.233129214000066],
              [105.43923173200005, 22.23479573900002],
              [105.43788892700007, 22.240671549000091],
              [105.43634855200007, 22.248235053000087],
              [105.43571544200009, 22.252193345000038],
              [105.43501594100003, 22.256105636000086],
              [105.43425203100006, 22.260377936000083],
              [105.43453393200012, 22.261012052000062],
              [105.43510609200003, 22.261649504000047],
              [105.43674258699998, 22.262299190000064],
              [105.44821141500009, 22.265855425000055],
              [105.44975242400004, 22.266413791000069],
              [105.45051817100007, 22.267053410000059],
              [105.45184546300003, 22.269141414000067],
              [105.45232570000006, 22.269417273000066],
              [105.45329330600001, 22.269428324],
              [105.454266847, 22.268988826000054],
              [105.45602158200006, 22.268017489000066],
              [105.45982127400003, 22.266078085000096],
              [105.460887986, 22.265909982000039],
              [105.46233345100006, 22.266377049000049],
              [105.46491401700005, 22.268839730000067],
              [105.47055091000007, 22.274401204000057],
              [105.47073854000006, 22.274853939000039],
              [105.47073382200001, 22.275214377000019],
              [105.47033614500006, 22.276020956000053],
              [105.46827813000004, 22.277980287000076],
              [105.46505651500003, 22.28010659600006],
              [105.46155163500003, 22.281688914000064],
              [105.45998797000006, 22.282842678000044],
              [105.45890812700006, 22.284001950000039],
              [105.4589954090001, 22.284723897000092],
              [105.45918420700008, 22.285086533000076],
              [105.45966449500003, 22.285362367000069],
              [105.46198220000004, 22.285749227000018],
              [105.46806918400009, 22.286539363000109],
              [105.47340732300006, 22.28800113000009],
              [105.48880809000006, 22.294268408000036],
              [105.4895963960001, 22.295020367000085],
              [105.48958481000003, 22.295911940000046],
              [105.48829260700005, 22.297086332000049],
              [105.48443330100011, 22.299272086000066],
              [105.47928356300008, 22.302483505000033],
              [105.47749692400005, 22.304841152000066],
              [105.47715248800006, 22.30676924200008],
              [105.47713114000007, 22.308403748000046],
              [105.47726163500003, 22.310634423000053],
              [105.47864571500006, 22.314662590000097],
              [105.48083954300003, 22.317808205000041],
              [105.48353165200002, 22.319473296000055],
              [105.48496429800008, 22.319786673000053],
              [105.48784121200001, 22.31952186200008],
              [105.49007772600004, 22.319398431000081],
              [105.49294111600008, 22.320173672000095],
              [105.4943680100001, 22.320932746000018],
              [105.49514681200006, 22.322427588000068],
              [105.49545447600012, 22.323322694000055],
              [105.49573325400009, 22.326446589000071],
              [105.49630816200005, 22.331357071000113],
              [105.49641765900006, 22.335067322000057],
              [105.49609874200004, 22.337352519000042],
              [105.49460539000003, 22.340970849000108],
              [105.49458792600009, 22.342316977000017],
              [105.49572745800006, 22.343676088000031],
              [105.49817040100004, 22.344915169000053],
              [105.50076321800007, 22.345752013000016],
              [105.50798068300003, 22.346909773000064],
              [105.51086457000008, 22.347615117000075],
              [105.51200772600008, 22.348704905000091],
              [105.51285469200008, 22.350329902000077],
              [105.51335000400012, 22.356797606],
              [105.51483011500001, 22.365430196000077],
              [105.51494184800002, 22.367989313000081],
              [105.51404625100008, 22.370133334000016],
              [105.51286480800012, 22.372004906000065],
              [105.51211729500008, 22.373881303000061],
              [105.51208433900011, 22.376438763000102],
              [105.512350424, 22.378200667000044],
              [105.51339324300005, 22.380541946000051],
              [105.51477941399999, 22.383996390000057],
              [105.51523481600002, 22.385665492000051],
              [105.51696521400001, 22.399440474000087],
              [105.51706588500008, 22.400883707000041],
              [105.51752705300005, 22.402109080000088],
              [105.51941599000003, 22.403572172000082],
              [105.52083520000012, 22.404475383000118],
              [105.53223475500008, 22.40815144900008],
              [105.53401739100005, 22.40861486000005],
              [105.53580570000004, 22.408634606000085],
              [105.53796016100004, 22.4079928],
              [105.54502237600005, 22.405851976000065],
              [105.55519393300008, 22.402968474000048],
              [105.55769608600008, 22.403106798000039],
              [105.55912109100004, 22.403566101000116],
              [105.56030065000003, 22.404577366000034],
              [105.56287303500005, 22.408598935000065],
              [105.56742620000003, 22.416302652000027],
              [105.56941811399999, 22.419097524000087],
              [105.56940973300001, 22.419762992000081],
              [105.56916708900009, 22.420093129000037],
              [105.56845032100003, 22.420196248000046],
              [105.56726083700002, 22.419961444000066],
              [105.56595632100009, 22.419392591000076],
              [105.56182819800003, 22.415797874000098],
              [105.55818273000003, 22.411764621000074],
              [105.55713721300003, 22.41134711800008],
              [105.55681526300003, 22.411336247000065],
              [105.55382511600007, 22.412118946000078],
              [105.55241354300009, 22.412837376000105],
              [105.55082356400007, 22.413798473000043],
              [105.54993264800007, 22.414930315000078],
              [105.54948305000008, 22.415822371000083],
              [105.549005483, 22.418915788000056],
              [105.54863108400011, 22.420787171000086],
              [105.54893772600008, 22.421466320000057],
              [105.54915654700007, 22.422627490000124],
              [105.55029571000009, 22.424885100000047],
              [105.55166477500003, 22.427434901000076],
              [105.55203287500002, 22.429104642000119],
              [105.55202277100008, 22.429901177000119],
              [105.55122592400005, 22.43134087200005],
              [105.54797437000005, 22.436012578000053],
              [105.54709317400005, 22.437958266000102],
              [105.54708949200001, 22.438247906000086],
              [105.547240593, 22.438611663000074],
              [105.54778370400007, 22.438762472000029],
              [105.54918502200012, 22.438777856000058],
              [105.55385514000008, 22.438901481000073],
              [105.55423980900011, 22.4392678],
              [105.55446417800002, 22.43999446],
              [105.5549836750001, 22.443268597],
              [105.55528796300005, 22.447730463000056],
              [105.55578205299999, 22.454974913000036],
              [105.55718992700004, 22.461279214000029],
              [105.55771652600001, 22.462416963000109],
              [105.55851677000004, 22.463306147000047],
              [105.566272973, 22.470308462000062],
              [105.57567625300007, 22.477669721000026],
              [105.58795517800004, 22.486231805000045],
              [105.59107541700011, 22.487934600000067],
              [105.59465277700008, 22.488974619000054],
              [105.59547558100003, 22.489353942],
              [105.59571933700003, 22.48982065400007],
              [105.59571205600005, 22.490404762000082],
              [105.59497951300003, 22.49156525500009],
              [105.59342881800006, 22.493551469000025],
              [105.59232371200001, 22.495792840000028],
              [105.59120086600009, 22.499452716000064],
              [105.59045064700011, 22.509208623000099],
              [105.59044230800004, 22.509876153000086],
              [105.59079625500001, 22.51084843800006],
              [105.59131321500007, 22.512006748000069],
              [105.59462713600008, 22.516159329000018],
              [105.5956642150001, 22.518228917000016],
              [105.59599486500009, 22.520126212000058],
              [105.59597844600007, 22.521443419000065],
              [105.59354738500009, 22.531709172000092],
              [105.59307679300007, 22.533927126000016],
              [105.59162473100008, 22.53671084000004],
              [105.5903518150001, 22.539331779000094],
              [105.58960002300007, 22.542781654000073],
              [105.58886570400007, 22.544832042000081],
              [105.58615694200003, 22.548919392000052],
              [105.58348113500006, 22.551612833000064],
              [105.58019374800001, 22.555306465000058],
              [105.57659579800004, 22.559140083000081],
              [105.57364206700007, 22.560829183000038],
              [105.57070093200008, 22.561514378000069],
              [105.56499104400007, 22.561452243000076],
              [105.56360033200011, 22.561580516000035],
              [105.562819663, 22.562289147000044],
              [105.56202629500008, 22.564001630000071],
              [105.56089885800006, 22.567718427000024],
              [105.55837878500009, 22.571706841000072],
              [105.55602566300007, 22.574693048000057],
              [105.55135198800008, 22.578084075000056],
              [105.54822152600006, 22.581491903000114],
              [105.54617315100005, 22.584768132000121],
              [105.54568275200006, 22.58691406500013],
              [105.54549736200008, 22.589350177000078],
              [105.54546449500003, 22.59193137000004],
              [105.54573850000006, 22.594659336000063],
              [105.54692400900009, 22.598544618000105],
              [105.54721629200003, 22.599838578000053],
              [105.54705098400002, 22.600697264000047],
              [105.54564355300002, 22.602115981000011],
              [105.5337603790001, 22.613888597000106],
              [105.52844216400007, 22.619279510000112],
              [105.5274957190001, 22.620846564000097],
              [105.52747548300002, 22.62242385200004],
              [105.52787053700011, 22.627734357000051],
              [105.52978435800001, 22.634619764000071],
              [105.53005163600001, 22.634562332000115],
              [105.53542338700002, 22.634648837000078],
              [105.53790507900001, 22.63583569400005],
              [105.53913556700005, 22.637240656000031],
              [105.53928751700009, 22.64489507600009],
              [105.53946611700003, 22.65046259700005],
              [105.54043519900002, 22.652792213000069],
              [105.54314909100003, 22.655372879000076],
              [105.54636832900012, 22.657495268],
              [105.5491118620001, 22.657757239000098],
              [105.55634672100008, 22.658300182000076],
              [105.55907853800008, 22.659489471000072],
              [105.56201605200003, 22.664159324000096],
              [105.56349659700005, 22.665566796000022],
              [105.566469457, 22.667454257000053],
              [105.57120194100006, 22.668433244000049],
              [105.57893666200002, 22.668980874000063],
              [105.58212011500007, 22.668867574000018],
              [105.5839334830001, 22.668803018000091],
              [105.59318960000007, 22.66751147800008],
              [105.59768120100009, 22.667791648000076],
              [105.59899521200001, 22.66843958200004],
              [105.60008857800005, 22.670001277000026],
              [105.60005961900006, 22.672325912000076],
              [105.59912530600012, 22.680323926000057],
              [105.59852064100009, 22.68419224300002],
              [105.59849810100009, 22.686000238000048],
              [105.59876985200002, 22.686519792000048],
              [105.59930371200005, 22.688333740000033],
              [105.61391694700008, 22.699080950000017],
              [105.61946522700003, 22.700431607000034],
              [105.62391664900011, 22.700478926000045],
              [105.64033763600005, 22.700136096],
              [105.642976494, 22.700101916000023],
              [105.64497690700004, 22.700696456000102],
              [105.646189475, 22.702573013000027],
              [105.65238785900003, 22.713534035000023],
              [105.65726154500004, 22.719176321000042],
              [105.6645167070001, 22.725445117000028],
              [105.6667364580001, 22.7259848630001],
              [105.67119473000011, 22.725514713000017],
              [105.67683297700006, 22.722821079000077],
              [105.68304489500005, 22.720018236000072],
              [105.68691260700007, 22.719484791000077],
              [105.68891851800004, 22.719648887000062],
              [105.68983480300008, 22.720518527000074],
              [105.69011100100002, 22.723245263000088],
              [105.69060064900003, 22.724467331000042],
              [105.69161068600002, 22.726988130000066],
              [105.69282042900002, 22.72915101400006],
              [105.69343304700008, 22.729587409000104],
              [105.69466857800009, 22.729600141],
              [105.69591953600012, 22.728322782000099],
              [105.697369495, 22.72332007100006],
              [105.69785335900008, 22.721604702000064],
              [105.69879024900006, 22.720754163000031],
              [105.69940455600008, 22.72104720800003],
              [105.70138140700004, 22.723648035000018],
              [105.70883793600007, 22.733042918000109],
              [105.70991222400005, 22.733627346000063],
              [105.71114778200004, 22.733639987000039],
              [105.71765314100007, 22.732129486000119],
              [105.71904143000009, 22.732286997000095],
              [105.71995790100007, 22.733156489000095],
              [105.72284488400003, 22.737199906000072],
              [105.72467623300003, 22.739082172000025],
              [105.72712710700004, 22.740827354000089],
              [105.73005152300007, 22.741717171000083],
              [105.73793022300003, 22.741653590000062],
              [105.73978868300006, 22.741242299000049],
              [105.74103437300001, 22.740394739000088],
              [105.74339480300009, 22.736691280000088],
              [105.74420109500001, 22.735194665000066],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 5, code: "AD01", ten_tinh: "Bạc Liêu" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.32590852100006, 9.600039486000076],
              [105.32754696200001, 9.599981427000069],
              [105.32863614800007, 9.600355078000076],
              [105.33115134300007, 9.604781129000093],
              [105.33583581000006, 9.610833479000023],
              [105.33771193800004, 9.612999310000079],
              [105.33894512300003, 9.6137423870001],
              [105.34027832200012, 9.613759343000082],
              [105.341250632, 9.612606043000048],
              [105.34044927200004, 9.607537788000096],
              [105.34137790000005, 9.602744423000063],
              [105.3420676850001, 9.598449297000043],
              [105.34265064600002, 9.595408129000036],
              [105.34302810700008, 9.594247294000089],
              [105.3443046790001, 9.593635866000037],
              [105.34611680000002, 9.593658867000075],
              [105.349650427, 9.593703673000052],
              [105.35553981100004, 9.593778218000091],
              [105.35868796300011, 9.593697244000053],
              [105.35995915700003, 9.593654717000087],
              [105.36065193000007, 9.593915781000105],
              [105.36451352100002, 9.595370914000066],
              [105.36960361700008, 9.597721415000125],
              [105.3706869010001, 9.59804886700004],
              [105.372263608, 9.596911101000048],
              [105.37281311200007, 9.596514568000101],
              [105.37463731000003, 9.593839464],
              [105.37529496500008, 9.59200962600004],
              [105.37552642600005, 9.588067378000085],
              [105.37753853000008, 9.586613189000024],
              [105.37994065500006, 9.58655362600007],
              [105.38572445300007, 9.587791611000094],
              [105.39112945199999, 9.589049220000055],
              [105.39340590800005, 9.589456571000097],
              [105.39475194300005, 9.590504469000026],
              [105.39604588500008, 9.592089679000091],
              [105.3974683930001, 9.594259302000106],
              [105.39771983300002, 9.595876368000054],
              [105.39701762200011, 9.597660902000092],
              [105.39672993600001, 9.598912617000098],
              [105.39717448900002, 9.599590633000064],
              [105.39762469800006, 9.599820388000067],
              [105.40322971300002, 9.601226955000096],
              [105.40675558200007, 9.601898355000047],
              [105.41020837900003, 9.602413002000086],
              [105.41295356700006, 9.602950828000088],
              [105.41433550000009, 9.603318579000051],
              [105.41482192200002, 9.603101263000012],
              [105.41542119500004, 9.602833528000064],
              [105.41608351600004, 9.601450563000034],
              [105.41566946300006, 9.599027110000067],
              [105.41460543300005, 9.595276554000074],
              [105.41531428100006, 9.594144666000124],
              [105.41639942000006, 9.593979637000059],
              [105.41860744600005, 9.595106137000096],
              [105.42345144200002, 9.598683418000052],
              [105.42653182600007, 9.601139676000068],
              [105.42807616200004, 9.602038072000061],
              [105.42967241400004, 9.602254127],
              [105.43454973100009, 9.599699233000079],
              [105.43778187900008, 9.598907272000099],
              [105.44056514100008, 9.598453834000063],
              [105.44300721700007, 9.598035815000026],
              [105.44523723800002, 9.597403529000113],
              [105.44631480000001, 9.595664797000046],
              [105.44664588300004, 9.592179623000073],
              [105.44669000200005, 9.588619756000048],
              [105.44780576500008, 9.587989725000055],
              [105.44914273400005, 9.588151221000095],
              [105.45360659500008, 9.591394471000035],
              [105.454568416, 9.591706510000096],
              [105.45528888800001, 9.591644073000081],
              [105.45626490900003, 9.591426818000047],
              [105.45710103600003, 9.590597986000125],
              [105.45849284500008, 9.588621083000069],
              [105.45935237000005, 9.5878646],
              [105.46030709000009, 9.587812063000099],
              [105.46106283900008, 9.588162275000032],
              [105.46629597400009, 9.591991322000036],
              [105.46695281500008, 9.592802595000103],
              [105.46706739500006, 9.593638457000074],
              [105.46712096500005, 9.595134492000044],
              [105.46731766500007, 9.597272108000075],
              [105.46789566900003, 9.597971348000021],
              [105.46906214200008, 9.597985538000041],
              [105.470504996, 9.596964549000029],
              [105.47411856100003, 9.593794941000054],
              [105.47586827600003, 9.591964642000049],
              [105.47610244700005, 9.591251791000042],
              [105.47603051400004, 9.590471232000118],
              [105.47351670700006, 9.587574544000075],
              [105.47330105900006, 9.586263873000076],
              [105.47361611200003, 9.58575295600008],
              [105.47422392900005, 9.585452392000081],
              [105.48118022200006, 9.584437149000012],
              [105.48749862900007, 9.583324251000123],
              [105.48842223100011, 9.58349965000007],
              [105.49071479700008, 9.586090646000043],
              [105.49360925600003, 9.589894083000059],
              [105.49465207300007, 9.590540260000056],
              [105.49576597800004, 9.590987733000043],
              [105.49812059600001, 9.591550943000103],
              [105.50120690800001, 9.59234931800008],
              [105.502397, 9.592294304000058],
              [105.50320611000005, 9.591923219000085],
              [105.50345995300009, 9.591224563000099],
              [105.50327929400007, 9.590066691000041],
              [105.50222850400007, 9.588416892000049],
              [105.502347872, 9.587690217000054],
              [105.5027569800001, 9.587114072000079],
              [105.50827969300008, 9.585201435000011],
              [105.50933235500005, 9.585433743000047],
              [105.51094043900005, 9.585672792000109],
              [105.51447662700008, 9.587253744000089],
              [105.51689934400007, 9.589041260000018],
              [105.51888304200007, 9.590383856000083],
              [105.520179064, 9.593477047000041],
              [105.52060226200003, 9.595240823000049],
              [105.52286905600009, 9.596146995000064],
              [105.52330517200002, 9.596155081000022],
              [105.52853473400009, 9.596214018000103],
              [105.53049095200004, 9.596737955000021],
              [105.53233493500002, 9.597731770000094],
              [105.53352658400011, 9.59850187300013],
              [105.53440499500005, 9.598080202000057],
              [105.53507154900005, 9.597116006000038],
              [105.53785590700004, 9.592504561000046],
              [105.5400711720001, 9.58983051700004],
              [105.54134974200011, 9.587769219000018],
              [105.54226410299999, 9.586263058],
              [105.54381931300006, 9.586281327000011],
              [105.54458026400005, 9.587162551000093],
              [105.54545308300007, 9.586301770000103],
              [105.54859082000007, 9.58012973900013],
              [105.55274407600007, 9.570745818000058],
              [105.55316545400004, 9.568295208000064],
              [105.55333094100004, 9.565422605000061],
              [105.55383477099998, 9.561366725000118],
              [105.55340235200001, 9.556974436000052],
              [105.55271529900008, 9.552920456],
              [105.55237120300004, 9.55013233700006],
              [105.55095365500009, 9.541814047000051],
              [105.54957470700003, 9.530580296000045],
              [105.54869365300004, 9.522539228000058],
              [105.54746005500003, 9.511279758000056],
              [105.54719993, 9.508154373000098],
              [105.54499591399998, 9.48589708300007],
              [105.54342136200009, 9.478391283000052],
              [105.54239465500005, 9.474464501000044],
              [105.54229963900009, 9.473307339000046],
              [105.54213455800001, 9.471296861000054],
              [105.54307186000004, 9.452078610000035],
              [105.55364618600001, 9.440339550000052],
              [105.57647072100008, 9.414613008000037],
              [105.57712957600006, 9.413998221000062],
              [105.57872421100011, 9.412384008000084],
              [105.58636757800005, 9.42616040400006],
              [105.586829693, 9.425814466000036],
              [105.58911211500003, 9.424481186000032],
              [105.59136019000005, 9.423217967000051],
              [105.59246277300007, 9.422536864000096],
              [105.59337332200008, 9.421997595000063],
              [105.594056161, 9.42154360900007],
              [105.59473920700003, 9.421216979000045],
              [105.59623786600004, 9.423124838000073],
              [105.59750131200005, 9.42489158700006],
              [105.59950643700003, 9.423161757000019],
              [105.60099917100008, 9.421630891000097],
              [105.60134034500007, 9.421316856000049],
              [105.601143119, 9.420415159000049],
              [105.60086309500011, 9.419667830000032],
              [105.60048759400004, 9.418846649000033],
              [105.60159161000007, 9.417706287],
              [105.60347020600008, 9.415534620000086],
              [105.60371889600006, 9.415222869000049],
              [105.61081486800001, 9.409224753000077],
              [105.61310479100011, 9.407921568000075],
              [105.61411693500001, 9.408143530000055],
              [105.61457516200009, 9.408371894000121],
              [105.61512312200004, 9.408644976000067],
              [105.61522629600006, 9.409124201000017],
              [105.6152806100001, 9.409853805000077],
              [105.61515497200001, 9.411283280000047],
              [105.61531998000008, 9.41197639600002],
              [105.61666246100005, 9.414125019000073],
              [105.61835974100006, 9.415811387000071],
              [105.61876585800009, 9.415747821000052],
              [105.62210201500008, 9.414397808000084],
              [105.62315848800009, 9.414084797000031],
              [105.62757646700007, 9.412987840000129],
              [105.63150470400009, 9.411678938000099],
              [105.63492085100005, 9.410710527000086],
              [105.63679975200007, 9.410197708000021],
              [105.63900241000007, 9.407618245000025],
              [105.639496482, 9.406682471000048],
              [105.63955734600006, 9.40578162300003],
              [105.63960628300003, 9.405057371000057],
              [105.639351526, 9.403853445],
              [105.63900871400006, 9.403288019000032],
              [105.63844147200011, 9.402231145000071],
              [105.63789956600004, 9.401541519000121],
              [105.63534639400008, 9.397847321000084],
              [105.63727267200004, 9.396243944000025],
              [105.63761418000004, 9.396101815000078],
              [105.64054598400007, 9.395134247000046],
              [105.64114398400008, 9.395076553000068],
              [105.6422264170001, 9.395159500000093],
              [105.64319555500001, 9.395582273000079],
              [105.64539034300006, 9.396512237000108],
              [105.64735180300008, 9.394499204000082],
              [105.64810502800005, 9.393677064000093],
              [105.64824038100005, 9.389884338000075],
              [105.64832423900006, 9.389035134000066],
              [105.64846617000009, 9.388780148000107],
              [105.65220840100001, 9.38718835700003],
              [105.654029929, 9.386534045000071],
              [105.65459937700004, 9.386448079000063],
              [105.65739279400007, 9.387688185000085],
              [105.6588327150001, 9.388562874000071],
              [105.65937421000005, 9.388759979000037],
              [105.662224565, 9.389971628000103],
              [105.66290821500007, 9.390026953000062],
              [105.66439275300007, 9.39064607400004],
              [105.66675319600006, 9.390876126000068],
              [105.66913921400004, 9.391032014000087],
              [105.67084255400002, 9.39131388500005],
              [105.67178187900002, 9.391129467000068],
              [105.67533163900001, 9.385743966000069],
              [105.67736212400007, 9.385118952000047],
              [105.680331521, 9.38511325300002],
              [105.68181723300009, 9.385612595000035],
              [105.686182882, 9.380016792000044],
              [105.68837311500002, 9.380703193000082],
              [105.68937029200002, 9.380899358000059],
              [105.69070917700004, 9.381066557000038],
              [105.69179129400004, 9.381007848000065],
              [105.69435356500004, 9.380550002000108],
              [105.69564962800007, 9.380119059000068],
              [105.69722950200006, 9.379243533000102],
              [105.69853857000001, 9.378839358000056],
              [105.69997344700008, 9.378628799000042],
              [105.70086513200005, 9.378557804000119],
              [105.70158987600006, 9.378625599000133],
              [105.70261380500004, 9.378904575000123],
              [105.70342170400001, 9.379219484000066],
              [105.70375707300005, 9.379597469000077],
              [105.70389887500005, 9.380005807000112],
              [105.7040614700001, 9.381199397000016],
              [105.70433796000006, 9.382923859000057],
              [105.705195008, 9.3882690940001],
              [105.70521799900004, 9.389075652000132],
              [105.7052917980001, 9.390363229000085],
              [105.70535043400008, 9.391183861000039],
              [105.70573294700002, 9.393730257000053],
              [105.70571321100007, 9.394133016000071],
              [105.70447220000007, 9.39667697000006],
              [105.70480159500005, 9.397934904000048],
              [105.70847733600007, 9.398762452000129],
              [105.71186570000003, 9.395442248000039],
              [105.71229897100008, 9.395202096000064],
              [105.712610286, 9.394933811000071],
              [105.71295807200008, 9.394550663000077],
              [105.71312951900006, 9.394208584000106],
              [105.71336990100005, 9.393124144000085],
              [105.71358453800005, 9.392768138000028],
              [105.71411238000012, 9.392354960000125],
              [105.71508764000006, 9.391724385000087],
              [105.71605701100007, 9.391262588000069],
              [105.71678521900002, 9.391155862000032],
              [105.71752079300003, 9.391227442000092],
              [105.72073174700006, 9.393711715000029],
              [105.72274906600009, 9.39404259900005],
              [105.72435085700002, 9.393926112000093],
              [105.72464768900007, 9.393860844000111],
              [105.725118213, 9.395171912000054],
              [105.72580356100005, 9.395990481000103],
              [105.72690967700008, 9.396281133000082],
              [105.72794086300004, 9.395986086000095],
              [105.72911821800002, 9.395104915000017],
              [105.73029726300007, 9.395029250000063],
              [105.73427858100005, 9.395753290000107],
              [105.73538454200008, 9.395970671000063],
              [105.73582797600008, 9.396555591000054],
              [105.73573161700001, 9.402117129000052],
              [105.73669023400002, 9.401662387000085],
              [105.7380523190001, 9.401016243000091],
              [105.73937184900004, 9.400750207000018],
              [105.7402636260001, 9.400900003000066],
              [105.740960171, 9.401701746000056],
              [105.74141242100002, 9.40334179800004],
              [105.74279990400007, 9.405459138000081],
              [105.74368403500004, 9.406309628000107],
              [105.74416047700002, 9.406643818000047],
              [105.74524961499999, 9.407536234000078],
              [105.74691981100007, 9.408118015000076],
              [105.74716612900002, 9.408196041000048],
              [105.75156028200007, 9.409469154000048],
              [105.75640883500004, 9.411127176000099],
              [105.75771353300009, 9.411452003000063],
              [105.75975133000003, 9.411732876000121],
              [105.76133260800003, 9.411309118000053],
              [105.76304388100004, 9.410907245000109],
              [105.76420950100004, 9.410796319000079],
              [105.76465395200005, 9.411062172000049],
              [105.765116096, 9.411055564000035],
              [105.76551832500006, 9.410975844000101],
              [105.77014142100003, 9.412349463000068],
              [105.77141966200003, 9.410761788000064],
              [105.77207247900003, 9.40972738],
              [105.7731938900001, 9.408069296000042],
              [105.77380321400005, 9.407254359000115],
              [105.77660660000002, 9.403504931000038],
              [105.77752395699999, 9.403702221000019],
              [105.77784440800012, 9.403847882000081],
              [105.77912820600002, 9.403868364000088],
              [105.78053358200005, 9.403321795000073],
              [105.78240555800004, 9.401764864000127],
              [105.78266103600004, 9.401517733000018],
              [105.775251633, 9.39484577900007],
              [105.77691394200005, 9.392446379000051],
              [105.780351706, 9.388302156000082],
              [105.78220985900001, 9.385894351000077],
              [105.78348160200008, 9.383950794000027],
              [105.78558633300005, 9.384254477000107],
              [105.78667523800009, 9.384209089000082],
              [105.78742535400006, 9.383704722000095],
              [105.7882942950001, 9.382803152000106],
              [105.78998648400005, 9.37991800500013],
              [105.79073962600002, 9.379129047000044],
              [105.7924676910001, 9.378236556],
              [105.79637955800011, 9.376684009000067],
              [105.80132658300006, 9.374743842000031],
              [105.807713871, 9.372021779000049],
              [105.81093155000005, 9.371087830000045],
              [105.8128216260001, 9.371107615000083],
              [105.81419682400012, 9.371065082000117],
              [105.8149974670001, 9.371187299000015],
              [105.81573601800002, 9.371764232000055],
              [105.81624365600001, 9.372509515000081],
              [105.816458287, 9.373877873000057],
              [105.81671814600001, 9.37638514200003],
              [105.81725656500006, 9.379635331000101],
              [105.81808620400005, 9.382433193000049],
              [105.81953570600008, 9.386205222000042],
              [105.81975156900009, 9.387459784000017],
              [105.81973591800003, 9.388939625000068],
              [105.81913361200003, 9.391722592000027],
              [105.81900038400002, 9.39348583300008],
              [105.81927715800001, 9.394399503],
              [105.82047345000004, 9.395038149000088],
              [105.82150511900005, 9.394991972000087],
              [105.82311441500006, 9.39449642900003],
              [105.82455786300004, 9.39342990000007],
              [105.82675075400002, 9.391915773000024],
              [105.82807061100007, 9.391701789000083],
              [105.82915833900009, 9.391770018000043],
              [105.82967086800005, 9.392059953000052],
              [105.83029197800002, 9.392920259000091],
              [105.83352993200005, 9.400980148000125],
              [105.83443511300007, 9.402071097000063],
              [105.83613868500007, 9.403511860000078],
              [105.83784705800011, 9.404497259000076],
              [105.83927856700006, 9.404568982000059],
              [105.84031446299998, 9.404124292000036],
              [105.84303659400007, 9.401306152000098],
              [105.84551822700004, 9.398101644000077],
              [105.84659322300008, 9.395746964000027],
              [105.84668286800004, 9.393869202000092],
              [105.84664191500012, 9.391085564000077],
              [105.84685850100001, 9.390461568000051],
              [105.84911282900001, 9.389162754000056],
              [105.85454534600008, 9.385252539000087],
              [105.85518417100009, 9.384424138000023],
              [105.85512283800009, 9.383588564000077],
              [105.85442703800007, 9.383163941000115],
              [105.85199176600001, 9.381677771000073],
              [105.85129960400012, 9.380905294000018],
              [105.85110117000004, 9.379789992000019],
              [105.85147153400004, 9.377845594000076],
              [105.852962088, 9.375912720000111],
              [105.855299976, 9.37329276600005],
              [105.85728559100006, 9.370877910000084],
              [105.85835525200011, 9.369010277000099],
              [105.85865335400007, 9.367273906000049],
              [105.85867358700006, 9.365325956000079],
              [105.85820159300009, 9.363581707],
              [105.85720136500002, 9.360662586000055],
              [105.85681717800003, 9.360994959000022],
              [105.85635342900011, 9.361103033000083],
              [105.85543461000003, 9.361323410000109],
              [105.85445722500008, 9.361370476],
              [105.85342654100006, 9.361017272000092],
              [105.85228569499999, 9.360206077000031],
              [105.85205782300005, 9.359963608000042],
              [105.85100051900005, 9.358838574000037],
              [105.85014789800003, 9.357931324000022],
              [105.84994470800009, 9.357739338000036],
              [105.84945557800003, 9.357351383],
              [105.84908330100006, 9.35705608400008],
              [105.84883590300011, 9.356886278000063],
              [105.84854755100005, 9.356700982000097],
              [105.84726693700006, 9.356113165000044],
              [105.84694423100008, 9.35598942700002],
              [105.84574009700002, 9.355600667000095],
              [105.84412676900011, 9.355082404000113],
              [105.84160093000003, 9.35427464800007],
              [105.840783765, 9.354018152000075],
              [105.83872301500003, 9.353380490000072],
              [105.83694127500004, 9.352829144000017],
              [105.83507445900003, 9.352251452000091],
              [105.83301053500006, 9.351612744000091],
              [105.8303187610001, 9.350492415000121],
              [105.82889512800008, 9.349899875000032],
              [105.82780783700008, 9.349437875000087],
              [105.82729995799998, 9.349208127000084],
              [105.82647980900008, 9.348821554000073],
              [105.82456468800007, 9.34791885700008],
              [105.8226049080001, 9.346985824000063],
              [105.82232706700002, 9.346842932000053],
              [105.82048006700006, 9.345815594000049],
              [105.81836400100012, 9.344640635000088],
              [105.81559666200003, 9.343104005000045],
              [105.81286609400006, 9.341587738000088],
              [105.81072532600004, 9.340398939000048],
              [105.81270487100004, 9.33432883700007],
              [105.8135406590001, 9.330394074000113],
              [105.81388882400003, 9.328684507000061],
              [105.81494454500007, 9.3239263670001],
              [105.81587658800001, 9.319436149000103],
              [105.81621424200006, 9.317664851000094],
              [105.81643859500006, 9.316161439000046],
              [105.81676665900002, 9.314719563000027],
              [105.82099494000002, 9.295205428000129],
              [105.82393812300009, 9.278735799000069],
              [105.82411691500005, 9.27818727200007],
              [105.81767813800003, 9.273637852000059],
              [105.81818511700007, 9.271387932000062],
              [105.81871295800003, 9.268925808000093],
              [105.81898763000002, 9.267737150000064],
              [105.82074915200012, 9.26208930100003],
              [105.82483955500007, 9.248425843000073],
              [105.82561764000006, 9.245834439000065],
              [105.82385565300011, 9.245049013000093],
              [105.81531028700005, 9.242144147000085],
              [105.81233326200002, 9.240939788000089],
              [105.79338471700002, 9.232371760000111],
              [105.76942529000003, 9.221715225000081],
              [105.74030089100008, 9.208810482000017],
              [105.73936214100011, 9.208331078000052],
              [105.73929376900006, 9.207391833000054],
              [105.74011632600008, 9.204115984000081],
              [105.74028991500005, 9.202631905000123],
              [105.7392041690001, 9.201212423000092],
              [105.73779696800003, 9.200415113000114],
              [105.73199538900005, 9.1989851050001],
              [105.73092420200007, 9.198624426000011],
              [105.72750080600005, 9.197471737000027],
              [105.71861097200009, 9.193805072000048],
              [105.70119842600009, 9.186657769000115],
              [105.68736150100008, 9.180921679000063],
              [105.67728166100007, 9.17678252700007],
              [105.67188093600005, 9.174068060000094],
              [105.67096228500007, 9.173585998000087],
              [105.66510904200001, 9.170514383000048],
              [105.65788133200007, 9.166589292000092],
              [105.64780800200008, 9.161991268000056],
              [105.64130569800004, 9.159080615000013],
              [105.63406626600003, 9.156252966000087],
              [105.61390305500008, 9.148701586000117],
              [105.59759162800006, 9.142474020000078],
              [105.58283277300006, 9.137361489000016],
              [105.58045273700004, 9.136445427000071],
              [105.57376190000004, 9.133869991000053],
              [105.56313664600002, 9.129536290000127],
              [105.55187530800004, 9.124553984],
              [105.53997037200001, 9.119563544000096],
              [105.53283555800004, 9.115910132000058],
              [105.52753363700003, 9.112918834000093],
              [105.51602114900007, 9.106009902000077],
              [105.49911745900006, 9.096015917000065],
              [105.49730014800006, 9.09487347000004],
              [105.49318138500007, 9.092284137000116],
              [105.48240834500008, 9.085290978000058],
              [105.47784843500008, 9.08194147900012],
              [105.46737939800002, 9.072755060000047],
              [105.45664208600006, 9.063015956000074],
              [105.45047018800001, 9.056169019000036],
              [105.44783868400006, 9.05320861100005],
              [105.44483347500001, 9.049144598000057],
              [105.44361056600007, 9.047490781000088],
              [105.4383735160001, 9.040057359000018],
              [105.43262025900006, 9.032358991000054],
              [105.42749945400001, 9.026090465],
              [105.42402791400011, 9.023074221000103],
              [105.42222963900008, 9.021371394000076],
              [105.42054679000003, 9.020833491000067],
              [105.41924570900002, 9.020946700000108],
              [105.41780334800005, 9.021963104000095],
              [105.41531168800003, 9.023742191000038],
              [105.416282822, 9.030166275000083],
              [105.41732077600008, 9.035554547000045],
              [105.41742369900008, 9.037144022000108],
              [105.41592402700007, 9.039202363000063],
              [105.41146619500009, 9.042079250000066],
              [105.40935237100007, 9.044129952000011],
              [105.40895939600007, 9.046079827000051],
              [105.40905438600002, 9.048377723000021],
              [105.40949737900009, 9.051477644],
              [105.40862634100004, 9.053039557000096],
              [105.40354018300003, 9.057008128000097],
              [105.39422241600002, 9.065322219000025],
              [105.39223409500008, 9.067130058000126],
              [105.39099504300006, 9.067969824000064],
              [105.38927550600008, 9.067948335000063],
              [105.38207073300001, 9.064559352000055],
              [105.37927685500003, 9.062080782000058],
              [105.37688719000002, 9.059376869000067],
              [105.37665285900007, 9.059273481000039],
              [105.37599953100002, 9.058985228000081],
              [105.37415725000008, 9.05896207200003],
              [105.37095773500003, 9.059410515000097],
              [105.36418553800006, 9.06066909700005],
              [105.35740073300009, 9.062904698000105],
              [105.355171173, 9.064342601000062],
              [105.35462637300004, 9.065654205000049],
              [105.35469967900008, 9.065898411000093],
              [105.3548910760001, 9.067026954000122],
              [105.3567083880001, 9.069004811000079],
              [105.35693995600008, 9.070107348000088],
              [105.35680304600002, 9.071205224000066],
              [105.35520161500004, 9.071551480000027],
              [105.34978941800006, 9.072093770000043],
              [105.34007354100004, 9.072947682000086],
              [105.33811462500006, 9.072434002000039],
              [105.33593529100004, 9.069962639000016],
              [105.33436539800009, 9.067865574000054],
              [105.33301749100011, 9.067604006000083],
              [105.33178293400002, 9.068076953000047],
              [105.32807132900005, 9.070106525000108],
              [105.32436915600006, 9.071403097000095],
              [105.32104334000005, 9.072093534000112],
              [105.31661366, 9.072647566000034],
              [105.31277104000002, 9.075286110000123],
              [105.30829504500005, 9.079382612000051],
              [105.29994552100003, 9.088438509000072],
              [105.29647891300007, 9.090470788000092],
              [105.29340218100006, 9.091280908000064],
              [105.2908187940001, 9.091130545000057],
              [105.28211505500008, 9.08967343300011],
              [105.27832324500005, 9.088402214],
              [105.27454116400003, 9.086397959000079],
              [105.27209254900002, 9.085755078000089],
              [105.26949849000006, 9.086820829000073],
              [105.26576003300009, 9.090803985000127],
              [105.26214589300011, 9.094666551000062],
              [105.26066208700007, 9.095380205000046],
              [105.25735354400007, 9.094725860000084],
              [105.2513630270001, 9.092845150000082],
              [105.25121758800006, 9.094094593000078],
              [105.25054768100006, 9.095132430000062],
              [105.24808972700006, 9.096616070000072],
              [105.24525912800007, 9.098025851000012],
              [105.24421686600009, 9.099434420000117],
              [105.24364599500009, 9.100925854000062],
              [105.24339875000007, 9.101732003000061],
              [105.24339920600002, 9.102398886000083],
              [105.24377208800006, 9.102843231000078],
              [105.24474094500002, 9.103064877000032],
              [105.24645512600009, 9.103508308000039],
              [105.24787158500006, 9.104470622000081],
              [105.24876625000009, 9.105136890000052],
              [105.2488413830001, 9.106026024000078],
              [105.24861890500007, 9.107582233000054],
              [105.24817297600003, 9.109286800000048],
              [105.24683293700009, 9.11114016],
              [105.24586491500003, 9.112178189000096],
              [105.2454182160001, 9.112771267000051],
              [105.245232528, 9.113660582000092],
              [105.245605977, 9.114919989000024],
              [105.24627806700001, 9.116994287000056],
              [105.24709893700008, 9.118697992000067],
              [105.24814334400007, 9.120327429000062],
              [105.24814390600002, 9.121142513000089],
              [105.2493417200001, 9.129070177000108],
              [105.2494543280001, 9.130255679000053],
              [105.24967985100001, 9.133071247000133],
              [105.25027748100007, 9.135145572000079],
              [105.25109820800003, 9.136552871000079],
              [105.2521423750001, 9.137737714000059],
              [105.25408045300006, 9.138403251000058],
              [105.25594374000003, 9.138698330000048],
              [105.25691301500004, 9.139364532000103],
              [105.2592248660001, 9.141585821000129],
              [105.26064211400002, 9.143363159000016],
              [105.261090655, 9.145289377000053],
              [105.26180108400008, 9.148623278000033],
              [105.26150558400006, 9.15218019400008],
              [105.26202834500006, 9.153661774000097],
              [105.26352040100005, 9.155735420000076],
              [105.26508701000009, 9.15780902100005],
              [105.26560951700007, 9.158920104000055],
              [105.26531261300002, 9.16042633800007],
              [105.26667216800007, 9.160852721000088],
              [105.26827298000009, 9.161151638000129],
              [105.27794490300001, 9.163416504000031],
              [105.27846586100003, 9.163685768000017],
              [105.27819849000012, 9.16845672000006],
              [105.27783371300005, 9.172222544000073],
              [105.2776315560001, 9.174536768000062],
              [105.27730873900003, 9.175186172000059],
              [105.27701029800004, 9.175817526000072],
              [105.27598376400002, 9.178261854000079],
              [105.27537619400002, 9.179692717000075],
              [105.27479672000003, 9.180463494000078],
              [105.27459965800007, 9.180677587000028],
              [105.27216660200001, 9.18348814400005],
              [105.2719454460001, 9.183732836000045],
              [105.27130620800006, 9.183977827000065],
              [105.26952474800001, 9.18378442000005],
              [105.26369201800004, 9.18170514200005],
              [105.26041730700007, 9.179862712000018],
              [105.25945632300005, 9.179631767],
              [105.25752266600003, 9.184067790000048],
              [105.25244444400005, 9.196126639000022],
              [105.25150879000009, 9.198498309000057],
              [105.24018097000001, 9.200796192000025],
              [105.23946459899999, 9.200954962000106],
              [105.23858921200012, 9.201430414000098],
              [105.238271088, 9.20190548700006],
              [105.23811282800006, 9.203330211000081],
              [105.23779533400007, 9.204755032000021],
              [105.23716140900002, 9.209187582000043],
              [105.2372675980001, 9.209808280000063],
              [105.23692363400006, 9.210770651000109],
              [105.23565089000002, 9.212354384000053],
              [105.23509387800001, 9.212750471000051],
              [105.23397956800001, 9.21314692200008],
              [105.23254665800002, 9.21330614500002],
              [105.23135299100002, 9.214098365000021],
              [105.23095524200006, 9.214573494000076],
              [105.23095596100003, 9.215681524],
              [105.23098727800006, 9.22575091500004],
              [105.23122799800008, 9.225584755000059],
              [105.23304922000004, 9.225184123000039],
              [105.23581879100004, 9.225751725000107],
              [105.23727957600003, 9.226636134],
              [105.2381498230001, 9.227163004000081],
              [105.24171444400007, 9.23123399300003],
              [105.24392794300007, 9.233761833000107],
              [105.2466000470001, 9.236813308000079],
              [105.25162425800005, 9.242550542000048],
              [105.2531256, 9.244264878000051],
              [105.25414262800004, 9.245426171000036],
              [105.25550143700012, 9.247674072000043],
              [105.25590605800002, 9.249378469000112],
              [105.255889729, 9.251203887000068],
              [105.25660427900007, 9.253104412000111],
              [105.25924490800006, 9.25536913800009],
              [105.26564893300005, 9.259400176000019],
              [105.26894359400006, 9.261473887000092],
              [105.276207879, 9.266036456000036],
              [105.27864589900001, 9.268054729000051],
              [105.27914445900005, 9.268491355000029],
              [105.28685261300002, 9.275619095000119],
              [105.2923044290001, 9.280643769000017],
              [105.28285336700006, 9.29093464700008],
              [105.26709007400009, 9.30505698800008],
              [105.26060881000002, 9.310287785000069],
              [105.25517991400008, 9.316737120000072],
              [105.2491935410001, 9.325428294000025],
              [105.24940795100008, 9.326489781000101],
              [105.24930173100007, 9.327339169000124],
              [105.24844820500007, 9.32893222100004],
              [105.24716719400004, 9.330313239000086],
              [105.24684781100008, 9.33190592700006],
              [105.24652940800011, 9.334878736000094],
              [105.24653052600011, 9.33647119700005],
              [105.24743944400009, 9.337638363000076],
              [105.25136513600009, 9.344040727000012],
              [105.25226447200008, 9.343495684000034],
              [105.25262752500001, 9.343877018000056],
              [105.2720795110001, 9.364305882000055],
              [105.29492731300004, 9.387689632000027],
              [105.27453320899998, 9.400640176000062],
              [105.27163041300005, 9.396493762000089],
              [105.26034637100007, 9.399935199000085],
              [105.26045227400004, 9.400167517000034],
              [105.26127458900004, 9.401169002000119],
              [105.26420008600007, 9.40720765100007],
              [105.26490318800006, 9.408652600000076],
              [105.27010147800003, 9.420577663000083],
              [105.27654890500006, 9.434075362],
              [105.28296081700007, 9.435617972],
              [105.285989262, 9.436061211],
              [105.28811994900008, 9.43583663300007],
              [105.29377153400007, 9.434963303000108],
              [105.29268827600006, 9.438675850000065],
              [105.29118535500005, 9.444334385],
              [105.28989638100006, 9.452052152000066],
              [105.290108043, 9.454474890000062],
              [105.28996806100005, 9.465121186000134],
              [105.29035642400004, 9.472628420000094],
              [105.29140027200006, 9.47723109700007],
              [105.29148548300002, 9.47765142100012],
              [105.29431768000005, 9.482084014000057],
              [105.29534721100006, 9.488251822000036],
              [105.29674887700003, 9.499919691000079],
              [105.29669698400009, 9.503875604000099],
              [105.29866401300004, 9.506318948000024],
              [105.30042340800004, 9.507660538],
              [105.30352972300003, 9.507920431000104],
              [105.30842134200002, 9.507543827000029],
              [105.31067947800007, 9.504715330000034],
              [105.3131338420001, 9.503867645000062],
              [105.3146826820001, 9.504327164000069],
              [105.31772602300001, 9.509421885000078],
              [105.32164361200006, 9.515626898000027],
              [105.32206209500005, 9.517610597000024],
              [105.31936263500006, 9.52021381100012],
              [105.31799285600006, 9.523053871000096],
              [105.31818635500008, 9.525254540000015],
              [105.31970678000003, 9.527911873000059],
              [105.32192213800002, 9.528379898000059],
              [105.32816830300008, 9.529070941000038],
              [105.32757075000002, 9.529663402000031],
              [105.32538652300005, 9.531681604000129],
              [105.32448486500004, 9.533131591000108],
              [105.32416406500006, 9.535173625000047],
              [105.32402423000003, 9.538387214000109],
              [105.32448622300006, 9.540756636000046],
              [105.32424781600005, 9.540851840000107],
              [105.32164516500009, 9.543619798000067],
              [105.32086944600005, 9.545403915000071],
              [105.32054515600004, 9.54719381800002],
              [105.32051988600007, 9.549137065000044],
              [105.32123537900003, 9.551986837000113],
              [105.32253730900001, 9.556040200000025],
              [105.32281109800002, 9.558136815000038],
              [105.32249651700002, 9.559179346000056],
              [105.32158191200001, 9.560064687000123],
              [105.31991677500008, 9.560790899000081],
              [105.31825358200005, 9.561367615000014],
              [105.31689914500008, 9.561350242000087],
              [105.31539616100007, 9.561181446000065],
              [105.31360584500008, 9.559962385000096],
              [105.31062528100003, 9.557681451000109],
              [105.30898160000001, 9.556763259000077],
              [105.30793208200004, 9.556450736000064],
              [105.30642133400009, 9.556879807000128],
              [105.30595618100006, 9.557920373000071],
              [105.30564542400002, 9.558663911000041],
              [105.30567217100001, 9.559400245000036],
              [105.30568352400006, 9.559712694000043],
              [105.30618289900008, 9.563604638000099],
              [105.30690801300003, 9.565707110000064],
              [105.30793414000003, 9.567813471000084],
              [105.30971474000009, 9.569780030000084],
              [105.31060602400004, 9.570688563000116],
              [105.31104971400006, 9.571292316000067],
              [105.31119046100007, 9.572041686000075],
              [105.31118070700006, 9.572789122000101],
              [105.31071750200002, 9.573680242000114],
              [105.30995327600002, 9.574567490000073],
              [105.30600894700007, 9.576908924000092],
              [105.30265721500004, 9.579194718000037],
              [105.29859659100006, 9.582285005000111],
              [105.29498667900006, 9.58439954],
              [105.29295592400008, 9.586015777000066],
              [105.29099431400005, 9.589016081000066],
              [105.28776866200006, 9.595112164000062],
              [105.2846347330001, 9.600863666000057],
              [105.2846176350001, 9.602160195000041],
              [105.28474794100006, 9.602878958000092],
              [105.28503736200003, 9.60346240600005],
              [105.28646447800006, 9.607889943000101],
              [105.28728578100007, 9.611877388000066],
              [105.28732420900005, 9.615595357000116],
              [105.28681408300012, 9.617836521000099],
              [105.28483043400004, 9.622479279000105],
              [105.284446761, 9.625067925000062],
              [105.2847689110001, 9.627147027000136],
              [105.28680668000004, 9.631669135000019],
              [105.28988396200002, 9.636896380000069],
              [105.29048455000006, 9.637654663000104],
              [105.29105228900005, 9.638371466000082],
              [105.29337536400007, 9.638772770000069],
              [105.29341040500003, 9.638520022000117],
              [105.29410019000008, 9.63351133000012],
              [105.29545427100004, 9.630670136000051],
              [105.29756953200008, 9.62812294600006],
              [105.29983001600006, 9.624419094000112],
              [105.30077605800008, 9.621728090000124],
              [105.30212244300003, 9.618269909000075],
              [105.30333027300004, 9.615453570000019],
              [105.30553053000001, 9.609729128000042],
              [105.30664833100005, 9.608757682000134],
              [105.30821984900004, 9.608004926000112],
              [105.31607248600007, 9.606092051000113],
              [105.32104892000007, 9.603581449000041],
              [105.32590852100006, 9.600039486000076],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 6, code: "AD01", ten_tinh: "Bắc Ninh" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.03246356600008, 21.224879391000059],
              [106.03216700300005, 21.219614881000034],
              [106.03219359800003, 21.216984157000063],
              [106.03305287100008, 21.212716470000089],
              [106.03389106100006, 21.210531421000042],
              [106.03708838800003, 21.205626666000029],
              [106.03862373000004, 21.203667014000068],
              [106.04038226300007, 21.202695959000017],
              [106.04082872100005, 21.202732227000084],
              [106.04189660000004, 21.202818985000071],
              [106.04352311400007, 21.20338149400007],
              [106.04444756800008, 21.204560226000076],
              [106.04458247800005, 21.205585532],
              [106.04413589200004, 21.206673970000089],
              [106.04123984100005, 21.210158069000059],
              [106.04028515600002, 21.212342106000023],
              [106.04026418900007, 21.214424784000038],
              [106.04151568100005, 21.217505311000103],
              [106.04427068600009, 21.221804961000053],
              [106.048648132, 21.227105438000038],
              [106.0500339970001, 21.228433105000107],
              [106.05201051400012, 21.228998607000094],
              [106.05364274699998, 21.229012961000038],
              [106.05960733700003, 21.227201756000014],
              [106.06382301700005, 21.225375098000043],
              [106.066404242, 21.223753329000061],
              [106.06817043300003, 21.222014794000096],
              [106.0707689700001, 21.218639153000041],
              [106.07159156000004, 21.217988576000032],
              [106.07299162400007, 21.217891145000053],
              [106.0740365190001, 21.218338736000049],
              [106.078438514, 21.221227169000123],
              [106.07983751700002, 21.221239307000062],
              [106.08101199700012, 21.220372516000019],
              [106.08407661400003, 21.217000750000103],
              [106.08467462900003, 21.215471207000022],
              [106.08468757100003, 21.214155834000081],
              [106.08343864600008, 21.210746682],
              [106.083451596, 21.209431298000055],
              [106.08439500500008, 21.208343223],
              [106.08896407300003, 21.206080583000109],
              [106.093990741, 21.204698790000087],
              [106.10322358700009, 21.202366334000097],
              [106.11747620700004, 21.199308844000072],
              [106.11992525800008, 21.199220032000099],
              [106.121317758, 21.199889617000075],
              [106.12212106700004, 21.201211939000061],
              [106.12256303900001, 21.203737084000096],
              [106.12252286600007, 21.207902491000041],
              [106.12295217600007, 21.211742980000011],
              [106.12374921500002, 21.213722972000056],
              [106.12570889800007, 21.216041659000062],
              [106.12825892700003, 21.217597955000045],
              [106.13000344000004, 21.218051186000061],
              [106.13163559600008, 21.218064967000046],
              [106.13408907100005, 21.217537548000017],
              [106.14239044900008, 21.215086024000037],
              [106.14677943600005, 21.213314019000059],
              [106.14897766500003, 21.21169050000011],
              [106.14919161000006, 21.211532488],
              [106.15101378000001, 21.20940296800002],
              [106.15775515700003, 21.19926403],
              [106.16343329700007, 21.19054106100009],
              [106.16852193900012, 21.182470716000076],
              [106.17034356900002, 21.180896160000096],
              [106.17362165100009, 21.179388418000045],
              [106.17794870600009, 21.177889262000079],
              [106.18075020800008, 21.177473781000074],
              [106.18214890300007, 21.177485273000052],
              [106.18308035300005, 21.177602560000075],
              [106.18389114600008, 21.178157361000061],
              [106.18469581100004, 21.179369893000022],
              [106.18607613700003, 21.181354540000093],
              [106.18746568500006, 21.182352582000071],
              [106.18979282500007, 21.182810150000094],
              [106.19317107300007, 21.183057010000091],
              [106.19573746200005, 21.182858680000024],
              [106.21630050000005, 21.177763137000035],
              [106.21817446000003, 21.176791568000027],
              [106.21900339900004, 21.175373046000075],
              [106.2191339930001, 21.173839269],
              [106.21891391700009, 21.172412297000065],
              [106.21383174500006, 21.167328296000051],
              [106.20725387000009, 21.160148996000046],
              [106.20598800500007, 21.158384604000076],
              [106.20587953900005, 21.157506652000087],
              [106.20635582000003, 21.156414180000027],
              [106.20822358200012, 21.156100411000089],
              [106.21323805500006, 21.15581204600003],
              [106.21619145300008, 21.155920085000091],
              [106.22092804800005, 21.156093277000117],
              [106.22464751300004, 21.157219480000045],
              [106.2274306460001, 21.1587766600001],
              [106.2299757550001, 21.160880060000054],
              [106.23435787900003, 21.166067791000032],
              [106.237712082, 21.168944952000054],
              [106.24235343400007, 21.171284114000066],
              [106.24583625200003, 21.172846578000112],
              [106.24862964700004, 21.173307230000127],
              [106.25061208000002, 21.17321326900008],
              [106.25213222400004, 21.172677129000036],
              [106.25342515900007, 21.171481395000043],
              [106.25579651300009, 21.167005201000052],
              [106.25627548300011, 21.16558376000004],
              [106.25582204400007, 21.164154968000034],
              [106.25397886500006, 21.161728523000086],
              [106.25352643500003, 21.160190092000072],
              [106.25390655100003, 21.156794459000047],
              [106.25451188100003, 21.154277651000086],
              [106.25604462900002, 21.152316320000075],
              [106.26060637800006, 21.150349906000088],
              [106.26193250200001, 21.149430543000044],
              [106.26392949200005, 21.14717339400007],
              [106.26637397400006, 21.143989934000018],
              [106.26771564800005, 21.141314356000059],
              [106.26862078200007, 21.138325405000025],
              [106.26963295900006, 21.135647211000027],
              [106.270747575, 21.133796294],
              [106.27218150900008, 21.133084311000069],
              [106.27383139799998, 21.132787249],
              [106.27558834200005, 21.132800949000071],
              [106.279865414, 21.133454120000025],
              [106.28250083900009, 21.13347459200012],
              [106.28536042100009, 21.13298020800007],
              [106.28988165000007, 21.130845630000024],
              [106.29297618200005, 21.128596633000107],
              [106.29530202000002, 21.126341663000048],
              [106.29630472200003, 21.124696363000069],
              [106.29741229400001, 21.122746178000092],
              [106.29522824800006, 21.118551561000039],
              [106.293915967, 21.113553912000071],
              [106.29369585000009, 21.110536482000072],
              [106.29399424700009, 21.108186152000115],
              [106.29422547000004, 21.106364903000063],
              [106.2953683170001, 21.102546003],
              [106.2978943250001, 21.095605911000078],
              [106.29927762600008, 21.0907798960001],
              [106.30010270000004, 21.088977341000081],
              [106.30068074700009, 21.084859748],
              [106.30072539400005, 21.079740766000029],
              [106.30157254400007, 21.073878709000034],
              [106.302930696, 21.070268080000076],
              [106.30360797300008, 21.069127903000059],
              [106.30521956800001, 21.066414829000024],
              [106.30834414900009, 21.062458862000085],
              [106.30948078700007, 21.060656209000058],
              [106.30962043500008, 21.059728281000019],
              [106.30915536300006, 21.05870505700009],
              [106.30597945300011, 21.055307575000093],
              [106.30333550900009, 21.052777886000037],
              [106.2988752700001, 21.048510167000067],
              [106.29493089400005, 21.044483973000091],
              [106.29231198300008, 21.040592793000101],
              [106.29192402400004, 21.039134711000074],
              [106.29141306500004, 21.037214339000073],
              [106.29143714700008, 21.034467357000075],
              [106.29217455100007, 21.030568890000012],
              [106.29358826600007, 21.025842573000105],
              [106.29361465700003, 21.022828156000095],
              [106.29256751800008, 21.020523212000072],
              [106.28862865100005, 21.017621666000075],
              [106.28195827400006, 21.013263267000056],
              [106.27680640700002, 21.009634254000083],
              [106.27347908300005, 21.006593599000034],
              [106.27106656100001, 21.003560025000034],
              [106.26987370000009, 21.000535949000017],
              [106.269908098, 20.99666011200005],
              [106.27070089400004, 20.993220862000058],
              [106.27119910700006, 20.991851608000012],
              [106.26178431300009, 20.993125274000057],
              [106.25591452400008, 20.993650378000069],
              [106.25429667300007, 20.993637608000078],
              [106.24643707600009, 20.990528230000024],
              [106.24401034000003, 20.990508966000029],
              [106.23791080800001, 20.994078945000091],
              [106.23283776100001, 20.99594291800004],
              [106.22939979600005, 20.995915408000073],
              [106.22536553800003, 20.994740373000106],
              [106.21893065500007, 20.99068920200002],
              [106.20785015200003, 20.986028852000068],
              [106.19515244300005, 20.98135470600004],
              [106.18950272700005, 20.979975334000045],
              [106.1824304890001, 20.979345937000105],
              [106.17678447300004, 20.977585355000095],
              [106.17195098900007, 20.975450443000021],
              [106.16818742100006, 20.971602187000116],
              [106.16594197200007, 20.969306098000025],
              [106.1648076490001, 20.972632953000044],
              [106.16147205600006, 20.975719280000057],
              [106.16092886100007, 20.976046497000041],
              [106.16307752900003, 20.978659187000034],
              [106.16543298500007, 20.981991296000047],
              [106.16679248900002, 20.984368665000105],
              [106.16715599400007, 20.985791335000044],
              [106.16714821000005, 20.986619396],
              [106.16638338300001, 20.9877960950001],
              [106.16549626700007, 20.988616858000064],
              [106.1637298110001, 20.989430322000047],
              [106.159574353, 20.990460491000107],
              [106.15756109500003, 20.990798626000107],
              [106.15680737900003, 20.99079234200012],
              [106.15580465800011, 20.990547371000012],
              [106.15430618700003, 20.989588435000073],
              [106.15305336600002, 20.989223064000036],
              [106.15091672300007, 20.989323509000087],
              [106.14928032300003, 20.989664721000075],
              [106.14826525800007, 20.990720949000071],
              [106.14559350000007, 20.994247640000104],
              [106.14381790000006, 20.996007274000036],
              [106.14077926400009, 20.998466083],
              [106.13670571600001, 21.001604723000078],
              [106.13622286200003, 21.001976759000037],
              [106.13521671600009, 21.002086571000063],
              [106.13358357800006, 21.002072788000078],
              [106.13132910400003, 21.001343942000084],
              [106.13020413700004, 21.000742917000089],
              [106.12945721300005, 21.000026796000078],
              [106.12859714000004, 20.998008386000052],
              [106.12735896700008, 20.992822160000024],
              [106.12580591600002, 20.987366308000063],
              [106.12522910500007, 20.984826623000075],
              [106.12147370400004, 20.98544946],
              [106.11929854600001, 20.985723232000097],
              [106.11806003100003, 20.98542042200009],
              [106.11636306800004, 20.984383013000048],
              [106.11481423700005, 20.98407753900009],
              [106.11372383900007, 20.984506645000067],
              [106.11325126900005, 20.98523328800006],
              [106.11308195599999, 20.986693203000058],
              [106.11321308700009, 20.989178618],
              [106.11375255400006, 20.990758753000094],
              [106.11373498100002, 20.992575018000089],
              [106.11369388499999, 20.996822284000061],
              [106.11120108500006, 20.998351923000058],
              [106.10755858900004, 21.000612232000023],
              [106.10723162000008, 21.000665316000067],
              [106.106816452, 21.000633823000037],
              [106.10586984900002, 21.000318324000133],
              [106.10356366200008, 20.999432271],
              [106.10187984500003, 20.998635367000063],
              [106.10123054700003, 20.998266507],
              [106.1008159300001, 20.998179115000056],
              [106.10045874800004, 20.99828782200008],
              [106.10016009900002, 20.998480871000069],
              [106.09920180200004, 20.999366862],
              [106.09737503100007, 21.001055761000131],
              [106.09611751400011, 21.002190666000061],
              [106.09462284799999, 21.003295563000087],
              [106.09375653100007, 21.003874927000062],
              [106.09205630600007, 21.004754489],
              [106.09002552600001, 21.006050336000023],
              [106.08969636200004, 21.006326934000086],
              [106.08969307600003, 21.006662240000118],
              [106.08996569900003, 21.007963324000031],
              [106.086807268, 21.007510573000054],
              [106.08401682800005, 21.007194148000075],
              [106.08294059399999, 21.00616189900002],
              [106.08186725600009, 21.004837408000029],
              [106.08111436100006, 21.002492774000103],
              [106.08005399899999, 20.999853187000014],
              [106.07836410500005, 20.997041665],
              [106.07224849100004, 21.000720320000084],
              [106.06802873900004, 21.001815811000043],
              [106.06291836300007, 21.001771090000076],
              [106.05660836700005, 21.001432632000046],
              [106.05479058600011, 21.002832058000109],
              [106.05416110000007, 21.005657352000107],
              [106.05383650100003, 21.00739057200007],
              [106.04399993200008, 21.004797898000049],
              [106.03920693100008, 21.003001855000036],
              [106.03766542700006, 21.001965257000066],
              [106.03613129100006, 21.00019804300004],
              [106.0319709880001, 20.997238375000023],
              [106.02966107000002, 20.995464193000046],
              [106.0285822430001, 20.994723908000076],
              [106.02692557800007, 20.994278167000054],
              [106.02581280600002, 20.994268232000124],
              [106.02379053800003, 20.995195849000048],
              [106.02108935500009, 20.996905455000011],
              [106.01703508200009, 20.999706177000022],
              [106.01601374300006, 21.000799956000016],
              [106.01567671600006, 21.002774288000033],
              [106.01566531100009, 21.003113888000065],
              [106.01347906800004, 21.002984139000077],
              [106.01231298700002, 21.002185588000089],
              [106.00948410200007, 20.999953547000061],
              [106.00630210100005, 20.999452023000039],
              [106.00462320800006, 20.999594491000018],
              [106.00327492500004, 21.000212773000015],
              [106.00259755500005, 21.000837109000067],
              [106.00141860500003, 21.001299293000031],
              [106.00007353300002, 21.001602360000057],
              [105.99900933800006, 21.001745332000077],
              [105.99728625500006, 21.006957288000102],
              [105.99778024100007, 21.007430460000087],
              [105.99993048400009, 21.008543550000077],
              [106.00308474700003, 21.009040752000097],
              [106.00607117000004, 21.009692635000121],
              [106.00738535300006, 21.011266800000072],
              [106.00756223700004, 21.012710387000055],
              [106.00733633200009, 21.012903512000044],
              [106.0033260750001, 21.017948081000078],
              [106.00231722100003, 21.019032565000103],
              [106.00214133700004, 21.019968352000085],
              [106.00213813500005, 21.020280781000089],
              [106.00245468100003, 21.02184593],
              [106.00425495400005, 21.024674289000075],
              [106.01166747300007, 21.031615052000063],
              [106.0144609270001, 21.034920885000119],
              [106.0165175920001, 21.038573264000071],
              [106.01836448300006, 21.042247828000079],
              [106.01805086600004, 21.048380966000124],
              [106.01762854400009, 21.048636259000105],
              [106.01362679500009, 21.051055225000056],
              [106.01273787300008, 21.051991220000055],
              [106.01235019800006, 21.053049715000057],
              [106.01181644100005, 21.056112845000108],
              [106.01130193400012, 21.057288188000101],
              [106.00809998400004, 21.063277172000113],
              [106.00649777899999, 21.065512849000086],
              [106.00507085400005, 21.064394692000029],
              [106.00481187400003, 21.06414673500003],
              [106.0033980580001, 21.069610720000043],
              [106.00244947100005, 21.070842647000099],
              [106.00097676900006, 21.073067511000062],
              [105.99915664400001, 21.072486944000076],
              [105.9949906320001, 21.071158036000078],
              [105.99220166100008, 21.070359702000104],
              [105.99096257100005, 21.070043436000049],
              [105.98344742100006, 21.0705882500001],
              [105.97616567600002, 21.071196555000071],
              [105.9709700810001, 21.072042138000043],
              [105.96688830300006, 21.073156967],
              [105.96524241100003, 21.074335045000048],
              [105.96318546400002, 21.075886771000093],
              [105.96047897100009, 21.078552601000041],
              [105.95802482700006, 21.087564108000031],
              [105.95727379200009, 21.089943535000089],
              [105.95617043900002, 21.091296968000073],
              [105.95562054599999, 21.091803245000037],
              [105.95489450400005, 21.091796534000103],
              [105.95127864200001, 21.09039944900006],
              [105.94369845800006, 21.086238207000108],
              [105.94108677800006, 21.085202869000064],
              [105.93992566, 21.085055579000041],
              [105.93919184900004, 21.085731140000071],
              [105.93393966000005, 21.097101900000048],
              [105.93365925900012, 21.098353426000038],
              [105.93351125200005, 21.101339853000042],
              [105.93309878000011, 21.10352892900007],
              [105.93176130800003, 21.107293114000036],
              [105.92990999600006, 21.110565157000046],
              [105.92776450300009, 21.112595370000072],
              [105.92075394700001, 21.117423431000049],
              [105.92009870800007, 21.118026403000023],
              [105.9222675120001, 21.121183825000053],
              [105.91989702300008, 21.12248895700013],
              [105.91323460800002, 21.123085752000037],
              [105.91267283200008, 21.123656752000031],
              [105.91102061200007, 21.12554333000007],
              [105.91079423200009, 21.126358480000107],
              [105.91143832400009, 21.12718184600002],
              [105.91198018300007, 21.127391286000119],
              [105.91285066700006, 21.127399500000088],
              [105.91393767400002, 21.12751191400006],
              [105.91490712400012, 21.128440499000064],
              [105.91576557900001, 21.129572358000104],
              [105.91597883600006, 21.129983007000064],
              [105.91597227700007, 21.130595910000061],
              [105.91508975100004, 21.131711353000071],
              [105.91497656600009, 21.132118926000132],
              [105.91508100300004, 21.132528558],
              [105.92004494200006, 21.136457345000089],
              [105.92198182, 21.138518720000029],
              [105.92429360600005, 21.141145475000123],
              [105.92449708500001, 21.142475440000027],
              [105.92458957400002, 21.144008681000059],
              [105.92436105200004, 21.14502811800012],
              [105.91992315500002, 21.152954700000045],
              [105.91749078100003, 21.156507293000089],
              [105.91758869200004, 21.157529774000047],
              [105.92048556400002, 21.161438918000016],
              [105.92123822200003, 21.162811024000035],
              [105.9212317540001, 21.163416905000076],
              [105.91944195700009, 21.164763454000109],
              [105.91602367700006, 21.167458011000036],
              [105.91552329800004, 21.168968144000111],
              [105.91549411100002, 21.171694568000099],
              [105.916118603, 21.1736697300001],
              [105.919436644, 21.180366145000029],
              [105.92101990200008, 21.183259160000119],
              [105.92085203100004, 21.18386350400003],
              [105.91906366200004, 21.185058536000057],
              [105.91142413400007, 21.189985395000029],
              [105.90485701400009, 21.19302459000005],
              [105.90475806000006, 21.19493600400002],
              [105.90399951500009, 21.196381257000048],
              [105.90567389500009, 21.19932265300006],
              [105.90861060300006, 21.201996254000029],
              [105.9102845630001, 21.202603355000079],
              [105.91111766100002, 21.203268198000025],
              [105.9116007100001, 21.20392974400005],
              [105.91377848000009, 21.209797414000064],
              [105.91372004400004, 21.215249752000084],
              [105.91313244300001, 21.217806394000057],
              [105.91198748800005, 21.220094992000057],
              [105.90999000600007, 21.224296515000063],
              [105.90907237100008, 21.225140222000057],
              [105.9080109400001, 21.22527225000006],
              [105.90725540600002, 21.225123055000061],
              [105.9062000860001, 21.224686887000033],
              [105.90454525100004, 21.223676803000032],
              [105.90363983500001, 21.223384104000061],
              [105.90287744200008, 21.223555256000012],
              [105.90331114600008, 21.22702070000004],
              [105.90438818600002, 21.232054227000049],
              [105.90483302200005, 21.23276594600005],
              [105.90535936900008, 21.232912428000091],
              [105.90770011600004, 21.232651557000089],
              [105.91033794100008, 21.23281797],
              [105.91320049000005, 21.233127971000073],
              [105.91410616700003, 21.23306575400008],
              [105.91546808700008, 21.232654076000031],
              [105.91675233300003, 21.232453911000079],
              [105.91773038400004, 21.23267536300002],
              [105.918702395, 21.233462761000062],
              [105.9189987340001, 21.233960802000063],
              [105.91958538600005, 21.235522827000075],
              [105.92010721500006, 21.236093733000061],
              [105.92197964700009, 21.237314070000046],
              [105.92406983100001, 21.238777022000122],
              [105.92542357100011, 21.239642060000016],
              [105.92571884900012, 21.240355115000042],
              [105.925706738, 21.241491459000095],
              [105.92431663400001, 21.244035457000088],
              [105.92430754400004, 21.244887713000082],
              [105.92445442700003, 21.245315265000016],
              [105.92490416700012, 21.24574564600001],
              [105.93292845000005, 21.245962757000122],
              [105.93395723800009, 21.245763921000012],
              [105.93459870600005, 21.245552168000081],
              [105.935090761, 21.244967488000022],
              [105.93556722500007, 21.244376096000025],
              [105.93527585200003, 21.243751306000021],
              [105.93395041500006, 21.241319156000046],
              [105.93388946699999, 21.239679631000016],
              [105.93397243100002, 21.23924525100005],
              [105.93410259300003, 21.238555091000052],
              [105.936587215, 21.235927998000115],
              [105.93955818100005, 21.233766309000046],
              [105.94103693000002, 21.233319139000081],
              [105.94288699500007, 21.23367968700007],
              [105.94397616100012, 21.234153060000068],
              [105.94886966700008, 21.23604212000005],
              [105.94997630700009, 21.235937142000068],
              [105.95133707900006, 21.235027896000069],
              [105.95296287800001, 21.232277430000046],
              [105.95471508100005, 21.22918240500011],
              [105.95595538700007, 21.228041552000043],
              [105.95792774900011, 21.227368366000029],
              [105.97346306700005, 21.224142684000036],
              [105.97551967900004, 21.224161499000083],
              [105.97645085699999, 21.224520845000058],
              [105.98199616400007, 21.230711006000021],
              [105.98865824600009, 21.237437344000028],
              [105.98938441800004, 21.239548884000037],
              [105.98936992199999, 21.240952053000051],
              [105.987835992, 21.244621745000103],
              [105.98573922500006, 21.248461702000029],
              [105.98439215600007, 21.252133023000042],
              [105.98418336600011, 21.25423601400005],
              [105.9876782100001, 21.259880815000095],
              [105.99137293100009, 21.264299489000038],
              [105.99230252100008, 21.26483413000004],
              [105.99298412800007, 21.26484030800011],
              [105.99325652700006, 21.264842777000048],
              [106.00105173600004, 21.262321346000057],
              [106.00487136100006, 21.26108881200004],
              [106.00707234100001, 21.260194760000047],
              [106.00765583400006, 21.259956276000125],
              [106.01348478700007, 21.257589779000071],
              [106.02428139500006, 21.253144571000071],
              [106.02893941300007, 21.250499011000024],
              [106.03211312800003, 21.248006044000078],
              [106.03366135700006, 21.246490585000096],
              [106.03390895900003, 21.245067749000057],
              [106.03380090800006, 21.242540136000088],
              [106.03286565600004, 21.231241003],
              [106.03246356600008, 21.224879391000059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 7, code: "AD01", ten_tinh: "Bến Tre" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.42507837400005, 10.320189322000049],
              [106.44474289300004, 10.308206495000059],
              [106.44721476000011, 10.307762885000036],
              [106.45143481200004, 10.30774397000007],
              [106.46045897900011, 10.308135880000078],
              [106.46948177800004, 10.30823917500001],
              [106.47239013600009, 10.307793388000073],
              [106.47384597700001, 10.307103976000052],
              [106.47984858600009, 10.30425488700004],
              [106.48806271000009, 10.298493822000053],
              [106.49676352100005, 10.29210960200002],
              [106.50169717700005, 10.289203080000052],
              [106.51462460900004, 10.284384830000091],
              [106.51738299100001, 10.283074277000102],
              [106.51854288400006, 10.282203757000017],
              [106.52201631200003, 10.278294534000041],
              [106.52664811900011, 10.273226365000097],
              [106.53121639100009, 10.27010492300011],
              [106.53499234400005, 10.26864525500004],
              [106.552136854, 10.263661553000068],
              [106.57334940599999, 10.257648070000068],
              [106.58852301200002, 10.251590807000087],
              [106.59317273800008, 10.250414619000113],
              [106.60887614400006, 10.248607139000041],
              [106.61629054800007, 10.247561120000091],
              [106.63083138900006, 10.244345469000036],
              [106.64230239500006, 10.241807930000045],
              [106.65393221100007, 10.240163088000108],
              [106.66527614900005, 10.239528516000094],
              [106.66681285200009, 10.239317450000039],
              [106.67181790100005, 10.238629928000085],
              [106.6786444680001, 10.236576362000081],
              [106.68604366400008, 10.232789634000115],
              [106.69270769300009, 10.227564879000093],
              [106.703273665, 10.217561997000056],
              [106.71306291100004, 10.210480040000023],
              [106.71421378900004, 10.209647389000052],
              [106.72682874700011, 10.202228216],
              [106.73843353100008, 10.19639988500005],
              [106.74670519200006, 10.192895728000016],
              [106.75389804500003, 10.191631779000064],
              [106.76480476100006, 10.191284722000125],
              [106.7725438030001, 10.191497608000065],
              [106.77185761200009, 10.172237634000057],
              [106.77242712400005, 10.171981527000094],
              [106.77605719700001, 10.16890113800004],
              [106.78215039100004, 10.160872494000015],
              [106.78785058500006, 10.152841511000064],
              [106.78975297800008, 10.149750985000059],
              [106.78961878800007, 10.14587332300003],
              [106.78932484900008, 10.142459996000051],
              [106.7874636550001, 10.138572393000088],
              [106.78560160600004, 10.134839874000077],
              [106.78354645200008, 10.132789200000046],
              [106.78269820700007, 10.131075853000125],
              [106.7828671340001, 10.129060919000031],
              [106.78391954300005, 10.127231649000111],
              [106.78629090700005, 10.124454146000071],
              [106.78835187900006, 10.121054570000075],
              [106.78884292800009, 10.117645915000127],
              [106.7891842870001, 10.112995885000052],
              [106.78795401100008, 10.108646938000019],
              [106.78235693100008, 10.099465722000021],
              [106.77971233000002, 10.095418728000103],
              [106.76947600700004, 10.082242541000051],
              [106.76218793900009, 10.073669624000024],
              [106.753757046, 10.063810321000053],
              [106.74904216500006, 10.058237893000042],
              [106.74130500299999, 10.052789428000073],
              [106.73822534, 10.050496340000057],
              [106.73406747700005, 10.048054546000083],
              [106.73148541700003, 10.046759492000133],
              [106.73112888800001, 10.046722083000057],
              [106.71393733700009, 10.028605643000075],
              [106.70739994200008, 10.026095380000031],
              [106.68811963700009, 10.01859910200009],
              [106.68335217700006, 10.013340392000101],
              [106.67376479000002, 10.005319517000098],
              [106.67033338200005, 10.002028347000101],
              [106.66452762100002, 9.998864157000041],
              [106.66309465600003, 9.998002139000038],
              [106.66152339400004, 9.996286339000092],
              [106.65568315200008, 9.987435911000107],
              [106.65383644100005, 9.983870447000063],
              [106.64969584700003, 9.9791530740001],
              [106.64755137400005, 9.977149248000037],
              [106.65216612900005, 9.958706153000028],
              [106.65929128800011, 9.930224586000056],
              [106.66241136900007, 9.926075907000074],
              [106.66474997400012, 9.92026357800002],
              [106.66681957400003, 9.91427634800006],
              [106.66809990700008, 9.910738789000016],
              [106.67054595000006, 9.906727597000041],
              [106.67659819400006, 9.900299175000081],
              [106.68471447500006, 9.890259714000125],
              [106.68689376100008, 9.886720404000114],
              [106.6874951300001, 9.882602737000033],
              [106.68679441500004, 9.879755991000126],
              [106.68588532200005, 9.875771013000099],
              [106.68090363500005, 9.867923339000038],
              [106.67507809000006, 9.857228077000089],
              [106.66624464300006, 9.844950516000049],
              [106.66019011400005, 9.83652751000008],
              [106.65462520400003, 9.830239163000075],
              [106.65225672300008, 9.827393550000084],
              [106.64765778200007, 9.822299577000047],
              [106.64563271500002, 9.8205096940001],
              [106.64272076800005, 9.818771390000068],
              [106.64201259100007, 9.818354106000092],
              [106.64131881400004, 9.818366216000117],
              [106.64116946400003, 9.819217860000061],
              [106.64128358900005, 9.819889251000101],
              [106.64177375600011, 9.82077948100004],
              [106.64497288900004, 9.824828705000053],
              [106.64808837400007, 9.828709514000076],
              [106.65022738300004, 9.832342421000105],
              [106.65067847200007, 9.835052009000032],
              [106.65066822700001, 9.836615109000134],
              [106.65075669100005, 9.839309042000098],
              [106.65029589000002, 9.839890588000076],
              [106.64945930900008, 9.839869112000075],
              [106.64785411000005, 9.838527625000014],
              [106.64489330500004, 9.83472375300005],
              [106.64194458100003, 9.829870462000121],
              [106.63676714600011, 9.824581555000032],
              [106.63364455500003, 9.821537339000129],
              [106.631482229, 9.819573465000085],
              [106.63142413400007, 9.819114303000019],
              [106.63180614300006, 9.818721967000064],
              [106.63414317500008, 9.818618772000052],
              [106.63557044600003, 9.818432266000075],
              [106.63642290200005, 9.817892299000073],
              [106.63677401700005, 9.81625573100011],
              [106.63678907000006, 9.813982211000026],
              [106.63616378700002, 9.812266485000086],
              [106.63578324200006, 9.812193615000096],
              [106.63555457300006, 9.812352882000129],
              [106.63483031900009, 9.813200764000028],
              [106.63410700200009, 9.813906552000072],
              [106.63278983100001, 9.814558003000061],
              [106.63185366000009, 9.814576465000078],
              [106.63077867100004, 9.814402758000094],
              [106.62845571000005, 9.812971442000061],
              [106.62747714300008, 9.812099827000063],
              [106.62684450900005, 9.811148078000096],
              [106.62590347400008, 9.808535990000065],
              [106.62543113000008, 9.807506325000047],
              [106.62481255100006, 9.806740691000114],
              [106.62123676400005, 9.804159395000054],
              [106.61970993000001, 9.803850081000041],
              [106.61780156900004, 9.803627682000114],
              [106.614617213, 9.803816433000099],
              [106.60691498700007, 9.8068285470001],
              [106.60208702100002, 9.809290032000105],
              [106.59620724100006, 9.800222477000096],
              [106.58755476300011, 9.808507655000119],
              [106.58193095900003, 9.81696526800002],
              [106.57734114400004, 9.82386734000009],
              [106.57014789900005, 9.833541705000036],
              [106.56294003100007, 9.840191436000099],
              [106.55444910000008, 9.847945544000057],
              [106.55004047300008, 9.85197127600008],
              [106.53694331300008, 9.862428374000041],
              [106.52660478600008, 9.872192044000093],
              [106.52388875300007, 9.875220832000052],
              [106.51826415600009, 9.88149276400012],
              [106.50556566500003, 9.896295119000074],
              [106.49703416000006, 9.905785376000063],
              [106.49001805600005, 9.913378323000098],
              [106.47976809800009, 9.922308767000041],
              [106.46889725300009, 9.92962314700012],
              [106.468653551, 9.929787109000097],
              [106.45838656600006, 9.935125235000085],
              [106.44735277700008, 9.939521185],
              [106.43460594300011, 9.9444912980001],
              [106.42434111600006, 9.950584700000052],
              [106.41046208900003, 9.958394554000092],
              [106.40221953700008, 9.964695261000054],
              [106.39881303300004, 9.967299047000021],
              [106.39678420900003, 9.968849787000076],
              [106.38640289500005, 9.974691853000104],
              [106.37986125900004, 9.978372743000106],
              [106.37572364900009, 9.980688608000053],
              [106.37339594900004, 9.981991415000023],
              [106.37295867300001, 9.982371741000049],
              [106.36465978200002, 9.989589392000118],
              [106.35479128900008, 10.000000159000065],
              [106.33031001700007, 10.011512098000084],
              [106.32891775900008, 10.012166669000065],
              [106.32510931100009, 10.014272404000033],
              [106.3191979580001, 10.017540652],
              [106.31869907400004, 10.017871029000068],
              [106.30890199800007, 10.02435864200004],
              [106.30296653300003, 10.030870871],
              [106.29645436700007, 10.038683043000061],
              [106.28751437200005, 10.051509868000073],
              [106.27752778500012, 10.069759188000125],
              [106.27430865000004, 10.074502849000043],
              [106.27203339199998, 10.077855490000063],
              [106.26306753200004, 10.090867814000058],
              [106.25264381700001, 10.103164504000082],
              [106.246126858, 10.110399202000051],
              [106.23932702700004, 10.119797855000099],
              [106.23166566400003, 10.132227846000051],
              [106.22877473800003, 10.136997283000058],
              [106.22573552900009, 10.14104625100005],
              [106.21867361200003, 10.148456141000038],
              [106.21758125100006, 10.1496022680001],
              [106.21139167200005, 10.156096395000079],
              [106.20219211900005, 10.166152264000059],
              [106.19784146600006, 10.169773235000077],
              [106.18956934100004, 10.175138867000031],
              [106.18710267500006, 10.176878249000064],
              [106.18564792800004, 10.176883517000055],
              [106.18215492800005, 10.176463547000123],
              [106.17778485300003, 10.174893083000123],
              [106.17589368100005, 10.174899878000064],
              [106.17458494200004, 10.175048774000038],
              [106.17419976200006, 10.175337300000107],
              [106.17375095300005, 10.175673480000034],
              [106.17342430100011, 10.175918156000117],
              [106.16387205500006, 10.189507362000059],
              [106.16147917000004, 10.191606827000042],
              [106.15837060200005, 10.193093448000033],
              [106.15677857900005, 10.193955462000035],
              [106.14826496600004, 10.198575315000101],
              [106.14594543200005, 10.200890720000098],
              [106.14101886600011, 10.206531929000027],
              [106.13918592000006, 10.209704568000076],
              [106.13292087500011, 10.220547972000114],
              [106.12994879000004, 10.223728829000043],
              [106.12690534100003, 10.226985933000064],
              [106.12139300200002, 10.231763720000052],
              [106.11035578200003, 10.23785816800009],
              [106.10076476100005, 10.241495888000072],
              [106.09044002200002, 10.243405343000054],
              [106.08003871100006, 10.244233190000072],
              [106.07130851700008, 10.244406275000093],
              [106.05384993399998, 10.245328562000021],
              [106.04614059100001, 10.246218704000041],
              [106.04112578500003, 10.247893199000123],
              [106.03647915300006, 10.250936424000122],
              [106.0360147050001, 10.251585209000067],
              [106.03358192000006, 10.254983487000045],
              [106.03271628800012, 10.257293575000046],
              [106.03273700700005, 10.257773016000014],
              [106.03276297300008, 10.258373894000082],
              [106.03334563700005, 10.271856614000095],
              [106.02695133700007, 10.274616883000041],
              [106.02713459700004, 10.286297203000048],
              [106.02816639700006, 10.290331771000092],
              [106.03270893000004, 10.299835057000093],
              [106.04801958400004, 10.292418742000018],
              [106.05188940000005, 10.290543998000066],
              [106.06772634100007, 10.282705002000101],
              [106.07368329500009, 10.279801194000092],
              [106.07804422600006, 10.278344651000014],
              [106.08138987300003, 10.277900884000067],
              [106.08633726700002, 10.277740139000089],
              [106.08990438900003, 10.278232897000093],
              [106.09413059300002, 10.279949123000074],
              [106.10258563200007, 10.284102425000084],
              [106.10593529000002, 10.284812041000089],
              [106.11335873800006, 10.285219231000029],
              [106.12312081500008, 10.284741777000049],
              [106.12933725199998, 10.284444055000018],
              [106.13401832700006, 10.284473644000068],
              [106.13656805600007, 10.284489713000065],
              [106.14069423300006, 10.285241328000081],
              [106.14122801300009, 10.285338555000044],
              [106.14836872000009, 10.288053181000032],
              [106.15638533800011, 10.291485529000076],
              [106.16527897900002, 10.295923911000047],
              [106.16913356800009, 10.297782297000067],
              [106.18160787200007, 10.303795768000066],
              [106.19035716700003, 10.308378110000136],
              [106.20253309200011, 10.314605761000093],
              [106.20354247600005, 10.314897162000046],
              [106.20463992300007, 10.315213985000058],
              [106.20894387500003, 10.316456437000097],
              [106.21899482000005, 10.31872585200002],
              [106.23370141700008, 10.320544593000045],
              [106.24949823000006, 10.321997932000036],
              [106.25925670000004, 10.323834589000034],
              [106.28387475100008, 10.32921706700005],
              [106.2858404080001, 10.329425535000068],
              [106.28830811900008, 10.327829538000087],
              [106.28883999000004, 10.327395644000068],
              [106.29701144500007, 10.320729112000036],
              [106.29933704300002, 10.319998788000094],
              [106.30253879600008, 10.319985885000092],
              [106.30924109300005, 10.321833305],
              [106.326877278, 10.328105831000032],
              [106.35077081400004, 10.336038404000101],
              [106.35711947300007, 10.338145594000101],
              [106.36498665700006, 10.339986857000037],
              [106.36732464700006, 10.340382016000136],
              [106.37081260200009, 10.340971478000059],
              [106.37961488700009, 10.340285059000019],
              [106.39110606200008, 10.338793783000087],
              [106.39652631300001, 10.337133793000051],
              [106.39678015600006, 10.337056053000103],
              [106.39779184700009, 10.336746202000045],
              [106.39956155600007, 10.335786548000046],
              [106.40476089400002, 10.33296699800008],
              [106.42507837400005, 10.320189322000049],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 8, code: "AD01", ten_tinh: "Bình Định" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.36862125300007, 13.591601958000064],
              [109.36824201700006, 13.591578165000049],
              [109.36786330500007, 13.591610759000082],
              [109.36755178100005, 13.591689803000049],
              [109.36717625700007, 13.591848585000042],
              [109.36685586400003, 13.592048770000085],
              [109.36665737300004, 13.592218245000074],
              [109.3664263900001, 13.592476758000059],
              [109.3662491210001, 13.592739514000039],
              [109.36613489500006, 13.59297175300007],
              [109.36604733200009, 13.593214824000109],
              [109.36598217400004, 13.593523119000038],
              [109.36595799600008, 13.593865640000045],
              [109.36598998900006, 13.59420754600009],
              [109.36607159800006, 13.594541471000097],
              [109.36620748800001, 13.59485793300008],
              [109.36643936100002, 13.595221846000081],
              [109.36673984800011, 13.595534067000068],
              [109.36704301200005, 13.595758217000052],
              [109.36740830400008, 13.595938633000058],
              [109.36774193400007, 13.596045249000086],
              [109.36804586600007, 13.596097515000059],
              [109.36835235200003, 13.596105240000076],
              [109.368700808, 13.596063686000013],
              [109.36912250100008, 13.595946513000088],
              [109.3693879460001, 13.595825933000059],
              [109.36961100300007, 13.595688765000078],
              [109.3698173760001, 13.595528549000056],
              [109.37002183700005, 13.595323710000072],
              [109.37020020400007, 13.595096739000033],
              [109.37036958000004, 13.594796046000022],
              [109.37051058499999, 13.594420279000106],
              [109.3705687290001, 13.594139703000073],
              [109.37058679799999, 13.59381500200003],
              [109.37055604900011, 13.593462587000092],
              [109.37048909200007, 13.593183893000036],
              [109.37038672600005, 13.592915688000049],
              [109.3702661450001, 13.592686542000097],
              [109.37010044600007, 13.592450566000112],
              [109.36984267000005, 13.592177298000061],
              [109.36949337000003, 13.591918358000042],
              [109.36915618600001, 13.591746721000051],
              [109.36890749000004, 13.591661633000028],
              [109.36862125300007, 13.591601958000064],
            ],
          ],
          [
            [
              [109.35968179700009, 13.605543156000087],
              [109.35911212800004, 13.605442573000024],
              [109.35804421700004, 13.605580174000041],
              [109.35712228300007, 13.605798279000057],
              [109.35317874800009, 13.607245798000099],
              [109.34991597300004, 13.608835736000023],
              [109.34848943700005, 13.609792501000049],
              [109.34630523400007, 13.611477550000115],
              [109.34521469200008, 13.61255879500005],
              [109.34110109900007, 13.617598372000115],
              [109.33876762000008, 13.620152096000114],
              [109.33795282600008, 13.62107997900006],
              [109.33638610800004, 13.623954753000065],
              [109.33567314100003, 13.626824141000069],
              [109.33520095600004, 13.629214537000054],
              [109.33521822600004, 13.631840593000087],
              [109.33546659600007, 13.633546272000084],
              [109.33596078100001, 13.63449813400006],
              [109.33692759100008, 13.634940202000044],
              [109.33829117000002, 13.635402392000094],
              [109.34024435800006, 13.635628804000087],
              [109.341140022, 13.635436691000089],
              [109.34194806900007, 13.635021185000083],
              [109.34316071800004, 13.633939170000085],
              [109.34502716300003, 13.630909252000032],
              [109.34551403500006, 13.62981764500004],
              [109.34716004500009, 13.626974480000074],
              [109.3481562530001, 13.625519427000119],
              [109.349316383, 13.624111682000082],
              [109.34991610900005, 13.623576198000096],
              [109.35058820900007, 13.623008460000017],
              [109.35392731200002, 13.620501202000044],
              [109.35731923800002, 13.617017791000039],
              [109.36046392600007, 13.61305844200009],
              [109.36109182800006, 13.611771939000048],
              [109.36166674000002, 13.610543955000052],
              [109.36152796800008, 13.608038085000105],
              [109.36078661400003, 13.606610401000106],
              [109.36038570500007, 13.606088232000067],
              [109.35968179700009, 13.605543156000087],
            ],
          ],
          [
            [
              [109.25290264700004, 13.67954222100005],
              [109.25246629499999, 13.679488336000121],
              [109.25147072000004, 13.679596811000037],
              [109.25005632100007, 13.679810353000031],
              [109.24976634000006, 13.679892680000092],
              [109.24906296200004, 13.680277928000082],
              [109.24875296800006, 13.680624834000097],
              [109.24854336600004, 13.681050518000058],
              [109.24848291000009, 13.681539347000092],
              [109.24854936100009, 13.68202523500006],
              [109.24903230600009, 13.683817993000067],
              [109.24929952700008, 13.684637267000086],
              [109.24972499300002, 13.685609518000062],
              [109.25007841700004, 13.685804125000088],
              [109.25046037800011, 13.685843369000048],
              [109.250799284, 13.685779128000062],
              [109.25111596400009, 13.685549026000031],
              [109.25186843600008, 13.684570833000096],
              [109.25306205000008, 13.682562982000055],
              [109.25346652600007, 13.681572226000059],
              [109.25361073600001, 13.680886685000091],
              [109.25357116900003, 13.680097450000071],
              [109.25343469000009, 13.67985030300005],
              [109.25320129900005, 13.679637905000062],
              [109.25290264700004, 13.67954222100005],
            ],
          ],
          [
            [
              [109.23825852500003, 13.68277152700008],
              [109.23630661800009, 13.682616911000077],
              [109.23588300900003, 13.682702373000042],
              [109.23537787000006, 13.683327758000095],
              [109.23533899900005, 13.683908945000043],
              [109.23534430300005, 13.684780346000112],
              [109.2356464800001, 13.685650012000037],
              [109.23598829600004, 13.686062992000101],
              [109.23652033900001, 13.686371117000105],
              [109.23775406800004, 13.68698638300012],
              [109.23824268800006, 13.687128775000089],
              [109.23853957300001, 13.687127043000123],
              [109.23887759900001, 13.686917586000023],
              [109.23912826800006, 13.686293667000092],
              [109.23933222200006, 13.684964580000079],
              [109.23966517500007, 13.683925221000058],
              [109.23966162700003, 13.683344290000042],
              [109.23949021000007, 13.683054811000071],
              [109.23898000700004, 13.682850304000025],
              [109.23825852500003, 13.68277152700008],
            ],
          ],
          [
            [
              [109.29793393400004, 13.762392855000092],
              [109.29757397200009, 13.762365200000113],
              [109.2970319400001, 13.762613059000016],
              [109.29672051600009, 13.762988301000066],
              [109.29635490200003, 13.763740570000047],
              [109.29614455300003, 13.764368111000101],
              [109.29391818400003, 13.766290006000046],
              [109.29377468700007, 13.766565773000126],
              [109.29375926800003, 13.766917912000041],
              [109.29390924700002, 13.767522777000083],
              [109.29428982800006, 13.76825505600001],
              [109.29468710800006, 13.768459084000105],
              [109.29544891100004, 13.768061808000096],
              [109.29715479400005, 13.76696656600007],
              [109.29800935400006, 13.766192513000062],
              [109.29876228900009, 13.76524164600008],
              [109.29904872, 13.764765584000125],
              [109.29907717300003, 13.764388388],
              [109.29908244500002, 13.76365878200003],
              [109.29895764600009, 13.763129561000119],
              [109.29872885200008, 13.762750584000026],
              [109.29829298900007, 13.762546297000087],
              [109.29793393400004, 13.762392855000092],
            ],
          ],
          [
            [
              [109.23241238200002, 13.858158873000013],
              [109.231091754, 13.85784420400009],
              [109.23003798700003, 13.858043705000076],
              [109.22905523300007, 13.85908082200009],
              [109.22880047400004, 13.860564979000054],
              [109.22862219300004, 13.863789250000112],
              [109.22945328200005, 13.870359860000024],
              [109.22972157600005, 13.871696179000102],
              [109.23027308400003, 13.876275664000024],
              [109.23031617400004, 13.877464669000082],
              [109.23044797300003, 13.878524814000047],
              [109.23098267100004, 13.879940695000046],
              [109.23164825800004, 13.880762014000057],
              [109.23267909100007, 13.881294198000125],
              [109.23363414100004, 13.881432172000084],
              [109.234439987, 13.881176345000055],
              [109.23509750800005, 13.880670240000102],
              [109.2359729290001, 13.879798412000087],
              [109.23800410700002, 13.877659240000105],
              [109.24127704600002, 13.873707767],
              [109.24264556800001, 13.871056699000038],
              [109.24308856100008, 13.868024253000042],
              [109.24268425700002, 13.866608383000044],
              [109.24168139000008, 13.864357978],
              [109.24021819600009, 13.862303658000117],
              [109.23935669700003, 13.861599569000036],
              [109.23604934600003, 13.859878290000081],
              [109.23241238200002, 13.858158873000013],
            ],
          ],
          [
            [
              [109.30086474100005, 13.878642844000042],
              [109.30051449900003, 13.878615058000053],
              [109.30013452100003, 13.878638155000099],
              [109.29987654800001, 13.878692223000131],
              [109.29962617800007, 13.878773541000021],
              [109.29936194900006, 13.878897419000056],
              [109.29904581000002, 13.879104571000054],
              [109.29878685900009, 13.879336774000082],
              [109.29858509500009, 13.879582319000059],
              [109.29841897400011, 13.879852151000092],
              [109.29831259000011, 13.88008803900007],
              [109.29822349800001, 13.88039063600006],
              [109.29817161600008, 13.880759058000079],
              [109.29817647100003, 13.881073901000017],
              [109.29822731500006, 13.881384839000075],
              [109.29832051300005, 13.881686268000058],
              [109.29844223, 13.881946743000116],
              [109.29863238900001, 13.882235548000031],
              [109.29888437200007, 13.882514297000091],
              [109.29910777700007, 13.88269963700006],
              [109.29932883200007, 13.882840361000069],
              [109.29959152500005, 13.882967343000095],
              [109.29992560500007, 13.883074097000046],
              [109.30030114700006, 13.883134926000105],
              [109.30056488800003, 13.883142984000061],
              [109.30094190500004, 13.883097098000084],
              [109.30139045400003, 13.882962799000074],
              [109.30175136400005, 13.882773171000016],
              [109.30209523500008, 13.882506716000085],
              [109.30233005200006, 13.882251192000082],
              [109.302533199, 13.881936698000084],
              [109.30267637900003, 13.881623106000072],
              [109.30275903500004, 13.881318767000026],
              [109.30280205200003, 13.880841771000084],
              [109.30276685000005, 13.880472474000081],
              [109.30269748800001, 13.880194276000067],
              [109.30260320400001, 13.879953530000043],
              [109.30243458400001, 13.87965222800007],
              [109.30222363200005, 13.879377555000081],
              [109.30194732300001, 13.879121778000057],
              [109.30160869800007, 13.878896314000054],
              [109.30126099300007, 13.878745204000074],
              [109.30086474100005, 13.878642844000042],
            ],
          ],
          [
            [
              [109.34564829500007, 13.895772105000058],
              [109.34529801700005, 13.895744394000074],
              [109.34491801200005, 13.895767571000038],
              [109.34466002900011, 13.895821693000075],
              [109.344409656, 13.895903065000057],
              [109.34414543200008, 13.896027001],
              [109.34382931200003, 13.896234220000013],
              [109.3435703910001, 13.896466481000045],
              [109.34336866300002, 13.896712071000115],
              [109.34320258800004, 13.896981940000062],
              [109.34309624700003, 13.89721785300013],
              [109.34300721400004, 13.897520471000034],
              [109.34295540900011, 13.897888907000054],
              [109.34296033400007, 13.898203753000081],
              [109.34301125200003, 13.89851468300005],
              [109.3431045240001, 13.89881609600012],
              [109.34322630900004, 13.899076548],
              [109.34341654700007, 13.899365314000088],
              [109.34366861300006, 13.899644013000055],
              [109.34389207800007, 13.899829308000021],
              [109.34411318300008, 13.899969987000114],
              [109.34437592600005, 13.900096914000047],
              [109.34471005700007, 13.900203599000081],
              [109.34508564500007, 13.900264349000045],
              [109.34534941000004, 13.900272352000085],
              [109.34572644900005, 13.900226386000012],
              [109.34617500600004, 13.900091991000084],
              [109.34653590400006, 13.899902286000019],
              [109.34687974500009, 13.899635755000082],
              [109.34711452600007, 13.899380180000074],
              [109.34731762100009, 13.899065640000073],
              [109.34746074400002, 13.898752014000058],
              [109.3475433400001, 13.898447655000011],
              [109.34758625500011, 13.897970645000067],
              [109.34755096800004, 13.897601353000065],
              [109.34748153900006, 13.897323167000051],
              [109.34738719300006, 13.897082438000028],
              [109.34721849300006, 13.896781169000059],
              [109.3470074630001, 13.896506537000073],
              [109.34673107400005, 13.896250816000054],
              [109.34639237100001, 13.896025421000056],
              [109.3460446030001, 13.895874383000082],
              [109.34564829500007, 13.895772105000058],
            ],
          ],
          [
            [
              [109.35008574500006, 13.897857001000078],
              [109.34973546300003, 13.897829297],
              [109.34935545400003, 13.89785248200006],
              [109.34909747000009, 13.897906610000097],
              [109.34884709700006, 13.89798798700008],
              [109.34858287200005, 13.898111928000032],
              [109.34826675400009, 13.898319155000125],
              [109.34800783499999, 13.89855142100007],
              [109.3478061110001, 13.898797015000051],
              [109.34764003900003, 13.899066889],
              [109.34753370200011, 13.899302804000067],
              [109.34744467500012, 13.899605424000059],
              [109.34739287800009, 13.899973862000079],
              [109.34739781000006, 13.900288707000106],
              [109.3474487350001, 13.900599637000076],
              [109.34754201500009, 13.900901048000057],
              [109.34766380600004, 13.901161498000112],
              [109.34785405300006, 13.901450261000024],
              [109.34810612700005, 13.901728955000079],
              [109.34832959800006, 13.901914245000045],
              [109.34855070900005, 13.902054920000049],
              [109.34881345800004, 13.90218184200007],
              [109.34914759500006, 13.902288520000104],
              [109.34952318700005, 13.902349262000067],
              [109.34978695500003, 13.902357260000107],
              [109.35016399700005, 13.902311286000034],
              [109.35061255600004, 13.902176881000104],
              [109.35097345400006, 13.901987168000039],
              [109.35131729300008, 13.901720630000101],
              [109.35155207100006, 13.901465050000093],
              [109.35175516000007, 13.901150505000091],
              [109.35189827700002, 13.900836876000076],
              [109.3519808680001, 13.900532514000117],
              [109.35202377300001, 13.900055503000084],
              [109.35198847800011, 13.899686211000082],
              [109.35191904200005, 13.899408026000069],
              [109.35182469000006, 13.899167299000046],
              [109.35165598200004, 13.898866033000077],
              [109.35144494300008, 13.898591405000092],
              [109.35116854600003, 13.898335690000073],
              [109.35082983400008, 13.898110302000076],
              [109.35048205900007, 13.897959271000014],
              [109.35008574500006, 13.897857001000078],
            ],
          ],
          [
            [
              [109.31606165400007, 13.899800338000111],
              [109.31571137400003, 13.899772577000123],
              [109.31533135800004, 13.899795702000082],
              [109.31507336200009, 13.899849788000028],
              [109.31482297200007, 13.899931126000096],
              [109.31455872400005, 13.900055025000045],
              [109.3142425680001, 13.900262201000134],
              [109.31398360800007, 13.900494426000074],
              [109.31378184000008, 13.900739988000053],
              [109.31361572200009, 13.901009835000087],
              [109.31350934500009, 13.901245734000065],
              [109.31342026600001, 13.901548340000057],
              [109.31336840600007, 13.901916770000076],
              [109.31337328500003, 13.902231616000014],
              [109.31342415900006, 13.902542555000073],
              [109.31351738900005, 13.902843981000055],
              [109.31363913800008, 13.903104450000024],
              [109.3138293380001, 13.903393244000116],
              [109.31408136800007, 13.903671979000086],
              [109.3143048100001, 13.903857305000054],
              [109.31452590000001, 13.903998016000061],
              [109.31478863000007, 13.904124979000086],
              [109.31512275200001, 13.904231711000124],
              [109.31549833700008, 13.904292514000092],
              [109.31576210700005, 13.904300554000047],
              [109.31613916, 13.904254640000067],
              [109.31658774600007, 13.904120307000055],
              [109.31694867900009, 13.903930651000083],
              [109.31729256600003, 13.903664168000061],
              [109.31752738900011, 13.903408625000056],
              [109.31773053300003, 13.903094113000057],
              [109.31787370400006, 13.902780506000044],
              [109.31795634700006, 13.902476158000086],
              [109.31799933200007, 13.901999153000054],
              [109.31796409900008, 13.901629854000051],
              [109.31789470900003, 13.901351658000037],
              [109.31780039600004, 13.901110916000013],
              [109.31763173700003, 13.900809622000041],
              [109.31742074200007, 13.900534961000053],
              [109.31714438500003, 13.90027920100003],
              [109.31680570800008, 13.900053758000029],
              [109.31645795499999, 13.899902671000051],
              [109.31606165400007, 13.899800338000111],
            ],
          ],
          [
            [
              [109.29894384700002, 14.142060198000104],
              [109.29729472, 14.141297719000077],
              [109.29540734400007, 14.141368643000083],
              [109.29468153500005, 14.142145612000066],
              [109.29462786000005, 14.143275042000079],
              [109.29542596400009, 14.144280433000043],
              [109.29628269700001, 14.144928913000053],
              [109.297505195, 14.145634564000085],
              [109.29799427400005, 14.145928719],
              [109.30067372700006, 14.145912353000066],
              [109.30127887800003, 14.145314385000061],
              [109.30127048100006, 14.144007047000072],
              [109.30041144700003, 14.143002050000051],
              [109.29894384700002, 14.142060198000104],
            ],
          ],
          [
            [
              [109.19926388800009, 14.230916174000095],
              [109.19891305000002, 14.230888216],
              [109.19853239900004, 14.230911135000033],
              [109.19827395000007, 14.230965089000057],
              [109.1980231040001, 14.231046302000026],
              [109.19775835300004, 14.231170077000053],
              [109.19744156000003, 14.231377114000042],
              [109.19718204300005, 14.231609235000063],
              [109.19697980400011, 14.231854728000124],
              [109.19681325800009, 14.232124529000066],
              [109.19670657100005, 14.232360408000131],
              [109.19661717200003, 14.232663017000034],
              [109.19656501500006, 14.233031479000056],
              [109.1965697200001, 14.233346381000088],
              [109.19662049700004, 14.233657400000064],
              [109.19671370600003, 14.233958928000055],
              [109.19683550400006, 14.234219508000121],
              [109.1970258490001, 14.234508454000137],
              [109.19727813, 14.234787374000033],
              [109.19750183000005, 14.234972854000103],
              [109.19772320000007, 14.235113710000034],
              [109.19798628700008, 14.235240839000072],
              [109.19832089300004, 14.235347773000038],
              [109.19869705800008, 14.235408792000024],
              [109.19896125400001, 14.23541697800008],
              [109.19933895, 14.235371264000028],
              [109.19978834700005, 14.235237155000034],
              [109.20014998000003, 14.235047666000076],
              [109.20049458300004, 14.234781327000066],
              [109.20072993700006, 14.234525871000068],
              [109.20093359500004, 14.234211417000074],
              [109.2010771810001, 14.233897837000063],
              [109.20116013400005, 14.233593483000016],
              [109.20120346500008, 14.233116422000068],
              [109.20116838700001, 14.232747042000058],
              [109.20109904400005, 14.232468761000037],
              [109.20100471700006, 14.232227927000094],
              [109.20083595500004, 14.231926490000111],
              [109.20062477400006, 14.231651666000021],
              [109.200348112, 14.23139571200007],
              [109.20000901200009, 14.23117004500005],
              [109.19966077700006, 14.231018742000055],
              [109.19926388800009, 14.230916174000095],
            ],
          ],
          [
            [
              [109.20796616100007, 14.244792332000065],
              [109.20761529900008, 14.244764389000062],
              [109.20723462300003, 14.244787324000095],
              [109.20697615800005, 14.244841288000119],
              [109.20672529899998, 14.244922512000089],
              [109.20646053400002, 14.245046298000117],
              [109.20614372900009, 14.245253350000018],
              [109.20588420300002, 14.24548548300004],
              [109.20568196100008, 14.245730986000103],
              [109.20551541600007, 14.246000796000134],
              [109.20540873100003, 14.246236681000111],
              [109.205319339, 14.246539295000014],
              [109.20526719400004, 14.246907762000125],
              [109.20527191400008, 14.247222666000068],
              [109.20532270800001, 14.247533684000045],
              [109.20541593600009, 14.247835210000035],
              [109.20553775300004, 14.248095786000102],
              [109.20572812299999, 14.248384727000117],
              [109.205980434, 14.248663638000012],
              [109.20620415800003, 14.248849111000082],
              [109.20642554900004, 14.248989958000012],
              [109.20668865900006, 14.249117077000049],
              [109.20702329300003, 14.249223998000014],
              [109.20739948600007, 14.249285002000088],
              [109.2076637000001, 14.249293177000053],
              [109.20804142, 14.249247448000089],
              [109.20849084300005, 14.249113319000093],
              [109.20885249200003, 14.248923814000046],
              [109.20919710700005, 14.248657460000034],
              [109.20943246600007, 14.248401992000124],
              [109.20963612500005, 14.24808752800004],
              [109.20977970700002, 14.247773940000029],
              [109.20986265300004, 14.24746958100007],
              [109.20990596600008, 14.246992515000032],
              [109.20987087000002, 14.246623135000023],
              [109.20980151000005, 14.246344855000091],
              [109.20970716600006, 14.24610402300006],
              [109.20953838000004, 14.245802591000077],
              [109.20932717300006, 14.245527774000076],
              [109.20905048100008, 14.245271829000037],
              [109.20871134700009, 14.245046175000107],
              [109.20836308300005, 14.244894885000024],
              [109.20796616100007, 14.244792332000065],
            ],
          ],
          [
            [
              [109.20747583600009, 14.249799567000119],
              [109.20712496500002, 14.249771622000116],
              [109.20674428000004, 14.24979455600006],
              [109.20648580900007, 14.249848521000084],
              [109.206234943, 14.249929744000054],
              [109.20597017200004, 14.250053530000082],
              [109.20565335800002, 14.250260581000072],
              [109.20539382500004, 14.250492715000094],
              [109.20519157800001, 14.250738217000068],
              [109.20502502699999, 14.251008027000099],
              [109.20491834000005, 14.251243913000076],
              [109.20482894500002, 14.251546527000068],
              [109.20477679800005, 14.251914995000091],
              [109.204781516, 14.252229899000033],
              [109.20483231100003, 14.252540918000099],
              [109.20492554100011, 14.252842445000089],
              [109.20504736000007, 14.253103023000067],
              [109.20523773400001, 14.253391965000082],
              [109.20549005100001, 14.253670877000067],
              [109.20571377900005, 14.253856350000136],
              [109.20593517600007, 14.253997198000066],
              [109.20619829200008, 14.254124319000104],
              [109.20653293400005, 14.254231240000069],
              [109.20690913600001, 14.254292245000054],
              [109.20717335700002, 14.254300421000019],
              [109.2075510870001, 14.254254692000055],
              [109.20800052000007, 14.25412056500006],
              [109.20836217900006, 14.253931060000101],
              [109.20870680300007, 14.253664706000089],
              [109.2089421690001, 14.25340923800009],
              [109.20914583300006, 14.253094774000095],
              [109.20928942000005, 14.252781185000083],
              [109.20937236900006, 14.252476826000036],
              [109.20941568400011, 14.251999759000087],
              [109.20938058800004, 14.251630378000078],
              [109.20931122700007, 14.251352097000057],
              [109.20921688099999, 14.251111264000114],
              [109.20904809100006, 14.250809831000042],
              [109.20883688, 14.250535013000041],
              [109.20856018200001, 14.250279067000092],
              [109.20822104000011, 14.250053412000073],
              [109.20787276700007, 14.249902121000078],
              [109.20747583600009, 14.249799567000119],
            ],
          ],
          [
            [
              [108.94643237800007, 14.688640680000018],
              [108.95413750600005, 14.683150086000028],
              [108.956646656, 14.686376780000019],
              [108.95831051600001, 14.686639137000055],
              [108.96098397200007, 14.685547798000085],
              [108.96562440800005, 14.681542855000094],
              [108.96852684900003, 14.679037808000126],
              [108.97367484800002, 14.673977598000041],
              [108.97653190400007, 14.672705338000112],
              [108.97967167400006, 14.672600992000094],
              [108.98567938500003, 14.673472731000102],
              [108.98912999900007, 14.67453709400006],
              [108.99172949700004, 14.674984877000073],
              [108.99345721400007, 14.67421040100003],
              [108.99525608500005, 14.671902684000047],
              [108.99823846500009, 14.670202580000067],
              [109.00091396600004, 14.67011339900006],
              [109.00343548800004, 14.670714693000074],
              [109.0056433550001, 14.671547384000048],
              [109.007137556, 14.671310395000068],
              [109.01043426400003, 14.669531986],
              [109.01553063400007, 14.665675585000047],
              [109.01951884000002, 14.660598053000038],
              [109.02389489899998, 14.654445596000086],
              [109.02553740500008, 14.65244496200002],
              [109.02882973400008, 14.649899869000114],
              [109.03157895500001, 14.648890222000064],
              [109.03400866700005, 14.646962364000069],
              [109.03635020600008, 14.643195470000057],
              [109.04038591300004, 14.637147626000056],
              [109.04342789200011, 14.631921027000059],
              [109.04404639400003, 14.628438862000069],
              [109.04452079600003, 14.622460210000019],
              [109.04443445300008, 14.619718561000104],
              [109.04311560700003, 14.615928303000112],
              [109.04245039700005, 14.612908260000077],
              [109.04280040700006, 14.610797263000029],
              [109.04423197200003, 14.608470048000031],
              [109.04681214000009, 14.604941933000058],
              [109.04903681100004, 14.602470171000091],
              [109.05060762400007, 14.601432293000103],
              [109.05290907500006, 14.600917068000017],
              [109.05398556100005, 14.5999498100001],
              [109.05664831100009, 14.596592737000067],
              [109.05888422200009, 14.594687489000078],
              [109.06232766400007, 14.592641883000022],
              [109.06527593300002, 14.590588215000098],
              [109.06793398300005, 14.588254723000114],
              [109.071017528, 14.584512883000043],
              [109.07352327200003, 14.582831710000031],
              [109.07669115600004, 14.581409555000045],
              [109.07935270700008, 14.579778997000046],
              [109.07962959300008, 14.579504036000033],
              [109.07834021600006, 14.578260559000087],
              [109.07601770900001, 14.577644512000097],
              [109.07331335400006, 14.577909255000064],
              [109.06996810600003, 14.578804982000102],
              [109.06916309900004, 14.57861315600004],
              [109.06890725500001, 14.578552185000058],
              [109.06763965200003, 14.577058849000045],
              [109.06464852600007, 14.571800380000075],
              [109.06411401400008, 14.568161874000028],
              [109.06550456500003, 14.559479131000062],
              [109.06776670900005, 14.552323396000054],
              [109.07404589000001, 14.533897831000074],
              [109.07773264900005, 14.524713636000095],
              [109.08091997400008, 14.51854527000007],
              [109.09488335800008, 14.488749734000093],
              [109.09499269500006, 14.488516387000059],
              [109.09860181500008, 14.483555641000033],
              [109.11324531300002, 14.45905454400007],
              [109.11841072000003, 14.450050494000076],
              [109.12083282900005, 14.445643442000032],
              [109.12081474400007, 14.442379185000041],
              [109.120024835, 14.439244543000116],
              [109.11601337600008, 14.435624606000095],
              [109.11366751200006, 14.4304892680001],
              [109.11273865600003, 14.425472077000022],
              [109.11423658300006, 14.416926800000061],
              [109.11804534700008, 14.407239458000019],
              [109.12078356500007, 14.401889192000093],
              [109.12284807, 14.397915513000024],
              [109.12320364200011, 14.397231105000062],
              [109.12370391300003, 14.394591926000098],
              [109.12330240100002, 14.391831956000088],
              [109.1207119420001, 14.388957970000058],
              [109.11927586100002, 14.38532459500008],
              [109.11847022900007, 14.379302480000076],
              [109.11843979100003, 14.373369373000065],
              [109.11900814800008, 14.371829521000022],
              [109.13340582100003, 14.347259784000064],
              [109.14712927900007, 14.328476275000044],
              [109.15118214300003, 14.322070851000122],
              [109.16168605500005, 14.307695204000124],
              [109.16989140300004, 14.296745151000055],
              [109.17745974900006, 14.286825018000025],
              [109.17841458200012, 14.285680422],
              [109.17984622100009, 14.283863],
              [109.18056398000006, 14.28328936500005],
              [109.18121560700006, 14.283151709000029],
              [109.18173087000005, 14.283182366000078],
              [109.18269387400007, 14.283445082000055],
              [109.18334667500008, 14.28350848000006],
              [109.18399674200005, 14.283102750000063],
              [109.184303223, 14.282665431000028],
              [109.18433325100008, 14.281928059000036],
              [109.18377836900001, 14.280992893000109],
              [109.18270491900009, 14.279457445000075],
              [109.18239451600007, 14.279224604000083],
              [109.18181040700007, 14.279160832000047],
              [109.18081561700009, 14.279333902000067],
              [109.17988774600006, 14.27920500600006],
              [109.17909663800006, 14.278974824000022],
              [109.17854569000004, 14.278709802000032],
              [109.17804016500004, 14.278257282000071],
              [109.17740608900007, 14.277441925000021],
              [109.17697979500007, 14.276284218000066],
              [109.17696749700011, 14.274168877000102],
              [109.17736839000005, 14.270959441000029],
              [109.17777958200007, 14.269524153000111],
              [109.1786001090001, 14.267839222000054],
              [109.18023413200004, 14.264767961000045],
              [109.18101266200006, 14.263381806000018],
              [109.18223398000003, 14.26148994000007],
              [109.18446059400006, 14.258142404000028],
              [109.18529230600004, 14.256892421000076],
              [109.18656558200004, 14.254932006000052],
              [109.18691216000003, 14.254418290000091],
              [109.18748537100004, 14.253800946000082],
              [109.18837208500004, 14.252994199000034],
              [109.19060913700004, 14.251454865000122],
              [109.191131398, 14.251093688000081],
              [109.19156575200006, 14.25064771400004],
              [109.19186007600007, 14.250168399000083],
              [109.19194495800008, 14.249741430000105],
              [109.19202823300003, 14.249041530000015],
              [109.19213616800009, 14.248077059000062],
              [109.19225198700008, 14.246967542],
              [109.19248965000003, 14.245772045000125],
              [109.19257442800003, 14.245328019000068],
              [109.19284026400008, 14.244465028000073],
              [109.19441128600003, 14.239671033000077],
              [109.19484765400009, 14.23809058800005],
              [109.19521126700006, 14.237508528000118],
              [109.19569710900002, 14.23690871500005],
              [109.19600988800005, 14.236599886000077],
              [109.19618294800007, 14.236308909000071],
              [109.19626801700009, 14.235916064000074],
              [109.19630065300004, 14.235523513000102],
              [109.1962530800001, 14.234866990000048],
              [109.19628128200004, 14.233723846000055],
              [109.19624370800004, 14.233280518000056],
              [109.19610227900006, 14.233008357000097],
              [109.19581971600005, 14.232515224000062],
              [109.19494831400006, 14.231453906000089],
              [109.1921835320001, 14.22792105900008],
              [109.19183249400001, 14.22766713700012],
              [109.19139457600001, 14.227498996000064],
              [109.19113222700007, 14.227466350000027],
              [109.1907655210001, 14.227519575000079],
              [109.19052155100007, 14.227640359000036],
              [109.19015583700011, 14.22786417000008],
              [109.18972092700008, 14.22820779500009],
              [109.18924297500007, 14.228662538000057],
              [109.18867004100009, 14.229313993000046],
              [109.18846119500004, 14.229573587000031],
              [109.18782466400005, 14.230202045000071],
              [109.18756976300008, 14.230404829000063],
              [109.18730726200005, 14.230524335],
              [109.18700182800009, 14.230602412000069],
              [109.18671022000002, 14.230610984000068],
              [109.18627582300006, 14.230530087000124],
              [109.18596945300001, 14.230448466000032],
              [109.18568837600003, 14.230343997000093],
              [109.18479806800009, 14.229958892000115],
              [109.18405667800003, 14.229574534000021],
              [109.18319179300005, 14.228767888000069],
              [109.18109281500003, 14.225741280000113],
              [109.18044342100004, 14.224211423],
              [109.18006627300008, 14.22290934600001],
              [109.17982528000005, 14.220579818000088],
              [109.17964049800003, 14.218138995000105],
              [109.17973933100004, 14.215585607000046],
              [109.17995606500003, 14.213753017000029],
              [109.18097819200004, 14.208669429000052],
              [109.1813664260001, 14.207002383000054],
              [109.18198107500007, 14.205167597000033],
              [109.18265223600008, 14.203276991000012],
              [109.18334970100007, 14.201025516000064],
              [109.18413161000009, 14.198634837000055],
              [109.18547903400002, 14.195741545000116],
              [109.18738706000008, 14.191513213000027],
              [109.18977247000001, 14.186311028000096],
              [109.19123309000005, 14.183361585000041],
              [109.19314316800009, 14.179521680000027],
              [109.19654779600006, 14.173703277000092],
              [109.19947617300006, 14.169080673000066],
              [109.20634102300008, 14.157387868000091],
              [109.20791651600004, 14.154715176000108],
              [109.209662467, 14.152041498000044],
              [109.21230972400011, 14.148030804000109],
              [109.21487346000004, 14.144325777000065],
              [109.21673422400008, 14.141873381000012],
              [109.21865277400011, 14.139587117000117],
              [109.21955770000001, 14.138860507000038],
              [109.22029514800002, 14.138634296000061],
              [109.22103393300003, 14.138630057000066],
              [109.22165972800012, 14.13873745500007],
              [109.22240052200004, 14.13906616300009],
              [109.22359796799999, 14.139725217000086],
              [109.22433876900001, 14.140053919000104],
              [109.22496423400007, 14.140105814000046],
              [109.22564384400007, 14.139713439000088],
              [109.22592497100007, 14.139212368000107],
              [109.22580459600005, 14.138103170000081],
              [109.2254004120001, 14.137051102000074],
              [109.22347099800007, 14.13281688000003],
              [109.22306650100008, 14.13170931700005],
              [109.22209139300004, 14.130216575000086],
              [109.22125765400007, 14.129099724000048],
              [109.22067345700005, 14.128434931000109],
              [109.22014409200004, 14.127924038000026],
              [109.21929438800008, 14.127414981000095],
              [109.21812529900006, 14.127064161000112],
              [109.21716294400002, 14.12684622000002],
              [109.21526394100002, 14.126879421000103],
              [109.21414463900004, 14.127198644000048],
              [109.21214529300008, 14.128142448000068],
              [109.21118655600007, 14.128527770000064],
              [109.20976260100011, 14.129185421000033],
              [109.20887334300008, 14.12961954900007],
              [109.20818836800007, 14.129869234000035],
              [109.20754875199999, 14.130051629000027],
              [109.20651682200005, 14.130108926000061],
              [109.20439968800012, 14.130020389000018],
              [109.20346393800006, 14.129996577],
              [109.20282377900003, 14.130089579000058],
              [109.20215003400006, 14.130305666000085],
              [109.20148911700008, 14.130756300000067],
              [109.20105780700001, 14.13133970500011],
              [109.20058750700009, 14.132238454000044],
              [109.19711228300005, 14.139376459000095],
              [109.194222021, 14.14558035200004],
              [109.19225512400007, 14.149365029000103],
              [109.19084735700002, 14.151592701000125],
              [109.18462098700009, 14.160811876000066],
              [109.18264923600002, 14.163819580000057],
              [109.17861979800004, 14.169752177000115],
              [109.17664592800006, 14.172426880000087],
              [109.17483899400003, 14.174490195000098],
              [109.17418871500006, 14.175076488000025],
              [109.17322500800007, 14.175525767000073],
              [109.17220188800006, 14.175531406000095],
              [109.17140516400005, 14.175369305000094],
              [109.17003683700007, 14.174655399000089],
              [109.16097991600006, 14.171319789000057],
              [109.15978312200006, 14.170771363000066],
              [109.15949448100008, 14.169996006000039],
              [109.16055438100003, 14.166494029000097],
              [109.16206830000004, 14.162878581000092],
              [109.162261019, 14.161795374000084],
              [109.16214384400008, 14.161185566000077],
              [109.161799327, 14.160577001000084],
              [109.1606616560001, 14.160416731000057],
              [109.15986531900003, 14.160310082000114],
              [109.15935252700005, 14.160090893000097],
              [109.15906580800004, 14.159648497000086],
              [109.15928648100001, 14.158481906000107],
              [109.15979291400008, 14.15759122000013],
              [109.1613153700001, 14.155474107000108],
              [109.16238949200007, 14.154469333000106],
              [109.16352426200008, 14.154130164000021],
              [109.1643758160001, 14.153959018000071],
              [109.16488444900008, 14.153456780000083],
              [109.16510604000004, 14.152456660000022],
              [109.16528245200007, 14.14854331600006],
              [109.16525684800008, 14.14460767200003],
              [109.16558152600004, 14.142241130000064],
              [109.16619915700007, 14.1380604590001],
              [109.16713999400011, 14.13429575100009],
              [109.16810941200002, 14.13147960300005],
              [109.16833503500004, 14.130907071000108],
              [109.17005048700005, 14.127817714000022],
              [109.17095297700011, 14.126647368000087],
              [109.17146184700003, 14.126200615000071],
              [109.17282246300007, 14.125638175000075],
              [109.17520625900005, 14.125125572000028],
              [109.18065603600003, 14.124151995000121],
              [109.18514233600007, 14.123627601000075],
              [109.18730235300008, 14.123726546000091],
              [109.18903667000005, 14.123911091000084],
              [109.19034593900003, 14.124292225000088],
              [109.1913152380001, 14.124841741000081],
              [109.19229776700004, 14.125473458000036],
              [109.19366852800005, 14.126467205000013],
              [109.19493049, 14.12701523400006],
              [109.19554683800003, 14.127166369000042],
              [109.19635346800007, 14.127324447000065],
              [109.19761216200003, 14.127317365000101],
              [109.19874788000003, 14.127112708000043],
              [109.20109933200007, 14.126504675000094],
              [109.2019916580001, 14.126341030000054],
              [109.202884678, 14.126296328000086],
              [109.20381925400005, 14.126410002000076],
              [109.20463367000012, 14.126801901000084],
              [109.20532769500008, 14.127432383000025],
              [109.20598070100002, 14.128027141000119],
              [109.20643885700008, 14.128113925000017],
              [109.20680338700005, 14.128098881000104],
              [109.20715221100005, 14.128014919000085],
              [109.20753556700006, 14.127839566000054],
              [109.20783089300006, 14.127480371000088],
              [109.20789673100009, 14.127010755000045],
              [109.20771115900001, 14.126587261000088],
              [109.20703143100009, 14.125797883000056],
              [109.20555362100004, 14.124457270000066],
              [109.20489926500007, 14.123667950000128],
              [109.20416088200008, 14.122403298000028],
              [109.20374849500007, 14.121335050000104],
              [109.20337177800008, 14.119433929000101],
              [109.20327597400005, 14.116976109000044],
              [109.20325543700004, 14.11503716900002],
              [109.20347635300001, 14.112842577000071],
              [109.20370133600007, 14.111333388000078],
              [109.20454368700004, 14.107421756000081],
              [109.20537008800002, 14.104766799000055],
              [109.20610643200003, 14.102706379000017],
              [109.20879775200004, 14.09503733300008],
              [109.21054761200006, 14.090003773000069],
              [109.21251820100012, 14.085033406000045],
              [109.214652927, 14.079945729000086],
              [109.218239365, 14.072830070000107],
              [109.22177148600004, 14.066148269000054],
              [109.22669902500007, 14.057555093000039],
              [109.23001925200008, 14.051720325000082],
              [109.23553848600004, 14.042647765000103],
              [109.23971630600003, 14.035539059000078],
              [109.24260872900008, 14.030658275000095],
              [109.24586600700012, 14.02525637100007],
              [109.24649674900007, 14.024424387000066],
              [109.24718302400001, 14.024006219000016],
              [109.24787120900002, 14.023898641000086],
              [109.24871994700008, 14.024048964000089],
              [109.24977936100008, 14.023990974000087],
              [109.25046530800007, 14.023521023000017],
              [109.25088440600003, 14.022742036000045],
              [109.25140243800007, 14.020823579000117],
              [109.25196993200005, 14.018335382000123],
              [109.25180426300003, 14.017249227000049],
              [109.25158753400002, 14.016473985000031],
              [109.25046049100004, 14.014151071000034],
              [109.24885949300008, 14.012296846000092],
              [109.24747523800004, 14.011217846000077],
              [109.24614779400007, 14.010759724000097],
              [109.24535146300008, 14.010505555000028],
              [109.24492601100005, 14.010249202000045],
              [109.2446582370001, 14.009784864000084],
              [109.24486157900006, 14.008385947000066],
              [109.245618909, 14.005808402000051],
              [109.24620670000006, 14.003917846000123],
              [109.24675764700008, 14.00192070300008],
              [109.24699536400003, 14.001036873000048],
              [109.24719959400005, 14.000356881000096],
              [109.24764426000007, 13.999234247000024],
              [109.24874489700002, 13.997462916000101],
              [109.24949288500008, 13.996805624000073],
              [109.25013904000008, 13.996396701000078],
              [109.25167032100003, 13.995559416000079],
              [109.25293733600006, 13.99482721600007],
              [109.25362093600005, 13.993994912000039],
              [109.25471810500005, 13.991503609000054],
              [109.25528996400004, 13.989740159000073],
              [109.25528162900005, 13.98839426100009],
              [109.25442470700003, 13.986898071000018],
              [109.25351674700005, 13.985712794000092],
              [109.25293112600008, 13.985250337000108],
              [109.25245547500008, 13.985099243000041],
              [109.25176500700006, 13.985153678000104],
              [109.250232528, 13.985783899000113],
              [109.24944011900007, 13.986150928000106],
              [109.24854174700008, 13.98651856800006],
              [109.24801474, 13.986512350000078],
              [109.247445871, 13.986415097000114],
              [109.24724236800004, 13.986183264000116],
              [109.24705426800003, 13.985854331000079],
              [109.24667643200011, 13.984717671000089],
              [109.24649413100003, 13.983362424000044],
              [109.24651154900006, 13.981886965000088],
              [109.24693771200006, 13.979824168000031],
              [109.24776937900003, 13.977230948000051],
              [109.24946599499999, 13.973157296000059],
              [109.25097697900004, 13.969058853000059],
              [109.25126337400012, 13.968254791000055],
              [109.25155039600006, 13.96755425400009],
              [109.25213052800009, 13.967136706000092],
              [109.25329206300002, 13.966508655000085],
              [109.25435020400008, 13.966295348000111],
              [109.2551983860001, 13.966393879000092],
              [109.25615380100001, 13.966698829000011],
              [109.25673615800005, 13.966643622000062],
              [109.25726234700008, 13.966071078000118],
              [109.25757214000004, 13.96477508900009],
              [109.25756828699998, 13.964153916000031],
              [109.2570877050001, 13.963535563000027],
              [109.25492098200006, 13.962302738000075],
              [109.25264979600007, 13.961154632000026],
              [109.25169409200005, 13.960797896000086],
              [109.24999873700003, 13.960756100000092],
              [109.24893934500003, 13.960762327000074],
              [109.24777241300002, 13.960510351],
              [109.24660453800003, 13.960103074000061],
              [109.24474455900005, 13.959130415000052],
              [109.242243898, 13.957333227000033],
              [109.24091270000008, 13.956202153000062],
              [109.24045787200002, 13.955454201000082],
              [109.24013151000001, 13.954058420000058],
              [109.24011282900007, 13.951004335000048],
              [109.24022545700007, 13.947768302000084],
              [109.24048142900001, 13.946317366000114],
              [109.24125817100007, 13.943413949000048],
              [109.24240437900005, 13.940301303000078],
              [109.243915261, 13.936202983000088],
              [109.2450135070001, 13.933918871000104],
              [109.24797038700007, 13.928129685000011],
              [109.25058588700001, 13.922834254000092],
              [109.25296533900007, 13.91792843100008],
              [109.25558794900009, 13.913823519000077],
              [109.25721358400003, 13.911225661000069],
              [109.2610722740001, 13.905793379000018],
              [109.26459285400006, 13.90137245900007],
              [109.26728016500002, 13.898121605000123],
              [109.26908659800009, 13.895936279000052],
              [109.27129796200009, 13.893904248000053],
              [109.27445688200007, 13.890986516000046],
              [109.27664247600008, 13.889083998000016],
              [109.2781198910001, 13.888246890000032],
              [109.27996663900005, 13.887200490000049],
              [109.280970731, 13.886883850000052],
              [109.28298285200003, 13.886871711000113],
              [109.28467825200006, 13.887016763000036],
              [109.28634832600005, 13.887343134000039],
              [109.28746127400004, 13.887491680000073],
              [109.28931421900005, 13.887428683000012],
              [109.2915894400001, 13.887156047000103],
              [109.29312269500012, 13.886784371000081],
              [109.29433593700006, 13.886052291000082],
              [109.29486081000005, 13.885324390000074],
              [109.29543731100006, 13.884389118000049],
              [109.29589797800007, 13.881901633000025],
              [109.29594563800001, 13.881073118000041],
              [109.29572920800004, 13.880349738000051],
              [109.29535625600001, 13.879989661000101],
              [109.29354906900005, 13.878913630000065],
              [109.29275187700009, 13.878452608000112],
              [109.29184482900008, 13.877371076000065],
              [109.29135807600005, 13.875769352000054],
              [109.29080916600002, 13.872718621000056],
              [109.29065786000011, 13.869743125000065],
              [109.29093007300007, 13.862598086000053],
              [109.29112828500004, 13.856307622000051],
              [109.29115618900003, 13.852373448000053],
              [109.29138536100008, 13.850948563000108],
              [109.29241120700004, 13.845766021000115],
              [109.2934379820001, 13.840738765000092],
              [109.29475464300006, 13.835690458000014],
              [109.29587883300007, 13.832702619000068],
              [109.296756886, 13.830958354000076],
              [109.29699662800002, 13.828721155000066],
              [109.29660287100003, 13.826736246000058],
              [109.29430144900005, 13.824390349000025],
              [109.29111095800002, 13.822049828000081],
              [109.28881123100008, 13.819952278000079],
              [109.28727127600003, 13.817477486000127],
              [109.28533695000003, 13.812893567000105],
              [109.28423901800005, 13.810609944000072],
              [109.28350630100009, 13.807931260000126],
              [109.28325222500003, 13.805482999000061],
              [109.28341491900008, 13.802915577000084],
              [109.28452340200005, 13.799000879000099],
              [109.28710546200008, 13.792335857000102],
              [109.28925026600008, 13.787277493000081],
              [109.29012814900007, 13.784647409000071],
              [109.29070672200007, 13.781844168000122],
              [109.29098894700006, 13.779334371000028],
              [109.29129783000003, 13.776328633000022],
              [109.29122001200008, 13.773471062000018],
              [109.29084884400001, 13.771373542000019],
              [109.28951836300006, 13.768465257000083],
              [109.28804051200008, 13.765820345000089],
              [109.28731529800004, 13.764308241000032],
              [109.28682493400005, 13.762211438000092],
              [109.28687124500006, 13.760111396000061],
              [109.28721682200006, 13.758184516000059],
              [109.28732535200007, 13.756492381000028],
              [109.28699091600001, 13.755473691000118],
              [109.28621203700006, 13.754895146000122],
              [109.28543611900007, 13.754783192],
              [109.28466279500003, 13.75507949800001],
              [109.28395351400005, 13.756075339000072],
              [109.28058924000004, 13.761578368000114],
              [109.27999844400001, 13.762456832000057],
              [109.27958323300004, 13.762809302000088],
              [109.27857171000009, 13.76316535300006],
              [109.27714091800001, 13.763232293],
              [109.27523234600002, 13.763185431000034],
              [109.27385971600005, 13.763018684000093],
              [109.27230564600006, 13.762444735000122],
              [109.27009161700002, 13.761233126000059],
              [109.26817541000001, 13.759961387000084],
              [109.2671265970001, 13.759121912000081],
              [109.26604961300006, 13.758545062000048],
              [109.26413998700008, 13.75832313200007],
              [109.25984553500011, 13.758173668000049],
              [109.25781992200011, 13.758477310000037],
              [109.25335342800003, 13.759436947000035],
              [109.251329582, 13.760032145000077],
              [109.24996346900012, 13.760915091000035],
              [109.24916329500009, 13.761707212000116],
              [109.24869020000003, 13.762351590000053],
              [109.24869450700002, 13.763051482000053],
              [109.24905517100011, 13.763515982000028],
              [109.25001251, 13.764035284],
              [109.25168579300008, 13.764608703000112],
              [109.25306128800003, 13.765242188000036],
              [109.25497745900003, 13.766514068000022],
              [109.25719650500004, 13.76854239100007],
              [109.25827822000011, 13.769877504000048],
              [109.25900034200008, 13.770923115000054],
              [109.2590650380001, 13.771739316],
              [109.25909938200002, 13.772468199000086],
              [109.25856810000002, 13.773346255000083],
              [109.25684520100002, 13.774406351000044],
              [109.25470592200003, 13.775643878000031],
              [109.25310225500003, 13.776703231000019],
              [109.25233167200005, 13.777466031000072],
              [109.2517999880001, 13.778285752000039],
              [109.2515090050001, 13.779454017000068],
              [109.25164230200002, 13.781728002000103],
              [109.25195273000004, 13.783709305000079],
              [109.25210884600008, 13.784845772000052],
              [109.25235459099999, 13.786010878000086],
              [109.25271494400005, 13.78641704900012],
              [109.25331313400008, 13.786705152000065],
              [109.25396770700006, 13.78646797900007],
              [109.2547390400001, 13.785821819000075],
              [109.25533001400007, 13.784943414000049],
              [109.25615844300003, 13.783888616000082],
              [109.256931927, 13.783592409000088],
              [109.25758830200004, 13.783646846000062],
              [109.25818720500004, 13.784051595000067],
              [109.25848973300002, 13.7847497380001],
              [109.2584456550001, 13.787258087000074],
              [109.25829224300003, 13.790353878000071],
              [109.25812037000001, 13.792509518000083],
              [109.258183633, 13.793092419000084],
              [109.25884293400003, 13.793613465000119],
              [109.259320415, 13.793668958000092],
              [109.26104890200003, 13.793483725000042],
              [109.26444514000011, 13.792938582000057],
              [109.26989838700011, 13.792293608000071],
              [109.27281819100003, 13.791867834000103],
              [109.27395098800005, 13.791802711000043],
              [109.27526600100005, 13.792261449000074],
              [109.27724292800011, 13.793649441000076],
              [109.27808341200002, 13.794519304000106],
              [109.27826821100003, 13.795451434000048],
              [109.27815921700007, 13.797085283000053],
              [109.27746923000009, 13.801172397000093],
              [109.27709751800006, 13.80371191100007],
              [109.27699475100009, 13.806337302000047],
              [109.27712248500009, 13.807678092000051],
              [109.27784630800002, 13.808956966000084],
              [109.27976708600005, 13.810870246000116],
              [109.280787323, 13.811855681000027],
              [109.28133334500006, 13.81331059900006],
              [109.28122657600002, 13.815294423000067],
              [109.280792664, 13.817426037000045],
              [109.2803224840001, 13.81853712500005],
              [109.27865712100005, 13.819305430000027],
              [109.27758382400006, 13.819370211000058],
              [109.27609005700002, 13.818970886000058],
              [109.27489050100007, 13.817928177000026],
              [109.27410299100009, 13.816008051000049],
              [109.27301214300007, 13.813273138000069],
              [109.27023336200003, 13.807690233000079],
              [109.26848105699999, 13.804084351000066],
              [109.26724688500011, 13.802254372000087],
              [109.26622895499999, 13.801618832000083],
              [109.26497608700006, 13.801567973000049],
              [109.26390433300003, 13.801865997000016],
              [109.26277656800006, 13.802747627000132],
              [109.26200773600004, 13.803802107000072],
              [109.26053694500003, 13.807077225000073],
              [109.25800297800006, 13.812050167000043],
              [109.257187446, 13.815204738000107],
              [109.25705404700007, 13.817742826000055],
              [109.25691124500007, 13.823576555000015],
              [109.25653714500002, 13.826806195000072],
              [109.25560718499999, 13.834550155000079],
              [109.25458033500003, 13.842139046000044],
              [109.25308479700001, 13.851130656000075],
              [109.25175839400003, 13.858546386000102],
              [109.25014544099999, 13.867888720000019],
              [109.24863228900003, 13.874080659000041],
              [109.24641474600004, 13.881404263000084],
              [109.24553459100005, 13.886500998000082],
              [109.24540934700008, 13.887038066000022],
              [109.24466762600008, 13.890218699000021],
              [109.24369667300003, 13.894679542000075],
              [109.24293127100003, 13.897272261000102],
              [109.24147680600004, 13.900760036000024],
              [109.24053046200007, 13.902165763000072],
              [109.2393200270001, 13.902978999000124],
              [109.23810673200009, 13.903325514000107],
              [109.23602506800006, 13.903634642000016],
              [109.23550367200009, 13.903552808000084],
              [109.23510884900001, 13.903445024000048],
              [109.23319652800006, 13.907725187000073],
              [109.23168368300003, 13.910810268000072],
              [109.230369357, 13.912794541000027],
              [109.22950696600006, 13.914184981000053],
              [109.2287819470001, 13.915935386000021],
              [109.22697152500007, 13.920247699000042],
              [109.22534786800001, 13.922956857000111],
              [109.22440451800003, 13.923974711000129],
              [109.22316184800006, 13.925007060000015],
              [109.22200771400004, 13.925321856000037],
              [109.22127146400005, 13.92522336900006],
              [109.22095497500007, 13.925022608000058],
              [109.22095343100003, 13.924766167000067],
              [109.22095062800007, 13.924300743000105],
              [109.22206534700011, 13.922553911000078],
              [109.22313579600004, 13.920945083000012],
              [109.224481477, 13.917547623000102],
              [109.22541052500004, 13.914516796000063],
              [109.22529985200003, 13.913592970000098],
              [109.22487517100006, 13.912876391000049],
              [109.22434602800007, 13.912263131000111],
              [109.22329271600006, 13.911858324000013],
              [109.22255093200005, 13.91083541200002],
              [109.22180483200005, 13.909093488000044],
              [109.22211512400006, 13.908269961000117],
              [109.22263867700012, 13.90795879600004],
              [109.22372153000001, 13.907254763000052],
              [109.22401627400006, 13.907077061000093],
              [109.22547051600003, 13.906069473000036],
              [109.22590125900005, 13.904853670000071],
              [109.22560144200003, 13.903570717000056],
              [109.22347589800002, 13.902226900000068],
              [109.22127778800005, 13.900954854000068],
              [109.22090886000008, 13.900314639000031],
              [109.22101918700005, 13.899701007000063],
              [109.22206887300004, 13.898951909000077],
              [109.2231644770001, 13.899016979000043],
              [109.22594320900006, 13.899714676000128],
              [109.22725914600005, 13.899992574000029],
              [109.22849903700006, 13.899771289000016],
              [109.22929568000006, 13.898696115000051],
              [109.22936089600005, 13.89741105000004],
              [109.22920449800007, 13.895699046000052],
              [109.22813007700009, 13.893100211000052],
              [109.22687942400007, 13.891537268000052],
              [109.22496741500007, 13.889264428000024],
              [109.22430086500006, 13.8876981050001],
              [109.22377776000005, 13.885702736000026],
              [109.22398643900007, 13.883988627000042],
              [109.22470227900007, 13.881629269000049],
              [109.22538353400003, 13.87959127900009],
              [109.22594686000011, 13.876162239000054],
              [109.2262237690001, 13.873662681],
              [109.22612272100008, 13.869024191000088],
              [109.22587956600003, 13.865028873000094],
              [109.22603510200003, 13.860567354000073],
              [109.22594226000007, 13.857284879000085],
              [109.22584338200006, 13.853003269000029],
              [109.22109272800002, 13.85203145800009],
              [109.22021229000009, 13.845220735000028],
              [109.21931536800004, 13.841728799000029],
              [109.21871214900004, 13.838520657000062],
              [109.21891310400005, 13.83552201100011],
              [109.21985251200007, 13.833946503000048],
              [109.22144620500003, 13.831939025000038],
              [109.22285031000004, 13.828755048000028],
              [109.22378491100001, 13.826394506000026],
              [109.2236174220001, 13.822827044000084],
              [109.22380106800001, 13.816973799000019],
              [109.22410558200008, 13.813011119000061],
              [109.22423087500005, 13.809584746000068],
              [109.22429653500006, 13.808371116000016],
              [109.22689484500002, 13.803574495000069],
              [109.22638317900005, 13.797404174000059],
              [109.22512925000004, 13.795199023000036],
              [109.2232932950001, 13.793282698000018],
              [109.22196180700007, 13.792392509000083],
              [109.22138825800005, 13.792009051000063],
              [109.21969315800003, 13.789235478000082],
              [109.21935428100005, 13.787488937000079],
              [109.21914483600005, 13.786689632000025],
              [109.23672770600002, 13.782481451000093],
              [109.23681678500003, 13.778053588000104],
              [109.23785309300006, 13.776685291000133],
              [109.24399840600005, 13.775627698000024],
              [109.24678053100011, 13.775043781000116],
              [109.24816913899998, 13.774354497000017],
              [109.24920395400008, 13.772759114000086],
              [109.24919696500002, 13.771623943000042],
              [109.2490787990001, 13.771284069000076],
              [109.24838031800005, 13.770947617000038],
              [109.24397404800007, 13.771654598000092],
              [109.23840087100008, 13.771346599000099],
              [109.23491409400006, 13.770572258000067],
              [109.23165810200005, 13.769569480000053],
              [109.22839732199999, 13.767772030000101],
              [109.22291912400004, 13.763943939000088],
              [109.2205828890001, 13.761459936000085],
              [109.21841841800007, 13.758577607000076],
              [109.21630761600004, 13.754957063000093],
              [109.21454164600003, 13.750766941000057],
              [109.21382224400001, 13.746911389000081],
              [109.2144995240001, 13.743728985000113],
              [109.215529008, 13.741225674000111],
              [109.21713801, 13.738605527000034],
              [109.21775480300005, 13.735026162000084],
              [109.21715144100006, 13.731169992000057],
              [109.21619045700002, 13.725726636000067],
              [109.21580901500009, 13.720166454],
              [109.21637490900011, 13.717779350000024],
              [109.21803662300002, 13.714307553000092],
              [109.21883998800004, 13.712827225000089],
              [109.22021325100008, 13.709640862000059],
              [109.22180007600009, 13.703388326],
              [109.22373145200002, 13.696566241000061],
              [109.22492604800001, 13.692643063000085],
              [109.22641647300006, 13.689683054000044],
              [109.22905807900004, 13.685240684000098],
              [109.23124101800009, 13.681709074000102],
              [109.23215258700002, 13.678979438000026],
              [109.23224999800009, 13.675913973000052],
              [109.2316575470001, 13.673874160000119],
              [109.23099947100009, 13.672844308],
              [109.22943266300004, 13.675109393000094],
              [109.22757918900007, 13.677624673000109],
              [109.22268634100007, 13.679702092000044],
              [109.20565504200007, 13.683214803000075],
              [109.20381200200008, 13.687551328000056],
              [109.20220113000002, 13.691658850000097],
              [109.20034985100006, 13.694629280000113],
              [109.19731707600003, 13.695329466000048],
              [109.1938130610001, 13.695349197000082],
              [109.18896294899999, 13.692628883000067],
              [109.18580979400005, 13.69040663200005],
              [109.18338901000003, 13.689151186000091],
              [109.17859413800005, 13.687757894000095],
              [109.17563580500006, 13.686898200000106],
              [109.16889420000004, 13.684967370000043],
              [109.16130956700006, 13.682712940000028],
              [109.15607316000006, 13.679133436000066],
              [109.15442658700006, 13.675324772000062],
              [109.15372612400002, 13.67075291500006],
              [109.15276727100009, 13.666432109000047],
              [109.15227815800003, 13.662564129000108],
              [109.15171522600008, 13.652424782000059],
              [109.15172836400009, 13.647995392000032],
              [109.15217865700005, 13.645033083000067],
              [109.15168574200004, 13.640482128000107],
              [109.15143541900002, 13.637523635000013],
              [109.14931813200008, 13.634802949000056],
              [109.14627322000011, 13.633225651000112],
              [109.14323476600006, 13.632786679000029],
              [109.13950212100005, 13.633489784000028],
              [109.13624798700009, 13.63623939600007],
              [109.12973184400006, 13.640372421000089],
              [109.1253160680001, 13.644266455000052],
              [109.12135086500011, 13.645198173000049],
              [109.11845836000006, 13.640593340000072],
              [109.12155138600009, 13.630992501000081],
              [109.12519871600007, 13.623092856000072],
              [109.12379004300004, 13.621734242000104],
              [109.12070144800002, 13.619495643000066],
              [109.11595137900011, 13.613770026000081],
              [109.11474233200008, 13.608409139000075],
              [109.11589379900005, 13.587169223000027],
              [109.11378750300004, 13.586269597000109],
              [109.11099326000003, 13.587650280000039],
              [109.10703530300002, 13.58972002300005],
              [109.10422982500006, 13.589051616000079],
              [109.10000992600006, 13.585886114000088],
              [109.09671790300007, 13.58157737],
              [109.09553335200012, 13.578396088000131],
              [109.09094405900001, 13.574106121000094],
              [109.08802457500012, 13.571376914000053],
              [109.08075318200005, 13.569523637000101],
              [109.08030830800001, 13.569139637000125],
              [109.07377151200005, 13.569172815000028],
              [109.06443319200011, 13.569274587000011],
              [109.05524211700001, 13.566999741000084],
              [109.04782018400009, 13.562017125000112],
              [109.04500122600008, 13.558615998000077],
              [109.04124392600006, 13.554308778000033],
              [109.03841472800011, 13.548858619000013],
              [109.03183694000003, 13.540694720000078],
              [109.02783992100008, 13.535022497000069],
              [109.02361315700003, 13.530034315000082],
              [109.01846553700008, 13.527554786000094],
              [109.01051828700005, 13.525316158000054],
              [108.99087434100007, 13.517668291000094],
              [108.98457368800011, 13.51792528100013],
              [108.97570563900008, 13.518193917000064],
              [108.96730543600005, 13.518687706000064],
              [108.96077133200005, 13.51894505300004],
              [108.95727033000001, 13.518960879000106],
              [108.95471042000003, 13.520566050000047],
              [108.95098878900004, 13.523314716000099],
              [108.93912091800006, 13.531108121000022],
              [108.93260204899998, 13.534779491000036],
              [108.92817854900008, 13.537303197000126],
              [108.91139265500006, 13.541929262000084],
              [108.90556601700007, 13.54263650800007],
              [108.89769753900006, 13.541057707000091],
              [108.89275838200004, 13.539958069000056],
              [108.88780362200006, 13.539069632000087],
              [108.88196309700008, 13.537955674000022],
              [108.8756557880001, 13.536843474000102],
              [108.87051956000003, 13.536636941000067],
              [108.86798306400004, 13.543932609000107],
              [108.86288534400005, 13.552832362000087],
              [108.84895079800005, 13.570419487000061],
              [108.849601804, 13.572237816000053],
              [108.84996295600004, 13.574854564000114],
              [108.85021262300009, 13.578723954000081],
              [108.84952793500001, 13.582483268000088],
              [108.84814848900001, 13.587611384000107],
              [108.84652932000006, 13.591260604000064],
              [108.84490536600008, 13.593771455000068],
              [108.83760266100008, 13.606436278000087],
              [108.83219423700004, 13.625696152000087],
              [108.828951863, 13.632311487000058],
              [108.82559067200008, 13.638471914000137],
              [108.82386132700007, 13.643942941000107],
              [108.82212357300008, 13.647364879000088],
              [108.82062898300006, 13.653176519000086],
              [108.81890716800005, 13.660582823000043],
              [108.81857426300004, 13.664910073000057],
              [108.81870941600003, 13.669463208000108],
              [108.81826383900002, 13.674815496000024],
              [108.81792767800002, 13.678345894000097],
              [108.81747610100004, 13.682218277000045],
              [108.816672741, 13.685750496000091],
              [108.81532788800011, 13.699872243000042],
              [108.81801713600004, 13.700544966000015],
              [108.82128767200003, 13.700532341000036],
              [108.824330602, 13.70200051200001],
              [108.82725723000003, 13.703582949000024],
              [108.828663099, 13.704602057000058],
              [108.8300694390001, 13.705735],
              [108.83077494000003, 13.706870688],
              [108.83124730800003, 13.708121124000082],
              [108.83125523400007, 13.710056450000073],
              [108.83068143300002, 13.71256328000001],
              [108.8298772520001, 13.715867925000056],
              [108.82801984200006, 13.718721312000069],
              [108.82604278800008, 13.720892068000088],
              [108.82511613100007, 13.722831043000026],
              [108.82535439400006, 13.723968575000088],
              [108.82734680100006, 13.725554663000048],
              [108.83146064900009, 13.731686271000052],
              [108.83229381300002, 13.735439931000125],
              [108.83288961900003, 13.738283756000056],
              [108.83348357000006, 13.740672205000058],
              [108.83248723100004, 13.754110089000092],
              [108.83077912700003, 13.763540946000051],
              [108.82631673100011, 13.788174975000087],
              [108.82656064400004, 13.790678729000126],
              [108.82586970400007, 13.79318611400002],
              [108.82354409400003, 13.796041411000102],
              [108.82040407300011, 13.79981063000004],
              [108.81760994200002, 13.802439967000078],
              [108.814462409, 13.804387525000015],
              [108.81236112300004, 13.804964828],
              [108.81072456200008, 13.804857236000066],
              [108.80347985, 13.805112460000133],
              [108.80114884300004, 13.806715182000049],
              [108.79881870500006, 13.808545575000062],
              [108.79637879400008, 13.812197985000106],
              [108.79452004000011, 13.815051234000107],
              [108.79371441800006, 13.818242093000064],
              [108.79331567900002, 13.819741319000032],
              [108.79313851200001, 13.82040743500008],
              [108.79301289800001, 13.822130036000056],
              [108.79320462200008, 13.822305122000065],
              [108.79393102500005, 13.822825931000081],
              [108.79490955700001, 13.824249332000081],
              [108.79443301900001, 13.826788118000087],
              [108.79283124400008, 13.831868118000033],
              [108.79220240700002, 13.836944496000035],
              [108.79157663100003, 13.842813694000087],
              [108.79160138300003, 13.849156183000042],
              [108.79047726400009, 13.852014533000022],
              [108.78724596800008, 13.855197835000064],
              [108.78141838900009, 13.858073522000057],
              [108.77906775200003, 13.859499695000109],
              [108.77876216000006, 13.859685100000084],
              [108.77506584700011, 13.862258319000096],
              [108.77215424000008, 13.86511529000005],
              [108.76947807100007, 13.868426792000042],
              [108.76773662300005, 13.871621044000014],
              [108.76681129400004, 13.874243054000067],
              [108.76623451200001, 13.876294537000076],
              [108.76554038600005, 13.878232586000115],
              [108.76414994100003, 13.881539409000075],
              [108.76334142500009, 13.884161008000094],
              [108.76276544500004, 13.886440198000026],
              [108.75964379200003, 13.895787631000099],
              [108.75007805900002, 13.901628319000013],
              [108.74623307800003, 13.905285295000079],
              [108.74402240700009, 13.908253343000075],
              [108.74321353400003, 13.91087489500009],
              [108.74323596400004, 13.916994803000103],
              [108.74329241900008, 13.932393883000048],
              [108.74341896200008, 13.935012197000061],
              [108.74366119200005, 13.937288544000113],
              [108.74438164400006, 13.9424097],
              [108.74462557600008, 13.945141488000077],
              [108.74497891600001, 13.9458234040001],
              [108.75228956300003, 13.961965848000089],
              [108.75417185900008, 13.964919567000054],
              [108.75499903800005, 13.967193853000113],
              [108.75524271900005, 13.969811803000097],
              [108.75431508500003, 13.971978461000043],
              [108.75187368200008, 13.975972272000091],
              [108.75025009000004, 13.979735451000062],
              [108.75049245900003, 13.982011845000029],
              [108.75413156600004, 13.985528692000047],
              [108.75694775100007, 13.987909804000063],
              [108.759765708, 13.990746317000101],
              [108.76188061500002, 13.993243732000058],
              [108.76211840300006, 13.994267647000045],
              [108.76188916400011, 13.995520958],
              [108.76107418900008, 13.996548646000038],
              [108.75283406200006, 14.01411298300008],
              [108.75132759600002, 14.01798969700012],
              [108.75110037300006, 14.019812328000024],
              [108.75204220100008, 14.021403105000017],
              [108.75438919400003, 14.023330489000061],
              [108.75521576600003, 14.025377122000025],
              [108.75534294600007, 14.028109425000055],
              [108.75558754300006, 14.030955190000059],
              [108.75571855300007, 14.034712275000059],
              [108.75514374700003, 14.03744708500003],
              [108.75386861100003, 14.040639852000057],
              [108.75258837100007, 14.042466237],
              [108.749208364, 14.046008018000043],
              [108.74595276400002, 14.04704267400003],
              [108.74558543100009, 14.047159416000097],
              [108.74114633600006, 14.049110658000073],
              [108.73694116800002, 14.051060998000063],
              [108.73355662200002, 14.053463893000105],
              [108.73063880000009, 14.055523537000079],
              [108.72830925600009, 14.058492061000026],
              [108.72562818600004, 14.061347895000063],
              [108.72329768900003, 14.064088637000081],
              [108.72073430400003, 14.067171755000095],
              [108.71875512400005, 14.070025133000017],
              [108.71747921000009, 14.07321773500003],
              [108.71632270600006, 14.07709313300005],
              [108.715171373, 14.082448800000067],
              [108.71471531500002, 14.085866377000043],
              [108.71379474000005, 14.090310337000032],
              [108.71275309100011, 14.093616027000076],
              [108.71124247900009, 14.096695534000057],
              [108.70961317700002, 14.099319965000044],
              [108.70704755800001, 14.10194750200011],
              [108.70459533200007, 14.10354980100008],
              [108.70214189200001, 14.104810482000028],
              [108.70062511900002, 14.10618191400008],
              [108.69829376800003, 14.108922443000081],
              [108.69246587900003, 14.116001423000045],
              [108.68426747700008, 14.131359350000048],
              [108.68211408200007, 14.135392843000092],
              [108.67688842900006, 14.147935376000119],
              [108.67560681600007, 14.149761395000036],
              [108.66652302600005, 14.164137941000108],
              [108.66512414000003, 14.165964278000052],
              [108.66466033300003, 14.16733219700008],
              [108.66560178500004, 14.168809600000108],
              [108.67347497300007, 14.177553046000034],
              [108.6746495690001, 14.178688061000065],
              [108.67535584500008, 14.179824557000041],
              [108.6747742310001, 14.180965130000088],
              [108.67155098800004, 14.183714183000083],
              [108.67240016100004, 14.184623686000107],
              [108.67562591400005, 14.188406881000047],
              [108.67780171700005, 14.191026183000053],
              [108.67915240200007, 14.192699732000056],
              [108.67968038600006, 14.19415705400011],
              [108.67976001200007, 14.195615810000016],
              [108.67869394500006, 14.200835188000037],
              [108.67720960400008, 14.203903851000097],
              [108.67606828800007, 14.206755200000119],
              [108.67531509800008, 14.209611626000116],
              [108.67532337300007, 14.212093375000022],
              [108.67559127300002, 14.216187456000016],
              [108.67573247800007, 14.220406036],
              [108.67536061600003, 14.223261269000085],
              [108.67432088200007, 14.22485957400008],
              [108.67398700900007, 14.225280936000038],
              [108.67309774100003, 14.226234110000034],
              [108.67064326700007, 14.228830488000098],
              [108.66828176700008, 14.231208433000029],
              [108.66800034100007, 14.231457776000054],
              [108.66533906100005, 14.233577517000116],
              [108.66507094700007, 14.233766829000059],
              [108.66645318300004, 14.243848224000109],
              [108.66607505400002, 14.247844049000056],
              [108.66393486300004, 14.251464934000067],
              [108.66217906200008, 14.252992177000044],
              [108.65846862300003, 14.255096129000043],
              [108.65574127200006, 14.258718759000109],
              [108.65457536400004, 14.261195236000022],
              [108.65380134800003, 14.263860740000032],
              [108.65381240500005, 14.267284713],
              [108.65401973700007, 14.270898305000033],
              [108.65619359300008, 14.277549428000034],
              [108.65737625200006, 14.280208906000079],
              [108.65737233900009, 14.283159681000066],
              [108.65602874500003, 14.28706113100011],
              [108.65467068800011, 14.290679573000096],
              [108.65312181900003, 14.295820399000101],
              [108.65215257700004, 14.298676748000092],
              [108.64534410200008, 14.311442621000124],
              [108.64457523400006, 14.315820152000034],
              [108.64341309200009, 14.319628203000077],
              [108.64089116500006, 14.326674215000066],
              [108.63817393100003, 14.333911036000034],
              [108.63506803899999, 14.342100147000012],
              [108.63201846, 14.348978807000011],
              [108.63171365300009, 14.349337669000038],
              [108.62956286400004, 14.351649054000058],
              [108.62678380000006, 14.353184387000082],
              [108.62513563000002, 14.354716358000095],
              [108.62410189800002, 14.358028185000114],
              [108.62445616500001, 14.360063319000103],
              [108.62619659200004, 14.363300991000115],
              [108.63056580700007, 14.369481500000079],
              [108.63188062400005, 14.372616733000052],
              [108.63179706800007, 14.373719911000114],
              [108.629888812, 14.37559207400003],
              [108.62719958800008, 14.378145255000073],
              [108.6243412540001, 14.382310891000126],
              [108.62365461500005, 14.385367202000053],
              [108.62418634000008, 14.388504795000044],
              [108.62350516800002, 14.393342782000039],
              [108.62126345000009, 14.400051853000038],
              [108.61966689200005, 14.403774523000033],
              [108.61746227300007, 14.409056203000057],
              [108.61630129600012, 14.416146694000085],
              [108.61587274600001, 14.420899103000105],
              [108.61454958300004, 14.422846583000124],
              [108.61178555400005, 14.424474257000062],
              [108.61012841700007, 14.425882762000073],
              [108.60880711599999, 14.428478098000051],
              [108.6086034570001, 14.434417659000063],
              [108.60938596000001, 14.43679102700006],
              [108.61072165000004, 14.438946832000086],
              [108.61017856800005, 14.442511798000089],
              [108.60753667900003, 14.448026432000026],
              [108.60455619700008, 14.451490340000104],
              [108.60201517500006, 14.453765171000031],
              [108.60014643700008, 14.458629658000087],
              [108.60037247200003, 14.460140776000062],
              [108.60148217400011, 14.460785548000031],
              [108.60380561000005, 14.459807127000078],
              [108.6061257790001, 14.45774886800004],
              [108.60800411000008, 14.45612377700005],
              [108.6098872850001, 14.456118394000049],
              [108.61188646300005, 14.457840385000063],
              [108.61465554100002, 14.457724429000061],
              [108.61587112200004, 14.456749071],
              [108.61697030600004, 14.45393834500006],
              [108.61917686500001, 14.45101641600006],
              [108.62249538800005, 14.449494995000032],
              [108.62825282600009, 14.448614234000077],
              [108.63202005500004, 14.448927045000024],
              [108.63468296000011, 14.450322893000054],
              [108.63760840000003, 14.452421779000078],
              [108.63868507600004, 14.454846196000036],
              [108.63825185900008, 14.457978988000074],
              [108.63827264300002, 14.464565880000089],
              [108.63984917600008, 14.472659884000015],
              [108.64074673400009, 14.476220636000024],
              [108.64042361, 14.479137163000109],
              [108.63844748700009, 14.484866230000113],
              [108.63767978599999, 14.487352158000098],
              [108.6366880950001, 14.489082860000034],
              [108.63392368600007, 14.490818840000051],
              [108.63193545600006, 14.49276846600007],
              [108.63050622900002, 14.496336176000034],
              [108.63029439800007, 14.499468345000109],
              [108.63196481000008, 14.502163004000044],
              [108.63418996200008, 14.505071978000037],
              [108.63486189400008, 14.507337660000095],
              [108.63387312700007, 14.510040219000063],
              [108.62956922100003, 14.515668192000051],
              [108.628468524, 14.518047111000064],
              [108.62836308200006, 14.519775187000048],
              [108.63147993300004, 14.524301356000089],
              [108.63270528300008, 14.526349445000085],
              [108.63248906500004, 14.528077857000097],
              [108.6303923400001, 14.530891693000035],
              [108.62851819300002, 14.534028811000075],
              [108.62698013500004, 14.538352772000083],
              [108.62615821100007, 14.545373495000112],
              [108.62610802600008, 14.550113010000041],
              [108.62676263600008, 14.55207002100005],
              [108.62832208600005, 14.553013304000027],
              [108.62987800700009, 14.552819135000037],
              [108.63557805300009, 14.550527319000127],
              [108.63816841100001, 14.549318941000065],
              [108.63940063100009, 14.549315248000115],
              [108.64031098000001, 14.550070812000103],
              [108.64083621500004, 14.55209136500004],
              [108.64071391100003, 14.55442982400008],
              [108.64052635000007, 14.55664209000007],
              [108.63904985200003, 14.561449105000078],
              [108.63815281900006, 14.564927339000098],
              [108.63848149700007, 14.566316577000025],
              [108.63939111900001, 14.566819388000061],
              [108.64198889600002, 14.567885842000104],
              [108.6454967440001, 14.569581431000065],
              [108.65173428800007, 14.572974821000082],
              [108.657649174, 14.576811372000085],
              [108.66556629000011, 14.580409835000106],
              [108.67065285300006, 14.582015538000091],
              [108.67407665800003, 14.584138484000063],
              [108.67531030300005, 14.586268327000072],
              [108.6750543110001, 14.588317534000071],
              [108.67427436000004, 14.590880499000034],
              [108.67489419300009, 14.592841593000026],
              [108.67709392800001, 14.595651169000055],
              [108.67841356600005, 14.597268628000036],
              [108.67981625100002, 14.597520210000068],
              [108.68112931800003, 14.597174624000074],
              [108.68357586500005, 14.595203734000071],
              [108.68558344200007, 14.592978180000047],
              [108.68707227200007, 14.59280269],
              [108.69110854700007, 14.594581993000054],
              [108.69514783600006, 14.597214716000016],
              [108.69708505200003, 14.600024955000011],
              [108.69902764300009, 14.604371460000019],
              [108.70009352400008, 14.608550136000085],
              [108.70080986600003, 14.612986024000028],
              [108.70204396900009, 14.615115733000026],
              [108.70379788700008, 14.615536701000041],
              [108.70546031400005, 14.614848403000023],
              [108.70589395300004, 14.613566699000023],
              [108.7050970280001, 14.611179516000037],
              [108.70535423300007, 14.609557011000083],
              [108.70639879800007, 14.607590485000086],
              [108.70893076000004, 14.605021566000074],
              [108.71268927700007, 14.602448514000075],
              [108.71907281300005, 14.598927719000086],
              [108.72580565800007, 14.595149487000112],
              [108.73244630600009, 14.590176457000046],
              [108.7342959640001, 14.589704148000047],
              [108.73660596500008, 14.590235813000072],
              [108.74030607700008, 14.592201661000013],
              [108.74372532200002, 14.593089155000081],
              [108.74852844800006, 14.593701921000067],
              [108.75398156000006, 14.595301620000088],
              [108.75712562100006, 14.596639567000034],
              [108.75981148700006, 14.598878500000099],
              [108.76138847600006, 14.600851546000102],
              [108.76176422500006, 14.602559058000073],
              [108.76103643400006, 14.605439732000034],
              [108.76004246400007, 14.611199445000087],
              [108.75969071200009, 14.615877578],
              [108.76006884100003, 14.618214677000106],
              [108.76155737000001, 14.621177398000016],
              [108.76378328700005, 14.62377770700005],
              [108.76480885800001, 14.626382305000083],
              [108.76528313799999, 14.629708400000128],
              [108.76512025500001, 14.635465190000028],
              [108.76441076100011, 14.64320266000003],
              [108.76351346400003, 14.650131342000082],
              [108.76159786200007, 14.656434083000123],
              [108.75968382300006, 14.66318652700007],
              [108.75913983200009, 14.665886708000107],
              [108.75942737600008, 14.668673883000059],
              [108.76137807000001, 14.671635001000103],
              [108.76453289400008, 14.675491219000046],
              [108.76703584900005, 14.677910669000125],
              [108.76888376600002, 14.67808389500007],
              [108.77165358800004, 14.677804065000098],
              [108.77727208500005, 14.673736271000058],
              [108.78086265000003, 14.670755068000039],
              [108.78363200600002, 14.670385139000073],
              [108.78963493700003, 14.670273060000037],
              [108.79674474200006, 14.669796900000074],
              [108.80099570700007, 14.670410569000046],
              [108.80737129000001, 14.671106065000037],
              [108.81143940099999, 14.672169925000043],
              [108.814955205, 14.673685534000034],
              [108.81985939600006, 14.67591528500005],
              [108.82346696300004, 14.677250514000038],
              [108.8260600620001, 14.678949385000093],
              [108.82875404700006, 14.682716507000036],
              [108.83176928700006, 14.6884989000001],
              [108.83381842100005, 14.692628248000082],
              [108.83632167200005, 14.694867019000045],
              [108.83918549000003, 14.694945706000071],
              [108.84130174500007, 14.692958632000062],
              [108.84358242900004, 14.686113995000126],
              [108.84551494600007, 14.684397437000117],
              [108.84782368100005, 14.684298326000087],
              [108.84939614700004, 14.684831722000078],
              [108.84967777700007, 14.685909906000026],
              [108.85033909500007, 14.689415028000051],
              [108.85191994900003, 14.691927117000068],
              [108.85358523000008, 14.692550049000095],
              [108.85681849800008, 14.69262702900007],
              [108.86346939700006, 14.692690150000052],
              [108.86826984200005, 14.692041060000081],
              [108.87149573800006, 14.690408939000042],
              [108.87610084000006, 14.687332019000097],
              [108.88034379400003, 14.685965412000089],
              [108.88477653100007, 14.685767177000029],
              [108.88865942500009, 14.686560522000034],
              [108.893558775, 14.687439467000017],
              [108.89873365600008, 14.687957385000059],
              [108.90153831200006, 14.687935761000046],
              [108.9032960750001, 14.688557923000099],
              [108.90431774500007, 14.689812784000098],
              [108.90478843900003, 14.691789524000052],
              [108.90590249900002, 14.693043996000108],
              [108.90766153099999, 14.693935945000101],
              [108.91404311400007, 14.695707548000046],
              [108.91843018300005, 14.697323457000076],
              [108.91959470000005, 14.697752369000037],
              [108.92329527200008, 14.698995576000049],
              [108.92680579100002, 14.699070295000103],
              [108.93216040500003, 14.69841735700008],
              [108.9356659100001, 14.697412639000083],
              [108.93833379200004, 14.695062402000046],
              [108.94182789000003, 14.691629167000107],
              [108.94643237800007, 14.688640680000018],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 9, code: "AD01", ten_tinh: "Bình Dương" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.43607563900011, 11.502096493000112],
              [106.44326708200006, 11.501108756000098],
              [106.4512626480001, 11.501167739],
              [106.46418650600006, 11.500204195000039],
              [106.46757700900011, 11.499211142000119],
              [106.485156859, 11.499490502000052],
              [106.49010198800008, 11.498716673000063],
              [106.49546907200006, 11.496529357],
              [106.50497554600005, 11.491133919000072],
              [106.5210929350001, 11.482142580000064],
              [106.52916053600006, 11.476331376000102],
              [106.53558008800009, 11.470710780000013],
              [106.53867818000001, 11.469113656000028],
              [106.54176614800008, 11.468932742000069],
              [106.54402671500006, 11.469353057000118],
              [106.54709170299999, 11.472409236000123],
              [106.55132738599998, 11.478558457000075],
              [106.55269321300001, 11.480541236000084],
              [106.55470472900001, 11.483850378000058],
              [106.55563699200005, 11.485383987000118],
              [106.55576971500001, 11.485159087000072],
              [106.57167065200009, 11.458210886000051],
              [106.57246533500003, 11.456863898000067],
              [106.57068389300008, 11.454186747000071],
              [106.56840176200008, 11.450757049000069],
              [106.56144134200007, 11.4441438860001],
              [106.55829969200009, 11.441986411000114],
              [106.55419879200007, 11.440371973000117],
              [106.55109834200005, 11.439345649000108],
              [106.54690228100004, 11.436660335000058],
              [106.54313278000008, 11.433032666000107],
              [106.54009067200009, 11.42832691400004],
              [106.53976504100005, 11.426485682000108],
              [106.54031022100004, 11.424651120000105],
              [106.54297072800009, 11.420034132000099],
              [106.54577332400007, 11.415946748000037],
              [106.54649340600005, 11.41337778600011],
              [106.54667714100006, 11.410372333000039],
              [106.54652525200002, 11.406699300000065],
              [106.54501321200006, 11.401482149000051],
              [106.543688945, 11.397041454000066],
              [106.54067557700007, 11.391818824000042],
              [106.53780396100007, 11.389354064000122],
              [106.54322641100001, 11.385577294000091],
              [106.54592903200007, 11.382808004000109],
              [106.54869642100007, 11.378443294000105],
              [106.55110084000006, 11.374878011000076],
              [106.55322281300005, 11.373416776000054],
              [106.55512681400002, 11.372391690000059],
              [106.55798710600007, 11.371651540000103],
              [106.56070405400007, 11.371564650000019],
              [106.56313038800002, 11.372059295],
              [106.56644318600011, 11.373491725000038],
              [106.56916928000007, 11.375072249000072],
              [106.58441950200005, 11.381490442000073],
              [106.58942855700008, 11.3824998090001],
              [106.59114895800005, 11.382889632000024],
              [106.61185110800001, 11.389212618000053],
              [106.61130532100006, 11.382031478000075],
              [106.61173401900001, 11.377389383000123],
              [106.61479456400011, 11.364501010000081],
              [106.6159023120001, 11.363796484000053],
              [106.61751478900003, 11.362989397000065],
              [106.62493229200001, 11.36110267000001],
              [106.63149494600007, 11.359968839000077],
              [106.63766409500008, 11.360732849000053],
              [106.64160710800009, 11.36101024200009],
              [106.64867950800004, 11.360671426000033],
              [106.65155593400002, 11.360006770000018],
              [106.65755879600002, 11.35749027200008],
              [106.65831068500006, 11.357175064000023],
              [106.66365899800009, 11.355822871000052],
              [106.6639617650001, 11.355746319000025],
              [106.66810389600006, 11.355523358000051],
              [106.67380479600004, 11.355707485],
              [106.675434669, 11.364649076000076],
              [106.677270451, 11.376174005000015],
              [106.67733909, 11.37710892200001],
              [106.67728286900005, 11.378293955000094],
              [106.68380101700006, 11.39554427800009],
              [106.69019607200002, 11.400197035000035],
              [106.69786504500004, 11.400928416000065],
              [106.6985591000001, 11.400308168000082],
              [106.70093783200008, 11.398916955000081],
              [106.702356928, 11.398253855000105],
              [106.70436915900004, 11.403292683000108],
              [106.70699838300007, 11.407528799000131],
              [106.71167709500003, 11.410772713000126],
              [106.71675575400005, 11.415425641000086],
              [106.71694873600003, 11.417235387000078],
              [106.71794513000005, 11.421260526000053],
              [106.71886295800005, 11.424045072000053],
              [106.71935465500005, 11.424053465000037],
              [106.7226280890001, 11.423901410000068],
              [106.72466501300005, 11.424918540000116],
              [106.72464749300011, 11.427731805000095],
              [106.72442564600006, 11.430543841000061],
              [106.72481927700002, 11.432957707000034],
              [106.72645264699999, 11.433168554000071],
              [106.72951755200009, 11.433187081000094],
              [106.73299484700004, 11.432605179000086],
              [106.73687209500012, 11.433432310000045],
              [106.73972155300008, 11.435258015000025],
              [106.740117846, 11.43726996000005],
              [106.7403036380001, 11.440285453000063],
              [106.74131048500001, 11.442702978000073],
              [106.745999157, 11.444539612000069],
              [106.75129358300009, 11.44758549400011],
              [106.75413235400005, 11.451219620000062],
              [106.75472339300011, 11.454840436000067],
              [106.75244495300007, 11.459851020000132],
              [106.74955208900006, 11.465058928000092],
              [106.74891568400008, 11.468873494000052],
              [106.74889235500009, 11.472691722000137],
              [106.75010015400002, 11.475713408000042],
              [106.75376413800005, 11.478146756000028],
              [106.75804619900006, 11.479779815000056],
              [106.76274315700002, 11.480410382000093],
              [106.76519080200003, 11.481228628000027],
              [106.76598253900008, 11.482468198000094],
              [106.77192143700003, 11.483617801],
              [106.77910212200007, 11.486814123000054],
              [106.78332470100004, 11.489523760000074],
              [106.786729291, 11.491342256000079],
              [106.79160974300005, 11.49225553400006],
              [106.79399919500004, 11.492240841000088],
              [106.79543524900002, 11.49260930100011],
              [106.80189123900004, 11.483321042000048],
              [106.80625661500002, 11.462324277000045],
              [106.80632276000007, 11.462006101],
              [106.80553545700003, 11.461192248000083],
              [106.80466340200009, 11.459573873000027],
              [106.80464966500001, 11.457408945000045],
              [106.80487192100003, 11.455397212000038],
              [106.80486162500006, 11.453773510000074],
              [106.80422609100006, 11.452385639000083],
              [106.80296189700007, 11.450692352000134],
              [106.78951026400006, 11.435658414000025],
              [106.78745805000008, 11.433196624000084],
              [106.78509787400003, 11.431587239000061],
              [106.78101003700002, 11.42929242900008],
              [106.77822796900004, 11.427981350000048],
              [106.77594720300007, 11.427660709000074],
              [106.77273605200003, 11.427757475000087],
              [106.76874709200003, 11.427344376000102],
              [106.76725765400008, 11.408991861000038],
              [106.77223517000006, 11.399765339000021],
              [106.78333351700006, 11.382076881000012],
              [106.786745405, 11.380847897000111],
              [106.79724072400002, 11.379194227000065],
              [106.81139464900005, 11.376963231000035],
              [106.8157674170001, 11.379262022000079],
              [106.81931650000004, 11.381682100000122],
              [106.82419543200004, 11.38577991900007],
              [106.85179867700001, 11.377793636000087],
              [106.85255907700012, 11.377412294000065],
              [106.85550765000002, 11.373507949000048],
              [106.8552230710001, 11.373207728000077],
              [106.85435196300006, 11.371661624000049],
              [106.85436226700001, 11.369819307000027],
              [106.85453804700008, 11.356923797000105],
              [106.85488221500005, 11.350989273000065],
              [106.85614833700001, 11.34792355200007],
              [106.85738337500004, 11.346601860000048],
              [106.85843394600002, 11.346095733000093],
              [106.86036346700007, 11.345226293000097],
              [106.86148299700005, 11.34413294400005],
              [106.86197584300007, 11.342942064000015],
              [106.86199062500005, 11.34028105200011],
              [106.86153868800005, 11.338784381],
              [106.85958183800005, 11.336522097000044],
              [106.85673806400004, 11.334213956000026],
              [106.85540148600002, 11.332262057],
              [106.85416916000003, 11.330208382000071],
              [106.85345909500006, 11.327441108000079],
              [106.85357992000003, 11.324371334000103],
              [106.85433003000001, 11.319974510000106],
              [106.85497133200005, 11.316498219000069],
              [106.85611994500005, 11.31496927400004],
              [106.85726226100006, 11.314566093000076],
              [106.8625569500001, 11.312957253000098],
              [106.86968865500008, 11.310121626000045],
              [106.87232587100004, 11.308928044000053],
              [106.87322144400008, 11.308522706000089],
              [106.87263088300006, 11.31289285900001],
              [106.86959799700003, 11.318555512000026],
              [106.86842220000008, 11.322410889000077],
              [106.868636791, 11.325365121000084],
              [106.87436941600002, 11.332665028000031],
              [106.87987899300008, 11.338600685000076],
              [106.88470476700004, 11.342942448000047],
              [106.89001599400011, 11.342970539000062],
              [106.89486659600009, 11.342768917000077],
              [106.89688419600004, 11.342623752000057],
              [106.89786981200005, 11.342557503000082],
              [106.90017903900008, 11.342569588000105],
              [106.90131780700005, 11.345528694000071],
              [106.903140894, 11.350081539000099],
              [106.90519984400001, 11.353726958],
              [106.91049926199999, 11.356026156000086],
              [106.91465132700007, 11.356956294000041],
              [106.91904156000007, 11.356524560000112],
              [106.92458765800002, 11.355871472000077],
              [106.93082771300003, 11.354994603000089],
              [106.93568210000005, 11.354110544000038],
              [106.94077560100004, 11.351637409000135],
              [106.94402505300006, 11.34847339800009],
              [106.94751350800004, 11.34372039300011],
              [106.94984836100005, 11.33873444700013],
              [106.95079763200008, 11.333741602000112],
              [106.95084416500001, 11.32465539900007],
              [106.94901326100005, 11.321466036000059],
              [106.94533290700009, 11.3187217120001],
              [106.9418765130001, 11.317341410000024],
              [106.93773455000004, 11.31459465300013],
              [106.93544334900011, 11.311175729000043],
              [106.93476249200008, 11.308900733000103],
              [106.93574761800005, 11.306475983000029],
              [106.93618627300002, 11.305524049000047],
              [106.93987557400001, 11.297074440000097],
              [106.94069698000003, 11.294886816000103],
              [106.94059983799998, 11.294089332000022],
              [106.93969110000002, 11.293586625000083],
              [106.93868007200004, 11.293282650000068],
              [106.93514693100009, 11.291172663000014],
              [106.93221984600011, 11.28936458100007],
              [106.93106845800006, 11.287401272000073],
              [106.92963863900006, 11.283911401000067],
              [106.92805543600007, 11.278996065000067],
              [106.92790946000007, 11.276145950000059],
              [106.92749155700004, 11.273799239000047],
              [106.92654387000006, 11.270622844000043],
              [106.92635442600002, 11.268131337000041],
              [106.92637418300005, 11.264827563000066],
              [106.92639819099999, 11.260245113000076],
              [106.92702858900002, 11.255865029000043],
              [106.92949527100009, 11.248804608000016],
              [106.92991269700008, 11.24641587300008],
              [106.92981770100005, 11.245219967000073],
              [106.92911167300007, 11.244718278000041],
              [106.92759733700009, 11.243913611000076],
              [106.92517297000005, 11.242905045000027],
              [106.92031591100007, 11.242481695000082],
              [106.91444074600005, 11.243148782000032],
              [106.91109795800006, 11.243529969000042],
              [106.90998757200002, 11.242926507000027],
              [106.9091835450001, 11.241826536000053],
              [106.90915107400008, 11.241230007000079],
              [106.90909133300002, 11.240132549000046],
              [106.90981319400007, 11.237645846000087],
              [106.91104647800006, 11.234165620000079],
              [106.91165608800006, 11.231245620000061],
              [106.91301110000008, 11.230516964000079],
              [106.91410451100003, 11.229043378000101],
              [106.91531851900004, 11.226722351000083],
              [106.91649658299998, 11.223638747000029],
              [106.91630637300005, 11.221585344000038],
              [106.91578327100004, 11.219110920000029],
              [106.91443419400005, 11.216521922000043],
              [106.91381335500002, 11.215635967000102],
              [106.91127504900005, 11.214033888000062],
              [106.90895735400005, 11.212962578000061],
              [106.90634680900006, 11.212353140000085],
              [106.90141012400007, 11.212261218000029],
              [106.9000718880001, 11.211856981000066],
              [106.89958421700007, 11.211148236000044],
              [106.89959345900003, 11.209426949000088],
              [106.90010340000003, 11.205986923000031],
              [106.90103928500011, 11.202262210000045],
              [106.90278568500001, 11.197350133000048],
              [106.90360071100012, 11.195324110000103],
              [106.90387230100004, 11.194663479000065],
              [106.90483930000002, 11.193454732000061],
              [106.90620342300009, 11.193174989000097],
              [106.90879151200009, 11.193762205000011],
              [106.91144650400003, 11.194371765000087],
              [106.91841768100005, 11.19405465700005],
              [106.92368127499999, 11.192447630000045],
              [106.92871180900002, 11.190825723000051],
              [106.93202557900003, 11.18900442400008],
              [106.93427291000008, 11.186669086000032],
              [106.93508720500003, 11.185776709],
              [106.93773039000007, 11.18349904400011],
              [106.9417026460001, 11.180107281000049],
              [106.94353795100008, 11.17831915600007],
              [106.94430497000003, 11.177124778000101],
              [106.94431191300008, 11.175776800000035],
              [106.9421931680001, 11.173519473000047],
              [106.94037956400005, 11.171113887000049],
              [106.93962417600008, 11.170061633000026],
              [106.93947588700007, 11.169311998000085],
              [106.94024056400011, 11.16856696800008],
              [106.94159831700009, 11.167989227000062],
              [106.94250106200008, 11.167519356000087],
              [106.94561233600002, 11.167347628000089],
              [106.94744791400002, 11.166891681000022],
              [106.94783383800007, 11.166795817000084],
              [106.95081743500002, 11.165152057000059],
              [106.95229577200004, 11.164023696000045],
              [106.95630415700005, 11.15876146300012],
              [106.96195285500006, 11.151784205000046],
              [106.96367333800004, 11.150147693000088],
              [106.96437056200008, 11.149239283000066],
              [106.96475868400005, 11.147505036000014],
              [106.9645907280001, 11.145385805000084],
              [106.96388144900003, 11.144323116000086],
              [106.96102919900002, 11.143073312000061],
              [106.95566862100004, 11.143223284000035],
              [106.95365189500006, 11.142886791000082],
              [106.95257938700006, 11.141520543000103],
              [106.95213382300005, 11.139414576000116],
              [106.95195763200005, 11.13632701900009],
              [106.95197098700008, 11.132613008000101],
              [106.954153472, 11.125033132000082],
              [106.9577496090001, 11.120461288000081],
              [106.96151528200001, 11.117655502000011],
              [106.9627755380001, 11.115719937000089],
              [106.96350523700008, 11.112722606000068],
              [106.96387409300004, 11.110429599000058],
              [106.96356424600008, 11.109337292000097],
              [106.96090534899999, 11.109324164000077],
              [106.95823430000006, 11.108584046000123],
              [106.95646264300011, 11.107445555000071],
              [106.955345112, 11.106050773000041],
              [106.94977047400008, 11.096576320000027],
              [106.94792556700003, 11.093967605],
              [106.94714770200005, 11.092867674000038],
              [106.94549549700004, 11.08909284500008],
              [106.9438559380001, 11.08287315000007],
              [106.94367847700008, 11.078486322000101],
              [106.944893854, 11.076072252000111],
              [106.947586137, 11.072583602000028],
              [106.94851011700007, 11.068230745000028],
              [106.94809684600007, 11.064260369000054],
              [106.94731291800005, 11.060688272000084],
              [106.94574626400001, 11.059048549000037],
              [106.94151583200001, 11.056958853000063],
              [106.93985205700002, 11.05735194300008],
              [106.93843368400007, 11.059845226000052],
              [106.93532008300012, 11.063996895000104],
              [106.93418643700002, 11.065115367000098],
              [106.93250047500005, 11.065649572000067],
              [106.93030751800006, 11.065359966000086],
              [106.92801311400004, 11.063681928000067],
              [106.92351821600009, 11.059642660000078],
              [106.92172085400006, 11.058179753],
              [106.92046601600006, 11.055586303],
              [106.92133410100003, 11.050867738000123],
              [106.92116959100009, 11.048595855000086],
              [106.92022881900006, 11.047250368000048],
              [106.91433958400003, 11.044719626000086],
              [106.91257098500007, 11.043860168000029],
              [106.91098006000007, 11.042201853000043],
              [106.91025134400009, 11.040081855000048],
              [106.90960280100006, 11.036916144000129],
              [106.90963306200008, 11.031224640000035],
              [106.90964859000005, 11.028304067000059],
              [106.90882615500003, 11.024410403000038],
              [106.90574362200006, 11.02272749100006],
              [106.90349532600001, 11.022715771000067],
              [106.90010947700009, 11.025198398000045],
              [106.89644250200008, 11.027679503000074],
              [106.89250340100004, 11.028492222000084],
              [106.88717260000004, 11.026797165000078],
              [106.87651735000003, 11.022295448000106],
              [106.87090121900003, 11.021431910000066],
              [106.86611749800009, 11.022517402000085],
              [106.85879046200003, 11.026089318000041],
              [106.84299261700005, 11.036559955000056],
              [106.83790686300006, 11.041254828000111],
              [106.83591578500008, 11.045411128000103],
              [106.83050896500001, 11.057049738000034],
              [106.82794586700003, 11.06286985100011],
              [106.82624659800003, 11.065083005000085],
              [106.82286868500005, 11.065897601000072],
              [106.81500444500004, 11.064742084000033],
              [106.80068979800002, 11.061049098000096],
              [106.79761248900004, 11.058531067000031],
              [106.79706172400005, 11.056583126000062],
              [106.79707150200005, 11.054916217000075],
              [106.79820396900004, 11.053533564000071],
              [106.80413414700008, 11.048844378000043],
              [106.80865712500004, 11.044424900000125],
              [106.81064874500007, 11.040268864000067],
              [106.81151929100007, 11.035550865000053],
              [106.81097489900006, 11.032491781000074],
              [106.80903014700009, 11.028591341000025],
              [106.80510847800005, 11.026346554],
              [106.79500243600009, 11.024344145000063],
              [106.78939315200006, 11.022367208000093],
              [106.78295572000006, 11.017885020000049],
              [106.77373491000007, 11.00894149],
              [106.77291014700005, 11.005880794000065],
              [106.77265240700004, 11.001989996000104],
              [106.77323103900004, 10.999215330000121],
              [106.77740331300002, 10.993198897000054],
              [106.78117801300006, 10.987803039000086],
              [106.78373474100003, 10.984910763000054],
              [106.792201714, 10.977031242000111],
              [106.79354598900002, 10.975717582000042],
              [106.79435642700003, 10.974268712000066],
              [106.79436341700007, 10.973079514000082],
              [106.79330201300009, 10.971752044000114],
              [106.78823591800005, 10.969608681000052],
              [106.78078174900004, 10.967097109000077],
              [106.78053683400003, 10.967014582000088],
              [106.77859554400007, 10.964787124000084],
              [106.76786722200006, 10.966427335000082],
              [106.76585610800001, 10.966244663000067],
              [106.76118619900006, 10.965820427000049],
              [106.76090150500005, 10.965251129000107],
              [106.760904248, 10.964797043000106],
              [106.76069295600011, 10.961617155000079],
              [106.760725876, 10.956168273000076],
              [106.76097144500007, 10.953672243000055],
              [106.76932062800006, 10.945093743000099],
              [106.77117530900003, 10.943288275000016],
              [106.77464866300009, 10.940584099000093],
              [106.77742511900004, 10.938783971000097],
              [106.78019073700008, 10.938800049000095],
              [106.78226763400006, 10.938358023000115],
              [106.78805478000007, 10.934077778000082],
              [106.80633527800003, 10.921468534000043],
              [106.80910988000002, 10.91989505800006],
              [106.81073484700009, 10.91786099000007],
              [106.81190547500005, 10.914689186000091],
              [106.81262044200005, 10.910606701000079],
              [106.81332882400004, 10.907659341000018],
              [106.81541714100008, 10.905173830000068],
              [106.81841664900001, 10.904509651000092],
              [106.82118179900002, 10.904525188000045],
              [106.82394304500004, 10.905221740000036],
              [106.82808559700004, 10.906153008000084],
              [106.83108119900001, 10.906169700000044],
              [106.83408196100005, 10.905278264000094],
              [106.83687921400004, 10.903151501000073],
              [106.83157568100006, 10.900269779000025],
              [106.82583585100006, 10.896605378000055],
              [106.82033317600005, 10.891807066000057],
              [106.8127767710001, 10.88359173500004],
              [106.80568441400001, 10.874924844000054],
              [106.78567760200012, 10.868453686000111],
              [106.77918819600009, 10.86996401600002],
              [106.77806397700006, 10.870225642],
              [106.77828355300002, 10.872043050000089],
              [106.77987065100007, 10.876365588000068],
              [106.77985040200008, 10.879770736000081],
              [106.77867812700006, 10.883169206000074],
              [106.7754334950001, 10.886328590000092],
              [106.77079404100002, 10.891522988000089],
              [106.76754642800007, 10.895136319000096],
              [106.76113583700007, 10.888287933000116],
              [106.75725323200008, 10.88258949000007],
              [106.75818721700008, 10.880551841000051],
              [106.7593680520001, 10.875791445000081],
              [106.76032117600008, 10.870575692],
              [106.74652825300006, 10.865499501000018],
              [106.74464629100005, 10.871844685000019],
              [106.74416467, 10.875247053000068],
              [106.743909253, 10.879331839000093],
              [106.74297514100007, 10.881369421000018],
              [106.74135535300007, 10.882494820000053],
              [106.73533407400005, 10.887453100000053],
              [106.73506578600006, 10.887766398000041],
              [106.73417362200004, 10.88880823600009],
              [106.72954291800006, 10.892412629000068],
              [106.71938340400004, 10.895756283000056],
              [106.71637660200003, 10.897554131000041],
              [106.71454461800006, 10.895726725000097],
              [106.71364144400012, 10.892769888000052],
              [106.71297155400002, 10.889360446000039],
              [106.71210201200002, 10.88424714600009],
              [106.71096389900001, 10.880757033000064],
              [106.71119975800006, 10.879132233000098],
              [106.71419584700007, 10.872502611000099],
              [106.71048930299999, 10.866927066000097],
              [106.70853335600006, 10.867777948000066],
              [106.70631064000001, 10.867783254000061],
              [106.70467698900002, 10.867785092000036],
              [106.70032401300001, 10.868423471000098],
              [106.69677289000001, 10.870837602000092],
              [106.69174430700005, 10.87681622000005],
              [106.68732910700004, 10.882436436000107],
              [106.68687315700006, 10.885040255000076],
              [106.68948245700004, 10.889183806000078],
              [106.68998821100006, 10.890279117000111],
              [106.69229904300002, 10.895283680000121],
              [106.69250207500004, 10.89789167000008],
              [106.69246885300004, 10.903104938000039],
              [106.68822731200001, 10.913722797000068],
              [106.68333745299999, 10.922598791000036],
              [106.68112949400009, 10.924322824000042],
              [106.67783289900009, 10.924519330000047],
              [106.67135925300003, 10.922777706000096],
              [106.66889830600007, 10.921801655000072],
              [106.6659358580001, 10.92163488500004],
              [106.65657832800008, 10.92544838800009],
              [106.65298338900007, 10.928271717000102],
              [106.64953861400008, 10.932900211000083],
              [106.64842156500002, 10.935717134000098],
              [106.64862584600004, 10.938991782000061],
              [106.64881692000004, 10.943337833000031],
              [106.64881794200004, 10.948508872000049],
              [106.64882019700005, 10.959962354000117],
              [106.64805367100004, 10.976251161000048],
              [106.64737011000005, 10.979940056000091],
              [106.64604163400001, 10.981452282000069],
              [106.6447203160001, 10.981878268000054],
              [106.64318213000011, 10.981868341000032],
              [106.64076785400002, 10.981418229],
              [106.63507049900007, 10.978991501000033],
              [106.63177732800008, 10.978535577000057],
              [106.62765044100003, 10.979540531000074],
              [106.62390103400004, 10.98157992400006],
              [106.61942523500008, 10.985406913000071],
              [106.61610707000008, 10.988643920000117],
              [106.61416271500005, 10.991835578000051],
              [106.61474896200006, 10.994500922000048],
              [106.61844382100006, 11.000608514000108],
              [106.61929800800004, 11.004307566000103],
              [106.61905044700008, 11.008433918000014],
              [106.61771864900001, 11.01038052200005],
              [106.61594728100009, 11.012324238000113],
              [106.61330265600003, 11.013393121000091],
              [106.59980107400006, 11.015374745000093],
              [106.59761081800011, 11.016649204000116],
              [106.5945696900001, 11.020872953],
              [106.59387905900007, 11.025430948000102],
              [106.59385224600007, 11.029341613000019],
              [106.59748349100005, 11.035268424000042],
              [106.60125247300006, 11.04004979200006],
              [106.60123625800006, 11.042427180000034],
              [106.6010060830001, 11.043946559000071],
              [106.59923426600005, 11.045890227000131],
              [106.59652704300005, 11.046946493000041],
              [106.59472899000005, 11.046729242000096],
              [106.59285979000003, 11.045847640000071],
              [106.59142149200004, 11.044586352000072],
              [106.59024298200005, 11.042788263000068],
              [106.58917384700005, 11.038435626000085],
              [106.58676351500002, 11.037333071000075],
              [106.58478531800003, 11.037319757000128],
              [106.58280615000005, 11.0375104860001],
              [106.58118718700004, 11.038912675000033],
              [106.58072877400012, 11.039309703000109],
              [106.57947248800009, 11.042715733000106],
              [106.57772464800007, 11.048044333000105],
              [106.57655769500006, 11.050952458000028],
              [106.57367743600005, 11.054192077000023],
              [106.57257078400005, 11.055270948000027],
              [106.57124732400007, 11.055913789000014],
              [106.56949179400003, 11.055467293000019],
              [106.56774992900006, 11.053065387000059],
              [106.56625526600001, 11.046754185000028],
              [106.56428457600003, 11.045654358000066],
              [106.56186668400008, 11.045637825000119],
              [106.54954508000004, 11.047291341000063],
              [106.54513499800001, 11.049216340000088],
              [106.54071554800001, 11.052444840000051],
              [106.53098449900008, 11.060633617000091],
              [106.52276020000004, 11.073178301000029],
              [106.52259957600006, 11.074017414000091],
              [106.52249825900003, 11.076436756000072],
              [106.52349799500006, 11.079022144000115],
              [106.52623832500004, 11.082335959000121],
              [106.52940901300011, 11.086512235000077],
              [106.53142519800004, 11.089391210000075],
              [106.53214235200005, 11.090828673000084],
              [106.53184421300007, 11.091829298],
              [106.53052665200003, 11.092966058000096],
              [106.52863330600005, 11.093239302000056],
              [106.52630864300009, 11.0927932760001],
              [106.52384367000006, 11.091630020000041],
              [106.52166143200007, 11.091614682000015],
              [106.518890012, 11.092597888000098],
              [106.51580479000008, 11.096730244000062],
              [106.51447361700008, 11.099729020000025],
              [106.51489762900007, 11.101450975000125],
              [106.51611914400007, 11.102721106000056],
              [106.51638935500004, 11.102901890000092],
              [106.52165683800004, 11.104242764000015],
              [106.52384542000003, 11.105670528000109],
              [106.52557389800005, 11.109919923000076],
              [106.52596991000004, 11.116006974000035],
              [106.52594491400001, 11.119483551000096],
              [106.52529671400004, 11.120956494000021],
              [106.52485068300007, 11.121822552000092],
              [106.52048140700005, 11.124109839000072],
              [106.51640551500005, 11.12666749600004],
              [106.51178774400006, 11.131009298000015],
              [106.50735569400008, 11.13564989400002],
              [106.50645497400008, 11.136589825000074],
              [106.50401520300009, 11.138606480000044],
              [106.49904696400006, 11.14162761100008],
              [106.49375472000006, 11.14354550500005],
              [106.49013748600008, 11.144436399000044],
              [106.48551928400006, 11.144403179000049],
              [106.48111691800005, 11.143599008000125],
              [106.47672187400011, 11.14313259000008],
              [106.47384385900001, 11.143313669000053],
              [106.47199783200004, 11.143870704000046],
              [106.4698215220001, 11.144479610000067],
              [106.46845310500002, 11.145568606000021],
              [106.46623816000007, 11.147405205000014],
              [106.46591114100002, 11.149041742000064],
              [106.46677944600002, 11.15056922400006],
              [106.46852095500005, 11.152972306000029],
              [106.4688254430001, 11.154636003000105],
              [106.46793279100005, 11.156367962000056],
              [106.46639351200005, 11.158063556000018],
              [106.46255574200003, 11.160105989000078],
              [106.460609085, 11.160661478000032],
              [106.45785888900009, 11.160735113000085],
              [106.45463435800011, 11.160746412000043],
              [106.45257393600006, 11.160541088000018],
              [106.45090163100008, 11.159456545000078],
              [106.4497425110001, 11.157971224000017],
              [106.44945260800009, 11.156312119000054],
              [106.44937697600004, 11.154244471000128],
              [106.44946787500001, 11.153131417000049],
              [106.45048511600007, 11.151761649000045],
              [106.45321796600005, 11.149021080000042],
              [106.45445458800006, 11.147555690000031],
              [106.45515457900007, 11.14542917000003],
              [106.45422766900002, 11.141703564000059],
              [106.45253977400007, 11.139518076000064],
              [106.45082047400011, 11.137848497000034],
              [106.44770705200007, 11.136823134000016],
              [106.44572060100003, 11.136677531000048],
              [106.44441011600004, 11.136581465000093],
              [106.44136777600002, 11.137344008000037],
              [106.4377221670001, 11.138832265000074],
              [106.43284930600007, 11.143359404000106],
              [106.43118890100008, 11.145525882000038],
              [106.43021733700007, 11.14747564800007],
              [106.43009830000008, 11.149147884000053],
              [106.43082345000005, 11.151558432000018],
              [106.43252919300005, 11.153603428000023],
              [106.43454689400002, 11.155791550000059],
              [106.43539504100002, 11.157716055000042],
              [106.43534010300002, 11.159926345000047],
              [106.43433465100004, 11.161983318000066],
              [106.43280702100003, 11.164009227000092],
              [106.43102924600002, 11.166386402000049],
              [106.429224581, 11.168681885000085],
              [106.42778414300001, 11.170083650000072],
              [106.42644217, 11.170630205000085],
              [106.42240986900006, 11.171754747000049],
              [106.41579487400003, 11.173060999000127],
              [106.41273707600007, 11.173825696000071],
              [106.40853151400009, 11.174475239000079],
              [106.38663255100003, 11.186613913000086],
              [106.38509572100007, 11.188653369000077],
              [106.38376736200011, 11.190802478000082],
              [106.38287036800006, 11.192968784000088],
              [106.38266563600008, 11.195016436000047],
              [106.38254465700003, 11.198210765000026],
              [106.38251487000005, 11.198753439000074],
              [106.37840117100002, 11.219149181000086],
              [106.37803097500003, 11.220749134000039],
              [106.37800689700001, 11.223791567000061],
              [106.3777525, 11.228136226000094],
              [106.37686498800011, 11.231974104000118],
              [106.37551222000009, 11.236092991000122],
              [106.37325927800002, 11.23983751300006],
              [106.37014275700008, 11.244377429000084],
              [106.369039195, 11.24480354400008],
              [106.36785682300004, 11.244767203],
              [106.36665511299999, 11.244283400000119],
              [106.36446878600002, 11.242527719000071],
              [106.3626308170001, 11.24023039800006],
              [106.36120447700003, 11.239271371000113],
              [106.35950684600007, 11.239434758000042],
              [106.35785468900006, 11.24015245],
              [106.35461102400009, 11.243359969],
              [106.35194202800005, 11.246903518000105],
              [106.34960676300001, 11.250003870000034],
              [106.34948449500011, 11.251524285000068],
              [106.35008082800007, 11.252615658000041],
              [106.35092751900005, 11.253355812000086],
              [106.35750404300009, 11.256450036000041],
              [106.35955708800006, 11.257688610000026],
              [106.36152263500006, 11.259551330000049],
              [106.36238975200004, 11.261188141000032],
              [106.36232475900007, 11.262437351000097],
              [106.36096547900006, 11.263866648000052],
              [106.35964348700001, 11.26407367700004],
              [106.35552160200002, 11.263385054000084],
              [106.34999146300005, 11.263219428000058],
              [106.3492760140001, 11.263748061000099],
              [106.34923004700002, 11.264823983000037],
              [106.34974834800001, 11.266577222000061],
              [106.35262023100003, 11.278879736000123],
              [106.35439547200011, 11.284693997000071],
              [106.35441083400005, 11.286311216000032],
              [106.3539326480001, 11.287515909000055],
              [106.35325418700009, 11.288991396000101],
              [106.35253154000007, 11.289909470000035],
              [106.35015486000006, 11.291249282000047],
              [106.34712493400011, 11.291714530000084],
              [106.34579327300006, 11.291228083000069],
              [106.34488475000002, 11.290335576000119],
              [106.34409188600006, 11.288042758000037],
              [106.343880853, 11.285662157000063],
              [106.34366440700003, 11.2841887500001],
              [106.34306407000001, 11.283586313000081],
              [106.3421301220001, 11.283415940000017],
              [106.34069135300007, 11.284436996000034],
              [106.33902149800011, 11.286814654000031],
              [106.333773068, 11.296227893000042],
              [106.33347985300007, 11.298453430000064],
              [106.33390586900006, 11.300195637000064],
              [106.33469503100007, 11.301261465000055],
              [106.33728320100008, 11.306697545000064],
              [106.33946160600001, 11.312877442000067],
              [106.341113487, 11.31757585500006],
              [106.3429190630001, 11.322275504000066],
              [106.34276407900006, 11.324317520000092],
              [106.342716083, 11.324647829000066],
              [106.34195693800007, 11.327255646000099],
              [106.34024742300002, 11.329660473000034],
              [106.33699417000005, 11.332959961000098],
              [106.3297339420001, 11.337739095000108],
              [106.32617928800006, 11.34028032600005],
              [106.32478170400007, 11.342082960000075],
              [106.32491803700007, 11.344200103000011],
              [106.32612124600006, 11.347383785000085],
              [106.32824513800003, 11.350725936000037],
              [106.34433020500006, 11.375793061000115],
              [106.355525562, 11.397798442000083],
              [106.35687960400003, 11.401436816000031],
              [106.35985619200002, 11.415695135000091],
              [106.36294875500002, 11.430507126000043],
              [106.364293495, 11.435354857000045],
              [106.36470272000001, 11.441858177000073],
              [106.36574012900005, 11.44670358200006],
              [106.36709468500004, 11.450342123000022],
              [106.36907140000008, 11.453078479000032],
              [106.37121752900002, 11.453850944000086],
              [106.37505825000001, 11.454182980000025],
              [106.38089024900009, 11.455437276000067],
              [106.3864101020001, 11.457293702000102],
              [106.39146411600004, 11.459751112000037],
              [106.39712370700006, 11.463422378000041],
              [106.40155834700008, 11.466479526000038],
              [106.40583937800008, 11.469535428000109],
              [106.40918753700001, 11.473944755000074],
              [106.41191616900008, 11.478954069000034],
              [106.41525291200003, 11.484875011000016],
              [106.41768134400006, 11.488975010000056],
              [106.42027179700008, 11.492017986000034],
              [106.42335604400009, 11.495223601000058],
              [106.426055628, 11.498032723000049],
              [106.42927833600001, 11.50055129500007],
              [106.43005382200005, 11.50098245200004],
              [106.43203444200006, 11.502083624000113],
              [106.43464723400005, 11.50225425300002],
              [106.43607563900011, 11.502096493000112],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 10, code: "AD01", ten_tinh: "Bình Phước" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.219396014, 12.202227116000051],
              [107.22416612400006, 12.167453309000019],
              [107.22605803000002, 12.157682522000089],
              [107.22660782000003, 12.134946017000024],
              [107.22361057900011, 12.130614753000122],
              [107.22222748700007, 12.128563235000021],
              [107.22480417300001, 12.121523942000055],
              [107.22667583600006, 12.116983236000054],
              [107.2334014410001, 12.116098272000018],
              [107.23919895400007, 12.115437131000116],
              [107.2348280050001, 12.10655363800006],
              [107.23067979400005, 12.100172071000069],
              [107.2299921690001, 12.098123199],
              [107.23093297200005, 12.094488670000112],
              [107.23140593700001, 12.091989301000082],
              [107.22871714900003, 12.089645222000021],
              [107.22794533300004, 12.088949645000111],
              [107.22867704200009, 12.078109775000099],
              [107.23035500400002, 12.063337106000025],
              [107.23015582000005, 12.054696573000088],
              [107.2301832310001, 12.047421110000032],
              [107.22974550400002, 12.040598733000072],
              [107.22930693900007, 12.03400375700001],
              [107.228378617, 12.03046556200009],
              [107.22816081800001, 12.029635438000062],
              [107.22867205400003, 12.029394088000076],
              [107.23013147400007, 12.028861102000031],
              [107.23122248200011, 12.029403401000048],
              [107.23212930500009, 12.030483334000051],
              [107.23302737300006, 12.033895936000059],
              [107.2344726960001, 12.03713109600004],
              [107.23665013800004, 12.03947170600008],
              [107.23901651100009, 12.040018583000032],
              [107.24019422000001, 12.039877899000116],
              [107.24047463600003, 12.039844401],
              [107.2423024550001, 12.038236010000068],
              [107.24358635900009, 12.035907904000085],
              [107.24359366600008, 12.033934097000019],
              [107.24232906800007, 12.031058535000037],
              [107.24124467500006, 12.028721941000063],
              [107.24125000500005, 12.027286460000129],
              [107.24344403700005, 12.025141093000078],
              [107.24746101600006, 12.022643341000093],
              [107.25411962300005, 12.020065171000025],
              [107.27162601600001, 12.014922727000064],
              [107.27454122200007, 12.014753342000082],
              [107.27727426800007, 12.014583284000045],
              [107.278547491, 12.015125953000092],
              [107.28054620400007, 12.016568261000058],
              [107.28272395400006, 12.018908350000055],
              [107.28599601200007, 12.020893252000063],
              [107.28854321300007, 12.021799035000045],
              [107.29127757700009, 12.021269937000055],
              [107.29310607100004, 12.019302275000053],
              [107.29384278800006, 12.016972081000075],
              [107.29312469200006, 12.013919276000083],
              [107.29276720400006, 12.011944295000097],
              [107.29368171700006, 12.01087075400009],
              [107.2964183930001, 12.009623865000039],
              [107.30334704300006, 12.007673095000102],
              [107.30644609600006, 12.006965574000093],
              [107.30917901100004, 12.006795112000111],
              [107.311361823, 12.00769942100006],
              [107.31289305500003, 12.009158640000093],
              [107.31514318900005, 12.008209812000102],
              [107.32389711000005, 12.001505635000063],
              [107.33477230600005, 11.993105038000083],
              [107.33709678200009, 11.990044067000037],
              [107.34330538300006, 11.974307715000075],
              [107.34671327100004, 11.970732568000118],
              [107.34946095300009, 11.969280031000016],
              [107.3494706020001, 11.968842982000092],
              [107.34962674900005, 11.961770351000032],
              [107.34968530600001, 11.959117937000128],
              [107.35002057600003, 11.956668210000119],
              [107.3500642930001, 11.956348778000068],
              [107.35090472300001, 11.954710104000108],
              [107.35164399500007, 11.95430996700013],
              [107.35270909899999, 11.954040750000059],
              [107.35391300200004, 11.953771954000024],
              [107.35433087300007, 11.953319079000034],
              [107.35447197900002, 11.952592876000077],
              [107.35444884200007, 11.952243903000069],
              [107.35433948000008, 11.950594217000086],
              [107.3540875190001, 11.949293061000075],
              [107.35331169900003, 11.945286493000127],
              [107.35323131100004, 11.944471798000043],
              [107.35311389600001, 11.943097043000071],
              [107.35298976300004, 11.942256591000076],
              [107.35293270800003, 11.941870300000089],
              [107.35247594900007, 11.941394101000034],
              [107.35210261900009, 11.94100487400007],
              [107.35138813100004, 11.940575267000051],
              [107.35116623400006, 11.940441844000114],
              [107.35218051600005, 11.940158657000042],
              [107.35403966800004, 11.939253863000053],
              [107.3568871730001, 11.936494668000107],
              [107.35790521900005, 11.935508145000105],
              [107.36258039200007, 11.934055253000063],
              [107.36554837800006, 11.934040067000049],
              [107.36601638100007, 11.933660837000025],
              [107.36870682000009, 11.931480649000024],
              [107.37195572300006, 11.929330717000129],
              [107.37485786400002, 11.926952390000077],
              [107.38432851300007, 11.915841229000089],
              [107.38642835300008, 11.910618878],
              [107.39946619600006, 11.886787711000045],
              [107.40215391900004, 11.881548049000033],
              [107.40435862400003, 11.869195816000031],
              [107.40663438600004, 11.867476033000022],
              [107.40877366200002, 11.866675067000097],
              [107.41346746199999, 11.866141467000089],
              [107.41655178100009, 11.866042668000086],
              [107.41917107500011, 11.865259282000105],
              [107.42535429400003, 11.861838625000052],
              [107.42423163900004, 11.858488801000087],
              [107.41748380100003, 11.84285221700012],
              [107.41616189299999, 11.84000863000003],
              [107.41577387500004, 11.839144127000088],
              [107.41467543500002, 11.835314410000118],
              [107.41450075500003, 11.834967663000027],
              [107.41237670100007, 11.830790623],
              [107.40579676, 11.823158001000065],
              [107.40340376500006, 11.818967980000126],
              [107.40298868800001, 11.815759657000056],
              [107.403418952, 11.813506943000094],
              [107.40584309800002, 11.809333748000126],
              [107.40671861900007, 11.808412973000058],
              [107.40774028100006, 11.807760687],
              [107.40739994300004, 11.806987372000053],
              [107.40628032900005, 11.805390230000066],
              [107.40472051200004, 11.804051595000063],
              [107.40231878900001, 11.802929284000106],
              [107.39694545500004, 11.802077339000048],
              [107.39454215500005, 11.801512688000047],
              [107.39369624300005, 11.800534189000087],
              [107.393137403, 11.798162134000021],
              [107.39243919500004, 11.795092487000057],
              [107.39244747900003, 11.792303767000066],
              [107.39387200600002, 11.788961384000094],
              [107.39544083500007, 11.784643363000045],
              [107.39573445100002, 11.781018882000092],
              [107.39602930100008, 11.77697611600008],
              [107.3946242900001, 11.773765098000053],
              [107.39194713500009, 11.770271541000097],
              [107.38890201700012, 11.767805685000086],
              [107.38787163300005, 11.768555749000063],
              [107.38668600300008, 11.769752861000081],
              [107.38453003700005, 11.772006470000028],
              [107.38216294100003, 11.772988246000081],
              [107.38037150900004, 11.773124232000052],
              [107.37292418400007, 11.77204287200002],
              [107.37024030000005, 11.771187412000115],
              [107.36784615600003, 11.769932513000112],
              [107.366093015, 11.768574434000099],
              [107.36266677200003, 11.764667456000042],
              [107.36117156800003, 11.762775123000056],
              [107.35995516500002, 11.761269422000085],
              [107.35538204000009, 11.757583191],
              [107.35108140300005, 11.754534183000052],
              [107.34465675300005, 11.751335503000069],
              [107.33363318300003, 11.748899839000069],
              [107.33062419700011, 11.749031544000042],
              [107.32746637000005, 11.750857607000052],
              [107.32631978500004, 11.750995187000067],
              [107.32503133900011, 11.750708575000067],
              [107.32202792900001, 11.749145290000039],
              [107.31731438300002, 11.744892879000089],
              [107.31417070700006, 11.742481611000064],
              [107.311738636, 11.741485002000028],
              [107.30701208300007, 11.741187033000065],
              [107.30185481200004, 11.741170005000077],
              [107.30018816900008, 11.740628936000073],
              [107.29869813300007, 11.739519429000021],
              [107.286708893, 11.729820245000065],
              [107.28471327900007, 11.726988745000087],
              [107.28401186100004, 11.722749284000054],
              [107.28431663500004, 11.71752462400004],
              [107.28362862000003, 11.70947195000007],
              [107.28213144500006, 11.703892641000023],
              [107.28292420800003, 11.702476788000114],
              [107.28400139800006, 11.696747563000097],
              [107.28401842000005, 11.691883383000066],
              [107.28367700800004, 11.688755228000057],
              [107.28174883500003, 11.685969123000104],
              [107.27683195100008, 11.681435567000074],
              [107.27030149900003, 11.671011647000016],
              [107.26645258300006, 11.666055361000092],
              [107.26435913400005, 11.662357589000026],
              [107.26403052700003, 11.661330300000115],
              [107.26403993900001, 11.659974252],
              [107.26433040600006, 11.658845470000044],
              [107.26641876400001, 11.657234074000121],
              [107.26734619900002, 11.656455168000067],
              [107.26878273000003, 11.655189151000032],
              [107.27065385400005, 11.652512390000068],
              [107.27123217500011, 11.650960936000041],
              [107.27094880200011, 11.650112615000106],
              [107.26970164600004, 11.648484230000109],
              [107.26784797900005, 11.646288839000094],
              [107.26713733500006, 11.644803535000035],
              [107.26692684700004, 11.64360241500013],
              [107.26707484300003, 11.642261329000101],
              [107.26750996400006, 11.640709406000036],
              [107.26837439000009, 11.639229581000075],
              [107.26930989200004, 11.637891234000096],
              [107.27131825300003, 11.636839040000078],
              [107.27482978100005, 11.635862613000112],
              [107.27783913300001, 11.63516685300001],
              [107.28373074600005, 11.63341487700009],
              [107.28479143200006, 11.632931060000116],
              [107.28615485900008, 11.632017753000044],
              [107.28818961600003, 11.630311769000022],
              [107.29868140700002, 11.62213921100008],
              [107.30160570100003, 11.619680619000048],
              [107.30260603000002, 11.618073342000022],
              [107.30374925800002, 11.614535155000015],
              [107.30449485400007, 11.610368520000057],
              [107.30486094800008, 11.606025712000088],
              [107.30522485200008, 11.604261720000062],
              [107.30519616900001, 11.600978383],
              [107.30494950100004, 11.599847857000077],
              [107.30281174300008, 11.597016511000023],
              [107.29882222000003, 11.59149595200004],
              [107.29640126000001, 11.587816353000056],
              [107.29618289700005, 11.58711960900005],
              [107.29619119300003, 11.584706833000078],
              [107.2979674540001, 11.579843161],
              [107.29988129900009, 11.574836568000089],
              [107.3029161270001, 11.566374109000053],
              [107.30853084200007, 11.55679052700007],
              [107.31025599000007, 11.554536890000032],
              [107.31168774300005, 11.554400375000016],
              [107.31440667800004, 11.554550448000088],
              [107.32059398100003, 11.554934603000076],
              [107.32593775300005, 11.555293594000114],
              [107.3295505200001, 11.555305130000091],
              [107.33128394500008, 11.554920002000111],
              [107.33207864700002, 11.554043577000071],
              [107.33316829800003, 11.55093937],
              [107.33461245200006, 11.548112517000051],
              [107.33478667800009, 11.547771465000077],
              [107.25522713600003, 11.529713330000034],
              [107.24790015900007, 11.528048628000052],
              [107.24017297200002, 11.527023961000092],
              [107.17634703400006, 11.513523693000048],
              [107.1739299970001, 11.513012038000079],
              [107.17342448400009, 11.51288848300007],
              [107.16732559200005, 11.511570377000078],
              [107.15679117400001, 11.509285056000083],
              [107.15291049300008, 11.508495626000046],
              [107.14644066900007, 11.506907067000059],
              [107.13442225800006, 11.503540919000075],
              [107.13032039900004, 11.502154708000132],
              [107.13001854300003, 11.501569842000057],
              [107.12832462100002, 11.498976609000076],
              [107.11932216100007, 11.487888882000073],
              [107.11808868800007, 11.486563246000079],
              [107.11465726300001, 11.484109615000103],
              [107.11193755100004, 11.482095656000089],
              [107.11000193600003, 11.481601234000037],
              [107.1069735180001, 11.481368922000089],
              [107.10353053000007, 11.48167975800005],
              [107.10115358600004, 11.482459518000086],
              [107.09941820900008, 11.482916888000092],
              [107.09800339700007, 11.48318128100003],
              [107.09723065200009, 11.483186843000047],
              [107.09587318800004, 11.482497512000032],
              [107.09489835700002, 11.48129701400009],
              [107.09437053600004, 11.479584872000091],
              [107.09436069100003, 11.47825032400007],
              [107.09479127900005, 11.475514455000011],
              [107.09484535500007, 11.474115906000025],
              [107.09470625600002, 11.472718735000063],
              [107.09398717100004, 11.471262179000073],
              [107.09249443300007, 11.46968404400006],
              [107.08496833500007, 11.464787330000091],
              [107.07880690600008, 11.45930770100003],
              [107.06888249600004, 11.450808991000104],
              [107.06710370500005, 11.448505251000094],
              [107.06569004800005, 11.44461368800007],
              [107.06552483300005, 11.441610694000113],
              [107.06644115000003, 11.436846487000095],
              [107.07005549200002, 11.428915434000062],
              [107.07294116800004, 11.423806781000055],
              [107.07402187000011, 11.421727764000076],
              [107.07418760800006, 11.418724442],
              [107.07402508800003, 11.418337186000114],
              [107.07245558300011, 11.413804099000034],
              [107.06892420200003, 11.409406839],
              [107.06420925100009, 11.405004275000062],
              [107.05771762400006, 11.400885796000091],
              [107.05506778900011, 11.397952843000068],
              [107.05448765000006, 11.394619115000102],
              [107.05659613799999, 11.391926206000045],
              [107.05850431800005, 11.389694761000067],
              [107.05927547200008, 11.387084897000042],
              [107.05948239700007, 11.383352523000044],
              [107.05854979100008, 11.37980174600006],
              [107.05761035600007, 11.377744241000086],
              [107.053634554, 11.375673109000049],
              [107.0496553780001, 11.374348535000054],
              [107.04661554600003, 11.375081433000032],
              [107.04395089100008, 11.376749303000098],
              [107.04234668200004, 11.378155754000117],
              [107.04074413200006, 11.378204742],
              [107.03920215200002, 11.377660981000036],
              [107.03561048600002, 11.374657945000076],
              [107.0345358590001, 11.371984923000065],
              [107.03487074900001, 11.370548003000101],
              [107.037542414, 11.367387004000038],
              [107.03792983300008, 11.365708834000097],
              [107.03604536900012, 11.362900315000067],
              [107.03208066600008, 11.358588955000036],
              [107.028255964, 11.356815387000017],
              [107.02284582200009, 11.356337601000098],
              [107.02037595400002, 11.355086053000017],
              [107.01960409, 11.353153961000116],
              [107.0175936360001, 11.349956338000043],
              [107.01498224700005, 11.345361039000055],
              [107.01277033800008, 11.341963193000113],
              [107.00974152000003, 11.339956331000066],
              [107.00226227899999, 11.336732793000069],
              [106.99538312200005, 11.335105968000024],
              [106.98546279200008, 11.334062048000069],
              [106.97999379700011, 11.334035600000012],
              [106.97655234800001, 11.333620358000116],
              [106.97615620100001, 11.331825088000079],
              [106.976767841, 11.331031021000102],
              [106.9783932280001, 11.330042626000123],
              [106.98205004300007, 11.327868496000091],
              [106.98409035700004, 11.324889473000075],
              [106.98430179400003, 11.323097164000016],
              [106.98412197400008, 11.318513377],
              [106.98337678300007, 11.315620561000104],
              [106.98307791800002, 11.314622833000064],
              [106.98227123700008, 11.313921541000036],
              [106.98115779200005, 11.313816526000052],
              [106.97801252700005, 11.314996800000097],
              [106.97405309200009, 11.316970087000032],
              [106.97009557600002, 11.318544803000028],
              [106.96715674200004, 11.318928904000101],
              [106.96513083500002, 11.31901858100006],
              [106.96300970400003, 11.317912221000091],
              [106.96109265800004, 11.316507965000076],
              [106.95877258100009, 11.31470318200005],
              [106.95716294100005, 11.312603028000099],
              [106.95496047500006, 11.307610727000037],
              [106.95366133900005, 11.304216949000077],
              [106.95285836500005, 11.302818183000072],
              [106.95164424800004, 11.302612861000048],
              [106.95032325199999, 11.303502909000061],
              [106.94868873900002, 11.306284302000067],
              [106.94654785000007, 11.309063148000041],
              [106.94532805200004, 11.309953680000094],
              [106.94390827800008, 11.310345062000058],
              [106.94086927300008, 11.310529039000093],
              [106.93874320800002, 11.310418699000111],
              [106.93722835000008, 11.309614030000057],
              [106.93622293700004, 11.308214166000051],
              [106.93602454600006, 11.307416144000102],
              [106.93618627300002, 11.305524049000047],
              [106.93574761800005, 11.306475983000029],
              [106.93476249200008, 11.308900733000103],
              [106.93544334900011, 11.311175729000043],
              [106.93773455000004, 11.31459465300013],
              [106.9418765130001, 11.317341410000024],
              [106.94533290700009, 11.3187217120001],
              [106.94901326100005, 11.321466036000059],
              [106.95084416500001, 11.32465539900007],
              [106.95079763200008, 11.333741602000112],
              [106.94984836100005, 11.33873444700013],
              [106.94751350800004, 11.34372039300011],
              [106.94402505300006, 11.34847339800009],
              [106.94077560100004, 11.351637409000135],
              [106.93568210000005, 11.354110544000038],
              [106.93082771300003, 11.354994603000089],
              [106.92458765800002, 11.355871472000077],
              [106.91904156000007, 11.356524560000112],
              [106.91465132700007, 11.356956294000041],
              [106.91049926199999, 11.356026156000086],
              [106.90519984400001, 11.353726958],
              [106.903140894, 11.350081539000099],
              [106.90131780700005, 11.345528694000071],
              [106.90017903900008, 11.342569588000105],
              [106.89786981200005, 11.342557503000082],
              [106.89688419600004, 11.342623752000057],
              [106.89486659600009, 11.342768917000077],
              [106.89001599400011, 11.342970539000062],
              [106.88470476700004, 11.342942448000047],
              [106.87987899300008, 11.338600685000076],
              [106.87436941600002, 11.332665028000031],
              [106.868636791, 11.325365121000084],
              [106.86842220000008, 11.322410889000077],
              [106.86959799700003, 11.318555512000026],
              [106.87263088300006, 11.31289285900001],
              [106.87322144400008, 11.308522706000089],
              [106.87232587100004, 11.308928044000053],
              [106.86968865500008, 11.310121626000045],
              [106.8625569500001, 11.312957253000098],
              [106.85726226100006, 11.314566093000076],
              [106.85611994500005, 11.31496927400004],
              [106.85497133200005, 11.316498219000069],
              [106.85433003000001, 11.319974510000106],
              [106.85357992000003, 11.324371334000103],
              [106.85345909500006, 11.327441108000079],
              [106.85416916000003, 11.330208382000071],
              [106.85540148600002, 11.332262057],
              [106.85673806400004, 11.334213956000026],
              [106.85958183800005, 11.336522097000044],
              [106.86153868800005, 11.338784381],
              [106.86199062500005, 11.34028105200011],
              [106.86197584300007, 11.342942064000015],
              [106.86148299700005, 11.34413294400005],
              [106.86036346700007, 11.345226293000097],
              [106.85843394600002, 11.346095733000093],
              [106.85738337500004, 11.346601860000048],
              [106.85614833700001, 11.34792355200007],
              [106.85488221500005, 11.350989273000065],
              [106.85453804700008, 11.356923797000105],
              [106.85436226700001, 11.369819307000027],
              [106.85435196300006, 11.371661624000049],
              [106.8552230710001, 11.373207728000077],
              [106.85550765000002, 11.373507949000048],
              [106.85255907700012, 11.377412294000065],
              [106.85179867700001, 11.377793636000087],
              [106.82419543200004, 11.38577991900007],
              [106.81931650000004, 11.381682100000122],
              [106.8157674170001, 11.379262022000079],
              [106.81139464900005, 11.376963231000035],
              [106.79724072400002, 11.379194227000065],
              [106.786745405, 11.380847897000111],
              [106.78333351700006, 11.382076881000012],
              [106.77223517000006, 11.399765339000021],
              [106.76725765400008, 11.408991861000038],
              [106.76874709200003, 11.427344376000102],
              [106.77273605200003, 11.427757475000087],
              [106.77594720300007, 11.427660709000074],
              [106.77822796900004, 11.427981350000048],
              [106.78101003700002, 11.42929242900008],
              [106.78509787400003, 11.431587239000061],
              [106.78745805000008, 11.433196624000084],
              [106.78951026400006, 11.435658414000025],
              [106.80296189700007, 11.450692352000134],
              [106.80422609100006, 11.452385639000083],
              [106.80486162500006, 11.453773510000074],
              [106.80487192100003, 11.455397212000038],
              [106.80464966500001, 11.457408945000045],
              [106.80466340200009, 11.459573873000027],
              [106.80553545700003, 11.461192248000083],
              [106.80632276000007, 11.462006101],
              [106.80625661500002, 11.462324277000045],
              [106.80189123900004, 11.483321042000048],
              [106.79543524900002, 11.49260930100011],
              [106.79399919500004, 11.492240841000088],
              [106.79160974300005, 11.49225553400006],
              [106.786729291, 11.491342256000079],
              [106.78332470100004, 11.489523760000074],
              [106.77910212200007, 11.486814123000054],
              [106.77192143700003, 11.483617801],
              [106.76598253900008, 11.482468198000094],
              [106.76519080200003, 11.481228628000027],
              [106.76274315700002, 11.480410382000093],
              [106.75804619900006, 11.479779815000056],
              [106.75376413800005, 11.478146756000028],
              [106.75010015400002, 11.475713408000042],
              [106.74889235500009, 11.472691722000137],
              [106.74891568400008, 11.468873494000052],
              [106.74955208900006, 11.465058928000092],
              [106.75244495300007, 11.459851020000132],
              [106.75472339300011, 11.454840436000067],
              [106.75413235400005, 11.451219620000062],
              [106.75129358300009, 11.44758549400011],
              [106.745999157, 11.444539612000069],
              [106.74131048500001, 11.442702978000073],
              [106.7403036380001, 11.440285453000063],
              [106.740117846, 11.43726996000005],
              [106.73972155300008, 11.435258015000025],
              [106.73687209500012, 11.433432310000045],
              [106.73299484700004, 11.432605179000086],
              [106.72951755200009, 11.433187081000094],
              [106.72645264699999, 11.433168554000071],
              [106.72481927700002, 11.432957707000034],
              [106.72442564600006, 11.430543841000061],
              [106.72464749300011, 11.427731805000095],
              [106.72466501300005, 11.424918540000116],
              [106.7226280890001, 11.423901410000068],
              [106.71935465500005, 11.424053465000037],
              [106.71886295800005, 11.424045072000053],
              [106.71794513000005, 11.421260526000053],
              [106.71694873600003, 11.417235387000078],
              [106.71675575400005, 11.415425641000086],
              [106.71167709500003, 11.410772713000126],
              [106.70699838300007, 11.407528799000131],
              [106.70436915900004, 11.403292683000108],
              [106.702356928, 11.398253855000105],
              [106.70093783200008, 11.398916955000081],
              [106.6985591000001, 11.400308168000082],
              [106.69786504500004, 11.400928416000065],
              [106.69019607200002, 11.400197035000035],
              [106.68380101700006, 11.39554427800009],
              [106.67728286900005, 11.378293955000094],
              [106.67733909, 11.37710892200001],
              [106.677270451, 11.376174005000015],
              [106.675434669, 11.364649076000076],
              [106.67380479600004, 11.355707485],
              [106.66810389600006, 11.355523358000051],
              [106.6639617650001, 11.355746319000025],
              [106.66365899800009, 11.355822871000052],
              [106.65831068500006, 11.357175064000023],
              [106.65755879600002, 11.35749027200008],
              [106.65155593400002, 11.360006770000018],
              [106.64867950800004, 11.360671426000033],
              [106.64160710800009, 11.36101024200009],
              [106.63766409500008, 11.360732849000053],
              [106.63149494600007, 11.359968839000077],
              [106.62493229200001, 11.36110267000001],
              [106.61751478900003, 11.362989397000065],
              [106.6159023120001, 11.363796484000053],
              [106.61479456400011, 11.364501010000081],
              [106.61173401900001, 11.377389383000123],
              [106.61130532100006, 11.382031478000075],
              [106.61185110800001, 11.389212618000053],
              [106.59114895800005, 11.382889632000024],
              [106.58942855700008, 11.3824998090001],
              [106.58441950200005, 11.381490442000073],
              [106.56916928000007, 11.375072249000072],
              [106.56644318600011, 11.373491725000038],
              [106.56313038800002, 11.372059295],
              [106.56070405400007, 11.371564650000019],
              [106.55798710600007, 11.371651540000103],
              [106.55512681400002, 11.372391690000059],
              [106.55322281300005, 11.373416776000054],
              [106.55110084000006, 11.374878011000076],
              [106.54869642100007, 11.378443294000105],
              [106.54592903200007, 11.382808004000109],
              [106.54322641100001, 11.385577294000091],
              [106.53780396100007, 11.389354064000122],
              [106.54067557700007, 11.391818824000042],
              [106.543688945, 11.397041454000066],
              [106.54501321200006, 11.401482149000051],
              [106.54652525200002, 11.406699300000065],
              [106.54667714100006, 11.410372333000039],
              [106.54649340600005, 11.41337778600011],
              [106.54577332400007, 11.415946748000037],
              [106.54297072800009, 11.420034132000099],
              [106.54031022100004, 11.424651120000105],
              [106.53976504100005, 11.426485682000108],
              [106.54009067200009, 11.42832691400004],
              [106.54313278000008, 11.433032666000107],
              [106.54690228100004, 11.436660335000058],
              [106.55109834200005, 11.439345649000108],
              [106.55419879200007, 11.440371973000117],
              [106.55829969200009, 11.441986411000114],
              [106.56144134200007, 11.4441438860001],
              [106.56840176200008, 11.450757049000069],
              [106.57068389300008, 11.454186747000071],
              [106.57246533500003, 11.456863898000067],
              [106.57167065200009, 11.458210886000051],
              [106.55576971500001, 11.485159087000072],
              [106.55563699200005, 11.485383987000118],
              [106.55470472900001, 11.483850378000058],
              [106.55269321300001, 11.480541236000084],
              [106.55132738599998, 11.478558457000075],
              [106.54709170299999, 11.472409236000123],
              [106.54402671500006, 11.469353057000118],
              [106.54176614800008, 11.468932742000069],
              [106.53867818000001, 11.469113656000028],
              [106.53558008800009, 11.470710780000013],
              [106.52916053600006, 11.476331376000102],
              [106.5210929350001, 11.482142580000064],
              [106.50497554600005, 11.491133919000072],
              [106.49546907200006, 11.496529357],
              [106.49010198800008, 11.498716673000063],
              [106.485156859, 11.499490502000052],
              [106.46757700900011, 11.499211142000119],
              [106.46418650600006, 11.500204195000039],
              [106.4512626480001, 11.501167739],
              [106.44326708200006, 11.501108756000098],
              [106.43607563900011, 11.502096493000112],
              [106.43464723400005, 11.50225425300002],
              [106.43203444200006, 11.502083624000113],
              [106.43005382200005, 11.50098245200004],
              [106.42927833600001, 11.50055129500007],
              [106.426055628, 11.498032723000049],
              [106.42335604400009, 11.495223601000058],
              [106.42191887000011, 11.498863551000047],
              [106.42142208000004, 11.502326142000106],
              [106.42115497200005, 11.506483743000027],
              [106.42300060100008, 11.511003839000098],
              [106.42506981300005, 11.517027719000071],
              [106.42655504000001, 11.522585080000079],
              [106.42907010200001, 11.531849106],
              [106.430036059, 11.536130205000104],
              [106.43211989200002, 11.54030551800007],
              [106.43702308300008, 11.544732880000106],
              [106.44100019300008, 11.547304484000074],
              [106.446877425, 11.547347926000027],
              [106.45371771900002, 11.544393967000042],
              [106.45936500600001, 11.543742067000016],
              [106.46546856900008, 11.544942151000013],
              [106.47109167100002, 11.547525176000038],
              [106.47741332200003, 11.551037519000127],
              [106.47973336700007, 11.555214141000112],
              [106.48344701400002, 11.561711858000121],
              [106.48670432900006, 11.566357420000093],
              [106.48808256500006, 11.570758359000076],
              [106.48795517800008, 11.571726833000094],
              [106.48780647800004, 11.576302989000096],
              [106.48657959800005, 11.583227500000069],
              [106.48555969900012, 11.58481764500001],
              [106.48339290600008, 11.58949611400004],
              [106.48254708700009, 11.591691332000041],
              [106.48162642000003, 11.594080768000124],
              [106.48125340200006, 11.597653844000087],
              [106.48244970300001, 11.5998760460001],
              [106.48364730100008, 11.601927974000057],
              [106.48467418400004, 11.60363813300007],
              [106.48413677500007, 11.606018132000083],
              [106.48151525700005, 11.609064228000053],
              [106.47733561100009, 11.61192875700001],
              [106.47247170300008, 11.61359631700005],
              [106.46847528500008, 11.615099794000104],
              [106.46655156400007, 11.617469697000081],
              [106.46472435900006, 11.620876293000036],
              [106.46426181200007, 11.624789366000064],
              [106.46481745100004, 11.629206397000045],
              [106.46461479600006, 11.633121415000057],
              [106.46372541600002, 11.636180020000085],
              [106.46196320500005, 11.640083678000044],
              [106.45794951200004, 11.643800574000094],
              [106.456373259, 11.646002732000047],
              [106.45318975800004, 11.654493682000043],
              [106.45317173800005, 11.656877571000022],
              [106.45301951900007, 11.657444615000133],
              [106.45200389900006, 11.661227974000086],
              [106.45088535600006, 11.66607895200009],
              [106.45072292900002, 11.670542856000042],
              [106.45060612300004, 11.673752966000082],
              [106.45207145600006, 11.683597869000016],
              [106.45228040500011, 11.68990339800011],
              [106.44836959200009, 11.697943811000043],
              [106.43957420800004, 11.706956760000063],
              [106.43010346500007, 11.720251306000049],
              [106.42516250000008, 11.728536089000039],
              [106.42123820700002, 11.738089340000089],
              [106.41780161400007, 11.750924625],
              [106.41259523100005, 11.766364623000046],
              [106.4187509920001, 11.782610470000064],
              [106.42253125000006, 11.789003866000057],
              [106.42792861400007, 11.795843312],
              [106.43585425800009, 11.809211334000034],
              [106.44553877200008, 11.823894249000054],
              [106.44669859300004, 11.826362143000056],
              [106.44638857400005, 11.828385200000112],
              [106.44266823300006, 11.833276466000074],
              [106.43895322800009, 11.837444377000049],
              [106.43745790100009, 11.840326691000056],
              [106.43753005900001, 11.850164841000053],
              [106.43764201800005, 11.854795173000097],
              [106.43814442700005, 11.856534963000074],
              [106.43988486500008, 11.860164683000017],
              [106.44367492700005, 11.865545633000083],
              [106.44557608100006, 11.867440429000096],
              [106.44690217500009, 11.867450205000106],
              [106.44882752200006, 11.866162313000038],
              [106.45163360700006, 11.865314904000089],
              [106.45429123500004, 11.864611038000115],
              [106.45796931899999, 11.865361354000113],
              [106.46208403, 11.866838157000091],
              [106.46398449600004, 11.867520216000036],
              [106.46376495300008, 11.8680587810001],
              [106.46236702800006, 11.871487993000061],
              [106.42312683000004, 11.948342337000016],
              [106.40948865900005, 11.972628224000024],
              [106.4085842790001, 11.976556964000016],
              [106.42310434400005, 11.972603067000074],
              [106.42637428100007, 11.972281013000073],
              [106.4291195640001, 11.972586750000056],
              [106.43135094600008, 11.973822126000043],
              [106.43207805800003, 11.975129768000057],
              [106.43338045100005, 11.978322694],
              [106.43585565000004, 11.982392487000125],
              [106.43921994200004, 11.985890076000059],
              [106.44244574100004, 11.988229036000051],
              [106.44471417600006, 11.989136342000071],
              [106.44696922200004, 11.989503128000043],
              [106.45231374800002, 11.989603952000037],
              [106.45452522300005, 11.989620172000047],
              [106.45872652000003, 11.987313454000057],
              [106.46341356900005, 11.984042048],
              [106.46623858500003, 11.980879354000072],
              [106.46936595000005, 11.97670598600009],
              [106.47395333800002, 11.974424154000117],
              [106.47971136200002, 11.973308215000065],
              [106.48472794500005, 11.972765559000019],
              [106.49003719900004, 11.972514255000098],
              [106.49577697900004, 11.973857511000039],
              [106.50062382500006, 11.976351801000094],
              [106.51031680000004, 11.98148475800007],
              [106.51385082900006, 11.982088468000105],
              [106.5175375420001, 11.981969731000051],
              [106.52226671200006, 11.98041130200007],
              [106.52492768200005, 11.979417081000053],
              [106.52946046100001, 11.972939708000039],
              [106.531067304, 11.969988435000099],
              [106.62564654500005, 11.976049838000067],
              [106.62621352600007, 11.972189782000074],
              [106.62768814400005, 11.965234527000044],
              [106.62887217500004, 11.962736856000065],
              [106.63082277500006, 11.96211276200003],
              [106.63300166800003, 11.963011121000109],
              [106.63342506099998, 11.963394089000088],
              [106.63356178900003, 11.965411196],
              [106.63343636200003, 11.968363155000093],
              [106.63502852100005, 11.97241277300005],
              [106.63701418100004, 11.974721919000062],
              [106.63943814800004, 11.975946157000104],
              [106.64045403299998, 11.975952710000024],
              [106.64143795400004, 11.975402473000061],
              [106.64195098000003, 11.973061763000102],
              [106.64211736200012, 11.971189535000091],
              [106.64296658000004, 11.96847570700008],
              [106.64442946200002, 11.966067972000081],
              [106.646932511, 11.964633769000075],
              [106.65137167100004, 11.963695354000057],
              [106.65506564300006, 11.963718950000056],
              [106.65727250100005, 11.965183295000068],
              [106.65791240400006, 11.966744346],
              [106.65765508200008, 11.968259017],
              [106.65710631300006, 11.969274160000063],
              [106.6537833790001, 11.971445635000054],
              [106.65208228900003, 11.97264333600012],
              [106.65080735200007, 11.974568915000104],
              [106.64979988100002, 11.977946504000057],
              [106.64977752600004, 11.981330413000089],
              [106.65073873900008, 11.984962348000037],
              [106.65258270700005, 11.987851499000078],
              [106.65371906300001, 11.98832294],
              [106.65607984700004, 11.988892676000072],
              [106.66058559900009, 11.985750279000039],
              [106.66833958600003, 11.979757595000072],
              [106.67260802400006, 11.976588340000125],
              [106.67576413500007, 11.974244861000026],
              [106.68183684200008, 11.969378697000097],
              [106.69055157900004, 11.966174276000084],
              [106.70176401600006, 11.967135778000058],
              [106.71044788300003, 11.968415802],
              [106.71556972900007, 11.970351022000079],
              [106.72241141100002, 11.975520470000054],
              [106.73054682, 11.986126013000041],
              [106.73209124800006, 11.987851669000092],
              [106.73566676300004, 11.992950690000013],
              [106.73863694100004, 11.996657166000089],
              [106.74066351400003, 12.000000175000112],
              [106.74310469700005, 12.003601628000114],
              [106.74631883800006, 12.011669441],
              [106.75133896200003, 12.024385337000075],
              [106.75755674400006, 12.038826288000058],
              [106.76713697700004, 12.052097601000076],
              [106.77937685800006, 12.069613011000067],
              [106.78710475, 12.078908453000013],
              [106.79111019600002, 12.082367507000045],
              [106.79405624100005, 12.0834416200001],
              [106.79834584000007, 12.084259038000054],
              [106.81525301800009, 12.084618988000082],
              [106.82116070800004, 12.084123461000027],
              [106.82520178900009, 12.081502876000092],
              [106.83058050400007, 12.077041750000104],
              [106.83371384900005, 12.074002135000049],
              [106.84083732400006, 12.069431109000027],
              [106.847282749, 12.068673508000048],
              [106.85184656000006, 12.068434093000045],
              [106.85479568600007, 12.068978741000079],
              [106.8577403940001, 12.070316248000077],
              [106.86336155400005, 12.072989708000124],
              [106.87247618400005, 12.074888791000136],
              [106.88035089700004, 12.074807175000045],
              [106.88683471500005, 12.074370133000032],
              [106.89977550100008, 12.071256124000039],
              [106.90749034300008, 12.069338132000061],
              [106.91048309700008, 12.067395524000089],
              [106.91223709900007, 12.064712219000027],
              [106.91274325400012, 12.063001512000058],
              [106.91808153100003, 12.062657752000037],
              [106.93796399300003, 12.066910043000066],
              [106.94434085000007, 12.06873221800002],
              [106.94987374700001, 12.072095611000043],
              [106.97685619500002, 12.08880817200013],
              [106.99190957000005, 12.095761051000052],
              [106.99215748500005, 12.095875546000022],
              [107.00161659400004, 12.11335300900007],
              [107.01010816000009, 12.120347207000036],
              [107.04100001800001, 12.132973900000097],
              [107.04445633200007, 12.134425265000102],
              [107.04572549000001, 12.135866686000083],
              [107.04716605600001, 12.139641870000071],
              [107.05077144300007, 12.148272279000059],
              [107.05312193000003, 12.152410488000063],
              [107.05756424700007, 12.157545096000087],
              [107.06065404300001, 12.159532985000075],
              [107.06593467100004, 12.160812679000086],
              [107.07431187900004, 12.162644250000108],
              [107.07667680000004, 12.163731404000087],
              [107.07758259100004, 12.164991622000086],
              [107.07812136600002, 12.16678863800009],
              [107.07811245500008, 12.168762728000091],
              [107.07700975400006, 12.170732032000052],
              [107.07353305700008, 12.173588271000122],
              [107.06932704000006, 12.176441247000101],
              [107.06840978800003, 12.17769347400006],
              [107.06876130400006, 12.180566523000012],
              [107.07128994500002, 12.185782275000088],
              [107.072920164, 12.1881225500001],
              [107.07401241900004, 12.188486293],
              [107.07528862300003, 12.188491889000106],
              [107.07803225200011, 12.186529722000026],
              [107.08132195800007, 12.184749370000068],
              [107.08443171500008, 12.18242978700008],
              [107.08570948800011, 12.182076384000061],
              [107.08826421000001, 12.181549035000037],
              [107.09044951800007, 12.182096868000068],
              [107.09207753300004, 12.184975389000028],
              [107.09425015300003, 12.188394649000054],
              [107.09440667900002, 12.189182930000085],
              [107.09478515800006, 12.191089005000054],
              [107.09550171500003, 12.193963615000046],
              [107.09548343300003, 12.198091384000074],
              [107.09674064500004, 12.20240410600004],
              [107.09818103000003, 12.206538151000055],
              [107.09999405100002, 12.208879049000119],
              [107.10653369500004, 12.214470556000022],
              [107.12154724900006, 12.226463496000049],
              [107.12470979700007, 12.228007451000076],
              [107.13629679100009, 12.240038691000075],
              [107.14338796200008, 12.252852326000061],
              [107.14510133800009, 12.257436080000071],
              [107.14889008800004, 12.267323013000107],
              [107.15069453200005, 12.271996909000025],
              [107.15485750900005, 12.276294866000102],
              [107.16108810900008, 12.278103326000076],
              [107.16471136000011, 12.278693837000086],
              [107.17199990200001, 12.280517427000069],
              [107.17709906000002, 12.282511731000055],
              [107.18110247700007, 12.284860642000071],
              [107.18346436600009, 12.28720314800009],
              [107.1863649260001, 12.291701572000056],
              [107.18945088800008, 12.295482734000094],
              [107.19227221600002, 12.297019211000036],
              [107.19628245100009, 12.297752482000044],
              [107.20290421400006, 12.298844462000067],
              [107.20560722100007, 12.289273530000065],
              [107.20726386300001, 12.280865254000071],
              [107.21872062000007, 12.206606315000011],
              [107.219396014, 12.202227116000051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 11, code: "AD01", ten_tinh: "Bình Thuận" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.08263698300004, 9.967407315000097],
              [109.08103882600005, 9.965287026000079],
              [109.07795825900007, 9.965302915000061],
              [109.07798639300009, 9.970670434000082],
              [109.07855378400005, 9.972055699000036],
              [109.08154109600005, 9.972040292000093],
              [109.08274299700001, 9.9698129600001],
              [109.08263698300004, 9.967407315000097],
            ],
          ],
          [
            [
              [109.05355351100009, 9.983733542000016],
              [109.05178578500002, 9.981640341000018],
              [109.05051381200012, 9.981706792000098],
              [109.04985350400001, 9.982911292000017],
              [109.049926353, 9.985313319000076],
              [109.05120940200003, 9.987408974000099],
              [109.05393548899998, 9.987335205000123],
              [109.05441495600004, 9.986311769000087],
              [109.05440941700003, 9.98523071900008],
              [109.05355351100009, 9.983733542000016],
            ],
          ],
          [
            [
              [108.97541188800002, 10.04446607800009],
              [108.97549029800004, 10.040927754000071],
              [108.97325337100004, 10.040938169000045],
              [108.97187983, 10.04235972800007],
              [108.97036615100008, 10.04434800100009],
              [108.9702763060001, 10.045480571],
              [108.97065929500008, 10.045950520000078],
              [108.97380058700003, 10.045935931000114],
              [108.97541188800002, 10.04446607800009],
            ],
          ],
          [
            [
              [108.90547966800003, 10.047172114000078],
              [108.90213814500004, 10.047130413000092],
              [108.90112295000006, 10.048088975],
              [108.90107565800005, 10.050222075000102],
              [108.90249690400006, 10.05145079100007],
              [108.90397207800008, 10.052061853000092],
              [108.90538791000007, 10.052055738000119],
              [108.90691106200006, 10.050702036000112],
              [108.90701740000007, 10.049129967000068],
              [108.90547966800003, 10.047172114000078],
            ],
          ],
          [
            [
              [108.96812754600003, 10.483290835000057],
              [108.96782331900009, 10.483280857000031],
              [108.96745580000005, 10.483392098000087],
              [108.96711398000011, 10.483639362000117],
              [108.96689076200002, 10.483995822000034],
              [108.96681094600008, 10.484347596000097],
              [108.96684361800003, 10.484667056000061],
              [108.96698515200011, 10.48499932500002],
              [108.96724215400005, 10.485281968000047],
              [108.96761129800004, 10.485474202000054],
              [108.96797839800004, 10.485531245000066],
              [108.96825887300007, 10.485484022000067],
              [108.96858886700002, 10.485331189000123],
              [108.96884141300004, 10.48509941500013],
              [108.96898193500003, 10.484877982000016],
              [108.96906557100002, 10.484609055000059],
              [108.96907234300005, 10.484240595000037],
              [108.96894464300006, 10.483862578000112],
              [108.96873800400002, 10.483589906000111],
              [108.96847346400006, 10.483404023],
              [108.96812754600003, 10.483290835000057],
            ],
          ],
          [
            [
              [108.96710299100009, 10.492790996000066],
              [108.965732254, 10.490560748000098],
              [108.9652706780001, 10.48882332900005],
              [108.9651666620001, 10.488127995000072],
              [108.96496421600006, 10.487930118000042],
              [108.96445973600005, 10.487783340000066],
              [108.96365370700006, 10.487787045000106],
              [108.96143616400008, 10.487598413000041],
              [108.96058021700004, 10.487701739000064],
              [108.95997871000002, 10.488350602000105],
              [108.959330064, 10.489695502000094],
              [108.9593356340001, 10.490888306000022],
              [108.95969176300008, 10.491632203000021],
              [108.96039845300001, 10.491927174000091],
              [108.96180905500003, 10.491920711000089],
              [108.96221394000004, 10.49231646500006],
              [108.96226687500007, 10.492862941000068],
              [108.96207258200008, 10.494404576000081],
              [108.96162731200009, 10.496146176000119],
              [108.96183418400005, 10.497288366000122],
              [108.96239231100006, 10.498130737000098],
              [108.9631505780001, 10.498673981000104],
              [108.96385636400004, 10.498770145000067],
              [108.96531504500004, 10.498266416000133],
              [108.96657244000006, 10.497813309000035],
              [108.96757816700006, 10.497411058000059],
              [108.96792942000008, 10.49711122000012],
              [108.96838002700005, 10.496512724000072],
              [108.96827575300004, 10.495767681000066],
              [108.9678148460001, 10.494179349000097],
              [108.96710299100009, 10.492790996000066],
            ],
          ],
          [
            [
              [108.96161729100005, 10.501535927000115],
              [108.96129390100003, 10.501509946000096],
              [108.96095669100002, 10.50157939900007],
              [108.96070439500002, 10.501709203000063],
              [108.96048239900003, 10.501915337000066],
              [108.96029176900007, 10.502245484000115],
              [108.96022065200005, 10.502558737000099],
              [108.96023913200007, 10.502839550000063],
              [108.96039777300005, 10.503228686000019],
              [108.9606667580001, 10.503526585000065],
              [108.96100749000004, 10.503702319000039],
              [108.96138772100007, 10.503762651000057],
              [108.96173286000003, 10.503696832000029],
              [108.96209514100008, 10.503489388],
              [108.96231097000008, 10.503249634000065],
              [108.96245073500009, 10.502938111000047],
              [108.96249309100004, 10.502568942000055],
              [108.96240934200004, 10.502212746000071],
              [108.96220414800003, 10.501879361000109],
              [108.96193572500002, 10.501665796000024],
              [108.96161729100005, 10.501535927000115],
            ],
          ],
          [
            [
              [108.95902345900002, 10.504955362000066],
              [108.95448634500005, 10.504379623000125],
              [108.94995431200006, 10.504897222000052],
              [108.94472239900007, 10.506610733000075],
              [108.93999695800007, 10.508918286000045],
              [108.93728174400012, 10.510123300000094],
              [108.93426736000001, 10.512025451000094],
              [108.9301453730001, 10.514131296000071],
              [108.92937632400007, 10.515499791000058],
              [108.92850112800005, 10.518214254000034],
              [108.92716135200004, 10.523694825000083],
              [108.92509131600009, 10.535513057000044],
              [108.924469765, 10.540499582000072],
              [108.92326799600004, 10.542194853000096],
              [108.92156139800005, 10.543693513000017],
              [108.92066282100005, 10.545586271000039],
              [108.92087196600004, 10.547275345000083],
              [108.92209342800003, 10.549954097000034],
              [108.92385548100006, 10.552013145000048],
              [108.92577668900005, 10.553396450000017],
              [108.92896930800006, 10.554894373000055],
              [108.93038078500004, 10.554987533000125],
              [108.93279833000005, 10.55467855400005],
              [108.93897913400005, 10.554087714000014],
              [108.94105973300006, 10.553945776000083],
              [108.94710559100008, 10.553620335000067],
              [108.95002565600001, 10.553010654000071],
              [108.951935072, 10.551809054000037],
              [108.95444549000005, 10.549809410000053],
              [108.95524576300001, 10.548513414000114],
              [108.95513804300006, 10.547022726000053],
              [108.95442239600001, 10.544838934000021],
              [108.95400864200005, 10.542554370000088],
              [108.95399802300007, 10.540267972],
              [108.95509690700004, 10.538175358000055],
              [108.956456401, 10.535992760000033],
              [108.95684711800007, 10.535284499000051],
              [108.95760931100008, 10.525837185000062],
              [108.96000371800004, 10.52065703800009],
              [108.96141032200008, 10.51975593000005],
              [108.96372664300006, 10.519447083000076],
              [108.96543740400003, 10.518942180000044],
              [108.966535122, 10.516650763000078],
              [108.96650799700006, 10.510885329000084],
              [108.96569299100004, 10.509000372000086],
              [108.96376912200002, 10.507021116000102],
              [108.95902345900002, 10.504955362000066],
            ],
          ],
          [
            [
              [108.95665466000005, 10.554415128000104],
              [108.95632173600004, 10.554327473000045],
              [108.95593850600004, 10.554355470000052],
              [108.95560063700006, 10.554490493000033],
              [108.95532897200005, 10.55473078700002],
              [108.95512843300007, 10.555077922000054],
              [108.95506813300005, 10.555473290000082],
              [108.95513059600007, 10.555828505000074],
              [108.95535123200003, 10.556187134000082],
              [108.95564699400008, 10.556428968000107],
              [108.95597535400006, 10.556551],
              [108.95623228800009, 10.556576748000078],
              [108.95651218900008, 10.556527252000068],
              [108.956844055, 10.556378355000094],
              [108.95708205500006, 10.556160060000062],
              [108.957264772, 10.555848052],
              [108.95733787500009, 10.555537148000036],
              [108.95732239700006, 10.555247885000083],
              [108.95722973700003, 10.554960878000051],
              [108.95701729000004, 10.55466829700009],
              [108.95665466000005, 10.554415128000104],
            ],
          ],
          [
            [
              [108.95104128100012, 10.554735998000096],
              [108.95067510400001, 10.554620865000073],
              [108.95035069300009, 10.554629216000054],
              [108.95002284000005, 10.554733925000013],
              [108.94971599200007, 10.554962816000033],
              [108.94949813900007, 10.555276042000104],
              [108.94940547000004, 10.555624780000093],
              [108.94941961000005, 10.555965356000028],
              [108.94955421700008, 10.556300098000102],
              [108.94981110700012, 10.556609006000082],
              [108.95018907900004, 10.556807987000115],
              [108.95056526400008, 10.556868737000078],
              [108.95082592100006, 10.556825777000077],
              [108.95112605800003, 10.556703742000105],
              [108.95136130300004, 10.556512465000088],
              [108.95155000800003, 10.556251225000056],
              [108.95164268500005, 10.556006315000042],
              [108.95167088200007, 10.555694558000093],
              [108.95162374700008, 10.555402383000077],
              [108.95151397100005, 10.555164515000024],
              [108.95134073000003, 10.554941326000032],
              [108.95104128100012, 10.554735998000096],
            ],
          ],
          [
            [
              [108.95120684400004, 10.558636701000077],
              [108.95077382400009, 10.558540429000105],
              [108.95039425400005, 10.558599953000057],
              [108.95008669000001, 10.558753488000059],
              [108.94984399100008, 10.558995398000091],
              [108.94968632200001, 10.55927602400004],
              [108.94962182100008, 10.559641183000055],
              [108.94966593700009, 10.559988814000041],
              [108.9498271620001, 10.560312201000064],
              [108.95013241600006, 10.56060317200007],
              [108.95048561200005, 10.56076680600002],
              [108.95078587900012, 10.560794763000024],
              [108.95106737600005, 10.56075082800003],
              [108.95131061800002, 10.560648582000097],
              [108.9515949230001, 10.560422984000082],
              [108.95180991700003, 10.560084404000046],
              [108.951892249, 10.559717484000062],
              [108.95184317500012, 10.559349942000027],
              [108.95159858000004, 10.558915661000066],
              [108.95120684400004, 10.558636701000077],
            ],
          ],
          [
            [
              [108.8706234320001, 10.620292016000031],
              [108.87027844600007, 10.62026684800008],
              [108.87001927200004, 10.62028258400005],
              [108.86973590400004, 10.620335853000105],
              [108.86940783100006, 10.620444387000115],
              [108.86905224600004, 10.620632629000058],
              [108.86871326700006, 10.620897466000121],
              [108.86848163399999, 10.621151630000012],
              [108.8682810440001, 10.621464626000064],
              [108.86813947200001, 10.621776846000051],
              [108.86805748300007, 10.622079967000085],
              [108.86801414200004, 10.622555231000062],
              [108.868048007, 10.622923326000054],
              [108.868115684, 10.623200707000089],
              [108.868207969, 10.623440816000061],
              [108.86837328500009, 10.623741423000064],
              [108.86858032000008, 10.624015580000011],
              [108.86885172000007, 10.624271038000098],
              [108.86918451500006, 10.624496413000093],
              [108.86952640400001, 10.624647707000088],
              [108.87003078300009, 10.624764144000041],
              [108.87037629800008, 10.624781505000056],
              [108.87063350700005, 10.62475769300004],
              [108.87088884200003, 10.62470330100007],
              [108.8711354210001, 10.624622766000067],
              [108.87139572900006, 10.624499844000111],
              [108.87170729600007, 10.624294040000047],
              [108.87196263200006, 10.624063155000039],
              [108.87216171200001, 10.623818859000076],
              [108.87232576200009, 10.623550288000059],
              [108.87243095000008, 10.623315425000078],
              [108.87251925700011, 10.623014049000099],
              [108.87257107900004, 10.622647001000106],
              [108.87256695100007, 10.622333233000079],
              [108.87251755400005, 10.622023266000012],
              [108.87242645500007, 10.6217226890001],
              [108.87230720500006, 10.621462869000096],
              [108.872120658, 10.621174681000054],
              [108.87187324600004, 10.620896390000032],
              [108.87165376700008, 10.620711241000079],
              [108.87143650800004, 10.620570559000074],
              [108.87117824400009, 10.620443488000129],
              [108.87093374800003, 10.620356983000077],
              [108.8706234320001, 10.620292016000031],
            ],
          ],
          [
            [
              [107.80085817300008, 10.650476546000041],
              [107.80051327200005, 10.650449619000122],
              [107.800253981, 10.650464034000072],
              [107.79997029800006, 10.650515861000097],
              [107.79964161500006, 10.650622725000057],
              [107.79928500200003, 10.650809160000028],
              [107.79894459800002, 10.651072279000038],
              [107.79871161000003, 10.651325271000099],
              [107.79850936300008, 10.651637254000066],
              [107.79836614500003, 10.651948762000083],
              [107.79828256600004, 10.652251476000083],
              [107.79823674300007, 10.652726535000044],
              [107.79826869500005, 10.653094815000051],
              [107.79833493600003, 10.653372550000116],
              [107.79842598200001, 10.653613137000127],
              [107.79858975400006, 10.653914598000112],
              [107.79879538900011, 10.654189819000058],
              [107.79906549400008, 10.654446669000082],
              [107.79939715900008, 10.654673749000041],
              [107.79973830500006, 10.654826791000092],
              [107.80024214600009, 10.65494580200008],
              [107.80058761599999, 10.654964926000064],
              [107.80084498400005, 10.654942424000067],
              [107.80110063600007, 10.654889332000026],
              [107.80134766800008, 10.654810051000128],
              [107.80160865100001, 10.654688453000103],
              [107.80192133200003, 10.654484230000081],
              [107.80217790500004, 10.654254639],
              [107.802378284, 10.654011350000035],
              [107.80254375600001, 10.653743606000086],
              [107.80265018100002, 10.653509272000059],
              [107.80274007, 10.653208336000118],
              [107.8027938110001, 10.652841539000056],
              [107.80279131700007, 10.652527740000027],
              [107.80274352900003, 10.652217510000115],
              [107.80265398299998, 10.651916460000075],
              [107.80253607100009, 10.651656023000021],
              [107.802351, 10.651366874000077],
              [107.80210500600003, 10.651087312000039],
              [107.80188646199998, 10.650901038000081],
              [107.8016699070001, 10.650759244000072],
              [107.8014122700001, 10.650630852000107],
              [107.80116819200006, 10.650543097000041],
              [107.80085817300008, 10.650476546000041],
            ],
          ],
          [
            [
              [107.99193409200011, 10.694461052000024],
              [107.99023012400008, 10.694260970000078],
              [107.98902774300007, 10.694643299000093],
              [107.98821322800009, 10.694949160000087],
              [107.98782532600005, 10.695713916000036],
              [107.98794163600009, 10.696478702000077],
              [107.98852339200008, 10.697167031],
              [107.98926029900012, 10.697740660000042],
              [107.99011358300002, 10.697970135000022],
              [107.99154868100001, 10.697893714000093],
              [107.99259591800006, 10.697740804000054],
              [107.99310016100004, 10.697281948000102],
              [107.99337168300003, 10.696478944000097],
              [107.99337171500004, 10.695484733000081],
              [107.99286493400004, 10.694728749000058],
              [107.99193409200011, 10.694461052000024],
            ],
          ],
          [
            [
              [108.30122167700003, 10.910221776000116],
              [108.30031066400007, 10.909147920000082],
              [108.29967406700007, 10.909148829000069],
              [108.29849206500005, 10.909329705000024],
              [108.29685625900007, 10.910138394000056],
              [108.29649455200007, 10.911572445000049],
              [108.29695083100007, 10.912646955000048],
              [108.29822483600006, 10.913182724000057],
              [108.29931643300004, 10.9133603650001],
              [108.30013428900004, 10.912911222000064],
              [108.3012235190001, 10.911476119000072],
              [108.30122167700003, 10.910221776000116],
            ],
          ],
          [
            [
              [108.8323042430001, 11.230113723000022],
              [108.83231092100002, 11.229832897000056],
              [108.83229399700004, 11.229551534000047],
              [108.83225963700004, 11.229272449000048],
              [108.83220318800008, 11.228928073000064],
              [108.83215105700003, 11.228653594],
              [108.83208613800008, 11.228381944000086],
              [108.83200132300007, 11.228115557000072],
              [108.83182711300005, 11.2277477060001],
              [108.83161240700005, 11.227433788000061],
              [108.83141969600008, 11.227222733000096],
              [108.83121511800003, 11.227029365000083],
              [108.83084046800003, 11.226692481],
              [108.83054103700005, 11.226382470000116],
              [108.83034765800002, 11.226139414000079],
              [108.83012700100006, 11.225829359],
              [108.82983301500008, 11.225397637000077],
              [108.829670967, 11.22516854600004],
              [108.82949957600002, 11.224943976000111],
              [108.82928975700007, 11.224700477000036],
              [108.829030308, 11.224449431000048],
              [108.828716904, 11.224211569000085],
              [108.82847191200008, 11.224068086000026],
              [108.82821505100006, 11.223948356000067],
              [108.82794807900008, 11.2238521460001],
              [108.82767288000005, 11.223778883000078],
              [108.82739399300007, 11.223720165000104],
              [108.82711492800007, 11.223667779000033],
              [108.82683569400005, 11.223621719000041],
              [108.82655617500002, 11.22358234400007],
              [108.82627624600005, 11.223550011000059],
              [108.82599578700011, 11.223525075000039],
              [108.82571468700003, 11.223507900000039],
              [108.82539756400008, 11.22349801300002],
              [108.82504477700012, 11.2234925380001],
              [108.82465665900006, 11.2234893170001],
              [108.82426854100001, 11.223488298000104],
              [108.82395048600007, 11.223487033000088],
              [108.82362801600004, 11.22347058800006],
              [108.82312113100002, 11.223429570000041],
              [108.82279712400003, 11.223413928000079],
              [108.82251391300005, 11.223421321000069],
              [108.82223782600003, 11.22345902400008],
              [108.82197136800002, 11.223536927000042],
              [108.819940151, 11.224426783000039],
              [108.819384294, 11.225471431000054],
              [108.81934986600001, 11.225844555000018],
              [108.81942139800006, 11.226130735000071],
              [108.81953229500009, 11.22641064900005],
              [108.81965325500006, 11.226673598000046],
              [108.81977838700001, 11.226926292000082],
              [108.81990937300003, 11.227171786000055],
              [108.82004727700007, 11.227411188000056],
              [108.82019317800005, 11.22764560400009],
              [108.820348133, 11.227876136000068],
              [108.82051322900007, 11.228103891000083],
              [108.82068944700009, 11.228329918000044],
              [108.82087294200008, 11.22855123900006],
              [108.82105997300002, 11.228760900000088],
              [108.82125655900002, 11.228956378000097],
              [108.82146952100005, 11.229135607],
              [108.82170565400006, 11.229296540000087],
              [108.82199531200004, 11.229453498000018],
              [108.82235622700006, 11.229616755000025],
              [108.82262212600003, 11.229714819000057],
              [108.82289099900008, 11.229797009000018],
              [108.82316299000011, 11.229864156000067],
              [108.82343825600006, 11.229917088000095],
              [108.82375210300009, 11.229960489000044],
              [108.82413914900005, 11.229999749000095],
              [108.82449128800006, 11.230030259000044],
              [108.82477304500001, 11.230049590000045],
              [108.82505487200007, 11.230063042],
              [108.82544254400007, 11.230069005000052],
              [108.82588327300003, 11.230060601000067],
              [108.82628880100002, 11.230047819000076],
              [108.82657091300008, 11.230037459000107],
              [108.82699409000001, 11.230021181],
              [108.82727620100009, 11.230010612000017],
              [108.82772291900001, 11.229994699000121],
              [108.82819377500007, 11.229970089000039],
              [108.82866619599999, 11.230008062000072],
              [108.82901587300006, 11.230171643000107],
              [108.82920112700002, 11.230368808000078],
              [108.82937569500007, 11.230640617000097],
              [108.82955510000008, 11.230969708000059],
              [108.82968762000004, 11.231197487000076],
              [108.82986140100012, 11.231435612000061],
              [108.83006221500003, 11.23165746400012],
              [108.83034870500002, 11.231982305000086],
              [108.83068785700002, 11.232282864000085],
              [108.83096897100009, 11.232383075000117],
              [108.83119571300003, 11.232259298000089],
              [108.83140588800009, 11.231979975000071],
              [108.83161688300007, 11.231666218000045],
              [108.83180004100002, 11.231400743000018],
              [108.83196325100003, 11.23115828600003],
              [108.8320951740001, 11.230914936000056],
              [108.8321969580001, 11.230660951000091],
              [108.83226781000009, 11.230391208000066],
              [108.8323042430001, 11.230113723000022],
            ],
          ],
          [
            [
              [108.62976683300005, 11.551362425000098],
              [108.63646976500007, 11.551115038000058],
              [108.64225491800009, 11.551273029000074],
              [108.64430702500006, 11.544047260000044],
              [108.64728757100002, 11.536312586000095],
              [108.65141055100001, 11.524484487000029],
              [108.65530629400003, 11.514020475000029],
              [108.65943494900004, 11.504237438000063],
              [108.66072461300008, 11.502023500000023],
              [108.6628824220001, 11.498319143000034],
              [108.66656079600007, 11.492400087000119],
              [108.66931905500012, 11.487847194],
              [108.67207570800011, 11.482839886000116],
              [108.67552545400008, 11.477830342000049],
              [108.67990158200006, 11.473499393000084],
              [108.68474345600002, 11.470302872000014],
              [108.69074203700004, 11.467556860000093],
              [108.69812838000011, 11.465260487000071],
              [108.702979134, 11.46478994100004],
              [108.70898753500003, 11.464996981000029],
              [108.71523177100003, 11.466566257000109],
              [108.721248239, 11.469044936000026],
              [108.72726404600012, 11.47129624800005],
              [108.73512855800006, 11.473540945000058],
              [108.7411372490001, 11.47374707000006],
              [108.74691405200006, 11.473726648000058],
              [108.75361262700008, 11.47302116600004],
              [108.758691148, 11.471639708000046],
              [108.75914149200004, 11.468457264000074],
              [108.75911706600007, 11.461868547000085],
              [108.75839695900008, 11.454600800000046],
              [108.7572013810001, 11.443699723000032],
              [108.756463791, 11.431661203000052],
              [108.75573550100005, 11.42212188700009],
              [108.75478883600006, 11.415991250000078],
              [108.75546929100005, 11.412581030000089],
              [108.75984907700006, 11.410066240000026],
              [108.77436514300004, 11.400244470000052],
              [108.78104477000009, 11.395221821000055],
              [108.78588408600011, 11.392250471000052],
              [108.794181741, 11.38767576000008],
              [108.80156226600005, 11.384921651000111],
              [108.81032930200007, 11.38238912900009],
              [108.81910247100005, 11.381446455000075],
              [108.82464295300008, 11.380743291000071],
              [108.83248843500004, 11.378894995000087],
              [108.83664487300005, 11.378651329000087],
              [108.84057126200003, 11.378635678000125],
              [108.84241618200009, 11.377946781000057],
              [108.84310158800005, 11.376126633000093],
              [108.84354947500006, 11.372717243000084],
              [108.8442283090001, 11.369306931],
              [108.84583372900006, 11.366574426000041],
              [108.84928385900007, 11.363152966000099],
              [108.85710563300003, 11.355851832000051],
              [108.86703214900005, 11.354902485000105],
              [108.87026527300004, 11.354889136000066],
              [108.874400807, 11.349874318],
              [108.87391859600007, 11.345105846000079],
              [108.87320839200002, 11.341019843000042],
              [108.87204411100005, 11.33875304300007],
              [108.86903734200006, 11.337629669000114],
              [108.8655716550001, 11.337189633000031],
              [108.86449019700005, 11.333627279000067],
              [108.86164731200002, 11.333604592000055],
              [108.86134909300003, 11.333586317000053],
              [108.86086420700006, 11.333592020000081],
              [108.86048166800003, 11.333626073000056],
              [108.860089461, 11.33370841400003],
              [108.85425679800004, 11.335024614000043],
              [108.84963985600004, 11.334573668000035],
              [108.84414167100005, 11.333320705000103],
              [108.84123731700006, 11.331427087000099],
              [108.83949093000008, 11.329354933000067],
              [108.83863075800005, 11.327625412000051],
              [108.83800790000004, 11.32591729500003],
              [108.83767016900006, 11.325408817000058],
              [108.83705439000001, 11.324714295000058],
              [108.83648368800004, 11.324219441000059],
              [108.83622104500007, 11.324048856000067],
              [108.83584892500001, 11.323984789000095],
              [108.82545455900001, 11.320964466000037],
              [108.82363968800003, 11.320663778000027],
              [108.80410426900005, 11.316556949000043],
              [108.79898208, 11.315942435000091],
              [108.79452193900009, 11.315739983000038],
              [108.79258055300004, 11.3156280200001],
              [108.79156738400005, 11.315645466000122],
              [108.79088380900006, 11.315615350000117],
              [108.7899986080001, 11.315458645000119],
              [108.78868462000001, 11.315073607000057],
              [108.78666367000004, 11.314408117000061],
              [108.78593195800008, 11.314052440000031],
              [108.78505060600007, 11.313395463000029],
              [108.78423343800004, 11.31262641000008],
              [108.78307853700005, 11.311317007000097],
              [108.78162152000003, 11.308410217000107],
              [108.77542924000007, 11.290110091000114],
              [108.77513393300006, 11.289178795000064],
              [108.773823609, 11.288916087000088],
              [108.76983897200009, 11.2891405860001],
              [108.76878211200004, 11.28913568800005],
              [108.76230041300002, 11.287362014000022],
              [108.76192069800004, 11.287237720000041],
              [108.76142510000008, 11.28705640900005],
              [108.76093745500005, 11.286872535000024],
              [108.76050270200005, 11.286686461000082],
              [108.76012093800009, 11.286485119000121],
              [108.75983357100002, 11.286383706000079],
              [108.75820500600001, 11.285653858000087],
              [108.75778467700005, 11.285288401000047],
              [108.75675330900006, 11.284590875000065],
              [108.75571603000003, 11.283746989000052],
              [108.75505787200002, 11.283128923000072],
              [108.75437236700006, 11.282399180000089],
              [108.753793026, 11.281621464000045],
              [108.75322080100008, 11.280836840000051],
              [108.75254926600003, 11.279671765000019],
              [108.75213194700005, 11.278466809000063],
              [108.7515615930001, 11.27698898600009],
              [108.75145092000001, 11.276709471000055],
              [108.75098559900006, 11.275329989000047],
              [108.75080635900007, 11.274799153000091],
              [108.75068267900005, 11.274380201000065],
              [108.7505008590001, 11.273727418000055],
              [108.75036404000005, 11.273182957000037],
              [108.75013224000006, 11.272157067000064],
              [108.74931517800005, 11.267490029],
              [108.74926787600005, 11.267224924000095],
              [108.74925587800003, 11.266953119000076],
              [108.74923816500007, 11.266057744000067],
              [108.74924325600006, 11.265402891000063],
              [108.7485235880001, 11.255810229000039],
              [108.74811480400002, 11.25453296200005],
              [108.74798257, 11.254172798000093],
              [108.74344338000004, 11.233614345000118],
              [108.74334748900002, 11.233251338000104],
              [108.74316563499998, 11.232605531000049],
              [108.74302217200001, 11.232012258000077],
              [108.74286476500005, 11.231397981000056],
              [108.74277903200006, 11.231087309000108],
              [108.74248771000002, 11.230465046000047],
              [108.74110272500009, 11.227787340000093],
              [108.73751596300006, 11.22063965200004],
              [108.73723601799999, 11.220534150000015],
              [108.736846552, 11.22018057700007],
              [108.73614145000002, 11.21958469100006],
              [108.73565961900003, 11.219101244000028],
              [108.73489110300004, 11.21825575800006],
              [108.73407655100006, 11.217326881000121],
              [108.73354055900003, 11.216603116000011],
              [108.73319017000009, 11.216028386000064],
              [108.73291550600001, 11.215325060000046],
              [108.73239253499999, 11.214130823000088],
              [108.73186260100002, 11.212632046000069],
              [108.73105262400006, 11.20992242100005],
              [108.73069092900006, 11.208406462000118],
              [108.73016081400007, 11.205735874000043],
              [108.73003584900005, 11.204996776000085],
              [108.72987683700003, 11.203832981000071],
              [108.72973559200007, 11.201589788000097],
              [108.72960130400006, 11.199651128000097],
              [108.72949596600004, 11.19759275100005],
              [108.72953892600005, 11.196873380000037],
              [108.72942202300005, 11.195100944000023],
              [108.72931990100004, 11.193826861000032],
              [108.72927788800003, 11.193226801000032],
              [108.72915277900007, 11.192506154000091],
              [108.72834731300004, 11.189224509000082],
              [108.72767910400009, 11.186303752000091],
              [108.7274591890001, 11.185766918000082],
              [108.72723813100001, 11.185377711000031],
              [108.72694235800006, 11.184987940000022],
              [108.72644177300003, 11.184522790000083],
              [108.72568104600006, 11.183889582000093],
              [108.72390742700003, 11.182630467000097],
              [108.72316451500002, 11.182108099000041],
              [108.7220769420001, 11.181481629000077],
              [108.72138919700004, 11.18107041000007],
              [108.71835870700002, 11.179349581000062],
              [108.7172432250001, 11.178713652000026],
              [108.71683386500007, 11.178525992000042],
              [108.71631216100008, 11.17837438700011],
              [108.71408195800011, 11.177154561000078],
              [108.71212909700009, 11.175364908000061],
              [108.71053669000005, 11.172256763000108],
              [108.70816177500009, 11.168743941000068],
              [108.70801559400009, 11.168336848000047],
              [108.70775721800003, 11.16794734000006],
              [108.70733152200006, 11.16746428900006],
              [108.70664527800004, 11.166868530000061],
              [108.70605152900002, 11.166384188000043],
              [108.70476039000005, 11.165744545000054],
              [108.70438795400005, 11.165612506000054],
              [108.70337688900005, 11.165310994000064],
              [108.70291796100001, 11.165269975000045],
              [108.70252824800009, 11.165383545000028],
              [108.70209295300005, 11.165689229000096],
              [108.70191019200003, 11.166004658000082],
              [108.70185280700008, 11.166544158000045],
              [108.70104409000004, 11.172631997000069],
              [108.700818117, 11.17382639900004],
              [108.70073955400007, 11.175073021000069],
              [108.70060840600006, 11.17701156800006],
              [108.69999315200005, 11.179872419000068],
              [108.69915638200008, 11.180871686000058],
              [108.69511676700006, 11.183605027000086],
              [108.69419329900003, 11.184130435000037],
              [108.69315950100004, 11.1845766710001],
              [108.69145042500003, 11.185229139000084],
              [108.69005095900005, 11.18581351600003],
              [108.68846069700001, 11.186474704000114],
              [108.68706134500005, 11.187043391000094],
              [108.68586093200003, 11.187511813000038],
              [108.68400120800007, 11.188170891000125],
              [108.68051297400011, 11.189310693000019],
              [108.67893939000002, 11.189862322000097],
              [108.67641246200003, 11.190704110000114],
              [108.67503767100006, 11.191171125000031],
              [108.67292506500004, 11.191734179000077],
              [108.66988319200007, 11.192548411000034],
              [108.668792696, 11.19253966500011],
              [108.66741153200012, 11.192810801000073],
              [108.66326537900008, 11.192834230000058],
              [108.64789441300006, 11.192704419000064],
              [108.64265720200007, 11.192501175000034],
              [108.6341836890001, 11.191171548000066],
              [108.63326571400009, 11.191007664],
              [108.63254567400001, 11.190868835000066],
              [108.632023792, 11.190723738000051],
              [108.63128093400009, 11.190467263000057],
              [108.63027016900003, 11.190020712000058],
              [108.62902321100006, 11.189384348000074],
              [108.62779196900007, 11.188763773000064],
              [108.62663173500007, 11.188182895000052],
              [108.62514783000005, 11.187450666000041],
              [108.62386957400007, 11.186767046000055],
              [108.623144247, 11.186307091000101],
              [108.62220617200011, 11.185696650000041],
              [108.62171754600008, 11.18536386500004],
              [108.62119748500004, 11.184999512000092],
              [108.61865079400009, 11.179958169000058],
              [108.61843237100005, 11.179533578000099],
              [108.61650212500004, 11.177236956000057],
              [108.61628133900007, 11.177102071000087],
              [108.61596952900003, 11.176954712000063],
              [108.61559006300007, 11.176842057000067],
              [108.61510708600008, 11.176791231000037],
              [108.61463930800009, 11.176818823000099],
              [108.61426618200004, 11.176901994000053],
              [108.61391604900003, 11.177079304000069],
              [108.61367672300003, 11.177273164000033],
              [108.61346002800005, 11.17760816800004],
              [108.61336560300005, 11.178010699000128],
              [108.61333459700006, 11.178409825000111],
              [108.61345857100009, 11.179722484000097],
              [108.61371860900003, 11.18183889200006],
              [108.61370039000003, 11.182128485000041],
              [108.61363846300006, 11.182433393000045],
              [108.61343379800009, 11.182748909000038],
              [108.6131548490001, 11.182942451000065],
              [108.61268536600008, 11.183177548000065],
              [108.61211258000002, 11.183447054000071],
              [108.61144406300004, 11.18379410700001],
              [108.61091934500006, 11.184001338000103],
              [108.610530086, 11.184115693000061],
              [108.61001792500008, 11.18424080300011],
              [108.60912056200009, 11.184468552000036],
              [108.60835837200008, 11.184642572000046],
              [108.60795359900007, 11.184717640000128],
              [108.60731502500008, 11.184794758000026],
              [108.60627553900005, 11.184958720000092],
              [108.605125249, 11.185106112000119],
              [108.60458189500008, 11.185168325000026],
              [108.60173157700005, 11.185337328000065],
              [108.60025666000004, 11.185450790000038],
              [108.59931310800005, 11.185513689000091],
              [108.59862731000007, 11.18555124400009],
              [108.59806450300003, 11.18557020800006],
              [108.59558734200007, 11.185656020000055],
              [108.59462033700008, 11.185679556000048],
              [108.59366146100005, 11.185679679000058],
              [108.59300368700008, 11.18568221400009],
              [108.59268305700002, 11.185644387000069],
              [108.59185186700007, 11.185535891000063],
              [108.58974998900007, 11.185280117000039],
              [108.58879228500005, 11.18513927400002],
              [108.58810399400004, 11.185000603000093],
              [108.58772446900008, 11.184895737000121],
              [108.58681187000006, 11.184571236000094],
              [108.58406583000006, 11.183636779000048],
              [108.58318867300004, 11.183339953000091],
              [108.58151824400002, 11.182664754000092],
              [108.58112334000003, 11.182504948000103],
              [108.58055528900012, 11.18220278900006],
              [108.57883921400006, 11.181300118000069],
              [108.5779004180001, 11.180791352000073],
              [108.57637428200003, 11.179921525000045],
              [108.5757986950001, 11.179572308000017],
              [108.57448625600004, 11.17873554200006],
              [108.57417918600004, 11.178498132000046],
              [108.57357270000004, 11.178054697000038],
              [108.57328146600001, 11.177817415000034],
              [108.57254629800012, 11.177126269000047],
              [108.57212166900007, 11.176731292000051],
              [108.57179161100002, 11.176399727000062],
              [108.5708531670001, 11.175378041000082],
              [108.56948460200002, 11.17367159700011],
              [108.56902219600003, 11.173057046000066],
              [108.56813248700004, 11.171894807000092],
              [108.56778770500003, 11.171429998000091],
              [108.56728909200007, 11.170684018000097],
              [108.56585478000005, 11.168512887000047],
              [108.56510122600005, 11.167499253000111],
              [108.56439966100001, 11.16671274400005],
              [108.563592247, 11.166032613000086],
              [108.56086410600004, 11.16466509200009],
              [108.55968455400006, 11.164073815000018],
              [108.55669420700008, 11.162926945000072],
              [108.55256852100004, 11.162208497000046],
              [108.55213267400005, 11.162131008000118],
              [108.55102891000011, 11.161967938000126],
              [108.53808018700005, 11.158974375000062],
              [108.53750936100008, 11.158682354000058],
              [108.53694407200001, 11.158388255000061],
              [108.53638524600005, 11.158093361000086],
              [108.52949224100011, 11.157176263000055],
              [108.52882362600003, 11.15710512700012],
              [108.52820147600008, 11.156913129000133],
              [108.52760309900003, 11.156693001000038],
              [108.52700996600008, 11.15645675500012],
              [108.526422913, 11.156201891000082],
              [108.52584279400006, 11.155925886000071],
              [108.52527030000002, 11.155626865000022],
              [108.52470151300002, 11.155322887000095],
              [108.52413559700001, 11.155020085000087],
              [108.52357425400002, 11.154714160000088],
              [108.5230191740001, 11.154400821000097],
              [108.52247207000005, 11.154075751000024],
              [108.52193465199998, 11.153734669000047],
              [108.52140862100006, 11.153373248000074],
              [108.5208947240001, 11.152990139000083],
              [108.52038856500005, 11.152598809000034],
              [108.51988851100006, 11.152202083000072],
              [108.51939360200006, 11.151800613000072],
              [108.51890285100004, 11.151395019000086],
              [108.51841529800002, 11.15098596100008],
              [108.51792995600002, 11.150574076000018],
              [108.51744585600002, 11.150160016000131],
              [108.51696203700004, 11.149744400000115],
              [108.51019287300005, 11.142231054000094],
              [108.50985182400007, 11.141695306000086],
              [108.50949272300002, 11.141172144000054],
              [108.50911523300005, 11.140661693000096],
              [108.50873126400008, 11.140155626000057],
              [108.50834341200004, 11.139652072000048],
              [108.50795395200007, 11.139149381000022],
              [108.50756518700005, 11.138645910000021],
              [108.50717939500002, 11.138140008000084],
              [108.50679885900007, 11.137630034000077],
              [108.50642578300001, 11.137114426000048],
              [108.50605960100009, 11.136594171000079],
              [108.50569930900004, 11.136070204000069],
              [108.50534472300009, 11.135542705000121],
              [108.50499561500007, 11.135011784000069],
              [108.50465178400005, 11.1344775860001],
              [108.50431301, 11.133940238000049],
              [108.50397909300008, 11.133399904000017],
              [108.50364983000001, 11.132856710000015],
              [108.50332499500004, 11.132310802000054],
              [108.50300439400007, 11.131762297000057],
              [108.50268876000007, 11.131210896000086],
              [108.50239296100003, 11.130649311000072],
              [108.50049653800005, 11.126519791000076],
              [108.50000948600008, 11.125459219000076],
              [108.499678859, 11.124917421000101],
              [108.49933730800007, 11.124382014000032],
              [108.497583585, 11.121732690000028],
              [108.49724282200005, 11.121196693000089],
              [108.49690846900009, 11.120656937000104],
              [108.49657694400003, 11.120115582000077],
              [108.49364820900009, 11.114488260000053],
              [108.49229820300012, 11.11093067700004],
              [108.49208020000002, 11.110335349000076],
              [108.49185654600008, 11.109742394000042],
              [108.49162435100004, 11.109152931000031],
              [108.49138078000006, 11.108568097000047],
              [108.49112491700002, 11.107988274000123],
              [108.49086122700005, 11.107411698000112],
              [108.49004008500006, 11.105694436000045],
              [108.48976160300002, 11.105123953000094],
              [108.4894833700001, 11.104553360000045],
              [108.48920695800005, 11.103981968000108],
              [108.48893394000002, 11.103409140000052],
              [108.48866590900005, 11.102834202000087],
              [108.488151058, 11.10167537100004],
              [108.48790664500011, 11.101090470000051],
              [108.48766912800008, 11.100502640000085],
              [108.48743625900009, 11.099912857000048],
              [108.48720578800003, 11.099322091000106],
              [108.48697546400007, 11.098731272000071],
              [108.48674304700002, 11.098141379000113],
              [108.48650630600007, 11.097553369000044],
              [108.486263073, 11.096968122000115],
              [108.48601298200001, 11.096385649000061],
              [108.48575721300008, 11.095805459000106],
              [108.48549701900004, 11.09522702400003],
              [108.4852336440001, 11.094649863000059],
              [108.484968349, 11.094073447000062],
              [108.48470235200001, 11.093497296000086],
              [108.48443692299999, 11.092920890000089],
              [108.48417328800012, 11.09234373000003],
              [108.48391270900005, 11.091765315000133],
              [108.48365643000008, 11.091185145000091],
              [108.48340569500002, 11.090602693000038],
              [108.48248862800008, 11.088239473000082],
              [108.482089462, 11.087036534000026],
              [108.48171144800008, 11.085826135000064],
              [108.48152510400003, 11.085220003000094],
              [108.48133770900007, 11.084614244000067],
              [108.48114715100012, 11.084009587000041],
              [108.48008309400009, 11.081025575000057],
              [108.47985298500006, 11.08043487100012],
              [108.47962287800003, 11.079844140000093],
              [108.47939425300002, 11.079252800000104],
              [108.47916605100006, 11.078661360000106],
              [108.47893685300002, 11.078070383000059],
              [108.47870740000005, 11.077479579000025],
              [108.47847839800001, 11.076888630000068],
              [108.47825054800008, 11.076297271000078],
              [108.47802457500003, 11.075705167000114],
              [108.47780118200002, 11.075112063000068],
              [108.47758109200002, 11.07451763100009],
              [108.47736501800009, 11.073921581000066],
              [108.47715365400009, 11.073323623000062],
              [108.47694782500002, 11.072723427000051],
              [108.4767597790001, 11.072118256000072],
              [108.47659446700001, 11.071507183000049],
              [108.47644729600009, 11.070891377000079],
              [108.47631366400005, 11.070272060000084],
              [108.47618897100003, 11.069650408000074],
              [108.47606862400002, 11.069027608000058],
              [108.47594803000008, 11.068404872000047],
              [108.47582259800001, 11.067783396000051],
              [108.47568773600004, 11.067164355000079],
              [108.47553884100006, 11.066548945000052],
              [108.47537710400009, 11.065936684000109],
              [108.47520818700006, 11.065326030000032],
              [108.47503529000005, 11.064716245000026],
              [108.47486162700008, 11.064106588000032],
              [108.47469036100003, 11.063496357000078],
              [108.47452472200008, 11.062884812000016],
              [108.47436789400008, 11.062271222000051],
              [108.47422306000009, 11.061654857000034],
              [108.47409092400008, 11.061035570000042],
              [108.47396079200006, 11.060415845000103],
              [108.47383097200006, 11.059796048000068],
              [108.47370135500009, 11.059176215000031],
              [108.47357187500003, 11.05855635400008],
              [108.47344241400006, 11.057936494000041],
              [108.4733129090001, 11.057316644000091],
              [108.47318324800003, 11.056696813000054],
              [108.47305969400001, 11.056108612000058],
              [108.47286925200007, 11.054349773000103],
              [108.47315917400007, 11.052887607000093],
              [108.47375466000005, 11.051767332000038],
              [108.47446988800007, 11.050705679000126],
              [108.4749606500001, 11.049621822000065],
              [108.4747877720001, 11.048863949000063],
              [108.47372012400004, 11.048366506000116],
              [108.47287491600009, 11.048181061000049],
              [108.471280737, 11.047832621000085],
              [108.46948302400003, 11.047415378000023],
              [108.46824207600011, 11.047245083000055],
              [108.46672878600005, 11.04665688700006],
              [108.46531957300006, 11.045676675000047],
              [108.46477859900007, 11.045339893000071],
              [108.46423494500003, 11.045006867000049],
              [108.46368876400004, 11.044676938000107],
              [108.46314013800003, 11.044349417000097],
              [108.4625892020001, 11.044023654000055],
              [108.46203605500008, 11.043698971000103],
              [108.46148065600005, 11.043377383000051],
              [108.46092207000001, 11.043066063000049],
              [108.46035930900007, 11.042765337000036],
              [108.45979131900006, 11.042475118000093],
              [108.45921710400007, 11.042195272000031],
              [108.45863555500009, 11.041925883000124],
              [108.45801098900002, 11.041732738000041],
              [108.45735009400005, 11.041676460000092],
              [108.45670361900002, 11.041766260000061],
              [108.45610444200001, 11.041983884000038],
              [108.45552130800007, 11.042242924000066],
              [108.45494927400009, 11.042531060000014],
              [108.454386836, 11.042842409000018],
              [108.45383859800003, 11.043177848000061],
              [108.45330793500008, 11.043535949000026],
              [108.45278032900003, 11.043894837000055],
              [108.45224083000008, 11.044232170000077],
              [108.45167493800007, 11.044525049000063],
              [108.44676264500005, 11.048139188000048],
              [108.44626913300007, 11.048606256000058],
              [108.44574104600008, 11.048999722000106],
              [108.444533683, 11.049295607000074],
              [108.44389290400002, 11.049303291000088],
              [108.44325215700012, 11.049304589000107],
              [108.44261142500004, 11.049300578000041],
              [108.44197074000004, 11.049292361000072],
              [108.44133008500006, 11.04928101400011],
              [108.44068948000009, 11.049267621000068],
              [108.44004892900003, 11.049253286000036],
              [108.43940842300005, 11.049239202000114],
              [108.43876792, 11.049226156000101],
              [108.43812741700002, 11.04921314400009],
              [108.43748693100005, 11.049199055000079],
              [108.43684645700009, 11.049182739000061],
              [108.43620604100002, 11.049163049000031],
              [108.43556568900007, 11.049138863000072],
              [108.43492563300005, 11.049107713000071],
              [108.43429227700004, 11.049033223000036],
              [108.4336670860001, 11.048907442000109],
              [108.43304824600006, 11.048741342000044],
              [108.43243387400003, 11.04854593700013],
              [108.43182212900004, 11.04833219000003],
              [108.4312120060001, 11.048110319000058],
              [108.43061631100009, 11.047872125000067],
              [108.43003827800005, 11.047605911000067],
              [108.42947573800005, 11.04730335600008],
              [108.42892618900005, 11.046962646000045],
              [108.4283868040001, 11.046610125000099],
              [108.427857542, 11.046248578000116],
              [108.42733867700007, 11.045876819000087],
              [108.42683049700003, 11.045493618000089],
              [108.42633324900001, 11.045097782000111],
              [108.42584722100003, 11.044688108000054],
              [108.42537268700002, 11.044263383000084],
              [108.42490991000001, 11.043822405000101],
              [108.42445622900004, 11.043371689000113],
              [108.42400860300005, 11.042917642000114],
              [108.42356617900003, 11.042460402000119],
              [108.42312809800002, 11.042000151000051],
              [108.42269351000003, 11.041537035000102],
              [108.42226157100001, 11.041071201000106],
              [108.42183141500003, 11.040602822000077],
              [108.41974202700007, 11.037243063000089],
              [108.41819568500003, 11.03412887200008],
              [108.41650541500006, 11.030981546000083],
              [108.41401878400002, 11.028206209000045],
              [108.41227943700008, 11.027653314000039],
              [108.41091247500005, 11.028582733000023],
              [108.41048691100009, 11.02967881100003],
              [108.41040743200008, 11.032711978000085],
              [108.40968408400002, 11.033746187000036],
              [108.40909268200002, 11.034090051000065],
              [108.408489219, 11.034329080000036],
              [108.4072365530001, 11.03449695000007],
              [108.40659564300009, 11.034503589000087],
              [108.40595484100001, 11.034500063000062],
              [108.40531451200005, 11.034481219000012],
              [108.40467475100004, 11.034448459000055],
              [108.40403499800006, 11.034415108000049],
              [108.40339525400006, 11.034381611000031],
              [108.40275550900006, 11.034347724000069],
              [108.40211581900007, 11.034313210000056],
              [108.40147615400008, 11.034277809000058],
              [108.4008365700001, 11.034241293000056],
              [108.40019706500003, 11.034203465000035],
              [108.39955762300005, 11.034164576000103],
              [108.39891822599999, 11.034124909000107],
              [108.39827886400012, 11.034084777000073],
              [108.39763952100006, 11.034044473000025],
              [108.39700016900011, 11.034004302000076],
              [108.39636079900005, 11.033964553000073],
              [108.39572132500007, 11.0339272990001],
              [108.38876486300003, 11.034821993000053],
              [108.38265519400007, 11.036326278000084],
              [108.38202031900002, 11.03644657400009],
              [108.38138015200008, 11.03650669300009],
              [108.38074031200009, 11.036531694000116],
              [108.38010060000008, 11.036536640000081],
              [108.37946087900009, 11.036536991000022],
              [108.378821203, 11.036526663000055],
              [108.37818171600003, 11.036495006000012],
              [108.377542546, 11.036431368000075],
              [108.37690381099999, 11.036325091000075],
              [108.37628131800008, 11.036158019000107],
              [108.37568587600003, 11.03592893100007],
              [108.37510956800004, 11.035652985000064],
              [108.37454450600009, 11.035345320000099],
              [108.37398324200011, 11.035021291000112],
              [108.37342752800004, 11.03469411500013],
              [108.37287976200003, 11.034363062000095],
              [108.37234040500007, 11.034024733000079],
              [108.37180997100006, 11.033675738000069],
              [108.37128892100002, 11.033312630000045],
              [108.37077773400009, 11.032932],
              [108.36220280200007, 11.026872376000084],
              [108.36167999900007, 11.026502208000098],
              [108.36070575500003, 11.025670760000049],
              [108.36026496800005, 11.025199590000055],
              [108.35748266500006, 11.02173813500009],
              [108.35705199900005, 11.021268652000058],
              [108.35662833500007, 11.020793317000075],
              [108.35620553299999, 11.020317916000087],
              [108.35578549400006, 11.019840684000124],
              [108.35537010800006, 11.01935982700004],
              [108.35496123900006, 11.01887357100013],
              [108.35456077900007, 11.018380149000071],
              [108.35417061000003, 11.017877767000071],
              [108.353792631, 11.017364661000071],
              [108.35308073200008, 11.016301470000121],
              [108.35274879700006, 11.015756526000063],
              [108.35243150300002, 11.01520497600008],
              [108.35212740500008, 11.014647410000043],
              [108.35183508500005, 11.014084427000091],
              [108.35155311799998, 11.013516644],
              [108.35128004900004, 11.012944652000021],
              [108.3510144700001, 11.012369049000091],
              [108.35075492600002, 11.01179045300009],
              [108.35050000100007, 11.011209444000068],
              [108.35024824000003, 11.010626658000076],
              [108.34924244800003, 11.008296230000079],
              [108.34899504000008, 11.007712507000099],
              [108.34875143699999, 11.007127394000092],
              [108.34851276400005, 11.006540439000021],
              [108.34828008300008, 11.005951168000115],
              [108.34805453500006, 11.005359119000067],
              [108.34783634100003, 11.004764157000045],
              [108.34761953800003, 11.004168641000065],
              [108.34744053000006, 11.003676946000061],
              [108.34718593500004, 11.00297762800002],
              [108.34696913600004, 11.00238211300004],
              [108.34675233800004, 11.001786607000062],
              [108.34653553200005, 11.001191102000083],
              [108.34631874500006, 11.000595587000104],
              [108.34610194200006, 11.000000082000037],
              [108.34553540500008, 10.99831969200009],
              [108.34439986700001, 10.995273464000109],
              [108.34333456100006, 10.992025430000039],
              [108.34290385800004, 10.99047527200012],
              [108.34241086500002, 10.988242308000016],
              [108.34204951600005, 10.986584538000075],
              [108.34152173000008, 10.984418801000027],
              [108.34102843900003, 10.982219601000052],
              [108.34073217800002, 10.980933839000071],
              [108.33988028700007, 10.976773],
              [108.33784166400005, 10.959513425000099],
              [108.33787888200003, 10.959176070000074],
              [108.33785655600005, 10.958352032000017],
              [108.33772612100009, 10.957641781000047],
              [108.33762808600005, 10.957134415000079],
              [108.33756453000004, 10.956593573000093],
              [108.33753335500003, 10.95625563300011],
              [108.33739959900007, 10.955916782000051],
              [108.33719715900003, 10.955611109000072],
              [108.33655899700007, 10.954322368000112],
              [108.33605336200006, 10.953507544000017],
              [108.33520913300002, 10.952318299000028],
              [108.33456266100009, 10.951958091000067],
              [108.3340496270001, 10.951970496000028],
              [108.33196453100005, 10.951884724000125],
              [108.33131458000001, 10.951912806000051],
              [108.33093816000004, 10.951943284],
              [108.33039078800005, 10.951972251000084],
              [108.32987760300004, 10.95200153200002],
              [108.32946518900012, 10.952234294000094],
              [108.3290860540001, 10.952568637000047],
              [108.32825786500005, 10.953405561000105],
              [108.32623258299999, 10.9580814540001],
              [108.32537748200012, 10.959028403000024],
              [108.324262903, 10.959932396000037],
              [108.3219518380001, 10.96094243300003],
              [108.32117651800007, 10.961346238000134],
              [108.32049115000004, 10.961509045000104],
              [108.31929379600008, 10.961566047000113],
              [108.31768583100009, 10.961653198000041],
              [108.315466052, 10.961329751000013],
              [108.31314462100005, 10.960904082000054],
              [108.31184867500011, 10.960521218000084],
              [108.31065621500004, 10.960037968000067],
              [108.30949947700007, 10.959386192000052],
              [108.30746384000004, 10.95761234300001],
              [108.30553263000003, 10.955636820000068],
              [108.30377302400011, 10.95359526400008],
              [108.30211783600002, 10.951352035000104],
              [108.30033172500004, 10.948466072000061],
              [108.29902032800007, 10.946023281000093],
              [108.29794461200005, 10.943987763000072],
              [108.29721141900005, 10.941887742000043],
              [108.29647945600006, 10.939652681000032],
              [108.29525027700005, 10.935691116000077],
              [108.29468529500011, 10.933896503000094],
              [108.29412522000004, 10.931561657000087],
              [108.29366311600009, 10.929734176000048],
              [108.2932155460001, 10.927503347000121],
              [108.29323947600004, 10.924247637000038],
              [108.29342119700006, 10.922342684000073],
              [108.294010998, 10.916440138000015],
              [108.29406192900005, 10.915910373000058],
              [108.29392839900001, 10.915554647000112],
              [108.29317418800005, 10.915210734000079],
              [108.29182170300005, 10.914743515000056],
              [108.29051983900007, 10.914410188000099],
              [108.29011021000011, 10.914339028000073],
              [108.28946036400008, 10.914367042000082],
              [108.28751936900007, 10.914667914000107],
              [108.28003732700006, 10.916680812000033],
              [108.27934744200009, 10.917349998000068],
              [108.27875796600007, 10.918256439000105],
              [108.278410267, 10.918894896000085],
              [108.27837145900007, 10.919401042000041],
              [108.27843522200001, 10.919908101000074],
              [108.27966545500001, 10.938726743000069],
              [108.27920959400005, 10.939972030000076],
              [108.27862098500002, 10.94077716600008],
              [108.27789715000009, 10.941412263000048],
              [108.27593634200011, 10.942711639],
              [108.27332560300002, 10.944038963000045],
              [108.27003013800001, 10.945461432000057],
              [108.26635918200003, 10.946812961000061],
              [108.26313420800001, 10.947999608000101],
              [108.260769232, 10.94861992500009],
              [108.25744290600002, 10.949670539000065],
              [108.25426063800006, 10.950590321000051],
              [108.25144262500005, 10.951473607000127],
              [108.24753284600003, 10.952755190000028],
              [108.24420958800005, 10.953468015000032],
              [108.24170874900007, 10.953985642000051],
              [108.23773556100004, 10.954726268000046],
              [108.23561142200006, 10.955179720000084],
              [108.23382980900008, 10.955568732000119],
              [108.23174142400003, 10.955853658000068],
              [108.22951817100002, 10.955934763000117],
              [108.22739716700003, 10.956050538000015],
              [108.22531161800005, 10.956031568000045],
              [108.22210003200007, 10.955765963000095],
              [108.21265084100001, 10.953417518000029],
              [108.21016393600004, 10.952449384000127],
              [108.20692870600004, 10.951069205000062],
              [108.20318354500006, 10.949380449000044],
              [108.20059827500003, 10.947972411000071],
              [108.19944319600002, 10.947185239000044],
              [108.19747456900004, 10.945904719000053],
              [108.1927682570001, 10.942216769000089],
              [108.18834163800003, 10.938844823000069],
              [108.187866849, 10.938435285000113],
              [108.18711793700004, 10.938090749000116],
              [108.18670802500004, 10.93805321100003],
              [108.18186197200001, 10.93681446700012],
              [108.17180221100006, 10.934346044000074],
              [108.16426010700005, 10.932658559000073],
              [108.16166774800006, 10.931921383000097],
              [108.16003003600008, 10.931534689000076],
              [108.15627881900004, 10.931101828000054],
              [108.1522788610001, 10.931713774000061],
              [108.14369254900004, 10.933146844000062],
              [108.13857712700009, 10.933849262000093],
              [108.13650296400007, 10.934602745000086],
              [108.13594602600008, 10.934963377000082],
              [108.13546435500011, 10.935429568000108],
              [108.13464372500007, 10.935544057000078],
              [108.13394478300006, 10.935493277000051],
              [108.13207709000007, 10.934742324000089],
              [108.12909687100004, 10.933501920000042],
              [108.12785818000005, 10.933157241000034],
              [108.12630637400008, 10.932485071000107],
              [108.12281884200009, 10.930907496000051],
              [108.11946402400007, 10.929331139000096],
              [108.11661313500008, 10.927785692000034],
              [108.11384562000006, 10.925851896000081],
              [108.11157957900008, 10.923924428000118],
              [108.11066909700006, 10.922784139],
              [108.10976192700005, 10.921266716000043],
              [108.10880952900003, 10.91988324200006],
              [108.10785743400004, 10.919035500000083],
              [108.10681491400001, 10.918589598000048],
              [108.1057726060001, 10.91854548100004],
              [108.10509285400008, 10.918545822000068],
              [108.10477567900004, 10.918635262000096],
              [108.10473050400003, 10.918903155000057],
              [108.105002724, 10.919528035000068],
              [108.10522976100005, 10.92042080000004],
              [108.10532070200001, 10.921001126000094],
              [108.10532097700005, 10.9215368520001],
              [108.10509466100005, 10.922072702000028],
              [108.10455105800003, 10.922474764000077],
              [108.10421845200003, 10.922376644000041],
              [108.10282883100007, 10.922163105000047],
              [108.10210373200003, 10.922118813000024],
              [108.10128011900009, 10.922142561000035],
              [108.10070389300003, 10.922099349000101],
              [108.09997380400002, 10.922119838000109],
              [108.09663689100006, 10.921720802000044],
              [108.09326626600006, 10.920917533000106],
              [108.09073715700005, 10.919868811000109],
              [108.08783583800007, 10.918011758000111],
              [108.08498870900004, 10.916044983000093],
              [108.08280223900005, 10.914372822000116],
              [108.08138279000011, 10.912970078000049],
              [108.07946352800012, 10.911116974000022],
              [108.07565415900005, 10.907148899000061],
              [108.07445144500011, 10.905302620000066],
              [108.07267571000008, 10.902349997000014],
              [108.07118524200004, 10.900081578000085],
              [108.06948403900006, 10.897653886000034],
              [108.06699528300003, 10.894353680000089],
              [108.06566255300007, 10.892244040000064],
              [108.05979129700002, 10.882096569000053],
              [108.05880049000011, 10.880304721000032],
              [108.05788519800005, 10.878932975000042],
              [108.05674113700007, 10.876536818000114],
              [108.05575397800001, 10.87437806000011],
              [108.05455558300007, 10.872112432000057],
              [108.05010119500001, 10.863656008000076],
              [108.04934721300006, 10.862076125],
              [108.04388878599998, 10.850517197],
              [108.03654485700004, 10.833776669000093],
              [108.02790811900006, 10.808295668000055],
              [108.02535127300007, 10.798547022],
              [108.02415413400004, 10.793503136000114],
              [108.02388171400007, 10.791534744000051],
              [108.02354953300002, 10.790247239000015],
              [108.02298869500008, 10.787935333000041],
              [108.02133442900006, 10.780098810000103],
              [108.0212145630001, 10.779530987000017],
              [108.02083579100011, 10.777587785],
              [108.02040785800006, 10.775250960000092],
              [108.02013442100008, 10.773387406],
              [108.01998507600007, 10.772389976000072],
              [108.01969873900001, 10.771836808000044],
              [108.01951978600003, 10.771153620000094],
              [108.01406718900007, 10.743030117000083],
              [108.013658349, 10.738753943000033],
              [108.01353013300005, 10.738307140000101],
              [108.01314134900012, 10.737386044000095],
              [108.01280148600006, 10.736884790000099],
              [108.011870034, 10.734723888000095],
              [108.0078500220001, 10.727633421000069],
              [108.0055227890001, 10.724088191000101],
              [108.00267974700006, 10.719750900000056],
              [108.00233836800005, 10.719406880000102],
              [108.00000008400011, 10.716529898000092],
              [107.99484079600009, 10.710143490000055],
              [107.99324043300001, 10.707646618000053],
              [107.9923837370001, 10.70509927700005],
              [107.99279080100003, 10.701976714000059],
              [107.99251628200005, 10.700766534000115],
              [107.9919876650001, 10.700425084000107],
              [107.99128652700009, 10.70034519300007],
              [107.99076131400005, 10.700350231000042],
              [107.99014942600006, 10.700442726000057],
              [107.98937009200006, 10.701316497000056],
              [107.98598419300004, 10.704207729000091],
              [107.98328326300003, 10.705533058000059],
              [107.9811908340001, 10.70641938400003],
              [107.9746364690001, 10.707608236000075],
              [107.97168270200005, 10.707626319000061],
              [107.96969417200009, 10.706972543000058],
              [107.9678231780001, 10.705547903000044],
              [107.96423123300008, 10.702796644000044],
              [107.96298150700007, 10.701958293000045],
              [107.96210612700003, 10.701966622000024],
              [107.961584262, 10.702318106000062],
              [107.96091185700007, 10.703050111000055],
              [107.96024873000009, 10.704438666000014],
              [107.958579598, 10.706400362000055],
              [107.95692213100008, 10.707788707000086],
              [107.95411613900009, 10.709338655000076],
              [107.9494135150001, 10.711183398000033],
              [107.93907863100004, 10.715309541000105],
              [107.93183333300009, 10.717543716000042],
              [107.92458455100009, 10.719431227000074],
              [107.91221700799998, 10.721669759000106],
              [107.905393218, 10.722253355000076],
              [107.89880604400005, 10.722720335000078],
              [107.89603209100004, 10.723033727000074],
              [107.89104093800007, 10.722993503000032],
              [107.88661350200003, 10.722384834000119],
              [107.8859949160001, 10.722241007000031],
              [107.88231370600006, 10.721385054000086],
              [107.87739778000011, 10.720044346000089],
              [107.873396853, 10.718301887000068],
              [107.87299731900008, 10.717992973],
              [107.86984232400006, 10.717341688000113],
              [107.86228857700007, 10.714812073000067],
              [107.85684261600005, 10.712956035000063],
              [107.85037325600007, 10.709333129000063],
              [107.84517306600004, 10.705655148000035],
              [107.84376825300006, 10.705234780000071],
              [107.84280602200003, 10.70533019100006],
              [107.84106475300003, 10.70638575600009],
              [107.83643593500003, 10.707640898000113],
              [107.83424881100007, 10.707834090000022],
              [107.83048013600009, 10.707435138000053],
              [107.82547050100001, 10.705401228000076],
              [107.82089625100008, 10.703103334000017],
              [107.81207816200001, 10.696338269000092],
              [107.80350398300006, 10.687491234000033],
              [107.79461240800005, 10.677390338000057],
              [107.78823099300007, 10.668869595000084],
              [107.78169457300005, 10.662516114000107],
              [107.77447532200003, 10.655804012000123],
              [107.77428706400008, 10.655628971000029],
              [107.769385791, 10.651924597000038],
              [107.76463602700002, 10.649453848000066],
              [107.75156924900006, 10.642073974000075],
              [107.74823118400009, 10.640803554000032],
              [107.72960689200001, 10.63351455400001],
              [107.72662217800006, 10.632587382000034],
              [107.724299774, 10.632304282000058],
              [107.72272564900007, 10.632491247000074],
              [107.71757402900005, 10.634008964000062],
              [107.71416236000003, 10.634298449000122],
              [107.71031299800003, 10.634591693000049],
              [107.70391964800004, 10.634300276000095],
              [107.69984906300004, 10.633517278000058],
              [107.69874572600006, 10.633305031000083],
              [107.68960978900007, 10.629744167000064],
              [107.68249495500007, 10.627032235000032],
              [107.67387472700004, 10.622339919000099],
              [107.67070305699998, 10.620027311000067],
              [107.66496732000004, 10.61479021700009],
              [107.65906495900001, 10.610507578000037],
              [107.65791952100008, 10.609650729000107],
              [107.65484159600007, 10.60803033000006],
              [107.64952447700003, 10.605605556000073],
              [107.64276052400002, 10.602889537000058],
              [107.637317829, 10.601115483000088],
              [107.63617818000003, 10.600839840000019],
              [107.6307387080001, 10.599524123000046],
              [107.61941362600008, 10.595719112000078],
              [107.61186002000002, 10.592748911000081],
              [107.58953613799999, 10.582187763000126],
              [107.58355493900008, 10.578770533000091],
              [107.57651119300002, 10.573888637000016],
              [107.57339859900004, 10.572341236000058],
              [107.57011157000004, 10.570545664000084],
              [107.56820854500006, 10.579864946000049],
              [107.56792193500006, 10.585573175000105],
              [107.56791507300002, 10.588835367000103],
              [107.56817711100003, 10.594816657000104],
              [107.56650728000007, 10.612021592000074],
              [107.56625348100003, 10.612839104000036],
              [107.5661555520001, 10.615682180000084],
              [107.56613253700006, 10.616036091000057],
              [107.56603980700002, 10.61987562300004],
              [107.56564346, 10.620920916000019],
              [107.5613411570001, 10.632266822000068],
              [107.55784875700006, 10.638263228000076],
              [107.54934710900007, 10.654413876000122],
              [107.54174779900004, 10.673688965000048],
              [107.54119729300005, 10.677438291000028],
              [107.54088592900005, 10.68253579100009],
              [107.54102852400004, 10.691131248000117],
              [107.54180298799999, 10.700582328000062],
              [107.54064004500005, 10.708608779000082],
              [107.53819280200007, 10.713961274000015],
              [107.53440197200003, 10.719071473000062],
              [107.52781887400006, 10.727325327000068],
              [107.52143877, 10.733610799000102],
              [107.50522994400004, 10.747603002000082],
              [107.50210202400004, 10.748573443000085],
              [107.50324325300005, 10.76196851200004],
              [107.50415967000004, 10.77272379200007],
              [107.51314296400008, 10.773565555000085],
              [107.52004727500008, 10.77539728400003],
              [107.52809897400005, 10.779047215000046],
              [107.53591708200007, 10.784285220000099],
              [107.54304466500005, 10.789521473000095],
              [107.55132268000001, 10.795440948000044],
              [107.55867941500004, 10.801585197000028],
              [107.56304480100002, 10.806588086000078],
              [107.56741087800005, 10.811363936000083],
              [107.56970320300009, 10.816589475000081],
              [107.57199281500009, 10.82317700600006],
              [107.57428210200005, 10.829991559000083],
              [107.57450350700007, 10.834304985000108],
              [107.57386845200003, 10.840436280000086],
              [107.57379829899999, 10.841113577000081],
              [107.57217299500007, 10.847239347000047],
              [107.56998842700006, 10.851072856000064],
              [107.56777974800011, 10.854948528000106],
              [107.55784273800001, 10.869229393000078],
              [107.55813724800004, 10.90684724100006],
              [107.55838490900001, 10.938472509000084],
              [107.540699563, 10.978585460000099],
              [107.53362365300002, 10.987227941000109],
              [107.52769430000005, 10.993451617000092],
              [107.51821536800003, 10.999667072000106],
              [107.51270751300002, 11.004964203000076],
              [107.50902160600006, 11.009914144],
              [107.50635276100003, 11.012893302000036],
              [107.5024032630001, 11.015222906000053],
              [107.50209275400007, 11.015277949000078],
              [107.498063104, 11.015992228000114],
              [107.49294087100002, 11.014030820000096],
              [107.48427618400004, 11.009331898000061],
              [107.47915127900011, 11.008539620000056],
              [107.4740254230001, 11.008137033000052],
              [107.46968529900008, 11.008905761000063],
              [107.4621799220001, 11.013564517000061],
              [107.45390582600001, 11.019554177000133],
              [107.44755099000011, 11.027170432000053],
              [107.44241306300007, 11.031055163000046],
              [107.43688266200007, 11.034159064000091],
              [107.42035380200009, 11.037300004000093],
              [107.415851741, 11.038677659000127],
              [107.41201615500007, 11.040328307000012],
              [107.40672821700007, 11.043690798000048],
              [107.40113594900001, 11.047829610000102],
              [107.3953896940001, 11.049813471000023],
              [107.39278426200008, 11.050712917000014],
              [107.39552468500008, 11.052977068000034],
              [107.39715500100006, 11.053956320000056],
              [107.3983664940001, 11.05415686200004],
              [107.40037308700005, 11.054276289000063],
              [107.40310996000001, 11.05370173200013],
              [107.41165378900003, 11.050913880000033],
              [107.41351133800006, 11.050895068000075],
              [107.41497187400003, 11.051391797000052],
              [107.41780844200008, 11.054445958000068],
              [107.42182338500001, 11.060080972000017],
              [107.42394488500003, 11.064539106000025],
              [107.42433734800008, 11.067239725000098],
              [107.42393106000004, 11.069460079000047],
              [107.42273818500011, 11.072034491000069],
              [107.42109761500008, 11.074502657],
              [107.42052318200007, 11.076738577000087],
              [107.42129625500009, 11.078826284000074],
              [107.42507725000011, 11.083523424000051],
              [107.42869891000001, 11.087699970000118],
              [107.43090442000006, 11.089135497000056],
              [107.43424679200004, 11.090387602000094],
              [107.43573045000005, 11.09011382300009],
              [107.43683832300005, 11.089213632000037],
              [107.43787440700007, 11.086604454000069],
              [107.43859032900006, 11.084965990000038],
              [107.43969235000003, 11.084373258000111],
              [107.44096173700007, 11.084972311000028],
              [107.44321632100011, 11.088484776000127],
              [107.44873950700003, 11.102802886000072],
              [107.451332597, 11.108668338000081],
              [107.45227313500001, 11.111717311000023],
              [107.45226875300001, 11.113357738000039],
              [107.45084006500011, 11.11546315900007],
              [107.44769456700008, 11.11923240000001],
              [107.44132811600011, 11.124577782000065],
              [107.43706524800008, 11.127284608000119],
              [107.43443354300003, 11.130418192000134],
              [107.43252561900009, 11.134162727000072],
              [107.43251653700004, 11.137443697000025],
              [107.43417176100004, 11.139323013000089],
              [107.44151613800003, 11.142623661000071],
              [107.45122659300003, 11.148273861000034],
              [107.45572308200011, 11.15226968900007],
              [107.45690296200011, 11.154616363000079],
              [107.45925724900005, 11.161418975000091],
              [107.46065966500008, 11.169391022000051],
              [107.46005570700002, 11.172472565000112],
              [107.4595435950001, 11.175082031000015],
              [107.45896076200002, 11.177624090000025],
              [107.45963460600009, 11.178139439000033],
              [107.461543265, 11.178520259000011],
              [107.46287953600007, 11.178711639000126],
              [107.46650787300008, 11.178720877000091],
              [107.46841704700005, 11.178913674000055],
              [107.46909414500001, 11.179706920000053],
              [107.46993796400002, 11.181549],
              [107.47049630100004, 11.187189334],
              [107.46952392400006, 11.193953646000042],
              [107.47141723600004, 11.200349315000045],
              [107.47236733300004, 11.20223138500007],
              [107.47408380500005, 11.203175533000119],
              [107.47637518000001, 11.203369219000068],
              [107.4786713500001, 11.2016832070001],
              [107.47981774600001, 11.201498078000059],
              [107.48097229200005, 11.202336277000045],
              [107.48305219800002, 11.206393253000078],
              [107.48665607500004, 11.216364555000078],
              [107.48665325000007, 11.217492386000048],
              [107.48626794900008, 11.218807256000039],
              [107.48492667900004, 11.220495733000034],
              [107.48476003800002, 11.221472979000067],
              [107.48473142200004, 11.222187017000083],
              [107.48492980400003, 11.222547071000031],
              [107.48587226900003, 11.224257522000068],
              [107.48650927700007, 11.225095735000055],
              [107.48739657800012, 11.225765041000011],
              [107.48848960900006, 11.225824259000115],
              [107.48949861400004, 11.225394207000059],
              [107.49198825000008, 11.222768593000064],
              [107.49351915699999, 11.22168304500004],
              [107.49466651800007, 11.221083282000064],
              [107.49638602200005, 11.220899435000129],
              [107.49772214200003, 11.221278576000058],
              [107.498670729, 11.223912478000029],
              [107.49865923300003, 11.22861182300001],
              [107.49836023400007, 11.230731482000065],
              [107.49798609100002, 11.231703888000053],
              [107.49585270200006, 11.234786880000057],
              [107.49473304100007, 11.237274604000012],
              [107.49452542000003, 11.239366060000043],
              [107.49482297200005, 11.241857201000103],
              [107.49532546200005, 11.243352664000026],
              [107.49623484700007, 11.244052171000083],
              [107.4980568170001, 11.244156142000071],
              [107.50210642600005, 11.244066155000086],
              [107.50635015700009, 11.244359317000095],
              [107.50807828899998, 11.244478675000023],
              [107.51070943000008, 11.244883289000105],
              [107.51232752400003, 11.245584366000026],
              [107.513591644, 11.247391479000067],
              [107.51416333600005, 11.249816865000104],
              [107.51482922300008, 11.252474126000054],
              [107.51617149800002, 11.253362471000104],
              [107.52042868800001, 11.253372222000023],
              [107.5213443640001, 11.253319196000076],
              [107.52226490200006, 11.257449379000127],
              [107.52202718700002, 11.260401622000058],
              [107.52108580500003, 11.268122188000083],
              [107.52038212200007, 11.272890537000089],
              [107.51759538600007, 11.279925576000059],
              [107.51666017800005, 11.28492060700008],
              [107.51780218500004, 11.290147583000108],
              [107.52056301100002, 11.294242541000086],
              [107.521481722, 11.296288969000033],
              [107.52124447200001, 11.299014214000101],
              [107.52008318300003, 11.301964508000049],
              [107.51845742000006, 11.306049513000092],
              [107.51683052000001, 11.310588822000065],
              [107.51659426400001, 11.312859810000118],
              [107.51820569700003, 11.314907895000113],
              [107.52097111300004, 11.317185754000112],
              [107.52535288200004, 11.319467237000056],
              [107.52950071900004, 11.3231110600001],
              [107.53364249900007, 11.32948069100008],
              [107.53616604800006, 11.336755384000073],
              [107.53892275600006, 11.343121968000068],
              [107.54075284500006, 11.350849547000051],
              [107.54166133800008, 11.357666505000095],
              [107.54280337600008, 11.363348189000087],
              [107.54215712300007, 11.367187572000057],
              [107.54440182500008, 11.371529782000065],
              [107.54924193700008, 11.376083643000088],
              [107.56470196400008, 11.382931411000106],
              [107.56816412600003, 11.384074414000088],
              [107.573709346, 11.383177023000089],
              [107.57579326500003, 11.380682324000089],
              [107.57880425900002, 11.376599247000028],
              [107.581584166, 11.372515699000106],
              [107.58598156900004, 11.367980986000068],
              [107.591073442, 11.362538877000093],
              [107.59477669000007, 11.358457024000044],
              [107.60056311400002, 11.351880270000068],
              [107.60542151300011, 11.347346142000079],
              [107.615592265, 11.342140232000107],
              [107.62460415800003, 11.338976235000063],
              [107.63153342200003, 11.338079843000058],
              [107.64007827000005, 11.337640314000017],
              [107.64515850100001, 11.337648927000108],
              [107.65162695400009, 11.336069620000087],
              [107.65833479, 11.332564406000055],
              [107.65856387800002, 11.332444689000097],
              [107.66526262100005, 11.329049824000046],
              [107.67219283400006, 11.327243476000069],
              [107.68212293300012, 11.3265772400001],
              [107.69135854000008, 11.327045308000015],
              [107.69735946000002, 11.328871288000107],
              [107.70451210000004, 11.332743155000101],
              [107.70958770300007, 11.335930457000119],
              [107.71327794300004, 11.339115766000061],
              [107.71650455600012, 11.3436633810001],
              [107.71903883200011, 11.347982883],
              [107.72088009200004, 11.352528621000067],
              [107.72156826600005, 11.355937021000083],
              [107.72133114600005, 11.360480042000097],
              [107.72226723800006, 11.363567952000063],
              [107.72251312700007, 11.363419017000085],
              [107.72406553200008, 11.361017775000091],
              [107.725699429, 11.359709024000074],
              [107.72738804100001, 11.358837319000068],
              [107.72904810800004, 11.35862098500011],
              [107.73181326400004, 11.359498445000058],
              [107.73380315700011, 11.360921086000085],
              [107.73700621100001, 11.365513198000075],
              [107.73819435100009, 11.366607050000075],
              [107.73921723800007, 11.367154556000079],
              [107.74109785000007, 11.367593843000099],
              [107.74430676200009, 11.367707012000048],
              [107.74884463300003, 11.366947788000024],
              [107.75039395100006, 11.36684039600002],
              [107.75194467600004, 11.367168482000077],
              [107.75271808300008, 11.368152557000053],
              [107.75278876700006, 11.368872459000022],
              [107.76404483700006, 11.370300550000055],
              [107.77305189700012, 11.370537633000042],
              [107.78875627700009, 11.371235504000053],
              [107.79350575100005, 11.371657361000052],
              [107.79594437100012, 11.371874890000109],
              [107.80168880900007, 11.37238394000004],
              [107.81670237200008, 11.371034532000033],
              [107.82354682600001, 11.370690673000093],
              [107.83448684500004, 11.370140593000054],
              [107.86843893400004, 11.36789285600006],
              [107.88807077899999, 11.366541201000045],
              [107.90423831300009, 11.364731831000084],
              [107.92086789399998, 11.361785553000024],
              [107.92584512300004, 11.360203623000052],
              [107.92872096800005, 11.359289516000091],
              [107.93334081700002, 11.356337862000119],
              [107.93472718900007, 11.354066640000047],
              [107.93634495800008, 11.350205331000039],
              [107.939116912, 11.347707355000034],
              [107.94281181000011, 11.347708392000031],
              [107.94743020400006, 11.348618251000085],
              [107.95112481200003, 11.349982116000133],
              [107.95597393500006, 11.352254830000062],
              [107.95943798000006, 11.352255527000031],
              [107.96267142100008, 11.350438810000083],
              [107.96636678700003, 11.347940626000062],
              [107.97699012800007, 11.343625959000024],
              [107.98599644400008, 11.341127977000019],
              [107.99546440000009, 11.33772097600003],
              [108.00008280100012, 11.334995106000088],
              [108.00134514200002, 11.334599272000021],
              [108.00493201900007, 11.333404929000055],
              [108.0141684710001, 11.331132923],
              [108.02040295500009, 11.329542299000115],
              [108.02594458900005, 11.327724428000073],
              [108.02686769200004, 11.32363549200006],
              [108.02871355500008, 11.313867597000103],
              [108.02940543400004, 11.308188665000072],
              [108.02894256300003, 11.300465615000109],
              [108.03032722700002, 11.296376749000013],
              [108.03378924100012, 11.289788962000012],
              [108.03817459800005, 11.283882438000068],
              [108.04140562800005, 11.278884678000088],
              [108.042328287, 11.275250225000109],
              [108.04117303200007, 11.270253355000113],
              [108.03955620400006, 11.265483743000091],
              [108.04047862, 11.260713676000121],
              [108.04186306400007, 11.257987769000087],
              [108.04532446000012, 11.253217208000075],
              [108.04901679500009, 11.249582238000054],
              [108.05012343200008, 11.248103430000087],
              [108.05116742200003, 11.246713116000072],
              [108.04920106100005, 11.242238099000089],
              [108.04718205400007, 11.237271611000031],
              [108.04558691500007, 11.22861649600013],
              [108.04443576700004, 11.21678478400012],
              [108.05224041000008, 11.218691965000092],
              [108.05524111800003, 11.220508190000087],
              [108.05570337100009, 11.223006435000025],
              [108.05547362599999, 11.226867636000025],
              [108.05478263200011, 11.232091745000082],
              [108.05478390700004, 11.236861450000022],
              [108.05570824800004, 11.240949547000055],
              [108.05871002900008, 11.245264230000094],
              [108.06402000600005, 11.249123939000059],
              [108.06933002100001, 11.252529256000086],
              [108.07487171100007, 11.257751467000075],
              [108.09865212400008, 11.267962855000045],
              [108.10234637800004, 11.269551060000092],
              [108.10275577800007, 11.269819326000084],
              [108.10511743100008, 11.271366811000048],
              [108.10581184800006, 11.275000722000071],
              [108.10647472900011, 11.277197887000055],
              [108.10766186400011, 11.281132625000099],
              [108.10928100700005, 11.287037534000087],
              [108.11274707400004, 11.292714353000031],
              [108.11390230700005, 11.294303763000089],
              [108.11597975800008, 11.293621182000011],
              [108.11736420100006, 11.29225753400007],
              [108.11897790799999, 11.288168002000097],
              [108.12013050000006, 11.285214460000057],
              [108.12359341100006, 11.285212453000069],
              [108.1263655650001, 11.288163693000095],
              [108.13052343800004, 11.291795487000032],
              [108.13583551300009, 11.294972179000061],
              [108.14181267800011, 11.297681573000061],
              [108.14899917800008, 11.300414832000081],
              [108.15800482300003, 11.302225454000057],
              [108.16539316900001, 11.302446919000085],
              [108.17416797400004, 11.304029890000054],
              [108.17994141400008, 11.305615087000119],
              [108.18479284200004, 11.308790988000082],
              [108.18918410800011, 11.313557288000096],
              [108.19195865400008, 11.317643541000065],
              [108.20651664200005, 11.328987564000046],
              [108.22616281200004, 11.346004403000086],
              [108.23147854600005, 11.34986040700001],
              [108.2370246360001, 11.353034536000093],
              [108.24303223900006, 11.3557536680001],
              [108.24857894400007, 11.359154689000126],
              [108.25412751500006, 11.363918587000031],
              [108.25921415800012, 11.368455764000094],
              [108.26291642600002, 11.373903274000073],
              [108.266617411, 11.378214882000036],
              [108.27124123500003, 11.381616576000029],
              [108.27501722000009, 11.385849785000078],
              [108.27609894900007, 11.38706245700005],
              [108.28974675100005, 11.40135629900003],
              [108.29622240000002, 11.406799624000106],
              [108.30200505100001, 11.412016599000106],
              [108.30624209500012, 11.416337895000071],
              [108.30186481500004, 11.425147012000101],
              [108.29685927300005, 11.431812382000127],
              [108.2923825940001, 11.440572048000062],
              [108.28728154400001, 11.446144056000039],
              [108.28476870500002, 11.44784788200006],
              [108.28178208900009, 11.448006488000127],
              [108.279107138, 11.446155056000061],
              [108.27737333400009, 11.442756520000062],
              [108.27518043500001, 11.441212816000055],
              [108.27339605700007, 11.440299807000097],
              [108.27155187200007, 11.4417060700001],
              [108.26786360199999, 11.448072201000057],
              [108.26509695000004, 11.452619590000078],
              [108.2623313390001, 11.458075763000062],
              [108.25979635300006, 11.463304442000053],
              [108.25749288400007, 11.468987251000049],
              [108.25657572600004, 11.474668391000085],
              [108.25704273000004, 11.478530260000051],
              [108.26423108600008, 11.497833834000025],
              [108.26492850800005, 11.501013880000064],
              [108.26840072600002, 11.505326472000108],
              [108.28320773100009, 11.516894896000094],
              [108.28808814800004, 11.518995960000032],
              [108.30425333200006, 11.525954146000037],
              [108.30957246900007, 11.527991347000109],
              [108.32690319800005, 11.525238537000069],
              [108.33360711400007, 11.525909673000088],
              [108.33959842600011, 11.526765164000084],
              [108.34446841900008, 11.524756093000031],
              [108.34677125500011, 11.5197535710001],
              [108.34861213000001, 11.514979030000026],
              [108.35345152100004, 11.506791310000073],
              [108.35876706700002, 11.50678231700004],
              [108.36269714700003, 11.507457220000015],
              [108.36755334600011, 11.509039281000087],
              [108.37125527000008, 11.511304905000051],
              [108.37426641800009, 11.514934988000023],
              [108.37704864300008, 11.519701525000057],
              [108.38006128200003, 11.524013197000025],
              [108.38584080700012, 11.524684336000044],
              [108.41381633800006, 11.528948326000064],
              [108.41635743500008, 11.528261659000092],
              [108.41843486600001, 11.526894236000105],
              [108.42051039300006, 11.524617934000057],
              [108.42465801800004, 11.518474785000075],
              [108.42904460500009, 11.516193747000079],
              [108.43551647200007, 11.516407666000104],
              [108.4422214360001, 11.517529762000043],
              [108.44800099000001, 11.518199180000098],
              [108.45493518400008, 11.518411527000081],
              [108.45909540800004, 11.518402495000133],
              [108.47945660600004, 11.527900187000027],
              [108.48616218700002, 11.529020900000118],
              [108.49055539100007, 11.529692357000075],
              [108.49471474400005, 11.529228188000038],
              [108.49979750300004, 11.52830731000005],
              [108.50487798400012, 11.526477448000081],
              [108.50880101700008, 11.523968622000067],
              [108.51341449000006, 11.520321958000055],
              [108.51687506800005, 11.517814129000035],
              [108.5207960540001, 11.514623457000093],
              [108.52425527200006, 11.511661102000035],
              [108.52933394600007, 11.50937626600008],
              [108.53418316700002, 11.507773528000074],
              [108.53788213600009, 11.508218532000035],
              [108.54204575700007, 11.509571123000038],
              [108.54783331600004, 11.51319154500001],
              [108.55316067200002, 11.517494684000072],
              [108.55444318000008, 11.518601197000054],
              [108.5570990250001, 11.520892529000013],
              [108.55988434400007, 11.525202253000087],
              [108.56174897400007, 11.530877733000098],
              [108.564079749, 11.5379153110001],
              [108.56640377800001, 11.54245349600007],
              [108.569888108, 11.548579113000111],
              [108.57313448500003, 11.552205888000076],
              [108.57753552400001, 11.555375014000012],
              [108.58225320300009, 11.556813911000051],
              [108.58794119400007, 11.556482345000061],
              [108.59649234300008, 11.555776617000022],
              [108.60319413000009, 11.555075841000038],
              [108.60874004100003, 11.554378205000136],
              [108.61497667700003, 11.55276949800008],
              [108.61982900800005, 11.552073589000054],
              [108.62398782900007, 11.551379633000101],
              [108.62976683300005, 11.551362425000098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 12, code: "AD01", ten_tinh: "Cà Mau" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.87929423400006, 8.381025730000056],
              [104.87883771200003, 8.380953215000096],
              [104.87846526300004, 8.380975596000083],
              [104.87821492300002, 8.381036806000084],
              [104.87793061400002, 8.381157336000021],
              [104.87758894000009, 8.381385487000069],
              [104.87730588900003, 8.381682953000079],
              [104.8771404280001, 8.381942954000097],
              [104.87703857100011, 8.382178767000069],
              [104.87696653900011, 8.382451776],
              [104.87693587800005, 8.382748618000056],
              [104.87696623400008, 8.383099956000082],
              [104.87708598800003, 8.383505063000117],
              [104.87731279700006, 8.383891708000045],
              [104.87758476100001, 8.384182016000084],
              [104.87785883600003, 8.38437338500002],
              [104.878224441, 8.38453142800004],
              [104.87854006100009, 8.384606512000035],
              [104.87879517400006, 8.38462122],
              [104.87915318900011, 8.384579078000074],
              [104.87949788900002, 8.384474272000107],
              [104.87974927800005, 8.384343851000063],
              [104.88006172500005, 8.384097240000086],
              [104.88030336800006, 8.383814243000119],
              [104.88043381800001, 8.383592838000096],
              [104.88054347200003, 8.383305063000089],
              [104.88061458500005, 8.382927375000103],
              [104.88061103600003, 8.382594057000057],
              [104.88056068600012, 8.382316269000077],
              [104.88045570600005, 8.382026748000014],
              [104.8803061130001, 8.381757383000108],
              [104.88011116600006, 8.381518506000061],
              [104.87988077100009, 8.381313304000047],
              [104.87964087200004, 8.381162947000041],
              [104.87929423400006, 8.381025730000056],
            ],
          ],
          [
            [
              [104.85387451400003, 8.420025085000036],
              [104.85434386200004, 8.41990836200006],
              [104.854907005, 8.419931959000058],
              [104.85579863000007, 8.420049174000075],
              [104.85643221400008, 8.419979269000065],
              [104.85687811800003, 8.419792401000057],
              [104.85718327500005, 8.419465206000075],
              [104.85723037100004, 8.419021023000093],
              [104.85720704900007, 8.418646950000051],
              [104.85699602600002, 8.418202674000062],
              [104.85619858500012, 8.417197093000082],
              [104.85540126700005, 8.41588757900009],
              [104.85486192800001, 8.414975599000039],
              [104.85465096000004, 8.414391044000078],
              [104.85453386400005, 8.413783159000053],
              [104.85436982000006, 8.413245378000054],
              [104.85413533100011, 8.412801092000064],
              [104.85383037000001, 8.412613951000123],
              [104.85343150400004, 8.412520283000012],
              [104.85279796200004, 8.412520042000081],
              [104.85200019900002, 8.412472992000096],
              [104.85146051800004, 8.412449409000098],
              [104.85117897900001, 8.412355790000079],
              [104.8508505610001, 8.412121875000088],
              [104.85035799000012, 8.411654106000109],
              [104.85002954100003, 8.411513704000038],
              [104.84965410900006, 8.411536944000096],
              [104.84911438000007, 8.41165363400007],
              [104.84829296300006, 8.412074144000046],
              [104.84789391900009, 8.412448053000075],
              [104.84756525400006, 8.412868739000066],
              [104.84693603300005, 8.414188208000082],
              [104.8466875550001, 8.415485803000065],
              [104.84666649100004, 8.416495110000087],
              [104.84672816000008, 8.41729845500012],
              [104.84691396400009, 8.417751691000051],
              [104.84709388100005, 8.41817557500004],
              [104.84791481100004, 8.419040915000052],
              [104.84901725400003, 8.420093384],
              [104.84993213200005, 8.420748342000104],
              [104.85044825000004, 8.421029094000064],
              [104.85096447100003, 8.421076047000041],
              [104.85138685100007, 8.421052820000073],
              [104.85176232300006, 8.420936071000094],
              [104.85248983300002, 8.420702557000046],
              [104.85326430700007, 8.420352162000098],
              [104.85387451400003, 8.420025085000036],
            ],
          ],
          [
            [
              [104.84050940700006, 8.439871734000091],
              [104.83991025500005, 8.439521330000053],
              [104.83943499700007, 8.439397540000114],
              [104.83875305300005, 8.439376662000074],
              [104.83831904000002, 8.439458876000037],
              [104.83796767399998, 8.439602917000054],
              [104.8374923280001, 8.439685114000016],
              [104.83701703900002, 8.439643725000055],
              [104.83645916800005, 8.439416911000118],
              [104.83596339800006, 8.438963538000087],
              [104.835632952, 8.438489645000045],
              [104.83540592100003, 8.437809809000017],
              [104.83524101300007, 8.436821020000094],
              [104.83542751000005, 8.435605804000089],
              [104.83592415700011, 8.433999353000042],
              [104.83623468600004, 8.432681195000045],
              [104.83660700200002, 8.431878015000025],
              [104.8371446470001, 8.43101310900005],
              [104.83762029300001, 8.430189378000106],
              [104.83782715400008, 8.42967451300005],
              [104.83793065500004, 8.429241987000056],
              [104.83795515500002, 8.42860345400007],
              [104.83787274700003, 8.427923669000046],
              [104.83785223600003, 8.427532297000083],
              [104.8376663760001, 8.427017264000101],
              [104.83741843900002, 8.426666988000074],
              [104.83702576600004, 8.426316657000131],
              [104.83569474100004, 8.425781627000093],
              [104.83408240000004, 8.425183598000084],
              [104.83282961300003, 8.424605239000103],
              [104.83212690100007, 8.42411058100012],
              [104.83152757000003, 8.423595364000034],
              [104.83051485800009, 8.422873995000122],
              [104.82931599600002, 8.422317323000071],
              [104.82807574100008, 8.421884219000029],
              [104.82604984200005, 8.421533158000026],
              [104.82478882100007, 8.421306018000061],
              [104.824003245, 8.421223275000054],
              [104.82350707000006, 8.421202451000019],
              [104.82249397700008, 8.421346184000099],
              [104.8219563800001, 8.421531321000051],
              [104.82133603400011, 8.421778223000052],
              [104.82036404200007, 8.422457522000036],
              [104.81899895000001, 8.423754590000064],
              [104.81757165800005, 8.425401788000018],
              [104.816930407, 8.426143026000064],
              [104.81651668200006, 8.426657787000112],
              [104.81612366400005, 8.427090171000094],
              [104.81595808600011, 8.427481457000051],
              [104.81593722700008, 8.427852213000085],
              [104.81618500700006, 8.428511478000099],
              [104.81690825500004, 8.429273951000125],
              [104.81810681100009, 8.430489808000095],
              [104.81909878900009, 8.431355386000037],
              [104.82102065800004, 8.433230716000127],
              [104.82228124200007, 8.434467185000115],
              [104.82319047699998, 8.435456308000065],
              [104.82372766500002, 8.436259886000117],
              [104.82428549900008, 8.437104661000115],
              [104.82469872200008, 8.4377227940001],
              [104.82500866200004, 8.438134900000092],
              [104.82542199800005, 8.438505852000054],
              [104.82631078300005, 8.439021209000062],
              [104.82763368500008, 8.439701528000041],
              [104.82841911200006, 8.440196234000029],
              [104.8288531370001, 8.440546591000063],
              [104.82916311500007, 8.440896896000092],
              [104.82936968100002, 8.441329546000096],
              [104.82949353000005, 8.441782762000026],
              [104.82959657800005, 8.442503751000084],
              [104.82967889700002, 8.44336891000008],
              [104.82974061500006, 8.444089876000048],
              [104.82982310700005, 8.444543077000065],
              [104.83002967400009, 8.444986026000033],
              [104.83040165900005, 8.445367261000046],
              [104.83106305400005, 8.445882504000045],
              [104.83242738900006, 8.446439240000101],
              [104.83385368600003, 8.447140180000099],
              [104.83453575700007, 8.4476554400001],
              [104.8353005160001, 8.448191311000118],
              [104.83583782100007, 8.448809483000018],
              [104.83627175400008, 8.449407016000075],
              [104.83643703800008, 8.449695455000049],
              [104.83664361000004, 8.450128106000053],
              [104.83716009200005, 8.451117036000076],
              [104.83749045000012, 8.452188289000072],
              [104.83777959600006, 8.452950550000081],
              [104.83808954900003, 8.453383241],
              [104.83901956700007, 8.454331134000091],
              [104.83974294600006, 8.454990581000031],
              [104.84003231200002, 8.4552275780001],
              [104.84030104400006, 8.455351273000121],
              [104.84059051800003, 8.455320499000061],
              [104.84081802300001, 8.45517639900004],
              [104.840962844, 8.454949879000127],
              [104.84106637300005, 8.454579154],
              [104.84112865000004, 8.453961236000039],
              [104.84110823600005, 8.453302081000034],
              [104.84127379800006, 8.452931379000093],
              [104.84145996800001, 8.452704874000093],
              [104.84179088900004, 8.452437239000064],
              [104.84230792700001, 8.452087278000063],
              [104.84280429900005, 8.451716709000044],
              [104.84305253800007, 8.451407826000066],
              [104.84334227400007, 8.450707600000039],
              [104.84371463200006, 8.450233989000109],
              [104.84445920600011, 8.449575134000041],
              [104.84485220200003, 8.449183928000091],
              [104.84503841900008, 8.448854425000095],
              [104.84510058700006, 8.448483689000062],
              [104.84510081300007, 8.447906938000036],
              [104.84477060900011, 8.446403129000133],
              [104.8445642840001, 8.445352534000071],
              [104.84450256300005, 8.444569782000054],
              [104.84444073200009, 8.444075403000094],
              [104.84431689700006, 8.443560397000026],
              [104.84408965100008, 8.443086545000076],
              [104.84394504100001, 8.442798115000104],
              [104.84371770100003, 8.442571436],
              [104.84295297900009, 8.441953188000094],
              [104.84231224800001, 8.441458567000025],
              [104.84202289100006, 8.441231875000097],
              [104.84158887500011, 8.440840329000119],
              [104.84106721400012, 8.440263339000074],
              [104.84050940700006, 8.439871734000091],
            ],
          ],
          [
            [
              [104.56469697600009, 8.880719868000051],
              [104.56444753400007, 8.880636771000104],
              [104.56419788700001, 8.88073309200008],
              [104.56383729300011, 8.880870688000096],
              [104.56311575700009, 8.881421906000051],
              [104.56222737400003, 8.882386975000054],
              [104.56194968000007, 8.882745496000053],
              [104.56178302200004, 8.882993731000076],
              [104.56172721600004, 8.883297301000058],
              [104.56189309700009, 8.883697742000061],
              [104.56225319400005, 8.883987998000096],
              [104.56253036400005, 8.884071131000047],
              [104.56311251500009, 8.884154628000117],
              [104.56336189300004, 8.884292936000103],
              [104.56361113700004, 8.884541652000076],
              [104.56381465700009, 8.884783601000022],
              [104.56405436700004, 8.884873419000082],
              [104.56434562500007, 8.884763343000122],
              [104.564512361, 8.884446099000074],
              [104.56485932800003, 8.884115265000057],
              [104.56533106300006, 8.883784579000052],
              [104.56605273300009, 8.883122939000108],
              [104.56602554000006, 8.88266745100008],
              [104.56585978500003, 8.882156597000089],
              [104.56561105900005, 8.881466231000077],
              [104.56541736800001, 8.881134757],
              [104.56514039300001, 8.88088601200012],
              [104.56469697600009, 8.880719868000051],
            ],
          ],
          [
            [
              [104.52858057600008, 8.943451666000078],
              [104.52813271200006, 8.943391640000085],
              [104.5277444610001, 8.943420868000105],
              [104.52729617000004, 8.943687821000077],
              [104.52651862600001, 8.944548856],
              [104.52538213100003, 8.945870169000079],
              [104.52358839600004, 8.947383847000088],
              [104.52242268600004, 8.948184921000022],
              [104.52191443700008, 8.94863014200009],
              [104.52152555200006, 8.949134966000115],
              [104.52128579800004, 8.949788603000018],
              [104.52122542400009, 8.950278990000092],
              [104.52114967000004, 8.951126057000101],
              [104.52095437200005, 8.952032423000043],
              [104.52068462300011, 8.952775197000038],
              [104.52047445300005, 8.953636943000019],
              [104.52041392900001, 8.954246237000072],
              [104.5204127590001, 8.955137984000048],
              [104.52050132800005, 8.955910943000054],
              [104.52064987100007, 8.956505642000055],
              [104.52088820199999, 8.956951823000024],
              [104.52154434100001, 8.957606624000023],
              [104.52231995100007, 8.958261585000036],
              [104.52294651300004, 8.958708268000047],
              [104.5235430590001, 8.959273806000033],
              [104.52387097700006, 8.9597201080001],
              [104.52413944400007, 8.959958257],
              [104.52443799900007, 8.960018086000064],
              [104.52473680300002, 8.959899577000028],
              [104.52494619400002, 8.959632310000119],
              [104.525544838, 8.958577843000091],
              [104.52656294000003, 8.956468660000015],
              [104.52755086200006, 8.954597242000071],
              [104.52838919000006, 8.952933706000097],
              [104.52904734500007, 8.952027942000116],
              [104.52982508600012, 8.951018279000065],
              [104.53024390100003, 8.950454033000098],
              [104.53048322400004, 8.950127361000069],
              [104.53060315200001, 8.949755947000069],
              [104.53072402200007, 8.948656266000031],
              [104.53066589100006, 8.947407746000112],
              [104.53033896500008, 8.946188599000047],
              [104.52965425400001, 8.944523130000091],
              [104.52926684000002, 8.943898408000093],
              [104.52893872900006, 8.943600741000067],
              [104.52858057600008, 8.943451666000078],
            ],
          ],
          [
            [
              [104.79951554900008, 9.177643487000074],
              [104.799205414, 9.177634164000013],
              [104.79882223400008, 9.177690566000061],
              [104.79852905500007, 9.177791561000069],
              [104.79827956500004, 9.177927526000039],
              [104.79803448200002, 9.178116645000054],
              [104.79785599000007, 9.178302808000092],
              [104.79769472200006, 9.178535753000093],
              [104.79753183700008, 9.178898886000029],
              [104.79744441400004, 9.17935749800005],
              [104.79748301600003, 9.179859646000031],
              [104.79759687300005, 9.180214127000051],
              [104.79774609700004, 9.180484476000038],
              [104.7979571170001, 9.180744326000044],
              [104.7982544210001, 9.180991080000032],
              [104.79859610600003, 9.181172421000026],
              [104.79898240100002, 9.181284306000046],
              [104.79930350600003, 9.181308524000096],
              [104.79955919800005, 9.181287969000083],
              [104.79986274500003, 9.18121349600005],
              [104.80019485700006, 9.181070877000062],
              [104.8004701680001, 9.180879458000034],
              [104.80072530700008, 9.180624688000092],
              [104.8008995750001, 9.180369563000033],
              [104.80102847000002, 9.180089079000096],
              [104.80109974700005, 9.179841894000072],
              [104.80113323100008, 9.179586925000114],
              [104.80113299800006, 9.179329793000067],
              [104.80109428300005, 9.179075556000081],
              [104.80099215400004, 9.178757452000051],
              [104.80078984600009, 9.178399554000103],
              [104.80055230000006, 9.178128657000071],
              [104.80030987400008, 9.177936200000135],
              [104.80006100100009, 9.177799122000073],
              [104.79976924700001, 9.177694116000088],
              [104.79951554900008, 9.177643487000074],
            ],
          ],
          [
            [
              [104.93944548000007, 9.538545081000025],
              [104.94406286000003, 9.533999194000041],
              [104.94572396800007, 9.536211675000049],
              [104.948551674, 9.540208776000013],
              [104.95120192899999, 9.54383916200001],
              [104.95297725000006, 9.546283856000047],
              [104.95438483000001, 9.54837403300006],
              [104.95684235900004, 9.551324316000097],
              [104.95789762200005, 9.552591138000109],
              [104.96116411900005, 9.556659839000046],
              [104.96207103400012, 9.555298565000035],
              [104.96408803400003, 9.553763626000132],
              [104.96466562200003, 9.55324535400006],
              [104.96829271600002, 9.54939417500009],
              [104.97101498100002, 9.547513334000078],
              [104.97472138400009, 9.545697958000064],
              [104.97612927200005, 9.544976373000045],
              [104.97830921300003, 9.543939095000019],
              [104.98033017100009, 9.54321752900009],
              [104.98269171500009, 9.542676397000081],
              [104.98500782100005, 9.542293110000076],
              [104.98627889000007, 9.542159996000075],
              [104.98769859500003, 9.542011307000029],
              [104.9884509500001, 9.541852348000111],
              [104.98874678200006, 9.541748859000075],
              [104.98926344900009, 9.541556107000114],
              [104.98987501700006, 9.541193815000069],
              [104.99009950800007, 9.541015848000088],
              [104.99167048000004, 9.540218699000123],
              [104.99271382300003, 9.539689273000105],
              [104.9941445270001, 9.541898970000061],
              [104.99619833100003, 9.54503130100006],
              [104.99763326200011, 9.547231084000083],
              [104.99898903100009, 9.551968908000051],
              [104.99922484800007, 9.552712723000044],
              [104.99942806300001, 9.553457770000049],
              [104.99961580900001, 9.554090364000077],
              [104.99991328500002, 9.555198666000118],
              [105.00000573600002, 9.555543109000018],
              [105.00012417700006, 9.55597276100004],
              [105.00111157700005, 9.559517990000098],
              [105.02253727900009, 9.546573921000066],
              [105.02566405000007, 9.544608131000041],
              [105.0263985230001, 9.54416204200008],
              [105.03860422200006, 9.536748602000065],
              [105.04823541700007, 9.531007338000029],
              [105.0502120360001, 9.529865369000044],
              [105.05914678400005, 9.524703057000037],
              [105.05940141300006, 9.52457583300008],
              [105.06239030900009, 9.522828870000042],
              [105.06298384500009, 9.522485902000087],
              [105.06337679900001, 9.522245660000104],
              [105.07927767600009, 9.512523265000093],
              [105.10314035300007, 9.497929069000042],
              [105.11929562800006, 9.488163537000014],
              [105.13201682000003, 9.480454478000054],
              [105.13367006600004, 9.479453823000126],
              [105.13489339200001, 9.478722040000063],
              [105.13518272300006, 9.478529537000034],
              [105.14288225200002, 9.473836165000103],
              [105.15234328900009, 9.468061046000019],
              [105.17874084500002, 9.451837315000054],
              [105.187894032, 9.446192246000072],
              [105.19027098900003, 9.444678951000094],
              [105.22305646100004, 9.423733253000053],
              [105.23746083100011, 9.414585452000067],
              [105.25846346900006, 9.401131004000083],
              [105.26034637100007, 9.399935199000085],
              [105.27163041300005, 9.396493762000089],
              [105.27453320899998, 9.400640176000062],
              [105.29492731300004, 9.387689632000027],
              [105.2720795110001, 9.364305882000055],
              [105.25262752500001, 9.343877018000056],
              [105.25226447200008, 9.343495684000034],
              [105.25136513600009, 9.344040727000012],
              [105.24743944400009, 9.337638363000076],
              [105.24653052600011, 9.33647119700005],
              [105.24652940800011, 9.334878736000094],
              [105.24684781100008, 9.33190592700006],
              [105.24716719400004, 9.330313239000086],
              [105.24844820500007, 9.32893222100004],
              [105.24930173100007, 9.327339169000124],
              [105.24940795100008, 9.326489781000101],
              [105.2491935410001, 9.325428294000025],
              [105.25517991400008, 9.316737120000072],
              [105.26060881000002, 9.310287785000069],
              [105.26709007400009, 9.30505698800008],
              [105.28285336700006, 9.29093464700008],
              [105.2923044290001, 9.280643769000017],
              [105.28685261300002, 9.275619095000119],
              [105.27914445900005, 9.268491355000029],
              [105.27864589900001, 9.268054729000051],
              [105.276207879, 9.266036456000036],
              [105.26894359400006, 9.261473887000092],
              [105.26564893300005, 9.259400176000019],
              [105.25924490800006, 9.25536913800009],
              [105.25660427900007, 9.253104412000111],
              [105.255889729, 9.251203887000068],
              [105.25590605800002, 9.249378469000112],
              [105.25550143700012, 9.247674072000043],
              [105.25414262800004, 9.245426171000036],
              [105.2531256, 9.244264878000051],
              [105.25162425800005, 9.242550542000048],
              [105.2466000470001, 9.236813308000079],
              [105.24392794300007, 9.233761833000107],
              [105.24171444400007, 9.23123399300003],
              [105.2381498230001, 9.227163004000081],
              [105.23727957600003, 9.226636134],
              [105.23581879100004, 9.225751725000107],
              [105.23304922000004, 9.225184123000039],
              [105.23122799800008, 9.225584755000059],
              [105.23098727800006, 9.22575091500004],
              [105.23095596100003, 9.215681524],
              [105.23095524200006, 9.214573494000076],
              [105.23135299100002, 9.214098365000021],
              [105.23254665800002, 9.21330614500002],
              [105.23397956800001, 9.21314692200008],
              [105.23509387800001, 9.212750471000051],
              [105.23565089000002, 9.212354384000053],
              [105.23692363400006, 9.210770651000109],
              [105.2372675980001, 9.209808280000063],
              [105.23716140900002, 9.209187582000043],
              [105.23779533400007, 9.204755032000021],
              [105.23811282800006, 9.203330211000081],
              [105.238271088, 9.20190548700006],
              [105.23858921200012, 9.201430414000098],
              [105.23946459899999, 9.200954962000106],
              [105.24018097000001, 9.200796192000025],
              [105.25150879000009, 9.198498309000057],
              [105.25244444400005, 9.196126639000022],
              [105.25752266600003, 9.184067790000048],
              [105.25945632300005, 9.179631767],
              [105.26041730700007, 9.179862712000018],
              [105.26369201800004, 9.18170514200005],
              [105.26952474800001, 9.18378442000005],
              [105.27130620800006, 9.183977827000065],
              [105.2719454460001, 9.183732836000045],
              [105.27216660200001, 9.18348814400005],
              [105.27459965800007, 9.180677587000028],
              [105.27479672000003, 9.180463494000078],
              [105.27537619400002, 9.179692717000075],
              [105.27598376400002, 9.178261854000079],
              [105.27701029800004, 9.175817526000072],
              [105.27730873900003, 9.175186172000059],
              [105.2776315560001, 9.174536768000062],
              [105.27783371300005, 9.172222544000073],
              [105.27819849000012, 9.16845672000006],
              [105.27846586100003, 9.163685768000017],
              [105.27794490300001, 9.163416504000031],
              [105.26827298000009, 9.161151638000129],
              [105.26667216800007, 9.160852721000088],
              [105.26531261300002, 9.16042633800007],
              [105.26560951700007, 9.158920104000055],
              [105.26508701000009, 9.15780902100005],
              [105.26352040100005, 9.155735420000076],
              [105.26202834500006, 9.153661774000097],
              [105.26150558400006, 9.15218019400008],
              [105.26180108400008, 9.148623278000033],
              [105.261090655, 9.145289377000053],
              [105.26064211400002, 9.143363159000016],
              [105.2592248660001, 9.141585821000129],
              [105.25691301500004, 9.139364532000103],
              [105.25594374000003, 9.138698330000048],
              [105.25408045300006, 9.138403251000058],
              [105.2521423750001, 9.137737714000059],
              [105.25109820800003, 9.136552871000079],
              [105.25027748100007, 9.135145572000079],
              [105.24967985100001, 9.133071247000133],
              [105.2494543280001, 9.130255679000053],
              [105.2493417200001, 9.129070177000108],
              [105.24814390600002, 9.121142513000089],
              [105.24814334400007, 9.120327429000062],
              [105.24709893700008, 9.118697992000067],
              [105.24627806700001, 9.116994287000056],
              [105.245605977, 9.114919989000024],
              [105.245232528, 9.113660582000092],
              [105.2454182160001, 9.112771267000051],
              [105.24586491500003, 9.112178189000096],
              [105.24683293700009, 9.11114016],
              [105.24817297600003, 9.109286800000048],
              [105.24861890500007, 9.107582233000054],
              [105.2488413830001, 9.106026024000078],
              [105.24876625000009, 9.105136890000052],
              [105.24787158500006, 9.104470622000081],
              [105.24645512600009, 9.103508308000039],
              [105.24474094500002, 9.103064877000032],
              [105.24377208800006, 9.102843231000078],
              [105.24339920600002, 9.102398886000083],
              [105.24339875000007, 9.101732003000061],
              [105.24364599500009, 9.100925854000062],
              [105.24421686600009, 9.099434420000117],
              [105.24525912800007, 9.098025851000012],
              [105.24808972700006, 9.096616070000072],
              [105.25054768100006, 9.095132430000062],
              [105.25121758800006, 9.094094593000078],
              [105.2513630270001, 9.092845150000082],
              [105.25735354400007, 9.094725860000084],
              [105.26066208700007, 9.095380205000046],
              [105.26214589300011, 9.094666551000062],
              [105.26576003300009, 9.090803985000127],
              [105.26949849000006, 9.086820829000073],
              [105.27209254900002, 9.085755078000089],
              [105.27454116400003, 9.086397959000079],
              [105.27832324500005, 9.088402214],
              [105.28211505500008, 9.08967343300011],
              [105.2908187940001, 9.091130545000057],
              [105.29340218100006, 9.091280908000064],
              [105.29647891300007, 9.090470788000092],
              [105.29994552100003, 9.088438509000072],
              [105.30829504500005, 9.079382612000051],
              [105.31277104000002, 9.075286110000123],
              [105.31661366, 9.072647566000034],
              [105.32104334000005, 9.072093534000112],
              [105.32436915600006, 9.071403097000095],
              [105.32807132900005, 9.070106525000108],
              [105.33178293400002, 9.068076953000047],
              [105.33301749100011, 9.067604006000083],
              [105.33436539800009, 9.067865574000054],
              [105.33593529100004, 9.069962639000016],
              [105.33811462500006, 9.072434002000039],
              [105.34007354100004, 9.072947682000086],
              [105.34978941800006, 9.072093770000043],
              [105.35520161500004, 9.071551480000027],
              [105.35680304600002, 9.071205224000066],
              [105.35693995600008, 9.070107348000088],
              [105.3567083880001, 9.069004811000079],
              [105.3548910760001, 9.067026954000122],
              [105.35469967900008, 9.065898411000093],
              [105.35462637300004, 9.065654205000049],
              [105.355171173, 9.064342601000062],
              [105.35740073300009, 9.062904698000105],
              [105.36418553800006, 9.06066909700005],
              [105.37095773500003, 9.059410515000097],
              [105.37415725000008, 9.05896207200003],
              [105.37599953100002, 9.058985228000081],
              [105.37665285900007, 9.059273481000039],
              [105.37688719000002, 9.059376869000067],
              [105.37927685500003, 9.062080782000058],
              [105.38207073300001, 9.064559352000055],
              [105.38927550600008, 9.067948335000063],
              [105.39099504300006, 9.067969824000064],
              [105.39223409500008, 9.067130058000126],
              [105.39422241600002, 9.065322219000025],
              [105.40354018300003, 9.057008128000097],
              [105.40862634100004, 9.053039557000096],
              [105.40949737900009, 9.051477644],
              [105.40905438600002, 9.048377723000021],
              [105.40895939600007, 9.046079827000051],
              [105.40935237100007, 9.044129952000011],
              [105.41146619500009, 9.042079250000066],
              [105.41592402700007, 9.039202363000063],
              [105.41742369900008, 9.037144022000108],
              [105.41732077600008, 9.035554547000045],
              [105.416282822, 9.030166275000083],
              [105.41531168800003, 9.023742191000038],
              [105.41503950100008, 9.02378386200011],
              [105.41453038300006, 9.023861804000076],
              [105.4137539270001, 9.023593627000091],
              [105.41311391300009, 9.022810022],
              [105.41274837600004, 9.020866297000028],
              [105.41277754600006, 9.018539635000076],
              [105.41228045400005, 9.016723579000095],
              [105.41062037500009, 9.0143759960001],
              [105.40639725600008, 9.009152498000093],
              [105.40243985600003, 9.005338819000118],
              [105.39920019000003, 9.001779891000082],
              [105.39659215200007, 8.998228813000074],
              [105.39461888100004, 8.994434328000088],
              [105.393150889, 8.990646161000091],
              [105.39145416700003, 8.984970255000045],
              [105.38952561300007, 8.977657933000048],
              [105.38832948100004, 8.972365378000101],
              [105.38778702900001, 8.967954129000065],
              [105.38775592900001, 8.9677012180001],
              [105.38705642600004, 8.9631766200001],
              [105.38623292000003, 8.958391477000037],
              [105.38355352700003, 8.950567503000071],
              [105.37953162500006, 8.939082976000099],
              [105.377444145, 8.934407813000076],
              [105.37484213400009, 8.930480078000079],
              [105.37149066000008, 8.925914685000079],
              [105.36789002500005, 8.921094845000075],
              [105.366910418, 8.918695277000042],
              [105.36521662200002, 8.912894383000046],
              [105.36305322200008, 8.904323541000013],
              [105.35976348500002, 8.894984761000034],
              [105.35621193300005, 8.886396607000078],
              [105.35191455700003, 8.876919635000123],
              [105.34454280500009, 8.861122254000085],
              [105.34096657600004, 8.854544118000083],
              [105.335910079, 8.845309010000067],
              [105.33243789200003, 8.840490986000036],
              [105.32859212500006, 8.835291297000046],
              [105.32460408000006, 8.831345972000102],
              [105.3198492220001, 8.828144472000064],
              [105.31821190300008, 8.826544991000061],
              [105.31698255400005, 8.825344022000079],
              [105.31399957500005, 8.821788344000046],
              [105.31030208600002, 8.814957413000089],
              [105.30732428600001, 8.811024935000054],
              [105.30084131500007, 8.804911706000061],
              [105.29473562300008, 8.798928857000108],
              [105.28504381600003, 8.787372448000051],
              [105.27260396600005, 8.773770269000101],
              [105.26886718400004, 8.770078860000028],
              [105.26222884000003, 8.76634935200009],
              [105.25419632300004, 8.763103731000042],
              [105.24426332700003, 8.760460738000029],
              [105.23345255500001, 8.757428842000079],
              [105.22359742100004, 8.755284186],
              [105.22118419800003, 8.755181371000019],
              [105.21903309900003, 8.756707816000107],
              [105.21760821900008, 8.757042256000068],
              [105.21690766100004, 8.756846272000106],
              [105.21654694800003, 8.756745357000016],
              [105.21611811300008, 8.755381321000042],
              [105.21551140000005, 8.754540257000086],
              [105.21168038400008, 8.753245407000064],
              [105.20213026700002, 8.750354360000044],
              [105.19258383099999, 8.747211753000082],
              [105.1854327450001, 8.744226644],
              [105.18192807000003, 8.742169775000084],
              [105.17980761200009, 8.740382761000108],
              [105.17732249500008, 8.737586028000075],
              [105.17535058800009, 8.734168244000083],
              [105.1720359390001, 8.727466817000094],
              [105.17006594600008, 8.723923493000106],
              [105.16696255600007, 8.720239194000088],
              [105.162969472, 8.717045148000045],
              [105.15709841800006, 8.712820744000014],
              [105.15173903900009, 8.708100837000108],
              [105.14863101400006, 8.704793083000071],
              [105.14689652600008, 8.702508789000072],
              [105.14332239000002, 8.696431725000052],
              [105.13998289700002, 8.690911377000086],
              [105.13826642100003, 8.688073851000054],
              [105.13321958400007, 8.679088211000073],
              [105.13074106699999, 8.675914618000123],
              [105.12886346800006, 8.674884145000037],
              [105.12785463400009, 8.674870258000043],
              [105.12700207900006, 8.674858518000049],
              [105.126746817, 8.674876488000026],
              [105.12635012500003, 8.674221641000111],
              [105.12637981900009, 8.672087196000076],
              [105.12756537100005, 8.668461735000042],
              [105.12850915700005, 8.664079503],
              [105.12817625200005, 8.660809933000023],
              [105.12694673700001, 8.658532631000069],
              [105.12400255400004, 8.655007800000101],
              [105.11699860600007, 8.646623675000063],
              [105.10714459500009, 8.637094991000092],
              [105.10567823100008, 8.636153855000115],
              [105.10392806000004, 8.635669161000068],
              [105.1015294420001, 8.635267526000055],
              [105.08955462200007, 8.631970089000072],
              [105.08109199000009, 8.628813234000086],
              [105.07411793000003, 8.625032396000075],
              [105.07136535000009, 8.623520514000036],
              [105.07007238600005, 8.623410285000064],
              [105.069328883, 8.623676071000118],
              [105.06839395499999, 8.624399553000027],
              [105.06689648900009, 8.625667596000051],
              [105.06633656000011, 8.626028033000031],
              [105.06448627600005, 8.626094072000077],
              [105.06162035200008, 8.626053719000113],
              [105.05766079000001, 8.624892966000084],
              [105.05269353000007, 8.623073362000076],
              [105.04883703700003, 8.621177285000046],
              [105.04305243000005, 8.61833304700008],
              [105.03736042900007, 8.615489997000035],
              [105.02762626599998, 8.610839792000123],
              [105.02403694500001, 8.609683699000035],
              [105.02035123700006, 8.608802409000036],
              [105.01823173400001, 8.608311743000028],
              [105.01456470800009, 8.606141572000057],
              [105.00705666700006, 8.601061971000055],
              [105.00448977600004, 8.599551948000082],
              [105.00406974900002, 8.59940234600005],
              [105.00071857500004, 8.598208723000054],
              [104.99887253100005, 8.597998027000118],
              [104.99795330800002, 8.598290523000072],
              [104.99569288500004, 8.599301637000066],
              [104.99414178600006, 8.600084292000075],
              [104.99368191300002, 8.600316333000089],
              [104.99207949600003, 8.600690817000077],
              [104.98998101700005, 8.600859340000076],
              [104.98524389800005, 8.600592132000083],
              [104.98370005100003, 8.600371012000084],
              [104.98175923700003, 8.599995050000063],
              [104.97942290600008, 8.599364842000055],
              [104.97753418000012, 8.598840490000013],
              [104.97574307300007, 8.598466642000078],
              [104.97440319900005, 8.597950220000071],
              [104.97311614200005, 8.597235769000109],
              [104.97129046300007, 8.595817715000107],
              [104.96980968300004, 8.594702850000056],
              [104.96779322300009, 8.592685624000039],
              [104.96641519000011, 8.591373441000092],
              [104.96532777600001, 8.590661854000102],
              [104.96314711900007, 8.589636161000056],
              [104.96006842000006, 8.588597359000081],
              [104.95619003400006, 8.587646236000078],
              [104.953054917, 8.587053788000086],
              [104.94932333, 8.586303477000088],
              [104.94698358200002, 8.585921354000091],
              [104.943147842, 8.585467593000116],
              [104.94105461800008, 8.585287810000073],
              [104.93836344900008, 8.585049538000076],
              [104.93597384800007, 8.584666561000097],
              [104.93114698100003, 8.583750741000083],
              [104.92622259200007, 8.58268428300004],
              [104.89613902100007, 8.575579382000063],
              [104.88729255700005, 8.573211199000051],
              [104.88525089800007, 8.572932238000062],
              [104.88246242600009, 8.572542697000072],
              [104.87972384799998, 8.572153865000052],
              [104.87874344100004, 8.572026311000068],
              [104.87713420000003, 8.571816936000063],
              [104.87439189300007, 8.571676456000075],
              [104.86861698700008, 8.570794695000037],
              [104.86219738100006, 8.56965459900003],
              [104.86023224100009, 8.569273448000024],
              [104.85886402000003, 8.569008068000093],
              [104.85517693400004, 8.568654326000045],
              [104.85318600300008, 8.568326119000068],
              [104.851647847, 8.567756253000079],
              [104.84966450500012, 8.566931177000113],
              [104.84703949500008, 8.565649114000083],
              [104.84520053700007, 8.565174043000033],
              [104.84350745800005, 8.564949648000029],
              [104.84126729400006, 8.564710608000057],
              [104.83837704600002, 8.564535845000075],
              [104.83475871900004, 8.564677403000063],
              [104.83153177600012, 8.565021299000096],
              [104.82796812200004, 8.565883880000056],
              [104.82314542600008, 8.567382052000115],
              [104.82162045400003, 8.568210088000059],
              [104.82055158100005, 8.569307004000047],
              [104.819739595, 8.570800738000093],
              [104.81879914400008, 8.572096057000065],
              [104.81720233400003, 8.573315880000097],
              [104.81480860200004, 8.575047392000011],
              [104.81248662500003, 8.576387080000101],
              [104.80719329400003, 8.578532580000074],
              [104.80104424100008, 8.580730305000104],
              [104.79661645200011, 8.582168496000085],
              [104.79232312600006, 8.58341220400005],
              [104.78935369400003, 8.584086852],
              [104.78546287200007, 8.584812742000024],
              [104.77722198900007, 8.586191721000079],
              [104.76897894800007, 8.587701329000106],
              [104.76218618500008, 8.588971182000103],
              [104.75730187100007, 8.59013953500005],
              [104.75241235700007, 8.591635128000073],
              [104.74871436300008, 8.592625301000021],
              [104.74613703799999, 8.593436430000077],
              [104.7410491090001, 8.594994157000089],
              [104.73291622900007, 8.597813844000067],
              [104.72749314800006, 8.599758939000067],
              [104.72292463600007, 8.601717333000101],
              [104.7204045340001, 8.603052974000123],
              [104.71900829500004, 8.604013337000092],
              [104.71786566700008, 8.60556708400013],
              [104.71678668600008, 8.607252810000075],
              [104.71590493300003, 8.608941644000099],
              [104.71514528800006, 8.611221810000112],
              [104.71478219000005, 8.613377241000107],
              [104.71447547000007, 8.616122992],
              [104.71454969600008, 8.619726133000059],
              [104.71460589500002, 8.621584162000049],
              [104.71433171300008, 8.623408274000076],
              [104.71352503400003, 8.626259105000054],
              [104.7121485150001, 8.63010142500009],
              [104.71164691300007, 8.632414814000098],
              [104.71149664600007, 8.633591315],
              [104.71147960700009, 8.63493732500009],
              [104.7122290610001, 8.637437874000041],
              [104.71413820100005, 8.641463054000019],
              [104.71561833900003, 8.643516676000028],
              [104.717369859, 8.645050606000025],
              [104.71951699800012, 8.646525268000092],
              [104.72212863900002, 8.647745220000045],
              [104.72389270500005, 8.648493379000042],
              [104.72638429400007, 8.648990951000089],
              [104.72855435200003, 8.649024996000064],
              [104.73131935400001, 8.648871861000094],
              [104.73435256800006, 8.648395416000108],
              [104.73791700300002, 8.647599755000089],
              [104.73996483300009, 8.647042327000063],
              [104.74135713400008, 8.646343641000074],
              [104.74301761599999, 8.64532166400007],
              [104.74468220100005, 8.644037771000066],
              [104.74608787300009, 8.642487853000079],
              [104.74703115400007, 8.641061707000029],
              [104.74752135100005, 8.639170045000098],
              [104.74816366300007, 8.635970897000076],
              [104.74838257700006, 8.634598970000072],
              [104.74891686000004, 8.634083352000042],
              [104.74937713600011, 8.634090525000103],
              [104.75041891200007, 8.634761682000123],
              [104.75321859600007, 8.636573590000028],
              [104.75458299900008, 8.637642704000113],
              [104.75496930800007, 8.638172665000086],
              [104.75495799400002, 8.638892907000082],
              [104.75494256500008, 8.639875064000078],
              [104.75492816500004, 8.640791748000074],
              [104.75531242400004, 8.641452659000047],
              [104.75628849100006, 8.642122783000071],
              [104.75733444200009, 8.642532003],
              [104.76060896600005, 8.64343429700005],
              [104.77744437299999, 8.647821209000115],
              [104.783207729, 8.649416534000041],
              [104.78648349400005, 8.650252960000058],
              [104.79367340500005, 8.653179877000115],
              [104.79563709700007, 8.653799518000048],
              [104.7992377250001, 8.654902715000022],
              [104.80198958300005, 8.655599873000062],
              [104.80323905500005, 8.655619025000048],
              [104.80482645600003, 8.655053895],
              [104.80582199500007, 8.654479684000103],
              [104.80754596000007, 8.653589143000049],
              [104.80927901300002, 8.652109283000085],
              [104.80978282200005, 8.65178586600006],
              [104.81013996400003, 8.651729478000013],
              [104.81066301600005, 8.651933957000056],
              [104.81196408800005, 8.65287077500003],
              [104.81314677400003, 8.652954338000106],
              [104.8140674310001, 8.652968401000026],
              [104.81525718500004, 8.652593605000012],
              [104.81558194700008, 8.652860545000072],
              [104.81557286200004, 8.653449854000071],
              [104.81464009500004, 8.654221552000061],
              [104.81292221700002, 8.654719278000032],
              [104.81087551900005, 8.655211955000118],
              [104.80948137000007, 8.656042071000057],
              [104.80681445000003, 8.658359100000011],
              [104.80394410400004, 8.661065972000069],
              [104.80006185500007, 8.665394711000117],
              [104.79718624700008, 8.668428928000081],
              [104.793832905, 8.672569261000083],
              [104.78987631900002, 8.677420766000042],
              [104.78866499600007, 8.679170580000049],
              [104.78784419200011, 8.681188407000116],
              [104.78707998300011, 8.683796606],
              [104.78582070500001, 8.688624184000025],
              [104.78525783500007, 8.690973517000076],
              [104.78464549100002, 8.692274094000128],
              [104.78350388900007, 8.693763005000042],
              [104.78216086000006, 8.695510815000061],
              [104.78168207100006, 8.696682453000014],
              [104.78165146000001, 8.698647014000114],
              [104.78266510400005, 8.701151711000053],
              [104.78367775400007, 8.703721899000081],
              [104.78462567100007, 8.706225599000026],
              [104.78569395500008, 8.709451689000057],
              [104.78696675000006, 8.71222243000007],
              [104.78831247800012, 8.714535766000074],
              [104.78966638900006, 8.716325203000073],
              [104.79179850900007, 8.718847154000018],
              [104.7937384200001, 8.721038621000064],
              [104.79496694500004, 8.722433096000069],
              [104.7976963240001, 8.72463665900005],
              [104.79880551200003, 8.725243228000055],
              [104.799857042, 8.725324861000059],
              [104.80159102000003, 8.724877714000099],
              [104.80389159500004, 8.724023103000087],
              [104.80648475800011, 8.725614737000063],
              [104.809374233, 8.727388182000071],
              [104.80840977800004, 8.728056137000094],
              [104.80827425100003, 8.728738382000055],
              [104.80851358600002, 8.729426354000045],
              [104.80962182000005, 8.730500870000045],
              [104.81160665400006, 8.731464368],
              [104.81328871900006, 8.73180112500007],
              [104.81435082600009, 8.73181734500008],
              [104.81498326700002, 8.731329314000023],
              [104.81531969000005, 8.73103707000009],
              [104.82164373100008, 8.734918012000035],
              [104.82151012700004, 8.736168352000016],
              [104.82176856000011, 8.736915584000114],
              [104.82211810800007, 8.737274375000046],
              [104.8232529890001, 8.737362356000043],
              [104.82439221700011, 8.737167611000094],
              [104.82637182400003, 8.736901313000088],
              [104.827684246, 8.736921272000052],
              [104.82908235800005, 8.737074359000106],
              [104.83161071400008, 8.73768459900006],
              [104.83869702500003, 8.739788454000051],
              [104.84965981300009, 8.742711360000042],
              [104.85751500200006, 8.744526508000126],
              [104.85935134800012, 8.745190410000079],
              [104.86280247700003, 8.74708041000005],
              [104.86617623100003, 8.749393381000024],
              [104.86744461800006, 8.750048687000065],
              [104.86836229800006, 8.750415946000077],
              [104.86906481800001, 8.750921357000062],
              [104.870382814, 8.752991326000091],
              [104.8723227400001, 8.75620176700007],
              [104.87482971300005, 8.759491397000117],
              [104.87587448300008, 8.760850298000021],
              [104.87690537100011, 8.763128057000101],
              [104.87765652100009, 8.765118843000062],
              [104.87866009200008, 8.768148921000128],
              [104.87996753800007, 8.772087223000112],
              [104.88091537900007, 8.774496194000035],
              [104.88229236300005, 8.777455867000048],
              [104.88357236100009, 8.779597676000071],
              [104.88448038800007, 8.781026360000064],
              [104.88523262000005, 8.78190844900004],
              [104.88651905900009, 8.783257628000028],
              [104.88720524400003, 8.783645609000066],
              [104.88864036100001, 8.784457038000058],
              [104.88960115000002, 8.784814820000085],
              [104.89201500600008, 8.78491948400004],
              [104.89360193800012, 8.784943114000042],
              [104.89476868900002, 8.785372636000053],
              [104.89579229600007, 8.786143483000062],
              [104.89646059000003, 8.78759596300007],
              [104.89705474300007, 8.789390813000074],
              [104.89778587299999, 8.791256404000068],
              [104.8995282930001, 8.794716975000048],
              [104.90032227200007, 8.796995668000117],
              [104.90066649200004, 8.800156718],
              [104.90049681500003, 8.803045724000034],
              [104.89979129600002, 8.805900552000077],
              [104.89933362100007, 8.807103816000071],
              [104.89875603300007, 8.807828486000078],
              [104.89807276000002, 8.808043940000053],
              [104.89728294700004, 8.807806589000043],
              [104.89650076500007, 8.80706170600005],
              [104.89613914299998, 8.806074862000022],
              [104.89565288600008, 8.803572180000073],
              [104.89548429200008, 8.803092087000051],
              [104.89521926200008, 8.802707971000066],
              [104.89490599600001, 8.802395854000087],
              [104.89464090300001, 8.80217975800006],
              [104.88907366100008, 8.799489885000076],
              [104.88779637800009, 8.798745435000031],
              [104.88430178800004, 8.797208223000029],
              [104.88324133400005, 8.796859861000071],
              [104.88184349900004, 8.796331373000042],
              [104.87337189300004, 8.793303335000056],
              [104.873015, 8.792880627000075],
              [104.8722442860001, 8.792496765000111],
              [104.86815915600005, 8.791459604000094],
              [104.86449806800007, 8.790307471000068],
              [104.8501624510001, 8.785161675],
              [104.83721438400001, 8.780821513000079],
              [104.82350393200008, 8.775422609000126],
              [104.8134653380001, 8.773346024000082],
              [104.80929465700007, 8.773375077000088],
              [104.8089858120001, 8.773421966000059],
              [104.80869106900005, 8.77349998800012],
              [104.80617575400004, 8.774475959000048],
              [104.80430887500002, 8.775276333000102],
              [104.802952831, 8.776037868000051],
              [104.80114446300003, 8.777620055000044],
              [104.79418481500008, 8.785922862000104],
              [104.7940864330001, 8.786186672000083],
              [104.78567588600002, 8.796638756000073],
              [104.78440240700007, 8.799361656000125],
              [104.78291437800006, 8.80289969900012],
              [104.78224099100004, 8.804500761000076],
              [104.78089024700009, 8.80745375300007],
              [104.77942328800003, 8.811327335000028],
              [104.77911341100007, 8.813897330000129],
              [104.77887990400002, 8.81765655300004],
              [104.77907121600003, 8.820035037000105],
              [104.77972299400005, 8.8259046100001],
              [104.78002993100006, 8.828321530000059],
              [104.78098984100006, 8.834651606000053],
              [104.78118180300005, 8.835955991000065],
              [104.78202748900004, 8.839945995000063],
              [104.78302731100007, 8.844051174000088],
              [104.78410446400002, 8.847772787000084],
              [104.78506599200009, 8.851532697000051],
              [104.78575841200009, 8.854026544000069],
              [104.78886108600004, 8.865905050000043],
              [104.79098971000003, 8.874053802000057],
              [104.791989691, 8.878197312000056],
              [104.79318198100006, 8.883184869000081],
              [104.79387449300008, 8.885717054000073],
              [104.79414330900002, 8.88759686500009],
              [104.7944126300001, 8.888556041000049],
              [104.79498936800003, 8.89135668500005],
              [104.79614246300005, 8.897648474000073],
              [104.79729524200005, 8.904592367000051],
              [104.79783126600007, 8.911382497000096],
              [104.79813852800001, 8.913530848000038],
              [104.79833010800004, 8.915717512000041],
              [104.79963520700002, 8.925141262000034],
              [104.79978898600005, 8.927111414000065],
              [104.79971009400001, 8.93037201900002],
              [104.79982340200006, 8.934706829000037],
              [104.79997494400007, 8.939617067000059],
              [104.80008964900006, 8.941381707000049],
              [104.80024252500002, 8.943875243000131],
              [104.80024193800006, 8.94494933],
              [104.80058739500004, 8.947826557000036],
              [104.80085524100008, 8.951624406000034],
              [104.80100743400006, 8.955383817000049],
              [104.801349074, 8.965319388000113],
              [104.80165451500004, 8.970920192000074],
              [104.80176878100008, 8.973528769000081],
              [104.801960002, 8.976444279000083],
              [104.80213974100005, 8.98123697200006],
              [104.80221129200001, 8.983144869000091],
              [104.80171965500001, 8.992977511000076],
              [104.80173796500002, 8.995066656000093],
              [104.80167921000005, 8.996429942000093],
              [104.80156225200005, 8.998769868000077],
              [104.80133650700002, 9.000000213000091],
              [104.80049724000003, 9.010974424000027],
              [104.8001824510001, 9.020519353000099],
              [104.80048921000008, 9.025599958],
              [104.80141668200008, 9.027755811000036],
              [104.80311812900008, 9.029758113000062],
              [104.80543919800002, 9.03083703300007],
              [104.81158333399999, 9.031652084000095],
              [104.81256325500007, 9.031662169000041],
              [104.81419028100004, 9.032319867000103],
              [104.815338436, 9.033497506000019],
              [104.81545709200006, 9.03424382000013],
              [104.815588593, 9.035070911000085],
              [104.81522983200009, 9.036089086000041],
              [104.81460048100006, 9.037118823000066],
              [104.81434889700002, 9.03734858700007],
              [104.81369714900009, 9.037959183000053],
              [104.81287801100009, 9.038310517000079],
              [104.81099784300004, 9.038920473000099],
              [104.80639707500005, 9.040240022000122],
              [104.80571256500008, 9.040590974000027],
              [104.80350399400004, 9.042565905000098],
              [104.80308419800012, 9.043268295000038],
              [104.80224447800001, 9.044848731000064],
              [104.80184659600003, 9.045814620000046],
              [104.80180206700008, 9.046517209000092],
              [104.80178979300004, 9.048767770000053],
              [104.80178926800009, 9.049733867000052],
              [104.80177325100001, 9.052848745000029],
              [104.80248509700002, 9.059555498000103],
              [104.8031890610001, 9.066187648000044],
              [104.80335498900006, 9.068951947000057],
              [104.8037045180001, 9.072765406000103],
              [104.80390364600007, 9.076062580000064],
              [104.80401958600007, 9.07837725400009],
              [104.80448651900006, 9.081841086000074],
              [104.80482016500005, 9.084105913000061],
              [104.8051030880001, 9.087286572000062],
              [104.80536794900007, 9.092915026000091],
              [104.80571617000001, 9.099226257000035],
              [104.80581558000007, 9.101191233000082],
              [104.80613177400004, 9.104804846000112],
              [104.80619714500003, 9.107768904000045],
              [104.807060951, 9.120657866000046],
              [104.80724357700009, 9.123572035000025],
              [104.80737467000002, 9.12893398700003],
              [104.80743727500004, 9.137093399000039],
              [104.80757047000006, 9.13852552400005],
              [104.80763423900001, 9.144520205000093],
              [104.80761415600003, 9.150731307000102],
              [104.80741172700007, 9.153462096000096],
              [104.80712359100002, 9.159839575000104],
              [104.80718646100007, 9.167466120000077],
              [104.80708444300008, 9.170330171000083],
              [104.80678197100009, 9.172195005000015],
              [104.80636759000001, 9.17558432100005],
              [104.80615026500006, 9.178218494000044],
              [104.80622850900005, 9.178706370000088],
              [104.80626584700011, 9.182218781000094],
              [104.80646139700004, 9.183526280000097],
              [104.80788184000006, 9.188083382000032],
              [104.80872423100004, 9.190971805000102],
              [104.80946863400008, 9.193626006000065],
              [104.81039913100007, 9.196953517000049],
              [104.81077121700012, 9.198534288000102],
              [104.81121179600007, 9.200476086],
              [104.81160316000003, 9.202700810000051],
              [104.81231751900008, 9.206574577000113],
              [104.81280760600004, 9.210227834000049],
              [104.81354986200007, 9.214789471000094],
              [104.81435191200009, 9.2198884430001],
              [104.81512973700006, 9.225417246000054],
              [104.81590758700003, 9.230946053000098],
              [104.81651757700004, 9.235925525000075],
              [104.81681587800003, 9.239758547000053],
              [104.81695892700006, 9.241860126000033],
              [104.81744835400005, 9.247770873000059],
              [104.81755442000004, 9.249311074000042],
              [104.81772430500006, 9.24984276900007],
              [104.81791833300004, 9.251441937000047],
              [104.81876519100003, 9.258421568000051],
              [104.81921259100001, 9.262952576000048],
              [104.81916350100003, 9.263870793000082],
              [104.819174897, 9.26535689200003],
              [104.81914977100006, 9.266951716000092],
              [104.81935446400011, 9.270733509000094],
              [104.81935360200004, 9.272424999000071],
              [104.81940124800008, 9.274333988000103],
              [104.81944826900006, 9.275086522000018],
              [104.81979911300004, 9.280701433000081],
              [104.82022230500007, 9.285111599000027],
              [104.82034311900003, 9.286561506000089],
              [104.82076646700006, 9.290681695000023],
              [104.82100882600004, 9.292180003000093],
              [104.82223884000003, 9.311373728000126],
              [104.82257117900008, 9.315695914000076],
              [104.82280043000004, 9.32543718600005],
              [104.82299832200007, 9.331022652000074],
              [104.82312988500003, 9.335477721000094],
              [104.823294726, 9.340298515000088],
              [104.82322043200006, 9.346987223],
              [104.82306908800007, 9.349315091000053],
              [104.82342759800007, 9.353045960000104],
              [104.82490605, 9.369387106000103],
              [104.82517407100006, 9.373953607000106],
              [104.825306525, 9.379370627000048],
              [104.82557455200006, 9.38393711600005],
              [104.82593101200007, 9.391906023000017],
              [104.82613797200005, 9.395013912000126],
              [104.82632992000003, 9.397938337000065],
              [104.82650751600008, 9.403669269000135],
              [104.82650623300006, 9.406445180000043],
              [104.82681895700009, 9.412310586000048],
              [104.82707732200006, 9.41333373300006],
              [104.82724087600009, 9.413585816000046],
              [104.82748875800009, 9.413797573000069],
              [104.82777976600001, 9.413926799000103],
              [104.82840492400004, 9.41410268300009],
              [104.82898887000005, 9.414236343000047],
              [104.82936357600005, 9.414366827],
              [104.82960903400001, 9.414736413000034],
              [104.82922395900005, 9.415279922000062],
              [104.8287147500001, 9.415729805000071],
              [104.82828922800006, 9.416180956000096],
              [104.82807230600005, 9.416676870000094],
              [104.82798455500007, 9.417488922000048],
              [104.8280213030001, 9.421072342000068],
              [104.82878136000001, 9.437274560000029],
              [104.82938882700009, 9.453747857000037],
              [104.82948011100009, 9.46211235800005],
              [104.82983906200005, 9.471489820000032],
              [104.83039318200005, 9.478962284000135],
              [104.83083024500007, 9.487519600000026],
              [104.83138246100002, 9.496038854000053],
              [104.83155093500002, 9.504634141000066],
              [104.83227137200004, 9.522358523000028],
              [104.8328968860001, 9.53189009400006],
              [104.83476929200003, 9.531790247000059],
              [104.83536081900003, 9.531771331000092],
              [104.83585522599999, 9.531802981000046],
              [104.83702893300003, 9.531814220000088],
              [104.83925531000003, 9.531816173000072],
              [104.84134896000006, 9.53123815600012],
              [104.84373945000006, 9.53039393900008],
              [104.84511555100005, 9.529001700000082],
              [104.84684824900006, 9.52613019300008],
              [104.84735277600006, 9.525385570000109],
              [104.84854915500003, 9.523771689000069],
              [104.84887443900003, 9.523360639000076],
              [104.84931197700008, 9.522807723000069],
              [104.85170400400003, 9.519870996000089],
              [104.85510529800007, 9.516506507000065],
              [104.856373332, 9.515348882000028],
              [104.85672495300005, 9.515027874000024],
              [104.85840924400006, 9.513578374000085],
              [104.859158886, 9.512938599000085],
              [104.86001919600004, 9.512204368000086],
              [104.86031949300011, 9.511958968000032],
              [104.86305505600005, 9.509838637000099],
              [104.86986490900004, 9.504461884000113],
              [104.8705965180001, 9.503866022000105],
              [104.87329078500008, 9.501428220000017],
              [104.87486389800003, 9.499905850000088],
              [104.87701636100005, 9.497762491000026],
              [104.87944682700007, 9.495332498000051],
              [104.87965832600007, 9.49518769200006],
              [104.88220153100008, 9.500020658000084],
              [104.88286207800012, 9.501382257000033],
              [104.88377169800006, 9.503096579000035],
              [104.88435164200008, 9.504218237000115],
              [104.88541844000007, 9.506337660000062],
              [104.88645060900002, 9.508358514000024],
              [104.88753851700007, 9.510488458000042],
              [104.88798871500011, 9.511340817000045],
              [104.88831340000006, 9.511981431000026],
              [104.88977465199999, 9.514863573000108],
              [104.89153517500009, 9.518216897000096],
              [104.89189548900005, 9.518903186000037],
              [104.89214316300003, 9.519395657000105],
              [104.89385301700001, 9.52279543800003],
              [104.89965332000006, 9.533955210000043],
              [104.90024033000003, 9.535077611000096],
              [104.90719445400006, 9.548363589000084],
              [104.909051886, 9.55187979700005],
              [104.91535799800006, 9.564121562000059],
              [104.91714330600007, 9.56063822300006],
              [104.91931274600007, 9.558524294000035],
              [104.92264934400001, 9.555163951000088],
              [104.92437470300004, 9.553453224000029],
              [104.92715301700005, 9.550654936000045],
              [104.93109834400008, 9.54692796100005],
              [104.93317882900001, 9.544752824000023],
              [104.93476455300002, 9.543152012000087],
              [104.93780907200005, 9.54017034400003],
              [104.93944548000007, 9.538545081000025],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 13, code: "AD01", ten_tinh: "Cần Thơ" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.59810952400009, 10.239508555000041],
              [105.60243902900002, 10.234073270000097],
              [105.60507947800001, 10.230673649],
              [105.60769578000003, 10.227304994000084],
              [105.6116102930001, 10.221153566000039],
              [105.61501613600007, 10.21338512600005],
              [105.61551935900005, 10.212237273000111],
              [105.61757427200008, 10.206294102000067],
              [105.62075149000006, 10.193078258000108],
              [105.62301441100007, 10.187953877000043],
              [105.62682848300008, 10.183645709000098],
              [105.64023411400011, 10.171331589000053],
              [105.64703922400005, 10.164866928000025],
              [105.64982822100008, 10.161406441000052],
              [105.65456132400001, 10.155533472000043],
              [105.6572675840001, 10.152141188000117],
              [105.6601252020001, 10.148559039000114],
              [105.66198138800004, 10.147019280000078],
              [105.68084312000005, 10.13504828000009],
              [105.68189089200006, 10.134383222000041],
              [105.70509040800006, 10.119788505000125],
              [105.70646433700006, 10.118924038],
              [105.70932862600007, 10.117121896000112],
              [105.7144847600001, 10.113424326000063],
              [105.72304798800005, 10.106339520000061],
              [105.72788682800011, 10.102335761000079],
              [105.732320945, 10.099253944000084],
              [105.742741107, 10.094417840000068],
              [105.74810539700012, 10.091743329000023],
              [105.75109662400007, 10.090098130000056],
              [105.75233154400011, 10.089091627000089],
              [105.76016761800001, 10.08270459300009],
              [105.76418732600007, 10.079316137000125],
              [105.77674785900007, 10.072540579000043],
              [105.78052313400008, 10.069866168000095],
              [105.7828529010001, 10.068213338000119],
              [105.78614905900007, 10.064723955000064],
              [105.79255960900005, 10.0558716360001],
              [105.79299854900005, 10.055265477000129],
              [105.79469143000006, 10.052927668000054],
              [105.79745381900008, 10.049067308000035],
              [105.80020281200008, 10.045225514000043],
              [105.80154339000006, 10.043351964000101],
              [105.80467352100005, 10.038977343000075],
              [105.81259379000008, 10.026670188000073],
              [105.82153643300006, 10.011060255000091],
              [105.82328523700008, 10.008007337],
              [105.82963589200008, 10.000019902000099],
              [105.83372063200002, 9.994951629000058],
              [105.83531311800006, 9.992975654000079],
              [105.84258099200011, 9.984050861000069],
              [105.84290347900006, 9.983684874000074],
              [105.83796024700007, 9.980440517000131],
              [105.83688797100007, 9.979304888000049],
              [105.83429793000009, 9.979311379000054],
              [105.83321114000005, 9.979065501000049],
              [105.83124456500005, 9.977827450000108],
              [105.82856572600009, 9.975762523000052],
              [105.82672180000003, 9.973446908000126],
              [105.82245374400003, 9.970640084000058],
              [105.81893852200005, 9.968162797000078],
              [105.81726241800001, 9.966095303000076],
              [105.81695765000002, 9.965409978000126],
              [105.80183185300004, 9.963350986000117],
              [105.78225112300005, 9.959704041000082],
              [105.77945987800003, 9.959222524000031],
              [105.77747637400005, 9.95902749700006],
              [105.77609240600002, 9.958842150000089],
              [105.77574414500008, 9.957833591000041],
              [105.77541840000004, 9.956592053000078],
              [105.77516371600009, 9.95614896700001],
              [105.77500172600001, 9.955905326000012],
              [105.77472455700008, 9.955706312000066],
              [105.7744476980001, 9.955640406000031],
              [105.77410181699999, 9.955641206000097],
              [105.77375599600006, 9.955664192000045],
              [105.77341022700006, 9.955709354000051],
              [105.77294948300003, 9.955887887000079],
              [105.77251189400006, 9.956110737000044],
              [105.77189010100004, 9.956444929000073],
              [105.76092163500005, 9.959642376000131],
              [105.76055442900004, 9.959649434000093],
              [105.75903162200008, 9.96018863300012],
              [105.75688989400007, 9.960543716000101],
              [105.75568559600006, 9.960759840000042],
              [105.75388336200008, 9.961083271000069],
              [105.74476232000009, 9.965365836000027],
              [105.74001929200004, 9.968398650000053],
              [105.73713286200005, 9.97024424200008],
              [105.73508774700012, 9.971449772000083],
              [105.73373517200012, 9.970323429000059],
              [105.72984436900006, 9.970417714000044],
              [105.72820284000005, 9.971021611000095],
              [105.72742653600004, 9.971880886000049],
              [105.72630589500004, 9.973426983000076],
              [105.72337148500007, 9.975920359000055],
              [105.72291901700002, 9.976156673000068],
              [105.72199873900009, 9.976637310000045],
              [105.72184640500009, 9.974455551000093],
              [105.72085092700007, 9.97217514900006],
              [105.71695623600004, 9.966710998000037],
              [105.71431503700009, 9.963277870000107],
              [105.70951509700005, 9.958938535000071],
              [105.70909510600004, 9.958516676000071],
              [105.70225830600003, 9.959576230000076],
              [105.7018833990001, 9.955150021000046],
              [105.69343497600006, 9.956441453000052],
              [105.69279926700005, 9.956328170000093],
              [105.69216234700011, 9.95564183600006],
              [105.69146694900003, 9.9546118010001],
              [105.6908301600001, 9.953982772000101],
              [105.68626228700008, 9.952043835000078],
              [105.68545791400004, 9.951800952000054],
              [105.68192686, 9.950734698000117],
              [105.67891977600007, 9.949250905],
              [105.67457982800008, 9.945706814000042],
              [105.66889430600003, 9.940264663000063],
              [105.66826835300002, 9.940816904000103],
              [105.666345619, 9.942519503000112],
              [105.66250048000009, 9.946109985000094],
              [105.65699410300003, 9.951063163000116],
              [105.66003021300007, 9.953752849000084],
              [105.66116675400005, 9.954781144000078],
              [105.66468206700003, 9.958033464000058],
              [105.664975892, 9.958302165000086],
              [105.66539207100007, 9.95865620700007],
              [105.66613835400008, 9.959291064000107],
              [105.66814509400004, 9.961112372000079],
              [105.66864997600007, 9.961545677000048],
              [105.66937725900002, 9.962272120000113],
              [105.67000311000002, 9.962932280000112],
              [105.67090813000003, 9.963872522000104],
              [105.67111452900005, 9.964118857000058],
              [105.6714617870001, 9.9645723610001],
              [105.67194330400005, 9.965207545000075],
              [105.67090720800005, 9.965891586000096],
              [105.66926984900002, 9.966346225000054],
              [105.66635937200007, 9.967345037000095],
              [105.65769205500006, 9.967362219000096],
              [105.65842858400006, 9.971763358000095],
              [105.65059300500012, 9.974424450000095],
              [105.64549000100004, 9.976939760000024],
              [105.64535261900005, 9.976503738000096],
              [105.64462162700005, 9.975060808000078],
              [105.64443782400011, 9.974158449000113],
              [105.64461825900003, 9.97334564],
              [105.64525378500004, 9.972531974],
              [105.64543439400006, 9.97180945700009],
              [105.64525059000005, 9.970907086000034],
              [105.64405885200003, 9.966576350000088],
              [105.6425976570001, 9.964051555000085],
              [105.64027156100011, 9.961392999000028],
              [105.63603492600004, 9.959099161000038],
              [105.63480624300007, 9.959101497000054],
              [105.63357938100008, 9.960051681000069],
              [105.6324890330001, 9.961001607000062],
              [105.63139711000008, 9.961139072000067],
              [105.63030285800005, 9.960057879000049],
              [105.62838637600001, 9.957353315000093],
              [105.62742788500003, 9.955865635000103],
              [105.62742478600003, 9.95424074000004],
              [105.62728464600008, 9.952345289000062],
              [105.62605185300004, 9.950181072000051],
              [105.61737367800001, 9.94654015700007],
              [105.6128255560001, 9.951409841000023],
              [105.61192736900003, 9.952368166],
              [105.60323842100009, 9.961638296000073],
              [105.60043114800007, 9.964662812000123],
              [105.59742108200007, 9.967863127000063],
              [105.59713742400004, 9.968166715000047],
              [105.58496130000005, 9.981251824000113],
              [105.58238366900011, 9.984078611000056],
              [105.58077705900006, 9.984913144000094],
              [105.57909694300004, 9.985845098000111],
              [105.57929472600006, 9.986517253000036],
              [105.58133637100005, 9.990946629000062],
              [105.58156418400007, 9.991440861000099],
              [105.57257388200006, 9.995315160000116],
              [105.57033883500006, 9.990484341000093],
              [105.56778724300005, 9.984969088000105],
              [105.56099014600005, 9.987820696000059],
              [105.54923184299999, 9.961858375000075],
              [105.55413211700008, 9.959795917000045],
              [105.54840985100002, 9.953397549000085],
              [105.54895267300007, 9.95291084300005],
              [105.54372586900006, 9.941200500000134],
              [105.54005804700007, 9.941959891000083],
              [105.53665728600006, 9.934418858000081],
              [105.53316863100008, 9.926503257000084],
              [105.53089529600001, 9.920140770000073],
              [105.51987218700002, 9.93050264900006],
              [105.50207182100007, 9.946742410000107],
              [105.49365462600005, 9.954420784000105],
              [105.47803172800006, 9.968999152],
              [105.477362102, 9.969607116000121],
              [105.44387923700002, 10.000000158000065],
              [105.44301213200004, 10.001301660000104],
              [105.44246609300006, 10.00185241500011],
              [105.43654596800006, 10.007558675],
              [105.43057975800006, 10.013171868000102],
              [105.42456549000009, 10.018781202000058],
              [105.42382845700004, 10.019469516000077],
              [105.41653760900004, 10.026286394000026],
              [105.41404872500007, 10.028695221000115],
              [105.40952305800008, 10.032939924000049],
              [105.40499818900005, 10.037181796000104],
              [105.39804530600004, 10.043519936000054],
              [105.39743734700001, 10.042513446000088],
              [105.38391528900003, 10.055219085000109],
              [105.37457248100007, 10.063509891000065],
              [105.36264212300007, 10.074373405000079],
              [105.36233553500001, 10.074735522000109],
              [105.35503254400008, 10.083360925000038],
              [105.35319667800006, 10.092827869000052],
              [105.3526251210001, 10.095428304000096],
              [105.35141253400005, 10.101433536000036],
              [105.34740282800003, 10.106226786000059],
              [105.34386657500009, 10.1102656080001],
              [105.34323320300008, 10.110988973000088],
              [105.34011453600006, 10.114717650000047],
              [105.33762199600007, 10.117586893000039],
              [105.31532358300009, 10.142346005000123],
              [105.30891053199998, 10.135532205000073],
              [105.30477308600004, 10.140108528],
              [105.30516122100001, 10.140507996000013],
              [105.30156246400006, 10.144479723000098],
              [105.29633321800002, 10.139392306000071],
              [105.28660822800006, 10.149839953000049],
              [105.27562329000003, 10.160277323000065],
              [105.26261463500008, 10.170840921000133],
              [105.22400006900004, 10.202041739000032],
              [105.22981427900008, 10.210101822000082],
              [105.23140797900004, 10.21231108500009],
              [105.24439136300005, 10.230307351000077],
              [105.25114221100003, 10.239318615000046],
              [105.25146852500004, 10.239631832000027],
              [105.25902676600003, 10.246886346000037],
              [105.265125674, 10.239503141000037],
              [105.28722006600006, 10.260789708000038],
              [105.28546448200009, 10.263581877000137],
              [105.29940895100003, 10.278135496000028],
              [105.30461316300006, 10.269867017000054],
              [105.31047980600007, 10.275280968000098],
              [105.32916668100009, 10.244181472000056],
              [105.32930042400005, 10.243963112000108],
              [105.33457724300003, 10.235287631000032],
              [105.33551807300006, 10.235579297000095],
              [105.36426507500006, 10.244390550000036],
              [105.38663386300007, 10.251069518000032],
              [105.39773728900005, 10.254383828000078],
              [105.39877107500006, 10.25474548400007],
              [105.39954782300002, 10.255054597000067],
              [105.41286612700002, 10.260330089000112],
              [105.42572899400008, 10.265469678000015],
              [105.42666503500007, 10.2658453380001],
              [105.43966488300002, 10.270699193000075],
              [105.43722751000006, 10.276210798000116],
              [105.42489701300008, 10.304089424000027],
              [105.46697268800004, 10.31175677500009],
              [105.47947014000006, 10.314996842000122],
              [105.48115581100009, 10.31615064000002],
              [105.48208474500004, 10.316843048000111],
              [105.48248056600002, 10.317255422000036],
              [105.48289409500003, 10.317998147000116],
              [105.48342865800004, 10.319236253000062],
              [105.48394646400007, 10.320755194000062],
              [105.48417719800005, 10.321558569],
              [105.48443016100003, 10.322439368000056],
              [105.4849655460001, 10.324206065000039],
              [105.48518959200007, 10.324635208000108],
              [105.48543043400011, 10.324816554000014],
              [105.48885216000006, 10.326643327000083],
              [105.49295568300005, 10.327878608000061],
              [105.49450250800004, 10.326213199000021],
              [105.51194139900009, 10.308572745000076],
              [105.51441934600004, 10.307032768000022],
              [105.51824098000006, 10.305593022],
              [105.52185588100011, 10.304153566000094],
              [105.53083856200001, 10.298916429000114],
              [105.54095529100006, 10.292243515000086],
              [105.54763836200004, 10.286838236000049],
              [105.5547840830001, 10.281058270000097],
              [105.5562352470001, 10.279670286000096],
              [105.56561655000004, 10.270696938000066],
              [105.57655275300003, 10.260949500000025],
              [105.57875045500006, 10.258832129000071],
              [105.58645535300012, 10.251408398000093],
              [105.59810952400009, 10.239508555000041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 14, code: "AD01", ten_tinh: "Cao Bằng" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.56411194200007, 23.113172121000055],
              [105.56613081200004, 23.108572404000057],
              [105.56653202800003, 23.107157732000069],
              [105.56712935600007, 23.105390428000035],
              [105.56753729800008, 23.103443680000076],
              [105.5679456440001, 23.102548485000085],
              [105.56845260400004, 23.101126498000021],
              [105.56935949800003, 23.098803111000073],
              [105.56949848700006, 23.098083306000028],
              [105.56950913600006, 23.097241898000043],
              [105.569391447, 23.09627887800012],
              [105.56888722600003, 23.095071227000027],
              [105.56773489600008, 23.094230586000016],
              [105.56470882900007, 23.092659858000083],
              [105.56388798100002, 23.091881988000026],
              [105.5629335780001, 23.090718177000014],
              [105.56266647500011, 23.089946312],
              [105.56268273800003, 23.088664889000121],
              [105.563985508, 23.08419337900007],
              [105.56416420800005, 23.08362214800006],
              [105.5654530300001, 23.079502297000062],
              [105.56578317200007, 23.078548942000054],
              [105.56683816800005, 23.076867330000091],
              [105.56852915200007, 23.07519261000008],
              [105.57390506000007, 23.071349391000062],
              [105.57602808000004, 23.070084118000089],
              [105.57777707200007, 23.07010300700005],
              [105.58047080200002, 23.070868193000038],
              [105.58680307900002, 23.073144751000108],
              [105.58774785000003, 23.073891025000037],
              [105.58789761400008, 23.074628747000055],
              [105.58804552200006, 23.075513679],
              [105.58867229900005, 23.07625652500009],
              [105.58993695000007, 23.076859],
              [105.59612711600002, 23.077808736000094],
              [105.60137433100005, 23.077864894000029],
              [105.60265005700008, 23.077584087000069],
              [105.60521251100009, 23.076139239000071],
              [105.60826477300003, 23.07366901300005],
              [105.61227279300002, 23.071061684000057],
              [105.61450977500002, 23.070202140000085],
              [105.61578360400007, 23.070068457000033],
              [105.618958132, 23.070543841000109],
              [105.62322564900005, 23.072650223000046],
              [105.62417238000005, 23.073249141000041],
              [105.62541892400007, 23.075323447000073],
              [105.62775489700007, 23.079323145000075],
              [105.62901242300003, 23.080514199000035],
              [105.63122402300006, 23.081715316000029],
              [105.634707737, 23.082929832000062],
              [105.64090736100003, 23.083142320000057],
              [105.64393398500007, 23.082732456000073],
              [105.64601195600007, 23.081870951000028],
              [105.64698044500008, 23.08070335400005],
              [105.64924801200003, 23.077341047000033],
              [105.65085608100003, 23.075885669000051],
              [105.65213172100006, 23.075604579000064],
              [105.65642306500004, 23.075796667000056],
              [105.65833471700003, 23.075522185000082],
              [105.66376774500004, 23.073370497000042],
              [105.66664948500001, 23.071781032000068],
              [105.67098358700005, 23.068439924000117],
              [105.67323989100005, 23.06596051700005],
              [105.67468156300005, 23.065092116000052],
              [105.67802583300009, 23.064685080000032],
              [105.68789600400007, 23.063756445000116],
              [105.69429142000006, 23.063750345000052],
              [105.69699161500009, 23.063903167],
              [105.69901264900007, 23.064361702000014],
              [105.70069747400002, 23.064691688000046],
              [105.70245283500005, 23.064772219000048],
              [105.70522583000007, 23.064487881000062],
              [105.70637853600006, 23.064124419],
              [105.70780289600005, 23.06363864000005],
              [105.70902462300006, 23.063150783000097],
              [105.70956570800007, 23.063093760000086],
              [105.71023964400004, 23.063225726000077],
              [105.71050384600005, 23.063728760000046],
              [105.71028929200007, 23.064727251000058],
              [105.7080170990001, 23.068331478000026],
              [105.70753086000011, 23.069452265000031],
              [105.70731703100009, 23.070388201000114],
              [105.70731031400005, 23.070950999000047],
              [105.70750623100011, 23.071515875000067],
              [105.7079730720001, 23.072020973000029],
              [105.70844365700003, 23.072213401000049],
              [105.70884967400006, 23.072155010000102],
              [105.70973671600009, 23.07141357200004],
              [105.71165703700008, 23.068994050000043],
              [105.712963454, 23.067068583000072],
              [105.71358252000006, 23.066136765000067],
              [105.71406052800009, 23.065703841000037],
              [105.71467064700003, 23.065522432000037],
              [105.71939866700008, 23.065570546000025],
              [105.72054764200001, 23.06551968400008],
              [105.72122456100007, 23.065401475000066],
              [105.7215652460001, 23.065154773000081],
              [105.72177529099999, 23.064531485000117],
              [105.72368564700004, 23.057233374000049],
              [105.72468972700008, 23.052302617000109],
              [105.72510089100008, 23.051806446000086],
              [105.72666315200011, 23.051071754000048],
              [105.72809106100004, 23.050273139000055],
              [105.72870630300005, 23.049653928000069],
              [105.730085133, 23.04729119800006],
              [105.73064315900008, 23.045795767000051],
              [105.7307173400001, 23.045233619000079],
              [105.73072103400004, 23.044920932000053],
              [105.73052507500009, 23.044356041000036],
              [105.73006118800006, 23.043600804000071],
              [105.72979696200005, 23.043097771000106],
              [105.7297353320001, 23.042596787000043],
              [105.73035202200006, 23.041852482000102],
              [105.73232898400003, 23.040308831000097],
              [105.73416716600008, 23.03907649300001],
              [105.73518459400006, 23.038711498000097],
              [105.73585992700006, 23.038718316000129],
              [105.73928722200004, 23.040191435000068],
              [105.740834636, 23.040707397000034],
              [105.74252373400005, 23.040661853000088],
              [105.74820468500008, 23.040030959000113],
              [105.75111304900001, 23.039684867000076],
              [105.7525363920001, 23.039261312000022],
              [105.75308398000001, 23.038641341000066],
              [105.75329389400007, 23.038017985000089],
              [105.75350965700007, 23.036894312000108],
              [105.75346113100005, 23.035267630000078],
              [105.75346771200006, 23.034704776000048],
              [105.75367763000003, 23.03408141400007],
              [105.75429126600008, 23.033587189000038],
              [105.75813345100002, 23.031342670000043],
              [105.76207357900006, 23.029380515000049],
              [105.76573477300012, 23.028166074000112],
              [105.77455426100003, 23.024751096000081],
              [105.77740639500003, 23.023403326000036],
              [105.77903865100008, 23.022418723000015],
              [105.78068389200004, 23.020308369000105],
              [105.78359074400004, 23.014207340000056],
              [105.78608999700006, 23.010007287000086],
              [105.78854901200005, 23.005874608000099],
              [105.79141226900008, 23.003525899000095],
              [105.79701069900001, 22.998201573000095],
              [105.80109910300007, 22.99498901500003],
              [105.80974433700004, 22.994823426000011],
              [105.8120399690001, 22.994845838000089],
              [105.81272412700012, 22.994885857000021],
              [105.81493638600003, 23.000015453000074],
              [105.82247646000003, 23.000163917000101],
              [105.82283398600003, 22.997237796000022],
              [105.82389573800005, 22.996572041],
              [105.82691452300007, 22.994948714000074],
              [105.82952783800002, 22.993321417000075],
              [105.8317357130001, 22.991690163000023],
              [105.83534307300005, 22.988194415000059],
              [105.83878053899998, 22.985373015000029],
              [105.83481374400004, 22.97759730700006],
              [105.83912933800008, 22.975986269000082],
              [105.84761396600007, 22.985833591000038],
              [105.85290984400004, 22.981410055000048],
              [105.84716406100006, 22.970773930000021],
              [105.85043265000006, 22.968302990000034],
              [105.85113744300003, 22.96738031100007],
              [105.85192738100001, 22.965743503000091],
              [105.85399966300005, 22.959829218000039],
              [105.85710505300004, 22.95120782],
              [105.859935272, 22.944164173000026],
              [105.86011714500005, 22.942637899000125],
              [105.86037551100002, 22.941647150000044],
              [105.86120580700006, 22.941120265000059],
              [105.86393294300011, 22.940535066000045],
              [105.86526037100006, 22.939783697000045],
              [105.86727903100002, 22.937349098000048],
              [105.870093223, 22.934066024000096],
              [105.870199552, 22.933423452000042],
              [105.87021686400001, 22.931860628000052],
              [105.87032420000001, 22.931126116000122],
              [105.87246272300003, 22.928233028000108],
              [105.87687030600003, 22.922269872000101],
              [105.87756564700001, 22.922184520000052],
              [105.88609193600008, 22.922632872000023],
              [105.88598566600008, 22.923275468000078],
              [105.88387499400005, 22.930523906000033],
              [105.88398127300006, 22.931267488000096],
              [105.88568995100005, 22.937224167000103],
              [105.88762707800001, 22.943235950000052],
              [105.89454892400002, 22.943566271000023],
              [105.89534142200004, 22.944369305000031],
              [105.89760997700007, 22.946994967000123],
              [105.89828373800005, 22.947473193000079],
              [105.89896645300004, 22.94785086400006],
              [105.90005093300007, 22.948126218000105],
              [105.9022840960001, 22.948041050000079],
              [105.90503001800002, 22.948172810000052],
              [105.90577285900011, 22.948285830000081],
              [105.90719901200006, 22.94872346300005],
              [105.91003807700005, 22.950818467000111],
              [105.91281767000008, 22.953125008000086],
              [105.91559330400005, 22.955802737000042],
              [105.91638882400007, 22.956340514000125],
              [105.91696115300007, 22.956345833000036],
              [105.91782137900002, 22.956194711000052],
              [105.91822660300006, 22.955774174000076],
              [105.91922195600009, 22.953714924000046],
              [105.91992251300005, 22.952448510000067],
              [105.92050115100005, 22.951870458000108],
              [105.92124688200003, 22.951718255000038],
              [105.92409129800009, 22.953335773000028],
              [105.92511805000005, 22.953663510000055],
              [105.92597825800009, 22.953512351000072],
              [105.92672685100003, 22.953094968000087],
              [105.92713891100001, 22.952038016000031],
              [105.92784056300007, 22.950665505000067],
              [105.92882606299999, 22.949507758000017],
              [105.93032664700007, 22.948354769000012],
              [105.932687367, 22.947050580000017],
              [105.93434345400007, 22.945985554000089],
              [105.93492112100009, 22.944941066000091],
              [105.93609543300008, 22.941080672000069],
              [105.93659522600004, 22.940691593000025],
              [105.93822353700003, 22.940706577000114],
              [105.94558352400006, 22.941036610000062],
              [105.95181219600002, 22.941224844000097],
              [105.95280055000008, 22.941496324000084],
              [105.95336412600008, 22.941763930000022],
              [105.95357162800011, 22.942225119000078],
              [105.953762372, 22.944260885000034],
              [105.95377395100006, 22.948098843000061],
              [105.95377801000004, 22.949444464000024],
              [105.95397784100005, 22.950627314000101],
              [105.95418534400008, 22.951088493000071],
              [105.95474616800004, 22.951618509000049],
              [105.95566377600002, 22.951889333000075],
              [105.95835418000004, 22.951913854000061],
              [105.96303047700007, 22.951628331000059],
              [105.96700153900011, 22.951073872000016],
              [105.97331795000012, 22.949687592000068],
              [105.9767988660001, 22.948603625000082],
              [105.97836472299998, 22.947830397000054],
              [105.98028938300007, 22.946601111000042],
              [105.98485968300012, 22.942902287000067],
              [105.98607147900002, 22.942125822000037],
              [105.98768302200004, 22.941502317000051],
              [105.98963903500007, 22.941138994000099],
              [105.99354805900002, 22.940697936000021],
              [105.99445487300008, 22.940812805000022],
              [106.00042310700007, 22.941568726000035],
              [106.00013277600003, 22.947255329000079],
              [105.99976610900006, 22.952869769000102],
              [105.99957122900003, 22.961722941000048],
              [105.99976088400008, 22.963248033000028],
              [106.000254822, 22.965156697000033],
              [106.00189905800008, 22.968692545000025],
              [106.00283068300006, 22.97118486500009],
              [106.00376416500002, 22.973499752000045],
              [106.00402836800004, 22.975719951000073],
              [106.00370888900007, 22.978822078000057],
              [106.00311597100004, 22.980591057000055],
              [106.00114842700005, 22.985630127000078],
              [105.99958040700007, 22.989075894000074],
              [105.99918447300008, 22.990314315000049],
              [105.99916692800005, 22.991999658000047],
              [105.99934920000004, 22.99288838400004],
              [105.99982427400009, 22.993247469],
              [106.00163991900004, 22.993618494000053],
              [106.0064422880001, 22.993591561000045],
              [106.00911384500009, 22.993419484000107],
              [106.00939547400003, 22.993380301000066],
              [106.01207675200001, 22.993007244000104],
              [106.01383253400006, 22.992697553000063],
              [106.01556924900005, 22.992591546000085],
              [106.01779361100006, 22.992577275000059],
              [106.02086683100006, 22.99275417000004],
              [106.02394672100007, 22.993815982000051],
              [106.02617778000011, 22.994686568000056],
              [106.02681332300003, 22.99468245400007],
              [106.033989074, 22.995151297000049],
              [106.03634023600003, 22.995148472000082],
              [106.03678616500007, 22.994930572000079],
              [106.03740262399999, 22.994220116000093],
              [106.03823571500001, 22.992346285000039],
              [106.03939256600006, 22.991256999000065],
              [106.04076877700007, 22.991149628000059],
              [106.04140505500004, 22.991243781000037],
              [106.04289850400008, 22.992610664000068],
              [106.04422793000002, 22.994282452000014],
              [106.04546559700002, 22.995838812],
              [106.04621236600008, 22.99652223000006],
              [106.04721826800008, 22.996466421000058],
              [106.04922626500004, 22.99586312200006],
              [106.05197493900002, 22.995156539000035],
              [106.05323685300007, 22.993968127000031],
              [106.05343954600005, 22.992786751000068],
              [106.054071256, 22.992290871000073],
              [106.05565853100006, 22.992083635000071],
              [106.05715989400002, 22.99227254300007],
              [106.057885153, 22.99236378600007],
              [106.06159928600009, 22.993223964],
              [106.06638193900002, 22.995256875000081],
              [106.07074181600008, 22.997390835000076],
              [106.07393432, 22.999237586000021],
              [106.07763991800009, 23.000831262000077],
              [106.07927770100007, 23.001330276000061],
              [106.08007554600009, 23.001539881],
              [106.08196111300005, 23.001661290000094],
              [106.08311971400008, 23.001519095000056],
              [106.08456073200003, 23.00043496300006],
              [106.08585032900005, 22.998546180000098],
              [106.08697108200006, 22.995093589000071],
              [106.08761558500001, 22.994487701000118],
              [106.08859076100005, 22.994631368000015],
              [106.09169194700004, 22.996715177000034],
              [106.09250239400004, 22.996559218000101],
              [106.09331041900002, 22.99610254600006],
              [106.09739148900009, 22.99161823100011],
              [106.09925953000003, 22.989591051000069],
              [106.10012377200003, 22.98891363900006],
              [106.10098908100004, 22.988370504000066],
              [106.10156886700005, 22.988366471000084],
              [106.10302159600008, 22.988759202000075],
              [106.10418880700011, 22.989691058000084],
              [106.10535711700005, 22.99075716],
              [106.10594018900007, 22.991155944000049],
              [106.106957009, 22.991417404000103],
              [106.10829557700001, 22.991204727000088],
              [106.11332301900003, 22.990656381000107],
              [106.11469510800006, 22.990056720000048],
              [106.11697900000011, 22.989365417000052],
              [106.11839326600007, 22.988948980000053],
              [106.12167664800003, 22.988925775],
              [106.12411841200002, 22.989596809000098],
              [106.12730485700004, 22.990655838000059],
              [106.129059431, 22.991479173000059],
              [106.12970229800008, 22.992359602000079],
              [106.12970966200007, 22.993244552000114],
              [106.12959337500004, 22.994116413000043],
              [106.12970429100007, 22.995159635000029],
              [106.13111036100005, 22.996086229000042],
              [106.13292085500002, 22.997253295000064],
              [106.13345128300003, 22.997347834000067],
              [106.13440457500005, 22.997341003000123],
              [106.14234786200011, 22.997185579000053],
              [106.14372068700001, 22.996684012000031],
              [106.14529955000009, 22.995492595000044],
              [106.14677412000006, 22.994498606000043],
              [106.15054309200006, 22.99301656600008],
              [106.15280172100006, 22.992313737000103],
              [106.15441527000007, 22.992171950000092],
              [106.15493469700007, 22.992510034000091],
              [106.15576632800004, 22.993154920000066],
              [106.15650859500009, 22.993247837000027],
              [106.15745933000005, 22.992945892000094],
              [106.15851598600008, 22.992643166000093],
              [106.15935240500002, 22.99135871900004],
              [106.16056221400002, 22.988948010000065],
              [106.1617590330001, 22.987899437000081],
              [106.16344184900007, 22.98651042400008],
              [106.16555334000007, 22.985708245000055],
              [106.17089465400005, 22.984833034000118],
              [106.17152927200007, 22.984730009000117],
              [106.17258837200004, 22.984722179000094],
              [106.17714592200011, 22.985081721000086],
              [106.17820502400008, 22.985073846000056],
              [106.179797084, 22.985455329],
              [106.18118333300004, 22.986526654],
              [106.18215108000007, 22.988191087000054],
              [106.18290361300008, 22.989463803000021],
              [106.18343747000002, 22.989951471000047],
              [106.18428563100012, 22.99004347200011],
              [106.18714272600008, 22.989727084000044],
              [106.18851961100006, 22.989716763000075],
              [106.18915681100002, 22.989908645000057],
              [106.19001274400006, 22.990885533000061],
              [106.19161353400007, 22.992250161000079],
              [106.19235752300008, 22.992539558000047],
              [106.19415810400002, 22.992525996000076],
              [106.19542388500004, 22.99192645600003],
              [106.1972097030001, 22.990241327000049],
              [106.19884179200004, 22.987772371000048],
              [106.20020577700009, 22.985963477000034],
              [106.201882384, 22.984603786000065],
              [106.20328247300003, 22.983156047000094],
              [106.20591696100004, 22.97819947300006],
              [106.20947476599999, 22.97333601600009],
              [106.21129328799999, 22.97199205200009],
              [106.2125623360001, 22.971785686000068],
              [106.21468030400011, 22.971769469000058],
              [106.21562723400004, 22.97107389100006],
              [106.21604202200007, 22.970087387000056],
              [106.21602267600007, 22.967924237000048],
              [106.21622742500004, 22.967136016000019],
              [106.21664309600007, 22.966247834000072],
              [106.217589097, 22.965453915000111],
              [106.21854213200007, 22.965446600000035],
              [106.21928515200008, 22.965637547000114],
              [106.22003170200004, 22.966221796000049],
              [106.22045527100009, 22.966218536000049],
              [106.22129889800009, 22.965818712],
              [106.22203573400003, 22.965321375000066],
              [106.22212925700003, 22.963944016000056],
              [106.22211688000009, 22.962567467000028],
              [106.22199950500006, 22.961290055000028],
              [106.22199155100002, 22.960405132000083],
              [106.22230126500006, 22.959517751000114],
              [106.22346073900007, 22.958918831000027],
              [106.22695238000006, 22.958596860000036],
              [106.23096098800008, 22.956894159000015],
              [106.23307069800002, 22.955992782000045],
              [106.2346464770001, 22.954603880000057],
              [106.23569640000002, 22.953612398000089],
              [106.23558515600006, 22.953023271000106],
              [106.23462506600002, 22.952244100000115],
              [106.23398441700004, 22.951659087000031],
              [106.23397460900003, 22.950577518000067],
              [106.23480827900008, 22.949096064000059],
              [106.23606453900001, 22.947512985000081],
              [106.23815438300009, 22.944448402000091],
              [106.23930649300003, 22.943062769000022],
              [106.24004314600005, 22.942565356000074],
              [106.24163305700006, 22.942749589000044],
              [106.24279858300004, 22.942838794000053],
              [106.24309141900002, 22.942715171000124],
              [106.24374785900007, 22.942438028000112],
              [106.24458316900008, 22.941153168000113],
              [106.24456428700003, 22.939088360000071],
              [106.24495540300006, 22.935545355000016],
              [106.24554218600012, 22.932195785000076],
              [106.24614761500003, 22.929756254000019],
              [106.24695201800006, 22.927933312000093],
              [106.25037712800004, 22.923789393000057],
              [106.25124957800001, 22.923137669000049],
              [106.25203927399998, 22.922924322000071],
              [106.25400849700003, 22.922939672],
              [106.25550976300008, 22.922439171000072],
              [106.25853015300005, 22.919462664],
              [106.25916891400006, 22.918516401000133],
              [106.25901734200004, 22.91785668199999],
              [106.2508040280001, 22.911499978000016],
              [106.25607614000005, 22.903418872000088],
              [106.25718343500009, 22.902915272000079],
              [106.26152654100008, 22.90170506000004],
              [106.266504287, 22.899987444000125],
              [106.2668252600001, 22.899331359000108],
              [106.26683646900005, 22.898087486000037],
              [106.26668290800004, 22.897647248000027],
              [106.26566762400006, 22.896688119000075],
              [106.26213472400005, 22.89541677800004],
              [106.26064759100009, 22.894380790000035],
              [106.26002745300003, 22.893278344000031],
              [106.25980177500001, 22.892105786000094],
              [106.25996987900007, 22.890936283000052],
              [106.26092685800003, 22.889626548000038],
              [106.26606247600004, 22.887836951000025],
              [106.27142964700003, 22.886561260000079],
              [106.27380541400005, 22.88511604600005],
              [106.27753501600009, 22.881998146000093],
              [106.2819147660001, 22.876616636000065],
              [106.28580288300002, 22.873353408000071],
              [106.28739089700005, 22.871901980000061],
              [106.28794473900004, 22.871613496000087],
              [106.28849597500009, 22.87161770800008],
              [106.28881031700007, 22.871693293000114],
              [106.28912075300009, 22.872207914000064],
              [106.28934919000007, 22.873087799000032],
              [106.28934333300003, 22.87374636100008],
              [106.28917477200011, 22.874989101000054],
              [106.28932316000005, 22.876014726000093],
              [106.28994796500005, 22.876604924000077],
              [106.29065476500008, 22.876829849000032],
              [106.29152101700001, 22.876836457000046],
              [106.29365117700004, 22.876413630000059],
              [106.29871126100002, 22.874183588000015],
              [106.30188320900001, 22.871719584000068],
              [106.30698662800003, 22.86633372700004],
              [106.30934734500005, 22.865067593000084],
              [106.30982187400005, 22.865063686000028],
              [106.31118255800001, 22.86673921400002],
              [106.31350716800006, 22.869946848000033],
              [106.31792069000002, 22.868296940000029],
              [106.318789967, 22.86821640400003],
              [106.31889253200005, 22.868518676000079],
              [106.31903198800005, 22.869320411000068],
              [106.31912230000006, 22.872610004000116],
              [106.31964959600008, 22.875322895000046],
              [106.31998015000008, 22.876786883000122],
              [106.32090009100007, 22.878736027000102],
              [106.32153978500006, 22.879222366000107],
              [106.32217471900002, 22.879217087000114],
              [106.32238445600005, 22.879018684000037],
              [106.32427113000001, 22.877134708000121],
              [106.32804337600002, 22.873268385000053],
              [106.33128633700002, 22.869406412000082],
              [106.33444687200006, 22.866298065000109],
              [106.33610956100004, 22.864842795000051],
              [106.34261183500007, 22.858888269000133],
              [106.34328762800004, 22.858538589000062],
              [106.34415959500006, 22.858477547000078],
              [106.34575540400003, 22.858691805000042],
              [106.35169777000002, 22.860152812000045],
              [106.35394483800003, 22.860641738000083],
              [106.36042972800004, 22.866628010000056],
              [106.36085911500005, 22.867373489],
              [106.36098767900003, 22.869331484],
              [106.36090471000009, 22.870545602000014],
              [106.36045633700003, 22.872026971000103],
              [106.360520331, 22.87303970600005],
              [106.36252149200001, 22.87646743400007],
              [106.36362975300005, 22.881472712000047],
              [106.37195134100007, 22.885718871000016],
              [106.37456901700003, 22.88531165800007],
              [106.37667956000004, 22.884703425000019],
              [106.37952885900008, 22.883892094000124],
              [106.38037130700005, 22.883949767000011],
              [106.38165428400006, 22.88475859100005],
              [106.38250884000007, 22.88553779100004],
              [106.38314379300003, 22.885532268000119],
              [106.38866696100006, 22.885004868000088],
              [106.38990953300001, 22.884784961000015],
              [106.39137503600003, 22.883198895000056],
              [106.392948319, 22.881808530000036],
              [106.39442579800004, 22.881402269000084],
              [106.39644342700009, 22.882072854000057],
              [106.39867372500007, 22.882839872000105],
              [106.39999498900005, 22.882680756000084],
              [106.40126080300007, 22.882276287000103],
              [106.4023099290001, 22.881382093000099],
              [106.40303955600007, 22.880294047000049],
              [106.40302439300005, 22.878819262000057],
              [106.40344947800006, 22.877981091000073],
              [106.40397887800007, 22.877145091],
              [106.40444965100004, 22.87642045200009],
              [106.40515722900005, 22.875472036000041],
              [106.40545437900003, 22.875303145000103],
              [106.40593141400007, 22.875188090000087],
              [106.40736708200011, 22.875286234000036],
              [106.40958550100007, 22.875931643000058],
              [106.41120449500009, 22.87641607300008],
              [106.41387385000006, 22.877422151000104],
              [106.414341171, 22.878249815000018],
              [106.41411003100006, 22.879598720000068],
              [106.4134845630001, 22.881089735000067],
              [106.41165092700008, 22.884089673000041],
              [106.41110522700005, 22.885307351000073],
              [106.41099925000007, 22.886347172000043],
              [106.41138168800009, 22.887209490000068],
              [106.412349921, 22.887894995000032],
              [106.42277604700007, 22.893171444000068],
              [106.42336148300009, 22.893039842000107],
              [106.42395025400006, 22.892501083000028],
              [106.42493485900007, 22.891195993000046],
              [106.42680961600003, 22.888223184000097],
              [106.43132396700007, 22.884002049000074],
              [106.43847516300005, 22.890838343000027],
              [106.44141976900006, 22.894025768000056],
              [106.44218787500003, 22.895388351000086],
              [106.44294317200008, 22.898334285000118],
              [106.44317785900004, 22.899421703000122],
              [106.44351432200008, 22.899966933000115],
              [106.44452594500004, 22.901331190000107],
              [106.44567672600004, 22.903555968000063],
              [106.44625523300003, 22.904283832000068],
              [106.44703072000007, 22.904741612000024],
              [106.44903918200006, 22.904905523000082],
              [106.45203512400006, 22.904878167000042],
              [106.452771797, 22.904478131000069],
              [106.45402924200003, 22.903286716000082],
              [106.45590697900006, 22.900713054000029],
              [106.4569380070001, 22.898147121000086],
              [106.45777309600001, 22.897057875000115],
              [106.45882823000009, 22.896753215000039],
              [106.46021034200005, 22.897330476000022],
              [106.46222954500006, 22.898098522000062],
              [106.46297879000005, 22.898878233000097],
              [106.46320521700005, 22.900252705000042],
              [106.46322314800003, 22.901924073000046],
              [106.46344113800002, 22.902512019000106],
              [106.46386870800005, 22.902901386000082],
              [106.47145931100007, 22.909910696000054],
              [106.47253152000006, 22.911178985000014],
              [106.47358991400006, 22.911169163000089],
              [106.47464299900005, 22.910667776000082],
              [106.476640113, 22.909370988000077],
              [106.47799575900009, 22.907490200000069],
              [106.47909052100007, 22.905955975000047],
              [106.48003450200011, 22.905160593000048],
              [106.48119656000007, 22.904953114000115],
              [106.48385205500006, 22.905813253000048],
              [106.48820847300007, 22.907345695000103],
              [106.49118372400005, 22.908399372000062],
              [106.49277128300004, 22.908384464000072],
              [106.49382103700003, 22.907587994000075],
              [106.49518613100001, 22.906591904],
              [106.49592697900002, 22.90658492700004],
              [106.49666999100003, 22.906774576000096],
              [106.49774237800004, 22.908042690000038],
              [106.49967232100002, 22.91028594500002],
              [106.50001378400003, 22.910773356000021],
              [106.50042508100005, 22.911360406000099],
              [106.50053633500002, 22.911850980000011],
              [106.50184215900006, 22.91508333200003],
              [106.50195232900003, 22.915475586000063],
              [106.50195666800003, 22.91586885000001],
              [106.50143395500004, 22.916463738000026],
              [106.49912270800012, 22.918058766000016],
              [106.49765169900009, 22.919055891000092],
              [106.49744541800007, 22.919549458000077],
              [106.49744865100007, 22.919844395000055],
              [106.49766682400009, 22.920432285000025],
              [106.49938092400008, 22.922284275000045],
              [106.50087686800003, 22.923548354000012],
              [106.50098814000009, 22.924038924000101],
              [106.50099464500002, 22.924628799000054],
              [106.50068141200003, 22.925025060000067],
              [106.49953114600007, 22.926314145000056],
              [106.49890469600011, 22.927106659000081],
              [106.49723218400001, 22.933857656],
              [106.49723975500011, 22.934545849000095],
              [106.49799156700004, 22.935521993000037],
              [106.49873690800008, 22.935908254000111],
              [106.49947899600002, 22.935999569000032],
              [106.500319355, 22.935401682000034],
              [106.50246721800008, 22.933171561000073],
              [106.50388811100005, 22.932614836000106],
              [106.50441738600006, 22.932609820000046],
              [106.50505686800004, 22.932997049000022],
              [106.50539074300004, 22.934468745000025],
              [106.50848373800007, 22.943028764000047],
              [106.50881658300008, 22.944402130000086],
              [106.50946706600007, 22.94596922300007],
              [106.51040415000007, 22.947399844000046],
              [106.51114958000002, 22.947786045000029],
              [106.51232665800006, 22.947873150000042],
              [106.51560290900008, 22.947827609000093],
              [106.51587490600011, 22.947863813000069],
              [106.51708140000001, 22.94802440000003],
              [106.51878514400002, 22.948893006000048],
              [106.51974344600009, 22.949375448000083],
              [106.52080321600002, 22.949463604000094],
              [106.52376751000007, 22.94943516000005],
              [106.52492764500005, 22.949030724000075],
              [106.5257602410001, 22.947744516000057],
              [106.52700636800009, 22.945569410000033],
              [106.52761234000005, 22.942640565000083],
              [106.52823699500004, 22.942017928000077],
              [106.52939705700001, 22.941613462000099],
              [106.53087910900007, 22.941599165000071],
              [106.531094153, 22.941892064000058],
              [106.53111737800006, 22.94395662600008],
              [106.53143938800005, 22.944346816000035],
              [106.53239215400002, 22.944337616000077],
              [106.53830937100005, 22.94329712300005],
              [106.53989729200008, 22.94328172100002],
              [106.54018866400003, 22.942637143000063],
              [106.53950063900002, 22.940974982000029],
              [106.53938255800003, 22.939894569000074],
              [106.53936031500005, 22.937928337000102],
              [106.53986402900007, 22.935662025000077],
              [106.54089254100009, 22.932997324000048],
              [106.54212311900002, 22.931251345000092],
              [106.54321844800006, 22.930460516000025],
              [106.54445477900003, 22.929718044000055],
              [106.5457543150001, 22.928822536000034],
              [106.54757603900001, 22.927286398000035],
              [106.5499117750001, 22.926029914000075],
              [106.56277125900006, 22.920689772000102],
              [106.56403686700006, 22.920284022000104],
              [106.56499058600009, 22.920372943000089],
              [106.56648707400011, 22.921636383000084],
              [106.56852753400008, 22.924172608000056],
              [106.57013108500007, 22.925533266000016],
              [106.57278746900005, 22.926391871000092],
              [106.5745936850001, 22.926963895000085],
              [106.57586839600005, 22.927344536000049],
              [106.57650348400001, 22.927338232000061],
              [106.57713743400006, 22.927233612000109],
              [106.57786811600005, 22.926341447000098],
              [106.57871375700002, 22.926234719000057],
              [106.57945923400007, 22.926620595000102],
              [106.58106634200007, 22.928276070000031],
              [106.5820896000001, 22.929789856000049],
              [106.58274069400005, 22.931159864000072],
              [106.58391762600009, 22.932229663000037],
              [106.58519813000008, 22.933101760000071],
              [106.58753603700008, 22.933864971000069],
              [106.59071619900004, 22.934226414000044],
              [106.59452010600009, 22.931579491000065],
              [106.59775554800009, 22.929976197000101],
              [106.59925162600007, 22.929477829000078],
              [106.60166118100004, 22.928708690000029],
              [106.60292442000011, 22.928106011000082],
              [106.603337395, 22.927216958000059],
              [106.60634432700002, 22.921877231000032],
              [106.60685962900001, 22.92069216400003],
              [106.60694575700003, 22.919019843000044],
              [106.60692024700006, 22.916857044000061],
              [106.60667031700007, 22.913614979000037],
              [106.60686576900008, 22.912236511000025],
              [106.60769736600005, 22.910949926000065],
              [106.60984188400003, 22.908259878000067],
              [106.61218192400003, 22.905300180000054],
              [106.61333440000008, 22.90430527300007],
              [106.61670689800006, 22.903091150000066],
              [106.61823614200003, 22.902633137000095],
              [106.62023632100005, 22.901727867000062],
              [106.62191550100007, 22.900530889000059],
              [106.62348647500002, 22.899138352000037],
              [106.62505157700012, 22.897254273000023],
              [106.62827807200009, 22.892698504000109],
              [106.62890358600006, 22.891905529000041],
              [106.62889654300002, 22.891315675],
              [106.62867551800011, 22.890531375000123],
              [106.62749160100003, 22.888872052000053],
              [106.62737875600003, 22.888283292000011],
              [106.62782741800002, 22.887511430000096],
              [106.62913855900005, 22.887345495000133],
              [106.63218570800002, 22.888229215000067],
              [106.633501408, 22.888444446000022],
              [106.63399377400006, 22.888439385000048],
              [106.63538240400003, 22.887891364],
              [106.63651295100004, 22.88635476500005],
              [106.63787868300007, 22.88390073900004],
              [106.63810747499998, 22.88244966900006],
              [106.63809740700005, 22.881611039000035],
              [106.63792598100007, 22.88100281400007],
              [106.63759227100007, 22.880548756000074],
              [106.63560466100003, 22.879044250000021],
              [106.63510502100007, 22.878439401000072],
              [106.63493543800007, 22.877983654000019],
              [106.63501293200009, 22.877601613000117],
              [106.63639687000003, 22.87667239000006],
              [106.63687559600002, 22.876471884000082],
              [106.63770333600003, 22.876125209000087],
              [106.63958871100007, 22.875953287000073],
              [106.6416372780001, 22.875703398],
              [106.64253709800003, 22.875465367000025],
              [106.643022989, 22.874926607000035],
              [106.64333652100001, 22.873703384000073],
              [106.64364598900004, 22.87283824399999],
              [106.64413041200007, 22.871483983000118],
              [106.64489289800008, 22.870065506000081],
              [106.64610892500008, 22.868832946000062],
              [106.64676255000006, 22.868597439000027],
              [106.64711684000007, 22.868547445000061],
              [106.64901203700002, 22.868280053000056],
              [106.65127231499999, 22.868352033000058],
              [106.65235913900003, 22.868809014000036],
              [106.653598614, 22.869621365000015],
              [106.6582328370001, 22.872717812000062],
              [106.662542747, 22.875761908000044],
              [106.66388848800007, 22.87687665],
              [106.66501524100003, 22.878342207000024],
              [106.66533134100008, 22.879601712000053],
              [106.66533063300002, 22.884696433000059],
              [106.66496862200009, 22.88613523700009],
              [106.66385163200005, 22.888332985000126],
              [106.66367925300008, 22.889847030000077],
              [106.66509940200007, 22.891890838000073],
              [106.66737891000002, 22.893735007000068],
              [106.6684457100001, 22.893723810000033],
              [106.67008042100009, 22.893172904000011],
              [106.67138406500004, 22.892396723],
              [106.67413370700008, 22.890157007000056],
              [106.67544593500003, 22.888999074000083],
              [106.67617697000003, 22.888381383000045],
              [106.67723810500009, 22.887912718000081],
              [106.67789457500004, 22.88790578400004],
              [106.67913388600009, 22.888578914000043],
              [106.68054484200003, 22.889860208000101],
              [106.68164510300004, 22.891222848000048],
              [106.68247849400007, 22.892168312000116],
              [106.68341223800005, 22.892921846000021],
              [106.68774246800004, 22.894511259000083],
              [106.68930447400005, 22.894723408000047],
              [106.69028920800008, 22.894712932000068],
              [106.69159841000005, 22.89439401100006],
              [106.69273686900002, 22.893543178000094],
              [106.69394695800004, 22.891852848000035],
              [106.69500237300005, 22.89092664300005],
              [106.69655106800003, 22.890071396000074],
              [106.69871944800003, 22.889552637000051],
              [106.70101327800002, 22.889223122000054],
              [106.70315249100001, 22.889657693000039],
              [106.70611612099999, 22.890388365000014],
              [106.7071008230001, 22.890377788000116],
              [106.7082429590001, 22.889831803000057],
              [106.71019897100004, 22.888743313000056],
              [106.71125137400007, 22.887588289000057],
              [106.71517154000006, 22.87954017000002],
              [106.71581837600012, 22.878770722000041],
              [106.71644795500006, 22.876629040000026],
              [106.71651946200004, 22.875789549000025],
              [106.71643166300005, 22.875333017000088],
              [106.71584772300005, 22.874576863000051],
              [106.71476577600006, 22.873368595000088],
              [106.71302077200001, 22.871633720000069],
              [106.71095145200003, 22.870207320000091],
              [106.70975176300007, 22.86941963600011],
              [106.70925763000004, 22.868859160000099],
              [106.70575252300002, 22.866061351000063],
              [106.70776010600004, 22.86424003400009],
              [106.70966434600004, 22.861955007000034],
              [106.71139558500002, 22.859281372000062],
              [106.71167501500004, 22.858944486000077],
              [106.71305057600009, 22.85723324900006],
              [106.71395916000003, 22.856533786000099],
              [106.71724738700004, 22.855136480000034],
              [106.72402159000011, 22.853166447000014],
              [106.72622242700004, 22.852313646000063],
              [106.72850108800002, 22.85129788000004],
              [106.72899511000003, 22.850958648000038],
              [106.73222781800008, 22.848738829000048],
              [106.73637680900001, 22.846570600000057],
              [106.7399966510001, 22.845100426],
              [106.75039842300001, 22.835890965000068],
              [106.75177798400003, 22.834858348000076],
              [106.757469181, 22.831415339000039],
              [106.75991280400004, 22.830303206000032],
              [106.76590803200004, 22.828237865000034],
              [106.76659370300011, 22.827763020000091],
              [106.76688201600007, 22.826944768000068],
              [106.76691857100008, 22.82559944000004],
              [106.76634279400005, 22.823835319000096],
              [106.76608452000008, 22.822867254000109],
              [106.76572545200004, 22.821574700000014],
              [106.76531458400007, 22.820567662000087],
              [106.76468105500004, 22.818941733000116],
              [106.76413634000009, 22.817180352000044],
              [106.76304463200009, 22.813448642000097],
              [106.76303580500007, 22.812763384000064],
              [106.76302772100011, 22.812135219000048],
              [106.76305108600009, 22.811563828000111],
              [106.76313588800009, 22.810991748000028],
              [106.76356368500005, 22.810815675000029],
              [106.76542351100001, 22.811319167000121],
              [106.76716841900006, 22.812246440000102],
              [106.76997242400007, 22.814440181000073],
              [106.77309145700004, 22.81634516600004],
              [106.77465330300004, 22.81693460700005],
              [106.77638378000003, 22.817326047000016],
              [106.77761958300006, 22.817223684000076],
              [106.77916126100007, 22.81645930800007],
              [106.78118245300007, 22.814688515000014],
              [106.78252298000005, 22.813813309000075],
              [106.78305334800005, 22.813652527],
              [106.78419356700007, 22.813590704000127],
              [106.78550560800001, 22.813880034000086],
              [106.78862820400009, 22.815061160000113],
              [106.79101502800005, 22.816250491000019],
              [106.79332412200006, 22.817744695000016],
              [106.79481169500004, 22.818944089000048],
              [106.79627604500004, 22.819902487000029],
              [106.79676976300004, 22.82006825400007],
              [106.79785717400004, 22.820470308000026],
              [106.80016821300003, 22.820932611000089],
              [106.80140139000011, 22.821261346000107],
              [106.80258890900004, 22.821391733000063],
              [106.80348628300004, 22.82153439700005],
              [106.80453215800006, 22.821636781000088],
              [106.80855313400005, 22.821573148000063],
              [106.81590599500002, 22.820892887000085],
              [106.8212495010001, 22.814224948000025],
              [106.82371461400005, 22.812449837000059],
              [106.82561805400007, 22.811299880000036],
              [106.82648370300011, 22.810885412000026],
              [106.82782910700004, 22.810427373000053],
              [106.82909048600006, 22.809860808000067],
              [106.82990340300009, 22.809317769000081],
              [106.83193312600007, 22.807769572000055],
              [106.83302202900011, 22.806987668000112],
              [106.83398023800007, 22.806140202000066],
              [106.83476607500008, 22.805135968000023],
              [106.83486889400005, 22.804659184000094],
              [106.83474966500005, 22.804323500000031],
              [106.83106259200007, 22.799405908000026],
              [106.83070203100007, 22.798059515000091],
              [106.8306765670001, 22.79615316200001],
              [106.83138460600007, 22.791457847000096],
              [106.83070460700003, 22.791331371000112],
              [106.83009175000004, 22.790837132000071],
              [106.82883642000009, 22.790403474000072],
              [106.82808429700003, 22.790095567],
              [106.82611090000009, 22.789728782000026],
              [106.82413903300008, 22.789617903000092],
              [106.82281714300005, 22.789507634000117],
              [106.82174705500006, 22.788577829000097],
              [106.82035018200003, 22.786484498000092],
              [106.82020608000002, 22.784169846000069],
              [106.82050525000008, 22.779749727000095],
              [106.82004250700007, 22.77754188000003],
              [106.81736303400005, 22.774661530000095],
              [106.81590080000004, 22.773541683000076],
              [106.81470840200006, 22.772930647000052],
              [106.80901535600002, 22.770250492000073],
              [106.80610290200009, 22.768207831],
              [106.80133501200004, 22.7666654860001],
              [106.80025852600005, 22.766254195],
              [106.79946912400001, 22.766547173000085],
              [106.79833342300002, 22.767171724000065],
              [106.79530577100006, 22.77040085400008],
              [106.79473642300012, 22.770876589000096],
              [106.79437542000008, 22.770970402000046],
              [106.79406709800007, 22.770872984000064],
              [106.79360612100002, 22.770487466000056],
              [106.79279404100006, 22.768615788000091],
              [106.79243754500007, 22.767991427000119],
              [106.79182272600008, 22.767509315000019],
              [106.79120579500002, 22.767362340000119],
              [106.7904843900001, 22.767454209000078],
              [106.78697521600002, 22.768727968000043],
              [106.78651214500007, 22.768677580000052],
              [106.78636040700003, 22.768245842000034],
              [106.78651848300007, 22.767672139000084],
              [106.78802091800003, 22.766196005000069],
              [106.78972833500008, 22.764187465000063],
              [106.79131667100005, 22.762283799000024],
              [106.79116940200004, 22.761971195000093],
              [106.79066323000006, 22.761743405000075],
              [106.78868582900006, 22.761056499000084],
              [106.78742782600007, 22.760619476000073],
              [106.78692214200007, 22.760161419000013],
              [106.78666387000001, 22.759515111],
              [106.78652299800008, 22.757762483000121],
              [106.78635512200005, 22.757459452000091],
              [106.78581239500008, 22.755834368000102],
              [106.78555114000005, 22.753897636000083],
              [106.78482968700004, 22.752878814000077],
              [106.78435316900004, 22.752541849000082],
              [106.78175084800003, 22.752114512000077],
              [106.77773825400001, 22.752159231000043],
              [106.776543315, 22.751031554000086],
              [106.77652265900008, 22.749434410000013],
              [106.77614648400004, 22.747727125000061],
              [106.77506667200004, 22.746369953000034],
              [106.77293066300005, 22.7454808950001],
              [106.77009983500004, 22.745626392000062],
              [106.76572896000006, 22.745332473000076],
              [106.76465805300009, 22.744659715000104],
              [106.76393533300006, 22.743526716000062],
              [106.76392362600008, 22.74261405500004],
              [106.7642673990001, 22.741811568000081],
              [106.76520561900001, 22.741344802000093],
              [106.76649198300004, 22.740417798000045],
              [106.76730338200012, 22.739267837000021],
              [106.76752618799999, 22.738238479000117],
              [106.76668841000004, 22.737334959000052],
              [106.7641824670001, 22.735194779000075],
              [106.76323871000008, 22.734527355000097],
              [106.76328926600009, 22.733311910000076],
              [106.76393907300012, 22.732409545000017],
              [106.7651563200001, 22.731384972000065],
              [106.76609421700009, 22.730275678000034],
              [106.76674070900002, 22.729117582000043],
              [106.76673002900003, 22.728286459000024],
              [106.76659217800008, 22.727481666000045],
              [106.76678208000007, 22.725936642000107],
              [106.76669582500003, 22.724217156000037],
              [106.76641448900003, 22.722587071000078],
              [106.76651753600002, 22.72168288700005],
              [106.76681452300008, 22.720870252000047],
              [106.76779170300003, 22.720151837000024],
              [106.77110298700009, 22.719446246000082],
              [106.77217509500007, 22.719090235000024],
              [106.77266309500003, 22.718821481000084],
              [106.77276322700006, 22.718369660000093],
              [106.77218605500008, 22.717371259000082],
              [106.7714166800001, 22.716009901000064],
              [106.77142418300011, 22.714833753],
              [106.77269768800005, 22.713393124000092],
              [106.776006583, 22.711579578000048],
              [106.77727260400005, 22.710448632000087],
              [106.77787585000004, 22.709389742000049],
              [106.77786843900004, 22.70881590400009],
              [106.77754397400003, 22.707576022000083],
              [106.77671042600007, 22.706724401000052],
              [106.76587185000007, 22.703400902000048],
              [106.762993126, 22.702174843000122],
              [106.76215017300007, 22.701815825000082],
              [106.76023307800008, 22.700641254000061],
              [106.75227729700003, 22.69757200300004],
              [106.75134481400008, 22.697008284000027],
              [106.75069520200006, 22.694432722],
              [106.75169640900003, 22.692317362000033],
              [106.75495050200007, 22.689507723000091],
              [106.75820810300004, 22.686984957000078],
              [106.75870985200008, 22.686022895000058],
              [106.75869763800004, 22.685066487000057],
              [106.75827306600009, 22.68401895500007],
              [106.75613567000008, 22.677633565000029],
              [106.75564574600004, 22.675486721000127],
              [106.75471096800005, 22.674731719000093],
              [106.75201828400002, 22.672881618000048],
              [106.74901554500008, 22.671446130000028],
              [106.7475730020001, 22.671174900000054],
              [106.74675077200001, 22.671183872000086],
              [106.74510752400002, 22.671297456000069],
              [106.74264203300004, 22.671419958000079],
              [106.74161424, 22.67143114100012],
              [106.74013520300011, 22.670978357000045],
              [106.73961025300011, 22.67059685400001],
              [106.73913185800005, 22.67021460300009],
              [106.735671568, 22.664799785000064],
              [106.73524842900008, 22.663847824000079],
              [106.73524000900008, 22.66317832100011],
              [106.73594618500007, 22.662118459000077],
              [106.73705855400001, 22.660138742000058],
              [106.737267828, 22.65899400300011],
              [106.73725212700003, 22.658253153000096],
              [106.73676782900003, 22.657453852000039],
              [106.73411731700001, 22.655633693000077],
              [106.73194959500006, 22.654891902000074],
              [106.73158210300004, 22.654274102000024],
              [106.7296699660001, 22.649320656000071],
              [106.72956002300006, 22.648747905000022],
              [106.72954205000008, 22.647313264000069],
              [106.73022663100005, 22.644531859000061],
              [106.73030781400011, 22.642809175000075],
              [106.73019308100004, 22.641853848000082],
              [106.72966851000007, 22.640998611000015],
              [106.72908018200006, 22.639661550000056],
              [106.72732159400003, 22.634468966000028],
              [106.72757939900005, 22.631873879000075],
              [106.72806778800005, 22.629798431000033],
              [106.7292105760001, 22.627132992000028],
              [106.72945838400008, 22.62655498800008],
              [106.73080267300003, 22.624455162000068],
              [106.731011768, 22.624053255000028],
              [106.73106993400002, 22.623575998000064],
              [106.73045293300004, 22.62213537600006],
              [106.72933365700007, 22.61982387500003],
              [106.72771145800004, 22.617648064000036],
              [106.72667991100005, 22.616899527],
              [106.72360666500006, 22.616362847000104],
              [106.72257752899999, 22.615804171000065],
              [106.72216160900004, 22.615238900000101],
              [106.72152476000011, 22.613346628000031],
              [106.72129198400003, 22.611070212000058],
              [106.72189101200004, 22.609924316000019],
              [106.72754368000003, 22.605444368000072],
              [106.72909757700008, 22.601988158],
              [106.73085174500008, 22.596350919000052],
              [106.73031576100006, 22.595412249000013],
              [106.72949344300005, 22.594515369000057],
              [106.72760231400008, 22.592965792000093],
              [106.71911667200008, 22.586363946000084],
              [106.70451397000004, 22.577513001000057],
              [106.7036399350001, 22.577461890000095],
              [106.70118844500004, 22.578383054000106],
              [106.69298077300003, 22.582385325000054],
              [106.68917709500009, 22.584239926000024],
              [106.68789565800002, 22.584152407000069],
              [106.68541843600005, 22.582563506000049],
              [106.68458417200003, 22.581874185000039],
              [106.68308969100011, 22.581729328000044],
              [106.68136272100003, 22.582164954000028],
              [106.67863316900005, 22.583322757000076],
              [106.67563968900008, 22.584777523000099],
              [106.67462418200007, 22.58505257000003],
              [106.67347138600009, 22.585002274000132],
              [106.67049563600007, 22.583331124000061],
              [106.66285664600005, 22.578754779000043],
              [106.660064037, 22.577231248000103],
              [106.65649597500001, 22.576771314000062],
              [106.65331185700008, 22.576804114000112],
              [106.65044042500008, 22.577216282000023],
              [106.64685338600003, 22.577922712000117],
              [106.64583305300008, 22.578507120000069],
              [106.64563444600007, 22.579083113000117],
              [106.64554195200006, 22.579944994000108],
              [106.64546195300001, 22.581859009000112],
              [106.64496427300004, 22.583203335000057],
              [106.6439495700001, 22.584265976000047],
              [106.64323847800007, 22.584942867],
              [106.64232193800008, 22.585621857000056],
              [106.63965572600002, 22.586031749000043],
              [106.63791510700005, 22.586527809000053],
              [106.63690372100004, 22.587877349000074],
              [106.63681343700003, 22.588930526000084],
              [106.63651769600008, 22.589985797000089],
              [106.63509651000008, 22.591435178000019],
              [106.63397216000004, 22.591924914000039],
              [106.63228000500001, 22.592181276000112],
              [106.6316530350001, 22.593238325000087],
              [106.63106333400006, 22.594425430000072],
              [106.63034814300005, 22.596910728000097],
              [106.63029193600001, 22.597845398000068],
              [106.63123454900003, 22.59936638900006],
              [106.63166464400008, 22.600988230000048],
              [106.63188139199998, 22.601942627000057],
              [106.63188928600002, 22.602612158000035],
              [106.63129092700007, 22.604148803000079],
              [106.63017548600006, 22.605403706000082],
              [106.62956470400006, 22.605888213000114],
              [106.62833523800003, 22.606187669000022],
              [106.62747172600008, 22.606311470000051],
              [106.62359988200009, 22.605941592000086],
              [106.622880717, 22.605948861000066],
              [106.61424797200009, 22.605277714000046],
              [106.61205019700006, 22.605123014000036],
              [106.60950823800005, 22.604569686000083],
              [106.607719252, 22.603913511000059],
              [106.60598938400004, 22.602221400000033],
              [106.60264755900003, 22.597405278000068],
              [106.60398944500012, 22.596875067000077],
              [106.604773741, 22.596128941000067],
              [106.60688750800001, 22.586952939000057],
              [106.60730683300007, 22.585410729000088],
              [106.60741286200005, 22.58423368000004],
              [106.60697738100008, 22.582314206000028],
              [106.60516917900003, 22.580793203000034],
              [106.60101536200007, 22.578910814000075],
              [106.59963725900009, 22.576860976000113],
              [106.59951972300004, 22.5746291190001],
              [106.59989288800001, 22.571162481000059],
              [106.601902216, 22.566268721000071],
              [106.60187110200009, 22.563575645000086],
              [106.60059477500009, 22.560381950000071],
              [106.59849233100005, 22.557196447000116],
              [106.59628872800005, 22.555112883000028],
              [106.59395088700006, 22.553837856000044],
              [106.59155452300003, 22.5526480300001],
              [106.58975570400005, 22.551896284000073],
              [106.58906137100007, 22.551390121000026],
              [106.58826673400004, 22.55090331500007],
              [106.58784033100002, 22.547695644000051],
              [106.58795062100009, 22.543799853000031],
              [106.58791431900002, 22.540626748000037],
              [106.58717522500007, 22.536979788000089],
              [106.58603424800008, 22.53352809000004],
              [106.58433389400007, 22.529312339000015],
              [106.58180033400005, 22.524463462000099],
              [106.57970619000007, 22.521918879000054],
              [106.57913514100007, 22.520128866],
              [106.57911618900002, 22.51846169],
              [106.57895055600008, 22.516733893000037],
              [106.57802299600007, 22.507057425000099],
              [106.57771713700006, 22.504366991000069],
              [106.57796914900005, 22.502312387000067],
              [106.57890061700003, 22.499481585000055],
              [106.57998306700006, 22.49685312200009],
              [106.58117998200007, 22.492149722000043],
              [106.58288316600003, 22.479832452],
              [106.58299008900008, 22.478504780000016],
              [106.58298297100004, 22.477878686000082],
              [106.58292836900004, 22.477297784000072],
              [106.58282528400004, 22.476672629000035],
              [106.582530725, 22.476094086000039],
              [106.58185023900009, 22.475340415000034],
              [106.58107479200007, 22.474677128000039],
              [106.58018707900007, 22.473915450000078],
              [106.57752980300003, 22.471633468],
              [106.57698009900002, 22.470883923000073],
              [106.57656071500004, 22.470560970000086],
              [106.57544361599999, 22.470628176000048],
              [106.57343824700007, 22.47217631700007],
              [106.5728783280001, 22.472395733000013],
              [106.57163365700005, 22.472441193000044],
              [106.57088720800002, 22.472410159000056],
              [106.56864706100004, 22.471858893],
              [106.56701828200009, 22.470871847000048],
              [106.56609716700008, 22.469636739000087],
              [106.56553230900006, 22.468880525000046],
              [106.56427259900002, 22.466457179000088],
              [106.56309317700004, 22.464319585000055],
              [106.56214646200004, 22.462322989000057],
              [106.5604305590001, 22.458464989000056],
              [106.56025006500008, 22.458087397000078],
              [106.55978518900008, 22.457114880000084],
              [106.559127248, 22.45492396200008],
              [106.55808013200004, 22.45144449200005],
              [106.55779553000012, 22.448293690000028],
              [106.55764701, 22.438789870000072],
              [106.55772935500006, 22.436474272000062],
              [106.55804379900006, 22.435565447],
              [106.56008248100008, 22.434036107000033],
              [106.56041526800007, 22.433791398000032],
              [106.56200088900009, 22.432625451000021],
              [106.56391683100007, 22.431085295000038],
              [106.56494526200008, 22.429968712000026],
              [106.56510513600007, 22.428452116000038],
              [106.56468640200006, 22.425843165000089],
              [106.55913779300008, 22.423553185000067],
              [106.55830541800003, 22.423191899000102],
              [106.55479859900004, 22.421555618000077],
              [106.553282369, 22.420867012000123],
              [106.55177495100007, 22.420969401000065],
              [106.54923049300004, 22.421081719000121],
              [106.54659078500003, 22.421107007000082],
              [106.54404243400003, 22.420867704],
              [106.54043510100007, 22.420558402000076],
              [106.53580579100003, 22.420322791000032],
              [106.53504702200004, 22.42025521100004],
              [106.53423423100003, 22.420123428000061],
              [106.53355834200005, 22.419823943000061],
              [106.52753897900001, 22.414726249000068],
              [106.52731664000001, 22.414240048000075],
              [106.52749987300007, 22.413925121000133],
              [106.52790899100007, 22.413632759],
              [106.52849619700008, 22.413055491000122],
              [106.52951910400007, 22.411952199000048],
              [106.53091003800004, 22.409829441000092],
              [106.53099660500001, 22.409125424000109],
              [106.51868618100011, 22.399792337000065],
              [106.51744451800003, 22.399279980000038],
              [106.51674698000005, 22.399101210000083],
              [106.51547745300009, 22.399470846000021],
              [106.51377816800009, 22.400240904000057],
              [106.51163551399999, 22.401616415],
              [106.50750592100007, 22.403325097000071],
              [106.503205763, 22.405348123000032],
              [106.50205886600006, 22.405482325000037],
              [106.50089842400004, 22.405496742000075],
              [106.49970935900006, 22.405460557000097],
              [106.49772868700006, 22.405316606],
              [106.49649035900009, 22.405163358000024],
              [106.49534035800006, 22.405009276000065],
              [106.49295175500005, 22.404383133000039],
              [106.48926038000008, 22.403010773000084],
              [106.48821597100007, 22.402317177000072],
              [106.4882066170001, 22.401438261000095],
              [106.48848004000007, 22.400556736000077],
              [106.48950852100009, 22.399756154],
              [106.49110631400004, 22.399301924000078],
              [106.49213757200002, 22.398764994000061],
              [106.4926023180001, 22.398145400000047],
              [106.49278332800006, 22.397440522000075],
              [106.49258730700009, 22.396739124000042],
              [106.49173144900007, 22.396043817000063],
              [106.48993206200005, 22.39526929900002],
              [106.48691011900003, 22.39476971600007],
              [106.48378833900001, 22.393743574000077],
              [106.48245844400003, 22.392788869000043],
              [106.48207116800005, 22.391825503000092],
              [106.48225130400003, 22.391032737000046],
              [106.48309401800003, 22.390497612],
              [106.48553818500002, 22.38985986000008],
              [106.48769954700006, 22.389224687000109],
              [106.488447992, 22.388690388000043],
              [106.48900510200009, 22.387894155000062],
              [106.48899293600006, 22.386751549000024],
              [106.48831912900009, 22.385439237000071],
              [106.48669812700008, 22.383696125000085],
              [106.48390415200001, 22.382447209000041],
              [106.48191367700008, 22.381410639000073],
              [106.47954995599999, 22.380729065000082],
              [106.47813340600001, 22.380478313000118],
              [106.47794211700008, 22.380216360000112],
              [106.47793748400007, 22.379776895000077],
              [106.47821466100008, 22.379246946000109],
              [106.47877365500005, 22.378626529000108],
              [106.48027513700004, 22.377997493000024],
              [106.48206401400007, 22.37780531300011],
              [106.48479536600003, 22.3776044640001],
              [106.48724022500008, 22.377054601000076],
              [106.48779917500005, 22.37643415000008],
              [106.48788782000001, 22.375905926000073],
              [106.48787847900005, 22.375027007000092],
              [106.48661872600007, 22.371786233000087],
              [106.48565854100003, 22.370124931000035],
              [106.48452202800006, 22.369607954000067],
              [106.47713672000006, 22.366423164000082],
              [106.47524170200003, 22.365473540000025],
              [106.463971137, 22.361288371000043],
              [106.4629890140001, 22.360761453000052],
              [106.45678365800003, 22.359277813000041],
              [106.45413464900005, 22.35865866000006],
              [106.45193415800006, 22.358256551000096],
              [106.45023788900005, 22.358271753000025],
              [106.44816921000003, 22.358729788000083],
              [106.444787531, 22.359814822000068],
              [106.44234638500009, 22.360715621000082],
              [106.44075878500009, 22.362136198000115],
              [106.43945117099999, 22.363290572000061],
              [106.43804657700012, 22.364182086000106],
              [106.43654141400002, 22.364459152000023],
              [106.43409472300009, 22.364832463000091],
              [106.43258324000007, 22.364494239000088],
              [106.43116152800005, 22.363715682000063],
              [106.42859368800003, 22.361452889000063],
              [106.42688483700003, 22.360237322000032],
              [106.42537521400001, 22.360074814000086],
              [106.42344464200005, 22.360223660000052],
              [106.42222309700006, 22.360585999000101],
              [106.42091264800007, 22.36147654400007],
              [106.41914346400006, 22.363601735000046],
              [106.41681852800008, 22.366698723000056],
              [106.41374042600003, 22.369890155000057],
              [106.41233824500002, 22.371045140000057],
              [106.40874734099999, 22.372423571000063],
              [106.40495190900002, 22.373405670000054],
              [106.3989909790001, 22.374540373000059],
              [106.39484233200004, 22.374625146000056],
              [106.39408788600005, 22.374587697000067],
              [106.39313968400003, 22.374024481],
              [106.3914230930001, 22.372017466000038],
              [106.38766845600004, 22.368841209000045],
              [106.38405492600002, 22.365619697000035],
              [106.38379385899999, 22.365452569000063],
              [106.38215787899999, 22.364405245000093],
              [106.37466771100003, 22.359897998000022],
              [106.37051506600007, 22.35931788000007],
              [106.36522812600006, 22.358395597000055],
              [106.36192543000008, 22.357983892],
              [106.360040618, 22.357999736000075],
              [106.35872807300004, 22.358713989000108],
              [106.35301415800004, 22.362365950000118],
              [106.35165823100002, 22.363476094000042],
              [106.34017463400004, 22.374911354000027],
              [106.33943071300004, 22.375972378000064],
              [106.33953506300006, 22.377026376000053],
              [106.34020157000005, 22.377724098000051],
              [106.3416255170001, 22.37876716100002],
              [106.34229288200007, 22.379552772000096],
              [106.34296870200004, 22.381217358000072],
              [106.3432658200001, 22.382709278000057],
              [106.34313163200001, 22.386089893000083],
              [106.34283901300005, 22.388938558000056],
              [106.34282527500007, 22.390538928000048],
              [106.34276220300009, 22.3916387740001],
              [106.34264874300003, 22.392076293000059],
              [106.34190475000001, 22.393137323000097],
              [106.33686654100006, 22.398629164000013],
              [106.33471429700006, 22.400317152000056],
              [106.33246857200007, 22.402093771000061],
              [106.331254766, 22.403334443000041],
              [106.33126145600002, 22.404037634000048],
              [106.33136829100006, 22.405355337000096],
              [106.33185558400005, 22.407021519000025],
              [106.33253310700007, 22.408861945000069],
              [106.33254065100007, 22.409653021000061],
              [106.33217444300006, 22.410798806000095],
              [106.33114743900009, 22.411862131000056],
              [106.32880553500011, 22.413463703000055],
              [106.32777181000003, 22.4138238130001],
              [106.32739468600009, 22.413826907],
              [106.32625832400004, 22.413308811000029],
              [106.32445202200006, 22.411741331000101],
              [106.32226531400005, 22.409825325000021],
              [106.3204657480001, 22.408960986000089],
              [106.3186719990001, 22.408711912000086],
              [106.31716441300006, 22.408812115000032],
              [106.31476655900008, 22.409490927000064],
              [106.31326473000007, 22.410206375000108],
              [106.31165456900008, 22.410823358000087],
              [106.30903496300004, 22.41201985800005],
              [106.30629657900005, 22.413709988],
              [106.30145632000004, 22.416692477000041],
              [106.29402107400009, 22.421501043000092],
              [106.28526166500009, 22.428461431000102],
              [106.28430757300001, 22.42967250100012],
              [106.28398769900011, 22.432583331000089],
              [106.28367080300009, 22.435817257000053],
              [106.28094372900004, 22.440847623000067],
              [106.27665799900009, 22.446051839000077],
              [106.27218387000009, 22.44964173000001],
              [106.26856148400005, 22.451609100000077],
              [106.24505256000002, 22.459062938000059],
              [106.24004129400008, 22.46087883400012],
              [106.23737063200008, 22.460899343000037],
              [106.2357745680001, 22.460531807000095],
              [106.21788354200002, 22.454901846000027],
              [106.21416122900006, 22.452561679000027],
              [106.21292219800003, 22.451076577000073],
              [106.21206119500003, 22.449676516000046],
              [106.21152913600005, 22.448142135000104],
              [106.21017755600005, 22.444548063000092],
              [106.20912573400007, 22.442885698000033],
              [106.20760165700008, 22.441138947000105],
              [106.20446312200002, 22.438085618000066],
              [106.20237259500006, 22.43625514000005],
              [106.20047224500003, 22.434599023000068],
              [106.19913469800012, 22.432587081000044],
              [106.19285384100002, 22.41902676100009],
              [106.1916342330001, 22.417042444000046],
              [106.19108273700003, 22.41505320100012],
              [106.19107105700004, 22.413682891000072],
              [106.19119299500009, 22.412311588000122],
              [106.19170833100002, 22.410065315000068],
              [106.19169770800002, 22.408819570000027],
              [106.19112179900006, 22.405957935000039],
              [106.18910160400007, 22.400232448000068],
              [106.18541796200002, 22.392421265000095],
              [106.18474715700003, 22.390712453000013],
              [106.18298356200003, 22.388300943000061],
              [106.18299756500004, 22.389956541000089],
              [106.18229950600001, 22.391396592000042],
              [106.17982124000004, 22.392297739000085],
              [106.17775398000006, 22.392809515000067],
              [106.17587307900004, 22.394368534000094],
              [106.17505562300008, 22.39569903500005],
              [106.17480387100004, 22.397891775000019],
              [106.17556231100008, 22.401103128000074],
              [106.17564788800006, 22.401630945000051],
              [106.17557151700004, 22.402197672000042],
              [106.17505922300002, 22.402736439000037],
              [106.17103441600008, 22.40627110400002],
              [106.16852740500003, 22.40856775600006],
              [106.16642791500004, 22.409908071000118],
              [106.16588437200002, 22.410185412000061],
              [106.16495127700009, 22.410486864000042],
              [106.16331606000006, 22.411102800000023],
              [106.15576498300003, 22.410629115000091],
              [106.15457841200008, 22.410306452000036],
              [106.152901908, 22.408000427000019],
              [106.15003711300002, 22.405150933000058],
              [106.145387715, 22.401210222000053],
              [106.14097624100003, 22.397378062000058],
              [106.13823926300006, 22.395631252000037],
              [106.13658114500001, 22.39553253600004],
              [106.13445402600004, 22.395988996000057],
              [106.13292411400002, 22.397103543000085],
              [106.13139954500009, 22.3988803230001],
              [106.12927858800002, 22.400109347000097],
              [106.12811831000003, 22.400704064000095],
              [106.124584181, 22.401917132000015],
              [106.12133817800002, 22.402866269000029],
              [106.11882741700002, 22.403327894000121],
              [106.11764362400004, 22.403336087000092],
              [106.11444030800003, 22.402475145],
              [106.11087314400001, 22.400512820000081],
              [106.1092123600001, 22.400082690000112],
              [106.10720081500001, 22.400206866000083],
              [106.10543038200008, 22.400881271000017],
              [106.10389930400005, 22.401885171000032],
              [106.10319600700004, 22.402773034000042],
              [106.10138442700007, 22.405992791000081],
              [106.10010515900009, 22.409615134000127],
              [106.0981557640001, 22.413927453000056],
              [106.09784850600008, 22.415282642000101],
              [106.09797644800008, 22.416495976000036],
              [106.09930046500003, 22.419246528000066],
              [106.10027545000005, 22.422772120000012],
              [106.10073315500009, 22.426140430000082],
              [106.10080575300006, 22.429073315000011],
              [106.10044832400007, 22.434461821000085],
              [106.09973252200008, 22.436518388000067],
              [106.09832117500009, 22.437742192000073],
              [106.09432034400001, 22.440970434000107],
              [106.09244043300006, 22.442859663000014],
              [106.09221311400009, 22.444075407000064],
              [106.09222002500005, 22.444958415000116],
              [106.0927049320001, 22.446390095000091],
              [106.09365920000002, 22.447266694000056],
              [106.09758784100009, 22.449889189000061],
              [106.10068592500009, 22.45229649300002],
              [106.10325515000004, 22.455204062000078],
              [106.11736990600004, 22.472657531],
              [106.11761387500006, 22.473538887000096],
              [106.11751048300003, 22.475416086000074],
              [106.11575951400002, 22.478629262000105],
              [106.11389443100006, 22.482395113000031],
              [106.11350149300006, 22.4851021630001],
              [106.11364379100011, 22.48808146300005],
              [106.11450831000005, 22.49249072500001],
              [106.1152420490001, 22.495355554000085],
              [106.11668656700007, 22.49821543800001],
              [106.11717016300003, 22.499426267000011],
              [106.11729483500004, 22.500198071000099],
              [106.11754900100001, 22.502635598000076],
              [106.11771247500005, 22.504203314000023],
              [106.11776574500001, 22.50859045300011],
              [106.11767354800003, 22.510430519000032],
              [106.11762223500004, 22.511454618000037],
              [106.11739238800003, 22.512339259000044],
              [106.11644992700003, 22.513008083000052],
              [106.11479572900004, 22.513571446000036],
              [106.11118977700002, 22.514534600000054],
              [106.10315101400005, 22.516797490000059],
              [106.10031193100005, 22.517368819000083],
              [106.0992517390001, 22.518148730000043],
              [106.09878219000005, 22.51870384200005],
              [106.09786262900008, 22.519671322000079],
              [106.09670929400005, 22.521425411000081],
              [106.09429396000004, 22.526343745000062],
              [106.09282224400005, 22.52923059200009],
              [106.09294941800007, 22.530333536000043],
              [106.09390689700008, 22.531541225000048],
              [106.09640564100006, 22.53284879000006],
              [106.0984295800001, 22.534049170000081],
              [106.09914751900008, 22.534927314000086],
              [106.09915364100006, 22.535699935000061],
              [106.09880253000004, 22.536254242],
              [106.09832855700009, 22.536257477],
              [106.09696917600006, 22.535896051000037],
              [106.09483056200003, 22.53555950500008],
              [106.09322028200002, 22.535468227000074],
              [106.0920683790001, 22.535812347000039],
              [106.09157527100007, 22.536413812000063],
              [106.090648125, 22.539069245000043],
              [106.09054436500004, 22.540946421000015],
              [106.0909690940001, 22.542212918000089],
              [106.09324143700009, 22.544846636000045],
              [106.09384264100001, 22.545946354000087],
              [106.09385046700002, 22.546939727000037],
              [106.09326578200006, 22.547937130000051],
              [106.09196831800008, 22.548718607000048],
              [106.08572298100009, 22.553286496000077],
              [106.08371177600003, 22.553741591000076],
              [106.08063054600009, 22.553762323000015],
              [106.07707697500005, 22.554006924000092],
              [106.07293852600003, 22.555248774000077],
              [106.07187533399998, 22.555697389000073],
              [106.07176109500004, 22.556250062000061],
              [106.07249947200003, 22.559777326000052],
              [106.07274589100007, 22.5609898740001],
              [106.07407094000011, 22.563740555000052],
              [106.07598264200001, 22.565714628000052],
              [106.07967222300002, 22.567676744000039],
              [106.08027171000006, 22.568555758000031],
              [106.08075440100004, 22.56965632000005],
              [106.08088066300009, 22.570648904000024],
              [106.080897873, 22.572856398000063],
              [106.08043410800009, 22.574184081000048],
              [106.07925571100002, 22.575075052000045],
              [106.07735926900006, 22.575087773000035],
              [106.07213722000007, 22.574239654000024],
              [106.06964304400003, 22.57359398800007],
              [106.06582896100011, 22.570859801000061],
              [106.06458149300001, 22.570481739000044],
              [106.06341295600005, 22.570533086000033],
              [106.06055927100002, 22.571501765000065],
              [106.05712876600003, 22.572407411000128],
              [106.0532341680001, 22.574640610000074],
              [106.04922510500005, 22.577426375000087],
              [106.04650806500004, 22.578658295000054],
              [106.04591540100003, 22.578662148000014],
              [106.04532024800008, 22.578334876000028],
              [106.0446024030001, 22.577456497000092],
              [106.04362838800006, 22.574040993000018],
              [106.04241736300006, 22.570627021000078],
              [106.04049693600005, 22.567438397000039],
              [106.03918078100004, 22.56579118300008],
              [106.03608273700004, 22.563603545],
              [106.03251564300004, 22.562081147000065],
              [106.02669109100003, 22.559800421000098],
              [106.02491171500007, 22.559591009000087],
              [106.02396439200002, 22.559707428000038],
              [106.02243181400004, 22.560821005000072],
              [106.02071939800003, 22.561659752000104],
              [106.01917865500009, 22.561669527000028],
              [106.01787171700005, 22.56123628400006],
              [106.01094346200003, 22.558465262000112],
              [106.00598209800003, 22.556455960000044],
              [106.00226781600003, 22.554937205000058],
              [106.00147887300002, 22.553939749000037],
              [106.00085009100005, 22.550343919000056],
              [106.000328853, 22.549225048000032],
              [105.998471792, 22.548465611000076],
              [105.99488706700005, 22.547319265000084],
              [105.98585300500007, 22.545009594000121],
              [105.98479132300005, 22.544628613000036],
              [105.98373612600005, 22.543628680000083],
              [105.98110335700009, 22.540633632000073],
              [105.978853379, 22.53925145500002],
              [105.97646244100007, 22.538610797000032],
              [105.97193658400005, 22.538322162000043],
              [105.96994134800008, 22.538056438000083],
              [105.96874916300001, 22.537426582000109],
              [105.96677742500003, 22.534932549000075],
              [105.96363845200003, 22.529456516000046],
              [105.962315765, 22.528577812],
              [105.95495322100003, 22.524866436000046],
              [105.95255213300004, 22.524546442000123],
              [105.95046687000008, 22.524676328000034],
              [105.94755956400004, 22.526735688000073],
              [105.94448874400007, 22.529091500000042],
              [105.94272241000003, 22.529373257000081],
              [105.93230374700005, 22.529277093000033],
              [105.93118333700008, 22.52911771200008],
              [105.92911079900001, 22.528055502000122],
              [105.92466287900005, 22.524289152000065],
              [105.92260809200003, 22.521587974000035],
              [105.92151983900006, 22.518448739000085],
              [105.92033696100006, 22.514132470000092],
              [105.91817592200005, 22.509872198000025],
              [105.91210211300003, 22.50010408400005],
              [105.91020894600007, 22.498308209000051],
              [105.90742427800005, 22.497324663000114],
              [105.90419533600004, 22.496610483],
              [105.90375875300008, 22.496727800000116],
              [105.90271980200001, 22.49700697800003],
              [105.9016796440001, 22.497954700000022],
              [105.90048193600002, 22.499858403000061],
              [105.89660190200007, 22.504882828000028],
              [105.89332358800007, 22.508681788000025],
              [105.8919875820001, 22.509763430000085],
              [105.88577718700006, 22.512577109000098],
              [105.87898415100004, 22.514837959000111],
              [105.87342510200007, 22.516832198000088],
              [105.86845991800004, 22.519582028000059],
              [105.86302186600004, 22.521544019000125],
              [105.854713921, 22.521800064000082],
              [105.85110837500011, 22.521317882000069],
              [105.84715427800006, 22.519713422],
              [105.84020532000001, 22.516849215000079],
              [105.83695968700005, 22.516482157000084],
              [105.83370402300007, 22.517010047000063],
              [105.83285781200004, 22.517337501000064],
              [105.83211811600003, 22.518896733000112],
              [105.83210931300002, 22.51967984500007],
              [105.83241756000002, 22.524382002000017],
              [105.83179567300009, 22.526166119000024],
              [105.83082654600001, 22.526716133000058],
              [105.82962300600008, 22.526704446000068],
              [105.82842198600009, 22.526469011000039],
              [105.82232058300011, 22.52316498000004],
              [105.82016562700007, 22.522137007000076],
              [105.81800184200009, 22.521892120000061],
              [105.81571137600011, 22.522205388000046],
              [105.81197160600003, 22.522952],
              [105.80253567700001, 22.524583222000061],
              [105.79917981900007, 22.529629442000051],
              [105.79813955000007, 22.53106213100002],
              [105.79636251700003, 22.533377871000049],
              [105.79482434800003, 22.535020522000067],
              [105.79335221700009, 22.536663819000054],
              [105.79284943200005, 22.537303560000055],
              [105.79244326700002, 22.538159162000056],
              [105.79181792200006, 22.540227601000048],
              [105.79103711400003, 22.542908895000032],
              [105.78895283700007, 22.547120963000019],
              [105.7877006570001, 22.548851419000115],
              [105.78525527000001, 22.551763448000095],
              [105.78440279000009, 22.552216682000022],
              [105.78375931200004, 22.552260096000062],
              [105.783159799, 22.552341914000081],
              [105.78264323200007, 22.552320363000078],
              [105.78200205300004, 22.552164609000066],
              [105.78166485600003, 22.551878924000057],
              [105.78097755500001, 22.551075365000095],
              [105.78025990500005, 22.550335579000077],
              [105.77975566400008, 22.550166913000069],
              [105.7787876890001, 22.549908231000067],
              [105.77741536100007, 22.549877377000094],
              [105.77644930100007, 22.550017160000024],
              [105.77514204699999, 22.550664915000063],
              [105.77457462800002, 22.551087016000082],
              [105.77357507200001, 22.551798479000063],
              [105.77168911300002, 22.552959380000104],
              [105.76864847800006, 22.554972734000067],
              [105.76658763200007, 22.556282922000022],
              [105.76622417500002, 22.556754560000059],
              [105.76626319500005, 22.557800555000092],
              [105.766249574, 22.558067345000055],
              [105.76784719200002, 22.563868066000033],
              [105.76876394900002, 22.56596240600004],
              [105.769548419, 22.566979193000059],
              [105.77406855700008, 22.57045468300003],
              [105.77693421100004, 22.573039198000089],
              [105.77714668900003, 22.573444888000083],
              [105.77713505700005, 22.574453713000061],
              [105.77705569700005, 22.57505828799999],
              [105.77653818100005, 22.575994816000033],
              [105.77558016400009, 22.577465056000086],
              [105.77433183600002, 22.57899965000005],
              [105.77213785700012, 22.580928389000029],
              [105.77001232900002, 22.583194089000088],
              [105.76619620400001, 22.587662483000059],
              [105.76436397700003, 22.589594717000061],
              [105.76393993300012, 22.589778615000085],
              [105.76344869600007, 22.589811703000073],
              [105.76263158800005, 22.589727522000125],
              [105.76010200200008, 22.5891701400001],
              [105.75243153900004, 22.587496979000043],
              [105.75177715200007, 22.587490399000032],
              [105.75120279300006, 22.587636640000049],
              [105.74391829200006, 22.594935973000041],
              [105.74333856600006, 22.595538180000041],
              [105.74328564300004, 22.596563731000074],
              [105.74352304000004, 22.597250186000025],
              [105.74407964500008, 22.598623918],
              [105.74480164800005, 22.599847298000064],
              [105.74552809500003, 22.600690693000033],
              [105.74836725200002, 22.602771459000067],
              [105.75007006500005, 22.604080697000128],
              [105.75095573400006, 22.605305689000055],
              [105.75135323000008, 22.606297759000071],
              [105.75166981800007, 22.607213001000034],
              [105.75173655700009, 22.608505747000059],
              [105.75148049800003, 22.609415226000081],
              [105.74965126800004, 22.611880947000081],
              [105.74499320300001, 22.61884688100011],
              [105.73846958000007, 22.628082873000018],
              [105.73726920900005, 22.62951102400006],
              [105.73589682600002, 22.630636628000019],
              [105.73132611600002, 22.633424418000025],
              [105.72635897700007, 22.636644091000058],
              [105.72583340000003, 22.636921130000061],
              [105.72622830200001, 22.638718286000078],
              [105.72835628400004, 22.64085073400004],
              [105.73049608800008, 22.641983409000055],
              [105.73276336300009, 22.642450786],
              [105.73598880200007, 22.642816746000079],
              [105.73758810700005, 22.643741037],
              [105.73868106800008, 22.64492691800006],
              [105.74190839700003, 22.652889585000047],
              [105.74577534500006, 22.660271105000085],
              [105.74918708700008, 22.666032540000103],
              [105.7527811360001, 22.66973984600007],
              [105.76265963100005, 22.677034266000092],
              [105.76610452600008, 22.680005553000093],
              [105.76891356300005, 22.683264110000053],
              [105.77078234200005, 22.685779041000039],
              [105.77450818200003, 22.691836574000043],
              [105.77449291500002, 22.69315796700004],
              [105.77306110100002, 22.693877908000012],
              [105.77052394500006, 22.694439992000071],
              [105.76377625300007, 22.696178911000061],
              [105.75963732900001, 22.69792977700007],
              [105.7569097680001, 22.700053165000078],
              [105.75456544800002, 22.702419327000101],
              [105.752851999, 22.705867138000087],
              [105.75126022000002, 22.709913533000048],
              [105.750839247, 22.712896332000106],
              [105.75108548600004, 22.713854651000084],
              [105.75184096400002, 22.715296009000063],
              [105.75400522300004, 22.717348903000101],
              [105.75654729200005, 22.720122426000074],
              [105.75717552000006, 22.721442985000095],
              [105.75716021600005, 22.722757090000108],
              [105.75661058700004, 22.725738526000058],
              [105.75504657800005, 22.727395526000024],
              [105.75310615700008, 22.728212391000021],
              [105.74988139600006, 22.728777364000052],
              [105.74819975900002, 22.729477314000057],
              [105.74623693200006, 22.731415669000029],
              [105.74420109500001, 22.735194665000066],
              [105.74339480300009, 22.736691280000088],
              [105.74103437300001, 22.740394739000088],
              [105.73978868300006, 22.741242299000049],
              [105.73793022300003, 22.741653590000062],
              [105.73005152300007, 22.741717171000083],
              [105.72712710700004, 22.740827354000089],
              [105.72467623300003, 22.739082172000025],
              [105.72284488400003, 22.737199906000072],
              [105.71995790100007, 22.733156489000095],
              [105.71904143000009, 22.732286997000095],
              [105.71765314100007, 22.732129486000119],
              [105.71114778200004, 22.733639987000039],
              [105.70991222400005, 22.733627346000063],
              [105.70883793600007, 22.733042918000109],
              [105.70138140700004, 22.723648035000018],
              [105.69940455600008, 22.72104720800003],
              [105.69879024900006, 22.720754163000031],
              [105.69785335900008, 22.721604702000064],
              [105.697369495, 22.72332007100006],
              [105.69591953600012, 22.728322782000099],
              [105.69466857800009, 22.729600141],
              [105.69343304700008, 22.729587409000104],
              [105.69282042900002, 22.72915101400006],
              [105.69161068600002, 22.726988130000066],
              [105.69060064900003, 22.724467331000042],
              [105.69011100100002, 22.723245263000088],
              [105.68983480300008, 22.720518527000074],
              [105.68891851800004, 22.719648887000062],
              [105.68691260700007, 22.719484791000077],
              [105.68304489500005, 22.720018236000072],
              [105.67683297700006, 22.722821079000077],
              [105.67119473000011, 22.725514713000017],
              [105.6667364580001, 22.7259848630001],
              [105.6645167070001, 22.725445117000028],
              [105.65726154500004, 22.719176321000042],
              [105.65238785900003, 22.713534035000023],
              [105.646189475, 22.702573013000027],
              [105.64497690700004, 22.700696456000102],
              [105.642976494, 22.700101916000023],
              [105.64033763600005, 22.700136096],
              [105.62391664900011, 22.700478926000045],
              [105.61946522700003, 22.700431607000034],
              [105.61391694700008, 22.699080950000017],
              [105.59930371200005, 22.688333740000033],
              [105.59876985200002, 22.686519792000048],
              [105.59849810100009, 22.686000238000048],
              [105.59852064100009, 22.68419224300002],
              [105.59912530600012, 22.680323926000057],
              [105.60005961900006, 22.672325912000076],
              [105.60008857800005, 22.670001277000026],
              [105.59899521200001, 22.66843958200004],
              [105.59768120100009, 22.667791648000076],
              [105.59318960000007, 22.66751147800008],
              [105.5839334830001, 22.668803018000091],
              [105.58212011500007, 22.668867574000018],
              [105.57893666200002, 22.668980874000063],
              [105.57120194100006, 22.668433244000049],
              [105.566469457, 22.667454257000053],
              [105.56349659700005, 22.665566796000022],
              [105.56201605200003, 22.664159324000096],
              [105.55907853800008, 22.659489471000072],
              [105.55634672100008, 22.658300182000076],
              [105.5491118620001, 22.657757239000098],
              [105.54636832900012, 22.657495268],
              [105.54314909100003, 22.655372879000076],
              [105.54043519900002, 22.652792213000069],
              [105.53946611700003, 22.65046259700005],
              [105.53928751700009, 22.64489507600009],
              [105.53913556700005, 22.637240656000031],
              [105.53790507900001, 22.63583569400005],
              [105.53542338700002, 22.634648837000078],
              [105.53005163600001, 22.634562332000115],
              [105.52978435800001, 22.634619764000071],
              [105.51966557000006, 22.636793655000083],
              [105.51466652100007, 22.637201956000027],
              [105.51092172500002, 22.637160307000045],
              [105.49629736800004, 22.634995205000052],
              [105.49637952900009, 22.635550696000095],
              [105.49627228300001, 22.636668964000073],
              [105.49454988800005, 22.640783093000039],
              [105.49256048400012, 22.64403305600009],
              [105.49207005700003, 22.646094234000103],
              [105.49184317400001, 22.649277805000075],
              [105.49218487300004, 22.651520514000097],
              [105.49290639600005, 22.653078586000049],
              [105.49444778800004, 22.655765268000039],
              [105.49480632100004, 22.656716491000047],
              [105.49488560000003, 22.657750690000086],
              [105.49484865699999, 22.660591869000065],
              [105.49320304, 22.665912139000085],
              [105.49187697300005, 22.668049965000044],
              [105.48952982800009, 22.67026242000005],
              [105.48705529299998, 22.672916243000074],
              [105.48629617300006, 22.67428429400011],
              [105.486283601, 22.675247753000072],
              [105.48771747500007, 22.678980587000105],
              [105.48858357400005, 22.680779835000031],
              [105.49057700900005, 22.684347365000065],
              [105.49423418500004, 22.690891928000042],
              [105.49525044100002, 22.692555130000081],
              [105.4979028070001, 22.693823677000054],
              [105.50188053900006, 22.695795244000024],
              [105.50245748, 22.697040543000035],
              [105.50243963400004, 22.698416841000046],
              [105.50136437500007, 22.701295476000013],
              [105.49776692200004, 22.704283545000067],
              [105.48718143700003, 22.70870728200007],
              [105.48286210300006, 22.710172842000098],
              [105.48076304700001, 22.711938629000095],
              [105.47830355500008, 22.715158460000048],
              [105.47584241900002, 22.718448636000055],
              [105.47476309800007, 22.720583332000018],
              [105.47421575000007, 22.722236100000082],
              [105.47416711900004, 22.725943758000078],
              [105.47451210200009, 22.731705060000081],
              [105.47550067, 22.736497746000062],
              [105.4766174370001, 22.73953535800004],
              [105.47659953600005, 22.740901291000078],
              [105.47595485300009, 22.74220778500009],
              [105.47419944100005, 22.744035853000085],
              [105.47033806700004, 22.748009330000052],
              [105.46761135800003, 22.751272479000036],
              [105.46751528300008, 22.751994462000091],
              [105.46784040900008, 22.753604948000117],
              [105.46782984400008, 22.754408231000056],
              [105.46773588100004, 22.754969553000045],
              [105.46712465100009, 22.755364332000028],
              [105.46530574300004, 22.755424086000083],
              [105.46262013400006, 22.755554336000024],
              [105.457756616, 22.756704242000048],
              [105.45132529500006, 22.758559262000055],
              [105.44932962800006, 22.759389389000084],
              [105.44798014200003, 22.760746818000044],
              [105.44692285100004, 22.76238212100008],
              [105.444916049, 22.768674063000027],
              [105.44465783300004, 22.769264809000056],
              [105.44354278000006, 22.771815772000089],
              [105.44204706400004, 22.773034181000092],
              [105.43684023100008, 22.775171049000065],
              [105.42393152700004, 22.77790566500002],
              [105.42244299100007, 22.778574905000056],
              [105.42124112200001, 22.779933818000053],
              [105.42092134300002, 22.781714703000056],
              [105.42088272300002, 22.784597009000059],
              [105.42057396800006, 22.785554365000046],
              [105.41968080500004, 22.785955896000054],
              [105.41627263400005, 22.786328402000052],
              [105.412986222, 22.787380946000013],
              [105.41099592100008, 22.788323581000022],
              [105.40952334400001, 22.789465315000079],
              [105.40718956600008, 22.792721461000021],
              [105.40455781600012, 22.794911912000067],
              [105.40267275700003, 22.795759120000092],
              [105.40016977200005, 22.796116320000067],
              [105.39777605800006, 22.796088511000079],
              [105.39631510800007, 22.796361209000082],
              [105.39505314300001, 22.797312154000068],
              [105.39399670900008, 22.798458607000072],
              [105.39271640900002, 22.80076118500007],
              [105.39004248700012, 22.806040893000024],
              [105.38761196600009, 22.808716234000045],
              [105.38298382100005, 22.812234898000035],
              [105.38067955100009, 22.813270101000072],
              [105.37859261799998, 22.813631915000073],
              [105.37671911500004, 22.813609974000123],
              [105.373703328, 22.813381505000052],
              [105.3722090470001, 22.813305521000068],
              [105.37099979900006, 22.813156661],
              [105.36995764900007, 22.813240987000057],
              [105.36808453000008, 22.813826686000112],
              [105.36040895900004, 22.816614231000095],
              [105.35737311800003, 22.817537724000125],
              [105.3548276990001, 22.817507671000037],
              [105.35228837100004, 22.817034909000085],
              [105.35030992900005, 22.816273586000065],
              [105.34871566800003, 22.815159869000112],
              [105.34794598100002, 22.814622173000117],
              [105.34723416300001, 22.814318569000044],
              [105.34659477200005, 22.814532375000063],
              [105.34562905399999, 22.815332644000108],
              [105.34432786100007, 22.817383393000057],
              [105.34302561100006, 22.819507902000069],
              [105.34259833300004, 22.821642779000051],
              [105.34258203100003, 22.82282324400002],
              [105.34235670900004, 22.827616952000085],
              [105.34154185600003, 22.829009298000088],
              [105.34097787500008, 22.829519129000083],
              [105.33897126800005, 22.830253239000072],
              [105.33495399300003, 22.83125271400008],
              [105.32753381500007, 22.833353975000037],
              [105.32577179300003, 22.834570580000069],
              [105.32493480600006, 22.835703020000061],
              [105.32428985900007, 22.837789780000058],
              [105.32341181000008, 22.841872979000044],
              [105.32265623100008, 22.844529581000117],
              [105.32213374000003, 22.845189747000028],
              [105.32089540100006, 22.845650936000084],
              [105.31800679400007, 22.846663575000022],
              [105.30775861300006, 22.851050550000096],
              [105.30637911700006, 22.851827165000046],
              [105.30396219500001, 22.853384482000116],
              [105.30016234500002, 22.855956264],
              [105.29921487200005, 22.856420757000073],
              [105.29783269000009, 22.856669063000098],
              [105.29014926400004, 22.856576241000056],
              [105.28000726200003, 22.856908149000077],
              [105.27803267399999, 22.857793834000084],
              [105.27488794400003, 22.860484595000052],
              [105.27141186200001, 22.863474483000076],
              [105.26860682900009, 22.865259614000074],
              [105.26548338400002, 22.866434374000022],
              [105.264816513, 22.867335886],
              [105.26479064900008, 22.869154863000048],
              [105.26554991500002, 22.873257524000046],
              [105.266206579, 22.880278934000039],
              [105.26625965400004, 22.88084644300001],
              [105.26625103700003, 22.881452751],
              [105.26706421700008, 22.88176587400006],
              [105.26788385800005, 22.881624270000067],
              [105.27051273400005, 22.880746690000109],
              [105.27839492900003, 22.878416926000078],
              [105.29429024600009, 22.876032187000114],
              [105.29772362000004, 22.876073628000078],
              [105.30082361800002, 22.876565816000038],
              [105.30195379500002, 22.877341311000073],
              [105.30398850300006, 22.888794360000105],
              [105.30410688600008, 22.892396505000079],
              [105.30369896900008, 22.897401239000104],
              [105.30345988800011, 22.902407947000114],
              [105.30374932500007, 22.905855489000082],
              [105.30552565500005, 22.911669081000063],
              [105.30601033400009, 22.913240357000042],
              [105.307177059, 22.914350189000032],
              [105.30970133700005, 22.91500669100008],
              [105.31256336100006, 22.915667204000023],
              [105.31644518500008, 22.915870271000038],
              [105.3203226640001, 22.916386304000099],
              [105.32149167100002, 22.917339542000015],
              [105.3228121380001, 22.919546914000044],
              [105.32362819000004, 22.921591707000033],
              [105.32579744600011, 22.923496094000129],
              [105.32813779800004, 22.925245958000048],
              [105.33046732600005, 22.927778366000062],
              [105.33094355900002, 22.92997557700005],
              [105.33040648100008, 22.932160718000105],
              [105.33049397200004, 22.933500449000022],
              [105.33062767500009, 22.934024638000047],
              [105.33160712000003, 22.934558898000112],
              [105.33259381900005, 22.934570644000104],
              [105.33583763300004, 22.934478585000036],
              [105.34049463200007, 22.934141941000071],
              [105.34444142200005, 22.934188740000039],
              [105.35455436500004, 22.936921318000088],
              [105.35763392000003, 22.938525430000119],
              [105.35930211400003, 22.940243518000081],
              [105.35998180700005, 22.942080585000021],
              [105.36037959100001, 22.943914323000044],
              [105.360358069, 22.945481828000069],
              [105.36065542900009, 22.954630464000033],
              [105.36029098500005, 22.960635725000067],
              [105.36095820600011, 22.963387045000069],
              [105.36246953500009, 22.966278920000072],
              [105.36259438800003, 22.967456144000039],
              [105.36157345400002, 22.969926272000052],
              [105.3598439830001, 22.972649318000023],
              [105.35957060700004, 22.974082242000101],
              [105.36010620500004, 22.978577169000047],
              [105.36030746799999, 22.979970450000039],
              [105.36039164400003, 22.980312183000073],
              [105.36166341200001, 22.98117797300003],
              [105.36519662100005, 22.983429624000081],
              [105.36803037600004, 22.985502475000082],
              [105.37100073300005, 22.988324728000094],
              [105.3728091780001, 22.990249500000104],
              [105.37346402200011, 22.990665081000117],
              [105.37470868300007, 22.990883613000079],
              [105.37503610800006, 22.990887445000041],
              [105.37595613200007, 22.990898215000044],
              [105.37948206300004, 22.990667513000048],
              [105.38256307100005, 22.990771486000128],
              [105.38461400100002, 22.991067358000095],
              [105.38746010000006, 22.992256277000052],
              [105.38972380300004, 22.993098456000013],
              [105.39155185500003, 22.993595621000026],
              [105.40216724300004, 22.995554425000094],
              [105.40519058800007, 22.996147934000085],
              [105.41074636400003, 22.99621215800007],
              [105.41126042500012, 22.996324217000016],
              [105.41177233300002, 22.996595440000078],
              [105.41245106900004, 22.997240032000029],
              [105.41318138, 22.998309708000072],
              [105.41424251800004, 23.000338316000054],
              [105.4157096120001, 23.002000157000069],
              [105.4163912330001, 23.002432509000045],
              [105.417077147, 23.002546533000064],
              [105.41776518900009, 23.00250140100006],
              [105.420815873, 23.001422206000122],
              [105.42606509400008, 22.998723283000096],
              [105.42750198200005, 22.998368338000127],
              [105.42876136700006, 22.998435840000027],
              [105.42938857100009, 22.998655286000066],
              [105.43001151900003, 22.999193049000063],
              [105.43046051300006, 22.999888005000102],
              [105.43056797600006, 23.000419858000051],
              [105.43043710300007, 23.001638787000012],
              [105.43030764700002, 23.002751607000071],
              [105.43048985500009, 23.003386219000085],
              [105.43104738000002, 23.003840059000069],
              [105.43281417800003, 23.00403280500003],
              [105.43922850200006, 23.004882457000072],
              [105.440159615, 23.004893088000067],
              [105.44109761900008, 23.004386252000053],
              [105.44261150000001, 23.002592140000019],
              [105.44496678500012, 23.000548823000067],
              [105.44608752800012, 23.000302821000048],
              [105.44683126100001, 23.00039754700007],
              [105.44766236900004, 23.000924547000068],
              [105.44867168900007, 23.002057373000092],
              [105.45130800300005, 23.003719414000031],
              [105.45441568400005, 23.005275933000036],
              [105.46189381000001, 23.007533858000073],
              [105.465088436, 23.008439245000112],
              [105.46664952000003, 23.008674201000098],
              [105.46970303900008, 23.008418926000026],
              [105.47165798, 23.008440976000074],
              [105.47391999300004, 23.008901098000045],
              [105.47982386000011, 23.009295619000092],
              [105.48914054300003, 23.008409837000073],
              [105.48902604100007, 23.020183265000057],
              [105.48944441000005, 23.023820769000025],
              [105.49015609400004, 23.027171382000056],
              [105.49093018300005, 23.030030336],
              [105.49274138900007, 23.034896047000061],
              [105.49377748200001, 23.036980531000047],
              [105.49506842200006, 23.039909962000046],
              [105.49536975700003, 23.040405636000024],
              [105.49572807100002, 23.040824216000111],
              [105.49625492600009, 23.041192849000055],
              [105.49717144900006, 23.041721304000085],
              [105.49796237700005, 23.041921112000097],
              [105.49843665700004, 23.042030048000051],
              [105.49987993800008, 23.042166079000111],
              [105.50144503600009, 23.042313075000017],
              [105.50177875400007, 23.042472258000046],
              [105.50205348500006, 23.042863986000036],
              [105.502241595, 23.043462023000046],
              [105.50237305700004, 23.04411126600003],
              [105.50248665700012, 23.045990306000071],
              [105.50271892700006, 23.048384360000092],
              [105.50285828600005, 23.04905319600001],
              [105.50317956000006, 23.049982255000053],
              [105.50348733900005, 23.050812293000078],
              [105.50398991000006, 23.052286173000077],
              [105.50507060100004, 23.055219776000065],
              [105.50552878300002, 23.056662503000069],
              [105.50594633900002, 23.058662138000052],
              [105.50609949300008, 23.059259780000026],
              [105.506198376, 23.059906484000066],
              [105.50640388000005, 23.06060403000005],
              [105.50663895100004, 23.061221246000052],
              [105.50720792300004, 23.062216572000068],
              [105.50760384400003, 23.063022183000022],
              [105.51135578600005, 23.067498872000058],
              [105.51627151400008, 23.073563968000066],
              [105.518201597, 23.075825955000084],
              [105.51857608000007, 23.076576968000047],
              [105.51879322800001, 23.077610760000056],
              [105.51877760900004, 23.078819800000083],
              [105.51868745800004, 23.079850198000074],
              [105.51832795300005, 23.080913164],
              [105.51805078800004, 23.081550273000047],
              [105.517502886, 23.082326641000073],
              [105.51656715500012, 23.083383222000094],
              [105.515907672, 23.083873830000101],
              [105.51567120400006, 23.084333551000036],
              [105.51566752400002, 23.084618028000037],
              [105.51585131400005, 23.08526023200006],
              [105.51690688499998, 23.087326693000065],
              [105.52038217200003, 23.093561923000024],
              [105.52287582300002, 23.09831937900011],
              [105.52472808800007, 23.102134715000133],
              [105.5260025900001, 23.104678672000119],
              [105.52919432100003, 23.110869806000075],
              [105.52960035600006, 23.111576083000074],
              [105.52976366100008, 23.112048169000055],
              [105.53000618200006, 23.112490471000058],
              [105.53353495900009, 23.111852481000113],
              [105.53596445400004, 23.11107941600001],
              [105.538136787, 23.110143556000118],
              [105.54003861800007, 23.110084437000111],
              [105.54478348800008, 23.110696222000016],
              [105.54917666200004, 23.111783914000036],
              [105.55046451700011, 23.112437779000047],
              [105.55251165500002, 23.114539448000038],
              [105.55420405800008, 23.117356990000104],
              [105.5556556180001, 23.118732358000038],
              [105.55668636200002, 23.119223422000076],
              [105.56013239200004, 23.120060638000069],
              [105.56142269700005, 23.120299088000081],
              [105.56257584700008, 23.116870471000084],
              [105.56317324300002, 23.115103216000051],
              [105.56411194200007, 23.113172121000055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 15, code: "AD01", ten_tinh: "Đà Nẵng" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [111.21577997200004, 15.773420222000121],
              [111.20997363100004, 15.771371520000075],
              [111.20350179600004, 15.771468336000092],
              [111.19899060500008, 15.773835720000038],
              [111.19501973300007, 15.777180669000044],
              [111.19421039200007, 15.781299897000077],
              [111.19636954300002, 15.785046311000082],
              [111.19993605500005, 15.789264554000047],
              [111.2065027940001, 15.795080760000067],
              [111.21180334300008, 15.798287146000066],
              [111.21672262500005, 15.799527687000124],
              [111.22109084000007, 15.799133531000018],
              [111.22505907600005, 15.795623851000061],
              [111.22814195700005, 15.791470276000029],
              [111.228425864, 15.787358833000019],
              [111.22693678400007, 15.781795489000048],
              [111.22176923200007, 15.775958881000028],
              [111.21577997200004, 15.773420222000121],
            ],
          ],
          [
            [
              [112.57873884600011, 16.058745926000114],
              [112.57411799100014, 16.055700832000049],
              [112.56882676100011, 16.05176175000009],
              [112.56266609200011, 16.047701304000036],
              [112.55862708500005, 16.044728340000077],
              [112.55157711500009, 16.038123910000124],
              [112.547009577, 16.033891321000034],
              [112.54057139100003, 16.029028752000052],
              [112.533391743, 16.024569892000073],
              [112.52436050200006, 16.021267572000042],
              [112.51158162900002, 16.018323122000041],
              [112.5024410780001, 16.016559057],
              [112.49662790800006, 16.015004342000069],
              [112.49039415700005, 16.0140171460001],
              [112.480955855, 16.011839257000112],
              [112.47240276000002, 16.010480812000068],
              [112.46494735100009, 16.010189581000041],
              [112.45609630500005, 16.01123916800011],
              [112.45120569200006, 16.012178952000063],
              [112.44720613600006, 16.014217874000011],
              [112.445912976, 16.016309722000074],
              [112.44372621800008, 16.020157994000073],
              [112.44166935600008, 16.024047276000083],
              [112.44061748600008, 16.029018840000028],
              [112.44191318, 16.035066759000038],
              [112.44456280500003, 16.039286722000057],
              [112.44800560700004, 16.042590261],
              [112.4545843730001, 16.045828687000082],
              [112.46068878800007, 16.048626789000053],
              [112.46560689500004, 16.050324451000094],
              [112.472625729, 16.052428222000081],
              [112.476377531, 16.053924952000017],
              [112.48150309399999, 16.054492972000034],
              [112.48452795900008, 16.054654785000096],
              [112.49036553800008, 16.05588269800009],
              [112.49838482000004, 16.055939779000106],
              [112.50608789600004, 16.058316898000058],
              [112.52149779099999, 16.063227596000026],
              [112.53263756600003, 16.066634223000072],
              [112.54710764300003, 16.071562947000118],
              [112.55493889300007, 16.074355692000083],
              [112.56316774800004, 16.076122840000018],
              [112.57558200700012, 16.078722784000107],
              [112.57818978800006, 16.079016479000074],
              [112.5831754200001, 16.079040453000104],
              [112.58975296700005, 16.078444204000064],
              [112.591965694, 16.077953811000079],
              [112.59419728600008, 16.077209819000068],
              [112.59575640600011, 16.076265955000046],
              [112.59673323300002, 16.074876911000047],
              [112.59742401900007, 16.073366073],
              [112.59736602200007, 16.071673273],
              [112.5961148160001, 16.069831327000102],
              [112.59405924900003, 16.068408308000045],
              [112.5895205920001, 16.065788368000078],
              [112.58615170900006, 16.063827042000064],
              [112.58283944700011, 16.061829587000084],
              [112.57873884600011, 16.058745926000114],
            ],
            [
              [112.50243192100005, 16.047481395000048],
              [112.49815298000013, 16.045595040000116],
              [112.49374459900007, 16.045912368000081],
              [112.48260493500003, 16.046595284000098],
              [112.47515671400006, 16.046075732000098],
              [112.4691683230001, 16.043275622000053],
              [112.46398308299997, 16.040008661000101],
              [112.45344068699997, 16.036177830000042],
              [112.44940227800005, 16.032211754000066],
              [112.44790400700008, 16.027518006000015],
              [112.44990466200014, 16.023426699000076],
              [112.45371836600012, 16.017272838000096],
              [112.46457425000004, 16.014347376000067],
              [112.4710672110001, 16.013762226000061],
              [112.478194884, 16.015590807000102],
              [112.49845603000007, 16.017639119000115],
              [112.50677896000002, 16.020958237000023],
              [112.51354412900004, 16.023550401000094],
              [112.52253897900007, 16.025867525000059],
              [112.53058799200001, 16.027976389000017],
              [112.54301044900005, 16.036144704000051],
              [112.54902037200011, 16.041713977000072],
              [112.55453428300007, 16.046154292000026],
              [112.55653517100006, 16.051352669000082],
              [112.55401456600006, 16.054368367000023],
              [112.54982642000002, 16.056507941000042],
              [112.54134676500006, 16.056231790000105],
              [112.53520430600005, 16.055222341000075],
              [112.52548681800009, 16.052237365000096],
              [112.5125937370001, 16.049318750000019],
              [112.50243192100005, 16.047481395000048],
            ],
          ],
          [
            [
              [111.7833342550001, 16.037918627000103],
              [111.77154984100009, 16.037401963000072],
              [111.76038506900002, 16.039045960000031],
              [111.75349920600004, 16.042424710000077],
              [111.74839380000004, 16.048125322000125],
              [111.74751000900002, 16.052485057000077],
              [111.74839344000002, 16.0584430440001],
              [111.75453842300006, 16.067024256000103],
              [111.76354839100001, 16.073925979000059],
              [111.77328677300001, 16.078461360000063],
              [111.77988295900009, 16.080155450000078],
              [111.79207300600008, 16.081569594000108],
              [111.79940379000001, 16.081258969000075],
              [111.80767555500007, 16.079664325000032],
              [111.81437220500003, 16.076468226000081],
              [111.82027599400001, 16.072199946000062],
              [111.82211980700006, 16.067641796000132],
              [111.82183722800005, 16.062940470000072],
              [111.81983847800004, 16.05935578400004],
              [111.81472115800003, 16.054197384000091],
              [111.80609282700004, 16.047110004000054],
              [111.79323011900007, 16.040640196000041],
              [111.7833342550001, 16.037918627000103],
            ],
          ],
          [
            [
              [107.91820522100002, 16.207671476000129],
              [107.92228342500005, 16.203615347000074],
              [107.92518040700003, 16.201231812000039],
              [107.92818462900007, 16.198744601000037],
              [107.93140297000009, 16.197190502000055],
              [107.93478198300008, 16.196154787000069],
              [107.93864346400009, 16.195637541000082],
              [107.94245134000003, 16.194964700000099],
              [107.94518675300006, 16.193773136000097],
              [107.94797592400008, 16.191959486000052],
              [107.95035991500006, 16.190824464000016],
              [107.95257368500009, 16.190268627000123],
              [107.95434461900007, 16.190183418000089],
              [107.95642524600001, 16.191039753000069],
              [107.95961261700003, 16.192452625000087],
              [107.96328709400004, 16.193565966],
              [107.96629750900007, 16.194636345000021],
              [107.96793543600006, 16.195920454000131],
              [107.96939610900003, 16.198317207000024],
              [107.97081266400009, 16.199900837000044],
              [107.97276065700007, 16.200671402000033],
              [107.974620116, 16.201399151000118],
              [107.97608106800008, 16.202469203000099],
              [107.97727638000003, 16.203624809000061],
              [107.97789605700007, 16.205251093000058],
              [107.97855998100002, 16.207305334000033],
              [107.97966665700002, 16.209573588000033],
              [107.98201314400009, 16.211799156000062],
              [107.98449351800006, 16.213586411000058],
              [107.98612484600004, 16.214432590000079],
              [107.98739812500006, 16.214394208000016],
              [107.98906937500004, 16.213125203000089],
              [107.99109874300009, 16.21101014100006],
              [107.99300866800012, 16.210087222000077],
              [107.99666928900004, 16.208933585000104],
              [107.99937494100008, 16.207702973000067],
              [108.00180204400009, 16.206856898000055],
              [108.003632301, 16.206087728000092],
              [108.0049452870001, 16.204741691],
              [108.00590015800006, 16.203357196000034],
              [108.00858654400002, 16.199209215000018],
              [108.01072291900003, 16.196885872000074],
              [108.01221835200005, 16.195182069000055],
              [108.01376724100004, 16.194614074000043],
              [108.01569006400005, 16.194872082000074],
              [108.01873452100007, 16.194975092000071],
              [108.02295393800004, 16.194303518000105],
              [108.02658581700007, 16.193735197000066],
              [108.029576703, 16.192857133000068],
              [108.0321937800001, 16.192546995000079],
              [108.03449038600004, 16.192236867000084],
              [108.03598578500005, 16.19166872000006],
              [108.04031264500003, 16.189322914000037],
              [108.04728632100009, 16.185340552000049],
              [108.05246643600005, 16.181807871000053],
              [108.05512288100002, 16.180137776000066],
              [108.05691625400007, 16.180073104000044],
              [108.05897562700002, 16.181099885],
              [108.06103542200002, 16.183410823000074],
              [108.06249700700008, 16.18430933100008],
              [108.06442334000012, 16.184501382000072],
              [108.06814273900002, 16.183601316000114],
              [108.07153017200008, 16.183214964000037],
              [108.07544931800007, 16.183727291000061],
              [108.07843842900003, 16.183918842000097],
              [108.08136096800008, 16.183725134000056],
              [108.08481464600003, 16.182953297000054],
              [108.08770512400008, 16.181839279000073],
              [108.08994253100012, 16.181297583000017],
              [108.09145288300003, 16.181601093000083],
              [108.09264905400005, 16.182877974000043],
              [108.09472684800002, 16.185492684000025],
              [108.09699302900003, 16.187194872000084],
              [108.09881819000006, 16.187741497000019],
              [108.10209026300006, 16.187435819000129],
              [108.10599095000005, 16.185913228000089],
              [108.10919913400002, 16.183904283000047],
              [108.11165277900008, 16.183051426000091],
              [108.11410654100007, 16.182441850000103],
              [108.11744161100006, 16.182440053000043],
              [108.12033656100004, 16.183046738000058],
              [108.12386131000001, 16.184504607000072],
              [108.12700894400004, 16.186570937000063],
              [108.13022015600008, 16.18954960600005],
              [108.13491741800007, 16.192872047000058],
              [108.13994174900003, 16.197643945],
              [108.14318280800005, 16.199911946000078],
              [108.14739521800007, 16.201396424000066],
              [108.15144641400006, 16.203820305000072],
              [108.15501196300002, 16.206479297000072],
              [108.15703700700007, 16.206869226],
              [108.16513396700006, 16.204436457000071],
              [108.16761919300008, 16.204016782000075],
              [108.17110222400005, 16.20464028100006],
              [108.17623147100008, 16.206776057000113],
              [108.18368422800006, 16.208648540000056],
              [108.18959793200005, 16.210130737000036],
              [108.19244818400009, 16.211386363000102],
              [108.192894853, 16.210875062000071],
              [108.1929465040001, 16.20970126100012],
              [108.19257474600008, 16.20735408700007],
              [108.1921510490001, 16.205874524000038],
              [108.19228078800009, 16.203552414000022],
              [108.19222707200008, 16.202582839000087],
              [108.19143457100006, 16.20181805400005],
              [108.19022024500003, 16.201512935000025],
              [108.18847812100005, 16.20125930600009],
              [108.1856804650001, 16.201159677000106],
              [108.18356867500009, 16.200702196000094],
              [108.18198487100005, 16.200397346],
              [108.17950421400005, 16.200603565000016],
              [108.17807899399999, 16.200553718000066],
              [108.176970215, 16.200197404000072],
              [108.17538563100007, 16.198973915000089],
              [108.17446073800002, 16.19762229700008],
              [108.17366784800005, 16.196296085000128],
              [108.17266417800005, 16.195378302000037],
              [108.17118578600012, 16.194818123000054],
              [108.16643497500006, 16.194362560000016],
              [108.164112421, 16.194211248000023],
              [108.16242288900001, 16.193600138000086],
              [108.16070667400002, 16.192606287000011],
              [108.15822499500004, 16.191434372000032],
              [108.15701097300004, 16.191384230000061],
              [108.15558547700005, 16.190874938000022],
              [108.15437102700002, 16.190263419000047],
              [108.15342014300003, 16.189192408000075],
              [108.15262722200006, 16.18761095200005],
              [108.15183409100007, 16.185723295000095],
              [108.15135702400003, 16.183018894000064],
              [108.15124881400006, 16.179497694000041],
              [108.15132515200007, 16.175746714000105],
              [108.15116347600011, 16.171306963000106],
              [108.15139909900006, 16.168831693000115],
              [108.15208282900005, 16.165718192000078],
              [108.15223956500006, 16.163625724000049],
              [108.15176366400001, 16.162401266000082],
              [108.15065479800002, 16.161585516000088],
              [108.14843799900009, 16.161178795000097],
              [108.14664402500009, 16.161639325000095],
              [108.14553633100009, 16.162405575000079],
              [108.14490374900006, 16.163375632000054],
              [108.14474664300003, 16.165059830000047],
              [108.14406115200006, 16.165876821000055],
              [108.14326978200005, 16.166234582000079],
              [108.14195040600003, 16.166235458000067],
              [108.14089483800009, 16.166134086000028],
              [108.1387832720001, 16.165318932000083],
              [108.13693500700002, 16.163636025000102],
              [108.135878453, 16.162054670000089],
              [108.1350852960001, 16.159758679000014],
              [108.13474050100008, 16.157130689000084],
              [108.13452728800011, 16.15396676700005],
              [108.134683796, 16.151261919000071],
              [108.13547425200004, 16.14962835900009],
              [108.13660810400006, 16.148581461000063],
              [108.13739920700006, 16.147917524000022],
              [108.13776807200003, 16.14715179000008],
              [108.13760906400002, 16.146131227000105],
              [108.13655259600009, 16.144549871],
              [108.13480976700004, 16.142407576000025],
              [108.13343716600006, 16.141489843000031],
              [108.13148450300007, 16.14113381500006],
              [108.12932119100003, 16.141441328000013],
              [108.12747471600002, 16.142105855000111],
              [108.12652510500006, 16.142463649000135],
              [108.12504754900002, 16.142413481000069],
              [108.12383364400003, 16.142056952000061],
              [108.12246094900006, 16.140883983],
              [108.12177411800008, 16.139506476000065],
              [108.12108674200005, 16.137210376000063],
              [108.1210852950001, 16.134811812000024],
              [108.12113649300007, 16.13220908400006],
              [108.12255859500004, 16.127972511000088],
              [108.12295209400001, 16.124323411000049],
              [108.12457524400003, 16.123230255000109],
              [108.126195872, 16.122407795000093],
              [108.12772908400002, 16.121581574000032],
              [108.12957530200001, 16.120814967000015],
              [108.13144431300003, 16.120082619000087],
              [108.132182555, 16.119418731000046],
              [108.13244576600002, 16.118499968000052],
              [108.13218141300008, 16.117683596000099],
              [108.13112553800002, 16.116714609000034],
              [108.13022799000001, 16.115796553000017],
              [108.12996361600008, 16.114929145000097],
              [108.12996295500002, 16.113908477000024],
              [108.13075311200004, 16.111968727000111],
              [108.13468031400008, 16.106786421000052],
              [108.14208720699999, 16.098590780000066],
              [108.14767586500001, 16.093687789000022],
              [108.15260572900006, 16.089984408000113],
              [108.15830036900006, 16.086305866000124],
              [108.15985148000006, 16.085449615000066],
              [108.16246607700003, 16.084006251000105],
              [108.16768644300004, 16.081348489000021],
              [108.17372430300001, 16.078664422000074],
              [108.18015784800005, 16.07620950500008],
              [108.18479883400008, 16.074980753000112],
              [108.18888619900005, 16.074084106000086],
              [108.19252575200001, 16.073825705000019],
              [108.19700982000003, 16.074076806000015],
              [108.20038668400011, 16.074890220000079],
              [108.20249774500007, 16.075908913000077],
              [108.20489977900004, 16.077718329000042],
              [108.20796296800009, 16.081185672000053],
              [108.20871275500005, 16.082174297000051],
              [108.21012882200006, 16.084041438000085],
              [108.21171444200007, 16.086897763000074],
              [108.21192703200008, 16.088377528000116],
              [108.21287764200004, 16.089346226000067],
              [108.21377458000009, 16.089447403000086],
              [108.21662295500005, 16.08908731300005],
              [108.218679276, 16.08806455600007],
              [108.22068192200007, 16.086225297000034],
              [108.22273524800005, 16.082548765000034],
              [108.22259706200006, 16.076858674000057],
              [108.22256572900005, 16.072393249000072],
              [108.22224318400001, 16.066932985000015],
              [108.22154753800001, 16.057951764000045],
              [108.22125369100003, 16.054558320000112],
              [108.221620488, 16.052363489000051],
              [108.22241070400001, 16.051495097000057],
              [108.22357086500008, 16.051289756000116],
              [108.22520600700003, 16.051339081000023],
              [108.2265240510001, 16.050827356000049],
              [108.22802521100004, 16.049014094000121],
              [108.23036767100007, 16.044954403000041],
              [108.23157658700008, 16.041329699000073],
              [108.23246900600002, 16.037705338000038],
              [108.2327566270001, 16.035587120000102],
              [108.23243817700005, 16.033852312000093],
              [108.23185632500004, 16.032372958000082],
              [108.23064213300003, 16.03135358800003],
              [108.22768785300011, 16.03059123300001],
              [108.22483918500004, 16.029930795000077],
              [108.22251758400006, 16.028963579000077],
              [108.22040618600008, 16.02728163800009],
              [108.21874272500001, 16.025267499000059],
              [108.21710101300008, 16.023177886],
              [108.21641907100005, 16.022309885000126],
              [108.21451911400005, 16.020984909000013],
              [108.21093118700001, 16.019253310000089],
              [108.20903103000009, 16.017673125000087],
              [108.20734116100007, 16.015429269000059],
              [108.20504381400005, 16.01213978400002],
              [108.20414529700005, 16.010150314000079],
              [108.20409153200005, 16.009129685000097],
              [108.20440751600007, 16.008721112000046],
              [108.20535687800003, 16.008873308000112],
              [108.20836364800003, 16.009789022000028],
              [108.21179269800007, 16.011061516000069],
              [108.21348138600007, 16.012182592],
              [108.21453776300008, 16.013763592000082],
              [108.21469738500005, 16.015090316000077],
              [108.21543759500003, 16.016875764000105],
              [108.2168630080001, 16.01830328000009],
              [108.21881613000005, 16.020036453000053],
              [108.22135080900007, 16.023044857000102],
              [108.22224813200006, 16.023758396000076],
              [108.22319786300004, 16.024165683000113],
              [108.22398892900003, 16.024164859000045],
              [108.22570229700005, 16.023627207000054],
              [108.2281803810001, 16.02311424800007],
              [108.23144980800006, 16.022855578000069],
              [108.23361191400008, 16.022751168000049],
              [108.23529986500003, 16.023055528],
              [108.23630259900004, 16.023666834000046],
              [108.23688355500006, 16.024380669000045],
              [108.23704267000008, 16.025146002000042],
              [108.2367809030001, 16.026779377000096],
              [108.23630896600004, 16.029076424],
              [108.23652190700007, 16.030760309000058],
              [108.23678854700002, 16.033260668000082],
              [108.23657946000003, 16.03484294900008],
              [108.23597729600004, 16.038543569000026],
              [108.23487480200004, 16.042882657000042],
              [108.233613131, 16.04645639400006],
              [108.23100734500005, 16.050822606000011],
              [108.22942827700005, 16.053682193],
              [108.22921875100003, 16.05495825200007],
              [108.22880097100001, 16.058633130000111],
              [108.22871977300005, 16.059473007000058],
              [108.22820143600006, 16.064834370000099],
              [108.22720504900005, 16.069989824000075],
              [108.22681441800005, 16.074404652000048],
              [108.22661159500005, 16.081651638000061],
              [108.22645649500001, 16.084458661000056],
              [108.22572305500007, 16.089001425000049],
              [108.2248817870001, 16.09150294500008],
              [108.22409187900003, 16.092779610000015],
              [108.22261659, 16.094465253000045],
              [108.22214279700006, 16.09538434800006],
              [108.22209191100011, 16.097068508000056],
              [108.22304311200003, 16.098496462000078],
              [108.2246262310001, 16.098852046000012],
              [108.22773891600008, 16.098848768],
              [108.23037571600001, 16.09792735100006],
              [108.23190443200011, 16.096854001000068],
              [108.23227278400003, 16.096037066000065],
              [108.23216621300001, 16.095118577],
              [108.2310047500001, 16.094405359000064],
              [108.22936826400006, 16.093488509000053],
              [108.22883890300005, 16.091907033000027],
              [108.22925965700004, 16.090783854000087],
              [108.23041945199999, 16.090068142000021],
              [108.23147455700007, 16.090067008000105],
              [108.23258252900008, 16.090167877000013],
              [108.23448290100004, 16.091186483000094],
              [108.23712325700009, 16.09337801800006],
              [108.23965918800003, 16.096437217000044],
              [108.24098141300001, 16.099191549000032],
              [108.24140672800003, 16.101895849000059],
              [108.24101524400007, 16.105392085000055],
              [108.24028141800009, 16.109424545000103],
              [108.23949226900008, 16.11131366500009],
              [108.23764728300002, 16.112693619000048],
              [108.23453648100011, 16.11448321000006],
              [108.23063459500003, 16.11663083100003],
              [108.22778632700009, 16.117399373000026],
              [108.2253068480001, 16.117708189],
              [108.22224725800008, 16.118272745000084],
              [108.22061185100003, 16.118478565000061],
              [108.219081628, 16.118378065000094],
              [108.21728681700009, 16.117563353],
              [108.2158089280001, 16.117054509000091],
              [108.21507047800002, 16.117259384000079],
              [108.2139110160001, 16.118485344000085],
              [108.21154042100007, 16.122008998000048],
              [108.21109335700002, 16.123361822000071],
              [108.21125256000005, 16.124229234000076],
              [108.21204520700009, 16.125351187000092],
              [108.21267885900002, 16.125809861000029],
              [108.21463125300009, 16.12590998500005],
              [108.21658309800003, 16.125499758000039],
              [108.21742759900009, 16.125754072000028],
              [108.21837785700002, 16.126212408000114],
              [108.21864290700002, 16.127334873000088],
              [108.21864463000007, 16.128916905000068],
              [108.21888387100006, 16.130575246000056],
              [108.21983516200008, 16.131952174000027],
              [108.22152513800002, 16.133277304000067],
              [108.22390121400007, 16.134703774000059],
              [108.22522129800004, 16.135518926000088],
              [108.22622485000002, 16.136385438000019],
              [108.22685967500007, 16.13781370100007],
              [108.22739024000001, 16.140364805000026],
              [108.22762940200008, 16.141870033000046],
              [108.22889911500005, 16.144726547000054],
              [108.2304851800001, 16.147327546000056],
              [108.23217541400007, 16.148703614000048],
              [108.23613487500002, 16.150128228000057],
              [108.2379831210001, 16.151197886000098],
              [108.23999012600007, 16.152624573000104],
              [108.24310685900011, 16.155274795000096],
              [108.24521886800005, 16.156190991000052],
              [108.24743555700006, 16.156392582000123],
              [108.25038998000008, 16.155725727000103],
              [108.25455723900009, 16.154342932000084],
              [108.256983174, 16.153115247000116],
              [108.25798465600008, 16.152195448000036],
              [108.25951159200012, 16.14953989000005],
              [108.26090639500006, 16.146756889000088],
              [108.26369850200007, 16.143181150000082],
              [108.26622762199999, 16.140320175000063],
              [108.26843944500003, 16.137000257000089],
              [108.26946713900006, 16.136054854000022],
              [108.27089039800005, 16.134981363000108],
              [108.27226192100002, 16.134673429000124],
              [108.27342279000007, 16.13467195400009],
              [108.27479549500003, 16.135231569000027],
              [108.27843979900004, 16.137676475000085],
              [108.28171289000005, 16.138743907000027],
              [108.28334875900003, 16.138792788000075],
              [108.28566959900004, 16.138126280000087],
              [108.29007211300006, 16.135645263000065],
              [108.29270703500003, 16.133294155000044],
              [108.29576146600007, 16.129207310000112],
              [108.29818495000004, 16.126703323000058],
              [108.3005837150001, 16.125347594000061],
              [108.30374748000003, 16.123965240000082],
              [108.30575258100002, 16.124013428000069],
              [108.30733651700007, 16.124674602000063],
              [108.30812907600001, 16.125387934000024],
              [108.30849960200003, 16.126152903000076],
              [108.3082428320001, 16.130746277000085],
              [108.3089330240001, 16.133501087000113],
              [108.30970338000006, 16.136893686000064],
              [108.30991862700003, 16.1395981410001],
              [108.310767336, 16.142454780000023],
              [108.31240634200006, 16.144493734000065],
              [108.313727009, 16.14541040800006],
              [108.31546888600006, 16.145714057000049],
              [108.31705125100007, 16.145252427000045],
              [108.31942343500006, 16.143717914000085],
              [108.32200608500006, 16.141825825000033],
              [108.32390155200004, 16.139220276000096],
              [108.32784805200008, 16.132426853000048],
              [108.33019030500007, 16.128876444000092],
              [108.33219140600009, 16.126474789000063],
              [108.33435067400005, 16.124021843000058],
              [108.33645737900009, 16.121722048000017],
              [108.33727302100007, 16.120419416000061],
              [108.33742950600006, 16.119347467000097],
              [108.33711181900006, 16.118684531000049],
              [108.33595027500006, 16.118227050000122],
              [108.33304809800009, 16.118078470000086],
              [108.33014532800009, 16.117572618000061],
              [108.32687232500008, 16.116505922000087],
              [108.32381197799999, 16.116408491000108],
              [108.3208570620001, 16.116259826000068],
              [108.31779591400004, 16.115651976000045],
              [108.31602723400007, 16.114914601000052],
              [108.31307028600003, 16.113438961000078],
              [108.31095770500011, 16.112064126000099],
              [108.3093720480001, 16.110229210000071],
              [108.30868334200005, 16.108392995000109],
              [108.30831081000004, 16.106301165000048],
              [108.3083050860001, 16.102575725000086],
              [108.30853822000003, 16.099794061000061],
              [108.308113964, 16.098365730000097],
              [108.30674057000006, 16.09724495800009],
              [108.30526237100005, 16.096583628000083],
              [108.30362675400008, 16.09648387900009],
              [108.30183403100008, 16.097149843000032],
              [108.30072774500012, 16.098223101000109],
              [108.29983293500007, 16.09960225800009],
              [108.29862490100003, 16.103227324000088],
              [108.29746678600004, 16.10496407200008],
              [108.29620178100001, 16.105782357000109],
              [108.29382836400001, 16.106295953000057],
              [108.29013507500004, 16.106198907000113],
              [108.28591266400008, 16.105030800000094],
              [108.28332560800008, 16.103707357000104],
              [108.28195216300004, 16.102484360000076],
              [108.27989025200006, 16.09937402000012],
              [108.27875444800004, 16.098278275000055],
              [108.27585210200007, 16.097771688000059],
              [108.27083964200003, 16.097369800000116],
              [108.26777912800002, 16.09691433600009],
              [108.26619696300003, 16.097324572000012],
              [108.26458939200006, 16.098474814000063],
              [108.26374756200005, 16.100210991],
              [108.26274790400002, 16.102304591000035],
              [108.26232677000009, 16.103019576000044],
              [108.26143047800008, 16.103479970000123],
              [108.26058647100007, 16.103583063000123],
              [108.25847561200003, 16.103177346000038],
              [108.25499141300004, 16.101497405000131],
              [108.25192786100006, 16.098643143000047],
              [108.24986569200004, 16.094920102000017],
              [108.24796154900005, 16.09094168900009],
              [108.24684917200004, 16.087268551000108],
              [108.24573339900009, 16.08078856800006],
              [108.24453555700009, 16.071833534000035],
              [108.24526108800009, 16.061217689000081],
              [108.24537751900004, 16.060793940000018],
              [108.24935687100005, 16.04631110900003],
              [108.2534055520001, 16.036354763000112],
              [108.26234709199998, 16.017665607000083],
              [108.27207426100004, 15.996270243000053],
              [108.281803655, 15.977936446000028],
              [108.28756393500002, 15.968486017000048],
              [108.2852988450001, 15.966829142000092],
              [108.28229241600003, 15.965602927000065],
              [108.27789934200008, 15.964434375000012],
              [108.27246807500003, 15.964609102000081],
              [108.27119196300005, 15.960976040000039],
              [108.26986292900004, 15.960921792000081],
              [108.26813032000004, 15.961539069000084],
              [108.26559202700007, 15.964617722000083],
              [108.26351341500003, 15.965794562000022],
              [108.26056717200007, 15.966301417000038],
              [108.25883549800005, 15.96770146500006],
              [108.25641582900005, 15.973352103000058],
              [108.2549738430001, 15.975422782000058],
              [108.25254792400003, 15.976264422000062],
              [108.2495431630001, 15.97632386],
              [108.24520864500002, 15.975825591000079],
              [108.24239572600007, 15.974315967000051],
              [108.23885380300001, 15.971061852000011],
              [108.23645423400006, 15.968634743000097],
              [108.23348294000007, 15.965214233000056],
              [108.22994011200004, 15.960910748000055],
              [108.22862561800008, 15.959089824000026],
              [108.22748364500009, 15.958428373000098],
              [108.22548639400003, 15.958320040000016],
              [108.22263811400009, 15.958618724000038],
              [108.22069317600001, 15.954961943000077],
              [108.22043530200008, 15.952687828000121],
              [108.22043324000001, 15.950808997000097],
              [108.22063634700002, 15.949721040000052],
              [108.22145245100005, 15.948731336000053],
              [108.20909481600003, 15.943813248000092],
              [108.20588240800008, 15.94742276100005],
              [108.20277107100003, 15.952565498000036],
              [108.20348572600001, 15.954797909000094],
              [108.20403251400005, 15.958738129000059],
              [108.20417095500005, 15.961430837000099],
              [108.20362988300005, 15.963139001000036],
              [108.20234178100004, 15.964191084000044],
              [108.20132512800004, 15.965308587000047],
              [108.19832028200001, 15.964495174000069],
              [108.19198699000002, 15.964255777000067],
              [108.185148885, 15.965977845000088],
              [108.18299585000003, 15.966224839000096],
              [108.18160123900002, 15.964755122000083],
              [108.18045467900001, 15.957401566000049],
              [108.18032351199999, 15.952376075000084],
              [108.18019290100001, 15.947963473000113],
              [108.17727430900003, 15.941591972000067],
              [108.17398032300007, 15.940246333000106],
              [108.16992807000007, 15.940739885000086],
              [108.16714308600002, 15.942212978000024],
              [108.16448790900007, 15.947608352000129],
              [108.16107090900006, 15.950797909000068],
              [108.1561334570001, 15.953375648000099],
              [108.15167507200006, 15.954817475000114],
              [108.1506482500001, 15.953626441000067],
              [108.14860836100007, 15.947585385000094],
              [108.14751781200007, 15.940755520000048],
              [108.14751569, 15.937865644000036],
              [108.14832766500004, 15.934975198000066],
              [108.14336889300009, 15.933151276000055],
              [108.13786371900004, 15.932049128000079],
              [108.13350202100001, 15.932453984000093],
              [108.12831079200001, 15.934970214000097],
              [108.11990191100006, 15.941006439000114],
              [108.1123226940001, 15.945735101000123],
              [108.10868813400002, 15.946943244000076],
              [108.10577998800009, 15.947045224000076],
              [108.10315953300008, 15.946046887000074],
              [108.08961458500008, 15.937641898],
              [108.08648078300004, 15.934709093000075],
              [108.08536763700005, 15.930895211000083],
              [108.08445731600004, 15.928743903000074],
              [108.08102111700003, 15.927082576000105],
              [108.07455413900007, 15.926693700000081],
              [108.06879473300005, 15.926989040000093],
              [108.06434926400003, 15.928457470000088],
              [108.06010649000007, 15.93217521500012],
              [108.05788421900006, 15.934816519000082],
              [108.0551561070001, 15.935501856000121],
              [108.04414185000005, 15.935602210000075],
              [108.03797781900003, 15.935212178000045],
              [108.03423886400002, 15.934234774000087],
              [108.02810447600005, 15.932396725000062],
              [108.01988549700009, 15.930762044000078],
              [108.01466225900002, 15.929126863000052],
              [108.00844049300011, 15.925707291],
              [108.0020653440001, 15.922956665000052],
              [107.9952294830001, 15.92161840000008],
              [107.98777924600006, 15.920651609000023],
              [107.98163469500005, 15.92072551800001],
              [107.97470389600009, 15.925930269000064],
              [107.97203282900006, 15.927936005000019],
              [107.9645813290001, 15.933436485000117],
              [107.95814767500006, 15.939114472000069],
              [107.9517836430001, 15.945704802000103],
              [107.94891495000006, 15.94969382500002],
              [107.94846599800005, 15.953076282000021],
              [107.95142222300004, 15.957933975000048],
              [107.95357210500002, 15.962271070000076],
              [107.95384004000007, 15.966347539000123],
              [107.95518384200005, 15.96886306600007],
              [107.96262157300004, 15.975109207000047],
              [107.97094827500004, 15.981994949000098],
              [107.97442821700011, 15.98568095900008],
              [107.97777708400008, 15.98815956000008],
              [107.97941854000001, 15.991082762000095],
              [107.98007478500003, 15.995785096000057],
              [107.9809934670001, 16.003537563000059],
              [107.98164973200005, 16.008875336000102],
              [107.98138680800005, 16.011226441000034],
              [107.97954763400003, 16.013196143000116],
              [107.97632907000009, 16.01586466400002],
              [107.97354119600007, 16.01773951400007],
              [107.97035980600002, 16.020992426000099],
              [107.96672350400006, 16.02653133200009],
              [107.96335987200011, 16.03101510000009],
              [107.95899627500002, 16.035498615000066],
              [107.95708683100004, 16.038927416000078],
              [107.95681328200006, 16.042972024000065],
              [107.95735726000004, 16.049478771000025],
              [107.95953721000009, 16.057392657000058],
              [107.96126302000008, 16.064163381000093],
              [107.96098999900005, 16.065746030000035],
              [107.95944451500007, 16.066273306000056],
              [107.95544488400007, 16.065393239000066],
              [107.95076563200007, 16.063715152000043],
              [107.94185347000004, 16.060264401000076],
              [107.92997167200005, 16.05393855500008],
              [107.91888275600004, 16.04780393900009],
              [107.91106130900002, 16.043969142000059],
              [107.90047001400006, 16.035055793000105],
              [107.89799361400006, 16.036778897000126],
              [107.89432793800007, 16.04032144600005],
              [107.89056337600007, 16.043384919000033],
              [107.88759111900001, 16.046065571000057],
              [107.88352965200005, 16.048458221000047],
              [107.87669498300005, 16.051328171000101],
              [107.87174200400004, 16.053720084000119],
              [107.86837296400009, 16.056783400000093],
              [107.86420125800007, 16.060323520000082],
              [107.86159636400001, 16.062000872000048],
              [107.85931773500002, 16.062471612000095],
              [107.85671423300008, 16.062102603000113],
              [107.85063909900005, 16.061678709000127],
              [107.84543199500007, 16.061150335000015],
              [107.84022474100007, 16.060831703000119],
              [107.83756674700005, 16.060829709000046],
              [107.83528933300001, 16.059778589000118],
              [107.83274185600008, 16.057363029],
              [107.83068218500001, 16.055472521000077],
              [107.82894672200004, 16.055103858000038],
              [107.82574609300008, 16.055416104000116],
              [107.82156833900008, 16.05656700100004],
              [107.81672917800009, 16.058448806000044],
              [107.81772043100003, 16.062672689000031],
              [107.81867583300003, 16.065360261000095],
              [107.81991886300003, 16.067770134000092],
              [107.82197607600007, 16.070227],
              [107.82288506700004, 16.071339518000102],
              [107.82302780700005, 16.07240507500007],
              [107.8225474720001, 16.073979674000057],
              [107.82163620800002, 16.075414946000066],
              [107.82091655800006, 16.076804058000072],
              [107.82024453400007, 16.078471143000073],
              [107.819859455, 16.080601697000048],
              [107.81998969400003, 16.082226021000068],
              [107.82074745200002, 16.083776667000024],
              [107.82285450700002, 16.085695533000077],
              [107.82390745400009, 16.087327987000052],
              [107.82517045400004, 16.089939569000045],
              [107.82706640800006, 16.092225325000072],
              [107.829215682, 16.094266528000034],
              [107.83102720900006, 16.096756132000053],
              [107.83363978300012, 16.099695032000035],
              [107.83700011900007, 16.102385205000047],
              [107.84130624600002, 16.105135513000057],
              [107.84622769800009, 16.108252454000116],
              [107.84953980800003, 16.11100188100005],
              [107.85237875200001, 16.113476215000055],
              [107.85427115500009, 16.11549203100013],
              [107.8545063920001, 16.117552504000088],
              [107.8537935480001, 16.121168997],
              [107.85312859000005, 16.124007191000089],
              [107.85331644900006, 16.126113412000095],
              [107.85495854700012, 16.12927873100007],
              [107.85716920800007, 16.13361618600004],
              [107.85801907100004, 16.135867067000028],
              [107.85790461300007, 16.137184251000036],
              [107.85705235000009, 16.138281400000039],
              [107.856143241, 16.139488284000066],
              [107.855914678, 16.141573783000048],
              [107.85613993500009, 16.144098682000063],
              [107.8567616720001, 16.147886206000095],
              [107.85732646500003, 16.151948130000093],
              [107.85783586800008, 16.154089004000035],
              [107.85908388500005, 16.155407083000028],
              [107.86135370800008, 16.156835584000092],
              [107.86396448400009, 16.157825197000086],
              [107.8673705210001, 16.158156639000062],
              [107.87015197700002, 16.15865231500004],
              [107.8719112890001, 16.159586427000058],
              [107.87315935400007, 16.161014190000067],
              [107.87378246500002, 16.163209979000023],
              [107.87423472200005, 16.166283841000073],
              [107.87432021900003, 16.16958001400004],
              [107.87409015, 16.171277142000037],
              [107.87298174700003, 16.173748580000066],
              [107.87165411800005, 16.176527292000031],
              [107.87011564700003, 16.17920761700011],
              [107.86847329000003, 16.180903877000041],
              [107.86667825400011, 16.182563138000106],
              [107.86564687100012, 16.183780094000078],
              [107.86560807700002, 16.18470249100001],
              [107.86667624300003, 16.185588677000062],
              [107.87026331000008, 16.186956063000046],
              [107.87411743300002, 16.188618714000121],
              [107.87541472099998, 16.189431207000027],
              [107.87591036200004, 16.190353914000077],
              [107.87564030000009, 16.191297894000023],
              [107.87437929600003, 16.193474069000047],
              [107.87323295500003, 16.195392036000023],
              [107.87330861700008, 16.196462093],
              [107.87414772800005, 16.197532589000026],
              [107.87697159300011, 16.198936303],
              [107.88029179100002, 16.200266466000102],
              [107.88342156900006, 16.200895439000092],
              [107.88532999000006, 16.201265434000064],
              [107.88643649200003, 16.202151540000017],
              [107.88765712100005, 16.203775644000103],
              [107.88788943200008, 16.205538647000047],
              [107.88807776500003, 16.206744465000078],
              [107.88887141700006, 16.20707371500005],
              [107.88985431900004, 16.206964608000085],
              [107.89170703100002, 16.206161734000091],
              [107.89314348200004, 16.206162453000061],
              [107.89476856700003, 16.206893996000105],
              [107.89782955800008, 16.208722322000121],
              [107.90043701700009, 16.210477328000117],
              [107.90157044400007, 16.2117931820001],
              [107.90277905600007, 16.213985945000076],
              [107.90387488700006, 16.21493640300011],
              [107.9050466100001, 16.215302290000096],
              [107.90693673700008, 16.215376192000086],
              [107.90829745000005, 16.215815226000082],
              [107.90962029700007, 16.216400377000092],
              [107.91067898500009, 16.215962370000092],
              [107.91317497100002, 16.213734644000063],
              [107.91548235900007, 16.210374161000104],
              [107.91820522100002, 16.207671476000129],
            ],
          ],
          [
            [
              [108.20576510500005, 16.211146015000018],
              [108.20449393500003, 16.210669502000115],
              [108.20258820000001, 16.210944295000026],
              [108.1993422040001, 16.2122440320001],
              [108.19779008700002, 16.213200929000045],
              [108.19701469900008, 16.214361852000089],
              [108.19687592000005, 16.21681887900008],
              [108.19723339200006, 16.221391122000028],
              [108.19864830000006, 16.224392701000042],
              [108.19963803100006, 16.225756730000015],
              [108.20090954900012, 16.226506268000037],
              [108.20323993000002, 16.227118300000051],
              [108.20507549300001, 16.227116556000084],
              [108.20705092600004, 16.225817964000022],
              [108.20824854700001, 16.22335990100003],
              [108.209093227, 16.220970422000036],
              [108.209091164, 16.218991251000055],
              [108.20852320600008, 16.215920678000103],
              [108.20675431800001, 16.212032288000074],
              [108.20576510500005, 16.211146015000018],
            ],
          ],
          [
            [
              [111.70095678900003, 16.227983309000123],
              [111.6971264100001, 16.227322583000081],
              [111.69388763700009, 16.228104963000064],
              [111.68859113399999, 16.230319590000072],
              [111.68565177900007, 16.233451585000047],
              [111.6845960310001, 16.237846781000087],
              [111.68494008200005, 16.24102893000012],
              [111.68634152000001, 16.243495226000078],
              [111.68787167600006, 16.244297067000076],
              [111.69373558200003, 16.247272131000031],
              [111.69601237900012, 16.247469576000114],
              [111.70295158100006, 16.247113517000052],
              [111.7074658450001, 16.245379476000018],
              [111.709439839, 16.242861109000017],
              [111.70949607500003, 16.239074538000061],
              [111.70894299500002, 16.236481492000095],
              [111.70722535100001, 16.232370633000045],
              [111.70426510000003, 16.229345893000065],
              [111.70095678900003, 16.227983309000123],
            ],
          ],
          [
            [
              [111.79128609200008, 16.28727182500009],
              [111.80206736100004, 16.273825626000082],
              [111.80615856400006, 16.266802016000078],
              [111.80757790700008, 16.259825665000079],
              [111.80601804300007, 16.25031559600005],
              [111.80126996300005, 16.240861921000068],
              [111.79058589200005, 16.231997916000026],
              [111.77645618300006, 16.223193968000068],
              [111.76122277600005, 16.215216718000121],
              [111.75223478500006, 16.213918971000041],
              [111.74292940200009, 16.214081293000056],
              [111.73657154600002, 16.215161909000116],
              [111.72407598400005, 16.219905231],
              [111.72099679800002, 16.223300099000056],
              [111.72112207000008, 16.230247883000089],
              [111.72230664600004, 16.231898564000069],
              [111.72483820900007, 16.23400998000006],
              [111.73001968200006, 16.234279309000051],
              [111.737666254, 16.232278390000054],
              [111.74367393100005, 16.230880555000034],
              [111.74901635300004, 16.230787401000057],
              [111.75473067400007, 16.232142518000089],
              [111.75995533700008, 16.234960956000052],
              [111.77134583500001, 16.244784002000046],
              [111.77451805900002, 16.248446384000111],
              [111.77589893900006, 16.253110135000078],
              [111.77583308300011, 16.258769240000071],
              [111.77231081700009, 16.264004082000035],
              [111.76654453600003, 16.269116544],
              [111.75661979500006, 16.273169980000098],
              [111.74210857900003, 16.276332927000055],
              [111.72092734400005, 16.277832288000035],
              [111.70575516400004, 16.277932796000094],
              [111.686850083, 16.276641404000088],
              [111.66684550400002, 16.276498709000094],
              [111.65680342900004, 16.274245003000054],
              [111.65026287100002, 16.270153206000067],
              [111.63815222600003, 16.258396228000024],
              [111.62603132099999, 16.245992110000081],
              [111.61558702700002, 16.235660600000031],
              [111.60365283000006, 16.223898712000029],
              [111.59446923900003, 16.216454810000066],
              [111.5825461100001, 16.207279061000079],
              [111.58233915300012, 16.205342660000085],
              [111.58294848600009, 16.203674302000067],
              [111.5870179720001, 16.200872280000041],
              [111.60536346800011, 16.203986520000058],
              [111.62767116700005, 16.217513295000074],
              [111.64843364200001, 16.232352834000082],
              [111.66414229100009, 16.235400419000086],
              [111.66761907000009, 16.234984078000103],
              [111.66841869000007, 16.234052360000106],
              [111.66877691300004, 16.22750617500008],
              [111.66814592500006, 16.224751702000084],
              [111.66772139800003, 16.222263340000076],
              [111.64910315499999, 16.211169108000064],
              [111.64028655500006, 16.205014141000085],
              [111.62386986400003, 16.193167573],
              [111.61189636000009, 16.188681230000057],
              [111.60260563400001, 16.184795875000098],
              [111.58928076000009, 16.181785837000092],
              [111.57791782700005, 16.18246036500004],
              [111.56645148900007, 16.187015727000095],
              [111.56348214400006, 16.189651476000044],
              [111.55944723199998, 16.19537627100005],
              [111.55884688700004, 16.200397489000103],
              [111.55912243700003, 16.206212481000115],
              [111.56100055600008, 16.214910657000111],
              [111.5640794280001, 16.218415980000067],
              [111.57671186000002, 16.230815039000035],
              [111.59170084100003, 16.239940905000068],
              [111.60858234300008, 16.253560266000108],
              [111.61683764500002, 16.261989091000117],
              [111.62852802900007, 16.27440097000008],
              [111.63886534900006, 16.283440404000068],
              [111.64756093800003, 16.287495987000078],
              [111.65611260400007, 16.29003584200008],
              [111.68238763699999, 16.291604642000031],
              [111.70273195200011, 16.29330712200003],
              [111.71723071100007, 16.294025850000082],
              [111.73190196600009, 16.294740615000066],
              [111.74950701300006, 16.295564714000044],
              [111.75779694500002, 16.296227443000078],
              [111.77002110299999, 16.298119026000087],
              [111.77578781500009, 16.297567250000085],
              [111.78160041299999, 16.294878585000028],
              [111.79128609200008, 16.28727182500009],
            ],
          ],
          [
            [
              [112.07824421500004, 16.341103245000085],
              [112.07768386000014, 16.338998122000078],
              [112.07663407000004, 16.337228841000062],
              [112.07506762700002, 16.335659707000083],
              [112.0732190460001, 16.334189498000029],
              [112.07055269800003, 16.33327640400006],
              [112.06836193200004, 16.332866324000058],
              [112.06502233300003, 16.332287492000127],
              [112.06325325600002, 16.332170418000125],
              [112.06122242300003, 16.331957934000037],
              [112.05888909100007, 16.332202695000127],
              [112.05553213300004, 16.333219375000049],
              [112.05202337299998, 16.334439554000113],
              [112.04861071200006, 16.335311979],
              [112.04597542000006, 16.336008530000079],
              [112.04265231799999, 16.336813544000073],
              [112.03920725100015, 16.337942096000099],
              [112.03781183900007, 16.338319531000067],
              [112.03589496700009, 16.338606397000085],
              [112.03289130100008, 16.339114336000101],
              [112.03061383400002, 16.339558448000076],
              [112.0290625560001, 16.339938757000105],
              [112.02756019700006, 16.340167599000033],
              [112.023728398, 16.340841452000099],
              [112.02061072500005, 16.340849614000064],
              [112.01894447400004, 16.340680072000069],
              [112.01689746200013, 16.340173390000068],
              [112.01560092500006, 16.33978923800008],
              [112.01397458200006, 16.33901682500003],
              [112.01037587300002, 16.33749288700006],
              [112.00858019300003, 16.336909811000041],
              [112.00723116599997, 16.336146646000046],
              [112.00438342300006, 16.334108049000037],
              [112.00196112500011, 16.332763733],
              [111.99900735200005, 16.331117343000031],
              [111.995650063, 16.329524020000093],
              [111.99359935100006, 16.328307764],
              [111.991229586, 16.327070576000047],
              [111.99115266200002, 16.327046720000027],
              [111.97151096100005, 16.320733034000057],
              [111.96088022800002, 16.319616118000098],
              [111.952676556, 16.319767130000074],
              [111.94851160500009, 16.321719486000049],
              [111.94480210900012, 16.326477105000059],
              [111.94369007800003, 16.33099944600005],
              [111.94462333000007, 16.337735206000033],
              [111.94853250800004, 16.3431032720001],
              [111.95303438400003, 16.346352671000105],
              [111.9643527270001, 16.353504184000123],
              [111.97054128900002, 16.356298507000041],
              [111.97802292900009, 16.358863579000094],
              [111.99185071900006, 16.362096955000105],
              [111.99808714900011, 16.363438731000102],
              [112.00736667700008, 16.36502590100006],
              [112.0127461400001, 16.365666594000047],
              [112.0200304470001, 16.365715774000122],
              [112.02650421300008, 16.365965146000057],
              [112.03224646300011, 16.365579653000047],
              [112.03606528900006, 16.364859598000066],
              [112.03941071200002, 16.364426256000094],
              [112.04585081100007, 16.363008360000016],
              [112.050894294, 16.361246082000044],
              [112.05450452500011, 16.359695975000044],
              [112.05879463000007, 16.358596108000079],
              [112.06493924100008, 16.356372884000066],
              [112.06909919000012, 16.353935984000053],
              [112.07115598700003, 16.352498227000112],
              [112.07440012700013, 16.350255454000084],
              [112.0763234010001, 16.347986593000115],
              [112.07739703400003, 16.345554295000042],
              [112.07829508699999, 16.343603275000085],
              [112.07824421500004, 16.341103245000085],
            ],
          ],
          [
            [
              [111.71313097600006, 16.446507241000049],
              [111.71137458900003, 16.446070123000119],
              [111.70887785100004, 16.446113189000044],
              [111.70663510400001, 16.446385568000018],
              [111.70366058800002, 16.447605387000053],
              [111.70047090400007, 16.450698604000102],
              [111.69856837100004, 16.455873098000048],
              [111.69894646300006, 16.45805247800007],
              [111.70165096400009, 16.46049441200006],
              [111.70617569100003, 16.4620525180001],
              [111.71192299100002, 16.46218713100005],
              [111.71640015, 16.461174969000055],
              [111.71836301300006, 16.459271311000109],
              [111.71956374200002, 16.456679664000085],
              [111.719542049, 16.455511459000057],
              [111.71924464000008, 16.45294571200013],
              [111.71794852100004, 16.450397227000032],
              [111.7166611060001, 16.44831602400005],
              [111.71564506300005, 16.44739870700009],
              [111.71313097600006, 16.446507241000049],
            ],
          ],
          [
            [
              [111.53662347700008, 16.439673074],
              [111.53010406900005, 16.438878262000053],
              [111.526254115, 16.439166893000042],
              [111.520054848, 16.442876061000078],
              [111.51769360000003, 16.445620413000128],
              [111.51725933700007, 16.448333226000052],
              [111.51876798400011, 16.451916177000037],
              [111.52171409400006, 16.455024629000015],
              [111.52538297300003, 16.458121205000076],
              [111.52953376000002, 16.461209803000095],
              [111.53752714900006, 16.463558738000117],
              [111.54380249600005, 16.464131877000021],
              [111.54862049899999, 16.464052453000107],
              [111.55293670599998, 16.462853832000057],
              [111.55483989500007, 16.461469526000094],
              [111.55577546400012, 16.459875728000029],
              [111.55598835200006, 16.458293873000059],
              [111.55591632300005, 16.454236483000081],
              [111.55490876400009, 16.451772891000083],
              [111.55341946400004, 16.449317251000117],
              [111.55121556800003, 16.447324359000071],
              [111.54828108200007, 16.444892538000119],
              [111.54366055900003, 16.442488461000067],
              [111.53662347700008, 16.439673074],
            ],
          ],
          [
            [
              [111.61163812500004, 16.438145954000035],
              [111.60906892000001, 16.437789001000077],
              [111.60551299000005, 16.437981995000058],
              [111.600555286, 16.439398625000123],
              [111.59322562600008, 16.443521935000135],
              [111.5883512840001, 16.44960440400007],
              [111.58200620300002, 16.461045547000097],
              [111.58009223600001, 16.469478908000035],
              [111.57960620900003, 16.474154495000093],
              [111.58069478700004, 16.479287869000103],
              [111.58320074500008, 16.484042717000058],
              [111.58884372200008, 16.489011699000052],
              [111.59128072700001, 16.489903699000052],
              [111.59369624200012, 16.489596892000066],
              [111.59847220500004, 16.485919633],
              [111.60557468300009, 16.477006989000117],
              [111.6133498400001, 16.465950963000076],
              [111.61787186700006, 16.456148496000097],
              [111.61881294400004, 16.453068163000047],
              [111.61881956600004, 16.449494054000091],
              [111.61699592400007, 16.443123537000034],
              [111.61408423900011, 16.439571805000064],
              [111.61163812500004, 16.438145954000035],
            ],
          ],
          [
            [
              [111.59901582700007, 16.506770428000017],
              [111.59298811300006, 16.505136721000021],
              [111.58715313400009, 16.50590721800009],
              [111.58409061100008, 16.507324116000085],
              [111.58484044300005, 16.511947324000076],
              [111.58765115400007, 16.514574911000054],
              [111.58997440200001, 16.516586571000055],
              [111.59322802700009, 16.517334591000044],
              [111.59559221500005, 16.5163144670001],
              [111.59783389700007, 16.513780857000079],
              [111.60051939000004, 16.511540513000078],
              [111.60048639000007, 16.509704590000048],
              [111.59892512900004, 16.507792213000094],
              [111.59901582700007, 16.506770428000017],
            ],
          ],
          [
            [
              [111.74473404400001, 16.444993391000118],
              [111.74130590700005, 16.443097714000118],
              [111.73791431000009, 16.443156681000026],
              [111.7373970830001, 16.443410085000032],
              [111.73664175000005, 16.444889707000065],
              [111.73485648400005, 16.447120452000071],
              [111.73462291800008, 16.448591004000065],
              [111.73473225100007, 16.454455081000049],
              [111.73776636300002, 16.463201326000032],
              [111.74374403600008, 16.475806940000034],
              [111.74951422000002, 16.484260954000085],
              [111.75410601400007, 16.492490946000068],
              [111.75497592700002, 16.497119622000064],
              [111.75607017800006, 16.499789064000048],
              [111.75618015800009, 16.505653069000026],
              [111.75496234700006, 16.510318168000104],
              [111.74908905600002, 16.518233055000039],
              [111.74532303800008, 16.52535026000006],
              [111.7453641380001, 16.527547509000051],
              [111.74790398200003, 16.530727397000078],
              [111.75107267200009, 16.532627470000079],
              [111.75316989500007, 16.533079735000022],
              [111.75681944600005, 16.532771640000107],
              [111.75991025200008, 16.530517961000065],
              [111.76504765600006, 16.52602872500006],
              [111.76914546100002, 16.521801983000017],
              [111.77272572800004, 16.517828643000065],
              [111.77706113400002, 16.512375503000065],
              [111.77831072300003, 16.509420602000091],
              [111.77820930300007, 16.504045289000132],
              [111.776807676, 16.498937211000083],
              [111.77355179900009, 16.492395171000048],
              [111.76399207500003, 16.483763716000063],
              [111.75823504900004, 16.476043103000038],
              [111.75550200400002, 16.469491629000018],
              [111.75301175400004, 16.461958223000025],
              [111.749432316, 16.451999604],
              [111.74645270100011, 16.44618553600008],
              [111.74473404400001, 16.444993391000118],
            ],
          ],
          [
            [
              [111.62605611300009, 16.521523918000099],
              [111.61561197100004, 16.520230809000047],
              [111.607787156, 16.521697461000073],
              [111.60497626400004, 16.524148140000072],
              [111.60318830100007, 16.527916966000063],
              [111.60240168300001, 16.531802547000041],
              [111.60346314700008, 16.535256579000098],
              [111.60839973000006, 16.539847455000078],
              [111.61590315600006, 16.544261568000081],
              [111.62223128600007, 16.546825769000058],
              [111.62697024000005, 16.548348332],
              [111.63210595700008, 16.548128184000085],
              [111.63649894800002, 16.546318093000068],
              [111.63871946800006, 16.542808747000045],
              [111.63857148400008, 16.534665834000073],
              [111.63809724800009, 16.532136757000067],
              [111.63646193300006, 16.528559046000076],
              [111.63152740600007, 16.524102296000095],
              [111.62605611300009, 16.521523918000099],
            ],
          ],
          [
            [
              [111.70098666300002, 16.545166344000073],
              [111.69796989300008, 16.544458249000101],
              [111.69651472000002, 16.544787233000058],
              [111.69427042100006, 16.54634568800013],
              [111.69204850400007, 16.549119685000058],
              [111.6908284310001, 16.55339639200006],
              [111.69094600200006, 16.555370267000043],
              [111.69085651300003, 16.559323592000027],
              [111.69188647600006, 16.56234576700006],
              [111.69356017600009, 16.565052898000054],
              [111.69502391500005, 16.565179770000071],
              [111.697727335, 16.562093501000064],
              [111.70083617500009, 16.554592469000085],
              [111.70219868900003, 16.549249307000125],
              [111.702142495, 16.54621041100004],
              [111.70098666300002, 16.545166344000073],
            ],
          ],
          [
            [
              [111.67095371100004, 16.546009375000011],
              [111.66353378900001, 16.544786774000016],
              [111.65921192000009, 16.544860303000064],
              [111.65577084000003, 16.545818141],
              [111.65310626700001, 16.54721242600008],
              [111.64745373800012, 16.550581971000078],
              [111.64475523200005, 16.553971495000042],
              [111.64351475299999, 16.55718431800004],
              [111.6437489780001, 16.5611321250001],
              [111.64494057600011, 16.564151766000101],
              [111.64690232400007, 16.564878490000098],
              [111.65024994400002, 16.566268274000073],
              [111.65324447100008, 16.567949846000118],
              [111.65682303500006, 16.569236909000026],
              [111.66241548900004, 16.570309936000079],
              [111.66780107600005, 16.570577655000115],
              [111.672504428, 16.570317746000015],
              [111.676220836, 16.568636894000029],
              [111.67971203900004, 16.565162717000078],
              [111.68107682500008, 16.561006011000053],
              [111.68136687100008, 16.555879257000058],
              [111.68044465200008, 16.552750065000055],
              [111.67924588800001, 16.55025458800008],
              [111.67612959900001, 16.547971586000095],
              [111.67095371100004, 16.546009375000011],
            ],
          ],
          [
            [
              [112.63703535400006, 16.568933126000026],
              [112.63528145300006, 16.568457315],
              [112.63368786000002, 16.568631701000044],
              [112.63181386600004, 16.569241672000068],
              [112.63082464899999, 16.570010004000046],
              [112.63025890800009, 16.571086702000045],
              [112.63039887000002, 16.572951300000049],
              [112.63112933600007, 16.574165336000043],
              [112.6320722600001, 16.574742675000032],
              [112.63365716300005, 16.575253042000071],
              [112.63474284500001, 16.575304368000054],
              [112.6364444630001, 16.575193016000078],
              [112.63787553000012, 16.575010301000056],
              [112.63983730900009, 16.574522270000088],
              [112.64082997500006, 16.573903229000031],
              [112.64151121500002, 16.573216196000118],
              [112.64167806100015, 16.572895099000014],
              [112.64171129800006, 16.572240720000053],
              [112.64129821000007, 16.571129061000086],
              [112.63889575699997, 16.569826846000076],
              [112.63703535400006, 16.568933126000026],
            ],
          ],
          [
            [
              [111.73783882100007, 16.538512796000049],
              [111.73474370700004, 16.53795856300011],
              [111.72938767699999, 16.538051450000069],
              [111.7229096460001, 16.53892358300002],
              [111.71856974500002, 16.541278507000087],
              [111.71281687700008, 16.54624160000013],
              [111.709182248, 16.551624010000054],
              [111.70677932500004, 16.562152853000086],
              [111.7040317930001, 16.571623645000066],
              [111.70323453200002, 16.576805075000053],
              [111.70330761700004, 16.580755590000074],
              [111.70483334500007, 16.584225137000082],
              [111.70745331600004, 16.585395949000059],
              [111.71445378300008, 16.586339198000118],
              [111.71769217100007, 16.585827316000042],
              [111.72282498000003, 16.582394802000074],
              [111.72805201900006, 16.575312728000121],
              [111.73395713700006, 16.561075111],
              [111.73766848000001, 16.551131177000045],
              [111.73899027000004, 16.543660556000091],
              [111.73933928400005, 16.540614633000047],
              [111.73783882100007, 16.538512796000049],
            ],
          ],
          [
            [
              [112.73980771900005, 16.658388710000068],
              [112.73731142400001, 16.657533345000083],
              [112.7352277570001, 16.65792054300006],
              [112.73320043900004, 16.659444371000106],
              [112.7292582, 16.662261910000083],
              [112.72728569600005, 16.663613797000032],
              [112.72396864200005, 16.665450306000103],
              [112.72215948200009, 16.667481279000107],
              [112.72068109100007, 16.669789463000129],
              [112.72032093600004, 16.672016223000071],
              [112.72050850700009, 16.673718926000092],
              [112.72149427900004, 16.675517923000079],
              [112.72329567900013, 16.676900780000011],
              [112.72499179800005, 16.677180583000073],
              [112.72711868000007, 16.676773091000108],
              [112.72862527300003, 16.675659032000034],
              [112.73210584400007, 16.673214524000102],
              [112.73413687000007, 16.671549569000099],
              [112.73597333799998, 16.669618444],
              [112.73764387600008, 16.668162342000059],
              [112.73940653699999, 16.666668634000104],
              [112.74164662200008, 16.663741448000025],
              [112.74212953499998, 16.66250935900004],
              [112.74189275200001, 16.661046706000079],
              [112.74096227700011, 16.659842547000011],
              [112.73980771900005, 16.658388710000068],
            ],
          ],
          [
            [
              [111.38788477500006, 16.709309856000054],
              [111.38075871900003, 16.708882429000127],
              [111.37807189800003, 16.709284169000057],
              [111.36962668600009, 16.710495232000078],
              [111.36639604400004, 16.712883106000028],
              [111.36055689600008, 16.720345942000044],
              [111.36042794400007, 16.724123675000065],
              [111.36087609800011, 16.727892399000055],
              [111.36289043500005, 16.733254837000096],
              [111.36542454800004, 16.735193024000061],
              [111.36669376300007, 16.74200541700008],
              [111.37271214200003, 16.745147595],
              [111.37946987800007, 16.746480048000123],
              [111.38543077300005, 16.746206674000064],
              [111.39637228600004, 16.744596106000117],
              [111.39785639200009, 16.741336383000061],
              [111.40352380200005, 16.735133859000051],
              [111.40391432200005, 16.72416010700006],
              [111.40324242800006, 16.718597020000026],
              [111.40047608400005, 16.714325568],
              [111.39444295600002, 16.710285390000124],
              [111.38788477500006, 16.709309856000054],
            ],
          ],
          [
            [
              [112.34487039800004, 16.839956954000087],
              [112.34416692800001, 16.83831007000007],
              [112.34346463200008, 16.836716736000042],
              [112.34259783500012, 16.8352338830001],
              [112.34144716300004, 16.833488810000041],
              [112.33999428199999, 16.831917747000077],
              [112.33852041600008, 16.830660715000079],
              [112.33715547300004, 16.829294256000082],
              [112.33530281000004, 16.828527336000043],
              [112.33311650200008, 16.827767109000114],
              [112.33127548000007, 16.827536053000088],
              [112.32966156300003, 16.827514869000112],
              [112.32821680200004, 16.827597501000113],
              [112.32741622299997, 16.82788163],
              [112.32687401500004, 16.828535883000129],
              [112.32676859400011, 16.828806061000101],
              [112.32694586900004, 16.829285022000114],
              [112.32818559700003, 16.830010702000031],
              [112.32872079900008, 16.830394843000107],
              [112.32870701500009, 16.830459464000036],
              [112.32847005200003, 16.831080547000084],
              [112.32833794900003, 16.831404756],
              [112.32762946500011, 16.832095151000104],
              [112.32689188400002, 16.832726620000038],
              [112.32624063800004, 16.833490410000081],
              [112.32572845100003, 16.834251407000075],
              [112.32498506800007, 16.835258333000077],
              [112.32370629500005, 16.836597743000056],
              [112.32305618300008, 16.837415121],
              [112.32279137900002, 16.838037090000043],
              [112.322804129, 16.838626677000065],
              [112.32291431600007, 16.839598239000075],
              [112.32113686199999, 16.83828634000006],
              [112.32052288500009, 16.838191496000029],
              [112.31975482400004, 16.838689047000074],
              [112.31938410800001, 16.839553644000112],
              [112.31940609400014, 16.840571090000097],
              [112.32016369100003, 16.842163098000107],
              [112.32120396100004, 16.843963721000051],
              [112.32212609100013, 16.84544527200007],
              [112.323670339, 16.847396502000045],
              [112.32480798599998, 16.848552289000018],
              [112.32573729099997, 16.849077726000061],
              [112.32730707800008, 16.849635848000055],
              [112.32954069200007, 16.850019818000114],
              [112.33254935300013, 16.850227378000056],
              [112.33561479400011, 16.850487349000076],
              [112.33867326500004, 16.850425807000043],
              [112.34109106800007, 16.850323532000019],
              [112.34308249700008, 16.849800989000038],
              [112.3443440130001, 16.848971522000063],
              [112.34487817099998, 16.847994214000025],
              [112.34575641199999, 16.847440687000059],
              [112.34697185100001, 16.847041093000122],
              [112.34813399599997, 16.846749746000086],
              [112.34895428099999, 16.846090204000049],
              [112.34943386100005, 16.845116035],
              [112.34930748200009, 16.844422008000045],
              [112.34873499500006, 16.843683396000038],
              [112.34754724100002, 16.842796449000019],
              [112.34635833300005, 16.841855942000095],
              [112.345504299, 16.840962254000132],
              [112.34487039800004, 16.839956954000087],
            ],
          ],
          [
            [
              [112.32454069700007, 16.959042648],
              [112.32744638000005, 16.956689582000095],
              [112.33246348400004, 16.952748642000067],
              [112.33536892400001, 16.9503928070001],
              [112.33749171800005, 16.94713369],
              [112.33942179500006, 16.943786535000079],
              [112.34130215400003, 16.940348448000094],
              [112.34303147200004, 16.936545784000053],
              [112.3443794320001, 16.932750791000025],
              [112.34501444300005, 16.929062050000084],
              [112.34465258100013, 16.925577180000047],
              [112.34403273500001, 16.923384095000046],
              [112.34228907500007, 16.922132632000064],
              [112.34061903200009, 16.922074357000035],
              [112.33805742900006, 16.92267728800001],
              [112.33761076300004, 16.924064774000058],
              [112.33804986700014, 16.9267210020001],
              [112.33896160800009, 16.929183927000047],
              [112.33813363600007, 16.930579064000106],
              [112.33675167500012, 16.932812427000066],
              [112.33522669500006, 16.935048654000084],
              [112.33431929300002, 16.937180556000087],
              [112.33303256700006, 16.939411974000059],
              [112.33136451300007, 16.941651050000047],
              [112.33027036900003, 16.943970477000022],
              [112.32799255500004, 16.946681251000051],
              [112.32576432699997, 16.949482892000042],
              [112.32380812000012, 16.951635769000092],
              [112.32221730400013, 16.953046122000082],
              [112.32168108100008, 16.954711037000081],
              [112.32185786700005, 16.956269755000086],
              [112.32082111500007, 16.956841911000083],
              [112.31938912400007, 16.956778697000097],
              [112.31767907800007, 16.957088625000068],
              [112.31626692500002, 16.957943970000052],
              [112.31355975200003, 16.960663154000066],
              [112.31139479000004, 16.96199301200005],
              [112.30883854100001, 16.96287117300006],
              [112.30752759499998, 16.964000121000119],
              [112.30459192800001, 16.964977705000095],
              [112.301660188, 16.966138967000077],
              [112.29915349300006, 16.967107920000046],
              [112.2972625240001, 16.967880780000129],
              [112.29586404400004, 16.969378989000106],
              [112.29523616800005, 16.97122943500009],
              [112.29621526499997, 16.972404590000068],
              [112.2974803860001, 16.973574041000106],
              [112.298440884, 16.973883724000061],
              [112.30052853100007, 16.973383164000033],
              [112.30382793300012, 16.97157333300003],
              [112.30674393299998, 16.969679316000082],
              [112.30938970900004, 16.968524989000052],
              [112.31287764300008, 16.966619456000039],
              [112.31514197800001, 16.965472674000104],
              [112.31852455100002, 16.963110206000032],
              [112.32068157199998, 16.961414764000082],
              [112.32454069700007, 16.959042648],
            ],
          ],
          [
            [
              [112.28416389800007, 16.978975987000041],
              [112.28162346600003, 16.976090841000065],
              [112.278558524, 16.975417767000067],
              [112.27363100099997, 16.976799762000013],
              [112.26791758800002, 16.97709636600004],
              [112.26561890700007, 16.976591414],
              [112.26295864699996, 16.977010954000079],
              [112.25967188500007, 16.974874195000034],
              [112.25720611500006, 16.975473301000044],
              [112.25493873300005, 16.976435400000064],
              [112.25187387600012, 16.975761947000123],
              [112.24825681100006, 16.976016696000059],
              [112.2456004200001, 16.976619413000016],
              [112.24424299700006, 16.975545292000049],
              [112.24306447100007, 16.973917211000078],
              [112.24152818500013, 16.973397005000024],
              [112.24022530900007, 16.974890412000043],
              [112.23834677800008, 16.976211654000025],
              [112.23681826700007, 16.97605820700003],
              [112.23638653900007, 16.973681521000024],
              [112.23486193600004, 16.973711461000015],
              [112.22972029200005, 16.973995827000095],
              [112.22415480100008, 16.972270152000043],
              [112.21799058900005, 16.969271732000024],
              [112.21031359900007, 16.966853085000096],
              [112.20498152200005, 16.967140582000084],
              [112.20141725800011, 16.968563537000044],
              [112.19705080800006, 16.970781040000112],
              [112.193853404, 16.972861440000116],
              [112.1914929980001, 16.977660026000052],
              [112.19256120700001, 16.983148250000021],
              [112.19625069200002, 16.986381889000057],
              [112.202515113, 16.991952534000099],
              [112.21112362600005, 16.996597712000039],
              [112.21928384400012, 16.999557199000037],
              [112.23004618600011, 17.002791438000116],
              [112.2380003440001, 17.003868724000078],
              [112.24386153500002, 17.002344640000011],
              [112.25042913800004, 16.997440901000083],
              [112.25535196300004, 16.995874798000088],
              [112.26412671000007, 16.994806956000019],
              [112.27125534500014, 16.990786033000035],
              [112.27823032300003, 16.98679965700007],
              [112.28293601100009, 16.983954172],
              [112.28574362300004, 16.981513245000102],
              [112.28416389800007, 16.978975987000041],
            ],
          ],
          [
            [
              [111.55987251100001, 17.101074885000052],
              [111.55981410500006, 17.097799743000053],
              [111.55851040900009, 17.094159695000052],
              [111.55557615000006, 17.09170279000007],
              [111.54494363100005, 17.085903574000035],
              [111.52164379600001, 17.075878473000024],
              [111.49164246900006, 17.066152111000044],
              [111.48171625800006, 17.065156191000064],
              [111.47676325900005, 17.065236043000098],
              [111.46459379599999, 17.065817174000038],
              [111.45912817100002, 17.066097559000056],
              [111.45421180400002, 17.06829615700007],
              [111.44525081300004, 17.075377041000102],
              [111.44406567300004, 17.078479355000105],
              [111.44397892800005, 17.085418370000035],
              [111.44527715400008, 17.088866468000049],
              [111.45093321000009, 17.093593888000044],
              [111.46145017900002, 17.098821403000031],
              [111.4780401630001, 17.102987075000087],
              [111.48801535600006, 17.106680328000046],
              [111.49384495900004, 17.109438356000069],
              [111.50091051400005, 17.11201943200011],
              [111.5098239270001, 17.113992630000041],
              [111.52760365600003, 17.115243134000124],
              [111.53543760700009, 17.114537352000077],
              [111.54612726400003, 17.111859004000074],
              [111.55453572000003, 17.108640064000014],
              [111.55849288800007, 17.104759159000068],
              [111.55987251100001, 17.101074885000052],
            ],
            [
              [111.47127280899998, 17.081897742000059],
              [111.48262437900006, 17.081714869000031],
              [111.49150597600004, 17.081956806000065],
              [111.49833716700003, 17.083002464000074],
              [111.50323135100005, 17.085428349000061],
              [111.50825932400005, 17.089586354000105],
              [111.50913233400007, 17.092270134000032],
              [111.50897334100006, 17.094970687000099],
              [111.50612765500006, 17.097522180000091],
              [111.50120088600001, 17.099143845000064],
              [111.49562107400001, 17.098848859000078],
              [111.48906112900009, 17.096178167000083],
              [111.48016628800008, 17.094666230000129],
              [111.46998208900004, 17.090783251000104],
              [111.46662948000002, 17.087946444000046],
              [111.46598018100003, 17.086222469000042],
              [111.46697201600003, 17.083893994000029],
              [111.46900918800002, 17.082319562000059],
              [111.47127280899998, 17.081897742000059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 16, code: "AD01", ten_tinh: "Đắk Lắk" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.06956157100008, 13.404312022000029],
              [108.07055997800009, 13.403920428000047],
              [108.08014615900002, 13.404503909000097],
              [108.098721944, 13.410560638000034],
              [108.10771072500006, 13.41368637100002],
              [108.116298554, 13.413681835000089],
              [108.119893, 13.412897299000102],
              [108.12388708500001, 13.412503746000047],
              [108.14235811500002, 13.408677338000084],
              [108.15952812400003, 13.402013673000022],
              [108.166714316, 13.39809554500002],
              [108.17469615100005, 13.390850843000111],
              [108.17618970700008, 13.386056712000103],
              [108.17644669600003, 13.384538183000048],
              [108.17691256000003, 13.384548100000069],
              [108.18434801000009, 13.382629819000067],
              [108.19134346200006, 13.380574880000038],
              [108.19950495900007, 13.378518619000078],
              [108.20537930500011, 13.377366140000023],
              [108.20766676600002, 13.376917320000102],
              [108.22212262100007, 13.372349942000115],
              [108.23285242400004, 13.373248974000072],
              [108.24707974900011, 13.373232872000072],
              [108.25640943200004, 13.373449427000049],
              [108.26270648900007, 13.373214087000028],
              [108.26923253400001, 13.36979135700007],
              [108.27692233400006, 13.364773469000022],
              [108.27800669700008, 13.363664024000064],
              [108.28204602400008, 13.359531119000055],
              [108.28553791000007, 13.354973764000098],
              [108.29113275900008, 13.353372719000054],
              [108.29579534900003, 13.352228105000028],
              [108.30605147300007, 13.348799056000081],
              [108.31397769200005, 13.346966400000058],
              [108.32306869600005, 13.344448787000116],
              [108.32368938000006, 13.344266001000092],
              [108.33472315400005, 13.341016233000056],
              [108.3398527460001, 13.340552837000084],
              [108.34404991000009, 13.340318441000052],
              [108.35195417300004, 13.326192395000101],
              [108.36195621900001, 13.312290267000025],
              [108.36544837600012, 13.309325132000092],
              [108.36707475000003, 13.306135556000063],
              [108.367065708, 13.301127817000056],
              [108.36890944400005, 13.289288181000101],
              [108.36936704300007, 13.284507318000037],
              [108.36982212800007, 13.278360751000038],
              [108.37097696100005, 13.272440602000033],
              [108.37353458200002, 13.268794193000042],
              [108.37795614700011, 13.264689220000035],
              [108.38284332100008, 13.260355695000035],
              [108.3856375610001, 13.258757311000034],
              [108.39519125900009, 13.256918766000057],
              [108.40825583300006, 13.254596324000099],
              [108.40821317600007, 13.252049527000052],
              [108.4093104080001, 13.249457707000099],
              [108.4132951, 13.246611070000112],
              [108.41607773900003, 13.244673579000031],
              [108.41710630100005, 13.243816981000032],
              [108.41894294600004, 13.241047480000121],
              [108.4203747220001, 13.237281631000107],
              [108.42054068900008, 13.232295358000011],
              [108.42056447600001, 13.231389736000041],
              [108.42027483100001, 13.229017660000062],
              [108.420230546, 13.228654963000073],
              [108.41867473800005, 13.225751534],
              [108.41410624100007, 13.220962651000077],
              [108.41654937200006, 13.214314133000114],
              [108.42002635000006, 13.205202907000045],
              [108.42350444800007, 13.196774500000073],
              [108.42884691400006, 13.188569934000059],
              [108.43349621000007, 13.183325551000012],
              [108.43707202900001, 13.179633478000062],
              [108.43791373700002, 13.178764381000095],
              [108.44396408500005, 13.175110139000077],
              [108.45140869900007, 13.16985954900005],
              [108.45489710700004, 13.166893261000022],
              [108.45908439200011, 13.163925410000042],
              [108.46001121700004, 13.161647384000029],
              [108.4623292420001, 13.156407525000089],
              [108.46394353200007, 13.14912082300008],
              [108.45995306800003, 13.135928881000057],
              [108.45738229300005, 13.132292919000042],
              [108.45718699800005, 13.13056868800002],
              [108.45658794200006, 13.125279569],
              [108.45991705400007, 13.119997333000109],
              [108.46247582000005, 13.11839860800008],
              [108.46573648900001, 13.118163866000108],
              [108.47016081800007, 13.117471317000094],
              [108.47714585300007, 13.116090133000034],
              [108.48319579500006, 13.113345313000123],
              [108.49177869800012, 13.098532155000074],
              [108.50408565900001, 13.083254816000077],
              [108.50780414300002, 13.080059727000036],
              [108.51012685400009, 13.077550699000094],
              [108.51360649600005, 13.072080252000083],
              [108.51568924000007, 13.066840748000084],
              [108.51568116600002, 13.063654604000073],
              [108.51380663300009, 13.059107505000076],
              [108.51146645700008, 13.054561531000084],
              [108.5100561240001, 13.049330575000083],
              [108.50911385200003, 13.04500882400008],
              [108.50910303600003, 13.040684846000071],
              [108.50944959, 13.038853699000084],
              [108.50979247100004, 13.037041927000102],
              [108.51118613600008, 13.035673101000087],
              [108.51630589800001, 13.034522786000123],
              [108.52166019600001, 13.034054507000102],
              [108.52515406, 13.034501024000098],
              [108.53167315100009, 13.034257182000085],
              [108.53981611800006, 13.031733224000062],
              [108.54656001800001, 13.028529850000046],
              [108.55516637900006, 13.025321393000054],
              [108.56097974000002, 13.022575155000114],
              [108.56516693200008, 13.021198568000079],
              [108.56703085300002, 13.021648734000022],
              [108.57029877700005, 13.0245984420001],
              [108.57379835000006, 13.027092326000032],
              [108.57752956400006, 13.029130373000084],
              [108.58125760500005, 13.030030477000041],
              [108.58638207400003, 13.030699073000028],
              [108.59034038300007, 13.030688073000094],
              [108.59382702600007, 13.028630137000084],
              [108.59872508800007, 13.024822888000108],
              [108.61632719600004, 13.011360262000069],
              [108.6322622810001, 13.006811069000078],
              [108.65057356900004, 13.002813664000088],
              [108.66334009800003, 13.001638177000084],
              [108.67429181600006, 12.998762730000111],
              [108.67843282400004, 12.997335878000092],
              [108.67769575700007, 12.996385813000087],
              [108.67381728800007, 12.99192053600011],
              [108.67230830699999, 12.989384814000092],
              [108.672439196, 12.989162994000123],
              [108.67532169900002, 12.987906186000052],
              [108.67975187200003, 12.986059069000076],
              [108.68289933700002, 12.98467424000007],
              [108.68779478900007, 12.982367232000056],
              [108.69070672300003, 12.980410265000051],
              [108.69698991300007, 12.974318110000114],
              [108.70210657900003, 12.968573356000055],
              [108.70408035200009, 12.965473768000011],
              [108.70425434100009, 12.96341117600006],
              [108.70424621700005, 12.961062459000059],
              [108.70333819100006, 12.956971651000069],
              [108.70063845400006, 12.951717146000073],
              [108.70033044600007, 12.948794023000064],
              [108.70151316400003, 12.946158392000127],
              [108.70240194800004, 12.944693384000059],
              [108.704184524, 12.943225402000012],
              [108.706990456, 12.941990237000043],
              [108.70912766300009, 12.944205388000094],
              [108.71112384800004, 12.946230073000018],
              [108.71532764000005, 12.950312026000075],
              [108.71975872300004, 12.952800185000067],
              [108.72418182000008, 12.953012665000061],
              [108.7269740350001, 12.952775526000069],
              [108.74533894600003, 12.946567473000046],
              [108.74951578800004, 12.943139302000082],
              [108.75252796200004, 12.939487646000101],
              [108.75622470800002, 12.932192560000109],
              [108.76340089500005, 12.921926686000072],
              [108.77171832600007, 12.905967637000069],
              [108.77379790800009, 12.902091633000088],
              [108.7768090390001, 12.898439751000089],
              [108.78280562300003, 12.884537038000053],
              [108.78580140900002, 12.877016812000084],
              [108.78811220500008, 12.87291236100012],
              [108.7904299830001, 12.870628245000136],
              [108.79298213400006, 12.868798314000072],
              [108.79484426500002, 12.869018878000114],
              [108.79787859700008, 12.871510476000124],
              [108.80091031800002, 12.873319399000048],
              [108.80394120500006, 12.87490074200006],
              [108.80720208700004, 12.87579851800009],
              [108.81162723000007, 12.876919326000101],
              [108.81882063100009, 12.87211310700007],
              [108.83390622800003, 12.862952328000075],
              [108.83948042300001, 12.86065483300005],
              [108.84366050700001, 12.858817848000024],
              [108.84621015900004, 12.856532221000093],
              [108.84759843400006, 12.854706312000095],
              [108.84828497600002, 12.851973029000073],
              [108.84710381300007, 12.847654442000065],
              [108.84685037200006, 12.842649534000115],
              [108.84868862200003, 12.83718117700001],
              [108.85122937100002, 12.832847707000015],
              [108.85493401700003, 12.828737055000071],
              [108.85956988700008, 12.824850114000073],
              [108.86489952300008, 12.820050101000023],
              [108.8683788160001, 12.817760479000116],
              [108.87349302900003, 12.81705681000007],
              [108.87581508300005, 12.816137064000086],
              [108.87860622600006, 12.816125490000017],
              [108.90789596200003, 12.812133696000048],
              [108.91417591100004, 12.81210665000012],
              [108.92208604000004, 12.812527390000115],
              [108.93138343700005, 12.811121445000049],
              [108.93626571500008, 12.810644822000048],
              [108.93718874600006, 12.809047983000086],
              [108.93799263500004, 12.806584148000065],
              [108.94475129500006, 12.809710098000069],
              [108.95346141200008, 12.813489637000062],
              [108.96250334700009, 12.816782787000045],
              [108.96516478300003, 12.817255553000082],
              [108.97090840500009, 12.807758215000129],
              [108.97482425000008, 12.79977649500008],
              [108.97340922500007, 12.795687417000055],
              [108.97316361400007, 12.792958151],
              [108.97314731200007, 12.789545256000066],
              [108.97336791800011, 12.787041394000108],
              [108.97568373100005, 12.784982945000054],
              [108.97916555300002, 12.783601673000076],
              [108.98195734100008, 12.783816257000067],
              [108.98451437900009, 12.783576844000065],
              [108.9856694640001, 12.781978750000087],
              [108.98565626500006, 12.779248448000134],
              [108.98929535000008, 12.762394391000063],
              [108.98927436200002, 12.758071502000055],
              [108.9890230620001, 12.75420475600013],
              [108.9876124800001, 12.75102601400002],
              [108.98480354200004, 12.747171216000106],
              [108.98313641500002, 12.738988178000097],
              [108.98450617400003, 12.733748831000112],
              [108.98588026600004, 12.729419555000019],
              [108.99142474400004, 12.722113040000059],
              [108.99211001800008, 12.719607132000109],
              [108.99186203400008, 12.71642305300009],
              [108.9909133020001, 12.712559726000093],
              [108.99027054500002, 12.706969813000056],
              [108.98120592800008, 12.688147321000097],
              [108.97886832700001, 12.685428027000077],
              [108.97639955700004, 12.683164818000046],
              [108.97620431300004, 12.683448654000083],
              [108.97230065600009, 12.685230817000013],
              [108.96812097000003, 12.68616003600007],
              [108.96254547600003, 12.686868026000029],
              [108.95743376800003, 12.687346255000074],
              [108.95023424700004, 12.688743795000066],
              [108.94605965000005, 12.69081011400006],
              [108.94304725000002, 12.692871175000063],
              [108.94073636400005, 12.69583912200005],
              [108.93653958000007, 12.693127615000053],
              [108.92232381300008, 12.685227231000072],
              [108.92063191200006, 12.683755802000094],
              [108.91864690800006, 12.681716851000056],
              [108.91689847600007, 12.680586903000089],
              [108.91387471200008, 12.680144968000116],
              [108.91073524600003, 12.67981725500009],
              [108.90736436500001, 12.679717992000048],
              [108.90340735600007, 12.678483645000059],
              [108.90177575200008, 12.677466823000126],
              [108.90060653900005, 12.675879250000042],
              [108.89978548500007, 12.674176444000105],
              [108.90001339600009, 12.673151691000049],
              [108.90765376200005, 12.666407592000082],
              [108.91147553600008, 12.663433579000037],
              [108.91251328000006, 12.661609066000066],
              [108.91249851300007, 12.658310319000064],
              [108.91166013400007, 12.652740099000058],
              [108.91151952100007, 12.647280660000069],
              [108.90964050000004, 12.642852472000053],
              [108.9064874530001, 12.639339768000111],
              [108.90263332200007, 12.634920021000083],
              [108.89562618900001, 12.626873649000101],
              [108.89213403300008, 12.62540968500012],
              [108.88829830800006, 12.625084588000044],
              [108.88551324900001, 12.625892518000102],
              [108.88098875200011, 12.627503905000113],
              [108.87820166100008, 12.627856746000086],
              [108.87599404700002, 12.627865906000045],
              [108.87390018000006, 12.627305828000067],
              [108.87051805200008, 12.624362324000058],
              [108.86759785200005, 12.620734397000087],
              [108.8651444290001, 12.61755951300003],
              [108.86362294600006, 12.614949551000086],
              [108.86268001300002, 12.61176847900005],
              [108.86138663800003, 12.608133865000058],
              [108.86033095000008, 12.605749492000127],
              [108.86008564100004, 12.602679344000121],
              [108.86042264200003, 12.599948078000088],
              [108.8619165640001, 12.596074649000053],
              [108.86504308100005, 12.593673230000134],
              [108.86944835400004, 12.591493999000123],
              [108.87234918100008, 12.590685837000047],
              [108.87559653900009, 12.589421213000039],
              [108.87791158400006, 12.587477955000013],
              [108.88068535000005, 12.584167839000047],
              [108.88323162900005, 12.581996086000034],
              [108.88485249500005, 12.580738120000133],
              [108.88682727300002, 12.58072984300007],
              [108.88945216200004, 12.581553247000075],
              [108.89131607100008, 12.577005075000079],
              [108.89260153600003, 12.572166292000107],
              [108.89417728400011, 12.567326296000124],
              [108.894299394, 12.562066005000093],
              [108.89327075000007, 12.559227248000047],
              [108.89091328800006, 12.551276547000077],
              [108.88959504900005, 12.548581169000082],
              [108.88741022700003, 12.546884514000032],
              [108.88449966399999, 12.545190878000055],
              [108.88042359700006, 12.542506985000033],
              [108.87794249100003, 12.539389948000055],
              [108.87487598300007, 12.535138099000063],
              [108.87209867900003, 12.530600725000072],
              [108.87121140100008, 12.526766301000068],
              [108.87192314300009, 12.523493902000025],
              [108.87278243900003, 12.520789506000083],
              [108.87407286200005, 12.517088275000086],
              [108.87377222900004, 12.514672979000084],
              [108.87318621400004, 12.513396067000125],
              [108.87041834300001, 12.510990957000077],
              [108.86778780100005, 12.506737329000115],
              [108.86347958700001, 12.501797677000058],
              [108.85728765500008, 12.494697686000043],
              [108.85815180700008, 12.493130566000053],
              [108.86075497200008, 12.490987788000039],
              [108.86437330500006, 12.488698684000086],
              [108.86828178600007, 12.48640833100003],
              [108.87204507100004, 12.484118510000107],
              [108.87551621100002, 12.481403409000078],
              [108.87696182700007, 12.480118112000048],
              [108.87738982500005, 12.478410600000077],
              [108.87636001500002, 12.475145566000021],
              [108.87563762800002, 12.473467217000065],
              [108.87489430400007, 12.471740199000079],
              [108.87358288600009, 12.470466346000107],
              [108.86829258500006, 12.454852554000071],
              [108.86727511500008, 12.45443030800004],
              [108.86495363200002, 12.454439826000028],
              [108.86205357500006, 12.454878107000054],
              [108.859444298, 12.455457297000104],
              [108.85640270700004, 12.456748919000036],
              [108.85365007600011, 12.457755047000061],
              [108.85162052300005, 12.458189666000051],
              [108.84684004100011, 12.460056718000077],
              [108.84292712500009, 12.461209500000068],
              [108.83742216700006, 12.463363519000035],
              [108.83496071400006, 12.464652541000108],
              [108.82280268600005, 12.472233799000064],
              [108.82005367100007, 12.474234491000045],
              [108.80906725700001, 12.484795516000078],
              [108.78823335600006, 12.501078903000094],
              [108.785771137, 12.502367400000123],
              [108.78128309200009, 12.505226997000117],
              [108.77838484500009, 12.506374875000057],
              [108.77504793200005, 12.506671431000081],
              [108.77185453000006, 12.506540982000034],
              [108.768513329, 12.505700268000018],
              [108.75339937900003, 12.500210962000045],
              [108.75049163300005, 12.498799850000085],
              [108.74917612000009, 12.496245880000066],
              [108.74872979400001, 12.493262399000036],
              [108.74755476400006, 12.489428625000086],
              [108.74681667600001, 12.486019754000031],
              [108.74302348300003, 12.480489497000045],
              [108.7388034090001, 12.477234990000062],
              [108.73314260900005, 12.476828286000073],
              [108.72995427800004, 12.477976475000039],
              [108.72691102500004, 12.479124118000049],
              [108.72372413600006, 12.48069863700003],
              [108.72024646300008, 12.482131966000052],
              [108.71720509300006, 12.483848040000021],
              [108.71445141100003, 12.484852390000077],
              [108.71183438700004, 12.483439777000081],
              [108.70950662600009, 12.481741872000017],
              [108.70601556100007, 12.479337128000088],
              [108.7029599090001, 12.476930875000035],
              [108.69710259700005, 12.461456780000011],
              [108.69578943900004, 12.459328993000083],
              [108.6924420890001, 12.456355031000042],
              [108.67365588400003, 12.435521349000032],
              [108.67219992800008, 12.433962482000103],
              [108.67001068300004, 12.4299895970001],
              [108.66854828300002, 12.426440827000105],
              [108.66796147100005, 12.424452772000015],
              [108.67180912300009, 12.403404697000029],
              [108.67209409400004, 12.401840326000089],
              [108.67281140500006, 12.399421797000086],
              [108.67444707500003, 12.395887076000099],
              [108.67670806900006, 12.393439805000115],
              [108.67960481000001, 12.392151319000087],
              [108.68379693800004, 12.387873860000107],
              [108.68494347100005, 12.38374836600009],
              [108.68608900100006, 12.37933862500009],
              [108.68680128300011, 12.375498828000085],
              [108.68664238400002, 12.371377609000065],
              [108.68561615700003, 12.368111999000051],
              [108.68430182200007, 12.365415843000081],
              [108.68197136100008, 12.362438705000043],
              [108.67949357300006, 12.358751413000045],
              [108.67672065700003, 12.353501657],
              [108.67726490400004, 12.342698425],
              [108.68158257100006, 12.332878021000047],
              [108.68301836800002, 12.328609713000025],
              [108.68315196000006, 12.325198358000121],
              [108.682275299, 12.32321149500004],
              [108.67842301100002, 12.317935486000041],
              [108.67827109900007, 12.317727434000057],
              [108.67644804900004, 12.314987201000081],
              [108.67542650100008, 12.313000796000038],
              [108.67396860000005, 12.310589422000094],
              [108.67207482800004, 12.307895200000106],
              [108.67003658200007, 12.3053435390001],
              [108.66712847300005, 12.302794615000055],
              [108.6648017630001, 12.300670186000049],
              [108.66116933600001, 12.298265600000134],
              [108.65782925300006, 12.296570621000045],
              [108.65391065700001, 12.295303740000028],
              [108.64970352000009, 12.294464030000094],
              [108.63563739900012, 12.293512034000019],
              [108.63259151300008, 12.293094830000049],
              [108.62969062100004, 12.292677158000041],
              [108.61967201700003, 12.287732732000032],
              [108.61502339400008, 12.284619785000038],
              [108.61318052300001, 12.284409531000049],
              [108.61077257300008, 12.284429986000054],
              [108.60718840900009, 12.283433486000115],
              [108.60155297100002, 12.280764744000104],
              [108.59777631700008, 12.279301207000069],
              [108.59539698600004, 12.278658075000058],
              [108.59328372000007, 12.278664025000106],
              [108.59156858100009, 12.279318695000095],
              [108.58906469700004, 12.281275251000066],
              [108.58748795600005, 12.284139],
              [108.58802152800006, 12.285957113000022],
              [108.58881852500009, 12.287514545000098],
              [108.5886898200001, 12.288684646000101],
              [108.58776671000003, 12.289207107000076],
              [108.58671003200007, 12.289210046000052],
              [108.58406571000005, 12.288307576000078],
              [108.58231337300001, 12.287878135000073],
              [108.58153747600002, 12.287850688000022],
              [108.57946336500001, 12.287777300000078],
              [108.57754705900008, 12.287810766000094],
              [108.57479553800006, 12.28853231000002],
              [108.56394648900009, 12.292223129000133],
              [108.56146928100003, 12.293065774000043],
              [108.56035174900011, 12.293388191000075],
              [108.54862538000002, 12.296770921000098],
              [108.54285192500006, 12.298291802000081],
              [108.54097639200006, 12.298464424000064],
              [108.53944016200005, 12.297964932000037],
              [108.53755888500008, 12.295956044000087],
              [108.53618793900007, 12.293442419000121],
              [108.53618265000009, 12.291428755000043],
              [108.53736935300003, 12.288740832000132],
              [108.53787432400007, 12.286222463000039],
              [108.53718733200007, 12.284378355000046],
              [108.53564902500007, 12.28303981600005],
              [108.53146642900003, 12.281288439],
              [108.52822463400004, 12.280625358000028],
              [108.52481448300006, 12.28080166800005],
              [108.52004138400009, 12.281484698000076],
              [108.51594739600003, 12.280991330000022],
              [108.51317796400012, 12.279691540000035],
              [108.51167911900009, 12.278988060000094],
              [108.50860879200008, 12.278659872000029],
              [108.50656371200006, 12.279168201000077],
              [108.50349876200004, 12.281021389000022],
              [108.49992463300008, 12.283882562000057],
              [108.49856898500009, 12.287409686000037],
              [108.49790198900006, 12.293620092000078],
              [108.49450827100002, 12.300675988000085],
              [108.49122313800007, 12.30500718300007],
              [108.49042807000009, 12.306055380000112],
              [108.48531914700007, 12.30925555700013],
              [108.48259228200004, 12.310100854000082],
              [108.480203354, 12.30960287300009],
              [108.47823786000001, 12.307845338000066],
              [108.47566237100007, 12.300467579000072],
              [108.47343049200006, 12.294095915000101],
              [108.47154563700006, 12.290240580000054],
              [108.47034955200004, 12.289236413000102],
              [108.46898642600006, 12.289742864000084],
              [108.46848097300007, 12.292428883000104],
              [108.46712549200001, 12.296291448000128],
              [108.46610453699999, 12.297300547000043],
              [108.46439911500002, 12.297304306000088],
              [108.46337395200001, 12.296467523000041],
              [108.4630260160001, 12.29344776500003],
              [108.46301231400003, 12.287406772000063],
              [108.46180338100001, 12.280697210000111],
              [108.459580084, 12.277849394000027],
              [108.45687641000002, 12.275673817000053],
              [108.45074294900004, 12.274418725000123],
              [108.44924398800001, 12.274354881000081],
              [108.44658602300009, 12.274668310000079],
              [108.4436953140001, 12.275828610000071],
              [108.44119371700006, 12.276141644000036],
              [108.44025403700003, 12.275528025000069],
              [108.43899728700005, 12.272914429000091],
              [108.43882898400007, 12.267374577000021],
              [108.438652103, 12.257833558000096],
              [108.43785899600002, 12.252602874000027],
              [108.43707449500003, 12.251373370000083],
              [108.43629196600003, 12.251067206000064],
              [108.43584342800004, 12.251068132000052],
              [108.43519736000006, 12.251069466000073],
              [108.4342607680001, 12.251840851000038],
              [108.43285797900003, 12.253998213000015],
              [108.43067717500007, 12.258003873000066],
              [108.42841405600004, 12.260086026000037],
              [108.42653750700003, 12.260089827000085],
              [108.42496987400008, 12.258246279000049],
              [108.42355608700008, 12.255171271000085],
              [108.42245795600009, 12.253480663000092],
              [108.4196381580001, 12.251024018000043],
              [108.41431587500009, 12.24826449600007],
              [108.40742975100009, 12.245353974000038],
              [108.40148504700001, 12.243980355000071],
              [108.3925700560001, 12.24291989600008],
              [108.38772270900004, 12.242928856000022],
              [108.38490898600003, 12.243395675000011],
              [108.38076943400009, 12.245634588000074],
              [108.37858341700004, 12.247331314000041],
              [108.37280336400009, 12.250419425000112],
              [108.36749060200007, 12.252583217000089],
              [108.36186414900003, 12.254285744000091],
              [108.35811304400008, 12.255369383000046],
              [108.35656790400004, 12.255925297000124],
              [108.35564198400009, 12.255838397000039],
              [108.35436055500001, 12.255683508000102],
              [108.35232634500002, 12.254917446000134],
              [108.349665058, 12.253229065000058],
              [108.34684796900007, 12.251848691000065],
              [108.34231109200002, 12.250624969000066],
              [108.33636742400003, 12.249711171000042],
              [108.33339435900005, 12.248484762000087],
              [108.33245313500007, 12.246639562],
              [108.33205832900009, 12.244254878000048],
              [108.33236555300002, 12.240868823000104],
              [108.33298824500011, 12.239175051000116],
              [108.33517379500003, 12.237017147000094],
              [108.33782787600006, 12.234550690000033],
              [108.33907517200007, 12.232394256000044],
              [108.33907236100001, 12.23070149500005],
              [108.33828802900004, 12.229163869000013],
              [108.33641150900003, 12.229012981000052],
              [108.33344150900005, 12.229479356000093],
              [108.33047123700004, 12.2297918120001],
              [108.32765658600005, 12.229642313000076],
              [108.32468361100008, 12.228261912000081],
              [108.319517155, 12.223960954000022],
              [108.30409989400006, 12.212827006000014],
              [108.29909195000003, 12.20944870600009],
              [108.29580746000006, 12.208530030000107],
              [108.29221274200009, 12.209304458000057],
              [108.2873683550001, 12.210849953000123],
              [108.27986816700009, 12.213937733000078],
              [108.27705438900007, 12.214249216],
              [108.27486474000008, 12.213636531000114],
              [108.27126617300006, 12.21164068600009],
              [108.26516362500006, 12.20764744600009],
              [108.25827768900008, 12.20211617600002],
              [108.25530232300011, 12.198118850000037],
              [108.251385118, 12.19089109800011],
              [108.24958275100008, 12.18712315100001],
              [108.24817060100004, 12.182816166000075],
              [108.24769455100008, 12.176969307000094],
              [108.24596617900008, 12.169585171000017],
              [108.24424254200001, 12.166047989000093],
              [108.24189446300007, 12.163127038000086],
              [108.24001771600004, 12.162205914000078],
              [108.23806417800004, 12.162438942000085],
              [108.23681569800004, 12.164132987000096],
              [108.23500783600012, 12.168290473000097],
              [108.23321515100011, 12.173425264000047],
              [108.23057996300004, 12.178758300000057],
              [108.22761353900009, 12.181992994],
              [108.22448967900007, 12.184304536000033],
              [108.22089511700003, 12.185077739000059],
              [108.21698722800008, 12.18523567300007],
              [108.21417311300011, 12.184930788000068],
              [108.21198364800006, 12.184009710000064],
              [108.20916734200009, 12.181550446000065],
              [108.20541164700003, 12.177553270000095],
              [108.20259536600007, 12.174940057000059],
              [108.20009282800004, 12.173403654000124],
              [108.19759137100002, 12.172944377000048],
              [108.19063609500007, 12.173643227000051],
              [108.18657265400003, 12.174416249000062],
              [108.18282249100008, 12.175958325000112],
              [108.17782403300004, 12.18011732500006],
              [108.17110775800006, 12.18658583400005],
              [108.16814040500003, 12.189973600000046],
              [108.166579149, 12.192436935000027],
              [108.16564343400003, 12.195207546000029],
              [108.16517581700006, 12.196900613000048],
              [108.16423856900005, 12.19782463600003],
              [108.16236271, 12.197979970000093],
              [108.16033002900004, 12.197519878000039],
              [108.15688884600007, 12.195060355000107],
              [108.15266552400004, 12.191831934000062],
              [108.14734817400009, 12.188758097000106],
              [108.13827841100007, 12.184455580000096],
              [108.12483061300001, 12.177693264000043],
              [108.12139100600008, 12.176618121000081],
              [108.11904627600005, 12.176619457000102],
              [108.11685873800006, 12.17815946600007],
              [108.11451570600009, 12.181238332000087],
              [108.11140778100007, 12.185287577000013],
              [108.11139752300002, 12.185543461000046],
              [108.11125247800001, 12.189161557000093],
              [108.11103526000007, 12.193994854000055],
              [108.11101917500007, 12.194352730000089],
              [108.11007656400005, 12.197917241000054],
              [108.10771715900006, 12.201637450000112],
              [108.105005842, 12.20416836],
              [108.10472746500005, 12.204428214000094],
              [108.09922699800003, 12.209621152000059],
              [108.09725237200009, 12.211420663000093],
              [108.09538714400003, 12.213236056000108],
              [108.08936039000008, 12.219661943000036],
              [108.08737685800001, 12.221570583000039],
              [108.086449257, 12.222463144000082],
              [108.08460354500005, 12.224855655000065],
              [108.08407931600003, 12.226385517000081],
              [108.08379063900003, 12.228376980000098],
              [108.08367922200006, 12.2291455920001],
              [108.0836893370001, 12.237163403000027],
              [108.08400006100004, 12.241269260000108],
              [108.08363973600007, 12.242121308],
              [108.08321114400005, 12.243134788000097],
              [108.08163246900008, 12.24484518600004],
              [108.08112019800006, 12.245418549000052],
              [108.07823875100003, 12.248643553000081],
              [108.06966971800006, 12.257996512000078],
              [108.0659914610001, 12.262010928000054],
              [108.06306351300005, 12.26517518300003],
              [108.058963503, 12.267328574000123],
              [108.05637425, 12.267329281000093],
              [108.05611383700003, 12.267329351000098],
              [108.05240221100007, 12.266523224000101],
              [108.04994174600006, 12.266254797],
              [108.04748179800001, 12.268138540000082],
              [108.04283592600009, 12.275672309000106],
              [108.04010382700008, 12.284819852000071],
              [108.03955764700001, 12.288048333000033],
              [108.03873768600002, 12.289393652000056],
              [108.03831117300008, 12.289745929000071],
              [108.03682397500003, 12.290201085000072],
              [108.03272271000007, 12.289394670000052],
              [108.02588751300003, 12.289395619000041],
              [108.02233327000002, 12.289934085000096],
              [108.01989326700001, 12.290473394000044],
              [108.01795887800002, 12.29154870300002],
              [108.01713879600003, 12.293431996000058],
              [108.01713906700004, 12.296660403000102],
              [108.01549891200006, 12.301234131000081],
              [108.01413194300001, 12.30338651500009],
              [108.01215853900007, 12.304610208000087],
              [108.008474699, 12.305214504000082],
              [108.00428863200003, 12.305539236000039],
              [107.99854660400011, 12.305808310000097],
              [107.99335145900005, 12.305270141000067],
              [107.98487536900004, 12.302310302000039],
              [107.97913377300007, 12.299081387000042],
              [107.97639965700006, 12.298273991000118],
              [107.97475913300005, 12.298273802000013],
              [107.97270839400009, 12.298946142000132],
              [107.97161452100009, 12.300291171000042],
              [107.97188706, 12.30674807800005],
              [107.97133978000001, 12.309707420000036],
              [107.96532338300003, 12.314549210000079],
              [107.95274407600007, 12.318851417000063],
              [107.95110306400001, 12.320196245000135],
              [107.94864101200005, 12.324231285000041],
              [107.94617856900005, 12.329342478000081],
              [107.94344268800008, 12.333915520000092],
              [107.94125402, 12.337143465000098],
              [107.93660416100002, 12.339832576000019],
              [107.93332148700003, 12.342791083000025],
              [107.93167971300008, 12.345481039000015],
              [107.929763941, 12.349516141000015],
              [107.92962492600006, 12.356107823000071],
              [107.93208335200008, 12.365256407000034],
              [107.93235597500006, 12.36794702900008],
              [107.93043918000011, 12.374403737000071],
              [107.92387304700003, 12.378706377000091],
              [107.92100033300002, 12.380454203000022],
              [107.91881113300008, 12.382874893000027],
              [107.91634756600007, 12.387178874000069],
              [107.91333589900002, 12.39363510800011],
              [107.91087150000004, 12.399284383000085],
              [107.91086973700007, 12.403320335000066],
              [107.91333018500008, 12.407088265000075],
              [107.91633885200005, 12.408434802000022],
              [107.92208295100006, 12.410589524000047],
              [107.92454468000008, 12.411666682000087],
              [107.92509143000004, 12.412743142000069],
              [107.92481726600008, 12.414357449000056],
              [107.92344817800004, 12.417585765000092],
              [107.92219310500012, 12.420791566000043],
              [107.92207894400009, 12.421083149000099],
              [107.92016258800007, 12.42431126400003],
              [107.91961466700012, 12.426194547000067],
              [107.91988762000005, 12.427809068000071],
              [107.92152866900003, 12.42915504500006],
              [107.92371722700001, 12.429693984000066],
              [107.92864195200008, 12.429695705000119],
              [107.93247176400006, 12.431311397000043],
              [107.93774025800006, 12.435463732000061],
              [107.94297117300006, 12.439002588000035],
              [107.94640403400005, 12.441415342000093],
              [107.950981783, 12.442702776000123],
              [107.95425191100004, 12.442381929000044],
              [107.95915757100005, 12.439488712000111],
              [107.96242775300007, 12.438524571000096],
              [107.96667863900008, 12.439168453000081],
              [107.97158328400008, 12.441741810000016],
              [107.97714196100003, 12.445279886000014],
              [107.98045716800006, 12.447761488000085],
              [107.98291000400005, 12.450946811000028],
              [107.98727846500002, 12.4581441070001],
              [107.99087550100009, 12.464897626000047],
              [107.99251055000011, 12.469078351000105],
              [107.99447276700002, 12.471651130000081],
              [107.99774327200008, 12.473902332000096],
              [107.99905148100005, 12.476796664000032],
              [107.99905147200005, 12.478726225000079],
              [107.99676196700007, 12.482263725000029],
              [107.99283701500012, 12.485801171000064],
              [107.987603576, 12.489660081000052],
              [107.9826968940001, 12.495126880000027],
              [107.97811691500004, 12.501880045000034],
              [107.97304598400005, 12.508472288000048],
              [107.95636083100001, 12.520047249000042],
              [107.94752683800004, 12.525834163000036],
              [107.94032893500003, 12.528726723000092],
              [107.92691569200007, 12.529687334000082],
              [107.91775576000006, 12.529362359000105],
              [107.91383056000009, 12.528074362000028],
              [107.91055955900008, 12.527108179000022],
              [107.90826956800005, 12.52710719800003],
              [107.90270664300002, 12.530320801000093],
              [107.89288921200006, 12.536105034000043],
              [107.88634358800007, 12.540604172000068],
              [107.88503385000007, 12.542533146000066],
              [107.88470523400005, 12.545105871000127],
              [107.88535812700007, 12.547679145000055],
              [107.88607391300006, 12.548460878000077],
              [107.889501846, 12.552204529000031],
              [107.89220462, 12.553575670000059],
              [107.89333388299998, 12.554907333000108],
              [107.8933331850001, 12.556241676000111],
              [107.89288022100001, 12.557787842000055],
              [107.89125822800003, 12.561534217000057],
              [107.8912924620001, 12.564786798000059],
              [107.89258412100003, 12.567074919000019],
              [107.89659023100003, 12.570635196000078],
              [107.90425377500004, 12.575273211000047],
              [107.91132579600006, 12.57864806400004],
              [107.91418115400006, 12.579563437000017],
              [107.91773428700006, 12.580702441000112],
              [107.9280056150001, 12.582509068000112],
              [107.9300720840001, 12.582975918000015],
              [107.93130686100004, 12.58395246700008],
              [107.9320106150001, 12.585772389000025],
              [107.93221077100004, 12.589903067000117],
              [107.93220898600008, 12.590315757000068],
              [107.93232525500011, 12.595367854000104],
              [107.93207178600009, 12.598295968000082],
              [107.93011881500007, 12.601412669000032],
              [107.92816569200002, 12.604769156000105],
              [107.92692386900003, 12.609581597000028],
              [107.92761061700001, 12.613955290000066],
              [107.92706338600003, 12.616878557000087],
              [107.92535434800003, 12.619515756000064],
              [107.92144744900006, 12.626468551000068],
              [107.92046921200003, 12.631983633000042],
              [107.91899998900007, 12.644692684000079],
              [107.91826670400005, 12.647570063000034],
              [107.91558169100003, 12.648768041000034],
              [107.91118946200005, 12.647566963000132],
              [107.90704076000007, 12.647085816000113],
              [107.90435590300007, 12.64780403200003],
              [107.90289094100008, 12.649242202],
              [107.90264596900006, 12.651160541000104],
              [107.90313339200007, 12.652599608000068],
              [107.90459659099999, 12.654998352000034],
              [107.90776856100007, 12.656678414000043],
              [107.91094109800011, 12.657159388000048],
              [107.91240483300007, 12.658598850000134],
              [107.91264828400006, 12.660037804000089],
              [107.91240308100002, 12.662675606000027],
              [107.91142588499999, 12.664833483000047],
              [107.90580899900003, 12.672025371000116],
              [107.90348874299998, 12.675261785000112],
              [107.90324386000006, 12.676940359000087],
              [107.90349612400007, 12.679333264000102],
              [107.90851485100009, 12.687794858000066],
              [107.90988888300009, 12.69139944400006],
              [107.91071462000011, 12.693565601000055],
              [107.90887803000004, 12.696449729000035],
              [107.90189981900005, 12.704740766],
              [107.894554516, 12.711949596000059],
              [107.8938763240001, 12.713879976000085],
              [107.89345078300006, 12.716637100000037],
              [107.89365531400003, 12.719133267000069],
              [107.89418255700004, 12.721325555000094],
              [107.89583591600007, 12.723422860000076],
              [107.89748471500006, 12.724933417],
              [107.90321813800007, 12.728742749000064],
              [107.90427423400006, 12.729444398000032],
              [107.90641878700004, 12.731897652000066],
              [107.90772733300004, 12.734578574000109],
              [107.90848982600006, 12.738009760000056],
              [107.90830788600002, 12.739904374000053],
              [107.90830749800008, 12.740766256000045],
              [107.90830723700006, 12.741346886000125],
              [107.90685022800002, 12.743155341000099],
              [107.9053687360001, 12.744230630000073],
              [107.90379315500005, 12.744869407000079],
              [107.90062733500011, 12.745618449000061],
              [107.89178227100004, 12.747830416000026],
              [107.87599234400008, 12.751428071000069],
              [107.87153342400009, 12.752625390000015],
              [107.865201008, 12.754551335000118],
              [107.860615174, 12.756263815000056],
              [107.85843108900012, 12.757548955000074],
              [107.85646424400009, 12.760335254000042],
              [107.85274732500004, 12.767837877000064],
              [107.85137611500001, 12.773771647000041],
              [107.85137269500008, 12.778459967000087],
              [107.85357129500009, 12.784953069000069],
              [107.85980739800004, 12.794694704000074],
              [107.86035566300011, 12.798481860000075],
              [107.85778191200005, 12.80280792500008],
              [107.85116656600007, 12.809295084000015],
              [107.84822573800002, 12.812899508000084],
              [107.844553, 12.812896841000041],
              [107.84161649800002, 12.810730752000053],
              [107.83390882900009, 12.804593777000049],
              [107.82730030700006, 12.802063935000072],
              [107.8232601980001, 12.802421252000061],
              [107.82179111000005, 12.803261150000099],
              [107.81899029000003, 12.795567809000106],
              [107.81692522500005, 12.791502902000039],
              [107.81482752400004, 12.789486231000067],
              [107.80387482500001, 12.782643782000045],
              [107.79815665900003, 12.78109484400005],
              [107.79477127600005, 12.780537369000021],
              [107.7838731670001, 12.779736242000082],
              [107.76975812500008, 12.779721348000093],
              [107.76246562800003, 12.7794831250001],
              [107.7563477410001, 12.780626987000064],
              [107.74740732600004, 12.781306985000105],
              [107.73799426700005, 12.78359718200006],
              [107.73384620700003, 12.78383071300002],
              [107.72999498500005, 12.784047459000101],
              [107.722935433, 12.7854193150001],
              [107.71116996900007, 12.787244903000071],
              [107.69799170000002, 12.78975831800002],
              [107.69022559900004, 12.79135825000006],
              [107.68387061400003, 12.793190184000112],
              [107.67563337200006, 12.795019052000088],
              [107.66857309300005, 12.796389108000115],
              [107.66198286800002, 12.797989852000045],
              [107.65774643900006, 12.798903718000075],
              [107.65374680000008, 12.798897209000069],
              [107.64669141900002, 12.797274368000087],
              [107.63463741700009, 12.800621509000097],
              [107.62527078300012, 12.803221882000047],
              [107.59774786200006, 12.800869645000111],
              [107.59445447400006, 12.800633184000086],
              [107.59163348400006, 12.799476914000072],
              [107.57257974100006, 12.797828095000073],
              [107.55911706000002, 12.79747119100003],
              [107.55862190500008, 12.799079595000061],
              [107.5556163780001, 12.805068211000046],
              [107.55249277600011, 12.810233739000052],
              [107.55153048500007, 12.812347565000069],
              [107.54480070000004, 12.824088095000084],
              [107.52379575800008, 12.847669586000075],
              [107.50314317800004, 12.872073191000053],
              [107.49954286100004, 12.875356240000089],
              [107.49730609500007, 12.880330422000073],
              [107.49544016700004, 12.888042497000106],
              [107.49399323400004, 12.892035962000124],
              [107.49379792500005, 12.902087070000066],
              [107.49404597100006, 12.907186712000039],
              [107.49454653000001, 12.915601406000031],
              [107.4921711260001, 12.924774700000084],
              [107.49203779200008, 12.925926746000103],
              [107.49229392300005, 12.927847978000083],
              [107.49380867300006, 12.931791836000082],
              [107.49356210200008, 12.934612702000068],
              [107.48955898300007, 12.941165043000067],
              [107.48547453300007, 12.945857629000022],
              [107.48354367000009, 12.951495921000065],
              [107.4835297810001, 12.956971613000066],
              [107.50486932300004, 12.975545265000042],
              [107.48969673100001, 13.008246093000063],
              [107.48957384900005, 13.008631246000045],
              [107.48369011900004, 13.021296435000094],
              [107.48354216600006, 13.021507710000078],
              [107.48494980300009, 13.027437073000046],
              [107.487019472, 13.033555980000065],
              [107.48890319300007, 13.038284922000097],
              [107.49094029000005, 13.041913678000048],
              [107.49760515000003, 13.056142423000059],
              [107.50904755500007, 13.08298387400013],
              [107.51104300800006, 13.087624869000079],
              [107.51243397000005, 13.090826430000034],
              [107.51821414300011, 13.104129503000104],
              [107.52545324300007, 13.119848634000062],
              [107.53506466400006, 13.14343891400007],
              [107.54031952300008, 13.154957117000075],
              [107.54809782100008, 13.173216574000078],
              [107.55319636700006, 13.185183553000089],
              [107.564647311, 13.211475674],
              [107.57794634700009, 13.24155936700002],
              [107.59111612500007, 13.272187508000053],
              [107.60471520700003, 13.303626606000039],
              [107.60542687600011, 13.305274557000056],
              [107.61242694200003, 13.308755276000015],
              [107.61292314500005, 13.311012365000073],
              [107.614669099, 13.312732809000048],
              [107.61733055400003, 13.31397231100002],
              [107.62594419700005, 13.319490631000084],
              [107.62914775600009, 13.322444277000043],
              [107.63475393700006, 13.32776045200008],
              [107.63655692400002, 13.328942765000116],
              [107.63916325300002, 13.329536821000064],
              [107.64076918500011, 13.328753408000081],
              [107.64317876000008, 13.327185197000089],
              [107.64538669600006, 13.326206226000091],
              [107.64679064800006, 13.326208570000107],
              [107.64859403100006, 13.327194243000038],
              [107.64899007200005, 13.330142933000031],
              [107.64817283200003, 13.338789168000085],
              [107.64776588900001, 13.342129629000075],
              [107.64916757700009, 13.343507711000056],
              [107.65267322800005, 13.346166747000064],
              [107.65848457000004, 13.349517333000092],
              [107.6790772950001, 13.361994347000026],
              [107.6841322710001, 13.365309967000092],
              [107.69246570400003, 13.372171287000077],
              [107.696087264, 13.374183629000045],
              [107.70238933100008, 13.375273319000057],
              [107.70272912500005, 13.375299409000084],
              [107.70443780200002, 13.375430583000101],
              [107.71279018800001, 13.375442061000074],
              [107.71499734700011, 13.374827465000026],
              [107.71689016500005, 13.37359484300009],
              [107.71799544300011, 13.372052381000097],
              [107.71988949300005, 13.369893387000074],
              [107.72304335500006, 13.368353600000125],
              [107.72722095700004, 13.367201067000066],
              [107.73857031200001, 13.364745083000072],
              [107.74377174900006, 13.363825078000067],
              [107.74676544400005, 13.364137460000068],
              [107.74818254400006, 13.365065502000027],
              [107.74912462700011, 13.367845687000022],
              [107.75006748600006, 13.370008302000079],
              [107.7517994390001, 13.371245482000036],
              [107.7547923330001, 13.372329722000041],
              [107.75920374600007, 13.373261138000101],
              [107.76125260300003, 13.373109057000041],
              [107.76156851400006, 13.372491839000043],
              [107.76062605800007, 13.369866097000036],
              [107.76062805800005, 13.368167772000026],
              [107.76094469000004, 13.366932987],
              [107.76157575900004, 13.366316127000031],
              [107.76315215000008, 13.365854711000045],
              [107.7656727940001, 13.366475083000038],
              [107.76866497900005, 13.368176694000054],
              [107.77206793500005, 13.370178057000091],
              [107.77617915400005, 13.372472418000124],
              [107.78234622000005, 13.375868],
              [107.78552279200008, 13.378069621000053],
              [107.790003531, 13.382171522000073],
              [107.79157855700002, 13.383099442000022],
              [107.79346973200009, 13.383101293],
              [107.79536169300005, 13.382331151000047],
              [107.79709587700009, 13.381715240000068],
              [107.79788371400008, 13.381870391000026],
              [107.7988285320001, 13.382643257000025],
              [107.79930010500004, 13.38387886900003],
              [107.79961302500008, 13.386195081],
              [107.79992457400002, 13.389900856000024],
              [107.79958919600007, 13.392670492000034],
              [107.81884040400006, 13.395380350000073],
              [107.82560339300008, 13.397207148000055],
              [107.83446513700002, 13.399945933000089],
              [107.84146505900002, 13.397447235000113],
              [107.84776690800007, 13.392443773000105],
              [107.86152891800002, 13.392680850000092],
              [107.86945849800001, 13.394506995000109],
              [107.87155782800008, 13.394508277000037],
              [107.87529026099999, 13.394055226000122],
              [107.87879036100004, 13.392008498000061],
              [107.88345732500001, 13.388824170000111],
              [107.89512020000008, 13.388374983000071],
              [107.90258373600007, 13.389516731000038],
              [107.91214812200006, 13.387244525000062],
              [107.91798114300005, 13.382921736000062],
              [107.92099669500007, 13.381374215000095],
              [107.92684613000009, 13.378372226000044],
              [107.93393694900006, 13.376463098000089],
              [107.93706409500007, 13.376437029000064],
              [107.93926104700003, 13.374872652000123],
              [107.94105827100006, 13.374481912000123],
              [107.94185675800004, 13.375264636000049],
              [107.94225567000009, 13.376829754000051],
              [107.94225450300009, 13.380937923000056],
              [107.94365148600004, 13.383872711000054],
              [107.94584747700002, 13.386025192000071],
              [107.94844314400011, 13.387199600000075],
              [107.95024041200004, 13.387004399000089],
              [107.95183812500008, 13.386222258000123],
              [107.95607491100006, 13.382827820000019],
              [107.95627755300005, 13.382665468000086],
              [107.95882826300006, 13.381333047000064],
              [107.96262235500011, 13.380942492000079],
              [107.96841309500006, 13.381725941000065],
              [107.97156238200004, 13.382393497000054],
              [107.97580127000003, 13.38329192900005],
              [107.97899616700005, 13.384270408000097],
              [107.97959507200005, 13.385835495],
              [107.97939518500003, 13.387791756000043],
              [107.97859609199999, 13.390921725000025],
              [107.97939461000007, 13.393464985000046],
              [107.98219026300009, 13.394639006000107],
              [107.98957913400004, 13.394052614000083],
              [107.99457163400002, 13.39209651100006],
              [107.99736741500007, 13.388770883000054],
              [108.00076218000002, 13.385836475000088],
              [108.00655306400007, 13.38251070000007],
              [108.00894931600001, 13.382901871000017],
              [108.01194470300005, 13.384271121000067],
              [108.01953322100007, 13.388183109000094],
              [108.02362080700007, 13.392422146000092],
              [108.02632366500006, 13.395225013000099],
              [108.03331366700003, 13.398549726000029],
              [108.04130234900003, 13.40148277000006],
              [108.0436991020001, 13.402851705000083],
              [108.04609668600006, 13.40774195500005],
              [108.05059167100009, 13.413707690000097],
              [108.05278881099999, 13.414685305000072],
              [108.05618402000007, 13.414684433000089],
              [108.06077729700007, 13.413900640000074],
              [108.06576952700003, 13.411747209000067],
              [108.0673665370001, 13.409594760000052],
              [108.06856376200004, 13.406464278000028],
              [108.06956157100008, 13.404312022000029],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 17, code: "AD01", ten_tinh: "Đắk Nông" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.85778191200005, 12.80280792500008],
              [107.86035566300011, 12.798481860000075],
              [107.85980739800004, 12.794694704000074],
              [107.85357129500009, 12.784953069000069],
              [107.85137269500008, 12.778459967000087],
              [107.85137611500001, 12.773771647000041],
              [107.85274732500004, 12.767837877000064],
              [107.85646424400009, 12.760335254000042],
              [107.85843108900012, 12.757548955000074],
              [107.860615174, 12.756263815000056],
              [107.865201008, 12.754551335000118],
              [107.87153342400009, 12.752625390000015],
              [107.87599234400008, 12.751428071000069],
              [107.89178227100004, 12.747830416000026],
              [107.90062733500011, 12.745618449000061],
              [107.90379315500005, 12.744869407000079],
              [107.9053687360001, 12.744230630000073],
              [107.90685022800002, 12.743155341000099],
              [107.90830723700006, 12.741346886000125],
              [107.90830749800008, 12.740766256000045],
              [107.90830788600002, 12.739904374000053],
              [107.90848982600006, 12.738009760000056],
              [107.90772733300004, 12.734578574000109],
              [107.90641878700004, 12.731897652000066],
              [107.90427423400006, 12.729444398000032],
              [107.90321813800007, 12.728742749000064],
              [107.89748471500006, 12.724933417],
              [107.89583591600007, 12.723422860000076],
              [107.89418255700004, 12.721325555000094],
              [107.89365531400003, 12.719133267000069],
              [107.89345078300006, 12.716637100000037],
              [107.8938763240001, 12.713879976000085],
              [107.894554516, 12.711949596000059],
              [107.90189981900005, 12.704740766],
              [107.90887803000004, 12.696449729000035],
              [107.91071462000011, 12.693565601000055],
              [107.90988888300009, 12.69139944400006],
              [107.90851485100009, 12.687794858000066],
              [107.90349612400007, 12.679333264000102],
              [107.90324386000006, 12.676940359000087],
              [107.90348874299998, 12.675261785000112],
              [107.90580899900003, 12.672025371000116],
              [107.91142588499999, 12.664833483000047],
              [107.91240308100002, 12.662675606000027],
              [107.91264828400006, 12.660037804000089],
              [107.91240483300007, 12.658598850000134],
              [107.91094109800011, 12.657159388000048],
              [107.90776856100007, 12.656678414000043],
              [107.90459659099999, 12.654998352000034],
              [107.90313339200007, 12.652599608000068],
              [107.90264596900006, 12.651160541000104],
              [107.90289094100008, 12.649242202],
              [107.90435590300007, 12.64780403200003],
              [107.90704076000007, 12.647085816000113],
              [107.91118946200005, 12.647566963000132],
              [107.91558169100003, 12.648768041000034],
              [107.91826670400005, 12.647570063000034],
              [107.91899998900007, 12.644692684000079],
              [107.92046921200003, 12.631983633000042],
              [107.92144744900006, 12.626468551000068],
              [107.92535434800003, 12.619515756000064],
              [107.92706338600003, 12.616878557000087],
              [107.92761061700001, 12.613955290000066],
              [107.92692386900003, 12.609581597000028],
              [107.92816569200002, 12.604769156000105],
              [107.93011881500007, 12.601412669000032],
              [107.93207178600009, 12.598295968000082],
              [107.93232525500011, 12.595367854000104],
              [107.93220898600008, 12.590315757000068],
              [107.93221077100004, 12.589903067000117],
              [107.9320106150001, 12.585772389000025],
              [107.93130686100004, 12.58395246700008],
              [107.9300720840001, 12.582975918000015],
              [107.9280056150001, 12.582509068000112],
              [107.91773428700006, 12.580702441000112],
              [107.91418115400006, 12.579563437000017],
              [107.91132579600006, 12.57864806400004],
              [107.90425377500004, 12.575273211000047],
              [107.89659023100003, 12.570635196000078],
              [107.89258412100003, 12.567074919000019],
              [107.8912924620001, 12.564786798000059],
              [107.89125822800003, 12.561534217000057],
              [107.89288022100001, 12.557787842000055],
              [107.8933331850001, 12.556241676000111],
              [107.89333388299998, 12.554907333000108],
              [107.89220462, 12.553575670000059],
              [107.889501846, 12.552204529000031],
              [107.88607391300006, 12.548460878000077],
              [107.88535812700007, 12.547679145000055],
              [107.88470523400005, 12.545105871000127],
              [107.88503385000007, 12.542533146000066],
              [107.88634358800007, 12.540604172000068],
              [107.89288921200006, 12.536105034000043],
              [107.90270664300002, 12.530320801000093],
              [107.90826956800005, 12.52710719800003],
              [107.91055955900008, 12.527108179000022],
              [107.91383056000009, 12.528074362000028],
              [107.91775576000006, 12.529362359000105],
              [107.92691569200007, 12.529687334000082],
              [107.94032893500003, 12.528726723000092],
              [107.94752683800004, 12.525834163000036],
              [107.95636083100001, 12.520047249000042],
              [107.97304598400005, 12.508472288000048],
              [107.97811691500004, 12.501880045000034],
              [107.9826968940001, 12.495126880000027],
              [107.987603576, 12.489660081000052],
              [107.99283701500012, 12.485801171000064],
              [107.99676196700007, 12.482263725000029],
              [107.99905147200005, 12.478726225000079],
              [107.99905148100005, 12.476796664000032],
              [107.99774327200008, 12.473902332000096],
              [107.99447276700002, 12.471651130000081],
              [107.99251055000011, 12.469078351000105],
              [107.99087550100009, 12.464897626000047],
              [107.98727846500002, 12.4581441070001],
              [107.98291000400005, 12.450946811000028],
              [107.98045716800006, 12.447761488000085],
              [107.97714196100003, 12.445279886000014],
              [107.97158328400008, 12.441741810000016],
              [107.96667863900008, 12.439168453000081],
              [107.96242775300007, 12.438524571000096],
              [107.95915757100005, 12.439488712000111],
              [107.95425191100004, 12.442381929000044],
              [107.950981783, 12.442702776000123],
              [107.94640403400005, 12.441415342000093],
              [107.94297117300006, 12.439002588000035],
              [107.93774025800006, 12.435463732000061],
              [107.93247176400006, 12.431311397000043],
              [107.92864195200008, 12.429695705000119],
              [107.92371722700001, 12.429693984000066],
              [107.92152866900003, 12.42915504500006],
              [107.91988762000005, 12.427809068000071],
              [107.91961466700012, 12.426194547000067],
              [107.92016258800007, 12.42431126400003],
              [107.92207894400009, 12.421083149000099],
              [107.92219310500012, 12.420791566000043],
              [107.92344817800004, 12.417585765000092],
              [107.92481726600008, 12.414357449000056],
              [107.92509143000004, 12.412743142000069],
              [107.92454468000008, 12.411666682000087],
              [107.92208295100006, 12.410589524000047],
              [107.91633885200005, 12.408434802000022],
              [107.91333018500008, 12.407088265000075],
              [107.91086973700007, 12.403320335000066],
              [107.91087150000004, 12.399284383000085],
              [107.91333589900002, 12.39363510800011],
              [107.91634756600007, 12.387178874000069],
              [107.91881113300008, 12.382874893000027],
              [107.92100033300002, 12.380454203000022],
              [107.92387304700003, 12.378706377000091],
              [107.93043918000011, 12.374403737000071],
              [107.93235597500006, 12.36794702900008],
              [107.93208335200008, 12.365256407000034],
              [107.92962492600006, 12.356107823000071],
              [107.929763941, 12.349516141000015],
              [107.93167971300008, 12.345481039000015],
              [107.93332148700003, 12.342791083000025],
              [107.93660416100002, 12.339832576000019],
              [107.94125402, 12.337143465000098],
              [107.94344268800008, 12.333915520000092],
              [107.94617856900005, 12.329342478000081],
              [107.94864101200005, 12.324231285000041],
              [107.95110306400001, 12.320196245000135],
              [107.95274407600007, 12.318851417000063],
              [107.96532338300003, 12.314549210000079],
              [107.97133978000001, 12.309707420000036],
              [107.97188706, 12.30674807800005],
              [107.97161452100009, 12.300291171000042],
              [107.97270839400009, 12.298946142000132],
              [107.97475913300005, 12.298273802000013],
              [107.97639965700006, 12.298273991000118],
              [107.97913377300007, 12.299081387000042],
              [107.98487536900004, 12.302310302000039],
              [107.99335145900005, 12.305270141000067],
              [107.99854660400011, 12.305808310000097],
              [108.00428863200003, 12.305539236000039],
              [108.008474699, 12.305214504000082],
              [108.01215853900007, 12.304610208000087],
              [108.01413194300001, 12.30338651500009],
              [108.01549891200006, 12.301234131000081],
              [108.01713906700004, 12.296660403000102],
              [108.01713879600003, 12.293431996000058],
              [108.01795887800002, 12.29154870300002],
              [108.01989326700001, 12.290473394000044],
              [108.02233327000002, 12.289934085000096],
              [108.02588751300003, 12.289395619000041],
              [108.03272271000007, 12.289394670000052],
              [108.03682397500003, 12.290201085000072],
              [108.03831117300008, 12.289745929000071],
              [108.03873768600002, 12.289393652000056],
              [108.03955764700001, 12.288048333000033],
              [108.04010382700008, 12.284819852000071],
              [108.04283592600009, 12.275672309000106],
              [108.04748179800001, 12.268138540000082],
              [108.04994174600006, 12.266254797],
              [108.05240221100007, 12.266523224000101],
              [108.05611383700003, 12.267329351000098],
              [108.05637425, 12.267329281000093],
              [108.058963503, 12.267328574000123],
              [108.06306351300005, 12.26517518300003],
              [108.0659914610001, 12.262010928000054],
              [108.06966971800006, 12.257996512000078],
              [108.07823875100003, 12.248643553000081],
              [108.08112019800006, 12.245418549000052],
              [108.08163246900008, 12.24484518600004],
              [108.08321114400005, 12.243134788000097],
              [108.08363973600007, 12.242121308],
              [108.08400006100004, 12.241269260000108],
              [108.0836893370001, 12.237163403000027],
              [108.08367922200006, 12.2291455920001],
              [108.08379063900003, 12.228376980000098],
              [108.08407931600003, 12.226385517000081],
              [108.08460354500005, 12.224855655000065],
              [108.086449257, 12.222463144000082],
              [108.08737685800001, 12.221570583000039],
              [108.08936039000008, 12.219661943000036],
              [108.09538714400003, 12.213236056000108],
              [108.09725237200009, 12.211420663000093],
              [108.09922699800003, 12.209621152000059],
              [108.10472746500005, 12.204428214000094],
              [108.105005842, 12.20416836],
              [108.10771715900006, 12.201637450000112],
              [108.11007656400005, 12.197917241000054],
              [108.11101917500007, 12.194352730000089],
              [108.11103526000007, 12.193994854000055],
              [108.11125247800001, 12.189161557000093],
              [108.11139752300002, 12.185543461000046],
              [108.11140778100007, 12.185287577000013],
              [108.10904498700006, 12.182344651000053],
              [108.10857127100003, 12.179555711000132],
              [108.10967006500009, 12.173821836000085],
              [108.11092628200002, 12.168242861000065],
              [108.11115947300004, 12.16289689000005],
              [108.11099794100008, 12.15530435900005],
              [108.110837944, 12.150500986000077],
              [108.11052124200008, 12.146937318000093],
              [108.11017021599999, 12.146897629000096],
              [108.10642899000005, 12.146474569000086],
              [108.10391050900004, 12.145856030000067],
              [108.10091914000007, 12.143688201000112],
              [108.09777014800002, 12.140900608000036],
              [108.09383421500006, 12.137803438000015],
              [108.0902139020001, 12.136410491000047],
              [108.08801048800004, 12.136101532000064],
              [108.08564996600003, 12.136412393000027],
              [108.08266042400004, 12.137963061000077],
              [108.08014526800004, 12.138892786000087],
              [108.07761458900008, 12.136007574000105],
              [108.07470511900004, 12.134672052],
              [108.0708262310001, 12.133909636000071],
              [108.06617202800003, 12.134293020000085],
              [108.06268180000008, 12.136012538000072],
              [108.05667047900008, 12.138114560000089],
              [108.04774985800006, 12.139644281000093],
              [108.04348343300002, 12.140408962000036],
              [108.03921718700008, 12.142319200000083],
              [108.03533881599999, 12.144611168],
              [108.03262371200006, 12.144993482000109],
              [108.03087821400004, 12.144802806000053],
              [108.02796897600004, 12.143848512000053],
              [108.02282921900012, 12.14069861400011],
              [108.01953204300007, 12.138025789000027],
              [108.01836826200005, 12.136116509000059],
              [108.01662251100002, 12.131725073000064],
              [108.01410115500005, 12.127524640000063],
              [108.01080431700011, 12.125233595000038],
              [108.00808936400009, 12.124088103000117],
              [108.00576230200002, 12.123706302000057],
              [108.00285348900005, 12.123897296000051],
              [107.99936291900006, 12.124088257000041],
              [107.99722978800006, 12.12408824000004],
              [107.99373921800007, 12.123706288000056],
              [107.98811560500009, 12.122178559000039],
              [107.98617651500005, 12.120650956000121],
              [107.98423755800012, 12.117786791000105],
              [107.98268654200004, 12.114158921000049],
              [107.98171739900005, 12.109576433000056],
              [107.98103912000006, 12.105280385000048],
              [107.98084591600006, 12.097834049000065],
              [107.97968359900008, 12.087141888000106],
              [107.978326779, 12.083514118000059],
              [107.97658207500007, 12.081413738],
              [107.97386797000011, 12.079695069000083],
              [107.97076624000007, 12.077403553000108],
              [107.9668892, 12.07434816400008],
              [107.96661912800005, 12.074062406000062],
              [107.96211683000011, 12.069298461000065],
              [107.96165564100005, 12.068810453000099],
              [107.96149142400006, 12.068573160000094],
              [107.96059762400009, 12.06728163100008],
              [107.96437060600007, 12.064228761000082],
              [107.96561123500004, 12.063278796000086],
              [107.967860585, 12.061556401000038],
              [107.96978196200007, 12.060205221000063],
              [107.9732892060001, 12.057738708000086],
              [107.97794203400005, 12.055639102],
              [107.9853087110001, 12.053539615000107],
              [107.99228751400004, 12.052203536000137],
              [107.9971338340001, 12.052012739000071],
              [108.00101089, 12.052394593000047],
              [108.00353099400004, 12.05334916900007],
              [108.00546954400005, 12.053730975000041],
              [108.00779578700005, 12.053539983000048],
              [108.00915271700006, 12.052394397000031],
              [108.00954032300007, 12.05029424900008],
              [108.00895853900006, 12.044948525000086],
              [108.00663198600003, 12.032538939000046],
              [108.00643807400007, 12.030057034000039],
              [108.00643802600007, 12.028529705000055],
              [108.00779475200005, 12.026429585000107],
              [108.01021751900005, 12.024615791000047],
              [108.01351250400009, 12.023661033000097],
              [108.01680735700005, 12.021369821000059],
              [108.02029576700002, 12.017169405000059],
              [108.02223361000004, 12.013923690000093],
              [108.02223327800002, 12.010869091000041],
              [108.02281390700009, 12.003614406000017],
              [108.02426701700006, 12.000000174000112],
              [108.02461970800002, 11.997933095000056],
              [108.02501632600007, 11.996046460000096],
              [108.02544140500007, 11.994655232000094],
              [108.02647594500004, 11.992981703000092],
              [108.02940749900004, 11.989043167000089],
              [108.03152226700004, 11.987616225000062],
              [108.03473807700003, 11.986416931000042],
              [108.03743197000007, 11.98491384800011],
              [108.04233297500005, 11.978145508000091],
              [108.04350194800004, 11.975219467000018],
              [108.04446364500004, 11.970662711000022],
              [108.04646035600004, 11.960817191000038],
              [108.04645940100005, 11.956616647000116],
              [108.04552571500007, 11.952547602000061],
              [108.04552548200004, 11.951497469000037],
              [108.04672447500005, 11.950315820000055],
              [108.05058815000007, 11.947952149000061],
              [108.05685000200002, 11.944931459000061],
              [108.05831545600003, 11.94401220900003],
              [108.05938142200003, 11.944011911000095],
              [108.06044755200006, 11.944536665000081],
              [108.06124750300006, 11.946111607000097],
              [108.062047546, 11.947949088000074],
              [108.06324726800005, 11.949523920000081],
              [108.06404680700004, 11.949654943000086],
              [108.06564567900008, 11.949260657000059],
              [108.06764377600004, 11.947291043000073],
              [108.070119909, 11.94331172400007],
              [108.07491352300006, 11.936098073000066],
              [108.07781069600006, 11.930876433000035],
              [108.08115915300009, 11.923790126000087],
              [108.08274308800004, 11.918798523000083],
              [108.08302132900006, 11.916176269000099],
              [108.0825086940001, 11.913084801000105],
              [108.08865796400005, 11.911493203000044],
              [108.09375173600007, 11.909899733000094],
              [108.09930878600005, 11.908760579000075],
              [108.10370772100005, 11.907167200000044],
              [108.10694830100005, 11.904665036000049],
              [108.1090314960001, 11.903072724000106],
              [108.10949352400004, 11.901026593],
              [108.10833393200002, 11.897617386000102],
              [108.10416239100002, 11.890572604000036],
              [108.09884894000002, 11.882651906000062],
              [108.09836961500007, 11.881937347000092],
              [108.09049290000009, 11.871711736000099],
              [108.08169028700007, 11.859895143000097],
              [108.07312002400008, 11.847623653000115],
              [108.06779290500003, 11.839442439000079],
              [108.06478221700006, 11.835124615000046],
              [108.061773628, 11.837398559000077],
              [108.05876485200008, 11.839217850000059],
              [108.05552404000008, 11.839218729000043],
              [108.04996759000007, 11.836037869000025],
              [108.04232766300005, 11.831266185000098],
              [108.0337621250001, 11.82603980000003],
              [108.02681746400006, 11.822176695000051],
              [108.02334514500008, 11.81944952000009],
              [108.02195609000009, 11.816949383000084],
              [108.02079848100004, 11.813994627000033],
              [108.02010382000003, 11.811039832000068],
              [108.01709471900001, 11.808767137000052],
              [108.01431722800007, 11.807858161000071],
              [108.009919655, 11.807858414000092],
              [108.00714225600007, 11.808313117000056],
              [108.00320757500003, 11.808313214000064],
              [107.9997358050001, 11.806949466000026],
              [107.99464397100006, 11.804449149000094],
              [107.98839500300002, 11.802403244000102],
              [107.98399768200008, 11.800811904000062],
              [107.98168349000009, 11.798084196000056],
              [107.97983219600003, 11.795811092000095],
              [107.97821244900004, 11.792856142000028],
              [107.97566686, 11.791264820000078],
              [107.97377352200003, 11.791298720000041],
              [107.97211982600007, 11.791328318000092],
              [107.9721481240001, 11.791582763000095],
              [107.97234650899999, 11.793366825000094],
              [107.97234621200006, 11.795496303000073],
              [107.97157444400005, 11.796865149],
              [107.96798612500008, 11.799906788000046],
              [107.96381874400006, 11.804089063000054],
              [107.95660247900003, 11.81161701000004],
              [107.95629352900004, 11.812985930000062],
              [107.95644754000008, 11.814507053000066],
              [107.95706451200006, 11.81618037900005],
              [107.95690986400005, 11.817701444000049],
              [107.95598356000005, 11.819070247000061],
              [107.95351386599999, 11.820590818000108],
              [107.94795708400004, 11.823327510000057],
              [107.94587328800002, 11.824239642000032],
              [107.94510753800007, 11.824095682000022],
              [107.942632352, 11.823630341000067],
              [107.93985475600005, 11.821956343000027],
              [107.93858191300006, 11.820510938000071],
              [107.93784926400011, 11.819065670000038],
              [107.93769538900008, 11.817544525000033],
              [107.93785005000008, 11.816479807000045],
              [107.93800479300009, 11.815110863000109],
              [107.93754231500003, 11.813437529000035],
              [107.93677098900007, 11.812372532000024],
              [107.933376084, 11.811306729000124],
              [107.92874691000006, 11.809327800000078],
              [107.92612395200011, 11.807653711000031],
              [107.92458142500001, 11.805675758000065],
              [107.9238105360001, 11.803698088000123],
              [107.92361817500009, 11.802253012000017],
              [107.92315647300006, 11.798906496000059],
              [107.92253942700009, 11.798297844000059],
              [107.92122789200002, 11.797841044000011],
              [107.91910782200002, 11.797922468000086],
              [107.91648409500009, 11.798681972000045],
              [107.90949802700008, 11.801562937000119],
              [107.90509888600008, 11.803690501000029],
              [107.89741872100011, 11.808858557000066],
              [107.89587437100005, 11.810987318000075],
              [107.88861612800008, 11.819805944000093],
              [107.88707143600008, 11.822238890000046],
              [107.88707026000007, 11.824368441000031],
              [107.88844426000007, 11.832217714000047],
              [107.88845150100005, 11.838667737000018],
              [107.88829458000004, 11.843383195000088],
              [107.88875681500008, 11.844904577000113],
              [107.88968252800004, 11.845665642000112],
              [107.8904540500001, 11.846122385000067],
              [107.89431253900005, 11.846884935000011],
              [107.89678168500006, 11.847950960000018],
              [107.89801607900006, 11.848864254000089],
              [107.89909590600006, 11.850233809000075],
              [107.89924928400004, 11.85221139400001],
              [107.89878530800011, 11.854036566000035],
              [107.89832108600007, 11.856318087000071],
              [107.89723848100002, 11.860424730000128],
              [107.89677339200009, 11.864379560000058],
              [107.89584515800003, 11.868334187000061],
              [107.89507275800007, 11.869398642000116],
              [107.89260175400007, 11.871374944000122],
              [107.88993816400004, 11.872590581000074],
              [107.88596215100004, 11.874109612000082],
              [107.88441822000001, 11.874413011000049],
              [107.88183219800001, 11.874791934000049],
              [107.87392053700007, 11.874559108000058],
              [107.86666504200008, 11.874250403000095],
              [107.86018145200008, 11.873941962000066],
              [107.85277205900005, 11.873024197000069],
              [107.85081928200003, 11.872460305000041],
              [107.84639553000002, 11.870767588000051],
              [107.84366624700007, 11.86997524900004],
              [107.84068057800003, 11.869918573000058],
              [107.83841708600008, 11.870579768000054],
              [107.83748975900008, 11.871948135000119],
              [107.83640645700007, 11.875293935000018],
              [107.83532398700004, 11.877574894000096],
              [107.83377946100005, 11.878486406000109],
              [107.83192690300007, 11.878484937000076],
              [107.8285309450001, 11.878025835000104],
              [107.82727627400003, 11.877571313000066],
              [107.82575328500005, 11.876654476000057],
              [107.82414448800007, 11.874853907000025],
              [107.82352829500007, 11.873332192000062],
              [107.82305588100006, 11.871784424000074],
              [107.82236516000002, 11.867220230000084],
              [107.82174980000009, 11.864785813000099],
              [107.82082412100006, 11.864176555000048],
              [107.81758278900001, 11.863717425000074],
              [107.81233510800004, 11.862800109000114],
              [107.808776075, 11.86205840800003],
              [107.80353792400003, 11.860966669000026],
              [107.79674595900009, 11.860808115000053],
              [107.7914967400001, 11.86156357200012],
              [107.78871846099999, 11.861256570000121],
              [107.78547820500003, 11.859884243000083],
              [107.78331870700005, 11.858360860000071],
              [107.779386023, 11.85501024300004],
              [107.77421894200005, 11.85135394300003],
              [107.77221381100006, 11.849982748000086],
              [107.770361511, 11.849980738000097],
              [107.76928049800009, 11.85043590000001],
              [107.76866202499998, 11.851347917000064],
              [107.76727020500002, 11.853628132000081],
              [107.76634247, 11.854996150000117],
              [107.76541543900004, 11.85575570800008],
              [107.76387040500001, 11.856970918000085],
              [107.75877265900002, 11.860159604000041],
              [107.756146814, 11.86152563700009],
              [107.7521301470001, 11.864106963000063],
              [107.7427073580001, 11.869115585000054],
              [107.73558226600005, 11.873916109000058],
              [107.73328371000011, 11.874428064000051],
              [107.7318945220001, 11.874274177000109],
              [107.73050633700009, 11.873359687000027],
              [107.72834852100007, 11.870770885000058],
              [107.72618750000007, 11.870615965000029],
              [107.72464294100007, 11.871222440000027],
              [107.72403023900009, 11.872211749000082],
              [107.72371382200002, 11.873350901000011],
              [107.72355512300004, 11.876545214000076],
              [107.72432558000001, 11.87761107200007],
              [107.72772028300005, 11.878832451000052],
              [107.72912155100008, 11.879579978],
              [107.73034910400004, 11.88083977500005],
              [107.730470978, 11.881633315000071],
              [107.73022410200009, 11.882417442000113],
              [107.72941221900007, 11.883550388000062],
              [107.727092817, 11.886285595000066],
              [107.72461712600007, 11.890389682000089],
              [107.72136460700007, 11.897991592000066],
              [107.7208991370001, 11.899664350000092],
              [107.72151505900008, 11.900882163000047],
              [107.72213181600004, 11.901491476000013],
              [107.72413897400003, 11.901494113000053],
              [107.72722792600008, 11.900737503000068],
              [107.72908067500008, 11.900739893],
              [107.73124042700007, 11.90211178700009],
              [107.73185601900012, 11.903633814000079],
              [107.7318536240001, 11.905459317000043],
              [107.73154262800003, 11.907132310000089],
              [107.73014860000006, 11.910477308000099],
              [107.72790447800001, 11.914429750000098],
              [107.72664588700006, 11.91559374800004],
              [107.72527629800007, 11.916860381000037],
              [107.72218165400008, 11.917996114000038],
              [107.70940737900007, 11.922996607000067],
              [107.70868462600004, 11.923193959000054],
              [107.70752613900002, 11.92330030000006],
              [107.70665607900006, 11.923261076000101],
              [107.70583340200002, 11.923126188000042],
              [107.70510640000001, 11.922895181000024],
              [107.7043291950001, 11.922474259000102],
              [107.7036959710001, 11.921956753000094],
              [107.70267156900007, 11.920967007000092],
              [107.70158897400003, 11.918978210000118],
              [107.70087577599999, 11.917699868000041],
              [107.70019333000002, 11.917087617],
              [107.69961268500002, 11.916997990000054],
              [107.6987914260001, 11.917005860000083],
              [107.69826144200005, 11.917153741000062],
              [107.69758793500009, 11.917445798000069],
              [107.69550628400007, 11.918274145000039],
              [107.68892223900012, 11.920575088000083],
              [107.68827216700006, 11.920795489000023],
              [107.68771636700009, 11.920777003000092],
              [107.68737772200004, 11.920732639000063],
              [107.68650533000007, 11.920455372000037],
              [107.67430224300006, 11.915768337000097],
              [107.67280403600009, 11.915111735000037],
              [107.67236737700003, 11.914925481000081],
              [107.671930262, 11.914691625],
              [107.67158931100003, 11.91440925900009],
              [107.6711498600001, 11.91393742500013],
              [107.67064819200006, 11.911968381000102],
              [107.67052599400007, 11.910516372000044],
              [107.67034295300003, 11.908967939000091],
              [107.66989249900007, 11.900680141000029],
              [107.66980312200005, 11.898585481000088],
              [107.66976823000009, 11.896147872000105],
              [107.67005295900002, 11.895621570000079],
              [107.67072321200006, 11.894996418000046],
              [107.67293321300012, 11.893737867000096],
              [107.67518962500003, 11.892288465000076],
              [107.67581201400002, 11.891711349000021],
              [107.67604982700007, 11.891328291000034],
              [107.67604563500007, 11.890899932000119],
              [107.67589699000003, 11.89052053400008],
              [107.675123845, 11.889169511000118],
              [107.67377443400009, 11.887710705000119],
              [107.67294768000008, 11.887147329000046],
              [107.67119789000007, 11.88670941400005],
              [107.67032700700004, 11.886574852000107],
              [107.66926337000005, 11.886489710000079],
              [107.66805621100009, 11.886548724000077],
              [107.66709243300002, 11.886795841],
              [107.66269700700005, 11.887202394000072],
              [107.66028926600005, 11.887425054000021],
              [107.65943375000006, 11.88663010300006],
              [107.65716274300009, 11.881605768000012],
              [107.65638189600006, 11.879987769000076],
              [107.65491204800003, 11.878600244000024],
              [107.65110458300003, 11.877100780000125],
              [107.64523636300007, 11.874720330000075],
              [107.63104210800006, 11.868045775],
              [107.62968367600011, 11.867439595000027],
              [107.62847063100001, 11.86687965000006],
              [107.62730452300008, 11.866176464000054],
              [107.62666974600005, 11.865468323000082],
              [107.62527826800002, 11.863767475000129],
              [107.62420202200006, 11.861407721000104],
              [107.62420537700007, 11.859582313000059],
              [107.62462806700005, 11.856622409000027],
              [107.62349631000002, 11.854035168000097],
              [107.61959906700005, 11.846438790000134],
              [107.61760155800008, 11.841560695000018],
              [107.61715644500002, 11.840469949000097],
              [107.61664250400004, 11.839760651000029],
              [107.61615634300004, 11.839431935],
              [107.61533255700002, 11.83915392600001],
              [107.61426817500005, 11.838973326000078],
              [107.61296320300008, 11.838890168000125],
              [107.61194847100005, 11.838851920000069],
              [107.611305966, 11.838995325000033],
              [107.6105536900001, 11.839483615000022],
              [107.60983552900011, 11.840156666000022],
              [107.6091151090001, 11.840591731000048],
              [107.60831952400008, 11.840741870000036],
              [107.60669755800009, 11.84076172400008],
              [107.60490677500006, 11.84033460900009],
              [107.60350022300004, 11.839358102000029],
              [107.60335307100003, 11.839121439000076],
              [107.60301227600007, 11.838838951000062],
              [107.60267057300004, 11.838461269000076],
              [107.60196212700005, 11.837610918000061],
              [107.60083640199998, 11.83605034800008],
              [107.59844727200002, 11.83369209300009],
              [107.59596383900006, 11.831572700000057],
              [107.59455242000007, 11.830443131000033],
              [107.58978739499999, 11.827106837000121],
              [107.5894484250001, 11.82701473300005],
              [107.58224898500004, 11.824176545000052],
              [107.57793623900005, 11.822692435000091],
              [107.57712418300011, 11.822309070000079],
              [107.57473114400008, 11.820735479000087],
              [107.56142661000007, 11.808132135000092],
              [107.5574692000001, 11.803264505000042],
              [107.55600139700007, 11.801230694000074],
              [107.55380213700009, 11.797141088],
              [107.55326687700003, 11.795399857000087],
              [107.5522403960001, 11.794076132000074],
              [107.55102337500003, 11.793039738000031],
              [107.54961276900011, 11.791957468000101],
              [107.54723806700009, 11.79107425300012],
              [107.54593064600002, 11.790705120000043],
              [107.5450117620001, 11.790570531000098],
              [107.54394844600004, 11.790484829000112],
              [107.54259668300003, 11.790544524000078],
              [107.54216336500005, 11.790691213000047],
              [107.54178008600005, 11.791027875000013],
              [107.53992381500002, 11.793995996000104],
              [107.53928753300005, 11.794638008000023],
              [107.53778831500009, 11.796300567000094],
              [107.53526235200007, 11.798101528000069],
              [107.53399683700007, 11.798794193000093],
              [107.53247175600011, 11.799268950000029],
              [107.52912430200007, 11.800032361000133],
              [107.52727360000006, 11.80036508400004],
              [107.52591409200002, 11.800469842],
              [107.51647232900004, 11.800553611000096],
              [107.514652208, 11.800143355000079],
              [107.51168585200006, 11.799870155000034],
              [107.50631870800007, 11.799375737000071],
              [107.49239849600008, 11.798290192000048],
              [107.48944427000008, 11.79741154700009],
              [107.48324564500004, 11.795609069000077],
              [107.48164502000003, 11.794861326000026],
              [107.47906097000001, 11.792170242000031],
              [107.47815895000006, 11.79122595600006],
              [107.476840022, 11.7895711780001],
              [107.47620193900006, 11.788434156000081],
              [107.47580661400004, 11.787437828000067],
              [107.47570149100004, 11.786486594000054],
              [107.47569210600007, 11.785439300000087],
              [107.47596310100002, 11.783342207000123],
              [107.47611079200007, 11.780960542000061],
              [107.47605056200005, 11.779628053000033],
              [107.47545910400007, 11.778300181000121],
              [107.47280655000006, 11.773324412000095],
              [107.47163348400008, 11.771763539000089],
              [107.46453683500008, 11.764073303000087],
              [107.463207036, 11.762897223000033],
              [107.46176555700001, 11.762061413000067],
              [107.4573299730001, 11.759950734000045],
              [107.45531495500008, 11.759119838000041],
              [107.45350257100004, 11.758637529000014],
              [107.45272865600005, 11.758501353000026],
              [107.45171454600008, 11.758510073000126],
              [107.44988118400008, 11.758716265000134],
              [107.44785507400005, 11.758971717000042],
              [107.44636142500009, 11.759365397000108],
              [107.44376546400007, 11.76072070300007],
              [107.44042599900007, 11.762653665000043],
              [107.43999347199998, 11.762895415000061],
              [107.43436926800004, 11.765895264000024],
              [107.43350503100004, 11.766473953000032],
              [107.43283186700002, 11.766812968000105],
              [107.43239848400005, 11.76695949000006],
              [107.4315779140001, 11.767014100000049],
              [107.43114286400007, 11.766970203000058],
              [107.4306095530001, 11.766736713000101],
              [107.42997547200011, 11.766027981000081],
              [107.41717011600011, 11.751425487000054],
              [107.41609983400011, 11.750529978000122],
              [107.415179815, 11.750252107000046],
              [107.41469609100004, 11.750160975000055],
              [107.41426146700005, 11.750164654000093],
              [107.41339431000006, 11.750410032000056],
              [107.41247927600003, 11.750703435000085],
              [107.41185398700007, 11.750994376000088],
              [107.40601377800002, 11.754096239000054],
              [107.40191553300004, 11.75684613800003],
              [107.40076524100007, 11.758255280000094],
              [107.39776440200004, 11.763587018000086],
              [107.3972897180001, 11.764543214000065],
              [107.39668985700003, 11.765352376000047],
              [107.39560699100005, 11.766312674000099],
              [107.39531970200008, 11.766600740000042],
              [107.39407545100008, 11.76704597100011],
              [107.39345940099999, 11.767103808000099],
              [107.39024071800004, 11.767373391000111],
              [107.38890201700012, 11.767805685000086],
              [107.39194713500009, 11.770271541000097],
              [107.3946242900001, 11.773765098000053],
              [107.39602930100008, 11.77697611600008],
              [107.39573445100002, 11.781018882000092],
              [107.39544083500007, 11.784643363000045],
              [107.39387200600002, 11.788961384000094],
              [107.39244747900003, 11.792303767000066],
              [107.39243919500004, 11.795092487000057],
              [107.393137403, 11.798162134000021],
              [107.39369624300005, 11.800534189000087],
              [107.39454215500005, 11.801512688000047],
              [107.39694545500004, 11.802077339000048],
              [107.40231878900001, 11.802929284000106],
              [107.40472051200004, 11.804051595000063],
              [107.40628032900005, 11.805390230000066],
              [107.40739994300004, 11.806987372000053],
              [107.40774028100006, 11.807760687],
              [107.40671861900007, 11.808412973000058],
              [107.40584309800002, 11.809333748000126],
              [107.403418952, 11.813506943000094],
              [107.40298868800001, 11.815759657000056],
              [107.40340376500006, 11.818967980000126],
              [107.40579676, 11.823158001000065],
              [107.41237670100007, 11.830790623],
              [107.41450075500003, 11.834967663000027],
              [107.41467543500002, 11.835314410000118],
              [107.41577387500004, 11.839144127000088],
              [107.41616189299999, 11.84000863000003],
              [107.41748380100003, 11.84285221700012],
              [107.42423163900004, 11.858488801000087],
              [107.42535429400003, 11.861838625000052],
              [107.41917107500011, 11.865259282000105],
              [107.41655178100009, 11.866042668000086],
              [107.41346746199999, 11.866141467000089],
              [107.40877366200002, 11.866675067000097],
              [107.40663438600004, 11.867476033000022],
              [107.40435862400003, 11.869195816000031],
              [107.40215391900004, 11.881548049000033],
              [107.39946619600006, 11.886787711000045],
              [107.38642835300008, 11.910618878],
              [107.38432851300007, 11.915841229000089],
              [107.37485786400002, 11.926952390000077],
              [107.37195572300006, 11.929330717000129],
              [107.36870682000009, 11.931480649000024],
              [107.36601638100007, 11.933660837000025],
              [107.36554837800006, 11.934040067000049],
              [107.36258039200007, 11.934055253000063],
              [107.35790521900005, 11.935508145000105],
              [107.3568871730001, 11.936494668000107],
              [107.35403966800004, 11.939253863000053],
              [107.35218051600005, 11.940158657000042],
              [107.35116623400006, 11.940441844000114],
              [107.35138813100004, 11.940575267000051],
              [107.35210261900009, 11.94100487400007],
              [107.35247594900007, 11.941394101000034],
              [107.35293270800003, 11.941870300000089],
              [107.35298976300004, 11.942256591000076],
              [107.35311389600001, 11.943097043000071],
              [107.35323131100004, 11.944471798000043],
              [107.35331169900003, 11.945286493000127],
              [107.3540875190001, 11.949293061000075],
              [107.35433948000008, 11.950594217000086],
              [107.35444884200007, 11.952243903000069],
              [107.35447197900002, 11.952592876000077],
              [107.35433087300007, 11.953319079000034],
              [107.35391300200004, 11.953771954000024],
              [107.35270909899999, 11.954040750000059],
              [107.35164399500007, 11.95430996700013],
              [107.35090472300001, 11.954710104000108],
              [107.3500642930001, 11.956348778000068],
              [107.35002057600003, 11.956668210000119],
              [107.34968530600001, 11.959117937000128],
              [107.34962674900005, 11.961770351000032],
              [107.3494706020001, 11.968842982000092],
              [107.34946095300009, 11.969280031000016],
              [107.34671327100004, 11.970732568000118],
              [107.34330538300006, 11.974307715000075],
              [107.33709678200009, 11.990044067000037],
              [107.33477230600005, 11.993105038000083],
              [107.32389711000005, 12.001505635000063],
              [107.31514318900005, 12.008209812000102],
              [107.31289305500003, 12.009158640000093],
              [107.311361823, 12.00769942100006],
              [107.30917901100004, 12.006795112000111],
              [107.30644609600006, 12.006965574000093],
              [107.30334704300006, 12.007673095000102],
              [107.2964183930001, 12.009623865000039],
              [107.29368171700006, 12.01087075400009],
              [107.29276720400006, 12.011944295000097],
              [107.29312469200006, 12.013919276000083],
              [107.29384278800006, 12.016972081000075],
              [107.29310607100004, 12.019302275000053],
              [107.29127757700009, 12.021269937000055],
              [107.28854321300007, 12.021799035000045],
              [107.28599601200007, 12.020893252000063],
              [107.28272395400006, 12.018908350000055],
              [107.28054620400007, 12.016568261000058],
              [107.278547491, 12.015125953000092],
              [107.27727426800007, 12.014583284000045],
              [107.27454122200007, 12.014753342000082],
              [107.27162601600001, 12.014922727000064],
              [107.25411962300005, 12.020065171000025],
              [107.24746101600006, 12.022643341000093],
              [107.24344403700005, 12.025141093000078],
              [107.24125000500005, 12.027286460000129],
              [107.24124467500006, 12.028721941000063],
              [107.24232906800007, 12.031058535000037],
              [107.24359366600008, 12.033934097000019],
              [107.24358635900009, 12.035907904000085],
              [107.2423024550001, 12.038236010000068],
              [107.24047463600003, 12.039844401],
              [107.24019422000001, 12.039877899000116],
              [107.23901651100009, 12.040018583000032],
              [107.23665013800004, 12.03947170600008],
              [107.2344726960001, 12.03713109600004],
              [107.23302737300006, 12.033895936000059],
              [107.23212930500009, 12.030483334000051],
              [107.23122248200011, 12.029403401000048],
              [107.23013147400007, 12.028861102000031],
              [107.22867205400003, 12.029394088000076],
              [107.22816081800001, 12.029635438000062],
              [107.228378617, 12.03046556200009],
              [107.22930693900007, 12.03400375700001],
              [107.22974550400002, 12.040598733000072],
              [107.2301832310001, 12.047421110000032],
              [107.23015582000005, 12.054696573000088],
              [107.23035500400002, 12.063337106000025],
              [107.22867704200009, 12.078109775000099],
              [107.22794533300004, 12.088949645000111],
              [107.22871714900003, 12.089645222000021],
              [107.23140593700001, 12.091989301000082],
              [107.23093297200005, 12.094488670000112],
              [107.2299921690001, 12.098123199],
              [107.23067979400005, 12.100172071000069],
              [107.2348280050001, 12.10655363800006],
              [107.23919895400007, 12.115437131000116],
              [107.2334014410001, 12.116098272000018],
              [107.22667583600006, 12.116983236000054],
              [107.22480417300001, 12.121523942000055],
              [107.22222748700007, 12.128563235000021],
              [107.22361057900011, 12.130614753000122],
              [107.22660782000003, 12.134946017000024],
              [107.22605803000002, 12.157682522000089],
              [107.22416612400006, 12.167453309000019],
              [107.219396014, 12.202227116000051],
              [107.21872062000007, 12.206606315000011],
              [107.20726386300001, 12.280865254000071],
              [107.20560722100007, 12.289273530000065],
              [107.20290421400006, 12.298844462000067],
              [107.20630887400003, 12.299405827000063],
              [107.21051900800003, 12.299302485000039],
              [107.21289246600004, 12.298772870000095],
              [107.21707957700001, 12.29729215800006],
              [107.21945368100006, 12.296582993000092],
              [107.22091434000004, 12.296229402000057],
              [107.22492670800003, 12.296423660000054],
              [107.22893638100005, 12.297335796000118],
              [107.23421691400007, 12.299867892000103],
              [107.24531568, 12.307625994000125],
              [107.25907094200001, 12.315862986000068],
              [107.26525087400002, 12.322839476000079],
              [107.26644802000004, 12.324373826000022],
              [107.26747765600005, 12.325413833000097],
              [107.26861197800011, 12.325996834000106],
              [107.27045596800011, 12.326279690000062],
              [107.27710322300004, 12.324385712000028],
              [107.27877165600007, 12.323748411000055],
              [107.28057447700012, 12.323865444000058],
              [107.28286837800002, 12.324257146000049],
              [107.283863266, 12.324837489000105],
              [107.28529181300007, 12.326437012000023],
              [107.28810164400004, 12.328664196000092],
              [107.28956447600009, 12.329334436000037],
              [107.29359511200008, 12.330760003000039],
              [107.29645229300006, 12.331533967000039],
              [107.30566719500008, 12.333490745000029],
              [107.30867157600007, 12.331574330000093],
              [107.30985240600008, 12.328329567000116],
              [107.31058697100011, 12.324648879000042],
              [107.31102362500008, 12.323910452000051],
              [107.31153666600008, 12.323495085000056],
              [107.3125171770001, 12.323059779000099],
              [107.31525048100005, 12.322946442000047],
              [107.31698237700007, 12.323210969000085],
              [107.31822976799999, 12.32356717800007],
              [107.33467671300005, 12.327469582000015],
              [107.33653691100004, 12.328453200000043],
              [107.34319677600004, 12.329358673000089],
              [107.34631818900003, 12.328858736000113],
              [107.35008295100005, 12.326784251000065],
              [107.35237088300008, 12.324833131000011],
              [107.35562823000004, 12.322422444000035],
              [107.3631657880001, 12.316191796000096],
              [107.369019076, 12.311183562000048],
              [107.37524350900004, 12.304022394000034],
              [107.37744676100009, 12.299362145000091],
              [107.37976982700009, 12.292945827000066],
              [107.38227402000008, 12.287583451000026],
              [107.38371219100006, 12.280517374000064],
              [107.38811549600005, 12.272261337000055],
              [107.39298018000004, 12.266250582000014],
              [107.39762861800007, 12.261845779000067],
              [107.4043369450001, 12.257446717000063],
              [107.41001167800005, 12.2541806560001],
              [107.4161718710001, 12.247986271000054],
              [107.41910323500009, 12.250362499000021],
              [107.42295034000009, 12.251950053000103],
              [107.42825813300004, 12.253045868000088],
              [107.43118775900005, 12.253234014000023],
              [107.43320122700003, 12.253599885000098],
              [107.43502956400005, 12.254686158000093],
              [107.43630702300005, 12.256311634000026],
              [107.43684514200008, 12.257217527000106],
              [107.43812995400008, 12.259380400000095],
              [107.43958467100001, 12.263169097000047],
              [107.43975693200004, 12.267134634000064],
              [107.44046530900003, 12.275967883000048],
              [107.44099502800007, 12.283178629000078],
              [107.44172768100003, 12.288824277000019],
              [107.4443755950001, 12.291304229000042],
              [107.44733961900002, 12.292878761000024],
              [107.45190141100008, 12.294681229000036],
              [107.45829114000004, 12.296040657000072],
              [107.46262417100009, 12.298066143000062],
              [107.46649885700005, 12.300761852000084],
              [107.46831916100003, 12.303228512000073],
              [107.46944864600005, 12.307931673000015],
              [107.46972320900002, 12.312061353000024],
              [107.49936320000009, 12.325081946000081],
              [107.5026554950001, 12.326358424000093],
              [107.50465899900004, 12.32734989700006],
              [107.50872701000002, 12.33044144300006],
              [107.51147827000001, 12.333335406000055],
              [107.513053998, 12.33515937900007],
              [107.51691434700003, 12.338692442000097],
              [107.52346449100003, 12.342688163000036],
              [107.53181662800007, 12.347401661000033],
              [107.53653746600006, 12.349736815000066],
              [107.53949483300006, 12.350758986000086],
              [107.54164173200006, 12.35189141100008],
              [107.54321357900008, 12.353868403000055],
              [107.54363389400004, 12.354918703000093],
              [107.54470987300007, 12.357607353000065],
              [107.54570387600008, 12.361697662000013],
              [107.54567723300009, 12.365406975000058],
              [107.5447842240001, 12.369992609000045],
              [107.54612828000002, 12.373994097000017],
              [107.54929004000006, 12.376035798000105],
              [107.55206731500007, 12.378992567000056],
              [107.55236884400007, 12.380492406000075],
              [107.55206073400007, 12.381990954000075],
              [107.54613869399999, 12.404076304000087],
              [107.5458512160001, 12.416374111000026],
              [107.54921516200008, 12.424214278000088],
              [107.55236456300007, 12.428767819000084],
              [107.55646379800004, 12.436522746000021],
              [107.55838686600001, 12.440225015000104],
              [107.56420592500008, 12.451981610000116],
              [107.56459265700002, 12.461278162000065],
              [107.56411591500009, 12.47032313000007],
              [107.56411143, 12.472422196000108],
              [107.56431281900004, 12.473322213000058],
              [107.56542930100005, 12.474124158000064],
              [107.56999826500008, 12.476732372000042],
              [107.57416133700009, 12.479039800000089],
              [107.57527602500005, 12.480741302000096],
              [107.57578077900007, 12.482441587],
              [107.57618304900004, 12.48454148500011],
              [107.57794435300011, 12.493080131000054],
              [107.57585487800006, 12.496898671000077],
              [107.57335637300011, 12.499379543000087],
              [107.57172718300004, 12.500575745000029],
              [107.57009671900005, 12.502371689000123],
              [107.56846156500004, 12.506366720000093],
              [107.56645259400007, 12.513444882000078],
              [107.563915197, 12.519895301000082],
              [107.563570575, 12.522613686000026],
              [107.5643403760001, 12.52771238900001],
              [107.57844056300004, 12.548170675000046],
              [107.58265163800004, 12.552627417000027],
              [107.58721974800008, 12.556634940000054],
              [107.58782526600001, 12.558935297000049],
              [107.58708333400008, 12.561135273000088],
              [107.58486529200005, 12.564227627000067],
              [107.58047701200005, 12.571616390000083],
              [107.57899437500004, 12.575462127000046],
              [107.57857991400003, 12.579160045000036],
              [107.57847076600005, 12.582758624000066],
              [107.578619303, 12.584708276000088],
              [107.57952520400008, 12.589308560000044],
              [107.58092327700002, 12.592611112],
              [107.58187965600011, 12.604207526],
              [107.58100486600003, 12.611704400000077],
              [107.57049640100009, 12.63203992100008],
              [107.56740214500003, 12.646366952000053],
              [107.56779659900008, 12.652266166000102],
              [107.56894138500012, 12.664215374000063],
              [107.57056009600002, 12.668617550000059],
              [107.57442647800009, 12.682697075000069],
              [107.57258473300003, 12.691614687000106],
              [107.56595103900005, 12.699322335000128],
              [107.56081540100003, 12.705288618000042],
              [107.55928252800004, 12.707885140000117],
              [107.55825940200002, 12.710382470000068],
              [107.55784515800012, 12.713680903000045],
              [107.5588056230001, 12.716632297000084],
              [107.56062938300001, 12.720435310000065],
              [107.56428075000005, 12.726341668000083],
              [107.56549699000006, 12.728743696000054],
              [107.56570721200004, 12.7320945010001],
              [107.56354631200003, 12.73538117300008],
              [107.56018160700009, 12.741956482000115],
              [107.55897173600007, 12.748066103000076],
              [107.55798685900004, 12.760758614000077],
              [107.55939856500004, 12.772045739000058],
              [107.55938585600006, 12.777922933000061],
              [107.56079167800002, 12.792031322000062],
              [107.55911706000002, 12.79747119100003],
              [107.57257974100006, 12.797828095000073],
              [107.59163348400006, 12.799476914000072],
              [107.59445447400006, 12.800633184000086],
              [107.59774786200006, 12.800869645000111],
              [107.62527078300012, 12.803221882000047],
              [107.63463741700009, 12.800621509000097],
              [107.64669141900002, 12.797274368000087],
              [107.65374680000008, 12.798897209000069],
              [107.65774643900006, 12.798903718000075],
              [107.66198286800002, 12.797989852000045],
              [107.66857309300005, 12.796389108000115],
              [107.67563337200006, 12.795019052000088],
              [107.68387061400003, 12.793190184000112],
              [107.69022559900004, 12.79135825000006],
              [107.69799170000002, 12.78975831800002],
              [107.71116996900007, 12.787244903000071],
              [107.722935433, 12.7854193150001],
              [107.72999498500005, 12.784047459000101],
              [107.73384620700003, 12.78383071300002],
              [107.73799426700005, 12.78359718200006],
              [107.74740732600004, 12.781306985000105],
              [107.7563477410001, 12.780626987000064],
              [107.76246562800003, 12.7794831250001],
              [107.76975812500008, 12.779721348000093],
              [107.7838731670001, 12.779736242000082],
              [107.79477127600005, 12.780537369000021],
              [107.79815665900003, 12.78109484400005],
              [107.80387482500001, 12.782643782000045],
              [107.81482752400004, 12.789486231000067],
              [107.81692522500005, 12.791502902000039],
              [107.81899029000003, 12.795567809000106],
              [107.82179111000005, 12.803261150000099],
              [107.8232601980001, 12.802421252000061],
              [107.82730030700006, 12.802063935000072],
              [107.83390882900009, 12.804593777000049],
              [107.84161649800002, 12.810730752000053],
              [107.844553, 12.812896841000041],
              [107.84822573800002, 12.812899508000084],
              [107.85116656600007, 12.809295084000015],
              [107.85778191200005, 12.80280792500008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 18, code: "AD01", ten_tinh: "Điện Biên" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [102.358446434, 22.482741910000037],
              [102.35965258100005, 22.47872343000008],
              [102.36013182000009, 22.477663747000065],
              [102.36072859500008, 22.476964295000101],
              [102.36195828400004, 22.476518998000117],
              [102.36494169900007, 22.475224790000055],
              [102.36856668900006, 22.473887170000047],
              [102.37115048100006, 22.473118695000061],
              [102.37398846100004, 22.472416133000102],
              [102.37882386500009, 22.471287472000064],
              [102.38185895200004, 22.470411140000031],
              [102.38321998200003, 22.469849927000048],
              [102.3840795080001, 22.468918832000014],
              [102.38915875900007, 22.461603383000025],
              [102.38982281500003, 22.460786432000113],
              [102.39045056500001, 22.459015833000073],
              [102.39063044000007, 22.457233976000076],
              [102.39040050600005, 22.454131795000087],
              [102.38987317800012, 22.450248009000084],
              [102.38886519400002, 22.4452802550001],
              [102.38871655400007, 22.442686214000048],
              [102.38889296800001, 22.441023307000037],
              [102.38996849500009, 22.439263891000039],
              [102.39261745600007, 22.43623391600007],
              [102.39422892500008, 22.433654231000055],
              [102.39560696400007, 22.425606106000103],
              [102.39449511300009, 22.425520482000039],
              [102.39167028900003, 22.424016956000067],
              [102.38964513000008, 22.422318605000058],
              [102.38915547900007, 22.421542251000034],
              [102.38907808100005, 22.420680745000077],
              [102.38910028100003, 22.419917262000055],
              [102.38965652400003, 22.418403137],
              [102.39025700700007, 22.417128884000093],
              [102.39028475200006, 22.416174520000084],
              [102.39004964300005, 22.415452305000102],
              [102.3861835160001, 22.409737530000129],
              [102.3890529220001, 22.4047016210001],
              [102.39065379600004, 22.402478851000041],
              [102.39170142300003, 22.401671438000022],
              [102.39324000200006, 22.401590985000027],
              [102.39489267400003, 22.401989814000075],
              [102.39668019400004, 22.402153803000054],
              [102.39771397300005, 22.401822432000081],
              [102.39877193000009, 22.400657923000011],
              [102.40184887700005, 22.396089963000065],
              [102.40369711600002, 22.392974213000016],
              [102.40386780000003, 22.3926864680001],
              [102.40559631700005, 22.390466742000022],
              [102.40665071500007, 22.389421191000025],
              [102.40795059800007, 22.388739114000074],
              [102.41038793800003, 22.388561951000071],
              [102.41383828200004, 22.388767450000024],
              [102.41576394900004, 22.388577421000022],
              [102.41784850500002, 22.387319368000114],
              [102.42048599400003, 22.384645807000055],
              [102.42208977000004, 22.382303692000072],
              [102.42239718800008, 22.38052471900005],
              [102.42237590300009, 22.376831741000025],
              [102.42256227500005, 22.374811496000021],
              [102.4232671660001, 22.372565973000036],
              [102.42393421000006, 22.371629740000017],
              [102.42588374600007, 22.37060642800008],
              [102.43171851600002, 22.36801248400004],
              [102.43366112000008, 22.367227146000076],
              [102.43571445000006, 22.367040136000057],
              [102.43750511200008, 22.367084780000113],
              [102.43892577600003, 22.366643729000124],
              [102.44088546500009, 22.365263186000028],
              [102.44430344700001, 22.36213222400005],
              [102.44721314000006, 22.358869418],
              [102.45147012200003, 22.353257730000031],
              [102.45358826100005, 22.35080893300001],
              [102.45453821900004, 22.348926660000053],
              [102.45485213200008, 22.346909449000037],
              [102.45480970900005, 22.343930420000021],
              [102.45497178800004, 22.342743252000027],
              [102.45552093900008, 22.341446579000035],
              [102.45683399700002, 22.340288004000094],
              [102.46046605300006, 22.338591412000049],
              [102.46243555500004, 22.336853496000053],
              [102.46454996300011, 22.334523540000092],
              [102.46642220400003, 22.331711082000091],
              [102.46696783600005, 22.330533390000085],
              [102.46828757100009, 22.329136652000066],
              [102.471141828, 22.327777931000092],
              [102.47193636600011, 22.326844636000054],
              [102.47238474800008, 22.324592396000043],
              [102.47270864700005, 22.322217944000045],
              [102.47326102700002, 22.32080213100005],
              [102.47433511600008, 22.319041866000063],
              [102.47736131400008, 22.316138671000104],
              [102.478950228, 22.314271987000019],
              [102.48135734400005, 22.310638619000045],
              [102.48374057700006, 22.307838512000089],
              [102.48661820000004, 22.305646225000061],
              [102.48832120500008, 22.304258778000104],
              [102.48911216700006, 22.303444413000051],
              [102.48952972400006, 22.302263447000122],
              [102.48868903800005, 22.300336598000037],
              [102.48822176500002, 22.298776394000086],
              [102.48838359200009, 22.297589090000081],
              [102.48958187900011, 22.29595087600007],
              [102.49114674, 22.294917356],
              [102.49347532300003, 22.294021813000064],
              [102.49568621200008, 22.292765942000067],
              [102.49817651899998, 22.290683020000031],
              [102.50056604200007, 22.287644518000072],
              [102.50522377200008, 22.281326136000068],
              [102.50762998400003, 22.277692238000043],
              [102.50844783300002, 22.275925355000048],
              [102.50945103000008, 22.272137716000096],
              [102.510595564, 22.267876970000074],
              [102.51170623400006, 22.264806731000064],
              [102.51332481600005, 22.261868107000012],
              [102.514912936, 22.26000095800007],
              [102.51806204800006, 22.257219051000021],
              [102.52276867500011, 22.25364137900003],
              [102.52603528300003, 22.25121964100012],
              [102.53020333800001, 22.24858168600008],
              [102.534875852, 22.246194285000072],
              [102.54121332900007, 22.24372841400006],
              [102.5441667420001, 22.243324142000098],
              [102.54799810900002, 22.243537003000121],
              [102.55129803500003, 22.24445168000004],
              [102.55293291500004, 22.245444766000055],
              [102.55479996400007, 22.247277499000084],
              [102.55651239000008, 22.250059560000054],
              [102.55747129600012, 22.252346643000109],
              [102.55937241200012, 22.257516048000078],
              [102.56046928400011, 22.259449029000052],
              [102.56133397000005, 22.260542368000102],
              [102.56259557000008, 22.261168832000067],
              [102.56538787400009, 22.261951732000092],
              [102.5680757920001, 22.261898089000098],
              [102.57193097900004, 22.261277147000058],
              [102.57671440100006, 22.259487343000025],
              [102.58047841200001, 22.257553507000068],
              [102.58308183400004, 22.255948830000079],
              [102.59143980900004, 22.249837323000051],
              [102.59602040600005, 22.246135889000037],
              [102.600328534, 22.243023454000088],
              [102.60318391500009, 22.241543739000043],
              [102.60770427200001, 22.239985127000047],
              [102.6133948720001, 22.237739834000081],
              [102.61598448300006, 22.236610964000114],
              [102.61714076200008, 22.235699910000051],
              [102.61777043400012, 22.234799943000013],
              [102.61834024300002, 22.233335335000071],
              [102.61860992400004, 22.231793091],
              [102.61880211100004, 22.230319358000102],
              [102.61883541700004, 22.229123384],
              [102.61877948800003, 22.228418046000087],
              [102.61834398800006, 22.227773949],
              [102.61650691000003, 22.225899211000041],
              [102.61489250300012, 22.224170629000106],
              [102.61431775300008, 22.223100751000047],
              [102.61405685600008, 22.221616057000034],
              [102.61397641100008, 22.219079707000056],
              [102.61394599300006, 22.217459767000044],
              [102.61413231900011, 22.21619706200002],
              [102.61454322800009, 22.21501017600005],
              [102.61541116100005, 22.213693524000085],
              [102.61604468900006, 22.212652812000044],
              [102.61645949800001, 22.211325214000063],
              [102.61664581200006, 22.210062482000033],
              [102.61667324800003, 22.209077529000076],
              [102.61597493800006, 22.207019007000014],
              [102.61557086700003, 22.205249195000043],
              [102.61546491800006, 22.203627385000054],
              [102.61556590900005, 22.202714595000117],
              [102.61591303000009, 22.201103721000059],
              [102.61640333500007, 22.199777908000051],
              [102.61710840200006, 22.19887970000007],
              [102.61810375400007, 22.1984109140001],
              [102.61931779100007, 22.19822901900006],
              [102.62038472600007, 22.197902759000065],
              [102.62122903700003, 22.197430312000051],
              [102.62529249500007, 22.192600094000078],
              [102.62614460600005, 22.191846184000049],
              [102.62737619600007, 22.191031027000108],
              [102.63004827900001, 22.190039365000032],
              [102.63317922700007, 22.188847510000095],
              [102.63418033700003, 22.188167572000062],
              [102.63481172800005, 22.187197106000056],
              [102.63618904300006, 22.183850831000115],
              [102.63772903400012, 22.18008600200001],
              [102.63843977600004, 22.178976608000056],
              [102.63952608300012, 22.177946655000099],
              [102.64082912800002, 22.177273936000041],
              [102.64295488000005, 22.176902608000049],
              [102.64840465800003, 22.176540717000037],
              [102.65309356300004, 22.176371645000081],
              [102.65490755200003, 22.176344761000074],
              [102.65627241800007, 22.176166269000049],
              [102.65894608200004, 22.175103875000076],
              [102.66299816700003, 22.173370393000091],
              [102.66537945700006, 22.17194888300007],
              [102.66799692000009, 22.17018095300007],
              [102.66999095500006, 22.169102153000075],
              [102.67174100199999, 22.168651157000063],
              [102.67287348800008, 22.168678234000083],
              [102.67377366600005, 22.168910971000066],
              [102.67451896299998, 22.169280818000026],
              [102.67547914000002, 22.170078243000098],
              [102.67687294200006, 22.171590077000047],
              [102.67789313400002, 22.172952160000037],
              [102.67881654400003, 22.175086394000054],
              [102.67980192100002, 22.177714935000026],
              [102.6804543370001, 22.178716181000091],
              [102.68148427000004, 22.179726442000103],
              [102.68266522800006, 22.180740300000053],
              [102.68376295600002, 22.182033777000051],
              [102.68463612300006, 22.183251488000089],
              [102.68519760100007, 22.184813782000035],
              [102.68559646400003, 22.186794614000057],
              [102.68620832100002, 22.189273344000071],
              [102.6868821730001, 22.192246363000031],
              [102.68715712700002, 22.193238568000062],
              [102.68781350000006, 22.194099045000115],
              [102.69040607300008, 22.195991307],
              [102.69234005500005, 22.197553482000032],
              [102.69366772300003, 22.199515965000046],
              [102.69472999400008, 22.202759361000091],
              [102.6957393440001, 22.207932307000085],
              [102.69568050000004, 22.210076281000021],
              [102.69516844800003, 22.21251563900006],
              [102.69415083800003, 22.215251113000086],
              [102.69254330800007, 22.219854128000094],
              [102.69097358600004, 22.223078157000131],
              [102.68776489600003, 22.227141179000043],
              [102.68606736300009, 22.230111219000023],
              [102.68546702400006, 22.232354760000113],
              [102.68537738200011, 22.235613960000045],
              [102.68519109400006, 22.237491050000102],
              [102.68448028900005, 22.238853889000062],
              [102.68211661700005, 22.241557070000084],
              [102.679487169, 22.24412844700003],
              [102.67674034400002, 22.246069813000076],
              [102.67087044000009, 22.248939964000058],
              [102.66636003400001, 22.251340755000015],
              [102.66187722400009, 22.252738655000122],
              [102.66036908200002, 22.253705995000047],
              [102.65994800500007, 22.254323067000122],
              [102.65992721700005, 22.255075156000089],
              [102.66045170800001, 22.25558944],
              [102.66231513700009, 22.25638664100007],
              [102.66658730900008, 22.257743167000044],
              [102.66923756100007, 22.259311704000062],
              [102.67554014800001, 22.263461561000049],
              [102.68060245400009, 22.258509210000099],
              [102.68448162000007, 22.254446007000084],
              [102.68820268700001, 22.249899442000043],
              [102.69035246800007, 22.247411329000052],
              [102.69127, 22.246838980000035],
              [102.69260891800008, 22.246375688000072],
              [102.69356993500001, 22.246150978000045],
              [102.69415307500003, 22.246164856000036],
              [102.69530847700007, 22.246588479000096],
              [102.69593511900004, 22.246950011000074],
              [102.696728955, 22.247018409000049],
              [102.69806241900002, 22.246753024000029],
              [102.70079864400003, 22.245629670000078],
              [102.70251810700009, 22.244828748000067],
              [102.70381617200012, 22.243918762000092],
              [102.70490353400002, 22.242954248000046],
              [102.70692732400005, 22.240724513000117],
              [102.70790773200008, 22.23960416900006],
              [102.7088150870001, 22.23916144000011],
              [102.70951091000003, 22.239177953000052],
              [102.71068599000009, 22.239855812000094],
              [102.71183821100004, 22.24136880100005],
              [102.71271762400006, 22.241946786000085],
              [102.71370914600003, 22.242063145000124],
              [102.71582205500006, 22.24118470000009],
              [102.71845733100005, 22.239390074000106],
              [102.71957107400002, 22.238673622000071],
              [102.7207689940001, 22.238516287000017],
              [102.72305529400009, 22.238570415000055],
              [102.72543593600007, 22.238812454000097],
              [102.72682507000007, 22.238938172000111],
              [102.72782671200008, 22.238683301000073],
              [102.73078029700012, 22.236153204000075],
              [102.73413394300005, 22.233539659000101],
              [102.73638398600005, 22.23127142],
              [102.73741843000002, 22.229810161000074],
              [102.73755320400008, 22.228513355000068],
              [102.73759363800005, 22.227028620000056],
              [102.73782529200011, 22.225826953000102],
              [102.73846210500005, 22.224356287000099],
              [102.740518244, 22.221897671000065],
              [102.74265604700005, 22.220090962000057],
              [102.74375949300004, 22.219745553000081],
              [102.74475344300004, 22.219768988000062],
              [102.74576000900001, 22.219328425000029],
              [102.74699058000004, 22.217964563000066],
              [102.74833819200008, 22.215953452000022],
              [102.74871031800005, 22.21507904700006],
              [102.74866743200005, 22.213998991000047],
              [102.74812841100004, 22.21257003700012],
              [102.74814855800011, 22.211828668000067],
              [102.74824089200011, 22.211088989000061],
              [102.74869596900001, 22.210290424000064],
              [102.74957683600007, 22.209771647000039],
              [102.751386987, 22.208747946000074],
              [102.749780028, 22.206091719000121],
              [102.74861513700007, 22.202867794],
              [102.7472918110001, 22.197066197000126],
              [102.7462376340001, 22.191270876000054],
              [102.74582555700002, 22.186619637000057],
              [102.74600275300003, 22.180100526000054],
              [102.74564685700005, 22.178335847000064],
              [102.74434241800004, 22.176799693000056],
              [102.74209628200001, 22.175241308000082],
              [102.73810811800007, 22.17339087500001],
              [102.73652446900005, 22.172224417000038],
              [102.73574802100011, 22.171077015000051],
              [102.73527136800001, 22.168807625000042],
              [102.73496339300002, 22.165287692000035],
              [102.73544549000003, 22.162413660000091],
              [102.737247467, 22.155556248000075],
              [102.73770903200008, 22.153434407000105],
              [102.73789132700007, 22.151682329],
              [102.73793909800003, 22.149927067000071],
              [102.73722756100004, 22.146397472000068],
              [102.73725827500002, 22.145269078000048],
              [102.73814723500001, 22.142279079000012],
              [102.73988753600004, 22.137678197000099],
              [102.74117313100001, 22.134948427000012],
              [102.74298644600003, 22.132607481000029],
              [102.74590222100005, 22.129901602000054],
              [102.74890281900008, 22.127854022000101],
              [102.75124715300007, 22.125776413000061],
              [102.75444263400006, 22.122087803000042],
              [102.75803126400007, 22.11878476000004],
              [102.76077206900008, 22.116967276000118],
              [102.76462877700007, 22.115062494000078],
              [102.7651368840001, 22.114811540000098],
              [102.76829121700005, 22.112627275000115],
              [102.77036279100012, 22.110668469000046],
              [102.77260270400009, 22.107458922000056],
              [102.7738631170001, 22.105537346000048],
              [102.77484237300004, 22.104186878000043],
              [102.77538865600003, 22.10359537500004],
              [102.77676822500004, 22.102693779000049],
              [102.77832295500008, 22.101851227000054],
              [102.77939131800011, 22.101256590000062],
              [102.77970990700008, 22.100675097000085],
              [102.77960992200008, 22.100013491000055],
              [102.77886813400006, 22.099117083000053],
              [102.77708276600005, 22.097591884000067],
              [102.77554036200006, 22.095797656000023],
              [102.77486342800009, 22.094682997000078],
              [102.77459126300005, 22.093852531000024],
              [102.77435470900011, 22.091704343000096],
              [102.77438734100005, 22.09049644100007],
              [102.774654012, 22.089348967000078],
              [102.77502805700004, 22.088588585000046],
              [102.77563169700007, 22.088053350000081],
              [102.77705669600002, 22.087647258000054],
              [102.78107687000004, 22.087137167000037],
              [102.78314951200011, 22.086746204000107],
              [102.78528399900006, 22.086246788],
              [102.78707407700001, 22.085409662000096],
              [102.78930735700006, 22.083429141000096],
              [102.79207346500004, 22.081351178000119],
              [102.79386051300003, 22.080623795000065],
              [102.79600229100004, 22.079849745000054],
              [102.79927307900005, 22.078717462000071],
              [102.80154289600009, 22.07756176500002],
              [102.80620022200006, 22.075253041000039],
              [102.80865101000006, 22.073936660000101],
              [102.81081181100006, 22.072448675000089],
              [102.81351865100002, 22.070369020000058],
              [102.8152628240001, 22.069036106000084],
              [102.8163534700001, 22.067907715000068],
              [102.81737493700007, 22.067162314000122],
              [102.81975638700001, 22.066228770000059],
              [102.82360966200007, 22.065329430000077],
              [102.82638115100005, 22.065229023000121],
              [102.83032865600009, 22.065210822000033],
              [102.83315460800006, 22.065276414000046],
              [102.83515339300006, 22.065432671000096],
              [102.83720080200008, 22.065974626000084],
              [102.83954259700005, 22.066523385000011],
              [102.84095412500004, 22.066611036000069],
              [102.84430558200005, 22.066853488000056],
              [102.84842242500007, 22.067113599],
              [102.84995463300001, 22.067094099000073],
              [102.85125865100008, 22.066794601000069],
              [102.85245513300003, 22.066108030000109],
              [102.85443595400007, 22.064725315000096],
              [102.85720343300008, 22.066489798000077],
              [102.85971870900009, 22.06993219600006],
              [102.86150214200003, 22.0763948210001],
              [102.86431057900006, 22.079323206000097],
              [102.86799795100006, 22.080709843000079],
              [102.87245275000006, 22.08124633500006],
              [102.87697919600004, 22.081074060000013],
              [102.878671544, 22.08081616700008],
              [102.87914061300009, 22.080594369000025],
              [102.88026204300006, 22.079955634000022],
              [102.88135577200005, 22.079017215000093],
              [102.88255105100009, 22.078280491000065],
              [102.88680370000006, 22.076451047000134],
              [102.88831321900008, 22.075653055000096],
              [102.88916004400008, 22.075672492000109],
              [102.89059685800002, 22.076269898000064],
              [102.89430245800003, 22.078048170000031],
              [102.89693122400011, 22.079350162000111],
              [102.89823817700007, 22.080283190000038],
              [102.89905528400008, 22.081430755000042],
              [102.89986645200005, 22.082803945000059],
              [102.90139458600001, 22.084532193000058],
              [102.90411178600004, 22.087077822000097],
              [102.90649579700003, 22.088486955000072],
              [102.91211380599999, 22.091211669000096],
              [102.91380167300011, 22.091475985000031],
              [102.91707422600008, 22.091324937000053],
              [102.9186470440001, 22.091360828000091],
              [102.9211847920001, 22.091531595000014],
              [102.92322971600002, 22.092029753000105],
              [102.92394082900006, 22.092610366000095],
              [102.92451320000008, 22.093865108000088],
              [102.92447767700007, 22.095218872000096],
              [102.92378095500007, 22.098702297000017],
              [102.92348863300003, 22.100614591000053],
              [102.92343533600007, 22.102645213000116],
              [102.923086751, 22.106700908000043],
              [102.92306306100008, 22.10760339700002],
              [102.92329912700008, 22.107834533000045],
              [102.92402511100002, 22.107851079000085],
              [102.92880905100007, 22.105476724000091],
              [102.93027872600008, 22.104832913000024],
              [102.93102539700004, 22.104059751000094],
              [102.93117595400007, 22.102934385000061],
              [102.93162466800007, 22.099671085000061],
              [102.932330119, 22.095849204000025],
              [102.93300303500007, 22.093268250000087],
              [102.93406548600005, 22.089680201000036],
              [102.93447570400006, 22.087883418000054],
              [102.93462032000004, 22.086983643000032],
              [102.93427805700006, 22.086185683000089],
              [102.92979306800004, 22.081794040000077],
              [102.92802569600009, 22.079947650000072],
              [102.92732348300008, 22.079028576000056],
              [102.92663607900005, 22.077545415000088],
              [102.92656244500009, 22.075737569000076],
              [102.92687540800004, 22.073035443000052],
              [102.92740072300008, 22.071467006000134],
              [102.92803516900008, 22.070352591000031],
              [102.92890859000005, 22.069356502000034],
              [102.93062586300002, 22.068492515000045],
              [102.93256436300001, 22.068423743000039],
              [102.93498380600009, 22.068478789000068],
              [102.93801105200001, 22.068434741000061],
              [102.94019740100008, 22.068145774000044],
              [102.94167561400008, 22.06716337300012],
              [102.94267877600004, 22.065831512000052],
              [102.94408610500004, 22.062928393000014],
              [102.94472337400001, 22.061701092000071],
              [102.94659683600004, 22.059485845000012],
              [102.95056787700007, 22.055737704000045],
              [102.95368320799999, 22.052308742000015],
              [102.95716130300009, 22.048887934000035],
              [102.96052419600007, 22.045238661000077],
              [102.96188998000009, 22.043914869000055],
              [102.96264802800002, 22.042690207000071],
              [102.96268033700004, 22.041449120000053],
              [102.96186596900006, 22.040188872000051],
              [102.96141152800004, 22.039049656000117],
              [102.96143503100002, 22.038147039000044],
              [102.96170043500004, 22.037249904000063],
              [102.96494209200003, 22.033597743000044],
              [102.96680037700004, 22.031946341],
              [102.96778850400008, 22.03117842200006],
              [102.96888877900008, 22.030751705000014],
              [102.97046111500006, 22.030787235000027],
              [102.97504839100002, 22.031229519000114],
              [102.97649978400003, 22.031262275000074],
              [102.97771806000002, 22.030951073000082],
              [102.97943476400003, 22.030086643000054],
              [102.98226339000004, 22.028344092000026],
              [102.98385033200007, 22.027815384000071],
              [102.98481791200001, 22.027837184000099],
              [102.98589182000005, 22.02842584800004],
              [102.98719302200007, 22.02958410500004],
              [102.988959166, 22.031517490000049],
              [102.98919362800004, 22.031774147000057],
              [102.9912918750001, 22.034869502000021],
              [102.99222153200002, 22.036358031000077],
              [102.99278250100006, 22.038064053000106],
              [102.99330845800006, 22.041123979000062],
              [102.99439250900005, 22.046002702000052],
              [102.99632043400005, 22.051013226000023],
              [102.99701702900005, 22.052157780000044],
              [102.99820915300009, 22.052861898000039],
              [103.00005726200007, 22.0532297110001],
              [103.00363562200002, 22.053678688000034],
              [103.00649954600001, 22.053137573000114],
              [103.00920505700006, 22.052882500000059],
              [103.01105245800005, 22.053264984000087],
              [103.01426608500003, 22.055320147000046],
              [103.01679716200002, 22.056502928000029],
              [103.01887720100008, 22.056816149000014],
              [103.02198473800007, 22.055413652000055],
              [103.02712004600006, 22.052523455000106],
              [103.03309915900006, 22.050003245000127],
              [103.03643301700004, 22.048963202000046],
              [103.0404495600001, 22.0487953250001],
              [103.04368733600003, 22.04912207700005],
              [103.04747241000004, 22.051047016000069],
              [103.05035699000005, 22.052513930000117],
              [103.05372759400005, 22.054426214000067],
              [103.05501023600004, 22.056601640000025],
              [103.05650480900007, 22.060219694000068],
              [103.05725900800003, 22.061524760000047],
              [103.05886627400005, 22.062551915000128],
              [103.06078626100003, 22.063294749000036],
              [103.06225194800007, 22.063384127000063],
              [103.06543548900004, 22.062053697000024],
              [103.0702507510001, 22.059950382000082],
              [103.07536293100004, 22.058714507000055],
              [103.08107799400005, 22.058565620000081],
              [103.08787956000006, 22.05799719200003],
              [103.09027055300001, 22.058169152000048],
              [103.09165322300001, 22.05868932500001],
              [103.09287380900011, 22.059783673000055],
              [103.093906233, 22.063396049000069],
              [103.09411174400003, 22.065342624000017],
              [103.0937894460001, 22.066346968000069],
              [103.09238638500001, 22.067338709000062],
              [103.08989480600009, 22.068893782000117],
              [103.08724691100012, 22.070358622000086],
              [103.09103163500001, 22.075859925000074],
              [103.09305543100004, 22.078194082000024],
              [103.09357204100006, 22.08254598400012],
              [103.09385766200006, 22.084277478000033],
              [103.09491728600008, 22.085873985000056],
              [103.09627129300002, 22.088554012000117],
              [103.09753654100008, 22.092097079000084],
              [103.09756838600005, 22.095481785000089],
              [103.09751177700008, 22.097226272000015],
              [103.09959997700001, 22.103222510000094],
              [103.10669003900009, 22.101295762000106],
              [103.11465977500004, 22.098038697000085],
              [103.11866928500005, 22.095430011000069],
              [103.12083936000002, 22.092780951000087],
              [103.12639702600005, 22.080890288000084],
              [103.13074276499999, 22.07534680600007],
              [103.13423227400006, 22.072481205000116],
              [103.14009239300007, 22.069422133000046],
              [103.14353860200002, 22.068271526000061],
              [103.14616586900006, 22.068328810000089],
              [103.14820622400003, 22.070824847000026],
              [103.15124845000004, 22.075303925000078],
              [103.15319134400004, 22.076449421000085],
              [103.15581258900002, 22.076751614000045],
              [103.158458362, 22.076073686000086],
              [103.1603220370001, 22.075133581000017],
              [103.16116367900003, 22.075194513000085],
              [103.16268666000009, 22.07518495900009],
              [103.16475793100005, 22.076455706000075],
              [103.16678025600004, 22.079686603000056],
              [103.16805112900003, 22.081430245],
              [103.169627597, 22.081464445000073],
              [103.1720289900001, 22.080045603000094],
              [103.18005169300008, 22.074580866000112],
              [103.1880084300001, 22.071310231000041],
              [103.18855065300002, 22.071087338000069],
              [103.19463607900011, 22.06950264700005],
              [103.19964010200007, 22.069120265000031],
              [103.20435100100003, 22.069957231],
              [103.20827374700006, 22.070777146000076],
              [103.21065046100007, 22.070337966000061],
              [103.21383354000001, 22.069180623000051],
              [103.2202238080001, 22.065885685000097],
              [103.22637530400006, 22.0616047930001],
              [103.23603232300007, 22.053721293000095],
              [103.241114193, 22.05015251400004],
              [103.24402803600005, 22.049234118000079],
              [103.25193920900003, 22.048177140000021],
              [103.26027134700003, 22.046025601000082],
              [103.27062444100009, 22.041832759],
              [103.27724561300002, 22.039766797000127],
              [103.28119196700004, 22.039605304000084],
              [103.28435229700005, 22.040463609000049],
              [103.28510845300008, 22.040668961000073],
              [103.28636672500001, 22.041897234000096],
              [103.28793243800007, 22.043425587000073],
              [103.28944896500005, 22.045909333000054],
              [103.289389312, 22.048359723000118],
              [103.28879230800007, 22.051289056000108],
              [103.28472033100003, 22.056596411000037],
              [103.2747605390001, 22.066191608000096],
              [103.27230618300007, 22.069816836000108],
              [103.27172083100004, 22.072255948000041],
              [103.27122790700011, 22.07629049700008],
              [103.27218305700009, 22.080233179000075],
              [103.28283588400008, 22.10693445],
              [103.28616249500007, 22.110681893],
              [103.28874859300001, 22.112452540000049],
              [103.29215294600004, 22.113014781000025],
              [103.29818523500009, 22.113632455000065],
              [103.30237800500007, 22.114211188000077],
              [103.30524494300005, 22.11525215300005],
              [103.30702504900007, 22.117740971000011],
              [103.31272840300008, 22.130089660000074],
              [103.31377034300006, 22.132345410000106],
              [103.31645365400003, 22.140981050000086],
              [103.31715894500003, 22.142760365000044],
              [103.31797179900005, 22.144090277000124],
              [103.321444931, 22.148014168000074],
              [103.32489012200004, 22.150962851000067],
              [103.32928280100006, 22.153954943000095],
              [103.33054773100008, 22.154618201000083],
              [103.3307862130001, 22.154743241000041],
              [103.331898182, 22.154786468000061],
              [103.33796734500008, 22.153933094000067],
              [103.34351075600009, 22.153068559000033],
              [103.35087637400005, 22.152977260000029],
              [103.37747434900005, 22.157022690000083],
              [103.38074739800007, 22.157520271000038],
              [103.38363879700002, 22.157580192000026],
              [103.38667038600003, 22.157275324000025],
              [103.38827089400003, 22.156328015000071],
              [103.38935151200009, 22.155124840000084],
              [103.38964356800001, 22.153905334000079],
              [103.38893681600007, 22.150459158000047],
              [103.38873240200012, 22.148003803000016],
              [103.38933403300007, 22.14482978000003],
              [103.39124980100004, 22.141682915000068],
              [103.39988194000009, 22.132546836000074],
              [103.40204858800003, 22.129895237000063],
              [103.40321050500006, 22.125261885000036],
              [103.41288929300002, 22.088568973000022],
              [103.41486800500005, 22.082726298000033],
              [103.41653160800003, 22.079083363000059],
              [103.41901819200007, 22.073986436000048],
              [103.42204171400003, 22.068410171000075],
              [103.42827568300007, 22.060448209000057],
              [103.432040595, 22.056848139000081],
              [103.43417126100009, 22.055666055000067],
              [103.43601605800005, 22.055458724000054],
              [103.43965367300011, 22.05724935100006],
              [103.44200083200008, 22.058032871000052],
              [103.446741339, 22.057639550000104],
              [103.44913463900004, 22.056462697000079],
              [103.4528818090001, 22.053597378000056],
              [103.46009534900001, 22.040722710000082],
              [103.46680846599999, 22.031326410000048],
              [103.46750822100007, 22.02950189799999],
              [103.4672799790001, 22.028026240000109],
              [103.46597707500004, 22.026259180000096],
              [103.46342019500004, 22.024515231000038],
              [103.45824719200004, 22.020977339000055],
              [103.45463886400009, 22.01796160100011],
              [103.45233820600006, 22.015217684],
              [103.45149087600004, 22.013766298000093],
              [103.45135091100009, 22.012500570000078],
              [103.45220222900011, 22.00982100800006],
              [103.45466098100006, 22.005708963000089],
              [103.4574578460001, 22.00205041500007],
              [103.45852152500001, 22.000883288000047],
              [103.45945526300008, 22.000000227000079],
              [103.46306209800008, 21.997737466000089],
              [103.46411127200011, 21.996683409000092],
              [103.4649268260001, 21.994668627000102],
              [103.46548357700006, 21.992768058000053],
              [103.466329871, 21.989439430000068],
              [103.46831964300006, 21.986492560000045],
              [103.47109407100002, 21.982844646000018],
              [103.47449435500008, 21.979806878000119],
              [103.47657004500005, 21.978654099000039],
              [103.47991160600004, 21.97812451],
              [103.48299161500006, 21.977828555000123],
              [103.48607996000007, 21.977174232000074],
              [103.48814720200005, 21.976379665000053],
              [103.49022555700006, 21.975107307000115],
              [103.49284083100008, 21.972770313000112],
              [103.49495658100008, 21.971102827000074],
              [103.4975287300001, 21.969399730000113],
              [103.50000007600003, 21.968094427000025],
              [103.50087473800002, 21.967166713],
              [103.50173260400003, 21.965502199000042],
              [103.50283231000007, 21.960478833000074],
              [103.50376364500003, 21.958000316000081],
              [103.50388311000006, 21.956076890000098],
              [103.49822569200003, 21.952765027000076],
              [103.49494394100002, 21.949779158000027],
              [103.48507733500006, 21.941694931000107],
              [103.48219922400006, 21.938325435000117],
              [103.48138986600006, 21.934997897000038],
              [103.48121018600001, 21.930027565000017],
              [103.48043306200002, 21.925320980000059],
              [103.47992573400006, 21.921723562000039],
              [103.47854480400011, 21.917556488000045],
              [103.47772915600004, 21.914504619000041],
              [103.47836521300007, 21.912585985000099],
              [103.48081277100009, 21.90904852700006],
              [103.484410005, 21.906914067000024],
              [103.48532215900008, 21.905828821000028],
              [103.48538001700007, 21.903346521000074],
              [103.48399910000002, 21.899179332000074],
              [103.48465433300007, 21.896433181000049],
              [103.486863951, 21.890407184000082],
              [103.48970926800007, 21.882462326000059],
              [103.49250302300003, 21.876723913000085],
              [103.49549583700012, 21.875128822000086],
              [103.49894552100008, 21.874803261000061],
              [103.50151672800011, 21.874395213000049],
              [103.50535966000011, 21.874380896000019],
              [103.50726280900001, 21.873039124000023],
              [103.50964299300004, 21.868118331000019],
              [103.51044574200003, 21.867490420000109],
              [103.51690217700008, 21.865228244000079],
              [103.52019871800007, 21.863270305000079],
              [103.52136198300005, 21.856095291000074],
              [103.52146266500003, 21.855474238000028],
              [103.52443945900004, 21.850289087000085],
              [103.52735731400006, 21.845516758000102],
              [103.52793455800003, 21.841847533000092],
              [103.52599631200007, 21.83619522100004],
              [103.53120121900005, 21.832619014000088],
              [103.53519659700004, 21.83129595200004],
              [103.53855853800003, 21.828602755000048],
              [103.53997452300004, 21.824858195000019],
              [103.54003908300005, 21.822052757000066],
              [103.53882373800005, 21.819221612000028],
              [103.5353818680001, 21.815770582000127],
              [103.5353464130001, 21.815171704000043],
              [103.53570582900008, 21.814534739000102],
              [103.53807555800005, 21.812143636000059],
              [103.53977460500002, 21.806794177000107],
              [103.54530363, 21.800854243000067],
              [103.54564335600004, 21.798928456000063],
              [103.54426454700008, 21.796784163000012],
              [103.54126323600009, 21.787037849000065],
              [103.53974457500003, 21.783487195000092],
              [103.53912801700008, 21.778182996000069],
              [103.54185577200009, 21.774119284000015],
              [103.5512989760001, 21.768142309000012],
              [103.55524823500009, 21.764470954],
              [103.55850039500007, 21.757909475000048],
              [103.55930012300007, 21.752012148000063],
              [103.5596667070001, 21.751053090000113],
              [103.56159985400009, 21.748330609],
              [103.56620609600002, 21.743889739000011],
              [103.5705491950001, 21.741261242000043],
              [103.57019039200007, 21.737572547000106],
              [103.57216834100004, 21.733953419000059],
              [103.57343222700007, 21.730343039000033],
              [103.57486361400002, 21.725861566000049],
              [103.57617443800004, 21.72342557400011],
              [103.58126038500004, 21.71409249200007],
              [103.58440613800008, 21.708885575000103],
              [103.58305687100008, 21.705453114000051],
              [103.58414763700009, 21.702943576000067],
              [103.58995070100009, 21.693417106000098],
              [103.59390806700006, 21.68712141000011],
              [103.59537751700005, 21.685263586000048],
              [103.59604417800004, 21.684080186000095],
              [103.59613833400002, 21.683184586000067],
              [103.59596975200002, 21.68193858900009],
              [103.59527677900006, 21.679945808000056],
              [103.59457546300003, 21.678321048],
              [103.59338328300007, 21.676640504000133],
              [103.59172856000002, 21.675825248000088],
              [103.59008538800003, 21.676667094000024],
              [103.58907122600006, 21.677981656000078],
              [103.58586958800001, 21.68242843000003],
              [103.58149448500004, 21.686529607000082],
              [103.57128241300009, 21.682207044],
              [103.56640439100008, 21.678151609000018],
              [103.56458490600008, 21.67270736000004],
              [103.55945078900001, 21.669106832000026],
              [103.55852793800003, 21.667500498000081],
              [103.5551001540001, 21.656017444000049],
              [103.55214008200005, 21.653127525000038],
              [103.54562382800002, 21.651892243000056],
              [103.54068310800005, 21.648479117000093],
              [103.54034647400009, 21.64706852000009],
              [103.54039511000012, 21.644952236000044],
              [103.54056380900005, 21.644035076000101],
              [103.54202710200005, 21.642453489000069],
              [103.54643599700007, 21.641161145000076],
              [103.54788338800002, 21.64026962100003],
              [103.54879821100003, 21.63899917400007],
              [103.54883704800005, 21.638378577000061],
              [103.54868253800007, 21.636534349000058],
              [103.54883260000004, 21.634281971000036],
              [103.55674812500003, 21.63025194],
              [103.55746418100003, 21.627987848000082],
              [103.55709318800007, 21.624850446000018],
              [103.556258396, 21.623147734],
              [103.55333289000006, 21.617180276000077],
              [103.55214902199999, 21.614095562000024],
              [103.54718741600007, 21.609485113000027],
              [103.54685375100009, 21.609018106000022],
              [103.546919196, 21.606165517000072],
              [103.546552207, 21.605007385000086],
              [103.54607948300006, 21.604169343000109],
              [103.54471849000008, 21.603451564000054],
              [103.54432721800003, 21.603351650000043],
              [103.54324542500005, 21.603329930000115],
              [103.54157039200001, 21.603434375000049],
              [103.54068529600005, 21.603416594000088],
              [103.53786502200003, 21.601978983000066],
              [103.53700108900004, 21.601040990000087],
              [103.53660931900005, 21.598823600000024],
              [103.536052629, 21.595221935],
              [103.53482393700003, 21.593033719000054],
              [103.53154999000003, 21.591011463000065],
              [103.52862167300005, 21.587868320000055],
              [103.52719857200006, 21.585584049000062],
              [103.52737575300007, 21.584298697000023],
              [103.52944036500001, 21.582222789000035],
              [103.53019618800002, 21.580373676000072],
              [103.52916666200011, 21.573816203],
              [103.52920692500007, 21.572067736000022],
              [103.52883640000005, 21.565730593000062],
              [103.52494087100004, 21.566158434000023],
              [103.52141422100001, 21.567652448],
              [103.51795922400005, 21.565294942000079],
              [103.51784265300009, 21.56506419599999],
              [103.51758951900011, 21.562879716000083],
              [103.51729086100009, 21.55557715200009],
              [103.51882481600009, 21.550912522000054],
              [103.51989366000006, 21.545087555000109],
              [103.51962313300007, 21.544023261000063],
              [103.51557972600008, 21.538094940000093],
              [103.51520560400004, 21.537258716000096],
              [103.51518195100003, 21.536153352000028],
              [103.51520745600001, 21.535048980000049],
              [103.51482803700006, 21.534442828000074],
              [103.51399559300002, 21.534287893000048],
              [103.51085075600005, 21.534178238000031],
              [103.50759909400003, 21.534434671000021],
              [103.50404077500005, 21.535191252000097],
              [103.50127258000006, 21.534743822000024],
              [103.49692661900011, 21.533412643000105],
              [103.49555489200003, 21.53297810000003],
              [103.48898910100007, 21.529737573000048],
              [103.48402752600006, 21.523422322000037],
              [103.47973760800004, 21.521183831000101],
              [103.47081921000002, 21.520284869000058],
              [103.46425426100004, 21.517043526000101],
              [103.45772864100003, 21.512129650000041],
              [103.44977806600008, 21.502644889000067],
              [103.44842002400006, 21.495207271000091],
              [103.44853757800011, 21.490190115000104],
              [103.45022574299999, 21.483531934000055],
              [103.45114251100009, 21.477096869000079],
              [103.45155435500006, 21.470412329000062],
              [103.45403584000012, 21.462574958000054],
              [103.45314358500009, 21.457058718000077],
              [103.44869542500003, 21.450752324000085],
              [103.44629464900007, 21.444248773000041],
              [103.44636183800003, 21.441381573000037],
              [103.44770151500003, 21.437873917000069],
              [103.44421335800008, 21.435067717000052],
              [103.44116745400004, 21.43293362000005],
              [103.44000481400012, 21.432219212000092],
              [103.43839363900004, 21.431771765000107],
              [103.43479158300008, 21.431352359000044],
              [103.43154980800009, 21.431285577000025],
              [103.42861248900005, 21.430810725000079],
              [103.424375008, 21.429204198000029],
              [103.42204633300005, 21.428546136000115],
              [103.41910290200006, 21.42771431200012],
              [103.41674855100004, 21.427527543000032],
              [103.41498035000011, 21.42749097800003],
              [103.41231175700003, 21.4281262800001],
              [103.41069416000002, 21.427954694000029],
              [103.40981657800006, 21.427660324],
              [103.409636409, 21.427424602000045],
              [103.40927966500006, 21.428546866000083],
              [103.40821884900004, 21.429767796000121],
              [103.40553716000002, 21.430955141000041],
              [103.40153250900005, 21.431976909000028],
              [103.39901116300007, 21.432615093000074],
              [103.39558279300009, 21.434201107000028],
              [103.39109013100006, 21.437145938000057],
              [103.38953997700006, 21.437112162000105],
              [103.37737283300001, 21.434271958000025],
              [103.36681031100002, 21.430355945000109],
              [103.35467042000001, 21.426406343000082],
              [103.34049681800012, 21.425000209000089],
              [103.33505437300002, 21.421559719000079],
              [103.33462355200008, 21.411464522000045],
              [103.33454923900007, 21.409723053000015],
              [103.33709296300003, 21.402385195000036],
              [103.34391279700004, 21.397724109000116],
              [103.35552664700006, 21.390576256000045],
              [103.36012929700004, 21.37958519000005],
              [103.36499367400009, 21.37414316899999],
              [103.3749050050001, 21.372132726000089],
              [103.38124078700011, 21.37115609900011],
              [103.38526309000012, 21.367913578000049],
              [103.38539460900009, 21.362372398000048],
              [103.37934006700006, 21.351527820000094],
              [103.36898435700004, 21.339114544000012],
              [103.36790714200001, 21.334656665000026],
              [103.37075493200008, 21.331020030000047],
              [103.37671270300009, 21.329296359000068],
              [103.3847292840001, 21.323919226000051],
              [103.39631102000003, 21.317876641000041],
              [103.40924218100005, 21.321471619000043],
              [103.41279034100009, 21.321545123000085],
              [103.41584096700008, 21.320086303],
              [103.41797637600011, 21.319065096000053],
              [103.42599140500008, 21.313686374000085],
              [103.42841344700001, 21.308468849000036],
              [103.43346910700008, 21.297576888000066],
              [103.43726815000008, 21.286935643000056],
              [103.438255758, 21.278454128000043],
              [103.43499448299998, 21.266188390000096],
              [103.4293256, 21.255720968000077],
              [103.42949918200006, 21.248331234000069],
              [103.43242261400009, 21.24136783000008],
              [103.43110654, 21.230250399000013],
              [103.43028878300001, 21.214706770000099],
              [103.43164432, 21.207340913000024],
              [103.43503038800003, 21.197428954000053],
              [103.43487905100002, 21.187074135000024],
              [103.432827359, 21.173722199000089],
              [103.43061115600004, 21.167391257000112],
              [103.42248638400005, 21.160938060000056],
              [103.41693679100007, 21.162302077000028],
              [103.41371581300011, 21.165193126000048],
              [103.40742176400008, 21.164692860000045],
              [103.401293599, 21.157171093000038],
              [103.39551609800007, 21.151505004000093],
              [103.399699512, 21.141239479000085],
              [103.40365435700002, 21.123943793000066],
              [103.4039247310001, 21.112487118000089],
              [103.41018905600006, 21.097457067000036],
              [103.42009179500008, 21.078434339000026],
              [103.42224291800008, 21.070713545000075],
              [103.42182701000003, 21.069993925000041],
              [103.42072880500002, 21.068093735000026],
              [103.41566253800006, 21.065770065000052],
              [103.40189973600006, 21.064744741000041],
              [103.3881633340001, 21.06260975200005],
              [103.37910790900003, 21.062420704000125],
              [103.37348182800011, 21.067110272],
              [103.36271936300002, 21.072431583000117],
              [103.35317280700008, 21.07629877400008],
              [103.34389594300008, 21.085348072000087],
              [103.32836212400008, 21.092415940000109],
              [103.31210186300002, 21.09687888600007],
              [103.29748740900007, 21.098417485000098],
              [103.28922690900005, 21.097871931000078],
              [103.28615715200007, 21.094478602000066],
              [103.28993348299998, 21.084944941000039],
              [103.29651912700007, 21.073252151000062],
              [103.30178917300003, 21.067077836000081],
              [103.30187830000003, 21.063381847000073],
              [103.30073282100003, 21.061878340000106],
              [103.29481826000003, 21.062122357000042],
              [103.28691725500009, 21.06306347000011],
              [103.28093997500007, 21.065894301000029],
              [103.27465063900007, 21.064803363000095],
              [103.27381505500003, 21.062704178000047],
              [103.272869095, 21.057011818000056],
              [103.27264065700003, 21.053661802000079],
              [103.27287302300007, 21.050467060000067],
              [103.27423111500008, 21.045551059000012],
              [103.27502965400006, 21.044550019000049],
              [103.27692567400001, 21.042990658000079],
              [103.27883575300004, 21.040849796000046],
              [103.27980310100006, 21.039270570000035],
              [103.28019725600006, 21.038369716000105],
              [103.28144230400005, 21.035523999000105],
              [103.28342615800007, 21.030330280000072],
              [103.28504410300009, 21.02745585800001],
              [103.28583899600005, 21.02660012900008],
              [103.28709815800005, 21.025754295000084],
              [103.28898335600006, 21.024630891000037],
              [103.29008772800005, 21.023781739000036],
              [103.29041832400004, 21.022916097000085],
              [103.29045345600011, 21.021462357000061],
              [103.29036545300002, 21.01869696400005],
              [103.28977455300007, 21.017520774000076],
              [103.28896032300003, 21.015976206000083],
              [103.28538349600011, 21.010227376000046],
              [103.28436007200007, 21.007732867000058],
              [103.28338593700002, 21.003203062000075],
              [103.2837516960001, 21.000883613],
              [103.28475046800004, 20.997995845000084],
              [103.28585822300003, 20.99700129700004],
              [103.28726658200003, 20.996422294000091],
              [103.287974553, 20.996131231000078],
              [103.29358811800003, 20.982585990000047],
              [103.29508101900009, 20.969673280000031],
              [103.29533971900001, 20.958953134000069],
              [103.29593836200007, 20.950459195000093],
              [103.29324644300002, 20.947812848000069],
              [103.287728816, 20.948065103000069],
              [103.28182663600006, 20.947939147000035],
              [103.27567429400003, 20.941889879000094],
              [103.27269685100006, 20.9347987250001],
              [103.27281335400002, 20.929992906000017],
              [103.27570179700008, 20.924506593000025],
              [103.28464332600007, 20.912861534000093],
              [103.28593970900003, 20.908080718000029],
              [103.28091499600004, 20.904274579000059],
              [103.27547021700011, 20.901568968000014],
              [103.26757563200007, 20.902509629000043],
              [103.25896607100003, 20.900475553000049],
              [103.24963297400008, 20.895836329000069],
              [103.24688838300001, 20.895407388000109],
              [103.23942336900008, 20.894876720000077],
              [103.23312945800006, 20.894740974000037],
              [103.2248234680001, 20.896411028000017],
              [103.21422058200005, 20.895441761000107],
              [103.21365224700003, 20.898412305000043],
              [103.21009085200005, 20.901854029000063],
              [103.20605027100004, 20.903023126000072],
              [103.19447524500004, 20.906039161000045],
              [103.19104964700003, 20.903953804000075],
              [103.18759313000002, 20.903124470000108],
              [103.18381350000001, 20.904550197000034],
              [103.17779587600003, 20.909948606000036],
              [103.17019260300003, 20.914558107000047],
              [103.16562307600005, 20.915463548000091],
              [103.16434609900008, 20.914302966000072],
              [103.16222254800006, 20.912372920000074],
              [103.15754164800009, 20.906992014000103],
              [103.15142453300004, 20.905601069000035],
              [103.14633924000002, 20.905740755000046],
              [103.13934516400006, 20.907346418000117],
              [103.13181006100011, 20.90919128600008],
              [103.12591642200007, 20.909564019000101],
              [103.12352308900003, 20.909008494000055],
              [103.12170839300006, 20.906706263000082],
              [103.11918786200006, 20.901114428000064],
              [103.118160872, 20.8988359290001],
              [103.11579913800007, 20.897024227000124],
              [103.11419530900007, 20.896988756000034],
              [103.11039007000008, 20.899418105000045],
              [103.10817600200002, 20.902385334000058],
              [103.10905625400002, 20.909945399000037],
              [103.10891135100003, 20.91572324100008],
              [103.104520569, 20.920150237000087],
              [103.10285977800008, 20.92237554400009],
              [103.10385345400005, 20.925413713000026],
              [103.10401357900007, 20.929690106000095],
              [103.10200963400008, 20.934923867000066],
              [103.09901189100003, 20.937119460000098],
              [103.09635093000003, 20.936557734000068],
              [103.09237217200008, 20.935212675000066],
              [103.087483816, 20.938120129000112],
              [103.08445419700007, 20.941571494000044],
              [103.08188318400005, 20.948049050000073],
              [103.08271194000005, 20.957618189000101],
              [103.08279561200006, 20.964908647000065],
              [103.0813255410001, 20.970153819000089],
              [103.07746772300003, 20.97459178700003],
              [103.07554508500002, 20.976559551000037],
              [103.07003167800009, 20.982971040000017],
              [103.06750413200004, 20.987689743000089],
              [103.06738972199999, 20.992210935000074],
              [103.06463270400005, 20.995416517000095],
              [103.05943041800002, 21.000075296000098],
              [103.05581347200007, 21.005523339000028],
              [103.05567329300001, 21.011049066000083],
              [103.05310678300003, 21.017274382000025],
              [103.04859757100006, 21.026220461000023],
              [103.04059776700011, 21.035590659000022],
              [103.03829795100006, 21.041821551000069],
              [103.03313082500001, 21.055526806000024],
              [103.03063987900006, 21.058737637000036],
              [103.02713642900008, 21.059664037000033],
              [103.02312354800006, 21.059573751000116],
              [103.01879178900003, 21.061486538000018],
              [103.01389916900004, 21.064391750000055],
              [103.009605811, 21.064797476000052],
              [103.00456135200005, 21.063175831000081],
              [103.00220513400006, 21.061112250000043],
              [102.99980383800006, 21.060806670000076],
              [102.99763139300008, 21.062013990000047],
              [102.99249673500005, 21.063908096000091],
              [102.98578910200008, 21.064509964000067],
              [102.97981930100005, 21.067641290000068],
              [102.97499721200003, 21.067783051000077],
              [102.97178679700006, 21.067710072000075],
              [102.96934655700004, 21.068911016000058],
              [102.96605176300001, 21.072102787000091],
              [102.96190876800006, 21.077034175000087],
              [102.9588682680001, 21.080734163000066],
              [102.95875778200011, 21.085003466000082],
              [102.96375048200011, 21.088635174000011],
              [102.96608067500009, 21.091703589000055],
              [102.96591841700008, 21.097981855000022],
              [102.96253864200003, 21.104438072000065],
              [102.95801675200008, 21.11363213300006],
              [102.95251512500006, 21.119285860000048],
              [102.94490485700001, 21.123634903],
              [102.93976806200008, 21.125527598000104],
              [102.93756188200007, 21.127989777000074],
              [102.93750965700009, 21.12999870000003],
              [102.93906621700009, 21.131867891000056],
              [102.94348231700009, 21.137170653000048],
              [102.94608040200004, 21.140245145000058],
              [102.94546691900007, 21.143246239000035],
              [102.94051895300004, 21.148158300000119],
              [102.93602113400003, 21.156346809000048],
              [102.93380149000005, 21.159311027000079],
              [102.93079839800001, 21.161503510000031],
              [102.92541243900006, 21.162636307000056],
              [102.92279474600009, 21.160314995000107],
              [102.92125428700008, 21.157767117000056],
              [102.91940035000007, 21.15697079500007],
              [102.91774853500002, 21.158691630000078],
              [102.915528577, 21.1616556540001],
              [102.91200960400008, 21.1630822710001],
              [102.90662349200002, 21.164214607000083],
              [102.90335235300009, 21.166400538000026],
              [102.90163455800007, 21.170632206000036],
              [102.90255404000003, 21.176432065000128],
              [102.90648385600011, 21.179788736000127],
              [102.90694040400004, 21.182814164000071],
              [102.90252585100009, 21.187737356000099],
              [102.90265547000007, 21.193016382000081],
              [102.90674164800004, 21.200647557000082],
              [102.90821657200003, 21.205706172000035],
              [102.90720973600004, 21.213471182000099],
              [102.90775016400008, 21.223532708000015],
              [102.90844829800002, 21.227568355000059],
              [102.9086372240001, 21.230587373],
              [102.90564590500006, 21.232277107000108],
              [102.90079993700002, 21.233170421000032],
              [102.90007052000007, 21.234037159000074],
              [102.89748148899999, 21.237113494000056],
              [102.89356807300001, 21.243303719000025],
              [102.8907716360001, 21.247761109000059],
              [102.8850888690001, 21.249890826000062],
              [102.87969359200009, 21.251273401000056],
              [102.86996734000003, 21.25431402600011],
              [102.86264416100002, 21.257121893000082],
              [102.85783063500001, 21.257298256000112],
              [102.85447917300009, 21.256735463],
              [102.85075668700003, 21.255644070000109],
              [102.84851777700004, 21.254820847000033],
              [102.845089751, 21.252731349000072],
              [102.84117282500002, 21.248871887000043],
              [102.83824724200007, 21.248050048000074],
              [102.83060500200004, 21.247840066000023],
              [102.82906649900011, 21.247888219000096],
              [102.82753651800009, 21.247939331000062],
              [102.82356239600007, 21.248060393000046],
              [102.82137057500009, 21.247907148000067],
              [102.82001472700007, 21.24759231400013],
              [102.81898151700003, 21.247226637000068],
              [102.819225922, 21.250711307000088],
              [102.81824391400002, 21.252386020000102],
              [102.81688006500006, 21.25389447600012],
              [102.81335742100001, 21.257014549000083],
              [102.81231909400007, 21.257677928000085],
              [102.81063414000002, 21.258754411000069],
              [102.80801798900009, 21.261003634000097],
              [102.80650185000005, 21.262882944000069],
              [102.80573360400008, 21.264501455000051],
              [102.80562473200004, 21.265625685000074],
              [102.80635052900006, 21.26704039800007],
              [102.80773503400003, 21.268185342000031],
              [102.81192475700004, 21.270546353000071],
              [102.82118444800005, 21.275033621000091],
              [102.8319914680001, 21.281817405000055],
              [102.83672567800002, 21.285193455000062],
              [102.83848725100003, 21.289504910000097],
              [102.84131736600007, 21.293023301000069],
              [102.84487537200003, 21.291160893000068],
              [102.84725266700009, 21.292472205000124],
              [102.849255783, 21.297793911000085],
              [102.85293921000005, 21.30039148500007],
              [102.85989669500007, 21.300804260000028],
              [102.86656648300001, 21.301963733000036],
              [102.87271387900009, 21.302608516000099],
              [102.87753526000004, 21.302720080000093],
              [102.88131825600006, 21.30155157500004],
              [102.88743929300007, 21.30320011400002],
              [102.89328597800004, 21.30509333300008],
              [102.89592503900008, 21.306661303000048],
              [102.89742024400007, 21.310965955000057],
              [102.89726876100006, 21.316739746000032],
              [102.89496762400009, 21.32271512900008],
              [102.88942525800007, 21.329620403000057],
              [102.8879934230001, 21.333103867000069],
              [102.88841704100005, 21.337383650000064],
              [102.89141425100007, 21.345741531000058],
              [102.89228808800007, 21.353296804000088],
              [102.89403830600003, 21.358109361000018],
              [102.89501117400005, 21.361899266000073],
              [102.89565682000008, 21.367942067000101],
              [102.89536683400007, 21.378986429000065],
              [102.89442452200009, 21.384239002000079],
              [102.89698558900008, 21.388818763000117],
              [102.90304369200004, 21.392976609000058],
              [102.90718668900008, 21.39859714400005],
              [102.91372875400003, 21.404774909000054],
              [102.91598972700005, 21.410603096],
              [102.917675424, 21.417924844000069],
              [102.92042023000009, 21.425773035000084],
              [102.92445298000007, 21.435659622000088],
              [102.92719175800002, 21.443758457000058],
              [102.93217344500009, 21.448141539000076],
              [102.93899911100004, 21.453822185000071],
              [102.94079714500005, 21.456876683000111],
              [102.93857414300007, 21.459839394000113],
              [102.93508919500002, 21.459759813000097],
              [102.92921779200005, 21.458621129000029],
              [102.92069201500001, 21.456417005],
              [102.91546858500001, 21.45102368800007],
              [102.91160542700003, 21.44490803200005],
              [102.91148853200004, 21.439129379000029],
              [102.90806315600004, 21.436790552000048],
              [102.90357888600009, 21.433925054000014],
              [102.90065024400008, 21.43310430700005],
              [102.89791058600005, 21.435301463000123],
              [102.89574650900005, 21.436005042000069],
              [102.89308590500009, 21.435190369000075],
              [102.88917756100001, 21.430831028000071],
              [102.88497494400009, 21.427469336000016],
              [102.87829412600004, 21.426561666000055],
              [102.87108393900004, 21.425390407000044],
              [102.86875745400002, 21.428408953000044],
              [102.869373239, 21.431085990000028],
              [102.87307692200002, 21.43567084500004],
              [102.8757830010001, 21.437529003000044],
              [102.8762564200001, 21.438337559000061],
              [102.87646355700009, 21.439436510000043],
              [102.87539553000002, 21.443268451000016],
              [102.87548623600006, 21.444719560000088],
              [102.87624082400005, 21.446266503000096],
              [102.87752058100007, 21.44754617100002],
              [102.88031982200005, 21.449168066000098],
              [102.88512548700002, 21.452549362000092],
              [102.88734883000005, 21.45465336800001],
              [102.88912210500004, 21.456706714000084],
              [102.89217282600004, 21.461687389000069],
              [102.88787955900008, 21.46997672300008],
              [102.88651842700008, 21.473953562000055],
              [102.88500047900007, 21.477443785],
              [102.88658263100007, 21.478484728000069],
              [102.89571113600006, 21.478192813000071],
              [102.90081157, 21.478059045000109],
              [102.90452543200007, 21.479651090000026],
              [102.90839563600011, 21.48551552900004],
              [102.91169040300005, 21.492873278000069],
              [102.9120162900001, 21.500916081000106],
              [102.91377698900003, 21.504909769000058],
              [102.91937891800008, 21.51027031300012],
              [102.93016762600004, 21.516668421000062],
              [102.93299476600001, 21.51835919800007],
              [102.93542594000007, 21.520747926000091],
              [102.93848710400005, 21.525059949000088],
              [102.94037674, 21.527793546000048],
              [102.94287831300002, 21.530533455000089],
              [102.94616819700011, 21.534779807000071],
              [102.95494022700008, 21.542322354000106],
              [102.95884577600002, 21.54693059],
              [102.95876759900003, 21.549941763000021],
              [102.95626256800007, 21.553399916000068],
              [102.95885339200002, 21.556973871000103],
              [102.96410040200003, 21.561612358000026],
              [102.96665243900006, 21.566691699],
              [102.96848446900003, 21.568490740000087],
              [102.975629123, 21.572418662000103],
              [102.98028209000006, 21.579302758000019],
              [102.98359215100002, 21.583697511000111],
              [102.98533277900007, 21.584719011000075],
              [102.9860404450001, 21.584891799000069],
              [102.98642567600008, 21.584985858000039],
              [102.98758479000006, 21.585268862000092],
              [102.989034217, 21.585925299000053],
              [102.98968749900004, 21.586439013000103],
              [102.99018502600009, 21.587822390000078],
              [102.99040323200003, 21.589698426000048],
              [102.99078361800005, 21.590455452000064],
              [102.99090736600009, 21.590832617000011],
              [102.99082867800006, 21.591621669000105],
              [102.99054739000005, 21.594442127000057],
              [102.99000289000003, 21.600043072000027],
              [102.98938626400007, 21.603272326000052],
              [102.98882111500004, 21.604506933000067],
              [102.98731359900009, 21.606094473000056],
              [102.9862283250001, 21.606818376],
              [102.98419423600011, 21.608144521000035],
              [102.98336576600003, 21.609248418000071],
              [102.98280700700008, 21.610233671000053],
              [102.98262220100005, 21.612225263000084],
              [102.98355285100006, 21.617485154000079],
              [102.98376779600008, 21.619485745000055],
              [102.98369691000008, 21.62222827500004],
              [102.98336273700005, 21.624840108000051],
              [102.98238537800009, 21.629121275000074],
              [102.98134940500009, 21.633089241000022],
              [102.98075510000004, 21.635445679000043],
              [102.98072608200003, 21.636567587000052],
              [102.98110975500006, 21.637199908000056],
              [102.98163958800004, 21.637336624000092],
              [102.98324521400009, 21.637123447000036],
              [102.98430810500011, 21.637272193000086],
              [102.98483149700007, 21.637658214000055],
              [102.98519907900003, 21.638913805000115],
              [102.98512820600011, 21.641656237000092],
              [102.98479724100008, 21.644143324000083],
              [102.98449847900005, 21.645383847000055],
              [102.98353980700004, 21.646360004000087],
              [102.98123873700008, 21.647680002000058],
              [102.97988344400002, 21.64852244700004],
              [102.97878177600009, 21.649869532000118],
              [102.97821642400004, 21.651104006000118],
              [102.97785581200004, 21.652156032000036],
              [102.97792779300005, 21.654527452000046],
              [102.97805662400005, 21.655045248000079],
              [102.98007051200005, 21.660134698],
              [102.9848190010001, 21.673797284000074],
              [102.98757662200009, 21.681390102000094],
              [102.98827807100005, 21.685422195000022],
              [102.98444931000007, 21.688096911000088],
              [102.98067238400003, 21.688764619],
              [102.97819192900005, 21.691218672000012],
              [102.97751873300004, 21.696474682000044],
              [102.98379818200002, 21.702891806000046],
              [102.98766874200008, 21.709003333000076],
              [102.98751314900008, 21.715023877000036],
              [102.98648090400003, 21.723785541000048],
              [102.98011202000002, 21.731171571000019],
              [102.97241985400004, 21.737774403000017],
              [102.97154943800005, 21.740264603000092],
              [102.97249971200007, 21.745054888000048],
              [102.97028690500004, 21.74751467700009],
              [102.96967746700007, 21.747538802000044],
              [102.96625332500008, 21.747674310000065],
              [102.96128924900005, 21.742291033000072],
              [102.95456373300001, 21.732349729000063],
              [102.95061535600009, 21.72924808100003],
              [102.94713863600001, 21.728667035000093],
              [102.93807045600005, 21.726201611000114],
              [102.93351351100003, 21.725846725000061],
              [102.92357466000004, 21.725870811000014],
              [102.91638596500007, 21.723447416000049],
              [102.89988146300006, 21.71779805400007],
              [102.8868277010001, 21.713732888000067],
              [102.88339115700006, 21.711645709000038],
              [102.88159166500003, 21.708592172000074],
              [102.87951719800007, 21.705783271000101],
              [102.87764494800005, 21.705489090000093],
              [102.87496084000001, 21.705427170000036],
              [102.87056013700006, 21.709341706000096],
              [102.86640109700002, 21.714265746000102],
              [102.86392551400009, 21.71646755500003],
              [102.86120802600004, 21.717659720000078],
              [102.8551695570001, 21.722539985000111],
              [102.85506308, 21.7265535330001],
              [102.85626555700001, 21.731852341000035],
              [102.85426662500001, 21.736323972],
              [102.85224761400011, 21.741548062000057],
              [102.854961073, 21.750646575000054],
              [102.85801644500002, 21.756991970000072],
              [102.85836715800008, 21.764027616000092],
              [102.85773704200008, 21.767526764000067],
              [102.85474364700003, 21.768963347000089],
              [102.85412676400006, 21.771960814000032],
              [102.85613502000005, 21.777277821000048],
              [102.85627042600007, 21.782300438000043],
              [102.85656789100007, 21.791342270000058],
              [102.85661679900004, 21.799625310000081],
              [102.85365835800003, 21.809846279000041],
              [102.85380705100008, 21.814366980000074],
              [102.85552719900002, 21.82042974700007],
              [102.85352733600003, 21.824900629000062],
              [102.85208452700002, 21.828631497000025],
              [102.84964926800005, 21.839365869000105],
              [102.84688329300008, 21.842313114000063],
              [102.83533355200004, 21.842045097],
              [102.82295030900005, 21.839658722000088],
              [102.81920297400006, 21.837598995000107],
              [102.81717622300005, 21.83439584900011],
              [102.81394621300005, 21.828797648000098],
              [102.80901706700007, 21.823554160000036],
              [102.80829942500002, 21.818803414000065],
              [102.81227846800009, 21.812189755000055],
              [102.81717533300007, 21.80283578600006],
              [102.81774505500005, 21.797325870000073],
              [102.81528607000007, 21.794506891000061],
              [102.81410407700005, 21.791323148000075],
              [102.8142517470001, 21.785803269000056],
              [102.81781835000007, 21.77878496900005],
              [102.81896812900007, 21.767370278000087],
              [102.81921046800005, 21.758301452000062],
              [102.82164717500005, 21.746127207000043],
              [102.82268073100003, 21.739049266000073],
              [102.82278600800004, 21.735106101000042],
              [102.81987415100008, 21.733459963000087],
              [102.81608672700006, 21.732977048000102],
              [102.81149754600004, 21.730897085000045],
              [102.80909227900007, 21.726106066000028],
              [102.80890881199998, 21.722639764000107],
              [102.80825838800008, 21.719921294000066],
              [102.80684006100006, 21.718874412000041],
              [102.801826229, 21.717067583000116],
              [102.79938726800007, 21.713631375000105],
              [102.79664139900008, 21.708160433000053],
              [102.79342558100009, 21.706733404000111],
              [102.79088713300003, 21.70701179900005],
              [102.78543070800002, 21.708235427000133],
              [102.78082413300008, 21.704748046000063],
              [102.77136849400003, 21.693374171000102],
              [102.76752157600005, 21.688552570000041],
              [102.76446124000006, 21.681383855000021],
              [102.76131906300004, 21.677254519000044],
              [102.75812225800004, 21.675151449000026],
              [102.75343476000006, 21.674702805000031],
              [102.75133104800008, 21.672287509000029],
              [102.74928223200003, 21.66784582100005],
              [102.74469555300004, 21.663681968000056],
              [102.74076749300011, 21.661899215000076],
              [102.73465367000006, 21.660740467000039],
              [102.73062486600006, 21.662672578000027],
              [102.72658680000008, 21.664942347000071],
              [102.70924639100008, 21.664529827000045],
              [102.69916809300003, 21.662937644000024],
              [102.69554631400003, 21.663189096000046],
              [102.69263780400007, 21.663795488000034],
              [102.69047027000005, 21.663743666000101],
              [102.68657970400002, 21.660609047],
              [102.68198529400011, 21.656781575000039],
              [102.67803015900006, 21.656010896000041],
              [102.67473264299998, 21.657621596000084],
              [102.67088830000006, 21.665978122000062],
              [102.66283733100005, 21.682681911000046],
              [102.66259574000004, 21.691462426000015],
              [102.65856421700008, 21.706572243000011],
              [102.64975982900008, 21.724269974000073],
              [102.64600977200004, 21.734655914000101],
              [102.64855294400004, 21.746719245000087],
              [102.65100918400006, 21.76037868700007],
              [102.65242190700008, 21.771977549],
              [102.65925103900005, 21.786670496000049],
              [102.65900915600011, 21.795449278000056],
              [102.65226928700005, 21.803733804000025],
              [102.64634516400008, 21.808659079000044],
              [102.64517663400002, 21.811671645000082],
              [102.64812273100007, 21.822891749000028],
              [102.64490379200001, 21.834638820000052],
              [102.64069306700004, 21.842983322000094],
              [102.64057167300004, 21.84737224900006],
              [102.64258255600006, 21.853163861],
              [102.64358338000009, 21.856228433000076],
              [102.64312842800011, 21.85959573700007],
              [102.63947488000005, 21.860858982000053],
              [102.63584932500001, 21.861109367000076],
              [102.62481338700003, 21.867599305000063],
              [102.61308157700003, 21.873058332000106],
              [102.60935242800012, 21.877021760000034],
              [102.609573181, 21.882094319000075],
              [102.61473528500008, 21.891678048000074],
              [102.61615196000005, 21.901181358000073],
              [102.61569084800001, 21.90543599600003],
              [102.61176060600008, 21.91525868800008],
              [102.60901215800004, 21.91956442500009],
              [102.60456225600009, 21.923402261000021],
              [102.59644833400003, 21.927470941000045],
              [102.58837110100006, 21.928533324000021],
              [102.58102285800003, 21.932407818000051],
              [102.57076133000008, 21.936886521000076],
              [102.56232712400008, 21.940733854000079],
              [102.55379764200009, 21.947956231000099],
              [102.53832682300006, 21.957372281000076],
              [102.52692031700008, 21.96384726900007],
              [102.51596092800003, 21.967292796000045],
              [102.51046672600009, 21.969521632000074],
              [102.50526715700002, 21.974121911000033],
              [102.50479513300004, 21.974827048000023],
              [102.50148593100008, 21.979770334000115],
              [102.49627627700005, 21.984707814000082],
              [102.49103754900003, 21.990657659000057],
              [102.48693216300008, 21.994946674000104],
              [102.48580222400003, 22.000000172000078],
              [102.48557336, 22.003084919000045],
              [102.4857355720001, 22.005597715000036],
              [102.48614387400005, 22.007680293000043],
              [102.48669073100008, 22.009002750000036],
              [102.48924427200006, 22.013756253000061],
              [102.49037532600003, 22.015093156000098],
              [102.49130375600004, 22.015334278000061],
              [102.49342840300005, 22.014623334000035],
              [102.49577428700009, 22.014354126000057],
              [102.49986331200002, 22.014455207000069],
              [102.50619514500006, 22.01595507400009],
              [102.50770265800001, 22.016707010000047],
              [102.51055682800009, 22.018492767000097],
              [102.51735001400003, 22.023073741000054],
              [102.51844807600003, 22.023921057000081],
              [102.51891345500007, 22.024273649000037],
              [102.51923073100004, 22.024690816000088],
              [102.519363379, 22.025171662000083],
              [102.51935179300001, 22.025580732],
              [102.51766270100006, 22.029700902000023],
              [102.51696921000006, 22.030945991000102],
              [102.51597789600001, 22.032027901],
              [102.51249950700006, 22.034731591000067],
              [102.50907979, 22.03684119800009],
              [102.50749811800006, 22.037324542000114],
              [102.5047120190001, 22.036837899000083],
              [102.50048881200007, 22.035688872000044],
              [102.49522870100006, 22.035558863000034],
              [102.49354103300004, 22.035830544000042],
              [102.49250406700004, 22.036849620000076],
              [102.49074801700006, 22.039522465000069],
              [102.4899467430001, 22.040129462000024],
              [102.48903948400009, 22.040524898000058],
              [102.48712495400004, 22.04089538100007],
              [102.48440613300006, 22.041977231000054],
              [102.48303927400012, 22.042779138000057],
              [102.48177539000008, 22.043897004000087],
              [102.48036681200007, 22.046160458000053],
              [102.47849559900006, 22.048934773],
              [102.47533949700004, 22.053557635000054],
              [102.47310253900005, 22.057367480000067],
              [102.47083564800008, 22.062221220000119],
              [102.46991631900003, 22.063034132000055],
              [102.4677776600001, 22.063398871000039],
              [102.46484948700002, 22.063952906000047],
              [102.46100490000006, 22.065215377000058],
              [102.45862439600009, 22.066200818000056],
              [102.45792587100003, 22.067123641000116],
              [102.45777805000006, 22.068373570000063],
              [102.45717948300009, 22.069716737000086],
              [102.45592125800007, 22.070625606000057],
              [102.45148087500003, 22.073126663000053],
              [102.44989876300005, 22.073609563000041],
              [102.44608087000007, 22.073932229],
              [102.44045693800007, 22.074732082000104],
              [102.43977630900007, 22.075028486000022],
              [102.43930753800001, 22.075748037000054],
              [102.438814764, 22.077302718000077],
              [102.43867281500006, 22.078343822000065],
              [102.43899363500006, 22.078874158000069],
              [102.43986816100002, 22.079627246000122],
              [102.44176217700006, 22.079987924000122],
              [102.44264571600007, 22.080427839000016],
              [102.4432933840001, 22.081279717000065],
              [102.44334834800003, 22.08326590900009],
              [102.44262269700003, 22.085128147000077],
              [102.44120736100007, 22.087599942000047],
              [102.43994294300009, 22.088717471000052],
              [102.43730812200003, 22.090740922000052],
              [102.43661240900012, 22.091559242000081],
              [102.43614359600005, 22.092278762000106],
              [102.43611657100004, 22.093218249000032],
              [102.43640738300012, 22.094792438000077],
              [102.43882917500012, 22.100180440000102],
              [102.43902305400009, 22.101229901000075],
              [102.43887507500001, 22.102479723],
              [102.43732959700003, 22.105574922000045],
              [102.43485234800006, 22.10990031199999],
              [102.43298588500008, 22.112465129000022],
              [102.432169102, 22.11359375100006],
              [102.43203009600005, 22.114530390000105],
              [102.432341932, 22.115373845000086],
              [102.43308059900002, 22.116959172000072],
              [102.43306557500001, 22.11748108000009],
              [102.43283263800001, 22.117788630000042],
              [102.43204588500002, 22.117873416000045],
              [102.43014850900006, 22.117617068000058],
              [102.42646875100007, 22.117002738000032],
              [102.42412655900007, 22.116630746000077],
              [102.42210821400005, 22.116684672000098],
              [102.42041973500005, 22.116955756000053],
              [102.41916705400006, 22.117655570000046],
              [102.41744843000006, 22.118970443000038],
              [102.41550283400002, 22.120384070000028],
              [102.4136842600001, 22.121278572000058],
              [102.40575005200006, 22.124422047000088],
              [102.40167086200006, 22.125990857000133],
              [102.39862656300006, 22.126645522000032],
              [102.39536132600009, 22.127190126000059],
              [102.3915422850001, 22.127511823000034],
              [102.3874932300001, 22.128036577000017],
              [102.38500263300008, 22.128913903000047],
              [102.38283882800002, 22.130112817000033],
              [102.38213971099999, 22.131035284000063],
              [102.38198831900009, 22.132389376000013],
              [102.38233007000011, 22.13605388100008],
              [102.38255682700004, 22.139819924000022],
              [102.38234758300005, 22.147021848000037],
              [102.38206599500003, 22.148999322000051],
              [102.38158774100006, 22.15003176900003],
              [102.38043151600006, 22.151256021000073],
              [102.3757430810001, 22.154480117000027],
              [102.36465545900009, 22.162792279000065],
              [102.35761394700003, 22.16787039900003],
              [102.35634731500004, 22.168325006000074],
              [102.355721101, 22.168309145000094],
              [102.35468592100007, 22.167990909000054],
              [102.35170752100001, 22.166260668000071],
              [102.34964289400007, 22.165429612000054],
              [102.34695491900007, 22.164485374],
              [102.34560667900007, 22.16415915],
              [102.34404116600004, 22.164119417000094],
              [102.34004388100006, 22.165088641000082],
              [102.33404076000002, 22.166785524000062],
              [102.33329592200003, 22.16725328000004],
              [102.33285848600008, 22.167923526000074],
              [102.33260697000006, 22.169377207000096],
              [102.33248434900003, 22.173559639000118],
              [102.3327353170001, 22.182813029000044],
              [102.33294864400008, 22.186225203000021],
              [102.33281004700007, 22.187389701000093],
              [102.33194078300008, 22.188535614000045],
              [102.329770432, 22.19130310900006],
              [102.32705811100004, 22.194738117000057],
              [102.32402969000002, 22.198262344000064],
              [102.32033482300001, 22.203132182000026],
              [102.31915224600004, 22.204270028000025],
              [102.31735190500004, 22.20510011500005],
              [102.31503245300006, 22.205819626000078],
              [102.31375687800002, 22.206565745000084],
              [102.31300038900005, 22.207422423000089],
              [102.31253989400005, 22.208870646],
              [102.31289163100011, 22.21111823299999],
              [102.3129731330001, 22.21189895400002],
              [102.31286014699999, 22.212188067000049],
              [102.31243398900008, 22.212469180000127],
              [102.31191202600006, 22.212455860000091],
              [102.30974268700007, 22.211621848],
              [102.30747756200006, 22.210493370000073],
              [102.30697279600004, 22.209896493000066],
              [102.30699858200003, 22.209021167000031],
              [102.3071516750001, 22.207370444000048],
              [102.30708166700008, 22.206200677000076],
              [102.30669849100008, 22.205022907000057],
              [102.30588917000009, 22.204126249000034],
              [102.30455217800004, 22.203410754000075],
              [102.30269040300001, 22.202779176000043],
              [102.30123470700009, 22.202547298000042],
              [102.30029522300006, 22.202523275000097],
              [102.298819458, 22.202972193000022],
              [102.29775263000002, 22.203723558000021],
              [102.29655843600005, 22.205250319000044],
              [102.29611791700003, 22.206017699000121],
              [102.29558449000004, 22.206393377000033],
              [102.29495240600005, 22.206571860000054],
              [102.28876755800007, 22.207289454000097],
              [102.28519529200001, 22.207976539000107],
              [102.28329613600008, 22.208609156000044],
              [102.28235085900012, 22.208779578000112],
              [102.2814113470001, 22.208755475000075],
              [102.27916370500002, 22.207043155000058],
              [102.27763529600003, 22.205738596000032],
              [102.27721774100004, 22.205727875000036],
              [102.27679154500004, 22.20600893200011],
              [102.27632790200005, 22.207554341000076],
              [102.27510825300003, 22.213460231000056],
              [102.27500164800009, 22.21705872299999],
              [102.27517874900008, 22.218133892000047],
              [102.27597358200003, 22.219516925000079],
              [102.27636524200003, 22.220402942000113],
              [102.27634507700004, 22.221083736000047],
              [102.27568699900004, 22.222137458000098],
              [102.27299923700005, 22.224696291000079],
              [102.2721409820001, 22.225452873000059],
              [102.27140441700007, 22.225628599000032],
              [102.26994281900002, 22.225591033000036],
              [102.2695165690001, 22.225872056000107],
              [102.26948195900009, 22.227039113000032],
              [102.27066267200007, 22.229502656000122],
              [102.27102260900001, 22.231458450000027],
              [102.27163233600001, 22.235561851000085],
              [102.27150198400007, 22.23643443300007],
              [102.27074230700001, 22.237388175000028],
              [102.26914448700005, 22.238417692000034],
              [102.26745955800008, 22.238861003000032],
              [102.26672871000001, 22.238842208000072],
              [102.26548737700006, 22.238420975000039],
              [102.26251736400008, 22.236398030000082],
              [102.26036818200006, 22.234882805000034],
              [102.25974465000009, 22.234769426000064],
              [102.25890941600008, 22.234747917000064],
              [102.25743619000004, 22.235099282000093],
              [102.25468119599999, 22.236390865000111],
              [102.25352406100009, 22.236653025000038],
              [102.25142727700006, 22.236890941000098],
              [102.24619253000002, 22.237242527000056],
              [102.24387822300011, 22.237766742000083],
              [102.24103898700011, 22.238374697000026],
              [102.23955410300009, 22.239114948000079],
              [102.23869275400006, 22.239968619000102],
              [102.23813592000008, 22.241122137000069],
              [102.23720493900005, 22.24430981500003],
              [102.23625942200006, 22.247983717000075],
              [102.23622750800008, 22.24905346000012],
              [102.23661904500007, 22.24993949300007],
              [102.23955420900009, 22.253129709000063],
              [102.24035762900004, 22.25422102100012],
              [102.24065348000001, 22.25481260100004],
              [102.24063898400006, 22.255298842000037],
              [102.23998350400009, 22.25625513900011],
              [102.23842887200007, 22.259329283000092],
              [102.23722806400009, 22.261050044000093],
              [102.23496563500004, 22.263327275000044],
              [102.23273799300003, 22.264437522000065],
              [102.2308293880001, 22.265361361000124],
              [102.23050450600005, 22.265742242000016],
              [102.230373957, 22.266614755000091],
              [102.22936717600001, 22.272330591000063],
              [102.22875789600005, 22.275234401000077],
              [102.228839077, 22.276015050000098],
              [102.22965705600009, 22.276620130000069],
              [102.23161212200003, 22.277643901000133],
              [102.23200950300006, 22.278335407000057],
              [102.23247937400004, 22.280099286000073],
              [102.23261274300005, 22.282633002000104],
              [102.23259532100012, 22.283216455000066],
              [102.23204703900005, 22.28407812600004],
              [102.23097658800003, 22.284926295000048],
              [102.22896624900005, 22.285750137],
              [102.22601891500008, 22.286452378000021],
              [102.22112401300009, 22.286929845000088],
              [102.21519412400008, 22.287398036000106],
              [102.21183848500007, 22.287932906000037],
              [102.20971963300008, 22.288588674000032],
              [102.20854888700003, 22.289446747000063],
              [102.20437182500009, 22.291914793000075],
              [102.20270831600004, 22.293293098000078],
              [102.2003560310001, 22.295364249000023],
              [102.19665317400005, 22.298121416000072],
              [102.19324622300007, 22.300154600000077],
              [102.18955885200006, 22.302190859000071],
              [102.18752977300004, 22.303026439000107],
              [102.18541602900004, 22.303504407000041],
              [102.18340028700004, 22.303896072000029],
              [102.17952738200006, 22.305749618000092],
              [102.17838048300011, 22.305808534000079],
              [102.17666682600007, 22.305674951000043],
              [102.17580865500003, 22.305652543000051],
              [102.17541118000001, 22.306175235000048],
              [102.17529440900003, 22.306882933000068],
              [102.17522374900004, 22.312389396000043],
              [102.17550342600001, 22.315772737000117],
              [102.17601666400002, 22.317740672000049],
              [102.17799677500007, 22.321701397000112],
              [102.17917490200003, 22.323775488000038],
              [102.179632977, 22.324409327000083],
              [102.18055985800004, 22.325321910000092],
              [102.18317382800005, 22.327255736000048],
              [102.18753562200008, 22.331367224000061],
              [102.19041179700011, 22.33410732800003],
              [102.19291994000007, 22.3363935940001],
              [102.19328272400007, 22.337024906000025],
              [102.19336207200006, 22.337560007000064],
              [102.19315532100009, 22.338087650000112],
              [102.19255641200002, 22.33896044400003],
              [102.19099846400007, 22.339985940000062],
              [102.18944851500008, 22.340745115000082],
              [102.18531021100007, 22.34188102200001],
              [102.18301062800005, 22.342176433000112],
              [102.18234304000006, 22.342159025000093],
              [102.18120395200005, 22.34195164500008],
              [102.18045437500011, 22.341487904000076],
              [102.17924935400006, 22.340301571000062],
              [102.17831976100005, 22.339477775000113],
              [102.17736875000006, 22.339364114000126],
              [102.17650239700006, 22.339608018000057],
              [102.17493900200002, 22.340810935000022],
              [102.17227829900006, 22.343584259000068],
              [102.16823135300005, 22.348009198000085],
              [102.16635758400008, 22.350003459000064],
              [102.16516481000008, 22.35157131700003],
              [102.16404592400005, 22.353851785000067],
              [102.1629216430001, 22.356309766000045],
              [102.16207004100008, 22.359219047000067],
              [102.16163483400004, 22.360984345000112],
              [102.16169263700002, 22.362229536000108],
              [102.16240735400001, 22.36384725300011],
              [102.16450987200007, 22.3669225820001],
              [102.16643778800007, 22.369460322000023],
              [102.16792368600008, 22.370831654000064],
              [102.16857261000004, 22.37147043900007],
              [102.16865994799998, 22.37173922100002],
              [102.16863581400003, 22.372538078000034],
              [102.16674834, 22.375969127000062],
              [102.166060266, 22.37663081],
              [102.16485522400004, 22.377333678000078],
              [102.16296850500007, 22.377743318000043],
              [102.16187311400003, 22.378081846000072],
              [102.161270587, 22.378433274000109],
              [102.160659733, 22.37905986300008],
              [102.15797470100006, 22.3830286150001],
              [102.15723470400003, 22.384661562000119],
              [102.15662104000006, 22.385379858000043],
              [102.15571163600005, 22.386090407000033],
              [102.15330414800007, 22.387404280000034],
              [102.14989694000006, 22.389150885000042],
              [102.14689233700005, 22.390632607000072],
              [102.14618003700011, 22.391348280000045],
              [102.14586482800004, 22.391982578000032],
              [102.14591611000003, 22.39354441700003],
              [102.14644638300011, 22.39557778900005],
              [102.146393527, 22.397320468000089],
              [102.1458644520001, 22.398499900000068],
              [102.14474387800001, 22.399663812000092],
              [102.14282784700004, 22.400787466000068],
              [102.14219770500003, 22.401372342000059],
              [102.14208323300005, 22.401780833000103],
              [102.14228628400008, 22.40230510600005],
              [102.15052582900005, 22.41452095300005],
              [102.15208102700004, 22.41709389200004],
              [102.15258282300007, 22.418499732],
              [102.15267664800011, 22.419894880000065],
              [102.15228580600009, 22.423809474000045],
              [102.15169296900007, 22.425907160000058],
              [102.15529455600009, 22.429215346000042],
              [102.15729857200007, 22.430951242000049],
              [102.1591023230001, 22.431151482000097],
              [102.16314858800006, 22.430645159000036],
              [102.16782699400008, 22.430996986000103],
              [102.17444357900007, 22.432547092000021],
              [102.17876326500007, 22.433884085000066],
              [102.18169021100005, 22.43503164800007],
              [102.18354164400002, 22.436380712000044],
              [102.18435904900007, 22.43655504400008],
              [102.18527479000007, 22.436196307000067],
              [102.18661075200004, 22.435464493],
              [102.18791149400001, 22.434010374000124],
              [102.19617383400001, 22.430778680000074],
              [102.20222635900004, 22.42865706000012],
              [102.203605372, 22.428059848000089],
              [102.20524497300005, 22.427849203000072],
              [102.20740578200004, 22.428411703000073],
              [102.21050722100006, 22.429378388000117],
              [102.21241124300003, 22.42942775900012],
              [102.21433044000003, 22.428971086000082],
              [102.21916249000006, 22.426690771000054],
              [102.22176543100005, 22.426125137000064],
              [102.22406984600011, 22.426437983000099],
              [102.22904886700005, 22.428339224000027],
              [102.23201062700005, 22.429428606],
              [102.23418288300012, 22.429611320000028],
              [102.23636269200009, 22.429541009000076],
              [102.23774159500005, 22.428943578000116],
              [102.23929049700004, 22.427211095000033],
              [102.24129266300005, 22.423971020000089],
              [102.24255817100004, 22.422611001000092],
              [102.24450364300007, 22.421268504000032],
              [102.25514108800003, 22.415971816000102],
              [102.25706381500005, 22.415388257000046],
              [102.25910746000002, 22.415314213000045],
              [102.26099254300004, 22.415995730000034],
              [102.26245084500009, 22.417299283000069],
              [102.26442683200007, 22.4195023770001],
              [102.26609703100006, 22.422837036000054],
              [102.266709351, 22.425131661000108],
              [102.26664920700009, 22.427155784000071],
              [102.26601121700006, 22.430304498000098],
              [102.26369225100004, 22.435055825000028],
              [102.261671533, 22.438928545000088],
              [102.25621696700006, 22.448409908000052],
              [102.25452073600005, 22.450518441000078],
              [102.25114701000005, 22.454102942000098],
              [102.24807537300011, 22.456682367],
              [102.24694193300012, 22.458172328],
              [102.2466208380001, 22.459809803000041],
              [102.24669649900005, 22.461837279000108],
              [102.24721041800004, 22.46286328300009],
              [102.24907706700003, 22.4641773170001],
              [102.25203572100004, 22.465392844000043],
              [102.25715581000003, 22.467170310000114],
              [102.25930215300006, 22.468238250000105],
              [102.26036404100009, 22.469151696000011],
              [102.26125603499999, 22.471200112000034],
              [102.26232604900007, 22.476417872000098],
              [102.26281375200008, 22.478329265000063],
              [102.26320304800007, 22.478972216000059],
              [102.26387195100007, 22.479369165000037],
              [102.26550440000005, 22.479411089000045],
              [102.27122549800002, 22.479304741000036],
              [102.27326231300006, 22.479483575000089],
              [102.27407102400009, 22.479757492000104],
              [102.27486848200007, 22.480410884000079],
              [102.275522397, 22.481313773000089],
              [102.275753174, 22.482712170000063],
              [102.27573440300006, 22.48334464100008],
              [102.27514518600006, 22.484848599000081],
              [102.2738156570001, 22.488358985000012],
              [102.27349474900011, 22.489996394000045],
              [102.27344592300005, 22.491640774000032],
              [102.27417582200006, 22.494570979000095],
              [102.27557474300008, 22.49789806200004],
              [102.27672411600005, 22.500459210000123],
              [102.27763145100008, 22.502001472000096],
              [102.27855755600005, 22.50291128100006],
              [102.28016019400005, 22.503964998000114],
              [102.28299110400006, 22.504923556000023],
              [102.28582204400008, 22.505882080000013],
              [102.28648359100002, 22.506531913000046],
              [102.2868505390001, 22.507933705000127],
              [102.28709268100006, 22.50895255700005],
              [102.28799263700009, 22.510747721000079],
              [102.2907987200001, 22.51714848400006],
              [102.29284098200006, 22.521757509000025],
              [102.29439145700009, 22.52458183700012],
              [102.29542014200003, 22.526633341000043],
              [102.297723547, 22.531628623000088],
              [102.29942147100007, 22.534076886000065],
              [102.30127046300002, 22.536022677000034],
              [102.30340661500003, 22.537469469000101],
              [102.31246598500007, 22.544282090000053],
              [102.31552506100005, 22.546764801000037],
              [102.31758276900004, 22.548156216000059],
              [102.31862513000004, 22.54881424900006],
              [102.31930596500007, 22.549344428000047],
              [102.32015942100007, 22.54795095700004],
              [102.32127002800003, 22.546406987000097],
              [102.32301352900005, 22.544646170000071],
              [102.32452198900008, 22.542355299000057],
              [102.32544469400003, 22.540806509000078],
              [102.32671808800006, 22.537985547000083],
              [102.32745125000005, 22.536490167000075],
              [102.32804205600009, 22.535573457000076],
              [102.32862430800003, 22.534947694],
              [102.33016101500009, 22.533822079000032],
              [102.333481347, 22.531693527000037],
              [102.33617730600005, 22.529490862000046],
              [102.33725215500007, 22.528527277000073],
              [102.33781814500001, 22.527740190000053],
              [102.33809694100007, 22.526945823000055],
              [102.33814540100009, 22.52638307600003],
              [102.33790580100008, 22.525842713000088],
              [102.33651210400004, 22.524491405000013],
              [102.33591003300006, 22.523660891000041],
              [102.33586794600004, 22.522961036000041],
              [102.33588503900003, 22.522379146000034],
              [102.33665054300005, 22.519778085000027],
              [102.33738354900002, 22.518282605000103],
              [102.33810801000001, 22.517078071000093],
              [102.33934007400009, 22.515653472000082],
              [102.34037748800003, 22.51445685500002],
              [102.34103759300002, 22.513308883000072],
              [102.34228356900009, 22.510334720000102],
              [102.34463507900006, 22.503849591000069],
              [102.34476693500004, 22.502642372000103],
              [102.34484629400004, 22.502293822000041],
              [102.34550040800006, 22.500574850000099],
              [102.34598206700004, 22.497252737000025],
              [102.346392737, 22.494166973000063],
              [102.34654688900005, 22.493277745000029],
              [102.347022748, 22.492337120000094],
              [102.34780634900008, 22.491821073000025],
              [102.34934392300002, 22.491800432],
              [102.35332018900004, 22.491603288000036],
              [102.35525389800007, 22.491175837000107],
              [102.35648369100005, 22.49073057400004],
              [102.35727423200008, 22.489976499000086],
              [102.35748883400008, 22.48920787800008],
              [102.35782138600005, 22.486596407000015],
              [102.358446434, 22.482741910000037],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 19, code: "AD01", ten_tinh: "Đồng Nai" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.37698378800006, 11.544719392000056],
              [107.37727562500004, 11.542884608000035],
              [107.37714281600007, 11.539495327000092],
              [107.37630433400005, 11.532856326000028],
              [107.37531763600005, 11.527911352000062],
              [107.37425583600003, 11.525853917000093],
              [107.37279312600006, 11.524508164000034],
              [107.37157246100006, 11.523348170000068],
              [107.36759455100008, 11.521097794000033],
              [107.36607884200008, 11.520347079000091],
              [107.36532629500007, 11.519409451000049],
              [107.36547202500006, 11.518562692000065],
              [107.36719274200004, 11.517438279000112],
              [107.36930650000006, 11.516456225000036],
              [107.37166896000005, 11.516039701000034],
              [107.373813302, 11.516752084000091],
              [107.37448090300009, 11.517762560000032],
              [107.37552509900007, 11.518577195000111],
              [107.37865638400007, 11.52146369600011],
              [107.382085257, 11.524867476000098],
              [107.38477058400005, 11.526941064000072],
              [107.38581597400004, 11.527386773000091],
              [107.38917822300006, 11.528134313000036],
              [107.39044924500007, 11.528137998000075],
              [107.40202290200004, 11.52916165600004],
              [107.40338051900001, 11.529281692000025],
              [107.406219709, 11.529953701000027],
              [107.40756636400005, 11.529662393000082],
              [107.40837248100004, 11.529136455000087],
              [107.40880832900005, 11.526878466000044],
              [107.40910351400005, 11.523772884000037],
              [107.41054758000007, 11.519258543000086],
              [107.41284451000004, 11.516723372000024],
              [107.41643089000004, 11.513626976000067],
              [107.41814983900008, 11.512453107000018],
              [107.41872222800001, 11.51245468800006],
              [107.41948019200001, 11.512620001000061],
              [107.42040301600008, 11.513392005000076],
              [107.42129385300007, 11.514487594000043],
              [107.42314777300007, 11.516751860000072],
              [107.425002932, 11.518592530000136],
              [107.42680093600009, 11.519775093000105],
              [107.42891827200003, 11.519990025000125],
              [107.42993836900007, 11.519170746000016],
              [107.43028040200004, 11.518395085000053],
              [107.43073034100004, 11.517407895000085],
              [107.43101909700005, 11.516490888000062],
              [107.43141391100002, 11.515637143000033],
              [107.43236600100002, 11.514427230000111],
              [107.4345514190001, 11.513220630000108],
              [107.43835840500006, 11.511144321000087],
              [107.44198670000006, 11.509036744000054],
              [107.44485454100003, 11.506855763000077],
              [107.44691445800002, 11.505564212000062],
              [107.44946678000008, 11.503685683000063],
              [107.45067957400006, 11.502620572000042],
              [107.45157513700009, 11.50111473800006],
              [107.45217349600003, 11.49896795000007],
              [107.45290233100003, 11.49395741800001],
              [107.45326211200005, 11.493181782000049],
              [107.453979793, 11.492336477],
              [107.45491096700005, 11.491915306000065],
              [107.45605564000007, 11.491918268000044],
              [107.45831014900001, 11.491068202000053],
              [107.45963682500009, 11.490374356000109],
              [107.46107043600006, 11.489319079000014],
              [107.46236244100001, 11.487698654000054],
              [107.46372614200004, 11.486007818000042],
              [107.46516173, 11.484175955000085],
              [107.4662386840001, 11.482696166000038],
              [107.46767239100006, 11.481570258000049],
              [107.46900035699998, 11.480816685000137],
              [107.46859709100002, 11.480260031000087],
              [107.46769779600004, 11.479630141000106],
              [107.46409969200005, 11.477476676],
              [107.460787549, 11.474493141000062],
              [107.45868217800006, 11.471918722000039],
              [107.44999205400002, 11.46233007000008],
              [107.44752686200006, 11.457798383000027],
              [107.44710378600006, 11.455538267000074],
              [107.44682224300003, 11.453843273000073],
              [107.44639918400001, 11.451583168000033],
              [107.44483093300002, 11.449602420000103],
              [107.44254552000011, 11.448325707000071],
              [107.43997287800005, 11.447471757000114],
              [107.43482883700005, 11.445340211000053],
              [107.43097391500004, 11.442647297000084],
              [107.42883330000002, 11.440806093000065],
              [107.42812200400006, 11.439392316000061],
              [107.42741465700007, 11.436566700000037],
              [107.42785720700009, 11.431767660000068],
              [107.42800655900001, 11.42950913800007],
              [107.42903598300009, 11.426338154000069],
              [107.43164887300001, 11.427976887000034],
              [107.4353597730001, 11.431114240000094],
              [107.4375602260001, 11.43247986000002],
              [107.43976442900004, 11.432485739000063],
              [107.44197086300008, 11.431675771000103],
              [107.44399376400003, 11.430095583000098],
              [107.444228498, 11.428826323000061],
              [107.44423076100001, 11.427992284000064],
              [107.44336775800011, 11.426397846000034],
              [107.44240265900001, 11.424878307000073],
              [107.44199420400005, 11.423109596000101],
              [107.44213862600002, 11.420662502000043],
              [107.44280656600003, 11.420141514000102],
              [107.4458591100001, 11.420264419000057],
              [107.45054131100008, 11.420820585000067],
              [107.45257459600001, 11.421474982000033],
              [107.45398011100004, 11.422733124000038],
              [107.45411388000009, 11.424229157000097],
              [107.45383876600006, 11.425969904000064],
              [107.45352602400004, 11.429257280000103],
              [107.454129994, 11.430753488000088],
              [107.45534352600005, 11.43165341300003],
              [107.45722984700006, 11.432120336000116],
              [107.46882535200007, 11.435280569000026],
              [107.47302573400007, 11.437061779000055],
              [107.47772792600003, 11.433395181000082],
              [107.48235421400008, 11.4311347170001],
              [107.48466846800008, 11.429550071000081],
              [107.48698722900008, 11.426147910000049],
              [107.48699405800002, 11.423421680000077],
              [107.48447633500008, 11.414100920000079],
              [107.48495491100006, 11.407513779000043],
              [107.48496462300007, 11.403651713000061],
              [107.48705380500009, 11.399567571000089],
              [107.49816689600006, 11.389144168000074],
              [107.50163874100008, 11.386199127000028],
              [107.50602889400005, 11.385527958000095],
              [107.51181189400009, 11.381906648000049],
              [107.51366881500007, 11.378048991000076],
              [107.51576038600003, 11.372601679000114],
              [107.51830841499999, 11.369427134000086],
              [107.53080289300004, 11.359459924000069],
              [107.53358198200004, 11.356058595000105],
              [107.53542641000006, 11.357425676000064],
              [107.53842199900002, 11.360385449000086],
              [107.53957213200006, 11.362432486000083],
              [107.54215712300007, 11.367187572000057],
              [107.54280337600008, 11.363348189000087],
              [107.54166133800008, 11.357666505000095],
              [107.54075284500006, 11.350849547000051],
              [107.53892275600006, 11.343121968000068],
              [107.53616604800006, 11.336755384000073],
              [107.53364249900007, 11.32948069100008],
              [107.52950071900004, 11.3231110600001],
              [107.52535288200004, 11.319467237000056],
              [107.52097111300004, 11.317185754000112],
              [107.51820569700003, 11.314907895000113],
              [107.51659426400001, 11.312859810000118],
              [107.51683052000001, 11.310588822000065],
              [107.51845742000006, 11.306049513000092],
              [107.52008318300003, 11.301964508000049],
              [107.52124447200001, 11.299014214000101],
              [107.521481722, 11.296288969000033],
              [107.52056301100002, 11.294242541000086],
              [107.51780218500004, 11.290147583000108],
              [107.51666017800005, 11.28492060700008],
              [107.51759538600007, 11.279925576000059],
              [107.52038212200007, 11.272890537000089],
              [107.52108580500003, 11.268122188000083],
              [107.52202718700002, 11.260401622000058],
              [107.52226490200006, 11.257449379000127],
              [107.5213443640001, 11.253319196000076],
              [107.52042868800001, 11.253372222000023],
              [107.51617149800002, 11.253362471000104],
              [107.51482922300008, 11.252474126000054],
              [107.51416333600005, 11.249816865000104],
              [107.513591644, 11.247391479000067],
              [107.51232752400003, 11.245584366000026],
              [107.51070943000008, 11.244883289000105],
              [107.50807828899998, 11.244478675000023],
              [107.50635015700009, 11.244359317000095],
              [107.50210642600005, 11.244066155000086],
              [107.4980568170001, 11.244156142000071],
              [107.49623484700007, 11.244052171000083],
              [107.49532546200005, 11.243352664000026],
              [107.49482297200005, 11.241857201000103],
              [107.49452542000003, 11.239366060000043],
              [107.49473304100007, 11.237274604000012],
              [107.49585270200006, 11.234786880000057],
              [107.49798609100002, 11.231703888000053],
              [107.49836023400007, 11.230731482000065],
              [107.49865923300003, 11.22861182300001],
              [107.498670729, 11.223912478000029],
              [107.49772214200003, 11.221278576000058],
              [107.49638602200005, 11.220899435000129],
              [107.49466651800007, 11.221083282000064],
              [107.49351915699999, 11.22168304500004],
              [107.49198825000008, 11.222768593000064],
              [107.48949861400004, 11.225394207000059],
              [107.48848960900006, 11.225824259000115],
              [107.48739657800012, 11.225765041000011],
              [107.48650927700007, 11.225095735000055],
              [107.48587226900003, 11.224257522000068],
              [107.48492980400003, 11.222547071000031],
              [107.48473142200004, 11.222187017000083],
              [107.48476003800002, 11.221472979000067],
              [107.48492667900004, 11.220495733000034],
              [107.48626794900008, 11.218807256000039],
              [107.48665325000007, 11.217492386000048],
              [107.48665607500004, 11.216364555000078],
              [107.48305219800002, 11.206393253000078],
              [107.48097229200005, 11.202336277000045],
              [107.47981774600001, 11.201498078000059],
              [107.4786713500001, 11.2016832070001],
              [107.47637518000001, 11.203369219000068],
              [107.47408380500005, 11.203175533000119],
              [107.47236733300004, 11.20223138500007],
              [107.47141723600004, 11.200349315000045],
              [107.46952392400006, 11.193953646000042],
              [107.47049630100004, 11.187189334],
              [107.46993796400002, 11.181549],
              [107.46909414500001, 11.179706920000053],
              [107.46841704700005, 11.178913674000055],
              [107.46650787300008, 11.178720877000091],
              [107.46287953600007, 11.178711639000126],
              [107.461543265, 11.178520259000011],
              [107.45963460600009, 11.178139439000033],
              [107.45896076200002, 11.177624090000025],
              [107.4595435950001, 11.175082031000015],
              [107.46005570700002, 11.172472565000112],
              [107.46065966500008, 11.169391022000051],
              [107.45925724900005, 11.161418975000091],
              [107.45690296200011, 11.154616363000079],
              [107.45572308200011, 11.15226968900007],
              [107.45122659300003, 11.148273861000034],
              [107.44151613800003, 11.142623661000071],
              [107.43417176100004, 11.139323013000089],
              [107.43251653700004, 11.137443697000025],
              [107.43252561900009, 11.134162727000072],
              [107.43443354300003, 11.130418192000134],
              [107.43706524800008, 11.127284608000119],
              [107.44132811600011, 11.124577782000065],
              [107.44769456700008, 11.11923240000001],
              [107.45084006500011, 11.11546315900007],
              [107.45226875300001, 11.113357738000039],
              [107.45227313500001, 11.111717311000023],
              [107.451332597, 11.108668338000081],
              [107.44873950700003, 11.102802886000072],
              [107.44321632100011, 11.088484776000127],
              [107.44096173700007, 11.084972311000028],
              [107.43969235000003, 11.084373258000111],
              [107.43859032900006, 11.084965990000038],
              [107.43787440700007, 11.086604454000069],
              [107.43683832300005, 11.089213632000037],
              [107.43573045000005, 11.09011382300009],
              [107.43424679200004, 11.090387602000094],
              [107.43090442000006, 11.089135497000056],
              [107.42869891000001, 11.087699970000118],
              [107.42507725000011, 11.083523424000051],
              [107.42129625500009, 11.078826284000074],
              [107.42052318200007, 11.076738577000087],
              [107.42109761500008, 11.074502657],
              [107.42273818500011, 11.072034491000069],
              [107.42393106000004, 11.069460079000047],
              [107.42433734800008, 11.067239725000098],
              [107.42394488500003, 11.064539106000025],
              [107.42182338500001, 11.060080972000017],
              [107.41780844200008, 11.054445958000068],
              [107.41497187400003, 11.051391797000052],
              [107.41351133800006, 11.050895068000075],
              [107.41165378900003, 11.050913880000033],
              [107.40310996000001, 11.05370173200013],
              [107.40037308700005, 11.054276289000063],
              [107.3983664940001, 11.05415686200004],
              [107.39715500100006, 11.053956320000056],
              [107.39552468500008, 11.052977068000034],
              [107.39278426200008, 11.050712917000014],
              [107.3953896940001, 11.049813471000023],
              [107.40113594900001, 11.047829610000102],
              [107.40672821700007, 11.043690798000048],
              [107.41201615500007, 11.040328307000012],
              [107.415851741, 11.038677659000127],
              [107.42035380200009, 11.037300004000093],
              [107.43688266200007, 11.034159064000091],
              [107.44241306300007, 11.031055163000046],
              [107.44755099000011, 11.027170432000053],
              [107.45390582600001, 11.019554177000133],
              [107.4621799220001, 11.013564517000061],
              [107.46968529900008, 11.008905761000063],
              [107.4740254230001, 11.008137033000052],
              [107.47915127900011, 11.008539620000056],
              [107.48427618400004, 11.009331898000061],
              [107.49294087100002, 11.014030820000096],
              [107.498063104, 11.015992228000114],
              [107.50209275400007, 11.015277949000078],
              [107.5024032630001, 11.015222906000053],
              [107.50635276100003, 11.012893302000036],
              [107.50902160600006, 11.009914144],
              [107.51270751300002, 11.004964203000076],
              [107.51821536800003, 10.999667072000106],
              [107.52769430000005, 10.993451617000092],
              [107.53362365300002, 10.987227941000109],
              [107.540699563, 10.978585460000099],
              [107.55838490900001, 10.938472509000084],
              [107.55813724800004, 10.90684724100006],
              [107.55784273800001, 10.869229393000078],
              [107.56777974800011, 10.854948528000106],
              [107.56998842700006, 10.851072856000064],
              [107.57217299500007, 10.847239347000047],
              [107.57379829899999, 10.841113577000081],
              [107.57386845200003, 10.840436280000086],
              [107.57450350700007, 10.834304985000108],
              [107.57428210200005, 10.829991559000083],
              [107.57199281500009, 10.82317700600006],
              [107.56970320300009, 10.816589475000081],
              [107.56741087800005, 10.811363936000083],
              [107.56304480100002, 10.806588086000078],
              [107.55867941500004, 10.801585197000028],
              [107.55132268000001, 10.795440948000044],
              [107.54304466500005, 10.789521473000095],
              [107.53591708200007, 10.784285220000099],
              [107.52809897400005, 10.779047215000046],
              [107.52004727500008, 10.77539728400003],
              [107.51314296400008, 10.773565555000085],
              [107.50415967000004, 10.77272379200007],
              [107.50385350100001, 10.772695093],
              [107.49910222600009, 10.770808778000072],
              [107.49564966300005, 10.770119575000068],
              [107.49012461300006, 10.769425293],
              [107.48505559200004, 10.770547814000063],
              [107.48113507200003, 10.772807943000105],
              [107.47813099100006, 10.776886134000028],
              [107.45226960700009, 10.802696506000052],
              [107.45012225500005, 10.804946231000121],
              [107.44727028000005, 10.805408715000018],
              [107.44425630200008, 10.805400760000071],
              [107.44199784500009, 10.804649447000079],
              [107.43917938400004, 10.802033301000067],
              [107.435178261, 10.797650161000137],
              [107.43148459500001, 10.791764525000056],
              [107.42248260400001, 10.777765549000049],
              [107.41872777600003, 10.773469753000063],
              [107.41778764500003, 10.77290818500005],
              [107.41609202600007, 10.773089800000067],
              [107.4123235480001, 10.77363824900006],
              [107.40911849000004, 10.774747191000065],
              [107.40704668600004, 10.774741350000026],
              [107.40347246900006, 10.773240648000113],
              [107.40046549600005, 10.770996215000126],
              [107.39741694600005, 10.766585450000042],
              [107.39711666300008, 10.766311560000117],
              [107.39704439100005, 10.766066140000062],
              [107.39552530900004, 10.764091634000026],
              [107.39410095500006, 10.762332888000079],
              [107.39202443800011, 10.759772974000013],
              [107.38313692700007, 10.753413322000059],
              [107.38037440000002, 10.75080807200006],
              [107.37827684300002, 10.747481087000031],
              [107.37762860900003, 10.74290173300003],
              [107.37687784800008, 10.741165643],
              [107.37487046500009, 10.738776504000034],
              [107.37255880700005, 10.737081527000035],
              [107.36567324700009, 10.734027583000037],
              [107.36134140700005, 10.731756969000104],
              [107.35909949100001, 10.728791273000036],
              [107.35829006700001, 10.725065593000059],
              [107.35808855300003, 10.723760496000077],
              [107.35753620000007, 10.718800433000107],
              [107.35548206300011, 10.715835339000089],
              [107.3536109090001, 10.714350183000041],
              [107.35117704100007, 10.712863285000116],
              [107.34725141400007, 10.708597878000068],
              [107.34613598600006, 10.705450724000086],
              [107.3461471750001, 10.70193724800008],
              [107.34673105900001, 10.69528195],
              [107.34731608, 10.688256886000056],
              [107.34902343900005, 10.68215995900008],
              [107.35025245300004, 10.67902023900009],
              [107.34105055300002, 10.679456712000055],
              [107.33443810100002, 10.67974625700012],
              [107.32877722600006, 10.677865769000048],
              [107.32312362800008, 10.673812309000128],
              [107.31872961200006, 10.67002004900006],
              [107.31831709800011, 10.669664013000089],
              [107.31544136300003, 10.670200534000072],
              [107.31267630100004, 10.671099273],
              [107.30990663800003, 10.673359628000066],
              [107.30459269300009, 10.67924267900011],
              [107.30066496600008, 10.683541637000092],
              [107.29742764400002, 10.687842854000083],
              [107.29944822900005, 10.702828488000103],
              [107.29943893600009, 10.70555192300011],
              [107.30080550000008, 10.709868662000053],
              [107.30447353600009, 10.714420022000047],
              [107.30350415300009, 10.728715397000064],
              [107.30233215500002, 10.734839567000074],
              [107.29999647200003, 10.744591411000036],
              [107.29882195200001, 10.75139662],
              [107.29857695900009, 10.755708285000052],
              [107.26011954800001, 10.756484160000033],
              [107.2421437830001, 10.760505590000056],
              [107.23776276100008, 10.762078553],
              [107.23430446200003, 10.763200853000042],
              [107.22922620500005, 10.765054972000064],
              [107.22795620300005, 10.764993416000033],
              [107.22767764200009, 10.764979862000066],
              [107.22669863000009, 10.764988788],
              [107.22658659300005, 10.764728448000048],
              [107.22601379200009, 10.763397424000052],
              [107.22533068700005, 10.76135212600011],
              [107.22372566200004, 10.759530396000104],
              [107.22119615200009, 10.758613120000058],
              [107.21728160400002, 10.758598562000097],
              [107.21619364600006, 10.758680258000107],
              [107.21152317700007, 10.759030964000081],
              [107.20507040900006, 10.760368489000081],
              [107.20504653600004, 10.761682906000123],
              [107.20477771200009, 10.776482835000055],
              [107.19972023500006, 10.77419381500002],
              [107.19604012900002, 10.773044851000078],
              [107.18659850400006, 10.773008485000089],
              [107.18430018600006, 10.77186466600004],
              [107.17947786600003, 10.768441190000109],
              [107.17168138500003, 10.760239411000059],
              [107.16565174300011, 10.755103654000029],
              [107.15180578600007, 10.745494296000045],
              [107.14030785200005, 10.746515677000088],
              [107.13483453100007, 10.746999806000089],
              [107.13568097900003, 10.744452138000058],
              [107.13655479200003, 10.74228366300005],
              [107.13705067700002, 10.737603583000064],
              [107.137443288, 10.729473547000088],
              [107.13732174700007, 10.719723356000086],
              [107.13726870700003, 10.716909615000068],
              [107.13712450800008, 10.70925962400011],
              [107.13554265200004, 10.702217452000083],
              [107.13304299200007, 10.694490692000079],
              [107.13123600400004, 10.686312960000064],
              [107.12894185300011, 10.684487854000045],
              [107.11678799000001, 10.673770515000045],
              [107.11173894200004, 10.670345035000127],
              [107.10600351800005, 10.666008746000076],
              [107.10210364500004, 10.663041841000085],
              [107.09843903000007, 10.658941178000079],
              [107.09569315900006, 10.655298325000027],
              [107.09385995700008, 10.653474906000058],
              [107.0846499580001, 10.654342694000094],
              [107.07107009800004, 10.654509960000079],
              [107.06554443300008, 10.654939308000076],
              [107.06093001100002, 10.657415054000106],
              [107.05540007000006, 10.658751881000049],
              [107.04987530699999, 10.658953887000065],
              [107.04412249300007, 10.658700819000087],
              [107.03883531900004, 10.65731502800009],
              [107.03447415100005, 10.654798667000074],
              [107.02253334900008, 10.649069925],
              [107.01593512500001, 10.6462864970001],
              [107.01219763100003, 10.644709729000022],
              [107.01476617700011, 10.63700602300006],
              [107.01524058100001, 10.634058118000043],
              [107.01294259100004, 10.633366521000108],
              [107.00971425500009, 10.634712906000042],
              [107.00694066300011, 10.637196067000064],
              [107.00325306800008, 10.638313257000041],
              [106.99797665100004, 10.634884126000086],
              [106.99545968500007, 10.631921971000045],
              [106.9952495380001, 10.627836208000117],
              [106.99618887700009, 10.62398289700006],
              [106.99874117000007, 10.619683453000038],
              [107.00220605500004, 10.616976826],
              [107.00655018200004, 10.613774035000032],
              [107.01098832700008, 10.610726035000082],
              [107.01210768800006, 10.609776072000086],
              [107.01528307800007, 10.607081152000129],
              [107.01744137300003, 10.604129711000086],
              [107.02186220200007, 10.597408787000083],
              [107.02420948000012, 10.592643483000062],
              [107.0247352330001, 10.5883630170001],
              [107.02478924600004, 10.587432647000037],
              [107.02478314100007, 10.586502676000096],
              [107.02465596800002, 10.585449477000084],
              [107.02440490400004, 10.583839059000065],
              [107.023669216, 10.581673748000052],
              [107.02269508000005, 10.579819971000058],
              [107.01819909900011, 10.572656628000042],
              [107.01237913800004, 10.565253632000092],
              [107.01079870300002, 10.562597642000069],
              [107.0100663960001, 10.560928257000056],
              [107.00963470000008, 10.559256960000061],
              [107.00938577000008, 10.557956508000109],
              [107.00926030100001, 10.557151288000098],
              [107.00924781300004, 10.555229312000057],
              [107.00965816000007, 10.553614666000042],
              [107.01180463100012, 10.550872938000031],
              [107.01389940700007, 10.549433566000042],
              [107.01629715500005, 10.548364251000029],
              [107.02073838200008, 10.547219880000023],
              [107.02319700700011, 10.546274139000021],
              [107.02457478500006, 10.545521306000081],
              [107.02517024800004, 10.544649483000066],
              [107.02540421600003, 10.543655959000107],
              [107.02549820100008, 10.541731917000073],
              [107.02551066800004, 10.541476691000094],
              [107.02538070300004, 10.54006004100003],
              [107.02434257000003, 10.537586639000017],
              [107.02210100200001, 10.534934920000081],
              [107.01895578700001, 10.531978933000106],
              [107.01435748100005, 10.527420098000027],
              [107.01248357400007, 10.525819960000058],
              [107.01145401000007, 10.525016540000108],
              [107.00530848000005, 10.530171658000052],
              [107.00322560000004, 10.53121932000005],
              [107.00069208500001, 10.531826473000104],
              [106.99874937500003, 10.531469279000037],
              [106.99291510500004, 10.529437068000023],
              [106.99022851600006, 10.529453966000089],
              [106.98762128300007, 10.530209261000055],
              [106.98598750300008, 10.531475683000034],
              [106.98487707700012, 10.532886618000068],
              [106.98347755600005, 10.535777190000049],
              [106.98304396800006, 10.537996693000105],
              [106.98306099200005, 10.540656728000018],
              [106.98344265800009, 10.541984408000092],
              [106.9843595400001, 10.545303813000116],
              [106.98587491800008, 10.548841144000052],
              [106.9863345700001, 10.550685571000072],
              [106.98624515200007, 10.554196034000094],
              [106.9858063560001, 10.555602752000047],
              [106.98402841300006, 10.557682908000031],
              [106.98002084700003, 10.561254903000082],
              [106.97861174200006, 10.562667708000093],
              [106.97765281500003, 10.564447138000064],
              [106.9775881130001, 10.56599929800006],
              [106.97801114500001, 10.567954804000033],
              [106.9807440480001, 10.575105265000049],
              [106.98206191500007, 10.580033727000069],
              [106.97888689700009, 10.582275484000101],
              [106.97759056000004, 10.582751369000041],
              [106.97577257700007, 10.582397661000085],
              [106.97353220400007, 10.582773123000065],
              [106.97195351600001, 10.584068381000032],
              [106.97045332800005, 10.586362875000075],
              [106.966944756, 10.590455161000099],
              [106.96486061200005, 10.591682145000012],
              [106.95964468300005, 10.593892659000035],
              [106.95755683900005, 10.594548339000108],
              [106.94884195200008, 10.596744706000026],
              [106.94560527800007, 10.598264362000085],
              [106.93418753500002, 10.601007462000032],
              [106.93325656100009, 10.601387352000112],
              [106.92977556700002, 10.602793375000095],
              [106.92442523400008, 10.605670221000095],
              [106.92134631900007, 10.609057043000101],
              [106.91939121900003, 10.610790404000079],
              [106.91810772600007, 10.611097611],
              [106.91742723300007, 10.611101764000084],
              [106.91613954500011, 10.610735387000057],
              [106.91333221600009, 10.609180750000034],
              [106.91151432900004, 10.608667894000055],
              [106.91045673700003, 10.608824010000093],
              [106.9091017960001, 10.6098052240001],
              [106.90782617600007, 10.611384707000047],
              [106.90607275500003, 10.615175008000042],
              [106.90422001000012, 10.621248679000049],
              [106.90302549700004, 10.623725802000104],
              [106.90099921800008, 10.626207971000044],
              [106.89678792900007, 10.629975692000036],
              [106.89419559000007, 10.632648358000017],
              [106.88358651000006, 10.641319480000087],
              [106.88225293700003, 10.64581823700008],
              [106.88120848900003, 10.648144719000106],
              [106.88038085100004, 10.648753087000083],
              [106.88007923300003, 10.648974795000042],
              [106.87860623300001, 10.649245593000066],
              [106.87233061000009, 10.649432846000105],
              [106.87058538700011, 10.648470267000041],
              [106.868830141, 10.645861125000076],
              [106.86609323400003, 10.643482347000075],
              [106.86178936400009, 10.640061637000107],
              [106.85940601700007, 10.638133122000058],
              [106.858194301, 10.637840916000039],
              [106.85706092100001, 10.637997316000012],
              [106.85366621300007, 10.639364651000081],
              [106.85083566900008, 10.640242102000029],
              [106.84728198500002, 10.640337907000051],
              [106.84455787000006, 10.640054549000054],
              [106.84076923200011, 10.638804399],
              [106.83924786100009, 10.637316385000071],
              [106.83847955600002, 10.635300001000035],
              [106.83770142200004, 10.631637025000094],
              [106.83739179300008, 10.630441271000011],
              [106.83594699, 10.629102473000083],
              [106.83397690900006, 10.628440365000101],
              [106.83146312700008, 10.627847620000084],
              [106.83117504000005, 10.627783085000074],
              [106.82705499300003, 10.627994206000045],
              [106.823735368, 10.629285929000075],
              [106.82080000600004, 10.63162326700011],
              [106.81561327500006, 10.636892703000099],
              [106.81145013700004, 10.642642689000057],
              [106.80885590700005, 10.65156465300001],
              [106.8075189470001, 10.655689045000114],
              [106.80586710800003, 10.657719492000071],
              [106.80081643300011, 10.660517996000072],
              [106.79633222200007, 10.663200899000101],
              [106.79053557500008, 10.667799917000041],
              [106.78594272600003, 10.6713441030001],
              [106.78315269700002, 10.67278216600007],
              [106.77658935000008, 10.675663811000023],
              [106.77463949200005, 10.676696433000108],
              [106.77387485500002, 10.67710137000004],
              [106.77187554200006, 10.678345221000106],
              [106.77165681400008, 10.678481300000085],
              [106.76521907100006, 10.684410846000068],
              [106.75942626500003, 10.689832742000059],
              [106.7539418210001, 10.696300755],
              [106.75274580600008, 10.698777539000035],
              [106.75045622900009, 10.70563744300008],
              [106.75018029600008, 10.706464132000091],
              [106.74961557000007, 10.713503259000044],
              [106.74978446800002, 10.716162590000076],
              [106.74994822200007, 10.718740934000069],
              [106.75087230600005, 10.721580060000056],
              [106.75396844699999, 10.72721383200008],
              [106.76172266900005, 10.737068946000059],
              [106.76454704100006, 10.741431656000076],
              [106.76493889900006, 10.741862320000093],
              [106.76823972700006, 10.745489984000043],
              [106.77070994100008, 10.747642856000093],
              [106.77103412499999, 10.747925393000022],
              [106.77532343600006, 10.750483215000093],
              [106.78364834300008, 10.754234098000024],
              [106.79138303900004, 10.757295776000129],
              [106.796807841, 10.759921551000049],
              [106.79967467100005, 10.761757433000072],
              [106.80055222700001, 10.763024770000039],
              [106.80154949800001, 10.76533927600005],
              [106.80177975900006, 10.765952306000058],
              [106.80302929300007, 10.769278922000057],
              [106.80448141200004, 10.771740465000066],
              [106.80619658100002, 10.773901071000045],
              [106.80775822000001, 10.775293592000066],
              [106.81138786900003, 10.778530054000067],
              [106.81507197900004, 10.780978469000056],
              [106.81719416800003, 10.781602228000063],
              [106.81897253700001, 10.781666637000063],
              [106.8201062520001, 10.781472864000106],
              [106.82138835500004, 10.780791696000056],
              [106.82278055200004, 10.779548512000046],
              [106.82398179800005, 10.778007069000049],
              [106.82427630600007, 10.776658081000077],
              [106.824284963, 10.774955247000049],
              [106.82332262100005, 10.772191560000087],
              [106.82116541500008, 10.765729924000043],
              [106.82096167800009, 10.763298579000134],
              [106.82144555200007, 10.761985898000058],
              [106.82223560900007, 10.761270208000083],
              [106.82333029400004, 10.760889530000027],
              [106.824848715, 10.760644732000022],
              [106.82525759800009, 10.760578810000075],
              [106.82601591000004, 10.760873738000052],
              [106.82723285600009, 10.761951854000083],
              [106.830987689, 10.766738664000018],
              [106.83468195700004, 10.770870834000055],
              [106.83956592800011, 10.774677794000096],
              [106.84107658100005, 10.775192119000019],
              [106.84388728800008, 10.776149032000058],
              [106.8480869840001, 10.776273707000071],
              [106.85008860400008, 10.775737827000052],
              [106.85203244500006, 10.775033893000073],
              [106.8559799180001, 10.771043421000035],
              [106.86111478600006, 10.766390891000109],
              [106.86394754400004, 10.765700255000075],
              [106.86830065900007, 10.766197976000045],
              [106.87095579600002, 10.767379498000105],
              [106.87255508700009, 10.769091297000076],
              [106.87288967600007, 10.77120366500006],
              [106.872561499, 10.773189048000107],
              [106.87071162900007, 10.777110593000126],
              [106.87050788000009, 10.777542505000069],
              [106.86980967600005, 10.778338735000135],
              [106.86861092400008, 10.77970576500009],
              [106.86376973100008, 10.785984573000105],
              [106.86302331800005, 10.787635677000116],
              [106.86282805500005, 10.789713827000112],
              [106.86321555200006, 10.791208408000051],
              [106.864055385, 10.792438311000028],
              [106.86878873900004, 10.796245593],
              [106.87555407600007, 10.80133155],
              [106.87703801300003, 10.802707178000047],
              [106.87883533000006, 10.805802329000079],
              [106.88056236600005, 10.809758614000129],
              [106.88049509000007, 10.811106237000077],
              [106.87975316800006, 10.813468373000122],
              [106.877681846, 10.818027823000074],
              [106.87561694900003, 10.820585114000012],
              [106.87093648600002, 10.825384922000046],
              [106.87031416900004, 10.826051560000048],
              [106.86745798900007, 10.82911104600006],
              [106.86231069300007, 10.834624441000072],
              [106.85697499000001, 10.84060689100008],
              [106.85361723800001, 10.84524884800002],
              [106.84888661900001, 10.854314967000088],
              [106.84597236200003, 10.860357595000012],
              [106.84508518100007, 10.863768418000051],
              [106.84518885500003, 10.865246009000094],
              [106.84561449100008, 10.866777798],
              [106.84680727800001, 10.869989027000047],
              [106.8483218370001, 10.873217016000057],
              [106.84909383700003, 10.87568228900002],
              [106.84910398000005, 10.87732884300001],
              [106.84888063400005, 10.877928951000102],
              [106.84581686000004, 10.88122190800007],
              [106.84273375100003, 10.884458815000107],
              [106.84202167700009, 10.885585789000094],
              [106.84133921300003, 10.888452761000076],
              [106.8403450870001, 10.892949511000081],
              [106.83870053500002, 10.89937744800009],
              [106.83708424500003, 10.902995610000053],
              [106.83687921400004, 10.903151501000073],
              [106.83408196100005, 10.905278264000094],
              [106.83108119900001, 10.906169700000044],
              [106.82808559700004, 10.906153008000084],
              [106.82394304500004, 10.905221740000036],
              [106.82118179900002, 10.904525188000045],
              [106.81841664900001, 10.904509651000092],
              [106.81541714100008, 10.905173830000068],
              [106.81332882400004, 10.907659341000018],
              [106.81262044200005, 10.910606701000079],
              [106.81190547500005, 10.914689186000091],
              [106.81073484700009, 10.91786099000007],
              [106.80910988000002, 10.91989505800006],
              [106.80633527800003, 10.921468534000043],
              [106.78805478000007, 10.934077778000082],
              [106.78226763400006, 10.938358023000115],
              [106.78019073700008, 10.938800049000095],
              [106.77742511900004, 10.938783971000097],
              [106.77464866300009, 10.940584099000093],
              [106.77117530900003, 10.943288275000016],
              [106.76932062800006, 10.945093743000099],
              [106.76097144500007, 10.953672243000055],
              [106.760725876, 10.956168273000076],
              [106.76069295600011, 10.961617155000079],
              [106.760904248, 10.964797043000106],
              [106.76090150500005, 10.965251129000107],
              [106.76118619900006, 10.965820427000049],
              [106.76585610800001, 10.966244663000067],
              [106.76786722200006, 10.966427335000082],
              [106.77859554400007, 10.964787124000084],
              [106.78053683400003, 10.967014582000088],
              [106.78078174900004, 10.967097109000077],
              [106.78823591800005, 10.969608681000052],
              [106.79330201300009, 10.971752044000114],
              [106.79436341700007, 10.973079514000082],
              [106.79435642700003, 10.974268712000066],
              [106.79354598900002, 10.975717582000042],
              [106.792201714, 10.977031242000111],
              [106.78373474100003, 10.984910763000054],
              [106.78117801300006, 10.987803039000086],
              [106.77740331300002, 10.993198897000054],
              [106.77323103900004, 10.999215330000121],
              [106.77265240700004, 11.001989996000104],
              [106.77291014700005, 11.005880794000065],
              [106.77373491000007, 11.00894149],
              [106.78295572000006, 11.017885020000049],
              [106.78939315200006, 11.022367208000093],
              [106.79500243600009, 11.024344145000063],
              [106.80510847800005, 11.026346554],
              [106.80903014700009, 11.028591341000025],
              [106.81097489900006, 11.032491781000074],
              [106.81151929100007, 11.035550865000053],
              [106.81064874500007, 11.040268864000067],
              [106.80865712500004, 11.044424900000125],
              [106.80413414700008, 11.048844378000043],
              [106.79820396900004, 11.053533564000071],
              [106.79707150200005, 11.054916217000075],
              [106.79706172400005, 11.056583126000062],
              [106.79761248900004, 11.058531067000031],
              [106.80068979800002, 11.061049098000096],
              [106.81500444500004, 11.064742084000033],
              [106.82286868500005, 11.065897601000072],
              [106.82624659800003, 11.065083005000085],
              [106.82794586700003, 11.06286985100011],
              [106.83050896500001, 11.057049738000034],
              [106.83591578500008, 11.045411128000103],
              [106.83790686300006, 11.041254828000111],
              [106.84299261700005, 11.036559955000056],
              [106.85879046200003, 11.026089318000041],
              [106.86611749800009, 11.022517402000085],
              [106.87090121900003, 11.021431910000066],
              [106.87651735000003, 11.022295448000106],
              [106.88717260000004, 11.026797165000078],
              [106.89250340100004, 11.028492222000084],
              [106.89644250200008, 11.027679503000074],
              [106.90010947700009, 11.025198398000045],
              [106.90349532600001, 11.022715771000067],
              [106.90574362200006, 11.02272749100006],
              [106.90882615500003, 11.024410403000038],
              [106.90964859000005, 11.028304067000059],
              [106.90963306200008, 11.031224640000035],
              [106.90960280100006, 11.036916144000129],
              [106.91025134400009, 11.040081855000048],
              [106.91098006000007, 11.042201853000043],
              [106.91257098500007, 11.043860168000029],
              [106.91433958400003, 11.044719626000086],
              [106.92022881900006, 11.047250368000048],
              [106.92116959100009, 11.048595855000086],
              [106.92133410100003, 11.050867738000123],
              [106.92046601600006, 11.055586303],
              [106.92172085400006, 11.058179753],
              [106.92351821600009, 11.059642660000078],
              [106.92801311400004, 11.063681928000067],
              [106.93030751800006, 11.065359966000086],
              [106.93250047500005, 11.065649572000067],
              [106.93418643700002, 11.065115367000098],
              [106.93532008300012, 11.063996895000104],
              [106.93843368400007, 11.059845226000052],
              [106.93985205700002, 11.05735194300008],
              [106.94151583200001, 11.056958853000063],
              [106.94574626400001, 11.059048549000037],
              [106.94731291800005, 11.060688272000084],
              [106.94809684600007, 11.064260369000054],
              [106.94851011700007, 11.068230745000028],
              [106.947586137, 11.072583602000028],
              [106.944893854, 11.076072252000111],
              [106.94367847700008, 11.078486322000101],
              [106.9438559380001, 11.08287315000007],
              [106.94549549700004, 11.08909284500008],
              [106.94714770200005, 11.092867674000038],
              [106.94792556700003, 11.093967605],
              [106.94977047400008, 11.096576320000027],
              [106.955345112, 11.106050773000041],
              [106.95646264300011, 11.107445555000071],
              [106.95823430000006, 11.108584046000123],
              [106.96090534899999, 11.109324164000077],
              [106.96356424600008, 11.109337292000097],
              [106.96387409300004, 11.110429599000058],
              [106.96350523700008, 11.112722606000068],
              [106.9627755380001, 11.115719937000089],
              [106.96151528200001, 11.117655502000011],
              [106.9577496090001, 11.120461288000081],
              [106.954153472, 11.125033132000082],
              [106.95197098700008, 11.132613008000101],
              [106.95195763200005, 11.13632701900009],
              [106.95213382300005, 11.139414576000116],
              [106.95257938700006, 11.141520543000103],
              [106.95365189500006, 11.142886791000082],
              [106.95566862100004, 11.143223284000035],
              [106.96102919900002, 11.143073312000061],
              [106.96388144900003, 11.144323116000086],
              [106.9645907280001, 11.145385805000084],
              [106.96475868400005, 11.147505036000014],
              [106.96437056200008, 11.149239283000066],
              [106.96367333800004, 11.150147693000088],
              [106.96195285500006, 11.151784205000046],
              [106.95630415700005, 11.15876146300012],
              [106.95229577200004, 11.164023696000045],
              [106.95081743500002, 11.165152057000059],
              [106.94783383800007, 11.166795817000084],
              [106.94744791400002, 11.166891681000022],
              [106.94561233600002, 11.167347628000089],
              [106.94250106200008, 11.167519356000087],
              [106.94159831700009, 11.167989227000062],
              [106.94024056400011, 11.16856696800008],
              [106.93947588700007, 11.169311998000085],
              [106.93962417600008, 11.170061633000026],
              [106.94037956400005, 11.171113887000049],
              [106.9421931680001, 11.173519473000047],
              [106.94431191300008, 11.175776800000035],
              [106.94430497000003, 11.177124778000101],
              [106.94353795100008, 11.17831915600007],
              [106.9417026460001, 11.180107281000049],
              [106.93773039000007, 11.18349904400011],
              [106.93508720500003, 11.185776709],
              [106.93427291000008, 11.186669086000032],
              [106.93202557900003, 11.18900442400008],
              [106.92871180900002, 11.190825723000051],
              [106.92368127499999, 11.192447630000045],
              [106.91841768100005, 11.19405465700005],
              [106.91144650400003, 11.194371765000087],
              [106.90879151200009, 11.193762205000011],
              [106.90620342300009, 11.193174989000097],
              [106.90483930000002, 11.193454732000061],
              [106.90387230100004, 11.194663479000065],
              [106.90360071100012, 11.195324110000103],
              [106.90278568500001, 11.197350133000048],
              [106.90103928500011, 11.202262210000045],
              [106.90010340000003, 11.205986923000031],
              [106.89959345900003, 11.209426949000088],
              [106.89958421700007, 11.211148236000044],
              [106.9000718880001, 11.211856981000066],
              [106.90141012400007, 11.212261218000029],
              [106.90634680900006, 11.212353140000085],
              [106.90895735400005, 11.212962578000061],
              [106.91127504900005, 11.214033888000062],
              [106.91381335500002, 11.215635967000102],
              [106.91443419400005, 11.216521922000043],
              [106.91578327100004, 11.219110920000029],
              [106.91630637300005, 11.221585344000038],
              [106.91649658299998, 11.223638747000029],
              [106.91531851900004, 11.226722351000083],
              [106.91410451100003, 11.229043378000101],
              [106.91301110000008, 11.230516964000079],
              [106.91165608800006, 11.231245620000061],
              [106.91104647800006, 11.234165620000079],
              [106.90981319400007, 11.237645846000087],
              [106.90909133300002, 11.240132549000046],
              [106.90915107400008, 11.241230007000079],
              [106.9091835450001, 11.241826536000053],
              [106.90998757200002, 11.242926507000027],
              [106.91109795800006, 11.243529969000042],
              [106.91444074600005, 11.243148782000032],
              [106.92031591100007, 11.242481695000082],
              [106.92517297000005, 11.242905045000027],
              [106.92759733700009, 11.243913611000076],
              [106.92911167300007, 11.244718278000041],
              [106.92981770100005, 11.245219967000073],
              [106.92991269700008, 11.24641587300008],
              [106.92949527100009, 11.248804608000016],
              [106.92702858900002, 11.255865029000043],
              [106.92639819099999, 11.260245113000076],
              [106.92637418300005, 11.264827563000066],
              [106.92635442600002, 11.268131337000041],
              [106.92654387000006, 11.270622844000043],
              [106.92749155700004, 11.273799239000047],
              [106.92790946000007, 11.276145950000059],
              [106.92805543600007, 11.278996065000067],
              [106.92963863900006, 11.283911401000067],
              [106.93106845800006, 11.287401272000073],
              [106.93221984600011, 11.28936458100007],
              [106.93514693100009, 11.291172663000014],
              [106.93868007200004, 11.293282650000068],
              [106.93969110000002, 11.293586625000083],
              [106.94059983799998, 11.294089332000022],
              [106.94069698000003, 11.294886816000103],
              [106.93987557400001, 11.297074440000097],
              [106.93618627300002, 11.305524049000047],
              [106.93602454600006, 11.307416144000102],
              [106.93622293700004, 11.308214166000051],
              [106.93722835000008, 11.309614030000057],
              [106.93874320800002, 11.310418699000111],
              [106.94086927300008, 11.310529039000093],
              [106.94390827800008, 11.310345062000058],
              [106.94532805200004, 11.309953680000094],
              [106.94654785000007, 11.309063148000041],
              [106.94868873900002, 11.306284302000067],
              [106.95032325199999, 11.303502909000061],
              [106.95164424800004, 11.302612861000048],
              [106.95285836500005, 11.302818183000072],
              [106.95366133900005, 11.304216949000077],
              [106.95496047500006, 11.307610727000037],
              [106.95716294100005, 11.312603028000099],
              [106.95877258100009, 11.31470318200005],
              [106.96109265800004, 11.316507965000076],
              [106.96300970400003, 11.317912221000091],
              [106.96513083500002, 11.31901858100006],
              [106.96715674200004, 11.318928904000101],
              [106.97009557600002, 11.318544803000028],
              [106.97405309200009, 11.316970087000032],
              [106.97801252700005, 11.314996800000097],
              [106.98115779200005, 11.313816526000052],
              [106.98227123700008, 11.313921541000036],
              [106.98307791800002, 11.314622833000064],
              [106.98337678300007, 11.315620561000104],
              [106.98412197400008, 11.318513377],
              [106.98430179400003, 11.323097164000016],
              [106.98409035700004, 11.324889473000075],
              [106.98205004300007, 11.327868496000091],
              [106.9783932280001, 11.330042626000123],
              [106.976767841, 11.331031021000102],
              [106.97615620100001, 11.331825088000079],
              [106.97655234800001, 11.333620358000116],
              [106.97999379700011, 11.334035600000012],
              [106.98546279200008, 11.334062048000069],
              [106.99538312200005, 11.335105968000024],
              [107.00226227899999, 11.336732793000069],
              [107.00974152000003, 11.339956331000066],
              [107.01277033800008, 11.341963193000113],
              [107.01498224700005, 11.345361039000055],
              [107.0175936360001, 11.349956338000043],
              [107.01960409, 11.353153961000116],
              [107.02037595400002, 11.355086053000017],
              [107.02284582200009, 11.356337601000098],
              [107.028255964, 11.356815387000017],
              [107.03208066600008, 11.358588955000036],
              [107.03604536900012, 11.362900315000067],
              [107.03792983300008, 11.365708834000097],
              [107.037542414, 11.367387004000038],
              [107.03487074900001, 11.370548003000101],
              [107.0345358590001, 11.371984923000065],
              [107.03561048600002, 11.374657945000076],
              [107.03920215200002, 11.377660981000036],
              [107.04074413200006, 11.378204742],
              [107.04234668200004, 11.378155754000117],
              [107.04395089100008, 11.376749303000098],
              [107.04661554600003, 11.375081433000032],
              [107.0496553780001, 11.374348535000054],
              [107.053634554, 11.375673109000049],
              [107.05761035600007, 11.377744241000086],
              [107.05854979100008, 11.37980174600006],
              [107.05948239700007, 11.383352523000044],
              [107.05927547200008, 11.387084897000042],
              [107.05850431800005, 11.389694761000067],
              [107.05659613799999, 11.391926206000045],
              [107.05448765000006, 11.394619115000102],
              [107.05506778900011, 11.397952843000068],
              [107.05771762400006, 11.400885796000091],
              [107.06420925100009, 11.405004275000062],
              [107.06892420200003, 11.409406839],
              [107.07245558300011, 11.413804099000034],
              [107.07402508800003, 11.418337186000114],
              [107.07418760800006, 11.418724442],
              [107.07402187000011, 11.421727764000076],
              [107.07294116800004, 11.423806781000055],
              [107.07005549200002, 11.428915434000062],
              [107.06644115000003, 11.436846487000095],
              [107.06552483300005, 11.441610694000113],
              [107.06569004800005, 11.44461368800007],
              [107.06710370500005, 11.448505251000094],
              [107.06888249600004, 11.450808991000104],
              [107.07880690600008, 11.45930770100003],
              [107.08496833500007, 11.464787330000091],
              [107.09249443300007, 11.46968404400006],
              [107.09398717100004, 11.471262179000073],
              [107.09470625600002, 11.472718735000063],
              [107.09484535500007, 11.474115906000025],
              [107.09479127900005, 11.475514455000011],
              [107.09436069100003, 11.47825032400007],
              [107.09437053600004, 11.479584872000091],
              [107.09489835700002, 11.48129701400009],
              [107.09587318800004, 11.482497512000032],
              [107.09723065200009, 11.483186843000047],
              [107.09800339700007, 11.48318128100003],
              [107.09941820900008, 11.482916888000092],
              [107.10115358600004, 11.482459518000086],
              [107.10353053000007, 11.48167975800005],
              [107.1069735180001, 11.481368922000089],
              [107.11000193600003, 11.481601234000037],
              [107.11193755100004, 11.482095656000089],
              [107.11465726300001, 11.484109615000103],
              [107.11808868800007, 11.486563246000079],
              [107.11932216100007, 11.487888882000073],
              [107.12832462100002, 11.498976609000076],
              [107.13001854300003, 11.501569842000057],
              [107.13032039900004, 11.502154708000132],
              [107.13442225800006, 11.503540919000075],
              [107.14644066900007, 11.506907067000059],
              [107.15291049300008, 11.508495626000046],
              [107.15679117400001, 11.509285056000083],
              [107.16732559200005, 11.511570377000078],
              [107.17342448400009, 11.51288848300007],
              [107.1739299970001, 11.513012038000079],
              [107.17634703400006, 11.513523693000048],
              [107.24017297200002, 11.527023961000092],
              [107.24790015900007, 11.528048628000052],
              [107.25522713600003, 11.529713330000034],
              [107.33478667800009, 11.547771465000077],
              [107.33504083700007, 11.547273960000036],
              [107.33690828800003, 11.545161799000068],
              [107.33893896200001, 11.544543445000064],
              [107.34149177800002, 11.544046545000073],
              [107.34435418000007, 11.544055479000102],
              [107.3456395470001, 11.5449067040001],
              [107.34563593600006, 11.546036326000129],
              [107.34568339500001, 11.549105330000129],
              [107.34575875200002, 11.552390932000021],
              [107.3461848000001, 11.553677449000064],
              [107.34690126200005, 11.554765159000089],
              [107.34917055600005, 11.556577971000069],
              [107.35046532000007, 11.557630121000082],
              [107.35203437900003, 11.559329438000086],
              [107.35345810100006, 11.561734335000027],
              [107.35416262300001, 11.565266685000084],
              [107.35457466800008, 11.570775072000037],
              [107.3549871700001, 11.576142285000033],
              [107.35582382000005, 11.581212120000115],
              [107.35639373700005, 11.582061128000108],
              [107.35654176000003, 11.582501500000129],
              [107.35711301400005, 11.582926878000064],
              [107.360119109, 11.582936035000023],
              [107.361697249, 11.581811140000029],
              [107.36266696900005, 11.580124479000096],
              [107.36467557300006, 11.575093657000044],
              [107.36526174700006, 11.569685468000033],
              [107.36517639900011, 11.566607146000061],
              [107.365191, 11.563040907000042],
              [107.3651945030001, 11.561911260000011],
              [107.36405383700004, 11.560495742000041],
              [107.36176681799999, 11.559500378000019],
              [107.35976651700004, 11.558364652000106],
              [107.35905396400005, 11.557374034000121],
              [107.35920063100012, 11.556244831000038],
              [107.36078121600006, 11.554272752000115],
              [107.36541274900003, 11.551590813000077],
              [107.36731285200004, 11.550850347000095],
              [107.36902287500004, 11.550202571000055],
              [107.37152961100006, 11.549786544000094],
              [107.37339036, 11.549376165000067],
              [107.37468006200005, 11.549231102000055],
              [107.37540344500005, 11.54839266800005],
              [107.37626130400005, 11.546976520000028],
              [107.37642581200008, 11.546462578000048],
              [107.37698378800006, 11.544719392000056],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 20, code: "AD01", ten_tinh: "Đồng Tháp" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.44962844, 10.955906315000073],
              [105.45153978400005, 10.955731533000089],
              [105.45301687300005, 10.956276688000075],
              [105.45414230199999, 10.956856582000094],
              [105.45483218700005, 10.957655814000054],
              [105.45877659400001, 10.962053404000114],
              [105.46184719600006, 10.964364322000096],
              [105.464035326, 10.965280488000049],
              [105.46643072900005, 10.965605976000068],
              [105.46912967900008, 10.965638508],
              [105.47142999900007, 10.96556734800007],
              [105.47353770400011, 10.964900684000066],
              [105.47535037500008, 10.963835010000087],
              [105.47645478400005, 10.96294073200008],
              [105.47816280899998, 10.959705894000125],
              [105.48012291200007, 10.955970870000085],
              [105.48108698000009, 10.954364984000085],
              [105.48259667700003, 10.953103089000125],
              [105.48626130500001, 10.951461836000041],
              [105.49092741400008, 10.950283848000096],
              [105.49375577400008, 10.949012556000069],
              [105.49494419000008, 10.948478371000101],
              [105.49707872200004, 10.954289650000069],
              [105.49763023200011, 10.953935479000075],
              [105.51227609400007, 10.930288396000075],
              [105.51392827600009, 10.927828861000053],
              [105.51425646600006, 10.926026700000067],
              [105.51408688100007, 10.923734016000084],
              [105.53097232000007, 10.918300326000095],
              [105.55617911800007, 10.905741267000034],
              [105.581441946, 10.893149947000056],
              [105.59190196600011, 10.870547295000053],
              [105.59788409800009, 10.858582060000098],
              [105.59958091200006, 10.855202696000086],
              [105.60524532600004, 10.843857397000088],
              [105.61067840900009, 10.833056314000087],
              [105.59823032600005, 10.832071766000071],
              [105.59748899100001, 10.832013108000103],
              [105.593932647, 10.831725452000017],
              [105.64594725700005, 10.783594688000074],
              [105.66781866100011, 10.76325749000002],
              [105.68924142200009, 10.743331953000011],
              [105.68977860500004, 10.742832244000054],
              [105.70475936900002, 10.728895141000104],
              [105.70791781400006, 10.725956395000045],
              [105.71896243200004, 10.71567909800004],
              [105.72178673300004, 10.713046212000064],
              [105.72605812900005, 10.709064128000099],
              [105.73190501500004, 10.703612913000081],
              [105.74474904100005, 10.6916366410001],
              [105.74493706100006, 10.69146130600007],
              [105.75307901900005, 10.68382726500001],
              [105.76347118800005, 10.674082293000085],
              [105.76474766800007, 10.672885220000071],
              [105.77716819900004, 10.661236319000087],
              [105.77897859600009, 10.660086528000077],
              [105.78112954100007, 10.659685142000084],
              [105.7997006320001, 10.656218636000057],
              [105.80464903700008, 10.655123424000033],
              [105.8205364410001, 10.646550964000044],
              [105.82624234400005, 10.643191799000105],
              [105.8319698170001, 10.639725429000089],
              [105.83255684700001, 10.639377227000056],
              [105.83467589400001, 10.636608571000037],
              [105.836192576, 10.634621476000026],
              [105.83773667400004, 10.632613913000098],
              [105.83927518200005, 10.630580870000104],
              [105.84236315200012, 10.626519843],
              [105.84352012400009, 10.625000165000118],
              [105.85075495100008, 10.615326756000075],
              [105.85228612200002, 10.61320818500011],
              [105.85536651100008, 10.608896195000026],
              [105.85688898600002, 10.606748448000046],
              [105.85838803500006, 10.604671938000097],
              [105.85988712100001, 10.602611851000086],
              [105.8614038530001, 10.600540764000053],
              [105.862932341, 10.598458687000088],
              [105.86447257500008, 10.596365613000012],
              [105.86603047800001, 10.594267013000101],
              [105.86760014300003, 10.592162899000089],
              [105.86913445100006, 10.590080784000031],
              [105.8706451460001, 10.588004190000076],
              [105.87217349499998, 10.585916594000098],
              [105.87446369200003, 10.583024575000087],
              [105.90735864100003, 10.540348886000038],
              [105.90827359300008, 10.539464898000082],
              [105.94020369100009, 10.537374696000066],
              [105.94102263000008, 10.537294439000087],
              [105.94095900800005, 10.511429909000112],
              [105.94006767500011, 10.49570725200004],
              [105.93979404900003, 10.495421754000043],
              [105.93653416200004, 10.492092594000011],
              [105.89843273100011, 10.451995785000088],
              [105.89137737000006, 10.444533157000089],
              [105.85793796800004, 10.409154119000073],
              [105.85780868900008, 10.408793992000119],
              [105.84282152800003, 10.367037126000065],
              [105.83424224000001, 10.370665609000083],
              [105.8148320670001, 10.371593679000044],
              [105.81402711600002, 10.371632128000028],
              [105.81420114800002, 10.368511689000036],
              [105.81469746100007, 10.363140698000082],
              [105.81518272900007, 10.357889011000053],
              [105.81539734000006, 10.355566365000078],
              [105.81690470000008, 10.345325355000055],
              [105.81738470900009, 10.336574071000102],
              [105.81519723000011, 10.323632348000087],
              [105.81744074900011, 10.323017271000088],
              [105.82567273200009, 10.32076018100012],
              [105.82753619900008, 10.319474913000091],
              [105.82782228200004, 10.319047351000064],
              [105.82063162000007, 10.314939646000097],
              [105.81991141200008, 10.314087809000048],
              [105.81976414100006, 10.312665409000131],
              [105.8200480200001, 10.311384192000082],
              [105.82234112600003, 10.309671033000088],
              [105.82506536500001, 10.30809904200005],
              [105.82948274700003, 10.307895478000082],
              [105.83281497100005, 10.306371850000049],
              [105.83381676900007, 10.305088767000113],
              [105.83652926700006, 10.299106124000089],
              [105.83904190100003, 10.299028473000059],
              [105.85454536, 10.297422863000081],
              [105.86373037200005, 10.295691162000063],
              [105.8720502290001, 10.292681061000051],
              [105.87620792700002, 10.290393419000042],
              [105.88416004000001, 10.284325090000033],
              [105.89217104000009, 10.276922649000129],
              [105.89115065500005, 10.275938766000079],
              [105.888051015, 10.272771511000121],
              [105.88690424600006, 10.271530085000078],
              [105.88627660499999, 10.270896067000111],
              [105.88587687000005, 10.270546785000077],
              [105.88496984500006, 10.26965979000005],
              [105.88373195700004, 10.268708790000057],
              [105.88246362400008, 10.267647983000037],
              [105.88116146500008, 10.266805813000078],
              [105.88010916200005, 10.266346020000048],
              [105.87979130100004, 10.266218725000085],
              [105.87947278400006, 10.266107243000098],
              [105.879059881, 10.266017917000079],
              [105.87613641100006, 10.265550545000044],
              [105.87520414200002, 10.265432577000054],
              [105.88312275599999, 10.255103829000054],
              [105.88875311, 10.245137436000016],
              [105.90020434800006, 10.245549193000068],
              [105.90005650900008, 10.2352036460001],
              [105.9001039930001, 10.234867471000085],
              [105.90029680100007, 10.234530900000037],
              [105.90143298900003, 10.233783681000117],
              [105.90554244400005, 10.231035991000057],
              [105.90955495200004, 10.228288531000105],
              [105.91034796400001, 10.227468319000097],
              [105.91083476700007, 10.226964819],
              [105.9116048330001, 10.225234489000099],
              [105.91293534800005, 10.221700311000069],
              [105.91328734400004, 10.220765313000067],
              [105.91482657700003, 10.217016621000051],
              [105.91622473800007, 10.214756462000095],
              [105.9173359820001, 10.213793240000067],
              [105.91787734600004, 10.211943825000079],
              [105.91601900900011, 10.206503649000085],
              [105.91157692200009, 10.193498656000076],
              [105.89743620600008, 10.192702306000088],
              [105.89356216000009, 10.186954530000046],
              [105.88732846400008, 10.190204698000025],
              [105.88442708700009, 10.190246993000061],
              [105.87449176100004, 10.190391520000029],
              [105.87499925000003, 10.183362822000051],
              [105.86821519400004, 10.169840569000053],
              [105.87241576200005, 10.167303539000015],
              [105.86880257200005, 10.155336689000103],
              [105.86678773300005, 10.155115581000105],
              [105.86450175, 10.154864694000041],
              [105.86193042100007, 10.155951465000078],
              [105.84963393800005, 10.161147359000021],
              [105.84885433500003, 10.158258543],
              [105.84697077000006, 10.150981276000069],
              [105.84478739200006, 10.150986908000091],
              [105.84190145400002, 10.150952589],
              [105.83667156100002, 10.150839705000079],
              [105.83389726400006, 10.150815195000094],
              [105.82979885500006, 10.150494127000085],
              [105.82947973600004, 10.150400235000044],
              [105.82912888600006, 10.15036954900008],
              [105.828331695, 10.150371563000069],
              [105.82609886900008, 10.150050158000033],
              [105.82566607700007, 10.150040036000084],
              [105.82447232200006, 10.150002487000085],
              [105.82365898100009, 10.149925611000031],
              [105.82107640500001, 10.150058354000089],
              [105.816502332, 10.150514563000089],
              [105.81439900100006, 10.151255328],
              [105.81208073800009, 10.152128440000039],
              [105.80688750800002, 10.153940581000052],
              [105.80401271700001, 10.15497775200007],
              [105.80112930100009, 10.155979176000061],
              [105.79955032800009, 10.156479626000079],
              [105.79749804700002, 10.158996422000131],
              [105.79685304700003, 10.159780581000087],
              [105.78002903200004, 10.181608404000107],
              [105.77892587100007, 10.183022419000041],
              [105.77517475800003, 10.187830371000038],
              [105.77402520300004, 10.189357278000076],
              [105.77342870300006, 10.190148091000049],
              [105.77308929500006, 10.19058201500007],
              [105.76294597100012, 10.179523707000014],
              [105.75752498700002, 10.183663664000074],
              [105.75711942700005, 10.184188076000032],
              [105.75548674300005, 10.186064368000112],
              [105.7543021800001, 10.18775239800007],
              [105.75259239800002, 10.190233958000048],
              [105.75162040900007, 10.19151675500005],
              [105.75090090200004, 10.192529875000032],
              [105.74905025900004, 10.194928224000053],
              [105.74878861900007, 10.195300005000083],
              [105.74694699000001, 10.197559135000043],
              [105.74633467200006, 10.198212263000082],
              [105.74615587200002, 10.198402978000052],
              [105.74573272400008, 10.197848297000077],
              [105.73712116500006, 10.201358208000054],
              [105.7302676260001, 10.204072799000039],
              [105.72848656200009, 10.204899883000083],
              [105.725989951, 10.206036679000084],
              [105.72387631500001, 10.203145410000046],
              [105.72316820600004, 10.20220416500006],
              [105.72093426600003, 10.198838879000059],
              [105.71364212900002, 10.188160864000027],
              [105.71334668900009, 10.187805039000073],
              [105.71098215100002, 10.184504756000122],
              [105.70947128100006, 10.182304412000052],
              [105.70372391700006, 10.174053232000089],
              [105.69991418700005, 10.168487529000101],
              [105.69931489200012, 10.167322642000086],
              [105.69649744800006, 10.162920917000038],
              [105.69469150900002, 10.160417922000063],
              [105.69131890100005, 10.155388393000029],
              [105.6904608400001, 10.154029134],
              [105.68812973500005, 10.150726243000106],
              [105.6824206040001, 10.142489905000039],
              [105.68385096700004, 10.139281296000123],
              [105.68084312000005, 10.13504828000009],
              [105.66198138800004, 10.147019280000078],
              [105.6601252020001, 10.148559039000114],
              [105.6572675840001, 10.152141188000117],
              [105.65456132400001, 10.155533472000043],
              [105.64982822100008, 10.161406441000052],
              [105.64703922400005, 10.164866928000025],
              [105.64023411400011, 10.171331589000053],
              [105.62682848300008, 10.183645709000098],
              [105.62301441100007, 10.187953877000043],
              [105.62075149000006, 10.193078258000108],
              [105.61757427200008, 10.206294102000067],
              [105.61551935900005, 10.212237273000111],
              [105.61501613600007, 10.21338512600005],
              [105.6116102930001, 10.221153566000039],
              [105.60769578000003, 10.227304994000084],
              [105.60507947800001, 10.230673649],
              [105.60243902900002, 10.234073270000097],
              [105.59810952400009, 10.239508555000041],
              [105.58645535300012, 10.251408398000093],
              [105.57875045500006, 10.258832129000071],
              [105.57655275300003, 10.260949500000025],
              [105.56561655000004, 10.270696938000066],
              [105.5562352470001, 10.279670286000096],
              [105.5547840830001, 10.281058270000097],
              [105.54763836200004, 10.286838236000049],
              [105.54095529100006, 10.292243515000086],
              [105.53083856200001, 10.298916429000114],
              [105.52185588100011, 10.304153566000094],
              [105.51824098000006, 10.305593022],
              [105.51441934600004, 10.307032768000022],
              [105.51194139900009, 10.308572745000076],
              [105.49450250800004, 10.326213199000021],
              [105.49295568300005, 10.327878608000061],
              [105.48583479700008, 10.335545034000047],
              [105.48914898600003, 10.339840943000043],
              [105.48935762000005, 10.341069465000084],
              [105.48881335300005, 10.343186464],
              [105.48850819000003, 10.343493208000075],
              [105.48867878700003, 10.345742326000094],
              [105.48954241600006, 10.347282883000018],
              [105.49193947800008, 10.349716206],
              [105.49782874000002, 10.355251681000064],
              [105.49936490000002, 10.355925829000066],
              [105.50764754000009, 10.36027550400007],
              [105.51427259400006, 10.363754343000052],
              [105.51910577300002, 10.364345199000089],
              [105.51875237100009, 10.365987216000059],
              [105.51847440700001, 10.367911464000111],
              [105.51854413300001, 10.369169383],
              [105.51878062300003, 10.370680693000088],
              [105.519616489, 10.371780070000101],
              [105.52071313200005, 10.372379690000065],
              [105.52341685500002, 10.373501133000039],
              [105.52577595300009, 10.37503750600006],
              [105.527023285, 10.377231203000024],
              [105.5270051360001, 10.378739528000034],
              [105.52698093600004, 10.380750622000077],
              [105.52493325400009, 10.388604444000025],
              [105.52365870900006, 10.395713188000054],
              [105.52242146500001, 10.399721458000053],
              [105.52223648000006, 10.400976431000062],
              [105.52256906200004, 10.401567029000079],
              [105.52315841400005, 10.401993048000067],
              [105.52630799000005, 10.401235779000027],
              [105.52704020300007, 10.401269346000051],
              [105.527949682, 10.401607479000051],
              [105.52908323100009, 10.403655355000028],
              [105.52947758400003, 10.406174337000083],
              [105.52970915900001, 10.40810472700011],
              [105.53003673800002, 10.409114322000066],
              [105.53154410600004, 10.41089211900008],
              [105.53499758000005, 10.413279475000079],
              [105.53761600300008, 10.414483616000057],
              [105.540080663, 10.414344919000127],
              [105.54280207800009, 10.414041578000075],
              [105.54441253000003, 10.414311875000058],
              [105.54753854200004, 10.415689452],
              [105.54947769900008, 10.416885504000016],
              [105.55031191900009, 10.418152433000037],
              [105.55055669000009, 10.418993428000077],
              [105.55020601700004, 10.41991126700008],
              [105.54858950600004, 10.420143819000048],
              [105.54595094100004, 10.420615835000111],
              [105.54484095100004, 10.421105720000055],
              [105.54432842000008, 10.421351162000024],
              [105.54473900500004, 10.422529353000074],
              [105.54691798000003, 10.424985455000078],
              [105.54774221700011, 10.427090432000073],
              [105.54958770500004, 10.431374119000035],
              [105.55071782500002, 10.433874365000049],
              [105.552045445, 10.435671544000067],
              [105.55983053900003, 10.432207598000074],
              [105.56387629100003, 10.430820293000041],
              [105.56752026300008, 10.430316584000108],
              [105.56803829900005, 10.430244970000045],
              [105.56974645700006, 10.430499660000068],
              [105.57154668200008, 10.430769160000073],
              [105.57208441200007, 10.430849497000059],
              [105.57131846400002, 10.437455701000074],
              [105.57096230100004, 10.439957199000014],
              [105.57168789500003, 10.442218591000072],
              [105.57397864400012, 10.445548970000027],
              [105.57398301200004, 10.447930737000098],
              [105.57279363400004, 10.455078197000132],
              [105.57161446800009, 10.467822816000092],
              [105.57090361900006, 10.473659441000025],
              [105.5693764280001, 10.478157563000055],
              [105.56815248700008, 10.481762381000069],
              [105.56204527800006, 10.496182960000054],
              [105.55808470600009, 10.504913853000096],
              [105.55612809300003, 10.509226856000085],
              [105.55593580700001, 10.509650719000073],
              [105.55509479800006, 10.511100073000089],
              [105.55233871300001, 10.51584963500005],
              [105.5518523800001, 10.516580345000113],
              [105.54963902800002, 10.519905818000018],
              [105.5471806250001, 10.523599338000087],
              [105.54285924000003, 10.528489406000039],
              [105.53495439100004, 10.535002514000078],
              [105.53301037200004, 10.536604168000084],
              [105.52784478100003, 10.540423677000097],
              [105.5248401310001, 10.541857744000092],
              [105.51197945200005, 10.547714181000066],
              [105.51005566500004, 10.548193642000035],
              [105.50836813800004, 10.546052752],
              [105.50560275500001, 10.546771716000068],
              [105.49886733200006, 10.547139679000049],
              [105.49137622700002, 10.544771168000098],
              [105.48574963400004, 10.542991966000058],
              [105.48225397600001, 10.538233719000088],
              [105.47972471800009, 10.536093953000057],
              [105.47862544700007, 10.535914264000022],
              [105.478280974, 10.53585795400007],
              [105.46702147100008, 10.540995385000061],
              [105.463616551, 10.542548804000072],
              [105.45291956199999, 10.548280627000038],
              [105.44510809200008, 10.553293506000026],
              [105.44354502200008, 10.553772058],
              [105.43861315800004, 10.553778941000045],
              [105.43079285400002, 10.552717881000094],
              [105.42116793400008, 10.551420849000069],
              [105.417799524, 10.551187133],
              [105.414913068, 10.551548212000037],
              [105.41262853400008, 10.552265742000076],
              [105.410344455, 10.553340535000098],
              [105.40818128299999, 10.554891526000086],
              [105.40702949899999, 10.556003054000128],
              [105.39953114400006, 10.563238936000106],
              [105.39700833200004, 10.565862103000077],
              [105.39388371300006, 10.568247849000032],
              [105.39075842700002, 10.570157174000093],
              [105.38691087600009, 10.571710094000062],
              [105.38366374300008, 10.572428608000093],
              [105.37367990600004, 10.573155138000077],
              [105.37139510400007, 10.573872381000092],
              [105.36863023300002, 10.575542897000021],
              [105.36634808800002, 10.578522833000113],
              [105.36585413900009, 10.579503681000089],
              [105.36310755400004, 10.584957494000056],
              [105.35686807100008, 10.599017371000039],
              [105.356508628, 10.599794127000093],
              [105.35074740200008, 10.61224337900004],
              [105.34855244600001, 10.616977257000125],
              [105.34726677800005, 10.619749972000124],
              [105.34683773100008, 10.620910864000075],
              [105.34378812600008, 10.629161985000033],
              [105.34091219900007, 10.639764222000013],
              [105.33948004000005, 10.650364864000037],
              [105.33792967900003, 10.662990174000074],
              [105.33733838400005, 10.672398979000073],
              [105.33782367300006, 10.675971180000053],
              [105.33879060800004, 10.679781041000068],
              [105.34313501400004, 10.690613550000093],
              [105.343817223, 10.693453301000131],
              [105.34387696100006, 10.6937019630001],
              [105.34439367500009, 10.695852762000067],
              [105.34579512600006, 10.701686034000078],
              [105.34655420700007, 10.704676345000051],
              [105.34966376400004, 10.716925280000032],
              [105.34930392500011, 10.717997499000107],
              [105.34878106400002, 10.720096339000039],
              [105.34754429900001, 10.722543839000041],
              [105.34490422200005, 10.722336392000019],
              [105.34108107600009, 10.724497360000028],
              [105.3350343160001, 10.725967240000054],
              [105.32968009200002, 10.725678257000125],
              [105.31921511200002, 10.723556438000069],
              [105.30853872500005, 10.720547641000076],
              [105.29918443300002, 10.718880937000018],
              [105.28953155300007, 10.718705271000049],
              [105.2806146300001, 10.7219552290001],
              [105.27633559300008, 10.724771927000097],
              [105.2745205890001, 10.726957748000119],
              [105.27447952900008, 10.73005035600003],
              [105.27467048500006, 10.732483175000061],
              [105.27730808700008, 10.735610578000097],
              [105.28438689300003, 10.740562990000056],
              [105.29701487300011, 10.748017123000063],
              [105.30076630900008, 10.751379576000096],
              [105.30205638000007, 10.755152258000054],
              [105.30178958700002, 10.758463047000076],
              [105.3006442970001, 10.760657813000039],
              [105.29861179600007, 10.762399270000065],
              [105.29680832500007, 10.763701758000098],
              [105.29211128000001, 10.764304073000106],
              [105.28585142700005, 10.764886094000035],
              [105.27580207700008, 10.764976875000086],
              [105.27110494100002, 10.765578730000056],
              [105.26774142200007, 10.766639740000093],
              [105.26449463600002, 10.768510295000054],
              [105.26427127000001, 10.768638977000043],
              [105.26030027400007, 10.772066581000049],
              [105.25848468000004, 10.774252419000073],
              [105.25730046200002, 10.779318927000057],
              [105.25678601200009, 10.784394211000057],
              [105.25910675400009, 10.792234939000076],
              [105.25982439000005, 10.794064998000062],
              [105.25981161500005, 10.796400536000037],
              [105.25910594700011, 10.798591053000093],
              [105.25714550700008, 10.802097797000075],
              [105.25474732800008, 10.804276059000093],
              [105.2538941430001, 10.804558162000076],
              [105.2534776750001, 10.804695864000012],
              [105.25114827600001, 10.805466056000055],
              [105.24475101800004, 10.805471205000037],
              [105.24094811599998, 10.805474174000016],
              [105.23654836200011, 10.805873260000086],
              [105.232149201, 10.80706365600008],
              [105.22955048600001, 10.80884618700013],
              [105.22539336600005, 10.815917907000115],
              [105.22195972900008, 10.821711542000044],
              [105.22036264400001, 10.825867391000086],
              [105.21916743600005, 10.832594903000102],
              [105.21822120900001, 10.834352793000066],
              [105.21756940799999, 10.835563683000069],
              [105.20937353500007, 10.842889556000033],
              [105.20177523500004, 10.847049331000045],
              [105.19677536100006, 10.848437460000058],
              [105.19583230400011, 10.84911673300004],
              [105.19457602799999, 10.850021600000035],
              [105.19057887200002, 10.855563706000025],
              [105.18878089100011, 10.85932383600001],
              [105.18838303600006, 10.862885270000076],
              [105.18801891700004, 10.87277383800007],
              [105.18798963600001, 10.87356902500005],
              [105.18879436500006, 10.883439919000091],
              [105.1891992920001, 10.888406492000067],
              [105.18900435200004, 10.896518163000033],
              [105.18761207900003, 10.909576617000114],
              [105.18718762100009, 10.912704181000075],
              [105.18712386600005, 10.913173943000041],
              [105.19809366200008, 10.913320564000093],
              [105.20445202400001, 10.909371284000089],
              [105.21865109600004, 10.901491852000065],
              [105.23429255700006, 10.896564308000032],
              [105.2480106520001, 10.89674450200002],
              [105.26132864800007, 10.899118906000103],
              [105.26385648000006, 10.896066658000068],
              [105.26434033200005, 10.895667273000063],
              [105.26784196300004, 10.893184977000113],
              [105.27170854000005, 10.890707365000106],
              [105.27373065600003, 10.889650257000126],
              [105.28141320800009, 10.888485910000066],
              [105.28581514300006, 10.886917797000082],
              [105.29190943100009, 10.883051150000076],
              [105.30808085299999, 10.874904872000089],
              [105.3111629580001, 10.873352454000072],
              [105.31139173400003, 10.873233188000063],
              [105.33011428500006, 10.86347154400009],
              [105.33452703700004, 10.86099985300004],
              [105.33563606600006, 10.859930603000032],
              [105.3360830230001, 10.858311139000064],
              [105.34663103200003, 10.873797849000065],
              [105.34677548500008, 10.874009916000114],
              [105.35082805900001, 10.87962759000005],
              [105.357647586, 10.88775362800005],
              [105.35914691000006, 10.888219162000022],
              [105.36020046000002, 10.888232406000052],
              [105.36261431900007, 10.887816056000064],
              [105.3645670920001, 10.888138353000086],
              [105.36621312100004, 10.888903462000064],
              [105.36665509500001, 10.889653465000036],
              [105.36722273900008, 10.892340648000063],
              [105.36717689000002, 10.895913521000098],
              [105.36730066000005, 10.897999587000038],
              [105.36862476100001, 10.900398508000107],
              [105.36991578000007, 10.902307453000047],
              [105.37126277100005, 10.904738326000095],
              [105.37294497300006, 10.904910248000109],
              [105.37480344900008, 10.903273858000071],
              [105.37680342100001, 10.902544463000117],
              [105.37832672700002, 10.903166991000111],
              [105.37982114500002, 10.906052276000098],
              [105.38113939300004, 10.910745891000049],
              [105.38200798600005, 10.914679529000091],
              [105.382603112, 10.916044853000082],
              [105.38395284600003, 10.918425533000063],
              [105.38454933, 10.919539301000023],
              [105.38651867700005, 10.921223487000107],
              [105.38864501900004, 10.922607843000074],
              [105.39580512900005, 10.925563453000018],
              [105.40035855700003, 10.928788354000039],
              [105.40382929900007, 10.930727061000043],
              [105.40890944200002, 10.944638196000065],
              [105.41123531500008, 10.948298469000049],
              [105.42344452000006, 10.973288671000068],
              [105.42373944400003, 10.973687739000047],
              [105.42417860400005, 10.974106512000057],
              [105.42628489200007, 10.974429287000119],
              [105.429097, 10.974562706000055],
              [105.43091054899999, 10.974188942000039],
              [105.43193142700008, 10.972914565000023],
              [105.43377839000004, 10.969868484000054],
              [105.43724894000005, 10.965555377000056],
              [105.440922754, 10.961046738000045],
              [105.44437204400002, 10.958416079000076],
              [105.44962844, 10.955906315000073],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 21, code: "AD01", ten_tinh: "Gia Lai" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.39731318100007, 14.600596046000101],
              [108.39973462400005, 14.599623034000063],
              [108.40265036400007, 14.597256851000038],
              [108.40581154700006, 14.593376913000087],
              [108.40816644800003, 14.590285363000087],
              [108.40989897000003, 14.586710751000085],
              [108.41158674400006, 14.582415278000036],
              [108.41271463500003, 14.576605002000063],
              [108.41428535300005, 14.573014614000089],
              [108.41656035100007, 14.570874853000058],
              [108.42015084500007, 14.568988728000052],
              [108.42522963000009, 14.56616003600004],
              [108.43083080000008, 14.561707333000038],
              [108.43485691900004, 14.558709713000082],
              [108.44046176400008, 14.556135830000104],
              [108.44484241200007, 14.555016371000116],
              [108.45036449900009, 14.554748538000071],
              [108.45456541500003, 14.554130845000122],
              [108.45544712100008, 14.553969029000056],
              [108.46061580200006, 14.552505910000056],
              [108.46990103100006, 14.549581621000039],
              [108.4779116500001, 14.547083283000093],
              [108.48625542500008, 14.545293262000046],
              [108.49352511000002, 14.544817363000016],
              [108.49904649300007, 14.545198096000076],
              [108.50611960300009, 14.546886966000015],
              [108.50841055200002, 14.547668695000125],
              [108.50982797400005, 14.549108467000059],
              [108.51091360200007, 14.552451383000074],
              [108.51212977100008, 14.554154019000086],
              [108.51388139400005, 14.554608993000071],
              [108.517718261, 14.554337313000064],
              [108.52115303900007, 14.554788133000061],
              [108.5258702510001, 14.556482102000066],
              [108.52728854400003, 14.55818415600003],
              [108.52820610900001, 14.561106502000063],
              [108.52862501300004, 14.572597946000075],
              [108.529676577, 14.578152152000088],
              [108.53240846300008, 14.581807803000045],
              [108.54007027700004, 14.586840203000049],
              [108.5445741070001, 14.589892546],
              [108.54553707000011, 14.589654468000022],
              [108.54732536600001, 14.587387624000046],
              [108.54932864200003, 14.586559834000033],
              [108.55312965400003, 14.586961323000123],
              [108.55809120300003, 14.587154050000082],
              [108.56083215800007, 14.585912893000062],
              [108.56451644200008, 14.582304118000076],
              [108.56733869, 14.580481421000078],
              [108.57039092300003, 14.577388388000132],
              [108.57280832700003, 14.573782866000061],
              [108.57712924900005, 14.571508962000033],
              [108.58292827400008, 14.569436583000071],
              [108.58999249500006, 14.566949243000058],
              [108.59441711400005, 14.564160562000071],
              [108.59588589500008, 14.561174409000087],
              [108.59576884600007, 14.557267231000102],
              [108.59480630300001, 14.552951107000032],
              [108.59437539900006, 14.549970269000044],
              [108.59521239900005, 14.547500047000115],
              [108.59826036099999, 14.543275533000076],
              [108.60330646700007, 14.536783123000061],
              [108.60509611000008, 14.535338451000078],
              [108.608680581, 14.534197139000058],
              [108.61110735000007, 14.534087366000035],
              [108.61248167000009, 14.534906043000094],
              [108.61544827400006, 14.538804971000017],
              [108.61851738400007, 14.541675286],
              [108.62158278700004, 14.543311625000042],
              [108.62615821100007, 14.545373495000112],
              [108.62698013500004, 14.538352772000083],
              [108.62851819300002, 14.534028811000075],
              [108.6303923400001, 14.530891693000035],
              [108.63248906500004, 14.528077857000097],
              [108.63270528300008, 14.526349445000085],
              [108.63147993300004, 14.524301356000089],
              [108.62836308200006, 14.519775187000048],
              [108.628468524, 14.518047111000064],
              [108.62956922100003, 14.515668192000051],
              [108.63387312700007, 14.510040219000063],
              [108.63486189400008, 14.507337660000095],
              [108.63418996200008, 14.505071978000037],
              [108.63196481000008, 14.502163004000044],
              [108.63029439800007, 14.499468345000109],
              [108.63050622900002, 14.496336176000034],
              [108.63193545600006, 14.49276846600007],
              [108.63392368600007, 14.490818840000051],
              [108.6366880950001, 14.489082860000034],
              [108.63767978599999, 14.487352158000098],
              [108.63844748700009, 14.484866230000113],
              [108.64042361, 14.479137163000109],
              [108.64074673400009, 14.476220636000024],
              [108.63984917600008, 14.472659884000015],
              [108.63827264300002, 14.464565880000089],
              [108.63825185900008, 14.457978988000074],
              [108.63868507600004, 14.454846196000036],
              [108.63760840000003, 14.452421779000078],
              [108.63468296000011, 14.450322893000054],
              [108.63202005500004, 14.448927045000024],
              [108.62825282600009, 14.448614234000077],
              [108.62249538800005, 14.449494995000032],
              [108.61917686500001, 14.45101641600006],
              [108.61697030600004, 14.45393834500006],
              [108.61587112200004, 14.456749071],
              [108.61465554100002, 14.457724429000061],
              [108.61188646300005, 14.457840385000063],
              [108.6098872850001, 14.456118394000049],
              [108.60800411000008, 14.45612377700005],
              [108.6061257790001, 14.45774886800004],
              [108.60380561000005, 14.459807127000078],
              [108.60148217400011, 14.460785548000031],
              [108.60037247200003, 14.460140776000062],
              [108.60014643700008, 14.458629658000087],
              [108.60201517500006, 14.453765171000031],
              [108.60455619700008, 14.451490340000104],
              [108.60753667900003, 14.448026432000026],
              [108.61017856800005, 14.442511798000089],
              [108.61072165000004, 14.438946832000086],
              [108.60938596000001, 14.43679102700006],
              [108.6086034570001, 14.434417659000063],
              [108.60880711599999, 14.428478098000051],
              [108.61012841700007, 14.425882762000073],
              [108.61178555400005, 14.424474257000062],
              [108.61454958300004, 14.422846583000124],
              [108.61587274600001, 14.420899103000105],
              [108.61630129600012, 14.416146694000085],
              [108.61746227300007, 14.409056203000057],
              [108.61966689200005, 14.403774523000033],
              [108.62126345000009, 14.400051853000038],
              [108.62350516800002, 14.393342782000039],
              [108.62418634000008, 14.388504795000044],
              [108.62365461500005, 14.385367202000053],
              [108.6243412540001, 14.382310891000126],
              [108.62719958800008, 14.378145255000073],
              [108.629888812, 14.37559207400003],
              [108.63179706800007, 14.373719911000114],
              [108.63188062400005, 14.372616733000052],
              [108.63056580700007, 14.369481500000079],
              [108.62619659200004, 14.363300991000115],
              [108.62445616500001, 14.360063319000103],
              [108.62410189800002, 14.358028185000114],
              [108.62513563000002, 14.354716358000095],
              [108.62678380000006, 14.353184387000082],
              [108.62956286400004, 14.351649054000058],
              [108.63171365300009, 14.349337669000038],
              [108.63201846, 14.348978807000011],
              [108.63506803899999, 14.342100147000012],
              [108.63817393100003, 14.333911036000034],
              [108.64089116500006, 14.326674215000066],
              [108.64341309200009, 14.319628203000077],
              [108.64457523400006, 14.315820152000034],
              [108.64534410200008, 14.311442621000124],
              [108.65215257700004, 14.298676748000092],
              [108.65312181900003, 14.295820399000101],
              [108.65467068800011, 14.290679573000096],
              [108.65602874500003, 14.28706113100011],
              [108.65737233900009, 14.283159681000066],
              [108.65737625200006, 14.280208906000079],
              [108.65619359300008, 14.277549428000034],
              [108.65401973700007, 14.270898305000033],
              [108.65381240500005, 14.267284713],
              [108.65380134800003, 14.263860740000032],
              [108.65457536400004, 14.261195236000022],
              [108.65574127200006, 14.258718759000109],
              [108.65846862300003, 14.255096129000043],
              [108.66217906200008, 14.252992177000044],
              [108.66393486300004, 14.251464934000067],
              [108.66607505400002, 14.247844049000056],
              [108.66645318300004, 14.243848224000109],
              [108.66507094700007, 14.233766829000059],
              [108.66533906100005, 14.233577517000116],
              [108.66800034100007, 14.231457776000054],
              [108.66828176700008, 14.231208433000029],
              [108.67064326700007, 14.228830488000098],
              [108.67309774100003, 14.226234110000034],
              [108.67398700900007, 14.225280936000038],
              [108.67432088200007, 14.22485957400008],
              [108.67536061600003, 14.223261269000085],
              [108.67573247800007, 14.220406036],
              [108.67559127300002, 14.216187456000016],
              [108.67532337300007, 14.212093375000022],
              [108.67531509800008, 14.209611626000116],
              [108.67606828800007, 14.206755200000119],
              [108.67720960400008, 14.203903851000097],
              [108.67869394500006, 14.200835188000037],
              [108.67976001200007, 14.195615810000016],
              [108.67968038600006, 14.19415705400011],
              [108.67915240200007, 14.192699732000056],
              [108.67780171700005, 14.191026183000053],
              [108.67562591400005, 14.188406881000047],
              [108.67240016100004, 14.184623686000107],
              [108.67155098800004, 14.183714183000083],
              [108.6747742310001, 14.180965130000088],
              [108.67535584500008, 14.179824557000041],
              [108.6746495690001, 14.178688061000065],
              [108.67347497300007, 14.177553046000034],
              [108.66560178500004, 14.168809600000108],
              [108.66466033300003, 14.16733219700008],
              [108.66512414000003, 14.165964278000052],
              [108.66652302600005, 14.164137941000108],
              [108.67560681600007, 14.149761395000036],
              [108.67688842900006, 14.147935376000119],
              [108.68211408200007, 14.135392843000092],
              [108.68426747700008, 14.131359350000048],
              [108.69246587900003, 14.116001423000045],
              [108.69829376800003, 14.108922443000081],
              [108.70062511900002, 14.10618191400008],
              [108.70214189200001, 14.104810482000028],
              [108.70459533200007, 14.10354980100008],
              [108.70704755800001, 14.10194750200011],
              [108.70961317700002, 14.099319965000044],
              [108.71124247900009, 14.096695534000057],
              [108.71275309100011, 14.093616027000076],
              [108.71379474000005, 14.090310337000032],
              [108.71471531500002, 14.085866377000043],
              [108.715171373, 14.082448800000067],
              [108.71632270600006, 14.07709313300005],
              [108.71747921000009, 14.07321773500003],
              [108.71875512400005, 14.070025133000017],
              [108.72073430400003, 14.067171755000095],
              [108.72329768900003, 14.064088637000081],
              [108.72562818600004, 14.061347895000063],
              [108.72830925600009, 14.058492061000026],
              [108.73063880000009, 14.055523537000079],
              [108.73355662200002, 14.053463893000105],
              [108.73694116800002, 14.051060998000063],
              [108.74114633600006, 14.049110658000073],
              [108.74558543100009, 14.047159416000097],
              [108.74595276400002, 14.04704267400003],
              [108.749208364, 14.046008018000043],
              [108.75258837100007, 14.042466237],
              [108.75386861100003, 14.040639852000057],
              [108.75514374700003, 14.03744708500003],
              [108.75571855300007, 14.034712275000059],
              [108.75558754300006, 14.030955190000059],
              [108.75534294600007, 14.028109425000055],
              [108.75521576600003, 14.025377122000025],
              [108.75438919400003, 14.023330489000061],
              [108.75204220100008, 14.021403105000017],
              [108.75110037300006, 14.019812328000024],
              [108.75132759600002, 14.01798969700012],
              [108.75283406200006, 14.01411298300008],
              [108.76107418900008, 13.996548646000038],
              [108.76188916400011, 13.995520958],
              [108.76211840300006, 13.994267647000045],
              [108.76188061500002, 13.993243732000058],
              [108.759765708, 13.990746317000101],
              [108.75694775100007, 13.987909804000063],
              [108.75413156600004, 13.985528692000047],
              [108.75049245900003, 13.982011845000029],
              [108.75025009000004, 13.979735451000062],
              [108.75187368200008, 13.975972272000091],
              [108.75431508500003, 13.971978461000043],
              [108.75524271900005, 13.969811803000097],
              [108.75499903800005, 13.967193853000113],
              [108.75417185900008, 13.964919567000054],
              [108.75228956300003, 13.961965848000089],
              [108.74497891600001, 13.9458234040001],
              [108.74462557600008, 13.945141488000077],
              [108.74438164400006, 13.9424097],
              [108.74366119200005, 13.937288544000113],
              [108.74341896200008, 13.935012197000061],
              [108.74329241900008, 13.932393883000048],
              [108.74323596400004, 13.916994803000103],
              [108.74321353400003, 13.91087489500009],
              [108.74402240700009, 13.908253343000075],
              [108.74623307800003, 13.905285295000079],
              [108.75007805900002, 13.901628319000013],
              [108.75964379200003, 13.895787631000099],
              [108.76276544500004, 13.886440198000026],
              [108.76334142500009, 13.884161008000094],
              [108.76414994100003, 13.881539409000075],
              [108.76554038600005, 13.878232586000115],
              [108.76623451200001, 13.876294537000076],
              [108.76681129400004, 13.874243054000067],
              [108.76773662300005, 13.871621044000014],
              [108.76947807100007, 13.868426792000042],
              [108.77215424000008, 13.86511529000005],
              [108.77506584700011, 13.862258319000096],
              [108.77876216000006, 13.859685100000084],
              [108.77906775200003, 13.859499695000109],
              [108.78141838900009, 13.858073522000057],
              [108.78724596800008, 13.855197835000064],
              [108.79047726400009, 13.852014533000022],
              [108.79160138300003, 13.849156183000042],
              [108.79157663100003, 13.842813694000087],
              [108.79220240700002, 13.836944496000035],
              [108.79283124400008, 13.831868118000033],
              [108.79443301900001, 13.826788118000087],
              [108.79490955700001, 13.824249332000081],
              [108.79393102500005, 13.822825931000081],
              [108.79320462200008, 13.822305122000065],
              [108.79301289800001, 13.822130036000056],
              [108.79313851200001, 13.82040743500008],
              [108.79331567900002, 13.819741319000032],
              [108.79371441800006, 13.818242093000064],
              [108.79452004000011, 13.815051234000107],
              [108.79637879400008, 13.812197985000106],
              [108.79881870500006, 13.808545575000062],
              [108.80114884300004, 13.806715182000049],
              [108.80347985, 13.805112460000133],
              [108.81072456200008, 13.804857236000066],
              [108.81236112300004, 13.804964828],
              [108.814462409, 13.804387525000015],
              [108.81760994200002, 13.802439967000078],
              [108.82040407300011, 13.79981063000004],
              [108.82354409400003, 13.796041411000102],
              [108.82586970400007, 13.79318611400002],
              [108.82656064400004, 13.790678729000126],
              [108.82631673100011, 13.788174975000087],
              [108.83077912700003, 13.763540946000051],
              [108.83248723100004, 13.754110089000092],
              [108.83348357000006, 13.740672205000058],
              [108.83288961900003, 13.738283756000056],
              [108.83229381300002, 13.735439931000125],
              [108.83146064900009, 13.731686271000052],
              [108.82734680100006, 13.725554663000048],
              [108.82535439400006, 13.723968575000088],
              [108.82511613100007, 13.722831043000026],
              [108.82604278800008, 13.720892068000088],
              [108.82801984200006, 13.718721312000069],
              [108.8298772520001, 13.715867925000056],
              [108.83068143300002, 13.71256328000001],
              [108.83125523400007, 13.710056450000073],
              [108.83124730800003, 13.708121124000082],
              [108.83077494000003, 13.706870688],
              [108.8300694390001, 13.705735],
              [108.828663099, 13.704602057000058],
              [108.82725723000003, 13.703582949000024],
              [108.824330602, 13.70200051200001],
              [108.82128767200003, 13.700532341000036],
              [108.81801713600004, 13.700544966000015],
              [108.81532788800011, 13.699872243000042],
              [108.816672741, 13.685750496000091],
              [108.81747610100004, 13.682218277000045],
              [108.81792767800002, 13.678345894000097],
              [108.81826383900002, 13.674815496000024],
              [108.81870941600003, 13.669463208000108],
              [108.81857426300004, 13.664910073000057],
              [108.81890716800005, 13.660582823000043],
              [108.82062898300006, 13.653176519000086],
              [108.82212357300008, 13.647364879000088],
              [108.82386132700007, 13.643942941000107],
              [108.82559067200008, 13.638471914000137],
              [108.828951863, 13.632311487000058],
              [108.83219423700004, 13.625696152000087],
              [108.83760266100008, 13.606436278000087],
              [108.84490536600008, 13.593771455000068],
              [108.84652932000006, 13.591260604000064],
              [108.84814848900001, 13.587611384000107],
              [108.84952793500001, 13.582483268000088],
              [108.85021262300009, 13.578723954000081],
              [108.84996295600004, 13.574854564000114],
              [108.849601804, 13.572237816000053],
              [108.84895079800005, 13.570419487000061],
              [108.84868006700003, 13.569663291000021],
              [108.84783374000008, 13.568146841000049],
              [108.84654545100007, 13.567127474000083],
              [108.844907, 13.566109495000054],
              [108.84268776500009, 13.565776820000062],
              [108.84000163300006, 13.565445970000043],
              [108.83755036000011, 13.565455659000046],
              [108.82798247000008, 13.566403880000076],
              [108.82506474100008, 13.566529081000043],
              [108.82215023400008, 13.567451065000034],
              [108.81970170300008, 13.568143538000042],
              [108.8174939420001, 13.570656413000036],
              [108.815173047, 13.574080379000096],
              [108.8135479220001, 13.576363319000071],
              [108.81215122, 13.577393184000107],
              [108.80806824700004, 13.578091775000088],
              [108.80398300200005, 13.578221129000044],
              [108.79826346200005, 13.578356558000058],
              [108.79242718500005, 13.578492259000095],
              [108.78635787300004, 13.578742510000017],
              [108.78452139800007, 13.577830077000018],
              [108.78226426100009, 13.576708609000042],
              [108.76832904200003, 13.565148412000026],
              [108.76621894300006, 13.562765531000041],
              [108.76481566500004, 13.562087587000081],
              [108.76189924000005, 13.562553422000077],
              [108.75770094600004, 13.563592951000112],
              [108.75432011800009, 13.564743338000083],
              [108.75047402800001, 13.566350653000113],
              [108.74825830100009, 13.566927649000069],
              [108.74697303100007, 13.566590669000076],
              [108.74650193900007, 13.565453992000085],
              [108.74105397900006, 13.559510603000112],
              [108.73818121500004, 13.556376407000046],
              [108.73724041200009, 13.554444514000073],
              [108.7348928120001, 13.55081000700009],
              [108.73359780800006, 13.547741002000089],
              [108.73277093400003, 13.545011883000056],
              [108.73241050500005, 13.542167331000064],
              [108.73216517900001, 13.538867042000023],
              [108.73180150700003, 13.535111851000091],
              [108.73144071700001, 13.532153474],
              [108.73049559700007, 13.528969459000082],
              [108.72592843600009, 13.525232358000048],
              [108.71342209600004, 13.519238110000041],
              [108.71236780100003, 13.518103346000119],
              [108.70187803900004, 13.488201263000031],
              [108.70175821600006, 13.487291037000125],
              [108.70272593900002, 13.485038818000115],
              [108.71090999600003, 13.468365255000057],
              [108.71160324200009, 13.466427883000074],
              [108.71264849100007, 13.465058461000099],
              [108.71486280800005, 13.464368049000084],
              [108.71719497900003, 13.464018700000045],
              [108.72030116100008, 13.463320719000114],
              [108.73468131200009, 13.460088762000042],
              [108.73887841300008, 13.459277406000057],
              [108.74331297400001, 13.459603386000117],
              [108.74576548100006, 13.460277736000046],
              [108.75931327300005, 13.46409975400009],
              [108.76608822300008, 13.466238087000091],
              [108.77122689400008, 13.467585390000101],
              [108.77718146400009, 13.468701915000024],
              [108.78348874100007, 13.470499936000111],
              [108.78886495900009, 13.472870382000044],
              [108.79191660200009, 13.47529442200006],
              [108.79233825400007, 13.472726566000047],
              [108.79213215200006, 13.468631064000112],
              [108.79145112600003, 13.464630435000034],
              [108.78934390600003, 13.460542039000066],
              [108.7887619900001, 13.457565125000047],
              [108.78913498400006, 13.455701813000063],
              [108.78989036000003, 13.454395657000083],
              [108.79273038600009, 13.451405986000074],
              [108.79538021900005, 13.448417005000033],
              [108.79878525600006, 13.444121811000096],
              [108.80275455600002, 13.438334924000104],
              [108.80340038100007, 13.433398443000042],
              [108.80311316400012, 13.431018600000042],
              [108.80396682300005, 13.426714488000078],
              [108.80372137500008, 13.423642189000072],
              [108.80347368400001, 13.420000799000052],
              [108.80392404400007, 13.415901474000067],
              [108.80483734200004, 13.410889828000093],
              [108.80551821800007, 13.406106726000109],
              [108.806067989, 13.397681864000075],
              [108.80547265500003, 13.394610960000096],
              [108.8047638730001, 13.392337241000083],
              [108.80376103700007, 13.389438743000069],
              [108.80372975400007, 13.38152825100005],
              [108.805556005, 13.371505228000034],
              [108.80577750300003, 13.368545098000071],
              [108.80830770600004, 13.359657641000043],
              [108.80969522400009, 13.356693084000069],
              [108.81178233000003, 13.353725846000051],
              [108.81480038200007, 13.350299750000081],
              [108.819679373, 13.345728270000022],
              [108.83244673100006, 13.331337676000119],
              [108.8363921480001, 13.326769526000069],
              [108.84033173300001, 13.320835574000077],
              [108.84240388400005, 13.314453737000063],
              [108.84563979600009, 13.307612016000101],
              [108.84694155100007, 13.305143556000052],
              [108.84864709200005, 13.301909320000059],
              [108.85258871100004, 13.296658108000068],
              [108.85513798200003, 13.293005840000125],
              [108.86375162600005, 13.29001179200011],
              [108.86561576300001, 13.289776558000097],
              [108.86747503100001, 13.288403213000064],
              [108.86932843500007, 13.285664135],
              [108.87092527100003, 13.27746314300011],
              [108.8697216840001, 13.268590852],
              [108.86945840200008, 13.261535701000058],
              [108.86895425200001, 13.252660641000078],
              [108.86799662000001, 13.246746507000038],
              [108.86610089000001, 13.239470538000118],
              [108.86279432400006, 13.229241356],
              [108.86138916900006, 13.227653773000013],
              [108.85577930100007, 13.223807077000059],
              [108.85133779200001, 13.220638394000071],
              [108.84969687800005, 13.218368851000049],
              [108.84944956500001, 13.214955666000082],
              [108.84995128300005, 13.209321990000065],
              [108.84998639800003, 13.208927681000125],
              [108.84923264300009, 13.208593064000061],
              [108.84736533900005, 13.20738339700007],
              [108.84636860900008, 13.206535386000072],
              [108.84611677200003, 13.205684388000092],
              [108.84611170900006, 13.204467268000107],
              [108.84685050800007, 13.203003750000073],
              [108.84759080900005, 13.201905361000053],
              [108.84809290300002, 13.201326298000103],
              [108.85013371700003, 13.199135758000045],
              [108.85167517200004, 13.197394223000101],
              [108.85166790600006, 13.19565891100005],
              [108.85055171400008, 13.193277301000082],
              [108.84844431300006, 13.189661184000022],
              [108.84269994400003, 13.176826342000057],
              [108.84045205800003, 13.170609933000053],
              [108.83931191500001, 13.166215800000101],
              [108.83880989900004, 13.16487897100007],
              [108.83780990400008, 13.163178986000011],
              [108.83557372700007, 13.162700978000073],
              [108.83414807900006, 13.163132575000079],
              [108.83130274200009, 13.16545625600005],
              [108.82956800300009, 13.166193318000106],
              [108.82834459600011, 13.166198106000058],
              [108.82500314400005, 13.165378108000068],
              [108.820874227, 13.164046360000013],
              [108.81418505700006, 13.160798961000085],
              [108.81142845000005, 13.158884132000102],
              [108.80945958700005, 13.157543874000053],
              [108.80520948300003, 13.153599105000067],
              [108.79901378800004, 13.147571454000049],
              [108.79428736500005, 13.143930985000017],
              [108.79271367600003, 13.14316672200002],
              [108.7899672, 13.14237560700011],
              [108.786623574, 13.142226736000049],
              [108.78311731000007, 13.142819382000058],
              [108.78113764400004, 13.144408892000035],
              [108.7791621420001, 13.147093758000048],
              [108.7780488540001, 13.148071518000036],
              [108.77655951500006, 13.148076983000044],
              [108.77469597900006, 13.147596972000118],
              [108.77407078600002, 13.146382174000058],
              [108.77394112100004, 13.144922140000046],
              [108.77417822300004, 13.142000282000053],
              [108.77391936100001, 13.139201945000066],
              [108.77292003500003, 13.137501688000073],
              [108.77031058800003, 13.136659238000091],
              [108.76683518200007, 13.136550145000035],
              [108.76286426900005, 13.1366861860001],
              [108.76087722100004, 13.136328216000058],
              [108.760128497, 13.135235535000065],
              [108.75962253300006, 13.132681497000041],
              [108.75973663700009, 13.130003537000066],
              [108.76084263100002, 13.127078610000085],
              [108.76566922100005, 13.123531732000069],
              [108.76702878900001, 13.122066332000058],
              [108.7672714710001, 13.120604982000115],
              [108.76651910300002, 13.118538714000037],
              [108.76515132600009, 13.117813417000066],
              [108.76179851500007, 13.1172169800001],
              [108.75726647300007, 13.11639852600006],
              [108.75634038000001, 13.113262944000057],
              [108.75457359900001, 13.108296553000097],
              [108.75437431700004, 13.103739767000098],
              [108.75337104699999, 13.103702622000045],
              [108.75213015600008, 13.10158166500006],
              [108.75068056700005, 13.099163778000021],
              [108.74936008100003, 13.096827402000065],
              [108.74845696700001, 13.094731875000052],
              [108.74848088200012, 13.091361375000064],
              [108.74859431600001, 13.087394946000062],
              [108.74925618800006, 13.081037249000092],
              [108.74963412800005, 13.076842216],
              [108.74998403000006, 13.072397705000059],
              [108.7508960050001, 13.06570903900006],
              [108.75145951900001, 13.060457737000062],
              [108.75221109600002, 13.054374140000034],
              [108.75293110800001, 13.048922015000118],
              [108.753426921, 13.042583470000045],
              [108.754031341, 13.038494922000064],
              [108.75431847000004, 13.035262819000069],
              [108.75432758800008, 13.034252083000107],
              [108.75392127400005, 13.033540989000068],
              [108.74991673800008, 13.032501496000036],
              [108.74653801600004, 13.031328878000091],
              [108.74332789099999, 13.030131095000018],
              [108.73569408200004, 13.027245293000076],
              [108.72754010000004, 13.024255221000123],
              [108.72234859200002, 13.022189552],
              [108.71632338300003, 13.019966870000042],
              [108.71407056400005, 13.019187547000042],
              [108.71123955100003, 13.018506047000054],
              [108.70733750500003, 13.018184102000061],
              [108.70541311100004, 13.018202030000035],
              [108.70349427800009, 13.018182146000077],
              [108.70249590100008, 13.01807686400007],
              [108.69703582600005, 13.017944885000075],
              [108.69326852100008, 13.01759690600006],
              [108.69190957400009, 13.017471365000064],
              [108.68913836400003, 13.012346730000067],
              [108.68786684, 13.009995283000107],
              [108.68697469500007, 13.008345393000107],
              [108.67843282400004, 12.997335878000092],
              [108.67429181600006, 12.998762730000111],
              [108.66334009800003, 13.001638177000084],
              [108.65057356900004, 13.002813664000088],
              [108.6322622810001, 13.006811069000078],
              [108.61632719600004, 13.011360262000069],
              [108.59872508800007, 13.024822888000108],
              [108.59382702600007, 13.028630137000084],
              [108.59034038300007, 13.030688073000094],
              [108.58638207400003, 13.030699073000028],
              [108.58125760500005, 13.030030477000041],
              [108.57752956400006, 13.029130373000084],
              [108.57379835000006, 13.027092326000032],
              [108.57029877700005, 13.0245984420001],
              [108.56703085300002, 13.021648734000022],
              [108.56516693200008, 13.021198568000079],
              [108.56097974000002, 13.022575155000114],
              [108.55516637900006, 13.025321393000054],
              [108.54656001800001, 13.028529850000046],
              [108.53981611800006, 13.031733224000062],
              [108.53167315100009, 13.034257182000085],
              [108.52515406, 13.034501024000098],
              [108.52166019600001, 13.034054507000102],
              [108.51630589800001, 13.034522786000123],
              [108.51118613600008, 13.035673101000087],
              [108.50979247100004, 13.037041927000102],
              [108.50944959, 13.038853699000084],
              [108.50910303600003, 13.040684846000071],
              [108.50911385200003, 13.04500882400008],
              [108.5100561240001, 13.049330575000083],
              [108.51146645700008, 13.054561531000084],
              [108.51380663300009, 13.059107505000076],
              [108.51568116600002, 13.063654604000073],
              [108.51568924000007, 13.066840748000084],
              [108.51360649600005, 13.072080252000083],
              [108.51012685400009, 13.077550699000094],
              [108.50780414300002, 13.080059727000036],
              [108.50408565900001, 13.083254816000077],
              [108.49177869800012, 13.098532155000074],
              [108.48319579500006, 13.113345313000123],
              [108.47714585300007, 13.116090133000034],
              [108.47016081800007, 13.117471317000094],
              [108.46573648900001, 13.118163866000108],
              [108.46247582000005, 13.11839860800008],
              [108.45991705400007, 13.119997333000109],
              [108.45658794200006, 13.125279569],
              [108.45718699800005, 13.13056868800002],
              [108.45738229300005, 13.132292919000042],
              [108.45995306800003, 13.135928881000057],
              [108.46394353200007, 13.14912082300008],
              [108.4623292420001, 13.156407525000089],
              [108.46001121700004, 13.161647384000029],
              [108.45908439200011, 13.163925410000042],
              [108.45489710700004, 13.166893261000022],
              [108.45140869900007, 13.16985954900005],
              [108.44396408500005, 13.175110139000077],
              [108.43791373700002, 13.178764381000095],
              [108.43707202900001, 13.179633478000062],
              [108.43349621000007, 13.183325551000012],
              [108.42884691400006, 13.188569934000059],
              [108.42350444800007, 13.196774500000073],
              [108.42002635000006, 13.205202907000045],
              [108.41654937200006, 13.214314133000114],
              [108.41410624100007, 13.220962651000077],
              [108.41867473800005, 13.225751534],
              [108.420230546, 13.228654963000073],
              [108.42027483100001, 13.229017660000062],
              [108.42056447600001, 13.231389736000041],
              [108.42054068900008, 13.232295358000011],
              [108.4203747220001, 13.237281631000107],
              [108.41894294600004, 13.241047480000121],
              [108.41710630100005, 13.243816981000032],
              [108.41607773900003, 13.244673579000031],
              [108.4132951, 13.246611070000112],
              [108.4093104080001, 13.249457707000099],
              [108.40821317600007, 13.252049527000052],
              [108.40825583300006, 13.254596324000099],
              [108.39519125900009, 13.256918766000057],
              [108.3856375610001, 13.258757311000034],
              [108.38284332100008, 13.260355695000035],
              [108.37795614700011, 13.264689220000035],
              [108.37353458200002, 13.268794193000042],
              [108.37097696100005, 13.272440602000033],
              [108.36982212800007, 13.278360751000038],
              [108.36936704300007, 13.284507318000037],
              [108.36890944400005, 13.289288181000101],
              [108.367065708, 13.301127817000056],
              [108.36707475000003, 13.306135556000063],
              [108.36544837600012, 13.309325132000092],
              [108.36195621900001, 13.312290267000025],
              [108.35195417300004, 13.326192395000101],
              [108.34404991000009, 13.340318441000052],
              [108.3398527460001, 13.340552837000084],
              [108.33472315400005, 13.341016233000056],
              [108.32368938000006, 13.344266001000092],
              [108.32306869600005, 13.344448787000116],
              [108.31397769200005, 13.346966400000058],
              [108.30605147300007, 13.348799056000081],
              [108.29579534900003, 13.352228105000028],
              [108.29113275900008, 13.353372719000054],
              [108.28553791000007, 13.354973764000098],
              [108.28204602400008, 13.359531119000055],
              [108.27800669700008, 13.363664024000064],
              [108.27692233400006, 13.364773469000022],
              [108.26923253400001, 13.36979135700007],
              [108.26270648900007, 13.373214087000028],
              [108.25640943200004, 13.373449427000049],
              [108.24707974900011, 13.373232872000072],
              [108.23285242400004, 13.373248974000072],
              [108.22212262100007, 13.372349942000115],
              [108.20766676600002, 13.376917320000102],
              [108.20537930500011, 13.377366140000023],
              [108.19950495900007, 13.378518619000078],
              [108.19134346200006, 13.380574880000038],
              [108.18434801000009, 13.382629819000067],
              [108.17691256000003, 13.384548100000069],
              [108.17644669600003, 13.384538183000048],
              [108.17618970700008, 13.386056712000103],
              [108.17469615100005, 13.390850843000111],
              [108.166714316, 13.39809554500002],
              [108.15952812400003, 13.402013673000022],
              [108.14235811500002, 13.408677338000084],
              [108.12388708500001, 13.412503746000047],
              [108.119893, 13.412897299000102],
              [108.116298554, 13.413681835000089],
              [108.10771072500006, 13.41368637100002],
              [108.098721944, 13.410560638000034],
              [108.08014615900002, 13.404503909000097],
              [108.07055997800009, 13.403920428000047],
              [108.06956157100008, 13.404312022000029],
              [108.06856376200004, 13.406464278000028],
              [108.0673665370001, 13.409594760000052],
              [108.06576952700003, 13.411747209000067],
              [108.06077729700007, 13.413900640000074],
              [108.05618402000007, 13.414684433000089],
              [108.05278881099999, 13.414685305000072],
              [108.05059167100009, 13.413707690000097],
              [108.04609668600006, 13.40774195500005],
              [108.0436991020001, 13.402851705000083],
              [108.04130234900003, 13.40148277000006],
              [108.03331366700003, 13.398549726000029],
              [108.02632366500006, 13.395225013000099],
              [108.02362080700007, 13.392422146000092],
              [108.01953322100007, 13.388183109000094],
              [108.01194470300005, 13.384271121000067],
              [108.00894931600001, 13.382901871000017],
              [108.00655306400007, 13.38251070000007],
              [108.00076218000002, 13.385836475000088],
              [107.99736741500007, 13.388770883000054],
              [107.99457163400002, 13.39209651100006],
              [107.98957913400004, 13.394052614000083],
              [107.98219026300009, 13.394639006000107],
              [107.97939461000007, 13.393464985000046],
              [107.97859609199999, 13.390921725000025],
              [107.97939518500003, 13.387791756000043],
              [107.97959507200005, 13.385835495],
              [107.97899616700005, 13.384270408000097],
              [107.97580127000003, 13.38329192900005],
              [107.97156238200004, 13.382393497000054],
              [107.96841309500006, 13.381725941000065],
              [107.96262235500011, 13.380942492000079],
              [107.95882826300006, 13.381333047000064],
              [107.95627755300005, 13.382665468000086],
              [107.95607491100006, 13.382827820000019],
              [107.95183812500008, 13.386222258000123],
              [107.95024041200004, 13.387004399000089],
              [107.94844314400011, 13.387199600000075],
              [107.94584747700002, 13.386025192000071],
              [107.94365148600004, 13.383872711000054],
              [107.94225450300009, 13.380937923000056],
              [107.94225567000009, 13.376829754000051],
              [107.94185675800004, 13.375264636000049],
              [107.94105827100006, 13.374481912000123],
              [107.93926104700003, 13.374872652000123],
              [107.93706409500007, 13.376437029000064],
              [107.93393694900006, 13.376463098000089],
              [107.92684613000009, 13.378372226000044],
              [107.92099669500007, 13.381374215000095],
              [107.91798114300005, 13.382921736000062],
              [107.91214812200006, 13.387244525000062],
              [107.90258373600007, 13.389516731000038],
              [107.89512020000008, 13.388374983000071],
              [107.88345732500001, 13.388824170000111],
              [107.87879036100004, 13.392008498000061],
              [107.87529026099999, 13.394055226000122],
              [107.87155782800008, 13.394508277000037],
              [107.86945849800001, 13.394506995000109],
              [107.86152891800002, 13.392680850000092],
              [107.84776690800007, 13.392443773000105],
              [107.84146505900002, 13.397447235000113],
              [107.83446513700002, 13.399945933000089],
              [107.82560339300008, 13.397207148000055],
              [107.81884040400006, 13.395380350000073],
              [107.79958919600007, 13.392670492000034],
              [107.79992457400002, 13.389900856000024],
              [107.79961302500008, 13.386195081],
              [107.79930010500004, 13.38387886900003],
              [107.7988285320001, 13.382643257000025],
              [107.79788371400008, 13.381870391000026],
              [107.79709587700009, 13.381715240000068],
              [107.79536169300005, 13.382331151000047],
              [107.79346973200009, 13.383101293],
              [107.79157855700002, 13.383099442000022],
              [107.790003531, 13.382171522000073],
              [107.78552279200008, 13.378069621000053],
              [107.78234622000005, 13.375868],
              [107.77617915400005, 13.372472418000124],
              [107.77206793500005, 13.370178057000091],
              [107.76866497900005, 13.368176694000054],
              [107.7656727940001, 13.366475083000038],
              [107.76315215000008, 13.365854711000045],
              [107.76157575900004, 13.366316127000031],
              [107.76094469000004, 13.366932987],
              [107.76062805800005, 13.368167772000026],
              [107.76062605800007, 13.369866097000036],
              [107.76156851400006, 13.372491839000043],
              [107.76125260300003, 13.373109057000041],
              [107.75920374600007, 13.373261138000101],
              [107.7547923330001, 13.372329722000041],
              [107.7517994390001, 13.371245482000036],
              [107.75006748600006, 13.370008302000079],
              [107.74912462700011, 13.367845687000022],
              [107.74818254400006, 13.365065502000027],
              [107.74676544400005, 13.364137460000068],
              [107.74377174900006, 13.363825078000067],
              [107.73857031200001, 13.364745083000072],
              [107.72722095700004, 13.367201067000066],
              [107.72304335500006, 13.368353600000125],
              [107.71988949300005, 13.369893387000074],
              [107.71799544300011, 13.372052381000097],
              [107.71689016500005, 13.37359484300009],
              [107.71499734700011, 13.374827465000026],
              [107.71279018800001, 13.375442061000074],
              [107.70443780200002, 13.375430583000101],
              [107.70272912500005, 13.375299409000084],
              [107.70238933100008, 13.375273319000057],
              [107.696087264, 13.374183629000045],
              [107.69246570400003, 13.372171287000077],
              [107.6841322710001, 13.365309967000092],
              [107.6790772950001, 13.361994347000026],
              [107.65848457000004, 13.349517333000092],
              [107.65267322800005, 13.346166747000064],
              [107.64916757700009, 13.343507711000056],
              [107.64776588900001, 13.342129629000075],
              [107.64817283200003, 13.338789168000085],
              [107.64899007200005, 13.330142933000031],
              [107.64859403100006, 13.327194243000038],
              [107.64679064800006, 13.326208570000107],
              [107.64538669600006, 13.326206226000091],
              [107.64317876000008, 13.327185197000089],
              [107.64076918500011, 13.328753408000081],
              [107.63916325300002, 13.329536821000064],
              [107.63655692400002, 13.328942765000116],
              [107.63475393700006, 13.32776045200008],
              [107.62914775600009, 13.322444277000043],
              [107.62594419700005, 13.319490631000084],
              [107.61733055400003, 13.31397231100002],
              [107.614669099, 13.312732809000048],
              [107.61292314500005, 13.311012365000073],
              [107.61242694200003, 13.308755276000015],
              [107.60542687600011, 13.305274557000056],
              [107.61817661100005, 13.334793497000062],
              [107.62993605700004, 13.361704223000091],
              [107.62903142100005, 13.374371695000063],
              [107.6275548, 13.391945037000051],
              [107.62552647500004, 13.413059804000047],
              [107.62512040100002, 13.417286523000092],
              [107.624762715, 13.422734580000027],
              [107.62210075400002, 13.452706153000092],
              [107.61929985200008, 13.482679210000102],
              [107.61691894200008, 13.51292012800007],
              [107.61677758800002, 13.515407815000023],
              [107.61599748100005, 13.52913593700009],
              [107.61062173100005, 13.541024260000077],
              [107.59843213100001, 13.564426058000032],
              [107.59626523700004, 13.569756894000038],
              [107.58287772500009, 13.59834306400005],
              [107.57341171300007, 13.616816176000055],
              [107.57067541800006, 13.622316384000042],
              [107.57041687800007, 13.622836066000053],
              [107.56964275100006, 13.62842466100007],
              [107.56889476000008, 13.642860252000053],
              [107.56821933900005, 13.657567318000117],
              [107.56714462700005, 13.667514609000085],
              [107.5611124480001, 13.675743765000091],
              [107.54926473900009, 13.690995742000032],
              [107.54643297600009, 13.698081808000049],
              [107.54223666000007, 13.712280649000073],
              [107.53342028200007, 13.740002435000063],
              [107.53301647100005, 13.740993618000095],
              [107.53186103600011, 13.743829658],
              [107.49952933500008, 13.765802228000011],
              [107.49248088100006, 13.770501699000091],
              [107.48536513199998, 13.775698065000073],
              [107.47206901300005, 13.778708498000112],
              [107.44885952000001, 13.794418696000042],
              [107.44946295400003, 13.795624048000031],
              [107.45370960500003, 13.802680194000059],
              [107.45710164300003, 13.807281040000062],
              [107.45970207700007, 13.814175909000069],
              [107.459690526, 13.818512928000091],
              [107.45706272700001, 13.821822809000126],
              [107.45417432200003, 13.824621746000075],
              [107.45311915600003, 13.827425376000056],
              [107.4549415060001, 13.831512024000057],
              [107.46121444200006, 13.836375370000018],
              [107.46643262000008, 13.844042266000132],
              [107.47034217200007, 13.851450690000087],
              [107.47033417800003, 13.854512176000087],
              [107.46822930300004, 13.858078696000041],
              [107.46539156000009, 13.860087567000077],
              [107.46140591100001, 13.862908927000104],
              [107.45510816100003, 13.866974918000084],
              [107.45247829100003, 13.870795084000063],
              [107.45351238200006, 13.875900331000055],
              [107.45585843400002, 13.880243554000058],
              [107.46160897100002, 13.88510568],
              [107.46736042500001, 13.889712532000063],
              [107.46918629300009, 13.892778682000094],
              [107.46916959800005, 13.899156953000078],
              [107.47018759000008, 13.910640504000058],
              [107.4709543220001, 13.918041323000095],
              [107.46963821200009, 13.92033426700001],
              [107.46727665400002, 13.921604042000089],
              [107.46203343600006, 13.922611380000125],
              [107.45895290800009, 13.92324141500003],
              [107.45783547700009, 13.924641429000049],
              [107.45861211200004, 13.928215694000111],
              [107.46096227900007, 13.931283314000085],
              [107.46645432700009, 13.935379376000066],
              [107.46749848100006, 13.936912818000023],
              [107.46801386900007, 13.940230911000116],
              [107.46747502800008, 13.945842616000091],
              [107.46720425500006, 13.949158756000111],
              [107.46745492400001, 13.953496761000038],
              [107.47137261200007, 13.958609357000016],
              [107.47870213000007, 13.961944299000082],
              [107.489174936, 13.966051986000048],
              [107.49467103000009, 13.969126840000088],
              [107.50252299000006, 13.973482791000079],
              [107.50748910599999, 13.979107508000064],
              [107.51036481100007, 13.982175880000101],
              [107.51298278700007, 13.983457609000103],
              [107.51612804500007, 13.983464792000076],
              [107.51873278100003, 13.98360661],
              [107.520293361, 13.983599402000022],
              [107.52138987100005, 13.983594330000047],
              [107.52328353000006, 13.983845381000036],
              [107.52466886900001, 13.98545263500006],
              [107.52466192500006, 13.988414140000048],
              [107.520885816, 14.001987922000046],
              [107.51903274200006, 14.01108035500007],
              [107.51890053700005, 14.013062197000091],
              [107.5192683010001, 14.014410985000046],
              [107.519745797, 14.014875090000077],
              [107.52048391100006, 14.015127328000075],
              [107.52486620500009, 14.015137166000089],
              [107.52827521300007, 14.014906888000043],
              [107.53095547900006, 14.013961361000058],
              [107.53485519300004, 14.012067016000083],
              [107.53850759700001, 14.011837115000068],
              [107.540294832, 14.012656789000031],
              [107.54168244400006, 14.01379165100005],
              [107.54408356300004, 14.022315176000076],
              [107.54536691200008, 14.023910883000033],
              [107.54724122900009, 14.025652039000123],
              [107.54822265700003, 14.026243017000082],
              [107.54935655700005, 14.02650789300006],
              [107.55130975500001, 14.02588541300007],
              [107.55235522700009, 14.025187136000026],
              [107.55502143100007, 14.023931307000035],
              [107.55820376300004, 14.022820715000071],
              [107.56697326100007, 14.020697975000116],
              [107.56956099500005, 14.02027125800007],
              [107.57561860600006, 14.019645012000035],
              [107.58160852400006, 14.020823508000111],
              [107.58562228300008, 14.022038687000114],
              [107.58979009000004, 14.023480470000036],
              [107.59654157600008, 14.027297422000105],
              [107.59889065200011, 14.030213589000072],
              [107.59935064900007, 14.031949994000035],
              [107.5995012560001, 14.033912192000029],
              [107.59911057500001, 14.036175203000044],
              [107.59870035600008, 14.043714304000064],
              [107.59909102200008, 14.046060224000099],
              [107.60015335000006, 14.047760791000117],
              [107.60178850700007, 14.049159098000084],
              [107.60333208700007, 14.049841110000026],
              [107.60574783700011, 14.050863521000066],
              [107.60867827400001, 14.052397202000042],
              [107.60965972300004, 14.053248464000133],
              [107.61050697800006, 14.054532824000086],
              [107.61127642200005, 14.056118865000132],
              [107.61158151200007, 14.058156819000088],
              [107.61149905300007, 14.060873197000133],
              [107.61157428100009, 14.061929769000068],
              [107.61172660900007, 14.063061932000041],
              [107.61195691500004, 14.063816952000073],
              [107.61218752200003, 14.064421045000051],
              [107.61288191600002, 14.064875065000047],
              [107.61450406500003, 14.064953471000051],
              [107.61635967500008, 14.064126776000039],
              [107.619377306, 14.061566584000039],
              [107.62231817200006, 14.058704380000096],
              [107.62432945400003, 14.057198757000043],
              [107.62649493000006, 14.055844304000063],
              [107.62811824900008, 14.055243479000088],
              [107.62920656700007, 14.055098026000044],
              [107.63130796800003, 14.055191011000099],
              [107.63267548600002, 14.055477776000112],
              [107.63398665700007, 14.056611927000072],
              [107.63471281200002, 14.057575579000046],
              [107.63622063400008, 14.060086876000087],
              [107.63884318900008, 14.062815765000012],
              [107.64077089900007, 14.064320331000067],
              [107.6431339320001, 14.065501176000071],
              [107.64336308000006, 14.065626630000059],
              [107.64645543000003, 14.067845126000032],
              [107.64736376400005, 14.068735379000062],
              [107.64936243700004, 14.070516183000057],
              [107.65163029300001, 14.074519275000073],
              [107.65507524400003, 14.081990382000063],
              [107.65561516500003, 14.085546263000113],
              [107.6547003590001, 14.088388790000026],
              [107.65159942700004, 14.092472004000099],
              [107.64722280600002, 14.097441826000077],
              [107.64703677600008, 14.099752289000021],
              [107.64739800500004, 14.101352654],
              [107.64885189500008, 14.102599323000041],
              [107.65067074200002, 14.103313326000054],
              [107.65358255900007, 14.103495841000056],
              [107.65503741900001, 14.104209214000017],
              [107.65603378700003, 14.106965991000029],
              [107.65729733700003, 14.113189373000077],
              [107.6587396960001, 14.121368342000016],
              [107.66090941300003, 14.130081758000033],
              [107.66199181100006, 14.135949383000044],
              [107.662529933, 14.140749625000113],
              [107.66212267400005, 14.144404940000042],
              [107.66197549800003, 14.145725908000093],
              [107.66087430200005, 14.151056834000107],
              [107.65940802600005, 14.156920454000053],
              [107.65703106700003, 14.16296037100002],
              [107.65639038200004, 14.165003542000052],
              [107.65657229900009, 14.166964696000049],
              [107.65739854000006, 14.168408700000066],
              [107.65819633400008, 14.169782636000059],
              [107.658765481, 14.170228675000105],
              [107.6609049260001, 14.171905321000098],
              [107.66306275800011, 14.173517444000082],
              [107.66548701800006, 14.175131824000074],
              [107.66610930900003, 14.175546217000077],
              [107.66889192900005, 14.178392331000021],
              [107.6705702830001, 14.180137185000062],
              [107.67271903300008, 14.183381198000065],
              [107.67305112200002, 14.184195387000019],
              [107.67464668000005, 14.1877248140001],
              [107.67562412700002, 14.189886894000024],
              [107.67635422400011, 14.192156287000033],
              [107.67651389000009, 14.194639127000118],
              [107.67599779000003, 14.198946285000067],
              [107.67585131300007, 14.2007511500001],
              [107.67612140200004, 14.202271253000019],
              [107.67759765900006, 14.206901864000086],
              [107.67988244200009, 14.211213282000033],
              [107.68172027600011, 14.214579459000019],
              [107.68376256600007, 14.218319971000069],
              [107.68606166600004, 14.221992441000085],
              [107.68629213600011, 14.222247204000025],
              [107.68729418000007, 14.222515545000112],
              [107.68847988900002, 14.222338847000058],
              [107.68993891700003, 14.221274056000064],
              [107.69183377600005, 14.219866888000075],
              [107.69342426600011, 14.21869020800006],
              [107.69678019300005, 14.217014129000026],
              [107.69881883000006, 14.216782405000037],
              [107.70224719700005, 14.21755556800006],
              [107.70525358900002, 14.218182432000058],
              [107.70871615300007, 14.218454055000061],
              [107.71473164400007, 14.217839535000019],
              [107.72138562000004, 14.216780901000091],
              [107.72266180000003, 14.216515703000086],
              [107.72523808500002, 14.21562430700005],
              [107.7281749630001, 14.214434240000083],
              [107.74071515300002, 14.208977405000043],
              [107.74909948300008, 14.205744870000013],
              [107.75228052300011, 14.204674144000061],
              [107.75521679500005, 14.2037224700001],
              [107.75767144500003, 14.203304378000057],
              [107.75894707000006, 14.203394775000076],
              [107.75994858900006, 14.204107539000075],
              [107.76040326100011, 14.2049086310001],
              [107.76094833100008, 14.20633249900005],
              [107.76108286600002, 14.20815618800013],
              [107.76089882700008, 14.209668186000089],
              [107.76062229700003, 14.212336486000064],
              [107.76107562500005, 14.214293974000023],
              [107.76198566300012, 14.215362433000054],
              [107.76407987000002, 14.216876985000047],
              [107.76773293600012, 14.218919309000098],
              [107.76963574700008, 14.219551682000107],
              [107.77072921600005, 14.219641821000016],
              [107.77209628600009, 14.21955433100006],
              [107.77337291600003, 14.21884406700009],
              [107.77501455500006, 14.217689411000032],
              [107.77601708600005, 14.217601510000042],
              [107.77647253900008, 14.217779896000058],
              [107.77683686600002, 14.217958185000066],
              [107.7772918310001, 14.218581340000112],
              [107.77774670200007, 14.219293452000057],
              [107.77856444900006, 14.221518154000099],
              [107.7803823620001, 14.225878787000033],
              [107.78079517800006, 14.226270828000052],
              [107.78147510300002, 14.22668050000002],
              [107.78203723100007, 14.22675947000007],
              [107.78384110100002, 14.226449865000033],
              [107.78608393600005, 14.225975248000021],
              [107.78861540300008, 14.22515441100005],
              [107.79180357700008, 14.223693789000079],
              [107.79414791100007, 14.222506769000097],
              [107.79696138500006, 14.220771254000029],
              [107.80088768000003, 14.217887131000049],
              [107.80427092300002, 14.214986491000035],
              [107.80562371500012, 14.214327784000067],
              [107.80778741100009, 14.21406576900006],
              [107.81048027600005, 14.213787083000094],
              [107.81454744000004, 14.214863700000089],
              [107.81616918700011, 14.215789035000091],
              [107.81711451500007, 14.217109753000033],
              [107.81792308800001, 14.220146214000094],
              [107.81864302000007, 14.223162025000045],
              [107.81916962600003, 14.225548510000062],
              [107.81954363200006, 14.226555162000041],
              [107.82081626700003, 14.22878172700006],
              [107.82274382900009, 14.231094835000045],
              [107.82467261800008, 14.233350935000109],
              [107.82544207700005, 14.233878908000095],
              [107.82628549300003, 14.234062566000103],
              [107.82811098000008, 14.233856079000072],
              [107.83237800799999, 14.233781904000063],
              [107.83495375700004, 14.234694922000111],
              [107.836591325, 14.237201454000024],
              [107.836351022, 14.244717091000057],
              [107.83594508200008, 14.247631773000078],
              [107.83470168300005, 14.256558970000109],
              [107.83422701800004, 14.264074489000043],
              [107.83467636900009, 14.267592093000035],
              [107.83609595300004, 14.270453070000054],
              [107.84265264000004, 14.274102107000084],
              [107.84803921100004, 14.276383573000116],
              [107.84982258700008, 14.27664171700007],
              [107.85436400300004, 14.277299016000098],
              [107.85669756600004, 14.277802577000019],
              [107.88083392300011, 14.283009378000065],
              [107.88809733400005, 14.281874545000049],
              [107.89465830000006, 14.2798280860001],
              [107.90051719300006, 14.275959057000074],
              [107.90520535900001, 14.270267307000049],
              [107.91059544900003, 14.265942299000043],
              [107.91621937800004, 14.262072763000065],
              [107.9586268890001, 14.22974437100004],
              [107.95956377900008, 14.229744551000055],
              [107.96260857600004, 14.230200609000041],
              [107.966355643, 14.233161998000108],
              [107.99118302600004, 14.245690824000066],
              [107.99821023400008, 14.245463251000066],
              [108.00219230200004, 14.24432448200008],
              [108.00828236300008, 14.24090805000011],
              [108.01156169300006, 14.241591157000054],
              [108.01366994600006, 14.243640803000089],
              [108.0242127490001, 14.261176859000011],
              [108.02655583200008, 14.265048401000067],
              [108.03100770000002, 14.27074166500004],
              [108.0333509860001, 14.274385399000076],
              [108.03592839900001, 14.276662532000016],
              [108.03850565400009, 14.277800863000055],
              [108.04670441600007, 14.273699618000089],
              [108.05115484600006, 14.270737777000074],
              [108.05490241399998, 14.268003788000081],
              [108.05794747900005, 14.266636454000013],
              [108.06638734600008, 14.285993214000124],
              [108.07881422200003, 14.310814588000119],
              [108.08429658700007, 14.317903295000088],
              [108.08454945000003, 14.318091190000091],
              [108.08470223000005, 14.318299990000048],
              [108.08564229600003, 14.320132500000058],
              [108.08589462200004, 14.323802465000044],
              [108.08518013900007, 14.324864602000085],
              [108.08414, 14.325760531000052],
              [108.082218819, 14.3266134300001],
              [108.07862380000009, 14.328209352000075],
              [108.07678287900009, 14.329753088000084],
              [108.0758661950001, 14.330901746000089],
              [108.07565311000006, 14.332196147000074],
              [108.07586702, 14.333103795000033],
              [108.07629990600002, 14.33400821000008],
              [108.07712152800005, 14.334571379000048],
              [108.08024410200005, 14.334773315000058],
              [108.080883478, 14.334814653000103],
              [108.08991194700008, 14.33481103100007],
              [108.09487630700005, 14.335261470000125],
              [108.09843942800006, 14.336030625000083],
              [108.10230807600007, 14.338015867000118],
              [108.10546378900004, 14.340186693000055],
              [108.109227859, 14.344099564000066],
              [108.10998469500001, 14.3451036470001],
              [108.11023240100008, 14.346545787000052],
              [108.1099825310001, 14.348258630000108],
              [108.10847875300001, 14.350249189000051],
              [108.10785553500006, 14.350939881000089],
              [108.10668828200002, 14.352248144000066],
              [108.10612106499998, 14.35321525100006],
              [108.10610714900008, 14.354297353000065],
              [108.10915794300007, 14.358663708000028],
              [108.11132683000002, 14.362586380000051],
              [108.11209096200002, 14.363569746000058],
              [108.11330304300003, 14.364233892000032],
              [108.11452070000006, 14.364465199000074],
              [108.11675352600007, 14.363410127000085],
              [108.12046191000003, 14.359775504000092],
              [108.12359053100008, 14.358081875000027],
              [108.12582189200005, 14.3571349090001],
              [108.12848872600009, 14.35684256700007],
              [108.13126789000009, 14.356443331000078],
              [108.13260200800006, 14.356243039000026],
              [108.13370870100006, 14.356472890000036],
              [108.13425687200004, 14.356929813000095],
              [108.13632884800005, 14.357385075000105],
              [108.13727644200003, 14.357880157000034],
              [108.1380167990001, 14.359389281000022],
              [108.13852591000003, 14.360583118000124],
              [108.13935794600003, 14.360988144000062],
              [108.14157235100002, 14.361260756000059],
              [108.14630099400003, 14.361715807000051],
              [108.14776931300003, 14.36157159000002],
              [108.14809192500007, 14.360969924000065],
              [108.14812060100004, 14.360024779000112],
              [108.14706214300004, 14.358364382000042],
              [108.14532860900005, 14.357219977000033],
              [108.14330153900002, 14.356247588000047],
              [108.14156830400005, 14.35547546600011],
              [108.14103912700006, 14.354673887000045],
              [108.14115593400004, 14.353728696000088],
              [108.14191874500008, 14.352668503000119],
              [108.14414891400006, 14.350225934000081],
              [108.1509992630001, 14.350174874000031],
              [108.15194176100002, 14.350899727000053],
              [108.1537741650001, 14.353204470000057],
              [108.15568426200005, 14.35729448400007],
              [108.1586635880001, 14.362945878000042],
              [108.16339593800005, 14.366512995000045],
              [108.16843222300002, 14.368443202000057],
              [108.18185961500008, 14.370068729000083],
              [108.18872602700003, 14.370955421000085],
              [108.19284710200004, 14.372737082000063],
              [108.198189187, 14.374889476000073],
              [108.20116575300008, 14.376300088000081],
              [108.20254076900008, 14.378158533000105],
              [108.20353595000009, 14.381579521],
              [108.20544747100006, 14.385817927000042],
              [108.207814759, 14.388047332000029],
              [108.21323330600006, 14.389827341000048],
              [108.21994948200003, 14.392126607000042],
              [108.22559629900007, 14.393162167000105],
              [108.22791176000007, 14.393159701000078],
              [108.23009597200004, 14.391743940000088],
              [108.23177380600005, 14.391221383000106],
              [108.23383421900009, 14.39166547700008],
              [108.23757489900007, 14.393669871000013],
              [108.24119693199999, 14.397076332000045],
              [108.24318112300003, 14.397520423000019],
              [108.24470656700004, 14.397146720000096],
              [108.24668858900007, 14.395805409000049],
              [108.24988971800008, 14.393198013000051],
              [108.25332141400007, 14.39200371],
              [108.26003372000007, 14.390879763000084],
              [108.26644089000004, 14.389830387000034],
              [108.27170362300011, 14.388782272000087],
              [108.27597624000003, 14.388999942000067],
              [108.27834219300004, 14.389666383000053],
              [108.28047907600011, 14.390184308000096],
              [108.28475173300004, 14.390401811000062],
              [108.29058855100007, 14.391010956000104],
              [108.29108493100003, 14.391062760000038],
              [108.29444318700007, 14.392025219000091],
              [108.29467997700003, 14.397455396000057],
              [108.29384516700006, 14.400506567],
              [108.29110408700009, 14.404378641000013],
              [108.29003880300007, 14.406388648000055],
              [108.290118513, 14.408769043000101],
              [108.29164888600006, 14.411816985000046],
              [108.29425044500002, 14.416871995000056],
              [108.29616267600005, 14.420068161000097],
              [108.29784232600011, 14.420735338000055],
              [108.30026565900008, 14.420943186000022],
              [108.30278501300006, 14.421832314000048],
              [108.30484779000004, 14.423540371000064],
              [108.30698838100005, 14.426215386000061],
              [108.30951408000004, 14.431195966000042],
              [108.31188407700002, 14.434093774000088],
              [108.31516870300001, 14.436246302000109],
              [108.31769064000004, 14.438623089000123],
              [108.32052035700005, 14.442561621000037],
              [108.32143988400001, 14.444940774000068],
              [108.32151200800003, 14.448829558000041],
              [108.32197841400007, 14.454185088000047],
              [108.32328007100004, 14.45686123900005],
              [108.32603179700007, 14.459535170000047],
              [108.33138161300005, 14.46413924900005],
              [108.33588126300003, 14.468964252000125],
              [108.34392093300008, 14.477584450000068],
              [108.35845429600008, 14.49335886300007],
              [108.36097189000002, 14.496393700000086],
              [108.36564139800001, 14.502801750000048],
              [108.37080508000004, 14.507574577000069],
              [108.37484675500005, 14.510049172],
              [108.37876294600008, 14.511797581000073],
              [108.38000776900004, 14.513187523000058],
              [108.38132113400009, 14.518427763000117],
              [108.38207666200007, 14.523813478000045],
              [108.38183311600008, 14.52641666100005],
              [108.38065646700007, 14.528295167000048],
              [108.37699573800005, 14.530480718000137],
              [108.37440119400004, 14.532524761000063],
              [108.37018547600012, 14.53701127700011],
              [108.36938147600003, 14.538949609000083],
              [108.36963316300006, 14.540704525000068],
              [108.37087942400007, 14.542820884000028],
              [108.37206482400009, 14.545603168000108],
              [108.37188602900004, 14.549719481000077],
              [108.37027696600005, 14.553051407000071],
              [108.366559029, 14.5582633740001],
              [108.36426500900008, 14.560809556],
              [108.36079260900001, 14.562594916000114],
              [108.35806207300004, 14.564294350000038],
              [108.35676062900009, 14.566112429],
              [108.35620607200003, 14.568716142000058],
              [108.35616358600008, 14.569009499000083],
              [108.35559224500005, 14.572954260000069],
              [108.35553831100003, 14.577615162000066],
              [108.35573003000009, 14.580641343000076],
              [108.35753451000008, 14.582151585000068],
              [108.36213616600007, 14.584322902000045],
              [108.36524530400001, 14.585709792000044],
              [108.36673989600004, 14.587583666000102],
              [108.36743029800006, 14.591395883000045],
              [108.3687823760001, 14.595320625000062],
              [108.37108829500002, 14.599069518000094],
              [108.37289372400002, 14.600942817000105],
              [108.374510087, 14.601363692000023],
              [108.37649815400009, 14.601178601000074],
              [108.37891947700005, 14.600084757000074],
              [108.38016188500009, 14.599900956000054],
              [108.38152992900004, 14.600443288000074],
              [108.38265069500005, 14.601651893000065],
              [108.38346054900009, 14.602739983000033],
              [108.38532539000002, 14.603099809000051],
              [108.38694004900003, 14.602612636000066],
              [108.38892648600009, 14.601580006000068],
              [108.39085194400005, 14.601152767000068],
              [108.39451805500008, 14.601146014000042],
              [108.39731318100007, 14.600596046000101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 22, code: "AD01", ten_tinh: "Hà Giang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.32928517400003, 23.38416617400005],
              [105.33322627400007, 23.380794392000048],
              [105.33653759200004, 23.378340929000082],
              [105.33852285799999, 23.376975630000096],
              [105.34011965400003, 23.375819374000081],
              [105.34207652500004, 23.37438434700001],
              [105.3429886050001, 23.373602613000074],
              [105.34452729200004, 23.372294338000096],
              [105.34594196400003, 23.37078952300002],
              [105.34671723400005, 23.3697062990001],
              [105.34744918200005, 23.368700587000021],
              [105.348404137, 23.36683918900011],
              [105.34880039200002, 23.36567344900002],
              [105.34889564200009, 23.364894295000035],
              [105.34876558100007, 23.362083749000107],
              [105.34861825600005, 23.360521443000067],
              [105.34813213100006, 23.35669056600009],
              [105.347939302, 23.354761817000025],
              [105.34795005700001, 23.353984586000109],
              [105.34842688400002, 23.351252567000103],
              [105.34965537500005, 23.347076049000037],
              [105.35020562100003, 23.345646097000028],
              [105.35073126200008, 23.344672129000088],
              [105.35151621700001, 23.343464622000099],
              [105.35229882700003, 23.342426076000052],
              [105.35421773500008, 23.34013017700007],
              [105.356825277, 23.337355512],
              [105.35876664600005, 23.335265184000065],
              [105.36008831700006, 23.333617398000058],
              [105.3627637270001, 23.330142067000011],
              [105.36438046500011, 23.328067473000047],
              [105.36521804900005, 23.326884754000062],
              [105.36761938300005, 23.322901589000097],
              [105.36852567600008, 23.321147762000024],
              [105.36947715400001, 23.320147968000079],
              [105.37200216700003, 23.318372263000064],
              [105.37492175100002, 23.316312290000063],
              [105.37902015100011, 23.313760546000047],
              [105.38090928200009, 23.312510692000018],
              [105.38216069300007, 23.311326728000026],
              [105.38324428900002, 23.309905191],
              [105.38589604900005, 23.311715609000046],
              [105.38715354, 23.312315041000083],
              [105.38789172400006, 23.312323621000083],
              [105.38874332100009, 23.311748707000035],
              [105.39270742800008, 23.307213677000057],
              [105.39866496800006, 23.300059251000071],
              [105.40400764900008, 23.294307346000039],
              [105.40701328800007, 23.291071247000069],
              [105.40939800200003, 23.288531368000065],
              [105.4119869460001, 23.286685020000064],
              [105.41533288100001, 23.284156120000077],
              [105.41651184100004, 23.283873433000046],
              [105.41758000500005, 23.283885711],
              [105.419707014, 23.284601376000055],
              [105.4251108820001, 23.287921992000093],
              [105.42521239600006, 23.288318138000093],
              [105.42520311600001, 23.289009256000078],
              [105.42377069900004, 23.292251436000107],
              [105.42353981500004, 23.293532466000052],
              [105.42353052900008, 23.294223575000039],
              [105.42383508200005, 23.295411997000045],
              [105.42507186500006, 23.298783466000117],
              [105.42600147100006, 23.301163947000077],
              [105.42672539900011, 23.302949611000123],
              [105.42746126300005, 23.303846711000098],
              [105.42820640400006, 23.304052723],
              [105.43493781600006, 23.304030913000062],
              [105.43611555000007, 23.303846867000019],
              [105.43718910399998, 23.303464143000063],
              [105.43934673000007, 23.301908851000071],
              [105.44141067100004, 23.299365046],
              [105.44393212500003, 23.294555287000037],
              [105.44438177600003, 23.292881751000039],
              [105.44450834300002, 23.291402015000081],
              [105.444542552, 23.288835019000054],
              [105.44499481700004, 23.286963997000051],
              [105.44662863300005, 23.28461264900001],
              [105.4499925370001, 23.278917605000068],
              [105.45154112300007, 23.276941672000092],
              [105.45577040300006, 23.273706173000086],
              [105.45833979800003, 23.271272665000083],
              [105.46014191200001, 23.269299506000074],
              [105.46363624500003, 23.264061942000055],
              [105.46752171200008, 23.258007772000063],
              [105.47183352600005, 23.252035211000077],
              [105.47762382400006, 23.246924442000072],
              [105.48118571200008, 23.244232570000101],
              [105.48493000400001, 23.242159538000081],
              [105.48656157900008, 23.241296559000091],
              [105.48762252100002, 23.240339053000092],
              [105.48897855900005, 23.238679841000121],
              [105.49034840700006, 23.235963269000059],
              [105.49075263100011, 23.234205259000085],
              [105.49067692700007, 23.232706274000044],
              [105.48986773200008, 23.22899590500009],
              [105.48952232800009, 23.22626010200004],
              [105.4896522010001, 23.223617711000074],
              [105.49015053400002, 23.221948848000103],
              [105.49092665100002, 23.220899973000094],
              [105.49353273300009, 23.218373324000119],
              [105.49782289900006, 23.210930104000035],
              [105.49831653200005, 23.209613642000093],
              [105.49833836100008, 23.207939390000028],
              [105.4979951720001, 23.205027219000037],
              [105.49801125900009, 23.203793549000103],
              [105.49878725000009, 23.202744602000088],
              [105.49974575800009, 23.202314611000041],
              [105.51870857800002, 23.193612398000063],
              [105.52066282300007, 23.192876597000023],
              [105.5235368940001, 23.192245595000124],
              [105.52609904100009, 23.191989822000011],
              [105.52732589900008, 23.192098006],
              [105.53109905600007, 23.193180931000065],
              [105.53482483300006, 23.194467986000063],
              [105.53619418000002, 23.194941001000032],
              [105.53813623700005, 23.195151650000057],
              [105.53913833500009, 23.195162634000077],
              [105.54039017100011, 23.194986999000022],
              [105.54162674800001, 23.194337836000045],
              [105.5459795310001, 23.190125137000095],
              [105.54690542600005, 23.189756558000063],
              [105.54892528800012, 23.189348622000061],
              [105.55070402000003, 23.188851279000033],
              [105.55141927700006, 23.188419193000072],
              [105.55586958800004, 23.180049176000047],
              [105.55608858200004, 23.179094901000049],
              [105.55610076300007, 23.178138373000046],
              [105.55570664200006, 23.176603419000052],
              [105.55571516900005, 23.175933846000071],
              [105.55613730400007, 23.175268775000021],
              [105.55864324200012, 23.173382696000111],
              [105.56249817500004, 23.171128561000032],
              [105.5637044260001, 23.1699045720001],
              [105.56443913500006, 23.16905152800004],
              [105.56627629500009, 23.166768817000083],
              [105.56765195500006, 23.164296330000056],
              [105.56912734700009, 23.162111911000061],
              [105.56965520900008, 23.161256595000076],
              [105.57030463100007, 23.158967542000042],
              [105.5707365490001, 23.154150750000021],
              [105.57075822100003, 23.152438279000087],
              [105.57028344100009, 23.150948795000126],
              [105.56764119700006, 23.148491993000064],
              [105.56491460900006, 23.146176211000032],
              [105.56265869700009, 23.144217220000129],
              [105.561444685, 23.142533318000069],
              [105.56070018600005, 23.141294171000126],
              [105.56052355700008, 23.140237065000058],
              [105.56053361200003, 23.139445768000044],
              [105.56093825600003, 23.137515636000039],
              [105.56153179500011, 23.135675475000106],
              [105.561637977, 23.134797285],
              [105.56145911500005, 23.133915997000095],
              [105.56043073600004, 23.132585811000084],
              [105.55865344800007, 23.130368109000088],
              [105.5584701320001, 23.129838508000056],
              [105.55848019500004, 23.129047201000041],
              [105.56142269700005, 23.120299088000081],
              [105.56013239200004, 23.120060638000069],
              [105.55668636200002, 23.119223422000076],
              [105.5556556180001, 23.118732358000038],
              [105.55420405800008, 23.117356990000104],
              [105.55251165500002, 23.114539448000038],
              [105.55046451700011, 23.112437779000047],
              [105.54917666200004, 23.111783914000036],
              [105.54478348800008, 23.110696222000016],
              [105.54003861800007, 23.110084437000111],
              [105.538136787, 23.110143556000118],
              [105.53596445400004, 23.11107941600001],
              [105.53353495900009, 23.111852481000113],
              [105.53000618200006, 23.112490471000058],
              [105.52976366100008, 23.112048169000055],
              [105.52960035600006, 23.111576083000074],
              [105.52919432100003, 23.110869806000075],
              [105.5260025900001, 23.104678672000119],
              [105.52472808800007, 23.102134715000133],
              [105.52287582300002, 23.09831937900011],
              [105.52038217200003, 23.093561923000024],
              [105.51690688499998, 23.087326693000065],
              [105.51585131400005, 23.08526023200006],
              [105.51566752400002, 23.084618028000037],
              [105.51567120400006, 23.084333551000036],
              [105.515907672, 23.083873830000101],
              [105.51656715500012, 23.083383222000094],
              [105.517502886, 23.082326641000073],
              [105.51805078800004, 23.081550273000047],
              [105.51832795300005, 23.080913164],
              [105.51868745800004, 23.079850198000074],
              [105.51877760900004, 23.078819800000083],
              [105.51879322800001, 23.077610760000056],
              [105.51857608000007, 23.076576968000047],
              [105.518201597, 23.075825955000084],
              [105.51627151400008, 23.073563968000066],
              [105.51135578600005, 23.067498872000058],
              [105.50760384400003, 23.063022183000022],
              [105.50720792300004, 23.062216572000068],
              [105.50663895100004, 23.061221246000052],
              [105.50640388000005, 23.06060403000005],
              [105.506198376, 23.059906484000066],
              [105.50609949300008, 23.059259780000026],
              [105.50594633900002, 23.058662138000052],
              [105.50552878300002, 23.056662503000069],
              [105.50507060100004, 23.055219776000065],
              [105.50398991000006, 23.052286173000077],
              [105.50348733900005, 23.050812293000078],
              [105.50317956000006, 23.049982255000053],
              [105.50285828600005, 23.04905319600001],
              [105.50271892700006, 23.048384360000092],
              [105.50248665700012, 23.045990306000071],
              [105.50237305700004, 23.04411126600003],
              [105.502241595, 23.043462023000046],
              [105.50205348500006, 23.042863986000036],
              [105.50177875400007, 23.042472258000046],
              [105.50144503600009, 23.042313075000017],
              [105.49987993800008, 23.042166079000111],
              [105.49843665700004, 23.042030048000051],
              [105.49796237700005, 23.041921112000097],
              [105.49717144900006, 23.041721304000085],
              [105.49625492600009, 23.041192849000055],
              [105.49572807100002, 23.040824216000111],
              [105.49536975700003, 23.040405636000024],
              [105.49506842200006, 23.039909962000046],
              [105.49377748200001, 23.036980531000047],
              [105.49274138900007, 23.034896047000061],
              [105.49093018300005, 23.030030336],
              [105.49015609400004, 23.027171382000056],
              [105.48944441000005, 23.023820769000025],
              [105.48902604100007, 23.020183265000057],
              [105.48914054300003, 23.008409837000073],
              [105.47982386000011, 23.009295619000092],
              [105.47391999300004, 23.008901098000045],
              [105.47165798, 23.008440976000074],
              [105.46970303900008, 23.008418926000026],
              [105.46664952000003, 23.008674201000098],
              [105.465088436, 23.008439245000112],
              [105.46189381000001, 23.007533858000073],
              [105.45441568400005, 23.005275933000036],
              [105.45130800300005, 23.003719414000031],
              [105.44867168900007, 23.002057373000092],
              [105.44766236900004, 23.000924547000068],
              [105.44683126100001, 23.00039754700007],
              [105.44608752800012, 23.000302821000048],
              [105.44496678500012, 23.000548823000067],
              [105.44261150000001, 23.002592140000019],
              [105.44109761900008, 23.004386252000053],
              [105.440159615, 23.004893088000067],
              [105.43922850200006, 23.004882457000072],
              [105.43281417800003, 23.00403280500003],
              [105.43104738000002, 23.003840059000069],
              [105.43048985500009, 23.003386219000085],
              [105.43030764700002, 23.002751607000071],
              [105.43043710300007, 23.001638787000012],
              [105.43056797600006, 23.000419858000051],
              [105.43046051300006, 22.999888005000102],
              [105.43001151900003, 22.999193049000063],
              [105.42938857100009, 22.998655286000066],
              [105.42876136700006, 22.998435840000027],
              [105.42750198200005, 22.998368338000127],
              [105.42606509400008, 22.998723283000096],
              [105.420815873, 23.001422206000122],
              [105.41776518900009, 23.00250140100006],
              [105.417077147, 23.002546533000064],
              [105.4163912330001, 23.002432509000045],
              [105.4157096120001, 23.002000157000069],
              [105.41424251800004, 23.000338316000054],
              [105.41318138, 22.998309708000072],
              [105.41245106900004, 22.997240032000029],
              [105.41177233300002, 22.996595440000078],
              [105.41126042500012, 22.996324217000016],
              [105.41074636400003, 22.99621215800007],
              [105.40519058800007, 22.996147934000085],
              [105.40216724300004, 22.995554425000094],
              [105.39155185500003, 22.993595621000026],
              [105.38972380300004, 22.993098456000013],
              [105.38746010000006, 22.992256277000052],
              [105.38461400100002, 22.991067358000095],
              [105.38256307100005, 22.990771486000128],
              [105.37948206300004, 22.990667513000048],
              [105.37595613200007, 22.990898215000044],
              [105.37503610800006, 22.990887445000041],
              [105.37470868300007, 22.990883613000079],
              [105.37346402200011, 22.990665081000117],
              [105.3728091780001, 22.990249500000104],
              [105.37100073300005, 22.988324728000094],
              [105.36803037600004, 22.985502475000082],
              [105.36519662100005, 22.983429624000081],
              [105.36166341200001, 22.98117797300003],
              [105.36039164400003, 22.980312183000073],
              [105.36030746799999, 22.979970450000039],
              [105.36010620500004, 22.978577169000047],
              [105.35957060700004, 22.974082242000101],
              [105.3598439830001, 22.972649318000023],
              [105.36157345400002, 22.969926272000052],
              [105.36259438800003, 22.967456144000039],
              [105.36246953500009, 22.966278920000072],
              [105.36095820600011, 22.963387045000069],
              [105.36029098500005, 22.960635725000067],
              [105.36065542900009, 22.954630464000033],
              [105.360358069, 22.945481828000069],
              [105.36037959100001, 22.943914323000044],
              [105.35998180700005, 22.942080585000021],
              [105.35930211400003, 22.940243518000081],
              [105.35763392000003, 22.938525430000119],
              [105.35455436500004, 22.936921318000088],
              [105.34444142200005, 22.934188740000039],
              [105.34049463200007, 22.934141941000071],
              [105.33583763300004, 22.934478585000036],
              [105.33259381900005, 22.934570644000104],
              [105.33160712000003, 22.934558898000112],
              [105.33062767500009, 22.934024638000047],
              [105.33049397200004, 22.933500449000022],
              [105.33040648100008, 22.932160718000105],
              [105.33094355900002, 22.92997557700005],
              [105.33046732600005, 22.927778366000062],
              [105.32813779800004, 22.925245958000048],
              [105.32579744600011, 22.923496094000129],
              [105.32362819000004, 22.921591707000033],
              [105.3228121380001, 22.919546914000044],
              [105.32149167100002, 22.917339542000015],
              [105.3203226640001, 22.916386304000099],
              [105.31644518500008, 22.915870271000038],
              [105.31256336100006, 22.915667204000023],
              [105.30970133700005, 22.91500669100008],
              [105.307177059, 22.914350189000032],
              [105.30601033400009, 22.913240357000042],
              [105.30552565500005, 22.911669081000063],
              [105.30374932500007, 22.905855489000082],
              [105.30345988800011, 22.902407947000114],
              [105.30369896900008, 22.897401239000104],
              [105.30410688600008, 22.892396505000079],
              [105.30398850300006, 22.888794360000105],
              [105.30195379500002, 22.877341311000073],
              [105.30082361800002, 22.876565816000038],
              [105.29772362000004, 22.876073628000078],
              [105.29429024600009, 22.876032187000114],
              [105.27839492900003, 22.878416926000078],
              [105.27051273400005, 22.880746690000109],
              [105.26788385800005, 22.881624270000067],
              [105.26706421700008, 22.88176587400006],
              [105.26625103700003, 22.881452751],
              [105.26625965400004, 22.88084644300001],
              [105.266206579, 22.880278934000039],
              [105.26554991500002, 22.873257524000046],
              [105.26479064900008, 22.869154863000048],
              [105.264816513, 22.867335886],
              [105.26548338400002, 22.866434374000022],
              [105.26860682900009, 22.865259614000074],
              [105.27141186200001, 22.863474483000076],
              [105.27488794400003, 22.860484595000052],
              [105.27803267399999, 22.857793834000084],
              [105.28000726200003, 22.856908149000077],
              [105.29014926400004, 22.856576241000056],
              [105.29783269000009, 22.856669063000098],
              [105.29921487200005, 22.856420757000073],
              [105.30016234500002, 22.855956264],
              [105.30396219500001, 22.853384482000116],
              [105.30637911700006, 22.851827165000046],
              [105.30775861300006, 22.851050550000096],
              [105.31800679400007, 22.846663575000022],
              [105.32089540100006, 22.845650936000084],
              [105.32213374000003, 22.845189747000028],
              [105.32265623100008, 22.844529581000117],
              [105.32341181000008, 22.841872979000044],
              [105.32428985900007, 22.837789780000058],
              [105.32493480600006, 22.835703020000061],
              [105.32577179300003, 22.834570580000069],
              [105.32753381500007, 22.833353975000037],
              [105.33495399300003, 22.83125271400008],
              [105.33897126800005, 22.830253239000072],
              [105.34097787500008, 22.829519129000083],
              [105.34154185600003, 22.829009298000088],
              [105.34235670900004, 22.827616952000085],
              [105.34258203100003, 22.82282324400002],
              [105.34259833300004, 22.821642779000051],
              [105.34302561100006, 22.819507902000069],
              [105.34432786100007, 22.817383393000057],
              [105.34562905399999, 22.815332644000108],
              [105.34659477200005, 22.814532375000063],
              [105.34723416300001, 22.814318569000044],
              [105.34794598100002, 22.814622173000117],
              [105.34871566800003, 22.815159869000112],
              [105.35030992900005, 22.816273586000065],
              [105.35228837100004, 22.817034909000085],
              [105.3548276990001, 22.817507671000037],
              [105.35737311800003, 22.817537724000125],
              [105.36040895900004, 22.816614231000095],
              [105.36808453000008, 22.813826686000112],
              [105.36995764900007, 22.813240987000057],
              [105.37099979900006, 22.813156661],
              [105.3722090470001, 22.813305521000068],
              [105.373703328, 22.813381505000052],
              [105.37671911500004, 22.813609974000123],
              [105.37859261799998, 22.813631915000073],
              [105.38067955100009, 22.813270101000072],
              [105.38298382100005, 22.812234898000035],
              [105.38761196600009, 22.808716234000045],
              [105.39004248700012, 22.806040893000024],
              [105.39271640900002, 22.80076118500007],
              [105.39399670900008, 22.798458607000072],
              [105.39505314300001, 22.797312154000068],
              [105.39631510800007, 22.796361209000082],
              [105.39777605800006, 22.796088511000079],
              [105.40016977200005, 22.796116320000067],
              [105.40267275700003, 22.795759120000092],
              [105.40455781600012, 22.794911912000067],
              [105.40718956600008, 22.792721461000021],
              [105.40952334400001, 22.789465315000079],
              [105.41099592100008, 22.788323581000022],
              [105.412986222, 22.787380946000013],
              [105.41627263400005, 22.786328402000052],
              [105.41968080500004, 22.785955896000054],
              [105.42057396800006, 22.785554365000046],
              [105.42088272300002, 22.784597009000059],
              [105.42092134300002, 22.781714703000056],
              [105.42124112200001, 22.779933818000053],
              [105.42244299100007, 22.778574905000056],
              [105.42393152700004, 22.77790566500002],
              [105.43684023100008, 22.775171049000065],
              [105.44204706400004, 22.773034181000092],
              [105.44354278000006, 22.771815772000089],
              [105.44465783300004, 22.769264809000056],
              [105.444916049, 22.768674063000027],
              [105.44692285100004, 22.76238212100008],
              [105.44798014200003, 22.760746818000044],
              [105.44932962800006, 22.759389389000084],
              [105.45132529500006, 22.758559262000055],
              [105.457756616, 22.756704242000048],
              [105.46262013400006, 22.755554336000024],
              [105.46530574300004, 22.755424086000083],
              [105.46712465100009, 22.755364332000028],
              [105.46773588100004, 22.754969553000045],
              [105.46782984400008, 22.754408231000056],
              [105.46784040900008, 22.753604948000117],
              [105.46751528300008, 22.751994462000091],
              [105.46761135800003, 22.751272479000036],
              [105.47033806700004, 22.748009330000052],
              [105.47419944100005, 22.744035853000085],
              [105.47595485300009, 22.74220778500009],
              [105.47659953600005, 22.740901291000078],
              [105.4766174370001, 22.73953535800004],
              [105.47550067, 22.736497746000062],
              [105.47451210200009, 22.731705060000081],
              [105.47416711900004, 22.725943758000078],
              [105.47421575000007, 22.722236100000082],
              [105.47476309800007, 22.720583332000018],
              [105.47584241900002, 22.718448636000055],
              [105.47830355500008, 22.715158460000048],
              [105.48076304700001, 22.711938629000095],
              [105.48286210300006, 22.710172842000098],
              [105.48718143700003, 22.70870728200007],
              [105.49776692200004, 22.704283545000067],
              [105.50136437500007, 22.701295476000013],
              [105.50243963400004, 22.698416841000046],
              [105.50245748, 22.697040543000035],
              [105.50188053900006, 22.695795244000024],
              [105.4979028070001, 22.693823677000054],
              [105.49525044100002, 22.692555130000081],
              [105.49423418500004, 22.690891928000042],
              [105.49057700900005, 22.684347365000065],
              [105.48858357400005, 22.680779835000031],
              [105.48771747500007, 22.678980587000105],
              [105.486283601, 22.675247753000072],
              [105.48629617300006, 22.67428429400011],
              [105.48705529299998, 22.672916243000074],
              [105.48952982800009, 22.67026242000005],
              [105.49187697300005, 22.668049965000044],
              [105.49320304, 22.665912139000085],
              [105.49484865699999, 22.660591869000065],
              [105.49488560000003, 22.657750690000086],
              [105.49480632100004, 22.656716491000047],
              [105.49444778800004, 22.655765268000039],
              [105.49290639600005, 22.653078586000049],
              [105.49218487300004, 22.651520514000097],
              [105.49184317400001, 22.649277805000075],
              [105.49207005700003, 22.646094234000103],
              [105.49256048400012, 22.64403305600009],
              [105.49454988800005, 22.640783093000039],
              [105.49627228300001, 22.636668964000073],
              [105.49637952900009, 22.635550696000095],
              [105.49629736800004, 22.634995205000052],
              [105.49588443900008, 22.632203218000058],
              [105.49395365800011, 22.628236092000087],
              [105.49228215300009, 22.626113064000016],
              [105.48906197100011, 22.623315047000055],
              [105.48528417700008, 22.619853089000053],
              [105.48387193800002, 22.619574162000063],
              [105.48217312100007, 22.619555039000083],
              [105.47819374900006, 22.620693871000071],
              [105.47592349100006, 22.621062811000044],
              [105.47493250200006, 22.62105162200001],
              [105.47310417900003, 22.62011033200011],
              [105.46508670900005, 22.616073966],
              [105.463431998, 22.614989908000013],
              [105.46164609200011, 22.613348494],
              [105.45880998100003, 22.611000345000051],
              [105.45822638700005, 22.610442765000101],
              [105.45750270100004, 22.610011066000091],
              [105.4558224350001, 22.60952613900011],
              [105.45418550900011, 22.609211078000065],
              [105.45311412400011, 22.609220056000098],
              [105.4527630060001, 22.609262117000029],
              [105.45216712600009, 22.609509405000061],
              [105.45047962500009, 22.610227520000059],
              [105.45002528500007, 22.610466223000088],
              [105.44951422200005, 22.610639486000039],
              [105.44887652100005, 22.610802099000082],
              [105.44851914400004, 22.610880778000109],
              [105.447830439, 22.610620767000086],
              [105.44642927500007, 22.609715484000056],
              [105.44134058700011, 22.606032594000069],
              [105.44086573600008, 22.605760463000109],
              [105.44048332800006, 22.605733866000044],
              [105.44028931900007, 22.605931662000067],
              [105.44009264800006, 22.606329436000024],
              [105.43992906600002, 22.607838792000031],
              [105.43991194700003, 22.609127584000085],
              [105.439761339, 22.609659230000112],
              [105.43947016700007, 22.609967029000089],
              [105.43884294500005, 22.610359877000086],
              [105.43836395200003, 22.610398838000023],
              [105.43774203400008, 22.610391712000052],
              [105.43659506700004, 22.610289667000046],
              [105.43573749600004, 22.610013149000082],
              [105.43516814900002, 22.609651035000056],
              [105.43389483300008, 22.608258549000126],
              [105.43296068100007, 22.606881680000065],
              [105.42978324800006, 22.609094819000042],
              [105.42864375100001, 22.609607811000032],
              [105.42396700400005, 22.60994855200007],
              [105.41628740400003, 22.611388511000108],
              [105.411004507, 22.612781218000052],
              [105.41014921500002, 22.612903479000046],
              [105.40928858400004, 22.613422175000061],
              [105.40754419900003, 22.616177417000038],
              [105.40405172000008, 22.621952098000087],
              [105.40159409800003, 22.624831117],
              [105.39929292700006, 22.626654630000061],
              [105.39541428800004, 22.629384869000098],
              [105.39341004400003, 22.630286640000108],
              [105.39212785799999, 22.630403852000121],
              [105.3887188300001, 22.629967592000085],
              [105.38659378400004, 22.629281957000025],
              [105.38446517700005, 22.628860592000066],
              [105.38290025300006, 22.628842282000058],
              [105.38133353500011, 22.628956103000064],
              [105.3710273180001, 22.633460787000011],
              [105.36359679600011, 22.635752175000064],
              [105.36221271400007, 22.636896472000068],
              [105.36057267299999, 22.638919156],
              [105.35983057900006, 22.639591067000083],
              [105.35879910400007, 22.639987287000096],
              [105.3536640830001, 22.640334989000088],
              [105.343542423, 22.640895656000026],
              [105.33636687000003, 22.640401905000118],
              [105.32714713300005, 22.639338951000099],
              [105.32496206100009, 22.638359881],
              [105.32438907700002, 22.637400081000081],
              [105.32387847900006, 22.631948516000122],
              [105.32391250400012, 22.629498443000081],
              [105.32335467600006, 22.627449694000035],
              [105.32178128500009, 22.62619124600009],
              [105.31915418600005, 22.625459875000061],
              [105.31746395800009, 22.625089659000075],
              [105.31616990800005, 22.623324399000033],
              [105.31511541300002, 22.617887529000043],
              [105.31478992500008, 22.614209109000072],
              [105.31520802700004, 22.611239511000036],
              [105.31639403800003, 22.607229236000045],
              [105.31639989500007, 22.606808549000057],
              [105.31640621800005, 22.606354484000057],
              [105.31623251200007, 22.605302518000059],
              [105.31529332300008, 22.605116262000102],
              [105.31434926000004, 22.605279904000049],
              [105.3124489410001, 22.606481929000033],
              [105.31110380700009, 22.608390531000033],
              [105.31071250200004, 22.609435699000088],
              [105.31029434500009, 22.612405300000127],
              [105.309907911, 22.613100561000095],
              [105.30876814600002, 22.613786755000028],
              [105.29914354700001, 22.615070517000021],
              [105.2885796130001, 22.616167507000014],
              [105.28573487900009, 22.617532803000095],
              [105.28515261900006, 22.618750565000042],
              [105.2852990590001, 22.621726918000014],
              [105.28599082300005, 22.626109665000094],
              [105.28614221800004, 22.628736087000064],
              [105.28396930400002, 22.635883494],
              [105.28203147100001, 22.639709263],
              [105.27877997500009, 22.643169063000101],
              [105.27459148600011, 22.646267443000042],
              [105.26738898200004, 22.649328929000134],
              [105.26380006600002, 22.649984905000053],
              [105.25415591800008, 22.652491161000029],
              [105.25071958900006, 22.653637660000037],
              [105.24885113800011, 22.654026586000064],
              [105.24779331100007, 22.654066299000064],
              [105.24624904400009, 22.653019543000049],
              [105.24581725800007, 22.652696824000081],
              [105.24544097200003, 22.652415675],
              [105.244976003, 22.652011063000096],
              [105.24409538200007, 22.65124485300003],
              [105.24309043700005, 22.65046820600007],
              [105.24236101900001, 22.651928329],
              [105.24167257500007, 22.65404132200004],
              [105.24103791200005, 22.655734097000035],
              [105.24030964400001, 22.656858848000105],
              [105.23660570300005, 22.659741929000084],
              [105.23188576700005, 22.662517870000087],
              [105.22983635300004, 22.663531745000043],
              [105.22881639900002, 22.663708062000062],
              [105.22546494100001, 22.663288602000087],
              [105.21968031900012, 22.662272011000084],
              [105.21744103700011, 22.662338636000086],
              [105.21529392900001, 22.663067731000105],
              [105.21210905500001, 22.665201013000043],
              [105.21014618900006, 22.667255015000087],
              [105.20931459100008, 22.668472830000042],
              [105.20772571900007, 22.672798862000043],
              [105.20675480400011, 22.674802182000064],
              [105.20529311300005, 22.676416152000023],
              [105.20384001200009, 22.677436671000081],
              [105.20239551400002, 22.677863762000072],
              [105.19663688900006, 22.67823681800003],
              [105.19054785600011, 22.679347485000086],
              [105.17434884800007, 22.683298151000116],
              [105.17192809000005, 22.684899753000117],
              [105.16997576300008, 22.687249154000085],
              [105.16867782400007, 22.688568157000063],
              [105.16593519400007, 22.690313983000095],
              [105.15725607800005, 22.693616575000064],
              [105.14698307700004, 22.696601744000056],
              [105.14649051800006, 22.696493938000017],
              [105.14528948800007, 22.695451961000046],
              [105.14187915300008, 22.692385205000051],
              [105.13961470400002, 22.690348797000048],
              [105.13744004300004, 22.687856954000019],
              [105.13636494300007, 22.685789823000043],
              [105.13371088500004, 22.678363635000039],
              [105.13230726500008, 22.676086896000044],
              [105.13002842, 22.673182883000017],
              [105.12894736099999, 22.671526264000086],
              [105.12884904400002, 22.670703627000051],
              [105.12885516400009, 22.670293013],
              [105.130200147, 22.669078211000034],
              [105.134767874, 22.6667753],
              [105.13699566700004, 22.665674421000048],
              [105.13781718700007, 22.664667583000053],
              [105.13855355000007, 22.662207599000084],
              [105.13818199900005, 22.658953594000053],
              [105.1389125470001, 22.656883409],
              [105.14061295700003, 22.655475475000038],
              [105.14259319600009, 22.654071091000013],
              [105.14429740700005, 22.652403222000125],
              [105.14676281100003, 22.646585910000013],
              [105.15253650000008, 22.634701759000016],
              [105.15678842199999, 22.626372431000071],
              [105.15769811100003, 22.624397638000104],
              [105.15770845, 22.623696699000014],
              [105.15721743300006, 22.622872545000035],
              [105.15422484300007, 22.621081794000105],
              [105.15108925800007, 22.620457638000069],
              [105.14970236100007, 22.620673652000086],
              [105.14524682100009, 22.624239019000036],
              [105.14308620800003, 22.625730403000063],
              [105.14195079700005, 22.625949589000079],
              [105.14069481700005, 22.625816685000096],
              [105.139323468, 22.62498122700007],
              [105.13872757500008, 22.622753548000048],
              [105.13818373200012, 22.617021133000037],
              [105.13770496200002, 22.615379146000066],
              [105.13684196200003, 22.614199618000086],
              [105.1346025350001, 22.612535060000113],
              [105.13047844900011, 22.610729451000019],
              [105.12836487200011, 22.609066433000088],
              [105.12613428700003, 22.606817639],
              [105.12602071800005, 22.605998227000065],
              [105.12604336500006, 22.604479473000083],
              [105.12790669000003, 22.597609211000069],
              [105.12889012500005, 22.595457813000053],
              [105.13047676200006, 22.593928405000071],
              [105.13185346800002, 22.59249314300007],
              [105.13196490100009, 22.592010261000084],
              [105.13197498800012, 22.591332360000038],
              [105.13115839700006, 22.590159529000076],
              [105.12920101200011, 22.588584603000058],
              [105.12568303600008, 22.586795864000031],
              [105.12309020000005, 22.585890740000103],
              [105.12185097900002, 22.585099883000034],
              [105.11887021100003, 22.582252429000071],
              [105.11454905000008, 22.578418967000054],
              [105.11383685000003, 22.577247394000018],
              [105.11363999100008, 22.576469932000087],
              [105.11365594200007, 22.575404613000046],
              [105.11589253200003, 22.572236905000054],
              [105.11770490800006, 22.56954802700006],
              [105.11911770600008, 22.565691545],
              [105.119172699, 22.562011276000057],
              [105.11881082300009, 22.558325595000014],
              [105.11884555800003, 22.556001174000073],
              [105.11924426400006, 22.554487527000063],
              [105.12342632600006, 22.547899769000068],
              [105.12449655200005, 22.544592703000085],
              [105.12542727200005, 22.539228016000031],
              [105.12547441100006, 22.536065851000053],
              [105.12501358900002, 22.532738991000123],
              [105.12206967000007, 22.524794025000091],
              [105.11649437300009, 22.510805364000049],
              [105.11201096200003, 22.503472602000052],
              [105.10867337400002, 22.499159355000039],
              [105.10684996700004, 22.495972703000078],
              [105.1061957740001, 22.494224555000031],
              [105.10621000700003, 22.493275818000043],
              [105.10614989500003, 22.49185519400001],
              [105.10670760699999, 22.49073098100007],
              [105.10717698400002, 22.490137670000095],
              [105.10946407000007, 22.487246611000074],
              [105.11003304700007, 22.485368216],
              [105.11005376000001, 22.483985592000089],
              [105.10918471400005, 22.478819892000068],
              [105.10871747500002, 22.473910778000047],
              [105.10877963900002, 22.469762801000037],
              [105.1092378860001, 22.466248521000054],
              [105.11033995100007, 22.464879834000051],
              [105.11307716100006, 22.462652218000031],
              [105.11539753900011, 22.461173515000077],
              [105.11595511800006, 22.460049206000043],
              [105.11599081700008, 22.457660903000054],
              [105.11559471700008, 22.457027170000018],
              [105.11413237900005, 22.455373848000093],
              [105.09603887500002, 22.444955443000048],
              [105.08838632400003, 22.441461127000117],
              [105.08650749200009, 22.440556517000054],
              [105.08624668200009, 22.439924463000068],
              [105.08625996900004, 22.439044529000093],
              [105.08699762300006, 22.436580184000029],
              [105.08917560700004, 22.434217375000017],
              [105.09220027900002, 22.4325297990001],
              [105.09481253100007, 22.42977403499999],
              [105.09822450900003, 22.42131615300007],
              [105.09855001600005, 22.41866340600005],
              [105.09805278700003, 22.413741486000035],
              [105.09347312200001, 22.404913664000055],
              [105.09069377, 22.399829008000083],
              [105.08888186600008, 22.39688257600001],
              [105.08746624000001, 22.396066972000114],
              [105.08532877000009, 22.395773361000067],
              [105.08060749700007, 22.396375944000013],
              [105.07745861700003, 22.39686616700007],
              [105.0757494580001, 22.39657807200004],
              [105.07447866500011, 22.395631429000048],
              [105.0734974420001, 22.394422860000063],
              [105.07139990600011, 22.387940630000088],
              [105.07068539800005, 22.385702681000019],
              [105.07071286900002, 22.383892313000061],
              [105.07147807200009, 22.382788064000088],
              [105.07544098900007, 22.378104238000063],
              [105.08013476000012, 22.371665533000048],
              [105.0840837420001, 22.364725173000096],
              [105.08464709700009, 22.362130839000116],
              [105.08466918900004, 22.360667667000108],
              [105.08399078800005, 22.358852416000019],
              [105.08332570400009, 22.357072749000025],
              [105.08143609500006, 22.354934136000089],
              [105.07866081800003, 22.353596946000032],
              [105.07621771200003, 22.353402345000092],
              [105.06042942200003, 22.357585377000078],
              [105.05447766900008, 22.358319937000104],
              [105.04661674400008, 22.358216045000034],
              [105.04487734000004, 22.35770519400004],
              [105.04245164800007, 22.356372197000063],
              [105.04020814700007, 22.354553749000061],
              [105.03917999300009, 22.3532392380001],
              [105.03920242900008, 22.351776043000093],
              [105.04077878100003, 22.350696154],
              [105.04428928800006, 22.348270587000037],
              [105.04721015600006, 22.344315846000114],
              [105.04871558900001, 22.33939150100008],
              [105.04996730600001, 22.337696570000084],
              [105.05490147800006, 22.335669926],
              [105.05779297200003, 22.333616232000075],
              [105.05863605800006, 22.331915844000072],
              [105.05849551800007, 22.327730279000058],
              [105.05756695900007, 22.321632584000014],
              [105.05766254700008, 22.315358132000092],
              [105.05746990000003, 22.314594891000091],
              [105.05686298400009, 22.314206535000025],
              [105.05584180000005, 22.314193061000061],
              [105.04665407000006, 22.313881448000039],
              [105.04482175600006, 22.313476842000043],
              [105.04380640600007, 22.313083039000055],
              [105.04260427700008, 22.311545696000046],
              [105.04202069600002, 22.30963619900006],
              [105.04223007000003, 22.30844279800008],
              [105.04349397800007, 22.306803069000033],
              [105.04621001400008, 22.30284529500004],
              [105.04712713800004, 22.300457613000013],
              [105.04735610700006, 22.294682688000123],
              [105.04717224000004, 22.293348980000083],
              [105.04658866900006, 22.291439423],
              [105.04586404900006, 22.290227929000039],
              [105.04459226900008, 22.289009541000034],
              [105.0400914840001, 22.287988633000033],
              [105.03341597300003, 22.285737169000086],
              [105.02714613000005, 22.282289341000094],
              [105.02280422100006, 22.279347676000057],
              [105.019236484, 22.276416254000075],
              [105.01696631000003, 22.273021485000029],
              [105.01520665300009, 22.269993981000091],
              [105.01355563200009, 22.268289650000028],
              [105.01207705500005, 22.267497147000093],
              [105.01104319800004, 22.267603448],
              [105.00942390000009, 22.268474603000051],
              [105.00806248900007, 22.26935859400001],
              [105.007202971, 22.270247202000078],
              [105.00640662900001, 22.271678421000018],
              [105.00583662800001, 22.275155352000105],
              [105.00604622900003, 22.278282259000015],
              [105.00589674500002, 22.279601975000048],
              [105.00510034500012, 22.281033162000071],
              [105.00274650600005, 22.28304420000002],
              [105.00195752600004, 22.283994837000073],
              [105.00194820500008, 22.284595491000033],
              [105.00244561000002, 22.285803731000087],
              [105.00493645300006, 22.291604600000078],
              [105.00591644700005, 22.294982048000023],
              [105.00562862200003, 22.296900625000049],
              [105.00470126800005, 22.298450158],
              [105.00326154000003, 22.299752490000024],
              [105.00000008800001, 22.301389169000082],
              [104.99740270300003, 22.301991134000062],
              [104.99604246600011, 22.302399636000111],
              [104.99476999600007, 22.302889826000083],
              [104.99406792700006, 22.303420892000055],
              [104.99384850300004, 22.303829412000105],
              [104.99376072200005, 22.30448305],
              [104.9927951170001, 22.30975300100004],
              [104.99257563600005, 22.311346237000059],
              [104.99218052700004, 22.314696138000031],
              [104.99178553000004, 22.315717437000067],
              [104.99095170700006, 22.316575287000084],
              [104.98261306600006, 22.324418321000024],
              [104.98147196400006, 22.325153545000106],
              [104.98002367000004, 22.325766150000078],
              [104.97901428300001, 22.325929438000088],
              [104.97822434900012, 22.325929329000083],
              [104.97734672900006, 22.325398133000093],
              [104.97278322500004, 22.322537756000123],
              [104.96909730900006, 22.320821287000037],
              [104.96602558400011, 22.320248735000092],
              [104.96163729400011, 22.320002645000066],
              [104.95689789600002, 22.319919750000047],
              [104.95329917400005, 22.320735805000069],
              [104.95005155200002, 22.32138846700007],
              [104.94706675400006, 22.323185043000038],
              [104.94381799400003, 22.326452155000084],
              [104.94127140700007, 22.329392638000023],
              [104.93933913400008, 22.332333302000059],
              [104.93793411500009, 22.333721748000102],
              [104.93714384700007, 22.334375092000066],
              [104.93547610300001, 22.334374440000104],
              [104.929595556, 22.333473234000053],
              [104.92538299700006, 22.332245779000104],
              [104.92213559400004, 22.331754024000091],
              [104.91695668000008, 22.33215998100011],
              [104.91458656300009, 22.332485556000044],
              [104.91300595700005, 22.333546882000022],
              [104.91221515899998, 22.334853745000061],
              [104.90948913400004, 22.34253250400009],
              [104.90896053700008, 22.345473582000075],
              [104.90843265700002, 22.347270783000091],
              [104.9075534250001, 22.349394617000051],
              [104.90667497700007, 22.350292865000121],
              [104.90421652400005, 22.350945063000083],
              [104.90094642500001, 22.351045244000112],
              [104.89741969400006, 22.350893846000105],
              [104.89502250500006, 22.340455577000014],
              [104.89467354200005, 22.339609308000071],
              [104.89460494200006, 22.337852105000074],
              [104.89474595600009, 22.336290262000077],
              [104.89555279300006, 22.332548666000029],
              [104.89555361900004, 22.33144230100006],
              [104.89534465200006, 22.330400873000045],
              [104.89471610300011, 22.329489342000031],
              [104.89331875500004, 22.328251899000051],
              [104.89101281600003, 22.326688435],
              [104.8900347470001, 22.325776653000062],
              [104.88975576800003, 22.324930415000033],
              [104.88975628300007, 22.324279615000094],
              [104.89054617000005, 22.322791887000015],
              [104.89239759200004, 22.320189848000012],
              [104.89514501900007, 22.316864020000082],
              [104.89577434400009, 22.316669184000126],
              [104.89703268200003, 22.3166699820001],
              [104.89912973800007, 22.316931622000077],
              [104.90020588400003, 22.316802356000039],
              [104.901573129, 22.316463073000037],
              [104.90511511000005, 22.315272046000075],
              [104.90853417600007, 22.314171287000036],
              [104.90930351800004, 22.313585985000103],
              [104.91000313700002, 22.312740332000125],
              [104.91559948800005, 22.306300360000094],
              [104.91596536200004, 22.305620021000024],
              [104.9164479990001, 22.304188374000056],
              [104.91982318400002, 22.293260461000038],
              [104.91988905400007, 22.292315272000081],
              [104.91990700800004, 22.291382109000054],
              [104.91955787200001, 22.290780641000119],
              [104.91878929400011, 22.290324689000052],
              [104.91795055800007, 22.290324267000102],
              [104.91606288300002, 22.291169348000039],
              [104.91452491700005, 22.291624117000097],
              [104.91333669500005, 22.291623484000041],
              [104.91242813500003, 22.291492833000071],
              [104.91151981600009, 22.290971698000114],
              [104.91061183900011, 22.289929900000068],
              [104.90970409100005, 22.288562712000015],
              [104.90925064100001, 22.287228297000091],
              [104.909252694, 22.284039349000025],
              [104.90904344900011, 22.283388418000079],
              [104.90868595000011, 22.282350177000062],
              [104.90727518700008, 22.279820945000047],
              [104.90615991800009, 22.278261059000037],
              [104.90436546400011, 22.276422088000025],
              [104.90345736700003, 22.275770741000045],
              [104.9025488720001, 22.27570511900003],
              [104.90066171400008, 22.275964295000072],
              [104.89828501699999, 22.276678714000028],
              [104.89625778600004, 22.277328247000035],
              [104.89492991300003, 22.277327391000057],
              [104.89388173300009, 22.277131463000011],
              [104.89248466500004, 22.276219409000042],
              [104.89070366400003, 22.274753908000022],
              [104.89021496000001, 22.274102763000059],
              [104.89000606000005, 22.273126407000099],
              [104.89035630100007, 22.272085355000023],
              [104.890915949, 22.271369843000066],
              [104.89217467800006, 22.270329398000044],
              [104.89413233400003, 22.269159221000031],
              [104.8960202260001, 22.267793748000116],
              [104.90157918000006, 22.263209003000028],
              [104.90436151800006, 22.26081157800008],
              [104.90523421400005, 22.258788974000062],
              [104.90577836500005, 22.256292254000073],
              [104.90639342300004, 22.252949876000017],
              [104.90737819500008, 22.250836286000016],
              [104.90994845400012, 22.247741517000108],
              [104.91319816600006, 22.244186548000044],
              [104.9157761700001, 22.241661927000052],
              [104.91627056300007, 22.241270744000104],
              [104.91615535200003, 22.240955827000072],
              [104.91583757000004, 22.240639054000063],
              [104.91459880200003, 22.240209447000048],
              [104.912768199, 22.239432269000048],
              [104.91197904799999, 22.23894160200004],
              [104.91132158500004, 22.238287595000102],
              [104.91075194800007, 22.237470226000063],
              [104.91053361300004, 22.235958537000045],
              [104.91040392400005, 22.232976194000017],
              [104.91036082300003, 22.231791433000048],
              [104.91027809700003, 22.223947581000033],
              [104.91041075700008, 22.222231807000092],
              [104.91155248100004, 22.21978123800001],
              [104.91190426600011, 22.218229003000097],
              [104.91181784400007, 22.216186298000103],
              [104.91085439700002, 22.21414311500007],
              [104.90980292900007, 22.212671819000015],
              [104.90804923100009, 22.212180600000053],
              [104.9067333560001, 22.21275179700006],
              [104.90453968900005, 22.214466358],
              [104.90372810600005, 22.214956116000018],
              [104.90315807500005, 22.214935351000072],
              [104.90197494500003, 22.213709053000038],
              [104.90153718500005, 22.212646606000064],
              [104.89875791900005, 22.205272682000029],
              [104.89807259600009, 22.204121519000083],
              [104.89583620700006, 22.197300973000011],
              [104.89510855800008, 22.191632109000103],
              [104.89506506000002, 22.188606088000107],
              [104.89470049300006, 22.186815825000039],
              [104.89344404300002, 22.185046295000085],
              [104.89239271300008, 22.184235827000087],
              [104.89179825600009, 22.184064954000071],
              [104.89102062900005, 22.184192293000038],
              [104.88941877100004, 22.185555043000079],
              [104.88781715200003, 22.186576823000067],
              [104.88617029000001, 22.186959262000094],
              [104.88379182400004, 22.187085432000053],
              [104.88191676300006, 22.1868283680001],
              [104.88022462200003, 22.186656654000011],
              [104.87894381300001, 22.186826183],
              [104.87775419899999, 22.18733674100006],
              [104.87633478600006, 22.189083074000045],
              [104.8744105910001, 22.192661648000112],
              [104.87285291100009, 22.195473341000046],
              [104.87253180900008, 22.196495964000107],
              [104.87275982400003, 22.197263293000091],
              [104.87390270500001, 22.197988715000072],
              [104.87467935000004, 22.199054804000085],
              [104.87472407300001, 22.200205572000087],
              [104.87399125400005, 22.201270499000088],
              [104.87307587000006, 22.201866460000105],
              [104.87023947400004, 22.202247792000037],
              [104.86671664500005, 22.202926868000091],
              [104.86488647000006, 22.203394170000124],
              [104.86369702700006, 22.203521043000052],
              [104.86303416100002, 22.203158223000052],
              [104.85485029100002, 22.199144889000074],
              [104.85402831500005, 22.197822945000034],
              [104.85370987800007, 22.196117883000085],
              [104.85353037600007, 22.192793397000088],
              [104.85312037400007, 22.191216104000056],
              [104.85156720900008, 22.189339426000032],
              [104.84868819900007, 22.186992705000108],
              [104.84789979000003, 22.186469884000104],
              [104.84735119900003, 22.186234969000026],
              [104.84700712900008, 22.18619764500005],
              [104.84673525900004, 22.186168145000096],
              [104.84591055300011, 22.186148375000059],
              [104.84515614300004, 22.185913246000055],
              [104.84391044199999, 22.185347342000036],
              [104.84130521600009, 22.183746586000019],
              [104.83982028200006, 22.182423929],
              [104.83904362600002, 22.181677315000044],
              [104.83815232200007, 22.1811863080001],
              [104.83716927500009, 22.180950917000075],
              [104.83561426000004, 22.180949350000034],
              [104.82925941800005, 22.178939702000022],
              [104.8286878910001, 22.178811249000049],
              [104.82823045400008, 22.178874694000058],
              [104.82788704100008, 22.179215287000083],
              [104.82752048500005, 22.179768954000068],
              [104.82667263600007, 22.181195813000087],
              [104.82584826800007, 22.18211125800007],
              [104.82225511800004, 22.184387536000116],
              [104.81896021000009, 22.185832974000071],
              [104.81748433800001, 22.18648127900007],
              [104.81666070400007, 22.186757388000089],
              [104.81606600700007, 22.18684195700007],
              [104.81572296800002, 22.186841563000122],
              [104.81549453100004, 22.186649522000046],
              [104.81538058000002, 22.186351046000041],
              [104.81544963600005, 22.186010171000078],
              [104.81638904100006, 22.184626102000045],
              [104.81696213700006, 22.18356125100005],
              [104.81710075100007, 22.182474609000025],
              [104.8170109860001, 22.181153298000119],
              [104.81655534700006, 22.179831572000097],
              [104.81107498800003, 22.174029003000051],
              [104.79955354900002, 22.171628537000032],
              [104.79799913000002, 22.171285664],
              [104.7961688510001, 22.171965305000093],
              [104.79369743100008, 22.173240787000026],
              [104.79159307900005, 22.173664314000071],
              [104.78948935100007, 22.173661610000025],
              [104.78473283700006, 22.17382588100012],
              [104.78153190200007, 22.173565902000099],
              [104.77970309800001, 22.173222473000109],
              [104.77860617700003, 22.172794794000069],
              [104.77796684800006, 22.172197254000103],
              [104.77796818200008, 22.171344864],
              [104.7785202040001, 22.169299873000092],
              [104.77843060100005, 22.168106398000106],
              [104.77760864800008, 22.167338131000044],
              [104.77614618300005, 22.16673945700007],
              [104.7742252140001, 22.166907286000104],
              [104.75949244000003, 22.171233412000021],
              [104.75601325500003, 22.173273972000018],
              [104.75445582400003, 22.174720710000081],
              [104.75408537100003, 22.177362572000092],
              [104.75472016700006, 22.180517375000086],
              [104.75499147700003, 22.182307803000079],
              [104.7547142550001, 22.183926932000105],
              [104.75388818100005, 22.18554522900007],
              [104.75269737100002, 22.186481049000051],
              [104.74986000100009, 22.18741435400009],
              [104.74647465300004, 22.187835313000015],
              [104.74455367900008, 22.18783231300003],
              [104.74162848600007, 22.18671959100007],
              [104.74034816100001, 22.186547073],
              [104.73888441300005, 22.186629975000116],
              [104.73778482100002, 22.187651081000048],
              [104.73695884500006, 22.189098799000107],
              [104.73695411400011, 22.191655969000031],
              [104.73649353900002, 22.193359999000073],
              [104.73548488100005, 22.194636958000032],
              [104.73365288100008, 22.195912545000063],
              [104.7297177360001, 22.196673218000029],
              [104.72752154100002, 22.197010514000048],
              [104.72413258100001, 22.199135782000035],
              [104.72229925100001, 22.201007922000123],
              [104.72137953100012, 22.203478272000062],
              [104.72025959600009, 22.20880347400005],
              [104.71897817200004, 22.214896108000026],
              [104.7189737620001, 22.217112301000071],
              [104.72299130500008, 22.221295878000099],
              [104.72637106700003, 22.224199702000114],
              [104.7273744180001, 22.225820906000045],
              [104.72809656900007, 22.230936434000093],
              [104.728087031, 22.235880241000046],
              [104.72228401200009, 22.255219519000043],
              [104.72209827600008, 22.256583013000061],
              [104.72169444800006, 22.257195927000062],
              [104.72097054800004, 22.258602773000021],
              [104.71986058000003, 22.259492829000038],
              [104.71482983000007, 22.262024615000087],
              [104.70920525900002, 22.263146760000026],
              [104.7027483200001, 22.26432672200005],
              [104.70183254000008, 22.264580758000108],
              [104.69753068900005, 22.264658045000104],
              [104.69259131800001, 22.263284970000093],
              [104.68948101500006, 22.26259715900003],
              [104.68746844500006, 22.26216710900006],
              [104.68591254300003, 22.262164116000079],
              [104.68270712100011, 22.263095499000052],
              [104.67693331000004, 22.266493636000106],
              [104.67463865600007, 22.269301897000027],
              [104.67004847800007, 22.275174039000056],
              [104.66844238800005, 22.27700337300007],
              [104.66734197400004, 22.277853504000063],
              [104.66605989900009, 22.278106582000042],
              [104.66505303700009, 22.278104506000048],
              [104.66441269900005, 22.277932708000044],
              [104.66359073899999, 22.277163869000027],
              [104.66277082400009, 22.275542649],
              [104.66213154700004, 22.274944660000081],
              [104.66103459700005, 22.274345719000088],
              [104.65892919000002, 22.274426541000111],
              [104.63721990500005, 22.280772310000074],
              [104.63419415500007, 22.282725973000069],
              [104.63061641600008, 22.285701174000039],
              [104.62795684800003, 22.287570321000061],
              [104.62520727700007, 22.288842550000076],
              [104.61125047600009, 22.290900055000051],
              [104.60905616700008, 22.29089478800006],
              [104.60799944800004, 22.290967909000067],
              [104.60651080600006, 22.290657597000063],
              [104.60440847600002, 22.289544401000057],
              [104.60207867200008, 22.287961822000035],
              [104.599891187, 22.284632202000054],
              [104.59478943400003, 22.276181075000068],
              [104.59260131700009, 22.273192302000041],
              [104.59180284800001, 22.272359235000039],
              [104.589975618, 22.271203938000014],
              [104.58892442100006, 22.270732467000087],
              [104.58796562600004, 22.269962898000095],
              [104.58700808400005, 22.268767153000013],
              [104.58554968099999, 22.266717740000082],
              [104.58454844600001, 22.26483996200006],
              [104.58418726300006, 22.263176923000039],
              [104.58456328400008, 22.259811008000071],
              [104.58500687600011, 22.251757821000062],
              [104.5803264730001, 22.253046026000064],
              [104.57823830700008, 22.253040625000061],
              [104.57676971600006, 22.252748669000063],
              [104.57576625000007, 22.252097746000032],
              [104.57468915600005, 22.250222062000091],
              [104.57323085900001, 22.246544528000101],
              [104.57214969900008, 22.246037450000067],
              [104.55213027000011, 22.242669993000064],
              [104.55073734300005, 22.242954283000046],
              [104.54965167300003, 22.243887716000096],
              [104.54840462100005, 22.246909658000021],
              [104.546532079, 22.25201881100007],
              [104.54142552500008, 22.276423735000073],
              [104.54141965300005, 22.278224546000033],
              [104.54203589100005, 22.279018652000012],
              [104.54365635100001, 22.280247778000017],
              [104.54759169700002, 22.283284182000074],
              [104.55067926700005, 22.285381723000071],
              [104.55145284300002, 22.285383862000074],
              [104.55307803200002, 22.285172235000061],
              [104.55586380200003, 22.284891754000036],
              [104.55671313400005, 22.285398308000111],
              [104.56227120700002, 22.289159112000064],
              [104.56420028400004, 22.290749030000079],
              [104.57631026200006, 22.30259451600007],
              [104.57776984100003, 22.306055913000122],
              [104.57821869500005, 22.311171424000072],
              [104.57774449600007, 22.31448370800004],
              [104.57711921900002, 22.316571042000085],
              [104.57051305600001, 22.326134035000052],
              [104.56896125200005, 22.327498557000069],
              [104.56392009200003, 22.331158751000046],
              [104.56368526500005, 22.332022507000104],
              [104.56368324100006, 22.332670792],
              [104.56383554500005, 22.333463564000056],
              [104.56468251900006, 22.334834463000064],
              [104.56668740100011, 22.337144883000093],
              [104.56907995300001, 22.339240209000089],
              [104.57325009099999, 22.342132564000039],
              [104.57711234900007, 22.344519764000026],
              [104.57881039900006, 22.346036878000049],
              [104.57903803100007, 22.347550152000025],
              [104.57887848700001, 22.349134454000108],
              [104.57732324100003, 22.351579515000083],
              [104.57383240400003, 22.354235579000111],
              [104.56926208000007, 22.355520071000079],
              [104.56639753600011, 22.355800562000105],
              [104.56167771300004, 22.355355660000065],
              [104.55479337000001, 22.354112332000042],
              [104.55239066800007, 22.355186192000076],
              [104.54944290800006, 22.35726695800011],
              [104.54540611600001, 22.361001359000099],
              [104.54486034700007, 22.362224375000039],
              [104.54516549900009, 22.363593842000014],
              [104.54609011000012, 22.364893034000133],
              [104.548401922, 22.368068918000091],
              [104.55133168900002, 22.371678675000069],
              [104.55171340800008, 22.373336469000016],
              [104.55101120400006, 22.375063303000076],
              [104.54797884700008, 22.379232724000083],
              [104.54611496700005, 22.381100354000068],
              [104.5435543250001, 22.38296598500007],
              [104.53479467000008, 22.386614674000072],
              [104.52836164500009, 22.388901062000066],
              [104.51455118000004, 22.397720126000117],
              [104.51051531700007, 22.400589223000011],
              [104.50717278000009, 22.404396754000096],
              [104.50523390000006, 22.405327246000084],
              [104.50302670600006, 22.405500560000036],
              [104.49950395100008, 22.405489676000023],
              [104.49539871200005, 22.405981131000097],
              [104.49435166800002, 22.406482083000064],
              [104.48871423500005, 22.413091240000057],
              [104.48805306300005, 22.413917510000125],
              [104.48789138700009, 22.414999584000036],
              [104.48827571400008, 22.416547361000113],
              [104.48885301000007, 22.417485604000028],
              [104.48912058500009, 22.418422856000042],
              [104.48895992200003, 22.420007034000022],
              [104.48872538900008, 22.420618563000083],
              [104.48814320600005, 22.421012888000021],
              [104.48668997500008, 22.421386424000019],
              [104.48374623800004, 22.421737211000092],
              [104.48041666400006, 22.421726533000097],
              [104.47739710000008, 22.421644758000078],
              [104.47538279700002, 22.421926356000107],
              [104.46510887500003, 22.425602260000055],
              [104.46394156200009, 22.427111053000104],
              [104.45413637600006, 22.439467538000102],
              [104.45023678299999, 22.44644129800006],
              [104.44851505100009, 22.45097336300006],
              [104.44670688700005, 22.457738061000043],
              [104.44685007400008, 22.4606918160001],
              [104.44769320800003, 22.462927655000087],
              [104.46351060700005, 22.478755759000087],
              [104.46782084800007, 22.481912912000013],
              [104.47217778100006, 22.484532912000077],
              [104.47240793400009, 22.48467130500007],
              [104.471341974, 22.486741065000082],
              [104.47027798500007, 22.488305768000046],
              [104.46881297400009, 22.489938060000014],
              [104.46733774900007, 22.490797562000076],
              [104.46563092600006, 22.491440180000041],
              [104.46229774800005, 22.491933317000075],
              [104.46027960200004, 22.492934991000091],
              [104.45174190000009, 22.496939871000073],
              [104.45042116000006, 22.497871768],
              [104.44426501300006, 22.503337233000039],
              [104.43813609500002, 22.508777984000083],
              [104.43626053100009, 22.512733088000033],
              [104.43438016100004, 22.517840631000041],
              [104.43281607200002, 22.521364591000022],
              [104.43133792800002, 22.522799979000041],
              [104.42993990700008, 22.523587373000083],
              [104.42303722200005, 22.525075402000105],
              [104.41574724600012, 22.52648970900006],
              [104.41357269700006, 22.527634279],
              [104.412637026, 22.528999438000064],
              [104.412550106, 22.531232033000045],
              [104.41345566500006, 22.536997712000058],
              [104.41437740400002, 22.538945881000046],
              [104.41584134200009, 22.540968027000119],
              [104.41992581700001, 22.546457064000066],
              [104.42161311400008, 22.55071288200012],
              [104.42160500400003, 22.552657654000093],
              [104.42043011700008, 22.555606640000022],
              [104.41902386200007, 22.558266661000026],
              [104.41907534900007, 22.559851494000057],
              [104.42083270200007, 22.563324688000108],
              [104.42107950900007, 22.563662786000101],
              [104.42244593000007, 22.565918629000059],
              [104.42320150400005, 22.570621263000042],
              [104.4231688680001, 22.571476499000106],
              [104.42274320000006, 22.573644858000108],
              [104.42277540100005, 22.574054639000082],
              [104.42294840600007, 22.574392900000092],
              [104.42327707700008, 22.574578657000096],
              [104.42494157600005, 22.575088822000033],
              [104.42679907600009, 22.575797749000067],
              [104.42747637900008, 22.576034257000096],
              [104.42889960000007, 22.576318437000076],
              [104.43095276800004, 22.576577821000043],
              [104.44114209400004, 22.577658015000068],
              [104.44288611900002, 22.577700088000086],
              [104.44567848600005, 22.57727756600012],
              [104.44723071300005, 22.576814722000016],
              [104.44874504900011, 22.576135644000047],
              [104.45049289400005, 22.57520523900007],
              [104.45185185800004, 22.574597640000071],
              [104.45821186400012, 22.573682816000051],
              [104.46386455600002, 22.575250414000081],
              [104.464289368, 22.575647992000114],
              [104.46436563800009, 22.575972382000131],
              [104.46428492000007, 22.576800453000075],
              [104.46385201800004, 22.578491708000037],
              [104.46249588900011, 22.583367188000025],
              [104.46086842800001, 22.593193761000038],
              [104.46093459300006, 22.596111187000112],
              [104.461318186, 22.597156897000033],
              [104.46205064900003, 22.598203783000059],
              [104.46208716900004, 22.598780145000049],
              [104.46196835300006, 22.599428003000099],
              [104.458199677, 22.611480270000058],
              [104.45799934000003, 22.613136259000029],
              [104.45745261900001, 22.614142827000094],
              [104.45659728600002, 22.614752181000064],
              [104.45523851100003, 22.615215773000052],
              [104.44460638600007, 22.617484267000073],
              [104.43630002599998, 22.619796254000057],
              [104.43093899600005, 22.622370378000056],
              [104.42833505699998, 22.623873737000011],
              [104.42020349600006, 22.630579246000067],
              [104.41343501200004, 22.635704707000045],
              [104.41063077200009, 22.638611586000046],
              [104.41024008100001, 22.639294416000055],
              [104.40455124600004, 22.654651496000078],
              [104.40435478300004, 22.655245007000069],
              [104.404430479, 22.655677457000053],
              [104.40479749900008, 22.656002938000071],
              [104.40691428100007, 22.657514375000076],
              [104.40876867700003, 22.659177886000045],
              [104.40407226900003, 22.66902838500009],
              [104.39662872900001, 22.681389520000025],
              [104.39123145900001, 22.680597642000052],
              [104.36276985800001, 22.673961747000071],
              [104.36034662700006, 22.673952090000071],
              [104.35771358500007, 22.674391565000107],
              [104.35653174000007, 22.674785231000079],
              [104.35466241400003, 22.675407892000088],
              [104.35039275600008, 22.67786568500005],
              [104.34747688900005, 22.679525293000054],
              [104.33368456900008, 22.68952940800002],
              [104.33270925700003, 22.690746799000024],
              [104.33235835900004, 22.691709645000024],
              [104.33228507100002, 22.692545065000047],
              [104.33746586600004, 22.702370354000045],
              [104.33852951000003, 22.704399785000092],
              [104.33900315100006, 22.706716072000077],
              [104.33937797900006, 22.711470202000058],
              [104.33963603800001, 22.710930325000064],
              [104.34931998400005, 22.694766993000123],
              [104.35064538300001, 22.69361501300002],
              [104.35270245600005, 22.692806386000086],
              [104.35959606200004, 22.692697954],
              [104.36245402100008, 22.693117844000099],
              [104.37293707700005, 22.693840060000078],
              [104.37550116800001, 22.694394734000056],
              [104.37725803200007, 22.695082380000109],
              [104.37930240200004, 22.697064647000076],
              [104.38090010800006, 22.700542929],
              [104.38147852500005, 22.702383328000053],
              [104.38271789500001, 22.704022048000105],
              [104.38483945900005, 22.705187587000083],
              [104.3873316880001, 22.705469472000047],
              [104.39195520300004, 22.70473821900012],
              [104.39433777500008, 22.703466069000022],
              [104.39538439799999, 22.702947986000055],
              [104.39696705100002, 22.702744186000068],
              [104.39792624700007, 22.703010012000036],
              [104.39848702900011, 22.703956111000068],
              [104.39836831600006, 22.705266767000062],
              [104.39897558700002, 22.70932613400003],
              [104.40137090800008, 22.715053872000013],
              [104.40544306100003, 22.723170635000045],
              [104.40638804500006, 22.725148472000086],
              [104.40762820800008, 22.726718914000088],
              [104.40967816300005, 22.727611542000048],
              [104.41231319600003, 22.72891477500005],
              [104.41414112900001, 22.730283091000025],
              [104.41574500300003, 22.732603699],
              [104.41830049900008, 22.735404309],
              [104.421589646, 22.738207546000041],
              [104.42641996700007, 22.740880082000011],
              [104.43344783700007, 22.744377268000093],
              [104.44114130200008, 22.746651009000018],
              [104.45483843900007, 22.752008622000019],
              [104.45820773100004, 22.753381698000027],
              [104.464800333, 22.755990992000093],
              [104.46809357500007, 22.758112490000123],
              [104.47474642000005, 22.764261754000081],
              [104.48418214200005, 22.771985628000024],
              [104.48893772200006, 22.775677286000118],
              [104.49325497800005, 22.778268089000072],
              [104.49408097600009, 22.779271472000048],
              [104.49498399100003, 22.781891713000043],
              [104.49538752400011, 22.784972257000021],
              [104.4958802670001, 22.786359493000049],
              [104.49712118800002, 22.787364164000046],
              [104.49927795400004, 22.787524912000023],
              [104.50226278100008, 22.78815010800006],
              [104.50613120900006, 22.789551776000039],
              [104.51351996000008, 22.796042125000056],
              [104.51673745100001, 22.799319769000093],
              [104.52024294100008, 22.804232155000115],
              [104.52148785800006, 22.806326787000053],
              [104.52243186400008, 22.807915081000104],
              [104.52300383100007, 22.812273935000071],
              [104.52504284900003, 22.816909496000015],
              [104.53036076700003, 22.821337468000102],
              [104.53342030900005, 22.824656660000109],
              [104.53498678900007, 22.827740516000091],
              [104.53539916500009, 22.828511531000025],
              [104.53589557500004, 22.828974937000091],
              [104.53697509200001, 22.828824110000042],
              [104.54046520700004, 22.827602517000045],
              [104.5409619050001, 22.827988848000032],
              [104.54128900600007, 22.829452443000072],
              [104.54111294100007, 22.83245421300002],
              [104.54094203000005, 22.833916384000119],
              [104.54118897700005, 22.834532936000059],
              [104.54434259900003, 22.834695882000041],
              [104.54801669100002, 22.834899274000087],
              [104.54874101500008, 22.834939358000028],
              [104.55081533400002, 22.835176164000082],
              [104.55214036900006, 22.836103677],
              [104.55238661400008, 22.836951170000042],
              [104.55155454400008, 22.837564684000085],
              [104.549311135, 22.838251188000044],
              [104.54648588400001, 22.839166947000052],
              [104.54556975500006, 22.840088110000067],
              [104.54498666400004, 22.840702303000079],
              [104.54515011100005, 22.841472596000045],
              [104.54680695100011, 22.842478081000102],
              [104.55070300700005, 22.844105732000038],
              [104.55493221700004, 22.845426290000056],
              [104.5603258730001, 22.846057150000028],
              [104.56455768500003, 22.846607630000015],
              [104.56721159200005, 22.847384687],
              [104.56886792800006, 22.848620876000055],
              [104.57201201800005, 22.851939600000023],
              [104.57350219000007, 22.853252287000103],
              [104.57524406900004, 22.853718832000069],
              [104.58097470200008, 22.852887225000096],
              [104.58256471500002, 22.852869088000105],
              [104.58701849000002, 22.853564884000029],
              [104.58950049200003, 22.853866502000031],
              [104.59121118900001, 22.85155968000003],
              [104.59197331500009, 22.850363936000036],
              [104.59247948900004, 22.849062971000045],
              [104.59264440300009, 22.848759208000047],
              [104.59816785000005, 22.838849411000069],
              [104.59948862500009, 22.835848969000061],
              [104.60014770400009, 22.834786007000041],
              [104.60084590000004, 22.834369502000037],
              [104.60154353200002, 22.834143117000046],
              [104.60207716200004, 22.833916311000024],
              [104.60244736000003, 22.833499],
              [104.60279754400005, 22.832910533000117],
              [104.60292385700011, 22.831770173000088],
              [104.60297289800008, 22.828994655000123],
              [104.6028936650001, 22.828043902000061],
              [104.60260780000007, 22.827662961000073],
              [104.60207529000006, 22.827509547000076],
              [104.60109094500007, 22.827659170000025],
              [104.59977851800001, 22.827845996000029],
              [104.59908157899999, 22.827844243000058],
              [104.59854930900009, 22.827614763000078],
              [104.59785404600002, 22.827042675000087],
              [104.59685339600001, 22.825766397000088],
              [104.59636391900001, 22.824928667000052],
              [104.59611974300006, 22.824319688000024],
              [104.59556020000004, 22.819451394000012],
              [104.5955619910001, 22.818843032000032],
              [104.59580929100004, 22.818387384000083],
              [104.59663095600008, 22.817781123000103],
              [104.59794428900003, 22.81725211600012],
              [104.59868215400007, 22.817253981000093],
              [104.59909209500006, 22.817255015000093],
              [104.60179926600006, 22.818028646000066],
              [104.60675540400005, 22.819148766000104],
              [104.60803218100008, 22.819177607000093],
              [104.60925333400004, 22.81902927800008],
              [104.61040254700001, 22.81853779900009],
              [104.61105984100004, 22.818045115000096],
              [104.61139555000003, 22.8176877430001],
              [104.61333957600002, 22.819618340000055],
              [104.61490537400007, 22.821321669000035],
              [104.61533824000001, 22.822536665000115],
              [104.61542299200001, 22.823427100000067],
              [104.61502711200002, 22.824740184000092],
              [104.61471924600005, 22.825448655000088],
              [104.61480514300004, 22.825934448000048],
              [104.61541526900008, 22.8261787040001],
              [104.61747684100004, 22.826153325000043],
              [104.61837067400009, 22.826357787000084],
              [104.61934547400001, 22.827034599000037],
              [104.62130843500007, 22.828974798000075],
              [104.62172118800002, 22.829602992],
              [104.62198118500004, 22.830251049000069],
              [104.622218913, 22.831060917000023],
              [104.622271643, 22.83171859200008],
              [104.62200863000001, 22.83216308100009],
              [104.62139521400006, 22.833092323000059],
              [104.620738617, 22.83385960200004],
              [104.62003915600003, 22.834343505000021],
              [104.61947161900005, 22.834463556000095],
              [104.61846819400009, 22.834420683000097],
              [104.617334785, 22.834053782000026],
              [104.61689823400003, 22.834133662000063],
              [104.616635882, 22.834335356000054],
              [104.61624160600006, 22.834900916000045],
              [104.61602139800004, 22.835628758000048],
              [104.61601924700005, 22.836397589000065],
              [104.61614673300002, 22.837611843000083],
              [104.61653444000004, 22.839393237000039],
              [104.61700954400001, 22.841134377000039],
              [104.61767081400009, 22.842623107000023],
              [104.61810523100007, 22.843312048000094],
              [104.61858431300001, 22.843636916000065],
              [104.61936861900006, 22.844043456000037],
              [104.62035458000003, 22.84496232500004],
              [104.62084794599998, 22.845584648000013],
              [104.62167410400004, 22.846638703000011],
              [104.62293629900003, 22.847815177000093],
              [104.62316787, 22.848103033000108],
              [104.62377195700006, 22.844414353000083],
              [104.62436564300008, 22.842100952000074],
              [104.62510621400006, 22.839753855000119],
              [104.62584284399999, 22.838836472000068],
              [104.62793999800004, 22.837037192000054],
              [104.63132086300001, 22.835581284000028],
              [104.63440692500009, 22.834533123000078],
              [104.63572893700005, 22.834297869000061],
              [104.63668235800006, 22.834640451000077],
              [104.63843952200006, 22.83641459300005],
              [104.639392071, 22.837097578000076],
              [104.64056602800005, 22.837338525000028],
              [104.64232781599999, 22.837376530000064],
              [104.65154564800008, 22.835558730000024],
              [104.65378629300002, 22.834916824000025],
              [104.65492570200007, 22.834306560000073],
              [104.65621362900005, 22.833015764000031],
              [104.65820135500012, 22.830739278000053],
              [104.66651314100002, 22.823914577000082],
              [104.66835420100003, 22.821467450000021],
              [104.66982694200003, 22.819530147000044],
              [104.67059881400004, 22.819055163000087],
              [104.67144300700008, 22.819022868000083],
              [104.67466994400009, 22.820186894000116],
              [104.68042719100002, 22.82227507100005],
              [104.69818064, 22.826973362000032],
              [104.70016164700003, 22.82741964200001],
              [104.7016664070001, 22.827456501000043],
              [104.7071754020001, 22.82583273500007],
              [104.716797684, 22.822888524000092],
              [104.71962550300003, 22.82204249400008],
              [104.72160809200003, 22.821705545],
              [104.72285588800007, 22.82174176700007],
              [104.72582683200004, 22.822700065000042],
              [104.72927420300007, 22.824033583000066],
              [104.7303521330001, 22.824961295],
              [104.73121615300008, 22.825704899000065],
              [104.73293761100005, 22.827546039000076],
              [104.73352175800007, 22.829146975000114],
              [104.73381227200004, 22.830747401000018],
              [104.73366320500006, 22.831904569000102],
              [104.7317466800001, 22.83591824200002],
              [104.73089353500004, 22.840444321000049],
              [104.73063323200009, 22.842145959000071],
              [104.72996886400009, 22.843983058000013],
              [104.72908572100002, 22.845070884000052],
              [104.72699015400011, 22.846667257000064],
              [104.72463730400003, 22.848399318000112],
              [104.72335049000004, 22.849384297000071],
              [104.72279861700012, 22.849996076000068],
              [104.72250389900007, 22.850506183000086],
              [104.72250320800002, 22.850846598000096],
              [104.7225760760001, 22.85111905400008],
              [104.72382262300003, 22.851904169000115],
              [104.72594879200008, 22.85340567300009],
              [104.72876955300006, 22.856406143000108],
              [104.73181278300007, 22.858351643000049],
              [104.73474709200011, 22.859718220000055],
              [104.73900594800006, 22.859589093000032],
              [104.74737807600009, 22.858649435000089],
              [104.75090316200007, 22.858246501000053],
              [104.75339930600011, 22.858386562000099],
              [104.75618600900002, 22.860297164000087],
              [104.76159853000003, 22.87228793400002],
              [104.76744519300009, 22.888636440000099],
              [104.76817196000009, 22.893130952],
              [104.76860752900006, 22.896127220000025],
              [104.77212192500006, 22.902668221000063],
              [104.77696019300008, 22.906898676000061],
              [104.77931185100006, 22.907580304000064],
              [104.78327902600003, 22.907041109000126],
              [104.78720908200005, 22.906740050000082],
              [104.78860462100005, 22.906741912000058],
              [104.79058662100007, 22.907493440000067],
              [104.79322628000008, 22.910492525000059],
              [104.79417930400007, 22.91171925200004],
              [104.79586739100003, 22.912606496000087],
              [104.80709596400006, 22.919496865000077],
              [104.80973794800008, 22.921270196000073],
              [104.81818131600004, 22.924479968000085],
              [104.8245712340001, 22.925304141000069],
              [104.82750938100001, 22.92551160900009],
              [104.83121817300011, 22.925311989000093],
              [104.83473874700007, 22.923883875000058],
              [104.83596938500003, 22.923315165000076],
              [104.83645614900007, 22.923090210000026],
              [104.837400024, 22.923250273000036],
              [104.8371382280001, 22.926909073000076],
              [104.83765214400009, 22.92778458500004],
              [104.84057914500005, 22.930767622000033],
              [104.84329416300008, 22.933582950000094],
              [104.84571484400009, 22.935269193000082],
              [104.84895785700009, 22.937657890000104],
              [104.8501022880001, 22.938500812000129],
              [104.85570182600004, 22.941242290000027],
              [104.85910675000004, 22.94320986600011],
              [104.86198100100009, 22.945948681000061],
              [104.86334108300009, 22.948686152000072],
              [104.86424629500003, 22.952054666000073],
              [104.86401332400003, 22.957877851000049],
              [104.86332656200005, 22.963139349000095],
              [104.86287046000005, 22.964892984000059],
              [104.85998342100001, 22.974151712000079],
              [104.85955280200008, 22.976893148000066],
              [104.85943737300005, 22.978578244000047],
              [104.85937653000008, 22.979466456000083],
              [104.85746673100006, 22.979464754000034],
              [104.85555710500006, 22.979302180000083],
              [104.85404283300009, 22.978988976000011],
              [104.85087049800006, 22.978332789000078],
              [104.84843987200004, 22.978330486000061],
              [104.84652864300008, 22.979615379000059],
              [104.84498703700008, 22.981962052000071],
              [104.84357196200006, 22.984116046000032],
              [104.84218185000006, 22.985079718000101],
              [104.84053409400006, 22.984837017000089],
              [104.83888355100001, 22.984593870000047],
              [104.83766838200009, 22.984431794000045],
              [104.83697310900003, 22.985074452000021],
              [104.83662334700004, 22.987165027000067],
              [104.83696132700004, 22.994885747000104],
              [104.83678556900006, 22.996654823000107],
              [104.83504722000002, 22.998261432000078],
              [104.83105046200009, 23.000669833000039],
              [104.83057251200006, 23.002311586000076],
              [104.82938979699999, 23.00762069600006],
              [104.82879214400002, 23.015245200000045],
              [104.82731122200005, 23.023685720000039],
              [104.82689741200001, 23.025265191000074],
              [104.82627697500001, 23.027633319000081],
              [104.82347807500007, 23.031715120000037],
              [104.821563392, 23.034163913000057],
              [104.81935438400011, 23.036748503000034],
              [104.81846886200003, 23.039198421000059],
              [104.81918989000009, 23.049819941000038],
              [104.81947712000004, 23.054994454000074],
              [104.81800231000005, 23.058124507000066],
              [104.81534976700006, 23.062070144000032],
              [104.81357988700007, 23.065744450000025],
              [104.81313510200005, 23.068331016000073],
              [104.81357107100006, 23.07214407],
              [104.81474322800005, 23.075413350000048],
              [104.81451765700004, 23.079021400000066],
              [104.81348392500004, 23.082015758000104],
              [104.80994669000002, 23.087049543000049],
              [104.80640939600009, 23.091947075000085],
              [104.80375525700003, 23.096300979000084],
              [104.80242695600001, 23.099294887],
              [104.80050228100001, 23.107598328000023],
              [104.79960703900009, 23.116039223000051],
              [104.79960057100001, 23.120396374000052],
              [104.799449198, 23.123255573],
              [104.80003450200012, 23.12543490000003],
              [104.80478944200007, 23.124529589000083],
              [104.81099068500006, 23.12397458],
              [104.81488192400008, 23.123416636000016],
              [104.81804488800003, 23.121957653],
              [104.82060096200004, 23.119710328000039],
              [104.82133441800006, 23.116673329000022],
              [104.82231468300009, 23.11082378400009],
              [104.82377523200005, 23.109475274000069],
              [104.82559897100005, 23.109252277000088],
              [104.82693531900003, 23.109928825000026],
              [104.82924276600008, 23.111731544000058],
              [104.8305751930001, 23.115558396000054],
              [104.83105907900008, 23.11747163299999],
              [104.83190965200004, 23.117810076000112],
              [104.83361220700004, 23.117361830000057],
              [104.83653414500003, 23.113876999000027],
              [104.83787207100008, 23.113315815000043],
              [104.83872237600008, 23.113879248000032],
              [104.8387204910001, 23.115454419000066],
              [104.83713302800003, 23.121303442000048],
              [104.83664496300005, 23.122765602000058],
              [104.83688661800007, 23.124003478000077],
              [104.83773697700008, 23.124566919000067],
              [104.84041129000011, 23.124907184000065],
              [104.84596094200002, 23.125560905000064],
              [104.85305296200008, 23.127169713000036],
              [104.85366061400001, 23.12739530700005],
              [104.85512417500004, 23.123121190000084],
              [104.85864965500005, 23.123349412000046],
              [104.86180995700006, 23.124027286000093],
              [104.86676082900007, 23.125845784000099],
              [104.87426117200008, 23.129800641000116],
              [104.87646581200006, 23.132525606000073],
              [104.878432694, 23.13924561],
              [104.88013330400003, 23.145055319000015],
              [104.88100638900005, 23.156221202],
              [104.88015418900007, 23.162926517000045],
              [104.87993124200008, 23.165377249000052],
              [104.88000361000005, 23.166738911],
              [104.88117962200009, 23.168373736],
              [104.88164433200008, 23.168874738000063],
              [104.88882640200005, 23.176617064000112],
              [104.89106975400003, 23.178286622000051],
              [104.89283538900001, 23.17917287100002],
              [104.89555825300006, 23.179515110000089],
              [104.89967974800001, 23.179517790000048],
              [104.90313862900004, 23.179860349000059],
              [104.90493033200005, 23.180202752000056],
              [104.90687584100007, 23.180991488000032],
              [104.90953850500003, 23.182524445000041],
              [104.91020441300006, 23.182597303000115],
              [104.91160607400005, 23.182750647000105],
              [104.91365028000007, 23.182136026000052],
              [104.91501741400003, 23.181102589000076],
              [104.915764204, 23.178804806000031],
              [104.91587675500008, 23.176871845000058],
              [104.91570223300003, 23.174963073000043],
              [104.91734055800005, 23.173459044000118],
              [104.91777165000011, 23.173444357000058],
              [104.92222696400006, 23.173292500000017],
              [104.92272417000007, 23.17271820800002],
              [104.92297384500003, 23.170535052000041],
              [104.92372090900002, 23.167432872000049],
              [104.92396964700001, 23.166858448000127],
              [104.92417402900004, 23.16612662100006],
              [104.92447118300002, 23.166106030000044],
              [104.92521220900005, 23.166054679000055],
              [104.92707302000009, 23.168513327000092],
              [104.92828853100004, 23.169751510000047],
              [104.92918496900005, 23.169848515000076],
              [104.93017946200008, 23.16835514000006],
              [104.93080313200008, 23.163233567000049],
              [104.93237513100003, 23.161901657000072],
              [104.94840017899999, 23.151366638000056],
              [104.94889144500004, 23.151102926000085],
              [104.94985392000009, 23.151837040000075],
              [104.954142253, 23.15784373700005],
              [104.95541238700011, 23.16121061400009],
              [104.95566931800005, 23.165406807000096],
              [104.95585190000006, 23.169736249000024],
              [104.95343854100004, 23.17413922700009],
              [104.95288798200005, 23.176282416000049],
              [104.95308994600006, 23.17757642],
              [104.95382970600008, 23.179073655000067],
              [104.95540344300008, 23.182418696000084],
              [104.95616453100008, 23.184356526000016],
              [104.95622705100001, 23.187292316000097],
              [104.95574330900004, 23.190026256000081],
              [104.95509453700001, 23.19369287100006],
              [104.95491005300002, 23.195167751000056],
              [104.95502449400009, 23.196573016000066],
              [104.95507500200009, 23.197193185000039],
              [104.95658259500004, 23.201414592000091],
              [104.95856960500004, 23.202946928000046],
              [104.96040954500005, 23.204104775000097],
              [104.96280161600008, 23.205228685000101],
              [104.966187432, 23.206693166000036],
              [104.9671075090001, 23.207033755000062],
              [104.97181187100006, 23.207391176000058],
              [104.97225002700007, 23.207402546000111],
              [104.97558143400002, 23.207516827000063],
              [104.97671418500005, 23.207657111000032],
              [104.97825629900009, 23.207749766000063],
              [104.98062711900006, 23.207714582000079],
              [104.98203853800004, 23.207484389000044],
              [104.98838204900008, 23.20410933000003],
              [104.98963348300006, 23.203666896000108],
              [104.99154738900006, 23.203667012000025],
              [104.99316682700002, 23.204075572000079],
              [104.99508069100003, 23.205267043000077],
              [105.00000008100008, 23.215310344000081],
              [105.00072208900005, 23.216637386000016],
              [105.00129757400006, 23.217342439000085],
              [105.00229433400003, 23.217898136000059],
              [105.00388353600006, 23.21807430800007],
              [105.00497376000003, 23.218011366000013],
              [105.00657021800005, 23.217722717000107],
              [105.00774661400004, 23.217505934000116],
              [105.01042966000009, 23.21738669800002],
              [105.01335797700008, 23.217658107000091],
              [105.01886872700004, 23.218893592000086],
              [105.02187002400004, 23.219863220000022],
              [105.02311937600003, 23.220344697000073],
              [105.0250244850001, 23.221764651000051],
              [105.0259327630001, 23.222629002000069],
              [105.02617566800004, 23.223174611],
              [105.02648434400001, 23.224883336000076],
              [105.02680022900003, 23.226127251000058],
              [105.02745600900002, 23.227065734000085],
              [105.03150579500007, 23.230683500000012],
              [105.03498366400007, 23.233363832000094],
              [105.03631212500002, 23.234156182000021],
              [105.03781532500005, 23.234485939000038],
              [105.03939873000007, 23.2350491830001],
              [105.04097018500006, 23.236387080000043],
              [105.0440150580001, 23.239991294],
              [105.04466620500007, 23.241239549000078],
              [105.0451259270001, 23.244034881000083],
              [105.04577827900007, 23.245205658000053],
              [105.04901515900011, 23.248436807000061],
              [105.05034464700006, 23.249251916000091],
              [105.05186855500003, 23.24989231200005],
              [105.05282695800001, 23.249904887000028],
              [105.05417824600008, 23.249302218000075],
              [105.05513936700002, 23.249137564000044],
              [105.05571303900007, 23.249233711],
              [105.05646752600005, 23.250041254000031],
              [105.05701808900004, 23.251643756000107],
              [105.05725667300007, 23.254837450000117],
              [105.05772637700002, 23.255463995000092],
              [105.05962964500003, 23.256375174000077],
              [105.06617063700007, 23.258732341000062],
              [105.06739018200008, 23.259316127000048],
              [105.07126942400006, 23.261709143000076],
              [105.07261709700001, 23.262774458000109],
              [105.07366101900006, 23.264060451000113],
              [105.07550665000008, 23.266108736000035],
              [105.07739768600005, 23.267680408000047],
              [105.07940152700012, 23.269166852000112],
              [105.08020893600005, 23.269633446000078],
              [105.08166214900011, 23.2700072310001],
              [105.08380951800007, 23.270177065000027],
              [105.08476864400011, 23.270046246000042],
              [105.0858989700001, 23.269771733000063],
              [105.08861589800001, 23.268014061000116],
              [105.09007650500007, 23.26658708700009],
              [105.09300904900006, 23.262981393000089],
              [105.09455994000004, 23.261136094000086],
              [105.095587752, 23.259783548000087],
              [105.09653712600003, 23.259174984000097],
              [105.09876593100006, 23.258334600000104],
              [105.09951011000008, 23.257971706000014],
              [105.09998572600009, 23.257605342000076],
              [105.10026554800007, 23.256863945000106],
              [105.10022281000008, 23.255249209000084],
              [105.09984531100011, 23.25356805600007],
              [105.09898658200011, 23.252625699000085],
              [105.09685599400004, 23.251418567000123],
              [105.09612592200003, 23.250850367000098],
              [105.09593014500008, 23.250475326000064],
              [105.09607476300006, 23.24979426500002],
              [105.09635270900006, 23.24917700000011],
              [105.0971649410001, 23.248752902000014],
              [105.10632187400006, 23.247132650000069],
              [105.10778112800008, 23.24704415600003],
              [105.10898014100009, 23.247124873000047],
              [105.10954091200003, 23.247393197000044],
              [105.11085456400011, 23.249237837000081],
              [105.11231924500005, 23.250431643000091],
              [105.11337219200004, 23.250836838000041],
              [105.11609772600005, 23.251744148000064],
              [105.11969487700004, 23.252941547000106],
              [105.12102139900003, 23.253937683000103],
              [105.12282347100009, 23.256180139000016],
              [105.12385787700006, 23.257825267000065],
              [105.12441576500004, 23.258289337000093],
              [105.12512168700009, 23.258298371000038],
              [105.127961016, 23.257290282000028],
              [105.12937871500006, 23.256916744000122],
              [105.13079055100009, 23.256934779000105],
              [105.13219750900006, 23.257279127000082],
              [105.13331332400001, 23.258207216000045],
              [105.13421054600008, 23.259589437000017],
              [105.13637553700008, 23.261183629000115],
              [105.13875332100008, 23.262715241000095],
              [105.1451095300001, 23.264982778000039],
              [105.14876493900005, 23.266073570000053],
              [105.15101623200006, 23.266624319000059],
              [105.15241845200009, 23.26729483700003],
              [105.15840653900003, 23.272984133000115],
              [105.16104095400004, 23.276281046000058],
              [105.16377998300001, 23.282059545000067],
              [105.16653846300007, 23.286532763000075],
              [105.17058528600006, 23.289847267000049],
              [105.17199544400003, 23.289995554000065],
              [105.17355065900001, 23.289884584000028],
              [105.17780996000009, 23.288371631000082],
              [105.17867064600007, 23.287468658000066],
              [105.17955819900006, 23.28473843700003],
              [105.17958699300004, 23.282780672000044],
              [105.17934682300006, 23.279905725000027],
              [105.17964266200002, 23.278995639000044],
              [105.18078377700003, 23.278226698000104],
              [105.18205457500002, 23.278242638000087],
              [105.18682090600004, 23.280652132000057],
              [105.19004751500002, 23.282128469000085],
              [105.19187741700009, 23.282542976000094],
              [105.19322351500008, 23.282454216000033],
              [105.19409934300006, 23.281954287000062],
              [105.19577844800008, 23.280515617000042],
              [105.19666385100007, 23.279358966000082],
              [105.19675025800002, 23.27884917300009],
              [105.19652837200005, 23.277824661000054],
              [105.19638009500004, 23.277165969000087],
              [105.19638969500004, 23.276509255000022],
              [105.19672145100004, 23.275418660000113],
              [105.19823727200006, 23.274342829000091],
              [105.20006776200007, 23.273343892000042],
              [105.20141822000008, 23.272776862000022],
              [105.20236546900003, 23.27278865800011],
              [105.20433144800006, 23.273324006000081],
              [105.20519869900005, 23.273407771000088],
              [105.20583232600008, 23.273269690000028],
              [105.20670808000007, 23.27276969100005],
              [105.20774807800005, 23.271833845000067],
              [105.20807551100006, 23.271035102000056],
              [105.20808188500004, 23.270597277000071],
              [105.20778207500011, 23.269498811000041],
              [105.2077948240001, 23.268623167000065],
              [105.2079580070001, 23.268260274000063],
              [105.20867267500009, 23.267977227000088],
              [105.21040607100007, 23.268217704000094],
              [105.21354864100002, 23.269278465000106],
              [105.21504736600004, 23.269370024000047],
              [105.21615353000007, 23.269310755000028],
              [105.21908368700005, 23.268690186000107],
              [105.22099082500002, 23.267837988000025],
              [105.22394734900004, 23.265393087000064],
              [105.22570713000007, 23.263809174000102],
              [105.22657962300005, 23.26352801700002],
              [105.22760575100004, 23.263540682],
              [105.228548738, 23.263844244000076],
              [105.23027053000008, 23.264887252000044],
              [105.23112195100001, 23.26606549200001],
              [105.23272062000004, 23.270172253000077],
              [105.23425304700008, 23.273402364000084],
              [105.23502664500005, 23.274506621000057],
              [105.23588444800006, 23.275246995000117],
              [105.23989560400003, 23.27631805],
              [105.24163016500002, 23.276485310000083],
              [105.24281425100003, 23.276499843000039],
              [105.24359943800007, 23.276801404000125],
              [105.24374997500009, 23.277314123000089],
              [105.24350686400004, 23.277749037000014],
              [105.24231228600006, 23.278464193000033],
              [105.23612448500003, 23.280504628000109],
              [105.23477197000003, 23.28121779800005],
              [105.23285632700006, 23.282653831000033],
              [105.23236898700007, 23.283596576000051],
              [105.23211109700004, 23.285053016000035],
              [105.23223212100002, 23.287608812000116],
              [105.23304481900003, 23.289081758000037],
              [105.23411655400007, 23.289986194000086],
              [105.23952186500001, 23.291278101000046],
              [105.241917669, 23.292310156000084],
              [105.2437093300001, 23.293446174000017],
              [105.24429589000005, 23.294567401000066],
              [105.24534369700007, 23.297142512000057],
              [105.24593507800006, 23.297929575000076],
              [105.24844671900003, 23.299297160000073],
              [105.24940285200009, 23.299865878000055],
              [105.24975642000004, 23.300427208000052],
              [105.24975003000004, 23.300872743000056],
              [105.24808418500005, 23.307759179000072],
              [105.24768904600005, 23.310093732000055],
              [105.24792049800001, 23.310764961000082],
              [105.24875297700007, 23.311554943000075],
              [105.252352724, 23.31271296300006],
              [105.25402731400001, 23.31362460900008],
              [105.25497715600008, 23.314638795000064],
              [105.25626149600004, 23.317550809000039],
              [105.25671810400007, 23.319338732000091],
              [105.25670854100008, 23.320006986000053],
              [105.25399332200004, 23.326142115000081],
              [105.24937473600008, 23.331420202000075],
              [105.24793772900001, 23.332626919000084],
              [105.24755041400003, 23.333234338000075],
              [105.24754413700005, 23.333671514000095],
              [105.24800724800008, 23.33437678300001],
              [105.251435859, 23.339490827000105],
              [105.25422349399999, 23.343110298000092],
              [105.25515982200001, 23.343821324000125],
              [105.25647970200006, 23.344187230000024],
              [105.25837115900006, 23.34429775100002],
              [105.26263474400005, 23.343999910000065],
              [105.26567554200003, 23.343162431000053],
              [105.26891554900006, 23.341627735000081],
              [105.27101119400007, 23.340691248000041],
              [105.27186289800002, 23.340701586000101],
              [105.27337454300005, 23.340894829000014],
              [105.28093585200003, 23.34287460400013],
              [105.28153672200001, 23.343349679000092],
              [105.28202706100008, 23.344478352000039],
              [105.28269205600003, 23.347573908000108],
              [105.28337298300006, 23.34954692300002],
              [105.28505190500007, 23.352561125000101],
              [105.28544500700009, 23.353407908000086],
              [105.28563426800011, 23.354345795000064],
              [105.28548134300007, 23.35799275],
              [105.2855653770001, 23.359210024],
              [105.28596114500007, 23.359869713000059],
              [105.28686454100003, 23.360441961000063],
              [105.28908300200001, 23.361123629000069],
              [105.29078856200007, 23.362266882000071],
              [105.29278999300004, 23.36397502000009],
              [105.29408130200002, 23.365768152000044],
              [105.29654294500006, 23.37075624000002],
              [105.29702684500003, 23.372352502000027],
              [105.29739364700001, 23.375070008000073],
              [105.29788811700006, 23.375917950000066],
              [105.30078537000006, 23.378759371000065],
              [105.30449420300006, 23.381516912000052],
              [105.30592830600006, 23.382720265000053],
              [105.30616166700001, 23.382855289000034],
              [105.30652311299998, 23.382722866000094],
              [105.30788765699999, 23.382739206000025],
              [105.30911061300003, 23.382960406000031],
              [105.31005867900004, 23.383511218000038],
              [105.31221203500004, 23.385204404000071],
              [105.31770496700005, 23.391105970000048],
              [105.32001158300002, 23.393242254000057],
              [105.32090594800005, 23.393841401000067],
              [105.32138311400008, 23.393896128000065],
              [105.32188750900008, 23.393902135000033],
              [105.32271320600009, 23.393715800000066],
              [105.32351715200009, 23.393185913000103],
              [105.32454304500007, 23.391972092000032],
              [105.32562750600009, 23.390366615000069],
              [105.32655882100005, 23.388317934000021],
              [105.32754456800008, 23.386171805000089],
              [105.32791657000004, 23.385538697000108],
              [105.32826947700002, 23.385115573000093],
              [105.32928517400003, 23.38416617400005],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 23, code: "AD01", ten_tinh: "Hà Nam" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.03324707000007, 20.679095714000084],
              [106.03327418000001, 20.676402826000114],
              [106.03329934900005, 20.673902573000099],
              [106.03237520600005, 20.668980854000019],
              [106.03159656200002, 20.664435311000062],
              [106.03137142200003, 20.659505654000029],
              [106.03125756000003, 20.657170477000086],
              [106.03116982200007, 20.652241991000061],
              [106.03134509100008, 20.648482910000077],
              [106.03137644400002, 20.645370921000072],
              [106.03211556500005, 20.640190364000063],
              [106.03451222100003, 20.634116780000063],
              [106.03842104000006, 20.627926898000034],
              [106.04120213600004, 20.624579887000039],
              [106.04212907200009, 20.623587220000061],
              [106.04358918000003, 20.622023540000086],
              [106.04564573, 20.619821046000105],
              [106.04994786800007, 20.615449897000104],
              [106.05314710600001, 20.611457951000119],
              [106.05453355100006, 20.610173334000059],
              [106.05785064200008, 20.608127618000083],
              [106.06212782200004, 20.606219983000074],
              [106.06667448100008, 20.604833356000093],
              [106.06997598000004, 20.604343531000069],
              [106.07286158500006, 20.604239085000039],
              [106.07574463400003, 20.604393954000066],
              [106.07779849000006, 20.605060299000044],
              [106.08039284300006, 20.606639140000112],
              [106.08339167000001, 20.608999565000104],
              [106.08693871200005, 20.611494383000029],
              [106.09185054000002, 20.614908739],
              [106.09581365900002, 20.617017936000082],
              [106.10019651000002, 20.618352582000021],
              [106.10312396800002, 20.61889048100003],
              [106.10389891200002, 20.619032864000083],
              [106.10788107500008, 20.619196757000054],
              [106.11172713700006, 20.619229727000032],
              [106.11585292300002, 20.61874629200009],
              [106.11855738800006, 20.618132504000108],
              [106.12219636700006, 20.616206739000106],
              [106.12482855100006, 20.613894834000043],
              [106.12774780700002, 20.610288487000084],
              [106.12859179500003, 20.608220689000056],
              [106.12930461800005, 20.60550334400002],
              [106.12933068000007, 20.602780168000031],
              [106.12896083500002, 20.59836768300007],
              [106.12817651100005, 20.59421102700005],
              [106.1277992560001, 20.590576569000071],
              [106.12756059600007, 20.586813567000029],
              [106.12757798600009, 20.584998060000089],
              [106.128023528, 20.581500212000044],
              [106.12956644400003, 20.578141351000042],
              [106.13137902000008, 20.575303520000077],
              [106.13249003400009, 20.574016014000037],
              [106.13497926200007, 20.572221400000053],
              [106.13718637500008, 20.571202532000129],
              [106.13980543700006, 20.570187109000045],
              [106.14296760800005, 20.569824690000083],
              [106.14333418600009, 20.569724884000081],
              [106.14943293600004, 20.568711836000105],
              [106.15259875100006, 20.56796023600009],
              [106.15452862700005, 20.567198236000102],
              [106.15632850000001, 20.565656982000036],
              [106.15938377600004, 20.562051100000112],
              [106.16508568200001, 20.554446644],
              [106.16829129900003, 20.549415166000081],
              [106.170661986, 20.545543956000053],
              [106.17305680400008, 20.539078926000087],
              [106.17588635100007, 20.530153118000079],
              [106.17732459800006, 20.523161172000037],
              [106.17789914400008, 20.518873422000091],
              [106.17796246800008, 20.517959221000034],
              [106.17807751300009, 20.516033783000061],
              [106.17758879100006, 20.509544569000049],
              [106.17695919500002, 20.503443237000063],
              [106.17701842700004, 20.497088141000042],
              [106.17717504200003, 20.495014124000122],
              [106.17913911500008, 20.49049060400003],
              [106.18067441400011, 20.48777942600006],
              [106.18179304500003, 20.48558361100001],
              [106.18363298000003, 20.483660999000094],
              [106.18142693300011, 20.482983981000032],
              [106.17820013100003, 20.481968869000063],
              [106.17633168200005, 20.480675310000059],
              [106.17183928200006, 20.475494890000064],
              [106.16967486800003, 20.47402404700005],
              [106.16187564000006, 20.472264082],
              [106.15203015, 20.470123456000074],
              [106.1475518770001, 20.469480467000075],
              [106.14524351900006, 20.469703194000118],
              [106.14240776800008, 20.471495401000084],
              [106.14081100100005, 20.473224173000034],
              [106.13930075000005, 20.474859229000053],
              [106.13813156600004, 20.476544369000038],
              [106.13746099600007, 20.479686587000053],
              [106.13734333800001, 20.485315423000117],
              [106.13857518400006, 20.494742046000077],
              [106.13528487000005, 20.495567899000022],
              [106.13326895400004, 20.495973272000079],
              [106.13237074400007, 20.49638810500003],
              [106.12988551900006, 20.499112904000057],
              [106.12853413800006, 20.500157532000074],
              [106.12763690900007, 20.500466743000075],
              [106.12685550200008, 20.500354492000028],
              [106.12552938700006, 20.498759087000096],
              [106.12408342400005, 20.498007502000078],
              [106.12129316400011, 20.497561282000021],
              [106.1151497550001, 20.497086366000069],
              [106.11247028700009, 20.496746600000023],
              [106.10290502900003, 20.491806312000094],
              [106.10001646700003, 20.489986024000117],
              [106.09824666000006, 20.488069734000099],
              [106.09661023800003, 20.483936702000079],
              [106.096022772, 20.481132869000085],
              [106.09648524000008, 20.479552664000074],
              [106.09977147800001, 20.47493401400002],
              [106.10316828900005, 20.470421850000072],
              [106.10329130400001, 20.469261147000047],
              [106.10330359100006, 20.467993864000086],
              [106.10298471200005, 20.46630127300002],
              [106.10014575499999, 20.459411739000096],
              [106.09949064300005, 20.457821836000086],
              [106.09860587900003, 20.456863645000034],
              [106.09715931100007, 20.456217443000035],
              [106.09503505200007, 20.456304706000061],
              [106.090331303, 20.457320145000054],
              [106.07911180500001, 20.461764118000048],
              [106.07675671500002, 20.462588493000041],
              [106.07528834900003, 20.464159918000036],
              [106.074260741, 20.466368868000103],
              [106.07323102900007, 20.468789033000064],
              [106.07222002300009, 20.469308264000041],
              [106.07087895400005, 20.469296523000047],
              [106.06953892600004, 20.469179161000014],
              [106.06675755100011, 20.467887387000069],
              [106.06364199800008, 20.46648701500002],
              [106.06252444800008, 20.466477188000098],
              [106.06094417800006, 20.468047526000085],
              [106.05732291700011, 20.472557083000083],
              [106.05540939200006, 20.473913194000112],
              [106.05216523700004, 20.474201370000067],
              [106.04970760499999, 20.474074013000099],
              [106.04736699000004, 20.473419591000038],
              [106.04314131200009, 20.471269786000065],
              [106.0415968020001, 20.469249369000053],
              [106.04053636600003, 20.463536671000071],
              [106.04012215000003, 20.460258866000025],
              [106.04014225000002, 20.45825232],
              [106.04051743200006, 20.454244923000076],
              [106.04053778400005, 20.452235622000089],
              [106.04032698400002, 20.450966324000049],
              [106.0393308000001, 20.450006890000068],
              [106.03755771300004, 20.448512438000051],
              [106.03566867200006, 20.44743941100009],
              [106.03244779400011, 20.446892610000056],
              [106.03102915400005, 20.446681219000062],
              [106.02886845400008, 20.446208062000082],
              [106.02783898200006, 20.445758694000027],
              [106.02784720800004, 20.444942522000083],
              [106.02813184600005, 20.441216692000097],
              [106.02765650900007, 20.439348238000044],
              [106.02595059200004, 20.437002702000129],
              [106.02326355800004, 20.433949263000081],
              [106.02157423000006, 20.429972625000033],
              [106.0184734920001, 20.418992327000041],
              [106.01801010100004, 20.415958730000035],
              [106.01807659700003, 20.413666198000065],
              [106.01833289300001, 20.412625317],
              [106.02033163600001, 20.409397822000038],
              [106.02393340600004, 20.405605127000022],
              [106.02591795700002, 20.403768351000018],
              [106.02669323200004, 20.403050789000062],
              [106.02628381600009, 20.403038901000059],
              [106.00453620100004, 20.402406253000024],
              [106.00227569500007, 20.401899800000045],
              [105.99720903100007, 20.39907541200003],
              [105.9949659440001, 20.397249582000128],
              [105.98890088700009, 20.403722772000066],
              [105.98811279100008, 20.405030967000037],
              [105.98776989400004, 20.408243236000075],
              [105.98802865900004, 20.413214825000111],
              [105.98905906700003, 20.418485726000043],
              [105.99070213, 20.423728199000024],
              [105.99249889599999, 20.428384097000048],
              [105.99248699800006, 20.429543857000084],
              [105.99198920500001, 20.430119247000086],
              [105.99100194600003, 20.430458211000065],
              [105.98915514300008, 20.430673325000015],
              [105.984482622, 20.430630611000119],
              [105.98287695600003, 20.431311836000084],
              [105.981138738, 20.432919718000072],
              [105.98013348800005, 20.434998253000103],
              [105.97973944900005, 20.437430341000073],
              [105.97945400300006, 20.441255240000089],
              [105.97624723900002, 20.442153678000082],
              [105.97515012800002, 20.441215712000101],
              [105.97467506200006, 20.439587552000127],
              [105.97388034100003, 20.43320101700008],
              [105.97365243500009, 20.431459120000014],
              [105.97222354800007, 20.427442368000115],
              [105.97223911000003, 20.426035381000048],
              [105.97224225300002, 20.425754149000049],
              [105.97218932900009, 20.424312431000132],
              [105.96739520400008, 20.424152241000023],
              [105.96712639400005, 20.426353531000082],
              [105.96392827400011, 20.426439975000044],
              [105.96233343000004, 20.426077253000052],
              [105.96013832200005, 20.424317120000076],
              [105.95528547800006, 20.418008722000103],
              [105.9540729170001, 20.416373611000083],
              [105.95272772200009, 20.415665174000083],
              [105.94868855700003, 20.41388773700001],
              [105.94795572400005, 20.413416948000048],
              [105.94759173600005, 20.412949595000015],
              [105.94735677900006, 20.411903489000061],
              [105.94761971000005, 20.410282077000105],
              [105.94815041400008, 20.406575319000098],
              [105.94791303000008, 20.405761170000055],
              [105.94742611400002, 20.405292665000019],
              [105.94620397100006, 20.404585315000112],
              [105.93876674300003, 20.4007175140001],
              [105.93536462900001, 20.398948039000061],
              [105.93063129000005, 20.398655366000092],
              [105.9277292150001, 20.399372762000034],
              [105.92693989300007, 20.399365332000038],
              [105.92483764600004, 20.399097302000065],
              [105.92379047400007, 20.398590965],
              [105.92321496100007, 20.397102390000029],
              [105.92281122900006, 20.394450357000068],
              [105.92377643700007, 20.384885014000083],
              [105.92511072400006, 20.381230747000068],
              [105.92773171800005, 20.378403433000081],
              [105.93012811400007, 20.376388823000099],
              [105.93231503700011, 20.373761062000106],
              [105.93320225900005, 20.371528517000037],
              [105.9333293270001, 20.368352498000064],
              [105.93238344600007, 20.369139786000098],
              [105.93086012000006, 20.369125472000071],
              [105.92934330000007, 20.368495153000055],
              [105.92458837500001, 20.36537008800012],
              [105.92264073900007, 20.364324943000064],
              [105.92002938500011, 20.36430028300007],
              [105.91805122200003, 20.366129765],
              [105.91626877200008, 20.370014595],
              [105.91493243000008, 20.372876862000041],
              [105.9123477200001, 20.377735899000093],
              [105.91033940500002, 20.376201754000078],
              [105.90834261400001, 20.374386142000041],
              [105.90700767700004, 20.37352797000009],
              [105.90611170100007, 20.373519450000096],
              [105.90409122600005, 20.373922965000091],
              [105.9001565670001, 20.375259377000084],
              [105.89835324400011, 20.376299025000044],
              [105.89631569000001, 20.378287573000087],
              [105.894951233, 20.380176851000087],
              [105.89414562400005, 20.382177145000036],
              [105.89332976300004, 20.385128494000071],
              [105.89264066800004, 20.386707148000127],
              [105.89128408700007, 20.387856680000112],
              [105.89004519600006, 20.388478906000081],
              [105.88734678, 20.389404176000077],
              [105.88621644600008, 20.390344482000074],
              [105.88514852900001, 20.391736049000109],
              [105.87814613700007, 20.400201760000058],
              [105.87390680300005, 20.406872201000056],
              [105.87308443100004, 20.40772714300001],
              [105.87195822600003, 20.40848326600004],
              [105.86971204200007, 20.409420307000033],
              [105.86787339100009, 20.410265382000055],
              [105.86674192700008, 20.411500805000045],
              [105.86167739600009, 20.419217534000019],
              [105.85960912600011, 20.422457149000103],
              [105.85929375000003, 20.423412825000042],
              [105.85825853700001, 20.425128477000065],
              [105.85671513400011, 20.426839183000119],
              [105.85533099600008, 20.427928233000081],
              [105.85440367200009, 20.429069674000019],
              [105.85337469600003, 20.430210119000058],
              [105.85305611200005, 20.431453359000074],
              [105.85272692100008, 20.433774753000122],
              [105.85269668600007, 20.436529986],
              [105.85235541800007, 20.441004354000114],
              [105.851147955, 20.451187861000086],
              [105.85058296400007, 20.456073265],
              [105.84970155500005, 20.463159877000066],
              [105.84966294500006, 20.466672637000016],
              [105.84990742800004, 20.471014752000102],
              [105.85013451100008, 20.476941005000079],
              [105.84997857600005, 20.477834967000064],
              [105.84938662600011, 20.478518029],
              [105.84858006800008, 20.478785691000034],
              [105.84741130500001, 20.478774273000063],
              [105.84471494500011, 20.478995721000089],
              [105.84441331200001, 20.4790204930001],
              [105.84289938100008, 20.479314459000086],
              [105.83583827299999, 20.48310585800008],
              [105.82802933200003, 20.487290276000088],
              [105.82329176200008, 20.489771318000024],
              [105.82146596600009, 20.491051325000065],
              [105.81933584400007, 20.493626351000046],
              [105.81831157000005, 20.494504352000043],
              [105.81752681900004, 20.494929816000074],
              [105.81730506600007, 20.496989009000096],
              [105.81626525200002, 20.501101465000104],
              [105.81622091100007, 20.505072882000071],
              [105.81471769400005, 20.504853033000089],
              [105.81276740000007, 20.504287140000073],
              [105.81176061000008, 20.503593968000096],
              [105.81111765900008, 20.502767773000038],
              [105.81041830600003, 20.500506347000069],
              [105.809705201, 20.499474490000047],
              [105.80913013700003, 20.499058870000027],
              [105.8081164970001, 20.498980467000116],
              [105.80724701200009, 20.498971815000019],
              [105.80608463000002, 20.499233511000092],
              [105.80484365700002, 20.500040964000021],
              [105.80410834200011, 20.500990091000034],
              [105.80302477300006, 20.502782102000069],
              [105.80222996400002, 20.504301675000121],
              [105.80098793100002, 20.51101025100008],
              [105.80052122800004, 20.512166479000093],
              [105.79979873600004, 20.513014651000013],
              [105.79895005500008, 20.51355607200005],
              [105.79810618400006, 20.513669836000048],
              [105.79739329800006, 20.51366271100008],
              [105.794615537, 20.512840638000092],
              [105.79409707900008, 20.512835448000111],
              [105.79364205700008, 20.512953088000074],
              [105.793378003, 20.513378145000068],
              [105.79317599500007, 20.514048205000087],
              [105.79217561100009, 20.516543244000026],
              [105.79057327600002, 20.520681900000071],
              [105.78930805700006, 20.52366303000008],
              [105.78728854900002, 20.527876844000033],
              [105.78452028000004, 20.534393721000029],
              [105.78229321800006, 20.538864651000033],
              [105.78123812100007, 20.540514595000033],
              [105.77997685200008, 20.542064786000047],
              [105.77713046200003, 20.545007656000088],
              [105.77360671300005, 20.548876305000086],
              [105.77260015200008, 20.549959308000076],
              [105.77090769700003, 20.553065550000049],
              [105.76934168500004, 20.559608698000083],
              [105.76807637100006, 20.56489719900005],
              [105.76797789300008, 20.565141830000044],
              [105.77132896300006, 20.565656411000077],
              [105.77741087800004, 20.566145916000075],
              [105.78040399400007, 20.566604179000066],
              [105.7827573050001, 20.567398443000059],
              [105.78502366600003, 20.567849330000065],
              [105.78747464100006, 20.568045184000106],
              [105.79074229000005, 20.568334822000089],
              [105.79201320200005, 20.568433171000052],
              [105.79301061300006, 20.56861439900004],
              [105.79346099100003, 20.568961393000059],
              [105.79408630700007, 20.56990946500008],
              [105.79548101800002, 20.575146176000111],
              [105.79784047300011, 20.58347472700008],
              [105.79862347700005, 20.586564776000046],
              [105.7989309970001, 20.591533627000032],
              [105.79949714300004, 20.594538010000107],
              [105.80170349100007, 20.598266979000087],
              [105.80543767300004, 20.60359980300008],
              [105.80836164400003, 20.606011901000088],
              [105.81027555300003, 20.607485837000091],
              [105.81002857700003, 20.611374844000082],
              [105.81007204800007, 20.613269579000089],
              [105.81015470600008, 20.615501854000051],
              [105.81051288300004, 20.617543741000063],
              [105.81049791800004, 20.618879574000104],
              [105.80995902800004, 20.619542057000025],
              [105.81043720800008, 20.620349949000079],
              [105.81062057900007, 20.621657450000086],
              [105.81142737000003, 20.634137742000021],
              [105.81149104500005, 20.635376993000065],
              [105.81465529700003, 20.637340114000047],
              [105.82375110000007, 20.641886108000037],
              [105.82907933600004, 20.644909294000065],
              [105.83110617000006, 20.647008702000043],
              [105.8320341740001, 20.648651688000093],
              [105.83218197300008, 20.649544337000059],
              [105.83185505700003, 20.650580849000022],
              [105.83090067500011, 20.651314126000031],
              [105.82773034700007, 20.652768241000082],
              [105.82566712100007, 20.653936153000082],
              [105.81785723400003, 20.661285555000042],
              [105.82207537400006, 20.664891945000093],
              [105.82458207300009, 20.666401972000067],
              [105.82521287900005, 20.666408192000048],
              [105.8301330190001, 20.663634601000069],
              [105.832189719, 20.6630607170001],
              [105.8370998120001, 20.661178038000024],
              [105.84106033700006, 20.659583013000123],
              [105.84375922800008, 20.657975582000084],
              [105.84551354600011, 20.656210359000049],
              [105.84887418900007, 20.651787232000096],
              [105.85190298500004, 20.648846111000104],
              [105.85508285100006, 20.646500537000101],
              [105.85587454700001, 20.646211170000051],
              [105.86282415100003, 20.645238896000073],
              [105.87165618100008, 20.644419364000029],
              [105.87381607500001, 20.644175179000079],
              [105.87616065000002, 20.64419777100008],
              [105.8781272140001, 20.644481721000126],
              [105.88214552800005, 20.645845441000077],
              [105.88589606600004, 20.64744707100008],
              [105.89016706400011, 20.649985925000088],
              [105.8948859380001, 20.65314852700002],
              [105.89419179500004, 20.659366860000091],
              [105.89418267400001, 20.660213016000029],
              [105.89408354500006, 20.669410038000088],
              [105.89559122600011, 20.669424427000124],
              [105.89703434900005, 20.669076728000043],
              [105.89858119800004, 20.668007097],
              [105.90012899100005, 20.666847090000125],
              [105.90108592600008, 20.666361044000055],
              [105.90184859900003, 20.666288573000102],
              [105.90252490600004, 20.666374733000033],
              [105.90378518100005, 20.667263639000041],
              [105.90714200100005, 20.670006011000019],
              [105.91117524000008, 20.672834442000095],
              [105.92013828700006, 20.677512970000045],
              [105.9291805010001, 20.679899684000077],
              [105.94020031000008, 20.682524637000107],
              [105.94153928200011, 20.682537136000079],
              [105.94358024800005, 20.680874952000039],
              [105.944254879, 20.67746651500002],
              [105.94463604100009, 20.673056922000029],
              [105.94337228300007, 20.667423433000032],
              [105.941580996, 20.664256620000018],
              [105.94140050800009, 20.663937518000083],
              [105.94144036000009, 20.66015510900003],
              [105.94180162000005, 20.657636615000015],
              [105.94483714400003, 20.655458290000084],
              [105.95638109200004, 20.651466846000055],
              [105.95546109900003, 20.654753072000084],
              [105.95510947300008, 20.656024307000017],
              [105.95508946700002, 20.657935859000084],
              [105.955920195, 20.659377369000076],
              [105.9594356640001, 20.662914752000106],
              [105.96414694100008, 20.665347934000074],
              [105.966850046, 20.665691487000082],
              [105.97124302000003, 20.666209874000074],
              [105.97507141799998, 20.666425375000053],
              [105.97685533400006, 20.671633953000068],
              [105.97684279000001, 20.672845346000109],
              [105.97663840500006, 20.674833799000055],
              [105.97763573500006, 20.676140977000031],
              [105.98181612400001, 20.680679109],
              [105.98336754600003, 20.681731720000052],
              [105.98611268400006, 20.682881758000079],
              [105.98739738400003, 20.683066551000096],
              [105.98935532800003, 20.680315507000024],
              [105.99250921700005, 20.67748834500005],
              [105.99598857900007, 20.678731483000057],
              [105.99393230700011, 20.685431600000115],
              [105.99090284500009, 20.69071351700007],
              [105.9963872810001, 20.693619005000038],
              [105.99385630700003, 20.698441851000027],
              [105.9967329350001, 20.700241925000014],
              [105.9987423220001, 20.701471614000063],
              [105.99983803900007, 20.70217381300008],
              [106.00056350300005, 20.703132239000062],
              [106.00126235600005, 20.705280123000058],
              [106.00350236900003, 20.70520970400009],
              [106.0067825400001, 20.704622851000032],
              [106.00839320700005, 20.704166985000057],
              [106.01269306500011, 20.702949929000077],
              [106.01650949700004, 20.701134730000078],
              [106.01954265100001, 20.699065816000051],
              [106.02297732400007, 20.696137337000039],
              [106.02562248300008, 20.693571633000118],
              [106.03002167100001, 20.687815618000101],
              [106.03175444100003, 20.685622433000091],
              [106.03267168000006, 20.683242360000072],
              [106.03301032900002, 20.682363623000107],
              [106.03324707000007, 20.679095714000084],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 24, code: "AD01", ten_tinh: "Hà Nội" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.82965304500009, 21.375121054000076],
              [105.83066927600005, 21.373933306000126],
              [105.83360267100002, 21.375001332000032],
              [105.83534112300006, 21.377594833000032],
              [105.83651307200005, 21.378158385000035],
              [105.84502102400003, 21.378631111000068],
              [105.84514650600002, 21.378317471000052],
              [105.84636804700001, 21.37626607400005],
              [105.84691726900007, 21.375761927000077],
              [105.84748714500006, 21.375525047000018],
              [105.84933588600009, 21.375774350000043],
              [105.85253074300002, 21.377077781000061],
              [105.85363770200004, 21.377435535000089],
              [105.85462507500002, 21.377445095000077],
              [105.85598255800008, 21.376596807000055],
              [105.85693321900004, 21.375211714000031],
              [105.85744028100007, 21.374002005000037],
              [105.85761914000004, 21.368971733000116],
              [105.85750846600003, 21.367813883000061],
              [105.85696010900004, 21.367172341000092],
              [105.85636359900008, 21.366565853000097],
              [105.85464479900006, 21.365915010000066],
              [105.85227715000008, 21.364181445000071],
              [105.85102702300003, 21.362197006000102],
              [105.85035541800006, 21.360215178000082],
              [105.84982444700009, 21.357446675000077],
              [105.85063088100003, 21.351171092000065],
              [105.85112927300005, 21.34928322600009],
              [105.85129887800005, 21.348916258000102],
              [105.85326107300006, 21.344670653],
              [105.85527279200005, 21.340994678000058],
              [105.85665921100011, 21.33901823500004],
              [105.86311577300009, 21.33097892500011],
              [105.86503052900005, 21.329332588000049],
              [105.86601804800006, 21.329016997000046],
              [105.86677916800005, 21.32874005200005],
              [105.87588608000004, 21.327832596000107],
              [105.88087422700002, 21.327296520000068],
              [105.88458550100005, 21.326794571000015],
              [105.88774701100007, 21.326399504000015],
              [105.88985024600001, 21.326402539000085],
              [105.89176736100009, 21.326867253000081],
              [105.89260373300002, 21.327049856000087],
              [105.89502475200004, 21.327552115000081],
              [105.89611424600004, 21.327281273000054],
              [105.89665473200009, 21.326800790000085],
              [105.89715483000008, 21.325806618000072],
              [105.89736093000008, 21.323974726000117],
              [105.89621540800006, 21.31646302000005],
              [105.89543915100005, 21.311805462000066],
              [105.895464304, 21.309480598000086],
              [105.89714809800005, 21.306654780000045],
              [105.90093022300007, 21.304327890000074],
              [105.90407755899999, 21.302845239000057],
              [105.90655430700011, 21.303127],
              [105.91146761900002, 21.305725094000039],
              [105.91423517000004, 21.306299627000058],
              [105.91563288800005, 21.3044667220001],
              [105.91485818500007, 21.299588438000058],
              [105.91488033500003, 21.297521840000044],
              [105.91544239600009, 21.296493729000062],
              [105.91710366200006, 21.295734306000021],
              [105.92646492900012, 21.296338833000036],
              [105.9286716340001, 21.296101135000086],
              [105.92923361800005, 21.295072977000103],
              [105.92905599300011, 21.293623075000042],
              [105.92898012700005, 21.293003795000054],
              [105.92706546100007, 21.291694121000049],
              [105.92289101500009, 21.288963474000063],
              [105.92106270000006, 21.286212418000083],
              [105.92053659200006, 21.283882267000109],
              [105.92065254900007, 21.273032270000058],
              [105.92174931600003, 21.269908493000052],
              [105.93109788000002, 21.255456326000029],
              [105.93410053300005, 21.251710949000113],
              [105.93583385500001, 21.249587546000015],
              [105.93650940500004, 21.247459568000068],
              [105.93638104200008, 21.246121137000081],
              [105.93556722500007, 21.244376096000025],
              [105.935090761, 21.244967488000022],
              [105.93459870600005, 21.245552168000081],
              [105.93395723800009, 21.245763921000012],
              [105.93292845000005, 21.245962757000122],
              [105.92490416700012, 21.24574564600001],
              [105.92445442700003, 21.245315265000016],
              [105.92430754400004, 21.244887713000082],
              [105.92431663400001, 21.244035457000088],
              [105.925706738, 21.241491459000095],
              [105.92571884900012, 21.240355115000042],
              [105.92542357100011, 21.239642060000016],
              [105.92406983100001, 21.238777022000122],
              [105.92197964700009, 21.237314070000046],
              [105.92010721500006, 21.236093733000061],
              [105.91958538600005, 21.235522827000075],
              [105.9189987340001, 21.233960802000063],
              [105.918702395, 21.233462761000062],
              [105.91773038400004, 21.23267536300002],
              [105.91675233300003, 21.232453911000079],
              [105.91546808700008, 21.232654076000031],
              [105.91410616700003, 21.23306575400008],
              [105.91320049000005, 21.233127971000073],
              [105.91033794100008, 21.23281797],
              [105.90770011600004, 21.232651557000089],
              [105.90535936900008, 21.232912428000091],
              [105.90483302200005, 21.23276594600005],
              [105.90438818600002, 21.232054227000049],
              [105.90331114600008, 21.22702070000004],
              [105.90287744200008, 21.223555256000012],
              [105.90363983500001, 21.223384104000061],
              [105.90454525100004, 21.223676803000032],
              [105.9062000860001, 21.224686887000033],
              [105.90725540600002, 21.225123055000061],
              [105.9080109400001, 21.22527225000006],
              [105.90907237100008, 21.225140222000057],
              [105.90999000600007, 21.224296515000063],
              [105.91198748800005, 21.220094992000057],
              [105.91313244300001, 21.217806394000057],
              [105.91372004400004, 21.215249752000084],
              [105.91377848000009, 21.209797414000064],
              [105.9116007100001, 21.20392974400005],
              [105.91111766100002, 21.203268198000025],
              [105.9102845630001, 21.202603355000079],
              [105.90861060300006, 21.201996254000029],
              [105.90567389500009, 21.19932265300006],
              [105.90399951500009, 21.196381257000048],
              [105.90475806000006, 21.19493600400002],
              [105.90485701400009, 21.19302459000005],
              [105.91142413400007, 21.189985395000029],
              [105.91906366200004, 21.185058536000057],
              [105.92085203100004, 21.18386350400003],
              [105.92101990200008, 21.183259160000119],
              [105.919436644, 21.180366145000029],
              [105.916118603, 21.1736697300001],
              [105.91549411100002, 21.171694568000099],
              [105.91552329800004, 21.168968144000111],
              [105.91602367700006, 21.167458011000036],
              [105.91944195700009, 21.164763454000109],
              [105.9212317540001, 21.163416905000076],
              [105.92123822200003, 21.162811024000035],
              [105.92048556400002, 21.161438918000016],
              [105.91758869200004, 21.157529774000047],
              [105.91749078100003, 21.156507293000089],
              [105.91992315500002, 21.152954700000045],
              [105.92436105200004, 21.14502811800012],
              [105.92458957400002, 21.144008681000059],
              [105.92449708500001, 21.142475440000027],
              [105.92429360600005, 21.141145475000123],
              [105.92198182, 21.138518720000029],
              [105.92004494200006, 21.136457345000089],
              [105.91508100300004, 21.132528558],
              [105.91497656600009, 21.132118926000132],
              [105.91508975100004, 21.131711353000071],
              [105.91597227700007, 21.130595910000061],
              [105.91597883600006, 21.129983007000064],
              [105.91576557900001, 21.129572358000104],
              [105.91490712400012, 21.128440499000064],
              [105.91393767400002, 21.12751191400006],
              [105.91285066700006, 21.127399500000088],
              [105.91198018300007, 21.127391286000119],
              [105.91143832400009, 21.12718184600002],
              [105.91079423200009, 21.126358480000107],
              [105.91102061200007, 21.12554333000007],
              [105.91267283200008, 21.123656752000031],
              [105.91323460800002, 21.123085752000037],
              [105.91989702300008, 21.12248895700013],
              [105.9222675120001, 21.121183825000053],
              [105.92009870800007, 21.118026403000023],
              [105.92075394700001, 21.117423431000049],
              [105.92776450300009, 21.112595370000072],
              [105.92990999600006, 21.110565157000046],
              [105.93176130800003, 21.107293114000036],
              [105.93309878000011, 21.10352892900007],
              [105.93351125200005, 21.101339853000042],
              [105.93365925900012, 21.098353426000038],
              [105.93393966000005, 21.097101900000048],
              [105.93919184900004, 21.085731140000071],
              [105.93992566, 21.085055579000041],
              [105.94108677800006, 21.085202869000064],
              [105.94369845800006, 21.086238207000108],
              [105.95127864200001, 21.09039944900006],
              [105.95489450400005, 21.091796534000103],
              [105.95562054599999, 21.091803245000037],
              [105.95617043900002, 21.091296968000073],
              [105.95727379200009, 21.089943535000089],
              [105.95802482700006, 21.087564108000031],
              [105.96047897100009, 21.078552601000041],
              [105.96318546400002, 21.075886771000093],
              [105.96524241100003, 21.074335045000048],
              [105.96688830300006, 21.073156967],
              [105.9709700810001, 21.072042138000043],
              [105.97616567600002, 21.071196555000071],
              [105.98344742100006, 21.0705882500001],
              [105.99096257100005, 21.070043436000049],
              [105.99220166100008, 21.070359702000104],
              [105.9949906320001, 21.071158036000078],
              [105.99915664400001, 21.072486944000076],
              [106.00097676900006, 21.073067511000062],
              [106.00244947100005, 21.070842647000099],
              [106.0033980580001, 21.069610720000043],
              [106.00481187400003, 21.06414673500003],
              [106.00507085400005, 21.064394692000029],
              [106.00649777899999, 21.065512849000086],
              [106.00809998400004, 21.063277172000113],
              [106.01130193400012, 21.057288188000101],
              [106.01181644100005, 21.056112845000108],
              [106.01235019800006, 21.053049715000057],
              [106.01273787300008, 21.051991220000055],
              [106.01362679500009, 21.051055225000056],
              [106.01762854400009, 21.048636259000105],
              [106.01805086600004, 21.048380966000124],
              [106.01836448300006, 21.042247828000079],
              [106.0165175920001, 21.038573264000071],
              [106.0144609270001, 21.034920885000119],
              [106.01166747300007, 21.031615052000063],
              [106.00425495400005, 21.024674289000075],
              [106.00245468100003, 21.02184593],
              [106.00213813500005, 21.020280781000089],
              [106.00214133700004, 21.019968352000085],
              [106.00231722100003, 21.019032565000103],
              [106.0033260750001, 21.017948081000078],
              [106.00733633200009, 21.012903512000044],
              [106.00756223700004, 21.012710387000055],
              [106.00738535300006, 21.011266800000072],
              [106.00607117000004, 21.009692635000121],
              [106.00308474700003, 21.009040752000097],
              [105.99993048400009, 21.008543550000077],
              [105.99778024100007, 21.007430460000087],
              [105.99728625500006, 21.006957288000102],
              [105.99900933800006, 21.001745332000077],
              [105.99786863200011, 21.001897624000065],
              [105.99530330300003, 21.002095140000065],
              [105.99232867100002, 21.002320227000034],
              [105.9888548280001, 21.001715472000093],
              [105.98785685700004, 20.999615436000063],
              [105.98492407400003, 20.997517589000033],
              [105.98298148700006, 20.997499872000077],
              [105.97919541800006, 20.99830042300006],
              [105.97742849200006, 20.999504963000071],
              [105.97658985300004, 20.999497288000057],
              [105.97508358000002, 20.999168263000101],
              [105.97107616800008, 20.997397758000069],
              [105.97024738400009, 20.996444464000064],
              [105.96975570600009, 20.995336635000058],
              [105.96960767500009, 20.993443886000037],
              [105.96897439200009, 20.991861911000065],
              [105.96662483200002, 20.989948910000052],
              [105.96562509900008, 20.989309243000065],
              [105.96461220000003, 20.98993038700003],
              [105.96309120200004, 20.991019692000101],
              [105.96173955000002, 20.991952927000042],
              [105.96047301400003, 20.99207558600007],
              [105.95915901000002, 20.988127116000044],
              [105.96011840700001, 20.986578985000023],
              [105.96064628800008, 20.984219587000027],
              [105.96099984600002, 20.982489045000108],
              [105.96087664600003, 20.978232177000052],
              [105.96123843100004, 20.975713590000034],
              [105.96229243700003, 20.971152292000014],
              [105.96269237600008, 20.968920088000061],
              [105.96300606700001, 20.967060694000047],
              [105.96285153700011, 20.965798286000044],
              [105.96223765800002, 20.965072412000026],
              [105.96181843600007, 20.965158267000028],
              [105.96101344900008, 20.965150842000035],
              [105.96000395900003, 20.965456769000035],
              [105.95545296900008, 20.967621441000084],
              [105.95467792600007, 20.968078420000055],
              [105.95225727100006, 20.969486110000091],
              [105.93708758800001, 20.968880373000061],
              [105.93473637000007, 20.96898204700004],
              [105.93377960300005, 20.971202821000112],
              [105.9327583510001, 20.97261187000008],
              [105.930896874, 20.974170661000088],
              [105.92954853300009, 20.97478851900005],
              [105.92215604000003, 20.975980113000055],
              [105.91846312100009, 20.976260612000083],
              [105.91426875200005, 20.976378694000083],
              [105.91226470300006, 20.9755716660001],
              [105.91009466000003, 20.974605426],
              [105.90926968100007, 20.973435330000086],
              [105.90896804900007, 20.970181357000058],
              [105.90816165600005, 20.967178894000057],
              [105.90504141300006, 20.961001994000036],
              [105.90340344900008, 20.957361080000055],
              [105.90245664800008, 20.951835169000077],
              [105.90242451100008, 20.947027209000076],
              [105.91276707900008, 20.938455511000065],
              [105.90946167900005, 20.931961372000082],
              [105.90634621400002, 20.927360526000079],
              [105.90972778300004, 20.923766956000023],
              [105.91024810500002, 20.923141340000129],
              [105.91092842799999, 20.921256156000112],
              [105.91095375200004, 20.918891868000067],
              [105.91030675600007, 20.916678842000103],
              [105.90831516900005, 20.914768358000032],
              [105.90283003600007, 20.910302514000101],
              [105.89684561000007, 20.905516463000048],
              [105.8945359950001, 20.902026397000029],
              [105.89372878900011, 20.900127018000077],
              [105.89373692300006, 20.898393049000049],
              [105.89426707000005, 20.895875833000055],
              [105.8957962110001, 20.893998702000083],
              [105.89611312600003, 20.893876066000061],
              [105.89799585900002, 20.892127936000101],
              [105.9059186910001, 20.888104472000087],
              [105.91198884800008, 20.884851411000049],
              [105.91622329700004, 20.882701758000088],
              [105.92462609000003, 20.878419229000045],
              [105.92846485200003, 20.875253619000134],
              [105.93170876100004, 20.871517339000079],
              [105.93334861600009, 20.867954336000061],
              [105.93438769700001, 20.864385674000019],
              [105.93441162000002, 20.862125856000091],
              [105.9337437020001, 20.859012037000113],
              [105.93198352400003, 20.85504044800004],
              [105.92980899900004, 20.852383326],
              [105.92782474500009, 20.850669651000061],
              [105.91846247800004, 20.846061410000068],
              [105.91468851700006, 20.843200668000073],
              [105.91172559200005, 20.839405800000051],
              [105.90938156400009, 20.833921615000058],
              [105.90919951900003, 20.832224784000083],
              [105.90928425500002, 20.824314995000037],
              [105.90952075800006, 20.820926952000015],
              [105.91119485700001, 20.814162171000113],
              [105.91418911300005, 20.805620427000093],
              [105.91950612600006, 20.795122677000052],
              [105.92075986200005, 20.793620507000107],
              [105.92357211400004, 20.790263649000096],
              [105.92659653599999, 20.788220133000031],
              [105.93301696800009, 20.785831674000029],
              [105.95227548900007, 20.781867241000015],
              [105.95849597300005, 20.780417973000098],
              [105.96111368300011, 20.778935270000076],
              [105.96394523900008, 20.776135976000099],
              [105.96597614700008, 20.773329258000039],
              [105.96660992900003, 20.770132912000072],
              [105.9664411680001, 20.767117527000067],
              [105.96578578200005, 20.762861072000049],
              [105.96542070800004, 20.760419861000031],
              [105.96549437700001, 20.757535268000105],
              [105.96550229800005, 20.75726365300001],
              [105.96558192600006, 20.754528872000044],
              [105.96571422300008, 20.750440291000061],
              [105.96639697400009, 20.742534991000113],
              [105.96745830900007, 20.736705193000127],
              [105.9674759530001, 20.735009983000019],
              [105.96449717600011, 20.731780156000035],
              [105.96372033800004, 20.729512476000075],
              [105.96355363100011, 20.726308532000104],
              [105.96398721000001, 20.723110111000054],
              [105.96522701000001, 20.71935397500004],
              [105.96892067800009, 20.710534123000102],
              [105.97117437600005, 20.705468530000104],
              [105.972211911, 20.70189877300006],
              [105.97322399000008, 20.700777771000034],
              [105.97462838800001, 20.700413897000125],
              [105.97762748200009, 20.700629791000047],
              [105.981022823, 20.701226051000084],
              [105.98619747000005, 20.703910716000081],
              [105.98780087000003, 20.704678883000049],
              [105.98999861100006, 20.70494550800008],
              [105.99162442900011, 20.705142733000059],
              [105.99235935500008, 20.705231882000064],
              [105.99633547900008, 20.705253465000069],
              [106.00126235600005, 20.705280123000058],
              [106.00056350300005, 20.703132239000062],
              [105.99983803900007, 20.70217381300008],
              [105.9987423220001, 20.701471614000063],
              [105.9967329350001, 20.700241925000014],
              [105.99385630700003, 20.698441851000027],
              [105.9963872810001, 20.693619005000038],
              [105.99090284500009, 20.69071351700007],
              [105.99393230700011, 20.685431600000115],
              [105.99598857900007, 20.678731483000057],
              [105.99250921700005, 20.67748834500005],
              [105.98935532800003, 20.680315507000024],
              [105.98739738400003, 20.683066551000096],
              [105.98611268400006, 20.682881758000079],
              [105.98336754600003, 20.681731720000052],
              [105.98181612400001, 20.680679109],
              [105.97763573500006, 20.676140977000031],
              [105.97663840500006, 20.674833799000055],
              [105.97684279000001, 20.672845346000109],
              [105.97685533400006, 20.671633953000068],
              [105.97507141799998, 20.666425375000053],
              [105.97124302000003, 20.666209874000074],
              [105.966850046, 20.665691487000082],
              [105.96414694100008, 20.665347934000074],
              [105.9594356640001, 20.662914752000106],
              [105.955920195, 20.659377369000076],
              [105.95508946700002, 20.657935859000084],
              [105.95510947300008, 20.656024307000017],
              [105.95546109900003, 20.654753072000084],
              [105.95638109200004, 20.651466846000055],
              [105.94483714400003, 20.655458290000084],
              [105.94180162000005, 20.657636615000015],
              [105.94144036000009, 20.66015510900003],
              [105.94140050800009, 20.663937518000083],
              [105.941580996, 20.664256620000018],
              [105.94337228300007, 20.667423433000032],
              [105.94463604100009, 20.673056922000029],
              [105.944254879, 20.67746651500002],
              [105.94358024800005, 20.680874952000039],
              [105.94153928200011, 20.682537136000079],
              [105.94020031000008, 20.682524637000107],
              [105.9291805010001, 20.679899684000077],
              [105.92013828700006, 20.677512970000045],
              [105.91117524000008, 20.672834442000095],
              [105.90714200100005, 20.670006011000019],
              [105.90378518100005, 20.667263639000041],
              [105.90252490600004, 20.666374733000033],
              [105.90184859900003, 20.666288573000102],
              [105.90108592600008, 20.666361044000055],
              [105.90012899100005, 20.666847090000125],
              [105.89858119800004, 20.668007097],
              [105.89703434900005, 20.669076728000043],
              [105.89559122600011, 20.669424427000124],
              [105.89408354500006, 20.669410038000088],
              [105.89418267400001, 20.660213016000029],
              [105.89419179500004, 20.659366860000091],
              [105.8948859380001, 20.65314852700002],
              [105.89016706400011, 20.649985925000088],
              [105.88589606600004, 20.64744707100008],
              [105.88214552800005, 20.645845441000077],
              [105.8781272140001, 20.644481721000126],
              [105.87616065000002, 20.64419777100008],
              [105.87381607500001, 20.644175179000079],
              [105.87165618100008, 20.644419364000029],
              [105.86282415100003, 20.645238896000073],
              [105.85587454700001, 20.646211170000051],
              [105.85508285100006, 20.646500537000101],
              [105.85190298500004, 20.648846111000104],
              [105.84887418900007, 20.651787232000096],
              [105.84551354600011, 20.656210359000049],
              [105.84375922800008, 20.657975582000084],
              [105.84106033700006, 20.659583013000123],
              [105.8370998120001, 20.661178038000024],
              [105.832189719, 20.6630607170001],
              [105.8301330190001, 20.663634601000069],
              [105.82521287900005, 20.666408192000048],
              [105.82458207300009, 20.666401972000067],
              [105.82207537400006, 20.664891945000093],
              [105.81785723400003, 20.661285555000042],
              [105.82566712100007, 20.653936153000082],
              [105.82773034700007, 20.652768241000082],
              [105.83090067500011, 20.651314126000031],
              [105.83185505700003, 20.650580849000022],
              [105.83218197300008, 20.649544337000059],
              [105.8320341740001, 20.648651688000093],
              [105.83110617000006, 20.647008702000043],
              [105.82907933600004, 20.644909294000065],
              [105.82375110000007, 20.641886108000037],
              [105.81465529700003, 20.637340114000047],
              [105.81149104500005, 20.635376993000065],
              [105.81142737000003, 20.634137742000021],
              [105.81062057900007, 20.621657450000086],
              [105.81043720800008, 20.620349949000079],
              [105.80995902800004, 20.619542057000025],
              [105.81049791800004, 20.618879574000104],
              [105.81051288300004, 20.617543741000063],
              [105.81015470600008, 20.615501854000051],
              [105.81007204800007, 20.613269579000089],
              [105.81002857700003, 20.611374844000082],
              [105.81027555300003, 20.607485837000091],
              [105.80836164400003, 20.606011901000088],
              [105.80543767300004, 20.60359980300008],
              [105.80170349100007, 20.598266979000087],
              [105.79949714300004, 20.594538010000107],
              [105.7989309970001, 20.591533627000032],
              [105.79862347700005, 20.586564776000046],
              [105.79784047300011, 20.58347472700008],
              [105.79548101800002, 20.575146176000111],
              [105.79408630700007, 20.56990946500008],
              [105.79346099100003, 20.568961393000059],
              [105.79301061300006, 20.56861439900004],
              [105.79201320200005, 20.568433171000052],
              [105.79074229000005, 20.568334822000089],
              [105.78747464100006, 20.568045184000106],
              [105.78502366600003, 20.567849330000065],
              [105.7827573050001, 20.567398443000059],
              [105.78040399400007, 20.566604179000066],
              [105.77741087800004, 20.566145916000075],
              [105.77132896300006, 20.565656411000077],
              [105.76797789300008, 20.565141830000044],
              [105.76413482099998, 20.574688121000051],
              [105.76290512100012, 20.578284409000069],
              [105.76210285700009, 20.579147341000059],
              [105.75984804800004, 20.579995512000067],
              [105.75838408700005, 20.580976145000115],
              [105.75157369400003, 20.587502041000057],
              [105.7468987280001, 20.592058507000033],
              [105.74529107800005, 20.594033065000069],
              [105.74312930000006, 20.598241787000049],
              [105.74127428300005, 20.600093096000101],
              [105.73927562100006, 20.600307696000094],
              [105.73559898700006, 20.599976102000106],
              [105.73235421900009, 20.600060294000052],
              [105.73084634400001, 20.600985114000103],
              [105.72920961400004, 20.602261224000081],
              [105.72605810200005, 20.605049735000058],
              [105.725308236, 20.605159541000084],
              [105.72456384100005, 20.604799243000024],
              [105.71978088300008, 20.597697304000029],
              [105.7190392700001, 20.597101914000056],
              [105.7182921740001, 20.596976639000083],
              [105.71653889200007, 20.59754620500005],
              [105.71365739300003, 20.598574224000018],
              [105.7100287530001, 20.599476901000102],
              [105.70615059400002, 20.600376921000048],
              [105.70514434700007, 20.601071709000074],
              [105.70412704100002, 20.602706700000084],
              [105.70361424300005, 20.603876777000082],
              [105.70346740300006, 20.605755918000042],
              [105.703154333, 20.609947948000062],
              [105.70288027600006, 20.611490922000094],
              [105.70230001000002, 20.614757887000046],
              [105.70111977400006, 20.619343338000107],
              [105.69958905000007, 20.621803081000053],
              [105.6987371160001, 20.622413114000082],
              [105.69788933200003, 20.622669512000066],
              [105.69028952200004, 20.62241320600009],
              [105.68916436200003, 20.622312999000059],
              [105.68823109300008, 20.621861121000059],
              [105.68601428000008, 20.618831661000044],
              [105.68471610100005, 20.617491760000028],
              [105.68359621200003, 20.616949489000099],
              [105.68228339500011, 20.616847273000083],
              [105.6800289510001, 20.617000394000051],
              [105.67420512300006, 20.617381137000024],
              [105.66965151600006, 20.617686592000069],
              [105.66704341400001, 20.618334771000058],
              [105.66580441900007, 20.619214023000062],
              [105.66564815800002, 20.619423064000038],
              [105.66477475800005, 20.620591412000074],
              [105.66348117700005, 20.623787519000111],
              [105.66208779300004, 20.627827223000047],
              [105.66207667900002, 20.628756251000091],
              [105.662161294, 20.629179482000026],
              [105.66277983100011, 20.629946246000053],
              [105.66873680200005, 20.63423267400006],
              [105.66909545900006, 20.634236465000107],
              [105.66972713300001, 20.633905270000064],
              [105.670366868, 20.632898423000064],
              [105.67118893300008, 20.631640103000045],
              [105.67227494000005, 20.630806897000028],
              [105.67317458300006, 20.630562987000097],
              [105.67469984700006, 20.630494608000035],
              [105.67703006000004, 20.630603636000089],
              [105.67846165100009, 20.630872117000095],
              [105.68033051900008, 20.63207432100009],
              [105.68245638700003, 20.634292821000066],
              [105.68315868200006, 20.635567207000079],
              [105.68340966000004, 20.637090241000067],
              [105.68330396900009, 20.638440598000059],
              [105.68275195100006, 20.639617329000082],
              [105.68093958200004, 20.641203132000015],
              [105.67967020700007, 20.642372306000119],
              [105.67814335000004, 20.644544359000072],
              [105.67701769600008, 20.646985562000083],
              [105.67699928000006, 20.648534668000096],
              [105.67739010200006, 20.650068921000027],
              [105.67840122300007, 20.651829161000101],
              [105.6788987140001, 20.653389595000071],
              [105.67888486000003, 20.654555840000022],
              [105.67840626400003, 20.660091145000067],
              [105.6773850700001, 20.667856227000044],
              [105.67661644, 20.671736013000071],
              [105.67609002000006, 20.672605236000052],
              [105.67233070200005, 20.676259068000036],
              [105.67129054900005, 20.676928460000084],
              [105.66963346799999, 20.677396938000037],
              [105.66890870300006, 20.677583671000029],
              [105.66796708900004, 20.678642870000097],
              [105.66774559800011, 20.679904078000085],
              [105.6679462070001, 20.680392175000058],
              [105.66854218100011, 20.680999405000122],
              [105.67636411100008, 20.684372215000032],
              [105.67822605000005, 20.685214382000083],
              [105.67896217400002, 20.686279708000122],
              [105.67997594100007, 20.688751390000078],
              [105.68166082900002, 20.692859176000056],
              [105.682130704, 20.695331771],
              [105.68193027600003, 20.696445987000089],
              [105.68129308000006, 20.697555610000059],
              [105.68021772300008, 20.69877812200005],
              [105.67768979000009, 20.701454175000045],
              [105.67180995000004, 20.707855014000117],
              [105.66838773500004, 20.71215111700004],
              [105.66448331600002, 20.717028680000027],
              [105.66168267900011, 20.72061981600006],
              [105.66101653500004, 20.721705820000032],
              [105.65954986500003, 20.725028651000073],
              [105.65765912200008, 20.727595783000119],
              [105.65427959200005, 20.732401562000021],
              [105.65188362600006, 20.735603843000035],
              [105.65025823800003, 20.737099549000071],
              [105.64852811400006, 20.738392394000101],
              [105.64582998100011, 20.739977500000073],
              [105.6443153110001, 20.741171732000026],
              [105.64355188899999, 20.74227311200012],
              [105.64256440400001, 20.744179029000072],
              [105.64189714300004, 20.746189213000044],
              [105.64153427700003, 20.749614766000057],
              [105.64072327300008, 20.754649348000065],
              [105.64035915200006, 20.758175702000067],
              [105.63990846700008, 20.768862366000107],
              [105.64003867800001, 20.775823217000067],
              [105.64127595800007, 20.779870879000036],
              [105.64222221600002, 20.781393891000022],
              [105.64402707500005, 20.782825204000055],
              [105.64620673400002, 20.78347004700003],
              [105.64683639500007, 20.793713005000122],
              [105.63968499500007, 20.793589921000063],
              [105.63090312200006, 20.793438457000057],
              [105.62857427300003, 20.793829976000033],
              [105.62790274800005, 20.794447557000062],
              [105.62766877900006, 20.795486370000035],
              [105.62787491300006, 20.796738164000047],
              [105.62852114800006, 20.798202953],
              [105.63179418600006, 20.802090952000093],
              [105.63222673000007, 20.802928638000125],
              [105.63221788900005, 20.803657464000125],
              [105.63052130300009, 20.806659041000053],
              [105.62905512100011, 20.810246784000071],
              [105.62692617500008, 20.820157229000102],
              [105.62702038100005, 20.821511902000012],
              [105.62767935700005, 20.821935499000062],
              [105.63373479100005, 20.824603667000119],
              [105.63862515800012, 20.826077263000094],
              [105.63925789900007, 20.826670864],
              [105.63955876200001, 20.827543579000093],
              [105.63923041000001, 20.82894338700001],
              [105.63672493600011, 20.830891552000082],
              [105.6341894610001, 20.832957458000045],
              [105.63352581, 20.834906924000052],
              [105.63385133500002, 20.837456572000065],
              [105.63454576800009, 20.839845499000106],
              [105.63468405, 20.84007926],
              [105.6338737040001, 20.840536439000076],
              [105.633097655, 20.840632241],
              [105.63177451200005, 20.840201561000079],
              [105.63079100900002, 20.839149758000097],
              [105.63014334700009, 20.837789185000055],
              [105.62993968400011, 20.836329246000048],
              [105.62996497200007, 20.834247004000069],
              [105.63021035300002, 20.832271248000019],
              [105.63022046700007, 20.831438348000027],
              [105.63011358100002, 20.831124822000021],
              [105.62967592500004, 20.830703615000076],
              [105.62879050900005, 20.830694108],
              [105.62668256900008, 20.831087970000084],
              [105.61648369900009, 20.832331751000055],
              [105.61459326600007, 20.833040223000054],
              [105.61291143600002, 20.834792189000062],
              [105.61055911300006, 20.837057508],
              [105.60787852400006, 20.839006862000083],
              [105.60231372600005, 20.841445502000063],
              [105.60031374000005, 20.842048527000046],
              [105.59931758900004, 20.842037699000038],
              [105.59854536100001, 20.84182105600005],
              [105.59656973100006, 20.840445943000049],
              [105.59394542700008, 20.837814236000085],
              [105.58937012700005, 20.831829156000126],
              [105.58409922900003, 20.823734206000033],
              [105.58259012200006, 20.821044782000087],
              [105.58172754300008, 20.818591518000048],
              [105.58159264600003, 20.816375321000088],
              [105.5814624920001, 20.813777325000068],
              [105.58122466600005, 20.813316500000042],
              [105.58050081400002, 20.812773981000099],
              [105.57493459500009, 20.809963580000087],
              [105.57331892000008, 20.809334860000028],
              [105.57169375700008, 20.809469735000086],
              [105.56381424200003, 20.809917517000095],
              [105.56145572900003, 20.810273334000051],
              [105.56112631000005, 20.810651546000081],
              [105.56262932000006, 20.813799323000111],
              [105.56464996300009, 20.817754673000088],
              [105.56608715600008, 20.819679754000028],
              [105.569522389, 20.822927601000082],
              [105.57229228100003, 20.823508059000062],
              [105.57301959000009, 20.823791051000029],
              [105.57461521800003, 20.824771053000021],
              [105.57634677000004, 20.826577519000097],
              [105.57706213900008, 20.827822828000109],
              [105.57762113500006, 20.829891399000111],
              [105.57828022800005, 20.835673394000054],
              [105.57884778700003, 20.837054553000108],
              [105.57971448500007, 20.837889016000055],
              [105.583623227, 20.840956669000029],
              [105.58404978200012, 20.841923787000027],
              [105.58404467600005, 20.842336204000041],
              [105.58344812399999, 20.84329212500009],
              [105.58325195500001, 20.846702729000107],
              [105.58350034700004, 20.85184145700012],
              [105.58323273100011, 20.854553261000049],
              [105.58320819800012, 20.856534001000071],
              [105.58330468900002, 20.859134693000044],
              [105.58120213600006, 20.860465517000115],
              [105.57942051700007, 20.860014310000082],
              [105.57832849700006, 20.860002338000072],
              [105.57754393000006, 20.860360588000049],
              [105.57636115200008, 20.861374792000028],
              [105.57486270300008, 20.862679005000118],
              [105.57399281200006, 20.863623259000086],
              [105.57229542700003, 20.868373640000115],
              [105.57165403900007, 20.869760606000028],
              [105.57109703800009, 20.870634909000074],
              [105.57031332900007, 20.870919767000103],
              [105.56922033500005, 20.870981110000031],
              [105.56753192700006, 20.871050752000109],
              [105.56528327500008, 20.873894720000052],
              [105.56193516300009, 20.876568288000016],
              [105.56035491900008, 20.877329601000035],
              [105.55952367000005, 20.87775045900004],
              [105.55839425900004, 20.878221148000087],
              [105.55785275300008, 20.878552038000109],
              [105.55752278400004, 20.879154772000064],
              [105.55751519400005, 20.879761062000043],
              [105.5576125310001, 20.880570638000059],
              [105.55783624300005, 20.881347722000051],
              [105.55406159900011, 20.881078107000036],
              [105.55077883300001, 20.881041730000049],
              [105.54754736900006, 20.88074132900006],
              [105.54643669600009, 20.880446776000028],
              [105.5451937720001, 20.879959734000053],
              [105.54374788800001, 20.87880622300009],
              [105.54301591000002, 20.87784765],
              [105.54181518600006, 20.879200361000102],
              [105.54117321200006, 20.880942363000088],
              [105.5411555790001, 20.882341489000034],
              [105.54152019400009, 20.882928591000024],
              [105.55173954300008, 20.88875593200008],
              [105.55752776300001, 20.891968428000048],
              [105.55813751000002, 20.89279142300002],
              [105.55835344600003, 20.894021336],
              [105.55877764300004, 20.895195286000053],
              [105.55968801800006, 20.898438],
              [105.560593254, 20.902093314000055],
              [105.56098910600005, 20.90553664200003],
              [105.56110272000004, 20.908151487000026],
              [105.56146155600007, 20.908705683000051],
              [105.56196840500009, 20.909123941000104],
              [105.56291153900005, 20.909753356000046],
              [105.56443726000008, 20.910595518],
              [105.56516098100009, 20.911222503000104],
              [105.56573326600002, 20.912260487000097],
              [105.57232909500006, 20.914364263],
              [105.57308154400006, 20.914604241000042],
              [105.57516019800001, 20.915610072000042],
              [105.57852930800006, 20.91751100300003],
              [105.57827268400005, 20.919192724000091],
              [105.57806912299999, 20.919991559000017],
              [105.57745237900004, 20.921235557],
              [105.57697056700005, 20.921614715000111],
              [105.57812907000007, 20.922114060000041],
              [105.57871266000004, 20.92212865],
              [105.57949982500007, 20.921891529000014],
              [105.58058274300005, 20.92099411],
              [105.58101936900003, 20.920916973000111],
              [105.58145092600003, 20.921249363000079],
              [105.58291171400002, 20.92290368200009],
              [105.58167877800005, 20.923955092000043],
              [105.57956849100006, 20.92548839300008],
              [105.57903557400002, 20.92630170700005],
              [105.57840331900012, 20.928096925000084],
              [105.57804058400004, 20.929239769000048],
              [105.57733543100004, 20.929887360000073],
              [105.57672147700005, 20.930208288000074],
              [105.57619871400004, 20.93020255400004],
              [105.57480875500006, 20.929859642000089],
              [105.57264587700007, 20.928607154000112],
              [105.57126002500007, 20.927936602000052],
              [105.56960667600005, 20.927754595000089],
              [105.56760278400007, 20.92773254300004],
              [105.56486838800004, 20.928112229000106],
              [105.55757545200005, 20.930041479000039],
              [105.55312644600009, 20.930774816000053],
              [105.53660332000004, 20.931765064],
              [105.53397247100006, 20.931344433000021],
              [105.52927727400007, 20.929596426000074],
              [105.52738621500001, 20.929047495000042],
              [105.52608563800004, 20.928702664000106],
              [105.52469811, 20.928712833000063],
              [105.52274709300004, 20.929335669000082],
              [105.52095738300001, 20.930087581000031],
              [105.51825926200006, 20.932389440000065],
              [105.51682948600006, 20.934307828000073],
              [105.51574737300005, 20.936513187000024],
              [105.51465201000011, 20.939756379000052],
              [105.51429417300008, 20.940384207000037],
              [105.51373758100003, 20.940508372000096],
              [105.5129085130001, 20.940238188000038],
              [105.51057670600007, 20.93812511800008],
              [105.50646649500007, 20.934035555000015],
              [105.50113274900009, 20.930378073],
              [105.49668673500003, 20.932503577000091],
              [105.49397844800002, 20.93431054400012],
              [105.49195641500006, 20.936562608000116],
              [105.48978166100005, 20.93961585400006],
              [105.48759649600001, 20.943471882000075],
              [105.48469949800003, 20.947319764000035],
              [105.48292954900003, 20.948943566000096],
              [105.48181792700008, 20.949963370000098],
              [105.47924001600003, 20.951138370000066],
              [105.47709779200007, 20.951649208000056],
              [105.474243793, 20.952151881000077],
              [105.47182724100007, 20.951856606000071],
              [105.47012767600005, 20.951168050000035],
              [105.46616960600002, 20.948981566000043],
              [105.46357636000005, 20.948266871000065],
              [105.46262300600003, 20.948004118000085],
              [105.46091479500009, 20.947984497000057],
              [105.45919961200011, 20.948500070000087],
              [105.45589236400011, 20.95100462100001],
              [105.45272389600005, 20.953778354000093],
              [105.45128985600006, 20.954564744000059],
              [105.44843223700005, 20.955334691000083],
              [105.44485755800005, 20.956497772000127],
              [105.44342346700006, 20.957284098000081],
              [105.44240765300003, 20.958744363000022],
              [105.44078903300004, 20.962740196000063],
              [105.43946736800011, 20.965802706000055],
              [105.4380191210001, 20.967659366000113],
              [105.43528602, 20.969768748000028],
              [105.43255110800001, 20.972011886000082],
              [105.4313927700001, 20.973470403000057],
              [105.42993733500002, 20.975862178000071],
              [105.42660100100002, 20.980506887000061],
              [105.42357401100003, 20.983281726000058],
              [105.42060626400006, 20.985810441000027],
              [105.41908731000007, 20.989285857000084],
              [105.41685231800005, 20.994262434000085],
              [105.41615132100006, 20.997645207000026],
              [105.41237718200006, 20.998043728000049],
              [105.40865581600008, 20.99800013400008],
              [105.40493096000009, 20.998218722000047],
              [105.4015700800001, 20.999053549000024],
              [105.39586111100007, 21.001521849000056],
              [105.39193022200007, 21.003224114000126],
              [105.39117540200006, 21.004002081000102],
              [105.39049722400009, 21.006004957000087],
              [105.39019354200009, 21.007837393],
              [105.39035504800003, 21.009984392000014],
              [105.39050951900003, 21.012037723000084],
              [105.39091733000002, 21.015382834000015],
              [105.38793766900004, 21.016652386000075],
              [105.38579109200006, 21.017931749000063],
              [105.38540681000006, 21.018970997000075],
              [105.38538697700002, 21.020449457000012],
              [105.38546555400005, 21.021494175000029],
              [105.38589224300009, 21.024195657000085],
              [105.38588757700006, 21.024543529000091],
              [105.38579034200009, 21.024890298000095],
              [105.38537026600007, 21.025146276000051],
              [105.38495485600006, 21.025054381000082],
              [105.38265805400002, 21.023722483000107],
              [105.379447267, 21.021509904000091],
              [105.37606311900004, 21.01842538500005],
              [105.3736797490001, 21.016657435000049],
              [105.37220802700001, 21.015944091000094],
              [105.36704300500006, 21.014490905000024],
              [105.36556897200003, 21.013951444000064],
              [105.36317634000004, 21.012879125000069],
              [105.36041817500009, 21.011454470000054],
              [105.354156743, 21.008944075],
              [105.3529557570001, 21.008755738000048],
              [105.3521214940001, 21.008832722000115],
              [105.35091223700005, 21.009253156000085],
              [105.34960331800002, 21.010194294000058],
              [105.34847478900008, 21.01148551200005],
              [105.3474305230001, 21.013386620000048],
              [105.34648946700004, 21.014506113000039],
              [105.34583793600004, 21.01475924200011],
              [105.33806701500002, 21.014317877000011],
              [105.33714371300002, 21.014132790000062],
              [105.33612019500006, 21.013433207000084],
              [105.334757209, 21.012025364000039],
              [105.33203001500003, 21.009302390000073],
              [105.33114541100011, 21.009013435000057],
              [105.32857887000003, 21.008982435000064],
              [105.32709563300006, 21.009150029000075],
              [105.32511119100009, 21.009868113000074],
              [105.32401771000012, 21.010411452000085],
              [105.32361394500002, 21.01105589200003],
              [105.32360248400005, 21.011890602000079],
              [105.32553604100006, 21.014882352000086],
              [105.32706081000002, 21.018889486000077],
              [105.3270391880001, 21.020466144000061],
              [105.32673285300007, 21.021204521000044],
              [105.32613291000007, 21.021753819000111],
              [105.32484319200003, 21.022202016000065],
              [105.32365728500005, 21.022280427000069],
              [105.32326367399999, 21.022182898],
              [105.32297389300008, 21.021715591000053],
              [105.32289555100009, 21.020230490000102],
              [105.32261086000007, 21.019392196000105],
              [105.3218351300001, 21.018362444000083],
              [105.32008244900003, 21.016578761000027],
              [105.31715286200001, 21.014224210000073],
              [105.31504306000006, 21.012524948000078],
              [105.31355661000001, 21.014500807000047],
              [105.30532980700011, 21.022032056000036],
              [105.30052172800001, 21.02706094600002],
              [105.29635056000004, 21.034959167000089],
              [105.29208627900007, 21.049533211000067],
              [105.29203326700004, 21.053348036000052],
              [105.29300005400009, 21.056857380000068],
              [105.29595798500006, 21.063252605000038],
              [105.30220851600004, 21.076364757000121],
              [105.31283869600003, 21.09111937200003],
              [105.31678274700003, 21.099751437000066],
              [105.31836165200011, 21.108036720000101],
              [105.31830476200003, 21.112169046000062],
              [105.31793119500003, 21.11470790400007],
              [105.314106194, 21.121973672000038],
              [105.30788003700007, 21.131435487000068],
              [105.29339416700002, 21.150333287000045],
              [105.28981999900006, 21.155372628000087],
              [105.28579382300009, 21.16104883400007],
              [105.28472914400001, 21.164532648000112],
              [105.28470698200005, 21.166121855000064],
              [105.28501903600002, 21.168033050000098],
              [105.286333789, 21.170910218000039],
              [105.28967946300006, 21.175367777000083],
              [105.29331751300008, 21.180214551000098],
              [105.29761442200007, 21.187896315000017],
              [105.3019601230001, 21.192081800000025],
              [105.30592416300007, 21.195016632000119],
              [105.31971223200004, 21.202277357000106],
              [105.32495829000004, 21.204320343000035],
              [105.33074083300004, 21.205709814000073],
              [105.33773813800008, 21.208268469000032],
              [105.33913535900004, 21.208945097000061],
              [105.33965143400012, 21.209776090000076],
              [105.33963790900006, 21.210765679000062],
              [105.34012920000004, 21.213410894000084],
              [105.34096757400003, 21.216390184000119],
              [105.34531508100012, 21.227732060000015],
              [105.34563643900006, 21.228570363000095],
              [105.34767636000001, 21.233708320000041],
              [105.35017868600002, 21.243635262000012],
              [105.351608388, 21.254868750000085],
              [105.35178504100006, 21.260604878000052],
              [105.35185231000003, 21.26278897600011],
              [105.35196465700008, 21.265748779000049],
              [105.35229348100003, 21.277290269000027],
              [105.35573151200001, 21.284640076000102],
              [105.36083811500002, 21.292425739000095],
              [105.37240045700004, 21.294564518000044],
              [105.38315256200008, 21.296552830000067],
              [105.40203409300003, 21.297588207000025],
              [105.42320319200007, 21.289907548000109],
              [105.43023512900007, 21.283687481000065],
              [105.43648378000006, 21.270952862000044],
              [105.43880303800003, 21.265815535000065],
              [105.44095729600011, 21.261043388000097],
              [105.44113370800005, 21.247628017000061],
              [105.44666218500006, 21.24229791100003],
              [105.44909103700003, 21.238782310000111],
              [105.4524947140001, 21.233855420000047],
              [105.45678679800005, 21.225787734000079],
              [105.45768025200007, 21.223177756000041],
              [105.46142927200003, 21.212225106000027],
              [105.46463509300007, 21.201787856000109],
              [105.46645574399999, 21.190548961000033],
              [105.46909614300006, 21.180628514000063],
              [105.46689486000008, 21.17824657600007],
              [105.467459655, 21.177729308000082],
              [105.46824585100003, 21.177108286000035],
              [105.47012764400004, 21.175619764000061],
              [105.471990546, 21.174150222000065],
              [105.47548087400004, 21.171392939],
              [105.47844946200007, 21.169047850000041],
              [105.47973230300008, 21.168034205000104],
              [105.48103809000003, 21.167002558000014],
              [105.48183707800007, 21.166371301000098],
              [105.4828111620001, 21.16591379500008],
              [105.48351767300002, 21.165583391],
              [105.49166183300011, 21.161769240000083],
              [105.49782571700003, 21.160186772000067],
              [105.50005146600006, 21.159615301000123],
              [105.500706576, 21.159501318000103],
              [105.50358132300001, 21.159013654000077],
              [105.50469888600001, 21.158824063000026],
              [105.50647888500004, 21.158522079000086],
              [105.50823270000011, 21.158224522000069],
              [105.51019779500007, 21.157891101000104],
              [105.51153540100007, 21.157664136000065],
              [105.51214113000002, 21.157561353000084],
              [105.51249649200003, 21.157379840000075],
              [105.51465698300008, 21.157134433000024],
              [105.51499510800008, 21.157111488000076],
              [105.51983473500012, 21.15678300900008],
              [105.52838359000002, 21.156202512],
              [105.5391287260001, 21.155558787000039],
              [105.53940023500009, 21.15553983500007],
              [105.53975457600009, 21.155501821000122],
              [105.54106439600011, 21.155367553000112],
              [105.54187269300012, 21.155284691000098],
              [105.54272339600007, 21.155197478000076],
              [105.54384316400008, 21.155082677000081],
              [105.54475739000006, 21.154988943000049],
              [105.54532153900001, 21.154889532000084],
              [105.549726773, 21.15411321400007],
              [105.55220952000008, 21.153675646000103],
              [105.55684129700006, 21.153261553000036],
              [105.55991446100005, 21.153615127000066],
              [105.57219059000006, 21.157694614],
              [105.57210033600005, 21.158691866000098],
              [105.57170318500005, 21.163079996000086],
              [105.58524078100008, 21.167172522000044],
              [105.5909636750001, 21.168880393000045],
              [105.600693295, 21.171786554000072],
              [105.61068194500011, 21.175713396000084],
              [105.61392741400005, 21.176512164000094],
              [105.61541765700008, 21.176655520000054],
              [105.61731942700004, 21.176421420000047],
              [105.61963427700009, 21.175682575000017],
              [105.62304025800007, 21.174446252000045],
              [105.62544112800003, 21.173837036000087],
              [105.6252346800001, 21.176455292000092],
              [105.62521321800001, 21.178214907000026],
              [105.62534260000007, 21.179434648000047],
              [105.62673544900011, 21.183510756000068],
              [105.62701073000005, 21.184596674000119],
              [105.62699753200003, 21.185679506],
              [105.62639417500003, 21.187838968000072],
              [105.62330396500005, 21.192814497000079],
              [105.62169908100005, 21.194286327],
              [105.61951718900008, 21.195751929000025],
              [105.61811027400002, 21.19669694400006],
              [105.61745118600003, 21.19803833600011],
              [105.61507781000003, 21.202956685000096],
              [105.61467756100004, 21.204158195000034],
              [105.61479423200009, 21.205124113000103],
              [105.61554748000007, 21.206579215000062],
              [105.61731837600004, 21.208889332000069],
              [105.61844564500007, 21.210189222000061],
              [105.6196269480001, 21.210372766000063],
              [105.62394807300005, 21.210960646000032],
              [105.62611195700003, 21.210983861000088],
              [105.628862762, 21.210201154000075],
              [105.63219715700011, 21.208883186],
              [105.63523477000008, 21.20823882600007],
              [105.63686025400003, 21.208529370000036],
              [105.63725164100008, 21.208909814000073],
              [105.63739593300002, 21.209835554000023],
              [105.63719234200005, 21.21086073100011],
              [105.63545066100001, 21.213758620000078],
              [105.63460726800012, 21.215357721000046],
              [105.63456071100009, 21.216218153000099],
              [105.63518951000007, 21.217344590000039],
              [105.63546178000004, 21.217338585000078],
              [105.63714100200006, 21.217599348000071],
              [105.64072267300003, 21.217383154000032],
              [105.64218264500003, 21.216988281000049],
              [105.64545177000008, 21.217095020000087],
              [105.64840967200003, 21.217945490000112],
              [105.65222428800008, 21.219368640000091],
              [105.65292892200003, 21.219631514000078],
              [105.65518463000006, 21.220802051000121],
              [105.65629932300001, 21.221587101000061],
              [105.65690676600009, 21.222785949000041],
              [105.65636724600003, 21.224090675000085],
              [105.65459181800004, 21.226528941000119],
              [105.65264965600002, 21.228310197000063],
              [105.65160214000012, 21.228299089000032],
              [105.64882063400002, 21.227286745000029],
              [105.64707478100007, 21.227268198],
              [105.64462463900003, 21.22773355700005],
              [105.64321407700002, 21.228865167000066],
              [105.64228558800002, 21.233441757000016],
              [105.64251852200003, 21.235749226000067],
              [105.64416247500007, 21.237065367000071],
              [105.64902934100006, 21.238918904000045],
              [105.6502515120001, 21.238931877000056],
              [105.65201130100004, 21.237803946000078],
              [105.65360239700007, 21.236182807000056],
              [105.65624567900002, 21.235784896],
              [105.65976115700001, 21.236152272000105],
              [105.66197163100003, 21.237716514000034],
              [105.66316571000006, 21.239559951000061],
              [105.6642073900001, 21.240062343000062],
              [105.66508037000003, 21.240071551000113],
              [105.66579054500008, 21.239096236000066],
              [105.66759146500006, 21.23452879000007],
              [105.66848208700003, 21.233063962000109],
              [105.66979223000007, 21.231305195000068],
              [105.67121709900002, 21.229889355000076],
              [105.67254859600004, 21.228847880000053],
              [105.67405098500009, 21.228378235],
              [105.67557338500004, 21.228614354000094],
              [105.67707026200007, 21.230369594000109],
              [105.67761783600008, 21.232099504000068],
              [105.67819584100003, 21.233788979000053],
              [105.67936328700009, 21.235478081000011],
              [105.68175770100004, 21.23607083500012],
              [105.68575395600003, 21.235364461000117],
              [105.686396609, 21.235315800000087],
              [105.68632302800003, 21.234642227000045],
              [105.68623937600005, 21.23404545500005],
              [105.68579279600004, 21.233444889000097],
              [105.68444092000001, 21.232664595000102],
              [105.68363547400007, 21.231719757000086],
              [105.68328065400001, 21.231035017000096],
              [105.68328785500009, 21.230430072000047],
              [105.68329787800012, 21.229587997000095],
              [105.68405613200005, 21.226871797000065],
              [105.68784076300003, 21.221463035000099],
              [105.69371084700006, 21.216161033000077],
              [105.69527332700002, 21.21447468200008],
              [105.69618963300007, 21.213718044000096],
              [105.69655252500009, 21.21372182100005],
              [105.6970001, 21.214237258000068],
              [105.69708276900008, 21.214919173000091],
              [105.69451626600009, 21.224767597000046],
              [105.69627906200002, 21.225866882000055],
              [105.70046532800009, 21.220508641],
              [105.70253468700007, 21.22464778500008],
              [105.70313482700007, 21.22740512200005],
              [105.70666289300004, 21.225830183000127],
              [105.70829903100007, 21.225591735000116],
              [105.714553354, 21.226167156000116],
              [105.71527718800003, 21.226344889000082],
              [105.71563510900008, 21.226774234000075],
              [105.71661716000006, 21.228146425000105],
              [105.71783017400006, 21.230421216000117],
              [105.72192460900008, 21.226100763000133],
              [105.72504514500008, 21.229084475],
              [105.73052152300005, 21.23408170900003],
              [105.73095797700002, 21.235614917000056],
              [105.73149146900002, 21.236556797],
              [105.7326077060001, 21.237127258000044],
              [105.73335094200004, 21.23686780200007],
              [105.73461615800009, 21.236880751000072],
              [105.73549628500008, 21.237364551000049],
              [105.73548523700006, 21.238314031000094],
              [105.73444957400008, 21.240321311000088],
              [105.73226824500007, 21.242910345000077],
              [105.73099883800002, 21.243253430000038],
              [105.72897581900006, 21.243113997000052],
              [105.72607682200001, 21.242134659000023],
              [105.72455852800006, 21.242119062000064],
              [105.72353661500006, 21.242939441000082],
              [105.72352273200006, 21.244126278000053],
              [105.726197099, 21.246880158000089],
              [105.72658304700005, 21.248398775000062],
              [105.72656977800003, 21.249534631000074],
              [105.72614401300005, 21.251423571000046],
              [105.72189213000006, 21.262441493000047],
              [105.72163516300003, 21.262788047000036],
              [105.7213162080001, 21.263540005000081],
              [105.72112535400004, 21.265027047000018],
              [105.72155815800005, 21.267554995000104],
              [105.72447731900009, 21.26929161],
              [105.72598064300004, 21.270527147000081],
              [105.72940197600006, 21.274629207000118],
              [105.73167578600008, 21.277378312000025],
              [105.73344541300004, 21.280530318000054],
              [105.73351402300003, 21.282394400000015],
              [105.73313897, 21.283576350000111],
              [105.73303484000009, 21.284761069000076],
              [105.73351517200004, 21.28616896000004],
              [105.734180275, 21.287229047],
              [105.73534051600008, 21.288426691000062],
              [105.73588042100008, 21.288601606000057],
              [105.73712005000006, 21.288520218000073],
              [105.73903061600004, 21.288408722000085],
              [105.73967450900003, 21.288555663000075],
              [105.740004877, 21.288724340000087],
              [105.74039110000007, 21.28907116],
              [105.74350389900006, 21.293253090000071],
              [105.74412529600005, 21.294191090000055],
              [105.74445256000004, 21.294708471000057],
              [105.74574405600005, 21.293869631000106],
              [105.74618185900003, 21.294175064000065],
              [105.74709492900008, 21.294848574000099],
              [105.75338043600003, 21.300067471000084],
              [105.75960475700006, 21.30439056500002],
              [105.75873590700003, 21.307437748000076],
              [105.75849702400008, 21.30849799800005],
              [105.75870162800004, 21.310412846000034],
              [105.75928643300003, 21.312119020000075],
              [105.76002315900004, 21.313755864000058],
              [105.76083871100012, 21.315110135000026],
              [105.76315225900005, 21.317613012000081],
              [105.76509623100004, 21.319403698000094],
              [105.76696225400008, 21.321406113000037],
              [105.76911346900008, 21.324899050000035],
              [105.76985601100006, 21.326039999000024],
              [105.77043608900009, 21.328171081000043],
              [105.77086587400004, 21.330229811000031],
              [105.77163771000004, 21.332114874000041],
              [105.77459352000008, 21.337953519000052],
              [105.77503633600004, 21.338878891000057],
              [105.77900296000006, 21.34224816900003],
              [105.78095460800006, 21.343401152000041],
              [105.7822334900001, 21.343909833000026],
              [105.78428992700007, 21.344377382000076],
              [105.78637888400007, 21.344872200000076],
              [105.78765778900002, 21.345380848000062],
              [105.78832818500007, 21.346237617000071],
              [105.78854520500008, 21.347089849000064],
              [105.7883844080001, 21.347938317000015],
              [105.78744865000004, 21.350479170000099],
              [105.78651850000008, 21.352524205000023],
              [105.78473936000005, 21.356119178000107],
              [105.78410578400012, 21.358663002000085],
              [105.78297838800009, 21.360337701],
              [105.78313460500004, 21.360819527000082],
              [105.78408814300008, 21.366483299000016],
              [105.78446083600009, 21.368994467000043],
              [105.78502755900008, 21.371095489000069],
              [105.78673514200007, 21.37484697000005],
              [105.78890976900004, 21.378396912000049],
              [105.79250161000006, 21.38163621400011],
              [105.79832718200005, 21.385974320000045],
              [105.79880485900006, 21.385984408000077],
              [105.80092164500005, 21.385980300000092],
              [105.81111548900007, 21.384475448000018],
              [105.81304146000009, 21.383954651000089],
              [105.81728544700005, 21.382197120000065],
              [105.82191936600003, 21.379903522000099],
              [105.82965304500009, 21.375121054000076],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 25, code: "AD01", ten_tinh: "Hà Tĩnh" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.45277791300006, 18.100608451000078],
              [106.45134261800006, 18.100488285000083],
              [106.45053451500007, 18.100977215000036],
              [106.45058895400004, 18.101362368000103],
              [106.45075572100008, 18.102078117000083],
              [106.45229349200004, 18.103793045000046],
              [106.45560874200007, 18.105960327000048],
              [106.45646490300004, 18.106680982000078],
              [106.45840789000005, 18.108013969000055],
              [106.46047005200005, 18.108798128000011],
              [106.46092963900006, 18.108801387000106],
              [106.4617352020001, 18.108642196000076],
              [106.46219989700009, 18.107985894000045],
              [106.46267310300007, 18.106230341000092],
              [106.46274587700006, 18.104252078000101],
              [106.46269523600003, 18.103372261000047],
              [106.462411403, 18.102930516000093],
              [106.46126758300004, 18.102262823000089],
              [106.45736591700006, 18.101630496000084],
              [106.45404002000004, 18.100837315000092],
              [106.45277791300006, 18.100608451000078],
            ],
          ],
          [
            [
              [105.75684703300007, 18.760324929000127],
              [105.75913938300002, 18.758173729000035],
              [105.76241223800011, 18.759767148000073],
              [105.76780374600007, 18.760019181000054],
              [105.76930867300007, 18.759981479000132],
              [105.77118157300004, 18.759709965000049],
              [105.77260307600005, 18.759377828000098],
              [105.77416910600004, 18.758862722000025],
              [105.7759892660001, 18.757714523000054],
              [105.77747499300003, 18.754730626000082],
              [105.77848575500008, 18.750934426000043],
              [105.78009109900003, 18.742855309000014],
              [105.78230031700012, 18.734895333000075],
              [105.78424272000008, 18.727084131000105],
              [105.78658469800006, 18.716700245000069],
              [105.78802786100009, 18.710854211000068],
              [105.79010962500003, 18.700012780000108],
              [105.79125273400011, 18.692546818000011],
              [105.79370890900003, 18.681355298000121],
              [105.79752119200006, 18.667297411000035],
              [105.79847682800009, 18.66410236100009],
              [105.80005231200006, 18.658834715000069],
              [105.80645651700006, 18.64025518300005],
              [105.80950199300007, 18.633111051000114],
              [105.81826584700008, 18.611724932000051],
              [105.82882596700004, 18.590558106000096],
              [105.83537407600005, 18.577738426000053],
              [105.83640696800006, 18.576054647000092],
              [105.84141232900005, 18.567894610000032],
              [105.84849049700003, 18.554877431000079],
              [105.85247183400003, 18.549055221000096],
              [105.85494982200007, 18.545138278000103],
              [105.85576417400003, 18.543276664000082],
              [105.85578168300006, 18.54165985700007],
              [105.85622707200005, 18.539643009000081],
              [105.85847564000002, 18.537340722000103],
              [105.86125570300001, 18.534841508000085],
              [105.86275649600002, 18.533138191000106],
              [105.86576562000005, 18.529024156000062],
              [105.87017592799999, 18.522599340000042],
              [105.87808231800003, 18.51100371700003],
              [105.88109403900009, 18.506586230000011],
              [105.88303192000002, 18.503573153000019],
              [105.88588193000002, 18.499406663000073],
              [105.89081911200006, 18.493087461000087],
              [105.89317611800003, 18.490003880000039],
              [105.8945765930001, 18.488171624000046],
              [105.89769237200005, 18.483855901000098],
              [105.90460775900007, 18.475584607000073],
              [105.90868208600003, 18.470671511000077],
              [105.91537956200004, 18.462903025000088],
              [105.91602946200008, 18.461494302000055],
              [105.91604115600008, 18.460382657000096],
              [105.91563164400003, 18.459064848000125],
              [105.91257840900009, 18.457519537000074],
              [105.91036442300005, 18.456689734000072],
              [105.91485668700004, 18.454826461],
              [105.91947246600003, 18.452911821000036],
              [105.92515233400005, 18.450555604000037],
              [105.92650092900007, 18.452082140000044],
              [105.92774870300011, 18.454216480000071],
              [105.92969087000004, 18.460804436000089],
              [105.9309302890001, 18.463747204000036],
              [105.93354881000005, 18.466399859000134],
              [105.93689144400004, 18.470676429000044],
              [105.93803885900012, 18.47230438800009],
              [105.9407394040001, 18.472178367000055],
              [105.94117012700012, 18.471474966000031],
              [105.94150235200007, 18.470063172000017],
              [105.94119848600006, 18.468746414000044],
              [105.93951765600008, 18.467517693000076],
              [105.9378347520001, 18.466491064000046],
              [105.93678583800009, 18.465571511000078],
              [105.93679952100007, 18.464257754000087],
              [105.93896779100007, 18.459325966000058],
              [105.94317090200011, 18.452088803000066],
              [105.94667064300003, 18.446310391000061],
              [105.94937282800007, 18.4408781120001],
              [105.9530304, 18.435151569000126],
              [105.95701146800006, 18.428821546000052],
              [105.96264748900009, 18.421041397000067],
              [105.97740796300005, 18.400509882000016],
              [105.98373367300003, 18.392381666000098],
              [105.98834768300006, 18.386056738],
              [105.99321410700001, 18.380896368000066],
              [105.99749689500007, 18.375882107000052],
              [105.99942561700006, 18.373474056000113],
              [106.01671135900006, 18.353568818],
              [106.01842926900008, 18.351676365000102],
              [106.02280829600002, 18.346852182000092],
              [106.02489720100002, 18.344527267000018],
              [106.026231346, 18.343042345000079],
              [106.04307373400009, 18.32464672400009],
              [106.0467085220001, 18.320737330000014],
              [106.05146209600004, 18.315978713000014],
              [106.05364226800003, 18.313434920000034],
              [106.06327896000006, 18.304073785000071],
              [106.06621512200009, 18.301509751000111],
              [106.07934431200007, 18.290043184000112],
              [106.08903128400007, 18.282254532000053],
              [106.09621602500009, 18.277593141000104],
              [106.10283118400005, 18.275026288000085],
              [106.11138322200003, 18.270901252000016],
              [106.11353476200006, 18.268041552000099],
              [106.11331785500005, 18.265277636000029],
              [106.11165819200005, 18.263376492000113],
              [106.10752699600009, 18.261925498000053],
              [106.10506354100001, 18.261526682000095],
              [106.10357970800007, 18.261891082000133],
              [106.10327644400012, 18.262643127000011],
              [106.10336880900003, 18.26330429300009],
              [106.10649964900009, 18.266161697000079],
              [106.10658587400012, 18.26743254900007],
              [106.10577547400003, 18.268532851000074],
              [106.10389392300004, 18.269149210000087],
              [106.10064240800004, 18.268804432000074],
              [106.09847413100005, 18.267678112000048],
              [106.089785986, 18.259506163000069],
              [106.0846078120001, 18.254083355000091],
              [106.082459687, 18.251705962000116],
              [106.08156295000005, 18.250179739000046],
              [106.08116017500004, 18.249604021000017],
              [106.08318485200004, 18.248302826],
              [106.08546279300005, 18.247298569000044],
              [106.088423957, 18.247230310000084],
              [106.09038837300001, 18.248190962000074],
              [106.09204771400007, 18.250092299000094],
              [106.09291585000005, 18.252175343000054],
              [106.09398588700004, 18.253788479000029],
              [106.09486493300003, 18.254739560000118],
              [106.09585081300007, 18.254842535000115],
              [106.09713454100006, 18.254759449000076],
              [106.09942766200008, 18.252326745000055],
              [106.10083172400007, 18.249980585000088],
              [106.1008470780001, 18.248376978000103],
              [106.09958418000006, 18.246290498000036],
              [106.09959864100001, 18.244781212000035],
              [106.10000508000007, 18.243558370000109],
              [106.10129687000001, 18.242626284000082],
              [106.10445959800005, 18.24184682200007],
              [106.10442441300006, 18.243948406000051],
              [106.10452036000001, 18.245452096000037],
              [106.10566024700009, 18.247386727000055],
              [106.10748140200005, 18.25049442400006],
              [106.10869796200008, 18.252973664000116],
              [106.10936263000009, 18.255715237000025],
              [106.11023816200006, 18.257043570000064],
              [106.11131735900008, 18.257713316000057],
              [106.11269977700007, 18.257630988000081],
              [106.11606566100004, 18.256528157000048],
              [106.11793699500006, 18.256921722000108],
              [106.11891574100004, 18.257779233000093],
              [106.12067411500004, 18.259681192000073],
              [106.12125190700007, 18.261195574000055],
              [106.12161893800007, 18.264123189000081],
              [106.12180112200004, 18.265728484000121],
              [106.12248476700003, 18.266489082000078],
              [106.12346804300003, 18.26687492000012],
              [106.12671930400009, 18.267468990000069],
              [106.13214877500009, 18.267327059000046],
              [106.13669361600007, 18.266800065000055],
              [106.141232234, 18.266933269000063],
              [106.14497961800008, 18.267248306000013],
              [106.14980903800007, 18.267949838000064],
              [106.15227266300008, 18.268348145000068],
              [106.15444287200006, 18.268460920000074],
              [106.15743318400003, 18.268235882000013],
              [106.15767621200007, 18.268127305000093],
              [106.15978417000004, 18.267185519000058],
              [106.16216659600008, 18.265696299000034],
              [106.170399188, 18.261237624000067],
              [106.17922608900008, 18.256500600000081],
              [106.18448721600001, 18.253148485000011],
              [106.18787330800005, 18.249780614000066],
              [106.18996789300007, 18.247345299000095],
              [106.19543796200006, 18.242673914000115],
              [106.20100729600007, 18.23790886500003],
              [106.20755366900002, 18.234189446000038],
              [106.21171988500004, 18.231770978000036],
              [106.21519702300006, 18.22915808900003],
              [106.219172481, 18.225983194000058],
              [106.22432923200009, 18.223100890000055],
              [106.22859535500001, 18.220494220000113],
              [106.23127942600004, 18.218251951000042],
              [106.23259890500006, 18.217582791],
              [106.23378619700007, 18.217215090000046],
              [106.23461726000008, 18.217255687000119],
              [106.23651266400006, 18.217822799000061],
              [106.23877767700006, 18.218312875000017],
              [106.2408520760001, 18.218046662000106],
              [106.24530100200006, 18.217044909],
              [106.24768052600004, 18.215743368000048],
              [106.25116273100011, 18.212469537000061],
              [106.25762855400006, 18.206483735000042],
              [106.26649791600002, 18.19636592000009],
              [106.27825492200006, 18.183062936000027],
              [106.287185263, 18.173434093000118],
              [106.29542562800003, 18.164968107000057],
              [106.30257844900007, 18.157932206000012],
              [106.30520174200005, 18.155897054000022],
              [106.30752950000002, 18.153859564000108],
              [106.3095894530001, 18.151717185000038],
              [106.31251812600003, 18.148502321000045],
              [106.32020132000008, 18.142137975000061],
              [106.33227123299999, 18.131541551000026],
              [106.34535447200008, 18.121675015000079],
              [106.3529268680001, 18.115462208000018],
              [106.35412309300004, 18.113723798000066],
              [106.35467471900003, 18.111980486000064],
              [106.35438736000006, 18.111036866000063],
              [106.353399225, 18.110326118000053],
              [106.35232494800003, 18.110317967000086],
              [106.34952156700007, 18.111530196000025],
              [106.34476986200011, 18.114475033000055],
              [106.34163808000007, 18.116404201000066],
              [106.33883627700006, 18.117410721000034],
              [106.33625791800006, 18.117390977000092],
              [106.33239643400005, 18.116641793],
              [106.32746759300008, 18.115061973000124],
              [106.32297462900003, 18.11276585500012],
              [106.31997706099999, 18.111509155000057],
              [106.31675770099999, 18.111073042000037],
              [106.31439384500007, 18.11110640800004],
              [106.31277631500008, 18.111813403],
              [106.31115351700001, 18.113137116000111],
              [106.30895801300008, 18.114693138000071],
              [106.30737422300004, 18.115369167000047],
              [106.30562718800009, 18.115157420000024],
              [106.30510455700001, 18.11435726100008],
              [106.30530650600011, 18.112121766000122],
              [106.30623458800007, 18.110426130000064],
              [106.30796832000007, 18.108692140000038],
              [106.31142608400009, 18.106354778000089],
              [106.31444446700007, 18.105144687000063],
              [106.31745322900007, 18.105065239000062],
              [106.32109958900006, 18.105813033000025],
              [106.32516093000001, 18.108311488000069],
              [106.32837247300007, 18.109672576000065],
              [106.33041273500004, 18.109791066000099],
              [106.33267647000012, 18.108883315000043],
              [106.33505624300003, 18.106948473],
              [106.33992909100006, 18.102360005000051],
              [106.34145019700006, 18.100315726000105],
              [106.34167705500001, 18.098878327000101],
              [106.34063548900006, 18.094964124000064],
              [106.33948138700006, 18.0916658310001],
              [106.33842312700007, 18.089756002000087],
              [106.33746670900007, 18.08851512600009],
              [106.33607546900006, 18.087887684000044],
              [106.33435682500007, 18.087874503000108],
              [106.33069691700011, 18.088771559000079],
              [106.32768927200004, 18.088748409000033],
              [106.32243197300008, 18.08798826000011],
              [106.31883678300005, 18.087626412000098],
              [106.31728473500007, 18.086920454000044],
              [106.31718416900002, 18.086295994000068],
              [106.31776532500004, 18.085422093000055],
              [106.31912540400005, 18.084032663000116],
              [106.32236885500001, 18.082745144000079],
              [106.32634745300004, 18.082261874000061],
              [106.32936018900008, 18.081668299000064],
              [106.33130225400001, 18.080655270000086],
              [106.33314291900008, 18.078921867000105],
              [106.334667364, 18.076466433000071],
              [106.33589365700004, 18.071130368000077],
              [106.3363495560001, 18.067998532000026],
              [106.33636678100001, 18.065942697000104],
              [106.33584095700012, 18.064602299000043],
              [106.33499294100002, 18.06325941700004],
              [106.33349966000002, 18.062014370000057],
              [106.33040489700002, 18.059626220000077],
              [106.32869861100006, 18.058173902000085],
              [106.32754232600004, 18.056792337000083],
              [106.32640950300002, 18.054914662000066],
              [106.32917067600005, 18.054386250000043],
              [106.33055723800001, 18.054281855000021],
              [106.33194995100004, 18.054703765000021],
              [106.33493639500006, 18.057193864000084],
              [106.33803633600004, 18.058965208000096],
              [106.34049279100007, 18.060628783000077],
              [106.34155477900001, 18.0620760730001],
              [106.3419680810001, 18.064032402000052],
              [106.34226711100007, 18.06681023000003],
              [106.34192688000007, 18.068966388000085],
              [106.34027197500009, 18.074196425000096],
              [106.33949605100003, 18.077068815000082],
              [106.33958455000007, 18.079331024000034],
              [106.34010527099998, 18.081288153000052],
              [106.34094824500001, 18.083247741],
              [106.34221832100012, 18.085518968000081],
              [106.34466057000003, 18.088929888000038],
              [106.34645753300003, 18.092438656000098],
              [106.34815440800001, 18.095072870000095],
              [106.34953719100012, 18.096728123000091],
              [106.35199250300006, 18.098597098000099],
              [106.35444871300007, 18.100363251000125],
              [106.35582902100006, 18.102326825000056],
              [106.35795198300012, 18.105426754000042],
              [106.35944319600004, 18.10697995100012],
              [106.36104270900006, 18.108431155000027],
              [106.36156014000005, 18.108652431000039],
              [106.36275559800002, 18.109163650000056],
              [106.36511899700008, 18.10918146100002],
              [106.36781143200001, 18.108379363],
              [106.37191304500001, 18.106045882000018],
              [106.37579381500004, 18.104430231],
              [106.38010254300011, 18.103023291000042],
              [106.38570488600001, 18.101060523000086],
              [106.39097943500008, 18.099763344000053],
              [106.39743223400009, 18.098885915000018],
              [106.40065571300009, 18.098806890000048],
              [106.40280083900004, 18.09923387000012],
              [106.404618758, 18.100275190000033],
              [106.40568225100006, 18.101619341000053],
              [106.40587976600006, 18.103779489000082],
              [106.40552281500001, 18.108094242000035],
              [106.40473944200002, 18.111994684000088],
              [106.40472374700006, 18.113947654000114],
              [106.40630053000005, 18.118276586],
              [106.410555022, 18.123653046000051],
              [106.41515011600006, 18.126770443000062],
              [106.4172922970001, 18.127608406000029],
              [106.41879560400005, 18.127722157000022],
              [106.42030791200004, 18.126705235000081],
              [106.42160940700009, 18.125172805000119],
              [106.42344438900003, 18.124106807000118],
              [106.42699302000001, 18.123721378000113],
              [106.43171530300003, 18.124372294000054],
              [106.43287844700008, 18.124100445000032],
              [106.43352792300009, 18.123488369000107],
              [106.43366617600006, 18.122227716000069],
              [106.4326178040001, 18.118930761000037],
              [106.43102743300005, 18.116246633000088],
              [106.42666185800006, 18.111280916000091],
              [106.42215805000009, 18.106722596000054],
              [106.42131335700002, 18.104866150000106],
              [106.42060590700001, 18.10275638300007],
              [106.42070505900004, 18.098020099000053],
              [106.42035560900008, 18.093708572000097],
              [106.42026454700002, 18.091651997000021],
              [106.4208163590001, 18.089805686000112],
              [106.42255871000003, 18.086837267000085],
              [106.42559975700001, 18.082644698000117],
              [106.42866264000006, 18.075676721],
              [106.43103866000008, 18.068469187000069],
              [106.43383492900008, 18.059986076000101],
              [106.43985908700007, 18.045123656000058],
              [106.445426248, 18.033650047000016],
              [106.45609118400003, 18.015581868000119],
              [106.46185142100003, 18.006679040000058],
              [106.46641383100005, 17.999874994000017],
              [106.46860872000011, 17.997960533000068],
              [106.46997540100004, 17.996943066000078],
              [106.47127265200008, 17.995651217000038],
              [106.473441205, 17.992653641000089],
              [106.47532416800001, 17.989585560000073],
              [106.47698519700005, 17.987474534000022],
              [106.48052481300009, 17.982843121000066],
              [106.48602156400005, 17.974733101000062],
              [106.48768574300009, 17.972176908000037],
              [106.48891851200003, 17.96992583600003],
              [106.48957818200007, 17.967807709000013],
              [106.49030361900006, 17.966443258000098],
              [106.49124043800003, 17.965491126000096],
              [106.49296168300008, 17.964818340000122],
              [106.49553576600007, 17.96483620900009],
              [106.49879062200009, 17.965387976000088],
              [106.50078508700008, 17.965465447000014],
              [106.5029149600001, 17.965225430000054],
              [106.50518452300003, 17.964094791000029],
              [106.50758944900006, 17.962646643000113],
              [106.50826111200004, 17.961759707000091],
              [106.5084594410001, 17.961022588000027],
              [106.50668900800008, 17.961135083],
              [106.50233140200004, 17.961938267000029],
              [106.49836545800008, 17.962096006000117],
              [106.49392304300004, 17.961324561000055],
              [106.48977426200005, 17.96009217700005],
              [106.4830324430001, 17.956527077000125],
              [106.47879211, 17.954645773000074],
              [106.47666416100004, 17.954723422000107],
              [106.47307727900009, 17.95590174400008],
              [106.46804209500004, 17.95679204600011],
              [106.46098846900004, 17.956094048000089],
              [106.45664446200006, 17.955137373000071],
              [106.45134944600008, 17.952137051000072],
              [106.44826328300009, 17.95109660600005],
              [106.44496999000009, 17.951813662000049],
              [106.44282827299999, 17.953649917000099],
              [106.44221637900007, 17.958437254000081],
              [106.44249227700007, 17.966171718000098],
              [106.44168516600008, 17.968958199000085],
              [106.43942649800007, 17.970875067000058],
              [106.43548598300001, 17.972672400000086],
              [106.43301556600005, 17.972976752000044],
              [106.42976584400003, 17.972523674000037],
              [106.42573771000006, 17.971205732000058],
              [106.42248719100006, 17.97085993300005],
              [106.42035073800012, 17.971488763000117],
              [106.41798308100007, 17.972975034000079],
              [106.41584231000004, 17.974140751000078],
              [106.41314748200008, 17.974443238000056],
              [106.41000995800002, 17.973990685],
              [106.40675165900008, 17.974611145],
              [106.403930841, 17.976630913000058],
              [106.40008650100006, 17.980361402000078],
              [106.39693332400003, 17.981841641000074],
              [106.39345048500007, 17.982460240000094],
              [106.38738483700011, 17.983381789000049],
              [106.38266619700005, 17.984205813000109],
              [106.37715170600003, 17.986312507000065],
              [106.37071153400007, 17.988576009000035],
              [106.36264633000005, 17.990644024000076],
              [106.35745345400008, 17.991111607000043],
              [106.35205718800009, 17.990563844000107],
              [106.34390355000002, 17.990501753000032],
              [106.33829640400006, 17.98985066300007],
              [106.33397358000006, 17.987587410000039],
              [106.32977974800008, 17.982588103000104],
              [106.32705912200004, 17.978715125000107],
              [106.3250063830001, 17.970995216000048],
              [106.32293497000003, 17.965505246000113],
              [106.32019671500005, 17.963760769000103],
              [106.31638600800005, 17.963629847000014],
              [106.30918379100011, 17.963877937000028],
              [106.30411381000002, 17.962419178000033],
              [106.30053830100012, 17.959552822000099],
              [106.29677113499999, 17.954353374000043],
              [106.29522654500009, 17.949272681000124],
              [106.29388071000002, 17.945714107000029],
              [106.29135122700008, 17.9443763660001],
              [106.28699072900007, 17.943959915000015],
              [106.28542466100006, 17.943598127000101],
              [106.283875912, 17.942461033000079],
              [106.2807888880001, 17.937946795000045],
              [106.27919093900006, 17.934761332000093],
              [106.27951958400007, 17.93290815000006],
              [106.28216226700006, 17.930953580000072],
              [106.28405836000009, 17.928574019000095],
              [106.28482814900011, 17.92630526800005],
              [106.28425574500008, 17.92019455000009],
              [106.27797383200007, 17.916493009000064],
              [106.27539121299999, 17.918747342000074],
              [106.27288040200008, 17.919924659000053],
              [106.27093997600011, 17.920208514000088],
              [106.26838017900005, 17.91982890600012],
              [106.26452492600001, 17.917463358000063],
              [106.26247415500006, 17.916070042000065],
              [106.26068762900005, 17.916129199000075],
              [106.25846197000001, 17.917248103000105],
              [106.25352445900003, 17.92077026500003],
              [106.24612220700006, 17.925560721000089],
              [106.24126407300008, 17.929007440000078],
              [106.23872295600006, 17.929972014000036],
              [106.23579418700004, 17.930023989000077],
              [106.23231281200002, 17.929919859000073],
              [106.23055662300001, 17.930124829000071],
              [106.2285899820001, 17.930268395000049],
              [106.22288596900003, 17.930903784000037],
              [106.2172746680001, 17.930024111000087],
              [106.21175141300006, 17.928159811000022],
              [106.20707675900009, 17.926343348],
              [106.20461942800007, 17.92450396900005],
              [106.20243674400004, 17.921609241000077],
              [106.20077654300007, 17.91956490000004],
              [106.198845588, 17.918152848000091],
              [106.1969960910001, 17.91750291500005],
              [106.19517964400008, 17.918080062000016],
              [106.19380068800002, 17.919126169000066],
              [106.19286036300012, 17.92051435700008],
              [106.1919134820001, 17.922621647000092],
              [106.19071155600005, 17.923626911000042],
              [106.18902987200008, 17.923951298000063],
              [106.18647164700008, 17.923506885000059],
              [106.18294509500005, 17.922673601000035],
              [106.17863042900009, 17.921072163000048],
              [106.17603935400003, 17.91940053900003],
              [106.17359050900009, 17.916878923000112],
              [106.1713085260001, 17.915655403000038],
              [106.16716691500008, 17.915549596000076],
              [106.16020399000004, 17.916553246000078],
              [106.153530946, 17.918196705000078],
              [106.14641463000007, 17.919765328000011],
              [106.13826260100004, 17.921324827000085],
              [106.13285672200004, 17.92191592100005],
              [106.12996917400005, 17.922174396000031],
              [106.12622405500007, 17.923404104000085],
              [106.11909392800004, 17.92638851400001],
              [106.11286540100011, 17.927892899000049],
              [106.10760720900007, 17.928484639000075],
              [106.10138056000001, 17.929776177000086],
              [106.09566419200009, 17.931851268000095],
              [106.09047300900008, 17.933151590000037],
              [106.08513722600003, 17.934096307000047],
              [106.08047333500005, 17.934409272],
              [106.07498746500009, 17.935564932000055],
              [106.06519432800009, 17.938382231000013],
              [106.05729023500007, 17.941074323000095],
              [106.04423266000006, 17.947088520000065],
              [106.04391420500009, 17.947308648000067],
              [106.04391676100003, 17.947745480000066],
              [106.04410280800006, 17.948312376000075],
              [106.04636424100003, 17.95614151700002],
              [106.04715107000004, 17.957884638000095],
              [106.047475967, 17.958756577000123],
              [106.04766254000008, 17.959410825000077],
              [106.04575457600004, 17.964234599000051],
              [106.04556652900003, 17.96509744600003],
              [106.04569781400005, 17.965527654000098],
              [106.04641207800009, 17.966570303000125],
              [106.04693178000009, 17.967367768000116],
              [106.04829052800008, 17.968530052000098],
              [106.05469314900003, 17.973481626000073],
              [106.05695578200002, 17.975272980000057],
              [106.06042108100003, 17.977819150000052],
              [106.06423680100009, 17.981068470000046],
              [106.06598492000005, 17.983422416000039],
              [106.06736555500007, 17.985889983000014],
              [106.06955089600004, 17.98725399200007],
              [106.07369438500004, 17.988226162000039],
              [106.0753364080001, 17.988942230000049],
              [106.07581419100011, 17.990057183000104],
              [106.07506948500004, 17.991278202000061],
              [106.06528430200007, 17.999900189000059],
              [106.0624313160001, 18.00338344400005],
              [106.05891707400011, 18.00674258600008],
              [106.05799012500006, 18.007844991000077],
              [106.05760995900005, 18.009244575000061],
              [106.05783539700005, 18.011175686000058],
              [106.05835992200011, 18.013752516000103],
              [106.05655318400001, 18.016425381000097],
              [106.04977670800012, 18.018254694000021],
              [106.049270662, 18.018419229000045],
              [106.04869386300005, 18.019120045000122],
              [106.04554658400006, 18.028146447000012],
              [106.04527612900006, 18.028855719000077],
              [106.04504529000006, 18.029119863000084],
              [106.04468745700004, 18.029344248000086],
              [106.04434956100005, 18.029366293000045],
              [106.04406369800003, 18.029256614000118],
              [106.0354073280001, 18.026613463],
              [106.03496238400007, 18.026393390000095],
              [106.03448474800007, 18.026001589000096],
              [106.03309269300009, 18.022773337000061],
              [106.03292204500009, 18.022491118000069],
              [106.03254083500008, 18.022331367000085],
              [106.03184369200004, 18.022335088000126],
              [106.03091488400001, 18.022461373000098],
              [106.02028413600007, 18.025389395000069],
              [106.01857933600006, 18.026510655000024],
              [106.01639452600003, 18.028665803000088],
              [106.01563860600001, 18.029478691000108],
              [106.01514590400011, 18.030138542000081],
              [106.01476968200006, 18.030848322000011],
              [106.0137899640001, 18.035009339000041],
              [106.01263618700003, 18.036451228000061],
              [106.00229558800001, 18.046050463000093],
              [106.00093755200001, 18.046896758000038],
              [105.99887630100008, 18.048565731000018],
              [105.99535264000012, 18.051324161000082],
              [105.993565066, 18.052829880000054],
              [105.99251744600011, 18.054412675],
              [105.98425047800008, 18.064890192000057],
              [105.98359688400009, 18.065156420000058],
              [105.98284690400003, 18.065190573000045],
              [105.98102941100011, 18.065159371000036],
              [105.97976155400001, 18.065165809000035],
              [105.97665318400004, 18.064797299000098],
              [105.97564795300008, 18.064529367000134],
              [105.97463122100007, 18.064089597000077],
              [105.97297632700001, 18.062884530000112],
              [105.96881198100007, 18.058759674000058],
              [105.96691146900011, 18.057060317000051],
              [105.96625455800003, 18.056719800000032],
              [105.96585187700002, 18.056499369000086],
              [105.96545040600002, 18.056501371000074],
              [105.96497558500008, 18.056614968000062],
              [105.96371340900001, 18.057652659000034],
              [105.96182149100005, 18.059461973000076],
              [105.96123247499999, 18.059950264000065],
              [105.96053714600001, 18.060317747000099],
              [105.9579908680001, 18.060320271000037],
              [105.95331404500011, 18.059049115000022],
              [105.95072366600002, 18.058707965000039],
              [105.94802928200001, 18.060642431000048],
              [105.94813939400007, 18.063442844000029],
              [105.94840446100005, 18.065585233000014],
              [105.94841011199999, 18.066636831000068],
              [105.94791824500007, 18.067518961000047],
              [105.94091085200007, 18.077038040000026],
              [105.937966676, 18.079762315000011],
              [105.9368223920001, 18.081173406000062],
              [105.93368647200006, 18.085627656000014],
              [105.93255797200004, 18.088039714000011],
              [105.93214009500011, 18.088951794000074],
              [105.93174185400002, 18.089580644000058],
              [105.93098347800006, 18.09004943800003],
              [105.93052957200007, 18.090142637000014],
              [105.93003106800003, 18.090145044000039],
              [105.92932418200009, 18.090006886000062],
              [105.92893039200001, 18.089774015000067],
              [105.92847614500008, 18.089505389000045],
              [105.92411904400009, 18.086846687000069],
              [105.920302904, 18.084559435000088],
              [105.91964491800009, 18.084016541000025],
              [105.91752158900009, 18.082105416000083],
              [105.91718219600007, 18.081864351000029],
              [105.91661139300001, 18.081826605000103],
              [105.91627419700008, 18.082010229000019],
              [105.91541161200003, 18.082762594000101],
              [105.91394242400006, 18.084731250000097],
              [105.91283923500001, 18.085959997000067],
              [105.91197660900004, 18.086712355000053],
              [105.91058553100004, 18.087446970000087],
              [105.90845713400006, 18.088650196000074],
              [105.90591404100006, 18.089359864000087],
              [105.89777216200008, 18.090459667000047],
              [105.89511095800007, 18.090815835000029],
              [105.89413886700002, 18.090840568000118],
              [105.8934624620001, 18.090823481000129],
              [105.89284843600009, 18.090603869000077],
              [105.89208564800005, 18.090223143000102],
              [105.88442639300003, 18.084332863000085],
              [105.88202745400005, 18.082179895000117],
              [105.88018966600005, 18.080236696],
              [105.87471491900004, 18.073618078000031],
              [105.87321222400004, 18.071026126000064],
              [105.87235924700008, 18.06947275200006],
              [105.87176404600004, 18.068767604000065],
              [105.8713397180001, 18.068425720000022],
              [105.87053440699999, 18.06796419500003],
              [105.86945507500005, 18.067645482000039],
              [105.86834461400008, 18.067438125000109],
              [105.86730904400011, 18.067422550000067],
              [105.86148854300009, 18.067721626000029],
              [105.85480227400002, 18.068165925000109],
              [105.85114759100006, 18.068424748000034],
              [105.84981626200005, 18.068430615000075],
              [105.84903359600003, 18.068272262000029],
              [105.84653378400006, 18.065071542000055],
              [105.84154739300004, 18.066312920000094],
              [105.83776391900007, 18.067264730000062],
              [105.83438743700003, 18.064908079000119],
              [105.83187507700008, 18.063169525000063],
              [105.83164406000006, 18.063473889000115],
              [105.82803687900012, 18.065971890000029],
              [105.82690798400006, 18.065203156000017],
              [105.82405109900003, 18.063208121000059],
              [105.81802638900007, 18.061620869000095],
              [105.8177401340001, 18.061409717000032],
              [105.81707914700004, 18.0606333530001],
              [105.81802599200005, 18.059105376000062],
              [105.82091625200009, 18.056254120000048],
              [105.825621298, 18.052556915000117],
              [105.82880280700007, 18.050572551000066],
              [105.83078381400003, 18.048288111000055],
              [105.8311079150001, 18.046267692000036],
              [105.83142395100006, 18.045879935000109],
              [105.83194752500003, 18.044886707000082],
              [105.83303273700002, 18.042010216000023],
              [105.83302473500008, 18.040311431000063],
              [105.83242817900002, 18.039262350000037],
              [105.83144960500005, 18.037850879000047],
              [105.82749242000001, 18.033511231000077],
              [105.8248732910001, 18.033055684000132],
              [105.82033863200007, 18.034773787000091],
              [105.81686600100008, 18.037720981000049],
              [105.81625569800012, 18.038249392000068],
              [105.81570746100004, 18.03849439],
              [105.81539062700004, 18.0385159450001],
              [105.81498909000004, 18.038497418000077],
              [105.81443797300008, 18.038115487000091],
              [105.81360092200002, 18.037583062000095],
              [105.81027364000005, 18.035392594000129],
              [105.806695104, 18.033688501000086],
              [105.80629220200005, 18.033366591000096],
              [105.80620630600005, 18.033063594000076],
              [105.80633086600005, 18.032577678000017],
              [105.80692744200003, 18.031351634000089],
              [105.80787054200007, 18.029669096000056],
              [105.80824770400002, 18.028979899000049],
              [105.80843619000008, 18.028615072000065],
              [105.80849782000011, 18.028230564000047],
              [105.80849505000006, 18.027623847000115],
              [105.80830350800002, 18.027321287000042],
              [105.80790070600008, 18.027019602000038],
              [105.80671674300001, 18.026842532000039],
              [105.79652506500007, 18.02504434400003],
              [105.79593294800002, 18.024925434000046],
              [105.79529914100007, 18.024928041000088],
              [105.79445459300008, 18.02505285700002],
              [105.79129946300006, 18.025824185000069],
              [105.78906095300003, 18.026055794000051],
              [105.78675806300001, 18.026065175000028],
              [105.78595433000004, 18.025866199000085],
              [105.78534020700002, 18.025545102000073],
              [105.78384611900003, 18.024509616000017],
              [105.78020707600004, 18.02095473200005],
              [105.77725953000004, 18.018479002000021],
              [105.77677220400005, 18.018157364000054],
              [105.77630698300001, 18.018058110000098],
              [105.77588445000005, 18.018059807000064],
              [105.77485084700005, 18.018427981000059],
              [105.77336641100007, 18.019576594000064],
              [105.77168296300005, 18.021120361000072],
              [105.76853700400008, 18.024014841000024],
              [105.76712481100003, 18.024788971000035],
              [105.766154183, 18.025075954000066],
              [105.76442295200012, 18.025365947000083],
              [105.76262738700005, 18.02543370500009],
              [105.75910083200003, 18.025852050000069],
              [105.75505004700007, 18.027202697000085],
              [105.75414344900001, 18.027651171000066],
              [105.75260731500001, 18.029113306000077],
              [105.75179951600006, 18.030441147000076],
              [105.75072960500009, 18.032245269000043],
              [105.7500783130001, 18.033117441000087],
              [105.74894182800004, 18.034173514000074],
              [105.74798223100005, 18.034591833000043],
              [105.73782181400003, 18.037755518000061],
              [105.736913273, 18.037758988000085],
              [105.7347032320001, 18.037271894000106],
              [105.73239808100007, 18.036775017000117],
              [105.73055727500008, 18.036155022000067],
              [105.72998528800009, 18.035793141000056],
              [105.72911668000008, 18.03523013600001],
              [105.72812054700009, 18.034485586000045],
              [105.72751410100008, 18.033446309000034],
              [105.7269592750001, 18.032113589000076],
              [105.72627744600008, 18.030720654000021],
              [105.72522575700002, 18.029298775000058],
              [105.72164833200006, 18.0250548960001],
              [105.72067292300008, 18.024188871000121],
              [105.71963397700009, 18.023282635000015],
              [105.71825536700007, 18.021973165000112],
              [105.71804278800006, 18.021650360000052],
              [105.71792335000004, 18.020851931000067],
              [105.71810750200009, 18.01937485600007],
              [105.71852785100006, 18.016228384000051],
              [105.71896478600006, 18.014568350000012],
              [105.71923797100001, 18.011604436000091],
              [105.71960904700008, 18.009337909000052],
              [105.719595285, 18.008549205000079],
              [105.71885010600008, 18.007126142000104],
              [105.7183416240001, 18.006763982000074],
              [105.71783306400003, 18.006381607000051],
              [105.71728257300005, 18.006080279000081],
              [105.7160133300001, 18.005660253000052],
              [105.71480845100007, 18.005482683000103],
              [105.71330750000004, 18.005225301000038],
              [105.7126931610001, 18.004802848000075],
              [105.71126092700008, 18.003240703000053],
              [105.7109273610001, 18.001714960000115],
              [105.71143796800007, 18.000000163000074],
              [105.711293133, 17.99961693900007],
              [105.71102883200007, 17.999229689000096],
              [105.70853657300009, 17.997198775000101],
              [105.70435825200005, 17.993613466000099],
              [105.70414262000006, 17.993430673000077],
              [105.70381973700009, 17.993308547000098],
              [105.70333588100002, 17.993248657000024],
              [105.702433044, 17.993221104000057],
              [105.69574361800004, 17.993353118000051],
              [105.68977583, 17.993488359000054],
              [105.68686207200005, 17.993554330000094],
              [105.68425071600008, 17.993594400000035],
              [105.67740027600011, 17.993787999000062],
              [105.67333809500009, 17.993832976000057],
              [105.66787396400007, 17.994021423000014],
              [105.66629441400002, 17.994088510000061],
              [105.66361878400006, 17.994190168000031],
              [105.65850870000003, 17.994223009000088],
              [105.656638517, 17.994167699000037],
              [105.65318854200011, 17.994117706000075],
              [105.64828671099998, 17.993795089000031],
              [105.64448085500004, 17.993407063000078],
              [105.64173520400006, 17.991198918000073],
              [105.64163336100006, 17.993229534000044],
              [105.64062851500006, 17.994319435000072],
              [105.63911033500003, 17.994302918000038],
              [105.63782971700003, 17.993953508000082],
              [105.63446033100004, 17.99246309100004],
              [105.63142266700007, 17.99254176700007],
              [105.62718914300008, 17.994955626000127],
              [105.6211662540001, 18.000480840000051],
              [105.61784930000002, 18.004358248000059],
              [105.61474969300005, 18.009579864000088],
              [105.61319512100003, 18.01258196700006],
              [105.61096932400008, 18.013116583000063],
              [105.60805486400008, 18.01263718700001],
              [105.60422090700011, 18.010917542000101],
              [105.60202075200006, 18.00932772],
              [105.59845293500008, 18.004927165000062],
              [105.59683412700008, 18.00356736500008],
              [105.59554946300001, 18.003553131000061],
              [105.59390629700005, 18.004205851000066],
              [105.5924858650001, 18.005755616000037],
              [105.59197804600009, 18.009104669000031],
              [105.59055620600006, 18.010766220000015],
              [105.58598227700006, 18.012280892000106],
              [105.58318196500008, 18.012026078000076],
              [105.5814259860001, 18.012341977000098],
              [105.57988860900008, 18.01389034900005],
              [105.57952187400011, 18.015228124000068],
              [105.58054850800006, 18.017252371000048],
              [105.58167971100005, 18.020284180000075],
              [105.58143114100008, 18.021511450000013],
              [105.57882341000006, 18.024613374000076],
              [105.5780994250001, 18.026506259000108],
              [105.57808033600003, 18.028071554000036],
              [105.58057371100001, 18.034361349000072],
              [105.58263403400005, 18.037850751000036],
              [105.5837735480001, 18.040211666000069],
              [105.58385363300006, 18.043231697000103],
              [105.58348687000009, 18.044569459000037],
              [105.58181338200009, 18.047681772000068],
              [105.58061255000003, 18.050352053000026],
              [105.57985851000008, 18.0547046],
              [105.57910853600002, 18.058721720000023],
              [105.57778941100003, 18.061502470000065],
              [105.57602752600009, 18.062265533000051],
              [105.57380653600009, 18.062352531000059],
              [105.57204736200002, 18.062891952000101],
              [105.57097270900002, 18.064780840000068],
              [105.57071854500003, 18.066455267000059],
              [105.57128899400007, 18.067579837000025],
              [105.57324906400007, 18.069726308000078],
              [105.57370270600003, 18.07084955400002],
              [105.57333719000007, 18.072075465000111],
              [105.57201517100002, 18.075079760000087],
              [105.56956635400009, 18.084221359000097],
              [105.56825103500006, 18.086666596000086],
              [105.56719128700004, 18.087325619000083],
              [105.56602702700008, 18.086977117000025],
              [105.56267307300004, 18.084144062000092],
              [105.55965596600007, 18.082432910000087],
              [105.55499361100004, 18.081485902000068],
              [105.54798222100007, 18.081518620000026],
              [105.54072884500006, 18.082219282],
              [105.53873296700004, 18.082979374],
              [105.53789294400008, 18.084758922000077],
              [105.53718810600003, 18.094478968000068],
              [105.53743738300004, 18.102644362000127],
              [105.53773661400002, 18.106784931000064],
              [105.53735278000006, 18.109464133000053],
              [105.53541516000006, 18.11492107],
              [105.53352050900006, 18.116912208000088],
              [105.52952937600006, 18.118320416000074],
              [105.52705730600006, 18.119745859000069],
              [105.52277654800008, 18.12562318500008],
              [105.52006922300006, 18.127157668000038],
              [105.5171477160001, 18.127124277000028],
              [105.513541859, 18.125741248000082],
              [105.51203809900002, 18.124494068],
              [105.51031887400005, 18.121790828000073],
              [105.50927417900003, 18.121219772000074],
              [105.50810280400002, 18.121429961000054],
              [105.50549964000001, 18.123971787000045],
              [105.50335259400001, 18.12741333500005],
              [105.50227663400005, 18.131786965000082],
              [105.50192501300009, 18.138690048000058],
              [105.50327256700004, 18.143066238000038],
              [105.50522281000011, 18.145995780000042],
              [105.50917117600004, 18.148053722000057],
              [105.51055681700002, 18.149411353000033],
              [105.5105427930001, 18.150529313000071],
              [105.50945164700006, 18.153647525000061],
              [105.50846190900006, 18.157996819000033],
              [105.50644435300011, 18.169713778000052],
              [105.50447718600006, 18.177406051000041],
              [105.50338578700008, 18.180524167000023],
              [105.5000057820001, 18.188982766],
              [105.49847900800007, 18.190117053000066],
              [105.49671716100005, 18.190174770000041],
              [105.49533415200003, 18.188593507000036],
              [105.49308521200001, 18.181523604000027],
              [105.49137140400006, 18.178373153000038],
              [105.48998427800004, 18.177127220000067],
              [105.48811688200007, 18.176882007000117],
              [105.486939502, 18.177539250000052],
              [105.48537176400005, 18.181322622],
              [105.48495873800007, 18.186237440000049],
              [105.48573025200004, 18.189936061000097],
              [105.49008749400005, 18.196694947000047],
              [105.49157470200001, 18.199283714000011],
              [105.49143376600006, 18.201182815000024],
              [105.48965192000007, 18.203398376000024],
              [105.48719833700001, 18.20325819100006],
              [105.48429414200005, 18.201771082000029],
              [105.48141553500008, 18.198271684000119],
              [105.47932408100006, 18.197241153000029],
              [105.47745366500001, 18.197219437000101],
              [105.46947301000006, 18.199586367000094],
              [105.46347096400002, 18.202646970000025],
              [105.46111141700001, 18.204296530000086],
              [105.45971680000009, 18.205467558000073],
              [105.45874753500001, 18.206281414000088],
              [105.45710941500001, 18.206374055000119],
              [105.45431226900003, 18.205670447000074],
              [105.451274142, 18.205523005000131],
              [105.44215260300007, 18.20563939600008],
              [105.44028787200006, 18.205170203000073],
              [105.43971344200004, 18.20438077500004],
              [105.43972786900008, 18.203262865000092],
              [105.44055917900002, 18.202266395000066],
              [105.44468105700001, 18.199966968000055],
              [105.44504616300003, 18.198853179000096],
              [105.44495661100007, 18.196727770000095],
              [105.44450917100009, 18.195157190000089],
              [105.44242946900005, 18.193231967000131],
              [105.44202052100006, 18.192716154],
              [105.44081884700002, 18.191200443000085],
              [105.43889652700004, 18.186146382000068],
              [105.43742887400002, 18.182103932000082],
              [105.43568994000002, 18.180965307000108],
              [105.43126109900004, 18.179906682000087],
              [105.429034384, 18.180327576000096],
              [105.42749740700003, 18.181651089000091],
              [105.42477407200002, 18.184302236000072],
              [105.421730494, 18.184601552000053],
              [105.41904630500004, 18.184234242000038],
              [105.41708095100006, 18.182533737000114],
              [105.41296756900006, 18.175217132000029],
              [105.41208350400009, 18.171293229000085],
              [105.41236829700003, 18.167383250000043],
              [105.4121811860001, 18.163803061000024],
              [105.41107071400006, 18.159317370000046],
              [105.40981860800011, 18.156730770000081],
              [105.40831380500003, 18.155594692000051],
              [105.4065606610001, 18.15557376000001],
              [105.40468041200005, 18.156333983000117],
              [105.40065242800001, 18.160422836000031],
              [105.39841999500004, 18.161290595000068],
              [105.39503782900007, 18.160690988000013],
              [105.39130798400001, 18.159863534000031],
              [105.38966286300005, 18.160514633000076],
              [105.38610789799999, 18.164161652000118],
              [105.38157791400008, 18.17081574800001],
              [105.380287756, 18.171135620000101],
              [105.378543339, 18.170443705000046],
              [105.37542589500008, 18.167498966000025],
              [105.37462842200009, 18.165923969000097],
              [105.37489028400007, 18.163802716000085],
              [105.3746801880001, 18.16201118900009],
              [105.37387090700003, 18.161330534000086],
              [105.37188691100009, 18.161082905000022],
              [105.36590975200008, 18.162240411000049],
              [105.36320071600002, 18.163772895000108],
              [105.36106416200008, 18.166206795000051],
              [105.35576195500003, 18.178106060000054],
              [105.35372264900006, 18.18199458300009],
              [105.35076304700004, 18.184753750000127],
              [105.34877288000006, 18.18495307700001],
              [105.34435189500002, 18.183333723000047],
              [105.34003288200006, 18.182833629000058],
              [105.33535270500006, 18.183111685000064],
              [105.33452395000006, 18.183884190000029],
              [105.33485510900002, 18.185341779000019],
              [105.33620379700005, 18.189383477000121],
              [105.33571068900002, 18.191278189000037],
              [105.33382532300007, 18.192373150000037],
              [105.33077997099998, 18.192782995000108],
              [105.3276147020001, 18.193414926000081],
              [105.32585219200011, 18.194064086000061],
              [105.31827853100008, 18.200791054000121],
              [105.31802508300007, 18.20224143100004],
              [105.31869779100003, 18.204374100000017],
              [105.31983663100003, 18.206624321000042],
              [105.32342150000008, 18.209575542000067],
              [105.3241033370001, 18.211037439000052],
              [105.32381221800009, 18.215282546000111],
              [105.3229289760001, 18.220079370000072],
              [105.3220638010001, 18.223534718000067],
              [105.32151613399999, 18.229453709000062],
              [105.32203130900007, 18.234603133000071],
              [105.32326766700005, 18.238307957000096],
              [105.32556998300004, 18.241019643000108],
              [105.32715121800004, 18.245175886000098],
              [105.329018574, 18.254143208000073],
              [105.32897190500005, 18.257608554000093],
              [105.32831344000002, 18.263078823000086],
              [105.32747227300004, 18.264745532000056],
              [105.32629081699999, 18.265625413000031],
              [105.32149021300006, 18.266013504000078],
              [105.31913477600004, 18.267214288000041],
              [105.31643303000006, 18.268075360000054],
              [105.3103535700001, 18.267888381000127],
              [105.30814533600009, 18.266854810000055],
              [105.3058642180001, 18.262578019000038],
              [105.30335388800003, 18.257962924000104],
              [105.30337212700005, 18.256621506000045],
              [105.30468423300007, 18.254737111000097],
              [105.30471157700002, 18.252724978000053],
              [105.30427575500002, 18.250371683000118],
              [105.29964441300007, 18.238351018000017],
              [105.29825045900002, 18.237662854000099],
              [105.296484412, 18.238535330000083],
              [105.29504168600009, 18.241424311000017],
              [105.2941744070001, 18.2449912820001],
              [105.29436709000005, 18.248012409000047],
              [105.29686032600002, 18.253857284000041],
              [105.29718827800006, 18.255538423000047],
              [105.29671637800001, 18.256450713000035],
              [105.29038318600003, 18.257130727000082],
              [105.28756908900007, 18.257654636000083],
              [105.2855536480001, 18.259641932000115],
              [105.28318735100007, 18.261624828000045],
              [105.28037164800007, 18.26226042300005],
              [105.27920686700006, 18.26191043000005],
              [105.27842972000006, 18.25888199000012],
              [105.27775264800009, 18.257084650000088],
              [105.27590011600002, 18.255719774000042],
              [105.274152246, 18.255250639000039],
              [105.27250595000002, 18.255900802000014],
              [105.27142277600002, 18.25812328400005],
              [105.27022418600006, 18.260232505000126],
              [105.26904866000005, 18.260664944000027],
              [105.26460351200005, 18.260720836000118],
              [105.25659162600009, 18.260309106000072],
              [105.25595480700007, 18.260276368000028],
              [105.25441625000003, 18.26095757000008],
              [105.25392664000003, 18.263157641000035],
              [105.25423837100007, 18.265221864000033],
              [105.25528194300009, 18.266640677000098],
              [105.25633227900003, 18.268714532],
              [105.25604948700008, 18.270339881000019],
              [105.25388415600007, 18.274672840000058],
              [105.25186200800009, 18.277106934000109],
              [105.24826519300002, 18.283434141000043],
              [105.24247592100001, 18.295994317000108],
              [105.24105065500004, 18.297541439000042],
              [105.23823275000002, 18.298288256000106],
              [105.23506394400003, 18.299030563000059],
              [105.23211186800002, 18.301005401000033],
              [105.22903340500004, 18.303649376000038],
              [105.221875213, 18.305235],
              [105.21812612200004, 18.305634239000042],
              [105.21542498400001, 18.306382253000031],
              [105.21412424400003, 18.3073717830001],
              [105.21054140200008, 18.312580423000036],
              [105.20754443099999, 18.314827391000051],
              [105.19546992100005, 18.318277300000126],
              [105.18953761500011, 18.320904708000093],
              [105.18283383200001, 18.32802865200005],
              [105.18255643700006, 18.330954404000096],
              [105.18571975500005, 18.340684713000051],
              [105.18820474800005, 18.348378148000052],
              [105.18767881800005, 18.35220196500007],
              [105.18578761200007, 18.357118476000075],
              [105.18102071900009, 18.36648872799999],
              [105.17735934500011, 18.370211615000095],
              [105.17688843400005, 18.37160592900009],
              [105.17708631199999, 18.373547545000065],
              [105.17905438400003, 18.377882088000064],
              [105.18299997300008, 18.385904848000045],
              [105.18298313300002, 18.387089588000016],
              [105.18114540700002, 18.389435694000063],
              [105.17818621700005, 18.391336338000116],
              [105.17071368200003, 18.393393699000079],
              [105.16699429700003, 18.393237551000126],
              [105.16553771600005, 18.392572227000059],
              [105.16454757900001, 18.390835741000089],
              [105.16322998800007, 18.388340902000074],
              [105.16199588800001, 18.387893905000041],
              [105.15873069900002, 18.387528089000057],
              [105.15455269600001, 18.387904356000014],
              [105.15024952400003, 18.389140680000082],
              [105.14842224000002, 18.39073258300008],
              [105.1466596010001, 18.395664747000048],
              [105.14543895100009, 18.402112109000065],
              [105.14517929200011, 18.404478599000115],
              [105.14436978000001, 18.405868361000088],
              [105.14107326900009, 18.407656323000054],
              [105.13720663400009, 18.40986762400005],
              [105.13650826500003, 18.411366534000081],
              [105.13637837700004, 18.41254977000002],
              [105.13724938200008, 18.414715671000081],
              [105.13888490700003, 18.418615165000048],
              [105.13897279600012, 18.420339861000024],
              [105.13805974700007, 18.421081902000047],
              [105.13693670600006, 18.420743957000063],
              [105.13403426400006, 18.418659022000035],
              [105.13134183100004, 18.417654029000104],
              [105.12897524300007, 18.417515070000064],
              [105.12479810500005, 18.417783058000033],
              [105.1199146050001, 18.420088310000082],
              [105.11899364600002, 18.421368768000065],
              [105.11862430800002, 18.423673116000124],
              [105.11775313600006, 18.429108237000058],
              [105.11725189300006, 18.432548645000054],
              [105.11621652000011, 18.433935280000036],
              [105.114397952, 18.434880641000092],
              [105.11236480200009, 18.435069091000052],
              [105.10988209600002, 18.435143840000109],
              [105.10818098400004, 18.435767541000111],
              [105.10529699200008, 18.440145688000072],
              [105.10271027000002, 18.4473284480001],
              [105.10221974700002, 18.450014886000062],
              [105.1028681220001, 18.451962452000089],
              [105.10574554900006, 18.455770910000076],
              [105.10572824000005, 18.456955575000045],
              [105.1053725510001, 18.458135736000081],
              [105.10319801200004, 18.460261152000079],
              [105.10295509700011, 18.461442808000058],
              [105.10383698300006, 18.462854880000101],
              [105.10616633600006, 18.465578817000058],
              [105.10658763000001, 18.46763104700004],
              [105.10628966200005, 18.472582065000061],
              [105.10726038500007, 18.483366584000024],
              [105.10733859100006, 18.485737367000077],
              [105.10876578300011, 18.48844922100001],
              [105.11163914200009, 18.492580556000071],
              [105.11184434300002, 18.493983570000069],
              [105.11147135600007, 18.496348339000065],
              [105.11075205300006, 18.49924707800001],
              [105.10878860800005, 18.502344701000069],
              [105.10795327900003, 18.505457297000035],
              [105.1083416540001, 18.509770998000086],
              [105.10951195600003, 18.514633629000073],
              [105.11182629100003, 18.518434298000038],
              [105.11449802600002, 18.520947132000032],
              [105.11527841000003, 18.52160375200009],
              [105.11762427800005, 18.5232505110001],
              [105.11783113000003, 18.524545794000069],
              [105.11713847200009, 18.525613734000054],
              [105.11428789700005, 18.527622471000079],
              [105.11336484100005, 18.529010477000085],
              [105.11299022600005, 18.531482872000105],
              [105.11380499900005, 18.537525490000029],
              [105.11445063500001, 18.539688258000098],
              [105.11579215000008, 18.540567719000123],
              [105.11949232800008, 18.542232381000098],
              [105.12284466000007, 18.544538638000049],
              [105.12628021800002, 18.548892408000128],
              [105.12648871600004, 18.550079968000063],
              [105.12612047800005, 18.552121581000058],
              [105.12607983600006, 18.554921475000086],
              [105.12706107900006, 18.557304022000039],
              [105.12951096100008, 18.559598228000063],
              [105.13366301100008, 18.561268573000063],
              [105.13748125100008, 18.562611313000055],
              [105.13770603500002, 18.562859426000067],
              [105.13837006000007, 18.563592373000048],
              [105.1381256710001, 18.564881656000054],
              [105.13434541600006, 18.568709391000127],
              [105.13239724000006, 18.570730174000083],
              [105.13214657400002, 18.572450186000111],
              [105.13283831900006, 18.579244828000085],
              [105.13434198500003, 18.584542238000076],
              [105.13290962800009, 18.589908665000095],
              [105.13253983100005, 18.592057898000022],
              [105.13397281300004, 18.594446282000106],
              [105.13987711300007, 18.599909170000025],
              [105.14914939700006, 18.606708434000026],
              [105.15296087400003, 18.608589268000038],
              [105.15452569000009, 18.609686767000078],
              [105.15573484900004, 18.611964330000049],
              [105.157056928, 18.614243365000057],
              [105.15896972800009, 18.614699142000021],
              [105.16237315400005, 18.613558799000081],
              [105.16589866500007, 18.611773761000094],
              [105.17535990800005, 18.605434512000052],
              [105.17933513100007, 18.603762793000023],
              [105.18114727300008, 18.60335545100007],
              [105.18430930400005, 18.603288668000047],
              [105.18671103600009, 18.603571016000053],
              [105.188674367, 18.601071996000051],
              [105.19075692800001, 18.600308294000101],
              [105.19781252000007, 18.599476874000054],
              [105.20512562800005, 18.599966169000126],
              [105.21051109200005, 18.600035241000064],
              [105.21661665100002, 18.598005155000052],
              [105.22011324400006, 18.594887479000107],
              [105.2240479980001, 18.590062363000115],
              [105.22668078100007, 18.589437095000072],
              [105.22888640100004, 18.589728716000039],
              [105.23078637300004, 18.592124671000043],
              [105.23523088900008, 18.600218810000065],
              [105.23821186500007, 18.604341335000107],
              [105.24203622600004, 18.607420382000051],
              [105.25517453100008, 18.61615099900007],
              [105.26011515500004, 18.618453181000035],
              [105.26536303800002, 18.61851915700008],
              [105.26811416900009, 18.619344270000049],
              [105.26960967100011, 18.621075925000063],
              [105.27178120200001, 18.623870137000061],
              [105.27452880500007, 18.624958634000066],
              [105.27811414800007, 18.625398769000064],
              [105.28074724000007, 18.624772868000058],
              [105.28451950699998, 18.621657672000055],
              [105.28674363200007, 18.620631309000132],
              [105.28812105900008, 18.620911994],
              [105.29085248000004, 18.623185937000116],
              [105.29306397700006, 18.62308168000002],
              [105.29586389700009, 18.620349482000087],
              [105.30237289600005, 18.608966923000111],
              [105.30432418200003, 18.607673463000118],
              [105.31568908100004, 18.604783340000083],
              [105.32329310700003, 18.604218142000036],
              [105.32661624000009, 18.603600162000063],
              [105.3291196750001, 18.60231325600008],
              [105.33194543200005, 18.597604418000024],
              [105.33602269100007, 18.592252017000099],
              [105.33755225200007, 18.59148013700009],
              [105.34294283600005, 18.5911507100001],
              [105.34918307900006, 18.589250312000068],
              [105.35404942700009, 18.586805984000065],
              [105.35768877200003, 18.583160766000084],
              [105.36213009900007, 18.581501678000031],
              [105.36668490300008, 18.581688591000045],
              [105.36985893200007, 18.581858731000089],
              [105.37262923200005, 18.581233362000127],
              [105.37526841500001, 18.580079312000031],
              [105.37734130800001, 18.579972530000077],
              [105.38367193200006, 18.581629888000073],
              [105.38836309000007, 18.58194974400007],
              [105.392912714, 18.582531344000053],
              [105.39634908500003, 18.583758357000058],
              [105.40430930300008, 18.587542830000103],
              [105.41310178000006, 18.591073358000095],
              [105.41667993900009, 18.592038201000079],
              [105.41903254200005, 18.591670812000061],
              [105.42445556500004, 18.588836229],
              [105.42957660800002, 18.587974410000108],
              [105.43302012400001, 18.588673859000068],
              [105.43560444800005, 18.591734890000033],
              [105.43705515000009, 18.597022510000016],
              [105.4390958020001, 18.599418256],
              [105.44417282600006, 18.601981407000068],
              [105.44830369900008, 18.602952197],
              [105.45438996100006, 18.602232876000087],
              [105.457700796, 18.602535090000046],
              [105.46126924000002, 18.604289632000089],
              [105.46373129300008, 18.60616299900002],
              [105.46757942200006, 18.607657173000099],
              [105.4710267820001, 18.608092532000065],
              [105.47336602100003, 18.608778494000063],
              [105.47444894100001, 18.610503959000098],
              [105.4744102760001, 18.613534008000073],
              [105.47494251200008, 18.615121313000046],
              [105.47645491000007, 18.615665893000077],
              [105.48199240300004, 18.614675912000052],
              [105.48879614600006, 18.611855761000037],
              [105.49186287000003, 18.61175510000006],
              [105.49473776500005, 18.611660687000061],
              [105.49974306800004, 18.61193818600011],
              [105.50233988800005, 18.611220982000098],
              [105.50457771400002, 18.609006707000027],
              [105.50739275500007, 18.604822587000044],
              [105.51004970600007, 18.602217755000083],
              [105.51339869200007, 18.59948903600008],
              [105.51397256100003, 18.597782677000026],
              [105.51404205500009, 18.592249433000063],
              [105.51520463100009, 18.587650994000079],
              [105.5168996350001, 18.584639765000063],
              [105.51915349900005, 18.581107834000044],
              [105.52096336600006, 18.579942574000086],
              [105.522916235, 18.578383629000058],
              [105.52474088000007, 18.576032621000046],
              [105.52660827900003, 18.57025618400003],
              [105.52834572700009, 18.563819391],
              [105.52988912100007, 18.561860420000095],
              [105.53446330900007, 18.560462849000103],
              [105.53640768200002, 18.559562493000037],
              [105.53906528100001, 18.556825462000056],
              [105.54144013400003, 18.554612244000076],
              [105.54324868800011, 18.554105945000018],
              [105.54351706100002, 18.554118117000051],
              [105.54710549300009, 18.554280871000103],
              [105.54929651400002, 18.55575499200004],
              [105.55119677600004, 18.558411709000033],
              [105.55339112900005, 18.55962228500001],
              [105.56510223100003, 18.56173011100006],
              [105.56951870300006, 18.56191123900004],
              [105.57380841000007, 18.561168495000047],
              [105.57934711300004, 18.559912501000042],
              [105.58183226900005, 18.559940125000104],
              [105.58292390900004, 18.561006388000045],
              [105.5841086130001, 18.565763110000063],
              [105.58543466900005, 18.570257841000078],
              [105.58719266400001, 18.573307931000024],
              [105.58912087900003, 18.573724593000037],
              [105.59174427400004, 18.573753632000134],
              [105.59393904500008, 18.574963784000076],
              [105.59654166400004, 18.576705466000121],
              [105.59970783100009, 18.577530993000032],
              [105.60716077700009, 18.577876577000026],
              [105.60793030100007, 18.57793268300005],
              [105.61450673800002, 18.578412178000022],
              [105.61538633200006, 18.574918268000125],
              [105.61582395400004, 18.573913493000035],
              [105.61656112400001, 18.572295002000061],
              [105.61877928000004, 18.570046744000109],
              [105.62328232000003, 18.567914325000068],
              [105.62786199600006, 18.567327967000047],
              [105.63176729100007, 18.567370492000101],
              [105.6351919840001, 18.567771331000046],
              [105.6376587460001, 18.568616237000057],
              [105.63982677900006, 18.570548678000073],
              [105.644143501, 18.576049505000114],
              [105.64763122200002, 18.579177854000051],
              [105.6516072090001, 18.581311491000108],
              [105.65804817600012, 18.584471487000087],
              [105.66044767400005, 18.585287429000097],
              [105.661084665, 18.585504036000074],
              [105.66394246000003, 18.585534722000126],
              [105.66681525400004, 18.584292933000057],
              [105.66768651100001, 18.583120562000133],
              [105.66858130800006, 18.579948637000051],
              [105.66945252600003, 18.578776264000041],
              [105.67164556800003, 18.578617928000085],
              [105.67487682600004, 18.579288772000076],
              [105.67554301300001, 18.579785518000058],
              [105.68007121600004, 18.583161998],
              [105.68382520600005, 18.588019672000122],
              [105.686354764, 18.591682552000051],
              [105.68754540300002, 18.595785666000083],
              [105.68813476400001, 18.602427532000029],
              [105.68792696300005, 18.612060527000018],
              [105.68754238800003, 18.620509895000033],
              [105.68777300400011, 18.625238983000081],
              [105.68944133600003, 18.629256135000013],
              [105.69122941200001, 18.631183924000091],
              [105.69435995300003, 18.632398739000067],
              [105.69787906400006, 18.632981350000044],
              [105.700251635, 18.63382448200008],
              [105.70250466800007, 18.636756933000065],
              [105.70454819900004, 18.640004251000072],
              [105.70475038800008, 18.640325537000102],
              [105.7068514040001, 18.648164642000076],
              [105.70774577700003, 18.651434257000062],
              [105.70877146600007, 18.655183722000054],
              [105.71115974400006, 18.662934803000027],
              [105.71273882500006, 18.666496228000092],
              [105.71519141500005, 18.668703402000105],
              [105.71850386100002, 18.670737771000034],
              [105.72267306000002, 18.672871931000131],
              [105.72600236500006, 18.673452073000085],
              [105.73106069500008, 18.672868575000031],
              [105.73948467300004, 18.669684033000067],
              [105.74426214500009, 18.668642858000069],
              [105.7494062910001, 18.668877855000062],
              [105.75815746000005, 18.670422392000049],
              [105.76365617400009, 18.673023915],
              [105.765914697, 18.675592071000047],
              [105.76711774800006, 18.678785627000046],
              [105.76688815300007, 18.682237154000077],
              [105.76490706300002, 18.688851903000092],
              [105.76081557300006, 18.696626475000052],
              [105.75749763900008, 18.703409102000101],
              [105.75279852600002, 18.714267405000037],
              [105.74919453000008, 18.723707929000085],
              [105.74778705300007, 18.727394473000025],
              [105.74505050200004, 18.733273862000118],
              [105.74135422800001, 18.739688563000101],
              [105.74056663800003, 18.741861677000045],
              [105.74035208200004, 18.743949849000074],
              [105.74145772500005, 18.747324100000107],
              [105.74355869400006, 18.749932914000041],
              [105.74568936900005, 18.75215077600005],
              [105.7488973820001, 18.755489919000112],
              [105.75684703300007, 18.760324929000127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 26, code: "AD01", ten_tinh: "Hải Dương" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.49707975700001, 21.228262853000054],
              [106.49952394500009, 21.225476055000044],
              [106.49919075700006, 21.22300050300003],
              [106.49863215800012, 21.219317363000108],
              [106.49866763000003, 21.214718380000086],
              [106.49912843200005, 21.2057069190001],
              [106.49914545200005, 21.203499358000052],
              [106.49856846600008, 21.20220763000011],
              [106.49759595400009, 21.201465142000053],
              [106.49485988600001, 21.201078606000046],
              [106.49329763900008, 21.200700028000071],
              [106.4927150020001, 21.200144141000088],
              [106.49252648900004, 21.199222985000077],
              [106.49333750400008, 21.195549004000021],
              [106.49386002800006, 21.191229113000084],
              [106.49416414100008, 21.18985134800004],
              [106.49438181800008, 21.187001167000027],
              [106.49429040900006, 21.186172645000042],
              [106.49400053400005, 21.185710730000018],
              [106.4931252210001, 21.18506084600007],
              [106.49049503700009, 21.183663075000055],
              [106.48883942200005, 21.182731880000105],
              [106.4881604710001, 21.181991320000023],
              [106.48787204600004, 21.181345418000049],
              [106.48720096500003, 21.179593020000098],
              [106.48595092500007, 21.176824754000023],
              [106.48528059300003, 21.174980348000091],
              [106.48528631800006, 21.174244454000046],
              [106.48598382700004, 21.172593388000038],
              [106.48708111500005, 21.169841131000041],
              [106.48738595600005, 21.168371344000022],
              [106.48729315600011, 21.167726764000072],
              [106.48710327100011, 21.166989533000091],
              [106.48564841300001, 21.165415709000079],
              [106.48293294200003, 21.162453334000105],
              [106.4814803030001, 21.160603507000083],
              [106.48109485100009, 21.159864911000078],
              [106.48053318700008, 21.156641269000076],
              [106.47881956500004, 21.150649851000125],
              [106.47815801800002, 21.147701451000067],
              [106.47826084100008, 21.147058198000046],
              [106.47885190000005, 21.146510281000097],
              [106.48090838100003, 21.146156433000037],
              [106.48189059200003, 21.145611192000047],
              [106.48228820600006, 21.144785960000064],
              [106.48249740600005, 21.143039474000069],
              [106.48250887400012, 21.141567606000052],
              [106.48194293700001, 21.138895826000059],
              [106.48088736900007, 21.136312663000112],
              [106.48079604200007, 21.135484063000028],
              [106.48107122900009, 21.134680970000105],
              [106.48240423000007, 21.133034166000094],
              [106.48289960000002, 21.132209577000083],
              [106.48360519500005, 21.130685035000063],
              [106.481680877, 21.128706578000056],
              [106.47978186900008, 21.127559842000032],
              [106.47808000800003, 21.126886822000031],
              [106.47615389600001, 21.125999650000082],
              [106.47320357600006, 21.124467713000072],
              [106.47190416300002, 21.123702933000033],
              [106.47135709100004, 21.123037829000069],
              [106.47096100800005, 21.122326528000102],
              [106.47087208700006, 21.120861536000035],
              [106.47052658800008, 21.120103343],
              [106.46962772900005, 21.119483037000109],
              [106.46001088600006, 21.116582010000059],
              [106.45875654100011, 21.116478798000045],
              [106.45709757300007, 21.116750667000069],
              [106.45402839700006, 21.117579528000086],
              [106.45186512200003, 21.118131264000084],
              [106.45020537900011, 21.118497534000099],
              [106.44693834000006, 21.118947010000078],
              [106.44502831800003, 21.119217016000036],
              [106.44397402800011, 21.119209603000044],
              [106.44216931600009, 21.118866243000056],
              [106.44101688200004, 21.118574694000088],
              [106.44013500900007, 21.118196475000062],
              [106.43928589500008, 21.117647247],
              [106.44004853600003, 21.114889181000059],
              [106.44066311299999, 21.113014559000121],
              [106.44028323100008, 21.112039123000059],
              [106.43861696000006, 21.110325044000078],
              [106.43772312600001, 21.108981178000086],
              [106.43760268200008, 21.107885974000062],
              [106.43787379800004, 21.106307140000077],
              [106.44009390700006, 21.103404495000078],
              [106.44361194900009, 21.099781362000058],
              [106.44633614300007, 21.098462943000037],
              [106.44728698200004, 21.097984366000055],
              [106.44787670900007, 21.097437670000119],
              [106.44876604000004, 21.096020920000043],
              [106.44891676600007, 21.095471140000122],
              [106.44887273000006, 21.094874090000104],
              [106.44863359900009, 21.094275672000059],
              [106.44795620300007, 21.093582379000075],
              [106.44402578500004, 21.090984175000038],
              [106.44334878400007, 21.090244962000071],
              [106.44281994800011, 21.089277268000025],
              [106.44058657700005, 21.084923629000052],
              [106.43910601700003, 21.081562932000075],
              [106.43887109400004, 21.080708027000036],
              [106.438853431, 21.079413025000093],
              [106.43974504300006, 21.078195935000018],
              [106.44431591300007, 21.075862617000048],
              [106.45078375900009, 21.07173589900011],
              [106.45291798600002, 21.070055669000084],
              [106.45334600100009, 21.069432933000073],
              [106.45343467800005, 21.066119574000105],
              [106.45479343000002, 21.065990921000029],
              [106.4588798410001, 21.066417641000079],
              [106.46662234200008, 21.068197008000041],
              [106.47070048200008, 21.069685327000087],
              [106.47267063100003, 21.070229878000113],
              [106.47577312500005, 21.070251281000104],
              [106.480997164, 21.069490821000066],
              [106.48539994500004, 21.068653364000056],
              [106.48563819600004, 21.068529924000053],
              [106.48905702500012, 21.066758606000043],
              [106.49175078700007, 21.064918698000042],
              [106.49444654800001, 21.062813298000016],
              [106.49644434600002, 21.059773957000068],
              [106.49759293200002, 21.057127030000089],
              [106.50597270800006, 21.059758813000059],
              [106.51425226500005, 21.061152263000068],
              [106.51520079500003, 21.06101000400011],
              [106.51583636900003, 21.060494064000054],
              [106.51632421600007, 21.058639457000083],
              [106.51737769900011, 21.055079385000042],
              [106.51738165700007, 21.054559201000075],
              [106.51699087400007, 21.054036369000073],
              [106.51542041300007, 21.052911096000045],
              [106.51424696400005, 21.05149121500007],
              [106.51346770600006, 21.05014828500007],
              [106.5129292610001, 21.048286753000056],
              [106.51309055100003, 21.047841941000023],
              [106.51364656700012, 21.047399782000028],
              [106.51459560700005, 21.047183206000049],
              [106.51569743800007, 21.047636502000074],
              [106.51718834800008, 21.04883557000008],
              [106.52104202200007, 21.050793598000084],
              [106.52238073300008, 21.05124845000006],
              [106.52324857500003, 21.051328558000115],
              [106.52372450700005, 21.05103447300003],
              [106.5248370690001, 21.050075789000019],
              [106.52618477600005, 21.049341607000116],
              [106.52784600400001, 21.048906777000106],
              [106.53029556200001, 21.048625807000043],
              [106.53210963400009, 21.048860799000032],
              [106.53344612000009, 21.049612835000083],
              [106.53446291700003, 21.050882948000016],
              [106.53560155200009, 21.05350187100008],
              [106.53803477200006, 21.053918443000086],
              [106.54138208500009, 21.0550693590001],
              [106.54383743500003, 21.05532901],
              [106.54694305400004, 21.055105968000106],
              [106.55018523100007, 21.053909881],
              [106.55199757900002, 21.053678286000022],
              [106.55380540600002, 21.054055320000131],
              [106.55573354800003, 21.055650490000041],
              [106.55766351300012, 21.057002182000062],
              [106.55998598900008, 21.057747731000021],
              [106.56334579400007, 21.058134794000068],
              [106.56993968500005, 21.058420999000035],
              [106.57317287300005, 21.058441883000079],
              [106.57511722800007, 21.057845740000047],
              [106.57784909000006, 21.055672082000051],
              [106.58189006800011, 21.051315524000103],
              [106.58488216300005, 21.048899954000078],
              [106.59345952700009, 21.043111252000109],
              [106.59800840500006, 21.039974937000046],
              [106.60152262100003, 21.036831961000054],
              [106.60439191900012, 21.033441368000091],
              [106.60556876700005, 21.031622681000073],
              [106.60623099000006, 21.029435520000028],
              [106.60676997800006, 21.026395374000018],
              [106.60768816000009, 21.024575033000033],
              [106.60963744300008, 21.022400104000027],
              [106.60985597800004, 21.022247934000049],
              [106.60597868600004, 21.020067123000082],
              [106.60238792000004, 21.018290186000051],
              [106.60012858700006, 21.017690612000095],
              [106.58868208100004, 21.016090557000041],
              [106.58807661100003, 21.015992731000033],
              [106.58747168000008, 21.015946651000036],
              [106.58582609899999, 21.016220685000064],
              [106.58368929300012, 21.016809703000035],
              [106.57974148100006, 21.017622483000054],
              [106.57825907400006, 21.017739718000072],
              [106.57743490100007, 21.017747369000084],
              [106.57677284500001, 21.017494819000056],
              [106.57621905200003, 21.017086042000074],
              [106.57543787100005, 21.01595504500002],
              [106.57442986900004, 21.014153551000092],
              [106.57353883400003, 21.013023558000029],
              [106.57254281700006, 21.012360173000033],
              [106.57155167700002, 21.012162382],
              [106.57045282000004, 21.012172521000046],
              [106.56941106600001, 21.012389091000024],
              [106.56853468600006, 21.012655871000071],
              [106.56793518400011, 21.013127030000064],
              [106.56723335100003, 21.014323473000026],
              [106.56654177700001, 21.016502852000052],
              [106.56573974700009, 21.018631493000051],
              [106.56492799200007, 21.019828940000096],
              [106.56438231400004, 21.020196132000102],
              [106.56394436500004, 21.020355375000044],
              [106.5633943660001, 21.020308693000089],
              [106.56306415600004, 21.020259987000053],
              [106.56273177800007, 21.020004354000044],
              [106.56250714200004, 21.019540774000056],
              [106.55612898800007, 21.008578993000079],
              [106.55369016300008, 21.004005767000052],
              [106.55315385000003, 21.000285290000065],
              [106.55304422500011, 20.997600823000084],
              [106.55303380200004, 20.997345545000101],
              [106.55361124000004, 20.995642040000106],
              [106.55433085600006, 20.993655497000049],
              [106.55590017100006, 20.991143485000038],
              [106.56037419800005, 20.985862394000065],
              [106.5618218970001, 20.984153457000062],
              [106.5637572210001, 20.981868832000124],
              [106.56482028100004, 20.981079213000072],
              [106.56623620300005, 20.98022550500005],
              [106.56842689900003, 20.979443178000068],
              [106.57224246300007, 20.978140324],
              [106.57969217200007, 20.976230201000057],
              [106.58152845300003, 20.975710975000084],
              [106.58287186300007, 20.975122194000036],
              [106.58351097200001, 20.974462520000081],
              [106.58379915100005, 20.973601452000068],
              [106.58381265000003, 20.971742972000037],
              [106.5832039260001, 20.968221036000045],
              [106.58301420800004, 20.965232802000067],
              [106.58309433100001, 20.963905751000041],
              [106.58352536800005, 20.962780075000076],
              [106.58466400900002, 20.961260662000122],
              [106.58771978500009, 20.957762117000108],
              [106.5901330730001, 20.955387875000042],
              [106.59063163500005, 20.95466088000002],
              [106.59085024000002, 20.953666575000085],
              [106.590650345, 20.952072188000059],
              [106.59024144600001, 20.950144564000084],
              [106.591049717, 20.94790138800002],
              [106.59294940700008, 20.946808908000044],
              [106.5889467960001, 20.947042353000086],
              [106.58799711700003, 20.946887016000026],
              [106.58713018400007, 20.946247076000098],
              [106.58618890700008, 20.944934940000067],
              [106.58385870900001, 20.941041148000039],
              [106.58068287500005, 20.935373006000098],
              [106.57908951200002, 20.933296489000064],
              [106.57646663, 20.932039840000094],
              [106.57383673000008, 20.931747385000051],
              [106.57163943800001, 20.932284193000058],
              [106.56928278900006, 20.934610731000092],
              [106.56883301100005, 20.936123075000076],
              [106.56925954800008, 20.937778846000029],
              [106.57362101100009, 20.941250802000063],
              [106.57629564300005, 20.943453347000052],
              [106.57691694400003, 20.945173967000038],
              [106.57654080200008, 20.947858404000016],
              [106.57560979900006, 20.949253072000126],
              [106.57310876600008, 20.951303151000019],
              [106.56973229700003, 20.953072045000098],
              [106.56780148000006, 20.953305562000054],
              [106.56666242100005, 20.95291441200002],
              [106.56606224900004, 20.95195018],
              [106.56612306300009, 20.946712247000043],
              [106.56632382400001, 20.941415749000036],
              [106.56565128600005, 20.938983278000123],
              [106.56401223100006, 20.936544516000041],
              [106.561969795, 20.93483875400004],
              [106.56022228200001, 20.933863114000047],
              [106.55796093800004, 20.933253159000031],
              [106.55588983800001, 20.933239633000063],
              [106.55451873000007, 20.933963634000108],
              [106.55407190200005, 20.935062718],
              [106.55417523400007, 20.940848924000122],
              [106.55423629600006, 20.945195932000082],
              [106.55417837500006, 20.946799824],
              [106.55375127800008, 20.948531371000101],
              [106.55310140800003, 20.949264210000074],
              [106.55231668400003, 20.94956258000007],
              [106.55144311000005, 20.949426780000024],
              [106.54947033700007, 20.948546671000123],
              [106.54735756900006, 20.947925774000083],
              [106.54625258000002, 20.94796186600005],
              [106.54508199600006, 20.948439534000052],
              [106.54475077000011, 20.948935505000051],
              [106.54282035600008, 20.947066362000029],
              [106.54199270800008, 20.945987433000113],
              [106.54139405800001, 20.944766891000107],
              [106.54102973700007, 20.942689106000053],
              [106.54084553100002, 20.939336391000026],
              [106.54131323100007, 20.9382874490001],
              [106.54143756400006, 20.936417281000082],
              [106.54111821900003, 20.934856013000044],
              [106.54025189100001, 20.932667464000062],
              [106.5402658940001, 20.930796559000072],
              [106.54051300100004, 20.927264055000094],
              [106.54063965800003, 20.925082037000031],
              [106.54042678100009, 20.924041179000064],
              [106.53966460300009, 20.922684846000017],
              [106.53736489500007, 20.920382816000057],
              [106.53441246800003, 20.918658992000069],
              [106.53297234600007, 20.91793262],
              [106.53122792000006, 20.917204214000051],
              [106.52887297700008, 20.916722617000083],
              [106.52736475500008, 20.916656987000067],
              [106.526689715, 20.916652486000054],
              [106.52589237200002, 20.916589344000066],
              [106.52534268400009, 20.916238754000105],
              [106.52522607500005, 20.91542848300012],
              [106.52523263300006, 20.914561210000038],
              [106.52550956700006, 20.910399926000075],
              [106.52564321800001, 20.90895528],
              [106.52607715200003, 20.908379962000097],
              [106.52799037100006, 20.90694716600003],
              [106.53230980600004, 20.903795696000046],
              [106.533789492, 20.902880364000076],
              [106.53637239300011, 20.90214580600005],
              [106.53754262800003, 20.90157532700001],
              [106.53809878700012, 20.901058612000064],
              [106.53835117700004, 20.900135120000037],
              [106.53854565200007, 20.898748670000074],
              [106.53827308300006, 20.894178871000065],
              [106.53786696200004, 20.891053735000042],
              [106.53751093900007, 20.889432327000087],
              [106.53751700800004, 20.888622831000077],
              [106.53801307400005, 20.887932239000051],
              [106.53943254600009, 20.886842978000075],
              [106.54035465500007, 20.886617778000094],
              [106.54283815200004, 20.886836562000077],
              [106.54455618900008, 20.886847888000126],
              [106.54566494400007, 20.886276956000046],
              [106.546657012, 20.884895710000073],
              [106.54914485700006, 20.880401795000033],
              [106.55002533800007, 20.877516360000037],
              [106.55003823000007, 20.87578170400004],
              [106.54967955500007, 20.874507205000015],
              [106.54665153900008, 20.869940215000057],
              [106.54595049200007, 20.868882828000054],
              [106.54494225000005, 20.869606313000055],
              [106.54278160500007, 20.869945793000078],
              [106.53949836300011, 20.869835671000111],
              [106.53612659600002, 20.869017474000096],
              [106.53425805200004, 20.867943898000085],
              [106.53313946900003, 20.866963732000073],
              [106.53277281099999, 20.865811683000054],
              [106.5326024510001, 20.863511317000061],
              [106.53224844000003, 20.860679137000112],
              [106.53188313900009, 20.859350218000117],
              [106.53012851500004, 20.855624371000125],
              [106.5286414130001, 20.8537573670001],
              [106.52649361200011, 20.852416541000096],
              [106.52377557400011, 20.852044655000057],
              [106.52227315800006, 20.852211474000093],
              [106.52151390800003, 20.853356032000114],
              [106.52150385800009, 20.854682460000085],
              [106.52158360900003, 20.856540092000039],
              [106.52296144800007, 20.860440357000073],
              [106.52304322300009, 20.862032685000109],
              [106.52256541100007, 20.863179111000107],
              [106.52180880100009, 20.863969931000078],
              [106.51870861100009, 20.864479751000076],
              [106.51072957700009, 20.864956692000099],
              [106.50753957600003, 20.864935159000098],
              [106.50454129100009, 20.864384277000077],
              [106.50304622600004, 20.863578266],
              [106.50184214900005, 20.861536158000057],
              [106.50093857500008, 20.857019960000045],
              [106.50029407200005, 20.855423789000049],
              [106.49889698400008, 20.854087794000087],
              [106.49777389300007, 20.85372642400003],
              [106.49673848500002, 20.85416154100006],
              [106.49626190100003, 20.855131067000073],
              [106.49605108900008, 20.858136362000046],
              [106.49565604300008, 20.860698213000092],
              [106.49479050300005, 20.863433719000035],
              [106.49279358100003, 20.86686894500005],
              [106.49090547200004, 20.868359392000087],
              [106.48959054500003, 20.868527266000036],
              [106.48696824500004, 20.867890303000088],
              [106.48532130500011, 20.867239554000072],
              [106.48354435800007, 20.868242282000082],
              [106.48244481600005, 20.869044331000097],
              [106.48126488100004, 20.870140217000085],
              [106.48079576900004, 20.870210592000049],
              [106.48001774200006, 20.869837241000063],
              [106.47916506800003, 20.869021772000096],
              [106.479011187, 20.868726314000071],
              [106.48088777700011, 20.865204261000066],
              [106.48014382000009, 20.86465989700006],
              [106.47958639500007, 20.86394860500009],
              [106.47959397500007, 20.86297590000008],
              [106.47963570800006, 20.862721212000054],
              [106.4752393790001, 20.859688268000106],
              [106.48062722200004, 20.852427348000013],
              [106.48199837300007, 20.851248119000054],
              [106.48276157900006, 20.850591738000013],
              [106.48407742000005, 20.849460036000075],
              [106.48981392600004, 20.846269016000107],
              [106.49274787500006, 20.844374786000053],
              [106.49415506400005, 20.842948676],
              [106.49480256900007, 20.84127808700007],
              [106.49481641000006, 20.839483537000092],
              [106.49420399100011, 20.836607916000013],
              [106.49321176800007, 20.83361005200004],
              [106.48931638900009, 20.828558346000037],
              [106.48654758600006, 20.825548250000026],
              [106.48617800300012, 20.824109963000033],
              [106.48631604600008, 20.822675152000066],
              [106.48771941800003, 20.821727596000095],
              [106.49000832700006, 20.821145025000121],
              [106.49191372900006, 20.820918759000051],
              [106.49723816600006, 20.821672937000102],
              [106.50306475900005, 20.823148275000044],
              [106.50585392300007, 20.823526113000042],
              [106.50889966700002, 20.823546702000058],
              [106.51118578600008, 20.823322832000102],
              [106.5130966630001, 20.822378537000041],
              [106.51386628900009, 20.821306906000103],
              [106.51400777000008, 20.819393509000058],
              [106.51402872000003, 20.816641765000021],
              [106.51366715100005, 20.814126725000023],
              [106.51266562700005, 20.812325259000097],
              [106.50915633600003, 20.806558441000078],
              [106.50775381000007, 20.804149991000109],
              [106.5065853020001, 20.80214335200008],
              [106.50563634200002, 20.802937056000111],
              [106.50463546900008, 20.804352726],
              [106.50210964800007, 20.80789169300008],
              [106.50125288100006, 20.808952704000113],
              [106.50082551900005, 20.809349857000115],
              [106.50006917500009, 20.809611425000085],
              [106.49898486700008, 20.809604056000097],
              [106.49724124600004, 20.809503299000106],
              [106.49502824300001, 20.809132614000077],
              [106.49128876400003, 20.80801804000005],
              [106.48754074100007, 20.804969720000074],
              [106.48499787400009, 20.802065619000039],
              [106.48406138400006, 20.801313988000025],
              [106.483374928, 20.801070311000025],
              [106.48436278700004, 20.800437811000094],
              [106.48567954900008, 20.79900004000006],
              [106.48858034600002, 20.795498067000025],
              [106.4919593270001, 20.790190766000102],
              [106.49455656300006, 20.785004952000108],
              [106.49611080400004, 20.77892354800008],
              [106.49613895800006, 20.775268511],
              [106.49547121000006, 20.773189278000089],
              [106.49345097900006, 20.77032830100007],
              [106.490733425, 20.765787690000082],
              [106.49056556200006, 20.764530410000056],
              [106.49102049800004, 20.763109908000033],
              [106.49236007000003, 20.762114154000066],
              [106.49426468800006, 20.761588771000028],
              [106.49616008300004, 20.761056866000068],
              [106.49381572100003, 20.758463653],
              [106.490100076, 20.754634141000047],
              [106.48828750400004, 20.753081970000054],
              [106.486085261, 20.752251703000098],
              [106.48253939600006, 20.751321603000058],
              [106.4801373990001, 20.751486216000089],
              [106.47859748100005, 20.751928484000089],
              [106.47531852400009, 20.753717349000041],
              [106.47319535200009, 20.755061292000043],
              [106.47204304000005, 20.75505331700009],
              [106.47089499400001, 20.754501914000031],
              [106.46937207000003, 20.752770442000035],
              [106.46643586200001, 20.747587272000075],
              [106.46340825100006, 20.72963200500002],
              [106.462783852, 20.727820222000034],
              [106.462657259, 20.727452903000106],
              [106.46180457100006, 20.725997701000054],
              [106.45980047100007, 20.724443887000099],
              [106.45778997499998, 20.723705207000087],
              [106.45357413400004, 20.722588761000083],
              [106.45126990600008, 20.722572599000074],
              [106.4357265180001, 20.721194841000035],
              [106.43447695400009, 20.721367140000076],
              [106.43350959300008, 20.722266066000117],
              [106.43252478800004, 20.725338759000067],
              [106.43173633700007, 20.727869354000106],
              [106.43037688000004, 20.729761831000104],
              [106.42747752800005, 20.732096220000074],
              [106.42419623300006, 20.734156100000064],
              [106.42005659300003, 20.735485156000074],
              [106.41559000500004, 20.736135630000071],
              [106.41368267400006, 20.73620368600011],
              [106.41203732900007, 20.736028334000075],
              [106.40739218900005, 20.734076259000034],
              [106.40507669000004, 20.733103138000075],
              [106.40253606400006, 20.732035371],
              [106.39995017400007, 20.730299712000111],
              [106.3990049070001, 20.729311748000079],
              [106.39806836800007, 20.727260989000037],
              [106.39753760400002, 20.725819048000105],
              [106.39652157200007, 20.725737895000051],
              [106.39559889200011, 20.725254362],
              [106.39392333100002, 20.724129587000029],
              [106.39091179900008, 20.721564590000071],
              [106.38831956200009, 20.719241011000108],
              [106.38446586200008, 20.71666975200008],
              [106.38321027900002, 20.715706897000082],
              [106.38212381800003, 20.714665806000099],
              [106.38091261300008, 20.712744627000035],
              [106.37993921800009, 20.712647813000018],
              [106.3739193120001, 20.712094269000133],
              [106.37102380300007, 20.710976648000035],
              [106.36780000600004, 20.709333028000017],
              [106.36284372800003, 20.70586737100006],
              [106.35921874700004, 20.703503544000057],
              [106.35748917900003, 20.702600920000066],
              [106.35473273700003, 20.701718929],
              [106.35256851300002, 20.701457488000052],
              [106.35020229300008, 20.701516301000119],
              [106.34744916700002, 20.702236826000053],
              [106.34605651000007, 20.702962251000123],
              [106.34439695400008, 20.704503278000082],
              [106.342507896, 20.707555168000091],
              [106.34005240300004, 20.710970688000074],
              [106.33882879100005, 20.712187904000068],
              [106.33717884400005, 20.71258427700009],
              [106.33544571000002, 20.712571208000071],
              [106.33319614300004, 20.712145423000017],
              [106.33043572100009, 20.710652849000063],
              [106.32622113000006, 20.706941642000039],
              [106.32243996700002, 20.703233638000107],
              [106.31986009300009, 20.700924655000016],
              [106.31571987100008, 20.698685489000113],
              [106.30832073900011, 20.694796955000072],
              [106.3073523680001, 20.694288006000058],
              [106.30244113500008, 20.691061479000084],
              [106.30079982700008, 20.690476497000084],
              [106.29958607200011, 20.690548910000039],
              [106.29819260000001, 20.691355806000011],
              [106.29443395500006, 20.695087910000076],
              [106.29286213500004, 20.696465729000032],
              [106.291819488, 20.696784707000049],
              [106.29078113000007, 20.69661313600006],
              [106.28879321500008, 20.696025393000106],
              [106.28248725500004, 20.693768773000087],
              [106.27911375500007, 20.693088375000102],
              [106.276774996, 20.692988353000082],
              [106.27486586200004, 20.693300482000062],
              [106.27295167200003, 20.694184908000054],
              [106.27110594300008, 20.695897709000107],
              [106.26976898800004, 20.69764514300001],
              [106.26670642400003, 20.702268346000082],
              [106.26573566900001, 20.70348346600008],
              [106.26491290500005, 20.704246882000099],
              [106.26390097500008, 20.704691792000048],
              [106.26255588900008, 20.704817060000025],
              [106.26154798000007, 20.704809120000078],
              [106.2600625310001, 20.704525688000075],
              [106.25702731500004, 20.703270379000038],
              [106.25208653099999, 20.700823227000065],
              [106.24743541800009, 20.697709339000077],
              [106.24702089100008, 20.696502009000106],
              [106.24788594700007, 20.69490348300009],
              [106.25031769100009, 20.69251469800006],
              [106.25260042400005, 20.690927384000091],
              [106.25530365500008, 20.689878513000082],
              [106.25927802500011, 20.689374775000054],
              [106.26410299900002, 20.688877661000049],
              [106.26637726700007, 20.688226620000094],
              [106.26738033800011, 20.687030447000062],
              [106.26765557300006, 20.685548698000034],
              [106.26568135700003, 20.685180783000057],
              [106.26330673100003, 20.685079079000076],
              [106.26189762700005, 20.685234025000021],
              [106.26006334100008, 20.686003393000085],
              [106.25763581200005, 20.686798083000021],
              [106.25589818500006, 20.687096185000087],
              [106.25368863800003, 20.687161726000092],
              [106.25120074000006, 20.687152168000097],
              [106.24797799000004, 20.687282488000044],
              [106.24709598600008, 20.687524547000088],
              [106.2466508840001, 20.688102161000096],
              [106.24393669200006, 20.693753275000049],
              [106.24026223600006, 20.697913968000044],
              [106.23638750000001, 20.700750239000065],
              [106.23150150600003, 20.703387174000021],
              [106.2272300540001, 20.705264359000083],
              [106.22539295600008, 20.706778748000062],
              [106.2233375980001, 20.710011672000036],
              [106.21630140700003, 20.726201962000076],
              [106.21567264500005, 20.728490550000075],
              [106.21564121300003, 20.73193078700006],
              [106.21701234800005, 20.737102573000051],
              [106.21699140900002, 20.739396036000102],
              [106.21488140800005, 20.748553425000082],
              [106.21385255700001, 20.750265295000055],
              [106.21287505000004, 20.751282605000121],
              [106.2083641450001, 20.752132125000067],
              [106.20264833400005, 20.752758657000037],
              [106.20116691000004, 20.753359090000068],
              [106.19653004499999, 20.75682922300004],
              [106.19557419800005, 20.758046417000031],
              [106.19119051700007, 20.766028684000034],
              [106.18907540600003, 20.765068943000117],
              [106.18510783200003, 20.764131156000062],
              [106.18154437300007, 20.763972513000084],
              [106.1796201220001, 20.764473906000092],
              [106.17823703000005, 20.76575563500009],
              [106.17587364800008, 20.769227590000035],
              [106.17058851200004, 20.777201232000039],
              [106.16557960100002, 20.784918332000046],
              [106.16447415700004, 20.785814311000102],
              [106.1629634330001, 20.786060368000037],
              [106.16214072000007, 20.786053519000092],
              [106.16022595, 20.785520322000117],
              [106.15913998500011, 20.784347475000061],
              [106.15901648300012, 20.784092013000063],
              [106.15819846500003, 20.782399969000039],
              [106.15724476800007, 20.781745451000063],
              [106.1553312660001, 20.781082909000048],
              [106.15272243000008, 20.781449026000054],
              [106.14941085000008, 20.783619577000053],
              [106.14629566000008, 20.786761547000054],
              [106.14176650400006, 20.793262275000046],
              [106.14160698800001, 20.793491218000071],
              [106.13906691700006, 20.79704544800007],
              [106.13614778200012, 20.801158660000048],
              [106.13379187700006, 20.803724899000109],
              [106.13116412900007, 20.806030188000065],
              [106.12964081700005, 20.807568952000018],
              [106.12962842800002, 20.808861911000065],
              [106.13112699100002, 20.809909040000015],
              [106.13345456000005, 20.810316647000079],
              [106.13591804400004, 20.810854687000102],
              [106.13687058700006, 20.811638550000033],
              [106.13754516200001, 20.812807986000067],
              [106.13887954200001, 20.816698371000086],
              [106.13886351500003, 20.818379183000069],
              [106.13871403900001, 20.819670964000103],
              [106.13801353100004, 20.821216697000096],
              [106.13635301500005, 20.822754314000044],
              [106.13137624300003, 20.826849931000076],
              [106.12736799200003, 20.830048521000052],
              [106.12687132800006, 20.83096415000005],
              [106.12350834800007, 20.82990681100005],
              [106.12289147600011, 20.831323297000012],
              [106.12297651400003, 20.832935316000075],
              [106.1233658940001, 20.834265563000095],
              [106.12611903900007, 20.840734043000083],
              [106.12739331100008, 20.844156938000054],
              [106.12748384800003, 20.845200290000051],
              [106.12737603199999, 20.845957610000099],
              [106.12686695700006, 20.846616753000099],
              [106.12574550699999, 20.84821848500011],
              [106.12543659600001, 20.848974093000017],
              [106.12543022300011, 20.849637496000106],
              [106.12622820800004, 20.85030772500005],
              [106.12732964900006, 20.850790959000065],
              [106.12963491700002, 20.851568723000113],
              [106.13274729100004, 20.852068960000025],
              [106.13602040300006, 20.85220046400007],
              [106.13768537300007, 20.855607595000066],
              [106.13923997500009, 20.858012673000111],
              [106.13976769900006, 20.86021514100009],
              [106.14014839900003, 20.863450712000109],
              [106.14036483300008, 20.869529334000077],
              [106.14112877600004, 20.87574179300011],
              [106.14232770000004, 20.879501324000046],
              [106.14354759200008, 20.881063070000039],
              [106.14490344700008, 20.882755244000073],
              [106.145156926, 20.884955298000115],
              [106.14511759300004, 20.889092224000102],
              [106.14454916500009, 20.891156076000073],
              [106.14302774600006, 20.892436173000114],
              [106.13697154000008, 20.894453787000074],
              [106.13574262800009, 20.89997183100003],
              [106.13565167100002, 20.900301994000081],
              [106.135029919, 20.902558830000117],
              [106.13487819100006, 20.904220342000102],
              [106.13548031100005, 20.905594334000099],
              [106.14159055800006, 20.911208033000058],
              [106.14391337800005, 20.913436071000021],
              [106.14604712600003, 20.914817136000075],
              [106.1478825460001, 20.91600507400004],
              [106.14823304700006, 20.916199525000053],
              [106.15012799100001, 20.917250824000085],
              [106.15329101200005, 20.918505836000101],
              [106.15165698200012, 20.924495899000114],
              [106.1482742700001, 20.923639364000032],
              [106.14750058700012, 20.925762564000117],
              [106.14696905600002, 20.928834310000077],
              [106.14719886400012, 20.931084224000074],
              [106.148145351, 20.934532760000124],
              [106.14840377400006, 20.940019579000065],
              [106.14821900600006, 20.942687522000099],
              [106.14821001900002, 20.943633949000073],
              [106.14844547500005, 20.945292289000058],
              [106.14981574000009, 20.946486888000045],
              [106.15231747200005, 20.947572624000088],
              [106.154942578, 20.948895983000071],
              [106.15569053899999, 20.949493789000059],
              [106.15593723300006, 20.949969103000043],
              [106.15592828300004, 20.950915519000098],
              [106.15554702200011, 20.951385573],
              [106.15415541100008, 20.952438758000099],
              [106.151748927, 20.954548229000025],
              [106.14887296200007, 20.957307814],
              [106.14832638600006, 20.957832258000053],
              [106.14630106500007, 20.959471616000066],
              [106.14415912500004, 20.960163488000113],
              [106.14114022300002, 20.960611355000047],
              [106.137749032, 20.96058280500008],
              [106.13415411100002, 20.960851038000072],
              [106.13347524300006, 20.960901684000085],
              [106.13140782000006, 20.966917953000049],
              [106.13096568300008, 20.971636391000104],
              [106.13315579100001, 20.972410480000022],
              [106.13609924800002, 20.975517123000117],
              [106.13356224600007, 20.978057007000039],
              [106.13217146000001, 20.978991698000101],
              [106.13002925500004, 20.97968342000005],
              [106.12663091400006, 20.980364478000091],
              [106.12536795700001, 20.981063604000028],
              [106.12498202200005, 20.982006765000079],
              [106.1249729200001, 20.982953130000048],
              [106.12522910500007, 20.984826623000075],
              [106.12580591600002, 20.987366308000063],
              [106.12735896700008, 20.992822160000024],
              [106.12859714000004, 20.998008386000052],
              [106.12945721300005, 21.000026796000078],
              [106.13020413700004, 21.000742917000089],
              [106.13132910400003, 21.001343942000084],
              [106.13358357800006, 21.002072788000078],
              [106.13521671600009, 21.002086571000063],
              [106.13622286200003, 21.001976759000037],
              [106.13670571600001, 21.001604723000078],
              [106.14077926400009, 20.998466083],
              [106.14381790000006, 20.996007274000036],
              [106.14559350000007, 20.994247640000104],
              [106.14826525800007, 20.990720949000071],
              [106.14928032300003, 20.989664721000075],
              [106.15091672300007, 20.989323509000087],
              [106.15305336600002, 20.989223064000036],
              [106.15430618700003, 20.989588435000073],
              [106.15580465800011, 20.990547371000012],
              [106.15680737900003, 20.99079234200012],
              [106.15756109500003, 20.990798626000107],
              [106.159574353, 20.990460491000107],
              [106.1637298110001, 20.989430322000047],
              [106.16549626700007, 20.988616858000064],
              [106.16638338300001, 20.9877960950001],
              [106.16714821000005, 20.986619396],
              [106.16715599400007, 20.985791335000044],
              [106.16679248900002, 20.984368665000105],
              [106.16543298500007, 20.981991296000047],
              [106.16307752900003, 20.978659187000034],
              [106.16092886100007, 20.976046497000041],
              [106.16147205600006, 20.975719280000057],
              [106.1648076490001, 20.972632953000044],
              [106.16594197200007, 20.969306098000025],
              [106.16818742100006, 20.971602187000116],
              [106.17195098900007, 20.975450443000021],
              [106.17678447300004, 20.977585355000095],
              [106.1824304890001, 20.979345937000105],
              [106.18950272700005, 20.979975334000045],
              [106.19515244300005, 20.98135470600004],
              [106.20785015200003, 20.986028852000068],
              [106.21893065500007, 20.99068920200002],
              [106.22536553800003, 20.994740373000106],
              [106.22939979600005, 20.995915408000073],
              [106.23283776100001, 20.99594291800004],
              [106.23791080800001, 20.994078945000091],
              [106.24401034000003, 20.990508966000029],
              [106.24643707600009, 20.990528230000024],
              [106.25429667300007, 20.993637608000078],
              [106.25591452400008, 20.993650378000069],
              [106.26178431300009, 20.993125274000057],
              [106.27119910700006, 20.991851608000012],
              [106.27070089400004, 20.993220862000058],
              [106.269908098, 20.99666011200005],
              [106.26987370000009, 21.000535949000017],
              [106.27106656100001, 21.003560025000034],
              [106.27347908300005, 21.006593599000034],
              [106.27680640700002, 21.009634254000083],
              [106.28195827400006, 21.013263267000056],
              [106.28862865100005, 21.017621666000075],
              [106.29256751800008, 21.020523212000072],
              [106.29361465700003, 21.022828156000095],
              [106.29358826600007, 21.025842573000105],
              [106.29217455100007, 21.030568890000012],
              [106.29143714700008, 21.034467357000075],
              [106.29141306500004, 21.037214339000073],
              [106.29192402400004, 21.039134711000074],
              [106.29231198300008, 21.040592793000101],
              [106.29493089400005, 21.044483973000091],
              [106.2988752700001, 21.048510167000067],
              [106.30333550900009, 21.052777886000037],
              [106.30597945300011, 21.055307575000093],
              [106.30915536300006, 21.05870505700009],
              [106.30962043500008, 21.059728281000019],
              [106.30948078700007, 21.060656209000058],
              [106.30834414900009, 21.062458862000085],
              [106.30521956800001, 21.066414829000024],
              [106.30360797300008, 21.069127903000059],
              [106.302930696, 21.070268080000076],
              [106.30157254400007, 21.073878709000034],
              [106.30072539400005, 21.079740766000029],
              [106.30068074700009, 21.084859748],
              [106.30010270000004, 21.088977341000081],
              [106.29927762600008, 21.0907798960001],
              [106.2978943250001, 21.095605911000078],
              [106.2953683170001, 21.102546003],
              [106.29422547000004, 21.106364903000063],
              [106.29399424700009, 21.108186152000115],
              [106.29369585000009, 21.110536482000072],
              [106.293915967, 21.113553912000071],
              [106.29522824800006, 21.118551561000039],
              [106.29741229400001, 21.122746178000092],
              [106.29862915700006, 21.124377133000038],
              [106.30267091000009, 21.127423838000055],
              [106.30819615200001, 21.130017856000016],
              [106.31077491300005, 21.131197419],
              [106.31310011700005, 21.133186915000032],
              [106.31712223700004, 21.1385528590001],
              [106.31966615200011, 21.143791467000113],
              [106.32110058600006, 21.14902159300005],
              [106.32107359100004, 21.152152903000051],
              [106.32067371500008, 21.155629320000102],
              [106.31934560500005, 21.159504589000022],
              [106.31746610300007, 21.162969715000109],
              [106.3127164640001, 21.17035621400003],
              [106.30996051400007, 21.175322227000045],
              [106.30781392000009, 21.181104666000024],
              [106.30741878900008, 21.184001040000041],
              [106.30754797500003, 21.186872414000035],
              [106.30832432100004, 21.189777724000081],
              [106.30910824500008, 21.191813261000014],
              [106.31020500200005, 21.193329296000037],
              [106.31129572500005, 21.194510737000094],
              [106.31309013800002, 21.194696763000117],
              [106.31457536500008, 21.194435163000058],
              [106.31727012500002, 21.193640284000104],
              [106.31978653900003, 21.193414784000097],
              [106.32143383800009, 21.193427282000066],
              [106.32221663300002, 21.193519908],
              [106.32313896600004, 21.192496037000105],
              [106.32314651100005, 21.191619979],
              [106.32297075900007, 21.190392089000021],
              [106.32206183300009, 21.187756850000113],
              [106.322069384, 21.186880785000106],
              [106.32226324500006, 21.186006140000032],
              [106.3245170640001, 21.18392051500004],
              [106.3299786270001, 21.177127988000066],
              [106.33167185800005, 21.175213259000088],
              [106.33410575400008, 21.173829770000019],
              [106.34178728700007, 21.168805830000089],
              [106.34384993400002, 21.167244206000113],
              [106.34515547700005, 21.1670787400001],
              [106.34589617600004, 21.16760997300009],
              [106.34588873300005, 21.168486059000102],
              [106.34531794700007, 21.169883627],
              [106.34249667000006, 21.173016627000059],
              [106.34248921200006, 21.173892713000072],
              [106.34304363900002, 21.174422546000038],
              [106.34397513100008, 21.174429518000082],
              [106.34677111400005, 21.174275190000102],
              [106.35664942500009, 21.173823077000087],
              [106.36279581800007, 21.174043859000058],
              [106.36409697500008, 21.174403931000093],
              [106.36446368800004, 21.17510755200005],
              [106.36427149900004, 21.175807041000013],
              [106.36332231700001, 21.177902746000044],
              [106.36312275600002, 21.17947830100011],
              [106.36330316900003, 21.180180539000034],
              [106.36440922000007, 21.181590520000078],
              [106.36607274300005, 21.183179827000039],
              [106.368100102, 21.185823150000083],
              [106.36882920000002, 21.187755985000045],
              [106.37230029500007, 21.196016976000109],
              [106.37303971300005, 21.196723296000108],
              [106.37396839600007, 21.197080556000088],
              [106.37639057500012, 21.197098336000053],
              [106.37806892500006, 21.196935426000074],
              [106.38067888200001, 21.196779311000036],
              [106.382914736, 21.196795656000056],
              [106.38588425700003, 21.198219093000056],
              [106.38884657199999, 21.200518527000064],
              [106.39124997300007, 21.202813859000088],
              [106.39254268400001, 21.204224991000054],
              [106.39551672100006, 21.205122661000075],
              [106.39979366100009, 21.206204931000094],
              [106.40425840900011, 21.207113250000106],
              [106.4089095300001, 21.208022808000045],
              [106.41057936500006, 21.208910891000073],
              [106.41187226700001, 21.210321904000025],
              [106.41241840200007, 21.21190276100009],
              [106.41331581100007, 21.216114365000038],
              [106.41457880300004, 21.221204613000033],
              [106.41567406000007, 21.224015850000022],
              [106.41732981600011, 21.226655870000052],
              [106.41954610000008, 21.229124654000131],
              [106.42157892600005, 21.231241681000057],
              [106.42306267000006, 21.23212830600005],
              [106.42473703000005, 21.232490649000013],
              [106.42697329800006, 21.232506548000082],
              [106.42846556400008, 21.23234193300005],
              [106.43126936300008, 21.231310560000068],
              [106.43333196400003, 21.229748269000126],
              [106.43501054800001, 21.229584928000023],
              [106.43706043000006, 21.229599407000066],
              [106.44022703000006, 21.229796951000068],
              [106.44264261299999, 21.230690009000064],
              [106.44486626300004, 21.232282521000023],
              [106.44988949499999, 21.233369007000036],
              [106.45249709700001, 21.233562450000051],
              [106.45398934100011, 21.233397672000095],
              [106.45530357700005, 21.232180381000099],
              [106.45699466399999, 21.230440068000078],
              [106.46073420900005, 21.228889211000102],
              [106.46260189300004, 21.228376557000047],
              [106.46577125200008, 21.228223329000073],
              [106.47545469300007, 21.229166264000106],
              [106.481412522, 21.229908049000109],
              [106.48364194000007, 21.230799381000054],
              [106.48512050000002, 21.232386392000087],
              [106.48546322000004, 21.236243318000092],
              [106.48564003900002, 21.237470991000059],
              [106.48656639700002, 21.238178146000038],
              [106.48731183700005, 21.238183240000104],
              [106.48843273200006, 21.237840484000081],
              [106.48918906000003, 21.236443982000083],
              [106.48920946000004, 21.233815995000072],
              [106.48960120100003, 21.231365741000019],
              [106.49110291300011, 21.229974301000084],
              [106.49315687200006, 21.229462661000028],
              [106.49539581900004, 21.229127471000098],
              [106.49707975700001, 21.228262853000054],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 27, code: "AD01", ten_tinh: "Hải Phòng" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.72799526100006, 20.128074562000094],
              [107.71874862800007, 20.12806292400002],
              [107.71579649300006, 20.128804429000091],
              [107.71461444500005, 20.129920853],
              [107.71500602200003, 20.131225638000046],
              [107.71814823700004, 20.135142515000034],
              [107.72659965400005, 20.141301863000116],
              [107.73171031700005, 20.144848285000094],
              [107.73485736800002, 20.145597397000081],
              [107.73702291900005, 20.144668385000038],
              [107.73722341600003, 20.141873789],
              [107.73506545000001, 20.13721311700003],
              [107.73172816400002, 20.131805660000076],
              [107.73015783000002, 20.129195185000093],
              [107.72799526100006, 20.128074562000094],
            ],
          ],
          [
            [
              [107.14969245900009, 20.614203798000027],
              [107.14885406700009, 20.613936365000107],
              [107.14654445100004, 20.614125588000043],
              [107.14528369900009, 20.614450949000052],
              [107.14458233700005, 20.614844530000106],
              [107.1441599660001, 20.615437341000039],
              [107.14436580200007, 20.616362833000053],
              [107.14478211500006, 20.617157046000045],
              [107.14533979400002, 20.617621554000017],
              [107.14617761900006, 20.618021098000035],
              [107.14694693100003, 20.618090131000066],
              [107.14813889700007, 20.617500292000024],
              [107.149474528, 20.616052354000125],
              [107.14989860800009, 20.615063240000083],
              [107.14969245900009, 20.614203798000027],
            ],
          ],
          [
            [
              [107.20663180500001, 20.614028383000083],
              [107.20589629700001, 20.614025735000041],
              [107.20473483600009, 20.614058098000051],
              [107.20295205800002, 20.614563299000018],
              [107.20105030200006, 20.615762432000025],
              [107.20031164800005, 20.616527219000069],
              [107.19996146000003, 20.616964498000101],
              [107.19995951500005, 20.61743958800006],
              [107.20088499900005, 20.618320042000079],
              [107.20184995200005, 20.619017911000093],
              [107.20219790100006, 20.619128805000031],
              [107.20289560900005, 20.618912050000034],
              [107.20487451400001, 20.617786281000058],
              [107.20681704600007, 20.616075619000092],
              [107.20751770100004, 20.615127951000112],
              [107.20740364600002, 20.614615894000018],
              [107.20713399800009, 20.614286009000082],
              [107.20663180500001, 20.614028383000083],
            ],
          ],
          [
            [
              [107.14002404800002, 20.617270643000026],
              [107.139395834, 20.616937940000035],
              [107.13827409500009, 20.61746196000005],
              [107.13602943200007, 20.618774165000083],
              [107.13434328800001, 20.620352742000044],
              [107.13427097700007, 20.620880848000041],
              [107.13447941500009, 20.621211916000078],
              [107.13517730300001, 20.621610951000058],
              [107.13643608600006, 20.621747977000027],
              [107.13895568300008, 20.621559682000075],
              [107.14007715300005, 20.621101715000108],
              [107.14099017800001, 20.620312681000108],
              [107.14120297000004, 20.619653017000061],
              [107.14064935200005, 20.61826382700005],
              [107.14002404800002, 20.617270643000026],
            ],
          ],
          [
            [
              [107.15847696000003, 20.621370851000087],
              [107.15756770100003, 20.621301333000105],
              [107.15595537700003, 20.622021704000112],
              [107.15469259000008, 20.622809458000098],
              [107.1530782400001, 20.623992149000081],
              [107.15230116800011, 20.625706431000076],
              [107.15201413600008, 20.627356546],
              [107.15249702900006, 20.628943562000046],
              [107.15354053600007, 20.630334589000121],
              [107.15444868300007, 20.630668309000022],
              [107.15514865400007, 20.630604947000016],
              [107.156411205, 20.629883253000081],
              [107.15893942500003, 20.627713306000125],
              [107.15971360700004, 20.626659480000065],
              [107.15999914400004, 20.625339596000103],
              [107.15972553400005, 20.623885478000133],
              [107.15924259500005, 20.622298456000095],
              [107.15847696000003, 20.621370851000087],
            ],
          ],
          [
            [
              [106.81560830500008, 20.666575348000023],
              [106.81486748800008, 20.666478071000057],
              [106.813531299, 20.666750772000064],
              [106.81229254600001, 20.667257207000134],
              [106.81174517800011, 20.667860658000059],
              [106.81159349600009, 20.668419599000032],
              [106.81163922700004, 20.669026244000044],
              [106.81203124000007, 20.669588104000077],
              [106.81242494600004, 20.669870107000051],
              [106.81380746300003, 20.670110772000072],
              [106.815093113, 20.670024394000031],
              [106.815737074, 20.669794618000111],
              [106.81623445000011, 20.669284179000066],
              [106.81673266100003, 20.668633815000078],
              [106.81683461700004, 20.668121252000034],
              [106.81659229300006, 20.667326967000044],
              [106.81624858000004, 20.666951949000101],
              [106.81560830500008, 20.666575348000023],
            ],
          ],
          [
            [
              [107.07461151700005, 20.70381599200006],
              [107.07241311000006, 20.702916257000048],
              [107.07193951100004, 20.702921956000075],
              [107.07166955000008, 20.703165332000047],
              [107.07165646200006, 20.703542850000112],
              [107.07192033200005, 20.704191446000038],
              [107.07237149000008, 20.705215143000096],
              [107.07272130900006, 20.705485374000077],
              [107.07310476, 20.705549363000038],
              [107.0735419370001, 20.70554409700005],
              [107.07441533700008, 20.705464962000072],
              [107.07508511200007, 20.705148158000064],
              [107.07513228400006, 20.704598722000078],
              [107.0750897840001, 20.704153275000081],
              [107.07461151700005, 20.70381599200006],
            ],
          ],
          [
            [
              [107.04118400400003, 20.703514906000109],
              [107.04053842200008, 20.703207500000076],
              [107.03978299900011, 20.70330574700003],
              [107.03870253800007, 20.70370717400003],
              [107.03762058100007, 20.70441319800009],
              [107.03664595400008, 20.705221227000067],
              [107.03567031500009, 20.706232303000057],
              [107.03458681900005, 20.707242910000012],
              [107.03371850600007, 20.708355975000089],
              [107.03349678000011, 20.7095734110001],
              [107.033493263, 20.710284130000019],
              [107.03392165000008, 20.710895208000046],
              [107.03424369500009, 20.711201223000053],
              [107.03488979600007, 20.711407114000032],
              [107.036077156, 20.711209241000091],
              [107.03737486600008, 20.710504164000021],
              [107.03878240500009, 20.709393437000045],
              [107.04030377400004, 20.707064777000106],
              [107.04139172500008, 20.705140356000037],
              [107.04150405600005, 20.704227038000056],
              [107.04118400400003, 20.703514906000109],
            ],
          ],
          [
            [
              [107.02174664200001, 20.710132671000011],
              [107.02033462400006, 20.710126406000114],
              [107.01846889900006, 20.71049033000012],
              [107.01728065600005, 20.710910425000058],
              [107.01665460300003, 20.711797153000063],
              [107.01631244700006, 20.712501290000063],
              [107.01591332500007, 20.713243920000046],
              [107.01534180900003, 20.714570689000041],
              [107.015112668, 20.71520773499999],
              [107.01522375400005, 20.715580434000103],
              [107.01550375700003, 20.716060234000096],
              [107.01623561300001, 20.716542049000083],
              [107.01719394400007, 20.716918532000058],
              [107.01820982900003, 20.717082571000041],
              [107.0191703030001, 20.717033673000081],
              [107.01973672900006, 20.716717148],
              [107.020022614, 20.716027182000026],
              [107.02053708800003, 20.714806499000105],
              [107.02122019800005, 20.713746088000029],
              [107.02218544600007, 20.712740084000103],
              [107.02309260800008, 20.712052861000082],
              [107.023489829, 20.711682405000076],
              [107.0234922220001, 20.71120386200009],
              [107.02287439100006, 20.710509884000047],
              [107.02174664200001, 20.710132671000011],
            ],
          ],
          [
            [
              [107.06551696300002, 20.712046617000098],
              [107.064223236, 20.711939602000129],
              [107.06217066300009, 20.712641619000038],
              [107.06162847899999, 20.713248512000071],
              [107.06140936000007, 20.713958311000091],
              [107.06140545700001, 20.714770564000062],
              [107.06150940700009, 20.71558326500007],
              [107.06268944700003, 20.716908205],
              [107.06344198900003, 20.717419055000086],
              [107.06398126000006, 20.717421343000098],
              [107.06506223100007, 20.71691827100004],
              [107.06592894600001, 20.716109684000102],
              [107.0663647280001, 20.715197730000057],
              [107.06637394900005, 20.713268644000046],
              [107.06605476300003, 20.712353499000088],
              [107.06551696300002, 20.712046617000098],
            ],
          ],
          [
            [
              [107.01277701000001, 20.719132043000066],
              [107.0119311130001, 20.718862393000045],
              [107.01108359300005, 20.718911775000045],
              [107.00978231900004, 20.719331309000033],
              [107.0087042800001, 20.720283573000039],
              [107.008189988, 20.721451041000094],
              [107.00824107100006, 20.722514710000084],
              [107.00863241400002, 20.723314045000052],
              [107.00908186300008, 20.723794609000024],
              [107.00981562800004, 20.723904249000036],
              [107.01083342900006, 20.723696122000042],
              [107.01213579600008, 20.723063896000049],
              [107.01298924499999, 20.721844761000071],
              [107.01333539500003, 20.720410669000074],
              [107.01333888700006, 20.719719452000085],
              [107.01277701000001, 20.719132043000066],
            ],
          ],
          [
            [
              [107.06278658300008, 20.719142323000113],
              [107.061923752, 20.71913865700008],
              [107.06116583800008, 20.719744626000121],
              [107.06029566800002, 20.721263897000064],
              [107.06028492100003, 20.723497552000048],
              [107.06038887100001, 20.724310243000055],
              [107.06081688500008, 20.725022783000036],
              [107.06146354700012, 20.725127064000048],
              [107.06254359200004, 20.724827066],
              [107.0640589490001, 20.723716652000057],
              [107.06482027200012, 20.722399971000094],
              [107.06471825000003, 20.721181157000053],
              [107.06386172700003, 20.719857611000059],
              [107.06278658300008, 20.719142323000113],
            ],
          ],
          [
            [
              [107.07958326000001, 20.725304920000021],
              [107.07893658800006, 20.725200692],
              [107.07817966200005, 20.725603659000043],
              [107.07655314100003, 20.727424407000058],
              [107.07546925800006, 20.728536708000078],
              [107.07503348900006, 20.729448653000034],
              [107.07492081900006, 20.730463488000069],
              [107.07513317600005, 20.731175089000061],
              [107.07577602300003, 20.732091550000042],
              [107.07706653100006, 20.7329091880001],
              [107.07803681600004, 20.733014782000048],
              [107.07890211200005, 20.732510744000088],
              [107.07955455100003, 20.731396633000095],
              [107.07999556400001, 20.729367868000011],
              [107.08022180500009, 20.727135141000105],
              [107.08011968400007, 20.725916344000069],
              [107.07958326000001, 20.725304920000021],
            ],
          ],
          [
            [
              [107.06555049600004, 20.72758115500006],
              [107.06425812600006, 20.727169552000113],
              [107.0626387710001, 20.727467267000051],
              [107.06037079800002, 20.728066806000101],
              [107.0598275880001, 20.728876744000054],
              [107.05971483900002, 20.729891563000088],
              [107.05959573400003, 20.732226255000086],
              [107.05991052400005, 20.734055127000062],
              [107.06044739800006, 20.734565064000066],
              [107.06130786600002, 20.735076376000094],
              [107.06281746400005, 20.735184320000052],
              [107.06411085800003, 20.735392866000076],
              [107.06583474000003, 20.735806291000088],
              [107.06766649300008, 20.736220150000051],
              [107.06842299800005, 20.735918761000072],
              [107.06842590400002, 20.735309596000029],
              [107.06692162900008, 20.734084884000126],
              [107.06563066900003, 20.733368714000022],
              [107.06487951800004, 20.732553295000052],
              [107.06456079400004, 20.731536639000048],
              [107.06456614300005, 20.730419836000102],
              [107.065217198, 20.729610354],
              [107.06586727700005, 20.729003924000011],
              [107.06608591100003, 20.728395665000043],
              [107.06555049600004, 20.72758115500006],
            ],
          ],
          [
            [
              [107.08262453000003, 20.73237288200005],
              [107.08199482700006, 20.732370264000096],
              [107.0812363910001, 20.73296113400005],
              [107.08003379400007, 20.734262980000025],
              [107.07857844900009, 20.73574197],
              [107.07781774500003, 20.736808034000106],
              [107.07781494000007, 20.737402045000053],
              [107.07825322400001, 20.737938496000119],
              [107.07881801200003, 20.73835667000008],
              [107.07950958600004, 20.738597164000083],
              [107.08051714700009, 20.738601364000075],
              [107.08190532900002, 20.738013123000073],
              [107.08329602200007, 20.736890262000074],
              [107.0839318700001, 20.735586051000077],
              [107.08418681500007, 20.734933682000097],
              [107.08425285000001, 20.73428052900006],
              [107.08400403400003, 20.733626068000085],
              [107.08369196300006, 20.733030747000036],
              [107.08325339700011, 20.73255370400009],
              [107.08262453000003, 20.73237288200005],
            ],
          ],
          [
            [
              [107.08412023000005, 20.746848024000109],
              [107.08315034900001, 20.746640944000113],
              [107.08109842500005, 20.747140041000108],
              [107.07904553300001, 20.747842176000027],
              [107.077422192, 20.748952200000033],
              [107.07622936200006, 20.750267064000113],
              [107.07579210600004, 20.75148355900005],
              [107.07546512300007, 20.75219287700012],
              [107.07492288000006, 20.752799766000059],
              [107.07416632000007, 20.75310116900004],
              [107.07254724800005, 20.753297431000114],
              [107.07092865600006, 20.753392148000046],
              [107.070171121, 20.753896584000039],
              [107.06919347700003, 20.755313840000063],
              [107.0686458700001, 20.757037470000036],
              [107.06864199600005, 20.757849659],
              [107.06895884800007, 20.759272356000032],
              [107.06949436400005, 20.760086826000098],
              [107.07003278500011, 20.760292144000033],
              [107.07165288300004, 20.75989285900004],
              [107.07359901500003, 20.758987309000076],
              [107.07543676200004, 20.758182810000037],
              [107.07641005300009, 20.757679254000024],
              [107.07836093200004, 20.755758413000081],
              [107.07977144500003, 20.754038356000045],
              [107.08075043900004, 20.75231648000004],
              [107.08129601600007, 20.750998902000092],
              [107.08205494300006, 20.750189846000033],
              [107.08389496300006, 20.748877638000081],
              [107.08454647600007, 20.747966598000019],
              [107.08454933100003, 20.747357444000066],
              [107.08412023000005, 20.746848024000109],
            ],
          ],
          [
            [
              [107.05840010900005, 20.756486363000093],
              [107.05643219400011, 20.756301495000073],
              [107.05615818600009, 20.756331104000125],
              [107.05494956200009, 20.756167040000051],
              [107.05419345000006, 20.75636685100006],
              [107.05337261100009, 20.757380055000048],
              [107.05289008900004, 20.758188732000079],
              [107.05245217800004, 20.759506688000059],
              [107.05276891500006, 20.760929404000095],
              [107.05330535200002, 20.761540859000057],
              [107.05406049200008, 20.761544097000062],
              [107.0553565070001, 20.761245076000101],
              [107.05708550100007, 20.760643316000049],
              [107.06162515799998, 20.7588352000001],
              [107.06205959200005, 20.758227891000033],
              [107.06184725300007, 20.757516308000042],
              [107.06109358400005, 20.75720852000007],
              [107.05840010900005, 20.756486363000093],
            ],
          ],
          [
            [
              [107.06744323300006, 20.760382742000068],
              [107.06679596600007, 20.76038000600002],
              [107.06539258200004, 20.760577116000078],
              [107.06280108500002, 20.761073756000044],
              [107.06010315200008, 20.761265340000087],
              [107.05902143400002, 20.761869875000102],
              [107.05847861300005, 20.762578236000095],
              [107.05869045600008, 20.763391348000049],
              [107.05944316700001, 20.763902175000034],
              [107.06008800200007, 20.764412548000074],
              [107.06138061100009, 20.764824152000116],
              [107.06332391700003, 20.76452782800002],
              [107.066131705, 20.763930576000078],
              [107.06732177700006, 20.763224934000043],
              [107.06840541500002, 20.762214264000086],
              [107.06851668400007, 20.761504057000124],
              [107.06808856200009, 20.760791573000056],
              [107.06744323300006, 20.760382742000068],
            ],
          ],
          [
            [
              [107.08586156800007, 20.757432129000094],
              [107.08499855800008, 20.757428552000064],
              [107.08413459500009, 20.757628018000048],
              [107.08283483300002, 20.758739407000078],
              [107.08120664700004, 20.760864670000068],
              [107.08000994200006, 20.762991713000112],
              [107.07870485200007, 20.765219823000081],
              [107.07859361700011, 20.765930031000046],
              [107.078806984, 20.76643853700002],
              [107.07945283600006, 20.766745810000131],
              [107.08031732200008, 20.766444835000094],
              [107.08183386500006, 20.765131329000042],
              [107.08356757700007, 20.76351414100008],
              [107.08465018100003, 20.762706435000041],
              [107.0867098480001, 20.760582939000116],
              [107.08703917800008, 20.759365994000056],
              [107.08704297600003, 20.758553806000087],
              [107.08639951400008, 20.75773893000008],
              [107.08586156800007, 20.757432129000094],
            ],
          ],
          [
            [
              [107.07417957200002, 20.765499431000123],
              [107.07256473700008, 20.764781979000091],
              [107.07180861000003, 20.764981847000016],
              [107.07126341300005, 20.766197851000086],
              [107.07136743100004, 20.767010483],
              [107.07233113000011, 20.768537397000038],
              [107.07372734000008, 20.769863074000035],
              [107.07480472700004, 20.770172170000123],
              [107.076099797, 20.770076063000076],
              [107.077502759, 20.769980408000066],
              [107.07869186900007, 20.769477762000044],
              [107.078801668, 20.769072120000054],
              [107.07858829800003, 20.768563607000075],
              [107.07729898700011, 20.767441451000046],
              [107.07417957200002, 20.765499431000123],
            ],
          ],
          [
            [
              [107.09804536100009, 20.768124280000052],
              [107.09750641900007, 20.768020554000088],
              [107.09674936600005, 20.768423557000034],
              [107.09631501700008, 20.769030925000017],
              [107.09576432900009, 20.771465236000083],
              [107.09543597300009, 20.77247913100004],
              [107.09521738100004, 20.773087377000095],
              [107.09521408800003, 20.773798026000101],
              [107.09553539300006, 20.774306961000022],
              [107.09650306500001, 20.77502158599999],
              [107.09801253100002, 20.775230803000078],
              [107.09930669600008, 20.775337609000118],
              [107.0998479990001, 20.77493373],
              [107.10028468900001, 20.773818753000036],
              [107.10007500200007, 20.772498094000099],
              [107.09943799600002, 20.770261978000093],
              [107.09879678800009, 20.768939549],
              [107.09804536100009, 20.768124280000052],
            ],
          ],
          [
            [
              [106.838856143, 20.769418457000107],
              [106.83770596900006, 20.769220787000094],
              [106.83594436600005, 20.769339248000037],
              [106.83485886400003, 20.769652890000053],
              [106.83431362800003, 20.770224861000131],
              [106.83424208800007, 20.770863204000101],
              [106.83443649900008, 20.772333295000053],
              [106.83497029700001, 20.77367743000007],
              [106.83604021000008, 20.775982463],
              [106.83651049600007, 20.776623666000127],
              [106.83698420100011, 20.776690034000111],
              [106.83759451400007, 20.776565506000019],
              [106.83807125900007, 20.776120916000096],
              [106.83875688600006, 20.774719340000033],
              [106.83931313300008, 20.772295120000095],
              [106.83945920900007, 20.770507464000062],
              [106.83932794100005, 20.769804181000048],
              [106.838856143, 20.769418457000107],
            ],
          ],
          [
            [
              [107.08355057199999, 20.776287988000064],
              [107.08581856000006, 20.775789776000053],
              [107.08765262900003, 20.775797364000056],
              [107.08937691700005, 20.776210580000054],
              [107.090457203, 20.775910460000091],
              [107.09132501900004, 20.774898809000049],
              [107.09251884300005, 20.773380865000043],
              [107.09371736, 20.770847707000058],
              [107.09393783800004, 20.769833369000068],
              [107.09394207900004, 20.768919674000053],
              [107.09330044000006, 20.767698747000018],
              [107.09158377600005, 20.765661200000096],
              [107.0905078050001, 20.76504762000004],
              [107.08824421200003, 20.764632179000039],
              [107.08684176800008, 20.764626381000092],
              [107.0854379080001, 20.764925148000124],
              [107.08370705800007, 20.765933211000039],
              [107.08219147500007, 20.767043686000086],
              [107.08132221300009, 20.768359886],
              [107.08088255900005, 20.770083968000016],
              [107.08011637500007, 20.772415818000042],
              [107.079138711, 20.773833068000066],
              [107.0779462190001, 20.77504637],
              [107.07750939900011, 20.776161302000062],
              [107.07761440800009, 20.77677087000005],
              [107.07804403800002, 20.777178760000048],
              [107.07933868400002, 20.777184163000051],
              [107.08160574700003, 20.776889043000054],
              [107.08355057199999, 20.776287988000064],
            ],
          ],
          [
            [
              [107.07411972300004, 20.771894961000022],
              [107.07324991300001, 20.771742046000071],
              [107.07222076800004, 20.77181235500003],
              [107.07126969800004, 20.77210688600006],
              [107.06991653100008, 20.773743144],
              [107.0696730710001, 20.775010897000087],
              [107.06990586500008, 20.775982126000066],
              [107.07069193100001, 20.777104939000068],
              [107.07179705400006, 20.777706664000029],
              [107.07274815000007, 20.77741212400008],
              [107.07457367700003, 20.776300275000011],
              [107.07568693400003, 20.775185436000051],
              [107.07608687200009, 20.774291503000072],
              [107.07593176800005, 20.77361914300004],
              [107.07475070800011, 20.772345413000082],
              [107.07411972300004, 20.771894961000022],
            ],
          ],
          [
            [
              [107.10193753100006, 20.789764457000089],
              [107.10064514900007, 20.789251590000021],
              [107.09891697200008, 20.789650629000086],
              [107.09826820100005, 20.789952538000108],
              [107.09772591500003, 20.790559447000057],
              [107.09718315500007, 20.791267871000052],
              [107.09728823400005, 20.791877424000035],
              [107.097717, 20.792488300000045],
              [107.0985768840001, 20.793202454000067],
              [107.09976280400008, 20.79341033100004],
              [107.10159984200001, 20.792808693000083],
              [107.10279181900006, 20.791696823000017],
              [107.10268765700005, 20.790884228000017],
              [107.10193753100006, 20.789764457000089],
            ],
          ],
          [
            [
              [106.93986881200007, 20.822158051000095],
              [106.93957748100004, 20.822115752000059],
              [106.93909348100004, 20.822166822000021],
              [106.93769462800003, 20.822684166000016],
              [106.93713150499998, 20.822983564000097],
              [106.93688135300008, 20.823514023000108],
              [106.93698234699998, 20.823832026000041],
              [106.93762105300007, 20.824508693000077],
              [106.93841111800008, 20.82491008900007],
              [106.93878988300006, 20.824951395000113],
              [106.93905416100009, 20.824891700000059],
              [106.93936728900005, 20.824625712000078],
              [106.93984136800002, 20.823799686000122],
              [106.94026697000007, 20.822974201000122],
              [106.94026346800004, 20.822700684000047],
              [106.94016188000008, 20.822337099000073],
              [106.93986881200007, 20.822158051000095],
            ],
          ],
          [
            [
              [107.06645162400009, 20.824305500000051],
              [107.06564891600001, 20.82389840700003],
              [107.06500495400003, 20.823946140000068],
              [107.06441270500005, 20.824397797000046],
              [107.06371074500007, 20.82535361],
              [107.06332967700007, 20.826512633000057],
              [107.06375543800003, 20.827220913000041],
              [107.06412997100003, 20.827424344000086],
              [107.06584392000008, 20.827986690000074],
              [107.06820064200012, 20.828753588000026],
              [107.07012918700008, 20.829316797000082],
              [107.07093409800007, 20.829269726000099],
              [107.07206279200003, 20.828820311000037],
              [107.07276351600009, 20.828116782000095],
              [107.07298049500011, 20.827613072000069],
              [107.07292901100001, 20.827158693000044],
              [107.0723942490001, 20.82680321000003],
              [107.07116163900007, 20.826545717000045],
              [107.06933968600003, 20.826134341000021],
              [107.06837554400002, 20.825827505000035],
              [107.06752062300008, 20.825117420000083],
              [107.06645162400009, 20.824305500000051],
            ],
          ],
          [
            [
              [106.93339368700006, 20.826930624000092],
              [106.93307880900004, 20.826911408000015],
              [106.93269336100012, 20.827052366000132],
              [106.93250499700008, 20.827464313000021],
              [106.93250964500001, 20.827828533000044],
              [106.93256676200004, 20.828510926000021],
              [106.93327573400008, 20.83096184700004],
              [106.93342442400004, 20.831233388000037],
              [106.93376729700007, 20.831548265000066],
              [106.93420406000006, 20.831634405000088],
              [106.93488166100008, 20.831626751000073],
              [106.93594529500007, 20.831523662000073],
              [106.93659694600007, 20.831379691000059],
              [106.93683603300005, 20.831149312],
              [106.93665907100007, 20.830559348000044],
              [106.93517740700004, 20.828253783000058],
              [106.93458730900007, 20.827531885000013],
              [106.93385551900009, 20.827084786000057],
              [106.93339368700006, 20.826930624000092],
            ],
          ],
          [
            [
              [106.87995406000007, 20.813915603000055],
              [106.88054417700005, 20.813601643000098],
              [106.88129920000009, 20.813843187000103],
              [106.88305277200004, 20.815833033000075],
              [106.8848095490001, 20.817268209000069],
              [106.88573132400006, 20.81774829400009],
              [106.886655816, 20.817752957000032],
              [106.88808818900003, 20.817126273000049],
              [106.89036730600004, 20.815394483000112],
              [106.89129763800001, 20.814369055],
              [106.89172416000005, 20.813261848000053],
              [106.89230652100009, 20.810853129000058],
              [106.89241141700006, 20.810617387],
              [106.89361487100007, 20.805902058000036],
              [106.89412315100003, 20.805191445000034],
              [106.89471366800004, 20.804798199000096],
              [106.89547047700005, 20.804722749000071],
              [106.89614144000005, 20.80496382500003],
              [106.8976492310001, 20.805842993000034],
              [106.89924420000008, 20.806167917],
              [106.90025268200004, 20.806172943000064],
              [106.90084318900006, 20.805779681000033],
              [106.90169206000003, 20.804278348000068],
              [106.90216385100007, 20.802577039000077],
              [106.90149689300004, 20.801622837000089],
              [106.898814831, 20.800341629000037],
              [106.88590201700006, 20.795205413000062],
              [106.88275862100004, 20.794410786000036],
              [106.88151892400005, 20.794298192000049],
              [106.88106608400007, 20.794614866000046],
              [106.88049926500005, 20.795143603],
              [106.88049194899999, 20.796419437000054],
              [106.88014819200006, 20.797374595000036],
              [106.87912911300003, 20.798113682000086],
              [106.87854427300005, 20.798149574000035],
              [106.87709888100008, 20.798209690000121],
              [106.87484375000001, 20.798198208000059],
              [106.87383200600006, 20.797661432000055],
              [106.87293916600008, 20.796062039000063],
              [106.87215847400006, 20.794569537000115],
              [106.870700051, 20.793286206000069],
              [106.86777462100007, 20.792207987000033],
              [106.86349247800005, 20.791760687000057],
              [106.85661455500012, 20.791725170000049],
              [106.85176493500005, 20.791912651000111],
              [106.84996088400008, 20.791903260000041],
              [106.84826708, 20.792319717000041],
              [106.84713327500008, 20.793377033000034],
              [106.84644984200001, 20.794543013000052],
              [106.8457531980001, 20.797941701000063],
              [106.84388801400006, 20.808245127000099],
              [106.84295188600005, 20.813981518000091],
              [106.84248380700004, 20.816849688000083],
              [106.84206617500004, 20.819224907000056],
              [106.84178366400002, 20.819854209000077],
              [106.84600704000006, 20.822241776000091],
              [106.84745078800006, 20.823248066000041],
              [106.84783611300006, 20.824091122000077],
              [106.85234154400007, 20.825954365000051],
              [106.86057081100007, 20.829886797000086],
              [106.86564208600001, 20.832542675000099],
              [106.86628845400003, 20.832545993000018],
              [106.86723905900007, 20.832550873000066],
              [106.86925628500009, 20.832561212000122],
              [106.87060386000003, 20.832092689000078],
              [106.87254893500003, 20.830042468000087],
              [106.87652118400007, 20.826259330000063],
              [106.87830168600004, 20.823574298000032],
              [106.87966281200005, 20.820728643000038],
              [106.88001036500002, 20.818749453000059],
              [106.87985409700006, 20.816688457000062],
              [106.87969828700005, 20.814548220000084],
              [106.87995406000007, 20.813915603000055],
            ],
          ],
          [
            [
              [107.04830579900008, 20.839427544000074],
              [107.0480238960001, 20.839426329000062],
              [107.04767776300007, 20.83974893000002],
              [107.04748680799999, 20.840366815000074],
              [107.04725978500004, 20.841956813000095],
              [107.04725115500003, 20.84372452500007],
              [107.04731049700005, 20.84440241600003],
              [107.04762171400006, 20.844816234000071],
              [107.04799702000003, 20.844935697000011],
              [107.04856127300008, 20.84484974500009],
              [107.04934680400005, 20.844352269000055],
              [107.05032341600004, 20.843207438],
              [107.05089224700004, 20.842178693000065],
              [107.05108391000007, 20.841413495000083],
              [107.05108749100002, 20.84067694900007],
              [107.05080787900006, 20.840204346000043],
              [107.05008917600001, 20.839847701000025],
              [107.04883814100006, 20.839459308000038],
              [107.04830579900008, 20.839427544000074],
            ],
          ],
          [
            [
              [106.94293277300001, 20.844035353000031],
              [106.94441196000001, 20.841061698000104],
              [106.94469825100006, 20.840484715000059],
              [106.9447008970001, 20.83999534700007],
              [106.94418849800007, 20.838747211000097],
              [106.94315962400003, 20.8370072270001],
              [106.94255020300007, 20.836247991000079],
              [106.94189214500005, 20.835755464],
              [106.94052739600002, 20.835037096000072],
              [106.93911401200006, 20.834585412000095],
              [106.93798210300011, 20.834446503000059],
              [106.93623547200009, 20.834527077000061],
              [106.93155994100005, 20.835127356000079],
              [106.92934087300003, 20.835294562000058],
              [106.92872565000002, 20.835603],
              [106.92848846700011, 20.835824298000105],
              [106.9284862690001, 20.836224690000016],
              [106.92862515600004, 20.836714738000062],
              [106.92970037100011, 20.83858849300011],
              [106.93007304300002, 20.839480072000072],
              [106.93021219000008, 20.839925632000075],
              [106.93011609200003, 20.840236586000046],
              [106.92978304100011, 20.84072435200008],
              [106.9293096320001, 20.840988991000039],
              [106.92874231900007, 20.841164191000061],
              [106.92793976100009, 20.841204791000045],
              [106.92647770700009, 20.841019727000095],
              [106.92586417500004, 20.841016744000029],
              [106.92534381000006, 20.841236659000018],
              [106.92501172600004, 20.841546462000068],
              [106.92500952000003, 20.84194685000007],
              [106.92552180500005, 20.843195024000046],
              [106.92589496600002, 20.843997635000022],
              [106.92650532500006, 20.844578951000067],
              [106.92895290399998, 20.845792026000076],
              [106.93191967100006, 20.84700757300002],
              [106.93361630100001, 20.847460662000024],
              [106.93550366800012, 20.847558739000057],
              [106.93682541000005, 20.847520620000104],
              [106.93876191200006, 20.847263005000016],
              [106.94008558400006, 20.846868966],
              [106.94089081000007, 20.84633896900003],
              [106.94150725400002, 20.845808063000071],
              [106.94212417700007, 20.845188186000115],
              [106.94293277300001, 20.844035353000031],
            ],
          ],
          [
            [
              [106.95653896500002, 20.859101309000039],
              [106.95611007200006, 20.859009347000068],
              [106.95544190200012, 20.85905115100006],
              [106.95501132400001, 20.859273958000109],
              [106.954578568, 20.859901468000075],
              [106.95418653800007, 20.861833236000038],
              [106.95379111600005, 20.86439453500013],
              [106.95378508800007, 20.865518705000063],
              [106.95402493300003, 20.86582058700008],
              [106.95440291000003, 20.86597131400012],
              [106.95497592300011, 20.865884082],
              [106.95631517700008, 20.865260872000043],
              [106.95787738700001, 20.86425715700004],
              [106.95867916900009, 20.863301485000108],
              [106.95886132800007, 20.862342888000015],
              [106.95873455900005, 20.861215982000125],
              [106.95834469700004, 20.86045887600001],
              [106.95725291200003, 20.859419461000073],
              [106.95653896500002, 20.859101309000039],
            ],
          ],
          [
            [
              [106.98260092200007, 20.864377235000028],
              [106.98191119600003, 20.86432760400001],
              [106.9814181240001, 20.864371784000113],
              [106.98072597200012, 20.864786636000062],
              [106.98037759400006, 20.865435341000094],
              [106.98022367300003, 20.866595884000013],
              [106.98026810600007, 20.867525101000069],
              [106.98061042100005, 20.868037631000021],
              [106.98115230600004, 20.868086576000074],
              [106.98218848400006, 20.86785910499999],
              [106.98445772200006, 20.867451498000023],
              [106.98514865800003, 20.867268879],
              [106.98564343700011, 20.866899543],
              [106.98579393600004, 20.866389284000064],
              [106.98569851000005, 20.865784987000069],
              [106.98520833300009, 20.865271784000058],
              [106.98417628300004, 20.864709634000086],
              [106.98260092200007, 20.864377235000028],
            ],
          ],
          [
            [
              [106.9957508360001, 20.864779234000075],
              [106.99533809400005, 20.864721743000111],
              [106.99462890000008, 20.864940972000042],
              [106.99338694500008, 20.865491438000028],
              [106.99303118800005, 20.865823491000057],
              [106.99261528700001, 20.866377716000081],
              [106.99219306400006, 20.868155389],
              [106.99230848900002, 20.868656430000065],
              [106.99277766000003, 20.869214689000067],
              [106.99383689800001, 20.869775636000028],
              [106.99436766200004, 20.869833667000037],
              [106.99466354900001, 20.869668182000019],
              [106.99513874400007, 20.869058612000032],
              [106.99567723400004, 20.867615139000058],
              [106.99604013400005, 20.865892803000108],
              [106.99604299500001, 20.86533668500001],
              [106.99598570800008, 20.865002749],
              [106.9957508360001, 20.864779234000075],
            ],
          ],
          [
            [
              [106.95721590500008, 20.866344351000073],
              [106.95669205500008, 20.866162008000089],
              [106.95621398300005, 20.866339619000129],
              [106.95563809000001, 20.866966447000031],
              [106.95515472700006, 20.868133318000034],
              [106.95466966300008, 20.869614951000059],
              [106.95466508600006, 20.87046930700005],
              [106.95496480500007, 20.871694531000081],
              [106.95553657300005, 20.872722905000082],
              [106.95606929000007, 20.873270374000086],
              [106.95647038400003, 20.873478863000017],
              [106.95697924500004, 20.873540870000085],
              [106.9572311310001, 20.873444725000034],
              [106.95744852700005, 20.873291296000041],
              [106.96020489900003, 20.869506173000026],
              [106.96044513500001, 20.869192530000099],
              [106.96044705100009, 20.868832802],
              [106.960115466, 20.868381570000061],
              [106.95811928400005, 20.866933194000033],
              [106.95721590500008, 20.866344351000073],
            ],
          ],
          [
            [
              [106.94267426400003, 20.86885653800001],
              [106.94227356000009, 20.86856107100008],
              [106.94179320400005, 20.86859546200008],
              [106.94119425100004, 20.868959528000097],
              [106.93989001700005, 20.870151913000079],
              [106.9386131740001, 20.871075331],
              [106.93832607300006, 20.871367494000111],
              [106.93829852400009, 20.871660905000056],
              [106.93840074200001, 20.871954939000048],
              [106.93989709600001, 20.873625543000074],
              [106.9402330560001, 20.873896233],
              [106.94069944900005, 20.874045246000072],
              [106.94106348700006, 20.873924680000044],
              [106.9413247690001, 20.873607926000126],
              [106.94164047400002, 20.872826660000051],
              [106.94267168000007, 20.869333534000042],
              [106.94267426400003, 20.86885653800001],
            ],
          ],
          [
            [
              [106.94436110400007, 20.871267179000071],
              [106.94369104700004, 20.871263976000073],
              [106.94325287300003, 20.871481534000047],
              [106.94310572100002, 20.871755392000054],
              [106.94310304400007, 20.872249595000092],
              [106.94333209400001, 20.872992023000059],
              [106.9442799600001, 20.875495098000037],
              [106.94436602400003, 20.875742616000089],
              [106.94468499900003, 20.876018701000106],
              [106.94509272100002, 20.876048103000052],
              [106.94553060600002, 20.875885459000095],
              [106.9458246120001, 20.87539265],
              [106.94612232500003, 20.874213436000041],
              [106.94615813100006, 20.872978070000059],
              [106.94601602500003, 20.872318433000103],
              [106.9456979350001, 20.871877608000133],
              [106.94497155000003, 20.871517204000064],
              [106.94436110400007, 20.871267179000071],
            ],
          ],
          [
            [
              [106.9637051400001, 20.873207906000069],
              [106.96414381500007, 20.873055220000047],
              [106.96458165900006, 20.873057273000128],
              [106.96529235800006, 20.873215347000063],
              [106.96638370300003, 20.87383944],
              [106.96720277300004, 20.874204351000088],
              [106.96791428600002, 20.874207677000101],
              [106.96873607700007, 20.874056763000048],
              [106.97290222300008, 20.87283820900003],
              [106.97421794200005, 20.872431667000058],
              [106.97471296300006, 20.871969725000028],
              [106.97504514900001, 20.871249114000086],
              [106.97538111600001, 20.869806363000087],
              [106.975445334, 20.868001274000036],
              [106.97534182800004, 20.866865976000071],
              [106.97518224099998, 20.865988331000018],
              [106.97480212200007, 20.865419152000086],
              [106.97349431100004, 20.864329840000018],
              [106.97133517100009, 20.862215316000032],
              [106.97014009800006, 20.861240144000078],
              [106.96922767400007, 20.860858819000057],
              [106.96654527900009, 20.860146007000054],
              [106.96460414500005, 20.859759845000077],
              [106.963518284, 20.8597547520001],
              [106.96271702500005, 20.859966465000035],
              [106.96185689700012, 20.860501088000127],
              [106.96139034100005, 20.860936447000086],
              [106.96097172700007, 20.861486919000072],
              [106.95977254700006, 20.863719335000042],
              [106.95921004700006, 20.864835717000041],
              [106.95916996300011, 20.865395048000046],
              [106.95942800300006, 20.865710986000074],
              [106.9602058450001, 20.865959438000026],
              [106.96124323200006, 20.86624407900004],
              [106.96190902400008, 20.866631872000063],
              [106.962204356, 20.866913019000052],
              [106.96223904900003, 20.867367778000109],
              [106.96216149, 20.867996864000112],
              [106.96185908700004, 20.869044535000022],
              [106.96140752200006, 20.870231367000081],
              [106.96134378700003, 20.871000389000116],
              [106.96133373900008, 20.87288791200006],
              [106.96115940700005, 20.87342575400006],
              [106.96087190900006, 20.873747593000047],
              [106.95972622500004, 20.874227001000101],
              [106.95880916200011, 20.874707471000065],
              [106.95840705600006, 20.875082636000016],
              [106.95783004100009, 20.876103356],
              [106.95678856899998, 20.878468524000127],
              [106.95678511300005, 20.87911489500005],
              [106.95718347700006, 20.87943996400012],
              [106.95750743700005, 20.879417328000024],
              [106.960327932, 20.878440114000085],
              [106.96371334700007, 20.876831295000017],
              [106.96422946500005, 20.876510517000035],
              [106.96428862800006, 20.876133735000032],
              [106.96417688800005, 20.875648420000026],
              [106.96314932100003, 20.874804342000083],
              [106.9630976060001, 20.874236692000103],
              [106.96320980700006, 20.873721405000097],
              [106.9637051400001, 20.873207906000069],
            ],
          ],
          [
            [
              [106.9527698750001, 20.868764879000068],
              [106.95261582400005, 20.866794039000069],
              [106.95255380200007, 20.866482050000101],
              [106.95250534900006, 20.865293273000063],
              [106.95252558200005, 20.864458913000036],
              [106.95271999200006, 20.863107432000035],
              [106.95304075900003, 20.861462547000095],
              [106.95317594100004, 20.85952276800009],
              [106.95287631900003, 20.857228126000038],
              [106.952770845, 20.856341754000063],
              [106.95294763800007, 20.856046955000096],
              [106.95316805800009, 20.855841296000044],
              [106.95355210800007, 20.85579144000009],
              [106.95437338500008, 20.856002034000106],
              [106.95623357900007, 20.856734306000035],
              [106.95798689400002, 20.85694929800006],
              [106.9609463890001, 20.857169945000109],
              [106.965440739, 20.857449424000052],
              [106.96785307400002, 20.857460711],
              [106.96873084300009, 20.857361460000057],
              [106.96939039000003, 20.857054474000059],
              [106.97021632600007, 20.856386531000034],
              [106.97170586900003, 20.854636469000098],
              [106.97264633300004, 20.853038859000073],
              [106.97331238500009, 20.851491650000042],
              [106.97369751700006, 20.851235058000036],
              [106.97408128100003, 20.851236841000095],
              [106.97451878700008, 20.85144557900005],
              [106.97468136400002, 20.851808077000022],
              [106.97478123900002, 20.853668903000063],
              [106.97488274400007, 20.855219675000036],
              [106.97504206700003, 20.856202276000069],
              [106.97596840100002, 20.857291776000061],
              [106.97722262700006, 20.858589501000054],
              [106.9779337480001, 20.858902857000047],
              [106.97919474700007, 20.858908689000089],
              [106.98037975300007, 20.858450156000075],
              [106.98065654900006, 20.858105857000098],
              [106.98065970800005, 20.857501102000064],
              [106.98048360100005, 20.856117968000106],
              [106.98003298100006, 20.854647168],
              [106.97931146200006, 20.852397548000035],
              [106.97877146400005, 20.850494347000037],
              [106.97804430900001, 20.849324632000062],
              [106.97731807800005, 20.847982133000095],
              [106.97718308100008, 20.847506327000076],
              [106.97718466600003, 20.847203944000015],
              [106.97741539300002, 20.846902623000041],
              [106.97787412000005, 20.846818350000085],
              [106.97865206100002, 20.847037944000043],
              [106.98080055400007, 20.848084628000052],
              [106.98281203100005, 20.849044254000049],
              [106.98326806000003, 20.849478341000079],
              [106.98340329900003, 20.849910941000083],
              [106.9834008230001, 20.850386109000048],
              [106.98312336800005, 20.85086001000009],
              [106.98293690200008, 20.851463918000057],
              [106.98293285000001, 20.852241465000084],
              [106.98305863500012, 20.854488332000095],
              [106.98327428200002, 20.857081182000044],
              [106.98336076500007, 20.858075120000038],
              [106.98354093500005, 20.858680718000052],
              [106.98376783200008, 20.859113733000086],
              [106.98495471200006, 20.860026334000011],
              [106.98819861700005, 20.861985104000077],
              [106.98915906000012, 20.862378269000104],
              [106.99012062400001, 20.86255546400011],
              [106.99199949300004, 20.862607240000038],
              [106.99322958900002, 20.862463462000044],
              [106.99403569400002, 20.862230060000094],
              [106.99489283400007, 20.861854654000034],
              [106.99681004100006, 20.860772846000053],
              [107.00013890100007, 20.859081039000046],
              [107.00089897599999, 20.858578761000054],
              [107.00142940600009, 20.857984667000018],
              [107.00175015200007, 20.857141082000027],
              [107.00196644200003, 20.856098189000072],
              [107.00197228900007, 20.854954930000069],
              [107.00160847700008, 20.853909411000068],
              [107.00024827300007, 20.851765797000077],
              [106.99862518700006, 20.849471845000075],
              [106.99826089200012, 20.848525732000041],
              [106.99800283300009, 20.847430969000058],
              [106.99795648100007, 20.846188033000068],
              [106.99806449800003, 20.845691433000098],
              [106.99832944500011, 20.845444089000061],
              [106.99869910000005, 20.84534635100006],
              [106.99938463700002, 20.845349458000051],
              [107.00006814699999, 20.845750221000081],
              [107.00080337400007, 20.84635006300006],
              [107.00184793100009, 20.848446381000052],
              [107.00243007900011, 20.850013300000022],
              [107.00306356400003, 20.852034583000112],
              [107.00348813100008, 20.852944790000016],
              [107.00391605000004, 20.853199021000087],
              [107.00445281900005, 20.853150976000023],
              [107.00509971300002, 20.852548367000075],
              [107.00538058700008, 20.851508654000028],
              [107.00565447800008, 20.848968167000059],
              [107.00593145800009, 20.847253743000046],
              [107.00652982000004, 20.845641692000072],
              [107.00810128400003, 20.842570619000078],
              [107.00880357900006, 20.841615009000058],
              [107.00977154600012, 20.841165199000052],
              [107.01165150300005, 20.840719476000032],
              [107.01342263300006, 20.840576005000067],
              [107.01406589800007, 20.840679802000039],
              [107.01417064600004, 20.841184881000082],
              [107.01363008600008, 20.841989850000076],
              [107.01136895800005, 20.843544043000058],
              [107.01048583500007, 20.84431046200006],
              [107.00971628700007, 20.845414867000045],
              [107.009154, 20.846561773000083],
              [107.00860777400001, 20.848476831000085],
              [107.00842572100005, 20.849136308000112],
              [107.00750248200005, 20.852744987000094],
              [107.00666258200002, 20.855905023000076],
              [107.00644545200008, 20.858099330000087],
              [107.00671085600001, 20.859908405000027],
              [107.00712829600009, 20.860880084000044],
              [107.00781692900004, 20.861567716000096],
              [107.00883377600007, 20.862076885000036],
              [107.01006626200008, 20.862385166000053],
              [107.01162195900011, 20.862442598000094],
              [107.01355322500002, 20.862501688000101],
              [107.01634269500008, 20.862615050000066],
              [107.01838051200011, 20.86282595700002],
              [107.019508, 20.862679580000076],
              [107.019992653, 20.862328514000069],
              [107.02037279000011, 20.861421933000109],
              [107.020486422, 20.86016094000005],
              [107.02102928200007, 20.858901848000052],
              [107.02183887000008, 20.857946697000074],
              [107.02270033900004, 20.857344992000023],
              [107.02334442200004, 20.857297385],
              [107.02377262900004, 20.857501112000065],
              [107.02403911500005, 20.857855510000078],
              [107.02392929300004, 20.858359631000042],
              [107.02327889300004, 20.859668711],
              [107.02278768300008, 20.861331710000016],
              [107.02251461300004, 20.862289234000016],
              [107.02256599800003, 20.862743600000044],
              [107.02288564300001, 20.863199152000078],
              [107.02347532700004, 20.863302671000028],
              [107.02503228600008, 20.863107718000059],
              [107.0253267140001, 20.863077082000103],
              [107.02688522700001, 20.862737440000068],
              [107.02903237500001, 20.862545058000045],
              [107.03048102000007, 20.862551422000038],
              [107.03155358500008, 20.862657048000074],
              [107.03257225200008, 20.862812889000089],
              [107.03348561000007, 20.862564591000066],
              [107.034239504, 20.862012830000062],
              [107.03499389400008, 20.861360157000064],
              [107.03526490500012, 20.860806282000063],
              [107.03532355400009, 20.859797338000071],
              [107.03516733700003, 20.85883792],
              [107.03458465000006, 20.857321573000043],
              [107.034324129, 20.855756174000106],
              [107.03454322500009, 20.854848845000085],
              [107.03508223600001, 20.854346601000096],
              [107.03567289800003, 20.85424825900013],
              [107.03658596300008, 20.854050401],
              [107.03787558300003, 20.853652344000025],
              [107.03927373400008, 20.853002442000076],
              [107.04067312600004, 20.852100244000034],
              [107.04250541300003, 20.850443001000052],
              [107.04326023400004, 20.849689358000134],
              [107.04358512500002, 20.8489428930001],
              [107.04393767600006, 20.848162280000082],
              [107.04431987500006, 20.846943843000076],
              [107.04448996300002, 20.845077523000022],
              [107.04466449500006, 20.842302922000044],
              [107.04450997500007, 20.840990261000059],
              [107.04397795600002, 20.840079651000032],
              [107.04301700200008, 20.839116722000114],
              [107.04216285800003, 20.838255176000061],
              [107.04198245300002, 20.837302047000072],
              [107.04204105000007, 20.836293073000078],
              [107.04220713900004, 20.834697824000067],
              [107.04232285200003, 20.832982617000084],
              [107.0422249690001, 20.831064636000107],
              [107.04191028600009, 20.829599862000059],
              [107.04148410400009, 20.82899246700007],
              [107.04110908500004, 20.828889912000115],
              [107.04067869100005, 20.829140353000049],
              [107.03987030700006, 20.82989377900013],
              [107.03868441200007, 20.831049251000074],
              [107.03679837500007, 20.832756737000047],
              [107.03609973800009, 20.833005998000061],
              [107.03561793000002, 20.832802052000062],
              [107.03524540200002, 20.832194882000096],
              [107.03514136200002, 20.831538416000051],
              [107.03541307000003, 20.830833134000045],
              [107.03617008600006, 20.829625349000125],
              [107.03703361700009, 20.828569408000064],
              [107.03811073400004, 20.827716236000079],
              [107.03972501700011, 20.826714018000111],
              [107.04144830700005, 20.825359013000089],
              [107.04279532600003, 20.824153756000015],
              [107.04338910800006, 20.823399392000042],
              [107.04358167700009, 20.822416209000046],
              [107.0435871170001, 20.821306045000121],
              [107.0434894840001, 20.819337571000048],
              [107.04349517200004, 20.818176942000029],
              [107.04408893300004, 20.817422561000051],
              [107.04586624200002, 20.815966817000039],
              [107.04801653900003, 20.815017295000082],
              [107.0489833, 20.814769146000067],
              [107.04941266900009, 20.81472052600013],
              [107.04984031900003, 20.815025152000111],
              [107.04989125400007, 20.81558046200005],
              [107.04924191700007, 20.816738324000106],
              [107.04714154900003, 20.81844502300001],
              [107.04648603800005, 20.81932120500003],
              [107.04638959200007, 20.819765200000084],
              [107.04638676900001, 20.82034293400001],
              [107.04652253300006, 20.821498992],
              [107.04670436700009, 20.822877457000018],
              [107.04670154500003, 20.823455187000036],
              [107.04655665100003, 20.824165621000112],
              [107.04589018200005, 20.825495973000052],
              [107.04534925300005, 20.826636133000065],
              [107.04506071200007, 20.827115629000033],
              [107.04451513100005, 20.828980378000047],
              [107.04444864300005, 20.831604132000066],
              [107.04508225600009, 20.833675810000059],
              [107.04609436900006, 20.835143584],
              [107.04684146800007, 20.835954190000017],
              [107.04745778200008, 20.836083008000017],
              [107.04815518500004, 20.836086015000085],
              [107.04858656900004, 20.835633717000057],
              [107.04880606799999, 20.834625432000031],
              [107.04859640900006, 20.833615287000029],
              [107.04796349700008, 20.831392229000031],
              [107.0471694120001, 20.829218934000068],
              [107.04695952100006, 20.828259245000069],
              [107.04712291599999, 20.82775532000003],
              [107.04782274100008, 20.82725371700009],
              [107.04862838700004, 20.827055337000019],
              [107.04927038700004, 20.827411340000076],
              [107.05012377800004, 20.828424264000134],
              [107.05102934600009, 20.829740176000122],
              [107.05187957000001, 20.831409079000096],
              [107.05251694500008, 20.832723826000077],
              [107.05358373100006, 20.833989952000117],
              [107.05465370700003, 20.834600077000061],
              [107.056155065, 20.834757891000066],
              [107.05776492800007, 20.834663841000101],
              [107.05851816000003, 20.834212896000089],
              [107.05895024600008, 20.833609197000058],
              [107.05932965100004, 20.832803421000087],
              [107.05933208300006, 20.832298814000083],
              [107.059226311, 20.831352749000054],
              [107.05918238000001, 20.830685952000039],
              [107.05918559300002, 20.830019356000037],
              [107.05909559800003, 20.829130155000094],
              [107.05905317600008, 20.8281522730001],
              [107.05903381300007, 20.827402704000072],
              [107.05876729600004, 20.827048331000064],
              [107.05812430500005, 20.826894204000013],
              [107.05737329500008, 20.826890999000014],
              [107.05694512000009, 20.826687318000033],
              [107.05673224700007, 20.826333172000133],
              [107.05679028600007, 20.825425091000049],
              [107.05696052600004, 20.824835320000098],
              [107.05733280600002, 20.824165831000037],
              [107.0578202180001, 20.823209121000019],
              [107.05825423000005, 20.822201713000062],
              [107.05841880800008, 20.821445475000111],
              [107.05868648800002, 20.820333671000043],
              [107.05888523500003, 20.819734412000024],
              [107.05922998600005, 20.819320426000054],
              [107.05962147000011, 20.819368252000103],
              [107.06035721300006, 20.819939843000057],
              [107.0623317820001, 20.822067680000075],
              [107.06292015200002, 20.822423418000113],
              [107.06334929300009, 20.822425239],
              [107.06426242200006, 20.822176798000044],
              [107.06539254000003, 20.821424645000072],
              [107.06636292200004, 20.820419488000041],
              [107.0671731030001, 20.819262262000038],
              [107.06739031700005, 20.818708083000011],
              [107.067392005, 20.818354848000098],
              [107.06659173300005, 20.817443136000065],
              [107.06456207600007, 20.815617863000035],
              [107.06370866700007, 20.814604983000073],
              [107.06376520800004, 20.813999662000079],
              [107.064034136, 20.813849405000084],
              [107.06467806500007, 20.813801675000043],
              [107.06580377700004, 20.813957828000085],
              [107.06724796200008, 20.814821810000069],
              [107.06890335800009, 20.816393168000054],
              [107.07012964100004, 20.817962693000069],
              [107.07065981000002, 20.8192769650001],
              [107.07060183700003, 20.820185058],
              [107.07038054400002, 20.821597088000132],
              [107.07037718, 20.822303554000051],
              [107.07077439700001, 20.823183509000032],
              [107.07264422, 20.824806169000084],
              [107.074037785, 20.825291742000026],
              [107.0748697540001, 20.825387554000052],
              [107.07567439800006, 20.825151704000078],
              [107.07793218800001, 20.824151888000038],
              [107.08003927300008, 20.823124477000121],
              [107.08350247600001, 20.821007046000069],
              [107.08772193300008, 20.818689658],
              [107.088806248, 20.817577455000077],
              [107.08978548100002, 20.815855720000087],
              [107.09152146800004, 20.813832536000106],
              [107.09390390400007, 20.812015018000096],
              [107.09996351700008, 20.808385170000051],
              [107.10310300800005, 20.806164556000084],
              [107.1045131920001, 20.804545993000097],
              [107.10646751600007, 20.801914428000053],
              [107.10766649200006, 20.799279784000113],
              [107.1082175830001, 20.796744025000088],
              [107.10822593100008, 20.794916701000062],
              [107.10845192500004, 20.792684175000083],
              [107.10813704900001, 20.790754015000076],
              [107.10792729100007, 20.789433399000053],
              [107.10739339100012, 20.788212988000062],
              [107.10685485300006, 20.788007771000046],
              [107.10620656400006, 20.788208186000112],
              [107.10501182600007, 20.789929204000131],
              [107.10424864700006, 20.791651961000035],
              [107.10380775500009, 20.793680577000103],
              [107.10315664600004, 20.794490091000029],
              [107.10218043900009, 20.795602835000082],
              [107.10088146300009, 20.7965112320001],
              [107.09947598000005, 20.79711462100002],
              [107.0982881620001, 20.797312809000076],
              [107.09613207600005, 20.796897910000034],
              [107.09483824000003, 20.796689573000023],
              [107.09343415200001, 20.796988375000055],
              [107.0910552030001, 20.798095301000075],
              [107.0881343500001, 20.799707570000066],
              [107.08672643100005, 20.800818454000058],
              [107.08369331100008, 20.803343857000108],
              [107.08174392799999, 20.804858532000111],
              [107.07946936000009, 20.806676389000067],
              [107.07827666900008, 20.807889630000084],
              [107.077411517, 20.808292083000076],
              [107.07654876300002, 20.808186959000082],
              [107.07579727800001, 20.807371674000038],
              [107.0758025650001, 20.806254994000106],
              [107.07656413100005, 20.804938444000065],
              [107.07905546700005, 20.802918481000084],
              [107.08187093000002, 20.800798314000076],
              [107.08533657100007, 20.798071682000071],
              [107.08750213800002, 20.796456335000087],
              [107.08955693100003, 20.795449617000102],
              [107.0917186560001, 20.79464636400008],
              [107.09291071000006, 20.793534542000103],
              [107.09421581800004, 20.791306457000047],
              [107.09427424000008, 20.790342246000115],
              [107.09417199800005, 20.789123576000087],
              [107.09374419300009, 20.78830965500007],
              [107.09353123400007, 20.787699654000043],
              [107.09375267600009, 20.786482307000128],
              [107.09581250400008, 20.784358806000022],
              [107.09830244100004, 20.782541606000038],
              [107.09960227300012, 20.781430167000089],
              [107.1003631130001, 20.780215002000091],
              [107.10036638800001, 20.779504359000086],
              [107.099939046, 20.778588919000107],
              [107.09940054300003, 20.778383671000086],
              [107.09767480600007, 20.778275102000073],
              [107.09562446800007, 20.778368232000055],
              [107.09400379500008, 20.778869201000113],
              [107.09302525600003, 20.780489545000066],
              [107.09226672900006, 20.781197082000077],
              [107.09151103000006, 20.781295494000048],
              [107.09054191700008, 20.78088541100005],
              [107.08968354600003, 20.779866655000134],
              [107.0888256490001, 20.778746366000078],
              [107.08807280400006, 20.778235642000098],
              [107.08688698800009, 20.77802769700012],
              [107.08537608500005, 20.778122963],
              [107.08321547100007, 20.778723136000018],
              [107.08224304700008, 20.779023659000103],
              [107.08062137800002, 20.77972756700008],
              [107.07856623500007, 20.780835746000022],
              [107.07683328700003, 20.78224981300005],
              [107.07585651100004, 20.783463990000058],
              [107.07552659000001, 20.784782391000078],
              [107.07552130200001, 20.785899108000102],
              [107.07530070000006, 20.786913393000091],
              [107.07432389100006, 20.788127545000101],
              [107.07150322700008, 20.791364366000039],
              [107.06997818800002, 20.79440355900006],
              [107.06889002200005, 20.796327851000029],
              [107.06671653600007, 20.799567289],
              [107.06389167700011, 20.803616093000073],
              [107.06258565300006, 20.805945470000076],
              [107.06159988500011, 20.808986825000098],
              [107.06126396600003, 20.811523328000092],
              [107.06124785400003, 20.814873321000071],
              [107.06071686800006, 20.815587606000108],
              [107.06022592100003, 20.815862489000025],
              [107.05893244500004, 20.816059368000062],
              [107.05688071100009, 20.816355168000108],
              [107.05573472800003, 20.816880144000024],
              [107.05486801400009, 20.817587050000071],
              [107.05432453300007, 20.818396860000021],
              [107.05442801600002, 20.819310945000069],
              [107.0545310090001, 20.820326541000078],
              [107.05409592200007, 20.821035297],
              [107.05334005000006, 20.821133572000058],
              [107.05237230700001, 20.820418811000074],
              [107.05140702900007, 20.819196472],
              [107.05141245599999, 20.818079814000079],
              [107.05185395700002, 20.816051382000111],
              [107.05240089200007, 20.814530982000079],
              [107.05294633200008, 20.81331511800002],
              [107.05435797700008, 20.811493857000052],
              [107.057066417, 20.809272059000072],
              [107.05869380200009, 20.807451680000085],
              [107.06065121500005, 20.804312958000089],
              [107.06174243500001, 20.801779630000091],
              [107.06218379200001, 20.799751125000029],
              [107.06219208300008, 20.798025327000055],
              [107.06317594800004, 20.796570329000104],
              [107.06449080400003, 20.795743456000082],
              [107.06654862200003, 20.794127842000073],
              [107.06793687600006, 20.792567584000032],
              [107.06924130400007, 20.79054267700009],
              [107.07076342500007, 20.788112586000103],
              [107.07131110100009, 20.786389036000052],
              [107.07142575300007, 20.784968219000085],
              [107.07143734200005, 20.782531743000021],
              [107.07160762500011, 20.780755819000071],
              [107.07128927000005, 20.77963774000002],
              [107.07032165800011, 20.778923011000039],
              [107.06795393600005, 20.777694752000016],
              [107.06590894200005, 20.776670876000125],
              [107.06321664200004, 20.775644241000087],
              [107.06278460900009, 20.775743928000075],
              [107.06224225200006, 20.776350764000107],
              [107.06213290200007, 20.776654864000044],
              [107.06202306500005, 20.777060492000118],
              [107.06299015000003, 20.777876780000071],
              [107.06471002800005, 20.779203868000096],
              [107.06545990000011, 20.780323794000118],
              [107.06513331700009, 20.780931545000044],
              [107.06394653600003, 20.780926516000072],
              [107.06146850100008, 20.780205338000087],
              [107.05888651000005, 20.778671501000098],
              [107.05522815600006, 20.776625418],
              [107.05274922300002, 20.776107186000026],
              [107.04562970700007, 20.77587346600005],
              [107.04465923600007, 20.775767746],
              [107.04412328400005, 20.775054765000078],
              [107.04369671300002, 20.774037689000032],
              [107.04402434600003, 20.773226913000094],
              [107.04456526100003, 20.77292468900005],
              [107.04596725700003, 20.77303227500007],
              [107.0474730280001, 20.773207009000046],
              [107.049064856, 20.772793409000109],
              [107.05067744800002, 20.771847951000041],
              [107.05331819100012, 20.770018209000085],
              [107.05472460400004, 20.769212046000085],
              [107.05559159400011, 20.768403566000067],
              [107.05559552200006, 20.767591390000106],
              [107.05505807900001, 20.767182989000062],
              [107.05408911200004, 20.766772746000051],
              [107.05322704600003, 20.766565994000082],
              [107.05192852700006, 20.767372623000121],
              [107.05074182900002, 20.767367523000054],
              [107.04901868899999, 20.766750958000024],
              [107.04837437000006, 20.766139032000105],
              [107.04773352100007, 20.764816447],
              [107.04709020300004, 20.764001473000079],
              [107.04568826200004, 20.763893883000058],
              [107.04450060100001, 20.764091801000092],
              [107.04352669800006, 20.764696733000051],
              [107.04190002700004, 20.766415593000076],
              [107.04059647400001, 20.768237373000083],
              [107.04005157300004, 20.769351773000011],
              [107.04015246900003, 20.770773547000054],
              [107.04046913400003, 20.772196256000086],
              [107.04003410300001, 20.772905029000107],
              [107.03895325700003, 20.773306414000011],
              [107.03830594800012, 20.773303595000044],
              [107.03700932600003, 20.773704039000052],
              [107.03581657300002, 20.774917114000054],
              [107.03527062300006, 20.776234530000082],
              [107.03471814000008, 20.778871708000054],
              [107.03449082600011, 20.781205726000081],
              [107.03437088600003, 20.78364173300011],
              [107.03403817200004, 20.785467671000021],
              [107.03295825100004, 20.785665983000086],
              [107.03209662700007, 20.785357637000068],
              [107.03167009600004, 20.784340554000025],
              [107.03146186800004, 20.782816815000068],
              [107.03157781600004, 20.781192965000095],
              [107.03203051300002, 20.776931005000066],
              [107.03269141500007, 20.774192778000078],
              [107.03421486800001, 20.771559836000094],
              [107.03725613300003, 20.767410635000068],
              [107.0388843530001, 20.765387240000074],
              [107.03997114500004, 20.763767566000091],
              [107.04094904400004, 20.762350467000076],
              [107.04171017500005, 20.761135467000088],
              [107.04328260700004, 20.759467121000071],
              [107.04634128900007, 20.757536742000049],
              [107.05009764500001, 20.755638568000023],
              [107.05366391300004, 20.754334045000085],
              [107.05636371900003, 20.753736445000115],
              [107.0590620400001, 20.753443390000115],
              [107.06079095400005, 20.75284159400006],
              [107.06306312100003, 20.751429877000049],
              [107.06576770500008, 20.749816905000081],
              [107.06825505900009, 20.748507574000108],
              [107.06944648200009, 20.747497322000093],
              [107.0701004740001, 20.746078693000044],
              [107.07065190300008, 20.743542814000101],
              [107.07076412000004, 20.742629536000031],
              [107.07076943400003, 20.741512743000087],
              [107.07023686400002, 20.74008910000007],
              [107.06851826200003, 20.738558922000024],
              [107.06615007000001, 20.737533618000015],
              [107.06453403700006, 20.737120663000042],
              [107.06323820500005, 20.737419749000097],
              [107.06248022500003, 20.738025706000052],
              [107.06161144700009, 20.739240362000096],
              [107.06160949500003, 20.739646464000124],
              [107.06225425600007, 20.74015685100008],
              [107.06397671600004, 20.74087486400007],
              [107.06472314700008, 20.741464394000083],
              [107.06499109200004, 20.742439334000018],
              [107.0649886620001, 20.742946959000093],
              [107.06464052600008, 20.744485481000119],
              [107.06395384400001, 20.745646592000085],
              [107.06276191800006, 20.746758342000057],
              [107.06114093400006, 20.747360619000062],
              [107.05962976799999, 20.74755723700008],
              [107.05822402000004, 20.748261935000031],
              [107.05670990099999, 20.749067685000085],
              [107.05541298700007, 20.749569777000062],
              [107.05455148700001, 20.749261510000046],
              [107.05390917600008, 20.748243479000017],
              [107.05337721200002, 20.746718287000121],
              [107.05338262600003, 20.745601501000088],
              [107.05392835900005, 20.744283977000052],
              [107.05642111800007, 20.741857977000052],
              [107.05750763300009, 20.740238157000057],
              [107.05805529200002, 20.73851451000008],
              [107.05774149200008, 20.73648258700009],
              [107.05689230300007, 20.733636125000118],
              [107.0559298790001, 20.731906006000052],
              [107.05518123000007, 20.730582910000095],
              [107.05513294800008, 20.729415102000104],
              [107.05567667000003, 20.728503658000093],
              [107.05687195800007, 20.726681214000031],
              [107.0572082780001, 20.724042842000046],
              [107.05721759000008, 20.722113784000037],
              [107.05690088400007, 20.72069098700009],
              [107.05593656100004, 20.719366948000051],
              [107.05335738700006, 20.717426785000015],
              [107.05077777000005, 20.715588124000114],
              [107.04787515100006, 20.713646493000049],
              [107.04669175300002, 20.713032184000021],
              [107.04572108800009, 20.713027988000121],
              [107.04410033200001, 20.713630171000119],
              [107.04301534500004, 20.714945395000051],
              [107.04236225500009, 20.716160955000085],
              [107.04235976600006, 20.716668601000073],
              [107.04364754700003, 20.71799410700006],
              [107.04601389700007, 20.719324270000072],
              [107.04773264900008, 20.720753140000078],
              [107.04804928800007, 20.722175959000118],
              [107.04782763500003, 20.723393373000043],
              [107.04695883100001, 20.724608],
              [107.04587777100008, 20.725110986000047],
              [107.04469232300005, 20.724902798000052],
              [107.04286072400004, 20.724488732000076],
              [107.04113748900009, 20.723973592000085],
              [107.04016826600011, 20.723664788000029],
              [107.03952112100002, 20.723661972000059],
              [107.03908770300005, 20.724066205000021],
              [107.03843605800004, 20.724977153000076],
              [107.03756218500004, 20.727207023000105],
              [107.03657792700004, 20.729944053000082],
              [107.03549129500001, 20.731563796000074],
              [107.03451802900005, 20.732067184000066],
              [107.03300846200003, 20.731959045000089],
              [107.03225544000007, 20.731549629000057],
              [107.0318285220001, 20.730633978000057],
              [107.03173223900004, 20.728298359000071],
              [107.0321752510001, 20.725965088000109],
              [107.03240002200005, 20.724138519000057],
              [107.03273113799999, 20.72261699200002],
              [107.03262680100006, 20.72190581400006],
              [107.03198167500007, 20.721496863000063],
              [107.03111781700008, 20.721696132000119],
              [107.03003471600009, 20.722605155000018],
              [107.02797281200002, 20.725134368000028],
              [107.02604676200008, 20.727613499000071],
              [107.02569310000004, 20.728068709000077],
              [107.02482565800003, 20.728978658000052],
              [107.0240686060001, 20.729381428000067],
              [107.02342144200003, 20.729378565000012],
              [107.02277631100003, 20.728969592000098],
              [107.02267403899999, 20.727852299000027],
              [107.02257481700005, 20.726125839000083],
              [107.02236773900006, 20.724398888000017],
              [107.02193834200006, 20.723990856000093],
              [107.02129119600006, 20.723987986000033],
              [107.01988649900002, 20.724489410000047],
              [107.01696310300008, 20.726710088000019],
              [107.01512079400005, 20.728427901000131],
              [107.01360152300002, 20.730248667000062],
              [107.01267623500011, 20.73191977400004],
              [107.01224067900004, 20.732730073000113],
              [107.01223759800006, 20.73333924000007],
              [107.01298750600003, 20.734357889000066],
              [107.01395366200003, 20.735275972000089],
              [107.0160005180001, 20.735792759000034],
              [107.0179917520001, 20.736406749000125],
              [107.02154872700007, 20.736930199000092],
              [107.02417053100001, 20.736917176000077],
              [107.02615103400009, 20.736702352000066],
              [107.02686866900005, 20.736624507000023],
              [107.03131051600005, 20.73638367000008],
              [107.03346881100001, 20.736190068000049],
              [107.03461279100007, 20.735947662000065],
              [107.03594543000003, 20.7356926470001],
              [107.03669964400008, 20.735775665000041],
              [107.03779614400003, 20.735916422000052],
              [107.03908845500011, 20.736436344000083],
              [107.039722635, 20.737521737000094],
              [107.03949941400001, 20.739043700000082],
              [107.03912965300006, 20.739455490000047],
              [107.0384152550001, 20.740155799000078],
              [107.038008319, 20.740330427000053],
              [107.03700998600009, 20.740758835000058],
              [107.03582344900005, 20.740753654000073],
              [107.03442168100007, 20.740645998000048],
              [107.0318328770001, 20.74063465600009],
              [107.02668335800008, 20.740631008000051],
              [107.02367264400002, 20.740743893000065],
              [107.02175717799999, 20.740859708000059],
              [107.01964549200005, 20.74060174100012],
              [107.01799882800006, 20.739786474000077],
              [107.01674584800004, 20.739470150000017],
              [107.01476247300008, 20.739958505000097],
              [107.01416508100002, 20.740577327000054],
              [107.01416225700005, 20.741136654000059],
              [107.01445945900005, 20.741709845000052],
              [107.01502308900005, 20.743405134000078],
              [107.01545852800011, 20.744657115000017],
              [107.01545432700003, 20.745490453000045],
              [107.01517751600004, 20.747822609000096],
              [107.01543794100009, 20.748740453000011],
              [107.01602811900007, 20.749763324000092],
              [107.01642615200005, 20.750617071000121],
              [107.01642233800001, 20.751374359000074],
              [107.01591561200009, 20.752129413000105],
              [107.014602815, 20.753164851000072],
              [107.01289054199999, 20.753630517000055],
              [107.01138230400008, 20.753529099000012],
              [107.010379841, 20.752861962000054],
              [107.00978115400002, 20.751912648000115],
              [107.00989229600007, 20.749830540000055],
              [107.010201717, 20.748317305000086],
              [107.00990669500003, 20.746990682000011],
              [107.00739898700002, 20.745654115],
              [107.00659436500005, 20.745650495000056],
              [107.00538357900005, 20.746402361],
              [107.00416603500004, 20.748479479000089],
              [107.00315154200003, 20.7501788490001],
              [107.00058186000004, 20.751680481000086],
              [106.99923637500009, 20.752096733000066],
              [106.99811857000006, 20.751774904000087],
              [106.99521782600004, 20.7498611440001],
              [106.99443967000005, 20.748801714000109],
              [106.99441596800004, 20.747935191000089],
              [106.99484483300009, 20.746937956000089],
              [106.99548452400009, 20.746141514],
              [106.99683144000007, 20.745148449000084],
              [106.99790030100004, 20.743288143000079],
              [106.99818790100002, 20.742290252000117],
              [106.99840863900009, 20.740559308000073],
              [106.99899953000003, 20.735499350000097],
              [106.99929803600004, 20.732369847000065],
              [106.99923424800001, 20.731037268000115],
              [106.99860657600003, 20.729502294000035],
              [106.99804474200009, 20.728900210000042],
              [106.99755147400003, 20.728698128000111],
              [106.99712574200008, 20.72909587700007],
              [106.996268109, 20.731090409000075],
              [106.99447805000003, 20.735878482000111],
              [106.9933550370001, 20.738317489000032],
              [106.99283876800011, 20.738801998000064],
              [106.99206163700002, 20.738865063000041],
              [106.99086113800008, 20.738859576000031],
              [106.98902647500007, 20.738584727000031],
              [106.98648392000011, 20.738639678000048],
              [106.98563512400004, 20.738902229000097],
              [106.98449936300004, 20.740029442000015],
              [106.98207996800005, 20.743548790000062],
              [106.97980450000007, 20.746535871000034],
              [106.97873753900008, 20.747996409000088],
              [106.97767370100007, 20.748857448000102],
              [106.97483907600011, 20.750709446000123],
              [106.97250191400008, 20.751964216000118],
              [106.97080031300007, 20.753221915000083],
              [106.96831516300006, 20.755741568000104],
              [106.96498210800006, 20.758323819000069],
              [106.96384433800007, 20.759783937000087],
              [106.96384151000009, 20.760316809000031],
              [106.96419251400002, 20.760718130000022],
              [106.96510888200007, 20.761055482000046],
              [106.96651997200003, 20.761328536000079],
              [106.96757899600007, 20.761400112000047],
              [106.96906247600008, 20.761340427000086],
              [106.97019354100004, 20.761145876000064],
              [106.9714672480001, 20.760685541000079],
              [106.97274165000002, 20.76009197100008],
              [106.97447972600004, 20.758289174000041],
              [106.97511304900004, 20.758183607000099],
              [106.97597504400004, 20.758350367000041],
              [106.97683276600004, 20.759330928000082],
              [106.97694439800004, 20.759982499000081],
              [106.97634037500006, 20.760708196000088],
              [106.9748916030001, 20.762269820000064],
              [106.973405767, 20.765157571000081],
              [106.97237604500006, 20.766419193000019],
              [106.96813965900002, 20.769474986000077],
              [106.96743086700008, 20.770496856000072],
              [106.96693699900007, 20.77235151600005],
              [106.96583477900001, 20.77521114100005],
              [106.96498182700003, 20.776584655000093],
              [106.96430474800006, 20.777160521000049],
              [106.96263128900006, 20.777661130000077],
              [106.96124575400009, 20.777980864000064],
              [106.96073911000009, 20.77846155500005],
              [106.96044539800003, 20.779599487000056],
              [106.95991665700008, 20.781658600000071],
              [106.95950759000007, 20.782850229000083],
              [106.95852176200003, 20.784310393000013],
              [106.95626125300005, 20.787446348000046],
              [106.95527682900004, 20.788635238],
              [106.95435195800007, 20.789444644000099],
              [106.952549762, 20.790717835000102],
              [106.95069357500007, 20.792037491000045],
              [106.94928029300011, 20.792861057000067],
              [106.94786969300002, 20.793186462000079],
              [106.94522590800003, 20.793588985000078],
              [106.93835385400004, 20.794303305000113],
              [106.92751758200006, 20.795330302000089],
              [106.92310902600008, 20.796388214000046],
              [106.91781897500007, 20.797607774000056],
              [106.91658255099999, 20.798265943000068],
              [106.91560935100003, 20.799091445000073],
              [106.91516446600008, 20.799919539000108],
              [106.91515800600007, 20.801081905],
              [106.91532211900007, 20.803241437000075],
              [106.91584677100005, 20.803908233000087],
              [106.91672548400001, 20.804244656000037],
              [106.91725427800006, 20.804164227000044],
              [106.91778445300005, 20.803834717000043],
              [106.91805368600004, 20.802922731000081],
              [106.91832704800001, 20.801263510000112],
              [106.91868295400005, 20.800601032000095],
              [106.91938968500008, 20.80018934900005],
              [106.92150619800006, 20.799618499000069],
              [106.92644452500009, 20.798314121000061],
              [106.92970522300006, 20.797831776000031],
              [106.93410969600006, 20.797520973000069],
              [106.93745751500005, 20.79720499900013],
              [106.94459429500009, 20.796408952000078],
              [106.94864694200002, 20.7960131520001],
              [106.94935136800004, 20.796016506000022],
              [106.94978939000005, 20.796433736000083],
              [106.94951764200003, 20.797843926000056],
              [106.94844534500004, 20.80074481000009],
              [106.94773598900005, 20.80165473000006],
              [106.94685185900009, 20.802314743000046],
              [106.94498030700005, 20.803587435000011],
              [106.93989094100004, 20.806357311000042],
              [106.9386851780001, 20.807031562000056],
              [106.93810716600007, 20.807458667000041],
              [106.93706333900012, 20.809226546000033],
              [106.93559230400008, 20.811745840000114],
              [106.9344086130001, 20.813291404000012],
              [106.93284470100012, 20.815588609000102],
              [106.93260674000005, 20.816119322000048],
              [106.93269834000009, 20.816562987000076],
              [106.93316577400003, 20.817052783000101],
              [106.93494683900005, 20.818036465000134],
              [106.93635498300003, 20.818442149000035],
              [106.93898541500008, 20.818853701000073],
              [106.93984575300003, 20.818978150000063],
              [106.94247433300009, 20.819537352000062],
              [106.944692043, 20.820176179000072],
              [106.94635391600011, 20.820917034000082],
              [106.94657349000006, 20.821389245000077],
              [106.94651569800007, 20.821807776000064],
              [106.94576548800009, 20.822782487000069],
              [106.94496306000005, 20.824289172000121],
              [106.94441840500009, 20.824966594000038],
              [106.94339378400005, 20.825471711000084],
              [106.94150340300004, 20.825951995000125],
              [106.9387946530001, 20.826286983000038],
              [106.93725507400008, 20.826569573000064],
              [106.936760389, 20.827031168000062],
              [106.9366938040001, 20.827958818000077],
              [106.93712128700002, 20.828540856000096],
              [106.93803684300011, 20.82987921000008],
              [106.93974837700006, 20.831917344000047],
              [106.94078983400007, 20.832734310000056],
              [106.9429970840001, 20.834136828000013],
              [106.94379436000007, 20.834604623000082],
              [106.94501884800005, 20.835712422000086],
              [106.94605627500007, 20.837283298000031],
              [106.94720957100003, 20.840188644000079],
              [106.94848303300006, 20.844724102],
              [106.94833171400006, 20.845005798000038],
              [106.94769082400005, 20.84533681000007],
              [106.94585200100005, 20.845449617000078],
              [106.9451429370001, 20.845560001000059],
              [106.94408719600005, 20.845910650000029],
              [106.94297268400003, 20.84655533400008],
              [106.94212613900007, 20.847237416000027],
              [106.94135209900006, 20.848678196000044],
              [106.94049769600005, 20.850804707000044],
              [106.94049103000003, 20.852032486000027],
              [106.94067808200006, 20.852863954000078],
              [106.94105766800003, 20.853515783000098],
              [106.94143999900004, 20.853662066000119],
              [106.94213213000002, 20.853416957],
              [106.94255603400011, 20.853021451000053],
              [106.94289709, 20.85234728100005],
              [106.94328032500007, 20.851673311000063],
              [106.94374660900012, 20.851238253000034],
              [106.944592239, 20.850844767000076],
              [106.94606961700003, 20.850613307000017],
              [106.94716614200007, 20.850618537000095],
              [106.94775593800003, 20.850740601000069],
              [106.94809182900011, 20.851020477000048],
              [106.94813185800004, 20.851418197],
              [106.94816675400008, 20.852769970000026],
              [106.94852618600007, 20.85650846900009],
              [106.94882180100011, 20.858611223000075],
              [106.94936394100002, 20.859750678000033],
              [106.94941599000006, 20.860267691000089],
              [106.94941376400006, 20.8606810940001],
              [106.9490277460001, 20.861092662000047],
              [106.94858801700003, 20.861297281000098],
              [106.94803947000003, 20.861346344000076],
              [106.94573506600005, 20.86164540900004],
              [106.942935807, 20.862200469000044],
              [106.94200121900012, 20.862661082000052],
              [106.94144958300004, 20.863278544000075],
              [106.94122634800004, 20.864000947000072],
              [106.94122186200011, 20.864827740000088],
              [106.94127247900003, 20.865603120000031],
              [106.94159725200009, 20.86637981300008],
              [106.94195198600003, 20.866761569000047],
              [106.94370854200007, 20.868311085000087],
              [106.94497594300005, 20.86912439200006],
              [106.945468025, 20.869322438000012],
              [106.94607838900004, 20.869140036000111],
              [106.94734279800012, 20.868525966000014],
              [106.94866092900003, 20.868118836000079],
              [106.949209217, 20.868121445000028],
              [106.94970072400008, 20.868485508000042],
              [106.94975305400007, 20.868950840000089],
              [106.94969543900001, 20.869467324000098],
              [106.94930885700008, 20.869982243000074],
              [106.94864728500005, 20.870650876000063],
              [106.94847945000002, 20.871270174000053],
              [106.94830995300003, 20.872199530000032],
              [106.94813347899999, 20.87442072300005],
              [106.94801685300003, 20.875712046000046],
              [106.94806666400007, 20.876642430000025],
              [106.94827875600002, 20.877986987000071],
              [106.94871184000007, 20.879022550000045],
              [106.94925681900004, 20.879645232000051],
              [106.95002224300003, 20.880062272000096],
              [106.95079017000008, 20.880014247000034],
              [106.95139444000007, 20.879810421000045],
              [106.95216597400005, 20.879090637000083],
              [106.95365696100004, 20.877134060000024],
              [106.95398900600007, 20.876567207000015],
              [106.954212485, 20.875793141000095],
              [106.95400036400012, 20.874448584000049],
              [106.95335208900005, 20.872636877000069],
              [106.9528096130001, 20.871549129000044],
              [106.95264845700009, 20.870928260000099],
              [106.95265206300003, 20.870256496000117],
              [106.9527698750001, 20.868764879000068],
            ],
          ],
          [
            [
              [106.61588105500003, 21.018052358000091],
              [106.62069361400005, 21.01406489500004],
              [106.62367606200009, 21.012866036000055],
              [106.62652317600002, 21.012518514000078],
              [106.63040208900006, 21.012542577000112],
              [106.63479392800009, 21.013178455000059],
              [106.63724717200003, 21.013680571000037],
              [106.63943419500006, 21.015276711000027],
              [106.64251363200005, 21.018704461000048],
              [106.64457398600008, 21.01993453500004],
              [106.64676789100005, 21.020556689],
              [106.64987199400012, 21.020453926000116],
              [106.65582293600001, 21.018454778000084],
              [106.65609278100004, 21.018422194000053],
              [106.66004235900007, 21.016970028000067],
              [106.66507736400003, 21.015118637000093],
              [106.67429633300004, 21.01165230800008],
              [106.68070485100004, 21.008902536000029],
              [106.68586250600002, 21.006732105000125],
              [106.69164044400007, 21.005005454000077],
              [106.69679099800008, 21.003861933000053],
              [106.70225512800002, 21.002426630000045],
              [106.70568648400007, 21.002006518000101],
              [106.70896295500009, 21.001438702000108],
              [106.71270885700005, 21.00058008000012],
              [106.71973594499998, 20.998419710000071],
              [106.72084672400005, 20.99835145900002],
              [106.72191859400002, 20.998285594000073],
              [106.72410028000004, 20.99829819600005],
              [106.7262810070001, 20.998457519000091],
              [106.72923708400008, 20.999208264000032],
              [106.73203639000005, 21.000104806000053],
              [106.73452690900007, 21.000559316000086],
              [106.73748780100009, 21.000576245000069],
              [106.74029569900006, 21.000152025000048],
              [106.74560734300007, 20.998127807000074],
              [106.75014347700005, 20.995512084000012],
              [106.75311743400002, 20.993474433000081],
              [106.75703940400007, 20.989387617000062],
              [106.76190733100005, 20.983545016000079],
              [106.76442374700007, 20.979890380000121],
              [106.76536889100002, 20.978281408000043],
              [106.76590328600001, 20.976318121000048],
              [106.76600703100007, 20.975936974000042],
              [106.76609505900007, 20.975210649000076],
              [106.7664669910001, 20.972700740000057],
              [106.76666463800004, 20.970510856000054],
              [106.76734355600007, 20.961709480000046],
              [106.76785455600007, 20.95481486500006],
              [106.76788144600008, 20.950559094000099],
              [106.76801296200003, 20.945149845000088],
              [106.76809193300008, 20.941901578000085],
              [106.76853355600012, 20.920052090000048],
              [106.76806822500008, 20.915250397000037],
              [106.76658509800002, 20.910843758000027],
              [106.76155658700007, 20.900513599000092],
              [106.75509772500008, 20.890197940000022],
              [106.75342200700007, 20.886754420000031],
              [106.75237526700006, 20.884650265000104],
              [106.75214184000002, 20.882153246000087],
              [106.752134993, 20.881563030000017],
              [106.74881386, 20.881326190000046],
              [106.74410989799999, 20.880905958000092],
              [106.74233379800003, 20.880600695000062],
              [106.73869037200002, 20.877923533000065],
              [106.73391257100003, 20.872780152000111],
              [106.73173228300003, 20.870307994000115],
              [106.73006216200004, 20.869806463000096],
              [106.72891367200005, 20.869504694000085],
              [106.72745593900005, 20.868512438000053],
              [106.72505248400005, 20.868203414000064],
              [106.72347860300003, 20.86898142200004],
              [106.72253542800007, 20.869271123000118],
              [106.72159806100004, 20.868675383000031],
              [106.72138539100004, 20.868309983000088],
              [106.72254048000003, 20.867329689000066],
              [106.73281388700008, 20.860092422000065],
              [106.73978881100004, 20.856804103000037],
              [106.74809216600006, 20.853728666000038],
              [106.75018541500006, 20.85310323900007],
              [106.75159224000002, 20.852286073000045],
              [106.75175504, 20.851973066000085],
              [106.75191798800006, 20.851636807000062],
              [106.75200642600001, 20.851346633000034],
              [106.75222524, 20.85060125600009],
              [106.75174157300003, 20.847721116000081],
              [106.751961232, 20.843122373000078],
              [106.7518927240001, 20.83857596800005],
              [106.75199638100007, 20.834154448000049],
              [106.75204664700006, 20.828995169000031],
              [106.75234965000007, 20.825077065000031],
              [106.75338434400007, 20.822643901000056],
              [106.75618814100007, 20.818826966000081],
              [106.75805986500004, 20.815875818000059],
              [106.76109541700002, 20.81178521800009],
              [106.75890576400003, 20.810414963000042],
              [106.75921638900007, 20.80914118700008],
              [106.75922834699999, 20.807259978000033],
              [106.75796364100006, 20.80443092800008],
              [106.751483562, 20.792568978000055],
              [106.74669355100008, 20.783941406000039],
              [106.74485486499999, 20.781780831000063],
              [106.74286767100006, 20.780560073000039],
              [106.74016194200001, 20.780275880000062],
              [106.73830330900006, 20.780368333000077],
              [106.73773861000005, 20.780396419000091],
              [106.73502157200005, 20.78185908300005],
              [106.73330199700005, 20.783327436000043],
              [106.73202036800006, 20.783185696000125],
              [106.73145822700009, 20.781973017000062],
              [106.73189532900001, 20.78049731100009],
              [106.73347674700003, 20.778356250000037],
              [106.73562637800008, 20.776487195000115],
              [106.73948415400007, 20.774896654000052],
              [106.742336634, 20.774509771000012],
              [106.74946560800001, 20.773878352000082],
              [106.75317472700004, 20.773227410000047],
              [106.755605669, 20.771897277000036],
              [106.76162782700001, 20.766018168000052],
              [106.76937296000003, 20.757998259000132],
              [106.77583266400009, 20.750374023000084],
              [106.77777333400007, 20.74762978000004],
              [106.77865113500006, 20.746200453000085],
              [106.78007642400004, 20.743879592000077],
              [106.78325851800007, 20.736236822000095],
              [106.7861529460001, 20.728995526000027],
              [106.78759840600006, 20.725643618000063],
              [106.78903715100009, 20.723366802000065],
              [106.79104069000005, 20.721899438000065],
              [106.79246524200005, 20.721907238000092],
              [106.79445879600006, 20.722052535000032],
              [106.79644571700005, 20.723272941000026],
              [106.79815271100007, 20.72368543100005],
              [106.79900992900012, 20.723286913000024],
              [106.79930393000002, 20.721810162000054],
              [106.79917470000008, 20.719659125000064],
              [106.79804912200002, 20.717368258000057],
              [106.79423859900004, 20.711568396000054],
              [106.79127380700005, 20.707251429000095],
              [106.79020800900007, 20.704612305000047],
              [106.79023335500003, 20.702743357000131],
              [106.79080516900009, 20.700678064000044],
              [106.79154110600005, 20.698584121000074],
              [106.79225578500004, 20.698184833000077],
              [106.79296798000007, 20.698188731000045],
              [106.79424992600009, 20.698195741000092],
              [106.79525030200008, 20.697663612000028],
              [106.79625813600008, 20.695921902000066],
              [106.79626476200009, 20.694846728000101],
              [106.79570164800003, 20.693768444000057],
              [106.79429472100007, 20.690938320000015],
              [106.79388235500005, 20.688516823000036],
              [106.79417468300008, 20.68730880600009],
              [106.79498357500005, 20.686571990000054],
              [106.79760292900005, 20.685714682000089],
              [106.79998640200009, 20.684669053000079],
              [106.80260937200005, 20.682247421000085],
              [106.80490381400008, 20.679706185000057],
              [106.80619712800006, 20.677831532000113],
              [106.806202877, 20.676890729000071],
              [106.80478605200003, 20.675673410000073],
              [106.8029395090001, 20.674856965000018],
              [106.80165854900007, 20.674715604000049],
              [106.80065912400006, 20.67511338900001],
              [106.79951232200003, 20.676316798000101],
              [106.79649992900001, 20.679794908000112],
              [106.79205651100007, 20.68434034700006],
              [106.79033073300009, 20.687018955],
              [106.7898917840001, 20.68889818600006],
              [106.79030743000003, 20.69078209900006],
              [106.79086468200003, 20.69280118300005],
              [106.79085719500004, 20.694010748000032],
              [106.78999509400003, 20.695215634000071],
              [106.78895286400004, 20.695881080000063],
              [106.78543303600004, 20.696093356000041],
              [106.78403867900005, 20.696626126000069],
              [106.78296920900002, 20.697546712000033],
              [106.78288064700007, 20.698627113000079],
              [106.78328496000003, 20.699401416000015],
              [106.7844247360001, 20.700334178000013],
              [106.78425580200008, 20.701182517000035],
              [106.78367581500004, 20.702337411000116],
              [106.7826900580001, 20.702949628000056],
              [106.78072382600004, 20.703324800000097],
              [106.77769480700007, 20.703539662000111],
              [106.77335220100011, 20.704442034000081],
              [106.77184152300001, 20.705329730000074],
              [106.76868405500005, 20.709075298000101],
              [106.76724263300007, 20.71175521300006],
              [106.7659461640001, 20.714032716000119],
              [106.76494732400005, 20.714295922000048],
              [106.76338292700011, 20.713883967000065],
              [106.75755874700005, 20.711297699000042],
              [106.74600962200006, 20.706157990000037],
              [106.73967823600005, 20.702431885000024],
              [106.73334783000011, 20.69859026500005],
              [106.73269158000008, 20.69817942300007],
              [106.72312360500005, 20.692189001000116],
              [106.71788688800002, 20.689391038000068],
              [106.71483935500002, 20.688220150000092],
              [106.71020093300011, 20.68738585600007],
              [106.70470407300004, 20.687007704000045],
              [106.70018101900008, 20.687211735000055],
              [106.69858930400001, 20.687663612000051],
              [106.69682620300006, 20.689398735000083],
              [106.693623325, 20.689195628000064],
              [106.69335233200005, 20.689178441000067],
              [106.69061629600006, 20.689004897000096],
              [106.69262216300002, 20.684514772000014],
              [106.69496633200001, 20.681184452000068],
              [106.69755021100011, 20.678547413000011],
              [106.70111541100003, 20.675339516000065],
              [106.70210294400003, 20.673846178000055],
              [106.7023549900001, 20.672694477000064],
              [106.70212362800005, 20.670732687000012],
              [106.69959210700003, 20.665528372000082],
              [106.697661673, 20.661826717000054],
              [106.69421131000007, 20.657020476000106],
              [106.69166320000008, 20.654352966000111],
              [106.68741081500005, 20.650752685000029],
              [106.68218038500001, 20.647261793000027],
              [106.67463662900005, 20.642603619000077],
              [106.66940978700002, 20.638651094000068],
              [106.66539461499998, 20.636204989000092],
              [106.66283533400006, 20.635266879000099],
              [106.65551937100003, 20.633146524000075],
              [106.64710247100004, 20.631249822000086],
              [106.63391315700005, 20.630476550000054],
              [106.629027256, 20.630330900000082],
              [106.62524330900008, 20.62984603000011],
              [106.62378136300001, 20.62926027900005],
              [106.62329676400006, 20.628680612000046],
              [106.6233048870001, 20.627527397000023],
              [106.62064884100008, 20.626335361000066],
              [106.61835907800007, 20.626024972000053],
              [106.6138820690001, 20.627339003000067],
              [106.61032649400008, 20.628676518000063],
              [106.60798830200005, 20.630230871000066],
              [106.60586946400007, 20.632100411000032],
              [106.60385616500011, 20.634702830000059],
              [106.60035887400002, 20.640432517000114],
              [106.59724224399999, 20.640320759000105],
              [106.59442183400004, 20.639659120000076],
              [106.592421491, 20.638650147000078],
              [106.58976648400005, 20.636778790000051],
              [106.58706682100006, 20.632899365000057],
              [106.584751587, 20.629758009000021],
              [106.5835893280001, 20.628830983000057],
              [106.57601751900005, 20.625195907000112],
              [106.56854136599999, 20.621837036000024],
              [106.56669470000006, 20.621273290000097],
              [106.56533102100011, 20.62126442100007],
              [106.56445234400006, 20.621534578000045],
              [106.56298381200006, 20.622536545000081],
              [106.56072383000003, 20.625188572000042],
              [106.55856526200003, 20.627289483000055],
              [106.55719678900005, 20.627924227000079],
              [106.55426975500008, 20.628548749000061],
              [106.55085701800006, 20.628986135000012],
              [106.54774059800005, 20.628873676000033],
              [106.54579376300008, 20.628676940000013],
              [106.54274284900006, 20.62631191100008],
              [106.54051619600003, 20.624458044000079],
              [106.53984603000001, 20.622890327000043],
              [106.53985839600006, 20.621235176000049],
              [106.54008892100003, 20.616454878000035],
              [106.53962251900008, 20.613693034000043],
              [106.53867187800003, 20.610560130000088],
              [106.53731996400008, 20.608987861000024],
              [106.53470120800003, 20.607499116000035],
              [106.5290728410001, 20.604702845000041],
              [106.525380071, 20.603574655000045],
              [106.52156463500006, 20.603235649000062],
              [106.52090259699999, 20.60317681800008],
              [106.51856368900009, 20.603345037000054],
              [106.51758762300007, 20.603614360000044],
              [106.51729125400006, 20.604164124000057],
              [106.51678194100002, 20.607103416000072],
              [106.51628097900004, 20.608939237000087],
              [106.51539041500007, 20.610772437000065],
              [106.51391822700003, 20.612233861000014],
              [106.51254481700009, 20.613512028000081],
              [106.51049237100008, 20.614417759000055],
              [106.50580581900002, 20.61585739300007],
              [106.50073452000007, 20.616650594000063],
              [106.49171869600008, 20.617370755000053],
              [106.490939466, 20.617365423000052],
              [106.49007064900006, 20.616347932000068],
              [106.48921250200002, 20.613951133000086],
              [106.48777992800007, 20.610262941000101],
              [106.4847940320001, 20.605920332000061],
              [106.48141654300007, 20.601850841000058],
              [106.47909335800004, 20.599995614000122],
              [106.47656825800001, 20.599058571000036],
              [106.47218551300003, 20.599028243000106],
              [106.47013735000012, 20.599381878000052],
              [106.46915764600006, 20.600110772000058],
              [106.46836983800006, 20.601208831000051],
              [106.46816639500003, 20.602310946000024],
              [106.46784321900002, 20.60626299300008],
              [106.46752939300006, 20.609019605000078],
              [106.466635486, 20.611220420000095],
              [106.46576951600012, 20.612507232000077],
              [106.46562419800009, 20.612723156000087],
              [106.46279998500009, 20.615883620000105],
              [106.45820102700006, 20.618518313000052],
              [106.45530491000007, 20.619203277000061],
              [106.45385817900009, 20.619545428000038],
              [106.44995616500009, 20.620253696000113],
              [106.448684063, 20.620980421000112],
              [106.4481904670001, 20.621804568000087],
              [106.448183896, 20.622632141000082],
              [106.44875301100011, 20.624567255000059],
              [106.451443974, 20.629184034000048],
              [106.4528716240001, 20.633424059000035],
              [106.45322852000004, 20.637564582000053],
              [106.45321396400008, 20.639403589000068],
              [106.45251969000002, 20.640961955000044],
              [106.44986322300004, 20.644253680000091],
              [106.44386199900008, 20.650916304000063],
              [106.441592927, 20.653883818000018],
              [106.44183103700007, 20.654125069000081],
              [106.442033108, 20.654623465000022],
              [106.44218784400002, 20.655315989000123],
              [106.44229782700006, 20.65587855800004],
              [106.44229472700007, 20.656267462000066],
              [106.4421317210001, 20.656612031000066],
              [106.44136799800005, 20.657643774000078],
              [106.44081236500008, 20.658417725000078],
              [106.43993432600008, 20.659427053000101],
              [106.43732445800006, 20.662282318000088],
              [106.43653693500009, 20.662949864000076],
              [106.43485774500007, 20.664403919000037],
              [106.43160739700008, 20.667168146000044],
              [106.42985548800007, 20.66864655600007],
              [106.42674357800007, 20.671260418000067],
              [106.42549927800006, 20.672245459000038],
              [106.42522419600003, 20.672286710000073],
              [106.42488095600005, 20.672262652000128],
              [106.424286753, 20.67212876300006],
              [106.42367296300003, 20.671584216000035],
              [106.42309057400007, 20.671043108000028],
              [106.42136931600002, 20.674374077000031],
              [106.41886263500001, 20.677323919000031],
              [106.41353322200004, 20.681346830000088],
              [106.40653691800007, 20.686763325000079],
              [106.40404388200002, 20.687994877000015],
              [106.40205633300008, 20.688136690000114],
              [106.40006238500003, 20.689059417000074],
              [106.39922709300001, 20.689990536000025],
              [106.3992130140001, 20.691708595000065],
              [106.40018570300002, 20.694214788000039],
              [106.40477839400006, 20.699402471000013],
              [106.40804383400005, 20.703893878000109],
              [106.40831001100005, 20.704171090000045],
              [106.40834593500009, 20.705890049000075],
              [106.40875295700002, 20.707022066000036],
              [106.40978313400008, 20.708305858000116],
              [106.41148291500012, 20.710428985000107],
              [106.41297659700007, 20.712305171000089],
              [106.41379950400004, 20.713489252],
              [106.41415733900007, 20.714277272000103],
              [106.41441072100008, 20.715113626000026],
              [106.41450322300008, 20.716537896000098],
              [106.41428075000007, 20.718303535000082],
              [106.41423487200009, 20.719107742000098],
              [106.41225395400005, 20.720316874000041],
              [106.40770907400007, 20.723211343000081],
              [106.40699237700001, 20.724126147],
              [106.4066295910001, 20.725127144000055],
              [106.40570145100004, 20.730222116000085],
              [106.40507669000004, 20.733103138000075],
              [106.40739218900005, 20.734076259000034],
              [106.41203732900007, 20.736028334000075],
              [106.41368267400006, 20.73620368600011],
              [106.41559000500004, 20.736135630000071],
              [106.42005659300003, 20.735485156000074],
              [106.42419623300006, 20.734156100000064],
              [106.42747752800005, 20.732096220000074],
              [106.43037688000004, 20.729761831000104],
              [106.43173633700007, 20.727869354000106],
              [106.43252478800004, 20.725338759000067],
              [106.43350959300008, 20.722266066000117],
              [106.43447695400009, 20.721367140000076],
              [106.4357265180001, 20.721194841000035],
              [106.45126990600008, 20.722572599000074],
              [106.45357413400004, 20.722588761000083],
              [106.45778997499998, 20.723705207000087],
              [106.45980047100007, 20.724443887000099],
              [106.46180457100006, 20.725997701000054],
              [106.462657259, 20.727452903000106],
              [106.462783852, 20.727820222000034],
              [106.46340825100006, 20.72963200500002],
              [106.46643586200001, 20.747587272000075],
              [106.46937207000003, 20.752770442000035],
              [106.47089499400001, 20.754501914000031],
              [106.47204304000005, 20.75505331700009],
              [106.47319535200009, 20.755061292000043],
              [106.47531852400009, 20.753717349000041],
              [106.47859748100005, 20.751928484000089],
              [106.4801373990001, 20.751486216000089],
              [106.48253939600006, 20.751321603000058],
              [106.486085261, 20.752251703000098],
              [106.48828750400004, 20.753081970000054],
              [106.490100076, 20.754634141000047],
              [106.49381572100003, 20.758463653],
              [106.49616008300004, 20.761056866000068],
              [106.49426468800006, 20.761588771000028],
              [106.49236007000003, 20.762114154000066],
              [106.49102049800004, 20.763109908000033],
              [106.49056556200006, 20.764530410000056],
              [106.490733425, 20.765787690000082],
              [106.49345097900006, 20.77032830100007],
              [106.49547121000006, 20.773189278000089],
              [106.49613895800006, 20.775268511],
              [106.49611080400004, 20.77892354800008],
              [106.49455656300006, 20.785004952000108],
              [106.4919593270001, 20.790190766000102],
              [106.48858034600002, 20.795498067000025],
              [106.48567954900008, 20.79900004000006],
              [106.48436278700004, 20.800437811000094],
              [106.483374928, 20.801070311000025],
              [106.48406138400006, 20.801313988000025],
              [106.48499787400009, 20.802065619000039],
              [106.48754074100007, 20.804969720000074],
              [106.49128876400003, 20.80801804000005],
              [106.49502824300001, 20.809132614000077],
              [106.49724124600004, 20.809503299000106],
              [106.49898486700008, 20.809604056000097],
              [106.50006917500009, 20.809611425000085],
              [106.50082551900005, 20.809349857000115],
              [106.50125288100006, 20.808952704000113],
              [106.50210964800007, 20.80789169300008],
              [106.50463546900008, 20.804352726],
              [106.50563634200002, 20.802937056000111],
              [106.5065853020001, 20.80214335200008],
              [106.50775381000007, 20.804149991000109],
              [106.50915633600003, 20.806558441000078],
              [106.51266562700005, 20.812325259000097],
              [106.51366715100005, 20.814126725000023],
              [106.51402872000003, 20.816641765000021],
              [106.51400777000008, 20.819393509000058],
              [106.51386628900009, 20.821306906000103],
              [106.5130966630001, 20.822378537000041],
              [106.51118578600008, 20.823322832000102],
              [106.50889966700002, 20.823546702000058],
              [106.50585392300007, 20.823526113000042],
              [106.50306475900005, 20.823148275000044],
              [106.49723816600006, 20.821672937000102],
              [106.49191372900006, 20.820918759000051],
              [106.49000832700006, 20.821145025000121],
              [106.48771941800003, 20.821727596000095],
              [106.48631604600008, 20.822675152000066],
              [106.48617800300012, 20.824109963000033],
              [106.48654758600006, 20.825548250000026],
              [106.48931638900009, 20.828558346000037],
              [106.49321176800007, 20.83361005200004],
              [106.49420399100011, 20.836607916000013],
              [106.49481641000006, 20.839483537000092],
              [106.49480256900007, 20.84127808700007],
              [106.49415506400005, 20.842948676],
              [106.49274787500006, 20.844374786000053],
              [106.48981392600004, 20.846269016000107],
              [106.48407742000005, 20.849460036000075],
              [106.48276157900006, 20.850591738000013],
              [106.48199837300007, 20.851248119000054],
              [106.48062722200004, 20.852427348000013],
              [106.4752393790001, 20.859688268000106],
              [106.47963570800006, 20.862721212000054],
              [106.47959397500007, 20.86297590000008],
              [106.47958639500007, 20.86394860500009],
              [106.48014382000009, 20.86465989700006],
              [106.48088777700011, 20.865204261000066],
              [106.479011187, 20.868726314000071],
              [106.47916506800003, 20.869021772000096],
              [106.48001774200006, 20.869837241000063],
              [106.48079576900004, 20.870210592000049],
              [106.48126488100004, 20.870140217000085],
              [106.48244481600005, 20.869044331000097],
              [106.48354435800007, 20.868242282000082],
              [106.48532130500011, 20.867239554000072],
              [106.48696824500004, 20.867890303000088],
              [106.48959054500003, 20.868527266000036],
              [106.49090547200004, 20.868359392000087],
              [106.49279358100003, 20.86686894500005],
              [106.49479050300005, 20.863433719000035],
              [106.49565604300008, 20.860698213000092],
              [106.49605108900008, 20.858136362000046],
              [106.49626190100003, 20.855131067000073],
              [106.49673848500002, 20.85416154100006],
              [106.49777389300007, 20.85372642400003],
              [106.49889698400008, 20.854087794000087],
              [106.50029407200005, 20.855423789000049],
              [106.50093857500008, 20.857019960000045],
              [106.50184214900005, 20.861536158000057],
              [106.50304622600004, 20.863578266],
              [106.50454129100009, 20.864384277000077],
              [106.50753957600003, 20.864935159000098],
              [106.51072957700009, 20.864956692000099],
              [106.51870861100009, 20.864479751000076],
              [106.52180880100009, 20.863969931000078],
              [106.52256541100007, 20.863179111000107],
              [106.52304322300009, 20.862032685000109],
              [106.52296144800007, 20.860440357000073],
              [106.52158360900003, 20.856540092000039],
              [106.52150385800009, 20.854682460000085],
              [106.52151390800003, 20.853356032000114],
              [106.52227315800006, 20.852211474000093],
              [106.52377557400011, 20.852044655000057],
              [106.52649361200011, 20.852416541000096],
              [106.5286414130001, 20.8537573670001],
              [106.53012851500004, 20.855624371000125],
              [106.53188313900009, 20.859350218000117],
              [106.53224844000003, 20.860679137000112],
              [106.5326024510001, 20.863511317000061],
              [106.53277281099999, 20.865811683000054],
              [106.53313946900003, 20.866963732000073],
              [106.53425805200004, 20.867943898000085],
              [106.53612659600002, 20.869017474000096],
              [106.53949836300011, 20.869835671000111],
              [106.54278160500007, 20.869945793000078],
              [106.54494225000005, 20.869606313000055],
              [106.54595049200007, 20.868882828000054],
              [106.54665153900008, 20.869940215000057],
              [106.54967955500007, 20.874507205000015],
              [106.55003823000007, 20.87578170400004],
              [106.55002533800007, 20.877516360000037],
              [106.54914485700006, 20.880401795000033],
              [106.546657012, 20.884895710000073],
              [106.54566494400007, 20.886276956000046],
              [106.54455618900008, 20.886847888000126],
              [106.54283815200004, 20.886836562000077],
              [106.54035465500007, 20.886617778000094],
              [106.53943254600009, 20.886842978000075],
              [106.53801307400005, 20.887932239000051],
              [106.53751700800004, 20.888622831000077],
              [106.53751093900007, 20.889432327000087],
              [106.53786696200004, 20.891053735000042],
              [106.53827308300006, 20.894178871000065],
              [106.53854565200007, 20.898748670000074],
              [106.53835117700004, 20.900135120000037],
              [106.53809878700012, 20.901058612000064],
              [106.53754262800003, 20.90157532700001],
              [106.53637239300011, 20.90214580600005],
              [106.533789492, 20.902880364000076],
              [106.53230980600004, 20.903795696000046],
              [106.52799037100006, 20.90694716600003],
              [106.52607715200003, 20.908379962000097],
              [106.52564321800001, 20.90895528],
              [106.52550956700006, 20.910399926000075],
              [106.52523263300006, 20.914561210000038],
              [106.52522607500005, 20.91542848300012],
              [106.52534268400009, 20.916238754000105],
              [106.52589237200002, 20.916589344000066],
              [106.526689715, 20.916652486000054],
              [106.52736475500008, 20.916656987000067],
              [106.52887297700008, 20.916722617000083],
              [106.53122792000006, 20.917204214000051],
              [106.53297234600007, 20.91793262],
              [106.53441246800003, 20.918658992000069],
              [106.53736489500007, 20.920382816000057],
              [106.53966460300009, 20.922684846000017],
              [106.54042678100009, 20.924041179000064],
              [106.54063965800003, 20.925082037000031],
              [106.54051300100004, 20.927264055000094],
              [106.5402658940001, 20.930796559000072],
              [106.54025189100001, 20.932667464000062],
              [106.54111821900003, 20.934856013000044],
              [106.54143756400006, 20.936417281000082],
              [106.54131323100007, 20.9382874490001],
              [106.54084553100002, 20.939336391000026],
              [106.54102973700007, 20.942689106000053],
              [106.54139405800001, 20.944766891000107],
              [106.54199270800008, 20.945987433000113],
              [106.54282035600008, 20.947066362000029],
              [106.54475077000011, 20.948935505000051],
              [106.54508199600006, 20.948439534000052],
              [106.54625258000002, 20.94796186600005],
              [106.54735756900006, 20.947925774000083],
              [106.54947033700007, 20.948546671000123],
              [106.55144311000005, 20.949426780000024],
              [106.55231668400003, 20.94956258000007],
              [106.55310140800003, 20.949264210000074],
              [106.55375127800008, 20.948531371000101],
              [106.55417837500006, 20.946799824],
              [106.55423629600006, 20.945195932000082],
              [106.55417523400007, 20.940848924000122],
              [106.55407190200005, 20.935062718],
              [106.55451873000007, 20.933963634000108],
              [106.55588983800001, 20.933239633000063],
              [106.55796093800004, 20.933253159000031],
              [106.56022228200001, 20.933863114000047],
              [106.561969795, 20.93483875400004],
              [106.56401223100006, 20.936544516000041],
              [106.56565128600005, 20.938983278000123],
              [106.56632382400001, 20.941415749000036],
              [106.56612306300009, 20.946712247000043],
              [106.56606224900004, 20.95195018],
              [106.56666242100005, 20.95291441200002],
              [106.56780148000006, 20.953305562000054],
              [106.56973229700003, 20.953072045000098],
              [106.57310876600008, 20.951303151000019],
              [106.57560979900006, 20.949253072000126],
              [106.57654080200008, 20.947858404000016],
              [106.57691694400003, 20.945173967000038],
              [106.57629564300005, 20.943453347000052],
              [106.57362101100009, 20.941250802000063],
              [106.56925954800008, 20.937778846000029],
              [106.56883301100005, 20.936123075000076],
              [106.56928278900006, 20.934610731000092],
              [106.57163943800001, 20.932284193000058],
              [106.57383673000008, 20.931747385000051],
              [106.57646663, 20.932039840000094],
              [106.57908951200002, 20.933296489000064],
              [106.58068287500005, 20.935373006000098],
              [106.58385870900001, 20.941041148000039],
              [106.58618890700008, 20.944934940000067],
              [106.58713018400007, 20.946247076000098],
              [106.58799711700003, 20.946887016000026],
              [106.5889467960001, 20.947042353000086],
              [106.59294940700008, 20.946808908000044],
              [106.591049717, 20.94790138800002],
              [106.59024144600001, 20.950144564000084],
              [106.590650345, 20.952072188000059],
              [106.59085024000002, 20.953666575000085],
              [106.59063163500005, 20.95466088000002],
              [106.5901330730001, 20.955387875000042],
              [106.58771978500009, 20.957762117000108],
              [106.58466400900002, 20.961260662000122],
              [106.58352536800005, 20.962780075000076],
              [106.58309433100001, 20.963905751000041],
              [106.58301420800004, 20.965232802000067],
              [106.5832039260001, 20.968221036000045],
              [106.58381265000003, 20.971742972000037],
              [106.58379915100005, 20.973601452000068],
              [106.58351097200001, 20.974462520000081],
              [106.58287186300007, 20.975122194000036],
              [106.58152845300003, 20.975710975000084],
              [106.57969217200007, 20.976230201000057],
              [106.57224246300007, 20.978140324],
              [106.56842689900003, 20.979443178000068],
              [106.56623620300005, 20.98022550500005],
              [106.56482028100004, 20.981079213000072],
              [106.5637572210001, 20.981868832000124],
              [106.5618218970001, 20.984153457000062],
              [106.56037419800005, 20.985862394000065],
              [106.55590017100006, 20.991143485000038],
              [106.55433085600006, 20.993655497000049],
              [106.55361124000004, 20.995642040000106],
              [106.55303380200004, 20.997345545000101],
              [106.55304422500011, 20.997600823000084],
              [106.55315385000003, 21.000285290000065],
              [106.55369016300008, 21.004005767000052],
              [106.55612898800007, 21.008578993000079],
              [106.56250714200004, 21.019540774000056],
              [106.56273177800007, 21.020004354000044],
              [106.56306415600004, 21.020259987000053],
              [106.5633943660001, 21.020308693000089],
              [106.56394436500004, 21.020355375000044],
              [106.56438231400004, 21.020196132000102],
              [106.56492799200007, 21.019828940000096],
              [106.56573974700009, 21.018631493000051],
              [106.56654177700001, 21.016502852000052],
              [106.56723335100003, 21.014323473000026],
              [106.56793518400011, 21.013127030000064],
              [106.56853468600006, 21.012655871000071],
              [106.56941106600001, 21.012389091000024],
              [106.57045282000004, 21.012172521000046],
              [106.57155167700002, 21.012162382],
              [106.57254281700006, 21.012360173000033],
              [106.57353883400003, 21.013023558000029],
              [106.57442986900004, 21.014153551000092],
              [106.57543787100005, 21.01595504500002],
              [106.57621905200003, 21.017086042000074],
              [106.57677284500001, 21.017494819000056],
              [106.57743490100007, 21.017747369000084],
              [106.57825907400006, 21.017739718000072],
              [106.57974148100006, 21.017622483000054],
              [106.58368929300012, 21.016809703000035],
              [106.58582609899999, 21.016220685000064],
              [106.58747168000008, 21.015946651000036],
              [106.58807661100003, 21.015992731000033],
              [106.58868208100004, 21.016090557000041],
              [106.60012858700006, 21.017690612000095],
              [106.60238792000004, 21.018290186000051],
              [106.60597868600004, 21.020067123000082],
              [106.60985597800004, 21.022247934000049],
              [106.61588105500003, 21.018052358000091],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 28, code: "AD01", ten_tinh: "Hậu Giang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.57909694300004, 9.985845098000111],
              [105.58077705900006, 9.984913144000094],
              [105.58238366900011, 9.984078611000056],
              [105.58496130000005, 9.981251824000113],
              [105.59713742400004, 9.968166715000047],
              [105.59742108200007, 9.967863127000063],
              [105.60043114800007, 9.964662812000123],
              [105.60323842100009, 9.961638296000073],
              [105.61192736900003, 9.952368166],
              [105.6128255560001, 9.951409841000023],
              [105.61737367800001, 9.94654015700007],
              [105.62605185300004, 9.950181072000051],
              [105.62728464600008, 9.952345289000062],
              [105.62742478600003, 9.95424074000004],
              [105.62742788500003, 9.955865635000103],
              [105.62838637600001, 9.957353315000093],
              [105.63030285800005, 9.960057879000049],
              [105.63139711000008, 9.961139072000067],
              [105.6324890330001, 9.961001607000062],
              [105.63357938100008, 9.960051681000069],
              [105.63480624300007, 9.959101497000054],
              [105.63603492600004, 9.959099161000038],
              [105.64027156100011, 9.961392999000028],
              [105.6425976570001, 9.964051555000085],
              [105.64405885200003, 9.966576350000088],
              [105.64525059000005, 9.970907086000034],
              [105.64543439400006, 9.97180945700009],
              [105.64525378500004, 9.972531974],
              [105.64461825900003, 9.97334564],
              [105.64443782400011, 9.974158449000113],
              [105.64462162700005, 9.975060808000078],
              [105.64535261900005, 9.976503738000096],
              [105.64549000100004, 9.976939760000024],
              [105.65059300500012, 9.974424450000095],
              [105.65842858400006, 9.971763358000095],
              [105.65769205500006, 9.967362219000096],
              [105.66635937200007, 9.967345037000095],
              [105.66926984900002, 9.966346225000054],
              [105.67090720800005, 9.965891586000096],
              [105.67194330400005, 9.965207545000075],
              [105.6714617870001, 9.9645723610001],
              [105.67111452900005, 9.964118857000058],
              [105.67090813000003, 9.963872522000104],
              [105.67000311000002, 9.962932280000112],
              [105.66937725900002, 9.962272120000113],
              [105.66864997600007, 9.961545677000048],
              [105.66814509400004, 9.961112372000079],
              [105.66613835400008, 9.959291064000107],
              [105.66539207100007, 9.95865620700007],
              [105.664975892, 9.958302165000086],
              [105.66468206700003, 9.958033464000058],
              [105.66116675400005, 9.954781144000078],
              [105.66003021300007, 9.953752849000084],
              [105.65699410300003, 9.951063163000116],
              [105.66250048000009, 9.946109985000094],
              [105.666345619, 9.942519503000112],
              [105.66826835300002, 9.940816904000103],
              [105.66889430600003, 9.940264663000063],
              [105.67457982800008, 9.945706814000042],
              [105.67891977600007, 9.949250905],
              [105.68192686, 9.950734698000117],
              [105.68545791400004, 9.951800952000054],
              [105.68626228700008, 9.952043835000078],
              [105.6908301600001, 9.953982772000101],
              [105.69146694900003, 9.9546118010001],
              [105.69216234700011, 9.95564183600006],
              [105.69279926700005, 9.956328170000093],
              [105.69343497600006, 9.956441453000052],
              [105.7018833990001, 9.955150021000046],
              [105.70225830600003, 9.959576230000076],
              [105.70909510600004, 9.958516676000071],
              [105.70951509700005, 9.958938535000071],
              [105.71431503700009, 9.963277870000107],
              [105.71695623600004, 9.966710998000037],
              [105.72085092700007, 9.97217514900006],
              [105.72184640500009, 9.974455551000093],
              [105.72199873900009, 9.976637310000045],
              [105.72291901700002, 9.976156673000068],
              [105.72337148500007, 9.975920359000055],
              [105.72630589500004, 9.973426983000076],
              [105.72742653600004, 9.971880886000049],
              [105.72820284000005, 9.971021611000095],
              [105.72984436900006, 9.970417714000044],
              [105.73373517200012, 9.970323429000059],
              [105.73508774700012, 9.971449772000083],
              [105.73713286200005, 9.97024424200008],
              [105.74001929200004, 9.968398650000053],
              [105.74078483500006, 9.967909143000052],
              [105.74476232000009, 9.965365836000027],
              [105.75388336200008, 9.961083271000069],
              [105.75568559600006, 9.960759840000042],
              [105.75688989400007, 9.960543716000101],
              [105.75903162200008, 9.96018863300012],
              [105.76055442900004, 9.959649434000093],
              [105.76092163500005, 9.959642376000131],
              [105.77189010100004, 9.956444929000073],
              [105.77251189400006, 9.956110737000044],
              [105.77294948300003, 9.955887887000079],
              [105.77341022700006, 9.955709354000051],
              [105.77375599600006, 9.955664192000045],
              [105.77410181699999, 9.955641206000097],
              [105.7744476980001, 9.955640406000031],
              [105.77472455700008, 9.955706312000066],
              [105.77500172600001, 9.955905326000012],
              [105.77516371600009, 9.95614896700001],
              [105.77541840000004, 9.956592053000078],
              [105.77574414500008, 9.957833591000041],
              [105.77609240600002, 9.958842150000089],
              [105.77747637400005, 9.95902749700006],
              [105.77945987800003, 9.959222524000031],
              [105.78225112300005, 9.959704041000082],
              [105.80183185300004, 9.963350986000117],
              [105.81695765000002, 9.965409978000126],
              [105.81726241800001, 9.966095303000076],
              [105.81893852200005, 9.968162797000078],
              [105.82245374400003, 9.970640084000058],
              [105.82672180000003, 9.973446908000126],
              [105.82856572600009, 9.975762523000052],
              [105.83124456500005, 9.977827450000108],
              [105.83321114000005, 9.979065501000049],
              [105.83429793000009, 9.979311379000054],
              [105.83688797100007, 9.979304888000049],
              [105.83796024700007, 9.980440517000131],
              [105.84290347900006, 9.983684874000074],
              [105.85678899600001, 9.969059641000031],
              [105.85899340100005, 9.96703343200007],
              [105.86414304800007, 9.96232275600004],
              [105.86455129100003, 9.961972860000044],
              [105.87480740000005, 9.953731387000085],
              [105.87754855600011, 9.951537366000094],
              [105.88170108700004, 9.948248474000108],
              [105.88210298000008, 9.947956731000128],
              [105.89537092100008, 9.939078380000053],
              [105.89223703300004, 9.934157698000055],
              [105.89042975299999, 9.93197399100003],
              [105.88958828300009, 9.930317112000074],
              [105.88792739000007, 9.930607156000091],
              [105.88621456200011, 9.929897055000046],
              [105.88450102200008, 9.928918962000031],
              [105.8803480870001, 9.924910074000069],
              [105.87330340700004, 9.916978166000055],
              [105.87293943800003, 9.915549837000091],
              [105.8716466130001, 9.907872985000042],
              [105.87198464600009, 9.901294105000067],
              [105.86701070300003, 9.89238411800004],
              [105.86977792400002, 9.890822398000053],
              [105.86850001600007, 9.888711771000123],
              [105.86744357100005, 9.887038287000037],
              [105.86726048300009, 9.886748258000109],
              [105.86677716600008, 9.885957983000091],
              [105.86637048400009, 9.885291074000067],
              [105.8673690140001, 9.881157859000032],
              [105.8651822950001, 9.872084277000058],
              [105.86508414500005, 9.871590279000129],
              [105.86479676500008, 9.87014386100009],
              [105.86154944100007, 9.853798820000035],
              [105.85917968800001, 9.854602367000085],
              [105.85698215800009, 9.848879540000064],
              [105.85518029100004, 9.844088262000115],
              [105.85052986600002, 9.823555955000083],
              [105.84848844900004, 9.815132737000097],
              [105.84697317300008, 9.808039857000049],
              [105.85296493000006, 9.806518132000084],
              [105.85136239200007, 9.802372114000056],
              [105.84643903200003, 9.774634937000124],
              [105.84332873300008, 9.766385468000037],
              [105.84281369300001, 9.764940873000059],
              [105.84165822400006, 9.75325659900008],
              [105.84123306700005, 9.749818794000118],
              [105.83443187000006, 9.744637800000076],
              [105.8192621530001, 9.735525375000085],
              [105.7970396040001, 9.722166987000056],
              [105.79614588300005, 9.72163554700005],
              [105.79542710300008, 9.721208129000035],
              [105.78980286000005, 9.717863690000071],
              [105.77795298800004, 9.710816415000066],
              [105.77670246700005, 9.711149372000081],
              [105.77592564, 9.711464808000068],
              [105.77571616400007, 9.711316766000076],
              [105.75501442200004, 9.698316530000016],
              [105.70041693899999, 9.663994053000112],
              [105.69279395800005, 9.658523978000043],
              [105.68386603000005, 9.648550873000071],
              [105.66767503100004, 9.634000705000021],
              [105.66441700100003, 9.642498122000108],
              [105.66028303800002, 9.640656754000075],
              [105.65138072300002, 9.636910330000069],
              [105.64764275200008, 9.635337107000018],
              [105.64515435100007, 9.626174092000015],
              [105.64471446900009, 9.626132698000054],
              [105.63952185200006, 9.625644003000032],
              [105.63621931300001, 9.624029482000116],
              [105.62828917300006, 9.621694322000083],
              [105.620212877, 9.620657531000097],
              [105.61763088500005, 9.620370308000046],
              [105.61577440100011, 9.620339233000051],
              [105.61010776300003, 9.620211560000056],
              [105.60844993400008, 9.620381627000095],
              [105.60621460600005, 9.620923093000043],
              [105.6048788140001, 9.620781796000076],
              [105.60349255100007, 9.619505833000106],
              [105.601364168, 9.616331256000075],
              [105.59853688600002, 9.613022638000055],
              [105.59620807300007, 9.610664777000098],
              [105.59540087700006, 9.608891429],
              [105.5947379670001, 9.605670680000065],
              [105.59399006600005, 9.604276026000134],
              [105.59337525100007, 9.603897785000102],
              [105.59220476800003, 9.603985860000107],
              [105.59099300300005, 9.604619548000047],
              [105.58711302500009, 9.608330120000106],
              [105.58261491300006, 9.612895053000068],
              [105.57691591000005, 9.615734456000078],
              [105.57429996700006, 9.616145162000043],
              [105.57369317500005, 9.615649841000094],
              [105.57298575900003, 9.614176815000073],
              [105.57264431200012, 9.610361220000117],
              [105.57157218300006, 9.607051016000055],
              [105.56805339000005, 9.603932253000023],
              [105.5658158840001, 9.605225314000075],
              [105.56356535100005, 9.607617456000115],
              [105.56111598800007, 9.608028613000029],
              [105.55890965000005, 9.606683780000045],
              [105.55648636100008, 9.604896684000062],
              [105.55607065900007, 9.602473493000028],
              [105.55633487900008, 9.599201615000117],
              [105.55569660600003, 9.597790078000033],
              [105.55211503200007, 9.596020097000045],
              [105.54688786000003, 9.58983481000004],
              [105.54458026400005, 9.587162551000093],
              [105.54381931300006, 9.586281327000011],
              [105.54226410299999, 9.586263058],
              [105.54134974200011, 9.587769219000018],
              [105.5400711720001, 9.58983051700004],
              [105.53785590700004, 9.592504561000046],
              [105.53507154900005, 9.597116006000038],
              [105.53440499500005, 9.598080202000057],
              [105.53352658400011, 9.59850187300013],
              [105.53233493500002, 9.597731770000094],
              [105.53049095200004, 9.596737955000021],
              [105.52853473400009, 9.596214018000103],
              [105.52330517200002, 9.596155081000022],
              [105.52286905600009, 9.596146995000064],
              [105.52060226200003, 9.595240823000049],
              [105.520179064, 9.593477047000041],
              [105.51888304200007, 9.590383856000083],
              [105.51689934400007, 9.589041260000018],
              [105.51447662700008, 9.587253744000089],
              [105.51094043900005, 9.585672792000109],
              [105.50933235500005, 9.585433743000047],
              [105.50827969300008, 9.585201435000011],
              [105.5027569800001, 9.587114072000079],
              [105.502347872, 9.587690217000054],
              [105.50222850400007, 9.588416892000049],
              [105.50327929400007, 9.590066691000041],
              [105.50345995300009, 9.591224563000099],
              [105.50320611000005, 9.591923219000085],
              [105.502397, 9.592294304000058],
              [105.50120690800001, 9.59234931800008],
              [105.49812059600001, 9.591550943000103],
              [105.49576597800004, 9.590987733000043],
              [105.49465207300007, 9.590540260000056],
              [105.49360925600003, 9.589894083000059],
              [105.49071479700008, 9.586090646000043],
              [105.48842223100011, 9.58349965000007],
              [105.48749862900007, 9.583324251000123],
              [105.48118022200006, 9.584437149000012],
              [105.47422392900005, 9.585452392000081],
              [105.47361611200003, 9.58575295600008],
              [105.47330105900006, 9.586263873000076],
              [105.47351670700006, 9.587574544000075],
              [105.47603051400004, 9.590471232000118],
              [105.47610244700005, 9.591251791000042],
              [105.47586827600003, 9.591964642000049],
              [105.47411856100003, 9.593794941000054],
              [105.470504996, 9.596964549000029],
              [105.46906214200008, 9.597985538000041],
              [105.46789566900003, 9.597971348000021],
              [105.46731766500007, 9.597272108000075],
              [105.46712096500005, 9.595134492000044],
              [105.46706739500006, 9.593638457000074],
              [105.46695281500008, 9.592802595000103],
              [105.46629597400009, 9.591991322000036],
              [105.46106283900008, 9.588162275000032],
              [105.46030709000009, 9.587812063000099],
              [105.45935237000005, 9.5878646],
              [105.45849284500008, 9.588621083000069],
              [105.45710103600003, 9.590597986000125],
              [105.45626490900003, 9.591426818000047],
              [105.45528888800001, 9.591644073000081],
              [105.454568416, 9.591706510000096],
              [105.45360659500008, 9.591394471000035],
              [105.44914273400005, 9.588151221000095],
              [105.44780576500008, 9.587989725000055],
              [105.44669000200005, 9.588619756000048],
              [105.44664588300004, 9.592179623000073],
              [105.44631480000001, 9.595664797000046],
              [105.44523723800002, 9.597403529000113],
              [105.44300721700007, 9.598035815000026],
              [105.44056514100008, 9.598453834000063],
              [105.43778187900008, 9.598907272000099],
              [105.43454973100009, 9.599699233000079],
              [105.42967241400004, 9.602254127],
              [105.42807616200004, 9.602038072000061],
              [105.42653182600007, 9.601139676000068],
              [105.42345144200002, 9.598683418000052],
              [105.41860744600005, 9.595106137000096],
              [105.41639942000006, 9.593979637000059],
              [105.41531428100006, 9.594144666000124],
              [105.41460543300005, 9.595276554000074],
              [105.41566946300006, 9.599027110000067],
              [105.41608351600004, 9.601450563000034],
              [105.41542119500004, 9.602833528000064],
              [105.41482192200002, 9.603101263000012],
              [105.41433550000009, 9.603318579000051],
              [105.41295356700006, 9.602950828000088],
              [105.41020837900003, 9.602413002000086],
              [105.40675558200007, 9.601898355000047],
              [105.40322971300002, 9.601226955000096],
              [105.39762469800006, 9.599820388000067],
              [105.39717448900002, 9.599590633000064],
              [105.39672993600001, 9.598912617000098],
              [105.39701762200011, 9.597660902000092],
              [105.39771983300002, 9.595876368000054],
              [105.3974683930001, 9.594259302000106],
              [105.39604588500008, 9.592089679000091],
              [105.39475194300005, 9.590504469000026],
              [105.39340590800005, 9.589456571000097],
              [105.39112945199999, 9.589049220000055],
              [105.38572445300007, 9.587791611000094],
              [105.37994065500006, 9.58655362600007],
              [105.37753853000008, 9.586613189000024],
              [105.37552642600005, 9.588067378000085],
              [105.37529496500008, 9.59200962600004],
              [105.37463731000003, 9.593839464],
              [105.37281311200007, 9.596514568000101],
              [105.372263608, 9.596911101000048],
              [105.37340312700005, 9.60738837900008],
              [105.37347972000008, 9.611402910000065],
              [105.37507128400004, 9.614340464000025],
              [105.37666231300005, 9.616776167000028],
              [105.37933754300008, 9.62028602900009],
              [105.38338283200002, 9.622217184000091],
              [105.38511608800009, 9.622645390000082],
              [105.38619931400009, 9.622859249000014],
              [105.38677739400005, 9.623288736000022],
              [105.38706684300011, 9.623861923000019],
              [105.38706904300003, 9.625797537000034],
              [105.38771957300003, 9.626442007000067],
              [105.38886555500008, 9.626651920000116],
              [105.38963719500006, 9.625590824000073],
              [105.39119695100007, 9.625173427000087],
              [105.39527073600007, 9.624083205000121],
              [105.39752428600011, 9.623087092000036],
              [105.39737985500005, 9.627609941000069],
              [105.39725722100012, 9.630423129000041],
              [105.397384679, 9.631735744000112],
              [105.39871110400006, 9.635297402000019],
              [105.40148686500005, 9.639795057000098],
              [105.40375755600007, 9.643105537000094],
              [105.40479769000007, 9.644104524000062],
              [105.4050503710001, 9.644791853000088],
              [105.4043407930001, 9.645137385000078],
              [105.40376476000006, 9.645308752000046],
              [105.40297630000001, 9.645369915000044],
              [105.40250120200004, 9.645410628000127],
              [105.40210710100003, 9.645551658000072],
              [105.40159189700003, 9.645873560000061],
              [105.40121792200009, 9.645934244000019],
              [105.40076297900002, 9.645924731000031],
              [105.40018654500003, 9.645754703000085],
              [105.39988326200006, 9.645755056000025],
              [105.39959018400006, 9.645835728000039],
              [105.3993479590001, 9.646177386000064],
              [105.39919679200001, 9.646579187],
              [105.3991365360001, 9.646920632],
              [105.39917750900003, 9.647377767000085],
              [105.39939032000004, 9.647808884000051],
              [105.39955244300005, 9.648129720000041],
              [105.39981480700008, 9.6487100930001],
              [105.4001413190001, 9.648854031000019],
              [105.40070676400008, 9.648931287000016],
              [105.40102757100006, 9.649077832000062],
              [105.401252314, 9.649211393000099],
              [105.401533536, 9.649522522000085],
              [105.4020102330001, 9.6500766070001],
              [105.40249131700003, 9.650511479000022],
              [105.40291200400002, 9.650814325000033],
              [105.40337729800005, 9.651034487000041],
              [105.40384937500004, 9.651198368000012],
              [105.40426156500004, 9.651438423000068],
              [105.40473956700006, 9.651904979000035],
              [105.4048633960001, 9.652274490000055],
              [105.40482854400005, 9.653162484000076],
              [105.40410107000008, 9.652969336000082],
              [105.39895339300004, 9.655763723000097],
              [105.39355551000008, 9.660753615000049],
              [105.39202442300005, 9.661324668000047],
              [105.39065789800006, 9.661377109000034],
              [105.38910492100007, 9.661137858000044],
              [105.38644905800007, 9.660225206000026],
              [105.38520582100006, 9.659919412000038],
              [105.38268197100004, 9.65929861200001],
              [105.36920629900007, 9.659798110000038],
              [105.36777160300002, 9.660650649000056],
              [105.36707956000005, 9.662620819],
              [105.36748754100006, 9.66548432800005],
              [105.36904855700007, 9.667262370000083],
              [105.37061586300008, 9.668564012000045],
              [105.37208480100003, 9.671039060000018],
              [105.37214528800007, 9.672084619000108],
              [105.371641133, 9.673230009000109],
              [105.37082861900009, 9.673706120000068],
              [105.36917473200003, 9.673542888000062],
              [105.36308318300006, 9.671329620000078],
              [105.3565571020001, 9.669324346000073],
              [105.35424899600008, 9.669651262000032],
              [105.35028900700009, 9.671863529000023],
              [105.34336483900006, 9.67441427200013],
              [105.33746738000006, 9.67709444600011],
              [105.33656800700007, 9.677741787000025],
              [105.33627060900007, 9.678468008000072],
              [105.33631010500005, 9.67959022600011],
              [105.33654744100006, 9.680621736000104],
              [105.33687587800006, 9.68179031000007],
              [105.33686164800012, 9.682890912000095],
              [105.33683816700005, 9.684706925000073],
              [105.33651089300005, 9.68616320000004],
              [105.33630780100003, 9.687066896000117],
              [105.33551552900003, 9.689273360000071],
              [105.33471025700001, 9.690348957000071],
              [105.33398337500009, 9.690724979],
              [105.33340748500005, 9.690679553000068],
              [105.33315113200007, 9.690659331000083],
              [105.33308197600003, 9.690923628000101],
              [105.33288044700005, 9.691693815000056],
              [105.33229713600011, 9.693922498000072],
              [105.33158489500006, 9.694890527000053],
              [105.33045783800007, 9.695707548000064],
              [105.32848458000005, 9.697206606000107],
              [105.32763477800007, 9.698165724000061],
              [105.32702749100011, 9.699054212000123],
              [105.32662417100003, 9.699702925000063],
              [105.32660667900009, 9.701072904000084],
              [105.32655039700006, 9.701321877000078],
              [105.32666453900001, 9.701663535000103],
              [105.32685432600007, 9.702254102000028],
              [105.32724154300004, 9.702847200000074],
              [105.32776291200003, 9.703245955000027],
              [105.32874578800008, 9.703585255000034],
              [105.32999359100003, 9.703797230000076],
              [105.33262336700001, 9.704026821000065],
              [105.33551722300008, 9.704194395000075],
              [105.33650434200004, 9.704206964000138],
              [105.33768381500002, 9.704614071000071],
              [105.33984450900002, 9.705491093000072],
              [105.34206343900011, 9.706956988000036],
              [105.35109127700012, 9.711253891000114],
              [105.35351030800007, 9.712526161000044],
              [105.35586103300007, 9.713993594000046],
              [105.35924886700006, 9.716715764000037],
              [105.36009353600005, 9.717575979000069],
              [105.36164541200003, 9.719752143000092],
              [105.36494000500004, 9.724629680000072],
              [105.36779492400009, 9.727867876000039],
              [105.36870382100007, 9.728859598000026],
              [105.36922367500009, 9.729388955000038],
              [105.37007009700005, 9.730118472000092],
              [105.37117813100006, 9.73098197300004],
              [105.37241781500005, 9.731847131000036],
              [105.37352836800001, 9.732514605000107],
              [105.37457561000006, 9.732985212000054],
              [105.37608440700002, 9.733396253000047],
              [105.37858048300005, 9.733819664000086],
              [105.38074664400004, 9.734304256000124],
              [105.38271951500003, 9.734459650000103],
              [105.38501898600005, 9.73481516600012],
              [105.38580714400007, 9.734955714000114],
              [105.38698523200001, 9.735493254000092],
              [105.38835829400003, 9.736229281000062],
              [105.38914066100001, 9.736827229000065],
              [105.39005218300011, 9.73762283700008],
              [105.39050381200009, 9.738347357000075],
              [105.39082297000003, 9.739135584000012],
              [105.3910663770001, 9.7407071090001],
              [105.39116738600011, 9.743126456000082],
              [105.39128743800009, 9.74404290900006],
              [105.39158231000006, 9.745061760000072],
              [105.39163492900006, 9.745311543000044],
              [105.392243831, 9.745724819000133],
              [105.39363435300004, 9.746163952000051],
              [105.39538825300011, 9.746667862],
              [105.39711065500006, 9.747468209000052],
              [105.39773103500006, 9.747851472000056],
              [105.4042279510001, 9.751133620000106],
              [105.41361840200008, 9.755877033000092],
              [105.41347987400007, 9.757844587000085],
              [105.41314319500003, 9.759160170000046],
              [105.41291862400001, 9.759943943000058],
              [105.41265170200003, 9.760671803000063],
              [105.41242671700007, 9.761119795000093],
              [105.41220167000002, 9.761511818000111],
              [105.41166654000006, 9.761932199000077],
              [105.41118748600003, 9.762100668000072],
              [105.410708293, 9.762157214000043],
              [105.41011622600004, 9.762129947000096],
              [105.40932657500008, 9.761907034000037],
              [105.40873427300009, 9.761683881000048],
              [105.40777493400003, 9.761013464000088],
              [105.40704137199999, 9.760538642000059],
              [105.40653361300008, 9.760287405000055],
              [105.40622350300001, 9.760287776000086],
              [105.40580072800002, 9.760372231000057],
              [105.40534993800003, 9.76059661800006],
              [105.40506858900002, 9.761072659000101],
              [105.40501278500003, 9.761548421000031],
              [105.40504239900007, 9.762723643000102],
              [105.40529832100002, 9.764542186000112],
              [105.40527134600009, 9.765549571000063],
              [105.40517328200006, 9.766053370000092],
              [105.40483582700003, 9.766753321000097],
              [105.40438559400008, 9.767453425000026],
              [105.40373817700006, 9.768293655000091],
              [105.40320350200007, 9.769105779000052],
              [105.40311980000007, 9.769833413000038],
              [105.40312033900003, 9.770281125000045],
              [105.40326183700009, 9.770728682000128],
              [105.40368530000005, 9.771203875000099],
              [105.40423628000008, 9.771600714000071],
              [105.40517616900004, 9.771984463000115],
              [105.40645441600002, 9.772574654],
              [105.40730713400005, 9.773418926000053],
              [105.40798992600006, 9.774601524000024],
              [105.40850223000008, 9.775657528000091],
              [105.40888802100002, 9.777728045000076],
              [105.40927196000008, 9.778277022000024],
              [105.40974050900004, 9.778360990000044],
              [105.4102511570001, 9.778064519000028],
              [105.41118637200003, 9.77671092300003],
              [105.41199377000004, 9.7753152050001],
              [105.41305708100001, 9.774257290000053],
              [105.41356789600007, 9.774087609000047],
              [105.41416396700002, 9.774002362000099],
              [105.41484575000003, 9.774339650000028],
              [105.41514462000006, 9.774973254000051],
              [105.41514550700005, 9.775691757000081],
              [105.41501870000005, 9.776452673000067],
              [105.41459358600004, 9.777044897000041],
              [105.41318932000011, 9.777891908000045],
              [105.41208310300007, 9.778696281000075],
              [105.41191343700007, 9.779245932000078],
              [105.41204212900004, 9.780006536000128],
              [105.41289504500001, 9.780977593],
              [105.41383343300002, 9.782202129000071],
              [105.41515495300004, 9.783299403000088],
              [105.41604978, 9.783720957000062],
              [105.41656080500009, 9.783720336000011],
              [105.41711379000002, 9.78321247600009],
              [105.4176235530001, 9.782197499000043],
              [105.41813284200005, 9.780802134000053],
              [105.41889758300007, 9.779364193000093],
              [105.41928073700008, 9.779279192000077],
              [105.41979170800008, 9.779236298000081],
              [105.42026051800005, 9.779531568],
              [105.42021973500005, 9.780968623000062],
              [105.41996783500008, 9.783842935000029],
              [105.42018278900011, 9.785448731000111],
              [105.42067344500002, 9.786187749000062],
              [105.42126982100007, 9.786313809000101],
              [105.42186564900008, 9.786017219000074],
              [105.42233319300004, 9.785298146000086],
              [105.4230553830001, 9.783902506000073],
              [105.42356593800008, 9.783521487000078],
              [105.42424735700006, 9.783562909000041],
              [105.42535528100007, 9.78411096200009],
              [105.42595246100009, 9.784870983000092],
              [105.42608108200008, 9.785547046000074],
              [105.42595418400006, 9.786223441000084],
              [105.42548627400006, 9.786646671000108],
              [105.42489033300005, 9.786858740000069],
              [105.42416669200004, 9.787113234000076],
              [105.42378395300008, 9.787536363000092],
              [105.42378438100012, 9.78787448100009],
              [105.42416802900007, 9.788169854000016],
              [105.42583035300002, 9.789308920000027],
              [105.42693873500008, 9.790195090000074],
              [105.427577539, 9.79019428],
              [105.42834393300008, 9.790066526000095],
              [105.42911038100007, 9.789981026000037],
              [105.43030318300001, 9.790275372000055],
              [105.43179501700007, 9.791287833000071],
              [105.43243497400009, 9.792174571000077],
              [105.43284667900005, 9.793464210000112],
              [105.43249126500004, 9.796646980000022],
              [105.43232571300011, 9.797031703000057],
              [105.43214796000005, 9.798155038000099],
              [105.43180819700009, 9.802404202000046],
              [105.43073790700001, 9.808186359000091],
              [105.42893364400003, 9.817240381000135],
              [105.43139985800006, 9.818154570000013],
              [105.43448195700009, 9.818762189000017],
              [105.43622802700004, 9.818759942],
              [105.44290674300002, 9.820687837],
              [105.45266728100007, 9.822917274000085],
              [105.45551102000009, 9.824344755000068],
              [105.45739640400004, 9.825883340000095],
              [105.45932731400011, 9.827058872000103],
              [105.46188892000005, 9.828618336000082],
              [105.4671419110001, 9.83190045400004],
              [105.46949095200003, 9.832651004000057],
              [105.47117066100002, 9.833038475000054],
              [105.47156292800005, 9.83304368100004],
              [105.47516354500007, 9.832922367000039],
              [105.49969921300006, 9.847911024000043],
              [105.51758276600003, 9.859237786000017],
              [105.51868210500005, 9.859997881000114],
              [105.51653718600004, 9.863489793000023],
              [105.514427514, 9.864241168000108],
              [105.51881437000007, 9.873662190000053],
              [105.5375486140001, 9.913886082000099],
              [105.53089529600001, 9.920140770000073],
              [105.53316863100008, 9.926503257000084],
              [105.53665728600006, 9.934418858000081],
              [105.54005804700007, 9.941959891000083],
              [105.54372586900006, 9.941200500000134],
              [105.54895267300007, 9.95291084300005],
              [105.54840985100002, 9.953397549000085],
              [105.55413211700008, 9.959795917000045],
              [105.54923184299999, 9.961858375000075],
              [105.56099014600005, 9.987820696000059],
              [105.56778724300005, 9.984969088000105],
              [105.57033883500006, 9.990484341000093],
              [105.57257388200006, 9.995315160000116],
              [105.58156418400007, 9.991440861000099],
              [105.58133637100005, 9.990946629000062],
              [105.57929472600006, 9.986517253000036],
              [105.57909694300004, 9.985845098000111],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 29, code: "AD01", ten_tinh: "Hòa Bình" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.92032270000006, 21.077080725000116],
              [104.92034494600003, 21.075673389000116],
              [104.91510479400007, 21.075287557000074],
              [104.91320231000005, 21.074478984000052],
              [104.91007085800003, 21.072401987000063],
              [104.90893382900001, 21.070587436000032],
              [104.90878593400002, 21.069412322000055],
              [104.90922327700007, 21.068088977000073],
              [104.91015765300001, 21.066928982000022],
              [104.91166362300001, 21.066480795000061],
              [104.913744917, 21.066509850000067],
              [104.92105623400009, 21.067550207000082],
              [104.92767507200008, 21.064983336000061],
              [104.93426462100005, 21.058974881000033],
              [104.93853316400001, 21.054928274000062],
              [104.95488638200003, 21.058125841000042],
              [104.959919402, 21.061088643000048],
              [104.96196328600008, 21.06084301300006],
              [104.96398442000006, 21.059384900000033],
              [104.96551090800007, 21.057607149000113],
              [104.96764200200001, 21.054429993000042],
              [104.96915746300007, 21.053355905000082],
              [104.97149200800006, 21.053153279000099],
              [104.97782721700003, 21.052692520000079],
              [104.983089885, 21.051591305],
              [104.987540591, 21.049149413000023],
              [104.9953112530001, 21.044680110000066],
              [105.000000082, 21.040964717000051],
              [105.00046747600004, 21.040873599000065],
              [105.0012367950001, 21.040884034000037],
              [105.00338807800001, 21.040775447000023],
              [105.00495283400008, 21.040234328000018],
              [105.00771487200008, 21.03867595500013],
              [105.00928976500009, 21.037238583000125],
              [105.01144464500004, 21.034141973000061],
              [105.01336172600003, 21.032084040000051],
              [105.01695962200009, 21.028902640000076],
              [105.01775496800009, 21.027663057000012],
              [105.01880075700011, 21.024551366000118],
              [105.01893076700003, 21.02330279800011],
              [105.018729713, 21.021945563000074],
              [105.01841618100008, 21.020691009000103],
              [105.01624954700006, 21.017327553000072],
              [105.01614902700008, 21.016648928000059],
              [105.01660132900003, 21.016081964000044],
              [105.01704803800004, 21.015879598000083],
              [105.01770853700006, 21.016201096000131],
              [105.01989907600003, 21.018001949],
              [105.02329345700005, 21.020860923000036],
              [105.02615259200009, 21.022462309000019],
              [105.02792196900003, 21.022798685000055],
              [105.0288106400001, 21.022706438000057],
              [105.03015077300006, 21.022099278],
              [105.03205960600008, 21.020562003000087],
              [105.03307341500002, 21.01953366700009],
              [105.03516071200006, 21.015140091000092],
              [105.035820152, 21.013943994000073],
              [105.03705566300009, 21.01291861000005],
              [105.04046199400003, 21.011349205000052],
              [105.04703537000007, 21.009353189000102],
              [105.04892028700007, 21.009378359000053],
              [105.05034907700008, 21.010230993000079],
              [105.05177315300004, 21.011396141000112],
              [105.05254930200005, 21.011406491000081],
              [105.05299596400005, 21.011204059000121],
              [105.05400803900004, 21.01027978300003],
              [105.06110485900005, 21.002976311000054],
              [105.06210743200005, 21.00267704500007],
              [105.06393956100003, 21.003012001000073],
              [105.06471881500002, 21.002813952000032],
              [105.06521659000001, 21.002405752000094],
              [105.06656275000009, 21.001381647000095],
              [105.06779802000007, 21.000356070000059],
              [105.06825717500004, 20.999320184000055],
              [105.06849771700006, 20.998073005000073],
              [105.06897565900007, 20.99578699000002],
              [105.07022653800001, 20.993719602000027],
              [105.07316855900007, 20.989799028000085],
              [105.07452552500008, 20.988045602000049],
              [105.07624010900003, 20.98515753400001],
              [105.07974517900004, 20.979891490000036],
              [105.08267116400002, 20.976106797000057],
              [105.08702018400005, 20.97165662800009],
              [105.08772207000004, 20.970538985000076],
              [105.08781731400002, 20.969896305000098],
              [105.08774250500005, 20.969170888000029],
              [105.08733839300001, 20.96755571300006],
              [105.08735041500003, 20.966750949000087],
              [105.08762413300011, 20.965627664000053],
              [105.08860330700004, 20.963145282000092],
              [105.09025426500004, 20.961557160000055],
              [105.09189558400004, 20.960612840000081],
              [105.09474046700007, 20.959362368000086],
              [105.09921818700009, 20.957730826],
              [105.10256835100009, 20.957050331000104],
              [105.10659872800005, 20.956700665000128],
              [105.10737293700006, 20.956469316000081],
              [105.10806391000004, 20.956075896000037],
              [105.10867163500005, 20.955520389000078],
              [105.10998224700005, 20.953766661000103],
              [105.11304104900009, 20.949620932000101],
              [105.11456385600005, 20.947990678000039],
              [105.11882114900006, 20.943860479000072],
              [105.12037775300006, 20.942834328000082],
              [105.12881703800005, 20.93964381300006],
              [105.13019285200002, 20.939259213000035],
              [105.130963412, 20.939269219000067],
              [105.13249979700002, 20.939611145000114],
              [105.13616955500008, 20.940463694000044],
              [105.13882136700002, 20.940659038000042],
              [105.14565318600006, 20.941954829000053],
              [105.14630228500002, 20.942019980000026],
              [105.14785171400007, 20.941749442000024],
              [105.15111249800003, 20.940701973000039],
              [105.15583479400004, 20.940109088000014],
              [105.15854200100004, 20.939926014000051],
              [105.15908069700004, 20.940078213000035],
              [105.15945958400002, 20.940591531000052],
              [105.15960457500003, 20.941247113000117],
              [105.15965221900007, 20.943281518000042],
              [105.15991046600003, 20.944120148000067],
              [105.16033063300006, 20.944452406000025],
              [105.17035967600005, 20.945597956000128],
              [105.17475405400002, 20.946307897000068],
              [105.17665822100001, 20.946853765000029],
              [105.17765955600008, 20.947210586000061],
              [105.17929132800001, 20.948263479000047],
              [105.18291836900008, 20.950545874000071],
              [105.1876396050001, 20.953100136000025],
              [105.18845306100008, 20.953798519000074],
              [105.18916140100006, 20.955441615000048],
              [105.19003290200006, 20.95846282300009],
              [105.19048291100007, 20.958984564],
              [105.19104386600003, 20.959116366000075],
              [105.193446862, 20.958627237000101],
              [105.19622336800003, 20.957796360000103],
              [105.19752196400002, 20.95720655800006],
              [105.20430488500008, 20.953308339000017],
              [105.20523489700003, 20.95271381600012],
              [105.20542661300009, 20.952196567000044],
              [105.205248549, 20.951761255000086],
              [105.20388644300002, 20.950358228000084],
              [105.203348562, 20.949312070000033],
              [105.20308707200005, 20.948269416000095],
              [105.20248937400007, 20.944970559000083],
              [105.20190855000006, 20.943707307000103],
              [105.19963394800006, 20.941686380000043],
              [105.19536251900004, 20.937561352000067],
              [105.19410142600006, 20.935553218000088],
              [105.19302584900007, 20.93346080000007],
              [105.19241340700002, 20.931201031000057],
              [105.19251550000003, 20.930509411000031],
              [105.19345540799999, 20.929222124000098],
              [105.19504762500002, 20.927423418000046],
              [105.195708649, 20.926305800000122],
              [105.19667093200003, 20.923459682],
              [105.197626955, 20.921046559000089],
              [105.1985630520001, 20.920019029000066],
              [105.19940205100005, 20.919336737000016],
              [105.20107257600007, 20.91849174],
              [105.20255264800005, 20.918077402000087],
              [105.2038422700001, 20.91809372700002],
              [105.20641407199999, 20.918645955000059],
              [105.20723815800005, 20.919002848000012],
              [105.20768758000003, 20.919788076000053],
              [105.20785446200006, 20.921002813000108],
              [105.20829893300007, 20.92213444700004],
              [105.20892640300011, 20.92335499900004],
              [105.21047631700002, 20.924500585000061],
              [105.21212703100008, 20.925041108000109],
              [105.21350634200007, 20.925231743000069],
              [105.21672928700011, 20.925358985000031],
              [105.21853121300008, 20.925651620000082],
              [105.22173814800003, 20.926828429000111],
              [105.22280174500001, 20.927599431000132],
              [105.22279455400005, 20.92810443500008],
              [105.22238447000004, 20.928604366000059],
              [105.21967510000005, 20.930211827],
              [105.21790578900001, 20.931831091000049],
              [105.21748666900007, 20.93296225300012],
              [105.21747225900003, 20.933972243000106],
              [105.21786796800009, 20.934482316000036],
              [105.21919298800003, 20.935761705000019],
              [105.22158886800003, 20.937307090000076],
              [105.23255707900009, 20.940601485000073],
              [105.23910774000004, 20.942830009000076],
              [105.24057084400003, 20.943858439000081],
              [105.24083053200012, 20.944493026000096],
              [105.24081802800006, 20.945376749000033],
              [105.24097084500009, 20.946011237000036],
              [105.242010963, 20.946024222000048],
              [105.24450036500009, 20.946544187000079],
              [105.24752720100007, 20.946670277000031],
              [105.24830508200003, 20.946418425000068],
              [105.24900384700004, 20.945983880000085],
              [105.24982573900009, 20.944857698000071],
              [105.25092335000005, 20.943229859000034],
              [105.25732288700002, 20.937122179000056],
              [105.25853695400011, 20.936758433000065],
              [105.25934278400007, 20.936768429],
              [105.26175140300006, 20.937429642],
              [105.26321635800012, 20.938331680000118],
              [105.26426777500008, 20.939986208000057],
              [105.26545175200008, 20.941768631000102],
              [105.26625229400005, 20.942157346000023],
              [105.26839946800007, 20.942310172000056],
              [105.26934669700002, 20.94181679800009],
              [105.26962592000004, 20.941062639000037],
              [105.26967541200004, 20.937527698000032],
              [105.26983268900008, 20.935888128],
              [105.27213547700011, 20.929197319000089],
              [105.27322091300003, 20.926946051000073],
              [105.27474211200004, 20.925832483000036],
              [105.27836092800005, 20.925452470000039],
              [105.27910966800002, 20.925744769000062],
              [105.27970589600005, 20.926176740000102],
              [105.28028831300001, 20.927599322000034],
              [105.28131052000003, 20.929876566000068],
              [105.28234852600004, 20.931021664000049],
              [105.28460073700005, 20.931473971000081],
              [105.28595757700005, 20.931349109000053],
              [105.28747676900007, 20.930376979000087],
              [105.29099425500004, 20.926456979000108],
              [105.29190730400009, 20.925760457000116],
              [105.29417322000009, 20.92522205500007],
              [105.30541392500004, 20.925163754000039],
              [105.31043513200009, 20.925137526000093],
              [105.31148498900011, 20.925433389000055],
              [105.31222597800004, 20.926291653000106],
              [105.31325634900003, 20.928002679000102],
              [105.31398565100007, 20.929710044000061],
              [105.31439440900004, 20.932828894000096],
              [105.3146623640001, 20.935238330000061],
              [105.31525675200007, 20.93581170700007],
              [105.31615612600007, 20.936105714000064],
              [105.32322983000003, 20.936333061000042],
              [105.32412727600004, 20.936768543000106],
              [105.32500920700008, 20.938336155000044],
              [105.32620000700003, 20.93934133700008],
              [105.32829798600007, 20.940074403000075],
              [105.34284447500008, 20.942649958000104],
              [105.34224087100004, 20.946161029000088],
              [105.33792553100004, 20.964871443],
              [105.33333191800001, 20.979125955000107],
              [105.32668571500007, 20.994945161000089],
              [105.32375274600004, 21.000946509000059],
              [105.31758857100002, 21.009141209000056],
              [105.31504306000006, 21.012524948000078],
              [105.31715286200001, 21.014224210000073],
              [105.32008244900003, 21.016578761000027],
              [105.3218351300001, 21.018362444000083],
              [105.32261086000007, 21.019392196000105],
              [105.32289555100009, 21.020230490000102],
              [105.32297389300008, 21.021715591000053],
              [105.32326367399999, 21.022182898],
              [105.32365728500005, 21.022280427000069],
              [105.32484319200003, 21.022202016000065],
              [105.32613291000007, 21.021753819000111],
              [105.32673285300007, 21.021204521000044],
              [105.3270391880001, 21.020466144000061],
              [105.32706081000002, 21.018889486000077],
              [105.32553604100006, 21.014882352000086],
              [105.32360248400005, 21.011890602000079],
              [105.32361394500002, 21.01105589200003],
              [105.32401771000012, 21.010411452000085],
              [105.32511119100009, 21.009868113000074],
              [105.32709563300006, 21.009150029000075],
              [105.32857887000003, 21.008982435000064],
              [105.33114541100011, 21.009013435000057],
              [105.33203001500003, 21.009302390000073],
              [105.334757209, 21.012025364000039],
              [105.33612019500006, 21.013433207000084],
              [105.33714371300002, 21.014132790000062],
              [105.33806701500002, 21.014317877000011],
              [105.34583793600004, 21.01475924200011],
              [105.34648946700004, 21.014506113000039],
              [105.3474305230001, 21.013386620000048],
              [105.34847478900008, 21.01148551200005],
              [105.34960331800002, 21.010194294000058],
              [105.35091223700005, 21.009253156000085],
              [105.3521214940001, 21.008832722000115],
              [105.3529557570001, 21.008755738000048],
              [105.354156743, 21.008944075],
              [105.36041817500009, 21.011454470000054],
              [105.36317634000004, 21.012879125000069],
              [105.36556897200003, 21.013951444000064],
              [105.36704300500006, 21.014490905000024],
              [105.37220802700001, 21.015944091000094],
              [105.3736797490001, 21.016657435000049],
              [105.37606311900004, 21.01842538500005],
              [105.379447267, 21.021509904000091],
              [105.38265805400002, 21.023722483000107],
              [105.38495485600006, 21.025054381000082],
              [105.38537026600007, 21.025146276000051],
              [105.38579034200009, 21.024890298000095],
              [105.38588757700006, 21.024543529000091],
              [105.38589224300009, 21.024195657000085],
              [105.38546555400005, 21.021494175000029],
              [105.38538697700002, 21.020449457000012],
              [105.38540681000006, 21.018970997000075],
              [105.38579109200006, 21.017931749000063],
              [105.38793766900004, 21.016652386000075],
              [105.39091733000002, 21.015382834000015],
              [105.39050951900003, 21.012037723000084],
              [105.39035504800003, 21.009984392000014],
              [105.39019354200009, 21.007837393],
              [105.39049722400009, 21.006004957000087],
              [105.39117540200006, 21.004002081000102],
              [105.39193022200007, 21.003224114000126],
              [105.39586111100007, 21.001521849000056],
              [105.4015700800001, 20.999053549000024],
              [105.40493096000009, 20.998218722000047],
              [105.40865581600008, 20.99800013400008],
              [105.41237718200006, 20.998043728000049],
              [105.41615132100006, 20.997645207000026],
              [105.41685231800005, 20.994262434000085],
              [105.41908731000007, 20.989285857000084],
              [105.42060626400006, 20.985810441000027],
              [105.42357401100003, 20.983281726000058],
              [105.42660100100002, 20.980506887000061],
              [105.42993733500002, 20.975862178000071],
              [105.4313927700001, 20.973470403000057],
              [105.43255110800001, 20.972011886000082],
              [105.43528602, 20.969768748000028],
              [105.4380191210001, 20.967659366000113],
              [105.43946736800011, 20.965802706000055],
              [105.44078903300004, 20.962740196000063],
              [105.44240765300003, 20.958744363000022],
              [105.44342346700006, 20.957284098000081],
              [105.44485755800005, 20.956497772000127],
              [105.44843223700005, 20.955334691000083],
              [105.45128985600006, 20.954564744000059],
              [105.45272389600005, 20.953778354000093],
              [105.45589236400011, 20.95100462100001],
              [105.45919961200011, 20.948500070000087],
              [105.46091479500009, 20.947984497000057],
              [105.46262300600003, 20.948004118000085],
              [105.46357636000005, 20.948266871000065],
              [105.46616960600002, 20.948981566000043],
              [105.47012767600005, 20.951168050000035],
              [105.47182724100007, 20.951856606000071],
              [105.474243793, 20.952151881000077],
              [105.47709779200007, 20.951649208000056],
              [105.47924001600003, 20.951138370000066],
              [105.48181792700008, 20.949963370000098],
              [105.48292954900003, 20.948943566000096],
              [105.48469949800003, 20.947319764000035],
              [105.48759649600001, 20.943471882000075],
              [105.48978166100005, 20.93961585400006],
              [105.49195641500006, 20.936562608000116],
              [105.49397844800002, 20.93431054400012],
              [105.49668673500003, 20.932503577000091],
              [105.50113274900009, 20.930378073],
              [105.50646649500007, 20.934035555000015],
              [105.51057670600007, 20.93812511800008],
              [105.5129085130001, 20.940238188000038],
              [105.51373758100003, 20.940508372000096],
              [105.51429417300008, 20.940384207000037],
              [105.51465201000011, 20.939756379000052],
              [105.51574737300005, 20.936513187000024],
              [105.51682948600006, 20.934307828000073],
              [105.51825926200006, 20.932389440000065],
              [105.52095738300001, 20.930087581000031],
              [105.52274709300004, 20.929335669000082],
              [105.52469811, 20.928712833000063],
              [105.52608563800004, 20.928702664000106],
              [105.52738621500001, 20.929047495000042],
              [105.52927727400007, 20.929596426000074],
              [105.53397247100006, 20.931344433000021],
              [105.53660332000004, 20.931765064],
              [105.55312644600009, 20.930774816000053],
              [105.55757545200005, 20.930041479000039],
              [105.56486838800004, 20.928112229000106],
              [105.56760278400007, 20.92773254300004],
              [105.56960667600005, 20.927754595000089],
              [105.57126002500007, 20.927936602000052],
              [105.57264587700007, 20.928607154000112],
              [105.57480875500006, 20.929859642000089],
              [105.57619871400004, 20.93020255400004],
              [105.57672147700005, 20.930208288000074],
              [105.57733543100004, 20.929887360000073],
              [105.57804058400004, 20.929239769000048],
              [105.57840331900012, 20.928096925000084],
              [105.57903557400002, 20.92630170700005],
              [105.57956849100006, 20.92548839300008],
              [105.58167877800005, 20.923955092000043],
              [105.58291171400002, 20.92290368200009],
              [105.58145092600003, 20.921249363000079],
              [105.58101936900003, 20.920916973000111],
              [105.58058274300005, 20.92099411],
              [105.57949982500007, 20.921891529000014],
              [105.57871266000004, 20.92212865],
              [105.57812907000007, 20.922114060000041],
              [105.57697056700005, 20.921614715000111],
              [105.57745237900004, 20.921235557],
              [105.57806912299999, 20.919991559000017],
              [105.57827268400005, 20.919192724000091],
              [105.57852930800006, 20.91751100300003],
              [105.57516019800001, 20.915610072000042],
              [105.57308154400006, 20.914604241000042],
              [105.57232909500006, 20.914364263],
              [105.56573326600002, 20.912260487000097],
              [105.56516098100009, 20.911222503000104],
              [105.56443726000008, 20.910595518],
              [105.56291153900005, 20.909753356000046],
              [105.56196840500009, 20.909123941000104],
              [105.56146155600007, 20.908705683000051],
              [105.56110272000004, 20.908151487000026],
              [105.56098910600005, 20.90553664200003],
              [105.560593254, 20.902093314000055],
              [105.55968801800006, 20.898438],
              [105.55877764300004, 20.895195286000053],
              [105.55835344600003, 20.894021336],
              [105.55813751000002, 20.89279142300002],
              [105.55752776300001, 20.891968428000048],
              [105.55173954300008, 20.88875593200008],
              [105.54152019400009, 20.882928591000024],
              [105.5411555790001, 20.882341489000034],
              [105.54117321200006, 20.880942363000088],
              [105.54181518600006, 20.879200361000102],
              [105.54301591000002, 20.87784765],
              [105.54374788800001, 20.87880622300009],
              [105.5451937720001, 20.879959734000053],
              [105.54643669600009, 20.880446776000028],
              [105.54754736900006, 20.88074132900006],
              [105.55077883300001, 20.881041730000049],
              [105.55406159900011, 20.881078107000036],
              [105.55783624300005, 20.881347722000051],
              [105.5576125310001, 20.880570638000059],
              [105.55751519400005, 20.879761062000043],
              [105.55752278400004, 20.879154772000064],
              [105.55785275300008, 20.878552038000109],
              [105.55839425900004, 20.878221148000087],
              [105.55952367000005, 20.87775045900004],
              [105.56035491900008, 20.877329601000035],
              [105.56193516300009, 20.876568288000016],
              [105.56528327500008, 20.873894720000052],
              [105.56753192700006, 20.871050752000109],
              [105.56922033500005, 20.870981110000031],
              [105.57031332900007, 20.870919767000103],
              [105.57109703800009, 20.870634909000074],
              [105.57165403900007, 20.869760606000028],
              [105.57229542700003, 20.868373640000115],
              [105.57399281200006, 20.863623259000086],
              [105.57486270300008, 20.862679005000118],
              [105.57636115200008, 20.861374792000028],
              [105.57754393000006, 20.860360588000049],
              [105.57832849700006, 20.860002338000072],
              [105.57942051700007, 20.860014310000082],
              [105.58120213600006, 20.860465517000115],
              [105.58330468900002, 20.859134693000044],
              [105.58320819800012, 20.856534001000071],
              [105.58323273100011, 20.854553261000049],
              [105.58350034700004, 20.85184145700012],
              [105.58325195500001, 20.846702729000107],
              [105.58344812399999, 20.84329212500009],
              [105.58404467600005, 20.842336204000041],
              [105.58404978200012, 20.841923787000027],
              [105.583623227, 20.840956669000029],
              [105.57971448500007, 20.837889016000055],
              [105.57884778700003, 20.837054553000108],
              [105.57828022800005, 20.835673394000054],
              [105.57762113500006, 20.829891399000111],
              [105.57706213900008, 20.827822828000109],
              [105.57634677000004, 20.826577519000097],
              [105.57461521800003, 20.824771053000021],
              [105.57301959000009, 20.823791051000029],
              [105.57229228100003, 20.823508059000062],
              [105.569522389, 20.822927601000082],
              [105.56608715600008, 20.819679754000028],
              [105.56464996300009, 20.817754673000088],
              [105.56262932000006, 20.813799323000111],
              [105.56112631000005, 20.810651546000081],
              [105.56145572900003, 20.810273334000051],
              [105.56381424200003, 20.809917517000095],
              [105.57169375700008, 20.809469735000086],
              [105.57331892000008, 20.809334860000028],
              [105.57493459500009, 20.809963580000087],
              [105.58050081400002, 20.812773981000099],
              [105.58122466600005, 20.813316500000042],
              [105.5814624920001, 20.813777325000068],
              [105.58159264600003, 20.816375321000088],
              [105.58172754300008, 20.818591518000048],
              [105.58259012200006, 20.821044782000087],
              [105.58409922900003, 20.823734206000033],
              [105.58937012700005, 20.831829156000126],
              [105.59394542700008, 20.837814236000085],
              [105.59656973100006, 20.840445943000049],
              [105.59854536100001, 20.84182105600005],
              [105.59931758900004, 20.842037699000038],
              [105.60031374000005, 20.842048527000046],
              [105.60231372600005, 20.841445502000063],
              [105.60787852400006, 20.839006862000083],
              [105.61055911300006, 20.837057508],
              [105.61291143600002, 20.834792189000062],
              [105.61459326600007, 20.833040223000054],
              [105.61648369900009, 20.832331751000055],
              [105.62668256900008, 20.831087970000084],
              [105.62879050900005, 20.830694108],
              [105.62967592500004, 20.830703615000076],
              [105.63011358100002, 20.831124822000021],
              [105.63022046700007, 20.831438348000027],
              [105.63021035300002, 20.832271248000019],
              [105.62996497200007, 20.834247004000069],
              [105.62993968400011, 20.836329246000048],
              [105.63014334700009, 20.837789185000055],
              [105.63079100900002, 20.839149758000097],
              [105.63177451200005, 20.840201561000079],
              [105.633097655, 20.840632241],
              [105.6338737040001, 20.840536439000076],
              [105.63468405, 20.84007926],
              [105.63454576800009, 20.839845499000106],
              [105.63385133500002, 20.837456572000065],
              [105.63352581, 20.834906924000052],
              [105.6341894610001, 20.832957458000045],
              [105.63672493600011, 20.830891552000082],
              [105.63923041000001, 20.82894338700001],
              [105.63955876200001, 20.827543579000093],
              [105.63925789900007, 20.826670864],
              [105.63862515800012, 20.826077263000094],
              [105.63373479100005, 20.824603667000119],
              [105.62767935700005, 20.821935499000062],
              [105.62702038100005, 20.821511902000012],
              [105.62692617500008, 20.820157229000102],
              [105.62905512100011, 20.810246784000071],
              [105.63052130300009, 20.806659041000053],
              [105.63221788900005, 20.803657464000125],
              [105.63222673000007, 20.802928638000125],
              [105.63179418600006, 20.802090952000093],
              [105.62852114800006, 20.798202953],
              [105.62787491300006, 20.796738164000047],
              [105.62766877900006, 20.795486370000035],
              [105.62790274800005, 20.794447557000062],
              [105.62857427300003, 20.793829976000033],
              [105.63090312200006, 20.793438457000057],
              [105.63968499500007, 20.793589921000063],
              [105.64683639500007, 20.793713005000122],
              [105.64620673400002, 20.78347004700003],
              [105.64402707500005, 20.782825204000055],
              [105.64222221600002, 20.781393891000022],
              [105.64127595800007, 20.779870879000036],
              [105.64003867800001, 20.775823217000067],
              [105.63990846700008, 20.768862366000107],
              [105.64035915200006, 20.758175702000067],
              [105.64072327300008, 20.754649348000065],
              [105.64153427700003, 20.749614766000057],
              [105.64189714300004, 20.746189213000044],
              [105.64256440400001, 20.744179029000072],
              [105.64355188899999, 20.74227311200012],
              [105.6443153110001, 20.741171732000026],
              [105.64582998100011, 20.739977500000073],
              [105.64852811400006, 20.738392394000101],
              [105.65025823800003, 20.737099549000071],
              [105.65188362600006, 20.735603843000035],
              [105.65427959200005, 20.732401562000021],
              [105.65765912200008, 20.727595783000119],
              [105.65954986500003, 20.725028651000073],
              [105.66101653500004, 20.721705820000032],
              [105.66168267900011, 20.72061981600006],
              [105.66448331600002, 20.717028680000027],
              [105.66838773500004, 20.71215111700004],
              [105.67180995000004, 20.707855014000117],
              [105.67768979000009, 20.701454175000045],
              [105.68021772300008, 20.69877812200005],
              [105.68129308000006, 20.697555610000059],
              [105.68193027600003, 20.696445987000089],
              [105.682130704, 20.695331771],
              [105.68166082900002, 20.692859176000056],
              [105.67997594100007, 20.688751390000078],
              [105.67896217400002, 20.686279708000122],
              [105.67822605000005, 20.685214382000083],
              [105.67636411100008, 20.684372215000032],
              [105.66854218100011, 20.680999405000122],
              [105.6679462070001, 20.680392175000058],
              [105.66774559800011, 20.679904078000085],
              [105.66796708900004, 20.678642870000097],
              [105.66890870300006, 20.677583671000029],
              [105.66963346799999, 20.677396938000037],
              [105.67129054900005, 20.676928460000084],
              [105.67233070200005, 20.676259068000036],
              [105.67609002000006, 20.672605236000052],
              [105.67661644, 20.671736013000071],
              [105.6773850700001, 20.667856227000044],
              [105.67840626400003, 20.660091145000067],
              [105.67888486000003, 20.654555840000022],
              [105.6788987140001, 20.653389595000071],
              [105.67840122300007, 20.651829161000101],
              [105.67739010200006, 20.650068921000027],
              [105.67699928000006, 20.648534668000096],
              [105.67701769600008, 20.646985562000083],
              [105.67814335000004, 20.644544359000072],
              [105.67967020700007, 20.642372306000119],
              [105.68093958200004, 20.641203132000015],
              [105.68275195100006, 20.639617329000082],
              [105.68330396900009, 20.638440598000059],
              [105.68340966000004, 20.637090241000067],
              [105.68315868200006, 20.635567207000079],
              [105.68245638700003, 20.634292821000066],
              [105.68033051900008, 20.63207432100009],
              [105.67846165100009, 20.630872117000095],
              [105.67703006000004, 20.630603636000089],
              [105.67469984700006, 20.630494608000035],
              [105.67317458300006, 20.630562987000097],
              [105.67227494000005, 20.630806897000028],
              [105.67118893300008, 20.631640103000045],
              [105.670366868, 20.632898423000064],
              [105.66972713300001, 20.633905270000064],
              [105.66909545900006, 20.634236465000107],
              [105.66873680200005, 20.63423267400006],
              [105.66277983100011, 20.629946246000053],
              [105.662161294, 20.629179482000026],
              [105.66207667900002, 20.628756251000091],
              [105.66208779300004, 20.627827223000047],
              [105.66348117700005, 20.623787519000111],
              [105.66477475800005, 20.620591412000074],
              [105.66564815800002, 20.619423064000038],
              [105.66580441900007, 20.619214023000062],
              [105.66704341400001, 20.618334771000058],
              [105.66965151600006, 20.617686592000069],
              [105.67420512300006, 20.617381137000024],
              [105.6800289510001, 20.617000394000051],
              [105.68228339500011, 20.616847273000083],
              [105.68359621200003, 20.616949489000099],
              [105.68471610100005, 20.617491760000028],
              [105.68601428000008, 20.618831661000044],
              [105.68823109300008, 20.621861121000059],
              [105.68916436200003, 20.622312999000059],
              [105.69028952200004, 20.62241320600009],
              [105.69788933200003, 20.622669512000066],
              [105.6987371160001, 20.622413114000082],
              [105.69958905000007, 20.621803081000053],
              [105.70111977400006, 20.619343338000107],
              [105.70230001000002, 20.614757887000046],
              [105.70288027600006, 20.611490922000094],
              [105.703154333, 20.609947948000062],
              [105.70346740300006, 20.605755918000042],
              [105.70361424300005, 20.603876777000082],
              [105.70412704100002, 20.602706700000084],
              [105.70514434700007, 20.601071709000074],
              [105.70615059400002, 20.600376921000048],
              [105.7100287530001, 20.599476901000102],
              [105.71365739300003, 20.598574224000018],
              [105.71653889200007, 20.59754620500005],
              [105.7182921740001, 20.596976639000083],
              [105.7190392700001, 20.597101914000056],
              [105.71978088300008, 20.597697304000029],
              [105.72456384100005, 20.604799243000024],
              [105.725308236, 20.605159541000084],
              [105.72605810200005, 20.605049735000058],
              [105.72920961400004, 20.602261224000081],
              [105.73084634400001, 20.600985114000103],
              [105.73235421900009, 20.600060294000052],
              [105.73559898700006, 20.599976102000106],
              [105.73927562100006, 20.600307696000094],
              [105.74127428300005, 20.600093096000101],
              [105.74312930000006, 20.598241787000049],
              [105.74529107800005, 20.594033065000069],
              [105.7468987280001, 20.592058507000033],
              [105.75157369400003, 20.587502041000057],
              [105.75838408700005, 20.580976145000115],
              [105.75984804800004, 20.579995512000067],
              [105.76210285700009, 20.579147341000059],
              [105.76290512100012, 20.578284409000069],
              [105.76413482099998, 20.574688121000051],
              [105.76797789300008, 20.565141830000044],
              [105.76807637100006, 20.56489719900005],
              [105.76934168500004, 20.559608698000083],
              [105.77090769700003, 20.553065550000049],
              [105.77260015200008, 20.549959308000076],
              [105.77360671300005, 20.548876305000086],
              [105.77713046200003, 20.545007656000088],
              [105.77997685200008, 20.542064786000047],
              [105.78123812100007, 20.540514595000033],
              [105.78229321800006, 20.538864651000033],
              [105.78452028000004, 20.534393721000029],
              [105.78728854900002, 20.527876844000033],
              [105.78930805700006, 20.52366303000008],
              [105.79057327600002, 20.520681900000071],
              [105.79217561100009, 20.516543244000026],
              [105.79317599500007, 20.514048205000087],
              [105.793378003, 20.513378145000068],
              [105.79364205700008, 20.512953088000074],
              [105.79409707900008, 20.512835448000111],
              [105.794615537, 20.512840638000092],
              [105.79739329800006, 20.51366271100008],
              [105.79810618400006, 20.513669836000048],
              [105.79895005500008, 20.51355607200005],
              [105.79979873600004, 20.513014651000013],
              [105.80052122800004, 20.512166479000093],
              [105.80098793100002, 20.51101025100008],
              [105.80222996400002, 20.504301675000121],
              [105.80302477300006, 20.502782102000069],
              [105.80410834200011, 20.500990091000034],
              [105.80484365700002, 20.500040964000021],
              [105.80608463000002, 20.499233511000092],
              [105.80724701200009, 20.498971815000019],
              [105.8081164970001, 20.498980467000116],
              [105.80913013700003, 20.499058870000027],
              [105.809705201, 20.499474490000047],
              [105.81041830600003, 20.500506347000069],
              [105.81111765900008, 20.502767773000038],
              [105.81176061000008, 20.503593968000096],
              [105.81276740000007, 20.504287140000073],
              [105.81471769400005, 20.504853033000089],
              [105.81622091100007, 20.505072882000071],
              [105.81626525200002, 20.501101465000104],
              [105.81730506600007, 20.496989009000096],
              [105.81752681900004, 20.494929816000074],
              [105.81831157000005, 20.494504352000043],
              [105.81933584400007, 20.493626351000046],
              [105.82146596600009, 20.491051325000065],
              [105.82329176200008, 20.489771318000024],
              [105.82802933200003, 20.487290276000088],
              [105.83583827299999, 20.48310585800008],
              [105.84289938100008, 20.479314459000086],
              [105.84441331200001, 20.4790204930001],
              [105.84471494500011, 20.478995721000089],
              [105.84741130500001, 20.478774273000063],
              [105.84858006800008, 20.478785691000034],
              [105.84938662600011, 20.478518029],
              [105.84997857600005, 20.477834967000064],
              [105.85013451100008, 20.476941005000079],
              [105.84990742800004, 20.471014752000102],
              [105.84966294500006, 20.466672637000016],
              [105.84970155500005, 20.463159877000066],
              [105.85058296400007, 20.456073265],
              [105.851147955, 20.451187861000086],
              [105.85235541800007, 20.441004354000114],
              [105.85269668600007, 20.436529986],
              [105.85272692100008, 20.433774753000122],
              [105.85305611200005, 20.431453359000074],
              [105.85337469600003, 20.430210119000058],
              [105.85440367200009, 20.429069674000019],
              [105.85533099600008, 20.427928233000081],
              [105.85671513400011, 20.426839183000119],
              [105.85825853700001, 20.425128477000065],
              [105.85929375000003, 20.423412825000042],
              [105.85960912600011, 20.422457149000103],
              [105.85717836300007, 20.421666519000055],
              [105.85423735100009, 20.42106264200001],
              [105.85189983400004, 20.421039852000078],
              [105.8482358470001, 20.4214834510001],
              [105.84437173900004, 20.421637402000044],
              [105.84266031500002, 20.421418574000057],
              [105.84193575300002, 20.421325923000033],
              [105.8390974860001, 20.420626973000111],
              [105.83747880900002, 20.419939957000018],
              [105.83536047300007, 20.41848103200001],
              [105.83364125300002, 20.417697129000075],
              [105.8321210660001, 20.417298670000058],
              [105.82907006600007, 20.417460365000025],
              [105.82652720000009, 20.417627025000051],
              [105.8224492360001, 20.418737230000069],
              [105.81634909600002, 20.423101638000048],
              [105.8094047860001, 20.428331139000107],
              [105.80592865000008, 20.431287586000032],
              [105.80128981800004, 20.435797370000053],
              [105.79825202700005, 20.439534291000072],
              [105.79388550800007, 20.445286352000025],
              [105.79216792400007, 20.447732328000079],
              [105.79177079500008, 20.448887489000086],
              [105.79070355600005, 20.454962249000097],
              [105.79031375700004, 20.455465460000077],
              [105.78992887500009, 20.455534038000067],
              [105.78946799900005, 20.455529413000036],
              [105.78701489800002, 20.455070107000047],
              [105.78663329400007, 20.454848936000047],
              [105.78632932300006, 20.454556096000061],
              [105.78618061600011, 20.454119928000033],
              [105.78622815400004, 20.449918538000041],
              [105.78608764800005, 20.448757986000118],
              [105.78361198800012, 20.443516926000079],
              [105.78195670100011, 20.440457484000071],
              [105.78086246900008, 20.438653050000077],
              [105.780674812, 20.438343596000053],
              [105.78021889800003, 20.437904317000033],
              [105.77937732300003, 20.437606041000045],
              [105.77784532300011, 20.437228345000058],
              [105.77677086000003, 20.437145060000091],
              [105.77615313200008, 20.437428606000104],
              [105.77568818200007, 20.437786145000111],
              [105.77483342400004, 20.438646887000097],
              [105.77335102800004, 20.440660427000076],
              [105.77194622400006, 20.442602296000075],
              [105.77109474000005, 20.443173252000065],
              [105.77047862600006, 20.443311915000081],
              [105.76932650800003, 20.443300249000096],
              [105.76335452400008, 20.441573396000123],
              [105.76067123900003, 20.44111144700009],
              [105.76005429500005, 20.441322514000056],
              [105.75912763300002, 20.44174777000007],
              [105.75812166000001, 20.442389554000059],
              [105.75704469500008, 20.442523479000037],
              [105.75619981300008, 20.442514873000036],
              [105.75213899300007, 20.441604100000085],
              [105.75015199400008, 20.440714445000104],
              [105.74977462500003, 20.440131007000062],
              [105.74978128900004, 20.439551500000071],
              [105.75001663900007, 20.438264272000062],
              [105.75170603100005, 20.431371672000061],
              [105.75456491400007, 20.420770152000053],
              [105.755065288, 20.419256566000051],
              [105.75579929600006, 20.418428775000038],
              [105.75722139300004, 20.417266285000046],
              [105.75755119000007, 20.416586235000089],
              [105.75751573000007, 20.41616823100005],
              [105.75683808300003, 20.415591819000056],
              [105.75563419800008, 20.415275808000018],
              [105.75194209900005, 20.414326933000027],
              [105.75130341000005, 20.413864801000067],
              [105.75090706800002, 20.413329208000071],
              [105.75061216100005, 20.410972208000096],
              [105.75024462100001, 20.407931041],
              [105.75041345700004, 20.407249347000089],
              [105.750987342, 20.406343978000052],
              [105.75270634200004, 20.403855649000043],
              [105.75344988900009, 20.402192642000024],
              [105.75412338700005, 20.399617683000045],
              [105.75455630400006, 20.396964323000063],
              [105.75469139800006, 20.395712740000072],
              [105.75473277100011, 20.395119015000063],
              [105.75481542700001, 20.391917146000083],
              [105.75519047200005, 20.387288779000109],
              [105.75585955200008, 20.38509340800001],
              [105.75718286900005, 20.381993421000018],
              [105.75752913400001, 20.379870676000067],
              [105.75745735900003, 20.379110552000057],
              [105.7574634480001, 20.378579042000041],
              [105.75699277900004, 20.377511111000054],
              [105.75565429900006, 20.374915537000057],
              [105.75510752100011, 20.373467121000118],
              [105.75468521400008, 20.368185010000062],
              [105.74447453900008, 20.367283249000053],
              [105.74422963300006, 20.367584504000021],
              [105.74339691600008, 20.370006053000097],
              [105.7431406150001, 20.371294398000117],
              [105.74320620500004, 20.372586041000048],
              [105.74327443000007, 20.373649894000053],
              [105.74370650700004, 20.378058786000075],
              [105.74374672100008, 20.381552377000034],
              [105.74349653500008, 20.382309195000033],
              [105.74268388600007, 20.382984317000115],
              [105.74138836, 20.383654472000053],
              [105.74001322600007, 20.384247865000034],
              [105.7311360250001, 20.386282818000012],
              [105.72839534300007, 20.386634250000043],
              [105.72835886200008, 20.387456800000066],
              [105.72908620200005, 20.395460450000066],
              [105.72972477800003, 20.399336099000017],
              [105.72944241900004, 20.400106998000034],
              [105.72848107900006, 20.400483991000051],
              [105.72628501300008, 20.401235133000029],
              [105.72454034300006, 20.40237420000004],
              [105.72083985500004, 20.405409315000078],
              [105.717820911, 20.408195310000018],
              [105.71631887200007, 20.408948076000044],
              [105.71441560700006, 20.409184448000062],
              [105.712379587, 20.409163304000089],
              [105.70979614300003, 20.409520613000076],
              [105.7061042870001, 20.411787206000028],
              [105.70487666200005, 20.412286641000051],
              [105.70337905000012, 20.412655199000085],
              [105.69441741200009, 20.412817655000111],
              [105.69211140100006, 20.412665443000044],
              [105.68794250800001, 20.412047213000051],
              [105.6856066380001, 20.411700777000071],
              [105.68425383100008, 20.411302380000059],
              [105.68364478300003, 20.410408738000015],
              [105.693210924, 20.401730799000092],
              [105.69448704700008, 20.399196889000045],
              [105.69471780800006, 20.397239860000035],
              [105.69411784000008, 20.395274116000017],
              [105.69333185100007, 20.394207407000039],
              [105.69228287100005, 20.393195556000073],
              [105.69145313000004, 20.391585486000082],
              [105.69161923800002, 20.390907680000048],
              [105.69182180100009, 20.390081110000047],
              [105.69460473400004, 20.385943410000081],
              [105.70092724200003, 20.37987731900008],
              [105.70286432400007, 20.378116250000033],
              [105.70252226500003, 20.376769617000079],
              [105.70252734000009, 20.376337178000092],
              [105.70323257299999, 20.374830829000068],
              [105.70559328100009, 20.370156567000031],
              [105.708935873, 20.365456817000016],
              [105.71056135900002, 20.361885744000041],
              [105.71221961000001, 20.358201655000038],
              [105.71581965600006, 20.352233565000063],
              [105.71950095000001, 20.347173100000049],
              [105.72300698000002, 20.343290258000124],
              [105.72703790500003, 20.33996099200008],
              [105.72665441300009, 20.337838547000061],
              [105.72581721000002, 20.336076101000074],
              [105.72370779400009, 20.333086313000059],
              [105.722584785, 20.331320886000093],
              [105.72102360800005, 20.33036036100011],
              [105.71419006200006, 20.327996031000062],
              [105.70123805900008, 20.323274178000084],
              [105.69981997200007, 20.32231498400003],
              [105.69601674800008, 20.317553309000047],
              [105.69235345200011, 20.313062828000028],
              [105.68755184300002, 20.308155548000073],
              [105.68599261700005, 20.307059850000094],
              [105.6849798100001, 20.306703485000099],
              [105.68286117000004, 20.306556085000068],
              [105.68074105200003, 20.306533749],
              [105.67729141899999, 20.306872651000063],
              [105.67383136500004, 20.308087075000088],
              [105.67129883300002, 20.30931125100004],
              [105.6683627840001, 20.311031506000084],
              [105.6654162450001, 20.313627287000102],
              [105.6591074450001, 20.320315411000053],
              [105.65549056800006, 20.323529360000059],
              [105.65120647300007, 20.32711140400005],
              [105.64692070800002, 20.330818415000081],
              [105.64088030500002, 20.337133461000079],
              [105.63070074300002, 20.345905581000068],
              [105.62615972500008, 20.348733722000119],
              [105.62376498100009, 20.34945841600004],
              [105.62270468800003, 20.349446966000073],
              [105.62085223100004, 20.349176775000011],
              [105.61965789000008, 20.349288954000052],
              [105.61806137500005, 20.349772041000058],
              [105.61632470000008, 20.350879040000081],
              [105.61298162600005, 20.353433342000038],
              [105.6020094620001, 20.362002335000092],
              [105.59729773700008, 20.364657107000106],
              [105.58699117300004, 20.368105084000021],
              [105.58047678, 20.370027576000012],
              [105.57972215000008, 20.370019280000037],
              [105.57881835100008, 20.36986691100013],
              [105.57717227100004, 20.368709375000016],
              [105.57315202700002, 20.364249793000063],
              [105.5700109630001, 20.361936270000044],
              [105.56700850600001, 20.360621233000039],
              [105.56414814200005, 20.360019878000024],
              [105.56083149200008, 20.359698294000061],
              [105.55781135600003, 20.359807230000101],
              [105.55236230000001, 20.361028578000081],
              [105.54540218200003, 20.362375377000049],
              [105.54113842700011, 20.363458801000078],
              [105.53941290800007, 20.367643267000091],
              [105.538225884, 20.368873627000106],
              [105.537938141, 20.369090445000012],
              [105.53659958300008, 20.370099064000065],
              [105.53218419400007, 20.371845959000012],
              [105.52895934200005, 20.372086145000075],
              [105.52691461300006, 20.371648640000025],
              [105.52531266100004, 20.370939733000078],
              [105.52401580100012, 20.369266977000052],
              [105.5226845610001, 20.367155251000035],
              [105.52243662100001, 20.366761941000085],
              [105.52187716100009, 20.364682922000021],
              [105.52117305300001, 20.362464076000016],
              [105.52088721500007, 20.361908126000024],
              [105.52001570000007, 20.361345577000108],
              [105.51840861300008, 20.361051099000079],
              [105.51211088800008, 20.361118191000038],
              [105.50537215700004, 20.361318289000074],
              [105.50199925500002, 20.361694586000034],
              [105.49610896700008, 20.364253134000073],
              [105.48431193000002, 20.370613169000059],
              [105.4782640290001, 20.37399848700003],
              [105.47546052700004, 20.375624520000102],
              [105.47384447500009, 20.37602052200009],
              [105.4720873310001, 20.37600035200002],
              [105.46307929000004, 20.376103991000072],
              [105.46000250400002, 20.376206692000039],
              [105.45882748400005, 20.376469492000112],
              [105.45720599800009, 20.377279850000015],
              [105.45396479100006, 20.378762369000114],
              [105.45146108600002, 20.379838837000094],
              [105.43778150300005, 20.384378138000066],
              [105.42778583600008, 20.387163330000121],
              [105.42440871399999, 20.387814730000017],
              [105.42382295900002, 20.387807876000075],
              [105.42294616100004, 20.387659445000047],
              [105.41860043300001, 20.384015883000117],
              [105.41467530900005, 20.38037416500007],
              [105.41151103300007, 20.37743819500006],
              [105.40948479500003, 20.37561801],
              [105.40788323600002, 20.374908262000076],
              [105.40583874900005, 20.374469635000111],
              [105.40290838500007, 20.37457326400007],
              [105.39114636800005, 20.37802688],
              [105.36856084500005, 20.38584159],
              [105.36723177700003, 20.386654775000054],
              [105.36634015600006, 20.387611354000065],
              [105.36484603000008, 20.389804334000061],
              [105.36395810400006, 20.390484597000032],
              [105.36248627000006, 20.391019682000078],
              [105.35645802400008, 20.392743696000082],
              [105.35498988700002, 20.393002419000084],
              [105.35382210700003, 20.392712030000034],
              [105.35193894700009, 20.391169428000033],
              [105.35091948000012, 20.390742629000066],
              [105.34960154000005, 20.390726766000085],
              [105.34490239500008, 20.391637393000025],
              [105.34357318900007, 20.392450429000064],
              [105.33926821900012, 20.396681944000058],
              [105.33615907000008, 20.399131535000059],
              [105.33306114700008, 20.40075214200003],
              [105.32562504600006, 20.403632729000073],
              [105.320164855, 20.406606206000077],
              [105.31351581700009, 20.410808599000063],
              [105.30833676000003, 20.414614230000083],
              [105.30581657100005, 20.416794179000064],
              [105.30268574700008, 20.420762871000072],
              [105.30179746400005, 20.421442843000115],
              [105.30047931200005, 20.421426687000107],
              [105.29917641300007, 20.420305338000055],
              [105.29758824300004, 20.418627776000072],
              [105.29598483900006, 20.418055399000053],
              [105.29451834200005, 20.418175559000048],
              [105.29025191800008, 20.419504830000079],
              [105.28611848500003, 20.421802856000056],
              [105.28005597400006, 20.425873225000132],
              [105.27871858200007, 20.427238420000023],
              [105.27841988900005, 20.427649234000086],
              [105.27691486100005, 20.430532225000064],
              [105.27602067500004, 20.431626534000102],
              [105.27513802100003, 20.431891955000125],
              [105.27396626800007, 20.431877453000077],
              [105.27193689500011, 20.430332443000054],
              [105.27062639300003, 20.429763519000055],
              [105.268868798, 20.429741728000025],
              [105.26194038900006, 20.432833624000054],
              [105.25677717700007, 20.435394610000031],
              [105.2612817670001, 20.443257226000085],
              [105.26213163900007, 20.445340318000135],
              [105.26409734500001, 20.451444105000057],
              [105.26478726900007, 20.454492338000115],
              [105.26462340200001, 20.455733789000071],
              [105.26250494200005, 20.460543262000016],
              [105.26102687600006, 20.463711693000072],
              [105.26039024700006, 20.465076390000014],
              [105.25919313700004, 20.466857629000046],
              [105.2568279180001, 20.468347976000075],
              [105.24297361800006, 20.474116004000017],
              [105.2400241350001, 20.47546069100008],
              [105.23898303600002, 20.476552941000122],
              [105.23838337100005, 20.477512557000033],
              [105.23792436200009, 20.478888418000096],
              [105.23776225400009, 20.479991667000071],
              [105.23875853000001, 20.482076573000093],
              [105.24263824900004, 20.48751340900008],
              [105.24269690900009, 20.488550344000075],
              [105.24224373600008, 20.489511787000048],
              [105.2391182580001, 20.492926604000061],
              [105.2377859740001, 20.493877013000095],
              [105.23631106600001, 20.494549308000032],
              [105.23528351300007, 20.49467457300009],
              [105.23336904500003, 20.495341331000105],
              [105.231595214, 20.49642430300009],
              [105.22863357400009, 20.498597591000056],
              [105.22788531500008, 20.49969343700004],
              [105.22772704900004, 20.500520388000076],
              [105.22855919600006, 20.503846611000128],
              [105.22840093000011, 20.504673559000075],
              [105.22780504300002, 20.50535684300003],
              [105.22662307000009, 20.506032748000088],
              [105.22325106200006, 20.506128432000104],
              [105.22075427300007, 20.506511419000084],
              [105.21928117500005, 20.507045455000124],
              [105.21632710100005, 20.508666012],
              [105.21171785700007, 20.513305003000056],
              [105.20993192, 20.515216549000037],
              [105.20860131400003, 20.516028621000082],
              [105.20358766500007, 20.518175510000077],
              [105.20181741600007, 20.518981958000012],
              [105.20005308400002, 20.51937400600012],
              [105.1988788350001, 20.519497235000102],
              [105.19317395100006, 20.518733869000094],
              [105.190682886, 20.51870212300004],
              [105.18848290900006, 20.518812216000093],
              [105.1862690180001, 20.519889185000025],
              [105.183605555, 20.52165114200006],
              [105.18213224200005, 20.522184918000086],
              [105.18095996500004, 20.522169924000089],
              [105.17870760000002, 20.520828645000044],
              [105.17391389300005, 20.517865995000051],
              [105.16939138700005, 20.516426401000039],
              [105.16851220600005, 20.516415105000085],
              [105.16763101500005, 20.516541933],
              [105.16688835800007, 20.51722315700006],
              [105.16673582400006, 20.517635658000088],
              [105.16522645500005, 20.520655620000113],
              [105.16418670100009, 20.521609315000063],
              [105.16255878400004, 20.522693584000073],
              [105.16093485900005, 20.523501585000048],
              [105.16004762300001, 20.524042764000058],
              [105.15931292800006, 20.524171446000047],
              [105.15900580900005, 20.525134547000064],
              [105.15983689200007, 20.528460916000043],
              [105.16025043400001, 20.530262219000051],
              [105.15991522500006, 20.533159067000014],
              [105.15901290500001, 20.53568629800013],
              [105.15752134800006, 20.537463009000057],
              [105.15155588800003, 20.543550553000053],
              [105.15022283300004, 20.54450036100004],
              [105.14904639700006, 20.544761443000056],
              [105.143764473, 20.545107495000089],
              [105.13935982800008, 20.545602960000053],
              [105.13803276600005, 20.546138324000118],
              [105.13640450000007, 20.547222360000106],
              [105.13521386300005, 20.548450219000088],
              [105.13205922600008, 20.553658787000103],
              [105.12907127500003, 20.55748798100003],
              [105.12744083500004, 20.558710052000073],
              [105.12300738800003, 20.561138848000034],
              [105.12005576800007, 20.562481745000035],
              [105.11890739799999, 20.56287583900005],
              [105.11863995000007, 20.562919484000105],
              [105.11652598600004, 20.563264466000049],
              [105.11138802800009, 20.563749769000061],
              [105.10953860700002, 20.564899760000088],
              [105.10655412199999, 20.56845234900004],
              [105.10399697500007, 20.572839356000117],
              [105.10325183600006, 20.573658423000026],
              [105.10222582000007, 20.573644937000068],
              [105.10105733600003, 20.573353286],
              [105.09873267000006, 20.571941276000061],
              [105.09408753300004, 20.568840921000039],
              [105.09292525200006, 20.568134873000062],
              [105.09233486200006, 20.568403374000077],
              [105.08698635100009, 20.573167778000112],
              [105.08402605200004, 20.575062640000041],
              [105.08018832000002, 20.576807694000095],
              [105.07827871200003, 20.577058676000078],
              [105.07637323100002, 20.577033410000034],
              [105.0708157820001, 20.576130772000042],
              [105.06349534700004, 20.57548079900009],
              [105.05998380800003, 20.57501956000003],
              [105.057952562, 20.573611024000101],
              [105.05403464600002, 20.570933971000024],
              [105.05346041600002, 20.57001393700002],
              [105.05289725400006, 20.568772698000082],
              [105.05223824900006, 20.564240252000054],
              [105.05182261000004, 20.562863884000123],
              [105.04995057400005, 20.561605112000066],
              [105.04489975900003, 20.558932907000035],
              [105.04373631100005, 20.558917304000076],
              [105.04241913200005, 20.559447962000021],
              [105.04065320200003, 20.560795065000015],
              [105.03992189200001, 20.561059404000034],
              [105.03919264700006, 20.561186698000085],
              [105.03281023700001, 20.560004210000038],
              [105.03033996500008, 20.559833859000065],
              [105.02670208700007, 20.559921893000066],
              [105.02008697100004, 20.559669747000072],
              [105.01994953100004, 20.560597439000091],
              [105.018482836, 20.562208387000069],
              [105.01628689900012, 20.564353041000061],
              [105.01031542200005, 20.568332350000063],
              [105.00811684600004, 20.570493573000093],
              [105.00548812900004, 20.572632230000032],
              [105.00301603700005, 20.573957592000092],
              [104.99994363500004, 20.575148321000114],
              [104.99847828900003, 20.576433614000052],
              [104.99579354200003, 20.57817755500011],
              [104.99495468200007, 20.578713965000084],
              [104.99337654400006, 20.578712014000097],
              [104.99138769600009, 20.578410951000059],
              [104.98602657100005, 20.577300719000036],
              [104.98511912300003, 20.576896992000023],
              [104.98230809700006, 20.574706256000042],
              [104.98131944500005, 20.574184],
              [104.97960947900006, 20.575324743000053],
              [104.97851057400008, 20.576561907000013],
              [104.97849606100004, 20.577500882000074],
              [104.97954540200008, 20.579471857000087],
              [104.982635875, 20.58374041600004],
              [104.98302091000005, 20.585702270000091],
              [104.98315923500004, 20.587504206000062],
              [104.98276786100008, 20.591333723000012],
              [104.98141127800001, 20.593115179000065],
              [104.98022802300008, 20.594429431],
              [104.97664593800008, 20.595084691000039],
              [104.97471590500008, 20.5949995060001],
              [104.97393647500007, 20.595712734000045],
              [104.97143918100004, 20.598730650000086],
              [104.96954954100001, 20.602735136000035],
              [104.96679460100006, 20.604947255000106],
              [104.95942244700008, 20.60891525200006],
              [104.95747108500009, 20.612860038000044],
              [104.956254648, 20.616286676000058],
              [104.95622601100011, 20.618125380000052],
              [104.95886194000008, 20.623581186000095],
              [104.95794605300006, 20.629046635000044],
              [104.95450900500006, 20.632344712000084],
              [104.949412624, 20.636813190000055],
              [104.94709490700008, 20.640243986000058],
              [104.94433211300002, 20.644235934000044],
              [104.94187353800007, 20.64471051900005],
              [104.93392440500006, 20.646928259000049],
              [104.93020307700009, 20.648441606000034],
              [104.92252619200012, 20.654458101000017],
              [104.91869749300005, 20.657495712000085],
              [104.91482898700008, 20.660415303000065],
              [104.91036886600008, 20.662641808000082],
              [104.90693474000007, 20.664354398000029],
              [104.89609875000011, 20.670364645000028],
              [104.88723736200011, 20.671061416000036],
              [104.885454205, 20.67084061100006],
              [104.88093413600002, 20.666374969000053],
              [104.87665708100008, 20.663614672000016],
              [104.86879876300003, 20.662662015000066],
              [104.86270562900009, 20.661753750000052],
              [104.85709718600003, 20.661713024000058],
              [104.851424509, 20.663079819000082],
              [104.84915822000006, 20.659330359000094],
              [104.84521873600006, 20.651771296000021],
              [104.84277607000011, 20.653810117000134],
              [104.84106439100006, 20.655605047000059],
              [104.83786391600002, 20.658894815000025],
              [104.83541489200007, 20.660033515000094],
              [104.83560782800004, 20.664154435000093],
              [104.83571941400004, 20.666885158000078],
              [104.83606889100008, 20.668396560000076],
              [104.83717798300002, 20.672393624000037],
              [104.83765726500006, 20.674229665000077],
              [104.837628651, 20.677281126000047],
              [104.83865359200007, 20.680064008000087],
              [104.83864777200003, 20.681707221000067],
              [104.83886505700008, 20.685613162000038],
              [104.83862788000008, 20.689356064000016],
              [104.83617577000005, 20.693869257000053],
              [104.83387545700006, 20.69670218600006],
              [104.83338260800005, 20.69890568300012],
              [104.83419715500003, 20.700570409000107],
              [104.839592012, 20.701039010000066],
              [104.84489128100006, 20.702924393000011],
              [104.84669465600007, 20.703840268000114],
              [104.84625176600009, 20.7042447480001],
              [104.84580666500004, 20.704786130000038],
              [104.84412757500012, 20.707109600000081],
              [104.84247649900001, 20.708983511000056],
              [104.83959523200009, 20.713040537],
              [104.83860434100009, 20.714561964000033],
              [104.83801858900003, 20.717370509000062],
              [104.83778193000008, 20.718511494000055],
              [104.83788461200004, 20.721153827000101],
              [104.84021858100007, 20.725246338000055],
              [104.84206634, 20.728568935000077],
              [104.84237979700001, 20.729747120000042],
              [104.84303583100011, 20.732231050000038],
              [104.84087490200001, 20.733491177000118],
              [104.83614651000002, 20.73480251200008],
              [104.83511320100007, 20.735726648000075],
              [104.83512102400009, 20.739091314000021],
              [104.83522458600004, 20.743601677000086],
              [104.83521630000007, 20.746036919000055],
              [104.83584730100006, 20.748139007000077],
              [104.84124549400003, 20.752979446],
              [104.84332504000005, 20.754838140000054],
              [104.84860369000009, 20.754874463000036],
              [104.85364384400003, 20.754877918000055],
              [104.85694559700002, 20.75506189],
              [104.86062730400009, 20.759407881000058],
              [104.86155503800002, 20.760526256000041],
              [104.86605034800009, 20.764052354000029],
              [104.86840071700004, 20.76589506500008],
              [104.872911926, 20.768443204000064],
              [104.87558214300005, 20.768490798000094],
              [104.87953961200002, 20.767979534000069],
              [104.88590482899998, 20.764421412000011],
              [104.89204621900009, 20.760546976000072],
              [104.89394659600003, 20.759331631000052],
              [104.89798547200007, 20.756297851000035],
              [104.89906821600005, 20.75552085700005],
              [104.90532878500007, 20.752615938000041],
              [104.91081665300001, 20.750590036000105],
              [104.91285858400002, 20.750266515000071],
              [104.91666082900012, 20.749683944000097],
              [104.92075555200007, 20.749662878000038],
              [104.92513187800006, 20.749577190000053],
              [104.92885810700008, 20.749198744000097],
              [104.93607856400004, 20.746668164000056],
              [104.941177178, 20.743589582000062],
              [104.947110077, 20.741637389000097],
              [104.95512799200003, 20.73924434200007],
              [104.96140280500006, 20.7366705190001],
              [104.96812094300002, 20.732302945000036],
              [104.97308867000001, 20.728224130000058],
              [104.97688059300005, 20.724911540000065],
              [104.98101915400008, 20.723364158000024],
              [104.993487429, 20.723377913],
              [104.99588906900003, 20.723958348000068],
              [104.99995064299999, 20.730116796000075],
              [105.00241469800004, 20.731531915000097],
              [105.00398657100007, 20.733353794000031],
              [105.005489772, 20.734920618000082],
              [105.00710888300003, 20.736631130000127],
              [105.00883599100003, 20.73821406200009],
              [105.01008574500005, 20.739425922000088],
              [105.0112220660001, 20.74066840600004],
              [105.01159734000007, 20.741122108000098],
              [105.01208103800012, 20.741597475000084],
              [105.01239473800004, 20.741823330000031],
              [105.01174994300007, 20.741898288000101],
              [105.01140277600004, 20.74195356300006],
              [105.01082521300003, 20.742133487000025],
              [105.01046354100004, 20.742334308000032],
              [105.00935484200005, 20.743179950000098],
              [105.00632510900002, 20.745592203000029],
              [105.00000007600009, 20.750294054000037],
              [104.99630466800004, 20.751680406000084],
              [104.99344161200003, 20.75213765400008],
              [104.99154946000006, 20.752318634000041],
              [104.99032198500001, 20.752136482000068],
              [104.98999757000009, 20.752556433000088],
              [104.98973555400008, 20.753374411],
              [104.99019326400003, 20.756040897000091],
              [104.99172011600005, 20.759465252],
              [104.9949829550001, 20.763539156000022],
              [104.99773092000001, 20.767312570000037],
              [104.99877426000009, 20.769752225000012],
              [104.99942673099999, 20.77328192100012],
              [105.00000008800001, 20.776381769000096],
              [105.00197518400009, 20.78031617700011],
              [105.00317816600005, 20.782664367000066],
              [105.00335779600009, 20.784415695000021],
              [105.00332051700011, 20.78684419300005],
              [105.00317701100005, 20.789465550000052],
              [105.00358101100008, 20.790053995000065],
              [105.00388923300009, 20.790155334000104],
              [105.00481984300009, 20.790070808000038],
              [105.00606761500003, 20.789504775000097],
              [105.00950713800005, 20.787413893000029],
              [105.01033747600009, 20.787133664000109],
              [105.01085365000009, 20.787140655000066],
              [105.01136386600011, 20.787536196000111],
              [105.01176342500004, 20.788416043000076],
              [105.01256700500009, 20.789884317000059],
              [105.01366693700007, 20.79223101700007],
              [105.01619738300009, 20.795568635000095],
              [105.01996977200011, 20.798728648000072],
              [105.02210078400003, 20.801186351000034],
              [105.02269801100007, 20.802651772000047],
              [105.02267134200008, 20.804400235000031],
              [105.02183353800004, 20.805166195000076],
              [105.02058422900006, 20.805829436000067],
              [105.01902814, 20.806294212000061],
              [105.01685555200001, 20.806556333000081],
              [105.01530687500004, 20.806535400000122],
              [105.01335117100004, 20.806120321000066],
              [105.01149425600006, 20.80599803800007],
              [105.01087181400007, 20.806183926000088],
              [105.01003544400007, 20.806852698000085],
              [105.008775665, 20.80819582500002],
              [105.00699069700002, 20.810114755000072],
              [105.00361978700009, 20.813076644000091],
              [105.00132295400003, 20.814697104000054],
              [105.00000008600009, 20.815261988000074],
              [104.99678550200012, 20.815259639000061],
              [104.99594321800006, 20.815952294000084],
              [104.99584562300006, 20.816889790000026],
              [104.99655782900003, 20.819246515000067],
              [104.99964405300003, 20.824060768000052],
              [105.00012009900007, 20.82555368200012],
              [104.99976465400006, 20.827035295000051],
              [104.99930441200009, 20.829923677000025],
              [104.99970266200009, 20.830989518000109],
              [105.0000000850001, 20.831439829000061],
              [105.00106627900001, 20.832725111000094],
              [105.00199783100001, 20.83378128],
              [105.00212721500006, 20.834419292000028],
              [105.00197480400006, 20.835280185000023],
              [105.001834667, 20.836991661000056],
              [105.00191363100005, 20.838214114000039],
              [105.00233207100004, 20.839984215000037],
              [105.00324197100009, 20.84172253600007],
              [105.00445128700005, 20.844523156000072],
              [105.00443978200008, 20.845272591000082],
              [105.00408844700006, 20.84591033],
              [105.00339892100003, 20.84632931600003],
              [105.00248175200008, 20.846745201000068],
              [105.000000083, 20.847027742000087],
              [104.99640643800002, 20.846645509000084],
              [104.99573273900006, 20.847183961000049],
              [104.99563996600003, 20.849158030000048],
              [104.99674461000002, 20.853045474000083],
              [104.99854167300003, 20.855182118000037],
              [104.99893041100005, 20.856947582000096],
              [104.99855862400001, 20.858135529000066],
              [104.99558769500004, 20.86200660600008],
              [104.99257265100007, 20.866033478000112],
              [104.99127149600005, 20.868147486000055],
              [104.99057824100008, 20.871030502000032],
              [104.99007151400008, 20.873137745000037],
              [104.98940547900003, 20.877216056000059],
              [104.98760151800008, 20.880907251000025],
              [104.98204307400006, 20.886639690000038],
              [104.97857595800008, 20.890268908000031],
              [104.97668716900003, 20.895386392000056],
              [104.97556885600005, 20.904464691000065],
              [104.9754052800001, 20.906965618000051],
              [104.97378474100005, 20.909524800000057],
              [104.97108153700002, 20.912147337000064],
              [104.96908186200004, 20.913664819000033],
              [104.96820596400003, 20.913697286000051],
              [104.964461348, 20.913836046000078],
              [104.96120427700008, 20.914612610000027],
              [104.95901946000005, 20.915990554000082],
              [104.95543916800003, 20.920145695000066],
              [104.95097941800006, 20.9259899400001],
              [104.94933271400005, 20.927531634000026],
              [104.94847356200002, 20.927930431000071],
              [104.94256985600005, 20.930312773000104],
              [104.93929155900005, 20.932418460000065],
              [104.93709287700003, 20.93465638400005],
              [104.93549701700006, 20.93692219900003],
              [104.93405727100003, 20.941165207],
              [104.93236151300006, 20.94448554800001],
              [104.93094687500007, 20.947125345000117],
              [104.92758852800003, 20.952964616000088],
              [104.92625449600004, 20.955761914000078],
              [104.92465478500003, 20.959572311000052],
              [104.92326659200009, 20.961840840000079],
              [104.92161381100004, 20.963734135000053],
              [104.91915506300008, 20.966593886000062],
              [104.91475821000009, 20.969641646000078],
              [104.90583801700009, 20.97581330400002],
              [104.90227972200003, 20.978442326000035],
              [104.89890017099999, 20.981601742000052],
              [104.89506264000009, 20.988684905000099],
              [104.8928428840001, 20.992173313000073],
              [104.89112540400002, 20.996783286000031],
              [104.89071599000007, 20.998967480000097],
              [104.89022146000011, 21.001267780000084],
              [104.88976283100007, 21.005230581000056],
              [104.88921854800007, 21.008038529000125],
              [104.88894578100006, 21.009481592000064],
              [104.88851779300009, 21.010218579000117],
              [104.88694998300005, 21.011956248],
              [104.88477280300003, 21.014056818000078],
              [104.8829552400001, 21.015790929000033],
              [104.88094303400005, 21.019282005000051],
              [104.87920908300002, 21.022288156000087],
              [104.8755377050001, 21.025403781000037],
              [104.87455635900008, 21.025984756000057],
              [104.86599256700001, 21.029117831000029],
              [104.86546643700009, 21.030806062000096],
              [104.86456418400005, 21.033701104],
              [104.86386545800005, 21.038797226000035],
              [104.86333910900002, 21.040829951000092],
              [104.85935518000008, 21.04911955900004],
              [104.858119856, 21.052254982000115],
              [104.85758892500004, 21.054565787000087],
              [104.85715431400006, 21.057784721000061],
              [104.85619997900005, 21.060338356000052],
              [104.85306469400007, 21.068126670000083],
              [104.85210972700003, 21.071191158000069],
              [104.85156144600003, 21.07553320600006],
              [104.85075191100003, 21.077386691000029],
              [104.85359768500004, 21.079026076000048],
              [104.85576084100005, 21.080849397000101],
              [104.8644082000001, 21.076803367000082],
              [104.86649839900012, 21.076285576000053],
              [104.86687696100009, 21.078637011000097],
              [104.86770024700009, 21.081815848000041],
              [104.86924822, 21.083949214000071],
              [104.87096275200003, 21.086084909000036],
              [104.87128072500003, 21.087027826000067],
              [104.87125435700008, 21.088669667000111],
              [104.86995771699999, 21.091622952000044],
              [104.86994264300009, 21.09256113900004],
              [104.87009032200004, 21.093736234000104],
              [104.87323257900006, 21.09772976900004],
              [104.87734192300007, 21.101150396],
              [104.88602239700003, 21.105182710000037],
              [104.89140113100009, 21.107369715000068],
              [104.89695400500008, 21.109089833000013],
              [104.90220496100005, 21.111470259000065],
              [104.90509087000001, 21.113309200000074],
              [104.90641700300004, 21.113718733000027],
              [104.91141317500006, 21.113203169000094],
              [104.91064258799999, 21.109867913000087],
              [104.91067234700006, 21.107991577000092],
              [104.91080520500003, 21.104865504000081],
              [104.91129354700003, 21.10295645900004],
              [104.91335885700002, 21.098762539000049],
              [104.91642695900003, 21.094347957000089],
              [104.91778638600007, 21.092646506000087],
              [104.92115150400006, 21.090503766000076],
              [104.92183119200008, 21.089653022000029],
              [104.92331234800007, 21.088148719000088],
              [104.92383042900012, 21.086982913000085],
              [104.92392850600007, 21.086045872000092],
              [104.92369848100003, 21.084791451000036],
              [104.92197473500005, 21.083203447000095],
              [104.92083135400004, 21.081779910000087],
              [104.92052550700005, 21.080055217000023],
              [104.92032270000006, 21.077080725000116],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 30, code: "AD01", ten_tinh: "Hưng Yên" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.10045874800004, 20.99828782200008],
              [106.1008159300001, 20.998179115000056],
              [106.10123054700003, 20.998266507],
              [106.10187984500003, 20.998635367000063],
              [106.10356366200008, 20.999432271],
              [106.10586984900002, 21.000318324000133],
              [106.106816452, 21.000633823000037],
              [106.10723162000008, 21.000665316000067],
              [106.10755858900004, 21.000612232000023],
              [106.11120108500006, 20.998351923000058],
              [106.11369388499999, 20.996822284000061],
              [106.11373498100002, 20.992575018000089],
              [106.11375255400006, 20.990758753000094],
              [106.11321308700009, 20.989178618],
              [106.11308195599999, 20.986693203000058],
              [106.11325126900005, 20.98523328800006],
              [106.11372383900007, 20.984506645000067],
              [106.11481423700005, 20.98407753900009],
              [106.11636306800004, 20.984383013000048],
              [106.11806003100003, 20.98542042200009],
              [106.11929854600001, 20.985723232000097],
              [106.12147370400004, 20.98544946],
              [106.12522910500007, 20.984826623000075],
              [106.1249729200001, 20.982953130000048],
              [106.12498202200005, 20.982006765000079],
              [106.12536795700001, 20.981063604000028],
              [106.12663091400006, 20.980364478000091],
              [106.13002925500004, 20.97968342000005],
              [106.13217146000001, 20.978991698000101],
              [106.13356224600007, 20.978057007000039],
              [106.13609924800002, 20.975517123000117],
              [106.13315579100001, 20.972410480000022],
              [106.13096568300008, 20.971636391000104],
              [106.13140782000006, 20.966917953000049],
              [106.13347524300006, 20.960901684000085],
              [106.13415411100002, 20.960851038000072],
              [106.137749032, 20.96058280500008],
              [106.14114022300002, 20.960611355000047],
              [106.14415912500004, 20.960163488000113],
              [106.14630106500007, 20.959471616000066],
              [106.14832638600006, 20.957832258000053],
              [106.14887296200007, 20.957307814],
              [106.151748927, 20.954548229000025],
              [106.15415541100008, 20.952438758000099],
              [106.15554702200011, 20.951385573],
              [106.15592828300004, 20.950915519000098],
              [106.15593723300006, 20.949969103000043],
              [106.15569053899999, 20.949493789000059],
              [106.154942578, 20.948895983000071],
              [106.15231747200005, 20.947572624000088],
              [106.14981574000009, 20.946486888000045],
              [106.14844547500005, 20.945292289000058],
              [106.14821001900002, 20.943633949000073],
              [106.14821900600006, 20.942687522000099],
              [106.14840377400006, 20.940019579000065],
              [106.148145351, 20.934532760000124],
              [106.14719886400012, 20.931084224000074],
              [106.14696905600002, 20.928834310000077],
              [106.14750058700012, 20.925762564000117],
              [106.1482742700001, 20.923639364000032],
              [106.15165698200012, 20.924495899000114],
              [106.15329101200005, 20.918505836000101],
              [106.15012799100001, 20.917250824000085],
              [106.14823304700006, 20.916199525000053],
              [106.1478825460001, 20.91600507400004],
              [106.14604712600003, 20.914817136000075],
              [106.14391337800005, 20.913436071000021],
              [106.14159055800006, 20.911208033000058],
              [106.13548031100005, 20.905594334000099],
              [106.13487819100006, 20.904220342000102],
              [106.135029919, 20.902558830000117],
              [106.13565167100002, 20.900301994000081],
              [106.13574262800009, 20.89997183100003],
              [106.13697154000008, 20.894453787000074],
              [106.14302774600006, 20.892436173000114],
              [106.14454916500009, 20.891156076000073],
              [106.14511759300004, 20.889092224000102],
              [106.145156926, 20.884955298000115],
              [106.14490344700008, 20.882755244000073],
              [106.14354759200008, 20.881063070000039],
              [106.14232770000004, 20.879501324000046],
              [106.14112877600004, 20.87574179300011],
              [106.14036483300008, 20.869529334000077],
              [106.14014839900003, 20.863450712000109],
              [106.13976769900006, 20.86021514100009],
              [106.13923997500009, 20.858012673000111],
              [106.13768537300007, 20.855607595000066],
              [106.13602040300006, 20.85220046400007],
              [106.13274729100004, 20.852068960000025],
              [106.12963491700002, 20.851568723000113],
              [106.12732964900006, 20.850790959000065],
              [106.12622820800004, 20.85030772500005],
              [106.12543022300011, 20.849637496000106],
              [106.12543659600001, 20.848974093000017],
              [106.12574550699999, 20.84821848500011],
              [106.12686695700006, 20.846616753000099],
              [106.12737603199999, 20.845957610000099],
              [106.12748384800003, 20.845200290000051],
              [106.12739331100008, 20.844156938000054],
              [106.12611903900007, 20.840734043000083],
              [106.1233658940001, 20.834265563000095],
              [106.12297651400003, 20.832935316000075],
              [106.12289147600011, 20.831323297000012],
              [106.12350834800007, 20.82990681100005],
              [106.12687132800006, 20.83096415000005],
              [106.12736799200003, 20.830048521000052],
              [106.13137624300003, 20.826849931000076],
              [106.13635301500005, 20.822754314000044],
              [106.13801353100004, 20.821216697000096],
              [106.13871403900001, 20.819670964000103],
              [106.13886351500003, 20.818379183000069],
              [106.13887954200001, 20.816698371000086],
              [106.13754516200001, 20.812807986000067],
              [106.13687058700006, 20.811638550000033],
              [106.13591804400004, 20.810854687000102],
              [106.13345456000005, 20.810316647000079],
              [106.13112699100002, 20.809909040000015],
              [106.12962842800002, 20.808861911000065],
              [106.12964081700005, 20.807568952000018],
              [106.13116412900007, 20.806030188000065],
              [106.13379187700006, 20.803724899000109],
              [106.13614778200012, 20.801158660000048],
              [106.13906691700006, 20.79704544800007],
              [106.14160698800001, 20.793491218000071],
              [106.14176650400006, 20.793262275000046],
              [106.14629566000008, 20.786761547000054],
              [106.14941085000008, 20.783619577000053],
              [106.15272243000008, 20.781449026000054],
              [106.1553312660001, 20.781082909000048],
              [106.15724476800007, 20.781745451000063],
              [106.15819846500003, 20.782399969000039],
              [106.15901648300012, 20.784092013000063],
              [106.15913998500011, 20.784347475000061],
              [106.16022595, 20.785520322000117],
              [106.16214072000007, 20.786053519000092],
              [106.1629634330001, 20.786060368000037],
              [106.16447415700004, 20.785814311000102],
              [106.16557960100002, 20.784918332000046],
              [106.17058851200004, 20.777201232000039],
              [106.17587364800008, 20.769227590000035],
              [106.17823703000005, 20.76575563500009],
              [106.1796201220001, 20.764473906000092],
              [106.18154437300007, 20.763972513000084],
              [106.18510783200003, 20.764131156000062],
              [106.18907540600003, 20.765068943000117],
              [106.19119051700007, 20.766028684000034],
              [106.19557419800005, 20.758046417000031],
              [106.19653004499999, 20.75682922300004],
              [106.20116691000004, 20.753359090000068],
              [106.20264833400005, 20.752758657000037],
              [106.2083641450001, 20.752132125000067],
              [106.21287505000004, 20.751282605000121],
              [106.21385255700001, 20.750265295000055],
              [106.21488140800005, 20.748553425000082],
              [106.21699140900002, 20.739396036000102],
              [106.21701234800005, 20.737102573000051],
              [106.21564121300003, 20.73193078700006],
              [106.21567264500005, 20.728490550000075],
              [106.21630140700003, 20.726201962000076],
              [106.2233375980001, 20.710011672000036],
              [106.22539295600008, 20.706778748000062],
              [106.2272300540001, 20.705264359000083],
              [106.23150150600003, 20.703387174000021],
              [106.23638750000001, 20.700750239000065],
              [106.24026223600006, 20.697913968000044],
              [106.24393669200006, 20.693753275000049],
              [106.2466508840001, 20.688102161000096],
              [106.24709598600008, 20.687524547000088],
              [106.24797799000004, 20.687282488000044],
              [106.25120074000006, 20.687152168000097],
              [106.25368863800003, 20.687161726000092],
              [106.25589818500006, 20.687096185000087],
              [106.25763581200005, 20.686798083000021],
              [106.26006334100008, 20.686003393000085],
              [106.26189762700005, 20.685234025000021],
              [106.26330673100003, 20.685079079000076],
              [106.26568135700003, 20.685180783000057],
              [106.26765557300006, 20.685548698000034],
              [106.26755176700007, 20.683687176000106],
              [106.26671184600004, 20.682476507000032],
              [106.26389316700005, 20.680581353000079],
              [106.26078625300002, 20.679219018000069],
              [106.25230675900009, 20.676208700000039],
              [106.24552263100001, 20.673880463000046],
              [106.24199309100001, 20.67224691600007],
              [106.24101899300005, 20.671519347000086],
              [106.23819094400001, 20.669407034000109],
              [106.23732062500002, 20.668571630000088],
              [106.23552052600004, 20.666843710000116],
              [106.23299919000006, 20.663478800000057],
              [106.23176688700005, 20.6617414400001],
              [106.23089955700006, 20.660518596000088],
              [106.22892597100009, 20.659298641000042],
              [106.22567181500006, 20.658603538000087],
              [106.21845240700002, 20.657475018000056],
              [106.2161905510001, 20.656787777000126],
              [106.21521298400003, 20.655174379000037],
              [106.21579584900005, 20.653439813000048],
              [106.21737090900007, 20.651713253000104],
              [106.22294635000006, 20.646540344000108],
              [106.22352912000005, 20.644805725000111],
              [106.2235437150001, 20.643200325000066],
              [106.22199312500005, 20.642251272000053],
              [106.21618193000009, 20.642204343000081],
              [106.20482825600003, 20.643717745000068],
              [106.20141546900001, 20.644894075000053],
              [106.19086738100006, 20.651229849000075],
              [106.18190725500006, 20.656151290000047],
              [106.17775965599999, 20.658346676000058],
              [106.17447174600008, 20.659256021000068],
              [106.17264796700009, 20.659240919000062],
              [106.17095324300001, 20.658557921000039],
              [106.16955205400008, 20.656807011000033],
              [106.16843688900009, 20.654790885000104],
              [106.16804802700007, 20.650907690000061],
              [106.1680655860001, 20.649034739000079],
              [106.16695048100003, 20.647018581000061],
              [106.16539887900008, 20.646202917000075],
              [106.1625640940001, 20.646179316000079],
              [106.16014823900005, 20.646828147000029],
              [106.15658203100003, 20.649206641000092],
              [106.14899497000005, 20.657036816000037],
              [106.14528415000005, 20.659681464000101],
              [106.14244027000002, 20.660594066000023],
              [106.13719553800004, 20.660549831000097],
              [106.13479345900006, 20.659726784000028],
              [106.13239907300002, 20.658101034000076],
              [106.12917986700002, 20.653792422],
              [106.12597566300012, 20.650476053000055],
              [106.12553045500005, 20.650015247000027],
              [106.12285406900003, 20.648253170000075],
              [106.12016872300006, 20.64742754400006],
              [106.11690999600009, 20.647265943000015],
              [106.11280600500007, 20.646561899000027],
              [106.11083071000006, 20.645608431000092],
              [106.10886061400004, 20.644119813000117],
              [106.10817135500011, 20.642106989000105],
              [106.10822655000007, 20.64006708600008],
              [106.10837670300005, 20.639198714000081],
              [106.10916853600003, 20.637934447000106],
              [106.110308221, 20.637342139000033],
              [106.11229376900005, 20.637225352000051],
              [106.11526885000009, 20.637384592000082],
              [106.11661724300002, 20.637195413000065],
              [106.11847071300008, 20.636073976000091],
              [106.12296802900005, 20.632700506000035],
              [106.12539539200006, 20.630848001000054],
              [106.12673632200008, 20.629500248000099],
              [106.12768892600012, 20.627653048000113],
              [106.12781137300004, 20.626449951000048],
              [106.12765353300003, 20.625558599000016],
              [106.12738327900006, 20.624823358000107],
              [106.12661493000007, 20.623979163000072],
              [106.12546042400002, 20.622922276000018],
              [106.12402866000005, 20.621863014000041],
              [106.121934987, 20.620431637000088],
              [106.11855738800006, 20.618132504000108],
              [106.11585292300002, 20.61874629200009],
              [106.11172713700006, 20.619229727000032],
              [106.10788107500008, 20.619196757000054],
              [106.10389891200002, 20.619032864000083],
              [106.10312396800002, 20.61889048100003],
              [106.10019651000002, 20.618352582000021],
              [106.09581365900002, 20.617017936000082],
              [106.09185054000002, 20.614908739],
              [106.08693871200005, 20.611494383000029],
              [106.08339167000001, 20.608999565000104],
              [106.08039284300006, 20.606639140000112],
              [106.07779849000006, 20.605060299000044],
              [106.07574463400003, 20.604393954000066],
              [106.07286158500006, 20.604239085000039],
              [106.06997598000004, 20.604343531000069],
              [106.06667448100008, 20.604833356000093],
              [106.06212782200004, 20.606219983000074],
              [106.05785064200008, 20.608127618000083],
              [106.05453355100006, 20.610173334000059],
              [106.05314710600001, 20.611457951000119],
              [106.04994786800007, 20.615449897000104],
              [106.04564573, 20.619821046000105],
              [106.04358918000003, 20.622023540000086],
              [106.04212907200009, 20.623587220000061],
              [106.04120213600004, 20.624579887000039],
              [106.03842104000006, 20.627926898000034],
              [106.03451222100003, 20.634116780000063],
              [106.03211556500005, 20.640190364000063],
              [106.03137644400002, 20.645370921000072],
              [106.03134509100008, 20.648482910000077],
              [106.03116982200007, 20.652241991000061],
              [106.03125756000003, 20.657170477000086],
              [106.03137142200003, 20.659505654000029],
              [106.03159656200002, 20.664435311000062],
              [106.03237520600005, 20.668980854000019],
              [106.03329934900005, 20.673902573000099],
              [106.03327418000001, 20.676402826000114],
              [106.03324707000007, 20.679095714000084],
              [106.03301032900002, 20.682363623000107],
              [106.03267168000006, 20.683242360000072],
              [106.03175444100003, 20.685622433000091],
              [106.03002167100001, 20.687815618000101],
              [106.02562248300008, 20.693571633000118],
              [106.02297732400007, 20.696137337000039],
              [106.01954265100001, 20.699065816000051],
              [106.01650949700004, 20.701134730000078],
              [106.01269306500011, 20.702949929000077],
              [106.00839320700005, 20.704166985000057],
              [106.0067825400001, 20.704622851000032],
              [106.00350236900003, 20.70520970400009],
              [106.00126235600005, 20.705280123000058],
              [105.99633547900008, 20.705253465000069],
              [105.99235935500008, 20.705231882000064],
              [105.99162442900011, 20.705142733000059],
              [105.98999861100006, 20.70494550800008],
              [105.98780087000003, 20.704678883000049],
              [105.98619747000005, 20.703910716000081],
              [105.981022823, 20.701226051000084],
              [105.97762748200009, 20.700629791000047],
              [105.97462838800001, 20.700413897000125],
              [105.97322399000008, 20.700777771000034],
              [105.972211911, 20.70189877300006],
              [105.97117437600005, 20.705468530000104],
              [105.96892067800009, 20.710534123000102],
              [105.96522701000001, 20.71935397500004],
              [105.96398721000001, 20.723110111000054],
              [105.96355363100011, 20.726308532000104],
              [105.96372033800004, 20.729512476000075],
              [105.96449717600011, 20.731780156000035],
              [105.9674759530001, 20.735009983000019],
              [105.96745830900007, 20.736705193000127],
              [105.96639697400009, 20.742534991000113],
              [105.96571422300008, 20.750440291000061],
              [105.96558192600006, 20.754528872000044],
              [105.96550229800005, 20.75726365300001],
              [105.96549437700001, 20.757535268000105],
              [105.96542070800004, 20.760419861000031],
              [105.96578578200005, 20.762861072000049],
              [105.9664411680001, 20.767117527000067],
              [105.96660992900003, 20.770132912000072],
              [105.96597614700008, 20.773329258000039],
              [105.96394523900008, 20.776135976000099],
              [105.96111368300011, 20.778935270000076],
              [105.95849597300005, 20.780417973000098],
              [105.95227548900007, 20.781867241000015],
              [105.93301696800009, 20.785831674000029],
              [105.92659653599999, 20.788220133000031],
              [105.92357211400004, 20.790263649000096],
              [105.92075986200005, 20.793620507000107],
              [105.91950612600006, 20.795122677000052],
              [105.91418911300005, 20.805620427000093],
              [105.91119485700001, 20.814162171000113],
              [105.90952075800006, 20.820926952000015],
              [105.90928425500002, 20.824314995000037],
              [105.90919951900003, 20.832224784000083],
              [105.90938156400009, 20.833921615000058],
              [105.91172559200005, 20.839405800000051],
              [105.91468851700006, 20.843200668000073],
              [105.91846247800004, 20.846061410000068],
              [105.92782474500009, 20.850669651000061],
              [105.92980899900004, 20.852383326],
              [105.93198352400003, 20.85504044800004],
              [105.9337437020001, 20.859012037000113],
              [105.93441162000002, 20.862125856000091],
              [105.93438769700001, 20.864385674000019],
              [105.93334861600009, 20.867954336000061],
              [105.93170876100004, 20.871517339000079],
              [105.92846485200003, 20.875253619000134],
              [105.92462609000003, 20.878419229000045],
              [105.91622329700004, 20.882701758000088],
              [105.91198884800008, 20.884851411000049],
              [105.9059186910001, 20.888104472000087],
              [105.89799585900002, 20.892127936000101],
              [105.89611312600003, 20.893876066000061],
              [105.8957962110001, 20.893998702000083],
              [105.89426707000005, 20.895875833000055],
              [105.89373692300006, 20.898393049000049],
              [105.89372878900011, 20.900127018000077],
              [105.8945359950001, 20.902026397000029],
              [105.89684561000007, 20.905516463000048],
              [105.90283003600007, 20.910302514000101],
              [105.90831516900005, 20.914768358000032],
              [105.91030675600007, 20.916678842000103],
              [105.91095375200004, 20.918891868000067],
              [105.91092842799999, 20.921256156000112],
              [105.91024810500002, 20.923141340000129],
              [105.90972778300004, 20.923766956000023],
              [105.90634621400002, 20.927360526000079],
              [105.90946167900005, 20.931961372000082],
              [105.91276707900008, 20.938455511000065],
              [105.90242451100008, 20.947027209000076],
              [105.90245664800008, 20.951835169000077],
              [105.90340344900008, 20.957361080000055],
              [105.90504141300006, 20.961001994000036],
              [105.90816165600005, 20.967178894000057],
              [105.90896804900007, 20.970181357000058],
              [105.90926968100007, 20.973435330000086],
              [105.91009466000003, 20.974605426],
              [105.91226470300006, 20.9755716660001],
              [105.91426875200005, 20.976378694000083],
              [105.91846312100009, 20.976260612000083],
              [105.92215604000003, 20.975980113000055],
              [105.92954853300009, 20.97478851900005],
              [105.930896874, 20.974170661000088],
              [105.9327583510001, 20.97261187000008],
              [105.93377960300005, 20.971202821000112],
              [105.93473637000007, 20.96898204700004],
              [105.93708758800001, 20.968880373000061],
              [105.95225727100006, 20.969486110000091],
              [105.95467792600007, 20.968078420000055],
              [105.95545296900008, 20.967621441000084],
              [105.96000395900003, 20.965456769000035],
              [105.96101344900008, 20.965150842000035],
              [105.96181843600007, 20.965158267000028],
              [105.96223765800002, 20.965072412000026],
              [105.96285153700011, 20.965798286000044],
              [105.96300606700001, 20.967060694000047],
              [105.96269237600008, 20.968920088000061],
              [105.96229243700003, 20.971152292000014],
              [105.96123843100004, 20.975713590000034],
              [105.96087664600003, 20.978232177000052],
              [105.96099984600002, 20.982489045000108],
              [105.96064628800008, 20.984219587000027],
              [105.96011840700001, 20.986578985000023],
              [105.95915901000002, 20.988127116000044],
              [105.96047301400003, 20.99207558600007],
              [105.96173955000002, 20.991952927000042],
              [105.96309120200004, 20.991019692000101],
              [105.96461220000003, 20.98993038700003],
              [105.96562509900008, 20.989309243000065],
              [105.96662483200002, 20.989948910000052],
              [105.96897439200009, 20.991861911000065],
              [105.96960767500009, 20.993443886000037],
              [105.96975570600009, 20.995336635000058],
              [105.97024738400009, 20.996444464000064],
              [105.97107616800008, 20.997397758000069],
              [105.97508358000002, 20.999168263000101],
              [105.97658985300004, 20.999497288000057],
              [105.97742849200006, 20.999504963000071],
              [105.97919541800006, 20.99830042300006],
              [105.98298148700006, 20.997499872000077],
              [105.98492407400003, 20.997517589000033],
              [105.98785685700004, 20.999615436000063],
              [105.9888548280001, 21.001715472000093],
              [105.99232867100002, 21.002320227000034],
              [105.99530330300003, 21.002095140000065],
              [105.99786863200011, 21.001897624000065],
              [105.99900933800006, 21.001745332000077],
              [106.00007353300002, 21.001602360000057],
              [106.00141860500003, 21.001299293000031],
              [106.00259755500005, 21.000837109000067],
              [106.00327492500004, 21.000212773000015],
              [106.00462320800006, 20.999594491000018],
              [106.00630210100005, 20.999452023000039],
              [106.00948410200007, 20.999953547000061],
              [106.01231298700002, 21.002185588000089],
              [106.01347906800004, 21.002984139000077],
              [106.01566531100009, 21.003113888000065],
              [106.01567671600006, 21.002774288000033],
              [106.01601374300006, 21.000799956000016],
              [106.01703508200009, 20.999706177000022],
              [106.02108935500009, 20.996905455000011],
              [106.02379053800003, 20.995195849000048],
              [106.02581280600002, 20.994268232000124],
              [106.02692557800007, 20.994278167000054],
              [106.0285822430001, 20.994723908000076],
              [106.02966107000002, 20.995464193000046],
              [106.0319709880001, 20.997238375000023],
              [106.03613129100006, 21.00019804300004],
              [106.03766542700006, 21.001965257000066],
              [106.03920693100008, 21.003001855000036],
              [106.04399993200008, 21.004797898000049],
              [106.05383650100003, 21.00739057200007],
              [106.05416110000007, 21.005657352000107],
              [106.05479058600011, 21.002832058000109],
              [106.05660836700005, 21.001432632000046],
              [106.06291836300007, 21.001771090000076],
              [106.06802873900004, 21.001815811000043],
              [106.07224849100004, 21.000720320000084],
              [106.07836410500005, 20.997041665],
              [106.08005399899999, 20.999853187000014],
              [106.08111436100006, 21.002492774000103],
              [106.08186725600009, 21.004837408000029],
              [106.08294059399999, 21.00616189900002],
              [106.08401682800005, 21.007194148000075],
              [106.086807268, 21.007510573000054],
              [106.08996569900003, 21.007963324000031],
              [106.08969307600003, 21.006662240000118],
              [106.08969636200004, 21.006326934000086],
              [106.09002552600001, 21.006050336000023],
              [106.09205630600007, 21.004754489],
              [106.09375653100007, 21.003874927000062],
              [106.09462284799999, 21.003295563000087],
              [106.09611751400011, 21.002190666000061],
              [106.09737503100007, 21.001055761000131],
              [106.09920180200004, 20.999366862],
              [106.10016009900002, 20.998480871000069],
              [106.10045874800004, 20.99828782200008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 31, code: "AD01", ten_tinh: "Khánh Hòa" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [113.59086926200003, 6.954622397000064],
              [113.58325818400009, 6.953306039000038],
              [113.57937091100007, 6.956796074000058],
              [113.57854221099998, 6.960681741000128],
              [113.57969688000003, 6.965135557000044],
              [113.58076083100005, 6.968487088000062],
              [113.58735600500003, 6.968309767000045],
              [113.59155019300015, 6.965478289000069],
              [113.59336398000002, 6.960283089000095],
              [113.59086926200003, 6.954622397000064],
            ],
          ],
          [
            [
              [113.86706220600004, 7.409177906000062],
              [113.86731712700008, 7.406316841000125],
              [113.86449868400003, 7.40169591800006],
              [113.85561930400004, 7.39657435500005],
              [113.84510938399998, 7.393177017000062],
              [113.83385512000005, 7.393157417000039],
              [113.82269044500008, 7.396324200000047],
              [113.81616369000005, 7.397178385000112],
              [113.80648086400004, 7.399295621000055],
              [113.80281660900008, 7.400405161000021],
              [113.80232149000005, 7.400719113000063],
              [113.80486897000007, 7.404712515000076],
              [113.81080463900003, 7.406729030000035],
              [113.81956081600003, 7.407491333000047],
              [113.82373335300012, 7.406535127000067],
              [113.82952030200012, 7.40922677900002],
              [113.83661235900001, 7.410538660000031],
              [113.84484758599999, 7.413664815000052],
              [113.85744100300005, 7.413478241000069],
              [113.86497045600007, 7.412426573000095],
              [113.86706220600004, 7.409177906000062],
            ],
          ],
          [
            [
              [111.54142291500008, 7.51187040600003],
              [111.53712216800004, 7.511230075000073],
              [111.53173022900003, 7.513363032000074],
              [111.52957101599999, 7.515639554000051],
              [111.52941785100002, 7.520636912000088],
              [111.53199107000003, 7.531772206000118],
              [111.53474196800001, 7.539336874000034],
              [111.53726289700005, 7.54738127700003],
              [111.53883487700008, 7.555679834],
              [111.54020940500004, 7.565029373000074],
              [111.54405682600004, 7.572235709],
              [111.54773270800008, 7.575349147000042],
              [111.55265507500005, 7.576110713000082],
              [111.55908899000011, 7.576169836000089],
              [111.56396486400003, 7.574226061000021],
              [111.56697615100005, 7.572145062000056],
              [111.57028796600007, 7.5678603790001],
              [111.5716622800001, 7.56411629400011],
              [111.57112058500005, 7.561788352000052],
              [111.56946243000007, 7.55924985000007],
              [111.56702634100003, 7.555402150000122],
              [111.56404180900009, 7.552157120000034],
              [111.56030758800003, 7.54858391000006],
              [111.55653202000011, 7.545149756000045],
              [111.55324215900004, 7.541264196000073],
              [111.54863068300004, 7.53390254200008],
              [111.54817345500004, 7.530307470000073],
              [111.5485090290001, 7.526802528000063],
              [111.54799770800007, 7.522811224000046],
              [111.54664769700001, 7.51809965100003],
              [111.54479100500004, 7.513715772000039],
              [111.54142291500008, 7.51187040600003],
            ],
          ],
          [
            [
              [111.61777609200004, 7.574463119000095],
              [111.6112362150001, 7.568326761000053],
              [111.60798259100001, 7.566187298000047],
              [111.60487309100003, 7.567460882000086],
              [111.60050544500005, 7.569396788000036],
              [111.59597143800008, 7.571504602000088],
              [111.59668948000007, 7.573859804000092],
              [111.60158846400005, 7.573267681000038],
              [111.60378262800005, 7.572891446000083],
              [111.60699512200001, 7.572666642000046],
              [111.60836961000005, 7.573826611000099],
              [111.611798526, 7.576303847000075],
              [111.61524819900005, 7.579964515000091],
              [111.61968979500003, 7.582254939000064],
              [111.62241243400005, 7.583053138000116],
              [111.62279528500002, 7.585583283000029],
              [111.622942021, 7.589132292000043],
              [111.62546888900008, 7.588411772000113],
              [111.62609156399999, 7.585298304000075],
              [111.62551583500006, 7.581423073000069],
              [111.61777609200004, 7.574463119000095],
            ],
          ],
          [
            [
              [114.04811794600002, 7.603503784000052],
              [114.04497098900005, 7.60211125800012],
              [114.04299455400003, 7.60289593900003],
              [114.04020796800003, 7.60459581600008],
              [114.03789133400002, 7.60548141600008],
              [114.03995152900005, 7.606868901000039],
              [114.04262039099999, 7.606968811000046],
              [114.04447337100009, 7.608196774000041],
              [114.04674775, 7.607456094000041],
              [114.04830408300005, 7.605252233000035],
              [114.04811794600002, 7.603503784000052],
            ],
          ],
          [
            [
              [114.02461180400005, 7.614906151000065],
              [114.02271703100003, 7.613611890000092],
              [114.02173921400004, 7.6148206980001],
              [114.01973059000011, 7.615984830000054],
              [114.02356329600006, 7.616586226000075],
              [114.02560715000007, 7.617200809000034],
              [114.02674388199998, 7.615651838000035],
              [114.02461180400005, 7.614906151000065],
            ],
          ],
          [
            [
              [114.11576290100004, 7.614412874000019],
              [114.115335211, 7.614227029000096],
              [114.11439739200003, 7.616782087000027],
              [114.1158268400001, 7.619436068000061],
              [114.11843834099999, 7.619226355000118],
              [114.11921228800007, 7.616841845000087],
              [114.1169539980001, 7.614715227000078],
              [114.11576290100004, 7.614412874000019],
            ],
          ],
          [
            [
              [114.07735192400007, 7.607136619000075],
              [114.06760385499997, 7.602867936000022],
              [114.06218647200001, 7.60235134600009],
              [114.05881122200006, 7.604119635000116],
              [114.0592785, 7.606820737000051],
              [114.061992175, 7.607247586000108],
              [114.06502348199999, 7.60699057100007],
              [114.07079939600006, 7.610514316000126],
              [114.07916377500005, 7.61364287800008],
              [114.08694149600001, 7.614613800000123],
              [114.09293473600012, 7.617473937000073],
              [114.09635809900013, 7.619060098000128],
              [114.10080349700006, 7.61985601900008],
              [114.09782665900005, 7.614463595000041],
              [114.095221967, 7.61201026900009],
              [114.08908990600005, 7.610166159000096],
              [114.07735192400007, 7.607136619000075],
            ],
          ],
          [
            [
              [114.1291077740001, 7.614526119000063],
              [114.12604564600005, 7.613772864000088],
              [114.12488944900005, 7.614650392000042],
              [114.12473567100002, 7.615161005000102],
              [114.12582785900005, 7.617827968000055],
              [114.12676131000002, 7.620837011000068],
              [114.12926244400002, 7.619751142000018],
              [114.12951509200005, 7.616875859000059],
              [114.1291077740001, 7.614526119000063],
            ],
          ],
          [
            [
              [114.14502120500006, 7.599505307000063],
              [114.14247540400001, 7.599074446000119],
              [114.14014331100006, 7.600155447000121],
              [114.13498462000008, 7.60240573400006],
              [114.13575266100013, 7.605851805000084],
              [114.13676557200009, 7.608689677000056],
              [114.14024010000007, 7.611960995000098],
              [114.1446521, 7.612674166000131],
              [114.15045904800004, 7.614864261000063],
              [114.15400824499999, 7.617795800000058],
              [114.15793927900008, 7.619366427000072],
              [114.15965672600007, 7.620327754000032],
              [114.161960667, 7.623970886000018],
              [114.16418439499996, 7.624917190000067],
              [114.16565788000003, 7.623355211000058],
              [114.1658648560001, 7.618962924000082],
              [114.1622461610001, 7.616539713000046],
              [114.15788744000005, 7.611945335000046],
              [114.15488375100003, 7.607479519000025],
              [114.15153773700003, 7.60285514300003],
              [114.14928927800007, 7.601066048000059],
              [114.14502120500006, 7.599505307000063],
            ],
          ],
          [
            [
              [114.00994397800002, 7.622109652000029],
              [114.00905465800008, 7.621497192000069],
              [114.00854961600012, 7.621532815000085],
              [114.00717906500006, 7.621291904000048],
              [114.0072011530001, 7.623991151],
              [114.00721134500006, 7.626522473000106],
              [114.0091719870001, 7.625540149000097],
              [114.00994397800002, 7.622109652000029],
            ],
          ],
          [
            [
              [114.17839313900001, 7.626529778000089],
              [114.17622922200012, 7.626459084000102],
              [114.17464147400001, 7.627585908000077],
              [114.17331249600011, 7.630594521000054],
              [114.17421496200008, 7.633671867000123],
              [114.17669721800011, 7.635352691000017],
              [114.1790082990001, 7.635823908000104],
              [114.18110482000012, 7.635896580000077],
              [114.18316201700006, 7.636915144000065],
              [114.18507618800008, 7.637668023000098],
              [114.18667604300002, 7.636945612000099],
              [114.18674656800009, 7.634784092000045],
              [114.18638390900001, 7.631690724000071],
              [114.18455241700008, 7.629180912000065],
              [114.181931143, 7.627369373000102],
              [114.17839313900001, 7.626529778000089],
            ],
          ],
          [
            [
              [114.196902517, 7.644543723000117],
              [114.19468347200007, 7.643766284],
              [114.1930060010001, 7.644153647000083],
              [114.19204892100004, 7.646037945000026],
              [114.19278950200012, 7.648209123000081],
              [114.19464584199997, 7.648153843000037],
              [114.19645679000007, 7.64658152300006],
              [114.1971065890001, 7.645718602000072],
              [114.196902517, 7.644543723000117],
            ],
          ],
          [
            [
              [111.70339441500002, 7.650192725000069],
              [111.70265630700005, 7.647584432000086],
              [111.7001062210001, 7.644180589000092],
              [111.69478180000009, 7.64032687300009],
              [111.68539961500007, 7.634794800000043],
              [111.67905083500004, 7.63000348600009],
              [111.67323951800006, 7.6257645420001],
              [111.66497665600011, 7.620520781000016],
              [111.65786354900008, 7.617059038000116],
              [111.6530010700001, 7.616207639000015],
              [111.6480493170001, 7.617231388000077],
              [111.64616267900006, 7.620023652],
              [111.64638834500008, 7.624986271000099],
              [111.65009978700003, 7.631368604000064],
              [111.65538740800011, 7.637320117000089],
              [111.65980088200006, 7.640616597000081],
              [111.66621477100006, 7.64169515800006],
              [111.67365234500002, 7.641056651000076],
              [111.67723039600006, 7.642346207000104],
              [111.68258509900008, 7.648339023000082],
              [111.68722396500003, 7.65214616900005],
              [111.690433313, 7.65235466500007],
              [111.69359095100006, 7.651056874000071],
              [111.69594222400009, 7.648791490000038],
              [111.69780090800005, 7.648589368000012],
              [111.70024917400011, 7.65218029200012],
              [111.70136504000007, 7.652712322000086],
              [111.70260191200001, 7.652276399000078],
              [111.70339441500002, 7.650192725000069],
            ],
          ],
          [
            [
              [113.84738717399998, 7.651510789000058],
              [113.84351839400006, 7.646527907000065],
              [113.838539048, 7.642747110000057],
              [113.82768902700003, 7.639355353000086],
              [113.82344508900007, 7.637849917000048],
              [113.81353008700003, 7.63578728],
              [113.80080776, 7.634448002000037],
              [113.78976066200002, 7.6323351430001],
              [113.780272071, 7.630324937000042],
              [113.77641807500007, 7.635425215000067],
              [113.77553077300001, 7.641463038000039],
              [113.790023268, 7.642257726000066],
              [113.79363033700011, 7.64540191500007],
              [113.79681732400002, 7.646166485],
              [113.80691650800011, 7.649129453000024],
              [113.81239471700005, 7.651409966000065],
              [113.81810557900006, 7.652331873000049],
              [113.82789683200008, 7.653950534000041],
              [113.83497558200011, 7.655374442000083],
              [113.84128102700004, 7.65817182300008],
              [113.84589491700004, 7.658583004000084],
              [113.84843324500001, 7.657224855000065],
              [113.84738717399998, 7.651510789000058],
            ],
            [
              [113.78297443100004, 7.638368622000067],
              [113.78181865700006, 7.633331135000086],
              [113.78760405100002, 7.634522119000041],
              [113.78827013100008, 7.63463601300005],
              [113.79427412299999, 7.635662529000074],
              [113.80290363499999, 7.637137628000088],
              [113.81004167400013, 7.638290277000069],
              [113.81377923700003, 7.638324078000023],
              [113.81535165400011, 7.638619118000099],
              [113.82617048200007, 7.639264777000053],
              [113.82835627300008, 7.639805772000051],
              [113.83384207400003, 7.642355832000101],
              [113.83871845800002, 7.647288785000049],
              [113.839818552, 7.650299796000047],
              [113.83781838600005, 7.651369916000038],
              [113.83443714600007, 7.651464784000071],
              [113.83068924400003, 7.650555903000099],
              [113.82183156800006, 7.648438089000107],
              [113.81505019600003, 7.647951811000018],
              [113.80723556400004, 7.646818185000102],
              [113.79702581800004, 7.64473708600004],
              [113.78881663099999, 7.641585899000079],
              [113.78675021800008, 7.640291475000092],
              [113.78605512200012, 7.639634867000032],
              [113.78297443100004, 7.638368622000067],
            ],
          ],
          [
            [
              [114.20343360700011, 7.655004784000051],
              [114.20087690500013, 7.654237448000066],
              [114.19980518100009, 7.655112964000031],
              [114.19862020300002, 7.657847705000085],
              [114.19803092500001, 7.660733419000019],
              [114.19946183200001, 7.663390237000021],
              [114.20233599800005, 7.663473274000053],
              [114.20479683600001, 7.661037852000073],
              [114.20592768200005, 7.65931695800006],
              [114.205335491, 7.656466476000022],
              [114.20343360700011, 7.655004784000051],
            ],
          ],
          [
            [
              [111.54888827800004, 7.64220659600001],
              [111.54409759400005, 7.639415342000076],
              [111.54174130600003, 7.640300453000036],
              [111.54095817299999, 7.644031705000119],
              [111.54134309200005, 7.646729114000015],
              [111.54375728500004, 7.649222983000035],
              [111.54347930800004, 7.652776715000085],
              [111.53950221700005, 7.661564196000102],
              [111.54115001000005, 7.665153635000086],
              [111.54505683300002, 7.665763109000066],
              [111.55007968400002, 7.662297358000012],
              [111.553709821, 7.656658249000079],
              [111.55716461300004, 7.650684156000052],
              [111.55778974900007, 7.647631468000069],
              [111.55553315100005, 7.644459092000131],
              [111.54888827800004, 7.64220659600001],
            ],
          ],
          [
            [
              [111.72455240900004, 7.664537736000019],
              [111.72635035600004, 7.660954304000088],
              [111.72280206900008, 7.661356044000021],
              [111.71981012600006, 7.664453478000064],
              [111.71831716600008, 7.666171237000084],
              [111.71903368100004, 7.668356835000088],
              [111.72123711500009, 7.668486535000072],
              [111.72325913700007, 7.667943038000134],
              [111.72455240900004, 7.664537736000019],
            ],
          ],
          [
            [
              [114.206983325, 7.671847854000101],
              [114.20522399700009, 7.671765358000114],
              [114.20475546, 7.671914314000093],
              [114.20198462800012, 7.674156572000078],
              [114.20212890200007, 7.676716852000086],
              [114.20406776500008, 7.678278766000089],
              [114.2062524370001, 7.679023473000067],
              [114.20811450200007, 7.67802305200007],
              [114.20926906900003, 7.675963921000049],
              [114.20922862400006, 7.674615355000112],
              [114.2086440380001, 7.673148052000032],
              [114.206983325, 7.671847854000101],
            ],
          ],
          [
            [
              [111.71261280600008, 7.669067144000062],
              [111.71020677700004, 7.667080768000021],
              [111.70750163400004, 7.667298109000063],
              [111.70346658800004, 7.668891947000043],
              [111.70218201900008, 7.672804331000103],
              [111.70056187700004, 7.676891811000084],
              [111.69942834800005, 7.679786881000082],
              [111.70217598800011, 7.681936486000039],
              [111.70333671000004, 7.680562914000076],
              [111.70600571400007, 7.678316918000048],
              [111.70850237000006, 7.675904862000047],
              [111.71083878600011, 7.67400297800007],
              [111.71215047400001, 7.671612033000129],
              [111.71261280600008, 7.669067144000062],
            ],
          ],
          [
            [
              [114.224886, 7.689008944000085],
              [114.22480894700007, 7.686446583000082],
              [114.22354500700013, 7.684864634000109],
              [114.22108609200006, 7.683993311000046],
              [114.21768198900006, 7.6831502300001],
              [114.21608188500008, 7.683872961000036],
              [114.21506237100002, 7.685928127000084],
              [114.21619122199999, 7.687514175000041],
              [114.21796283900002, 7.6880011580001],
              [114.21929410600004, 7.690405187000058],
              [114.22092950700008, 7.687777481000067],
              [114.220851208, 7.689669571000122],
              [114.22142377800003, 7.690732309000035],
              [114.22372746100004, 7.690933370000062],
              [114.224886, 7.689008944000085],
            ],
          ],
          [
            [
              [114.24274846600008, 7.689597462000018],
              [114.24069246700004, 7.688646785000051],
              [114.23799181700006, 7.688727789],
              [114.23618590300012, 7.690469221000027],
              [114.23611674000009, 7.69097748100007],
              [114.23561736600007, 7.694029577],
              [114.23654260900005, 7.696701542000104],
              [114.23875221, 7.697141479],
              [114.24160144700012, 7.696381087000058],
              [114.24322839300008, 7.694307487000083],
              [114.24315213700008, 7.691778831000116],
              [114.24274846600008, 7.689597462000018],
            ],
          ],
          [
            [
              [111.74181829400008, 7.676114119000131],
              [111.73939286900004, 7.675488071000112],
              [111.73748204500004, 7.676902204000056],
              [111.73738859800002, 7.679387528000079],
              [111.73856609100002, 7.682683929000065],
              [111.74206766000002, 7.689047381000068],
              [111.74301491800006, 7.694611248000081],
              [111.74490542500007, 7.697328255000089],
              [111.74647705800008, 7.69877694100005],
              [111.75023756500011, 7.701509818000128],
              [111.7530007390001, 7.701322003000131],
              [111.75420777000005, 7.699230412000089],
              [111.75441711900007, 7.694490128],
              [111.75349416000007, 7.690283708000044],
              [111.75275318800006, 7.687665842000067],
              [111.75160450400004, 7.685340850000076],
              [111.74630387800005, 7.679276568000136],
              [111.74181829400008, 7.676114119000131],
            ],
          ],
          [
            [
              [114.26917325500004, 7.687388353000111],
              [114.26417178700002, 7.686811919000112],
              [114.26170500800002, 7.690942335],
              [114.26193643500008, 7.691655836000065],
              [114.26259488400002, 7.695550569000085],
              [114.26456701800012, 7.698190896000055],
              [114.268016483, 7.700516744000117],
              [114.27356324900009, 7.702914309000073],
              [114.27688193200007, 7.705378980000075],
              [114.27951321400008, 7.70745939000008],
              [114.28196036699997, 7.707925462],
              [114.28392872100002, 7.705976169000016],
              [114.28320437300013, 7.704378174000047],
              [114.280274393, 7.70136193400006],
              [114.27663657400001, 7.697287128000061],
              [114.27435742100008, 7.693441310000091],
              [114.27345321400006, 7.690363939000109],
              [114.27164058299999, 7.688528826000061],
              [114.26917325500004, 7.687388353000111],
            ],
          ],
          [
            [
              [114.31545606900012, 7.719293890000055],
              [114.31571653900009, 7.716754840000116],
              [114.31510723500003, 7.713398527000058],
              [114.31251854500007, 7.711620960000063],
              [114.30931120700009, 7.711718260000032],
              [114.30664623300004, 7.712980242000088],
              [114.30313204900006, 7.714099188000121],
              [114.29937204699999, 7.712694426000065],
              [114.29613391200003, 7.711780018000079],
              [114.29311586299998, 7.712546291000065],
              [114.28852338400006, 7.712949753000052],
              [114.28822818900005, 7.71573141500008],
              [114.28843797, 7.717074999000049],
              [114.29033077000011, 7.718198964000054],
              [114.29313156500004, 7.718620481000114],
              [114.29699377300003, 7.717828634000055],
              [114.30041621400005, 7.719243699000076],
              [114.30605898000012, 7.721435125000118],
              [114.30947640300008, 7.722681453000034],
              [114.31181927100013, 7.721935411000034],
              [114.31545606900012, 7.719293890000055],
            ],
          ],
          [
            [
              [111.75283272200006, 7.731451183000091],
              [111.75135806900008, 7.730804301000125],
              [111.74978313200006, 7.730832686000076],
              [111.74787883000008, 7.731315992000097],
              [111.74665977100001, 7.732190448000063],
              [111.74534218400007, 7.734167865000073],
              [111.7452158990001, 7.736612710000122],
              [111.74540817300002, 7.737814750000014],
              [111.74599706600007, 7.73926339200006],
              [111.74732666200003, 7.740971031000042],
              [111.74792438300003, 7.741251007000114],
              [111.74896576200003, 7.741623299000098],
              [111.75081424400003, 7.741874522000012],
              [111.75211783400006, 7.741678733000066],
              [111.75317181400005, 7.741156426000105],
              [111.75405812100011, 7.740072230000104],
              [111.75453994400002, 7.738716551000023],
              [111.75484997200007, 7.737195578000032],
              [111.7548234010001, 7.735363873],
              [111.75410958400002, 7.733350052000086],
              [111.75283272200006, 7.731451183000091],
            ],
          ],
          [
            [
              [114.36728346200003, 7.710439828000036],
              [114.36641329300006, 7.704121876000068],
              [114.364453418, 7.69972718400007],
              [114.36353954500007, 7.696380447000093],
              [114.362191594, 7.692102092000129],
              [114.36171188800009, 7.689687023000056],
              [114.35938736400014, 7.688813203000054],
              [114.35663581200004, 7.689437244000084],
              [114.35326159100009, 7.693994857000062],
              [114.35205195900001, 7.698621373000057],
              [114.35185636900007, 7.701057143000066],
              [114.34806019000004, 7.705087704000045],
              [114.34286656000002, 7.709835820000064],
              [114.33789179200008, 7.71511714900006],
              [114.33648057400002, 7.717590034000031],
              [114.33629863700007, 7.718270540000026],
              [114.33605183400007, 7.721247944000085],
              [114.33730034100009, 7.722289879000053],
              [114.34084889599997, 7.723396716000062],
              [114.34591888700007, 7.721217182000116],
              [114.34707646000001, 7.71929193400007],
              [114.34603599400008, 7.716218814000085],
              [114.34531941700003, 7.714890737000067],
              [114.34526980200009, 7.713272337000097],
              [114.34667261600008, 7.710529688000099],
              [114.35011757800009, 7.708264688],
              [114.35165532600006, 7.705517935000026],
              [114.35433960000002, 7.704895988000081],
              [114.35547375200007, 7.706616222000127],
              [114.35614644000013, 7.710915401000039],
              [114.35638502700006, 7.714282921000047],
              [114.35902548900005, 7.716632125000087],
              [114.36397429800006, 7.719315676000081],
              [114.36682994300008, 7.722063214000039],
              [114.36929183000004, 7.727387732000056],
              [114.36906096900012, 7.733064749000016],
              [114.367998962, 7.738092281000062],
              [114.36721648600009, 7.741221271000052],
              [114.36970990699999, 7.743169984000085],
              [114.37255873700006, 7.743487763000088],
              [114.37610163600002, 7.740004137000065],
              [114.37718423200006, 7.735650909000034],
              [114.37711568599998, 7.729038024000088],
              [114.37818571800005, 7.724280286000072],
              [114.3781067650001, 7.721717779000057],
              [114.37714998200008, 7.719182189000049],
              [114.37354731600006, 7.716322714000066],
              [114.37022730600007, 7.713859511000097],
              [114.36728346200003, 7.710439828000036],
            ],
          ],
          [
            [
              [114.15468651600006, 7.753988326000092],
              [114.16099593300012, 7.747563154000135],
              [114.16359974700001, 7.744282554000035],
              [114.1641591340001, 7.74038812200004],
              [114.16712024100005, 7.737771350000065],
              [114.17138207200007, 7.737644913000084],
              [114.17167528600008, 7.736691538000072],
              [114.1739414220001, 7.733420902000086],
              [114.17453072300009, 7.730537232000055],
              [114.17714416099997, 7.727593494000116],
              [114.17881083200011, 7.726869625000086],
              [114.18025851299998, 7.724466272000095],
              [114.17981051900004, 7.720770486000015],
              [114.18068631700012, 7.719390323000129],
              [114.17666996600008, 7.720189385000074],
              [114.17379752200009, 7.720106051000107],
              [114.1709099980001, 7.71951735600007],
              [114.16892609600008, 7.720924943000094],
              [114.16532065900002, 7.724572310000076],
              [114.16247322100006, 7.72533105200006],
              [114.15794399900003, 7.726308148000081],
              [114.15551903200002, 7.729920443000092],
              [114.15641419800011, 7.737312308000098],
              [114.15639081100002, 7.742202444000055],
              [114.15272045400002, 7.746526111000039],
              [114.14774968500004, 7.749707934000048],
              [114.14419704400008, 7.749475669000015],
              [114.13923988400009, 7.747430147000054],
              [114.13464516800006, 7.74621684900003],
              [114.12969223800006, 7.73860715200003],
              [114.1264713620001, 7.732463862000126],
              [114.12280942000007, 7.728525337000034],
              [114.11755016300003, 7.724802369000104],
              [114.10986023400005, 7.721656486000049],
              [114.10425918600008, 7.720640762000118],
              [114.09829602900007, 7.718792540000051],
              [114.091856706, 7.717969649000088],
              [114.08612184500005, 7.71813750400012],
              [114.07918175900012, 7.714631435000017],
              [114.07669181299998, 7.710320898000054],
              [114.07554633400007, 7.705802514000125],
              [114.07202484400003, 7.700847786000063],
              [114.06817115300011, 7.696071348000098],
              [114.06267599200009, 7.689994112000063],
              [114.05821714100003, 7.687595343000096],
              [114.05324261400001, 7.684874346000113],
              [114.04977615100009, 7.681772242000038],
              [114.04510472100002, 7.677862268000089],
              [114.04048830300005, 7.672939211000072],
              [114.03569771, 7.670718275000075],
              [114.03156213900002, 7.667803985000086],
              [114.02614627300008, 7.667286671000094],
              [114.02079129900004, 7.663058985000058],
              [114.01776303500003, 7.657583949000111],
              [114.01533489100004, 7.65242872900007],
              [114.01371946100004, 7.649104204000066],
              [114.00806219400012, 7.64606506100005],
              [113.99965847600005, 7.641419432000072],
              [113.99606396500009, 7.63966895500001],
              [113.98677889600003, 7.63766185600012],
              [113.98206020200004, 7.638304015000051],
              [113.97648628800009, 7.641361218000052],
              [113.97056868700011, 7.639340805000037],
              [113.96729929100006, 7.636852034000084],
              [113.96400372300005, 7.63345249000009],
              [113.95389263900003, 7.631311529000049],
              [113.94622904100001, 7.629555876],
              [113.94212133800013, 7.629673275000036],
              [113.94085226700011, 7.633203545000098],
              [113.94198728299997, 7.640918854000043],
              [113.94072685100004, 7.644752815000098],
              [113.93680600299999, 7.651397326000085],
              [113.93674091400011, 7.65443763200002],
              [113.93801912000001, 7.656528065000055],
              [113.94109710200003, 7.657655558000085],
              [113.95219631000009, 7.65094068000005],
              [113.96277704700007, 7.648206893000074],
              [113.96942579300006, 7.64528168400007],
              [113.974496109, 7.648798958000034],
              [113.97656486300002, 7.646612639000059],
              [113.97785420700004, 7.645802697000102],
              [113.98406607700011, 7.645883429000032],
              [113.98578449300008, 7.645623141000078],
              [113.99270591900007, 7.645086234000063],
              [113.99646905100003, 7.646496988000083],
              [113.99945245700006, 7.650016418000048],
              [114.00333223800008, 7.652708577000043],
              [114.00411778800007, 7.659428446000048],
              [114.0046162440001, 7.664976740000016],
              [114.00622187899999, 7.667964600000053],
              [114.00981696700001, 7.669715023000022],
              [114.01340233100009, 7.671128524],
              [114.01665647200005, 7.6698544480001],
              [114.01984583300006, 7.66925646],
              [114.02388816000003, 7.668970865000089],
              [114.02970021000006, 7.671499596000063],
              [114.03489185100001, 7.675900466000128],
              [114.04103996700005, 7.684150706000102],
              [114.04443112900006, 7.690458135000087],
              [114.04643949099996, 7.69275988000002],
              [114.05222785400008, 7.694445944000082],
              [114.05981627000008, 7.699956801000061],
              [114.06471636299999, 7.705883039000037],
              [114.069711309, 7.709277665000068],
              [114.07407098100012, 7.716905483000057],
              [114.07633788700001, 7.719368153000071],
              [114.08381398900006, 7.721004161000076],
              [114.08878978600001, 7.723724667000019],
              [114.09518460600012, 7.728763838000051],
              [114.10626854000006, 7.741083668000035],
              [114.11058029600004, 7.744160509000063],
              [114.11432139800004, 7.745062215000063],
              [114.11760624300005, 7.747663294000072],
              [114.12427548499996, 7.753368264000036],
              [114.1307908870001, 7.756717121000099],
              [114.13686918600004, 7.75670652500011],
              [114.14714003200005, 7.755728886000044],
              [114.15468651600006, 7.753988326000092],
            ],
          ],
          [
            [
              [114.19301312700006, 7.740233655000049],
              [114.1906315560001, 7.740088727000048],
              [114.188920306, 7.740679162000051],
              [114.185955401, 7.7427096490001],
              [114.18476616000009, 7.746305917000116],
              [114.18332231300009, 7.748614904000116],
              [114.18425367200005, 7.750853284000046],
              [114.18645811600003, 7.752298456000071],
              [114.18918306300006, 7.753080696000046],
              [114.19179685700006, 7.753758274000065],
              [114.19443001800006, 7.755082716000048],
              [114.19633883100008, 7.755673346000068],
              [114.19681265800003, 7.757062050000039],
              [114.19804229200007, 7.758428263000015],
              [114.19967152700005, 7.758703447000046],
              [114.20154344000002, 7.756983077000077],
              [114.20325527200009, 7.755683054000071],
              [114.20460066600003, 7.753700556000085],
              [114.2050984910001, 7.752282896000027],
              [114.20571720200005, 7.751293250000034],
              [114.20427485500008, 7.750041374000103],
              [114.20090854, 7.749494277000045],
              [114.19809965500011, 7.745909121000059],
              [114.19747590400002, 7.743122124000122],
              [114.19596355800002, 7.741332766000129],
              [114.19301312700006, 7.740233655000049],
            ],
          ],
          [
            [
              [114.2155366840001, 7.768203408000128],
              [114.21375380000011, 7.76731353100004],
              [114.21120557800012, 7.767928546000034],
              [114.20724534700001, 7.771010917000075],
              [114.20353476700004, 7.773412104000064],
              [114.203349557, 7.776247103000065],
              [114.20573290600009, 7.779140272000083],
              [114.20873872500012, 7.780263245000095],
              [114.21131539200006, 7.780590552000087],
              [114.21468979300002, 7.78048979000001],
              [114.21669017500005, 7.77962161100003],
              [114.21837610600002, 7.777280676000052],
              [114.21881263400007, 7.776535297000107],
              [114.21837760900006, 7.772834285000039],
              [114.21748285600009, 7.770031561000044],
              [114.2155366840001, 7.768203408000128],
            ],
          ],
          [
            [
              [114.36935578300009, 7.760308452000063],
              [114.36700838600009, 7.758761151000025],
              [114.36367099600001, 7.760212556000033],
              [114.36170995900011, 7.762431412000035],
              [114.36157224599999, 7.766753415000059],
              [114.36151995500002, 7.769453657000012],
              [114.36066911300006, 7.772583137000042],
              [114.36024031400011, 7.776239465000055],
              [114.3612900640001, 7.779580762000114],
              [114.36310113300011, 7.780814547000057],
              [114.36480857400008, 7.77914309400005],
              [114.36634762800006, 7.776862294000074],
              [114.3664040040001, 7.774296812000079],
              [114.36599269400008, 7.76972165100007],
              [114.367122051, 7.766853515000081],
              [114.36920576900002, 7.76422603800002],
              [114.36982762700003, 7.762452899000037],
              [114.36935578300009, 7.760308452000063],
            ],
          ],
          [
            [
              [111.74588028500008, 7.758857490000091],
              [111.74406463600005, 7.757924116000046],
              [111.74157485000011, 7.757968903000021],
              [111.73938164100009, 7.759112384000042],
              [111.73761333300007, 7.760800249000075],
              [111.73476081300004, 7.763749647000038],
              [111.73342235400004, 7.76625784],
              [111.73238289800001, 7.77000277000006],
              [111.73234808300006, 7.774148434000059],
              [111.73244560100005, 7.779556469000058],
              [111.73275509500007, 7.782969346000089],
              [111.73407233100009, 7.786948188000052],
              [111.73678549300004, 7.791592078000063],
              [111.73861138000004, 7.793077497000043],
              [111.74048174700008, 7.793275081000049],
              [111.74206252500004, 7.792601410000087],
              [111.74368023100004, 7.790225999000098],
              [111.74542807600007, 7.784947999000023],
              [111.74643560200012, 7.781895414000049],
              [111.74700134600003, 7.778326419000068],
              [111.74791329000007, 7.771793806000103],
              [111.74807501800005, 7.765442393000031],
              [111.74760259700004, 7.762276687000023],
              [111.74742931100009, 7.760347684000111],
              [111.74588028500008, 7.758857490000091],
            ],
          ],
          [
            [
              [114.36912047899997, 7.789397649000039],
              [114.36722390200008, 7.789320596000058],
              [114.36663521700004, 7.79217563300003],
              [114.36551826900009, 7.795452146000057],
              [114.36449082200008, 7.797239846000091],
              [114.36401650900009, 7.799415933000107],
              [114.36581121499999, 7.800576936000068],
              [114.36742092800004, 7.800122375000028],
              [114.37031597200004, 7.797466883000046],
              [114.37161788500011, 7.795805844000102],
              [114.37169488399999, 7.793912113000088],
              [114.37079657700011, 7.791102579000063],
              [114.36912047899997, 7.789397649000039],
            ],
          ],
          [
            [
              [114.39573144200007, 7.813160956000063],
              [114.39688555400008, 7.811098921000069],
              [114.39656639100005, 7.809546285000033],
              [114.39459037399999, 7.806904969000071],
              [114.39316000500003, 7.805920811000087],
              [114.38986053400005, 7.80535823900008],
              [114.38376287000001, 7.803542492000036],
              [114.38026569200011, 7.804111569000048],
              [114.37735719600008, 7.807510171000052],
              [114.3766500630001, 7.810909409000109],
              [114.37767596199998, 7.812791599000056],
              [114.38414584499999, 7.812658351000044],
              [114.3864614070001, 7.812917815000018],
              [114.39230369800005, 7.813684152000098],
              [114.39573144200007, 7.813160956000063],
            ],
          ],
          [
            [
              [111.68946077200009, 7.82777311900009],
              [111.68623908000006, 7.827492156000025],
              [111.68509949300002, 7.83004775900009],
              [111.68515063200007, 7.83292034],
              [111.68671602800001, 7.835259021000061],
              [111.68894470600003, 7.837210729000075],
              [111.69096541700006, 7.836197891000028],
              [111.69208688200004, 7.832628369000092],
              [111.69170015300001, 7.829930778000092],
              [111.68946077200009, 7.82777311900009],
            ],
          ],
          [
            [
              [109.13000402000003, 7.814111316],
              [109.12977759800003, 7.81411116200008],
              [109.12956062500011, 7.814113669000021],
              [109.12934370200001, 7.814118781000088],
              [109.12912685600001, 7.814126499000016],
              [109.12890069300005, 7.814137331000024],
              [109.12868410200007, 7.814150366000126],
              [109.12845827500003, 7.81416674900006],
              [109.12824207300008, 7.814185092000068],
              [109.128016718, 7.814207006000105],
              [109.127801031, 7.814230647000018],
              [109.12757628300005, 7.814258084000068],
              [109.12736124600012, 7.814287014000064],
              [109.12713724800005, 7.814319954000036],
              [109.126922988, 7.814354153000112],
              [109.12669986700011, 7.814392578000094],
              [109.12648651800004, 7.814432019000071],
              [109.12626440900004, 7.814475911000061],
              [109.12605209700007, 7.814520575000115],
              [109.12584034000005, 7.814567798000025],
              [109.12562916400007, 7.814617550000056],
              [109.1254094650001, 7.814672165000046],
              [109.12519957800005, 7.81472708600006],
              [109.12498129500003, 7.81478707500005],
              [109.12477283200009, 7.814847128000045],
              [109.12455609100002, 7.814912454000121],
              [109.12434917000003, 7.814977602000093],
              [109.124134107, 7.815048229000075],
              [109.12392885300008, 7.815118435000022],
              [109.1237156010001, 7.815194308000082],
              [109.12351214900005, 7.815269536000089],
              [109.12330082700007, 7.815350619000048],
              [109.12309928400001, 7.815430814000111],
              [109.1228900350001, 7.815517061000053],
              [109.12269052800011, 7.815602178000079],
              [109.12249207000012, 7.815689669000035],
              [109.12229468800004, 7.8157795540001],
              [109.12208988900007, 7.815875858000075],
              [109.12189477599999, 7.815970546000093],
              [109.12169241900004, 7.816071842000037],
              [109.12149970100008, 7.816171297],
              [109.12129990200006, 7.816277530000091],
              [109.12110969600005, 7.816381680000093],
              [109.120912581, 7.816492785000049],
              [109.12072500400011, 7.816601566000079],
              [109.12053068000002, 7.816717473000077],
              [109.12034584800008, 7.816830830000042],
              [109.12015444200006, 7.816951474000076],
              [109.1199724630001, 7.817069326000057],
              [109.11978409100006, 7.817194626000033],
              [109.11960507300009, 7.817316910000026],
              [109.11942753500004, 7.817441333000106],
              [109.11925152200004, 7.817567878000096],
              [109.11906948700009, 7.817702173000017],
              [109.11889665000008, 7.817833004000095],
              [109.11871799100004, 7.817971720000026],
              [109.11854842000005, 7.818106748],
              [109.11837323400003, 7.81824981200012],
              [109.11820705400005, 7.818388963000087],
              [109.11803545900005, 7.818536276000108],
              [109.11787275200001, 7.818679461000054],
              [109.11770483600006, 7.818830941000064],
              [109.11754572700005, 7.81897807800007],
              [109.11738159000005, 7.819133626000062],
              [109.11722616000007, 7.819284625000121],
              [109.11706590900005, 7.819444159000087],
              [109.11691424700011, 7.819598921000102],
              [109.11676445900005, 7.819755487000089],
              [109.11661657300007, 7.819913850000136],
              [109.11646430900001, 7.820080970000019],
              [109.11632037, 7.820242914000096],
              [109.116172271, 7.820413722000106],
              [109.11603237800006, 7.820579140000026],
              [109.11588854100006, 7.820753538000068],
              [109.1157527660001, 7.82092233900009],
              [109.1156132720001, 7.821100209000063],
              [109.11548170400006, 7.821272295000091],
              [109.11534664300008, 7.821453528000076],
              [109.11521936200003, 7.821628789000011],
              [109.11508880500006, 7.821813287000088],
              [109.11496589200001, 7.821991616000011],
              [109.11483993000007, 7.822179261000082],
              [109.11472144700004, 7.822360550000072],
              [109.11460516100001, 7.82254325500009],
              [109.11449108100007, 7.822727341000045],
              [109.11437443000001, 7.822920876000071],
              [109.1142649330001, 7.823107706000075],
              [109.11415309000009, 7.823304045000066],
              [109.11404823800002, 7.823493500000021],
              [109.11394127400006, 7.823692528000057],
              [109.11384113000003, 7.823884501000043],
              [109.11373909900003, 7.824086089000025],
              [109.11364372500005, 7.82428046300012],
              [109.11354669900001, 7.824484496000126],
              [109.11345614800007, 7.824681145000055],
              [109.11336418900001, 7.824887496000075],
              [109.11327851500002, 7.825086320000095],
              [109.11319167600003, 7.82529486300003],
              [109.11311092300011, 7.82549571900004],
              [109.11303260800001, 7.82569753100004],
              [109.11295674000004, 7.825900275000029],
              [109.11288017600002, 7.826112789000115],
              [109.11280932700001, 7.826317330000075],
              [109.11273804300006, 7.826531658000045],
              [109.11267225800005, 7.826737870000054],
              [109.11260627200009, 7.826953887000075],
              [109.11254560400005, 7.827161653000035],
              [109.11248497, 7.827379214000095],
              [109.11242944500003, 7.827588417000085],
              [109.11237419700007, 7.827807396000084],
              [109.11232384200008, 7.828017893],
              [109.11227401700005, 7.828238173000022],
              [109.11222887600005, 7.828449837000038],
              [109.11218449900009, 7.828671274000064],
              [109.11214458900008, 7.828883980000077],
              [109.11210725900001, 7.829097158000041],
              [109.11207249900006, 7.829310762000128],
              [109.11203898100004, 7.829534091000044],
              [109.11200950500009, 7.829748485000019],
              [109.11198150600009, 7.829972567000086],
              [109.11195732300004, 7.830187616000027],
              [109.11193486800005, 7.830412315000055],
              [109.11191600400008, 7.830627902000041],
              [109.11189909300002, 7.830853084000109],
              [109.11188554700006, 7.831069065000039],
              [109.11187419800009, 7.831294586000047],
              [109.11186599700004, 7.831510835000087],
              [109.11186021700009, 7.831736579000024],
              [109.11185734300003, 7.831952961000075],
              [109.1118571320001, 7.832178774000019],
              [109.11185976800004, 7.832404571000049],
              [109.11186496700003, 7.832620911],
              [109.11187276900006, 7.83283717400005],
              [109.11188370300007, 7.83306273000006],
              [109.1118968480001, 7.83327873100008],
              [109.11191334000004, 7.833503943000062],
              [109.11193180800007, 7.833719566000051],
              [109.11195384800001, 7.833944308000083],
              [109.11197762900012, 7.834159408000028],
              [109.112005217, 7.834383537000099],
              [109.1120342940001, 7.83459799800008],
              [109.11206740100009, 7.834821378000088],
              [109.11210176300008, 7.835035057000093],
              [109.11214037200008, 7.835257563000118],
              [109.11218, 7.835470333000048],
              [109.11222408200008, 7.835691822000078],
              [109.11226894900007, 7.835903558000011],
              [109.11231636399999, 7.836114732000075],
              [109.11236632600004, 7.836325326000091],
              [109.11242117200005, 7.8365444130001],
              [109.11247631800009, 7.836753722000099],
              [109.1125365450001, 7.836971396000079],
              [109.11259683700011, 7.837179285000049],
              [109.11266242700003, 7.83739542100008],
              [109.11272783000003, 7.8376017640001],
              [109.11279872800003, 7.837816228000081],
              [109.11286920400008, 7.838020899000052],
              [109.11294537200008, 7.838233565000062],
              [109.11302088500003, 7.838436448000062],
              [109.113102279, 7.838647172],
              [109.113182765, 7.838848142000108],
              [109.11326933000007, 7.839056807000054],
              [109.11335475200011, 7.839255747000083],
              [109.11344257500009, 7.839453641000114],
              [109.1135327800001, 7.839650471000057],
              [109.11362943200001, 7.839854680000078],
              [109.11372446500006, 7.8400492370001],
              [109.11382612300012, 7.84025102],
              [109.11392592800003, 7.840443189000011],
              [109.11403253800006, 7.840642411000063],
              [109.11413705100009, 7.840832065000123],
              [109.11424854100008, 7.841028620000133],
              [109.11435770800006, 7.841215653000065],
              [109.11447402200008, 7.841409414000021],
              [109.11458776100008, 7.841593708000081],
              [109.11470881800005, 7.841784550000062],
              [109.11482707500008, 7.841966008000066],
              [109.11495281000001, 7.842153824000063],
              [109.11507550400003, 7.842332320000088],
              [109.11520035200004, 7.842509330000079],
              [109.11532732800008, 7.842684829000033],
              [109.11546207800004, 7.84286632400004],
              [109.11559334400006, 7.843038655000088],
              [109.11573253700003, 7.843216783000083],
              [109.11586801200005, 7.843385838000037],
              [109.11601154800012, 7.8435605020001],
              [109.11615116000003, 7.843726183000042],
              [109.116298966, 7.843897265000075],
              [109.11644262400007, 7.844059474000085],
              [109.11659461000004, 7.844226885000081],
              [109.11674222300009, 7.844385514000061],
              [109.11689828900003, 7.84454913800001],
              [109.11704978500003, 7.844704107000043],
              [109.11720984100005, 7.844863855000115],
              [109.11736510200011, 7.845015056000102],
              [109.11752218200004, 7.845164378000023],
              [109.11768105400004, 7.845311803000053],
              [109.11784871900007, 7.845463593000089],
              [109.1180111840001, 7.845607081000059],
              [109.11818254800006, 7.845754709000106],
              [109.11834849700003, 7.845894161],
              [109.11852345200003, 7.84603754600006],
              [109.1186927950001, 7.846172882000067],
              [109.11887123200003, 7.846311926000025],
              [109.11904386900001, 7.846443075000041],
              [109.11922568000004, 7.846577697000077],
              [109.11940150200012, 7.846704569000094],
              [109.11958657900006, 7.846834689000025],
              [109.11976547800003, 7.846957204000125],
              [109.11995371100006, 7.847082751000032],
              [109.120135578, 7.847200838000033],
              [109.12031885699999, 7.84731673400003],
              [109.12050352200008, 7.84743042000011],
              [109.12069766200007, 7.847546679000049],
              [109.12088506900002, 7.847655803000107],
              [109.12108202200007, 7.847767257],
              [109.12127207100008, 7.847871748000033],
              [109.12147172000007, 7.84797834400006],
              [109.12166428600003, 7.848078139000057],
              [109.12186650400005, 7.84817979600003],
              [109.12206147700002, 7.848274834000078],
              [109.12226613700008, 7.848371508000083],
              [109.12246340000006, 7.848461734000088],
              [109.12267038600004, 7.848553345000118],
              [109.12286981300011, 7.848638707000076],
              [109.12307899700001, 7.84872522000004],
              [109.12328047000007, 7.848805664000034],
              [109.12348289500004, 7.848883676000094],
              [109.12368625199998, 7.848959257000042],
              [109.12389941800004, 7.849035512000045],
              [109.12410457800004, 7.84910607800011],
              [109.12431955500007, 7.849177075000034],
              [109.12452639100005, 7.849242590000037],
              [109.12474306000003, 7.849308294000055],
              [109.12495145400004, 7.84936870500008],
              [109.12516967100007, 7.849429081000013],
              [109.12537948900011, 7.849484361000057],
              [109.12559913700008, 7.849539355000077],
              [109.12581026100004, 7.84958947900005],
              [109.12603118600003, 7.849639065000067],
              [109.12624349000001, 7.849683988000054],
              [109.12646557500008, 7.849728139000066],
              [109.12667892300007, 7.849767845000065],
              [109.12689272500008, 7.849804977000117],
              [109.12710696400003, 7.849839537000046],
              [109.12733094600001, 7.84987286700005],
              [109.12754596700003, 7.849902166000076],
              [109.12777070400007, 7.849929973000068],
              [109.12798639000006, 7.849953993000012],
              [109.12821174600006, 7.84997628000008],
              [109.128427953, 7.84999499400003],
              [109.12865379200004, 7.850011751000084],
              [109.12887040300002, 7.850025152000038],
              [109.12909658200009, 7.850036362000077],
              [109.129313461, 7.850044450000036],
              [109.12953984400008, 7.85005010600006],
              [109.129756856, 7.85005286400011],
              [109.12998331600009, 7.850052965000119],
              [109.1302003270001, 7.850050394000084],
              [109.13041728700003, 7.8500452260001],
              [109.13063417000004, 7.850037444000078],
              [109.130860372, 7.850026555000065],
              [109.13107699200008, 7.850013462000048],
              [109.13130285700002, 7.849997022000109],
              [109.13151909600006, 7.849978611],
              [109.13174448000007, 7.849956637000053],
              [109.13196019500006, 7.849932927000046],
              [109.13218497200009, 7.84990542900008],
              [109.13240004500003, 7.849876429000078],
              [109.13262407100009, 7.849843427000012],
              [109.13283835700005, 7.849809165000108],
              [109.13306150600005, 7.84977066700012],
              [109.13327488100003, 7.849731162000049],
              [109.13349700800002, 7.849687206000054],
              [109.1337093450001, 7.849642467000082],
              [109.13392112700004, 7.849595188000079],
              [109.1341323180001, 7.849545369000042],
              [109.13435204300006, 7.849490677000047],
              [109.13456194400003, 7.849435688000115],
              [109.13478024300007, 7.849375631000031],
              [109.13498872800008, 7.84931550900003],
              [109.13520548300008, 7.849250104000037],
              [109.13541241700005, 7.849184886000058],
              [109.13562749400009, 7.849114188000071],
              [109.13583275000011, 7.849043911000118],
              [109.13604601400007, 7.848967956000051],
              [109.13624948500004, 7.848892665000038],
              [109.13646080900003, 7.848811499000073],
              [109.136662352, 7.848731241000094],
              [109.13687162000008, 7.848644910000056],
              [109.13707111600007, 7.848559728000025],
              [109.13726958100006, 7.848472153000062],
              [109.13746697000005, 7.848382211000081],
              [109.13767176600004, 7.8482858310001],
              [109.13786687500001, 7.848191057000074],
              [109.13806923700005, 7.848089685000035],
              [109.13826194000011, 7.847990162000061],
              [109.13846173400003, 7.847883851000058],
              [109.13865193300003, 7.84777963300005],
              [109.1388490410001, 7.847668448000087],
              [109.13903661000008, 7.847559589000051],
              [109.13923091500007, 7.847443603000047],
              [109.13941574600008, 7.847330176000076],
              [109.13960713300003, 7.847209461000125],
              [109.13978910000007, 7.847091538000049],
              [109.13997745000003, 7.846966158000067],
              [109.1401564550001, 7.846843803000068],
              [109.14033396900004, 7.84671930800007],
              [109.14050995800002, 7.846592683000074],
              [109.14069196800008, 7.846458315000058],
              [109.14086478800007, 7.846327412000063],
              [109.14104342100003, 7.846188623000037],
              [109.14121295500011, 7.84605352300005],
              [109.14138811200004, 7.845910386000108],
              [109.14155426200001, 7.845771162000046],
              [109.14172583600001, 7.845623775000108],
              [109.14188849300005, 7.845480518000068],
              [109.142056376, 7.845328964000052],
              [109.14221546100001, 7.845181763000129],
              [109.142379546, 7.845026132000042],
              [109.14253495000007, 7.844875078000067],
              [109.14269514600009, 7.84471547],
              [109.14284677100002, 7.844560635000073],
              [109.14299652100007, 7.844404005000081],
              [109.14314436000004, 7.844245579000118],
              [109.14329658300002, 7.844078385000051],
              [109.14344046300003, 7.843916377000058],
              [109.1435885110001, 7.843745504000042],
              [109.14372836200005, 7.843580013000027],
              [109.14387214600004, 7.84340556000007],
              [109.14400786800007, 7.843236687000042],
              [109.14414730699998, 7.843058762000064],
              [109.14427882000003, 7.842886614000031],
              [109.14441382400001, 7.84270530800004],
              [109.14454104800005, 7.842529993000101],
              [109.14467153700011, 7.842345432000018],
              [109.14479439200005, 7.842167041],
              [109.14492029399999, 7.841979342000103],
              [109.14503870700003, 7.841797992000108],
              [109.14515493200004, 7.841615235000086],
              [109.14526894000007, 7.841431096000038],
              [109.14538552800012, 7.841237500000096],
              [109.14549495200002, 7.841050620000088],
              [109.14560672100004, 7.840854228000092],
              [109.14571150800001, 7.840664713000043],
              [109.14581839500005, 7.840465635],
              [109.14591847300007, 7.840273612000102],
              [109.14602042600006, 7.840071973000116],
              [109.14611573200006, 7.839877560000018],
              [109.14621267000007, 7.839673477000096],
              [109.14630315100001, 7.839476781000075],
              [109.14639503000006, 7.83927038100005],
              [109.14648062400005, 7.839071519000116],
              [109.14656738000011, 7.838862937000089],
              [109.14664805200005, 7.838662036000075],
              [109.14672628500003, 7.838460177000071],
              [109.14680208000009, 7.83825739800008],
              [109.14687855000001, 7.838044847000079],
              [109.146949315, 7.837840272000028],
              [109.14702051300003, 7.837625908000055],
              [109.14708621400005, 7.837419653000042],
              [109.14715210300008, 7.837203612000019],
              [109.14721269500004, 7.836995812000057],
              [109.14727323200007, 7.836778219000083],
              [109.14732867000004, 7.836568993000091],
              [109.14738382900008, 7.836349983000089],
              [109.14743408700006, 7.836139455000078],
              [109.14748382300009, 7.835919154000058],
              [109.14752887600008, 7.83570747000004],
              [109.14757316200011, 7.835486013000102],
              [109.14761297300004, 7.835273279000086],
              [109.14765021400009, 7.835060083000121],
              [109.14768488400003, 7.834846461000032],
              [109.14771831000004, 7.834623115000026],
              [109.14774769600007, 7.834408704000049],
              [109.14777559400008, 7.834184616000071],
              [109.14779967700011, 7.833969543000039],
              [109.14782204000001, 7.833744838000099],
              [109.14784081200006, 7.833529247000024],
              [109.14785762100011, 7.833304052000043],
              [109.14787107500007, 7.833088068000025],
              [109.14788232300002, 7.832862535000105],
              [109.14789043200007, 7.832646275000064],
              [109.14789611800009, 7.832420540000038],
              [109.14789889200006, 7.832204148000075],
              [109.14789900100007, 7.831978335000132],
              [109.14789644000004, 7.83176194600008],
              [109.14789126700005, 7.831545607000033],
              [109.14788347200003, 7.83132934600008],
              [109.14787256200002, 7.83110379300007],
              [109.14785944300009, 7.83088778500005],
              [109.14784296600006, 7.830662578000068],
              [109.14782452400004, 7.830446950000079],
              [109.14780249900008, 7.830222214000047],
              [109.14777873500006, 7.830007110000013],
              [109.147751172, 7.829782979000031],
              [109.147722113, 7.829568525000051],
              [109.14768903100001, 7.829345135000041],
              [109.14765468800002, 7.829131465000037],
              [109.14761609600002, 7.828908959000012],
              [109.14757649500004, 7.828696190000082],
              [109.14753243100003, 7.828474694000052],
              [109.14748759200005, 7.828262960000119],
              [109.14744018900004, 7.828051789000055],
              [109.14739024700008, 7.827841198000039],
              [109.14733542900007, 7.827622098000029],
              [109.14728030500004, 7.827412803000032],
              [109.14722009800003, 7.82719511600005],
              [109.14715982700002, 7.826987233000081],
              [109.14709426800002, 7.82677109500005],
              [109.14702887900002, 7.82656475000003],
              [109.1469580130001, 7.826350285000048],
              [109.14688756100006, 7.826145613000078],
              [109.14681141600005, 7.825932947000068],
              [109.14673592900003, 7.825730064000068],
              [109.14665456800005, 7.82551933300004],
              [109.14657410000005, 7.825318364000021],
              [109.14648756100009, 7.825109701000075],
              [109.14640216700003, 7.824910754000046],
              [109.14631437300005, 7.824712863000103],
              [109.14622419700005, 7.824516027000071],
              [109.14612757400005, 7.824311814000049],
              [109.1460325720001, 7.824117251000116],
              [109.14593093500012, 7.82391547400003],
              [109.14583116300004, 7.823723301000117],
              [109.14572458400002, 7.823524075000064],
              [109.14562009600007, 7.823334408000092],
              [109.145508631, 7.823137861000083],
              [109.14539949900004, 7.822950816000061],
              [109.14528322100008, 7.822757054000105],
              [109.145169509, 7.822572748000043],
              [109.14504849000012, 7.822381905000063],
              [109.14493026200002, 7.822200446000059],
              [109.1448045650001, 7.822012621000061],
              [109.14468189400007, 7.821834115000035],
              [109.14455708700005, 7.821657104000044],
              [109.14443014400003, 7.821481596000089],
              [109.14429542800008, 7.821300102000082],
              [109.14416419600005, 7.821127762000034],
              [109.14402504800009, 7.820949626000038],
              [109.14388959900006, 7.820780553000082],
              [109.143746101, 7.820605891000108],
              [109.143606527, 7.820440201000077],
              [109.14345876000003, 7.820269103000042],
              [109.14331514200005, 7.82010688600012],
              [109.14316319700001, 7.819939467000035],
              [109.14301562600005, 7.819780822000054],
              [109.1428595940001, 7.819617181000103],
              [109.14270815100011, 7.819462214000071],
              [109.1425481310001, 7.819302450000086],
              [109.14239290700004, 7.819151241],
              [109.14223586500002, 7.819001902000087],
              [109.14207704000002, 7.818854459000056],
              [109.141909415, 7.818702653000019],
              [109.14174699900006, 7.818559157000047],
              [109.14157567700002, 7.818411513000088],
              [109.14140977000005, 7.818272043000095],
              [109.14123485800006, 7.818128642000131],
              [109.14106556000009, 7.817993288000123],
              [109.14088716700007, 7.817854228000075],
              [109.14071457600008, 7.817723061000057],
              [109.14053281200006, 7.817588422000108],
              [109.14035703800008, 7.817461532],
              [109.14017201000004, 7.817331385000069],
              [109.13999316100008, 7.817208851000055],
              [109.13980496900005, 7.817083286000058],
              [109.13962315300002, 7.816965180000056],
              [109.13943991600004, 7.816849256000056],
              [109.13925530500005, 7.816735550000063],
              [109.13906121000007, 7.816619262000033],
              [109.13887383900001, 7.816510118000062],
              [109.13867694200007, 7.816398635000075],
              [109.13848693900007, 7.816294122000043],
              [109.13828734800008, 7.816187497000014],
              [109.13809482200004, 7.816087680000104],
              [109.1378926630001, 7.815985983000038],
              [109.13769773100005, 7.815890914000077],
              [109.13749312200008, 7.81579421700007],
              [109.13729591000002, 7.815703958000063],
              [109.13708897800004, 7.815612315000029],
              [109.13688959400007, 7.815526919000069],
              [109.13668046500008, 7.815440373000103],
              [109.13647903700011, 7.815359904000017],
              [109.13627665800007, 7.815281847000042],
              [109.13607334600003, 7.815206240000093],
              [109.13586023700007, 7.815129949],
              [109.13565512500006, 7.815059347000018],
              [109.13544019800004, 7.814988304000091],
              [109.13523340100008, 7.814922760000085],
              [109.135016792, 7.814857009000063],
              [109.13480844700008, 7.814796560000035],
              [109.13459027200007, 7.81473614500001],
              [109.13438049500012, 7.814680825000052],
              [109.13416090900006, 7.814625782000027],
              [109.13394982700001, 7.814575616000051],
              [109.13372894600012, 7.814525980000029],
              [109.13351669500005, 7.814481014000039],
              [109.13329465400007, 7.814436820000113],
              [109.13308136000009, 7.81439707100011],
              [109.13286759300009, 7.814359894000054],
              [109.13265340800004, 7.814325280000032],
              [109.13242947100007, 7.814291905000024],
              [109.13221448600004, 7.814262560000083],
              [109.13198979600001, 7.814234696000087],
              [109.13177415500012, 7.81421062900005],
              [109.13154884700003, 7.814188285000066],
              [109.131332677, 7.814169522000023],
              [109.13110688500007, 7.814152706000053],
              [109.130890311, 7.814139255],
              [109.13066417100002, 7.814127985000051],
              [109.13044733800002, 7.814119853000088],
              [109.13022099500003, 7.814114137000059],
              [109.13000402000003, 7.814111316],
            ],
          ],
          [
            [
              [110.45338010000006, 7.802786686000028],
              [110.45005487500001, 7.802681733000048],
              [110.44876800600011, 7.80384991800007],
              [110.44827714100009, 7.805980922000087],
              [110.45102257200004, 7.811174087000071],
              [110.45555527800009, 7.815588804000038],
              [110.45980771700005, 7.820727459000041],
              [110.46654807800006, 7.828431103000087],
              [110.47710019300007, 7.840015606000071],
              [110.48293989200008, 7.845389095000053],
              [110.48673923800001, 7.848803232000099],
              [110.49109530100002, 7.850617116000079],
              [110.49843060400005, 7.85205829000004],
              [110.50500061700009, 7.85229242300005],
              [110.50929923700005, 7.851646048000037],
              [110.51186669600003, 7.849292942000117],
              [110.51230396600006, 7.847054736000112],
              [110.51179534600007, 7.844414801000086],
              [110.50871000800005, 7.836677594000106],
              [110.50619143300003, 7.831705104000085],
              [110.5026584550001, 7.826414459000118],
              [110.49724178400001, 7.820714023000084],
              [110.48850169400001, 7.815053303000051],
              [110.48064810100007, 7.810967186000093],
              [110.47411431100002, 7.808450582000058],
              [110.46570213900006, 7.805956049000072],
              [110.45739110700002, 7.803480711000075],
              [110.45338010000006, 7.802786686000028],
            ],
          ],
          [
            [
              [114.40594759500007, 7.820511263000094],
              [114.40538368, 7.82027850300002],
              [114.40454863700006, 7.823934132000064],
              [114.40568272200001, 7.827783597000069],
              [114.40986843900012, 7.834579104000026],
              [114.41323114000009, 7.839373316000129],
              [114.41522097700006, 7.843534293000063],
              [114.4220808670001, 7.846531674000088],
              [114.42997541, 7.85287482700001],
              [114.43677177900008, 7.859251768000062],
              [114.44320424600002, 7.864795346000085],
              [114.44751890000006, 7.870235535000079],
              [114.44853038100008, 7.87147872100009],
              [114.44951958300013, 7.867131148000082],
              [114.44855348500003, 7.863276313000077],
              [114.44293451000009, 7.856694339000024],
              [114.43979762700006, 7.853751260000051],
              [114.43563099100001, 7.850333327000046],
              [114.43283757499999, 7.847548464000019],
              [114.426199565, 7.843531210000075],
              [114.42307883, 7.841094160000049],
              [114.41945638500012, 7.838841461000088],
              [114.4164838720001, 7.835724210000093],
              [114.41382330200005, 7.831752899000042],
              [114.41060879700001, 7.826278678000074],
              [114.40594759500007, 7.820511263000094],
            ],
          ],
          [
            [
              [111.74053724600005, 7.838384561000032],
              [111.73845788000004, 7.838214879000114],
              [111.73611671100005, 7.838878072000098],
              [111.73303187900007, 7.840520822000128],
              [111.73023120700006, 7.842572546000069],
              [111.72868212300006, 7.84494691200006],
              [111.72673649100001, 7.848363646000054],
              [111.72560123500006, 7.850661587000028],
              [111.72520967500007, 7.851979956000131],
              [111.72489104300008, 7.853504085000028],
              [111.72463537800007, 7.854681999000055],
              [111.72464583900005, 7.855768218000046],
              [111.7246689190001, 7.856544932000096],
              [111.72495812100004, 7.857229950000109],
              [111.72559948400009, 7.858253763000088],
              [111.72632954300009, 7.859301559000096],
              [111.72682432000008, 7.859845378000061],
              [111.72728632900004, 7.860493430000036],
              [111.72785099600003, 7.861070554],
              [111.72813799200006, 7.8616181120001],
              [111.72849550500007, 7.862233487000124],
              [111.72906453500008, 7.863052338000019],
              [111.72935464700001, 7.863772558000013],
              [111.72961075600006, 7.864527928000074],
              [111.72983038100003, 7.865180324000052],
              [111.73015574900003, 7.86593444600001],
              [111.7302015880001, 7.866555409000052],
              [111.73035132100006, 7.86717452900003],
              [111.73050541100011, 7.868035353000023],
              [111.73068852900006, 7.868584787000097],
              [111.73083577500009, 7.869065757000014],
              [111.73098363500006, 7.869581266000035],
              [111.73116488800005, 7.870027096000063],
              [111.73145379000006, 7.870678257000028],
              [111.73163504500005, 7.871124079000056],
              [111.73178229500006, 7.871605060000062],
              [111.73203468600008, 7.872153247000123],
              [111.73225244100006, 7.872702046000056],
              [111.732571611, 7.873110856000041],
              [111.73292852500003, 7.873691710000051],
              [111.7333522270001, 7.874133186000075],
              [111.73476745000005, 7.875765939000077],
              [111.735225175, 7.876172253000034],
              [111.73561112200005, 7.876441682000033],
              [111.73609723700012, 7.876502058000055],
              [111.73651034400011, 7.876356462000087],
              [111.73674966000002, 7.876179434000095],
              [111.73705200900007, 7.875655840000116],
              [111.73721642700008, 7.875169271000091],
              [111.73734560900003, 7.874648786000103],
              [111.73736902300001, 7.874026561000045],
              [111.73739055700001, 7.873300738000031],
              [111.73747949700001, 7.872470085000048],
              [111.73750039700005, 7.871709719000107],
              [111.73748855000004, 7.871053599000087],
              [111.73750945900008, 7.870293244000058],
              [111.73753473400004, 7.869774630000046],
              [111.73771633100006, 7.868320523000081],
              [111.73784176600005, 7.867592845000091],
              [111.73820524800001, 7.86661908600008],
              [111.73957896900008, 7.860203847000056],
              [111.73985382600009, 7.858160862000128],
              [111.73997861400001, 7.857398664000035],
              [111.74007002300003, 7.856706163000114],
              [111.74022944400008, 7.85594336200006],
              [111.74042411400011, 7.855214442000056],
              [111.74130355600003, 7.852124338000087],
              [111.74169226600004, 7.850632018000068],
              [111.74174841700008, 7.849905613000095],
              [111.74177305400008, 7.849352497000071],
              [111.74173747400006, 7.847384219000018],
              [111.74158396700005, 7.846557964000043],
              [111.74149597000006, 7.845476137000061],
              [111.74154395400004, 7.844301969000076],
              [111.74192540700007, 7.842431631000045],
              [111.74187800200005, 7.839809822000097],
              [111.74053724600005, 7.838384561000032],
            ],
          ],
          [
            [
              [114.25170184300005, 7.829774665000054],
              [114.25385944900005, 7.828529699000073],
              [114.25639518500007, 7.828622065000078],
              [114.25680455400001, 7.825406529000121],
              [114.25815259400008, 7.816936570000086],
              [114.25872551400006, 7.813547625000082],
              [114.25931879100003, 7.810832419000045],
              [114.26144048500008, 7.808408413000032],
              [114.25918634500009, 7.806453233000086],
              [114.25315196700004, 7.805117430000051],
              [114.24886858800002, 7.803054554000076],
              [114.24628741100005, 7.801446245000053],
              [114.23822591200008, 7.802868154000107],
              [114.23293436600011, 7.806566931000079],
              [114.23131844300008, 7.808975492000057],
              [114.22759195200004, 7.808581306000038],
              [114.22579354400001, 7.804926315000047],
              [114.22631121700012, 7.799684846000064],
              [114.23151329300003, 7.795820399000062],
              [114.23428450900009, 7.792534420000051],
              [114.23467893100005, 7.78881394800006],
              [114.23365799100013, 7.782944462000073],
              [114.23307081200004, 7.780264908000055],
              [114.22958263000008, 7.779357950000064],
              [114.22634602499998, 7.784006267000086],
              [114.22384476900007, 7.790655482000107],
              [114.22293748100005, 7.799785781000066],
              [114.22197772900003, 7.804366145000062],
              [114.22346907900007, 7.80904183800004],
              [114.22643317200009, 7.812156242000066],
              [114.23420212800012, 7.81782411500007],
              [114.24103146700011, 7.82031679500008],
              [114.24594119400001, 7.823541258000065],
              [114.24850141500011, 7.830039419000022],
              [114.24681339700005, 7.835653669000029],
              [114.25052431900005, 7.841105888000042],
              [114.25631394200001, 7.845484041000093],
              [114.25859335400006, 7.85384549000012],
              [114.26051000100009, 7.861374984000056],
              [114.26320652000001, 7.869555574000041],
              [114.26994975300008, 7.877347665000032],
              [114.27217030200003, 7.869959986000106],
              [114.27314722300005, 7.863186251000085],
              [114.27027145800005, 7.854674111000024],
              [114.26951487599997, 7.851999281000042],
              [114.270762609, 7.848589677000105],
              [114.268824976, 7.845950466000049],
              [114.26490863000006, 7.844888218000088],
              [114.260397259, 7.838111519000089],
              [114.25679306200004, 7.836196782000025],
              [114.25349074500001, 7.833092761000058],
              [114.25170184300005, 7.829774665000054],
            ],
          ],
          [
            [
              [112.92309947700005, 7.900458797000097],
              [112.92402644000001, 7.899427621000131],
              [112.92349547200004, 7.899823954000061],
              [112.9224807610001, 7.895998098000055],
              [112.92101418099999, 7.89502311900012],
              [112.91939790000002, 7.894487829000061],
              [112.91879213500007, 7.894287205000071],
              [112.91647533000005, 7.893618465000072],
              [112.91113997900003, 7.892078335000095],
              [112.91522222100002, 7.895404086000022],
              [112.91629405600007, 7.896277275000061],
              [112.91615409800012, 7.897466207000113],
              [112.91607058500006, 7.898175646000103],
              [112.91187092300002, 7.895661640000105],
              [112.91915696600006, 7.901474412000107],
              [112.92054328500002, 7.90189618100001],
              [112.92309947700005, 7.900458797000097],
            ],
          ],
          [
            [
              [114.48774545400015, 7.902186721000069],
              [114.48373966000008, 7.898594296000015],
              [114.47690376800007, 7.895105210000073],
              [114.46893035399999, 7.891458036000108],
              [114.46528438400004, 7.888530019000049],
              [114.46048088100005, 7.884126268000012],
              [114.46024048700006, 7.889069795000035],
              [114.46345993899999, 7.894542550000059],
              [114.46533787800003, 7.900402575000063],
              [114.46439410500001, 7.900552436000028],
              [114.47190575900007, 7.900277115000074],
              [114.47487998900013, 7.908846665000089],
              [114.47892812300006, 7.91378980100008],
              [114.48058879800006, 7.916073092000085],
              [114.48409810600008, 7.918879354000106],
              [114.48024206199997, 7.924763088000118],
              [114.48110018500009, 7.929106706000065],
              [114.48270696499999, 7.931760324000068],
              [114.48945623500003, 7.931043061000052],
              [114.498784226, 7.926189610000021],
              [114.50259449400005, 7.923535959000015],
              [114.50412118900007, 7.918250018000082],
              [114.50214418100009, 7.914594378000038],
              [114.49888339900004, 7.913303470000075],
              [114.490629031, 7.906240753000036],
              [114.48774545400015, 7.902186721000069],
            ],
          ],
          [
            [
              [111.72186429200012, 7.923825825000018],
              [111.71725386300002, 7.921561151000043],
              [111.71407489799999, 7.921894017000051],
              [111.70847232300007, 7.92525188600006],
              [111.70327614600009, 7.92821771200005],
              [111.70028955599999, 7.9316512190001],
              [111.69867882200003, 7.93597447800005],
              [111.69832152000004, 7.939968288],
              [111.69911116, 7.944665014000039],
              [111.7014170620001, 7.947350377000094],
              [111.70900522000001, 7.948078775000054],
              [111.71900543700009, 7.947047343000067],
              [111.72529745400004, 7.945302604000036],
              [111.72899323300004, 7.942889274000018],
              [111.73180599400007, 7.939171407000066],
              [111.73355840200003, 7.934937635000059],
              [111.73374064600009, 7.929687900000102],
              [111.73285773500004, 7.926804410000086],
              [111.72935458800001, 7.924520137000089],
              [111.724544447, 7.92445788800009],
              [111.72186429200012, 7.923825825000018],
            ],
          ],
          [
            [
              [114.46936551300003, 7.964103962000111],
              [114.46861095100004, 7.96143915900007],
              [114.47269948600001, 7.957095833000059],
              [114.47603347200001, 7.955642941000106],
              [114.47868688600008, 7.951344200000053],
              [114.479726035, 7.946758431000054],
              [114.48030623900004, 7.943704777000081],
              [114.48038004700001, 7.940666918000082],
              [114.47536895000009, 7.937112760000085],
              [114.47220411200007, 7.935862077000076],
              [114.46952451800004, 7.936619943000077],
              [114.46860658000003, 7.939684016000024],
              [114.47171867800004, 7.941948257000052],
              [114.47282634700004, 7.944949393000032],
              [114.47155499500001, 7.947518577000066],
              [114.471401, 7.949926916000026],
              [114.46914790900006, 7.953292339000127],
              [114.46509762400004, 7.959861834000035],
              [114.46199585400009, 7.957934359000072],
              [114.45742745600002, 7.957738833000059],
              [114.45156639100001, 7.959438412000084],
              [114.4458792810001, 7.961301101000105],
              [114.44050872100001, 7.962479239000063],
              [114.4365788060001, 7.961082954000085],
              [114.43238057700002, 7.956490582000074],
              [114.428916, 7.951032300000092],
              [114.42733000700002, 7.943492330000089],
              [114.42892898400007, 7.940576048000112],
              [114.42926258900005, 7.935000631000031],
              [114.42872019600011, 7.931138713000062],
              [114.42728090600004, 7.92831634700004],
              [114.42393655700008, 7.929431407000019],
              [114.42080278500005, 7.931889020000059],
              [114.41668911300003, 7.92999224300006],
              [114.409895796, 7.92885247800008],
              [114.40318078800013, 7.930239615000099],
              [114.39856093700006, 7.928358063000117],
              [114.39291805700009, 7.926170574000045],
              [114.38727932000009, 7.926849604],
              [114.3836620080001, 7.9245997030001],
              [114.37774414200011, 7.924443850000083],
              [114.37091733300005, 7.927688341000037],
              [114.36188048600002, 7.930494269000031],
              [114.35368218100007, 7.933105587000087],
              [114.35161882600005, 7.934686297000047],
              [114.34569552000005, 7.934361060000049],
              [114.33942948900003, 7.933877459000092],
              [114.33504600500007, 7.934179554000041],
              [114.33185954500004, 7.934951056000015],
              [114.32943971500001, 7.933169651000059],
              [114.32453323300004, 7.927416611000114],
              [114.32182121900009, 7.92159689800007],
              [114.32066440000003, 7.916910402000086],
              [114.31768917600006, 7.910761529000075],
              [114.31367224200005, 7.904694264000066],
              [114.30979488200005, 7.899415943000053],
              [114.30503234300008, 7.89484741900006],
              [114.30151342899998, 7.890232689000063],
              [114.29627585100005, 7.887129024000037],
              [114.28947437300005, 7.88564851700002],
              [114.28801682200006, 7.882151658000057],
              [114.28508660600008, 7.880216815000017],
              [114.27797325800012, 7.882479973000043],
              [114.28029377200001, 7.886031772000111],
              [114.2853777170001, 7.891332353000012],
              [114.292932467, 7.899122491000108],
              [114.30115619199999, 7.906050747000036],
              [114.30607064900002, 7.912362562000029],
              [114.310285912, 7.918871986000029],
              [114.31046576500005, 7.924768591000129],
              [114.315859344, 7.931601157000044],
              [114.320463378, 7.936409818000099],
              [114.33164192800007, 7.944401344000072],
              [114.34285869900009, 7.949511342000013],
              [114.34755250000005, 7.949613553000033],
              [114.35444826500013, 7.94833206000005],
              [114.36360994800012, 7.943814823000126],
              [114.369088691, 7.938708524000047],
              [114.37401512400004, 7.935013414000112],
              [114.37699628900006, 7.933067097000011],
              [114.38383633900006, 7.935724255000041],
              [114.38947938300014, 7.937912030000048],
              [114.39788686400007, 7.939340173000085],
              [114.40509630300008, 7.942997167000064],
              [114.40891949200012, 7.946420966000021],
              [114.40973042800007, 7.950780718000059],
              [114.40773946800002, 7.95741919500008],
              [114.40898812000003, 7.96227155400002],
              [114.41406137800004, 7.967849385000124],
              [114.42045934500005, 7.972543057000079],
              [114.42394221000011, 7.97430078100003],
              [114.43202318900002, 7.976771335000075],
              [114.43697182300002, 7.978694845000065],
              [114.446458667, 7.978716971000031],
              [114.453222599, 7.977469675000036],
              [114.45836678300012, 7.974442995000073],
              [114.46368308300006, 7.971374044000077],
              [114.46936551300003, 7.964103962000111],
            ],
          ],
          [
            [
              [113.91898565900009, 8.018157814000036],
              [113.92747854200006, 8.016209980000076],
              [113.93187130500007, 8.014378666000075],
              [113.93342313499996, 8.008874675000042],
              [113.93491618600004, 8.001325038000038],
              [113.93591909699997, 7.989330052000108],
              [113.94193896900006, 7.98054004700006],
              [113.94933756500009, 7.972555537000046],
              [113.95895190900004, 7.965014420000037],
              [113.96420119000004, 7.958949889000076],
              [113.96525300600004, 7.957149838000087],
              [113.96069923900011, 7.952122241000035],
              [113.95320881300003, 7.950984814000071],
              [113.94826038600004, 7.949200073000016],
              [113.94390575300008, 7.951014195000104],
              [113.94390937300003, 7.957671570000089],
              [113.94279700700008, 7.966152151000115],
              [113.93792926600011, 7.973726170000093],
              [113.92361467900002, 7.982921103000072],
              [113.91371253, 7.992327882000081],
              [113.90124978100006, 8.001770230000103],
              [113.89501195800005, 8.004847403000069],
              [113.8920828, 8.010048778000021],
              [113.89568401200006, 8.016430303],
              [113.90061325100007, 8.021409264000033],
              [113.90779818100009, 8.021546739000128],
              [113.91898565900009, 8.018157814000036],
            ],
            [
              [113.92213499300007, 7.990125392000012],
              [113.93303751500002, 7.988253813000055],
              [113.93033174299997, 7.99655202200009],
              [113.92918811700011, 8.003244670000063],
              [113.92712298900004, 8.008853619000076],
              [113.92409821, 8.012047816000044],
              [113.91951641700004, 8.01528627200012],
              [113.91489643200013, 8.017193667000109],
              [113.90979982600005, 8.018004434000135],
              [113.90532611400009, 8.017243333000044],
              [113.901875905, 8.013344994000081],
              [113.90017980399999, 8.008508915000066],
              [113.90795844200011, 8.000296174000086],
              [113.92213499300007, 7.990125392000012],
            ],
          ],
          [
            [
              [110.50178055100011, 8.070005157000091],
              [110.49652675500009, 8.06989933900004],
              [110.49229350400003, 8.070118919],
              [110.49196687600002, 8.071136023000046],
              [110.49572655600005, 8.073792882000051],
              [110.49947204500006, 8.075267777000049],
              [110.50198876700003, 8.073211134000056],
              [110.50366042300004, 8.071333497000042],
              [110.50178055100011, 8.070005157000091],
            ],
          ],
          [
            [
              [110.63513973700002, 8.061414822000044],
              [110.63808681100005, 8.053433972000066],
              [110.63812038800009, 8.042786050000037],
              [110.63470312700005, 8.033787608000063],
              [110.63173205600006, 8.026558146000083],
              [110.62922833100004, 8.016111962000048],
              [110.62396664100007, 8.008489139000028],
              [110.61799156200004, 8.004677550000048],
              [110.610226846, 8.006633946000095],
              [110.60468003900007, 8.009914261000082],
              [110.60468596600003, 8.010385169000054],
              [110.59708980900008, 8.019050240000068],
              [110.59176894100001, 8.026890425000131],
              [110.58866169300005, 8.035717108000105],
              [110.58750688200007, 8.044942201000092],
              [110.59040679600002, 8.060116960000039],
              [110.59466936700001, 8.069106143000047],
              [110.59948368400009, 8.074792669000084],
              [110.60442720700011, 8.077266171000106],
              [110.61307349, 8.077833933000099],
              [110.62099951600005, 8.075030027000095],
              [110.62399664300005, 8.070935860000093],
              [110.62928536800004, 8.067235309000063],
              [110.63513973700002, 8.061414822000044],
            ],
            [
              [110.59544139700003, 8.036139739000079],
              [110.59931273100007, 8.034232430000097],
              [110.60272488900003, 8.036217832000057],
              [110.60840503800009, 8.036738256000129],
              [110.611757583, 8.033992199000027],
              [110.61204503800008, 8.02993256200004],
              [110.60979899500003, 8.026411726000047],
              [110.60741565200001, 8.025427601000066],
              [110.60551477100003, 8.022409437000098],
              [110.60717605300009, 8.019853677000109],
              [110.61241858799998, 8.019280990000064],
              [110.61275723400011, 8.019276742000068],
              [110.61511276100006, 8.018064207000107],
              [110.62215984200004, 8.019581030000021],
              [110.62574130200008, 8.021563864000123],
              [110.62528265600005, 8.025456599000068],
              [110.62638012600009, 8.031864761000039],
              [110.62637376500001, 8.038033426000089],
              [110.62474473700004, 8.043124110000031],
              [110.618884177, 8.048437112000071],
              [110.61489041300004, 8.054064592000108],
              [110.61086231200009, 8.056988318000078],
              [110.60444186400008, 8.058251805000081],
              [110.59910196500003, 8.057895982000039],
              [110.59481729599999, 8.053893170000047],
              [110.59304331100003, 8.04749294700011],
              [110.59398308300007, 8.041397031000129],
              [110.59544139700003, 8.036139739000079],
            ],
          ],
          [
            [
              [110.46476585300003, 8.077707884000061],
              [110.46153887000003, 8.077070581000088],
              [110.45850540000004, 8.078457373000079],
              [110.45920918200008, 8.080644372000021],
              [110.46158709400001, 8.08112288300009],
              [110.46566326600004, 8.081919011000059],
              [110.46802912200003, 8.081384373000054],
              [110.46833774500007, 8.078847667000042],
              [110.46544754700004, 8.078037554000071],
              [110.46476585300003, 8.077707884000061],
            ],
          ],
          [
            [
              [114.13769119600011, 8.08005026900007],
              [114.13295387100001, 8.079279697000084],
              [114.128569885, 8.080167241000067],
              [114.12564317200005, 8.084045706000074],
              [114.12589838400004, 8.087527194000098],
              [114.12740066600009, 8.092033891000126],
              [114.13228656900004, 8.097806309000076],
              [114.13784259700003, 8.100524902000037],
              [114.1426203850001, 8.100080622000048],
              [114.14801889700003, 8.097494097],
              [114.149513961, 8.094366797000062],
              [114.15006608500003, 8.093975954000053],
              [114.15055698299997, 8.091526930000013],
              [114.15031915400002, 8.089848528000053],
              [114.14993810400001, 8.089672515000055],
              [114.14801616, 8.088231113000076],
              [114.14326928199998, 8.086487334000124],
              [114.13850447299998, 8.081764274000046],
              [114.13769119600011, 8.08005026900007],
            ],
          ],
          [
            [
              [110.53777722100008, 8.107202372000087],
              [110.53499276000008, 8.106329289000056],
              [110.53413508300008, 8.10707395600012],
              [110.53436975000005, 8.108539576000061],
              [110.53585315200004, 8.109342136000029],
              [110.53905662200006, 8.109471096000041],
              [110.54149506000003, 8.109964415000091],
              [110.54518310100009, 8.113123070000048],
              [110.54688312400006, 8.114606389000032],
              [110.54959225700004, 8.116387269000123],
              [110.55253090700009, 8.119201903000127],
              [110.55511334300007, 8.121243428000112],
              [110.555680454, 8.121538814000038],
              [110.55637383600001, 8.121530306000045],
              [110.55644876500007, 8.12057916900004],
              [110.55600259000002, 8.119548047000079],
              [110.55429183500004, 8.117226202000081],
              [110.55078114600009, 8.114385916000087],
              [110.54767516700005, 8.112749454000044],
              [110.54526300100004, 8.109745625000107],
              [110.54204240400001, 8.107565738000043],
              [110.53777722100008, 8.107202372000087],
            ],
          ],
          [
            [
              [110.45194041900005, 8.144459492000065],
              [110.44701970600005, 8.144010685000056],
              [110.44204224400006, 8.144153120000029],
              [110.43936355000002, 8.144827580000056],
              [110.43825670800001, 8.146616329],
              [110.43872834900004, 8.14828568800001],
              [110.441639939, 8.150464167000047],
              [110.44572285600003, 8.151150568000086],
              [110.44918823900007, 8.150937066],
              [110.45233793500007, 8.149690560000076],
              [110.4546579340001, 8.147935472000077],
              [110.45507186500006, 8.146289204000111],
              [110.45422904100005, 8.14486341600009],
              [110.45194041900005, 8.144459492000065],
            ],
          ],
          [
            [
              [110.48687686700003, 8.162171308000053],
              [110.48153461600006, 8.161951679000088],
              [110.47814689800008, 8.162160914000081],
              [110.47731970200002, 8.163859788000048],
              [110.47787470600011, 8.167737972000031],
              [110.48060075600007, 8.16888785900005],
              [110.48636350400002, 8.168819146000049],
              [110.48992081500009, 8.168607749000055],
              [110.49109099300001, 8.167242511000071],
              [110.4911466400001, 8.164825878000046],
              [110.48687686700003, 8.162171308000053],
            ],
          ],
          [
            [
              [114.73080271300003, 8.168622404000113],
              [114.74106055100003, 8.163439572000096],
              [114.75575539000015, 8.166428485000042],
              [114.76829950200013, 8.167407242000111],
              [114.77647325300008, 8.162290366000134],
              [114.77601954100011, 8.148445170000061],
              [114.78345262500015, 8.141966345000105],
              [114.80341193000002, 8.135341901000091],
              [114.80922726400006, 8.121985620000059],
              [114.81028954100012, 8.112679264000098],
              [114.8079107340001, 8.103749],
              [114.80016113000008, 8.100537640000114],
              [114.787938983, 8.109251434000074],
              [114.77869686100003, 8.124104161000021],
              [114.76434432699999, 8.131500386000122],
              [114.74358415700011, 8.134252544000018],
              [114.72351772, 8.136980229000111],
              [114.70060628100011, 8.137718652000013],
              [114.68298055600005, 8.129969551000025],
              [114.67256657700001, 8.130303479000121],
              [114.66504118500006, 8.134009205000046],
              [114.64622882700004, 8.132531580000089],
              [114.63140480000008, 8.125381784000039],
              [114.62562897200011, 8.118636333000049],
              [114.62475733900011, 8.11312071000012],
              [114.62594617, 8.106846705000057],
              [114.64084051400009, 8.094593032000129],
              [114.65143004199999, 8.077069093000054],
              [114.65933870100004, 8.075465935000102],
              [114.66545135200008, 8.075945515000081],
              [114.6753134920001, 8.07714873000007],
              [114.68417147600009, 8.078721304000068],
              [114.69381420099998, 8.08098655400009],
              [114.70398235500011, 8.081166499000057],
              [114.70957677200008, 8.081324365000063],
              [114.71179823800003, 8.081928899000078],
              [114.71815637900012, 8.07952642100007],
              [114.72377497199996, 8.077823769000057],
              [114.72694019800011, 8.076200243000107],
              [114.73097879900004, 8.075393595000067],
              [114.73645338400004, 8.077075892000083],
              [114.73904627700009, 8.078682293000037],
              [114.742796051, 8.079406055000058],
              [114.74858167700008, 8.080232817000075],
              [114.75238664100004, 8.082644951000082],
              [114.76191500900006, 8.086561601000051],
              [114.76655519900012, 8.088608334000112],
              [114.77039370400003, 8.092033349000081],
              [114.776154443, 8.097255219000131],
              [114.77912507600001, 8.10003222800013],
              [114.78320977100013, 8.098039744000028],
              [114.787401155, 8.096719876000067],
              [114.78580626400003, 8.094574332000091],
              [114.77937408400011, 8.089543658000052],
              [114.77278960500011, 8.085024917000093],
              [114.76658247100008, 8.081676998000107],
              [114.75415039200004, 8.077009666000022],
              [114.74488189100011, 8.073253374000087],
              [114.73843623200005, 8.072954982000089],
              [114.73564123100007, 8.070340958000076],
              [114.72734559900007, 8.070440127000021],
              [114.71632419199996, 8.072655266000071],
              [114.70928948200002, 8.075079458000099],
              [114.70323145000006, 8.076288760000059],
              [114.69929627700007, 8.075063180000084],
              [114.69030183800007, 8.074507244000094],
              [114.68272373400012, 8.073229352000054],
              [114.67438437000014, 8.071975594000056],
              [114.66608321900003, 8.071903466000039],
              [114.66095314700013, 8.07037739000007],
              [114.65874283500003, 8.070110024000064],
              [114.65440598100011, 8.069547061000025],
              [114.65035632200015, 8.070014051000028],
              [114.64851151700009, 8.070579333000081],
              [114.63436291800005, 8.090838752000094],
              [114.62979437200015, 8.096222767000059],
              [114.62478840400001, 8.103817488000063],
              [114.62062954400008, 8.106146609000041],
              [114.61840325400009, 8.10572665500011],
              [114.59768075600005, 8.105090653000067],
              [114.58596129700008, 8.106644571000031],
              [114.57388593200008, 8.104998302000064],
              [114.56521016200006, 8.103751148000081],
              [114.55718010900002, 8.104173157000092],
              [114.54827650000007, 8.101073641000054],
              [114.53852735899997, 8.098000326000053],
              [114.53137454000003, 8.096703965000083],
              [114.52380423700001, 8.095589428000059],
              [114.51716440600005, 8.094445502],
              [114.51175128300008, 8.094614861000069],
              [114.50928296100004, 8.0968887960001],
              [114.51174692100007, 8.09985344100008],
              [114.51791242400012, 8.107433841000054],
              [114.52356552900005, 8.114861267000071],
              [114.52594530400006, 8.11782851800009],
              [114.53230091700003, 8.120671082000095],
              [114.53942786800005, 8.121123354000035],
              [114.54569290700009, 8.121095505000129],
              [114.55328999100001, 8.120349582000051],
              [114.55924877900011, 8.121344914000071],
              [114.56641453600005, 8.125681920000092],
              [114.56975416899998, 8.126854026000084],
              [114.57352991100001, 8.128424838000024],
              [114.57625299600008, 8.128845841000041],
              [114.57995361000012, 8.128052963000075],
              [114.58367031900006, 8.127766493000086],
              [114.58869503499997, 8.13132550000004],
              [114.59611554000003, 8.13565353600003],
              [114.60056840000004, 8.137202437000022],
              [114.60741530100009, 8.136816301000048],
              [114.6140408, 8.137450970000069],
              [114.61728066500011, 8.13769036500007],
              [114.63704215699995, 8.149455608],
              [114.66564364000008, 8.152700037000036],
              [114.69399988400008, 8.169808503000107],
              [114.70873941900007, 8.174185379000052],
              [114.73080271300003, 8.168622404000113],
            ],
          ],
          [
            [
              [116.60481173300001, 8.207294728000123],
              [116.6006167590001, 8.206919514000076],
              [116.59540786100006, 8.208282752000072],
              [116.59170711100001, 8.210249506000087],
              [116.58858499900006, 8.214314885000105],
              [116.58822144400008, 8.217446380000036],
              [116.58852180100007, 8.220714524000082],
              [116.5901228030001, 8.223600996000078],
              [116.59371568200002, 8.226897374000062],
              [116.6000606450001, 8.229095912000071],
              [116.60322295800009, 8.229949473000085],
              [116.6063307430001, 8.229493001000064],
              [116.61035600400008, 8.227358232000091],
              [116.61351882700004, 8.224274892000059],
              [116.61533321900005, 8.220427162000108],
              [116.61488181300001, 8.217493338000013],
              [116.612562001, 8.213160578000077],
              [116.60917817100005, 8.209410581000041],
              [116.60481173300001, 8.207294728000123],
            ],
          ],
          [
            [
              [113.35147328600011, 8.220775353000052],
              [113.34253711200006, 8.203147666000097],
              [113.33682075400012, 8.194066905000062],
              [113.32223353600003, 8.169749586000117],
              [113.30821119000004, 8.153961086000015],
              [113.3048584420001, 8.150116798000083],
              [113.28829344400006, 8.135246730000032],
              [113.27970254900005, 8.12743484300009],
              [113.26390120600004, 8.1087858770001],
              [113.24910123000011, 8.095748815000102],
              [113.24100081000009, 8.08689877400006],
              [113.22946050100003, 8.08078284200011],
              [113.22493860799999, 8.08165197600008],
              [113.22523763800004, 8.086440774],
              [113.22827155900006, 8.091318696000103],
              [113.23304698100004, 8.09734871400005],
              [113.24185476800005, 8.103704345000027],
              [113.24742151300003, 8.107151732000071],
              [113.25105839000004, 8.108768441000079],
              [113.25429473500003, 8.114837040000021],
              [113.25860581900012, 8.122757838],
              [113.26535311100008, 8.128823215000034],
              [113.27045101400009, 8.133990550000101],
              [113.27530269700007, 8.142922803000106],
              [113.28256251900007, 8.162301855000107],
              [113.290306653, 8.180473178000101],
              [113.29247445700005, 8.184860691000038],
              [113.29947036300007, 8.193824428000072],
              [113.30840716, 8.201627976000035],
              [113.31489070799999, 8.207272483000059],
              [113.32822749000002, 8.216244756],
              [113.33591934700006, 8.22219967600004],
              [113.34105220300003, 8.228561809000022],
              [113.34795114800011, 8.240261655000046],
              [113.35024896800007, 8.249601824000051],
              [113.3503556110001, 8.257033113000121],
              [113.34932252000006, 8.26490722600006],
              [113.35046113000013, 8.272888639000085],
              [113.35114074600004, 8.277435827000089],
              [113.35645554900006, 8.284135571000116],
              [113.35938620300007, 8.291409362000122],
              [113.36142447900014, 8.294006072000036],
              [113.36365545800008, 8.2941195600001],
              [113.36456785100007, 8.289652349000052],
              [113.36413508600006, 8.279579806000113],
              [113.36266598900011, 8.269192380000055],
              [113.36010684700001, 8.253108140000085],
              [113.3585288820001, 8.245116631000114],
              [113.35235265000004, 8.228271757000092],
              [113.35147328600011, 8.220775353000052],
            ],
            [
              [113.27616033600006, 8.134383367000096],
              [113.27764748300001, 8.131570017000021],
              [113.28948164200004, 8.14148429000001],
              [113.30637883800004, 8.15770905700006],
              [113.32634976300008, 8.180738919000062],
              [113.33460205000004, 8.198405379000027],
              [113.34440568500011, 8.215699323000079],
              [113.33821451399999, 8.217523801000047],
              [113.32772666499996, 8.208242248000095],
              [113.3115599460001, 8.194663562000047],
              [113.299921541, 8.183855932000126],
              [113.29004932600003, 8.16367613400004],
              [113.27616033600006, 8.134383367000096],
            ],
          ],
          [
            [
              [115.19961144500012, 8.383447810000087],
              [115.20661678600003, 8.375609601000098],
              [115.21043090300013, 8.370920097000044],
              [115.21319307800005, 8.367617347000065],
              [115.21685341600005, 8.365802642000107],
              [115.22362144900003, 8.365736894000085],
              [115.23253997900005, 8.36424593200009],
              [115.23928434300005, 8.363505316],
              [115.24469575500007, 8.360954098000127],
              [115.24911468300002, 8.361475576000109],
              [115.25394276100008, 8.364008269000054],
              [115.26281636600004, 8.366062286],
              [115.27003818400009, 8.364460052000043],
              [115.27502662400001, 8.361922776000073],
              [115.28120556900002, 8.357149467000101],
              [115.28006656400011, 8.353644602000097],
              [115.27734689300006, 8.348507059],
              [115.27278577000006, 8.343940216000066],
              [115.2667020480001, 8.341789463000014],
              [115.26145610300001, 8.341803574000027],
              [115.25531433000006, 8.345224540000105],
              [115.25185922900009, 8.348045453000095],
              [115.24566391600005, 8.349948909000114],
              [115.24104665600011, 8.348590320000058],
              [115.22899178200011, 8.347489843000043],
              [115.21642192900005, 8.348600762000034],
              [115.20506685600003, 8.348149810000026],
              [115.19780401400014, 8.348569642000124],
              [115.19465680200005, 8.350535032000026],
              [115.18919329800009, 8.356462497000015],
              [115.18018859200004, 8.365212662000053],
              [115.17139313499999, 8.375136966000072],
              [115.16983119400007, 8.378904262000029],
              [115.17159207200011, 8.380869255000077],
              [115.17775463000009, 8.382851466000128],
              [115.18442451899996, 8.384816009000049],
              [115.19262205300001, 8.386896443000056],
              [115.19632988200011, 8.386430806000076],
              [115.19961144500012, 8.383447810000087],
            ],
            [
              [115.19906721500003, 8.353252126000131],
              [115.20497787900004, 8.352879012000079],
              [115.21024763500006, 8.353540668000024],
              [115.21685212800003, 8.353649582000065],
              [115.22008752200011, 8.354212650000113],
              [115.221160689, 8.355863380000093],
              [115.21736096200003, 8.35852693000003],
              [115.20972591699997, 8.362842319000039],
              [115.20432941300012, 8.368261647000084],
              [115.19930011300002, 8.37451218800007],
              [115.19542109500006, 8.377346971000053],
              [115.18902598900002, 8.378411718000043],
              [115.18644896500004, 8.377319014000049],
              [115.18448961200011, 8.374517009000025],
              [115.18459419300005, 8.372656622000106],
              [115.18467532600002, 8.370121868000078],
              [115.19028837700002, 8.366045936000075],
              [115.19411678400006, 8.359330827000109],
              [115.19652922400009, 8.355702871000046],
              [115.19906721500003, 8.353252126000131],
            ],
          ],
          [
            [
              [114.59254458899997, 8.484320460000026],
              [114.58173005900002, 8.473159870000034],
              [114.57483851100002, 8.480774767000108],
              [114.5727215930001, 8.482511651000023],
              [114.57466554200001, 8.487354290000047],
              [114.585603859, 8.492464169000067],
              [114.58882694300004, 8.496633236000138],
              [114.59254458899997, 8.484320460000026],
            ],
          ],
          [
            [
              [111.91626688000005, 8.639972753000079],
              [111.91564843500005, 8.639896650000088],
              [111.91406590500003, 8.641206010000067],
              [111.91410945600008, 8.643503922000127],
              [111.91578278700007, 8.646026686000051],
              [111.91726736200006, 8.647616447000049],
              [111.91889071, 8.647500813000073],
              [111.92119017400009, 8.64694675300006],
              [111.92228097800002, 8.645819434000133],
              [111.92200661800005, 8.644888067000077],
              [111.92035421800003, 8.643471766000042],
              [111.91799829100006, 8.641047032000058],
              [111.91626688000005, 8.639972753000079],
            ],
          ],
          [
            [
              [111.69410420200006, 8.684573244000136],
              [111.6920681890001, 8.680773329000056],
              [111.68853152000003, 8.676786871000084],
              [111.680836615, 8.672319742000044],
              [111.67171672000011, 8.669497059],
              [111.66840923400008, 8.66874546600007],
              [111.66132795200011, 8.669892788000054],
              [111.65372886300001, 8.670793216000037],
              [111.649319996, 8.670657301000071],
              [111.64392070000009, 8.670155021000079],
              [111.64069737700004, 8.67174564200006],
              [111.64088648700005, 8.672765239000045],
              [111.64122572400005, 8.677447617000043],
              [111.64376941700002, 8.6785539640001],
              [111.64936560100004, 8.680501950000073],
              [111.65725489300007, 8.681471920000126],
              [111.66052053600001, 8.682267056000102],
              [111.66467000800006, 8.682236811000086],
              [111.66915142200003, 8.684033387000055],
              [111.67452321800005, 8.687774805000091],
              [111.67902458100008, 8.690679011000045],
              [111.68131200199998, 8.691789457000086],
              [111.68553865900003, 8.693675457000081],
              [111.68896046700009, 8.69361496700005],
              [111.69158037800005, 8.691778466000065],
              [111.69409030200006, 8.688579964],
              [111.69410420200006, 8.684573244000136],
            ],
          ],
          [
            [
              [114.19111512100005, 8.737614308000017],
              [114.19365015100001, 8.736295678000071],
              [114.1943252870001, 8.733176149000064],
              [114.19783647000008, 8.729529077000018],
              [114.19748877600001, 8.723569862000067],
              [114.19626067900001, 8.717805050000036],
              [114.19545243900009, 8.713373021000058],
              [114.19544145500008, 8.705974753000055],
              [114.19597237300005, 8.701166770000056],
              [114.19742393400006, 8.69750850300006],
              [114.19993449100005, 8.693986904],
              [114.20461450700012, 8.689644130000128],
              [114.20669644799997, 8.6873122600001],
              [114.207144285, 8.685365288000034],
              [114.20414020900003, 8.683689204000087],
              [114.19832605900001, 8.681171843000078],
              [114.19270804000004, 8.679261173000084],
              [114.188645529, 8.67212767200005],
              [114.18289860000003, 8.669019494000095],
              [114.17895751199998, 8.66703462200009],
              [114.17631433300005, 8.667533265000047],
              [114.17475121600003, 8.671698861],
              [114.17174571600005, 8.678429246000084],
              [114.16995759500007, 8.684955517000057],
              [114.16793597200004, 8.690732134000088],
              [114.16872243600001, 8.698695714000058],
              [114.16921030900005, 8.703725678000039],
              [114.17050682400013, 8.708983992000084],
              [114.17122767300009, 8.713334569000073],
              [114.16921284100009, 8.71793426700007],
              [114.16509009600011, 8.724025619000118],
              [114.16340734299997, 8.728447387000047],
              [114.16292984399999, 8.732245026000028],
              [114.16039062200004, 8.734842408000086],
              [114.15720461400004, 8.739813104000048],
              [114.15799207400009, 8.743573461000052],
              [114.16096996100011, 8.747185091000096],
              [114.16239421100008, 8.748236083000014],
              [114.16487182, 8.749225458000074],
              [114.16821545900008, 8.74811879700008],
              [114.17336188200011, 8.745195077000021],
              [114.1806141430001, 8.7421248780001],
              [114.18595405500005, 8.740034964000108],
              [114.19111512100005, 8.737614308000017],
            ],
            [
              [114.17781002700008, 8.678161321000031],
              [114.18202191200005, 8.674028093000084],
              [114.18867641300007, 8.679072508000017],
              [114.19937915900002, 8.68851833000006],
              [114.19639076900002, 8.694494385000072],
              [114.19121181900006, 8.711991008000018],
              [114.19375698100006, 8.724558521000068],
              [114.19089311600007, 8.732352908000072],
              [114.18172821499999, 8.736633657000057],
              [114.17423568000001, 8.734696929000039],
              [114.1718665620001, 8.727982790000064],
              [114.17572938900005, 8.712141659000048],
              [114.17757301300001, 8.701294480000053],
              [114.17702393300014, 8.693293557000102],
              [114.17586526500011, 8.685619168000079],
              [114.17781002700008, 8.678161321000031],
            ],
          ],
          [
            [
              [112.2171485380001, 8.826735184000077],
              [112.21464784600005, 8.825849062000122],
              [112.21243862700013, 8.826660024000075],
              [112.21119672799996, 8.828728693000086],
              [112.21094675300006, 8.830221328000041],
              [112.21363792500006, 8.830893310000041],
              [112.21673421300011, 8.83159711300001],
              [112.21917027400005, 8.833506334000061],
              [112.22106161200013, 8.83389383600006],
              [112.22336341299997, 8.833421507000059],
              [112.22392063100007, 8.831366611000123],
              [112.2221743450001, 8.829698955000074],
              [112.21973482100013, 8.827619548000063],
              [112.2171485380001, 8.826735184000077],
            ],
          ],
          [
            [
              [113.94297145000004, 8.835053756000109],
              [113.94599792499999, 8.832919945000038],
              [113.95014125, 8.8338256140001],
              [113.95806549400007, 8.834964532000099],
              [113.96769409500004, 8.835713141000044],
              [113.97863480500004, 8.834376121000085],
              [113.98518794000002, 8.835553307000053],
              [113.99470773100006, 8.83254986800006],
              [113.99776335600011, 8.8314382540001],
              [114.00728108700005, 8.829784710000064],
              [114.01595094700011, 8.825243176000084],
              [114.02100420200006, 8.820977385000093],
              [114.02515278700004, 8.821200948000028],
              [114.03037109800006, 8.822595192000014],
              [114.03379485400001, 8.821637855000029],
              [114.03589615300007, 8.816770413000084],
              [114.03755716400009, 8.808654133000092],
              [114.04030277399998, 8.802223187000012],
              [114.04365081900006, 8.798693015000046],
              [114.04862088800006, 8.797519022000076],
              [114.053235997, 8.796011890000075],
              [114.0569363620001, 8.792737513000047],
              [114.05965069, 8.785268854000082],
              [114.05940876600003, 8.777026897000082],
              [114.06082428700009, 8.7723457820001],
              [114.06244743000002, 8.768861621000033],
              [114.0616519700001, 8.76527590700009],
              [114.0545435680001, 8.764107574000056],
              [114.04745535299999, 8.763625798000073],
              [114.04050784600007, 8.767951438000042],
              [114.03151132100008, 8.773023547000046],
              [114.02476264900008, 8.772359313000065],
              [114.01768424000008, 8.772219928000078],
              [114.00988125000008, 8.770898236000056],
              [114.00297030600007, 8.770581686000066],
              [113.99851811700003, 8.77476999200004],
              [113.990441205, 8.780120475000073],
              [113.97927922300008, 8.785559143000031],
              [113.96400364700004, 8.791114901000084],
              [113.95187108500011, 8.798627362000032],
              [113.93868968900006, 8.805486581000109],
              [113.92922908900005, 8.810533105000038],
              [113.92414604700002, 8.812724980000029],
              [113.91936658100008, 8.813543143000045],
              [113.91396987300003, 8.816767436000104],
              [113.90823995600013, 8.820342293000037],
              [113.901422934, 8.821900243000068],
              [113.89810206400003, 8.825747946000105],
              [113.90131408800005, 8.83009346800012],
              [113.90451648500007, 8.834097994000077],
              [113.90844371600012, 8.83944704600008],
              [113.91128403600011, 8.842779261000098],
              [113.9164363670001, 8.842974495000087],
              [113.92977763700004, 8.841571948000125],
              [113.93858341400006, 8.837567553],
              [113.94297145000004, 8.835053756000109],
            ],
            [
              [114.00724982200005, 8.776579345000089],
              [114.01808020300007, 8.776112980000049],
              [114.03083546200003, 8.777903410000041],
              [114.04086062700004, 8.769200960000132],
              [114.05074066100003, 8.769925438000124],
              [114.05366361500003, 8.772099635000073],
              [114.04942765200011, 8.777981870000049],
              [114.04281555300005, 8.78495836000006],
              [114.03474515700013, 8.795061038000094],
              [114.02513490800007, 8.805516479000096],
              [114.00811954100007, 8.817110356000056],
              [114.00175171800005, 8.821920021000016],
              [113.98941094100012, 8.823816800000104],
              [113.97614993000006, 8.826047647000033],
              [113.94861815099998, 8.827759734000027],
              [113.93099917900012, 8.82934084400002],
              [113.92321368100015, 8.828019876000058],
              [113.921285062, 8.825607209000095],
              [113.92206369300003, 8.820341892],
              [113.93506796500007, 8.809177671000066],
              [113.98520203400003, 8.785539892000125],
              [114.00724982200005, 8.776579345000089],
            ],
          ],
          [
            [
              [112.57914968400007, 8.842562101000072],
              [112.579321573, 8.842558302000024],
              [112.5912070090001, 8.843355944000029],
              [112.59859459300003, 8.843022008000022],
              [112.60943327200002, 8.842383010000086],
              [112.6156022350001, 8.841390400000114],
              [112.62735193900008, 8.837321235000049],
              [112.63692762800011, 8.834882430000056],
              [112.64698319700007, 8.831718131000075],
              [112.65473365199999, 8.831128474000048],
              [112.66251759900003, 8.828263982000093],
              [112.66726438100011, 8.826310527000112],
              [112.67101082600003, 8.824685481000037],
              [112.67231609800002, 8.821573846000062],
              [112.667629078, 8.819623818000098],
              [112.65518481500011, 8.816819464000057],
              [112.64605467900007, 8.816749299000025],
              [112.63265650100004, 8.817389702000076],
              [112.62444267700006, 8.817076322000082],
              [112.61151032600002, 8.815478895000071],
              [112.59168877, 8.80784316900005],
              [112.58299154200007, 8.806248257000069],
              [112.57204409700005, 8.808263194000075],
              [112.563673889, 8.807088008000095],
              [112.558865919, 8.811431323000106],
              [112.55591972800005, 8.815367464000088],
              [112.5552943, 8.817659658000119],
              [112.55140131500002, 8.825451329000076],
              [112.54781186600003, 8.829119563000097],
              [112.5459652720001, 8.835067786000115],
              [112.54588385100011, 8.83917950900006],
              [112.54614244000001, 8.843112597000056],
              [112.54893799900005, 8.845106665000023],
              [112.55476731799999, 8.844294505000059],
              [112.56162418300005, 8.843288469000042],
              [112.57193397600008, 8.842891582000066],
              [112.57914968400007, 8.842562101000072],
            ],
          ],
          [
            [
              [114.66031648500002, 8.846453274000064],
              [114.66047115100002, 8.847044297000116],
              [114.66246949400008, 8.848020454000059],
              [114.66031648500002, 8.846453274000064],
            ],
          ],
          [
            [
              [112.85679542800003, 8.860097971000068],
              [112.86001732500007, 8.860097567000123],
              [112.85562876900005, 8.859095333000065],
              [112.85679542800003, 8.860097971000068],
            ],
          ],
          [
            [
              [114.66246949400008, 8.848020454000059],
              [114.66584424100007, 8.850476856000087],
              [114.67220234400007, 8.854658524000046],
              [114.67437677500004, 8.856444137000084],
              [114.67328525800005, 8.859346137000033],
              [114.67053072500005, 8.86187968400005],
              [114.66579136000004, 8.861946825000098],
              [114.65555175200005, 8.859490950000025],
              [114.6497664530001, 8.85731429500005],
              [114.64614893600002, 8.855405743000054],
              [114.64291371800003, 8.852220117000085],
              [114.64070707100007, 8.849423337000049],
              [114.63976537300003, 8.845152791000109],
              [114.63895056900003, 8.840878262000016],
              [114.64013139500003, 8.836793210000058],
              [114.64125308000003, 8.834818099000062],
              [114.64266640299999, 8.834014210000072],
              [114.64547624700005, 8.834514982000117],
              [114.64972883300001, 8.837752349000104],
              [114.6551981800001, 8.84196273300004],
              [114.66029531300005, 8.846437857000032],
              [114.66031648500002, 8.846453274000064],
              [114.65944612000013, 8.843127222000112],
              [114.65609344100002, 8.840086494000053],
              [114.65177413500012, 8.834229955000071],
              [114.64579493300003, 8.82995149],
              [114.64109642600012, 8.827318536000027],
              [114.63889375700003, 8.826802415000133],
              [114.63396376700007, 8.827964151000066],
              [114.63217351500009, 8.830210358000022],
              [114.63322814100005, 8.834050228000121],
              [114.63442221700008, 8.836959428000045],
              [114.63426853899998, 8.840079914000041],
              [114.63413769200007, 8.847861526000054],
              [114.63346789700007, 8.853363888000041],
              [114.63533990599998, 8.857604941000117],
              [114.64048471099996, 8.862247837000046],
              [114.6478224050001, 8.865302917000049],
              [114.65937720500003, 8.869150801000012],
              [114.66513028300005, 8.870316347000083],
              [114.67113862400008, 8.870208660000055],
              [114.67877864800002, 8.870807521000044],
              [114.68356321900004, 8.870822969000079],
              [114.68580938600007, 8.872448142000076],
              [114.68514632400002, 8.867662676000075],
              [114.68144099400003, 8.86262622],
              [114.676755007, 8.857800971000083],
              [114.67077388500002, 8.852076709000032],
              [114.66246949400008, 8.848020454000059],
            ],
          ],
          [
            [
              [112.85679542800003, 8.860097971000068],
              [112.8526193820001, 8.860098426000105],
              [112.83896988000005, 8.860284630000058],
              [112.83023237200008, 8.861857554000084],
              [112.82432447600003, 8.862055298000103],
              [112.81829125099999, 8.866299344000071],
              [112.81422816500007, 8.869134126000054],
              [112.81156462000004, 8.872964462000075],
              [112.81059669099999, 8.875727827000011],
              [112.81304413100007, 8.877384545000043],
              [112.81648745700014, 8.877476415],
              [112.82416592500014, 8.874729311000083],
              [112.82914888800005, 8.874442735000086],
              [112.83457151500011, 8.870890888000103],
              [112.84004214700005, 8.869393551000114],
              [112.84606473900008, 8.869425086000058],
              [112.84983577900009, 8.868823534000024],
              [112.85635007000005, 8.867815468000105],
              [112.859552772, 8.864999896000112],
              [112.85931632599998, 8.862264406000085],
              [112.85679542800003, 8.860097971000068],
            ],
          ],
          [
            [
              [112.23326717000008, 8.879443146000108],
              [112.23090791, 8.874992600000018],
              [112.22780686700001, 8.872815404000088],
              [112.22658667300004, 8.871883908000109],
              [112.22369908000005, 8.874899571000048],
              [112.21808710700013, 8.877962957000117],
              [112.210285848, 8.876844023000084],
              [112.19796611600012, 8.873506362000059],
              [112.18745143700001, 8.864394532000029],
              [112.18209618500012, 8.860916163000086],
              [112.18170115500003, 8.853274569000023],
              [112.18539267600011, 8.845790205000091],
              [112.19031832200007, 8.839954370000045],
              [112.19001033200007, 8.836614025000065],
              [112.18230574900005, 8.835692926000059],
              [112.17185782700004, 8.841638985000076],
              [112.17407330600012, 8.856176315000129],
              [112.19685050100009, 8.877712298000072],
              [112.21303461500005, 8.882047850000062],
              [112.21763145100009, 8.88362842400001],
              [112.23204973000006, 8.883814655000053],
              [112.23326717000008, 8.879443146000108],
            ],
          ],
          [
            [
              [112.25519483000009, 8.888004370000056],
              [112.25839121200006, 8.885300720000087],
              [112.26099043900001, 8.882694330000083],
              [112.262721872, 8.879509881000034],
              [112.26321172900012, 8.874223069000029],
              [112.26194388700003, 8.870844606000093],
              [112.25995193800006, 8.867622914000071],
              [112.25766644200004, 8.864687311000095],
              [112.25567408900005, 8.862277770000036],
              [112.25503359700008, 8.861503156000071],
              [112.25478636700005, 8.86126687400006],
              [112.25219664700001, 8.858791792000085],
              [112.249866158, 8.85781679600006],
              [112.24869146199997, 8.858948397000075],
              [112.24864975400007, 8.861079395000092],
              [112.25024201800011, 8.863603161000098],
              [112.2526111730001, 8.866451931000084],
              [112.25519487700001, 8.867251334000057],
              [112.25754307200006, 8.869078008000118],
              [112.25828888500004, 8.872045071000033],
              [112.26026013200007, 8.874262872000125],
              [112.26015761300002, 8.877588114000011],
              [112.25860786100004, 8.881283756000078],
              [112.25642761500004, 8.883458480000071],
              [112.25376541300004, 8.88508915800008],
              [112.24864317900006, 8.885704770000034],
              [112.24349989300003, 8.885298151000052],
              [112.240497022, 8.884933144000049],
              [112.23973061600009, 8.885119131000073],
              [112.23835800100002, 8.886802854000029],
              [112.24288302400007, 8.888595490000027],
              [112.24760739600006, 8.889435616000084],
              [112.25170316800003, 8.888841542000046],
              [112.25519483000009, 8.888004370000056],
            ],
          ],
          [
            [
              [116.30750851400001, 8.913082491000067],
              [116.31124568500005, 8.91072892000002],
              [116.31042914200009, 8.907369557000065],
              [116.30473590100011, 8.901321339000033],
              [116.29877762600006, 8.892909352000087],
              [116.29314947900005, 8.877870476000014],
              [116.28955203600003, 8.873095589000089],
              [116.28127289799997, 8.868337011000092],
              [116.27137204600004, 8.867712337000098],
              [116.26470732100006, 8.870793364000027],
              [116.26688254200009, 8.878904316000023],
              [116.27022678100005, 8.890118184000071],
              [116.27168749600003, 8.901068244000053],
              [116.27365771900006, 8.905608759000037],
              [116.27618081500009, 8.909070133000084],
              [116.28003711500004, 8.911800217000108],
              [116.28428805600005, 8.91163147000009],
              [116.289375576, 8.91109020400007],
              [116.29571459100011, 8.912025411000089],
              [116.30142120700012, 8.914172763000037],
              [116.30433912800005, 8.914735022000018],
              [116.30750851400001, 8.913082491000067],
            ],
            [
              [116.27645654700008, 8.87463303300011],
              [116.28049748700003, 8.874472677000076],
              [116.28501963800009, 8.876836847000023],
              [116.28874625299997, 8.880589225000078],
              [116.29319401400001, 8.889570056000105],
              [116.29777132900003, 8.897528293000077],
              [116.30059745200001, 8.902164406000066],
              [116.3011010070001, 8.90621458700007],
              [116.29519752100005, 8.90763654100004],
              [116.28802887600004, 8.90724307100008],
              [116.28047263300002, 8.905677591000048],
              [116.27824879800005, 8.90118687700001],
              [116.27735810500008, 8.895964946000044],
              [116.276079877, 8.889571326000032],
              [116.27427131300011, 8.882690083000087],
              [116.27371402200001, 8.877285544000111],
              [116.27645654700008, 8.87463303300011],
            ],
          ],
          [
            [
              [112.35042487899999, 8.929192961000075],
              [112.34750370900005, 8.928566902000055],
              [112.34600443100008, 8.928633648000071],
              [112.34493481000004, 8.929336546000053],
              [112.34391010800007, 8.930986705000075],
              [112.34382986600011, 8.932267457000085],
              [112.34460981000007, 8.933672429000071],
              [112.34620920600005, 8.934905781000067],
              [112.34807969800011, 8.935554342000083],
              [112.34881085700012, 8.93559543900002],
              [112.35069634800007, 8.935726343000015],
              [112.35215209700007, 8.935505],
              [112.35303002100007, 8.934776079000082],
              [112.35344536300015, 8.933372003000082],
              [112.35346361400006, 8.933310293000128],
              [112.35322011000008, 8.932231308000025],
              [112.35200853100001, 8.930391637000044],
              [112.3510229960001, 8.929645679000052],
              [112.35042487899999, 8.929192961000075],
            ],
          ],
          [
            [
              [113.70368704500004, 8.967374032000029],
              [113.69913429500002, 8.964922700000065],
              [113.69637823499997, 8.958816319000016],
              [113.69299916600008, 8.955130906000084],
              [113.68720248300008, 8.95133964200001],
              [113.68039076700011, 8.948262725000065],
              [113.67356620100006, 8.947847390000073],
              [113.66530726599999, 8.94858685100006],
              [113.65610182900002, 8.946260940000082],
              [113.64928135000004, 8.942839867000084],
              [113.64371447700005, 8.941101742000067],
              [113.64063033800004, 8.938695436],
              [113.63789618900003, 8.939627826000061],
              [113.63695114700009, 8.942915713000053],
              [113.63724536400001, 8.947372068000091],
              [113.63850811600004, 8.949398424000064],
              [113.63911336700002, 8.949455200000054],
              [113.64304114900003, 8.954322720000095],
              [113.64730915500002, 8.959009517000103],
              [113.65572360899999, 8.963926971000101],
              [113.66268892800005, 8.966310795000075],
              [113.66681641300015, 8.965855745000082],
              [113.67025440600004, 8.965419353000035],
              [113.67529084900002, 8.966654572],
              [113.683602421, 8.967800506000055],
              [113.6881077070001, 8.968535344000104],
              [113.69185022200004, 8.969757732000081],
              [113.69870072800002, 8.974207117000075],
              [113.70311922100004, 8.978035912000058],
              [113.70818944700015, 8.980473082000094],
              [113.71355229499997, 8.981013233000022],
              [113.72015515300006, 8.979641864000064],
              [113.71690484200006, 8.977487070000032],
              [113.71349659600006, 8.97277232800011],
              [113.7094180170001, 8.968762776000093],
              [113.70368704500004, 8.967374032000029],
            ],
          ],
          [
            [
              [116.68273338100008, 9.022418206000092],
              [116.67903858899999, 9.021729942000077],
              [116.67439394000009, 9.021923051000067],
              [116.66455389200011, 9.025980326000125],
              [116.65890076299999, 9.029021523000045],
              [116.6544794670001, 9.036221387000063],
              [116.65235723900001, 9.046132600000066],
              [116.65275646900001, 9.050606730000064],
              [116.65453973800003, 9.05277815400005],
              [116.65743744699998, 9.054622665000077],
              [116.66200680300004, 9.055976780000028],
              [116.66619335000004, 9.054931171000112],
              [116.67297415500003, 9.050188543000013],
              [116.67920767100011, 9.044175707000067],
              [116.68435605600004, 9.039190234000037],
              [116.68585657000003, 9.035567882000082],
              [116.68677182000008, 9.031475291000033],
              [116.685689916, 9.025663046000075],
              [116.68273338100008, 9.022418206000092],
            ],
          ],
          [
            [
              [116.49733577400002, 9.176183379000056],
              [116.49565858499999, 9.174802588000118],
              [116.49189847500008, 9.17398952400008],
              [116.48687081500012, 9.173871949000032],
              [116.48286659700001, 9.175000699000078],
              [116.48140418199998, 9.174899107000021],
              [116.480047548, 9.177369281000035],
              [116.48181723200005, 9.181000532000015],
              [116.48412157900012, 9.181872949000079],
              [116.489284717, 9.181341079000037],
              [116.49345740100003, 9.180366345000033],
              [116.49662636200003, 9.178627334000032],
              [116.49733577400002, 9.176183379000056],
            ],
          ],
          [
            [
              [113.66800250000006, 9.204995701000128],
              [113.66602317600007, 9.20420374400006],
              [113.66427663800006, 9.205688754000095],
              [113.662919007, 9.208939123000091],
              [113.66236543000002, 9.210476346000094],
              [113.66220229300002, 9.210734473000047],
              [113.66194794600003, 9.213870394000077],
              [113.66249539400012, 9.215208650000049],
              [113.66362428, 9.216023739000077],
              [113.66472996000009, 9.215993757000083],
              [113.667156995, 9.214490246000116],
              [113.6688371260001, 9.21368352800007],
              [113.66974016400003, 9.212475069000091],
              [113.67020165700002, 9.210686605000083],
              [113.66923963400009, 9.206653474000069],
              [113.66800250000006, 9.204995701000128],
            ],
          ],
          [
            [
              [115.93013774300005, 9.324954102000048],
              [115.93278616000006, 9.324010466000042],
              [115.93505803700009, 9.324297495000076],
              [115.93970878100005, 9.323980423000043],
              [115.94250367900011, 9.323172425000069],
              [115.94530394900008, 9.322504540000052],
              [115.94844044500007, 9.32322731300008],
              [115.95198403000008, 9.323513490000044],
              [115.95486001200001, 9.324807498000086],
              [115.95724239400013, 9.324295708000106],
              [115.96031400299998, 9.323336574000061],
              [115.96273997299998, 9.320296763000078],
              [115.96289762100005, 9.317062729000012],
              [115.96083033, 9.314755604000078],
              [115.95523051800002, 9.312442696000021],
              [115.9497011010001, 9.311951475000056],
              [115.94413389000007, 9.310479117000091],
              [115.93712277400004, 9.311868419000023],
              [115.93149547800012, 9.312503210000054],
              [115.92479431000005, 9.310933050000079],
              [115.91854880100004, 9.310187485000119],
              [115.9118747040001, 9.309317628000089],
              [115.90786566900012, 9.309780271000088],
              [115.90421894600001, 9.310480329000102],
              [115.90155443600008, 9.311002660000066],
              [115.89966526400009, 9.313322249000054],
              [115.90073356700012, 9.315389473000034],
              [115.90263229300001, 9.317003763000018],
              [115.90503623200004, 9.317053322000033],
              [115.90827594200005, 9.31679017800011],
              [115.91294271300001, 9.316894466000035],
              [115.91635584100005, 9.317467695000035],
              [115.9194373600001, 9.320442126000026],
              [115.92305638900005, 9.322693591000062],
              [115.925682423, 9.324842167000023],
              [115.93013774300005, 9.324954102000048],
            ],
          ],
          [
            [
              [115.44654707500001, 9.416191560000085],
              [115.44969350800002, 9.407773982000032],
              [115.45148730700011, 9.401397853000047],
              [115.45318813800006, 9.391183016000058],
              [115.45425815800004, 9.378795431000063],
              [115.45476817100005, 9.371276254000092],
              [115.45835018200005, 9.36602585100008],
              [115.46052517200006, 9.363844304000056],
              [115.46145975900004, 9.361707052000055],
              [115.46035980700006, 9.359276550000038],
              [115.45479695300004, 9.355541820000022],
              [115.44987711400002, 9.350503447000056],
              [115.44620163900002, 9.348073297000068],
              [115.44195242400008, 9.34758441300003],
              [115.43683395400005, 9.348498450000074],
              [115.43179432600007, 9.350324252000062],
              [115.42864462200005, 9.352265693000026],
              [115.42599731300004, 9.357939562000082],
              [115.4244491310001, 9.363482935000087],
              [115.42100131400002, 9.373759023000082],
              [115.41988939600004, 9.379927347000104],
              [115.42257107200005, 9.38541215500006],
              [115.42651349400003, 9.393962422000078],
              [115.42830260800008, 9.397649452000055],
              [115.43304912900007, 9.40296942500005],
              [115.4373003500001, 9.408581423000049],
              [115.43864368800004, 9.413931025000098],
              [115.44088941100004, 9.417510354000045],
              [115.44248806900006, 9.418459734000082],
              [115.44534671499999, 9.417351594000055],
              [115.44654707500001, 9.416191560000085],
            ],
            [
              [115.43765378900002, 9.352847578000121],
              [115.44319231100008, 9.352032732000025],
              [115.44847243100006, 9.354383049000068],
              [115.45451467400004, 9.359930285000038],
              [115.45481078500003, 9.363029803000076],
              [115.45241328100008, 9.36796343800008],
              [115.451987218, 9.370174021000109],
              [115.44991746900007, 9.373998296000073],
              [115.44976402200008, 9.377388367000082],
              [115.45095013900007, 9.383474975000093],
              [115.4501012290001, 9.387987629000081],
              [115.44792702400005, 9.395291919000036],
              [115.44689750000013, 9.399902579000051],
              [115.44598624900006, 9.405240894000054],
              [115.44412267200005, 9.40841764],
              [115.44063355800002, 9.407352703000081],
              [115.43873576000011, 9.403212174000062],
              [115.43365077300011, 9.396166253000081],
              [115.43044037200005, 9.391340613000041],
              [115.42678666300007, 9.383146094000059],
              [115.42545714400002, 9.379442828000071],
              [115.42654584700004, 9.372634969000069],
              [115.42924863300003, 9.363391513000073],
              [115.43287050600011, 9.357957280000038],
              [115.43765378900002, 9.352847578000121],
            ],
          ],
          [
            [
              [116.92752633000013, 9.440742045000137],
              [116.92602867700012, 9.440524246000058],
              [116.92446266000005, 9.442000100000046],
              [116.92175685300006, 9.444158664000034],
              [116.91968883400003, 9.447135349000034],
              [116.91870619000008, 9.450629289000021],
              [116.91985068300009, 9.453327961000111],
              [116.92289727200001, 9.456579471000024],
              [116.92543963000006, 9.459641140000128],
              [116.928568141, 9.4623255070001],
              [116.93137899200005, 9.463403045000083],
              [116.9342307070001, 9.462153920000025],
              [116.93642129300007, 9.46036942600008],
              [116.93756595000001, 9.458981899000028],
              [116.93759151700009, 9.458754482000041],
              [116.93729630599999, 9.456034482000051],
              [116.93639001600006, 9.453114355000112],
              [116.93596977100002, 9.449962101000063],
              [116.93591057600008, 9.447780708000051],
              [116.93385364499999, 9.446107447000072],
              [116.93121558900012, 9.444106785000093],
              [116.92935875200013, 9.442143139000068],
              [116.92752633000013, 9.440742045000137],
            ],
          ],
          [
            [
              [114.02046010800008, 9.540737213000037],
              [114.0222357990001, 9.540006532000065],
              [114.02332249900003, 9.539125788000112],
              [114.02372022400007, 9.53809501100009],
              [114.02367554600002, 9.536567333000077],
              [114.02242892600009, 9.534904397000064],
              [114.02040042900002, 9.53283926600008],
              [114.01908074700007, 9.531603135000031],
              [114.01751946600004, 9.530883596000093],
              [114.01661616800008, 9.532183736000109],
              [114.01550841700009, 9.535273517000052],
              [114.01496938900007, 9.537327660000104],
              [114.01467942500007, 9.539119802000061],
              [114.01617013600004, 9.540351057000061],
              [114.01753816399999, 9.54031168800009],
              [114.01935106800006, 9.540854090000027],
              [114.02046010800008, 9.540737213000037],
            ],
          ],
          [
            [
              [116.16922715400008, 9.59526648100004],
              [116.166492146, 9.594247326000092],
              [116.16366013800007, 9.594357942],
              [116.16141682100009, 9.595008532000103],
              [116.15980671500009, 9.597323429000058],
              [116.16051235900012, 9.600814711000092],
              [116.16165398799998, 9.604570516000075],
              [116.16461429700006, 9.607692282000089],
              [116.1685436800001, 9.610213148000034],
              [116.17206097400006, 9.611367631000077],
              [116.17316105400005, 9.608625130000091],
              [116.17258562599996, 9.604847218000055],
              [116.17198231600004, 9.600366661000058],
              [116.17098769700009, 9.596745957000062],
              [116.16922715400008, 9.59526648100004],
            ],
          ],
          [
            [
              [113.05622312900009, 9.683926350000059],
              [113.05262803700008, 9.679289831000036],
              [113.04490722800003, 9.674331126000089],
              [113.03529526300011, 9.668405596000083],
              [113.02750610500009, 9.662351558000102],
              [113.0200886790001, 9.658938477000055],
              [113.01145464899999, 9.649530597000043],
              [113.00315894399998, 9.642187454000071],
              [112.99860386400003, 9.636392627000044],
              [112.98825899200003, 9.628472314000101],
              [112.97945061499998, 9.622272643000061],
              [112.96891263400008, 9.616951261000038],
              [112.95668920500007, 9.609053289000078],
              [112.94826647200003, 9.604698744000116],
              [112.94469613100003, 9.603442698000018],
              [112.9397622490001, 9.602984512000122],
              [112.93363957300004, 9.603526355000012],
              [112.92905376800003, 9.603237197000068],
              [112.92433686100001, 9.601384314000059],
              [112.917322711, 9.597047043000105],
              [112.91236764100009, 9.593366249000022],
              [112.90545724500012, 9.588299970000056],
              [112.90095969000006, 9.584786144000111],
              [112.90029731800006, 9.584271132000042],
              [112.89416146300005, 9.576259248000072],
              [112.8891942270001, 9.573591413000047],
              [112.88096624600011, 9.573706559000071],
              [112.87905411200009, 9.575078128000046],
              [112.87897405500004, 9.577810088000136],
              [112.88157503100004, 9.582508808000066],
              [112.88741001700009, 9.588039236000066],
              [112.893302077, 9.593129918],
              [112.89675206900009, 9.596294265000081],
              [112.89857322200012, 9.596792843000033],
              [112.90037071700009, 9.598441425000102],
              [112.90291494500001, 9.601923736000105],
              [112.90519050600008, 9.605059378000112],
              [112.9080460990001, 9.60944701800006],
              [112.91165965200004, 9.615253864000104],
              [112.91420552700001, 9.618786561000102],
              [112.91661019700011, 9.622466289],
              [112.91961785900001, 9.62713776100008],
              [112.92159743800002, 9.631016190000084],
              [112.923544515, 9.634343851000081],
              [112.92610756000006, 9.638317569000064],
              [112.9293272030001, 9.645590933000035],
              [112.93206322600008, 9.650987382000052],
              [112.93630881, 9.658935053000038],
              [112.94260196400005, 9.667696675000046],
              [112.94824068600003, 9.673311969000052],
              [112.95794687700004, 9.679405674000028],
              [112.96282240100003, 9.683929812000079],
              [112.97418636200007, 9.692613488000033],
              [112.97754301200006, 9.684292228000045],
              [112.96106332800005, 9.668695992000041],
              [112.92243469000002, 9.616510072000038],
              [112.92855078300008, 9.607802241000039],
              [112.94123305700012, 9.609215737000019],
              [112.96335670600001, 9.620681278000108],
              [112.9991155820001, 9.648947937000061],
              [113.02840833500014, 9.671084821000074],
              [113.02631815400002, 9.679699950000041],
              [113.02654101200008, 9.68883062000012],
              [113.02886817300008, 9.689916704000011],
              [113.03614549200002, 9.681747580000087],
              [113.04138854400004, 9.684476296000087],
              [113.0490289320001, 9.691144101000047],
              [113.054201787, 9.7144311410001],
              [113.05245552700002, 9.716987954000087],
              [113.05560007300005, 9.720802117000103],
              [113.0593222120001, 9.723664458000057],
              [113.06139241200005, 9.724964035000115],
              [113.06402567700006, 9.724984653000133],
              [113.06655301800001, 9.724695916000046],
              [113.06701390900004, 9.723900057000011],
              [113.06807975000002, 9.718897444000072],
              [113.06811047200009, 9.713245137000024],
              [113.06722723500002, 9.706687182000094],
              [113.06632650000009, 9.701141908000109],
              [113.06204671900001, 9.694581965000014],
              [113.05843816100004, 9.689247990000103],
              [113.05622312900009, 9.683926350000059],
            ],
          ],
          [
            [
              [114.28251018600008, 9.719765543000046],
              [114.28493793900013, 9.718510132000089],
              [114.28805314700004, 9.720274693000073],
              [114.28932764300006, 9.723023661000061],
              [114.29054029400004, 9.723747371000078],
              [114.2916626990001, 9.721517564000058],
              [114.28994263399997, 9.715319044000113],
              [114.28780047900001, 9.709217723000039],
              [114.28426961600007, 9.703580493000084],
              [114.27991133, 9.700079605000054],
              [114.27565995600003, 9.698686924000109],
              [114.27156163400004, 9.700921356000068],
              [114.27038501000003, 9.704166114000133],
              [114.27078894400006, 9.709052734000066],
              [114.27071926699998, 9.712348823000115],
              [114.27056842700001, 9.717167677000036],
              [114.27077417599999, 9.72113126300007],
              [114.27133264800001, 9.725506617000066],
              [114.27147023900002, 9.72862765700002],
              [114.27275742000003, 9.731798710000115],
              [114.27575167300007, 9.735171962000065],
              [114.27818475200003, 9.73687272700001],
              [114.27992011900008, 9.738003163000107],
              [114.28257636200013, 9.737838130000041],
              [114.28167377300005, 9.735551892000059],
              [114.280299011, 9.732302851000084],
              [114.27841450200003, 9.729069121000043],
              [114.27901194500009, 9.72635146500007],
              [114.28100740700008, 9.722073268000118],
              [114.28251018600008, 9.719765543000046],
            ],
          ],
          [
            [
              [114.31096204299999, 9.734948078000103],
              [114.30907417900002, 9.734414275000079],
              [114.30809307100002, 9.735709748000064],
              [114.30893807700012, 9.7383004310001],
              [114.30970328000002, 9.741062308],
              [114.31156824900005, 9.743622232000069],
              [114.31257815800004, 9.74603917600003],
              [114.31341889200004, 9.746648098000053],
              [114.31505332300001, 9.74630205100002],
              [114.31727572200013, 9.743871919000034],
              [114.31772875100005, 9.742001586000091],
              [114.31688620600005, 9.739495236000016],
              [114.3146324600001, 9.738128614000095],
              [114.31252804300006, 9.73608231500007],
              [114.31096204299999, 9.734948078000103],
            ],
          ],
          [
            [
              [116.53905680900004, 9.741782727000013],
              [116.54640510899999, 9.74105979000006],
              [116.55331603099999, 9.741763727000128],
              [116.56031539300004, 9.742886592000042],
              [116.56681960700004, 9.742338406000071],
              [116.57368913100001, 9.743747988000083],
              [116.57776135499996, 9.744426563],
              [116.58126157400002, 9.743296501000119],
              [116.58487406499997, 9.741457131000074],
              [116.58976204300009, 9.736183164000066],
              [116.59279600900004, 9.734085606000063],
              [116.59674440400006, 9.733500564000062],
              [116.60066338500005, 9.73221205200012],
              [116.60342003100004, 9.728575608000066],
              [116.60667231700003, 9.724918752000098],
              [116.60991270100008, 9.72098051400012],
              [116.61196695800005, 9.715822827000052],
              [116.61192593400004, 9.713147082000083],
              [116.61055123600008, 9.710808085000087],
              [116.60720556800001, 9.708832056000103],
              [116.60373598800008, 9.70728384900008],
              [116.60035461200007, 9.707845697000026],
              [116.59507858499998, 9.710599123000028],
              [116.58885796000008, 9.714518554000049],
              [116.58480347700008, 9.715953304000012],
              [116.57820588100006, 9.715942217000071],
              [116.57234039400005, 9.71477356300001],
              [116.56973831500014, 9.713611971000089],
              [116.56570715000011, 9.715609049000038],
              [116.56315074699998, 9.718954854000026],
              [116.56154476300006, 9.721275304000086],
              [116.55834563300004, 9.722815438000064],
              [116.554108219, 9.723270650000069],
              [116.5508440230001, 9.724954270000106],
              [116.54864207400007, 9.726594396000097],
              [116.54475792500004, 9.728726019000076],
              [116.53942807200012, 9.730212007000104],
              [116.53648275500005, 9.731036860000051],
              [116.53377986600006, 9.73086533600007],
              [116.5301146410001, 9.731437632000082],
              [116.52668022600007, 9.730732160000065],
              [116.52358828900003, 9.729730854000083],
              [116.52148111500003, 9.730239483000068],
              [116.51968052000009, 9.731299283000093],
              [116.51827572400012, 9.73163834600008],
              [116.51745688900004, 9.728994225000063],
              [116.51531093000004, 9.725135887000123],
              [116.51280414500002, 9.722842339000069],
              [116.51114140700011, 9.720373508000078],
              [116.508192364, 9.717675178000105],
              [116.50673160800001, 9.713225304000071],
              [116.50580148400006, 9.709599361000086],
              [116.50409826400009, 9.706145793000063],
              [116.50133182, 9.704426412000087],
              [116.49657105800007, 9.702506225000105],
              [116.49385697600005, 9.702052832000073],
              [116.491354148, 9.70328178000006],
              [116.48379913800008, 9.705843021000058],
              [116.48066342900006, 9.708929391000075],
              [116.47683684099999, 9.712466458000078],
              [116.473523215, 9.71640545200003],
              [116.46790138199999, 9.721142461000019],
              [116.46612096500003, 9.726146632000086],
              [116.46235165499999, 9.73109039700004],
              [116.45879463500003, 9.736025554000081],
              [116.45710349600009, 9.739757862000074],
              [116.45620441600012, 9.742048952000102],
              [116.45143720600004, 9.746892063000077],
              [116.44698503200007, 9.749044846000031],
              [116.44631469100003, 9.750774010000017],
              [116.44677989, 9.751991027000083],
              [116.44805737700007, 9.752763303000032],
              [116.44991449900007, 9.752482319000054],
              [116.45204674700001, 9.751271773000079],
              [116.45513436000013, 9.75044235100002],
              [116.45602191900002, 9.747869853000067],
              [116.45983795800011, 9.744051563000065],
              [116.46220559000008, 9.742969320000048],
              [116.46427971500009, 9.739926042000134],
              [116.46533468399998, 9.734528373000016],
              [116.46694764200004, 9.732349289000107],
              [116.46811247800005, 9.729624672000091],
              [116.47038148300012, 9.726150755000072],
              [116.47148599200003, 9.72550666400007],
              [116.47414373900001, 9.722862472000047],
              [116.47966695500013, 9.715733721000051],
              [116.48463821400004, 9.712431955000065],
              [116.48886634400003, 9.708314778000025],
              [116.4955461110001, 9.708607161000058],
              [116.49888489500003, 9.710444287000096],
              [116.5028821640001, 9.716200218000013],
              [116.50853755600005, 9.722593268000066],
              [116.51495874300012, 9.732055316000031],
              [116.51615980500002, 9.737079559000071],
              [116.51673028200005, 9.740579373000101],
              [116.51427172600003, 9.746316405000053],
              [116.51310983200008, 9.751314772000084],
              [116.51367750500006, 9.75499915700002],
              [116.51504032300012, 9.757827288000074],
              [116.51913501700008, 9.75642470300002],
              [116.52436228700003, 9.750133006000093],
              [116.52888536600008, 9.746284564000083],
              [116.53388573800011, 9.743684882000101],
              [116.53905680900004, 9.741782727000013],
            ],
          ],
          [
            [
              [114.32703225900003, 9.74992208700005],
              [114.32574435299998, 9.749538572000025],
              [114.32430668100002, 9.751907216000076],
              [114.32471263300013, 9.754723832000066],
              [114.32452765800011, 9.757010747000015],
              [114.32527768600008, 9.759269412000025],
              [114.32616139700011, 9.760341109000057],
              [114.32769398399999, 9.760379247000104],
              [114.32865949000004, 9.75857567000009],
              [114.32904295400013, 9.757212160000071],
              [114.33000842300008, 9.755408586000057],
              [114.33028871500009, 9.753456767000033],
              [114.32793670500008, 9.75166907700009],
              [114.32703225900003, 9.74992208700005],
            ],
          ],
          [
            [
              [116.44134588200005, 9.749001227000063],
              [116.43942839500002, 9.7484960730001],
              [116.43828517200002, 9.748900063000042],
              [116.43579298400005, 9.750409823000082],
              [116.43461606200002, 9.75285298200008],
              [116.42977558700008, 9.759389716000031],
              [116.42955413100006, 9.760187197000024],
              [116.42981195399999, 9.761412636000076],
              [116.43088213500008, 9.762193385000016],
              [116.43335801100002, 9.766219786000095],
              [116.43503538500008, 9.765558725000021],
              [116.43605339600002, 9.762699141000029],
              [116.44000597500012, 9.758734685000102],
              [116.44332355800007, 9.758318937000075],
              [116.44697014800008, 9.755820059000085],
              [116.44449413100003, 9.751793903000115],
              [116.44327891400012, 9.75057094300009],
              [116.44134588200005, 9.749001227000063],
            ],
          ],
          [
            [
              [114.34045263800013, 9.758894518000092],
              [114.339231453, 9.757917595],
              [114.33671527099999, 9.759092248000021],
              [114.33544102499998, 9.761919161000064],
              [114.334775666, 9.763798182000103],
              [114.33437050700005, 9.765838340000064],
              [114.33523611699999, 9.766319093000051],
              [114.33681477600014, 9.765088347000093],
              [114.33847581799999, 9.763770599000042],
              [114.34081943700002, 9.762516659000118],
              [114.34162003800009, 9.760886992000016],
              [114.34045263800013, 9.758894518000092],
            ],
          ],
          [
            [
              [116.51058201100001, 9.760480438000039],
              [116.50956579800004, 9.755990380000055],
              [116.50486856899997, 9.757409294000039],
              [116.50203314800001, 9.760906817000057],
              [116.49715394400008, 9.766460357000105],
              [116.49440652800004, 9.770377038000076],
              [116.49400840800004, 9.771452964000014],
              [116.49649603500009, 9.771351920000091],
              [116.50033455500008, 9.767881258000076],
              [116.50798096000008, 9.764047141],
              [116.51058201100001, 9.760480438000039],
            ],
          ],
          [
            [
              [114.34770016200001, 9.764927324],
              [114.34672611300006, 9.763689452000078],
              [114.34537911200007, 9.764152794000045],
              [114.34444754800009, 9.767053875],
              [114.34400518800008, 9.769264181000013],
              [114.34338758800006, 9.771310803000064],
              [114.34417083900011, 9.771878529000054],
              [114.3460331250001, 9.771568556000075],
              [114.34752416200001, 9.770255877000087],
              [114.34815731900008, 9.76871574800011],
              [114.34809484299998, 9.766689757000078],
              [114.34770016200001, 9.764927324],
            ],
          ],
          [
            [
              [114.25822047500002, 9.759739376000097],
              [114.25668622400008, 9.759616375000045],
              [114.25486343, 9.761190894000025],
              [114.25315120000008, 9.763606536000056],
              [114.25158834, 9.765342142000042],
              [114.24926085600006, 9.767100590000053],
              [114.2481406310001, 9.769414087000071],
              [114.24895089100008, 9.773696640000116],
              [114.25016186500008, 9.775771630000028],
              [114.25151566700001, 9.778349041000032],
              [114.25232568200006, 9.779037241000042],
              [114.25447794300007, 9.777888163000091],
              [114.25569085900005, 9.775827509000033],
              [114.25728498300006, 9.772321535000026],
              [114.25932178400001, 9.769392762000082],
              [114.26113184600003, 9.767398591000108],
              [114.26200224900002, 9.765263853000048],
              [114.26211319700005, 9.763320545000019],
              [114.26031831800005, 9.761618833000084],
              [114.25822047500002, 9.759739376000097],
            ],
          ],
          [
            [
              [116.49119100300008, 9.77362713800005],
              [116.49027827200005, 9.77350417800009],
              [116.48696514100001, 9.775752601000052],
              [116.48283665500003, 9.778879625000057],
              [116.48277235400012, 9.780972044000079],
              [116.4865548710001, 9.782054485000023],
              [116.49084707900013, 9.776972264000071],
              [116.49247179800001, 9.775076325000052],
              [116.49119100300008, 9.77362713800005],
            ],
          ],
          [
            [
              [114.38444711900006, 9.782246521000014],
              [114.38190524299998, 9.779872931000087],
              [114.37929148600007, 9.777924117000046],
              [114.37610611200003, 9.77675339800008],
              [114.37377105900009, 9.775556731000011],
              [114.36898847500007, 9.775026216000081],
              [114.36233265500003, 9.774468049000083],
              [114.36088976100011, 9.774596448000049],
              [114.36043698100003, 9.776469678000053],
              [114.36092999000006, 9.778652256000074],
              [114.3610463270001, 9.779662979000124],
              [114.36285678400006, 9.781787272000118],
              [114.36454020300008, 9.783931103000041],
              [114.36558408900012, 9.784659159000062],
              [114.36648033000002, 9.783365519000052],
              [114.36787631100006, 9.781718968000062],
              [114.36939907900002, 9.781419343000051],
              [114.37116648000006, 9.780774557000081],
              [114.37534872500007, 9.781153695000098],
              [114.37918817400013, 9.782829548000025],
              [114.38161622100003, 9.784276910000054],
              [114.38350529300011, 9.784810937000097],
              [114.38475459900009, 9.783927589000106],
              [114.38444711900006, 9.782246521000014],
            ],
          ],
          [
            [
              [116.42854513699997, 9.797704461000121],
              [116.43262536200007, 9.796835345000064],
              [116.43566310600013, 9.79688406900001],
              [116.4392940140001, 9.797701132000023],
              [116.43995465200013, 9.793554796000057],
              [116.43821767700004, 9.790515864000058],
              [116.43541604800012, 9.787951148000039],
              [116.43243821400003, 9.784547924],
              [116.42872325900005, 9.780469743000085],
              [116.42448781100009, 9.777539921000054],
              [116.4241658740001, 9.774875243000023],
              [116.4258866990001, 9.771846433000063],
              [116.42953564400004, 9.770853843000092],
              [116.42991658600012, 9.769036175000066],
              [116.42838966400008, 9.767243956000103],
              [116.42659963000003, 9.765791638000019],
              [116.42056362300002, 9.769135123000082],
              [116.41813437200003, 9.772192384000087],
              [116.41755259000003, 9.775316221000095],
              [116.41782088100003, 9.778405864000115],
              [116.41839556500005, 9.782046907000018],
              [116.416961883, 9.786896257000064],
              [116.41528107400002, 9.790909974000071],
              [116.4160204560001, 9.795108214000068],
              [116.41764236100008, 9.796593227000104],
              [116.42023310800003, 9.797475489000094],
              [116.42471944799998, 9.797858582000083],
              [116.42854513699997, 9.797704461000121],
            ],
          ],
          [
            [
              [114.396449788, 9.789148881000108],
              [114.39432127600001, 9.789129433000097],
              [114.3926971770001, 9.790277865000084],
              [114.39163835000006, 9.793184038000023],
              [114.39103408800008, 9.795653699000082],
              [114.38955321400012, 9.797304910000014],
              [114.38991057400003, 9.80059047800008],
              [114.39163704300005, 9.802735399000094],
              [114.39363495200007, 9.804026771000094],
              [114.39669382300012, 9.803848748000108],
              [114.40037786600011, 9.801876504000083],
              [114.40236892700001, 9.800209608],
              [114.40446076099998, 9.799046756000072],
              [114.40584861199997, 9.797144708000081],
              [114.40553567500001, 9.795294742000047],
              [114.40334931600003, 9.793417597000074],
              [114.40131731100004, 9.791028579000026],
              [114.39812897500011, 9.789773729000027],
              [114.396449788, 9.789148881000108],
            ],
          ],
          [
            [
              [115.89200243600014, 9.797680068000057],
              [115.89288446000002, 9.794044462000072],
              [115.892717834, 9.790590896000049],
              [115.89177367100004, 9.786686535000031],
              [115.89128093900004, 9.784403043000099],
              [115.88873986000011, 9.775844899000051],
              [115.88616409100007, 9.77049194200008],
              [115.88449437800003, 9.765108067000126],
              [115.8823622460001, 9.757199833000117],
              [115.87987886300004, 9.749668056000081],
              [115.87810566200007, 9.744486545000084],
              [115.87710520000006, 9.734546836000034],
              [115.87455918200008, 9.729456111000088],
              [115.87188233400002, 9.72324632000001],
              [115.87031363900007, 9.719561291000021],
              [115.86906553000001, 9.715616579000129],
              [115.86657005000004, 9.711339461000089],
              [115.86467410200011, 9.70598413500001],
              [115.86258503400009, 9.699168168000048],
              [115.85966463300005, 9.695719628000086],
              [115.85771637300004, 9.689257327000076],
              [115.85468632399999, 9.683861928000058],
              [115.84694984600007, 9.667357857000079],
              [115.84571254800001, 9.667629643000096],
              [115.84912501000009, 9.688218871000041],
              [115.85015389800006, 9.694377713000078],
              [115.84976171800011, 9.701942147000063],
              [115.85025603100007, 9.705980207000041],
              [115.85225656800003, 9.71232813400009],
              [115.85254416400002, 9.719867309000028],
              [115.85361279800007, 9.725574247000067],
              [115.85458845399998, 9.729224565000026],
              [115.85475529200001, 9.731522264000068],
              [115.85359668, 9.738327174000093],
              [115.85355305400002, 9.746311497000091],
              [115.85169730500003, 9.752691974000081],
              [115.85263402699999, 9.754700614000099],
              [115.85195314300006, 9.757876033000022],
              [115.85176690800002, 9.763405091],
              [115.84983749500007, 9.765751401000065],
              [115.84720372600012, 9.768104163000134],
              [115.84654830800007, 9.770916473000034],
              [115.84618512300003, 9.773296747000069],
              [115.84824372100002, 9.778178235000022],
              [115.85174044100005, 9.783569284000036],
              [115.85451444300007, 9.787860451000084],
              [115.85682574900008, 9.791161714000028],
              [115.85812233500008, 9.795395697000052],
              [115.86131933500009, 9.800114605000053],
              [115.86490395900003, 9.80266997700001],
              [115.86862106800001, 9.804896987000065],
              [115.87087979100011, 9.806565538000099],
              [115.87611918000002, 9.807044573000033],
              [115.88401461000004, 9.804946059000129],
              [115.88778011500001, 9.803113119000082],
              [115.88942465900001, 9.801492229000084],
              [115.89200243600014, 9.797680068000057],
            ],
            [
              [115.85537582000011, 9.705559107000015],
              [115.85582629300008, 9.702499731000012],
              [115.85730324100007, 9.702556922000037],
              [115.85981611100006, 9.704490799000086],
              [115.86149659600001, 9.706906683000048],
              [115.86286734800001, 9.710123007000069],
              [115.86442881100007, 9.715360523000081],
              [115.86518947400012, 9.718937886000044],
              [115.86637248900004, 9.723175505000102],
              [115.86904128800005, 9.727695343000063],
              [115.87186721800001, 9.731871186000072],
              [115.87328566000005, 9.736325404000111],
              [115.8743298090001, 9.739892209000088],
              [115.87480679500005, 9.744945389000035],
              [115.87524436100001, 9.750450876000103],
              [115.87691546100008, 9.755571875000067],
              [115.87869928200007, 9.76215371600002],
              [115.88055841700006, 9.766253461000039],
              [115.88224268000015, 9.771712174000056],
              [115.88327793100009, 9.776519193000064],
              [115.88534666900003, 9.780160311000062],
              [115.88582462900004, 9.783748700000048],
              [115.88503444000003, 9.786821408],
              [115.88308014900008, 9.790614213000113],
              [115.87990565000004, 9.793664096000041],
              [115.87588172900008, 9.795280765000046],
              [115.87132193400011, 9.796241359000122],
              [115.86865504400006, 9.794763865000087],
              [115.86530897500006, 9.79184674100013],
              [115.86264804399998, 9.787551407000093],
              [115.85982072300008, 9.784840144000027],
              [115.85681652400002, 9.778979922000096],
              [115.8538202650001, 9.774809891000034],
              [115.8520663310001, 9.770480536000113],
              [115.85311362900006, 9.768187267000082],
              [115.85683404300005, 9.763088913000038],
              [115.85745110200004, 9.758445217000132],
              [115.85798059800007, 9.754481021000048],
              [115.85775639000008, 9.750094384000095],
              [115.85635109200007, 9.745977550000083],
              [115.85643595600007, 9.742255522000052],
              [115.85766671800003, 9.738828547000098],
              [115.8588107060001, 9.734616021000074],
              [115.86026524400009, 9.7281380570001],
              [115.85977655100007, 9.721282450000055],
              [115.85825301400004, 9.718522527000049],
              [115.85639906600012, 9.714535472000117],
              [115.85582138000002, 9.711289286000111],
              [115.85537582000011, 9.705559107000015],
            ],
          ],
          [
            [
              [116.47950832100005, 9.797984671000036],
              [116.48107210400003, 9.790104205000104],
              [116.47924406500013, 9.786058657000039],
              [116.475150544, 9.786942468000069],
              [116.47056373900001, 9.789091215000044],
              [116.465157683, 9.792420732000016],
              [116.45673160800004, 9.794593773000047],
              [116.45121726800004, 9.795098511000065],
              [116.44762074400001, 9.794753607000038],
              [116.44349104199999, 9.795332317000048],
              [116.44272922000002, 9.797010946000027],
              [116.44570980900008, 9.80095830500005],
              [116.44969153300011, 9.804602777000058],
              [116.45356209300007, 9.807265139000075],
              [116.45859669299999, 9.808893821000096],
              [116.46314263400006, 9.808991815000033],
              [116.46736464500009, 9.808116271000065],
              [116.47135398800003, 9.806761498000059],
              [116.47631035000005, 9.803041352],
              [116.47950832100005, 9.797984671000036],
            ],
          ],
          [
            [
              [114.43148558600004, 9.811157131000046],
              [114.43043046000004, 9.810090393000069],
              [114.42924215400004, 9.810211459000049],
              [114.42852277500003, 9.811228048000052],
              [114.42896440199998, 9.813586687000074],
              [114.42911799700008, 9.815777780000088],
              [114.43150287100005, 9.817159818000041],
              [114.43438735100005, 9.816817468000048],
              [114.434834385, 9.814774466000118],
              [114.4333327610001, 9.813045036000021],
              [114.43148558600004, 9.811157131000046],
            ],
          ],
          [
            [
              [114.45328187500002, 9.829183946000073],
              [114.45132299600004, 9.828936144000087],
              [114.44740270900003, 9.831594369000117],
              [114.44683537400007, 9.835237704000031],
              [114.44730269900002, 9.839278457000054],
              [114.44812681800008, 9.841111686000124],
              [114.45079989600001, 9.843500091000033],
              [114.45145614800003, 9.845282939000109],
              [114.4555896820001, 9.844113607000081],
              [114.45759775500008, 9.842952079000076],
              [114.46051811400002, 9.841001150000038],
              [114.46196176600002, 9.839329594000022],
              [114.46231235700007, 9.836390020000073],
              [114.46152493300011, 9.833543463000092],
              [114.45739381899999, 9.832186062000046],
              [114.45328187500002, 9.829183946000073],
            ],
          ],
          [
            [
              [114.49035773000001, 9.845454045000055],
              [114.488732258, 9.845250939000037],
              [114.48732494800008, 9.846562199000084],
              [114.48575003000006, 9.847963157],
              [114.48367226000002, 9.849633213000073],
              [114.48285019000002, 9.850588289000049],
              [114.48213719, 9.852300563000059],
              [114.48364031400004, 9.854028693000048],
              [114.48517007200005, 9.853896779000058],
              [114.48659872500004, 9.853260926000116],
              [114.48824817600008, 9.851519600000101],
              [114.49058131500013, 9.849841573000083],
              [114.49139004100007, 9.848464341000083],
              [114.49125431200004, 9.846862876],
              [114.49035773000001, 9.845454045000055],
            ],
          ],
          [
            [
              [114.28346864800007, 9.859417035000105],
              [114.27685104100009, 9.844100262000042],
              [114.27483481300006, 9.855823973000087],
              [114.27749991500004, 9.862572717000083],
              [114.28559523300007, 9.865452442000054],
              [114.28346864800007, 9.859417035000105],
            ],
          ],
          [
            [
              [114.50870808800008, 9.862546630000056],
              [114.50715692499999, 9.862003291000042],
              [114.50599169300003, 9.862884637000134],
              [114.50453926800003, 9.865465129000127],
              [114.5036083240001, 9.868367473000104],
              [114.50409006399998, 9.870127254000048],
              [114.50547900900007, 9.870929203000054],
              [114.50712338800005, 9.871723212000026],
              [114.50865326200007, 9.871591122000021],
              [114.51040651300001, 9.870437912000083],
              [114.51198678800002, 9.869205565000081],
              [114.51280879700009, 9.868250352000095],
              [114.51318640200006, 9.866717386000088],
              [114.51253697399997, 9.865047367000022],
              [114.5112278480001, 9.86407396200004],
              [114.50870808800008, 9.862546630000056],
            ],
          ],
          [
            [
              [114.33280622100003, 9.874333954000035],
              [114.33529032100003, 9.872179268000101],
              [114.33649480900013, 9.869882482000046],
              [114.33659614899999, 9.867257444000098],
              [114.33653218200003, 9.865179904000064],
              [114.33469090900009, 9.860270929000057],
              [114.32537448700009, 9.860684492000081],
              [114.31993895600013, 9.86295220100004],
              [114.31411565000006, 9.873148661000107],
              [114.30966416600006, 9.871531219000039],
              [114.30029788700003, 9.878820276000065],
              [114.30042036600004, 9.882820551000025],
              [114.30765844200002, 9.882936182],
              [114.31431042200009, 9.880399935000115],
              [114.323697702, 9.879282326000103],
              [114.3273356350001, 9.877121410000099],
              [114.33013451000012, 9.87631346400004],
              [114.33280622100003, 9.874333954000035],
            ],
          ],
          [
            [
              [114.33058816100001, 9.879620370000096],
              [114.32921359600005, 9.879415824000136],
              [114.32882837700006, 9.879520555000097],
              [114.32878047600001, 9.879522003000128],
              [114.32865548599996, 9.879567557000078],
              [114.32682920900002, 9.880064068000126],
              [114.3267688670001, 9.880080473000062],
              [114.32663710000011, 9.880192887000037],
              [114.32461518500007, 9.881917782000114],
              [114.32228383700009, 9.88350726800009],
              [114.32357129200003, 9.886675235000018],
              [114.32650556100006, 9.888105608000062],
              [114.32907727500005, 9.888719018000101],
              [114.33253317400008, 9.886463313000069],
              [114.33459417700008, 9.884377885000093],
              [114.33487426500008, 9.882430664000095],
              [114.33335532800007, 9.880032132000059],
              [114.33104768300009, 9.879688754000069],
              [114.33058816100001, 9.879620370000096],
            ],
          ],
          [
            [
              [114.53144461000014, 9.882128675000066],
              [114.52972561300011, 9.88167465400007],
              [114.527844706, 9.882833296000102],
              [114.52703345800003, 9.884127798000096],
              [114.527451809, 9.886568524000071],
              [114.52731058700013, 9.887490234000039],
              [114.52642751699999, 9.889208083000066],
              [114.52445355200008, 9.891467035000099],
              [114.5240554870001, 9.893697362000074],
              [114.52420496100004, 9.895723453000114],
              [114.52602531000001, 9.896682074000028],
              [114.52779739900009, 9.896119109000077],
              [114.53063352300001, 9.894169106000026],
              [114.53200057000005, 9.891588019000073],
              [114.533189247, 9.888758659000095],
              [114.53293843900011, 9.886228086000058],
              [114.5326795870001, 9.88344393600009],
              [114.53144461000014, 9.882128675000066],
            ],
          ],
          [
            [
              [114.34625530300001, 9.889162071000078],
              [114.34480671400006, 9.889037267000054],
              [114.34151876000006, 9.889896230000071],
              [114.33961526000004, 9.891556888000073],
              [114.33935612100001, 9.894180167000053],
              [114.34003878500009, 9.897028029000053],
              [114.34119716300009, 9.898764684000037],
              [114.34293433000003, 9.899977589000029],
              [114.34383801100002, 9.90172195800003],
              [114.34567919500003, 9.902172364000082],
              [114.347535913, 9.900390864000118],
              [114.34862074500006, 9.898344095000054],
              [114.34870224300005, 9.895476395000101],
              [114.34921606700003, 9.892848402000086],
              [114.34872398800007, 9.890672297000068],
              [114.34625530300001, 9.889162071000078],
            ],
          ],
          [
            [
              [114.427675462, 9.880226702000082],
              [114.42558113700008, 9.878435996000066],
              [114.42310132200008, 9.880620094000035],
              [114.42136411700005, 9.882106842000036],
              [114.41928445000011, 9.884868866000128],
              [114.41604867300006, 9.887329048000114],
              [114.41460005200011, 9.889903056000014],
              [114.41495442600004, 9.893096385000087],
              [114.41844897799999, 9.894844299000026],
              [114.424112745, 9.896694230000056],
              [114.42911354, 9.899070327000102],
              [114.43171128000006, 9.900059068000109],
              [114.435073698, 9.902242137000082],
              [114.43937029900005, 9.90246515600006],
              [114.44195615900004, 9.900952097000102],
              [114.44290063900003, 9.898562],
              [114.44307871400011, 9.896111183000043],
              [114.44049124600008, 9.892143407000019],
              [114.43572371300004, 9.890435012000063],
              [114.43034879900003, 9.889672713000051],
              [114.427593925, 9.888492532000013],
              [114.4263960840001, 9.885493790000053],
              [114.42706242100007, 9.882353503000017],
              [114.427675462, 9.880226702000082],
            ],
          ],
          [
            [
              [114.36601407400008, 9.904043575000097],
              [114.37066199199998, 9.903227373000066],
              [114.37654269700009, 9.903807404000096],
              [114.37898613800012, 9.903142513000061],
              [114.38221706200011, 9.90321271],
              [114.38666577600013, 9.902823968000083],
              [114.390528675, 9.901356505000079],
              [114.39455082200008, 9.899546732000085],
              [114.39463030400009, 9.899472859000101],
              [114.39474423700001, 9.899369810000012],
              [114.39508612300006, 9.898283314000087],
              [114.39790832000006, 9.889314082000043],
              [114.40050395800006, 9.884124085000055],
              [114.40024206699998, 9.881744728000095],
              [114.39975741400011, 9.879819659000065],
              [114.39801794600007, 9.8785233610001],
              [114.39614019700005, 9.878243394000029],
              [114.39515048100006, 9.87920141300007],
              [114.39497440900004, 9.881737076000084],
              [114.39553605299997, 9.883406787000036],
              [114.39515356400004, 9.884767968000039],
              [114.39166716400005, 9.887404796000075],
              [114.38651181200009, 9.891020303000085],
              [114.38403595, 9.893373144000071],
              [114.3824434620001, 9.895445991000072],
              [114.37815619200001, 9.896926224000067],
              [114.37206799900005, 9.897870788000061],
              [114.36494810100007, 9.898424833000073],
              [114.36100876500009, 9.898797641000011],
              [114.35792425300005, 9.897963571000011],
              [114.3556073310001, 9.899973890000078],
              [114.35627962800004, 9.902483835000096],
              [114.35928973200006, 9.903657584000046],
              [114.36601407400008, 9.904043575000097],
            ],
          ],
          [
            [
              [114.56511162199999, 9.896726014000111],
              [114.56297144600002, 9.896454987000068],
              [114.56071210200004, 9.89779394],
              [114.55993304900007, 9.900100998000115],
              [114.55977174400005, 9.903065018000044],
              [114.55976196700011, 9.905432523000089],
              [114.56159383200007, 9.906727787000056],
              [114.56294054600004, 9.906178301000066],
              [114.56416434000005, 9.904449066000074],
              [114.56481141500002, 9.902300030000076],
              [114.56501238800004, 9.901632561],
              [114.5650324190001, 9.901472373000074],
              [114.56528843900006, 9.899425107000058],
              [114.56535203500007, 9.89891656200008],
              [114.56533728600002, 9.898782214000066],
              [114.56511162199999, 9.896726014000111],
            ],
          ],
          [
            [
              [114.46308858000009, 9.892381191000064],
              [114.45909352900003, 9.892251647000093],
              [114.4558537610001, 9.894543331000081],
              [114.45455967200006, 9.897955153000025],
              [114.45492249400006, 9.901400176000051],
              [114.45400220200011, 9.904547607000056],
              [114.45391162500007, 9.908428159000058],
              [114.455510061, 9.910654946000095],
              [114.45807641000006, 9.91125016900005],
              [114.462631331, 9.910266569000024],
              [114.46605418500005, 9.908390555000056],
              [114.46974472800005, 9.906927711000078],
              [114.47259145200005, 9.904287566000035],
              [114.47352480500001, 9.901564260000038],
              [114.47270306699996, 9.89973726500004],
              [114.47059876800002, 9.896279696000041],
              [114.46722407000004, 9.894276568000036],
              [114.46308858000009, 9.892381191000064],
            ],
          ],
          [
            [
              [114.59316523599999, 9.89767862400006],
              [114.59075598100004, 9.896993638000049],
              [114.58857386400007, 9.898076853000058],
              [114.58716913400011, 9.899473742000085],
              [114.58631869600008, 9.902205872000101],
              [114.58644576300003, 9.906175385],
              [114.58644710600004, 9.908880731000112],
              [114.58773968700005, 9.911968171000039],
              [114.58976919000011, 9.914102430000058],
              [114.59233008500009, 9.914190868000093],
              [114.59502884600009, 9.913260408000022],
              [114.59536686899999, 9.91316520700005],
              [114.59781006099999, 9.91224275600011],
              [114.60008288400002, 9.911325650000078],
              [114.60064934099999, 9.910377800000079],
              [114.598941524, 9.907641711000091],
              [114.59805031900007, 9.903780810000027],
              [114.59633446000012, 9.900791363000037],
              [114.59316523599999, 9.89767862400006],
            ],
          ],
          [
            [
              [114.6072438660001, 9.916387472000031],
              [114.60593626000001, 9.915497676000127],
              [114.60491845899999, 9.91832302100004],
              [114.60379101500006, 9.920390046000092],
              [114.60488153200005, 9.921405748000021],
              [114.60636013599999, 9.920732186000068],
              [114.60779199400008, 9.920179124000049],
              [114.60852062700005, 9.918971103000017],
              [114.6088206280001, 9.917691984000053],
              [114.6072438660001, 9.916387472000031],
            ],
          ],
          [
            [
              [114.49777092000009, 9.904513620000087],
              [114.49451083800001, 9.903434969000012],
              [114.49146290799997, 9.90369808500011],
              [114.48708716800003, 9.904929331000021],
              [114.48554737399999, 9.907252487000038],
              [114.48438395500001, 9.910743826000077],
              [114.48755145100009, 9.914269540000118],
              [114.49001927100008, 9.91714271300008],
              [114.4909630750001, 9.920147410000089],
              [114.49322948200003, 9.922015499000024],
              [114.49676279900008, 9.92232724200006],
              [114.50002555100004, 9.920793218000055],
              [114.50212459200007, 9.918705360000056],
              [114.50220109800001, 9.915753282000049],
              [114.50127311000006, 9.91325381800001],
              [114.50177105500005, 9.910204401000032],
              [114.5016117450001, 9.907849628000063],
              [114.49777092000009, 9.904513620000087],
            ],
          ],
          [
            [
              [114.52277723100012, 9.912688528000045],
              [114.51950620900001, 9.911273475000114],
              [114.51760319500012, 9.911501297000045],
              [114.51508757300007, 9.909809815000068],
              [114.51240235800003, 9.908123604000084],
              [114.51035884000007, 9.907934348000056],
              [114.50856709399996, 9.907652985000047],
              [114.506563508, 9.908726582000059],
              [114.50670690100006, 9.910576198000063],
              [114.508538617, 9.912120485000029],
              [114.51113370000004, 9.913641025000073],
              [114.51289132899998, 9.915524701000054],
              [114.51339536400003, 9.918037331000027],
              [114.51199343000003, 9.919345118000054],
              [114.50915222000006, 9.920781941000099],
              [114.50793053000004, 9.922421207000108],
              [114.51044603700008, 9.922764365000079],
              [114.51323442800005, 9.922340500000091],
              [114.51564887300006, 9.920832624000029],
              [114.51710094500004, 9.918427636000081],
              [114.51923952600006, 9.916254110000098],
              [114.52129858200007, 9.914251592000055],
              [114.52277723100012, 9.912688528000045],
            ],
          ],
          [
            [
              [115.5471296180001, 9.930165100000108],
              [115.55067171900008, 9.930037332000017],
              [115.55495318500003, 9.930726780000041],
              [115.55916240000013, 9.929449508000053],
              [115.567827886, 9.925901156000093],
              [115.57286116, 9.923890511000087],
              [115.57692265299995, 9.920534469000051],
              [115.57895633200009, 9.914123907000059],
              [115.58057006500003, 9.907869429000103],
              [115.58035414300002, 9.90393435000003],
              [115.57768504100007, 9.900792239000021],
              [115.57442866600002, 9.897108099000105],
              [115.56935869400002, 9.890391643000028],
              [115.56221442900006, 9.883186889000049],
              [115.55754728799999, 9.879694424000094],
              [115.55275414700007, 9.876628845000111],
              [115.54771911300011, 9.874698386000079],
              [115.53921136799997, 9.876694812000062],
              [115.532038832, 9.878361209],
              [115.52182983000004, 9.878446820000074],
              [115.51660422900014, 9.879057032000025],
              [115.51089822000009, 9.880106789000108],
              [115.50521366200012, 9.883690195000037],
              [115.50201908500003, 9.889437188000015],
              [115.49921856600004, 9.894325186000062],
              [115.49829851600012, 9.899804630000107],
              [115.49719553500007, 9.906526984000051],
              [115.49742485200014, 9.91281979300007],
              [115.498381814, 9.917940943000046],
              [115.50082881200004, 9.921672125000034],
              [115.50306269899995, 9.924838110000065],
              [115.50644372900001, 9.927771995000077],
              [115.50981094200009, 9.930324452000059],
              [115.51466820499999, 9.931486736000037],
              [115.51930531100007, 9.931893035000082],
              [115.52528881899998, 9.932250808000019],
              [115.530296678, 9.932261627000026],
              [115.53452121100004, 9.931918629000068],
              [115.53948713100014, 9.930785043000066],
              [115.54373950300008, 9.930568642000102],
              [115.5471296180001, 9.930165100000108],
            ],
            [
              [115.51262385900013, 9.884809901000045],
              [115.51696499199996, 9.883245484000012],
              [115.52140375500005, 9.884353646000042],
              [115.52838156200008, 9.883116759000108],
              [115.53498960099998, 9.881470401000046],
              [115.5490812940001, 9.878849712000102],
              [115.55553626400004, 9.882701433000117],
              [115.56110147000008, 9.887430242000049],
              [115.5682980500001, 9.894071938000069],
              [115.57348699500008, 9.902053861000047],
              [115.57475528900014, 9.905670253000041],
              [115.57365081400005, 9.910358578000087],
              [115.57140222699999, 9.914806590000056],
              [115.56729052600005, 9.918758556000016],
              [115.56332918000011, 9.921014676000082],
              [115.55643665800002, 9.922672287000099],
              [115.54784338400009, 9.92439100700002],
              [115.54146594900008, 9.924620917000125],
              [115.53491713400003, 9.925983709000084],
              [115.528307666, 9.927630204000069],
              [115.51969850600008, 9.92892575100006],
              [115.51367928600006, 9.927310737000104],
              [115.50874771200004, 9.924388778000102],
              [115.50459989400005, 9.919607445000088],
              [115.50219198000003, 9.911946537000025],
              [115.50157192399998, 9.90661619200006],
              [115.50343272800002, 9.903168931000026],
              [115.50420306500007, 9.895112580000019],
              [115.50769224600003, 9.88963500700004],
              [115.51262385900013, 9.884809901000045],
            ],
          ],
          [
            [
              [114.61784353600005, 9.925448053000084],
              [114.61610444900013, 9.924402653000096],
              [114.61475505800004, 9.924868528],
              [114.61479190700007, 9.926014750000077],
              [114.61703610300003, 9.92818220700009],
              [114.61915406700004, 9.930400659000059],
              [114.62133100100004, 9.933125110000061],
              [114.62247384700001, 9.934189342000098],
              [114.62461995200012, 9.934629309000085],
              [114.62628836200005, 9.933476092000058],
              [114.62768483600004, 9.931823616000111],
              [114.62781707300006, 9.930634384000124],
              [114.62511085700005, 9.928688581000065],
              [114.62198148800003, 9.926840773000032],
              [114.61784353600005, 9.925448053000084],
            ],
          ],
          [
            [
              [114.529275544, 9.924355636000113],
              [114.52804264800004, 9.922961362000125],
              [114.52578188600003, 9.923958912000062],
              [114.52456832900008, 9.925850882000107],
              [114.52204238099998, 9.926519561000101],
              [114.51940546500012, 9.929045795000134],
              [114.51568177600004, 9.93211151900004],
              [114.51485801700008, 9.935592629000032],
              [114.51488759100005, 9.937867269000032],
              [114.51683911500004, 9.940503489000017],
              [114.51949810100005, 9.941347780000063],
              [114.52409480000006, 9.941710235000032],
              [114.52581507100003, 9.941066638000072],
              [114.52819791200001, 9.939896692000081],
              [114.53022505000004, 9.936883637000092],
              [114.53129499800002, 9.933141927000031],
              [114.53162823800005, 9.930266019000021],
              [114.53190301800007, 9.926886288000068],
              [114.529275544, 9.924355636000113],
            ],
          ],
          [
            [
              [114.54724622900008, 9.93871921400009],
              [114.54644265400012, 9.937481604000096],
              [114.54558737800005, 9.938621872000029],
              [114.54435783800002, 9.940008826000033],
              [114.54228268400001, 9.941506470000137],
              [114.54265680700006, 9.94259043600003],
              [114.54367720000002, 9.943102167000093],
              [114.54556121300003, 9.941802523000119],
              [114.54678812000012, 9.940333029000035],
              [114.54724622900008, 9.93871921400009],
            ],
          ],
          [
            [
              [114.52941444800004, 9.947943616000108],
              [114.52771517900013, 9.947912331000095],
              [114.52793026200007, 9.949338416000051],
              [114.52934633700008, 9.951148454000068],
              [114.53158135600009, 9.952005836000042],
              [114.53279038800011, 9.95264236700004],
              [114.53325429000004, 9.95254360600004],
              [114.53425585400014, 9.950658126000088],
              [114.53454437500002, 9.949047756000072],
              [114.53366664600006, 9.948148050000059],
              [114.53205220400005, 9.948114152000096],
              [114.52941444800004, 9.947943616000108],
            ],
          ],
          [
            [
              [114.5477712930001, 9.94890818700007],
              [114.545536274, 9.948052056000021],
              [114.54347704400007, 9.950052694000087],
              [114.54152668000008, 9.95280757400012],
              [114.54316256299998, 9.953514098000047],
              [114.54651837499999, 9.952230593000076],
              [114.54871596600002, 9.951909283000047],
              [114.54956930300001, 9.950665385000066],
              [114.5477712930001, 9.94890818700007],
            ],
          ],
          [
            [
              [114.57344749300003, 9.968601546000054],
              [114.57462500000011, 9.966637865000026],
              [114.57471929500002, 9.964275589000058],
              [114.57473954200009, 9.962252710000106],
              [114.576138281, 9.96086061300012],
              [114.57473557700003, 9.959472248000088],
              [114.57092328200008, 9.957064169000061],
              [114.56496573600009, 9.95792518100007],
              [114.56106988300007, 9.95821584800005],
              [114.55700707199999, 9.958595931000057],
              [114.55501890200007, 9.957478582000066],
              [114.55291207300002, 9.957965842000059],
              [114.54899790600007, 9.959015194000108],
              [114.54572246500004, 9.960128761000052],
              [114.54621615500004, 9.962304075000093],
              [114.54966220600015, 9.963881485000046],
              [114.55287264200004, 9.966056032000024],
              [114.55716426000004, 9.967522603000043],
              [114.56079567300003, 9.968251424000039],
              [114.56248926200008, 9.968114080000044],
              [114.56525586400002, 9.967016187000064],
              [114.56798600900005, 9.967436110000081],
              [114.57070540200009, 9.967519290000036],
              [114.57344749300003, 9.968601546000054],
            ],
          ],
          [
            [
              [114.59067837200011, 9.970851674000068],
              [114.58898405900007, 9.96964108400009],
              [114.58729317100006, 9.969862836000052],
              [114.58615428699997, 9.971415375000079],
              [114.58554313600004, 9.973541160000108],
              [114.58329622200002, 9.974960025000089],
              [114.58198495100007, 9.976433722000076],
              [114.58238078200013, 9.978190790000062],
              [114.58540598099997, 9.979865166000044],
              [114.59024477900003, 9.982493570000093],
              [114.59333409700002, 9.984839944000077],
              [114.595106071, 9.987143517000073],
              [114.59735460700006, 9.98842089700007],
              [114.59899197199999, 9.989045110000026],
              [114.60059593200002, 9.9875603460001],
              [114.60046788100007, 9.98621614800008],
              [114.59863535000007, 9.984672907000023],
              [114.59515888900002, 9.982170284000079],
              [114.59307391100008, 9.979371004000102],
              [114.59254460800007, 9.976101422000026],
              [114.5918537900001, 9.973089734000061],
              [114.59067837200011, 9.970851674000068],
            ],
          ],
          [
            [
              [114.66647029400009, 9.998280921000101],
              [114.66527582900002, 9.99560289200012],
              [114.66414338000003, 9.993038470000036],
              [114.6630128390001, 9.990531788000022],
              [114.66253599700008, 9.988408811000047],
              [114.66138119000001, 9.985151672000113],
              [114.66041233200005, 9.982235352000133],
              [114.65920497600007, 9.979153274000028],
              [114.65844668000004, 9.97553684400012],
              [114.65774105300009, 9.97174539700003],
              [114.65691058100003, 9.966879293000106],
              [114.656486114, 9.958425744000097],
              [114.65512755000012, 9.954689734000064],
              [114.65393481800002, 9.950192641000031],
              [114.65172040300004, 9.946483870000032],
              [114.64856417500002, 9.943088454000113],
              [114.64428098400001, 9.940255898000048],
              [114.64316987100008, 9.93607828300007],
              [114.64272196900006, 9.932722216000032],
              [114.64145871900007, 9.930403114000084],
              [114.63983564200008, 9.928769482000098],
              [114.63856369599999, 9.928809827000061],
              [114.63724768400009, 9.930115414000083],
              [114.63781078900004, 9.9317826870001],
              [114.63896476000005, 9.933346968000121],
              [114.63991053000005, 9.936350212000098],
              [114.64016978400001, 9.939122498000062],
              [114.64108842900008, 9.941284058000031],
              [114.64309701900001, 9.944337875000107],
              [114.64545195399998, 9.946285335000125],
              [114.64854247800008, 9.949978849000017],
              [114.64951342500004, 9.951127642000033],
              [114.65088232900005, 9.952769348000066],
              [114.65208551400005, 9.955848737000038],
              [114.65174268300009, 9.960999542000069],
              [114.65021407500001, 9.964924140000074],
              [114.64732727600008, 9.967543693000012],
              [114.6443691960001, 9.970586781000089],
              [114.64131098700003, 9.974475664000069],
              [114.639950633, 9.977046701000079],
              [114.6395395510001, 9.980093234],
              [114.64123696500012, 9.981387612000068],
              [114.64434479500004, 9.982974271000076],
              [114.64517342600004, 9.984970317000117],
              [114.64488853400009, 9.986664653000064],
              [114.64264732300001, 9.988252560000086],
              [114.63782882500003, 9.988910992000031],
              [114.63197073400011, 9.988928133000028],
              [114.62779476600008, 9.991082745000044],
              [114.62229039500002, 9.992857993000113],
              [114.61865113799999, 9.993225879000088],
              [114.61365198200008, 9.993552411000016],
              [114.61031410700004, 9.992730904000078],
              [114.60827921300006, 9.994143398000064],
              [114.60706364400002, 9.995951348000085],
              [114.60670440700005, 9.997985066000046],
              [114.60869055400008, 9.999017833000055],
              [114.61101871600007, 10.000124057000013],
              [114.61304371400001, 9.99972302700005],
              [114.61420962800003, 9.999012071000022],
              [114.61577799200003, 9.997614242000095],
              [114.61958918200007, 9.99782492300003],
              [114.62076059500009, 9.998171247000085],
              [114.62340552800001, 9.998838841000078],
              [114.62585715100008, 9.998934617000097],
              [114.63058011700005, 9.998958449000027],
              [114.63350698100011, 9.999328036000053],
              [114.63759863900009, 9.99966064700004],
              [114.64250378400004, 9.999909593000032],
              [114.64462092900007, 10.000478076000084],
              [114.64668915500013, 10.0013370380001],
              [114.64816008000005, 10.001752641000026],
              [114.65116017800004, 10.002581968000078],
              [114.65338451500006, 10.002858025000092],
              [114.65683375500008, 10.003152893000065],
              [114.65940380000009, 10.003302319000083],
              [114.66233263800004, 10.003729225000056],
              [114.66537797500004, 10.004152391000074],
              [114.66742387100012, 10.004318406000044],
              [114.668524831, 10.004109969000028],
              [114.66891753800007, 10.003635149000088],
              [114.66882185900015, 10.002482409000011],
              [114.667948495, 10.000718761000103],
              [114.66647029400009, 9.998280921000101],
            ],
          ],
          [
            [
              [116.16446198200006, 10.113160653000095],
              [116.15632795700003, 10.111258225000073],
              [116.14486388900006, 10.11569940500001],
              [116.14026259800002, 10.123868317000094],
              [116.14626717400009, 10.128517634000021],
              [116.15247301000011, 10.126944441000061],
              [116.16029096300011, 10.120869324000113],
              [116.16446198200006, 10.113160653000095],
            ],
          ],
          [
            [
              [113.84729811899997, 10.006364808000077],
              [113.84029271900015, 10.005570948000029],
              [113.84370422200007, 10.020316724000098],
              [113.84790232400005, 10.02762014600007],
              [113.84862562200013, 10.03551539400007],
              [113.84294618700005, 10.134131257000066],
              [113.84649324400007, 10.136011488000028],
              [113.85290590500007, 10.133358383000067],
              [113.8626823080001, 10.126157784000076],
              [113.86352457899999, 10.120691475000053],
              [113.85287874000004, 10.009671750000047],
              [113.84729811899997, 10.006364808000077],
            ],
          ],
          [
            [
              [114.30810775599998, 10.165685410000068],
              [114.30756891100008, 10.16560146300005],
              [114.30729291400007, 10.16585],
              [114.30699937000011, 10.166419041000017],
              [114.30690047200002, 10.166908524000105],
              [114.30692673700005, 10.167737710000056],
              [114.30699253, 10.167984763000069],
              [114.30716910500004, 10.169186943000062],
              [114.30741109499999, 10.170080490000098],
              [114.30828364400006, 10.1715769080001],
              [114.30878373100005, 10.172051392000101],
              [114.30927509300002, 10.172400726000051],
              [114.30972825400013, 10.172635995000066],
              [114.31025944400002, 10.172231030000132],
              [114.31051958700006, 10.171494920000066],
              [114.31052291700007, 10.170682523000083],
              [114.31057329200006, 10.169560582000068],
              [114.31049727099999, 10.168672093000096],
              [114.31037107500003, 10.167577949000071],
              [114.31021467600014, 10.167159682000102],
              [114.3100671450001, 10.166886847000088],
              [114.30951671600003, 10.166455266000083],
              [114.30876304600011, 10.166033472],
              [114.30810775599998, 10.165685410000068],
            ],
          ],
          [
            [
              [114.26069778400009, 10.164989820000068],
              [114.25948892000008, 10.164706009000033],
              [114.25859886, 10.164861916000055],
              [114.25729334900004, 10.165338452000048],
              [114.25675694100008, 10.165934472000069],
              [114.25623561400002, 10.166428134000059],
              [114.25519518100008, 10.16766344600004],
              [114.25495200100012, 10.168090729000044],
              [114.25455777800001, 10.168783406000069],
              [114.25394317699997, 10.170423305000041],
              [114.2536131720001, 10.171837283000061],
              [114.25402769500012, 10.172451349000065],
              [114.25467758300006, 10.172971944000063],
              [114.25582952800008, 10.174141773000132],
              [114.25626303900006, 10.174272909000058],
              [114.25852101300011, 10.173323407000099],
              [114.25989219400005, 10.172591499000115],
              [114.26180903400011, 10.17147478],
              [114.26298054600014, 10.170288196000053],
              [114.2641716820001, 10.169745896000036],
              [114.26559062300009, 10.169058593000011],
              [114.26777561500005, 10.168203313000033],
              [114.26638472600007, 10.167779938000086],
              [114.2651881420001, 10.167388915000062],
              [114.26428401400007, 10.166935829000055],
              [114.26232531400001, 10.165744828000099],
              [114.26069778400009, 10.164989820000068],
            ],
          ],
          [
            [
              [114.32282961300004, 10.171646206000061],
              [114.32131369000001, 10.171504769000082],
              [114.32019543500004, 10.172006494000117],
              [114.31880838600003, 10.172984298000108],
              [114.31914902400005, 10.174845946000042],
              [114.32034491900006, 10.176868987000097],
              [114.32160647799999, 10.177954078000083],
              [114.32293367500003, 10.178101221000089],
              [114.32369295400005, 10.178153366000052],
              [114.32479555300007, 10.177202646000081],
              [114.32503269000006, 10.175697932000014],
              [114.32471212300001, 10.17449084900006],
              [114.32362497800003, 10.172932543000089],
              [114.32282961300004, 10.171646206000061],
            ],
          ],
          [
            [
              [114.25324018199997, 10.176393874000043],
              [114.25195540399999, 10.17562072100011],
              [114.25066941300007, 10.176321583000101],
              [114.24899849100011, 10.177891502000056],
              [114.24715170600007, 10.179789092000123],
              [114.24615869500006, 10.180739960000016],
              [114.24522604900008, 10.182149657000126],
              [114.24505859300008, 10.182753470000081],
              [114.24578233900002, 10.183950094000059],
              [114.24695405500006, 10.183528058000133],
              [114.24858935100009, 10.183023755000063],
              [114.24956861200012, 10.18210555800003],
              [114.25025192899999, 10.18175705000006],
              [114.25079793200004, 10.181620702000059],
              [114.25232345100005, 10.181507990000059],
              [114.25299111500006, 10.181219116000049],
              [114.25390011800005, 10.180524683000057],
              [114.25519350800003, 10.179549833000044],
              [114.25546534, 10.178904400000109],
              [114.25544260100003, 10.178418920000086],
              [114.25509230900001, 10.177795118000075],
              [114.254275452, 10.177330296000074],
              [114.25324018199997, 10.176393874000043],
            ],
          ],
          [
            [
              [114.36584409600005, 10.183680019000095],
              [114.36863961200007, 10.182425065000087],
              [114.37073441400003, 10.181425378000062],
              [114.37141336000006, 10.180468749000047],
              [114.3704078980001, 10.178510386000104],
              [114.3692863680001, 10.177531028000073],
              [114.36881127600006, 10.177116161000031],
              [114.36846581000007, 10.176814483000028],
              [114.36698483500001, 10.174753568000126],
              [114.36590850900005, 10.174318299000083],
              [114.36369215600007, 10.175204615000053],
              [114.36193324900003, 10.17560903600012],
              [114.36008527800007, 10.176952117000047],
              [114.35845518600001, 10.177703584000051],
              [114.35658186800001, 10.178228428000045],
              [114.35446174099999, 10.178409747000037],
              [114.35282799700009, 10.179044295000049],
              [114.34893444400008, 10.179162336000045],
              [114.34550563100007, 10.179032237000028],
              [114.34351068500003, 10.179443662000052],
              [114.3417661230001, 10.180315453000064],
              [114.34121224500009, 10.181502196000023],
              [114.34326491700003, 10.182961009000023],
              [114.34555360400013, 10.184412658000051],
              [114.34732344500001, 10.184359040000055],
              [114.3506307590001, 10.184375800000021],
              [114.35260768, 10.18337986900004],
              [114.35473869800001, 10.18354922800002],
              [114.35665905200008, 10.184543945000078],
              [114.35866848200008, 10.184599949000093],
              [114.36183610100007, 10.183918773000043],
              [114.36584409600005, 10.183680019000095],
            ],
          ],
          [
            [
              [114.37840424400011, 10.189040128000036],
              [114.3770998330001, 10.188845709000114],
              [114.37621903500005, 10.188989590000029],
              [114.37548155100005, 10.189948581000062],
              [114.37458893100009, 10.191614688000071],
              [114.37446463500011, 10.193315964000059],
              [114.37414893200001, 10.194554791000064],
              [114.37430856400007, 10.195896220000032],
              [114.37551141800012, 10.196620565000012],
              [114.37747355400002, 10.197087656000024],
              [114.37847230800006, 10.196940169000078],
              [114.38017008600002, 10.196447995000122],
              [114.38128305000009, 10.195274138000075],
              [114.3813002710001, 10.193927318000105],
              [114.380967336, 10.192708238000046],
              [114.38039854900001, 10.19149635300005],
              [114.37985199900008, 10.190049650000075],
              [114.37953723500006, 10.189415366000086],
              [114.37840424400011, 10.189040128000036],
            ],
          ],
          [
            [
              [114.3898417380001, 10.199786128000097],
              [114.38800473900007, 10.199549469000056],
              [114.38671477700009, 10.199822945000035],
              [114.3860879700001, 10.200544480000049],
              [114.38606714600007, 10.201774344000023],
              [114.38651806900008, 10.202989839000052],
              [114.38736720300008, 10.203724910000032],
              [114.38857560000005, 10.204624624000045],
              [114.38976946700006, 10.205056500000074],
              [114.39044878600005, 10.204974289000022],
              [114.39170484200007, 10.203709679000015],
              [114.39213837800006, 10.202467208000064],
              [114.39228435300005, 10.201467650000049],
              [114.39171730300004, 10.200314250000096],
              [114.3898417380001, 10.199786128000097],
            ],
          ],
          [
            [
              [114.40217189900014, 10.200889714000104],
              [114.40152899100005, 10.200864473000058],
              [114.40075675000003, 10.200932956000129],
              [114.39998130900008, 10.201107848000033],
              [114.39951833800005, 10.201345424000062],
              [114.399267706, 10.201801942000086],
              [114.39917592000005, 10.202479760000037],
              [114.39921523800001, 10.203742917000101],
              [114.39904840699998, 10.20474992300011],
              [114.39911886900003, 10.20519753900002],
              [114.39947354900008, 10.205689355000034],
              [114.40004428000005, 10.206105835000033],
              [114.40079837900009, 10.20646123700004],
              [114.40159489300001, 10.206620406000065],
              [114.40275817699998, 10.206737505000072],
              [114.40387378500009, 10.207019038000096],
              [114.40564970300012, 10.207187267000071],
              [114.40647480300001, 10.207128099000061],
              [114.4074883640001, 10.206985050000037],
              [114.40809543300007, 10.206878595000111],
              [114.4094474310001, 10.206322714000036],
              [114.41033643900003, 10.206061636000106],
              [114.41084536800005, 10.205817867000098],
              [114.41107663700009, 10.205571249000032],
              [114.41119604900011, 10.205274900000026],
              [114.4112216210001, 10.204921152000066],
              [114.41111598100005, 10.204521021000089],
              [114.41091378400003, 10.204134487000081],
              [114.41061040700006, 10.203724130000056],
              [114.41008413100003, 10.203270131000062],
              [114.40939245500009, 10.202752954000081],
              [114.408493894, 10.20224174200006],
              [114.40775249300012, 10.201850706000036],
              [114.40675238200005, 10.201517138000058],
              [114.405914521, 10.201290410000039],
              [114.40475914600006, 10.201073875000064],
              [114.40420757700008, 10.201061640000118],
              [114.40217189900014, 10.200889714000104],
            ],
          ],
          [
            [
              [115.31234858200003, 10.198098509000085],
              [115.31503064900008, 10.197906198000071],
              [115.32017357499998, 10.199699654000048],
              [115.3242154320001, 10.201334176000019],
              [115.3288892920001, 10.203933097000027],
              [115.33111273700004, 10.204841850000077],
              [115.33216823699998, 10.203719683000132],
              [115.33235767600002, 10.200654689000109],
              [115.32841282600012, 10.196155903000113],
              [115.323551916, 10.191097183000062],
              [115.31931959500002, 10.186904314000065],
              [115.31692328599999, 10.183929775000065],
              [115.31062783800012, 10.179118297000043],
              [115.30475431600006, 10.176363645000029],
              [115.30192755700003, 10.175278394000118],
              [115.29871001199999, 10.17440402500007],
              [115.29527261100006, 10.172945350000083],
              [115.2902579790001, 10.171936142000069],
              [115.28914139400001, 10.174145341000072],
              [115.290750418, 10.180205765000048],
              [115.28875799099998, 10.18579967200006],
              [115.28437136300013, 10.191279586000055],
              [115.28198778, 10.197084442000024],
              [115.28194941200002, 10.201623929000013],
              [115.28251485400008, 10.206339780000027],
              [115.28327228200014, 10.21085167300013],
              [115.28614832000007, 10.213316754000077],
              [115.28963851100002, 10.210630226000104],
              [115.29488411600008, 10.206895944000037],
              [115.30113121600004, 10.203323953000075],
              [115.30881335700008, 10.199504416000059],
              [115.31234858200003, 10.198098509000085],
            ],
          ],
          [
            [
              [114.22604930800003, 10.203484366000025],
              [114.22471549899998, 10.202353675000085],
              [114.222501135, 10.203355722000127],
              [114.22114485300004, 10.205385571000123],
              [114.21945919500001, 10.208244442000057],
              [114.21858561900004, 10.210611048000116],
              [114.21905294600006, 10.214342296000021],
              [114.22113752200006, 10.216855179000015],
              [114.22306515700004, 10.218085322000102],
              [114.22590886800005, 10.218351949000047],
              [114.22602690500004, 10.218348441000023],
              [114.22720381500008, 10.218196417000057],
              [114.22775507100003, 10.216892640000095],
              [114.227430418, 10.213976405000034],
              [114.22732060600006, 10.210351574000052],
              [114.2275755520001, 10.207067047000072],
              [114.22739020600014, 10.20484891000004],
              [114.22604930800003, 10.203484366000025],
            ],
          ],
          [
            [
              [114.41544955200006, 10.212775219000035],
              [114.41456345900011, 10.212743831000102],
              [114.41394965400004, 10.213874491000039],
              [114.413812933, 10.215166124000062],
              [114.41304050200004, 10.21688684300006],
              [114.41218721600005, 10.217907795000068],
              [114.41223098400012, 10.21931094300008],
              [114.41385191600003, 10.220197645000082],
              [114.41558739200001, 10.220027476000036],
              [114.41684786300003, 10.218818456000099],
              [114.41781538400005, 10.217676934000062],
              [114.41865585200006, 10.21624670100003],
              [114.41880714900005, 10.215422793000071],
              [114.4181884210001, 10.214505429000019],
              [114.41750710200003, 10.213472951000123],
              [114.41661553200008, 10.213266180000067],
              [114.41544955200006, 10.212775219000035],
            ],
          ],
          [
            [
              [114.42934879599999, 10.222608487000072],
              [114.42791564299998, 10.22206793200011],
              [114.42680464800007, 10.222394276000022],
              [114.42556859500012, 10.223439456000081],
              [114.42515908900008, 10.225441720000015],
              [114.42569287400009, 10.227415070000044],
              [114.42715349800007, 10.228833284000059],
              [114.42850377500005, 10.22955263700007],
              [114.42943061700009, 10.228997504000057],
              [114.43034830000008, 10.228150049000105],
              [114.43087513400006, 10.226127270000072],
              [114.43095285300001, 10.224838918000055],
              [114.430501811, 10.223625248000088],
              [114.42934879599999, 10.222608487000072],
            ],
          ],
          [
            [
              [114.43968079400008, 10.223113234],
              [114.43780962600006, 10.222702716000052],
              [114.43659025799998, 10.223325220000044],
              [114.43579624200001, 10.224344190000066],
              [114.43583832900003, 10.225688486000093],
              [114.4375727930001, 10.228326395000041],
              [114.43851179899998, 10.230052674000055],
              [114.44113975099999, 10.232019565000082],
              [114.44391326600007, 10.233923450000047],
              [114.44618781300005, 10.234965068000077],
              [114.44904446300006, 10.235754730000044],
              [114.4516082560001, 10.236611878000089],
              [114.45303139300009, 10.237043901000041],
              [114.45398068000011, 10.236070768000081],
              [114.454826236, 10.234816094000097],
              [114.454121009, 10.233024165000069],
              [114.45283555900005, 10.231542629000055],
              [114.45148936800004, 10.230004457000062],
              [114.44832540199999, 10.227878696000035],
              [114.44686321000007, 10.226402588000026],
              [114.44435667299999, 10.225485128000054],
              [114.44225355000005, 10.224262721000075],
              [114.43968079400008, 10.223113234],
            ],
          ],
          [
            [
              [114.53238565400011, 10.23664350900013],
              [114.53182563600005, 10.235725141000083],
              [114.53082581000001, 10.235814805000041],
              [114.5294227950001, 10.236209464000099],
              [114.52862942500009, 10.237228502000038],
              [114.52842528000008, 10.238229189000057],
              [114.52945852900004, 10.239191309000082],
              [114.53052102000002, 10.23921668200005],
              [114.53153546800002, 10.238658648000063],
              [114.53275429800006, 10.23803575900004],
              [114.53281668400007, 10.237214967000073],
              [114.53238565400011, 10.23664350900013],
            ],
          ],
          [
            [
              [114.49442308800012, 10.244468693000018],
              [114.49726980700002, 10.243093650000022],
              [114.50028956500003, 10.241596237000026],
              [114.50358184000004, 10.240324272000032],
              [114.50707661200009, 10.23892899400005],
              [114.50970042800012, 10.237970139000117],
              [114.51246243600012, 10.237650349000125],
              [114.51626671700002, 10.237649030000016],
              [114.51791286300008, 10.237480853000045],
              [114.51907835200005, 10.237035147000116],
              [114.5192143510001, 10.235744160000058],
              [114.51894900900001, 10.234816591000049],
              [114.51809814700003, 10.234024223000036],
              [114.515422562, 10.233347092000054],
              [114.51256836100001, 10.232616994000042],
              [114.50686400900008, 10.233145177000045],
              [114.50285544599997, 10.233211145000086],
              [114.50030894500004, 10.233816572000086],
              [114.49896850500006, 10.234326065000053],
              [114.49725069200004, 10.235958552000033],
              [114.49397313800002, 10.236762032000078],
              [114.48932740200003, 10.237198435000037],
              [114.48313122800002, 10.238033645000131],
              [114.47688712500012, 10.238285259000078],
              [114.47438284800003, 10.238362668000088],
              [114.47175701200001, 10.239262643000034],
              [114.46834492200006, 10.239543479000089],
              [114.46516666600007, 10.239758566000122],
              [114.46374138900001, 10.240387426000018],
              [114.46353325100002, 10.241271194000133],
              [114.46499340600003, 10.242688372000062],
              [114.46661380400008, 10.24357418700008],
              [114.46856937800007, 10.243864754000052],
              [114.47275494499999, 10.243793979000058],
              [114.47719095000002, 10.244183285000117],
              [114.48052946400003, 10.244372497000052],
              [114.48430440200001, 10.244372679000067],
              [114.48668171600006, 10.244942462000049],
              [114.48888042400009, 10.245459282000089],
              [114.49102568600003, 10.245217361000057],
              [114.49442308800012, 10.244468693000018],
            ],
          ],
          [
            [
              [115.3741193000001, 10.258266067000042],
              [115.377029107, 10.258163622],
              [115.3788423350001, 10.258298772000083],
              [115.38013603000002, 10.257954692000036],
              [115.38080984100009, 10.257134930000065],
              [115.38035495500002, 10.255658379000108],
              [115.37803337000004, 10.252556035000014],
              [115.37492345400003, 10.249879444000063],
              [115.37121161400005, 10.247224018000088],
              [115.36823069700006, 10.245338836000071],
              [115.36600557100006, 10.244919570000109],
              [115.36371574000005, 10.245497587000061],
              [115.36286971100003, 10.247119348000043],
              [115.36337785700007, 10.25008657500006],
              [115.36450228600003, 10.253430153000041],
              [115.36561249500005, 10.256376225000084],
              [115.36658321800007, 10.258232682000122],
              [115.36870814400004, 10.258655462000108],
              [115.37201219000012, 10.25834022800005],
              [115.3741193000001, 10.258266067000042],
            ],
          ],
          [
            [
              [114.54954617600005, 10.242852153000024],
              [114.54748967200007, 10.242156278],
              [114.54532868, 10.242808539000057],
              [114.54376793100006, 10.243792860000054],
              [114.54331028800003, 10.246087621000031],
              [114.54417281599999, 10.249101300000072],
              [114.54548804900001, 10.251516117000035],
              [114.54762775100004, 10.252969589000125],
              [114.5491656710001, 10.254968118000106],
              [114.55150416700005, 10.256181445000044],
              [114.55298509800002, 10.258240221000035],
              [114.55647503400012, 10.261347162000066],
              [114.56012355300001, 10.264799940000023],
              [114.56369236400008, 10.266676344000112],
              [114.56646134700011, 10.266589579000128],
              [114.56902744300007, 10.265690492000077],
              [114.57091490100001, 10.263856018000082],
              [114.57115537600009, 10.262154663000087],
              [114.57047001500005, 10.26100800400007],
              [114.56789016100004, 10.259628706000077],
              [114.56284275600002, 10.256691304000041],
              [114.55938489, 10.254589961000081],
              [114.557587, 10.251839477000056],
              [114.55640622200011, 10.249946783000039],
              [114.55499877500009, 10.24741797300006],
              [114.55387135900007, 10.245348201000047],
              [114.55171527600011, 10.244304641000033],
              [114.54954617600005, 10.242852153000024],
            ],
          ],
          [
            [
              [114.25746359200001, 10.257439625000051],
              [114.25543154100001, 10.256680990000076],
              [114.2542829140001, 10.2577686870001],
              [114.25467981000007, 10.259161370000045],
              [114.25745589900008, 10.26106824200008],
              [114.26048242000003, 10.263435780000039],
              [114.26233540899996, 10.266072456000062],
              [114.26356959100011, 10.267791263000079],
              [114.26507493799997, 10.267974772000075],
              [114.26649644499999, 10.266884430000099],
              [114.26680074000004, 10.265236675],
              [114.265784865, 10.26292614100006],
              [114.26321988000004, 10.260193746000111],
              [114.26044019700009, 10.258170023000089],
              [114.25746359200001, 10.257439625000051],
            ],
          ],
          [
            [
              [113.61669116200002, 10.253798544000057],
              [113.61350683200007, 10.247758283000062],
              [113.609549161, 10.247864278000039],
              [113.60302428400006, 10.250654082000031],
              [113.60448156500001, 10.255682115000029],
              [113.60574000799996, 10.266318607000089],
              [113.62067460200001, 10.268488178000011],
              [113.61950830099997, 10.260261149000092],
              [113.61669116200002, 10.253798544000057],
            ],
          ],
          [
            [
              [114.6088347240001, 10.265892624000104],
              [114.60664051100001, 10.265494148000085],
              [114.60573194900007, 10.26657505400008],
              [114.60598810100012, 10.268903868000033],
              [114.60664120500007, 10.27010075200012],
              [114.60783791700005, 10.270646933000103],
              [114.60878217000003, 10.270675536000072],
              [114.6096065370001, 10.26972658900007],
              [114.61001386100006, 10.267726119000107],
              [114.60961916100008, 10.266452471000063],
              [114.6088347240001, 10.265892624000104],
            ],
          ],
          [
            [
              [114.59349936800001, 10.262900798000095],
              [114.58869832499997, 10.262058321000021],
              [114.58612075300005, 10.262548461000074],
              [114.58368100800006, 10.262742015000102],
              [114.58212118600014, 10.26372610100008],
              [114.58071462100011, 10.265815739000049],
              [114.58175767300008, 10.268938869000086],
              [114.58205863000003, 10.27097489100006],
              [114.58276810900011, 10.272855843000082],
              [114.58461819, 10.274517915000024],
              [114.58665202000012, 10.275447523000112],
              [114.59065605200013, 10.275321614000086],
              [114.59267948800004, 10.27408909400007],
              [114.59493601900013, 10.271855695000019],
              [114.59653411000011, 10.270227437000033],
              [114.59846859200003, 10.268062596000059],
              [114.59895727500006, 10.266761479000051],
              [114.59886972300001, 10.264952523000124],
              [114.59843319100004, 10.264206520000039],
              [114.59663495800011, 10.263269638000059],
              [114.59349936800001, 10.262900798000095],
            ],
          ],
          [
            [
              [114.64678185400007, 10.289767533000125],
              [114.6471999470001, 10.28811828000009],
              [114.64470857400012, 10.287613025000029],
              [114.64161720900005, 10.286776181000066],
              [114.63672588900008, 10.284944633000046],
              [114.6327351000001, 10.281799054000105],
              [114.62775362199996, 10.277165685000076],
              [114.6264746730001, 10.274051091000034],
              [114.62554278100001, 10.272561481000062],
              [114.62430904200006, 10.270847702000106],
              [114.62272590100008, 10.269261820000073],
              [114.62071325200009, 10.268974884000047],
              [114.62007833000004, 10.271215129000042],
              [114.61948475100004, 10.274739458000058],
              [114.62075231600012, 10.277503884000081],
              [114.62402515200002, 10.280321783000089],
              [114.62715399, 10.282326222000025],
              [114.63127681600005, 10.285913598000105],
              [114.63335361800003, 10.288182256000054],
              [114.63573850900001, 10.289040513000101],
              [114.6390201170001, 10.288469682000121],
              [114.6421900280001, 10.289913142],
              [114.64515993400008, 10.29063696600012],
              [114.64678185400007, 10.289767533000125],
            ],
          ],
          [
            [
              [114.28301913900009, 10.273880640000053],
              [114.281580788, 10.273221468000047],
              [114.28040360300012, 10.27337381100004],
              [114.27974874400003, 10.275149158000117],
              [114.2800492150001, 10.277247018000056],
              [114.28271127000002, 10.279274095000089],
              [114.287354776, 10.282323674000081],
              [114.29065045900009, 10.285740333000069],
              [114.29394982699998, 10.28927403200006],
              [114.29744961900009, 10.291629812000023],
              [114.30006903900002, 10.292254210000083],
              [114.30105841400008, 10.291755710000048],
              [114.30148766700006, 10.290336551000044],
              [114.29926883400006, 10.287356402000023],
              [114.29596580900007, 10.283705711000033],
              [114.29313522399998, 10.280040806000022],
              [114.29022249100004, 10.277550199000094],
              [114.28559772100002, 10.275110935000063],
              [114.28301913900009, 10.273880640000053],
            ],
          ],
          [
            [
              [116.55067320100008, 10.311678253000077],
              [116.54968021700009, 10.311578725000102],
              [116.54901873200006, 10.312656112000072],
              [116.54895695000002, 10.312868705000076],
              [116.55029309100007, 10.314424768000039],
              [116.55167647400006, 10.315418691000032],
              [116.55216066000006, 10.316869469000077],
              [116.55298870600009, 10.318096139000053],
              [116.55387336800001, 10.317289741000122],
              [116.55428838, 10.315382090000112],
              [116.55525518500004, 10.314852429000075],
              [116.55590791300004, 10.313565320000071],
              [116.55567308199997, 10.313014695000076],
              [116.55402510300004, 10.312451767000129],
              [116.55230371500008, 10.311821784000051],
              [116.55067320100008, 10.311678253000077],
            ],
          ],
          [
            [
              [114.35070286200003, 10.313504326000087],
              [114.34939980600004, 10.313426724000061],
              [114.34761032700013, 10.314827471000052],
              [114.34617160400001, 10.316100507000046],
              [114.34407951300003, 10.317276211000069],
              [114.34290900699997, 10.317662925000091],
              [114.34155227400008, 10.317762521000072],
              [114.34129123000007, 10.318882786000037],
              [114.3423350930001, 10.320139209000077],
              [114.34386724400004, 10.319975741000052],
              [114.34650721500007, 10.319310345000064],
              [114.34893925200004, 10.317655944000046],
              [114.35008082900008, 10.316333360000039],
              [114.351218776, 10.314893787000033],
              [114.35119708400006, 10.314191888000044],
              [114.35070286200003, 10.313504326000087],
            ],
          ],
          [
            [
              [114.617071147, 10.315001178000037],
              [114.611284103, 10.314600370000091],
              [114.60799919600008, 10.31505406000006],
              [114.60346054200006, 10.316830808000075],
              [114.59878913, 10.318144878000089],
              [114.59623666400002, 10.319392153000083],
              [114.59391959800006, 10.320632001000083],
              [114.59290872300008, 10.322180800000067],
              [114.59578274100012, 10.323607366000063],
              [114.59960611400001, 10.325237384000017],
              [114.60198685600008, 10.325979251000115],
              [114.60421991600002, 10.325792191000092],
              [114.60617254600001, 10.324213642000045],
              [114.61034315500004, 10.321981661000022],
              [114.61498456800011, 10.319734725],
              [114.61764338600001, 10.318133780000059],
              [114.61891580100004, 10.317393447000088],
              [114.61876804100007, 10.31646457900006],
              [114.617071147, 10.315001178000037],
            ],
          ],
          [
            [
              [116.56700960400005, 10.314709108000029],
              [116.56381032700001, 10.314210393000065],
              [116.56282606200003, 10.314320570000033],
              [116.56187961500005, 10.315338482000056],
              [116.56094412500011, 10.318314399000082],
              [116.55994460400009, 10.319754138000102],
              [116.55827481600014, 10.32206060700007],
              [116.55861214400015, 10.323375797],
              [116.56005156000006, 10.324016451000071],
              [116.56052682900012, 10.324171118000079],
              [116.56154335300003, 10.325913980000044],
              [116.56284468099997, 10.326630203000063],
              [116.56489257300004, 10.32661645200008],
              [116.56610364499997, 10.325168015000051],
              [116.56767373200002, 10.322166091],
              [116.56954974800007, 10.31803252400003],
              [116.569362123, 10.316921078000084],
              [116.56881027200009, 10.31554475000007],
              [116.56700960400005, 10.314709108000029],
            ],
          ],
          [
            [
              [114.58326643300003, 10.313500097000093],
              [114.58197535500007, 10.313177863000076],
              [114.58052471700003, 10.313586206000112],
              [114.57736084100009, 10.314773902000061],
              [114.57278102200004, 10.317457122000027],
              [114.56964311100003, 10.319460160000025],
              [114.56759897400001, 10.321338185000071],
              [114.5648745140001, 10.321967743000114],
              [114.56186717299995, 10.322334020000044],
              [114.56014512000002, 10.322841421000104],
              [114.55977492900004, 10.325573955000065],
              [114.56111523, 10.327436669000088],
              [114.56202962200007, 10.328152316000059],
              [114.56530048, 10.329573056000106],
              [114.56786540900012, 10.329674089000028],
              [114.57048697900004, 10.328684917000073],
              [114.57337982600004, 10.32759651400008],
              [114.57516427900006, 10.326180046000031],
              [114.57738827100005, 10.32420559800009],
              [114.57968622200011, 10.321684603000046],
              [114.58152714900011, 10.319177953000034],
              [114.58317654599998, 10.316405220000123],
              [114.58376113300002, 10.314663617000084],
              [114.58326643300003, 10.313500097000093],
            ],
          ],
          [
            [
              [116.60347410400009, 10.328812647000072],
              [116.60224932199999, 10.328232867000063],
              [116.60040177699999, 10.327958684000025],
              [116.59850714299998, 10.328246469000122],
              [116.5964539700001, 10.328120691000018],
              [116.59431860200007, 10.327718244000026],
              [116.5930555030001, 10.327910069],
              [116.59213870800011, 10.32962785900011],
              [116.59214420700002, 10.331447832000061],
              [116.59347163200007, 10.332793556000029],
              [116.59525456399999, 10.333210484000064],
              [116.59767197400006, 10.333601355000075],
              [116.59971643600012, 10.333517455000061],
              [116.60133497700011, 10.333381014000052],
              [116.60301230500006, 10.332962094000029],
              [116.60371199700005, 10.332395498000045],
              [116.60392024300005, 10.331034591000064],
              [116.60386170600009, 10.329636826000053],
              [116.60347410400009, 10.328812647000072],
            ],
          ],
          [
            [
              [114.36121239700006, 10.329285752000049],
              [114.36013102500013, 10.328733103000063],
              [114.35860607000006, 10.329130657000094],
              [114.35818055700013, 10.330665789000102],
              [114.35795513400004, 10.331023911000068],
              [114.35740062700008, 10.332211673000112],
              [114.35817463500007, 10.334295887000076],
              [114.35958511200008, 10.334018905000073],
              [114.36096298500004, 10.332689048000086],
              [114.36208646, 10.330781435000079],
              [114.36121239700006, 10.329285752000049],
            ],
          ],
          [
            [
              [116.62291898400011, 10.335294176000074],
              [116.62202605500013, 10.334210781000051],
              [116.62119767600007, 10.334664915000056],
              [116.62072167900011, 10.336784778000039],
              [116.62065106, 10.338467901000035],
              [116.62157625400003, 10.340320096000076],
              [116.62278649499999, 10.340550324000029],
              [116.62385529000005, 10.339964386000043],
              [116.62391170300009, 10.337913657000096],
              [116.62356809000008, 10.336457611000059],
              [116.62291898400011, 10.335294176000074],
            ],
          ],
          [
            [
              [116.50067559400004, 10.28665587400006],
              [116.49873413100008, 10.285710719000106],
              [116.48794391700009, 10.286148179000064],
              [116.48376381099999, 10.288418759000082],
              [116.48022637000005, 10.291713882000051],
              [116.47699977400006, 10.295696797000085],
              [116.47216883500003, 10.299324319000023],
              [116.46833177900008, 10.305643280000075],
              [116.46807066600006, 10.308735766000064],
              [116.46941384400009, 10.312183694000064],
              [116.47183661400008, 10.314397209000068],
              [116.47576467800002, 10.319701858000128],
              [116.47647899800006, 10.323315338000059],
              [116.47915497000004, 10.329090947000095],
              [116.48140394700013, 10.332222073000084],
              [116.48480703699998, 10.336777466000056],
              [116.48846704100004, 10.339851421000022],
              [116.49325354900012, 10.341969042000086],
              [116.49513105100003, 10.342943659000079],
              [116.49656618800012, 10.341764694000053],
              [116.498858731, 10.337398785000039],
              [116.50110088900007, 10.333525253000085],
              [116.50405992800003, 10.329902813000034],
              [116.50513407800008, 10.32768776500008],
              [116.50722521100009, 10.32529133300004],
              [116.51006322200007, 10.322164075000016],
              [116.51115515000001, 10.319528026000045],
              [116.51416647000011, 10.316323658000035],
              [116.51706513400005, 10.314664836000096],
              [116.52046863400003, 10.31211231900002],
              [116.52188157800003, 10.309845952000083],
              [116.52304962400005, 10.307346867000076],
              [116.52323311300005, 10.304957885000119],
              [116.52323692900009, 10.303346708000037],
              [116.52203930200004, 10.300873850000068],
              [116.51886448600007, 10.297570830000067],
              [116.51595062700002, 10.295447906],
              [116.51405366000002, 10.293143547000037],
              [116.50915071300007, 10.290751167000066],
              [116.50405656500001, 10.288856742000084],
              [116.50247896600004, 10.288290398000031],
              [116.5016434150001, 10.287366096000115],
              [116.50067559400004, 10.28665587400006],
            ],
          ],
          [
            [
              [116.63745638700009, 10.335885532000065],
              [116.63629902400007, 10.335233144000087],
              [116.63500949200014, 10.335636343000052],
              [116.635047685, 10.33822511500002],
              [116.63494488200003, 10.340819709000113],
              [116.63509763400005, 10.34277369600005],
              [116.63570570700008, 10.343798787000045],
              [116.6365957940001, 10.343972134000088],
              [116.63736862700001, 10.343481436000078],
              [116.63800874400013, 10.34230365100006],
              [116.63872547500002, 10.340873899000067],
              [116.63914255500006, 10.339036449000092],
              [116.63918072400011, 10.338264778000015],
              [116.63885170500006, 10.337158186000027],
              [116.63745638700009, 10.335885532000065],
            ],
          ],
          [
            [
              [116.70008470000008, 10.343366627000082],
              [116.70219854300001, 10.341599068000027],
              [116.70324925600008, 10.339735664000036],
              [116.7040417210001, 10.338442916000099],
              [116.70314269100005, 10.337220442000026],
              [116.70125108500012, 10.33589920000013],
              [116.69742754200004, 10.335638019000013],
              [116.69187117000003, 10.334468807000084],
              [116.68857011900002, 10.333205924000042],
              [116.68431787900008, 10.332822306000098],
              [116.68148108900002, 10.332519929000037],
              [116.67861479400008, 10.331518857000075],
              [116.67469762900008, 10.332380957000085],
              [116.67136192800004, 10.33363886400007],
              [116.67069873000005, 10.336325914000087],
              [116.6714860980001, 10.338253051000022],
              [116.67466386800004, 10.339941236000081],
              [116.67921586400008, 10.3407326010001],
              [116.68188818400003, 10.340481903000052],
              [116.68547122700011, 10.341733212000024],
              [116.69016426300001, 10.342518480000072],
              [116.69495689300008, 10.342319648000052],
              [116.69628469000007, 10.343664362000114],
              [116.69860514200005, 10.345107837000088],
              [116.70008470000008, 10.343366627000082],
            ],
          ],
          [
            [
              [116.65686177600004, 10.341385543000035],
              [116.65607155000012, 10.341068132000062],
              [116.65502581700001, 10.341391370000073],
              [116.65330452400005, 10.342442616000101],
              [116.6529315680001, 10.343648188000108],
              [116.65297572500003, 10.344696530000073],
              [116.65322255700002, 10.345526461000086],
              [116.654485698, 10.345334268000082],
              [116.65583627300011, 10.34470304900008],
              [116.65703480800006, 10.344653535000068],
              [116.65786316900002, 10.344199740000091],
              [116.6580722570001, 10.343295812000084],
              [116.65725845100009, 10.342419298000037],
              [116.65686177600004, 10.341385543000035],
            ],
          ],
          [
            [
              [116.735186838, 10.34862532500007],
              [116.7311507850001, 10.346693789000126],
              [116.72735668599998, 10.347131834000043],
              [116.72178797600009, 10.345683913000057],
              [116.71823429400004, 10.345131892000035],
              [116.71595519500012, 10.344666789000099],
              [116.71434072800002, 10.346553704000081],
              [116.71401861100003, 10.348946819000025],
              [116.71427813500009, 10.351735704000118],
              [116.71702171000008, 10.353161406000043],
              [116.71985289500003, 10.353323567000048],
              [116.72382962900008, 10.353857952000032],
              [116.72656736300003, 10.355143805000109],
              [116.72857058900004, 10.355760292000047],
              [116.73167188300015, 10.355631075000103],
              [116.73467809800002, 10.353266014000083],
              [116.73618712200008, 10.352223215000043],
              [116.73624488900006, 10.35026102500005],
              [116.735186838, 10.34862532500007],
            ],
          ],
          [
            [
              [114.53110291000012, 10.342616917000045],
              [114.52820083200007, 10.340712039],
              [114.52631958200003, 10.341124511000109],
              [114.52309997900008, 10.340404103000099],
              [114.519640125, 10.339573900000063],
              [114.51752967000007, 10.340225720000083],
              [114.51415895500007, 10.342206255000084],
              [114.51117271500007, 10.345112669000043],
              [114.50885527900009, 10.346708699000116],
              [114.50553258700006, 10.350211685000053],
              [114.50332592700009, 10.351569746000065],
              [114.50056856400012, 10.354234472000099],
              [114.498498634, 10.35617445000012],
              [114.49740463400008, 10.359022045000097],
              [114.49713123900001, 10.361609728000063],
              [114.49771182700009, 10.364991626000107],
              [114.49911751700004, 10.366381437000081],
              [114.50128393, 10.367487989000066],
              [114.50391242600003, 10.368228057000104],
              [114.50650380800003, 10.36779550600002],
              [114.5090840270001, 10.367011144000047],
              [114.51152364000006, 10.365526877000073],
              [114.51368201400012, 10.362642828000011],
              [114.51524145100011, 10.359542642000095],
              [114.51717429000007, 10.357017713000081],
              [114.52056747499998, 10.355738451000105],
              [114.52518029699999, 10.353716930000088],
              [114.527157664, 10.35259901300005],
              [114.52946750600007, 10.350766477000127],
              [114.53116352800006, 10.348248825],
              [114.53131810300007, 10.345661852000097],
              [114.53110291000012, 10.342616917000045],
            ],
          ],
          [
            [
              [114.35344110500002, 10.359047331000058],
              [114.35166150000012, 10.358866798000042],
              [114.34982165600005, 10.360563838000035],
              [114.34837606900007, 10.363538529000047],
              [114.34785763600007, 10.365898948000039],
              [114.34769730900004, 10.368365779000042],
              [114.34946975500006, 10.36831208600004],
              [114.35143868800007, 10.366962829000046],
              [114.35441182100013, 10.365191946000072],
              [114.35583102000005, 10.363664289000061],
              [114.35589120699997, 10.361786699000051],
              [114.35501338000009, 10.360172016000122],
              [114.35344110500002, 10.359047331000058],
            ],
          ],
          [
            [
              [116.76315622600005, 10.357116420000077],
              [116.76089478000009, 10.357070997000049],
              [116.75863929300007, 10.357165295000035],
              [116.75640167200002, 10.357678770000065],
              [116.75429905400004, 10.358046605000125],
              [116.75418982300006, 10.362110791000044],
              [116.75651884700004, 10.367053053000051],
              [116.75720785400003, 10.369964015000031],
              [116.75872488000005, 10.37242042400006],
              [116.76146307000012, 10.373705862000103],
              [116.76533410000005, 10.372097645000087],
              [116.76796869400007, 10.368954066000045],
              [116.76955301500006, 10.366367958000032],
              [116.77039775299997, 10.364652739000045],
              [116.77086020900003, 10.362253584000046],
              [116.76954970100005, 10.361328523000067],
              [116.76741129300004, 10.360858058000042],
              [116.76534229400009, 10.358704820000051],
              [116.76315622600005, 10.357116420000077],
            ],
          ],
          [
            [
              [114.70103030200002, 10.333093350000054],
              [114.70312247200002, 10.328586848000041],
              [114.704962131, 10.325373572],
              [114.70487073000007, 10.322572561000063],
              [114.7025502500001, 10.320076546000044],
              [114.69812608100005, 10.31811507600011],
              [114.69658793000008, 10.314308895000044],
              [114.69473652900001, 10.309928593000082],
              [114.69289097700008, 10.30753417800012],
              [114.69022906800001, 10.305399485000065],
              [114.68605973300009, 10.304013812000077],
              [114.68543567700006, 10.303680702000049],
              [114.68152831700006, 10.303179703000074],
              [114.67828560699999, 10.301297032000088],
              [114.67551200700009, 10.297530187000079],
              [114.67390219000004, 10.295128163000109],
              [114.67159376000008, 10.292982012000083],
              [114.66877259400007, 10.293188646000127],
              [114.66534395800007, 10.292830441000065],
              [114.66092451200007, 10.290984978000049],
              [114.65852125500008, 10.289542661000082],
              [114.65587883300012, 10.287991113000048],
              [114.65442642800011, 10.288621358000059],
              [114.65375043200004, 10.289577405000026],
              [114.65595983600006, 10.292310982000073],
              [114.66064871400008, 10.295199405000053],
              [114.66423671800011, 10.296837681000069],
              [114.667494365, 10.299187369000061],
              [114.66944969300005, 10.301344817000045],
              [114.67022590300006, 10.305292186000068],
              [114.66996745299998, 10.308221072000022],
              [114.66763933500009, 10.312734583000081],
              [114.66589213300003, 10.316995971000063],
              [114.66097180400007, 10.323344366000063],
              [114.65686984800004, 10.327680566000106],
              [114.65175410700007, 10.329829122000065],
              [114.64565489800009, 10.330723614000091],
              [114.63768560000008, 10.330275197000025],
              [114.62897744699997, 10.33066766800008],
              [114.62108585100009, 10.330800318000042],
              [114.61432689700005, 10.331605071000103],
              [114.61817507400004, 10.33324523],
              [114.62124733300004, 10.333498261000072],
              [114.625960307, 10.333465998000067],
              [114.63627758600002, 10.333631644000095],
              [114.64277160700009, 10.334010055000052],
              [114.65043919100007, 10.334234196000034],
              [114.65813161800008, 10.333405794000059],
              [114.6641206840001, 10.332748103000087],
              [114.67116927100004, 10.332056517000064],
              [114.674492223, 10.332768525000089],
              [114.67995513300001, 10.335865760000027],
              [114.68305371600006, 10.340209963000023],
              [114.68515011600006, 10.343063937000084],
              [114.686824999, 10.347450188],
              [114.68883230399999, 10.352994216000065],
              [114.69056807500007, 10.355626036000041],
              [114.69289642400008, 10.358355762000125],
              [114.69639847300003, 10.360931152000051],
              [114.702565131, 10.363888643000061],
              [114.70700175700009, 10.366200309000105],
              [114.71062186700013, 10.368771727000057],
              [114.71280618600011, 10.370688040000074],
              [114.71443229800002, 10.373556947000038],
              [114.71705936500004, 10.376393783000102],
              [114.71825597300001, 10.376939632000058],
              [114.71952072800003, 10.375964367000011],
              [114.71968369800001, 10.373739174000105],
              [114.71745731500002, 10.37053903000009],
              [114.71328600700011, 10.365531776000035],
              [114.70887559000006, 10.36041530200009],
              [114.7062107480001, 10.356411306],
              [114.70216917000002, 10.351750315000084],
              [114.70027746800008, 10.347955246000049],
              [114.69928206400004, 10.342729527000037],
              [114.69894184500006, 10.337716576000043],
              [114.70103030200002, 10.333093350000054],
            ],
          ],
          [
            [
              [114.36556221300009, 10.374272034000052],
              [114.36220640899998, 10.372849823000061],
              [114.35995763399998, 10.372800834000094],
              [114.35833956800005, 10.374022309000084],
              [114.35502729900008, 10.374005524000028],
              [114.35305106899999, 10.375120576000095],
              [114.35310539300005, 10.376877510000069],
              [114.35432692600004, 10.378130101000059],
              [114.35852444500006, 10.379995872000068],
              [114.36188033100009, 10.381418159000065],
              [114.36499993200002, 10.382847562000029],
              [114.36775779900003, 10.384053434000061],
              [114.37026482100006, 10.384797949000111],
              [114.37261736300003, 10.384374712000087],
              [114.37339732200007, 10.382826878000094],
              [114.3734500960001, 10.380714950000057],
              [114.37173396500012, 10.378774059000049],
              [114.36869624400005, 10.376169884000051],
              [114.36556221300009, 10.374272034000052],
            ],
          ],
          [
            [
              [114.41144364400007, 10.374922144000019],
              [114.40977422400006, 10.37450411300007],
              [114.40648290300014, 10.375191099000068],
              [114.40415177400008, 10.376317797000032],
              [114.40241162000008, 10.3774264200001],
              [114.40140257000007, 10.37921633500005],
              [114.40087360400001, 10.381226149000076],
              [114.400769479, 10.381681560000098],
              [114.40070231700005, 10.383323542000065],
              [114.40158808700006, 10.385170709000045],
              [114.40426157600011, 10.38743182300008],
              [114.40713139000006, 10.388398382000029],
              [114.41140512, 10.388853394000106],
              [114.41587873400013, 10.388130816000094],
              [114.41911190100004, 10.387470331000069],
              [114.42110677299998, 10.386940127000075],
              [114.42211202700003, 10.385032926000102],
              [114.42179871200003, 10.382579760000075],
              [114.42057273000003, 10.381210016000074],
              [114.41728609900005, 10.378261541000098],
              [114.41329036100002, 10.37533472900005],
              [114.41144364400007, 10.374922144000019],
            ],
          ],
          [
            [
              [116.78484283300011, 10.37384692000005],
              [116.78369708600007, 10.373474978],
              [116.78237692300002, 10.375630185000071],
              [116.78123355500009, 10.378617930000098],
              [116.78278080800013, 10.381772907000107],
              [116.78617886599999, 10.385270281000112],
              [116.78813725000012, 10.388128042000043],
              [116.79113680800005, 10.390306746000098],
              [116.79430014900004, 10.388569458000061],
              [116.79639702300008, 10.38806146100004],
              [116.796748167, 10.386366787000066],
              [116.7955245280001, 10.384178247000085],
              [116.79334393800006, 10.382729837000058],
              [116.79213231000008, 10.380820773000019],
              [116.79036149100003, 10.377395259000098],
              [116.78744023700008, 10.375137931000021],
              [116.78484283300011, 10.37384692000005],
            ],
          ],
          [
            [
              [114.44676054800001, 10.389060679000027],
              [114.44935892400007, 10.388863532000057],
              [114.45292556700012, 10.389339808000029],
              [114.45545718800007, 10.390785554000013],
              [114.45780488199998, 10.392028924000039],
              [114.45981927800003, 10.392084194000082],
              [114.46346741099998, 10.391385010000052],
              [114.46676808499997, 10.390931174000071],
              [114.46789178900005, 10.389018999000131],
              [114.46976942299997, 10.388491664000108],
              [114.47316614200008, 10.387330699000062],
              [114.47527659500008, 10.386678799000036],
              [114.47727245500002, 10.386147746000061],
              [114.47957136, 10.383964503000074],
              [114.48155605899998, 10.38120424500004],
              [114.48314155000007, 10.378925710000074],
              [114.48425023700005, 10.376544560000054],
              [114.484412783, 10.374192702000061],
              [114.48399877900012, 10.372328040000054],
              [114.48264609000002, 10.370727111000109],
              [114.48112710400001, 10.36948333800005],
              [114.47818530900005, 10.370043647000047],
              [114.47440086900011, 10.370160585000042],
              [114.47146642100004, 10.370955257000068],
              [114.46852087800005, 10.371398204000036],
              [114.46418196300013, 10.372705472000108],
              [114.46152452700008, 10.374782223000077],
              [114.45942152400008, 10.375668422000038],
              [114.45496808700001, 10.37521891600006],
              [114.45225161500005, 10.37541989500008],
              [114.44781264500003, 10.377316631000076],
              [114.44372838900004, 10.379202354000048],
              [114.439647732, 10.381205234000033],
              [114.43557067700009, 10.383325272000119],
              [114.43235856800005, 10.384714649000058],
              [114.43007381900011, 10.387366296000076],
              [114.42801089600005, 10.389541754000041],
              [114.4266245920001, 10.390640348000078],
              [114.42678688400011, 10.39204349800009],
              [114.42793170399997, 10.392686337000077],
              [114.43147636099998, 10.392460394000034],
              [114.43296997000002, 10.391008071000039],
              [114.43637432199996, 10.390083100000068],
              [114.43954942000005, 10.389399538000086],
              [114.44439871300008, 10.389250503000101],
              [114.44676054800001, 10.389060679000027],
            ],
          ],
          [
            [
              [116.59051858599999, 10.39651895500011],
              [116.58932978600004, 10.396437292000103],
              [116.58799947200011, 10.397390355000089],
              [116.58781183100004, 10.397933824000026],
              [116.58791669900009, 10.398797284000059],
              [116.58839765100001, 10.399344137000098],
              [116.58935966300011, 10.399439920000118],
              [116.59036450200003, 10.399133127000047],
              [116.590863224, 10.3984607140001],
              [116.59095115199999, 10.397413971000089],
              [116.59051858599999, 10.39651895500011],
            ],
          ],
          [
            [
              [116.80614278300007, 10.394651919000085],
              [116.80471489000004, 10.394291928000053],
              [116.80365283900002, 10.395876509000066],
              [116.80388368000001, 10.397966763000086],
              [116.80226361300001, 10.399714782000034],
              [116.80055042600009, 10.402586691000069],
              [116.80201319199998, 10.403918464000038],
              [116.80427489400006, 10.402150252000107],
              [116.80648891200011, 10.401077247000055],
              [116.80846285900009, 10.400994280000118],
              [116.80867893200003, 10.399445222000024],
              [116.80687911499997, 10.397000917000108],
              [116.80614278300007, 10.394651919000085],
            ],
          ],
          [
            [
              [116.81166051800008, 10.409679783000046],
              [116.81077853000011, 10.408876869000048],
              [116.80895150300003, 10.409093670000045],
              [116.80692036100001, 10.411139041000082],
              [116.80698028499999, 10.412536519000069],
              [116.80761621400003, 10.414189804000083],
              [116.80860086599996, 10.414269980000055],
              [116.80997135600003, 10.413110806000068],
              [116.81135742100005, 10.412492536000071],
              [116.81214948600004, 10.411199227000088],
              [116.81166051800008, 10.409679783000046],
            ],
          ],
          [
            [
              [116.60389528800012, 10.410072166000093],
              [116.60261703300004, 10.409984282000016],
              [116.601538848, 10.411291759000017],
              [116.60133826000002, 10.413264995000036],
              [116.60213316700006, 10.415337747000118],
              [116.60293829200006, 10.415696226000113],
              [116.60438935600004, 10.415104777000026],
              [116.60489028500001, 10.413540258000074],
              [116.605102589, 10.411847246000059],
              [116.60389528800012, 10.410072166000093],
            ],
          ],
          [
            [
              [114.54960520200005, 10.322165009000093],
              [114.54836835500012, 10.32211618800005],
              [114.54716388800003, 10.324511965000037],
              [114.54690511400011, 10.327875887000102],
              [114.54511564200001, 10.332013228000035],
              [114.54384900500011, 10.336769155000079],
              [114.54379605300008, 10.340852298000057],
              [114.54498235800008, 10.343626986000041],
              [114.54518586000006, 10.345706746000081],
              [114.54461239300012, 10.347810785000087],
              [114.54497031800007, 10.350429957000083],
              [114.54576508400011, 10.352400574000063],
              [114.54474323100011, 10.354790746000029],
              [114.54423276200006, 10.357437065000051],
              [114.54460801600011, 10.360599950000092],
              [114.54743123000003, 10.363051470000071],
              [114.54966660800007, 10.367154012000055],
              [114.55320845500002, 10.373483349000077],
              [114.55629864300009, 10.378556939000115],
              [114.56061858000012, 10.386313251000077],
              [114.56259177700005, 10.390786864000033],
              [114.56422891, 10.397629442000065],
              [114.56457102900004, 10.402607743000123],
              [114.56574991500005, 10.406562070000101],
              [114.56874341200002, 10.408855993000097],
              [114.56950283900005, 10.409437929000108],
              [114.57295837000007, 10.41159738000008],
              [114.57587406399999, 10.414045853000074],
              [114.57783263400005, 10.416615044000109],
              [114.58043059500005, 10.417712770000074],
              [114.58291102300004, 10.417997736000116],
              [114.58454236600001, 10.417492942000093],
              [114.58817367399999, 10.416562434000108],
              [114.59051322600013, 10.415309629000101],
              [114.59198621699997, 10.412723382000076],
              [114.59191060700002, 10.410367268000085],
              [114.59137493600011, 10.407934918000093],
              [114.58962741000002, 10.404814989000053],
              [114.58630529500013, 10.401109572000065],
              [114.58179394400007, 10.397528276000044],
              [114.58010079100004, 10.396418188000032],
              [114.57689322000012, 10.39442047800012],
              [114.57494577700001, 10.390762529000062],
              [114.57236935800009, 10.386035815000037],
              [114.57070952100007, 10.379919779000078],
              [114.56787518400003, 10.372842847000106],
              [114.56571632500003, 10.368284527000069],
              [114.56123400200015, 10.361168562000065],
              [114.55975751000001, 10.35504696400009],
              [114.55824697200001, 10.349289302000029],
              [114.55862765800006, 10.344016701000045],
              [114.55834593900011, 10.339490477000089],
              [114.55663885600008, 10.334736802000053],
              [114.55470100800005, 10.33135084700003],
              [114.55079818200008, 10.328026323000115],
              [114.54960520200005, 10.322165009000093],
            ],
          ],
          [
            [
              [116.50721378900003, 10.352846295000083],
              [116.50461469300001, 10.351550015000031],
              [116.49953240600004, 10.35175623100004],
              [116.4972272980001, 10.350728283000075],
              [116.49496851600009, 10.350819866000014],
              [116.49456665300013, 10.351360795000096],
              [116.49732750800004, 10.356569530000035],
              [116.50156974700005, 10.363398412],
              [116.50669411900002, 10.37103161500008],
              [116.50915064299997, 10.379122092000069],
              [116.50912335500001, 10.385291432000086],
              [116.50939005799998, 10.391729268000025],
              [116.51208248899999, 10.395264856000084],
              [116.51467058199999, 10.396281233000069],
              [116.52004422600007, 10.403072397000111],
              [116.52362892000011, 10.411057824000112],
              [116.52782700600009, 10.416775221000039],
              [116.53049682000002, 10.419750874000043],
              [116.53263672400006, 10.423589262000108],
              [116.5370479500001, 10.42761556200009],
              [116.53839038300006, 10.425878502000073],
              [116.53882726900005, 10.422776359000084],
              [116.53802962799996, 10.417201007000097],
              [116.53567706200009, 10.415053706000064],
              [116.53225296000002, 10.414351961000088],
              [116.53315010499999, 10.408707663000081],
              [116.5329989210001, 10.405068797000093],
              [116.52830584200001, 10.40105406000009],
              [116.52319912399999, 10.400701051000015],
              [116.5208817370001, 10.399393369000085],
              [116.51905954200005, 10.396383255000073],
              [116.52273453000009, 10.389504719000085],
              [116.523210046, 10.380513352000074],
              [116.5232949880001, 10.375743563000038],
              [116.51919072899997, 10.372265633000042],
              [116.51480228500006, 10.37216363700013],
              [116.510663462, 10.367845865000012],
              [116.50718226800009, 10.365744294000121],
              [116.50584417800005, 10.360752042000064],
              [116.50731810600004, 10.355365316000052],
              [116.50721378900003, 10.352846295000083],
            ],
          ],
          [
            [
              [116.68925086500012, 10.425195887000081],
              [116.68889682900013, 10.423527185000035],
              [116.68762366900003, 10.42357996200005],
              [116.6859734950001, 10.424770608000065],
              [116.6861978740001, 10.426725251000056],
              [116.68671804000007, 10.427655611000118],
              [116.68818431100007, 10.426783197000054],
              [116.68925086500012, 10.425195887000081],
            ],
          ],
          [
            [
              [116.8167349930001, 10.427526620000014],
              [116.81611094800007, 10.426152829000033],
              [116.81437374100004, 10.428465967000109],
              [116.81276551800012, 10.430493658000016],
              [116.81243241199999, 10.432607737000053],
              [116.81385179600011, 10.433781434000087],
              [116.81517628100001, 10.43235233700006],
              [116.81623842300004, 10.430767605000122],
              [116.81721805100001, 10.428906344000042],
              [116.8167349930001, 10.427526620000014],
            ],
          ],
          [
            [
              [116.54326915700005, 10.43770529000003],
              [116.54065645100006, 10.436130834000055],
              [116.54038553400002, 10.436422031000079],
              [116.53870188699999, 10.436770774000069],
              [116.5385937680001, 10.440977547000061],
              [116.53955773200001, 10.443739856000088],
              [116.54184142700005, 10.444207118000024],
              [116.54312622800003, 10.441073012000055],
              [116.54326915700005, 10.43770529000003],
            ],
          ],
          [
            [
              [116.82609808500005, 10.44323321600009],
              [116.82519788899999, 10.442011048000042],
              [116.82379965800013, 10.442349939000016],
              [116.82092817900003, 10.444570931000017],
              [116.82100622600001, 10.446387745000061],
              [116.82265479799999, 10.446856286000104],
              [116.82342170400003, 10.446706100000112],
              [116.82518899200015, 10.445091602000021],
              [116.82609808500005, 10.44323321600009],
            ],
          ],
          [
            [
              [116.63159819300006, 10.44416389500009],
              [116.62953619599998, 10.44228368500004],
              [116.62699106300006, 10.442388454000094],
              [116.62653143400004, 10.444933956000034],
              [116.62690840399998, 10.447164309000099],
              [116.62677877899999, 10.447450372000054],
              [116.62917080200012, 10.447071173000031],
              [116.631409631, 10.446417518000127],
              [116.63159819300006, 10.44416389500009],
            ],
          ],
          [
            [
              [116.55626049600008, 10.450623043000105],
              [116.55391843100001, 10.448757497000114],
              [116.54998586400006, 10.449478262000065],
              [116.54577069600009, 10.450210484000076],
              [116.54328580400009, 10.451712601000017],
              [116.54301487100005, 10.452003809000042],
              [116.54402548300001, 10.455884908000089],
              [116.54798148500004, 10.455723613000066],
              [116.55268035700007, 10.45301046000011],
              [116.55575362500007, 10.452044543000037],
              [116.55626049600008, 10.450623043000105],
            ],
          ],
          [
            [
              [115.73250605300008, 10.459766781000022],
              [115.73179398300014, 10.459493059000112],
              [115.73088267800006, 10.459986864000113],
              [115.72986394799997, 10.460714719000029],
              [115.72839388800001, 10.461804373000037],
              [115.72772336600005, 10.462519383000044],
              [115.72775795900006, 10.463438504000065],
              [115.7279086220001, 10.464353349000087],
              [115.72953802100008, 10.464408331000106],
              [115.73067717400011, 10.463791076000104],
              [115.73145078399999, 10.462727098000089],
              [115.73234478900007, 10.461773729000077],
              [115.73289488000002, 10.460948096000065],
              [115.73298067600001, 10.460139579000048],
              [115.73273555400007, 10.459803467000127],
              [115.73250605300008, 10.459766781000022],
            ],
          ],
          [
            [
              [116.64246283800007, 10.459998887000042],
              [116.64130799000009, 10.459485020000068],
              [116.63968184400001, 10.461236497000035],
              [116.63943440199996, 10.462088911000043],
              [116.63983512600008, 10.464879803000125],
              [116.64147310200008, 10.463408583000076],
              [116.6428164270001, 10.461668748000095],
              [116.64246283800007, 10.459998887000042],
            ],
          ],
          [
            [
              [116.55614983400008, 10.461554014000017],
              [116.55525549199997, 10.460469846000107],
              [116.55299487400006, 10.460562107000014],
              [116.5485318450001, 10.462145003000066],
              [116.54915522799999, 10.463520422],
              [116.55121483200006, 10.465397594000068],
              [116.55455922500001, 10.464140452000057],
              [116.55614983400008, 10.461554014000017],
            ],
          ],
          [
            [
              [116.83515321100001, 10.455032526000084],
              [116.83369477900013, 10.453973915000068],
              [116.8323686120001, 10.455989952000076],
              [116.83122553300011, 10.458978356000069],
              [116.82963526500006, 10.461425551000046],
              [116.82935637500009, 10.464797600000074],
              [116.83029877300007, 10.466998111000068],
              [116.83113802000005, 10.467313411000044],
              [116.833200515, 10.465475716000048],
              [116.8340314740001, 10.461800366000061],
              [116.83628477900002, 10.458345089000074],
              [116.83648865800001, 10.45651634600002],
              [116.83515321100001, 10.455032526000084],
            ],
          ],
          [
            [
              [115.78896734800007, 10.45910228300002],
              [115.78786605399999, 10.45764753700009],
              [115.78619748500005, 10.456559033000088],
              [115.7836047190001, 10.455619868],
              [115.78069423400007, 10.455497818000124],
              [115.77918531700001, 10.455553825000051],
              [115.77837716300012, 10.45569885500006],
              [115.77361823700008, 10.455875387000011],
              [115.77130115500003, 10.456076346000119],
              [115.77074687600009, 10.456787193000048],
              [115.77134462700008, 10.457225232000054],
              [115.77310745800001, 10.457735117000054],
              [115.77496896600012, 10.457781138000131],
              [115.777406488, 10.457690712000021],
              [115.77880805900006, 10.457868805000102],
              [115.7815212600001, 10.458918615000099],
              [115.78320723, 10.460466724000115],
              [115.78408071500004, 10.462044994000051],
              [115.78496291300003, 10.463853048000081],
              [115.78597428300004, 10.466001467000028],
              [115.78673173200002, 10.467584051000054],
              [115.7877300590001, 10.469387798000083],
              [115.78871097500009, 10.470731983000102],
              [115.78931752900009, 10.471399757000109],
              [115.79037096600004, 10.4715907240001],
              [115.79129088800003, 10.471326428000081],
              [115.79172904400005, 10.470619825000059],
              [115.79167673800006, 10.469241143000117],
              [115.79148655900005, 10.467292327000076],
              [115.79102501, 10.464318139000016],
              [115.7902065210001, 10.461127141000047],
              [115.78896734800007, 10.45910228300002],
            ],
          ],
          [
            [
              [116.8532172, 10.474788041000059],
              [116.84721076200006, 10.472806561000068],
              [116.84468379800005, 10.473052897000022],
              [116.84690274900007, 10.475473730000127],
              [116.85005271500009, 10.476598],
              [116.8503136500001, 10.479380870000019],
              [116.85017043900007, 10.482599898000087],
              [116.85179657100005, 10.48434727300007],
              [116.85678821100007, 10.485673091000079],
              [116.86156710300006, 10.4869679340001],
              [116.86343510499998, 10.48451687500007],
              [116.86490493200009, 10.482640780000095],
              [116.86564705800004, 10.480237325000095],
              [116.86526938799996, 10.478020765000016],
              [116.86375094100001, 10.477136754000059],
              [116.85989728, 10.476042438000109],
              [116.85646581700003, 10.474930200000097],
              [116.8532172, 10.474788041000059],
            ],
          ],
          [
            [
              [116.87892627599999, 10.495263308000078],
              [116.87759034800004, 10.493781445000126],
              [116.876332894, 10.494114407000053],
              [116.87685264200002, 10.496330148000064],
              [116.87752552400001, 10.498819119000034],
              [116.87578224800009, 10.500990833000044],
              [116.87432709600004, 10.503290201000047],
              [116.87481668200004, 10.504807939000036],
              [116.87715443400012, 10.503310356000027],
              [116.87971391000006, 10.500404749000046],
              [116.88091705200004, 10.498815319000075],
              [116.88286775800006, 10.498173228000061],
              [116.88253731500008, 10.497068363000038],
              [116.88039725400003, 10.496599588000064],
              [116.87892627599999, 10.495263308000078],
            ],
          ],
          [
            [
              [116.697056726, 10.504689457],
              [116.69590148100008, 10.504175854000051],
              [116.69427544600001, 10.505927870000063],
              [116.69402817800007, 10.506780418000082],
              [116.69442973900007, 10.509571405000083],
              [116.69606767200005, 10.508099654000079],
              [116.697410827, 10.506359345000059],
              [116.697056726, 10.504689457],
            ],
          ],
          [
            [
              [115.7058600790001, 10.48391836300002],
              [115.70481096800009, 10.483841865000086],
              [115.70435092300012, 10.48397382600008],
              [115.70390811900008, 10.484565365],
              [115.70278170800005, 10.485527186000086],
              [115.70200786400005, 10.486591088000095],
              [115.7015952080001, 10.487986890000032],
              [115.70164691500013, 10.489365644000069],
              [115.70193512099999, 10.490850753000112],
              [115.70267908600007, 10.492089026000071],
              [115.70414548200009, 10.493991070000062],
              [115.7057323140001, 10.496003741000058],
              [115.70671282900005, 10.497348367000113],
              [115.707689036, 10.498578091000075],
              [115.70891902000008, 10.500373766000058],
              [115.71003292400005, 10.502173717000039],
              [115.71133198400007, 10.505807751000072],
              [115.71245888500005, 10.507952390000062],
              [115.71355991300001, 10.509407639000033],
              [115.71501790900004, 10.511079874000105],
              [115.7168285580001, 10.512854168],
              [115.71777898800005, 10.513394472000035],
              [115.71881528900009, 10.513126213000044],
              [115.71936558000006, 10.512300547000029],
              [115.71977816400009, 10.510904657000085],
              [115.71937370400011, 10.509423782000036],
              [115.71849187799998, 10.507615321000062],
              [115.71725312200006, 10.505589890000076],
              [115.71623796900009, 10.503326112000087],
              [115.71487885300007, 10.501190054000096],
              [115.71349387000006, 10.498364607000086],
              [115.71198851400003, 10.495428544000072],
              [115.71062950400002, 10.493292484000081],
              [115.70890498499998, 10.490709637000071],
              [115.70767937100001, 10.489028888000094],
              [115.70728797800004, 10.487892742000058],
              [115.70736525400004, 10.486854416000122],
              [115.70743391300003, 10.485586307000093],
              [115.70681033700014, 10.484458702000053],
              [115.7058600790001, 10.48391836300002],
            ],
          ],
          [
            [
              [116.56603368900004, 10.493230443000099],
              [116.56309718100007, 10.492398387],
              [116.55975242400008, 10.493657014000044],
              [116.55544761200007, 10.495656047000066],
              [116.55234321100006, 10.499289091000017],
              [116.55155811800003, 10.504230057000095],
              [116.55184522900007, 10.507724751000056],
              [116.55355143000011, 10.511301841000085],
              [116.55655942800004, 10.513843997000015],
              [116.55960898300012, 10.515683134000129],
              [116.56271233000002, 10.515416142000065],
              [116.56546875200002, 10.51362043600008],
              [116.56819964100011, 10.511213981000099],
              [116.56971455900012, 10.506809120000058],
              [116.57029272100009, 10.503703417000041],
              [116.56953482700008, 10.499111319000052],
              [116.56827590900002, 10.496080735000122],
              [116.56603368900004, 10.493230443000099],
            ],
          ],
          [
            [
              [116.65156994700007, 10.516434646000048],
              [116.6512214520001, 10.515961322000054],
              [116.65065773400008, 10.516228435000039],
              [116.64991976800005, 10.516258883000072],
              [116.64960887000008, 10.516678140000034],
              [116.64931508100001, 10.51750311300008],
              [116.649274105, 10.518480234000014],
              [116.64946202500001, 10.519041473000089],
              [116.65013168000007, 10.519338993000103],
              [116.65103364900001, 10.519301777000043],
              [116.65150511200002, 10.51879459700009],
              [116.65172375500001, 10.518135289000073],
              [116.65169298200013, 10.517404999000044],
              [116.65156994700007, 10.516434646000048],
            ],
          ],
          [
            [
              [115.72755816700005, 10.526093583000049],
              [115.72645293700009, 10.52607678000008],
              [115.72594556300012, 10.526498196000132],
              [115.72536642700007, 10.527294495000115],
              [115.72556110000002, 10.528641003000059],
              [115.72589893599999, 10.529894232000101],
              [115.72646684900009, 10.531081462000101],
              [115.72726916700003, 10.532317579000059],
              [115.728189766, 10.533606869000065],
              [115.72952111900011, 10.534996073000078],
              [115.73089540000004, 10.535980979000035],
              [115.73225671200007, 10.536621160000069],
              [115.73396640400003, 10.537248484000104],
              [115.73519646300011, 10.537490768000078],
              [115.73659638600012, 10.537611721000049],
              [115.73840054000001, 10.537660206000064],
              [115.7403767190001, 10.537644793000121],
              [115.74206044000005, 10.537582635000129],
              [115.74396989100003, 10.537339538000088],
              [115.745874994, 10.53698150000004],
              [115.74695210500013, 10.536251334000022],
              [115.74733250200008, 10.535546904000091],
              [115.74730865700006, 10.534914925000026],
              [115.746985846, 10.534063880000042],
              [115.74585674100001, 10.533415218000107],
              [115.74404177000011, 10.533079545000044],
              [115.74151712900004, 10.532424854000054],
              [115.73916882700007, 10.531821167000109],
              [115.73699038600003, 10.531096133000071],
              [115.73538388500008, 10.530119841000028],
              [115.73193435400003, 10.528060894000111],
              [115.730450495, 10.527252644000027],
              [115.72868069600011, 10.526569988000032],
              [115.72755816700005, 10.526093583000049],
            ],
          ],
          [
            [
              [116.57386290100008, 10.523095504000056],
              [116.56944655900007, 10.522434497000075],
              [116.56611887799998, 10.524113355000074],
              [116.56768996100003, 10.527836212000084],
              [116.56926695500013, 10.531699105000046],
              [116.57255178300014, 10.535772713000123],
              [116.57532497100009, 10.537763269000065],
              [116.57766341000003, 10.536124698000025],
              [116.57983491300003, 10.531176292000042],
              [116.57906692000007, 10.529054121000046],
              [116.57673536600007, 10.527466369000125],
              [116.57463972499997, 10.524746865000088],
              [116.57386290100008, 10.523095504000056],
            ],
          ],
          [
            [
              [115.80215668399997, 10.533974337000094],
              [115.80144513700007, 10.530548892000089],
              [115.80029098500006, 10.527715256000112],
              [115.799975828, 10.525540781000052],
              [115.79975933100003, 10.522902403000066],
              [115.79930625700003, 10.520157782000082],
              [115.79914477700007, 10.51590653200005],
              [115.79864372600001, 10.511898048000122],
              [115.79786685900004, 10.506749259000079],
              [115.79628171700006, 10.501745619000056],
              [115.79375917000007, 10.496546766000046],
              [115.79199870199997, 10.493045434000068],
              [115.79142971300006, 10.490305231000095],
              [115.79104496600011, 10.486292577000087],
              [115.79119431800004, 10.484100975000031],
              [115.79117526500015, 10.480534997000033],
              [115.79140362400003, 10.479495822000027],
              [115.78559000900005, 10.473990456000058],
              [115.78229562400006, 10.466237789000042],
              [115.78016001900001, 10.465108723000059],
              [115.77825227800008, 10.463856113000068],
              [115.77641784700009, 10.463003545000047],
              [115.77498327600006, 10.461963509000059],
              [115.77367787100005, 10.461263905000083],
              [115.77261988699999, 10.460957904000077],
              [115.77025283100008, 10.461390890000109],
              [115.76874724600012, 10.462255356000048],
              [115.76707677800002, 10.462662492000073],
              [115.76435981100009, 10.462357252000041],
              [115.76340273600013, 10.461644684000058],
              [115.76244133400007, 10.460817203000071],
              [115.76148646300013, 10.460162091000045],
              [115.76107141900006, 10.459947299000037],
              [115.75762524000002, 10.461053020000042],
              [115.75503679000009, 10.461781729000029],
              [115.75245048100005, 10.462567853000058],
              [115.74992871400003, 10.463524183000048],
              [115.74729516100001, 10.464599694000041],
              [115.74536687400007, 10.465879268000043],
              [115.74334412700003, 10.467737716000068],
              [115.74178360699997, 10.469521544000047],
              [115.74071427100004, 10.470363312000062],
              [115.73853254800009, 10.47107665000002],
              [115.73683856, 10.470851538000135],
              [115.73560022400012, 10.47037951200007],
              [115.73415571700006, 10.470605378000107],
              [115.73317552000006, 10.470814125000059],
              [115.72906796500001, 10.472863946000109],
              [115.72741935400003, 10.473845130000113],
              [115.726179179, 10.474868787000078],
              [115.72516468900008, 10.475711531000085],
              [115.72439748000011, 10.476947851000061],
              [115.72380223299997, 10.478120308000081],
              [115.72358115799997, 10.479969283000033],
              [115.7236394920001, 10.481520359000028],
              [115.723491284, 10.482216132000042],
              [115.72345431400005, 10.482255550000017],
              [115.71882906300007, 10.48532459400011],
              [115.71962270100002, 10.486331112000078],
              [115.72015199100011, 10.488037854000074],
              [115.72028566200007, 10.490046872000034],
              [115.72113334500004, 10.492489931000113],
              [115.72232941299997, 10.49492017500002],
              [115.72451473200013, 10.497371517000074],
              [115.72689375700006, 10.500333581000108],
              [115.72875891800001, 10.503544750000058],
              [115.72956384700001, 10.506392218000114],
              [115.72975352900006, 10.508341680000031],
              [115.73005933000006, 10.510286885000129],
              [115.73114994700006, 10.511455074000063],
              [115.73268996500011, 10.512203883000025],
              [115.74019696200006, 10.510352898000084],
              [115.74337973800004, 10.509947657000039],
              [115.74056529599997, 10.511337857000045],
              [115.73910438699998, 10.514834773000102],
              [115.73645919700004, 10.519237160000026],
              [115.73527844600005, 10.521812957000074],
              [115.74997717699996, 10.534758802000075],
              [115.75083503800006, 10.534381907000066],
              [115.75238308800007, 10.533806886000095],
              [115.75426644700012, 10.532874286000025],
              [115.75581013600004, 10.532184337000048],
              [115.75765061900009, 10.531656003000121],
              [115.75932559200012, 10.531363899000022],
              [115.76164135400006, 10.531105596000055],
              [115.76442158400005, 10.530830060000083],
              [115.766743862, 10.530744057000073],
              [115.76866191500007, 10.530730527000019],
              [115.77035643200011, 10.530955404000062],
              [115.77246606700005, 10.531394992000019],
              [115.77552638100005, 10.532374628000072],
              [115.77783195200003, 10.53338221900013],
              [115.77966874900004, 10.534292122000098],
              [115.7811572060001, 10.535214928000068],
              [115.78282638800009, 10.536303627000086],
              [115.78430398300004, 10.53693916100009],
              [115.78604868400008, 10.53848528400003],
              [115.78761923, 10.540037864000061],
              [115.78927540800007, 10.540781803000064],
              [115.79090107000002, 10.54072140100004],
              [115.79344260700007, 10.54028176500008],
              [115.7964485980001, 10.539824821000108],
              [115.79920925899999, 10.539031757000126],
              [115.80080871200008, 10.538281877000076],
              [115.80158660500005, 10.537332418000032],
              [115.80222655, 10.535812766000062],
              [115.80215668399997, 10.533974337000094],
            ],
          ],
          [
            [
              [116.69682306000003, 10.539451670000053],
              [116.69649265300005, 10.538342273000099],
              [116.69550241900001, 10.538383334000033],
              [116.69538470400003, 10.538949771000093],
              [116.69320441100002, 10.54100564100011],
              [116.69276911699998, 10.544112263000025],
              [116.69418375400005, 10.544053609000057],
              [116.69578634700005, 10.541740906000106],
              [116.69682306000003, 10.539451670000053],
            ],
          ],
          [
            [
              [116.59849630699998, 10.551822753000105],
              [116.59842592100001, 10.550142430000074],
              [116.59571684100004, 10.549692430000055],
              [116.59407927700008, 10.551162243000077],
              [116.59121711500006, 10.55043793000001],
              [116.58870872600008, 10.548015889000071],
              [116.58689540200005, 10.545284831000055],
              [116.5882151430001, 10.542986523000051],
              [116.59203144200002, 10.542830164000081],
              [116.59336868300008, 10.540951903000105],
              [116.59315113900003, 10.539137373000072],
              [116.590248136, 10.537432899000086],
              [116.58778677400009, 10.536131081000109],
              [116.58696216400007, 10.536725925000033],
              [116.58624882200003, 10.539981217000081],
              [116.58534725900003, 10.542122113000028],
              [116.58168254700004, 10.5438382],
              [116.58376780200001, 10.546675291000083],
              [116.58606371000008, 10.550789286000038],
              [116.58857172600003, 10.554894609000074],
              [116.59191073899999, 10.556861846000041],
              [116.59430203900004, 10.556483298000073],
              [116.59674084700012, 10.553858486000054],
              [116.59849630699998, 10.551822753000105],
            ],
          ],
          [
            [
              [116.903808959, 10.550232846000094],
              [116.90586548899998, 10.54888846900006],
              [116.90883291200011, 10.54904172900004],
              [116.91359202600006, 10.551493218000106],
              [116.92024135300008, 10.555260936000098],
              [116.92782151400004, 10.557731768000076],
              [116.93447431900009, 10.559660844000083],
              [116.93571222300002, 10.559816528000086],
              [116.93947715100005, 10.559888942000038],
              [116.94295304900005, 10.558762986000135],
              [116.94495472600012, 10.556163463000077],
              [116.94680346900005, 10.553291107000094],
              [116.94732130899999, 10.550615217000113],
              [116.94693048400008, 10.548117795000065],
              [116.944985598, 10.545547017000077],
              [116.94096237000011, 10.537338391000082],
              [116.93800587000008, 10.53257604300004],
              [116.93559053700008, 10.528907964000032],
              [116.93196953800006, 10.526687922000109],
              [116.92894784300006, 10.525280297000082],
              [116.92439654200007, 10.524356769000052],
              [116.91780869200007, 10.52198345700006],
              [116.91286335599999, 10.520098783],
              [116.908263842, 10.51805964200012],
              [116.90359792800004, 10.514486938000099],
              [116.90011218100001, 10.512121006000095],
              [116.89705467400007, 10.509876527000104],
              [116.89537227000005, 10.510087591000071],
              [116.89547494700003, 10.512457493000047],
              [116.89745515700008, 10.515865048000082],
              [116.899288672, 10.519139159000089],
              [116.89868785400003, 10.521538966000053],
              [116.89655728900004, 10.524422696000089],
              [116.89298145800004, 10.529742057000123],
              [116.88980949200004, 10.53462526500004],
              [116.88757813000007, 10.536814895000111],
              [116.88268705500003, 10.541072560000051],
              [116.87990670200011, 10.545240747000049],
              [116.87610495600006, 10.5486141480001],
              [116.87331834100007, 10.552642872000105],
              [116.8727070110001, 10.556439879000033],
              [116.87343267100002, 10.560180329000078],
              [116.87606845100007, 10.562443205000081],
              [116.8777693830001, 10.562650542000094],
              [116.88041329600001, 10.561840206000015],
              [116.88380507400008, 10.558763390000079],
              [116.88820604700007, 10.556202441000016],
              [116.89298501500006, 10.555860122000114],
              [116.9008860070001, 10.552731399000058],
              [116.903808959, 10.550232846000094],
            ],
          ],
          [
            [
              [116.61974470400001, 10.567221622000121],
              [116.61962122200011, 10.564280996],
              [116.61688839100012, 10.563271156000019],
              [116.61325422399999, 10.56440236300009],
              [116.60933728799999, 10.565545127000052],
              [116.60664558500004, 10.565515361000076],
              [116.60457472400002, 10.568044274000064],
              [116.60706290200001, 10.572091007000049],
              [116.61014314100005, 10.574629727000049],
              [116.61252872200009, 10.574110946000113],
              [116.61642229300001, 10.572407986000075],
              [116.61844280600003, 10.569940312000091],
              [116.61974470400001, 10.567221622000121],
            ],
          ],
          [
            [
              [116.86080787800003, 10.570624198000099],
              [116.8600422120001, 10.569256594000096],
              [116.85849647400001, 10.56946189300003],
              [116.85638405100011, 10.572911064000044],
              [116.85356014800004, 10.576250268000107],
              [116.85336238000002, 10.578218588000075],
              [116.85440416900008, 10.579434591000057],
              [116.85462180800005, 10.579565394000044],
              [116.85694355600003, 10.579327339000065],
              [116.85855204900008, 10.57729941000005],
              [116.85954193000009, 10.574037627000086],
              [116.86080787800003, 10.570624198000099],
            ],
          ],
          [
            [
              [116.62753681400001, 10.577421642000038],
              [116.6270538680001, 10.576038775000105],
              [116.625528257, 10.576802902000091],
              [116.6251924190001, 10.578920833],
              [116.62295405699999, 10.579573991000045],
              [116.62109112500005, 10.580520457000107],
              [116.62207034800007, 10.582135282000047],
              [116.62350758400005, 10.582637281000105],
              [116.62531010500007, 10.581721488000094],
              [116.62677101000006, 10.579417001000111],
              [116.62753681400001, 10.577421642000038],
            ],
          ],
          [
            [
              [116.64479575300001, 10.58540791600011],
              [116.64252790400003, 10.585361116000056],
              [116.63956498700011, 10.585623494000094],
              [116.63673753500002, 10.58573996400005],
              [116.63553587400007, 10.587472777000071],
              [116.63534148800007, 10.587901606000026],
              [116.63888176800006, 10.589579394000024],
              [116.64200970099998, 10.589871341000022],
              [116.64468994300005, 10.589620591000058],
              [116.64549690400011, 10.58860537400008],
              [116.64597981200009, 10.586621594000077],
              [116.64479575300001, 10.58540791600011],
            ],
          ],
          [
            [
              [116.656353524, 10.590822771000051],
              [116.65629446200001, 10.589422435000094],
              [116.65445659700008, 10.589498291000064],
              [116.65225953100001, 10.591132014000058],
              [116.65233626500006, 10.592952454000047],
              [116.65248944800013, 10.593226694000089],
              [116.6544569270001, 10.59286494700009],
              [116.656353524, 10.590822771000051],
            ],
          ],
          [
            [
              [116.84775778700011, 10.586067580000067],
              [116.84710912400008, 10.584134958000034],
              [116.8440414810001, 10.58510436000004],
              [116.84093768600007, 10.585235241000122],
              [116.83844034600011, 10.586320520000035],
              [116.83857874500002, 10.589534696000056],
              [116.83704216400007, 10.593239477000081],
              [116.837471822, 10.595011683000076],
              [116.84163181100004, 10.591506],
              [116.84478646400011, 10.589272947000071],
              [116.84697152600006, 10.587500762000078],
              [116.84775778700011, 10.586067580000067],
            ],
          ],
          [
            [
              [116.66925441800001, 10.592814835000029],
              [116.66738105, 10.592050600000034],
              [116.66586729399998, 10.593095120000038],
              [116.66552567700005, 10.593389788000083],
              [116.66755844100011, 10.596251645000086],
              [116.66892548500006, 10.598439619000109],
              [116.67091664600005, 10.59863785300008],
              [116.67112249700003, 10.596805703000099],
              [116.67060962700006, 10.594722714000058],
              [116.66925441800001, 10.592814835000029],
            ],
          ],
          [
            [
              [116.68423802300003, 10.607204917000066],
              [116.68402002200003, 10.607073651000041],
              [116.6817697030001, 10.607447389000056],
              [116.68056237300003, 10.609040480000063],
              [116.68081590900013, 10.610011965000064],
              [116.68211801800014, 10.610659476000082],
              [116.68412116000005, 10.611137677000036],
              [116.68498134, 10.611382616000052],
              [116.68563494700007, 10.610092996000018],
              [116.68512190700008, 10.608010008000067],
              [116.68423802300003, 10.607204917000066],
            ],
          ],
          [
            [
              [116.712366252, 10.617541510000093],
              [116.71200088500005, 10.617276115000072],
              [116.71098205400004, 10.618300443000093],
              [116.71020501300006, 10.620016143000031],
              [116.71054722700003, 10.621404805000088],
              [116.71125419800001, 10.621375441000056],
              [116.71260275700003, 10.619776260000078],
              [116.71310887900007, 10.618352380000127],
              [116.712366252, 10.617541510000093],
            ],
          ],
          [
            [
              [116.73575878900007, 10.624705024000123],
              [116.7359640960001, 10.622872736000131],
              [116.73331333500006, 10.623824858000129],
              [116.72824093800004, 10.624456894000021],
              [116.72427588499997, 10.624481591000022],
              [116.72195545500009, 10.626542138000071],
              [116.72210280500012, 10.626676290000068],
              [116.72617508200008, 10.629172367000093],
              [116.72964015199999, 10.630711629000087],
              [116.73249796300001, 10.631294093000053],
              [116.73412325600012, 10.629542950000113],
              [116.73528261000003, 10.626829188000018],
              [116.73575878900007, 10.624705024000123],
            ],
          ],
          [
            [
              [116.74758101600001, 10.631226687000071],
              [116.74625469800006, 10.630019386000098],
              [116.74471719600002, 10.630504378000081],
              [116.74339865500008, 10.632803985000104],
              [116.74213796700005, 10.633137119000045],
              [116.74179042800007, 10.633291887000061],
              [116.74090709599999, 10.634170441000011],
              [116.74279902800004, 10.635354197000078],
              [116.74558532099999, 10.634256],
              [116.74748736299999, 10.632352902],
              [116.74758101600001, 10.631226687000071],
            ],
          ],
          [
            [
              [116.76387830700003, 10.641348684000105],
              [116.75968783200005, 10.639419371000079],
              [116.75635183699998, 10.637594604000087],
              [116.75319911500007, 10.636744177000066],
              [116.75124932200004, 10.637526994],
              [116.75061394400005, 10.639237004000089],
              [116.75391407499998, 10.64022161500011],
              [116.75716044900001, 10.639945801000026],
              [116.76119754500009, 10.641600985000109],
              [116.764103439, 10.6433033790001],
              [116.76597166200013, 10.643926758000076],
              [116.76836962000002, 10.643686228000069],
              [116.7691701980001, 10.642530415000094],
              [116.76730198800006, 10.641907066000032],
              [116.76387830700003, 10.641348684000105],
            ],
          ],
          [
            [
              [115.86295809400005, 10.647400633000091],
              [115.861789485, 10.646193956000081],
              [115.85942908400001, 10.646838181000094],
              [115.86024115400005, 10.649725538000075],
              [115.86271320900005, 10.651994914000083],
              [115.86724429800005, 10.653075509000052],
              [115.87083729300006, 10.655302763000039],
              [115.87363764800004, 10.658810221000079],
              [115.87457941500007, 10.661414828000114],
              [115.87626070500009, 10.661351709000131],
              [115.87759787200005, 10.65963415800004],
              [115.87620913, 10.656351627000047],
              [115.87337683100013, 10.652011760000056],
              [115.87032298200005, 10.649208558000021],
              [115.86776396500015, 10.648331964000056],
              [115.86550644500005, 10.647999791000105],
              [115.86295809400005, 10.647400633000091],
            ],
          ],
          [
            [
              [114.42544004000004, 10.663317798],
              [114.42284238600006, 10.662864968000106],
              [114.41973031000013, 10.663173164000026],
              [114.41741426500008, 10.664841146000102],
              [114.41654281900001, 10.667955584000042],
              [114.41703618200006, 10.669963532000091],
              [114.41907846000002, 10.673308301000082],
              [114.42095003100007, 10.674635226000094],
              [114.42191130200003, 10.674392852000024],
              [114.42233483900007, 10.674166934000072],
              [114.42423407700001, 10.672937546000048],
              [114.42651019300004, 10.66999297400004],
              [114.42600007800002, 10.66745317800004],
              [114.42655875800006, 10.664667715000052],
              [114.42544004000004, 10.663317798],
            ],
          ],
          [
            [
              [114.38829206000004, 10.669488177000106],
              [114.38581518300009, 10.669457101000111],
              [114.384870436, 10.670231182000032],
              [114.38340779400004, 10.671659873000026],
              [114.38316455000007, 10.674222693000067],
              [114.38398025199997, 10.676220921000022],
              [114.38532048700002, 10.677777284000099],
              [114.38706595000011, 10.677926725000118],
              [114.39000632800004, 10.676143954000048],
              [114.39082367099999, 10.674734872000078],
              [114.39077399200005, 10.673139238000127],
              [114.38984074700011, 10.670825195000065],
              [114.38829206000004, 10.669488177000106],
            ],
          ],
          [
            [
              [114.46295285800014, 10.673757834000062],
              [114.46068431500011, 10.673507896000077],
              [114.459192067, 10.673980247000079],
              [114.45783081300009, 10.675194759000115],
              [114.45636533399997, 10.676519075000089],
              [114.45567949000008, 10.678672209000071],
              [114.45631366400005, 10.680010218000023],
              [114.45696900700001, 10.680309479000092],
              [114.45856888299996, 10.679834333000125],
              [114.45982600300015, 10.678730840000037],
              [114.46148316200004, 10.67665673700011],
              [114.46296205200009, 10.675759365000113],
              [114.46433338100005, 10.674865297000034],
              [114.46425347900001, 10.674037571000026],
              [114.46295285800014, 10.673757834000062],
            ],
          ],
          [
            [
              [116.81628865600013, 10.658313119000072],
              [116.81573036800003, 10.651897804000042],
              [116.81617769800009, 10.645860176000086],
              [116.81495762500003, 10.63555363700006],
              [116.81443544300009, 10.629976822000041],
              [116.81484822900005, 10.624780675000077],
              [116.81536153400006, 10.620280166000027],
              [116.81873209100006, 10.616499341000106],
              [116.82293087500001, 10.612263738000125],
              [116.82638370100005, 10.608759308000069],
              [116.8281393310001, 10.60686587200008],
              [116.82805520400007, 10.604909927000071],
              [116.82596628400007, 10.602338558000033],
              [116.82060625300005, 10.602564085000042],
              [116.81544423900007, 10.607399924000037],
              [116.81256893600002, 10.612839408000061],
              [116.81116100900014, 10.622836119000032],
              [116.81003508199997, 10.632821133000013],
              [116.80952305399998, 10.639001296000037],
              [116.80716351700005, 10.643299517000047],
              [116.80142057100015, 10.651099067000034],
              [116.79464032900003, 10.656142567000069],
              [116.78972608200003, 10.656908459000112],
              [116.78531035600007, 10.656113657000075],
              [116.78341039599999, 10.654653533000056],
              [116.78229719400008, 10.651760720000068],
              [116.78041521100015, 10.650719749000091],
              [116.77511362000007, 10.652341352000059],
              [116.77406257100006, 10.654204951000066],
              [116.77666312500003, 10.657175554],
              [116.77964635800004, 10.66082998700004],
              [116.78493511500002, 10.665507643000092],
              [116.78957592200004, 10.668252698000023],
              [116.79378755000008, 10.670875696000069],
              [116.79778764800002, 10.673507496000044],
              [116.8019362980001, 10.677952706000047],
              [116.80276269500008, 10.679013479000064],
              [116.80553528800007, 10.683876188000061],
              [116.80719318200011, 10.688812350000097],
              [116.80892433000008, 10.691242553000089],
              [116.812116852, 10.691181146000071],
              [116.81339611500007, 10.688051386000017],
              [116.81508987500006, 10.681408770000099],
              [116.81545722100007, 10.67681249400003],
              [116.81570789700004, 10.674568472000077],
              [116.81653168300006, 10.668904558000046],
              [116.8165620330001, 10.663031092000084],
              [116.81628865600013, 10.658313119000072],
            ],
          ],
          [
            [
              [114.49399174900005, 10.685861643000026],
              [114.49107688200002, 10.685631954000117],
              [114.48741566400012, 10.68563854100004],
              [114.4854069200001, 10.686820306000032],
              [114.48423866400007, 10.688189340000063],
              [114.48444548500004, 10.689622504000072],
              [114.48528772100009, 10.690716139000054],
              [114.48637031500004, 10.690895945000099],
              [114.48909168899998, 10.690963956000042],
              [114.49119635400004, 10.691111873000112],
              [114.4933043880001, 10.691366183000104],
              [114.49576718900005, 10.69262134800004],
              [114.49790219300003, 10.693726863000029],
              [114.49902220300007, 10.694236653000109],
              [114.50042421700007, 10.694299851],
              [114.50154545500003, 10.693998529000035],
              [114.502708673, 10.69332267200007],
              [114.50331031700004, 10.69191776200009],
              [114.50236882500003, 10.691093862000043],
              [114.50114994300009, 10.690011962000055],
              [114.49855926400005, 10.688119482000056],
              [114.49577370900013, 10.686872823000025],
              [114.49399174900005, 10.685861643000026],
            ],
          ],
          [
            [
              [114.360434553, 10.707196139000084],
              [114.36311632800003, 10.706901683000057],
              [114.36532663500013, 10.707022721000035],
              [114.36670809200004, 10.706448558000135],
              [114.36805654400005, 10.70481088300008],
              [114.36847837100005, 10.702795790000062],
              [114.36641212700006, 10.702112473000101],
              [114.36297108000009, 10.702216853000033],
              [114.36062516500006, 10.702927420000028],
              [114.35818159100003, 10.703960643000075],
              [114.35758256600002, 10.705470819000061],
              [114.35826735900002, 10.706728948000064],
              [114.360434553, 10.707196139000084],
            ],
          ],
          [
            [
              [114.35161874000005, 10.704052824000064],
              [114.34965019500007, 10.703046671000036],
              [114.34675666900002, 10.703453939000072],
              [114.3452237680001, 10.704352884000022],
              [114.34516889700001, 10.706059719000026],
              [114.34663600500006, 10.708253401000077],
              [114.34870553800006, 10.70904338300007],
              [114.34982808100004, 10.708796265000052],
              [114.35174725500011, 10.708205314000095],
              [114.3528774160001, 10.706465909000061],
              [114.35294539800013, 10.705184975000035],
              [114.35161874000005, 10.704052824000064],
            ],
          ],
          [
            [
              [114.33412622099999, 10.697014957000038],
              [114.33092245000003, 10.694340780000021],
              [114.32890033800007, 10.693336079000025],
              [114.3281624220001, 10.693944700000111],
              [114.32513103400002, 10.694562173000042],
              [114.32408451400012, 10.694227278000044],
              [114.32266695200008, 10.693630573000055],
              [114.32195618700007, 10.691520575000089],
              [114.32128151300002, 10.690581766000038],
              [114.31941748100006, 10.68946563300006],
              [114.3178045870001, 10.689514202000082],
              [114.31631887200001, 10.690198367000024],
              [114.31541337300006, 10.692250460000084],
              [114.31602637900005, 10.694683139000103],
              [114.31850019000001, 10.698125517000088],
              [114.32049053400011, 10.700475947000053],
              [114.32153277400002, 10.701714281000106],
              [114.324761447, 10.705716672000063],
              [114.32745718400011, 10.709365474000075],
              [114.3285444520001, 10.711464169000083],
              [114.33019693200005, 10.712693210000078],
              [114.33121854500011, 10.712662390000014],
              [114.33257384400008, 10.711236030000036],
              [114.33251470100006, 10.709319476000088],
              [114.33073171300006, 10.707348378000068],
              [114.33050471800003, 10.705223765000047],
              [114.33161862000009, 10.702952125000118],
              [114.332846604, 10.700890183000043],
              [114.33464507300003, 10.699876747000035],
              [114.33515315600006, 10.698902250000051],
              [114.33412622099999, 10.697014957000038],
            ],
          ],
          [
            [
              [114.51429847100006, 10.695817060000044],
              [114.5122132700001, 10.695455125000118],
              [114.51304277000008, 10.69827761000006],
              [114.51623305599999, 10.700362704],
              [114.51909038800007, 10.702138471000032],
              [114.52223739900003, 10.703705336],
              [114.52374282800008, 10.705311890000056],
              [114.5239063360001, 10.707066837000044],
              [114.52386138100012, 10.709041610000012],
              [114.52309840800004, 10.71130539100009],
              [114.52317292100008, 10.71364980200009],
              [114.52380253500004, 10.716510304000069],
              [114.52448238700006, 10.718409238000069],
              [114.5263612590001, 10.719897526000109],
              [114.52922876900004, 10.720288346000116],
              [114.53370738099996, 10.72052229400011],
              [114.53571385200007, 10.720939824000107],
              [114.53755233500013, 10.722002609000112],
              [114.53927497900011, 10.723655663000018],
              [114.54074894100003, 10.725956513000055],
              [114.54175162800003, 10.726991977000109],
              [114.54291210800004, 10.72706246700008],
              [114.54352563900008, 10.726029951],
              [114.54265891800006, 10.724190221000105],
              [114.5409849700001, 10.72237565500007],
              [114.53966738500006, 10.719909955000071],
              [114.53844243200005, 10.717814723000084],
              [114.53554710000003, 10.716668332],
              [114.53345844300006, 10.713153461000056],
              [114.53254917800005, 10.710769828000098],
              [114.53159319300009, 10.707812849000041],
              [114.52968196800005, 10.704458949000093],
              [114.52758627500012, 10.702924132000112],
              [114.52620201199997, 10.701740507000055],
              [114.52499764000007, 10.700284622000121],
              [114.52303334699999, 10.699492367000028],
              [114.5209597700001, 10.698650142000064],
              [114.51785195300009, 10.697466705000112],
              [114.51429847100006, 10.695817060000044],
            ],
          ],
          [
            [
              [114.34377964000008, 10.718570948000126],
              [114.34150152900006, 10.718000354000077],
              [114.340442561, 10.718565228],
              [114.33996076800011, 10.720391570000041],
              [114.34241225700009, 10.723088468000073],
              [114.34519303300013, 10.725988544000041],
              [114.34707070800006, 10.727530403000072],
              [114.349987626, 10.727868470000066],
              [114.35331496800006, 10.727554604000121],
              [114.35546258700003, 10.727383000000133],
              [114.35704279700005, 10.726269352000093],
              [114.35730605700006, 10.724342995000045],
              [114.35596276400011, 10.722678467000078],
              [114.35140638599998, 10.721537511000088],
              [114.347925495, 10.720363930000063],
              [114.34618835600007, 10.719883593000111],
              [114.34569453599997, 10.719578796000027],
              [114.34377964000008, 10.718570948000126],
            ],
          ],
          [
            [
              [116.17342386100003, 10.604425379000036],
              [116.16596353800006, 10.604159106000031],
              [116.16203944900009, 10.604451086000031],
              [116.15927125900011, 10.60553335600005],
              [116.15875573100008, 10.608477067000075],
              [116.15906737500002, 10.6127808370001],
              [116.16027639299996, 10.618441951000078],
              [116.16115503500009, 10.622862950000032],
              [116.16035659700003, 10.62929839200004],
              [116.15992892699998, 10.639757100000045],
              [116.15961229500007, 10.64770548900009],
              [116.15993415500006, 10.652333957000069],
              [116.16001096900003, 10.654196550000082],
              [116.16008983000006, 10.656180015000059],
              [116.16015230000008, 10.659519166000029],
              [116.1622779810001, 10.663474226000067],
              [116.16361672100003, 10.668852265000071],
              [116.165084866, 10.673946836000068],
              [116.16521933000003, 10.6790933540001],
              [116.16631065, 10.685316584000047],
              [116.16570059900005, 10.691188375000047],
              [116.16569812500003, 10.699960641000049],
              [116.16464464700007, 10.705292841000078],
              [116.16281160100003, 10.708706013000045],
              [116.16136825900006, 10.71307872800009],
              [116.16147332299997, 10.715720277000072],
              [116.16257793200006, 10.71874070400005],
              [116.16534, 10.721000407000057],
              [116.1685898220001, 10.724912044000055],
              [116.17015839800004, 10.727218164000083],
              [116.17293174000008, 10.729755859000088],
              [116.17516378100002, 10.729110078000035],
              [116.17524521600001, 10.726045296000029],
              [116.17428276000014, 10.719538204000044],
              [116.17454534700002, 10.712008725000109],
              [116.17521433000003, 10.70585593300004],
              [116.17495953500008, 10.699460703000073],
              [116.17383823600004, 10.696023367000061],
              [116.17635093100002, 10.686735596000039],
              [116.17919591300004, 10.680498193000075],
              [116.18530283900004, 10.669677992000091],
              [116.19120797900005, 10.662624962000054],
              [116.19398590300004, 10.660010247000072],
              [116.19650878100006, 10.656291611000055],
              [116.19891895200008, 10.653273526000094],
              [116.20009901700011, 10.651138910000045],
              [116.20286761600003, 10.650056025000064],
              [116.20567094799999, 10.646326357000111],
              [116.20605107200001, 10.643526862000028],
              [116.205743963, 10.639361977000037],
              [116.20221962000006, 10.635601408000104],
              [116.19863432700006, 10.630311687000036],
              [116.19281264500005, 10.62357784900003],
              [116.19051870400004, 10.618933777000093],
              [116.18784818399998, 10.615418212000092],
              [116.18481025000006, 10.611499308000115],
              [116.18175213100007, 10.608834185000136],
              [116.17728510800012, 10.606084740000108],
              [116.17342386100003, 10.604425379000036],
            ],
          ],
          [
            [
              [114.39655850800003, 10.733691502000029],
              [114.394609926, 10.733324697000056],
              [114.39262748300011, 10.733598202000037],
              [114.39128279800005, 10.735343926000061],
              [114.39112743500007, 10.737266538000066],
              [114.39172140300002, 10.739059775000024],
              [114.39273550399999, 10.740520567000098],
              [114.39501004500006, 10.740984005000074],
              [114.39782550500004, 10.74153748900004],
              [114.39966666000007, 10.741907557000017],
              [114.40117860100011, 10.742074530000066],
              [114.40246222000009, 10.741822281000067],
              [114.40304363000006, 10.74148489000004],
              [114.40442811900003, 10.741016450000094],
              [114.40449909600014, 10.739842234000017],
              [114.40414332100009, 10.738787594000064],
              [114.40272230600011, 10.738085126000028],
              [114.40126809700006, 10.736318161000023],
              [114.39875201700015, 10.735009761000034],
              [114.39655850800003, 10.733691502000029],
            ],
          ],
          [
            [
              [114.41757241700012, 10.746632278000055],
              [114.41592963600007, 10.745724660000098],
              [114.41394779900006, 10.7460209540001],
              [114.41258325600005, 10.74712815300005],
              [114.41275399000006, 10.749147401000057],
              [114.41258883700006, 10.750750716000111],
              [114.41382819500002, 10.752524214000035],
              [114.41570274800004, 10.753958643000059],
              [114.41745981200003, 10.755076982000043],
              [114.41907607100009, 10.755134099000061],
              [114.42065568500004, 10.754020265000095],
              [114.4210277020001, 10.753870098000016],
              [114.42185131800012, 10.752674178000097],
              [114.42124713200005, 10.750564112000037],
              [114.41955109500005, 10.7479553140001],
              [114.41757241700012, 10.746632278000055],
            ],
          ],
          [
            [
              [116.68863839200006, 10.793322811000026],
              [116.6922574260001, 10.792196882000036],
              [116.69521974400007, 10.792213584000086],
              [116.69756580400011, 10.791000870000021],
              [116.69850142599999, 10.790244514000056],
              [116.69602228100004, 10.787857857000025],
              [116.69308283500001, 10.785051547000089],
              [116.69143993, 10.7828886700001],
              [116.68912858100012, 10.781590069000034],
              [116.68457076100003, 10.780523858000011],
              [116.68181947700012, 10.778825098000054],
              [116.68001299900004, 10.779457569000071],
              [116.68023621900014, 10.78140038100006],
              [116.68204333700008, 10.784114273000077],
              [116.68192674300012, 10.788023231000031],
              [116.68319437700002, 10.791317201000082],
              [116.68565249400001, 10.792749252000087],
              [116.68863839200006, 10.793322811000026],
            ],
          ],
          [
            [
              [114.57679794300006, 10.738916419000065],
              [114.57286028300004, 10.737119088000034],
              [114.5694420990001, 10.738079773000029],
              [114.56524630400013, 10.738317782000093],
              [114.56334229200006, 10.739444398000051],
              [114.56273717100008, 10.740743788000094],
              [114.56407017100007, 10.741982558000094],
              [114.56798737699999, 10.743140461000065],
              [114.56943150200001, 10.744482433000078],
              [114.56955261200009, 10.744898765000064],
              [114.5714152300001, 10.749210352000114],
              [114.5729685230001, 10.753957964000092],
              [114.57408433, 10.758506147000089],
              [114.57483101900002, 10.763307281000055],
              [114.57586331700008, 10.768610427000079],
              [114.57602389400004, 10.773620789000036],
              [114.57476819400006, 10.779849397000072],
              [114.57401253400008, 10.783181142000052],
              [114.57214572300006, 10.785480568000059],
              [114.57010708600005, 10.787465236000047],
              [114.56809494800009, 10.788595349000028],
              [114.56803502600012, 10.790091208000073],
              [114.56998044400005, 10.790243744000081],
              [114.57194635200008, 10.791035904000077],
              [114.57396008600008, 10.793320541000105],
              [114.57474473700007, 10.794256389000088],
              [114.57572430600013, 10.794545851000057],
              [114.57762191600001, 10.793205822000028],
              [114.57986482000005, 10.789187114000052],
              [114.58168028400007, 10.785288512000067],
              [114.58285635900003, 10.781623398000038],
              [114.58442386600007, 10.780080253000078],
              [114.58585972400012, 10.777794254000115],
              [114.58717759400008, 10.775191816000083],
              [114.58828689000003, 10.772809351000133],
              [114.58867564500005, 10.76980925300006],
              [114.58888916900004, 10.766387822000032],
              [114.58784126800002, 10.763966399000056],
              [114.5860601310001, 10.762208238000069],
              [114.58350814900005, 10.759940714000036],
              [114.58070580100001, 10.754906608000068],
              [114.57855734300006, 10.751772736000119],
              [114.57930598800009, 10.74822793],
              [114.57912910700011, 10.74439204100006],
              [114.57859587500005, 10.74120758000001],
              [114.57679794300006, 10.738916419000065],
            ],
          ],
          [
            [
              [114.46076104900006, 10.79203044000012],
              [114.4579040350001, 10.782638840000034],
              [114.45608920000004, 10.78312041600009],
              [114.456690995, 10.79268786300007],
              [114.45719814300006, 10.795120263000067],
              [114.45864983700008, 10.796778571000052],
              [114.4600778250001, 10.797035448000079],
              [114.46131178400006, 10.795845165000094],
              [114.4619101940001, 10.794336633000066],
              [114.46076104900006, 10.79203044000012],
            ],
          ],
          [
            [
              [116.273982391, 10.7947750110001],
              [116.2714856350001, 10.793898872000083],
              [116.26677823799999, 10.795755067000099],
              [116.25999226900002, 10.798389099000081],
              [116.25735201600006, 10.799189121000017],
              [116.25436314900003, 10.801812759000116],
              [116.25264374700005, 10.804525667000052],
              [116.25431721400005, 10.807662060000032],
              [116.25814212900006, 10.811827626000079],
              [116.26205467700005, 10.812926676000066],
              [116.26542531300005, 10.812793957000098],
              [116.26828376900004, 10.812110048000088],
              [116.27107694100005, 10.809925955000031],
              [116.27316700800004, 10.805945206000088],
              [116.27395383800004, 10.801041277000055],
              [116.274369783, 10.797405016000104],
              [116.273982391, 10.7947750110001],
            ],
          ],
          [
            [
              [116.09439835400012, 10.799219800000069],
              [116.09336134900002, 10.798136375000043],
              [116.0916705850001, 10.798482424000076],
              [116.08929282800001, 10.799416663000015],
              [116.08865559600005, 10.801266787000062],
              [116.08752292900012, 10.804961578000059],
              [116.08736643400005, 10.808197474000012],
              [116.08790801400001, 10.811125637000083],
              [116.08884192100001, 10.813196097000064],
              [116.09052880100005, 10.814150125000042],
              [116.09211507500002, 10.813350771000072],
              [116.09423496400009, 10.809477407000132],
              [116.09519802700002, 10.805086945000129],
              [116.09576880900009, 10.80155421000004],
              [116.09439835400012, 10.799219800000069],
            ],
          ],
          [
            [
              [116.18509447300004, 10.731437288000032],
              [116.182966467, 10.730963464000085],
              [116.18047825500001, 10.732034954000101],
              [116.17768707500008, 10.732561385000048],
              [116.17669135500003, 10.735802003000037],
              [116.17917589700008, 10.741691197000057],
              [116.18114514600003, 10.745233907000083],
              [116.1822720670001, 10.748809478000075],
              [116.18408995299997, 10.752079684000114],
              [116.18503218500004, 10.754548778000038],
              [116.185333407, 10.758574237000039],
              [116.18491041800009, 10.762071106000091],
              [116.18409577600001, 10.768089166000026],
              [116.18590100700007, 10.774561949000104],
              [116.18751600800012, 10.779789243000069],
              [116.18919575300001, 10.784874830000122],
              [116.19192878600009, 10.789919323000062],
              [116.19342028400004, 10.795569192000086],
              [116.19535340300004, 10.799948836000082],
              [116.19878344499998, 10.80482693000002],
              [116.20149474900005, 10.809315404000051],
              [116.20258346600006, 10.81191815600002],
              [116.20570865900001, 10.814441291000064],
              [116.20785427899999, 10.815331962000039],
              [116.20939113900013, 10.81353476900002],
              [116.20858149600012, 10.808899079000065],
              [116.20840906, 10.804589844000121],
              [116.20787093800004, 10.801687172000117],
              [116.20508587900008, 10.798872391000101],
              [116.20102031500005, 10.795689955000041],
              [116.19671647300001, 10.791820671000083],
              [116.19586437000012, 10.788094970000104],
              [116.19725355000004, 10.784142537000017],
              [116.199367, 10.780718689000055],
              [116.19925589, 10.777938653000072],
              [116.19805275400009, 10.774226763000081],
              [116.19351658200003, 10.769809755000013],
              [116.18937964300004, 10.764820239000093],
              [116.18851669000004, 10.760816675000038],
              [116.18817848700004, 10.757627932000078],
              [116.19014586900002, 10.754070802000102],
              [116.190596497, 10.75126894200009],
              [116.18881179900004, 10.748832772000048],
              [116.18720084100006, 10.747225099000078],
              [116.18679801400003, 10.744178148000037],
              [116.18789709800002, 10.741768667000072],
              [116.18802257400002, 10.737865885000096],
              [116.18603108600009, 10.733767304000084],
              [116.18509447300004, 10.731437288000032],
            ],
          ],
          [
            [
              [116.70220893000007, 10.811771437000049],
              [116.70144036600007, 10.810268590000048],
              [116.69961110699997, 10.810344430000017],
              [116.69785271800005, 10.812091562000068],
              [116.69712064000005, 10.814772798000137],
              [116.69858686300005, 10.816107239000059],
              [116.69979206500003, 10.815602002000089],
              [116.70100158900009, 10.813216711000083],
              [116.70220893000007, 10.811771437000049],
            ],
          ],
          [
            [
              [114.57577203199999, 10.801066315000083],
              [114.57456985000009, 10.800570243000072],
              [114.57373871700005, 10.801556998000093],
              [114.5736822260001, 10.803159959000117],
              [114.57378132000012, 10.806252497000022],
              [114.57439861000003, 10.808688357000038],
              [114.57469953100006, 10.811347616000065],
              [114.57482598400006, 10.81529333300004],
              [114.57615308299998, 10.816319245000015],
              [114.57827818600005, 10.816538520000039],
              [114.57963994500001, 10.815747846000077],
              [114.58046431100001, 10.814549141000017],
              [114.57953405000003, 10.812445801000068],
              [114.57899719100013, 10.809157289000025],
              [114.57816103500012, 10.806624537000074],
              [114.57778660000005, 10.805036915000075],
              [114.57722387600003, 10.802622018000106],
              [114.57577203199999, 10.801066315000083],
            ],
          ],
          [
            [
              [114.49186524600005, 10.8124837550001],
              [114.49009432199998, 10.810940550000046],
              [114.48869649100006, 10.810983763000069],
              [114.48661985700009, 10.809982636000102],
              [114.48512123300009, 10.810242004000068],
              [114.48369323400006, 10.812736335000038],
              [114.48366643400007, 10.81529393600009],
              [114.48645221199999, 10.818297300000077],
              [114.48868358600005, 10.820785117000041],
              [114.49056549400007, 10.822431457000013],
              [114.49213143700004, 10.822596101000046],
              [114.49466165000005, 10.820919835000085],
              [114.49545787100011, 10.818871070000034],
              [114.49488301100007, 10.816012490000036],
              [114.49298092700008, 10.813727626000079],
              [114.49186524600005, 10.8124837550001],
            ],
          ],
          [
            [
              [116.66029329700011, 10.771651142000048],
              [116.65446888500003, 10.770635134000093],
              [116.64659763400006, 10.77123884200004],
              [116.64309603500006, 10.771801878000085],
              [116.63568809199997, 10.775038314000083],
              [116.63091352299998, 10.778864157000088],
              [116.62768147499999, 10.782486894000074],
              [116.62238448600012, 10.787311202000092],
              [116.61861829699998, 10.791653742000038],
              [116.61749746400007, 10.796864795000081],
              [116.61735611900009, 10.80357122700004],
              [116.61787776100009, 10.809273309000032],
              [116.61908011300001, 10.812713888000046],
              [116.62028240800009, 10.817829694000112],
              [116.62228857700009, 10.821935297000083],
              [116.62480530200004, 10.824763509000055],
              [116.62670031399999, 10.82454601800009],
              [116.62873616600004, 10.822647508000037],
              [116.62994485100003, 10.817851376000041],
              [116.63026763700009, 10.812114504000101],
              [116.63088952000012, 10.810134551000059],
              [116.63274334800003, 10.807266319000062],
              [116.63740123800005, 10.805678663000061],
              [116.64131405200006, 10.804819592000035],
              [116.64375434300008, 10.802485521000092],
              [116.64561966100005, 10.799895924000056],
              [116.64902771800004, 10.797103141],
              [116.65275251500012, 10.795134829000029],
              [116.65636585900005, 10.793869030000012],
              [116.659644652, 10.791360620000122],
              [116.66118126200004, 10.789342866000061],
              [116.66221923600003, 10.787206091000099],
              [116.66454772100005, 10.785574417000097],
              [116.66717539900003, 10.784349137000058],
              [116.670066913, 10.78269414100008],
              [116.67237172000002, 10.780504986000048],
              [116.67283310700009, 10.776408100000088],
              [116.6709569110001, 10.773723399000088],
              [116.66029329700011, 10.771651142000048],
            ],
          ],
          [
            [
              [116.71721463800006, 10.816589972000122],
              [116.71535574000008, 10.815969583000127],
              [116.71289977000001, 10.817885344],
              [116.71142286800001, 10.819620934000083],
              [116.71107760600005, 10.821449065000087],
              [116.71246241600014, 10.824182046000084],
              [116.71460873600009, 10.82493007100007],
              [116.71768686699995, 10.824383652000067],
              [116.71905559000005, 10.823057354000108],
              [116.71993109400002, 10.820802328000044],
              [116.71927348100004, 10.818597276000029],
              [116.71721463800006, 10.816589972000122],
            ],
          ],
          [
            [
              [116.78567215200007, 10.813735084000074],
              [116.78268736700011, 10.81316240100003],
              [116.7818144810001, 10.814175610000108],
              [116.78105965200008, 10.816300040000025],
              [116.78296646300012, 10.818034061000057],
              [116.78606468200005, 10.821252968000026],
              [116.78899838700013, 10.823920626000037],
              [116.7917556350001, 10.825758495000047],
              [116.79683351800004, 10.825824757000111],
              [116.80093233, 10.826071478000097],
              [116.80457910200001, 10.825639458000056],
              [116.80844272900003, 10.82483429800005],
              [116.81080977900004, 10.823005998000033],
              [116.81242658200006, 10.821263801000034],
              [116.81409576200005, 10.819100820000036],
              [116.813209581, 10.818161363000021],
              [116.80907502300012, 10.817079237000103],
              [116.80294659500011, 10.815522581],
              [116.79502484500006, 10.814878077000055],
              [116.78906118200008, 10.813872209000051],
              [116.78567215200007, 10.813735084000074],
            ],
          ],
          [
            [
              [116.76315316400007, 10.816211416000064],
              [116.76083034200008, 10.814634119000031],
              [116.75831534300008, 10.815157681000096],
              [116.75572501200008, 10.81721909900004],
              [116.754600264, 10.818940305000078],
              [116.75538708600001, 10.820860820000087],
              [116.757593004, 10.823001170000078],
              [116.75938870600008, 10.825437673000058],
              [116.76174139700005, 10.82771139000007],
              [116.76443893500004, 10.828156857000069],
              [116.76790341099999, 10.826756403000013],
              [116.769778757, 10.823850818000034],
              [116.76884348300007, 10.820717530000044],
              [116.76603889, 10.817765180000015],
              [116.76315316400007, 10.816211416000064],
            ],
          ],
          [
            [
              [115.8699391780001, 10.788866913000026],
              [115.87105215599999, 10.784932839000037],
              [115.87060659200007, 10.780640212000046],
              [115.87187040200001, 10.776978542000036],
              [115.8740731060001, 10.772169577000044],
              [115.87434544300007, 10.766460015000055],
              [115.87496832400009, 10.760737356000048],
              [115.87727901200006, 10.755090382000086],
              [115.88178036200009, 10.746164055000039],
              [115.88329370699998, 10.738045041000086],
              [115.88521609100005, 10.731439737000057],
              [115.88541263900007, 10.72559435300011],
              [115.88692954600008, 10.72122837600009],
              [115.88826811500007, 10.717703095000084],
              [115.89115470200008, 10.712451647000075],
              [115.89254189300001, 10.708368570000102],
              [115.89536282100009, 10.705065536000101],
              [115.89783322900004, 10.701775663000122],
              [115.89855139600004, 10.69855176],
              [115.89744733200008, 10.695396471000061],
              [115.89462925600006, 10.691471745000046],
              [115.89191943100002, 10.686708991000058],
              [115.88885398300013, 10.681820619000069],
              [115.8872283390001, 10.676044186000054],
              [115.88421557100006, 10.671195976000101],
              [115.88159306900005, 10.666848212000078],
              [115.88066716199999, 10.664659859000023],
              [115.87922345600009, 10.663602519000024],
              [115.87716442300008, 10.664791425000073],
              [115.87743767200004, 10.66825482400011],
              [115.87913337800001, 10.672220616000059],
              [115.88276415000004, 10.675418972000015],
              [115.88382491399997, 10.677463339000058],
              [115.88319893300005, 10.679432157000067],
              [115.88188301700005, 10.681704790000078],
              [115.87957553400008, 10.683736777000073],
              [115.87584016200002, 10.685127604000042],
              [115.87434139700009, 10.686295471000038],
              [115.87333751499999, 10.689390077000025],
              [115.8736222380001, 10.691324719000049],
              [115.87636986900006, 10.693444810000051],
              [115.87871314599997, 10.695996915000093],
              [115.88026364700009, 10.699829376000112],
              [115.88040218100001, 10.703436974000089],
              [115.88026045800009, 10.707055128000052],
              [115.88072270700006, 10.709955841000072],
              [115.88306089600007, 10.712369206000092],
              [115.88338915900006, 10.713607483000052],
              [115.88233198, 10.715314692000087],
              [115.88027245600003, 10.716503714000057],
              [115.87809939000007, 10.718391763000044],
              [115.87568944100008, 10.719593918000035],
              [115.87219642700008, 10.720002917000036],
              [115.86823839700006, 10.72304215400006],
              [115.86476902900006, 10.727759193000084],
              [115.86166497799999, 10.734686527000024],
              [115.85772482800004, 10.741784199000024],
              [115.85772614900006, 10.749151292000112],
              [115.85763278300007, 10.752212878000121],
              [115.85899569300003, 10.754802925000105],
              [115.86210694500006, 10.755381398000095],
              [115.86470711900009, 10.753615897000122],
              [115.86620686500009, 10.752447637],
              [115.86919918300006, 10.753586488000087],
              [115.87173187800009, 10.757383636000027],
              [115.87076459700009, 10.761451094000039],
              [115.87115686200003, 10.764355533000101],
              [115.87034130400005, 10.76688826000005],
              [115.8698383330001, 10.767082021000094],
              [115.8654532380001, 10.769026171000105],
              [115.862081469, 10.769053203000032],
              [115.86067547900011, 10.76903123500008],
              [115.85862837000002, 10.7669275060001],
              [115.85507275400008, 10.764135631000025],
              [115.85073373899998, 10.765603231000041],
              [115.84881105000014, 10.767383045000132],
              [115.84576448000004, 10.771398455000012],
              [115.84552053200002, 10.774590874000099],
              [115.84825795300003, 10.774977979000065],
              [115.85067340900005, 10.77341926300006],
              [115.85314496200009, 10.773326788000047],
              [115.85632584900011, 10.772926131000114],
              [115.85653192400002, 10.772966059000042],
              [115.85718319400007, 10.775304836000108],
              [115.85525055700013, 10.778287393000062],
              [115.85264399300003, 10.78145215600008],
              [115.84973527200006, 10.784341190000111],
              [115.84505230800001, 10.786740480000033],
              [115.83932393400006, 10.78751059900007],
              [115.83508508200008, 10.786834830000112],
              [115.83295506600005, 10.786219293000075],
              [115.82989641700011, 10.787028488000059],
              [115.82756539500006, 10.788505560000059],
              [115.82557032700001, 10.787745898000088],
              [115.82347339, 10.786155954000074],
              [115.82126933700013, 10.78359691300008],
              [115.81914997700001, 10.783258863000087],
              [115.81624212100012, 10.784340257000034],
              [115.81532139800005, 10.782289409000077],
              [115.81684550300012, 10.778062371000095],
              [115.81931408300009, 10.772827091000091],
              [115.82251541200003, 10.768398562000046],
              [115.82518619800013, 10.766630902000072],
              [115.82731322000006, 10.763493436000092],
              [115.82793487700008, 10.76138515300009],
              [115.82427864300007, 10.761243508000094],
              [115.82303786400009, 10.7618457880001],
              [115.82126000600007, 10.759409971000087],
              [115.82082082899997, 10.755256205000039],
              [115.82006157500004, 10.753755467000035],
              [115.81755632300005, 10.750651740000093],
              [115.81594935800004, 10.743483494000115],
              [115.81524579100001, 10.739756666000043],
              [115.81834351100008, 10.73630525800011],
              [115.81851813000004, 10.733518755000125],
              [115.81689798800002, 10.729687147000087],
              [115.81336138400006, 10.728984892000069],
              [115.81245680000009, 10.727350601000028],
              [115.81623947099999, 10.723456775000082],
              [115.81775512800007, 10.72270532800008],
              [115.82424623799997, 10.723575340000123],
              [115.82703417500008, 10.723054354000093],
              [115.82960246500012, 10.720456579000091],
              [115.83069466700007, 10.715967934000046],
              [115.83080430900009, 10.713322915000044],
              [115.83087809499997, 10.707899369000094],
              [115.83225585000002, 10.703539129000104],
              [115.83259405500011, 10.697688810000015],
              [115.83375086300009, 10.693058891000094],
              [115.83586637500007, 10.689644105000083],
              [115.83521562900005, 10.687305576000126],
              [115.83460437700001, 10.68232478500004],
              [115.83513283800004, 10.675353385000095],
              [115.836609508, 10.672007178000074],
              [115.83872946100007, 10.668732203000085],
              [115.83960425400008, 10.665920589000065],
              [115.83905672500006, 10.662606371000026],
              [115.83890718300003, 10.660527796000084],
              [115.83724943600009, 10.657532972000093],
              [115.8371646610001, 10.655313051000091],
              [115.83715112399996, 10.651284209000076],
              [115.83728298100003, 10.647388909000098],
              [115.83888351300004, 10.647051223000046],
              [115.84128109299999, 10.647378443000031],
              [115.84260785000008, 10.645383659000094],
              [115.84325756300009, 10.640357502000073],
              [115.84479550099999, 10.63654861700005],
              [115.84725349300005, 10.634789399000065],
              [115.84819481400005, 10.6373940190001],
              [115.84958829500006, 10.640815380000033],
              [115.85266295900009, 10.644173813000089],
              [115.85484404600007, 10.646176267000039],
              [115.85664938800005, 10.645691826000101],
              [115.85696875300002, 10.643039988000067],
              [115.85416890600007, 10.639532393000104],
              [115.85097011900008, 10.636595468000019],
              [115.84976448200007, 10.634417560000118],
              [115.8479975, 10.629481839000031],
              [115.84607937000007, 10.625177072000012],
              [115.845004991, 10.620007155000087],
              [115.84493076, 10.61806483700005],
              [115.84200463600008, 10.614909293000084],
              [115.83768755700011, 10.609304913000093],
              [115.836479583, 10.60705765300005],
              [115.831856225, 10.600770016000103],
              [115.83016078200005, 10.598610430000031],
              [115.82749084900003, 10.595723100000052],
              [115.82452837200005, 10.595277937000079],
              [115.82332530500007, 10.597754087000054],
              [115.82318264099999, 10.601371611000053],
              [115.82114242200011, 10.606727117000057],
              [115.81838587200002, 10.611692531000115],
              [115.81783874000006, 10.615742012000061],
              [115.81857478000003, 10.618493273000061],
              [115.82040901800009, 10.622454004000035],
              [115.82184951500008, 10.627124126000085],
              [115.82173323800006, 10.63143551300003],
              [115.82040503400003, 10.635236365000099],
              [115.81923935800003, 10.637780728000033],
              [115.81982350200005, 10.642066062000042],
              [115.82043937900002, 10.647183899000099],
              [115.82195010899997, 10.651851588000037],
              [115.82179679899998, 10.655191926000017],
              [115.8209641120001, 10.659113382000028],
              [115.81998070500001, 10.662762588000072],
              [115.81944799100006, 10.669034973000089],
              [115.81821071000002, 10.673388414000049],
              [115.81735673100005, 10.676754968000067],
              [115.81737631000007, 10.677268972000052],
              [115.81579345700003, 10.681775568000072],
              [115.81341848400007, 10.685755718000054],
              [115.81107511500008, 10.690568625000106],
              [115.80860445700002, 10.699417041000101],
              [115.80674855000002, 10.707825672000036],
              [115.80564240800007, 10.715650518000073],
              [115.80451359300002, 10.724727267000043],
              [115.80392583600006, 10.731421021000108],
              [115.8036515870001, 10.737130188000064],
              [115.80605322100004, 10.741210865000104],
              [115.80829361300006, 10.744741528000111],
              [115.80845209100002, 10.748905689000042],
              [115.80868066900011, 10.753067268000025],
              [115.81035227600003, 10.76009426100004],
              [115.81055308100005, 10.765368988000111],
              [115.80833168200002, 10.771567947000092],
              [115.80601890300014, 10.777214293],
              [115.80548148099999, 10.781543556000093],
              [115.80554869100004, 10.785155306000059],
              [115.80681846600012, 10.789000365000055],
              [115.80805658799999, 10.792012542000061],
              [115.81017847400005, 10.796103944000011],
              [115.8123560920001, 10.797969067000055],
              [115.81360883500011, 10.80320493500002],
              [115.81501637800004, 10.810659314000047],
              [115.81754876600004, 10.814457442000068],
              [115.820691246, 10.817676765000071],
              [115.82425328700002, 10.822687664000073],
              [115.827373545, 10.827158928000101],
              [115.83115284100006, 10.830493444000043],
              [115.83361807000003, 10.830679492000071],
              [115.83526411500006, 10.829644901000089],
              [115.837246068, 10.826373458000125],
              [115.83880187800003, 10.822978902000012],
              [115.84238717500003, 10.817562274000101],
              [115.84634525700005, 10.810880536000054],
              [115.84770103900004, 10.807771491000027],
              [115.84981751900003, 10.804355971000044],
              [115.85239716600003, 10.802035229000049],
              [115.85631868200005, 10.801749452000118],
              [115.85950173700007, 10.800518151000025],
              [115.86158410400006, 10.799884093000054],
              [115.864531066, 10.796159316000063],
              [115.86789330800002, 10.792279937000069],
              [115.8699391780001, 10.788866913000026],
            ],
          ],
          [
            [
              [116.31091363900012, 10.831464391000088],
              [116.32010773900002, 10.830960879000083],
              [116.3303890630001, 10.831249213000136],
              [116.33336543200006, 10.831743444000084],
              [116.33733885500011, 10.828783023000097],
              [116.33533485600003, 10.82300584100002],
              [116.33559533, 10.820805254000019],
              [116.33052673300003, 10.817320525000083],
              [116.3261673210001, 10.817354294000035],
              [116.31961154400008, 10.818728142000021],
              [116.312583625, 10.818867392000085],
              [116.30496283900005, 10.816523739000059],
              [116.29889545000003, 10.812586882000106],
              [116.29355858799998, 10.809317141000106],
              [116.28949707200002, 10.80627537700005],
              [116.28639620800004, 10.806119393000111],
              [116.28319410400005, 10.810422621],
              [116.28134291800009, 10.815090225000022],
              [116.28049995500007, 10.822084963000032],
              [116.28353909100008, 10.827673494000043],
              [116.28731159700011, 10.832258458000064],
              [116.29277816600006, 10.835244892000071],
              [116.29587946600007, 10.835400798000093],
              [116.29907061800006, 10.83429999700005],
              [116.30422248, 10.832982353000096],
              [116.31091363900012, 10.831464391000088],
            ],
          ],
          [
            [
              [116.8314209160001, 10.8302322160001],
              [116.829519569, 10.828637889000079],
              [116.82772616200012, 10.829550506000095],
              [116.82585195700003, 10.831861775000016],
              [116.82601379, 10.835622254000031],
              [116.82781041500007, 10.838058227000056],
              [116.82831494800007, 10.838316060000073],
              [116.83091680900006, 10.836532231000094],
              [116.832621905, 10.835204711000033],
              [116.83267865900008, 10.833248919000125],
              [116.83258868900009, 10.831159768000107],
              [116.8314209160001, 10.8302322160001],
            ],
          ],
          [
            [
              [114.50909206100005, 10.833019076000115],
              [114.50698815899997, 10.831168894000063],
              [114.50490542200012, 10.831682171000077],
              [114.50346740200007, 10.833857436000114],
              [114.50388448500003, 10.836827503000011],
              [114.50504087800005, 10.839348529000057],
              [114.50686069100007, 10.840940212000037],
              [114.50851806400007, 10.838676716000071],
              [114.50974780800004, 10.836723156000081],
              [114.51001307300014, 10.834905928000092],
              [114.50909206100005, 10.833019076000115],
            ],
          ],
          [
            [
              [114.52190981000003, 10.836664897000057],
              [114.52058555300005, 10.835641909000058],
              [114.51432196900005, 10.836303357000075],
              [114.51085417100001, 10.838964846000108],
              [114.51383309000002, 10.839795752000024],
              [114.514839767, 10.841140944000037],
              [114.5169770700001, 10.840648947000096],
              [114.51985025700006, 10.83960201900007],
              [114.52164464800002, 10.838482145000048],
              [114.52190981000003, 10.836664897000057],
            ],
          ],
          [
            [
              [116.22333896200008, 10.817371070000091],
              [116.22190663600006, 10.816731051000071],
              [116.21966505500004, 10.816958093000027],
              [116.21719826700004, 10.818586198000089],
              [116.21585503600011, 10.820170288000062],
              [116.21542130500009, 10.823389524000058],
              [116.21685242700002, 10.827510374000047],
              [116.21831145600004, 10.832326300000082],
              [116.22114730600003, 10.836392182000052],
              [116.22423631700008, 10.839751959000042],
              [116.22654504900007, 10.841193037000084],
              [116.22778690700014, 10.840587435000069],
              [116.22838883500003, 10.838057648000097],
              [116.22862616600004, 10.833453701000016],
              [116.22793049600003, 10.830139427000061],
              [116.22631965299998, 10.825051061000044],
              [116.22514695300002, 10.82036324000004],
              [116.22333896200008, 10.817371070000091],
            ],
          ],
          [
            [
              [116.87009798000007, 10.850926044000085],
              [116.86983074400007, 10.848007135000069],
              [116.86874924300002, 10.845820314000052],
              [116.86670673000005, 10.844232234000104],
              [116.86417959800012, 10.844478508000053],
              [116.86144320100001, 10.846407993000094],
              [116.85913498300008, 10.848458899000056],
              [116.85867431599998, 10.850850408000044],
              [116.85959093900006, 10.852486119000025],
              [116.86157930600008, 10.852820776],
              [116.8632079600001, 10.85135666400001],
              [116.86500742100004, 10.850582987000033],
              [116.86614534600005, 10.85081398300005],
              [116.86693333399997, 10.852734171000032],
              [116.86759263300007, 10.854938864000106],
              [116.86873661800004, 10.8553091290001],
              [116.86957785400001, 10.853569567000052],
              [116.87009798000007, 10.850926044000085],
            ],
          ],
          [
            [
              [116.72764655700007, 10.848150307000102],
              [116.7260742890001, 10.847657403000087],
              [116.72442643799999, 10.848702897000083],
              [116.72408707200007, 10.850670969000118],
              [116.72517923100011, 10.853137833000035],
              [116.72693975500012, 10.85473948100004],
              [116.72992584800009, 10.855313155000077],
              [116.73227687200001, 10.855876636000071],
              [116.73394256800009, 10.855249695000067],
              [116.73444642900003, 10.853834630000046],
              [116.73242807300009, 10.852803357000072],
              [116.7306913020001, 10.851760346000015],
              [116.72892493300006, 10.850021510000117],
              [116.72764655700007, 10.848150307000102],
            ],
          ],
          [
            [
              [116.85071144000007, 10.84602365100009],
              [116.8491393390001, 10.845531823000075],
              [116.84647744400004, 10.84592309900003],
              [116.84462131100011, 10.848652446000083],
              [116.8423849620001, 10.852374533000029],
              [116.84138947799997, 10.853811808000023],
              [116.84119807500012, 10.855912873000049],
              [116.84128814300001, 10.858002088000072],
              [116.84259685200004, 10.858923695000032],
              [116.84496542400008, 10.858265756000039],
              [116.84725587700005, 10.855797136000065],
              [116.84958057500002, 10.852489848000067],
              [116.85100845600005, 10.849638975000085],
              [116.85121178400007, 10.847816473000103],
              [116.85071144000007, 10.84602365100009],
            ],
          ],
          [
            [
              [114.52690492000013, 10.844917125000018],
              [114.52586960600001, 10.842819181000072],
              [114.52388404500003, 10.842987408000047],
              [114.52102860000002, 10.844567215000021],
              [114.51753480100005, 10.846379817000072],
              [114.51488415200004, 10.847633678000077],
              [114.512441745, 10.848668025000039],
              [114.51187772500006, 10.851241691],
              [114.51550684400004, 10.853685274000034],
              [114.51924018100011, 10.856019062000131],
              [114.5220664630001, 10.858593983000018],
              [114.52424392400006, 10.859378394000084],
              [114.52582285700014, 10.858264238000091],
              [114.52731078100005, 10.855981295000026],
              [114.52713174900008, 10.85204606100003],
              [114.52690492000013, 10.844917125000018],
            ],
          ],
          [
            [
              [116.24918764499999, 10.848123516000017],
              [116.248202264, 10.846380175000101],
              [116.24431614800007, 10.847787054000067],
              [116.24144764200005, 10.849990033],
              [116.23728022200012, 10.851407829000069],
              [116.23463134300006, 10.852069196000079],
              [116.23369188400014, 10.853220897000069],
              [116.23312296200004, 10.856587755000112],
              [116.23368440500005, 10.860049658000024],
              [116.23404380100013, 10.861986566000057],
              [116.2359274600001, 10.86330623700009],
              [116.23880230300003, 10.863007713000082],
              [116.24349328300002, 10.860596383000052],
              [116.24562550600005, 10.857589520000131],
              [116.24676792400007, 10.854482352000081],
              [116.24854532500005, 10.853159752000073],
              [116.24931621400006, 10.851319916000078],
              [116.24918764499999, 10.848123516000017],
            ],
          ],
          [
            [
              [116.74738387400014, 10.859569269000067],
              [116.74483786300006, 10.859396537000075],
              [116.7444926540001, 10.861223263000051],
              [116.74417709900001, 10.863745800000045],
              [116.74512272300001, 10.866076404000056],
              [116.74633806600002, 10.868116937000048],
              [116.74919029700003, 10.867821077000082],
              [116.75121231099999, 10.866659053000125],
              [116.75295941700004, 10.864634433000029],
              [116.75287032300005, 10.862546975000063],
              [116.75209511400013, 10.860906376000033],
              [116.75006473399999, 10.859596924000046],
              [116.74738387400014, 10.859569269000067],
            ],
          ],
          [
            [
              [114.53898031600015, 10.857428787000066],
              [114.53782084800005, 10.856506322000124],
              [114.53513629400007, 10.856696421000043],
              [114.53455220000005, 10.858631752000035],
              [114.53311471600004, 10.860806679000044],
              [114.53126067300002, 10.863420589000048],
              [114.52960806400006, 10.865602198000078],
              [114.5321590210001, 10.867972578000094],
              [114.53510962200008, 10.869371898000056],
              [114.53899797600002, 10.871487549000046],
              [114.54239914500013, 10.873511818000024],
              [114.54600524200008, 10.875210107000031],
              [114.54867245199996, 10.872783679000085],
              [114.55040185599998, 10.869640902000109],
              [114.55123764000002, 10.867165070000071],
              [114.55075998100003, 10.865688834000052],
              [114.54802096600007, 10.864176632000076],
              [114.54370653400005, 10.863885111000025],
              [114.54098116600005, 10.862798435000085],
              [114.53898133699997, 10.860837058000071],
              [114.53723389399998, 10.860039397000063],
              [114.53773418900008, 10.858852223000069],
              [114.53898031600015, 10.857428787000066],
            ],
          ],
          [
            [
              [116.87392328000011, 10.869274549000083],
              [116.87077868200008, 10.868292467000094],
              [116.8679998340001, 10.869246152000043],
              [116.866173719, 10.872668320000043],
              [116.86604861200013, 10.876297401000022],
              [116.86728893499999, 10.878893171000035],
              [116.870658699, 10.881817094000022],
              [116.87265336700003, 10.882290307000096],
              [116.87515688500004, 10.881487571000024],
              [116.87556711400006, 10.881191466000038],
              [116.87732466600008, 10.879444608000098],
              [116.87752787200012, 10.877624096000103],
              [116.87617076, 10.875590829000091],
              [116.875235922, 10.873539705000022],
              [116.87500485600007, 10.871458828000067],
              [116.87392328000011, 10.869274549000083],
            ],
          ],
          [
            [
              [114.56429218200005, 10.868141941000074],
              [114.5608310030001, 10.867611075000115],
              [114.55838859900008, 10.868645978000034],
              [114.55781148500003, 10.870794234000057],
              [114.55493680800012, 10.875144465000107],
              [114.55379607600005, 10.87986662000011],
              [114.55308076000003, 10.881060591000045],
              [114.55400220700005, 10.882949054000067],
              [114.555511688, 10.88471265600006],
              [114.55743706900004, 10.884332994000088],
              [114.56053601900012, 10.880261606000106],
              [114.56174477800005, 10.877670491000034],
              [114.56382732500005, 10.87547762000005],
              [114.56633652400001, 10.873164997000105],
              [114.56776021000012, 10.870567149000097],
              [114.567673095, 10.869527365000041],
              [114.56602958100009, 10.86862016500003],
              [114.56429218200005, 10.868141941000074],
            ],
          ],
          [
            [
              [116.75289640299998, 10.881858098000039],
              [116.75034809000007, 10.88134011000008],
              [116.74856596000006, 10.882875364000098],
              [116.74765132900006, 10.884586957000051],
              [116.74744684300001, 10.886408398000034],
              [116.74919043100013, 10.887590840000081],
              [116.75104518200011, 10.888071342000048],
              [116.75226526100008, 10.886904832000075],
              [116.75316798100008, 10.884914801000088],
              [116.75394766600002, 10.883348267000057],
              [116.75289640299998, 10.881858098000039],
            ],
          ],
          [
            [
              [116.25009357100008, 10.875990063000083],
              [116.2467971640001, 10.874587960000069],
              [116.24426550899997, 10.874687413000037],
              [116.24174502600015, 10.875064865000093],
              [116.24018014300007, 10.878189572000084],
              [116.23916664900005, 10.881014150000022],
              [116.24031880299999, 10.885146149000134],
              [116.24200010700011, 10.888421937000102],
              [116.24364234500001, 10.890724565000108],
              [116.2455659310001, 10.893016148000088],
              [116.24632956300005, 10.892736915000077],
              [116.24810426800006, 10.889574606000084],
              [116.25096380700005, 10.883614069000112],
              [116.25194358900004, 10.879955293000075],
              [116.25213537700009, 10.877719920000038],
              [116.25009357100008, 10.875990063000083],
            ],
          ],
          [
            [
              [116.63777507799998, 10.831459065000091],
              [116.63262231600005, 10.829716076000027],
              [116.62745608700008, 10.832841449000073],
              [116.63346734800007, 10.843087202000044],
              [116.63889058000009, 10.851242856000097],
              [116.64278830300009, 10.863371513000073],
              [116.63844495200007, 10.874163834000059],
              [116.63857428400003, 10.883934259000048],
              [116.64073468199999, 10.888314296000077],
              [116.64674628800002, 10.893652989000111],
              [116.65084420700006, 10.897115238000053],
              [116.658006684, 10.899613105000048],
              [116.66319675699999, 10.902192141000098],
              [116.66570936200006, 10.901529803000097],
              [116.66688261800006, 10.895895017000077],
              [116.665602283, 10.892316793000044],
              [116.66104665, 10.891387556000074],
              [116.65565748599997, 10.890771906000028],
              [116.65163018400008, 10.888982744000051],
              [116.64809596500004, 10.88549734400006],
              [116.649633718, 10.881802962000096],
              [116.65297964500009, 10.88082712200009],
              [116.65414138500002, 10.874913769000026],
              [116.65389428200001, 10.869058556000065],
              [116.65162795400008, 10.862169426],
              [116.65000703600006, 10.850505693000033],
              [116.64558114400006, 10.842588528000084],
              [116.64365584000011, 10.837082008000019],
              [116.63777507799998, 10.831459065000091],
            ],
          ],
          [
            [
              [116.39230422600005, 10.861613951000047],
              [116.39137633500002, 10.859563037000084],
              [116.38872520700014, 10.860086612000062],
              [116.38629405300006, 10.862550176000061],
              [116.38365989899998, 10.86349062400007],
              [116.38072783100003, 10.864025318000079],
              [116.378065256, 10.864270816000053],
              [116.37475157600009, 10.865795072000049],
              [116.372320186, 10.868258504000037],
              [116.37194566800009, 10.872867128000061],
              [116.37116114100002, 10.877770536000051],
              [116.37035955000013, 10.882257057000098],
              [116.36891240900003, 10.888161396000038],
              [116.36932729, 10.893156298000049],
              [116.37140712500003, 10.895857520000083],
              [116.37335223500013, 10.898703315000089],
              [116.37570174700011, 10.901115342000088],
              [116.37871386900011, 10.902526439000047],
              [116.38147763200007, 10.901302432000113],
              [116.38742643200014, 10.898837487],
              [116.39153779800006, 10.896167422000056],
              [116.39462604400005, 10.892563750000049],
              [116.39619196900003, 10.889577759000078],
              [116.39658833600005, 10.888950699000064],
              [116.39729863400011, 10.882248121000069],
              [116.39725718300005, 10.877800366000121],
              [116.39620992, 10.872836719000121],
              [116.39430871, 10.867629163000101],
              [116.39311444700003, 10.864226390000018],
              [116.39230422600005, 10.861613951000047],
            ],
          ],
          [
            [
              [116.90283062600005, 10.90178030400004],
              [116.90067076300014, 10.900756830000089],
              [116.89855923200004, 10.900846350000036],
              [116.89693641300006, 10.902448364000071],
              [116.89478065500013, 10.904769856000128],
              [116.89457757600012, 10.90659044900004],
              [116.89588074900011, 10.907371531000097],
              [116.89882491, 10.906967974000013],
              [116.90101084200003, 10.905342068000044],
              [116.90318464900012, 10.903437887000054],
              [116.90283062600005, 10.90178030400004],
            ],
          ],
          [
            [
              [114.92233709900007, 10.866218849000029],
              [114.91860796299999, 10.863690345000075],
              [114.91060763300008, 10.863211454000064],
              [114.9023432610001, 10.862847013000108],
              [114.89850366500002, 10.863397449000088],
              [114.89680507500007, 10.863877481000104],
              [114.89337241100009, 10.862187267000055],
              [114.88728860700004, 10.859735548000058],
              [114.88262146400007, 10.857979607000077],
              [114.87934603200004, 10.856177987000047],
              [114.87595295300005, 10.854061992000117],
              [114.87243132199998, 10.852904653000016],
              [114.86967293900003, 10.853737325000077],
              [114.86899456400012, 10.855880620000045],
              [114.86948661900006, 10.857773511000078],
              [114.87188429000003, 10.85896770200012],
              [114.87609474700005, 10.859890379000079],
              [114.87995566500004, 10.859975994000058],
              [114.8859109980001, 10.861795807000084],
              [114.89088873300003, 10.863223315000068],
              [114.89505641600007, 10.86605605800006],
              [114.8990671920001, 10.8689999460001],
              [114.90327075100001, 10.872891961000073],
              [114.90823103500009, 10.876971135000078],
              [114.91033652300004, 10.879023021000121],
              [114.91421571800001, 10.881228613000092],
              [114.91715637100005, 10.882616579000091],
              [114.92071104200009, 10.886317605000071],
              [114.92277764100001, 10.890385842000059],
              [114.92435894800012, 10.894364030000057],
              [114.92748923200013, 10.89818507200002],
              [114.92988087900004, 10.902348663000081],
              [114.93108390900009, 10.904642347000058],
              [114.93160170900003, 10.907276840000078],
              [114.93293674400002, 10.908717662000099],
              [114.93540304799997, 10.908742277],
              [114.93681250500006, 10.906044115000045],
              [114.93679415500006, 10.902332493000076],
              [114.935155427, 10.898250169000077],
              [114.932931306, 10.892702322000057],
              [114.93192739000008, 10.889955388000059],
              [114.93022502300009, 10.882397710000101],
              [114.92689483700006, 10.875825871000087],
              [114.92459260000008, 10.869538305000058],
              [114.92233709900007, 10.866218849000029],
            ],
          ],
          [
            [
              [116.68129398800008, 10.904813116000065],
              [116.67812256100004, 10.90326979200009],
              [116.67671317200005, 10.903328050000114],
              [116.67552722399998, 10.905330608000073],
              [116.67601487700009, 10.906845369000083],
              [116.67849342300008, 10.908696464000032],
              [116.6808133830001, 10.91013546700008],
              [116.68142374, 10.907877610000048],
              [116.68154699300014, 10.907453896000073],
              [116.68129398800008, 10.904813116000065],
            ],
          ],
          [
            [
              [116.43709520100006, 10.904306148000041],
              [116.44167185600011, 10.902730454000052],
              [116.44642738600012, 10.903791710000108],
              [116.44954523400007, 10.904362091000051],
              [116.45301839800003, 10.906727359000088],
              [116.45694964000005, 10.905868149000051],
              [116.45905175100008, 10.902959864000115],
              [116.46002622400015, 10.899306335000068],
              [116.45958508800007, 10.895431852000035],
              [116.45820115300003, 10.892568447000082],
              [116.45470540300008, 10.889651104000018],
              [116.4464873310001, 10.883053072000058],
              [116.44199178300002, 10.879754748],
              [116.4372212940001, 10.876606568000023],
              [116.43200931600008, 10.876398510000039],
              [116.42854497900014, 10.877650960000016],
              [116.42540135400009, 10.879864662000037],
              [116.42063953400009, 10.885483234000047],
              [116.41667251600003, 10.891626539000042],
              [116.41149282600011, 10.899070920000041],
              [116.40744936400003, 10.905078042000047],
              [116.40492943700008, 10.908797405000124],
              [116.40723888100003, 10.910235810000033],
              [116.41099614300005, 10.909250272000024],
              [116.41545549500006, 10.90823653300008],
              [116.4249528680001, 10.906603048000106],
              [116.43189966800006, 10.904514899000082],
              [116.43709520100006, 10.904306148000041],
            ],
          ],
          [
            [
              [116.47089554200011, 10.900258105000125],
              [116.46943407000006, 10.898926973000032],
              [116.4681817930001, 10.899255520000038],
              [116.46757713400008, 10.901643073000054],
              [116.46628183600002, 10.904336517000067],
              [116.46524441900007, 10.906463519000019],
              [116.46434740400001, 10.908584869000036],
              [116.4655452720001, 10.91034371400008],
              [116.46902718300007, 10.90950821000005],
              [116.47248613799999, 10.908117535000093],
              [116.47481593700003, 10.906633370000039],
              [116.47572430900004, 10.90478955900007],
              [116.47468401900004, 10.903441446000082],
              [116.47264361899997, 10.901716675000015],
              [116.47089554200011, 10.900258105000125],
            ],
          ],
          [
            [
              [114.59853500700002, 10.899835269000045],
              [114.59665748700002, 10.894266068000123],
              [114.59601255300004, 10.889744296000091],
              [114.5961152660001, 10.883619261000097],
              [114.59553527199998, 10.878009405000098],
              [114.594905326, 10.872586758000045],
              [114.59324312000004, 10.867740847000016],
              [114.59278388300007, 10.863496059000076],
              [114.59264640299996, 10.861166851000091],
              [114.59249030900001, 10.857864123000114],
              [114.59231443500003, 10.855500022000085],
              [114.59246403799997, 10.852385204000113],
              [114.59233966000008, 10.850068870000127],
              [114.59251930200001, 10.844781],
              [114.592637189, 10.840679911000082],
              [114.592442953, 10.837338178000076],
              [114.59237715100006, 10.83659110400008],
              [114.59203206600003, 10.834281358000018],
              [114.59111979600004, 10.830755685000069],
              [114.59048732400002, 10.828856593000056],
              [114.58781236700011, 10.825959282000051],
              [114.58605869400006, 10.824949567000084],
              [114.58479637399998, 10.825840964000031],
              [114.58461566200003, 10.830367420000096],
              [114.58637666600008, 10.835446184000117],
              [114.58807564000006, 10.840857537000124],
              [114.58936226200001, 10.847418723000056],
              [114.58976282300003, 10.853802921000087],
              [114.58915941700002, 10.859894026000026],
              [114.58901293500007, 10.86310751800008],
              [114.58924340600004, 10.865618035000056],
              [114.58962041300006, 10.8680252460001],
              [114.58973765399999, 10.871674804000085],
              [114.59029130000015, 10.8749157450001],
              [114.59017094400005, 10.877387949000104],
              [114.58980109400008, 10.879867993000046],
              [114.58932827300013, 10.88225255000008],
              [114.58871367000009, 10.884888404000035],
              [114.58832477899998, 10.886776650000039],
              [114.58773944600003, 10.888769783000017],
              [114.5870305550001, 10.890026279000063],
              [114.58636204200002, 10.890985269000096],
              [114.58527190300006, 10.892088197000048],
              [114.58318120300009, 10.893320128000104],
              [114.58072160599998, 10.894223049000027],
              [114.57868199000006, 10.894484455000073],
              [114.57693545100005, 10.894539170000071],
              [114.57543049700007, 10.894339452000066],
              [114.57331094100002, 10.893665279000063],
              [114.57082628200014, 10.892508808000033],
              [114.56933966600006, 10.891321087000081],
              [114.56835489000002, 10.890243364000083],
              [114.567765256, 10.88895124000002],
              [114.56783663700004, 10.888060362000116],
              [114.56805131700004, 10.887484289000058],
              [114.56949624200004, 10.885293127000038],
              [114.56945674300006, 10.884060138000072],
              [114.56859109300002, 10.883544156000101],
              [114.56708230299998, 10.884107133000052],
              [114.56485404, 10.885388965000063],
              [114.56371717099999, 10.886554997000085],
              [114.56373695200003, 10.88794172100007],
              [114.5638762240001, 10.889171598000045],
              [114.56433224700008, 10.890934644000081],
              [114.56512021900008, 10.892144244000066],
              [114.56804985000009, 10.894718587000083],
              [114.57226593000006, 10.896907056000058],
              [114.57532012500008, 10.898687499000024],
              [114.57796250600003, 10.900085835000082],
              [114.58031181400008, 10.901690821],
              [114.58174392900001, 10.902732078],
              [114.58399032600008, 10.90424150000011],
              [114.58708275600006, 10.907205497000037],
              [114.58903299900012, 10.908822916000103],
              [114.59065953299998, 10.910371006000029],
              [114.59382551100009, 10.911832225000049],
              [114.59651406100014, 10.911550293000081],
              [114.59927701100004, 10.910476050000016],
              [114.6011283480001, 10.908808854000094],
              [114.60156449500005, 10.907046913000061],
              [114.60100512800003, 10.905188404000031],
              [114.59853500700002, 10.899835269000045],
            ],
          ],
          [
            [
              [116.79686624100009, 10.906700261000067],
              [116.79499819100006, 10.905941280000063],
              [116.79338459900005, 10.907822929000053],
              [116.79221768100004, 10.910244028000092],
              [116.79413355300002, 10.912117379000017],
              [116.79657139700012, 10.912992039000089],
              [116.79794495699998, 10.912097208000125],
              [116.79901271600014, 10.910657029000069],
              [116.79895294199999, 10.909264102000103],
              [116.79861129400005, 10.907883],
              [116.79686624100009, 10.906700261000067],
            ],
          ],
          [
            [
              [114.09305655900008, 10.912491251000043],
              [114.094999731, 10.914139729000015],
              [114.09460752599998, 10.91373368000008],
              [114.09373118900011, 10.912957970000024],
              [114.09305655900008, 10.912491251000043],
            ],
          ],
          [
            [
              [114.094999731, 10.914139729000015],
              [114.09563294100008, 10.914795264000075],
              [114.09548514800011, 10.914551518000069],
              [114.094999731, 10.914139729000015],
            ],
          ],
          [
            [
              [114.09563294100008, 10.914795264000075],
              [114.09590975500006, 10.915251821000103],
              [114.09579605899998, 10.914964139000103],
              [114.09563294100008, 10.914795264000075],
            ],
          ],
          [
            [
              [116.48329856500004, 10.907680872000112],
              [116.48116355000005, 10.9070720760001],
              [116.48068796200005, 10.909176470000135],
              [116.47968511500002, 10.912136252000069],
              [116.48037309000003, 10.91516674300008],
              [116.48353136500012, 10.916707297000093],
              [116.4863628810001, 10.917148915000039],
              [116.48931759000007, 10.917168461000058],
              [116.49067014300006, 10.915862638000105],
              [116.48989331200009, 10.914086885000083],
              [116.48808181300004, 10.911101844000102],
              [116.48473722900007, 10.908456804000098],
              [116.48329856500004, 10.907680872000112],
            ],
          ],
          [
            [
              [116.923214859, 10.906768903000062],
              [116.92118350799997, 10.905461400000059],
              [116.91923086600005, 10.905962532000045],
              [116.91981054400006, 10.909561907000052],
              [116.91983319300007, 10.913324365000053],
              [116.91611691299998, 10.915433654000049],
              [116.91273823900011, 10.915577137000108],
              [116.90922481700011, 10.915865662000089],
              [116.90878260800002, 10.918672168000041],
              [116.91162084400003, 10.920653412000101],
              [116.91716731700002, 10.920128200000077],
              [116.92021609100004, 10.918883567000124],
              [116.92196147700012, 10.916857968000036],
              [116.92308316500012, 10.913465013000055],
              [116.92403982000005, 10.909521536000087],
              [116.923214859, 10.906768903000062],
            ],
          ],
          [
            [
              [114.60744593200005, 10.918400781000058],
              [114.60399164700004, 10.918083496000095],
              [114.60166049699998, 10.919221688000034],
              [114.60090306600003, 10.922440174000057],
              [114.60513274300004, 10.926779737000023],
              [114.60826677800002, 10.930514784000065],
              [114.6111447210001, 10.932979984000022],
              [114.61487608400002, 10.935205286000025],
              [114.61742196500008, 10.935681427000075],
              [114.62144702700004, 10.935364652000066],
              [114.62397961300007, 10.933795620000096],
              [114.62365474000001, 10.930402101000068],
              [114.62203274000001, 10.926836774000035],
              [114.61760840300005, 10.923147053000013],
              [114.61181524399998, 10.92039302100005],
              [114.60744593200005, 10.918400781000058],
            ],
          ],
          [
            [
              [114.09590975500006, 10.915251821000103],
              [114.09625739500005, 10.916131379000136],
              [114.09646798299998, 10.917474565000072],
              [114.09682024600008, 10.91927746700012],
              [114.09653305500004, 10.921098929000015],
              [114.09629351000002, 10.923087679000075],
              [114.09559003900009, 10.925258576000106],
              [114.094858991, 10.926502604000097],
              [114.09404393800006, 10.92779124800005],
              [114.09305459100008, 10.92895844900008],
              [114.09157367900004, 10.930814560000089],
              [114.08941562200009, 10.932858977000047],
              [114.08800115000011, 10.934080648000053],
              [114.0873277960001, 10.934395344000068],
              [114.086097706, 10.934641825000122],
              [114.0847334660001, 10.934681357000018],
              [114.08344574900005, 10.934423504000089],
              [114.08189098900002, 10.93379387000004],
              [114.08066855700004, 10.932859446000085],
              [114.07948751300002, 10.931881648000095],
              [114.07726584500006, 10.930343631000115],
              [114.07518957500012, 10.929391718000135],
              [114.07418659100009, 10.928661742000132],
              [114.07348078600005, 10.927880999000104],
              [114.0721631900001, 10.926611955000086],
              [114.07114525599998, 10.925376401000085],
              [114.07034922800003, 10.924429604000084],
              [114.06972746300009, 10.923604256000095],
              [114.06887883400013, 10.922321645000109],
              [114.0681129970001, 10.920952303000051],
              [114.06748003400003, 10.919747797000044],
              [114.06664390600004, 10.918886481000099],
              [114.06553830500005, 10.91756912100008],
              [114.06438884200006, 10.916210858000051],
              [114.0635841540001, 10.914969140000073],
              [114.06273311800011, 10.913602265000042],
              [114.06158120500001, 10.912159739000058],
              [114.06156625600011, 10.911654192000062],
              [114.06196638800009, 10.910757179000095],
              [114.06283541300004, 10.909846621000073],
              [114.06383854000001, 10.909143017000121],
              [114.06437774800013, 10.908621461000044],
              [114.06486934100012, 10.907932634000071],
              [114.06506379500004, 10.907294539000121],
              [114.06528965700008, 10.906276065000052],
              [114.06551427000011, 10.905215452000048],
              [114.06604349700001, 10.904356878000064],
              [114.06653756600004, 10.903752296000045],
              [114.06788539200009, 10.903165213000053],
              [114.06886080700006, 10.902968364000019],
              [114.07056584200009, 10.902919080000027],
              [114.07176310800003, 10.903010957000079],
              [114.0729566330001, 10.902976443000066],
              [114.07396968, 10.902609813000106],
              [114.07426806300009, 10.902601182000103],
              [114.07482344800012, 10.902627283000042],
              [114.07610471500008, 10.902674538000044],
              [114.07875375600005, 10.902808698000042],
              [114.07940063400011, 10.903042958000063],
              [114.08095641100014, 10.903714694000131],
              [114.08278417900011, 10.904926702000049],
              [114.08487774199997, 10.90646831000006],
              [114.08696631800002, 10.90784138300007],
              [114.08862116400007, 10.908974026000083],
              [114.08971560900014, 10.909912080000071],
              [114.0916838860001, 10.911541585000071],
              [114.09305655900008, 10.912491251000043],
              [114.09058317800006, 10.910392908000064],
              [114.08636048700008, 10.907300173000054],
              [114.083080075, 10.904833795000089],
              [114.08003754100005, 10.902940182000085],
              [114.07685201700009, 10.901978289000079],
              [114.07332924600003, 10.901342318000038],
              [114.07116408900004, 10.901510369000087],
              [114.06664825500005, 10.901725218000101],
              [114.06282688600005, 10.902341557000026],
              [114.05909323200004, 10.903039639000054],
              [114.05597621500007, 10.904394496000068],
              [114.05412802200009, 10.90537541800005],
              [114.05358099000003, 10.907077772000038],
              [114.05459339600004, 10.90957847100009],
              [114.05712259499997, 10.912286565000072],
              [114.06105662700004, 10.914620480000091],
              [114.06475451500003, 10.918477248000078],
              [114.06754210200002, 10.923287935000035],
              [114.06945226700006, 10.927280660000081],
              [114.07185911000008, 10.930753038000061],
              [114.07364106200008, 10.932227514000113],
              [114.07614437499998, 10.934255400000035],
              [114.07953244700001, 10.936106465000071],
              [114.0834420460001, 10.937586823000077],
              [114.08522217900004, 10.938209214000059],
              [114.08705165900007, 10.938029696000038],
              [114.0888586140001, 10.937091815000064],
              [114.08969500300012, 10.936519386000041],
              [114.09188951899996, 10.934579733000048],
              [114.09410022500001, 10.931120772000023],
              [114.09528802300005, 10.929700404000096],
              [114.09632592600005, 10.928484014000082],
              [114.09731686799996, 10.927256957000074],
              [114.09831338600009, 10.925010843000036],
              [114.09817351700005, 10.922053545000042],
              [114.09771672600002, 10.919293597000122],
              [114.09674135000004, 10.916623323000072],
              [114.09590975500006, 10.915251821000103],
            ],
          ],
          [
            [
              [114.66295426100014, 10.96293322600008],
              [114.66430898400006, 10.962893489000079],
              [114.666916849, 10.963661453000027],
              [114.66786540400005, 10.963369860000059],
              [114.66835013800002, 10.962910092000032],
              [114.66832446500003, 10.962120901000015],
              [114.66744539700004, 10.96121067300002],
              [114.665968956, 10.960368788000084],
              [114.66359241500001, 10.959506082000113],
              [114.66150418499997, 10.958288579000095],
              [114.65967035000003, 10.95721112100003],
              [114.65677230000006, 10.955673621000093],
              [114.65472125900008, 10.954059883000065],
              [114.65114401000004, 10.951605732000045],
              [114.64874364500011, 10.950003012000041],
              [114.64546085400002, 10.948921934000031],
              [114.64314046300005, 10.94978533500006],
              [114.64243328899998, 10.951091453000037],
              [114.64237771000001, 10.953643775],
              [114.64667284900011, 10.96473291700004],
              [114.64769005000008, 10.966823874000029],
              [114.65018338399996, 10.971287511000048],
              [114.653985968, 10.976055302000097],
              [114.65610127900004, 10.979642136000043],
              [114.65695766900002, 10.981392547000011],
              [114.65815231800002, 10.982786608000071],
              [114.658999128, 10.984241069000065],
              [114.65973328900009, 10.98530408700009],
              [114.66075579100004, 10.986012310000069],
              [114.66191844000005, 10.986419828000036],
              [114.66361742000004, 10.986415318000107],
              [114.66435504100005, 10.986046265000125],
              [114.66481739100001, 10.984895922000069],
              [114.66506401700008, 10.983258658000048],
              [114.66474385000006, 10.981096235000097],
              [114.66202558800003, 10.972111458000098],
              [114.6616172830001, 10.966169172000127],
              [114.66161747300011, 10.964851902000115],
              [114.66217950799998, 10.963599100000108],
              [114.66295426100014, 10.96293322600008],
            ],
          ],
          [
            [
              [114.73966242499999, 11.007196620000046],
              [114.7369919570001, 11.00654161000003],
              [114.73501268200002, 11.007148250000041],
              [114.73480680000004, 11.008488094000056],
              [114.73623102500002, 11.010763252000098],
              [114.73808518500005, 11.012432058000064],
              [114.73973684700002, 11.013724942000101],
              [114.74254105, 11.014247606000092],
              [114.74392559400012, 11.013726008000011],
              [114.74447247, 11.01234673800011],
              [114.74283519300005, 11.009642276000074],
              [114.73966242499999, 11.007196620000046],
            ],
          ],
          [
            [
              [114.69335459600006, 11.007395325000056],
              [114.68828836800002, 11.005826705000031],
              [114.68592079500004, 11.006790911000051],
              [114.68500587900012, 11.009387733000063],
              [114.68580782500013, 11.012522464000083],
              [114.68992373800006, 11.017245114000039],
              [114.69914986600013, 11.024762692000055],
              [114.70493580700004, 11.028866856000084],
              [114.70815175900012, 11.030404457000119],
              [114.71253615500007, 11.032014565000024],
              [114.71443039300003, 11.031855250000076],
              [114.71479431700004, 11.030757250000088],
              [114.71434638300001, 11.029290149000113],
              [114.71089578600005, 11.024659902000076],
              [114.7069428030001, 11.019946917000121],
              [114.70282898299999, 11.016424127000056],
              [114.69982020300009, 11.013063565],
              [114.69743966000011, 11.010571761000064],
              [114.69335459600006, 11.007395325000056],
            ],
          ],
          [
            [
              [114.18414146500001, 11.029827284000058],
              [114.18203578000013, 11.025225765000089],
              [114.17423952900006, 11.027592491000069],
              [114.16849006000007, 11.032619223000049],
              [114.17498341100006, 11.039229457000131],
              [114.18576312399998, 11.037940738000083],
              [114.18752014200005, 11.037500355000061],
              [114.18414146500001, 11.029827284000058],
            ],
          ],
          [
            [
              [114.34343303600008, 11.050027630000068],
              [114.34280648700012, 11.049972476000034],
              [114.34145961500005, 11.050062513000023],
              [114.34081953600003, 11.050378111000024],
              [114.34040724100011, 11.050785588000076],
              [114.34009788400009, 11.05128872300005],
              [114.34011695600007, 11.051905388000092],
              [114.34024429900003, 11.052790371000041],
              [114.34058342100003, 11.053249243000083],
              [114.34097094900002, 11.053657276000093],
              [114.34157788399997, 11.053885848000087],
              [114.34247607900014, 11.053834041000064],
              [114.34311844700008, 11.053592440000058],
              [114.34355877100009, 11.053282866000112],
              [114.34399374100012, 11.052800634000091],
              [114.3442704820001, 11.052051585000109],
              [114.34421726300005, 11.051139673000064],
              [114.34387051300004, 11.050434139000041],
              [114.34343303600008, 11.050027630000068],
            ],
          ],
          [
            [
              [114.33641158, 11.05574534200011],
              [114.33565616500002, 11.05475584400004],
              [114.334453745, 11.054668652000109],
              [114.33255746200005, 11.054775205000043],
              [114.32887986400014, 11.054663816000064],
              [114.32482237300007, 11.054390981000049],
              [114.32130740400012, 11.053879529000097],
              [114.31805814000003, 11.053878522000014],
              [114.31479355800002, 11.05395600400003],
              [114.31337497900006, 11.054389647000027],
              [114.31287641200002, 11.055244085000114],
              [114.31312698800005, 11.056076003000024],
              [114.31341779000005, 11.05699018400008],
              [114.3151886090001, 11.057004223000087],
              [114.31866587600004, 11.05728038000011],
              [114.32175572800008, 11.057491984000121],
              [114.324705663, 11.058164635000088],
              [114.32880139100004, 11.058498150000061],
              [114.33134044699999, 11.058345505000132],
              [114.333032366, 11.058218367000093],
              [114.33556201100014, 11.057761371000117],
              [114.33621143100004, 11.057356219000079],
              [114.33660249, 11.056710708000049],
              [114.33641158, 11.05574534200011],
            ],
          ],
          [
            [
              [114.28311557499998, 11.044813371000116],
              [114.28092324900004, 11.043659765000056],
              [114.27909476100001, 11.044324008000038],
              [114.2766785150001, 11.045920186000034],
              [114.27706562600008, 11.048499373000096],
              [114.27792388500004, 11.051369277000054],
              [114.27877748700004, 11.054086944000028],
              [114.27948186000006, 11.056961474000014],
              [114.2807553450001, 11.058294996000127],
              [114.28295250300008, 11.059600902000087],
              [114.28525694200006, 11.05937957000007],
              [114.28745984899999, 11.059021691000035],
              [114.2893666030001, 11.057732569000043],
              [114.29084057900002, 11.055554849000069],
              [114.292053376, 11.054908926000094],
              [114.292757628, 11.052754251000072],
              [114.29283678700007, 11.050313508],
              [114.29200636200002, 11.048357205000057],
              [114.28981394800007, 11.047203685000094],
              [114.28684255900006, 11.045768655000108],
              [114.28311557499998, 11.044813371000116],
            ],
          ],
          [
            [
              [114.22320874900001, 11.059764443000029],
              [114.234060095, 11.060802990000072],
              [114.23052406000008, 11.05449524500006],
              [114.22320874900001, 11.059764443000029],
            ],
          ],
          [
            [
              [114.22320874900001, 11.059764443000029],
              [114.2170061840001, 11.049843399000013],
              [114.21252463600001, 11.057748798000054],
              [114.20701217899999, 11.05111051900005],
              [114.19405591700001, 11.046966199000073],
              [114.20448656500001, 11.052156718000015],
              [114.20868783099999, 11.06097137900006],
              [114.22320874900001, 11.059764443000029],
            ],
          ],
          [
            [
              [114.31591507499999, 11.058969688000085],
              [114.31440641200004, 11.058702611000102],
              [114.31515985300004, 11.060727813000069],
              [114.31608831000003, 11.062090447000104],
              [114.31799533199998, 11.063939189000036],
              [114.31981363100007, 11.065409369000088],
              [114.32144504900005, 11.065817753000127],
              [114.32251386300001, 11.065480616000032],
              [114.32263735100003, 11.064485721000048],
              [114.32074671400008, 11.063170240000096],
              [114.31877909700009, 11.061857051000066],
              [114.31687443400006, 11.060084494000042],
              [114.31591507499999, 11.058969688000085],
            ],
          ],
          [
            [
              [114.25823924600006, 11.07024458900012],
              [114.25696582400003, 11.068910899000082],
              [114.25511844900004, 11.068965907000104],
              [114.25392867700009, 11.070372941000082],
              [114.25335933300006, 11.07191391200004],
              [114.2538955010001, 11.074336386000104],
              [114.25427774200003, 11.076763450000014],
              [114.25568656699997, 11.077483528000085],
              [114.257347525, 11.076367244],
              [114.25931172000007, 11.075089507000067],
              [114.2598810290001, 11.073548522000108],
              [114.25968058700003, 11.072030473],
              [114.25823924600006, 11.07024458900012],
            ],
          ],
          [
            [
              [115.01955166300003, 11.070916219000091],
              [115.01853737600004, 11.070331988000069],
              [115.01650658600015, 11.071172309000053],
              [115.0153113260001, 11.07359993700001],
              [115.01531002300008, 11.075636559000122],
              [115.01666137599999, 11.077768482000037],
              [115.01814264000006, 11.079544912000115],
              [115.01828852800006, 11.079657092000071],
              [115.019504127, 11.079897079000121],
              [115.02064505599999, 11.082103799],
              [115.02181613900008, 11.083116930000079],
              [115.02353316300004, 11.083480441000047],
              [115.02471998100009, 11.082879545000067],
              [115.02566753200006, 11.081514994000051],
              [115.02552933500009, 11.079555371000092],
              [115.02516392200005, 11.077182459000044],
              [115.02373921100003, 11.074985275000058],
              [115.02203317200009, 11.072867648000082],
              [115.01955166300003, 11.070916219000091],
            ],
          ],
          [
            [
              [114.37365245800005, 11.079213394000041],
              [114.37262178100003, 11.078477929000117],
              [114.37038047300004, 11.078699238000043],
              [114.36926392700003, 11.080189885000012],
              [114.37065917500001, 11.082677784000071],
              [114.37106440300015, 11.084478902000059],
              [114.36913804100001, 11.084870740000062],
              [114.36778910300004, 11.08636842600008],
              [114.36673330200009, 11.087320501000073],
              [114.36619932700003, 11.090096948000026],
              [114.36613072400004, 11.091632500000069],
              [114.3677512300001, 11.093883626000077],
              [114.37072553300008, 11.094790230000038],
              [114.37455189400013, 11.095670938000078],
              [114.37804699400013, 11.095871568000069],
              [114.38051371300003, 11.095413304000077],
              [114.38211884300006, 11.094674491000054],
              [114.38330327900009, 11.092874992000111],
              [114.38320490000004, 11.09096112900003],
              [114.38222058600005, 11.089227528000032],
              [114.38019837200014, 11.086528682000017],
              [114.37726823400008, 11.084547460000048],
              [114.37523124300003, 11.082615769000091],
              [114.37415051200006, 11.08027170000012],
              [114.37365245800005, 11.079213394000041],
            ],
          ],
          [
            [
              [114.81312364400003, 11.145315574000046],
              [114.81377156000009, 11.134265368000039],
              [114.81283306700007, 11.127850239000024],
              [114.81118166300003, 11.119619612000074],
              [114.81022129200005, 11.108177317000044],
              [114.8068565770001, 11.096382879000098],
              [114.80268860200013, 11.088773543000039],
              [114.79914672100008, 11.084729244000078],
              [114.79606684600006, 11.083681608000083],
              [114.79379672099999, 11.085189139000116],
              [114.79199434900008, 11.087685420000069],
              [114.79275418600007, 11.09311054100006],
              [114.79531413599996, 11.100341869000106],
              [114.79799537100003, 11.106852242000096],
              [114.79969710200005, 11.112247016000063],
              [114.7996117460001, 11.118416740000024],
              [114.80064263300004, 11.123259628000069],
              [114.80213309100009, 11.126653458000122],
              [114.804121192, 11.129744332000067],
              [114.804824411, 11.13345051900003],
              [114.80362032700012, 11.136501363000127],
              [114.80136394600004, 11.138438902000035],
              [114.79756831500003, 11.137701191000103],
              [114.79297228599999, 11.134694533000109],
              [114.78835741, 11.131114716000033],
              [114.783588323, 11.127252970000077],
              [114.77998318600005, 11.12349699900008],
              [114.77727587200005, 11.12057254500005],
              [114.773737267, 11.118822211000088],
              [114.77155940200011, 11.11874899700007],
              [114.77121944200005, 11.121628313000096],
              [114.77280552200001, 11.125736351000091],
              [114.77733671700003, 11.13118362100005],
              [114.78083024, 11.135947231000019],
              [114.78484182100007, 11.138829605000032],
              [114.78758270200001, 11.142756924000084],
              [114.79065744000005, 11.145812901000072],
              [114.79308024300005, 11.148889925000026],
              [114.79393373900005, 11.15007018300007],
              [114.80187165400002, 11.155920234000048],
              [114.80577217000008, 11.155437239000053],
              [114.80945400100008, 11.152739666000054],
              [114.81312364400003, 11.145315574000046],
            ],
          ],
          [
            [
              [116.11625845900005, 11.349585987000042],
              [116.11318248100009, 11.34890241000006],
              [116.1099353220001, 11.349027678000121],
              [116.10799090300007, 11.350564031000053],
              [116.10758519300008, 11.356973260000112],
              [116.106918823, 11.361097801000051],
              [116.10790934400006, 11.365158481000035],
              [116.11135291499997, 11.368304784000125],
              [116.11629131200002, 11.367294446000107],
              [116.12027133299999, 11.363041889000057],
              [116.12090497400001, 11.358098773000068],
              [116.11959451200003, 11.352434695000023],
              [116.11625845900005, 11.349585987000042],
            ],
          ],
          [
            [
              [114.60793101300013, 11.370968590000084],
              [114.60954711200007, 11.368239082000018],
              [114.60786528600011, 11.366531634000053],
              [114.60465308800006, 11.365407985000074],
              [114.59989646700005, 11.36563384800011],
              [114.59639943199998, 11.36528436800006],
              [114.59385421800008, 11.363221230000063],
              [114.59101675300005, 11.361702957000118],
              [114.58772130200013, 11.362801153000122],
              [114.58489192099998, 11.362736688000028],
              [114.58126597100008, 11.360783794],
              [114.57610228600005, 11.359185049000063],
              [114.57264596200007, 11.3576858920001],
              [114.57122253, 11.354362926000048],
              [114.56914633500006, 11.351213417000048],
              [114.56733611300001, 11.347902538000108],
              [114.567145955, 11.344387999000014],
              [114.56772027300009, 11.341767987000127],
              [114.569037, 11.339354380000087],
              [114.57119498000009, 11.336608362000076],
              [114.57344008700014, 11.334165739000033],
              [114.57523623600007, 11.333420803000125],
              [114.57858901200009, 11.331708786000137],
              [114.58150208399999, 11.330775798000035],
              [114.58440412000003, 11.331909440000041],
              [114.58750880199996, 11.332118328000094],
              [114.59066624600008, 11.331560197000011],
              [114.5922326570001, 11.330898854000091],
              [114.59458085499998, 11.33166706700006],
              [114.59682605000002, 11.334045633000043],
              [114.59916098400004, 11.3368040220001],
              [114.60291582700003, 11.340359677000043],
              [114.60520235600004, 11.342813419000029],
              [114.60821661400004, 11.345014690000086],
              [114.6101205250001, 11.346408961000074],
              [114.61181539800009, 11.346126082000028],
              [114.61316865800005, 11.344859014000084],
              [114.61411527500005, 11.342992464000099],
              [114.61251099, 11.341282677000029],
              [114.61084664700005, 11.34011049600012],
              [114.61038326900007, 11.337752580000069],
              [114.61018744700003, 11.334085225000122],
              [114.60828118300005, 11.332614526000027],
              [114.60580870000003, 11.330396280000075],
              [114.60366223600015, 11.327478996000105],
              [114.60195861400005, 11.325083522000055],
              [114.5980163080001, 11.322911362000097],
              [114.59548391400008, 11.321230620000032],
              [114.59135709500005, 11.321742672000122],
              [114.58866153400004, 11.322209802000048],
              [114.58572288600007, 11.32475081700006],
              [114.58360327100002, 11.326271269000097],
              [114.58059073500007, 11.327356578000101],
              [114.57644148400004, 11.327702491000123],
              [114.57084907500007, 11.325828377000045],
              [114.56793320000013, 11.325267271000071],
              [114.5645976620001, 11.32500331200008],
              [114.562141473, 11.325692211000058],
              [114.56087587400009, 11.327108282000074],
              [114.55935471200009, 11.330637688000065],
              [114.55863065500003, 11.333479927000043],
              [114.5583017890001, 11.33561618000005],
              [114.55722304400001, 11.339539511000039],
              [114.55690994800004, 11.343438994000097],
              [114.55658301600008, 11.346906726000048],
              [114.5566379390001, 11.352445628000039],
              [114.557304442, 11.356328019000015],
              [114.55966653500003, 11.361152470000134],
              [114.56131218000004, 11.364162483000049],
              [114.56426115300006, 11.366749144000019],
              [114.56907670500006, 11.368359074000088],
              [114.57264246900004, 11.370849840000028],
              [114.57660388700008, 11.371185198000065],
              [114.58057469399999, 11.371813087000058],
              [114.58385437300001, 11.372627667000044],
              [114.58543894400012, 11.373724589000032],
              [114.58702106600002, 11.374745135000095],
              [114.5894126960001, 11.374440910000059],
              [114.59124540300004, 11.373619124000099],
              [114.59218259600004, 11.371449555000089],
              [114.59290728000005, 11.369898138000066],
              [114.59518835600014, 11.370973148000106],
              [114.59856759900006, 11.372472292000067],
              [114.60256354100011, 11.373875585000091],
              [114.60453269200005, 11.374883862000027],
              [114.60608024400003, 11.373628932000111],
              [114.60793101300013, 11.370968590000084],
            ],
          ],
          [
            [
              [114.61935061200005, 11.374948942000083],
              [114.61707121000009, 11.373950274000054],
              [114.61521834700009, 11.37416147200012],
              [114.61387680700003, 11.375809274000035],
              [114.613014515, 11.377900731000066],
              [114.61240661800007, 11.380672324000061],
              [114.61303976100014, 11.383481353000134],
              [114.61479465000002, 11.385031790000076],
              [114.61715630700003, 11.384975738000056],
              [114.61872564900003, 11.383398303000101],
              [114.6207768380001, 11.380714816000113],
              [114.62171648600005, 11.378620882000055],
              [114.62088064700008, 11.376735760000132],
              [114.61935061200005, 11.374948942000083],
            ],
          ],
          [
            [
              [116.21322421800004, 11.373027708000015],
              [116.21041095300005, 11.372906505000023],
              [116.20774226900005, 11.373472825000073],
              [116.204755436, 11.374860297000032],
              [116.20343661500013, 11.376991411000057],
              [116.20303860099997, 11.37873997700005],
              [116.20264982400013, 11.380719279000038],
              [116.202733029, 11.382795700000068],
              [116.20348491900005, 11.384037278000035],
              [116.20425531000001, 11.385740297000078],
              [116.20524552200006, 11.38708811000013],
              [116.20752564500006, 11.388501125000071],
              [116.21046984300004, 11.388963878000078],
              [116.21279744600007, 11.388641910000084],
              [116.2146623000001, 11.38845353700013],
              [116.21614884300007, 11.387586683000082],
              [116.21750002200005, 11.38626297100007],
              [116.21856190600008, 11.383564092000052],
              [116.21868443600009, 11.380786399000085],
              [116.21811520000006, 11.378266836000071],
              [116.21768135000005, 11.37620412100002],
              [116.21573327500006, 11.374316120000037],
              [116.21322421800004, 11.373027708000015],
            ],
          ],
          [
            [
              [114.29833895300013, 11.379196712000093],
              [114.29737902000011, 11.379102276000095],
              [114.29692172700003, 11.379115967000073],
              [114.29628246299998, 11.379331194000118],
              [114.29610291600014, 11.3795510360001],
              [114.29579051700004, 11.38021728500007],
              [114.29568597800009, 11.380877321000058],
              [114.29563562300009, 11.381946295000132],
              [114.29561928200008, 11.382767919000079],
              [114.29585363000002, 11.383623089000048],
              [114.29599979000004, 11.384316672000059],
              [114.29606784899998, 11.385176821000085],
              [114.29610316299997, 11.38632535100008],
              [114.296250578, 11.387059955000021],
              [114.29636149200003, 11.387959882000052],
              [114.2965051250001, 11.388571439000115],
              [114.29677853500007, 11.389343331000122],
              [114.29700657900003, 11.389993410000086],
              [114.29731274700013, 11.390476931000125],
              [114.29770583100006, 11.391081021000014],
              [114.29821860300004, 11.391517289000051],
              [114.29885736300004, 11.391990840000064],
              [114.29924163100013, 11.392307791000087],
              [114.29995596400008, 11.392532732000047],
              [114.30055062300008, 11.392925494000037],
              [114.300936147, 11.393283454000079],
              [114.30140104600001, 11.393515871000124],
              [114.30178279499998, 11.39375077900011],
              [114.30219853600009, 11.393738323000056],
              [114.30265333500006, 11.393642572000127],
              [114.30318243600004, 11.393257210000039],
              [114.30321390900008, 11.392927807000051],
              [114.30302993300005, 11.392358526000111],
              [114.30272250500001, 11.391834001000054],
              [114.302207197, 11.391315696000069],
              [114.30164780200008, 11.390716597000059],
              [114.30133279700013, 11.389945964000068],
              [114.30102409700008, 11.389380411000081],
              [114.30074941700001, 11.388567505000056],
              [114.30056040300008, 11.387834147000129],
              [114.30045958600009, 11.387262377000072],
              [114.30044191600005, 11.386688107000074],
              [114.30038519700007, 11.38619712200004],
              [114.30040783900007, 11.385580588],
              [114.30068369700007, 11.38507965900004],
              [114.30116995400007, 11.384654523000036],
              [114.30157557700015, 11.384313916000098],
              [114.302151288, 11.384091391000071],
              [114.3024359780001, 11.383877576000055],
              [114.30325480200008, 11.383442471000114],
              [114.30378009600011, 11.382934045000058],
              [114.304016905, 11.38251637900005],
              [114.30437843000007, 11.382094984000089],
              [114.30465806300002, 11.381717091000086],
              [114.30473110500012, 11.381386456000085],
              [114.3047159490001, 11.380894229000125],
              [114.304577331, 11.38044676000005],
              [114.30398775099998, 11.380218100000137],
              [114.30305802000007, 11.379753292000048],
              [114.30168109899999, 11.379630324000088],
              [114.29971586900001, 11.379319694000054],
              [114.29833895300013, 11.379196712000093],
            ],
          ],
          [
            [
              [116.330099172, 11.390718167000012],
              [116.33075855700011, 11.387922323000053],
              [116.33186807700008, 11.384599025000062],
              [116.33045877100008, 11.380555708000093],
              [116.3274092290001, 11.376987287000071],
              [116.32227199400003, 11.373091571000058],
              [116.31632313700005, 11.369637763000105],
              [116.31383778700003, 11.369736043000065],
              [116.31055721500002, 11.370685552000024],
              [116.30939758400008, 11.372780908000022],
              [116.30994471500006, 11.376038563000089],
              [116.31093934100011, 11.380098365],
              [116.31276250100005, 11.384125435000046],
              [116.31713782300005, 11.389691251000043],
              [116.31972320700001, 11.392048483000037],
              [116.32103255800007, 11.393636358000057],
              [116.32439665900006, 11.39473297600002],
              [116.32721333000006, 11.392571856000087],
              [116.330099172, 11.390718167000012],
            ],
          ],
          [
            [
              [114.63657536600006, 11.387584886000111],
              [114.63498584, 11.386889227000017],
              [114.63187150300006, 11.387241531000123],
              [114.63045811000009, 11.388256950000118],
              [114.63037510600006, 11.390479761000092],
              [114.63248450100006, 11.39339904200005],
              [114.63400435200005, 11.397255635000112],
              [114.63632381400012, 11.399479252000065],
              [114.63921651700001, 11.40145507800012],
              [114.64206063599997, 11.403126161],
              [114.64429538200004, 11.40512269600009],
              [114.64713463700004, 11.406640756000018],
              [114.64927216600006, 11.408027847000122],
              [114.65120127899998, 11.408962153000067],
              [114.65218787199998, 11.40831846900001],
              [114.65288751100006, 11.405999515000074],
              [114.65256330300005, 11.403177041000044],
              [114.65152743300006, 11.399917713000105],
              [114.64997949200006, 11.397593312000074],
              [114.64764106400001, 11.395982930000057],
              [114.64627285100005, 11.394418425000106],
              [114.64381341000004, 11.392658708000079],
              [114.64174355500002, 11.390963238000039],
              [114.63897949500003, 11.389366209000061],
              [114.63803069800004, 11.388783669000089],
              [114.63657536600006, 11.387584886000111],
            ],
          ],
          [
            [
              [116.14103903700004, 11.387527557000075],
              [116.13670236000006, 11.387022485000029],
              [116.13331755900003, 11.38760203900002],
              [116.13139163600012, 11.390592463000091],
              [116.13133380700003, 11.394856480000024],
              [116.13172969100002, 11.399102979000107],
              [116.13322428800005, 11.402409779000065],
              [116.13421180800005, 11.40439035400007],
              [116.13636036700009, 11.406998943000078],
              [116.13846445900001, 11.408487691000067],
              [116.14055076899997, 11.409528489000119],
              [116.14371796100011, 11.412502432000069],
              [116.15018714000006, 11.423286906000028],
              [116.1535775670001, 11.42853906],
              [116.159717656, 11.434357484000122],
              [116.16401072500003, 11.43374224400011],
              [116.16958465100008, 11.431058319000085],
              [116.16869840400003, 11.425933460000069],
              [116.16716731699999, 11.421730935000072],
              [116.16396779700003, 11.415574382000047],
              [116.16019499600006, 11.412131824000047],
              [116.15616870599999, 11.408026117000068],
              [116.15418429600003, 11.403841184000017],
              [116.15042971800005, 11.400846429000032],
              [116.14833454100008, 11.399581769000109],
              [116.14590558700002, 11.395638362000058],
              [116.14485561500008, 11.39209014900011],
              [116.14427717000001, 11.388972288000064],
              [116.14103903700004, 11.387527557000075],
            ],
          ],
          [
            [
              [114.32950037600006, 11.426950929000055],
              [114.32868666700006, 11.42657969400007],
              [114.3280291650001, 11.426627746000049],
              [114.32751304600004, 11.42661499900006],
              [114.32708716600006, 11.426740868000083],
              [114.32672202200004, 11.426977971000072],
              [114.32641053100011, 11.427277388000066],
              [114.32626158400004, 11.427839785000058],
              [114.32619491300012, 11.428463627000072],
              [114.32627485400008, 11.429196122000105],
              [114.32658378699998, 11.429921731000102],
              [114.32694735900012, 11.430560899000051],
              [114.3274601750001, 11.431393437000013],
              [114.32820025200013, 11.432162613000061],
              [114.32870260100005, 11.432656281000051],
              [114.32922921200009, 11.433007885000011],
              [114.32970729900003, 11.433643605000031],
              [114.33035889400004, 11.434330640000033],
              [114.331120629, 11.434873025000057],
              [114.33181726100008, 11.435162980000067],
              [114.33222676000004, 11.435433305000052],
              [114.33276890400005, 11.435360459000064],
              [114.33315831200002, 11.434981270000042],
              [114.33340459300007, 11.434606408000112],
              [114.33341836500006, 11.43412547500011],
              [114.33340263199999, 11.433617176000064],
              [114.33326891100003, 11.432999359000105],
              [114.33284980699999, 11.432418397000086],
              [114.33257820600008, 11.431974328000114],
              [114.33244536200002, 11.431384749000093],
              [114.33212941000008, 11.430433236000058],
              [114.33182132199998, 11.42973588100009],
              [114.3315479610001, 11.429235330000063],
              [114.33112887900006, 11.428654377000045],
              [114.33071153700008, 11.428129887000081],
              [114.33040869500007, 11.427601960000098],
              [114.32999485800012, 11.427190434000066],
              [114.32950037600006, 11.426950929000055],
            ],
          ],
          [
            [
              [114.68896461300001, 11.433175043000075],
              [114.68741058200013, 11.433071480000121],
              [114.68571349600003, 11.434522209000074],
              [114.68546365799997, 11.436368195000044],
              [114.68738750100007, 11.438298259000044],
              [114.68855933500005, 11.440941503000078],
              [114.69050574399998, 11.443560109000115],
              [114.69193124299997, 11.445659183000066],
              [114.69293075399999, 11.447771807000072],
              [114.69421009500006, 11.448956487000126],
              [114.69614652000006, 11.448894852000089],
              [114.69771370400005, 11.4474007410001],
              [114.69787671800012, 11.444899757000114],
              [114.6965624330001, 11.44264700800006],
              [114.69430375400009, 11.439965394000049],
              [114.69211508600009, 11.437052091000053],
              [114.69041366400009, 11.434811645000041],
              [114.68896461300001, 11.433175043000075],
            ],
          ],
          [
            [
              [114.36052185800004, 11.459832004000068],
              [114.36112234399999, 11.459785576000046],
              [114.36186865100001, 11.459819557000104],
              [114.36235283600001, 11.459720086000045],
              [114.36263393700008, 11.45954194600005],
              [114.36265467100012, 11.459286851000082],
              [114.36252782700001, 11.458894853000066],
              [114.36210936900012, 11.458342034000067],
              [114.36134550200006, 11.457743113000072],
              [114.36055301400005, 11.45714505500006],
              [114.35949579100006, 11.456328803000114],
              [114.35867116900013, 11.455618610000061],
              [114.35831701700013, 11.455290027000052],
              [114.35796110200008, 11.454904961000077],
              [114.35748800700001, 11.454438607000037],
              [114.3569854110001, 11.453944861000041],
              [114.35624847600005, 11.453288566000095],
              [114.35560182500001, 11.452770907000074],
              [114.35501596400003, 11.452364510000022],
              [114.35439707800006, 11.451817736000079],
              [114.353924007, 11.451351376000039],
              [114.35308043200011, 11.450952749000097],
              [114.35260912000001, 11.450542879000112],
              [114.35104529500003, 11.450024628000042],
              [114.3501809830001, 11.449881081000067],
              [114.34978271800001, 11.44997792],
              [114.34923776700013, 11.449966094000084],
              [114.34880912400001, 11.45000730300003],
              [114.34812975900003, 11.450282259000042],
              [114.34785217200006, 11.45057337200006],
              [114.34769524400005, 11.451058754000075],
              [114.34762685699998, 11.451626293000048],
              [114.34772941200004, 11.452160393],
              [114.34821123000002, 11.452909242000063],
              [114.34877808000012, 11.453627251000052],
              [114.34940309000004, 11.454371769000103],
              [114.34999420300002, 11.454947670000058],
              [114.35088220600004, 11.455853894000077],
              [114.3517579300001, 11.456364664000061],
              [114.35264593800004, 11.457270882000079],
              [114.35314765500007, 11.457736375000048],
              [114.35381855800007, 11.458111948000036],
              [114.35472119800006, 11.458565330000068],
              [114.35562032500003, 11.458905730000078],
              [114.35648906499996, 11.459190499000014],
              [114.3573838030001, 11.459389650000061],
              [114.35847813500006, 11.459554501000111],
              [114.35963061700011, 11.459745853000046],
              [114.36052185800004, 11.459832004000068],
            ],
          ],
          [
            [
              [114.39030724100007, 11.468575427000081],
              [114.39110910500007, 11.468551071000109],
              [114.39168273600001, 11.468561919000118],
              [114.3924255420001, 11.468482804000056],
              [114.39408652300004, 11.468432333000054],
              [114.39468614400009, 11.468357561000085],
              [114.39531175300007, 11.468197159000047],
              [114.395881862, 11.468095008000121],
              [114.39650129100008, 11.467736884000066],
              [114.39697665100007, 11.467354865000077],
              [114.39745377500003, 11.467029338000055],
              [114.39784233900002, 11.466621683000076],
              [114.39816920400007, 11.466074535000102],
              [114.39841105000009, 11.465558246000016],
              [114.39871105100005, 11.465068453000081],
              [114.39883657400004, 11.46449915500005],
              [114.39884313600014, 11.46379210700008],
              [114.39884175100011, 11.462830822000035],
              [114.39876240500003, 11.462126389000014],
              [114.39871611000002, 11.461562319000061],
              [114.39872001400008, 11.460770526000095],
              [114.39868293100002, 11.459584152000078],
              [114.398633096, 11.458907080000101],
              [114.39855640100004, 11.458287390000075],
              [114.39850922400004, 11.457695076000094],
              [114.39840389400004, 11.457076248000051],
              [114.39832808300007, 11.456484806000054],
              [114.39828179100006, 11.455920739000101],
              [114.39818176000004, 11.455471401000048],
              [114.39794471500014, 11.455224141000107],
              [114.39768611600006, 11.455203732000106],
              [114.39722794600007, 11.455217664000104],
              [114.39659883800007, 11.455265066000029],
              [114.39583362300009, 11.455542798000094],
              [114.39516136300006, 11.456043897000077],
              [114.39387587500002, 11.457100824000042],
              [114.39352037700009, 11.457648829000087],
              [114.39319262199997, 11.458167717000032],
              [114.39295165400002, 11.458712251000057],
              [114.39253887900006, 11.459261995000068],
              [114.39226839000005, 11.459779152000047],
              [114.39205517100001, 11.46029456000006],
              [114.39152431000005, 11.46073479800007],
              [114.39118949800006, 11.461027703000058],
              [114.390707969, 11.461211980000117],
              [114.389997342, 11.461403205000041],
              [114.38957220100009, 11.461557484000105],
              [114.38874085600006, 11.461554457000121],
              [114.3882756190001, 11.461342396000072],
              [114.38748569200003, 11.460829186000064],
              [114.3869499650001, 11.460195144000094],
              [114.38671998800008, 11.460173843000108],
              [114.38623933800002, 11.460386356000106],
              [114.38570846500015, 11.460826579000114],
              [114.3852366230001, 11.461321556000124],
              [114.38452026000007, 11.462248063000045],
              [114.38380301800005, 11.463146322000027],
              [114.38333997500001, 11.463923773000049],
              [114.38324039400004, 11.464407453000101],
              [114.38325713000009, 11.464944151000099],
              [114.38329986000008, 11.465395240000118],
              [114.383571692, 11.465839378000096],
              [114.3837219230001, 11.466061017000049],
              [114.38437222000007, 11.466691596000087],
              [114.38504585300004, 11.467151814000063],
              [114.38603451300003, 11.467602468000047],
              [114.38682094700009, 11.468002710000034],
              [114.38742762000001, 11.468153944000024],
              [114.38837971000009, 11.468351226],
              [114.38924236400007, 11.468438117000089],
              [114.39030724100007, 11.468575427000081],
            ],
          ],
          [
            [
              [116.330099172, 11.390718167000012],
              [116.33182573600004, 11.395778219000084],
              [116.32294188900005, 11.398380152000074],
              [116.32496518400004, 11.407318425000044],
              [116.32957449900002, 11.418613985000102],
              [116.33408432600004, 11.427453935000107],
              [116.34031868000002, 11.437865292000103],
              [116.34240986300003, 11.448441028000021],
              [116.34612548900006, 11.458132520000051],
              [116.34881225900006, 11.462945392000011],
              [116.35232799100007, 11.467725349000089],
              [116.35833045399995, 11.472406490000074],
              [116.36423281900009, 11.474631655000065],
              [116.36827672800011, 11.472011021000037],
              [116.3704954240001, 11.465363305000052],
              [116.36939877700004, 11.458847569000062],
              [116.36243388700011, 11.450925462000059],
              [116.35357817700003, 11.437339060000083],
              [116.34624746100012, 11.420412574000059],
              [116.34339449000005, 11.41150722600006],
              [116.34219892200008, 11.402536206000134],
              [116.33772263000007, 11.394515247000035],
              [116.330099172, 11.390718167000012],
            ],
          ],
          [
            [
              [114.70003446500004, 11.462178929000098],
              [114.69739053000004, 11.460661653000058],
              [114.69352697600003, 11.46123515000008],
              [114.69112744700008, 11.46375978800007],
              [114.68824797199996, 11.46584060400002],
              [114.68618083400007, 11.466671413000105],
              [114.683276189, 11.470359689000096],
              [114.68125139499998, 11.472489769000036],
              [114.67851918700003, 11.47433626800005],
              [114.67517896700006, 11.475379857000066],
              [114.67112692600008, 11.477193817000051],
              [114.66916954899996, 11.479017914000076],
              [114.66811439700005, 11.482345621000077],
              [114.6686516650001, 11.484550313000062],
              [114.67071269700001, 11.484714813000084],
              [114.67383236800003, 11.485228774000065],
              [114.67818943200011, 11.485626792000046],
              [114.68209905400012, 11.486575226000094],
              [114.685518763, 11.486773027000117],
              [114.68767480600005, 11.485095660000065],
              [114.68992453100012, 11.482725794000091],
              [114.69266167800002, 11.481029904000104],
              [114.69514878700011, 11.478805665000012],
              [114.69874572900008, 11.477312163000073],
              [114.70098656700013, 11.475861814000064],
              [114.70253614700009, 11.473437516000027],
              [114.70318822000004, 11.469662828000081],
              [114.70260551400006, 11.466080730000082],
              [114.70179674000011, 11.463884816000112],
              [114.70003446500004, 11.462178929000098],
            ],
          ],
          [
            [
              [114.66698157800012, 11.490157449000014],
              [114.66574009500007, 11.487746572000022],
              [114.66030112100009, 11.487382843000125],
              [114.65209633400005, 11.487795556000078],
              [114.64249242200007, 11.488098979000048],
              [114.63712254000004, 11.486277672000076],
              [114.63455238200001, 11.483525714000018],
              [114.63365889200007, 11.479878635000061],
              [114.63280724800003, 11.47393323700001],
              [114.63313123700001, 11.467185160000069],
              [114.63447468600006, 11.464386423000049],
              [114.63680595300005, 11.462169044000079],
              [114.63664915400004, 11.459723879000093],
              [114.63444670900006, 11.456347902000095],
              [114.63373514, 11.449938885000053],
              [114.63266464000004, 11.445608475000045],
              [114.63120361400004, 11.439988812000122],
              [114.62971633100008, 11.434752823000059],
              [114.62783117900007, 11.427998146000016],
              [114.62757794199999, 11.422570215000059],
              [114.62603869599999, 11.412129814000059],
              [114.62436703299997, 11.40238276100005],
              [114.62358031699998, 11.398426447000086],
              [114.62210178400004, 11.39572437900007],
              [114.62032618400012, 11.394853959000082],
              [114.61886516600012, 11.395206171],
              [114.61686391300009, 11.398025266000021],
              [114.61633457199999, 11.400142305000125],
              [114.6154148510001, 11.405574104000047],
              [114.61532856900007, 11.409683020000026],
              [114.61527018700012, 11.414655559000051],
              [114.61596250900003, 11.422522131000077],
              [114.61633486300002, 11.427265077000113],
              [114.61693113900006, 11.433400014000126],
              [114.61734260800009, 11.441497548000077],
              [114.61715388500011, 11.445826008000013],
              [114.61708157899997, 11.450366966000034],
              [114.61750233400006, 11.457340921000101],
              [114.6180931690001, 11.464825762000073],
              [114.61885368100005, 11.469166117000016],
              [114.62007159600002, 11.476861044000051],
              [114.62190658000006, 11.480861365000104],
              [114.62489512600006, 11.488700935000027],
              [114.62652890800005, 11.491783503000084],
              [114.62850162100003, 11.49517961600006],
              [114.63201037500009, 11.498635380000087],
              [114.63713672600014, 11.501283597000088],
              [114.64521879900006, 11.503838361000083],
              [114.65312140900005, 11.50423706100012],
              [114.65861137300007, 11.503362765000078],
              [114.66440590400008, 11.500346217000066],
              [114.66739946599996, 11.497035443000046],
              [114.66786957700005, 11.492426376000074],
              [114.66698157800012, 11.490157449000014],
            ],
          ],
          [
            [
              [116.19135260000003, 11.452153315000134],
              [116.188066349, 11.449914753000099],
              [116.18358676800008, 11.45114087300003],
              [116.18187186000003, 11.454888840000075],
              [116.18148684100007, 11.458585078000015],
              [116.18328220000001, 11.463511263000024],
              [116.18509865500003, 11.468962558000049],
              [116.18679968, 11.471525877000131],
              [116.18875624300006, 11.473816293000104],
              [116.19332765400011, 11.481526935000106],
              [116.19892144400002, 11.48814589200011],
              [116.20580363500005, 11.493662659000046],
              [116.21482502299997, 11.499358705000072],
              [116.2192503560001, 11.503393275000027],
              [116.22581478300003, 11.507607120000072],
              [116.23041150700014, 11.509268107000102],
              [116.2351158570001, 11.506980272000076],
              [116.23841589200002, 11.50290656400008],
              [116.23930033400011, 11.498401523000011],
              [116.23725842400009, 11.4940111070001],
              [116.23218274100006, 11.493683864000024],
              [116.22705430000011, 11.492043766000036],
              [116.22186264200006, 11.488828253000081],
              [116.21853329300011, 11.485539865000048],
              [116.21629972400004, 11.482997536000104],
              [116.21431115100009, 11.479919715000085],
              [116.20930176200002, 11.47459319100008],
              [116.20487725700005, 11.47055856100012],
              [116.20230437400006, 11.466188723000046],
              [116.1994655980001, 11.461829228000029],
              [116.19719047300006, 11.458236622000049],
              [116.19489439499998, 11.454118925000055],
              [116.19135260000003, 11.452153315000134],
            ],
          ],
          [
            [
              [116.38326602800008, 11.493945294000072],
              [116.37739642000008, 11.492539214000084],
              [116.37331872600011, 11.494341473000079],
              [116.3689818190001, 11.500138520000023],
              [116.37209624500012, 11.50504964300003],
              [116.37740531400001, 11.513038014000097],
              [116.38347677000002, 11.519356009000068],
              [116.38616522100006, 11.524168775000108],
              [116.38550391300005, 11.528295094000017],
              [116.38401347700001, 11.532454474000085],
              [116.38587299400004, 11.537300379000024],
              [116.39114987000002, 11.544470063000032],
              [116.39559794, 11.551672757000107],
              [116.39925072800007, 11.559727226000048],
              [116.39703193300008, 11.566376041000035],
              [116.39730077400012, 11.572925634000073],
              [116.39909374700005, 11.576134250000079],
              [116.40393483700004, 11.572660782000098],
              [116.40774462199995, 11.564308187000092],
              [116.40903289900005, 11.555236371000086],
              [116.41025375700008, 11.544527298000061],
              [116.4045390360001, 11.526715542000064],
              [116.40174927500003, 11.519447048000051],
              [116.3972009050001, 11.509788853000112],
              [116.39189113900007, 11.501801014000089],
              [116.38837389900002, 11.497021515000048],
              [116.38326602800008, 11.493945294000072],
            ],
          ],
          [
            [
              [116.27237008700001, 11.51730049200008],
              [116.26589879100005, 11.514727266000067],
              [116.26209715000002, 11.515190783000049],
              [116.25994810700001, 11.51716020900002],
              [116.25950229899999, 11.521890362000063],
              [116.25895520100002, 11.524111089000131],
              [116.26163750700007, 11.527461734000076],
              [116.26720619199996, 11.531327341000084],
              [116.27347430900006, 11.536736249000066],
              [116.28264194400001, 11.542973449000014],
              [116.29126330700005, 11.551431178000103],
              [116.2959430520001, 11.556902187000071],
              [116.30354807700006, 11.563828694000032],
              [116.31114088299999, 11.570441294000132],
              [116.31877244800003, 11.577994768000098],
              [116.32672253700004, 11.585535465000072],
              [116.33272702000009, 11.592210361000086],
              [116.33812064900005, 11.595610143000011],
              [116.34187243300006, 11.593890407000094],
              [116.34489863200007, 11.589999893000067],
              [116.34665285200001, 11.586159806000039],
              [116.34746594100004, 11.582671274000054],
              [116.34376366200004, 11.577790818],
              [116.33851020100005, 11.573914410000084],
              [116.33152671700007, 11.566650204000071],
              [116.32689686000008, 11.562434696000066],
              [116.32085517600005, 11.554818802000087],
              [116.31160937700012, 11.546701038000061],
              [116.30106728899997, 11.538005773000036],
              [116.29627340500008, 11.533639178000053],
              [116.28969962300008, 11.528557171000029],
              [116.28051972500009, 11.522006719],
              [116.27237008700001, 11.51730049200008],
            ],
          ],
          [
            [
              [116.37214018200002, 11.616899529000067],
              [116.36908849100004, 11.614232568000114],
              [116.36414538700005, 11.615249162000028],
              [116.3593026230001, 11.618721910000128],
              [116.35777754200004, 11.622062824000066],
              [116.36046672900014, 11.626876544000096],
              [116.36657452100005, 11.634014703000071],
              [116.372414616, 11.634602562000039],
              [116.37739168200004, 11.634404522000084],
              [116.37967893900012, 11.629392861000019],
              [116.37951121500009, 11.625299059000048],
              [116.37685513100006, 11.621304342000103],
              [116.37214018200002, 11.616899529000067],
            ],
          ],
          [
            [
              [116.40713307300007, 11.643410365000049],
              [116.40037388500001, 11.642083905000076],
              [116.39587295300009, 11.642263500000103],
              [116.39253077800007, 11.64251081800005],
              [116.39048611500007, 11.643390504000093],
              [116.39050147300011, 11.646582574000062],
              [116.3902673250001, 11.649328504000065],
              [116.39085040500002, 11.652269917000078],
              [116.39176107000006, 11.654742170000086],
              [116.39229747200002, 11.656545197000055],
              [116.39304604200007, 11.657883674000045],
              [116.3969216820001, 11.662176130000111],
              [116.40217787800006, 11.666299419000035],
              [116.40815959300009, 11.671191836000093],
              [116.41440055200009, 11.676757925000024],
              [116.42062690900002, 11.680557000000123],
              [116.42620404500008, 11.686833572000042],
              [116.42875014600006, 11.689696459000041],
              [116.43285608200006, 11.691128458000042],
              [116.43540035600007, 11.691140551000066],
              [116.43749966800013, 11.68877578100007],
              [116.43739632900011, 11.686271237000055],
              [116.43536156399998, 11.68179154500004],
              [116.43176061600002, 11.67577818500005],
              [116.42708538799998, 11.671746327000053],
              [116.42274724600004, 11.667472841000047],
              [116.41783686000011, 11.663336213],
              [116.41490326200002, 11.660175221000111],
              [116.41329551600005, 11.658329545000077],
              [116.41108690900001, 11.655225069000073],
              [116.41026802100011, 11.652179092000024],
              [116.41014630000006, 11.649219279000087],
              [116.40949741200004, 11.644684202000109],
              [116.40713307300007, 11.643410365000049],
            ],
          ],
          [
            [
              [116.48155453199999, 11.663531852000107],
              [116.47726367900006, 11.662735578000126],
              [116.47374708000005, 11.664815440000085],
              [116.47227249600009, 11.668751335000048],
              [116.47159881400003, 11.676208530000041],
              [116.47254678300013, 11.683277467000062],
              [116.47307425000008, 11.688102026000101],
              [116.47127245900003, 11.692051179000094],
              [116.46974419700005, 11.694697120000084],
              [116.46662078300008, 11.69837638900013],
              [116.46345720100007, 11.701088061000052],
              [116.460607235, 11.703464020000085],
              [116.45873835799999, 11.705800528000053],
              [116.45570793900008, 11.711737359000017],
              [116.45816001100002, 11.722910190000045],
              [116.46172837700011, 11.730556327000039],
              [116.46606747500005, 11.740397104000076],
              [116.46828392900011, 11.746446382000018],
              [116.47082764700008, 11.752482531000028],
              [116.47441619600008, 11.756053549000088],
              [116.48038477700001, 11.757751725000086],
              [116.48595940200002, 11.757850220000064],
              [116.49006474799998, 11.754130831000074],
              [116.49135204800008, 11.745678868000107],
              [116.49088319700002, 11.734390150000083],
              [116.488612643, 11.727051084],
              [116.48695621800007, 11.718718090000023],
              [116.48790298200008, 11.709957182000073],
              [116.49056522200009, 11.703065537000066],
              [116.49262287500008, 11.693452316000029],
              [116.49137393400012, 11.687041591000112],
              [116.49068534099999, 11.678346968000051],
              [116.48883610600001, 11.673252753000083],
              [116.48563888200006, 11.667243781000101],
              [116.48155453199999, 11.663531852000107],
            ],
          ],
          [
            [
              [109.21995766500007, 11.773424802000052],
              [109.21887560300007, 11.773136322000076],
              [109.21773598000001, 11.773260812000073],
              [109.21474703600006, 11.775282289000087],
              [109.2120036850001, 11.778245499000127],
              [109.21016962600004, 11.782736114000068],
              [109.20958267600005, 11.78497953500006],
              [109.20976970500008, 11.786157440000087],
              [109.21091638600009, 11.787211931000028],
              [109.21133967000004, 11.787740032000025],
              [109.21362188300004, 11.787962696000063],
              [109.21625826100004, 11.787181163000058],
              [109.219191564, 11.785867337000065],
              [109.22008625200004, 11.784918975000023],
              [109.220496892, 11.783324980000119],
              [109.22066358800004, 11.78108397700006],
              [109.2208790350001, 11.776956349000043],
              [109.22068349700008, 11.774363759000027],
              [109.21995766500007, 11.773424802000052],
            ],
          ],
          [
            [
              [109.23464527900003, 11.841235752000044],
              [109.23682249600006, 11.839265703000111],
              [109.23772121600007, 11.839374209000118],
              [109.23894341500004, 11.840277310000056],
              [109.24057637500005, 11.84203138100006],
              [109.24133717200003, 11.843278546000045],
              [109.24145719300002, 11.843960550000075],
              [109.24105852100007, 11.845100762000092],
              [109.24002562300005, 11.846756736000062],
              [109.23974696600008, 11.848578958000109],
              [109.23981076900003, 11.849545767000079],
              [109.24057228000007, 11.850906727000064],
              [109.24185457400003, 11.852150834000062],
              [109.24329134200008, 11.852904989000111],
              [109.2451626540001, 11.85259797600011],
              [109.24657184100008, 11.851895669000088],
              [109.24750774400006, 11.850849153000096],
              [109.24767128000009, 11.849010356000099],
              [109.24708913300006, 11.847565551000104],
              [109.24671837300012, 11.845837941000068],
              [109.24669755200009, 11.84469544700004],
              [109.2472751710001, 11.843129694000075],
              [109.24819489500007, 11.841929495000068],
              [109.24974706000006, 11.839929053000018],
              [109.25014288100004, 11.838333707000091],
              [109.25007485900001, 11.836684233000126],
              [109.24966409100004, 11.835833281000061],
              [109.24861498300001, 11.834815439000044],
              [109.24582884600005, 11.833978542000077],
              [109.24328173599999, 11.834334932000077],
              [109.24113969000004, 11.835765386000039],
              [109.23960073500007, 11.835402062000087],
              [109.23849443200001, 11.834498289000093],
              [109.23830700200004, 11.833475449000018],
              [109.23880274400003, 11.831402918000043],
              [109.2382744330001, 11.830268177000034],
              [109.23641536500003, 11.829425721000051],
              [109.23276616500007, 11.829560881000043],
              [109.23109050800008, 11.830310267000053],
              [109.22232078400002, 11.836448788],
              [109.22209583900005, 11.837587937000114],
              [109.22221039100003, 11.838736635000123],
              [109.22206244400004, 11.842340545000061],
              [109.22145545400009, 11.844669295000097],
              [109.22072631000007, 11.845959126000059],
              [109.21969256400007, 11.847501222000021],
              [109.2191185990001, 11.84841483400003],
              [109.21901390300005, 11.850292917000081],
              [109.21896376300008, 11.851601756000017],
              [109.21960960000004, 11.853020355000064],
              [109.22094360700002, 11.853240210000047],
              [109.22258779700007, 11.852035911000057],
              [109.22477762900007, 11.850031922000069],
              [109.22690640700009, 11.847516242000021],
              [109.2292284060001, 11.845404270000069],
              [109.23193436200009, 11.843090478000116],
              [109.23464527900003, 11.841235752000044],
            ],
          ],
          [
            [
              [109.32052228600001, 12.003547290000059],
              [109.32017490800004, 12.003522816000077],
              [109.31991401400003, 12.003539139000095],
              [109.31962884400008, 12.003593116000031],
              [109.31929878800003, 12.003702556000027],
              [109.31894121100002, 12.003891911000062],
              [109.31860052000007, 12.004157969000049],
              [109.31836787900001, 12.004413103000021],
              [109.31816662100006, 12.004727112000067],
              [109.31802477900004, 12.005040216000038],
              [109.3179429030001, 12.005344076000044],
              [109.31790031600005, 12.005820317000103],
              [109.31793522300009, 12.006189023000056],
              [109.31800397000009, 12.006466774000033],
              [109.3180974090001, 12.006707131000026],
              [109.31826450799998, 12.007007942000046],
              [109.31847355000005, 12.007282163000086],
              [109.31874735100006, 12.007537513000075],
              [109.31908289700002, 12.007762592000047],
              [109.31942743500011, 12.007913432000093],
              [109.31993548700004, 12.008029002000063],
              [109.32028338000005, 12.008045653000108],
              [109.32054227900007, 12.008021244000043],
              [109.32079922200009, 12.007966203000107],
              [109.32104729400001, 12.007884988000049],
              [109.32130909300001, 12.007761279000116],
              [109.32162231700008, 12.007554424000054],
              [109.32187887200007, 12.007322561000054],
              [109.322078762, 12.007077384000107],
              [109.32224333100004, 12.006807962000019],
              [109.32234871300005, 12.006572437000072],
              [109.32243695300006, 12.006270311000032],
              [109.32248831600006, 12.005902469000061],
              [109.322483468, 12.005588127000076],
              [109.32243305300001, 12.005277690000058],
              [109.322340674, 12.004976751000116],
              [109.32222004400006, 12.004716704000094],
              [109.32203159800011, 12.004428381000041],
              [109.32178189700005, 12.004150103000109],
              [109.32156052300012, 12.003965082000077],
              [109.32134148300003, 12.003824605000089],
              [109.32108118900007, 12.003697853000082],
              [109.32083484700011, 12.00361171100006],
              [109.32052228600001, 12.003547290000059],
            ],
          ],
          [
            [
              [109.32512578400005, 12.035283390000105],
              [109.32462380700008, 12.035221003000094],
              [109.32386010700004, 12.03523620900002],
              [109.3234377740001, 12.035293039000104],
              [109.32317580900009, 12.03541022100003],
              [109.32287293100008, 12.035645877000077],
              [109.32250878600006, 12.036039504000049],
              [109.32157709800006, 12.037221330000046],
              [109.32024106100002, 12.038796515000081],
              [109.319694575, 12.039426530000073],
              [109.31953198100005, 12.039722409000129],
              [109.31949018500006, 12.039979431000079],
              [109.31948796900005, 12.040335647000065],
              [109.31952447200005, 12.040929580000091],
              [109.31995965800009, 12.042040458000079],
              [109.32078662400004, 12.043163595000104],
              [109.32192463700005, 12.044357880000065],
              [109.3240882640001, 12.047052492000086],
              [109.32448806800005, 12.047391341000056],
              [109.32482943800005, 12.047432967000036],
              [109.32545237700008, 12.04743671100008],
              [109.32593477500002, 12.047419819000103],
              [109.3261569200001, 12.047243040000042],
              [109.32617873200005, 12.046966103000043],
              [109.32610006900002, 12.046688562000083],
              [109.3244039150001, 12.042978759000087],
              [109.32396324700005, 12.041621999000094],
              [109.32408779300006, 12.039027448000093],
              [109.32436129100003, 12.038150596000017],
              [109.325522323, 12.036146647000033],
              [109.32560496200009, 12.035781023000068],
              [109.32546633500007, 12.03545364700007],
              [109.32512578400005, 12.035283390000105],
            ],
          ],
          [
            [
              [116.16790116900006, 12.029299389000057],
              [116.16118339800005, 12.028852084000084],
              [116.15284967300009, 12.029882104000043],
              [116.14834788900012, 12.03099953100004],
              [116.14485807200002, 12.033492796000099],
              [116.142600331, 12.036881770000017],
              [116.14272237900006, 12.039942973000082],
              [116.14401091100001, 12.042251608000052],
              [116.14841496000007, 12.044675664000069],
              [116.15567692300007, 12.046753243000106],
              [116.16121001000012, 12.047482470000135],
              [116.16810140700011, 12.046272180000093],
              [116.17350258500007, 12.043704381000085],
              [116.17627544900007, 12.041238417000065],
              [116.176888512, 12.038620385000074],
              [116.17576314699997, 12.034418893000074],
              [116.17324204200008, 12.031214912000097],
              [116.16790116900006, 12.029299389000057],
            ],
          ],
          [
            [
              [109.31532128300009, 12.164435610000089],
              [109.31445896800005, 12.163765257000046],
              [109.312309188, 12.163018440000071],
              [109.31025010100007, 12.163031237000064],
              [109.30682422200003, 12.163981608000094],
              [109.304938867, 12.16433113900006],
              [109.30099170300008, 12.164271050000062],
              [109.29936319300008, 12.164534484000098],
              [109.29756575900004, 12.16522127200008],
              [109.29594366900002, 12.166498232000043],
              [109.29501275300001, 12.168531132000025],
              [109.29476660800007, 12.17030644100001],
              [109.29511568700009, 12.171233421000059],
              [109.2955484390001, 12.171822032000088],
              [109.2968397130001, 12.172489832000098],
              [109.30027492400011, 12.172975495000047],
              [109.30242045500009, 12.173046728000079],
              [109.30327738000003, 12.172872508000053],
              [109.30721064600003, 12.170736509000067],
              [109.31482009700001, 12.166550379000094],
              [109.31550158099999, 12.165785940000083],
              [109.315497228, 12.165110240000041],
              [109.31532128300009, 12.164435610000089],
            ],
          ],
          [
            [
              [109.24948827700007, 12.170306749000035],
              [109.24859723300005, 12.170177192000063],
              [109.24723096900007, 12.170724587000057],
              [109.24299681200003, 12.172637179000033],
              [109.23534675800008, 12.175918012000063],
              [109.23316434900006, 12.177413908000021],
              [109.23268987700007, 12.178225663000129],
              [109.23262464400008, 12.178765363000108],
              [109.23297071800003, 12.179370076000049],
              [109.23358989500011, 12.179838370000072],
              [109.23455108000005, 12.180237236000034],
              [109.23794356000008, 12.180655557000096],
              [109.24150665100011, 12.180971704000052],
              [109.24294435000004, 12.18089582500008],
              [109.244447661, 12.180347644000021],
              [109.246424985, 12.178920254000046],
              [109.24744436700006, 12.177633344000059],
              [109.24828780800003, 12.175572199000049],
              [109.24990574100005, 12.171382905000081],
              [109.24976466400001, 12.17070959500006],
              [109.24948827700007, 12.170306749000035],
            ],
          ],
          [
            [
              [109.27377330900001, 12.174826470000076],
              [109.27282792200003, 12.174578773000098],
              [109.27231312500007, 12.174581878000089],
              [109.26940272500005, 12.175697480000023],
              [109.26854734200006, 12.176124956000043],
              [109.26846573300006, 12.176801181000128],
              [109.26865045300006, 12.178911750000053],
              [109.26918311700008, 12.181780435000087],
              [109.26970266800008, 12.182537514000023],
              [109.27099129600003, 12.18278316],
              [109.27305059400007, 12.182770747000047],
              [109.2745076830001, 12.182508547000024],
              [109.27484826400004, 12.182084155000082],
              [109.27501670000002, 12.181576329000075],
              [109.27526409500007, 12.179969954000034],
              [109.27550567400007, 12.177434479000036],
              [109.27489662700003, 12.176086690000075],
              [109.27377330900001, 12.174826470000076],
            ],
          ],
          [
            [
              [109.22304605500007, 12.1865263180001],
              [109.22164252600002, 12.186275674000056],
              [109.22059207300006, 12.186454269000089],
              [109.21980706600004, 12.187062605000063],
              [109.21719531900004, 12.189924181000043],
              [109.21684995800007, 12.19078875700008],
              [109.21694272300002, 12.191650797000085],
              [109.21749270600004, 12.192815389000076],
              [109.21927608600006, 12.195811120000053],
              [109.2200482170001, 12.197262703000076],
              [109.22086667600004, 12.198479178000097],
              [109.22141723000004, 12.198781299000048],
              [109.22241926200007, 12.198775497000012],
              [109.22337188500003, 12.198488156000041],
              [109.22656008100003, 12.197013603000071],
              [109.22865305200001, 12.19592113],
              [109.22945939800012, 12.195117957000077],
              [109.22978915400004, 12.194411497000067],
              [109.22925581200006, 12.193005536000088],
              [109.2284222170001, 12.191670543000031],
              [109.22480524600006, 12.18763745200002],
              [109.22304605500007, 12.1865263180001],
            ],
          ],
          [
            [
              [109.277702139, 12.228602606000026],
              [109.280322481, 12.228526740000055],
              [109.28117686600008, 12.228701514000038],
              [109.28230683800005, 12.229084565000024],
              [109.28371352300009, 12.229855850000069],
              [109.28487680600006, 12.230688595000048],
              [109.2859163140001, 12.231222148000052],
              [109.28744227600006, 12.231572771000019],
              [109.29049194000005, 12.231914073000015],
              [109.29262511600007, 12.231901019000089],
              [109.293843694, 12.231833566000104],
              [109.29542605500009, 12.231463933000073],
              [109.29609343400007, 12.230979937000084],
              [109.29681756700003, 12.229835726000092],
              [109.29711163300003, 12.228154274000056],
              [109.29818197900005, 12.223948602000066],
              [109.29968576300003, 12.220820032000022],
              [109.30170427600007, 12.217178398000071],
              [109.30224588100003, 12.216095304000072],
              [109.30296992700008, 12.21495109000008],
              [109.30369741100004, 12.21434673700008],
              [109.3052202100001, 12.214217354000121],
              [109.30711096600004, 12.214445592000088],
              [109.30839268300005, 12.214737585000089],
              [109.30955405700004, 12.21527025700002],
              [109.31028960600005, 12.21592554700006],
              [109.31072352300008, 12.217062590000081],
              [109.31091867600006, 12.21898095500009],
              [109.31186058200009, 12.223294143000095],
              [109.31285730400006, 12.226647223000063],
              [109.31304515800005, 12.227425880000096],
              [109.31347525600003, 12.227963096000048],
              [109.31384325200001, 12.228320721000062],
              [109.31451598300006, 12.228676458000097],
              [109.31579586800009, 12.228668482000058],
              [109.31695037700005, 12.228121398],
              [109.32022987300006, 12.22630129400004],
              [109.32302368900012, 12.224784125],
              [109.32551467500008, 12.223568753000079],
              [109.32691174000003, 12.22284012700001],
              [109.32763879100006, 12.222175703000103],
              [109.32830294200004, 12.221211736000013],
              [109.32884480700001, 12.220188543000086],
              [109.32911144800005, 12.219017125000063],
              [109.32915989700007, 12.217097251000018],
              [109.32902434600004, 12.214998575000012],
              [109.32864267800008, 12.212541534000108],
              [109.32758950500006, 12.209908779000054],
              [109.32696956800002, 12.208293052000039],
              [109.32662406200004, 12.206705598000054],
              [109.32661899600006, 12.205925811000016],
              [109.32685496200004, 12.204724619000016],
              [109.32751711900002, 12.203460753000071],
              [109.32866755100009, 12.202313782000116],
              [109.33048438700004, 12.200562754000098],
              [109.33241954600003, 12.198271100000023],
              [109.33344687100004, 12.196944934000074],
              [109.33371737200011, 12.196373363000122],
              [109.33359118900006, 12.195714327000038],
              [109.33310094300001, 12.195297528000102],
              [109.33114236100005, 12.193990219000026],
              [109.3295521540001, 12.193100469000038],
              [109.32759205500001, 12.191553196000092],
              [109.32513788799999, 12.188989282000049],
              [109.32366300600009, 12.18707902300009],
              [109.32292674200009, 12.186303847000078],
              [109.32188930700005, 12.186070406000036],
              [109.32085265000008, 12.185956931000062],
              [109.31921014100007, 12.186387098000038],
              [109.31483175600009, 12.1877940690001],
              [109.311220717, 12.189278848000116],
              [109.30170838500005, 12.193511152],
              [109.29563462700004, 12.196809008000018],
              [109.29312474800003, 12.197998188],
              [109.29140401500003, 12.198269558000073],
              [109.28954996600001, 12.198411322000078],
              [109.28861841000003, 12.197764911000062],
              [109.28741202000003, 12.195555141000101],
              [109.28699893700005, 12.193079692000095],
              [109.28685740400007, 12.191645946000039],
              [109.287378313, 12.190208161],
              [109.28896073000001, 12.189024735000052],
              [109.29103656000009, 12.187910994000093],
              [109.29231125600003, 12.18712339000003],
              [109.29279455500006, 12.18646060400008],
              [109.293032216, 12.185499398000042],
              [109.29296748500008, 12.184899945],
              [109.29229379600011, 12.184364214000086],
              [109.28960741000006, 12.183540867000085],
              [109.28496626800009, 12.181949610000052],
              [109.28301490400003, 12.181721543000011],
              [109.28191924100005, 12.181908160000086],
              [109.279305886, 12.183003732000051],
              [109.27535945400005, 12.185307024000023],
              [109.27426825500001, 12.186213382000053],
              [109.27305890800004, 12.187720291000035],
              [109.27084239600006, 12.193852098000056],
              [109.27060424700007, 12.194753306000015],
              [109.270001221, 12.195776679000046],
              [109.26891143300007, 12.196922956000032],
              [109.26815325800004, 12.197497374000042],
              [109.26705934800003, 12.197983826000057],
              [109.26535531900001, 12.19835396300004],
              [109.26273573000006, 12.198489622000073],
              [109.25999722200005, 12.199105849000079],
              [109.25817314300011, 12.199776566000063],
              [109.25641146600002, 12.200686842000062],
              [109.25513802200007, 12.201714169000065],
              [109.25265571500003, 12.204368281000109],
              [109.24992444100009, 12.206184044000066],
              [109.24688950300006, 12.208181533000049],
              [109.24364103400005, 12.210150243000049],
              [109.24157814100008, 12.21166203600008],
              [109.24042789600003, 12.212928509000063],
              [109.23939988600006, 12.214254245000065],
              [109.23891743900002, 12.215096886000063],
              [109.23855651100008, 12.215878828000102],
              [109.23831746700003, 12.216660047000081],
              [109.23851084800003, 12.218398519000038],
              [109.23913812400006, 12.221334182000108],
              [109.23924283300002, 12.223523079000119],
              [109.23906581200004, 12.224483915000038],
              [109.23864355800001, 12.225206231000117],
              [109.23809831700001, 12.22574931000011],
              [109.23682208200002, 12.226356669],
              [109.23499405400003, 12.226427359000079],
              [109.23158069600004, 12.226387320000052],
              [109.22987564400009, 12.226637205000122],
              [109.22920739900005, 12.227001022000115],
              [109.22872452000007, 12.227783668000123],
              [109.22854673200001, 12.228624523000063],
              [109.22854998000001, 12.229164386000056],
              [109.22873643200008, 12.229763169000039],
              [109.22959908600004, 12.231317817000063],
              [109.23070301700008, 12.232451137000043],
              [109.23186718400004, 12.233464138000015],
              [109.23336350600002, 12.233965295000091],
              [109.23488830900007, 12.234136338000033],
              [109.23635071400001, 12.234067790000045],
              [109.24308935200006, 12.229649132000107],
              [109.24612207300007, 12.227231766000022],
              [109.24781941300009, 12.225722057000075],
              [109.25103154900003, 12.222763690000081],
              [109.25196756200003, 12.221348449000045],
              [109.25268749200009, 12.219484593000105],
              [109.25328404900006, 12.217381521000091],
              [109.2537021840001, 12.215999352000038],
              [109.25412252300008, 12.21497709000001],
              [109.25484867700003, 12.214132965000067],
              [109.25551609600009, 12.213649106000089],
              [109.25630724200001, 12.213464443000086],
              [109.25691666600007, 12.213460815000053],
              [109.257405314, 12.213637859000047],
              [109.25783449800008, 12.214055205000117],
              [109.25944379300009, 12.218064693000128],
              [109.260824187, 12.224475023000101],
              [109.26121061, 12.227831990000125],
              [109.26191772400006, 12.233766504000075],
              [109.26192588000002, 12.235086181000019],
              [109.26214717800003, 12.236374588000041],
              [109.26257753200011, 12.23697189700008],
              [109.26300640500007, 12.237329259000072],
              [109.26373965600008, 12.2376248020001],
              [109.2644097310001, 12.237560802000045],
              [109.26507646000002, 12.236956933000085],
              [109.26786262800007, 12.234120791000079],
              [109.27113518000003, 12.231101742000037],
              [109.27216680600003, 12.230375675000092],
              [109.27319879800007, 12.229709596000047],
              [109.27484062900002, 12.229099807000042],
              [109.277702139, 12.228602606000026],
            ],
          ],
          [
            [
              [109.35977891900005, 12.268165549000097],
              [109.35885970400003, 12.268160041000085],
              [109.35809336600003, 12.268205630000038],
              [109.35706888300008, 12.268701318000106],
              [109.35583608600004, 12.269848129000048],
              [109.35526685100011, 12.271049106000119],
              [109.35495546200011, 12.271850166000052],
              [109.35495265800004, 12.272301780000115],
              [109.35510336599999, 12.272704155000012],
              [109.35636951700005, 12.274417956000059],
              [109.35677620500012, 12.274721499000039],
              [109.35779635900006, 12.274928338000011],
              [109.35846025900001, 12.274932324000075],
              [109.35932906700005, 12.274837169000106],
              [109.3601477290001, 12.27459115200009],
              [109.36060922100009, 12.274292814000097],
              [109.36102026400009, 12.273893811000034],
              [109.36132916300006, 12.273494190000099],
              [109.36148485100007, 12.273093664000088],
              [109.36149384700011, 12.271638415000028],
              [109.36145084600001, 12.270333408000054],
              [109.361200776, 12.269478807000043],
              [109.36079626200002, 12.268824011000044],
              [109.36038988000007, 12.268470303000088],
              [109.35977891900005, 12.268165549000097],
            ],
          ],
          [
            [
              [109.36845734400001, 12.280899272000044],
              [109.36782754000004, 12.280839150000043],
              [109.36700320200005, 12.28180056300001],
              [109.36654132200002, 12.282377597000059],
              [109.36624293600002, 12.282987827000101],
              [109.36610583100006, 12.283985547000052],
              [109.36608913000001, 12.286691152000094],
              [109.36621828500003, 12.286981817000074],
              [109.36647878200009, 12.287208853000029],
              [109.36687081200002, 12.287340040000048],
              [109.36723111800008, 12.287309971000047],
              [109.36769121100004, 12.287022824000092],
              [109.36831854700007, 12.286156870000029],
              [109.36879015200002, 12.284001554000044],
              [109.36892803700006, 12.282875001],
              [109.36870781800005, 12.281424193000044],
              [109.36845734400001, 12.280899272000044],
            ],
          ],
          [
            [
              [109.24190223300006, 12.288247458000077],
              [109.24155472600009, 12.288219611000082],
              [109.24117769900008, 12.288242573000028],
              [109.24092171900003, 12.288296500000049],
              [109.24067327500008, 12.288377644000102],
              [109.24041106700005, 12.28850129100003],
              [109.24009733000003, 12.288708089000087],
              [109.23984032800008, 12.288939924000085],
              [109.23964006300012, 12.28918509800012],
              [109.23947515800006, 12.28945453900012],
              [109.23936953400003, 12.28969009500007],
              [109.23928104800008, 12.289992280000115],
              [109.23922946400008, 12.290360215000094],
              [109.23923419000002, 12.290674656000087],
              [109.23928454900002, 12.290985211000114],
              [109.23937693500002, 12.291286280000067],
              [109.23949762900006, 12.2915464550001],
              [109.23968622400002, 12.291834943000078],
              [109.2399361650001, 12.292113405000025],
              [109.24015777700006, 12.292298570000069],
              [109.24037707100008, 12.292439176000068],
              [109.24063768200006, 12.292566067000086],
              [109.24096913000004, 12.292672778000034],
              [109.24134173100005, 12.292733633000095],
              [109.24160341600003, 12.292741754000057],
              [109.24197751100007, 12.292696031000093],
              [109.24242260800004, 12.292562028000107],
              [109.24278076200001, 12.292372743000078],
              [109.24312203300009, 12.292106724000094],
              [109.2433550960001, 12.291851592000034],
              [109.24355675200009, 12.291537557000074],
              [109.24369890700004, 12.29122440500001],
              [109.24378100800008, 12.290920479000087],
              [109.24382382800007, 12.290444105000107],
              [109.24378900600004, 12.290075272000054],
              [109.24372026500004, 12.289797411000068],
              [109.24362678400011, 12.289556947000067],
              [109.24345956400005, 12.289255984000123],
              [109.24325033400005, 12.288981604000069],
              [109.24297624900002, 12.288726078000066],
              [109.24264032600003, 12.288500809000078],
              [109.24229537100008, 12.288349797000107],
              [109.24190223300006, 12.288247458000077],
            ],
          ],
          [
            [
              [109.21463226400007, 12.359547298000082],
              [109.21363987300002, 12.358980693000097],
              [109.21288358600006, 12.358985038000101],
              [109.21201264600003, 12.359276199000123],
              [109.21009962800011, 12.360431786000079],
              [109.2090572060001, 12.361238989000077],
              [109.20882653100003, 12.361583694000087],
              [109.20885782300005, 12.361955515000121],
              [109.20941304300005, 12.362381568000023],
              [109.21116140000004, 12.362886620000067],
              [109.21343336700008, 12.363388658000039],
              [109.21378277500003, 12.363443882000078],
              [109.21465440300003, 12.363267173000024],
              [109.21552399400004, 12.362747097000069],
              [109.21581146400011, 12.362173128000096],
              [109.21592440900004, 12.36160017400003],
              [109.21586248100007, 12.360970985000018],
              [109.21539298300004, 12.360286921000084],
              [109.21463226400007, 12.359547298000082],
            ],
          ],
          [
            [
              [109.37792195500001, 12.357925386000089],
              [109.37757440800003, 12.357923316],
              [109.37677939000007, 12.358016252000057],
              [109.37254881100006, 12.359651587000096],
              [109.37184412100001, 12.360365112000069],
              [109.370339954, 12.363545625000059],
              [109.369980442, 12.363923863000091],
              [109.36958881600002, 12.364566775000084],
              [109.36713889200009, 12.367287160000016],
              [109.36609104700007, 12.368111157000071],
              [109.36598900800007, 12.368550111000063],
              [109.36598718300009, 12.368843142000062],
              [109.36613370200004, 12.369234747000045],
              [109.36677676800005, 12.369629332],
              [109.36895421600009, 12.37081457600002],
              [109.3698958140001, 12.371113262000042],
              [109.37039233700007, 12.371116244000023],
              [109.37064272400009, 12.370775857000103],
              [109.37589673100004, 12.364262607000052],
              [109.37824783800006, 12.361443866000064],
              [109.37869981700007, 12.360616270000067],
              [109.37880514100007, 12.359640078000032],
              [109.37856411000003, 12.358466459000093],
              [109.37831766100007, 12.358171945000061],
              [109.37792195500001, 12.357925386000089],
            ],
          ],
          [
            [
              [109.23508766500004, 12.382902589000098],
              [109.23346140200005, 12.38211462],
              [109.23256961300007, 12.38211982600008],
              [109.23143701400005, 12.382525166000045],
              [109.22658515300006, 12.384626850000034],
              [109.22157200300006, 12.386888853000054],
              [109.22028198000008, 12.388092532000082],
              [109.2200440010001, 12.388971133000036],
              [109.22053808100004, 12.390244249000125],
              [109.22496400700004, 12.398193493000063],
              [109.22586161500007, 12.399145276000032],
              [109.22699863300008, 12.399457665000034],
              [109.22797254000002, 12.399611500000061],
              [109.22991602300006, 12.399201452000062],
              [109.23250139000001, 12.397671144000098],
              [109.23403656500003, 12.396784946000048],
              [109.235650875, 12.395579275000033],
              [109.23718070200002, 12.393815846000054],
              [109.23821824900007, 12.391098311000091],
              [109.23837313800001, 12.389901178000072],
              [109.2383678140001, 12.389023973000056],
              [109.23754063000005, 12.386317390000022],
              [109.23655518200005, 12.384249711000093],
              [109.23508766500004, 12.382902589000098],
            ],
          ],
          [
            [
              [109.2217754120001, 12.399243856000018],
              [109.22142771800002, 12.399215973000109],
              [109.22105048400007, 12.399238901000052],
              [109.22079435900011, 12.399292810000071],
              [109.22054577200005, 12.399373940000123],
              [109.22028340900002, 12.39949757600005],
              [109.21996948100006, 12.399704368000107],
              [109.21971231600001, 12.399936204000104],
              [109.21951191800005, 12.400181388000052],
              [109.21934689600008, 12.400450844000053],
              [109.21924119200003, 12.400686417000093],
              [109.21915262800007, 12.400988628000052],
              [109.21910097900006, 12.401356600000033],
              [109.21910567800009, 12.40167107800003],
              [109.21915603300009, 12.40198167300006],
              [109.21924844000009, 12.402282785000105],
              [109.21936917400005, 12.402543002000053],
              [109.21955784300012, 12.402831540000124],
              [109.21980789200002, 12.403110058000076],
              [109.22002960600007, 12.403295265000034],
              [109.2202490060001, 12.403435908000036],
              [109.22050974700009, 12.403562839000058],
              [109.22084136400008, 12.403669593000098],
              [109.22121416100002, 12.403730491000074],
              [109.22147598800011, 12.403738638000037],
              [109.22185029200008, 12.403692945000076],
              [109.22229564300007, 12.403558969000093],
              [109.22265401100006, 12.403369697000064],
              [109.22299549400006, 12.403103680000081],
              [109.22322870900008, 12.402848541000109],
              [109.22343050500008, 12.402534489000059],
              [109.22357276900004, 12.402221316000082],
              [109.22365494500009, 12.401917362000068],
              [109.22369783400009, 12.401440938000084],
              [109.22366303000005, 12.401072059000027],
              [109.22359427900005, 12.400794160000126],
              [109.22350077100003, 12.400553660000034],
              [109.22333349000004, 12.400252647000086],
              [109.22312417300006, 12.399978215000116],
              [109.22284996400001, 12.399722634000019],
              [109.22251388000009, 12.399497307000027],
              [109.22216875300005, 12.399346244000052],
              [109.2217754120001, 12.399243856000018],
            ],
          ],
          [
            [
              [109.21698033300001, 12.401044126000025],
              [109.21663263700002, 12.401016234000027],
              [109.21625539900008, 12.401039155000058],
              [109.21599927100002, 12.401093057000077],
              [109.21575068000006, 12.401174182000128],
              [109.21548831100002, 12.401297812000054],
              [109.21517437500006, 12.401504597000022],
              [109.21491720300001, 12.401736428000019],
              [109.21471679800004, 12.401981608000055],
              [109.21455176800006, 12.402251060000056],
              [109.21444605700003, 12.402486632000096],
              [109.21435748600007, 12.402788841000055],
              [109.21430582800005, 12.403156813000036],
              [109.21431051800009, 12.403471291000033],
              [109.21436086700008, 12.403781888000063],
              [109.2144532670001, 12.40408300300002],
              [109.21457399600004, 12.404343223000057],
              [109.2147626600001, 12.404631766000039],
              [109.21501270500002, 12.40491029000008],
              [109.21523441700006, 12.405095503000039],
              [109.21545381600001, 12.405236151000041],
              [109.21571455500001, 12.405363088000064],
              [109.21604617300008, 12.405469850000104],
              [109.21641897200001, 12.405530756000081],
              [109.21668080200001, 12.405538909000045],
              [109.21705510900007, 12.405493225000084],
              [109.21750046800005, 12.405359258000102],
              [109.21785884300004, 12.405169994000074],
              [109.21820033500006, 12.404903984],
              [109.21843355800009, 12.404648851000031],
              [109.21863536400008, 12.40433480300007],
              [109.21877763700004, 12.404021631000093],
              [109.21885982000001, 12.40371767900008],
              [109.21890272100009, 12.403241255000095],
              [109.21886792600006, 12.402872375000038],
              [109.21879918000005, 12.402594474000049],
              [109.21870567700003, 12.402353971000045],
              [109.21853840100005, 12.402052953000096],
              [109.21832908900005, 12.401778516000126],
              [109.21805488400001, 12.401522929000029],
              [109.21771880200001, 12.401297594000125],
              [109.21737367500005, 12.40114652300006],
              [109.21698033300001, 12.401044126000025],
            ],
          ],
          [
            [
              [109.3756462220001, 12.476413546000041],
              [109.37532885900004, 12.476372702000036],
              [109.37497979100003, 12.476374317000081],
              [109.37463545300005, 12.476430632000033],
              [109.37422360100007, 12.476570874000091],
              [109.37386855300009, 12.47676633900001],
              [109.37361783700004, 12.476961881000113],
              [109.373434789, 12.47714612000008],
              [109.37324402400003, 12.477398679000105],
              [109.37307557600003, 12.477699121000095],
              [109.37296528500005, 12.477994394000101],
              [109.37288779800004, 12.478386994000077],
              [109.37287617900006, 12.478756503000097],
              [109.37291489400006, 12.479062651000026],
              [109.37300901000003, 12.47939297200009],
              [109.37316481600004, 12.479731442000029],
              [109.3733575860001, 12.480017427000066],
              [109.37363608700005, 12.480309039000035],
              [109.37393545300003, 12.480535882000064],
              [109.37419017800006, 12.480674290000058],
              [109.37446064000005, 12.480780017000102],
              [109.37488743700003, 12.480871854000062],
              [109.37526425800012, 12.480891349000077],
              [109.37563850900008, 12.480843244000093],
              [109.37600258100009, 12.480740365000107],
              [109.37629227300009, 12.480606572000051],
              [109.37653797200008, 12.480453224000064],
              [109.37682136300008, 12.480207260000052],
              [109.37706353900003, 12.479922133000086],
              [109.37725277900006, 12.479600536000122],
              [109.37739447200006, 12.4792256350001],
              [109.37746825100004, 12.478803496000078],
              [109.377468784, 12.478432024000073],
              [109.37740973200009, 12.478094038000085],
              [109.37724216000008, 12.477637697000075],
              [109.37703351800005, 12.477294336000087],
              [109.37678556300004, 12.477013916000066],
              [109.37654326200007, 12.476808404000026],
              [109.37632367600003, 12.476667911000037],
              [109.376007852, 12.476522019000045],
              [109.3756462220001, 12.476413546000041],
            ],
          ],
          [
            [
              [109.35440117400007, 12.479170340000055],
              [109.35166172700008, 12.478590152000011],
              [109.35085122200007, 12.478595336000085],
              [109.34903877200003, 12.480300502000128],
              [109.34646603900006, 12.483078587000039],
              [109.34537089300004, 12.484371695000094],
              [109.34488542900002, 12.485138427000029],
              [109.34448330200004, 12.486145786000067],
              [109.34420352600009, 12.48711216200009],
              [109.34404691500001, 12.488158155000038],
              [109.34393064000007, 12.489123493000063],
              [109.34405671300001, 12.489645185000063],
              [109.344387161, 12.490165577000043],
              [109.34479804000007, 12.490484501000052],
              [109.34557548800009, 12.490600126000027],
              [109.34733051500008, 12.490187021000041],
              [109.35193887100009, 12.488549904000122],
              [109.35316194900003, 12.488059775000069],
              [109.35458540000003, 12.48696547100012],
              [109.35645685900008, 12.485627134000053],
              [109.35792140900001, 12.48457274100012],
              [109.35857059300008, 12.483845112000045],
              [109.35876961700004, 12.483040007000044],
              [109.35892507700001, 12.481833257000028],
              [109.35892025800005, 12.481109835000035],
              [109.35883556300007, 12.480668269000093],
              [109.35850643100011, 12.480348859000044],
              [109.35785083700003, 12.480111926000069],
              [109.35625380700006, 12.479680074000042],
              [109.35440117400007, 12.479170340000055],
            ],
          ],
          [
            [
              [109.2940800110001, 12.486642229000021],
              [109.29102967800006, 12.486535951000109],
              [109.29001474500009, 12.48679203100007],
              [109.28957397700005, 12.48741941200011],
              [109.28951321000008, 12.487857068000084],
              [109.28977405000008, 12.488917452000068],
              [109.29048753000005, 12.491224466000082],
              [109.29139163400006, 12.49353031],
              [109.29235256500009, 12.494773832000037],
              [109.29362721500003, 12.495390726],
              [109.29706365900006, 12.496244234000107],
              [109.30304552700002, 12.497706701000054],
              [109.30686325800006, 12.498557700000033],
              [109.31118770200003, 12.499155604000032],
              [109.31214074300001, 12.499149687000076],
              [109.31277409000009, 12.498833404000019],
              [109.31308815400006, 12.498269221000056],
              [109.313084132, 12.497644548000062],
              [109.31219101600009, 12.497087866000099],
              [109.31059942300001, 12.496597990000067],
              [109.30900663500003, 12.495920693000071],
              [109.306901177, 12.494559396000058],
              [109.30472901700004, 12.492698729000027],
              [109.30306476600001, 12.490772446000074],
              [109.30101416700008, 12.488036436000092],
              [109.30050311900007, 12.487602297000054],
              [109.29973755000006, 12.487107258000039],
              [109.29801978900012, 12.486742999000098],
              [109.2940800110001, 12.486642229000021],
            ],
          ],
          [
            [
              [109.39139591200009, 12.547083037000027],
              [109.39104759900006, 12.547058666000053],
              [109.39078601500009, 12.547075085000079],
              [109.39050010400005, 12.547129189000115],
              [109.39016920700003, 12.547238803000125],
              [109.38981074200004, 12.547428386000091],
              [109.38946923500002, 12.5476947110001],
              [109.38923606200009, 12.547950069000089],
              [109.38903437700002, 12.548264325000067],
              [109.38889226600007, 12.548577656000058],
              [109.38881027700003, 12.54888171700008],
              [109.38876774300007, 12.549358244000073],
              [109.38880287100005, 12.549727150000043],
              [109.38887189800008, 12.550005037000032],
              [109.3889656690001, 12.550245499000122],
              [109.38913331800003, 12.550546426000063],
              [109.38934301300006, 12.55082073400011],
              [109.38961763300004, 12.551076137000015],
              [109.389954151, 12.551301232000077],
              [109.39029966000008, 12.551452043000031],
              [109.39080910700004, 12.551567507000081],
              [109.39115793300004, 12.551584051000118],
              [109.39141751300011, 12.551559541000044],
              [109.39167512300003, 12.551504382000099],
              [109.39192382800007, 12.55142303800003],
              [109.39218628200004, 12.551299170000084],
              [109.39250026900008, 12.551092091],
              [109.39275742800004, 12.550860010000074],
              [109.39295776500009, 12.550614625000021],
              [109.39312267900007, 12.550344994000094],
              [109.3932282600001, 12.550109299000043],
              [109.39331663000004, 12.549806971000075],
              [109.39336800200002, 12.549438901000086],
              [109.39336303200005, 12.549124381000086],
              [109.39331237500004, 12.548813783000055],
              [109.393219645, 12.548512703000101],
              [109.39309860300003, 12.54825254800007],
              [109.39290955300001, 12.547964124000098],
              [109.39265909000008, 12.54768577100007],
              [109.39243706200008, 12.547500718000036],
              [109.39221738900004, 12.547360234000047],
              [109.3919563580001, 12.547233497000041],
              [109.391709328, 12.547147389000111],
              [109.39139591200009, 12.547083037000027],
            ],
          ],
          [
            [
              [109.44724357700002, 12.595290143000069],
              [109.44689517500007, 12.595265862000103],
              [109.44663353400009, 12.595282352000046],
              [109.44634757000006, 12.595336533000088],
              [109.44601662400004, 12.595446240000017],
              [109.44565812700003, 12.59563592700008],
              [109.44531661200003, 12.595902354000097],
              [109.445083454, 12.596157786000093],
              [109.44488180800002, 12.596472110000077],
              [109.44473974800007, 12.596785492000071],
              [109.44465782400005, 12.597089590000097],
              [109.44461541, 12.597566150000093],
              [109.44465064700007, 12.597935064000064],
              [109.44471976600002, 12.598212945000052],
              [109.44481362600006, 12.598453394000051],
              [109.44498139700008, 12.598754290000079],
              [109.44519121600004, 12.599028555000034],
              [109.44546597100003, 12.599283898000111],
              [109.44580263100009, 12.599508914000078],
              [109.44614826300008, 12.599659640000114],
              [109.44665786200007, 12.599774976000065],
              [109.44700677500006, 12.599791428000094],
              [109.44726641000005, 12.599766848000103],
              [109.44752406500005, 12.599711618000063],
              [109.44777280600003, 12.599630205000077],
              [109.44803528900007, 12.599506262000125],
              [109.44834929300004, 12.599299091000125],
              [109.44860644900008, 12.599066931000012],
              [109.44880676600005, 12.598821481000043],
              [109.4489716460001, 12.598551794000022],
              [109.44907718700004, 12.598316060000057],
              [109.44916549600006, 12.598013695000086],
              [109.44921677900004, 12.597645595000094],
              [109.44921172100007, 12.597331062000093],
              [109.44916096700004, 12.597020462000062],
              [109.449068133, 12.59671939300002],
              [109.4489469910001, 12.596459258000079],
              [109.44875781800009, 12.596170870000021],
              [109.44850721900005, 12.595892570000087],
              [109.44828508800005, 12.595707568000057],
              [109.44806532399998, 12.595567136000072],
              [109.44780419600005, 12.595440462000072],
              [109.44755708500003, 12.595354415000058],
              [109.44724357700002, 12.595290143000069],
            ],
          ],
          [
            [
              [109.34578264900009, 12.628020062000099],
              [109.3483977600001, 12.627915379000052],
              [109.34956614200006, 12.627983060000057],
              [109.35162956900007, 12.627572722000123],
              [109.35356898900005, 12.627312698000102],
              [109.35458145400007, 12.627711818000085],
              [109.35558986900001, 12.628745555000085],
              [109.35696177800001, 12.630657961000045],
              [109.3576626350001, 12.631689797000066],
              [109.35962110600005, 12.633273370000085],
              [109.36063206300003, 12.633914237000086],
              [109.36118454600009, 12.634098954000093],
              [109.36238448100008, 12.634045888000054],
              [109.36423265200006, 12.633634121000091],
              [109.36592740100012, 12.633160974000111],
              [109.36703852500004, 12.632563342000045],
              [109.37141798100002, 12.630716391000083],
              [109.374008121, 12.629704649000038],
              [109.37582682500003, 12.629081076000135],
              [109.37844787700001, 12.62803926],
              [109.38014600300009, 12.627022037000033],
              [109.38150407500009, 12.626274737000108],
              [109.38230869900006, 12.625493855000068],
              [109.382990674, 12.624651780000116],
              [109.38314978000002, 12.62380653200008],
              [109.3830637730001, 12.622808682000118],
              [109.38279930700007, 12.620842676000075],
              [109.38222916100005, 12.613676660000104],
              [109.38313089700006, 12.60721465200003],
              [109.38322638300005, 12.606701455000023],
              [109.38366226100005, 12.605857887000036],
              [109.38843950100009, 12.599328586000079],
              [109.38868798400004, 12.598937201000114],
              [109.39430197400003, 12.596281223000092],
              [109.39482780500002, 12.595800820000045],
              [109.39520097800008, 12.595138173000107],
              [109.395361139, 12.594111584000077],
              [109.39534279400009, 12.592116830000053],
              [109.395306389, 12.589545940000056],
              [109.39579074200002, 12.586455296000041],
              [109.39625607800006, 12.583720584000078],
              [109.39672411500007, 12.582635396000084],
              [109.3970700850001, 12.58139836400005],
              [109.39735659700008, 12.579828537000102],
              [109.39895984400005, 12.574486712000075],
              [109.40057773500006, 12.571174054000076],
              [109.40240828800009, 12.568555646000055],
              [109.40367877400004, 12.566991671000059],
              [109.40525859100002, 12.565127293000076],
              [109.410145754, 12.560502103000102],
              [109.41119997200009, 12.559087908000064],
              [109.41132463500001, 12.55881663500009],
              [109.41132815800005, 12.558242435000098],
              [109.41087267200001, 12.557302848000072],
              [109.40986555799999, 12.556057771000082],
              [109.40763554200005, 12.553656986000037],
              [109.40623002900001, 12.552197967000021],
              [109.40552447600008, 12.551921779000084],
              [109.40113562500004, 12.550565889000074],
              [109.40030690400003, 12.550319167000088],
              [109.39959969200005, 12.550314949],
              [109.397658237, 12.550998470000073],
              [109.39519068500002, 12.552222828000124],
              [109.39358162700003, 12.553845183000066],
              [109.39225284100004, 12.555050576000058],
              [109.38965586300002, 12.557146066000067],
              [109.38922255500005, 12.557596792000057],
              [109.38467790300007, 12.561498301000105],
              [109.38399650200009, 12.562279969000121],
              [109.38349676300004, 12.563516042000076],
              [109.38343111100002, 12.564180530000078],
              [109.38351637600007, 12.565299265000094],
              [109.38341354400009, 12.566991059000097],
              [109.38248707600007, 12.567620133000011],
              [109.38137760100003, 12.568006314000078],
              [109.37959238700003, 12.568267523000108],
              [109.37747005300001, 12.568345350000063],
              [109.37522528400009, 12.568331746000093],
              [109.37342028500004, 12.568546973000048],
              [109.37200886700006, 12.568999747000118],
              [109.37090998000008, 12.569937515000083],
              [109.369642357, 12.571017780000114],
              [109.36605543900005, 12.57413897300008],
              [109.36302210600007, 12.577152099000076],
              [109.35985682800001, 12.579804391000058],
              [109.35626349000006, 12.582243099000044],
              [109.35335430800005, 12.583883160000118],
              [109.35258204200001, 12.584422385000058],
              [109.34643478100004, 12.588706133000114],
              [109.3434384280001, 12.590772864000053],
              [109.34190273800004, 12.591071311000055],
              [109.33980752200006, 12.591082792000028],
              [109.33759275900005, 12.591159700000086],
              [109.33660654800008, 12.591486006000084],
              [109.33635800200003, 12.591877336000044],
              [109.33616685400001, 12.592903656000052],
              [109.33615340600005, 12.594988839000097],
              [109.3363299370001, 12.596229003000035],
              [109.33723499200002, 12.598954537000038],
              [109.33743701900003, 12.60101082400009],
              [109.33742785400008, 12.602431172000104],
              [109.33726688300008, 12.60354834400008],
              [109.33680050200005, 12.604331192000105],
              [109.33559407500005, 12.605411641000062],
              [109.33438979300007, 12.606159671000048],
              [109.33052478400002, 12.609338989000028],
              [109.3291357360001, 12.610116053000107],
              [109.32737959400002, 12.610588593000037],
              [109.3256254180001, 12.610758923],
              [109.32378211200007, 12.61044514900007],
              [109.32157350000003, 12.609554875000038],
              [109.31976372800004, 12.608818193000014],
              [109.31823944100005, 12.608441809000047],
              [109.31616374700008, 12.608499047000075],
              [109.31453493700005, 12.608875922000083],
              [109.30954009200003, 12.610778486000116],
              [109.30735058200005, 12.611671253000088],
              [109.30654699800002, 12.612270562000026],
              [109.30349214600002, 12.616510150000069],
              [109.302323974, 12.618378542000118],
              [109.30117247600003, 12.620426223000079],
              [109.30092204800009, 12.621089475000067],
              [109.30088710000004, 12.621723882000067],
              [109.30073250400004, 12.622928634000095],
              [109.30132585400003, 12.627809693000101],
              [109.30215376200005, 12.630193724],
              [109.30254821699999, 12.631012189000044],
              [109.30340520900003, 12.63165227300007],
              [109.30641610500001, 12.633689130000024],
              [109.31011531400003, 12.635593339000105],
              [109.31198555500005, 12.636511808000071],
              [109.31296800100006, 12.636790018000086],
              [109.31704410700009, 12.638032866000069],
              [109.32166210900009, 12.638416519000105],
              [109.32440231000005, 12.638010663],
              [109.32665037500007, 12.637601691],
              [109.32881516000005, 12.637174849000042],
              [109.33080914600001, 12.63663047100003],
              [109.33235310500004, 12.635703275000052],
              [109.33429924900003, 12.634415932000119],
              [109.34337992300006, 12.628579360000101],
              [109.34455198300004, 12.628072877000116],
              [109.34578264900009, 12.628020062000099],
            ],
          ],
          [
            [
              [109.30610177500003, 12.636437396000042],
              [109.30575332000006, 12.636412882000057],
              [109.30549161500008, 12.636429197000075],
              [109.30520555500004, 12.636483190000101],
              [109.30487446700009, 12.636592680000101],
              [109.30451576600008, 12.636782135000056],
              [109.30417399800004, 12.637048345000055],
              [109.30394061600001, 12.637303631000039],
              [109.30373871100008, 12.637617832000013],
              [109.30359640700004, 12.637931132000089],
              [109.30351425500008, 12.638235186000111],
              [109.30347150200001, 12.638711737000017],
              [109.30350649100006, 12.63908068800008],
              [109.30357543200007, 12.639358626000073],
              [109.30366914400001, 12.639599147000078],
              [109.30383674000002, 12.639900167000027],
              [109.30404641200005, 12.640174583000084],
              [109.30432104600004, 12.640430119000088],
              [109.30465761800009, 12.640655369000074],
              [109.30500321500008, 12.640806332000041],
              [109.30551283700007, 12.640922012000109],
              [109.30586180900008, 12.640938698000069],
              [109.30612151300006, 12.640914291000092],
              [109.30637925900007, 12.640859231000066],
              [109.30662810600006, 12.640777980000093],
              [109.30689072800003, 12.640654208000067],
              [109.307204939, 12.640447238000084],
              [109.30746230700007, 12.640215241000073],
              [109.30766283500006, 12.639969916000025],
              [109.30782793400003, 12.639700328000101],
              [109.30793366000007, 12.639464656000053],
              [109.30802219600012, 12.639162337000085],
              [109.30807374400004, 12.638794258000095],
              [109.30806890300008, 12.638479709000093],
              [109.30801835400007, 12.638169064000058],
              [109.30792570900005, 12.637867920000099],
              [109.30780472300006, 12.637607694000062],
              [109.30761571100007, 12.637319169000081],
              [109.30736525500004, 12.637040691000044],
              [109.30714320700005, 12.636855533000089],
              [109.30692349600008, 12.636714949000092],
              [109.30666240400005, 12.636588096000077],
              [109.30641530200003, 12.636501881000049],
              [109.30610177500003, 12.636437396000042],
            ],
          ],
          [
            [
              [109.29309036500011, 12.6371905760001],
              [109.29274191000006, 12.637166041000023],
              [109.29248020200008, 12.63718234000004],
              [109.29219413900003, 12.637236315000065],
              [109.29186304200007, 12.637345784000063],
              [109.29150432800006, 12.637535218000016],
              [109.29116254200002, 12.637801407000014],
              [109.290929143, 12.638056678000085],
              [109.29072721700007, 12.638370867000058],
              [109.29058489200003, 12.638684159000134],
              [109.29050272000006, 12.638988208000066],
              [109.29045993700007, 12.639464757000061],
              [109.29049490300012, 12.639833710000035],
              [109.29056382600005, 12.640111653000117],
              [109.29065752300008, 12.640352180000034],
              [109.29082510100008, 12.640653210000073],
              [109.29103475600003, 12.640927639000042],
              [109.29130937400001, 12.641183192000048],
              [109.29164593300005, 12.641408463000035],
              [109.29199152200005, 12.641559447000093],
              [109.29250113800003, 12.641675159000075],
              [109.29285011000005, 12.641691867000036],
              [109.29310981700003, 12.641667475000061],
              [109.29336756700005, 12.641612431000036],
              [109.29361642100011, 12.641531196000065],
              [109.293879051, 12.641407439000128],
              [109.29419327600006, 12.641200489000058],
              [109.29445066100004, 12.640968507000048],
              [109.29465120500002, 12.64072319400009],
              [109.294816322, 12.640453617000079],
              [109.29492206300006, 12.640217950000119],
              [109.29501061800001, 12.639915638000064],
              [109.29506219100001, 12.639547561000073],
              [109.29505737000005, 12.639233011000071],
              [109.29500684000006, 12.638922363000036],
              [109.29491421400012, 12.638621214000077],
              [109.29479324400006, 12.638360980000039],
              [109.29460425000005, 12.638072443000057],
              [109.29435381100002, 12.637793949000107],
              [109.29413177400004, 12.637608778000063],
              [109.29391207100007, 12.637468179000065],
              [109.29365098600005, 12.637341310000048],
              [109.29340388900003, 12.637255080000019],
              [109.29309036500011, 12.6371905760001],
            ],
          ],
          [
            [
              [109.21735771700006, 12.641313040000044],
              [109.21700961100004, 12.641285143000045],
              [109.21663192900004, 12.641308069000077],
              [109.21637549900007, 12.641361985000097],
              [109.21612661500008, 12.641443129000061],
              [109.21586393700001, 12.641566789000079],
              [109.21554963200003, 12.64177362300005],
              [109.21529215700005, 12.642005509000052],
              [109.21509151600006, 12.642250746000093],
              [109.21492629200006, 12.642520262000099],
              [109.214820457, 12.642755889000055],
              [109.21473178200004, 12.643058169000108],
              [109.21468006400002, 12.643426228000097],
              [109.21468476000005, 12.643740780000099],
              [109.21473516900005, 12.644051449000047],
              [109.21482767900007, 12.644352635000098],
              [109.21494855100003, 12.644612916000051],
              [109.21513743800003, 12.644901526000039],
              [109.21538777800005, 12.645180115000086],
              [109.21560975100003, 12.645365371000048],
              [109.21582940800009, 12.645506051000053],
              [109.2160904560001, 12.645633018000078],
              [109.21642246500004, 12.645739804000032],
              [109.2167957030001, 12.645800724000098],
              [109.21705784100003, 12.645808878000063],
              [109.21743259000004, 12.645763183000012],
              [109.21787847300007, 12.645629184000116],
              [109.21823727100009, 12.645439874000084],
              [109.21857916500005, 12.645173802],
              [109.21881266200009, 12.644918608000031],
              [109.21901470600002, 12.644604486000064],
              [109.21915714500008, 12.644291241000081],
              [109.21923942500005, 12.643987218000062],
              [109.21928237600005, 12.643510682000068],
              [109.21924753800002, 12.643141715000093],
              [109.21917871100001, 12.642863749000098],
              [109.21908509700008, 12.642623190000089],
              [109.21891762399999, 12.642322102000046],
              [109.21870806400005, 12.642047601000071],
              [109.21843353600008, 12.641791953000057],
              [109.21809705800004, 12.64156656600006],
              [109.21775152400005, 12.641415460000081],
              [109.21735771700006, 12.641313040000044],
            ],
          ],
          [
            [
              [109.31542406000007, 12.64198281500004],
              [109.3151076880001, 12.641980816000052],
              [109.31479015500003, 12.642156438000105],
              [109.31460762300001, 12.64242174800003],
              [109.31446911100001, 12.64286496100002],
              [109.31358911500003, 12.64610129100001],
              [109.31345177800003, 12.646366873000135],
              [109.313608065, 12.646656523000031],
              [109.31403584400005, 12.646903488000037],
              [109.31457732700008, 12.647040136000063],
              [109.31502900700006, 12.647087401000066],
              [109.31543577200006, 12.647089979000013],
              [109.31570754000008, 12.647002877000089],
              [109.31598018300008, 12.646782558000067],
              [109.31620732900004, 12.646606346000054],
              [109.31643738200006, 12.645986069000068],
              [109.31653476500004, 12.64492086100004],
              [109.31640528, 12.64398744200008],
              [109.31636415400004, 12.643365450000131],
              [109.31623177300001, 12.642876104000054],
              [109.31605360400002, 12.642475299000109],
              [109.31573955900006, 12.642118039000126],
              [109.31542406000007, 12.64198281500004],
            ],
          ],
          [
            [
              [109.37424359600004, 12.644397292000063],
              [109.37389546300001, 12.644369653000085],
              [109.37351779300012, 12.644392859000051],
              [109.37326140000005, 12.644446966000087],
              [109.37301257500006, 12.644528295000066],
              [109.3727499890001, 12.6446521500001],
              [109.37243583800003, 12.644859218000091],
              [109.37217853800007, 12.64509129500002],
              [109.37197808200008, 12.645336683000073],
              [109.37181306300002, 12.64560632200009],
              [109.37170740800008, 12.645842028000052],
              [109.37161896400004, 12.64614437400011],
              [109.37156752900005, 12.646512472000014],
              [109.37157246700011, 12.646827022000105],
              [109.37162311600011, 12.64713765400005],
              [109.37171585900006, 12.647438772],
              [109.37183693300004, 12.64769896400013],
              [109.37202604500006, 12.647987435000106],
              [109.372276603, 12.648265839000048],
              [109.37249872200009, 12.648450931000086],
              [109.37271849100006, 12.648591448000078],
              [109.37297964, 12.648718221000086],
              [109.37322678500001, 12.648804360000019],
              [109.37361331300012, 12.648881222000073],
              [109.37394717500003, 12.648893365000012],
              [109.37432189400003, 12.648847391000027],
              [109.37476768100007, 12.648713061000104],
              [109.37512633800007, 12.64852348400005],
              [109.3754680330001, 12.648257157000041],
              [109.37570133700004, 12.64800179000005],
              [109.37590314200004, 12.647687517000071],
              [109.37604534200008, 12.647374165000079],
              [109.37612738900003, 12.647070080000054],
              [109.37616997400009, 12.646593511000058],
              [109.37613485200004, 12.646224569000085],
              [109.37606581000009, 12.645946653000093],
              [109.37597200900005, 12.64570616300009],
              [109.37580430200003, 12.645405198000057],
              [109.37559452800008, 12.645130852000095],
              [109.3753197990001, 12.644875408000098],
              [109.37498314200003, 12.644650270000033],
              [109.37463748700003, 12.644499420000075],
              [109.37424359600004, 12.644397292000063],
            ],
          ],
          [
            [
              [109.34343743100003, 12.643014427000042],
              [109.34266900700004, 12.642981572000076],
              [109.34178660100012, 12.643138140000064],
              [109.34132743500005, 12.643405256000049],
              [109.34090766900005, 12.643823096000071],
              [109.338222, 12.646953421000083],
              [109.33717379200002, 12.648451414000037],
              [109.3369859470001, 12.649094239000112],
              [109.336988917, 12.649547145000016],
              [109.33716684200007, 12.650319755000082],
              [109.33771225900007, 12.651486352000061],
              [109.33821679400008, 12.652275773],
              [109.33854718700006, 12.652634738000044],
              [109.33883483700005, 12.652800269000062],
              [109.33952697400012, 12.652909114000098],
              [109.34110280300004, 12.653050090000038],
              [109.34448643000005, 12.653557008000059],
              [109.34567865800001, 12.65377589000005],
              [109.34660152099998, 12.653920980000064],
              [109.34733107800004, 12.653878595000021],
              [109.3479049900001, 12.653535239000034],
              [109.34824719300008, 12.653004646000097],
              [109.34835693000011, 12.652173594000081],
              [109.34792293600007, 12.650440168],
              [109.34741088300007, 12.648518520000083],
              [109.34689609800003, 12.646181722000092],
              [109.34661925100004, 12.644975699000048],
              [109.34626858300007, 12.644223069000036],
              [109.34609437600004, 12.644016590000094],
              [109.34547758700008, 12.643680831000024],
              [109.34447646200002, 12.643309769000053],
              [109.34343743100003, 12.643014427000042],
            ],
          ],
          [
            [
              [109.40061292000004, 12.644764708000087],
              [109.40018378000006, 12.644520350000031],
              [109.39901190600008, 12.644996858000111],
              [109.39740561100005, 12.64607518500007],
              [109.39592064900002, 12.647083910000044],
              [109.39561001900007, 12.647241765000048],
              [109.39377339400008, 12.648466175000104],
              [109.3924521700001, 12.649525205000065],
              [109.39153032600004, 12.650698950000015],
              [109.39118388900005, 12.651258446000034],
              [109.39112251100003, 12.651931967000072],
              [109.391176849, 12.652381556000057],
              [109.39140405400006, 12.652607571000106],
              [109.39447214900008, 12.655546302000074],
              [109.39475652800007, 12.655772643000061],
              [109.39515450100005, 12.656111989000072],
              [109.395496377, 12.656282531000061],
              [109.396126495, 12.656061694000085],
              [109.39778961800003, 12.655173172000021],
              [109.39983583000004, 12.653700445000114],
              [109.4005835360001, 12.653004982000125],
              [109.40247716900009, 12.651666482000044],
              [109.40322068800006, 12.650824728000119],
              [109.40328708600006, 12.650039354000063],
              [109.40286750000007, 12.64826335300002],
              [109.40180701200008, 12.646147760000035],
              [109.40091862700011, 12.645068754000109],
              [109.40061292000004, 12.644764708000087],
            ],
          ],
          [
            [
              [109.46553158900008, 12.65162337400003],
              [109.46521806800008, 12.651518821000083],
              [109.46481942000005, 12.651639823000059],
              [109.46454508800008, 12.651987572000055],
              [109.46441771000012, 12.652274537000084],
              [109.46376009800007, 12.655425091000081],
              [109.46377747800008, 12.656021123000103],
              [109.46390540200009, 12.656891385000078],
              [109.46468334200007, 12.658636131000021],
              [109.46514027500004, 12.659275797000101],
              [109.46551577500003, 12.659524557000077],
              [109.46606029200004, 12.65952769300007],
              [109.46675288200007, 12.659285096000071],
              [109.46738301300006, 12.658980481000091],
              [109.46776145900003, 12.658736075000119],
              [109.46803665900006, 12.658244456000121],
              [109.46809325200003, 12.65753582900002],
              [109.46831371600007, 12.655687639000044],
              [109.46832058300001, 12.654536911000045],
              [109.46819958000003, 12.653755332000037],
              [109.46786830500007, 12.653116397000106],
              [109.46724272900009, 12.652660701000061],
              [109.46553158900008, 12.65162337400003],
            ],
          ],
          [
            [
              [109.26237318400008, 12.676136459000096],
              [109.26202466700002, 12.676111872000105],
              [109.26176290700003, 12.676128133000118],
              [109.26147678000008, 12.676182069000051],
              [109.26114560400003, 12.676291494000045],
              [109.260786792, 12.676480882000083],
              [109.26044490100006, 12.676747031000078],
              [109.260211419, 12.677002278000058],
              [109.26000940700008, 12.677316449000029],
              [109.25986700700003, 12.677629731000104],
              [109.25978477500006, 12.677933780000037],
              [109.25974191100006, 12.678410340000122],
              [109.25977682800001, 12.678779312000097],
              [109.25984572300004, 12.679057275000092],
              [109.25993940200006, 12.6792978240001],
              [109.26010696600007, 12.679598890000053],
              [109.26031662000001, 12.679873359000025],
              [109.26059125200008, 12.680128961000035],
              [109.26092784100004, 12.680354289000116],
              [109.26127347300005, 12.68050532900009],
              [109.26178316900003, 12.680621119000078],
              [109.26213220600005, 12.680637878000043],
              [109.26239196600004, 12.680613524000071],
              [109.26264977400005, 12.680558515000049],
              [109.26289868700005, 12.680477313000081],
              [109.26316138600002, 12.680353590000058],
              [109.263475703, 12.68014667800008],
              [109.26373317100007, 12.679914725000073],
              [109.26393379000007, 12.679669433000116],
              [109.26409898000006, 12.679399869000106],
              [109.26420477700002, 12.679164209000058],
              [109.26429339400006, 12.678861898],
              [109.26434503200008, 12.678493816000101],
              [109.26434025800003, 12.678179254],
              [109.26428976500003, 12.677868587000061],
              [109.26419716700011, 12.6775674130001],
              [109.26407621300004, 12.67730715300006],
              [109.26388722600004, 12.677018577000075],
              [109.26363678100009, 12.676740038000032],
              [109.26341472900002, 12.676554827000073],
              [109.26319500600005, 12.676414192000072],
              [109.26293389000003, 12.676287280000052],
              [109.26268675900009, 12.676201010000019],
              [109.26237318400008, 12.676136459000096],
            ],
          ],
          [
            [
              [109.27986118700002, 12.695289125000018],
              [109.27927507000003, 12.695087465000119],
              [109.27889987600007, 12.695212852000013],
              [109.27835914000009, 12.695585490000026],
              [109.27769436000007, 12.696164083000026],
              [109.27757109800008, 12.696493152000075],
              [109.27765695699999, 12.69686200200004],
              [109.28085008400005, 12.699756592000087],
              [109.282278166, 12.700979163000079],
              [109.28408404600002, 12.702486731000116],
              [109.28471222500004, 12.702729160000013],
              [109.28525529700002, 12.702725859000095],
              [109.28567252800009, 12.702641241000022],
              [109.28596314100007, 12.702352185000086],
              [109.28600129300003, 12.701777374000043],
              [109.28574650399999, 12.701122273000108],
              [109.28507318800006, 12.700346581000053],
              [109.27986118700002, 12.695289125000018],
            ],
          ],
          [
            [
              [109.36937671100006, 12.705564888000074],
              [109.36652237300005, 12.703942806000066],
              [109.36552946800006, 12.703949221000064],
              [109.3645380470001, 12.704177317000108],
              [109.36395429700011, 12.704624475000069],
              [109.36382187300008, 12.705068721000055],
              [109.36400922600004, 12.706087316000046],
              [109.36446529600002, 12.706793796000058],
              [109.36592262199999, 12.708735313000117],
              [109.36678728400003, 12.709793867000128],
              [109.36714953700007, 12.709968889000043],
              [109.36751120500007, 12.710055227000082],
              [109.36796253500002, 12.710052307000106],
              [109.3690445440001, 12.709867951000129],
              [109.36972094700012, 12.709774884000067],
              [109.37026165700009, 12.709638370000052],
              [109.37071075400003, 12.709302909000096],
              [109.37056983000009, 12.708483546000069],
              [109.36978796900007, 12.706315990000048],
              [109.36937671100006, 12.705564888000074],
            ],
          ],
          [
            [
              [109.29486460700009, 12.712481065000068],
              [109.29357106100009, 12.712368029000041],
              [109.29277383100003, 12.712856719000063],
              [109.29234942300009, 12.71388742900008],
              [109.29229323800006, 12.714734449000085],
              [109.29230129100002, 12.716004426],
              [109.29280413600006, 12.717634233000028],
              [109.29386337200003, 12.719623510000043],
              [109.294825862, 12.720917879000114],
              [109.2961860290001, 12.721816706000054],
              [109.29803910500003, 12.722772977000128],
              [109.29995415100004, 12.723789339000023],
              [109.3010971410001, 12.724417332000113],
              [109.30319613300004, 12.725311570000118],
              [109.30442859700005, 12.725485403000024],
              [109.30849032400005, 12.725218399000047],
              [109.30966005100001, 12.72521115800007],
              [109.31058235600003, 12.725024011000039],
              [109.31101136300001, 12.724718964000113],
              [109.31137724700008, 12.724172398000098],
              [109.31137218800009, 12.723386222000064],
              [109.31068915200007, 12.722483286000051],
              [109.30808988300004, 12.720382665000061],
              [109.305556096, 12.718886392000071],
              [109.30382576000009, 12.717868947000087],
              [109.30277262200009, 12.716847327000023],
              [109.30190341100011, 12.715703608000071],
              [109.30134162899999, 12.714497526000022],
              [109.300657895, 12.713473625000036],
              [109.29936318200008, 12.713179192000011],
              [109.29769910900006, 12.712887026000084],
              [109.29486460700009, 12.712481065000068],
            ],
          ],
          [
            [
              [109.37685677800008, 12.838931994000101],
              [109.37547776700011, 12.838490597000083],
              [109.3740425660001, 12.838218441000036],
              [109.3728187250001, 12.838113790000081],
              [109.37102156700008, 12.838012857000079],
              [109.36930211000001, 12.838099054000017],
              [109.3659024290001, 12.838421276000121],
              [109.36425941200004, 12.838506938000103],
              [109.3634937300001, 12.838324217000084],
              [109.362726041, 12.837841270000093],
              [109.36234132000006, 12.837468452000065],
              [109.36054912200009, 12.835246926000108],
              [109.35908220900005, 12.833079599000106],
              [109.35716866899999, 12.829845528000037],
              [109.35639427100004, 12.82834929300005],
              [109.35546077700008, 12.825915827000054],
              [109.35514703100006, 12.824716882000063],
              [109.35514178900007, 12.823928788000048],
              [109.35522373300009, 12.821882886000056],
              [109.35592015300007, 12.81454137000013],
              [109.35676789200005, 12.80697376100006],
              [109.35686954600003, 12.805021593],
              [109.35738528200007, 12.799257560000042],
              [109.35797519500002, 12.796026279000026],
              [109.35920068800007, 12.790689315000046],
              [109.36076475400009, 12.784562082000049],
              [109.36318155200003, 12.777397356000096],
              [109.36389372000008, 12.775328711000087],
              [109.36598101800011, 12.770342758000107],
              [109.36912012000005, 12.762685527000082],
              [109.37241257300006, 12.755139886000054],
              [109.37589656400003, 12.747705575000088],
              [109.37949592100003, 12.740420612000046],
              [109.38768401200011, 12.725393830000085],
              [109.39177837300004, 12.717992977000046],
              [109.39234520000004, 12.717088618000092],
              [109.39637234, 12.711076632000038],
              [109.40142280300009, 12.703932047000064],
              [109.40616786700011, 12.696864469000067],
              [109.41094768000009, 12.68934629000009],
              [109.41340266900009, 12.68535221500011],
              [109.41583963700002, 12.681527108000077],
              [109.41746544900008, 12.679152136000125],
              [109.42202785400009, 12.673436473000075],
              [109.42369433300004, 12.67143644500006],
              [109.42509609100003, 12.669813442000065],
              [109.42581880400004, 12.669395800000059],
              [109.42688496300008, 12.668938308000042],
              [109.42879048800009, 12.668362570000101],
              [109.43309903400007, 12.667357771000097],
              [109.43828403400008, 12.666084245000063],
              [109.44087743400007, 12.665578768000072],
              [109.44179369700004, 12.665534992000092],
              [109.44279032400007, 12.666053540000098],
              [109.44401715600006, 12.666720602000046],
              [109.44643424900003, 12.668280114000117],
              [109.4488890370001, 12.669764293000084],
              [109.44973161300001, 12.670096237000015],
              [109.450686886, 12.670164734000087],
              [109.45148780100001, 12.670009137000092],
              [109.45194367400008, 12.669668287000134],
              [109.45251010000004, 12.668763806000081],
              [109.45326291800006, 12.667220117000076],
              [109.45414685000003, 12.665319046000077],
              [109.45497066700005, 12.663024381000108],
              [109.45602386300007, 12.660765667000094],
              [109.45773149100006, 12.656569935000038],
              [109.459272459, 12.653107082000046],
              [109.45972075800006, 12.651715590000022],
              [109.45997918700004, 12.650475517000091],
              [109.45989959700003, 12.650025775000094],
              [109.45959007500009, 12.649465053000062],
              [109.45859241200006, 12.64879651200008],
              [109.45777021100005, 12.648633318000078],
              [109.45673866100006, 12.648565380000052],
              [109.45567053100007, 12.648722835000024],
              [109.4534231920001, 12.649526302000067],
              [109.45083269900007, 12.650407150000119],
              [109.44854474400005, 12.650873122000037],
              [109.44713184900012, 12.650882798000039],
              [109.44539398600003, 12.650838399000095],
              [109.44252524800008, 12.650182566000099],
              [109.43816462900006, 12.649161607000091],
              [109.43663376300005, 12.648684206000025],
              [109.43579050500009, 12.648239644000101],
              [109.43521402400009, 12.647718221000035],
              [109.43478870100006, 12.646970634000086],
              [109.4337608220001, 12.644707412000054],
              [109.43321753000001, 12.643472816000044],
              [109.43267055400004, 12.641712902000089],
              [109.43231265200009, 12.639689054000057],
              [109.43203061000003, 12.637589648000079],
              [109.4319284260001, 12.636633482000013],
              [109.43206929600004, 12.634943973000111],
              [109.43232498200005, 12.633291208000051],
              [109.43311861299999, 12.629402163000057],
              [109.43348222900005, 12.626810592000124],
              [109.43384662700001, 12.624331596000092],
              [109.43398906600007, 12.622867235000077],
              [109.43410857600001, 12.620858937],
              [109.4342825840001, 12.618456292000067],
              [109.43442422400001, 12.616879370000065],
              [109.43441604000004, 12.615716216000104],
              [109.43421828100003, 12.614741968000052],
              [109.43386911900009, 12.613956359000065],
              [109.43269081300012, 12.611994407000092],
              [109.43222686600011, 12.611172057000084],
              [109.43075790600007, 12.608592945000028],
              [109.42868605600006, 12.60442320500008],
              [109.42806523100003, 12.603001551000135],
              [109.42774852000011, 12.601390235000041],
              [109.42758215400002, 12.599440199000076],
              [109.42772618800004, 12.598201],
              [109.42783519500009, 12.597412298000064],
              [109.42811797200004, 12.596903834],
              [109.42876380200001, 12.596449210000047],
              [109.42951032100004, 12.596238657000034],
              [109.43051065600012, 12.596126473000078],
              [109.43131896100003, 12.596056699000078],
              [109.43223517700008, 12.596050492000098],
              [109.43273120400009, 12.596009603000089],
              [109.43322590200007, 12.595781124000014],
              [109.43368111200007, 12.595365287000069],
              [109.43421214800006, 12.59487389900009],
              [109.43451306100008, 12.594233983000079],
              [109.43480790800005, 12.592731101000075],
              [109.43498822200004, 12.591229008000047],
              [109.43679665600011, 12.582399103000073],
              [109.43746209000001, 12.579317810000122],
              [109.43816954000002, 12.574078763000072],
              [109.43857353900005, 12.57182473300009],
              [109.43841026900004, 12.57032499600008],
              [109.43794639400002, 12.569502691000077],
              [109.43713819500005, 12.568570159000013],
              [109.43625551800001, 12.567900785000051],
              [109.43456835600004, 12.566824132000052],
              [109.43364963300004, 12.566455154000076],
              [109.43231234900007, 12.566276620000048],
              [109.431625544, 12.566318790000073],
              [109.43111248700009, 12.566641194000015],
              [109.43009504300002, 12.568524128000112],
              [109.42892752700006, 12.570783298000075],
              [109.42794637600005, 12.572403338000091],
              [109.42669800400006, 12.57402518000008],
              [109.42554176800004, 12.575177378000111],
              [109.424440815, 12.576047784000098],
              [109.42318663200004, 12.576844183000089],
              [109.42021677499999, 12.577952284000025],
              [109.41835154100002, 12.578715235000091],
              [109.41682984500007, 12.579475876000055],
              [109.4157664840001, 12.580270953000115],
              [109.41451640600005, 12.581667615000034],
              [109.41366648100006, 12.582967793000053],
              [109.41310116200002, 12.584022176000074],
              [109.41283992300006, 12.584886922000036],
              [109.41261634700004, 12.585676372000075],
              [109.4125140350001, 12.587440574000118],
              [109.4125504080001, 12.589935525000049],
              [109.412601841, 12.591848796000081],
              [109.412887, 12.594435907000088],
              [109.41339371800007, 12.598691268000016],
              [109.41359422400004, 12.600078243000022],
              [109.41375577300009, 12.601352917000062],
              [109.41364879100003, 12.602441781],
              [109.41350153800002, 12.603230735000091],
              [109.41289824200001, 12.604322909000041],
              [109.41217833200004, 12.605115691],
              [109.41126622100006, 12.605722145000085],
              [109.41050396700007, 12.605914842000042],
              [109.40953004800004, 12.605865053000096],
              [109.4078486710001, 12.605651123000071],
              [109.406015106, 12.605513237000119],
              [109.40441061100003, 12.605373814000041],
              [109.40261574700003, 12.605310687000058],
              [109.40116602800006, 12.605470393000038],
              [109.40021389900006, 12.60581440200008],
              [109.39952928100008, 12.60619416300006],
              [109.39823946600004, 12.607403415000105],
              [109.39759583800007, 12.608195644000107],
              [109.39706906000001, 12.609324799000097],
              [109.39667756900003, 12.610696957000032],
              [109.39660917600006, 12.611860612000035],
              [109.39631788300002, 12.613926281000063],
              [109.39591203900002, 12.615992710000066],
              [109.39553973700004, 12.617383506000033],
              [109.39503163000009, 12.618456256000064],
              [109.39393774200009, 12.62041465600001],
              [109.39280514799999, 12.622298272000075],
              [109.39072337200005, 12.624976107000039],
              [109.38960917500006, 12.626765777000058],
              [109.38843883300004, 12.628724666000045],
              [109.38813924800012, 12.629589669000028],
              [109.38829708400003, 12.630339092000041],
              [109.38887544100001, 12.631160810000084],
              [109.38968111900006, 12.631718364000031],
              [109.39048602400004, 12.632163344000078],
              [109.39197898500002, 12.632716367000095],
              [109.39343275300006, 12.633119553000059],
              [109.39589927300003, 12.633647360000031],
              [109.39800191100001, 12.634008701000086],
              [109.40045000500007, 12.6346303940001],
              [109.40324331100007, 12.635474910000076],
              [109.40477452500002, 12.636027591000063],
              [109.40592390400009, 12.636582801000083],
              [109.406767567, 12.637102511000096],
              [109.40745978800007, 12.637810851000085],
              [109.40775225800012, 12.638690705000053],
              [109.40818239, 12.640151256000108],
              [109.40866314900008, 12.643412612000036],
              [109.40937486300007, 12.646935109000077],
              [109.40961513300006, 12.648547031000044],
              [109.40964316900008, 12.649841408000027],
              [109.40953795500008, 12.651192973000034],
              [109.40915035800006, 12.653128041000093],
              [109.40716448800006, 12.661415347000025],
              [109.4067290240001, 12.664720398000016],
              [109.40577933300003, 12.670993312000077],
              [109.40529062100009, 12.672122300000053],
              [109.40489259800003, 12.672556476000095],
              [109.40386663000004, 12.67331379100005],
              [109.40242107500008, 12.674148927000049],
              [109.39660337800002, 12.677902400000017],
              [109.3961673900001, 12.678374333000074],
              [109.39578907500007, 12.67890218400005],
              [109.39545075000004, 12.679692442000066],
              [109.39530696700005, 12.681006761000011],
              [109.39528392800001, 12.683220893000067],
              [109.39522244700007, 12.685397754000059],
              [109.39499971000004, 12.686337355000086],
              [109.39446963500005, 12.68701630100013],
              [109.39351839700004, 12.687547930000063],
              [109.39271711200008, 12.687665781000044],
              [109.39222060800003, 12.687669051000048],
              [109.39164592, 12.687410161000033],
              [109.39076261300002, 12.686702993000052],
              [109.389092142, 12.685381828000073],
              [109.38862897000004, 12.684671892000042],
              [109.38851030700009, 12.684072275000078],
              [109.38857978400006, 12.683058638000054],
              [109.38887663300002, 12.68178083700011],
              [109.39001832700006, 12.678377320000054],
              [109.39084905500006, 12.676983440000098],
              [109.39239900300005, 12.674646691000111],
              [109.39285011400007, 12.673593028000061],
              [109.39299569300005, 12.672541378000089],
              [109.39302926900007, 12.671865716000051],
              [109.39267991200002, 12.671042469000058],
              [109.39221830700002, 12.670557690000093],
              [109.391720042, 12.670298301000036],
              [109.39118538800008, 12.670301819000061],
              [109.38967726900009, 12.67036801800012],
              [109.38711982200006, 12.67057243300007],
              [109.38269183400006, 12.67090163800013],
              [109.379790665, 12.67110821200008],
              [109.37805467400007, 12.671363445000059],
              [109.37592159900007, 12.672202877000059],
              [109.37462744000001, 12.672849215000069],
              [109.37390662400006, 12.673566871000029],
              [109.37334084800004, 12.674621238000048],
              [109.37258259600002, 12.675451713000106],
              [109.37208788000002, 12.675717595000078],
              [109.37109592800002, 12.675874130000063],
              [109.36987331500002, 12.675807005000104],
              [109.36899392300009, 12.675662601000058],
              [109.36826627700002, 12.675367111000034],
              [109.367613507, 12.674845993000082],
              [109.3667268240001, 12.67361340600006],
              [109.36500410100011, 12.670153501000046],
              [109.36469306100004, 12.669329971000119],
              [109.36453603400001, 12.66869306700009],
              [109.36468527300011, 12.668166757000064],
              [109.36491014000006, 12.667527388000098],
              [109.36551136600006, 12.666060055000104],
              [109.36630503500001, 12.664816616000072],
              [109.36787463600002, 12.662536237000042],
              [109.36870722300009, 12.661405120000067],
              [109.36911771700002, 12.659976539000082],
              [109.36918956000002, 12.659300633000024],
              [109.36914708600005, 12.658662996000112],
              [109.36826330500003, 12.65501011400003],
              [109.36697218400005, 12.650403015000043],
              [109.36588059400006, 12.647070448000084],
              [109.3654924570001, 12.646134868000035],
              [109.36445366700003, 12.644978336000088],
              [109.36372337600001, 12.644270098000019],
              [109.36322471800005, 12.643935603000054],
              [109.36238364500009, 12.643790929000074],
              [109.36165939000003, 12.643983211000085],
              [109.36120317300004, 12.644286341000031],
              [109.36059822300007, 12.645190801000082],
              [109.35876437400003, 12.647923068000109],
              [109.35623339800006, 12.65206694900005],
              [109.35360996000006, 12.656680451000033],
              [109.35323556100008, 12.657808580000028],
              [109.35275231600006, 12.659800455000079],
              [109.35234346000007, 12.661491660000122],
              [109.35181678200011, 12.6626958050001],
              [109.35109941300009, 12.663938689000085],
              [109.34969487900005, 12.665223490000075],
              [109.34881876600012, 12.66556680800006],
              [109.34836049600004, 12.665569731000035],
              [109.34759523000005, 12.665349462000018],
              [109.34656065700007, 12.664830709000084],
              [109.34535312800008, 12.664144193000125],
              [109.34393198200009, 12.662914925000022],
              [109.34258697600009, 12.661647645000061],
              [109.34073913200005, 12.659407920000108],
              [109.34000690700005, 12.658399407000063],
              [109.33938875500006, 12.657315130000056],
              [109.33814729700001, 12.654358584000123],
              [109.33756932500006, 12.653574245000064],
              [109.33718598200005, 12.653351525000021],
              [109.33676592800002, 12.653354182000063],
              [109.33607954700008, 12.653508618000052],
              [109.33539637000004, 12.654150842000076],
              [109.33505563100009, 12.65460327600003],
              [109.33426402100005, 12.656184286000014],
              [109.33330141500008, 12.65789770200003],
              [109.33250953300009, 12.659441191000019],
              [109.33220992, 12.660343658000082],
              [109.33217663400009, 12.661094357000112],
              [109.33218178400007, 12.661882326000027],
              [109.33237812100006, 12.662706629000107],
              [109.33288020900004, 12.663566525000112],
              [109.33384057800001, 12.664423520000055],
              [109.33491305200002, 12.664904576000067],
              [109.33684467000006, 12.66536141700003],
              [109.33894999500008, 12.666098578000094],
              [109.34074833700001, 12.666612527000074],
              [109.34178413800001, 12.66731892100008],
              [109.34247870700001, 12.668402716000047],
              [109.3438216050001, 12.672240451000059],
              [109.34452338900007, 12.67441241800009],
              [109.34511011300005, 12.676510078000101],
              [109.34538909400004, 12.678271969000042],
              [109.34553353600009, 12.679903378000022],
              [109.34604439400007, 12.682076580000066],
              [109.34674896200002, 12.684661316000055],
              [109.34721570800009, 12.685934196000035],
              [109.34816321700006, 12.687710598000111],
              [109.34978746500008, 12.690739779000102],
              [109.35198889600005, 12.694403207000081],
              [109.35295271900004, 12.695747959000057],
              [109.35339457500001, 12.696139140000094],
              [109.35385415100011, 12.696323822000098],
              [109.35435093600009, 12.696358171],
              [109.35496205200012, 12.69635425500004],
              [109.35534250100007, 12.696126662000038],
              [109.35560787100007, 12.695824755000016],
              [109.35583378500003, 12.695335477000034],
              [109.35594338100005, 12.694584259000051],
              [109.35597758300003, 12.693983638000093],
              [109.35597134200005, 12.693045541000101],
              [109.35592615700004, 12.691995122000053],
              [109.35591966600003, 12.691019507000066],
              [109.35595336800007, 12.690343831000026],
              [109.35629236400005, 12.689628676],
              [109.35674794000009, 12.689212977000073],
              [109.35773921800003, 12.688943940000017],
              [109.35930314100003, 12.688633687000015],
              [109.359876039, 12.688630003000066],
              [109.36132863800002, 12.688808278000073],
              [109.36228498200011, 12.689027272000073],
              [109.36331770000001, 12.689245768000122],
              [109.36525262900007, 12.690152648000018],
              [109.36632683100004, 12.690858690000082],
              [109.36736435500009, 12.691790118000055],
              [109.36802045300001, 12.692799062000047],
              [109.36875520800005, 12.694145222000051],
              [109.3689906760001, 12.695081827000097],
              [109.36906246300008, 12.697239078000065],
              [109.36920351200011, 12.70117836000013],
              [109.36921107500002, 12.702304081000102],
              [109.36948273500003, 12.702940259000071],
              [109.36990692700005, 12.70353791800005],
              [109.37067589300008, 12.704283456],
              [109.37182860500006, 12.705289171000087],
              [109.37294084500007, 12.705957421000045],
              [109.37460859600004, 12.706865965000079],
              [109.37614102000001, 12.707531468000075],
              [109.376984647, 12.708013812000017],
              [109.37752321400006, 12.708573193000026],
              [109.37787281900003, 12.709434013000108],
              [109.37845998700003, 12.711531640000116],
              [109.37858422100001, 12.712956822000086],
              [109.37855415300001, 12.714157853000073],
              [109.37838847200007, 12.715078319000026],
              [109.37774772500008, 12.716358386000069],
              [109.37702931100006, 12.717451329000083],
              [109.37634705400009, 12.718243824000107],
              [109.37562507900003, 12.718811415000085],
              [109.37455955600007, 12.719418769000066],
              [109.37334401400004, 12.720439872000087],
              [109.37232097300003, 12.721684888000071],
              [109.37150915400011, 12.723097390000058],
              [109.36738029200006, 12.731173579000108],
              [109.363858581, 12.738682926000065],
              [109.36272930800001, 12.741204520000069],
              [109.36182547700001, 12.743161746000096],
              [109.36118654400008, 12.744742002000107],
              [109.36041752100004, 12.74686723200009],
              [109.35978106900005, 12.748822748000064],
              [109.35865970499999, 12.752545159000038],
              [109.35785592100009, 12.755196006000078],
              [109.35680165800009, 12.757529480000061],
              [109.35544420000004, 12.76024017500008],
              [109.35434167400004, 12.763943719000091],
              [109.35408495200002, 12.765559057000074],
              [109.35393760000008, 12.766385611000073],
              [109.353908625, 12.767774335000047],
              [109.35426344500004, 12.772294183],
              [109.35446671300008, 12.774131759000083],
              [109.35506279900001, 12.780451451000106],
              [109.3553130220001, 12.783602231000122],
              [109.35540892000006, 12.786528844000065],
              [109.35540541700003, 12.78887440100007],
              [109.35523482600004, 12.791952843000052],
              [109.35481771300007, 12.795314343000095],
              [109.35445329500003, 12.797981226000042],
              [109.35415631000006, 12.799296647000078],
              [109.35378015500002, 12.800199748000106],
              [109.35309802900008, 12.801067282000121],
              [109.35180372500001, 12.801826135000026],
              [109.34991546000001, 12.802344834000044],
              [109.34747344700001, 12.802923340000037],
              [109.34502919100001, 12.803164063000059],
              [109.34399689600004, 12.803095575000077],
              [109.34265765100008, 12.802841370000094],
              [109.33712269000006, 12.800906151000023],
              [109.33528421900009, 12.800279771000065],
              [109.33126240600004, 12.798879018000074],
              [109.32739276800004, 12.797364653],
              [109.32472898400009, 12.796161657000125],
              [109.32396080500003, 12.795566010000046],
              [109.32323012400006, 12.794857534000046],
              [109.32276571500009, 12.793959741000014],
              [109.32225993800007, 12.79257434200005],
              [109.32202067600008, 12.79103716300005],
              [109.32194984100009, 12.788954758000058],
              [109.32197953200006, 12.787641066000075],
              [109.32204377500004, 12.785764242000129],
              [109.32218760800004, 12.784374784000095],
              [109.32287539500001, 12.781424502000059],
              [109.32293987100012, 12.77958521100002],
              [109.32273858700006, 12.77801028],
              [109.32238203400006, 12.776061046000018],
              [109.32179501300007, 12.773925610000079],
              [109.32100235700003, 12.772485723000047],
              [109.31999555100003, 12.770427975000052],
              [109.31807054900001, 12.768188296000103],
              [109.31726283800006, 12.767367718000065],
              [109.31605601200005, 12.766868598000068],
              [109.31341458000007, 12.76609693200008],
              [109.31127155300004, 12.765584845000076],
              [109.30939720000002, 12.765258701000091],
              [109.30675894200004, 12.764974802000049],
              [109.30203777500003, 12.764609868000052],
              [109.29680070800006, 12.764229241000089],
              [109.29511695000011, 12.763826754000094],
              [109.29419616200003, 12.763231943000084],
              [109.29365791800009, 12.762709853000052],
              [109.29319419799999, 12.761887074000098],
              [109.29189819800006, 12.759361878000062],
              [109.29015253100005, 12.755206982000098],
              [109.28972870300005, 12.7546466560001],
              [109.28640127900007, 12.751083049000032],
              [109.28559281700007, 12.750112258000089],
              [109.28424595300001, 12.748581818000025],
              [109.28286136600011, 12.747126665000062],
              [109.27784428700004, 12.742147164000082],
              [109.276997932, 12.741214110000064],
              [109.271978566, 12.735821720000027],
              [109.26966866000002, 12.732983569000032],
              [109.26781793600001, 12.730255224000063],
              [109.26667969200007, 12.72844200600005],
              [109.26621381800007, 12.727243950000043],
              [109.26589865200002, 12.725707255000083],
              [109.26557813900006, 12.723307488000033],
              [109.26470571500008, 12.72122999],
              [109.26315773700007, 12.718011994000021],
              [109.26126787400003, 12.715096237000088],
              [109.25959360300004, 12.713061047000094],
              [109.25747997700005, 12.711009700000096],
              [109.25582731400009, 12.709368381],
              [109.25467584500002, 12.70847460000004],
              [109.25390936500003, 12.708066364000013],
              [109.25237848100001, 12.707587603000102],
              [109.251229811, 12.707144091000087],
              [109.24757500200012, 12.706021146000078],
              [109.24378735100008, 12.70503026300007],
              [109.24126157900008, 12.704257065000133],
              [109.23904071600001, 12.703369479000058],
              [109.23697197100007, 12.702368397],
              [109.23300517300004, 12.700158797000059],
              [109.22993752300006, 12.698187814000049],
              [109.22813488700004, 12.696959950000062],
              [109.22679196700004, 12.69595456800001],
              [109.22569472400002, 12.694516199000104],
              [109.22449942300007, 12.692646866000066],
              [109.22368608100001, 12.690775312000111],
              [109.22238666700005, 12.687461854000047],
              [109.2222316470001, 12.68708749700007],
              [109.22134105900004, 12.685066298000079],
              [109.22033996000008, 12.683721180000072],
              [109.218763075, 12.681891558000034],
              [109.21766551600004, 12.680378118000045],
              [109.21662515100012, 12.678845595000075],
              [109.21547067900008, 12.677388766000062],
              [109.21176167800004, 12.673451207000051],
              [109.21049291800006, 12.672032542000087],
              [109.20964671600004, 12.671024222000058],
              [109.20898903400006, 12.669602055000071],
              [109.20867416500003, 12.668027829000026],
              [109.20849107900007, 12.666133896000085],
              [109.20850902600004, 12.662719088000072],
              [109.20831126600002, 12.658348661000039],
              [109.20810898700007, 12.656436095000066],
              [109.20771776700003, 12.654862324000058],
              [109.20721489600008, 12.653777010000054],
              [109.20679172900006, 12.653254088000041],
              [109.20556509000009, 12.65247308600008],
              [109.20357251600007, 12.651321208000075],
              [109.20087097300009, 12.649741804000046],
              [109.19872262200006, 12.648065433000076],
              [109.19638094700011, 12.64597738000006],
              [109.19339950000008, 12.642223115000116],
              [109.19162720200008, 12.639531429000071],
              [109.18989358400007, 12.636914565],
              [109.18694882600005, 12.632859858000066],
              [109.18456250400004, 12.62960874],
              [109.18321523400004, 12.62774013500003],
              [109.18194151900008, 12.62538331700007],
              [109.18085808700005, 12.622912870000034],
              [109.18062400100004, 12.622051154000056],
              [109.18063604000007, 12.620831602000047],
              [109.18101436900011, 12.620229131000114],
              [109.18154824300005, 12.620113575000056],
              [109.18260115100003, 12.620614239000092],
              [109.1836743530001, 12.621321163000053],
              [109.18493868500009, 12.622064529000097],
              [109.18635422100006, 12.622544392000099],
              [109.18765325700008, 12.622687190000102],
              [109.18944776800005, 12.622677099000066],
              [109.19036213900003, 12.62233424100012],
              [109.19173248800008, 12.621613587000089],
              [109.19294944700005, 12.620781227000053],
              [109.19486757500005, 12.619063108000093],
              [109.1964998660001, 12.617440397000033],
              [109.19801670500001, 12.615668248000043],
              [109.19926494300009, 12.613672479000025],
              [109.19963945100008, 12.61243212000007],
              [109.19978664500009, 12.611493223000101],
              [109.19978245, 12.610780322],
              [109.199606342, 12.610049629000029],
              [109.19925545300001, 12.60881339100006],
              [109.19722056700004, 12.603628101000107],
              [109.19601527500006, 12.599920244000094],
              [109.19498324700002, 12.596455305000108],
              [109.19443669500001, 12.594394697000054],
              [109.19374038800004, 12.589577096000058],
              [109.19361466600003, 12.587664209000058],
              [109.19337375900008, 12.585639415000037],
              [109.19298696900005, 12.584778605000045],
              [109.19221822600004, 12.583882433000058],
              [109.191106796, 12.583138268000125],
              [109.18987845000007, 12.581982032000026],
              [109.18923969700006, 12.580985664000098],
              [109.18881588000008, 12.579793015000094],
              [109.18869699000001, 12.579043255000053],
              [109.18873144700011, 12.578405202000019],
              [109.18903048000007, 12.577315413000088],
              [109.19029010300002, 12.574025224000083],
              [109.19100616300003, 12.5724453070001],
              [109.19183936300004, 12.571314984000102],
              [109.19301433700009, 12.569882560000066],
              [109.1941147160001, 12.568750722000031],
              [109.19835237400008, 12.565593746000035],
              [109.19979622900007, 12.564459935000103],
              [109.20568688000004, 12.560074019000032],
              [109.20701569900004, 12.558865779000071],
              [109.20746839900001, 12.557962704000134],
              [109.20757868100007, 12.557249186000073],
              [109.20765001500011, 12.555862983000042],
              [109.20780837400007, 12.55302058700005],
              [109.20737225200004, 12.548590995],
              [109.20693456800007, 12.543892815000033],
              [109.20678443700008, 12.541610492000109],
              [109.20677410600003, 12.539864602000071],
              [109.20731580200008, 12.539055677000109],
              [109.20840634200007, 12.538646537000096],
              [109.20950086900004, 12.538908879000042],
              [109.21005052000004, 12.539442950000089],
              [109.21088137600005, 12.541318450000103],
              [109.21269894100007, 12.548291878000038],
              [109.21312158700005, 12.550438344],
              [109.21328298200007, 12.554600893000066],
              [109.21316552800003, 12.557824927000103],
              [109.2118008850001, 12.56120218800003],
              [109.20965381100008, 12.564694714000078],
              [109.20898417900001, 12.566981769000039],
              [109.20885868900011, 12.568862796000067],
              [109.20996612000008, 12.571274006000086],
              [109.2131319600001, 12.575150802000044],
              [109.21657281400005, 12.579294607000067],
              [109.22029272, 12.584376927000028],
              [109.22366352900009, 12.588185279000095],
              [109.22751638600002, 12.592595192000109],
              [109.22902926700006, 12.594198138000044],
              [109.23040228300006, 12.595264643000091],
              [109.23135886500008, 12.595259072000074],
              [109.232583877, 12.594446057000129],
              [109.23271240400007, 12.593102196000048],
              [109.23186653000006, 12.588809178000114],
              [109.23103041400007, 12.586127844000126],
              [109.23074900800006, 12.584786382000066],
              [109.23156078000008, 12.583438555000013],
              [109.23292480400005, 12.583027669000034],
              [109.23648573, 12.584349964000106],
              [109.23921866300007, 12.584333957000114],
              [109.24112926100005, 12.583919813000042],
              [109.24235171200004, 12.582703849000064],
              [109.24247525600005, 12.5805541620001],
              [109.24219051900012, 12.57867550300009],
              [109.24163822500007, 12.577738588000019],
              [109.24026935200006, 12.577343695000119],
              [109.23643768100001, 12.576425979000037],
              [109.23547712900003, 12.575760052000094],
              [109.23410015300004, 12.574022080000089],
              [109.23257763100001, 12.57080756300004],
              [109.23076405800006, 12.564639968000078],
              [109.22970974600005, 12.559743892000073],
              [109.22885679100008, 12.55424228600006],
              [109.22821082700001, 12.549075270000019],
              [109.22805317600003, 12.545584247000095],
              [109.22802810600008, 12.541420943000057],
              [109.22828355100009, 12.538464770000067],
              [109.22919938400001, 12.53174426],
              [109.23067292900006, 12.526900776000092],
              [109.23322625500003, 12.519902181000118],
              [109.23578347500006, 12.513575081000015],
              [109.23726325800001, 12.509806004],
              [109.23794215700005, 12.509130527000066],
              [109.240400886, 12.509116106000116],
              [109.24367837400003, 12.508962532000021],
              [109.2465452430001, 12.508677041000025],
              [109.24995191100005, 12.507313914000038],
              [109.25498113000006, 12.503255101000031],
              [109.27448180500009, 12.487089687],
              [109.28215735300003, 12.491340775000113],
              [109.29163488800003, 12.478121915000083],
              [109.29164832599999, 12.469459752000132],
              [109.29145717600008, 12.467627676000069],
              [109.29123113700011, 12.46505748700004],
              [109.29085954200009, 12.463794602000087],
              [109.29037764900002, 12.462779920000065],
              [109.29001075900004, 12.4622596],
              [109.28953234200006, 12.461794961000104],
              [109.28832033400005, 12.460317182000045],
              [109.28751587700002, 12.459153199000104],
              [109.28717592900004, 12.458467691000051],
              [109.28692057500005, 12.457891678000086],
              [109.28593082300006, 12.453951006000084],
              [109.28437156700009, 12.448391137000046],
              [109.28394238000001, 12.446853592000105],
              [109.28342031000008, 12.443886454000095],
              [109.28328365200005, 12.442168361000048],
              [109.28355395800003, 12.440681574000044],
              [109.28446560100005, 12.436674392000045],
              [109.28504021400008, 12.434663204000083],
              [109.28651523800009, 12.43131267500006],
              [109.28712089400003, 12.429796352],
              [109.28789415600005, 12.428251503000086],
              [109.28939097200001, 12.426152192000115],
              [109.29029325500005, 12.425115346000036],
              [109.29065451200009, 12.424755604000024],
              [109.29193680100005, 12.424115216000063],
              [109.29405605000008, 12.423167168000047],
              [109.2947197230001, 12.42285936100007],
              [109.30045555600005, 12.421162616000101],
              [109.30579175600005, 12.420115642000072],
              [109.31206407100007, 12.419766936000086],
              [109.31539609800006, 12.419671714000023],
              [109.31781685699998, 12.419209678000074],
              [109.31876196900002, 12.418820254000094],
              [109.31990450200006, 12.418208076000068],
              [109.321239201, 12.417044640000084],
              [109.32198811300006, 12.416104885000044],
              [109.32352509100004, 12.413798839000021],
              [109.32432669400002, 12.412363708000028],
              [109.32576852400004, 12.410484526000063],
              [109.32660181400006, 12.409626742000055],
              [109.327325753, 12.409154658000075],
              [109.32853891300002, 12.408665748000123],
              [109.32951628500007, 12.408467081000117],
              [109.33080211600004, 12.408403984000048],
              [109.33222792600007, 12.408367492000048],
              [109.33435329900007, 12.408409073000113],
              [109.33547100500009, 12.408292003000019],
              [109.33630712700008, 12.407874186000088],
              [109.33715334700001, 12.406865016000078],
              [109.33793010100007, 12.405925028000107],
              [109.33837296000003, 12.405234678000097],
              [109.33928319200002, 12.403331290000084],
              [109.33964089400004, 12.402448963],
              [109.339787363, 12.401334220000047],
              [109.33988890500005, 12.399765979000053],
              [109.33991144800005, 12.398940788000077],
              [109.339735929, 12.397759337000112],
              [109.33903549800004, 12.395412394000081],
              [109.33846640000003, 12.393903421000106],
              [109.33798082900009, 12.392338922000066],
              [109.33738450800004, 12.390940127000059],
              [109.33673732100006, 12.39031168800002],
              [109.33597992900009, 12.389931463],
              [109.33515381600004, 12.389730428000066],
              [109.33451113500007, 12.389789487000067],
              [109.33333761100005, 12.389906903000012],
              [109.33124182600001, 12.390085134000104],
              [109.3296487530001, 12.390177680000123],
              [109.32855676600008, 12.389937033000107],
              [109.32718431100002, 12.389560646000051],
              [109.32621502400012, 12.38883795300003],
              [109.32531536900011, 12.388073568000022],
              [109.32458256600005, 12.387170619000097],
              [109.32418437500006, 12.386128073000078],
              [109.32409658200007, 12.385523593000068],
              [109.32426004300001, 12.384862541000084],
              [109.324758097, 12.384061885000095],
              [109.32506277600008, 12.383619953000034],
              [109.32549298600006, 12.383135984000136],
              [109.32702044100006, 12.381558833000026],
              [109.32810342400005, 12.38042447900005],
              [109.32846329100006, 12.379872194000095],
              [109.32862619600004, 12.379128638000035],
              [109.32856687900002, 12.378606493000087],
              [109.32826751700003, 12.377714594000098],
              [109.32703949400003, 12.374930590000053],
              [109.32635347300003, 12.374397769000021],
              [109.32602626100004, 12.374275822000056],
              [109.32510516800004, 12.374243107000014],
              [109.32360795900006, 12.374923909000033],
              [109.32184121200007, 12.376143519000047],
              [109.319131914, 12.379383259000052],
              [109.31818762200001, 12.381134831000027],
              [109.31684505800004, 12.384634585000052],
              [109.31549291600008, 12.386657270000077],
              [109.312914703, 12.389090433000137],
              [109.311011251, 12.390310819000126],
              [109.30871511700008, 12.390518489000078],
              [109.30704970500004, 12.389932518000089],
              [109.30485428200011, 12.388200404000125],
              [109.30265204800006, 12.385394029000095],
              [109.3008576930001, 12.382316564000105],
              [109.3000720050001, 12.379745415000086],
              [109.29922131500007, 12.374782187000031],
              [109.29827064700001, 12.361921606000069],
              [109.29817037200006, 12.356886710000124],
              [109.29787966599999, 12.35570633000007],
              [109.2974742430001, 12.354742506000081],
              [109.29183555300003, 12.348063126000051],
              [109.29146183700001, 12.347726061000051],
              [109.2907394050001, 12.347398436000033],
              [109.28996419300009, 12.34735558600004],
              [109.28882857800001, 12.34741010800011],
              [109.28378697900004, 12.348783607000067],
              [109.28153613400005, 12.349447393000011],
              [109.27970158700006, 12.350285471000076],
              [109.278700433, 12.351078001000102],
              [109.27807547700007, 12.352175227000115],
              [109.27767693600005, 12.353923273000065],
              [109.27741739500007, 12.356073313000058],
              [109.27684693900002, 12.358402993000082],
              [109.27668004100008, 12.358712449000018],
              [109.27597260300007, 12.359583186000032],
              [109.27548025500002, 12.359927760000032],
              [109.27504752800009, 12.360149767000015],
              [109.27461574300007, 12.360302762000062],
              [109.27415816000004, 12.360412291000065],
              [109.27380859300007, 12.360438126000071],
              [109.27332437900007, 12.360321710000031],
              [109.27285421100007, 12.360171023000087],
              [109.27075011700006, 12.359470548000038],
              [109.26856451900005, 12.359215124000043],
              [109.26528862200007, 12.359234779000071],
              [109.263517505, 12.359782506],
              [109.2612053730001, 12.361139130000074],
              [109.26060764400002, 12.361892261000039],
              [109.25985200700002, 12.363027137000058],
              [109.25864339800003, 12.366257093000053],
              [109.2582545970001, 12.369616440000096],
              [109.25839260300006, 12.37067335600006],
              [109.25867652400011, 12.371628149000101],
              [109.25916494200001, 12.372424506000089],
              [109.25977767100009, 12.373098691000093],
              [109.26114770100003, 12.373896203000088],
              [109.26208052800003, 12.374396952000042],
              [109.26237995100011, 12.37471119800002],
              [109.26279823600008, 12.375900572000107],
              [109.26287975800008, 12.376639542000055],
              [109.26280737600004, 12.377377631000018],
              [109.26250782300012, 12.378069789000088],
              [109.26213231000006, 12.378590203000071],
              [109.26063817700006, 12.379807675000086],
              [109.24869354700003, 12.391024190000087],
              [109.24618643800007, 12.394060406000039],
              [109.24428745300007, 12.396085876000116],
              [109.24348305700003, 12.398507750000041],
              [109.24336044200001, 12.40079133100007],
              [109.24269172600002, 12.403078122000098],
              [109.24147607200007, 12.405233856000029],
              [109.23917143400007, 12.407933119000079],
              [109.23360705600001, 12.413471466000113],
              [109.22667944900004, 12.419420476000107],
              [109.21561216500005, 12.429623273000077],
              [109.2066408270001, 12.436523506000084],
              [109.19779756300008, 12.44207976500009],
              [109.19037940600003, 12.446217593000062],
              [109.18439441400008, 12.450279992000011],
              [109.18044981100012, 12.452987927000068],
              [109.17556496700006, 12.457160770000096],
              [109.17487415500001, 12.453769773000099],
              [109.17380631600003, 12.451512486000114],
              [109.171969205, 12.448882264000092],
              [109.16844247200005, 12.444233929000069],
              [109.16119986200005, 12.435315397000045],
              [109.16056631600003, 12.434720007000076],
              [109.15985105900009, 12.43419121200011],
              [109.15917263100009, 12.43377339800009],
              [109.15821621300009, 12.433351491000087],
              [109.15770657200002, 12.433223566000072],
              [109.15677636700006, 12.43307648800007],
              [109.154667477, 12.433182322000034],
              [109.15399083400007, 12.43316557900007],
              [109.15322816100011, 12.43300158600009],
              [109.15275707500004, 12.432736370000084],
              [109.15149588100005, 12.431879453000059],
              [109.15017405500001, 12.430935747000136],
              [109.14985589600008, 12.430669330000031],
              [109.14964150200009, 12.430317788000076],
              [109.14915160100006, 12.429308037000018],
              [109.14907062400002, 12.428974226000021],
              [109.14902651300011, 12.428533848000088],
              [109.14906557800005, 12.428078148000042],
              [109.14918570500004, 12.427544666000044],
              [109.14942085500005, 12.426564625000045],
              [109.15166508600001, 12.42615725500009],
              [109.15214352900009, 12.426072224000071],
              [109.152753354, 12.425927592000095],
              [109.15398428600005, 12.425520500000074],
              [109.15489209200004, 12.425138723000105],
              [109.15523790300004, 12.42488955],
              [109.15567702500005, 12.424204176000051],
              [109.15627005900009, 12.423211794000098],
              [109.15652978600009, 12.422562721000039],
              [109.15660881200006, 12.422318122000052],
              [109.15708519100004, 12.42104725500006],
              [109.15715676500001, 12.420764371000102],
              [109.15721890800009, 12.420300305000074],
              [109.15707711200008, 12.419622696000053],
              [109.15679027500006, 12.419226952000082],
              [109.1564751610001, 12.418862153000097],
              [109.15607135900008, 12.418629789000056],
              [109.15441417300011, 12.41818435700006],
              [109.15378785500006, 12.418143793000079],
              [109.1534834760001, 12.418239233000071],
              [109.1523144330001, 12.419038189],
              [109.14901628300005, 12.421294827000027],
              [109.14854615000006, 12.421407481000111],
              [109.14763708200007, 12.421380499000099],
              [109.14701961800006, 12.421256150000113],
              [109.14649791200006, 12.420843953000114],
              [109.14584440500008, 12.420081286000071],
              [109.14005080400005, 12.411492782],
              [109.13995547900006, 12.411024332000052],
              [109.14016681200006, 12.410572457000056],
              [109.14845396300007, 12.406501040000071],
              [109.14992273600009, 12.406913733000112],
              [109.15114609900006, 12.407147454],
              [109.15261319400004, 12.407259646000044],
              [109.15450662600003, 12.40712911100008],
              [109.15713088100009, 12.406573872000058],
              [109.15945265800008, 12.405842299000101],
              [109.16454067300006, 12.404099213000029],
              [109.16661633900003, 12.403787267000064],
              [109.16851076200001, 12.40383689],
              [109.17211874200002, 12.404357773000106],
              [109.17566528400002, 12.404818841000063],
              [109.17794778100004, 12.405127651000035],
              [109.18084278900002, 12.405055735000035],
              [109.18217258500002, 12.404812565000077],
              [109.18588744300007, 12.402688339000088],
              [109.18893049500005, 12.40062793100005],
              [109.19355605100006, 12.397536904000093],
              [109.19602081400006, 12.395870303000043],
              [109.19760122300001, 12.394479124000044],
              [109.19881292499998, 12.392729455000049],
              [109.19908025299999, 12.391435862000042],
              [109.19907071900009, 12.389813318000085],
              [109.19851169200011, 12.388253990000029],
              [109.19684486400004, 12.385318736000082],
              [109.19591824100011, 12.383581206000114],
              [109.19492350200005, 12.380642144000118],
              [109.19454885800005, 12.379262062000061],
              [109.19447263900005, 12.376678390000071],
              [109.19447081300008, 12.376366424000105],
              [109.19444731500009, 12.372351669000102],
              [109.19424119200008, 12.36844665800003],
              [109.19400963100003, 12.365413178000038],
              [109.19393483000005, 12.363069910000133],
              [109.19410683600007, 12.361145908000102],
              [109.19446174800004, 12.359160791000077],
              [109.19524002800004, 12.35645214100005],
              [109.19663765900003, 12.349984126000011],
              [109.19759100800005, 12.345892346000118],
              [109.19878864700011, 12.341799201000113],
              [109.19978076600005, 12.339119423000076],
              [109.20102176800005, 12.337219432000078],
              [109.21348124600004, 12.326752161000075],
              [109.21779543900004, 12.323001600000081],
              [109.22089265600007, 12.32003920100002],
              [109.22587620700003, 12.315924082000061],
              [109.22855076600007, 12.313805325000081],
              [109.23180417800006, 12.311472845000091],
              [109.23369123100005, 12.310440275000099],
              [109.23606719100009, 12.309464909000043],
              [109.23899313200006, 12.308546390000073],
              [109.24002878600004, 12.308119676000027],
              [109.24066765000006, 12.307725325000083],
              [109.24096934700006, 12.307122646000044],
              [109.24084245500003, 12.306342207000041],
              [109.23997798200001, 12.304784919000065],
              [109.237518435, 12.302151504000046],
              [109.23486256300006, 12.299333070000083],
              [109.23329584100003, 12.297942303000029],
              [109.23261129100004, 12.29760310200003],
              [109.23144398700001, 12.297323519000079],
              [109.22961051200009, 12.297093837000082],
              [109.225825414, 12.297296128000122],
              [109.22173605200004, 12.297680373000029],
              [109.21801559000005, 12.298483049000096],
              [109.21669562000008, 12.298587541000037],
              [109.21518171800005, 12.298525341000042],
              [109.21404964200009, 12.298071554000066],
              [109.21364584100007, 12.297937352000064],
              [109.21162275300001, 12.29662697900009],
              [109.20809805300006, 12.294393788000056],
              [109.20582456600006, 12.292816214000089],
              [109.20453167000002, 12.291611121000033],
              [109.20355503300004, 12.290333607000024],
              [109.20206752100006, 12.286616504000046],
              [109.20118716300006, 12.282295053000068],
              [109.20096512600007, 12.280692373000067],
              [109.2008258650001, 12.278480071000073],
              [109.20107217000005, 12.277036997000044],
              [109.20245723100007, 12.27427113800008],
              [109.20365596600004, 12.272321198000034],
              [109.20434847200005, 12.270938262000096],
              [109.2043436650001, 12.27012344200009],
              [109.20402039, 12.269310439000057],
              [109.20321813300006, 12.268280777000127],
              [109.20212981900008, 12.26734676800003],
              [109.19970323900003, 12.266295001000048],
              [109.19734406200004, 12.265869636000025],
              [109.19486190300002, 12.26619710600003],
              [109.19238377100005, 12.267214005000058],
              [109.19003591500004, 12.268731590000035],
              [109.18851020800001, 12.269241634000048],
              [109.18329245800004, 12.270148481000119],
              [109.17670435000009, 12.270906140000102],
              [109.17377444300006, 12.270922452000031],
              [109.17284316700001, 12.270751927000044],
              [109.17294138500006, 12.270049549000104],
              [109.17350849700009, 12.268980822000051],
              [109.17458587000004, 12.268034620000011],
              [109.17636782000005, 12.267773977000028],
              [109.18025017200003, 12.267250858000088],
              [109.18330595900008, 12.266983031000043],
              [109.18559488400003, 12.266280698000019],
              [109.18749580500001, 12.264577640000061],
              [109.18984435300008, 12.263185441000067],
              [109.19767138200004, 12.261950254000096],
              [109.19651208900004, 12.259763019000046],
              [109.19592896900011, 12.258073963000092],
              [109.19544009900008, 12.256164996000061],
              [109.19511322200003, 12.254725208000126],
              [109.19490931600004, 12.252532571000073],
              [109.19488950800007, 12.249147981000073],
              [109.19493485400001, 12.246013756000094],
              [109.19525706000012, 12.241216982000045],
              [109.19581002600003, 12.237766513000013],
              [109.19702741700004, 12.233591493000072],
              [109.19966670400002, 12.227559395000041],
              [109.20282042500001, 12.222401860000074],
              [109.20623537000003, 12.218371005000071],
              [109.21054683500007, 12.215212452000033],
              [109.21308420700005, 12.213568275000062],
              [109.21458363100004, 12.212371724000091],
              [109.21531469900005, 12.211370021000077],
              [109.21521447800009, 12.210010380000112],
              [109.21446076500008, 12.207203601000048],
              [109.21389562100005, 12.205121197000055],
              [109.21194760400007, 12.202865386000015],
              [109.21065198700008, 12.201875338000077],
              [109.20880581000009, 12.201251158000035],
              [109.20732920200004, 12.200806203000079],
              [109.20511992000012, 12.201090874],
              [109.20337230000011, 12.201554245000066],
              [109.20061613800006, 12.202839470000091],
              [109.19942441500004, 12.203843732000051],
              [109.19732050400005, 12.206394741000091],
              [109.19640564800002, 12.20748809700005],
              [109.19484432600007, 12.208313067000097],
              [109.19254334100003, 12.208688805000099],
              [109.18849041300007, 12.20880235600008],
              [109.18614585500005, 12.209106660000122],
              [109.18315246600008, 12.209739154000053],
              [109.18158792400004, 12.210019964000105],
              [109.1807588030001, 12.210024605000045],
              [109.17918744900003, 12.209126578000083],
              [109.17788882100004, 12.207592260000053],
              [109.17723766700009, 12.206507726000025],
              [109.17704872399999, 12.205692649000087],
              [109.17667501400007, 12.204787933000103],
              [109.17611967200004, 12.204337620000059],
              [109.17482787100009, 12.203982094000041],
              [109.17289539000006, 12.204355565000034],
              [109.17060429500003, 12.206453938000015],
              [109.16863428800011, 12.208323812000097],
              [109.16725553400008, 12.208875531],
              [109.166149014, 12.208700282000072],
              [109.16531782800007, 12.208342151000105],
              [109.16485047900002, 12.207165885000036],
              [109.16487243500006, 12.205896486000077],
              [109.16487686400008, 12.205640435000118],
              [109.16813424200004, 12.201434864000047],
              [109.1689358190001, 12.196624390000109],
              [109.17225000700005, 12.196243278000017],
              [109.1740143330001, 12.198681832000078],
              [109.17651462100012, 12.20093490300007],
              [109.18039731700003, 12.203270908000084],
              [109.18261505900003, 12.20443734200005],
              [109.18492285400002, 12.205240518000069],
              [109.18616624400005, 12.205188199000093],
              [109.18818871200006, 12.204451369000056],
              [109.19369113100005, 12.200158347000032],
              [109.20376929200009, 12.190761088000011],
              [109.20624356700009, 12.188570664000052],
              [109.20734037100007, 12.187113531000103],
              [109.207514406, 12.18538963900002],
              [109.2072096630001, 12.180585416000065],
              [109.20673570700005, 12.178321179000038],
              [109.20608076300003, 12.176602044000083],
              [109.20529113900007, 12.175473082000019],
              [109.20418369900001, 12.175116694000019],
              [109.20224780900006, 12.174855698],
              [109.20058878600003, 12.174683794000083],
              [109.19929607400009, 12.174147076000084],
              [109.19735382000005, 12.1727979430001],
              [109.19633160300003, 12.171262231000044],
              [109.19604518900005, 12.169540988000092],
              [109.19644506900009, 12.167045102000083],
              [109.19717287500001, 12.165499464000094],
              [109.19863909499999, 12.164221673000062],
              [109.20286145800003, 12.161749373000051],
              [109.2101230800001, 12.159259594000014],
              [109.21618612500002, 12.15659512600009],
              [109.21884730000004, 12.15494761300002],
              [109.21994491700008, 12.153671808000064],
              [109.22057877200004, 12.151854634000079],
              [109.22124646200004, 12.147997071000114],
              [109.22123399300007, 12.145911611000047],
              [109.22117240400003, 12.144778557000089],
              [109.22113052200004, 12.144008045000108],
              [109.2207545520001, 12.142740774000059],
              [109.22001292500002, 12.141928997000038],
              [109.21871981900006, 12.141301748],
              [109.21625011800008, 12.140850237000043],
              [109.21448203900007, 12.1402315490001],
              [109.21390273400002, 12.139667819000087],
              [109.21277328200009, 12.138008762000128],
              [109.21095640600004, 12.135021462000049],
              [109.2102507370001, 12.132709081000124],
              [109.210080061, 12.127259661000076],
              [109.20951280300005, 12.124946512000088],
              [109.20887077100005, 12.123576160000125],
              [109.20771086100008, 12.122205686000063],
              [109.2060535200001, 12.120189990000084],
              [109.20530833300008, 12.119384187000025],
              [109.20464684400005, 12.118820915000049],
              [109.20415276000003, 12.118742735000064],
              [109.20102850400008, 12.119084565000014],
              [109.19931587400004, 12.119196548000042],
              [109.19803972600005, 12.119203799000021],
              [109.19664527400005, 12.119188446000082],
              [109.1959352170001, 12.119006308000023],
              [109.19512953300004, 12.118638553000059],
              [109.19437030500002, 12.118130911000067],
              [109.19394111900004, 12.117481778000093],
              [109.19326880800001, 12.115670508000063],
              [109.192786664, 12.114044330000072],
              [109.19254165100006, 12.112556441000065],
              [109.19227042600009, 12.11062655800008],
              [109.19216721700008, 12.109137865],
              [109.19206046900004, 12.107044182000058],
              [109.19204037300007, 12.103600366000043],
              [109.19216586100006, 12.100807290000136],
              [109.19233738100004, 12.093755628000034],
              [109.19246937800003, 12.092079483000083],
              [109.19299423700002, 12.088888600000034],
              [109.19354718800004, 12.086465467],
              [109.19447165900006, 12.082923300000024],
              [109.19539935600008, 12.079939585000064],
              [109.19683620700003, 12.075138004000062],
              [109.19790560400006, 12.072153499000125],
              [109.19832187900003, 12.070615390000048],
              [109.19887472700003, 12.06819228300011],
              [109.19966163000004, 12.065395551000078],
              [109.20031196100003, 12.063483811000085],
              [109.20126708000001, 12.061174774000081],
              [109.2028558210001, 12.058140791000048],
              [109.20530725300007, 12.053170602000039],
              [109.20698678900004, 12.049531122000088],
              [109.20867464400007, 12.046200915000059],
              [109.21060012200005, 12.042352104000107],
              [109.21221972800002, 12.038808039000058],
              [109.21409110000005, 12.0345050820001],
              [109.21616625700007, 12.029998965000031],
              [109.2175181060001, 12.027159963000054],
              [109.21768524800007, 12.026808950000055],
              [109.22115775500006, 12.020249683000104],
              [109.2237418980001, 12.015185162000106],
              [109.22716608500008, 12.009181599000039],
              [109.22914486200003, 12.005786924000093],
              [109.23023674500004, 12.004088991000026],
              [109.23155789600003, 12.002162479000054],
              [109.23257362600003, 12.000957651000109],
              [109.2330134370001, 12.000309341000024],
              [109.23692529200012, 11.995165146000096],
              [109.24484634700003, 11.981917152000053],
              [109.25026284500002, 11.976422521000083],
              [109.25223055300003, 11.975841820000086],
              [109.25409766100006, 11.977765390000082],
              [109.25549609700003, 11.978895101000118],
              [109.25770552300006, 11.979906166000108],
              [109.26117714100006, 11.978747396000065],
              [109.26672810900007, 11.976324246000035],
              [109.26851831100005, 11.975175445000017],
              [109.26977469000009, 11.972095195000064],
              [109.27009355200009, 11.967427367000115],
              [109.26923212600005, 11.957012495000095],
              [109.26969657700002, 11.954415431000063],
              [109.2701709090001, 11.953410249000028],
              [109.27160598100008, 11.952340312000052],
              [109.27324225000002, 11.951703787000055],
              [109.27478561900008, 11.951672546000045],
              [109.27743751700004, 11.953071541000069],
              [109.28003269900009, 11.95500149800006],
              [109.28138362200005, 11.955751285000103],
              [109.28226157900004, 11.955990290000072],
              [109.28288942800003, 11.955514521000053],
              [109.28327537800007, 11.954804905000048],
              [109.28322724500001, 11.953919260000044],
              [109.28258389600006, 11.949915403000054],
              [109.28162139300009, 11.947442409000073],
              [109.28118236500009, 11.946740070000049],
              [109.2803375460001, 11.946084613000084],
              [109.27732037500003, 11.945647716000083],
              [109.27511832099999, 11.945774843000033],
              [109.27430109800005, 11.944869388000077],
              [109.27356940400009, 11.942202676000056],
              [109.27321741000002, 11.940748590000092],
              [109.27302646700011, 11.939791252000107],
              [109.27299751800003, 11.937954673000116],
              [109.27295700600004, 11.936600750000091],
              [109.27279364600003, 11.936129355000078],
              [109.27223266900006, 11.935720126000058],
              [109.27014099100002, 11.934936149000029],
              [109.26944910500006, 11.934858346000075],
              [109.26913005000004, 11.935143688000059],
              [109.26824308700002, 11.936975573000018],
              [109.26768595000001, 11.937451306000034],
              [109.26641831100002, 11.937616385000103],
              [109.26536734400003, 11.937465235000031],
              [109.26369828800003, 11.936398293000126],
              [109.26043540500007, 11.933686672000119],
              [109.25762933000006, 11.929834315000052],
              [109.25644418200005, 11.925630881000069],
              [109.25606355200009, 11.920284713000038],
              [109.25730158500004, 11.914246161000095],
              [109.25865406500003, 11.907979367000097],
              [109.26082624700007, 11.903073233000059],
              [109.26220734000003, 11.901471851000077],
              [109.2646308970001, 11.89975042800002],
              [109.26601405100006, 11.898490396000037],
              [109.266816245, 11.897006268000075],
              [109.2678340090001, 11.89290359800008],
              [109.26966234700004, 11.888682282000039],
              [109.27057825500005, 11.886856093000107],
              [109.27218743000006, 11.884684344000094],
              [109.27253229600008, 11.884227097000098],
              [109.27519161600003, 11.883186905000096],
              [109.27704032600005, 11.882265383000055],
              [109.27830809900004, 11.881119782000036],
              [109.27847554200004, 11.880094651000126],
              [109.27846060000005, 11.877705139000037],
              [109.27692416600011, 11.875146518000077],
              [109.27194747200009, 11.869751543000094],
              [109.27013229600001, 11.866676502000038],
              [109.26986790400008, 11.865418513000046],
              [109.27037204100004, 11.863966959000026],
              [109.27005025400007, 11.861247007000129],
              [109.26926287300002, 11.860950659000123],
              [109.26819464600001, 11.861030609000077],
              [109.26189489800004, 11.862317453000058],
              [109.26093434000005, 11.862575121000061],
              [109.26023305100003, 11.863272079000085],
              [109.25903159400009, 11.865760378000088],
              [109.25753132900003, 11.866793433000032],
              [109.25556259000004, 11.867032753000027],
              [109.25404972700009, 11.866017661000059],
              [109.25264998900009, 11.864546724000123],
              [109.25186270000006, 11.861672805000099],
              [109.25112446200008, 11.860083905000083],
              [109.25040965700001, 11.856902086000074],
              [109.24983032500003, 11.8550571360001],
              [109.24882455900004, 11.854311276000116],
              [109.24746968200002, 11.854261475000081],
              [109.24623505800001, 11.854615764000085],
              [109.24582774100006, 11.855427828000041],
              [109.24563221000004, 11.856702968000119],
              [109.24565990000011, 11.858557376000077],
              [109.2462566610001, 11.861398547000055],
              [109.24638780600003, 11.863901122000085],
              [109.24512273199998, 11.865501636000085],
              [109.24367757600005, 11.866079111000081],
              [109.24159016500009, 11.865863845000122],
              [109.23974076300001, 11.866671256000048],
              [109.23882535900003, 11.868611050000053],
              [109.23815481200006, 11.872711412000035],
              [109.23712622600004, 11.875107037000097],
              [109.23506073300004, 11.878532864000045],
              [109.23240469800001, 11.880141483],
              [109.22975272800005, 11.882432790000051],
              [109.22500495100006, 11.883143179000031],
              [109.22106639700006, 11.883507407000058],
              [109.21654967600008, 11.88410247800009],
              [109.21226204100006, 11.884240979000092],
              [109.20912666400008, 11.883234862000068],
              [109.20447927900004, 11.881327027000042],
              [109.20285401400004, 11.880881145],
              [109.20147059000006, 11.882140736000045],
              [109.20089911000011, 11.883509485000053],
              [109.20195358200006, 11.885437923],
              [109.20348240400007, 11.889184319000011],
              [109.2047774530001, 11.892590688000036],
              [109.20537576800005, 11.895773466000035],
              [109.20526791100011, 11.897139597000093],
              [109.20482976700001, 11.901466238000056],
              [109.20461403000009, 11.904198519000085],
              [109.20463931100005, 11.905837230000047],
              [109.20424892900007, 11.907948530000033],
              [109.20479611200005, 11.909659029000073],
              [109.20655644800004, 11.912153490000053],
              [109.21001830800004, 11.918526841000075],
              [109.210546908, 11.919908065000049],
              [109.21095722800001, 11.921768715000079],
              [109.21128922100004, 11.925312059000069],
              [109.21044657600004, 11.925642795000078],
              [109.2097903060001, 11.925382749000056],
              [109.20783501800005, 11.92400985600006],
              [109.20655854700004, 11.92388534100006],
              [109.20555414800008, 11.924352455000074],
              [109.20450892000009, 11.925706429000103],
              [109.20301329700007, 11.927649522000115],
              [109.20314361000001, 11.928321002000073],
              [109.20360095700002, 11.929011732000026],
              [109.20511336500002, 11.929913469000029],
              [109.20651089000009, 11.930489078000049],
              [109.20846684800001, 11.930442610000023],
              [109.20975515400004, 11.930787577000055],
              [109.21063113900004, 11.931309833000102],
              [109.21149204800004, 11.932800383000062],
              [109.21257112100005, 11.936357171000099],
              [109.21356097400007, 11.938477248000099],
              [109.21404548300009, 11.940913167000121],
              [109.21354044600005, 11.943407109000058],
              [109.21193355900006, 11.946147534000049],
              [109.20662403700004, 11.950160978000033],
              [109.2039668470001, 11.951769364000063],
              [109.2039722100001, 11.952679734000069],
              [109.20467992000003, 11.954724103000023],
              [109.20572472600006, 11.954945736000065],
              [109.207813, 11.955161380000055],
              [109.21024910700004, 11.955375015000056],
              [109.21141053600005, 11.955709740000129],
              [109.2119983330001, 11.957071971000042],
              [109.21294680200006, 11.960594351000074],
              [109.21336151700008, 11.971858367],
              [109.21338651400002, 11.976068942000049],
              [109.21270632600005, 11.978690337000051],
              [109.21016207200003, 11.979842987000032],
              [109.20910395400006, 11.980201786000054],
              [109.20879874400003, 11.981002827000074],
              [109.209157964, 11.982713813000066],
              [109.20940150500007, 11.984539676000059],
              [109.20964572600003, 11.986479738000087],
              [109.2095401780001, 11.988307611000069],
              [109.20908146400006, 11.9894522870001],
              [109.2085043550001, 11.990255022000083],
              [109.20769451200006, 11.991059097000088],
              [109.206777055, 11.993348441000061],
              [109.20574253600003, 11.995524245000054],
              [109.20505575400004, 11.997469668000081],
              [109.20448703200006, 12.000000176000112],
              [109.20404903500005, 12.003136216000064],
              [109.20395689800007, 12.006841579000048],
              [109.20431732500006, 12.009983023000091],
              [109.20490703100009, 12.013347719000043],
              [109.20525953900002, 12.015142005000088],
              [109.20493019900005, 12.01727700200008],
              [109.20415193800005, 12.020420820000053],
              [109.20403957700007, 12.020874708000038],
              [109.202579703, 12.024587950000052],
              [109.20145841500003, 12.02773791400009],
              [109.20056307700003, 12.030549782000042],
              [109.20034764300004, 12.032684160000072],
              [109.20041947200005, 12.035209861000061],
              [109.200442556, 12.039139241000017],
              [109.19977380400009, 12.041725310000027],
              [109.19807717500004, 12.043980404000097],
              [109.19636932400005, 12.04432692000008],
              [109.19431818900006, 12.044450816000028],
              [109.193292302, 12.044456623000064],
              [109.19249568700008, 12.044685670000097],
              [109.19204432000004, 12.045474136000054],
              [109.19013332300001, 12.050088122000078],
              [109.18810823900006, 12.054702757000067],
              [109.18703475300006, 12.056336775000085],
              [109.18464542100007, 12.057136133000055],
              [109.18282344500004, 12.057483185000082],
              [109.18225995900005, 12.05860908800007],
              [109.18161298899999, 12.065012373000082],
              [109.1814168060001, 12.070514975],
              [109.18108842600002, 12.072874618000105],
              [109.17996913500005, 12.076473724],
              [109.17834227499999, 12.08103004100005],
              [109.17711016700011, 12.084854355000106],
              [109.17714962800008, 12.091703096000026],
              [109.17670456600003, 12.093614321000066],
              [109.1754613690001, 12.095529990000029],
              [109.17387545900007, 12.097335284000097],
              [109.17336786400008, 12.098292485000071],
              [109.17325899800005, 12.099191329000101],
              [109.17338011600003, 12.100425728000095],
              [109.174758029, 12.102102255000078],
              [109.17945231800009, 12.105444461000035],
              [109.17957606200011, 12.107127975000063],
              [109.179824862, 12.110719570000049],
              [109.1796058850001, 12.112292730000094],
              [109.17835872500002, 12.113534791000101],
              [109.17290541800001, 12.117045903000088],
              [109.17029304800005, 12.118856926000097],
              [109.16830170400004, 12.119597802000024],
              [109.16488271000003, 12.11995354800006],
              [109.16077761400001, 12.119976175000067],
              [109.15894293300009, 12.118189754000051],
              [109.1566426360001, 12.114721653000069],
              [109.15526160300006, 12.112483597000077],
              [109.15468322600007, 12.111027108000092],
              [109.15284171000012, 12.108005605000114],
              [109.15123081700006, 12.105431952000066],
              [109.15110920500003, 12.104085249000105],
              [109.15155706200005, 12.102623172000014],
              [109.15348274400004, 12.100367058000037],
              [109.15637657100002, 12.097937211000074],
              [109.16126211800005, 12.094878842000066],
              [109.16546920400006, 12.092834619000124],
              [109.16944881600008, 12.090903854000066],
              [109.17137933000004, 12.089545807000055],
              [109.172850548, 12.087628898000078],
              [109.17339095100004, 12.082461122000064],
              [109.174051773, 12.07841547100008],
              [109.17449425500011, 12.076055190000099],
              [109.17512056700006, 12.073550299000056],
              [109.17554662700005, 12.070716211000041],
              [109.17710320900005, 12.063858719000018],
              [109.17844529899999, 12.059360235000048],
              [109.17889286200008, 12.057898163000045],
              [109.17888832800006, 12.057112272000035],
              [109.17865710400011, 12.056552194000057],
              [109.177057287, 12.055887485000126],
              [109.17625932600005, 12.05589193700005],
              [109.175691941, 12.056344198000078],
              [109.17467695800003, 12.058258512000107],
              [109.17401942900008, 12.062865430000079],
              [109.17335026600004, 12.065451484000087],
              [109.17255806600005, 12.066466369000038],
              [109.17187470500008, 12.066582440000049],
              [109.17107477100006, 12.066250062000082],
              [109.17038112000004, 12.064569784000055],
              [109.16990133800005, 12.060418271000104],
              [109.17008303500008, 12.052333524000057],
              [109.17068430000009, 12.047897527000105],
              [109.17135501600006, 12.045533939000029],
              [109.17316272500007, 12.042717078000107],
              [109.17565816600005, 12.040570008000094],
              [109.17963342300007, 12.038077832000036],
              [109.18395183500006, 12.035808200000096],
              [109.18713228700004, 12.033881702000125],
              [109.18984955500008, 12.030722795000058],
              [109.191944119, 12.028297148000087],
              [109.19375860600003, 12.026715096000105],
              [109.19591828200002, 12.025692425000043],
              [109.19807266200011, 12.023771608000098],
              [109.19964791100006, 12.020282290000049],
              [109.20049833300008, 12.018772724000113],
              [109.20066313100004, 12.018480193000068],
              [109.20076852700006, 12.017020098000051],
              [109.19985737400008, 12.007426320000112],
              [109.19869333500004, 12.003279038000068],
              [109.19769974600007, 12.00115267100008],
              [109.19626927600002, 11.998509723000071],
              [109.19597442300005, 11.996779038000048],
              [109.19659808200005, 11.99397747500006],
              [109.19705552900007, 11.992604424000051],
              [109.19826936800004, 11.991227069000042],
              [109.20007004800009, 11.990702914000016],
              [109.20181153900003, 11.990007771000053],
              [109.20349012, 11.988513538000054],
              [109.20435176600006, 11.986624252000054],
              [109.20469446700008, 11.985537361000096],
              [109.20474658900005, 11.984509223000115],
              [109.20468437000005, 11.983824358000026],
              [109.20426969700003, 11.982570488000109],
              [109.20415030000001, 11.982057261000099],
              [109.20333414500007, 11.982158143],
              [109.20124894400001, 11.982511447000109],
              [109.19961554300005, 11.980813684000056],
              [109.19797882600008, 11.978546915],
              [109.19656339700002, 11.974458033000078],
              [109.1920289, 11.962420675000105],
              [109.18687181200008, 11.9528905000001],
              [109.18487379400005, 11.948235936000094],
              [109.1849785150001, 11.946300759000026],
              [109.1858974190001, 11.944816205000029],
              [109.18750464400003, 11.942075983000054],
              [109.18668452100003, 11.940601212000066],
              [109.18517212500004, 11.939699331000051],
              [109.18308205700004, 11.939142080000041],
              [109.1806461730001, 11.938928135000102],
              [109.18029575300007, 11.938474903000083],
              [109.17819718400007, 11.936438280000059],
              [109.17556846600009, 11.932925221000088],
              [109.17256027500005, 11.927938092000099],
              [109.16854317100004, 11.920788019000026],
              [109.16689817600007, 11.91729040700009],
              [109.16628364600004, 11.912575947000013],
              [109.1659344880001, 11.910560896000087],
              [109.16499484300007, 11.908403995000059],
              [109.16335246000007, 11.906955756000047],
              [109.16296891000005, 11.906825969000055],
              [109.16258012400003, 11.906745060000022],
              [109.16178958299999, 11.906657232000038],
              [109.16136963300006, 11.906651074000061],
              [109.16097051900007, 11.906761471000047],
              [109.16063234100008, 11.90702891400006],
              [109.16033163900006, 11.907321674000036],
              [109.16005685500006, 11.90762252800006],
              [109.15904618300002, 11.908840426000021],
              [109.15821217400003, 11.909702235000095],
              [109.15763715600005, 11.910264343000064],
              [109.15733410700003, 11.910541708],
              [109.15701120100005, 11.910808999],
              [109.15665565200003, 11.911031394000114],
              [109.15627847200008, 11.911193208],
              [109.15589280600005, 11.911281446000109],
              [109.15549817800009, 11.911272115000047],
              [109.15509600500012, 11.911176656000054],
              [109.15469866300002, 11.911029985000086],
              [109.15357289000004, 11.910534890000067],
              [109.15321672700006, 11.910352719000093],
              [109.1528705540001, 11.91015218100002],
              [109.15253466700003, 11.909927329000068],
              [109.15220934600011, 11.909672288000104],
              [109.15189767000007, 11.909384026000057],
              [109.15161663800009, 11.90907920500006],
              [109.15136412400007, 11.908762741000077],
              [109.15113414600005, 11.908436153000055],
              [109.15071787800007, 11.907758697000046],
              [109.15010182300009, 11.906694677000116],
              [109.14990373300003, 11.906293106000108],
              [109.150008638, 11.905954888000013],
              [109.15029240000004, 11.905688972000037],
              [109.15063502300005, 11.905464633000038],
              [109.15138471500009, 11.905075031000043],
              [109.15175378400008, 11.904899178000059],
              [109.1528791150001, 11.904456723000044],
              [109.15324584000007, 11.904266462000111],
              [109.15358496100006, 11.904021971000043],
              [109.15386992500001, 11.903726031000071],
              [109.15409702700006, 11.903390320000094],
              [109.15426137300007, 11.903025093000073],
              [109.15434673700003, 11.902628039000083],
              [109.15435413300003, 11.902212478000072],
              [109.1542895740001, 11.901796696000043],
              [109.15415914600005, 11.901398996000088],
              [109.15397154300007, 11.901035407000025],
              [109.15376443800008, 11.900689656000017],
              [109.15331665800007, 11.90002616],
              [109.15307202200007, 11.899706789000051],
              [109.15281092100005, 11.899394559000063],
              [109.15225637800009, 11.898804010000051],
              [109.15197080000009, 11.89853170200008],
              [109.15166696000009, 11.898267477000067],
              [109.15133455100002, 11.898005362000051],
              [109.15062863300005, 11.89749367400008],
              [109.14990174300004, 11.897017489000026],
              [109.14953266700006, 11.896797623000042],
              [109.14878770000006, 11.896404133000081],
              [109.1484135960001, 11.896234427000072],
              [109.14803959900007, 11.896085375000085],
              [109.14766660300003, 11.895958926000015],
              [109.14729550100006, 11.895857057000024],
              [109.14692717600005, 11.895781720000096],
              [109.14656252200007, 11.895734873000039],
              [109.14619897300001, 11.89576207600007],
              [109.14583840200001, 11.895930395000052],
              [109.14549569899999, 11.896205854000106],
              [109.14518602600003, 11.896551913000051],
              [109.14492716100003, 11.896923319000051],
              [109.14472056500007, 11.897300152000055],
              [109.14455808800004, 11.897681389000068],
              [109.14443159200006, 11.898066033000097],
              [109.14433291100006, 11.898453033000054],
              [109.14405329400002, 11.900004339000068],
              [109.14397140100009, 11.900387899000096],
              [109.14386840300008, 11.90076771000008],
              [109.14373614300007, 11.901142756000114],
              [109.14327047900008, 11.902255297000062],
              [109.14227920700004, 11.90484145800008],
              [109.14183909000005, 11.905933189000082],
              [109.14135456900001, 11.907007266000045],
              [109.140994839, 11.907710882000087],
              [109.14080124000006, 11.908058365000061],
              [109.14038206600002, 11.908743701000011],
              [109.14015469700004, 11.909081157000044],
              [109.13965982900004, 11.909744663000053],
              [109.13939052600004, 11.910070316000086],
              [109.13880369300007, 11.910708441000038],
              [109.13848434900004, 11.911020524000014],
              [109.13778927900007, 11.911629715000059],
              [109.13741224000006, 11.911900499000081],
              [109.13701851100006, 11.912082644000053],
              [109.13661275100007, 11.912187057000077],
              [109.13619961400008, 11.912227824000075],
              [109.13578376600005, 11.912219013000056],
              [109.13536986300008, 11.912174729000034],
              [109.13496256900005, 11.912109047000017],
              [109.13384731100005, 11.911888142000036],
              [109.13045297800005, 11.908705855000077],
              [109.130200121, 11.908289606],
              [109.12623144700008, 11.901734856000077],
              [109.12430158800001, 11.897172812000088],
              [109.12389282400002, 11.89625849700011],
              [109.12204287700008, 11.892694565000015],
              [109.12023366800004, 11.890227073000046],
              [109.11920880300006, 11.888948577000045],
              [109.11900438300003, 11.888753137000039],
              [109.11883895100001, 11.888527804000136],
              [109.11872064400009, 11.888267057000055],
              [109.11861159000003, 11.887844483],
              [109.11855308900007, 11.887421832000109],
              [109.11853856300003, 11.886999101000038],
              [109.11857626400004, 11.886435278000105],
              [109.11869315300004, 11.885730102000112],
              [109.11882352900008, 11.885165562000031],
              [109.11918488800004, 11.883752218000064],
              [109.11927629100006, 11.883327533000097],
              [109.11934814400004, 11.8829024810001],
              [109.1193938960001, 11.882477043000071],
              [109.11940665100009, 11.882193183000121],
              [109.11939959500005, 11.880914532000107],
              [109.11936040000009, 11.879777954000035],
              [109.11927769099999, 11.878928269000076],
              [109.11911876300007, 11.87794753400002],
              [109.11887904000005, 11.876831900000083],
              [109.11868163100006, 11.876150312000087],
              [109.11847089100003, 11.875621837000059],
              [109.118205389, 11.875104505000065],
              [109.11781520900004, 11.874497090000078],
              [109.11439403200004, 11.868195506000044],
              [109.11282823400006, 11.862514446000104],
              [109.11282313300009, 11.860954093000052],
              [109.11276472500006, 11.860242627000071],
              [109.11263970100001, 11.859393119000037],
              [109.11248367900006, 11.85869274],
              [109.11227500700004, 11.858002849000025],
              [109.11195388800012, 11.857195033000066],
              [109.11131552900005, 11.855769147000037],
              [109.11024223000007, 11.85343991000005],
              [109.11001202300011, 11.853074590000022],
              [109.10945845700004, 11.852394626000072],
              [109.10926021600008, 11.852106697000051],
              [109.10911323099999, 11.851803981000051],
              [109.10899616000009, 11.851420933000066],
              [109.10893313500002, 11.851020811000089],
              [109.10892015800002, 11.850606905000037],
              [109.10895326000008, 11.85018253],
              [109.10906212100012, 11.849606113],
              [109.10918715300008, 11.849170158000087],
              [109.10940412200009, 11.84859029500007],
              [109.10966984500004, 11.848019225000071],
              [109.10997486300006, 11.847464792000027],
              [109.11022373200004, 11.847064587000132],
              [109.11057067300007, 11.846552588000046],
              [109.11102263400007, 11.845950978000095],
              [109.11131484300009, 11.845625826000104],
              [109.11152250300003, 11.845429502000025],
              [109.11174273700004, 11.845252624000045],
              [109.11197751600011, 11.845097833000027],
              [109.11222879400003, 11.844967782000102],
              [109.11249855000005, 11.844865109000045],
              [109.11306508700004, 11.844729501000105],
              [109.11349156300007, 11.844663731000081],
              [109.11420561500009, 11.844578846000074],
              [109.11492541900004, 11.84454147100009],
              [109.11528329400008, 11.844490483000046],
              [109.11567273200006, 11.844315226000022],
              [109.11594725300003, 11.844070992000065],
              [109.11616990200007, 11.843755031000034],
              [109.11628322500003, 11.843473417],
              [109.11631446200005, 11.843185196000137],
              [109.11626529200007, 11.842905827000026],
              [109.11630764600001, 11.841576634000093],
              [109.11632945100004, 11.841149272000083],
              [109.11641742100004, 11.840418105000071],
              [109.11631882700004, 11.839203138000086],
              [109.11614722900006, 11.838111465000088],
              [109.11560185800005, 11.835763832000088],
              [109.11563447900009, 11.83455383400007],
              [109.11600126900007, 11.833360223000076],
              [109.11666099600002, 11.832239907000107],
              [109.11719086800008, 11.831484402000035],
              [109.11815155900007, 11.830245307],
              [109.12060173100012, 11.827619958000033],
              [109.12227227600005, 11.825923911000034],
              [109.12496520400002, 11.823750860000091],
              [109.13154499200007, 11.820501455000086],
              [109.13268945400009, 11.820067663000076],
              [109.13385162600005, 11.819693990000067],
              [109.13503435200003, 11.819381181000031],
              [109.13624521100004, 11.81914059700002],
              [109.137464177, 11.818998701000092],
              [109.13990479400005, 11.818843044000092],
              [109.14112976800007, 11.818833627000023],
              [109.14234962100001, 11.819089864000086],
              [109.14346322400004, 11.819662200000012],
              [109.14441321300005, 11.820426797000037],
              [109.14524680300009, 11.821324908000095],
              [109.14601127300003, 11.822297813000125],
              [109.14674882400003, 11.823288946000064],
              [109.1474656770001, 11.824277275000037],
              [109.14815771200007, 11.825270273000042],
              [109.14882129900009, 11.826276186000049],
              [109.14945287400012, 11.82730325100003],
              [109.15004884600005, 11.828359752000049],
              [109.15059872000006, 11.829442271000088],
              [109.15111284300006, 11.830536271000101],
              [109.15160538200004, 11.83163702200005],
              [109.15201023400006, 11.83277261800013],
              [109.15235115400003, 11.833934377000064],
              [109.15267534600011, 11.835104038000031],
              [109.15302999500005, 11.836263343000029],
              [109.15407570300007, 11.838404770000109],
              [109.15655903100003, 11.842404157000084],
              [109.15883499900005, 11.846965131000074],
              [109.15968716600003, 11.84978982000011],
              [109.16005585100007, 11.852601764000068],
              [109.15967843600002, 11.856023900000066],
              [109.15994543400008, 11.85645535200006],
              [109.16074152800005, 11.856883890000079],
              [109.16490650700004, 11.854243223000081],
              [109.16575334800004, 11.85430200400006],
              [109.16624104900006, 11.854775497000068],
              [109.16736210600001, 11.858059509],
              [109.16803978400003, 11.860913018000117],
              [109.16877087600004, 11.864196999000052],
              [109.1692094970001, 11.865318961000069],
              [109.17092977800006, 11.86704001],
              [109.17866771300001, 11.871421895000099],
              [109.17959808600007, 11.872214793000067],
              [109.18054366700007, 11.872988849000075],
              [109.18146733400002, 11.873779661000048],
              [109.18233193700006, 11.874622716000081],
              [109.18311688100007, 11.875525816000019],
              [109.18390696100008, 11.87644649800008],
              [109.18482463000007, 11.877321731000022],
              [109.1858833730001, 11.877807947000106],
              [109.18699530100008, 11.87766569800006],
              [109.18813910200004, 11.877061043000124],
              [109.18925740800002, 11.876177261000096],
              [109.19019807500004, 11.875205887000016],
              [109.19058964200011, 11.874161422000016],
              [109.19028929300003, 11.873019994000076],
              [109.18967047100008, 11.87192129300003],
              [109.18901941100005, 11.870893606],
              [109.18831510500003, 11.869919582000053],
              [109.18751366800007, 11.869001313000103],
              [109.18667522000007, 11.868123582000043],
              [109.18579909600004, 11.867280833000036],
              [109.18487378000005, 11.866490273000084],
              [109.18393980400003, 11.865708982000099],
              [109.18304848300001, 11.86487861800005],
              [109.1822304360001, 11.863982097000035],
              [109.18146383599999, 11.863042483000095],
              [109.18071851200006, 11.862076873000136],
              [109.17997039700006, 11.861105810000081],
              [109.17930295200009, 11.860092126000053],
              [109.1788308270001, 11.858979204000073],
              [109.17848948700001, 11.857821004000078],
              [109.17816407900008, 11.856655565000016],
              [109.17803593500005, 11.854378165000055],
              [109.17777508100005, 11.851637650000056],
              [109.17700399800002, 11.848055899000085],
              [109.17602532700005, 11.845167614000028],
              [109.17616011300001, 11.841240513000082],
              [109.176471276, 11.840075170000118],
              [109.17670872900001, 11.838895208000098],
              [109.17692472900004, 11.837706207000041],
              [109.17759895200004, 11.835399550000057],
              [109.17886440600003, 11.83108562000008],
              [109.17918742000002, 11.82890903000011],
              [109.17926122600009, 11.825710508000052],
              [109.17925485200001, 11.824512703000066],
              [109.17937668700006, 11.823312020000019],
              [109.17950072500001, 11.822111308000059],
              [109.17962475200005, 11.820910589],
              [109.17991618000011, 11.819765046000084],
              [109.18029129200005, 11.818647091000043],
              [109.1806664100001, 11.817529137000092],
              [109.18127591100007, 11.816534293000112],
              [109.18230089000006, 11.81583174800007],
              [109.18330469100007, 11.815668396000055],
              [109.18444630900002, 11.815710736000094],
              [109.18595569400003, 11.815941471000089],
              [109.18720254599999, 11.816260323000092],
              [109.18832385900005, 11.816320477000096],
              [109.18967980900007, 11.816253538000064],
              [109.19082209800007, 11.815889655000065],
              [109.19167392300002, 11.81525471700011],
              [109.19251719300009, 11.814396473000064],
              [109.19310702200004, 11.813366902000054],
              [109.19324777600005, 11.812743931000023],
              [109.1907065190001, 11.813472520000088],
              [109.18677444000011, 11.813949304000102],
              [109.18237550300002, 11.81374673600004],
              [109.17450215000005, 11.813108859000108],
              [109.16593059600005, 11.811792657000096],
              [109.15712385400003, 11.809795507000052],
              [109.1464618740001, 11.807126185000081],
              [109.13511215300008, 11.805596545000084],
              [109.12562540500002, 11.806102032000075],
              [109.11637005600002, 11.806605866000018],
              [109.10781269200004, 11.807787516000088],
              [109.10203630300003, 11.809636206000016],
              [109.0978775240001, 11.811021711000079],
              [109.09488082300004, 11.813310301000078],
              [109.09096307500008, 11.816512828000024],
              [109.08659074700006, 11.821308758],
              [109.08153100600001, 11.827471975000046],
              [109.07555104500003, 11.834776336000095],
              [109.07025796400004, 11.840713256000067],
              [109.06405162500008, 11.849155103000085],
              [109.05969327300005, 11.856905635000102],
              [109.05648393300008, 11.863059189000056],
              [109.05540760800002, 11.865888465000028],
              [109.05397130100002, 11.869663886000035],
              [109.05193090200004, 11.878084784000096],
              [109.04990058400008, 11.882863420000065],
              [109.04941443900002, 11.884007599000055],
              [109.04760642499998, 11.892654763000097],
              [109.04509197200004, 11.899032262000018],
              [109.04141262700008, 11.904051589000096],
              [109.03726766300005, 11.908618530000046],
              [109.03125850900005, 11.910921269000061],
              [109.02269629600002, 11.912099608000036],
              [109.00269142300002, 11.924144683000025],
              [109.00125568400006, 11.923656597000052],
              [108.99960986700005, 11.923097078000032],
              [108.98467339300001, 11.916774717000054],
              [108.98076824300001, 11.917219101000052],
              [108.97700642400007, 11.917378629000018],
              [108.97281317800004, 11.918108311000084],
              [108.96732353600004, 11.920122395000108],
              [108.96285146900003, 11.923126120000036],
              [108.95707615400002, 11.925993610000061],
              [108.95462723100007, 11.928419738000072],
              [108.95204081200004, 11.93240913100002],
              [108.94988317800004, 11.935260094000098],
              [108.94786428500007, 11.936831853000028],
              [108.94627888300008, 11.938259596000121],
              [108.94411061900007, 11.938837561000069],
              [108.9417943390001, 11.938705857000096],
              [108.93846431900008, 11.938436590000022],
              [108.93556978700005, 11.938449455000109],
              [108.93238515700007, 11.938321509000092],
              [108.92602491900007, 11.940054291000111],
              [108.92284860700006, 11.941772963000028],
              [108.92039844900002, 11.944056677000068],
              [108.91910545400006, 11.946193262000103],
              [108.91868211100004, 11.948610186000062],
              [108.91825620600005, 11.950458867000078],
              [108.91667112900009, 11.952028473],
              [108.91421507000008, 11.953033586000037],
              [108.9120446470001, 11.953185051000046],
              [108.90031031800007, 11.95082039500006],
              [108.89639748200005, 11.949700556000041],
              [108.89190513000005, 11.9484410350001],
              [108.88930178200003, 11.948878226000124],
              [108.88771587800009, 11.95030554],
              [108.87400978000007, 11.960591455000012],
              [108.86477152100005, 11.966596319000098],
              [108.86246337000004, 11.968452635000036],
              [108.86102007400008, 11.969453],
              [108.85942545000003, 11.968891243000089],
              [108.85724886900007, 11.967621503000101],
              [108.85478340900009, 11.966494981000062],
              [108.85188433100002, 11.965512251000108],
              [108.84996468900009, 11.964908],
              [108.84826803200004, 11.966095100000105],
              [108.84711771700007, 11.967946594000088],
              [108.84611388800006, 11.970223684000112],
              [108.84530298000003, 11.984149608000026],
              [108.84719485600006, 11.986557196000101],
              [108.85242698400008, 11.991508554000061],
              [108.85388590100008, 11.994201987000073],
              [108.85520247200003, 11.99746429500008],
              [108.85593999800004, 12.000728949000104],
              [108.85595546900008, 12.00442275700002],
              [108.85553601700011, 12.007976280000053],
              [108.85511001100011, 12.009967030000011],
              [108.85381887400004, 12.012813757000094],
              [108.8525271190001, 12.015518384000055],
              [108.84473183500002, 12.021090665000054],
              [108.83691982500007, 12.022684643000046],
              [108.83431541400005, 12.023121193000106],
              [108.83258202200007, 12.024122571000094],
              [108.82564887600009, 12.028270026000063],
              [108.82020584300004, 12.031743575000052],
              [108.82005001700003, 12.032096176],
              [108.81994625200005, 12.032330960000069],
              [108.81816704400011, 12.036356759000098],
              [108.8178062640001, 12.039673969000118],
              [108.81822231500006, 12.040485515000031],
              [108.81585588300001, 12.041521321000024],
              [108.81209673000011, 12.04295661600012],
              [108.80862601100009, 12.044106572000056],
              [108.80443068200006, 12.045117174],
              [108.7999451420001, 12.045986706000098],
              [108.79733813700004, 12.045854498000084],
              [108.79821416300005, 12.047698210000044],
              [108.80214842500006, 12.05393479800003],
              [108.80714779099999, 12.061023583000095],
              [108.80508876800008, 12.063578629000025],
              [108.8017442610001, 12.06930209800001],
              [108.80026801, 12.074465810000044],
              [108.79972774500006, 12.079810222000019],
              [108.79824416900004, 12.083131812000135],
              [108.79451927200004, 12.087382955000068],
              [108.79275745200002, 12.091350347000061],
              [108.79240259300006, 12.096325691000052],
              [108.79242403000006, 12.101852325],
              [108.79040243600012, 12.111255400000063],
              [108.78500925900002, 12.119749987000093],
              [108.78153250200003, 12.1248446760001],
              [108.78245631400009, 12.130167055000117],
              [108.78334237400003, 12.134568635000063],
              [108.78496156300007, 12.141241007000046],
              [108.7850778320001, 12.14184135600007],
              [108.78614404200003, 12.147346668000035],
              [108.78746216200003, 12.151036221000043],
              [108.787913788, 12.155439504000041],
              [108.78763498800002, 12.15828247700008],
              [108.78634526500008, 12.161981800000097],
              [108.78331793600006, 12.165971793],
              [108.78115113200001, 12.167684996000093],
              [108.778399859, 12.168121461000057],
              [108.77228430000008, 12.165448280000122],
              [108.76836237600004, 12.157500954000076],
              [108.76734126500001, 12.155657421000042],
              [108.76415122800003, 12.154958521000124],
              [108.74430727200003, 12.156308314000073],
              [108.73866049400009, 12.157322839000082],
              [108.7356195080001, 12.157759746000083],
              [108.73349567400004, 12.158946839000071],
              [108.73229384100009, 12.159618575000051],
              [108.73027156400009, 12.161330741000052],
              [108.72724683600002, 12.16310931600013],
              [108.72705052200004, 12.163277456000097],
              [108.72435043900001, 12.166750838000072],
              [108.72160909900003, 12.170028483000104],
              [108.71945074400006, 12.174298808000026],
              [108.717587592, 12.180131227000055],
              [108.71557444800005, 12.18454316600006],
              [108.71355526000005, 12.187249910000018],
              [108.71153354300002, 12.189246158000076],
              [108.71009240300006, 12.191524634000038],
              [108.7089410450001, 12.193802123000095],
              [108.70923974200012, 12.196358959000085],
              [108.7107026840001, 12.200332904000087],
              [108.71274584600005, 12.204447011000051],
              [108.71610930500007, 12.213103976000104],
              [108.71713173800003, 12.215374184],
              [108.7175909960001, 12.222335786000114],
              [108.71571689200002, 12.225184274000076],
              [108.71355188100003, 12.227749530000052],
              [108.71152827700011, 12.229319535000103],
              [108.70371852600007, 12.234177381000054],
              [108.70068509800009, 12.237171743000095],
              [108.698814027, 12.24101488500011],
              [108.69882764500007, 12.244993896],
              [108.700001329, 12.249111192000049],
              [108.70235812600005, 12.260045912000093],
              [108.70208093300003, 12.263741743000088],
              [108.70054735200006, 12.281511057000037],
              [108.7017510790001, 12.294297497000082],
              [108.70222512800007, 12.305665350000082],
              [108.69948156700008, 12.308943192000042],
              [108.69716901200005, 12.311082630000046],
              [108.68051632400002, 12.317532357000081],
              [108.67842301100002, 12.317935486000041],
              [108.682275299, 12.32321149500004],
              [108.68315196000006, 12.325198358000121],
              [108.68301836800002, 12.328609713000025],
              [108.68158257100006, 12.332878021000047],
              [108.67726490400004, 12.342698425],
              [108.67672065700003, 12.353501657],
              [108.67949357300006, 12.358751413000045],
              [108.68197136100008, 12.362438705000043],
              [108.68430182200007, 12.365415843000081],
              [108.68561615700003, 12.368111999000051],
              [108.68664238400002, 12.371377609000065],
              [108.68680128300011, 12.375498828000085],
              [108.68608900100006, 12.37933862500009],
              [108.68494347100005, 12.38374836600009],
              [108.68379693800004, 12.387873860000107],
              [108.67960481000001, 12.392151319000087],
              [108.67670806900006, 12.393439805000115],
              [108.67444707500003, 12.395887076000099],
              [108.67281140500006, 12.399421797000086],
              [108.67209409400004, 12.401840326000089],
              [108.67180912300009, 12.403404697000029],
              [108.66796147100005, 12.424452772000015],
              [108.66854828300002, 12.426440827000105],
              [108.67001068300004, 12.4299895970001],
              [108.67219992800008, 12.433962482000103],
              [108.67365588400003, 12.435521349000032],
              [108.6924420890001, 12.456355031000042],
              [108.69578943900004, 12.459328993000083],
              [108.69710259700005, 12.461456780000011],
              [108.7029599090001, 12.476930875000035],
              [108.70601556100007, 12.479337128000088],
              [108.70950662600009, 12.481741872000017],
              [108.71183438700004, 12.483439777000081],
              [108.71445141100003, 12.484852390000077],
              [108.71720509300006, 12.483848040000021],
              [108.72024646300008, 12.482131966000052],
              [108.72372413600006, 12.48069863700003],
              [108.72691102500004, 12.479124118000049],
              [108.72995427800004, 12.477976475000039],
              [108.73314260900005, 12.476828286000073],
              [108.7388034090001, 12.477234990000062],
              [108.74302348300003, 12.480489497000045],
              [108.74681667600001, 12.486019754000031],
              [108.74755476400006, 12.489428625000086],
              [108.74872979400001, 12.493262399000036],
              [108.74917612000009, 12.496245880000066],
              [108.75049163300005, 12.498799850000085],
              [108.75339937900003, 12.500210962000045],
              [108.768513329, 12.505700268000018],
              [108.77185453000006, 12.506540982000034],
              [108.77504793200005, 12.506671431000081],
              [108.77838484500009, 12.506374875000057],
              [108.78128309200009, 12.505226997000117],
              [108.785771137, 12.502367400000123],
              [108.78823335600006, 12.501078903000094],
              [108.80906725700001, 12.484795516000078],
              [108.82005367100007, 12.474234491000045],
              [108.82280268600005, 12.472233799000064],
              [108.83496071400006, 12.464652541000108],
              [108.83742216700006, 12.463363519000035],
              [108.84292712500009, 12.461209500000068],
              [108.84684004100011, 12.460056718000077],
              [108.85162052300005, 12.458189666000051],
              [108.85365007600011, 12.457755047000061],
              [108.85640270700004, 12.456748919000036],
              [108.859444298, 12.455457297000104],
              [108.86205357500006, 12.454878107000054],
              [108.86495363200002, 12.454439826000028],
              [108.86727511500008, 12.45443030800004],
              [108.86829258500006, 12.454852554000071],
              [108.87358288600009, 12.470466346000107],
              [108.87489430400007, 12.471740199000079],
              [108.87563762800002, 12.473467217000065],
              [108.87636001500002, 12.475145566000021],
              [108.87738982500005, 12.478410600000077],
              [108.87696182700007, 12.480118112000048],
              [108.87551621100002, 12.481403409000078],
              [108.87204507100004, 12.484118510000107],
              [108.86828178600007, 12.48640833100003],
              [108.86437330500006, 12.488698684000086],
              [108.86075497200008, 12.490987788000039],
              [108.85815180700008, 12.493130566000053],
              [108.85728765500008, 12.494697686000043],
              [108.86347958700001, 12.501797677000058],
              [108.86778780100005, 12.506737329000115],
              [108.87041834300001, 12.510990957000077],
              [108.87318621400004, 12.513396067000125],
              [108.87377222900004, 12.514672979000084],
              [108.87407286200005, 12.517088275000086],
              [108.87278243900003, 12.520789506000083],
              [108.87192314300009, 12.523493902000025],
              [108.87121140100008, 12.526766301000068],
              [108.87209867900003, 12.530600725000072],
              [108.87487598300007, 12.535138099000063],
              [108.87794249100003, 12.539389948000055],
              [108.88042359700006, 12.542506985000033],
              [108.88449966399999, 12.545190878000055],
              [108.88741022700003, 12.546884514000032],
              [108.88959504900005, 12.548581169000082],
              [108.89091328800006, 12.551276547000077],
              [108.89327075000007, 12.559227248000047],
              [108.894299394, 12.562066005000093],
              [108.89417728400011, 12.567326296000124],
              [108.89260153600003, 12.572166292000107],
              [108.89131607100008, 12.577005075000079],
              [108.88945216200004, 12.581553247000075],
              [108.88682727300002, 12.58072984300007],
              [108.88485249500005, 12.580738120000133],
              [108.88323162900005, 12.581996086000034],
              [108.88068535000005, 12.584167839000047],
              [108.87791158400006, 12.587477955000013],
              [108.87559653900009, 12.589421213000039],
              [108.87234918100008, 12.590685837000047],
              [108.86944835400004, 12.591493999000123],
              [108.86504308100005, 12.593673230000134],
              [108.8619165640001, 12.596074649000053],
              [108.86042264200003, 12.599948078000088],
              [108.86008564100004, 12.602679344000121],
              [108.86033095000008, 12.605749492000127],
              [108.86138663800003, 12.608133865000058],
              [108.86268001300002, 12.61176847900005],
              [108.86362294600006, 12.614949551000086],
              [108.8651444290001, 12.61755951300003],
              [108.86759785200005, 12.620734397000087],
              [108.87051805200008, 12.624362324000058],
              [108.87390018000006, 12.627305828000067],
              [108.87599404700002, 12.627865906000045],
              [108.87820166100008, 12.627856746000086],
              [108.88098875200011, 12.627503905000113],
              [108.88551324900001, 12.625892518000102],
              [108.88829830800006, 12.625084588000044],
              [108.89213403300008, 12.62540968500012],
              [108.89562618900001, 12.626873649000101],
              [108.90263332200007, 12.634920021000083],
              [108.9064874530001, 12.639339768000111],
              [108.90964050000004, 12.642852472000053],
              [108.91151952100007, 12.647280660000069],
              [108.91166013400007, 12.652740099000058],
              [108.91249851300007, 12.658310319000064],
              [108.91251328000006, 12.661609066000066],
              [108.91147553600008, 12.663433579000037],
              [108.90765376200005, 12.666407592000082],
              [108.90001339600009, 12.673151691000049],
              [108.89978548500007, 12.674176444000105],
              [108.90060653900005, 12.675879250000042],
              [108.90177575200008, 12.677466823000126],
              [108.90340735600007, 12.678483645000059],
              [108.90736436500001, 12.679717992000048],
              [108.91073524600003, 12.67981725500009],
              [108.91387471200008, 12.680144968000116],
              [108.91689847600007, 12.680586903000089],
              [108.91864690800006, 12.681716851000056],
              [108.92063191200006, 12.683755802000094],
              [108.92232381300008, 12.685227231000072],
              [108.93653958000007, 12.693127615000053],
              [108.94073636400005, 12.69583912200005],
              [108.94304725000002, 12.692871175000063],
              [108.94605965000005, 12.69081011400006],
              [108.95023424700004, 12.688743795000066],
              [108.95743376800003, 12.687346255000074],
              [108.96254547600003, 12.686868026000029],
              [108.96812097000003, 12.68616003600007],
              [108.97230065600009, 12.685230817000013],
              [108.97620431300004, 12.683448654000083],
              [108.97639955700004, 12.683164818000046],
              [108.97886832700001, 12.685428027000077],
              [108.98120592800008, 12.688147321000097],
              [108.99027054500002, 12.706969813000056],
              [108.99164563700002, 12.707778463000084],
              [108.99560930700007, 12.710148745000092],
              [109.00027553600006, 12.713539459000065],
              [109.00494696000004, 12.717953874],
              [109.00681637100008, 12.719878878000122],
              [109.02666638000005, 12.73741619600006],
              [109.03658369700003, 12.744420993000103],
              [109.04008590600009, 12.747247833000051],
              [109.04311385000005, 12.748256776000042],
              [109.04695388400009, 12.74892037600006],
              [109.050671236, 12.748333130000054],
              [109.05875745700007, 12.750209569000058],
              [109.07438254700007, 12.747190144000074],
              [109.07635232000007, 12.745928744000066],
              [109.07901960200005, 12.74466377900003],
              [109.08168927600005, 12.743853814000071],
              [109.08343311500001, 12.743844888000043],
              [109.08499101700004, 12.745353472000074],
              [109.08530289900008, 12.745655482000105],
              [109.08717696500007, 12.748262380000062],
              [109.08952524800003, 12.752573254000053],
              [109.08896643700007, 12.756785343000042],
              [109.08851718700002, 12.759745493000095],
              [109.08794801000009, 12.762023682000033],
              [109.0890075240001, 12.769212036000075],
              [109.10046219200008, 12.775610631000054],
              [109.10315156800002, 12.777950650000045],
              [109.10549097900005, 12.780931363000024],
              [109.11254834800009, 12.795683941000034],
              [109.11287529200006, 12.796883726000095],
              [109.11326036200003, 12.798296838000043],
              [109.1146620280001, 12.799427122000038],
              [109.11652636499998, 12.800099897000015],
              [109.11920035600008, 12.799971996000091],
              [109.12198311700011, 12.798478280000046],
              [109.12394807100006, 12.796306280000101],
              [109.12556224100004, 12.793794827000047],
              [109.13133538800005, 12.786369216000091],
              [109.13515868800002, 12.78384588300012],
              [109.13817176000001, 12.782009444000051],
              [109.14037721000005, 12.781314968000055],
              [109.14119242300009, 12.781538107000044],
              [109.1444622380001, 12.784023284000055],
              [109.14668241500004, 12.785945278],
              [109.146689458, 12.787196662000076],
              [109.14932592000009, 12.80106189400003],
              [109.15097516400004, 12.804807266000038],
              [109.15238673600004, 12.807643779000076],
              [109.15333253300005, 12.810369060000058],
              [109.15450706600006, 12.812410488000035],
              [109.15626827700004, 12.81535884900009],
              [109.15791517800005, 12.818649152000104],
              [109.15932888500005, 12.821826949000046],
              [109.16085569200008, 12.824435284000034],
              [109.16249301100001, 12.82601906600007],
              [109.16447924900004, 12.827600926000036],
              [109.16704183600007, 12.828269421000103],
              [109.18846096000001, 12.831449528000059],
              [109.19171618900006, 12.831203670000056],
              [109.19590183700009, 12.830952514000058],
              [109.20229056300005, 12.829551048],
              [109.20705230200004, 12.828386234000087],
              [109.21041564600006, 12.826774230000114],
              [109.21377080200003, 12.823796983000067],
              [109.21769579800005, 12.818882327000034],
              [109.22058757100005, 12.81624897200002],
              [109.22278696500004, 12.814529728000055],
              [109.22429539500006, 12.813952166000051],
              [109.22603844900004, 12.813714529000018],
              [109.22943023600001, 12.816880385000038],
              [109.23434228900001, 12.821402557000015],
              [109.23901918000008, 12.825470941000015],
              [109.24357718100001, 12.82908484400007],
              [109.24638657400001, 12.83202635200009],
              [109.24837491700005, 12.833834962000077],
              [109.25095322700004, 12.837005323000115],
              [109.25282395500001, 12.838587056000071],
              [109.25375793500011, 12.839150383000051],
              [109.25701179400004, 12.838675989000057],
              [109.26177343300002, 12.837509925000033],
              [109.26618326200003, 12.83589077500009],
              [109.26827396500008, 12.835423167000036],
              [109.28093789000012, 12.833185220000052],
              [109.28361292900003, 12.833169007000043],
              [109.28664551000003, 12.834515838000103],
              [109.29084769900001, 12.836879438],
              [109.29412096000003, 12.839476184000096],
              [109.29657577200001, 12.841395276000076],
              [109.29983682800001, 12.842057901000102],
              [109.30513554600002, 12.843049230000057],
              [109.31349238300002, 12.840266985000069],
              [109.31556980500005, 12.837751095],
              [109.31765746900003, 12.836827931000093],
              [109.3206918050001, 12.838401807000109],
              [109.32396473200001, 12.840884334000078],
              [109.32538266400007, 12.844288623000109],
              [109.32703327100005, 12.847691454000108],
              [109.33162847600003, 12.849423973000016],
              [109.33356325100007, 12.850153399000062],
              [109.33775808800004, 12.85126464200008],
              [109.34406169300007, 12.85463789000003],
              [109.35224172400009, 12.860274464000019],
              [109.35948857800001, 12.86546164200004],
              [109.36462514600001, 12.868159167000035],
              [109.371557196, 12.861060325000027],
              [109.37617162900005, 12.855341657000084],
              [109.38240495900004, 12.848247078000073],
              [109.38692560200006, 12.844282623000057],
              [109.38585149400009, 12.843545807000021],
              [109.38395247500009, 12.842526140000119],
              [109.38134370200007, 12.841117031000058],
              [109.37823706500006, 12.839561022000099],
              [109.37685677800008, 12.838931994000101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 32, code: "AD01", ten_tinh: "Kiên Giang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.43909732499999, 9.26394136900006],
              [103.43815579700008, 9.263852139000049],
              [103.43687062700005, 9.264016763000081],
              [103.43592571100004, 9.264693585000126],
              [103.43523579400005, 9.265797090000126],
              [103.43497438400001, 9.266817347000076],
              [103.43497023700012, 9.267753608000094],
              [103.43513885100003, 9.268350173000071],
              [103.43556586600002, 9.268607387000126],
              [103.43659341400003, 9.268611873000053],
              [103.43856777100005, 9.267513970000072],
              [103.439258436, 9.266240228000109],
              [103.43943683800002, 9.264623782000035],
              [103.43909732499999, 9.26394136900006],
            ],
          ],
          [
            [
              [103.46093302300005, 9.28926483600012],
              [103.45980214700009, 9.289259958000072],
              [103.45849351200008, 9.290118978000093],
              [103.45796815700004, 9.290894892000077],
              [103.45796134800001, 9.292451230000063],
              [103.45830439700006, 9.293576763000109],
              [103.45925979500007, 9.293926743000023],
              [103.46039068800012, 9.293931624000072],
              [103.46230676300011, 9.293421088000018],
              [103.46309421600007, 9.292386895000069],
              [103.46327159800009, 9.291609470000049],
              [103.46327574300008, 9.290658362000047],
              [103.46258434000008, 9.289617814000016],
              [103.46093302300005, 9.28926483600012],
            ],
          ],
          [
            [
              [103.4794882640001, 9.303438260000084],
              [103.47748738300001, 9.303429723000088],
              [103.47609321600004, 9.303942567000066],
              [103.475741869, 9.304719257000025],
              [103.47573812700006, 9.305583902000068],
              [103.47643146500005, 9.306192123000033],
              [103.47756128200008, 9.306456348000046],
              [103.47938856800002, 9.306377671000108],
              [103.48043512400001, 9.305776875000047],
              [103.48061134900003, 9.305258833000083],
              [103.48061583000005, 9.304221258000032],
              [103.4794882640001, 9.303438260000084],
            ],
          ],
          [
            [
              [103.42306389000005, 9.309612399000054],
              [103.41995519800005, 9.309289527000074],
              [103.41860703400006, 9.309386586000111],
              [103.41798177700011, 9.310105079000051],
              [103.41797806300008, 9.31092936200004],
              [103.41807755900004, 9.311857139000066],
              [103.41890275800002, 9.312788143000093],
              [103.42014263700003, 9.313720967000092],
              [103.42273116800006, 9.314453708000057],
              [103.42459817400008, 9.314255894000032],
              [103.42563903400006, 9.313333146000067],
              [103.42564364800008, 9.312302793000081],
              [103.42523543300005, 9.310858463000036],
              [103.42306389000005, 9.309612399000054],
            ],
          ],
          [
            [
              [103.48931292400005, 9.321314852000027],
              [103.48736356500004, 9.321306578000053],
              [103.48667919700009, 9.321788023000098],
              [103.48648091700002, 9.322562149000111],
              [103.48647632600009, 9.32362770200008],
              [103.48696075700011, 9.324307859000045],
              [103.48783504900007, 9.324989662000059],
              [103.49017431200008, 9.324999592000081],
              [103.49115276100004, 9.324131909000053],
              [103.49135310200009, 9.32287343400011],
              [103.49106485500005, 9.321903510000061],
              [103.48931292400005, 9.321314852000027],
            ],
          ],
          [
            [
              [103.46030665400009, 9.326324464000011],
              [103.46504949100003, 9.326030823000052],
              [103.47184489600006, 9.326217126000101],
              [103.47674442900009, 9.326238133000063],
              [103.477693403, 9.326085131000104],
              [103.47959951900006, 9.323894358000116],
              [103.48024530800004, 9.320755809000046],
              [103.479309938, 9.317767570000067],
              [103.46498678400008, 9.30419856900002],
              [103.45680182500008, 9.296624172000099],
              [103.45144383900006, 9.293145572000048],
              [103.44828457700005, 9.2928177250001],
              [103.44496450800006, 9.293117410000116],
              [103.44338002200006, 9.294052885000069],
              [103.44305702500006, 9.295622111000055],
              [103.442878159, 9.300333220000121],
              [103.44175383700006, 9.30441195500009],
              [103.43968820800004, 9.306915921000057],
              [103.43937073600006, 9.307228660000087],
              [103.43604216000007, 9.309412959000072],
              [103.43239817500002, 9.311438768000089],
              [103.43096810200009, 9.313160153000053],
              [103.43080375800008, 9.314572987000068],
              [103.43095337800003, 9.316458400000014],
              [103.43284008299999, 9.318665571000027],
              [103.43693801600003, 9.321196586000012],
              [103.44561308699998, 9.325161144000036],
              [103.45176722400008, 9.327386856000073],
              [103.45429599700006, 9.327397849000095],
              [103.46030665400009, 9.326324464000011],
            ],
          ],
          [
            [
              [103.50372212200006, 9.32154902000004],
              [103.50226008300007, 9.321542876000065],
              [103.50069892600004, 9.321923792000051],
              [103.49835056700006, 9.324045056000061],
              [103.49707848900006, 9.325202137000053],
              [103.49688022700009, 9.325976269000067],
              [103.49707143400011, 9.326848927000063],
              [103.49794659700004, 9.32733697100012],
              [103.49940824200009, 9.327440014000031],
              [103.50096900900009, 9.327155973000066],
              [103.50341279000006, 9.325519448000106],
              [103.5043945030001, 9.323876763000081],
              [103.50440110300011, 9.322326850000094],
              [103.50372212200006, 9.32154902000004],
            ],
          ],
          [
            [
              [104.39446971700008, 9.636914784000082],
              [104.39424514900006, 9.636254239000051],
              [104.39359977400004, 9.635351900000087],
              [104.39280948900002, 9.634634689000075],
              [104.39248064700007, 9.634535204000104],
              [104.39214934100002, 9.634576401000048],
              [104.39181475000004, 9.634805152000057],
              [104.39151751500006, 9.635597423000062],
              [104.39112995700005, 9.636153605000073],
              [104.39074487100007, 9.63656911200008],
              [104.389373242, 9.636686158000083],
              [104.38838178000003, 9.636669041000088],
              [104.38767113400004, 9.636797487000058],
              [104.38714522000004, 9.637163654000068],
              [104.38656796900007, 9.637763465000136],
              [104.38603464699999, 9.638551645000069],
              [104.38588067200004, 9.639252574000066],
              [104.38611015400002, 9.63963179000009],
              [104.38638849600005, 9.63991803500006],
              [104.386949294, 9.640256070000051],
              [104.38826140000003, 9.640841603000093],
              [104.390094494, 9.641342304000132],
              [104.39765568100006, 9.643771066000094],
              [104.39831667900005, 9.643782448000058],
              [104.39850963100011, 9.643551236000024],
              [104.39846897500004, 9.643175282000092],
              [104.39829242200008, 9.642468649000067],
              [104.39801734000005, 9.641994853000123],
              [104.39446971700008, 9.636914784000082],
            ],
          ],
          [
            [
              [104.35893581400003, 9.643148355000085],
              [104.35853290500009, 9.643080879000097],
              [104.357916695, 9.643324389000084],
              [104.35697395300006, 9.644545904000077],
              [104.35647983200003, 9.645345683000082],
              [104.35632208600003, 9.645879174000081],
              [104.356454736, 9.646769074000082],
              [104.35662166500005, 9.647303178000042],
              [104.35668791300003, 9.647792614000126],
              [104.35687825500005, 9.64799314000002],
              [104.35713536400002, 9.64799362400006],
              [104.35735973600001, 9.647749389000103],
              [104.35774157200007, 9.64712734300006],
              [104.358459887, 9.646172292000086],
              [104.35913351700005, 9.645172689000068],
              [104.35938108000002, 9.644505913000055],
              [104.35935964800011, 9.643994311000091],
              [104.35927101300005, 9.643504833000092],
              [104.35893581400003, 9.643148355000085],
            ],
          ],
          [
            [
              [104.39278773800008, 9.647422945000093],
              [104.392445423, 9.647387261000072],
              [104.39207278300007, 9.647401503000095],
              [104.39181888200002, 9.647449352000058],
              [104.39157178000002, 9.647524481000056],
              [104.39131009800003, 9.647641622000068],
              [104.39099561000003, 9.647840396000083],
              [104.39073652900001, 9.648065402000048],
              [104.39053313200006, 9.648304992000066],
              [104.39036406200009, 9.648569557000107],
              [104.39025429500008, 9.648801734000044],
              [104.39015988000008, 9.649100658000085],
              [104.39010034200004, 9.649465908000108],
              [104.39009763399999, 9.649779172000093],
              [104.39014005300004, 9.65008960900011],
              [104.39022417300006, 9.650391561000047],
              [104.39033719, 9.650653435000043],
              [104.3905165570001, 9.650945058000014],
              [104.39075670300008, 9.651228100000074],
              [104.39097107900005, 9.651417579000031],
              [104.39118421200001, 9.651562628000042],
              [104.39143844400009, 9.651694963000066],
              [104.39176305900003, 9.651808821000072],
              [104.39212936600005, 9.651877953000019],
              [104.39238744300009, 9.651892028000118],
              [104.39275772399999, 9.651855052000078],
              [104.39320014800009, 9.651731780000093],
              [104.39355806200004, 9.651551464000095],
              [104.39390111300001, 9.651294340000048],
              [104.39413711400009, 9.651045585000071],
              [104.39434350000002, 9.650737447000067],
              [104.39449114100006, 9.650428828000111],
              [104.39457929600007, 9.650128023000091],
              [104.39463272600005, 9.649654576000087],
              [104.39460700800007, 9.649286454000093],
              [104.39454568000005, 9.64900815700007],
              [104.39445905800008, 9.648766538000063],
              [104.39430107900006, 9.648462979000081],
              [104.39410101500003, 9.64818493400008],
              [104.39383650300007, 9.647924182000088],
              [104.393510249, 9.647692148000074],
              [104.39317334100011, 9.647533861000033],
              [104.39278773800008, 9.647422945000093],
            ],
          ],
          [
            [
              [104.35319526000005, 9.647585908000076],
              [104.35281495000002, 9.647407261000039],
              [104.35244528400008, 9.647517773000034],
              [104.35238871400003, 9.647829045000032],
              [104.35243984699999, 9.65036465500013],
              [104.35252864400005, 9.650765172000051],
              [104.35279654100002, 9.651188263000064],
              [104.35342202400008, 9.651967886000088],
              [104.35395847800005, 9.652458197000067],
              [104.35427182600004, 9.65252550200004],
              [104.35454066000008, 9.652459294000069],
              [104.35480961100004, 9.652326345000082],
              [104.355090354, 9.651882042000091],
              [104.35513678700002, 9.651014714],
              [104.35500446100009, 9.649946877000025],
              [104.35469255300004, 9.64912336900001],
              [104.35400002500003, 9.648276899000051],
              [104.35319526000005, 9.647585908000076],
            ],
          ],
          [
            [
              [104.35669836600009, 9.6535095090001],
              [104.35609529400001, 9.653483433000075],
              [104.35549194500001, 9.65360706700009],
              [104.35506406200005, 9.653955585000061],
              [104.35428346200003, 9.654777520000033],
              [104.35408080400005, 9.655625495000029],
              [104.35405381600003, 9.656598554000071],
              [104.35417866400006, 9.657022961000015],
              [104.35442916100001, 9.657447611000068],
              [104.35505656000005, 9.657897908000022],
              [104.35543332800005, 9.657998427000079],
              [104.35593617100008, 9.657874601000048],
              [104.35679126200004, 9.657526888000055],
              [104.3577474030001, 9.656904881000104],
              [104.35857873000006, 9.655833521000099],
              [104.35870514200005, 9.655434529000038],
              [104.35868086100007, 9.654985352],
              [104.35838024200007, 9.654485760000052],
              [104.35770272100005, 9.653960524000114],
              [104.35669836600009, 9.6535095090001],
            ],
          ],
          [
            [
              [104.38205361700003, 9.651728144000058],
              [104.381569175, 9.651719765000076],
              [104.38108333300003, 9.651791563000066],
              [104.38050970900009, 9.652262832000067],
              [104.38032286400008, 9.65370320000005],
              [104.38037260500002, 9.655468463000092],
              [104.38098613400007, 9.657323681000028],
              [104.381860203, 9.658140807000047],
              [104.38258124800006, 9.658474076000088],
              [104.38298636500011, 9.658400886000072],
              [104.38347504600004, 9.658168731000048],
              [104.38405993900002, 9.657056037000087],
              [104.38408246000003, 9.655773216000084],
              [104.38378342400006, 9.65440464500009],
              [104.38244745600011, 9.652296352000088],
              [104.38205361700003, 9.651728144000058],
            ],
          ],
          [
            [
              [104.388965333, 9.66021978000011],
              [104.38828753000006, 9.66020807800003],
              [104.38815355700007, 9.66081791000004],
              [104.3883276860001, 9.661433061000045],
              [104.38874507499999, 9.66223606300005],
              [104.38976900800006, 9.663600458000053],
              [104.39101584600012, 9.666315434000071],
              [104.39154362800008, 9.667854922000084],
              [104.3917006190001, 9.669449232000103],
              [104.39175687800005, 9.669756284000107],
              [104.39161755700007, 9.670672109000032],
              [104.39122530300003, 9.671950879000056],
              [104.39119849600004, 9.673480807000077],
              [104.39147551500004, 9.67526085900001],
              [104.39170163800001, 9.676427867000113],
              [104.39224445900007, 9.677110606000026],
              [104.39279586700005, 9.677303770000021],
              [104.39390509700007, 9.677322893000094],
              [104.39688232700003, 9.67627228900003],
              [104.39769092700006, 9.6758577080001],
              [104.39819033800008, 9.675499006000086],
              [104.398378413, 9.675318599000081],
              [104.39838697400009, 9.674829016000073],
              [104.39833284400005, 9.674399570000068],
              [104.39809384199999, 9.673966941000066],
              [104.39736721, 9.673219840000067],
              [104.39609454700005, 9.671973605000071],
              [104.39445324300004, 9.670659787000078],
              [104.39421425400008, 9.670227157000076],
              [104.39409850500009, 9.669796659000072],
              [104.39404867100005, 9.669122423000063],
              [104.394122076, 9.668450320000053],
              [104.39437606100005, 9.668026188000043],
              [104.39488724100006, 9.666994337000022],
              [104.39534215300004, 9.665655447000086],
              [104.39542197800009, 9.664616166000071],
              [104.39542840399999, 9.664248985000066],
              [104.39518942000002, 9.663816360000064],
              [104.39488239300002, 9.663749855000068],
              [104.39328027600007, 9.663722239],
              [104.39242295000001, 9.663401387000102],
              [104.39145096800006, 9.662588825000016],
              [104.38975458700003, 9.660906760000019],
              [104.388965333, 9.66021978000011],
            ],
          ],
          [
            [
              [104.37889719800008, 9.677190190000127],
              [104.37855441800005, 9.677157696000014],
              [104.37829614800009, 9.677167866000056],
              [104.37801298200002, 9.677214982000047],
              [104.37768412400007, 9.677316306000082],
              [104.37732615100003, 9.677496604000078],
              [104.37698303600006, 9.677753713000035],
              [104.37674698300006, 9.6780024610001],
              [104.37654054400004, 9.678310597000104],
              [104.37639285600008, 9.67861921900006],
              [104.37630466200007, 9.678920033000081],
              [104.37625118199999, 9.679393500000087],
              [104.37627687200008, 9.679761645000083],
              [104.37633818500008, 9.680039963000107],
              [104.37642479800006, 9.680281604000117],
              [104.37658277500007, 9.6805851940001],
              [104.37678284600001, 9.680863272000105],
              [104.37704738000005, 9.681124062000011],
              [104.37737366800005, 9.681356135000028],
              [104.37771061900004, 9.681514458000072],
              [104.37821003900007, 9.68164145900001],
              [104.37855351500006, 9.681666172000101],
              [104.37881000600005, 9.681647900000099],
              [104.37906529700004, 9.681599063000055],
              [104.37931244400006, 9.681523945000057],
              [104.37957417600005, 9.681406811000047],
              [104.37988872600005, 9.681208042000032],
              [104.38014786000008, 9.680983036000066],
              [104.38035130200004, 9.680743442000049],
              [104.38052041100009, 9.680478869000096],
              [104.38063020600002, 9.680246684000069],
              [104.38072464900003, 9.679947747000115],
              [104.38078421200007, 9.679582479000091],
              [104.38078693500003, 9.679269198000016],
              [104.38074452400006, 9.678958742000086],
              [104.38066040600003, 9.678656770000059],
              [104.38054738600002, 9.678394878000061],
              [104.38036800900009, 9.678103232000089],
              [104.38012784600002, 9.677820163000115],
              [104.37991345300006, 9.677630664000068],
              [104.37970030100009, 9.677485597000056],
              [104.37944604500009, 9.677353241000029],
              [104.37920460800011, 9.677261669000091],
              [104.37889719800008, 9.677190190000127],
            ],
          ],
          [
            [
              [104.39575159700009, 9.679207757000084],
              [104.39540922800009, 9.679172075000063],
              [104.39503653100007, 9.679186323000087],
              [104.39478259100011, 9.67923417900005],
              [104.39453545300009, 9.679309315000049],
              [104.39427373200002, 9.679426467000061],
              [104.39395919800002, 9.679625257000078],
              [104.39370008100008, 9.679850280000045],
              [104.39349665500004, 9.680089887000063],
              [104.39332756400007, 9.680354470000017],
              [104.39321778300004, 9.680586662000044],
              [104.39312335700005, 9.680885604000087],
              [104.39306381600001, 9.681250877000112],
              [104.39306111200005, 9.68156416000001],
              [104.39310354200009, 9.681874614000028],
              [104.39318767900005, 9.682176583000056],
              [104.39330071700007, 9.682438470000053],
              [104.39348011599999, 9.682730107000024],
              [104.39372030400006, 9.683013163000085],
              [104.39393471500003, 9.683202650000043],
              [104.39414788300009, 9.683347704000054],
              [104.39440215600007, 9.683480044000079],
              [104.39472682300003, 9.683593904000086],
              [104.39509318700006, 9.683663035000032],
              [104.395351304, 9.683677107000042],
              [104.39571704900007, 9.68364124],
              [104.39616413300011, 9.683516838000104],
              [104.39651117200005, 9.683343303000045],
              [104.39686519900003, 9.683079363000056],
              [104.39710123400002, 9.682830589000078],
              [104.39730764600004, 9.682522430000072],
              [104.39745530600001, 9.682213791000025],
              [104.39754347100002, 9.681912967000093],
              [104.397596902, 9.681439492],
              [104.39757117400009, 9.68107134900009],
              [104.397509832, 9.680793036000067],
              [104.39742319400001, 9.680551404000058],
              [104.39726518700009, 9.680247830000075],
              [104.39706508800005, 9.679969771000073],
              [104.39680053100001, 9.67970900800008],
              [104.39647422300001, 9.679476965000065],
              [104.39613726100002, 9.679318673000113],
              [104.39575159700009, 9.679207757000084],
            ],
          ],
          [
            [
              [104.39736166100008, 9.683916607000052],
              [104.39701928500008, 9.683880928000031],
              [104.39661452100007, 9.683900894000084],
              [104.39622747000003, 9.683991742000051],
              [104.39585674600008, 9.6841501220001],
              [104.39554594600003, 9.684350831000097],
              [104.39531010300006, 9.684559151000103],
              [104.39510667500011, 9.684798762000034],
              [104.39493758200005, 9.685063349000076],
              [104.39482780000003, 9.685295543000015],
              [104.39473337500004, 9.685594489000058],
              [104.39467383500008, 9.685959766000083],
              [104.39467113300003, 9.68627305100007],
              [104.39471356700008, 9.686583507000089],
              [104.39479770800003, 9.686885478000116],
              [104.39491075100005, 9.687147367000113],
              [104.39509015600007, 9.687439005000085],
              [104.39533035100004, 9.687722062000058],
              [104.39554476900003, 9.687911549000104],
              [104.39575794300008, 9.688056602000115],
              [104.39601222200007, 9.688188941000052],
              [104.3963368980001, 9.688302800000058],
              [104.39670327100005, 9.688371928],
              [104.39696139400009, 9.688385999000014],
              [104.39733174, 9.688349012000062],
              [104.39777424000002, 9.688225722000075],
              [104.39813221400006, 9.688045386000075],
              [104.39847531900003, 9.687788238000026],
              [104.39871135700011, 9.687539460000048],
              [104.39891777200005, 9.687231297000041],
              [104.399065433, 9.686922654000084],
              [104.39915359700011, 9.686621827000062],
              [104.39920702600008, 9.686148347000055],
              [104.39918129500009, 9.685780201000059],
              [104.39911994900007, 9.685501886000035],
              [104.3990333070001, 9.685260253000115],
              [104.39887529400008, 9.684956678000043],
              [104.39867518800004, 9.68467861800004],
              [104.39841062300009, 9.684417854000047],
              [104.398084306, 9.684185812000033],
              [104.3977473350001, 9.684027521000081],
              [104.39736166100008, 9.683916607000052],
            ],
          ],
          [
            [
              [104.38043031300001, 9.682014855000086],
              [104.38005324700006, 9.682008331000079],
              [104.37962139000004, 9.682054360000068],
              [104.37934641100003, 9.682370658000126],
              [104.37884281400002, 9.683432118000022],
              [104.37881648000005, 9.684929913000049],
              [104.37885623300006, 9.685733235000081],
              [104.37926929900004, 9.686757065000061],
              [104.37989408100005, 9.687998588000111],
              [104.38067764600004, 9.689403394000083],
              [104.38216433400004, 9.690659825000035],
              [104.38524924400011, 9.692960560000062],
              [104.38658281500005, 9.693732730000091],
              [104.38717349700008, 9.69384995600011],
              [104.38771312200005, 9.693805762000094],
              [104.38858065200006, 9.693499675000082],
              [104.38912777900003, 9.693027538000097],
              [104.38930064000004, 9.692388404000061],
              [104.3893100180001, 9.691853465000031],
              [104.38899525600009, 9.691366443000058],
              [104.38831090900004, 9.690444965000021],
              [104.38602332600003, 9.685696642000073],
              [104.38533995800006, 9.68472166900005],
              [104.38449405800006, 9.683797407000048],
              [104.38326641100009, 9.683134081000054],
              [104.38043031300001, 9.682014855000086],
            ],
          ],
          [
            [
              [104.37784207400009, 9.698902352000081],
              [104.37704137800009, 9.698254871000065],
              [104.37663444100008, 9.698305424000074],
              [104.37621635900004, 9.69898940800009],
              [104.37577395400002, 9.701055408000057],
              [104.37539155900001, 9.703007254000083],
              [104.37505701500004, 9.705535967000055],
              [104.374835402, 9.708239443000098],
              [104.37479586800001, 9.710485277000112],
              [104.37507568899998, 9.711066155000124],
              [104.3754206020001, 9.711244934000083],
              [104.37588251900004, 9.711368147000064],
              [104.37652245800005, 9.711264020000064],
              [104.37704641400006, 9.711157891000076],
              [104.37857143400004, 9.710205038000106],
              [104.38081071500004, 9.707457333000134],
              [104.38069951500007, 9.705689477000055],
              [104.38080289300009, 9.703854709000035],
              [104.38041097200009, 9.701870103000053],
              [104.38011710400011, 9.701476208000058],
              [104.37784207400009, 9.698902352000081],
            ],
          ],
          [
            [
              [104.35525480400003, 9.659370423],
              [104.35362284200008, 9.65856891500003],
              [104.35175916200006, 9.65875880100011],
              [104.348769804, 9.659689428000105],
              [104.34746044900001, 9.660651304000021],
              [104.34525198000007, 9.662909637],
              [104.34286856200005, 9.666117903000069],
              [104.34045795500008, 9.669240193000038],
              [104.33929864599999, 9.672109200000099],
              [104.33867863200003, 9.675813176000057],
              [104.33832582700006, 9.680072200000021],
              [104.33851235900003, 9.685166233000063],
              [104.33903949800003, 9.686688939000108],
              [104.33958130300002, 9.687386366000082],
              [104.34040747000007, 9.687676002000066],
              [104.34343757600006, 9.688692115000029],
              [104.34575562700009, 9.690796467000061],
              [104.34764843900003, 9.693443747000073],
              [104.34831417500004, 9.694968862000051],
              [104.34842097900008, 9.696759420000054],
              [104.34707919900009, 9.702102106000064],
              [104.345902714, 9.705934185000052],
              [104.34598510600007, 9.709100301000094],
              [104.3463665030001, 9.711033290000069],
              [104.34718789500002, 9.711598012000076],
              [104.34787564, 9.711885213000036],
              [104.34843218500004, 9.711757330000113],
              [104.35151385100002, 9.709884761000074],
              [104.35323703900005, 9.706474932000029],
              [104.35514493600007, 9.70045406700004],
              [104.35589593600004, 9.697164941000034],
              [104.35550473000009, 9.695782211000024],
              [104.35482433100002, 9.695082392000119],
              [104.35276123800003, 9.69422089700007],
              [104.35165800400004, 9.693926475000046],
              [104.35111613100007, 9.693229071000074],
              [104.35058402, 9.691981476000054],
              [104.350601082, 9.691018638000058],
              [104.35133057300004, 9.681124937000083],
              [104.35362974000003, 9.668644679000058],
              [104.35407201200003, 9.667138959000086],
              [104.35536228200003, 9.664684938000077],
              [104.35625780300005, 9.661865910000065],
              [104.3562679720001, 9.661291083000021],
              [104.35587705100008, 9.660479275000087],
              [104.35525480400003, 9.659370423],
            ],
          ],
          [
            [
              [104.35883776100009, 9.708690897000059],
              [104.35849493200006, 9.708658369000121],
              [104.35823662100009, 9.708668514000072],
              [104.3579534080001, 9.708715606000061],
              [104.35762448900007, 9.708816904000093],
              [104.35726644400002, 9.708997178000088],
              [104.35692325200004, 9.709254269000043],
              [104.35668713900004, 9.709503009000018],
              [104.35648063800002, 9.709811143000112],
              [104.35633289700004, 9.710119769000068],
              [104.35624466000003, 9.710420591000089],
              [104.35619112700005, 9.710894081],
              [104.35621678400003, 9.711262249000095],
              [104.35627807900005, 9.711540590000121],
              [104.35636468200002, 9.711782253000044],
              [104.35652265400003, 9.71208587500003],
              [104.35672272900007, 9.712363989000037],
              [104.35698727700002, 9.712624818000036],
              [104.3573135930001, 9.712856937000057],
              [104.35765058000001, 9.713015301000015],
              [104.35815006400003, 9.713142357000047],
              [104.35849358900003, 9.713167105000052],
              [104.35875012100003, 9.713148856000052],
              [104.35900545600002, 9.713100041000098],
              [104.35925264800005, 9.713024942000102],
              [104.35951443200003, 9.712907827],
              [104.35982905000004, 9.71270907700008],
              [104.36008824599999, 9.712484082000026],
              [104.36029174200004, 9.712244494000098],
              [104.3604609020001, 9.711979921000056],
              [104.36057073600003, 9.711747734000118],
              [104.36066522300004, 9.711448789000075],
              [104.360724831, 9.711083505000049],
              [104.36072758500005, 9.710770206000062],
              [104.36068519700009, 9.71045972900013],
              [104.36060109700006, 9.710157731000011],
              [104.36048808500004, 9.709895813000101],
              [104.36030870900002, 9.709604133000125],
              [104.36006853600006, 9.70932102500006],
              [104.35985412900007, 9.709131494000099],
              [104.35964095900002, 9.708986398000084],
              [104.35938667700003, 9.708854010000055],
              [104.35914521100004, 9.708762409000114],
              [104.35883776100009, 9.708690897000059],
            ],
          ],
          [
            [
              [104.3377122950001, 9.702002203000058],
              [104.33705547200002, 9.701819698000056],
              [104.33661713300008, 9.701927594000013],
              [104.33628811100003, 9.702144421000101],
              [104.3358852210001, 9.702796053000016],
              [104.33573762100005, 9.703593144000065],
              [104.33569709800007, 9.705622768000042],
              [104.33488220500006, 9.711529082000057],
              [104.33469850500005, 9.712108637000048],
              [104.33477010600011, 9.712833672000086],
              [104.33513307500003, 9.71392172100005],
              [104.33549511200005, 9.715480945000097],
              [104.33600509100006, 9.716079975000101],
              [104.33647984400008, 9.716026532000031],
              [104.33677272400007, 9.715628402000041],
              [104.33691983600008, 9.715085015000023],
              [104.33710596100011, 9.713273135000032],
              [104.33716305200004, 9.712095297000099],
              [104.33767977300008, 9.709269202000124],
              [104.33794018000003, 9.706805063000077],
              [104.33831091200007, 9.703942441000011],
              [104.33834883500001, 9.703217630000081],
              [104.33831311100008, 9.702818863000038],
              [104.33816778100004, 9.702456136000047],
              [104.3377122950001, 9.702002203000058],
            ],
          ],
          [
            [
              [104.34709495700007, 9.719612953000121],
              [104.3467003160001, 9.719521761000037],
              [104.34633580300004, 9.719551217000076],
              [104.346092668, 9.71964119700006],
              [104.345727637, 9.719941980000078],
              [104.34560551200011, 9.720273370000053],
              [104.34465405000007, 9.725396739000034],
              [104.34443933900008, 9.726481668000096],
              [104.34443781300004, 9.727265508000114],
              [104.34452798300003, 9.727748056000072],
              [104.34483069300003, 9.728261161000072],
              [104.34577056200008, 9.729107103000077],
              [104.34636206400009, 9.729485095000088],
              [104.346696041, 9.729546022000067],
              [104.34733429600006, 9.729306062000108],
              [104.34788179900006, 9.728885030000088],
              [104.34833872300004, 9.728192501000075],
              [104.34843128600005, 9.727438969000078],
              [104.34843327200004, 9.726413935],
              [104.34865153900007, 9.723489974000097],
              [104.3485617640001, 9.722796400000087],
              [104.34833535800001, 9.72208747700005],
              [104.34776135500005, 9.72051867500001],
              [104.34745876400005, 9.719945284000085],
              [104.34709495700007, 9.719612953000121],
            ],
          ],
          [
            [
              [104.33581156900004, 9.727981351000013],
              [104.33546913000004, 9.727945569000072],
              [104.33509634000009, 9.727959711],
              [104.33484232700005, 9.728007498000045],
              [104.33459510800002, 9.728082570000128],
              [104.33433329100002, 9.728199658000046],
              [104.33401862500001, 9.728398375000056],
              [104.33375938000006, 9.728623344000107],
              [104.33355583700009, 9.728862914000034],
              [104.33338662800003, 9.729127472000075],
              [104.3332767540001, 9.729359653000101],
              [104.33318221899999, 9.729658596000055],
              [104.33312255700012, 9.730023884000081],
              [104.33311976100006, 9.73033719400007],
              [104.33316211100002, 9.730647688000092],
              [104.33324618000005, 9.730949708000123],
              [104.33335916900008, 9.731211651000125],
              [104.33353852500008, 9.731503366000103],
              [104.33377868700006, 9.731786516000083],
              [104.33399309400004, 9.731976081000047],
              [104.33420627, 9.732121209000065],
              [104.33446056499999, 9.732253633000097],
              [104.33478527600003, 9.732367597000023],
              [104.33515170700007, 9.732436838000067],
              [104.33540988100002, 9.732450986000083],
              [104.33578031700003, 9.732414105000052],
              [104.33622294800006, 9.732290936000075],
              [104.33658105300002, 9.732110690000082],
              [104.336924309, 9.73185362200004],
              [104.33716047100009, 9.731604894000066],
              [104.33736702200004, 9.731296767000062],
              [104.33751480700001, 9.730988142000017],
              [104.33760308000004, 9.730687317000085],
              [104.33765666200003, 9.730213815000075],
              [104.33763103500003, 9.729845631000076],
              [104.33756976000002, 9.729567276000049],
              [104.33748317100006, 9.729325597000036],
              [104.33732521500005, 9.729021951000048],
              [104.33712515000011, 9.728743810000127],
              [104.33686060500006, 9.728482948000037],
              [104.33653428800005, 9.728250791000013],
              [104.33619729200007, 9.728092388000052],
              [104.33581156900004, 9.727981351000013],
            ],
          ],
          [
            [
              [104.35422989200003, 9.736689937000074],
              [104.35391470400008, 9.736641187000126],
              [104.35362349000005, 9.736736929000054],
              [104.3534653020001, 9.73702551200004],
              [104.35347390600002, 9.738855107000076],
              [104.35361501600005, 9.741142347000052],
              [104.35390373100006, 9.742346552000035],
              [104.35416958900002, 9.742828533000123],
              [104.354387305, 9.743117823000079],
              [104.35472656500011, 9.743262900000092],
              [104.35526007200011, 9.743287984000036],
              [104.35674022000009, 9.742905602000105],
              [104.35780816100008, 9.742474294000123],
              [104.35839082100006, 9.742162431000077],
              [104.35870675299999, 9.741826002000041],
              [104.35907146000007, 9.741345219000051],
              [104.35919335800003, 9.741008418000073],
              [104.35914586700009, 9.740478721000033],
              [104.35876049100008, 9.739081744000087],
              [104.35839795000003, 9.738431069000074],
              [104.35820434099999, 9.738214048000058],
              [104.35793775200005, 9.738117254000132],
              [104.35740430200005, 9.738068104000062],
              [104.35485971000001, 9.737076301000068],
              [104.35422989200003, 9.736689937000074],
            ],
          ],
          [
            [
              [104.02741499400003, 9.797180455000019],
              [104.02707207000007, 9.797147377000036],
              [104.02681363300009, 9.797157115000042],
              [104.02653022200009, 9.797203766000083],
              [104.02620099900011, 9.797304559000073],
              [104.02584251000003, 9.797484295000023],
              [104.02549875700001, 9.797740883000115],
              [104.02526214200006, 9.797989289000062],
              [104.02505505700007, 9.798297145000044],
              [104.02490675600006, 9.798605586000074],
              [104.02481799700008, 9.798906316000087],
              [104.02476367800003, 9.799379796],
              [104.02478875400007, 9.799748064000101],
              [104.02484962700004, 9.80002654600005],
              [104.02493587800006, 9.800268385000075],
              [104.02509342900005, 9.800572306000086],
              [104.02529314200005, 9.800850781000035],
              [104.02555738400007, 9.801112072000072],
              [104.02588346500005, 9.801344744000049],
              [104.02622034200003, 9.801503667000054],
              [104.02671983600005, 9.801631535000064],
              [104.02706346800007, 9.801656831000026],
              [104.02732014, 9.801638985000059],
              [104.02757566300008, 9.80159056700005],
              [104.02782308200004, 9.801515848000085],
              [104.02808516600005, 9.801399128000108],
              [104.0284002380001, 9.801200845000045],
              [104.02865990800009, 9.800976225000023],
              [104.02886387700009, 9.800736921000029],
              [104.0290335350001, 9.800472575000095],
              [104.02914379100007, 9.800240524000079],
              [104.02923879900003, 9.799941681000044],
              [104.02929902100003, 9.799576433000111],
              [104.02930228000012, 9.799263089000119],
              [104.02926037400003, 9.798952495],
              [104.02917672400004, 9.798650316000044],
              [104.02906408500004, 9.798388177000026],
              [104.02888510200006, 9.798096166000024],
              [104.02864528200003, 9.797812632000012],
              [104.02843108800008, 9.797622732000109],
              [104.02821806000003, 9.797477275000064],
              [104.02796388200005, 9.797344463000089],
              [104.02772246000006, 9.797252465000026],
              [104.02741499400003, 9.797180455000019],
            ],
          ],
          [
            [
              [104.65064373900007, 9.800642300000016],
              [104.64918405700008, 9.799032841000075],
              [104.64516758000012, 9.796900499000037],
              [104.64295374300009, 9.796591086000104],
              [104.63937726700001, 9.796279716000099],
              [104.63642774600005, 9.796740262000101],
              [104.63308627400006, 9.797702394000039],
              [104.63219010100008, 9.798126377000036],
              [104.62965546700006, 9.799520253000081],
              [104.62624873400009, 9.800522092000017],
              [104.623546758, 9.800519107000037],
              [104.619759668, 9.799138347000012],
              [104.6165897760001, 9.797436347000053],
              [104.61288126400002, 9.797376359000063],
              [104.61171572200004, 9.798246373000017],
              [104.61105542300004, 9.799505512000016],
              [104.61140772800005, 9.801415973000081],
              [104.61207203300003, 9.804415347000093],
              [104.61351151000012, 9.809603392],
              [104.61444404400001, 9.811242959000042],
              [104.61594113700006, 9.813552926000034],
              [104.62554007900007, 9.821073182000093],
              [104.62959944400004, 9.823170410000069],
              [104.63188910900004, 9.82396919800008],
              [104.63393545300008, 9.824002119000051],
              [104.63508859600009, 9.823893670000048],
              [104.65083593700007, 9.821177317000094],
              [104.65364811300006, 9.820626115000051],
              [104.65526279700006, 9.819217294000014],
              [104.65610832800004, 9.817088331000077],
              [104.6560928000001, 9.815371660000059],
              [104.65577786400006, 9.812691426000074],
              [104.65292703000007, 9.804554175000122],
              [104.65064373900007, 9.800642300000016],
            ],
          ],
          [
            [
              [104.04403182900009, 9.819819185000076],
              [104.04345466000004, 9.819753891000092],
              [104.04311234500004, 9.819816594000129],
              [104.0427480970001, 9.820070226000064],
              [104.04246895100002, 9.820472660000055],
              [104.04233944000003, 9.82089673200006],
              [104.04242393100002, 9.821257743000087],
              [104.04280687500008, 9.821937928000056],
              [104.04323345800003, 9.822321119000053],
              [104.04390550900004, 9.822853568000054],
              [104.04417176700004, 9.823215095000125],
              [104.04432035900007, 9.823597506000057],
              [104.04440466900003, 9.824022184000024],
              [104.04438212700003, 9.824425332000073],
              [104.04446626900007, 9.824913678000067],
              [104.04465780600002, 9.825232545000072],
              [104.04499963400005, 9.825339614000049],
              [104.04540604500002, 9.825298325000096],
              [104.04596238500005, 9.825172561000082],
              [104.04666902200007, 9.824835005000042],
              [104.04703332700011, 9.824560139000125],
              [104.04731234500009, 9.824200155000094],
              [104.04742029900007, 9.823839684000022],
              [104.04733633500004, 9.823287682000091],
              [104.04701765400006, 9.82256523600009],
              [104.04590917300008, 9.82135246200002],
              [104.04469343000004, 9.820266720000069],
              [104.04403182900009, 9.819819185000076],
            ],
          ],
          [
            [
              [104.094433501, 9.844037582000096],
              [104.09379622100002, 9.843682834000054],
              [104.09345118600007, 9.843738947000079],
              [104.09310585200009, 9.843909141000038],
              [104.09270132500002, 9.844706632000031],
              [104.09209404600006, 9.846073977000101],
              [104.09122547400004, 9.848410318000054],
              [104.09113746000004, 9.849066052000042],
              [104.09122188500001, 9.849722246000068],
              [104.0913926050001, 9.850350152000063],
              [104.0917071060001, 9.85094993000004],
              [104.09233712700009, 9.851778713000046],
              [104.09268139300006, 9.852007812000084],
              [104.09394486300006, 9.852410482],
              [104.09509400500011, 9.85258468500003],
              [104.09595621000004, 9.852586996000044],
              [104.09670406400001, 9.852360836000072],
              [104.09710735500008, 9.852019674000088],
              [104.09730986600005, 9.851535365000073],
              [104.0972547810001, 9.850651082000093],
              [104.0969413550001, 9.849652030000032],
              [104.096487182, 9.847568819000061],
              [104.096287472, 9.847026390000034],
              [104.09591541100004, 9.846454989000096],
              [104.09520630800007, 9.845176218000072],
              [104.094433501, 9.844037582000096],
            ],
          ],
          [
            [
              [103.99173327800001, 9.901882967000029],
              [103.99139060900008, 9.901846610000041],
              [103.99101748100003, 9.901860143000095],
              [103.99076317400004, 9.901907527000018],
              [103.99051562100007, 9.90198221600007],
              [103.99025338700004, 9.902098910000044],
              [103.98993812300007, 9.902297172000106],
              [103.98967828400006, 9.902521784000038],
              [103.98947416900005, 9.902761094000121],
              [103.98930437500003, 9.903025456000057],
              [103.98919402000006, 9.903257529000074],
              [103.98909890500009, 9.903556409000112],
              [103.98903858200006, 9.903921713000051],
              [103.98903526000007, 9.904235115000047],
              [103.98907712600007, 9.904545775000083],
              [103.98916076100005, 9.904848028000044],
              [103.98927340700004, 9.905110238000068],
              [103.98945242700003, 9.905402339000078],
              [103.98969231800007, 9.905685972000098],
              [103.98990658900004, 9.905875949000096],
              [103.99011970200009, 9.906021473000058],
              [103.99037399000008, 9.906154357000039],
              [103.99069878500003, 9.906268889000012],
              [103.99106540900009, 9.906338755000109],
              [103.99132377700005, 9.906353332000071],
              [103.99169458700001, 9.906317049000089],
              [103.99213779800009, 9.90619456900008],
              [103.99249650600009, 9.906014856000043],
              [103.99284048100004, 9.905758273000041],
              [103.99307725800001, 9.905509856],
              [103.99328449900001, 9.905201973000109],
              [103.99343292500004, 9.904893496000076],
              [103.99352177500002, 9.904592721000059],
              [103.99357619400008, 9.904119160000045],
              [103.99355116100006, 9.903750820000122],
              [103.99349030000008, 9.903472277000079],
              [103.99340404200005, 9.903230381000048],
              [103.99324646000007, 9.902926381000031],
              [103.99304669100006, 9.902647824000075],
              [103.99278236000004, 9.902386445000031],
              [103.99245615600005, 9.902153679000046],
              [103.99211914000006, 9.901994673000035],
              [103.99173327800001, 9.901882967000029],
            ],
          ],
          [
            [
              [104.0209258790001, 9.906549390000039],
              [104.02058277400005, 9.906516296000055],
              [104.02032419900006, 9.90652602700006],
              [104.02004063600005, 9.906572678000101],
              [104.01971123500006, 9.906673481],
              [104.01935255000004, 9.906853241000045],
              [104.0190086060001, 9.907109869000051],
              [104.01877185800005, 9.90735831600009],
              [104.01856465300004, 9.907666225000076],
              [104.01841626300002, 9.907974722000022],
              [104.01832744800005, 9.90827550900004],
              [104.01827308499999, 9.908749079000055],
              [104.01829816200002, 9.909117420000067],
              [104.01835905900001, 9.909395958000021],
              [104.01844534900003, 9.909637847000051],
              [104.01860297300001, 9.909941832000067],
              [104.01880278300003, 9.910220368000109],
              [104.01906715800006, 9.910481718000062],
              [104.01939340500005, 9.910714445000044],
              [104.01973045600005, 9.910873410000052],
              [104.0202302110001, 9.911001319000066],
              [104.02057402500003, 9.911026630000029],
              [104.02083083400005, 9.911008789000062],
              [104.02108649400007, 9.910960369000053],
              [104.02133404700004, 9.910885643000087],
              [104.02159627400006, 9.91076890900011],
              [104.02191152000003, 9.910570597000044],
              [104.02217133400003, 9.910345941000019],
              [104.02237541900004, 9.910106596000022],
              [104.02254517600005, 9.909842204000084],
              [104.02265549800003, 9.909610111000065],
              [104.02275056600008, 9.909311212000114],
              [104.0228108310001, 9.908945895000086],
              [104.02281410200001, 9.908632490000089],
              [104.0227721840001, 9.908321834000054],
              [104.02268849800001, 9.908019593000093],
              [104.02257580800001, 9.907757399000071],
              [104.02239673900003, 9.907465326000063],
              [104.02215680100008, 9.907181729000046],
              [104.02194249900002, 9.90699178500005],
              [104.02172936200006, 9.906846293000092],
              [104.02147505300007, 9.906713447000113],
              [104.02123350600007, 9.906621424000138],
              [104.0209258790001, 9.906549390000039],
            ],
          ],
          [
            [
              [104.01614486700002, 9.913875962000061],
              [104.015446696, 9.912998839000052],
              [104.01497737200006, 9.912409199000114],
              [104.01458352800007, 9.911266700000086],
              [104.01422669500003, 9.910898774000108],
              [104.013605979, 9.910732182000091],
              [104.01313620700004, 9.910826415000072],
              [104.01229460100004, 9.911326291000043],
              [104.01113336100006, 9.911568991000051],
              [104.00957986900005, 9.911893657000091],
              [104.00900113300003, 9.912243758000015],
              [104.00893915200005, 9.91275848400006],
              [104.00903310300001, 9.913276192000085],
              [104.00943992000001, 9.913748261000064],
              [104.010167584, 9.913762148000059],
              [104.011410633, 9.913455354000076],
              [104.01240955500005, 9.913443721000091],
              [104.01344508800003, 9.913669812000057],
              [104.01431829700006, 9.914420473000083],
              [104.01533925000007, 9.915202014000089],
              [104.01580205099999, 9.915468777000045],
              [104.01616689000008, 9.915424117000081],
              [104.01648574500007, 9.915069050000101],
              [104.01654670100005, 9.914605886000059],
              [104.01614486700002, 9.913875962000061],
            ],
          ],
          [
            [
              [103.98876397800009, 9.909860469000085],
              [103.98771358600004, 9.909840340000107],
              [103.98653307500007, 9.910026272000037],
              [103.98551656400006, 9.910423896000074],
              [103.98462202700004, 9.911032406000063],
              [103.98414846100012, 9.91160727900011],
              [103.98375486400005, 9.912392248000044],
              [103.98370144700007, 9.912975181000137],
              [103.98369249700005, 9.913433836000072],
              [103.98389526100004, 9.913813131000014],
              [103.9841441020001, 9.913984748000091],
              [103.984893083, 9.9143745200001],
              [103.98714491300007, 9.915293632000031],
              [103.98760466000009, 9.915427585000101],
              [103.98802319500008, 9.915519028000029],
              [103.99182420900007, 9.914590760000051],
              [103.99289888500009, 9.914444485000027],
              [103.99317605200011, 9.914241232000085],
              [103.99324719400003, 9.913825477000058],
              [103.99294667900004, 9.913068925000077],
              [103.99196604600009, 9.912069190000048],
              [103.99031961500002, 9.910507993000104],
              [103.98955902200005, 9.910042552000052],
              [103.98876397800009, 9.909860469000085],
            ],
          ],
          [
            [
              [104.02259204400012, 9.916899544000033],
              [104.02194447800009, 9.916887240000081],
              [104.02144587200004, 9.916902488000099],
              [104.02074323400009, 9.917161107000093],
              [104.020239847, 9.917423521000044],
              [104.01990841600012, 9.917812819000103],
              [104.01975085200004, 9.918230155000083],
              [104.01956120799998, 9.919017757000058],
              [104.01942763400004, 9.920770719000059],
              [104.01944537200009, 9.921141934000042],
              [104.01966943300006, 9.921473811000057],
              [104.02015955100002, 9.921575846000062],
              [104.02043448000003, 9.921531626000045],
              [104.0207680720001, 9.921351651000075],
              [104.02119411800001, 9.920903202000096],
              [104.02295671100009, 9.919947678000083],
              [104.02323641400005, 9.919656284000043],
              [104.02399966300005, 9.91843155600005],
              [104.02411404200011, 9.918078175000032],
              [104.02409605800004, 9.917719319000094],
              [104.02402611800002, 9.917470741000043],
              [104.02383116200005, 9.917244511000066],
              [104.02345995100008, 9.917113826000088],
              [104.02259204400012, 9.916899544000033],
            ],
          ],
          [
            [
              [103.98011360300005, 9.918018419000061],
              [103.97977048600008, 9.917985258000071],
              [103.979511894, 9.917994939000073],
              [103.97922830700007, 9.918041536000109],
              [103.97889886800007, 9.918142277000095],
              [103.97854012700006, 9.918321970000042],
              [103.97819611300011, 9.918578536000043],
              [103.97795930200006, 9.918826942000079],
              [103.97775202500004, 9.919134817000062],
              [103.97760356500002, 9.919443291000095],
              [103.97751468600003, 9.919744066000112],
              [103.97746022600006, 9.920217636000038],
              [103.97748523100009, 9.920585989000051],
              [103.97754607600007, 9.920864545000095],
              [103.97763232300009, 9.921106456000038],
              [103.97778989600008, 9.921410477000057],
              [103.977989662, 9.921689058000103],
              [103.9782539990001, 9.92195046400006],
              [103.978580218, 9.922183261000049],
              [103.97891725700001, 9.922342294000062],
              [103.97941701400005, 9.922470303000084],
              [103.97976084300008, 9.922495681000052],
              [103.98001766900011, 9.92247789],
              [103.98027335300003, 9.92242952],
              [103.980520934, 9.922354840000123],
              [103.98078319900003, 9.922238155000061],
              [103.98109850200009, 9.922039900000089],
              [103.9813583760001, 9.921815290000067],
              [103.98156251900011, 9.921575980000073],
              [103.98173233800004, 9.921311615000137],
              [103.98184271200003, 9.921079540000031],
              [103.98193784500009, 9.920780654000081],
              [103.98199818600001, 9.920415341000053],
              [103.98200151900002, 9.920101929000056],
              [103.98195966000003, 9.919791259000109],
              [103.98187603000012, 9.919488996000057],
              [103.98176338500004, 9.919226775000121],
              [103.98158436400006, 9.918934661000021],
              [103.98134446800002, 9.918651011000089],
              [103.98113019200005, 9.91846102200009],
              [103.9809170720001, 9.918315485000038],
              [103.980662776, 9.918182587000056],
              [103.98042123400009, 9.918090515000076],
              [103.98011360300005, 9.918018419000061],
            ],
          ],
          [
            [
              [104.0013706180001, 9.916309928000098],
              [104.00109453200007, 9.916146964000026],
              [104.00081758300007, 9.916270859000063],
              [103.99834696500002, 9.918433633000044],
              [103.99751581200009, 9.918905089000061],
              [103.99660973500008, 9.91920171200009],
              [103.9956278650001, 9.919622818000027],
              [103.99446936200009, 9.920242950000089],
              [103.99404093600008, 9.92056594100008],
              [103.99386424800011, 9.920789909000048],
              [103.99373754400003, 9.921113800000024],
              [103.99376185300008, 9.921388263000084],
              [103.99393655900005, 9.921812835000042],
              [103.99463875600007, 9.9224135960001],
              [103.99574354300009, 9.922915786000084],
              [103.99810438200002, 9.923795905000075],
              [103.99894612600004, 9.923985492000041],
              [103.99929814200003, 9.923986542000039],
              [103.99997768800003, 9.923764080000106],
              [104.00038120100002, 9.923366175000046],
              [104.00055826900011, 9.923017489000062],
              [104.00077754800004, 9.921184739000031],
              [104.00131523400003, 9.917993482000041],
              [104.00144411700003, 9.916946220000076],
              [104.001470469, 9.916547189000101],
              [104.0013706180001, 9.916309928000098],
            ],
          ],
          [
            [
              [103.99207730700005, 9.927459027000028],
              [103.99173417200008, 9.927425885000039],
              [103.991475569, 9.927435581000042],
              [103.99119197100008, 9.92748219400008],
              [103.99086252299999, 9.927582956000068],
              [103.99050377600007, 9.927762673000105],
              [103.99015976100003, 9.928019262000108],
              [103.98992295400006, 9.928267686000057],
              [103.98971568500005, 9.928575578000041],
              [103.98956723700012, 9.928884066000075],
              [103.98947837000004, 9.929184851000093],
              [103.98942393500008, 9.92965843200002],
              [103.989448963, 9.930026790000033],
              [103.98950982800007, 9.930305347000077],
              [103.98959609200001, 9.930547256000109],
              [103.98975369000009, 9.930851274000039],
              [103.98995348100001, 9.931129848000085],
              [103.99021784600004, 9.931391244000041],
              [103.99054409400003, 9.931624025000117],
              [103.99088115700002, 9.93178304200004],
              [103.99138094400007, 9.93191102400006],
              [103.99172479000002, 9.931936384000027],
              [103.99198162700004, 9.931918577000063],
              [103.99223732000006, 9.931870192000057],
              [103.99248490800002, 9.931795497000094],
              [103.99274717900005, 9.93167879400003],
              [103.99306248500002, 9.931480518000056],
              [103.99332235700004, 9.931255890000033],
              [103.99352649600006, 9.931016565000038],
              [103.99369630800007, 9.930752185000101],
              [103.99380667300005, 9.930520099000082],
              [103.99390179300011, 9.930221203000132],
              [103.99396211500004, 9.929855881000014],
              [103.99396543000005, 9.929542464000106],
              [103.99392355100005, 9.929231791000069],
              [103.99383989900005, 9.928929527000017],
              [103.99372723400006, 9.928667309000081],
              [103.99354818800008, 9.928375199000071],
              [103.99330826500002, 9.92809155900005],
              [103.99309396800007, 9.927901579000052],
              [103.99288083000002, 9.92775605200009],
              [103.99262651400002, 9.92762316600002],
              [103.99238495600011, 9.927531106000041],
              [103.99207730700005, 9.927459027000028],
            ],
          ],
          [
            [
              [104.01740092400004, 9.929265117000073],
              [104.01629874600006, 9.928865546000052],
              [104.0158773590001, 9.929132874000056],
              [104.01513592400003, 9.929807138000067],
              [104.01485254900004, 9.930111509000115],
              [104.01451918200007, 9.931206551000036],
              [104.01432582700006, 9.932235432000079],
              [104.01438717200008, 9.932649622000065],
              [104.01445052499999, 9.932960594000038],
              [104.0146192190001, 9.933204735000077],
              [104.01502730000001, 9.933625526000077],
              [104.01581144600006, 9.934328830000093],
              [104.01674095900006, 9.934690703000102],
              [104.01725971400008, 9.934769409000042],
              [104.01875065200007, 9.934797766000079],
              [104.01917139000007, 9.93456483700008],
              [104.01952144500007, 9.934399399000069],
              [104.02005085500009, 9.933927595000023],
              [104.02072027700005, 9.933389623],
              [104.02123567100007, 9.932745467000091],
              [104.02130298300004, 9.931955122000067],
              [104.02113826500009, 9.93150454300009],
              [104.02087019700011, 9.931017591000034],
              [104.02063214800005, 9.930772138000064],
              [104.01870381500001, 9.930047109000027],
              [104.01740092400004, 9.929265117000073],
            ],
          ],
          [
            [
              [104.02077197600008, 9.941175653000032],
              [104.02036729100007, 9.941067051000106],
              [104.01705759200004, 9.943930557000067],
              [104.01570671900001, 9.945418533000082],
              [104.01457477900006, 9.94610337400008],
              [104.01374781100003, 9.946794017000025],
              [104.01159337200002, 9.947762116000101],
              [104.00912609600007, 9.94912787900004],
              [104.00880350400011, 9.95002994300007],
              [104.00899117900011, 9.950840833000106],
              [104.00938609600009, 9.951453841000022],
              [104.01008991500007, 9.951870902000069],
              [104.01089736100008, 9.952189033000101],
              [104.01188858800002, 9.953519793000075],
              [104.01182211700004, 9.956949600000085],
              [104.01124729700007, 9.960369744],
              [104.01101072600004, 9.962080796000093],
              [104.00882284300012, 9.964763818000042],
              [104.00799970400004, 9.965252707000081],
              [104.00768294500003, 9.96585216300012],
              [104.00805831700009, 9.967473973000017],
              [104.00824209100003, 9.96848664300005],
              [104.00873675000001, 9.969202487000038],
              [104.00964202700004, 9.969724326000049],
              [104.01085623400004, 9.970050208000101],
              [104.01166960900011, 9.970065703000051],
              [104.01248885800004, 9.969778561000096],
              [104.01433655700006, 9.968905487000066],
              [104.01525941800004, 9.968519392000095],
              [104.01744529600006, 9.965937162000047],
              [104.02090577500005, 9.960553520000113],
              [104.02113056800007, 9.959447736000103],
              [104.02080192400007, 9.955404911000086],
              [104.02093675400002, 9.953691945000019],
              [104.02391395800007, 9.946987327000032],
              [104.0239314990001, 9.946079452000054],
              [104.02374377000011, 9.945268592000021],
              [104.02317144700001, 9.943576733000013],
              [104.02226955700007, 9.942616871000086],
              [104.02147773200002, 9.941491805000078],
              [104.02077197600008, 9.941175653000032],
            ],
          ],
          [
            [
              [104.83704776800008, 9.949894637000066],
              [104.83663405800004, 9.949610216000069],
              [104.83551978500005, 9.949704401000046],
              [104.83450087600002, 9.950114435000044],
              [104.83118922200002, 9.951754904000064],
              [104.82755841800008, 9.954847822000088],
              [104.82558293400005, 9.958067886000064],
              [104.82491281800003, 9.961004415000028],
              [104.82502259000005, 9.964115026000096],
              [104.82597597200007, 9.967305033000072],
              [104.82686675900004, 9.968505510000101],
              [104.83517639500005, 9.973803609000063],
              [104.84105214700003, 9.977081359000104],
              [104.84444657300004, 9.978428090000067],
              [104.84763562600003, 9.980070650000082],
              [104.8489395770001, 9.980189932000092],
              [104.85035463900002, 9.979613271000074],
              [104.85147147100005, 9.978832808000069],
              [104.85219270800005, 9.977647765000073],
              [104.85220337700004, 9.976950324000098],
              [104.85160999600006, 9.976343457000068],
              [104.85101357900001, 9.975935862000094],
              [104.85011132300005, 9.975822628000051],
              [104.84761152000007, 9.975087410000059],
              [104.84561382200008, 9.974359727000069],
              [104.844717696, 9.97384794500009],
              [104.84381774200006, 9.97263495],
              [104.84251415500002, 9.969034299000066],
              [104.84041693800005, 9.960822695000092],
              [104.84006951800008, 9.956039205000076],
              [104.83892479300005, 9.952390294000056],
              [104.83704776800008, 9.949894637000066],
            ],
          ],
          [
            [
              [104.49431628000011, 9.975746500000058],
              [104.49397340100008, 9.975710961000049],
              [104.4936001730001, 9.975725392000088],
              [104.49334589000003, 9.975773393000063],
              [104.49309843200007, 9.975848686000076],
              [104.49283638900005, 9.975966024000103],
              [104.49252149800003, 9.976165068000052],
              [104.49226211500006, 9.976390332000038],
              [104.492058511, 9.976630163000076],
              [104.49188930200003, 9.976894966000048],
              [104.491779475, 9.977127333000089],
              [104.49168505600009, 9.977426480000061],
              [104.49162560400006, 9.977791975000104],
              [104.49162304600003, 9.978105425000104],
              [104.491665686, 9.978416025000046],
              [104.49175008900005, 9.978718114000083],
              [104.49186341600002, 9.978980087000087],
              [104.49204321300006, 9.979271796000065],
              [104.49228388200008, 9.97955488900004],
              [104.49249869400009, 9.979744375000086],
              [104.49271223800008, 9.979889406],
              [104.49296694100011, 9.980021696000028],
              [104.49320878400005, 9.980113187000049],
              [104.49358838900011, 9.980198643000014],
              [104.49391755200007, 9.980218417000051],
              [104.49428840500011, 9.980181239000084],
              [104.49473147500001, 9.980057680000074],
              [104.49508987100008, 9.979877083000053],
              [104.49543334200007, 9.979619641000069],
              [104.49566959800009, 9.979370624000071],
              [104.49587616000012, 9.979062204000043],
              [104.49602388500008, 9.978753331000066],
              [104.49611203200001, 9.978452305000117],
              [104.49616531400007, 9.977978554000087],
              [104.49613937300005, 9.977610227000076],
              [104.49607780900001, 9.977331795000042],
              [104.49599093000003, 9.977090075000115],
              [104.49583254900007, 9.976786414000037],
              [104.49563202899999, 9.976508301000118],
              [104.49536696500009, 9.976247524000124],
              [104.49504007600004, 9.976015512000023],
              [104.49470255200001, 9.975857294000077],
              [104.49431628000011, 9.975746500000058],
            ],
          ],
          [
            [
              [104.0248911740001, 9.969591017000122],
              [104.024383388, 9.969581376000035],
              [104.02372913100005, 9.969640953000079],
              [104.023214389, 9.969991191000103],
              [104.022622923, 9.970555965000115],
              [104.02092385700011, 9.972107718000077],
              [104.01725219100007, 9.974341965000109],
              [104.01442726700002, 9.977816328000076],
              [104.01231792900002, 9.981808313000061],
              [104.01148225900006, 9.983736477000081],
              [104.01146133100011, 9.984816130000061],
              [104.01159665400012, 9.985322729000055],
              [104.01188126400004, 9.985616171000087],
              [104.01332661700008, 9.985931706000082],
              [104.01455990600007, 9.985955183000071],
              [104.01543465000006, 9.985755821000096],
              [104.01668047800011, 9.985131489000041],
              [104.0175593960001, 9.984716189000052],
              [104.01844666600005, 9.983869020000034],
              [104.02022674500007, 9.981886778000069],
              [104.02237614400011, 9.978111410000066],
              [104.02370848600003, 9.974464670000048],
              [104.02477270300007, 9.971948879000079],
              [104.02507264400002, 9.971466507000047],
              [104.02509906100008, 9.970098979000051],
              [104.0248911740001, 9.969591017000122],
            ],
          ],
          [
            [
              [104.04096793499998, 9.992237831000049],
              [104.04039965800004, 9.991846295000071],
              [104.03982222600004, 9.991930571000028],
              [104.038462662, 9.992761649000046],
              [104.03690571800004, 9.993874581000027],
              [104.035232658, 9.99603249700005],
              [104.03462585700007, 9.997639405000045],
              [104.03479939300003, 9.998594692000038],
              [104.03537134500002, 9.998795924000079],
              [104.03594879900001, 9.998711659000035],
              [104.03653541900005, 9.998151557000055],
              [104.03839670100004, 9.996187576000091],
              [104.04015284500005, 9.994697604000089],
              [104.04094962500007, 9.993189475000095],
              [104.04114881100003, 9.992812450000075],
              [104.04096793499998, 9.992237831000049],
            ],
          ],
          [
            [
              [104.01002925700001, 9.99177757900007],
              [104.00920080300003, 9.991761791],
              [104.00813769700009, 9.992190017],
              [104.00629533800006, 9.993948884000046],
              [104.00559283700002, 9.995206228000074],
              [104.00527126200006, 9.996246594000089],
              [104.00516970800001, 10.00147717700006],
              [104.00522906800003, 10.002300563000064],
              [104.00544922100005, 10.002603761000016],
              [104.00590113400004, 10.002612381000107],
              [104.00612999200007, 10.002467247000089],
              [104.00643706800007, 10.002174105000082],
              [104.00743070200004, 10.001445540000107],
              [104.00858512100007, 10.00019679000008],
              [104.01134869700012, 9.997558444],
              [104.01249643300005, 9.995366457],
              [104.01254216900001, 9.994292177000117],
              [104.01247843800007, 9.993692966000099],
              [104.01196426300007, 9.993010430000114],
              [104.01092579300003, 9.992168401000077],
              [104.01002925700001, 9.99177757900007],
            ],
          ],
          [
            [
              [104.03429770000008, 9.999727596000072],
              [104.03353386900004, 9.999522727000086],
              [104.03305235100008, 9.999608811000103],
              [104.03217423100007, 10.000353775000102],
              [104.03138963, 10.001063370000017],
              [104.03031836900008, 10.002032210000065],
              [104.02759167199999, 10.00407492500006],
              [104.02649774800007, 10.006053402000068],
              [104.02647936900006, 10.007005069000028],
              [104.02675799700002, 10.00748636400006],
              [104.02732812900003, 10.007782783000073],
              [104.02809933300007, 10.007607007],
              [104.02974686200005, 10.006781441000088],
              [104.03179465300011, 10.005106624000069],
              [104.03395301799999, 10.00267229100009],
              [104.03455799600005, 10.001160532000062],
              [104.03456299700011, 10.000901023000084],
              [104.03457450300007, 10.000304036000072],
              [104.03429770000008, 9.999727596000072],
            ],
          ],
          [
            [
              [104.55068900300006, 10.016228449000051],
              [104.5493939510001, 10.016109924000103],
              [104.54851028800009, 10.016517444000071],
              [104.54732998700004, 10.018559373000089],
              [104.54213564800004, 10.028127728000056],
              [104.54119101200004, 10.029994807],
              [104.54118961800002, 10.030987385000055],
              [104.54189350800009, 10.032856744000094],
              [104.54536085800001, 10.037415700000095],
              [104.5472426530001, 10.039053105000038],
              [104.54971469700008, 10.039640348000132],
              [104.55101001500006, 10.0396421020001],
              [104.55201125100004, 10.039409906000072],
              [104.55313088800003, 10.038710773000046],
              [104.55431050600002, 10.037194292000072],
              [104.55619896200005, 10.033985539000055],
              [104.55814608500003, 10.030893633000025],
              [104.55950332500007, 10.028618332000059],
              [104.55979927100005, 10.027450983000017],
              [104.55962540600004, 10.025407205000112],
              [104.55857134100005, 10.021201926000066],
              [104.55751492500004, 10.018748265000086],
              [104.55675083700005, 10.017813052000067],
              [104.55445602500008, 10.016875787000055],
              [104.55068900300006, 10.016228449000051],
            ],
          ],
          [
            [
              [104.50290788600009, 10.085142202000073],
              [104.50204145500007, 10.084892114000075],
              [104.50106038100007, 10.085026335000078],
              [104.50019270100005, 10.085590421000033],
              [104.50016897700004, 10.086178404000099],
              [104.50066959600008, 10.086970717000019],
              [104.50158078200006, 10.087786274000086],
              [104.50513682400003, 10.089419966000078],
              [104.50575258700009, 10.08951136200009],
              [104.50616339100006, 10.089376274000104],
              [104.50639198200005, 10.089059993000136],
              [104.50646124000011, 10.088517314000088],
              [104.50637096600009, 10.087883928000084],
              [104.50598421700008, 10.087204868000031],
              [104.50520970400002, 10.086502610000101],
              [104.50404745200005, 10.085777157000029],
              [104.50290788600009, 10.085142202000073],
            ],
          ],
          [
            [
              [104.50934934500006, 10.08861824200009],
              [104.50900585900006, 10.088585938000081],
              [104.50874707900005, 10.088596275000048],
              [104.50846337600002, 10.088643603000056],
              [104.50813392500004, 10.088745206000025],
              [104.50777535000003, 10.088925860000051],
              [104.50743171200003, 10.089183371000042],
              [104.5071953450001, 10.089432448000045],
              [104.50698868800005, 10.089740938000078],
              [104.50684090000007, 10.090049878000061],
              [104.50675272100005, 10.090350967000104],
              [104.506699435, 10.090824813000053],
              [104.50672540900011, 10.091193211000069],
              [104.50678702100006, 10.091471695000019],
              [104.50687396100005, 10.091713458000038],
              [104.50703244700003, 10.092017171000032],
              [104.50723309500002, 10.092295329000043],
              [104.50749832300002, 10.09255614300004],
              [104.50782541100008, 10.092788183000055],
              [104.50816313700005, 10.09294641100009],
              [104.50866363700007, 10.0930731940001],
              [104.50900781500005, 10.093097710000086],
              [104.50926480700008, 10.093079264000069],
              [104.50952057800001, 10.093030233000098],
              [104.50976817200007, 10.092954906000083],
              [104.510030353, 10.092837523000052],
              [104.51034540700005, 10.092638413000097],
              [104.51060491700011, 10.092413080000105],
              [104.51080861300009, 10.092173184000062],
              [104.51097789200007, 10.091908312000085],
              [104.51108776000009, 10.091675889000038],
              [104.51118220200001, 10.091376674000061],
              [104.51124165300004, 10.091011102000012],
              [104.51124418200007, 10.09069759],
              [104.511201489, 10.09038693400006],
              [104.51111701200003, 10.090084794000107],
              [104.51100359900006, 10.0898227810001],
              [104.51082367800009, 10.08953103300003],
              [104.51058285500007, 10.089247907000052],
              [104.51036791300004, 10.089058403000093],
              [104.51015424300004, 10.088913363000083],
              [104.50989939400009, 10.088781069000062],
              [104.50965741500005, 10.088689580000041],
              [104.50934934500006, 10.08861824200009],
            ],
          ],
          [
            [
              [104.51131803600006, 10.096405978000076],
              [104.51092975500008, 10.096286904000083],
              [104.51045156800004, 10.09634543600013],
              [104.50994282500005, 10.096848306000082],
              [104.50946297300004, 10.098002978000052],
              [104.50922221300002, 10.099128365000086],
              [104.50919103300001, 10.099987437000022],
              [104.50930314500005, 10.104875733000094],
              [104.50924185200005, 10.105882892000027],
              [104.50983784100006, 10.106979899000111],
              [104.51070325500008, 10.107751431000088],
              [104.51127075200006, 10.107930023000032],
              [104.51168919400004, 10.107871396000066],
              [104.51426120700003, 10.106453206000053],
              [104.51468013800006, 10.106068691000035],
              [104.514770668, 10.10547632800005],
              [104.51465207300005, 10.104854031000075],
              [104.51408578200008, 10.10387557000003],
              [104.51304246000009, 10.102185404000073],
              [104.51244963900007, 10.098985033000039],
              [104.51221286400002, 10.097473801000055],
              [104.51179564300006, 10.096732558],
              [104.51131803600006, 10.096405978000076],
            ],
          ],
          [
            [
              [104.50308699200008, 10.108340924000101],
              [104.50248285700009, 10.107987385000071],
              [104.50148752600006, 10.107597994000093],
              [104.50059836300004, 10.107561382000084],
              [104.49913927300003, 10.108052847000069],
              [104.49867699400005, 10.107981620000126],
              [104.49810871600002, 10.107451825000078],
              [104.497399043, 10.106357604000047],
              [104.49693872600002, 10.105016926000053],
              [104.49647788400009, 10.104028875000013],
              [104.49576739400001, 10.103463591000137],
              [104.49509175600005, 10.103356770000092],
              [104.49452238800002, 10.10353220900013],
              [104.49434354700006, 10.104166650000044],
              [104.49572231800001, 10.10956391600004],
              [104.49618108100003, 10.111891942000016],
              [104.49657075900004, 10.112915149000033],
              [104.49731672200004, 10.113551009000064],
              [104.49849018600005, 10.113764372000043],
              [104.49937964300001, 10.113624678000031],
              [104.50026991500005, 10.112956044000041],
              [104.50333472600005, 10.109152333000091],
              [104.50337093900004, 10.108729243000079],
              [104.50308699200008, 10.108340924000101],
            ],
          ],
          [
            [
              [104.49674244900004, 10.122667558000041],
              [104.49647160000009, 10.122650361000039],
              [104.49625966300006, 10.122851436000067],
              [104.49325786200002, 10.126153145000048],
              [104.49277427100006, 10.126840511000077],
              [104.49243467300003, 10.127477756000046],
              [104.49221357200004, 10.128115188000029],
              [104.49219529300004, 10.128971113000061],
              [104.49238031500006, 10.129743428000014],
              [104.49278569000003, 10.130348263000053],
              [104.49332685600004, 10.130735110000087],
              [104.49405458500004, 10.13092085200009],
              [104.49464729900005, 10.130821064000093],
              [104.49519811400006, 10.130452672000077],
              [104.49691124800006, 10.128424510000043],
              [104.49724856100008, 10.127321904000029],
              [104.49736727200005, 10.123692304000096],
              [104.49735088500002, 10.123339827000065],
              [104.497114493, 10.122936665000013],
              [104.49674244900004, 10.122667558000041],
            ],
          ],
          [
            [
              [104.54097302900004, 10.123465100000127],
              [104.54042073600009, 10.123433904000121],
              [104.53959154200004, 10.123919451000061],
              [104.53879270300003, 10.124648392000067],
              [104.53836175800008, 10.125590784000059],
              [104.53938523900003, 10.128725386000069],
              [104.53996376200011, 10.131889779000057],
              [104.53990034400002, 10.133319385000037],
              [104.54014431500003, 10.13438439900005],
              [104.54087939000001, 10.135358834000028],
              [104.54146199500008, 10.13566383900004],
              [104.54215372700003, 10.135693375000086],
              [104.543058317, 10.135209778000041],
              [104.54376551500008, 10.13420693400011],
              [104.54401242400007, 10.133203450000037],
              [104.54291691400003, 10.126722650000096],
              [104.54245791800007, 10.12580943400012],
              [104.54181564400004, 10.124318004000086],
              [104.5413408520001, 10.123739385000084],
              [104.54097302900004, 10.123465100000127],
            ],
          ],
          [
            [
              [104.55238948000006, 10.132676390000126],
              [104.55204632900009, 10.132640937000035],
              [104.55167282000009, 10.132655475000083],
              [104.551418357, 10.132703560000065],
              [104.55117073100003, 10.132778941000085],
              [104.55090852200001, 10.132896384000032],
              [104.55059344700005, 10.133095569000082],
              [104.55033392900008, 10.133320967000079],
              [104.55013023800009, 10.133560918000038],
              [104.54996097500003, 10.133825841000109],
              [104.549851129, 10.134058301000069],
              [104.549756723, 10.134357554000049],
              [104.54969732800006, 10.134723165000102],
              [104.54969485700005, 10.13503670100002],
              [104.54973761800002, 10.135347373000057],
              [104.54982217, 10.135649521000099],
              [104.54993565700008, 10.135911533000106],
              [104.55011567400004, 10.136203271000086],
              [104.550356607, 10.136486374000063],
              [104.55057163600003, 10.136675852000019],
              [104.55078538400004, 10.136820863000116],
              [104.55104031900008, 10.136953118000045],
              [104.551365789, 10.137066826000039],
              [104.55173301400001, 10.137135738000056],
              [104.55199171100001, 10.137149628000051],
              [104.55236283600007, 10.137112337000074],
              [104.55280620900008, 10.136988622000052],
              [104.55316482800001, 10.136807876000107],
              [104.55350848900011, 10.13655027000002],
              [104.55374485400003, 10.136301120000011],
              [104.55395148700008, 10.13599255900006],
              [104.55409923700005, 10.135683562000073],
              [104.55418736600005, 10.135382430000027],
              [104.55424055500001, 10.134908535000074],
              [104.55421449000008, 10.134540116000055],
              [104.55415280100003, 10.134261625000105],
              [104.55406578700003, 10.134019864],
              [104.55390720000005, 10.133716165000093],
              [104.55370644800004, 10.133438033000084],
              [104.55344110800003, 10.133177258000091],
              [104.55311390500005, 10.132945274000081],
              [104.55277607800008, 10.13278710700005],
              [104.55238948000006, 10.132676390000126],
            ],
          ],
          [
            [
              [104.49425764700005, 10.135697908000017],
              [104.49367049100006, 10.135632289000094],
              [104.49312659800009, 10.135717741000059],
              [104.49264782000009, 10.135889574000066],
              [104.49223413600005, 10.136169378000124],
              [104.49203796900008, 10.136427937000024],
              [104.49190681600005, 10.13683760100008],
              [104.4919063080001, 10.137161189000031],
              [104.49197098800003, 10.13752801],
              [104.49216609400001, 10.137938180000015],
              [104.492361272, 10.138305217],
              [104.49279563100006, 10.138715754000048],
              [104.49305640200005, 10.138867164000052],
              [104.49355649500004, 10.13897579800007],
              [104.49414378300006, 10.138955134000048],
              [104.49464620800005, 10.138773585000125],
              [104.49491190600001, 10.138527710000032],
              [104.49514645900005, 10.13834507800002],
              [104.49531989900001, 10.137943043000062],
              [104.49538616000008, 10.137295982000081],
              [104.495343437, 10.136799759000057],
              [104.49506152600004, 10.136260022000075],
              [104.49469238500005, 10.135871152000052],
              [104.49425764700005, 10.135697908000017],
            ],
          ],
          [
            [
              [104.54450484900005, 10.135197684000017],
              [104.54416127900005, 10.135165435000102],
              [104.54390244200003, 10.135175817000073],
              [104.54358727500008, 10.135231061000114],
              [104.54331541400005, 10.135312993000055],
              [104.54308429800003, 10.135463831000012],
              [104.54283266400007, 10.135565702000092],
              [104.54261976400005, 10.135731716000061],
              [104.54238814300007, 10.135969132000076],
              [104.5421438490001, 10.136320866000046],
              [104.54199608000005, 10.136629855000121],
              [104.54190793300003, 10.136930982000079],
              [104.54185471500007, 10.137404876000032],
              [104.541880758, 10.137773299000051],
              [104.54194243200004, 10.13805179400009],
              [104.54202943300005, 10.138293562000021],
              [104.54218800700002, 10.138597273000103],
              [104.54238874800002, 10.138875418000024],
              [104.54265408100004, 10.139136209000108],
              [104.54298128200003, 10.139368213000031],
              [104.54331911200009, 10.139526397000063],
              [104.54381974600003, 10.139653105000066],
              [104.54416400700009, 10.139677566000048],
              [104.54442105400005, 10.139659076000028],
              [104.54467687500008, 10.139609998000052],
              [104.54492451100005, 10.139534623000122],
              [104.54518673200008, 10.139417187000086],
              [104.54550182300002, 10.139218007000038],
              [104.54576135300002, 10.13899261400004],
              [104.54596505500008, 10.138752664000082],
              [104.54613432700005, 10.138487742000011],
              [104.54624417900008, 10.138255282000051],
              [104.54633859200007, 10.137956028000071],
              [104.5463979940001, 10.137590417000107],
              [104.54640047000004, 10.137276880000099],
              [104.54635771400007, 10.136966206000062],
              [104.54627316600008, 10.136664056000109],
              [104.54615968300001, 10.136402041000013],
              [104.54597967200004, 10.136110300000032],
              [104.54573874600001, 10.135827192000056],
              [104.54552372200007, 10.13563770900001],
              [104.54530997900005, 10.13549269400009],
              [104.54505505000002, 10.135360431000072],
              [104.54481300100005, 10.135268977000054],
              [104.54450484900005, 10.135197684000017],
            ],
          ],
          [
            [
              [104.49678950200011, 10.13810295800006],
              [104.49644635200005, 10.13806741300005],
              [104.49607283000006, 10.13808185200009],
              [104.49581834700004, 10.138129869000066],
              [104.49549109100006, 10.138238439000078],
              [104.49516351900004, 10.138374865000086],
              [104.494928966, 10.138557498000099],
              [104.49466326800004, 10.138803373000103],
              [104.49441976600005, 10.13915386900006],
              [104.49425071100011, 10.139484207000036],
              [104.4941562220001, 10.139783438000014],
              [104.49409672700006, 10.140149037000066],
              [104.49409417100003, 10.140462575000074],
              [104.49413684800001, 10.140773261000023],
              [104.49422132200007, 10.141075434000067],
              [104.49433474100005, 10.141337479000077],
              [104.49451468400009, 10.141629267000061],
              [104.49475554600004, 10.141912436000043],
              [104.49497052900006, 10.142101973000093],
              [104.49518424400007, 10.142247042000106],
              [104.49543915000002, 10.142379367000041],
              [104.49576459800004, 10.142493162000042],
              [104.49613181400005, 10.142562172000067],
              [104.49639051300005, 10.142576131000068],
              [104.49676165900011, 10.142538938000099],
              [104.49720507700003, 10.142415339000086],
              [104.49756375400004, 10.142234687000061],
              [104.49790749300007, 10.14197716900007],
              [104.49814393200009, 10.141728080000066],
              [104.49835065400005, 10.141419571000119],
              [104.49849849200002, 10.141110610000046],
              [104.49858670500004, 10.14080949900009],
              [104.49864002300009, 10.140335614000049],
              [104.49861405700008, 10.13996718400003],
              [104.49855244100004, 10.139688675000079],
              [104.49846549100005, 10.139446888000057],
              [104.49830698100007, 10.139143144000061],
              [104.498106299, 10.138864956000047],
              [104.49784102300006, 10.138604109000047],
              [104.49751387400009, 10.138372036000119],
              [104.49717608100012, 10.138213778000081],
              [104.49678950200011, 10.13810295800006],
            ],
          ],
          [
            [
              [104.53075830100006, 10.140120769000037],
              [104.53041472600006, 10.14008849700012],
              [104.53015588200005, 10.140098862000089],
              [104.5298721130001, 10.140146223000011],
              [104.5295425890001, 10.140247869000072],
              [104.5291839440001, 10.140428575000014],
              [104.52884024600007, 10.140686143],
              [104.52860384600005, 10.140935267000105],
              [104.52839717000009, 10.141243805000054],
              [104.52824937700004, 10.141552787000039],
              [104.52816120700003, 10.141853911000085],
              [104.52810795700006, 10.142327805000127],
              [104.52813397600008, 10.142696233000057],
              [104.52819563200003, 10.142974735000097],
              [104.52828262000003, 10.143216511000029],
              [104.5284411760001, 10.143520234000023],
              [104.52864190400001, 10.143798395000124],
              [104.52890722600003, 10.144059206000032],
              [104.52923442000009, 10.144291232000045],
              [104.52957224700006, 10.144449440000079],
              [104.53007288500001, 10.144576182],
              [104.53041715200007, 10.144600666000068],
              [104.53067420700003, 10.144582192000049],
              [104.53093003600006, 10.144533131000076],
              [104.53117768400003, 10.144457771000058],
              [104.53143991900006, 10.144340351000023],
              [104.53175503100002, 10.144141191000065],
              [104.53201458200009, 10.143915813000069],
              [104.53221830500007, 10.143675874000023],
              [104.53238759800004, 10.143410962000042],
              [104.53249746900008, 10.143178507000082],
              [104.53259190400007, 10.142879257000102],
              [104.53265133200001, 10.142513646000049],
              [104.53265382900004, 10.142200106000042],
              [104.53261109300006, 10.141889427000093],
              [104.53252656299999, 10.14158726900005],
              [104.53241309500002, 10.141325245000042],
              [104.53223309900005, 10.14103349000006],
              [104.53199218500001, 10.140750363000082],
              [104.53177717000007, 10.140560865000035],
              [104.53156343100007, 10.140415834000025],
              [104.53130850500003, 10.140283554000094],
              [104.53106645600006, 10.140192082000075],
              [104.53075830100006, 10.140120769000037],
            ],
          ],
          [
            [
              [104.49176826900005, 10.139002785000082],
              [104.49146383000009, 10.138959167000026],
              [104.49118101200004, 10.139001881000096],
              [104.49089798100007, 10.139174009000039],
              [104.49065814900004, 10.139540372000065],
              [104.49059190300007, 10.140165863000037],
              [104.49069918400005, 10.141093626000062],
              [104.49093694300009, 10.142043170000113],
              [104.49119699700003, 10.14264759100003],
              [104.49154423600008, 10.143144288000093],
              [104.4932598930001, 10.144829563000089],
              [104.49354251800004, 10.144916291000071],
              [104.49382531300007, 10.144895163000099],
              [104.49401945100007, 10.144727208000058],
              [104.49419568600008, 10.144507429000081],
              [104.49434855300002, 10.144119360000035],
              [104.49432774600008, 10.14351531400006],
              [104.49419781900008, 10.143148383000076],
              [104.49391577100002, 10.142694932000039],
              [104.49265797300004, 10.140320058],
              [104.49246312700008, 10.139737311000106],
              [104.49213755100006, 10.139305370000072],
              [104.49176826900005, 10.139002785000082],
            ],
          ],
          [
            [
              [104.5204515010001, 10.138998373000073],
              [104.51986181400007, 10.138952604000016],
              [104.51940812800009, 10.138966910000045],
              [104.51857623100001, 10.139100430000079],
              [104.51812230800003, 10.139279408000055],
              [104.51778937600001, 10.13944358300005],
              [104.51746376100007, 10.139772459000083],
              [104.51728909400001, 10.140281196000076],
              [104.51729074900005, 10.144220853000084],
              [104.51738085400005, 10.144645200000111],
              [104.51769785800008, 10.145019917000029],
              [104.51803074300008, 10.145199181000029],
              [104.51830250300002, 10.14518548200005],
              [104.51855220900006, 10.145058604000033],
              [104.52162118500007, 10.140519576000083],
              [104.52169746500007, 10.140070579000058],
              [104.521637576, 10.139666293000092],
              [104.52150181700006, 10.139441535000058],
              [104.52108623300003, 10.139253792000067],
              [104.5204515010001, 10.138998373000073],
            ],
          ],
          [
            [
              [104.54880738300007, 10.140265394000012],
              [104.54830094400009, 10.139967801000081],
              [104.54763275100004, 10.140012548000053],
              [104.54648031000008, 10.140376367000046],
              [104.54578849800004, 10.140832172000012],
              [104.54558042000005, 10.141357155000017],
              [104.54562574700007, 10.141882496000052],
              [104.54659992400012, 10.145423718000044],
              [104.54671399700005, 10.146223204000114],
              [104.54728908100003, 10.146863459000064],
              [104.54800284500001, 10.147184176000044],
              [104.54892428900006, 10.14727680300007],
              [104.54984614100003, 10.147072514000042],
              [104.550929782, 10.146503057000087],
              [104.55150658400002, 10.14591005600005],
              [104.55159966200009, 10.145247882000062],
              [104.55139332700006, 10.144516788000056],
              [104.55038183800004, 10.14291675],
              [104.549267157, 10.140973996000112],
              [104.54880738300007, 10.140265394000012],
            ],
          ],
          [
            [
              [104.55282759300007, 10.148606746000064],
              [104.55233238200005, 10.148514713000088],
              [104.5514335280001, 10.148741867000053],
              [104.54983098100011, 10.149676031000075],
              [104.54867776900008, 10.150565125000098],
              [104.54763974100008, 10.151454379000045],
              [104.54722412900007, 10.15209326600006],
              [104.54731506700004, 10.152961230000111],
              [104.54784347200007, 10.154035346000077],
              [104.54818871700003, 10.15428703800003],
              [104.54867242100009, 10.154379055000094],
              [104.54911042500001, 10.154196954000037],
              [104.54971032100008, 10.153581152000067],
              [104.55261697800006, 10.150958766000072],
              [104.55326325900006, 10.150114640000128],
              [104.55340234700003, 10.149498211000017],
              [104.55324174100005, 10.149018392000107],
              [104.55282759300007, 10.148606746000064],
            ],
          ],
          [
            [
              [104.50466738900009, 10.150411824000026],
              [104.50432380000009, 10.150379509000105],
              [104.50406494200007, 10.150389842000072],
              [104.50378115300003, 10.15043716900008],
              [104.50345160000003, 10.150538775000049],
              [104.50309291300003, 10.150719440000076],
              [104.5027491660001, 10.150976970000068],
              [104.50251272300008, 10.151226069000073],
              [104.50230599700002, 10.151534587000109],
              [104.50215815700005, 10.151843556000093],
              [104.50206994500003, 10.15214467400005],
              [104.50201663200006, 10.152618569000092],
              [104.50204260500007, 10.152987007000112],
              [104.50210423000003, 10.153265521000064],
              [104.50219119100002, 10.153507312000086],
              [104.50234971700009, 10.153811060000082],
              [104.50255041900009, 10.154089251000096],
              [104.502815721, 10.1543501],
              [104.50314290200008, 10.154582171000113],
              [104.50348072600005, 10.154740424000062],
              [104.50398137300009, 10.154867231000074],
              [104.50432565400007, 10.15489175800006],
              [104.50458272400002, 10.154873315000133],
              [104.50483857300004, 10.154824285000073],
              [104.50508624200002, 10.154748955000057],
              [104.50534850500006, 10.154631566000115],
              [104.5056636580001, 10.15443244100007],
              [104.50592325100008, 10.154207091000076],
              [104.50612701400007, 10.153967174000032],
              [104.50629635000004, 10.153702278000052],
              [104.50640625600008, 10.153469833000093],
              [104.50650073400008, 10.153170589000114],
              [104.50656021000003, 10.152804979000061],
              [104.50656274800006, 10.152491434000053],
              [104.50652004900009, 10.152180745000104],
              [104.5064355530001, 10.15187857100006],
              [104.50632211200005, 10.15161652700005],
              [104.50614214400008, 10.151324745000066],
              [104.50590125500004, 10.151041584000085],
              [104.50568625200002, 10.150852055000124],
              [104.5054725210001, 10.150706995000023],
              [104.50521759900005, 10.150574681000089],
              [104.50497555000001, 10.150483178000067],
              [104.50466738900009, 10.150411824000026],
            ],
          ],
          [
            [
              [104.5877978550001, 10.15290969800011],
              [104.58724777100007, 10.152875961000072],
              [104.58578066700009, 10.152956729],
              [104.58496359, 10.153137450000125],
              [104.58426312400003, 10.153384432000044],
              [104.58389595400003, 10.153697940000049],
              [104.58382045100009, 10.154077913000046],
              [104.584003049, 10.15467301800008],
              [104.58443569900001, 10.155284977000088],
              [104.58495209800002, 10.155583075000061],
              [104.58538532600006, 10.1557488590001],
              [104.58598531800007, 10.155865295000053],
              [104.587218976, 10.155833799000023],
              [104.58876956700004, 10.155653978000066],
              [104.58923644600003, 10.155571942000028],
              [104.58986182299999, 10.155390964000064],
              [104.59054547000008, 10.155276136000067],
              [104.59084567700005, 10.155160845000031],
              [104.59096268600004, 10.154913125000052],
              [104.59091303400002, 10.154632145000074],
              [104.59073006000007, 10.154317962000013],
              [104.59051352600009, 10.154168962000119],
              [104.58824776600002, 10.153058988000028],
              [104.5877978550001, 10.15290969800011],
            ],
          ],
          [
            [
              [104.57462703000006, 10.153775885000103],
              [104.57346267700008, 10.153774372000067],
              [104.5724881260001, 10.154008651000089],
              [104.57146581200007, 10.154407730000068],
              [104.57084753100007, 10.154760233000014],
              [104.57003876200011, 10.155395143000055],
              [104.56980038900011, 10.155960119000083],
              [104.56977584000003, 10.156548939000041],
              [104.56991790800006, 10.156925988000063],
              [104.57025017300002, 10.157232631000033],
              [104.57096270500008, 10.157492656000054],
              [104.57485899200006, 10.158063027000084],
              [104.57552430700005, 10.158087447000062],
              [104.57609463000007, 10.158064631000039],
              [104.57989833000002, 10.156774056000105],
              [104.58025509400005, 10.156515409000109],
              [104.5804220160001, 10.156068095000046],
              [104.58035153300004, 10.155455600000083],
              [104.58004347600004, 10.154795687000016],
              [104.57959270300005, 10.154253368000086],
              [104.57926028300008, 10.154064512000092],
              [104.57462703000006, 10.153775885000103],
            ],
          ],
          [
            [
              [104.51929808600005, 10.148730799000115],
              [104.51878888900002, 10.148730046000052],
              [104.5182155680001, 10.149044261000116],
              [104.51643052500003, 10.150931956000074],
              [104.51627734300006, 10.151208975000079],
              [104.51617504200003, 10.151511275000045],
              [104.51617443600006, 10.151914547000016],
              [104.51625008200001, 10.152406137000101],
              [104.516402365, 10.152721416000075],
              [104.51961721500008, 10.156688440000037],
              [104.52035496400002, 10.157701878000045],
              [104.522780772, 10.16094734200008],
              [104.52308601500003, 10.161149410000101],
              [104.52349334700007, 10.161175213000105],
              [104.52381174700004, 10.161087462000038],
              [104.52462757400004, 10.160357719000055],
              [104.52503581500007, 10.159778612000101],
              [104.52506212900002, 10.159198950000102],
              [104.52496072600007, 10.158896352000022],
              [104.52245855899999, 10.155023877000067],
              [104.52210338200008, 10.154166410000085],
              [104.52137975600007, 10.152816931000073],
              [104.52039047400002, 10.150345470000042],
              [104.51970489800006, 10.149109460000092],
              [104.51929808600005, 10.148730799000115],
            ],
          ],
          [
            [
              [104.52894219700008, 10.160774126000137],
              [104.5285990030001, 10.160738632000042],
              [104.52822544200009, 10.160753130000087],
              [104.5279709380001, 10.160801188000066],
              [104.52772327000011, 10.160876545000084],
              [104.52746101200009, 10.16099396400003],
              [104.52714587000004, 10.161193123000077],
              [104.52688629200006, 10.161418503000073],
              [104.52668254500007, 10.161658443000119],
              [104.526513229, 10.161923359000101],
              [104.52640334200007, 10.162155818000061],
              [104.52630888900006, 10.162455075000041],
              [104.52624944400003, 10.162820697000095],
              [104.52624693700008, 10.163134247000103],
              [104.52628966800008, 10.163444939000053],
              [104.52637419700005, 10.163747111000097],
              [104.52648767000002, 10.164009148000018],
              [104.52666767800008, 10.164300920000089],
              [104.52690861200003, 10.164584063000069],
              [104.52712364800007, 10.164773575000117],
              [104.52733740900007, 10.164918616000039],
              [104.52759236400003, 10.16505090600006],
              [104.52783444000008, 10.165142386000079],
              [104.52821440000008, 10.165227806000042],
              [104.52854386300007, 10.165247533000075],
              [104.52891504400004, 10.165210281000013],
              [104.52935849100005, 10.165086609000083],
              [104.52971718000006, 10.164905896000052],
              [104.53006091700009, 10.164648315000056],
              [104.53029734200003, 10.164399180000048],
              [104.53050403900008, 10.164090627000098],
              [104.53065184500005, 10.163781632000022],
              [104.53074002000005, 10.163480496000064],
              [104.53079327000002, 10.16300658400011],
              [104.53076724400009, 10.162638144000089],
              [104.53070557800005, 10.162359634000049],
              [104.53061858000004, 10.162117851000028],
              [104.53046000500007, 10.161814120000122],
              [104.53025925800007, 10.16153595200011],
              [104.52999391200005, 10.161275135000023],
              [104.52966669000007, 10.161043103000098],
              [104.52932883600009, 10.160884891000064],
              [104.52894219700008, 10.160774126000137],
            ],
          ],
          [
            [
              [104.5771705450001, 10.167955829000073],
              [104.57682609800003, 10.167933819000028],
              [104.57645433400008, 10.167972347000019],
              [104.57598623900007, 10.168109616],
              [104.57565351100007, 10.168278183000011],
              [104.57539722400001, 10.168462174000046],
              [104.57518900400001, 10.16865902500008],
              [104.57497589300006, 10.168928022000044],
              [104.57481575600004, 10.169198479000039],
              [104.57470708800003, 10.169462507000036],
              [104.57460914799999, 10.169878429000077],
              [104.57458518000006, 10.170219473000051],
              [104.57461740400007, 10.170559941000066],
              [104.57469840000002, 10.170892353000037],
              [104.57483188300004, 10.171207709000017],
              [104.57500948000008, 10.171500938000031],
              [104.57517146400008, 10.171701274000087],
              [104.57540076100004, 10.171917406000118],
              [104.575680565, 10.172117647000077],
              [104.575909096, 10.172238598000137],
              [104.5762613800001, 10.172362632],
              [104.576628396, 10.172432427],
              [104.57688713900009, 10.17244628200009],
              [104.57714550300007, 10.172426739000072],
              [104.57751116300004, 10.1723497990001],
              [104.57785974800001, 10.172216049000047],
              [104.57811053000006, 10.172076140000017],
              [104.57831845200005, 10.171922978000046],
              [104.5785082080001, 10.171748198000062],
              [104.57867586800003, 10.171552493000123],
              [104.57884859500003, 10.171289739000054],
              [104.57900288700009, 10.170952415000032],
              [104.57909099000001, 10.170651258000072],
              [104.57913212700004, 10.170345556000091],
              [104.57913568000006, 10.170063092000079],
              [104.57910025400007, 10.169780124000026],
              [104.57903142800006, 10.169503265000033],
              [104.57891261600008, 10.169212641000057],
              [104.57874118300002, 10.168915840000102],
              [104.57858341900003, 10.16871222500013],
              [104.57838077500004, 10.168509751000075],
              [104.57813135000001, 10.168316725000091],
              [104.57785665100002, 10.168160980000083],
              [104.57753564900003, 10.168035276000074],
              [104.5771705450001, 10.167955829000073],
            ],
          ],
          [
            [
              [104.57674191800007, 10.172909687000063],
              [104.57639746299999, 10.172887679000018],
              [104.57602568900005, 10.172926207],
              [104.57566407300007, 10.173019994000041],
              [104.57537458900001, 10.173146775000051],
              [104.57505947500005, 10.173346011000104],
              [104.57479993400005, 10.173571454000106],
              [104.57459623100009, 10.173811447000068],
              [104.57440167200006, 10.174127677000032],
              [104.57425287600009, 10.174498111000039],
              [104.57417011600009, 10.174917164000073],
              [104.57416094100003, 10.175287380000062],
              [104.57421144800004, 10.175625573000067],
              [104.574366481, 10.176084184000088],
              [104.57454789399999, 10.176407978000055],
              [104.57476438800005, 10.176674206000056],
              [104.57510855300011, 10.176976078000076],
              [104.57540288300004, 10.177154721000113],
              [104.57564001900002, 10.177258162000056],
              [104.5758878020001, 10.177333210000048],
              [104.57619974600007, 10.177386326000091],
              [104.57645849500007, 10.177400183000083],
              [104.57671686600004, 10.177380636000064],
              [104.5769998280001, 10.1773284710001],
              [104.57727313400005, 10.177239074000076],
              [104.57763180200007, 10.177058272000037],
              [104.57797549900009, 10.176800608000034],
              [104.5782118810001, 10.176551412000022],
              [104.57839126600003, 10.176293066000051],
              [104.57857339200008, 10.175905958000085],
              [104.57866758800006, 10.17557706600005],
              [104.57870597500005, 10.175195409000091],
              [104.57867737200007, 10.174761978000022],
              [104.57861966500009, 10.174511731000011],
              [104.57853260500008, 10.17426996300008],
              [104.5783739510001, 10.173966262000087],
              [104.5781731270001, 10.173688134000079],
              [104.57790770400007, 10.173427374000086],
              [104.57758040900008, 10.173195414000078],
              [104.57735031200005, 10.173077428000086],
              [104.577107031, 10.172989135000064],
              [104.57674191800007, 10.172909687000063],
            ],
          ],
          [
            [
              [104.5908676980001, 10.176756824000053],
              [104.59041336000007, 10.176530960000017],
              [104.58978818500003, 10.176586506000083],
              [104.58930475900009, 10.176895688000085],
              [104.58902004600007, 10.177345939000036],
              [104.58890577300012, 10.177824555000113],
              [104.5887905650001, 10.179035418000113],
              [104.58901651400004, 10.180105903000046],
              [104.58947048900004, 10.180613403000025],
              [104.58998161800005, 10.18086751],
              [104.59063478200009, 10.1811499520001],
              [104.59134493200006, 10.181319830000033],
              [104.59165748800007, 10.181320219000066],
              [104.59191364600012, 10.180982581000107],
              [104.59197147700011, 10.180194080000058],
              [104.59177433500005, 10.178813859000078],
              [104.59134987700001, 10.177433347000074],
              [104.5908676980001, 10.176756824000053],
            ],
          ],
          [
            [
              [104.57031570100003, 10.178266474000084],
              [104.56997205500002, 10.178234265000084],
              [104.56971316500008, 10.178244679000057],
              [104.56942935300006, 10.178292097000073],
              [104.56909978800005, 10.178393812000051],
              [104.56874111100004, 10.178574597000088],
              [104.56839739900002, 10.17883224700009],
              [104.56816100300009, 10.179081431000013],
              [104.56795434800004, 10.179390029000055],
              [104.56780658700008, 10.179699059000045],
              [104.56771845800007, 10.180000219000094],
              [104.56766528900002, 10.18047415300005],
              [104.56769138400006, 10.180842600000071],
              [104.5677531060001, 10.181121108000111],
              [104.56784015600002, 10.181362883000043],
              [104.56799880000008, 10.181666596000037],
              [104.56819961800009, 10.181944737000046],
              [104.56846503900002, 10.182205515000041],
              [104.56879233900001, 10.18243749400005],
              [104.56913025900009, 10.182595648000079],
              [104.56963101400004, 10.182722304000078],
              [104.56997535000002, 10.182746725000056],
              [104.57023244900006, 10.182728201000122],
              [104.5704883170001, 10.182679088000056],
              [104.57073599600008, 10.182603677000122],
              [104.57099825700001, 10.182486200000083],
              [104.57131338800006, 10.182286968000119],
              [104.57157294500004, 10.182061525000028],
              [104.57177665900011, 10.181821533000067],
              [104.57194593300008, 10.181556572000082],
              [104.57205577900002, 10.18132408100003],
              [104.57215017500002, 10.181024794000047],
              [104.57220954300004, 10.18065914800008],
              [104.57221198000008, 10.180345588000071],
              [104.57216917600009, 10.180034897000033],
              [104.57208457300001, 10.179732735000078],
              [104.57197103300004, 10.17947071500007],
              [104.57179094700005, 10.17917897500009],
              [104.57154993500009, 10.178895876000114],
              [104.57133484300006, 10.178706406000069],
              [104.57112103600005, 10.178561406000062],
              [104.57086603800009, 10.178429165000045],
              [104.57062392600004, 10.178337734000118],
              [104.57031570100003, 10.178266474000084],
            ],
          ],
          [
            [
              [104.53083727500011, 10.170373378000084],
              [104.53000960200011, 10.170336520000054],
              [104.52930772400003, 10.170424693000067],
              [104.52862343200007, 10.170780414000101],
              [104.52820890300006, 10.171243527000049],
              [104.52813610500007, 10.171796325000047],
              [104.52874343400006, 10.174847022000042],
              [104.52880515400001, 10.175703212000096],
              [104.52871345300007, 10.176880200000047],
              [104.52820650600005, 10.179001869000091],
              [104.52771002300008, 10.180124774000097],
              [104.527060588, 10.181229614000118],
              [104.52627422200004, 10.182308959000073],
              [104.52519242300005, 10.183645213000057],
              [104.52233577900004, 10.185552232000104],
              [104.52210336800009, 10.186239913000071],
              [104.52233355700012, 10.187042940000078],
              [104.52337261300003, 10.188535175000089],
              [104.52452793800006, 10.189645335000106],
              [104.52511147100002, 10.189916076000125],
              [104.52702824700008, 10.189784833000102],
              [104.52868779900007, 10.188946308000089],
              [104.53074136500008, 10.186672881000012],
              [104.53132179100005, 10.185335886000056],
              [104.53159431500004, 10.183577995000091],
              [104.53163534100005, 10.181896211000028],
              [104.53274989400009, 10.172926558000126],
              [104.53237662800002, 10.171695838000081],
              [104.53163755000007, 10.170677725000042],
              [104.53083727500011, 10.170373378000084],
            ],
          ],
          [
            [
              [104.33198275500006, 10.288691796000114],
              [104.332327799, 10.288036973000112],
              [104.33233664000002, 10.287541487000061],
              [104.33192449300003, 10.287286460000098],
              [104.33043368400004, 10.286764724000095],
              [104.3261308940001, 10.285037212000068],
              [104.32472342600009, 10.284516899],
              [104.32355853000007, 10.284413864],
              [104.32314196900006, 10.284406557000112],
              [104.32264061200006, 10.284480366000091],
              [104.32204855600001, 10.284965616000095],
              [104.32202048400009, 10.286534646000066],
              [104.32216790900003, 10.28761112700005],
              [104.32257265800004, 10.288279081000072],
              [104.32348173100011, 10.288708065000039],
              [104.32389534800005, 10.288880530000098],
              [104.32439523900011, 10.288889297000113],
              [104.32490102600003, 10.288567744000064],
              [104.32607481100008, 10.288175287000099],
              [104.326992745, 10.288108769000102],
              [104.32749115000003, 10.28820010800011],
              [104.32781998100006, 10.28845368500013],
              [104.32872611700007, 10.289047806000083],
              [104.32971851900002, 10.28947822200008],
              [104.33029877600008, 10.289653602000024],
              [104.33105302900005, 10.289418981000063],
              [104.33172544100007, 10.289100319000076],
              [104.33198275500006, 10.288691796000114],
            ],
          ],
          [
            [
              [104.31304027600002, 10.290217316000037],
              [104.312594491, 10.290198716000097],
              [104.31215360600002, 10.290266593000029],
              [104.31173456100008, 10.290418339000061],
              [104.31135345600008, 10.290648124000066],
              [104.31102493500008, 10.290947119000025],
              [104.31078233800008, 10.291266386000062],
              [104.31063327900009, 10.291549495000128],
              [104.31051025700003, 10.291916683000045],
              [104.3104478110001, 10.292354204],
              [104.31045559400005, 10.292685960000101],
              [104.31051781600004, 10.29301203400008],
              [104.31062248799999, 10.293327277000051],
              [104.31077761700004, 10.293621423000053],
              [104.31104583600003, 10.293974543000049],
              [104.3113784610001, 10.294269062000081],
              [104.31176271100001, 10.294493665000102],
              [104.31218382200004, 10.294639720000108],
              [104.31251389699999, 10.294696917000044],
              [104.31279282200006, 10.29470594300008],
              [104.3131699780001, 10.294659957000095],
              [104.31370897600002, 10.294480723],
              [104.31409014700003, 10.294251031000011],
              [104.31434421500009, 10.294034661000049],
              [104.31455823600004, 10.293781789000088],
              [104.31478613800006, 10.293399688000092],
              [104.31493366800001, 10.292982671000049],
              [104.31499621100004, 10.292544869000062],
              [104.314971208, 10.29210281400008],
              [104.31485972400002, 10.291674959000117],
              [104.31472426100001, 10.29137144000005],
              [104.31454070400009, 10.291093814000083],
              [104.31423874200007, 10.290768511000079],
              [104.31387850500002, 10.29050779400009],
              [104.31347383300002, 10.290321679000057],
              [104.31304027600002, 10.290217316000037],
            ],
          ],
          [
            [
              [104.3375642500001, 10.290536071000121],
              [104.33713441600007, 10.290514922000058],
              [104.336683814, 10.290635688000103],
              [104.33610941400008, 10.291222719000091],
              [104.33573992400005, 10.29170874200007],
              [104.33569822300007, 10.292073751000073],
              [104.33672481900001, 10.295503651000091],
              [104.33684667500006, 10.295970403000075],
              [104.3371734400001, 10.296336163000051],
              [104.33823469500007, 10.297879837000055],
              [104.33864293400009, 10.29844858600004],
              [104.33913368500002, 10.29871326100009],
              [104.33952254500004, 10.298754626000049],
              [104.3399322650001, 10.298613485000095],
              [104.34060885300003, 10.298107792000087],
              [104.34114218500011, 10.297581524000064],
              [104.34130671400004, 10.297216767000082],
              [104.34134857300005, 10.296770632000028],
              [104.34120620900008, 10.296324117000031],
              [104.3408027310001, 10.293463414000124],
              [104.34053775400011, 10.292915238000065],
              [104.34002725400006, 10.292305711000081],
              [104.33904678000002, 10.291289570000027],
              [104.33828020600001, 10.290780934000042],
              [104.3375642500001, 10.290536071000121],
            ],
          ],
          [
            [
              [104.34883078800007, 10.297329306000067],
              [104.34483592900003, 10.297011852000091],
              [104.34441492700009, 10.297252338000094],
              [104.34424242100003, 10.297579755000095],
              [104.3442336090001, 10.298075259000059],
              [104.34472176700008, 10.298744634000021],
              [104.34594512400005, 10.300252913000028],
              [104.34733220000007, 10.301929255000084],
              [104.34783212400004, 10.301937966000095],
              [104.34935095300003, 10.300890516000026],
              [104.35011402300002, 10.300160334000095],
              [104.35039037500006, 10.298678198000031],
              [104.35007469600005, 10.297681396000067],
              [104.34974582300002, 10.29742785000005],
              [104.34883078800007, 10.297329306000067],
            ],
          ],
          [
            [
              [104.31476018100004, 10.300994890000046],
              [104.31442824400003, 10.300959564000054],
              [104.31406088000008, 10.301149231000027],
              [104.31393725200007, 10.301754928000049],
              [104.31402286600006, 10.302568824000067],
              [104.31433511400004, 10.303625586000107],
              [104.31485624200009, 10.305063672000079],
              [104.3152212900001, 10.305947407000103],
              [104.31595361300002, 10.306693426000011],
              [104.31672118300003, 10.307301004000101],
              [104.3176463880001, 10.307735783000014],
              [104.318135673, 10.3077368180001],
              [104.31853803100007, 10.307529913000034],
              [104.31894139000005, 10.306855549000103],
              [104.31927553, 10.305869400000043],
              [104.31925962, 10.305142225000095],
              [104.31908587500001, 10.304674409000112],
              [104.31885930700001, 10.304396920000068],
              [104.31606920100009, 10.301707499000122],
              [104.31540612200011, 10.301273270000076],
              [104.31476018100004, 10.300994890000046],
            ],
          ],
          [
            [
              [104.32054371000002, 10.311704506000028],
              [104.32009787700008, 10.311685921000089],
              [104.31965694900002, 10.311753816000111],
              [104.31923786500008, 10.311905583000057],
              [104.31885672900006, 10.312135390000064],
              [104.31852818400006, 10.312434407000113],
              [104.31826485500002, 10.312791147000054],
              [104.31811379700005, 10.31308738000005],
              [104.3180134880001, 10.313404024000048],
              [104.3179510520001, 10.313841562000013],
              [104.31795884800002, 10.314173330000107],
              [104.31802108800011, 10.314499414000087],
              [104.31812578200007, 10.314814664000059],
              [104.31828093800003, 10.315108814000061],
              [104.31855840200006, 10.315472457000039],
              [104.31888186900001, 10.315756455],
              [104.31926616700011, 10.315981052000021],
              [104.31968732800004, 10.316127097000026],
              [104.32001744000009, 10.31618428400005],
              [104.32029639400008, 10.316193300000085],
              [104.32067358700002, 10.316147300000098],
              [104.32121263600004, 10.315968040000099],
              [104.32159383700005, 10.315738326000099],
              [104.32184792400002, 10.315521939000048],
              [104.32206302000006, 10.315267455000042],
              [104.32228987000008, 10.314886928000087],
              [104.32243740100003, 10.314469891000043],
              [104.32249993400006, 10.314032072000055],
              [104.32247491200012, 10.313590001000071],
              [104.32236340200004, 10.313162136000107],
              [104.32222791300002, 10.31285861100004],
              [104.32207871000003, 10.312624701000049],
              [104.32190320500007, 10.312408931000048],
              [104.32171159800002, 10.312226681000068],
              [104.32138203700002, 10.31199496400008],
              [104.32097731600001, 10.311808857000047],
              [104.32054371000002, 10.311704506000028],
            ],
          ],
          [
            [
              [104.32744567499999, 10.320077224000011],
              [104.32785846500005, 10.320008105000065],
              [104.32820877600008, 10.320084405000072],
              [104.32969992500009, 10.320630465000045],
              [104.33007541600007, 10.320827152000065],
              [104.33040561000003, 10.32106014100007],
              [104.33066789100006, 10.321326562000086],
              [104.33111012500007, 10.322038365000095],
              [104.33149015700006, 10.322761228000042],
              [104.33171016200006, 10.322977185000058],
              [104.33202350200006, 10.323159757000065],
              [104.33233417200009, 10.32326955300009],
              [104.33322750400001, 10.323413197000074],
              [104.33478504600001, 10.32330212300012],
              [104.33593932800004, 10.322961649000105],
              [104.33656948700005, 10.322592581000032],
              [104.33673400300006, 10.322313042000085],
              [104.33677433100011, 10.321952091000062],
              [104.3367580880001, 10.321610610000052],
              [104.33661531600011, 10.320952612000054],
              [104.336500191, 10.320594130000059],
              [104.33626652000002, 10.320157045000132],
              [104.33528235300012, 10.319097088000094],
              [104.33401563000004, 10.318000167000017],
              [104.32990126500005, 10.314969009000039],
              [104.32944973700008, 10.314649012000119],
              [104.32846732400006, 10.314109608000075],
              [104.32796186700007, 10.313901448000083],
              [104.3271935290001, 10.313706737000048],
              [104.32689679900005, 10.313692126000083],
              [104.32652072400003, 10.313778108000092],
              [104.32572649600004, 10.314401240000047],
              [104.3222771320001, 10.319447648000056],
              [104.32198393500008, 10.320667541000093],
              [104.32195478400007, 10.321078886000111],
              [104.32205269600009, 10.322873094000061],
              [104.32209711400003, 10.323251020000066],
              [104.32232256300003, 10.323559358000088],
              [104.32299898700005, 10.323911135000062],
              [104.32353668800005, 10.323821594000025],
              [104.32402727300004, 10.323367017000072],
              [104.32636234200007, 10.32031600500005],
              [104.32744567499999, 10.320077224000011],
            ],
          ],
          [
            [
              [104.27601737400006, 10.353913246000078],
              [104.27557942300007, 10.35382954600008],
              [104.274669327, 10.353863580000054],
              [104.27335221900009, 10.354251971000037],
              [104.27234560700002, 10.354671401000102],
              [104.27151849600006, 10.355454802000084],
              [104.27139510000006, 10.355749899000076],
              [104.27133814300007, 10.35630170800008],
              [104.27145359100011, 10.356968728000114],
              [104.27195159400002, 10.357837104000021],
              [104.27225344000003, 10.358049307000082],
              [104.27255192600003, 10.358128928],
              [104.2729885050001, 10.358153961000125],
              [104.27359132000007, 10.357965347000063],
              [104.27432420800004, 10.357472493000051],
              [104.275433741, 10.356083255000035],
              [104.27590256000006, 10.355082428000093],
              [104.27606994500006, 10.354230222000103],
              [104.27601737400006, 10.353913246000078],
            ],
          ],
          [
            [
              [104.31871053900005, 10.354248453000102],
              [104.31819092800004, 10.35424734900001],
              [104.31793060200009, 10.354487007000033],
              [104.31734138600009, 10.35864648500011],
              [104.31737475800001, 10.359229912000066],
              [104.31754700400006, 10.359676381000058],
              [104.31774327000004, 10.361023666000067],
              [104.317949895, 10.361590310000055],
              [104.31833020100002, 10.361934261],
              [104.31876524200004, 10.362109034000076],
              [104.31914393200003, 10.362090412000045],
              [104.31933487000001, 10.361902076000094],
              [104.3194914530001, 10.36157640600006],
              [104.31947502600006, 10.361164594000092],
              [104.31932081000009, 10.360392172000042],
              [104.3194850190001, 10.356532062000044],
              [104.31965988600008, 10.355760338000051],
              [104.31962691400003, 10.354988172000022],
              [104.31935097200001, 10.35443853900002],
              [104.31909150100003, 10.354283577000077],
              [104.31871053900005, 10.354248453000102],
            ],
          ],
          [
            [
              [103.81251689200008, 10.357907272000054],
              [103.81217349500008, 10.357870594000039],
              [103.81179952700005, 10.357883818000067],
              [103.8115446140001, 10.357931021000065],
              [103.81129644599999, 10.358005556000103],
              [103.81103352900008, 10.358122115000066],
              [103.81071739200004, 10.358320261000118],
              [103.81045678100006, 10.358544825000047],
              [103.81025200300007, 10.358784146000042],
              [103.81008160200001, 10.359048568000071],
              [103.80997079900008, 10.359280726000096],
              [103.80987521300008, 10.359579757000057],
              [103.80981443700003, 10.359945292000104],
              [103.80981083400007, 10.360258935000031],
              [103.80985252200007, 10.36056987300009],
              [103.80993607900005, 10.360872431000077],
              [103.81004874600005, 10.361134941000037],
              [103.81022790900005, 10.361427422000078],
              [103.81046808500011, 10.361711481000045],
              [103.8106826650001, 10.36190178900007],
              [103.81089612400008, 10.362047608000056],
              [103.81115086000003, 10.362180813000064],
              [103.81147627600004, 10.362295713000067],
              [103.81184365300005, 10.362365947000017],
              [103.81210258100008, 10.362380757],
              [103.81247424600009, 10.36234476300004],
              [103.812918547, 10.362222568000055],
              [103.813278208, 10.362043023000032],
              [103.81362317000003, 10.361786535000038],
              [103.81386069000006, 10.36153812800009],
              [103.81406866000003, 10.361230183000101],
              [103.81421768400003, 10.360921594000059],
              [103.81430699300003, 10.360620662000118],
              [103.81436194600005, 10.360146781000077],
              [103.81433718000005, 10.35977813300004],
              [103.81427642600008, 10.359499322000064],
              [103.81419018800005, 10.359257164000011],
              [103.81403252200006, 10.358952792000052],
              [103.81383255300004, 10.358673848000064],
              [103.81356786300007, 10.358412040000074],
              [103.81324113800005, 10.35817881400005],
              [103.81290351299999, 10.358019396000094],
              [103.81251689200008, 10.357907272000054],
            ],
          ],
          [
            [
              [104.30710875800006, 10.36157021100008],
              [104.30676526700006, 10.361534342000043],
              [104.30639132600002, 10.361548449000056],
              [104.30613652199999, 10.361596253000103],
              [104.30588853000008, 10.361671374000101],
              [104.30562588900003, 10.361788554000027],
              [104.3053102260001, 10.361987447000052],
              [104.30505015000008, 10.362212625000032],
              [104.30484594500005, 10.362452430000067],
              [104.30467617700005, 10.362717255000041],
              [104.30456593200007, 10.362949675],
              [104.30447106400004, 10.363248933000067],
              [104.30441116600005, 10.363614613000037],
              [104.30440831800009, 10.363928265000053],
              [104.30445075500005, 10.364239105000104],
              [104.30453504100009, 10.364541468000075],
              [104.30464834200005, 10.364803713000102],
              [104.30482821100001, 10.365095772000108],
              [104.30506907400004, 10.365379265000028],
              [104.30528411600008, 10.3655690680001],
              [104.3054979290001, 10.365714384000045],
              [104.30575299000006, 10.365846988],
              [104.30607868600001, 10.365961121000032],
              [104.30644623700005, 10.366030488000087],
              [104.30670520600006, 10.366044688000018],
              [104.30707678900008, 10.366007816000076],
              [104.30752080300005, 10.365884573000093],
              [104.3078800370001, 10.365704179000089],
              [104.30822438800008, 10.365446877000027],
              [104.30846131300005, 10.365197908000122],
              [104.30866854500006, 10.364889472000092],
              [104.30881682900008, 10.36458053000011],
              [104.30890541600003, 10.364279388000062],
              [104.30895922800005, 10.3638053750001],
              [104.30893357500005, 10.363436784000067],
              [104.30887215000003, 10.363158116000102],
              [104.30878532700004, 10.362916160000067],
              [104.30862692600007, 10.362612160000049],
              [104.30842628300009, 10.362333686000101],
              [104.30816096000007, 10.362072502000073],
              [104.30783366800009, 10.361840045000024],
              [104.307495655, 10.361681423000045],
              [104.30710875800006, 10.36157021100008],
            ],
          ],
          [
            [
              [104.07463125100006, 10.386841859000116],
              [104.07431522000012, 10.38681947200004],
              [104.07394240100008, 10.386850158000092],
              [104.07347114, 10.386978208000118],
              [104.07313443000002, 10.387139818000078],
              [104.07289715900002, 10.387301629000055],
              [104.072643521, 10.387533939000091],
              [104.07239524900007, 10.387848022000055],
              [104.072239967, 10.388121432000029],
              [104.072144403, 10.388360153000063],
              [104.07207580100001, 10.388607793000036],
              [104.072037134, 10.388861704000083],
              [104.07202830799999, 10.389145035000078],
              [104.07205874200011, 10.38945121700001],
              [104.07212459500005, 10.389728910000072],
              [104.07225081700001, 10.39004735500013],
              [104.07242170100002, 10.390344677000128],
              [104.07259850700009, 10.390569902000022],
              [104.07278068800007, 10.390752683000047],
              [104.07298334400005, 10.390912945000073],
              [104.07328029300001, 10.391087685000054],
              [104.0737095850001, 10.391251699000035],
              [104.07407741600007, 10.391319812000075],
              [104.07433646700009, 10.39133311500011],
              [104.074595065, 10.391313006000045],
              [104.07493351400002, 10.391244031000022],
              [104.07525753200009, 10.391125184000048],
              [104.07558320400004, 10.390942704000048],
              [104.07585472000004, 10.390731123000039],
              [104.0760712000001, 10.390502075000095],
              [104.07625458299999, 10.390246279000069],
              [104.07641971700006, 10.389913839000096],
              [104.07653753300005, 10.38953245200007],
              [104.07658067400007, 10.389221696000027],
              [104.07657542200008, 10.388879648000058],
              [104.076520859, 10.388541854],
              [104.07644582200001, 10.388296054000088],
              [104.07634206800003, 10.388060712000067],
              [104.07621292600001, 10.387838022000027],
              [104.076019656, 10.387589476000068],
              [104.07577395600002, 10.387348949000062],
              [104.07554224500004, 10.387179427000069],
              [104.07521114300008, 10.387006832000088],
              [104.07488555400005, 10.386892351000119],
              [104.07463125100006, 10.386841859000116],
            ],
          ],
          [
            [
              [104.32810918800001, 10.394340281000057],
              [104.32729854500008, 10.394124312000129],
              [104.32648188500011, 10.394244522000038],
              [104.32559497300008, 10.39449799600005],
              [104.32456633100006, 10.395085271000058],
              [104.32421743300006, 10.395617196000016],
              [104.3241375580001, 10.396288354000125],
              [104.32410749100011, 10.397969217000112],
              [104.32436567700006, 10.398713558000059],
              [104.32476440100001, 10.399191337000067],
              [104.326239206, 10.400226017000056],
              [104.32837697400005, 10.402146637000074],
              [104.32891261700004, 10.402559553000044],
              [104.32965304200012, 10.402908798000075],
              [104.32999233200003, 10.402914737000033],
              [104.330468534, 10.402855808000043],
              [104.33094593600006, 10.402729653000085],
              [104.33115790800011, 10.402262569000074],
              [104.33123537100003, 10.401725879000077],
              [104.33124497500003, 10.40118799200007],
              [104.33106061200004, 10.400108676000029],
              [104.33041685900008, 10.398146988000077],
              [104.32970407900007, 10.396251352000084],
              [104.32849094200006, 10.394588141000048],
              [104.32810918800001, 10.394340281000057],
            ],
          ],
          [
            [
              [104.33434249800007, 10.401469443000087],
              [104.33389647000003, 10.401450884000061],
              [104.33345535300003, 10.401518818000087],
              [104.33313688800007, 10.401622571000056],
              [104.33283975000009, 10.401776298000074],
              [104.33248305400005, 10.402042051000125],
              [104.33218557800005, 10.402371583000035],
              [104.33195874900011, 10.402752235],
              [104.33183772000004, 10.403061759000119],
              [104.33176921200005, 10.40338666100009],
              [104.3317504020001, 10.403828219000031],
              [104.33181896200009, 10.404264927000016],
              [104.33197226000007, 10.404680004000076],
              [104.33213713700003, 10.404969004000096],
              [104.33234736800003, 10.405227556000083],
              [104.33268020300002, 10.405522095000029],
              [104.33306468400005, 10.40574669900005],
              [104.33348603900001, 10.405892738000054],
              [104.33381629900006, 10.405949913000077],
              [104.33409537600005, 10.405958912000111],
              [104.33437381, 10.405929907000109],
              [104.33480611500006, 10.405819608000042],
              [104.33520838000004, 10.405627944000083],
              [104.33548275700009, 10.405437201000021],
              [104.33572210300009, 10.405204781000064],
              [104.33598561500006, 10.404848034000034],
              [104.33617376700008, 10.404447252000091],
              [104.33627932900001, 10.404017834000088],
              [104.33629817300006, 10.403575398000074],
              [104.33622949300006, 10.403138414000066],
              [104.33607607900007, 10.40272338100001],
              [104.33591112100001, 10.402434427000083],
              [104.33570082000003, 10.4021759350001],
              [104.33545823600011, 10.401946833000062],
              [104.3351812110001, 10.401759888000049],
              [104.33477630100001, 10.401573780000104],
              [104.33434249800007, 10.401469443000087],
            ],
          ],
          [
            [
              [104.32945821000007, 10.403952107000068],
              [104.32901218100002, 10.403933423000032],
              [104.32857104000003, 10.404001232000047],
              [104.32815173500006, 10.404152931000075],
              [104.32777037700004, 10.404382690000078],
              [104.32744161800011, 10.404681681000037],
              [104.32717809200005, 10.405038417000066],
              [104.32698992200004, 10.405439192000097],
              [104.32688434000009, 10.4058686070001],
              [104.32686547500003, 10.406311044000025],
              [104.32693413600003, 10.406748032000033],
              [104.32708753600012, 10.40716307600009],
              [104.327252484, 10.407452039000107],
              [104.32746277899999, 10.407710541000091],
              [104.32779569000009, 10.40800500200003],
              [104.32818023000002, 10.408229518000043],
              [104.32849291100004, 10.408349299000095],
              [104.32882112000003, 10.408417083000108],
              [104.32921098500005, 10.408441487000085],
              [104.32954486900005, 10.408404800000069],
              [104.32981662500006, 10.408340053000041],
              [104.33012756800012, 10.408215916000072],
              [104.33050887000005, 10.407986053000061],
              [104.33076300400003, 10.407769557000089],
              [104.33097811800006, 10.407514970000074],
              [104.33120495300008, 10.40713431700002],
              [104.33132598600007, 10.406824791000078],
              [104.33139449700005, 10.406499887000018],
              [104.33141330800001, 10.406058327000077],
              [104.33134474700002, 10.405621618000092],
              [104.33123998600006, 10.405306328000028],
              [104.33108474400009, 10.405012144000112],
              [104.33081634600009, 10.404658985000113],
              [104.33048351399999, 10.404364444000079],
              [104.33020049500003, 10.4041865090001],
              [104.32989198800007, 10.404056568],
              [104.32945821000007, 10.403952107000068],
            ],
          ],
          [
            [
              [104.06127669500009, 10.406327524000057],
              [104.0609614410001, 10.406296009000114],
              [104.06061608200005, 10.406307741000107],
              [104.06036039300002, 10.406350989000133],
              [104.06011162900005, 10.406423746000113],
              [104.05982150000003, 10.406549866000068],
              [104.0595744, 10.40669658700004],
              [104.05937055600002, 10.406855393000034],
              [104.05913021500002, 10.407101194000033],
              [104.05892781000007, 10.407378466000059],
              [104.0587831820001, 10.40765757400006],
              [104.05867779199998, 10.407953405000024],
              [104.05861442300008, 10.40828960700013],
              [104.05860683000006, 10.408745112000071],
              [104.05866136900005, 10.409082921000044],
              [104.05874702600003, 10.409355276000019],
              [104.05885277300007, 10.409589759000058],
              [104.05900249500003, 10.409833477000062],
              [104.05918017400008, 10.410058043000079],
              [104.05940919000003, 10.410274823000075],
              [104.05968881600006, 10.410475829000097],
              [104.05999725000001, 10.410630103000072],
              [104.06032478900002, 10.410739168000037],
              [104.060636952, 10.410793071000056],
              [104.06089595900002, 10.410807568000012],
              [104.06115467200001, 10.410788653000045],
              [104.06143808500011, 10.410737163000048],
              [104.06171192500003, 10.410648406000076],
              [104.06197329700008, 10.410528332000089],
              [104.06226114900009, 10.410339075000062],
              [104.06252066000008, 10.410113204000114],
              [104.06268900300006, 10.409917834000025],
              [104.06286259400011, 10.409655403000071],
              [104.06298876500007, 10.40939895500008],
              [104.06307894900007, 10.409128038000047],
              [104.06314197400005, 10.408791673000016],
              [104.06315439400001, 10.408480007000074],
              [104.06312397000008, 10.408173814000053],
              [104.06305812500004, 10.407896110000079],
              [104.062944875, 10.407603141],
              [104.06275902000007, 10.407281686000134],
              [104.06244238900007, 10.406913119000103],
              [104.06215198300004, 10.406679458000044],
              [104.06181172600003, 10.40649348200011],
              [104.06152892600008, 10.406387456000042],
              [104.06127669500009, 10.406327524000057],
            ],
          ],
          [
            [
              [104.05259289500003, 10.412621896000116],
              [104.05227682700007, 10.412599476000127],
              [104.05193213600006, 10.412624025000115],
              [104.05162256900003, 10.412691006000061],
              [104.05130000200008, 10.412813744000092],
              [104.05095180900005, 10.413014405000085],
              [104.05072606300011, 10.413191656],
              [104.05052821000008, 10.413399011000111],
              [104.05028274600002, 10.413750332000047],
              [104.05010695700004, 10.414140446000085],
              [104.05001041800006, 10.414556787000071],
              [104.04998938000008, 10.414924889000064],
              [104.05001978300002, 10.415231088000086],
              [104.05007691500005, 10.415481579000117],
              [104.05018764400006, 10.41577548400001],
              [104.05040100400004, 10.416146782000089],
              [104.05061760700002, 10.416413325000027],
              [104.050807408, 10.416588366000033],
              [104.05109013500004, 10.416784930000043],
              [104.05150565900004, 10.416980755000081],
              [104.05195343600005, 10.417091293000079],
              [104.05229759200002, 10.417113303000034],
              [104.05255622500002, 10.41709322200006],
              [104.05289472400004, 10.417024281000041],
              [104.05319279800001, 10.416917815000113],
              [104.0534482350001, 10.416785789000114],
              [104.05372857800003, 10.416585751000083],
              [104.05397717400008, 10.416348113000049],
              [104.05418429000008, 10.41607426300004],
              [104.05434975700008, 10.415773926000059],
              [104.05447828800007, 10.415395840000128],
              [104.05453958400007, 10.415030455000094],
              [104.05454281600007, 10.414716780000076],
              [104.05450074700005, 10.414405864000019],
              [104.05441680800004, 10.414103377000037],
              [104.05430379600001, 10.413840974],
              [104.05417466400007, 10.413618262000044],
              [104.05401993300006, 10.413412153000044],
              [104.05377836400007, 10.413167523000054],
              [104.05345436699999, 10.412930450000067],
              [104.05312033400006, 10.412763364000098],
              [104.05284722400002, 10.412672414000033],
              [104.05259289500003, 10.412621896000116],
            ],
          ],
          [
            [
              [104.04721742500006, 10.414823160000084],
              [104.04687671900002, 10.414765907000055],
              [104.04650247800006, 10.414766086000069],
              [104.04613256600001, 10.414822290000101],
              [104.0458310340001, 10.414918731000087],
              [104.045545347, 10.415054447000037],
              [104.045327505, 10.415193870000026],
              [104.04508696000009, 10.415398061000046],
              [104.04484057600004, 10.415676939000116],
              [104.04464497500003, 10.41599280200005],
              [104.04449504700005, 10.416363007000127],
              [104.04441110500004, 10.416782017000068],
              [104.04440096500008, 10.417152357000068],
              [104.04445688, 10.417518679000091],
              [104.04457987000005, 10.417898483000075],
              [104.04472350300003, 10.418178102000029],
              [104.04490601800003, 10.418434540000108],
              [104.04510037800004, 10.418645133000036],
              [104.04529810900006, 10.418811358000111],
              [104.04553820000004, 10.418969104000018],
              [104.04582339700009, 10.419105846000052],
              [104.04615275599998, 10.419209454000098],
              [104.04643662600003, 10.41925848900007],
              [104.04669577300007, 10.419271808000106],
              [104.04695342400007, 10.419257168000049],
              [104.04720945700002, 10.41920636300002],
              [104.04753878600009, 10.419102679000057],
              [104.04782277500006, 10.418963497000087],
              [104.04812921800001, 10.418750799000074],
              [104.04841560700008, 10.418470251000132],
              [104.04863366600009, 10.418169140000087],
              [104.04881700600006, 10.417782339000057],
              [104.04891215900004, 10.417453556000032],
              [104.04895157600001, 10.417071845000068],
              [104.04892405400005, 10.416638169000068],
              [104.04886692200003, 10.416387677000037],
              [104.04878039000006, 10.416145593000079],
              [104.04862233300004, 10.415841370000043],
              [104.04844081600004, 10.415584256000086],
              [104.04824370300007, 10.415376186000101],
              [104.04799950900011, 10.41517627500008],
              [104.04775476400002, 10.415025702000056],
              [104.04751984800004, 10.414916785000104],
              [104.04721742500006, 10.414823160000084],
            ],
          ],
          [
            [
              [104.34233247100011, 10.423310730000066],
              [104.34180581100004, 10.423202216000059],
              [104.34123220800009, 10.423308464000057],
              [104.34079766600003, 10.423614519000067],
              [104.34056434600009, 10.424028425000119],
              [104.34020530500004, 10.425270836000065],
              [104.34007910800008, 10.42632956400009],
              [104.34029418000003, 10.427220156000059],
              [104.34058784000007, 10.427589111000122],
              [104.34128416200005, 10.428081667000109],
              [104.34185683600001, 10.428420492000077],
              [104.34221300300007, 10.428544002000082],
              [104.34252291600005, 10.428559996000073],
              [104.34275574500003, 10.428376305000063],
              [104.34308238000006, 10.427809117000029],
              [104.34339565400005, 10.426213602000088],
              [104.34339864100012, 10.424786286000119],
              [104.34310568700003, 10.424079698000098],
              [104.34273480200008, 10.423587814000078],
              [104.34233247100011, 10.423310730000066],
            ],
          ],
          [
            [
              [104.32337489400007, 10.430395608000074],
              [104.32167243300006, 10.430365752000091],
              [104.31990217100005, 10.430464481000088],
              [104.31397644400003, 10.432177424000065],
              [104.3125195960001, 10.433060324000019],
              [104.311992258, 10.433245732000083],
              [104.31198527300003, 10.433634975000048],
              [104.3123080120001, 10.433900226000057],
              [104.314388261, 10.434780421000054],
              [104.31613064900002, 10.436238713000099],
              [104.31819349900007, 10.438091980000051],
              [104.31864140600001, 10.438683896000088],
              [104.31857631400004, 10.442316849000031],
              [104.31875068500008, 10.443552916000074],
              [104.3193133, 10.445055380000044],
              [104.31988987700001, 10.445779343000082],
              [104.32028046300009, 10.445915991000019],
              [104.32060788100009, 10.44592173600005],
              [104.32563689100009, 10.443089631000111],
              [104.3298579560001, 10.441476290000116],
              [104.33078282500007, 10.441038204000018],
              [104.33125047500003, 10.440527233000106],
              [104.33172275600003, 10.439756757000039],
              [104.3317331800001, 10.439172894000047],
              [104.33155757400004, 10.438001717000043],
              [104.33114442200005, 10.43549982100007],
              [104.330577066, 10.434220706000106],
              [104.32967308600008, 10.433491054000042],
              [104.32707822800006, 10.432082853000056],
              [104.32487047600009, 10.431005871000119],
              [104.32337489400007, 10.430395608000074],
            ],
          ],
          [
            [
              [104.02983981100002, 10.413712404000107],
              [104.03157934900005, 10.413349196000075],
              [104.03277834200006, 10.413371884000087],
              [104.0344771150001, 10.415120596000092],
              [104.03591965, 10.416336282000048],
              [104.03657813000004, 10.416744861000014],
              [104.03791037900004, 10.416770039000054],
              [104.03898380000004, 10.416394188000043],
              [104.04088454600002, 10.41458145700007],
              [104.04661703600003, 10.407559256000097],
              [104.04919640600011, 10.40509906400011],
              [104.05953603900012, 10.394070146000088],
              [104.06367582500008, 10.388490695000117],
              [104.0700503140001, 10.380112868000067],
              [104.07172656300007, 10.376942386000019],
              [104.07304417600002, 10.373026301],
              [104.07437585700006, 10.368371614000081],
              [104.07545196200002, 10.364081610000129],
              [104.07618532100004, 10.361939274000063],
              [104.07723511600003, 10.359865754000095],
              [104.07790552100008, 10.358422179000025],
              [104.07848410700008, 10.356976887000078],
              [104.07859327600005, 10.356068857000087],
              [104.07860369100003, 10.355523013000044],
              [104.07852229600005, 10.354975452000035],
              [104.07798017400003, 10.354510280000094],
              [104.07733930000003, 10.354407282000098],
              [104.07651655500008, 10.354209870000107],
              [104.07433591800005, 10.352985964000093],
              [104.07290535099999, 10.350957033000084],
              [104.07193032400005, 10.349118649000031],
              [104.07141256800003, 10.347379832000046],
              [104.07119630400007, 10.344281600000025],
              [104.07057131200006, 10.340948212000088],
              [104.07140652800005, 10.335685621000044],
              [104.07205116800002, 10.330783518000073],
              [104.07382156300012, 10.324628502000087],
              [104.07539092300003, 10.319379788000036],
              [104.07771529000006, 10.313053236000068],
              [104.07868865800003, 10.310159438000086],
              [104.07856409300004, 10.307063119000047],
              [104.07792358000006, 10.302137178000057],
              [104.07671508000008, 10.298110628000055],
              [104.0751534290001, 10.293349504000025],
              [104.07340830000004, 10.288584966000068],
              [104.07307792300006, 10.281360623000067],
              [104.07308729400003, 10.278144520000064],
              [104.0737504790001, 10.273322240000125],
              [104.07449217600001, 10.269384593000018],
              [104.07580368100008, 10.264805343000114],
              [104.07792998700006, 10.25902235500009],
              [104.07932438300006, 10.253800081000096],
              [104.07965866200001, 10.250424089000097],
              [104.07966585000007, 10.247931602000014],
              [104.07894333000006, 10.245276239000043],
              [104.07725119800003, 10.241251277000083],
              [104.07604309700008, 10.238192518000043],
              [104.05747516100004, 10.20783323500005],
              [104.05466250200003, 10.201728766000072],
              [104.05210799600005, 10.194903009000098],
              [104.0488773220001, 10.185159899000061],
              [104.04639299300005, 10.17470482800009],
              [104.04344417900006, 10.16303091900008],
              [104.04158060800005, 10.158396886000086],
              [104.03827178900011, 10.15276739200006],
              [104.03516048900006, 10.149562017000056],
              [104.03277658700009, 10.146612427000077],
              [104.03158933600008, 10.144895672000052],
              [104.03040676500002, 10.142936977000081],
              [104.02927560000005, 10.138316861000083],
              [104.02983829000007, 10.134454982000117],
              [104.03062624300007, 10.131565525000051],
              [104.03164880500003, 10.129164587000082],
              [104.03342190000009, 10.125809746000057],
              [104.03420043900003, 10.123404199000062],
              [104.03423775900011, 10.121468686000055],
              [104.03356654200007, 10.118309628000064],
              [104.03023524000005, 10.113890064000051],
              [104.02039665100001, 10.09264762100006],
              [104.01692877800005, 10.086295961000056],
              [104.01629435000005, 10.084860788000059],
              [104.01610971600005, 10.082996283000066],
              [104.01635369000007, 10.081796757000026],
              [104.01705803200008, 10.079620769000107],
              [104.01932175900006, 10.076708147000078],
              [104.02033797900005, 10.07552330500001],
              [104.02181211300007, 10.074857219000053],
              [104.0340541780001, 10.073922506000075],
              [104.03650450800006, 10.072764743000027],
              [104.03928999200006, 10.071394362000062],
              [104.0405119750001, 10.070979598000028],
              [104.04118655200004, 10.070335542000029],
              [104.04153436200005, 10.069466381000057],
              [104.04155120800002, 10.068590966000011],
              [104.04050275200004, 10.065725018000114],
              [104.03932505200004, 10.063841833000085],
              [104.03824082600002, 10.062836129000095],
              [104.03277648900007, 10.048611791],
              [104.03122737300006, 10.043109306000096],
              [104.03050167000006, 10.040687400000079],
              [104.02861118700005, 10.035725799000071],
              [104.02830774000009, 10.034297053000072],
              [104.02832041400006, 10.033640536000108],
              [104.02822700900006, 10.032763084000072],
              [104.02858326800005, 10.031456313000129],
              [104.0319704, 10.027470525000036],
              [104.03569673300002, 10.023053292000037],
              [104.04389360100002, 10.015655730000093],
              [104.04938621700009, 10.011162259000093],
              [104.050068971, 10.010080588000035],
              [104.05064141800003, 10.00899682700007],
              [104.05077061100006, 10.008014169000122],
              [104.05078741300012, 10.007138837000083],
              [104.05057734100006, 10.006587603000037],
              [104.050030099, 10.006358377000078],
              [104.04859214900003, 10.006550153000136],
              [104.04447361100006, 10.008442593000042],
              [104.04133499000007, 10.011010216000036],
              [104.01756549600006, 10.019206631000086],
              [104.01602772300001, 10.018849007000073],
              [104.0151538440001, 10.01839455100013],
              [104.01416967200002, 10.017937992000013],
              [104.01298824700007, 10.01627364300006],
              [104.01258736500002, 10.014186328000017],
              [104.01217800900008, 10.012536684000038],
              [104.01131900400006, 10.011316304000049],
              [104.0106636310001, 10.010975450000091],
              [104.00967526100004, 10.010737702000048],
              [104.00878869600004, 10.010939723000066],
              [104.00801028900003, 10.011253256000073],
              [104.00733368800009, 10.012006553000051],
              [104.00698369800008, 10.012984984000093],
              [104.00673976000007, 10.01418435700012],
              [104.00682244700008, 10.015608870000035],
              [104.00608831700008, 10.025008258000055],
              [104.00491097000001, 10.028816881000056],
              [104.00141057800002, 10.038601509000065],
              [103.99175379100011, 10.047173900000049],
              [103.99086065300001, 10.047704118000043],
              [103.98974833400008, 10.048589532000028],
              [103.98925886000009, 10.049552869000065],
              [103.98925586400001, 10.050517686000136],
              [103.98932920000004, 10.053010383000059],
              [103.98898963400011, 10.057913888000057],
              [103.98695530000001, 10.07399924500003],
              [103.98666191000007, 10.076318891000112],
              [103.98541945200003, 10.084676903000032],
              [103.98401675400005, 10.09239119500007],
              [103.98211932, 10.102676807000053],
              [103.98006208200005, 10.11215786700008],
              [103.97849992400002, 10.11890678000009],
              [103.97578139800007, 10.132245022000051],
              [103.97380480900003, 10.141726243000091],
              [103.97058812000007, 10.158520098000066],
              [103.96819913900003, 10.170009981000037],
              [103.96663426900005, 10.177401968000053],
              [103.96474641000007, 10.184310506000125],
              [103.96277189900005, 10.192907156000073],
              [103.96112587500009, 10.200218432000073],
              [103.95915972500003, 10.207372797000057],
              [103.95899176500009, 10.207930127000074],
              [103.95587750300007, 10.217648668000033],
              [103.95536750000007, 10.218734613],
              [103.954407077, 10.220779594000094],
              [103.95170500500006, 10.228408978000035],
              [103.93521716600002, 10.258585974000043],
              [103.924208779, 10.275031650000075],
              [103.92281996300002, 10.277841039000089],
              [103.9149944370001, 10.2885080720001],
              [103.911882222, 10.290847756000064],
              [103.91071367900003, 10.291726215],
              [103.9093478880001, 10.292252653000043],
              [103.90695673000005, 10.292205972000088],
              [103.90149462700001, 10.291929880000071],
              [103.89912036100003, 10.291036657000062],
              [103.89622022300006, 10.290810539000093],
              [103.89211437000006, 10.291068817000058],
              [103.88970631400004, 10.291868391000046],
              [103.88743516600006, 10.294364206000049],
              [103.88550556300001, 10.296866713000073],
              [103.88303298100007, 10.300882783000096],
              [103.87920743700009, 10.304194814000043],
              [103.87643718700011, 10.30600329900002],
              [103.87401189500008, 10.307649193000133],
              [103.87023710100004, 10.308421662000098],
              [103.86680729200003, 10.30903150700002],
              [103.86203825800006, 10.308259955000041],
              [103.86051802400004, 10.307383129000057],
              [103.85967762400004, 10.306689082],
              [103.85779873200002, 10.306651960000044],
              [103.856073592, 10.307464678000054],
              [103.85468322800008, 10.308622715000036],
              [103.85432795200008, 10.309293139000085],
              [103.85430405100006, 10.310478208000083],
              [103.85132285600004, 10.322369310000033],
              [103.84800423800007, 10.33368655900002],
              [103.84667309800008, 10.339202021000025],
              [103.84275990600004, 10.352556989000037],
              [103.84114450300005, 10.356319504000043],
              [103.83983555600001, 10.358397275000096],
              [103.83549361000003, 10.361763286000068],
              [103.8319392020001, 10.368467789000045],
              [103.83350018400003, 10.375782170000123],
              [103.83414245600005, 10.377827514000069],
              [103.83515049700006, 10.378694459000124],
              [103.83583053400011, 10.378877347000069],
              [103.84386130600004, 10.379036701000109],
              [103.86928931000004, 10.372594623000085],
              [103.87407687100004, 10.372519510000087],
              [103.89022263800004, 10.368602162000061],
              [103.90067379600004, 10.365255165000063],
              [103.91439970600001, 10.361930045000099],
              [103.91823761800003, 10.36085],
              [103.91909188600002, 10.360866631000041],
              [103.92267646200006, 10.361105751000109],
              [103.92589583400006, 10.362523349000073],
              [103.93193938300006, 10.368060772000119],
              [103.93687725700006, 10.375591306000052],
              [103.94065569500005, 10.383239827000066],
              [103.94174390400012, 10.387111015000093],
              [103.94301970800007, 10.394751265000044],
              [103.94370317700005, 10.399910319000048],
              [103.94479680200003, 10.402194818000064],
              [103.94819216000012, 10.404784386000097],
              [103.95259075800008, 10.406782187000106],
              [103.95577501000011, 10.407169907000123],
              [103.95699878300003, 10.407269410000096],
              [103.96050649900009, 10.408096066000015],
              [103.9646930270001, 10.409467059000031],
              [103.96575173900004, 10.410170578000065],
              [103.96604912600003, 10.410631728000029],
              [103.96684831900008, 10.412848355000024],
              [103.96742522700004, 10.414681175000061],
              [103.9677619820001, 10.417040727000069],
              [103.96924264400003, 10.423194986000082],
              [103.97003668800001, 10.428584361000047],
              [103.97018502700003, 10.432846045000053],
              [103.97057241000002, 10.438475299000059],
              [103.97137951400001, 10.440761117000095],
              [103.97275629500005, 10.442607353000087],
              [103.97413583400007, 10.444313659000047],
              [103.97691691000006, 10.446606790000066],
              [103.97914675000007, 10.448189394000094],
              [103.98194435300009, 10.449642867000096],
              [103.98559222600002, 10.450972603000073],
              [103.98938414600006, 10.45216505100006],
              [103.99588170200006, 10.452289216000031],
              [103.99915501700009, 10.451091818000078],
              [104.00271351300009, 10.449759818000091],
              [104.00459057100005, 10.447695815000111],
              [104.00924370700002, 10.440925188000081],
              [104.01385693800009, 10.433466181000115],
              [104.02645821599999, 10.416289239000063],
              [104.02983981100002, 10.413712404000107],
            ],
          ],
          [
            [
              [104.33178633600005, 10.450585344],
              [104.33134420400006, 10.450523475000038],
              [104.33100894900004, 10.450531174000053],
              [104.33067943500004, 10.4505928],
              [104.33026003700007, 10.45074451400003],
              [104.32987859400004, 10.450974294000035],
              [104.32962434900006, 10.451190737000092],
              [104.32940912000001, 10.451445287000103],
              [104.32918213100005, 10.451825904000065],
              [104.32903451600001, 10.452243040000029],
              [104.32897195500007, 10.452680960000025],
              [104.32899700800002, 10.453123130000018],
              [104.3291086080001, 10.453551089000078],
              [104.32924420000003, 10.453854679000061],
              [104.32942792100005, 10.454132367000033],
              [104.32973014700011, 10.454457733000043],
              [104.33009069300009, 10.454718492000035],
              [104.33049570300003, 10.45490462500007],
              [104.33092961700004, 10.455008981000089],
              [104.33131957100007, 10.455033381000066],
              [104.33165353000008, 10.454996688000049],
              [104.33192534800001, 10.454931935000021],
              [104.33223635900008, 10.454807784000051],
              [104.33261774600001, 10.454577900000038],
              [104.33294649700012, 10.454278791000069],
              [104.33320998199999, 10.453921951000032],
              [104.33336112200006, 10.453625638000029],
              [104.3334614760001, 10.453308916000113],
              [104.33352392100002, 10.452871273000051],
              [104.33351609500009, 10.452539432000039],
              [104.33345378800009, 10.452213278000054],
              [104.33334900100003, 10.451897966000077],
              [104.33319371900006, 10.451603762000071],
              [104.33292525600005, 10.451250578000069],
              [104.33259234600004, 10.450956019000033],
              [104.33220778100011, 10.4507313970001],
              [104.33178633600005, 10.450585344],
            ],
          ],
          [
            [
              [104.69170241100008, 10.539325160000066],
              [104.73052787800006, 10.527345538000075],
              [104.73290229200006, 10.526612640000097],
              [104.75116580300005, 10.520217293000121],
              [104.75791807300007, 10.519808003],
              [104.77669154000006, 10.520283274000073],
              [104.77777983200004, 10.512624414000092],
              [104.77922018600002, 10.509671686000031],
              [104.78146532200006, 10.507431992000081],
              [104.77653037400005, 10.488620295000127],
              [104.78936352100004, 10.474810584000059],
              [104.79506916200006, 10.468670007000112],
              [104.80726014500004, 10.441882031000114],
              [104.810410258, 10.436541057000069],
              [104.81748282100006, 10.424548644000085],
              [104.82775850500011, 10.4071223960001],
              [104.82560253600008, 10.404581560000013],
              [104.82784064700007, 10.401776654000104],
              [104.83450641600004, 10.39342227600004],
              [104.83659835200011, 10.390542064000028],
              [104.86267044300003, 10.357026120000066],
              [104.86339214600005, 10.356093277000065],
              [104.87435225300007, 10.351401878000031],
              [104.90001582200003, 10.346388436000085],
              [104.901471101, 10.346056128000034],
              [104.97607014400005, 10.329006800000061],
              [104.97787798500005, 10.328593266000039],
              [104.9782272910001, 10.32851336400009],
              [105.00000007200001, 10.323559993000051],
              [105.003716341, 10.322314384000018],
              [105.01593693800004, 10.318217758000079],
              [105.015081159, 10.316990756000076],
              [105.01542241200009, 10.316792146000074],
              [105.03904118600006, 10.303043888000028],
              [105.05496911800006, 10.294093919000067],
              [105.06821663900007, 10.286367235000069],
              [105.06917009400009, 10.285786345000057],
              [105.08005520100002, 10.279154191000115],
              [105.09134432300007, 10.272786904000062],
              [105.09360398600009, 10.271482155000021],
              [105.10153746600005, 10.266900980000047],
              [105.11367438600008, 10.260119106000063],
              [105.12447345100001, 10.253943936],
              [105.13382333600002, 10.245200425000077],
              [105.13996583900004, 10.239455725000111],
              [105.14225787700005, 10.238156394000073],
              [105.15841411300003, 10.224174983000099],
              [105.1620776390001, 10.221004220000028],
              [105.16362466100003, 10.219665236000084],
              [105.20419283500006, 10.184550853000117],
              [105.20534878300003, 10.186090465000051],
              [105.20618273000012, 10.187201187000115],
              [105.20844657400002, 10.190264944000125],
              [105.21177790900006, 10.194859840000079],
              [105.212362, 10.19573566300007],
              [105.21491004400005, 10.199210231000054],
              [105.21706420000002, 10.202148101000128],
              [105.21951458100008, 10.205385471000026],
              [105.22075995900011, 10.207176030000118],
              [105.22239867200005, 10.209474841000075],
              [105.22407829600003, 10.211787916000057],
              [105.22538675000003, 10.213643534000115],
              [105.225802985, 10.214216642000016],
              [105.22981427900008, 10.210101822000082],
              [105.22400006900004, 10.202041739000032],
              [105.26261463500008, 10.170840921000133],
              [105.27562329000003, 10.160277323000065],
              [105.28660822800006, 10.149839953000049],
              [105.29633321800002, 10.139392306000071],
              [105.30156246400006, 10.144479723000098],
              [105.30516122100001, 10.140507996000013],
              [105.30477308600004, 10.140108528],
              [105.30891053199998, 10.135532205000073],
              [105.31532358300009, 10.142346005000123],
              [105.33762199600007, 10.117586893000039],
              [105.34011453600006, 10.114717650000047],
              [105.34323320300008, 10.110988973000088],
              [105.34386657500009, 10.1102656080001],
              [105.34740282800003, 10.106226786000059],
              [105.35141253400005, 10.101433536000036],
              [105.3526251210001, 10.095428304000096],
              [105.35319667800006, 10.092827869000052],
              [105.35503254400008, 10.083360925000038],
              [105.36233553500001, 10.074735522000109],
              [105.36264212300007, 10.074373405000079],
              [105.37457248100007, 10.063509891000065],
              [105.38391528900003, 10.055219085000109],
              [105.39743734700001, 10.042513446000088],
              [105.39804530600004, 10.043519936000054],
              [105.40499818900005, 10.037181796000104],
              [105.40952305800008, 10.032939924000049],
              [105.41404872500007, 10.028695221000115],
              [105.41653760900004, 10.026286394000026],
              [105.42382845700004, 10.019469516000077],
              [105.42456549000009, 10.018781202000058],
              [105.43057975800006, 10.013171868000102],
              [105.43654596800006, 10.007558675],
              [105.44246609300006, 10.00185241500011],
              [105.44301213200004, 10.001301660000104],
              [105.44387923700002, 10.000000158000065],
              [105.477362102, 9.969607116000121],
              [105.47803172800006, 9.968999152],
              [105.49365462600005, 9.954420784000105],
              [105.50207182100007, 9.946742410000107],
              [105.51987218700002, 9.93050264900006],
              [105.53089529600001, 9.920140770000073],
              [105.5375486140001, 9.913886082000099],
              [105.51881437000007, 9.873662190000053],
              [105.514427514, 9.864241168000108],
              [105.51653718600004, 9.863489793000023],
              [105.51868210500005, 9.859997881000114],
              [105.51758276600003, 9.859237786000017],
              [105.49969921300006, 9.847911024000043],
              [105.47516354500007, 9.832922367000039],
              [105.47156292800005, 9.83304368100004],
              [105.47117066100002, 9.833038475000054],
              [105.46949095200003, 9.832651004000057],
              [105.4671419110001, 9.83190045400004],
              [105.46188892000005, 9.828618336000082],
              [105.45932731400011, 9.827058872000103],
              [105.45739640400004, 9.825883340000095],
              [105.45551102000009, 9.824344755000068],
              [105.45266728100007, 9.822917274000085],
              [105.44290674300002, 9.820687837],
              [105.43622802700004, 9.818759942],
              [105.43448195700009, 9.818762189000017],
              [105.43139985800006, 9.818154570000013],
              [105.42893364400003, 9.817240381000135],
              [105.43073790700001, 9.808186359000091],
              [105.43180819700009, 9.802404202000046],
              [105.43214796000005, 9.798155038000099],
              [105.43232571300011, 9.797031703000057],
              [105.43249126500004, 9.796646980000022],
              [105.43284667900005, 9.793464210000112],
              [105.43243497400009, 9.792174571000077],
              [105.43179501700007, 9.791287833000071],
              [105.43030318300001, 9.790275372000055],
              [105.42911038100007, 9.789981026000037],
              [105.42834393300008, 9.790066526000095],
              [105.427577539, 9.79019428],
              [105.42693873500008, 9.790195090000074],
              [105.42583035300002, 9.789308920000027],
              [105.42416802900007, 9.788169854000016],
              [105.42378438100012, 9.78787448100009],
              [105.42378395300008, 9.787536363000092],
              [105.42416669200004, 9.787113234000076],
              [105.42489033300005, 9.786858740000069],
              [105.42548627400006, 9.786646671000108],
              [105.42595418400006, 9.786223441000084],
              [105.42608108200008, 9.785547046000074],
              [105.42595246100009, 9.784870983000092],
              [105.42535528100007, 9.78411096200009],
              [105.42424735700006, 9.783562909000041],
              [105.42356593800008, 9.783521487000078],
              [105.4230553830001, 9.783902506000073],
              [105.42233319300004, 9.785298146000086],
              [105.42186564900008, 9.786017219000074],
              [105.42126982100007, 9.786313809000101],
              [105.42067344500002, 9.786187749000062],
              [105.42018278900011, 9.785448731000111],
              [105.41996783500008, 9.783842935000029],
              [105.42021973500005, 9.780968623000062],
              [105.42026051800005, 9.779531568],
              [105.41979170800008, 9.779236298000081],
              [105.41928073700008, 9.779279192000077],
              [105.41889758300007, 9.779364193000093],
              [105.41813284200005, 9.780802134000053],
              [105.4176235530001, 9.782197499000043],
              [105.41711379000002, 9.78321247600009],
              [105.41656080500009, 9.783720336000011],
              [105.41604978, 9.783720957000062],
              [105.41515495300004, 9.783299403000088],
              [105.41383343300002, 9.782202129000071],
              [105.41289504500001, 9.780977593],
              [105.41204212900004, 9.780006536000128],
              [105.41191343700007, 9.779245932000078],
              [105.41208310300007, 9.778696281000075],
              [105.41318932000011, 9.777891908000045],
              [105.41459358600004, 9.777044897000041],
              [105.41501870000005, 9.776452673000067],
              [105.41514550700005, 9.775691757000081],
              [105.41514462000006, 9.774973254000051],
              [105.41484575000003, 9.774339650000028],
              [105.41416396700002, 9.774002362000099],
              [105.41356789600007, 9.774087609000047],
              [105.41305708100001, 9.774257290000053],
              [105.41199377000004, 9.7753152050001],
              [105.41118637200003, 9.77671092300003],
              [105.4102511570001, 9.778064519000028],
              [105.40974050900004, 9.778360990000044],
              [105.40927196000008, 9.778277022000024],
              [105.40888802100002, 9.777728045000076],
              [105.40850223000008, 9.775657528000091],
              [105.40798992600006, 9.774601524000024],
              [105.40730713400005, 9.773418926000053],
              [105.40645441600002, 9.772574654],
              [105.40517616900004, 9.771984463000115],
              [105.40423628000008, 9.771600714000071],
              [105.40368530000005, 9.771203875000099],
              [105.40326183700009, 9.770728682000128],
              [105.40312033900003, 9.770281125000045],
              [105.40311980000007, 9.769833413000038],
              [105.40320350200007, 9.769105779000052],
              [105.40373817700006, 9.768293655000091],
              [105.40438559400008, 9.767453425000026],
              [105.40483582700003, 9.766753321000097],
              [105.40517328200006, 9.766053370000092],
              [105.40527134600009, 9.765549571000063],
              [105.40529832100002, 9.764542186000112],
              [105.40504239900007, 9.762723643000102],
              [105.40501278500003, 9.761548421000031],
              [105.40506858900002, 9.761072659000101],
              [105.40534993800003, 9.76059661800006],
              [105.40580072800002, 9.760372231000057],
              [105.40622350300001, 9.760287776000086],
              [105.40653361300008, 9.760287405000055],
              [105.40704137199999, 9.760538642000059],
              [105.40777493400003, 9.761013464000088],
              [105.40873427300009, 9.761683881000048],
              [105.40932657500008, 9.761907034000037],
              [105.41011622600004, 9.762129947000096],
              [105.410708293, 9.762157214000043],
              [105.41118748600003, 9.762100668000072],
              [105.41166654000006, 9.761932199000077],
              [105.41220167000002, 9.761511818000111],
              [105.41242671700007, 9.761119795000093],
              [105.41265170200003, 9.760671803000063],
              [105.41291862400001, 9.759943943000058],
              [105.41314319500003, 9.759160170000046],
              [105.41347987400007, 9.757844587000085],
              [105.41361840200008, 9.755877033000092],
              [105.4042279510001, 9.751133620000106],
              [105.39773103500006, 9.747851472000056],
              [105.39711065500006, 9.747468209000052],
              [105.39538825300011, 9.746667862],
              [105.39363435300004, 9.746163952000051],
              [105.392243831, 9.745724819000133],
              [105.39163492900006, 9.745311543000044],
              [105.39158231000006, 9.745061760000072],
              [105.39128743800009, 9.74404290900006],
              [105.39116738600011, 9.743126456000082],
              [105.3910663770001, 9.7407071090001],
              [105.39082297000003, 9.739135584000012],
              [105.39050381200009, 9.738347357000075],
              [105.39005218300011, 9.73762283700008],
              [105.38914066100001, 9.736827229000065],
              [105.38835829400003, 9.736229281000062],
              [105.38698523200001, 9.735493254000092],
              [105.38580714400007, 9.734955714000114],
              [105.38501898600005, 9.73481516600012],
              [105.38271951500003, 9.734459650000103],
              [105.38074664400004, 9.734304256000124],
              [105.37858048300005, 9.733819664000086],
              [105.37608440700002, 9.733396253000047],
              [105.37457561000006, 9.732985212000054],
              [105.37352836800001, 9.732514605000107],
              [105.37241781500005, 9.731847131000036],
              [105.37117813100006, 9.73098197300004],
              [105.37007009700005, 9.730118472000092],
              [105.36922367500009, 9.729388955000038],
              [105.36870382100007, 9.728859598000026],
              [105.36779492400009, 9.727867876000039],
              [105.36494000500004, 9.724629680000072],
              [105.36164541200003, 9.719752143000092],
              [105.36009353600005, 9.717575979000069],
              [105.35924886700006, 9.716715764000037],
              [105.35586103300007, 9.713993594000046],
              [105.35351030800007, 9.712526161000044],
              [105.35109127700012, 9.711253891000114],
              [105.34206343900011, 9.706956988000036],
              [105.33984450900002, 9.705491093000072],
              [105.33768381500002, 9.704614071000071],
              [105.33650434200004, 9.704206964000138],
              [105.33551722300008, 9.704194395000075],
              [105.33262336700001, 9.704026821000065],
              [105.32999359100003, 9.703797230000076],
              [105.32874578800008, 9.703585255000034],
              [105.32776291200003, 9.703245955000027],
              [105.32724154300004, 9.702847200000074],
              [105.32685432600007, 9.702254102000028],
              [105.32666453900001, 9.701663535000103],
              [105.32655039700006, 9.701321877000078],
              [105.32660667900009, 9.701072904000084],
              [105.32662417100003, 9.699702925000063],
              [105.32702749100011, 9.699054212000123],
              [105.32763477800007, 9.698165724000061],
              [105.32848458000005, 9.697206606000107],
              [105.33045783800007, 9.695707548000064],
              [105.33158489500006, 9.694890527000053],
              [105.33229713600011, 9.693922498000072],
              [105.33288044700005, 9.691693815000056],
              [105.33308197600003, 9.690923628000101],
              [105.33315113200007, 9.690659331000083],
              [105.33340748500005, 9.690679553000068],
              [105.33398337500009, 9.690724979],
              [105.33471025700001, 9.690348957000071],
              [105.33551552900003, 9.689273360000071],
              [105.33630780100003, 9.687066896000117],
              [105.33651089300005, 9.68616320000004],
              [105.33683816700005, 9.684706925000073],
              [105.33686164800012, 9.682890912000095],
              [105.33687587800006, 9.68179031000007],
              [105.33654744100006, 9.680621736000104],
              [105.33631010500005, 9.67959022600011],
              [105.33627060900007, 9.678468008000072],
              [105.33656800700007, 9.677741787000025],
              [105.33746738000006, 9.67709444600011],
              [105.34336483900006, 9.67441427200013],
              [105.35028900700009, 9.671863529000023],
              [105.35424899600008, 9.669651262000032],
              [105.3565571020001, 9.669324346000073],
              [105.36308318300006, 9.671329620000078],
              [105.36917473200003, 9.673542888000062],
              [105.37082861900009, 9.673706120000068],
              [105.371641133, 9.673230009000109],
              [105.37214528800007, 9.672084619000108],
              [105.37208480100003, 9.671039060000018],
              [105.37061586300008, 9.668564012000045],
              [105.36904855700007, 9.667262370000083],
              [105.36748754100006, 9.66548432800005],
              [105.36707956000005, 9.662620819],
              [105.36777160300002, 9.660650649000056],
              [105.36920629900007, 9.659798110000038],
              [105.38268197100004, 9.65929861200001],
              [105.38520582100006, 9.659919412000038],
              [105.38644905800007, 9.660225206000026],
              [105.38910492100007, 9.661137858000044],
              [105.39065789800006, 9.661377109000034],
              [105.39202442300005, 9.661324668000047],
              [105.39355551000008, 9.660753615000049],
              [105.39895339300004, 9.655763723000097],
              [105.40410107000008, 9.652969336000082],
              [105.40482854400005, 9.653162484000076],
              [105.4048633960001, 9.652274490000055],
              [105.40473956700006, 9.651904979000035],
              [105.40426156500004, 9.651438423000068],
              [105.40384937500004, 9.651198368000012],
              [105.40337729800005, 9.651034487000041],
              [105.40291200400002, 9.650814325000033],
              [105.40249131700003, 9.650511479000022],
              [105.4020102330001, 9.6500766070001],
              [105.401533536, 9.649522522000085],
              [105.401252314, 9.649211393000099],
              [105.40102757100006, 9.649077832000062],
              [105.40070676400008, 9.648931287000016],
              [105.4001413190001, 9.648854031000019],
              [105.39981480700008, 9.6487100930001],
              [105.39955244300005, 9.648129720000041],
              [105.39939032000004, 9.647808884000051],
              [105.39917750900003, 9.647377767000085],
              [105.3991365360001, 9.646920632],
              [105.39919679200001, 9.646579187],
              [105.3993479590001, 9.646177386000064],
              [105.39959018400006, 9.645835728000039],
              [105.39988326200006, 9.645755056000025],
              [105.40018654500003, 9.645754703000085],
              [105.40076297900002, 9.645924731000031],
              [105.40121792200009, 9.645934244000019],
              [105.40159189700003, 9.645873560000061],
              [105.40210710100003, 9.645551658000072],
              [105.40250120200004, 9.645410628000127],
              [105.40297630000001, 9.645369915000044],
              [105.40376476000006, 9.645308752000046],
              [105.4043407930001, 9.645137385000078],
              [105.4050503710001, 9.644791853000088],
              [105.40479769000007, 9.644104524000062],
              [105.40375755600007, 9.643105537000094],
              [105.40148686500005, 9.639795057000098],
              [105.39871110400006, 9.635297402000019],
              [105.397384679, 9.631735744000112],
              [105.39725722100012, 9.630423129000041],
              [105.39737985500005, 9.627609941000069],
              [105.39752428600011, 9.623087092000036],
              [105.39527073600007, 9.624083205000121],
              [105.39119695100007, 9.625173427000087],
              [105.38963719500006, 9.625590824000073],
              [105.38886555500008, 9.626651920000116],
              [105.38771957300003, 9.626442007000067],
              [105.38706904300003, 9.625797537000034],
              [105.38706684300011, 9.623861923000019],
              [105.38677739400005, 9.623288736000022],
              [105.38619931400009, 9.622859249000014],
              [105.38511608800009, 9.622645390000082],
              [105.38338283200002, 9.622217184000091],
              [105.37933754300008, 9.62028602900009],
              [105.37666231300005, 9.616776167000028],
              [105.37507128400004, 9.614340464000025],
              [105.37347972000008, 9.611402910000065],
              [105.37340312700005, 9.60738837900008],
              [105.372263608, 9.596911101000048],
              [105.3706869010001, 9.59804886700004],
              [105.36960361700008, 9.597721415000125],
              [105.36451352100002, 9.595370914000066],
              [105.36065193000007, 9.593915781000105],
              [105.35995915700003, 9.593654717000087],
              [105.35868796300011, 9.593697244000053],
              [105.35553981100004, 9.593778218000091],
              [105.349650427, 9.593703673000052],
              [105.34611680000002, 9.593658867000075],
              [105.3443046790001, 9.593635866000037],
              [105.34302810700008, 9.594247294000089],
              [105.34265064600002, 9.595408129000036],
              [105.3420676850001, 9.598449297000043],
              [105.34137790000005, 9.602744423000063],
              [105.34044927200004, 9.607537788000096],
              [105.341250632, 9.612606043000048],
              [105.34027832200012, 9.613759343000082],
              [105.33894512300003, 9.6137423870001],
              [105.33771193800004, 9.612999310000079],
              [105.33583581000006, 9.610833479000023],
              [105.33115134300007, 9.604781129000093],
              [105.32863614800007, 9.600355078000076],
              [105.32754696200001, 9.599981427000069],
              [105.32590852100006, 9.600039486000076],
              [105.32104892000007, 9.603581449000041],
              [105.31607248600007, 9.606092051000113],
              [105.30821984900004, 9.608004926000112],
              [105.30664833100005, 9.608757682000134],
              [105.30553053000001, 9.609729128000042],
              [105.30333027300004, 9.615453570000019],
              [105.30212244300003, 9.618269909000075],
              [105.30077605800008, 9.621728090000124],
              [105.29983001600006, 9.624419094000112],
              [105.29756953200008, 9.62812294600006],
              [105.29545427100004, 9.630670136000051],
              [105.29410019000008, 9.63351133000012],
              [105.29341040500003, 9.638520022000117],
              [105.29337536400007, 9.638772770000069],
              [105.29105228900005, 9.638371466000082],
              [105.29048455000006, 9.637654663000104],
              [105.28988396200002, 9.636896380000069],
              [105.28680668000004, 9.631669135000019],
              [105.2847689110001, 9.627147027000136],
              [105.284446761, 9.625067925000062],
              [105.28483043400004, 9.622479279000105],
              [105.28681408300012, 9.617836521000099],
              [105.28732420900005, 9.615595357000116],
              [105.28728578100007, 9.611877388000066],
              [105.28646447800006, 9.607889943000101],
              [105.28503736200003, 9.60346240600005],
              [105.28474794100006, 9.602878958000092],
              [105.2846176350001, 9.602160195000041],
              [105.2846347330001, 9.600863666000057],
              [105.28776866200006, 9.595112164000062],
              [105.29099431400005, 9.589016081000066],
              [105.29295592400008, 9.586015777000066],
              [105.29498667900006, 9.58439954],
              [105.29859659100006, 9.582285005000111],
              [105.30265721500004, 9.579194718000037],
              [105.30600894700007, 9.576908924000092],
              [105.30995327600002, 9.574567490000073],
              [105.31071750200002, 9.573680242000114],
              [105.31118070700006, 9.572789122000101],
              [105.31119046100007, 9.572041686000075],
              [105.31104971400006, 9.571292316000067],
              [105.31060602400004, 9.570688563000116],
              [105.30971474000009, 9.569780030000084],
              [105.30793414000003, 9.567813471000084],
              [105.30690801300003, 9.565707110000064],
              [105.30618289900008, 9.563604638000099],
              [105.30568352400006, 9.559712694000043],
              [105.30567217100001, 9.559400245000036],
              [105.30564542400002, 9.558663911000041],
              [105.30595618100006, 9.557920373000071],
              [105.30642133400009, 9.556879807000128],
              [105.30793208200004, 9.556450736000064],
              [105.30898160000001, 9.556763259000077],
              [105.31062528100003, 9.557681451000109],
              [105.31360584500008, 9.559962385000096],
              [105.31539616100007, 9.561181446000065],
              [105.31689914500008, 9.561350242000087],
              [105.31825358200005, 9.561367615000014],
              [105.31991677500008, 9.560790899000081],
              [105.32158191200001, 9.560064687000123],
              [105.32249651700002, 9.559179346000056],
              [105.32281109800002, 9.558136815000038],
              [105.32253730900001, 9.556040200000025],
              [105.32123537900003, 9.551986837000113],
              [105.32051988600007, 9.549137065000044],
              [105.32054515600004, 9.54719381800002],
              [105.32086944600005, 9.545403915000071],
              [105.32164516500009, 9.543619798000067],
              [105.32424781600005, 9.540851840000107],
              [105.32448622300006, 9.540756636000046],
              [105.32402423000003, 9.538387214000109],
              [105.32416406500006, 9.535173625000047],
              [105.32448486500004, 9.533131591000108],
              [105.32538652300005, 9.531681604000129],
              [105.32757075000002, 9.529663402000031],
              [105.32816830300008, 9.529070941000038],
              [105.32192213800002, 9.528379898000059],
              [105.31970678000003, 9.527911873000059],
              [105.31818635500008, 9.525254540000015],
              [105.31799285600006, 9.523053871000096],
              [105.31936263500006, 9.52021381100012],
              [105.32206209500005, 9.517610597000024],
              [105.32164361200006, 9.515626898000027],
              [105.31772602300001, 9.509421885000078],
              [105.3146826820001, 9.504327164000069],
              [105.3131338420001, 9.503867645000062],
              [105.31067947800007, 9.504715330000034],
              [105.30842134200002, 9.507543827000029],
              [105.30352972300003, 9.507920431000104],
              [105.30042340800004, 9.507660538],
              [105.29866401300004, 9.506318948000024],
              [105.29669698400009, 9.503875604000099],
              [105.29674887700003, 9.499919691000079],
              [105.29534721100006, 9.488251822000036],
              [105.29431768000005, 9.482084014000057],
              [105.29148548300002, 9.47765142100012],
              [105.29140027200006, 9.47723109700007],
              [105.29035642400004, 9.472628420000094],
              [105.28996806100005, 9.465121186000134],
              [105.290108043, 9.454474890000062],
              [105.28989638100006, 9.452052152000066],
              [105.29118535500005, 9.444334385],
              [105.29268827600006, 9.438675850000065],
              [105.29377153400007, 9.434963303000108],
              [105.28811994900008, 9.43583663300007],
              [105.285989262, 9.436061211],
              [105.28296081700007, 9.435617972],
              [105.27654890500006, 9.434075362],
              [105.27010147800003, 9.420577663000083],
              [105.26490318800006, 9.408652600000076],
              [105.26420008600007, 9.40720765100007],
              [105.26127458900004, 9.401169002000119],
              [105.26045227400004, 9.400167517000034],
              [105.26034637100007, 9.399935199000085],
              [105.25846346900006, 9.401131004000083],
              [105.23746083100011, 9.414585452000067],
              [105.22305646100004, 9.423733253000053],
              [105.19027098900003, 9.444678951000094],
              [105.187894032, 9.446192246000072],
              [105.17874084500002, 9.451837315000054],
              [105.15234328900009, 9.468061046000019],
              [105.14288225200002, 9.473836165000103],
              [105.13518272300006, 9.478529537000034],
              [105.13489339200001, 9.478722040000063],
              [105.13367006600004, 9.479453823000126],
              [105.13201682000003, 9.480454478000054],
              [105.11929562800006, 9.488163537000014],
              [105.10314035300007, 9.497929069000042],
              [105.07927767600009, 9.512523265000093],
              [105.06337679900001, 9.522245660000104],
              [105.06298384500009, 9.522485902000087],
              [105.06239030900009, 9.522828870000042],
              [105.05940141300006, 9.52457583300008],
              [105.05914678400005, 9.524703057000037],
              [105.0502120360001, 9.529865369000044],
              [105.04823541700007, 9.531007338000029],
              [105.03860422200006, 9.536748602000065],
              [105.0263985230001, 9.54416204200008],
              [105.02566405000007, 9.544608131000041],
              [105.02253727900009, 9.546573921000066],
              [105.00111157700005, 9.559517990000098],
              [105.00012417700006, 9.55597276100004],
              [105.00000573600002, 9.555543109000018],
              [104.99991328500002, 9.555198666000118],
              [104.99961580900001, 9.554090364000077],
              [104.99942806300001, 9.553457770000049],
              [104.99922484800007, 9.552712723000044],
              [104.99898903100009, 9.551968908000051],
              [104.99763326200011, 9.547231084000083],
              [104.99619833100003, 9.54503130100006],
              [104.9941445270001, 9.541898970000061],
              [104.99271382300003, 9.539689273000105],
              [104.99167048000004, 9.540218699000123],
              [104.99009950800007, 9.541015848000088],
              [104.98987501700006, 9.541193815000069],
              [104.98926344900009, 9.541556107000114],
              [104.98874678200006, 9.541748859000075],
              [104.9884509500001, 9.541852348000111],
              [104.98769859500003, 9.542011307000029],
              [104.98627889000007, 9.542159996000075],
              [104.98500782100005, 9.542293110000076],
              [104.98269171500009, 9.542676397000081],
              [104.98033017100009, 9.54321752900009],
              [104.97830921300003, 9.543939095000019],
              [104.97612927200005, 9.544976373000045],
              [104.97472138400009, 9.545697958000064],
              [104.97101498100002, 9.547513334000078],
              [104.96829271600002, 9.54939417500009],
              [104.96466562200003, 9.55324535400006],
              [104.96408803400003, 9.553763626000132],
              [104.96207103400012, 9.555298565000035],
              [104.96116411900005, 9.556659839000046],
              [104.95789762200005, 9.552591138000109],
              [104.95684235900004, 9.551324316000097],
              [104.95438483000001, 9.54837403300006],
              [104.95297725000006, 9.546283856000047],
              [104.95120192899999, 9.54383916200001],
              [104.948551674, 9.540208776000013],
              [104.94572396800007, 9.536211675000049],
              [104.94406286000003, 9.533999194000041],
              [104.93944548000007, 9.538545081000025],
              [104.93780907200005, 9.54017034400003],
              [104.93476455300002, 9.543152012000087],
              [104.93317882900001, 9.544752824000023],
              [104.93109834400008, 9.54692796100005],
              [104.92715301700005, 9.550654936000045],
              [104.92437470300004, 9.553453224000029],
              [104.92264934400001, 9.555163951000088],
              [104.91931274600007, 9.558524294000035],
              [104.91714330600007, 9.56063822300006],
              [104.91535799800006, 9.564121562000059],
              [104.909051886, 9.55187979700005],
              [104.90719445400006, 9.548363589000084],
              [104.90024033000003, 9.535077611000096],
              [104.89965332000006, 9.533955210000043],
              [104.89385301700001, 9.52279543800003],
              [104.89214316300003, 9.519395657000105],
              [104.89189548900005, 9.518903186000037],
              [104.89153517500009, 9.518216897000096],
              [104.88977465199999, 9.514863573000108],
              [104.88831340000006, 9.511981431000026],
              [104.88798871500011, 9.511340817000045],
              [104.88753851700007, 9.510488458000042],
              [104.88645060900002, 9.508358514000024],
              [104.88541844000007, 9.506337660000062],
              [104.88435164200008, 9.504218237000115],
              [104.88377169800006, 9.503096579000035],
              [104.88286207800012, 9.501382257000033],
              [104.88220153100008, 9.500020658000084],
              [104.87965832600007, 9.49518769200006],
              [104.87944682700007, 9.495332498000051],
              [104.87701636100005, 9.497762491000026],
              [104.87486389800003, 9.499905850000088],
              [104.87329078500008, 9.501428220000017],
              [104.8705965180001, 9.503866022000105],
              [104.86986490900004, 9.504461884000113],
              [104.86305505600005, 9.509838637000099],
              [104.86031949300011, 9.511958968000032],
              [104.86001919600004, 9.512204368000086],
              [104.859158886, 9.512938599000085],
              [104.85840924400006, 9.513578374000085],
              [104.85672495300005, 9.515027874000024],
              [104.856373332, 9.515348882000028],
              [104.85510529800007, 9.516506507000065],
              [104.85170400400003, 9.519870996000089],
              [104.84931197700008, 9.522807723000069],
              [104.84887443900003, 9.523360639000076],
              [104.84854915500003, 9.523771689000069],
              [104.84735277600006, 9.525385570000109],
              [104.84684824900006, 9.52613019300008],
              [104.84511555100005, 9.529001700000082],
              [104.84373945000006, 9.53039393900008],
              [104.84134896000006, 9.53123815600012],
              [104.83925531000003, 9.531816173000072],
              [104.83702893300003, 9.531814220000088],
              [104.83585522599999, 9.531802981000046],
              [104.83536081900003, 9.531771331000092],
              [104.83476929200003, 9.531790247000059],
              [104.8328968860001, 9.53189009400006],
              [104.83284175400006, 9.533145682000031],
              [104.83345341100005, 9.537986732000102],
              [104.83356758000008, 9.539968834000055],
              [104.83469298600002, 9.55386270900007],
              [104.83614583300006, 9.56541257900003],
              [104.83639364600005, 9.568862205000082],
              [104.83663626900005, 9.570446873000014],
              [104.83673798500003, 9.571111215000093],
              [104.83696718500008, 9.573398290000055],
              [104.83736741400006, 9.579211180000067],
              [104.83778818300006, 9.582222543000095],
              [104.83926218200007, 9.590056084000052],
              [104.83962518900009, 9.593505764000019],
              [104.83966217200003, 9.596478831000075],
              [104.83992963900005, 9.599070842000044],
              [104.84109577400008, 9.608848141000069],
              [104.84214736000004, 9.617443781000111],
              [104.84346752000003, 9.626420701000081],
              [104.84398275500003, 9.632919710000108],
              [104.84448032200008, 9.636121681000097],
              [104.84618429200002, 9.645556152],
              [104.84666176100009, 9.65083541500009],
              [104.84700632400009, 9.652931946000097],
              [104.84746458200009, 9.658306500000078],
              [104.84884219500003, 9.668217242000042],
              [104.849971236, 9.676088669000114],
              [104.8503341000001, 9.680300636000093],
              [104.851061777, 9.684341234000014],
              [104.85196141600009, 9.690268644000087],
              [104.85272774100008, 9.693813746000044],
              [104.8529361240001, 9.695246581000026],
              [104.85393356900002, 9.702104466000082],
              [104.85533114100011, 9.711043223000091],
              [104.85611415200006, 9.720667799000092],
              [104.86107882200005, 9.739441882000042],
              [104.86197968000008, 9.741782828000021],
              [104.86322382300007, 9.745301738000121],
              [104.86733020200002, 9.756915402000031],
              [104.86984162100006, 9.763366311000075],
              [104.87114447100004, 9.767439216000094],
              [104.87308648100006, 9.774784412000058],
              [104.87509937700007, 9.783094759000031],
              [104.87602282200001, 9.787308752000088],
              [104.87654423400006, 9.788344698000094],
              [104.87682822200003, 9.79008675000008],
              [104.87902968100011, 9.802163520000116],
              [104.87992973600005, 9.805741905000074],
              [104.88127962600001, 9.811745051000019],
              [104.8813847820001, 9.81213091500006],
              [104.88189554500008, 9.814005096000054],
              [104.88255852000005, 9.817442151000043],
              [104.885187989, 9.827635821000056],
              [104.88650427600003, 9.83187182900007],
              [104.89305780400012, 9.845246228000091],
              [104.89433814500009, 9.84736521300009],
              [104.89576095600005, 9.849154691000093],
              [104.89794288900002, 9.850991461000101],
              [104.90024366000004, 9.852192687000104],
              [104.90214137800004, 9.852616956000036],
              [104.90565436, 9.852775653000021],
              [104.90811079200003, 9.852595468000034],
              [104.90975517600002, 9.852024421000037],
              [104.91156716000002, 9.851185221000057],
              [104.91261430000006, 9.850442978000016],
              [104.91367442200003, 9.848834974000019],
              [104.91405925700008, 9.848624166000073],
              [104.914495372, 9.848630595000072],
              [104.91520243300006, 9.848749257000032],
              [104.91618206400003, 9.848871934000057],
              [104.91699977900001, 9.848883979000076],
              [104.91874666100004, 9.848747340000051],
              [104.92120708000004, 9.848296460000048],
              [104.92481228200009, 9.847862373000014],
              [104.92995173700007, 9.847802195],
              [104.93217581600007, 9.847869287000053],
              [104.94199669300005, 9.849942616000094],
              [104.95425523800003, 9.855223882000084],
              [104.96390865700002, 9.862834660000084],
              [104.96695161200009, 9.866235585000103],
              [104.971872343, 9.871607923000079],
              [104.98550289400006, 9.888364352000066],
              [104.98726132700008, 9.889776636000123],
              [104.98743735199999, 9.889962874000078],
              [105.00430595800002, 9.907807575000048],
              [105.00733389600001, 9.910518372000075],
              [105.01567703900011, 9.918742403000101],
              [105.02089660200005, 9.923135392000047],
              [105.02450994600007, 9.925868263000035],
              [105.02999166100003, 9.931136724000032],
              [105.03469745100001, 9.934470441000086],
              [105.04181564700011, 9.939647405000061],
              [105.04703571700001, 9.942784761000032],
              [105.05193943100002, 9.945294470000119],
              [105.05794320600003, 9.948075369],
              [105.06186553500002, 9.949333013000048],
              [105.07001188100001, 9.950978884000071],
              [105.07346, 9.950527699000043],
              [105.07658184000005, 9.949719920000087],
              [105.07809971600005, 9.948570457000104],
              [105.07919166600007, 9.947202191000047],
              [105.07996644200006, 9.94551024000012],
              [105.08097980300005, 9.942118851000091],
              [105.08208980200006, 9.939473790000085],
              [105.08401366800007, 9.934368377000078],
              [105.08456327500008, 9.932909834000013],
              [105.08621896900011, 9.928681711000118],
              [105.09137650900007, 9.928307628000075],
              [105.09619447600002, 9.927963652000036],
              [105.09756884500004, 9.929366162000084],
              [105.09863356500003, 9.929913016000034],
              [105.10038221800004, 9.929940817000025],
              [105.10130812900007, 9.930375737000039],
              [105.10202872500003, 9.932514108000044],
              [105.10292197700002, 9.937352432000067],
              [105.10372275900002, 9.941689733000112],
              [105.10412621600001, 9.943504483000075],
              [105.10619211700006, 9.948952132000065],
              [105.10718636200008, 9.950895372000089],
              [105.10722793800005, 9.951741911000054],
              [105.10657855500006, 9.952158638000073],
              [105.10432064900003, 9.952553116000027],
              [105.10238286100009, 9.953254565000067],
              [105.09997988700005, 9.955542226000079],
              [105.09582150800006, 9.964549134000043],
              [105.09339131000007, 9.969871366000048],
              [105.08664422700004, 9.984548799000057],
              [105.08343282200006, 9.991652953000061],
              [105.07834244200006, 10.003312200000012],
              [105.07651336700008, 10.00556856900001],
              [105.07498098600011, 10.007922948000035],
              [105.0741799960001, 10.010241616000124],
              [105.07379497000004, 10.011356172000061],
              [105.07226255700007, 10.01380862700003],
              [105.06699715100007, 10.019032721000086],
              [105.05369712400007, 10.033306421000086],
              [105.04797984300006, 10.039288764000085],
              [105.03564869500012, 10.052190099000045],
              [105.01890504000004, 10.06934296900006],
              [105.012679695, 10.075719515000079],
              [105.00811916800005, 10.080465822000038],
              [105.00778716000011, 10.080811345000116],
              [104.99862132300005, 10.088136037000071],
              [104.99490956100007, 10.091524001000083],
              [104.98984943400008, 10.095149527000034],
              [104.98575399100007, 10.098083701000069],
              [104.98226260400003, 10.099562873000066],
              [104.97920881800003, 10.099968994000095],
              [104.97532888000009, 10.100560553000014],
              [104.971523566, 10.100782172000054],
              [104.96547987200007, 10.100633633000109],
              [104.95861553200011, 10.099745106000045],
              [104.95279584900007, 10.098708634000085],
              [104.94361877600005, 10.09670980800008],
              [104.93727703200008, 10.095007176000069],
              [104.93100993400006, 10.093452387000033],
              [104.92220635100003, 10.090861219000134],
              [104.91613735300007, 10.089517956000103],
              [104.91116454600009, 10.088417174000062],
              [104.90348011200004, 10.08686147900004],
              [104.89915284500003, 10.086416324000067],
              [104.89504928300005, 10.086415053000051],
              [104.89102012900001, 10.08700556000006],
              [104.88706540200009, 10.088113873000013],
              [104.88199099100007, 10.090553361000064],
              [104.87878199700003, 10.092475610000038],
              [104.87557273800007, 10.094989649000039],
              [104.87355739700003, 10.097060250000121],
              [104.86594271600006, 10.107192136],
              [104.86415102400005, 10.109410724000066],
              [104.85959588600011, 10.117694264000042],
              [104.85563842400003, 10.123832669000075],
              [104.85212850300006, 10.130045194000024],
              [104.848618625, 10.135887786000096],
              [104.84607933600005, 10.140399211000071],
              [104.83650209100006, 10.154443093000063],
              [104.82940874500005, 10.163993280000037],
              [104.82075878100005, 10.17650106100003],
              [104.81814518000007, 10.179951313000045],
              [104.81285598100004, 10.186235347000137],
              [104.80744257200004, 10.19190283200002],
              [104.79991378000008, 10.198924937000074],
              [104.79618064000002, 10.202004485000058],
              [104.7918878130001, 10.204960370000025],
              [104.77898104700004, 10.213706639000096],
              [104.77807568600009, 10.21432010400005],
              [104.77689349500007, 10.215182219000061],
              [104.77340952000002, 10.21709055800004],
              [104.76824580800007, 10.219798959000046],
              [104.76208721700006, 10.22213673400003],
              [104.75530696300002, 10.224042454000058],
              [104.74666049400004, 10.226439694000076],
              [104.7412488840001, 10.227606544000077],
              [104.736210945, 10.22809559200004],
              [104.73123570400001, 10.227968252000073],
              [104.72632334900008, 10.227039620000065],
              [104.71904954000007, 10.224074853000074],
              [104.70997413300003, 10.21913597100008],
              [104.70310097800005, 10.214812998000065],
              [104.70251569200012, 10.21444486400007],
              [104.69667384300001, 10.210309829000062],
              [104.689776685, 10.204386226000038],
              [104.68084415900006, 10.195819095000045],
              [104.677162604, 10.19159213900007],
              [104.67425998600001, 10.188391490000102],
              [104.67091948800001, 10.184169896000038],
              [104.66814710300004, 10.179957302],
              [104.65750429800008, 10.16637782100007],
              [104.65238581400006, 10.15947109900006],
              [104.64758759600006, 10.151689710000065],
              [104.64682451400009, 10.150513934000037],
              [104.64664092900009, 10.14729337500002],
              [104.64689925800005, 10.143724933000085],
              [104.64712846500001, 10.141942312000117],
              [104.64536, 10.139929253000089],
              [104.64417888400003, 10.13871949000009],
              [104.64220066900003, 10.13729847600011],
              [104.64100662500002, 10.136882387000055],
              [104.63980611100007, 10.136863135000061],
              [104.63899927700001, 10.137247141000037],
              [104.63778577700006, 10.138021565000075],
              [104.63574270100003, 10.140568936000081],
              [104.63349948000005, 10.143113082000086],
              [104.631272455, 10.144665103000071],
              [104.62163532600003, 10.146494691000017],
              [104.61560306200008, 10.14818363100005],
              [104.6137989150001, 10.148352957000027],
              [104.61259832700006, 10.148333549000109],
              [104.611207451, 10.147715622000051],
              [104.60805825500003, 10.144489024000068],
              [104.60647387300003, 10.143470983000034],
              [104.60507322100004, 10.14344829100011],
              [104.6004612520001, 10.143968935000112],
              [104.59823725100003, 10.14532217400008],
              [104.59801089900003, 10.146906319000058],
              [104.59858493400002, 10.148503463000045],
              [104.60291497400009, 10.152940254000063],
              [104.60565418200007, 10.156755785000103],
              [104.60799981800008, 10.160167958000075],
              [104.60817374600008, 10.16175863200006],
              [104.60812527100006, 10.163956745000034],
              [104.60527720600004, 10.167467744000101],
              [104.60342699900008, 10.170415043000068],
              [104.599342591, 10.175310951000059],
              [104.59646789800009, 10.179347161000061],
              [104.59559068600007, 10.180812940000104],
              [104.59472397200004, 10.18164458200008],
              [104.5933207890001, 10.182678940000043],
              [104.58846985400002, 10.185877241000084],
              [104.58792111200009, 10.186819765000086],
              [104.58758555100005, 10.187765761000021],
              [104.58778821400003, 10.188403366000019],
              [104.58841725200003, 10.189047914000058],
              [104.59108210600007, 10.189091285000094],
              [104.59203969600007, 10.189212578000094],
              [104.59278410800002, 10.189330401000072],
              [104.59362810300004, 10.18987271300009],
              [104.59414882800006, 10.190621210000115],
              [104.59476039100012, 10.192322640000027],
              [104.59484248399998, 10.193804027000029],
              [104.59437059000001, 10.19654505000007],
              [104.59327869700009, 10.199585627000117],
              [104.58940624000005, 10.204566550000036],
              [104.58727316800004, 10.205727275000063],
              [104.58578640500004, 10.205948133000041],
              [104.58478980200009, 10.206422037000083],
              [104.58339606300005, 10.20848242400003],
              [104.58337977900004, 10.209462452000116],
              [104.58373824300003, 10.210203522000059],
              [104.58446536200006, 10.211073127000068],
              [104.58593182400008, 10.212077322000111],
              [104.588384757, 10.213220106000074],
              [104.58995856800001, 10.215206318000101],
              [104.59031097100004, 10.216314892000076],
              [104.59028862500006, 10.217662452000107],
              [104.58900486100006, 10.22341355600007],
              [104.58741807600003, 10.231273874000111],
              [104.58608782900011, 10.236097163000045],
              [104.58501655800002, 10.239009234000028],
              [104.58453025100002, 10.240916771000029],
              [104.58461954600003, 10.24238300300002],
              [104.58515401400003, 10.244419865000063],
              [104.58568661200005, 10.246569373000101],
              [104.585667906, 10.247695826000045],
              [104.58542942300008, 10.248367997000061],
              [104.58479477600005, 10.249178706000082],
              [104.5819624330001, 10.251804454000089],
              [104.58036010900004, 10.254472214000108],
              [104.57637869000004, 10.260852921000028],
              [104.57383063800006, 10.265160454000098],
              [104.57200813600008, 10.268241320000103],
              [104.57072412100007, 10.270174120000062],
              [104.56579346100004, 10.277595785000047],
              [104.55793327000001, 10.285267368000104],
              [104.55154636900002, 10.289730225000058],
              [104.54833568800002, 10.291367637000091],
              [104.54456615000007, 10.29243237300008],
              [104.54308484500007, 10.292633317000098],
              [104.54217559900006, 10.292618319000011],
              [104.540717038, 10.291467406],
              [104.53836823700007, 10.289174942000033],
              [104.53614262500005, 10.28632108100007],
              [104.53528846800008, 10.283039143000037],
              [104.53375815000003, 10.279407996000065],
              [104.53309526200006, 10.278270209000071],
              [104.53230543100008, 10.277919102000064],
              [104.53094357000002, 10.277783879000067],
              [104.52992074100004, 10.277766947000087],
              [104.529119506, 10.278091731000048],
              [104.52865351100007, 10.278760107000016],
              [104.52874242500006, 10.280226446000105],
              [104.52884276100011, 10.281016891000137],
              [104.530178022, 10.282729238000064],
              [104.53161555400003, 10.285119383000028],
              [104.53236360700001, 10.287948847000015],
              [104.53210019800007, 10.290085493000054],
              [104.53161897100004, 10.291655113000074],
              [104.53032694600007, 10.294112797000119],
              [104.52728625300001, 10.299133288000027],
              [104.52552806200003, 10.302259371000043],
              [104.52550902000004, 10.303385923000084],
              [104.5258252510001, 10.304856100000046],
              [104.52750543500012, 10.306348871000102],
              [104.52895451600003, 10.308063190000015],
              [104.52995087100004, 10.309657316000107],
              [104.53014586800001, 10.311576239000074],
              [104.52547029900005, 10.324340035000111],
              [104.5252938830001, 10.324821630000079],
              [104.52199690600007, 10.329976562000097],
              [104.51897464800003, 10.33465662000008],
              [104.51274339100004, 10.342743360000027],
              [104.48964451900011, 10.366911027000112],
              [104.48495192100006, 10.37184183600006],
              [104.48465609900009, 10.372711828000101],
              [104.48422154000011, 10.374653294000067],
              [104.48417393500009, 10.37508997400005],
              [104.48428546400007, 10.375608859000083],
              [104.48486079600006, 10.377169569000076],
              [104.4850608950001, 10.377647370000085],
              [104.48487053100007, 10.377819814000054],
              [104.48357202000001, 10.378996052000032],
              [104.48216483100002, 10.380270717000071],
              [104.48149070600002, 10.379932090000031],
              [104.48096015400009, 10.379646367000104],
              [104.48041309800004, 10.37959454800008],
              [104.47968154200008, 10.380060270000067],
              [104.47867180300003, 10.381407287000053],
              [104.47587644100001, 10.383669063000054],
              [104.47353410700001, 10.383697003000057],
              [104.47142382400003, 10.383386972000073],
              [104.4696256280001, 10.382759194000089],
              [104.46675469100005, 10.381396331000035],
              [104.46405233600009, 10.379494197000037],
              [104.46307675900003, 10.37854398200002],
              [104.45722657400003, 10.373463795000058],
              [104.44375897500004, 10.365233646000059],
              [104.44266985400007, 10.365454161000097],
              [104.44133963600005, 10.365670588000063],
              [104.44047714000001, 10.366731424000085],
              [104.44034416800011, 10.367446156000069],
              [104.44044196600004, 10.368762292000076],
              [104.44066031400007, 10.370080486000075],
              [104.44136089400004, 10.371406868000042],
              [104.44266014000007, 10.372982406000107],
              [104.44417778200005, 10.375876133000082],
              [104.444265273, 10.377789621000131],
              [104.44272755500005, 10.383021559000037],
              [104.441431338, 10.386247782000078],
              [104.44197686700002, 10.387810223000125],
              [104.44406204100007, 10.390767099000085],
              [104.44844275900006, 10.39403343000007],
              [104.44940081700001, 10.394883713000079],
              [104.45052803400003, 10.396990270000027],
              [104.45076151600007, 10.400656795000083],
              [104.45000019900004, 10.404864716000082],
              [104.44905803400007, 10.407507575000084],
              [104.44805416900006, 10.409761189000031],
              [104.44605671500008, 10.413671065000061],
              [104.44432722400006, 10.416031891000086],
              [104.4429718640001, 10.417682010000105],
              [104.43693743600005, 10.422998746000099],
              [104.45902985600003, 10.429180931000113],
              [104.46991918400005, 10.429364528000027],
              [104.47771490600007, 10.428468212000029],
              [104.48506229400004, 10.423454501000061],
              [104.49354074800007, 10.41210905200005],
              [104.49673978800008, 10.416603201000017],
              [104.50036090600003, 10.417564556000068],
              [104.50467971100005, 10.419174289000031],
              [104.50815682800007, 10.42081046400005],
              [104.50955738300004, 10.421533585000107],
              [104.5101849150001, 10.422136351000065],
              [104.50951178300008, 10.423657164000042],
              [104.51135014700004, 10.431221767000041],
              [104.51379682500007, 10.439993563000092],
              [104.515495237, 10.443670307000017],
              [104.51972174800002, 10.448696285000024],
              [104.52352421100005, 10.451931225000076],
              [104.53136512700009, 10.453224908],
              [104.53508913300001, 10.449361705000102],
              [104.53790448200007, 10.453391029000068],
              [104.54715752400007, 10.4597580580001],
              [104.54714475500008, 10.46274081700007],
              [104.54798001100008, 10.470358475000106],
              [104.5495763820001, 10.47364399900008],
              [104.55227926800009, 10.47920662800008],
              [104.55324341500003, 10.483298598000077],
              [104.55319362000007, 10.488843345000108],
              [104.55303581100007, 10.4941600650001],
              [104.55644847500008, 10.49975142900008],
              [104.56315298300007, 10.502219947000045],
              [104.567716542, 10.501098918000105],
              [104.57030991699999, 10.498978098000043],
              [104.57066471700004, 10.499016524000114],
              [104.57517193500011, 10.501274732000086],
              [104.58104844200005, 10.504659312000085],
              [104.58007251400005, 10.51187107700011],
              [104.57949166500003, 10.516994927000043],
              [104.58020791300005, 10.52259710800003],
              [104.58173434000005, 10.527881193000075],
              [104.58577442300002, 10.533492763000128],
              [104.58934687300011, 10.537490049000018],
              [104.59554977500007, 10.540382219000042],
              [104.59649243399998, 10.54082172200008],
              [104.60464139600002, 10.540681977000064],
              [104.609877505, 10.539091523000097],
              [104.61573582600005, 10.535370465000058],
              [104.62696745500006, 10.531101279000053],
              [104.63940194900002, 10.532328418000068],
              [104.64299162200003, 10.534954804000039],
              [104.64756873900011, 10.540679632000137],
              [104.65898298100004, 10.540861734000105],
              [104.69170241100008, 10.539325160000066],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 33, code: "AD01", ten_tinh: "Kon Tum" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.6975052830001, 15.313324399000104],
              [107.70063789900003, 15.311050255000056],
              [107.7034372680001, 15.307365065000054],
              [107.70539654700012, 15.304926462000077],
              [107.70634685100006, 15.304656519000035],
              [107.71019931300005, 15.306777571000028],
              [107.7138850640001, 15.308301570000092],
              [107.71975125800007, 15.309014597000024],
              [107.727016647, 15.309086637000034],
              [107.72602834500003, 15.30298103700005],
              [107.72464280200006, 15.292480984000088],
              [107.72479570200001, 15.282722376000011],
              [107.72522676300005, 15.273641839000083],
              [107.7256533570001, 15.267814234000097],
              [107.72803467700004, 15.261175892],
              [107.73181013300005, 15.251744188000059],
              [107.73405509700008, 15.242394856000095],
              [107.73531961200008, 15.235755066000054],
              [107.73510073500009, 15.23026418400004],
              [107.74115877700004, 15.230198052000075],
              [107.74190702400006, 15.23018987200002],
              [107.74598053700004, 15.231184167000043],
              [107.74933965000005, 15.233265891000068],
              [107.75269676800009, 15.237029550000079],
              [107.75524346400005, 15.237131426000071],
              [107.76105277500002, 15.234961300000103],
              [107.76645517400009, 15.232196919000083],
              [107.76839389600009, 15.229428679000099],
              [107.76880488299999, 15.22636195900011],
              [107.76941903400009, 15.223790166000038],
              [107.77145859300001, 15.221813535000093],
              [107.77502607400004, 15.219838508000104],
              [107.77930500500005, 15.219249325000115],
              [107.78728903400003, 15.21917597],
              [107.79454713200006, 15.217827581000074],
              [107.80096934500004, 15.214941973000061],
              [107.80655278500008, 15.213410893000033],
              [107.80980873600001, 15.21359454200004],
              [107.81343574000007, 15.214953215000014],
              [107.81678368800006, 15.216311587000051],
              [107.8198316240001, 15.216008802000134],
              [107.82385620700002, 15.214058405000051],
              [107.8273940710001, 15.210988873000096],
              [107.82972137300001, 15.209183435000016],
              [107.83455983500005, 15.208102850000047],
              [107.83958300400008, 15.208648870000017],
              [107.84860561400011, 15.210372390000069],
              [107.85576840200008, 15.211190626000091],
              [107.863117489, 15.211737641000054],
              [107.86869989800005, 15.210927859000021],
              [107.872608805, 15.2084],
              [107.87689109700001, 15.203613164],
              [107.87968386500008, 15.200361614000089],
              [107.88219736900004, 15.1972906030001],
              [107.88740821600005, 15.194672829000043],
              [107.88964097700006, 15.194312530000074],
              [107.891314811, 15.195397769000072],
              [107.89373114600008, 15.199736494000058],
              [107.89754227100011, 15.20552171600009],
              [107.89996020700006, 15.207149436000032],
              [107.90554166300002, 15.207784531000089],
              [107.90861139499999, 15.208328057000118],
              [107.91149428700005, 15.210678757000016],
              [107.914005575, 15.211944894000061],
              [107.91679645300009, 15.212126735000096],
              [107.92293705000003, 15.210773561000044],
              [107.92651160900003, 15.210291588000045],
              [107.92864018600002, 15.208343500000064],
              [107.92991783100004, 15.205686463000044],
              [107.93028972700009, 15.204877493000078],
              [107.93168219200001, 15.2018484800001],
              [107.93210884900006, 15.198600611000044],
              [107.93204880500005, 15.196356522000086],
              [107.93281545300007, 15.195024677000022],
              [107.93518977000006, 15.192950185000063],
              [107.93886937300005, 15.191337206000098],
              [107.94516007700007, 15.188917819000023],
              [107.94729668500001, 15.187419584000049],
              [107.94800952900002, 15.184422226000025],
              [107.94807900700009, 15.179616127000093],
              [107.94938522700008, 15.17638832200001],
              [107.95211513400008, 15.174429023000078],
              [107.95769307300002, 15.172354982000067],
              [107.96422014600006, 15.170742117000112],
              [107.97039114200004, 15.1690137140001],
              [107.97537532800004, 15.16763086900008],
              [107.980003524, 15.165440857000066],
              [107.98285171000003, 15.162789442000067],
              [107.98736107800001, 15.160138095000073],
              [107.99270095100005, 15.157832553000093],
              [107.99590482500003, 15.155757427000081],
              [107.99815936500005, 15.1532210890001],
              [107.99886673700004, 15.150169290000102],
              [108.00088387500006, 15.14763292500003],
              [108.00207039400009, 15.144981262000011],
              [108.00207036500008, 15.142214329000046],
              [108.00040923800003, 15.137718061000081],
              [107.99815495200004, 15.133452357000097],
              [107.99732448500006, 15.129532521000042],
              [107.99732454800008, 15.124805685000096],
              [107.99708731300007, 15.12123172800006],
              [107.99590099500006, 15.117657757000112],
              [107.99340979099999, 15.112585002000055],
              [107.99068151700003, 15.106013454000065],
              [107.98736033900003, 15.098634827000089],
              [107.98498818400006, 15.093907864000043],
              [107.98226033500003, 15.088489112000046],
              [107.97988815600004, 15.086298429000065],
              [107.97660030000006, 15.084208043000034],
              [107.97536947800009, 15.081655197000108],
              [107.97487733500006, 15.079182218000129],
              [107.97479590500006, 15.074076809000035],
              [107.97455014100007, 15.070487041000112],
              [107.97307309200008, 15.068891428000073],
              [107.96913401800002, 15.067215687000067],
              [107.96289717100011, 15.065220410000089],
              [107.95912236900006, 15.063624282000097],
              [107.95477357300004, 15.060193226000072],
              [107.95181993400008, 15.057161266000028],
              [107.95075383200006, 15.054129700000106],
              [107.95059037200005, 15.051417437000046],
              [107.95288881500005, 15.048386637000098],
              [107.95650014200008, 15.04543586200011],
              [107.95684120000006, 15.044792601],
              [107.95789570600009, 15.042803688000015],
              [107.95715782500005, 15.039772237000102],
              [107.95494684400008, 15.037864341000072],
              [107.96135007, 15.028275929000044],
              [107.96458006700009, 15.021474707000058],
              [107.96993251000001, 15.015456497000022],
              [107.97413002600004, 15.012702583000069],
              [107.97895686300009, 15.011070837000061],
              [107.98105557000009, 15.009234717000107],
              [107.98283958900001, 15.005970320000104],
              [107.98514822200006, 15.001889810000078],
              [107.987876294, 15.000155695000037],
              [107.99112887600009, 14.999645766000034],
              [107.99595519800002, 15.001278193000099],
              [107.99994222000004, 15.001176206000098],
              [108.00602762600003, 14.999339818000033],
              [108.00823094400008, 14.999033697000106],
              [108.01179834700011, 15.000767817000058],
              [108.01536577000005, 15.002093815000082],
              [108.01893309700007, 15.001787471000048],
              [108.02501820100005, 14.99882835000008],
              [108.03519509000009, 14.996582550000069],
              [108.04253900700004, 14.994540871000071],
              [108.04663950400003, 14.991498130000112],
              [108.05300530400005, 14.981936609000089],
              [108.05992914700005, 14.972592045000075],
              [108.06316797800008, 14.969766560000062],
              [108.06696595900009, 14.969004947000107],
              [108.07400403400004, 14.969545806],
              [108.07891892200008, 14.968349054000017],
              [108.08383299400008, 14.965305359000068],
              [108.08539578800006, 14.9625888390001],
              [108.08517039900006, 14.957917576000044],
              [108.08550381500002, 14.953897911000078],
              [108.08784860500006, 14.951615610000067],
              [108.10348770500005, 14.953020864000075],
              [108.10773320000006, 14.954431034000047],
              [108.11611439300003, 14.960292990000122],
              [108.12331223300006, 14.961458921000046],
              [108.12504492700006, 14.96155151900007],
              [108.13394505600007, 14.962026963000062],
              [108.14218260500007, 14.963083239000062],
              [108.1483059920001, 14.964274546000043],
              [108.15390047700008, 14.969075374000072],
              [108.15741540900002, 14.974636256000016],
              [108.15858891500004, 14.97880797600004],
              [108.15820269700006, 14.983739507000047],
              [108.15561055600008, 14.994741901000095],
              [108.15184524100002, 15.002457614000074],
              [108.15015956700009, 15.009033826000094],
              [108.14886328600002, 15.014724670000044],
              [108.14769827800009, 15.022185629000083],
              [108.1487439480001, 15.029265735000051],
              [108.14887868000005, 15.03558782],
              [108.14719300100003, 15.042669840000132],
              [108.14563545000003, 15.047222888000087],
              [108.14654807200004, 15.050130477000055],
              [108.14993244800003, 15.053415675000091],
              [108.15617993100003, 15.058342523000066],
              [108.15969427900011, 15.061121700000067],
              [108.16398808900001, 15.062509335000039],
              [108.16971213600004, 15.063010642000039],
              [108.17413385300006, 15.061742640000107],
              [108.181024667, 15.057817138000116],
              [108.18908317300001, 15.050982172000092],
              [108.19714213100005, 15.045158461000073],
              [108.20208102700005, 15.041360529000068],
              [108.20636902700002, 15.037183784000073],
              [108.21039684300004, 15.033133657000073],
              [108.21676750200001, 15.030851302000057],
              [108.22196969400002, 15.030593072],
              [108.22847306200006, 15.030839102000114],
              [108.23671048100005, 15.032735223000058],
              [108.24480820900001, 15.027875070000094],
              [108.24667233200006, 15.027659649000034],
              [108.24853527700002, 15.026484468000055],
              [108.25061581300011, 15.023922718000014],
              [108.25127063100003, 15.02136262200011],
              [108.2507165960001, 15.016777826000103],
              [108.25093221600009, 15.013791708000033],
              [108.25213489900004, 15.010911063000075],
              [108.25574734400007, 15.005894772000071],
              [108.26111299200002, 15.000023200000088],
              [108.26231530800003, 14.997035864000095],
              [108.26263857600003, 14.992663318000064],
              [108.26356712800003, 14.989996241000101],
              [108.26674089000009, 14.985406885000073],
              [108.27079324200001, 14.981989384000103],
              [108.27627052600005, 14.978356697],
              [108.28010461900003, 14.97589904200005],
              [108.28196570000009, 14.973870481000072],
              [108.2832774930001, 14.97109618200003],
              [108.283931276, 14.96821611100003],
              [108.28502599600009, 14.967041646000022],
              [108.28667024300006, 14.966826172000046],
              [108.28694965900009, 14.96361230600005],
              [108.28831575600006, 14.962201729000045],
              [108.29249826700008, 14.960630776000093],
              [108.29836778900007, 14.957100812000055],
              [108.30198410400006, 14.953730424000076],
              [108.3076910130001, 14.949339549000038],
              [108.31090660900006, 14.947221788000052],
              [108.31262157800002, 14.945782326000055],
              [108.31305312600003, 14.941302954000067],
              [108.31556328400006, 14.93415462600005],
              [108.31720337700006, 14.931486289000071],
              [108.31895557300007, 14.930417312000086],
              [108.32026877500002, 14.929029079000065],
              [108.32070470000011, 14.927428877000091],
              [108.32042355900003, 14.922950579000103],
              [108.32041882000009, 14.91996478500006],
              [108.32128721900008, 14.914631687000044],
              [108.32170566600007, 14.912278606000127],
              [108.32375614700008, 14.911429554000042],
              [108.32561528700008, 14.911208422000042],
              [108.32773131, 14.911236368000045],
              [108.32901141000005, 14.911370599000049],
              [108.33030147200003, 14.911076660000065],
              [108.33168075600003, 14.910286284000039],
              [108.33383981500006, 14.909173538000074],
              [108.33542964100006, 14.908674746000104],
              [108.33686894900003, 14.907913431000084],
              [108.33827751600006, 14.906714249000073],
              [108.34028457200003, 14.904492309000082],
              [108.34213138300007, 14.902262517000063],
              [108.34352732000011, 14.900342361000083],
              [108.345370105, 14.899411370000058],
              [108.34784875200009, 14.898417451000066],
              [108.34899139100004, 14.897178226000028],
              [108.34956002200002, 14.895011920000021],
              [108.34904321600007, 14.890372667000038],
              [108.348521851, 14.883073110000032],
              [108.34940916700006, 14.88133936000011],
              [108.35125099800004, 14.879975246000042],
              [108.35328574500008, 14.879848152000095],
              [108.35494111100009, 14.880959024000083],
              [108.35793305600002, 14.882686328000093],
              [108.36009612300003, 14.883177635000065],
              [108.3619401170001, 14.883050780000049],
              [108.36282931900004, 14.882430588000071],
              [108.36441191800007, 14.880378887000044],
              [108.36524227800003, 14.878329578000125],
              [108.36671198400003, 14.873822787000089],
              [108.36835856500004, 14.867267803000049],
              [108.37060648300005, 14.859831647000108],
              [108.37185744900007, 14.856895680000013],
              [108.373360491, 14.85393458700006],
              [108.37481100000011, 14.849155805000079],
              [108.37586873500005, 14.84721763600003],
              [108.37772292000008, 14.845536227000087],
              [108.38056998300002, 14.842755799000072],
              [108.38262002300009, 14.839460411000081],
              [108.38599633100003, 14.836033525000046],
              [108.38990303100006, 14.832541077000092],
              [108.39195497400001, 14.829949664000084],
              [108.39488994800003, 14.826673086000051],
              [108.39540460400006, 14.826098529000118],
              [108.39873454400008, 14.824230029000061],
              [108.40161908300009, 14.823515845000122],
              [108.4050864390001, 14.820913970000047],
              [108.40853943100007, 14.818924934000053],
              [108.41162441600007, 14.818138043000049],
              [108.41495791100002, 14.818131587000048],
              [108.41903292300003, 14.81848406900008],
              [108.42267583700009, 14.818837331000088],
              [108.42588498600004, 14.818410424000115],
              [108.42911808900003, 14.817266756000079],
              [108.42988503600007, 14.81479544300006],
              [108.43142437000007, 14.812429958000093],
              [108.43362531400001, 14.8099557310001],
              [108.43433841000004, 14.808128800000107],
              [108.43696391000003, 14.805867005000016],
              [108.43863618800002, 14.804885838000102],
              [108.44053256100007, 14.804302535000067],
              [108.44269026300007, 14.804117013000083],
              [108.44722821500002, 14.80373650200004],
              [108.44989275300004, 14.802952435000092],
              [108.45135629900008, 14.801651908000045],
              [108.45254927700002, 14.79860039000007],
              [108.45433641800001, 14.79301760600009],
              [108.45586563800006, 14.791327680000064],
              [108.45833090400002, 14.790933167000018],
              [108.46219913800007, 14.791962770000032],
              [108.46973560100005, 14.793957339000128],
              [108.47627208600007, 14.795824140000045],
              [108.48340922000006, 14.797948858000074],
              [108.49193915000006, 14.801402607000023],
              [108.49425047600009, 14.796450790000025],
              [108.49663446300008, 14.791569460000085],
              [108.49851099600008, 14.787042601000076],
              [108.50030414100004, 14.782409073000034],
              [108.5022575040001, 14.779648605000071],
              [108.5054478120001, 14.778015816000066],
              [108.50900212200003, 14.776806080000069],
              [108.51342613100005, 14.775028897000107],
              [108.51596290000006, 14.773397524000041],
              [108.51733754000003, 14.771556941000073],
              [108.51913410200008, 14.7644155890001],
              [108.52028541200004, 14.760455648000102],
              [108.52202215800003, 14.758331511000032],
              [108.52477218300001, 14.756660781000084],
              [108.52614515700002, 14.754254851000058],
              [108.52714778100002, 14.74902332],
              [108.52685115499999, 14.746621520000057],
              [108.5257586710001, 14.745210958000053],
              [108.52343204300007, 14.743732759000048],
              [108.52204851699999, 14.742040247000075],
              [108.5206617370001, 14.73907579900011],
              [108.51905543000005, 14.735405262000077],
              [108.51584064600007, 14.727145545000029],
              [108.51342915200003, 14.720721093000043],
              [108.51153228100003, 14.716839230000021],
              [108.51001729299999, 14.713781335000052],
              [108.507252142, 14.710890780000073],
              [108.502962677, 14.707862476000065],
              [108.49932648600006, 14.7048325520001],
              [108.49620084, 14.702790651000084],
              [108.49503640200001, 14.701450782000068],
              [108.49503155500005, 14.699472263000056],
              [108.49691335000006, 14.697418695000051],
              [108.49988154800006, 14.694514634000109],
              [108.50248734500011, 14.69182336700001],
              [108.5048081030001, 14.691252595000035],
              [108.50742106800003, 14.691458373],
              [108.50937771900001, 14.690393788000032],
              [108.51096919800005, 14.688411457000059],
              [108.51110808000001, 14.685937974000039],
              [108.5102310450001, 14.683466916000041],
              [108.5080519700001, 14.678361528000037],
              [108.50579420200008, 14.675045815000052],
              [108.50273553300003, 14.670601395000025],
              [108.50105894200004, 14.667496247000054],
              [108.49916525100004, 14.664603591000079],
              [108.49741447100004, 14.660791984000097],
              [108.49355843600003, 14.656490636000095],
              [108.49013617500002, 14.651481621000071],
              [108.48613554400004, 14.647251137000071],
              [108.48329977300006, 14.644643144000023],
              [108.48242460600002, 14.642737293000073],
              [108.48147472100004, 14.639913020000071],
              [108.47938816600006, 14.635149220000086],
              [108.47886718500003, 14.63214741100005],
              [108.47920276700009, 14.629199286000103],
              [108.48022505300003, 14.626916957000089],
              [108.48364441700005, 14.624295521000084],
              [108.48746602200006, 14.622785333000097],
              [108.49043349100002, 14.622222427000063],
              [108.49534384200004, 14.622377900000048],
              [108.49945807000002, 14.623814181000048],
              [108.50219921400007, 14.624085797000051],
              [108.50442459200008, 14.623580048000038],
              [108.50596456300005, 14.622964684000104],
              [108.50807684500002, 14.622904046000061],
              [108.51064797900004, 14.62367645300011],
              [108.51440037600001, 14.624953244000059],
              [108.52084110300009, 14.625246109000043],
              [108.52400615600006, 14.62441569700008],
              [108.526113858, 14.622970866000083],
              [108.5280020660001, 14.618338798000073],
              [108.52976401800004, 14.612625456000037],
              [108.53259561200011, 14.605523082000092],
              [108.53447520400002, 14.602980257000107],
              [108.537855135, 14.598517396000064],
              [108.54185341500005, 14.593674240000086],
              [108.54553707000011, 14.589654468000022],
              [108.5445741070001, 14.589892546],
              [108.54007027700004, 14.586840203000049],
              [108.53240846300008, 14.581807803000045],
              [108.529676577, 14.578152152000088],
              [108.52862501300004, 14.572597946000075],
              [108.52820610900001, 14.561106502000063],
              [108.52728854400003, 14.55818415600003],
              [108.5258702510001, 14.556482102000066],
              [108.52115303900007, 14.554788133000061],
              [108.517718261, 14.554337313000064],
              [108.51388139400005, 14.554608993000071],
              [108.51212977100008, 14.554154019000086],
              [108.51091360200007, 14.552451383000074],
              [108.50982797400005, 14.549108467000059],
              [108.50841055200002, 14.547668695000125],
              [108.50611960300009, 14.546886966000015],
              [108.49904649300007, 14.545198096000076],
              [108.49352511000002, 14.544817363000016],
              [108.48625542500008, 14.545293262000046],
              [108.4779116500001, 14.547083283000093],
              [108.46990103100006, 14.549581621000039],
              [108.46061580200006, 14.552505910000056],
              [108.45544712100008, 14.553969029000056],
              [108.45456541500003, 14.554130845000122],
              [108.45036449900009, 14.554748538000071],
              [108.44484241200007, 14.555016371000116],
              [108.44046176400008, 14.556135830000104],
              [108.43485691900004, 14.558709713000082],
              [108.43083080000008, 14.561707333000038],
              [108.42522963000009, 14.56616003600004],
              [108.42015084500007, 14.568988728000052],
              [108.41656035100007, 14.570874853000058],
              [108.41428535300005, 14.573014614000089],
              [108.41271463500003, 14.576605002000063],
              [108.41158674400006, 14.582415278000036],
              [108.40989897000003, 14.586710751000085],
              [108.40816644800003, 14.590285363000087],
              [108.40581154700006, 14.593376913000087],
              [108.40265036400007, 14.597256851000038],
              [108.39973462400005, 14.599623034000063],
              [108.39731318100007, 14.600596046000101],
              [108.39451805500008, 14.601146014000042],
              [108.39085194400005, 14.601152767000068],
              [108.38892648600009, 14.601580006000068],
              [108.38694004900003, 14.602612636000066],
              [108.38532539000002, 14.603099809000051],
              [108.38346054900009, 14.602739983000033],
              [108.38265069500005, 14.601651893000065],
              [108.38152992900004, 14.600443288000074],
              [108.38016188500009, 14.599900956000054],
              [108.37891947700005, 14.600084757000074],
              [108.37649815400009, 14.601178601000074],
              [108.374510087, 14.601363692000023],
              [108.37289372400002, 14.600942817000105],
              [108.37108829500002, 14.599069518000094],
              [108.3687823760001, 14.595320625000062],
              [108.36743029800006, 14.591395883000045],
              [108.36673989600004, 14.587583666000102],
              [108.36524530400001, 14.585709792000044],
              [108.36213616600007, 14.584322902000045],
              [108.35753451000008, 14.582151585000068],
              [108.35573003000009, 14.580641343000076],
              [108.35553831100003, 14.577615162000066],
              [108.35559224500005, 14.572954260000069],
              [108.35616358600008, 14.569009499000083],
              [108.35620607200003, 14.568716142000058],
              [108.35676062900009, 14.566112429],
              [108.35806207300004, 14.564294350000038],
              [108.36079260900001, 14.562594916000114],
              [108.36426500900008, 14.560809556],
              [108.366559029, 14.5582633740001],
              [108.37027696600005, 14.553051407000071],
              [108.37188602900004, 14.549719481000077],
              [108.37206482400009, 14.545603168000108],
              [108.37087942400007, 14.542820884000028],
              [108.36963316300006, 14.540704525000068],
              [108.36938147600003, 14.538949609000083],
              [108.37018547600012, 14.53701127700011],
              [108.37440119400004, 14.532524761000063],
              [108.37699573800005, 14.530480718000137],
              [108.38065646700007, 14.528295167000048],
              [108.38183311600008, 14.52641666100005],
              [108.38207666200007, 14.523813478000045],
              [108.38132113400009, 14.518427763000117],
              [108.38000776900004, 14.513187523000058],
              [108.37876294600008, 14.511797581000073],
              [108.37484675500005, 14.510049172],
              [108.37080508000004, 14.507574577000069],
              [108.36564139800001, 14.502801750000048],
              [108.36097189000002, 14.496393700000086],
              [108.35845429600008, 14.49335886300007],
              [108.34392093300008, 14.477584450000068],
              [108.33588126300003, 14.468964252000125],
              [108.33138161300005, 14.46413924900005],
              [108.32603179700007, 14.459535170000047],
              [108.32328007100004, 14.45686123900005],
              [108.32197841400007, 14.454185088000047],
              [108.32151200800003, 14.448829558000041],
              [108.32143988400001, 14.444940774000068],
              [108.32052035700005, 14.442561621000037],
              [108.31769064000004, 14.438623089000123],
              [108.31516870300001, 14.436246302000109],
              [108.31188407700002, 14.434093774000088],
              [108.30951408000004, 14.431195966000042],
              [108.30698838100005, 14.426215386000061],
              [108.30484779000004, 14.423540371000064],
              [108.30278501300006, 14.421832314000048],
              [108.30026565900008, 14.420943186000022],
              [108.29784232600011, 14.420735338000055],
              [108.29616267600005, 14.420068161000097],
              [108.29425044500002, 14.416871995000056],
              [108.29164888600006, 14.411816985000046],
              [108.290118513, 14.408769043000101],
              [108.29003880300007, 14.406388648000055],
              [108.29110408700009, 14.404378641000013],
              [108.29384516700006, 14.400506567],
              [108.29467997700003, 14.397455396000057],
              [108.29444318700007, 14.392025219000091],
              [108.29108493100003, 14.391062760000038],
              [108.29058855100007, 14.391010956000104],
              [108.28475173300004, 14.390401811000062],
              [108.28047907600011, 14.390184308000096],
              [108.27834219300004, 14.389666383000053],
              [108.27597624000003, 14.388999942000067],
              [108.27170362300011, 14.388782272000087],
              [108.26644089000004, 14.389830387000034],
              [108.26003372000007, 14.390879763000084],
              [108.25332141400007, 14.39200371],
              [108.24988971800008, 14.393198013000051],
              [108.24668858900007, 14.395805409000049],
              [108.24470656700004, 14.397146720000096],
              [108.24318112300003, 14.397520423000019],
              [108.24119693199999, 14.397076332000045],
              [108.23757489900007, 14.393669871000013],
              [108.23383421900009, 14.39166547700008],
              [108.23177380600005, 14.391221383000106],
              [108.23009597200004, 14.391743940000088],
              [108.22791176000007, 14.393159701000078],
              [108.22559629900007, 14.393162167000105],
              [108.21994948200003, 14.392126607000042],
              [108.21323330600006, 14.389827341000048],
              [108.207814759, 14.388047332000029],
              [108.20544747100006, 14.385817927000042],
              [108.20353595000009, 14.381579521],
              [108.20254076900008, 14.378158533000105],
              [108.20116575300008, 14.376300088000081],
              [108.198189187, 14.374889476000073],
              [108.19284710200004, 14.372737082000063],
              [108.18872602700003, 14.370955421000085],
              [108.18185961500008, 14.370068729000083],
              [108.16843222300002, 14.368443202000057],
              [108.16339593800005, 14.366512995000045],
              [108.1586635880001, 14.362945878000042],
              [108.15568426200005, 14.35729448400007],
              [108.1537741650001, 14.353204470000057],
              [108.15194176100002, 14.350899727000053],
              [108.1509992630001, 14.350174874000031],
              [108.14414891400006, 14.350225934000081],
              [108.14191874500008, 14.352668503000119],
              [108.14115593400004, 14.353728696000088],
              [108.14103912700006, 14.354673887000045],
              [108.14156830400005, 14.35547546600011],
              [108.14330153900002, 14.356247588000047],
              [108.14532860900005, 14.357219977000033],
              [108.14706214300004, 14.358364382000042],
              [108.14812060100004, 14.360024779000112],
              [108.14809192500007, 14.360969924000065],
              [108.14776931300003, 14.36157159000002],
              [108.14630099400003, 14.361715807000051],
              [108.14157235100002, 14.361260756000059],
              [108.13935794600003, 14.360988144000062],
              [108.13852591000003, 14.360583118000124],
              [108.1380167990001, 14.359389281000022],
              [108.13727644200003, 14.357880157000034],
              [108.13632884800005, 14.357385075000105],
              [108.13425687200004, 14.356929813000095],
              [108.13370870100006, 14.356472890000036],
              [108.13260200800006, 14.356243039000026],
              [108.13126789000009, 14.356443331000078],
              [108.12848872600009, 14.35684256700007],
              [108.12582189200005, 14.3571349090001],
              [108.12359053100008, 14.358081875000027],
              [108.12046191000003, 14.359775504000092],
              [108.11675352600007, 14.363410127000085],
              [108.11452070000006, 14.364465199000074],
              [108.11330304300003, 14.364233892000032],
              [108.11209096200002, 14.363569746000058],
              [108.11132683000002, 14.362586380000051],
              [108.10915794300007, 14.358663708000028],
              [108.10610714900008, 14.354297353000065],
              [108.10612106499998, 14.35321525100006],
              [108.10668828200002, 14.352248144000066],
              [108.10785553500006, 14.350939881000089],
              [108.10847875300001, 14.350249189000051],
              [108.1099825310001, 14.348258630000108],
              [108.11023240100008, 14.346545787000052],
              [108.10998469500001, 14.3451036470001],
              [108.109227859, 14.344099564000066],
              [108.10546378900004, 14.340186693000055],
              [108.10230807600007, 14.338015867000118],
              [108.09843942800006, 14.336030625000083],
              [108.09487630700005, 14.335261470000125],
              [108.08991194700008, 14.33481103100007],
              [108.080883478, 14.334814653000103],
              [108.08024410200005, 14.334773315000058],
              [108.07712152800005, 14.334571379000048],
              [108.07629990600002, 14.33400821000008],
              [108.07586702, 14.333103795000033],
              [108.07565311000006, 14.332196147000074],
              [108.0758661950001, 14.330901746000089],
              [108.07678287900009, 14.329753088000084],
              [108.07862380000009, 14.328209352000075],
              [108.082218819, 14.3266134300001],
              [108.08414, 14.325760531000052],
              [108.08518013900007, 14.324864602000085],
              [108.08589462200004, 14.323802465000044],
              [108.08564229600003, 14.320132500000058],
              [108.08470223000005, 14.318299990000048],
              [108.08454945000003, 14.318091190000091],
              [108.08429658700007, 14.317903295000088],
              [108.07881422200003, 14.310814588000119],
              [108.06638734600008, 14.285993214000124],
              [108.05794747900005, 14.266636454000013],
              [108.05490241399998, 14.268003788000081],
              [108.05115484600006, 14.270737777000074],
              [108.04670441600007, 14.273699618000089],
              [108.03850565400009, 14.277800863000055],
              [108.03592839900001, 14.276662532000016],
              [108.0333509860001, 14.274385399000076],
              [108.03100770000002, 14.27074166500004],
              [108.02655583200008, 14.265048401000067],
              [108.0242127490001, 14.261176859000011],
              [108.01366994600006, 14.243640803000089],
              [108.01156169300006, 14.241591157000054],
              [108.00828236300008, 14.24090805000011],
              [108.00219230200004, 14.24432448200008],
              [107.99821023400008, 14.245463251000066],
              [107.99118302600004, 14.245690824000066],
              [107.966355643, 14.233161998000108],
              [107.96260857600004, 14.230200609000041],
              [107.95956377900008, 14.229744551000055],
              [107.9586268890001, 14.22974437100004],
              [107.91621937800004, 14.262072763000065],
              [107.91059544900003, 14.265942299000043],
              [107.90520535900001, 14.270267307000049],
              [107.90051719300006, 14.275959057000074],
              [107.89465830000006, 14.2798280860001],
              [107.88809733400005, 14.281874545000049],
              [107.88083392300011, 14.283009378000065],
              [107.85669756600004, 14.277802577000019],
              [107.85436400300004, 14.277299016000098],
              [107.84982258700008, 14.27664171700007],
              [107.84803921100004, 14.276383573000116],
              [107.84265264000004, 14.274102107000084],
              [107.83609595300004, 14.270453070000054],
              [107.83467636900009, 14.267592093000035],
              [107.83422701800004, 14.264074489000043],
              [107.83470168300005, 14.256558970000109],
              [107.83594508200008, 14.247631773000078],
              [107.836351022, 14.244717091000057],
              [107.836591325, 14.237201454000024],
              [107.83495375700004, 14.234694922000111],
              [107.83237800799999, 14.233781904000063],
              [107.82811098000008, 14.233856079000072],
              [107.82628549300003, 14.234062566000103],
              [107.82544207700005, 14.233878908000095],
              [107.82467261800008, 14.233350935000109],
              [107.82274382900009, 14.231094835000045],
              [107.82081626700003, 14.22878172700006],
              [107.81954363200006, 14.226555162000041],
              [107.81916962600003, 14.225548510000062],
              [107.81864302000007, 14.223162025000045],
              [107.81792308800001, 14.220146214000094],
              [107.81711451500007, 14.217109753000033],
              [107.81616918700011, 14.215789035000091],
              [107.81454744000004, 14.214863700000089],
              [107.81048027600005, 14.213787083000094],
              [107.80778741100009, 14.21406576900006],
              [107.80562371500012, 14.214327784000067],
              [107.80427092300002, 14.214986491000035],
              [107.80088768000003, 14.217887131000049],
              [107.79696138500006, 14.220771254000029],
              [107.79414791100007, 14.222506769000097],
              [107.79180357700008, 14.223693789000079],
              [107.78861540300008, 14.22515441100005],
              [107.78608393600005, 14.225975248000021],
              [107.78384110100002, 14.226449865000033],
              [107.78203723100007, 14.22675947000007],
              [107.78147510300002, 14.22668050000002],
              [107.78079517800006, 14.226270828000052],
              [107.7803823620001, 14.225878787000033],
              [107.77856444900006, 14.221518154000099],
              [107.77774670200007, 14.219293452000057],
              [107.7772918310001, 14.218581340000112],
              [107.77683686600002, 14.217958185000066],
              [107.77647253900008, 14.217779896000058],
              [107.77601708600005, 14.217601510000042],
              [107.77501455500006, 14.217689411000032],
              [107.77337291600003, 14.21884406700009],
              [107.77209628600009, 14.21955433100006],
              [107.77072921600005, 14.219641821000016],
              [107.76963574700008, 14.219551682000107],
              [107.76773293600012, 14.218919309000098],
              [107.76407987000002, 14.216876985000047],
              [107.76198566300012, 14.215362433000054],
              [107.76107562500005, 14.214293974000023],
              [107.76062229700003, 14.212336486000064],
              [107.76089882700008, 14.209668186000089],
              [107.76108286600002, 14.20815618800013],
              [107.76094833100008, 14.20633249900005],
              [107.76040326100011, 14.2049086310001],
              [107.75994858900006, 14.204107539000075],
              [107.75894707000006, 14.203394775000076],
              [107.75767144500003, 14.203304378000057],
              [107.75521679500005, 14.2037224700001],
              [107.75228052300011, 14.204674144000061],
              [107.74909948300008, 14.205744870000013],
              [107.74071515300002, 14.208977405000043],
              [107.7281749630001, 14.214434240000083],
              [107.72523808500002, 14.21562430700005],
              [107.72266180000003, 14.216515703000086],
              [107.72138562000004, 14.216780901000091],
              [107.71473164400007, 14.217839535000019],
              [107.70871615300007, 14.218454055000061],
              [107.70525358900002, 14.218182432000058],
              [107.70224719700005, 14.21755556800006],
              [107.69881883000006, 14.216782405000037],
              [107.69678019300005, 14.217014129000026],
              [107.69342426600011, 14.21869020800006],
              [107.69183377600005, 14.219866888000075],
              [107.68993891700003, 14.221274056000064],
              [107.68847988900002, 14.222338847000058],
              [107.68729418000007, 14.222515545000112],
              [107.68629213600011, 14.222247204000025],
              [107.68606166600004, 14.221992441000085],
              [107.68376256600007, 14.218319971000069],
              [107.68172027600011, 14.214579459000019],
              [107.67988244200009, 14.211213282000033],
              [107.67759765900006, 14.206901864000086],
              [107.67612140200004, 14.202271253000019],
              [107.67585131300007, 14.2007511500001],
              [107.67599779000003, 14.198946285000067],
              [107.67651389000009, 14.194639127000118],
              [107.67635422400011, 14.192156287000033],
              [107.67562412700002, 14.189886894000024],
              [107.67464668000005, 14.1877248140001],
              [107.67305112200002, 14.184195387000019],
              [107.67271903300008, 14.183381198000065],
              [107.6705702830001, 14.180137185000062],
              [107.66889192900005, 14.178392331000021],
              [107.66610930900003, 14.175546217000077],
              [107.66548701800006, 14.175131824000074],
              [107.66306275800011, 14.173517444000082],
              [107.6609049260001, 14.171905321000098],
              [107.658765481, 14.170228675000105],
              [107.65819633400008, 14.169782636000059],
              [107.65739854000006, 14.168408700000066],
              [107.65657229900009, 14.166964696000049],
              [107.65639038200004, 14.165003542000052],
              [107.65703106700003, 14.16296037100002],
              [107.65940802600005, 14.156920454000053],
              [107.66087430200005, 14.151056834000107],
              [107.66197549800003, 14.145725908000093],
              [107.66212267400005, 14.144404940000042],
              [107.662529933, 14.140749625000113],
              [107.66199181100006, 14.135949383000044],
              [107.66090941300003, 14.130081758000033],
              [107.6587396960001, 14.121368342000016],
              [107.65729733700003, 14.113189373000077],
              [107.65603378700003, 14.106965991000029],
              [107.65503741900001, 14.104209214000017],
              [107.65358255900007, 14.103495841000056],
              [107.65067074200002, 14.103313326000054],
              [107.64885189500008, 14.102599323000041],
              [107.64739800500004, 14.101352654],
              [107.64703677600008, 14.099752289000021],
              [107.64722280600002, 14.097441826000077],
              [107.65159942700004, 14.092472004000099],
              [107.6547003590001, 14.088388790000026],
              [107.65561516500003, 14.085546263000113],
              [107.65507524400003, 14.081990382000063],
              [107.65163029300001, 14.074519275000073],
              [107.64936243700004, 14.070516183000057],
              [107.64736376400005, 14.068735379000062],
              [107.64645543000003, 14.067845126000032],
              [107.64336308000006, 14.065626630000059],
              [107.6431339320001, 14.065501176000071],
              [107.64077089900007, 14.064320331000067],
              [107.63884318900008, 14.062815765000012],
              [107.63622063400008, 14.060086876000087],
              [107.63471281200002, 14.057575579000046],
              [107.63398665700007, 14.056611927000072],
              [107.63267548600002, 14.055477776000112],
              [107.63130796800003, 14.055191011000099],
              [107.62920656700007, 14.055098026000044],
              [107.62811824900008, 14.055243479000088],
              [107.62649493000006, 14.055844304000063],
              [107.62432945400003, 14.057198757000043],
              [107.62231817200006, 14.058704380000096],
              [107.619377306, 14.061566584000039],
              [107.61635967500008, 14.064126776000039],
              [107.61450406500003, 14.064953471000051],
              [107.61288191600002, 14.064875065000047],
              [107.61218752200003, 14.064421045000051],
              [107.61195691500004, 14.063816952000073],
              [107.61172660900007, 14.063061932000041],
              [107.61157428100009, 14.061929769000068],
              [107.61149905300007, 14.060873197000133],
              [107.61158151200007, 14.058156819000088],
              [107.61127642200005, 14.056118865000132],
              [107.61050697800006, 14.054532824000086],
              [107.60965972300004, 14.053248464000133],
              [107.60867827400001, 14.052397202000042],
              [107.60574783700011, 14.050863521000066],
              [107.60333208700007, 14.049841110000026],
              [107.60178850700007, 14.049159098000084],
              [107.60015335000006, 14.047760791000117],
              [107.59909102200008, 14.046060224000099],
              [107.59870035600008, 14.043714304000064],
              [107.59911057500001, 14.036175203000044],
              [107.5995012560001, 14.033912192000029],
              [107.59935064900007, 14.031949994000035],
              [107.59889065200011, 14.030213589000072],
              [107.59654157600008, 14.027297422000105],
              [107.58979009000004, 14.023480470000036],
              [107.58562228300008, 14.022038687000114],
              [107.58160852400006, 14.020823508000111],
              [107.57561860600006, 14.019645012000035],
              [107.56956099500005, 14.02027125800007],
              [107.56697326100007, 14.020697975000116],
              [107.55820376300004, 14.022820715000071],
              [107.55502143100007, 14.023931307000035],
              [107.55235522700009, 14.025187136000026],
              [107.55130975500001, 14.02588541300007],
              [107.54935655700005, 14.02650789300006],
              [107.54822265700003, 14.026243017000082],
              [107.54724122900009, 14.025652039000123],
              [107.54536691200008, 14.023910883000033],
              [107.54408356300004, 14.022315176000076],
              [107.54168244400006, 14.01379165100005],
              [107.540294832, 14.012656789000031],
              [107.53850759700001, 14.011837115000068],
              [107.53485519300004, 14.012067016000083],
              [107.53095547900006, 14.013961361000058],
              [107.52827521300007, 14.014906888000043],
              [107.52486620500009, 14.015137166000089],
              [107.52048391100006, 14.015127328000075],
              [107.519745797, 14.014875090000077],
              [107.5192683010001, 14.014410985000046],
              [107.51890053700005, 14.013062197000091],
              [107.51903274200006, 14.01108035500007],
              [107.520885816, 14.001987922000046],
              [107.52466192500006, 13.988414140000048],
              [107.52466886900001, 13.98545263500006],
              [107.52328353000006, 13.983845381000036],
              [107.52138987100005, 13.983594330000047],
              [107.520293361, 13.983599402000022],
              [107.51873278100003, 13.98360661],
              [107.51612804500007, 13.983464792000076],
              [107.51298278700007, 13.983457609000103],
              [107.51036481100007, 13.982175880000101],
              [107.50748910599999, 13.979107508000064],
              [107.50252299000006, 13.973482791000079],
              [107.49467103000009, 13.969126840000088],
              [107.489174936, 13.966051986000048],
              [107.47870213000007, 13.961944299000082],
              [107.47137261200007, 13.958609357000016],
              [107.46745492400001, 13.953496761000038],
              [107.46720425500006, 13.949158756000111],
              [107.46747502800008, 13.945842616000091],
              [107.46801386900007, 13.940230911000116],
              [107.46749848100006, 13.936912818000023],
              [107.46645432700009, 13.935379376000066],
              [107.46096227900007, 13.931283314000085],
              [107.45861211200004, 13.928215694000111],
              [107.45783547700009, 13.924641429000049],
              [107.45553593900007, 13.927187305000109],
              [107.45447447, 13.92842666900007],
              [107.45253653600005, 13.931814934000018],
              [107.45146115700007, 13.937184820000057],
              [107.450855953, 13.946986031000018],
              [107.45255943799999, 13.959055459000068],
              [107.45066527500003, 13.981766978000019],
              [107.44625125600004, 13.992734655000037],
              [107.44522836800004, 13.997715835000067],
              [107.42934479500008, 13.99559484900008],
              [107.40856617900009, 13.991692299000123],
              [107.40295465700004, 13.990115994000083],
              [107.39997162600005, 13.989865541000055],
              [107.3834571780001, 13.990168731],
              [107.38071320000007, 13.99306519400003],
              [107.37721798399998, 13.997653682000076],
              [107.35925820500009, 14.015511143000124],
              [107.35576904000004, 14.017920996000031],
              [107.35476738700004, 14.020096317000073],
              [107.35475737100009, 14.02324281200009],
              [107.35648817900004, 14.0263945890001],
              [107.35747603300007, 14.028575944000108],
              [107.36117897300002, 14.037300606000079],
              [107.36116295100004, 14.042383451000083],
              [107.36040001300006, 14.047706100000122],
              [107.36012684800002, 14.055450687000091],
              [107.36159785200005, 14.062232362000058],
              [107.36332834800005, 14.065626190000023],
              [107.36581269900009, 14.066601791],
              [107.37004277800006, 14.066130270000075],
              [107.37452083200002, 14.065901450000061],
              [107.37750569400008, 14.065910208000075],
              [107.37899442600006, 14.067124785000116],
              [107.37973397300006, 14.06930535600006],
              [107.38046244600011, 14.075116597000113],
              [107.38044986600005, 14.079231357000042],
              [107.379198671, 14.081648183000082],
              [107.37720340100002, 14.083336681000079],
              [107.37322023900006, 14.084293174000083],
              [107.36500656900009, 14.085721094000101],
              [107.35354956499999, 14.090043449000065],
              [107.34732022400007, 14.09317106200003],
              [107.34083954900005, 14.097023862000045],
              [107.33435783100003, 14.101118529000088],
              [107.33135806500007, 14.105466025000089],
              [107.33084930000008, 14.10885313100003],
              [107.33158050600011, 14.113454404000068],
              [107.33330775100011, 14.117816765000056],
              [107.34121433300007, 14.134785105000079],
              [107.347148652, 14.146422025000048],
              [107.35086415200003, 14.151758622000086],
              [107.35706066800007, 14.159523247000026],
              [107.35932545000006, 14.161855446000081],
              [107.35994538100002, 14.162676532000072],
              [107.35997907900004, 14.163602718000055],
              [107.35727418800006, 14.170658535000056],
              [107.35727034700001, 14.171868819000098],
              [107.35925364800006, 14.174295386000056],
              [107.36272718800005, 14.177694636000114],
              [107.36595424200007, 14.180366928000064],
              [107.37366650400003, 14.181358002000087],
              [107.37490866900005, 14.182087835000077],
              [107.37763595300001, 14.185484659000023],
              [107.38454958900007, 14.203659334000028],
              [107.39024750600007, 14.212632041000093],
              [107.39235458200005, 14.215795989000044],
              [107.39433221800009, 14.220400840000112],
              [107.39689070600004, 14.224707082000075],
              [107.39974721100003, 14.229180036000065],
              [107.40263134600011, 14.233726725000027],
              [107.40360858900006, 14.24002316500008],
              [107.40285627000007, 14.245374033000056],
              [107.40396896700003, 14.257376637000084],
              [107.40578097300003, 14.263267676000078],
              [107.40751392800007, 14.266661470000129],
              [107.40750188500004, 14.270776605],
              [107.407237299, 14.276101397000039],
              [107.40521118800005, 14.287715120000131],
              [107.40415086700007, 14.29309691300009],
              [107.3974378020001, 14.305848649000017],
              [107.390684741, 14.315512365000059],
              [107.38967907200002, 14.318656450000054],
              [107.38916490300002, 14.32398060800004],
              [107.38965133500004, 14.327855209000083],
              [107.39138821200004, 14.330038858000103],
              [107.39634998300006, 14.3365890260001],
              [107.39982335200003, 14.341198294],
              [107.40304850500007, 14.345564740000059],
              [107.40700305900008, 14.349884996000116],
              [107.40724597500009, 14.351632402000101],
              [107.40770484600006, 14.355310909000083],
              [107.4077902010001, 14.363290231000024],
              [107.40864237300008, 14.36450928300008],
              [107.409693347, 14.364703204000049],
              [107.41262996400006, 14.364673299000062],
              [107.41410046200005, 14.364862932000031],
              [107.4157916950001, 14.366073389000087],
              [107.41770151000001, 14.368100088000089],
              [107.41966645000007, 14.373190718000027],
              [107.41992955200007, 14.379205505000046],
              [107.41955133100004, 14.384041424000047],
              [107.418341126, 14.388555407000057],
              [107.41837417199999, 14.391624349000052],
              [107.41951790900004, 14.400411231000108],
              [107.41933237700005, 14.402663922000068],
              [107.41893485300001, 14.404714174000064],
              [107.41811331900007, 14.406359541000045],
              [107.41728956900005, 14.40780029700006],
              [107.41730500200008, 14.409232467000075],
              [107.41795645800006, 14.411271976000071],
              [107.42072803700006, 14.41533593200006],
              [107.42221655900002, 14.417162213],
              [107.42369182700006, 14.417760920000065],
              [107.42495287800003, 14.417952581000025],
              [107.42997933000008, 14.417082401000057],
              [107.43228717100007, 14.417058619000043],
              [107.43397003600005, 14.417450503000049],
              [107.43588273000003, 14.419681537000082],
              [107.43864582400003, 14.422926843000104],
              [107.44118803200006, 14.425151312000127],
              [107.44435527200001, 14.426960023000033],
              [107.44582617800003, 14.427149386000069],
              [107.447082806, 14.426931742000091],
              [107.4477077650001, 14.426516021000067],
              [107.45102890500002, 14.423207714000073],
              [107.45331436000002, 14.42113782000005],
              [107.45455974300003, 14.419897186000073],
              [107.45517347100009, 14.418458508000052],
              [107.45618659700008, 14.415174161],
              [107.45657257200004, 14.412100944000093],
              [107.45738708000002, 14.409841727000037],
              [107.45779545700006, 14.408814404000035],
              [107.459043, 14.40777835800011],
              [107.46176128500011, 14.406931585000036],
              [107.46469384000009, 14.40649176300006],
              [107.46679623000006, 14.406879035000129],
              [107.4691219210001, 14.408491618000062],
              [107.47017987300006, 14.409298986000072],
              [107.47228002600011, 14.409481623000085],
              [107.47415459600003, 14.408234322000089],
              [107.47602462000005, 14.406577826000076],
              [107.47915559900011, 14.405112707000088],
              [107.48124439200009, 14.404272336000099],
              [107.48376632500003, 14.404655038000056],
              [107.48503409900005, 14.405460137000057],
              [107.48673731800001, 14.407692907000056],
              [107.4878225620001, 14.410955210000063],
              [107.48830343500001, 14.416474582000125],
              [107.48716957900007, 14.427740018000089],
              [107.48740210800004, 14.42978365200007],
              [107.48868822300005, 14.432225379000039],
              [107.49102799500011, 14.435065171000081],
              [107.49294135500006, 14.437295627000067],
              [107.49443056000008, 14.439121337000048],
              [107.49852212900005, 14.448489902000093],
              [107.49983138600003, 14.452977356000039],
              [107.49829458200006, 14.465679273000108],
              [107.49873256200006, 14.467311474000065],
              [107.4998184130001, 14.47057363800006],
              [107.50133774300005, 14.475058820000084],
              [107.50202232700011, 14.479962085000063],
              [107.50185377200002, 14.483646778000024],
              [107.50315422700007, 14.487315835000024],
              [107.50464391100004, 14.489141401000081],
              [107.50592607200008, 14.491173778000109],
              [107.50979571500007, 14.49931664100008],
              [107.51023158300006, 14.50074421400007],
              [107.51024311500005, 14.501767117000062],
              [107.50962732300007, 14.50300132200004],
              [107.50838422000007, 14.504446851000095],
              [107.50694734400003, 14.507326663000073],
              [107.50697037800006, 14.509372464000057],
              [107.50761845200005, 14.511002384000092],
              [107.50909682400007, 14.511804992000064],
              [107.51141473500009, 14.512598597000091],
              [107.51400762000004, 14.514010218000093],
              [107.5147704980001, 14.514828458000027],
              [107.515707487, 14.515833419000044],
              [107.51607605000004, 14.516436099000083],
              [107.5190653710001, 14.520905273000027],
              [107.52035482100003, 14.52355124700002],
              [107.520391913, 14.526824488000043],
              [107.51999763600006, 14.529079361000095],
              [107.52044061000002, 14.531120627000059],
              [107.52109118100003, 14.532955045000049],
              [107.52236682800006, 14.534373532000087],
              [107.52428148300002, 14.536603517000033],
              [107.52493210600004, 14.538437918000023],
              [107.52499023800004, 14.543552338000064],
              [107.52544262100008, 14.546411886000053],
              [107.52630554000008, 14.548448591000083],
              [107.52906934300005, 14.551487746000101],
              [107.52994165700002, 14.554342724000067],
              [107.52996731100009, 14.55659306800001],
              [107.52873111300005, 14.558652438000051],
              [107.52665752500008, 14.560925478000096],
              [107.52311906800004, 14.563623502000043],
              [107.52188044800005, 14.565478282000031],
              [107.521698438, 14.567935469000036],
              [107.52173334300005, 14.571004124000096],
              [107.52134372600005, 14.573668149000072],
              [107.52115938400007, 14.575920759000025],
              [107.52097271900008, 14.577968795000105],
              [107.51974333400005, 14.580641883000032],
              [107.51850460700007, 14.582496663000109],
              [107.51621379600006, 14.584158176000093],
              [107.51581249700007, 14.58579932500008],
              [107.51582644100007, 14.587026789000033],
              [107.516677875, 14.588040619000074],
              [107.51836679100003, 14.588840812000024],
              [107.520274984, 14.59045703100008],
              [107.52071586100006, 14.592293692000078],
              [107.52148594100001, 14.594344633000043],
              [107.52214633300011, 14.596594112000092],
              [107.52300483800009, 14.598221640000107],
              [107.52442228900004, 14.598887034000095],
              [107.52583648500008, 14.599399354000029],
              [107.52920286500009, 14.599976679000102],
              [107.53109953000008, 14.60056991400007],
              [107.53490460500005, 14.60277919000008],
              [107.53808220500007, 14.605199820000079],
              [107.54062292800005, 14.607013535000045],
              [107.54212796700006, 14.610066108000106],
              [107.54341366100003, 14.612302672000064],
              [107.54238971100004, 14.614564414000062],
              [107.5407405130001, 14.617242186000022],
              [107.5388813050001, 14.619922234000081],
              [107.53743260800005, 14.621779407000089],
              [107.53725083900011, 14.624236574000092],
              [107.53856953400006, 14.629337177000055],
              [107.5403153500001, 14.635046891000057],
              [107.5437719330001, 14.643397642000087],
              [107.54636044600005, 14.649302653000083],
              [107.54766777600003, 14.653380277000048],
              [107.547925089, 14.657469389000076],
              [107.54798424700002, 14.662583668000105],
              [107.54793752200005, 14.672465238000052],
              [107.54794474700003, 14.677315076000051],
              [107.54817609300008, 14.679153909000052],
              [107.549261438, 14.680783687000075],
              [107.55327387000008, 14.684008178000061],
              [107.55582196500005, 14.687749302000073],
              [107.5574864380001, 14.688317218000078],
              [107.55805359200005, 14.689985778000024],
              [107.55781407900004, 14.695098599000021],
              [107.5565438770001, 14.701654367000037],
              [107.55516407400006, 14.706097872000077],
              [107.55515355100007, 14.710877714000057],
              [107.55559948000003, 14.715769674000077],
              [107.5552471670001, 14.720215337000029],
              [107.55385304900004, 14.72211187],
              [107.54837632600004, 14.723470626000077],
              [107.54149337900004, 14.724217220000034],
              [107.533828419, 14.724961876000096],
              [107.52725958000005, 14.725099674000129],
              [107.52287950900002, 14.725546680000077],
              [107.52006153400001, 14.72675839500006],
              [107.51974295600003, 14.729193754000034],
              [107.52286045300005, 14.733616140000015],
              [107.53020059200003, 14.738200075000039],
              [107.53566419000001, 14.742779695000062],
              [107.53862686900001, 14.746744760000039],
              [107.53908637000011, 14.751008909000072],
              [107.53782461900006, 14.755573854000124],
              [107.53342879100006, 14.76256805700009],
              [107.53073109300006, 14.767938328000072],
              [107.52567236100001, 14.773769001000046],
              [107.52253408100005, 14.776640600000055],
              [107.51678748900009, 14.778913611000098],
              [107.50981635600009, 14.783892929000062],
              [107.5070287610001, 14.785495138000025],
              [107.50545733600002, 14.787777457000038],
              [107.50544748, 14.788079131000041],
              [107.50536329400006, 14.790655851000077],
              [107.50726556200007, 14.79540150000007],
              [107.51212478600004, 14.800577247000122],
              [107.51838130000007, 14.80363941200009],
              [107.52072483000009, 14.805930655000042],
              [107.52115300700008, 14.80881024200009],
              [107.52114477600003, 14.812281510000068],
              [107.52253300400004, 14.81389326500002],
              [107.52766061600002, 14.816275311000114],
              [107.53391747100008, 14.819506371000022],
              [107.53782669700006, 14.822139510000019],
              [107.5403412000001, 14.825954899000047],
              [107.54250776600004, 14.829769526000101],
              [107.55102209300001, 14.835460229000129],
              [107.55632220800005, 14.838942624000049],
              [107.56118337800009, 14.84428664600008],
              [107.56499985500004, 14.84996711000006],
              [107.56795285900003, 14.852597766000118],
              [107.57299532700007, 14.854809240000042],
              [107.57690698800008, 14.856849025000061],
              [107.58168824799999, 14.859229119000082],
              [107.58585594500005, 14.863809239000057],
              [107.58741494300007, 14.86745293400009],
              [107.58744462700004, 14.868690909000025],
              [107.58757533400011, 14.874141922000115],
              [107.58616655400007, 14.882097892000077],
              [107.58336510500001, 14.890051165000083],
              [107.58152910600006, 14.894026931000058],
              [107.57961252800003, 14.894869841000078],
              [107.57430375800007, 14.894605316000041],
              [107.57056114600002, 14.894597819000042],
              [107.56786045000003, 14.895777720000057],
              [107.56472013300008, 14.89898870000008],
              [107.56149287800004, 14.902114795],
              [107.55940167600004, 14.903126500000051],
              [107.55478830600006, 14.903201599000047],
              [107.55165482100006, 14.903195042000037],
              [107.54791015400011, 14.904033821000072],
              [107.54311730900011, 14.906478978000058],
              [107.53841158500005, 14.908839547000062],
              [107.53518395100006, 14.911880581000057],
              [107.53142962100003, 14.916783107000063],
              [107.52941787000002, 14.920927413000038],
              [107.528271203, 14.927359657000048],
              [107.52643616300006, 14.930318974000032],
              [107.52364827600005, 14.931244127000108],
              [107.51772783700001, 14.931569505000118],
              [107.51224428900007, 14.931218379000109],
              [107.50763170600007, 14.930699778000099],
              [107.50440762300012, 14.931962344000025],
              [107.50187596299999, 14.934835173000048],
              [107.49916760400008, 14.938723580000078],
              [107.49663325100008, 14.94261236700005],
              [107.49480099900009, 14.944216739000103],
              [107.49288400700001, 14.944889545000082],
              [107.48887649800008, 14.945980674000035],
              [107.48591000300004, 14.94851361200009],
              [107.48224541000005, 14.951637499000102],
              [107.47814342100003, 14.955522272000097],
              [107.47682956700004, 14.958567131000054],
              [107.47682056800008, 14.962038530000131],
              [107.47794586700012, 14.964581354000115],
              [107.4788045180001, 14.969240240000124],
              [107.47818736100002, 14.972202144000056],
              [107.47670166800002, 14.9743152380001],
              [107.47303713700003, 14.977184977000045],
              [107.46727667700002, 14.982335508000054],
              [107.46448308200006, 14.98503794000001],
              [107.46456566700006, 14.986731536000073],
              [107.46639114700011, 14.987921474000119],
              [107.47152829300008, 14.988103585000088],
              [107.47457513300002, 14.988449777000044],
              [107.47596354000005, 14.990315915000084],
              [107.47543622900012, 14.992177349000089],
              [107.47334056800001, 14.994373595000086],
              [107.47011392900002, 14.996143679000083],
              [107.46740435000001, 14.999947065000095],
              [107.46556058100003, 15.005615331000042],
              [107.46537609800005, 15.009509677000031],
              [107.46736613200004, 15.014425498000071],
              [107.47040047200002, 15.019682573000102],
              [107.47274530300005, 15.022228477000075],
              [107.47761810100008, 15.023933843000046],
              [107.48475715500004, 15.024967222000017],
              [107.49128918700005, 15.024982879000069],
              [107.49782184300003, 15.024744334000049],
              [107.5019996230001, 15.025854820000093],
              [107.50469620900002, 15.027215816000082],
              [107.50930708000004, 15.029343212000066],
              [107.51791396900005, 15.035882409000044],
              [107.52486908300006, 15.041316891000099],
              [107.53017533000008, 15.044292083000064],
              [107.53434704800001, 15.048280728000039],
              [107.53913253700001, 15.050577208000069],
              [107.54270460800007, 15.050246197000126],
              [107.54741206000001, 15.048562820000109],
              [107.55302875500004, 15.045713841000106],
              [107.55730348200007, 15.042674613000083],
              [107.56279857900007, 15.039129786000059],
              [107.56872716000007, 15.036432390000074],
              [107.57404768900003, 15.032886917000086],
              [107.57797220000002, 15.030439275000072],
              [107.58154402600005, 15.030022951000086],
              [107.58441784100006, 15.030197900000084],
              [107.58641880000008, 15.031302498000086],
              [107.58649895600004, 15.034689461000104],
              [107.58752854600006, 15.04231176000008],
              [107.59074098900003, 15.047398163000112],
              [107.59387177200007, 15.049859578000023],
              [107.59744188100005, 15.050459027000061],
              [107.60319429100004, 15.048691705000026],
              [107.60615731400006, 15.047935157000046],
              [107.60998922300004, 15.04828086600005],
              [107.61417086100008, 15.047949781000101],
              [107.6166078360001, 15.048970212000066],
              [107.61938910500001, 15.052192665000062],
              [107.61973305300005, 15.054564045000072],
              [107.61745768500008, 15.06014823700008],
              [107.61492334000008, 15.064429547000111],
              [107.61064938300004, 15.068636608000039],
              [107.60663130100002, 15.074217509000041],
              [107.59824346600003, 15.086648527000078],
              [107.59780094600009, 15.090119206000095],
              [107.60519205300004, 15.097414701000032],
              [107.61380527900012, 15.103780782000074],
              [107.61937826800009, 15.105484210000062],
              [107.62102862100004, 15.108196627000046],
              [107.62111050300007, 15.110990923000054],
              [107.61989002900006, 15.113990890000116],
              [107.61604363600004, 15.120588390000121],
              [107.61211555300011, 15.124222180000045],
              [107.60827430300007, 15.127940716000053],
              [107.60774719400007, 15.130141219000047],
              [107.60922495100004, 15.132006704000032],
              [107.6127079550001, 15.133367789000117],
              [107.61584324400008, 15.134304841000111],
              [107.61945671800001, 15.135835392],
              [107.62254422600003, 15.139058401000048],
              [107.62341075600006, 15.141684754000016],
              [107.62305909800008, 15.143335231000064],
              [107.62218487100004, 15.144815452000058],
              [107.62196367100005, 15.146593168000065],
              [107.62344120500008, 15.148712577000099],
              [107.62417880000001, 15.150407308000078],
              [107.62374053600007, 15.151761294000019],
              [107.62216995700005, 15.152774587000104],
              [107.61894400200003, 15.153530905000066],
              [107.61711281700005, 15.154078002000036],
              [107.61545463200008, 15.155302774000033],
              [107.61414827200008, 15.157437398000082],
              [107.61189544300001, 15.163321272000106],
              [107.61022661200005, 15.167673708000072],
              [107.60930664400004, 15.171140279000015],
              [107.60789069100004, 15.173476741000067],
              [107.60439540900003, 15.177664474000084],
              [107.60089711800003, 15.183303971000049],
              [107.59765046100009, 15.187572724000107],
              [107.59315850000003, 15.191677739000029],
              [107.58883375200004, 15.195137730000051],
              [107.584345102, 15.197468114000044],
              [107.58201661900007, 15.199157383000015],
              [107.58168213900002, 15.200285933000133],
              [107.58165693800007, 15.201784316000031],
              [107.58191675400008, 15.202222829000076],
              [107.58283129300007, 15.202689153000025],
              [107.58499364000002, 15.202628976000108],
              [107.59153724800009, 15.205591178000065],
              [107.59190376700005, 15.205834197000099],
              [107.59205305100005, 15.206150348000056],
              [107.59220578100008, 15.206749026000031],
              [107.59214085900003, 15.207385541000027],
              [107.59132295400003, 15.208878414000091],
              [107.59111176000006, 15.209445964000066],
              [107.59093735500002, 15.210048417000086],
              [107.59122034500007, 15.210892830000054],
              [107.59162495400007, 15.211276685000106],
              [107.5922844400001, 15.211692918000084],
              [107.59283315000002, 15.211969146000024],
              [107.59334423100003, 15.212139853000027],
              [107.59378086300009, 15.212170137000046],
              [107.59414394000004, 15.212130624000062],
              [107.59572950900004, 15.210982081000067],
              [107.59669885700004, 15.209981925000092],
              [107.59981288800003, 15.205570447000037],
              [107.60055237100002, 15.205596094000027],
              [107.60167380700008, 15.206124705000066],
              [107.60491712800004, 15.209201275000071],
              [107.609491887, 15.212871608000087],
              [107.610820749, 15.213262355],
              [107.61494503700001, 15.214297571000122],
              [107.61557213300003, 15.214290273000051],
              [107.61927503700008, 15.215059506000015],
              [107.61964016300008, 15.216409213000047],
              [107.61965188400008, 15.217356841000116],
              [107.61931187100001, 15.218037776000058],
              [107.61842112100003, 15.219266726000045],
              [107.61682049000011, 15.222264085000068],
              [107.61705797000005, 15.224563046000037],
              [107.61791505800008, 15.226245497000066],
              [107.6187654670001, 15.227386436000055],
              [107.61925574400007, 15.227583813000043],
              [107.61974266500005, 15.2275104340001],
              [107.62063762700011, 15.226619920000049],
              [107.62112120500007, 15.226275793000086],
              [107.62181800500007, 15.226267657000035],
              [107.62811864800003, 15.228563394000092],
              [107.63019349200002, 15.230002852],
              [107.63121489900006, 15.230964226000053],
              [107.63128962700003, 15.231369533000104],
              [107.63122668400005, 15.231911846000033],
              [107.63074395700008, 15.232323685],
              [107.62935031700006, 15.232340014000021],
              [107.62781478800011, 15.232154913000072],
              [107.62606518000003, 15.231566107000026],
              [107.62515763699999, 15.231441335000094],
              [107.62456701800006, 15.231583637000055],
              [107.62381059100009, 15.232404856000057],
              [107.62298783000006, 15.233497630000057],
              [107.62217092800005, 15.235064220000092],
              [107.62167650900008, 15.236673975000057],
              [107.62169031100007, 15.237787626000097],
              [107.62208442300009, 15.239330168],
              [107.6219643590001, 15.24088861900008],
              [107.61890784600007, 15.250525215],
              [107.61885702400004, 15.252784483000069],
              [107.61841470100003, 15.260025027000077],
              [107.61861505800009, 15.263468114000121],
              [107.61864781900005, 15.266109213000066],
              [107.61853955700006, 15.266914411000075],
              [107.61834868100007, 15.267414315000048],
              [107.61612556100008, 15.27066070100007],
              [107.61482634900005, 15.278978498000038],
              [107.61429876500009, 15.284114515000089],
              [107.61382194300008, 15.288495576000026],
              [107.61894178000003, 15.302754959000085],
              [107.62155222700002, 15.309741336000025],
              [107.6223744760001, 15.311291018000082],
              [107.62341639700007, 15.312058456000075],
              [107.62636734700007, 15.311851406000086],
              [107.62717896000008, 15.311841862000096],
              [107.62790092200004, 15.311833370000015],
              [107.62957730800011, 15.312084435000093],
              [107.63154606500011, 15.313415186000066],
              [107.63226009800003, 15.314760700000106],
              [107.63285673600011, 15.317867718000054],
              [107.6328770760001, 15.319492198000082],
              [107.63261178300009, 15.320578476000078],
              [107.63162658900011, 15.322056326000054],
              [107.627707167, 15.326435084000066],
              [107.62636504600006, 15.327728420000051],
              [107.62412463800005, 15.329933496000068],
              [107.62202499400006, 15.331422505000079],
              [107.61874312800003, 15.332250099000076],
              [107.61580144099999, 15.332904136000101],
              [107.61272457500004, 15.333644943000111],
              [107.6102755750001, 15.334997502000112],
              [107.60833952900005, 15.336704607000049],
              [107.607654111, 15.337660399000084],
              [107.60697738000006, 15.341399650000119],
              [107.60647143000003, 15.343496233000041],
              [107.60572552200004, 15.34741600500009],
              [107.60506549700006, 15.352745611000083],
              [107.60493795100007, 15.357560314000105],
              [107.604754595, 15.36071261800007],
              [107.60444963400008, 15.365641628000047],
              [107.60416703800006, 15.367151010000056],
              [107.60314165400004, 15.370052911000085],
              [107.60147231500011, 15.373341678000061],
              [107.59694730000008, 15.379622744000086],
              [107.59629537200004, 15.382820038000132],
              [107.59751266900005, 15.385844351000076],
              [107.60061463500003, 15.388516026000064],
              [107.60709602400001, 15.393720538000098],
              [107.61018301000009, 15.395173666000071],
              [107.61145855100001, 15.396783418000036],
              [107.613635249, 15.403527550000094],
              [107.61506390200007, 15.406218614000087],
              [107.6170522150001, 15.409038475000079],
              [107.6193161680001, 15.411584272000043],
              [107.62147100700005, 15.413935014000034],
              [107.62194886000006, 15.416072030000104],
              [107.62794917200003, 15.41792833400004],
              [107.63080295800009, 15.418394950000012],
              [107.63450565200003, 15.417170167000014],
              [107.63656973500009, 15.415839949000086],
              [107.63816115500006, 15.412764795000021],
              [107.64044220600005, 15.408305761000028],
              [107.64302477800004, 15.405285085000029],
              [107.64763961800004, 15.402035324000083],
              [107.65180325200002, 15.398881964000031],
              [107.65391212100008, 15.396114160000083],
              [107.65587130500009, 15.392957146000084],
              [107.6578305100001, 15.389751495000057],
              [107.65917574600012, 15.387688573000078],
              [107.65948818500003, 15.387080150000097],
              [107.65965083599998, 15.386142493000053],
              [107.65984229400001, 15.385038761000123],
              [107.65959665300004, 15.381734805000045],
              [107.65848936400008, 15.378289464000082],
              [107.65828615800005, 15.375048122000033],
              [107.659057348, 15.371538244000059],
              [107.65996764900009, 15.368028596000014],
              [107.66094815500003, 15.364113924000032],
              [107.66123379900004, 15.35972551400002],
              [107.66151840000006, 15.355944781000106],
              [107.66263511699999, 15.353718353000099],
              [107.66493511600005, 15.351021120000039],
              [107.66794489500003, 15.347350447000084],
              [107.67320318500008, 15.344428955000032],
              [107.67633673400009, 15.342046660000095],
              [107.67824175200006, 15.339011448000049],
              [107.68026141600009, 15.334131845000075],
              [107.68339882300008, 15.329145376000051],
              [107.68843858000007, 15.322534115000062],
              [107.69101496600007, 15.318740249000127],
              [107.69314107800004, 15.317007275],
              [107.6975052830001, 15.313324399000104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 34, code: "AD01", ten_tinh: "Lai Châu" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.3330318870001, 22.806704420000031],
              [103.33398632300003, 22.806646350000022],
              [103.33786834000006, 22.80716325300007],
              [103.34014400100006, 22.807050867],
              [103.34256966600003, 22.806667778000111],
              [103.34419128100009, 22.806038121000057],
              [103.34589322800004, 22.804830828],
              [103.34714559300001, 22.804401480000088],
              [103.35041926800007, 22.803652768000049],
              [103.35258279000003, 22.803709169000093],
              [103.35481559100003, 22.804106518000111],
              [103.35617222900001, 22.804154549000089],
              [103.3572737580001, 22.804063816000038],
              [103.36124873200002, 22.802947815000074],
              [103.365481184, 22.801766282000102],
              [103.3694584510001, 22.800445922],
              [103.370897719, 22.799712028000037],
              [103.37452346100005, 22.797094910000034],
              [103.37586353400009, 22.79544123400008],
              [103.37731641800005, 22.793550495000048],
              [103.37828525700009, 22.792267316000014],
              [103.37962357100007, 22.790056034000102],
              [103.38132948400008, 22.788016607000117],
              [103.38264169000003, 22.786997248000063],
              [103.38414439100002, 22.785856529000093],
              [103.3858166540001, 22.784916983000066],
              [103.38705254800004, 22.784116695000108],
              [103.38846265000009, 22.783399432000095],
              [103.39092290700009, 22.782774037000038],
              [103.39315355600009, 22.782473658000058],
              [103.39373067000005, 22.78239593500011],
              [103.40368094700008, 22.781297297000066],
              [103.40760087200006, 22.78142743300009],
              [103.41064701500004, 22.780821096000103],
              [103.41281616500001, 22.779751187000038],
              [103.41440663300007, 22.777856857000067],
              [103.41512219900002, 22.775317104000067],
              [103.4143062200001, 22.769578578000079],
              [103.41483201700001, 22.766491190000067],
              [103.41745690800012, 22.762830287000035],
              [103.42444495200002, 22.762002801000044],
              [103.43168496600006, 22.75871603600006],
              [103.43598524200007, 22.756354530000067],
              [103.43869122000005, 22.754446751000046],
              [103.43958876900007, 22.753091129000055],
              [103.44030470600006, 22.747526416000063],
              [103.43990723600004, 22.743779413000098],
              [103.43890464300004, 22.741659875000053],
              [103.43453468500005, 22.739030963000047],
              [103.43066646900009, 22.737427716000091],
              [103.42760411700002, 22.735900381000107],
              [103.42637325200005, 22.734527133000078],
              [103.42594268200004, 22.733706224000017],
              [103.42595437100003, 22.732685493000112],
              [103.42729822400003, 22.730588985000018],
              [103.42878401600004, 22.728902180000077],
              [103.4289438640001, 22.727746799000045],
              [103.428621596, 22.723864413000072],
              [103.42725587100007, 22.721469012000028],
              [103.42624426800003, 22.720165985000037],
              [103.42589485100008, 22.718665308000041],
              [103.42612957400004, 22.71737453900009],
              [103.42754113100004, 22.715755070000029],
              [103.42954144800008, 22.713937207000079],
              [103.43051386800008, 22.712245345000071],
              [103.43063888300003, 22.710919528000062],
              [103.43024094800005, 22.707240563000042],
              [103.42983837400004, 22.703969900000054],
              [103.43144427200005, 22.701482068000089],
              [103.43764008100004, 22.697351695000023],
              [103.44258057000008, 22.694075938000054],
              [103.44920332000004, 22.690002935000031],
              [103.45259942400001, 22.687857981000015],
              [103.4571970850001, 22.683138346000042],
              [103.45855137300008, 22.680020753000107],
              [103.45770840400004, 22.676745865000036],
              [103.45602868700007, 22.66965170600006],
              [103.45451046600004, 22.664773684000039],
              [103.45417476900006, 22.662049852000088],
              [103.45554403200002, 22.659682532000055],
              [103.45850540100008, 22.657517852000097],
              [103.46306498900006, 22.654811362000068],
              [103.46377434700004, 22.653685690000103],
              [103.46370435500002, 22.652148099000065],
              [103.46249276800005, 22.649547098000063],
              [103.46274822300005, 22.648529326000052],
              [103.4663127310001, 22.646296301000035],
              [103.47156770500004, 22.64312204400003],
              [103.47378092600012, 22.641781974000082],
              [103.47527341400006, 22.639346081000063],
              [103.47623071200005, 22.632277129000045],
              [103.47817462500004, 22.62875656000007],
              [103.48189170100009, 22.623891585000067],
              [103.48617372300001, 22.620937471000069],
              [103.49735830300003, 22.616550736000107],
              [103.50103200700006, 22.615614773000026],
              [103.50365900600008, 22.61426412100009],
              [103.50508184700004, 22.611527039000023],
              [103.50476516500004, 22.609133442000129],
              [103.50460578000008, 22.607235301000017],
              [103.50486265300002, 22.606476514000029],
              [103.50602128000006, 22.604189514000069],
              [103.50911917300004, 22.601684757000037],
              [103.51385617900003, 22.599982952000097],
              [103.52414655200005, 22.597108795000054],
              [103.52530537200001, 22.596775527000098],
              [103.52704509900008, 22.592961848000037],
              [103.52773493700003, 22.590843189000061],
              [103.53071692000003, 22.58479796200001],
              [103.53190256000008, 22.585145816000107],
              [103.53751862400006, 22.58728546600004],
              [103.54335065800005, 22.589174842000048],
              [103.54508954900004, 22.589658694000022],
              [103.54648609900005, 22.58952731800008],
              [103.54971378400003, 22.588476276000065],
              [103.55473484400008, 22.586397090000077],
              [103.56008149400006, 22.582808347000061],
              [103.56203653700008, 22.581133498000035],
              [103.5630909810001, 22.580350774000024],
              [103.56402432300006, 22.580035040000013],
              [103.56572853200002, 22.580122286000034],
              [103.56723936900006, 22.580171773000046],
              [103.56894954000008, 22.579682907000098],
              [103.57000207000003, 22.579080152000053],
              [103.57035564300007, 22.578615200000037],
              [103.5702829750001, 22.578146457000067],
              [103.56898895100005, 22.575866447000067],
              [103.56688164800005, 22.573543167000039],
              [103.56550948300001, 22.571334449000076],
              [103.56459335400007, 22.569993987000103],
              [103.56440708900001, 22.569272173000066],
              [103.56441193400006, 22.568804110000059],
              [103.56464926000007, 22.568338129000054],
              [103.5664185040001, 22.56586938700007],
              [103.56889846800003, 22.562110665000112],
              [103.57420517499999, 22.554740204000012],
              [103.5767721710001, 22.550009901000045],
              [103.57789454700007, 22.546347019000024],
              [103.57920352100011, 22.539589094000064],
              [103.580409833, 22.53527878200012],
              [103.5815187370001, 22.532912039000053],
              [103.58245869800005, 22.531912091],
              [103.58471270900003, 22.531211770000056],
              [103.58781450900008, 22.530950956000062],
              [103.59077156600006, 22.529680586000019],
              [103.59264546500003, 22.528256664000068],
              [103.59366423300004, 22.527113303000057],
              [103.59352096400008, 22.52595978500009],
              [103.58947727, 22.519803021000051],
              [103.5870320600001, 22.516468820000043],
              [103.58532665900003, 22.514671449000012],
              [103.58387540800004, 22.512642236000069],
              [103.58319037400003, 22.511447939000014],
              [103.58320064500008, 22.510439795000089],
              [103.58337879500007, 22.510063273000114],
              [103.58451331800006, 22.508956999000063],
              [103.58537416200005, 22.508100371000062],
              [103.58565113500006, 22.507526669000107],
              [103.5856580870001, 22.506842573000085],
              [103.58477647899998, 22.505934624000098],
              [103.58317078900009, 22.503868038000086],
              [103.58291254300003, 22.502605483000067],
              [103.58189149700011, 22.500183947],
              [103.57955414800007, 22.497714809000065],
              [103.57802128800007, 22.496116919000109],
              [103.57737473900005, 22.494958951000044],
              [103.57565530200007, 22.492675249000094],
              [103.57467177700005, 22.491343181000012],
              [103.57394191100005, 22.490760585000125],
              [103.57224507100008, 22.490061417],
              [103.56893182400003, 22.488375651000055],
              [103.56646760900007, 22.486985434000047],
              [103.56500727800002, 22.485892205000098],
              [103.56377892500009, 22.484837029000104],
              [103.56328335100004, 22.484076442000053],
              [103.56191278400006, 22.481795713000082],
              [103.56119046000006, 22.480492976000029],
              [103.56061523100007, 22.479947719000037],
              [103.55930467700009, 22.479359885000072],
              [103.55883487100003, 22.478979961000078],
              [103.55799897800006, 22.478303980000099],
              [103.55543496100009, 22.475364378000059],
              [103.55398530800005, 22.473262910000088],
              [103.55333830800008, 22.472176867000115],
              [103.55330930400007, 22.471240397000081],
              [103.55355246500002, 22.4701983510001],
              [103.55491046000003, 22.468068052000056],
              [103.55538739700006, 22.466884053000058],
              [103.55609588100006, 22.465774155000062],
              [103.55719337100001, 22.464487680000019],
              [103.55875618800009, 22.463133350000049],
              [103.56191748100008, 22.460713005000073],
              [103.56418228700008, 22.458860757000117],
              [103.56551640000006, 22.457144240000027],
              [103.56627122700007, 22.455278527000111],
              [103.56737780300006, 22.453091853000018],
              [103.56933751500003, 22.450768715],
              [103.57089858400005, 22.449558256000032],
              [103.57299571000001, 22.448928670000079],
              [103.57408334300004, 22.448578200000036],
              [103.57521593900005, 22.447615976000087],
              [103.57615826900012, 22.446327991000103],
              [103.57695675300002, 22.443958475000066],
              [103.57783226200007, 22.441625654000049],
              [103.57897175000002, 22.439979303000079],
              [103.58018165500003, 22.439017722000095],
              [103.58264688, 22.438337252000018],
              [103.58548474900006, 22.437173858000037],
              [103.58603070500001, 22.436782548000075],
              [103.58638492600002, 22.436209521000094],
              [103.58635497100002, 22.435345055000063],
              [103.58581410900004, 22.431415377000086],
              [103.58578343000008, 22.430622940000063],
              [103.58606022000006, 22.430049222000022],
              [103.58706917100005, 22.429805997000059],
              [103.59210551300004, 22.429417880000102],
              [103.59544016899999, 22.428870785000043],
              [103.59788741700005, 22.428027847000109],
              [103.59995335200001, 22.426605417000097],
              [103.60101033800008, 22.425426294000061],
              [103.60160896800005, 22.423631042000025],
              [103.60129344000005, 22.420351518000103],
              [103.60091060000008, 22.416063160000107],
              [103.60102841800006, 22.412031193000104],
              [103.60337651100011, 22.409458865000076],
              [103.6127122450001, 22.404786091000069],
              [103.61738629800003, 22.401801386000031],
              [103.62210702599999, 22.397988722000051],
              [103.62474850400007, 22.39700296100002],
              [103.62729982200004, 22.397312708000072],
              [103.63925245400002, 22.401950924000054],
              [103.64287704500006, 22.403349744000067],
              [103.64542713600012, 22.403803248000109],
              [103.64975751300004, 22.404271592000043],
              [103.65726977000006, 22.403902052000021],
              [103.66051863800001, 22.404145061000058],
              [103.66708557200008, 22.405495697000077],
              [103.67048930100006, 22.405739780000111],
              [103.69398946700007, 22.409244277000084],
              [103.69747280400007, 22.409272390000098],
              [103.705288959, 22.409551256000078],
              [103.7070892080001, 22.409511658],
              [103.70890929000007, 22.409418176000081],
              [103.70995129500004, 22.409750584000051],
              [103.71079514000004, 22.410585550000036],
              [103.71178584800003, 22.412285923000042],
              [103.71355001200007, 22.414064468000028],
              [103.71489807700006, 22.414795393000105],
              [103.71675197000006, 22.415242251000045],
              [103.71775831700002, 22.415250241000081],
              [103.71868923300003, 22.415041556000048],
              [103.71978057600009, 22.414221982000093],
              [103.72083222300007, 22.413510103000078],
              [103.72168571300008, 22.41330080400008],
              [103.72241913500001, 22.413522657000051],
              [103.72376064400007, 22.414973675000027],
              [103.72529573, 22.416426187000035],
              [103.72669419500005, 22.417057713000062],
              [103.72694069400002, 22.417169028000039],
              [103.72792118700006, 22.417167086000056],
              [103.72916634700006, 22.41645667700007],
              [103.73150277700002, 22.414926611000041],
              [103.73301555900004, 22.414578381000091],
              [103.73588173000005, 22.414384774000062],
              [103.73735710400008, 22.413892170000068],
              [103.74152066300005, 22.411475954000082],
              [103.74463841800009, 22.409123530000038],
              [103.74667299900003, 22.406690615000088],
              [103.74874717800006, 22.404149951000107],
              [103.75085741600004, 22.401897612000084],
              [103.75432631600009, 22.399187579000035],
              [103.75857358200005, 22.395979301000054],
              [103.76189026000003, 22.392943845000076],
              [103.76298977900007, 22.391151731000036],
              [103.76350815400005, 22.389427184000077],
              [103.76365935300004, 22.387645850000034],
              [103.76386494600008, 22.386279017],
              [103.76469636800005, 22.384160736000069],
              [103.76599817400002, 22.381361847000036],
              [103.76620593900006, 22.379742954000022],
              [103.76613807600005, 22.378662117000125],
              [103.76518781800011, 22.376710294000098],
              [103.76381067600003, 22.37489924200009],
              [103.76335492000007, 22.373923466000086],
              [103.76336605900011, 22.372663186000082],
              [103.76391859500008, 22.371443052000117],
              [103.76494656400004, 22.368966154000077],
              [103.76516479100007, 22.366158980000073],
              [103.76506176900008, 22.360288453000088],
              [103.76470121100009, 22.357296808000093],
              [103.76467236400001, 22.35618025600008],
              [103.76479446800008, 22.355496990000034],
              [103.765261599, 22.355176445000069],
              [103.76769873900012, 22.355231026000055],
              [103.76913153000001, 22.355097880000052],
              [103.77138374800002, 22.354178705000024],
              [103.77398981200008, 22.352613981000054],
              [103.77741583000005, 22.350191140000049],
              [103.77850883600007, 22.349083050000026],
              [103.778863304, 22.348365506000079],
              [103.77882869300007, 22.347897095000043],
              [103.77687603200006, 22.345541657000112],
              [103.77591984900009, 22.344274051000035],
              [103.77573113800001, 22.343732458000076],
              [103.77573648900001, 22.343120320000054],
              [103.77741351200004, 22.341584512000075],
              [103.77967611500002, 22.339440907],
              [103.77998967300003, 22.338975136],
              [103.77999280600002, 22.338615045000054],
              [103.77972482100006, 22.338288927000072],
              [103.77663722500003, 22.337437399000045],
              [103.77231428700001, 22.3362883570001],
              [103.77042945800011, 22.33504970200002],
              [103.76908657400003, 22.333779121000049],
              [103.768358527, 22.332981357000037],
              [103.76832364900005, 22.33254895300005],
              [103.76832807000005, 22.332044841000084],
              [103.76977483600004, 22.330291294000034],
              [103.77087341400008, 22.328535089000034],
              [103.77103886700009, 22.327311977000086],
              [103.77058888500007, 22.325688082000013],
              [103.76895588900004, 22.32221864200001],
              [103.76763484499999, 22.320678142000091],
              [103.76502803500004, 22.31799349],
              [103.76418914000008, 22.316618688000034],
              [103.76408038500006, 22.315789606000088],
              [103.7641260450001, 22.314997717000026],
              [103.76432671900001, 22.314171002000101],
              [103.76542396500011, 22.31255885900012],
              [103.76818401700004, 22.310995369000075],
              [103.77035973800011, 22.309895539000109],
              [103.77168578000006, 22.308645196000043],
              [103.77271332200007, 22.306924778000067],
              [103.77286340100005, 22.306673501000063],
              [103.77338631500007, 22.30437275000012],
              [103.7740123670001, 22.303549229000019],
              [103.77676742300005, 22.302525707000065],
              [103.77808269900007, 22.302499606000126],
              [103.78252133600006, 22.303577306000122],
              [103.78484187600004, 22.30359469100005],
              [103.78810526400005, 22.301926592000051],
              [103.79449253400007, 22.296788553000013],
              [103.80314688400009, 22.288786082000023],
              [103.80750869600003, 22.285217029000037],
              [103.81708118900008, 22.27822880800003],
              [103.82113719800003, 22.274152991000037],
              [103.82527512600009, 22.269501451000018],
              [103.82629428600004, 22.267852290000079],
              [103.82638663000004, 22.2660523690001],
              [103.82641185600009, 22.263027567000023],
              [103.82681291600005, 22.26130190300006],
              [103.82900091400008, 22.258580792000068],
              [103.83096162700004, 22.255281833000048],
              [103.83260936400004, 22.252412737000071],
              [103.83440094900004, 22.250841073000061],
              [103.83696573000006, 22.249274922000062],
              [103.83836705200011, 22.248132568000045],
              [103.84071992800008, 22.244116023000068],
              [103.84135151000004, 22.242536013000077],
              [103.84216022600006, 22.238220332000047],
              [103.84217502400006, 22.23641984400011],
              [103.84065416800007, 22.23331197],
              [103.83990769200003, 22.230065569000068],
              [103.84024419200007, 22.226754869000054],
              [103.8411837670001, 22.225321089000083],
              [103.84475832300009, 22.223113767000058],
              [103.84778396300007, 22.221838782000077],
              [103.84957674100008, 22.220050864000115],
              [103.85098705400006, 22.217756058000049],
              [103.85405289900007, 22.21151155700003],
              [103.85469702700007, 22.208347016000026],
              [103.85493945000005, 22.207052278000013],
              [103.85626347800009, 22.205837183000106],
              [103.85936653300007, 22.204490483000058],
              [103.86299960300002, 22.204515900000033],
              [103.86563416400004, 22.203742007000038],
              [103.86773220800009, 22.202388145000015],
              [103.87007017500008, 22.200027563000013],
              [103.8710138660001, 22.198017419000045],
              [103.87059386400007, 22.192540618000045],
              [103.86991954400006, 22.189871031000052],
              [103.86869097100001, 22.188854151000022],
              [103.86623095300004, 22.18718047],
              [103.86508432900011, 22.185587944000048],
              [103.86401675700006, 22.18377988200011],
              [103.86379357900007, 22.182697956000119],
              [103.86387897300003, 22.181690199000045],
              [103.86722365900002, 22.179048572000056],
              [103.8704846200001, 22.177198572000023],
              [103.87365731500006, 22.176716344000091],
              [103.87945479700004, 22.176612269000096],
              [103.88456639300003, 22.17527885900008],
              [103.88681612400009, 22.174213872000067],
              [103.88923454700009, 22.17134935500011],
              [103.89314206300001, 22.165829976000047],
              [103.89549923700008, 22.160876190000067],
              [103.89760590200004, 22.158297526000048],
              [103.89977857300005, 22.157159787000055],
              [103.90240926100002, 22.156745374000053],
              [103.90388026300005, 22.156395138000029],
              [103.90629635500002, 22.153746408000075],
              [103.91370692200007, 22.144720673000059],
              [103.91556852600009, 22.143796714000082],
              [103.91874584700008, 22.142593356000084],
              [103.92044681200005, 22.142460571000107],
              [103.92291607400007, 22.142909050000092],
              [103.92500174800007, 22.142994831000081],
              [103.93412473000004, 22.142334425000065],
              [103.93536588200007, 22.141694286000032],
              [103.93607102900006, 22.14040241],
              [103.93704254500005, 22.134502576000067],
              [103.93778705300011, 22.127953009000031],
              [103.93757200699999, 22.125718796000093],
              [103.93666273499998, 22.123335998000115],
              [103.93659521000008, 22.1220390760001],
              [103.936606016, 22.120598614000109],
              [103.93716246300004, 22.118513470000067],
              [103.93966906200004, 22.113920053000058],
              [103.94139495300001, 22.11040193700002],
              [103.94242390800007, 22.107095370000081],
              [103.94245290200007, 22.10320611500007],
              [103.94224800799999, 22.09960344200006],
              [103.94065437200003, 22.095775693000071],
              [103.93768129100006, 22.090498437000058],
              [103.93777631100002, 22.088122175000088],
              [103.93798096600008, 22.086173769000084],
              [103.93802580000005, 22.085746908000111],
              [103.93927717400004, 22.083666247000089],
              [103.94091308700003, 22.081804157000114],
              [103.94443038900009, 22.076208781000069],
              [103.945609172, 22.073479359000103],
              [103.94640764600008, 22.069955186000101],
              [103.94742174700005, 22.068593199000119],
              [103.95014598700004, 22.065729611000059],
              [103.95140338400007, 22.062784565000101],
              [103.95260897100007, 22.056381845000047],
              [103.953517225, 22.048392609000089],
              [103.95363945900007, 22.042271052000025],
              [103.95374737700004, 22.038094133000108],
              [103.95414825200008, 22.036079929000074],
              [103.95385529, 22.0339172340001],
              [103.95264325700008, 22.030740266000045],
              [103.95165759100006, 22.028285019000023],
              [103.95166607900005, 22.027132637000065],
              [103.95392882500005, 22.023905847000066],
              [103.95705721500008, 22.020970480000109],
              [103.96206582100007, 22.016376995000094],
              [103.96363670700001, 22.014130492000028],
              [103.96368413200003, 22.011856350000066],
              [103.9629250040001, 22.009283202000063],
              [103.95995334400007, 22.005627165000071],
              [103.95565760300008, 22.00213658700001],
              [103.95446466500006, 22.000882590000082],
              [103.95447655200006, 21.999832636000072],
              [103.96604315000008, 21.989178634000069],
              [103.96992545900005, 21.984327626000066],
              [103.97258015700005, 21.976297150000065],
              [103.98290270200008, 21.967940781000017],
              [103.98072923000009, 21.965565882000071],
              [103.97590106100002, 21.96137778500011],
              [103.96805780600006, 21.958942101000019],
              [103.96662646200011, 21.9564268560001],
              [103.96220301000004, 21.94865314700003],
              [103.96112015500006, 21.939875432000022],
              [103.95723434200008, 21.935182488000038],
              [103.9510440820001, 21.928535955000058],
              [103.94185364200004, 21.919401550000117],
              [103.93654454300007, 21.920486331000077],
              [103.93311942800008, 21.922265802000048],
              [103.92956727100008, 21.924772763000085],
              [103.92115786000008, 21.93163933000006],
              [103.91704068200004, 21.932675971000037],
              [103.91014112500008, 21.926189005000118],
              [103.90817116000008, 21.924275977000015],
              [103.89646591300004, 21.921488428000124],
              [103.89035696000005, 21.921653708000029],
              [103.88671651800011, 21.912515034000087],
              [103.88467632700005, 21.906916221000117],
              [103.885867101, 21.894668891],
              [103.88679253000001, 21.880886983000011],
              [103.88720738000005, 21.875966867000059],
              [103.88803610300005, 21.866137323000068],
              [103.89830014700001, 21.855968402000059],
              [103.9040225680001, 21.851103140000113],
              [103.91084035100003, 21.845458337000068],
              [103.91836828400002, 21.837879668000056],
              [103.91877989900006, 21.834237254000044],
              [103.91570074500001, 21.832025474000091],
              [103.91626250100005, 21.830089123000015],
              [103.923922179, 21.826823009000051],
              [103.93370095600007, 21.820084376000089],
              [103.93703080000006, 21.813887953000055],
              [103.94764094500007, 21.802922605000049],
              [103.94985031600001, 21.80045988100003],
              [103.95007893900001, 21.796640010000075],
              [103.94953908700009, 21.793988090000127],
              [103.9492064030001, 21.792104755000082],
              [103.94922426200007, 21.791250084000069],
              [103.94923690100005, 21.790645205000111],
              [103.94969768900008, 21.789958311000035],
              [103.95364240100005, 21.788118112000099],
              [103.957559793, 21.778663799000064],
              [103.9610122870001, 21.773650822000064],
              [103.96267595900009, 21.772359963000021],
              [103.96444030700006, 21.771592399000014],
              [103.96497970400002, 21.770698284000019],
              [103.96499850800006, 21.769794715000046],
              [103.96258950700005, 21.760433636000087],
              [103.96167775600001, 21.757774807000096],
              [103.96097345900009, 21.757657707000085],
              [103.95702630700001, 21.757864060000109],
              [103.95004179300004, 21.757458727000056],
              [103.95188387300004, 21.747618461000059],
              [103.95147508600006, 21.742256813000083],
              [103.95074166000003, 21.741756705000093],
              [103.94591874500004, 21.744728305000116],
              [103.93672873400003, 21.744873332000033],
              [103.92939481500011, 21.743417918000048],
              [103.92187886300005, 21.740011913000053],
              [103.916426132, 21.737547523000075],
              [103.91473495100004, 21.736612459000071],
              [103.91422763499999, 21.735977298000094],
              [103.91560850700003, 21.733986130000105],
              [103.91757128900007, 21.729085074000039],
              [103.91759177299998, 21.728111930000082],
              [103.9167419720001, 21.726079724000066],
              [103.91535430800005, 21.723098850000078],
              [103.91463714400004, 21.721833974000049],
              [103.91108040400009, 21.717665755000048],
              [103.91065042800004, 21.716892914000049],
              [103.91776653400005, 21.710973880000047],
              [103.91967200100007, 21.708783620000105],
              [103.92021581600002, 21.707680970000084],
              [103.92095363700005, 21.704426118000072],
              [103.92011913400002, 21.701721143000086],
              [103.9145875880001, 21.697810269000058],
              [103.91283980800009, 21.697058083000087],
              [103.90977165000007, 21.696490609000115],
              [103.90526532200008, 21.695803674000032],
              [103.90382420400003, 21.695823558000079],
              [103.90262256100007, 21.696265929000091],
              [103.90104778200008, 21.697909216000077],
              [103.90009245400009, 21.698449024000062],
              [103.89833273600006, 21.699438516000043],
              [103.89585831900004, 21.700159293000084],
              [103.88810082900008, 21.702849480000097],
              [103.88095261800007, 21.70959210000003],
              [103.88008301800005, 21.709599196000084],
              [103.87989486300003, 21.706762074],
              [103.87974910800006, 21.698420972000051],
              [103.87976741300005, 21.696085183000058],
              [103.87982172400011, 21.689154697000035],
              [103.87742695800003, 21.687298523000017],
              [103.87451099100008, 21.686594013000082],
              [103.87307092600003, 21.686567258000089],
              [103.87077680500002, 21.686989160000088],
              [103.86897133600012, 21.687791774000061],
              [103.8652258210001, 21.689882217000012],
              [103.85887373000006, 21.694223498000028],
              [103.85404087300005, 21.692553889000088],
              [103.85285204200008, 21.692392327000043],
              [103.85135929600003, 21.69250381500003],
              [103.85065414200004, 21.692955183000073],
              [103.84978610000007, 21.69405386900003],
              [103.8488052980001, 21.696938931000084],
              [103.848037506, 21.70031572300006],
              [103.84665391800009, 21.702287363000039],
              [103.84312996000001, 21.702128533000042],
              [103.84074124000009, 21.702316076000017],
              [103.83963375200001, 21.702992147000089],
              [103.83856994400001, 21.703947741000015],
              [103.8376585190001, 21.705904935000042],
              [103.83666709500005, 21.70811611200012],
              [103.83648820700007, 21.709506360000127],
              [103.8363747500001, 21.712477215],
              [103.83578000800001, 21.715880340000027],
              [103.83519680500007, 21.717588146000111],
              [103.83347208000001, 21.721550688000058],
              [103.83280963200009, 21.725788610000052],
              [103.83121681100002, 21.730543220000072],
              [103.83269545300006, 21.735727025000081],
              [103.83497608400002, 21.738278137000044],
              [103.83107037100007, 21.744289874000074],
              [103.82772320900006, 21.738188492000084],
              [103.82547135699998, 21.73661331700005],
              [103.82354422900005, 21.736112594000097],
              [103.82235214700009, 21.736090180000112],
              [103.82062903600007, 21.73649905300001],
              [103.8177191500001, 21.737791367000092],
              [103.81254497200008, 21.740388034000091],
              [103.8113965200001, 21.740645086000043],
              [103.81071356300008, 21.74069872900013],
              [103.8087922940001, 21.740524067000067],
              [103.80746083600006, 21.740403014],
              [103.80327743600006, 21.74063650100004],
              [103.80191563000008, 21.741325473000117],
              [103.80118102800006, 21.743123155000085],
              [103.79890413600005, 21.748445101000065],
              [103.79827386200003, 21.750012471000076],
              [103.79509846800002, 21.754365109000062],
              [103.79369985500003, 21.756986235000042],
              [103.79081078500009, 21.758441123000068],
              [103.78943426600006, 21.760040756000087],
              [103.78822112200004, 21.763269167000132],
              [103.78759777400008, 21.764511465000069],
              [103.78709292600004, 21.764873479000087],
              [103.78336816200006, 21.764756385000084],
              [103.78132939100003, 21.764810584000124],
              [103.78057615200007, 21.765167867000024],
              [103.78020719300008, 21.766136283000037],
              [103.78006742200006, 21.767991554000062],
              [103.78014353100005, 21.769061312000112],
              [103.78044378700012, 21.771250067000025],
              [103.78036379100007, 21.772641990000082],
              [103.77994312400008, 21.77370229600006],
              [103.77923245400008, 21.774385514000102],
              [103.77892258200002, 21.774683996000107],
              [103.77867716500006, 21.775013310000091],
              [103.77776542500003, 21.777222510000094],
              [103.77721512400005, 21.779243739],
              [103.77695052800001, 21.779892630000042],
              [103.77671887100003, 21.780259802000046],
              [103.77606442400005, 21.780642168000064],
              [103.77491822600008, 21.780782945000077],
              [103.77283161600009, 21.780743265000076],
              [103.77188819000001, 21.780702088000048],
              [103.77099288500001, 21.780516983],
              [103.76986834700006, 21.780189439000083],
              [103.76820857000003, 21.779823866000037],
              [103.76752450000001, 21.779783007000027],
              [103.76647894100009, 21.779930078000028],
              [103.76514475500002, 21.780538856000042],
              [103.76340286900005, 21.781213972000032],
              [103.76227489400006, 21.781656933000093],
              [103.76013353500008, 21.781848328000116],
              [103.75829939000002, 21.781627541000056],
              [103.75775797600008, 21.781384968000062],
              [103.75646384700011, 21.779200466000056],
              [103.7561211650001, 21.778961683000105],
              [103.75557518700003, 21.778928034000074],
              [103.754622614, 21.779304636000063],
              [103.75262820100001, 21.780729615000013],
              [103.75180786800007, 21.781875119000027],
              [103.75168099400005, 21.783695728000026],
              [103.75164233200006, 21.785459975000073],
              [103.75136423800005, 21.789077494000075],
              [103.75191159400005, 21.791317384000038],
              [103.75254307800007, 21.794255560000053],
              [103.754782608, 21.79980216900006],
              [103.75692090800001, 21.804301750000029],
              [103.75741259300001, 21.806819164000046],
              [103.75723101400008, 21.808301929000073],
              [103.75645316800004, 21.809773317000044],
              [103.75504156000002, 21.810675257000064],
              [103.75303468800007, 21.811519365],
              [103.75144261200006, 21.811581829000112],
              [103.74936586600009, 21.811077675000057],
              [103.74541625600006, 21.809841001000052],
              [103.74402600400005, 21.809767937000046],
              [103.74142283700004, 21.810600534000102],
              [103.73679568199999, 21.810790501],
              [103.73331953400006, 21.81063091500004],
              [103.72968431300011, 21.809794768000046],
              [103.72450597000001, 21.807930315000057],
              [103.72262910900007, 21.807383319000095],
              [103.72203080500003, 21.807464694000075],
              [103.72006223700004, 21.808820143000048],
              [103.71586837100006, 21.811897587000125],
              [103.71561171400005, 21.812264204000087],
              [103.71385690300006, 21.81406492900004],
              [103.71317500900005, 21.815677324000049],
              [103.71180809500001, 21.819041387000127],
              [103.7112327450001, 21.820330717000047],
              [103.70950830900006, 21.82187652700005],
              [103.70799804500007, 21.822729801000037],
              [103.70574855300002, 21.823290125000128],
              [103.7031806940001, 21.823635281000087],
              [103.70014229300006, 21.823901638],
              [103.69579231700003, 21.825024989000049],
              [103.69483063500003, 21.825795905000064],
              [103.69372830500004, 21.828421819000084],
              [103.69272326500001, 21.831142494000041],
              [103.6935776980001, 21.835199574000086],
              [103.69449217, 21.838793361000072],
              [103.69590137900008, 21.84026035100004],
              [103.69781581400011, 21.841365570000093],
              [103.70042602100008, 21.842484236000104],
              [103.70284261700002, 21.843366905000039],
              [103.70367099100008, 21.844125987000048],
              [103.70516936600006, 21.849426051000087],
              [103.70583665900003, 21.852968469000089],
              [103.70757680300004, 21.857460379000045],
              [103.711041499, 21.861544334000094],
              [103.71496978400005, 21.866031867000046],
              [103.71925308200007, 21.871338816000026],
              [103.72005190300007, 21.873443954000038],
              [103.72046335200011, 21.875077231000084],
              [103.72101519100002, 21.879360211000076],
              [103.72088810100007, 21.880611610000052],
              [103.71566482600008, 21.880696797],
              [103.714668649, 21.880770480000052],
              [103.71380207700005, 21.881728984000048],
              [103.71331979100003, 21.883298588000066],
              [103.71328692300007, 21.884783984000045],
              [103.71340214900002, 21.8863186620001],
              [103.71309671500005, 21.886637834000041],
              [103.71274876000004, 21.886631126000019],
              [103.71050093100004, 21.885960872000076],
              [103.70807759899999, 21.885356866000016],
              [103.70609135500005, 21.885225650000081],
              [103.70415276500006, 21.885188213],
              [103.7032000840001, 21.885541320000087],
              [103.70263477500005, 21.886366276000047],
              [103.70245887100006, 21.887570264000097],
              [103.70248489800008, 21.888638843000052],
              [103.70273466400005, 21.894192956000055],
              [103.70333073200007, 21.898708880000051],
              [103.70381372500007, 21.90159728700003],
              [103.70379210600007, 21.902572035000034],
              [103.70373106700006, 21.903081659000101],
              [103.70316673900001, 21.903860177000034],
              [103.70139037100003, 21.906588825000114],
              [103.69958240600009, 21.90961865400007],
              [103.69598053700005, 21.912799499000087],
              [103.69271606400005, 21.914222249000126],
              [103.68835103900001, 21.914811],
              [103.68623942000009, 21.920295860000039],
              [103.68402190200003, 21.92159945700007],
              [103.68132996500005, 21.921872259000082],
              [103.68135173000007, 21.923126427000028],
              [103.68187895600008, 21.924018921000062],
              [103.68312554000002, 21.924995025],
              [103.68448763800009, 21.9263680500001],
              [103.68491647900005, 21.927212192000042],
              [103.68518893300003, 21.928378341000077],
              [103.68566027500005, 21.929548335000071],
              [103.68612954600006, 21.930811162000019],
              [103.687302129, 21.931762570000046],
              [103.68762742600009, 21.932790425000093],
              [103.68777509000006, 21.933977353000067],
              [103.68703052600009, 21.938374112000055],
              [103.68712387800008, 21.9408833190001],
              [103.68710392100003, 21.941823518],
              [103.68637298000004, 21.943329710000015],
              [103.68468504300009, 21.944295290000056],
              [103.68087921400006, 21.945428695000047],
              [103.67572564100006, 21.946768010000106],
              [103.66976038500005, 21.947696726000032],
              [103.66698614400011, 21.949407150000063],
              [103.66509045500001, 21.951009737000057],
              [103.66424806100004, 21.951721860000092],
              [103.66332391800005, 21.953003961000036],
              [103.66290844600005, 21.953785209000017],
              [103.66283273800009, 21.954944531000109],
              [103.66280674800007, 21.956104828],
              [103.66220527000004, 21.957416408000086],
              [103.65967091600004, 21.961731554000075],
              [103.65825960700008, 21.964768491000086],
              [103.65779060000008, 21.966825522000107],
              [103.65785570400004, 21.968359014000072],
              [103.65892350100002, 21.971769284000061],
              [103.65938541600005, 21.973356936000066],
              [103.659370849, 21.974006671],
              [103.65896686100005, 21.975290631000099],
              [103.658302855, 21.976917415000052],
              [103.65745319000004, 21.979360446000037],
              [103.65690067300008, 21.980992626000081],
              [103.65673158000003, 21.981335741000052],
              [103.65637940800011, 21.982050340000022],
              [103.65481688400006, 21.982948422000078],
              [103.65133949300011, 21.984923399000067],
              [103.65020121100007, 21.985760097000103],
              [103.65002490000005, 21.986963811000045],
              [103.65031639700001, 21.99170526300005],
              [103.65023434200006, 21.993142951000074],
              [103.64966848400005, 21.993967608],
              [103.64825399299998, 21.994914952000052],
              [103.646980001, 21.995145396000026],
              [103.64389119200004, 21.995317108000023],
              [103.6416075440001, 21.995086689000054],
              [103.63608532100007, 21.992842765000105],
              [103.63241176000005, 21.990263549000041],
              [103.62837434200004, 21.992784296000067],
              [103.62452737900006, 21.99568013800004],
              [103.6227159150001, 21.996573091000116],
              [103.62171280900006, 21.996924785000083],
              [103.61923986500008, 21.997363617000062],
              [103.61490581500004, 21.999785397000068],
              [103.60646608400003, 22.006211957000055],
              [103.59961300800011, 22.013391152000047],
              [103.59480444000005, 22.01339357600007],
              [103.59167136200008, 22.014034528000082],
              [103.590712991, 22.014258695000066],
              [103.58695744500005, 22.015852418000037],
              [103.58244930100008, 22.017904133000066],
              [103.57986857500006, 22.018346375000064],
              [103.57547597500006, 22.018983661000036],
              [103.571340338, 22.019317559000037],
              [103.56984142100006, 22.019614210000071],
              [103.56867178500001, 22.020223448000117],
              [103.56666023000011, 22.021909345000076],
              [103.55874207600007, 22.024162804000099],
              [103.55633208000006, 22.024141582000119],
              [103.55384293900008, 22.023732486000114],
              [103.55201390400001, 22.023793759000029],
              [103.55034396700003, 22.024553292000078],
              [103.5480796680001, 22.02654642800006],
              [103.54530244400007, 22.029928734000045],
              [103.54392051100004, 22.030961759000078],
              [103.54259160900004, 22.030872513000062],
              [103.53944787200008, 22.029450761000113],
              [103.53678835900003, 22.028620871000015],
              [103.53337502900006, 22.027555698000079],
              [103.52353989500004, 22.024662360000043],
              [103.52229819700005, 22.024186581000023],
              [103.52139211400005, 22.02340411200003],
              [103.51975101600007, 22.021376119000102],
              [103.51775011100007, 22.017989834000048],
              [103.51404785300008, 22.014394546000027],
              [103.51123592600004, 22.012427041000038],
              [103.51334161699999, 22.009582075000097],
              [103.51388832800002, 22.007908178000065],
              [103.51389656800009, 22.005117696000106],
              [103.51349166900008, 22.003108684000061],
              [103.51202484300003, 22.000657014000069],
              [103.5113516640001, 22.000000179000075],
              [103.51038594600004, 21.999215646],
              [103.51020786600006, 21.998864533000081],
              [103.51018272000002, 21.998342740000076],
              [103.5103113230001, 21.997615545000123],
              [103.51052323900004, 21.996507753000039],
              [103.51175748900002, 21.993874092000084],
              [103.51200178199998, 21.992975456000075],
              [103.51194827000009, 21.992070813000097],
              [103.5104906560001, 21.990686057000097],
              [103.50885499200004, 21.988950176000088],
              [103.50759031800001, 21.986474582000071],
              [103.50694767800005, 21.985280005000085],
              [103.50626218200009, 21.98432783100008],
              [103.50210345000008, 21.982193383000045],
              [103.49916994600009, 21.980222645000055],
              [103.49827614000003, 21.979422616000122],
              [103.49616714300006, 21.972429211000041],
              [103.49495658100008, 21.971102827000074],
              [103.49284083100008, 21.972770313000112],
              [103.49022555700006, 21.975107307000115],
              [103.48814720200005, 21.976379665000053],
              [103.48607996000007, 21.977174232000074],
              [103.48299161500006, 21.977828555000123],
              [103.47991160600004, 21.97812451],
              [103.47657004500005, 21.978654099000039],
              [103.47449435500008, 21.979806878000119],
              [103.47109407100002, 21.982844646000018],
              [103.46831964300006, 21.986492560000045],
              [103.466329871, 21.989439430000068],
              [103.46548357700006, 21.992768058000053],
              [103.4649268260001, 21.994668627000102],
              [103.46411127200011, 21.996683409000092],
              [103.46306209800008, 21.997737466000089],
              [103.45945526300008, 22.000000227000079],
              [103.45852152500001, 22.000883288000047],
              [103.4574578460001, 22.00205041500007],
              [103.45466098100006, 22.005708963000089],
              [103.45220222900011, 22.00982100800006],
              [103.45135091100009, 22.012500570000078],
              [103.45149087600004, 22.013766298000093],
              [103.45233820600006, 22.015217684],
              [103.45463886400009, 22.01796160100011],
              [103.45824719200004, 22.020977339000055],
              [103.46342019500004, 22.024515231000038],
              [103.46597707500004, 22.026259180000096],
              [103.4672799790001, 22.028026240000109],
              [103.46750822100007, 22.02950189799999],
              [103.46680846599999, 22.031326410000048],
              [103.46009534900001, 22.040722710000082],
              [103.4528818090001, 22.053597378000056],
              [103.44913463900004, 22.056462697000079],
              [103.446741339, 22.057639550000104],
              [103.44200083200008, 22.058032871000052],
              [103.43965367300011, 22.05724935100006],
              [103.43601605800005, 22.055458724000054],
              [103.43417126100009, 22.055666055000067],
              [103.432040595, 22.056848139000081],
              [103.42827568300007, 22.060448209000057],
              [103.42204171400003, 22.068410171000075],
              [103.41901819200007, 22.073986436000048],
              [103.41653160800003, 22.079083363000059],
              [103.41486800500005, 22.082726298000033],
              [103.41288929300002, 22.088568973000022],
              [103.40321050500006, 22.125261885000036],
              [103.40204858800003, 22.129895237000063],
              [103.39988194000009, 22.132546836000074],
              [103.39124980100004, 22.141682915000068],
              [103.38933403300007, 22.14482978000003],
              [103.38873240200012, 22.148003803000016],
              [103.38893681600007, 22.150459158000047],
              [103.38964356800001, 22.153905334000079],
              [103.38935151200009, 22.155124840000084],
              [103.38827089400003, 22.156328015000071],
              [103.38667038600003, 22.157275324000025],
              [103.38363879700002, 22.157580192000026],
              [103.38074739800007, 22.157520271000038],
              [103.37747434900005, 22.157022690000083],
              [103.35087637400005, 22.152977260000029],
              [103.34351075600009, 22.153068559000033],
              [103.33796734500008, 22.153933094000067],
              [103.331898182, 22.154786468000061],
              [103.3307862130001, 22.154743241000041],
              [103.33054773100008, 22.154618201000083],
              [103.32928280100006, 22.153954943000095],
              [103.32489012200004, 22.150962851000067],
              [103.321444931, 22.148014168000074],
              [103.31797179900005, 22.144090277000124],
              [103.31715894500003, 22.142760365000044],
              [103.31645365400003, 22.140981050000086],
              [103.31377034300006, 22.132345410000106],
              [103.31272840300008, 22.130089660000074],
              [103.30702504900007, 22.117740971000011],
              [103.30524494300005, 22.11525215300005],
              [103.30237800500007, 22.114211188000077],
              [103.29818523500009, 22.113632455000065],
              [103.29215294600004, 22.113014781000025],
              [103.28874859300001, 22.112452540000049],
              [103.28616249500007, 22.110681893],
              [103.28283588400008, 22.10693445],
              [103.27218305700009, 22.080233179000075],
              [103.27122790700011, 22.07629049700008],
              [103.27172083100004, 22.072255948000041],
              [103.27230618300007, 22.069816836000108],
              [103.2747605390001, 22.066191608000096],
              [103.28472033100003, 22.056596411000037],
              [103.28879230800007, 22.051289056000108],
              [103.289389312, 22.048359723000118],
              [103.28944896500005, 22.045909333000054],
              [103.28793243800007, 22.043425587000073],
              [103.28636672500001, 22.041897234000096],
              [103.28510845300008, 22.040668961000073],
              [103.28435229700005, 22.040463609000049],
              [103.28119196700004, 22.039605304000084],
              [103.27724561300002, 22.039766797000127],
              [103.27062444100009, 22.041832759],
              [103.26027134700003, 22.046025601000082],
              [103.25193920900003, 22.048177140000021],
              [103.24402803600005, 22.049234118000079],
              [103.241114193, 22.05015251400004],
              [103.23603232300007, 22.053721293000095],
              [103.22637530400006, 22.0616047930001],
              [103.2202238080001, 22.065885685000097],
              [103.21383354000001, 22.069180623000051],
              [103.21065046100007, 22.070337966000061],
              [103.20827374700006, 22.070777146000076],
              [103.20435100100003, 22.069957231],
              [103.19964010200007, 22.069120265000031],
              [103.19463607900011, 22.06950264700005],
              [103.18855065300002, 22.071087338000069],
              [103.1880084300001, 22.071310231000041],
              [103.18005169300008, 22.074580866000112],
              [103.1720289900001, 22.080045603000094],
              [103.169627597, 22.081464445000073],
              [103.16805112900003, 22.081430245],
              [103.16678025600004, 22.079686603000056],
              [103.16475793100005, 22.076455706000075],
              [103.16268666000009, 22.07518495900009],
              [103.16116367900003, 22.075194513000085],
              [103.1603220370001, 22.075133581000017],
              [103.158458362, 22.076073686000086],
              [103.15581258900002, 22.076751614000045],
              [103.15319134400004, 22.076449421000085],
              [103.15124845000004, 22.075303925000078],
              [103.14820622400003, 22.070824847000026],
              [103.14616586900006, 22.068328810000089],
              [103.14353860200002, 22.068271526000061],
              [103.14009239300007, 22.069422133000046],
              [103.13423227400006, 22.072481205000116],
              [103.13074276499999, 22.07534680600007],
              [103.12639702600005, 22.080890288000084],
              [103.12083936000002, 22.092780951000087],
              [103.11866928500005, 22.095430011000069],
              [103.11465977500004, 22.098038697000085],
              [103.10669003900009, 22.101295762000106],
              [103.09959997700001, 22.103222510000094],
              [103.09751177700008, 22.097226272000015],
              [103.09756838600005, 22.095481785000089],
              [103.09753654100008, 22.092097079000084],
              [103.09627129300002, 22.088554012000117],
              [103.09491728600008, 22.085873985000056],
              [103.09385766200006, 22.084277478000033],
              [103.09357204100006, 22.08254598400012],
              [103.09305543100004, 22.078194082000024],
              [103.09103163500001, 22.075859925000074],
              [103.08724691100012, 22.070358622000086],
              [103.08989480600009, 22.068893782000117],
              [103.09238638500001, 22.067338709000062],
              [103.0937894460001, 22.066346968000069],
              [103.09411174400003, 22.065342624000017],
              [103.093906233, 22.063396049000069],
              [103.09287380900011, 22.059783673000055],
              [103.09165322300001, 22.05868932500001],
              [103.09027055300001, 22.058169152000048],
              [103.08787956000006, 22.05799719200003],
              [103.08107799400005, 22.058565620000081],
              [103.07536293100004, 22.058714507000055],
              [103.0702507510001, 22.059950382000082],
              [103.06543548900004, 22.062053697000024],
              [103.06225194800007, 22.063384127000063],
              [103.06078626100003, 22.063294749000036],
              [103.05886627400005, 22.062551915000128],
              [103.05725900800003, 22.061524760000047],
              [103.05650480900007, 22.060219694000068],
              [103.05501023600004, 22.056601640000025],
              [103.05372759400005, 22.054426214000067],
              [103.05035699000005, 22.052513930000117],
              [103.04747241000004, 22.051047016000069],
              [103.04368733600003, 22.04912207700005],
              [103.0404495600001, 22.0487953250001],
              [103.03643301700004, 22.048963202000046],
              [103.03309915900006, 22.050003245000127],
              [103.02712004600006, 22.052523455000106],
              [103.02198473800007, 22.055413652000055],
              [103.01887720100008, 22.056816149000014],
              [103.01679716200002, 22.056502928000029],
              [103.01426608500003, 22.055320147000046],
              [103.01105245800005, 22.053264984000087],
              [103.00920505700006, 22.052882500000059],
              [103.00649954600001, 22.053137573000114],
              [103.00363562200002, 22.053678688000034],
              [103.00005726200007, 22.0532297110001],
              [102.99820915300009, 22.052861898000039],
              [102.99701702900005, 22.052157780000044],
              [102.99632043400005, 22.051013226000023],
              [102.99439250900005, 22.046002702000052],
              [102.99330845800006, 22.041123979000062],
              [102.99278250100006, 22.038064053000106],
              [102.99222153200002, 22.036358031000077],
              [102.9912918750001, 22.034869502000021],
              [102.98919362800004, 22.031774147000057],
              [102.988959166, 22.031517490000049],
              [102.98719302200007, 22.02958410500004],
              [102.98589182000005, 22.02842584800004],
              [102.98481791200001, 22.027837184000099],
              [102.98385033200007, 22.027815384000071],
              [102.98226339000004, 22.028344092000026],
              [102.97943476400003, 22.030086643000054],
              [102.97771806000002, 22.030951073000082],
              [102.97649978400003, 22.031262275000074],
              [102.97504839100002, 22.031229519000114],
              [102.97046111500006, 22.030787235000027],
              [102.96888877900008, 22.030751705000014],
              [102.96778850400008, 22.03117842200006],
              [102.96680037700004, 22.031946341],
              [102.96494209200003, 22.033597743000044],
              [102.96170043500004, 22.037249904000063],
              [102.96143503100002, 22.038147039000044],
              [102.96141152800004, 22.039049656000117],
              [102.96186596900006, 22.040188872000051],
              [102.96268033700004, 22.041449120000053],
              [102.96264802800002, 22.042690207000071],
              [102.96188998000009, 22.043914869000055],
              [102.96052419600007, 22.045238661000077],
              [102.95716130300009, 22.048887934000035],
              [102.95368320799999, 22.052308742000015],
              [102.95056787700007, 22.055737704000045],
              [102.94659683600004, 22.059485845000012],
              [102.94472337400001, 22.061701092000071],
              [102.94408610500004, 22.062928393000014],
              [102.94267877600004, 22.065831512000052],
              [102.94167561400008, 22.06716337300012],
              [102.94019740100008, 22.068145774000044],
              [102.93801105200001, 22.068434741000061],
              [102.93498380600009, 22.068478789000068],
              [102.93256436300001, 22.068423743000039],
              [102.93062586300002, 22.068492515000045],
              [102.92890859000005, 22.069356502000034],
              [102.92803516900008, 22.070352591000031],
              [102.92740072300008, 22.071467006000134],
              [102.92687540800004, 22.073035443000052],
              [102.92656244500009, 22.075737569000076],
              [102.92663607900005, 22.077545415000088],
              [102.92732348300008, 22.079028576000056],
              [102.92802569600009, 22.079947650000072],
              [102.92979306800004, 22.081794040000077],
              [102.93427805700006, 22.086185683000089],
              [102.93462032000004, 22.086983643000032],
              [102.93447570400006, 22.087883418000054],
              [102.93406548600005, 22.089680201000036],
              [102.93300303500007, 22.093268250000087],
              [102.932330119, 22.095849204000025],
              [102.93162466800007, 22.099671085000061],
              [102.93117595400007, 22.102934385000061],
              [102.93102539700004, 22.104059751000094],
              [102.93027872600008, 22.104832913000024],
              [102.92880905100007, 22.105476724000091],
              [102.92402511100002, 22.107851079000085],
              [102.92329912700008, 22.107834533000045],
              [102.92306306100008, 22.10760339700002],
              [102.923086751, 22.106700908000043],
              [102.92343533600007, 22.102645213000116],
              [102.92348863300003, 22.100614591000053],
              [102.92378095500007, 22.098702297000017],
              [102.92447767700007, 22.095218872000096],
              [102.92451320000008, 22.093865108000088],
              [102.92394082900006, 22.092610366000095],
              [102.92322971600002, 22.092029753000105],
              [102.9211847920001, 22.091531595000014],
              [102.9186470440001, 22.091360828000091],
              [102.91707422600008, 22.091324937000053],
              [102.91380167300011, 22.091475985000031],
              [102.91211380599999, 22.091211669000096],
              [102.90649579700003, 22.088486955000072],
              [102.90411178600004, 22.087077822000097],
              [102.90139458600001, 22.084532193000058],
              [102.89986645200005, 22.082803945000059],
              [102.89905528400008, 22.081430755000042],
              [102.89823817700007, 22.080283190000038],
              [102.89693122400011, 22.079350162000111],
              [102.89430245800003, 22.078048170000031],
              [102.89059685800002, 22.076269898000064],
              [102.88916004400008, 22.075672492000109],
              [102.88831321900008, 22.075653055000096],
              [102.88680370000006, 22.076451047000134],
              [102.88255105100009, 22.078280491000065],
              [102.88135577200005, 22.079017215000093],
              [102.88026204300006, 22.079955634000022],
              [102.87914061300009, 22.080594369000025],
              [102.878671544, 22.08081616700008],
              [102.87697919600004, 22.081074060000013],
              [102.87245275000006, 22.08124633500006],
              [102.86799795100006, 22.080709843000079],
              [102.86431057900006, 22.079323206000097],
              [102.86150214200003, 22.0763948210001],
              [102.85971870900009, 22.06993219600006],
              [102.85720343300008, 22.066489798000077],
              [102.85443595400007, 22.064725315000096],
              [102.85245513300003, 22.066108030000109],
              [102.85125865100008, 22.066794601000069],
              [102.84995463300001, 22.067094099000073],
              [102.84842242500007, 22.067113599],
              [102.84430558200005, 22.066853488000056],
              [102.84095412500004, 22.066611036000069],
              [102.83954259700005, 22.066523385000011],
              [102.83720080200008, 22.065974626000084],
              [102.83515339300006, 22.065432671000096],
              [102.83315460800006, 22.065276414000046],
              [102.83032865600009, 22.065210822000033],
              [102.82638115100005, 22.065229023000121],
              [102.82360966200007, 22.065329430000077],
              [102.81975638700001, 22.066228770000059],
              [102.81737493700007, 22.067162314000122],
              [102.8163534700001, 22.067907715000068],
              [102.8152628240001, 22.069036106000084],
              [102.81351865100002, 22.070369020000058],
              [102.81081181100006, 22.072448675000089],
              [102.80865101000006, 22.073936660000101],
              [102.80620022200006, 22.075253041000039],
              [102.80154289600009, 22.07756176500002],
              [102.79927307900005, 22.078717462000071],
              [102.79600229100004, 22.079849745000054],
              [102.79386051300003, 22.080623795000065],
              [102.79207346500004, 22.081351178000119],
              [102.78930735700006, 22.083429141000096],
              [102.78707407700001, 22.085409662000096],
              [102.78528399900006, 22.086246788],
              [102.78314951200011, 22.086746204000107],
              [102.78107687000004, 22.087137167000037],
              [102.77705669600002, 22.087647258000054],
              [102.77563169700007, 22.088053350000081],
              [102.77502805700004, 22.088588585000046],
              [102.774654012, 22.089348967000078],
              [102.77438734100005, 22.09049644100007],
              [102.77435470900011, 22.091704343000096],
              [102.77459126300005, 22.093852531000024],
              [102.77486342800009, 22.094682997000078],
              [102.77554036200006, 22.095797656000023],
              [102.77708276600005, 22.097591884000067],
              [102.77886813400006, 22.099117083000053],
              [102.77960992200008, 22.100013491000055],
              [102.77970990700008, 22.100675097000085],
              [102.77939131800011, 22.101256590000062],
              [102.77832295500008, 22.101851227000054],
              [102.77676822500004, 22.102693779000049],
              [102.77538865600003, 22.10359537500004],
              [102.77484237300004, 22.104186878000043],
              [102.7738631170001, 22.105537346000048],
              [102.77260270400009, 22.107458922000056],
              [102.77036279100012, 22.110668469000046],
              [102.76829121700005, 22.112627275000115],
              [102.7651368840001, 22.114811540000098],
              [102.76462877700007, 22.115062494000078],
              [102.76077206900008, 22.116967276000118],
              [102.75803126400007, 22.11878476000004],
              [102.75444263400006, 22.122087803000042],
              [102.75124715300007, 22.125776413000061],
              [102.74890281900008, 22.127854022000101],
              [102.74590222100005, 22.129901602000054],
              [102.74298644600003, 22.132607481000029],
              [102.74117313100001, 22.134948427000012],
              [102.73988753600004, 22.137678197000099],
              [102.73814723500001, 22.142279079000012],
              [102.73725827500002, 22.145269078000048],
              [102.73722756100004, 22.146397472000068],
              [102.73793909800003, 22.149927067000071],
              [102.73789132700007, 22.151682329],
              [102.73770903200008, 22.153434407000105],
              [102.737247467, 22.155556248000075],
              [102.73544549000003, 22.162413660000091],
              [102.73496339300002, 22.165287692000035],
              [102.73527136800001, 22.168807625000042],
              [102.73574802100011, 22.171077015000051],
              [102.73652446900005, 22.172224417000038],
              [102.73810811800007, 22.17339087500001],
              [102.74209628200001, 22.175241308000082],
              [102.74434241800004, 22.176799693000056],
              [102.74564685700005, 22.178335847000064],
              [102.74600275300003, 22.180100526000054],
              [102.74582555700002, 22.186619637000057],
              [102.7462376340001, 22.191270876000054],
              [102.7472918110001, 22.197066197000126],
              [102.74861513700007, 22.202867794],
              [102.749780028, 22.206091719000121],
              [102.751386987, 22.208747946000074],
              [102.74957683600007, 22.209771647000039],
              [102.74869596900001, 22.210290424000064],
              [102.74824089200011, 22.211088989000061],
              [102.74814855800011, 22.211828668000067],
              [102.74812841100004, 22.21257003700012],
              [102.74866743200005, 22.213998991000047],
              [102.74871031800005, 22.21507904700006],
              [102.74833819200008, 22.215953452000022],
              [102.74699058000004, 22.217964563000066],
              [102.74576000900001, 22.219328425000029],
              [102.74475344300004, 22.219768988000062],
              [102.74375949300004, 22.219745553000081],
              [102.74265604700005, 22.220090962000057],
              [102.740518244, 22.221897671000065],
              [102.73846210500005, 22.224356287000099],
              [102.73782529200011, 22.225826953000102],
              [102.73759363800005, 22.227028620000056],
              [102.73755320400008, 22.228513355000068],
              [102.73741843000002, 22.229810161000074],
              [102.73638398600005, 22.23127142],
              [102.73413394300005, 22.233539659000101],
              [102.73078029700012, 22.236153204000075],
              [102.72782671200008, 22.238683301000073],
              [102.72682507000007, 22.238938172000111],
              [102.72543593600007, 22.238812454000097],
              [102.72305529400009, 22.238570415000055],
              [102.7207689940001, 22.238516287000017],
              [102.71957107400002, 22.238673622000071],
              [102.71845733100005, 22.239390074000106],
              [102.71582205500006, 22.24118470000009],
              [102.71370914600003, 22.242063145000124],
              [102.71271762400006, 22.241946786000085],
              [102.71183821100004, 22.24136880100005],
              [102.71068599000009, 22.239855812000094],
              [102.70951091000003, 22.239177953000052],
              [102.7088150870001, 22.23916144000011],
              [102.70790773200008, 22.23960416900006],
              [102.70692732400005, 22.240724513000117],
              [102.70490353400002, 22.242954248000046],
              [102.70381617200012, 22.243918762000092],
              [102.70251810700009, 22.244828748000067],
              [102.70079864400003, 22.245629670000078],
              [102.69806241900002, 22.246753024000029],
              [102.696728955, 22.247018409000049],
              [102.69593511900004, 22.246950011000074],
              [102.69530847700007, 22.246588479000096],
              [102.69415307500003, 22.246164856000036],
              [102.69356993500001, 22.246150978000045],
              [102.69260891800008, 22.246375688000072],
              [102.69127, 22.246838980000035],
              [102.69035246800007, 22.247411329000052],
              [102.68820268700001, 22.249899442000043],
              [102.68448162000007, 22.254446007000084],
              [102.68060245400009, 22.258509210000099],
              [102.67554014800001, 22.263461561000049],
              [102.66923756100007, 22.259311704000062],
              [102.66658730900008, 22.257743167000044],
              [102.66231513700009, 22.25638664100007],
              [102.66045170800001, 22.25558944],
              [102.65992721700005, 22.255075156000089],
              [102.65994800500007, 22.254323067000122],
              [102.66036908200002, 22.253705995000047],
              [102.66187722400009, 22.252738655000122],
              [102.66636003400001, 22.251340755000015],
              [102.67087044000009, 22.248939964000058],
              [102.67674034400002, 22.246069813000076],
              [102.679487169, 22.24412844700003],
              [102.68211661700005, 22.241557070000084],
              [102.68448028900005, 22.238853889000062],
              [102.68519109400006, 22.237491050000102],
              [102.68537738200011, 22.235613960000045],
              [102.68546702400006, 22.232354760000113],
              [102.68606736300009, 22.230111219000023],
              [102.68776489600003, 22.227141179000043],
              [102.69097358600004, 22.223078157000131],
              [102.69254330800007, 22.219854128000094],
              [102.69415083800003, 22.215251113000086],
              [102.69516844800003, 22.21251563900006],
              [102.69568050000004, 22.210076281000021],
              [102.6957393440001, 22.207932307000085],
              [102.69472999400008, 22.202759361000091],
              [102.69366772300003, 22.199515965000046],
              [102.69234005500005, 22.197553482000032],
              [102.69040607300008, 22.195991307],
              [102.68781350000006, 22.194099045000115],
              [102.68715712700002, 22.193238568000062],
              [102.6868821730001, 22.192246363000031],
              [102.68620832100002, 22.189273344000071],
              [102.68559646400003, 22.186794614000057],
              [102.68519760100007, 22.184813782000035],
              [102.68463612300006, 22.183251488000089],
              [102.68376295600002, 22.182033777000051],
              [102.68266522800006, 22.180740300000053],
              [102.68148427000004, 22.179726442000103],
              [102.6804543370001, 22.178716181000091],
              [102.67980192100002, 22.177714935000026],
              [102.67881654400003, 22.175086394000054],
              [102.67789313400002, 22.172952160000037],
              [102.67687294200006, 22.171590077000047],
              [102.67547914000002, 22.170078243000098],
              [102.67451896299998, 22.169280818000026],
              [102.67377366600005, 22.168910971000066],
              [102.67287348800008, 22.168678234000083],
              [102.67174100199999, 22.168651157000063],
              [102.66999095500006, 22.169102153000075],
              [102.66799692000009, 22.17018095300007],
              [102.66537945700006, 22.17194888300007],
              [102.66299816700003, 22.173370393000091],
              [102.65894608200004, 22.175103875000076],
              [102.65627241800007, 22.176166269000049],
              [102.65490755200003, 22.176344761000074],
              [102.65309356300004, 22.176371645000081],
              [102.64840465800003, 22.176540717000037],
              [102.64295488000005, 22.176902608000049],
              [102.64082912800002, 22.177273936000041],
              [102.63952608300012, 22.177946655000099],
              [102.63843977600004, 22.178976608000056],
              [102.63772903400012, 22.18008600200001],
              [102.63618904300006, 22.183850831000115],
              [102.63481172800005, 22.187197106000056],
              [102.63418033700003, 22.188167572000062],
              [102.63317922700007, 22.188847510000095],
              [102.63004827900001, 22.190039365000032],
              [102.62737619600007, 22.191031027000108],
              [102.62614460600005, 22.191846184000049],
              [102.62529249500007, 22.192600094000078],
              [102.62122903700003, 22.197430312000051],
              [102.62038472600007, 22.197902759000065],
              [102.61931779100007, 22.19822901900006],
              [102.61810375400007, 22.1984109140001],
              [102.61710840200006, 22.19887970000007],
              [102.61640333500007, 22.199777908000051],
              [102.61591303000009, 22.201103721000059],
              [102.61556590900005, 22.202714595000117],
              [102.61546491800006, 22.203627385000054],
              [102.61557086700003, 22.205249195000043],
              [102.61597493800006, 22.207019007000014],
              [102.61667324800003, 22.209077529000076],
              [102.61664581200006, 22.210062482000033],
              [102.61645949800001, 22.211325214000063],
              [102.61604468900006, 22.212652812000044],
              [102.61541116100005, 22.213693524000085],
              [102.61454322800009, 22.21501017600005],
              [102.61413231900011, 22.21619706200002],
              [102.61394599300006, 22.217459767000044],
              [102.61397641100008, 22.219079707000056],
              [102.61405685600008, 22.221616057000034],
              [102.61431775300008, 22.223100751000047],
              [102.61489250300012, 22.224170629000106],
              [102.61650691000003, 22.225899211000041],
              [102.61834398800006, 22.227773949],
              [102.61877948800003, 22.228418046000087],
              [102.61883541700004, 22.229123384],
              [102.61880211100004, 22.230319358000102],
              [102.61860992400004, 22.231793091],
              [102.61834024300002, 22.233335335000071],
              [102.61777043400012, 22.234799943000013],
              [102.61714076200008, 22.235699910000051],
              [102.61598448300006, 22.236610964000114],
              [102.6133948720001, 22.237739834000081],
              [102.60770427200001, 22.239985127000047],
              [102.60318391500009, 22.241543739000043],
              [102.600328534, 22.243023454000088],
              [102.59602040600005, 22.246135889000037],
              [102.59143980900004, 22.249837323000051],
              [102.58308183400004, 22.255948830000079],
              [102.58047841200001, 22.257553507000068],
              [102.57671440100006, 22.259487343000025],
              [102.57193097900004, 22.261277147000058],
              [102.5680757920001, 22.261898089000098],
              [102.56538787400009, 22.261951732000092],
              [102.56259557000008, 22.261168832000067],
              [102.56133397000005, 22.260542368000102],
              [102.56046928400011, 22.259449029000052],
              [102.55937241200012, 22.257516048000078],
              [102.55747129600012, 22.252346643000109],
              [102.55651239000008, 22.250059560000054],
              [102.55479996400007, 22.247277499000084],
              [102.55293291500004, 22.245444766000055],
              [102.55129803500003, 22.24445168000004],
              [102.54799810900002, 22.243537003000121],
              [102.5441667420001, 22.243324142000098],
              [102.54121332900007, 22.24372841400006],
              [102.534875852, 22.246194285000072],
              [102.53020333800001, 22.24858168600008],
              [102.52603528300003, 22.25121964100012],
              [102.52276867500011, 22.25364137900003],
              [102.51806204800006, 22.257219051000021],
              [102.514912936, 22.26000095800007],
              [102.51332481600005, 22.261868107000012],
              [102.51170623400006, 22.264806731000064],
              [102.510595564, 22.267876970000074],
              [102.50945103000008, 22.272137716000096],
              [102.50844783300002, 22.275925355000048],
              [102.50762998400003, 22.277692238000043],
              [102.50522377200008, 22.281326136000068],
              [102.50056604200007, 22.287644518000072],
              [102.49817651899998, 22.290683020000031],
              [102.49568621200008, 22.292765942000067],
              [102.49347532300003, 22.294021813000064],
              [102.49114674, 22.294917356],
              [102.48958187900011, 22.29595087600007],
              [102.48838359200009, 22.297589090000081],
              [102.48822176500002, 22.298776394000086],
              [102.48868903800005, 22.300336598000037],
              [102.48952972400006, 22.302263447000122],
              [102.48911216700006, 22.303444413000051],
              [102.48832120500008, 22.304258778000104],
              [102.48661820000004, 22.305646225000061],
              [102.48374057700006, 22.307838512000089],
              [102.48135734400005, 22.310638619000045],
              [102.478950228, 22.314271987000019],
              [102.47736131400008, 22.316138671000104],
              [102.47433511600008, 22.319041866000063],
              [102.47326102700002, 22.32080213100005],
              [102.47270864700005, 22.322217944000045],
              [102.47238474800008, 22.324592396000043],
              [102.47193636600011, 22.326844636000054],
              [102.471141828, 22.327777931000092],
              [102.46828757100009, 22.329136652000066],
              [102.46696783600005, 22.330533390000085],
              [102.46642220400003, 22.331711082000091],
              [102.46454996300011, 22.334523540000092],
              [102.46243555500004, 22.336853496000053],
              [102.46046605300006, 22.338591412000049],
              [102.45683399700002, 22.340288004000094],
              [102.45552093900008, 22.341446579000035],
              [102.45497178800004, 22.342743252000027],
              [102.45480970900005, 22.343930420000021],
              [102.45485213200008, 22.346909449000037],
              [102.45453821900004, 22.348926660000053],
              [102.45358826100005, 22.35080893300001],
              [102.45147012200003, 22.353257730000031],
              [102.44721314000006, 22.358869418],
              [102.44430344700001, 22.36213222400005],
              [102.44088546500009, 22.365263186000028],
              [102.43892577600003, 22.366643729000124],
              [102.43750511200008, 22.367084780000113],
              [102.43571445000006, 22.367040136000057],
              [102.43366112000008, 22.367227146000076],
              [102.43171851600002, 22.36801248400004],
              [102.42588374600007, 22.37060642800008],
              [102.42393421000006, 22.371629740000017],
              [102.4232671660001, 22.372565973000036],
              [102.42256227500005, 22.374811496000021],
              [102.42237590300009, 22.376831741000025],
              [102.42239718800008, 22.38052471900005],
              [102.42208977000004, 22.382303692000072],
              [102.42048599400003, 22.384645807000055],
              [102.41784850500002, 22.387319368000114],
              [102.41576394900004, 22.388577421000022],
              [102.41383828200004, 22.388767450000024],
              [102.41038793800003, 22.388561951000071],
              [102.40795059800007, 22.388739114000074],
              [102.40665071500007, 22.389421191000025],
              [102.40559631700005, 22.390466742000022],
              [102.40386780000003, 22.3926864680001],
              [102.40369711600002, 22.392974213000016],
              [102.40184887700005, 22.396089963000065],
              [102.39877193000009, 22.400657923000011],
              [102.39771397300005, 22.401822432000081],
              [102.39668019400004, 22.402153803000054],
              [102.39489267400003, 22.401989814000075],
              [102.39324000200006, 22.401590985000027],
              [102.39170142300003, 22.401671438000022],
              [102.39065379600004, 22.402478851000041],
              [102.3890529220001, 22.4047016210001],
              [102.3861835160001, 22.409737530000129],
              [102.39004964300005, 22.415452305000102],
              [102.39028475200006, 22.416174520000084],
              [102.39025700700007, 22.417128884000093],
              [102.38965652400003, 22.418403137],
              [102.38910028100003, 22.419917262000055],
              [102.38907808100005, 22.420680745000077],
              [102.38915547900007, 22.421542251000034],
              [102.38964513000008, 22.422318605000058],
              [102.39167028900003, 22.424016956000067],
              [102.39449511300009, 22.425520482000039],
              [102.39560696400007, 22.425606106000103],
              [102.39422892500008, 22.433654231000055],
              [102.39261745600007, 22.43623391600007],
              [102.38996849500009, 22.439263891000039],
              [102.38889296800001, 22.441023307000037],
              [102.38871655400007, 22.442686214000048],
              [102.38886519400002, 22.4452802550001],
              [102.38987317800012, 22.450248009000084],
              [102.39040050600005, 22.454131795000087],
              [102.39063044000007, 22.457233976000076],
              [102.39045056500001, 22.459015833000073],
              [102.38982281500003, 22.460786432000113],
              [102.38915875900007, 22.461603383000025],
              [102.3840795080001, 22.468918832000014],
              [102.38321998200003, 22.469849927000048],
              [102.38185895200004, 22.470411140000031],
              [102.37882386500009, 22.471287472000064],
              [102.37398846100004, 22.472416133000102],
              [102.37115048100006, 22.473118695000061],
              [102.36856668900006, 22.473887170000047],
              [102.36494169900007, 22.475224790000055],
              [102.36195828400004, 22.476518998000117],
              [102.36072859500008, 22.476964295000101],
              [102.36013182000009, 22.477663747000065],
              [102.35965258100005, 22.47872343000008],
              [102.358446434, 22.482741910000037],
              [102.35782138600005, 22.486596407000015],
              [102.35748883400008, 22.48920787800008],
              [102.35727423200008, 22.489976499000086],
              [102.35648369100005, 22.49073057400004],
              [102.35525389800007, 22.491175837000107],
              [102.35332018900004, 22.491603288000036],
              [102.34934392300002, 22.491800432],
              [102.34780634900008, 22.491821073000025],
              [102.347022748, 22.492337120000094],
              [102.34654688900005, 22.493277745000029],
              [102.346392737, 22.494166973000063],
              [102.34598206700004, 22.497252737000025],
              [102.34550040800006, 22.500574850000099],
              [102.34484629400004, 22.502293822000041],
              [102.34476693500004, 22.502642372000103],
              [102.34463507900006, 22.503849591000069],
              [102.34228356900009, 22.510334720000102],
              [102.34103759300002, 22.513308883000072],
              [102.34037748800003, 22.51445685500002],
              [102.33934007400009, 22.515653472000082],
              [102.33810801000001, 22.517078071000093],
              [102.33738354900002, 22.518282605000103],
              [102.33665054300005, 22.519778085000027],
              [102.33588503900003, 22.522379146000034],
              [102.33586794600004, 22.522961036000041],
              [102.33591003300006, 22.523660891000041],
              [102.33651210400004, 22.524491405000013],
              [102.33790580100008, 22.525842713000088],
              [102.33814540100009, 22.52638307600003],
              [102.33809694100007, 22.526945823000055],
              [102.33781814500001, 22.527740190000053],
              [102.33725215500007, 22.528527277000073],
              [102.33617730600005, 22.529490862000046],
              [102.333481347, 22.531693527000037],
              [102.33016101500009, 22.533822079000032],
              [102.32862430800003, 22.534947694],
              [102.32804205600009, 22.535573457000076],
              [102.32745125000005, 22.536490167000075],
              [102.32671808800006, 22.537985547000083],
              [102.32544469400003, 22.540806509000078],
              [102.32452198900008, 22.542355299000057],
              [102.32301352900005, 22.544646170000071],
              [102.32127002800003, 22.546406987000097],
              [102.32015942100007, 22.54795095700004],
              [102.31930596500007, 22.549344428000047],
              [102.31936460800003, 22.54966193400012],
              [102.31915534100003, 22.550998607000047],
              [102.31912512200002, 22.552024067000076],
              [102.31910303900005, 22.552773439000084],
              [102.31915480300007, 22.555340308000012],
              [102.31920714100005, 22.556446801000085],
              [102.31967807500003, 22.557761279000133],
              [102.31994747900004, 22.558253574],
              [102.320535471, 22.558370525],
              [102.32581401900003, 22.559152164000103],
              [102.33014680500007, 22.559585917000021],
              [102.335840643, 22.561025387000107],
              [102.33953707300005, 22.562414118000085],
              [102.34632925600008, 22.564933377000074],
              [102.35011031000002, 22.566405001000064],
              [102.35348067400008, 22.567831865000088],
              [102.35300600200007, 22.570648538000079],
              [102.35280155600003, 22.572217527000049],
              [102.3525716930001, 22.572851218000075],
              [102.35202655400012, 22.573427745000082],
              [102.35126881900004, 22.574048092000076],
              [102.34981622100003, 22.574946010000119],
              [102.34884157600003, 22.575757633000038],
              [102.347805475, 22.576862837000085],
              [102.34714752500011, 22.577682458000048],
              [102.34634102399998, 22.579064014000046],
              [102.34476778800011, 22.582270841000081],
              [102.3441966340001, 22.58373211700011],
              [102.34400652800009, 22.584809508000077],
              [102.34402325500005, 22.586039703000083],
              [102.34419834700007, 22.587273902000057],
              [102.34443633000004, 22.5881653530001],
              [102.34512635600002, 22.589855287000127],
              [102.34552992400002, 22.590504964000054],
              [102.34614754900005, 22.591061686000025],
              [102.35205815600007, 22.593080347000068],
              [102.35394423500007, 22.593619891000039],
              [102.35468187, 22.593687715000051],
              [102.35542671300007, 22.593509765000071],
              [102.35745293100004, 22.592872271000083],
              [102.35962599600006, 22.5926319800001],
              [102.36120829700008, 22.592721088000097],
              [102.36317861900002, 22.593090527000115],
              [102.36700796200006, 22.594023290000017],
              [102.36878995000006, 22.594510899000042],
              [102.37004402100008, 22.594985197000113],
              [102.3712851870001, 22.595901876000113],
              [102.37276879700002, 22.597562480000107],
              [102.37537656900005, 22.600530279000083],
              [102.37691304499999, 22.602192168],
              [102.37845384000012, 22.603706583000083],
              [102.37998606700006, 22.60551590500004],
              [102.38126293700002, 22.607023653000091],
              [102.38354285400001, 22.609466566000066],
              [102.38450872900007, 22.610769722000065],
              [102.38505941000008, 22.611816497000078],
              [102.38568991700002, 22.613750648000064],
              [102.38611496700003, 22.615482886000038],
              [102.38654433000002, 22.617067664000068],
              [102.38719490400011, 22.618313678000042],
              [102.38835916700008, 22.620064443000039],
              [102.38912252100009, 22.621067344000068],
              [102.39061506400003, 22.622432836000073],
              [102.39268270500006, 22.624009487000052],
              [102.39376432900011, 22.62497115500004],
              [102.39510753500004, 22.626247146000097],
              [102.39691267100009, 22.628119582000039],
              [102.39938742300004, 22.631835961000043],
              [102.40108662700004, 22.633801854000076],
              [102.40302305200004, 22.63471583700003],
              [102.40558451200005, 22.635453112000015],
              [102.40887705100008, 22.635920131000105],
              [102.41280297800009, 22.635922103],
              [102.41455528800005, 22.636062054000035],
              [102.41548166400004, 22.636181363000048],
              [102.41638298500001, 22.637165527000036],
              [102.41666485100006, 22.638134225000073],
              [102.41663978700004, 22.638999089000045],
              [102.41617947300004, 22.640622400000062],
              [102.41499922300009, 22.642131564000053],
              [102.41341991300007, 22.643149918000027],
              [102.41133551200009, 22.643770984000078],
              [102.41019424100007, 22.64393478200013],
              [102.40823816400005, 22.643693547000062],
              [102.40610591700002, 22.642946251000048],
              [102.40557891700003, 22.642761545000042],
              [102.40490784500011, 22.642155002000038],
              [102.40448758200006, 22.641550193000093],
              [102.40275569200003, 22.641848040000042],
              [102.40180195700007, 22.642261472000058],
              [102.40074142300007, 22.643109515000056],
              [102.39996572400003, 22.643877237000076],
              [102.39927396100006, 22.644996897000077],
              [102.39844996500001, 22.647425136],
              [102.39711474900011, 22.651239901000061],
              [102.39645349600002, 22.654546756000023],
              [102.39613557200002, 22.655763214000046],
              [102.39533183400003, 22.65749222500002],
              [102.39444923700002, 22.658694511000107],
              [102.39261023200001, 22.660397558000064],
              [102.39010477400009, 22.662346239],
              [102.38837000400008, 22.663702019000098],
              [102.38738811800002, 22.665076688000063],
              [102.38580842100006, 22.667573266000126],
              [102.38446771000005, 22.67156257400007],
              [102.38152926000002, 22.678660052000055],
              [102.38079392900002, 22.681265195000044],
              [102.38098717200003, 22.682713033000113],
              [102.38140602300004, 22.683696490000038],
              [102.38212649600005, 22.684834168000023],
              [102.38366113900003, 22.686482096000034],
              [102.38543398100008, 22.687716157000089],
              [102.38809838, 22.689392399000049],
              [102.39018678200003, 22.69014451600011],
              [102.39168491300009, 22.690461976000087],
              [102.39288993700005, 22.690492187000096],
              [102.39432904600002, 22.690248368000084],
              [102.39578035100001, 22.68958500700009],
              [102.39976579600008, 22.687305748000064],
              [102.40204956600004, 22.686523240000064],
              [102.40486659900006, 22.685544132000054],
              [102.40607565600001, 22.68543442700004],
              [102.40735596000007, 22.685466444000021],
              [102.40885610400002, 22.685713865000068],
              [102.410570025, 22.68638646600003],
              [102.41130488400005, 22.687034583000099],
              [102.411667252, 22.687533453000071],
              [102.41179758400004, 22.688236439000065],
              [102.41198497800009, 22.689570599000049],
              [102.41234328600007, 22.690209309000053],
              [102.41299879600004, 22.690995377000078],
              [102.41403899600004, 22.691511168000119],
              [102.41515249500007, 22.692098752000064],
              [102.41642068100002, 22.692550247000028],
              [102.41777026700004, 22.692793844000022],
              [102.41920125700005, 22.692829552000049],
              [102.42124084500006, 22.692670519000032],
              [102.42176601900007, 22.692753584000066],
              [102.42243575200007, 22.693050174000092],
              [102.42331928800007, 22.693771924000124],
              [102.42477904100005, 22.695417669000051],
              [102.42616755200001, 22.696921671000055],
              [102.42719972500007, 22.697717078000053],
              [102.42867977000004, 22.698663568000121],
              [102.43178447100007, 22.700770037000062],
              [102.43310785600005, 22.701922506000116],
              [102.43390606000001, 22.702991922000045],
              [102.43468001300002, 22.704900381000037],
              [102.4349429000001, 22.706236348000083],
              [102.43489238100008, 22.707984340000031],
              [102.43501068700002, 22.709106797000089],
              [102.43549348900008, 22.710658133000017],
              [102.43626950000008, 22.712496631000079],
              [102.43706573500006, 22.713635934000109],
              [102.43794336100011, 22.714567344000081],
              [102.43889833600008, 22.715430707000017],
              [102.43955202200007, 22.716286577000041],
              [102.43997167000006, 22.717416499000095],
              [102.44006174500005, 22.719517778000053],
              [102.44058158000007, 22.722399373000087],
              [102.44163882200007, 22.724944461000085],
              [102.442569647, 22.726646789000071],
              [102.44359397600002, 22.727721725000016],
              [102.44596416700001, 22.729179904000048],
              [102.45142532800006, 22.731939164],
              [102.45350638100003, 22.732970282000053],
              [102.45408889, 22.733684378000071],
              [102.45420330500005, 22.734946592000057],
              [102.45417510900002, 22.735925406000042],
              [102.45408242100008, 22.73693891100006],
              [102.45359476100006, 22.737867881000092],
              [102.45254700900006, 22.739039616000056],
              [102.45009780300009, 22.740860987000033],
              [102.44904754900006, 22.742118184000049],
              [102.44834151599999, 22.743271360000072],
              [102.44820311100008, 22.744535122000094],
              [102.44852868900001, 22.748958771],
              [102.44930408700004, 22.753498673000117],
              [102.45001174200007, 22.75645988200008],
              [102.45098825300005, 22.757955923000047],
              [102.45403412300008, 22.762341736000025],
              [102.45687062700003, 22.766013306000055],
              [102.45841517300002, 22.767525096000085],
              [102.460460955, 22.768865091000023],
              [102.46231898900008, 22.769832028000089],
              [102.46429211800009, 22.770249221000057],
              [102.46794875600004, 22.770800112000074],
              [102.47131315800006, 22.771159538000063],
              [102.47557024300004, 22.77154094100009],
              [102.47685438700006, 22.771756828],
              [102.47754076700001, 22.772050046000025],
              [102.47802613900002, 22.772430399000051],
              [102.47830258100007, 22.773173982000024],
              [102.47819168700005, 22.777039206],
              [102.47854620200006, 22.778521461000075],
              [102.47912286100008, 22.779180335000056],
              [102.47960561400006, 22.779652708000064],
              [102.48039386300009, 22.779856339000034],
              [102.48148759800007, 22.779791213000067],
              [102.48427813000004, 22.779399532000077],
              [102.48596437500002, 22.779441081000051],
              [102.48853278700008, 22.77987271100006],
              [102.49118723800007, 22.780766903000057],
              [102.49246881900007, 22.781074708000034],
              [102.49375831200008, 22.78110643399999],
              [102.49684641600007, 22.780721918000069],
              [102.49935250400003, 22.779862592000107],
              [102.50077010600009, 22.778884401000084],
              [102.50168122700006, 22.778262129000026],
              [102.5021798060001, 22.778182282000081],
              [102.50307252000007, 22.778204208000119],
              [102.50414784100005, 22.778783176000061],
              [102.50503267500008, 22.779081185000024],
              [102.50612378000002, 22.77910797000002],
              [102.50753083600009, 22.778497848000079],
              [102.510799418, 22.775262668],
              [102.51377559200004, 22.77183605099999],
              [102.51502090900006, 22.769932585000063],
              [102.51574144100007, 22.769029293000109],
              [102.51666033200007, 22.76813084800002],
              [102.51949779300001, 22.766082152000067],
              [102.5223273350001, 22.764309511000036],
              [102.52343145200005, 22.763876053000054],
              [102.52472082500007, 22.763907602000089],
              [102.52591364500009, 22.763844684000034],
              [102.52681938000006, 22.76340635600009],
              [102.52824456900004, 22.76215185300012],
              [102.53120191600004, 22.759369148000097],
              [102.53468643200004, 22.755494091000017],
              [102.53786283700005, 22.751979820000123],
              [102.54071809500005, 22.749286542000036],
              [102.54183252200005, 22.748484831000049],
              [102.54342976100006, 22.748155391000054],
              [102.54631627400005, 22.747857365000087],
              [102.5470157190001, 22.747690209000027],
              [102.54722449300006, 22.747326903000072],
              [102.54715399700008, 22.746312092000039],
              [102.54670766300011, 22.744551319000017],
              [102.54645446400011, 22.742979448000078],
              [102.54639440300012, 22.741596483000045],
              [102.54653268200011, 22.740218330000062],
              [102.54687191200001, 22.738752986000051],
              [102.54803055300005, 22.736386592000102],
              [102.54907175800004, 22.734662025000048],
              [102.55070535200011, 22.733043989000024],
              [102.55151691900002, 22.732419036000096],
              [102.55241723900009, 22.732164646000093],
              [102.55380818300007, 22.732106404000071],
              [102.55540007000008, 22.73196094800003],
              [102.55660308100005, 22.731529708000053],
              [102.55802524000003, 22.730366968000034],
              [102.56060061900004, 22.727021782000101],
              [102.56174342200009, 22.725207499000085],
              [102.56227563300007, 22.72393098800007],
              [102.56271127600006, 22.722560010000059],
              [102.56322488200001, 22.718427789000103],
              [102.56325866800006, 22.717231251000044],
              [102.56337861600012, 22.716497320000073],
              [102.56379344500007, 22.715862661000052],
              [102.56602689700004, 22.714074813000096],
              [102.56665301000007, 22.71298474900005],
              [102.56679112300003, 22.71160651400006],
              [102.5665170340001, 22.710770895000017],
              [102.56606799800007, 22.70910207600005],
              [102.56592164200003, 22.707256377000107],
              [102.56604676900008, 22.706338346000088],
              [102.56645637300005, 22.705887760000017],
              [102.56715305200005, 22.705812571000102],
              [102.56922229800001, 22.706323351],
              [102.57237966500004, 22.706952623000021],
              [102.57524995700004, 22.707206463000063],
              [102.57931530100005, 22.707305009000045],
              [102.58119406000007, 22.707534734000134],
              [102.58227181200007, 22.708021380000076],
              [102.5830365730001, 22.709053073000085],
              [102.58329779400005, 22.710348884],
              [102.58352017200006, 22.713025345000034],
              [102.58386838600009, 22.718283776000092],
              [102.58420548400001, 22.720410331],
              [102.5847357330001, 22.722725771000093],
              [102.58616365700003, 22.724878712000063],
              [102.58751572700008, 22.726200865000031],
              [102.58976806300009, 22.727268472000084],
              [102.59211700300003, 22.72843049300004],
              [102.59357347300001, 22.729570922000072],
              [102.59549737300003, 22.731735751000045],
              [102.59722556700002, 22.733803723000086],
              [102.59859585099998, 22.734481518000038],
              [102.60076724700005, 22.734902328000125],
              [102.60235395100007, 22.734940610000095],
              [102.60405015, 22.734613120000091],
              [102.606061895, 22.733648513000034],
              [102.60950320700007, 22.731244707000094],
              [102.61143633400006, 22.729541341000115],
              [102.61480151100005, 22.726306681],
              [102.61825802900006, 22.72335044400009],
              [102.61958830000007, 22.721908786000085],
              [102.62073309800012, 22.720002138000069],
              [102.62218563300004, 22.717734459000027],
              [102.62331495000004, 22.716380029000049],
              [102.62525294400008, 22.714492398000097],
              [102.62748837700012, 22.712611887000023],
              [102.63041786200003, 22.710748006000081],
              [102.63445596800001, 22.708265920000059],
              [102.63607579100001, 22.707107381000036],
              [102.63749985200008, 22.705852036000081],
              [102.63966152600005, 22.703048527000114],
              [102.64068380800008, 22.701967732000035],
              [102.64158386700004, 22.701712976000096],
              [102.64267710400007, 22.701647052000062],
              [102.64436781200007, 22.701503327000069],
              [102.645667035, 22.701165993000046],
              [102.64738077300005, 22.700193824000081],
              [102.64951922300006, 22.698218612000076],
              [102.65105762800007, 22.696413244],
              [102.65210536299999, 22.694411909000053],
              [102.65306415300009, 22.692039991000087],
              [102.65357266900007, 22.691591601000113],
              [102.65406840600005, 22.691603449000027],
              [102.65533688000002, 22.692370639],
              [102.65933813500007, 22.694768935000106],
              [102.66148624000007, 22.696017615000038],
              [102.66443014200003, 22.697193154000047],
              [102.66796643500005, 22.698474881000053],
              [102.6704273820001, 22.699178284000077],
              [102.67309174900002, 22.699702284000089],
              [102.67536970000003, 22.69984862700003],
              [102.67932560700005, 22.700311182000021],
              [102.68080272100005, 22.700714737000013],
              [102.68226713100007, 22.701578522000077],
              [102.68373315100004, 22.702620023000094],
              [102.68425313600008, 22.703191918000073],
              [102.6844316180001, 22.704004366000063],
              [102.68441275300006, 22.704687793000033],
              [102.68467017100004, 22.705066934000051],
              [102.685070024, 22.70513859900003],
              [102.68580623100007, 22.705156090000052],
              [102.68654758100003, 22.704987188000111],
              [102.68769906600009, 22.704517181000128],
              [102.68885740100009, 22.703798636],
              [102.69090975500009, 22.702230932000056],
              [102.69487024700004, 22.699465051000089],
              [102.69616408100008, 22.698687510000056],
              [102.69697573600008, 22.698395898000086],
              [102.697578073, 22.698410177000113],
              [102.69870724800009, 22.698747795000074],
              [102.69957044600001, 22.699016929000045],
              [102.7002414130001, 22.698970653000039],
              [102.70112511900004, 22.698494228000051],
              [102.70310014600003, 22.697297586000076],
              [102.70480744200006, 22.696094587000104],
              [102.70570479800006, 22.695121084000021],
              [102.70633615, 22.694079115000051],
              [102.70685585300001, 22.692226268000049],
              [102.70764289900011, 22.687955030000047],
              [102.70787439200008, 22.686841404000099],
              [102.70782967400001, 22.686032102000105],
              [102.70739024800008, 22.684964773000083],
              [102.70714305600005, 22.684212853000041],
              [102.70715671700003, 22.683715792000037],
              [102.70730421100006, 22.683221898000028],
              [102.7076524710001, 22.682732763000054],
              [102.70819978800009, 22.682310494000113],
              [102.71068945300003, 22.681871990000069],
              [102.71263695800005, 22.681669348000085],
              [102.71337989900006, 22.681438221000057],
              [102.71413136500006, 22.680896418000081],
              [102.71543181600001, 22.679870205000086],
              [102.71604431300007, 22.67951164200008],
              [102.71698118800005, 22.679533774000049],
              [102.71751142400004, 22.679732810000083],
              [102.71869212200004, 22.680631123000069],
              [102.71975261500003, 22.681029195000058],
              [102.721018976, 22.681245609000108],
              [102.72175509400006, 22.681262982000035],
              [102.72216002000009, 22.681148193000041],
              [102.72291826900003, 22.680357832000105],
              [102.72403493600005, 22.6787055],
              [102.72446027700008, 22.677845108000035],
              [102.724825502, 22.676734597000078],
              [102.724895272, 22.674187122000063],
              [102.72504611800005, 22.673568931000077],
              [102.72539261400003, 22.673141886000089],
              [102.72587294600007, 22.672717987000013],
              [102.72681998500009, 22.672367279000035],
              [102.72803639500002, 22.67196073800006],
              [102.72871916400003, 22.67147943500003],
              [102.72941043000003, 22.67068745900005],
              [102.73018050800006, 22.669462116000091],
              [102.73073453700007, 22.668791254],
              [102.73148590400007, 22.668249378000027],
              [102.73249982800012, 22.667900219],
              [102.73337481800003, 22.667734300000042],
              [102.73437175000001, 22.668006470000087],
              [102.73609286900003, 22.668730912000079],
              [102.73835781900004, 22.669157262000091],
              [102.74036868200005, 22.669080196000024],
              [102.74465129100011, 22.669180873000094],
              [102.74645123900009, 22.669471856000101],
              [102.74724744500007, 22.66973926100011],
              [102.74778277800004, 22.669751834000081],
              [102.74832318400007, 22.669578],
              [102.74954969400004, 22.668798527000078],
              [102.75024255800008, 22.667944352000099],
              [102.75177903200003, 22.665617771000065],
              [102.75263786800012, 22.663586145000103],
              [102.75251757800002, 22.663085927000012],
              [102.75137238500004, 22.66088291300008],
              [102.75118349300004, 22.660443225000023],
              [102.75126394200004, 22.659947706000061],
              [102.75147653700004, 22.659517467000079],
              [102.75235993600003, 22.659040791000074],
              [102.75317642600001, 22.658562540000027],
              [102.75379724700012, 22.657893176000066],
              [102.75518117200005, 22.656246886000098],
              [102.75790231000005, 22.652207042000057],
              [102.75934798900002, 22.6507486880001],
              [102.76161176400011, 22.648749897000094],
              [102.76255862000002, 22.648399009000016],
              [102.76396539200007, 22.648369774000088],
              [102.76597266100006, 22.648416767000064],
              [102.76957728200006, 22.64881198700008],
              [102.77078163700006, 22.648840154000105],
              [102.77151932300006, 22.648795223000029],
              [102.77246447500002, 22.648506436000027],
              [102.77503566400004, 22.64750952000005],
              [102.77624676000006, 22.647289110000109],
              [102.77751970100005, 22.647256663000093],
              [102.77892477900005, 22.647289475000051],
              [102.780532271, 22.647264822000054],
              [102.78154262900003, 22.647039698000079],
              [102.78337271400001, 22.64621192200007],
              [102.78494355400004, 22.645067187000031],
              [102.78542873100004, 22.644456727000062],
              [102.78544386800004, 22.643897486000064],
              [102.78518633300006, 22.643518410000048],
              [102.78381997800007, 22.642056458000056],
              [102.78292029800005, 22.640667555000071],
              [102.78092027500004, 22.63788503100006],
              [102.78034506600005, 22.63687675200012],
              [102.78028489400005, 22.636626636000031],
              [102.78030172900003, 22.636005243000042],
              [102.78085211100009, 22.635458476000011],
              [102.78223058500001, 22.633998360000085],
              [102.78313062600006, 22.632900137000078],
              [102.78348869700001, 22.632037986000064],
              [102.7839254460001, 22.630742407000071],
              [102.78416652300005, 22.629255726000075],
              [102.78445936100003, 22.628329862000029],
              [102.78480732600005, 22.627840539000132],
              [102.785422903, 22.627357466000042],
              [102.78623244100001, 22.627127621000028],
              [102.78957752300003, 22.627205603000085],
              [102.79139058600003, 22.626999133000055],
              [102.79241090199999, 22.626401102000045],
              [102.79471911800002, 22.625211254000099],
              [102.79688847500009, 22.624204690000035],
              [102.79816630300004, 22.623985708000035],
              [102.7993671520001, 22.624138004000116],
              [102.80122695900006, 22.624678701000086],
              [102.8040183590001, 22.625427567000052],
              [102.80561560500009, 22.625775582000074],
              [102.80675292700005, 22.62580200300004],
              [102.807965526, 22.625519267000094],
              [102.81006289400008, 22.624697448000042],
              [102.81264528100006, 22.623265061],
              [102.81386120000003, 22.62285801000008],
              [102.81446497100008, 22.622809840000087],
              [102.81518917000004, 22.623261896000102],
              [102.81629471500003, 22.62446895000005],
              [102.81798232800004, 22.626435642000061],
              [102.81850583800011, 22.626883041000042],
              [102.81950602100008, 22.627030576],
              [102.82117856200009, 22.627069324],
              [102.82265541200007, 22.626916987000101],
              [102.823869664, 22.626572021000065],
              [102.82616767500006, 22.625754699000119],
              [102.82948586700007, 22.624339150000061],
              [102.83672586700004, 22.621459651000109],
              [102.83868926500006, 22.620634457000044],
              [102.83978129500008, 22.619851327000084],
              [102.84026789800001, 22.61917856900002],
              [102.8401573830001, 22.618305485000079],
              [102.83966379200004, 22.616739573000096],
              [102.83955329100004, 22.615866492000066],
              [102.83957659000001, 22.614996490000024],
              [102.83993770400005, 22.614009932000023],
              [102.84062498900008, 22.613341797000075],
              [102.84259494, 22.612267986000042],
              [102.84469201600005, 22.61144581800005],
              [102.84630917300009, 22.611047836000076],
              [102.84724571900004, 22.611069425],
              [102.84958377000005, 22.611247666000089],
              [102.85171781800005, 22.611545553000042],
              [102.85298886000007, 22.611574821000069],
              [102.85466459300007, 22.611489026000072],
              [102.85749911900002, 22.610621517000062],
              [102.85912784700005, 22.609788439000056],
              [102.86001406700011, 22.609187000000119],
              [102.86022967700009, 22.608632325000052],
              [102.86033078200002, 22.604841540000017],
              [102.86054969200011, 22.604162572000064],
              [102.86117162200004, 22.603430682000081],
              [102.86227009000007, 22.602398830000055],
              [102.86383514600007, 22.601439869000025],
              [102.86763743600008, 22.599412988000012],
              [102.87076577600001, 22.597557114000018],
              [102.87233570000006, 22.5964116540001],
              [102.87323174600004, 22.595437278000041],
              [102.87427974000006, 22.593782347000065],
              [102.87534424000003, 22.591505955000102],
              [102.8760643570001, 22.589594761000065],
              [102.87615930700005, 22.588539797000081],
              [102.87585460400007, 22.58741349800006],
              [102.87515022800005, 22.58621583500009],
              [102.87436905400008, 22.585389519000017],
              [102.87338888200007, 22.58449645400011],
              [102.87167792200009, 22.583400059000077],
              [102.87075969000004, 22.582694947000093],
              [102.87030968300012, 22.582000587000017],
              [102.87007024900002, 22.58093794200002],
              [102.87006457100009, 22.578636945000063],
              [102.87009269000001, 22.577580433000044],
              [102.87032311300007, 22.576466372000056],
              [102.87074261400005, 22.575791950000031],
              [102.87170214100004, 22.574943366000078],
              [102.8734675740001, 22.57398889400006],
              [102.87537667400005, 22.572664568000086],
              [102.87675067900005, 22.571327967000073],
              [102.87806935100009, 22.569554779],
              [102.87849541499999, 22.5686317380001],
              [102.87857467400006, 22.565648574000093],
              [102.87820476200001, 22.564458528000038],
              [102.87769447600007, 22.563514008000048],
              [102.87685145700004, 22.562499675000055],
              [102.87594983600005, 22.561173061000069],
              [102.87563526500004, 22.560419583000076],
              [102.87566169900001, 22.559425183000045],
              [102.87648571000007, 22.55863562600009],
              [102.87853830400007, 22.55694140700006],
              [102.88360438700002, 22.552642046000045],
              [102.88483619200008, 22.551613032000084],
              [102.88598462900006, 22.55120399500008],
              [102.88793886000011, 22.550689011000102],
              [102.8892226880001, 22.550220861000092],
              [102.89065507900006, 22.54919639900006],
              [102.89373360400003, 22.546654805000088],
              [102.89442868200008, 22.545675645000074],
              [102.89444514200002, 22.545054121000071],
              [102.8943848590001, 22.544803987000073],
              [102.89386138600004, 22.544356696000101],
              [102.89267248500008, 22.543769806000036],
              [102.89168584300006, 22.543125349],
              [102.89076773900004, 22.542420268000015],
              [102.88860706700007, 22.540629512000088],
              [102.887561831, 22.53967273700006],
              [102.88724725, 22.538919250000063],
              [102.88706641800005, 22.53816882200006],
              [102.88721828500007, 22.537488191000051],
              [102.88783660300008, 22.536880410000038],
              [102.89009276800006, 22.535066281000034],
              [102.89344678100011, 22.532219960000077],
              [102.89679412500004, 22.529622196000076],
              [102.89952312100007, 22.527632169000086],
              [102.90231894100003, 22.525643626000047],
              [102.90396481700004, 22.524126357000092],
              [102.90520455200004, 22.522786391000068],
              [102.90632038600009, 22.521070436000024],
              [102.90681963200009, 22.519900141000093],
              [102.90718843100005, 22.518602493000017],
              [102.907110522, 22.516486136000054],
              [102.90697065500007, 22.514181765000082],
              [102.90699199600004, 22.513373726000019],
              [102.90707363500006, 22.512815842000045],
              [102.90763316400007, 22.511895683000112],
              [102.90886293900004, 22.510928608000121],
              [102.91082491300003, 22.510102585000077],
              [102.91446659400006, 22.509003825000029],
              [102.91811152000011, 22.507780681000078],
              [102.91912917500011, 22.507244080000085],
              [102.92009307000011, 22.50620868900004],
              [102.9205889200001, 22.505162649000084],
              [102.92075047500006, 22.504109005000124],
              [102.92060228700005, 22.502115383000017],
              [102.92047046500007, 22.499500165000086],
              [102.92043798500005, 22.498193306000047],
              [102.92060608300001, 22.496890996000026],
              [102.92105633700005, 22.495035343000055],
              [102.92198602500005, 22.492755206000048],
              [102.92514993, 22.48691838300001],
              [102.92598160600008, 22.485817719000067],
              [102.92687686700008, 22.484842897000057],
              [102.92762042700005, 22.484548796000055],
              [102.92875693400002, 22.484574604000059],
              [102.92982391900001, 22.484728727000025],
              [102.93028965000009, 22.484795996000081],
              [102.93274526000006, 22.485535892000108],
              [102.93417262700004, 22.486090053000041],
              [102.93517271800009, 22.486256417000035],
              [102.93672089300003, 22.486147837000111],
              [102.93998862600003, 22.485287946000106],
              [102.94294369500003, 22.484564619000125],
              [102.94488359000003, 22.48424931800006],
              [102.9464279810001, 22.48428426800011],
              [102.94872576500006, 22.485054671000078],
              [102.951938942, 22.486276786000033],
              [102.95301250500009, 22.486588413000064],
              [102.95378658900002, 22.486534062000096],
              [102.95480360100005, 22.486054150000086],
              [102.958282002, 22.483043491000032],
              [102.96018404100008, 22.481218517000023],
              [102.9616774460001, 22.480246416000057],
              [102.96268690600004, 22.480053661000099],
              [102.96415405500002, 22.48008674700008],
              [102.96630491300009, 22.480566286000055],
              [102.96876840200008, 22.480909171000093],
              [102.97085704500006, 22.480812520000093],
              [102.97465571000004, 22.480323300000023],
              [102.97612472300007, 22.480284508000103],
              [102.97750904700004, 22.48053117799999],
              [102.97957899300006, 22.481152451000064],
              [102.98126659000008, 22.481621430000054],
              [102.98250022100009, 22.481720998000029],
              [102.98335149900001, 22.481668270000021],
              [102.98413489000008, 22.481254816],
              [102.98596131400004, 22.47935608100002],
              [102.98850876900005, 22.476467705000047],
              [102.99066261600008, 22.473857835000018],
              [102.99106920900006, 22.473076675000044],
              [102.99124414500005, 22.472290318000084],
              [102.99103673800005, 22.471351698000049],
              [102.98994379200012, 22.468812648000025],
              [102.98883967300006, 22.466704391000022],
              [102.98863414900002, 22.465693956000088],
              [102.98888816800007, 22.464837519000099],
              [102.989368224, 22.464201693000071],
              [102.99031528400003, 22.463432646000037],
              [102.9921097910001, 22.462754443000051],
              [102.99459731900004, 22.462163605000015],
              [102.9970487570001, 22.461972103000072],
              [103.00008565300001, 22.461581122000048],
              [103.00459412500001, 22.461128619000092],
              [103.0061323780001, 22.461011560000088],
              [103.007382863, 22.460550701000059],
              [103.00849675600007, 22.459407707000075],
              [103.01209368600004, 22.453464195],
              [103.01342500600006, 22.452459933000043],
              [103.01732925100008, 22.450602717000031],
              [103.02123820600012, 22.448405240000021],
              [103.02663126400003, 22.444728832000038],
              [103.02787962000012, 22.444403869000062],
              [103.03064394800005, 22.445526343000033],
              [103.03202324900003, 22.446291657000117],
              [103.03384723000005, 22.446654118000083],
              [103.03669630800006, 22.446960983000075],
              [103.04028170300006, 22.446936522],
              [103.04313559000003, 22.446903109000083],
              [103.04664402900006, 22.447149754000058],
              [103.04904853400006, 22.447859303000058],
              [103.05200976400003, 22.450616886000049],
              [103.05388993600008, 22.452204627000057],
              [103.05519914100005, 22.452764769000041],
              [103.05607605500003, 22.452843381000065],
              [103.05894617600009, 22.451653119000078],
              [103.06175634400009, 22.449509451000083],
              [103.06359559800003, 22.448783025000104],
              [103.06652929400012, 22.448273834000076],
              [103.0715646780001, 22.449218702000096],
              [103.07509486400008, 22.450553729000063],
              [103.07683745500002, 22.45152714700005],
              [103.07777252700002, 22.452695070000047],
              [103.07862883600009, 22.459577922000037],
              [103.07857630000009, 22.463387876000063],
              [103.07822358700004, 22.467738620000063],
              [103.07817081000012, 22.46804499200001],
              [103.07782583400008, 22.470047444000052],
              [103.07641126500002, 22.471799773],
              [103.07242072800005, 22.474610023000082],
              [103.06990662000005, 22.476485230000073],
              [103.06923583500006, 22.477361783000035],
              [103.06914284600001, 22.478789630000051],
              [103.06912397100004, 22.480150322000014],
              [103.07183822500009, 22.487589829000051],
              [103.07360598400007, 22.491763676000076],
              [103.07471765900004, 22.49556577600007],
              [103.07813197600009, 22.508867344000102],
              [103.08023291300003, 22.511056736000064],
              [103.08078861200008, 22.511896919000037],
              [103.08125845200006, 22.512327813000077],
              [103.08171165400007, 22.512639414000098],
              [103.08258873700008, 22.512734919000096],
              [103.08385124100009, 22.512749950000099],
              [103.08566639700008, 22.51249935500006],
              [103.08751580600007, 22.512419269000091],
              [103.089635937, 22.512614553000084],
              [103.09069435900008, 22.512831251000073],
              [103.09142064600005, 22.513248137000019],
              [103.09391347400006, 22.515625259000061],
              [103.09641803100008, 22.517151908000073],
              [103.09733013400006, 22.517366830000093],
              [103.09850398600001, 22.51717655900007],
              [103.09990020200007, 22.516784771000076],
              [103.10649204000005, 22.516522212000019],
              [103.10799663800002, 22.516233690000043],
              [103.108691956, 22.516241861000097],
              [103.10963604000004, 22.516797321000048],
              [103.11145009800003, 22.517975394000089],
              [103.11283429300008, 22.518467958000073],
              [103.11333971300006, 22.518644214000091],
              [103.11370937400009, 22.518716378000114],
              [103.11484059600008, 22.51897752200005],
              [103.11619697700004, 22.519341055000019],
              [103.1172732790001, 22.519852091000111],
              [103.11982977200006, 22.521795169000121],
              [103.12095116400012, 22.523184295000043],
              [103.12125105300007, 22.524010161000071],
              [103.12122344900008, 22.526051237000019],
              [103.12163654600008, 22.526634448000046],
              [103.12298333800004, 22.527194541000114],
              [103.12583291, 22.527958921000035],
              [103.12666138700007, 22.528304617000039],
              [103.127721014, 22.528746764000026],
              [103.12974857900006, 22.530403467000056],
              [103.13145582100002, 22.532255808000023],
              [103.13261206100003, 22.533873107000076],
              [103.13471201700006, 22.537744788000055],
              [103.13616463800007, 22.540703426000071],
              [103.13971653400006, 22.543202036000039],
              [103.14407617800001, 22.542209937000024],
              [103.151802929, 22.541264883000075],
              [103.15224536300011, 22.541210759000037],
              [103.15407913300004, 22.540959627000042],
              [103.15561193900004, 22.541317447000075],
              [103.15654917300004, 22.542416949000099],
              [103.15805129600008, 22.545088015000125],
              [103.16051333100003, 22.547157625000033],
              [103.17031860900006, 22.553189657000061],
              [103.17641217800012, 22.557545916000073],
              [103.18117133800007, 22.561115210000082],
              [103.17362155000006, 22.567655512000059],
              [103.17156530300011, 22.569706086000082],
              [103.16999515700005, 22.572023377000058],
              [103.16653441300005, 22.576568896000104],
              [103.16445307400005, 22.579078439000085],
              [103.16268898400001, 22.581194844000052],
              [103.16113003900008, 22.583469342000036],
              [103.15798202700003, 22.588877308000029],
              [103.15624424200004, 22.59287234],
              [103.15593317600006, 22.594229767000051],
              [103.15591505600007, 22.59559056600002],
              [103.15720879100009, 22.598820811000039],
              [103.15777656500008, 22.600188334000038],
              [103.15825284400006, 22.600724010000036],
              [103.162533142, 22.604623849000035],
              [103.16401285600004, 22.607390213000045],
              [103.1641027150001, 22.609982579000039],
              [103.16403413400012, 22.612715412000028],
              [103.16473974700006, 22.615464966000062],
              [103.16876171600003, 22.621883910000037],
              [103.17237706500003, 22.625991411000079],
              [103.17909676500003, 22.62987795300004],
              [103.181693255, 22.631372973000019],
              [103.18274484800006, 22.632690775000071],
              [103.18284576900007, 22.634851505000064],
              [103.18187358600009, 22.639651315],
              [103.18179445600003, 22.642815447000061],
              [103.182522, 22.644701832000088],
              [103.18479101300008, 22.646909204000032],
              [103.18921024500006, 22.649882309000091],
              [103.19134965900007, 22.651079516000024],
              [103.19319371500005, 22.65169475200004],
              [103.19459469100006, 22.651437067000032],
              [103.1989919280001, 22.649085244000041],
              [103.20103384400002, 22.647977879000081],
              [103.20539512000001, 22.647064133000057],
              [103.21082253200009, 22.646892640000083],
              [103.21901964700004, 22.647499742000043],
              [103.22289127100001, 22.647582485000051],
              [103.22489382100005, 22.648056959000051],
              [103.22687143700009, 22.649538167000038],
              [103.22891135000002, 22.654762007000073],
              [103.22943467000006, 22.65865836100005],
              [103.23063805900007, 22.660122981000086],
              [103.233080473, 22.661614003000082],
              [103.24224520300007, 22.666989324000042],
              [103.24628034100006, 22.669881026000027],
              [103.24762106900006, 22.672067885000047],
              [103.24850426200004, 22.673957226000049],
              [103.24927160500002, 22.674261315000074],
              [103.25051070400005, 22.674287631000119],
              [103.25316504400003, 22.67348063400005],
              [103.25644245300003, 22.672542931000095],
              [103.25846304600007, 22.672297997000079],
              [103.26109610600005, 22.672353802000075],
              [103.26646763300006, 22.674482010000041],
              [103.27150831400004, 22.677466421000055],
              [103.28017493500009, 22.684268302000099],
              [103.28174673200002, 22.686531696000053],
              [103.28170797800001, 22.688113620000117],
              [103.28030668400007, 22.694702746000047],
              [103.27985588800007, 22.700448525000105],
              [103.28010226600003, 22.70304358700006],
              [103.28145418000007, 22.704798670000059],
              [103.28449959700006, 22.707021075000085],
              [103.28540443600006, 22.708047303000086],
              [103.28603271100009, 22.708828892],
              [103.28610133500008, 22.709632705000082],
              [103.28608979100001, 22.710249367000031],
              [103.28540730900004, 22.712116691000084],
              [103.28487439800008, 22.714338591000072],
              [103.28479445500004, 22.715686034],
              [103.28496927800003, 22.716860336000089],
              [103.28587776900005, 22.719977014000037],
              [103.28624205100009, 22.721153349000112],
              [103.286293524, 22.722091847000044],
              [103.28624917300006, 22.723117253000069],
              [103.28547019200008, 22.724808922000058],
              [103.28430059900003, 22.727434337000126],
              [103.28351718100005, 22.729477672000087],
              [103.28318166800008, 22.731056848],
              [103.28321407300011, 22.733519295000093],
              [103.28345398300006, 22.737068472000118],
              [103.28375294200002, 22.738419963000034],
              [103.28437717900006, 22.739012887000065],
              [103.28550010300008, 22.740138746000049],
              [103.28744116100009, 22.741507857000087],
              [103.28856267500007, 22.742750933000089],
              [103.28924063100004, 22.74410647800007],
              [103.29023001900001, 22.745817099000121],
              [103.2911359930001, 22.746618190000056],
              [103.29308011200001, 22.747752786000049],
              [103.29514914000005, 22.749005932000088],
              [103.2965248830001, 22.75013442100002],
              [103.29752021000004, 22.751376089000082],
              [103.29788465899999, 22.752552415000068],
              [103.29804510400004, 22.754898984000071],
              [103.29841100900002, 22.755958074000127],
              [103.29903394100005, 22.756668162000082],
              [103.30071908700003, 22.758327510000065],
              [103.30225251600008, 22.759486962000075],
              [103.30394497500005, 22.760560142000053],
              [103.30539275600003, 22.760985869000102],
              [103.30626985600003, 22.761581393000085],
              [103.30757253500008, 22.763529722000087],
              [103.30787464900003, 22.764646736000046],
              [103.30805115200008, 22.765703805000022],
              [103.30797786500004, 22.766523729000099],
              [103.30733171400004, 22.767689309000083],
              [103.30741525900004, 22.768598835000105],
              [103.30802312500006, 22.770539792000122],
              [103.30888514200007, 22.772366211000069],
              [103.30962876900004, 22.773546519000028],
              [103.31598908500004, 22.788122649000094],
              [103.31616926900003, 22.788886643000069],
              [103.31691880100006, 22.789598014000134],
              [103.31760659800003, 22.790191491000122],
              [103.31785211900009, 22.790780297000076],
              [103.31784133200009, 22.791659511000084],
              [103.31750888500002, 22.79300429500006],
              [103.31677789000003, 22.795927671000094],
              [103.31598080300007, 22.799084826000104],
              [103.31582994100003, 22.801076363000035],
              [103.31592461700004, 22.80365671000002],
              [103.3162494540001, 22.805506734000055],
              [103.31710960700003, 22.807508949000074],
              [103.31785635500009, 22.808454776000083],
              [103.31847370300001, 22.809040610000061],
              [103.31873093800007, 22.80928470500001],
              [103.31935062700009, 22.809978294000111],
              [103.31926744300006, 22.810404885000054],
              [103.3183383750001, 22.811381841000063],
              [103.31741013700012, 22.812290767000043],
              [103.31662316300002, 22.81364351900006],
              [103.31649054000006, 22.815479548],
              [103.31684103300002, 22.816810286000063],
              [103.31723906600008, 22.817256838000063],
              [103.31778718000005, 22.817432760000052],
              [103.31863123400008, 22.817407638000105],
              [103.32128302900003, 22.8165509090001],
              [103.32290193900003, 22.816159632000051],
              [103.32407986200006, 22.815831756000101],
              [103.32485679100004, 22.815295510000055],
              [103.32641479299998, 22.813882758000048],
              [103.32749262099999, 22.81273716300003],
              [103.32786812400006, 22.812026551000116],
              [103.32788099200006, 22.810971860000073],
              [103.32821774000004, 22.810430966000084],
              [103.32903503800007, 22.809588864000126],
              [103.33203621000006, 22.807136348000064],
              [103.3330318870001, 22.806704420000031],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 35, code: "AD01", ten_tinh: "Lâm Đồng" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.7017510790001, 12.294297497000082],
              [108.70054735200006, 12.281511057000037],
              [108.70208093300003, 12.263741743000088],
              [108.70235812600005, 12.260045912000093],
              [108.700001329, 12.249111192000049],
              [108.69882764500007, 12.244993896],
              [108.698814027, 12.24101488500011],
              [108.70068509800009, 12.237171743000095],
              [108.70371852600007, 12.234177381000054],
              [108.71152827700011, 12.229319535000103],
              [108.71355188100003, 12.227749530000052],
              [108.71571689200002, 12.225184274000076],
              [108.7175909960001, 12.222335786000114],
              [108.71713173800003, 12.215374184],
              [108.71610930500007, 12.213103976000104],
              [108.71274584600005, 12.204447011000051],
              [108.7107026840001, 12.200332904000087],
              [108.70923974200012, 12.196358959000085],
              [108.7089410450001, 12.193802123000095],
              [108.71009240300006, 12.191524634000038],
              [108.71153354300002, 12.189246158000076],
              [108.71355526000005, 12.187249910000018],
              [108.71557444800005, 12.18454316600006],
              [108.717587592, 12.180131227000055],
              [108.71945074400006, 12.174298808000026],
              [108.72160909900003, 12.170028483000104],
              [108.72435043900001, 12.166750838000072],
              [108.72705052200004, 12.163277456000097],
              [108.72345581100006, 12.15796353800007],
              [108.71525211900003, 12.156459951],
              [108.70959012300007, 12.155975603000078],
              [108.70553245600007, 12.155875515000057],
              [108.70251496100006, 12.15486233900007],
              [108.69786605700008, 12.151353279000075],
              [108.694612578, 12.149090158000051],
              [108.69240511900006, 12.147619408000041],
              [108.69170499900004, 12.146257395000058],
              [108.69251140400006, 12.144776748000117],
              [108.69343370800007, 12.143295719000056],
              [108.69424047500004, 12.141928763000108],
              [108.69446689100003, 12.140336330000066],
              [108.69376214700007, 12.13761005300009],
              [108.69200382400007, 12.131817593000081],
              [108.69072306700006, 12.130116427000102],
              [108.68712381400006, 12.128309136000047],
              [108.68201937500005, 12.126961419000091],
              [108.67679721200007, 12.125045507000019],
              [108.67308377100004, 12.123693112000032],
              [108.67169030700002, 12.1229017290001],
              [108.67157066200008, 12.121765212000033],
              [108.67202904300007, 12.120172109000025],
              [108.67364717000002, 12.11880268000005],
              [108.68451634900006, 12.111150571000094],
              [108.68740458300002, 12.108412672000044],
              [108.6924839390001, 12.102484323000072],
              [108.69537117000007, 12.099518966000032],
              [108.69767694200007, 12.095987080000013],
              [108.69940202500007, 12.092116045000088],
              [108.70020153700005, 12.088702826000118],
              [108.70800797400008, 12.067417885000074],
              [108.70915999600001, 12.065481421000078],
              [108.7098374440001, 12.060363454000099],
              [108.70959189200003, 12.056385435000026],
              [108.70877058000011, 12.053432489000036],
              [108.70690456600008, 12.049914649000113],
              [108.70469103300007, 12.04639797800011],
              [108.70306156200007, 12.044243504000105],
              [108.70178399500007, 12.043338323000084],
              [108.69981323400008, 12.04300382700003],
              [108.69517891100006, 12.043019153000055],
              [108.69320623599999, 12.042116212000041],
              [108.69169355000007, 12.040188631000069],
              [108.69098994800002, 12.037690007000011],
              [108.68504628000002, 12.027251012000038],
              [108.682365428, 12.022371594000079],
              [108.67978997300006, 12.014308923000081],
              [108.67897190500005, 12.012151717000028],
              [108.67907830600006, 12.009309493000051],
              [108.68011215399999, 12.006691642000076],
              [108.68149159200007, 12.003504303000055],
              [108.68240917100006, 12.00077315700012],
              [108.68240233900012, 11.998727047000022],
              [108.68134770700007, 11.995092908000069],
              [108.68052326400007, 11.991003336000093],
              [108.67924046300007, 11.988393007000029],
              [108.67784035700004, 11.985328368000035],
              [108.67597812900006, 11.982606215000134],
              [108.67330594300003, 11.980113999000073],
              [108.67016680500009, 11.97648652800005],
              [108.66819006000004, 11.974105717000059],
              [108.66563093100004, 11.970703700000039],
              [108.66355486700004, 11.968460859000094],
              [108.66341180800002, 11.96824706500008],
              [108.66179314800003, 11.965827996000121],
              [108.66074346100004, 11.963557904000053],
              [108.659577267, 11.961060836000035],
              [108.65875522900002, 11.957539684000016],
              [108.65840047500006, 11.955267430000037],
              [108.65873874400009, 11.952424677000105],
              [108.66127398600005, 11.948552046000048],
              [108.66288456000009, 11.945250647000092],
              [108.66150701100007, 11.913201555000084],
              [108.66045502700007, 11.910135966000011],
              [108.65893996300012, 11.907071840000059],
              [108.65661411400006, 11.90389657800006],
              [108.65493177400003, 11.90139305100004],
              [108.65440375500005, 11.90060728100004],
              [108.65323690800005, 11.897769383000066],
              [108.65288123000002, 11.895156303000041],
              [108.65170972500007, 11.890840853000027],
              [108.65065077900003, 11.88550214800008],
              [108.64935565600008, 11.878686635000067],
              [108.64887348100007, 11.872664273000042],
              [108.64966467300007, 11.866638032000052],
              [108.65103206000005, 11.859814466000083],
              [108.65183255800011, 11.856743296000081],
              [108.65205645100008, 11.854355852000074],
              [108.65262796300003, 11.852081003000057],
              [108.65238703600008, 11.849126738000047],
              [108.6509864770001, 11.845494152000045],
              [108.64819775700002, 11.84209314800002],
              [108.64169396200003, 11.835066594000041],
              [108.63961117400001, 11.833486009000092],
              [108.63856151400003, 11.832689427000085],
              [108.61870240300001, 11.810359580000094],
              [108.61765674800009, 11.80899886600004],
              [108.61718801800004, 11.807068216000081],
              [108.61810831300006, 11.80524715000004],
              [108.62122595000008, 11.802965027000043],
              [108.62561540100009, 11.80033814300012],
              [108.62908247700008, 11.798850414000038],
              [108.63208698100007, 11.797477650000053],
              [108.63613220000011, 11.795874426000095],
              [108.63948518000007, 11.794955104000058],
              [108.6559083540001, 11.792177109000066],
              [108.65891331200005, 11.791031277000029],
              [108.66214892800006, 11.789657382000128],
              [108.6658473630001, 11.788281978000013],
              [108.66850469400006, 11.787023455000066],
              [108.67035014300006, 11.785199266000033],
              [108.67138483600006, 11.783150355000059],
              [108.67149458100008, 11.781331687000128],
              [108.67067641400007, 11.778834117000068],
              [108.66893000400007, 11.775543985000068],
              [108.66706869200007, 11.772481499000074],
              [108.665208545, 11.769759940000045],
              [108.66404413000002, 11.767490753000052],
              [108.66357321400005, 11.76499209300008],
              [108.6633322320001, 11.762038157000097],
              [108.66355330600001, 11.758855489000018],
              [108.66446928100004, 11.755897920000089],
              [108.66515459000007, 11.753168372000021],
              [108.66630264900002, 11.750437367000098],
              [108.66837730300009, 11.748044364000075],
              [108.67114792600009, 11.746217315000072],
              [108.67530676900003, 11.744385789000054],
              [108.67853927600005, 11.742329886000034],
              [108.68200346900007, 11.740386830000114],
              [108.68523622500003, 11.738444474000074],
              [108.68708261400012, 11.737074795000078],
              [108.68869493300012, 11.734910400000052],
              [108.68926454600009, 11.73229485100009],
              [108.69532966600009, 11.712843046000019],
              [108.6970535, 11.709541928000087],
              [108.69877883900003, 11.706695342000016],
              [108.69957335900003, 11.70226097300011],
              [108.69922286600007, 11.701239431000053],
              [108.69806195300004, 11.699993313000066],
              [108.68120820899999, 11.675276744000127],
              [108.67830641700006, 11.672104473000021],
              [108.67552035500002, 11.668931795000058],
              [108.67297090000002, 11.667349154000116],
              [108.66984438500006, 11.666109197000106],
              [108.66683799, 11.666232368000083],
              [108.6639505590001, 11.667377784000086],
              [108.66245047300006, 11.668405171000094],
              [108.66002593700009, 11.669662701000048],
              [108.65852180200008, 11.669440146000019],
              [108.65412177700009, 11.667749424000105],
              [108.64948873400007, 11.665491195000042],
              [108.64647696000002, 11.663909666000015],
              [108.64184480100009, 11.661878520000089],
              [108.63709673500007, 11.65973400800002],
              [108.63257970500007, 11.657475070000077],
              [108.62215517500006, 11.651938394000092],
              [108.619032985, 11.651947594000054],
              [108.61440648600011, 11.65162025600006],
              [108.60954736700005, 11.650838992000077],
              [108.60364757400004, 11.650060602000066],
              [108.59902047800009, 11.64950568000007],
              [108.56119020100002, 11.643246839000108],
              [108.55776479100011, 11.639229729000087],
              [108.55133024100009, 11.631683166000071],
              [108.55097480600008, 11.62850263800008],
              [108.55095981699999, 11.622935145000033],
              [108.55105401600005, 11.614981369000075],
              [108.55104300300003, 11.610891031000035],
              [108.55091944300003, 11.607937224000063],
              [108.55056435100005, 11.604870403000065],
              [108.55067323100008, 11.602370488000078],
              [108.56288060600009, 11.585181833000123],
              [108.5669168310001, 11.581648875000015],
              [108.57348326500002, 11.573564355000075],
              [108.57543652500007, 11.569355263000068],
              [108.57739226800007, 11.566055082000036],
              [108.57934955500009, 11.563322956000109],
              [108.58095938700006, 11.560364566000112],
              [108.58225320300009, 11.556813911000051],
              [108.57753552400001, 11.555375014000012],
              [108.57313448500003, 11.552205888000076],
              [108.569888108, 11.548579113000111],
              [108.56640377800001, 11.54245349600007],
              [108.564079749, 11.5379153110001],
              [108.56174897400007, 11.530877733000098],
              [108.55988434400007, 11.525202253000087],
              [108.5570990250001, 11.520892529000013],
              [108.55444318000008, 11.518601197000054],
              [108.55316067200002, 11.517494684000072],
              [108.54783331600004, 11.51319154500001],
              [108.54204575700007, 11.509571123000038],
              [108.53788213600009, 11.508218532000035],
              [108.53418316700002, 11.507773528000074],
              [108.52933394600007, 11.50937626600008],
              [108.52425527200006, 11.511661102000035],
              [108.5207960540001, 11.514623457000093],
              [108.51687506800005, 11.517814129000035],
              [108.51341449000006, 11.520321958000055],
              [108.50880101700008, 11.523968622000067],
              [108.50487798400012, 11.526477448000081],
              [108.49979750300004, 11.52830731000005],
              [108.49471474400005, 11.529228188000038],
              [108.49055539100007, 11.529692357000075],
              [108.48616218700002, 11.529020900000118],
              [108.47945660600004, 11.527900187000027],
              [108.45909540800004, 11.518402495000133],
              [108.45493518400008, 11.518411527000081],
              [108.44800099000001, 11.518199180000098],
              [108.4422214360001, 11.517529762000043],
              [108.43551647200007, 11.516407666000104],
              [108.42904460500009, 11.516193747000079],
              [108.42465801800004, 11.518474785000075],
              [108.42051039300006, 11.524617934000057],
              [108.41843486600001, 11.526894236000105],
              [108.41635743500008, 11.528261659000092],
              [108.41381633800006, 11.528948326000064],
              [108.38584080700012, 11.524684336000044],
              [108.38006128200003, 11.524013197000025],
              [108.37704864300008, 11.519701525000057],
              [108.37426641800009, 11.514934988000023],
              [108.37125527000008, 11.511304905000051],
              [108.36755334600011, 11.509039281000087],
              [108.36269714700003, 11.507457220000015],
              [108.35876706700002, 11.50678231700004],
              [108.35345152100004, 11.506791310000073],
              [108.34861213000001, 11.514979030000026],
              [108.34677125500011, 11.5197535710001],
              [108.34446841900008, 11.524756093000031],
              [108.33959842600011, 11.526765164000084],
              [108.33360711400007, 11.525909673000088],
              [108.32690319800005, 11.525238537000069],
              [108.30957246900007, 11.527991347000109],
              [108.30425333200006, 11.525954146000037],
              [108.28808814800004, 11.518995960000032],
              [108.28320773100009, 11.516894896000094],
              [108.26840072600002, 11.505326472000108],
              [108.26492850800005, 11.501013880000064],
              [108.26423108600008, 11.497833834000025],
              [108.25704273000004, 11.478530260000051],
              [108.25657572600004, 11.474668391000085],
              [108.25749288400007, 11.468987251000049],
              [108.25979635300006, 11.463304442000053],
              [108.2623313390001, 11.458075763000062],
              [108.26509695000004, 11.452619590000078],
              [108.26786360199999, 11.448072201000057],
              [108.27155187200007, 11.4417060700001],
              [108.27339605700007, 11.440299807000097],
              [108.27518043500001, 11.441212816000055],
              [108.27737333400009, 11.442756520000062],
              [108.279107138, 11.446155056000061],
              [108.28178208900009, 11.448006488000127],
              [108.28476870500002, 11.44784788200006],
              [108.28728154400001, 11.446144056000039],
              [108.2923825940001, 11.440572048000062],
              [108.29685927300005, 11.431812382000127],
              [108.30186481500004, 11.425147012000101],
              [108.30624209500012, 11.416337895000071],
              [108.30200505100001, 11.412016599000106],
              [108.29622240000002, 11.406799624000106],
              [108.28974675100005, 11.40135629900003],
              [108.27609894900007, 11.38706245700005],
              [108.27501722000009, 11.385849785000078],
              [108.27124123500003, 11.381616576000029],
              [108.266617411, 11.378214882000036],
              [108.26291642600002, 11.373903274000073],
              [108.25921415800012, 11.368455764000094],
              [108.25412751500006, 11.363918587000031],
              [108.24857894400007, 11.359154689000126],
              [108.24303223900006, 11.3557536680001],
              [108.2370246360001, 11.353034536000093],
              [108.23147854600005, 11.34986040700001],
              [108.22616281200004, 11.346004403000086],
              [108.20651664200005, 11.328987564000046],
              [108.19195865400008, 11.317643541000065],
              [108.18918410800011, 11.313557288000096],
              [108.18479284200004, 11.308790988000082],
              [108.17994141400008, 11.305615087000119],
              [108.17416797400004, 11.304029890000054],
              [108.16539316900001, 11.302446919000085],
              [108.15800482300003, 11.302225454000057],
              [108.14899917800008, 11.300414832000081],
              [108.14181267800011, 11.297681573000061],
              [108.13583551300009, 11.294972179000061],
              [108.13052343800004, 11.291795487000032],
              [108.1263655650001, 11.288163693000095],
              [108.12359341100006, 11.285212453000069],
              [108.12013050000006, 11.285214460000057],
              [108.11897790799999, 11.288168002000097],
              [108.11736420100006, 11.29225753400007],
              [108.11597975800008, 11.293621182000011],
              [108.11390230700005, 11.294303763000089],
              [108.11274707400004, 11.292714353000031],
              [108.10928100700005, 11.287037534000087],
              [108.10766186400011, 11.281132625000099],
              [108.10647472900011, 11.277197887000055],
              [108.10581184800006, 11.275000722000071],
              [108.10511743100008, 11.271366811000048],
              [108.10275577800007, 11.269819326000084],
              [108.10234637800004, 11.269551060000092],
              [108.09865212400008, 11.267962855000045],
              [108.07487171100007, 11.257751467000075],
              [108.06933002100001, 11.252529256000086],
              [108.06402000600005, 11.249123939000059],
              [108.05871002900008, 11.245264230000094],
              [108.05570824800004, 11.240949547000055],
              [108.05478390700004, 11.236861450000022],
              [108.05478263200011, 11.232091745000082],
              [108.05547362599999, 11.226867636000025],
              [108.05570337100009, 11.223006435000025],
              [108.05524111800003, 11.220508190000087],
              [108.05224041000008, 11.218691965000092],
              [108.04443576700004, 11.21678478400012],
              [108.04558691500007, 11.22861649600013],
              [108.04718205400007, 11.237271611000031],
              [108.04920106100005, 11.242238099000089],
              [108.05116742200003, 11.246713116000072],
              [108.05012343200008, 11.248103430000087],
              [108.04901679500009, 11.249582238000054],
              [108.04532446000012, 11.253217208000075],
              [108.04186306400007, 11.257987769000087],
              [108.04047862, 11.260713676000121],
              [108.03955620400006, 11.265483743000091],
              [108.04117303200007, 11.270253355000113],
              [108.042328287, 11.275250225000109],
              [108.04140562800005, 11.278884678000088],
              [108.03817459800005, 11.283882438000068],
              [108.03378924100012, 11.289788962000012],
              [108.03032722700002, 11.296376749000013],
              [108.02894256300003, 11.300465615000109],
              [108.02940543400004, 11.308188665000072],
              [108.02871355500008, 11.313867597000103],
              [108.02686769200004, 11.32363549200006],
              [108.02594458900005, 11.327724428000073],
              [108.02040295500009, 11.329542299000115],
              [108.0141684710001, 11.331132923],
              [108.00493201900007, 11.333404929000055],
              [108.00134514200002, 11.334599272000021],
              [108.00008280100012, 11.334995106000088],
              [107.99546440000009, 11.33772097600003],
              [107.98599644400008, 11.341127977000019],
              [107.97699012800007, 11.343625959000024],
              [107.96636678700003, 11.347940626000062],
              [107.96267142100008, 11.350438810000083],
              [107.95943798000006, 11.352255527000031],
              [107.95597393500006, 11.352254830000062],
              [107.95112481200003, 11.349982116000133],
              [107.94743020400006, 11.348618251000085],
              [107.94281181000011, 11.347708392000031],
              [107.939116912, 11.347707355000034],
              [107.93634495800008, 11.350205331000039],
              [107.93472718900007, 11.354066640000047],
              [107.93334081700002, 11.356337862000119],
              [107.92872096800005, 11.359289516000091],
              [107.92584512300004, 11.360203623000052],
              [107.92086789399998, 11.361785553000024],
              [107.90423831300009, 11.364731831000084],
              [107.88807077899999, 11.366541201000045],
              [107.86843893400004, 11.36789285600006],
              [107.83448684500004, 11.370140593000054],
              [107.82354682600001, 11.370690673000093],
              [107.81670237200008, 11.371034532000033],
              [107.80168880900007, 11.37238394000004],
              [107.79594437100012, 11.371874890000109],
              [107.79350575100005, 11.371657361000052],
              [107.78875627700009, 11.371235504000053],
              [107.77305189700012, 11.370537633000042],
              [107.76404483700006, 11.370300550000055],
              [107.75278876700006, 11.368872459000022],
              [107.75271808300008, 11.368152557000053],
              [107.75194467600004, 11.367168482000077],
              [107.75039395100006, 11.36684039600002],
              [107.74884463300003, 11.366947788000024],
              [107.74430676200009, 11.367707012000048],
              [107.74109785000007, 11.367593843000099],
              [107.73921723800007, 11.367154556000079],
              [107.73819435100009, 11.366607050000075],
              [107.73700621100001, 11.365513198000075],
              [107.73380315700011, 11.360921086000085],
              [107.73181326400004, 11.359498445000058],
              [107.72904810800004, 11.35862098500011],
              [107.72738804100001, 11.358837319000068],
              [107.725699429, 11.359709024000074],
              [107.72406553200008, 11.361017775000091],
              [107.72251312700007, 11.363419017000085],
              [107.72226723800006, 11.363567952000063],
              [107.72133114600005, 11.360480042000097],
              [107.72156826600005, 11.355937021000083],
              [107.72088009200004, 11.352528621000067],
              [107.71903883200011, 11.347982883],
              [107.71650455600012, 11.3436633810001],
              [107.71327794300004, 11.339115766000061],
              [107.70958770300007, 11.335930457000119],
              [107.70451210000004, 11.332743155000101],
              [107.69735946000002, 11.328871288000107],
              [107.69135854000008, 11.327045308000015],
              [107.68212293300012, 11.3265772400001],
              [107.67219283400006, 11.327243476000069],
              [107.66526262100005, 11.329049824000046],
              [107.65856387800002, 11.332444689000097],
              [107.65833479, 11.332564406000055],
              [107.65162695400009, 11.336069620000087],
              [107.64515850100001, 11.337648927000108],
              [107.64007827000005, 11.337640314000017],
              [107.63153342200003, 11.338079843000058],
              [107.62460415800003, 11.338976235000063],
              [107.615592265, 11.342140232000107],
              [107.60542151300011, 11.347346142000079],
              [107.60056311400002, 11.351880270000068],
              [107.59477669000007, 11.358457024000044],
              [107.591073442, 11.362538877000093],
              [107.58598156900004, 11.367980986000068],
              [107.581584166, 11.372515699000106],
              [107.57880425900002, 11.376599247000028],
              [107.57579326500003, 11.380682324000089],
              [107.573709346, 11.383177023000089],
              [107.56816412600003, 11.384074414000088],
              [107.56470196400008, 11.382931411000106],
              [107.54924193700008, 11.376083643000088],
              [107.54440182500008, 11.371529782000065],
              [107.54215712300007, 11.367187572000057],
              [107.53957213200006, 11.362432486000083],
              [107.53842199900002, 11.360385449000086],
              [107.53542641000006, 11.357425676000064],
              [107.53358198200004, 11.356058595000105],
              [107.53080289300004, 11.359459924000069],
              [107.51830841499999, 11.369427134000086],
              [107.51576038600003, 11.372601679000114],
              [107.51366881500007, 11.378048991000076],
              [107.51181189400009, 11.381906648000049],
              [107.50602889400005, 11.385527958000095],
              [107.50163874100008, 11.386199127000028],
              [107.49816689600006, 11.389144168000074],
              [107.48705380500009, 11.399567571000089],
              [107.48496462300007, 11.403651713000061],
              [107.48495491100006, 11.407513779000043],
              [107.48447633500008, 11.414100920000079],
              [107.48699405800002, 11.423421680000077],
              [107.48698722900008, 11.426147910000049],
              [107.48466846800008, 11.429550071000081],
              [107.48235421400008, 11.4311347170001],
              [107.47772792600003, 11.433395181000082],
              [107.47302573400007, 11.437061779000055],
              [107.46882535200007, 11.435280569000026],
              [107.45722984700006, 11.432120336000116],
              [107.45534352600005, 11.43165341300003],
              [107.454129994, 11.430753488000088],
              [107.45352602400004, 11.429257280000103],
              [107.45383876600006, 11.425969904000064],
              [107.45411388000009, 11.424229157000097],
              [107.45398011100004, 11.422733124000038],
              [107.45257459600001, 11.421474982000033],
              [107.45054131100008, 11.420820585000067],
              [107.4458591100001, 11.420264419000057],
              [107.44280656600003, 11.420141514000102],
              [107.44213862600002, 11.420662502000043],
              [107.44199420400005, 11.423109596000101],
              [107.44240265900001, 11.424878307000073],
              [107.44336775800011, 11.426397846000034],
              [107.44423076100001, 11.427992284000064],
              [107.444228498, 11.428826323000061],
              [107.44399376400003, 11.430095583000098],
              [107.44197086300008, 11.431675771000103],
              [107.43976442900004, 11.432485739000063],
              [107.4375602260001, 11.43247986000002],
              [107.4353597730001, 11.431114240000094],
              [107.43164887300001, 11.427976887000034],
              [107.42903598300009, 11.426338154000069],
              [107.42800655900001, 11.42950913800007],
              [107.42785720700009, 11.431767660000068],
              [107.42741465700007, 11.436566700000037],
              [107.42812200400006, 11.439392316000061],
              [107.42883330000002, 11.440806093000065],
              [107.43097391500004, 11.442647297000084],
              [107.43482883700005, 11.445340211000053],
              [107.43997287800005, 11.447471757000114],
              [107.44254552000011, 11.448325707000071],
              [107.44483093300002, 11.449602420000103],
              [107.44639918400001, 11.451583168000033],
              [107.44682224300003, 11.453843273000073],
              [107.44710378600006, 11.455538267000074],
              [107.44752686200006, 11.457798383000027],
              [107.44999205400002, 11.46233007000008],
              [107.45868217800006, 11.471918722000039],
              [107.460787549, 11.474493141000062],
              [107.46409969200005, 11.477476676],
              [107.46769779600004, 11.479630141000106],
              [107.46859709100002, 11.480260031000087],
              [107.46900035699998, 11.480816685000137],
              [107.46767239100006, 11.481570258000049],
              [107.4662386840001, 11.482696166000038],
              [107.46516173, 11.484175955000085],
              [107.46372614200004, 11.486007818000042],
              [107.46236244100001, 11.487698654000054],
              [107.46107043600006, 11.489319079000014],
              [107.45963682500009, 11.490374356000109],
              [107.45831014900001, 11.491068202000053],
              [107.45605564000007, 11.491918268000044],
              [107.45491096700005, 11.491915306000065],
              [107.453979793, 11.492336477],
              [107.45326211200005, 11.493181782000049],
              [107.45290233100003, 11.49395741800001],
              [107.45217349600003, 11.49896795000007],
              [107.45157513700009, 11.50111473800006],
              [107.45067957400006, 11.502620572000042],
              [107.44946678000008, 11.503685683000063],
              [107.44691445800002, 11.505564212000062],
              [107.44485454100003, 11.506855763000077],
              [107.44198670000006, 11.509036744000054],
              [107.43835840500006, 11.511144321000087],
              [107.4345514190001, 11.513220630000108],
              [107.43236600100002, 11.514427230000111],
              [107.43141391100002, 11.515637143000033],
              [107.43101909700005, 11.516490888000062],
              [107.43073034100004, 11.517407895000085],
              [107.43028040200004, 11.518395085000053],
              [107.42993836900007, 11.519170746000016],
              [107.42891827200003, 11.519990025000125],
              [107.42680093600009, 11.519775093000105],
              [107.425002932, 11.518592530000136],
              [107.42314777300007, 11.516751860000072],
              [107.42129385300007, 11.514487594000043],
              [107.42040301600008, 11.513392005000076],
              [107.41948019200001, 11.512620001000061],
              [107.41872222800001, 11.51245468800006],
              [107.41814983900008, 11.512453107000018],
              [107.41643089000004, 11.513626976000067],
              [107.41284451000004, 11.516723372000024],
              [107.41054758000007, 11.519258543000086],
              [107.40910351400005, 11.523772884000037],
              [107.40880832900005, 11.526878466000044],
              [107.40837248100004, 11.529136455000087],
              [107.40756636400005, 11.529662393000082],
              [107.406219709, 11.529953701000027],
              [107.40338051900001, 11.529281692000025],
              [107.40202290200004, 11.52916165600004],
              [107.39044924500007, 11.528137998000075],
              [107.38917822300006, 11.528134313000036],
              [107.38581597400004, 11.527386773000091],
              [107.38477058400005, 11.526941064000072],
              [107.382085257, 11.524867476000098],
              [107.37865638400007, 11.52146369600011],
              [107.37552509900007, 11.518577195000111],
              [107.37448090300009, 11.517762560000032],
              [107.373813302, 11.516752084000091],
              [107.37166896000005, 11.516039701000034],
              [107.36930650000006, 11.516456225000036],
              [107.36719274200004, 11.517438279000112],
              [107.36547202500006, 11.518562692000065],
              [107.36532629500007, 11.519409451000049],
              [107.36607884200008, 11.520347079000091],
              [107.36759455100008, 11.521097794000033],
              [107.37157246100006, 11.523348170000068],
              [107.37279312600006, 11.524508164000034],
              [107.37425583600003, 11.525853917000093],
              [107.37531763600005, 11.527911352000062],
              [107.37630433400005, 11.532856326000028],
              [107.37714281600007, 11.539495327000092],
              [107.37727562500004, 11.542884608000035],
              [107.37698378800006, 11.544719392000056],
              [107.37642581200008, 11.546462578000048],
              [107.37626130400005, 11.546976520000028],
              [107.37540344500005, 11.54839266800005],
              [107.37468006200005, 11.549231102000055],
              [107.37339036, 11.549376165000067],
              [107.37152961100006, 11.549786544000094],
              [107.36902287500004, 11.550202571000055],
              [107.36731285200004, 11.550850347000095],
              [107.36541274900003, 11.551590813000077],
              [107.36078121600006, 11.554272752000115],
              [107.35920063100012, 11.556244831000038],
              [107.35905396400005, 11.557374034000121],
              [107.35976651700004, 11.558364652000106],
              [107.36176681799999, 11.559500378000019],
              [107.36405383700004, 11.560495742000041],
              [107.3651945030001, 11.561911260000011],
              [107.365191, 11.563040907000042],
              [107.36517639900011, 11.566607146000061],
              [107.36526174700006, 11.569685468000033],
              [107.36467557300006, 11.575093657000044],
              [107.36266696900005, 11.580124479000096],
              [107.361697249, 11.581811140000029],
              [107.360119109, 11.582936035000023],
              [107.35711301400005, 11.582926878000064],
              [107.35654176000003, 11.582501500000129],
              [107.35639373700005, 11.582061128000108],
              [107.35582382000005, 11.581212120000115],
              [107.3549871700001, 11.576142285000033],
              [107.35457466800008, 11.570775072000037],
              [107.35416262300001, 11.565266685000084],
              [107.35345810100006, 11.561734335000027],
              [107.35203437900003, 11.559329438000086],
              [107.35046532000007, 11.557630121000082],
              [107.34917055600005, 11.556577971000069],
              [107.34690126200005, 11.554765159000089],
              [107.3461848000001, 11.553677449000064],
              [107.34575875200002, 11.552390932000021],
              [107.34568339500001, 11.549105330000129],
              [107.34563593600006, 11.546036326000129],
              [107.3456395470001, 11.5449067040001],
              [107.34435418000007, 11.544055479000102],
              [107.34149177800002, 11.544046545000073],
              [107.33893896200001, 11.544543445000064],
              [107.33690828800003, 11.545161799000068],
              [107.33504083700007, 11.547273960000036],
              [107.33478667800009, 11.547771465000077],
              [107.33461245200006, 11.548112517000051],
              [107.33316829800003, 11.55093937],
              [107.33207864700002, 11.554043577000071],
              [107.33128394500008, 11.554920002000111],
              [107.3295505200001, 11.555305130000091],
              [107.32593775300005, 11.555293594000114],
              [107.32059398100003, 11.554934603000076],
              [107.31440667800004, 11.554550448000088],
              [107.31168774300005, 11.554400375000016],
              [107.31025599000007, 11.554536890000032],
              [107.30853084200007, 11.55679052700007],
              [107.3029161270001, 11.566374109000053],
              [107.29988129900009, 11.574836568000089],
              [107.2979674540001, 11.579843161],
              [107.29619119300003, 11.584706833000078],
              [107.29618289700005, 11.58711960900005],
              [107.29640126000001, 11.587816353000056],
              [107.29882222000003, 11.59149595200004],
              [107.30281174300008, 11.597016511000023],
              [107.30494950100004, 11.599847857000077],
              [107.30519616900001, 11.600978383],
              [107.30522485200008, 11.604261720000062],
              [107.30486094800008, 11.606025712000088],
              [107.30449485400007, 11.610368520000057],
              [107.30374925800002, 11.614535155000015],
              [107.30260603000002, 11.618073342000022],
              [107.30160570100003, 11.619680619000048],
              [107.29868140700002, 11.62213921100008],
              [107.28818961600003, 11.630311769000022],
              [107.28615485900008, 11.632017753000044],
              [107.28479143200006, 11.632931060000116],
              [107.28373074600005, 11.63341487700009],
              [107.27783913300001, 11.63516685300001],
              [107.27482978100005, 11.635862613000112],
              [107.27131825300003, 11.636839040000078],
              [107.26930989200004, 11.637891234000096],
              [107.26837439000009, 11.639229581000075],
              [107.26750996400006, 11.640709406000036],
              [107.26707484300003, 11.642261329000101],
              [107.26692684700004, 11.64360241500013],
              [107.26713733500006, 11.644803535000035],
              [107.26784797900005, 11.646288839000094],
              [107.26970164600004, 11.648484230000109],
              [107.27094880200011, 11.650112615000106],
              [107.27123217500011, 11.650960936000041],
              [107.27065385400005, 11.652512390000068],
              [107.26878273000003, 11.655189151000032],
              [107.26734619900002, 11.656455168000067],
              [107.26641876400001, 11.657234074000121],
              [107.26433040600006, 11.658845470000044],
              [107.26403993900001, 11.659974252],
              [107.26403052700003, 11.661330300000115],
              [107.26435913400005, 11.662357589000026],
              [107.26645258300006, 11.666055361000092],
              [107.27030149900003, 11.671011647000016],
              [107.27683195100008, 11.681435567000074],
              [107.28174883500003, 11.685969123000104],
              [107.28367700800004, 11.688755228000057],
              [107.28401842000005, 11.691883383000066],
              [107.28400139800006, 11.696747563000097],
              [107.28292420800003, 11.702476788000114],
              [107.28213144500006, 11.703892641000023],
              [107.28362862000003, 11.70947195000007],
              [107.28431663500004, 11.71752462400004],
              [107.28401186100004, 11.722749284000054],
              [107.28471327900007, 11.726988745000087],
              [107.286708893, 11.729820245000065],
              [107.29869813300007, 11.739519429000021],
              [107.30018816900008, 11.740628936000073],
              [107.30185481200004, 11.741170005000077],
              [107.30701208300007, 11.741187033000065],
              [107.311738636, 11.741485002000028],
              [107.31417070700006, 11.742481611000064],
              [107.31731438300002, 11.744892879000089],
              [107.32202792900001, 11.749145290000039],
              [107.32503133900011, 11.750708575000067],
              [107.32631978500004, 11.750995187000067],
              [107.32746637000005, 11.750857607000052],
              [107.33062419700011, 11.749031544000042],
              [107.33363318300003, 11.748899839000069],
              [107.34465675300005, 11.751335503000069],
              [107.35108140300005, 11.754534183000052],
              [107.35538204000009, 11.757583191],
              [107.35995516500002, 11.761269422000085],
              [107.36117156800003, 11.762775123000056],
              [107.36266677200003, 11.764667456000042],
              [107.366093015, 11.768574434000099],
              [107.36784615600003, 11.769932513000112],
              [107.37024030000005, 11.771187412000115],
              [107.37292418400007, 11.77204287200002],
              [107.38037150900004, 11.773124232000052],
              [107.38216294100003, 11.772988246000081],
              [107.38453003700005, 11.772006470000028],
              [107.38668600300008, 11.769752861000081],
              [107.38787163300005, 11.768555749000063],
              [107.38890201700012, 11.767805685000086],
              [107.39024071800004, 11.767373391000111],
              [107.39345940099999, 11.767103808000099],
              [107.39407545100008, 11.76704597100011],
              [107.39531970200008, 11.766600740000042],
              [107.39560699100005, 11.766312674000099],
              [107.39668985700003, 11.765352376000047],
              [107.3972897180001, 11.764543214000065],
              [107.39776440200004, 11.763587018000086],
              [107.40076524100007, 11.758255280000094],
              [107.40191553300004, 11.75684613800003],
              [107.40601377800002, 11.754096239000054],
              [107.41185398700007, 11.750994376000088],
              [107.41247927600003, 11.750703435000085],
              [107.41339431000006, 11.750410032000056],
              [107.41426146700005, 11.750164654000093],
              [107.41469609100004, 11.750160975000055],
              [107.415179815, 11.750252107000046],
              [107.41609983400011, 11.750529978000122],
              [107.41717011600011, 11.751425487000054],
              [107.42997547200011, 11.766027981000081],
              [107.4306095530001, 11.766736713000101],
              [107.43114286400007, 11.766970203000058],
              [107.4315779140001, 11.767014100000049],
              [107.43239848400005, 11.76695949000006],
              [107.43283186700002, 11.766812968000105],
              [107.43350503100004, 11.766473953000032],
              [107.43436926800004, 11.765895264000024],
              [107.43999347199998, 11.762895415000061],
              [107.44042599900007, 11.762653665000043],
              [107.44376546400007, 11.76072070300007],
              [107.44636142500009, 11.759365397000108],
              [107.44785507400005, 11.758971717000042],
              [107.44988118400008, 11.758716265000134],
              [107.45171454600008, 11.758510073000126],
              [107.45272865600005, 11.758501353000026],
              [107.45350257100004, 11.758637529000014],
              [107.45531495500008, 11.759119838000041],
              [107.4573299730001, 11.759950734000045],
              [107.46176555700001, 11.762061413000067],
              [107.463207036, 11.762897223000033],
              [107.46453683500008, 11.764073303000087],
              [107.47163348400008, 11.771763539000089],
              [107.47280655000006, 11.773324412000095],
              [107.47545910400007, 11.778300181000121],
              [107.47605056200005, 11.779628053000033],
              [107.47611079200007, 11.780960542000061],
              [107.47596310100002, 11.783342207000123],
              [107.47569210600007, 11.785439300000087],
              [107.47570149100004, 11.786486594000054],
              [107.47580661400004, 11.787437828000067],
              [107.47620193900006, 11.788434156000081],
              [107.476840022, 11.7895711780001],
              [107.47815895000006, 11.79122595600006],
              [107.47906097000001, 11.792170242000031],
              [107.48164502000003, 11.794861326000026],
              [107.48324564500004, 11.795609069000077],
              [107.48944427000008, 11.79741154700009],
              [107.49239849600008, 11.798290192000048],
              [107.50631870800007, 11.799375737000071],
              [107.51168585200006, 11.799870155000034],
              [107.514652208, 11.800143355000079],
              [107.51647232900004, 11.800553611000096],
              [107.52591409200002, 11.800469842],
              [107.52727360000006, 11.80036508400004],
              [107.52912430200007, 11.800032361000133],
              [107.53247175600011, 11.799268950000029],
              [107.53399683700007, 11.798794193000093],
              [107.53526235200007, 11.798101528000069],
              [107.53778831500009, 11.796300567000094],
              [107.53928753300005, 11.794638008000023],
              [107.53992381500002, 11.793995996000104],
              [107.54178008600005, 11.791027875000013],
              [107.54216336500005, 11.790691213000047],
              [107.54259668300003, 11.790544524000078],
              [107.54394844600004, 11.790484829000112],
              [107.5450117620001, 11.790570531000098],
              [107.54593064600002, 11.790705120000043],
              [107.54723806700009, 11.79107425300012],
              [107.54961276900011, 11.791957468000101],
              [107.55102337500003, 11.793039738000031],
              [107.5522403960001, 11.794076132000074],
              [107.55326687700003, 11.795399857000087],
              [107.55380213700009, 11.797141088],
              [107.55600139700007, 11.801230694000074],
              [107.5574692000001, 11.803264505000042],
              [107.56142661000007, 11.808132135000092],
              [107.57473114400008, 11.820735479000087],
              [107.57712418300011, 11.822309070000079],
              [107.57793623900005, 11.822692435000091],
              [107.58224898500004, 11.824176545000052],
              [107.5894484250001, 11.82701473300005],
              [107.58978739499999, 11.827106837000121],
              [107.59455242000007, 11.830443131000033],
              [107.59596383900006, 11.831572700000057],
              [107.59844727200002, 11.83369209300009],
              [107.60083640199998, 11.83605034800008],
              [107.60196212700005, 11.837610918000061],
              [107.60267057300004, 11.838461269000076],
              [107.60301227600007, 11.838838951000062],
              [107.60335307100003, 11.839121439000076],
              [107.60350022300004, 11.839358102000029],
              [107.60490677500006, 11.84033460900009],
              [107.60669755800009, 11.84076172400008],
              [107.60831952400008, 11.840741870000036],
              [107.6091151090001, 11.840591731000048],
              [107.60983552900011, 11.840156666000022],
              [107.6105536900001, 11.839483615000022],
              [107.611305966, 11.838995325000033],
              [107.61194847100005, 11.838851920000069],
              [107.61296320300008, 11.838890168000125],
              [107.61426817500005, 11.838973326000078],
              [107.61533255700002, 11.83915392600001],
              [107.61615634300004, 11.839431935],
              [107.61664250400004, 11.839760651000029],
              [107.61715644500002, 11.840469949000097],
              [107.61760155800008, 11.841560695000018],
              [107.61959906700005, 11.846438790000134],
              [107.62349631000002, 11.854035168000097],
              [107.62462806700005, 11.856622409000027],
              [107.62420537700007, 11.859582313000059],
              [107.62420202200006, 11.861407721000104],
              [107.62527826800002, 11.863767475000129],
              [107.62666974600005, 11.865468323000082],
              [107.62730452300008, 11.866176464000054],
              [107.62847063100001, 11.86687965000006],
              [107.62968367600011, 11.867439595000027],
              [107.63104210800006, 11.868045775],
              [107.64523636300007, 11.874720330000075],
              [107.65110458300003, 11.877100780000125],
              [107.65491204800003, 11.878600244000024],
              [107.65638189600006, 11.879987769000076],
              [107.65716274300009, 11.881605768000012],
              [107.65943375000006, 11.88663010300006],
              [107.66028926600005, 11.887425054000021],
              [107.66269700700005, 11.887202394000072],
              [107.66709243300002, 11.886795841],
              [107.66805621100009, 11.886548724000077],
              [107.66926337000005, 11.886489710000079],
              [107.67032700700004, 11.886574852000107],
              [107.67119789000007, 11.88670941400005],
              [107.67294768000008, 11.887147329000046],
              [107.67377443400009, 11.887710705000119],
              [107.675123845, 11.889169511000118],
              [107.67589699000003, 11.89052053400008],
              [107.67604563500007, 11.890899932000119],
              [107.67604982700007, 11.891328291000034],
              [107.67581201400002, 11.891711349000021],
              [107.67518962500003, 11.892288465000076],
              [107.67293321300012, 11.893737867000096],
              [107.67072321200006, 11.894996418000046],
              [107.67005295900002, 11.895621570000079],
              [107.66976823000009, 11.896147872000105],
              [107.66980312200005, 11.898585481000088],
              [107.66989249900007, 11.900680141000029],
              [107.67034295300003, 11.908967939000091],
              [107.67052599400007, 11.910516372000044],
              [107.67064819200006, 11.911968381000102],
              [107.6711498600001, 11.91393742500013],
              [107.67158931100003, 11.91440925900009],
              [107.671930262, 11.914691625],
              [107.67236737700003, 11.914925481000081],
              [107.67280403600009, 11.915111735000037],
              [107.67430224300006, 11.915768337000097],
              [107.68650533000007, 11.920455372000037],
              [107.68737772200004, 11.920732639000063],
              [107.68771636700009, 11.920777003000092],
              [107.68827216700006, 11.920795489000023],
              [107.68892223900012, 11.920575088000083],
              [107.69550628400007, 11.918274145000039],
              [107.69758793500009, 11.917445798000069],
              [107.69826144200005, 11.917153741000062],
              [107.6987914260001, 11.917005860000083],
              [107.69961268500002, 11.916997990000054],
              [107.70019333000002, 11.917087617],
              [107.70087577599999, 11.917699868000041],
              [107.70158897400003, 11.918978210000118],
              [107.70267156900007, 11.920967007000092],
              [107.7036959710001, 11.921956753000094],
              [107.7043291950001, 11.922474259000102],
              [107.70510640000001, 11.922895181000024],
              [107.70583340200002, 11.923126188000042],
              [107.70665607900006, 11.923261076000101],
              [107.70752613900002, 11.92330030000006],
              [107.70868462600004, 11.923193959000054],
              [107.70940737900007, 11.922996607000067],
              [107.72218165400008, 11.917996114000038],
              [107.72527629800007, 11.916860381000037],
              [107.72664588700006, 11.91559374800004],
              [107.72790447800001, 11.914429750000098],
              [107.73014860000006, 11.910477308000099],
              [107.73154262800003, 11.907132310000089],
              [107.7318536240001, 11.905459317000043],
              [107.73185601900012, 11.903633814000079],
              [107.73124042700007, 11.90211178700009],
              [107.72908067500008, 11.900739893],
              [107.72722792600008, 11.900737503000068],
              [107.72413897400003, 11.901494113000053],
              [107.72213181600004, 11.901491476000013],
              [107.72151505900008, 11.900882163000047],
              [107.7208991370001, 11.899664350000092],
              [107.72136460700007, 11.897991592000066],
              [107.72461712600007, 11.890389682000089],
              [107.727092817, 11.886285595000066],
              [107.72941221900007, 11.883550388000062],
              [107.73022410200009, 11.882417442000113],
              [107.730470978, 11.881633315000071],
              [107.73034910400004, 11.88083977500005],
              [107.72912155100008, 11.879579978],
              [107.72772028300005, 11.878832451000052],
              [107.72432558000001, 11.87761107200007],
              [107.72355512300004, 11.876545214000076],
              [107.72371382200002, 11.873350901000011],
              [107.72403023900009, 11.872211749000082],
              [107.72464294100007, 11.871222440000027],
              [107.72618750000007, 11.870615965000029],
              [107.72834852100007, 11.870770885000058],
              [107.73050633700009, 11.873359687000027],
              [107.7318945220001, 11.874274177000109],
              [107.73328371000011, 11.874428064000051],
              [107.73558226600005, 11.873916109000058],
              [107.7427073580001, 11.869115585000054],
              [107.7521301470001, 11.864106963000063],
              [107.756146814, 11.86152563700009],
              [107.75877265900002, 11.860159604000041],
              [107.76387040500001, 11.856970918000085],
              [107.76541543900004, 11.85575570800008],
              [107.76634247, 11.854996150000117],
              [107.76727020500002, 11.853628132000081],
              [107.76866202499998, 11.851347917000064],
              [107.76928049800009, 11.85043590000001],
              [107.770361511, 11.849980738000097],
              [107.77221381100006, 11.849982748000086],
              [107.77421894200005, 11.85135394300003],
              [107.779386023, 11.85501024300004],
              [107.78331870700005, 11.858360860000071],
              [107.78547820500003, 11.859884243000083],
              [107.78871846099999, 11.861256570000121],
              [107.7914967400001, 11.86156357200012],
              [107.79674595900009, 11.860808115000053],
              [107.80353792400003, 11.860966669000026],
              [107.808776075, 11.86205840800003],
              [107.81233510800004, 11.862800109000114],
              [107.81758278900001, 11.863717425000074],
              [107.82082412100006, 11.864176555000048],
              [107.82174980000009, 11.864785813000099],
              [107.82236516000002, 11.867220230000084],
              [107.82305588100006, 11.871784424000074],
              [107.82352829500007, 11.873332192000062],
              [107.82414448800007, 11.874853907000025],
              [107.82575328500005, 11.876654476000057],
              [107.82727627400003, 11.877571313000066],
              [107.8285309450001, 11.878025835000104],
              [107.83192690300007, 11.878484937000076],
              [107.83377946100005, 11.878486406000109],
              [107.83532398700004, 11.877574894000096],
              [107.83640645700007, 11.875293935000018],
              [107.83748975900008, 11.871948135000119],
              [107.83841708600008, 11.870579768000054],
              [107.84068057800003, 11.869918573000058],
              [107.84366624700007, 11.86997524900004],
              [107.84639553000002, 11.870767588000051],
              [107.85081928200003, 11.872460305000041],
              [107.85277205900005, 11.873024197000069],
              [107.86018145200008, 11.873941962000066],
              [107.86666504200008, 11.874250403000095],
              [107.87392053700007, 11.874559108000058],
              [107.88183219800001, 11.874791934000049],
              [107.88441822000001, 11.874413011000049],
              [107.88596215100004, 11.874109612000082],
              [107.88993816400004, 11.872590581000074],
              [107.89260175400007, 11.871374944000122],
              [107.89507275800007, 11.869398642000116],
              [107.89584515800003, 11.868334187000061],
              [107.89677339200009, 11.864379560000058],
              [107.89723848100002, 11.860424730000128],
              [107.89832108600007, 11.856318087000071],
              [107.89878530800011, 11.854036566000035],
              [107.89924928400004, 11.85221139400001],
              [107.89909590600006, 11.850233809000075],
              [107.89801607900006, 11.848864254000089],
              [107.89678168500006, 11.847950960000018],
              [107.89431253900005, 11.846884935000011],
              [107.8904540500001, 11.846122385000067],
              [107.88968252800004, 11.845665642000112],
              [107.88875681500008, 11.844904577000113],
              [107.88829458000004, 11.843383195000088],
              [107.88845150100005, 11.838667737000018],
              [107.88844426000007, 11.832217714000047],
              [107.88707026000007, 11.824368441000031],
              [107.88707143600008, 11.822238890000046],
              [107.88861612800008, 11.819805944000093],
              [107.89587437100005, 11.810987318000075],
              [107.89741872100011, 11.808858557000066],
              [107.90509888600008, 11.803690501000029],
              [107.90949802700008, 11.801562937000119],
              [107.91648409500009, 11.798681972000045],
              [107.91910782200002, 11.797922468000086],
              [107.92122789200002, 11.797841044000011],
              [107.92253942700009, 11.798297844000059],
              [107.92315647300006, 11.798906496000059],
              [107.92361817500009, 11.802253012000017],
              [107.9238105360001, 11.803698088000123],
              [107.92458142500001, 11.805675758000065],
              [107.92612395200011, 11.807653711000031],
              [107.92874691000006, 11.809327800000078],
              [107.933376084, 11.811306729000124],
              [107.93677098900007, 11.812372532000024],
              [107.93754231500003, 11.813437529000035],
              [107.93800479300009, 11.815110863000109],
              [107.93785005000008, 11.816479807000045],
              [107.93769538900008, 11.817544525000033],
              [107.93784926400011, 11.819065670000038],
              [107.93858191300006, 11.820510938000071],
              [107.93985475600005, 11.821956343000027],
              [107.942632352, 11.823630341000067],
              [107.94510753800007, 11.824095682000022],
              [107.94587328800002, 11.824239642000032],
              [107.94795708400004, 11.823327510000057],
              [107.95351386599999, 11.820590818000108],
              [107.95598356000005, 11.819070247000061],
              [107.95690986400005, 11.817701444000049],
              [107.95706451200006, 11.81618037900005],
              [107.95644754000008, 11.814507053000066],
              [107.95629352900004, 11.812985930000062],
              [107.95660247900003, 11.81161701000004],
              [107.96381874400006, 11.804089063000054],
              [107.96798612500008, 11.799906788000046],
              [107.97157444400005, 11.796865149],
              [107.97234621200006, 11.795496303000073],
              [107.97234650899999, 11.793366825000094],
              [107.9721481240001, 11.791582763000095],
              [107.97211982600007, 11.791328318000092],
              [107.97377352200003, 11.791298720000041],
              [107.97566686, 11.791264820000078],
              [107.97821244900004, 11.792856142000028],
              [107.97983219600003, 11.795811092000095],
              [107.98168349000009, 11.798084196000056],
              [107.98399768200008, 11.800811904000062],
              [107.98839500300002, 11.802403244000102],
              [107.99464397100006, 11.804449149000094],
              [107.9997358050001, 11.806949466000026],
              [108.00320757500003, 11.808313214000064],
              [108.00714225600007, 11.808313117000056],
              [108.009919655, 11.807858414000092],
              [108.01431722800007, 11.807858161000071],
              [108.01709471900001, 11.808767137000052],
              [108.02010382000003, 11.811039832000068],
              [108.02079848100004, 11.813994627000033],
              [108.02195609000009, 11.816949383000084],
              [108.02334514500008, 11.81944952000009],
              [108.02681746400006, 11.822176695000051],
              [108.0337621250001, 11.82603980000003],
              [108.04232766300005, 11.831266185000098],
              [108.04996759000007, 11.836037869000025],
              [108.05552404000008, 11.839218729000043],
              [108.05876485200008, 11.839217850000059],
              [108.061773628, 11.837398559000077],
              [108.06478221700006, 11.835124615000046],
              [108.06779290500003, 11.839442439000079],
              [108.07312002400008, 11.847623653000115],
              [108.08169028700007, 11.859895143000097],
              [108.09049290000009, 11.871711736000099],
              [108.09836961500007, 11.881937347000092],
              [108.09884894000002, 11.882651906000062],
              [108.10416239100002, 11.890572604000036],
              [108.10833393200002, 11.897617386000102],
              [108.10949352400004, 11.901026593],
              [108.1090314960001, 11.903072724000106],
              [108.10694830100005, 11.904665036000049],
              [108.10370772100005, 11.907167200000044],
              [108.09930878600005, 11.908760579000075],
              [108.09375173600007, 11.909899733000094],
              [108.08865796400005, 11.911493203000044],
              [108.0825086940001, 11.913084801000105],
              [108.08302132900006, 11.916176269000099],
              [108.08274308800004, 11.918798523000083],
              [108.08115915300009, 11.923790126000087],
              [108.07781069600006, 11.930876433000035],
              [108.07491352300006, 11.936098073000066],
              [108.070119909, 11.94331172400007],
              [108.06764377600004, 11.947291043000073],
              [108.06564567900008, 11.949260657000059],
              [108.06404680700004, 11.949654943000086],
              [108.06324726800005, 11.949523920000081],
              [108.062047546, 11.947949088000074],
              [108.06124750300006, 11.946111607000097],
              [108.06044755200006, 11.944536665000081],
              [108.05938142200003, 11.944011911000095],
              [108.05831545600003, 11.94401220900003],
              [108.05685000200002, 11.944931459000061],
              [108.05058815000007, 11.947952149000061],
              [108.04672447500005, 11.950315820000055],
              [108.04552548200004, 11.951497469000037],
              [108.04552571500007, 11.952547602000061],
              [108.04645940100005, 11.956616647000116],
              [108.04646035600004, 11.960817191000038],
              [108.04446364500004, 11.970662711000022],
              [108.04350194800004, 11.975219467000018],
              [108.04233297500005, 11.978145508000091],
              [108.03743197000007, 11.98491384800011],
              [108.03473807700003, 11.986416931000042],
              [108.03152226700004, 11.987616225000062],
              [108.02940749900004, 11.989043167000089],
              [108.02647594500004, 11.992981703000092],
              [108.02544140500007, 11.994655232000094],
              [108.02501632600007, 11.996046460000096],
              [108.02461970800002, 11.997933095000056],
              [108.02426701700006, 12.000000174000112],
              [108.02281390700009, 12.003614406000017],
              [108.02223327800002, 12.010869091000041],
              [108.02223361000004, 12.013923690000093],
              [108.02029576700002, 12.017169405000059],
              [108.01680735700005, 12.021369821000059],
              [108.01351250400009, 12.023661033000097],
              [108.01021751900005, 12.024615791000047],
              [108.00779475200005, 12.026429585000107],
              [108.00643802600007, 12.028529705000055],
              [108.00643807400007, 12.030057034000039],
              [108.00663198600003, 12.032538939000046],
              [108.00895853900006, 12.044948525000086],
              [108.00954032300007, 12.05029424900008],
              [108.00915271700006, 12.052394397000031],
              [108.00779578700005, 12.053539983000048],
              [108.00546954400005, 12.053730975000041],
              [108.00353099400004, 12.05334916900007],
              [108.00101089, 12.052394593000047],
              [107.9971338340001, 12.052012739000071],
              [107.99228751400004, 12.052203536000137],
              [107.9853087110001, 12.053539615000107],
              [107.97794203400005, 12.055639102],
              [107.9732892060001, 12.057738708000086],
              [107.96978196200007, 12.060205221000063],
              [107.967860585, 12.061556401000038],
              [107.96561123500004, 12.063278796000086],
              [107.96437060600007, 12.064228761000082],
              [107.96059762400009, 12.06728163100008],
              [107.96149142400006, 12.068573160000094],
              [107.96165564100005, 12.068810453000099],
              [107.96211683000011, 12.069298461000065],
              [107.96661912800005, 12.074062406000062],
              [107.9668892, 12.07434816400008],
              [107.97076624000007, 12.077403553000108],
              [107.97386797000011, 12.079695069000083],
              [107.97658207500007, 12.081413738],
              [107.978326779, 12.083514118000059],
              [107.97968359900008, 12.087141888000106],
              [107.98084591600006, 12.097834049000065],
              [107.98103912000006, 12.105280385000048],
              [107.98171739900005, 12.109576433000056],
              [107.98268654200004, 12.114158921000049],
              [107.98423755800012, 12.117786791000105],
              [107.98617651500005, 12.120650956000121],
              [107.98811560500009, 12.122178559000039],
              [107.99373921800007, 12.123706288000056],
              [107.99722978800006, 12.12408824000004],
              [107.99936291900006, 12.124088257000041],
              [108.00285348900005, 12.123897296000051],
              [108.00576230200002, 12.123706302000057],
              [108.00808936400009, 12.124088103000117],
              [108.01080431700011, 12.125233595000038],
              [108.01410115500005, 12.127524640000063],
              [108.01662251100002, 12.131725073000064],
              [108.01836826200005, 12.136116509000059],
              [108.01953204300007, 12.138025789000027],
              [108.02282921900012, 12.14069861400011],
              [108.02796897600004, 12.143848512000053],
              [108.03087821400004, 12.144802806000053],
              [108.03262371200006, 12.144993482000109],
              [108.03533881599999, 12.144611168],
              [108.03921718700008, 12.142319200000083],
              [108.04348343300002, 12.140408962000036],
              [108.04774985800006, 12.139644281000093],
              [108.05667047900008, 12.138114560000089],
              [108.06268180000008, 12.136012538000072],
              [108.06617202800003, 12.134293020000085],
              [108.0708262310001, 12.133909636000071],
              [108.07470511900004, 12.134672052],
              [108.07761458900008, 12.136007574000105],
              [108.08014526800004, 12.138892786000087],
              [108.08266042400004, 12.137963061000077],
              [108.08564996600003, 12.136412393000027],
              [108.08801048800004, 12.136101532000064],
              [108.0902139020001, 12.136410491000047],
              [108.09383421500006, 12.137803438000015],
              [108.09777014800002, 12.140900608000036],
              [108.10091914000007, 12.143688201000112],
              [108.10391050900004, 12.145856030000067],
              [108.10642899000005, 12.146474569000086],
              [108.11017021599999, 12.146897629000096],
              [108.11052124200008, 12.146937318000093],
              [108.110837944, 12.150500986000077],
              [108.11099794100008, 12.15530435900005],
              [108.11115947300004, 12.16289689000005],
              [108.11092628200002, 12.168242861000065],
              [108.10967006500009, 12.173821836000085],
              [108.10857127100003, 12.179555711000132],
              [108.10904498700006, 12.182344651000053],
              [108.11140778100007, 12.185287577000013],
              [108.11451570600009, 12.181238332000087],
              [108.11685873800006, 12.17815946600007],
              [108.11904627600005, 12.176619457000102],
              [108.12139100600008, 12.176618121000081],
              [108.12483061300001, 12.177693264000043],
              [108.13827841100007, 12.184455580000096],
              [108.14734817400009, 12.188758097000106],
              [108.15266552400004, 12.191831934000062],
              [108.15688884600007, 12.195060355000107],
              [108.16033002900004, 12.197519878000039],
              [108.16236271, 12.197979970000093],
              [108.16423856900005, 12.19782463600003],
              [108.16517581700006, 12.196900613000048],
              [108.16564343400003, 12.195207546000029],
              [108.166579149, 12.192436935000027],
              [108.16814040500003, 12.189973600000046],
              [108.17110775800006, 12.18658583400005],
              [108.17782403300004, 12.18011732500006],
              [108.18282249100008, 12.175958325000112],
              [108.18657265400003, 12.174416249000062],
              [108.19063609500007, 12.173643227000051],
              [108.19759137100002, 12.172944377000048],
              [108.20009282800004, 12.173403654000124],
              [108.20259536600007, 12.174940057000059],
              [108.20541164700003, 12.177553270000095],
              [108.20916734200009, 12.181550446000065],
              [108.21198364800006, 12.184009710000064],
              [108.21417311300011, 12.184930788000068],
              [108.21698722800008, 12.18523567300007],
              [108.22089511700003, 12.185077739000059],
              [108.22448967900007, 12.184304536000033],
              [108.22761353900009, 12.181992994],
              [108.23057996300004, 12.178758300000057],
              [108.23321515100011, 12.173425264000047],
              [108.23500783600012, 12.168290473000097],
              [108.23681569800004, 12.164132987000096],
              [108.23806417800004, 12.162438942000085],
              [108.24001771600004, 12.162205914000078],
              [108.24189446300007, 12.163127038000086],
              [108.24424254200001, 12.166047989000093],
              [108.24596617900008, 12.169585171000017],
              [108.24769455100008, 12.176969307000094],
              [108.24817060100004, 12.182816166000075],
              [108.24958275100008, 12.18712315100001],
              [108.251385118, 12.19089109800011],
              [108.25530232300011, 12.198118850000037],
              [108.25827768900008, 12.20211617600002],
              [108.26516362500006, 12.20764744600009],
              [108.27126617300006, 12.21164068600009],
              [108.27486474000008, 12.213636531000114],
              [108.27705438900007, 12.214249216],
              [108.27986816700009, 12.213937733000078],
              [108.2873683550001, 12.210849953000123],
              [108.29221274200009, 12.209304458000057],
              [108.29580746000006, 12.208530030000107],
              [108.29909195000003, 12.20944870600009],
              [108.30409989400006, 12.212827006000014],
              [108.319517155, 12.223960954000022],
              [108.32468361100008, 12.228261912000081],
              [108.32765658600005, 12.229642313000076],
              [108.33047123700004, 12.2297918120001],
              [108.33344150900005, 12.229479356000093],
              [108.33641150900003, 12.229012981000052],
              [108.33828802900004, 12.229163869000013],
              [108.33907236100001, 12.23070149500005],
              [108.33907517200007, 12.232394256000044],
              [108.33782787600006, 12.234550690000033],
              [108.33517379500003, 12.237017147000094],
              [108.33298824500011, 12.239175051000116],
              [108.33236555300002, 12.240868823000104],
              [108.33205832900009, 12.244254878000048],
              [108.33245313500007, 12.246639562],
              [108.33339435900005, 12.248484762000087],
              [108.33636742400003, 12.249711171000042],
              [108.34231109200002, 12.250624969000066],
              [108.34684796900007, 12.251848691000065],
              [108.349665058, 12.253229065000058],
              [108.35232634500002, 12.254917446000134],
              [108.35436055500001, 12.255683508000102],
              [108.35564198400009, 12.255838397000039],
              [108.35656790400004, 12.255925297000124],
              [108.35811304400008, 12.255369383000046],
              [108.36186414900003, 12.254285744000091],
              [108.36749060200007, 12.252583217000089],
              [108.37280336400009, 12.250419425000112],
              [108.37858341700004, 12.247331314000041],
              [108.38076943400009, 12.245634588000074],
              [108.38490898600003, 12.243395675000011],
              [108.38772270900004, 12.242928856000022],
              [108.3925700560001, 12.24291989600008],
              [108.40148504700001, 12.243980355000071],
              [108.40742975100009, 12.245353974000038],
              [108.41431587500009, 12.24826449600007],
              [108.4196381580001, 12.251024018000043],
              [108.42245795600009, 12.253480663000092],
              [108.42355608700008, 12.255171271000085],
              [108.42496987400008, 12.258246279000049],
              [108.42653750700003, 12.260089827000085],
              [108.42841405600004, 12.260086026000037],
              [108.43067717500007, 12.258003873000066],
              [108.43285797900003, 12.253998213000015],
              [108.4342607680001, 12.251840851000038],
              [108.43519736000006, 12.251069466000073],
              [108.43584342800004, 12.251068132000052],
              [108.43629196600003, 12.251067206000064],
              [108.43707449500003, 12.251373370000083],
              [108.43785899600002, 12.252602874000027],
              [108.438652103, 12.257833558000096],
              [108.43882898400007, 12.267374577000021],
              [108.43899728700005, 12.272914429000091],
              [108.44025403700003, 12.275528025000069],
              [108.44119371700006, 12.276141644000036],
              [108.4436953140001, 12.275828610000071],
              [108.44658602300009, 12.274668310000079],
              [108.44924398800001, 12.274354881000081],
              [108.45074294900004, 12.274418725000123],
              [108.45687641000002, 12.275673817000053],
              [108.459580084, 12.277849394000027],
              [108.46180338100001, 12.280697210000111],
              [108.46301231400003, 12.287406772000063],
              [108.4630260160001, 12.29344776500003],
              [108.46337395200001, 12.296467523000041],
              [108.46439911500002, 12.297304306000088],
              [108.46610453699999, 12.297300547000043],
              [108.46712549200001, 12.296291448000128],
              [108.46848097300007, 12.292428883000104],
              [108.46898642600006, 12.289742864000084],
              [108.47034955200004, 12.289236413000102],
              [108.47154563700006, 12.290240580000054],
              [108.47343049200006, 12.294095915000101],
              [108.47566237100007, 12.300467579000072],
              [108.47823786000001, 12.307845338000066],
              [108.480203354, 12.30960287300009],
              [108.48259228200004, 12.310100854000082],
              [108.48531914700007, 12.30925555700013],
              [108.49042807000009, 12.306055380000112],
              [108.49122313800007, 12.30500718300007],
              [108.49450827100002, 12.300675988000085],
              [108.49790198900006, 12.293620092000078],
              [108.49856898500009, 12.287409686000037],
              [108.49992463300008, 12.283882562000057],
              [108.50349876200004, 12.281021389000022],
              [108.50656371200006, 12.279168201000077],
              [108.50860879200008, 12.278659872000029],
              [108.51167911900009, 12.278988060000094],
              [108.51317796400012, 12.279691540000035],
              [108.51594739600003, 12.280991330000022],
              [108.52004138400009, 12.281484698000076],
              [108.52481448300006, 12.28080166800005],
              [108.52822463400004, 12.280625358000028],
              [108.53146642900003, 12.281288439],
              [108.53564902500007, 12.28303981600005],
              [108.53718733200007, 12.284378355000046],
              [108.53787432400007, 12.286222463000039],
              [108.53736935300003, 12.288740832000132],
              [108.53618265000009, 12.291428755000043],
              [108.53618793900007, 12.293442419000121],
              [108.53755888500008, 12.295956044000087],
              [108.53944016200005, 12.297964932000037],
              [108.54097639200006, 12.298464424000064],
              [108.54285192500006, 12.298291802000081],
              [108.54862538000002, 12.296770921000098],
              [108.56035174900011, 12.293388191000075],
              [108.56146928100003, 12.293065774000043],
              [108.56394648900009, 12.292223129000133],
              [108.57479553800006, 12.28853231000002],
              [108.57754705900008, 12.287810766000094],
              [108.57946336500001, 12.287777300000078],
              [108.58153747600002, 12.287850688000022],
              [108.58231337300001, 12.287878135000073],
              [108.58406571000005, 12.288307576000078],
              [108.58671003200007, 12.289210046000052],
              [108.58776671000003, 12.289207107000076],
              [108.5886898200001, 12.288684646000101],
              [108.58881852500009, 12.287514545000098],
              [108.58802152800006, 12.285957113000022],
              [108.58748795600005, 12.284139],
              [108.58906469700004, 12.281275251000066],
              [108.59156858100009, 12.279318695000095],
              [108.59328372000007, 12.278664025000106],
              [108.59539698600004, 12.278658075000058],
              [108.59777631700008, 12.279301207000069],
              [108.60155297100002, 12.280764744000104],
              [108.60718840900009, 12.283433486000115],
              [108.61077257300008, 12.284429986000054],
              [108.61318052300001, 12.284409531000049],
              [108.61502339400008, 12.284619785000038],
              [108.61967201700003, 12.287732732000032],
              [108.62969062100004, 12.292677158000041],
              [108.63259151300008, 12.293094830000049],
              [108.63563739900012, 12.293512034000019],
              [108.64970352000009, 12.294464030000094],
              [108.65391065700001, 12.295303740000028],
              [108.65782925300006, 12.296570621000045],
              [108.66116933600001, 12.298265600000134],
              [108.6648017630001, 12.300670186000049],
              [108.66712847300005, 12.302794615000055],
              [108.67003658200007, 12.3053435390001],
              [108.67207482800004, 12.307895200000106],
              [108.67396860000005, 12.310589422000094],
              [108.67542650100008, 12.313000796000038],
              [108.67644804900004, 12.314987201000081],
              [108.67827109900007, 12.317727434000057],
              [108.67842301100002, 12.317935486000041],
              [108.68051632400002, 12.317532357000081],
              [108.69716901200005, 12.311082630000046],
              [108.69948156700008, 12.308943192000042],
              [108.70222512800007, 12.305665350000082],
              [108.7017510790001, 12.294297497000082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 36, code: "AD01", ten_tinh: "Lạng Sơn" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.31716441300006, 22.408812115000032],
              [106.3186719990001, 22.408711912000086],
              [106.3204657480001, 22.408960986000089],
              [106.32226531400005, 22.409825325000021],
              [106.32445202200006, 22.411741331000101],
              [106.32625832400004, 22.413308811000029],
              [106.32739468600009, 22.413826907],
              [106.32777181000003, 22.4138238130001],
              [106.32880553500011, 22.413463703000055],
              [106.33114743900009, 22.411862131000056],
              [106.33217444300006, 22.410798806000095],
              [106.33254065100007, 22.409653021000061],
              [106.33253310700007, 22.408861945000069],
              [106.33185558400005, 22.407021519000025],
              [106.33136829100006, 22.405355337000096],
              [106.33126145600002, 22.404037634000048],
              [106.331254766, 22.403334443000041],
              [106.33246857200007, 22.402093771000061],
              [106.33471429700006, 22.400317152000056],
              [106.33686654100006, 22.398629164000013],
              [106.34190475000001, 22.393137323000097],
              [106.34264874300003, 22.392076293000059],
              [106.34276220300009, 22.3916387740001],
              [106.34282527500007, 22.390538928000048],
              [106.34283901300005, 22.388938558000056],
              [106.34313163200001, 22.386089893000083],
              [106.3432658200001, 22.382709278000057],
              [106.34296870200004, 22.381217358000072],
              [106.34229288200007, 22.379552772000096],
              [106.3416255170001, 22.37876716100002],
              [106.34020157000005, 22.377724098000051],
              [106.33953506300006, 22.377026376000053],
              [106.33943071300004, 22.375972378000064],
              [106.34017463400004, 22.374911354000027],
              [106.35165823100002, 22.363476094000042],
              [106.35301415800004, 22.362365950000118],
              [106.35872807300004, 22.358713989000108],
              [106.360040618, 22.357999736000075],
              [106.36192543000008, 22.357983892],
              [106.36522812600006, 22.358395597000055],
              [106.37051506600007, 22.35931788000007],
              [106.37466771100003, 22.359897998000022],
              [106.38215787899999, 22.364405245000093],
              [106.38379385899999, 22.365452569000063],
              [106.38405492600002, 22.365619697000035],
              [106.38766845600004, 22.368841209000045],
              [106.3914230930001, 22.372017466000038],
              [106.39313968400003, 22.374024481],
              [106.39408788600005, 22.374587697000067],
              [106.39484233200004, 22.374625146000056],
              [106.3989909790001, 22.374540373000059],
              [106.40495190900002, 22.373405670000054],
              [106.40874734099999, 22.372423571000063],
              [106.41233824500002, 22.371045140000057],
              [106.41374042600003, 22.369890155000057],
              [106.41681852800008, 22.366698723000056],
              [106.41914346400006, 22.363601735000046],
              [106.42091264800007, 22.36147654400007],
              [106.42222309700006, 22.360585999000101],
              [106.42344464200005, 22.360223660000052],
              [106.42537521400001, 22.360074814000086],
              [106.42688483700003, 22.360237322000032],
              [106.42859368800003, 22.361452889000063],
              [106.43116152800005, 22.363715682000063],
              [106.43258324000007, 22.364494239000088],
              [106.43409472300009, 22.364832463000091],
              [106.43654141400002, 22.364459152000023],
              [106.43804657700012, 22.364182086000106],
              [106.43945117099999, 22.363290572000061],
              [106.44075878500009, 22.362136198000115],
              [106.44234638500009, 22.360715621000082],
              [106.444787531, 22.359814822000068],
              [106.44816921000003, 22.358729788000083],
              [106.45023788900005, 22.358271753000025],
              [106.45193415800006, 22.358256551000096],
              [106.45413464900005, 22.35865866000006],
              [106.45678365800003, 22.359277813000041],
              [106.4629890140001, 22.360761453000052],
              [106.463971137, 22.361288371000043],
              [106.47524170200003, 22.365473540000025],
              [106.47713672000006, 22.366423164000082],
              [106.48452202800006, 22.369607954000067],
              [106.48565854100003, 22.370124931000035],
              [106.48661872600007, 22.371786233000087],
              [106.48787847900005, 22.375027007000092],
              [106.48788782000001, 22.375905926000073],
              [106.48779917500005, 22.37643415000008],
              [106.48724022500008, 22.377054601000076],
              [106.48479536600003, 22.3776044640001],
              [106.48206401400007, 22.37780531300011],
              [106.48027513700004, 22.377997493000024],
              [106.47877365500005, 22.378626529000108],
              [106.47821466100008, 22.379246946000109],
              [106.47793748400007, 22.379776895000077],
              [106.47794211700008, 22.380216360000112],
              [106.47813340600001, 22.380478313000118],
              [106.47954995599999, 22.380729065000082],
              [106.48191367700008, 22.381410639000073],
              [106.48390415200001, 22.382447209000041],
              [106.48669812700008, 22.383696125000085],
              [106.48831912900009, 22.385439237000071],
              [106.48899293600006, 22.386751549000024],
              [106.48900510200009, 22.387894155000062],
              [106.488447992, 22.388690388000043],
              [106.48769954700006, 22.389224687000109],
              [106.48553818500002, 22.38985986000008],
              [106.48309401800003, 22.390497612],
              [106.48225130400003, 22.391032737000046],
              [106.48207116800005, 22.391825503000092],
              [106.48245844400003, 22.392788869000043],
              [106.48378833900001, 22.393743574000077],
              [106.48691011900003, 22.39476971600007],
              [106.48993206200005, 22.39526929900002],
              [106.49173144900007, 22.396043817000063],
              [106.49258730700009, 22.396739124000042],
              [106.49278332800006, 22.397440522000075],
              [106.4926023180001, 22.398145400000047],
              [106.49213757200002, 22.398764994000061],
              [106.49110631400004, 22.399301924000078],
              [106.48950852100009, 22.399756154],
              [106.48848004000007, 22.400556736000077],
              [106.4882066170001, 22.401438261000095],
              [106.48821597100007, 22.402317177000072],
              [106.48926038000008, 22.403010773000084],
              [106.49295175500005, 22.404383133000039],
              [106.49534035800006, 22.405009276000065],
              [106.49649035900009, 22.405163358000024],
              [106.49772868700006, 22.405316606],
              [106.49970935900006, 22.405460557000097],
              [106.50089842400004, 22.405496742000075],
              [106.50205886600006, 22.405482325000037],
              [106.503205763, 22.405348123000032],
              [106.50750592100007, 22.403325097000071],
              [106.51163551399999, 22.401616415],
              [106.51377816800009, 22.400240904000057],
              [106.51547745300009, 22.399470846000021],
              [106.51674698000005, 22.399101210000083],
              [106.51744451800003, 22.399279980000038],
              [106.51868618100011, 22.399792337000065],
              [106.53099660500001, 22.409125424000109],
              [106.53091003800004, 22.409829441000092],
              [106.52951910400007, 22.411952199000048],
              [106.52849619700008, 22.413055491000122],
              [106.52790899100007, 22.413632759],
              [106.52749987300007, 22.413925121000133],
              [106.52731664000001, 22.414240048000075],
              [106.52753897900001, 22.414726249000068],
              [106.53355834200005, 22.419823943000061],
              [106.53423423100003, 22.420123428000061],
              [106.53504702200004, 22.42025521100004],
              [106.53580579100003, 22.420322791000032],
              [106.54043510100007, 22.420558402000076],
              [106.54404243400003, 22.420867704],
              [106.54659078500003, 22.421107007000082],
              [106.54923049300004, 22.421081719000121],
              [106.55177495100007, 22.420969401000065],
              [106.553282369, 22.420867012000123],
              [106.55479859900004, 22.421555618000077],
              [106.55830541800003, 22.423191899000102],
              [106.55913779300008, 22.423553185000067],
              [106.56468640200006, 22.425843165000089],
              [106.562961597, 22.415095794000095],
              [106.56268934200003, 22.413399140000045],
              [106.5629457790001, 22.410760513000035],
              [106.56353689200007, 22.41038494600005],
              [106.56634226500003, 22.409978068000044],
              [106.56685754700003, 22.409888275000078],
              [106.57397964200001, 22.401950969000062],
              [106.57458620700001, 22.40127491100008],
              [106.57783311500008, 22.397156597000034],
              [106.57862080800005, 22.39627498],
              [106.57983958500009, 22.394281138000061],
              [106.58151018500003, 22.391620434000089],
              [106.58354992900004, 22.387781888000099],
              [106.58540845600007, 22.383338457000065],
              [106.58547141800004, 22.3829899670001],
              [106.58534163300006, 22.379556253000118],
              [106.58425822100003, 22.377422969000094],
              [106.58106375600003, 22.375406963000088],
              [106.58025490000009, 22.374037114000075],
              [106.57997766500006, 22.3728072010001],
              [106.58021688400004, 22.371420379000106],
              [106.58137605300003, 22.369505410000023],
              [106.58201484800004, 22.368281309000082],
              [106.57868550100008, 22.366156126000099],
              [106.57530027000003, 22.363753897000024],
              [106.57445970000002, 22.362743966000039],
              [106.57263277400003, 22.361410549000112],
              [106.569263791, 22.359281364000068],
              [106.56435589600008, 22.359991923000067],
              [106.56304836200007, 22.360084386000075],
              [106.55975127100002, 22.360685797000102],
              [106.55789031100005, 22.350442808000089],
              [106.55804561600003, 22.349518224000064],
              [106.55872098500004, 22.34903416900007],
              [106.56314796400005, 22.346248820000092],
              [106.56788916900003, 22.343763541000072],
              [106.57069329500005, 22.346893305000037],
              [106.57306694900007, 22.348209571000055],
              [106.5738884190001, 22.348297251000112],
              [106.57470666100002, 22.348097970000055],
              [106.57531550200005, 22.347518063000038],
              [106.57652349100007, 22.345497364],
              [106.57710541500009, 22.342526094000092],
              [106.57801431800006, 22.341273610000108],
              [106.57903544599999, 22.340881002000046],
              [106.58098162200008, 22.34067069300005],
              [106.58304005900004, 22.341320238000058],
              [106.58470021700001, 22.343025956000062],
              [106.58615425200006, 22.344638019000037],
              [106.58636150600006, 22.344827319000068],
              [106.58718296700007, 22.344914932000037],
              [106.58840484400011, 22.344137645000025],
              [106.59125006000006, 22.341813774000038],
              [106.59532895200005, 22.339764720000055],
              [106.59696198900008, 22.339078982000068],
              [106.59941644500003, 22.338480782000047],
              [106.60136472400002, 22.338461540000054],
              [106.60341881600007, 22.338728210000092],
              [106.60768907600007, 22.339977336000061],
              [106.61077851099999, 22.341094614000042],
              [106.61221410200002, 22.341080337000015],
              [106.61333435799999, 22.340399527000088],
              [106.6154579770001, 22.337795463000099],
              [106.61716254500001, 22.334430250000104],
              [106.61787477500005, 22.333944825000081],
              [106.61869507800007, 22.333936633000029],
              [106.62003025300005, 22.334114613000011],
              [106.62312406700006, 22.335614254000099],
              [106.62745507900003, 22.337675391000019],
              [106.63074966600004, 22.33879020800007],
              [106.63240590300003, 22.340112803000075],
              [106.63333655800004, 22.340773070000083],
              [106.63375007400006, 22.341055885000038],
              [106.63426389300011, 22.341146361000064],
              [106.63498168299999, 22.341139121000083],
              [106.64720861200001, 22.340726270000033],
              [106.64796517200008, 22.34040729900002],
              [106.64904981200004, 22.340058732000045],
              [106.65099583800003, 22.339001753000076],
              [106.65277039500003, 22.337749947000063],
              [106.65456796600009, 22.336683287000092],
              [106.65670880700003, 22.335609136000031],
              [106.65793130900002, 22.334926998000078],
              [106.65792565400008, 22.334448750000035],
              [106.65686979000003, 22.331876682000114],
              [106.65612157300004, 22.329301457000028],
              [106.65588940000001, 22.327007949000063],
              [106.65607640500011, 22.325475452000099],
              [106.65778208100002, 22.322301164000088],
              [106.65939083600009, 22.31960617500004],
              [106.66162371700008, 22.317670071000073],
              [106.6634487150001, 22.315929439000023],
              [106.66483458600001, 22.314431028000037],
              [106.66572649900009, 22.313466668],
              [106.66642596200006, 22.311928903000044],
              [106.66691583500008, 22.31001063700004],
              [106.66689766700009, 22.308480231000068],
              [106.66594099400007, 22.305620212000044],
              [106.66457656900008, 22.302955689000022],
              [106.66436814400005, 22.302670839000079],
              [106.66436247500012, 22.302192596000033],
              [106.66497413800002, 22.301899327000015],
              [106.66722370000006, 22.301397913000095],
              [106.66865316700003, 22.300904909000074],
              [106.66956438800003, 22.299938911000083],
              [106.67005987300007, 22.298498883000036],
              [106.67004963900003, 22.297638041000042],
              [106.66952573500002, 22.296686813000029],
              [106.66703719800009, 22.294320854000112],
              [106.66537556900001, 22.292520346000089],
              [106.664646707, 22.291571204000071],
              [106.66468267000005, 22.290279394000031],
              [106.66599591800009, 22.288639662000072],
              [106.66821993200007, 22.286658417000105],
              [106.6703499610001, 22.286136357000075],
              [106.67167574100002, 22.286122711000097],
              [106.67329783600012, 22.286248891000056],
              [106.67525969399999, 22.286688505000107],
              [106.67617971500009, 22.287005098000101],
              [106.67824388200007, 22.287796970000073],
              [106.6794688940001, 22.288254309000074],
              [106.68028943800005, 22.288383106000076],
              [106.68065545300004, 22.288384280000081],
              [106.68100007300004, 22.288115494000046],
              [106.68135157799999, 22.286855048000028],
              [106.68131611500007, 22.28388991300001],
              [106.68130810800004, 22.283220366000123],
              [106.6815073810001, 22.28274],
              [106.68238895800003, 22.282078033000019],
              [106.68327997600004, 22.281493178000034],
              [106.68611466700001, 22.281176430000116],
              [106.68862023400001, 22.280159942000033],
              [106.68894664100003, 22.279938887000036],
              [106.68948773200003, 22.278793375000113],
              [106.68985226100008, 22.277678954000102],
              [106.68983042700006, 22.275861610000014],
              [106.68940666800007, 22.274718087000075],
              [106.68878024300002, 22.273767996000068],
              [106.68701613000007, 22.271968772000058],
              [106.6854673510001, 22.271028229000045],
              [106.68370217400006, 22.269133320000023],
              [106.68328307200002, 22.268372369000033],
              [106.68307122900008, 22.267800602000065],
              [106.68285367300011, 22.266750565000045],
              [106.68309329700004, 22.265360993000094],
              [106.68471012000006, 22.263431],
              [106.68704427700001, 22.261493542000018],
              [106.68804612900004, 22.259569900000109],
              [106.68822126100005, 22.257080885000043],
              [106.69017894000002, 22.249407611000024],
              [106.69066265200007, 22.24832609200007],
              [106.69116758700002, 22.246604107000053],
              [106.69150268100003, 22.244639557000042],
              [106.69205593800007, 22.242368896000109],
              [106.6934903960001, 22.239280847000131],
              [106.6951885510001, 22.235628013000053],
              [106.69771090000008, 22.232349209000098],
              [106.69961148000003, 22.228502900000088],
              [106.70020891300008, 22.22706172200013],
              [106.70019276199999, 22.225722635000089],
              [106.7002606070001, 22.222852081000077],
              [106.70023519900003, 22.220744962000083],
              [106.69951870300008, 22.220839841000029],
              [106.69878327100008, 22.220798512000073],
              [106.69814986900008, 22.220511056000092],
              [106.69762055500006, 22.220149002000063],
              [106.69690488800009, 22.219568336000073],
              [106.69642011500012, 22.218544178000066],
              [106.69625204700007, 22.217663736000048],
              [106.696236136, 22.216340612000081],
              [106.696608574, 22.214572352000062],
              [106.69674569000006, 22.212880052000017],
              [106.69677312500002, 22.211887299000033],
              [106.69636269000009, 22.2104947750001],
              [106.69555775400009, 22.209032853000018],
              [106.69428136300009, 22.207649335000035],
              [106.69213637400007, 22.20605429600004],
              [106.68968269900009, 22.204977044000081],
              [106.68731306000004, 22.204339981000132],
              [106.68541928900002, 22.203992017000118],
              [106.68369087300002, 22.20430396900008],
              [106.68070896100002, 22.205143476000089],
              [106.67914208300006, 22.205594622000021],
              [106.67873101300006, 22.200393247000072],
              [106.67809381900001, 22.197737631000081],
              [106.67733974800005, 22.196203239000042],
              [106.668995217, 22.18666798400006],
              [106.66882266200003, 22.186108482000044],
              [106.66878451500007, 22.185544761000031],
              [106.66944680100006, 22.184528753000073],
              [106.67131742700003, 22.182499963000076],
              [106.67472743000009, 22.178950343000011],
              [106.67771443900006, 22.173358150000055],
              [106.68420616600011, 22.166256571000083],
              [106.686619, 22.164740795000078],
              [106.68847888700004, 22.16424174100009],
              [106.69513943900003, 22.164382837000041],
              [106.70059741900008, 22.16472072400002],
              [106.701472397, 22.164521824000083],
              [106.70257125100005, 22.16350821000006],
              [106.70389464600004, 22.161577886000124],
              [106.70434101200003, 22.160152461000045],
              [106.70435194600005, 22.158520489000111],
              [106.70348930700007, 22.156883431000018],
              [106.70251132800007, 22.156163723000063],
              [106.70174900800006, 22.155853270000044],
              [106.69781452500004, 22.156340330000113],
              [106.69650966900008, 22.155516683000087],
              [106.69542527900006, 22.154388304000072],
              [106.69467192800008, 22.152751837000025],
              [106.69287422800001, 22.144070935000038],
              [106.68820746100006, 22.139861279000073],
              [106.69074844500007, 22.135489935000052],
              [106.69185891900003, 22.13274226599999],
              [106.6918671980001, 22.131518223000057],
              [106.69167851300003, 22.127130762000036],
              [106.69128734700007, 22.120395838000029],
              [106.69307965200005, 22.113673650000095],
              [106.69418996800007, 22.110925840000029],
              [106.69551239900007, 22.109097368000086],
              [106.69748706900003, 22.107680750000021],
              [106.70329215800011, 22.104858250000078],
              [106.70833224600004, 22.102133162000115],
              [106.70987824500006, 22.099489760000068],
              [106.71021730000004, 22.097757476000091],
              [106.71001325800003, 22.095613978000102],
              [106.70980377800002, 22.094286569000054],
              [106.71003774500011, 22.091941565000091],
              [106.70864021800003, 22.089310019000052],
              [106.70727081900006, 22.087196371000047],
              [106.70672296000002, 22.084846708000057],
              [106.70686074500006, 22.081901049000052],
              [106.70885487400002, 22.074898203000082],
              [106.70916841700003, 22.073246008000069],
              [106.70933744300007, 22.06617963399999],
              [106.70647525800004, 22.065806019000078],
              [106.70580984200005, 22.065355879000045],
              [106.70534077200006, 22.064103610000082],
              [106.70242981800007, 22.056767824000097],
              [106.69976714200004, 22.055145703000086],
              [106.69653523400005, 22.052984671000075],
              [106.69533492100004, 22.046908231000103],
              [106.69554091200003, 22.044678015000052],
              [106.6965229030001, 22.04328775700013],
              [106.69992689800002, 22.041479593000091],
              [106.70226997200008, 22.04059427700011],
              [106.70379446600001, 22.039717451000037],
              [106.70388418700006, 22.038664222000115],
              [106.70387043600007, 22.037516414],
              [106.7040498850001, 22.035409946000058],
              [106.70442935900004, 22.032918750000086],
              [106.70512609400006, 22.031285223000019],
              [106.7063160110001, 22.028115935000066],
              [106.70645400900005, 22.026823044000118],
              [106.70644713000011, 22.026249145000065],
              [106.70612759300005, 22.025200163000044],
              [106.70457120700007, 22.023398766000035],
              [106.70359958100005, 22.022510386000071],
              [106.69866809800004, 22.019793015000033],
              [106.68491147600011, 22.00712616000002],
              [106.68332230800007, 22.004865729000045],
              [106.68272011800008, 22.003431589000044],
              [106.68223469300005, 21.999913273000082],
              [106.68146064400005, 21.997984746000029],
              [106.68147544000003, 21.995812231000045],
              [106.68196079000003, 21.992773456000059],
              [106.68277065600009, 21.987563946000051],
              [106.68338968900005, 21.985219110000017],
              [106.69378230700001, 21.97981880800004],
              [106.69455860000002, 21.97953367900007],
              [106.6952893540001, 21.973244140000055],
              [106.69530988800001, 21.970196681000061],
              [106.69555380300002, 21.964329131000056],
              [106.69678499000003, 21.961162416000057],
              [106.69755026200002, 21.960776694000025],
              [106.69806450500002, 21.960529015000049],
              [106.69894671400002, 21.961220238000045],
              [106.70139072900008, 21.967765138000047],
              [106.70331992600009, 21.971896544000025],
              [106.70453460200005, 21.975524779000111],
              [106.7052973130001, 21.977267364000049],
              [106.70575997400002, 21.977559740000082],
              [106.70684501500008, 21.977421209000077],
              [106.70948644600006, 21.976132926000069],
              [106.71259050800003, 21.975136980000087],
              [106.71506931700004, 21.975006467000036],
              [106.71661412800006, 21.975594760000035],
              [106.71846677200004, 21.976474509000084],
              [106.72061671300003, 21.979238937000019],
              [106.72444740300008, 21.985489201000071],
              [106.72567209400006, 21.987668850000119],
              [106.72597421800002, 21.988829320000036],
              [106.72596564000003, 21.990132843000062],
              [106.72641312900001, 21.992742546000073],
              [106.72732716600005, 21.995065219000054],
              [106.72948030100012, 21.997394963000083],
              [106.7318115260001, 22.000000214000082],
              [106.73328623800008, 22.001004406000035],
              [106.73439232100003, 22.002183730000091],
              [106.73495418800006, 22.003242961000069],
              [106.73526127100007, 22.004683676000077],
              [106.73509462000004, 22.006851396000023],
              [106.73472508100011, 22.008209025],
              [106.73492247400002, 22.008567928000033],
              [106.73583639600005, 22.009289568000057],
              [106.73783743100006, 22.010161512000082],
              [106.73976874800007, 22.010634265000114],
              [106.74131394600002, 22.010708109000099],
              [106.74341983600002, 22.010319294000077],
              [106.74584409500004, 22.009805680000035],
              [106.74792826000001, 22.009969886000036],
              [106.75010029800004, 22.010572520000068],
              [106.75282132200002, 22.012077641000083],
              [106.75515081300003, 22.013135675000051],
              [106.75718191000004, 22.013474904000049],
              [106.76364788400009, 22.013405450000079],
              [106.76634671500001, 22.013105640000049],
              [106.76846201400006, 22.012451095000074],
              [106.77143463000009, 22.010884800000063],
              [106.77385771100002, 22.009706080000058],
              [106.78191947200003, 22.005784082000091],
              [106.78229870800006, 22.005238485000064],
              [106.78359471400009, 22.003081975000072],
              [106.78479536200004, 22.000000325000087],
              [106.78611658800003, 21.995959452000058],
              [106.78650906500008, 21.99476162200007],
              [106.78728497400006, 21.993805874000124],
              [106.78805561800006, 21.993690068000049],
              [106.79100613400001, 21.993826116000022],
              [106.79331582300009, 21.993838649000082],
              [106.79498766700003, 21.993247718000042],
              [106.79782103499998, 21.991583068000061],
              [106.80039550000001, 21.990276973000086],
              [106.80155626600003, 21.989323242000047],
              [106.80258871000002, 21.988368803000036],
              [106.80388740000009, 21.985855813000029],
              [106.80505775000003, 21.983342104000059],
              [106.80506293400002, 21.982502131000011],
              [106.80455562600005, 21.981539399000027],
              [106.80340901200003, 21.980213237000079],
              [106.80289949200007, 21.979610495000124],
              [106.80277712300007, 21.97864982300004],
              [106.80355511300004, 21.977333999000059],
              [106.80471578500007, 21.976380217000013],
              [106.80857229800003, 21.975200880000045],
              [106.81114205300003, 21.974614612000032],
              [106.81396546400005, 21.974509669000049],
              [106.81729694400009, 21.975247412000073],
              [106.82152512500008, 21.976229851000092],
              [106.82460151500007, 21.976726127000035],
              [106.82755325200006, 21.976621698000095],
              [106.83012296800004, 21.976035214000063],
              [106.83076303900006, 21.976278573000037],
              [106.83152924300005, 21.976882603000014],
              [106.83165176200006, 21.977843262000093],
              [106.83087177900009, 21.979519181000029],
              [106.82957058000008, 21.982512348000057],
              [106.82956550200001, 21.983352322000101],
              [106.830331739, 21.983956348000078],
              [106.83353369100007, 21.984933154000075],
              [106.83558592900005, 21.985063895000053],
              [106.83776720700006, 21.985075295000023],
              [106.83943955000005, 21.984364029000055],
              [106.84150328300009, 21.982574776000071],
              [106.84395263000008, 21.980667505],
              [106.84523641800007, 21.980554172000041],
              [106.84664350700005, 21.981281466],
              [106.84830223200007, 21.982850061000114],
              [106.85009143000005, 21.98405930800007],
              [106.85086057300001, 21.984183276000024],
              [106.85150212100007, 21.984186587000032],
              [106.85343600300011, 21.982636564000032],
              [106.85472192900002, 21.982163182000036],
              [106.85574769000007, 21.982288463000096],
              [106.85664159300005, 21.983013052000011],
              [106.85778858800009, 21.984338941000111],
              [106.85867827400007, 21.985783497000085],
              [106.85931841400001, 21.986026778000053],
              [106.86021658600008, 21.986031379000082],
              [106.86522985600001, 21.984496998000047],
              [106.86664688200001, 21.983544224000084],
              [106.86793699100009, 21.982350793000101],
              [106.86948160300008, 21.981518642000083],
              [106.8711510090001, 21.981287114000025],
              [106.87243408700003, 21.981293617000027],
              [106.87345984600009, 21.981418808000086],
              [106.87435451800003, 21.982023334000097],
              [106.87563481400009, 21.982509808000117],
              [106.87691650800009, 21.982756280000082],
              [106.87897014100011, 21.982646627000065],
              [106.88372656400001, 21.981110536000067],
              [106.88668316500004, 21.980165345000025],
              [106.89169744700006, 21.978390354000062],
              [106.8950395630001, 21.977326961000095],
              [106.89787389500003, 21.975300993000069],
              [106.90006596500004, 21.973391815000106],
              [106.90148004400007, 21.972918788000051],
              [106.90327763800003, 21.972687647000022],
              [106.90396500500006, 21.972798279000116],
              [106.90481590300004, 21.972935228000075],
              [106.9069882430001, 21.97450592700001],
              [106.91018434700004, 21.976561623000102],
              [106.91172197700008, 21.976929157000043],
              [106.91364655300008, 21.976938559000111],
              [106.91531586600009, 21.976706697000026],
              [106.91711750600003, 21.975755454000101],
              [106.91801966500006, 21.975039838000043],
              [106.91853823400007, 21.974082339000041],
              [106.91855230800007, 21.971562371000083],
              [106.91868932900005, 21.970002999000116],
              [106.92023963700007, 21.968090476000057],
              [106.92166295500006, 21.965937320000073],
              [106.92231846900003, 21.963420411000101],
              [106.92233917200005, 21.959700365000053],
              [106.92196097700003, 21.958498483000085],
              [106.92030389100006, 21.95657033800007],
              [106.92005065800006, 21.95596908000006],
              [106.91992638400009, 21.955248445000031],
              [106.92057318900004, 21.954291528000077],
              [106.92303272500007, 21.950343216000064],
              [106.92587834100006, 21.946156694000059],
              [106.92769426200003, 21.942565196000082],
              [106.92834961799998, 21.940048154000102],
              [106.92861594300008, 21.936796885000028],
              [106.92825257100003, 21.934407196000016],
              [106.92826054000008, 21.932967100000056],
              [106.92839147899998, 21.932487682],
              [106.92929272200004, 21.931891964],
              [106.93147737900006, 21.931182392],
              [106.9328884000001, 21.931189164000031],
              [106.93596567900006, 21.931443926000057],
              [106.9396790860001, 21.932661767000106],
              [106.94480227200006, 21.934126257000038],
              [106.94826119000008, 21.934982711000117],
              [106.95018535000004, 21.934991797000066],
              [106.95147137200004, 21.934397808000128],
              [106.95211795100008, 21.933440768000075],
              [106.95238618400006, 21.931281821000056],
              [106.95239851700011, 21.929001652000046],
              [106.95215366000009, 21.926840279000089],
              [106.95229166700005, 21.925040729000052],
              [106.95255145900006, 21.924441887000064],
              [106.95448328900002, 21.923010812000051],
              [106.95872844200008, 21.920750457000082],
              [106.96078201600008, 21.920520029000023],
              [106.96129444500012, 21.920642441000027],
              [106.96218845900002, 21.921366693],
              [106.96307734900003, 21.923051032],
              [106.96448125400011, 21.924377721],
              [106.96665928600001, 21.924867904000052],
              [106.968585913, 21.924396788000067],
              [106.96974033900003, 21.924402136000062],
              [106.97076459300004, 21.924766914000045],
              [106.97268483400003, 21.925495872000056],
              [106.97677620300007, 21.928034994000086],
              [106.98314260400005, 21.937065007000101],
              [106.98568115600003, 21.942237008000021],
              [106.98644079900001, 21.944160613000108],
              [106.98643514600008, 21.945240665000121],
              [106.98591132500007, 21.947278415000056],
              [106.985906298, 21.948238451000087],
              [106.98636547200007, 21.949108128000105],
              [106.98654144900007, 21.94944142700006],
              [106.98743193500003, 21.950885566000089],
              [106.99151965000003, 21.954264316000049],
              [106.99203156400003, 21.954506640000112],
              [106.99280130400003, 21.954510125000049],
              [106.99370246100007, 21.953914172000033],
              [106.99538081900003, 21.951881646000082],
              [106.99640735300005, 21.950051299000073],
              [106.99736222600011, 21.947958267000089],
              [106.99820080100002, 21.945533540000014],
              [106.99879250900005, 21.945205043000016],
              [107.00044279399999, 21.945543622000052],
              [107.00373704700007, 21.946929640000064],
              [107.00499440000002, 21.947406662000013],
              [107.00600222900009, 21.94741116600003],
              [107.008021501, 21.946713082000088],
              [107.01016432200002, 21.946486926000119],
              [107.01268207300006, 21.946851659000096],
              [107.01532521700008, 21.947334766000012],
              [107.01683874700009, 21.94698791200009],
              [107.01873379300012, 21.945935634000065],
              [107.02088433800006, 21.944177365000073],
              [107.02253690000002, 21.941238379000083],
              [107.02342584900003, 21.9398280670001],
              [107.02507002900009, 21.938538918000106],
              [107.02696316300006, 21.937840099000105],
              [107.03124267400003, 21.938565878000112],
              [107.03288443800007, 21.937748063000036],
              [107.03453323700006, 21.9355160470001],
              [107.03631089600006, 21.932695306000127],
              [107.03807972300004, 21.931642288000127],
              [107.04186459000003, 21.930480093000071],
              [107.04451049100003, 21.930373618000054],
              [107.04753138000004, 21.930857981000074],
              [107.05521080100003, 21.931833543000053],
              [107.05924063700004, 21.9320863230001],
              [107.06039493300007, 21.927848498000024],
              [107.06078883700006, 21.92455025800006],
              [107.06055005600003, 21.921838598000051],
              [107.06081740900004, 21.918657640000028],
              [107.06297525000005, 21.91524891100007],
              [107.06424792400001, 21.912543568000054],
              [107.06419320400002, 21.910834411000032],
              [107.06259756500006, 21.908193961000052],
              [107.06112759400003, 21.90663755500006],
              [107.05829610100005, 21.904437406000099],
              [107.06098242500009, 21.900347696000019],
              [107.06295613300006, 21.895118228000051],
              [107.06043248000006, 21.894141493000063],
              [107.05779281500006, 21.893069577000098],
              [107.05616475000009, 21.891176891000086],
              [107.05479894000007, 21.88716377700004],
              [107.05251265000007, 21.884684997000114],
              [107.04605985300006, 21.881364368000078],
              [107.04337463200001, 21.880703510000018],
              [107.03909162900001, 21.880024656000074],
              [107.03720846600008, 21.878837868000019],
              [107.03468643100004, 21.874825594000043],
              [107.03105741500006, 21.870213038000053],
              [107.02796621800009, 21.867940005],
              [107.02519942800005, 21.866304547000048],
              [107.02060918200004, 21.865148026000014],
              [107.01648361800001, 21.864369044000043],
              [107.0119633470001, 21.858894561000049],
              [107.01069781700002, 21.856228205000058],
              [107.00659606800011, 21.85235183900005],
              [107.00302266700002, 21.847538727000071],
              [107.00193834900001, 21.846078163000101],
              [107.00254171900011, 21.839295812000081],
              [107.00385215600009, 21.833181764000109],
              [107.01224311500008, 21.831888770000063],
              [107.01763356900004, 21.827537550000017],
              [107.02256686000001, 21.822962091000072],
              [107.02900711400007, 21.819100348000028],
              [107.03168396100003, 21.818117003000026],
              [107.03463361700003, 21.817723948],
              [107.03814180200003, 21.817666578000058],
              [107.04179564100011, 21.817092964000025],
              [107.04721717600006, 21.815465963000058],
              [107.052392599, 21.812658983000063],
              [107.05667861900007, 21.811498414000049],
              [107.06348180500004, 21.810466259000094],
              [107.06614110400007, 21.810146823000039],
              [107.06995631800008, 21.810000464000012],
              [107.07281519800001, 21.81041829400003],
              [107.07543792000004, 21.811223584000047],
              [107.07701069600006, 21.811406948000034],
              [107.08066373300005, 21.810950566000109],
              [107.08331076800005, 21.810254209000043],
              [107.08469833400002, 21.809670526000069],
              [107.08507989200005, 21.808846935000048],
              [107.08508378600003, 21.808021789000072],
              [107.08584386200003, 21.805195759000107],
              [107.08689102100007, 21.803738876000089],
              [107.08854574600012, 21.802122112000077],
              [107.09089127100003, 21.801076408000064],
              [107.09288889100003, 21.800272787],
              [107.09494320600001, 21.79945680600008],
              [107.09620807900004, 21.798165245000064],
              [107.09886309200006, 21.795700483000054],
              [107.10037638200004, 21.795117196000021],
              [107.10490858800004, 21.795017609000041],
              [107.10843320800009, 21.795031774000059],
              [107.11070011900007, 21.794805088000047],
              [107.11158452400005, 21.794101324000081],
              [107.11196809600008, 21.792806137000028],
              [107.11197457600009, 21.791391555000047],
              [107.11260935400003, 21.790215247000063],
              [107.11455714300007, 21.787817203000067],
              [107.1167215670001, 21.784815439000035],
              [107.11801948000003, 21.783245100000045],
              [107.11875923500004, 21.78239809100004],
              [107.11957323200006, 21.781283643000023],
              [107.12028493700006, 21.780656297000078],
              [107.12228824900009, 21.779349253000113],
              [107.12424434500011, 21.778368935000088],
              [107.12695603400003, 21.777009896],
              [107.12909789900006, 21.776546750000044],
              [107.13060777700011, 21.776670549000073],
              [107.13148884900009, 21.776673993000095],
              [107.13212083300007, 21.776087025000024],
              [107.13364387700005, 21.773263660000097],
              [107.13402884100002, 21.771614724000088],
              [107.13365599200007, 21.770552273000106],
              [107.13366020500007, 21.769609179000057],
              [107.13416839700002, 21.768550158000011],
              [107.13619374600009, 21.765964471000032],
              [107.13821537400003, 21.764203970000025],
              [107.13909795900004, 21.76385372],
              [107.14035654800009, 21.763858592000048],
              [107.14249355300007, 21.764456299000031],
              [107.14350146700005, 21.764224406000032],
              [107.14539611700006, 21.762699126000129],
              [107.14741451800009, 21.761645863000112],
              [107.15157402000007, 21.760247092000014],
              [107.15611153100008, 21.758731784000048],
              [107.15812830600005, 21.758032085000067],
              [107.15939043400003, 21.757211622000035],
              [107.16015169400005, 21.755799799000016],
              [107.16015782100003, 21.754385108000029],
              [107.16028775300003, 21.753442460000102],
              [107.16117178000007, 21.752738443000027],
              [107.16318999100008, 21.751685017000085],
              [107.16419731500012, 21.751570915000059],
              [107.16532794800005, 21.752046736000082],
              [107.16796374900009, 21.753707118000065],
              [107.16909692900006, 21.753593465000073],
              [107.17023263000006, 21.752890354000073],
              [107.17143351200004, 21.751656961000073],
              [107.17294976000008, 21.750247893000015],
              [107.17433712800003, 21.749545693000094],
              [107.177107333, 21.74920228900001],
              [107.18075897200006, 21.748744208000033],
              [107.18239601300007, 21.74851445000003],
              [107.18416286200006, 21.747342007000015],
              [107.18782778000008, 21.743700713000088],
              [107.18871560700009, 21.742053430000041],
              [107.189989811, 21.738285426000026],
              [107.19087612000006, 21.736991800000013],
              [107.19201360900011, 21.735816978000067],
              [107.19466067700002, 21.734765520000018],
              [107.19743209800001, 21.73406816800005],
              [107.20020787400007, 21.732309724000039],
              [107.20260753500003, 21.730196197000048],
              [107.20463302100011, 21.72725600100005],
              [107.20539721500009, 21.725018665000029],
              [107.20653454600006, 21.723843741000067],
              [107.20867755700009, 21.722908192000112],
              [107.21006325600007, 21.72229657400004],
              [107.2112787070001, 21.721732558],
              [107.21206056000004, 21.721248192000076],
              [107.21274344400005, 21.721026117000086],
              [107.21402223200008, 21.720320567000059],
              [107.21542368700004, 21.719454082000041],
              [107.21794552000007, 21.718166073000056],
              [107.22198012100003, 21.716175932000056],
              [107.22378200300008, 21.715443763000053],
              [107.22613810100006, 21.714775645000103],
              [107.22815415700008, 21.714075253000061],
              [107.22929034900002, 21.713135988000062],
              [107.22986263400004, 21.711648941000036],
              [107.23005794600003, 21.709955306000062],
              [107.23043869, 21.709131310000096],
              [107.23157484200004, 21.708192025000095],
              [107.23421992300005, 21.707493744000047],
              [107.23812506600004, 21.706328145000064],
              [107.24127154200006, 21.706103109000097],
              [107.24529738800001, 21.706234725000058],
              [107.24642841300006, 21.706592273000069],
              [107.24692941600003, 21.707183485000044],
              [107.24691797400007, 21.710131002000118],
              [107.24728629500008, 21.712490283000108],
              [107.24815199900003, 21.716383946000036],
              [107.24924507500006, 21.718616481000105],
              [107.25105566200003, 21.720977105000117],
              [107.25323260000002, 21.723697873000077],
              [107.25498702100006, 21.725590142000058],
              [107.25606031700005, 21.726514798],
              [107.25701040100006, 21.72732986900003],
              [107.26061493200004, 21.730009071000019],
              [107.26258715900006, 21.730942297000055],
              [107.2645143180001, 21.731824525000043],
              [107.27067376200009, 21.733613281000075],
              [107.27570208000003, 21.73504448700001],
              [107.27746297200005, 21.735286013000099],
              [107.278596857, 21.734936009000094],
              [107.27998373300008, 21.73423312900011],
              [107.28212476000004, 21.733768470000122],
              [107.28413863500005, 21.733657071000053],
              [107.28552157300007, 21.734015212000109],
              [107.28627401500007, 21.734725009000044],
              [107.28639724900006, 21.735432783000071],
              [107.28601625700006, 21.73637472800003],
              [107.28550854500006, 21.737552055],
              [107.28575849400005, 21.738024441000015],
              [107.28638683900007, 21.738262251000059],
              [107.29103398600009, 21.740752929000081],
              [107.29404523900011, 21.743238306000109],
              [107.29542783600002, 21.743714272000055],
              [107.29693713700003, 21.743954841000068],
              [107.29832230500006, 21.743723431000106],
              [107.29970789600004, 21.743374119000066],
              [107.30172907700003, 21.741258376000069],
              [107.30350191200007, 21.738198664000038],
              [107.30413959400008, 21.735842745000063],
              [107.30473350500002, 21.734141747000102],
              [107.30569255900005, 21.732520966000024],
              [107.30708401099999, 21.731145139000049],
              [107.31168892300006, 21.727749509000112],
              [107.31321651100006, 21.727335585000063],
              [107.31578338800009, 21.727192078000087],
              [107.31810426400008, 21.726957427000038],
              [107.3203426470001, 21.72663797800007],
              [107.31997092100008, 21.724986265000013],
              [107.31897666800006, 21.721446253000032],
              [107.31835372400003, 21.719675851000098],
              [107.31785993400004, 21.716962637000044],
              [107.31786490900001, 21.715547847000046],
              [107.31887774900005, 21.713782443000085],
              [107.32103042400009, 21.709898287000051],
              [107.32242438000004, 21.707072892000042],
              [107.32570396500006, 21.704724808],
              [107.32986462500006, 21.702261431000025],
              [107.33226128300004, 21.700500094000041],
              [107.33283050800006, 21.699617524000111],
              [107.33308984600011, 21.697378121],
              [107.33360526400003, 21.693842520000125],
              [107.33449364700009, 21.69160497700009],
              [107.33600864400003, 21.690076733000026],
              [107.33714336700004, 21.689372685000038],
              [107.33852809800003, 21.689141001000053],
              [107.3402882500001, 21.689499954],
              [107.34154676100005, 21.689385782000059],
              [107.34255485500005, 21.688917143000097],
              [107.34419598100004, 21.687271302000077],
              [107.34596487100002, 21.685036285000066],
              [107.34672487700001, 21.683505725000082],
              [107.34672884700008, 21.682326660000051],
              [107.34622840800006, 21.681499832000029],
              [107.34409487800011, 21.679960735000044],
              [107.34309123500002, 21.679132410000079],
              [107.34296702200004, 21.678660408000013],
              [107.34522743800002, 21.675644021000018],
              [107.34782995400006, 21.674596123000086],
              [107.35000036300005, 21.673222139000089],
              [107.35225873700004, 21.671442405],
              [107.35460558900006, 21.669094510000072],
              [107.35504115500004, 21.668364999000019],
              [107.35608339800004, 21.667556043000054],
              [107.35753901500004, 21.666145613000062],
              [107.35829808600005, 21.664850781000041],
              [107.35843391500001, 21.664071332000034],
              [107.35817852400001, 21.662963846000061],
              [107.35755502000004, 21.661311271000017],
              [107.35731085200007, 21.65907022400004],
              [107.35744601900007, 21.656240697000051],
              [107.35833668400005, 21.653177511000088],
              [107.35884724000002, 21.650938611000115],
              [107.35935660600006, 21.649053443000099],
              [107.360626896, 21.645283807000034],
              [107.36189518800005, 21.642103707000082],
              [107.36202368700002, 21.641278659000115],
              [107.36177755400008, 21.639627114000071],
              [107.36128142000007, 21.637503174000102],
              [107.36059294800003, 21.636498891000052],
              [107.35833285700008, 21.635313191000094],
              [107.35431546800004, 21.633061096000091],
              [107.35255854500006, 21.631876792000071],
              [107.35243592200004, 21.630933083000059],
              [107.35308168700001, 21.625864421000074],
              [107.3537160330001, 21.624215383000056],
              [107.35472454300005, 21.623510793000023],
              [107.3568657320001, 21.622573639000016],
              [107.35737271100004, 21.621395897000092],
              [107.35826360700004, 21.61821459300004],
              [107.358650649, 21.615267657000103],
              [107.35903494500009, 21.61314615800007],
              [107.35841003400007, 21.611965113000053],
              [107.35678138700005, 21.610073626000045],
              [107.35484014000011, 21.607650562000025],
              [107.35522054500005, 21.606708264000041],
              [107.35812350100008, 21.603532710000103],
              [107.36052196800003, 21.600674227000027],
              [107.35941213200003, 21.600065266000087],
              [107.35613189300005, 21.599029360000088],
              [107.35300755900009, 21.598140486000084],
              [107.34995942500004, 21.597663006000097],
              [107.34714737700007, 21.596921598000037],
              [107.34504097200005, 21.59577100800005],
              [107.34285837100003, 21.593858327000071],
              [107.34176832900008, 21.592535403000035],
              [107.33849280700004, 21.59017952700006],
              [107.33599310600007, 21.589585527000033],
              [107.33239541200003, 21.590014642000099],
              [107.32957694900006, 21.591179218],
              [107.32636577900011, 21.592855779000075],
              [107.32072918700003, 21.59503809300007],
              [107.31760146300006, 21.595175128000044],
              [107.31557136900004, 21.594435718000057],
              [107.31307479600007, 21.592961675000041],
              [107.31182548900009, 21.592517909],
              [107.31025878900003, 21.593392847000104],
              [107.30947073000002, 21.595149983000091],
              [107.30805460800009, 21.597638328000102],
              [107.30680266500006, 21.597927686000062],
              [107.30445827900007, 21.597627089000056],
              [107.30070875000007, 21.596735515000098],
              [107.29477287500001, 21.595103773000091],
              [107.29227539200005, 21.593922761000073],
              [107.29149734500007, 21.592893851000028],
              [107.29056669300007, 21.590838007000102],
              [107.28870760500007, 21.58613976000003],
              [107.28746808300011, 21.583056432000092],
              [107.28591104700006, 21.581291792000101],
              [107.28341704300003, 21.579230848000108],
              [107.28232803000007, 21.577760957000081],
              [107.28202292400005, 21.575707042000047],
              [107.28187905500005, 21.572333896000103],
              [107.28110712200004, 21.569691889000083],
              [107.27924083600007, 21.567046316000038],
              [107.27658856600002, 21.565571281000118],
              [107.27393303800002, 21.564976045000073],
              [107.27170022800001, 21.565136221],
              [107.27002354700002, 21.565256502000096],
              [107.265879807, 21.565462793000023],
              [107.26259731000002, 21.565305265000042],
              [107.25994238700008, 21.564563227000022],
              [107.25385094300003, 21.563076417000012],
              [107.249482648, 21.560715418000065],
              [107.24683126000008, 21.559093374000057],
              [107.24574320700003, 21.557476604000044],
              [107.24514068800001, 21.551608812000019],
              [107.24467684500009, 21.550287426000025],
              [107.24342796800005, 21.54984324100004],
              [107.241706691, 21.550277297000072],
              [107.23763363500007, 21.552463015000086],
              [107.23090175500005, 21.554932730000061],
              [107.2269918210001, 21.55535908000008],
              [107.22261463300003, 21.555343789000055],
              [107.21870938200003, 21.554596851000071],
              [107.21511859700011, 21.553411028000099],
              [107.21168894000003, 21.551052559000091],
              [107.20717110400005, 21.547223693000106],
              [107.20358833400009, 21.54413128700012],
              [107.20249952000006, 21.542807559000018],
              [107.20266005900002, 21.541781610000044],
              [107.20368303100003, 21.540098830000076],
              [107.206509286, 21.537029339000036],
              [107.21105547400002, 21.533819282000088],
              [107.21434333900001, 21.532511098000029],
              [107.21716814200005, 21.529734735000083],
              [107.22015093400003, 21.526518919000104],
              [107.22172748900009, 21.523151468],
              [107.22298142300009, 21.522275949000047],
              [107.22438814100005, 21.522280872000096],
              [107.227356699, 21.522584534000089],
              [107.229545505, 21.522445498000039],
              [107.23064310200004, 21.521569392000025],
              [107.23221479800006, 21.519375039000096],
              [107.233797458, 21.514394288000105],
              [107.23490307600008, 21.511465005000026],
              [107.2360109680001, 21.507949085000085],
              [107.23602135000007, 21.505309311000076],
              [107.23532815400009, 21.502740414000066],
              [107.23471572200003, 21.499511825000091],
              [107.23503694500006, 21.497313056000067],
              [107.23520073800003, 21.495407054000012],
              [107.23661589600005, 21.49321202600003],
              [107.23991093300002, 21.489850153000042],
              [107.24195337900008, 21.487070581000097],
              [107.24430945400006, 21.483998720000038],
              [107.24447199500001, 21.482385979000092],
              [107.24416516700003, 21.480918301000074],
              [107.24323555100003, 21.47886185],
              [107.24090129500007, 21.476360576000083],
              [107.23794205000009, 21.473857129000073],
              [107.23967413700005, 21.470489767000075],
              [107.24015152000003, 21.468291402000084],
              [107.23968905700005, 21.466676485000043],
              [107.23821180700003, 21.464838071000081],
              [107.23665904600007, 21.462339372000102],
              [107.2365091340001, 21.460725507000063],
              [107.23698307400005, 21.459407123000048],
              [107.23856224100007, 21.455159127000023],
              [107.23935668800007, 21.451788434000015],
              [107.24093402300011, 21.447980387000065],
              [107.24266982700003, 21.443586144000015],
              [107.243624955, 21.439042520000037],
              [107.24442779100008, 21.433471603000065],
              [107.24444147500006, 21.429951418000122],
              [107.244921554, 21.427019519000012],
              [107.24594526300005, 21.424896173],
              [107.24766825900006, 21.423728600000032],
              [107.25063591400003, 21.423885324000125],
              [107.251729471, 21.423889018000075],
              [107.25471287800011, 21.419938742000078],
              [107.25550128300006, 21.418034561000084],
              [107.25519895900001, 21.415393301000037],
              [107.25480597000009, 21.41235243900006],
              [107.2547438070001, 21.411871427000051],
              [107.25522762000007, 21.407912644000056],
              [107.25633174800004, 21.405129381000073],
              [107.25743024300006, 21.403812917000039],
              [107.2602486530001, 21.40206213400004],
              [107.26291080300011, 21.400310791],
              [107.26120259900009, 21.397664778000092],
              [107.25918484300011, 21.394284279000075],
              [107.25888362500005, 21.39134954399999],
              [107.25874364900002, 21.387095147000053],
              [107.25828793900007, 21.383719794000022],
              [107.25720805700007, 21.380195636000018],
              [107.25457481300006, 21.374465872000044],
              [107.252719228, 21.369618797],
              [107.25116421700004, 21.367853236000123],
              [107.24944853100001, 21.367260660000028],
              [107.24570256900004, 21.366661169000075],
              [107.23899272600008, 21.365171296000085],
              [107.23773470100008, 21.367367368000068],
              [107.23365733900005, 21.371607395000055],
              [107.22754475400011, 21.377013750000096],
              [107.22315458700004, 21.381252413000063],
              [107.21782177500005, 21.386807824000087],
              [107.21469082100006, 21.388557008000042],
              [107.21226892300005, 21.388768443000039],
              [107.20805645900003, 21.387579931000019],
              [107.20618873300003, 21.385959684000078],
              [107.20261557700007, 21.381252812000092],
              [107.20043557900009, 21.379631375000052],
              [107.19809580600003, 21.378889467000036],
              [107.19653395300003, 21.378883810000012],
              [107.19325161000005, 21.37945864800006],
              [107.19013094000005, 21.378713820000073],
              [107.18732388500004, 21.377676717000057],
              [107.18436314100003, 21.376052238000028],
              [107.18078575100006, 21.372518449000033],
              [107.17720537800002, 21.369718037000048],
              [107.16941775900005, 21.364701425000106],
              [107.16738938, 21.364253727000104],
              [107.16441892100008, 21.364976022000093],
              [107.15925564200008, 21.367156894000061],
              [107.15659427600005, 21.36861371400007],
              [107.15347008900001, 21.368748491000034],
              [107.14894735399999, 21.367264238000061],
              [107.14457893500003, 21.366220569000035],
              [107.1395774170001, 21.367081369000026],
              [107.13254032300004, 21.369107641000085],
              [107.12777420700004, 21.370115742000095],
              [107.12495744800005, 21.370911484000118],
              [107.12182988100008, 21.371779268000068],
              [107.11588437100002, 21.374102701000105],
              [107.11025377500009, 21.375840467],
              [107.10619098800001, 21.376264189000068],
              [107.10353518700001, 21.376400158000038],
              [107.09838180400003, 21.37623255900003],
              [107.0961993330001, 21.375343522],
              [107.09526701000006, 21.37431288200009],
              [107.09449566700002, 21.372256044000082],
              [107.093433863, 21.365503830000065],
              [107.09313861200008, 21.361835256000106],
              [107.09220911900009, 21.360217808000037],
              [107.091745401, 21.35918904400009],
              [107.09034398400004, 21.358303121000063],
              [107.08582267300005, 21.356670855000104],
              [107.08254799100008, 21.355630451000081],
              [107.08021658800001, 21.353273637000122],
              [107.07727242300004, 21.34842037300011],
              [107.07587255300002, 21.347240951000046],
              [107.0741547910001, 21.347233780000074],
              [107.07196784600006, 21.347371335000084],
              [107.06821858900001, 21.347648999000057],
              [107.06493428800005, 21.348662050000122],
              [107.06212126100009, 21.349090252000025],
              [107.06009470000001, 21.348348178],
              [107.05798044900004, 21.346769461000022],
              [107.05569404100005, 21.34351568000001],
              [107.05256153700003, 21.336743823000056],
              [107.04999417600007, 21.332137009000093],
              [107.04741635500002, 21.329692845000103],
              [107.04368097400004, 21.32859537300007],
              [107.03735863600006, 21.326945923000018],
              [107.02959771700009, 21.325289992000052],
              [107.02526537500009, 21.328515121000088],
              [107.021508393, 21.331742698000063],
              [107.01975464700003, 21.337141793000036],
              [107.01772123900001, 21.340917551000068],
              [107.01482445100004, 21.344689430000052],
              [107.00789183400011, 21.349794890000062],
              [106.99955890100004, 21.355201375000021],
              [106.99663917300005, 21.356692639000038],
              [106.99459728400004, 21.357367225000075],
              [106.99051628500006, 21.35816927400009],
              [106.98759862700005, 21.359250090000081],
              [106.98628324000002, 21.360201447000101],
              [106.98554953700007, 21.361292220000024],
              [106.98554382900004, 21.362386322000049],
              [106.9858278940001, 21.363755280000071],
              [106.98742311200002, 21.364993459000026],
              [106.98858154500003, 21.366229640000078],
              [106.98930245499999, 21.36760057700009],
              [106.99015123300005, 21.372391184000072],
              [106.99113998600006, 21.378276491000037],
              [106.9912714070001, 21.381012324],
              [106.99082321100005, 21.383198462000053],
              [106.98891311700001, 21.386472009],
              [106.98774033100005, 21.387971011000047],
              [106.98697485100004, 21.388730359000085],
              [106.9855446060001, 21.390149124000054],
              [106.98495640600002, 21.391240504000031],
              [106.98494855200008, 21.39274481000006],
              [106.98478436400009, 21.396299770000034],
              [106.98432604900009, 21.40040038700004],
              [106.98431175900006, 21.403135450000029],
              [106.98488928800006, 21.404095391000055],
              [106.9859051510001, 21.40478381500008],
              [106.98851374200009, 21.407257329000018],
              [106.98996013900005, 21.409178491000077],
              [106.990247154, 21.410000326000045],
              [106.990242892, 21.410820832000077],
              [106.989654656, 21.411912184000045],
              [106.98906356900002, 21.413550534000066],
              [106.98905788000009, 21.41464453499999],
              [106.98919925100006, 21.41546569900008],
              [106.99093913500005, 21.416977902000056],
              [106.992100018, 21.417803698000078],
              [106.99326161900009, 21.418492742000069],
              [106.996169541, 21.419463189000076],
              [107.00009911900007, 21.420027955000087],
              [107.00140570200008, 21.42085435400007],
              [107.00198336700001, 21.421814212000086],
              [107.0019770480001, 21.423044941000043],
              [107.00168084300003, 21.424000869000089],
              [107.00065638700008, 21.42495350500004],
              [106.99685471100003, 21.427808099000067],
              [106.99378472200004, 21.429982184],
              [106.99246960400004, 21.430796708000074],
              [106.99130225500008, 21.431201643000094],
              [106.99042549200001, 21.43174464900008],
              [106.99012921700009, 21.432700547000039],
              [106.98968656700006, 21.433792521000058],
              [106.989236089, 21.436388680000015],
              [106.98864777000009, 21.43747998000007],
              [106.98674708600004, 21.438838777000058],
              [106.98528550900008, 21.43978932000001],
              [106.98382748200005, 21.440056137000063],
              [106.98135126500007, 21.440044775000011],
              [106.9774206180001, 21.439616455000099],
              [106.97291024300003, 21.438638372000085],
              [106.96869195000006, 21.437524811000053],
              [106.96680204000009, 21.43683227800004],
              [106.96549839900005, 21.435458719000074],
              [106.96289774800009, 21.431480852000014],
              [106.96116160700005, 21.429284732000028],
              [106.95913346900002, 21.42722397400005],
              [106.95666696900005, 21.425434620000061],
              [106.95448665400004, 21.424603844000067],
              [106.953176554, 21.424460915000054],
              [106.95157442800004, 21.424453345000046],
              [106.95026138600011, 21.424857380000084],
              [106.94909176700006, 21.425672344000098],
              [106.94806112400005, 21.42771871700004],
              [106.94673026000007, 21.431404638000018],
              [106.94569660400008, 21.433997961],
              [106.94496238500008, 21.435088456000081],
              [106.94364777200011, 21.435765934000095],
              [106.94146143900004, 21.436029004000098],
              [106.93971356400002, 21.436020652000117],
              [106.93825998500009, 21.435466704000113],
              [106.93579730600008, 21.432993422000024],
              [106.93159137500007, 21.42969120900009],
              [106.92941639300005, 21.427902973000101],
              [106.92796366700003, 21.427212197000053],
              [106.9272323930001, 21.427755661000077],
              [106.92679394100006, 21.42802704000006],
              [106.92678941000003, 21.42884751800009],
              [106.92722031800007, 21.429943603000098],
              [106.92822933500007, 21.431862975000016],
              [106.92894177400004, 21.434738149000054],
              [106.92907537100008, 21.436926767000038],
              [106.92833201000003, 21.439658120000082],
              [106.92686635600009, 21.441291998000089],
              [106.925552393, 21.441832622000057],
              [106.92496899400004, 21.441966546000032],
              [106.92395088200001, 21.441688114],
              [106.92235543900007, 21.440449638000111],
              [106.92047325100003, 21.438389275000077],
              [106.91700260500002, 21.433859663000106],
              [106.91526696200003, 21.431663193000091],
              [106.91381424900001, 21.430972329000042],
              [106.91264979500002, 21.430829877000065],
              [106.9113389180001, 21.430823444000069],
              [106.91060912600003, 21.43109335800002],
              [106.90973368300007, 21.431362554000088],
              [106.90783097100011, 21.432994181000083],
              [106.90460654400003, 21.436533739000119],
              [106.90269448400002, 21.43980622200008],
              [106.90108450200003, 21.441165722000029],
              [106.89874618500009, 21.442521590000041],
              [106.89655505800008, 21.443604670000035],
              [106.89435768200008, 21.445781664000037],
              [106.89039275000002, 21.45136842000008],
              [106.88950936400002, 21.453004922000041],
              [106.88935822700005, 21.45396137800013],
              [106.88949061100007, 21.456286680000055],
              [106.89020335700008, 21.459025109000059],
              [106.89092391700009, 21.460396140000078],
              [106.89164838699999, 21.461083478000106],
              [106.89310124600003, 21.461774440000077],
              [106.895862865, 21.462882142000069],
              [106.89673226900008, 21.463706919000103],
              [106.89716542200004, 21.464392786000097],
              [106.89817818799999, 21.465628486000107],
              [106.90004800900007, 21.46987672100007],
              [106.90047809300003, 21.471109517000016],
              [106.900471129, 21.472340134000056],
              [106.89973652100005, 21.473430406000112],
              [106.89885621300004, 21.474519949000026],
              [106.89724746800007, 21.47560586600008],
              [106.89432830700004, 21.476548507000089],
              [106.89243125800002, 21.477085995000067],
              [106.8911161780001, 21.477763110000048],
              [106.88979639100006, 21.479260630000049],
              [106.88817738500003, 21.482124001],
              [106.88690779000007, 21.48439087600007],
              [106.88679441400009, 21.485108179000086],
              [106.88675289400004, 21.485996749000066],
              [106.88685825100005, 21.486680963000104],
              [106.88721856400011, 21.487366445000063],
              [106.88804828800002, 21.488772147000113],
              [106.88851769800004, 21.489492357000103],
              [106.88862404000005, 21.490005655000033],
              [106.88850289600006, 21.490487901000051],
              [106.88822457100012, 21.490969999000065],
              [106.888066397, 21.491177934000035],
              [106.88748284600004, 21.491567330000102],
              [106.88402711400002, 21.493124103000035],
              [106.87829926800002, 21.495867696000026],
              [106.87651044600007, 21.496695731000059],
              [106.8746119300001, 21.497209289000097],
              [106.86959019800001, 21.497916212000057],
              [106.86724809000002, 21.498008893000083],
              [106.86579859199999, 21.498001486000092],
              [106.85454267500002, 21.497001923000077],
              [106.84307197100003, 21.494640392000086],
              [106.84251448300009, 21.494637484000023],
              [106.84150850700009, 21.49505078700011],
              [106.84072302200011, 21.495883773000024],
              [106.84071365700004, 21.497453262000121],
              [106.84115154200003, 21.498815817000057],
              [106.84192455300004, 21.500075488000014],
              [106.84214444200003, 21.500599806000054],
              [106.8421388300001, 21.501541496000073],
              [106.84111783800009, 21.504465948000103],
              [106.84066557900006, 21.505509933000063],
              [106.83920977400004, 21.506548667000033],
              [106.83818219100002, 21.507092535000091],
              [106.83764370300005, 21.507377539000046],
              [106.83719392400005, 21.508002981000018],
              [106.83684089900007, 21.508909420000052],
              [106.83683635500005, 21.509668433000058],
              [106.83728754900007, 21.511514172000098],
              [106.83785301100005, 21.513577372000015],
              [106.83842691400011, 21.514230973000089],
              [106.83981167600011, 21.514563522000074],
              [106.84212221500006, 21.514684027],
              [106.84339336500007, 21.514690658000021],
              [106.84489241700003, 21.515240629000054],
              [106.84661872000004, 21.516442373000096],
              [106.84822756700002, 21.517968782000096],
              [106.84880151800007, 21.518622352000079],
              [106.84891322200008, 21.519273530000049],
              [106.848906797, 21.520357818000058],
              [106.84866732100008, 21.521766183000054],
              [106.84865960800006, 21.523067323000063],
              [106.848884298, 21.524152785000084],
              [106.84934013700006, 21.525239460000108],
              [106.85002710900005, 21.526327331000061],
              [106.85094971900001, 21.526657406000105],
              [106.85222094800012, 21.526663986000116],
              [106.85361542100003, 21.525370024000075],
              [106.85501051500009, 21.52396762100004],
              [106.85593823100002, 21.523430255000072],
              [106.85651605400005, 21.523433234000052],
              [106.85786255300007, 21.524091160000047],
              [106.8639814320001, 21.534909171000024],
              [106.86221677900002, 21.536690980000017],
              [106.85873558900002, 21.539058521000065],
              [106.85676509400005, 21.540024230000121],
              [106.85514449300007, 21.540449590000051],
              [106.8514435430001, 21.54086417000007],
              [106.85028458400004, 21.54140031100011],
              [106.84912241700007, 21.542478555000066],
              [106.848420597, 21.543884473000041],
              [106.84818173100004, 21.545184353000124],
              [106.84828573300004, 21.547136575000088],
              [106.84827801700001, 21.548437655],
              [106.84815600900005, 21.549521285000047],
              [106.84710227100008, 21.55179275100005],
              [106.84616280900006, 21.554281649000103],
              [106.84441035600003, 21.557416854000074],
              [106.84266107800005, 21.560009918000041],
              [106.84219550799999, 21.560549615000113],
              [106.84115393300006, 21.560761019000019],
              [106.83953571700002, 21.56075256300003],
              [106.83745451300003, 21.560850095000106],
              [106.83623572300009, 21.561144983000077],
              [106.83513952600006, 21.561380080000077],
              [106.83259009600008, 21.562450922000039],
              [106.8305069020001, 21.562873642000021],
              [106.82761459500006, 21.563292080000096],
              [106.82333586500008, 21.563594708],
              [106.82009872900001, 21.563685927000087],
              [106.81767137499999, 21.56367300800008],
              [106.81317205000003, 21.562239481000042],
              [106.81051951000003, 21.561249465000017],
              [106.80844227800003, 21.560696206000074],
              [106.80601762800005, 21.560249473000056],
              [106.80451434500002, 21.560349805],
              [106.79999776500006, 21.561734944000044],
              [106.7982592610001, 21.562484501000064],
              [106.797677295, 21.563131887000075],
              [106.79732315100009, 21.564322629000095],
              [106.79650798600005, 21.565294019000092],
              [106.79546230100004, 21.566155729000066],
              [106.79163367600007, 21.568411788000041],
              [106.78966052100006, 21.569702108000044],
              [106.78757038600006, 21.571208605000081],
              [106.78606023000006, 21.572392993000022],
              [106.78559107400007, 21.573474644000079],
              [106.78531895100011, 21.575397607000113],
              [106.78524996800009, 21.577186175000037],
              [106.78487088600008, 21.577726208000044],
              [106.78432146000009, 21.57777741000011],
              [106.78311007900004, 21.577391296000044],
              [106.77953511000001, 21.576016410000065],
              [106.77578645500009, 21.574694706000038],
              [106.7751661990001, 21.574444530000036],
              [106.7727885300001, 21.573485511000086],
              [106.77123041300007, 21.573097420000039],
              [106.76961313700009, 21.572925826000052],
              [106.76799413500008, 21.57302526700002],
              [106.76677902900008, 21.573235364000084],
              [106.76486759000002, 21.573875261000097],
              [106.76394146100004, 21.574086950000023],
              [106.76313264000001, 21.57402823300005],
              [106.76243976600006, 21.573915948],
              [106.76013411100006, 21.572927284000087],
              [106.75938553000003, 21.572489410000095],
              [106.75724378300001, 21.570729133000022],
              [106.75549142000006, 21.569092981],
              [106.75485983400003, 21.568438906000061],
              [106.75454596499999, 21.567813717000028],
              [106.75429005200004, 21.567161738],
              [106.75406548400011, 21.56613045800011],
              [106.75384144000006, 21.565017870000069],
              [106.75373262700002, 21.563960129000037],
              [106.75370669500009, 21.56349917900009],
              [106.7537337090001, 21.563036983000039],
              [106.75380170700005, 21.562756484000097],
              [106.75417033300009, 21.561235842000045],
              [106.75529080600006, 21.558828808000015],
              [106.75581265500008, 21.557866407000056],
              [106.75648226500006, 21.556835874000058],
              [106.75788631700006, 21.556154209000056],
              [106.75961222500004, 21.556210153000066],
              [106.75978502600005, 21.555526444000073],
              [106.75962164300003, 21.55473551500009],
              [106.75906895300004, 21.553363062000038],
              [106.75801063100005, 21.550837832000092],
              [106.75551742400008, 21.544189627000065],
              [106.75457030600005, 21.543278904000118],
              [106.75346861100012, 21.542313246000063],
              [106.75214959300004, 21.541790082000119],
              [106.75057798300004, 21.541647118000107],
              [106.74843371200008, 21.54163503400008],
              [106.74399701400007, 21.542414636000018],
              [106.74084860000008, 21.542933233000028],
              [106.73640835200004, 21.544249101000013],
              [106.73339846300004, 21.54543893700005],
              [106.73196366500008, 21.546235409000047],
              [106.72980268000006, 21.548771141000067],
              [106.72720484400006, 21.55251132500009],
              [106.72575942, 21.554916985000091],
              [106.72417538900004, 21.556651285000058],
              [106.72288431900006, 21.557314402000124],
              [106.71987679600009, 21.558101722000075],
              [106.71658065600003, 21.559289651000043],
              [106.7148606480001, 21.559950228000076],
              [106.71142326700009, 21.560869046000072],
              [106.70941639800003, 21.56166202800005],
              [106.70798230300004, 21.562324212000036],
              [106.70596825600011, 21.564189961000046],
              [106.70466723400001, 21.566328067000114],
              [106.70322052200004, 21.568867627000095],
              [106.70206868900004, 21.570067838000014],
              [106.699772153, 21.571395440000082],
              [106.69776063100002, 21.572858790000012],
              [106.69718333300003, 21.573660029000052],
              [106.69717523500007, 21.574866914000076],
              [106.69772195900001, 21.578625009000071],
              [106.69840449600008, 21.583456694000077],
              [106.69908975300004, 21.587886044000101],
              [106.69950703200001, 21.58963178700013],
              [106.70091536700009, 21.592858425000117],
              [106.70090908600011, 21.593797084000066],
              [106.70004576200009, 21.594596625000051],
              [106.69688738500005, 21.596455488000018],
              [106.6879910870001, 21.600962408000065],
              [106.68541082500003, 21.601885815000088],
              [106.68225946100006, 21.60267171400001],
              [106.67825100500005, 21.603318371000043],
              [106.67610331500006, 21.603707859000032],
              [106.674237944, 21.604635403000124],
              [106.67308754200003, 21.605567205000042],
              [106.66977561900009, 21.608899778000087],
              [106.66876909500004, 21.609698319000081],
              [106.66747932300008, 21.610092863000126],
              [106.66233773100001, 21.609123273000108],
              [106.65948327600009, 21.608301479000055],
              [106.65756332000004, 21.60743515000005],
              [106.65549598800004, 21.605863641000056],
              [106.65393229799999, 21.604513203000053],
              [106.65236026200003, 21.604369570000067],
              [106.65121627900007, 21.604362622000025],
              [106.64906479000004, 21.605288216000048],
              [106.6454748730001, 21.607411878000079],
              [106.64447294800007, 21.607539857000098],
              [106.64332989200008, 21.607398771000064],
              [106.64233264500008, 21.606856294000032],
              [106.64176815100009, 21.605780072000066],
              [106.640771855, 21.605103489000044],
              [106.63820730400008, 21.603746807000057],
              [106.63549884800007, 21.602523303000076],
              [106.63364273200006, 21.602109598000041],
              [106.63163891000008, 21.602365447000068],
              [106.62605543100004, 21.603269639000096],
              [106.62405158600006, 21.603525419000036],
              [106.62333184300012, 21.604191445000076],
              [106.62304014600005, 21.604994209000061],
              [106.62303253800005, 21.606066933000086],
              [106.62259878600003, 21.606734726000099],
              [106.62159303100003, 21.60739896700008],
              [106.61872637099999, 21.608319821000066],
              [106.6165785060001, 21.60870873],
              [106.61429050000005, 21.608694460000066],
              [106.61300637300012, 21.608284156000046],
              [106.61058592600004, 21.606793959000115],
              [106.60617125500008, 21.604218453],
              [106.59946587700003, 21.602030695000082],
              [106.59432776100007, 21.60065724400004],
              [106.59104375500004, 21.599965932000131],
              [106.5865993190001, 21.599941073000117],
              [106.58475203100002, 21.599925907000014],
              [106.58045340000007, 21.601105339000078],
              [106.57386185600004, 21.602940456000042],
              [106.57142895800008, 21.603193004000062],
              [106.57000097100006, 21.602915618000026],
              [106.55547526300008, 21.594775670000033],
              [106.55362438700006, 21.593690821000067],
              [106.55135058000003, 21.591798592000089],
              [106.55050265700004, 21.59045205500005],
              [106.55022369700006, 21.589511528000038],
              [106.55066768800002, 21.587502912000019],
              [106.55096666400007, 21.585761543],
              [106.55083168500009, 21.584687847000069],
              [106.54927491500007, 21.582532043000043],
              [106.54728823000009, 21.580507516000047],
              [106.54034677000004, 21.571879316000064],
              [106.54006889400006, 21.570804646000049],
              [106.53794350000007, 21.568242641000076],
              [106.53567317700006, 21.565947867000098],
              [106.53396666, 21.564729641000021],
              [106.53268096700003, 21.564587038000035],
              [106.53096231600003, 21.564977994000053],
              [106.52981857900006, 21.564970420000051],
              [106.52924873700007, 21.564698424000106],
              [106.52811413900008, 21.563483939000072],
              [106.52684066600006, 21.561732099000075],
              [106.52515667600008, 21.557563569000045],
              [106.52459908900003, 21.555682335000093],
              [106.52460622000009, 21.554743626000047],
              [106.52504324800002, 21.553673655000068],
              [106.52562323300005, 21.552604632000083],
              [106.52562934200002, 21.551800010000122],
              [106.52549859900003, 21.550189823000032],
              [106.52451827600001, 21.547501086000054],
              [106.52410368000008, 21.545620785],
              [106.52284158000002, 21.542393699000058],
              [106.52185929200002, 21.539973132000064],
              [106.520729993, 21.538088018000053],
              [106.520247813, 21.537225063000065],
              [106.5196017470001, 21.536068787000048],
              [106.51775674800002, 21.53431299099999],
              [106.51676227500008, 21.533501656000098],
              [106.51505104400006, 21.532953746000061],
              [106.51376557500002, 21.532811026000061],
              [106.51262716300006, 21.53213282800008],
              [106.51049534500007, 21.530509141000032],
              [106.50780108800008, 21.527674613000066],
              [106.50567761300006, 21.524977989000057],
              [106.505545, 21.523635929000037],
              [106.505842219, 21.522162645],
              [106.50656926800012, 21.520558125000022],
              [106.50916886500009, 21.517088538000095],
              [106.5110485750001, 21.514284669000091],
              [106.51278838900008, 21.511077477000029],
              [106.51540003700008, 21.505998382000065],
              [106.51886702600005, 21.501193176000029],
              [106.51901608500003, 21.500389434000056],
              [106.51888134100008, 21.49931555200002],
              [106.51661921800005, 21.49608147700004],
              [106.51435100700004, 21.49365206800011],
              [106.50952378100006, 21.489461823000063],
              [106.50781913900006, 21.488109100000052],
              [106.50496918100006, 21.487016902],
              [106.50126385900009, 21.485650637000028],
              [106.49826691600001, 21.48509386500006],
              [106.49498520600008, 21.484400986000104],
              [106.49355924100004, 21.483988928000116],
              [106.49256512700003, 21.483177411000028],
              [106.49200183200006, 21.482100566000014],
              [106.48807140900001, 21.472953079000057],
              [106.48694701400007, 21.470531078000121],
              [106.48610739100005, 21.468245135000075],
              [106.48234136900003, 21.456415779000082],
              [106.48153015700005, 21.450508329000058],
              [106.48068229600005, 21.44929530800006],
              [106.48025679600002, 21.448889981],
              [106.4666152770001, 21.439272377000052],
              [106.46476425900008, 21.43845474900008],
              [106.46319687900007, 21.437907335000084],
              [106.46033940800007, 21.437887512000131],
              [106.45876460000007, 21.438278981000011],
              [106.45767467000003, 21.438751267],
              [106.4563272230001, 21.439335127000092],
              [106.45346545200002, 21.439851740000023],
              [106.45132125600006, 21.439970911000025],
              [106.45003751900008, 21.439693668000089],
              [106.44947350600003, 21.438750760000055],
              [106.44848623200006, 21.437134193000063],
              [106.44734753600008, 21.436589673000036],
              [106.44620454800001, 21.436581667],
              [106.44334280100001, 21.437098142000096],
              [106.44234161200005, 21.437225257000044],
              [106.44133611800007, 21.437888876000066],
              [106.44046596100002, 21.439492417000046],
              [106.43930250200003, 21.442032837000014],
              [106.43800475400003, 21.443499202000012],
              [106.43699921300006, 21.444162803000033],
              [106.43599906200009, 21.444155750000071],
              [106.43457351799999, 21.443743275000045],
              [106.42445310700001, 21.440720586000062],
              [106.42269489100002, 21.440400805000067],
              [106.41978515700005, 21.439597868000071],
              [106.41605025900002, 21.437811238000037],
              [106.4137734390001, 21.436034967000062],
              [106.41233140300007, 21.43406909800003],
              [106.41067631599999, 21.432688348000092],
              [106.40797823400004, 21.431495654000088],
              [106.40507192300001, 21.430301418000042],
              [106.40353111400006, 21.429111435000081],
              [106.40330717100009, 21.427902144000122],
              [106.40484665300002, 21.424750306000107],
              [106.40634061800009, 21.422438800000073],
              [106.40862252100007, 21.420605422000079],
              [106.41036850700002, 21.419388166000083],
              [106.41246078500004, 21.418229793000073],
              [106.41393142500003, 21.416675826000109],
              [106.41437029200002, 21.413941095000055],
              [106.41438783800004, 21.411790021000066],
              [106.41398567300007, 21.410027077000038],
              [106.41295715200006, 21.4084552],
              [106.41068230200005, 21.406483258],
              [106.40777312600004, 21.405680114000077],
              [106.40527568000007, 21.405466595000085],
              [106.40277501600012, 21.405644159000033],
              [106.40089268200006, 21.406608413000058],
              [106.39880690300006, 21.406984490000092],
              [106.39589131300005, 21.406963412000032],
              [106.39360535200009, 21.406360167000038],
              [106.39194738900005, 21.405370312000024],
              [106.3905041830001, 21.40359974900008],
              [106.38885599300006, 21.401436536000062],
              [106.38679134200004, 21.39927028000006],
              [106.38264428200009, 21.397088812000042],
              [106.37724949400004, 21.39470254600004],
              [106.37475221300009, 21.394488688000109],
              [106.37120553900009, 21.395244929000064],
              [106.36890665700004, 21.396205869000084],
              [106.36785887300003, 21.396980438000043],
              [106.3678490230001, 21.398153776000051],
              [106.36679628600004, 21.399515],
              [106.36553693800002, 21.400679124000099],
              [106.36408577900004, 21.399886143000032],
              [106.36077193900003, 21.397710414000045],
              [106.35787820400006, 21.396944138000059],
              [106.35332648500003, 21.396597415000059],
              [106.34865749900007, 21.396979962],
              [106.34515896700006, 21.397922317000081],
              [106.34375832400008, 21.398299575000031],
              [106.3399734920001, 21.399210146000058],
              [106.33685512300009, 21.400125653000046],
              [106.33025249800002, 21.4044662700001],
              [106.32878464300008, 21.405628606000079],
              [106.32511941300004, 21.408382839000062],
              [106.32262321200008, 21.411090248000072],
              [106.32235444800006, 21.412066041000074],
              [106.32207850700004, 21.413872913000098],
              [106.32207217700005, 21.414606230000018],
              [106.3223270230001, 21.415243736],
              [106.32309958300007, 21.416227393000042],
              [106.32407745900007, 21.417554830000093],
              [106.32453974100012, 21.418291684000046],
              [106.32469004400004, 21.418977286000107],
              [106.32488525300002, 21.420494360000042],
              [106.32612632600004, 21.427543881000055],
              [106.32617460600004, 21.427984257000077],
              [106.32617081800008, 21.428424231000065],
              [106.32605825700008, 21.429401169000073],
              [106.32610106500007, 21.430477063000097],
              [106.32629966500008, 21.431603013000085],
              [106.32680521300007, 21.433366843],
              [106.32716676600006, 21.433711795000043],
              [106.32794699400004, 21.433815455000094],
              [106.32909003200002, 21.434117413000116],
              [106.3299185540001, 21.434661436000013],
              [106.33037879000008, 21.435642679000111],
              [106.33042204300001, 21.436669672000086],
              [106.32985915200007, 21.437521987000082],
              [106.32863328500008, 21.438182434000019],
              [106.32752718800003, 21.43959224500005],
              [106.32728003000001, 21.442290221000079],
              [106.32718186700006, 21.442834995000034],
              [106.32698229300007, 21.443617938000067],
              [106.32707868400006, 21.445047264000024],
              [106.32727850800006, 21.44708960500008],
              [106.32683583100004, 21.448004642000029],
              [106.32642845300008, 21.448353458000021],
              [106.32440068799998, 21.44931796500007],
              [106.32353740100005, 21.449439238000068],
              [106.32193497400007, 21.449090066000039],
              [106.3179195120001, 21.448345357000065],
              [106.31694392600012, 21.448031822000054],
              [106.31627138700007, 21.447403261000098],
              [106.31544168500008, 21.44577549200006],
              [106.3149008840001, 21.445465250000062],
              [106.31414004900003, 21.445459467000113],
              [106.31370351200006, 21.445660240000031],
              [106.31271288700007, 21.447081290000099],
              [106.31104615100001, 21.451252306000065],
              [106.31016861700006, 21.452163983],
              [106.30918414700005, 21.452870768000039],
              [106.308097194, 21.452862478000064],
              [106.30452538100005, 21.451100504000031],
              [106.3023639840001, 21.449655382000095],
              [106.30204859800003, 21.448428469000099],
              [106.30247629500006, 21.447269554000044],
              [106.30398910900009, 21.445697939000041],
              [106.30421377100002, 21.4448141270001],
              [106.30372109900003, 21.444200655000053],
              [106.30243056900009, 21.443750995000066],
              [106.29993069300011, 21.44373184300008],
              [106.29660093300004, 21.44414607100007],
              [106.2936744830001, 21.445133752000075],
              [106.29203861000011, 21.44599937600011],
              [106.28916563800007, 21.447958845000059],
              [106.28727483000003, 21.449879252000059],
              [106.28666225400006, 21.450923139000011],
              [106.28676464900011, 21.451638222000021],
              [106.28776924300008, 21.453088769000047],
              [106.2889255860001, 21.454855062],
              [106.28895338800001, 21.455654771000084],
              [106.28860962300007, 21.456287054000086],
              [106.28795085600009, 21.456993547000103],
              [106.28724923100005, 21.457149572000041],
              [106.28623540300001, 21.457062381000114],
              [106.28463747700005, 21.456097628000045],
              [106.28228357700006, 21.454571446000067],
              [106.28091509200007, 21.453965624000126],
              [106.28113413799998, 21.456360986000078],
              [106.2816336110001, 21.45906705700007],
              [106.28239153700011, 21.462143603000072],
              [106.28392915400005, 21.465840286000045],
              [106.28456821200008, 21.467564789000093],
              [106.28455086400007, 21.469529860000058],
              [106.2838760050001, 21.471858302000065],
              [106.28321197200005, 21.472958579000064],
              [106.2824225090001, 21.473443769000063],
              [106.28098203700002, 21.473555429000065],
              [106.28045536400005, 21.473919818000105],
              [106.28018821800006, 21.474531868000035],
              [106.280557958, 21.477114026000073],
              [106.28144027500009, 21.480928374000108],
              [106.28207937000003, 21.482652828000056],
              [106.28258978000008, 21.484130650000033],
              [106.28387669200006, 21.48659703],
              [106.28593473700009, 21.49066603000005],
              [106.28618780500007, 21.491650549000063],
              [106.28605259800007, 21.492140780000035],
              [106.28591956800008, 21.492385395000021],
              [106.28474176900005, 21.492376290000067],
              [106.28317571100007, 21.491872889000071],
              [106.28108511500002, 21.491488238000045],
              [106.27898799900005, 21.491840436000054],
              [106.27728020800006, 21.492564097000063],
              [106.27386241600007, 21.494257003000072],
              [106.27202480200008, 21.49485679400005],
              [106.27084808200006, 21.494724799000053],
              [106.26209531700007, 21.49293691800009],
              [106.260396251, 21.492677966000073],
              [106.2589644280001, 21.491806987000039],
              [106.25780535500003, 21.489709939000079],
              [106.25702788000009, 21.488844091000111],
              [106.2555927920001, 21.488341529000095],
              [106.24359221300007, 21.483948076000026],
              [106.24254865700006, 21.483571344000026],
              [106.24202521800001, 21.483567196000038],
              [106.24110364000005, 21.484173992000066],
              [106.239381307, 21.486493941000084],
              [106.23844189100008, 21.489065722000063],
              [106.23763554000007, 21.491392911000062],
              [106.23696003400003, 21.493721118000053],
              [106.23623785900006, 21.495365254000106],
              [106.23498817500004, 21.49613277600011],
              [106.23229405800006, 21.496305686000113],
              [106.22835908100009, 21.496274292000095],
              [106.22462766100008, 21.496633193000036],
              [106.21965002500008, 21.497370759000134],
              [106.21157293400005, 21.497305784000083],
              [106.20514010800008, 21.498614375],
              [106.19974094200009, 21.500125539000067],
              [106.19599855600006, 21.501650049000084],
              [106.19391481700006, 21.502993642000057],
              [106.19161850600004, 21.504918561000075],
              [106.18932577200005, 21.506454759000079],
              [106.18558136800004, 21.508173420000084],
              [106.18142630300005, 21.50949992000006],
              [106.177696365, 21.509663680000109],
              [106.17417535900007, 21.509634728000023],
              [106.16960228700006, 21.511346289000066],
              [106.16710035400008, 21.513074874],
              [106.16625902100002, 21.514428443],
              [106.16581725300006, 21.51734017200004],
              [106.16615260800009, 21.525700298000046],
              [106.16653936900003, 21.528618825000123],
              [106.16754755500006, 21.531542460000082],
              [106.16794900300005, 21.532906251000036],
              [106.16793067200004, 21.534849624000074],
              [106.16728906600007, 21.53698220000004],
              [106.16580784200008, 21.540273928000083],
              [106.16515885100003, 21.543183807000062],
              [106.16610653600002, 21.552520332000121],
              [106.16602945800003, 21.560682172000064],
              [106.16702863200001, 21.564577271000118],
              [106.16780050200002, 21.570608214000053],
              [106.16778033200004, 21.57274577500008],
              [106.16693324300012, 21.574682172000067],
              [106.16546457700011, 21.576613412000079],
              [106.16543887300001, 21.579333916000017],
              [106.16624744900004, 21.581478300000079],
              [106.16888050700003, 21.587913085000118],
              [106.16906754500005, 21.590052287000013],
              [106.16905288500008, 21.59160682700012],
              [106.16778581200005, 21.594122683000094],
              [106.16715134600004, 21.595477766000037],
              [106.16714400800007, 21.596255029000041],
              [106.16755109900008, 21.597035712000064],
              [106.16858165399999, 21.59762721000007],
              [106.170026636, 21.598222111],
              [106.17436530100008, 21.599618147000044],
              [106.17642831900005, 21.60060674800004],
              [106.18034172500003, 21.603165132000065],
              [106.18304070100005, 21.605302993000031],
              [106.18322095200004, 21.605520647],
              [106.19018854100003, 21.613933559000117],
              [106.1942879420001, 21.618825020000095],
              [106.19551870600007, 21.620195284000054],
              [106.19549703100006, 21.622526956000065],
              [106.19403008600008, 21.624263921000072],
              [106.19277036000004, 21.626002549000042],
              [106.19015845100003, 21.639389173000012],
              [106.18950043000002, 21.643270095000041],
              [106.18932463200005, 21.64532442700002],
              [106.18926779700006, 21.645988578000086],
              [106.18987145600008, 21.647936627000064],
              [106.19151328500004, 21.649698807000028],
              [106.19336784200001, 21.650879766000038],
              [106.19893705000001, 21.65383966300007],
              [106.20161357700005, 21.655804444000104],
              [106.2036665410001, 21.657958458000067],
              [106.20447946200004, 21.659713801000088],
              [106.20466697100009, 21.66185268900005],
              [106.20423083700007, 21.664180840000121],
              [106.20463461499999, 21.665349943000045],
              [106.20607123100011, 21.666915995000032],
              [106.20978087500001, 21.669277575000123],
              [106.21307602000006, 21.671635756000015],
              [106.21678582900006, 21.673997207000092],
              [106.22015918800003, 21.676909412],
              [106.22375393800009, 21.680336728000071],
              [106.22469183100006, 21.683516177000058],
              [106.22419178300008, 21.685324718000039],
              [106.22246850100004, 21.688709418000052],
              [106.22243529100005, 21.692334179000028],
              [106.22277056300008, 21.693022089000102],
              [106.224605211, 21.694317820000016],
              [106.22634976900011, 21.695696368000128],
              [106.22791516900004, 21.697129168000018],
              [106.23057028800008, 21.699907396000029],
              [106.23086201000007, 21.700578094],
              [106.23173732800009, 21.706228609000085],
              [106.23243371000007, 21.709405946000082],
              [106.23385542800003, 21.712589030000018],
              [106.23552101899999, 21.715547470000018],
              [106.23598395800011, 21.717816670000012],
              [106.23595305600003, 21.721214701000058],
              [106.23495295600006, 21.724831561000087],
              [106.23371929000004, 21.72754034600004],
              [106.23103690700006, 21.730011025000096],
              [106.22617416900005, 21.732917365000048],
              [106.2244733200001, 21.73380997],
              [106.22302254500005, 21.733798372000109],
              [106.2215738570001, 21.733560226000037],
              [106.21941849900001, 21.731277505000079],
              [106.21750915600005, 21.728543624000096],
              [106.21398000900007, 21.72780989300005],
              [106.21106043100009, 21.728604949000015],
              [106.19731174600001, 21.735178587000092],
              [106.19543689700005, 21.735841486000048],
              [106.19408426600005, 21.737744404000026],
              [106.19319409800002, 21.739510654000057],
              [106.19288508800003, 21.741418040000049],
              [106.19243680900007, 21.742642180000082],
              [106.19068691400005, 21.742900782000071],
              [106.185308193, 21.741901977000033],
              [106.18268718400003, 21.741880556000034],
              [106.18062140800008, 21.742743524000026],
              [106.17867207200008, 21.744313353000067],
              [106.16868310500004, 21.752160154000052],
              [106.16599741100005, 21.754856461000124],
              [106.163812444, 21.755744564000068],
              [106.16066662000004, 21.755945084000047],
              [106.15630306300005, 21.757041613000091],
              [106.14998540100001, 21.760160611000053],
              [106.14754982400011, 21.761952596000086],
              [106.14656951700009, 21.763303633000049],
              [106.14652621500005, 21.76783392800008],
              [106.144556843, 21.771441993000096],
              [106.13991190800009, 21.77661333700005],
              [106.13902502000006, 21.777632767000021],
              [106.13795538500003, 21.778862234000052],
              [106.13671783400004, 21.781796711000112],
              [106.135961788, 21.784961728000063],
              [106.13473942200004, 21.786310616000023],
              [106.13207460500008, 21.786741284000044],
              [106.1267624430001, 21.785790483000063],
              [106.1192824020001, 21.783915121000064],
              [106.11469594300004, 21.782970155000037],
              [106.11107253199999, 21.782486340000062],
              [106.10985663300002, 21.78315557900001],
              [106.10741376300004, 21.785626571000094],
              [106.10594049000009, 21.787879267000051],
              [106.10471125400008, 21.789907493],
              [106.10445388600002, 21.7914909610001],
              [106.10443617000006, 21.793302980000107],
              [106.1048756380001, 21.797837142],
              [106.10481142700012, 21.804405620000097],
              [106.10333354900004, 21.807111201000048],
              [106.09917486000003, 21.811832490000072],
              [106.09525119200002, 21.817235207000074],
              [106.09304069700008, 21.820613915000116],
              [106.0930161860001, 21.823105300000023],
              [106.09396153500006, 21.82537849100008],
              [106.09634501800011, 21.829023022000094],
              [106.0987419210001, 21.83130859000002],
              [106.10042866700003, 21.832002521000057],
              [106.10308975300003, 21.832025240000071],
              [106.10526256400004, 21.83249677500001],
              [106.10694270800006, 21.833870117000039],
              [106.11484231700007, 21.8425443040001],
              [106.11579681400008, 21.84391139800006],
              [106.11578140100005, 21.845496757000049],
              [106.11479385000005, 21.847526875000064],
              [106.11209064000003, 21.851807381000029],
              [106.108899003, 21.856536654000088],
              [106.10765830500003, 21.859697007000101],
              [106.10762955400006, 21.862641160000074],
              [106.10856863500004, 21.865593529000101],
              [106.11047559200007, 21.8685541030001],
              [106.1135792100001, 21.872883717000043],
              [106.11451401400008, 21.876288935000062],
              [106.11448977200003, 21.878780065000036],
              [106.11422576300004, 21.881042668000106],
              [106.11298941500006, 21.883749976000018],
              [106.11197956200007, 21.888044546000081],
              [106.11194866600002, 21.891214998000038],
              [106.11264816100007, 21.893938675000065],
              [106.11383828900004, 21.895987069000086],
              [106.1160052370001, 21.897137812],
              [106.12276142500009, 21.899233217000088],
              [106.12613628400008, 21.900620518000032],
              [106.12709991400006, 21.901081581000078],
              [106.12804385400008, 21.903580741],
              [106.12802853600006, 21.905165919000069],
              [106.1277755830001, 21.90629615800006],
              [106.12724754000001, 21.907315333],
              [106.12555587400001, 21.910580415000069],
              [106.12553615300007, 21.912618481000031],
              [106.12648889000003, 21.914211785000056],
              [106.129615454, 21.916276310000079],
              [106.13032840200002, 21.917641104000026],
              [106.13031091600008, 21.919452695000082],
              [106.12979844600005, 21.922392442000046],
              [106.12832658200003, 21.924418240000065],
              [106.12637718400003, 21.925760621000023],
              [106.12273132000009, 21.927315126000032],
              [106.11763096900006, 21.929083738000088],
              [106.11471990500007, 21.929738474000082],
              [106.11229511199998, 21.930170837000063],
              [106.10986588900008, 21.931056058000117],
              [106.10621315100009, 21.933289609],
              [106.10328202700005, 21.93598216200003],
              [106.10133012000001, 21.937550723000051],
              [106.09678380700008, 21.93920705000005],
              [106.09892946000006, 21.940875623000089],
              [106.10102637500002, 21.942209453000046],
              [106.10531617500004, 21.945347838000103],
              [106.10790636100003, 21.947625725000108],
              [106.10870279600003, 21.948384443000087],
              [106.10959602400004, 21.94951995100007],
              [106.109886433, 21.950650334000073],
              [106.10988001200009, 21.951308229000063],
              [106.10947170300005, 21.951962710000124],
              [106.10846053600005, 21.952612074000029],
              [106.10604365500011, 21.953155471000052],
              [106.10181728700009, 21.953777407000082],
              [106.10061159200006, 21.953767119000034],
              [106.10021062200011, 21.953669704000056],
              [106.09931188600009, 21.953098075000099],
              [106.09801311200002, 21.952335038000022],
              [106.09731163900005, 21.952141056000052],
              [106.09670510100005, 21.952511839000088],
              [106.09629673500011, 21.953166294000056],
              [106.09617686400004, 21.95513910600004],
              [106.09624129900004, 21.958805346000076],
              [106.09672891200007, 21.960313377000062],
              [106.09752442200008, 21.961166099000096],
              [106.09922332700005, 21.962120526000014],
              [106.10322958900005, 21.963470577000074],
              [106.10533598299999, 21.963864482000069],
              [106.10785072800005, 21.963603906000095],
              [106.10996447200006, 21.963245905000051],
              [106.11076647100008, 21.963440696000092],
              [106.11116196600003, 21.964101987],
              [106.11145424000004, 21.965044370000086],
              [106.11173643600003, 21.967020565000084],
              [106.11201222200003, 21.969654632000065],
              [106.11188057700006, 21.972849153000062],
              [106.11155254200001, 21.97557203700002],
              [106.11142822000002, 21.978014672000064],
              [106.11156962100009, 21.984125054000089],
              [106.11193858300007, 21.987511703000081],
              [106.11229290600008, 21.992401970000053],
              [106.11247740000006, 21.994095265000091],
              [106.11236774800005, 21.995034183000058],
              [106.11165602300005, 21.995874008],
              [106.11023806700005, 21.996989788000043],
              [106.10872418400002, 21.997634821000034],
              [106.10660914400009, 21.998086762000035],
              [106.10600523900004, 21.998175617000101],
              [106.10580056900007, 21.998549812000064],
              [106.10599788400005, 21.998927427000048],
              [106.10714356400007, 22.00000021699999],
              [106.1086391970001, 22.003529267000133],
              [106.10903283800008, 22.00860442900003],
              [106.10906632700009, 22.012909295000057],
              [106.10885341000005, 22.015891189000044],
              [106.10793213900004, 22.018877874000076],
              [106.10595256100002, 22.022423644000085],
              [106.10302753100004, 22.025865365000108],
              [106.09974389600004, 22.028757512000041],
              [106.09709801300005, 22.030155093000033],
              [106.09510104400003, 22.031493100000077],
              [106.094406195, 22.033263943000044],
              [106.09406470900008, 22.0349220350001],
              [106.09407913700002, 22.03679853000002],
              [106.094438446, 22.037458452000088],
              [106.09550535600009, 22.038003250000045],
              [106.09764177100001, 22.039423977000091],
              [106.09942740400005, 22.041288578],
              [106.10050801900012, 22.043599444000073],
              [106.10063462800011, 22.044702466000032],
              [106.10065086000003, 22.046799713],
              [106.10019388900005, 22.048789753000086],
              [106.09891629500004, 22.051558004000071],
              [106.09799376300001, 22.054434264000022],
              [106.09777121900007, 22.056201949000084],
              [106.09756844, 22.068180286000057],
              [106.09758208300005, 22.069946379000079],
              [106.098306053, 22.071928479000036],
              [106.09914045000002, 22.072916359000061],
              [106.10542139700004, 22.075633697000093],
              [106.1067231330001, 22.075956045000034],
              [106.10754986900011, 22.075950451000011],
              [106.10837316400003, 22.075503327000057],
              [106.10919559100003, 22.074945824000025],
              [106.11025335900007, 22.074276323000049],
              [106.11131371000005, 22.073937961000034],
              [106.11202232700006, 22.073933151000077],
              [106.11273354500003, 22.074259473000076],
              [106.11428361400003, 22.076125486000109],
              [106.11548373600003, 22.078545800000079],
              [106.11633737900004, 22.081961951000025],
              [106.11717804000006, 22.083722383000023],
              [106.11872393100006, 22.085036448000039],
              [106.11979216900008, 22.085691452000056],
              [106.12387013299998, 22.086049821000039],
              [106.12777224800008, 22.08657487600005],
              [106.13991222500002, 22.083289498000092],
              [106.14392175500004, 22.082488797000011],
              [106.14994265400003, 22.082115401000024],
              [106.1536040280001, 22.082089612000019],
              [106.154432582, 22.082304536000038],
              [106.15514573500003, 22.082851420000082],
              [106.15598507600002, 22.084390886000094],
              [106.15767461200002, 22.088794330000013],
              [106.15963116299999, 22.096948927000071],
              [106.16013883800008, 22.101250330000077],
              [106.16086475200009, 22.103342483000077],
              [106.16702044200005, 22.112074193000097],
              [106.16797460100004, 22.11317121000009],
              [106.16904144000003, 22.113605118000024],
              [106.17069532900008, 22.11359326700002],
              [106.17222285300008, 22.112588858000045],
              [106.1735122800001, 22.111365371000069],
              [106.17422107200004, 22.111360274000091],
              [106.17481447700003, 22.111687164000045],
              [106.17529158600007, 22.112235648000045],
              [106.18230743500004, 22.117704201000073],
              [106.18301993800003, 22.11814057400003],
              [106.18361892400003, 22.119129691000069],
              [106.18422621600008, 22.121112195000059],
              [106.18423912600009, 22.122657478000107],
              [106.18343057200008, 22.12487101700011],
              [106.18203034700012, 22.126978446000045],
              [106.17956491400005, 22.128872806000018],
              [106.17898244800008, 22.129870465000053],
              [106.17910611200003, 22.13053187600007],
              [106.18932499600008, 22.144421239000067],
              [106.19039213300009, 22.144854983000073],
              [106.19110109600004, 22.144849806000089],
              [106.19192541500003, 22.144512641000077],
              [106.19357499900009, 22.143948670000043],
              [106.19452026500005, 22.143941748000096],
              [106.19535203800004, 22.144487572000045],
              [106.196780205, 22.145691308000082],
              [106.19821027200001, 22.147115786000079],
              [106.20322276200005, 22.152929185000048],
              [106.20655868800003, 22.156105640000057],
              [106.22542740500009, 22.172025509000015],
              [106.2282819600001, 22.17410127600008],
              [106.23019305300005, 22.176404879000074],
              [106.23163242000004, 22.178822401000083],
              [106.23248377900003, 22.181575500000058],
              [106.23202450400005, 22.183124316000047],
              [106.231086674, 22.184014464000064],
              [106.22873342100007, 22.185246427000038],
              [106.22685481700006, 22.186695550000124],
              [106.22445433800004, 22.189307582000062],
              [106.22130887800002, 22.194629525000039],
              [106.21967982000005, 22.197622043000109],
              [106.21945609500003, 22.19935450900001],
              [106.21955433000007, 22.203120379000026],
              [106.21975038200003, 22.205789750000093],
              [106.21974271100002, 22.206721438000088],
              [106.21976182800009, 22.207114239000077],
              [106.21981570000003, 22.208418354000067],
              [106.21989912200009, 22.209320843000043],
              [106.22097831500011, 22.211078855000018],
              [106.22313007100007, 22.213822239000081],
              [106.22468125299999, 22.215466296000038],
              [106.22646602100005, 22.216777422000078],
              [106.23305497300005, 22.221218016000051],
              [106.234969339, 22.222508113000039],
              [106.23557108900005, 22.223717738000026],
              [106.23654977600002, 22.227463251000046],
              [106.23800629600001, 22.231757013000042],
              [106.23947165100002, 22.237044128000072],
              [106.2401007010001, 22.241344171000051],
              [106.24049234900009, 22.245535648000022],
              [106.24050303700008, 22.246749747000116],
              [106.241001141, 22.249089385000076],
              [106.24341770400011, 22.262125524000083],
              [106.24355156300008, 22.263890582000023],
              [106.24369712500004, 22.266980113000031],
              [106.24359155700002, 22.268415864000076],
              [106.24277834600005, 22.270077786000094],
              [106.23938375500003, 22.27407741600009],
              [106.23627735900004, 22.277246930000061],
              [106.23428229700008, 22.279028192000091],
              [106.2190181600001, 22.291947663000045],
              [106.21795361700005, 22.291955660000085],
              [106.21652946299999, 22.291414436000068],
              [106.21427067800005, 22.290106779000048],
              [106.21261094000005, 22.28967766500007],
              [106.21143195100009, 22.290128],
              [106.20990760700001, 22.291684702000047],
              [106.20839176500009, 22.294234754000094],
              [106.20513851500003, 22.301102563000086],
              [106.20303197700008, 22.303767338000036],
              [106.20193039900006, 22.306369449000037],
              [106.19947921500003, 22.310250928000031],
              [106.19843243800001, 22.312355904000114],
              [106.19845128100006, 22.314563387000064],
              [106.19907201400008, 22.317980594000097],
              [106.19957730900006, 22.321729797000067],
              [106.19970788000003, 22.323163777000055],
              [106.20007224500006, 22.324264895000034],
              [106.2017569380001, 22.327563833000049],
              [106.20447440800007, 22.334000916000015],
              [106.20651618900007, 22.337517897000048],
              [106.20951037800009, 22.341690023000105],
              [106.21261162900001, 22.344536719000097],
              [106.21464038100001, 22.346508356000072],
              [106.21737820900005, 22.34836425200006],
              [106.21821516300011, 22.349351380000115],
              [106.21833923500007, 22.350012726000031],
              [106.21835171200004, 22.35144756800009],
              [106.21812753000005, 22.352884193000122],
              [106.21673629200005, 22.356206051000079],
              [106.215572084, 22.358422389000054],
              [106.21440114300002, 22.359866112000049],
              [106.20555917900002, 22.370694242000063],
              [106.20002800700004, 22.374377897000031],
              [106.19708053900007, 22.375724274000049],
              [106.19389159200009, 22.376520500000119],
              [106.19023106600008, 22.377540888000077],
              [106.18764129900003, 22.379215623000093],
              [106.18588559799998, 22.381546496000034],
              [106.18298356200003, 22.388300943000061],
              [106.18474715700003, 22.390712453000013],
              [106.18541796200002, 22.392421265000095],
              [106.18910160400007, 22.400232448000068],
              [106.19112179900006, 22.405957935000039],
              [106.19169770800002, 22.408819570000027],
              [106.19170833100002, 22.410065315000068],
              [106.19119299500009, 22.412311588000122],
              [106.19107105700004, 22.413682891000072],
              [106.19108273700003, 22.41505320100012],
              [106.1916342330001, 22.417042444000046],
              [106.19285384100002, 22.41902676100009],
              [106.19913469800012, 22.432587081000044],
              [106.20047224500003, 22.434599023000068],
              [106.20237259500006, 22.43625514000005],
              [106.20446312200002, 22.438085618000066],
              [106.20760165700008, 22.441138947000105],
              [106.20912573400007, 22.442885698000033],
              [106.21017755600005, 22.444548063000092],
              [106.21152913600005, 22.448142135000104],
              [106.21206119500003, 22.449676516000046],
              [106.21292219800003, 22.451076577000073],
              [106.21416122900006, 22.452561679000027],
              [106.21788354200002, 22.454901846000027],
              [106.2357745680001, 22.460531807000095],
              [106.23737063200008, 22.460899343000037],
              [106.24004129400008, 22.46087883400012],
              [106.24505256000002, 22.459062938000059],
              [106.26856148400005, 22.451609100000077],
              [106.27218387000009, 22.44964173000001],
              [106.27665799900009, 22.446051839000077],
              [106.28094372900004, 22.440847623000067],
              [106.28367080300009, 22.435817257000053],
              [106.28398769900011, 22.432583331000089],
              [106.28430757300001, 22.42967250100012],
              [106.28526166500009, 22.428461431000102],
              [106.29402107400009, 22.421501043000092],
              [106.30145632000004, 22.416692477000041],
              [106.30629657900005, 22.413709988],
              [106.30903496300004, 22.41201985800005],
              [106.31165456900008, 22.410823358000087],
              [106.31326473000007, 22.410206375000108],
              [106.31476655900008, 22.409490927000064],
              [106.31716441300006, 22.408812115000032],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 37, code: "AD01", ten_tinh: "Lào Cai" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.26173072300006, 22.843361362000088],
              [104.27122495900002, 22.834491922000041],
              [104.26969952300001, 22.830628024000085],
              [104.26523362100008, 22.811466903000088],
              [104.26065114000008, 22.799537635],
              [104.25884708100003, 22.794371225000098],
              [104.25616911900005, 22.786577877000092],
              [104.25517196600006, 22.782367343000054],
              [104.25495957300006, 22.779737672000074],
              [104.25488301400004, 22.775491392000013],
              [104.25577001800008, 22.770219511000086],
              [104.25593856600007, 22.768174739000102],
              [104.25649682800008, 22.766862331000048],
              [104.2576864960001, 22.76526063900004],
              [104.26604285500007, 22.755911544000092],
              [104.26771051600011, 22.753216111000093],
              [104.26834848300005, 22.751684856000054],
              [104.26835937000007, 22.749639336000094],
              [104.26755524600006, 22.744173223000082],
              [104.26727604600005, 22.742275220000074],
              [104.26817550100003, 22.742472203000119],
              [104.26914525700008, 22.742476641000039],
              [104.27208797400002, 22.742715072000053],
              [104.27485770300004, 22.742920523000087],
              [104.27672796000006, 22.742929004000075],
              [104.27783727000009, 22.742741157000076],
              [104.280059255, 22.741722611000093],
              [104.28519914300001, 22.739045677000021],
              [104.28888126100003, 22.736940667000027],
              [104.29249466400005, 22.734706684000017],
              [104.29603638900008, 22.732922309000081],
              [104.29971523000009, 22.731395602000056],
              [104.30162144700007, 22.731114651000084],
              [104.30383883800005, 22.730931461000026],
              [104.30640027800005, 22.731199750000108],
              [104.31013566200004, 22.732180209000049],
              [104.313249, 22.732900772000072],
              [104.31539587300006, 22.732974261000024],
              [104.31768254900008, 22.732791175000067],
              [104.31983260600008, 22.732221770000116],
              [104.32226318400009, 22.7309463610001],
              [104.33709508500002, 22.721847711000095],
              [104.33828423600008, 22.719409741000085],
              [104.33898360100004, 22.717998331000011],
              [104.33951367200011, 22.715782628000092],
              [104.33952101000008, 22.714253189000111],
              [104.33952539100001, 22.713339778000119],
              [104.33937797900006, 22.711470202000058],
              [104.33900315100006, 22.706716072000077],
              [104.33852951000003, 22.704399785000092],
              [104.33746586600004, 22.702370354000045],
              [104.33228507100002, 22.692545065000047],
              [104.33235835900004, 22.691709645000024],
              [104.33270925700003, 22.690746799000024],
              [104.33368456900008, 22.68952940800002],
              [104.34747688900005, 22.679525293000054],
              [104.35039275600008, 22.67786568500005],
              [104.35466241400003, 22.675407892000088],
              [104.35653174000007, 22.674785231000079],
              [104.35771358500007, 22.674391565000107],
              [104.36034662700006, 22.673952090000071],
              [104.36276985800001, 22.673961747000071],
              [104.39123145900001, 22.680597642000052],
              [104.39662872900001, 22.681389520000025],
              [104.40407226900003, 22.66902838500009],
              [104.40876867700003, 22.659177886000045],
              [104.40691428100007, 22.657514375000076],
              [104.40479749900008, 22.656002938000071],
              [104.404430479, 22.655677457000053],
              [104.40435478300004, 22.655245007000069],
              [104.40455124600004, 22.654651496000078],
              [104.41024008100001, 22.639294416000055],
              [104.41063077200009, 22.638611586000046],
              [104.41343501200004, 22.635704707000045],
              [104.42020349600006, 22.630579246000067],
              [104.42833505699998, 22.623873737000011],
              [104.43093899600005, 22.622370378000056],
              [104.43630002599998, 22.619796254000057],
              [104.44460638600007, 22.617484267000073],
              [104.45523851100003, 22.615215773000052],
              [104.45659728600002, 22.614752181000064],
              [104.45745261900001, 22.614142827000094],
              [104.45799934000003, 22.613136259000029],
              [104.458199677, 22.611480270000058],
              [104.46196835300006, 22.599428003000099],
              [104.46208716900004, 22.598780145000049],
              [104.46205064900003, 22.598203783000059],
              [104.461318186, 22.597156897000033],
              [104.46093459300006, 22.596111187000112],
              [104.46086842800001, 22.593193761000038],
              [104.46249588900011, 22.583367188000025],
              [104.46385201800004, 22.578491708000037],
              [104.46428492000007, 22.576800453000075],
              [104.46436563800009, 22.575972382000131],
              [104.464289368, 22.575647992000114],
              [104.46386455600002, 22.575250414000081],
              [104.45821186400012, 22.573682816000051],
              [104.45185185800004, 22.574597640000071],
              [104.45049289400005, 22.57520523900007],
              [104.44874504900011, 22.576135644000047],
              [104.44723071300005, 22.576814722000016],
              [104.44567848600005, 22.57727756600012],
              [104.44288611900002, 22.577700088000086],
              [104.44114209400004, 22.577658015000068],
              [104.43095276800004, 22.576577821000043],
              [104.42889960000007, 22.576318437000076],
              [104.42747637900008, 22.576034257000096],
              [104.42679907600009, 22.575797749000067],
              [104.42494157600005, 22.575088822000033],
              [104.42327707700008, 22.574578657000096],
              [104.42294840600007, 22.574392900000092],
              [104.42277540100005, 22.574054639000082],
              [104.42274320000006, 22.573644858000108],
              [104.4231688680001, 22.571476499000106],
              [104.42320150400005, 22.570621263000042],
              [104.42244593000007, 22.565918629000059],
              [104.42107950900007, 22.563662786000101],
              [104.42083270200007, 22.563324688000108],
              [104.41907534900007, 22.559851494000057],
              [104.41902386200007, 22.558266661000026],
              [104.42043011700008, 22.555606640000022],
              [104.42160500400003, 22.552657654000093],
              [104.42161311400008, 22.55071288200012],
              [104.41992581700001, 22.546457064000066],
              [104.41584134200009, 22.540968027000119],
              [104.41437740400002, 22.538945881000046],
              [104.41345566500006, 22.536997712000058],
              [104.412550106, 22.531232033000045],
              [104.412637026, 22.528999438000064],
              [104.41357269700006, 22.527634279],
              [104.41574724600012, 22.52648970900006],
              [104.42303722200005, 22.525075402000105],
              [104.42993990700008, 22.523587373000083],
              [104.43133792800002, 22.522799979000041],
              [104.43281607200002, 22.521364591000022],
              [104.43438016100004, 22.517840631000041],
              [104.43626053100009, 22.512733088000033],
              [104.43813609500002, 22.508777984000083],
              [104.44426501300006, 22.503337233000039],
              [104.45042116000006, 22.497871768],
              [104.45174190000009, 22.496939871000073],
              [104.46027960200004, 22.492934991000091],
              [104.46229774800005, 22.491933317000075],
              [104.46563092600006, 22.491440180000041],
              [104.46733774900007, 22.490797562000076],
              [104.46881297400009, 22.489938060000014],
              [104.47027798500007, 22.488305768000046],
              [104.471341974, 22.486741065000082],
              [104.47240793400009, 22.48467130500007],
              [104.47217778100006, 22.484532912000077],
              [104.46782084800007, 22.481912912000013],
              [104.46351060700005, 22.478755759000087],
              [104.44769320800003, 22.462927655000087],
              [104.44685007400008, 22.4606918160001],
              [104.44670688700005, 22.457738061000043],
              [104.44851505100009, 22.45097336300006],
              [104.45023678299999, 22.44644129800006],
              [104.45413637600006, 22.439467538000102],
              [104.46394156200009, 22.427111053000104],
              [104.46510887500003, 22.425602260000055],
              [104.47538279700002, 22.421926356000107],
              [104.47739710000008, 22.421644758000078],
              [104.48041666400006, 22.421726533000097],
              [104.48374623800004, 22.421737211000092],
              [104.48668997500008, 22.421386424000019],
              [104.48814320600005, 22.421012888000021],
              [104.48872538900008, 22.420618563000083],
              [104.48895992200003, 22.420007034000022],
              [104.48912058500009, 22.418422856000042],
              [104.48885301000007, 22.417485604000028],
              [104.48827571400008, 22.416547361000113],
              [104.48789138700009, 22.414999584000036],
              [104.48805306300005, 22.413917510000125],
              [104.48871423500005, 22.413091240000057],
              [104.49435166800002, 22.406482083000064],
              [104.49539871200005, 22.405981131000097],
              [104.49950395100008, 22.405489676000023],
              [104.50302670600006, 22.405500560000036],
              [104.50523390000006, 22.405327246000084],
              [104.50717278000009, 22.404396754000096],
              [104.51051531700007, 22.400589223000011],
              [104.51455118000004, 22.397720126000117],
              [104.52836164500009, 22.388901062000066],
              [104.53479467000008, 22.386614674000072],
              [104.5435543250001, 22.38296598500007],
              [104.54611496700005, 22.381100354000068],
              [104.54797884700008, 22.379232724000083],
              [104.55101120400006, 22.375063303000076],
              [104.55171340800008, 22.373336469000016],
              [104.55133168900002, 22.371678675000069],
              [104.548401922, 22.368068918000091],
              [104.54609011000012, 22.364893034000133],
              [104.54516549900009, 22.363593842000014],
              [104.54486034700007, 22.362224375000039],
              [104.54540611600001, 22.361001359000099],
              [104.54944290800006, 22.35726695800011],
              [104.55239066800007, 22.355186192000076],
              [104.55479337000001, 22.354112332000042],
              [104.56167771300004, 22.355355660000065],
              [104.56639753600011, 22.355800562000105],
              [104.56926208000007, 22.355520071000079],
              [104.57383240400003, 22.354235579000111],
              [104.57732324100003, 22.351579515000083],
              [104.57887848700001, 22.349134454000108],
              [104.57903803100007, 22.347550152000025],
              [104.57881039900006, 22.346036878000049],
              [104.57711234900007, 22.344519764000026],
              [104.57325009099999, 22.342132564000039],
              [104.56907995300001, 22.339240209000089],
              [104.56668740100011, 22.337144883000093],
              [104.56468251900006, 22.334834463000064],
              [104.56383554500005, 22.333463564000056],
              [104.56368324100006, 22.332670792],
              [104.56368526500005, 22.332022507000104],
              [104.56392009200003, 22.331158751000046],
              [104.56896125200005, 22.327498557000069],
              [104.57051305600001, 22.326134035000052],
              [104.57711921900002, 22.316571042000085],
              [104.57774449600007, 22.31448370800004],
              [104.57821869500005, 22.311171424000072],
              [104.57776984100003, 22.306055913000122],
              [104.57631026200006, 22.30259451600007],
              [104.56420028400004, 22.290749030000079],
              [104.56227120700002, 22.289159112000064],
              [104.55671313400005, 22.285398308000111],
              [104.55586380200003, 22.284891754000036],
              [104.55307803200002, 22.285172235000061],
              [104.55145284300002, 22.285383862000074],
              [104.55067926700005, 22.285381723000071],
              [104.54759169700002, 22.283284182000074],
              [104.54365635100001, 22.280247778000017],
              [104.54203589100005, 22.279018652000012],
              [104.54141965300005, 22.278224546000033],
              [104.54142552500008, 22.276423735000073],
              [104.546532079, 22.25201881100007],
              [104.54840462100005, 22.246909658000021],
              [104.54965167300003, 22.243887716000096],
              [104.55073734300005, 22.242954283000046],
              [104.55213027000011, 22.242669993000064],
              [104.57214969900008, 22.246037450000067],
              [104.57323085900001, 22.246544528000101],
              [104.57468915600005, 22.250222062000091],
              [104.57576625000007, 22.252097746000032],
              [104.57676971600006, 22.252748669000063],
              [104.57823830700008, 22.253040625000061],
              [104.5803264730001, 22.253046026000064],
              [104.58500687600011, 22.251757821000062],
              [104.5846547360001, 22.248208321],
              [104.58425336600003, 22.244669937000069],
              [104.58272986200009, 22.23379826700009],
              [104.58259437500007, 22.233201261000076],
              [104.58259562900008, 22.232775068000073],
              [104.58282578000004, 22.232306850000057],
              [104.58430854100006, 22.225960457000049],
              [104.58486041600007, 22.224981632000059],
              [104.58646488400004, 22.223877637000022],
              [104.58962923600002, 22.221243278000102],
              [104.59150699000006, 22.220523478000054],
              [104.59361347000008, 22.219804236000051],
              [104.59539872300009, 22.219425111000035],
              [104.59617605700001, 22.219554897000116],
              [104.6043557510001, 22.222814035000034],
              [104.60800923400005, 22.22512429500005],
              [104.61033984500007, 22.226067524000115],
              [104.61244375400005, 22.22628564300004],
              [104.61514366600008, 22.226036337000018],
              [104.61853190600002, 22.225021470000073],
              [104.61976954000002, 22.224129370000064],
              [104.62522159700011, 22.221158695000028],
              [104.62645837200003, 22.220564876000104],
              [104.62682525800001, 22.220224764000115],
              [104.62696328900006, 22.219926748000063],
              [104.62678142200011, 22.219500145000026],
              [104.62271889200007, 22.216123871],
              [104.61915769500011, 22.213515822000126],
              [104.61737662700008, 22.212403549000022],
              [104.61673850600005, 22.211549672000075],
              [104.61400267400009, 22.208304163000037],
              [104.6135476800001, 22.207408084000058],
              [104.613649556, 22.203615230000118],
              [104.61448675700008, 22.198545538000047],
              [104.61646992000003, 22.192540928000071],
              [104.61732423800002, 22.189097179000058],
              [104.6190288170001, 22.184540921000107],
              [104.62221882000009, 22.180748887000057],
              [104.62280462500006, 22.179759347000072],
              [104.62310434000007, 22.178843726000075],
              [104.62319910900001, 22.177607985000058],
              [104.62414181000003, 22.173174195000023],
              [104.62339994700002, 22.173521797000092],
              [104.622137283, 22.174248109000061],
              [104.621178818, 22.175015633000058],
              [104.61956605500006, 22.176591875],
              [104.61830104800002, 22.178168926000104],
              [104.61766842300008, 22.178997961000043],
              [104.61727671300008, 22.179159085000052],
              [104.61697239100005, 22.179158369000085],
              [104.616494281, 22.179116742000105],
              [104.61601705400004, 22.178750995000129],
              [104.61502011800007, 22.177654796000112],
              [104.61397951200003, 22.176639519000041],
              [104.61345860500009, 22.17635468400001],
              [104.61289355100007, 22.176312836000101],
              [104.60322061800005, 22.17630983200003],
              [104.60235115700006, 22.176307711000028],
              [104.60152492300008, 22.17638672],
              [104.60074102800007, 22.176870955000091],
              [104.60017392300004, 22.177558273000031],
              [104.60004200900005, 22.17808461900006],
              [104.60003994600005, 22.178813843000086],
              [104.60007780200007, 22.180799074000035],
              [104.5999663090001, 22.18179136400007],
              [104.59979057100003, 22.182439145000018],
              [104.59939768500003, 22.183005355000063],
              [104.59856980400009, 22.183651519000058],
              [104.59804739600004, 22.183893305000076],
              [104.59717776900003, 22.183931677000054],
              [104.59617840200012, 22.183726636000053],
              [104.59461525500009, 22.183034037000127],
              [104.59227130700005, 22.181731775000024],
              [104.59157616800009, 22.181567985000058],
              [104.590358866, 22.181564933000075],
              [104.5898362250001, 22.181887720000049],
              [104.589226282, 22.182331819000112],
              [104.58863030700006, 22.182632497000029],
              [104.58738879000001, 22.181008977000083],
              [104.58623983900007, 22.180093880000037],
              [104.58371022500003, 22.178745995000071],
              [104.58155804100004, 22.178107155000056],
              [104.58220909600007, 22.174229697000044],
              [104.58237533800006, 22.172163140000031],
              [104.58251819400007, 22.170245621],
              [104.58279849500008, 22.168243217000061],
              [104.58385695200009, 22.165987085000083],
              [104.58642795800009, 22.162541439000094],
              [104.58702465900008, 22.161775804000072],
              [104.58698142100006, 22.160923306000058],
              [104.58684672100001, 22.160070568000023],
              [104.58607121000006, 22.159429309000075],
              [104.58438213400011, 22.158444760000059],
              [104.58186871900007, 22.157884295000052],
              [104.58063507900005, 22.157540167],
              [104.57976777300006, 22.15702650100004],
              [104.57963198200009, 22.156557341000038],
              [104.5796802310001, 22.155705055000041],
              [104.58051152800005, 22.152936923000063],
              [104.58184979600001, 22.148806255000061],
              [104.58299919200007, 22.146678212000026],
              [104.58345956000007, 22.145613883000067],
              [104.58346169700008, 22.144889362000072],
              [104.58309829700002, 22.144078663000052],
              [104.58091013300007, 22.141856830000073],
              [104.57945155400009, 22.140318768],
              [104.57904258800009, 22.13946531300008],
              [104.57904587600009, 22.138357207000055],
              [104.5769934460001, 22.121346647000095],
              [104.57704386700001, 22.119769842000011],
              [104.57867533400008, 22.116897222000098],
              [104.58005017400006, 22.115750024000036],
              [104.58169978300003, 22.114433053000049],
              [104.58238787000003, 22.11362503900007],
              [104.5823887490001, 22.113326695000076],
              [104.58197808600006, 22.113069924000058],
              [104.58028745000007, 22.112809878000036],
              [104.57740814400003, 22.11258935500009],
              [104.57544387300005, 22.112115434000046],
              [104.57462232000002, 22.11168709600004],
              [104.57389283100005, 22.111045895000103],
              [104.57048000600003, 22.105879957000063],
              [104.56984300500008, 22.104898036000087],
              [104.56943458500004, 22.10391670200007],
              [104.56939107400008, 22.103192047000064],
              [104.56948443600008, 22.102552988000035],
              [104.56962454000004, 22.101573110000047],
              [104.57008615000004, 22.100082630000095],
              [104.57008743900006, 22.099656431000092],
              [104.56972370000007, 22.099016170000048],
              [104.56430801600007, 22.091287639000079],
              [104.56285198800003, 22.089152753000093],
              [104.5614381340001, 22.088168719000031],
              [104.56043288100007, 22.088080786000127],
              [104.55942617800008, 22.08846165300011],
              [104.55608423100006, 22.090200032000062],
              [104.55439017300004, 22.091133056000075],
              [104.55159873300001, 22.092190916000028],
              [104.54981442400008, 22.09274007500008],
              [104.54867014399998, 22.093248363000036],
              [104.54757018800011, 22.094182944000096],
              [104.54499501000002, 22.099034460000055],
              [104.54348073000006, 22.100862885000083],
              [104.54238041000005, 22.101882686000081],
              [104.53573482400006, 22.10727664700007],
              [104.5198653960001, 22.123000029000117],
              [104.51793893500005, 22.124869610000083],
              [104.51280811800007, 22.127922987000126],
              [104.50611907500004, 22.131994352000014],
              [104.49512526100008, 22.137927421000114],
              [104.48550129700004, 22.144119786000083],
              [104.48385062100006, 22.1453931550001],
              [104.48192120100003, 22.147858954000107],
              [104.47897793000004, 22.152452453000038],
              [104.47117054200002, 22.161547823000127],
              [104.46997585600012, 22.163078237],
              [104.46694844700001, 22.165540275000062],
              [104.46410772500006, 22.166980005000077],
              [104.46218480200012, 22.167570337000072],
              [104.45962260400005, 22.167902828000052],
              [104.45815761000007, 22.168324157000093],
              [104.45650640300005, 22.169597222000093],
              [104.45572257600003, 22.171256759000087],
              [104.45525770400012, 22.173215684000077],
              [104.45496721900005, 22.177391369000077],
              [104.45468164300006, 22.180288510000068],
              [104.45449047200005, 22.182418813000112],
              [104.45393638800003, 22.183780763000094],
              [104.45292460600007, 22.18522641800007],
              [104.45209805800008, 22.186076008000022],
              [104.45136462700006, 22.186499738000087],
              [104.44825290200006, 22.186915415000016],
              [104.44651492300007, 22.186909517000061],
              [104.44395738900002, 22.185963194000102],
              [104.44249483300007, 22.185702488000025],
              [104.43974967500003, 22.185948779000064],
              [104.43819160300009, 22.186710547000033],
              [104.43708749000004, 22.188326250000046],
              [104.43552119900002, 22.191133651000072],
              [104.43358927900005, 22.193854550000047],
              [104.43101969000007, 22.195891296000077],
              [104.42652481100004, 22.198944072000067],
              [104.42304334200003, 22.200210336000033],
              [104.41764139800009, 22.201299174000063],
              [104.41526253500007, 22.201375888000108],
              [104.41371018100006, 22.200688412000069],
              [104.40276962100006, 22.191869221],
              [104.40067653100007, 22.189304408000062],
              [104.39804196900005, 22.185032841000037],
              [104.39605000900008, 22.180252174000081],
              [104.39477639000005, 22.178627939000073],
              [104.39322513000003, 22.177769796000035],
              [104.38993346100008, 22.177501799000062],
              [104.38581603400011, 22.177827280000081],
              [104.38343867200005, 22.177647827000065],
              [104.38270806800008, 22.177389359000088],
              [104.38234593100006, 22.176535606000058],
              [104.38234965700009, 22.175683247000052],
              [104.38391123000007, 22.174154892000075],
              [104.38805916200008, 22.166754877000102],
              [104.38928048200006, 22.161911571000111],
              [104.38960325000002, 22.161294813000062],
              [104.38985594000006, 22.161018737000049],
              [104.39031350099999, 22.160956523000053],
              [104.39136485900003, 22.161045692000059],
              [104.39246240799999, 22.161028469000051],
              [104.39433943100003, 22.160524026000061],
              [104.39619557100005, 22.159550671000083],
              [104.39720483400006, 22.158787261000064],
              [104.39761946, 22.158064280000112],
              [104.40039662700011, 22.150147422000096],
              [104.39986546900012, 22.146011435000013],
              [104.39844322600004, 22.141765628000073],
              [104.39794427200005, 22.140826170000061],
              [104.39721528300004, 22.140226815000027],
              [104.39425931900007, 22.139257042000082],
              [104.3946128470001, 22.138945692000078],
              [104.39662563400002, 22.136279107000064],
              [104.39730549400005, 22.135336603000063],
              [104.39944413900008, 22.132371713000062],
              [104.40092123600007, 22.130138409000018],
              [104.40162706600003, 22.128866154],
              [104.40473689200005, 22.118200349000091],
              [104.40287196600011, 22.117186792000076],
              [104.40211536000002, 22.116982194000023],
              [104.401386148, 22.116794633000048],
              [104.39932382800006, 22.116390622000011],
              [104.39802235800003, 22.11606619100008],
              [104.39628770900003, 22.115484417000083],
              [104.3950101020001, 22.114925649000035],
              [104.39450800100005, 22.114753305000079],
              [104.39428124800003, 22.114326271],
              [104.38851875400009, 22.10433192200005],
              [104.38834050900009, 22.103265776000036],
              [104.38817587800006, 22.099045850000017],
              [104.38795653300011, 22.096914061000028],
              [104.38754866500003, 22.096102771000048],
              [104.38686527700006, 22.095588782000064],
              [104.38275650400001, 22.094465210000088],
              [104.37978915800007, 22.093644218000108],
              [104.37878568000008, 22.093171590000082],
              [104.37810233400003, 22.092657562000092],
              [104.37773912100009, 22.092102128000057],
              [104.37719701100009, 22.090651009000069],
              [104.37693399699999, 22.08809285600006],
              [104.37709448600005, 22.082766057000036],
              [104.37771083500006, 22.077696712000083],
              [104.37804548500003, 22.074331053000051],
              [104.37819117900001, 22.072371118000063],
              [104.37819454200012, 22.071603991000096],
              [104.37801715600008, 22.070367353],
              [104.37802218600007, 22.069216651000097],
              [104.378345618, 22.068408103000081],
              [104.37889677400004, 22.067770907000025],
              [104.38063684200004, 22.06696774000001],
              [104.38357052400004, 22.064890476000087],
              [104.38549623500003, 22.063406043],
              [104.38604753000008, 22.062726198000071],
              [104.39900552300003, 22.045811735000065],
              [104.40199456800006, 22.041262373000059],
              [104.40599496800004, 22.035182319000057],
              [104.40704932600005, 22.034333748],
              [104.40883413100002, 22.033615664000102],
              [104.41020826200004, 22.032768221000062],
              [104.41222975600004, 22.03004783100004],
              [104.41393555600006, 22.02630340400011],
              [104.41468086800009, 22.022811239000092],
              [104.41496389500006, 22.020638647000013],
              [104.41545675000006, 22.011860707000075],
              [104.41559938800006, 22.010497381000071],
              [104.416981273, 22.007689394000082],
              [104.41808364600008, 22.006201603000086],
              [104.41877281000008, 22.005223793000091],
              [104.41928134900006, 22.003733892000099],
              [104.41954977100006, 22.002317733000076],
              [104.41955262600004, 22.001614514000067],
              [104.41937356400005, 22.000718865000032],
              [104.41916916900001, 21.999970714000128],
              [104.41950367200008, 21.998026250000088],
              [104.42070174200008, 21.995120743000115],
              [104.42505294200004, 21.985812719000108],
              [104.42632544700007, 21.981561944000113],
              [104.42777021000006, 21.976060374000014],
              [104.42792791300005, 21.972905875000045],
              [104.42768946200005, 21.96965213500004],
              [104.42675476900007, 21.966387195000081],
              [104.42644600300009, 21.961553730000013],
              [104.42538190000008, 21.957218800000078],
              [104.43062197300003, 21.948202837000075],
              [104.42502243700008, 21.936273310000054],
              [104.41733594000003, 21.932342354000056],
              [104.4011361920001, 21.920844488000043],
              [104.3939311550001, 21.920495649000046],
              [104.38543067400011, 21.912278450000052],
              [104.38415232000003, 21.900835085000026],
              [104.3745681290001, 21.89213517400006],
              [104.35388309400003, 21.877518154000072],
              [104.35110029900008, 21.877426082000092],
              [104.34153779500008, 21.88403065100006],
              [104.33790035500002, 21.886542687000073],
              [104.33231912200009, 21.896364563000056],
              [104.3298352730001, 21.901477790000087],
              [104.32670651300003, 21.907810777000059],
              [104.32396496900003, 21.913383901000124],
              [104.32298432300007, 21.919194997000076],
              [104.31208638000005, 21.92212556300008],
              [104.29744623400002, 21.926966699000069],
              [104.29096249700011, 21.929203497000046],
              [104.28469183000006, 21.937177915000028],
              [104.28190393400004, 21.941194034000056],
              [104.27179978600006, 21.936381866000062],
              [104.26436525200008, 21.933726662000105],
              [104.25538255200007, 21.932693459000063],
              [104.25098274300009, 21.933873013000046],
              [104.24632089100002, 21.93702137600004],
              [104.24007141300004, 21.94123392700007],
              [104.23052802100008, 21.944717249000064],
              [104.22070943900012, 21.948265118000073],
              [104.21515537700003, 21.949935061000048],
              [104.21152081200003, 21.950105360000101],
              [104.20793532400002, 21.947769198000074],
              [104.20655328400007, 21.944681236000108],
              [104.2065632850001, 21.944170669000052],
              [104.20919815500005, 21.940431467000018],
              [104.21689388200008, 21.936058639000052],
              [104.22592184500009, 21.927111355000051],
              [104.22951976100006, 21.921136159000106],
              [104.22918525600001, 21.915326382000089],
              [104.22916350600008, 21.906225035000126],
              [104.228241504, 21.902494673000028],
              [104.22471093500009, 21.899927319000057],
              [104.21686902600005, 21.896590084000067],
              [104.21174792000008, 21.893995431000057],
              [104.20808195100008, 21.893282873000075],
              [104.20302772300005, 21.892360810000078],
              [104.19631435200004, 21.889831457000049],
              [104.19393187100009, 21.889604958000049],
              [104.19015385300004, 21.889540273000115],
              [104.18382504000007, 21.890221175000057],
              [104.17774198400008, 21.89104542200004],
              [104.16906288500006, 21.892382041000054],
              [104.15998044900007, 21.893989958000049],
              [104.15648219500008, 21.894858241000037],
              [104.15025252000004, 21.895539900000038],
              [104.14407897300003, 21.895897317000035],
              [104.14088074500009, 21.896677676000117],
              [104.138371073, 21.897841431000039],
              [104.13264266100005, 21.900806615000064],
              [104.1262636690001, 21.906453505000023],
              [104.12124384800006, 21.9087806100001],
              [104.1182911060001, 21.909704217000048],
              [104.11289946500003, 21.910724483000088],
              [104.10791668200005, 21.91119455900008],
              [104.10059087600008, 21.914409501000073],
              [104.09518029500006, 21.916357675000064],
              [104.09218602200011, 21.916862294000069],
              [104.08980333100006, 21.91663469100007],
              [104.08757449400011, 21.916177598000083],
              [104.08002716800003, 21.905736351000101],
              [104.07805174500001, 21.905051440000101],
              [104.07516267900007, 21.905279087000018],
              [104.07127178100009, 21.905860488000073],
              [104.06506381400008, 21.907886746000123],
              [104.05748978400001, 21.911049470000066],
              [104.04795478000005, 21.915245078000019],
              [104.04245939100011, 21.916447526000034],
              [104.03578591100003, 21.916885910000069],
              [104.02626804400006, 21.917784066000046],
              [104.00921693000004, 21.919800511000084],
              [103.99784645000008, 21.926096913000045],
              [103.99361317400003, 21.93117477300008],
              [103.99051846900004, 21.936505182000055],
              [103.9895638780001, 21.939366773000032],
              [103.98972624600003, 21.941134107000067],
              [103.99096716300004, 21.943663781000033],
              [103.99473234200008, 21.949210491000045],
              [103.99516162599998, 21.95010040500005],
              [103.99298435700005, 21.959161602000066],
              [103.98706972800005, 21.966112357000078],
              [103.98290270200008, 21.967940781000017],
              [103.97258015700005, 21.976297150000065],
              [103.96992545900005, 21.984327626000066],
              [103.96604315000008, 21.989178634000069],
              [103.95447655200006, 21.999832636000072],
              [103.95446466500006, 22.000882590000082],
              [103.95565760300008, 22.00213658700001],
              [103.95995334400007, 22.005627165000071],
              [103.9629250040001, 22.009283202000063],
              [103.96368413200003, 22.011856350000066],
              [103.96363670700001, 22.014130492000028],
              [103.96206582100007, 22.016376995000094],
              [103.95705721500008, 22.020970480000109],
              [103.95392882500005, 22.023905847000066],
              [103.95166607900005, 22.027132637000065],
              [103.95165759100006, 22.028285019000023],
              [103.95264325700008, 22.030740266000045],
              [103.95385529, 22.0339172340001],
              [103.95414825200008, 22.036079929000074],
              [103.95374737700004, 22.038094133000108],
              [103.95363945900007, 22.042271052000025],
              [103.953517225, 22.048392609000089],
              [103.95260897100007, 22.056381845000047],
              [103.95140338400007, 22.062784565000101],
              [103.95014598700004, 22.065729611000059],
              [103.94742174700005, 22.068593199000119],
              [103.94640764600008, 22.069955186000101],
              [103.945609172, 22.073479359000103],
              [103.94443038900009, 22.076208781000069],
              [103.94091308700003, 22.081804157000114],
              [103.93927717400004, 22.083666247000089],
              [103.93802580000005, 22.085746908000111],
              [103.93798096600008, 22.086173769000084],
              [103.93777631100002, 22.088122175000088],
              [103.93768129100006, 22.090498437000058],
              [103.94065437200003, 22.095775693000071],
              [103.94224800799999, 22.09960344200006],
              [103.94245290200007, 22.10320611500007],
              [103.94242390800007, 22.107095370000081],
              [103.94139495300001, 22.11040193700002],
              [103.93966906200004, 22.113920053000058],
              [103.93716246300004, 22.118513470000067],
              [103.936606016, 22.120598614000109],
              [103.93659521000008, 22.1220390760001],
              [103.93666273499998, 22.123335998000115],
              [103.93757200699999, 22.125718796000093],
              [103.93778705300011, 22.127953009000031],
              [103.93704254500005, 22.134502576000067],
              [103.93607102900006, 22.14040241],
              [103.93536588200007, 22.141694286000032],
              [103.93412473000004, 22.142334425000065],
              [103.92500174800007, 22.142994831000081],
              [103.92291607400007, 22.142909050000092],
              [103.92044681200005, 22.142460571000107],
              [103.91874584700008, 22.142593356000084],
              [103.91556852600009, 22.143796714000082],
              [103.91370692200007, 22.144720673000059],
              [103.90629635500002, 22.153746408000075],
              [103.90388026300005, 22.156395138000029],
              [103.90240926100002, 22.156745374000053],
              [103.89977857300005, 22.157159787000055],
              [103.89760590200004, 22.158297526000048],
              [103.89549923700008, 22.160876190000067],
              [103.89314206300001, 22.165829976000047],
              [103.88923454700009, 22.17134935500011],
              [103.88681612400009, 22.174213872000067],
              [103.88456639300003, 22.17527885900008],
              [103.87945479700004, 22.176612269000096],
              [103.87365731500006, 22.176716344000091],
              [103.8704846200001, 22.177198572000023],
              [103.86722365900002, 22.179048572000056],
              [103.86387897300003, 22.181690199000045],
              [103.86379357900007, 22.182697956000119],
              [103.86401675700006, 22.18377988200011],
              [103.86508432900011, 22.185587944000048],
              [103.86623095300004, 22.18718047],
              [103.86869097100001, 22.188854151000022],
              [103.86991954400006, 22.189871031000052],
              [103.87059386400007, 22.192540618000045],
              [103.8710138660001, 22.198017419000045],
              [103.87007017500008, 22.200027563000013],
              [103.86773220800009, 22.202388145000015],
              [103.86563416400004, 22.203742007000038],
              [103.86299960300002, 22.204515900000033],
              [103.85936653300007, 22.204490483000058],
              [103.85626347800009, 22.205837183000106],
              [103.85493945000005, 22.207052278000013],
              [103.85469702700007, 22.208347016000026],
              [103.85405289900007, 22.21151155700003],
              [103.85098705400006, 22.217756058000049],
              [103.84957674100008, 22.220050864000115],
              [103.84778396300007, 22.221838782000077],
              [103.84475832300009, 22.223113767000058],
              [103.8411837670001, 22.225321089000083],
              [103.84024419200007, 22.226754869000054],
              [103.83990769200003, 22.230065569000068],
              [103.84065416800007, 22.23331197],
              [103.84217502400006, 22.23641984400011],
              [103.84216022600006, 22.238220332000047],
              [103.84135151000004, 22.242536013000077],
              [103.84071992800008, 22.244116023000068],
              [103.83836705200011, 22.248132568000045],
              [103.83696573000006, 22.249274922000062],
              [103.83440094900004, 22.250841073000061],
              [103.83260936400004, 22.252412737000071],
              [103.83096162700004, 22.255281833000048],
              [103.82900091400008, 22.258580792000068],
              [103.82681291600005, 22.26130190300006],
              [103.82641185600009, 22.263027567000023],
              [103.82638663000004, 22.2660523690001],
              [103.82629428600004, 22.267852290000079],
              [103.82527512600009, 22.269501451000018],
              [103.82113719800003, 22.274152991000037],
              [103.81708118900008, 22.27822880800003],
              [103.80750869600003, 22.285217029000037],
              [103.80314688400009, 22.288786082000023],
              [103.79449253400007, 22.296788553000013],
              [103.78810526400005, 22.301926592000051],
              [103.78484187600004, 22.30359469100005],
              [103.78252133600006, 22.303577306000122],
              [103.77808269900007, 22.302499606000126],
              [103.77676742300005, 22.302525707000065],
              [103.7740123670001, 22.303549229000019],
              [103.77338631500007, 22.30437275000012],
              [103.77286340100005, 22.306673501000063],
              [103.77271332200007, 22.306924778000067],
              [103.77168578000006, 22.308645196000043],
              [103.77035973800011, 22.309895539000109],
              [103.76818401700004, 22.310995369000075],
              [103.76542396500011, 22.31255885900012],
              [103.76432671900001, 22.314171002000101],
              [103.7641260450001, 22.314997717000026],
              [103.76408038500006, 22.315789606000088],
              [103.76418914000008, 22.316618688000034],
              [103.76502803500004, 22.31799349],
              [103.76763484499999, 22.320678142000091],
              [103.76895588900004, 22.32221864200001],
              [103.77058888500007, 22.325688082000013],
              [103.77103886700009, 22.327311977000086],
              [103.77087341400008, 22.328535089000034],
              [103.76977483600004, 22.330291294000034],
              [103.76832807000005, 22.332044841000084],
              [103.76832364900005, 22.33254895300005],
              [103.768358527, 22.332981357000037],
              [103.76908657400003, 22.333779121000049],
              [103.77042945800011, 22.33504970200002],
              [103.77231428700001, 22.3362883570001],
              [103.77663722500003, 22.337437399000045],
              [103.77972482100006, 22.338288927000072],
              [103.77999280600002, 22.338615045000054],
              [103.77998967300003, 22.338975136],
              [103.77967611500002, 22.339440907],
              [103.77741351200004, 22.341584512000075],
              [103.77573648900001, 22.343120320000054],
              [103.77573113800001, 22.343732458000076],
              [103.77591984900009, 22.344274051000035],
              [103.77687603200006, 22.345541657000112],
              [103.77882869300007, 22.347897095000043],
              [103.778863304, 22.348365506000079],
              [103.77850883600007, 22.349083050000026],
              [103.77741583000005, 22.350191140000049],
              [103.77398981200008, 22.352613981000054],
              [103.77138374800002, 22.354178705000024],
              [103.76913153000001, 22.355097880000052],
              [103.76769873900012, 22.355231026000055],
              [103.765261599, 22.355176445000069],
              [103.76479446800008, 22.355496990000034],
              [103.76467236400001, 22.35618025600008],
              [103.76470121100009, 22.357296808000093],
              [103.76506176900008, 22.360288453000088],
              [103.76516479100007, 22.366158980000073],
              [103.76494656400004, 22.368966154000077],
              [103.76391859500008, 22.371443052000117],
              [103.76336605900011, 22.372663186000082],
              [103.76335492000007, 22.373923466000086],
              [103.76381067600003, 22.37489924200009],
              [103.76518781800011, 22.376710294000098],
              [103.76613807600005, 22.378662117000125],
              [103.76620593900006, 22.379742954000022],
              [103.76599817400002, 22.381361847000036],
              [103.76469636800005, 22.384160736000069],
              [103.76386494600008, 22.386279017],
              [103.76365935300004, 22.387645850000034],
              [103.76350815400005, 22.389427184000077],
              [103.76298977900007, 22.391151731000036],
              [103.76189026000003, 22.392943845000076],
              [103.75857358200005, 22.395979301000054],
              [103.75432631600009, 22.399187579000035],
              [103.75085741600004, 22.401897612000084],
              [103.74874717800006, 22.404149951000107],
              [103.74667299900003, 22.406690615000088],
              [103.74463841800009, 22.409123530000038],
              [103.74152066300005, 22.411475954000082],
              [103.73735710400008, 22.413892170000068],
              [103.73588173000005, 22.414384774000062],
              [103.73301555900004, 22.414578381000091],
              [103.73150277700002, 22.414926611000041],
              [103.72916634700006, 22.41645667700007],
              [103.72792118700006, 22.417167086000056],
              [103.72694069400002, 22.417169028000039],
              [103.72669419500005, 22.417057713000062],
              [103.72529573, 22.416426187000035],
              [103.72376064400007, 22.414973675000027],
              [103.72241913500001, 22.413522657000051],
              [103.72168571300008, 22.41330080400008],
              [103.72083222300007, 22.413510103000078],
              [103.71978057600009, 22.414221982000093],
              [103.71868923300003, 22.415041556000048],
              [103.71775831700002, 22.415250241000081],
              [103.71675197000006, 22.415242251000045],
              [103.71489807700006, 22.414795393000105],
              [103.71355001200007, 22.414064468000028],
              [103.71178584800003, 22.412285923000042],
              [103.71079514000004, 22.410585550000036],
              [103.70995129500004, 22.409750584000051],
              [103.70890929000007, 22.409418176000081],
              [103.7070892080001, 22.409511658],
              [103.705288959, 22.409551256000078],
              [103.69747280400007, 22.409272390000098],
              [103.69398946700007, 22.409244277000084],
              [103.67048930100006, 22.405739780000111],
              [103.66708557200008, 22.405495697000077],
              [103.66051863800001, 22.404145061000058],
              [103.65726977000006, 22.403902052000021],
              [103.64975751300004, 22.404271592000043],
              [103.64542713600012, 22.403803248000109],
              [103.64287704500006, 22.403349744000067],
              [103.63925245400002, 22.401950924000054],
              [103.62729982200004, 22.397312708000072],
              [103.62474850400007, 22.39700296100002],
              [103.62210702599999, 22.397988722000051],
              [103.61738629800003, 22.401801386000031],
              [103.6127122450001, 22.404786091000069],
              [103.60337651100011, 22.409458865000076],
              [103.60102841800006, 22.412031193000104],
              [103.60091060000008, 22.416063160000107],
              [103.60129344000005, 22.420351518000103],
              [103.60160896800005, 22.423631042000025],
              [103.60101033800008, 22.425426294000061],
              [103.59995335200001, 22.426605417000097],
              [103.59788741700005, 22.428027847000109],
              [103.59544016899999, 22.428870785000043],
              [103.59210551300004, 22.429417880000102],
              [103.58706917100005, 22.429805997000059],
              [103.58606022000006, 22.430049222000022],
              [103.58578343000008, 22.430622940000063],
              [103.58581410900004, 22.431415377000086],
              [103.58635497100002, 22.435345055000063],
              [103.58638492600002, 22.436209521000094],
              [103.58603070500001, 22.436782548000075],
              [103.58548474900006, 22.437173858000037],
              [103.58264688, 22.438337252000018],
              [103.58018165500003, 22.439017722000095],
              [103.57897175000002, 22.439979303000079],
              [103.57783226200007, 22.441625654000049],
              [103.57695675300002, 22.443958475000066],
              [103.57615826900012, 22.446327991000103],
              [103.57521593900005, 22.447615976000087],
              [103.57408334300004, 22.448578200000036],
              [103.57299571000001, 22.448928670000079],
              [103.57089858400005, 22.449558256000032],
              [103.56933751500003, 22.450768715],
              [103.56737780300006, 22.453091853000018],
              [103.56627122700007, 22.455278527000111],
              [103.56551640000006, 22.457144240000027],
              [103.56418228700008, 22.458860757000117],
              [103.56191748100008, 22.460713005000073],
              [103.55875618800009, 22.463133350000049],
              [103.55719337100001, 22.464487680000019],
              [103.55609588100006, 22.465774155000062],
              [103.55538739700006, 22.466884053000058],
              [103.55491046000003, 22.468068052000056],
              [103.55355246500002, 22.4701983510001],
              [103.55330930400007, 22.471240397000081],
              [103.55333830800008, 22.472176867000115],
              [103.55398530800005, 22.473262910000088],
              [103.55543496100009, 22.475364378000059],
              [103.55799897800006, 22.478303980000099],
              [103.55883487100003, 22.478979961000078],
              [103.55930467700009, 22.479359885000072],
              [103.56061523100007, 22.479947719000037],
              [103.56119046000006, 22.480492976000029],
              [103.56191278400006, 22.481795713000082],
              [103.56328335100004, 22.484076442000053],
              [103.56377892500009, 22.484837029000104],
              [103.56500727800002, 22.485892205000098],
              [103.56646760900007, 22.486985434000047],
              [103.56893182400003, 22.488375651000055],
              [103.57224507100008, 22.490061417],
              [103.57394191100005, 22.490760585000125],
              [103.57467177700005, 22.491343181000012],
              [103.57565530200007, 22.492675249000094],
              [103.57737473900005, 22.494958951000044],
              [103.57802128800007, 22.496116919000109],
              [103.57955414800007, 22.497714809000065],
              [103.58189149700011, 22.500183947],
              [103.58291254300003, 22.502605483000067],
              [103.58317078900009, 22.503868038000086],
              [103.58477647899998, 22.505934624000098],
              [103.5856580870001, 22.506842573000085],
              [103.58565113500006, 22.507526669000107],
              [103.58537416200005, 22.508100371000062],
              [103.58451331800006, 22.508956999000063],
              [103.58337879500007, 22.510063273000114],
              [103.58320064500008, 22.510439795000089],
              [103.58319037400003, 22.511447939000014],
              [103.58387540800004, 22.512642236000069],
              [103.58532665900003, 22.514671449000012],
              [103.5870320600001, 22.516468820000043],
              [103.58947727, 22.519803021000051],
              [103.59352096400008, 22.52595978500009],
              [103.59366423300004, 22.527113303000057],
              [103.59264546500003, 22.528256664000068],
              [103.59077156600006, 22.529680586000019],
              [103.58781450900008, 22.530950956000062],
              [103.58471270900003, 22.531211770000056],
              [103.58245869800005, 22.531912091],
              [103.5815187370001, 22.532912039000053],
              [103.580409833, 22.53527878200012],
              [103.57920352100011, 22.539589094000064],
              [103.57789454700007, 22.546347019000024],
              [103.5767721710001, 22.550009901000045],
              [103.57420517499999, 22.554740204000012],
              [103.56889846800003, 22.562110665000112],
              [103.5664185040001, 22.56586938700007],
              [103.56464926000007, 22.568338129000054],
              [103.56441193400006, 22.568804110000059],
              [103.56440708900001, 22.569272173000066],
              [103.56459335400007, 22.569993987000103],
              [103.56550948300001, 22.571334449000076],
              [103.56688164800005, 22.573543167000039],
              [103.56898895100005, 22.575866447000067],
              [103.5702829750001, 22.578146457000067],
              [103.57035564300007, 22.578615200000037],
              [103.57000207000003, 22.579080152000053],
              [103.56894954000008, 22.579682907000098],
              [103.56723936900006, 22.580171773000046],
              [103.56572853200002, 22.580122286000034],
              [103.56402432300006, 22.580035040000013],
              [103.5630909810001, 22.580350774000024],
              [103.56203653700008, 22.581133498000035],
              [103.56008149400006, 22.582808347000061],
              [103.55473484400008, 22.586397090000077],
              [103.54971378400003, 22.588476276000065],
              [103.54648609900005, 22.58952731800008],
              [103.54508954900004, 22.589658694000022],
              [103.54335065800005, 22.589174842000048],
              [103.53751862400006, 22.58728546600004],
              [103.53190256000008, 22.585145816000107],
              [103.53071692000003, 22.58479796200001],
              [103.52773493700003, 22.590843189000061],
              [103.52704509900008, 22.592961848000037],
              [103.52530537200001, 22.596775527000098],
              [103.52581556100003, 22.596628797000037],
              [103.52747859700006, 22.596405376000028],
              [103.52857736700003, 22.596684058000079],
              [103.52966423800007, 22.597475906000049],
              [103.53153802200002, 22.600080399000078],
              [103.54377390900008, 22.620603615000064],
              [103.54752237000008, 22.62581207300007],
              [103.54859170100009, 22.627373483000035],
              [103.54964919800001, 22.62944802],
              [103.54986024800007, 22.632275747000016],
              [103.5497950650001, 22.635097959000028],
              [103.55030013300005, 22.637161461000055],
              [103.55192796200001, 22.638477252000122],
              [103.55685889500005, 22.640372044000046],
              [103.56484637400004, 22.641557321000057],
              [103.56704454100002, 22.642114266000043],
              [103.56842577100008, 22.642141644000091],
              [103.570071463, 22.642687600000052],
              [103.570306413, 22.644488995000067],
              [103.57257453000007, 22.654030832000061],
              [103.57383796800008, 22.659189224],
              [103.57489591200003, 22.661263498000039],
              [103.57704720900004, 22.663872715000096],
              [103.57839916299999, 22.665182772000044],
              [103.57863421900008, 22.666984061000051],
              [103.57744073500001, 22.670810403000097],
              [103.57122082900007, 22.676847162000065],
              [103.56730543300003, 22.678822863000022],
              [103.5664411050001, 22.680345670000079],
              [103.56612937800006, 22.681879424000059],
              [103.56403882800001, 22.687753082000128],
              [103.56323847300006, 22.691805687000063],
              [103.56259398800002, 22.694405286000041],
              [103.56158100600004, 22.697335136000071],
              [103.56074267800005, 22.700514526000056],
              [103.56066085600003, 22.704064018000025],
              [103.57878531000006, 22.731055355000059],
              [103.58219445700004, 22.732543037000049],
              [103.58674277000006, 22.734408220000098],
              [103.59014390800009, 22.736250697000045],
              [103.59421250500002, 22.742367223000045],
              [103.59565292800009, 22.746301424000045],
              [103.59673532000004, 22.749163324000051],
              [103.59967323700002, 22.754547182000096],
              [103.6027926510001, 22.75952607700011],
              [103.60783635200008, 22.765714532000025],
              [103.61160455000001, 22.768273858000043],
              [103.61676929000004, 22.773583209000044],
              [103.61763173400008, 22.775113730000029],
              [103.61785215000002, 22.776444825000027],
              [103.61786244300008, 22.778066931000041],
              [103.61747388200007, 22.779395521000097],
              [103.61576370800006, 22.783001693000045],
              [103.61574692400009, 22.783738517000081],
              [103.616205103, 22.784696383000025],
              [103.617477256, 22.786260305000013],
              [103.61882619400006, 22.787233003000026],
              [103.62432764500005, 22.790543377000105],
              [103.62630283300007, 22.791616811000011],
              [103.63475684700002, 22.796356899000081],
              [103.63653276900006, 22.797300467000078],
              [103.63724656800005, 22.79752646300004],
              [103.63777057000007, 22.797476077000042],
              [103.63885466400006, 22.797224517000103],
              [103.64382956900002, 22.795798880000092],
              [103.64560157500009, 22.795596727000067],
              [103.64682182500006, 22.794511227000044],
              [103.64995099900005, 22.792590212000086],
              [103.65263044700006, 22.791755308000106],
              [103.65295672300007, 22.791687300000071],
              [103.65603182900004, 22.791394395000083],
              [103.65628956500005, 22.791306627000019],
              [103.65903636700003, 22.78968370100003],
              [103.66098403100007, 22.787519391000011],
              [103.66297630100007, 22.783374039000037],
              [103.66354998300001, 22.778980941000079],
              [103.66330570900006, 22.774021424000068],
              [103.66406205300011, 22.772054147000098],
              [103.66624671300012, 22.76989430200009],
              [103.66888102500003, 22.768844211000072],
              [103.67437689800002, 22.767188761000057],
              [103.67846414800007, 22.764845319000045],
              [103.68377689300009, 22.760763684000018],
              [103.69635352100001, 22.750214988000025],
              [103.70482279200007, 22.742889996000045],
              [103.71681149200002, 22.731998140000087],
              [103.72821322100006, 22.720763841000036],
              [103.73692689400011, 22.713001394000095],
              [103.73937158100006, 22.709744329000081],
              [103.74608126500002, 22.695996471000065],
              [103.74709303200007, 22.693152452000028],
              [103.74834677400005, 22.690092755000087],
              [103.7495540240001, 22.688794176000023],
              [103.74982838900007, 22.688799390000014],
              [103.75098565200008, 22.688764334000041],
              [103.75134715400006, 22.688765653000061],
              [103.75311374500004, 22.689082042000038],
              [103.75888718200007, 22.690843460000032],
              [103.76290396600007, 22.691580340000073],
              [103.76327983400007, 22.691505277000076],
              [103.76446428000003, 22.691391422000073],
              [103.76472397300002, 22.691309109000102],
              [103.76696917900007, 22.690115534000107],
              [103.77452324700008, 22.681007669000053],
              [103.80611654200004, 22.646360010000066],
              [103.8116016860001, 22.639524172000058],
              [103.81482713700009, 22.63297625200007],
              [103.81857427500006, 22.62423512900007],
              [103.82160453000006, 22.61927964300012],
              [103.82177058700003, 22.619008063000123],
              [103.823961287, 22.61640556499999],
              [103.82780170900007, 22.614274394000056],
              [103.83429232800002, 22.610209948000112],
              [103.83808309900006, 22.606588264000038],
              [103.8405912540001, 22.602786549000072],
              [103.84215076400008, 22.599695185000087],
              [103.84369153900012, 22.597470220000027],
              [103.84711614000005, 22.594413448000061],
              [103.85428586100006, 22.590732672000065],
              [103.85562046000008, 22.589933141000081],
              [103.86066408300005, 22.585806520000062],
              [103.86455545900006, 22.581252226000089],
              [103.86810766700006, 22.575920471000096],
              [103.87254808400012, 22.567851179000087],
              [103.8749556610001, 22.566133150000041],
              [103.8781910390001, 22.564430368000018],
              [103.87846523000005, 22.564435425000084],
              [103.87873035700005, 22.564370291000024],
              [103.88020821700012, 22.564410506000065],
              [103.88185177500003, 22.565158788000069],
              [103.882529801, 22.566713440000093],
              [103.88295203700002, 22.569144613000077],
              [103.88386237400003, 22.570923837000031],
              [103.88551662000003, 22.571174605000088],
              [103.88589222400003, 22.571089293000043],
              [103.88797452800002, 22.570719330000074],
              [103.89078678, 22.568627870000043],
              [103.90195513400002, 22.556275243000101],
              [103.91090067900005, 22.54806712700006],
              [103.9233811500001, 22.540804331000075],
              [103.92577862900004, 22.539526132],
              [103.92747452500004, 22.53779448000008],
              [103.92790669500008, 22.534167031000059],
              [103.92916582200007, 22.530664794000096],
              [103.93149711200006, 22.528538666000131],
              [103.93174978700009, 22.528439746000025],
              [103.93361030800003, 22.527680085000057],
              [103.933881598, 22.527643114000014],
              [103.93414553900007, 22.527574112000082],
              [103.93658094000006, 22.527246849000093],
              [103.93695887600006, 22.527212734000024],
              [103.9387488670001, 22.527180018000067],
              [103.94014174700007, 22.527073533000049],
              [103.94051953000006, 22.527043612000064],
              [103.94358476800002, 22.526660763000095],
              [103.94755859900002, 22.523948706000049],
              [103.96270748800004, 22.507778779000098],
              [103.96296837800004, 22.5092022420001],
              [103.96437812200007, 22.510747066000114],
              [103.96627774900003, 22.51185385400003],
              [103.96790028900008, 22.512419379000015],
              [103.9688687870001, 22.512079367000034],
              [103.96957324900004, 22.510572729000074],
              [103.96990449100008, 22.508523112000134],
              [103.97117247300011, 22.507652241000109],
              [103.97280805100007, 22.507592363000036],
              [103.97587209100001, 22.508183816000034],
              [103.978056334, 22.509474382000057],
              [103.98182036600005, 22.513385165000074],
              [103.98675308000006, 22.519172404000095],
              [103.98812793500004, 22.52035608400007],
              [103.98898585900005, 22.520702632000052],
              [103.98983500200006, 22.520947042000024],
              [103.99194937100003, 22.520984983000055],
              [103.99790748600007, 22.519940162000026],
              [104.00000008000002, 22.519382465000071],
              [104.00216874500005, 22.518372376000073],
              [104.002973271, 22.518128607000065],
              [104.00337435600005, 22.51817255200006],
              [104.00368505700001, 22.518381768000047],
              [104.00446341200011, 22.51888675],
              [104.00838954000005, 22.522283082000079],
              [104.00927077700005, 22.523260732000061],
              [104.00978006200009, 22.523756866000078],
              [104.00991589600005, 22.523978139000086],
              [104.01026538300002, 22.524371652000049],
              [104.01038456400002, 22.524859520000092],
              [104.01038102000007, 22.525357005000046],
              [104.01028709400003, 22.526019760000082],
              [104.00987713700005, 22.527219536000054],
              [104.00919392500001, 22.529205341000043],
              [104.00855383500006, 22.531398693000071],
              [104.00789009100004, 22.533778471000062],
              [104.00707479900008, 22.5355147000001],
              [104.00635344700008, 22.536588163000104],
              [104.00500598500005, 22.537885775000085],
              [104.00446836500006, 22.538214130000078],
              [104.00337813000002, 22.539151942000046],
              [104.00429662200007, 22.541701912000086],
              [104.00569742000006, 22.54401591900006],
              [104.00668200600009, 22.545075860000097],
              [104.01223972900003, 22.550840489000024],
              [104.01258470500007, 22.552159935000034],
              [104.01243359600008, 22.553476343000064],
              [104.01192857200004, 22.554724707000062],
              [104.01107012700007, 22.555839155000079],
              [104.00978737600008, 22.55681926500008],
              [104.00872002100004, 22.557405489000089],
              [104.00807698600005, 22.558126061000024],
              [104.00800092800006, 22.558850120000074],
              [104.00785023500002, 22.56010065500007],
              [104.00783986700006, 22.561549650000053],
              [104.00783163700008, 22.562700032000023],
              [104.00782981400002, 22.562954735000048],
              [104.00803835200004, 22.563526872000047],
              [104.00867342100001, 22.563925981000033],
              [104.01228344600001, 22.564606875000059],
              [104.01341654400005, 22.564745566000077],
              [104.01397981600003, 22.565275960000086],
              [104.01418963700007, 22.565672439000117],
              [104.01418496100004, 22.566331074000075],
              [104.01375593500008, 22.566855386000029],
              [104.00451926000002, 22.574867069000071],
              [104.00373292900005, 22.575784324000026],
              [104.00344418800006, 22.576507064000047],
              [104.00344040200011, 22.577033969000034],
              [104.0040726660001, 22.577828295000032],
              [104.01150560600004, 22.579257373000061],
              [104.01178679400005, 22.579588439000098],
              [104.01213793100004, 22.580051664000052],
              [104.01234402000004, 22.580975059000075],
              [104.01218627300005, 22.583213549000014],
              [104.012096598, 22.585847647],
              [104.01164401600003, 22.589665114000113],
              [104.01162946500008, 22.591704166000071],
              [104.01200786000004, 22.594269292000035],
              [104.01266437200009, 22.595799452000072],
              [104.014643893, 22.599910547000057],
              [104.01848069500002, 22.605732909000054],
              [104.01922824600007, 22.607024880000012],
              [104.02009697900009, 22.608406109000079],
              [104.02117778400007, 22.609911551000025],
              [104.022607749, 22.613246518],
              [104.02329770300005, 22.615951232000064],
              [104.02385099400001, 22.617930588000057],
              [104.02425801100003, 22.61874246399999],
              [104.02347638400002, 22.622570679000106],
              [104.02294557900007, 22.62508626200006],
              [104.02290220500002, 22.626039052000024],
              [104.02293114500002, 22.6271284460001],
              [104.02585355000005, 22.636501622000083],
              [104.02604048400008, 22.638311787000106],
              [104.02896609100002, 22.649630117000093],
              [104.0291828520001, 22.65204234900002],
              [104.03002037000003, 22.656160156000027],
              [104.031339288, 22.660280883],
              [104.03341408900008, 22.66646252000006],
              [104.0356653920001, 22.674969789000073],
              [104.03565601500003, 22.676310842000099],
              [104.03438927500011, 22.678448983000088],
              [104.03293621600005, 22.679691920000074],
              [104.03157877800001, 22.681024822000047],
              [104.03012251400004, 22.682714738000072],
              [104.02876123300004, 22.684584039000107],
              [104.02797830600009, 22.68636742700005],
              [104.02748739200007, 22.687705550000103],
              [104.02776554100006, 22.689227159000055],
              [104.03187128400006, 22.70046610000005],
              [104.03331488600004, 22.703742446000064],
              [104.03328526200008, 22.707962942000037],
              [104.03487374900006, 22.71151245100009],
              [104.03718039500004, 22.717244669000088],
              [104.03992155500001, 22.723796378000124],
              [104.04367463700002, 22.732532507000066],
              [104.04745363700006, 22.737592654000061],
              [104.0529867430001, 22.743616166],
              [104.06609729100006, 22.757308424000122],
              [104.07182528500006, 22.761868838000062],
              [104.07474640400008, 22.763859975000052],
              [104.07744887500004, 22.76564558400009],
              [104.08081033500008, 22.767639153000019],
              [104.08431681300004, 22.769905806000068],
              [104.08650688800006, 22.771552161000038],
              [104.08853174400009, 22.775988652000123],
              [104.09722753900007, 22.792512360000039],
              [104.10173232800003, 22.799345353000078],
              [104.1075726700001, 22.804007279000068],
              [104.11530563900007, 22.811130232000032],
              [104.11925193800002, 22.813738959000048],
              [104.12089990300004, 22.814258633000058],
              [104.1231732350001, 22.814543461000092],
              [104.12574447900003, 22.814149123000057],
              [104.12898356600007, 22.81260112100005],
              [104.13141963500001, 22.810367918000019],
              [104.13333459900011, 22.80928911700002],
              [104.13575851800002, 22.808961901000124],
              [104.1392772400001, 22.80959362600008],
              [104.14623503300008, 22.811877543000044],
              [104.1534027070001, 22.815864180000034],
              [104.1578941490001, 22.819103279000075],
              [104.15980641100006, 22.821421475000037],
              [104.16140358500004, 22.822770069000065],
              [104.16276452300012, 22.823372861000024],
              [104.16469101000008, 22.823382975000058],
              [104.16734671700009, 22.82228005400011],
              [104.16919698100006, 22.82161962],
              [104.17274546600002, 22.821343787],
              [104.17494608500003, 22.821559435000083],
              [104.17758009600001, 22.822934568000086],
              [104.18174531000003, 22.826019446000068],
              [104.18437948900002, 22.827394487000063],
              [104.18705252000004, 22.828342165000045],
              [104.18907709000004, 22.828900249000036],
              [104.19073675600005, 22.829370743000062],
              [104.19345066200006, 22.830330611000079],
              [104.20008728500002, 22.832322909000077],
              [104.20261702000008, 22.833156618000046],
              [104.2038753210001, 22.833831862000054],
              [104.20963523400007, 22.835616546000011],
              [104.21242580600004, 22.836382935000096],
              [104.21378058600004, 22.835971537000077],
              [104.21559095800009, 22.834726179000064],
              [104.21740733700001, 22.83295271000005],
              [104.22001873700007, 22.833223316000058],
              [104.22390514500003, 22.833923070000047],
              [104.22690654000003, 22.835367285000082],
              [104.23107175800004, 22.83872319800011],
              [104.23326854200009, 22.83968687],
              [104.23891127200005, 22.841415862000055],
              [104.24103930100009, 22.841562162000081],
              [104.2459601410001, 22.841109010000068],
              [104.24815888200004, 22.840839453000058],
              [104.24889864000005, 22.840714431000016],
              [104.25101473400008, 22.843039016000063],
              [104.25254660800009, 22.844816207000029],
              [104.25357060800008, 22.845501785000089],
              [104.25496351700005, 22.8458486910001],
              [104.25709349200007, 22.845654417000013],
              [104.26024426200007, 22.844096426000071],
              [104.26173072300006, 22.843361362000088],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 38, code: "AD01", ten_tinh: "Long An" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.81728305400003, 10.960274538000101],
              [105.85562725500007, 10.911527971000099],
              [105.86239715500004, 10.902429184000082],
              [105.86280613000004, 10.898766267000058],
              [105.84863807100008, 10.870752432000062],
              [105.84832117700006, 10.865615517000025],
              [105.84928086500005, 10.864685824000018],
              [105.85131762100009, 10.862712689000096],
              [105.86181045000008, 10.858409164000092],
              [105.88184843600007, 10.850188909000023],
              [105.89060637400004, 10.847955973000099],
              [105.90675194400002, 10.843838411000116],
              [105.92420719500009, 10.840711593000039],
              [105.92976728700006, 10.840766481000051],
              [105.93196545600004, 10.843354873000076],
              [105.931576304, 10.845184421000019],
              [105.92519317500006, 10.853188388000133],
              [105.92510049100011, 10.862354570000042],
              [105.92575601300008, 10.863684314000109],
              [105.92837027100003, 10.868987240000115],
              [105.93720497900004, 10.875307951000044],
              [105.94715614700007, 10.881272573000087],
              [105.94823905300005, 10.884216729000061],
              [105.94591910800003, 10.893728264000034],
              [105.94399912700007, 10.90031015900008],
              [105.93831582700003, 10.912355885000034],
              [105.93826423800002, 10.917489378000054],
              [105.94160153800001, 10.917522108000098],
              [106.00240759500005, 10.863918305000107],
              [106.00388146500005, 10.86261849600003],
              [106.00743441300003, 10.859485044000026],
              [106.01258265900003, 10.855627189000037],
              [106.0127734340001, 10.855417240000074],
              [106.01729066900002, 10.85197534200004],
              [106.02834908900009, 10.843548580000027],
              [106.04394427000008, 10.832648058000059],
              [106.06044242900002, 10.821114055000079],
              [106.07310958300006, 10.814264142000061],
              [106.08944108800006, 10.811846781000066],
              [106.11617615300003, 10.813344926000033],
              [106.12456819600001, 10.813814483000071],
              [106.15981951300003, 10.815620683000036],
              [106.18878904900005, 10.790987249000048],
              [106.18779674700002, 10.828480643000125],
              [106.18528523400005, 10.842020372000059],
              [106.17842272700005, 10.85232755800005],
              [106.16063402400002, 10.879040278000039],
              [106.16009451700005, 10.886934893000076],
              [106.13398050500004, 10.915784262000015],
              [106.13398799600003, 10.917757389000114],
              [106.13715478000005, 10.925920140000043],
              [106.14776412300007, 10.941947567000019],
              [106.14409913800006, 10.952672692000041],
              [106.14346887600004, 10.958415243],
              [106.14134439500003, 10.977770165000054],
              [106.15350975300004, 10.984223740000052],
              [106.15681424600007, 10.983463644000045],
              [106.15896165900001, 10.982781515000093],
              [106.16006047300007, 10.982155213000054],
              [106.16100094799999, 10.981270291000021],
              [106.16146945900003, 10.980387175000049],
              [106.16159457700009, 10.978857328000034],
              [106.16137226200007, 10.975643918000076],
              [106.16151966100001, 10.973103036000074],
              [106.16182820900005, 10.971546568000077],
              [106.16276561100008, 10.969884019000096],
              [106.1660635480001, 10.964150123000048],
              [106.17694173400008, 10.963829881000018],
              [106.18287609900003, 10.964862950000052],
              [106.186024241, 10.965887679000017],
              [106.19948937900007, 10.969810864000083],
              [106.20332815600008, 10.973651084000034],
              [106.20370158500006, 10.974120337000047],
              [106.20659702500009, 10.975577307000071],
              [106.21646422400001, 10.977952869000072],
              [106.22110462600008, 10.979424452000062],
              [106.22748002500001, 10.982056361000046],
              [106.23327396700006, 10.984683206000055],
              [106.23732562300009, 10.987008839000099],
              [106.24342536300003, 10.987919275000111],
              [106.25042105700004, 10.987622054000033],
              [106.2614826560001, 10.984918517000073],
              [106.27155704800001, 10.982076940000061],
              [106.27320071800003, 10.983882895000095],
              [106.27483614900007, 10.984705129000092],
              [106.27821733400006, 10.985440565000102],
              [106.28006062300007, 10.985961172000101],
              [106.28199555800003, 10.987796816000104],
              [106.28387243400007, 10.990440777000019],
              [106.28487942000008, 10.992774330000097],
              [106.28475141700005, 10.995806341000057],
              [106.28254337000006, 11.002056653000112],
              [106.28035469400007, 11.005981776000072],
              [106.27591606500008, 11.008877414000027],
              [106.270806656, 11.01217186800003],
              [106.26954685700004, 11.014417394000107],
              [106.26912429300008, 11.015829375000051],
              [106.26942438200004, 11.017822359000105],
              [106.27168559200007, 11.024440648000054],
              [106.27765751400001, 11.01861056700004],
              [106.28040511500006, 11.020903752000065],
              [106.28430367000004, 11.023433382000023],
              [106.28752390300004, 11.024367928000077],
              [106.29166641300004, 11.025309926000125],
              [106.29489614900011, 11.025109084000027],
              [106.30090577100006, 11.023341208000124],
              [106.307837519, 11.021580594000023],
              [106.31501462600009, 11.018005183000044],
              [106.32196650800002, 11.013746547000023],
              [106.32868570000005, 11.009712919000057],
              [106.33609256600006, 11.006138683000087],
              [106.33980877200005, 11.002762135000042],
              [106.34167692100009, 10.999825083000035],
              [106.343323658, 10.995750987000095],
              [106.34951886100002, 10.999571600000111],
              [106.35460087900005, 10.992752926000014],
              [106.37126385000003, 10.970392207000133],
              [106.40934935100007, 10.97517213000012],
              [106.416587866, 10.969686858000037],
              [106.45144130700005, 10.942792288000101],
              [106.493630635, 10.921795167000068],
              [106.49446814700011, 10.921724442000079],
              [106.534068711, 10.902945432000076],
              [106.52503140500002, 10.874175659000061],
              [106.51161578000004, 10.821516444000066],
              [106.50803605900006, 10.806963159000114],
              [106.50038701700001, 10.789667398000089],
              [106.49897278000006, 10.786469270000064],
              [106.46369449900003, 10.758865679000083],
              [106.47265714400007, 10.747565614000097],
              [106.48057393000006, 10.737582970000046],
              [106.48736219100005, 10.729022450000068],
              [106.50761584500003, 10.718692206000034],
              [106.51817048500007, 10.724637833000104],
              [106.53316736500004, 10.688323422000055],
              [106.53378828800007, 10.686854074000072],
              [106.53592978600007, 10.6870465620001],
              [106.54740928700008, 10.688454404000057],
              [106.54979315700005, 10.683327846000077],
              [106.55168101000002, 10.676759288000069],
              [106.55194319000005, 10.672222100000093],
              [106.55106255600001, 10.666542326000082],
              [106.54786760000007, 10.662662159000112],
              [106.5469694650001, 10.659478716000057],
              [106.55025884200003, 10.655904333000075],
              [106.55091247500012, 10.655194048000014],
              [106.55554734900009, 10.650687162000059],
              [106.55854398800005, 10.650026939000055],
              [106.56060730600007, 10.651175810000078],
              [106.582899868, 10.655866360000042],
              [106.5877392350001, 10.654991211000018],
              [106.59119300100005, 10.654787477000037],
              [106.59418957700009, 10.65412675000008],
              [106.59788741199999, 10.651882072000072],
              [106.59968716600005, 10.651662427000106],
              [106.59996026200008, 10.651668964000025],
              [106.60025793400011, 10.652981082000055],
              [106.60063058500003, 10.654623680000073],
              [106.60313881600008, 10.658961186000047],
              [106.60404422100004, 10.660320014000122],
              [106.61605197600005, 10.654499013000061],
              [106.61998582300002, 10.651347738000092],
              [106.62763820400008, 10.642774217000104],
              [106.62811059800001, 10.640961806000069],
              [106.62817123700005, 10.631884773000076],
              [106.6277382910001, 10.627797136000082],
              [106.63072575300002, 10.627573586000059],
              [106.633156903, 10.627355427000039],
              [106.63348253500004, 10.62733159800011],
              [106.63745706400009, 10.626354573000095],
              [106.63878272800008, 10.62600659400008],
              [106.64107158300001, 10.629926302000035],
              [106.64381354000004, 10.632894181000017],
              [106.64701732100009, 10.63563807700003],
              [106.65159302400005, 10.63975238100001],
              [106.65917252500005, 10.642070314000039],
              [106.66377962500003, 10.641418883000052],
              [106.66534821100005, 10.640975001000095],
              [106.66567542000004, 10.640891910000059],
              [106.66649779500005, 10.640726100000018],
              [106.66942460100009, 10.642723260000063],
              [106.672086576, 10.644985214000078],
              [106.67698396600011, 10.647475498000068],
              [106.67760621300008, 10.647795546000081],
              [106.68113653600005, 10.650135620000077],
              [106.68299936400008, 10.652857122000102],
              [106.68348619600005, 10.655780560000048],
              [106.68341230400006, 10.656624180000039],
              [106.68338539900006, 10.656931349000052],
              [106.69703404900011, 10.654458243000063],
              [106.70078962300008, 10.654212455000065],
              [106.71079984000002, 10.651125353000078],
              [106.71759820000003, 10.64697422700007],
              [106.72153176600007, 10.64359416200009],
              [106.72524210700001, 10.639077992000077],
              [106.72779322800007, 10.635916380000053],
              [106.72896774300008, 10.632065615000029],
              [106.72742438300001, 10.621163547000021],
              [106.72697963800007, 10.618664626000028],
              [106.72515849000003, 10.615476578000125],
              [106.72264711100007, 10.612284320000052],
              [106.72013721400006, 10.608865135000121],
              [106.7183290140001, 10.603634837000085],
              [106.71837983100005, 10.602742612000069],
              [106.71858741000008, 10.599097974000047],
              [106.71953038300003, 10.595472993000053],
              [106.72140493700005, 10.590038349000073],
              [106.72373389800002, 10.585514174000108],
              [106.72558994800002, 10.583029371000039],
              [106.72675013600009, 10.581448000000114],
              [106.72836340700007, 10.581003953000055],
              [106.73134865300008, 10.581929686000089],
              [106.73225631800004, 10.583977420000055],
              [106.73339687, 10.585572728000068],
              [106.73499897900004, 10.58694389600001],
              [106.73707799700001, 10.585594917000039],
              [106.74055271000002, 10.581758225000025],
              [106.74403146400007, 10.577240777000084],
              [106.741573418, 10.572115067000086],
              [106.74119851300007, 10.569826699000105],
              [106.74112702500001, 10.567196206000061],
              [106.74129605300006, 10.563029697000024],
              [106.74139928900007, 10.560484893000053],
              [106.74222358800006, 10.553965860000057],
              [106.74340299800004, 10.547196702000059],
              [106.74425358900007, 10.541926865000038],
              [106.74587168400011, 10.53190132800008],
              [106.74664071900006, 10.528984868000089],
              [106.74672223800005, 10.528675712000089],
              [106.74692932300005, 10.526885160000086],
              [106.74676418500005, 10.525120245000073],
              [106.74587508900004, 10.520912565000094],
              [106.74437820300008, 10.517343788000042],
              [106.74271207000007, 10.51474541500008],
              [106.73985051000008, 10.510282585000128],
              [106.73785982400005, 10.506421808000075],
              [106.73666715200004, 10.504108655],
              [106.73598818800008, 10.501944585000087],
              [106.73553321300001, 10.50002252700004],
              [106.73541724700002, 10.499650276000059],
              [106.73529531700004, 10.499263320000106],
              [106.73466925000011, 10.498427668000064],
              [106.73366293400008, 10.497731385000096],
              [106.73147166400004, 10.496022038000062],
              [106.72610712500008, 10.492446944000021],
              [106.72061250900003, 10.488401717000071],
              [106.71787585200009, 10.486202235000073],
              [106.71526046300005, 10.483817558000029],
              [106.71108205900001, 10.479847654000094],
              [106.7060808630001, 10.475496260000037],
              [106.7038696000001, 10.473528666000041],
              [106.70282676900005, 10.472381978000021],
              [106.70153311200005, 10.471008155000037],
              [106.69970464700002, 10.468770068000042],
              [106.697008931, 10.464406182000106],
              [106.69514088200006, 10.461295978000072],
              [106.69321676400003, 10.457498274000086],
              [106.69173389499998, 10.454553746000082],
              [106.689879166, 10.450654989000084],
              [106.68965997300008, 10.450327047000128],
              [106.6875552770001, 10.447178089000085],
              [106.68500777100009, 10.444323006000021],
              [106.68356847200002, 10.442955038000077],
              [106.68216589800005, 10.441922369000077],
              [106.68058926600007, 10.440991265000042],
              [106.67859480300007, 10.440297196000079],
              [106.67635733100003, 10.439872804000048],
              [106.67410301300004, 10.439565900000057],
              [106.67127326200003, 10.439412967000104],
              [106.66970185100003, 10.439454736000096],
              [106.66809675900001, 10.439731532000083],
              [106.66722455600005, 10.43990384800013],
              [106.66582990300003, 10.440347274000048],
              [106.66363572300003, 10.441499430000077],
              [106.66201859600008, 10.442799548],
              [106.66101165100007, 10.443911956000116],
              [106.66052539000007, 10.444417739000043],
              [106.65917417200009, 10.446471362000052],
              [106.65832748400003, 10.448170062000093],
              [106.65798784100008, 10.44998355800004],
              [106.65792332400007, 10.450990408000036],
              [106.65792813100002, 10.451896253000113],
              [106.65786718000004, 10.453574104000028],
              [106.65839960300005, 10.461757679000081],
              [106.65855090600004, 10.463937671000066],
              [106.65848917000008, 10.468752502000099],
              [106.65815861000009, 10.472277036000042],
              [106.65752875500002, 10.475350191000119],
              [106.65680453300004, 10.477098592000099],
              [106.65628523800007, 10.477973611000023],
              [106.65569644200011, 10.478916089000021],
              [106.65534982700004, 10.479421158000067],
              [106.65410039500004, 10.480937430000024],
              [106.65215433000004, 10.482826371000085],
              [106.65076266100002, 10.483873665000052],
              [106.6502752480001, 10.48417814700011],
              [106.64940378300003, 10.48451818700009],
              [106.64849685800003, 10.484757740000104],
              [106.6475541140001, 10.484829722000109],
              [106.64661118000006, 10.484868158],
              [106.64559787700006, 10.484806305000037],
              [106.64384943800007, 10.484446278000091],
              [106.64173185400003, 10.483635224000041],
              [106.63931447700003, 10.482272111000055],
              [106.63850756800005, 10.481605254000035],
              [106.63784090900005, 10.481038322000112],
              [106.63727921700011, 10.480504400000077],
              [106.63601189800006, 10.478632066000058],
              [106.63518112100007, 10.476740710000064],
              [106.63457766000005, 10.47489850600005],
              [106.63397331800005, 10.472888559000102],
              [106.63323981000011, 10.469553997000068],
              [106.6329519600001, 10.46794503600008],
              [106.63194642900004, 10.462682679000055],
              [106.63104418500008, 10.459720070000065],
              [106.63106540400005, 10.459465444000047],
              [106.63054499100008, 10.458512755000092],
              [106.6297701180001, 10.457275322000024],
              [106.62861070200009, 10.455972757000074],
              [106.6268364970001, 10.453985521000051],
              [106.62560848700011, 10.452918151000022],
              [106.62431254700007, 10.452220188000092],
              [106.62382183700007, 10.451887163000071],
              [106.62175734700004, 10.451159544000086],
              [106.61922110200007, 10.450383979000042],
              [106.61645706300008, 10.449458559000034],
              [106.61383100800005, 10.448196904],
              [106.6099436530001, 10.446169908000067],
              [106.60834995400005, 10.445288833000086],
              [106.60677294300004, 10.444256672000039],
              [106.60561556700003, 10.443323060000063],
              [106.60284508500003, 10.441122585000072],
              [106.60046207800004, 10.439557620000082],
              [106.59843057400008, 10.438427039000063],
              [106.59563055900004, 10.437266736000071],
              [106.59391751600006, 10.436939768000018],
              [106.59214493400003, 10.436923441000088],
              [106.59112408500003, 10.43707111900005],
              [106.58985093300004, 10.437396199000124],
              [106.58883972100003, 10.437719973000091],
              [106.58804032800006, 10.437972248000092],
              [106.58778236100002, 10.437980416000057],
              [106.58728689500006, 10.437996104000112],
              [106.58664047400005, 10.43791450100011],
              [106.58621339000007, 10.437859004000048],
              [106.58295083200004, 10.436753215000037],
              [106.5824600450001, 10.433549327000067],
              [106.58247395800001, 10.431532483000082],
              [106.58307478000008, 10.429907469000097],
              [106.58509558800003, 10.42746086500008],
              [106.58532191600003, 10.427338089000047],
              [106.58558044900009, 10.425119436000061],
              [106.58596033800009, 10.421859280000055],
              [106.58487691300006, 10.419369622000117],
              [106.58253045500007, 10.417647179000065],
              [106.57625587500004, 10.415587777000022],
              [106.56899327900008, 10.414598608000068],
              [106.56353145400007, 10.414104699000058],
              [106.559447237, 10.413611246000086],
              [106.55740458300002, 10.413442061000032],
              [106.55613927600004, 10.414519368000084],
              [106.55597225100007, 10.415914522000056],
              [106.55641991200008, 10.419330804000102],
              [106.55668100700009, 10.424456541000014],
              [106.55558695399999, 10.425996388000057],
              [106.54675300400004, 10.430124364000013],
              [106.54503107000002, 10.430668725000112],
              [106.54282133400007, 10.430252228000112],
              [106.54094346400004, 10.428997982000075],
              [106.53876000400004, 10.426500406000104],
              [106.53551881800009, 10.422095216000036],
              [106.52782505800006, 10.43447335800005],
              [106.51909742000002, 10.427758809000041],
              [106.51662923, 10.426983240000085],
              [106.51281306200008, 10.42709374400008],
              [106.51108588199999, 10.426771188000126],
              [106.50857711500007, 10.425822477000056],
              [106.50419868100008, 10.422533196000037],
              [106.49479584500004, 10.416183012000067],
              [106.47828217600008, 10.397833951000022],
              [106.47245547600008, 10.399187856000045],
              [106.46346729400005, 10.402845594000086],
              [106.46143180300004, 10.401744671000033],
              [106.45940330300007, 10.399712934000059],
              [106.45643840800007, 10.396297210000059],
              [106.45020757900002, 10.397399502000047],
              [106.44674427500009, 10.399415534000054],
              [106.44534623700005, 10.401900551000052],
              [106.44532907700005, 10.404168954000069],
              [106.44739444500006, 10.404637963000061],
              [106.44946494700004, 10.404426434000056],
              [106.45246003500006, 10.403541146000082],
              [106.45567132300002, 10.404472234000115],
              [106.45818793900006, 10.406078724000077],
              [106.45702667600007, 10.407658167000109],
              [106.4540127760001, 10.411038802000048],
              [106.45008098500003, 10.414185769000104],
              [106.44685756000004, 10.414842496000086],
              [106.44294277500005, 10.415720895000023],
              [106.43995435600006, 10.41569868500005],
              [106.42526474700001, 10.412639741000083],
              [106.42295206900006, 10.414437218000037],
              [106.42038855800006, 10.418955097000014],
              [106.41874621200006, 10.423253061000091],
              [106.41247443500001, 10.431599582000038],
              [106.41223051800007, 10.433412648000038],
              [106.41290094400003, 10.435913241000081],
              [106.41634770400003, 10.436166158000047],
              [106.42048766900008, 10.435970519000113],
              [106.42645293300009, 10.43760342600004],
              [106.43446841700009, 10.441747011000045],
              [106.43926718000004, 10.44563953800006],
              [106.44314474700006, 10.449752007000066],
              [106.4463515690001, 10.451363885000118],
              [106.44979863900002, 10.451616259000039],
              [106.45221728400004, 10.45195560600005],
              [106.44987797600001, 10.453712003000069],
              [106.44372091500004, 10.456924672000055],
              [106.44153383200009, 10.458526547000078],
              [106.44031546200009, 10.460157668000123],
              [106.43995476400009, 10.461425085000096],
              [106.43983076800004, 10.461860794000087],
              [106.43926255900001, 10.464100917000072],
              [106.43615341700007, 10.468309873000068],
              [106.4299890030001, 10.473889740000073],
              [106.42140391200005, 10.480608895000019],
              [106.41564069300006, 10.483426397000082],
              [106.41524860100003, 10.489246672000084],
              [106.40696400400006, 10.49257885400001],
              [106.40140685400007, 10.494813785000099],
              [106.39802634200005, 10.485939466000092],
              [106.38936447900004, 10.476117196000077],
              [106.38817262000001, 10.476793309000064],
              [106.37989376800007, 10.481489413000043],
              [106.36786781500007, 10.490017773000114],
              [106.36714386100002, 10.499022946000112],
              [106.36710220400005, 10.499541081000084],
              [106.36787068600003, 10.51860602800012],
              [106.35907840300007, 10.525117450000019],
              [106.35587293000005, 10.523277170000076],
              [106.35194475900002, 10.525515325000077],
              [106.35023359600009, 10.526576800000063],
              [106.34616553100004, 10.529100226000041],
              [106.34662159000003, 10.545370509000039],
              [106.33772564500001, 10.548774122000014],
              [106.33379310800005, 10.551465813000059],
              [106.32216006500005, 10.567483773000051],
              [106.31798948500003, 10.571081016000059],
              [106.30882165200008, 10.566398829000077],
              [106.29440410200007, 10.578008808000037],
              [106.284967667, 10.588056122000109],
              [106.20331618100006, 10.580203252000063],
              [106.19812181100006, 10.581154520000078],
              [106.19643817300003, 10.580951314000052],
              [106.15872721200007, 10.579224283000064],
              [106.14559944800003, 10.578621481000104],
              [106.1441844930001, 10.578556460000053],
              [106.13151270000004, 10.577973728000066],
              [106.126726741, 10.577753438000046],
              [106.09968715000005, 10.576599840000076],
              [106.09985259200006, 10.569424420000038],
              [106.09989828600003, 10.566302267000081],
              [106.0998798140001, 10.564303337000068],
              [106.09991789600005, 10.562236858000061],
              [106.09994630000008, 10.56064205700009],
              [106.09998526300004, 10.558822626000095],
              [106.10003487100005, 10.55680100800007],
              [106.10009688000011, 10.55507133000013],
              [106.10012954900006, 10.553400162000058],
              [106.10013502400008, 10.551305855000058],
              [106.10024861800005, 10.548548277000068],
              [106.10024220100006, 10.546742482000047],
              [106.1002458840001, 10.543939396000022],
              [106.10029504100008, 10.542429889000092],
              [106.10032397100008, 10.539060714000035],
              [106.10045404600001, 10.537308347000083],
              [106.10053883900009, 10.534316314000057],
              [106.100590389, 10.533480610000099],
              [106.10061172000007, 10.531809477000118],
              [106.10061761000003, 10.529626296000048],
              [106.10061378299999, 10.528548203000108],
              [106.10054156700005, 10.523676991000029],
              [106.082651846, 10.524846812000098],
              [106.08214708600006, 10.524875625000083],
              [105.99961008100004, 10.533271011000076],
              [105.99863687600008, 10.53336980800008],
              [105.96568787700004, 10.535410725000103],
              [105.94152549100004, 10.536998327000012],
              [105.94102263000008, 10.537294439000087],
              [105.94020369100009, 10.537374696000066],
              [105.90827359300008, 10.539464898000082],
              [105.90735864100003, 10.540348886000038],
              [105.87446369200003, 10.583024575000087],
              [105.87217349499998, 10.585916594000098],
              [105.8706451460001, 10.588004190000076],
              [105.86913445100006, 10.590080784000031],
              [105.86760014300003, 10.592162899000089],
              [105.86603047800001, 10.594267013000101],
              [105.86447257500008, 10.596365613000012],
              [105.862932341, 10.598458687000088],
              [105.8614038530001, 10.600540764000053],
              [105.85988712100001, 10.602611851000086],
              [105.85838803500006, 10.604671938000097],
              [105.85688898600002, 10.606748448000046],
              [105.85536651100008, 10.608896195000026],
              [105.85228612200002, 10.61320818500011],
              [105.85075495100008, 10.615326756000075],
              [105.84352012400009, 10.625000165000118],
              [105.84236315200012, 10.626519843],
              [105.83927518200005, 10.630580870000104],
              [105.83773667400004, 10.632613913000098],
              [105.836192576, 10.634621476000026],
              [105.83467589400001, 10.636608571000037],
              [105.83255684700001, 10.639377227000056],
              [105.8319698170001, 10.639725429000089],
              [105.82624234400005, 10.643191799000105],
              [105.8205364410001, 10.646550964000044],
              [105.80464903700008, 10.655123424000033],
              [105.7997006320001, 10.656218636000057],
              [105.78112954100007, 10.659685142000084],
              [105.77897859600009, 10.660086528000077],
              [105.77716819900004, 10.661236319000087],
              [105.76474766800007, 10.672885220000071],
              [105.76347118800005, 10.674082293000085],
              [105.75307901900005, 10.68382726500001],
              [105.74493706100006, 10.69146130600007],
              [105.74474904100005, 10.6916366410001],
              [105.73190501500004, 10.703612913000081],
              [105.72605812900005, 10.709064128000099],
              [105.72178673300004, 10.713046212000064],
              [105.71896243200004, 10.71567909800004],
              [105.70791781400006, 10.725956395000045],
              [105.70475936900002, 10.728895141000104],
              [105.68977860500004, 10.742832244000054],
              [105.68924142200009, 10.743331953000011],
              [105.66781866100011, 10.76325749000002],
              [105.64594725700005, 10.783594688000074],
              [105.593932647, 10.831725452000017],
              [105.59748899100001, 10.832013108000103],
              [105.59823032600005, 10.832071766000071],
              [105.61067840900009, 10.833056314000087],
              [105.60524532600004, 10.843857397000088],
              [105.59958091200006, 10.855202696000086],
              [105.59788409800009, 10.858582060000098],
              [105.59190196600011, 10.870547295000053],
              [105.581441946, 10.893149947000056],
              [105.55617911800007, 10.905741267000034],
              [105.53097232000007, 10.918300326000095],
              [105.51408688100007, 10.923734016000084],
              [105.51425646600006, 10.926026700000067],
              [105.51392827600009, 10.927828861000053],
              [105.51227609400007, 10.930288396000075],
              [105.49763023200011, 10.953935479000075],
              [105.50339862300008, 10.950231022000077],
              [105.53651197900004, 10.953747669000078],
              [105.53714593000009, 10.953814940000049],
              [105.55041552700006, 10.960621302000016],
              [105.57545201000008, 10.96476095200005],
              [105.61070707800005, 10.97464029200008],
              [105.61106334700004, 10.974740093000078],
              [105.62391441800003, 10.978486665],
              [105.63796145100009, 10.982580872000101],
              [105.65608719400005, 10.986205485000065],
              [105.6668706080001, 10.988891724000014],
              [105.69095637400007, 11.001133603000122],
              [105.69866044400005, 11.008489240000062],
              [105.70636028400006, 11.016272348000109],
              [105.71494970500002, 11.021926307000028],
              [105.72442377700001, 11.025878630000106],
              [105.73391710800007, 11.028119857000094],
              [105.74646233500006, 11.028254773000066],
              [105.77279953800006, 11.033240566000034],
              [105.77539517400007, 11.033268061000088],
              [105.77642702100009, 11.032519926000093],
              [105.78293720800006, 11.030389217000012],
              [105.80972437200008, 10.973398652000109],
              [105.81728305400003, 10.960274538000101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 39, code: "AD01", ten_tinh: "Nam Định" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.10473755000004, 19.891062682],
              [106.10381766400005, 19.890831352000049],
              [106.10306637400005, 19.89083601400008],
              [106.10273383200007, 19.891035606000074],
              [106.10265281700003, 19.891391654000046],
              [106.10265555100008, 19.891786695000043],
              [106.103056032, 19.892357052000072],
              [106.10435705800009, 19.893376129000018],
              [106.10687623500007, 19.895493797000086],
              [106.11073392900005, 19.897998121000079],
              [106.11331104100003, 19.899423973000104],
              [106.11922228500001, 19.903060916000115],
              [106.12392383000008, 19.90678429900008],
              [106.124844954, 19.907173528000044],
              [106.12547137700005, 19.907209069000054],
              [106.12584621400006, 19.907088182000088],
              [106.12601374400006, 19.90688727400007],
              [106.12590394100005, 19.906396473000051],
              [106.12523019800008, 19.905571115000065],
              [106.12157553500002, 19.902315230000056],
              [106.116393739, 19.89853553100005],
              [106.11278363800008, 19.895634732000111],
              [106.10884003400011, 19.892775427000053],
              [106.10691289800008, 19.891760276000078],
              [106.10595016900001, 19.891371201000126],
              [106.10473755000004, 19.891062682],
            ],
          ],
          [
            [
              [106.12910754200007, 19.910930763000053],
              [106.12879848900009, 19.910928129000105],
              [106.12844488900005, 19.910966901000023],
              [106.12808870000006, 19.911277245000036],
              [106.12803917300005, 19.911840921000042],
              [106.12838680000004, 19.912428868000106],
              [106.129216103, 19.913438770000091],
              [106.13114084600009, 19.915335463000083],
              [106.13271478700003, 19.916978453000041],
              [106.13381223100006, 19.917656340000086],
              [106.13491046700007, 19.918250653000058],
              [106.13526209400003, 19.918420781000101],
              [106.13543592000011, 19.918714742000084],
              [106.13543155500001, 19.919174338000097],
              [106.13520286400009, 19.920008078000073],
              [106.13506762300001, 19.920299412000105],
              [106.1350632570001, 19.920759008000033],
              [106.13536954800003, 19.921054093000109],
              [106.13589819300007, 19.921183936000105],
              [106.13629556400009, 19.921187310000029],
              [106.13682657900006, 19.92106646600007],
              [106.13718336700001, 19.920693437000111],
              [106.13768054300002, 19.919485916000035],
              [106.13773024200005, 19.918901360000071],
              [106.13738257500006, 19.918313433],
              [106.13676841100005, 19.917890374000088],
              [106.13610930600005, 19.917550506000033],
              [106.13519006300001, 19.916707012000053],
              [106.13383407300009, 19.915358379000025],
              [106.13077331900006, 19.912198498000052],
              [106.12981076100009, 19.911271031000055],
              [106.12910754200007, 19.910930763000053],
            ],
          ],
          [
            [
              [106.14177459300001, 19.923489970000041],
              [106.14131524300002, 19.923473162000072],
              [106.14102357400004, 19.923554049000103],
              [106.140732062, 19.923654686000077],
              [106.14048399299999, 19.923992065000014],
              [106.14044564700001, 19.924466378000091],
              [106.14062753200005, 19.925087414000053],
              [106.14122078100011, 19.926308277000082],
              [106.14175141500004, 19.927529529000054],
              [106.14244692600005, 19.928453432000111],
              [106.14304730400008, 19.929210049000098],
              [106.1437196800001, 19.929818048000044],
              [106.14487757200007, 19.93117352300002],
              [106.14538296400005, 19.931782591000058],
              [106.14570049600005, 19.932392884000102],
              [106.14602938900008, 19.933131482000107],
              [106.14662950000007, 19.938184271000111],
              [106.14684238300002, 19.938755723000057],
              [106.14711646700005, 19.93912924600005],
              [106.14743088500003, 19.939304988000025],
              [106.14780764300004, 19.939440826000073],
              [106.14843389500007, 19.939436782000094],
              [106.14876747400005, 19.939375356000077],
              [106.14907960300012, 19.939235070000016],
              [106.14932811300008, 19.938956929000092],
              [106.14974003200005, 19.938183914000085],
              [106.14999589000008, 19.937481050000102],
              [106.14988321599999, 19.93633612000005],
              [106.14952263400009, 19.935548358000062],
              [106.14836704000004, 19.93307688600003],
              [106.14737496000004, 19.931562344000042],
              [106.14584441200005, 19.929192034000032],
              [106.14487368900011, 19.927736593000041],
              [106.14305652200004, 19.924686633000107],
              [106.14269727300007, 19.924076619000083],
              [106.14221420400006, 19.923664919000124],
              [106.14177459300001, 19.923489970000041],
            ],
          ],
          [
            [
              [106.563197819, 20.207583974000087],
              [106.56276688700005, 20.207535798],
              [106.5623832040001, 20.207578650000087],
              [106.56223745500003, 20.20784986000006],
              [106.56232863500003, 20.208485520000075],
              [106.56307969800008, 20.210622420000043],
              [106.56345673700011, 20.21148675500006],
              [106.56378818200008, 20.212033251000072],
              [106.5649326130001, 20.212811872000103],
              [106.56703279700005, 20.213959597000027],
              [106.57090077400008, 20.21584457200003],
              [106.57171277900002, 20.216212735000028],
              [106.57233509600009, 20.216307496000049],
              [106.57281462600007, 20.216265249000017],
              [106.5731778480001, 20.216063821000049],
              [106.57347345500004, 20.215576645000063],
              [106.57354409600003, 20.2151305390001],
              [106.57330440400008, 20.214816872000085],
              [106.5724940540001, 20.21422191500006],
              [106.57015323600004, 20.213208768000079],
              [106.56838683000008, 20.212290047000089],
              [106.56671892400001, 20.211009060000059],
              [106.56476850600011, 20.209045801000066],
              [106.56372193000001, 20.207995651000132],
              [106.563197819, 20.207583974000087],
            ],
          ],
          [
            [
              [106.59666956500004, 20.218491720000117],
              [106.596065794, 20.218470066000101],
              [106.59572301700005, 20.218676775000063],
              [106.59549836800002, 20.219194576000099],
              [106.59544809800005, 20.219900820000092],
              [106.59555119700005, 20.22155574700006],
              [106.59568193100003, 20.223101843000087],
              [106.59597004700007, 20.223421574000078],
              [106.59631805700008, 20.223476095000059],
              [106.59668027300003, 20.223343179000075],
              [106.59717274100009, 20.222735406000055],
              [106.59745485000008, 20.22221707200007],
              [106.59764881500006, 20.221509522000055],
              [106.59772559900001, 20.220585882000108],
              [106.59766203200009, 20.219989264000034],
              [106.59754153300005, 20.219447454000061],
              [106.59722042900005, 20.218961759000109],
              [106.59666956500004, 20.218491720000117],
            ],
          ],
          [
            [
              [106.57570738000005, 20.214230162000035],
              [106.57518598399999, 20.214164134000079],
              [106.57477131500005, 20.214495923000086],
              [106.5747107330001, 20.215350751000116],
              [106.57470238100004, 20.216499010000085],
              [106.57466738000007, 20.218221254000035],
              [106.57493930700009, 20.21926710400006],
              [106.5764948620001, 20.222151021000023],
              [106.57747580900009, 20.223760088000105],
              [106.58072108400002, 20.227152266000111],
              [106.582287982, 20.228488747000114],
              [106.58274909800004, 20.229320707000028],
              [106.5832050230001, 20.230871069000052],
              [106.5833134050001, 20.232032344000039],
              [106.58352499700003, 20.235073288000116],
              [106.5835722030001, 20.2366210120001],
              [106.58373978200004, 20.237672129000117],
              [106.58402655400005, 20.238392414000117],
              [106.58449009600011, 20.238892786000036],
              [106.58495723300004, 20.238895792000022],
              [106.58536837000004, 20.238566848000069],
              [106.58584228600009, 20.237630388000035],
              [106.58608940700002, 20.235752969000131],
              [106.58581859499999, 20.232822180000106],
              [106.585027473, 20.229169576000047],
              [106.58393764500006, 20.226454550000113],
              [106.58157333500002, 20.222349637000022],
              [106.57972457400008, 20.219629658000123],
              [106.57752796200005, 20.216575813000048],
              [106.57615404200006, 20.214594560000073],
              [106.57570738000005, 20.214230162000035],
            ],
          ],
          [
            [
              [106.14524351900006, 20.469703194000118],
              [106.1475518770001, 20.469480467000075],
              [106.15203015, 20.470123456000074],
              [106.16187564000006, 20.472264082],
              [106.16967486800003, 20.47402404700005],
              [106.17183928200006, 20.475494890000064],
              [106.17633168200005, 20.480675310000059],
              [106.17820013100003, 20.481968869000063],
              [106.18142693300011, 20.482983981000032],
              [106.18363298000003, 20.483660999000094],
              [106.187045224, 20.481735627000127],
              [106.18966162900004, 20.480849176000056],
              [106.19323405800004, 20.480489381000041],
              [106.20256791100006, 20.480565735000056],
              [106.20696507600007, 20.480082725000059],
              [106.20814613600001, 20.47972138100009],
              [106.21109480800001, 20.478819206000054],
              [106.21758036500007, 20.47511018600003],
              [106.21937772300002, 20.473697907000066],
              [106.22145663800008, 20.471509618000105],
              [106.22243510400003, 20.469571842000086],
              [106.22355670600011, 20.466986653000063],
              [106.22400143000004, 20.463358297000056],
              [106.22361088000007, 20.461020307000055],
              [106.2221482840001, 20.45581995500001],
              [106.22054380400004, 20.451137274000075],
              [106.2177148860001, 20.445277203000067],
              [106.21582312500007, 20.442018973000039],
              [106.21176058300003, 20.436019018000096],
              [106.20851453200004, 20.430803892000071],
              [106.20810324900002, 20.42938464800006],
              [106.20798937000009, 20.428205232000082],
              [106.20827926500002, 20.426521234000106],
              [106.20926476900011, 20.423805125000079],
              [106.21129268900003, 20.420299914000047],
              [106.21322907100003, 20.417696063000079],
              [106.21455143500002, 20.415935069000035],
              [106.21732295900009, 20.412973918000077],
              [106.21898371300001, 20.411430688000017],
              [106.22243056400001, 20.409642419000114],
              [106.22875645400011, 20.408136720000059],
              [106.23466475800011, 20.407276104000083],
              [106.24029626200003, 20.406672567000058],
              [106.25840813700009, 20.406816569000071],
              [106.26239302000002, 20.406199427000082],
              [106.26582903800009, 20.405577900000083],
              [106.27215560800006, 20.403941238000108],
              [106.27559726900007, 20.402670984000082],
              [106.27821906300002, 20.401005087000087],
              [106.28098599400008, 20.398432214000103],
              [106.28279129500005, 20.395981526000106],
              [106.28363384800005, 20.393782765000076],
              [106.28406820400002, 20.39119163100009],
              [106.2840875360001, 20.388986428000067],
              [106.28383701800007, 20.386260218000096],
              [106.28262387100006, 20.383785929000098],
              [106.280453816, 20.380914983000054],
              [106.273508447, 20.375022831000052],
              [106.26969123700009, 20.372268538000071],
              [106.26383132400009, 20.367811578000072],
              [106.25988526800009, 20.364147947000077],
              [106.25622182000011, 20.359578338000084],
              [106.25324209300004, 20.355273533000059],
              [106.25081929600003, 20.350064956000047],
              [106.25029032100005, 20.347855263],
              [106.25017401800002, 20.345519115000066],
              [106.25060986900012, 20.342798138000084],
              [106.25146067300008, 20.339691244000058],
              [106.25286356900007, 20.336199502000071],
              [106.25439663700008, 20.333487190000099],
              [106.25483600900002, 20.333002037000099],
              [106.25661313300006, 20.331039749000098],
              [106.25868780900004, 20.329110114000045],
              [106.26102984800011, 20.327960986000093],
              [106.26322894800008, 20.32745940300007],
              [106.26528628400004, 20.327475629000077],
              [106.26775278700011, 20.32775452700006],
              [106.27213146800003, 20.328956595000044],
              [106.27595815800001, 20.330543458000072],
              [106.27991522300003, 20.332909692],
              [106.28564639900003, 20.336327567000097],
              [106.28905617900007, 20.338559622000041],
              [106.29181903200006, 20.340470549000052],
              [106.29341941300004, 20.341577416000064],
              [106.29709693200003, 20.344589814000088],
              [106.30240262100008, 20.349690441000057],
              [106.30784911800008, 20.354402780000044],
              [106.31071076200006, 20.356630214000049],
              [106.31371187600004, 20.358599214000073],
              [106.31671194500011, 20.360697892000076],
              [106.31847861900009, 20.36231499200003],
              [106.31957044200001, 20.363314354000025],
              [106.32433291900006, 20.367891241000088],
              [106.32936335500008, 20.373248402000051],
              [106.33290262100009, 20.376518466000078],
              [106.33481225, 20.377830200000076],
              [106.3360436720001, 20.378228701000097],
              [106.33796435300003, 20.378243210000058],
              [106.33865471500005, 20.377729501000097],
              [106.3399015360001, 20.376311895000043],
              [106.34156870400001, 20.373859612000089],
              [106.34255477200006, 20.371925347000094],
              [106.34394041200005, 20.36920720500008],
              [106.34783525299999, 20.36287966700003],
              [106.35033402000006, 20.359395655000064],
              [106.35227636300002, 20.356815561000104],
              [106.3551799630001, 20.354112901000043],
              [106.35904586300003, 20.35115789000006],
              [106.36400792400008, 20.348340694000022],
              [106.36868687300006, 20.34655914500005],
              [106.37184824300007, 20.345804149000024],
              [106.37528070000012, 20.345440315000118],
              [106.37884709300006, 20.345466612000074],
              [106.38000408700005, 20.345438667000067],
              [106.38296323200011, 20.345367156000101],
              [106.38570981800007, 20.344998110000034],
              [106.38818633400008, 20.344108118000023],
              [106.39245878200009, 20.341674405],
              [106.39880450500004, 20.337309668000088],
              [106.40474374400003, 20.33229308700006],
              [106.40668087799999, 20.330231326000089],
              [106.40959070900006, 20.326619734000047],
              [106.41181360400003, 20.323132860000023],
              [106.41528338500007, 20.318098062000089],
              [106.41944082200007, 20.312808631000046],
              [106.42234805900009, 20.309456240000131],
              [106.42497988500007, 20.306231568000044],
              [106.43050816000007, 20.300951686000076],
              [106.43126370000006, 20.300431689000042],
              [106.43367997800004, 20.29876865900011],
              [106.44015431900007, 20.295181853000074],
              [106.44400644500004, 20.293652199000078],
              [106.44936695400001, 20.292133119000063],
              [106.45266427800007, 20.291377869000101],
              [106.45746796400007, 20.290892627000098],
              [106.46240672200005, 20.290667705000054],
              [106.46747853200003, 20.290962590000028],
              [106.47076562700005, 20.291504434],
              [106.47405072800008, 20.292305704000043],
              [106.47787731700006, 20.294018876000038],
              [106.48101831100007, 20.295727244000084],
              [106.48388207000005, 20.297822886000105],
              [106.48660474200008, 20.30043647800008],
              [106.48872736000003, 20.302963914000124],
              [106.49000218200007, 20.304481813000038],
              [106.49245273600006, 20.306833955000059],
              [106.49403362500004, 20.308098550000061],
              [106.49422825700006, 20.308276558000045],
              [106.49600050800007, 20.309193563000072],
              [106.49777939600008, 20.309724682000052],
              [106.50203087700004, 20.309753665000052],
              [106.50724534100002, 20.309399875000089],
              [106.50984811899998, 20.309806741000088],
              [106.51162311700003, 20.310856678],
              [106.51462653200011, 20.312693323000097],
              [106.51899364400005, 20.315577031000132],
              [106.52131635900002, 20.31676030600007],
              [106.5228210810001, 20.317289364000054],
              [106.52391730800011, 20.317426460000028],
              [106.52487735200006, 20.317432897000032],
              [106.52625275700008, 20.316923152000044],
              [106.52700893800004, 20.316483122000051],
              [106.52735483800005, 20.316281837000091],
              [106.52887225200001, 20.315124339000064],
              [106.53094991500006, 20.312413687000053],
              [106.53316661100007, 20.309444449000047],
              [106.53593471600004, 20.306089637000113],
              [106.53842167500002, 20.303641090000021],
              [106.54311533000006, 20.299520482000055],
              [106.54932976200007, 20.293723125000085],
              [106.55526860600003, 20.288053474000023],
              [106.55899878800003, 20.284315351000082],
              [106.56329673900009, 20.280000890000061],
              [106.5613098580001, 20.276741814000054],
              [106.56306926400002, 20.274950841000106],
              [106.56625951100001, 20.272868766000052],
              [106.572973695, 20.266453516000112],
              [106.57537220700003, 20.26406573100008],
              [106.57617789100007, 20.262418649000047],
              [106.57618444100009, 20.261517438000084],
              [106.57571261200005, 20.260913548000033],
              [106.57175198500008, 20.259836409000087],
              [106.57001033700003, 20.259224257000064],
              [106.56953963000008, 20.258470153000104],
              [106.569548404, 20.257268532000069],
              [106.57037931800001, 20.252166781000099],
              [106.571037221, 20.249016614000048],
              [106.57105584500003, 20.246463138000067],
              [106.57044507900012, 20.243154495000049],
              [106.56873321000006, 20.238486753000103],
              [106.56433876000008, 20.231698452000032],
              [106.56279309100009, 20.230481054000023],
              [106.56053425300006, 20.229350500000095],
              [106.55384605500009, 20.226302581000063],
              [106.55095384599998, 20.224996084000054],
              [106.54959493500007, 20.22481547300012],
              [106.54868751200004, 20.224895313000069],
              [106.54786886300008, 20.225233234000051],
              [106.54622900900003, 20.226252363000086],
              [106.54276150000001, 20.229147624000099],
              [106.54220975000001, 20.230173925000102],
              [106.54229468800001, 20.230946957000114],
              [106.543365, 20.233357267000059],
              [106.54452665300003, 20.235682343000057],
              [106.54541182800008, 20.238606378000057],
              [106.54540736700008, 20.239207150000112],
              [106.54458610300007, 20.239888342000079],
              [106.54340463500002, 20.240223829000037],
              [106.54150151600003, 20.240039568000064],
              [106.53666298600007, 20.238247944000065],
              [106.53141742300002, 20.236324748000101],
              [106.52743636200005, 20.235096531000082],
              [106.52471199300005, 20.235593244000057],
              [106.52252652900006, 20.236780182000032],
              [106.5194225180001, 20.239505828000048],
              [106.51814903200007, 20.240012219000022],
              [106.51724218500009, 20.240006107000049],
              [106.51489216100006, 20.238960308000031],
              [106.5139931300001, 20.23792430000011],
              [106.51418360900006, 20.236723980000093],
              [106.51419011700006, 20.235865735000047],
              [106.51310842800009, 20.235000135000099],
              [106.50949285700008, 20.233430731000098],
              [106.50460517200005, 20.232195948000076],
              [106.50080704800004, 20.23079681300004],
              [106.49502455100006, 20.228010793000095],
              [106.49214927600009, 20.226122992000036],
              [106.491269038, 20.225354481000046],
              [106.49119260100008, 20.224820224000069],
              [106.491194949, 20.22451524800006],
              [106.49160182, 20.223984305],
              [106.49241674900001, 20.22276993200007],
              [106.49242320100011, 20.221931247000043],
              [106.49178405900007, 20.221240637000015],
              [106.49130308600007, 20.220932345000087],
              [106.48792576900004, 20.220146670000091],
              [106.47989469, 20.218008242000082],
              [106.47203979700005, 20.217335163000079],
              [106.46413479800005, 20.216312362000096],
              [106.45443537800007, 20.215367546000081],
              [106.44667918700003, 20.214682929000013],
              [106.43635512700006, 20.21374255700001],
              [106.43498822900003, 20.213427836000086],
              [106.43340627900005, 20.212940822000022],
              [106.42731636800004, 20.208932392000101],
              [106.42571215800008, 20.208082176000097],
              [106.42442585700006, 20.207767964000119],
              [106.42193074700003, 20.207521325000052],
              [106.4104113220001, 20.207590861000121],
              [106.4060617910001, 20.207559377000095],
              [106.40372717400003, 20.207389938000105],
              [106.40324513100002, 20.207233943000077],
              [106.40276434200004, 20.206925451000046],
              [106.40268752200006, 20.206467396000072],
              [106.40269311500001, 20.205781192000046],
              [106.40270554400006, 20.204256285000085],
              [106.40238833400009, 20.203643977000048],
              [106.40174770400003, 20.203181823000094],
              [106.39509368600005, 20.19932084400002],
              [106.385222657, 20.194902282],
              [106.3835138900001, 20.19399613200008],
              [106.38041095900005, 20.192350606000083],
              [106.37175503400007, 20.187177821000098],
              [106.36710286500001, 20.184855761000087],
              [106.35666945000006, 20.180431640000052],
              [106.35321923000004, 20.178880771000074],
              [106.35274179200006, 20.178190922000013],
              [106.35266638200004, 20.177580330000033],
              [106.35283385300004, 20.176819055000102],
              [106.35332218700003, 20.176212685000024],
              [106.35396837800003, 20.175988765000064],
              [106.354773069, 20.176071044000118],
              [106.35533745200007, 20.175999011000023],
              [106.35550171300007, 20.175618979000106],
              [106.35534640900001, 20.17493154100007],
              [106.35359260600002, 20.17278331800005],
              [106.344899567, 20.162652522000087],
              [106.33924083900004, 20.155670645000079],
              [106.333969595, 20.150521635000104],
              [106.32789275300009, 20.145442591000062],
              [106.32318310300005, 20.140602546000075],
              [106.31815618700006, 20.135226135000025],
              [106.31153896500011, 20.127549609000091],
              [106.310026287, 20.125555266000013],
              [106.30883504000012, 20.123639642000057],
              [106.30755936300008, 20.122180907000065],
              [106.30436395600002, 20.119258439000106],
              [106.30053647700005, 20.11495834800003],
              [106.29844965500004, 20.112478737000039],
              [106.29379835200004, 20.107746464000087],
              [106.291949458, 20.106558044000074],
              [106.29001133600005, 20.10545277300011],
              [106.2878983730001, 20.104094538000084],
              [106.28570570800005, 20.101729324000047],
              [106.28097893700003, 20.095570456000097],
              [106.27853045700003, 20.092112913000015],
              [106.27519906300009, 20.088396816000028],
              [106.26985183900005, 20.082400448000023],
              [106.26770574900003, 20.079825679000059],
              [106.26016176200005, 20.072050514000047],
              [106.25463320600002, 20.066639266000053],
              [106.247006981, 20.058275767000033],
              [106.24183770000012, 20.05236375500008],
              [106.23745092200006, 20.048051345000047],
              [106.23648607600008, 20.046983702000084],
              [106.23157797900006, 20.041714008000078],
              [106.22378127700004, 20.032844827000048],
              [106.21857331200007, 20.02647044700003],
              [106.21577892500005, 20.02233809600007],
              [106.20185038000002, 20.006792060000045],
              [106.19676766400002, 20.001382503000094],
              [106.19577667700001, 20.000000166000028],
              [106.19536148200002, 19.99963446800006],
              [106.19498638100008, 19.999357658000072],
              [106.19445599300008, 19.999058512000097],
              [106.19357020800005, 19.998756441000062],
              [106.19309332000003, 19.998678821000034],
              [106.192724873, 19.998886354000064],
              [106.19267686300003, 19.999275496000024],
              [106.19282711900003, 19.999845246000092],
              [106.19291305600004, 20.000161798000079],
              [106.19311536700008, 20.000499172000019],
              [106.19060509300003, 20.000444444000017],
              [106.18665024600001, 20.000326460000025],
              [106.18636429900008, 19.999546065000025],
              [106.18612919900006, 19.998132761000065],
              [106.185926626, 19.997039540000024],
              [106.18507428900008, 19.99510783900007],
              [106.18335862600007, 19.992435789000048],
              [106.1816404730001, 19.990038638000037],
              [106.18088062700012, 19.988565929000046],
              [106.18060079300005, 19.987463790000071],
              [106.18051941100008, 19.985813386000117],
              [106.1810329620001, 19.983432093000083],
              [106.18133478700004, 19.981496963000019],
              [106.18134925000008, 19.979936218000113],
              [106.18124816900007, 19.978589801000091],
              [106.1808622500001, 19.977348662000068],
              [106.17997786700003, 19.97475781100011],
              [106.179534183, 19.973623843000077],
              [106.17891719800011, 19.972811365000084],
              [106.17813290800005, 19.971674574000083],
              [106.17745617400001, 19.97118455200004],
              [106.175589286, 19.970469333000018],
              [106.17428910000007, 19.969866461000052],
              [106.17299443400006, 19.96867156800004],
              [106.17068799, 19.966391754000057],
              [106.16725881500005, 19.962756929000044],
              [106.16281302200009, 19.958575288000091],
              [106.15788669600008, 19.954110004000114],
              [106.15689257300006, 19.953469479000049],
              [106.15611773400008, 19.953146882000091],
              [106.15500656700007, 19.953137546000029],
              [106.15389539900005, 19.953128205000056],
              [106.1528923810001, 19.953435860000113],
              [106.15020970200001, 19.955099089000058],
              [106.14920169200009, 19.955933491000089],
              [106.14919374400006, 19.956776330000103],
              [106.14985350800002, 19.957519423000036],
              [106.15151334600004, 19.958270946000042],
              [106.15406215300008, 19.959029931000046],
              [106.15583610800005, 19.959466290000094],
              [106.15694533200008, 19.959686330000089],
              [106.158501996, 19.95959403000009],
              [106.15983445700002, 19.959710564000055],
              [106.16094071700007, 19.960246638000086],
              [106.161266185, 19.961092265000069],
              [106.16081380400006, 19.961931375000042],
              [106.16014213400008, 19.962452550000087],
              [106.15936231400003, 19.962656739000103],
              [106.15814096200006, 19.962541134000041],
              [106.15470114300011, 19.961985447000075],
              [106.15148655700006, 19.961115505000038],
              [106.147720378, 19.959819412000087],
              [106.14627582000003, 19.959807220000059],
              [106.1452697600001, 19.960430899000059],
              [106.14380726400006, 19.962315056000079],
              [106.14334783000007, 19.963891606000047],
              [106.14334185100002, 19.964523720000038],
              [106.1439976230001, 19.965688246000113],
              [106.14465341300009, 19.966852761],
              [106.14453830800007, 19.967273235000071],
              [106.14397869500004, 19.967689952000086],
              [106.14164209100007, 19.96798627200009],
              [106.136614428, 19.968451010000088],
              [106.11801544100007, 19.962587905000092],
              [106.10462601400005, 19.958037576],
              [106.10544898700002, 19.953965439000051],
              [106.10722692100003, 19.953592547000106],
              [106.109548262, 19.953483130000023],
              [106.11171943300005, 19.954795924000024],
              [106.11402711900003, 19.956109858000026],
              [106.11461528300002, 19.951714964000097],
              [106.11420514500001, 19.949397201000082],
              [106.11334545100003, 19.947304272000018],
              [106.11231416900006, 19.945686493000011],
              [106.11207578000005, 19.946128465000079],
              [106.11111924000004, 19.946249647000066],
              [106.10647547000003, 19.946597882000013],
              [106.10474917300012, 19.947194756000016],
              [106.0980978600001, 19.945090228000062],
              [106.09733058400012, 19.947109217000047],
              [106.09483891300002, 19.955468262000053],
              [106.09356709600003, 19.962314048000081],
              [106.093515299, 19.967646647000059],
              [106.09501506300002, 19.97829880900008],
              [106.09892235300001, 19.990168097],
              [106.10174101000008, 19.997924086000026],
              [106.10518585500009, 20.007363393000063],
              [106.10548636, 20.008311716000016],
              [106.10685564800005, 20.012632727000046],
              [106.10869601900001, 20.018439967000035],
              [106.10968406900004, 20.021791060000012],
              [106.11012510400012, 20.024636186000052],
              [106.11036938299999, 20.028456316000117],
              [106.11103672200005, 20.033730726000073],
              [106.1111199800001, 20.034456164000055],
              [106.11161634800004, 20.038795084000057],
              [106.11243212300005, 20.046843128000091],
              [106.11359453400003, 20.049815581000097],
              [106.11594926700009, 20.05265716600011],
              [106.11801580400001, 20.054508780000013],
              [106.12142355900006, 20.056230765000024],
              [106.12504783700008, 20.057307175000091],
              [106.12737198800001, 20.057997411000095],
              [106.12973737200005, 20.058699856000125],
              [106.13240630800009, 20.059851097000077],
              [106.13617640600009, 20.062633926000025],
              [106.13897290000001, 20.06604322500003],
              [106.14072064700012, 20.070148940000095],
              [106.14216402000008, 20.074957350000133],
              [106.14242705400008, 20.07862724800011],
              [106.14271921700001, 20.083243655000068],
              [106.14320419700009, 20.090906260000096],
              [106.14391129200006, 20.094861950000094],
              [106.1468884120001, 20.102856956],
              [106.15243915400001, 20.114329391000041],
              [106.15551326300007, 20.119997462000057],
              [106.15673112800002, 20.121498627],
              [106.15816331100004, 20.123263965000056],
              [106.16014853000007, 20.124643177000046],
              [106.16186252400004, 20.125833923000066],
              [106.16466299700004, 20.128960515000038],
              [106.1659808950001, 20.131369430000042],
              [106.16624445100005, 20.135039008],
              [106.16605725700002, 20.1391279730001],
              [106.16458760600001, 20.14496933700007],
              [106.16303612600008, 20.151585738000044],
              [106.161309132, 20.161021562000059],
              [106.16066172600009, 20.166516983000093],
              [106.16038968400005, 20.179631939000053],
              [106.16002820900007, 20.186399001000055],
              [106.15924627500003, 20.190341641000131],
              [106.15839446900007, 20.193790027000077],
              [106.15607228200005, 20.20307923700004],
              [106.15254734600006, 20.213486510000052],
              [106.15100290000001, 20.21925604100003],
              [106.15020734200007, 20.22460871800007],
              [106.14914305300005, 20.226856326000053],
              [106.14779006000001, 20.228114244000039],
              [106.14629339000007, 20.228806810000052],
              [106.14435639000003, 20.228790476000121],
              [106.14242071700001, 20.228633100000067],
              [106.13566603300005, 20.22596679800008],
              [106.12423232300003, 20.221779544000107],
              [106.11323386200003, 20.218864729000074],
              [106.10827791900005, 20.217824355000054],
              [106.10800836800004, 20.217850360000078],
              [106.10549306400003, 20.218092994000081],
              [106.10399086400008, 20.219349384000026],
              [106.10217290000003, 20.222436497000018],
              [106.10033573800007, 20.227497919000115],
              [106.09693174900006, 20.240443600000013],
              [106.09521998300001, 20.247903462000046],
              [106.09534706700005, 20.25016105600006],
              [106.09543400300004, 20.251271683000112],
              [106.0955312510001, 20.251746021000102],
              [106.09428037200007, 20.254376338000043],
              [106.09339974900004, 20.255357862000075],
              [106.09182311800006, 20.25625091200007],
              [106.08999159200005, 20.256482308000031],
              [106.08764390100004, 20.256049762000124],
              [106.08547767500004, 20.254876905000074],
              [106.08340582400004, 20.252962972000077],
              [106.08225297800004, 20.250603668000089],
              [106.08098848900003, 20.246306250000053],
              [106.07998372900003, 20.242175936000081],
              [106.07880320600006, 20.238208911000029],
              [106.077690406, 20.236220826000114],
              [106.07330142400012, 20.230824327000093],
              [106.0705413590001, 20.228079844000035],
              [106.06786114500002, 20.226077923000041],
              [106.05976952200004, 20.220854596000045],
              [106.05612995700008, 20.219008909000102],
              [106.05317647600005, 20.218240870000059],
              [106.05091395100007, 20.218055963000033],
              [106.04812400100006, 20.218360952000047],
              [106.04515493800008, 20.21915892100008],
              [106.04209217600005, 20.220615490000071],
              [106.03963814400005, 20.222159869000045],
              [106.03735412000007, 20.22411790300005],
              [106.03466098200008, 20.226520998000105],
              [106.03182461500006, 20.229419878000066],
              [106.02944609600007, 20.232147605000073],
              [106.02841623000005, 20.234358735000058],
              [106.02782561500003, 20.237419628000097],
              [106.02781816600003, 20.238159682000045],
              [106.02792160800007, 20.239006452000027],
              [106.02835878200008, 20.24006767200008],
              [106.02924376800004, 20.241132911000115],
              [106.03080366700004, 20.241886991000065],
              [106.03225481600003, 20.242322897000072],
              [106.03450166600004, 20.24240333900007],
              [106.03647489700006, 20.242327631000109],
              [106.04004822600005, 20.242112205000048],
              [106.04318525399999, 20.24197527600009],
              [106.04518595700006, 20.242240353000085],
              [106.04691632800007, 20.24340977000012],
              [106.04760077100012, 20.244652315000074],
              [106.04775853000002, 20.246302328000084],
              [106.0474717810001, 20.248855142000096],
              [106.04675117700008, 20.251239227],
              [106.04541444800006, 20.253393892000112],
              [106.04287357200005, 20.255738410000035],
              [106.04103704800006, 20.256463936000024],
              [106.039382141, 20.256449192000048],
              [106.03764260700005, 20.256186389000064],
              [106.03486780300005, 20.254925168000071],
              [106.03437821800004, 20.254707910000036],
              [106.032907112, 20.254181149000061],
              [106.02780399000001, 20.252106474000087],
              [106.02450638200001, 20.251296499000127],
              [106.01707067600003, 20.251073514000062],
              [106.00863542500011, 20.251621616000115],
              [106.00429666600003, 20.252343223000047],
              [106.00151593800008, 20.252805657000025],
              [105.99538003599999, 20.254778805000036],
              [105.99039455200004, 20.257386592000067],
              [105.98423591400002, 20.261544252000085],
              [105.98057077000009, 20.264319965000062],
              [105.97943257000006, 20.26573475100006],
              [105.97941532600005, 20.267406527],
              [105.98009600300006, 20.270087865000086],
              [105.98052245800008, 20.271104336000079],
              [105.98199722700006, 20.274619504000043],
              [105.98422000100004, 20.282330659000088],
              [105.98453975400011, 20.285677383000063],
              [105.98396204700001, 20.290186180000092],
              [105.98305080300003, 20.292685646000038],
              [105.97978046100006, 20.297859701000043],
              [105.97583549100005, 20.302725430000109],
              [105.96717876200003, 20.311224021000029],
              [105.96141825100008, 20.315827533000075],
              [105.95776022400003, 20.317999451000013],
              [105.94627920400006, 20.323284642000061],
              [105.9369125650001, 20.327246680000052],
              [105.93562771900011, 20.327677691000098],
              [105.93541714100009, 20.32912349500009],
              [105.93561955900005, 20.330562944000086],
              [105.93587073300004, 20.331123057000063],
              [105.93642666800004, 20.332148090000054],
              [105.93776720300011, 20.333252794000067],
              [105.94295457100006, 20.33658713100008],
              [105.94811844500002, 20.342180070000012],
              [105.95087832600011, 20.34877726100008],
              [105.95170145200009, 20.353302771000067],
              [105.95124690500005, 20.355146749000049],
              [105.94993053700003, 20.356161270000058],
              [105.94884037400007, 20.356356473000048],
              [105.94405307900008, 20.356311809000083],
              [105.93752063100004, 20.356661405000054],
              [105.93576679900006, 20.357877099000099],
              [105.93487039000006, 20.360332944000078],
              [105.93483356800003, 20.363823619000065],
              [105.93457912900003, 20.367312226000053],
              [105.9333293270001, 20.368352498000064],
              [105.93320225900005, 20.371528517000037],
              [105.93231503700011, 20.373761062000106],
              [105.93012811400007, 20.376388823000099],
              [105.92773171800005, 20.378403433000081],
              [105.92511072400006, 20.381230747000068],
              [105.92377643700007, 20.384885014000083],
              [105.92281122900006, 20.394450357000068],
              [105.92321496100007, 20.397102390000029],
              [105.92379047400007, 20.398590965],
              [105.92483764600004, 20.399097302000065],
              [105.92693989300007, 20.399365332000038],
              [105.9277292150001, 20.399372762000034],
              [105.93063129000005, 20.398655366000092],
              [105.93536462900001, 20.398948039000061],
              [105.93876674300003, 20.4007175140001],
              [105.94620397100006, 20.404585315000112],
              [105.94742611400002, 20.405292665000019],
              [105.94791303000008, 20.405761170000055],
              [105.94815041400008, 20.406575319000098],
              [105.94761971000005, 20.410282077000105],
              [105.94735677900006, 20.411903489000061],
              [105.94759173600005, 20.412949595000015],
              [105.94795572400005, 20.413416948000048],
              [105.94868855700003, 20.41388773700001],
              [105.95272772200009, 20.415665174000083],
              [105.9540729170001, 20.416373611000083],
              [105.95528547800006, 20.418008722000103],
              [105.96013832200005, 20.424317120000076],
              [105.96233343000004, 20.426077253000052],
              [105.96392827400011, 20.426439975000044],
              [105.96712639400005, 20.426353531000082],
              [105.96739520400008, 20.424152241000023],
              [105.97218932900009, 20.424312431000132],
              [105.97224225300002, 20.425754149000049],
              [105.97223911000003, 20.426035381000048],
              [105.97222354800007, 20.427442368000115],
              [105.97365243500009, 20.431459120000014],
              [105.97388034100003, 20.43320101700008],
              [105.97467506200006, 20.439587552000127],
              [105.97515012800002, 20.441215712000101],
              [105.97624723900002, 20.442153678000082],
              [105.97945400300006, 20.441255240000089],
              [105.97973944900005, 20.437430341000073],
              [105.98013348800005, 20.434998253000103],
              [105.981138738, 20.432919718000072],
              [105.98287695600003, 20.431311836000084],
              [105.984482622, 20.430630611000119],
              [105.98915514300008, 20.430673325000015],
              [105.99100194600003, 20.430458211000065],
              [105.99198920500001, 20.430119247000086],
              [105.99248699800006, 20.429543857000084],
              [105.99249889599999, 20.428384097000048],
              [105.99070213, 20.423728199000024],
              [105.98905906700003, 20.418485726000043],
              [105.98802865900004, 20.413214825000111],
              [105.98776989400004, 20.408243236000075],
              [105.98811279100008, 20.405030967000037],
              [105.98890088700009, 20.403722772000066],
              [105.9949659440001, 20.397249582000128],
              [105.99720903100007, 20.39907541200003],
              [106.00227569500007, 20.401899800000045],
              [106.00453620100004, 20.402406253000024],
              [106.02628381600009, 20.403038901000059],
              [106.02669323200004, 20.403050789000062],
              [106.02591795700002, 20.403768351000018],
              [106.02393340600004, 20.405605127000022],
              [106.02033163600001, 20.409397822000038],
              [106.01833289300001, 20.412625317],
              [106.01807659700003, 20.413666198000065],
              [106.01801010100004, 20.415958730000035],
              [106.0184734920001, 20.418992327000041],
              [106.02157423000006, 20.429972625000033],
              [106.02326355800004, 20.433949263000081],
              [106.02595059200004, 20.437002702000129],
              [106.02765650900007, 20.439348238000044],
              [106.02813184600005, 20.441216692000097],
              [106.02784720800004, 20.444942522000083],
              [106.02783898200006, 20.445758694000027],
              [106.02886845400008, 20.446208062000082],
              [106.03102915400005, 20.446681219000062],
              [106.03244779400011, 20.446892610000056],
              [106.03566867200006, 20.44743941100009],
              [106.03755771300004, 20.448512438000051],
              [106.0393308000001, 20.450006890000068],
              [106.04032698400002, 20.450966324000049],
              [106.04053778400005, 20.452235622000089],
              [106.04051743200006, 20.454244923000076],
              [106.04014225000002, 20.45825232],
              [106.04012215000003, 20.460258866000025],
              [106.04053636600003, 20.463536671000071],
              [106.0415968020001, 20.469249369000053],
              [106.04314131200009, 20.471269786000065],
              [106.04736699000004, 20.473419591000038],
              [106.04970760499999, 20.474074013000099],
              [106.05216523700004, 20.474201370000067],
              [106.05540939200006, 20.473913194000112],
              [106.05732291700011, 20.472557083000083],
              [106.06094417800006, 20.468047526000085],
              [106.06252444800008, 20.466477188000098],
              [106.06364199800008, 20.46648701500002],
              [106.06675755100011, 20.467887387000069],
              [106.06953892600004, 20.469179161000014],
              [106.07087895400005, 20.469296523000047],
              [106.07222002300009, 20.469308264000041],
              [106.07323102900007, 20.468789033000064],
              [106.074260741, 20.466368868000103],
              [106.07528834900003, 20.464159918000036],
              [106.07675671500002, 20.462588493000041],
              [106.07911180500001, 20.461764118000048],
              [106.090331303, 20.457320145000054],
              [106.09503505200007, 20.456304706000061],
              [106.09715931100007, 20.456217443000035],
              [106.09860587900003, 20.456863645000034],
              [106.09949064300005, 20.457821836000086],
              [106.10014575499999, 20.459411739000096],
              [106.10298471200005, 20.46630127300002],
              [106.10330359100006, 20.467993864000086],
              [106.10329130400001, 20.469261147000047],
              [106.10316828900005, 20.470421850000072],
              [106.09977147800001, 20.47493401400002],
              [106.09648524000008, 20.479552664000074],
              [106.096022772, 20.481132869000085],
              [106.09661023800003, 20.483936702000079],
              [106.09824666000006, 20.488069734000099],
              [106.10001646700003, 20.489986024000117],
              [106.10290502900003, 20.491806312000094],
              [106.11247028700009, 20.496746600000023],
              [106.1151497550001, 20.497086366000069],
              [106.12129316400011, 20.497561282000021],
              [106.12408342400005, 20.498007502000078],
              [106.12552938700006, 20.498759087000096],
              [106.12685550200008, 20.500354492000028],
              [106.12763690900007, 20.500466743000075],
              [106.12853413800006, 20.500157532000074],
              [106.12988551900006, 20.499112904000057],
              [106.13237074400007, 20.49638810500003],
              [106.13326895400004, 20.495973272000079],
              [106.13528487000005, 20.495567899000022],
              [106.13857518400006, 20.494742046000077],
              [106.13734333800001, 20.485315423000117],
              [106.13746099600007, 20.479686587000053],
              [106.13813156600004, 20.476544369000038],
              [106.13930075000005, 20.474859229000053],
              [106.14081100100005, 20.473224173000034],
              [106.14240776800008, 20.471495401000084],
              [106.14524351900006, 20.469703194000118],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 40, code: "AD01", ten_tinh: "Nghệ An" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.98788373600004, 18.796442402000046],
              [105.9875667200001, 18.79633843900006],
              [105.98671756900009, 18.796431634000044],
              [105.98575313300006, 18.797433094000034],
              [105.98605677100005, 18.798850426000065],
              [105.986796833, 18.79905933600012],
              [105.98818122000006, 18.798465888000045],
              [105.988296495, 18.797557620000035],
              [105.98830266300007, 18.796951454000066],
              [105.98788373600004, 18.796442402000046],
            ],
          ],
          [
            [
              [105.77187234899999, 18.797296657000025],
              [105.77086635000006, 18.797185333000051],
              [105.76996408300002, 18.797277142000013],
              [105.768892534, 18.798276566000105],
              [105.76808258700004, 18.799581767000092],
              [105.76759296900003, 18.800688178000065],
              [105.76758441300004, 18.801445874000052],
              [105.76763285200005, 18.801850520000052],
              [105.76815609500008, 18.802462113000026],
              [105.76984098600005, 18.803489734000053],
              [105.77163304900009, 18.80441740800007],
              [105.77326610000003, 18.805343432000129],
              [105.77520585500012, 18.807282955000026],
              [105.77578100300011, 18.807996084000052],
              [105.77636241500007, 18.808153571000027],
              [105.77689649500012, 18.807805389000087],
              [105.77850440000002, 18.806255697000026],
              [105.77946822600009, 18.805406702000127],
              [105.78022170700009, 18.804403994000026],
              [105.78023758800006, 18.802989632000063],
              [105.77987673500006, 18.802076610000107],
              [105.77845685000008, 18.80105176700004],
              [105.77645047900008, 18.800324045000046],
              [105.77406681800002, 18.800148159000059],
              [105.77375160600005, 18.79989233600012],
              [105.77296900700006, 18.798772925000051],
              [105.77245031100003, 18.797757245000035],
              [105.77187234899999, 18.797296657000025],
            ],
          ],
          [
            [
              [105.96518394300008, 18.797444118000058],
              [105.96402192400004, 18.797029118000097],
              [105.9620034550001, 18.797414404000087],
              [105.95965754900007, 18.798705948000105],
              [105.95804956100007, 18.800408518000019],
              [105.95605588500004, 18.803839447000122],
              [105.95363278400002, 18.807136574000083],
              [105.95243306300006, 18.810358480000033],
              [105.95189038800001, 18.811565812000097],
              [105.95262732300004, 18.812077923000103],
              [105.95454202100011, 18.8114896740001],
              [105.9588163490001, 18.80829658100005],
              [105.96383891600009, 18.804504159000054],
              [105.96480348800004, 18.803502802000068],
              [105.96578573200006, 18.80078397200009],
              [105.96580860100008, 18.79856136500004],
              [105.96518394300008, 18.797444118000058],
            ],
          ],
          [
            [
              [104.93395414100003, 19.989665407000068],
              [104.9359226290001, 19.989639690000068],
              [104.94460885000009, 19.98973413900007],
              [104.948137407, 19.990634196000109],
              [104.95227945400006, 19.99186183700008],
              [104.95378664900007, 19.992574180000041],
              [104.95506485000004, 19.993549269000077],
              [104.9560123410001, 19.994094270000048],
              [104.95668615800008, 19.99415679400007],
              [104.95764767200005, 19.993797816000033],
              [104.95995959700012, 19.991569399000042],
              [104.96121536400001, 19.990363485],
              [104.96213791900006, 19.988887018000057],
              [104.9625528490001, 19.987509893000066],
              [104.96241769500003, 19.981710611000025],
              [104.96308545200003, 19.978528594000089],
              [104.9639020880001, 19.976625128000066],
              [104.96514869000004, 19.976004105000108],
              [104.96779736800008, 19.975615195000081],
              [104.96953099700008, 19.976224201000022],
              [104.97305286400007, 19.977549287000059],
              [104.97400606700006, 19.977721992000049],
              [104.97541227500005, 19.977688182000094],
              [104.97614881200008, 19.977326008000063],
              [104.97808879500009, 19.975491142000031],
              [104.97963114900007, 19.97391673300006],
              [104.98417815400006, 19.970734760000127],
              [104.99288475200005, 19.965774695000071],
              [104.99788592400004, 19.962279500000065],
              [104.998526204, 19.960852158000094],
              [105.000000082, 19.955357935000073],
              [105.0012530110001, 19.953879170000022],
              [105.00223664900005, 19.953316039000057],
              [105.00308963100011, 19.953327685000133],
              [105.00709850300007, 19.954189566000125],
              [105.00929012900012, 19.954334738000057],
              [105.01306587400006, 19.954501455000084],
              [105.01440977900006, 19.954289120000098],
              [105.015757197, 19.953846189000046],
              [105.01871322200006, 19.951810726000119],
              [105.02069087200009, 19.949992571000053],
              [105.02281339800003, 19.948261862000024],
              [105.02464904600004, 19.947268883000021],
              [105.02697990000006, 19.946360880000036],
              [105.02978651800002, 19.945675781000105],
              [105.03134870100004, 19.945711039000088],
              [105.03236157000001, 19.945485964000028],
              [105.03300317900008, 19.944778325000101],
              [105.03341231000009, 19.942754292000053],
              [105.03394037400008, 19.941209403000038],
              [105.03458196300005, 19.940501756000103],
              [105.03546684400008, 19.940394322000095],
              [105.03684552500002, 19.941009866000044],
              [105.03832695800003, 19.943178811000095],
              [105.03851371500011, 19.943494316000084],
              [105.03969051400006, 19.943087544],
              [105.04111235000001, 19.94208883200006],
              [105.04170210700005, 19.941392101000055],
              [105.04298203600007, 19.938825494000056],
              [105.04333419400008, 19.937420853],
              [105.04360950400003, 19.935623693000082],
              [105.04397461800005, 19.933357935000103],
              [105.04406324800001, 19.93296762800005],
              [105.04464825400001, 19.932584014000021],
              [105.04671416600006, 19.932768418000087],
              [105.05647521700007, 19.932977825000094],
              [105.06052082400005, 19.933580137000014],
              [105.07265195600004, 19.935778025000062],
              [105.07933811900003, 19.936884968000079],
              [105.08519703200002, 19.937980730000056],
              [105.08717449300003, 19.938555072000064],
              [105.08923006100011, 19.939443616000041],
              [105.09095471000009, 19.940327763000099],
              [105.09178204500006, 19.940338725000025],
              [105.09253013800006, 19.940113736000065],
              [105.09561561800008, 19.938510323000088],
              [105.09744616800002, 19.93782984700001],
              [105.09877453000006, 19.93753421400006],
              [105.10009825300007, 19.937551705000089],
              [105.10241016400005, 19.937895429000015],
              [105.10364885100005, 19.938068373000114],
              [105.10419533800005, 19.938070200000091],
              [105.1051533780001, 19.937881981000061],
              [105.11411907700003, 19.934484933000093],
              [105.11561652800002, 19.933701164000084],
              [105.11668949700007, 19.932911809000053],
              [105.117765406, 19.9319216210001],
              [105.12100044000007, 19.928448956000096],
              [105.12282645900007, 19.926966417000088],
              [105.12506954300004, 19.925991483000068],
              [105.12998598600004, 19.923645442000023],
              [105.133092076, 19.922386999000082],
              [105.13369155700005, 19.923477607000081],
              [105.13497621600001, 19.924500605000084],
              [105.13522889300006, 19.924592063000098],
              [105.13583402800003, 19.924599960000045],
              [105.13624133800008, 19.924524872000049],
              [105.13719558500006, 19.924042769000039],
              [105.14146015300004, 19.922161356000068],
              [105.14435823300002, 19.921646752000029],
              [105.14543744600006, 19.921660791000036],
              [105.14590312300004, 19.921831453000038],
              [105.14698945700005, 19.922551635000026],
              [105.14766454700003, 19.923599634000055],
              [105.15096022300004, 19.922282795000079],
              [105.15141032900007, 19.922235475000072],
              [105.152469033, 19.922833983000118],
              [105.15386319600007, 19.923543169000091],
              [105.15475957000008, 19.923714275000037],
              [105.15520967900002, 19.923666948000026],
              [105.15696165500003, 19.922945384000101],
              [105.16041708400012, 19.920969984000081],
              [105.16117302000004, 19.920620477000028],
              [105.16376044400008, 19.920246690000099],
              [105.16534197500002, 19.919995630000109],
              [105.16642348900007, 19.919602367000074],
              [105.16758160600006, 19.918870714000022],
              [105.16889487300008, 19.917326579000068],
              [105.17086326300006, 19.915112155000081],
              [105.17174428900003, 19.913698171000057],
              [105.17204675200001, 19.912616094000057],
              [105.17264837400006, 19.905700739000039],
              [105.17327085700005, 19.902315056000042],
              [105.17381284200003, 19.899539173000043],
              [105.17421139200006, 19.896761451000074],
              [105.17453334600006, 19.894322098000124],
              [105.17478457400006, 19.891813960000079],
              [105.17574257600009, 19.885038752000114],
              [105.17590647200007, 19.883615467000126],
              [105.17620105900002, 19.883076249000098],
              [105.17685608200003, 19.882405917000057],
              [105.17837630900006, 19.881407337000034],
              [105.17982094200003, 19.880679271000098],
              [105.18090513300004, 19.880082316000092],
              [105.18156304300003, 19.879208375000076],
              [105.18171715300008, 19.878463714000105],
              [105.18158447100002, 19.87771537800009],
              [105.18103527300005, 19.876011409000057],
              [105.18104598900005, 19.875264903000108],
              [105.18142003700009, 19.874183689000091],
              [105.18368241200005, 19.871429785000046],
              [105.18470069200002, 19.870424706000023],
              [105.18550680500002, 19.869213259000063],
              [105.18573256800005, 19.868469505000071],
              [105.18589832200009, 19.866910451000038],
              [105.18580744800002, 19.863243905000068],
              [105.18532119200003, 19.862151632000021],
              [105.18473219500005, 19.860682357000044],
              [105.18368918200008, 19.859667793000121],
              [105.18246396200004, 19.859068041000057],
              [105.17983612300004, 19.857949666000074],
              [105.17509024600012, 19.856991590000028],
              [105.16709126300006, 19.856484211000058],
              [105.16144184100008, 19.855905885000077],
              [105.15867124400008, 19.85556683],
              [105.15610825600002, 19.85563468900002],
              [105.15043543500005, 19.856672754000023],
              [105.14915466600004, 19.856656115000071],
              [105.14777011000007, 19.856436005000063],
              [105.14681980200004, 19.855716217000108],
              [105.14618823400009, 19.855101628000057],
              [105.14558310300004, 19.852668272000074],
              [105.14472536200003, 19.845582748000098],
              [105.14434988500004, 19.842040647000125],
              [105.14427841900007, 19.839614191000088],
              [105.14408407300007, 19.838297836000059],
              [105.14377566200005, 19.837485312000069],
              [105.14315298100004, 19.836264435000125],
              [105.14126291400007, 19.83411746000003],
              [105.13904831900007, 19.832269431000071],
              [105.13629576100004, 19.830717544],
              [105.13131255900002, 19.828429003000011],
              [105.13025278800009, 19.827909806000044],
              [105.12952750500003, 19.827001421000112],
              [105.12870841500003, 19.825277618000122],
              [105.12806668000005, 19.81967309700012],
              [105.12809005000003, 19.818074506000102],
              [105.12835292000001, 19.816593257000115],
              [105.12911979500005, 19.813633898000127],
              [105.12963549300008, 19.811356489000076],
              [105.12964550300002, 19.810671370000051],
              [105.12917152100006, 19.810094128000074],
              [105.12750513100012, 19.808587605000035],
              [105.1264399670001, 19.807203146000063],
              [105.12597436500003, 19.806054962000097],
              [105.12575993600005, 19.804224811000012],
              [105.12655186100002, 19.799552595000058],
              [105.12821777600001, 19.792836019000056],
              [105.12940853300005, 19.789561983000056],
              [105.12986820800006, 19.787405215000042],
              [105.12976302000007, 19.784835514000051],
              [105.1291107710001, 19.78063654099999],
              [105.12913446500002, 19.779014745000012],
              [105.12986962000009, 19.777537433000077],
              [105.1315560100001, 19.774995133000047],
              [105.13470441, 19.768949335000038],
              [105.13520922300003, 19.767468977000121],
              [105.13522104500002, 19.766658061000086],
              [105.13503956000002, 19.765969298000115],
              [105.13447433100006, 19.76532690900008],
              [105.13221505500009, 19.76350967200009],
              [105.13137280000004, 19.762552404000111],
              [105.13067323200006, 19.761596999000105],
              [105.13065924300005, 19.760917170000077],
              [105.13090610900005, 19.760472376000045],
              [105.132173295, 19.758946793000028],
              [105.13276364000009, 19.757865932000037],
              [105.13347088799999, 19.755335148000071],
              [105.13379514800009, 19.752799356000047],
              [105.13431088200004, 19.750266049000054],
              [105.13413263400003, 19.749356555000119],
              [105.13235989800009, 19.74615830700008],
              [105.13199547900004, 19.744883501000032],
              [105.13200474300001, 19.744248603000081],
              [105.13288754100004, 19.742808686000046],
              [105.1345747230001, 19.74122197300003],
              [105.13592694100008, 19.739797993000074],
              [105.13804377200007, 19.73811368800007],
              [105.14390028600006, 19.734315620000054],
              [105.14630092200009, 19.732725020000075],
              [105.14726612900002, 19.731746447000106],
              [105.14824571200006, 19.729776909000037],
              [105.14898948200005, 19.72880416200011],
              [105.15008752600005, 19.727368052000124],
              [105.15191494599999, 19.725950128000044],
              [105.15316663500008, 19.724885137000037],
              [105.15392988000008, 19.724714844000069],
              [105.15820845200007, 19.724770352000043],
              [105.15945098600011, 19.72433593300007],
              [105.16031971500004, 19.723446141000075],
              [105.16052158500005, 19.722637814000073],
              [105.15989638400002, 19.719836467000015],
              [105.15878932200008, 19.717479390000037],
              [105.15747909900003, 19.716020722000046],
              [105.15362537000003, 19.712907128],
              [105.15212769000001, 19.711265769000086],
              [105.15166407300011, 19.710448790000079],
              [105.15167843100005, 19.70945780600006],
              [105.15226060900008, 19.708654414000115],
              [105.15437695500003, 19.706969886000088],
              [105.15881401000007, 19.702612220000049],
              [105.161887419, 19.700489467000089],
              [105.16277113400011, 19.699784017000077],
              [105.16277720600007, 19.699363233000078],
              [105.16237113500006, 19.696727546000133],
              [105.16221592400009, 19.692095963000078],
              [105.16203947200006, 19.688937141000018],
              [105.16217173500007, 19.687465800000048],
              [105.16285747400006, 19.686106837000047],
              [105.16543931300008, 19.684141083000029],
              [105.16891363600004, 19.681871178000065],
              [105.17047519100001, 19.681365249000038],
              [105.17203219000005, 19.681174906000095],
              [105.17668958200002, 19.681550612000095],
              [105.17879403000006, 19.681893369000122],
              [105.17957101900005, 19.681903370000065],
              [105.180127521, 19.681805315000119],
              [105.18102606900001, 19.68108033900009],
              [105.182943181, 19.679000602000052],
              [105.18529821800007, 19.677347351000037],
              [105.1919055920001, 19.673328543000054],
              [105.2014239260001, 19.667557967000079],
              [105.204330629, 19.666121973000102],
              [105.20734976600005, 19.664582152000065],
              [105.20880755900005, 19.66354852200007],
              [105.21061173200012, 19.661572284000073],
              [105.21311309100004, 19.657395245000053],
              [105.21368143100008, 19.656455478000098],
              [105.2151436, 19.655106178000011],
              [105.22086473500006, 19.650864691000081],
              [105.2225398570001, 19.650149369000047],
              [105.22553931200004, 19.649976931000076],
              [105.22776784000004, 19.649373794],
              [105.23044077000004, 19.647827077],
              [105.23281979300006, 19.64640358200009],
              [105.23376635800005, 19.646191917000074],
              [105.23529313299998, 19.646658430000038],
              [105.23657149500006, 19.648016293000033],
              [105.23772723600004, 19.649708043000125],
              [105.23839400100005, 19.652623532000035],
              [105.23905450800007, 19.655986166000083],
              [105.23963004700009, 19.656999717000097],
              [105.24103428200002, 19.657800069000061],
              [105.24256586700008, 19.657931155000071],
              [105.24374522000005, 19.657945993000055],
              [105.24433645300003, 19.657841622000127],
              [105.24624533200006, 19.656300278000046],
              [105.25031760700003, 19.651878999000068],
              [105.25260203200003, 19.648776946000091],
              [105.25549162600002, 19.644564327000055],
              [105.25886057800005, 19.639798575000086],
              [105.25946889400004, 19.63846443700001],
              [105.25984758000001, 19.636680177000088],
              [105.25991455100004, 19.631873042000066],
              [105.26029323000003, 19.63008876600005],
              [105.26209312500001, 19.627874991000027],
              [105.26413037300007, 19.625552340000048],
              [105.26485341400004, 19.624443229000029],
              [105.26630567500003, 19.621777809000029],
              [105.26850580700008, 19.616577118000045],
              [105.26925509700008, 19.61320480500008],
              [105.26964451800006, 19.610637890000056],
              [105.26978414600006, 19.609074201000084],
              [105.27003081500004, 19.608294563000058],
              [105.27050864500006, 19.607853246000047],
              [105.271928159, 19.607535480000045],
              [105.275711954, 19.606799858000024],
              [105.278200321, 19.605936255000067],
              [105.28117882500007, 19.60373690600008],
              [105.28165969900006, 19.603071972000038],
              [105.28167205800003, 19.602177585000021],
              [105.28091473800006, 19.597248204000017],
              [105.28032646400007, 19.595030270000088],
              [105.27951851000009, 19.593714549000119],
              [105.27815449900004, 19.592783621000095],
              [105.27582502300002, 19.591971246000114],
              [105.27253554500008, 19.590885755],
              [105.27158276000003, 19.590090472000021],
              [105.27159181200007, 19.589437737000104],
              [105.27314410000007, 19.586715103000074],
              [105.27667779400007, 19.580099975000032],
              [105.27893447100008, 19.576210886000034],
              [105.27908836700003, 19.575037653000038],
              [105.27956992, 19.570081893000065],
              [105.27933794500007, 19.566945265000065],
              [105.27868040200011, 19.564717350000116],
              [105.27786717400006, 19.563793229000034],
              [105.27649975500007, 19.563123359000123],
              [105.27362006300011, 19.562304085000015],
              [105.27198462400005, 19.561108543000039],
              [105.27057105600008, 19.558805890000116],
              [105.269904583, 19.557230688000082],
              [105.26992450700004, 19.555794614],
              [105.27136607400004, 19.55111186600007],
              [105.27180432500006, 19.549289267000077],
              [105.27183328400005, 19.547200412000088],
              [105.27079739400006, 19.542486747000076],
              [105.26948086700003, 19.538161285000029],
              [105.26639662000009, 19.531092091000076],
              [105.26627165700006, 19.530030196000055],
              [105.26742230300005, 19.527658799000044],
              [105.2725230180001, 19.51948271100008],
              [105.27298908400003, 19.518767275000037],
              [105.27371038400003, 19.517322301000029],
              [105.27440815100003, 19.515014893000071],
              [105.27542612400006, 19.51208374500002],
              [105.27596928900006, 19.510252585000032],
              [105.27669606000006, 19.508520106000034],
              [105.27716168400005, 19.506946727000063],
              [105.27740395200001, 19.505475850000039],
              [105.27731630500004, 19.503790301000024],
              [105.27601573900009, 19.49944127800007],
              [105.27495808700006, 19.496654473000063],
              [105.274891946, 19.494833447000111],
              [105.27509020700006, 19.493709112000076],
              [105.27564540800009, 19.493195966000073],
              [105.27628595200005, 19.493117256000048],
              [105.27690751500006, 19.493059660000078],
              [105.27918367400009, 19.493608030000061],
              [105.28100749600002, 19.493624111000059],
              [105.28189036800005, 19.493659326000042],
              [105.28330256500001, 19.493306368000063],
              [105.28589153700008, 19.492109772000084],
              [105.28681801400009, 19.491167816000129],
              [105.28737106500003, 19.489996764000047],
              [105.28827246800009, 19.488368832000091],
              [105.29107134900003, 19.477482095000106],
              [105.29141904000002, 19.475954053000066],
              [105.29390397400002, 19.477891461000048],
              [105.29531927700006, 19.479825143000085],
              [105.29577690900008, 19.480981606000022],
              [105.29605386700008, 19.483332561000033],
              [105.29649750000002, 19.484805242000022],
              [105.29770809500009, 19.486727557000094],
              [105.29907733300004, 19.488358377000026],
              [105.30014945600003, 19.4891052],
              [105.30279907000005, 19.492157533000039],
              [105.30441039000006, 19.494497229],
              [105.30557745000007, 19.496679728000018],
              [105.30662801900004, 19.49860585100005],
              [105.30732547700002, 19.499721140000048],
              [105.30901191600003, 19.501601043000072],
              [105.31230023400005, 19.505198228000076],
              [105.31416349800003, 19.506424135000074],
              [105.31666371000004, 19.507025976000044],
              [105.31885305500001, 19.507674084000115],
              [105.32075352000004, 19.508789591000038],
              [105.32204215600008, 19.509947717000031],
              [105.3229269280001, 19.511291281000084],
              [105.32331398000007, 19.512343162000079],
              [105.32349650700007, 19.513678133000042],
              [105.32358077300003, 19.514775341000053],
              [105.32374543600005, 19.516390902000055],
              [105.32404670000003, 19.516981436000066],
              [105.32550889500004, 19.520651963000098],
              [105.32942125300011, 19.52812486900006],
              [105.33146497100009, 19.532814272000017],
              [105.33308349000006, 19.53484397200009],
              [105.33423563200009, 19.535703814],
              [105.33761612900003, 19.538029568000056],
              [105.33980648100007, 19.539645008000051],
              [105.34498330800002, 19.540017982000087],
              [105.34905570400009, 19.53977040900012],
              [105.35062846300011, 19.539195613000082],
              [105.35220321900002, 19.538472367000011],
              [105.35363744800003, 19.536559735000125],
              [105.35857780600006, 19.529938706000038],
              [105.36019226800005, 19.526246667000088],
              [105.36103231600001, 19.521951416000057],
              [105.36122261000004, 19.519429857000056],
              [105.36065051100005, 19.515414472000089],
              [105.35990207200011, 19.512881575000037],
              [105.3577475550001, 19.510183243000064],
              [105.35636539400005, 19.508236522000018],
              [105.3661945210001, 19.498704782000061],
              [105.37237908300004, 19.492543532000091],
              [105.37476526500004, 19.489602830000052],
              [105.37844635000005, 19.483411300000064],
              [105.38260831700006, 19.476334606000108],
              [105.38356214200003, 19.475132377000115],
              [105.38610883600006, 19.471922350000078],
              [105.38880543500008, 19.469133602000085],
              [105.38992399600008, 19.467365296000061],
              [105.39024864000002, 19.466478337000034],
              [105.39026241400011, 19.465439204000035],
              [105.38871989100002, 19.463787623000073],
              [105.38734365900009, 19.461395644000042],
              [105.38737123600006, 19.459317374000037],
              [105.38865798700004, 19.456660221],
              [105.39168092400006, 19.452835972000045],
              [105.39326465500008, 19.451370106000084],
              [105.39483657900003, 19.450794933000012],
              [105.39843785800011, 19.450540829000126],
              [105.40109284500005, 19.450869322000038],
              [105.40233039700003, 19.451923329000032],
              [105.40510269, 19.45522263000008],
              [105.4078809490001, 19.458076534000043],
              [105.409130293, 19.458239804000051],
              [105.41038159300005, 19.458254614000033],
              [105.42290627100003, 19.457511622000112],
              [105.42658810200001, 19.456944097000047],
              [105.42902812400011, 19.45626281100008],
              [105.43280706600005, 19.453289838000032],
              [105.43696677000005, 19.449788780000077],
              [105.44057003700004, 19.445926133000043],
              [105.44284133700003, 19.443822783000094],
              [105.44396545100004, 19.443658425000088],
              [105.45365110400003, 19.446611217000061],
              [105.45625266000008, 19.447883917000027],
              [105.45828644700005, 19.449682459000073],
              [105.45957237500005, 19.451472298000013],
              [105.46139637900006, 19.455043267000072],
              [105.46176347100005, 19.455580001000072],
              [105.46325245700007, 19.456129731000082],
              [105.46437891000001, 19.455787808000125],
              [105.46627383300003, 19.453857360000093],
              [105.47364568800008, 19.447730413000031],
              [105.47552906700011, 19.446687206000043],
              [105.47684469200009, 19.446169899000054],
              [105.47815349300001, 19.446184986000056],
              [105.48407308800006, 19.451222878000074],
              [105.49646737500008, 19.461836877000046],
              [105.49869317400004, 19.463282232],
              [105.50130650199999, 19.463667066000042],
              [105.50528270300001, 19.464033962000116],
              [105.51886551600003, 19.46528673300012],
              [105.52186183100007, 19.464965700000022],
              [105.52430831600007, 19.463750974000057],
              [105.53035204800008, 19.459027085000088],
              [105.53300318000007, 19.456394655000061],
              [105.53448722100011, 19.45332433500004],
              [105.53860090000003, 19.4424359550001],
              [105.54110018400006, 19.436961789000051],
              [105.54642139600007, 19.430099271000053],
              [105.55609935400004, 19.41867039300007],
              [105.56199353800012, 19.410748773000108],
              [105.56695479900002, 19.402639063000038],
              [105.56884820500008, 19.400707618000098],
              [105.57355203900008, 19.398274835000073],
              [105.57655312300005, 19.395491262000064],
              [105.57733423500012, 19.394766741000069],
              [105.57999041600007, 19.391601118000068],
              [105.58057079500004, 19.390010021000045],
              [105.58078605300008, 19.387704869000096],
              [105.5802601840001, 19.384859008000085],
              [105.58029070400005, 19.382374296000116],
              [105.58106231600003, 19.380430292000113],
              [105.58467671000004, 19.375322607],
              [105.58827354500001, 19.371634680000049],
              [105.5900757420001, 19.370944814000076],
              [105.59052721600005, 19.37077199400008],
              [105.59388926200005, 19.370986508000065],
              [105.59697307900004, 19.371865527000054],
              [105.59888871800008, 19.372000353000104],
              [105.59972880300005, 19.371895784000067],
              [105.60370617800007, 19.370004960000117],
              [105.60586833600009, 19.369573472000035],
              [105.60802081500002, 19.369938397000027],
              [105.60944890200005, 19.370750540000081],
              [105.61015535900006, 19.371782366000012],
              [105.61038257000003, 19.37280896300004],
              [105.610352209, 19.375311999000111],
              [105.61070338200001, 19.375998573000075],
              [105.61579903200001, 19.380719530000064],
              [105.61841442000005, 19.382454847000119],
              [105.61972694200003, 19.382924291000059],
              [105.62140443400008, 19.382942538000059],
              [105.62356395000006, 19.382738426000046],
              [105.62467292400007, 19.382274252000094],
              [105.62491978300007, 19.382170925000075],
              [105.62694496100004, 19.380613148000059],
              [105.62779739800004, 19.379484511000022],
              [105.62793228700011, 19.378234293000048],
              [105.62751056700003, 19.373450565000013],
              [105.62729834000005, 19.371172468000083],
              [105.62768107600002, 19.369242186000065],
              [105.62964187400007, 19.365622152000036],
              [105.63071742400007, 19.365861393000024],
              [105.63393181100011, 19.36760304200007],
              [105.63536408500003, 19.368073695000106],
              [105.63584332800008, 19.368078876000091],
              [105.63704827400004, 19.367522943000012],
              [105.63801767100004, 19.366623084000075],
              [105.63826957100009, 19.365601693000123],
              [105.63828184600004, 19.364577713000038],
              [105.63819204100008, 19.362073340000038],
              [105.63844803500004, 19.360710628000085],
              [105.64127306700001, 19.354937734000096],
              [105.64260451900003, 19.353814175000124],
              [105.64620540700008, 19.353283996000044],
              [105.64836457200003, 19.3530796410001],
              [105.64969055200005, 19.352411141000033],
              [105.65073376800004, 19.35133504000008],
              [105.65188495800007, 19.349248520000103],
              [105.65288537000006, 19.345731681000089],
              [105.65362177200006, 19.34426027300011],
              [105.6548319390001, 19.343249117000113],
              [105.65603667000005, 19.342693064000024],
              [105.65687524600006, 19.34270205100006],
              [105.65914191700003, 19.343522877000026],
              [105.65998049700003, 19.343531851000058],
              [105.66130096200004, 19.343318393000075],
              [105.66418957300004, 19.342211327000044],
              [105.66588424300002, 19.340750106000115],
              [105.66637824300004, 19.339503645],
              [105.6662705750001, 19.338478355000085],
              [105.66396776600007, 19.330601954000045],
              [105.66270002100008, 19.326377984000054],
              [105.66246267500007, 19.324372782000054],
              [105.66189764100004, 19.319473827000074],
              [105.66192602600007, 19.317081221000088],
              [105.66265920600007, 19.31594958500007],
              [105.66409988300009, 19.315851032000083],
              [105.6683787600001, 19.319201186000079],
              [105.67277639400007, 19.322666464000093],
              [105.67397049500002, 19.323134963000129],
              [105.675536542, 19.322581874000022],
              [105.67603514300005, 19.320991914],
              [105.67594744300007, 19.318256231000049],
              [105.67645139400003, 19.316210523000073],
              [105.682461297, 19.304993372000112],
              [105.68501209900002, 19.302285596000068],
              [105.68717762600005, 19.301738769000032],
              [105.68909257400004, 19.302100870000061],
              [105.69099419100009, 19.303602303000034],
              [105.69277723400009, 19.304988524000066],
              [105.69481083900004, 19.305465766000026],
              [105.70045853500007, 19.304613644000042],
              [105.70754807400002, 19.303548596000027],
              [105.71066774900004, 19.303467343000058],
              [105.71270005900006, 19.30405835600002],
              [105.71614654400003, 19.306829164000035],
              [105.72010862700002, 19.310635511000029],
              [105.72233462700008, 19.311109819000102],
              [105.72067920000006, 19.319410773000101],
              [105.72299139500007, 19.319084030000063],
              [105.72668702200005, 19.318561737000017],
              [105.73076255700002, 19.318831967000079],
              [105.73506359200007, 19.320357871000034],
              [105.74019586800009, 19.324163034000016],
              [105.74068933600006, 19.321441595000088],
              [105.74085891500006, 19.317113345000081],
              [105.74150042200003, 19.313473645000023],
              [105.7443438600001, 19.306096339000064],
              [105.74569836799999, 19.303033681000059],
              [105.74678821600003, 19.302133320000081],
              [105.74858335100005, 19.302493664000053],
              [105.75057935600009, 19.306274529000063],
              [105.75224419200008, 19.307545090000119],
              [105.75392464000004, 19.307448410000028],
              [105.759741299, 19.302266426000081],
              [105.76133669500011, 19.299092178000073],
              [105.76233626700012, 19.295569965000055],
              [105.76367491100004, 19.2938744100001],
              [105.76620508400003, 19.292874722000072],
              [105.76956442699999, 19.292795068000054],
              [105.77099971700009, 19.293151552000062],
              [105.77301922800004, 19.294881394000107],
              [105.77670755300002, 19.297539780000058],
              [105.77981298600011, 19.298710836000055],
              [105.78425182000004, 19.298641916000037],
              [105.78760997400006, 19.298675923000097],
              [105.79166602300002, 19.300654071000075],
              [105.7950076430001, 19.30216914200011],
              [105.79727624600005, 19.303103601000068],
              [105.79871676100004, 19.303004159000103],
              [105.79957030100006, 19.301759308000044],
              [105.80194438500011, 19.293236963000112],
              [105.80221360900001, 19.292512587000132],
              [105.80197182400008, 19.291795599000046],
              [105.79976530200003, 19.284607948000065],
              [105.79871066600008, 19.279240338000122],
              [105.79683772400008, 19.27593562000007],
              [105.79298387000009, 19.273360430000075],
              [105.79016698300006, 19.273397794000054],
              [105.78908874600003, 19.2744739440001],
              [105.78906576700008, 19.27682027200008],
              [105.78799170900002, 19.27781928700005],
              [105.78438295300005, 19.27737878900011],
              [105.78205403300008, 19.276547268000051],
              [105.77848401300011, 19.272673438000076],
              [105.77515629400001, 19.266176278000025],
              [105.77246637000012, 19.259685510000054],
              [105.77166420100009, 19.255435719000054],
              [105.76823649900008, 19.239040064000079],
              [105.76778348500008, 19.236849284000087],
              [105.76705538300006, 19.235035711000045],
              [105.76546670700006, 19.232939663000082],
              [105.76009710300004, 19.229108219000054],
              [105.75787331900007, 19.227169794000076],
              [105.75661530900008, 19.22639063800009],
              [105.75592126700012, 19.226657176000025],
              [105.75556333900008, 19.227748134000052],
              [105.75590957200004, 19.229723438000065],
              [105.75628169800009, 19.234372967000098],
              [105.75583142000005, 19.236590197000055],
              [105.75431860100012, 19.238796505],
              [105.75143567300006, 19.241279098000064],
              [105.74896680100007, 19.24217519900013],
              [105.74651496000006, 19.238974652000081],
              [105.74477900800005, 19.236678412000074],
              [105.74542806500001, 19.235675180000023],
              [105.74589032800007, 19.234681101000106],
              [105.74591341600002, 19.232661476000068],
              [105.74493817600006, 19.230122732000062],
              [105.74255773900002, 19.227521051000096],
              [105.7400211030001, 19.225317948000061],
              [105.73729118000008, 19.222461851000091],
              [105.73457990600011, 19.217989977000101],
              [105.73233550800003, 19.209887039000058],
              [105.730548754, 19.198960837000101],
              [105.72945544500004, 19.192835346000066],
              [105.72865248900004, 19.188336346000057],
              [105.72770193300008, 19.178630547000083],
              [105.72822917000011, 19.169748016000106],
              [105.72834314500003, 19.159851090000046],
              [105.72751406499999, 19.14883321600005],
              [105.72702345800008, 19.136101666000101],
              [105.72735451200003, 19.125802626000073],
              [105.72828078300002, 19.119145885000052],
              [105.72992588100007, 19.114617708000111],
              [105.73187436000008, 19.111405743000098],
              [105.73468382900009, 19.107192606000112],
              [105.73532786700009, 19.106593245000077],
              [105.73576650000007, 19.105385700000085],
              [105.73513869600008, 19.104571133000015],
              [105.73068379300007, 19.104120854000058],
              [105.72388597700009, 19.104252120000083],
              [105.72134699500008, 19.103417583000045],
              [105.71819666, 19.100354443000086],
              [105.71629481000012, 19.099526499000063],
              [105.71501600800009, 19.099917153000057],
              [105.71456323400011, 19.102336607000048],
              [105.71492946700005, 19.107390820000077],
              [105.71204871800008, 19.106480632000086],
              [105.70963916800011, 19.105719285000063],
              [105.70967667800001, 19.102487425000092],
              [105.70844944700008, 19.098434231000013],
              [105.70466939600004, 19.094758217000063],
              [105.700457731, 19.091683639000046],
              [105.6905413220001, 19.086124474000066],
              [105.68863027300007, 19.08610425300008],
              [105.68648320400006, 19.08810170000006],
              [105.68367060600005, 19.092516293000102],
              [105.67873201000008, 19.097110258000072],
              [105.67596430800005, 19.097686869000086],
              [105.67277427400006, 19.098056936000063],
              [105.66853201400008, 19.097607641000103],
              [105.66156750500008, 19.093896854000022],
              [105.65874818600004, 19.091448513000039],
              [105.65179310600011, 19.08540816900004],
              [105.64717705700009, 19.080712002000055],
              [105.64090848400004, 19.072159431000024],
              [105.63364184700001, 19.058343132000033],
              [105.62721325900003, 19.045545626000134],
              [105.62215194700012, 19.033873827000036],
              [105.61990938700002, 19.026172172000066],
              [105.617923139, 19.014836598000073],
              [105.61741555500001, 19.004123139000036],
              [105.61758383000007, 18.998973010000064],
              [105.61722516400006, 18.993514044000023],
              [105.61662257800003, 18.990678898000098],
              [105.61452950700004, 18.988231543000019],
              [105.61241939000007, 18.98719826500006],
              [105.61176423400003, 18.98514132400004],
              [105.61119243700007, 18.983346058000095],
              [105.61322080300003, 18.982358039000054],
              [105.61493204000004, 18.981265523000076],
              [105.61623099900005, 18.97915827200007],
              [105.61691276400006, 18.975427931000063],
              [105.61675783300004, 18.970678210000088],
              [105.61712978500007, 18.966237288000087],
              [105.61776392600005, 18.962051765000076],
              [105.61846752600009, 18.956503169000079],
              [105.61949668800007, 18.950351942000054],
              [105.6207379630001, 18.944202995000033],
              [105.62236445800002, 18.93689640500007],
              [105.62380194700003, 18.932062832000085],
              [105.62513326600006, 18.927228072000091],
              [105.62579769700005, 18.924911693000098],
              [105.62555737600007, 18.922838026000122],
              [105.62579139300007, 18.921022085000061],
              [105.62665096600004, 18.920122199000033],
              [105.62793847200008, 18.918923882000094],
              [105.62873437800003, 18.914487324000092],
              [105.63103346400001, 18.908551649000046],
              [105.63214141800003, 18.904623574000112],
              [105.6326435500001, 18.902557934000086],
              [105.63320294500002, 18.900139309000075],
              [105.63385267400004, 18.899035042],
              [105.63421798800007, 18.898721157000061],
              [105.63524607300005, 18.897837798000069],
              [105.63727948900006, 18.89634440100005],
              [105.63866319100006, 18.895955261],
              [105.64035784700005, 18.896175661000122],
              [105.64216822400009, 18.895589065000081],
              [105.64675678000005, 18.893314943000036],
              [105.64772098600007, 18.892517109000018],
              [105.64826093100007, 18.891714697000062],
              [105.64806203600004, 18.890601217000032],
              [105.64754494400007, 18.889484314000079],
              [105.64755336300006, 18.888777187000102],
              [105.64841388900003, 18.887776169000055],
              [105.651611379, 18.88649722600011],
              [105.65406279800006, 18.885513309000054],
              [105.65570615500005, 18.885581496000015],
              [105.65685486100006, 18.887109300000034],
              [105.65736002200002, 18.889236362000084],
              [105.65861962000005, 18.89036121500007],
              [105.65925122400009, 18.890772113000054],
              [105.66086371600002, 18.888970872000051],
              [105.66205431500003, 18.886963042000012],
              [105.66347134800003, 18.88374524900005],
              [105.66476795400003, 18.881738522000099],
              [105.66540789800004, 18.881442283000105],
              [105.66763633500004, 18.881365097000113],
              [105.67049041300001, 18.882203855000057],
              [105.67176075300006, 18.882419482000046],
              [105.67282968100004, 18.881723670000028],
              [105.67369599500003, 18.880217456000103],
              [105.67425244599998, 18.878000709],
              [105.67671433600002, 18.876107357000116],
              [105.67842192900008, 18.875216264000102],
              [105.67927156200004, 18.87512427700004],
              [105.68021184100004, 18.87634665300002],
              [105.68104843800006, 18.877365868000062],
              [105.68262982099999, 18.878190924000116],
              [105.68540152800009, 18.877007983000027],
              [105.68774660800005, 18.876022553000027],
              [105.68929919200008, 18.874776114000014],
              [105.69073931400004, 18.869537728000104],
              [105.69193657100007, 18.866923573000079],
              [105.69291226900008, 18.865115326000122],
              [105.69821789300003, 18.858062810000042],
              [105.70091885000005, 18.854861846000048],
              [105.70336821600003, 18.85246556700011],
              [105.70618250600009, 18.850352962000017],
              [105.70990688700005, 18.84928074500003],
              [105.71191701800009, 18.849705982000039],
              [105.71486985899999, 18.851151415000089],
              [105.71657122900008, 18.8507651070001],
              [105.71807215800001, 18.849366355000093],
              [105.71829240900011, 18.848661417000031],
              [105.71819103600008, 18.848256223000078],
              [105.7159734900001, 18.847424736000029],
              [105.71491891500006, 18.846908519000038],
              [105.71408227200001, 18.845889403000093],
              [105.71367446600003, 18.84447065500003],
              [105.7134927800001, 18.841841858000038],
              [105.71422095700004, 18.840682131000094],
              [105.71500534200001, 18.839432886000026],
              [105.71661924100005, 18.837429098000058],
              [105.71747687000004, 18.836629794000089],
              [105.71828181400004, 18.835850550000096],
              [105.71830732900001, 18.835520050000106],
              [105.71818001000005, 18.835270641000079],
              [105.71789337900005, 18.834934274000133],
              [105.71659715700009, 18.834751441000016],
              [105.71378540000006, 18.834846523000067],
              [105.71362334100006, 18.833205343000081],
              [105.71345350500005, 18.831485357000055],
              [105.71493471300003, 18.822609736000025],
              [105.72056234500008, 18.812767050000012],
              [105.72376272100011, 18.806485643000045],
              [105.7263571550001, 18.802168092000038],
              [105.73109148500005, 18.796155110000079],
              [105.73431474400012, 18.79245020200004],
              [105.73940981300007, 18.787299581000113],
              [105.74338059100006, 18.783097056000116],
              [105.74487966500003, 18.7817990570001],
              [105.75663009300006, 18.769340767000116],
              [105.75717269700002, 18.768234893000098],
              [105.75718874000009, 18.766820484000043],
              [105.75720134500007, 18.765709152000021],
              [105.75489003800006, 18.763866600000064],
              [105.75383810200006, 18.763148470000068],
              [105.75684703300007, 18.760324929000127],
              [105.7488973820001, 18.755489919000112],
              [105.74568936900005, 18.75215077600005],
              [105.74355869400006, 18.749932914000041],
              [105.74145772500005, 18.747324100000107],
              [105.74035208200004, 18.743949849000074],
              [105.74056663800003, 18.741861677000045],
              [105.74135422800001, 18.739688563000101],
              [105.74505050200004, 18.733273862000118],
              [105.74778705300007, 18.727394473000025],
              [105.74919453000008, 18.723707929000085],
              [105.75279852600002, 18.714267405000037],
              [105.75749763900008, 18.703409102000101],
              [105.76081557300006, 18.696626475000052],
              [105.76490706300002, 18.688851903000092],
              [105.76688815300007, 18.682237154000077],
              [105.76711774800006, 18.678785627000046],
              [105.765914697, 18.675592071000047],
              [105.76365617400009, 18.673023915],
              [105.75815746000005, 18.670422392000049],
              [105.7494062910001, 18.668877855000062],
              [105.74426214500009, 18.668642858000069],
              [105.73948467300004, 18.669684033000067],
              [105.73106069500008, 18.672868575000031],
              [105.72600236500006, 18.673452073000085],
              [105.72267306000002, 18.672871931000131],
              [105.71850386100002, 18.670737771000034],
              [105.71519141500005, 18.668703402000105],
              [105.71273882500006, 18.666496228000092],
              [105.71115974400006, 18.662934803000027],
              [105.70877146600007, 18.655183722000054],
              [105.70774577700003, 18.651434257000062],
              [105.7068514040001, 18.648164642000076],
              [105.70475038800008, 18.640325537000102],
              [105.70454819900004, 18.640004251000072],
              [105.70250466800007, 18.636756933000065],
              [105.700251635, 18.63382448200008],
              [105.69787906400006, 18.632981350000044],
              [105.69435995300003, 18.632398739000067],
              [105.69122941200001, 18.631183924000091],
              [105.68944133600003, 18.629256135000013],
              [105.68777300400011, 18.625238983000081],
              [105.68754238800003, 18.620509895000033],
              [105.68792696300005, 18.612060527000018],
              [105.68813476400001, 18.602427532000029],
              [105.68754540300002, 18.595785666000083],
              [105.686354764, 18.591682552000051],
              [105.68382520600005, 18.588019672000122],
              [105.68007121600004, 18.583161998],
              [105.67554301300001, 18.579785518000058],
              [105.67487682600004, 18.579288772000076],
              [105.67164556800003, 18.578617928000085],
              [105.66945252600003, 18.578776264000041],
              [105.66858130800006, 18.579948637000051],
              [105.66768651100001, 18.583120562000133],
              [105.66681525400004, 18.584292933000057],
              [105.66394246000003, 18.585534722000126],
              [105.661084665, 18.585504036000074],
              [105.66044767400005, 18.585287429000097],
              [105.65804817600012, 18.584471487000087],
              [105.6516072090001, 18.581311491000108],
              [105.64763122200002, 18.579177854000051],
              [105.644143501, 18.576049505000114],
              [105.63982677900006, 18.570548678000073],
              [105.6376587460001, 18.568616237000057],
              [105.6351919840001, 18.567771331000046],
              [105.63176729100007, 18.567370492000101],
              [105.62786199600006, 18.567327967000047],
              [105.62328232000003, 18.567914325000068],
              [105.61877928000004, 18.570046744000109],
              [105.61656112400001, 18.572295002000061],
              [105.61582395400004, 18.573913493000035],
              [105.61538633200006, 18.574918268000125],
              [105.61450673800002, 18.578412178000022],
              [105.60793030100007, 18.57793268300005],
              [105.60716077700009, 18.577876577000026],
              [105.59970783100009, 18.577530993000032],
              [105.59654166400004, 18.576705466000121],
              [105.59393904500008, 18.574963784000076],
              [105.59174427400004, 18.573753632000134],
              [105.58912087900003, 18.573724593000037],
              [105.58719266400001, 18.573307931000024],
              [105.58543466900005, 18.570257841000078],
              [105.5841086130001, 18.565763110000063],
              [105.58292390900004, 18.561006388000045],
              [105.58183226900005, 18.559940125000104],
              [105.57934711300004, 18.559912501000042],
              [105.57380841000007, 18.561168495000047],
              [105.56951870300006, 18.56191123900004],
              [105.56510223100003, 18.56173011100006],
              [105.55339112900005, 18.55962228500001],
              [105.55119677600004, 18.558411709000033],
              [105.54929651400002, 18.55575499200004],
              [105.54710549300009, 18.554280871000103],
              [105.54351706100002, 18.554118117000051],
              [105.54324868800011, 18.554105945000018],
              [105.54144013400003, 18.554612244000076],
              [105.53906528100001, 18.556825462000056],
              [105.53640768200002, 18.559562493000037],
              [105.53446330900007, 18.560462849000103],
              [105.52988912100007, 18.561860420000095],
              [105.52834572700009, 18.563819391],
              [105.52660827900003, 18.57025618400003],
              [105.52474088000007, 18.576032621000046],
              [105.522916235, 18.578383629000058],
              [105.52096336600006, 18.579942574000086],
              [105.51915349900005, 18.581107834000044],
              [105.5168996350001, 18.584639765000063],
              [105.51520463100009, 18.587650994000079],
              [105.51404205500009, 18.592249433000063],
              [105.51397256100003, 18.597782677000026],
              [105.51339869200007, 18.59948903600008],
              [105.51004970600007, 18.602217755000083],
              [105.50739275500007, 18.604822587000044],
              [105.50457771400002, 18.609006707000027],
              [105.50233988800005, 18.611220982000098],
              [105.49974306800004, 18.61193818600011],
              [105.49473776500005, 18.611660687000061],
              [105.49186287000003, 18.61175510000006],
              [105.48879614600006, 18.611855761000037],
              [105.48199240300004, 18.614675912000052],
              [105.47645491000007, 18.615665893000077],
              [105.47494251200008, 18.615121313000046],
              [105.4744102760001, 18.613534008000073],
              [105.47444894100001, 18.610503959000098],
              [105.47336602100003, 18.608778494000063],
              [105.4710267820001, 18.608092532000065],
              [105.46757942200006, 18.607657173000099],
              [105.46373129300008, 18.60616299900002],
              [105.46126924000002, 18.604289632000089],
              [105.457700796, 18.602535090000046],
              [105.45438996100006, 18.602232876000087],
              [105.44830369900008, 18.602952197],
              [105.44417282600006, 18.601981407000068],
              [105.4390958020001, 18.599418256],
              [105.43705515000009, 18.597022510000016],
              [105.43560444800005, 18.591734890000033],
              [105.43302012400001, 18.588673859000068],
              [105.42957660800002, 18.587974410000108],
              [105.42445556500004, 18.588836229],
              [105.41903254200005, 18.591670812000061],
              [105.41667993900009, 18.592038201000079],
              [105.41310178000006, 18.591073358000095],
              [105.40430930300008, 18.587542830000103],
              [105.39634908500003, 18.583758357000058],
              [105.392912714, 18.582531344000053],
              [105.38836309000007, 18.58194974400007],
              [105.38367193200006, 18.581629888000073],
              [105.37734130800001, 18.579972530000077],
              [105.37526841500001, 18.580079312000031],
              [105.37262923200005, 18.581233362000127],
              [105.36985893200007, 18.581858731000089],
              [105.36668490300008, 18.581688591000045],
              [105.36213009900007, 18.581501678000031],
              [105.35768877200003, 18.583160766000084],
              [105.35404942700009, 18.586805984000065],
              [105.34918307900006, 18.589250312000068],
              [105.34294283600005, 18.5911507100001],
              [105.33755225200007, 18.59148013700009],
              [105.33602269100007, 18.592252017000099],
              [105.33194543200005, 18.597604418000024],
              [105.3291196750001, 18.60231325600008],
              [105.32661624000009, 18.603600162000063],
              [105.32329310700003, 18.604218142000036],
              [105.31568908100004, 18.604783340000083],
              [105.30432418200003, 18.607673463000118],
              [105.30237289600005, 18.608966923000111],
              [105.29586389700009, 18.620349482000087],
              [105.29306397700006, 18.62308168000002],
              [105.29085248000004, 18.623185937000116],
              [105.28812105900008, 18.620911994],
              [105.28674363200007, 18.620631309000132],
              [105.28451950699998, 18.621657672000055],
              [105.28074724000007, 18.624772868000058],
              [105.27811414800007, 18.625398769000064],
              [105.27452880500007, 18.624958634000066],
              [105.27178120200001, 18.623870137000061],
              [105.26960967100011, 18.621075925000063],
              [105.26811416900009, 18.619344270000049],
              [105.26536303800002, 18.61851915700008],
              [105.26011515500004, 18.618453181000035],
              [105.25517453100008, 18.61615099900007],
              [105.24203622600004, 18.607420382000051],
              [105.23821186500007, 18.604341335000107],
              [105.23523088900008, 18.600218810000065],
              [105.23078637300004, 18.592124671000043],
              [105.22888640100004, 18.589728716000039],
              [105.22668078100007, 18.589437095000072],
              [105.2240479980001, 18.590062363000115],
              [105.22011324400006, 18.594887479000107],
              [105.21661665100002, 18.598005155000052],
              [105.21051109200005, 18.600035241000064],
              [105.20512562800005, 18.599966169000126],
              [105.19781252000007, 18.599476874000054],
              [105.19075692800001, 18.600308294000101],
              [105.188674367, 18.601071996000051],
              [105.18671103600009, 18.603571016000053],
              [105.19014212500012, 18.60594890900007],
              [105.19068052400007, 18.607786821000083],
              [105.189815459, 18.613053103000077],
              [105.18967199500003, 18.615205312000072],
              [105.19076718800005, 18.617588892000114],
              [105.19152986900011, 18.619537375000036],
              [105.19093335100004, 18.621791427000019],
              [105.18941842400001, 18.625110635000027],
              [105.18925506500008, 18.62866268100003],
              [105.19163625700003, 18.6359093680001],
              [105.19263855, 18.636891598000105],
              [105.1947759080001, 18.637457640000044],
              [105.19623439000006, 18.638122628000083],
              [105.19688583200005, 18.639961925000122],
              [105.19674242600009, 18.642114077000116],
              [105.19475600100004, 18.646827286000089],
              [105.193265395, 18.648423573000095],
              [105.19213333000002, 18.6486243720001],
              [105.18999433000006, 18.648165986],
              [105.18796976300005, 18.647601351],
              [105.18672020300005, 18.648123713000061],
              [105.18195325700007, 18.657754985000082],
              [105.18035416000001, 18.659026670000053],
              [105.17571422000006, 18.659720434000079],
              [105.17222332900006, 18.659028952000064],
              [105.16908197200011, 18.65758805100004],
              [105.16727393300008, 18.657672246000075],
              [105.16544584000007, 18.65915623600003],
              [105.16324040500004, 18.663327760000037],
              [105.15920664600006, 18.668983162000075],
              [105.15310918200004, 18.67676544300005],
              [105.15070846400009, 18.678780289000052],
              [105.14855837100001, 18.679075242000032],
              [105.1464191420001, 18.678616441000081],
              [105.14371378200008, 18.678257890000083],
              [105.14019621600009, 18.679396392000044],
              [105.13722450600005, 18.681834382000062],
              [105.13261252600003, 18.688343181],
              [105.13225346900006, 18.689738499000086],
              [105.13599779400008, 18.696357178000063],
              [105.13665041700008, 18.698088876000085],
              [105.14257965900003, 18.702043746000108],
              [105.14290911700003, 18.702694235000102],
              [105.14244650500004, 18.703442026000069],
              [105.14028367800005, 18.704598276000084],
              [105.13572428100009, 18.707446119000082],
              [105.13411356500005, 18.709471118000032],
              [105.13257047800001, 18.714620099000086],
              [105.1317611800001, 18.715901766000087],
              [105.12972202400005, 18.716305679000108],
              [105.1210351230001, 18.715544895000043],
              [105.11370511200005, 18.71469405300008],
              [105.1110134620001, 18.713366052000069],
              [105.10732747500003, 18.710517106000069],
              [105.10490592500005, 18.706177171000071],
              [105.10334693700003, 18.704648730000081],
              [105.10143182700001, 18.704300172000018],
              [105.09769558400005, 18.704896593000072],
              [105.09156557600009, 18.706968722000013],
              [105.08452719500011, 18.709351605000087],
              [105.07864345900001, 18.710026639000077],
              [105.06679325800008, 18.709328938000077],
              [105.06250493300007, 18.709055763000038],
              [105.05022398600002, 18.706951312000086],
              [105.04840259300005, 18.707895894000082],
              [105.04804612000004, 18.709075708000086],
              [105.048778822, 18.712962624000085],
              [105.05164295400002, 18.717847623000061],
              [105.05689361200004, 18.723790128000047],
              [105.05640014500007, 18.725137400000058],
              [105.054006681, 18.726910300000021],
              [105.04946974700006, 18.729556764000051],
              [105.04758654300011, 18.728854286000015],
              [105.040327652, 18.723565711000042],
              [105.03797459900009, 18.722631107000076],
              [105.03536124900005, 18.723272532000031],
              [105.03175385800009, 18.727059570000023],
              [105.02865016200006, 18.728822514000051],
              [105.02033978700008, 18.730514259000053],
              [105.017225727, 18.732953852000023],
              [105.0159947380001, 18.736096158000045],
              [105.01642723400001, 18.738809932000052],
              [105.01963867500007, 18.74562347100008],
              [105.02030793200002, 18.748340441],
              [105.01934418300004, 18.749455522000069],
              [105.01769078200002, 18.74920725000004],
              [105.00000007500009, 18.738087081000018],
              [104.998513399, 18.73736941300006],
              [104.99723225200005, 18.737270380000034],
              [104.99567409600009, 18.737600405000073],
              [104.99468724200005, 18.738161460000043],
              [104.99389080000006, 18.739283613000076],
              [104.99271343, 18.741527934000054],
              [104.99177841600007, 18.743475201000059],
              [104.990843426, 18.744630339000068],
              [104.98976995800008, 18.745158365000059],
              [104.98888695700003, 18.74529033500005],
              [104.98764037000009, 18.745257250000066],
              [104.98608218000004, 18.744828069000086],
              [104.98487028300005, 18.744233877000035],
              [104.98369308100007, 18.743012590000063],
              [104.98244670700007, 18.741164189000067],
              [104.98158117000004, 18.740074931],
              [104.98088867100003, 18.739777810000113],
              [104.97950362100009, 18.739843681000053],
              [104.97782418200005, 18.740404570000102],
              [104.97398048800008, 18.741625261000117],
              [104.97100240000005, 18.742780009000093],
              [104.96927095100006, 18.743406830000083],
              [104.96809360200007, 18.743604677000022],
              [104.96596405700008, 18.743604287000082],
              [104.963678719, 18.743405832000093],
              [104.95879685000001, 18.741259480000046],
              [104.95710028900007, 18.740698010000045],
              [104.95585380400004, 18.740499691000068],
              [104.94236720000006, 18.739538868000061],
              [104.94155352800007, 18.739406595000041],
              [104.94075706700006, 18.739571366000085],
              [104.93996037400005, 18.740396235000034],
              [104.93957913300004, 18.741386274000057],
              [104.93923231100003, 18.742970412000126],
              [104.93907440100003, 18.74877926000007],
              [104.93820762100006, 18.751782453000132],
              [104.93732366200003, 18.754290546000085],
              [104.93427508900002, 18.757424985000078],
              [104.93375540700002, 18.758051912000084],
              [104.93365126000002, 18.758711972000071],
              [104.93512919500009, 18.763796450000122],
              [104.93671208000005, 18.769241658000091],
              [104.93710966100008, 18.771090070000085],
              [104.93700519100005, 18.772641276000087],
              [104.93665846700006, 18.773697318000067],
              [104.93600001000001, 18.774819265000083],
              [104.93485676800002, 18.775710006000068],
              [104.93315936099999, 18.776666557000077],
              [104.9321548020001, 18.777128266000084],
              [104.93021499700009, 18.777886674000044],
              [104.92846569900004, 18.778579108000045],
              [104.91788191700007, 18.784944735000053],
              [104.917396904, 18.785208551000032],
              [104.91632207400006, 18.787485426000039],
              [104.91524763200007, 18.788970159000051],
              [104.91320321900011, 18.790685489000047],
              [104.91129771400001, 18.791641739000028],
              [104.910501045, 18.791674357000062],
              [104.90980840700007, 18.791509003000058],
              [104.90932366500002, 18.79117872400009],
              [104.90870072300008, 18.790221276000096],
              [104.90840959900007, 18.789209368000037],
              [104.90787265500005, 18.788184740000077],
              [104.90709660300004, 18.787615266000032],
              [104.90600929200005, 18.787355666000046],
              [104.90566348000004, 18.787273102000057],
              [104.90375248800002, 18.787158307000063],
              [104.90231910100009, 18.787328287],
              [104.899989788, 18.787668509000085],
              [104.89736175600007, 18.788179257000067],
              [104.89586870900008, 18.788235343000089],
              [104.894734107, 18.788063981000036],
              [104.89330151800004, 18.78692499100012],
              [104.89151078400002, 18.785558147000089],
              [104.89055555900003, 18.785102321000032],
              [104.88942098800004, 18.784930922000065],
              [104.87162250200006, 18.787537346000072],
              [104.87120450400003, 18.787480153000047],
              [104.87066727800008, 18.787138333000094],
              [104.85583684600003, 18.775233533000076],
              [104.85559846400011, 18.774664257000047],
              [104.85512073200002, 18.774663887000109],
              [104.85274313000011, 18.775319310000071],
              [104.84944461600004, 18.778130838000081],
              [104.84824884500004, 18.779723313000126],
              [104.84720218600005, 18.7814866350001],
              [104.84540870600006, 18.783590788000112],
              [104.84385496400003, 18.784727667000119],
              [104.84080814100008, 18.785920180000026],
              [104.83973274100001, 18.786374529000049],
              [104.83029673500008, 18.786593829000076],
              [104.82853503700005, 18.786535316000119],
              [104.82596777800009, 18.78585002700008],
              [104.81342946700006, 18.783220133000086],
              [104.80539712700009, 18.78332574900012],
              [104.80253081100007, 18.783152013000048],
              [104.79942494800004, 18.783547069000051],
              [104.79530365400011, 18.784111687000049],
              [104.79357082100009, 18.784906504000084],
              [104.79279335800005, 18.785816178000033],
              [104.78654453200004, 18.79223980600004],
              [104.78588535900012, 18.794003216000107],
              [104.78306730300001, 18.802650065000122],
              [104.78217046900005, 18.803388831000056],
              [104.78151333900004, 18.80350187700008],
              [104.77996046200008, 18.80350005000011],
              [104.77649687300003, 18.803097574000027],
              [104.77479514200009, 18.802754086000029],
              [104.75682718100005, 18.796130165000058],
              [104.75605078600002, 18.796129156000063],
              [104.75494515400003, 18.796639880000043],
              [104.75249415899999, 18.798230081000078],
              [104.74914641900003, 18.800388124000023],
              [104.74687534000006, 18.801409416000055],
              [104.74544133800006, 18.801805829000081],
              [104.74120092400004, 18.801743087000133],
              [104.73797637800006, 18.801340228000107],
              [104.73684081200007, 18.801850801000072],
              [104.73612250400005, 18.802874141000103],
              [104.73561155300006, 18.804979031000087],
              [104.73452996300009, 18.809131798000053],
              [104.73446523900006, 18.812318571000027],
              [104.73505913600009, 18.814481924000056],
              [104.73511708600009, 18.815620174000088],
              [104.73451861800004, 18.816359130000123],
              [104.73326357100002, 18.816812611000074],
              [104.72003128300004, 18.817988296000095],
              [104.71943359400005, 18.818215023000029],
              [104.71688471600001, 18.820101526000066],
              [104.71420577200004, 18.825248923000082],
              [104.711435658, 18.828829194000015],
              [104.71019407500003, 18.834290669000083],
              [104.71153522300006, 18.841574641000044],
              [104.71011009500009, 18.841491378000086],
              [104.70464344300004, 18.840365154000068],
              [104.70015998700008, 18.840891346000085],
              [104.69836740500008, 18.841116092000028],
              [104.69735195000008, 18.841057529000068],
              [104.69585918500012, 18.84065674500004],
              [104.69442659200007, 18.840028421000099],
              [104.692964864, 18.839001668000051],
              [104.69159316500001, 18.837747437000104],
              [104.69022055600004, 18.837005346000076],
              [104.68801141800004, 18.836375685000029],
              [104.68633909800006, 18.836202153000059],
              [104.68466657500005, 18.83614241900009],
              [104.68371045300007, 18.836311525000049],
              [104.67516366200007, 18.838573137000125],
              [104.67432602600005, 18.839254559000018],
              [104.67363738000009, 18.840106979000026],
              [104.67183970300003, 18.84294921900009],
              [104.66703900700001, 18.853866991000068],
              [104.66512361000008, 18.855684599000085],
              [104.66305903600005, 18.857388121000085],
              [104.65917299900003, 18.85868991800006],
              [104.65767752900003, 18.859597695000026],
              [104.65689863700007, 18.860677501000019],
              [104.65581928500011, 18.862610377000074],
              [104.65515798900002, 18.864600921000104],
              [104.65449938000006, 18.865282589000017],
              [104.65363222800008, 18.86567933400007],
              [104.64520054700009, 18.869134811000073],
              [104.64245102400005, 18.869641726000097],
              [104.6400008520001, 18.869864625000062],
              [104.63599731600004, 18.870027556000046],
              [104.63519008700005, 18.8703105170001],
              [104.63393239700008, 18.871673820000098],
              [104.63261232500011, 18.874232059000029],
              [104.63111496400003, 18.875879408000081],
              [104.62686572700008, 18.879000839000071],
              [104.62560828000004, 18.880193373000061],
              [104.62536787900004, 18.880818868000034],
              [104.62551499000004, 18.88184349400008],
              [104.62735562900011, 18.887139584000053],
              [104.62717434500003, 18.888049734000042],
              [104.62651539900004, 18.888788205000125],
              [104.61843888900006, 18.892982949000015],
              [104.59763103800005, 18.898174535000045],
              [104.59547768200005, 18.899023468000031],
              [104.59416106300009, 18.899817294000073],
              [104.5924834140001, 18.901634651000073],
              [104.59056776900005, 18.902996212000019],
              [104.58817402600006, 18.904356689000053],
              [104.58703760300008, 18.904752516000123],
              [104.58608156500003, 18.904693484000035],
              [104.58482700700006, 18.904519971000063],
              [104.58309536000004, 18.903947008000088],
              [104.57921420800008, 18.902629401000048],
              [104.57801943500007, 18.902455970000084],
              [104.57736121900007, 18.902795914000059],
              [104.57664094500007, 18.904046232000034],
              [104.57645709100004, 18.905866830000036],
              [104.57680735400007, 18.909168237000081],
              [104.57638242000007, 18.911784987000054],
              [104.57560234600001, 18.913035158000113],
              [104.56984132700003, 18.922297716000088],
              [104.56369705000004, 18.929282912000112],
              [104.56333444500007, 18.930818538000068],
              [104.56326802000008, 18.933379181000106],
              [104.56296502700005, 18.934971863000079],
              [104.56194312400005, 18.937188807000013],
              [104.56065236600001, 18.939348208000069],
              [104.55963174700004, 18.941052992000081],
              [104.54908553800007, 18.951023575000114],
              [104.54372093800004, 18.956094749000037],
              [104.54323759500009, 18.957971480000069],
              [104.54269229000001, 18.960644733],
              [104.54277234500006, 18.964173128000091],
              [104.54305729300009, 18.969295401000075],
              [104.54226954500011, 18.973163089000074],
              [104.5417272820001, 18.974698223000082],
              [104.540107306, 18.976799729000057],
              [104.53759126600001, 18.978614469000021],
              [104.52980112100009, 18.984968346000116],
              [104.52824244700008, 18.986443941000083],
              [104.52777736900006, 18.986458696000064],
              [104.52405974100004, 18.983768355000038],
              [104.52280136400002, 18.98317877500002],
              [104.51997659800006, 18.981855303000117],
              [104.51825131600005, 18.981504914000077],
              [104.51625172700007, 18.981759126000064],
              [104.51370623800004, 18.982271326000074],
              [104.51188859800001, 18.982439506000048],
              [104.51061534100009, 18.982868530000104],
              [104.50902152900002, 18.984161474000061],
              [104.50792590300006, 18.985974590000062],
              [104.50691958200011, 18.988306820000034],
              [104.50435553800006, 18.995045210000058],
              [104.50344203400005, 18.996685833000093],
              [104.50216555700004, 18.99815251700003],
              [104.49779131100004, 19.002378093000068],
              [104.49605962400003, 19.004102927000055],
              [104.4949676360001, 19.004618816000104],
              [104.48708072000007, 19.006643280000013],
              [104.48187773300008, 19.00613920200005],
              [104.48105990800008, 19.006136912000038],
              [104.48060717900002, 19.005616774000075],
              [104.47925140000011, 19.003278078000015],
              [104.47771475300006, 19.000679435000031],
              [104.47185645800002, 18.99046040300005],
              [104.47089766600001, 18.989814974000026],
              [104.46128259300008, 18.991962496000077],
              [104.4605550090001, 18.992108695000098],
              [104.45098897800005, 18.987136788000036],
              [104.4489124130001, 18.986883439000032],
              [104.44766532900005, 18.987077469000013],
              [104.44631373500003, 18.987468931000073],
              [104.44548097200004, 18.988010252],
              [104.44469932800004, 18.988798913000068],
              [104.44342117000006, 18.990525394000102],
              [104.44117754900006, 18.993633201000115],
              [104.43976946800008, 18.995359265000019],
              [104.43836268800003, 18.996689816000064],
              [104.43372790500007, 18.99936183500008],
              [104.43336280000005, 18.999972998000111],
              [104.43311347700003, 19.000384607000065],
              [104.43065024200006, 19.004844241000015],
              [104.42996238100008, 19.005540121000038],
              [104.42971710000003, 19.005678962000069],
              [104.42932477100007, 19.005863890000093],
              [104.42883498800005, 19.005908906000087],
              [104.42411966600008, 19.005255966000064],
              [104.42266690900004, 19.005921010000023],
              [104.42030009000007, 19.007252749000081],
              [104.41955208399999, 19.007841202000094],
              [104.41928113400004, 19.008293285000022],
              [104.41907201300006, 19.008844040000028],
              [104.41911113500001, 19.009513743000106],
              [104.41927143200004, 19.011089694000113],
              [104.41980268200003, 19.013257614000075],
              [104.42086970500003, 19.01629371900011],
              [104.42109458200008, 19.017180622000041],
              [104.421175288, 19.017811058000063],
              [104.42125654500006, 19.018283941000021],
              [104.4212140010001, 19.018598899000061],
              [104.42113002500008, 19.018913740000087],
              [104.42092184799999, 19.019188784000015],
              [104.4132833300001, 19.028676508000061],
              [104.40652977300006, 19.033834237000043],
              [104.40582304200002, 19.034422756000069],
              [104.40515776000004, 19.035011402000102],
              [104.40449079900002, 19.036072693000072],
              [104.40276477700012, 19.039469954000054],
              [104.40237001700004, 19.040306268000059],
              [104.402163285, 19.041071003000098],
              [104.40245378900005, 19.042002596000081],
              [104.40406631700006, 19.044677214000046],
              [104.4049737540001, 19.045940506000122],
              [104.40555113300009, 19.046769470000058],
              [104.40567408400004, 19.047163729000083],
              [104.40573416000004, 19.047754717000132],
              [104.40554565800008, 19.048305519000053],
              [104.40529469800001, 19.048934880000076],
              [104.40446115900005, 19.050192560000042],
              [104.40277183800008, 19.053121371000081],
              [104.40227073500009, 19.054143789000122],
              [104.40185379500005, 19.054811997000073],
              [104.40089597200003, 19.056029867000035],
              [104.39964741200002, 19.057404323000071],
              [104.39810976000007, 19.05846273900007],
              [104.39646938800006, 19.059264795000082],
              [104.39456075000005, 19.059731176000035],
              [104.393315973, 19.060042170000102],
              [104.38280113300006, 19.061641815000115],
              [104.38234498200006, 19.061679686000055],
              [104.38192955700006, 19.061914603000041],
              [104.38130568400004, 19.062463917000102],
              [104.37727378500004, 19.065325527000084],
              [104.37369377200004, 19.069251997000066],
              [104.37236299800007, 19.070350285000082],
              [104.36589791000009, 19.074806495000104],
              [104.36494361600009, 19.07500013800005],
              [104.36246525100003, 19.074254681000099],
              [104.36005794600007, 19.073813966000053],
              [104.34980356300008, 19.072005106000042],
              [104.33703591699999, 19.076863167000099],
              [104.331509764, 19.079875908000062],
              [104.32305496800006, 19.084236551000089],
              [104.31960589000005, 19.086153735],
              [104.31655342800009, 19.087383091000021],
              [104.31131301600001, 19.088591019000134],
              [104.30978882700003, 19.089096332000018],
              [104.30785766500003, 19.090579572000088],
              [104.30470742100005, 19.09452809900003],
              [104.303805126, 19.096185513000115],
              [104.30384419000004, 19.097548378000084],
              [104.30225156800007, 19.106379541000074],
              [104.30039714200002, 19.110843910000064],
              [104.29917823300006, 19.113224022000082],
              [104.29805495700003, 19.114156603000062],
              [104.2956072540001, 19.115009895000057],
              [104.28067947400007, 19.120213084000071],
              [104.27816950700006, 19.120799446000092],
              [104.27373641700007, 19.1208671670001],
              [104.27033499900001, 19.120513036000105],
              [104.25931021600007, 19.116979526000044],
              [104.25388458700003, 19.115562684000054],
              [104.24319739000003, 19.116281415000103],
              [104.23799811800009, 19.115289578000016],
              [104.23266329700002, 19.112869675000077],
              [104.23131627100003, 19.112726206000019],
              [104.22861602000008, 19.113778302000114],
              [104.22332296100009, 19.116005346000087],
              [104.21995574200011, 19.117153797000071],
              [104.21704531400007, 19.118643244000033],
              [104.21408550100006, 19.119793325000067],
              [104.21255097000009, 19.121143223000026],
              [104.20936682900007, 19.126603896000027],
              [104.20946499700003, 19.127509090000046],
              [104.21799763200009, 19.143169670000049],
              [104.21972198500004, 19.144824137000072],
              [104.22241229300005, 19.146918693000089],
              [104.22418768300005, 19.148573338000041],
              [104.22519959100002, 19.15007941800004],
              [104.22514094600005, 19.151726307000061],
              [104.22432985500009, 19.154708001000117],
              [104.2228421770001, 19.156881768000034],
              [104.22049058000003, 19.158567381000054],
              [104.21778778900006, 19.15908879800012],
              [104.21482862400008, 19.159948187000083],
              [104.200621552, 19.162530195000024],
              [104.19868007500003, 19.163635965000125],
              [104.19744883600004, 19.165374615000097],
              [104.1964664600001, 19.168325468000091],
              [104.19330658000004, 19.180830646000047],
              [104.18715011200003, 19.18969037300009],
              [104.18486967100009, 19.193638365000076],
              [104.18104803600006, 19.197766047000094],
              [104.17961657100003, 19.198680106000054],
              [104.17823992300006, 19.198819269000111],
              [104.16839497600004, 19.198625248000042],
              [104.15426403100004, 19.199470658000095],
              [104.14826215500003, 19.200195963000063],
              [104.12900228400011, 19.204086013000051],
              [104.12764138900005, 19.204617451000061],
              [104.12581811100009, 19.20697556600004],
              [104.12088277500004, 19.214800369000073],
              [104.11658351500003, 19.220294110000054],
              [104.11346062700001, 19.222749481000086],
              [104.11164672400011, 19.223446548000119],
              [104.10937461400006, 19.223038467000016],
              [104.10766732400002, 19.221177326000124],
              [104.10630285100009, 19.216009186000079],
              [104.10538036300007, 19.214945919000037],
              [104.10376123600004, 19.213967475000082],
              [104.10292680300009, 19.213786929000065],
              [104.10153329800008, 19.214000679000073],
              [104.10013306200004, 19.215449614000057],
              [104.09938134400002, 19.217122280000076],
              [104.09797480800009, 19.219718162],
              [104.09263177700004, 19.229176527000021],
              [104.09146364300003, 19.230582419000086],
              [104.09006657900008, 19.231413655000026],
              [104.08249059800002, 19.234785400000121],
              [104.07618142000007, 19.239048742000087],
              [104.07460709600011, 19.241091135000055],
              [104.07203189900011, 19.244157343],
              [104.07117013800004, 19.245773637000063],
              [104.07047979800002, 19.247228725000106],
              [104.06956786800006, 19.247656258000042],
              [104.06809052300005, 19.247540706000073],
              [104.06327164100003, 19.24524718100011],
              [104.06003989200006, 19.243772022000094],
              [104.05787986400004, 19.24376091500006],
              [104.05565559600004, 19.245045971000074],
              [104.05372106600002, 19.245360107000039],
              [104.05059936000002, 19.244533615000044],
              [104.03581397200004, 19.24094847900006],
              [104.02135863900003, 19.237846569000091],
              [104.01761008900012, 19.237340293000038],
              [104.00966451300003, 19.235298696000044],
              [104.0083792450001, 19.234860280000099],
              [104.00396178400007, 19.232189262000077],
              [104.00163796000001, 19.231096188000052],
              [104.00016149500006, 19.230872045000069],
              [103.99890943700011, 19.231135318],
              [103.99771357000002, 19.231506921000104],
              [103.99623813900007, 19.232413109000035],
              [103.99387818100011, 19.237262042000047],
              [103.99267499600003, 19.238822031000069],
              [103.98112286400007, 19.244141338000098],
              [103.979071891, 19.244886197000085],
              [103.97702458900008, 19.245036828000117],
              [103.97304331900008, 19.245392680000073],
              [103.96928164600007, 19.246992141000071],
              [103.96015389300005, 19.252180478000099],
              [103.95494817900001, 19.25723287100007],
              [103.94495784800003, 19.268079901000057],
              [103.93981844000005, 19.272175227000062],
              [103.93471186600004, 19.276084282000028],
              [103.92590622700003, 19.283274940000084],
              [103.92301334100003, 19.285989604000072],
              [103.91653355600009, 19.295226344000049],
              [103.9157135880001, 19.297889638000051],
              [103.91601006500012, 19.298726010000067],
              [103.91638286900006, 19.299896089000065],
              [103.91615402700003, 19.301554517000064],
              [103.91446337300005, 19.303370360000116],
              [103.90840527000009, 19.305958934000067],
              [103.90222310200006, 19.308660479000125],
              [103.89841110300006, 19.309605076000125],
              [103.89496716800002, 19.309217142000087],
              [103.89236485800009, 19.308105995000076],
              [103.89069175300004, 19.306735166000074],
              [103.88935456800007, 19.303246087000048],
              [103.88840666500006, 19.302073615000026],
              [103.88676874900008, 19.301350007000018],
              [103.88487917600006, 19.301037337000082],
              [103.88196521800009, 19.302194533000083],
              [103.88193874300003, 19.303461525000017],
              [103.881842792, 19.304458690000011],
              [103.88054101600002, 19.305647205000028],
              [103.87902888300006, 19.306118188000099],
              [103.875115513, 19.306472524000107],
              [103.87382412800009, 19.307161704000109],
              [103.87290517900009, 19.308000622000066],
              [103.87272065800001, 19.309638301000028],
              [103.87265642200002, 19.312705347000048],
              [103.87299491100005, 19.318063320000078],
              [103.87320076300003, 19.318994811000053],
              [103.87382035500005, 19.319898433000034],
              [103.88231265100006, 19.326908494000072],
              [103.89054383200006, 19.332058075000091],
              [103.89279073900008, 19.332385704000018],
              [103.896399753, 19.332239403000081],
              [103.90168311200006, 19.331054139000067],
              [103.90386233500007, 19.331023623000121],
              [103.90528913, 19.331050346000019],
              [103.91009054000008, 19.333173769000034],
              [103.91320608600002, 19.335087191000078],
              [103.91503026000005, 19.337689965000045],
              [103.91588411800009, 19.339989186000125],
              [103.91644498800005, 19.341926175000076],
              [103.91717824000006, 19.342796084000021],
              [103.9186654480001, 19.343537372000071],
              [103.92091252300003, 19.343864708000069],
              [103.92525355400002, 19.344659156000077],
              [103.9280926160001, 19.34542555500007],
              [103.93151629200005, 19.346987664],
              [103.93396518300008, 19.348460245000076],
              [103.94114718100003, 19.353588262000088],
              [103.94476265100005, 19.356794770000093],
              [103.94622799900006, 19.357696002000054],
              [103.94760423600006, 19.358363662000052],
              [103.94902830100003, 19.358532751000013],
              [103.95022119500004, 19.358982943000044],
              [103.9506608290001, 19.359526215000052],
              [103.95106772500002, 19.360291842000073],
              [103.9532650180001, 19.364426234000028],
              [103.95508412600006, 19.367313879000037],
              [103.95652921599999, 19.370123237000044],
              [103.95915161100008, 19.376896362000103],
              [103.95921209400011, 19.377610975000081],
              [103.95904723600006, 19.378321415000066],
              [103.95811514600003, 19.379802519000044],
              [103.95588121500002, 19.382472467000063],
              [103.95497848000011, 19.38343681500001],
              [103.95477970500011, 19.383968255000106],
              [103.95488066200002, 19.384540910000055],
              [103.95561790300009, 19.38523235100007],
              [103.96404738200006, 19.391916389000038],
              [103.96483101000005, 19.392180555000046],
              [103.96588558100009, 19.392057316000063],
              [103.96761914300001, 19.391803888000055],
              [103.96881739000003, 19.392004347000032],
              [103.96981688700004, 19.392736238000019],
              [103.97090118200003, 19.393915613000075],
              [103.97191637800002, 19.395717988000079],
              [103.97282804500004, 19.39890972200002],
              [103.97322517600004, 19.399701855000018],
              [103.97381958300011, 19.400033857000039],
              [103.97531909400001, 19.400204140000099],
              [103.97910981400004, 19.400416549000084],
              [103.98241443700007, 19.40051293800007],
              [103.98470564600004, 19.400555006000083],
              [103.98590178300006, 19.400862346000025],
              [103.98682845399999, 19.401485797000092],
              [103.98913597700005, 19.404417653000024],
              [103.99016664000006, 19.405471068000054],
              [103.99079647700003, 19.405910696000106],
              [103.99218119400004, 19.406185784000037],
              [103.99432222100008, 19.406224998000084],
              [103.99691834600003, 19.406058477000073],
              [103.99887735600004, 19.40580893000012],
              [104.00054022700004, 19.405339910000038],
              [104.00252093900005, 19.404020535000122],
              [104.00366226100007, 19.403327916000102],
              [104.00603730800003, 19.402943216000068],
              [104.00930876000008, 19.40282455000002],
              [104.01043415900003, 19.402916431000072],
              [104.01155594000004, 19.40318659200004],
              [104.01263367400003, 19.403777011000045],
              [104.01354239900007, 19.405291848000061],
              [104.01566428700005, 19.408077940000098],
              [104.01631565800004, 19.40797319800005],
              [104.01714203000002, 19.407988245000048],
              [104.017736536, 19.408320124000063],
              [104.01931496200004, 19.410168165000115],
              [104.01998973900001, 19.412106785000027],
              [104.020348175, 19.414824430000088],
              [104.02044213300005, 19.415753630000054],
              [104.02076507100003, 19.416508626000081],
              [104.02117107500005, 19.416872745000099],
              [104.02210297600007, 19.417246416000111],
              [104.02360554000003, 19.417273731000058],
              [104.02586730400004, 19.416922423000038],
              [104.02774623100007, 19.416920874000084],
              [104.02961940700007, 19.417204587000114],
              [104.03148036200004, 19.418094496000023],
              [104.03347795800003, 19.419664632],
              [104.03574643000005, 19.420847269000063],
              [104.03705402200006, 19.421227682000094],
              [104.04074499900001, 19.422685734000119],
              [104.04402207900009, 19.424171900000076],
              [104.04778453600008, 19.426754788],
              [104.04834516200002, 19.426907599000032],
              [104.04898449800007, 19.426883474000078],
              [104.04966713300007, 19.426574751000111],
              [104.05009108400006, 19.426047321000084],
              [104.05033650700003, 19.42505293200005],
              [104.05179253500006, 19.419906733000047],
              [104.05210449500004, 19.419341602000095],
              [104.05271124500005, 19.419067170000034],
              [104.0562530230001, 19.41859595400004],
              [104.06111416500005, 19.418049074000088],
              [104.06202817200008, 19.418042079000131],
              [104.06266917600001, 19.418100514000081],
              [104.06321048600003, 19.418204042000028],
              [104.06372803600004, 19.418260243000063],
              [104.06424653400005, 19.418269571000032],
              [104.06480115500003, 19.41817019800007],
              [104.06576509600011, 19.417884318000041],
              [104.06754693100001, 19.417259103000092],
              [104.06866258500004, 19.418154588000021],
              [104.06968041500005, 19.42017011800008],
              [104.07031608100007, 19.422444308000038],
              [104.07044930000008, 19.42612946500013],
              [104.07053978500002, 19.430737407],
              [104.07072307200008, 19.43374229700003],
              [104.07086100300003, 19.435972871000025],
              [104.07137607700011, 19.438198668000069],
              [104.07206226100004, 19.440993214000102],
              [104.07246635800009, 19.442685958000091],
              [104.07359527600005, 19.44645816300001],
              [104.07395766300002, 19.447803818000061],
              [104.07434208300003, 19.449265305000019],
              [104.07415344300006, 19.450185476000037],
              [104.07370467200005, 19.450731551000011],
              [104.07306410800007, 19.451135648000047],
              [104.07058727800003, 19.451518330000091],
              [104.06478520900004, 19.452129811000063],
              [104.06377523600004, 19.452758133000088],
              [104.0633947650001, 19.453536300000081],
              [104.06359932600006, 19.454255732000014],
              [104.06468029100006, 19.454944746000088],
              [104.06671662100005, 19.455304595000108],
              [104.06832022400005, 19.45601452500005],
              [104.06952050100006, 19.456821095000087],
              [104.07006924400008, 19.457361986000073],
              [104.07221929000006, 19.459340025000046],
              [104.07390641900004, 19.460744061000042],
              [104.07571008000006, 19.463015982000087],
              [104.0781011450001, 19.465113715000101],
              [104.07954332600005, 19.466605659],
              [104.08055236900006, 19.467247113000049],
              [104.08370575000002, 19.467719159000026],
              [104.08406596900008, 19.467956487000038],
              [104.08447069100008, 19.468402397000069],
              [104.08453263800004, 19.468957628000091],
              [104.08412192900008, 19.470035421000098],
              [104.08349324600002, 19.471674974000038],
              [104.08332298000006, 19.472895595000047],
              [104.08345374600005, 19.474883506000126],
              [104.08372630900004, 19.476469913000116],
              [104.08399566200004, 19.478829703000052],
              [104.08433752900007, 19.479990222000062],
              [104.08507774200004, 19.481307926000021],
              [104.08694367900006, 19.48413492100007],
              [104.08828326900004, 19.485901992],
              [104.09027090600001, 19.488731116000046],
              [104.09134115500011, 19.489973874000022],
              [104.09318655700011, 19.491392082000033],
              [104.094844692, 19.491825701000032],
              [104.09778325100004, 19.492108985000108],
              [104.10091549000003, 19.492441858000028],
              [104.10313669900006, 19.492088928000051],
              [104.10376082900009, 19.491903792000095],
              [104.10486406200008, 19.491484762000063],
              [104.10650878500009, 19.490752137000108],
              [104.10792957900004, 19.490269477000055],
              [104.10926781400011, 19.490270185000021],
              [104.11102555200006, 19.490590019000109],
              [104.1122551070001, 19.491165969000065],
              [104.11275906100005, 19.491521227000064],
              [104.11356327400006, 19.492689890000037],
              [104.11489086200004, 19.49507992000008],
              [104.11561985200005, 19.496362667000078],
              [104.11660667100008, 19.496911187000073],
              [104.11791197900007, 19.49734990900005],
              [104.11938710300009, 19.497814728000051],
              [104.12083609899999, 19.498371420000105],
              [104.12138282500011, 19.499027557000126],
              [104.12156468400005, 19.499677219000056],
              [104.12162958200001, 19.500705756000066],
              [104.1217111930001, 19.50273887900007],
              [104.1217637070001, 19.503778726000043],
              [104.12150954400002, 19.504328321000038],
              [104.12095847500008, 19.505126600000011],
              [104.12045139100005, 19.505544729000057],
              [104.11926877300007, 19.506285641000076],
              [104.11752073500003, 19.507316659000118],
              [104.1139210210001, 19.510311814000133],
              [104.11228133200004, 19.512014225000129],
              [104.11178159399999, 19.512674864000076],
              [104.11164857000009, 19.513249678000122],
              [104.1112751090001, 19.514018373000042],
              [104.11047789800004, 19.515299963000118],
              [104.10903321300006, 19.516826773000062],
              [104.10774222100008, 19.518119768000105],
              [104.10545780700008, 19.519942990000061],
              [104.10495870600008, 19.520518543000072],
              [104.10345978800005, 19.522477325000054],
              [104.10246339300004, 19.523637013000084],
              [104.10183978400003, 19.524722469000011],
              [104.10452345500005, 19.5260417490001],
              [104.10519630900002, 19.52673463000005],
              [104.10595279500009, 19.527932286000024],
              [104.10738585200009, 19.531214306000066],
              [104.10767734000005, 19.532255656000075],
              [104.10784178900006, 19.534197687000088],
              [104.10758360100002, 19.536206220000096],
              [104.10730374900004, 19.539309725000109],
              [104.10743340100002, 19.540644225000037],
              [104.10771368700003, 19.541463329000038],
              [104.10843032100009, 19.542072275000066],
              [104.10623955800001, 19.544955231000039],
              [104.1041280960001, 19.547110847000049],
              [104.10384121900009, 19.547463578000098],
              [104.10360579800005, 19.548290485000038],
              [104.10325019800003, 19.550269548000095],
              [104.10319896200005, 19.552242480000089],
              [104.10325927100007, 19.554113511000089],
              [104.10325354900004, 19.556860616000016],
              [104.10324171700003, 19.55807240700009],
              [104.10324970600007, 19.560127174000016],
              [104.10359840000007, 19.562799767000079],
              [104.10410092400008, 19.56569440100013],
              [104.10481582200008, 19.566468940000092],
              [104.10652106900004, 19.567630454],
              [104.10661618100009, 19.568359340000093],
              [104.10637709200003, 19.569370853000031],
              [104.105408, 19.570369374000052],
              [104.1037984700001, 19.571748954000064],
              [104.10209986500007, 19.573323154000022],
              [104.09923234500003, 19.575534454000106],
              [104.09700613800004, 19.57731852200002],
              [104.09465361000008, 19.578719406000019],
              [104.09350211300003, 19.579091315000063],
              [104.09125782600005, 19.579206528],
              [104.08468937600006, 19.582480448000084],
              [104.08267154300007, 19.585835605000042],
              [104.07567929700009, 19.594034334000106],
              [104.07131793000009, 19.600738525000061],
              [104.07058773500007, 19.604733168000088],
              [104.0685817520001, 19.607471756000017],
              [104.06166836600008, 19.611663554000103],
              [104.05997495300002, 19.615024229000078],
              [104.05925064900005, 19.618710599000089],
              [104.06136599800001, 19.626763949000022],
              [104.06672060900003, 19.635491928000093],
              [104.06695947600008, 19.63981205900005],
              [104.06295280700003, 19.644980743000048],
              [104.05797043899999, 19.650131772000101],
              [104.05824000700009, 19.652911055000018],
              [104.06300035200006, 19.658853742000112],
              [104.06898165500006, 19.668825615000074],
              [104.07012846800009, 19.676552730000104],
              [104.06977396400006, 19.680051662000061],
              [104.06941134000009, 19.683630593000068],
              [104.07075910500004, 19.683131088000039],
              [104.0711012090001, 19.682942051000026],
              [104.07622227900008, 19.680112185],
              [104.08212690400003, 19.674577901000042],
              [104.08513226000011, 19.671106632000068],
              [104.08784069500011, 19.668780275000053],
              [104.09094040499998, 19.666460851000075],
              [104.09433248600007, 19.665185552000075],
              [104.10520698700002, 19.661668572000046],
              [104.10960314700006, 19.661078778],
              [104.11273380900009, 19.661134342000075],
              [104.11789649100004, 19.661374290000118],
              [104.11945304600005, 19.661847126000076],
              [104.13540790600005, 19.662722798000054],
              [104.13844431600002, 19.663592646000083],
              [104.14934517700001, 19.666678682000068],
              [104.15143666900005, 19.667828580000091],
              [104.15436158500003, 19.67040307400012],
              [104.15588494200004, 19.672581880000095],
              [104.15648211700007, 19.674076547000126],
              [104.15644296300012, 19.67607951200003],
              [104.15580802700008, 19.680520925000074],
              [104.15544270500004, 19.683186023000054],
              [104.15539918700009, 19.685411518000073],
              [104.15664069000005, 19.692000719000099],
              [104.15837153800008, 19.695593032000055],
              [104.15968057700003, 19.696729087000087],
              [104.16092293600006, 19.697270301000103],
              [104.16217110400004, 19.697514787000081],
              [104.16327717399999, 19.697014705000093],
              [104.16939880300006, 19.692298294000032],
              [104.17162963000007, 19.6903336660001],
              [104.17282259100001, 19.689389795000075],
              [104.17368942200005, 19.689108106000042],
              [104.17486360300005, 19.689128604000047],
              [104.17656408400006, 19.690271398000014],
              [104.17830681700011, 19.69327009700006],
              [104.17961595600006, 19.694406036000075],
              [104.18234856800002, 19.694824691000079],
              [104.18844008000009, 19.695672791000085],
              [104.19149305000009, 19.695725866000124],
              [104.19345294699998, 19.695611504000077],
              [104.19430201700007, 19.695236678000057],
              [104.19628775100006, 19.693787002000107],
              [104.19696031000011, 19.693427637000035],
              [104.19754956200011, 19.693326548000108],
              [104.1983309300001, 19.69341430900009],
              [104.19933710000004, 19.694025424000117],
              [104.20432149400006, 19.699491816000055],
              [104.207472416, 19.702588838000068],
              [104.20847580000006, 19.703348263000109],
              [104.20987275500001, 19.704003179000026],
              [104.21162343800009, 19.704589994000088],
              [104.21423811900007, 19.705043311000026],
              [104.22176553300001, 19.706583121000065],
              [104.23366999300001, 19.708568955000061],
              [104.23484570800011, 19.708514941000118],
              [104.23555810600008, 19.708119051000047],
              [104.23683482700002, 19.706879478000069],
              [104.23758707500009, 19.706447158000095],
              [104.23908230700009, 19.706064683000065],
              [104.24175111000001, 19.705739431000069],
              [104.25061858700008, 19.704778213000026],
              [104.25396125900008, 19.704019104000011],
              [104.25517962200007, 19.703780205000051],
              [104.25686203800009, 19.703846051000085],
              [104.25778690400006, 19.704112287000086],
              [104.25920607300006, 19.704637409000014],
              [104.25990816700008, 19.704779246000022],
              [104.26043624700006, 19.70480681],
              [104.26096643400005, 19.704723098000088],
              [104.26136138200005, 19.704544320000124],
              [104.26233906200012, 19.703577767000091],
              [104.26323916000007, 19.702572780000068],
              [104.26420887700002, 19.700993874000083],
              [104.26533620700003, 19.699361995000061],
              [104.26569693400003, 19.698922904000057],
              [104.26639142300004, 19.698433838000092],
              [104.26788397300007, 19.69767081800002],
              [104.26890868300003, 19.697317232000074],
              [104.27036112900004, 19.697119329000131],
              [104.27259252500002, 19.697138737000053],
              [104.27370980000009, 19.697064978000078],
              [104.27944371900006, 19.696142060000099],
              [104.28178172200006, 19.695699392000066],
              [104.28310031200009, 19.695332171000054],
              [104.28449681200009, 19.694984817000091],
              [104.28539916100004, 19.694888810000052],
              [104.28650190700007, 19.695065184000079],
              [104.2896184690001, 19.695897144000085],
              [104.29658767300003, 19.69798148600006],
              [104.29834134500001, 19.69841925100004],
              [104.299906306, 19.698482784000056],
              [104.30151460300002, 19.698324414000098],
              [104.30277061600003, 19.69816009],
              [104.30360025400002, 19.697765945000079],
              [104.30511271900011, 19.696455738000026],
              [104.30685862400006, 19.695223648000045],
              [104.31291951900008, 19.69146694900008],
              [104.31608826200006, 19.689516642000093],
              [104.31822681600008, 19.688216857000015],
              [104.31893497000006, 19.687615479000087],
              [104.31934077900006, 19.687270871000081],
              [104.31994522300006, 19.686353430000025],
              [104.32059783100006, 19.684954454000092],
              [104.32093176600007, 19.683846947000028],
              [104.32094634200003, 19.68306800900006],
              [104.3204275570001, 19.68150093800007],
              [104.31815103400002, 19.67658352600008],
              [104.31718071200004, 19.67404413400002],
              [104.31693316200007, 19.672630018000078],
              [104.31691277900006, 19.671627860000029],
              [104.31708137000007, 19.669942441000018],
              [104.31768855000004, 19.668876620000027],
              [104.31822474000009, 19.668094710000084],
              [104.32052547500003, 19.669955236000099],
              [104.322454163, 19.671003233000036],
              [104.32831812400006, 19.673894498000074],
              [104.33421132700006, 19.676532184000095],
              [104.33652712800009, 19.677725005000113],
              [104.33862067400003, 19.680391328000013],
              [104.33976184400004, 19.681887631000095],
              [104.34034261600004, 19.683397647000064],
              [104.34053713100005, 19.686032210000093],
              [104.34089836000001, 19.687561629000072],
              [104.34132460000011, 19.688215035000042],
              [104.3439190230001, 19.690151020000087],
              [104.34652293000008, 19.691579342000054],
              [104.35034004000006, 19.693258641000085],
              [104.35165059600006, 19.693511275000027],
              [104.35274501400002, 19.693598736000069],
              [104.35616583700008, 19.693009354000068],
              [104.35973273700006, 19.692422355000083],
              [104.36041279000003, 19.692525973000038],
              [104.36132609100009, 19.693187423000055],
              [104.36458719700009, 19.695965144000112],
              [104.36608234600007, 19.696774701000038],
              [104.36671031500008, 19.697062091000106],
              [104.36765865800002, 19.697147055000116],
              [104.371364876, 19.696931470000045],
              [104.37814335100005, 19.696535812000079],
              [104.3812558270001, 19.696818038000075],
              [104.38195728400005, 19.697083509],
              [104.38330387300009, 19.698028983000079],
              [104.38508791700008, 19.699027829000038],
              [104.38702062700004, 19.699890621000101],
              [104.38900967500004, 19.700338867000063],
              [104.39042281500008, 19.700315979000038],
              [104.3915513800001, 19.699872940000063],
              [104.39233819400002, 19.699470427000065],
              [104.39342143500002, 19.698841987000023],
              [104.39538971800008, 19.697766467000029],
              [104.39636792500001, 19.697551742000115],
              [104.39716849899999, 19.697726471000095],
              [104.400594025, 19.699536924000057],
              [104.40121528500006, 19.70019340200011],
              [104.40135040800006, 19.70079573100012],
              [104.4007423510001, 19.702078287000099],
              [104.40062635900001, 19.703091955000041],
              [104.40100244900005, 19.703836717000023],
              [104.40200742300007, 19.704822622000059],
              [104.40389071700011, 19.70573061100005],
              [104.40701548700009, 19.706682016000073],
              [104.4128444880001, 19.707608406000077],
              [104.414453323, 19.707542400000122],
              [104.41548951800011, 19.706820766000099],
              [104.41822022500001, 19.704003358000044],
              [104.42144877300007, 19.700593937000029],
              [104.421605798, 19.69999639200006],
              [104.42154156600002, 19.699510643000018],
              [104.41983712900003, 19.696805396000094],
              [104.41865832200003, 19.69466265500008],
              [104.41857521300008, 19.693876537000051],
              [104.41862895700008, 19.693600436000033],
              [104.41902779300004, 19.693099171000128],
              [104.42086238200005, 19.691328794000107],
              [104.42144382400002, 19.690161135000039],
              [104.42170914800003, 19.688965233000118],
              [104.42162687000007, 19.688132958],
              [104.42140102700003, 19.687159848000043],
              [104.420654649, 19.685347300000089],
              [104.41908634300009, 19.683198176000083],
              [104.41866489900004, 19.682268018000038],
              [104.41867414600009, 19.681760380000043],
              [104.42385217800006, 19.674251045000091],
              [104.42415608900008, 19.673609718000051],
              [104.42597702800002, 19.67257765],
              [104.42724891200001, 19.672275247000037],
              [104.42924700100004, 19.672215489000067],
              [104.43155697700003, 19.672414690000025],
              [104.43713660600011, 19.67361340300009],
              [104.44288658000006, 19.673475997000089],
              [104.44391247500003, 19.673307997000045],
              [104.44633180500001, 19.671500692000073],
              [104.44683040600006, 19.670862489000029],
              [104.4468932770001, 19.670078718000106],
              [104.44681924900001, 19.668784931000083],
              [104.4461890100001, 19.667274381000063],
              [104.44403765500009, 19.662346079000059],
              [104.44290498700008, 19.660342631000027],
              [104.44291917900009, 19.659558060000037],
              [104.44327001800008, 19.659009785000059],
              [104.44382229600004, 19.65809546100008],
              [104.444712893, 19.657325111000112],
              [104.45089462000007, 19.653478254000056],
              [104.45216967300004, 19.652991089000068],
              [104.45348924700008, 19.652735469000056],
              [104.45578340900009, 19.652449431000015],
              [104.45759235700011, 19.652063206000037],
              [104.45911414600008, 19.651395334000107],
              [104.46006918500005, 19.65092030400006],
              [104.46095696500005, 19.650478724000031],
              [104.46218411400007, 19.649944566000066],
              [104.46320904900006, 19.649822617000012],
              [104.46496179300003, 19.649850903000043],
              [104.46666088300002, 19.650155302000094],
              [104.46820810900005, 19.65078039200003],
              [104.46984278100004, 19.651960853000091],
              [104.47152449800009, 19.653234394000037],
              [104.4746000170001, 19.655545967000073],
              [104.47565959400001, 19.656209311000069],
              [104.476073861, 19.656192894000043],
              [104.47837794700004, 19.655352809000078],
              [104.48096837500006, 19.654840448000051],
              [104.48306529300001, 19.654689461000082],
              [104.48559957700009, 19.65459163000007],
              [104.48881797800001, 19.654366246000073],
              [104.48979834800004, 19.654012637000037],
              [104.49130022700005, 19.653090314000018],
              [104.49219142900007, 19.652273620000038],
              [104.49284084100006, 19.651360724000085],
              [104.49315765900008, 19.649980856000049],
              [104.4932491430001, 19.64758174600005],
              [104.49342317900002, 19.646014920000084],
              [104.49398835200006, 19.644362023000014],
              [104.49693135200003, 19.637715120000095],
              [104.497282001, 19.637166744000112],
              [104.49989185300002, 19.635546496000075],
              [104.50186383600003, 19.634193017000086],
              [104.50363108500005, 19.632028358000042],
              [104.50453363400004, 19.630565460000057],
              [104.50451365800008, 19.628949330000097],
              [104.50448876100006, 19.627610117000046],
              [104.50427242100001, 19.626083187000098],
              [104.50434079100008, 19.624976288000084],
              [104.5050988930001, 19.623418731000086],
              [104.50554931900005, 19.622733424000046],
              [104.50638263399999, 19.622423554000079],
              [104.50830675900004, 19.622384981000081],
              [104.51025153900009, 19.622554471000072],
              [104.51176473600006, 19.62234774100002],
              [104.51323334799999, 19.621909450000082],
              [104.5154427800001, 19.620882781000134],
              [104.51780396400007, 19.619535339000024],
              [104.52075498100007, 19.617874067000059],
              [104.52272419500011, 19.616658843000053],
              [104.52418348800006, 19.615366250000044],
              [104.52502571100003, 19.614548613000068],
              [104.52581109500008, 19.614191736000031],
              [104.52741994300007, 19.614078745000093],
              [104.53581222500007, 19.613103622000061],
              [104.53698051600001, 19.613122094000076],
              [104.53789647000004, 19.613644409000038],
              [104.53873413800007, 19.614465565000039],
              [104.54164513300005, 19.617835540000073],
              [104.54351201800006, 19.619665495000049],
              [104.54448156600009, 19.619911622000075],
              [104.54501786100005, 19.619873911000063],
              [104.5457066860001, 19.619469282000068],
              [104.54593519700009, 19.619254354000049],
              [104.54961532500003, 19.616548456000068],
              [104.55431183300004, 19.614835427000088],
              [104.55862706600004, 19.614392713000051],
              [104.56321204700006, 19.614208421000079],
              [104.56593390700003, 19.614178083000056],
              [104.56797600800006, 19.614348588000041],
              [104.56934927700009, 19.615178015000097],
              [104.57052280100002, 19.617689385000055],
              [104.57223709200011, 19.622702162000039],
              [104.57388644700006, 19.624459189000021],
              [104.57715823900006, 19.626726300000108],
              [104.58464788500007, 19.630074782000051],
              [104.5886414230001, 19.631452727000109],
              [104.59146185800005, 19.631681294000103],
              [104.60335584800006, 19.633804962000042],
              [104.60726575600009, 19.634396447000043],
              [104.60940149600006, 19.63479883400003],
              [104.61067126200004, 19.634587656000058],
              [104.61531509800005, 19.630735398000084],
              [104.61733572600008, 19.629335477000076],
              [104.61882412400004, 19.629150707000065],
              [104.62033170600009, 19.629266299000037],
              [104.62300847800005, 19.629353746000078],
              [104.625445798, 19.62920663600007],
              [104.62936575300007, 19.627789692000071],
              [104.6317867700001, 19.625749472000017],
              [104.63290213800011, 19.623897133000057],
              [104.63331541700011, 19.623210772000022],
              [104.64046766200009, 19.623551402000047],
              [104.64274151700002, 19.624417243000011],
              [104.64544172700006, 19.625982081000082],
              [104.64714315500007, 19.629008887000055],
              [104.64780618500004, 19.631535041000078],
              [104.64887558200006, 19.634505971000046],
              [104.65013817800008, 19.637572176000084],
              [104.65049543700006, 19.63947039700011],
              [104.65007801300007, 19.642533972000109],
              [104.64974658300009, 19.644837137000067],
              [104.64873273800005, 19.647176017000056],
              [104.6448335370001, 19.653071537000066],
              [104.64457434400006, 19.653990846000099],
              [104.64473287600003, 19.656116814000058],
              [104.64551145800006, 19.657582903000034],
              [104.64772523300009, 19.659140186000101],
              [104.65038984600002, 19.659965714000101],
              [104.65403615900001, 19.660344576000099],
              [104.65655709000005, 19.661029342000091],
              [104.658340616, 19.662118309000043],
              [104.6586960010001, 19.662700775000097],
              [104.65876829400004, 19.664179111000109],
              [104.65842751800008, 19.667036040000063],
              [104.65817706300005, 19.670309808000042],
              [104.658604747, 19.672370581000017],
              [104.65911920700003, 19.673624818000057],
              [104.66026512800008, 19.674981009000092],
              [104.66315390000008, 19.676963852000107],
              [104.66544978400006, 19.679445411000081],
              [104.66663900300006, 19.681125351000084],
              [104.66825165700007, 19.683688805000088],
              [104.66885734100001, 19.685313695000062],
              [104.66903728100007, 19.686193504000116],
              [104.66855990100001, 19.688494354000063],
              [104.66564855500008, 19.693574043000069],
              [104.66303317900004, 19.698427364000089],
              [104.661727, 19.700761701000054],
              [104.66145293200003, 19.702557816000073],
              [104.66183236000008, 19.703163691000022],
              [104.66294309400004, 19.703734536000091],
              [104.66526746000008, 19.704554657000095],
              [104.66815231100003, 19.705360186000043],
              [104.67190262200009, 19.708279140000059],
              [104.67487848300001, 19.710909305000079],
              [104.67766428000004, 19.713259581000031],
              [104.68065100900007, 19.716697622000098],
              [104.68198358900001, 19.718564212000089],
              [104.68234674500005, 19.720139147000012],
              [104.6828112160001, 19.721484821000068],
              [104.68362287000011, 19.722466464000021],
              [104.68512032000002, 19.723227675000118],
              [104.68768543100008, 19.724235809000042],
              [104.69209910400005, 19.725410295000053],
              [104.69400479500007, 19.72652379400008],
              [104.69679942500002, 19.731274203000027],
              [104.69847775000007, 19.735776934000079],
              [104.69949129800008, 19.73780012000006],
              [104.70617925900011, 19.742839701000037],
              [104.71290466400004, 19.747117998000085],
              [104.71492204700007, 19.748856100000097],
              [104.72028934499998, 19.754244651000086],
              [104.72182664899999, 19.755560064000029],
              [104.72347217800008, 19.75623087600011],
              [104.72531642000008, 19.75667384500008],
              [104.7273369330001, 19.756773251000048],
              [104.72919347700005, 19.75647784300012],
              [104.7299775220001, 19.756212597000022],
              [104.73150533100002, 19.755173747000043],
              [104.73318694199999, 19.753675590000071],
              [104.73542124400002, 19.751170182000074],
              [104.73690103600008, 19.75008441200012],
              [104.73812576800006, 19.749687209000029],
              [104.73953845500007, 19.749708222000081],
              [104.74128524200009, 19.750149621000013],
              [104.74261224000003, 19.750930949000086],
              [104.74459802600011, 19.751652813000042],
              [104.74635096300001, 19.751724996000064],
              [104.75069564800005, 19.751235526000066],
              [104.75362837900005, 19.750678915000016],
              [104.75484316500011, 19.750881536000087],
              [104.75546958900007, 19.751306226000054],
              [104.75584862900008, 19.751958051000074],
              [104.75597801900004, 19.752975437000053],
              [104.75590340399999, 19.754543704000049],
              [104.75573059200008, 19.75615667600001],
              [104.75634256400009, 19.757458146000047],
              [104.75742000400007, 19.758604946000077],
              [104.75919736300004, 19.760154431000032],
              [104.761315768, 19.76170892800004],
              [104.76526113400003, 19.76476739800006],
              [104.76607186000005, 19.765840972000071],
              [104.76611070700007, 19.766441589000024],
              [104.76594937000004, 19.767362349000095],
              [104.76581428100006, 19.768168108000062],
              [104.76477069400005, 19.769398956000117],
              [104.76347589200003, 19.771087649000037],
              [104.76162235400005, 19.774152789000077],
              [104.76136963100001, 19.774702942000118],
              [104.7619072900001, 19.776196965000011],
              [104.76226641500006, 19.776470135000054],
              [104.76333445400005, 19.776716685000025],
              [104.76489577300001, 19.77660125400007],
              [104.76860760000008, 19.776102108000067],
              [104.77148207200005, 19.77614444800011],
              [104.77322840800004, 19.776631718000104],
              [104.77494699300004, 19.777326271000014],
              [104.77662344100001, 19.779104862000096],
              [104.77882449400005, 19.781583494000095],
              [104.78208540400004, 19.784816140000046],
              [104.78734119800005, 19.786762519000064],
              [104.79063248300005, 19.788149232000052],
              [104.79566279900007, 19.790484422000084],
              [104.79735758800005, 19.791155362],
              [104.79823386599999, 19.79121431600008],
              [104.79901946000007, 19.790856539000053],
              [104.79980956000007, 19.790221887000122],
              [104.80225329200002, 19.788295917000106],
              [104.80377571400004, 19.78757961800008],
              [104.80543979600006, 19.78714230800005],
              [104.80651169700012, 19.787157920000098],
              [104.80942607300003, 19.787754208000052],
              [104.81083679400004, 19.787913197000059],
              [104.81205860800006, 19.787700183000112],
              [104.813488788, 19.786659387000057],
              [104.81438450300004, 19.785518499000077],
              [104.81526748300008, 19.78516207600007],
              [104.81663170900008, 19.785181884000025],
              [104.81777481800006, 19.785313869000021],
              [104.82024323400002, 19.786365098000132],
              [104.82494397600003, 19.78800250500008],
              [104.82702043700006, 19.78918644700007],
              [104.82937958600004, 19.790974486000046],
              [104.83105738600004, 19.792706492],
              [104.83236643000008, 19.794640846000085],
              [104.83389067200008, 19.796832154000029],
              [104.83510327500004, 19.800218977000036],
              [104.83657239300001, 19.804324872000031],
              [104.83693248900005, 19.806176256000093],
              [104.836892371, 19.808668021000116],
              [104.83653195200003, 19.809862838000036],
              [104.83414336500002, 19.814420721000126],
              [104.83387889500008, 19.815709217000062],
              [104.83384989200007, 19.817508804000013],
              [104.83401506500006, 19.819357339000106],
              [104.83503085100008, 19.824379677000081],
              [104.83563315400001, 19.826326815000073],
              [104.83808257500007, 19.8316236080001],
              [104.83883484300006, 19.833342107000092],
              [104.83967247700009, 19.834300316000061],
              [104.84242735000004, 19.835770727000046],
              [104.84450293900008, 19.837046722000103],
              [104.84579212700008, 19.838726760000021],
              [104.84720086900002, 19.842069993000067],
              [104.84761431600003, 19.843645117000094],
              [104.84755077600002, 19.844567248000097],
              [104.84680637800007, 19.84538729000009],
              [104.8454734260001, 19.846429628000095],
              [104.83952865800009, 19.849297809000085],
              [104.83569511300007, 19.851227081000019],
              [104.83470545900008, 19.85213584100007],
              [104.8341507370001, 19.853281639000016],
              [104.83413214300006, 19.85443517100007],
              [104.83439929600006, 19.856008186000018],
              [104.83562713200004, 19.858471928000121],
              [104.83653091100004, 19.859869511000028],
              [104.83792804799999, 19.860904978000079],
              [104.84090522400004, 19.862217028000103],
              [104.84298108200002, 19.863492995000072],
              [104.84452080400006, 19.864761230000028],
              [104.84489591100001, 19.865689644000014],
              [104.84502287100003, 19.86689139700006],
              [104.84553977200008, 19.868098754000037],
              [104.84639936600006, 19.869218734000071],
              [104.84768729100003, 19.870990968000065],
              [104.84850186700002, 19.871879536000044],
              [104.84848853900007, 19.872710056000109],
              [104.84785738700008, 19.874062422000094],
              [104.84675166900007, 19.876123310000082],
              [104.844604019, 19.880822843000075],
              [104.84276776400004, 19.88282703400008],
              [104.84092628500009, 19.885154177000075],
              [104.8402222980001, 19.886482389000015],
              [104.83980632400007, 19.888091646000113],
              [104.83979988300007, 19.890006760000034],
              [104.84006490200005, 19.891718104000056],
              [104.84038164700006, 19.893245605000054],
              [104.84123314800007, 19.894873087000065],
              [104.84270581500007, 19.897294042000091],
              [104.84337894099998, 19.899403510000099],
              [104.84408276100004, 19.901121148000108],
              [104.84477695500001, 19.903438567000094],
              [104.84494526900009, 19.905102341000088],
              [104.84508171700001, 19.908750046000101],
              [104.84554404300007, 19.91032574000009],
              [104.84650354700005, 19.911308645000055],
              [104.84779989700012, 19.912573266000066],
              [104.84773929800008, 19.91331076900007],
              [104.84753834800006, 19.913677069000087],
              [104.84623783700002, 19.914189089000089],
              [104.84427134100011, 19.915176076000037],
              [104.84200488400008, 19.916620217000066],
              [104.84130894700004, 19.917440866000021],
              [104.84110563600009, 19.918206102000099],
              [104.84111046900006, 19.919168556000066],
              [104.84126990500002, 19.919450340000111],
              [104.84125261700009, 19.9197056900001],
              [104.84147825000011, 19.920558280000122],
              [104.84178095000001, 19.922962318000081],
              [104.84213019000005, 19.925505452],
              [104.84260217900002, 19.926481339000098],
              [104.84399093000009, 19.928070324000124],
              [104.84727786400001, 19.931417087000099],
              [104.85093542100003, 19.93446914700003],
              [104.85435446700004, 19.937194681000037],
              [104.85699860400005, 19.939586043000098],
              [104.8593328680001, 19.941534529000116],
              [104.86200597600003, 19.945172173000088],
              [104.863063998, 19.946110216000079],
              [104.86543346500011, 19.947390003000095],
              [104.86853367100012, 19.948726346000086],
              [104.87447446600005, 19.950864500000073],
              [104.87738560600005, 19.951828841000108],
              [104.88010465500005, 19.952605834000089],
              [104.88433395000004, 19.953496521000069],
              [104.88588782000009, 19.953933875000104],
              [104.88777207900003, 19.955068092000069],
              [104.88982666800003, 19.956227773000101],
              [104.89190456900009, 19.957456983000021],
              [104.89388350100005, 19.958777058000088],
              [104.89672055600005, 19.961355159000057],
              [104.8975306250001, 19.962566381000087],
              [104.89804955800005, 19.963681196000046],
              [104.89924322700006, 19.965313135000073],
              [104.90197926600004, 19.968120449000089],
              [104.90355513000003, 19.9702653310001],
              [104.90527297600006, 19.97268906500009],
              [104.906843823, 19.975156845000086],
              [104.90783497000004, 19.976748321000045],
              [104.90869933900005, 19.978136201000041],
              [104.90896798400007, 19.979662733000048],
              [104.908947638, 19.980954475000075],
              [104.90872058100004, 19.982981616000117],
              [104.90861726200011, 19.984895121000079],
              [104.90883132800005, 19.9867900200001],
              [104.90923685500003, 19.988918326000068],
              [104.90966201700004, 19.989801037000099],
              [104.91023712100011, 19.990455132000037],
              [104.911449932, 19.99088748800002],
              [104.91285784200005, 19.991322571000048],
              [104.91357856300009, 19.992024849000067],
              [104.915010592, 19.99402913200008],
              [104.91509291500006, 19.994999293000056],
              [104.91498085600003, 19.995920576000081],
              [104.91447787400007, 19.996882512000091],
              [104.91779149000006, 19.998374510000083],
              [104.91857524700006, 19.998598256000029],
              [104.919024183, 19.99865774000007],
              [104.91948063100001, 19.998238650000118],
              [104.92086915700006, 19.995758359000071],
              [104.92152095100008, 19.993640027000126],
              [104.92210984000005, 19.991946304000052],
              [104.92296230300005, 19.991373190000061],
              [104.92606768900005, 19.990565697000036],
              [104.93395414100003, 19.989665407000068],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 41, code: "AD01", ten_tinh: "Ninh Bình" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.79825202700005, 20.439534291000072],
              [105.80128981800004, 20.435797370000053],
              [105.80592865000008, 20.431287586000032],
              [105.8094047860001, 20.428331139000107],
              [105.81634909600002, 20.423101638000048],
              [105.8224492360001, 20.418737230000069],
              [105.82652720000009, 20.417627025000051],
              [105.82907006600007, 20.417460365000025],
              [105.8321210660001, 20.417298670000058],
              [105.83364125300002, 20.417697129000075],
              [105.83536047300007, 20.41848103200001],
              [105.83747880900002, 20.419939957000018],
              [105.8390974860001, 20.420626973000111],
              [105.84193575300002, 20.421325923000033],
              [105.84266031500002, 20.421418574000057],
              [105.84437173900004, 20.421637402000044],
              [105.8482358470001, 20.4214834510001],
              [105.85189983400004, 20.421039852000078],
              [105.85423735100009, 20.42106264200001],
              [105.85717836300007, 20.421666519000055],
              [105.85960912600011, 20.422457149000103],
              [105.86167739600009, 20.419217534000019],
              [105.86674192700008, 20.411500805000045],
              [105.86787339100009, 20.410265382000055],
              [105.86971204200007, 20.409420307000033],
              [105.87195822600003, 20.40848326600004],
              [105.87308443100004, 20.40772714300001],
              [105.87390680300005, 20.406872201000056],
              [105.87814613700007, 20.400201760000058],
              [105.88514852900001, 20.391736049000109],
              [105.88621644600008, 20.390344482000074],
              [105.88734678, 20.389404176000077],
              [105.89004519600006, 20.388478906000081],
              [105.89128408700007, 20.387856680000112],
              [105.89264066800004, 20.386707148000127],
              [105.89332976300004, 20.385128494000071],
              [105.89414562400005, 20.382177145000036],
              [105.894951233, 20.380176851000087],
              [105.89631569000001, 20.378287573000087],
              [105.89835324400011, 20.376299025000044],
              [105.9001565670001, 20.375259377000084],
              [105.90409122600005, 20.373922965000091],
              [105.90611170100007, 20.373519450000096],
              [105.90700767700004, 20.37352797000009],
              [105.90834261400001, 20.374386142000041],
              [105.91033940500002, 20.376201754000078],
              [105.9123477200001, 20.377735899000093],
              [105.91493243000008, 20.372876862000041],
              [105.91626877200008, 20.370014595],
              [105.91805122200003, 20.366129765],
              [105.92002938500011, 20.36430028300007],
              [105.92264073900007, 20.364324943000064],
              [105.92458837500001, 20.36537008800012],
              [105.92934330000007, 20.368495153000055],
              [105.93086012000006, 20.369125472000071],
              [105.93238344600007, 20.369139786000098],
              [105.9333293270001, 20.368352498000064],
              [105.93457912900003, 20.367312226000053],
              [105.93483356800003, 20.363823619000065],
              [105.93487039000006, 20.360332944000078],
              [105.93576679900006, 20.357877099000099],
              [105.93752063100004, 20.356661405000054],
              [105.94405307900008, 20.356311809000083],
              [105.94884037400007, 20.356356473000048],
              [105.94993053700003, 20.356161270000058],
              [105.95124690500005, 20.355146749000049],
              [105.95170145200009, 20.353302771000067],
              [105.95087832600011, 20.34877726100008],
              [105.94811844500002, 20.342180070000012],
              [105.94295457100006, 20.33658713100008],
              [105.93776720300011, 20.333252794000067],
              [105.93642666800004, 20.332148090000054],
              [105.93587073300004, 20.331123057000063],
              [105.93561955900005, 20.330562944000086],
              [105.93541714100009, 20.32912349500009],
              [105.93562771900011, 20.327677691000098],
              [105.9369125650001, 20.327246680000052],
              [105.94627920400006, 20.323284642000061],
              [105.95776022400003, 20.317999451000013],
              [105.96141825100008, 20.315827533000075],
              [105.96717876200003, 20.311224021000029],
              [105.97583549100005, 20.302725430000109],
              [105.97978046100006, 20.297859701000043],
              [105.98305080300003, 20.292685646000038],
              [105.98396204700001, 20.290186180000092],
              [105.98453975400011, 20.285677383000063],
              [105.98422000100004, 20.282330659000088],
              [105.98199722700006, 20.274619504000043],
              [105.98052245800008, 20.271104336000079],
              [105.98009600300006, 20.270087865000086],
              [105.97941532600005, 20.267406527],
              [105.97943257000006, 20.26573475100006],
              [105.98057077000009, 20.264319965000062],
              [105.98423591400002, 20.261544252000085],
              [105.99039455200004, 20.257386592000067],
              [105.99538003599999, 20.254778805000036],
              [106.00151593800008, 20.252805657000025],
              [106.00429666600003, 20.252343223000047],
              [106.00863542500011, 20.251621616000115],
              [106.01707067600003, 20.251073514000062],
              [106.02450638200001, 20.251296499000127],
              [106.02780399000001, 20.252106474000087],
              [106.032907112, 20.254181149000061],
              [106.03437821800004, 20.254707910000036],
              [106.03486780300005, 20.254925168000071],
              [106.03764260700005, 20.256186389000064],
              [106.039382141, 20.256449192000048],
              [106.04103704800006, 20.256463936000024],
              [106.04287357200005, 20.255738410000035],
              [106.04541444800006, 20.253393892000112],
              [106.04675117700008, 20.251239227],
              [106.0474717810001, 20.248855142000096],
              [106.04775853000002, 20.246302328000084],
              [106.04760077100012, 20.244652315000074],
              [106.04691632800007, 20.24340977000012],
              [106.04518595700006, 20.242240353000085],
              [106.04318525399999, 20.24197527600009],
              [106.04004822600005, 20.242112205000048],
              [106.03647489700006, 20.242327631000109],
              [106.03450166600004, 20.24240333900007],
              [106.03225481600003, 20.242322897000072],
              [106.03080366700004, 20.241886991000065],
              [106.02924376800004, 20.241132911000115],
              [106.02835878200008, 20.24006767200008],
              [106.02792160800007, 20.239006452000027],
              [106.02781816600003, 20.238159682000045],
              [106.02782561500003, 20.237419628000097],
              [106.02841623000005, 20.234358735000058],
              [106.02944609600007, 20.232147605000073],
              [106.03182461500006, 20.229419878000066],
              [106.03466098200008, 20.226520998000105],
              [106.03735412000007, 20.22411790300005],
              [106.03963814400005, 20.222159869000045],
              [106.04209217600005, 20.220615490000071],
              [106.04515493800008, 20.21915892100008],
              [106.04812400100006, 20.218360952000047],
              [106.05091395100007, 20.218055963000033],
              [106.05317647600005, 20.218240870000059],
              [106.05612995700008, 20.219008909000102],
              [106.05976952200004, 20.220854596000045],
              [106.06786114500002, 20.226077923000041],
              [106.0705413590001, 20.228079844000035],
              [106.07330142400012, 20.230824327000093],
              [106.077690406, 20.236220826000114],
              [106.07880320600006, 20.238208911000029],
              [106.07998372900003, 20.242175936000081],
              [106.08098848900003, 20.246306250000053],
              [106.08225297800004, 20.250603668000089],
              [106.08340582400004, 20.252962972000077],
              [106.08547767500004, 20.254876905000074],
              [106.08764390100004, 20.256049762000124],
              [106.08999159200005, 20.256482308000031],
              [106.09182311800006, 20.25625091200007],
              [106.09339974900004, 20.255357862000075],
              [106.09428037200007, 20.254376338000043],
              [106.0955312510001, 20.251746021000102],
              [106.09543400300004, 20.251271683000112],
              [106.09534706700005, 20.25016105600006],
              [106.09521998300001, 20.247903462000046],
              [106.09693174900006, 20.240443600000013],
              [106.10033573800007, 20.227497919000115],
              [106.10217290000003, 20.222436497000018],
              [106.10399086400008, 20.219349384000026],
              [106.10549306400003, 20.218092994000081],
              [106.10800836800004, 20.217850360000078],
              [106.10827791900005, 20.217824355000054],
              [106.11323386200003, 20.218864729000074],
              [106.12423232300003, 20.221779544000107],
              [106.13566603300005, 20.22596679800008],
              [106.14242071700001, 20.228633100000067],
              [106.14435639000003, 20.228790476000121],
              [106.14629339000007, 20.228806810000052],
              [106.14779006000001, 20.228114244000039],
              [106.14914305300005, 20.226856326000053],
              [106.15020734200007, 20.22460871800007],
              [106.15100290000001, 20.21925604100003],
              [106.15254734600006, 20.213486510000052],
              [106.15607228200005, 20.20307923700004],
              [106.15839446900007, 20.193790027000077],
              [106.15924627500003, 20.190341641000131],
              [106.16002820900007, 20.186399001000055],
              [106.16038968400005, 20.179631939000053],
              [106.16066172600009, 20.166516983000093],
              [106.161309132, 20.161021562000059],
              [106.16303612600008, 20.151585738000044],
              [106.16458760600001, 20.14496933700007],
              [106.16605725700002, 20.1391279730001],
              [106.16624445100005, 20.135039008],
              [106.1659808950001, 20.131369430000042],
              [106.16466299700004, 20.128960515000038],
              [106.16186252400004, 20.125833923000066],
              [106.16014853000007, 20.124643177000046],
              [106.15816331100004, 20.123263965000056],
              [106.15673112800002, 20.121498627],
              [106.15551326300007, 20.119997462000057],
              [106.15243915400001, 20.114329391000041],
              [106.1468884120001, 20.102856956],
              [106.14391129200006, 20.094861950000094],
              [106.14320419700009, 20.090906260000096],
              [106.14271921700001, 20.083243655000068],
              [106.14242705400008, 20.07862724800011],
              [106.14216402000008, 20.074957350000133],
              [106.14072064700012, 20.070148940000095],
              [106.13897290000001, 20.06604322500003],
              [106.13617640600009, 20.062633926000025],
              [106.13240630800009, 20.059851097000077],
              [106.12973737200005, 20.058699856000125],
              [106.12737198800001, 20.057997411000095],
              [106.12504783700008, 20.057307175000091],
              [106.12142355900006, 20.056230765000024],
              [106.11801580400001, 20.054508780000013],
              [106.11594926700009, 20.05265716600011],
              [106.11359453400003, 20.049815581000097],
              [106.11243212300005, 20.046843128000091],
              [106.11161634800004, 20.038795084000057],
              [106.1111199800001, 20.034456164000055],
              [106.11103672200005, 20.033730726000073],
              [106.11036938299999, 20.028456316000117],
              [106.11012510400012, 20.024636186000052],
              [106.10968406900004, 20.021791060000012],
              [106.10869601900001, 20.018439967000035],
              [106.10685564800005, 20.012632727000046],
              [106.10548636, 20.008311716000016],
              [106.10518585500009, 20.007363393000063],
              [106.10174101000008, 19.997924086000026],
              [106.09892235300001, 19.990168097],
              [106.09501506300002, 19.97829880900008],
              [106.093515299, 19.967646647000059],
              [106.09356709600003, 19.962314048000081],
              [106.09483891300002, 19.955468262000053],
              [106.09733058400012, 19.947109217000047],
              [106.0980978600001, 19.945090228000062],
              [106.09113008, 19.942885296000057],
              [106.09083540300006, 19.940897706],
              [106.09003548600003, 19.938949555000107],
              [106.08896012900006, 19.937257832000022],
              [106.08680948200001, 19.933874349000021],
              [106.08479536699998, 19.930492020000123],
              [106.0834636110001, 19.927115617000091],
              [106.08293168700006, 19.925687409000133],
              [106.08131696300003, 19.923343825000117],
              [106.07996127000004, 19.922426059000117],
              [106.0758828630001, 19.920837358000121],
              [106.07359557700003, 19.919854739000087],
              [106.0716790490001, 19.919577894],
              [106.06771018700006, 19.918892947000089],
              [106.06428966700003, 19.918212754000116],
              [106.06360300900005, 19.918336710000069],
              [106.06332628200008, 19.918594284000065],
              [106.06345567000002, 19.919375483000046],
              [106.06371828500008, 19.920547883000062],
              [106.06329935300006, 19.921324241000079],
              [106.06233211200006, 19.922095759000058],
              [106.05612621800003, 19.925811133000067],
              [106.05433126300002, 19.927095315000095],
              [106.05418774300006, 19.927744080000039],
              [106.05431837900002, 19.928395284000096],
              [106.05471932900006, 19.929438897000026],
              [106.05456935800001, 19.930737647000015],
              [106.0534456120001, 19.933457828000108],
              [106.052042489, 19.936695552000039],
              [106.05066617000008, 19.940681033000104],
              [106.04939617300008, 19.944309900000064],
              [106.04945826400007, 19.944960474000069],
              [106.04959147900007, 19.945351673000019],
              [106.05054340300005, 19.94614015800007],
              [106.05136145900006, 19.946602444000035],
              [106.051357588, 19.946992424000065],
              [106.05094178700006, 19.947443746000104],
              [106.03733357000006, 19.959652554000051],
              [106.03641988800004, 19.960220838000083],
              [106.03573084700005, 19.960574965000077],
              [106.03504469000003, 19.960640879000024],
              [106.03390301000007, 19.960558604000056],
              [106.03245596700008, 19.960545640000049],
              [106.03176907600007, 19.960683592000095],
              [106.03008271300008, 19.961749329000078],
              [106.02716181500008, 19.964389206000099],
              [106.02538690900005, 19.966679068000111],
              [106.02476456300006, 19.96797048300003],
              [106.02444468000006, 19.969480787000023],
              [106.02405442900002, 19.970414007000059],
              [106.02366853800007, 19.970914927000116],
              [106.02318011400006, 19.970910530000104],
              [106.02305955700004, 19.982749829000035],
              [106.02302963100004, 19.985720544000074],
              [106.02361143200004, 19.988163560000061],
              [106.0238750760001, 19.989270582000081],
              [106.02537512400005, 19.993451611000076],
              [106.02863548400006, 19.997856788000057],
              [106.03169240900004, 20.000593081000062],
              [106.03507344800002, 20.00203435500012],
              [106.03690542900007, 20.002459485000124],
              [106.03762549800005, 20.002465921000031],
              [106.04393910600001, 20.002522247000073],
              [106.04678272300002, 20.003319793000074],
              [106.05022777200006, 20.005087952000089],
              [106.05304464600006, 20.008588010000047],
              [106.05704543100006, 20.015959634000069],
              [106.05928369300007, 20.019942571000101],
              [106.06142719000006, 20.026037243000069],
              [106.06340120400006, 20.032425422000074],
              [106.06388817500002, 20.037149181000032],
              [106.06743003300006, 20.045256587],
              [106.07244216599999, 20.056151184000015],
              [106.07331369000008, 20.063435004000048],
              [106.07153602100007, 20.066100087000024],
              [106.069906847, 20.067362285000101],
              [106.06801952400008, 20.06747335000005],
              [106.0568505090001, 20.06609833400006],
              [106.0456854550001, 20.064339839000041],
              [106.04245608000005, 20.063928127000082],
              [106.03631141600005, 20.061815882000111],
              [106.03334465900006, 20.061045805000077],
              [106.02752710700003, 20.060844938000066],
              [106.02485329500003, 20.060872503000034],
              [106.02281747000008, 20.060893469000085],
              [106.01981931400007, 20.060924311000058],
              [106.01651059700005, 20.06148929200009],
              [106.01508763000008, 20.062219979000062],
              [106.01381891300009, 20.063249474000067],
              [106.01207081800004, 20.065018151000032],
              [106.01095934200001, 20.066049040000067],
              [106.009064494, 20.066775412000126],
              [105.99000210900007, 20.069724687000075],
              [105.98732533300003, 20.069997593000011],
              [105.98386858400001, 20.069975125000013],
              [105.97806246300011, 20.069136437000076],
              [105.97055686000002, 20.067666720000062],
              [105.96894349300003, 20.067350760000032],
              [105.96465753700005, 20.06678753700006],
              [105.96008657300004, 20.067006918000097],
              [105.9589764020001, 20.067258392000028],
              [105.95339133500002, 20.07178755900005],
              [105.94221081600007, 20.081761513000096],
              [105.93774352300002, 20.085253574000106],
              [105.93244538900005, 20.088737722000083],
              [105.93017705100004, 20.090488671000095],
              [105.92909503500005, 20.091323888000097],
              [105.92783240300011, 20.092882610000103],
              [105.92549883200003, 20.097507001000011],
              [105.92324201000008, 20.101412205000102],
              [105.92169681100005, 20.103491735000119],
              [105.91992016900004, 20.104961624000062],
              [105.91807192100006, 20.105813327000099],
              [105.91488040700004, 20.10643748500005],
              [105.91016721799998, 20.106916246000047],
              [105.90739384300011, 20.107282500000068],
              [105.90558408500003, 20.108181437000013],
              [105.89747902700007, 20.115040800000102],
              [105.89440328500005, 20.117759863000103],
              [105.89203527200004, 20.119045968000115],
              [105.88870077700003, 20.120061007000075],
              [105.87565071200001, 20.123076385000083],
              [105.8724304720001, 20.124047144000109],
              [105.86847229700008, 20.125781631000095],
              [105.86506904200004, 20.128230570000127],
              [105.85853844100006, 20.133197290000027],
              [105.85759274400007, 20.133821828000094],
              [105.85466925000003, 20.135752450000055],
              [105.84711163400007, 20.140642424000085],
              [105.84522606800003, 20.141510349000043],
              [105.84221498000002, 20.142367210000067],
              [105.83826594100005, 20.143214807000028],
              [105.83544433900008, 20.143896161],
              [105.8331795990001, 20.145114805000027],
              [105.83184688800006, 20.146874455000052],
              [105.83069389800005, 20.149344982000095],
              [105.82993176200006, 20.150401134000084],
              [105.82860880300009, 20.151274459000042],
              [105.82747539100002, 20.151972366000059],
              [105.81994012600006, 20.154734208000043],
              [105.81296546700003, 20.157678634000042],
              [105.80182759300004, 20.163772203000093],
              [105.79521160500011, 20.168137821000087],
              [105.79374711600008, 20.169172805000102],
              [105.79199630699999, 20.170410109000066],
              [105.79077184400002, 20.170840995000013],
              [105.78851651400002, 20.171172868000028],
              [105.78025158900007, 20.171975941000127],
              [105.77667087900001, 20.173357941000077],
              [105.77534954000002, 20.174053669000088],
              [105.77364289600003, 20.175631849000105],
              [105.77212787100009, 20.176857409000078],
              [105.77062089100011, 20.177373947000099],
              [105.75820979800002, 20.179729554000048],
              [105.75218566100003, 20.181440760000058],
              [105.74446058100008, 20.184197983000018],
              [105.74012889800005, 20.185571664000079],
              [105.73899292700003, 20.186446345000064],
              [105.73916010600001, 20.188220762000064],
              [105.73951900500003, 20.18964260500011],
              [105.73893976100007, 20.191054809000072],
              [105.73817083200004, 20.192642313000064],
              [105.73551737200006, 20.194919495000093],
              [105.71849832300009, 20.206088646000083],
              [105.707717621, 20.213244381],
              [105.70147733400007, 20.217256305000127],
              [105.69401597000009, 20.221343866000026],
              [105.69212477500011, 20.222564861000066],
              [105.69155553300008, 20.223090669000051],
              [105.69155135600009, 20.223445150000074],
              [105.69226859900002, 20.226288858000082],
              [105.69243956300004, 20.227708733000057],
              [105.692416603, 20.229658357000076],
              [105.69221849900005, 20.230542576000055],
              [105.69126763300007, 20.231596150000094],
              [105.68485341300004, 20.234364872000029],
              [105.68258459500007, 20.235759044000098],
              [105.68011338100007, 20.238391871000069],
              [105.67839910900007, 20.24050087300013],
              [105.67742501000002, 20.243503956],
              [105.67720791300009, 20.245983253000063],
              [105.67681573600007, 20.247397154000076],
              [105.67549354300009, 20.248092210000031],
              [105.67436323300009, 20.248434775000042],
              [105.67116839100007, 20.248755484000114],
              [105.66909964700005, 20.249088083000096],
              [105.66853237500001, 20.249436574000065],
              [105.66777108600009, 20.250314787000079],
              [105.66719536600006, 20.251372206000084],
              [105.66696968800004, 20.254560395000084],
              [105.66638761800002, 20.256149506000028],
              [105.66409728400005, 20.259315748000084],
              [105.65308786900005, 20.269479162000081],
              [105.64871749500001, 20.273863735000042],
              [105.64616533300006, 20.275343059000051],
              [105.64257752400002, 20.277077128000087],
              [105.63485997000006, 20.278766785000087],
              [105.62092403600005, 20.282338720000048],
              [105.61884838800007, 20.283202510000109],
              [105.61714600099999, 20.284247556000068],
              [105.61524940400001, 20.285822229000061],
              [105.61029951200008, 20.291440386000126],
              [105.60952265700011, 20.293558895000082],
              [105.60833786700005, 20.298331607000094],
              [105.60660930300003, 20.301503191000059],
              [105.60107167000004, 20.30906428400003],
              [105.59878400700003, 20.31187518200008],
              [105.59612948600005, 20.313973078000025],
              [105.59424114000004, 20.314838638000055],
              [105.59198817800005, 20.314813996000055],
              [105.59030063800004, 20.314618278000026],
              [105.58431461000001, 20.312780249000092],
              [105.58112732700008, 20.312390754000106],
              [105.57905994800009, 20.312545264000015],
              [105.57641617300001, 20.313756842000075],
              [105.56938980400005, 20.318447885000076],
              [105.56837647400005, 20.31854223000007],
              [105.56762547300008, 20.318533930000093],
              [105.56631783000006, 20.317987749000018],
              [105.56389251800003, 20.316720239000041],
              [105.56314152500008, 20.31671192400006],
              [105.56238391400009, 20.317235257000021],
              [105.56142751500001, 20.318642569000112],
              [105.56195105100007, 20.321838666000055],
              [105.56230229000009, 20.323792166000132],
              [105.56247239800004, 20.325211955],
              [105.56170592500004, 20.326444123000087],
              [105.55773207200005, 20.328881371000037],
              [105.55489575800001, 20.330444994000096],
              [105.55093060500003, 20.332173268000098],
              [105.54922521100006, 20.333394932000104],
              [105.54846084200005, 20.334449824000075],
              [105.54786647700008, 20.336924488000108],
              [105.54779767600007, 20.342417963000067],
              [105.54812661900012, 20.346143511000037],
              [105.547927746, 20.347027453000074],
              [105.54411218600005, 20.35177015900009],
              [105.54257656800009, 20.354411470000052],
              [105.54157534100003, 20.359362711000095],
              [105.54113842700011, 20.363458801000078],
              [105.54540218200003, 20.362375377000049],
              [105.55236230000001, 20.361028578000081],
              [105.55781135600003, 20.359807230000101],
              [105.56083149200008, 20.359698294000061],
              [105.56414814200005, 20.360019878000024],
              [105.56700850600001, 20.360621233000039],
              [105.5700109630001, 20.361936270000044],
              [105.57315202700002, 20.364249793000063],
              [105.57717227100004, 20.368709375000016],
              [105.57881835100008, 20.36986691100013],
              [105.57972215000008, 20.370019280000037],
              [105.58047678, 20.370027576000012],
              [105.58699117300004, 20.368105084000021],
              [105.59729773700008, 20.364657107000106],
              [105.6020094620001, 20.362002335000092],
              [105.61298162600005, 20.353433342000038],
              [105.61632470000008, 20.350879040000081],
              [105.61806137500005, 20.349772041000058],
              [105.61965789000008, 20.349288954000052],
              [105.62085223100004, 20.349176775000011],
              [105.62270468800003, 20.349446966000073],
              [105.62376498100009, 20.34945841600004],
              [105.62615972500008, 20.348733722000119],
              [105.63070074300002, 20.345905581000068],
              [105.64088030500002, 20.337133461000079],
              [105.64692070800002, 20.330818415000081],
              [105.65120647300007, 20.32711140400005],
              [105.65549056800006, 20.323529360000059],
              [105.6591074450001, 20.320315411000053],
              [105.6654162450001, 20.313627287000102],
              [105.6683627840001, 20.311031506000084],
              [105.67129883300002, 20.30931125100004],
              [105.67383136500004, 20.308087075000088],
              [105.67729141899999, 20.306872651000063],
              [105.68074105200003, 20.306533749],
              [105.68286117000004, 20.306556085000068],
              [105.6849798100001, 20.306703485000099],
              [105.68599261700005, 20.307059850000094],
              [105.68755184300002, 20.308155548000073],
              [105.69235345200011, 20.313062828000028],
              [105.69601674800008, 20.317553309000047],
              [105.69981997200007, 20.32231498400003],
              [105.70123805900008, 20.323274178000084],
              [105.71419006200006, 20.327996031000062],
              [105.72102360800005, 20.33036036100011],
              [105.722584785, 20.331320886000093],
              [105.72370779400009, 20.333086313000059],
              [105.72581721000002, 20.336076101000074],
              [105.72665441300009, 20.337838547000061],
              [105.72703790500003, 20.33996099200008],
              [105.72300698000002, 20.343290258000124],
              [105.71950095000001, 20.347173100000049],
              [105.71581965600006, 20.352233565000063],
              [105.71221961000001, 20.358201655000038],
              [105.71056135900002, 20.361885744000041],
              [105.708935873, 20.365456817000016],
              [105.70559328100009, 20.370156567000031],
              [105.70323257299999, 20.374830829000068],
              [105.70252734000009, 20.376337178000092],
              [105.70252226500003, 20.376769617000079],
              [105.70286432400007, 20.378116250000033],
              [105.70092724200003, 20.37987731900008],
              [105.69460473400004, 20.385943410000081],
              [105.69182180100009, 20.390081110000047],
              [105.69161923800002, 20.390907680000048],
              [105.69145313000004, 20.391585486000082],
              [105.69228287100005, 20.393195556000073],
              [105.69333185100007, 20.394207407000039],
              [105.69411784000008, 20.395274116000017],
              [105.69471780800006, 20.397239860000035],
              [105.69448704700008, 20.399196889000045],
              [105.693210924, 20.401730799000092],
              [105.68364478300003, 20.410408738000015],
              [105.68425383100008, 20.411302380000059],
              [105.6856066380001, 20.411700777000071],
              [105.68794250800001, 20.412047213000051],
              [105.69211140100006, 20.412665443000044],
              [105.69441741200009, 20.412817655000111],
              [105.70337905000012, 20.412655199000085],
              [105.70487666200005, 20.412286641000051],
              [105.7061042870001, 20.411787206000028],
              [105.70979614300003, 20.409520613000076],
              [105.712379587, 20.409163304000089],
              [105.71441560700006, 20.409184448000062],
              [105.71631887200007, 20.408948076000044],
              [105.717820911, 20.408195310000018],
              [105.72083985500004, 20.405409315000078],
              [105.72454034300006, 20.40237420000004],
              [105.72628501300008, 20.401235133000029],
              [105.72848107900006, 20.400483991000051],
              [105.72944241900004, 20.400106998000034],
              [105.72972477800003, 20.399336099000017],
              [105.72908620200005, 20.395460450000066],
              [105.72835886200008, 20.387456800000066],
              [105.72839534300007, 20.386634250000043],
              [105.7311360250001, 20.386282818000012],
              [105.74001322600007, 20.384247865000034],
              [105.74138836, 20.383654472000053],
              [105.74268388600007, 20.382984317000115],
              [105.74349653500008, 20.382309195000033],
              [105.74374672100008, 20.381552377000034],
              [105.74370650700004, 20.378058786000075],
              [105.74327443000007, 20.373649894000053],
              [105.74320620500004, 20.372586041000048],
              [105.7431406150001, 20.371294398000117],
              [105.74339691600008, 20.370006053000097],
              [105.74422963300006, 20.367584504000021],
              [105.74447453900008, 20.367283249000053],
              [105.75468521400008, 20.368185010000062],
              [105.75510752100011, 20.373467121000118],
              [105.75565429900006, 20.374915537000057],
              [105.75699277900004, 20.377511111000054],
              [105.7574634480001, 20.378579042000041],
              [105.75745735900003, 20.379110552000057],
              [105.75752913400001, 20.379870676000067],
              [105.75718286900005, 20.381993421000018],
              [105.75585955200008, 20.38509340800001],
              [105.75519047200005, 20.387288779000109],
              [105.75481542700001, 20.391917146000083],
              [105.75473277100011, 20.395119015000063],
              [105.75469139800006, 20.395712740000072],
              [105.75455630400006, 20.396964323000063],
              [105.75412338700005, 20.399617683000045],
              [105.75344988900009, 20.402192642000024],
              [105.75270634200004, 20.403855649000043],
              [105.750987342, 20.406343978000052],
              [105.75041345700004, 20.407249347000089],
              [105.75024462100001, 20.407931041],
              [105.75061216100005, 20.410972208000096],
              [105.75090706800002, 20.413329208000071],
              [105.75130341000005, 20.413864801000067],
              [105.75194209900005, 20.414326933000027],
              [105.75563419800008, 20.415275808000018],
              [105.75683808300003, 20.415591819000056],
              [105.75751573000007, 20.41616823100005],
              [105.75755119000007, 20.416586235000089],
              [105.75722139300004, 20.417266285000046],
              [105.75579929600006, 20.418428775000038],
              [105.755065288, 20.419256566000051],
              [105.75456491400007, 20.420770152000053],
              [105.75170603100005, 20.431371672000061],
              [105.75001663900007, 20.438264272000062],
              [105.74978128900004, 20.439551500000071],
              [105.74977462500003, 20.440131007000062],
              [105.75015199400008, 20.440714445000104],
              [105.75213899300007, 20.441604100000085],
              [105.75619981300008, 20.442514873000036],
              [105.75704469500008, 20.442523479000037],
              [105.75812166000001, 20.442389554000059],
              [105.75912763300002, 20.44174777000007],
              [105.76005429500005, 20.441322514000056],
              [105.76067123900003, 20.44111144700009],
              [105.76335452400008, 20.441573396000123],
              [105.76932650800003, 20.443300249000096],
              [105.77047862600006, 20.443311915000081],
              [105.77109474000005, 20.443173252000065],
              [105.77194622400006, 20.442602296000075],
              [105.77335102800004, 20.440660427000076],
              [105.77483342400004, 20.438646887000097],
              [105.77568818200007, 20.437786145000111],
              [105.77615313200008, 20.437428606000104],
              [105.77677086000003, 20.437145060000091],
              [105.77784532300011, 20.437228345000058],
              [105.77937732300003, 20.437606041000045],
              [105.78021889800003, 20.437904317000033],
              [105.780674812, 20.438343596000053],
              [105.78086246900008, 20.438653050000077],
              [105.78195670100011, 20.440457484000071],
              [105.78361198800012, 20.443516926000079],
              [105.78608764800005, 20.448757986000118],
              [105.78622815400004, 20.449918538000041],
              [105.78618061600011, 20.454119928000033],
              [105.78632932300006, 20.454556096000061],
              [105.78663329400007, 20.454848936000047],
              [105.78701489800002, 20.455070107000047],
              [105.78946799900005, 20.455529413000036],
              [105.78992887500009, 20.455534038000067],
              [105.79031375700004, 20.455465460000077],
              [105.79070355600005, 20.454962249000097],
              [105.79177079500008, 20.448887489000086],
              [105.79216792400007, 20.447732328000079],
              [105.79388550800007, 20.445286352000025],
              [105.79825202700005, 20.439534291000072],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 42, code: "AD01", ten_tinh: "Ninh Thuận" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.14636621300008, 11.579568401000094],
              [109.14518861600007, 11.579213719000057],
              [109.14372276800003, 11.579871640000048],
              [109.14218827300007, 11.581396541000098],
              [109.14087619700004, 11.58328131200008],
              [109.14065819200005, 11.583715794000058],
              [109.14058792900009, 11.584293913000108],
              [109.14088465000003, 11.584797835000058],
              [109.14184064200001, 11.584937090000034],
              [109.14323627700001, 11.584857298000093],
              [109.14499768200008, 11.584486634000067],
              [109.14572596800004, 11.583327195000058],
              [109.14645182000005, 11.581734451000079],
              [109.14666536900005, 11.5805055970001],
              [109.14636621300008, 11.579568401000094],
            ],
          ],
          [
            [
              [109.17500851500003, 11.648956113000073],
              [109.17374622300012, 11.648491640000101],
              [109.17308711000004, 11.648613186000121],
              [109.17297154100007, 11.649380040000066],
              [109.1729772840001, 11.650381966000099],
              [109.17364519200002, 11.651792785000035],
              [109.17467062200005, 11.652789029000042],
              [109.17629385800007, 11.653428299],
              [109.17719630300003, 11.653894772000049],
              [109.177916087, 11.653890747000071],
              [109.17827292500003, 11.65335829400007],
              [109.17808822400011, 11.652534182000094],
              [109.17760158800009, 11.651358118000042],
              [109.17651379300005, 11.649949667000035],
              [109.17500851500003, 11.648956113000073],
            ],
          ],
          [
            [
              [108.787913788, 12.155439504000041],
              [108.78746216200003, 12.151036221000043],
              [108.78614404200003, 12.147346668000035],
              [108.7850778320001, 12.14184135600007],
              [108.78496156300007, 12.141241007000046],
              [108.78334237400003, 12.134568635000063],
              [108.78245631400009, 12.130167055000117],
              [108.78153250200003, 12.1248446760001],
              [108.78500925900002, 12.119749987000093],
              [108.79040243600012, 12.111255400000063],
              [108.79242403000006, 12.101852325],
              [108.79240259300006, 12.096325691000052],
              [108.79275745200002, 12.091350347000061],
              [108.79451927200004, 12.087382955000068],
              [108.79824416900004, 12.083131812000135],
              [108.79972774500006, 12.079810222000019],
              [108.80026801, 12.074465810000044],
              [108.8017442610001, 12.06930209800001],
              [108.80508876800008, 12.063578629000025],
              [108.80714779099999, 12.061023583000095],
              [108.80214842500006, 12.05393479800003],
              [108.79821416300005, 12.047698210000044],
              [108.79733813700004, 12.045854498000084],
              [108.7999451420001, 12.045986706000098],
              [108.80443068200006, 12.045117174],
              [108.80862601100009, 12.044106572000056],
              [108.81209673000011, 12.04295661600012],
              [108.81585588300001, 12.041521321000024],
              [108.81822231500006, 12.040485515000031],
              [108.8178062640001, 12.039673969000118],
              [108.81816704400011, 12.036356759000098],
              [108.81994625200005, 12.032330960000069],
              [108.82005001700003, 12.032096176],
              [108.82020584300004, 12.031743575000052],
              [108.82564887600009, 12.028270026000063],
              [108.83258202200007, 12.024122571000094],
              [108.83431541400005, 12.023121193000106],
              [108.83691982500007, 12.022684643000046],
              [108.84473183500002, 12.021090665000054],
              [108.8525271190001, 12.015518384000055],
              [108.85381887400004, 12.012813757000094],
              [108.85511001100011, 12.009967030000011],
              [108.85553601700011, 12.007976280000053],
              [108.85595546900008, 12.00442275700002],
              [108.85593999800004, 12.000728949000104],
              [108.85520247200003, 11.99746429500008],
              [108.85388590100008, 11.994201987000073],
              [108.85242698400008, 11.991508554000061],
              [108.84719485600006, 11.986557196000101],
              [108.84530298000003, 11.984149608000026],
              [108.84611388800006, 11.970223684000112],
              [108.84711771700007, 11.967946594000088],
              [108.84826803200004, 11.966095100000105],
              [108.84996468900009, 11.964908],
              [108.85188433100002, 11.965512251000108],
              [108.85478340900009, 11.966494981000062],
              [108.85724886900007, 11.967621503000101],
              [108.85942545000003, 11.968891243000089],
              [108.86102007400008, 11.969453],
              [108.86246337000004, 11.968452635000036],
              [108.86477152100005, 11.966596319000098],
              [108.87400978000007, 11.960591455000012],
              [108.88771587800009, 11.95030554],
              [108.88930178200003, 11.948878226000124],
              [108.89190513000005, 11.9484410350001],
              [108.89639748200005, 11.949700556000041],
              [108.90031031800007, 11.95082039500006],
              [108.9120446470001, 11.953185051000046],
              [108.91421507000008, 11.953033586000037],
              [108.91667112900009, 11.952028473],
              [108.91825620600005, 11.950458867000078],
              [108.91868211100004, 11.948610186000062],
              [108.91910545400006, 11.946193262000103],
              [108.92039844900002, 11.944056677000068],
              [108.92284860700006, 11.941772963000028],
              [108.92602491900007, 11.940054291000111],
              [108.93238515700007, 11.938321509000092],
              [108.93556978700005, 11.938449455000109],
              [108.93846431900008, 11.938436590000022],
              [108.9417943390001, 11.938705857000096],
              [108.94411061900007, 11.938837561000069],
              [108.94627888300008, 11.938259596000121],
              [108.94786428500007, 11.936831853000028],
              [108.94988317800004, 11.935260094000098],
              [108.95204081200004, 11.93240913100002],
              [108.95462723100007, 11.928419738000072],
              [108.95707615400002, 11.925993610000061],
              [108.96285146900003, 11.923126120000036],
              [108.96732353600004, 11.920122395000108],
              [108.97281317800004, 11.918108311000084],
              [108.97700642400007, 11.917378629000018],
              [108.98076824300001, 11.917219101000052],
              [108.98467339300001, 11.916774717000054],
              [108.99960986700005, 11.923097078000032],
              [109.00125568400006, 11.923656597000052],
              [109.00269142300002, 11.924144683000025],
              [109.02269629600002, 11.912099608000036],
              [109.03125850900005, 11.910921269000061],
              [109.03726766300005, 11.908618530000046],
              [109.04141262700008, 11.904051589000096],
              [109.04509197200004, 11.899032262000018],
              [109.04760642499998, 11.892654763000097],
              [109.04941443900002, 11.884007599000055],
              [109.04990058400008, 11.882863420000065],
              [109.05193090200004, 11.878084784000096],
              [109.05397130100002, 11.869663886000035],
              [109.05540760800002, 11.865888465000028],
              [109.05648393300008, 11.863059189000056],
              [109.05969327300005, 11.856905635000102],
              [109.06405162500008, 11.849155103000085],
              [109.07025796400004, 11.840713256000067],
              [109.07555104500003, 11.834776336000095],
              [109.08153100600001, 11.827471975000046],
              [109.08659074700006, 11.821308758],
              [109.09096307500008, 11.816512828000024],
              [109.09488082300004, 11.813310301000078],
              [109.0978775240001, 11.811021711000079],
              [109.10203630300003, 11.809636206000016],
              [109.10781269200004, 11.807787516000088],
              [109.11637005600002, 11.806605866000018],
              [109.12562540500002, 11.806102032000075],
              [109.13511215300008, 11.805596545000084],
              [109.1464618740001, 11.807126185000081],
              [109.15712385400003, 11.809795507000052],
              [109.16593059600005, 11.811792657000096],
              [109.17450215000005, 11.813108859000108],
              [109.18237550300002, 11.81374673600004],
              [109.18677444000011, 11.813949304000102],
              [109.1907065190001, 11.813472520000088],
              [109.19324777600005, 11.812743931000023],
              [109.19336844100008, 11.812209872000066],
              [109.19282433600009, 11.811169429000044],
              [109.19195827600002, 11.811100380000104],
              [109.19043092800004, 11.81145587200003],
              [109.18717349800008, 11.811626654000039],
              [109.18599145700006, 11.811355198000049],
              [109.18508733300004, 11.810210304000089],
              [109.18433510800006, 11.80912362500006],
              [109.18384399100002, 11.80805649200005],
              [109.1835647660001, 11.806918076],
              [109.18349561600006, 11.805741105000054],
              [109.183540081, 11.804562094000024],
              [109.18361843700009, 11.803385465000103],
              [109.18368870600005, 11.802211471000044],
              [109.18376362700012, 11.801038185000044],
              [109.18385198100006, 11.799865713000111],
              [109.18396252500006, 11.798694222000082],
              [109.18410403000004, 11.797523798000052],
              [109.18428527000003, 11.796354591000034],
              [109.18453449400005, 11.795196742000067],
              [109.18501243800006, 11.794042068000097],
              [109.18556133200009, 11.793073192000046],
              [109.18622055300001, 11.792051829000092],
              [109.18689935700004, 11.791056535000076],
              [109.18760938100009, 11.790101772000037],
              [109.18836853900009, 11.789198577000091],
              [109.18919472300007, 11.788357990000085],
              [109.19010583600004, 11.787591022000042],
              [109.19111394300006, 11.786939476000045],
              [109.19217335200005, 11.786391756000024],
              [109.19320734800007, 11.785812328000043],
              [109.19417776299998, 11.785128557000045],
              [109.19513863800009, 11.784427759000058],
              [109.19609609100009, 11.783721058000117],
              [109.19705344000008, 11.78301487000004],
              [109.19801397400005, 11.782315656],
              [109.19901874700005, 11.781683842000044],
              [109.20663553000001, 11.775838739000031],
              [109.20769074300001, 11.775279867000064],
              [109.20864736700003, 11.77455774600009],
              [109.2094758850001, 11.773645025000066],
              [109.21012613000008, 11.772638403000089],
              [109.210644716, 11.771598030000073],
              [109.21236092700008, 11.767632833000084],
              [109.21271298100007, 11.766275014000092],
              [109.21288812700008, 11.765448745000116],
              [109.21282357399998, 11.764682806000069],
              [109.21093810400004, 11.763267900000061],
              [109.21010134800008, 11.762408835000125],
              [109.20931579000009, 11.761465447000051],
              [109.20966530900006, 11.760533046000086],
              [109.21077921900003, 11.759774866000059],
              [109.21181837300003, 11.759182882000106],
              [109.21285793600008, 11.758600602000067],
              [109.21389745200003, 11.758023701000029],
              [109.2149364630001, 11.75744786600008],
              [109.21597449400011, 11.756868784000039],
              [109.21701069500004, 11.756282649000036],
              [109.21804616200009, 11.755694458000042],
              [109.21916807900001, 11.755316872000064],
              [109.22040434300007, 11.755531937000095],
              [109.22133709200007, 11.756214563000087],
              [109.22207157600009, 11.757208802000017],
              [109.22312519800005, 11.75775237100005],
              [109.22436004600007, 11.757854907000096],
              [109.22540764500008, 11.7573225170001],
              [109.22612526200002, 11.75632789800005],
              [109.22624014800003, 11.755151496000058],
              [109.22591615100005, 11.754029848000068],
              [109.22507321500002, 11.753158327000078],
              [109.224012315, 11.752631040000059],
              [109.22291948600008, 11.752155479000058],
              [109.22182569900008, 11.751684805000105],
              [109.22073526900002, 11.751203310000056],
              [109.21965844300009, 11.750692139000039],
              [109.21921063800008, 11.749498680000057],
              [109.21993204200008, 11.74859486400006],
              [109.22093301900003, 11.747930876000099],
              [109.22203994500005, 11.747328547000089],
              [109.22314747900003, 11.746990401000089],
              [109.22423201600006, 11.747396805000053],
              [109.22520406800001, 11.748161141000056],
              [109.22601617000007, 11.749009316000068],
              [109.2271233590001, 11.749516267000091],
              [109.22834189700002, 11.749333334000054],
              [109.22907675000008, 11.74840796900005],
              [109.22975043100004, 11.742613984000092],
              [109.22993504600004, 11.741452785000115],
              [109.23004776800003, 11.74028336400008],
              [109.23014807500007, 11.739111412000101],
              [109.23023966400011, 11.737937567000055],
              [109.23032571500003, 11.736762461000081],
              [109.23040935600002, 11.735586683000053],
              [109.23049374800007, 11.73441085800002],
              [109.2305820280001, 11.733235581000121],
              [109.23067736600008, 11.732061468000053],
              [109.23080236200003, 11.730888668000093],
              [109.23105718100007, 11.729718016000044],
              [109.23088756400007, 11.728575174000076],
              [109.23048591700005, 11.727461606000043],
              [109.23008991200007, 11.726350957000074],
              [109.22958372600002, 11.725246362000073],
              [109.22888180700002, 11.72423810800005],
              [109.22796396600002, 11.723458158000087],
              [109.22685062800009, 11.722986645000065],
              [109.22570493600007, 11.722651706000063],
              [109.22455160900003, 11.722367544000088],
              [109.22339323800003, 11.722093151000122],
              [109.22222154100007, 11.721885441000078],
              [109.22106487400002, 11.721555599000054],
              [109.21436884700003, 11.720754720000047],
              [109.21256744600002, 11.720572122000037],
              [109.21164095200001, 11.720336364000071],
              [109.21078601400006, 11.719853012000046],
              [109.20992901700004, 11.719020904000031],
              [109.20917161100007, 11.717865690000016],
              [109.20828202300001, 11.717050749000089],
              [109.207162, 11.716557747000065],
              [109.20602179100004, 11.716247014000112],
              [109.20486776000004, 11.716011743000097],
              [109.20367540400005, 11.715959080000092],
              [109.20319097200003, 11.716304485000071],
              [109.20298497700004, 11.716927918000051],
              [109.20294409300003, 11.71811573800001],
              [109.2028676780001, 11.719113929000089],
              [109.20255767200004, 11.720389283000097],
              [109.20190126200008, 11.721354535000026],
              [109.19778821500003, 11.727558645000078],
              [109.19729375100006, 11.728025838000098],
              [109.196456901, 11.728223089000076],
              [109.19554678500002, 11.727437856000032],
              [109.19485493600007, 11.726333824000077],
              [109.19409152800004, 11.725291877000108],
              [109.19334198500012, 11.724257867000086],
              [109.19332074300004, 11.723805661000062],
              [109.19370867100008, 11.723043191000036],
              [109.19464831000002, 11.721985476],
              [109.19536190100008, 11.72064002600006],
              [109.19666781400005, 11.718399207000106],
              [109.19712528800007, 11.717481646000037],
              [109.19768881100006, 11.715766820000082],
              [109.19797022900008, 11.714502484000098],
              [109.19834696800007, 11.713574189000029],
              [109.19878377600006, 11.712359700000084],
              [109.19964870800004, 11.711233148000042],
              [109.20064753600008, 11.710446574000065],
              [109.20166764400003, 11.709584125000026],
              [109.20204647500002, 11.708995918000118],
              [109.20203725100005, 11.708374607000048],
              [109.20135893100006, 11.707233543000049],
              [109.2001936930001, 11.706574528000056],
              [109.19782392700004, 11.705036206000051],
              [109.19515795700009, 11.701777270000056],
              [109.18614279100007, 11.691692466000079],
              [109.1861507560001, 11.69049760700007],
              [109.18662796700006, 11.68928638900004],
              [109.18724289200004, 11.688134127000092],
              [109.18791026100011, 11.687032505000071],
              [109.18842732600008, 11.685757939000039],
              [109.18828213600005, 11.685138064000087],
              [109.18783852000004, 11.684701418000108],
              [109.18208388700005, 11.679818036000087],
              [109.18122723300004, 11.679232836000073],
              [109.17961149500012, 11.678932416000086],
              [109.17832325300002, 11.678889750000019],
              [109.17701873400009, 11.678872703000119],
              [109.17624096400004, 11.678864696000078],
              [109.17563576800006, 11.678593700000038],
              [109.17509857400002, 11.677497938000057],
              [109.17216201800005, 11.665503786000018],
              [109.172025026, 11.664226744000048],
              [109.17191289300004, 11.662946263000061],
              [109.17180029700006, 11.661664809000081],
              [109.1716902910001, 11.660383370000103],
              [109.17157768000001, 11.659103676000091],
              [109.17145235800004, 11.657827456000101],
              [109.17125521, 11.656554941000062],
              [109.17104798400007, 11.655283895000055],
              [109.169883563, 11.647073843000031],
              [109.16961676400004, 11.64581574600003],
              [109.16935531600008, 11.644556960000061],
              [109.16909501800004, 11.643297998000078],
              [109.1683090220001, 11.639521612000079],
              [109.168045729, 11.638263188000051],
              [109.16763188800005, 11.637049694000011],
              [109.16572797400008, 11.629641186000049],
              [109.16568531200009, 11.628356861],
              [109.16564433000008, 11.627072476000054],
              [109.16560598200002, 11.625788023],
              [109.16556843300002, 11.624503550000041],
              [109.16547112900005, 11.623224648000095],
              [109.1645647710001, 11.622590314000101],
              [109.16330023100002, 11.623279785000038],
              [109.162345805, 11.624163048000112],
              [109.16124310700008, 11.624888177000068],
              [109.16005022300004, 11.625431659000094],
              [109.15879737900003, 11.625657214000016],
              [109.15749531900005, 11.625540696000057],
              [109.15620190500005, 11.625186519000062],
              [109.15495683900006, 11.624723889000064],
              [109.153780684, 11.624169073000077],
              [109.15273070500008, 11.623400223000056],
              [109.15183856800007, 11.622425530000056],
              [109.15105406400008, 11.621410199000069],
              [109.150323149, 11.620357450000094],
              [109.14960314400003, 11.61927988400002],
              [109.14889782200002, 11.618203939000081],
              [109.14821171100002, 11.617120432000137],
              [109.14754853100003, 11.616018414000083],
              [109.14691202800003, 11.614886952000079],
              [109.14631884500007, 11.613719742000049],
              [109.14582186000007, 11.612521398000107],
              [109.14545786500005, 11.611288664000073],
              [109.14303025000001, 11.601451066000033],
              [109.14194317400005, 11.599115735000074],
              [109.14140146600008, 11.597946970000093],
              [109.14086360700007, 11.596776212000035],
              [109.14033686800011, 11.595600698000029],
              [109.13984013400002, 11.594413523000034],
              [109.13936198800008, 11.593217941000054],
              [109.13889332100003, 11.592017822000054],
              [109.13846718900004, 11.590808842000031],
              [109.13713671600009, 11.588558625000099],
              [109.13536434499999, 11.586577415000042],
              [109.1317986440001, 11.583439411000105],
              [109.13078340200012, 11.582646709000064],
              [109.12985547300008, 11.581754772000073],
              [109.12898126300004, 11.58075988900009],
              [109.12812468100005, 11.579738589000053],
              [109.12738990700009, 11.57867810200006],
              [109.12730102100002, 11.577550805000046],
              [109.12740850000003, 11.575559646000054],
              [109.12933136400005, 11.568149865000075],
              [109.12932788700004, 11.567807292000063],
              [109.12929622700008, 11.567482305000086],
              [109.12920130700005, 11.567025356000025],
              [109.12905931900011, 11.566523866000098],
              [109.12882438600003, 11.565936698000099],
              [109.12863599400008, 11.565594068000083],
              [109.12840158500005, 11.565286119000094],
              [109.12810180100011, 11.564984971000046],
              [109.12769307700005, 11.564655581000059],
              [109.12718074600011, 11.564333997000096],
              [109.12675911000004, 11.56421536200005],
              [109.12637110400009, 11.564251208000085],
              [109.12603887600004, 11.564453919000071],
              [109.12578685400007, 11.564757202000029],
              [109.12565221400011, 11.565072850000123],
              [109.12560089200004, 11.565344448000035],
              [109.12383411900005, 11.569815763000067],
              [109.12136767500007, 11.573009666000099],
              [109.12073104700008, 11.573745019000102],
              [109.11926673900007, 11.574711875000075],
              [109.1172963230001, 11.57586566200006],
              [109.11069510800006, 11.578296251000085],
              [109.10544144900004, 11.580076646000046],
              [109.10515757500009, 11.580136494000024],
              [109.10487039100005, 11.580247875000092],
              [109.10429941400005, 11.580516174000087],
              [109.10376622000007, 11.5808040240001],
              [109.10323255700007, 11.581126183000022],
              [109.10270240900007, 11.581475873000089],
              [109.09962562700005, 11.583613644000044],
              [109.09797280599999, 11.584668350000092],
              [109.09615165400002, 11.585779754000034],
              [109.09380100500005, 11.587181503000108],
              [109.09278085000001, 11.587744038000023],
              [109.09184710400002, 11.588199701000066],
              [109.09125193600008, 11.588454412000091],
              [109.09064559000009, 11.588669451000031],
              [109.09001715700005, 11.588843616000053],
              [109.08940479900011, 11.58896750000009],
              [109.08878978600011, 11.589047867000078],
              [109.0881638920001, 11.589085511000084],
              [109.08753726900004, 11.589079457000116],
              [109.08687965700007, 11.589028485000073],
              [109.08237779000008, 11.588553003000078],
              [109.07875562900006, 11.588226802000088],
              [109.07815000100004, 11.588160762000131],
              [109.07775844600006, 11.588096474000052],
              [109.07741185200007, 11.588015706000119],
              [109.07700993800003, 11.58789596200007],
              [109.07660321200004, 11.587749009000079],
              [109.07622113600004, 11.5875903840001],
              [109.07544956900007, 11.587223326000105],
              [109.07388793300001, 11.586405506000029],
              [109.07317109100002, 11.586004613000076],
              [109.0716466440001, 11.585031909000064],
              [109.07001094200002, 11.583468218000091],
              [109.06977737600006, 11.583255759000098],
              [109.06924905200005, 11.582968859000074],
              [109.06870810400005, 11.582771807000112],
              [109.06812270100002, 11.582629807000087],
              [109.06753029500004, 11.582543765000073],
              [109.06676062500003, 11.582491933000048],
              [109.06537644800008, 11.582467600000077],
              [109.06439632800007, 11.582489748000045],
              [109.06353914200002, 11.582550320000083],
              [109.06284812700005, 11.582635292000097],
              [109.06195303900007, 11.582789275000048],
              [109.06106368400003, 11.582978503000072],
              [109.06022148800007, 11.583191501000021],
              [109.05936393300007, 11.583442221000071],
              [109.05764406600005, 11.583997824000035],
              [109.05653152900003, 11.584401678000054],
              [109.05485988900008, 11.585397610000124],
              [109.05030914400005, 11.590221449000103],
              [109.0475981070001, 11.593956856000085],
              [109.04469686600004, 11.59994606600012],
              [109.04149270300005, 11.604133962000061],
              [109.03596040300006, 11.608373199000075],
              [109.03516040700003, 11.6098151470001],
              [109.035166426, 11.611005201000067],
              [109.03532280600007, 11.611996178000082],
              [109.03608778600004, 11.613628568000054],
              [109.03692345800008, 11.614219730000029],
              [109.03894925, 11.615697360000075],
              [109.04011579600007, 11.616881694000101],
              [109.0425559, 11.620439926000079],
              [109.04288337900009, 11.62135259200001],
              [109.04317250900003, 11.622618722000054],
              [109.04321140600005, 11.623430830000101],
              [109.04318059700007, 11.624205499000071],
              [109.04295859700009, 11.624573281000037],
              [109.04258015100004, 11.625200225000041],
              [109.0417768450001, 11.625997612000026],
              [109.04057087900006, 11.626995330000065],
              [109.03873010200007, 11.629673763000079],
              [109.03758407600003, 11.632779317000084],
              [109.03738673500004, 11.635936928000133],
              [109.03706893900004, 11.637459990000119],
              [109.03645941200006, 11.638654355000087],
              [109.03357572400002, 11.640716178000064],
              [109.03105809100008, 11.641819471000046],
              [109.02934186, 11.641728688000084],
              [109.02540227800009, 11.641053669000099],
              [109.02307802800007, 11.640469904000026],
              [109.02186197300009, 11.639484037000079],
              [109.02145332600003, 11.638494240000073],
              [109.02134101400006, 11.63621371500003],
              [109.02133507400001, 11.63502361400006],
              [109.02133012500005, 11.634031873000071],
              [109.02102489100011, 11.63353746700002],
              [109.0204173860001, 11.633143702000035],
              [109.01960753900008, 11.632651748000098],
              [109.01869924900006, 11.632656144000107],
              [109.01678421500006, 11.633161276000068],
              [109.01476775400002, 11.633567702000034],
              [109.01204139900004, 11.63328330000004],
              [109.01042274500004, 11.63249765500005],
              [109.00930674500007, 11.631312892000077],
              [109.00960020400002, 11.629427134000101],
              [109.009823302, 11.629009437000091],
              [109.01088906100009, 11.627014040000104],
              [109.01234863500005, 11.623438503000026],
              [109.013568455, 11.61982948200002],
              [109.01408850200004, 11.618694608000089],
              [109.01494163200002, 11.617643967000022],
              [109.01680136200005, 11.616301337000044],
              [109.01808504400005, 11.615597218000049],
              [109.01882305400009, 11.615192397000039],
              [109.01971993500004, 11.614700433000012],
              [109.02374352800007, 11.612102375000076],
              [109.028105098, 11.608968400000029],
              [109.03075460000011, 11.606613614000059],
              [109.03286491000009, 11.604867736000111],
              [109.03562857600005, 11.60262276900008],
              [109.03792260600005, 11.60018078200009],
              [109.03946877, 11.59796196100009],
              [109.04028946400005, 11.596451099000136],
              [109.04163802199999, 11.593766782000079],
              [109.04203381100005, 11.592227658000096],
              [109.04203521500008, 11.59112811800007],
              [109.04157566500007, 11.590475609000082],
              [109.04107412300004, 11.5902665080001],
              [109.03926895700008, 11.590136146000061],
              [109.03741931200007, 11.589921889],
              [109.03685242900006, 11.589803541000075],
              [109.03635478700001, 11.589682083000062],
              [109.035209239, 11.58934845700008],
              [109.03401120299999, 11.588931156000102],
              [109.03354280500005, 11.588739518000056],
              [109.03309833300004, 11.588537159000099],
              [109.03260773700003, 11.588286945000091],
              [109.03220492900002, 11.588057408000017],
              [109.03173717600004, 11.587759716000077],
              [109.03133888000004, 11.58747643],
              [109.03096258199999, 11.587180577000121],
              [109.03061862700004, 11.586883580000061],
              [109.03013727600009, 11.58642854900007],
              [109.02972698300007, 11.586012110000075],
              [109.02926751200008, 11.585511362000121],
              [109.02888070500003, 11.585058644000055],
              [109.028507663, 11.584592722000052],
              [109.02813477900006, 11.584095946000071],
              [109.02775691500005, 11.583559031000055],
              [109.0273624590001, 11.58296117100006],
              [109.026978017, 11.582341439000031],
              [109.02659341600005, 11.581685816000052],
              [109.02558545600006, 11.579845607000026],
              [109.024871004, 11.578443418000093],
              [109.02432281100005, 11.577231315000079],
              [109.02409127700007, 11.576653036000016],
              [109.02374205000004, 11.575644947000095],
              [109.02357717300008, 11.575072090000036],
              [109.02345827800009, 11.574589231000052],
              [109.02334547700009, 11.574036506000061],
              [109.02318090800006, 11.572911578000065],
              [109.02306390700006, 11.571577251000063],
              [109.022995489, 11.569860037000089],
              [109.02296721900005, 11.567396127000061],
              [109.0229818170001, 11.566683616000082],
              [109.02301842200004, 11.565938861000101],
              [109.02307048100008, 11.565314192000107],
              [109.02313772400004, 11.564753224000055],
              [109.02322599800006, 11.564220237000098],
              [109.02334479300004, 11.563656703000012],
              [109.02349208200005, 11.563075651000075],
              [109.023654521, 11.562517198000053],
              [109.02408689800006, 11.561237732000061],
              [109.02482870600006, 11.559279784000063],
              [109.02519632400002, 11.558363914000045],
              [109.02603175200004, 11.556537576000013],
              [109.030657799, 11.552092826000136],
              [109.03187799900006, 11.550411405000103],
              [109.032286238, 11.548981803000034],
              [109.03249534100007, 11.547922922000085],
              [109.03248858300005, 11.546583008000065],
              [109.03208499300004, 11.545279486000124],
              [109.03101753600001, 11.54195087900005],
              [109.03035317800001, 11.540163856000072],
              [109.02923299100006, 11.538841408000076],
              [109.0283027240001, 11.538634388000089],
              [109.02751824300002, 11.53899083],
              [109.02688025700007, 11.540051787000037],
              [109.02681230800005, 11.540757342000061],
              [109.02681690700008, 11.541674121000066],
              [109.0271091920001, 11.542942110000086],
              [109.02718739300009, 11.544281669000076],
              [109.02769801300006, 11.546324357000069],
              [109.02828891600011, 11.550064199000063],
              [109.02748444600007, 11.550790269000098],
              [109.02645559200002, 11.550723073000045],
              [109.02412063300001, 11.549489073000084],
              [109.02317031300007, 11.549164565000057],
              [109.02110391499998, 11.548845469000034],
              [109.01914967800012, 11.548635525000071],
              [109.01730759000003, 11.548534719000079],
              [109.01526890700009, 11.548077226000062],
              [109.01054493000004, 11.546830532000028],
              [109.00689216100005, 11.545367045000086],
              [109.00431302000007, 11.544180469000052],
              [109.00369589300008, 11.543715185000012],
              [109.00337826100009, 11.543056555000051],
              [109.00345960300007, 11.542656102000047],
              [109.00373185300003, 11.542279112000118],
              [109.00498203200002, 11.542132103000123],
              [109.00526794000004, 11.542130738000099],
              [109.00669751700011, 11.542123906000066],
              [109.00722082500006, 11.542143846000029],
              [109.00811366800004, 11.54217786300009],
              [109.00998621900007, 11.542249199000041],
              [109.01403810900004, 11.541913170000038],
              [109.01721561500005, 11.541221819000034],
              [109.01839881700008, 11.540868888000054],
              [109.01966063400002, 11.54029371500007],
              [109.02139169499999, 11.53940919100007],
              [109.02266152200008, 11.538598927000086],
              [109.02363982000001, 11.537945432000106],
              [109.02400449500009, 11.537421767000032],
              [109.02399014200005, 11.536533277000059],
              [109.02098963900004, 11.534389870000082],
              [109.01732695300002, 11.532136287000048],
              [109.01481544700006, 11.529332117000111],
              [109.01261797400005, 11.525170161000096],
              [109.01144368400006, 11.520474831000092],
              [109.01042019600001, 11.514320491000072],
              [109.00964848000011, 11.502758916000031],
              [109.00983805100007, 11.499424],
              [109.00989807300007, 11.498368110000088],
              [109.01030605400007, 11.491190645000058],
              [109.0118231340001, 11.480041525000038],
              [109.01264504500007, 11.472844836000105],
              [109.01174159200011, 11.46354102],
              [109.01084658500012, 11.455929641000038],
              [109.00976922000007, 11.451429918000054],
              [109.00929164800007, 11.445069921000071],
              [109.00961223600004, 11.442216342000043],
              [109.01047589700009, 11.438725901000078],
              [109.011029906, 11.435627933000079],
              [109.01172144300003, 11.430540384000041],
              [109.01169636300008, 11.425463595000094],
              [109.01168382400003, 11.422925213000033],
              [109.01081058100007, 11.419685853000059],
              [109.00951222400002, 11.417153663000066],
              [109.00621167400006, 11.414207993000057],
              [109.00608270300003, 11.413832148000045],
              [109.00577683800005, 11.412940868000108],
              [109.00562912700008, 11.411954416000112],
              [109.00533370100007, 11.409981514000032],
              [109.00531778000007, 11.406738098000078],
              [109.00514931400008, 11.401521125000079],
              [109.00526379200005, 11.395738762000105],
              [109.00524372100008, 11.39164931200005],
              [109.00622220700004, 11.387273078000069],
              [109.00734419700005, 11.383037184000065],
              [109.008614526, 11.379928737000087],
              [109.01017259800004, 11.377241957000093],
              [109.01244482400003, 11.37455173100008],
              [109.01586318700006, 11.372561046000039],
              [109.0185152060001, 11.370656423000101],
              [109.01927450500006, 11.366375097000068],
              [109.01915044400009, 11.363853002000022],
              [109.0185860760001, 11.362143663000078],
              [109.01671962100005, 11.360208437],
              [109.01307323600005, 11.357204025000021],
              [109.00892969000006, 11.354659557000078],
              [109.00301303300007, 11.351784792000073],
              [108.99975583500004, 11.35021715100004],
              [108.99747066700006, 11.350227998000049],
              [108.99561739500011, 11.350941835000048],
              [108.99318871400007, 11.350812305000078],
              [108.99132109200008, 11.348564942000026],
              [108.99059402300006, 11.345889176000055],
              [108.99001252400008, 11.343776765000067],
              [108.98813411800009, 11.33927330200004],
              [108.98597696300004, 11.336181245000086],
              [108.98181848400004, 11.3326755],
              [108.97895351000005, 11.330855754000078],
              [108.97580498200004, 11.329460315000082],
              [108.97279930200004, 11.328064171000028],
              [108.97064717200006, 11.325959016000107],
              [108.96634503200008, 11.32217166900009],
              [108.96204305100011, 11.318384254000067],
              [108.95989376700001, 11.316843026000088],
              [108.95474903100003, 11.316020412000059],
              [108.94746104800001, 11.314925333000046],
              [108.94260986900008, 11.313427238000083],
              [108.93995506000006, 11.312321933000112],
              [108.93601655500007, 11.310464465000079],
              [108.93272391500003, 11.30864604900008],
              [108.93115061000006, 11.308089014000087],
              [108.92292122100012, 11.307984842000083],
              [108.91732086400002, 11.308552834000094],
              [108.91625915300001, 11.309062807000101],
              [108.91580377300008, 11.309786703000045],
              [108.91527189600004, 11.310832441000061],
              [108.91570160600007, 11.313187081000107],
              [108.91517213100005, 11.315310259000098],
              [108.91444020100006, 11.316650854000086],
              [108.90898052200005, 11.32134736200001],
              [108.90352135000009, 11.324706985000075],
              [108.900347143, 11.326744263000064],
              [108.8977956950001, 11.328758636000112],
              [108.89484100600005, 11.331794408000027],
              [108.89242428700003, 11.334342804000118],
              [108.88806050300009, 11.336853208],
              [108.88499981500007, 11.338029996000019],
              [108.88454321700004, 11.338229783000118],
              [108.88416918700008, 11.338262657000085],
              [108.88366487200003, 11.338324335000037],
              [108.88335262900003, 11.33836958600005],
              [108.88251838400001, 11.33843481800003],
              [108.88132402900004, 11.338527160000032],
              [108.87992573700008, 11.338618043000091],
              [108.87791818300006, 11.338769809000036],
              [108.87471082900008, 11.336315214000068],
              [108.87257632000004, 11.334017568000119],
              [108.87135460900002, 11.333642033000045],
              [108.86449019700005, 11.333627279000067],
              [108.8655716550001, 11.337189633000031],
              [108.86903734200006, 11.337629669000114],
              [108.87204411100005, 11.33875304300007],
              [108.87320839200002, 11.341019843000042],
              [108.87391859600007, 11.345105846000079],
              [108.874400807, 11.349874318],
              [108.87026527300004, 11.354889136000066],
              [108.86703214900005, 11.354902485000105],
              [108.85710563300003, 11.355851832000051],
              [108.84928385900007, 11.363152966000099],
              [108.84583372900006, 11.366574426000041],
              [108.8442283090001, 11.369306931],
              [108.84354947500006, 11.372717243000084],
              [108.84310158800005, 11.376126633000093],
              [108.84241618200009, 11.377946781000057],
              [108.84057126200003, 11.378635678000125],
              [108.83664487300005, 11.378651329000087],
              [108.83248843500004, 11.378894995000087],
              [108.82464295300008, 11.380743291000071],
              [108.81910247100005, 11.381446455000075],
              [108.81032930200007, 11.38238912900009],
              [108.80156226600005, 11.384921651000111],
              [108.794181741, 11.38767576000008],
              [108.78588408600011, 11.392250471000052],
              [108.78104477000009, 11.395221821000055],
              [108.77436514300004, 11.400244470000052],
              [108.75984907700006, 11.410066240000026],
              [108.75546929100005, 11.412581030000089],
              [108.75478883600006, 11.415991250000078],
              [108.75573550100005, 11.42212188700009],
              [108.756463791, 11.431661203000052],
              [108.7572013810001, 11.443699723000032],
              [108.75839695900008, 11.454600800000046],
              [108.75911706600007, 11.461868547000085],
              [108.75914149200004, 11.468457264000074],
              [108.758691148, 11.471639708000046],
              [108.75361262700008, 11.47302116600004],
              [108.74691405200006, 11.473726648000058],
              [108.7411372490001, 11.47374707000006],
              [108.73512855800006, 11.473540945000058],
              [108.72726404600012, 11.47129624800005],
              [108.721248239, 11.469044936000026],
              [108.71523177100003, 11.466566257000109],
              [108.70898753500003, 11.464996981000029],
              [108.702979134, 11.46478994100004],
              [108.69812838000011, 11.465260487000071],
              [108.69074203700004, 11.467556860000093],
              [108.68474345600002, 11.470302872000014],
              [108.67990158200006, 11.473499393000084],
              [108.67552545400008, 11.477830342000049],
              [108.67207570800011, 11.482839886000116],
              [108.66931905500012, 11.487847194],
              [108.66656079600007, 11.492400087000119],
              [108.6628824220001, 11.498319143000034],
              [108.66072461300008, 11.502023500000023],
              [108.65943494900004, 11.504237438000063],
              [108.65530629400003, 11.514020475000029],
              [108.65141055100001, 11.524484487000029],
              [108.64728757100002, 11.536312586000095],
              [108.64430702500006, 11.544047260000044],
              [108.64225491800009, 11.551273029000074],
              [108.63646976500007, 11.551115038000058],
              [108.62976683300005, 11.551362425000098],
              [108.62398782900007, 11.551379633000101],
              [108.61982900800005, 11.552073589000054],
              [108.61497667700003, 11.55276949800008],
              [108.60874004100003, 11.554378205000136],
              [108.60319413000009, 11.555075841000038],
              [108.59649234300008, 11.555776617000022],
              [108.58794119400007, 11.556482345000061],
              [108.58225320300009, 11.556813911000051],
              [108.58095938700006, 11.560364566000112],
              [108.57934955500009, 11.563322956000109],
              [108.57739226800007, 11.566055082000036],
              [108.57543652500007, 11.569355263000068],
              [108.57348326500002, 11.573564355000075],
              [108.5669168310001, 11.581648875000015],
              [108.56288060600009, 11.585181833000123],
              [108.55067323100008, 11.602370488000078],
              [108.55056435100005, 11.604870403000065],
              [108.55091944300003, 11.607937224000063],
              [108.55104300300003, 11.610891031000035],
              [108.55105401600005, 11.614981369000075],
              [108.55095981699999, 11.622935145000033],
              [108.55097480600008, 11.62850263800008],
              [108.55133024100009, 11.631683166000071],
              [108.55776479100011, 11.639229729000087],
              [108.56119020100002, 11.643246839000108],
              [108.59902047800009, 11.64950568000007],
              [108.60364757400004, 11.650060602000066],
              [108.60954736700005, 11.650838992000077],
              [108.61440648600011, 11.65162025600006],
              [108.619032985, 11.651947594000054],
              [108.62215517500006, 11.651938394000092],
              [108.63257970500007, 11.657475070000077],
              [108.63709673500007, 11.65973400800002],
              [108.64184480100009, 11.661878520000089],
              [108.64647696000002, 11.663909666000015],
              [108.64948873400007, 11.665491195000042],
              [108.65412177700009, 11.667749424000105],
              [108.65852180200008, 11.669440146000019],
              [108.66002593700009, 11.669662701000048],
              [108.66245047300006, 11.668405171000094],
              [108.6639505590001, 11.667377784000086],
              [108.66683799, 11.666232368000083],
              [108.66984438500006, 11.666109197000106],
              [108.67297090000002, 11.667349154000116],
              [108.67552035500002, 11.668931795000058],
              [108.67830641700006, 11.672104473000021],
              [108.68120820899999, 11.675276744000127],
              [108.69806195300004, 11.699993313000066],
              [108.69922286600007, 11.701239431000053],
              [108.69957335900003, 11.70226097300011],
              [108.69877883900003, 11.706695342000016],
              [108.6970535, 11.709541928000087],
              [108.69532966600009, 11.712843046000019],
              [108.68926454600009, 11.73229485100009],
              [108.68869493300012, 11.734910400000052],
              [108.68708261400012, 11.737074795000078],
              [108.68523622500003, 11.738444474000074],
              [108.68200346900007, 11.740386830000114],
              [108.67853927600005, 11.742329886000034],
              [108.67530676900003, 11.744385789000054],
              [108.67114792600009, 11.746217315000072],
              [108.66837730300009, 11.748044364000075],
              [108.66630264900002, 11.750437367000098],
              [108.66515459000007, 11.753168372000021],
              [108.66446928100004, 11.755897920000089],
              [108.66355330600001, 11.758855489000018],
              [108.6633322320001, 11.762038157000097],
              [108.66357321400005, 11.76499209300008],
              [108.66404413000002, 11.767490753000052],
              [108.665208545, 11.769759940000045],
              [108.66706869200007, 11.772481499000074],
              [108.66893000400007, 11.775543985000068],
              [108.67067641400007, 11.778834117000068],
              [108.67149458100008, 11.781331687000128],
              [108.67138483600006, 11.783150355000059],
              [108.67035014300006, 11.785199266000033],
              [108.66850469400006, 11.787023455000066],
              [108.6658473630001, 11.788281978000013],
              [108.66214892800006, 11.789657382000128],
              [108.65891331200005, 11.791031277000029],
              [108.6559083540001, 11.792177109000066],
              [108.63948518000007, 11.794955104000058],
              [108.63613220000011, 11.795874426000095],
              [108.63208698100007, 11.797477650000053],
              [108.62908247700008, 11.798850414000038],
              [108.62561540100009, 11.80033814300012],
              [108.62122595000008, 11.802965027000043],
              [108.61810831300006, 11.80524715000004],
              [108.61718801800004, 11.807068216000081],
              [108.61765674800009, 11.80899886600004],
              [108.61870240300001, 11.810359580000094],
              [108.63856151400003, 11.832689427000085],
              [108.63961117400001, 11.833486009000092],
              [108.64169396200003, 11.835066594000041],
              [108.64819775700002, 11.84209314800002],
              [108.6509864770001, 11.845494152000045],
              [108.65238703600008, 11.849126738000047],
              [108.65262796300003, 11.852081003000057],
              [108.65205645100008, 11.854355852000074],
              [108.65183255800011, 11.856743296000081],
              [108.65103206000005, 11.859814466000083],
              [108.64966467300007, 11.866638032000052],
              [108.64887348100007, 11.872664273000042],
              [108.64935565600008, 11.878686635000067],
              [108.65065077900003, 11.88550214800008],
              [108.65170972500007, 11.890840853000027],
              [108.65288123000002, 11.895156303000041],
              [108.65323690800005, 11.897769383000066],
              [108.65440375500005, 11.90060728100004],
              [108.65493177400003, 11.90139305100004],
              [108.65661411400006, 11.90389657800006],
              [108.65893996300012, 11.907071840000059],
              [108.66045502700007, 11.910135966000011],
              [108.66150701100007, 11.913201555000084],
              [108.66288456000009, 11.945250647000092],
              [108.66127398600005, 11.948552046000048],
              [108.65873874400009, 11.952424677000105],
              [108.65840047500006, 11.955267430000037],
              [108.65875522900002, 11.957539684000016],
              [108.659577267, 11.961060836000035],
              [108.66074346100004, 11.963557904000053],
              [108.66179314800003, 11.965827996000121],
              [108.66341180800002, 11.96824706500008],
              [108.66355486700004, 11.968460859000094],
              [108.66563093100004, 11.970703700000039],
              [108.66819006000004, 11.974105717000059],
              [108.67016680500009, 11.97648652800005],
              [108.67330594300003, 11.980113999000073],
              [108.67597812900006, 11.982606215000134],
              [108.67784035700004, 11.985328368000035],
              [108.67924046300007, 11.988393007000029],
              [108.68052326400007, 11.991003336000093],
              [108.68134770700007, 11.995092908000069],
              [108.68240233900012, 11.998727047000022],
              [108.68240917100006, 12.00077315700012],
              [108.68149159200007, 12.003504303000055],
              [108.68011215399999, 12.006691642000076],
              [108.67907830600006, 12.009309493000051],
              [108.67897190500005, 12.012151717000028],
              [108.67978997300006, 12.014308923000081],
              [108.682365428, 12.022371594000079],
              [108.68504628000002, 12.027251012000038],
              [108.69098994800002, 12.037690007000011],
              [108.69169355000007, 12.040188631000069],
              [108.69320623599999, 12.042116212000041],
              [108.69517891100006, 12.043019153000055],
              [108.69981323400008, 12.04300382700003],
              [108.70178399500007, 12.043338323000084],
              [108.70306156200007, 12.044243504000105],
              [108.70469103300007, 12.04639797800011],
              [108.70690456600008, 12.049914649000113],
              [108.70877058000011, 12.053432489000036],
              [108.70959189200003, 12.056385435000026],
              [108.7098374440001, 12.060363454000099],
              [108.70915999600001, 12.065481421000078],
              [108.70800797400008, 12.067417885000074],
              [108.70020153700005, 12.088702826000118],
              [108.69940202500007, 12.092116045000088],
              [108.69767694200007, 12.095987080000013],
              [108.69537117000007, 12.099518966000032],
              [108.6924839390001, 12.102484323000072],
              [108.68740458300002, 12.108412672000044],
              [108.68451634900006, 12.111150571000094],
              [108.67364717000002, 12.11880268000005],
              [108.67202904300007, 12.120172109000025],
              [108.67157066200008, 12.121765212000033],
              [108.67169030700002, 12.1229017290001],
              [108.67308377100004, 12.123693112000032],
              [108.67679721200007, 12.125045507000019],
              [108.68201937500005, 12.126961419000091],
              [108.68712381400006, 12.128309136000047],
              [108.69072306700006, 12.130116427000102],
              [108.69200382400007, 12.131817593000081],
              [108.69376214700007, 12.13761005300009],
              [108.69446689100003, 12.140336330000066],
              [108.69424047500004, 12.141928763000108],
              [108.69343370800007, 12.143295719000056],
              [108.69251140400006, 12.144776748000117],
              [108.69170499900004, 12.146257395000058],
              [108.69240511900006, 12.147619408000041],
              [108.694612578, 12.149090158000051],
              [108.69786605700008, 12.151353279000075],
              [108.70251496100006, 12.15486233900007],
              [108.70553245600007, 12.155875515000057],
              [108.70959012300007, 12.155975603000078],
              [108.71525211900003, 12.156459951],
              [108.72345581100006, 12.15796353800007],
              [108.72705052200004, 12.163277456000097],
              [108.72724683600002, 12.16310931600013],
              [108.73027156400009, 12.161330741000052],
              [108.73229384100009, 12.159618575000051],
              [108.73349567400004, 12.158946839000071],
              [108.7356195080001, 12.157759746000083],
              [108.73866049400009, 12.157322839000082],
              [108.74430727200003, 12.156308314000073],
              [108.76415122800003, 12.154958521000124],
              [108.76734126500001, 12.155657421000042],
              [108.76836237600004, 12.157500954000076],
              [108.77228430000008, 12.165448280000122],
              [108.778399859, 12.168121461000057],
              [108.78115113200001, 12.167684996000093],
              [108.78331793600006, 12.165971793],
              [108.78634526500008, 12.161981800000097],
              [108.78763498800002, 12.15828247700008],
              [108.787913788, 12.155439504000041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 43, code: "AD01", ten_tinh: "Phú Thọ" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.11400695100008, 21.705834796000097],
              [105.11600953500003, 21.705736062000099],
              [105.11974422500005, 21.705784519000112],
              [105.12228586000002, 21.705318489000014],
              [105.12416615400008, 21.704469648000035],
              [105.12564997300009, 21.703366165000126],
              [105.12877125400007, 21.699788990000087],
              [105.13026054600002, 21.698311313000126],
              [105.13120156100007, 21.697824491000084],
              [105.13253533000002, 21.697841713000088],
              [105.13665159900009, 21.699142249000047],
              [105.14288911200006, 21.701343207000079],
              [105.144089525, 21.701358644000024],
              [105.14529361000005, 21.701124635000021],
              [105.1481085990001, 21.699668388000056],
              [105.14832547300009, 21.695786148000096],
              [105.14920229400008, 21.693350054000057],
              [105.15088647600007, 21.691125374000109],
              [105.15316280900004, 21.689469834000086],
              [105.15407991900007, 21.688358436000051],
              [105.15501760200002, 21.685843347000052],
              [105.15489838300002, 21.683735891000119],
              [105.15511455600006, 21.683038333000049],
              [105.15550960900005, 21.682859894000025],
              [105.15609681400005, 21.682959168000068],
              [105.15831664400005, 21.68546487600009],
              [105.16093293200007, 21.687700367000048],
              [105.16277793100005, 21.689008453000014],
              [105.163852949, 21.689297433000029],
              [105.16513086500004, 21.689130251000051],
              [105.17456265200008, 21.688241176000034],
              [105.17593864900007, 21.688075170000062],
              [105.17696715400008, 21.687669473000071],
              [105.17748513200004, 21.686305673000028],
              [105.17772402400009, 21.683372659],
              [105.17833925900001, 21.681545438000072],
              [105.17973919700002, 21.679728175000079],
              [105.18191316800004, 21.678654741000081],
              [105.18643061100008, 21.678345006000121],
              [105.18780385800009, 21.678362399000051],
              [105.18957212800004, 21.678201282000131],
              [105.19076248400002, 21.677298811000064],
              [105.19077576600006, 21.676381455000104],
              [105.19042326600002, 21.673624397000069],
              [105.19044451900001, 21.672156619000035],
              [105.19204310600003, 21.670158249000067],
              [105.20213511100006, 21.664229838],
              [105.20410998200003, 21.663337166000041],
              [105.20746060300007, 21.66227826500009],
              [105.22219909400008, 21.66062801200006],
              [105.23243638800005, 21.659786199000038],
              [105.23462410100005, 21.652041528000062],
              [105.23548184700006, 21.647978768000087],
              [105.23551274200004, 21.645809910000047],
              [105.23431528400008, 21.643047267000078],
              [105.23158658900007, 21.639253202000042],
              [105.22854268100011, 21.635888993000037],
              [105.22532777100012, 21.633679538000102],
              [105.22407929000012, 21.632878160000054],
              [105.2233985400001, 21.63164752700002],
              [105.22341428900008, 21.630546608000053],
              [105.22448999900008, 21.628126617000106],
              [105.226314985, 21.625109520000052],
              [105.22930525200006, 21.621224377000111],
              [105.23037416700004, 21.619766777000038],
              [105.23100991700007, 21.619284395000093],
              [105.23185106100003, 21.619098759000011],
              [105.23699845100003, 21.61828031800006],
              [105.24151426900002, 21.617650036000043],
              [105.24350949400008, 21.617380631000046],
              [105.24403623300006, 21.617191045000084],
              [105.24456436300005, 21.616903411000088],
              [105.24530903800003, 21.616128148000065],
              [105.24724672200004, 21.612523833000097],
              [105.2482231820001, 21.610182402000078],
              [105.24888248000012, 21.608033147000057],
              [105.24901367000004, 21.606171541000037],
              [105.24902617500001, 21.605289110000037],
              [105.24882633500009, 21.604600177000052],
              [105.24809839800008, 21.60419889200007],
              [105.24569531200011, 21.603678760000022],
              [105.2445552030001, 21.602782021000074],
              [105.24310912, 21.601293078000069],
              [105.24218836000003, 21.599712583000041],
              [105.24208775300005, 21.59941713500011],
              [105.24357697199999, 21.597866571000068],
              [105.24518069000007, 21.595630946000099],
              [105.24561928900003, 21.594263450000106],
              [105.24576858400005, 21.591127145000044],
              [105.24601569000006, 21.588482376000059],
              [105.24647879000001, 21.585953069000041],
              [105.24733579400004, 21.584148208],
              [105.25016728300002, 21.579903900000104],
              [105.25508955600006, 21.574777628000064],
              [105.26139508300007, 21.569797958000066],
              [105.26684903100005, 21.56623409500007],
              [105.26964518400003, 21.564452952000046],
              [105.27133124500008, 21.562787805000113],
              [105.27232280500006, 21.561243798000085],
              [105.27275114000007, 21.560341274000017],
              [105.27276752900009, 21.559174315000092],
              [105.27223709400008, 21.557481894000126],
              [105.26608558800002, 21.551570353000038],
              [105.26513237500002, 21.550391428000104],
              [105.26469091100009, 21.549683723000079],
              [105.26460531500003, 21.548759581000084],
              [105.26502310200004, 21.547103162000077],
              [105.26633082100007, 21.54527308500009],
              [105.26772282500005, 21.544459437000093],
              [105.26811454100003, 21.544368540000036],
              [105.27187803400003, 21.543495153000066],
              [105.28016893900008, 21.542950771000058],
              [105.28474877800004, 21.541656366000069],
              [105.28713755500007, 21.540574564000089],
              [105.28961758700009, 21.539493850000021],
              [105.29173724700006, 21.538066855000082],
              [105.29276035400007, 21.536711929000063],
              [105.29435015500007, 21.53399648],
              [105.29473787100008, 21.532377383000068],
              [105.2945791320001, 21.530666156000052],
              [105.29353504500004, 21.526978434000021],
              [105.29311679000007, 21.524238440000062],
              [105.29316085100008, 21.521076738000119],
              [105.29356164800002, 21.518517636000034],
              [105.29414021300002, 21.516302564000078],
              [105.2952639760001, 21.514265060000071],
              [105.29665675300005, 21.512572689000109],
              [105.29951925300001, 21.510214463000032],
              [105.30106223200005, 21.509725387000067],
              [105.30311664800004, 21.509558225000092],
              [105.30556809200002, 21.510356596000065],
              [105.31270141400003, 21.513901688000111],
              [105.31577380000004, 21.51432317700008],
              [105.31753636300004, 21.514177639000117],
              [105.31959455900008, 21.513350778000095],
              [105.32143417100005, 21.511882456000109],
              [105.32259161000007, 21.510405884000058],
              [105.32283512900005, 21.509237680000034],
              [105.3229419080001, 21.507734554000102],
              [105.322322442, 21.505185698000062],
              [105.32079690000008, 21.500376162000023],
              [105.32082332600004, 21.498460023000021],
              [105.32130301500004, 21.49665582100004],
              [105.32235255500009, 21.494752032000086],
              [105.32397047400012, 21.492855080000069],
              [105.32789119500006, 21.488856437000067],
              [105.32939386500006, 21.487064508000039],
              [105.33079746100002, 21.484206648000011],
              [105.33250436500002, 21.47581578000004],
              [105.33501260700008, 21.467008509000074],
              [105.33834832000012, 21.455868492],
              [105.34078246300008, 21.448284464],
              [105.34150807300009, 21.445098767000026],
              [105.34177178400004, 21.442439924000034],
              [105.34104906900002, 21.437107201000046],
              [105.33945659400007, 21.428888957000055],
              [105.33947991100004, 21.427185507000068],
              [105.34008890399998, 21.424211269000089],
              [105.34125151400008, 21.422308497000039],
              [105.34286718, 21.420517627],
              [105.34447264400005, 21.419472017000096],
              [105.34720717800009, 21.418972331000049],
              [105.34845717600004, 21.418987286000046],
              [105.34970572300006, 21.419108699000056],
              [105.35094845800003, 21.419655976000037],
              [105.35229324100001, 21.421056344000085],
              [105.35507912500003, 21.425135996000044],
              [105.35743226400004, 21.427613179000012],
              [105.36294433900012, 21.431831639000087],
              [105.36825387500005, 21.434237338000095],
              [105.37221705900005, 21.435349163000069],
              [105.37380810200007, 21.43536801500003],
              [105.37808345500005, 21.434407042000103],
              [105.38242778500006, 21.432541697000126],
              [105.38702221200006, 21.428975489000024],
              [105.39095179000003, 21.424123536000117],
              [105.39559721700007, 21.41672424800003],
              [105.39655193200011, 21.413327927000033],
              [105.39659756200007, 21.409920916000047],
              [105.39487648700005, 21.40265957000009],
              [105.39180636600004, 21.394317362000059],
              [105.39026997000011, 21.390252659000097],
              [105.38987267400009, 21.385988363000052],
              [105.39065726299999, 21.378330204000036],
              [105.39069520700012, 21.375976102000038],
              [105.39041217600005, 21.372223252000119],
              [105.39035482900002, 21.371387108000036],
              [105.39027277100007, 21.370190611000069],
              [105.39029793000002, 21.368316132000054],
              [105.39151906899998, 21.364268475000081],
              [105.39340260800005, 21.360541043000048],
              [105.39544854700003, 21.357127949000088],
              [105.39832768500005, 21.353724601000046],
              [105.40254617100004, 21.349868190000088],
              [105.40977455000009, 21.345265694000126],
              [105.4213580620001, 21.33899502600007],
              [105.43083002, 21.334808461000065],
              [105.43574263400004, 21.332192119000034],
              [105.44057032800006, 21.328286898000037],
              [105.44462555400004, 21.324863278000095],
              [105.445400089, 21.324209349000078],
              [105.45119083600004, 21.316870490000014],
              [105.45400887500006, 21.312080619000092],
              [105.4547705410001, 21.310022677000084],
              [105.45479983100005, 21.307784075000043],
              [105.45445956100005, 21.305713454000049],
              [105.45380267600005, 21.304191622000076],
              [105.45295184900009, 21.303117432000107],
              [105.45212629800008, 21.302488022000077],
              [105.45166731900012, 21.302139615000023],
              [105.44995110100005, 21.301106145000048],
              [105.4490402820001, 21.300487416000102],
              [105.4483469730001, 21.299769820000058],
              [105.44810369000005, 21.299299189000109],
              [105.447819807, 21.298750014000056],
              [105.44750683500004, 21.297884743000047],
              [105.44740672300003, 21.297275344000077],
              [105.447492325, 21.296584601000035],
              [105.44861529900001, 21.295658608000068],
              [105.44919931600002, 21.295306898],
              [105.45112601300009, 21.294130519000106],
              [105.45091106400008, 21.293070985000014],
              [105.44955883100002, 21.288647513000036],
              [105.44840956400004, 21.280699971000061],
              [105.44759843800009, 21.273043136000069],
              [105.44757946600002, 21.272315180000057],
              [105.44712022700003, 21.26583769300003],
              [105.43880303800003, 21.265815535000065],
              [105.43648378000006, 21.270952862000044],
              [105.43023512900007, 21.283687481000065],
              [105.42320319200007, 21.289907548000109],
              [105.40203409300003, 21.297588207000025],
              [105.38315256200008, 21.296552830000067],
              [105.37240045700004, 21.294564518000044],
              [105.36083811500002, 21.292425739000095],
              [105.35573151200001, 21.284640076000102],
              [105.35229348100003, 21.277290269000027],
              [105.35196465700008, 21.265748779000049],
              [105.35185231000003, 21.26278897600011],
              [105.35178504100006, 21.260604878000052],
              [105.351608388, 21.254868750000085],
              [105.35017868600002, 21.243635262000012],
              [105.34767636000001, 21.233708320000041],
              [105.34563643900006, 21.228570363000095],
              [105.34531508100012, 21.227732060000015],
              [105.34096757400003, 21.216390184000119],
              [105.34012920000004, 21.213410894000084],
              [105.33963790900006, 21.210765679000062],
              [105.33965143400012, 21.209776090000076],
              [105.33913535900004, 21.208945097000061],
              [105.33773813800008, 21.208268469000032],
              [105.33074083300004, 21.205709814000073],
              [105.32495829000004, 21.204320343000035],
              [105.31971223200004, 21.202277357000106],
              [105.30592416300007, 21.195016632000119],
              [105.3019601230001, 21.192081800000025],
              [105.29761442200007, 21.187896315000017],
              [105.29331751300008, 21.180214551000098],
              [105.28967946300006, 21.175367777000083],
              [105.286333789, 21.170910218000039],
              [105.28501903600002, 21.168033050000098],
              [105.28470698200005, 21.166121855000064],
              [105.28472914400001, 21.164532648000112],
              [105.28579382300009, 21.16104883400007],
              [105.28981999900006, 21.155372628000087],
              [105.29339416700002, 21.150333287000045],
              [105.30788003700007, 21.131435487000068],
              [105.314106194, 21.121973672000038],
              [105.31793119500003, 21.11470790400007],
              [105.31830476200003, 21.112169046000062],
              [105.31836165200011, 21.108036720000101],
              [105.31678274700003, 21.099751437000066],
              [105.31283869600003, 21.09111937200003],
              [105.30220851600004, 21.076364757000121],
              [105.29595798500006, 21.063252605000038],
              [105.29300005400009, 21.056857380000068],
              [105.29203326700004, 21.053348036000052],
              [105.29208627900007, 21.049533211000067],
              [105.29635056000004, 21.034959167000089],
              [105.30052172800001, 21.02706094600002],
              [105.30532980700011, 21.022032056000036],
              [105.31355661000001, 21.014500807000047],
              [105.31504306000006, 21.012524948000078],
              [105.31758857100002, 21.009141209000056],
              [105.32375274600004, 21.000946509000059],
              [105.32668571500007, 20.994945161000089],
              [105.33333191800001, 20.979125955000107],
              [105.33792553100004, 20.964871443],
              [105.34224087100004, 20.946161029000088],
              [105.34284447500008, 20.942649958000104],
              [105.32829798600007, 20.940074403000075],
              [105.32620000700003, 20.93934133700008],
              [105.32500920700008, 20.938336155000044],
              [105.32412727600004, 20.936768543000106],
              [105.32322983000003, 20.936333061000042],
              [105.31615612600007, 20.936105714000064],
              [105.31525675200007, 20.93581170700007],
              [105.3146623640001, 20.935238330000061],
              [105.31439440900004, 20.932828894000096],
              [105.31398565100007, 20.929710044000061],
              [105.31325634900003, 20.928002679000102],
              [105.31222597800004, 20.926291653000106],
              [105.31148498900011, 20.925433389000055],
              [105.31043513200009, 20.925137526000093],
              [105.30541392500004, 20.925163754000039],
              [105.29417322000009, 20.92522205500007],
              [105.29190730400009, 20.925760457000116],
              [105.29099425500004, 20.926456979000108],
              [105.28747676900007, 20.930376979000087],
              [105.28595757700005, 20.931349109000053],
              [105.28460073700005, 20.931473971000081],
              [105.28234852600004, 20.931021664000049],
              [105.28131052000003, 20.929876566000068],
              [105.28028831300001, 20.927599322000034],
              [105.27970589600005, 20.926176740000102],
              [105.27910966800002, 20.925744769000062],
              [105.27836092800005, 20.925452470000039],
              [105.27474211200004, 20.925832483000036],
              [105.27322091300003, 20.926946051000073],
              [105.27213547700011, 20.929197319000089],
              [105.26983268900008, 20.935888128],
              [105.26967541200004, 20.937527698000032],
              [105.26962592000004, 20.941062639000037],
              [105.26934669700002, 20.94181679800009],
              [105.26839946800007, 20.942310172000056],
              [105.26625229400005, 20.942157346000023],
              [105.26545175200008, 20.941768631000102],
              [105.26426777500008, 20.939986208000057],
              [105.26321635800012, 20.938331680000118],
              [105.26175140300006, 20.937429642],
              [105.25934278400007, 20.936768429],
              [105.25853695400011, 20.936758433000065],
              [105.25732288700002, 20.937122179000056],
              [105.25092335000005, 20.943229859000034],
              [105.24982573900009, 20.944857698000071],
              [105.24900384700004, 20.945983880000085],
              [105.24830508200003, 20.946418425000068],
              [105.24752720100007, 20.946670277000031],
              [105.24450036500009, 20.946544187000079],
              [105.242010963, 20.946024222000048],
              [105.24097084500009, 20.946011237000036],
              [105.24081802800006, 20.945376749000033],
              [105.24083053200012, 20.944493026000096],
              [105.24057084400003, 20.943858439000081],
              [105.23910774000004, 20.942830009000076],
              [105.23255707900009, 20.940601485000073],
              [105.22158886800003, 20.937307090000076],
              [105.21919298800003, 20.935761705000019],
              [105.21786796800009, 20.934482316000036],
              [105.21747225900003, 20.933972243000106],
              [105.21748666900007, 20.93296225300012],
              [105.21790578900001, 20.931831091000049],
              [105.21967510000005, 20.930211827],
              [105.22238447000004, 20.928604366000059],
              [105.22279455400005, 20.92810443500008],
              [105.22280174500001, 20.927599431000132],
              [105.22173814800003, 20.926828429000111],
              [105.21853121300008, 20.925651620000082],
              [105.21672928700011, 20.925358985000031],
              [105.21350634200007, 20.925231743000069],
              [105.21212703100008, 20.925041108000109],
              [105.21047631700002, 20.924500585000061],
              [105.20892640300011, 20.92335499900004],
              [105.20829893300007, 20.92213444700004],
              [105.20785446200006, 20.921002813000108],
              [105.20768758000003, 20.919788076000053],
              [105.20723815800005, 20.919002848000012],
              [105.20641407199999, 20.918645955000059],
              [105.2038422700001, 20.91809372700002],
              [105.20255264800005, 20.918077402000087],
              [105.20107257600007, 20.91849174],
              [105.19940205100005, 20.919336737000016],
              [105.1985630520001, 20.920019029000066],
              [105.197626955, 20.921046559000089],
              [105.19667093200003, 20.923459682],
              [105.195708649, 20.926305800000122],
              [105.19504762500002, 20.927423418000046],
              [105.19345540799999, 20.929222124000098],
              [105.19251550000003, 20.930509411000031],
              [105.19241340700002, 20.931201031000057],
              [105.19302584900007, 20.93346080000007],
              [105.19410142600006, 20.935553218000088],
              [105.19536251900004, 20.937561352000067],
              [105.19963394800006, 20.941686380000043],
              [105.20190855000006, 20.943707307000103],
              [105.20248937400007, 20.944970559000083],
              [105.20308707200005, 20.948269416000095],
              [105.203348562, 20.949312070000033],
              [105.20388644300002, 20.950358228000084],
              [105.205248549, 20.951761255000086],
              [105.20542661300009, 20.952196567000044],
              [105.20523489700003, 20.95271381600012],
              [105.20430488500008, 20.953308339000017],
              [105.19752196400002, 20.95720655800006],
              [105.19622336800003, 20.957796360000103],
              [105.193446862, 20.958627237000101],
              [105.19104386600003, 20.959116366000075],
              [105.19048291100007, 20.958984564],
              [105.19003290200006, 20.95846282300009],
              [105.18916140100006, 20.955441615000048],
              [105.18845306100008, 20.953798519000074],
              [105.1876396050001, 20.953100136000025],
              [105.18291836900008, 20.950545874000071],
              [105.17929132800001, 20.948263479000047],
              [105.17765955600008, 20.947210586000061],
              [105.17665822100001, 20.946853765000029],
              [105.17475405400002, 20.946307897000068],
              [105.17035967600005, 20.945597956000128],
              [105.16033063300006, 20.944452406000025],
              [105.15991046600003, 20.944120148000067],
              [105.15965221900007, 20.943281518000042],
              [105.15960457500003, 20.941247113000117],
              [105.15945958400002, 20.940591531000052],
              [105.15908069700004, 20.940078213000035],
              [105.15854200100004, 20.939926014000051],
              [105.15583479400004, 20.940109088000014],
              [105.15111249800003, 20.940701973000039],
              [105.14785171400007, 20.941749442000024],
              [105.14630228500002, 20.942019980000026],
              [105.14565318600006, 20.941954829000053],
              [105.13882136700002, 20.940659038000042],
              [105.13616955500008, 20.940463694000044],
              [105.13249979700002, 20.939611145000114],
              [105.130963412, 20.939269219000067],
              [105.13019285200002, 20.939259213000035],
              [105.12881703800005, 20.93964381300006],
              [105.12037775300006, 20.942834328000082],
              [105.11882114900006, 20.943860479000072],
              [105.11456385600005, 20.947990678000039],
              [105.11304104900009, 20.949620932000101],
              [105.10998224700005, 20.953766661000103],
              [105.10867163500005, 20.955520389000078],
              [105.10806391000004, 20.956075896000037],
              [105.10737293700006, 20.956469316000081],
              [105.10659872800005, 20.956700665000128],
              [105.10256835100009, 20.957050331000104],
              [105.09921818700009, 20.957730826],
              [105.09474046700007, 20.959362368000086],
              [105.09189558400004, 20.960612840000081],
              [105.09025426500004, 20.961557160000055],
              [105.08860330700004, 20.963145282000092],
              [105.08762413300011, 20.965627664000053],
              [105.08735041500003, 20.966750949000087],
              [105.08733839300001, 20.96755571300006],
              [105.08774250500005, 20.969170888000029],
              [105.08781731400002, 20.969896305000098],
              [105.08772207000004, 20.970538985000076],
              [105.08702018400005, 20.97165662800009],
              [105.08267116400002, 20.976106797000057],
              [105.07974517900004, 20.979891490000036],
              [105.07624010900003, 20.98515753400001],
              [105.07452552500008, 20.988045602000049],
              [105.07316855900007, 20.989799028000085],
              [105.07022653800001, 20.993719602000027],
              [105.06897565900007, 20.99578699000002],
              [105.06849771700006, 20.998073005000073],
              [105.06825717500004, 20.999320184000055],
              [105.06779802000007, 21.000356070000059],
              [105.06656275000009, 21.001381647000095],
              [105.06521659000001, 21.002405752000094],
              [105.06471881500002, 21.002813952000032],
              [105.06393956100003, 21.003012001000073],
              [105.06210743200005, 21.00267704500007],
              [105.06110485900005, 21.002976311000054],
              [105.05400803900004, 21.01027978300003],
              [105.05299596400005, 21.011204059000121],
              [105.05254930200005, 21.011406491000081],
              [105.05177315300004, 21.011396141000112],
              [105.05034907700008, 21.010230993000079],
              [105.04892028700007, 21.009378359000053],
              [105.04703537000007, 21.009353189000102],
              [105.04046199400003, 21.011349205000052],
              [105.03705566300009, 21.01291861000005],
              [105.035820152, 21.013943994000073],
              [105.03516071200006, 21.015140091000092],
              [105.03307341500002, 21.01953366700009],
              [105.03205960600008, 21.020562003000087],
              [105.03015077300006, 21.022099278],
              [105.0288106400001, 21.022706438000057],
              [105.02792196900003, 21.022798685000055],
              [105.02615259200009, 21.022462309000019],
              [105.02329345700005, 21.020860923000036],
              [105.01989907600003, 21.018001949],
              [105.01770853700006, 21.016201096000131],
              [105.01704803800004, 21.015879598000083],
              [105.01660132900003, 21.016081964000044],
              [105.01614902700008, 21.016648928000059],
              [105.01624954700006, 21.017327553000072],
              [105.01841618100008, 21.020691009000103],
              [105.018729713, 21.021945563000074],
              [105.01893076700003, 21.02330279800011],
              [105.01880075700011, 21.024551366000118],
              [105.01775496800009, 21.027663057000012],
              [105.01695962200009, 21.028902640000076],
              [105.01336172600003, 21.032084040000051],
              [105.01144464500004, 21.034141973000061],
              [105.00928976500009, 21.037238583000125],
              [105.00771487200008, 21.03867595500013],
              [105.00495283400008, 21.040234328000018],
              [105.00338807800001, 21.040775447000023],
              [105.0012367950001, 21.040884034000037],
              [105.00046747600004, 21.040873599000065],
              [105.000000082, 21.040964717000051],
              [104.9953112530001, 21.044680110000066],
              [104.987540591, 21.049149413000023],
              [104.983089885, 21.051591305],
              [104.97782721700003, 21.052692520000079],
              [104.97149200800006, 21.053153279000099],
              [104.96915746300007, 21.053355905000082],
              [104.96764200200001, 21.054429993000042],
              [104.96551090800007, 21.057607149000113],
              [104.96398442000006, 21.059384900000033],
              [104.96196328600008, 21.06084301300006],
              [104.959919402, 21.061088643000048],
              [104.95488638200003, 21.058125841000042],
              [104.93853316400001, 21.054928274000062],
              [104.93426462100005, 21.058974881000033],
              [104.92767507200008, 21.064983336000061],
              [104.92105623400009, 21.067550207000082],
              [104.913744917, 21.066509850000067],
              [104.91166362300001, 21.066480795000061],
              [104.91015765300001, 21.066928982000022],
              [104.90922327700007, 21.068088977000073],
              [104.90878593400002, 21.069412322000055],
              [104.90893382900001, 21.070587436000032],
              [104.91007085800003, 21.072401987000063],
              [104.91320231000005, 21.074478984000052],
              [104.91510479400007, 21.075287557000074],
              [104.92034494600003, 21.075673389000116],
              [104.92032270000006, 21.077080725000116],
              [104.92052550700005, 21.080055217000023],
              [104.92083135400004, 21.081779910000087],
              [104.92197473500005, 21.083203447000095],
              [104.92369848100003, 21.084791451000036],
              [104.92392850600007, 21.086045872000092],
              [104.92383042900012, 21.086982913000085],
              [104.92331234800007, 21.088148719000088],
              [104.92183119200008, 21.089653022000029],
              [104.92115150400006, 21.090503766000076],
              [104.91778638600007, 21.092646506000087],
              [104.91642695900003, 21.094347957000089],
              [104.91335885700002, 21.098762539000049],
              [104.91129354700003, 21.10295645900004],
              [104.91080520500003, 21.104865504000081],
              [104.91067234700006, 21.107991577000092],
              [104.91064258799999, 21.109867913000087],
              [104.91141317500006, 21.113203169000094],
              [104.91148169500005, 21.114727872000039],
              [104.91087275100003, 21.116361512000111],
              [104.90950069300001, 21.118844641000045],
              [104.90719514100003, 21.119789882000092],
              [104.90056795100006, 21.122746795000054],
              [104.89509397200001, 21.12650163800005],
              [104.89486633900002, 21.130330026000088],
              [104.8816779680001, 21.134640749000063],
              [104.87799482700009, 21.135761730000091],
              [104.8746209350001, 21.13837270300003],
              [104.86972648000005, 21.142291406000076],
              [104.86793351500002, 21.145002809000069],
              [104.85616952400002, 21.148706546000092],
              [104.85162656100002, 21.15145689200002],
              [104.85058032500004, 21.154335126000134],
              [104.85142192600004, 21.158960430000029],
              [104.85362084300009, 21.164660573000035],
              [104.85569809800009, 21.170163434000067],
              [104.85729714400006, 21.174330208000036],
              [104.85624834400005, 21.177364701000023],
              [104.84955716300003, 21.18153085200003],
              [104.85612274300006, 21.187722898000061],
              [104.85811069700006, 21.193615179000069],
              [104.85931177100004, 21.199222572000039],
              [104.86171524600005, 21.205198788000068],
              [104.86233007600002, 21.2084130920001],
              [104.86213572400001, 21.210130404000083],
              [104.86042030200011, 21.213155282000073],
              [104.85712499400003, 21.218542315000022],
              [104.85484008900006, 21.220699007000029],
              [104.85239608600008, 21.22238430400003],
              [104.84946730500005, 21.223124479000077],
              [104.84628348200006, 21.224173707000112],
              [104.84409691300006, 21.225393480000051],
              [104.84273718200009, 21.227015925],
              [104.84263864300002, 21.227952716000061],
              [104.84666688400007, 21.231371957000086],
              [104.85320254300002, 21.234357673000126],
              [104.8507796960001, 21.23987409600003],
              [104.85007513600007, 21.242209488000086],
              [104.84845881700001, 21.249379053000084],
              [104.84863412400004, 21.253994125000105],
              [104.84893581000004, 21.255952885000085],
              [104.84875016300005, 21.257122924000079],
              [104.84723244200006, 21.258195815000036],
              [104.84328154900001, 21.260250348000028],
              [104.84082932600006, 21.262404355000086],
              [104.83908604300007, 21.264529369000051],
              [104.83919430700004, 21.268127072000098],
              [104.84144655600007, 21.273162516000085],
              [104.84140214800003, 21.275898050000116],
              [104.83933254500003, 21.282708886000101],
              [104.83941941200003, 21.283215472000094],
              [104.83978944100008, 21.285373358000115],
              [104.84052897400008, 21.291168803000105],
              [104.84056998200001, 21.291917154000117],
              [104.84088267600004, 21.297623166000079],
              [104.84161934600006, 21.308734141000052],
              [104.84167605600004, 21.310376545000054],
              [104.84081584700002, 21.312005878000036],
              [104.84009271400004, 21.312894529000026],
              [104.83364372100007, 21.314678546000017],
              [104.8282961140001, 21.315383757000106],
              [104.82475937100003, 21.317521861000088],
              [104.81534526000004, 21.324695626000079],
              [104.81278690100001, 21.333257427000071],
              [104.8116661830001, 21.334421546000023],
              [104.81645942200004, 21.338350642000044],
              [104.81916078000008, 21.339167987000081],
              [104.82316743100003, 21.339759995000037],
              [104.82741727600003, 21.339123685000061],
              [104.83498305600004, 21.338104742000041],
              [104.84067809400007, 21.337779317000027],
              [104.84505017000006, 21.338771226000027],
              [104.84622318800004, 21.339055188000074],
              [104.84784553300007, 21.339914922000069],
              [104.84791084800005, 21.340473610000018],
              [104.84626573200009, 21.344075652000072],
              [104.84445321300005, 21.34805872900003],
              [104.84401373200011, 21.349167979000057],
              [104.84333366300007, 21.350587539000095],
              [104.84311800900007, 21.3516534870001],
              [104.84347512000004, 21.352564925000102],
              [104.84443130300011, 21.353229248],
              [104.84686091600005, 21.353240597000053],
              [104.85081201900003, 21.352692557000097],
              [104.85246604700011, 21.353134372000056],
              [104.85274302700002, 21.353637959000068],
              [104.85267609100008, 21.357773633000043],
              [104.85261383300008, 21.362385752000087],
              [104.85272551600008, 21.36544328800003],
              [104.86007380700003, 21.366430610000094],
              [104.86128230600011, 21.366064290000075],
              [104.8630502020001, 21.364067520000063],
              [104.864608816, 21.361207916000069],
              [104.86630728300003, 21.358129498],
              [104.86724572100005, 21.355284322000045],
              [104.86770141300009, 21.353931252000088],
              [104.87169757700006, 21.349037664000022],
              [104.8734680850001, 21.347633406000099],
              [104.87902496900007, 21.344341973000077],
              [104.88023888700005, 21.343627008000077],
              [104.88173541400006, 21.343067083000022],
              [104.88443793600003, 21.343058616000029],
              [104.88836753700009, 21.343834280000081],
              [104.89458273900009, 21.345095092000044],
              [104.89538848200004, 21.345873298000036],
              [104.89597819600009, 21.34682951500011],
              [104.897007703, 21.348498829000029],
              [104.89861291100003, 21.351228725000034],
              [104.89895842600002, 21.352883566000088],
              [104.89874789100001, 21.35364752400006],
              [104.89806487000007, 21.355276344000096],
              [104.89607462600007, 21.358804100000036],
              [104.89241809100004, 21.364969323000068],
              [104.88414954600006, 21.371116106000088],
              [104.88238916500006, 21.373426841000054],
              [104.88197947900001, 21.374234435000083],
              [104.88227642600006, 21.375818837000082],
              [104.88313402100006, 21.380327547],
              [104.88013525200009, 21.384084820000027],
              [104.87996487600003, 21.385430242000055],
              [104.88038886700012, 21.389142691000096],
              [104.88098706400005, 21.390498918000041],
              [104.88157455400008, 21.391750412000121],
              [104.88402482200004, 21.393597437],
              [104.88579692000012, 21.394435663000074],
              [104.88887353600003, 21.394339439000106],
              [104.89308178800006, 21.394038283000057],
              [104.89614190200004, 21.399634972000037],
              [104.89876325500003, 21.406317572000063],
              [104.89980829400008, 21.409213605000055],
              [104.90098069100002, 21.410345383000084],
              [104.90461047400005, 21.412905710000096],
              [104.90587619500006, 21.414410546000084],
              [104.90654090599999, 21.41627878000012],
              [104.90649801700008, 21.418973651000073],
              [104.90604801800006, 21.422313458000097],
              [104.90561871600008, 21.424352289000034],
              [104.90459130900007, 21.4265686520001],
              [104.90434556500007, 21.42953948400006],
              [104.90989007399999, 21.433381107000038],
              [104.9105311970001, 21.435179226000045],
              [104.91071862800007, 21.438992542000079],
              [104.91073573300005, 21.441037541000085],
              [104.90926355500002, 21.449381868000025],
              [104.90247334600005, 21.459859179000063],
              [104.89595195500004, 21.468992253000074],
              [104.894237054, 21.472290801000057],
              [104.89205250300007, 21.478579963000044],
              [104.8921300750001, 21.481485334000091],
              [104.89215590600001, 21.482972680000053],
              [104.89181886000001, 21.485430773000047],
              [104.88927809900011, 21.489159056000062],
              [104.88569077800004, 21.494708051000089],
              [104.88502718600003, 21.495860419000046],
              [104.88371905100006, 21.500070543000049],
              [104.88046637400005, 21.509480751000076],
              [104.88115974900003, 21.520386693000049],
              [104.88264815000009, 21.521964193000095],
              [104.88265333200007, 21.522450721000027],
              [104.88266795000004, 21.52382307200007],
              [104.88132710700003, 21.526963832000057],
              [104.87942471800001, 21.531072416000093],
              [104.87735326300003, 21.534740066000069],
              [104.87387935300002, 21.537940717000126],
              [104.87163828700005, 21.539378447000068],
              [104.86735935700003, 21.542123405000076],
              [104.86661694000007, 21.543530057000027],
              [104.8662683690001, 21.549751173000061],
              [104.86642894400006, 21.550566543000024],
              [104.86719749800005, 21.552157120000089],
              [104.86953167100009, 21.555163660000076],
              [104.87075141000003, 21.556481805000068],
              [104.87158611800008, 21.557051125000015],
              [104.87441493800002, 21.557090988000112],
              [104.87798071100008, 21.557605803000079],
              [104.88332792800003, 21.558470823000064],
              [104.88888549300005, 21.560151748000052],
              [104.89478158900002, 21.563951259000099],
              [104.89590228800002, 21.565267856000055],
              [104.89540362900006, 21.568513165000077],
              [104.89569677600007, 21.573465348000106],
              [104.89615003400009, 21.57616639400004],
              [104.89612478600004, 21.577745698000058],
              [104.89565796400005, 21.580550010000096],
              [104.89399683800011, 21.585079832000083],
              [104.89310025800009, 21.588365904000014],
              [104.89148483200009, 21.594684954000073],
              [104.89080883000003, 21.599693013000021],
              [104.89106639700002, 21.603761713000047],
              [104.89053800300009, 21.605752011000057],
              [104.88672479800006, 21.611319936000051],
              [104.88904470600008, 21.610632373],
              [104.892184802, 21.609886573000026],
              [104.89898205400002, 21.610213924000021],
              [104.90385068100007, 21.610049594000103],
              [104.90377659200007, 21.613137974000018],
              [104.90276592400002, 21.620464071000086],
              [104.9016366430001, 21.623838474000046],
              [104.90180227900004, 21.62709144600008],
              [104.90336359900009, 21.628900780000038],
              [104.90665691400008, 21.632717071000052],
              [104.90647797900002, 21.633003223000102],
              [104.91221090300007, 21.637080599000043],
              [104.91574639200005, 21.63937262000006],
              [104.9191842860001, 21.641131447000106],
              [104.92561892600004, 21.642121213000017],
              [104.92975210100008, 21.642538634000097],
              [104.9316643490001, 21.643375592000069],
              [104.93407543200003, 21.646155636000053],
              [104.93568049300005, 21.648159044000082],
              [104.93632443500005, 21.650059117000083],
              [104.93629458500006, 21.651949877000028],
              [104.93610036400005, 21.652469178000011],
              [104.93712387500001, 21.652796973000044],
              [104.94067283500003, 21.653933519000113],
              [104.94385544500001, 21.65597924500009],
              [104.94538407600001, 21.656919098000046],
              [104.94759436900002, 21.658201225000084],
              [104.94870870400003, 21.658837150000032],
              [104.95028184400007, 21.659872356000065],
              [104.9511323660001, 21.660654914000062],
              [104.9520320120001, 21.66187503900003],
              [104.95527237600007, 21.661106562000043],
              [104.95888642100005, 21.657091444000095],
              [104.96041570200002, 21.654580671000097],
              [104.96177017300009, 21.653716584000016],
              [104.96321593900008, 21.653364736000036],
              [104.96388553100006, 21.653420350000104],
              [104.96499946600002, 21.653644616000101],
              [104.96578919600009, 21.653957361000039],
              [104.96699300400005, 21.654786738],
              [104.97010043000003, 21.656954403000036],
              [104.97180009300006, 21.657836972000055],
              [104.97248254200012, 21.658211629000057],
              [104.97336911900003, 21.658571417000068],
              [104.97432677900004, 21.659140791000105],
              [104.97671996400007, 21.660830539000031],
              [104.97932361300012, 21.661075015],
              [104.98375514100006, 21.661948172000045],
              [104.98410734800007, 21.663253630000057],
              [104.9839488370001, 21.667060585000094],
              [104.98390484400001, 21.669092276000065],
              [104.97812516100008, 21.673449861000016],
              [104.97845041600007, 21.674092994000112],
              [104.98178468, 21.675276406000066],
              [104.98650584000009, 21.676710839000087],
              [104.98977362200003, 21.678183539000081],
              [104.99438624300002, 21.68022018900011],
              [104.99646262000005, 21.680050864000044],
              [104.99812192400003, 21.680352003000088],
              [104.99861278800006, 21.68073024300012],
              [104.99917602600003, 21.682851377000034],
              [104.99950066900007, 21.684342187000105],
              [104.99918761900001, 21.685313429000082],
              [104.99731741800008, 21.686600380000073],
              [104.99705472800011, 21.687525841000088],
              [104.9975837640001, 21.688647804000105],
              [105.00000008100008, 21.689359932000052],
              [105.00249636200004, 21.689623589000107],
              [105.00979709200006, 21.689523739000016],
              [105.01435552400001, 21.689234124000031],
              [105.01631369600003, 21.689045317000044],
              [105.0170003480001, 21.68926966100004],
              [105.01745370600003, 21.689705974000084],
              [105.0177722330001, 21.691431171000097],
              [105.01773586600011, 21.693796939],
              [105.01794601700009, 21.695090439000083],
              [105.01861947100012, 21.696175046000029],
              [105.01953447900007, 21.696509994000031],
              [105.02126603300007, 21.696102994000022],
              [105.02287599000005, 21.696124579000028],
              [105.02424934200009, 21.696573210000025],
              [105.02629951200008, 21.697891339000108],
              [105.02901168800004, 21.701046747000063],
              [105.03003516300008, 21.701813322000071],
              [105.03049515300003, 21.701819472000047],
              [105.03141680000009, 21.701724234000075],
              [105.03455960600007, 21.69929243700004],
              [105.03676418300005, 21.697203895000072],
              [105.04242812500007, 21.691837722000074],
              [105.0444690730001, 21.690515084000062],
              [105.04921612000004, 21.688418514000048],
              [105.05279629300006, 21.687116236000037],
              [105.05510935100003, 21.686876960000042],
              [105.05645214600003, 21.687164718000048],
              [105.05740329900003, 21.687897226000082],
              [105.05796280300005, 21.689074468000058],
              [105.05823096100004, 21.690427828000033],
              [105.05861033400006, 21.690792802000125],
              [105.05899244500006, 21.690977832000065],
              [105.05928107000005, 21.690981654000026],
              [105.06005619300007, 21.690631966000048],
              [105.06405247500004, 21.687265316000023],
              [105.06600735500005, 21.685883124000057],
              [105.06878467700008, 21.685251829000052],
              [105.07658219100009, 21.683267210000068],
              [105.079711473, 21.682974398000084],
              [105.08310351100009, 21.683018973000046],
              [105.08738317900007, 21.683409119000082],
              [105.08872214400002, 21.683426674000117],
              [105.09033015700001, 21.683364256000104],
              [105.09390573100009, 21.683077082000054],
              [105.09640035100009, 21.683220907000056],
              [105.09742609300005, 21.686936522000089],
              [105.097335345, 21.688534630000063],
              [105.09605785200009, 21.694965188000111],
              [105.0941483460001, 21.699688971000072],
              [105.09347365600004, 21.700844262000096],
              [105.09214542400011, 21.701565978000033],
              [105.08739811400007, 21.701873330000062],
              [105.08554114800008, 21.70271126400003],
              [105.0845969210001, 21.704177069000075],
              [105.08458396400002, 21.705039186000086],
              [105.08495505300007, 21.706645427000026],
              [105.08611277400003, 21.708508356000067],
              [105.08796028600004, 21.710207983000096],
              [105.08875231900002, 21.711939229000073],
              [105.08871228800008, 21.713135274000095],
              [105.08852566200001, 21.713596862000095],
              [105.0871055690001, 21.716161675000123],
              [105.085092671, 21.719549306000104],
              [105.0880340090001, 21.720838931000053],
              [105.08923266800007, 21.720979379000127],
              [105.09043881200003, 21.720620961000044],
              [105.09687335200005, 21.718584573000044],
              [105.09861862900004, 21.717858942000042],
              [105.09982845500005, 21.717251038000107],
              [105.10077709500011, 21.716265506000099],
              [105.10120708700006, 21.714275274],
              [105.10124622600003, 21.711656235000106],
              [105.10166873900008, 21.71016485500008],
              [105.10248023300008, 21.709426989000043],
              [105.10677456700003, 21.70773657900007],
              [105.11226741000011, 21.706186408000057],
              [105.11400695100008, 21.705834796000097],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 44, code: "AD01", ten_tinh: "Phú Yên" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.39067106600008, 12.826958963000033],
              [109.39037035500007, 12.826914880000027],
              [109.39013351700001, 12.827050146000117],
              [109.39001049800004, 12.827286840000072],
              [109.38988931800006, 12.828337921000086],
              [109.38951887700004, 12.830482931000107],
              [109.38947471600004, 12.831055340000129],
              [109.38952575500008, 12.83149116400004],
              [109.38987576300009, 12.832623175],
              [109.39044143000008, 12.834005833000093],
              [109.39100366100007, 12.834884387000043],
              [109.39138560900005, 12.835090152000104],
              [109.39173331000003, 12.835215673],
              [109.39206170600004, 12.835225272000093],
              [109.39241758300008, 12.835169159000069],
              [109.39382662200005, 12.834739766000068],
              [109.39497837200004, 12.834228038000093],
              [109.39540363200005, 12.833847129000018],
              [109.39557189500003, 12.833425905000071],
              [109.395569305, 12.833047818000052],
              [109.39530914600005, 12.832545398000049],
              [109.39450881000009, 12.831526895000065],
              [109.39239235900004, 12.829012481000033],
              [109.39067106600008, 12.826958963000033],
            ],
          ],
          [
            [
              [109.31978122400007, 13.166173402000057],
              [109.31951839599999, 13.166167036000063],
              [109.31914137400005, 13.166207924000071],
              [109.31880306700005, 13.166296831000055],
              [109.31848406000003, 13.166438321000038],
              [109.31811928000005, 13.166674932000076],
              [109.31788452200007, 13.166889719000084],
              [109.31768348600005, 13.167135164000053],
              [109.31751797300004, 13.167404882000076],
              [109.31741198400006, 13.167640667000045],
              [109.31732322900008, 13.167943127000024],
              [109.31727156000008, 13.168311377000029],
              [109.31727642800003, 13.168626069000043],
              [109.31733558300004, 13.168964278000049],
              [109.31749100900002, 13.169395103000079],
              [109.31766112500006, 13.169695178000039],
              [109.3178214980001, 13.169899328000055],
              [109.31806846800002, 13.170142728000121],
              [109.31832514300002, 13.170331915000054],
              [109.31863368200007, 13.170494389000085],
              [109.31899113200006, 13.170618811000077],
              [109.31933567700005, 13.170680626000129],
              [109.31965668200007, 13.170693948000077],
              [109.31991735800005, 13.170672762000011],
              [109.320175455, 13.170615349000057],
              [109.32047982000005, 13.170514887000094],
              [109.32076518700003, 13.170370489000048],
              [109.3210955270001, 13.170135228000033],
              [109.32137840600006, 13.169846622000046],
              [109.32156329400007, 13.16958928300007],
              [109.32171665900006, 13.169280724000119],
              [109.32184297500004, 13.168870406000098],
              [109.32188579800003, 13.168393634000084],
              [109.32185068000005, 13.168024518],
              [109.32178153200002, 13.167746459000094],
              [109.32168756200005, 13.16750583700008],
              [109.32151950500001, 13.167204694000121],
              [109.32122788000004, 13.166847969000093],
              [109.32088230200004, 13.166562667000024],
              [109.32061925900004, 13.166408477000056],
              [109.320378406, 13.166305161000123],
              [109.32006955100003, 13.16621888500009],
              [109.31978122400007, 13.166173402000057],
            ],
          ],
          [
            [
              [109.31141391900009, 13.17441028000008],
              [109.31074348600004, 13.174241256000039],
              [109.31023942700008, 13.174292803000041],
              [109.30962156300002, 13.174617958000033],
              [109.30888875800005, 13.175381337000134],
              [109.30820625500007, 13.176968112000063],
              [109.30802403300001, 13.179052027000091],
              [109.30829443300009, 13.180425609000055],
              [109.308572371, 13.180701801000081],
              [109.30907417200004, 13.180979474000052],
              [109.3099681100001, 13.181204850000048],
              [109.31086318200008, 13.181265624000014],
              [109.31142321400006, 13.181214444000132],
              [109.31187207800008, 13.181052782000078],
              [109.31198588300008, 13.180779171],
              [109.31193255500001, 13.180394719000075],
              [109.31230407400002, 13.175184343000089],
              [109.31202764500006, 13.174688683000022],
              [109.31141391900009, 13.17441028000008],
            ],
          ],
          [
            [
              [109.33538504400008, 13.276284163000083],
              [109.33222836600001, 13.276056634000087],
              [109.33020994200008, 13.276193005],
              [109.32857497900007, 13.277069132000106],
              [109.32745207200006, 13.27905529900004],
              [109.326593664, 13.282895252000058],
              [109.32585497200009, 13.285744892000116],
              [109.32599328300006, 13.287599476000088],
              [109.32701102900008, 13.288830078000034],
              [109.32840499400004, 13.289687225000078],
              [109.32941710400011, 13.290051963000058],
              [109.33017275500002, 13.289799833000069],
              [109.33117353100005, 13.288432883000032],
              [109.33242751800006, 13.287188033000062],
              [109.33481817700007, 13.286059724000053],
              [109.33884788100006, 13.284673644000032],
              [109.34010506800008, 13.28392352500005],
              [109.34047716200004, 13.282931602000046],
              [109.34020844900002, 13.28045937000004],
              [109.33943736400008, 13.278361417],
              [109.33829501800007, 13.277379067000082],
              [109.33652249100004, 13.276524380000064],
              [109.33538504400008, 13.276284163000083],
            ],
          ],
          [
            [
              [109.22986229000006, 13.655211587000069],
              [109.22728288500006, 13.650686001000077],
              [109.2244139530001, 13.646218834000122],
              [109.22370636400008, 13.644293201000044],
              [109.22369610200003, 13.642590582000123],
              [109.22644285300001, 13.636445096000037],
              [109.23125237500005, 13.626201243000045],
              [109.23670031200007, 13.616123898000064],
              [109.24140882100001, 13.608491247000119],
              [109.246239218, 13.601879399000081],
              [109.25135955200008, 13.595322514000062],
              [109.25723576600009, 13.589158332000027],
              [109.2627693960001, 13.583904104000053],
              [109.26871175800007, 13.579158099000031],
              [109.27321691500006, 13.576406936000039],
              [109.27818574600005, 13.573652890000075],
              [109.28061643200007, 13.572957192000066],
              [109.28328402400008, 13.573054589000042],
              [109.28665306700003, 13.574055749000101],
              [109.28943807300007, 13.574379371000054],
              [109.29291156400006, 13.573563681000065],
              [109.29452693000005, 13.572305258000126],
              [109.29567484799999, 13.570482138],
              [109.29612922900002, 13.569003777000075],
              [109.29610558800002, 13.568339940000039],
              [109.29603623900005, 13.568075431000091],
              [109.29549286200003, 13.567747579000054],
              [109.29461373600003, 13.567819172000027],
              [109.29110354000004, 13.569098993000049],
              [109.28901201800008, 13.570038972000107],
              [109.28772063100008, 13.569119548000062],
              [109.28696883100005, 13.567931913000029],
              [109.28662425100005, 13.566940502000067],
              [109.286754532, 13.566144916000054],
              [109.28735169400002, 13.564286770000054],
              [109.28734539700012, 13.5632933100001],
              [109.28706595200001, 13.561904120000088],
              [109.28617845900003, 13.560651073000061],
              [109.28461358600005, 13.559269669000072],
              [109.28338994300006, 13.558349807000079],
              [109.28125527700008, 13.557799732000129],
              [109.27936095300002, 13.557811162000098],
              [109.27841254100007, 13.557618175000117],
              [109.27732507000006, 13.55682993300009],
              [109.27629693900008, 13.554716678000121],
              [109.27533066000007, 13.551675796000049],
              [109.274699755, 13.548169272000086],
              [109.274655755, 13.546546841000049],
              [109.27530356200005, 13.541972934000055],
              [109.27601358900009, 13.536537656000023],
              [109.27648295800002, 13.535872516000056],
              [109.2775594820001, 13.534938794000071],
              [109.27998808100004, 13.533864450000086],
              [109.28207843500007, 13.53279211500009],
              [109.28295490500003, 13.532323201000017],
              [109.28349314400006, 13.531856321000111],
              [109.28348812200009, 13.531061573000079],
              [109.28233145400004, 13.530008855000107],
              [109.28130884700008, 13.52875664100006],
              [109.28049167600005, 13.527900563000104],
              [109.27974467000007, 13.527441455000131],
              [109.277445598, 13.52758777300007],
              [109.27522123799999, 13.528859545000048],
              [109.27333465700012, 13.53006304100006],
              [109.27158375300012, 13.531331943000067],
              [109.270071043, 13.532831210000039],
              [109.26960207400002, 13.533562568000066],
              [109.26960580000005, 13.534158635],
              [109.26988136000003, 13.534951765000079],
              [109.27083504500007, 13.536005761000068],
              [109.27192485900009, 13.537191401000113],
              [109.27240627500004, 13.538446922000078],
              [109.27254696800006, 13.539307087000017],
              [109.27262084200004, 13.540300126000114],
              [109.27236808200009, 13.54314961900007],
              [109.27204186600009, 13.545072299000081],
              [109.27149523900007, 13.549612485000038],
              [109.27137445300011, 13.551931344000055],
              [109.27104696000002, 13.553655349000058],
              [109.27044886100001, 13.555380975000018],
              [109.26944937300009, 13.557837567000062],
              [109.26800906700001, 13.560131212000035],
              [109.26740512800005, 13.560929613000015],
              [109.26619146200008, 13.561599186000082],
              [109.26409330700002, 13.561479230000105],
              [109.26212759600001, 13.560894832000068],
              [109.26111194000001, 13.560768405000088],
              [109.2607064200001, 13.560837052000084],
              [109.26016723, 13.561171414000038],
              [109.25949888500003, 13.562500038000012],
              [109.25843240100008, 13.565089451000032],
              [109.257429028, 13.566949922000047],
              [109.2555505870001, 13.56954413800011],
              [109.25454102900007, 13.570411144000081],
              [109.25309229300009, 13.571380083000049],
              [109.25160705900004, 13.571918713000029],
              [109.24991680699999, 13.572127380000063],
              [109.24829379500008, 13.5722694],
              [109.24734778500006, 13.572473658000115],
              [109.24667323000003, 13.572808793000133],
              [109.24620286500001, 13.573341421000061],
              [109.244998385, 13.575534195000037],
              [109.24426508100008, 13.577326801000121],
              [109.24393489300012, 13.578653406000017],
              [109.24327895500002, 13.582035166000049],
              [109.24255250100005, 13.584953703000034],
              [109.24178794200003, 13.587177012000049],
              [109.24038519700005, 13.59016574600007],
              [109.2393811310001, 13.591959935000107],
              [109.23844027200006, 13.593025173000049],
              [109.23655489400002, 13.594559547000083],
              [109.23453495600005, 13.596227165000039],
              [109.23298584400004, 13.597428387000042],
              [109.23160809700009, 13.598992896000082],
              [109.23013103000008, 13.600922264000113],
              [109.22906155500006, 13.603114192000108],
              [109.2283996890001, 13.605568707000069],
              [109.2273349750001, 13.608555432000101],
              [109.22606641800004, 13.611410865000016],
              [109.22503247900002, 13.613900646000053],
              [109.22430365300004, 13.616488022000082],
              [109.223574399, 13.619009162000049],
              [109.22324879600006, 13.621130566000071],
              [109.2229992420001, 13.624642481000125],
              [109.22278052800004, 13.627657458],
              [109.22199190700007, 13.631569906000072],
              [109.22126736100007, 13.634885865000077],
              [109.22060568700005, 13.637406644000103],
              [109.22037577500002, 13.638567097000108],
              [109.21976982300006, 13.639100468],
              [109.21895844199999, 13.639237601000074],
              [109.21787235400009, 13.63871394800009],
              [109.21610430600001, 13.63733312500006],
              [109.21283622000006, 13.634106261000055],
              [109.21188237500009, 13.633051937000038],
              [109.21127089400004, 13.63265801200013],
              [109.21066059100008, 13.632462792000053],
              [109.21011954900007, 13.632532108000104],
              [109.20964737200009, 13.632799742000133],
              [109.20938218100008, 13.633728554000067],
              [109.20925510300005, 13.635120234000018],
              [109.20933304200001, 13.636841923000096],
              [109.210022092, 13.638891315000111],
              [109.21084532900008, 13.640741230000051],
              [109.21105548200006, 13.641932281000011],
              [109.21119993800002, 13.643454892000049],
              [109.21047004000012, 13.645909801000043],
              [109.20960356800001, 13.648166776],
              [109.20869988100003, 13.649860954000113],
              [109.20742134000002, 13.651126728000039],
              [109.20559933500007, 13.652064395000084],
              [109.20465251800007, 13.65220224300012],
              [109.20404217200011, 13.652007001000042],
              [109.20356640700001, 13.651678512000041],
              [109.20288365800009, 13.650688830000133],
              [109.20233471900006, 13.649433452],
              [109.20197943400007, 13.646587296000055],
              [109.20176650400003, 13.639203159000067],
              [109.20161349700007, 13.63622341300008],
              [109.20112014000001, 13.632914407000028],
              [109.20084041500006, 13.631392573000054],
              [109.20022464500009, 13.63027004600008],
              [109.19927010100007, 13.629083201000112],
              [109.19763996600001, 13.628098867000025],
              [109.19580915000006, 13.627513075000051],
              [109.19519731800006, 13.627052868000076],
              [109.194648101, 13.62573125100006],
              [109.19374688100004, 13.622093374000064],
              [109.1926140780001, 13.619350979000087],
              [109.19206296400007, 13.617698193000114],
              [109.19226014900005, 13.616703565000064],
              [109.19300184600004, 13.616235747000081],
              [109.1939504420001, 13.616429123],
              [109.19951576700005, 13.619113377000057],
              [109.20203189100005, 13.621152437000104],
              [109.2033228380001, 13.622006185000133],
              [109.2044756810001, 13.622397058000056],
              [109.2058949120001, 13.622057818000053],
              [109.20744791700008, 13.621452877000094],
              [109.21000893300007, 13.619649937000043],
              [109.21189434900002, 13.618049536000052],
              [109.21928884500007, 13.609727804000025],
              [109.22154011, 13.607098585000086],
              [109.22388943000004, 13.603905790000058],
              [109.22761247300006, 13.598486171000078],
              [109.22935661200005, 13.59595917300007],
              [109.23049932400001, 13.594694097000025],
              [109.23298892200003, 13.592361450000022],
              [109.23500802000002, 13.590561395000032],
              [109.23594687000008, 13.589165012000047],
              [109.23708346000006, 13.586906438000044],
              [109.23761387600007, 13.585115029000059],
              [109.23783757300002, 13.582961122000102],
              [109.2378793980001, 13.578721930000091],
              [109.23779518400006, 13.576006847000064],
              [109.23799130600004, 13.574879732000065],
              [109.23933884900006, 13.573944600000052],
              [109.24290982000012, 13.571473090000017],
              [109.24378330100009, 13.570474471000081],
              [109.24438492700006, 13.569278748000089],
              [109.24437437600007, 13.567556751000074],
              [109.24353815300006, 13.56358767800012],
              [109.24249534700002, 13.559023725000062],
              [109.24249129500004, 13.558361413000062],
              [109.24315851200001, 13.556834161000085],
              [109.24416602300005, 13.555636071000075],
              [109.24538135900008, 13.555231547000089],
              [109.24781606699999, 13.555084778000024],
              [109.25126803400006, 13.555329380000012],
              [109.25736136900009, 13.556021914000025],
              [109.26054397500008, 13.556466663000053],
              [109.26325179000004, 13.556715490000036],
              [109.26453721399999, 13.556707833000113],
              [109.26575374500001, 13.556501878000036],
              [109.26710310500002, 13.555897736000054],
              [109.26757213500011, 13.555166373000114],
              [109.26763276200006, 13.554040054],
              [109.26728995300002, 13.553313549000022],
              [109.26634239900005, 13.553252969000072],
              [109.26472202400001, 13.553792493000037],
              [109.26303070100005, 13.553802567000071],
              [109.26093057200009, 13.553351435000048],
              [109.25828677600001, 13.552506104000093],
              [109.25557248200005, 13.551197536000091],
              [109.25353473600002, 13.549884939000021],
              [109.25230720600007, 13.548302614000105],
              [109.25155160900007, 13.546452573000066],
              [109.25054845800003, 13.542848837000053],
              [109.24916124500001, 13.537293528000037],
              [109.24679061000006, 13.531313520000053],
              [109.24541901800008, 13.528274925000082],
              [109.24500340900006, 13.526687814000036],
              [109.24506658900005, 13.525958896000121],
              [109.245669284, 13.524961896000052],
              [109.24667906100007, 13.52416119100006],
              [109.24843371300003, 13.523488563000095],
              [109.25096482300002, 13.52257953200011],
              [109.25839485200007, 13.5208135860001],
              [109.26237997900003, 13.519862669000114],
              [109.26514751700003, 13.518918951000012],
              [109.26818228100005, 13.517443753000078],
              [109.27235818300007, 13.514570823000048],
              [109.27539034400003, 13.51269815700009],
              [109.27734522500005, 13.511626706000078],
              [109.27926854900002, 13.510919698000111],
              [109.28143007100007, 13.510443044000018],
              [109.28379867900003, 13.510627411000085],
              [109.28522160500009, 13.5110161790001],
              [109.28806999400004, 13.512191055000054],
              [109.28976938100004, 13.513505344000086],
              [109.29146921000009, 13.51488585300009],
              [109.29242375600002, 13.516072194000104],
              [109.29263384600009, 13.517196843000077],
              [109.29257463199998, 13.518521822000105],
              [109.2921133610001, 13.520445334],
              [109.29124412100003, 13.52204017200007],
              [109.28936316400009, 13.5241047800001],
              [109.28802038100011, 13.525702485000044],
              [109.28788887000006, 13.526299361000047],
              [109.28795987500007, 13.526828780000065],
              [109.28864262200008, 13.527818108000121],
              [109.28932284400005, 13.528410062000072],
              [109.29040726400007, 13.528734622000103],
              [109.29135260600003, 13.528463945000089],
              [109.29290253300012, 13.527527266000037],
              [109.29492087700004, 13.525792944000068],
              [109.29700218900004, 13.523329671000093],
              [109.29800667700002, 13.521733981000049],
              [109.29866954500005, 13.519610532000035],
              [109.29886019300002, 13.517688663000092],
              [109.2993108040001, 13.514109430000063],
              [109.30016638200001, 13.510395271000062],
              [109.3002897910001, 13.508540060000128],
              [109.30031873600009, 13.507778230000065],
              [109.29984018600001, 13.506986397],
              [109.29943309200009, 13.506790204000117],
              [109.29868907600007, 13.50679476000005],
              [109.29808161500009, 13.506997165000099],
              [109.29761238400008, 13.507662340000069],
              [109.29681679100005, 13.510183964000076],
              [109.29615013100006, 13.511711346000064],
              [109.29574852300004, 13.512376106000088],
              [109.29487090200008, 13.512646387000069],
              [109.29385462400002, 13.512387664000068],
              [109.29256610000007, 13.511865674000132],
              [109.29113936500006, 13.510880907000098],
              [109.28943831000001, 13.509301711000086],
              [109.28828006500001, 13.507984138000049],
              [109.28711679000004, 13.505871826000069],
              [109.28649589, 13.503954909000091],
              [109.28614764700005, 13.502367495000021],
              [109.28607036600005, 13.500844665000054],
              [109.28612290800005, 13.498460064000104],
              [109.28651487400006, 13.496272094000082],
              [109.2874084150001, 13.493186978000081],
              [109.28873880400005, 13.489668731000036],
              [109.29047324600002, 13.485883098000071],
              [109.29241009300004, 13.482029994000031],
              [109.29451529800006, 13.478076517000124],
              [109.29585350100002, 13.475816558000096],
              [109.29759784300003, 13.473620334000101],
              [109.29954418200003, 13.471290404000056],
              [109.30172737000002, 13.468992127000055],
              [109.30401386000003, 13.466991223000056],
              [109.30569807500002, 13.465987428000046],
              [109.30745203800011, 13.465314338000043],
              [109.31103351800006, 13.464894860000063],
              [109.31299588200007, 13.465081403000044],
              [109.314961678, 13.465797744000072],
              [109.31652559900007, 13.467112615000064],
              [109.31720914400006, 13.468234264000055],
              [109.31817221500008, 13.470744974000047],
              [109.31907413100001, 13.474249504000023],
              [109.31935151400003, 13.475307443000071],
              [109.31969524800006, 13.476166287000078],
              [109.3203064680001, 13.476559858000046],
              [109.320779859, 13.476556911000069],
              [109.32199155300007, 13.47568838000006],
              [109.32380712300002, 13.474087560000035],
              [109.32518460900003, 13.472721268000052],
              [109.32618905700011, 13.471191721000062],
              [109.327522092, 13.46820308200005],
              [109.32845151400007, 13.465548116000024],
              [109.328552652, 13.464816208000039],
              [109.32850772900011, 13.464178043000084],
              [109.32830089100004, 13.463700506000096],
              [109.32797311300001, 13.46342324100007],
              [109.32723818500004, 13.463188425000093],
              [109.32613734000009, 13.463075598000083],
              [109.32552750600007, 13.463278925000031],
              [109.32443342400006, 13.464203523000061],
              [109.3235837980001, 13.465126599000053],
              [109.32305725400005, 13.465608717000064],
              [109.32220294200008, 13.465813559000049],
              [109.32102086500005, 13.465741113000094],
              [109.32008247800003, 13.465547440000057],
              [109.31889833200003, 13.46515578500007],
              [109.318079063, 13.464482522000052],
              [109.31713472600003, 13.463371110000109],
              [109.31627007700007, 13.461979883000106],
              [109.31523963500008, 13.46015075300002],
              [109.31445339200005, 13.458280196000059],
              [109.31403539200001, 13.456646782000091],
              [109.31394461700005, 13.455210856000031],
              [109.31405806600002, 13.453853468000075],
              [109.31457479100006, 13.451855141000111],
              [109.31561802900003, 13.449374714000049],
              [109.31688879600003, 13.447431570000033],
              [109.317979198, 13.445948407000062],
              [109.31834144800004, 13.44526781700006],
              [109.31833860000008, 13.444828910000071],
              [109.31821276000005, 13.44427105600001],
              [109.31780303800008, 13.443914487000084],
              [109.31678379200004, 13.443801107000029],
              [109.31564232300009, 13.443688489000037],
              [109.31376627200004, 13.443380895000077],
              [109.31237934500004, 13.443110169000059],
              [109.31127629900007, 13.442638166000085],
              [109.31033468300005, 13.441925740000025],
              [109.30939024600008, 13.440774412000065],
              [109.30877222800009, 13.439700867000058],
              [109.30835508200005, 13.438187160000046],
              [109.30830253000005, 13.436351992000082],
              [109.30868703600007, 13.432798344000039],
              [109.30887246400005, 13.429964164000106],
              [109.30877943400007, 13.42816915700009],
              [109.30860415300005, 13.42625495000007],
              [109.30861052000003, 13.424080275000081],
              [109.30883745200008, 13.421365555000085],
              [109.30907183200001, 13.419807947000082],
              [109.30942610400011, 13.417890483000059],
              [109.30964384400009, 13.416911555000064],
              [109.30967968800005, 13.416153209000113],
              [109.30951366400006, 13.415675410000103],
              [109.30914526900006, 13.415398376],
              [109.30837031100006, 13.415243551000076],
              [109.30743395700003, 13.415329134000052],
              [109.30393601200007, 13.416148695000095],
              [109.30259334800009, 13.41639636300007],
              [109.30177841900007, 13.416361459000113],
              [109.30067606400007, 13.415969215000077],
              [109.29940771300002, 13.415099159000119],
              [109.29862893899998, 13.414345797000047],
              [109.29792933600001, 13.413232842000065],
              [109.29763886200004, 13.412396689000071],
              [109.29747006700002, 13.411479993000073],
              [109.29754567500007, 13.41056180100013],
              [109.297698418, 13.408964827000068],
              [109.29773099300003, 13.407687786000098],
              [109.29752223800008, 13.406891036000077],
              [109.29686671400002, 13.406296535000093],
              [109.29621424800004, 13.406180816000111],
              [109.29458550000001, 13.406270574000077],
              [109.29421916500007, 13.406312710000099],
              [109.29377264500008, 13.406554841000061],
              [109.29300280900006, 13.40719795600007],
              [109.29199186900009, 13.408361251000132],
              [109.29142644300002, 13.409122815000083],
              [109.29090503600004, 13.410402832000033],
              [109.29088834200007, 13.41098151500004],
              [109.29109910300011, 13.412097468000093],
              [109.29147153400001, 13.413012930000077],
              [109.29200638900004, 13.413847602000127],
              [109.29221360700004, 13.414404954000057],
              [109.29209495100008, 13.414964301000063],
              [109.2915712600001, 13.415885223000055],
              [109.29068196200006, 13.416967978000025],
              [109.29003478400007, 13.417690140000092],
              [109.28918190600011, 13.418094343000051],
              [109.28840973500007, 13.418378344000102],
              [109.28708499800007, 13.418246739000049],
              [109.28581871100002, 13.417695795000027],
              [109.28161407400002, 13.416284799000076],
              [109.279126569, 13.415860902000086],
              [109.26731956200007, 13.413479135000015],
              [109.26644874500005, 13.41348433700009],
              [109.26582748900007, 13.413609912000089],
              [109.25694888300006, 13.426336500000067],
              [109.25633813800007, 13.42816808100009],
              [109.25634341200006, 13.429021098000058],
              [109.25697375800009, 13.430357861000084],
              [109.26085853700008, 13.434843781000081],
              [109.26235531200003, 13.435444204000111],
              [109.26484514900007, 13.435673095000043],
              [109.26745560600006, 13.435291918000035],
              [109.27006300700005, 13.434423267000016],
              [109.27130716500005, 13.434415803000109],
              [109.27280243100004, 13.43477243600004],
              [109.27467630600002, 13.435979828000109],
              [109.27556175400009, 13.438289932000112],
              [109.27619532500003, 13.440114090000053],
              [109.27722218700008, 13.441242561000076],
              [109.28098746000005, 13.443936534000043],
              [109.28497308400003, 13.445894882000074],
              [109.28700317400005, 13.44683708800005],
              [109.28888801400004, 13.448514397000098],
              [109.29009911100006, 13.450342649000023],
              [109.29093587500002, 13.452246595000082],
              [109.29072823000007, 13.454964562000081],
              [109.290217442, 13.457170414000073],
              [109.28947707400002, 13.458643412000091],
              [109.28828827000007, 13.460339413000087],
              [109.28560375200009, 13.462631875000051],
              [109.28396227800008, 13.463890047000058],
              [109.28250196300003, 13.464155873000026],
              [109.280777621, 13.464166292000089],
              [109.27920229100005, 13.464028948000093],
              [109.27822858600004, 13.464181669000117],
              [109.27718083500012, 13.464481682000072],
              [109.27561289000006, 13.465519066000107],
              [109.27442485100008, 13.467361844000079],
              [109.27227363200008, 13.471046028000087],
              [109.26944939900008, 13.475027922000036],
              [109.26800089100004, 13.477202649000029],
              [109.26658458900002, 13.478532773000039],
              [109.26486835200004, 13.479864677000107],
              [109.26270085500002, 13.480978981000112],
              [109.26045471500007, 13.481506319000045],
              [109.25678352300007, 13.481968660000108],
              [109.25446238100004, 13.482496380000073],
              [109.25199488700007, 13.483612349000039],
              [109.24997814500009, 13.484872488000031],
              [109.24863618700007, 13.486128638000048],
              [109.24700106400006, 13.488487890000032],
              [109.24481050600009, 13.49198851000004],
              [109.24376750600004, 13.493096027000105],
              [109.24182155900004, 13.493694842000091],
              [109.24024693200006, 13.493704054000053],
              [109.23904586900008, 13.493490797000083],
              [109.23821793400005, 13.492981638000055],
              [109.23753772500008, 13.492104494000044],
              [109.23737971700007, 13.490783743000099],
              [109.23684814300005, 13.489685446000086],
              [109.23526460900003, 13.488226149000047],
              [109.2339859300001, 13.487572756000075],
              [109.23158434500007, 13.487219575000074],
              [109.23008652400004, 13.487521972000048],
              [109.228287881, 13.487679248000093],
              [109.22686060400012, 13.487246948000029],
              [109.22565472000008, 13.486225956000018],
              [109.22541088700007, 13.485263135000112],
              [109.22571864300005, 13.483000970000079],
              [109.22629331900008, 13.481395349000101],
              [109.22727806200002, 13.479959037000098],
              [109.22919565200006, 13.478173967000105],
              [109.23070625600008, 13.476734585000115],
              [109.23157234800006, 13.475012819000119],
              [109.2317393160001, 13.473638462000091],
              [109.23161377300002, 13.472208581000087],
              [109.23102182900007, 13.470953078000035],
              [109.22954926900009, 13.469015985000075],
              [109.227491398, 13.466910604000047],
              [109.22549027300008, 13.46451875400003],
              [109.22407245800008, 13.461951855000097],
              [109.22247042700005, 13.457898195000071],
              [109.22128330300006, 13.454814939000046],
              [109.21974565800011, 13.4517337080001],
              [109.21802891900009, 13.44848244100003],
              [109.21947598600009, 13.444344970000087],
              [109.22059350000009, 13.441714926000079],
              [109.22375260200009, 13.435944960000066],
              [109.22528148200011, 13.43321163100004],
              [109.22702428499998, 13.431788851000091],
              [109.2282568520001, 13.431176270000032],
              [109.22990514600005, 13.431166718000041],
              [109.23196979300008, 13.431861081000116],
              [109.23320908200003, 13.432358414000054],
              [109.23454833500008, 13.432350622000031],
              [109.23588328200006, 13.431636499000097],
              [109.23675310400009, 13.43067281000012],
              [109.23701150900006, 13.429741553000072],
              [109.23677618300005, 13.429231140000029],
              [109.23595824100005, 13.428382942000015],
              [109.23140869300002, 13.424883802000041],
              [109.23059457900007, 13.424661065000086],
              [109.2298412750001, 13.424892897000083],
              [109.22868537800011, 13.425752561000069],
              [109.22753015800001, 13.42672595200005],
              [109.22671810900005, 13.426844378000078],
              [109.22601906700004, 13.426450371000074],
              [109.21994604800008, 13.420571483000019],
              [109.21866039300002, 13.419157274000069],
              [109.21807374200006, 13.418137081000035],
              [109.21800956200008, 13.417113897000108],
              [109.21881578000009, 13.416028845000126],
              [109.22026361800008, 13.415451880000083],
              [109.22223595000001, 13.41521306000004],
              [109.2251964970001, 13.415195976000048],
              [109.22682085500003, 13.415015992000077],
              [109.22797704200003, 13.41421320100009],
              [109.22872687000009, 13.413412767000031],
              [109.22929911400009, 13.41204470600008],
              [109.22935131000006, 13.411077721000096],
              [109.22860903300001, 13.40832412300008],
              [109.22784238, 13.406338329000087],
              [109.22695313700005, 13.403272835000022],
              [109.22641012800005, 13.399864158000073],
              [109.22608418900009, 13.398757202000054],
              [109.22544054900004, 13.397907975000043],
              [109.22485804800007, 13.39757015800007],
              [109.22253625000008, 13.397583556000113],
              [109.22120293400008, 13.397875555000025],
              [109.21963946800003, 13.398510052000033],
              [109.21779225100005, 13.400226575000127],
              [109.21497045500007, 13.403995763000063],
              [109.21387606100002, 13.405423617000077],
              [109.21335703000007, 13.405995231000121],
              [109.21289434900007, 13.406282195000061],
              [109.21202227900002, 13.406059722000039],
              [109.21149545400004, 13.405323505000053],
              [109.21148867500003, 13.404186266000105],
              [109.21154077400007, 13.403757680000082],
              [109.2116097910001, 13.401953713000035],
              [109.21225627800003, 13.398206049000104],
              [109.21272003400003, 13.395863422000074],
              [109.213763177, 13.394547064000049],
              [109.21573405300011, 13.393323709000081],
              [109.22017110600005, 13.390375542000042],
              [109.22115646800005, 13.390142412000026],
              [109.22278131300001, 13.390076191000054],
              [109.22452533299999, 13.390521031000089],
              [109.22691127400005, 13.391530774000058],
              [109.22923952700005, 13.39259769100005],
              [109.23063669700009, 13.393271950000061],
              [109.23208985500007, 13.393604693000059],
              [109.23330877700002, 13.393597607000094],
              [109.23562637000003, 13.392901751000073],
              [109.23956222400008, 13.391059157000116],
              [109.24129830100001, 13.390196049000023],
              [109.24210811900005, 13.389736410000095],
              [109.24256794800007, 13.388994497000082],
              [109.24293010000011, 13.386518830000059],
              [109.24329986400006, 13.380546059000052],
              [109.24351741700004, 13.378156544000051],
              [109.24417042400006, 13.37579291000006],
              [109.24525504100009, 13.372829699000109],
              [109.24640080400005, 13.370377874000015],
              [109.24789858900007, 13.368549470000078],
              [109.25023455899999, 13.366175931000067],
              [109.25381488500003, 13.363254820000046],
              [109.25583281100009, 13.361606487000087],
              [109.25740666600002, 13.360645820000093],
              [109.25883192800009, 13.360009650000036],
              [109.26006124700011, 13.359567782000068],
              [109.26079655600002, 13.358935699000083],
              [109.26099038300006, 13.358403399000094],
              [109.26103666799999, 13.357920267000088],
              [109.26088493300004, 13.357293459000095],
              [109.26034076500005, 13.356958703000108],
              [109.25965024500003, 13.356866234000096],
              [109.25856579300009, 13.356824383000097],
              [109.25684279000009, 13.357124323000045],
              [109.25455529400008, 13.357789719000033],
              [109.25332775200005, 13.358521263000076],
              [109.25200551400006, 13.359881071000055],
              [109.24975344800006, 13.362308634],
              [109.24833170900011, 13.363524154000038],
              [109.24651451300004, 13.364548843000088],
              [109.24440101300004, 13.36547869300002],
              [109.24272837900003, 13.365971353000104],
              [109.24115194300006, 13.366077161000065],
              [109.23964869300003, 13.366061810000039],
              [109.23836618500006, 13.365876157000043],
              [109.23732891400003, 13.365495928000113],
              [109.2366224190001, 13.364897510000072],
              [109.23613997400007, 13.3644888650001],
              [109.23539517799999, 13.363575782000046],
              [109.23514554100004, 13.363046088000095],
              [109.23464040700007, 13.361021037000052],
              [109.23438608400008, 13.359718804000041],
              [109.23378534500003, 13.358177169000028],
              [109.23283906000006, 13.356540979],
              [109.23174436500008, 13.35480906800006],
              [109.23094859000005, 13.35360655400004],
              [109.22995423900002, 13.352163764000034],
              [109.23276518200008, 13.352485428000092],
              [109.23483724000002, 13.352859651000058],
              [109.23557994000001, 13.353434741000035],
              [109.23647166700007, 13.354202115000112],
              [109.23746959300004, 13.356224275],
              [109.23851329800006, 13.357666746000072],
              [109.23985239900001, 13.359059196000088],
              [109.24185459800003, 13.360085609000103],
              [109.24279181200004, 13.360224973000088],
              [109.24333390200005, 13.360221794],
              [109.24426698000006, 13.359685182000101],
              [109.24549189000007, 13.358519140000078],
              [109.24745302600006, 13.356865897000068],
              [109.24946252000007, 13.355067507000038],
              [109.25132651900002, 13.353656240000065],
              [109.25331502900005, 13.352461495000066],
              [109.25581353700001, 13.351786527000085],
              [109.259370808, 13.351105234000025],
              [109.26110277100004, 13.351000632000074],
              [109.26172436800005, 13.351233294000052],
              [109.26217524300004, 13.351758084000041],
              [109.26298178800008, 13.353820751000088],
              [109.26462673600003, 13.357388302000038],
              [109.26667396300004, 13.361525843000075],
              [109.26754882300004, 13.363999319000095],
              [109.26812062200001, 13.365011367000077],
              [109.26878786200007, 13.365497893000098],
              [109.2698585910001, 13.365849788000082],
              [109.27289401300007, 13.366350311000106],
              [109.27378870000003, 13.366333852000077],
              [109.27472176700005, 13.366150705000022],
              [109.27721681600008, 13.364909617000096],
              [109.27990910300008, 13.364421816000059],
              [109.28201082099999, 13.364580649000056],
              [109.28250987900006, 13.364689032000053],
              [109.28501857600007, 13.365616982000093],
              [109.2864401850001, 13.366191574000117],
              [109.28704480300003, 13.366359199000042],
              [109.28786339900009, 13.366408837000064],
              [109.28896999900007, 13.366347504000052],
              [109.29020983700008, 13.366270598000083],
              [109.29122616200006, 13.366132054000078],
              [109.29164794300004, 13.366036618000086],
              [109.29191415200005, 13.365875919000112],
              [109.292288093, 13.365642298000056],
              [109.29263598600008, 13.365355324000026],
              [109.29281199300009, 13.365003722000067],
              [109.29307789700006, 13.364569916000056],
              [109.29328608800006, 13.363869066000065],
              [109.29401773100001, 13.357828575000051],
              [109.29400926800002, 13.357415325000053],
              [109.29381861600007, 13.356792348000022],
              [109.2934445490001, 13.356374857000029],
              [109.29294752000001, 13.356043160000029],
              [109.29169440800007, 13.355767859000077],
              [109.29061710300002, 13.355471314000054],
              [109.29002568400003, 13.355083048000081],
              [109.28985958200005, 13.35483590500006],
              [109.28924371200007, 13.353989493000105],
              [109.28906346400008, 13.353568053000052],
              [109.289006742, 13.352623897000091],
              [109.28925633500005, 13.350784136000103],
              [109.29001079300004, 13.348327430000049],
              [109.291008549, 13.346293645000083],
              [109.29158065800007, 13.345441357000086],
              [109.29210353500008, 13.344949691000084],
              [109.29282530900012, 13.34439633800004],
              [109.29484174000007, 13.343629536000011],
              [109.29589687200007, 13.343057216000085],
              [109.29632388400005, 13.34246329100006],
              [109.29646714700009, 13.341921992000037],
              [109.2972154670001, 13.338522190000022],
              [109.29748915900009, 13.33616273400011],
              [109.29745903600009, 13.335597945000098],
              [109.29709755700003, 13.335127704000092],
              [109.29641837100004, 13.334893580000083],
              [109.29501878600007, 13.334775218000059],
              [109.29406036299999, 13.3347810710001],
              [109.29314971200004, 13.334868888000083],
              [109.29248171200003, 13.335019156000081],
              [109.291709634, 13.335443537000023],
              [109.28912119100006, 13.337062581000046],
              [109.28734560100006, 13.337994839000087],
              [109.28489650200001, 13.338691527],
              [109.2834528010001, 13.338700273000102],
              [109.281717981, 13.338333518000043],
              [109.27949303200003, 13.336555049000063],
              [109.27842306000005, 13.334769588000126],
              [109.27773571300003, 13.33260457900005],
              [109.27713573500003, 13.32902437600003],
              [109.27705296200003, 13.323507702000102],
              [109.27741065200004, 13.319167280000064],
              [109.27816395000011, 13.316522053000044],
              [109.2805264940001, 13.309623188000062],
              [109.28303649000003, 13.303242140000025],
              [109.28590396000006, 13.296322325000085],
              [109.28753784100006, 13.292227885000061],
              [109.28886765000004, 13.289484866000032],
              [109.28905232500004, 13.288257739000107],
              [109.28875528800008, 13.286939231000082],
              [109.28759644100002, 13.286286122000133],
              [109.28634436300007, 13.286105103000077],
              [109.28519268100008, 13.286583637000058],
              [109.28431695100002, 13.287605996000094],
              [109.28228869800006, 13.290405884000032],
              [109.28065990400003, 13.293252251000085],
              [109.27928332400009, 13.296537244000104],
              [109.27815437000002, 13.299527302000056],
              [109.27739365000005, 13.301561487000056],
              [109.27630963400007, 13.303768779000078],
              [109.27542029300002, 13.305192409000098],
              [109.27428325500009, 13.306910959000094],
              [109.27356503200008, 13.307771132000122],
              [109.272324181, 13.308854517000055],
              [109.27118374300005, 13.310035113000128],
              [109.26969853800009, 13.311951355000049],
              [109.26737651500008, 13.315731034000054],
              [109.26668424800009, 13.316762205000108],
              [109.26553976600007, 13.317307015000067],
              [109.26454317900004, 13.317557492],
              [109.26324502700001, 13.317467420000105],
              [109.26194384000009, 13.31688829000006],
              [109.25998783000007, 13.315334920000053],
              [109.25837875900007, 13.313388227000098],
              [109.25726905900009, 13.311487470000126],
              [109.25691308200004, 13.310413647000095],
              [109.25707973100006, 13.309116650000073],
              [109.25762322600009, 13.308233117000066],
              [109.25960633200003, 13.306118385000063],
              [109.26198435700006, 13.303316601000057],
              [109.26331986499999, 13.301401317000037],
              [109.26425583400007, 13.299439515000076],
              [109.26479530900012, 13.297920225000047],
              [109.26483760700006, 13.296697324000116],
              [109.264681212, 13.295622335000077],
              [109.26382409800004, 13.294209181000037],
              [109.26287113800007, 13.293432380000068],
              [109.26101666000002, 13.292122967000084],
              [109.25981392300007, 13.29129872500001],
              [109.25851351800003, 13.290817401000064],
              [109.25671594600007, 13.290632438000037],
              [109.25397331100007, 13.29094212000008],
              [109.25113117300002, 13.291301269000131],
              [109.24848591400009, 13.291219048000078],
              [109.24688824000005, 13.291081738000086],
              [109.24623268400008, 13.290712069000053],
              [109.24608626600005, 13.290499595000059],
              [109.24648365900006, 13.290203820000011],
              [109.24842340900005, 13.289165392000067],
              [109.25046323500008, 13.288175260000033],
              [109.25205546900003, 13.287432279000109],
              [109.25345050500007, 13.287081700000057],
              [109.25624419000012, 13.2869673590001],
              [109.25818961800006, 13.286858010000023],
              [109.25928555300005, 13.286558073000075],
              [109.25973160900001, 13.286066365000069],
              [109.26017522900003, 13.285183433000023],
              [109.26051815200007, 13.284154385000056],
              [109.26051451400004, 13.283567541000084],
              [109.26036088000004, 13.282932694000047],
              [109.25975756500003, 13.282202702000044],
              [109.25800573200003, 13.281332815000011],
              [109.25650399100003, 13.280559240000043],
              [109.25535033500006, 13.279587976000061],
              [109.25474735900008, 13.278906874000105],
              [109.25449062800007, 13.277734677000106],
              [109.25445406800006, 13.275852054000035],
              [109.25449279400007, 13.274042345000046],
              [109.25488078700002, 13.272230571000064],
              [109.25517442100006, 13.271299642000132],
              [109.25586871500005, 13.270610870000075],
              [109.25666551100008, 13.270361616000057],
              [109.25761439700007, 13.270502700000096],
              [109.26021283600004, 13.271123030000085],
              [109.26116112200008, 13.271166293000112],
              [109.26195700200002, 13.270770322000123],
              [109.26259985600009, 13.269837304000108],
              [109.26298868000003, 13.268172227],
              [109.26298230100012, 13.267145269000123],
              [109.26222510200009, 13.265731544000035],
              [109.26094388600004, 13.264296493000048],
              [109.26023994100007, 13.263420400000124],
              [109.25983383400003, 13.262298015000072],
              [109.25962547600002, 13.260881022000069],
              [109.259755157, 13.25765257300011],
              [109.2600160790001, 13.255474780000041],
              [109.26040641200007, 13.2540542440001],
              [109.26154204900006, 13.252140229000096],
              [109.26327664200005, 13.250271544000023],
              [109.26600592800001, 13.247907865000029],
              [109.26803793600001, 13.245743950000042],
              [109.27014396900009, 13.243457317000027],
              [109.27386071700003, 13.239473814000034],
              [109.27583991600002, 13.236870018000067],
              [109.277522823, 13.234757024000119],
              [109.27871084600005, 13.233282763000085],
              [109.27955488400008, 13.232641930000087],
              [109.28057398300002, 13.232073392000119],
              [109.28127205300005, 13.232020269000076],
              [109.28172163500004, 13.232115356000039],
              [109.28197414000006, 13.232602857000051],
              [109.28281208700002, 13.238906332000065],
              [109.28359000900008, 13.243596381000076],
              [109.28460065400004, 13.249580983000085],
              [109.28460745000007, 13.250656828000016],
              [109.28441963500008, 13.252516320000039],
              [109.28381191200006, 13.255038576000098],
              [109.28317630000005, 13.257096400000099],
              [109.28199587700009, 13.259793281000041],
              [109.28017513900006, 13.263814455000043],
              [109.27872192100008, 13.266781960000083],
              [109.27694664500011, 13.270118174000077],
              [109.27620683300009, 13.271491968000058],
              [109.27571617600009, 13.272815347000043],
              [109.2753975360001, 13.273722004000097],
              [109.27506115500006, 13.275778040000128],
              [109.27507526100007, 13.278027579000094],
              [109.27546987400004, 13.281277395000044],
              [109.27573102500007, 13.283134219000113],
              [109.27603439100002, 13.283768163000074],
              [109.27638521300001, 13.284010574000058],
              [109.27693407600005, 13.284007268000051],
              [109.27787874700005, 13.283463623000012],
              [109.27892135900007, 13.282625945000069],
              [109.280560882, 13.281491229000062],
              [109.28170696300006, 13.28123976900004],
              [109.28315396600007, 13.281231013000115],
              [109.28420303100009, 13.281420287000055],
              [109.28555333600008, 13.281901148000051],
              [109.28948310100007, 13.283906848000091],
              [109.29063601300007, 13.284731212000088],
              [109.29143903300007, 13.285459902000072],
              [109.29204277400004, 13.286238708000029],
              [109.29250149500007, 13.287751981],
              [109.29241071600009, 13.289170786000069],
              [109.29192416100008, 13.29112997000008],
              [109.29183367700006, 13.292597691000106],
              [109.29188637700005, 13.293037524000082],
              [109.29223753500007, 13.293328814000025],
              [109.29283758000007, 13.29352077500001],
              [109.29353430200007, 13.293223094000071],
              [109.29432647000007, 13.292240147000099],
              [109.29803707900004, 13.287229094000084],
              [109.30098144500003, 13.283371986000068],
              [109.30390562400004, 13.280272984000073],
              [109.30529110400002, 13.278454967000107],
              [109.30652625200007, 13.276540061000029],
              [109.30879499800002, 13.272418054000033],
              [109.31051735300007, 13.26869063900009],
              [109.31144956200011, 13.266239646000065],
              [109.31275104200006, 13.263028344000103],
              [109.31402549400008, 13.259499324000055],
              [109.3144659750001, 13.25817617900009],
              [109.31455721900002, 13.256855192000037],
              [109.31427729300003, 13.256001112000069],
              [109.31367481200002, 13.25541799300005],
              [109.31207138000005, 13.254352036000048],
              [109.31027030100006, 13.253580711],
              [109.30826967200008, 13.252810604000052],
              [109.30562112300005, 13.252142278000088],
              [109.30327349800005, 13.251716596000039],
              [109.30222425500001, 13.251478519000058],
              [109.30142320800007, 13.251043308000108],
              [109.30102159100007, 13.250654545000094],
              [109.30059237500008, 13.249850263000072],
              [109.30027929000002, 13.247700413000095],
              [109.30031353900002, 13.245255006],
              [109.30123895400003, 13.237840416000067],
              [109.301674463, 13.235734893000116],
              [109.30231295300007, 13.234166057000072],
              [109.30300539400008, 13.233232635000112],
              [109.30404952700005, 13.232688280000014],
              [109.30534339000008, 13.232191276000014],
              [109.30594012600007, 13.231894182000035],
              [109.30628554500005, 13.231305210000064],
              [109.30628209000002, 13.230767300000055],
              [109.3057280240001, 13.229939358000118],
              [109.30502368300009, 13.22901453600007],
              [109.30397236000006, 13.228434166000101],
              [109.30132509100005, 13.227912501000015],
              [109.29972537800002, 13.227384390000017],
              [109.29839925699999, 13.226732324000066],
              [109.29689527100007, 13.225567863],
              [109.295435582, 13.22352286500008],
              [109.29327074000003, 13.220406295000052],
              [109.29211289000006, 13.21875066000011],
              [109.29160693600002, 13.217628982000027],
              [109.29157439000009, 13.216431065000032],
              [109.29200436300005, 13.213445382000087],
              [109.29282528500002, 13.209185849000081],
              [109.29365549300006, 13.206393344000043],
              [109.29505414300006, 13.202790489000014],
              [109.29657602600004, 13.198966804000099],
              [109.29849779300005, 13.195287383000041],
              [109.29975790300006, 13.193445834000082],
              [109.30154151400009, 13.191576614000054],
              [109.30342610000007, 13.189902374000082],
              [109.30422001200003, 13.189261763000014],
              [109.30451642800004, 13.18881981800004],
              [109.30451266400009, 13.188233026000072],
              [109.30426110100005, 13.187892257000032],
              [109.30326175100006, 13.187605],
              [109.30128908600008, 13.187201465000074],
              [109.30018873900008, 13.18671920000005],
              [109.29903540600004, 13.185748243000093],
              [109.29767822000011, 13.184093906000081],
              [109.2961119120001, 13.180924902000067],
              [109.29397747100006, 13.174678583000075],
              [109.29280054500002, 13.170922706000086],
              [109.29227217, 13.169236523000015],
              [109.29168610100001, 13.167259620000047],
              [109.29117220700003, 13.164866608000112],
              [109.29085282799998, 13.161690011000092],
              [109.29078248900002, 13.158462995000074],
              [109.29091036000004, 13.155039180000115],
              [109.29148267800005, 13.150928067000043],
              [109.292135816, 13.147770025000048],
              [109.29306178800006, 13.144390267000093],
              [109.29433427300009, 13.140617201000014],
              [109.29632175400005, 13.135592824000051],
              [109.29814040100007, 13.131474115000062],
              [109.30062784600008, 13.126691163000091],
              [109.30299570300008, 13.122715772000058],
              [109.30546411900012, 13.118886443000031],
              [109.30949298500005, 13.113507146000101],
              [109.31354673000007, 13.108152079000133],
              [109.32138240600003, 13.097908012000035],
              [109.32387971000007, 13.094787385000116],
              [109.32576035000007, 13.092673005000053],
              [109.32680019300004, 13.091590738000034],
              [109.32739392500005, 13.090902443000106],
              [109.32754060500011, 13.090461437000032],
              [109.32728880500005, 13.090071836000037],
              [109.32658931300008, 13.089831731000066],
              [109.32559359700004, 13.090033556000067],
              [109.32424987900006, 13.09038424500004],
              [109.32295442100008, 13.090490136000097],
              [109.32185646000009, 13.090301403000113],
              [109.31970816000006, 13.089581330000042],
              [109.31770815700006, 13.088664724000052],
              [109.3154310030001, 13.087285297000049],
              [109.31372856000011, 13.086122320000102],
              [109.31186199900003, 13.084578349000074],
              [109.30960357100005, 13.082240052000047],
              [109.30920849700004, 13.0809525400001],
              [109.30927755800008, 13.079662149000091],
              [109.30925900800005, 13.076778847000091],
              [109.30878415800001, 13.075112429000056],
              [109.30784715900003, 13.073752387000058],
              [109.30675864500003, 13.072886495000089],
              [109.30473779700006, 13.071381372000078],
              [109.30232677600006, 13.069746541000081],
              [109.30064284800008, 13.068707979000036],
              [109.30382718300004, 13.068318201000027],
              [109.306658532, 13.068332238000034],
              [109.309137107, 13.068848077000082],
              [109.31107571800008, 13.069594878000055],
              [109.31216398800007, 13.07042280600008],
              [109.31294629600005, 13.071783784000067],
              [109.31388527900002, 13.073447300000039],
              [109.31520906000004, 13.074804913000014],
              [109.31707531100008, 13.076310903000097],
              [109.31979049600004, 13.077508076000031],
              [109.32198122000003, 13.07807747800007],
              [109.32390937000004, 13.078694569000058],
              [109.32627598100001, 13.07945196300002],
              [109.32778560400004, 13.079571198000027],
              [109.32863503800007, 13.079051074000068],
              [109.32928557100007, 13.078210441000058],
              [109.32967370400003, 13.077371455],
              [109.3297367820001, 13.076984966000092],
              [109.32940500400009, 13.07640789800004],
              [109.32795847500005, 13.075902179000119],
              [109.32644761100009, 13.075589895000038],
              [109.32539594600011, 13.075274735000074],
              [109.32394859800009, 13.074640291000071],
              [109.323092058, 13.074066494000112],
              [109.32295500200003, 13.073166457000072],
              [109.3234100060001, 13.072520117000062],
              [109.32531065000008, 13.072250834000075],
              [109.32793370200004, 13.072105707000057],
              [109.32957194800005, 13.071838031000114],
              [109.33088136200003, 13.071443706000084],
              [109.33205873900002, 13.070921510000042],
              [109.33277904500002, 13.070723929000037],
              [109.3334358490001, 13.07084848800004],
              [109.33363812700004, 13.071683759000051],
              [109.33358686500006, 13.073871969],
              [109.33333585800008, 13.075610992000096],
              [109.33223964300007, 13.078513632000098],
              [109.331529414, 13.080255548000073],
              [109.33042557600007, 13.081999928000075],
              [109.32866780800002, 13.084070173000038],
              [109.32775821700008, 13.085427231000056],
              [109.32756644400011, 13.086200632],
              [109.32750588699999, 13.086973220000074],
              [109.32767346100009, 13.087519147000124],
              [109.32826598100009, 13.08783717900006],
              [109.32892074600008, 13.08764002800009],
              [109.33009695100006, 13.086924783000063],
              [109.33198551500008, 13.084789360000036],
              [109.333092306, 13.083495391000087],
              [109.3344534650001, 13.080977168000095],
              [109.33586963300012, 13.076849842000103],
              [109.33787273600004, 13.072204019000109],
              [109.33991002400002, 13.067783205000081],
              [109.34282192700007, 13.061715931000071],
              [109.34463729200009, 13.05848696900007],
              [109.34866935500001, 13.05162439000007],
              [109.349210177, 13.050703872000023],
              [109.35303637400003, 13.044115929],
              [109.35488272000003, 13.040661503000093],
              [109.35831460300004, 13.034011694000112],
              [109.36129449300003, 13.028458675000108],
              [109.36502430700008, 13.022289510000105],
              [109.36918124000005, 13.016278413000036],
              [109.37428162800008, 13.009199428000072],
              [109.37854183800005, 13.003991861000051],
              [109.38387438100003, 12.997393788000087],
              [109.38660391100009, 12.993772576000049],
              [109.38757827300006, 12.992414937000072],
              [109.38805549600002, 12.991504785000084],
              [109.38822277300001, 12.99110720200005],
              [109.38829143500001, 12.990632930000066],
              [109.38833340800002, 12.988884538],
              [109.38844994200007, 12.98752592900003],
              [109.39043897100007, 12.984056540000031],
              [109.39779689300011, 12.974750092000063],
              [109.40472116200004, 12.96649556800009],
              [109.40983785300001, 12.960659951000062],
              [109.41255347900008, 12.95782588900005],
              [109.41426270200002, 12.956242693000062],
              [109.416327779, 12.954657095000053],
              [109.41738936000009, 12.953776772000046],
              [109.41780050100009, 12.953191880000057],
              [109.41797357600011, 12.952492151000071],
              [109.41796992700007, 12.951968244000065],
              [109.41767052000007, 12.951562750000088],
              [109.41671786300003, 12.951045206000076],
              [109.41571034599998, 12.951168370000053],
              [109.41470647900006, 12.951815435000034],
              [109.41364530000001, 12.952753957000061],
              [109.41269991700003, 12.953284200000057],
              [109.41103993800009, 12.953411695000037],
              [109.40920000199999, 12.953249316000013],
              [109.40800929800007, 12.952616877000088],
              [109.40705384400006, 12.951691809000108],
              [109.40651519600007, 12.95099682100007],
              [109.40627312000004, 12.950299863000046],
              [109.40793710100004, 12.950754505000093],
              [109.40930250800004, 12.950920064000025],
              [109.41030962400006, 12.95073871000003],
              [109.41143213100003, 12.950090875000074],
              [109.41190138600004, 12.949330962000081],
              [109.41195626000005, 12.948690245000092],
              [109.41185583500001, 12.947727248000083],
              [109.41497075899998, 12.946519860000103],
              [109.41899305599999, 12.945405234000072],
              [109.42251571900005, 12.944887179000107],
              [109.42443127700008, 12.945072055000127],
              [109.42554433000008, 12.945756739000023],
              [109.42585002500006, 12.946249088000082],
              [109.42585417900005, 12.946842349000052],
              [109.4249564560001, 12.948133863000065],
              [109.42255371400009, 12.950325427000029],
              [109.42125708900009, 12.952212901000058],
              [109.41996251900009, 12.954397007000027],
              [109.41826284700005, 12.956287172000101],
              [109.413156762, 12.960968812000127],
              [109.40685709100003, 12.967932613000064],
              [109.40000993900006, 12.975938164000043],
              [109.39671601400002, 12.980508612000017],
              [109.39271726200009, 12.985182546000116],
              [109.39121861000008, 12.987071217000066],
              [109.39081316900011, 12.987985668000055],
              [109.39071535800011, 12.988421468000054],
              [109.390696964, 12.988678724000112],
              [109.39085154800009, 12.989284831000079],
              [109.39118478300004, 12.989494131000077],
              [109.39179067100008, 12.989442941000016],
              [109.39239264700007, 12.98928841400002],
              [109.39307701400001, 12.98890042900007],
              [109.39369653500003, 12.988389865000014],
              [109.39918982700007, 12.981233909000077],
              [109.40418506100011, 12.974971114000054],
              [109.40908265400006, 12.969203300000041],
              [109.4120834510001, 12.966019033000096],
              [109.41403481, 12.964077776000059],
              [109.4166358790001, 12.961390543000027],
              [109.41854490800003, 12.959458107],
              [109.42204896700007, 12.955696437000073],
              [109.424738244, 12.951962478000032],
              [109.42561152000005, 12.951055775000063],
              [109.42618252600003, 12.950676594000042],
              [109.428961309, 12.948818677000061],
              [109.42975806600003, 12.947912483000044],
              [109.430284757, 12.946670301000028],
              [109.43035491600003, 12.945769016000062],
              [109.43027262900006, 12.944943830000087],
              [109.43003843500004, 12.944269799],
              [109.42938122900003, 12.943260831000101],
              [109.42864990500009, 12.942590156000032],
              [109.42807445199999, 12.942331306000108],
              [109.42734551200007, 12.941998412000098],
              [109.42699765700007, 12.941475291000069],
              [109.42695310200003, 12.940574784000079],
              [109.42704252600005, 12.939692131000056],
              [109.42730494900009, 12.93893969200006],
              [109.42885435700003, 12.936301883000032],
              [109.42984041700001, 12.935131680000021],
              [109.43186946800002, 12.932734583000103],
              [109.43437156600001, 12.929639905000021],
              [109.43719264500001, 12.92569852800005],
              [109.43867011900005, 12.923736766000092],
              [109.44052702100004, 12.921359543000042],
              [109.44128484900003, 12.920378525000052],
              [109.44227395900006, 12.919658657000086],
              [109.44461578300003, 12.918272721000077],
              [109.44587411700005, 12.917776200000029],
              [109.44965147800005, 12.916624370000116],
              [109.45270312400002, 12.915590051000068],
              [109.4535787990001, 12.915058574000058],
              [109.45399590800005, 12.91456778100004],
              [109.45414429200008, 12.913928702000097],
              [109.45413839600003, 12.913103023000081],
              [109.453380087, 12.911325416000084],
              [109.45190254000005, 12.907882539000058],
              [109.45066942000007, 12.903856235000076],
              [109.45004405400002, 12.901946362000059],
              [109.44992214400006, 12.900933817000036],
              [109.45003363200004, 12.9004826700001],
              [109.45022132100003, 12.899993454000123],
              [109.45079027600012, 12.899351503000032],
              [109.45151508800005, 12.899121345000085],
              [109.45235567300006, 12.899040512000058],
              [109.45407720800003, 12.899178811000043],
              [109.45553166000012, 12.899394008000085],
              [109.45618134900005, 12.899352010000074],
              [109.45671391900007, 12.898973018000069],
              [109.45697725399999, 12.898370692000059],
              [109.45708604900003, 12.897544224000066],
              [109.45678977200004, 12.896213863000037],
              [109.45624701200006, 12.89516669600008],
              [109.45562805900001, 12.894157581000075],
              [109.45481902700004, 12.893299910000076],
              [109.45385787300009, 12.892555867000064],
              [109.45327904700008, 12.891809203000014],
              [109.45293014900007, 12.891136015000091],
              [109.45281116600009, 12.890536322000033],
              [109.45284511500006, 12.889935571000064],
              [109.45303198500005, 12.889333779000099],
              [109.45350411500004, 12.888523588000032],
              [109.45525011800001, 12.886747583000078],
              [109.45695812700011, 12.885009355000053],
              [109.45741286100007, 12.884443245000019],
              [109.45782694300004, 12.883539629000039],
              [109.457823182, 12.883014204000085],
              [109.45743551500007, 12.882266241000105],
              [109.45682089000012, 12.881857615000046],
              [109.45613141600009, 12.881674705000099],
              [109.45536683000006, 12.881679965000091],
              [109.45418386500006, 12.881988355000116],
              [109.45322840000006, 12.882032447000123],
              [109.45253892800012, 12.881849525000087],
              [109.45152223800002, 12.881349816000041],
              [109.45060072900007, 12.880793159000079],
              [109.45006177300006, 12.880271413000075],
              [109.44979070200002, 12.879785349000093],
              [109.44959127700004, 12.87862323600004],
              [109.44957899000008, 12.876896858000107],
              [109.44969315200002, 12.874137497000058],
              [109.44998775800006, 12.872559150000116],
              [109.45043633700006, 12.871129877000074],
              [109.45114527300009, 12.868685476000062],
              [109.45156950500002, 12.866524503000052],
              [109.45189561600009, 12.864007663000086],
              [109.4520041370001, 12.8631437070001],
              [109.45180418000008, 12.861906545000055],
              [109.45153152500008, 12.861195323000095],
              [109.45072397800006, 12.860525304000079],
              [109.45007147200006, 12.860154463000038],
              [109.44888488600003, 12.85993740400002],
              [109.44739223000008, 12.859684905000089],
              [109.44350824200005, 12.85912970100007],
              [109.44006320200003, 12.858477622000029],
              [109.43753531300004, 12.857781722000093],
              [109.43554180000002, 12.856969578000042],
              [109.43412219900007, 12.856228580000016],
              [109.43254759200008, 12.855188381000103],
              [109.43166263400005, 12.854368694000049],
              [109.43056203600003, 12.85278106600005],
              [109.42878478200005, 12.850090855000039],
              [109.42747834200006, 12.849123876000055],
              [109.4262509910001, 12.848531658000082],
              [109.42529410100008, 12.848350456000011],
              [109.42414740000005, 12.848358178000028],
              [109.42338346400008, 12.848438381000092],
              [109.4223145230001, 12.848633220000048],
              [109.42094032199999, 12.848905176000079],
              [109.418881492, 12.849669604],
              [109.41830997100004, 12.849936149000117],
              [109.41777928700002, 12.850577729000088],
              [109.417668529, 12.8511414330001],
              [109.41770962800003, 12.85155400400013],
              [109.41792260200006, 12.851946641000021],
              [109.41915544000001, 12.853327013000102],
              [109.42402948600007, 12.858830079000059],
              [109.42522418000006, 12.860210681000071],
              [109.42587821700008, 12.860806776000098],
              [109.42741512200006, 12.861922347000119],
              [109.42979750400009, 12.863670226000055],
              [109.43144913600005, 12.86478499500001],
              [109.43262150100007, 12.865696571000028],
              [109.43373958200003, 12.867040126000083],
              [109.43493729800011, 12.868833516000054],
              [109.43540184300008, 12.869656054000076],
              [109.43594707400007, 12.871078550000091],
              [109.43620865400004, 12.872934582000093],
              [109.43639226600004, 12.877287006000058],
              [109.43622361900003, 12.880478349000056],
              [109.43607811400008, 12.881530225000047],
              [109.43562332700002, 12.882096288000076],
              [109.43480337600003, 12.882383334000023],
              [109.43308648500009, 12.882882882000056],
              [109.43129047600007, 12.883007630000074],
              [109.42937873500009, 12.882983025000081],
              [109.42777098600001, 12.882693622000117],
              [109.42623785400008, 12.882140985000133],
              [109.4251624450001, 12.881435126000083],
              [109.4241608630001, 12.880353444000024],
              [109.4219861130001, 12.87824752400004],
              [109.4201741840001, 12.87608285900008],
              [109.41871026300004, 12.874478823000056],
              [109.41667477800011, 12.873141325000057],
              [109.4144117770001, 12.872068048000072],
              [109.4122273400001, 12.871294477000015],
              [109.40973761600007, 12.87059798200003],
              [109.40661728800009, 12.869924415000076],
              [109.40271190500007, 12.86904959100008],
              [109.4009883360001, 12.86857310400009],
              [109.40014450300001, 12.868165837000054],
              [109.39952977800004, 12.867719534000075],
              [109.39922163800003, 12.867383792000096],
              [109.39910464200003, 12.867046777000011],
              [109.39910232200002, 12.866709013000042],
              [109.39921313000004, 12.866145312000031],
              [109.39964590200006, 12.865147866000102],
              [109.40021155600004, 12.864018188000069],
              [109.40085236800005, 12.862700350000098],
              [109.401113756, 12.861797874000034],
              [109.4011450030001, 12.860784325000017],
              [109.40098513400002, 12.859772050000105],
              [109.39999658900003, 12.857770676000067],
              [109.39910864700001, 12.856500497000043],
              [109.39810501700001, 12.855080955000096],
              [109.39664091500002, 12.853401727000067],
              [109.39346675000012, 12.850401427000067],
              [109.39038760000007, 12.847306637000065],
              [109.38838747200005, 12.845480773000071],
              [109.38692560200006, 12.844282623000057],
              [109.38240495900004, 12.848247078000073],
              [109.37617162900005, 12.855341657000084],
              [109.371557196, 12.861060325000027],
              [109.36462514600001, 12.868159167000035],
              [109.35948857800001, 12.86546164200004],
              [109.35224172400009, 12.860274464000019],
              [109.34406169300007, 12.85463789000003],
              [109.33775808800004, 12.85126464200008],
              [109.33356325100007, 12.850153399000062],
              [109.33162847600003, 12.849423973000016],
              [109.32703327100005, 12.847691454000108],
              [109.32538266400007, 12.844288623000109],
              [109.32396473200001, 12.840884334000078],
              [109.3206918050001, 12.838401807000109],
              [109.31765746900003, 12.836827931000093],
              [109.31556980500005, 12.837751095],
              [109.31349238300002, 12.840266985000069],
              [109.30513554600002, 12.843049230000057],
              [109.29983682800001, 12.842057901000102],
              [109.29657577200001, 12.841395276000076],
              [109.29412096000003, 12.839476184000096],
              [109.29084769900001, 12.836879438],
              [109.28664551000003, 12.834515838000103],
              [109.28361292900003, 12.833169007000043],
              [109.28093789000012, 12.833185220000052],
              [109.26827396500008, 12.835423167000036],
              [109.26618326200003, 12.83589077500009],
              [109.26177343300002, 12.837509925000033],
              [109.25701179400004, 12.838675989000057],
              [109.25375793500011, 12.839150383000051],
              [109.25282395500001, 12.838587056000071],
              [109.25095322700004, 12.837005323000115],
              [109.24837491700005, 12.833834962000077],
              [109.24638657400001, 12.83202635200009],
              [109.24357718100001, 12.82908484400007],
              [109.23901918000008, 12.825470941000015],
              [109.23434228900001, 12.821402557000015],
              [109.22943023600001, 12.816880385000038],
              [109.22603844900004, 12.813714529000018],
              [109.22429539500006, 12.813952166000051],
              [109.22278696500004, 12.814529728000055],
              [109.22058757100005, 12.81624897200002],
              [109.21769579800005, 12.818882327000034],
              [109.21377080200003, 12.823796983000067],
              [109.21041564600006, 12.826774230000114],
              [109.20705230200004, 12.828386234000087],
              [109.20229056300005, 12.829551048],
              [109.19590183700009, 12.830952514000058],
              [109.19171618900006, 12.831203670000056],
              [109.18846096000001, 12.831449528000059],
              [109.16704183600007, 12.828269421000103],
              [109.16447924900004, 12.827600926000036],
              [109.16249301100001, 12.82601906600007],
              [109.16085569200008, 12.824435284000034],
              [109.15932888500005, 12.821826949000046],
              [109.15791517800005, 12.818649152000104],
              [109.15626827700004, 12.81535884900009],
              [109.15450706600006, 12.812410488000035],
              [109.15333253300005, 12.810369060000058],
              [109.15238673600004, 12.807643779000076],
              [109.15097516400004, 12.804807266000038],
              [109.14932592000009, 12.80106189400003],
              [109.146689458, 12.787196662000076],
              [109.14668241500004, 12.785945278],
              [109.1444622380001, 12.784023284000055],
              [109.14119242300009, 12.781538107000044],
              [109.14037721000005, 12.781314968000055],
              [109.13817176000001, 12.782009444000051],
              [109.13515868800002, 12.78384588300012],
              [109.13133538800005, 12.786369216000091],
              [109.12556224100004, 12.793794827000047],
              [109.12394807100006, 12.796306280000101],
              [109.12198311700011, 12.798478280000046],
              [109.11920035600008, 12.799971996000091],
              [109.11652636499998, 12.800099897000015],
              [109.1146620280001, 12.799427122000038],
              [109.11326036200003, 12.798296838000043],
              [109.11287529200006, 12.796883726000095],
              [109.11254834800009, 12.795683941000034],
              [109.10549097900005, 12.780931363000024],
              [109.10315156800002, 12.777950650000045],
              [109.10046219200008, 12.775610631000054],
              [109.0890075240001, 12.769212036000075],
              [109.08794801000009, 12.762023682000033],
              [109.08851718700002, 12.759745493000095],
              [109.08896643700007, 12.756785343000042],
              [109.08952524800003, 12.752573254000053],
              [109.08717696500007, 12.748262380000062],
              [109.08530289900008, 12.745655482000105],
              [109.08499101700004, 12.745353472000074],
              [109.08343311500001, 12.743844888000043],
              [109.08168927600005, 12.743853814000071],
              [109.07901960200005, 12.74466377900003],
              [109.07635232000007, 12.745928744000066],
              [109.07438254700007, 12.747190144000074],
              [109.05875745700007, 12.750209569000058],
              [109.050671236, 12.748333130000054],
              [109.04695388400009, 12.74892037600006],
              [109.04311385000005, 12.748256776000042],
              [109.04008590600009, 12.747247833000051],
              [109.03658369700003, 12.744420993000103],
              [109.02666638000005, 12.73741619600006],
              [109.00681637100008, 12.719878878000122],
              [109.00494696000004, 12.717953874],
              [109.00027553600006, 12.713539459000065],
              [108.99560930700007, 12.710148745000092],
              [108.99164563700002, 12.707778463000084],
              [108.99027054500002, 12.706969813000056],
              [108.9909133020001, 12.712559726000093],
              [108.99186203400008, 12.71642305300009],
              [108.99211001800008, 12.719607132000109],
              [108.99142474400004, 12.722113040000059],
              [108.98588026600004, 12.729419555000019],
              [108.98450617400003, 12.733748831000112],
              [108.98313641500002, 12.738988178000097],
              [108.98480354200004, 12.747171216000106],
              [108.9876124800001, 12.75102601400002],
              [108.9890230620001, 12.75420475600013],
              [108.98927436200002, 12.758071502000055],
              [108.98929535000008, 12.762394391000063],
              [108.98565626500006, 12.779248448000134],
              [108.9856694640001, 12.781978750000087],
              [108.98451437900009, 12.783576844000065],
              [108.98195734100008, 12.783816257000067],
              [108.97916555300002, 12.783601673000076],
              [108.97568373100005, 12.784982945000054],
              [108.97336791800011, 12.787041394000108],
              [108.97314731200007, 12.789545256000066],
              [108.97316361400007, 12.792958151],
              [108.97340922500007, 12.795687417000055],
              [108.97482425000008, 12.79977649500008],
              [108.97090840500009, 12.807758215000129],
              [108.96516478300003, 12.817255553000082],
              [108.96250334700009, 12.816782787000045],
              [108.95346141200008, 12.813489637000062],
              [108.94475129500006, 12.809710098000069],
              [108.93799263500004, 12.806584148000065],
              [108.93718874600006, 12.809047983000086],
              [108.93626571500008, 12.810644822000048],
              [108.93138343700005, 12.811121445000049],
              [108.92208604000004, 12.812527390000115],
              [108.91417591100004, 12.81210665000012],
              [108.90789596200003, 12.812133696000048],
              [108.87860622600006, 12.816125490000017],
              [108.87581508300005, 12.816137064000086],
              [108.87349302900003, 12.81705681000007],
              [108.8683788160001, 12.817760479000116],
              [108.86489952300008, 12.820050101000023],
              [108.85956988700008, 12.824850114000073],
              [108.85493401700003, 12.828737055000071],
              [108.85122937100002, 12.832847707000015],
              [108.84868862200003, 12.83718117700001],
              [108.84685037200006, 12.842649534000115],
              [108.84710381300007, 12.847654442000065],
              [108.84828497600002, 12.851973029000073],
              [108.84759843400006, 12.854706312000095],
              [108.84621015900004, 12.856532221000093],
              [108.84366050700001, 12.858817848000024],
              [108.83948042300001, 12.86065483300005],
              [108.83390622800003, 12.862952328000075],
              [108.81882063100009, 12.87211310700007],
              [108.81162723000007, 12.876919326000101],
              [108.80720208700004, 12.87579851800009],
              [108.80394120500006, 12.87490074200006],
              [108.80091031800002, 12.873319399000048],
              [108.79787859700008, 12.871510476000124],
              [108.79484426500002, 12.869018878000114],
              [108.79298213400006, 12.868798314000072],
              [108.7904299830001, 12.870628245000136],
              [108.78811220500008, 12.87291236100012],
              [108.78580140900002, 12.877016812000084],
              [108.78280562300003, 12.884537038000053],
              [108.7768090390001, 12.898439751000089],
              [108.77379790800009, 12.902091633000088],
              [108.77171832600007, 12.905967637000069],
              [108.76340089500005, 12.921926686000072],
              [108.75622470800002, 12.932192560000109],
              [108.75252796200004, 12.939487646000101],
              [108.74951578800004, 12.943139302000082],
              [108.74533894600003, 12.946567473000046],
              [108.7269740350001, 12.952775526000069],
              [108.72418182000008, 12.953012665000061],
              [108.71975872300004, 12.952800185000067],
              [108.71532764000005, 12.950312026000075],
              [108.71112384800004, 12.946230073000018],
              [108.70912766300009, 12.944205388000094],
              [108.706990456, 12.941990237000043],
              [108.704184524, 12.943225402000012],
              [108.70240194800004, 12.944693384000059],
              [108.70151316400003, 12.946158392000127],
              [108.70033044600007, 12.948794023000064],
              [108.70063845400006, 12.951717146000073],
              [108.70333819100006, 12.956971651000069],
              [108.70424621700005, 12.961062459000059],
              [108.70425434100009, 12.96341117600006],
              [108.70408035200009, 12.965473768000011],
              [108.70210657900003, 12.968573356000055],
              [108.69698991300007, 12.974318110000114],
              [108.69070672300003, 12.980410265000051],
              [108.68779478900007, 12.982367232000056],
              [108.68289933700002, 12.98467424000007],
              [108.67975187200003, 12.986059069000076],
              [108.67532169900002, 12.987906186000052],
              [108.672439196, 12.989162994000123],
              [108.67230830699999, 12.989384814000092],
              [108.67381728800007, 12.99192053600011],
              [108.67769575700007, 12.996385813000087],
              [108.67843282400004, 12.997335878000092],
              [108.68697469500007, 13.008345393000107],
              [108.68786684, 13.009995283000107],
              [108.68913836400003, 13.012346730000067],
              [108.69190957400009, 13.017471365000064],
              [108.69326852100008, 13.01759690600006],
              [108.69703582600005, 13.017944885000075],
              [108.70249590100008, 13.01807686400007],
              [108.70349427800009, 13.018182146000077],
              [108.70541311100004, 13.018202030000035],
              [108.70733750500003, 13.018184102000061],
              [108.71123955100003, 13.018506047000054],
              [108.71407056400005, 13.019187547000042],
              [108.71632338300003, 13.019966870000042],
              [108.72234859200002, 13.022189552],
              [108.72754010000004, 13.024255221000123],
              [108.73569408200004, 13.027245293000076],
              [108.74332789099999, 13.030131095000018],
              [108.74653801600004, 13.031328878000091],
              [108.74991673800008, 13.032501496000036],
              [108.75392127400005, 13.033540989000068],
              [108.75432758800008, 13.034252083000107],
              [108.75431847000004, 13.035262819000069],
              [108.754031341, 13.038494922000064],
              [108.753426921, 13.042583470000045],
              [108.75293110800001, 13.048922015000118],
              [108.75221109600002, 13.054374140000034],
              [108.75145951900001, 13.060457737000062],
              [108.7508960050001, 13.06570903900006],
              [108.74998403000006, 13.072397705000059],
              [108.74963412800005, 13.076842216],
              [108.74925618800006, 13.081037249000092],
              [108.74859431600001, 13.087394946000062],
              [108.74848088200012, 13.091361375000064],
              [108.74845696700001, 13.094731875000052],
              [108.74936008100003, 13.096827402000065],
              [108.75068056700005, 13.099163778000021],
              [108.75213015600008, 13.10158166500006],
              [108.75337104699999, 13.103702622000045],
              [108.75437431700004, 13.103739767000098],
              [108.75457359900001, 13.108296553000097],
              [108.75634038000001, 13.113262944000057],
              [108.75726647300007, 13.11639852600006],
              [108.76179851500007, 13.1172169800001],
              [108.76515132600009, 13.117813417000066],
              [108.76651910300002, 13.118538714000037],
              [108.7672714710001, 13.120604982000115],
              [108.76702878900001, 13.122066332000058],
              [108.76566922100005, 13.123531732000069],
              [108.76084263100002, 13.127078610000085],
              [108.75973663700009, 13.130003537000066],
              [108.75962253300006, 13.132681497000041],
              [108.760128497, 13.135235535000065],
              [108.76087722100004, 13.136328216000058],
              [108.76286426900005, 13.1366861860001],
              [108.76683518200007, 13.136550145000035],
              [108.77031058800003, 13.136659238000091],
              [108.77292003500003, 13.137501688000073],
              [108.77391936100001, 13.139201945000066],
              [108.77417822300004, 13.142000282000053],
              [108.77394112100004, 13.144922140000046],
              [108.77407078600002, 13.146382174000058],
              [108.77469597900006, 13.147596972000118],
              [108.77655951500006, 13.148076983000044],
              [108.7780488540001, 13.148071518000036],
              [108.7791621420001, 13.147093758000048],
              [108.78113764400004, 13.144408892000035],
              [108.78311731000007, 13.142819382000058],
              [108.786623574, 13.142226736000049],
              [108.7899672, 13.14237560700011],
              [108.79271367600003, 13.14316672200002],
              [108.79428736500005, 13.143930985000017],
              [108.79901378800004, 13.147571454000049],
              [108.80520948300003, 13.153599105000067],
              [108.80945958700005, 13.157543874000053],
              [108.81142845000005, 13.158884132000102],
              [108.81418505700006, 13.160798961000085],
              [108.820874227, 13.164046360000013],
              [108.82500314400005, 13.165378108000068],
              [108.82834459600011, 13.166198106000058],
              [108.82956800300009, 13.166193318000106],
              [108.83130274200009, 13.16545625600005],
              [108.83414807900006, 13.163132575000079],
              [108.83557372700007, 13.162700978000073],
              [108.83780990400008, 13.163178986000011],
              [108.83880989900004, 13.16487897100007],
              [108.83931191500001, 13.166215800000101],
              [108.84045205800003, 13.170609933000053],
              [108.84269994400003, 13.176826342000057],
              [108.84844431300006, 13.189661184000022],
              [108.85055171400008, 13.193277301000082],
              [108.85166790600006, 13.19565891100005],
              [108.85167517200004, 13.197394223000101],
              [108.85013371700003, 13.199135758000045],
              [108.84809290300002, 13.201326298000103],
              [108.84759080900005, 13.201905361000053],
              [108.84685050800007, 13.203003750000073],
              [108.84611170900006, 13.204467268000107],
              [108.84611677200003, 13.205684388000092],
              [108.84636860900008, 13.206535386000072],
              [108.84736533900005, 13.20738339700007],
              [108.84923264300009, 13.208593064000061],
              [108.84998639800003, 13.208927681000125],
              [108.84995128300005, 13.209321990000065],
              [108.84944956500001, 13.214955666000082],
              [108.84969687800005, 13.218368851000049],
              [108.85133779200001, 13.220638394000071],
              [108.85577930100007, 13.223807077000059],
              [108.86138916900006, 13.227653773000013],
              [108.86279432400006, 13.229241356],
              [108.86610089000001, 13.239470538000118],
              [108.86799662000001, 13.246746507000038],
              [108.86895425200001, 13.252660641000078],
              [108.86945840200008, 13.261535701000058],
              [108.8697216840001, 13.268590852],
              [108.87092527100003, 13.27746314300011],
              [108.86932843500007, 13.285664135],
              [108.86747503100001, 13.288403213000064],
              [108.86561576300001, 13.289776558000097],
              [108.86375162600005, 13.29001179200011],
              [108.85513798200003, 13.293005840000125],
              [108.85258871100004, 13.296658108000068],
              [108.84864709200005, 13.301909320000059],
              [108.84694155100007, 13.305143556000052],
              [108.84563979600009, 13.307612016000101],
              [108.84240388400005, 13.314453737000063],
              [108.84033173300001, 13.320835574000077],
              [108.8363921480001, 13.326769526000069],
              [108.83244673100006, 13.331337676000119],
              [108.819679373, 13.345728270000022],
              [108.81480038200007, 13.350299750000081],
              [108.81178233000003, 13.353725846000051],
              [108.80969522400009, 13.356693084000069],
              [108.80830770600004, 13.359657641000043],
              [108.80577750300003, 13.368545098000071],
              [108.805556005, 13.371505228000034],
              [108.80372975400007, 13.38152825100005],
              [108.80376103700007, 13.389438743000069],
              [108.8047638730001, 13.392337241000083],
              [108.80547265500003, 13.394610960000096],
              [108.806067989, 13.397681864000075],
              [108.80551821800007, 13.406106726000109],
              [108.80483734200004, 13.410889828000093],
              [108.80392404400007, 13.415901474000067],
              [108.80347368400001, 13.420000799000052],
              [108.80372137500008, 13.423642189000072],
              [108.80396682300005, 13.426714488000078],
              [108.80311316400012, 13.431018600000042],
              [108.80340038100007, 13.433398443000042],
              [108.80275455600002, 13.438334924000104],
              [108.79878525600006, 13.444121811000096],
              [108.79538021900005, 13.448417005000033],
              [108.79273038600009, 13.451405986000074],
              [108.78989036000003, 13.454395657000083],
              [108.78913498400006, 13.455701813000063],
              [108.7887619900001, 13.457565125000047],
              [108.78934390600003, 13.460542039000066],
              [108.79145112600003, 13.464630435000034],
              [108.79213215200006, 13.468631064000112],
              [108.79233825400007, 13.472726566000047],
              [108.79191660200009, 13.47529442200006],
              [108.78886495900009, 13.472870382000044],
              [108.78348874100007, 13.470499936000111],
              [108.77718146400009, 13.468701915000024],
              [108.77122689400008, 13.467585390000101],
              [108.76608822300008, 13.466238087000091],
              [108.75931327300005, 13.46409975400009],
              [108.74576548100006, 13.460277736000046],
              [108.74331297400001, 13.459603386000117],
              [108.73887841300008, 13.459277406000057],
              [108.73468131200009, 13.460088762000042],
              [108.72030116100008, 13.463320719000114],
              [108.71719497900003, 13.464018700000045],
              [108.71486280800005, 13.464368049000084],
              [108.71264849100007, 13.465058461000099],
              [108.71160324200009, 13.466427883000074],
              [108.71090999600003, 13.468365255000057],
              [108.70272593900002, 13.485038818000115],
              [108.70175821600006, 13.487291037000125],
              [108.70187803900004, 13.488201263000031],
              [108.71236780100003, 13.518103346000119],
              [108.71342209600004, 13.519238110000041],
              [108.72592843600009, 13.525232358000048],
              [108.73049559700007, 13.528969459000082],
              [108.73144071700001, 13.532153474],
              [108.73180150700003, 13.535111851000091],
              [108.73216517900001, 13.538867042000023],
              [108.73241050500005, 13.542167331000064],
              [108.73277093400003, 13.545011883000056],
              [108.73359780800006, 13.547741002000089],
              [108.7348928120001, 13.55081000700009],
              [108.73724041200009, 13.554444514000073],
              [108.73818121500004, 13.556376407000046],
              [108.74105397900006, 13.559510603000112],
              [108.74650193900007, 13.565453992000085],
              [108.74697303100007, 13.566590669000076],
              [108.74825830100009, 13.566927649000069],
              [108.75047402800001, 13.566350653000113],
              [108.75432011800009, 13.564743338000083],
              [108.75770094600004, 13.563592951000112],
              [108.76189924000005, 13.562553422000077],
              [108.76481566500004, 13.562087587000081],
              [108.76621894300006, 13.562765531000041],
              [108.76832904200003, 13.565148412000026],
              [108.78226426100009, 13.576708609000042],
              [108.78452139800007, 13.577830077000018],
              [108.78635787300004, 13.578742510000017],
              [108.79242718500005, 13.578492259000095],
              [108.79826346200005, 13.578356558000058],
              [108.80398300200005, 13.578221129000044],
              [108.80806824700004, 13.578091775000088],
              [108.81215122, 13.577393184000107],
              [108.8135479220001, 13.576363319000071],
              [108.815173047, 13.574080379000096],
              [108.8174939420001, 13.570656413000036],
              [108.81970170300008, 13.568143538000042],
              [108.82215023400008, 13.567451065000034],
              [108.82506474100008, 13.566529081000043],
              [108.82798247000008, 13.566403880000076],
              [108.83755036000011, 13.565455659000046],
              [108.84000163300006, 13.565445970000043],
              [108.84268776500009, 13.565776820000062],
              [108.844907, 13.566109495000054],
              [108.84654545100007, 13.567127474000083],
              [108.84783374000008, 13.568146841000049],
              [108.84868006700003, 13.569663291000021],
              [108.84895079800005, 13.570419487000061],
              [108.86288534400005, 13.552832362000087],
              [108.86798306400004, 13.543932609000107],
              [108.87051956000003, 13.536636941000067],
              [108.8756557880001, 13.536843474000102],
              [108.88196309700008, 13.537955674000022],
              [108.88780362200006, 13.539069632000087],
              [108.89275838200004, 13.539958069000056],
              [108.89769753900006, 13.541057707000091],
              [108.90556601700007, 13.54263650800007],
              [108.91139265500006, 13.541929262000084],
              [108.92817854900008, 13.537303197000126],
              [108.93260204899998, 13.534779491000036],
              [108.93912091800006, 13.531108121000022],
              [108.95098878900004, 13.523314716000099],
              [108.95471042000003, 13.520566050000047],
              [108.95727033000001, 13.518960879000106],
              [108.96077133200005, 13.51894505300004],
              [108.96730543600005, 13.518687706000064],
              [108.97570563900008, 13.518193917000064],
              [108.98457368800011, 13.51792528100013],
              [108.99087434100007, 13.517668291000094],
              [109.01051828700005, 13.525316158000054],
              [109.01846553700008, 13.527554786000094],
              [109.02361315700003, 13.530034315000082],
              [109.02783992100008, 13.535022497000069],
              [109.03183694000003, 13.540694720000078],
              [109.03841472800011, 13.548858619000013],
              [109.04124392600006, 13.554308778000033],
              [109.04500122600008, 13.558615998000077],
              [109.04782018400009, 13.562017125000112],
              [109.05524211700001, 13.566999741000084],
              [109.06443319200011, 13.569274587000011],
              [109.07377151200005, 13.569172815000028],
              [109.08030830800001, 13.569139637000125],
              [109.08075318200005, 13.569523637000101],
              [109.08802457500012, 13.571376914000053],
              [109.09094405900001, 13.574106121000094],
              [109.09553335200012, 13.578396088000131],
              [109.09671790300007, 13.58157737],
              [109.10000992600006, 13.585886114000088],
              [109.10422982500006, 13.589051616000079],
              [109.10703530300002, 13.58972002300005],
              [109.11099326000003, 13.587650280000039],
              [109.11378750300004, 13.586269597000109],
              [109.11589379900005, 13.587169223000027],
              [109.11474233200008, 13.608409139000075],
              [109.11595137900011, 13.613770026000081],
              [109.12070144800002, 13.619495643000066],
              [109.12379004300004, 13.621734242000104],
              [109.12519871600007, 13.623092856000072],
              [109.12155138600009, 13.630992501000081],
              [109.11845836000006, 13.640593340000072],
              [109.12135086500011, 13.645198173000049],
              [109.1253160680001, 13.644266455000052],
              [109.12973184400006, 13.640372421000089],
              [109.13624798700009, 13.63623939600007],
              [109.13950212100005, 13.633489784000028],
              [109.14323476600006, 13.632786679000029],
              [109.14627322000011, 13.633225651000112],
              [109.14931813200008, 13.634802949000056],
              [109.15143541900002, 13.637523635000013],
              [109.15168574200004, 13.640482128000107],
              [109.15217865700005, 13.645033083000067],
              [109.15172836400009, 13.647995392000032],
              [109.15171522600008, 13.652424782000059],
              [109.15227815800003, 13.662564129000108],
              [109.15276727100009, 13.666432109000047],
              [109.15372612400002, 13.67075291500006],
              [109.15442658700006, 13.675324772000062],
              [109.15607316000006, 13.679133436000066],
              [109.16130956700006, 13.682712940000028],
              [109.16889420000004, 13.684967370000043],
              [109.17563580500006, 13.686898200000106],
              [109.17859413800005, 13.687757894000095],
              [109.18338901000003, 13.689151186000091],
              [109.18580979400005, 13.69040663200005],
              [109.18896294899999, 13.692628883000067],
              [109.1938130610001, 13.695349197000082],
              [109.19731707600003, 13.695329466000048],
              [109.20034985100006, 13.694629280000113],
              [109.20220113000002, 13.691658850000097],
              [109.20381200200008, 13.687551328000056],
              [109.20565504200007, 13.683214803000075],
              [109.22268634100007, 13.679702092000044],
              [109.22757918900007, 13.677624673000109],
              [109.22943266300004, 13.675109393000094],
              [109.23099947100009, 13.672844308],
              [109.22995925700009, 13.671216432000042],
              [109.22890144400004, 13.668952276000111],
              [109.22841612700003, 13.665436158000064],
              [109.22885952900005, 13.662028182000084],
              [109.23034829500003, 13.658841184],
              [109.23045329200002, 13.657024372000047],
              [109.22986229000006, 13.655211587000069],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 45, code: "AD01", ten_tinh: "Quảng Bình" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.46824793500011, 17.872290551000077],
              [106.4675038840001, 17.872010316000072],
              [106.46623858300003, 17.872331342000081],
              [106.46485253500001, 17.873421466000124],
              [106.46386443800006, 17.875009366000057],
              [106.4638542380001, 17.876329214000108],
              [106.46447076500004, 17.878258451000036],
              [106.46611667700006, 17.88068994900005],
              [106.46747436200005, 17.883284375000038],
              [106.46849444500008, 17.884996464000032],
              [106.46952344700003, 17.885553689000041],
              [106.47049926900004, 17.885560575000078],
              [106.47176845300007, 17.884744583000064],
              [106.47292958100005, 17.883047883000103],
              [106.47317693100008, 17.880739785000088],
              [106.47267976100007, 17.878206452000086],
              [106.47047037900005, 17.874396116000035],
              [106.46824793500011, 17.872290551000077],
            ],
          ],
          [
            [
              [106.494648205, 17.906957929],
              [106.49350046200007, 17.906894962000038],
              [106.49292346300011, 17.907275923000029],
              [106.49234437600006, 17.907931839000035],
              [106.49198948900002, 17.909304248000076],
              [106.49134881300003, 17.910509690000076],
              [106.49065447400005, 17.911219793000029],
              [106.48926452500007, 17.912804971000092],
              [106.4890277850001, 17.913738238000043],
              [106.48959561500001, 17.914567119000054],
              [106.49188117200002, 17.916012902000134],
              [106.49308637500009, 17.916076276000048],
              [106.49383688800002, 17.915531535000092],
              [106.49557627100005, 17.913288817000073],
              [106.49605261000002, 17.911037317000122],
              [106.49612333200011, 17.909277971000037],
              [106.49561914800005, 17.907624608000013],
              [106.494648205, 17.906957929],
            ],
          ],
          [
            [
              [106.53221044400009, 17.932073067000061],
              [106.53037559200006, 17.931730671000068],
              [106.52744578900003, 17.931930777000012],
              [106.52531856500006, 17.932301278000025],
              [106.52462549600008, 17.932846512000111],
              [106.52427569700011, 17.933559056],
              [106.52441310700002, 17.934384904000034],
              [106.52423146700008, 17.935648529000048],
              [106.52318652200005, 17.93718125000003],
              [106.52306188600004, 17.938500264000098],
              [106.52363115500006, 17.939164066000046],
              [106.52460438400003, 17.939555651000113],
              [106.52977807100009, 17.938765876000048],
              [106.53308850000008, 17.937578419000118],
              [106.53378358200004, 17.936758209000025],
              [106.53385236600005, 17.935218840000019],
              [106.53323661200008, 17.933069908000029],
              [106.53221044400009, 17.932073067000061],
            ],
          ],
          [
            [
              [106.52077834500001, 17.940574477000034],
              [106.51991707900007, 17.940568601000081],
              [106.51928466800005, 17.940674274000031],
              [106.51703141600005, 17.94252867400008],
              [106.51644820000011, 17.943734545000019],
              [106.51672995700007, 17.944451391000094],
              [106.51804608700007, 17.945065327000087],
              [106.520109484, 17.945574356000101],
              [106.52102901500005, 17.945470640000046],
              [106.52229836400001, 17.944654385000099],
              [106.52276835300009, 17.943227750000098],
              [106.52254889800005, 17.941851402000083],
              [106.52215146800003, 17.941243764000077],
              [106.52077834500001, 17.940574477000034],
            ],
          ],
          [
            [
              [105.91627419700008, 18.082010229000019],
              [105.91661139300001, 18.081826605000103],
              [105.91718219600007, 18.081864351000029],
              [105.91752158900009, 18.082105416000083],
              [105.91964491800009, 18.084016541000025],
              [105.920302904, 18.084559435000088],
              [105.92411904400009, 18.086846687000069],
              [105.92847614500008, 18.089505389000045],
              [105.92893039200001, 18.089774015000067],
              [105.92932418200009, 18.090006886000062],
              [105.93003106800003, 18.090145044000039],
              [105.93052957200007, 18.090142637000014],
              [105.93098347800006, 18.09004943800003],
              [105.93174185400002, 18.089580644000058],
              [105.93214009500011, 18.088951794000074],
              [105.93255797200004, 18.088039714000011],
              [105.93368647200006, 18.085627656000014],
              [105.9368223920001, 18.081173406000062],
              [105.937966676, 18.079762315000011],
              [105.94091085200007, 18.077038040000026],
              [105.94791824500007, 18.067518961000047],
              [105.94841011199999, 18.066636831000068],
              [105.94840446100005, 18.065585233000014],
              [105.94813939400007, 18.063442844000029],
              [105.94802928200001, 18.060642431000048],
              [105.95072366600002, 18.058707965000039],
              [105.95331404500011, 18.059049115000022],
              [105.9579908680001, 18.060320271000037],
              [105.96053714600001, 18.060317747000099],
              [105.96123247499999, 18.059950264000065],
              [105.96182149100005, 18.059461973000076],
              [105.96371340900001, 18.057652659000034],
              [105.96497558500008, 18.056614968000062],
              [105.96545040600002, 18.056501371000074],
              [105.96585187700002, 18.056499369000086],
              [105.96625455800003, 18.056719800000032],
              [105.96691146900011, 18.057060317000051],
              [105.96881198100007, 18.058759674000058],
              [105.97297632700001, 18.062884530000112],
              [105.97463122100007, 18.064089597000077],
              [105.97564795300008, 18.064529367000134],
              [105.97665318400004, 18.064797299000098],
              [105.97976155400001, 18.065165809000035],
              [105.98102941100011, 18.065159371000036],
              [105.98284690400003, 18.065190573000045],
              [105.98359688400009, 18.065156420000058],
              [105.98425047800008, 18.064890192000057],
              [105.99251744600011, 18.054412675],
              [105.993565066, 18.052829880000054],
              [105.99535264000012, 18.051324161000082],
              [105.99887630100008, 18.048565731000018],
              [106.00093755200001, 18.046896758000038],
              [106.00229558800001, 18.046050463000093],
              [106.01263618700003, 18.036451228000061],
              [106.0137899640001, 18.035009339000041],
              [106.01476968200006, 18.030848322000011],
              [106.01514590400011, 18.030138542000081],
              [106.01563860600001, 18.029478691000108],
              [106.01639452600003, 18.028665803000088],
              [106.01857933600006, 18.026510655000024],
              [106.02028413600007, 18.025389395000069],
              [106.03091488400001, 18.022461373000098],
              [106.03184369200004, 18.022335088000126],
              [106.03254083500008, 18.022331367000085],
              [106.03292204500009, 18.022491118000069],
              [106.03309269300009, 18.022773337000061],
              [106.03448474800007, 18.026001589000096],
              [106.03496238400007, 18.026393390000095],
              [106.0354073280001, 18.026613463],
              [106.04406369800003, 18.029256614000118],
              [106.04434956100005, 18.029366293000045],
              [106.04468745700004, 18.029344248000086],
              [106.04504529000006, 18.029119863000084],
              [106.04527612900006, 18.028855719000077],
              [106.04554658400006, 18.028146447000012],
              [106.04869386300005, 18.019120045000122],
              [106.049270662, 18.018419229000045],
              [106.04977670800012, 18.018254694000021],
              [106.05655318400001, 18.016425381000097],
              [106.05835992200011, 18.013752516000103],
              [106.05783539700005, 18.011175686000058],
              [106.05760995900005, 18.009244575000061],
              [106.05799012500006, 18.007844991000077],
              [106.05891707400011, 18.00674258600008],
              [106.0624313160001, 18.00338344400005],
              [106.06528430200007, 17.999900189000059],
              [106.07506948500004, 17.991278202000061],
              [106.07581419100011, 17.990057183000104],
              [106.0753364080001, 17.988942230000049],
              [106.07369438500004, 17.988226162000039],
              [106.06955089600004, 17.98725399200007],
              [106.06736555500007, 17.985889983000014],
              [106.06598492000005, 17.983422416000039],
              [106.06423680100009, 17.981068470000046],
              [106.06042108100003, 17.977819150000052],
              [106.05695578200002, 17.975272980000057],
              [106.05469314900003, 17.973481626000073],
              [106.04829052800008, 17.968530052000098],
              [106.04693178000009, 17.967367768000116],
              [106.04641207800009, 17.966570303000125],
              [106.04569781400005, 17.965527654000098],
              [106.04556652900003, 17.96509744600003],
              [106.04575457600004, 17.964234599000051],
              [106.04766254000008, 17.959410825000077],
              [106.047475967, 17.958756577000123],
              [106.04715107000004, 17.957884638000095],
              [106.04636424100003, 17.95614151700002],
              [106.04410280800006, 17.948312376000075],
              [106.04391676100003, 17.947745480000066],
              [106.04391420500009, 17.947308648000067],
              [106.04423266000006, 17.947088520000065],
              [106.05729023500007, 17.941074323000095],
              [106.06519432800009, 17.938382231000013],
              [106.07498746500009, 17.935564932000055],
              [106.08047333500005, 17.934409272],
              [106.08513722600003, 17.934096307000047],
              [106.09047300900008, 17.933151590000037],
              [106.09566419200009, 17.931851268000095],
              [106.10138056000001, 17.929776177000086],
              [106.10760720900007, 17.928484639000075],
              [106.11286540100011, 17.927892899000049],
              [106.11909392800004, 17.92638851400001],
              [106.12622405500007, 17.923404104000085],
              [106.12996917400005, 17.922174396000031],
              [106.13285672200004, 17.92191592100005],
              [106.13826260100004, 17.921324827000085],
              [106.14641463000007, 17.919765328000011],
              [106.153530946, 17.918196705000078],
              [106.16020399000004, 17.916553246000078],
              [106.16716691500008, 17.915549596000076],
              [106.1713085260001, 17.915655403000038],
              [106.17359050900009, 17.916878923000112],
              [106.17603935400003, 17.91940053900003],
              [106.17863042900009, 17.921072163000048],
              [106.18294509500005, 17.922673601000035],
              [106.18647164700008, 17.923506885000059],
              [106.18902987200008, 17.923951298000063],
              [106.19071155600005, 17.923626911000042],
              [106.1919134820001, 17.922621647000092],
              [106.19286036300012, 17.92051435700008],
              [106.19380068800002, 17.919126169000066],
              [106.19517964400008, 17.918080062000016],
              [106.1969960910001, 17.91750291500005],
              [106.198845588, 17.918152848000091],
              [106.20077654300007, 17.91956490000004],
              [106.20243674400004, 17.921609241000077],
              [106.20461942800007, 17.92450396900005],
              [106.20707675900009, 17.926343348],
              [106.21175141300006, 17.928159811000022],
              [106.2172746680001, 17.930024111000087],
              [106.22288596900003, 17.930903784000037],
              [106.2285899820001, 17.930268395000049],
              [106.23055662300001, 17.930124829000071],
              [106.23231281200002, 17.929919859000073],
              [106.23579418700004, 17.930023989000077],
              [106.23872295600006, 17.929972014000036],
              [106.24126407300008, 17.929007440000078],
              [106.24612220700006, 17.925560721000089],
              [106.25352445900003, 17.92077026500003],
              [106.25846197000001, 17.917248103000105],
              [106.26068762900005, 17.916129199000075],
              [106.26247415500006, 17.916070042000065],
              [106.26452492600001, 17.917463358000063],
              [106.26838017900005, 17.91982890600012],
              [106.27093997600011, 17.920208514000088],
              [106.27288040200008, 17.919924659000053],
              [106.27539121299999, 17.918747342000074],
              [106.27797383200007, 17.916493009000064],
              [106.28425574500008, 17.92019455000009],
              [106.28482814900011, 17.92630526800005],
              [106.28405836000009, 17.928574019000095],
              [106.28216226700006, 17.930953580000072],
              [106.27951958400007, 17.93290815000006],
              [106.27919093900006, 17.934761332000093],
              [106.2807888880001, 17.937946795000045],
              [106.283875912, 17.942461033000079],
              [106.28542466100006, 17.943598127000101],
              [106.28699072900007, 17.943959915000015],
              [106.29135122700008, 17.9443763660001],
              [106.29388071000002, 17.945714107000029],
              [106.29522654500009, 17.949272681000124],
              [106.29677113499999, 17.954353374000043],
              [106.30053830100012, 17.959552822000099],
              [106.30411381000002, 17.962419178000033],
              [106.30918379100011, 17.963877937000028],
              [106.31638600800005, 17.963629847000014],
              [106.32019671500005, 17.963760769000103],
              [106.32293497000003, 17.965505246000113],
              [106.3250063830001, 17.970995216000048],
              [106.32705912200004, 17.978715125000107],
              [106.32977974800008, 17.982588103000104],
              [106.33397358000006, 17.987587410000039],
              [106.33829640400006, 17.98985066300007],
              [106.34390355000002, 17.990501753000032],
              [106.35205718800009, 17.990563844000107],
              [106.35745345400008, 17.991111607000043],
              [106.36264633000005, 17.990644024000076],
              [106.37071153400007, 17.988576009000035],
              [106.37715170600003, 17.986312507000065],
              [106.38266619700005, 17.984205813000109],
              [106.38738483700011, 17.983381789000049],
              [106.39345048500007, 17.982460240000094],
              [106.39693332400003, 17.981841641000074],
              [106.40008650100006, 17.980361402000078],
              [106.403930841, 17.976630913000058],
              [106.40675165900008, 17.974611145],
              [106.41000995800002, 17.973990685],
              [106.41314748200008, 17.974443238000056],
              [106.41584231000004, 17.974140751000078],
              [106.41798308100007, 17.972975034000079],
              [106.42035073800012, 17.971488763000117],
              [106.42248719100006, 17.97085993300005],
              [106.42573771000006, 17.971205732000058],
              [106.42976584400003, 17.972523674000037],
              [106.43301556600005, 17.972976752000044],
              [106.43548598300001, 17.972672400000086],
              [106.43942649800007, 17.970875067000058],
              [106.44168516600008, 17.968958199000085],
              [106.44249227700007, 17.966171718000098],
              [106.44221637900007, 17.958437254000081],
              [106.44282827299999, 17.953649917000099],
              [106.44496999000009, 17.951813662000049],
              [106.44826328300009, 17.95109660600005],
              [106.45134944600008, 17.952137051000072],
              [106.45664446200006, 17.955137373000071],
              [106.46098846900004, 17.956094048000089],
              [106.46804209500004, 17.95679204600011],
              [106.47307727900009, 17.95590174400008],
              [106.47666416100004, 17.954723422000107],
              [106.47879211, 17.954645773000074],
              [106.4830324430001, 17.956527077000125],
              [106.48977426200005, 17.96009217700005],
              [106.49392304300004, 17.961324561000055],
              [106.49836545800008, 17.962096006000117],
              [106.50233140200004, 17.961938267000029],
              [106.50668900800008, 17.961135083],
              [106.5084594410001, 17.961022588000027],
              [106.5082716310001, 17.960358756000083],
              [106.50754351500008, 17.959907959000084],
              [106.50515251700004, 17.959509401000062],
              [106.50369392200007, 17.958926191000032],
              [106.50296870100001, 17.958093305000133],
              [106.50271279300006, 17.956754199000088],
              [106.50279464000009, 17.9547168940001],
              [106.50375674400001, 17.950584117000098],
              [106.5053153110001, 17.946710165000113],
              [106.50659086000005, 17.945095018000053],
              [106.50779249000007, 17.944466452000093],
              [106.50925673400008, 17.944285469000043],
              [106.51244879800005, 17.944243707000048],
              [106.51344901800012, 17.94386846000009],
              [106.51398714300005, 17.943044257000018],
              [106.51385939900005, 17.942342857000071],
              [106.51267114800007, 17.941188411000027],
              [106.50942602400005, 17.939446660000065],
              [106.50697643900006, 17.93802876600008],
              [106.50519272900009, 17.936488058000052],
              [106.50301971000006, 17.933670972000115],
              [106.49960827100006, 17.927565544000053],
              [106.49829588200001, 17.925263815000037],
              [106.49691270900004, 17.923534751000055],
              [106.49605564000009, 17.92257353900002],
              [106.49546017700004, 17.922187297000036],
              [106.49439595500009, 17.922243599000076],
              [106.49152893300001, 17.923306312000072],
              [106.48873320500006, 17.923732637000086],
              [106.48687042600004, 17.923878859000105],
              [106.48427996800011, 17.923542346000062],
              [106.48315363000009, 17.923024991000069],
              [106.48176621500004, 17.921868956000075],
              [106.47999161400003, 17.919181765000047],
              [106.47828647299998, 17.916112929000057],
              [106.47387571800007, 17.910413951000059],
              [106.46972156900009, 17.905958536000099],
              [106.46814424700005, 17.903591037000055],
              [106.46729330600007, 17.9018655140001],
              [106.46604991800005, 17.899309304000077],
              [106.46336476500001, 17.894068044000022],
              [106.46100916400006, 17.889211191000065],
              [106.45964334600004, 17.885316626000055],
              [106.45866330900003, 17.883112491000119],
              [106.45781401100001, 17.881195848000075],
              [106.45702378900009, 17.88023492700006],
              [106.45616413200003, 17.879655632],
              [106.45443765400007, 17.879420424000081],
              [106.45144677900007, 17.879367237000032],
              [106.44972155000005, 17.878972793000081],
              [106.44674237700011, 17.877422984000098],
              [106.44848001300008, 17.876225382000129],
              [106.44935155600007, 17.875276315000029],
              [106.44976029100005, 17.874005493000041],
              [106.44957330900003, 17.872411984000088],
              [106.44852822800007, 17.870048091000079],
              [106.44604233500009, 17.864935315000082],
              [106.44395859100007, 17.859411406000092],
              [106.44305105900004, 17.856475259000071],
              [106.4426062740001, 17.853860875000024],
              [106.44225691700005, 17.847553262000112],
              [106.4416454970001, 17.840766077000048],
              [106.44149155100006, 17.834969322000035],
              [106.441793557, 17.830354070000084],
              [106.44297511900007, 17.823738917000043],
              [106.44476212100004, 17.816172736000091],
              [106.44792635900009, 17.802406624000078],
              [106.45076135600002, 17.791992742000048],
              [106.45430286100003, 17.780208548000061],
              [106.45938585900004, 17.765522129000111],
              [106.46248657100008, 17.757513587000062],
              [106.47079694000007, 17.739464097000024],
              [106.47616631600006, 17.729975308000064],
              [106.481314884, 17.722453118000111],
              [106.48567408200009, 17.716106249000063],
              [106.48659160600006, 17.714223069000035],
              [106.48826001900008, 17.71077044200009],
              [106.48863489400001, 17.709078141000042],
              [106.48798405700008, 17.708286260000072],
              [106.48734545000008, 17.70798042300008],
              [106.48924874900008, 17.705856303000097],
              [106.49038896300002, 17.704583768000056],
              [106.49150491400007, 17.703338291000122],
              [106.49268108700004, 17.702852087000039],
              [106.49375507100008, 17.701583698000078],
              [106.49576733600004, 17.695808762000098],
              [106.49855549000009, 17.683585420000014],
              [106.49993249100007, 17.680245714000087],
              [106.50393272000011, 17.672602504000068],
              [106.51026189800002, 17.662274186000012],
              [106.51605943000001, 17.655183242000035],
              [106.51969976700012, 17.650346275000039],
              [106.52302619300011, 17.646047297000052],
              [106.52433660499999, 17.644003423000051],
              [106.52434261300004, 17.643193149000069],
              [106.52406826900008, 17.642218894000017],
              [106.52345044100012, 17.641998596000086],
              [106.52243723700005, 17.641937658000103],
              [106.52046151500008, 17.642572423000047],
              [106.51877135500004, 17.642668909000037],
              [106.51792868800004, 17.642393040000037],
              [106.51664439800001, 17.64092566300004],
              [106.5159831550001, 17.638976359000047],
              [106.51580589700008, 17.635820217000031],
              [106.51851204300007, 17.637982913000094],
              [106.5198433850001, 17.638489541000091],
              [106.52094371900002, 17.638308029000079],
              [106.52249706400002, 17.637670376000081],
              [106.52436932100004, 17.635792386000034],
              [106.52646917800004, 17.633591807000037],
              [106.52868400200008, 17.631067855000104],
              [106.53027101500003, 17.629674070000064],
              [106.53236676000006, 17.628013607000078],
              [106.53650183400006, 17.624692244000073],
              [106.53848168900001, 17.623463120000068],
              [106.54000671600006, 17.622825146000039],
              [106.54203967600006, 17.622028513000117],
              [106.54350955500009, 17.621228082000059],
              [106.54475855500004, 17.619831908],
              [106.54575771500002, 17.617947854000079],
              [106.54971043200004, 17.60868254500005],
              [106.55334528600005, 17.600495445000064],
              [106.55700133600004, 17.593226781000034],
              [106.56045383800007, 17.586821043000086],
              [106.5666651590001, 17.576435781000029],
              [106.57250181200003, 17.567182313],
              [106.57767865500008, 17.559599053000063],
              [106.58094932400007, 17.554973835000084],
              [106.58295315800005, 17.552139976000092],
              [106.58532543000004, 17.548952419000052],
              [106.58800607800006, 17.543999696000064],
              [106.59088090400007, 17.539426366000029],
              [106.59417733300006, 17.534909748000068],
              [106.598210667, 17.53030107500004],
              [106.59911371600003, 17.529269168000013],
              [106.60743501100011, 17.518409852000083],
              [106.61416695100004, 17.509376844000087],
              [106.62007523900004, 17.50136482300011],
              [106.623847932, 17.496958754000055],
              [106.6252703600001, 17.494698718000024],
              [106.62595975900004, 17.492650118000071],
              [106.62623382800007, 17.489626411000089],
              [106.62598645200002, 17.487724037000071],
              [106.62768701500003, 17.484692078000116],
              [106.62864885500004, 17.483125980000032],
              [106.62957865800006, 17.483974922000108],
              [106.6301405840001, 17.484086534000106],
              [106.63087243100009, 17.484037145000016],
              [106.63160834900003, 17.483393489000051],
              [106.63383128500003, 17.479247523000119],
              [106.63707576700007, 17.473811343000101],
              [106.64060211800007, 17.468268817000073],
              [106.64372451100004, 17.464182400000091],
              [106.64914327600005, 17.457462977000127],
              [106.65266242600008, 17.452892641000069],
              [106.65859161100012, 17.445473767000053],
              [106.6619931070001, 17.441604881000039],
              [106.67097405100004, 17.431989365000078],
              [106.67420596800011, 17.428173243000074],
              [106.6832829520001, 17.417535617000098],
              [106.68392872700007, 17.416778751],
              [106.69324850800007, 17.406623979000102],
              [106.69681775900007, 17.402701455000091],
              [106.7048314470001, 17.394267153000072],
              [106.71031872200008, 17.389383350000017],
              [106.71900581200006, 17.381006512000049],
              [106.72359085900008, 17.376387145000116],
              [106.73272670000006, 17.368012355000047],
              [106.73459615800002, 17.36586213800004],
              [106.73686297700007, 17.36311990600008],
              [106.74260506300001, 17.357696419000135],
              [106.74736314400005, 17.35221303200003],
              [106.75107633500006, 17.347425883000064],
              [106.75362116100004, 17.345117288000012],
              [106.75785845900006, 17.341899890000079],
              [106.7635642110001, 17.337610211000062],
              [106.77438496200003, 17.328973003000044],
              [106.79156666200004, 17.314265453000118],
              [106.79858139100001, 17.308109732000077],
              [106.80343565600002, 17.303849543000105],
              [106.81202369900006, 17.296656785000053],
              [106.82422083700001, 17.287430147000023],
              [106.8302084540001, 17.282491768000071],
              [106.83455308900004, 17.279705614000115],
              [106.84561774399999, 17.271552224000075],
              [106.85227697000005, 17.266940979000097],
              [106.85927744300008, 17.261574876000019],
              [106.86650235200005, 17.256209712000015],
              [106.89375879300007, 17.236467156000046],
              [106.90087125900007, 17.230776018000071],
              [106.91278223200005, 17.221002350000077],
              [106.92203706900003, 17.213808330000099],
              [106.93027229200008, 17.208013707000092],
              [106.94263397000006, 17.197484069000076],
              [106.94624419100009, 17.194800046000047],
              [106.95470437200002, 17.18868134400013],
              [106.9612436850001, 17.18449827700006],
              [106.97201030300009, 17.177633482000054],
              [106.97922693000007, 17.172696601000048],
              [106.98956204500007, 17.165070406000012],
              [106.980126688, 17.15528038300009],
              [106.96794447800004, 17.142640255000074],
              [106.9660442520001, 17.140792365000031],
              [106.93996402000005, 17.115425135000081],
              [106.93741790900006, 17.113057890000057],
              [106.93440564300005, 17.110852698000123],
              [106.9257211820001, 17.107595923000041],
              [106.92545942700004, 17.107509288000067],
              [106.92512193100001, 17.107588963000083],
              [106.92486194100007, 17.107823331000112],
              [106.92457302400005, 17.10806608300004],
              [106.92424166500008, 17.108229236000035],
              [106.92382622600009, 17.108313182000053],
              [106.92340956600007, 17.108277142000091],
              [106.9228247400001, 17.108082695000078],
              [106.92101038400007, 17.107303861000034],
              [106.91644178300007, 17.104838128000033],
              [106.91422789800004, 17.10363181900005],
              [106.91210103, 17.102715315000069],
              [106.91078991100005, 17.101863767000047],
              [106.90869240000006, 17.100527098000029],
              [106.90539282400007, 17.098313559000111],
              [106.90404838700005, 17.097304557000115],
              [106.90178573800006, 17.095861747000107],
              [106.90109178800002, 17.095665895000067],
              [106.8968719710001, 17.093407169000052],
              [106.89623673500003, 17.093066213000085],
              [106.894906388, 17.092413751],
              [106.89372557500009, 17.091673155000109],
              [106.88946040500005, 17.087882503000081],
              [106.88906351800009, 17.087308024000066],
              [106.88884799700001, 17.08681858200007],
              [106.8886859400001, 17.085663725000089],
              [106.88834515800006, 17.084683988000023],
              [106.88785803700009, 17.084110348000081],
              [106.887311622, 17.083623968000069],
              [106.885463277, 17.082468098000092],
              [106.87968442500005, 17.081605125000095],
              [106.87874476200003, 17.081463571000107],
              [106.87749419800011, 17.081505208000088],
              [106.87599495800005, 17.081699412000084],
              [106.87474467600008, 17.08177108000006],
              [106.87295947300007, 17.0815171],
              [106.86519147500003, 17.080326444000079],
              [106.86290326000007, 17.079806506000047],
              [106.86108263000006, 17.079131985000103],
              [106.85941510500002, 17.078125443000133],
              [106.85693603600006, 17.075698746000079],
              [106.85582995000004, 17.074536770000037],
              [106.85528883500002, 17.073579983000094],
              [106.85524954700006, 17.072768879000044],
              [106.85527486900003, 17.072167562000025],
              [106.85551760400004, 17.071413990000025],
              [106.8561195070001, 17.070612044000107],
              [106.85655283300008, 17.070157273000049],
              [106.85785845000001, 17.069363910000042],
              [106.86009917700005, 17.068261426000042],
              [106.86403169300009, 17.065896138000092],
              [106.869657864, 17.060900497000105],
              [106.87114497800005, 17.059504307000047],
              [106.87244416100006, 17.058079791000047],
              [106.87296642600003, 17.0571433040001],
              [106.87323944500005, 17.056299269000071],
              [106.873279607, 17.055622688000049],
              [106.87305174700006, 17.054723174000053],
              [106.87247994100001, 17.053826824000048],
              [106.87191024300003, 17.053140834000132],
              [106.86921103500008, 17.050566032000077],
              [106.86703362300004, 17.048572437000061],
              [106.86379587300011, 17.045281202000062],
              [106.86089112800002, 17.042452681000064],
              [106.85968817500006, 17.040960986000094],
              [106.85940083600008, 17.04036254300005],
              [106.85895391100006, 17.039434942000042],
              [106.8583199980001, 17.038569163000084],
              [106.85765511800005, 17.037733734000057],
              [106.85587932900003, 17.036014326000078],
              [106.85367932600008, 17.03476670900006],
              [106.850764908, 17.033747431],
              [106.84877033599999, 17.033176756000046],
              [106.847926853, 17.033049324000068],
              [106.84652324500004, 17.033062077000061],
              [106.84493648400003, 17.033481797000057],
              [106.84424964600004, 17.033872900000091],
              [106.84384432100008, 17.034060804000092],
              [106.84356576800003, 17.034356301000031],
              [106.84336827800004, 17.03458497700003],
              [106.84287963900002, 17.035195300000083],
              [106.84230571900005, 17.035783008000038],
              [106.84180736700003, 17.036015473000084],
              [106.84101846500003, 17.036174578000015],
              [106.8403868770001, 17.036256279000028],
              [106.83935862200011, 17.036189608000107],
              [106.83603571500005, 17.035890410000043],
              [106.83505965600003, 17.035772597000062],
              [106.83379181200011, 17.03545479400006],
              [106.83284209700005, 17.035336723000057],
              [106.83189485300001, 17.035471903000051],
              [106.83131695400007, 17.035654397000052],
              [106.8221533390001, 17.038978598000121],
              [106.82086721900001, 17.039496674000084],
              [106.82002875900008, 17.039985386000112],
              [106.81861824, 17.041264367000061],
              [106.81772657400003, 17.041702908000019],
              [106.81722646700004, 17.04175802700005],
              [106.81664583200005, 17.041661924],
              [106.81564094900003, 17.041290997000132],
              [106.81471314700009, 17.040716756000052],
              [106.80902674500007, 17.035778050000069],
              [106.80868155800002, 17.03550253400001],
              [106.80841492300007, 17.035175650000056],
              [106.80808950700006, 17.034216118000067],
              [106.80802416300007, 17.032899699000033],
              [106.80807052600008, 17.032240778000052],
              [106.80830059200011, 17.03150424400004],
              [106.80855747500006, 17.030818136000022],
              [106.80902245700007, 17.029851566000076],
              [106.80909565500008, 17.029243062000084],
              [106.80906443599999, 17.02873680500003],
              [106.80889906100008, 17.027978470000079],
              [106.80849754600007, 17.027323533000072],
              [106.80777771100001, 17.026443485000087],
              [106.80689881200004, 17.025463539],
              [106.80586137000009, 17.024434348000028],
              [106.80235902900003, 17.021882059000056],
              [106.8020399700001, 17.021580957000104],
              [106.80156091000006, 17.021078650000021],
              [106.80134530400008, 17.020574023],
              [106.80134191700007, 17.020219470000072],
              [106.80146757700007, 17.019585184000086],
              [106.80179964700002, 17.01849317900006],
              [106.80218657500006, 17.017628630000026],
              [106.80270716200005, 17.016965500000047],
              [106.80309578300006, 17.016278230000111],
              [106.80350128300003, 17.014603046000062],
              [106.8043435220001, 17.011758899000107],
              [106.80585847400002, 17.007642446000126],
              [106.80598679200007, 17.00728673400009],
              [106.80650078300006, 17.005939841000028],
              [106.80696184100002, 17.004568075000037],
              [106.80708457600005, 17.003629889000123],
              [106.80710144700002, 17.002641981000096],
              [106.80693853800004, 17.002136886000052],
              [106.80653732500006, 17.00150726],
              [106.80532633900005, 16.999993986000028],
              [106.80501473200012, 16.999503219000097],
              [106.80427727300003, 16.998327391000068],
              [106.80395162700009, 16.99749825000012],
              [106.80357767500004, 16.996362986000065],
              [106.80324491200005, 16.994789458000078],
              [106.80287097800002, 16.993654191000118],
              [106.8022289260001, 16.992959218000081],
              [106.80154484000008, 16.992614938000102],
              [106.80052186500008, 16.992426927000089],
              [106.79902048000011, 16.992046079000112],
              [106.79710943200008, 16.991537448000123],
              [106.79610769400006, 16.991195959000024],
              [106.79515008000006, 16.990722683000037],
              [106.79391560600006, 16.989770150000091],
              [106.79263243900002, 16.988467703000062],
              [106.79130598300006, 16.987384588000062],
              [106.79030469900007, 16.987086850000118],
              [106.7890340230001, 16.987098014000068],
              [106.78740196600006, 16.987287496000114],
              [106.78527277600001, 16.987700298000071],
              [106.78413906, 16.987797816000061],
              [106.78304784200004, 16.98758840600005],
              [106.78231884600004, 16.987288240000083],
              [106.78142501400006, 16.986354533000011],
              [106.78068816200002, 16.985222397000129],
              [106.77953588499999, 16.983349426000061],
              [106.77925865300006, 16.982826346000124],
              [106.77761919800002, 16.977410471],
              [106.77761135900009, 16.976578504000095],
              [106.77775306800001, 16.974759905000077],
              [106.77828318399999, 16.973222564000068],
              [106.77921754700003, 16.971243790000031],
              [106.77943824900008, 16.970584984000055],
              [106.77933635700001, 16.969403491],
              [106.77887269100009, 16.968356540000059],
              [106.7781363500001, 16.967268167000071],
              [106.7769437860001, 16.965921008000073],
              [106.77634978000006, 16.965488267000062],
              [106.77507636200005, 16.965192821000038],
              [106.77357606800007, 16.96489932300009],
              [106.77218819500008, 16.964495367000062],
              [106.77141064800006, 16.963845239000094],
              [106.77076799200009, 16.963062551000085],
              [106.77034976300003, 16.962015186000109],
              [106.77034279100008, 16.961270781000067],
              [106.770427791, 16.960656953000083],
              [106.77051485300009, 16.96026207700001],
              [106.77268392400006, 16.952032237000125],
              [106.77334070200006, 16.949486606000086],
              [106.77417668800004, 16.946698552000058],
              [106.77257780900008, 16.945551955000049],
              [106.77107110200002, 16.944557809000038],
              [106.76956155800009, 16.943257139000067],
              [106.76829850500003, 16.941625875000099],
              [106.767927775, 16.940797038000085],
              [106.76791591300008, 16.93952717000009],
              [106.76809125400003, 16.938868770000056],
              [106.76866837400009, 16.93750622100012],
              [106.76955488800007, 16.935265140000055],
              [106.76981604700009, 16.934080491000088],
              [106.770297902, 16.932237054000062],
              [106.77029175900003, 16.931580228000072],
              [106.76855999200009, 16.93076317700006],
              [106.76630508300003, 16.929797358000087],
              [106.76443356099999, 16.928587343000068],
              [106.76329570700008, 16.928203029000066],
              [106.76041171200002, 16.927666963000028],
              [106.75279669000003, 16.926235105000039],
              [106.75197967400001, 16.926198319000015],
              [106.75089328700001, 16.926470376000054],
              [106.74953509699999, 16.926788557000094],
              [106.74876384300003, 16.926795158000104],
              [106.74796889900009, 16.926692477000046],
              [106.7471028790001, 16.926261960000133],
              [106.74550451600001, 16.925137003000131],
              [106.74335773900002, 16.923578797000083],
              [106.74239939500004, 16.922973862000035],
              [106.74190035500006, 16.922978116000117],
              [106.74153782200008, 16.923025003000092],
              [106.74117649600002, 16.923203247000096],
              [106.74054618900001, 16.923734133000053],
              [106.73973601300003, 16.924441716000068],
              [106.73775231100004, 16.925816156000018],
              [106.73459221300004, 16.927550923000112],
              [106.73206240900006, 16.928754784000063],
              [106.73014084100006, 16.929493649000094],
              [106.72805747200007, 16.929905409000057],
              [106.72556577600007, 16.930320604000123],
              [106.72352616400006, 16.930556778000039],
              [106.722033368, 16.931051086000082],
              [106.721086579, 16.931715960000112],
              [106.72032364300003, 16.932642039000086],
              [106.71987870200007, 16.933609236000088],
              [106.71989978900004, 16.935930091],
              [106.71981223900008, 16.936281171000097],
              [106.71913963400003, 16.937162687000026],
              [106.71810724100004, 16.938397580000064],
              [106.71639902000001, 16.940163671000086],
              [106.71408320299999, 16.942482256000076],
              [106.71304638, 16.943235444000045],
              [106.71155345300011, 16.943729686000083],
              [106.71060141400002, 16.943825246000088],
              [106.70973933800011, 16.943832470000061],
              [106.70792047700007, 16.943409754000079],
              [106.70632768900001, 16.942897559000066],
              [106.70487300900008, 16.942603155000043],
              [106.70219287900001, 16.942275169000084],
              [106.70028487800006, 16.942028295000085],
              [106.69965871100004, 16.943040754000016],
              [106.69890034300008, 16.944492256000032],
              [106.69775453500004, 16.945749891000084],
              [106.69698907100003, 16.946413157000073],
              [106.69586377200001, 16.947429753000073],
              [106.69474079700009, 16.948709093000055],
              [106.69397924300004, 16.949810255000038],
              [106.69335615700001, 16.951173021000088],
              [106.69273542400008, 16.952798527000112],
              [106.69225118000006, 16.954466694000111],
              [106.69180761100004, 16.955609008000039],
              [106.69131436300009, 16.956270003000014],
              [106.6908163950001, 16.956405505000035],
              [106.69027266800005, 16.95649759400002],
              [106.68768622500001, 16.956519003000011],
              [106.6866899, 16.95674621000007],
              [106.68535656600008, 16.957348439000071],
              [106.68458829500001, 16.957705129000097],
              [106.68367412400005, 16.956968180000054],
              [106.68111357100003, 16.954799605000034],
              [106.67951374800008, 16.953498961000065],
              [106.67873768400008, 16.952979827000014],
              [106.67727944600004, 16.952291111000051],
              [106.67477488300003, 16.951304418000035],
              [106.67290830000003, 16.95061903100008],
              [106.67068295700003, 16.949908127000057],
              [106.66876997200002, 16.94933469500004],
              [106.66627471600006, 16.948597174000035],
              [106.66528576300004, 16.948163194000095],
              [106.66492006000007, 16.947859622000024],
              [106.66427941600008, 16.94725172200009],
              [106.6637729880001, 16.946423760000059],
              [106.66298544900005, 16.944590835000014],
              [106.66196683300005, 16.942278030000054],
              [106.66053796800004, 16.939749596000105],
              [106.65961975500001, 16.938530825000072],
              [106.65884188800004, 16.937792643000101],
              [106.65824936600005, 16.937490894000092],
              [106.65700028, 16.937347751000061],
              [106.65514041100002, 16.937406631000052],
              [106.65291715700002, 16.937424635000028],
              [106.65019518800004, 16.937490428000054],
              [106.64856178200007, 16.937503617000083],
              [106.64756587000009, 16.937774424000018],
              [106.64395126300008, 16.939555327000022],
              [106.64254773800012, 16.939916971000102],
              [106.64123266900002, 16.940015136000049],
              [106.63903338700004, 16.940186075000071],
              [106.63622515700003, 16.940777913000105],
              [106.63346152600008, 16.941281780000047],
              [106.63065439000007, 16.942004930000017],
              [106.628887804, 16.942369386000067],
              [106.62793494800003, 16.94237698400007],
              [106.62734358300004, 16.942206509000087],
              [106.62679608100004, 16.941860521000059],
              [106.6262470720001, 16.941339348000014],
              [106.62542092800004, 16.940251053000033],
              [106.62450781900007, 16.939601399000104],
              [106.62332546900004, 16.939304245000116],
              [106.62241762300005, 16.93926765800002],
              [106.62169165100002, 16.939273422000056],
              [106.621035416, 16.939475709000092],
              [106.62026669400008, 16.939788373000027],
              [106.6192257270001, 16.940103183000055],
              [106.61850048500004, 16.940196524000051],
              [106.61682127900011, 16.940166036000015],
              [106.614051594, 16.939968964000052],
              [106.60974032400007, 16.93991539600006],
              [106.60688361700005, 16.940156872000053],
              [106.60561425500001, 16.940298232000117],
              [106.604620106, 16.940787804000124],
              [106.60367392200004, 16.941583545000057],
              [106.60205416100004, 16.943216678000091],
              [106.59998580400006, 16.945466448000076],
              [106.59859285, 16.947097787000054],
              [106.59805685300006, 16.948109269000078],
              [106.59795024200004, 16.948920324000127],
              [106.59795727500008, 16.949752370000049],
              [106.598109693, 16.951678175000048],
              [106.5982407210001, 16.953757418000045],
              [106.59829201800008, 16.954457737000084],
              [106.598394259, 16.955814596000081],
              [106.59835295700006, 16.956296659000088],
              [106.59822015200008, 16.956691860000106],
              [106.59713921300008, 16.957663816000057],
              [106.59533109000004, 16.958510075000085],
              [106.59320382500007, 16.959183617000036],
              [106.59193615400008, 16.95954387600009],
              [106.59144030900009, 16.959941908000076],
              [106.59121784300007, 16.960469185000093],
              [106.59113186600005, 16.961039198],
              [106.59127907000007, 16.96235190200008],
              [106.59142517599999, 16.963533238000124],
              [106.59138642500007, 16.964321856000097],
              [106.59100825900003, 16.965222612000019],
              [106.58890447100006, 16.968698842000052],
              [106.588098648, 16.97001898400012],
              [106.58593539300006, 16.971831422000072],
              [106.58485614400003, 16.973022290000102],
              [106.58448575300011, 16.973816736000025],
              [106.58435000800007, 16.974629182000101],
              [106.58440414300003, 16.976162372000125],
              [106.58481573800006, 16.977697905000078],
              [106.5851683230001, 16.978388435000099],
              [106.58579132100004, 16.978993083000127],
              [106.58679529400007, 16.979642197000096],
              [106.58911909400005, 16.98071897600002],
              [106.59130787300005, 16.98192817400006],
              [106.59213108200004, 16.98266625700002],
              [106.59250044700005, 16.983407888],
              [106.59250820800007, 16.984327536000073],
              [106.59233590000011, 16.985423765000093],
              [106.59193556600012, 16.986390375000049],
              [106.59135293800006, 16.987270835000068],
              [106.59056477200002, 16.98799961000006],
              [106.58930023700003, 16.988753998000035],
              [106.58758330400008, 16.989687092000061],
              [106.58608996600006, 16.990224262000098],
              [106.58436785000004, 16.990544229000101],
              [106.58250846400003, 16.990733873000075],
              [106.58110109500011, 16.990700996000015],
              [106.57937346000007, 16.990364034000024],
              [106.57755579600004, 16.990115352000053],
              [106.57533183500007, 16.990132564000056],
              [106.57222302300005, 16.990178471000036],
              [106.5706798640001, 16.990190372000043],
              [106.56877215600008, 16.990029897000092],
              [106.56677111500007, 16.989563544000049],
              [106.56467823400001, 16.988966491000035],
              [106.56258754700005, 16.98863216600008],
              [106.56177058000003, 16.988638429000069],
              [106.56122774800006, 16.988861566000054],
              [106.56082398000005, 16.989434013000078],
              [106.56046957600003, 16.990487818000055],
              [106.55966311500001, 16.991764081000049],
              [106.55898880600009, 16.992557556000065],
              [106.55772554400002, 16.993486939000043],
              [106.55636679300004, 16.993847691000049],
              [106.55351165800005, 16.994395045000132],
              [106.5510196230001, 16.994939586000065],
              [106.55043209900006, 16.995250629000047],
              [106.54993749800009, 16.995823741000038],
              [106.54916750200006, 16.996946395000109],
              [106.54857190000007, 16.998118812000129],
              [106.548242638, 16.998559280000066],
              [106.54763542200004, 16.998980101000072],
              [106.54799063600004, 17.001706926000089],
              [106.54887990900008, 17.006278426000065],
              [106.5484154330001, 17.00898196100011],
              [106.54720934200004, 17.010282443000065],
              [106.54623597700007, 17.010289835000059],
              [106.54501830400004, 17.010181694000082],
              [106.54379390800007, 17.009251858000063],
              [106.54292299500004, 17.00691062700006],
              [106.54236523900008, 16.998227878000094],
              [106.54205004200003, 16.996880249000057],
              [106.54107195200001, 16.996300693000066],
              [106.53691917600004, 16.994336422000075],
              [106.53495923400006, 16.99270770300005],
              [106.53359616100008, 16.98966578100007],
              [106.53160580000007, 16.984280705000018],
              [106.52926486600009, 16.98065912100013],
              [106.52547182700005, 16.977987518000056],
              [106.5185112780001, 16.974752481000088],
              [106.51460155300001, 16.972668481000049],
              [106.51192436800008, 16.972570955000066],
              [106.50645955200009, 16.973785343000088],
              [106.50367377100008, 16.975332015000077],
              [106.50065583200005, 16.978289099000051],
              [106.49775294800003, 16.980423545000086],
              [106.49575952000002, 16.981114160000033],
              [106.493178716, 16.981625320000134],
              [106.49063176000003, 16.982202837000045],
              [106.48743176500003, 16.982964404000086],
              [106.48366503500003, 16.98357883800012],
              [106.4806226900001, 16.983483563000057],
              [106.47745593300002, 16.983036969000054],
              [106.47538776600004, 16.983051946000046],
              [106.4695628540001, 16.98497235600005],
              [106.46641706600005, 16.98722552900005],
              [106.46295517200005, 16.990050373000095],
              [106.46157685400004, 16.99155039900004],
              [106.46097875500004, 16.994087902000032],
              [106.46069990800005, 16.997964232000037],
              [106.45994041900011, 16.999608821000045],
              [106.45886397400002, 17.000212587000085],
              [106.45701188700008, 17.000374865000097],
              [106.45453858500008, 17.000094519000086],
              [106.44989743200003, 16.999084527],
              [106.44354954400006, 16.997043433000059],
              [106.43921738200001, 16.996030994000044],
              [106.43705869200001, 16.996493286000103],
              [106.43567785100007, 16.997695130000068],
              [106.43433990500003, 17.000445948000028],
              [106.43297713700008, 17.002721685000033],
              [106.43189006800002, 17.004316817000117],
              [106.4296992430001, 17.005314933000101],
              [106.42742701700004, 17.005935637000036],
              [106.42609920000004, 17.00685207],
              [106.42587224200001, 17.007987568000082],
              [106.42553917300008, 17.01585169100008],
              [106.42548412000005, 17.018951432000051],
              [106.42495281400005, 17.021374157000047],
              [106.42440734200004, 17.02308156],
              [106.422721537, 17.027438904000022],
              [106.42187437400007, 17.029637249000103],
              [106.41377296300007, 17.03831219800005],
              [106.40882146200009, 17.042996016000075],
              [106.40445615000004, 17.047335471000032],
              [106.40150024100006, 17.051135930000072],
              [106.40045557800005, 17.053222149000113],
              [106.40046515800005, 17.05450729600004],
              [106.40134986700006, 17.057298420000052],
              [106.40445278800011, 17.062644623],
              [106.40797169500004, 17.065833261000037],
              [106.41026400800007, 17.067782949000062],
              [106.41137887500004, 17.069816405],
              [106.413891333, 17.075721455000092],
              [106.41419499400007, 17.07643515000008],
              [106.41478884200009, 17.07935607899999],
              [106.40586745900001, 17.088981565000068],
              [106.40446552200009, 17.090578898000075],
              [106.40047827000006, 17.093101299000054],
              [106.39758945700005, 17.095464860000043],
              [106.39541259700006, 17.098503888000046],
              [106.39030147400003, 17.103112860000103],
              [106.38873546400004, 17.10380401000009],
              [106.38810872100005, 17.104035111000115],
              [106.386538778, 17.104197068000104],
              [106.383633988, 17.104443738000086],
              [106.3818317170001, 17.105060853000076],
              [106.37831182900001, 17.107126058000063],
              [106.37471495000003, 17.109418533000031],
              [106.37233478500009, 17.111589307000052],
              [106.37055454600005, 17.115230222000029],
              [106.36926772900007, 17.121891818],
              [106.36869428700005, 17.124050334000081],
              [106.36784337100008, 17.125870518000049],
              [106.36574039400007, 17.128379560000084],
              [106.36222758000012, 17.13150288600005],
              [106.35588898800005, 17.135023145000083],
              [106.35468352200004, 17.136732252000094],
              [106.35453515600003, 17.137942861000077],
              [106.35400653800008, 17.140894859000078],
              [106.35301140300007, 17.144530389000053],
              [106.35254708900003, 17.145516310000097],
              [106.35083327200007, 17.147569012000119],
              [106.349618401, 17.147992938000108],
              [106.34640072700005, 17.148543617000108],
              [106.34593256000004, 17.149000337000061],
              [106.34593582800004, 17.149453931000025],
              [106.34609732800007, 17.150057670000059],
              [106.35219129700008, 17.156065079000093],
              [106.35369904500004, 17.158096213000107],
              [106.35402047900007, 17.159076875000068],
              [106.35390837000004, 17.159871446000082],
              [106.35312697700004, 17.160481481000112],
              [106.34881885100002, 17.162400342000069],
              [106.34349887400002, 17.165686661000024],
              [106.3384095860001, 17.168290914000025],
              [106.33723627600001, 17.169054706000075],
              [106.334658748, 17.171264205000099],
              [106.32596956200004, 17.181565518000049],
              [106.32527086200004, 17.182779735000061],
              [106.32314162000009, 17.187292025000112],
              [106.32023874400006, 17.191888002000063],
              [106.32002487300004, 17.192455920000072],
              [106.31980186900006, 17.192908434000032],
              [106.31973879900008, 17.193170521000042],
              [106.31980562100001, 17.19343762700003],
              [106.32062369400003, 17.194375376000082],
              [106.32170739600008, 17.195617619000103],
              [106.32554313200009, 17.198956716000069],
              [106.32554798000005, 17.19963709500005],
              [106.32492245, 17.20009482600009],
              [106.31959187700001, 17.202095441000061],
              [106.31364093600003, 17.205309595000081],
              [106.31144284300007, 17.205701925000128],
              [106.30689264000007, 17.206941116000074],
              [106.30214823000009, 17.208521680000018],
              [106.30168087300007, 17.209129525000044],
              [106.30160596700009, 17.209659220000084],
              [106.30160860900003, 17.210037213000092],
              [106.30232130900002, 17.210788629000092],
              [106.30453749800007, 17.212966755000103],
              [106.3055694020001, 17.214396495000095],
              [106.30622476100005, 17.218172350000046],
              [106.3072128900001, 17.218959768],
              [106.31012869300004, 17.219999236000127],
              [106.31343273900004, 17.220355719000047],
              [106.31586787900008, 17.220113015000038],
              [106.31987079700008, 17.219255197000024],
              [106.3216711530001, 17.218184970000024],
              [106.32269192600009, 17.218027066000019],
              [106.323950107, 17.218094389000079],
              [106.32486885700008, 17.220167389],
              [106.32606732100004, 17.2228811620001],
              [106.32710375800008, 17.224915567000032],
              [106.32813535900009, 17.226269585000061],
              [106.33128557000006, 17.227080395000094],
              [106.33184015000002, 17.227681537000095],
              [106.33200061400007, 17.228134071000056],
              [106.33294065200005, 17.233155333000113],
              [106.33279157100006, 17.234290341000055],
              [106.33162273000006, 17.235734502000085],
              [106.33068481300009, 17.236496719000094],
              [106.32809822600004, 17.237572228000083],
              [106.32362115000006, 17.238130943000037],
              [106.31764075300003, 17.237338594000029],
              [106.31583268900002, 17.237350423000031],
              [106.31300854300011, 17.238200493000107],
              [106.30779069000003, 17.239633116000071],
              [106.30724784600007, 17.240695076000101],
              [106.30702211400003, 17.242132977000054],
              [106.30749965300002, 17.242961487000038],
              [106.30876014100005, 17.243331297000083],
              [106.31363903900005, 17.243979912000015],
              [106.31569211600001, 17.245251710000083],
              [106.316645147, 17.246606300000074],
              [106.31688743000007, 17.247511939000049],
              [106.31666766200009, 17.249781413000065],
              [106.31578205699999, 17.252395474000082],
              [106.31545941500003, 17.252771512000017],
              [106.31507931600002, 17.253080486],
              [106.31374762000007, 17.253769600000091],
              [106.31264642600007, 17.253701188000022],
              [106.31051624400004, 17.252656676000107],
              [106.30961889500004, 17.252694281000025],
              [106.30886686600009, 17.252894217000048],
              [106.30792770400009, 17.253505154000059],
              [106.30355851400007, 17.258296441000098],
              [106.30490868700005, 17.265810048000048],
              [106.3054824750001, 17.269132799000083],
              [106.30629108500004, 17.272302808000092],
              [106.30630440800007, 17.274192767000059],
              [106.30599293400006, 17.280205120000055],
              [106.30555046900004, 17.28436608500008],
              [106.30509572000008, 17.286788285000029],
              [106.30446328300006, 17.291895493000098],
              [106.3038485770001, 17.293940739000035],
              [106.30301047400012, 17.29507591400008],
              [106.30244643200007, 17.295839885000049],
              [106.29973510000009, 17.296491463000052],
              [106.29545184700008, 17.297142800000117],
              [106.29003232000011, 17.297432146000077],
              [106.28625769500007, 17.297456390000043],
              [106.28204872500008, 17.297218730000097],
              [106.27551968100005, 17.2969579620001],
              [106.27465049799999, 17.296358672000075],
              [106.27441145500011, 17.295906570000064],
              [106.27400630100007, 17.294170293000022],
              [106.27335484900007, 17.290923536000058],
              [106.27357255600006, 17.288276082000031],
              [106.27605604800002, 17.283497355000051],
              [106.27857429500001, 17.27807577300009],
              [106.27895387400007, 17.276107702000054],
              [106.27893718400003, 17.273688543000084],
              [106.27764839700006, 17.269236200000115],
              [106.27617325500003, 17.266259288000093],
              [106.27411082900001, 17.263626298000112],
              [106.27300334900004, 17.262650497000017],
              [106.27000736200004, 17.261459805000086],
              [106.26416553500003, 17.258018926000048],
              [106.26088551700008, 17.255506847000035],
              [106.25694831800008, 17.254624272000015],
              [106.25435179200011, 17.254338080000053],
              [106.25246132000009, 17.253820643000047],
              [106.25127792900005, 17.253223191000089],
              [106.2501696150001, 17.25209603899999],
              [106.24990684800012, 17.25176365400003],
              [106.24422544600006, 17.250885480000107],
              [106.24312426400007, 17.25081667700001],
              [106.23816921100007, 17.250544849000079],
              [106.23455371500006, 17.250718291000041],
              [106.23228026599999, 17.251715083000093],
              [106.2255563670001, 17.257426461000058],
              [106.21448688900009, 17.265999105000063],
              [106.21010253400006, 17.26889851300006],
              [106.20646525300003, 17.271831145000057],
              [106.20481894700006, 17.272597074000103],
              [106.20215093100011, 17.273444710000071],
              [106.19838693100007, 17.275054888000071],
              [106.19079176800003, 17.280089963000123],
              [106.18840630300009, 17.282107617000086],
              [106.18536568700004, 17.286208223000088],
              [106.18021086000003, 17.291757733000068],
              [106.17367686800004, 17.296899370000048],
              [106.17007323500005, 17.29911297200006],
              [106.1620058490001, 17.304376602000012],
              [106.1585172850001, 17.306173509000097],
              [106.15467780800005, 17.308463801000059],
              [106.14973999100005, 17.311213966000061],
              [106.14667976300007, 17.312365572000132],
              [106.144486027, 17.313739007000081],
              [106.14276509200008, 17.315260927000061],
              [106.14241607400007, 17.316056774000096],
              [106.14205799200002, 17.319368106000077],
              [106.142240229, 17.322586977000043],
              [106.14248132600005, 17.323417255000081],
              [106.14326909500004, 17.325297503000044],
              [106.14342255300004, 17.326182105000051],
              [106.14303301900004, 17.326789169],
              [106.13415606200009, 17.335042825000087],
              [106.13329453500003, 17.335652549000088],
              [106.12671025500006, 17.339469980000018],
              [106.12393230700008, 17.341867172000036],
              [106.12001711800005, 17.344837739000063],
              [106.11704165200005, 17.347122527000103],
              [106.11295979100001, 17.348581780000046],
              [106.110291096, 17.349579490000089],
              [106.10911611900003, 17.350417669000073],
              [106.10390633600009, 17.354037761000107],
              [106.10054032700003, 17.356853719000043],
              [106.09991323300002, 17.357235206000077],
              [106.09865768000006, 17.357771347000025],
              [106.09669366, 17.358235755000081],
              [106.09082029700005, 17.360910660000073],
              [106.0878057350001, 17.363614908000088],
              [106.07875687800005, 17.373753948000022],
              [106.06264462300007, 17.391815748000013],
              [106.03319679700009, 17.424814995000034],
              [105.96256613500003, 17.503899536000041],
              [105.96176229800004, 17.504797063000055],
              [105.88364505400004, 17.591964044000093],
              [105.85887364200001, 17.619583424000048],
              [105.8562887520001, 17.621459280000096],
              [105.85442129900012, 17.621664481000018],
              [105.85267643400007, 17.621311624000043],
              [105.85069482400007, 17.621291960000107],
              [105.8501035420001, 17.622069038000099],
              [105.84997610100004, 17.623074413000062],
              [105.85065497400007, 17.624982589000112],
              [105.85271337100002, 17.62869403200007],
              [105.85325641600011, 17.632390425000018],
              [105.85207018100002, 17.63428008300005],
              [105.85090082900007, 17.63460402300003],
              [105.84915828300004, 17.634027475000025],
              [105.84628873600012, 17.629860551000043],
              [105.84398155600009, 17.627600610000101],
              [105.84200230900011, 17.627357190000033],
              [105.83732375800007, 17.628764542000042],
              [105.83415690900006, 17.630522467],
              [105.83156189700001, 17.633292700000069],
              [105.83035620700008, 17.635775697000078],
              [105.82923481800003, 17.643559423000085],
              [105.82833334500005, 17.651379731000105],
              [105.8268879500001, 17.65561543000009],
              [105.82583257699999, 17.656164064],
              [105.82374259400008, 17.655360142000106],
              [105.82143170200001, 17.653435501000025],
              [105.81811452300008, 17.647586022],
              [105.81627378100011, 17.645330510000079],
              [105.81534360000006, 17.645097427000067],
              [105.81382069900008, 17.645753151000051],
              [105.80981761900007, 17.649291819000013],
              [105.80662678300011, 17.653174179000075],
              [105.80567684900004, 17.654730417000053],
              [105.80422354400004, 17.659636963000125],
              [105.80304655800009, 17.660631645000088],
              [105.80211261600009, 17.660734020000035],
              [105.79979568800005, 17.659368319000102],
              [105.79828748300005, 17.658681907000059],
              [105.79734983500001, 17.659119764000053],
              [105.79605251000005, 17.660448725000052],
              [105.79330526300002, 17.666348628000073],
              [105.78998979100007, 17.670900524000018],
              [105.78834876800008, 17.671666690000087],
              [105.784272941, 17.671177642000124],
              [105.78216926800012, 17.671603488000095],
              [105.78005184300007, 17.673259500000071],
              [105.7774705090001, 17.674687017000053],
              [105.77396887, 17.674986596000061],
              [105.76860042700008, 17.675378725000087],
              [105.76627351700002, 17.674907354000077],
              [105.7584243430001, 17.667779913000103],
              [105.75609250200007, 17.667755761000059],
              [105.75013240700005, 17.668924232000016],
              [105.74883470300006, 17.67025290200008],
              [105.74705806600005, 17.682984894000064],
              [105.74399846500003, 17.705993126000052],
              [105.74195240800003, 17.72185360100007],
              [105.74135405400008, 17.723189486000109],
              [105.74030189800007, 17.723402192000037],
              [105.73879213200011, 17.722827228000071],
              [105.73543040200005, 17.721002615000089],
              [105.73298641000008, 17.720529671000037],
              [105.73088083000003, 17.721066842000077],
              [105.72864838700005, 17.722497408000045],
              [105.727215685, 17.725390298000043],
              [105.72633540300008, 17.73097320000003],
              [105.72638685400008, 17.736677700000044],
              [105.72774938600001, 17.739935423000119],
              [105.72888764000004, 17.742407882000052],
              [105.72886721100005, 17.74419713800004],
              [105.72803287500003, 17.745754172000083],
              [105.72639099600006, 17.746519832000018],
              [105.72310209900007, 17.748498441000038],
              [105.72015792300002, 17.75092797900006],
              [105.71824806500008, 17.754710477000032],
              [105.71658553500002, 17.757265300000121],
              [105.71412315900002, 17.758357745000097],
              [105.71272342300003, 17.758342971000026],
              [105.71063925900008, 17.756978871000051],
              [105.70912288400011, 17.756962841000053],
              [105.704783831, 17.758930047000106],
              [105.701495806, 17.760796480000081],
              [105.69642562200006, 17.765439932000056],
              [105.69168958400007, 17.771428901000071],
              [105.68784360799999, 17.781117931000075],
              [105.68581097600004, 17.785346123000068],
              [105.68403502300001, 17.787563926000068],
              [105.67698176100004, 17.792073816000098],
              [105.67438245199999, 17.794841898000065],
              [105.67377034400006, 17.797295772000062],
              [105.67397228600007, 17.799982049000015],
              [105.68145471700002, 17.818739068000063],
              [105.68259414900007, 17.821099817000082],
              [105.68279623400008, 17.823786054000031],
              [105.6816756740001, 17.829813223000102],
              [105.68139144600012, 17.834171774000126],
              [105.68056288300004, 17.835169427000075],
              [105.67904199800006, 17.83548865100002],
              [105.67670689300012, 17.835575475],
              [105.67529092600002, 17.836902315000103],
              [105.67337415400006, 17.84113148000008],
              [105.67286680000009, 17.844592915000042],
              [105.6722571390001, 17.846823071000095],
              [105.67012908900006, 17.849148729000049],
              [105.66460094300007, 17.852779775000016],
              [105.66094641200002, 17.855871726000046],
              [105.65977017900005, 17.85664187400009],
              [105.65790821000009, 17.856174432000046],
              [105.6553513210001, 17.85525211700012],
              [105.65336872000009, 17.855118823000012],
              [105.65032524100008, 17.855868698000066],
              [105.64444775800004, 17.859383593000096],
              [105.64129012600009, 17.859908429000093],
              [105.63954487700002, 17.859442100000052],
              [105.63803702700004, 17.858642834000094],
              [105.63709809200007, 17.859079942000022],
              [105.63662329600004, 17.859745768000046],
              [105.63602249000007, 17.861193051000065],
              [105.63456881300004, 17.865650546000019],
              [105.63373455900003, 17.86709527],
              [105.62300448300005, 17.876148192000052],
              [105.61981305400005, 17.879468159000034],
              [105.61782085400006, 17.880117272000092],
              [105.61348626500006, 17.881411587000073],
              [105.61052130600005, 17.885293064000059],
              [105.60717106600005, 17.892077863000019],
              [105.60643033700005, 17.895424626000086],
              [105.60675627700002, 17.897441194000052],
              [105.60767123300005, 17.899016928000044],
              [105.60916850600003, 17.90071090600005],
              [105.61402677300001, 17.904454841000117],
              [105.61517931300007, 17.905697658000093],
              [105.61574016200002, 17.907604944000077],
              [105.6165009600001, 17.912310179000102],
              [105.61797427400005, 17.916016758000097],
              [105.62095053700001, 17.92096992700003],
              [105.6217061560001, 17.926122363000019],
              [105.62162585900002, 17.932831221000086],
              [105.62175229000009, 17.941778854000034],
              [105.62220056100006, 17.943349357000038],
              [105.62300579100007, 17.94436463100002],
              [105.62543891100003, 17.945956870000131],
              [105.62600932300006, 17.947081392000094],
              [105.62609000300009, 17.950101614000054],
              [105.62664173200008, 17.952791496000131],
              [105.62906431000006, 17.95527818300009],
              [105.63346788700008, 17.958121941000101],
              [105.63765136900003, 17.959844938000025],
              [105.63815923000003, 17.960298375000058],
              [105.64061972400003, 17.962495188000013],
              [105.64122056200002, 17.964133179],
              [105.64105593900007, 17.977997737000081],
              [105.64186404000006, 17.988629856000045],
              [105.64173520400006, 17.991198918000073],
              [105.64448085500004, 17.993407063000078],
              [105.64828671099998, 17.993795089000031],
              [105.65318854200011, 17.994117706000075],
              [105.656638517, 17.994167699000037],
              [105.65850870000003, 17.994223009000088],
              [105.66361878400006, 17.994190168000031],
              [105.66629441400002, 17.994088510000061],
              [105.66787396400007, 17.994021423000014],
              [105.67333809500009, 17.993832976000057],
              [105.67740027600011, 17.993787999000062],
              [105.68425071600008, 17.993594400000035],
              [105.68686207200005, 17.993554330000094],
              [105.68977583, 17.993488359000054],
              [105.69574361800004, 17.993353118000051],
              [105.702433044, 17.993221104000057],
              [105.70333588100002, 17.993248657000024],
              [105.70381973700009, 17.993308547000098],
              [105.70414262000006, 17.993430673000077],
              [105.70435825200005, 17.993613466000099],
              [105.70853657300009, 17.997198775000101],
              [105.71102883200007, 17.999229689000096],
              [105.711293133, 17.99961693900007],
              [105.71143796800007, 18.000000163000074],
              [105.7109273610001, 18.001714960000115],
              [105.71126092700008, 18.003240703000053],
              [105.7126931610001, 18.004802848000075],
              [105.71330750000004, 18.005225301000038],
              [105.71480845100007, 18.005482683000103],
              [105.7160133300001, 18.005660253000052],
              [105.71728257300005, 18.006080279000081],
              [105.71783306400003, 18.006381607000051],
              [105.7183416240001, 18.006763982000074],
              [105.71885010600008, 18.007126142000104],
              [105.719595285, 18.008549205000079],
              [105.71960904700008, 18.009337909000052],
              [105.71923797100001, 18.011604436000091],
              [105.71896478600006, 18.014568350000012],
              [105.71852785100006, 18.016228384000051],
              [105.71810750200009, 18.01937485600007],
              [105.71792335000004, 18.020851931000067],
              [105.71804278800006, 18.021650360000052],
              [105.71825536700007, 18.021973165000112],
              [105.71963397700009, 18.023282635000015],
              [105.72067292300008, 18.024188871000121],
              [105.72164833200006, 18.0250548960001],
              [105.72522575700002, 18.029298775000058],
              [105.72627744600008, 18.030720654000021],
              [105.7269592750001, 18.032113589000076],
              [105.72751410100008, 18.033446309000034],
              [105.72812054700009, 18.034485586000045],
              [105.72911668000008, 18.03523013600001],
              [105.72998528800009, 18.035793141000056],
              [105.73055727500008, 18.036155022000067],
              [105.73239808100007, 18.036775017000117],
              [105.7347032320001, 18.037271894000106],
              [105.736913273, 18.037758988000085],
              [105.73782181400003, 18.037755518000061],
              [105.74798223100005, 18.034591833000043],
              [105.74894182800004, 18.034173514000074],
              [105.7500783130001, 18.033117441000087],
              [105.75072960500009, 18.032245269000043],
              [105.75179951600006, 18.030441147000076],
              [105.75260731500001, 18.029113306000077],
              [105.75414344900001, 18.027651171000066],
              [105.75505004700007, 18.027202697000085],
              [105.75910083200003, 18.025852050000069],
              [105.76262738700005, 18.02543370500009],
              [105.76442295200012, 18.025365947000083],
              [105.766154183, 18.025075954000066],
              [105.76712481100003, 18.024788971000035],
              [105.76853700400008, 18.024014841000024],
              [105.77168296300005, 18.021120361000072],
              [105.77336641100007, 18.019576594000064],
              [105.77485084700005, 18.018427981000059],
              [105.77588445000005, 18.018059807000064],
              [105.77630698300001, 18.018058110000098],
              [105.77677220400005, 18.018157364000054],
              [105.77725953000004, 18.018479002000021],
              [105.78020707600004, 18.02095473200005],
              [105.78384611900003, 18.024509616000017],
              [105.78534020700002, 18.025545102000073],
              [105.78595433000004, 18.025866199000085],
              [105.78675806300001, 18.026065175000028],
              [105.78906095300003, 18.026055794000051],
              [105.79129946300006, 18.025824185000069],
              [105.79445459300008, 18.02505285700002],
              [105.79529914100007, 18.024928041000088],
              [105.79593294800002, 18.024925434000046],
              [105.79652506500007, 18.02504434400003],
              [105.80671674300001, 18.026842532000039],
              [105.80790070600008, 18.027019602000038],
              [105.80830350800002, 18.027321287000042],
              [105.80849505000006, 18.027623847000115],
              [105.80849782000011, 18.028230564000047],
              [105.80843619000008, 18.028615072000065],
              [105.80824770400002, 18.028979899000049],
              [105.80787054200007, 18.029669096000056],
              [105.80692744200003, 18.031351634000089],
              [105.80633086600005, 18.032577678000017],
              [105.80620630600005, 18.033063594000076],
              [105.80629220200005, 18.033366591000096],
              [105.806695104, 18.033688501000086],
              [105.81027364000005, 18.035392594000129],
              [105.81360092200002, 18.037583062000095],
              [105.81443797300008, 18.038115487000091],
              [105.81498909000004, 18.038497418000077],
              [105.81539062700004, 18.0385159450001],
              [105.81570746100004, 18.03849439],
              [105.81625569800012, 18.038249392000068],
              [105.81686600100008, 18.037720981000049],
              [105.82033863200007, 18.034773787000091],
              [105.8248732910001, 18.033055684000132],
              [105.82749242000001, 18.033511231000077],
              [105.83144960500005, 18.037850879000047],
              [105.83242817900002, 18.039262350000037],
              [105.83302473500008, 18.040311431000063],
              [105.83303273700002, 18.042010216000023],
              [105.83194752500003, 18.044886707000082],
              [105.83142395100006, 18.045879935000109],
              [105.8311079150001, 18.046267692000036],
              [105.83078381400003, 18.048288111000055],
              [105.82880280700007, 18.050572551000066],
              [105.825621298, 18.052556915000117],
              [105.82091625200009, 18.056254120000048],
              [105.81802599200005, 18.059105376000062],
              [105.81707914700004, 18.0606333530001],
              [105.8177401340001, 18.061409717000032],
              [105.81802638900007, 18.061620869000095],
              [105.82405109900003, 18.063208121000059],
              [105.82690798400006, 18.065203156000017],
              [105.82803687900012, 18.065971890000029],
              [105.83164406000006, 18.063473889000115],
              [105.83187507700008, 18.063169525000063],
              [105.83438743700003, 18.064908079000119],
              [105.83776391900007, 18.067264730000062],
              [105.84154739300004, 18.066312920000094],
              [105.84653378400006, 18.065071542000055],
              [105.84903359600003, 18.068272262000029],
              [105.84981626200005, 18.068430615000075],
              [105.85114759100006, 18.068424748000034],
              [105.85480227400002, 18.068165925000109],
              [105.86148854300009, 18.067721626000029],
              [105.86730904400011, 18.067422550000067],
              [105.86834461400008, 18.067438125000109],
              [105.86945507500005, 18.067645482000039],
              [105.87053440699999, 18.06796419500003],
              [105.8713397180001, 18.068425720000022],
              [105.87176404600004, 18.068767604000065],
              [105.87235924700008, 18.06947275200006],
              [105.87321222400004, 18.071026126000064],
              [105.87471491900004, 18.073618078000031],
              [105.88018966600005, 18.080236696],
              [105.88202745400005, 18.082179895000117],
              [105.88442639300003, 18.084332863000085],
              [105.89208564800005, 18.090223143000102],
              [105.89284843600009, 18.090603869000077],
              [105.8934624620001, 18.090823481000129],
              [105.89413886700002, 18.090840568000118],
              [105.89511095800007, 18.090815835000029],
              [105.89777216200008, 18.090459667000047],
              [105.90591404100006, 18.089359864000087],
              [105.90845713400006, 18.088650196000074],
              [105.91058553100004, 18.087446970000087],
              [105.91197660900004, 18.086712355000053],
              [105.91283923500001, 18.085959997000067],
              [105.91394242400006, 18.084731250000097],
              [105.91541161200003, 18.082762594000101],
              [105.91627419700008, 18.082010229000019],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 46, code: "AD01", ten_tinh: "Quảng Nam" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [108.64510265000008, 15.478408736000036],
              [108.6439370680001, 15.478161110000064],
              [108.64154361400003, 15.478419432000033],
              [108.63779303300004, 15.479246845000104],
              [108.63684093900005, 15.480003114000061],
              [108.63638985700003, 15.480600912000051],
              [108.63642439600004, 15.481291444000076],
              [108.63681394600003, 15.481698388000085],
              [108.63794768000005, 15.482103116000088],
              [108.63985735200005, 15.482317166000124],
              [108.64344921400006, 15.48243197200012],
              [108.64574615800004, 15.482330879000104],
              [108.646651709, 15.482202573000057],
              [108.64710387300002, 15.481950071000126],
              [108.64736130600009, 15.481509794000113],
              [108.64732723500002, 15.480976221000018],
              [108.64700075300001, 15.480066825000092],
              [108.6459616600001, 15.478908435000081],
              [108.64510265000008, 15.478408736000036],
            ],
          ],
          [
            [
              [108.62311010900002, 15.480277168000088],
              [108.61993357600009, 15.480011253000024],
              [108.61664446700009, 15.480020752000099],
              [108.6155680090001, 15.480354014000051],
              [108.61443752900001, 15.48156783700003],
              [108.6124590690001, 15.483664503000048],
              [108.61042251900007, 15.48532110200007],
              [108.609971839, 15.48631285900006],
              [108.60991795800007, 15.487248460000023],
              [108.61065904000002, 15.488511949000026],
              [108.61173922900004, 15.489389286000053],
              [108.61315735000005, 15.489495281000119],
              [108.61468687900003, 15.488940628000057],
              [108.61564743000011, 15.487782317000052],
              [108.61683380900004, 15.486293197000032],
              [108.61836144400004, 15.485133240000069],
              [108.61926660400006, 15.484415279000084],
              [108.62147490500008, 15.483308356000068],
              [108.62368249000004, 15.481981308000046],
              [108.62436112200005, 15.481374047000072],
              [108.624131898, 15.480604346000069],
              [108.62311010900002, 15.480277168000088],
            ],
          ],
          [
            [
              [108.67466510500005, 15.500337235000037],
              [108.67353962900009, 15.495321343000066],
              [108.67303330300004, 15.491259577000081],
              [108.67302351400011, 15.488331601000114],
              [108.67363201700002, 15.486118740000075],
              [108.67497897000008, 15.48372429400011],
              [108.67632889200004, 15.482226155000054],
              [108.67798693900009, 15.480786783000065],
              [108.67940022000005, 15.479826218000079],
              [108.68006352400005, 15.479072074000031],
              [108.68030243600001, 15.478452270000085],
              [108.68032870500005, 15.477833142000106],
              [108.67983774700011, 15.476682190000089],
              [108.6790604080001, 15.475897497000089],
              [108.677537118, 15.475230975],
              [108.67599767899999, 15.475295594000025],
              [108.67396754000006, 15.475959302000051],
              [108.67107698000008, 15.477163493000122],
              [108.66622411400002, 15.480943227000042],
              [108.66321478900007, 15.483522038000073],
              [108.66186868100006, 15.486215193000062],
              [108.66092464200003, 15.489444894000124],
              [108.65988387900006, 15.491360265000063],
              [108.65865480300006, 15.49220062200005],
              [108.65644024900007, 15.493044005000021],
              [108.65397601200002, 15.492872282000024],
              [108.65163357900008, 15.493013352000061],
              [108.64978012700003, 15.494121053000052],
              [108.64920363600002, 15.495195488000045],
              [108.64914710300005, 15.495772215000068],
              [108.64915607000009, 15.49689923800006],
              [108.64956460200005, 15.497854547000054],
              [108.65124771100004, 15.499271733000073],
              [108.65238334000003, 15.499915713000066],
              [108.65441669800006, 15.500148533000056],
              [108.65735654300002, 15.500152054000081],
              [108.6612367330001, 15.50008035300001],
              [108.66370185200003, 15.50049101600006],
              [108.665613228, 15.501082629000072],
              [108.66758799200005, 15.502211831000066],
              [108.67005752600002, 15.503937023000079],
              [108.67160516100009, 15.506262628000032],
              [108.672290096, 15.50847141900009],
              [108.67241747800006, 15.509725884000057],
              [108.67217568500004, 15.51110102100006],
              [108.67101172300001, 15.513016836000036],
              [108.67052391600006, 15.514512246000043],
              [108.67071249000003, 15.515647007000053],
              [108.67151665700004, 15.516660334000052],
              [108.67281083100001, 15.516835536000071],
              [108.67422639100008, 15.516472553000058],
              [108.67607033000004, 15.51527162600008],
              [108.68054840300002, 15.509759954000033],
              [108.68125231500008, 15.508443090000055],
              [108.68143386400001, 15.507486426000037],
              [108.68124463000007, 15.506172411000117],
              [108.68025567300005, 15.505159718000082],
              [108.67785105000004, 15.504450284000091],
              [108.67661674800006, 15.503737120000059],
              [108.67550303500011, 15.502246756000114],
              [108.67466510500005, 15.500337235000037],
            ],
          ],
          [
            [
              [108.69580027900004, 15.812361080000057],
              [108.69424796100003, 15.811683609000047],
              [108.68945742200006, 15.811767318000046],
              [108.6881902050001, 15.812044388000055],
              [108.68770007100005, 15.812933211000056],
              [108.68842026200004, 15.817503663000119],
              [108.68969524200001, 15.81947883100012],
              [108.69047094900007, 15.819681082000068],
              [108.69187794800004, 15.819062296000117],
              [108.69595371100004, 15.815977844000082],
              [108.69651355700009, 15.814884020000084],
              [108.69664997100008, 15.813586823000046],
              [108.69580027900004, 15.812361080000057],
            ],
          ],
          [
            [
              [108.53911108500009, 15.904062490000024],
              [108.53706519200009, 15.903385123000112],
              [108.53304755000002, 15.903395144000052],
              [108.52980740500007, 15.904222172000091],
              [108.52854190800008, 15.905453788000033],
              [108.52854532000001, 15.906750525000033],
              [108.53010357700006, 15.909613177000013],
              [108.53285981200004, 15.912336333000086],
              [108.53751987100006, 15.915259456000095],
              [108.53871964900003, 15.91580244100008],
              [108.53991741500001, 15.915594684000032],
              [108.54076144800004, 15.914910060000052],
              [108.54089764900002, 15.913135219000106],
              [108.54011731900003, 15.911294445000033],
              [108.53954883800006, 15.909589630000108],
              [108.54010539200002, 15.906858235000064],
              [108.54003069000005, 15.90528868000005],
              [108.53911108500009, 15.904062490000024],
            ],
          ],
          [
            [
              [108.48048442599999, 15.940783651000066],
              [108.4787177490001, 15.939013107000031],
              [108.47737845900004, 15.939084342000063],
              [108.47611160200003, 15.939974408000078],
              [108.47541050300001, 15.94161395300002],
              [108.47456936000005, 15.943663295000119],
              [108.47457323000002, 15.945301286000111],
              [108.47535180000004, 15.946596303000058],
              [108.47754395700005, 15.949389663000078],
              [108.47909725900006, 15.950341693000068],
              [108.48212963100005, 15.95067614900003],
              [108.48375034800003, 15.950331253000092],
              [108.48445387900006, 15.949715421000031],
              [108.48452208100011, 15.948759774000097],
              [108.48048442599999, 15.940783651000066],
            ],
          ],
          [
            [
              [108.52419429, 15.961360928000088],
              [108.53349626000002, 15.959563521000051],
              [108.53567644300003, 15.9575106010001],
              [108.53651811400003, 15.955870508000112],
              [108.5360830660001, 15.951367126],
              [108.53578904000003, 15.946863381000041],
              [108.53704417800004, 15.941673273000044],
              [108.53773929300009, 15.937986050000051],
              [108.53729914500009, 15.931571688000099],
              [108.53813740300008, 15.928703093000072],
              [108.53925761100004, 15.925833796000076],
              [108.53854865900009, 15.924334087000068],
              [108.53614901000005, 15.923248098],
              [108.5317785120001, 15.923258976000019],
              [108.52938502700003, 15.924493388000103],
              [108.52453852900004, 15.931193764000094],
              [108.52017669500002, 15.934616871000083],
              [108.51313757400007, 15.938728804000045],
              [108.50666284100001, 15.943112169000083],
              [108.50483639600004, 15.945709958000085],
              [108.50484566000002, 15.94939541500011],
              [108.50359374800009, 15.956223311000082],
              [108.50134411400002, 15.958822060000076],
              [108.49838496600005, 15.959647952000109],
              [108.49570477600007, 15.959244661000048],
              [108.49342589200008, 15.957168309000023],
              [108.49150307800005, 15.956524342000121],
              [108.48984692100007, 15.956801126000107],
              [108.48687515600002, 15.959708501000057],
              [108.48323501100002, 15.963231567000056],
              [108.48126672900005, 15.965692976000055],
              [108.48169501300006, 15.967875995000023],
              [108.48522467200007, 15.969642532000082],
              [108.4879086270001, 15.971547430000042],
              [108.49151359700004, 15.975224675],
              [108.49433903700005, 15.977265661000038],
              [108.49928056500008, 15.979574702000042],
              [108.50181898600005, 15.979568781000085],
              [108.50407330000006, 15.978744509000096],
              [108.50829741900006, 15.97614106800007],
              [108.50927868200007, 15.973818260000082],
              [108.50913247000005, 15.971771121000076],
              [108.50870215100007, 15.968905666000042],
              [108.509824044, 15.9664460180001],
              [108.51207643800011, 15.964939170000035],
              [108.51686701400008, 15.963426190000083],
              [108.52419429, 15.961360928000088],
            ],
          ],
          [
            [
              [108.44709644900004, 15.969315869000054],
              [108.44533238800001, 15.968705284000071],
              [108.44399273700002, 15.968708060000035],
              [108.44103605600007, 15.970829894000092],
              [108.44033486200006, 15.972605822000039],
              [108.44047826700002, 15.973697512000038],
              [108.44231873500007, 15.976969706000064],
              [108.44535857100004, 15.980512385000088],
              [108.447053899, 15.981873847000115],
              [108.44832327200004, 15.981939451000036],
              [108.44994368800008, 15.981321807000091],
              [108.45085758700003, 15.980091404000072],
              [108.45113580400005, 15.97838458300007],
              [108.45070629900003, 15.975519014000026],
              [108.44844021900012, 15.971155796000057],
              [108.44709644900004, 15.969315869000054],
            ],
          ],
          [
            [
              [107.80653112100006, 16.053840042000012],
              [107.80851993400003, 16.053590929000094],
              [107.81120018600002, 16.053593303000113],
              [107.81362051700009, 16.054180846000051],
              [107.81543433200005, 16.056189611000079],
              [107.81672917800009, 16.058448806000044],
              [107.82156833900008, 16.05656700100004],
              [107.82574609300008, 16.055416104000116],
              [107.82894672200004, 16.055103858000038],
              [107.83068218500001, 16.055472521000077],
              [107.83274185600008, 16.057363029],
              [107.83528933300001, 16.059778589000118],
              [107.83756674700005, 16.060829709000046],
              [107.84022474100007, 16.060831703000119],
              [107.84543199500007, 16.061150335000015],
              [107.85063909900005, 16.061678709000127],
              [107.85671423300008, 16.062102603000113],
              [107.85931773500002, 16.062471612000095],
              [107.86159636400001, 16.062000872000048],
              [107.86420125800007, 16.060323520000082],
              [107.86837296400009, 16.056783400000093],
              [107.87174200400004, 16.053720084000119],
              [107.87669498300005, 16.051328171000101],
              [107.88352965200005, 16.048458221000047],
              [107.88759111900001, 16.046065571000057],
              [107.89056337600007, 16.043384919000033],
              [107.89432793800007, 16.04032144600005],
              [107.89799361400006, 16.036778897000126],
              [107.90047001400006, 16.035055793000105],
              [107.91106130900002, 16.043969142000059],
              [107.91888275600004, 16.04780393900009],
              [107.92997167200005, 16.05393855500008],
              [107.94185347000004, 16.060264401000076],
              [107.95076563200007, 16.063715152000043],
              [107.95544488400007, 16.065393239000066],
              [107.95944451500007, 16.066273306000056],
              [107.96098999900005, 16.065746030000035],
              [107.96126302000008, 16.064163381000093],
              [107.95953721000009, 16.057392657000058],
              [107.95735726000004, 16.049478771000025],
              [107.95681328200006, 16.042972024000065],
              [107.95708683100004, 16.038927416000078],
              [107.95899627500002, 16.035498615000066],
              [107.96335987200011, 16.03101510000009],
              [107.96672350400006, 16.02653133200009],
              [107.97035980600002, 16.020992426000099],
              [107.97354119600007, 16.01773951400007],
              [107.97632907000009, 16.01586466400002],
              [107.97954763400003, 16.013196143000116],
              [107.98138680800005, 16.011226441000034],
              [107.98164973200005, 16.008875336000102],
              [107.9809934670001, 16.003537563000059],
              [107.98007478500003, 15.995785096000057],
              [107.97941854000001, 15.991082762000095],
              [107.97777708400008, 15.98815956000008],
              [107.97442821700011, 15.98568095900008],
              [107.97094827500004, 15.981994949000098],
              [107.96262157300004, 15.975109207000047],
              [107.95518384200005, 15.96886306600007],
              [107.95384004000007, 15.966347539000123],
              [107.95357210500002, 15.962271070000076],
              [107.95142222300004, 15.957933975000048],
              [107.94846599800005, 15.953076282000021],
              [107.94891495000006, 15.94969382500002],
              [107.9517836430001, 15.945704802000103],
              [107.95814767500006, 15.939114472000069],
              [107.9645813290001, 15.933436485000117],
              [107.97203282900006, 15.927936005000019],
              [107.97470389600009, 15.925930269000064],
              [107.98163469500005, 15.92072551800001],
              [107.98777924600006, 15.920651609000023],
              [107.9952294830001, 15.92161840000008],
              [108.0020653440001, 15.922956665000052],
              [108.00844049300011, 15.925707291],
              [108.01466225900002, 15.929126863000052],
              [108.01988549700009, 15.930762044000078],
              [108.02810447600005, 15.932396725000062],
              [108.03423886400002, 15.934234774000087],
              [108.03797781900003, 15.935212178000045],
              [108.04414185000005, 15.935602210000075],
              [108.0551561070001, 15.935501856000121],
              [108.05788421900006, 15.934816519000082],
              [108.06010649000007, 15.93217521500012],
              [108.06434926400003, 15.928457470000088],
              [108.06879473300005, 15.926989040000093],
              [108.07455413900007, 15.926693700000081],
              [108.08102111700003, 15.927082576000105],
              [108.08445731600004, 15.928743903000074],
              [108.08536763700005, 15.930895211000083],
              [108.08648078300004, 15.934709093000075],
              [108.08961458500008, 15.937641898],
              [108.10315953300008, 15.946046887000074],
              [108.10577998800009, 15.947045224000076],
              [108.10868813400002, 15.946943244000076],
              [108.1123226940001, 15.945735101000123],
              [108.11990191100006, 15.941006439000114],
              [108.12831079200001, 15.934970214000097],
              [108.13350202100001, 15.932453984000093],
              [108.13786371900004, 15.932049128000079],
              [108.14336889300009, 15.933151276000055],
              [108.14832766500004, 15.934975198000066],
              [108.14751569, 15.937865644000036],
              [108.14751781200007, 15.940755520000048],
              [108.14860836100007, 15.947585385000094],
              [108.1506482500001, 15.953626441000067],
              [108.15167507200006, 15.954817475000114],
              [108.1561334570001, 15.953375648000099],
              [108.16107090900006, 15.950797909000068],
              [108.16448790900007, 15.947608352000129],
              [108.16714308600002, 15.942212978000024],
              [108.16992807000007, 15.940739885000086],
              [108.17398032300007, 15.940246333000106],
              [108.17727430900003, 15.941591972000067],
              [108.18019290100001, 15.947963473000113],
              [108.18032351199999, 15.952376075000084],
              [108.18045467900001, 15.957401566000049],
              [108.18160123900002, 15.964755122000083],
              [108.18299585000003, 15.966224839000096],
              [108.185148885, 15.965977845000088],
              [108.19198699000002, 15.964255777000067],
              [108.19832028200001, 15.964495174000069],
              [108.20132512800004, 15.965308587000047],
              [108.20234178100004, 15.964191084000044],
              [108.20362988300005, 15.963139001000036],
              [108.20417095500005, 15.961430837000099],
              [108.20403251400005, 15.958738129000059],
              [108.20348572600001, 15.954797909000094],
              [108.20277107100003, 15.952565498000036],
              [108.20588240800008, 15.94742276100005],
              [108.20909481600003, 15.943813248000092],
              [108.22145245100005, 15.948731336000053],
              [108.22063634700002, 15.949721040000052],
              [108.22043324000001, 15.950808997000097],
              [108.22043530200008, 15.952687828000121],
              [108.22069317600001, 15.954961943000077],
              [108.22263811400009, 15.958618724000038],
              [108.22548639400003, 15.958320040000016],
              [108.22748364500009, 15.958428373000098],
              [108.22862561800008, 15.959089824000026],
              [108.22994011200004, 15.960910748000055],
              [108.23348294000007, 15.965214233000056],
              [108.23645423400006, 15.968634743000097],
              [108.23885380300001, 15.971061852000011],
              [108.24239572600007, 15.974315967000051],
              [108.24520864500002, 15.975825591000079],
              [108.2495431630001, 15.97632386],
              [108.25254792400003, 15.976264422000062],
              [108.2549738430001, 15.975422782000058],
              [108.25641582900005, 15.973352103000058],
              [108.25883549800005, 15.96770146500006],
              [108.26056717200007, 15.966301417000038],
              [108.26351341500003, 15.965794562000022],
              [108.26559202700007, 15.964617722000083],
              [108.26813032000004, 15.961539069000084],
              [108.26986292900004, 15.960921792000081],
              [108.27119196300005, 15.960976040000039],
              [108.27246807500003, 15.964609102000081],
              [108.27789934200008, 15.964434375000012],
              [108.28229241600003, 15.965602927000065],
              [108.2852988450001, 15.966829142000092],
              [108.28756393500002, 15.968486017000048],
              [108.28790547600003, 15.967925645000046],
              [108.29721649700011, 15.953776466000045],
              [108.30416135700008, 15.944478503000068],
              [108.31547805500003, 15.933387713000078],
              [108.32695371100006, 15.923571943000068],
              [108.34471734200008, 15.911923051000086],
              [108.35027816400002, 15.908275839000028],
              [108.36596760500001, 15.898757247000093],
              [108.38760313300001, 15.885195102000081],
              [108.38970642800004, 15.882741633000117],
              [108.38970167700009, 15.880291994000027],
              [108.38952770100009, 15.880030996000103],
              [108.39344952300004, 15.87702265300006],
              [108.39857502600002, 15.873090748000072],
              [108.40020079900006, 15.873000239000044],
              [108.40251787400003, 15.872179350000069],
              [108.40356978400011, 15.871156682],
              [108.40346212900008, 15.870034124000119],
              [108.40303727400001, 15.868401817000064],
              [108.40176643300002, 15.865342127000035],
              [108.40154177800008, 15.85840184400009],
              [108.40326294500004, 15.850867977000059],
              [108.40394457100004, 15.847884213],
              [108.40838588900007, 15.829452307000034],
              [108.41068720300005, 15.821180297000035],
              [108.41660614500005, 15.804582617000039],
              [108.42090666400007, 15.795387972000084],
              [108.42966879300003, 15.778988455000052],
              [108.43670173500001, 15.767338324000056],
              [108.44934722000002, 15.745826567000075],
              [108.47600075300009, 15.703512256000083],
              [108.49136997600004, 15.679644332000107],
              [108.50641996700007, 15.65587812900011],
              [108.51759531700003, 15.639339371000103],
              [108.5222043470001, 15.632517360000085],
              [108.53830247000006, 15.609767063000033],
              [108.55450500600004, 15.588035988000025],
              [108.556524763, 15.585453497000044],
              [108.57102149200007, 15.566915276000069],
              [108.58764390400007, 15.54722198000009],
              [108.60751671, 15.524864410000115],
              [108.61701175400002, 15.51611034900011],
              [108.62320547600009, 15.511703439000078],
              [108.6298191070001, 15.507090987000092],
              [108.63948392000006, 15.502673368000062],
              [108.64395691300005, 15.500311538000043],
              [108.6446596370001, 15.497986827000075],
              [108.64442961100008, 15.49699704500007],
              [108.64385825300006, 15.495678135000011],
              [108.64192789700003, 15.495023613000035],
              [108.63739260400004, 15.495587431000056],
              [108.63513220900006, 15.496692416000089],
              [108.63423672900004, 15.492955547000074],
              [108.63314932600005, 15.489822268000085],
              [108.63251737100006, 15.487237906000038],
              [108.63205332000004, 15.483937702000093],
              [108.63153896700003, 15.482673619000041],
              [108.63091343500005, 15.482125206000051],
              [108.629947825, 15.481632808000077],
              [108.62904048000007, 15.481635477000031],
              [108.62813433700006, 15.482023318000058],
              [108.62677724800004, 15.483292906000122],
              [108.62497023, 15.485774367000092],
              [108.62277248500007, 15.490292921000036],
              [108.62093444700004, 15.491949043000107],
              [108.61923544800007, 15.492724326000127],
              [108.61674010200001, 15.492731529000102],
              [108.61379157200003, 15.492905089000073],
              [108.61175243000007, 15.493736313000015],
              [108.60965789700005, 15.495007890000066],
              [108.60790361600002, 15.496278481000035],
              [108.60660036900002, 15.496667367000059],
              [108.60540921900005, 15.496615713000049],
              [108.60339342100006, 15.495796008000083],
              [108.60032435100005, 15.493603586000047],
              [108.59890227900007, 15.492176890000041],
              [108.59799013600011, 15.490583683000112],
              [108.59747304100003, 15.488329053000081],
              [108.59723803500005, 15.485578403000131],
              [108.597574702, 15.484366888000077],
              [108.59853582900004, 15.483373730000059],
              [108.59944236299999, 15.483096056000088],
              [108.60086010500007, 15.483092081000025],
              [108.60284626, 15.483526712000105],
              [108.60460393200003, 15.483411704000092],
              [108.60613663500001, 15.482654426000051],
              [108.60671581500006, 15.481585444000066],
              [108.60697805000009, 15.480372438000066],
              [108.6078798250001, 15.478988602000053],
              [108.61014030100009, 15.477538113000032],
              [108.61230635400007, 15.476966867000018],
              [108.61566989100001, 15.47651772200007],
              [108.61819220600007, 15.476070954000052],
              [108.62045701700006, 15.476064402000043],
              [108.62304441400009, 15.475742970000093],
              [108.62440115400008, 15.475048374],
              [108.62504571300003, 15.474230287000077],
              [108.62594518900002, 15.472155749000114],
              [108.62658132100009, 15.468637912000103],
              [108.62605703400004, 15.466504755000086],
              [108.62488842800008, 15.465252467000122],
              [108.62210104500011, 15.46362817500002],
              [108.62073275000004, 15.460555672000085],
              [108.62033814700008, 15.458484909000079],
              [108.62052393000009, 15.455784612000032],
              [108.62197275900006, 15.453520138000075],
              [108.62558298699999, 15.449303022000025],
              [108.62874762600009, 15.447472979000041],
              [108.632946992, 15.445514268000069],
              [108.63465650000005, 15.444257005000049],
              [108.63817549400007, 15.441668857000044],
              [108.64153658300008, 15.440654242000027],
              [108.64483435699999, 15.440079272000061],
              [108.64929665600008, 15.439500712000063],
              [108.65065550600008, 15.439559367000031],
              [108.65208372100003, 15.441061865000092],
              [108.65285390400007, 15.442729362000039],
              [108.65267496100009, 15.443822610000078],
              [108.65132034700011, 15.445082429000044],
              [108.64815292900005, 15.445971039000115],
              [108.64550105400009, 15.446230197000064],
              [108.64255803000003, 15.446490187000082],
              [108.64126663600004, 15.447310265000079],
              [108.64094773100003, 15.44875527500009],
              [108.64166644300008, 15.4509506000001],
              [108.64322648300001, 15.453206178000032],
              [108.64374912000008, 15.4547742350001],
              [108.64388393800004, 15.456469030000084],
              [108.64366369800004, 15.458416028000064],
              [108.64367192800009, 15.460990191000066],
              [108.64439372800004, 15.464127277000015],
              [108.64668159500008, 15.471340675000087],
              [108.64766028600005, 15.47384912400007],
              [108.64869885700008, 15.474850540000061],
              [108.64986604600003, 15.475600424000023],
              [108.65206798100006, 15.476158799000038],
              [108.65349157500007, 15.476154458000034],
              [108.65433115899999, 15.475649604000095],
              [108.65497519000007, 15.47470585600008],
              [108.65561368500006, 15.472066929000048],
              [108.65580351500004, 15.470747852000065],
              [108.65638076000002, 15.469176453000076],
              [108.6576042450001, 15.467351932000016],
              [108.66008369700008, 15.463765542000106],
              [108.66336213500009, 15.45722575500008],
              [108.66548571300011, 15.453703189000032],
              [108.66606532600011, 15.452885181000029],
              [108.667486475, 15.452190121000074],
              [108.66923278300008, 15.451996315000114],
              [108.67078498200007, 15.451803093000114],
              [108.671688923, 15.451235198000022],
              [108.67224726800001, 15.450149931000022],
              [108.672035808, 15.448534350000019],
              [108.67125396600007, 15.446904385000069],
              [108.67192786200005, 15.445269861],
              [108.67367201400003, 15.444448174000057],
              [108.67535649800006, 15.445133498000095],
              [108.67730612900007, 15.447701532000034],
              [108.67860922900007, 15.45039716400005],
              [108.67861663500005, 15.452594620000058],
              [108.67778290500009, 15.45479474200002],
              [108.67617716200002, 15.45831578700003],
              [108.67511355700003, 15.459512066000068],
              [108.67207667400001, 15.460777326000128],
              [108.66858389800004, 15.461164981000051],
              [108.66722782000001, 15.461985418000078],
              [108.66697149600003, 15.462739641000043],
              [108.66743005300005, 15.464433414000032],
              [108.66911718600002, 15.465872204000062],
              [108.67456003600006, 15.468115385000123],
              [108.67890511100003, 15.470989775000097],
              [108.68372625500005, 15.474286889000053],
              [108.68465140500004, 15.475356934000038],
              [108.68494159200007, 15.477309439000029],
              [108.68466373000008, 15.480389215000033],
              [108.68421673700004, 15.482148630000035],
              [108.68305662500008, 15.483533613000054],
              [108.682867813, 15.485103848000035],
              [108.68397410200005, 15.486921094000115],
              [108.68611154200006, 15.487479317000028],
              [108.68785642200001, 15.486783083000065],
              [108.68992033500008, 15.484767309000079],
              [108.69081768200007, 15.482253003000055],
              [108.69119409500004, 15.478798596000052],
              [108.69170573000009, 15.477038958000032],
              [108.69408699000005, 15.473264133000074],
              [108.69685139900005, 15.468043978000077],
              [108.69945042000008, 15.46181976800005],
              [108.70021770000005, 15.45918027900006],
              [108.70021551600006, 15.458552434000071],
              [108.69943406100006, 15.457110932000083],
              [108.69794110200007, 15.455734529000063],
              [108.69703026900002, 15.454293440000109],
              [108.6958556500001, 15.451409138000114],
              [108.69506905300003, 15.448460799000062],
              [108.6950560490001, 15.444693734000035],
              [108.69588845300005, 15.442179624000028],
              [108.69775164300003, 15.438406452000029],
              [108.70825505100001, 15.426379998000071],
              [108.72050628900008, 15.415037800000063],
              [108.73398196400005, 15.402811719000018],
              [108.73682875600005, 15.40067558300011],
              [108.73530339300005, 15.397145755000084],
              [108.73450233800003, 15.39499188200004],
              [108.72100615000005, 15.378979207000041],
              [108.71713579600001, 15.374383903000053],
              [108.71228496800011, 15.372091221000071],
              [108.70718633000004, 15.371778291000025],
              [108.70251422100009, 15.371958605000112],
              [108.69715986500009, 15.371398897000075],
              [108.68457546600007, 15.368306041000055],
              [108.68002471500003, 15.367520910000019],
              [108.67305552000001, 15.365853203000055],
              [108.66919952600009, 15.365503217000052],
              [108.66435513300009, 15.367087359000029],
              [108.66038428000007, 15.369634259000085],
              [108.6566553480001, 15.370128518000035],
              [108.65018676700001, 15.369424101000016],
              [108.64197080000012, 15.366672914000022],
              [108.63375543800001, 15.36404210100004],
              [108.6254114490001, 15.359963009000042],
              [108.61669513600002, 15.356005358000056],
              [108.61295973900003, 15.354326361000046],
              [108.60512226200011, 15.353021023000045],
              [108.60250390200009, 15.350614503000058],
              [108.60050476700006, 15.347361354],
              [108.59788939300003, 15.345920340000061],
              [108.59316178300001, 15.344967932000129],
              [108.58731803100002, 15.344984055000042],
              [108.5818497110001, 15.34584386700004],
              [108.58024008300006, 15.34570623100011],
              [108.57637755600004, 15.345375900000045],
              [108.57040295300006, 15.343098707000101],
              [108.56392439300004, 15.33840877100001],
              [108.55881844000007, 15.335404796000061],
              [108.55421825500012, 15.335416764000074],
              [108.54800211100005, 15.335553468000017],
              [108.54215504700005, 15.334240721000112],
              [108.53630516600001, 15.331841573000114],
              [108.531074195, 15.32835446600007],
              [108.52770286700003, 15.322810842000044],
              [108.525336666, 15.321247633000022],
              [108.52297551500004, 15.321615507000084],
              [108.51639268400001, 15.324045399000051],
              [108.51154839400002, 15.325746762000046],
              [108.50545896200005, 15.326726794000043],
              [108.50160341700007, 15.326132402000068],
              [108.49699717900006, 15.323608556000055],
              [108.49250564900005, 15.317101436000067],
              [108.48802024600009, 15.313008100000046],
              [108.48403547300009, 15.310241172000081],
              [108.48055439600009, 15.310128332000071],
              [108.47608079800005, 15.310862502000065],
              [108.47023886300003, 15.311237512],
              [108.46079039800004, 15.311016678000032],
              [108.45382851900008, 15.31091087300007],
              [108.44848246100005, 15.310680761000038],
              [108.44264101000009, 15.311296405000084],
              [108.43829314700005, 15.312753689000134],
              [108.43568695200007, 15.314810820000078],
              [108.43469814500006, 15.317468115000031],
              [108.434094241, 15.325676577000101],
              [108.43235629800009, 15.326887050000069],
              [108.43165924600005, 15.326798256000094],
              [108.42676016200006, 15.326174112000055],
              [108.42550035600001, 15.318331474000056],
              [108.42225946000009, 15.31423428200007],
              [108.41665447700005, 15.309055410000026],
              [108.4137932940001, 15.308095402000086],
              [108.40932146000003, 15.309793733],
              [108.40472500400003, 15.311371509000081],
              [108.40148848200003, 15.309205104000066],
              [108.40147806600005, 15.304840824000097],
              [108.40110069400001, 15.302525291000089],
              [108.39930890000008, 15.301225759000124],
              [108.39446073400003, 15.300004249000041],
              [108.39132922500002, 15.299792861000048],
              [108.38879649800008, 15.300810827000076],
              [108.38633982400002, 15.302624849000066],
              [108.38321172400002, 15.304222889000039],
              [108.37963408700003, 15.304663560000085],
              [108.37635287600006, 15.304162681000031],
              [108.37165241300008, 15.302144197000088],
              [108.36426236900004, 15.29687298100013],
              [108.36217344800005, 15.296007946000055],
              [108.36031426000005, 15.296216254000059],
              [108.35889396900004, 15.29637537900008],
              [108.35315679300007, 15.298122113000099],
              [108.34369092600005, 15.299223275000083],
              [108.33877152600007, 15.299737801000022],
              [108.33280803600005, 15.300036691000061],
              [108.32691978300001, 15.300769591000039],
              [108.321776873, 15.301428841000051],
              [108.316930197, 15.300857014000078],
              [108.31327454800005, 15.299125216000052],
              [108.30976438800005, 15.295004532000076],
              [108.307073605, 15.291321136000045],
              [108.30453520500002, 15.287846185000118],
              [108.30020404900003, 15.28278556],
              [108.29826185100009, 15.280037745000106],
              [108.29893079600002, 15.278733932000053],
              [108.30168591700007, 15.276775745000036],
              [108.30332080699999, 15.273443850000133],
              [108.30353843200005, 15.269462513000052],
              [108.30270959600004, 15.263528355000043],
              [108.30166197100004, 15.260779320000049],
              [108.29957111000007, 15.258176499000074],
              [108.29314488300002, 15.254928390000087],
              [108.29042676700001, 15.252498634000043],
              [108.28520141800003, 15.248956853],
              [108.28039427600001, 15.245718581000032],
              [108.2794517020001, 15.243590528000082],
              [108.28069972000006, 15.240141478000078],
              [108.28299125400004, 15.236386860000016],
              [108.28381888000004, 15.231011867000086],
              [108.28475585500011, 15.229185522000053],
              [108.28726103600009, 15.22897938200005],
              [108.29195952100009, 15.229479990000081],
              [108.29843109200007, 15.228862688000076],
              [108.30270958800004, 15.227741335000109],
              [108.30562847100006, 15.224999535000093],
              [108.30614457700004, 15.22114581300009],
              [108.30436106000009, 15.215267495000083],
              [108.29975896800008, 15.209190358000056],
              [108.29244413300005, 15.203725211000039],
              [108.29164596400008, 15.202841636000118],
              [108.29024862100002, 15.201294748000025],
              [108.28972307200006, 15.198760613000049],
              [108.29034671900006, 15.19693467700005],
              [108.29274608800007, 15.196018860000036],
              [108.29785907899999, 15.194997858000113],
              [108.30036170700002, 15.193372055000063],
              [108.30077603300002, 15.191242202000053],
              [108.30014570500001, 15.188505466000011],
              [108.29836610300002, 15.184959177000044],
              [108.29888375700004, 15.182119431],
              [108.30003904300006, 15.177941272000071],
              [108.29974988900003, 15.1748092910001],
              [108.29794444000004, 15.172324327000107],
              [108.29272214400004, 15.16772509900006],
              [108.28645178800011, 15.159442001000032],
              [108.28198505500009, 15.152261905000051],
              [108.27770826699999, 15.145081470000042],
              [108.275139548, 15.138727933000061],
              [108.27371022200003, 15.133478449000036],
              [108.27105130600005, 15.130349475000047],
              [108.26649631800011, 15.127222843000075],
              [108.260234709, 15.123913938000031],
              [108.25691215000008, 15.120509217000054],
              [108.25652129500006, 15.119509149000086],
              [108.25558056200003, 15.117102074000053],
              [108.25623809800008, 15.112310635000091],
              [108.25803070800008, 15.105583142000077],
              [108.25726668700001, 15.101161929000016],
              [108.25692417900009, 15.099771523000081],
              [108.25583739100006, 15.095359596000078],
              [108.25393526500005, 15.090386972000134],
              [108.25118232200001, 15.087165767000064],
              [108.24691387500006, 15.084775426000084],
              [108.23363726000005, 15.079354839000112],
              [108.23676021299998, 15.075113532000016],
              [108.23950204100001, 15.069306466000041],
              [108.23968470000001, 15.063502249000036],
              [108.23778089200006, 15.056502724000074],
              [108.23625737100012, 15.050424035],
              [108.23720140800008, 15.047290677000101],
              [108.23994624200007, 15.044339562000085],
              [108.2408900840001, 15.041114062000103],
              [108.23974773200008, 15.036785399000062],
              [108.23671048100005, 15.032735223000058],
              [108.22847306200006, 15.030839102000114],
              [108.22196969400002, 15.030593072],
              [108.21676750200001, 15.030851302000057],
              [108.21039684300004, 15.033133657000073],
              [108.20636902700002, 15.037183784000073],
              [108.20208102700005, 15.041360529000068],
              [108.19714213100005, 15.045158461000073],
              [108.18908317300001, 15.050982172000092],
              [108.181024667, 15.057817138000116],
              [108.17413385300006, 15.061742640000107],
              [108.16971213600004, 15.063010642000039],
              [108.16398808900001, 15.062509335000039],
              [108.15969427900011, 15.061121700000067],
              [108.15617993100003, 15.058342523000066],
              [108.14993244800003, 15.053415675000091],
              [108.14654807200004, 15.050130477000055],
              [108.14563545000003, 15.047222888000087],
              [108.14719300100003, 15.042669840000132],
              [108.14887868000005, 15.03558782],
              [108.1487439480001, 15.029265735000051],
              [108.14769827800009, 15.022185629000083],
              [108.14886328600002, 15.014724670000044],
              [108.15015956700009, 15.009033826000094],
              [108.15184524100002, 15.002457614000074],
              [108.15561055600008, 14.994741901000095],
              [108.15820269700006, 14.983739507000047],
              [108.15858891500004, 14.97880797600004],
              [108.15741540900002, 14.974636256000016],
              [108.15390047700008, 14.969075374000072],
              [108.1483059920001, 14.964274546000043],
              [108.14218260500007, 14.963083239000062],
              [108.13394505600007, 14.962026963000062],
              [108.12504492700006, 14.96155151900007],
              [108.12331223300006, 14.961458921000046],
              [108.11611439300003, 14.960292990000122],
              [108.10773320000006, 14.954431034000047],
              [108.10348770500005, 14.953020864000075],
              [108.08784860500006, 14.951615610000067],
              [108.08550381500002, 14.953897911000078],
              [108.08517039900006, 14.957917576000044],
              [108.08539578800006, 14.9625888390001],
              [108.08383299400008, 14.965305359000068],
              [108.07891892200008, 14.968349054000017],
              [108.07400403400004, 14.969545806],
              [108.06696595900009, 14.969004947000107],
              [108.06316797800008, 14.969766560000062],
              [108.05992914700005, 14.972592045000075],
              [108.05300530400005, 14.981936609000089],
              [108.04663950400003, 14.991498130000112],
              [108.04253900700004, 14.994540871000071],
              [108.03519509000009, 14.996582550000069],
              [108.02501820100005, 14.99882835000008],
              [108.01893309700007, 15.001787471000048],
              [108.01536577000005, 15.002093815000082],
              [108.01179834700011, 15.000767817000058],
              [108.00823094400008, 14.999033697000106],
              [108.00602762600003, 14.999339818000033],
              [107.99994222000004, 15.001176206000098],
              [107.99595519800002, 15.001278193000099],
              [107.99112887600009, 14.999645766000034],
              [107.987876294, 15.000155695000037],
              [107.98514822200006, 15.001889810000078],
              [107.98283958900001, 15.005970320000104],
              [107.98105557000009, 15.009234717000107],
              [107.97895686300009, 15.011070837000061],
              [107.97413002600004, 15.012702583000069],
              [107.96993251000001, 15.015456497000022],
              [107.96458006700009, 15.021474707000058],
              [107.96135007, 15.028275929000044],
              [107.95494684400008, 15.037864341000072],
              [107.95715782500005, 15.039772237000102],
              [107.95789570600009, 15.042803688000015],
              [107.95684120000006, 15.044792601],
              [107.95650014200008, 15.04543586200011],
              [107.95288881500005, 15.048386637000098],
              [107.95059037200005, 15.051417437000046],
              [107.95075383200006, 15.054129700000106],
              [107.95181993400008, 15.057161266000028],
              [107.95477357300004, 15.060193226000072],
              [107.95912236900006, 15.063624282000097],
              [107.96289717100011, 15.065220410000089],
              [107.96913401800002, 15.067215687000067],
              [107.97307309200008, 15.068891428000073],
              [107.97455014100007, 15.070487041000112],
              [107.97479590500006, 15.074076809000035],
              [107.97487733500006, 15.079182218000129],
              [107.97536947800009, 15.081655197000108],
              [107.97660030000006, 15.084208043000034],
              [107.97988815600004, 15.086298429000065],
              [107.98226033500003, 15.088489112000046],
              [107.98498818400006, 15.093907864000043],
              [107.98736033900003, 15.098634827000089],
              [107.99068151700003, 15.106013454000065],
              [107.99340979099999, 15.112585002000055],
              [107.99590099500006, 15.117657757000112],
              [107.99708731300007, 15.12123172800006],
              [107.99732454800008, 15.124805685000096],
              [107.99732448500006, 15.129532521000042],
              [107.99815495200004, 15.133452357000097],
              [108.00040923800003, 15.137718061000081],
              [108.00207036500008, 15.142214329000046],
              [108.00207039400009, 15.144981262000011],
              [108.00088387500006, 15.14763292500003],
              [107.99886673700004, 15.150169290000102],
              [107.99815936500005, 15.1532210890001],
              [107.99590482500003, 15.155757427000081],
              [107.99270095100005, 15.157832553000093],
              [107.98736107800001, 15.160138095000073],
              [107.98285171000003, 15.162789442000067],
              [107.980003524, 15.165440857000066],
              [107.97537532800004, 15.16763086900008],
              [107.97039114200004, 15.1690137140001],
              [107.96422014600006, 15.170742117000112],
              [107.95769307300002, 15.172354982000067],
              [107.95211513400008, 15.174429023000078],
              [107.94938522700008, 15.17638832200001],
              [107.94807900700009, 15.179616127000093],
              [107.94800952900002, 15.184422226000025],
              [107.94729668500001, 15.187419584000049],
              [107.94516007700007, 15.188917819000023],
              [107.93886937300005, 15.191337206000098],
              [107.93518977000006, 15.192950185000063],
              [107.93281545300007, 15.195024677000022],
              [107.93204880500005, 15.196356522000086],
              [107.93210884900006, 15.198600611000044],
              [107.93168219200001, 15.2018484800001],
              [107.93028972700009, 15.204877493000078],
              [107.92991783100004, 15.205686463000044],
              [107.92864018600002, 15.208343500000064],
              [107.92651160900003, 15.210291588000045],
              [107.92293705000003, 15.210773561000044],
              [107.91679645300009, 15.212126735000096],
              [107.914005575, 15.211944894000061],
              [107.91149428700005, 15.210678757000016],
              [107.90861139499999, 15.208328057000118],
              [107.90554166300002, 15.207784531000089],
              [107.89996020700006, 15.207149436000032],
              [107.89754227100011, 15.20552171600009],
              [107.89373114600008, 15.199736494000058],
              [107.891314811, 15.195397769000072],
              [107.88964097700006, 15.194312530000074],
              [107.88740821600005, 15.194672829000043],
              [107.88219736900004, 15.1972906030001],
              [107.87968386500008, 15.200361614000089],
              [107.87689109700001, 15.203613164],
              [107.872608805, 15.2084],
              [107.86869989800005, 15.210927859000021],
              [107.863117489, 15.211737641000054],
              [107.85576840200008, 15.211190626000091],
              [107.84860561400011, 15.210372390000069],
              [107.83958300400008, 15.208648870000017],
              [107.83455983500005, 15.208102850000047],
              [107.82972137300001, 15.209183435000016],
              [107.8273940710001, 15.210988873000096],
              [107.82385620700002, 15.214058405000051],
              [107.8198316240001, 15.216008802000134],
              [107.81678368800006, 15.216311587000051],
              [107.81343574000007, 15.214953215000014],
              [107.80980873600001, 15.21359454200004],
              [107.80655278500008, 15.213410893000033],
              [107.80096934500004, 15.214941973000061],
              [107.79454713200006, 15.217827581000074],
              [107.78728903400003, 15.21917597],
              [107.77930500500005, 15.219249325000115],
              [107.77502607400004, 15.219838508000104],
              [107.77145859300001, 15.221813535000093],
              [107.76941903400009, 15.223790166000038],
              [107.76880488299999, 15.22636195900011],
              [107.76839389600009, 15.229428679000099],
              [107.76645517400009, 15.232196919000083],
              [107.76105277500002, 15.234961300000103],
              [107.75524346400005, 15.237131426000071],
              [107.75269676800009, 15.237029550000079],
              [107.74933965000005, 15.233265891000068],
              [107.74598053700004, 15.231184167000043],
              [107.74190702400006, 15.23018987200002],
              [107.74115877700004, 15.230198052000075],
              [107.73510073500009, 15.23026418400004],
              [107.73531961200008, 15.235755066000054],
              [107.73405509700008, 15.242394856000095],
              [107.73181013300005, 15.251744188000059],
              [107.72803467700004, 15.261175892],
              [107.7256533570001, 15.267814234000097],
              [107.72522676300005, 15.273641839000083],
              [107.72479570200001, 15.282722376000011],
              [107.72464280200006, 15.292480984000088],
              [107.72602834500003, 15.30298103700005],
              [107.727016647, 15.309086637000034],
              [107.71975125800007, 15.309014597000024],
              [107.7138850640001, 15.308301570000092],
              [107.71019931300005, 15.306777571000028],
              [107.70634685100006, 15.304656519000035],
              [107.70539654700012, 15.304926462000077],
              [107.7034372680001, 15.307365065000054],
              [107.70063789900003, 15.311050255000056],
              [107.6975052830001, 15.313324399000104],
              [107.69314107800004, 15.317007275],
              [107.69101496600007, 15.318740249000127],
              [107.68843858000007, 15.322534115000062],
              [107.68339882300008, 15.329145376000051],
              [107.68026141600009, 15.334131845000075],
              [107.67824175200006, 15.339011448000049],
              [107.67633673400009, 15.342046660000095],
              [107.67320318500008, 15.344428955000032],
              [107.66794489500003, 15.347350447000084],
              [107.66493511600005, 15.351021120000039],
              [107.66263511699999, 15.353718353000099],
              [107.66151840000006, 15.355944781000106],
              [107.66123379900004, 15.35972551400002],
              [107.66094815500003, 15.364113924000032],
              [107.65996764900009, 15.368028596000014],
              [107.659057348, 15.371538244000059],
              [107.65828615800005, 15.375048122000033],
              [107.65848936400008, 15.378289464000082],
              [107.65959665300004, 15.381734805000045],
              [107.65984229400001, 15.385038761000123],
              [107.65965083599998, 15.386142493000053],
              [107.65948818500003, 15.387080150000097],
              [107.65917574600012, 15.387688573000078],
              [107.6578305100001, 15.389751495000057],
              [107.65587130500009, 15.392957146000084],
              [107.65391212100008, 15.396114160000083],
              [107.65180325200002, 15.398881964000031],
              [107.64763961800004, 15.402035324000083],
              [107.64302477800004, 15.405285085000029],
              [107.64044220600005, 15.408305761000028],
              [107.63816115500006, 15.412764795000021],
              [107.63656973500009, 15.415839949000086],
              [107.63450565200003, 15.417170167000014],
              [107.63080295800009, 15.418394950000012],
              [107.62794917200003, 15.41792833400004],
              [107.62194886000006, 15.416072030000104],
              [107.62092689700006, 15.417657974000052],
              [107.61982117300005, 15.418483388000046],
              [107.61019644000004, 15.418596760000101],
              [107.60908559200006, 15.419015996000061],
              [107.60839490800001, 15.419565694000068],
              [107.60715976800005, 15.421204947000076],
              [107.60549261800004, 15.421766103000055],
              [107.60312462300004, 15.422064672000067],
              [107.60130953400005, 15.42195055800004],
              [107.60018516400001, 15.42128676],
              [107.59988259200003, 15.419394769000048],
              [107.59943547700007, 15.417098281000017],
              [107.599148083, 15.41642466700006],
              [107.59830272700005, 15.415757580000021],
              [107.59619861400003, 15.414834418000021],
              [107.59347018300006, 15.414189323000114],
              [107.59110055200001, 15.414352379000016],
              [107.58816792400002, 15.41411579600007],
              [107.58549920600009, 15.412657525000027],
              [107.58451106200005, 15.411721240000096],
              [107.58253649200002, 15.409984047000068],
              [107.57195050400004, 15.399425152000033],
              [107.56422178200006, 15.39617292400006],
              [107.56213323900009, 15.395732640000046],
              [107.5596226060001, 15.395761568000042],
              [107.5578159890001, 15.396323960000034],
              [107.54741701500006, 15.402928080000054],
              [107.54561605800009, 15.404435681000093],
              [107.54257497, 15.40632921600009],
              [107.53694313100004, 15.407787619000056],
              [107.53588997000004, 15.407799643000073],
              [107.53540900200004, 15.407619273000071],
              [107.53193152900008, 15.406317947000046],
              [107.52964194800009, 15.405826289000045],
              [107.52840179800006, 15.406212148000085],
              [107.52755022400004, 15.407058272000105],
              [107.51897954500001, 15.414962308000034],
              [107.51708362500008, 15.416563734000109],
              [107.51652705200004, 15.418057033000119],
              [107.51655167500004, 15.42010136400007],
              [107.51706850300008, 15.423255360000086],
              [107.51757302099999, 15.425387186000082],
              [107.51786453500004, 15.426071152000096],
              [107.52463668800002, 15.438692776],
              [107.52536073600007, 15.44057520000006],
              [107.52510997900001, 15.443349194000076],
              [107.52479740500009, 15.444296053000082],
              [107.52270670500012, 15.445577596000069],
              [107.52138433200004, 15.446761774000082],
              [107.52075724700002, 15.448498300000056],
              [107.52017176500007, 15.450322954000038],
              [107.51813530400005, 15.456829135000037],
              [107.51637280400007, 15.459163456000089],
              [107.51521964500004, 15.460673515000066],
              [107.514161825, 15.46249700200004],
              [107.51342508100007, 15.466370487000079],
              [107.51269955500008, 15.472839198000086],
              [107.51235238400004, 15.473680030000112],
              [107.51098280200006, 15.474324441000052],
              [107.50872211200006, 15.474978955000028],
              [107.50419501300011, 15.475816326000123],
              [107.49843645400007, 15.476683076000073],
              [107.49537311100009, 15.477975411000065],
              [107.49289867600008, 15.479717159000115],
              [107.48998974200001, 15.480378802000059],
              [107.48706949700005, 15.48009721300012],
              [107.48365944199999, 15.479506638000105],
              [107.47991783200003, 15.478290844000052],
              [107.47749305000004, 15.478789676000114],
              [107.476015617, 15.479768980000051],
              [107.47534426700005, 15.481258580000111],
              [107.47541272900004, 15.482170618000078],
              [107.47580661100004, 15.482850904000054],
              [107.477602, 15.484462096000049],
              [107.4791017470001, 15.485606513000059],
              [107.48037695400009, 15.486546101000085],
              [107.48075845400004, 15.487568830000063],
              [107.48077506900007, 15.488961013000056],
              [107.47947966400001, 15.49105775200008],
              [107.47807001800008, 15.49226085000006],
              [107.47463466200006, 15.494911842000112],
              [107.47179864100006, 15.496895478000015],
              [107.46927020900003, 15.498592619000105],
              [107.46647641800006, 15.49950892200007],
              [107.4619373140001, 15.499402314000042],
              [107.45902050600009, 15.499434795000065],
              [107.45773159400002, 15.500078028000084],
              [107.45709462000008, 15.501275134000101],
              [107.45585380000009, 15.503003119000079],
              [107.45345281000002, 15.504117076000055],
              [107.44624161600004, 15.504833746000111],
              [107.436100251, 15.504864351000068],
              [107.43245287400002, 15.504349304000085],
              [107.43020074900002, 15.502591304000111],
              [107.429414885, 15.500840587000118],
              [107.42902893700006, 15.498665921000041],
              [107.42900872400006, 15.496936656000047],
              [107.42946730900007, 15.494573222000072],
              [107.42944709300009, 15.492843957000078],
              [107.4288814170001, 15.491969692000039],
              [107.42764441700007, 15.491134262000104],
              [107.42488970300005, 15.49116449800003],
              [107.41606381100004, 15.491732800000067],
              [107.40797068100002, 15.492607240000032],
              [107.40732249400003, 15.492614300000083],
              [107.39777262000008, 15.493661458000039],
              [107.39292746800004, 15.495129034000053],
              [107.38996151200003, 15.496408294000029],
              [107.38891150900011, 15.496419641000079],
              [107.38691336100007, 15.495461572000034],
              [107.38333201500004, 15.4940851380001],
              [107.38222132800007, 15.494301296000044],
              [107.3804601250001, 15.49481640200012],
              [107.37999086800008, 15.496897915000128],
              [107.37962772800006, 15.499576101000033],
              [107.37782079300004, 15.502269817000068],
              [107.3782670410001, 15.506849470000093],
              [107.37830059100003, 15.509778075000023],
              [107.37792868, 15.511692268000042],
              [107.37616683300006, 15.518333236000091],
              [107.37345541900007, 15.522310143000071],
              [107.37275317200005, 15.523805452000071],
              [107.37261266100006, 15.525693737000077],
              [107.37266662600008, 15.530410105000046],
              [107.37220197100001, 15.532301876000075],
              [107.36742931700009, 15.540214752000024],
              [107.36312606400001, 15.549036515000035],
              [107.36251401700008, 15.551492957000066],
              [107.36260782100004, 15.553683409000117],
              [107.36312095500003, 15.557292115000097],
              [107.363923544, 15.561380596000072],
              [107.36406066000006, 15.561888502000086],
              [107.3641178140001, 15.566320172000035],
              [107.36283015600004, 15.570452660000099],
              [107.361545827, 15.571567064000112],
              [107.35864393200006, 15.573013282000135],
              [107.35767124200005, 15.573023710000109],
              [107.35669138200007, 15.572405268000098],
              [107.35488842400011, 15.570695008000078],
              [107.35228924700007, 15.57025110900012],
              [107.34357253000005, 15.573646010000084],
              [107.34212773000006, 15.574919268000096],
              [107.34132603500007, 15.575713975000037],
              [107.34133315700004, 15.57634283300011],
              [107.34140291500003, 15.580360351000076],
              [107.34383128300006, 15.584390697000037],
              [107.35071444199998, 15.589156437],
              [107.35125701800011, 15.590672533000125],
              [107.35116529000007, 15.592590195000076],
              [107.34927696800005, 15.597641835000074],
              [107.34737962500002, 15.601907410000047],
              [107.34577075500003, 15.603025231000077],
              [107.34318365800003, 15.603681773000043],
              [107.34140011700008, 15.603700789000106],
              [107.34027226100001, 15.604341748000115],
              [107.34028474200007, 15.605442246000043],
              [107.34064827400005, 15.608897513000029],
              [107.34118289100005, 15.613137125000074],
              [107.34105765800007, 15.614598216000084],
              [107.34033869800001, 15.615549283000083],
              [107.33177191400004, 15.622235933000093],
              [107.33034525100004, 15.623127164000026],
              [107.32582893200001, 15.624935703000096],
              [107.31887199900011, 15.626424458000086],
              [107.31475766000004, 15.626724195000106],
              [107.30783971000008, 15.626069090000081],
              [107.30232092100006, 15.625655364000044],
              [107.29598430600007, 15.62462111100009],
              [107.29144375000007, 15.62466857800011],
              [107.28796247900007, 15.625176618000046],
              [107.28668292000006, 15.6256775030001],
              [107.28546012100006, 15.626267269000047],
              [107.28337135500007, 15.627435183000047],
              [107.28155088600008, 15.629109708000042],
              [107.27594951600007, 15.633370542000067],
              [107.27386904100005, 15.635302418000039],
              [107.26913100100008, 15.641075476000042],
              [107.2676971720001, 15.64434387200002],
              [107.266525836, 15.648732960000087],
              [107.26527155300005, 15.651485120000073],
              [107.26315876300002, 15.655624085000051],
              [107.26245312800008, 15.657832733000097],
              [107.26263608700003, 15.659717728000098],
              [107.26315383700006, 15.662542696000068],
              [107.26349554700009, 15.664111576000026],
              [107.26498478700003, 15.666769246000099],
              [107.26648793400005, 15.670684694000057],
              [107.26880565000006, 15.67490614600011],
              [107.27012763600003, 15.677093790000105],
              [107.27309086900007, 15.680993994000046],
              [107.27392456400003, 15.683029438000061],
              [107.27393849800004, 15.684287198000034],
              [107.27330183600003, 15.685394501000081],
              [107.27105521400003, 15.687619192000033],
              [107.27091632200009, 15.689075955000041],
              [107.27173282100006, 15.691614523000029],
              [107.27282989500006, 15.694047552000077],
              [107.27479920900001, 15.696071159],
              [107.27538700300006, 15.697355697000058],
              [107.2751587170001, 15.69815986400007],
              [107.27498934000002, 15.698585006000075],
              [107.27386946600009, 15.700011817000091],
              [107.27129826300006, 15.702239910000058],
              [107.26656915300002, 15.70781317600005],
              [107.26538537100004, 15.710087063000076],
              [107.2642836470001, 15.713476755000054],
              [107.2648451200001, 15.720232234000074],
              [107.26485903700011, 15.721490004000048],
              [107.26421528400004, 15.721968400000017],
              [107.26219246100001, 15.722461101000105],
              [107.25813984300007, 15.722817564000112],
              [107.25668137000009, 15.722989900000071],
              [107.25489495699999, 15.722851129000047],
              [107.25222242300002, 15.721744605000064],
              [107.25077135400004, 15.721250170000099],
              [107.25000990000009, 15.721518003000055],
              [107.24914218900007, 15.723081537000015],
              [107.24780915300008, 15.726756924000094],
              [107.24790056800001, 15.729867386000061],
              [107.24794093900006, 15.731240972000114],
              [107.24845594500005, 15.732919749000105],
              [107.251095037, 15.735839676000028],
              [107.25173091300003, 15.73721646700008],
              [107.25223653200004, 15.73875495],
              [107.25211302600012, 15.739697678000118],
              [107.24991926900006, 15.742557586000048],
              [107.24719022600003, 15.745258833],
              [107.24541396100005, 15.746063350000037],
              [107.24281945700005, 15.746247319000071],
              [107.238919939, 15.745815704000064],
              [107.23355832600002, 15.745241756],
              [107.22316138000006, 15.744247380000063],
              [107.22039262500004, 15.743332142],
              [107.21679380400008, 15.740695636000089],
              [107.2148415390001, 15.740243765000093],
              [107.21240759400007, 15.740268481],
              [107.21184648700009, 15.740903140000027],
              [107.21136819700004, 15.741694220000022],
              [107.21028334800008, 15.746422548000092],
              [107.20921681200012, 15.755424391000082],
              [107.20937716300006, 15.758097254000079],
              [107.20991117500002, 15.758855976000062],
              [107.2130295310001, 15.760232138000061],
              [107.21466487700005, 15.760845367000085],
              [107.21562361500007, 15.764401588000075],
              [107.21605386900005, 15.76770847100004],
              [107.21622207800002, 15.770263240000036],
              [107.21644756700003, 15.776078951000015],
              [107.21703438400009, 15.777802641000081],
              [107.21851383300002, 15.779517230000105],
              [107.22151116300003, 15.78066935600013],
              [107.22330497800004, 15.781437232000071],
              [107.226167328, 15.782804345000033],
              [107.227493194, 15.784299945000056],
              [107.22766064400001, 15.784769959000043],
              [107.22766752100004, 15.785398868000032],
              [107.22690418500004, 15.791869937000056],
              [107.225425548, 15.797971769000084],
              [107.22459667200008, 15.80146903000008],
              [107.22361537400006, 15.806160322000016],
              [107.21889649800006, 15.814123122000099],
              [107.21567755400008, 15.818956235000046],
              [107.21341112100006, 15.822087552000051],
              [107.20971714800004, 15.826004794000069],
              [107.20856087500002, 15.827106107000064],
              [107.20840367000008, 15.82757944600006],
              [107.20871770700003, 15.828557254000051],
              [107.20996994000008, 15.829834687000053],
              [107.21162286800006, 15.830947564000029],
              [107.21407567600006, 15.832118705000118],
              [107.21618522600008, 15.833308266000044],
              [107.21683971800006, 15.833773301000063],
              [107.21812026500005, 15.835453879000026],
              [107.21948689300007, 15.836731673000058],
              [107.22088813800009, 15.837322518000095],
              [107.22304075800012, 15.837336152000068],
              [107.22971563200005, 15.838997248000018],
              [107.23020955800006, 15.839621129000124],
              [107.23022336900004, 15.840878917000099],
              [107.23071821600004, 15.843887904000018],
              [107.231696771, 15.844781792000083],
              [107.23254401800003, 15.844971385000049],
              [107.23432340700001, 15.844610460000029],
              [107.23787071200005, 15.842372490000043],
              [107.24065264000009, 15.840939730000068],
              [107.24596167900008, 15.839930191000027],
              [107.24775784300002, 15.840855009000075],
              [107.24940039600003, 15.842567602000111],
              [107.25334891200006, 15.847243754000127],
              [107.25531798600008, 15.849110125],
              [107.25842528500006, 15.851121846000103],
              [107.26054279100002, 15.851728712000044],
              [107.26655487000008, 15.852137597000125],
              [107.26866887900012, 15.852429935000066],
              [107.26964999300003, 15.853048608000096],
              [107.26974699400006, 15.854462719000127],
              [107.26960926600003, 15.85666548300013],
              [107.26963388900003, 15.858866550000082],
              [107.26981559800005, 15.860594260000125],
              [107.27047380000006, 15.861373543000033],
              [107.27340849400005, 15.862443398],
              [107.27422026199999, 15.862434871000096],
              [107.27486261900002, 15.861799181000078],
              [107.27694496700011, 15.859261502000075],
              [107.27872552300008, 15.858771055000085],
              [107.27970139000008, 15.858918014000077],
              [107.28116961100005, 15.859531493000121],
              [107.28542438500003, 15.862474107000056],
              [107.28657147899999, 15.863405406000107],
              [107.28988471500004, 15.866950192000038],
              [107.29226237100004, 15.868342095000097],
              [107.29543397400009, 15.86981346800007],
              [107.29784862400004, 15.869889820000081],
              [107.299936067, 15.867823595000095],
              [107.30090842000007, 15.867656027000086],
              [107.30237138700006, 15.867797703000084],
              [107.30531880900004, 15.869967593000032],
              [107.307945144, 15.872455332000078],
              [107.31044145500005, 15.877774623000017],
              [107.31410143100001, 15.885439897000085],
              [107.31467331100008, 15.885748241000018],
              [107.32332386600004, 15.889586235000051],
              [107.32577024800004, 15.89050332000008],
              [107.32788105600008, 15.890480589000065],
              [107.33514052200005, 15.886314269000044],
              [107.33627708600002, 15.886301986000094],
              [107.34513769200007, 15.894224763000063],
              [107.34709342900005, 15.894832446000072],
              [107.35082661600003, 15.894926406000119],
              [107.35451021000007, 15.895011824000081],
              [107.35834635600004, 15.89539479900006],
              [107.36279152300004, 15.897176982000081],
              [107.36817000700009, 15.898847589000107],
              [107.368819488, 15.898840466000051],
              [107.36946713700003, 15.898676131000043],
              [107.37059268200008, 15.897720410000103],
              [107.37685679400003, 15.891834201000064],
              [107.38249700200005, 15.88815587],
              [107.38298409000004, 15.888150505000096],
              [107.38560224600003, 15.889851108000117],
              [107.38658569200004, 15.890626381000049],
              [107.39002687900002, 15.893261176000093],
              [107.39508250000009, 15.895091902000045],
              [107.39660848900004, 15.895557181000084],
              [107.39835031200002, 15.896785117000077],
              [107.39918077700007, 15.898348146000084],
              [107.40000940100006, 15.899753953000049],
              [107.40477616700011, 15.908587200000033],
              [107.40793974800003, 15.911143011000116],
              [107.41022801700009, 15.912375250000109],
              [107.41316582100008, 15.913600217000033],
              [107.41414761400004, 15.914218127000089],
              [107.415483679, 15.916997113000074],
              [107.41712654700005, 15.925662013000073],
              [107.41771067100007, 15.92656211900003],
              [107.41843082100006, 15.927044201000038],
              [107.41942257600004, 15.927522934000125],
              [107.42186405299999, 15.927967203000025],
              [107.42333687600009, 15.928893978000056],
              [107.42508358000001, 15.930313681000106],
              [107.42644472900001, 15.932843431000075],
              [107.42643153700007, 15.934446020000069],
              [107.42614068799999, 15.936943437000116],
              [107.42629877100011, 15.939600464000046],
              [107.42912854700003, 15.946826692000025],
              [107.4305957230001, 15.953417351000086],
              [107.43281595800003, 15.959052218000112],
              [107.4364482400001, 15.965627588000062],
              [107.43864196600006, 15.971195519000057],
              [107.44480677500007, 15.980662375000064],
              [107.44526563500003, 15.983124990000073],
              [107.4451002160001, 15.983857974000058],
              [107.44286273500006, 15.987494878000062],
              [107.44167324900006, 15.989437048000086],
              [107.44143675100003, 15.991368378000102],
              [107.44144286400009, 15.99187144300007],
              [107.44170379500009, 15.991952329000101],
              [107.44403893300004, 15.991590365],
              [107.44697950500007, 15.991137630000106],
              [107.447895406, 15.991324885000056],
              [107.44861722600004, 15.991945057000033],
              [107.45076095000005, 15.994685566000031],
              [107.45309726000008, 16.000000243000031],
              [107.45537503700008, 16.006191069000053],
              [107.4578270260001, 16.010336142000092],
              [107.45969223100006, 16.013364278000083],
              [107.461696778, 16.015823444000077],
              [107.46274216100008, 16.017584418000027],
              [107.46340518800005, 16.019360750000097],
              [107.46398092500007, 16.021698700000094],
              [107.46451277800003, 16.023323721000082],
              [107.46516325700003, 16.024995924000059],
              [107.46540490900004, 16.02599312400006],
              [107.47027109200006, 16.030596168000066],
              [107.47380924300006, 16.033176928000081],
              [107.47816099300006, 16.035330935000047],
              [107.4812595220001, 16.03662448200005],
              [107.48332211100006, 16.038701395000118],
              [107.48656441400001, 16.041567015000076],
              [107.48878046000004, 16.041429416000064],
              [107.49195843500009, 16.040579606000094],
              [107.49484365500007, 16.038657364000116],
              [107.49906230200007, 16.035094942000057],
              [107.50150781300009, 16.031742668000071],
              [107.50388662500009, 16.025460913000074],
              [107.50574325800005, 16.021249870000084],
              [107.50818620400003, 16.018826310000108],
              [107.51143950300009, 16.017190480000032],
              [107.51520829, 16.016127327000085],
              [107.5173328170001, 16.016278957000019],
              [107.51830933300008, 16.016348650000097],
              [107.52163039200005, 16.017213434000062],
              [107.52421487000002, 16.017290622000054],
              [107.52864871900006, 16.016014375000061],
              [107.53500432900003, 16.013956295000042],
              [107.53826373000007, 16.012579778000102],
              [107.54114647300003, 16.01176010700005],
              [107.54456138800005, 16.011509298000078],
              [107.54701414800004, 16.01208225900006],
              [107.550691183, 16.013896546000069],
              [107.55452558700009, 16.016949874000105],
              [107.55702599200002, 16.02015522700011],
              [107.55862097100001, 16.022687691000037],
              [107.55882938800002, 16.024959216000042],
              [107.55887411900008, 16.028882106000083],
              [107.55866240600005, 16.031239857000031],
              [107.55750877300008, 16.033227019000051],
              [107.55380904000003, 16.037725082000073],
              [107.55186544300007, 16.041290499000112],
              [107.55107475700002, 16.043219873000055],
              [107.55173610500003, 16.045035246000069],
              [107.55397009400004, 16.046912407000043],
              [107.56013071700005, 16.05125528100011],
              [107.56405867500007, 16.053135800000092],
              [107.56732290900005, 16.054254200000081],
              [107.57228138400006, 16.055200389000035],
              [107.57669643700007, 16.055677271000057],
              [107.58322748299999, 16.056801845000024],
              [107.589215338, 16.057340025000052],
              [107.59544531600005, 16.057819986000062],
              [107.59925508300006, 16.058529314000047],
              [107.60215639800005, 16.059763540000027],
              [107.60948036100004, 16.061893549000047],
              [107.61808984100006, 16.06495746700007],
              [107.62174582700004, 16.058836889000091],
              [107.62310882300002, 16.054905499000057],
              [107.62382474300001, 16.050288825000081],
              [107.62287858900007, 16.047300341000081],
              [107.62033710200011, 16.043443482000086],
              [107.61795706700009, 16.038508209000035],
              [107.61812266100003, 16.035195452000025],
              [107.61932082800004, 16.033348438000061],
              [107.62131366000008, 16.032427383000055],
              [107.62497796400004, 16.032048585000069],
              [107.62959810600005, 16.031594317000049],
              [107.63374205600007, 16.030214566000019],
              [107.63900384800006, 16.027218616000113],
              [107.64498135900001, 16.023276590000087],
              [107.64856097000008, 16.021033928000072],
              [107.65222764600006, 16.020088587000103],
              [107.65660793000011, 16.020009155000103],
              [107.65935653500006, 16.019395348000032],
              [107.66045318800012, 16.019150443000015],
              [107.66447906700006, 16.017167672000099],
              [107.66797025700001, 16.014146201000031],
              [107.67039039500006, 16.010085232000023],
              [107.67415471800003, 16.00386419800008],
              [107.67630535800006, 16.000494622000083],
              [107.67751474800004, 15.999261510000016],
              [107.68173406300009, 15.997989562000024],
              [107.68671487000009, 15.997111957000063],
              [107.69194960100005, 15.996283758000105],
              [107.69667530400008, 15.996044671000041],
              [107.70089260000006, 15.996001433000105],
              [107.70378797400008, 15.996546242000063],
              [107.7068851610001, 15.998074550000039],
              [107.71099691300005, 16.000784107000051],
              [107.716479597, 16.004282001000099],
              [107.72160595000005, 16.008369249000062],
              [107.72419255700002, 16.011912342000031],
              [107.72507896400001, 16.016834179000035],
              [107.72466817500003, 16.02191274000004],
              [107.72377231400006, 16.027303237000098],
              [107.72489741700008, 16.031446087000042],
              [107.72804358600007, 16.034341268000048],
              [107.73522680100004, 16.038569789000064],
              [107.74418739700008, 16.042800201000063],
              [107.75121182500006, 16.045308925000064],
              [107.75621678900011, 16.047971411000091],
              [107.76009106000004, 16.050554381000026],
              [107.76396691100008, 16.051887053000065],
              [107.76630815500009, 16.05306170500009],
              [107.76840601100011, 16.055173744000051],
              [107.77042197000003, 16.058301497000024],
              [107.77183187900008, 16.060164772000093],
              [107.77253443600003, 16.062210940000021],
              [107.77248435500005, 16.064938118000072],
              [107.77210521900004, 16.067755846000061],
              [107.77504361100006, 16.068194238000011],
              [107.78126898200001, 16.06836794000008],
              [107.78509931100004, 16.068177436000042],
              [107.7877548220001, 16.067538117000076],
              [107.79121580700003, 16.065283418000128],
              [107.7969252760001, 16.062529009000045],
              [107.80029976300006, 16.060106819000097],
              [107.80332931300009, 16.056680656000118],
              [107.80506056300007, 16.054591410000093],
              [107.80653112100006, 16.053840042000012],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 47, code: "AD01", ten_tinh: "Quảng Ngãi" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [109.13983592500004, 15.377546459000067],
              [109.13737426300003, 15.377354804000019],
              [109.13484346200005, 15.377368243000065],
              [109.13188473000011, 15.376291926000095],
              [109.12920415900001, 15.374668088000119],
              [109.125743105, 15.371751591000036],
              [109.121726231, 15.369998203000089],
              [109.117294085, 15.369407140000122],
              [109.11385216400006, 15.369902838000103],
              [109.10957303700012, 15.371563063000069],
              [109.10086615600002, 15.373450786000118],
              [109.09777635400008, 15.374080952000034],
              [109.09552864699999, 15.37443372100009],
              [109.09430323200003, 15.375327235000034],
              [109.09054734700004, 15.382785621000037],
              [109.09027758900001, 15.38490274000006],
              [109.09084630100008, 15.386060091000074],
              [109.09352855700007, 15.388025642000079],
              [109.095260282, 15.389723021000099],
              [109.098221993, 15.391345834000079],
              [109.10350084500006, 15.392410674000077],
              [109.11011180000003, 15.392785971000043],
              [109.11696430700007, 15.39234080900007],
              [109.12174317100006, 15.391974561000053],
              [109.12575055600007, 15.391953515000088],
              [109.1287694690001, 15.391186860000071],
              [109.13291285200009, 15.390345961000039],
              [109.13670428500009, 15.389438608000104],
              [109.14007734200003, 15.389147665000012],
              [109.1411291950001, 15.388664305000075],
              [109.14161513400008, 15.387569713000103],
              [109.141253562, 15.385797152000078],
              [109.14050262600004, 15.383548906000042],
              [109.14042459899999, 15.382184316000023],
              [109.14090472800009, 15.380066001000079],
              [109.14061385800001, 15.378361306000075],
              [109.13983592500004, 15.377546459000067],
            ],
          ],
          [
            [
              [108.80770589700008, 15.414349759000066],
              [108.81054968600009, 15.413711156000074],
              [108.81288102200007, 15.414330164000132],
              [108.81755698000008, 15.418832880000098],
              [108.82079662300006, 15.420076182000029],
              [108.82467653100011, 15.419684549000044],
              [108.82686776800004, 15.417666968000086],
              [108.82686209700002, 15.41628572500011],
              [108.82568995900003, 15.41440669700007],
              [108.8230952160001, 15.412658703000124],
              [108.82205199900002, 15.410653598000044],
              [108.82178350900001, 15.408268790000077],
              [108.823030798, 15.404810827000047],
              [108.82521648700002, 15.401474805000069],
              [108.82721656100004, 15.40021137300007],
              [108.82909240900008, 15.400204113000092],
              [108.83051495400011, 15.400073034000082],
              [108.83122259300004, 15.399128511000091],
              [108.83154135000002, 15.397997135000095],
              [108.83185206500006, 15.394919468000088],
              [108.83139228400003, 15.39322605900013],
              [108.82878407100006, 15.388150590000025],
              [108.82646987300008, 15.383764605000037],
              [108.82652398900002, 15.381190210000016],
              [108.82844574600004, 15.376662264000094],
              [108.83377571800007, 15.367412187000083],
              [108.84075223000008, 15.357653253000068],
              [108.84377703000007, 15.354125402000117],
              [108.84957770200005, 15.349456372000068],
              [108.85345193800006, 15.348059687000058],
              [108.85597318200003, 15.347861261000071],
              [108.85785201800006, 15.348669935000101],
              [108.85954061300006, 15.350358346000091],
              [108.86187727500004, 15.35235805900008],
              [108.86394991700011, 15.353103117000087],
              [108.86614813500009, 15.352968658000064],
              [108.86844227200002, 15.3525826520001],
              [108.86934438800006, 15.351825579000076],
              [108.86940472200007, 15.350820772000072],
              [108.86881593000003, 15.349253546000073],
              [108.86673763700003, 15.347190082000049],
              [108.86485635300006, 15.345816430000079],
              [108.86355387000003, 15.343687009000105],
              [108.863284171, 15.341113930000104],
              [108.86333807500003, 15.338602326000037],
              [108.86378156900004, 15.336465854000012],
              [108.86506487600008, 15.334137636000021],
              [108.86774163600005, 15.332557179000082],
              [108.87168035500007, 15.331222692000066],
              [108.87575053100004, 15.330389870000081],
              [108.87827290100002, 15.330505091000111],
              [108.88105336600003, 15.330493657000053],
              [108.88202054700004, 15.329861825000089],
              [108.88304578000005, 15.327722924000041],
              [108.88297478900003, 15.326279174000133],
              [108.88232432700003, 15.325402870000103],
              [108.8808330260001, 15.324467248000049],
              [108.87985818900009, 15.323341139000133],
              [108.879396523, 15.321271141000102],
              [108.87906419400012, 15.319200619000116],
              [108.87776199700002, 15.317134080000104],
              [108.874319058, 15.313443893000043],
              [108.87161898300005, 15.309562275000042],
              [108.87025254100006, 15.307558735],
              [108.86868729800004, 15.304425878000048],
              [108.86803047700005, 15.302042740000042],
              [108.86866456200002, 15.299152089000055],
              [108.86966861100007, 15.297699685000053],
              [108.87001384300002, 15.297200285000121],
              [108.87335231400003, 15.291787231000063],
              [108.87623997700004, 15.286815458000101],
              [108.88886547500009, 15.269120993000039],
              [108.89253007300007, 15.264585272000096],
              [108.89703313000007, 15.25966926600004],
              [108.90472568100009, 15.252542256000099],
              [108.90942717900006, 15.248755273000112],
              [108.91484575700005, 15.246346370000106],
              [108.91807464800004, 15.245704691000038],
              [108.91917315300007, 15.245637183000047],
              [108.92344594300002, 15.247125591],
              [108.92641916300009, 15.247112730000099],
              [108.93391568700005, 15.24682898900007],
              [108.93687956300005, 15.244806934000097],
              [108.93867470500008, 15.241659863000033],
              [108.93991016399998, 15.236317803000075],
              [108.94034823800008, 15.233239472000013],
              [108.94001219799999, 15.230478457000094],
              [108.93835098700004, 15.227660491000082],
              [108.93698677100005, 15.22615965900007],
              [108.93633493900002, 15.224969628000016],
              [108.93554862200007, 15.22265006900003],
              [108.93508312900011, 15.219826837000115],
              [108.933615672, 15.216945194000074],
              [108.93199277100011, 15.215382682000021],
              [108.92894266400008, 15.212633472000013],
              [108.92751659500003, 15.211697909000053],
              [108.92589927200007, 15.211328221000107],
              [108.92454332200005, 15.211585218000055],
              [108.92142141500003, 15.214298391000101],
              [108.92051953700009, 15.214930113000056],
              [108.91909921700008, 15.215250142000068],
              [108.91670638400007, 15.214883708000036],
              [108.91446601300007, 15.213974470000034],
              [108.91462870100003, 15.212757968000099],
              [108.915136, 15.210621150000046],
              [108.91667869, 15.208793813000019],
              [108.91983576100009, 15.206708396000044],
              [108.9206709740001, 15.205637478000076],
              [108.92066638700007, 15.204632964],
              [108.9196275720001, 15.203570113000085],
              [108.9172305820001, 15.202261960000028],
              [108.91598436100001, 15.201765028000034],
              [108.91524151000002, 15.201830999000075],
              [108.91488694800007, 15.202020865000064],
              [108.91447004200005, 15.202713264000066],
              [108.91415762000004, 15.205068988000024],
              [108.91341873000002, 15.206013900000046],
              [108.91183918500006, 15.206836827000101],
              [108.90970778100009, 15.207097045000049],
              [108.90841462300004, 15.20694558100004],
              [108.90566422600003, 15.206078285000045],
              [108.90278320200012, 15.204897580000052],
              [108.90090384300005, 15.203712617000063],
              [108.89941082500006, 15.202212105000077],
              [108.8977228570001, 15.200461254000073],
              [108.8966159340001, 15.198582395000047],
              [108.89550568500007, 15.195950142000035],
              [108.89406542000009, 15.191749680000031],
              [108.8932034100001, 15.186793402000108],
              [108.89286393100008, 15.183090601000069],
              [108.89290019400005, 15.176686553000083],
              [108.89364103700007, 15.168898337000057],
              [108.89490036100008, 15.161484652000111],
              [108.89639407800004, 15.155953486000049],
              [108.89676328900003, 15.151808254000086],
              [108.89649844800006, 15.150365358000071],
              [108.89526873500009, 15.149868251000063],
              [108.89555013600003, 15.146156952000032],
              [108.89580415400007, 15.142806689000031],
              [108.8961373380001, 15.14176567500005],
              [108.896061855, 15.139317456000077],
              [108.89525571000009, 15.132351938000086],
              [108.89567890300002, 15.125820758000062],
              [108.8982422230001, 15.113944097000063],
              [108.89938137700003, 15.108665571000033],
              [108.90073503500003, 15.100812054000111],
              [108.90040649400002, 15.099557791000072],
              [108.89975612700003, 15.098556007000051],
              [108.89878283600007, 15.097555589000054],
              [108.89839140300009, 15.096678278000029],
              [108.89844410700002, 15.09580688400005],
              [108.89896652600007, 15.095294646000033],
              [108.90109480000004, 15.094595070000059],
              [108.90141352600003, 15.093651991000099],
              [108.90132172500007, 15.087562495000027],
              [108.90142985100005, 15.082853364000036],
              [108.90192404900009, 15.077828697000049],
              [108.90560070500001, 15.062433514000071],
              [108.90965712900008, 15.04546377100011],
              [108.92000834200007, 15.007307903000109],
              [108.92855343300002, 14.984291138000037],
              [108.94097431200001, 14.9485115850001],
              [108.95292095300003, 14.920710061000085],
              [108.953022672, 14.920473346000039],
              [108.96350473700008, 14.895608693000016],
              [108.97780212000001, 14.863397962000045],
              [108.98304689300008, 14.854081790000071],
              [108.99603731400003, 14.832737766000024],
              [108.99808215600004, 14.8288356100001],
              [108.99921522100007, 14.823179767000026],
              [109.00035506500006, 14.818905140000103],
              [109.00303716500011, 14.813493160000045],
              [109.01084790400006, 14.801904142000042],
              [109.03367545100011, 14.76343434000009],
              [109.04660420700002, 14.744787671000024],
              [109.05882437800003, 14.726708869000047],
              [109.065796151, 14.71618955200009],
              [109.07194618100002, 14.708876046000084],
              [109.07476584700008, 14.705722769000127],
              [109.07578119300007, 14.70270413000012],
              [109.07589607600006, 14.700066724000036],
              [109.07446860500008, 14.698316040000046],
              [109.07316942900006, 14.69643913],
              [109.07327567300005, 14.692169461000047],
              [109.07696870100008, 14.683612582000103],
              [109.08067888300012, 14.678320193000081],
              [109.08168190500007, 14.673041497000037],
              [109.08089371600003, 14.670283129000071],
              [109.07881521300001, 14.667280168000028],
              [109.07558671900006, 14.666166426000069],
              [109.07326921200006, 14.666680342000047],
              [109.07173307500005, 14.668697064000112],
              [109.07098828900007, 14.67409997100013],
              [109.07117775600003, 14.685525183000079],
              [109.06952915600004, 14.690681524000036],
              [109.06709039300006, 14.692702752000029],
              [109.06464165900005, 14.692840553000062],
              [109.06373396400008, 14.691840577000013],
              [109.06372074500004, 14.68932938900007],
              [109.06610253300003, 14.676510132000063],
              [109.06645678400012, 14.670355818000045],
              [109.06488217800005, 14.665090092000097],
              [109.06304128700005, 14.658193395000028],
              [109.06300958800009, 14.652166610000082],
              [109.06467716900005, 14.638409372000092],
              [109.06833972500004, 14.624202743000046],
              [109.06931889000006, 14.614404163000037],
              [109.07016710600007, 14.604229599000094],
              [109.07154196500005, 14.596186917000063],
              [109.07645638100007, 14.587435837000038],
              [109.07875689300008, 14.583908570000046],
              [109.0800346140001, 14.581893170000091],
              [109.07989437700004, 14.579759393000112],
              [109.07962959300008, 14.579504036000033],
              [109.07935270700008, 14.579778997000046],
              [109.07669115600004, 14.581409555000045],
              [109.07352327200003, 14.582831710000031],
              [109.071017528, 14.584512883000043],
              [109.06793398300005, 14.588254723000114],
              [109.06527593300002, 14.590588215000098],
              [109.06232766400007, 14.592641883000022],
              [109.05888422200009, 14.594687489000078],
              [109.05664831100009, 14.596592737000067],
              [109.05398556100005, 14.5999498100001],
              [109.05290907500006, 14.600917068000017],
              [109.05060762400007, 14.601432293000103],
              [109.04903681100004, 14.602470171000091],
              [109.04681214000009, 14.604941933000058],
              [109.04423197200003, 14.608470048000031],
              [109.04280040700006, 14.610797263000029],
              [109.04245039700005, 14.612908260000077],
              [109.04311560700003, 14.615928303000112],
              [109.04443445300008, 14.619718561000104],
              [109.04452079600003, 14.622460210000019],
              [109.04404639400003, 14.628438862000069],
              [109.04342789200011, 14.631921027000059],
              [109.04038591300004, 14.637147626000056],
              [109.03635020600008, 14.643195470000057],
              [109.03400866700005, 14.646962364000069],
              [109.03157895500001, 14.648890222000064],
              [109.02882973400008, 14.649899869000114],
              [109.02553740500008, 14.65244496200002],
              [109.02389489899998, 14.654445596000086],
              [109.01951884000002, 14.660598053000038],
              [109.01553063400007, 14.665675585000047],
              [109.01043426400003, 14.669531986],
              [109.007137556, 14.671310395000068],
              [109.0056433550001, 14.671547384000048],
              [109.00343548800004, 14.670714693000074],
              [109.00091396600004, 14.67011339900006],
              [108.99823846500009, 14.670202580000067],
              [108.99525608500005, 14.671902684000047],
              [108.99345721400007, 14.67421040100003],
              [108.99172949700004, 14.674984877000073],
              [108.98912999900007, 14.67453709400006],
              [108.98567938500003, 14.673472731000102],
              [108.97967167400006, 14.672600992000094],
              [108.97653190400007, 14.672705338000112],
              [108.97367484800002, 14.673977598000041],
              [108.96852684900003, 14.679037808000126],
              [108.96562440800005, 14.681542855000094],
              [108.96098397200007, 14.685547798000085],
              [108.95831051600001, 14.686639137000055],
              [108.956646656, 14.686376780000019],
              [108.95413750600005, 14.683150086000028],
              [108.94643237800007, 14.688640680000018],
              [108.94182789000003, 14.691629167000107],
              [108.93833379200004, 14.695062402000046],
              [108.9356659100001, 14.697412639000083],
              [108.93216040500003, 14.69841735700008],
              [108.92680579100002, 14.699070295000103],
              [108.92329527200008, 14.698995576000049],
              [108.91959470000005, 14.697752369000037],
              [108.91843018300005, 14.697323457000076],
              [108.91404311400007, 14.695707548000046],
              [108.90766153099999, 14.693935945000101],
              [108.90590249900002, 14.693043996000108],
              [108.90478843900003, 14.691789524000052],
              [108.90431774500007, 14.689812784000098],
              [108.9032960750001, 14.688557923000099],
              [108.90153831200006, 14.687935761000046],
              [108.89873365600008, 14.687957385000059],
              [108.893558775, 14.687439467000017],
              [108.88865942500009, 14.686560522000034],
              [108.88477653100007, 14.685767177000029],
              [108.88034379400003, 14.685965412000089],
              [108.87610084000006, 14.687332019000097],
              [108.87149573800006, 14.690408939000042],
              [108.86826984200005, 14.692041060000081],
              [108.86346939700006, 14.692690150000052],
              [108.85681849800008, 14.69262702900007],
              [108.85358523000008, 14.692550049000095],
              [108.85191994900003, 14.691927117000068],
              [108.85033909500007, 14.689415028000051],
              [108.84967777700007, 14.685909906000026],
              [108.84939614700004, 14.684831722000078],
              [108.84782368100005, 14.684298326000087],
              [108.84551494600007, 14.684397437000117],
              [108.84358242900004, 14.686113995000126],
              [108.84130174500007, 14.692958632000062],
              [108.83918549000003, 14.694945706000071],
              [108.83632167200005, 14.694867019000045],
              [108.83381842100005, 14.692628248000082],
              [108.83176928700006, 14.6884989000001],
              [108.82875404700006, 14.682716507000036],
              [108.8260600620001, 14.678949385000093],
              [108.82346696300004, 14.677250514000038],
              [108.81985939600006, 14.67591528500005],
              [108.814955205, 14.673685534000034],
              [108.81143940099999, 14.672169925000043],
              [108.80737129000001, 14.671106065000037],
              [108.80099570700007, 14.670410569000046],
              [108.79674474200006, 14.669796900000074],
              [108.78963493700003, 14.670273060000037],
              [108.78363200600002, 14.670385139000073],
              [108.78086265000003, 14.670755068000039],
              [108.77727208500005, 14.673736271000058],
              [108.77165358800004, 14.677804065000098],
              [108.76888376600002, 14.67808389500007],
              [108.76703584900005, 14.677910669000125],
              [108.76453289400008, 14.675491219000046],
              [108.76137807000001, 14.671635001000103],
              [108.75942737600008, 14.668673883000059],
              [108.75913983200009, 14.665886708000107],
              [108.75968382300006, 14.66318652700007],
              [108.76159786200007, 14.656434083000123],
              [108.76351346400003, 14.650131342000082],
              [108.76441076100011, 14.64320266000003],
              [108.76512025500001, 14.635465190000028],
              [108.76528313799999, 14.629708400000128],
              [108.76480885800001, 14.626382305000083],
              [108.76378328700005, 14.62377770700005],
              [108.76155737000001, 14.621177398000016],
              [108.76006884100003, 14.618214677000106],
              [108.75969071200009, 14.615877578],
              [108.76004246400007, 14.611199445000087],
              [108.76103643400006, 14.605439732000034],
              [108.76176422500006, 14.602559058000073],
              [108.76138847600006, 14.600851546000102],
              [108.75981148700006, 14.598878500000099],
              [108.75712562100006, 14.596639567000034],
              [108.75398156000006, 14.595301620000088],
              [108.74852844800006, 14.593701921000067],
              [108.74372532200002, 14.593089155000081],
              [108.74030607700008, 14.592201661000013],
              [108.73660596500008, 14.590235813000072],
              [108.7342959640001, 14.589704148000047],
              [108.73244630600009, 14.590176457000046],
              [108.72580565800007, 14.595149487000112],
              [108.71907281300005, 14.598927719000086],
              [108.71268927700007, 14.602448514000075],
              [108.70893076000004, 14.605021566000074],
              [108.70639879800007, 14.607590485000086],
              [108.70535423300007, 14.609557011000083],
              [108.7050970280001, 14.611179516000037],
              [108.70589395300004, 14.613566699000023],
              [108.70546031400005, 14.614848403000023],
              [108.70379788700008, 14.615536701000041],
              [108.70204396900009, 14.615115733000026],
              [108.70080986600003, 14.612986024000028],
              [108.70009352400008, 14.608550136000085],
              [108.69902764300009, 14.604371460000019],
              [108.69708505200003, 14.600024955000011],
              [108.69514783600006, 14.597214716000016],
              [108.69110854700007, 14.594581993000054],
              [108.68707227200007, 14.59280269],
              [108.68558344200007, 14.592978180000047],
              [108.68357586500005, 14.595203734000071],
              [108.68112931800003, 14.597174624000074],
              [108.67981625100002, 14.597520210000068],
              [108.67841356600005, 14.597268628000036],
              [108.67709392800001, 14.595651169000055],
              [108.67489419300009, 14.592841593000026],
              [108.67427436000004, 14.590880499000034],
              [108.6750543110001, 14.588317534000071],
              [108.67531030300005, 14.586268327000072],
              [108.67407665800003, 14.584138484000063],
              [108.67065285300006, 14.582015538000091],
              [108.66556629000011, 14.580409835000106],
              [108.657649174, 14.576811372000085],
              [108.65173428800007, 14.572974821000082],
              [108.6454967440001, 14.569581431000065],
              [108.64198889600002, 14.567885842000104],
              [108.63939111900001, 14.566819388000061],
              [108.63848149700007, 14.566316577000025],
              [108.63815281900006, 14.564927339000098],
              [108.63904985200003, 14.561449105000078],
              [108.64052635000007, 14.55664209000007],
              [108.64071391100003, 14.55442982400008],
              [108.64083621500004, 14.55209136500004],
              [108.64031098000001, 14.550070812000103],
              [108.63940063100009, 14.549315248000115],
              [108.63816841100001, 14.549318941000065],
              [108.63557805300009, 14.550527319000127],
              [108.62987800700009, 14.552819135000037],
              [108.62832208600005, 14.553013304000027],
              [108.62676263600008, 14.55207002100005],
              [108.62610802600008, 14.550113010000041],
              [108.62615821100007, 14.545373495000112],
              [108.62158278700004, 14.543311625000042],
              [108.61851738400007, 14.541675286],
              [108.61544827400006, 14.538804971000017],
              [108.61248167000009, 14.534906043000094],
              [108.61110735000007, 14.534087366000035],
              [108.608680581, 14.534197139000058],
              [108.60509611000008, 14.535338451000078],
              [108.60330646700007, 14.536783123000061],
              [108.59826036099999, 14.543275533000076],
              [108.59521239900005, 14.547500047000115],
              [108.59437539900006, 14.549970269000044],
              [108.59480630300001, 14.552951107000032],
              [108.59576884600007, 14.557267231000102],
              [108.59588589500008, 14.561174409000087],
              [108.59441711400005, 14.564160562000071],
              [108.58999249500006, 14.566949243000058],
              [108.58292827400008, 14.569436583000071],
              [108.57712924900005, 14.571508962000033],
              [108.57280832700003, 14.573782866000061],
              [108.57039092300003, 14.577388388000132],
              [108.56733869, 14.580481421000078],
              [108.56451644200008, 14.582304118000076],
              [108.56083215800007, 14.585912893000062],
              [108.55809120300003, 14.587154050000082],
              [108.55312965400003, 14.586961323000123],
              [108.54932864200003, 14.586559834000033],
              [108.54732536600001, 14.587387624000046],
              [108.54553707000011, 14.589654468000022],
              [108.54185341500005, 14.593674240000086],
              [108.537855135, 14.598517396000064],
              [108.53447520400002, 14.602980257000107],
              [108.53259561200011, 14.605523082000092],
              [108.52976401800004, 14.612625456000037],
              [108.5280020660001, 14.618338798000073],
              [108.526113858, 14.622970866000083],
              [108.52400615600006, 14.62441569700008],
              [108.52084110300009, 14.625246109000043],
              [108.51440037600001, 14.624953244000059],
              [108.51064797900004, 14.62367645300011],
              [108.50807684500002, 14.622904046000061],
              [108.50596456300005, 14.622964684000104],
              [108.50442459200008, 14.623580048000038],
              [108.50219921400007, 14.624085797000051],
              [108.49945807000002, 14.623814181000048],
              [108.49534384200004, 14.622377900000048],
              [108.49043349100002, 14.622222427000063],
              [108.48746602200006, 14.622785333000097],
              [108.48364441700005, 14.624295521000084],
              [108.48022505300003, 14.626916957000089],
              [108.47920276700009, 14.629199286000103],
              [108.47886718500003, 14.63214741100005],
              [108.47938816600006, 14.635149220000086],
              [108.48147472100004, 14.639913020000071],
              [108.48242460600002, 14.642737293000073],
              [108.48329977300006, 14.644643144000023],
              [108.48613554400004, 14.647251137000071],
              [108.49013617500002, 14.651481621000071],
              [108.49355843600003, 14.656490636000095],
              [108.49741447100004, 14.660791984000097],
              [108.49916525100004, 14.664603591000079],
              [108.50105894200004, 14.667496247000054],
              [108.50273553300003, 14.670601395000025],
              [108.50579420200008, 14.675045815000052],
              [108.5080519700001, 14.678361528000037],
              [108.5102310450001, 14.683466916000041],
              [108.51110808000001, 14.685937974000039],
              [108.51096919800005, 14.688411457000059],
              [108.50937771900001, 14.690393788000032],
              [108.50742106800003, 14.691458373],
              [108.5048081030001, 14.691252595000035],
              [108.50248734500011, 14.69182336700001],
              [108.49988154800006, 14.694514634000109],
              [108.49691335000006, 14.697418695000051],
              [108.49503155500005, 14.699472263000056],
              [108.49503640200001, 14.701450782000068],
              [108.49620084, 14.702790651000084],
              [108.49932648600006, 14.7048325520001],
              [108.502962677, 14.707862476000065],
              [108.507252142, 14.710890780000073],
              [108.51001729299999, 14.713781335000052],
              [108.51153228100003, 14.716839230000021],
              [108.51342915200003, 14.720721093000043],
              [108.51584064600007, 14.727145545000029],
              [108.51905543000005, 14.735405262000077],
              [108.5206617370001, 14.73907579900011],
              [108.52204851699999, 14.742040247000075],
              [108.52343204300007, 14.743732759000048],
              [108.5257586710001, 14.745210958000053],
              [108.52685115499999, 14.746621520000057],
              [108.52714778100002, 14.74902332],
              [108.52614515700002, 14.754254851000058],
              [108.52477218300001, 14.756660781000084],
              [108.52202215800003, 14.758331511000032],
              [108.52028541200004, 14.760455648000102],
              [108.51913410200008, 14.7644155890001],
              [108.51733754000003, 14.771556941000073],
              [108.51596290000006, 14.773397524000041],
              [108.51342613100005, 14.775028897000107],
              [108.50900212200003, 14.776806080000069],
              [108.5054478120001, 14.778015816000066],
              [108.5022575040001, 14.779648605000071],
              [108.50030414100004, 14.782409073000034],
              [108.49851099600008, 14.787042601000076],
              [108.49663446300008, 14.791569460000085],
              [108.49425047600009, 14.796450790000025],
              [108.49193915000006, 14.801402607000023],
              [108.48340922000006, 14.797948858000074],
              [108.47627208600007, 14.795824140000045],
              [108.46973560100005, 14.793957339000128],
              [108.46219913800007, 14.791962770000032],
              [108.45833090400002, 14.790933167000018],
              [108.45586563800006, 14.791327680000064],
              [108.45433641800001, 14.79301760600009],
              [108.45254927700002, 14.79860039000007],
              [108.45135629900008, 14.801651908000045],
              [108.44989275300004, 14.802952435000092],
              [108.44722821500002, 14.80373650200004],
              [108.44269026300007, 14.804117013000083],
              [108.44053256100007, 14.804302535000067],
              [108.43863618800002, 14.804885838000102],
              [108.43696391000003, 14.805867005000016],
              [108.43433841000004, 14.808128800000107],
              [108.43362531400001, 14.8099557310001],
              [108.43142437000007, 14.812429958000093],
              [108.42988503600007, 14.81479544300006],
              [108.42911808900003, 14.817266756000079],
              [108.42588498600004, 14.818410424000115],
              [108.42267583700009, 14.818837331000088],
              [108.41903292300003, 14.81848406900008],
              [108.41495791100002, 14.818131587000048],
              [108.41162441600007, 14.818138043000049],
              [108.40853943100007, 14.818924934000053],
              [108.4050864390001, 14.820913970000047],
              [108.40161908300009, 14.823515845000122],
              [108.39873454400008, 14.824230029000061],
              [108.39540460400006, 14.826098529000118],
              [108.39488994800003, 14.826673086000051],
              [108.39195497400001, 14.829949664000084],
              [108.38990303100006, 14.832541077000092],
              [108.38599633100003, 14.836033525000046],
              [108.38262002300009, 14.839460411000081],
              [108.38056998300002, 14.842755799000072],
              [108.37772292000008, 14.845536227000087],
              [108.37586873500005, 14.84721763600003],
              [108.37481100000011, 14.849155805000079],
              [108.373360491, 14.85393458700006],
              [108.37185744900007, 14.856895680000013],
              [108.37060648300005, 14.859831647000108],
              [108.36835856500004, 14.867267803000049],
              [108.36671198400003, 14.873822787000089],
              [108.36524227800003, 14.878329578000125],
              [108.36441191800007, 14.880378887000044],
              [108.36282931900004, 14.882430588000071],
              [108.3619401170001, 14.883050780000049],
              [108.36009612300003, 14.883177635000065],
              [108.35793305600002, 14.882686328000093],
              [108.35494111100009, 14.880959024000083],
              [108.35328574500008, 14.879848152000095],
              [108.35125099800004, 14.879975246000042],
              [108.34940916700006, 14.88133936000011],
              [108.348521851, 14.883073110000032],
              [108.34904321600007, 14.890372667000038],
              [108.34956002200002, 14.895011920000021],
              [108.34899139100004, 14.897178226000028],
              [108.34784875200009, 14.898417451000066],
              [108.345370105, 14.899411370000058],
              [108.34352732000011, 14.900342361000083],
              [108.34213138300007, 14.902262517000063],
              [108.34028457200003, 14.904492309000082],
              [108.33827751600006, 14.906714249000073],
              [108.33686894900003, 14.907913431000084],
              [108.33542964100006, 14.908674746000104],
              [108.33383981500006, 14.909173538000074],
              [108.33168075600003, 14.910286284000039],
              [108.33030147200003, 14.911076660000065],
              [108.32901141000005, 14.911370599000049],
              [108.32773131, 14.911236368000045],
              [108.32561528700008, 14.911208422000042],
              [108.32375614700008, 14.911429554000042],
              [108.32170566600007, 14.912278606000127],
              [108.32128721900008, 14.914631687000044],
              [108.32041882000009, 14.91996478500006],
              [108.32042355900003, 14.922950579000103],
              [108.32070470000011, 14.927428877000091],
              [108.32026877500002, 14.929029079000065],
              [108.31895557300007, 14.930417312000086],
              [108.31720337700006, 14.931486289000071],
              [108.31556328400006, 14.93415462600005],
              [108.31305312600003, 14.941302954000067],
              [108.31262157800002, 14.945782326000055],
              [108.31090660900006, 14.947221788000052],
              [108.3076910130001, 14.949339549000038],
              [108.30198410400006, 14.953730424000076],
              [108.29836778900007, 14.957100812000055],
              [108.29249826700008, 14.960630776000093],
              [108.28831575600006, 14.962201729000045],
              [108.28694965900009, 14.96361230600005],
              [108.28667024300006, 14.966826172000046],
              [108.28502599600009, 14.967041646000022],
              [108.283931276, 14.96821611100003],
              [108.2832774930001, 14.97109618200003],
              [108.28196570000009, 14.973870481000072],
              [108.28010461900003, 14.97589904200005],
              [108.27627052600005, 14.978356697],
              [108.27079324200001, 14.981989384000103],
              [108.26674089000009, 14.985406885000073],
              [108.26356712800003, 14.989996241000101],
              [108.26263857600003, 14.992663318000064],
              [108.26231530800003, 14.997035864000095],
              [108.26111299200002, 15.000023200000088],
              [108.25574734400007, 15.005894772000071],
              [108.25213489900004, 15.010911063000075],
              [108.25093221600009, 15.013791708000033],
              [108.2507165960001, 15.016777826000103],
              [108.25127063100003, 15.02136262200011],
              [108.25061581300011, 15.023922718000014],
              [108.24853527700002, 15.026484468000055],
              [108.24667233200006, 15.027659649000034],
              [108.24480820900001, 15.027875070000094],
              [108.23671048100005, 15.032735223000058],
              [108.23974773200008, 15.036785399000062],
              [108.2408900840001, 15.041114062000103],
              [108.23994624200007, 15.044339562000085],
              [108.23720140800008, 15.047290677000101],
              [108.23625737100012, 15.050424035],
              [108.23778089200006, 15.056502724000074],
              [108.23968470000001, 15.063502249000036],
              [108.23950204100001, 15.069306466000041],
              [108.23676021299998, 15.075113532000016],
              [108.23363726000005, 15.079354839000112],
              [108.24691387500006, 15.084775426000084],
              [108.25118232200001, 15.087165767000064],
              [108.25393526500005, 15.090386972000134],
              [108.25583739100006, 15.095359596000078],
              [108.25692417900009, 15.099771523000081],
              [108.25726668700001, 15.101161929000016],
              [108.25803070800008, 15.105583142000077],
              [108.25623809800008, 15.112310635000091],
              [108.25558056200003, 15.117102074000053],
              [108.25652129500006, 15.119509149000086],
              [108.25691215000008, 15.120509217000054],
              [108.260234709, 15.123913938000031],
              [108.26649631800011, 15.127222843000075],
              [108.27105130600005, 15.130349475000047],
              [108.27371022200003, 15.133478449000036],
              [108.275139548, 15.138727933000061],
              [108.27770826699999, 15.145081470000042],
              [108.28198505500009, 15.152261905000051],
              [108.28645178800011, 15.159442001000032],
              [108.29272214400004, 15.16772509900006],
              [108.29794444000004, 15.172324327000107],
              [108.29974988900003, 15.1748092910001],
              [108.30003904300006, 15.177941272000071],
              [108.29888375700004, 15.182119431],
              [108.29836610300002, 15.184959177000044],
              [108.30014570500001, 15.188505466000011],
              [108.30077603300002, 15.191242202000053],
              [108.30036170700002, 15.193372055000063],
              [108.29785907899999, 15.194997858000113],
              [108.29274608800007, 15.196018860000036],
              [108.29034671900006, 15.19693467700005],
              [108.28972307200006, 15.198760613000049],
              [108.29024862100002, 15.201294748000025],
              [108.29164596400008, 15.202841636000118],
              [108.29244413300005, 15.203725211000039],
              [108.29975896800008, 15.209190358000056],
              [108.30436106000009, 15.215267495000083],
              [108.30614457700004, 15.22114581300009],
              [108.30562847100006, 15.224999535000093],
              [108.30270958800004, 15.227741335000109],
              [108.29843109200007, 15.228862688000076],
              [108.29195952100009, 15.229479990000081],
              [108.28726103600009, 15.22897938200005],
              [108.28475585500011, 15.229185522000053],
              [108.28381888000004, 15.231011867000086],
              [108.28299125400004, 15.236386860000016],
              [108.28069972000006, 15.240141478000078],
              [108.2794517020001, 15.243590528000082],
              [108.28039427600001, 15.245718581000032],
              [108.28520141800003, 15.248956853],
              [108.29042676700001, 15.252498634000043],
              [108.29314488300002, 15.254928390000087],
              [108.29957111000007, 15.258176499000074],
              [108.30166197100004, 15.260779320000049],
              [108.30270959600004, 15.263528355000043],
              [108.30353843200005, 15.269462513000052],
              [108.30332080699999, 15.273443850000133],
              [108.30168591700007, 15.276775745000036],
              [108.29893079600002, 15.278733932000053],
              [108.29826185100009, 15.280037745000106],
              [108.30020404900003, 15.28278556],
              [108.30453520500002, 15.287846185000118],
              [108.307073605, 15.291321136000045],
              [108.30976438800005, 15.295004532000076],
              [108.31327454800005, 15.299125216000052],
              [108.316930197, 15.300857014000078],
              [108.321776873, 15.301428841000051],
              [108.32691978300001, 15.300769591000039],
              [108.33280803600005, 15.300036691000061],
              [108.33877152600007, 15.299737801000022],
              [108.34369092600005, 15.299223275000083],
              [108.35315679300007, 15.298122113000099],
              [108.35889396900004, 15.29637537900008],
              [108.36031426000005, 15.296216254000059],
              [108.36217344800005, 15.296007946000055],
              [108.36426236900004, 15.29687298100013],
              [108.37165241300008, 15.302144197000088],
              [108.37635287600006, 15.304162681000031],
              [108.37963408700003, 15.304663560000085],
              [108.38321172400002, 15.304222889000039],
              [108.38633982400002, 15.302624849000066],
              [108.38879649800008, 15.300810827000076],
              [108.39132922500002, 15.299792861000048],
              [108.39446073400003, 15.300004249000041],
              [108.39930890000008, 15.301225759000124],
              [108.40110069400001, 15.302525291000089],
              [108.40147806600005, 15.304840824000097],
              [108.40148848200003, 15.309205104000066],
              [108.40472500400003, 15.311371509000081],
              [108.40932146000003, 15.309793733],
              [108.4137932940001, 15.308095402000086],
              [108.41665447700005, 15.309055410000026],
              [108.42225946000009, 15.31423428200007],
              [108.42550035600001, 15.318331474000056],
              [108.42676016200006, 15.326174112000055],
              [108.43165924600005, 15.326798256000094],
              [108.43235629800009, 15.326887050000069],
              [108.434094241, 15.325676577000101],
              [108.43469814500006, 15.317468115000031],
              [108.43568695200007, 15.314810820000078],
              [108.43829314700005, 15.312753689000134],
              [108.44264101000009, 15.311296405000084],
              [108.44848246100005, 15.310680761000038],
              [108.45382851900008, 15.31091087300007],
              [108.46079039800004, 15.311016678000032],
              [108.47023886300003, 15.311237512],
              [108.47608079800005, 15.310862502000065],
              [108.48055439600009, 15.310128332000071],
              [108.48403547300009, 15.310241172000081],
              [108.48802024600009, 15.313008100000046],
              [108.49250564900005, 15.317101436000067],
              [108.49699717900006, 15.323608556000055],
              [108.50160341700007, 15.326132402000068],
              [108.50545896200005, 15.326726794000043],
              [108.51154839400002, 15.325746762000046],
              [108.51639268400001, 15.324045399000051],
              [108.52297551500004, 15.321615507000084],
              [108.525336666, 15.321247633000022],
              [108.52770286700003, 15.322810842000044],
              [108.531074195, 15.32835446600007],
              [108.53630516600001, 15.331841573000114],
              [108.54215504700005, 15.334240721000112],
              [108.54800211100005, 15.335553468000017],
              [108.55421825500012, 15.335416764000074],
              [108.55881844000007, 15.335404796000061],
              [108.56392439300004, 15.33840877100001],
              [108.57040295300006, 15.343098707000101],
              [108.57637755600004, 15.345375900000045],
              [108.58024008300006, 15.34570623100011],
              [108.5818497110001, 15.34584386700004],
              [108.58731803100002, 15.344984055000042],
              [108.59316178300001, 15.344967932000129],
              [108.59788939300003, 15.345920340000061],
              [108.60050476700006, 15.347361354],
              [108.60250390200009, 15.350614503000058],
              [108.60512226200011, 15.353021023000045],
              [108.61295973900003, 15.354326361000046],
              [108.61669513600002, 15.356005358000056],
              [108.6254114490001, 15.359963009000042],
              [108.63375543800001, 15.36404210100004],
              [108.64197080000012, 15.366672914000022],
              [108.65018676700001, 15.369424101000016],
              [108.6566553480001, 15.370128518000035],
              [108.66038428000007, 15.369634259000085],
              [108.66435513300009, 15.367087359000029],
              [108.66919952600009, 15.365503217000052],
              [108.67305552000001, 15.365853203000055],
              [108.68002471500003, 15.367520910000019],
              [108.68457546600007, 15.368306041000055],
              [108.69715986500009, 15.371398897000075],
              [108.70251422100009, 15.371958605000112],
              [108.70718633000004, 15.371778291000025],
              [108.71228496800011, 15.372091221000071],
              [108.71713579600001, 15.374383903000053],
              [108.72100615000005, 15.378979207000041],
              [108.73450233800003, 15.39499188200004],
              [108.73530339300005, 15.397145755000084],
              [108.73682875600005, 15.40067558300011],
              [108.73952968900005, 15.398648817000014],
              [108.745724018, 15.394483511000077],
              [108.75670262500005, 15.389798893000071],
              [108.76122650800008, 15.388778283000091],
              [108.76672105100003, 15.387879692000082],
              [108.76904380100011, 15.386364516000038],
              [108.76954876300006, 15.383097886000117],
              [108.7703028280001, 15.377318977000074],
              [108.77209549700009, 15.37254086600006],
              [108.77259748500006, 15.368520825000063],
              [108.77206758100002, 15.365257939000097],
              [108.77010577800004, 15.359614386000063],
              [108.77059960800005, 15.353459704000015],
              [108.76921182500007, 15.345679412000022],
              [108.76971620300006, 15.342287227000016],
              [108.77048787000004, 15.341154329000071],
              [108.77203551800002, 15.340018629000072],
              [108.77410576100007, 15.34026228100007],
              [108.77577955500001, 15.340362178000076],
              [108.78005643500003, 15.340617377000052],
              [108.7837789320001, 15.341913656000104],
              [108.78367581900009, 15.342149907000111],
              [108.78295627300007, 15.343798507000093],
              [108.78143632100007, 15.346262954000077],
              [108.77950602300005, 15.348781380000085],
              [108.77900081300008, 15.351922452000098],
              [108.78033748700001, 15.363093252000047],
              [108.78087580500005, 15.368490781000064],
              [108.77934062000004, 15.372891312000101],
              [108.77664602800012, 15.378551742000111],
              [108.7764076440001, 15.383826528],
              [108.77661474700001, 15.387216163000071],
              [108.77830322700001, 15.388968012000069],
              [108.78024560000004, 15.389463215000097],
              [108.78464538400002, 15.389823832000092],
              [108.7909925270001, 15.391935154000079],
              [108.79656559200006, 15.394551449000103],
              [108.80188457400007, 15.39829868],
              [108.80519801400004, 15.401927765000066],
              [108.80546478200009, 15.403935883000129],
              [108.804825958, 15.405947409000118],
              [108.80147375800001, 15.408848107000049],
              [108.79683714400007, 15.414139361000066],
              [108.79452221800003, 15.417663938000102],
              [108.79337263500003, 15.42143530800004],
              [108.79429020000001, 15.424445588000067],
              [108.79662011000005, 15.424688072000057],
              [108.79803986300001, 15.423803787000077],
              [108.80125798100001, 15.419647940000035],
              [108.80422172, 15.41662314000005],
              [108.80770589700008, 15.414349759000066],
            ],
          ],
          [
            [
              [109.08155532200007, 15.426613567000034],
              [109.07853021500006, 15.426355835000026],
              [109.07628334100005, 15.426981395],
              [109.07537869400007, 15.428760445000121],
              [109.07542606700008, 15.43104658],
              [109.07719206600009, 15.432539195000048],
              [109.08134787700004, 15.433814996000093],
              [109.0832109790001, 15.433737322000061],
              [109.08426431300003, 15.433458990000036],
              [109.08521031800007, 15.432839938000063],
              [109.08548588700003, 15.431780672000082],
              [109.08554462200001, 15.429630495000078],
              [109.08419865100011, 15.427794563000051],
              [109.08155532200007, 15.426613567000034],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 48, code: "AD01", ten_tinh: "Quảng Ninh" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.34548744600005, 20.686667200000031],
              [107.34411171400008, 20.686663114000048],
              [107.34273379700002, 20.687308093000034],
              [107.34135149500005, 20.689251228000046],
              [107.34134602400005, 20.690873915000019],
              [107.34220150200012, 20.692174629000078],
              [107.34374652600006, 20.692990584000093],
              [107.34581182200006, 20.692509903000108],
              [107.34753913100008, 20.690243241000061],
              [107.34737257500005, 20.688620034000046],
              [107.34651707900005, 20.687319333000076],
              [107.34548744600005, 20.686667200000031],
            ],
          ],
          [
            [
              [107.15007442600007, 20.734678561000038],
              [107.14921959800004, 20.73452854100006],
              [107.14812988700004, 20.734744417000073],
              [107.14719410400005, 20.735327696000105],
              [107.14680206000003, 20.7360597730001],
              [107.14672107299999, 20.736793079000023],
              [107.14718225100005, 20.738041990000063],
              [107.14826783300008, 20.738779790000095],
              [107.14974418600011, 20.739078924000061],
              [107.15130213400005, 20.738498031000049],
              [107.15208494000007, 20.737327275000084],
              [107.15162500700004, 20.735784935000098],
              [107.15085051700007, 20.735048345000081],
              [107.15007442600007, 20.734678561000038],
            ],
          ],
          [
            [
              [107.45159044200003, 20.71700833800012],
              [107.44870945700004, 20.717001158000059],
              [107.44667645700005, 20.717993978000045],
              [107.44540384200009, 20.718549153000062],
              [107.4446387240001, 20.719424665000119],
              [107.44471915300001, 20.720940442000121],
              [107.44564559500002, 20.72293693800011],
              [107.44665883, 20.724215745000052],
              [107.44960726200007, 20.726665671000077],
              [107.45285211400005, 20.729050944000079],
              [107.45585360400005, 20.731282193000041],
              [107.45715125200005, 20.732358960000049],
              [107.45807388100009, 20.733098091],
              [107.45913841100007, 20.734090950000095],
              [107.46116694700004, 20.736010278],
              [107.46424345400004, 20.738487130000038],
              [107.46754216400004, 20.740808281000064],
              [107.46923703600004, 20.741500532000053],
              [107.46999953300006, 20.741582124000054],
              [107.47042325400004, 20.741583141000049],
              [107.47093324600004, 20.741026014000049],
              [107.47102166600007, 20.739670236000052],
              [107.47102598800008, 20.73807494800004],
              [107.47018633100006, 20.735201402000044],
              [107.46926023100008, 20.732965749000073],
              [107.46816293700007, 20.731367802000108],
              [107.46375470100001, 20.725473557000029],
              [107.45962029800006, 20.721399189000067],
              [107.45629549100003, 20.718553679000081],
              [107.453778895, 20.717397189000053],
              [107.45159044200003, 20.71700833800012],
            ],
          ],
          [
            [
              [107.1574011320001, 20.740948017000079],
              [107.15662616700011, 20.740878580000107],
              [107.15599158600001, 20.740942631000081],
              [107.155142418, 20.74173714000009],
              [107.15450496500002, 20.742465979000087],
              [107.15443045100007, 20.743396404000066],
              [107.15442670900003, 20.744260630000078],
              [107.15498708700002, 20.745060531],
              [107.15569042300005, 20.745395614000103],
              [107.15660607000005, 20.745532077000028],
              [107.15808870400006, 20.744939423000105],
              [107.15900834900003, 20.744145175000114],
              [107.15943465200004, 20.743349044000055],
              [107.15894760800008, 20.741884638000037],
              [107.1574011320001, 20.740948017000079],
            ],
          ],
          [
            [
              [107.386250262, 20.746295522000047],
              [107.38548753100005, 20.746293399000045],
              [107.38480779100011, 20.746849852000082],
              [107.38446729100009, 20.747327490000075],
              [107.38463327300006, 20.748444643000049],
              [107.38513472700008, 20.750679416000125],
              [107.3865669530001, 20.753395343000044],
              [107.38935424100006, 20.756434059000114],
              [107.39214335400008, 20.758914386000079],
              [107.39265087600006, 20.75923483800004],
              [107.39349815600009, 20.759316925000078],
              [107.39477069000004, 20.758921606000058],
              [107.39655371200004, 20.757889583000107],
              [107.39740418800007, 20.756934756000064],
              [107.39749189600005, 20.755977844000114],
              [107.397495588, 20.754781419000068],
              [107.39741428000011, 20.753664518000022],
              [107.39665546300003, 20.752386239000131],
              [107.39496516300005, 20.750866111000029],
              [107.39310414600008, 20.749744314000026],
              [107.39048115700004, 20.748381104000124],
              [107.38819669700003, 20.747178334000083],
              [107.386250262, 20.746295522000047],
            ],
          ],
          [
            [
              [107.14617323100005, 20.753137698000046],
              [107.14552643100009, 20.75303367000005],
              [107.14455378600009, 20.753436002000036],
              [107.14379555100005, 20.754143746000061],
              [107.14324905100004, 20.755766050000091],
              [107.14324326000011, 20.757085865000043],
              [107.14356110000001, 20.758406934000014],
              [107.14371731400007, 20.758832612000063],
              [107.1440955890001, 20.75952578200004],
              [107.14452576499998, 20.759832031000066],
              [107.14528179800001, 20.759631912000117],
              [107.14658298500002, 20.758114058000032],
              [107.14756141100007, 20.756391905],
              [107.14789079800005, 20.755073338000059],
              [107.14745505300006, 20.754167012000032],
              [107.14617323100005, 20.753137698000046],
            ],
          ],
          [
            [
              [107.15227401099999, 20.750645465000069],
              [107.151605268, 20.750642895000034],
              [107.15093605900005, 20.750745227000067],
              [107.15043266900005, 20.751162891000071],
              [107.15020679000001, 20.751843884000017],
              [107.15031322500002, 20.752998208000051],
              [107.15036439400009, 20.754047413000087],
              [107.15019332200005, 20.75493841300009],
              [107.14991101000005, 20.755776531000066],
              [107.14890031900009, 20.757503496000048],
              [107.14777814100006, 20.759230021000086],
              [107.14654585700006, 20.760641409000065],
              [107.14576262600009, 20.761320232000102],
              [107.14514681700004, 20.761947248000112],
              [107.14492274500006, 20.762208628000067],
              [107.14492136600003, 20.762523323000082],
              [107.14536538900009, 20.762944636000036],
              [107.14619954800001, 20.763367467000116],
              [107.14653324900009, 20.763526102000093],
              [107.14709081200003, 20.763475815000021],
              [107.14798505200004, 20.762902322000087],
              [107.14944003500008, 20.761544231000073],
              [107.15061589300002, 20.760289967000034],
              [107.15196233900005, 20.758249590000055],
              [107.15280536700008, 20.756626865000079],
              [107.15320207500004, 20.75510733400003],
              [107.153318536, 20.753953870000075],
              [107.15332331200004, 20.752852428000068],
              [107.15321549400008, 20.752012805000049],
              [107.15266298200002, 20.750909212000046],
              [107.15227401099999, 20.750645465000069],
            ],
          ],
          [
            [
              [107.12336770000005, 20.759134387000074],
              [107.12247670500005, 20.758973500000089],
              [107.12152808800008, 20.759231976000066],
              [107.12068951300006, 20.759805588000098],
              [107.12023822500002, 20.76101014200011],
              [107.12039998799999, 20.762217136000061],
              [107.12111978400004, 20.763268997000047],
              [107.12167523000008, 20.763690801000045],
              [107.12245454700007, 20.763903706000072],
              [107.12345867600003, 20.763697891],
              [107.12412935600001, 20.76328096000006],
              [107.12457803800001, 20.762653341000089],
              [107.12480590000003, 20.761552799000071],
              [107.1247541700001, 20.760660944000087],
              [107.12408985400003, 20.759661754000014],
              [107.12336770000005, 20.759134387000074],
            ],
          ],
          [
            [
              [107.14207002300002, 20.75403552000008],
              [107.14186096700004, 20.752511813000041],
              [107.14100646300002, 20.750579479000031],
              [107.139825231, 20.749356553],
              [107.13885662200005, 20.74884513700006],
              [107.13810107300007, 20.748943723000046],
              [107.13766779300002, 20.749348133000112],
              [107.13712349800001, 20.750462806000058],
              [107.13646908000004, 20.752084672000045],
              [107.1360339930001, 20.752895180000053],
              [107.13549462300006, 20.752893068000056],
              [107.13495570400005, 20.752789431000096],
              [107.13442128500007, 20.751670548000064],
              [107.13476244900005, 20.747712330000034],
              [107.13541372000003, 20.746801129000048],
              [107.13660254000007, 20.746298153000083],
              [107.137574269, 20.746098891000116],
              [107.13929974300004, 20.746207162000104],
              [107.14080813900006, 20.746619145000089],
              [107.14177763400006, 20.746927505000109],
              [107.14264148100004, 20.746727801000105],
              [107.14350845200002, 20.745817422000101],
              [107.14394393800008, 20.74490536600004],
              [107.14362433700009, 20.743990369000095],
              [107.14244402900005, 20.742564394000063],
              [107.14039900300004, 20.74154116000004],
              [107.13932167000007, 20.741232371000073],
              [107.13867446500008, 20.741229845000042],
              [107.13824254700005, 20.741329686000128],
              [107.13780838800001, 20.741937161000031],
              [107.13748030000005, 20.74295116600009],
              [107.13693826600004, 20.743558216000046],
              [107.13607307100006, 20.744062464000109],
              [107.13520922000006, 20.74426214200011],
              [107.13456290500007, 20.744056546000067],
              [107.13424244900001, 20.743344594000042],
              [107.13457280400009, 20.74182296900009],
              [107.13479483600007, 20.740402443000058],
              [107.13415078600008, 20.739689218000109],
              [107.132209188, 20.7396815910001],
              [107.13080557600004, 20.739980649000067],
              [107.12918531200009, 20.740481906000063],
              [107.12853629800004, 20.740885462000058],
              [107.1278863740001, 20.741492066000063],
              [107.12777533200006, 20.742202324000033],
              [107.12787322500007, 20.744436326000049],
              [107.12754236000005, 20.746059459000051],
              [107.12721375800004, 20.747174966000067],
              [107.12667077300006, 20.747985035000035],
              [107.12580507700001, 20.748590772000064],
              [107.12472363200007, 20.749195650000019],
              [107.12439683000011, 20.749905043000091],
              [107.12417516600007, 20.751224014000066],
              [107.12460074000002, 20.752545550000079],
              [107.12610279300004, 20.754378982000077],
              [107.12792759400004, 20.7564167230001],
              [107.12975605300007, 20.757642253000071],
              [107.13083165500007, 20.758357171000071],
              [107.13265833600005, 20.75998876200007],
              [107.13437718000006, 20.761619911000118],
              [107.13501950500009, 20.762739208000092],
              [107.13490712700003, 20.763754021000032],
              [107.13425354600005, 20.765172803],
              [107.13424949500006, 20.766086504000022],
              [107.13500241400001, 20.766597080000082],
              [107.13931095900008, 20.768136784000113],
              [107.140820418, 20.76834571100008],
              [107.14168436900007, 20.768146027000078],
              [107.14244222100008, 20.7675398270001],
              [107.14276942300006, 20.766728906000061],
              [107.14309975100004, 20.765207324000109],
              [107.14321386800006, 20.763786423000049],
              [107.143112226, 20.762364681000101],
              [107.14247298700005, 20.760534742000033],
              [107.14193671900009, 20.759821981000037],
              [107.14129079200002, 20.759514888000027],
              [107.14021201700002, 20.759510688000127],
              [107.13956564700001, 20.759305121000082],
              [107.13924604200012, 20.75839014700005],
              [107.13936063100002, 20.756867701000111],
              [107.13968739100005, 20.756158294000038],
              [107.14087581000005, 20.755756824000038],
              [107.14185025800003, 20.754948408000118],
              [107.14207002300002, 20.75403552000008],
            ],
          ],
          [
            [
              [107.12922020200006, 20.759000209000078],
              [107.12871862600012, 20.758998230000088],
              [107.12855049400007, 20.759207364000076],
              [107.12837908500009, 20.760150791000065],
              [107.1283727630001, 20.761566914000085],
              [107.12825451, 20.763087495000043],
              [107.1275793880001, 20.764500970000107],
              [107.12662793600006, 20.765388852000029],
              [107.12579076800007, 20.765647784000052],
              [107.12506552200001, 20.76580225500004],
              [107.124450338, 20.766271859000085],
              [107.12405667800003, 20.767057046000033],
              [107.12377401000001, 20.767947565000085],
              [107.12399529400003, 20.768315582000071],
              [107.12460695800009, 20.76863270600002],
              [107.12589002200011, 20.768375545000062],
              [107.12868117900004, 20.767390047000056],
              [107.13018902900009, 20.766714149000087],
              [107.13153013400004, 20.765932689000088],
              [107.13214506700002, 20.765515507000117],
              [107.13259372800012, 20.76488787400006],
              [107.13298875700011, 20.763787984000089],
              [107.13299155099999, 20.763158597000064],
              [107.13265994900009, 20.762527904000102],
              [107.13155066300006, 20.761317193000117],
              [107.13049712500001, 20.760106693000054],
              [107.12977612000005, 20.759317103000093],
              [107.12922020200006, 20.759000209000078],
            ],
          ],
          [
            [
              [107.47944430800001, 20.757954530000063],
              [107.478272938, 20.757654764000044],
              [107.47700079599998, 20.757970800000081],
              [107.47479422400002, 20.759082231000022],
              [107.47241660600012, 20.760751562000031],
              [107.4709725540001, 20.761944528000065],
              [107.47054683200005, 20.762661362000046],
              [107.47046012800007, 20.763379016],
              [107.47147200800003, 20.765295710000053],
              [107.47358466500005, 20.767613837000098],
              [107.47654153500009, 20.771210100000111],
              [107.47865772300004, 20.772252],
              [107.48060632900007, 20.772575633000123],
              [107.48289503500007, 20.772501243000093],
              [107.48518415100003, 20.772267319000015],
              [107.48654134400005, 20.77187167700005],
              [107.48730564200005, 20.771315129000101],
              [107.48764697900005, 20.770438558000045],
              [107.48765032800006, 20.769162386000062],
              [107.48721636000003, 20.766862066000066],
              [107.4863057470001, 20.764772403000094],
              [107.48508195800009, 20.763001575000125],
              [107.48371410000007, 20.761203988000076],
              [107.48141757800003, 20.75886346500004],
              [107.47944430800001, 20.757954530000063],
            ],
          ],
          [
            [
              [107.13305271300007, 20.774487891000071],
              [107.13216140700004, 20.774379487000068],
              [107.13160357500007, 20.774482194000129],
              [107.1312677670001, 20.774795567000034],
              [107.13098557800001, 20.775581185000114],
              [107.13081510900003, 20.776314801000062],
              [107.13069966000008, 20.777205965000078],
              [107.13080903800007, 20.777678439000091],
              [107.13142074300005, 20.777995539000038],
              [107.13292563800005, 20.778001456000084],
              [107.13432022400005, 20.777744688000062],
              [107.13493565300008, 20.777222607000034],
              [107.13493890600009, 20.776488343000032],
              [107.13455339600007, 20.775437861000064],
              [107.13410960100003, 20.774964075000035],
              [107.13305271300007, 20.774487891000071],
            ],
          ],
          [
            [
              [107.18404546400004, 20.772503801000127],
              [107.18317933900005, 20.772429697000035],
              [107.18084079900009, 20.773130012000074],
              [107.17978393800004, 20.773622375000045],
              [107.17891467900006, 20.77429267800008],
              [107.1787617570001, 20.774823851000065],
              [107.17890826800006, 20.775816968000079],
              [107.17924328500007, 20.776775339000061],
              [107.17954346700003, 20.777060039000084],
              [107.17984393700003, 20.777273857000097],
              [107.18327203899999, 20.777286568000086],
              [107.18436361900007, 20.777503296000077],
              [107.18537882700005, 20.777967875000058],
              [107.18571713200006, 20.778146369000083],
              [107.18601849800004, 20.778147481000083],
              [107.18639609800002, 20.777936181000101],
              [107.18662435000007, 20.777405292000047],
              [107.18651413800009, 20.776731355000067],
              [107.18610566800005, 20.775311884000033],
              [107.18577209799999, 20.773999041000124],
              [107.18547398300009, 20.773218059000072],
              [107.18506138500007, 20.772791149000099],
              [107.18461022300005, 20.772576780000037],
              [107.18404546400004, 20.772503801000127],
            ],
          ],
          [
            [
              [107.48832961200004, 20.773616945000036],
              [107.48753822100011, 20.773615109000062],
              [107.48674624200004, 20.773837165000053],
              [107.4861908920001, 20.774358315000093],
              [107.48595131300009, 20.775178727000117],
              [107.48626335700006, 20.776896021000098],
              [107.48712939500004, 20.7786145970001],
              [107.488156272, 20.779363313000054],
              [107.48902624500005, 20.779589223000094],
              [107.49045176000004, 20.779219352000041],
              [107.49132406800001, 20.778549672000054],
              [107.49172269200004, 20.77743109000005],
              [107.49180435200005, 20.77646104800008],
              [107.49164704700009, 20.776087518000082],
              [107.49054200500004, 20.774965466000062],
              [107.48935724800008, 20.77406712600007],
              [107.48832961200004, 20.773616945000036],
            ],
          ],
          [
            [
              [107.17041299800006, 20.776457372000046],
              [107.16930631800007, 20.776154681000044],
              [107.16859373600008, 20.776226630000046],
              [107.16772224100001, 20.776447243000092],
              [107.16740282500004, 20.777117745000062],
              [107.16739773300007, 20.77831186400001],
              [107.16771015200007, 20.779283274000093],
              [107.16865540000003, 20.780331707000066],
              [107.16968169800003, 20.780932632000045],
              [107.17102647300001, 20.781086958000024],
              [107.17197775300004, 20.78071736200009],
              [107.17261373600007, 20.78004804400004],
              [107.17269572600003, 20.779376648],
              [107.17254060100007, 20.778629739000017],
              [107.17183277000004, 20.777582211000031],
              [107.17041299800006, 20.776457372000046],
            ],
          ],
          [
            [
              [107.17159180000007, 20.794880760000112],
              [107.170699934, 20.794877412000098],
              [107.17025310900007, 20.795085528000087],
              [107.17013917500006, 20.795662017],
              [107.17041364900003, 20.796659537000025],
              [107.17107809600003, 20.797710977000072],
              [107.17218694500011, 20.799131201000073],
              [107.17396584400004, 20.80029169200008],
              [107.17474557700007, 20.800451944000109],
              [107.17524793399998, 20.800296486000036],
              [107.17625397500009, 20.79967088000005],
              [107.17742988100004, 20.798416539000094],
              [107.17787913600009, 20.797631512000066],
              [107.177658159, 20.797158670000023],
              [107.17688108100002, 20.796369063000064],
              [107.17571336800003, 20.795682901000042],
              [107.17365291800009, 20.795203169000054],
              [107.17159180000007, 20.794880760000112],
            ],
          ],
          [
            [
              [107.1279767180001, 20.805807387000108],
              [107.12830858700003, 20.803981376000102],
              [107.12788514300007, 20.802152368000023],
              [107.12746125300001, 20.800424870000086],
              [107.12778858200008, 20.799614013000053],
              [107.12865359200006, 20.799211358000044],
              [107.12994793200005, 20.799317980000076],
              [107.132320819, 20.799530355000059],
              [107.13372395500002, 20.799434347000023],
              [107.13426570399999, 20.798928874000033],
              [107.13470585100008, 20.797001732000098],
              [107.13471710100004, 20.794463781000069],
              [107.13483219600005, 20.792839916000091],
              [107.13484209400008, 20.790606510000131],
              [107.13517162500007, 20.78928803300002],
              [107.13549618800002, 20.788153043000079],
              [107.13545012700011, 20.787309467000092],
              [107.135022603, 20.786394105000028],
              [107.13416576800006, 20.784969449000101],
              [107.13374321100009, 20.782937359000094],
              [107.13342855200008, 20.78090568600004],
              [107.13310804900004, 20.780193774000018],
              [107.13235463100006, 20.779784721000013],
              [107.13138227700006, 20.78008546900012],
              [107.13008625400003, 20.780384927000028],
              [107.12846791800007, 20.780378544000122],
              [107.1276084350001, 20.779562972000051],
              [107.12750372200006, 20.778851903000103],
              [107.12783237600007, 20.777736451000095],
              [107.12902910300005, 20.775507678000068],
              [107.12947015400005, 20.773377434000025],
              [107.12958301800005, 20.772261119000035],
              [107.12947920699999, 20.771347001000073],
              [107.12922275300008, 20.770743217000117],
              [107.12710752700005, 20.770320993],
              [107.12516144600005, 20.770075222000095],
              [107.12382405300006, 20.770017466000112],
              [107.12237196500008, 20.769882617000057],
              [107.12159191600003, 20.769827063000079],
              [107.12131230100007, 20.770035753000023],
              [107.12114297100001, 20.770507119000037],
              [107.12113895800012, 20.771398739000091],
              [107.12096514000004, 20.772866621000041],
              [107.12073676100006, 20.774072039000124],
              [107.12028520500009, 20.775329006000028],
              [107.1197776650001, 20.776638206000086],
              [107.11882186800007, 20.778470088000041],
              [107.11775605700008, 20.780151155000048],
              [107.11746233100011, 20.780537858000066],
              [107.11593717300005, 20.783780457000134],
              [107.11484859800004, 20.785908045000042],
              [107.11445739200008, 20.788901343000084],
              [107.11444864200007, 20.790830202000073],
              [107.11498075200004, 20.792456667000089],
              [107.1158370210001, 20.793982898000074],
              [107.11658540600003, 20.795508690000105],
              [107.11657759500009, 20.79723449600008],
              [107.11635352700003, 20.799060941000036],
              [107.1158089730001, 20.800175474000056],
              [107.11494117000005, 20.8011871900001],
              [107.11407612300007, 20.801589790000016],
              [107.11321199500007, 20.801789365000012],
              [107.11267018400009, 20.802294774000082],
              [107.11190611000002, 20.804220555000086],
              [107.11103041000004, 20.806958020000096],
              [107.11091880800004, 20.807769712000024],
              [107.11134811300003, 20.808279031000062],
              [107.11253459100007, 20.808385320000063],
              [107.11415361899999, 20.808290306000018],
              [107.11588330800002, 20.807586615000062],
              [107.11772501900001, 20.805969700000034],
              [107.11891378600004, 20.805568371000049],
              [107.1196681910001, 20.805774414000041],
              [107.12160405100005, 20.807203384000061],
              [107.12343384800002, 20.808225830000104],
              [107.12451152600001, 20.808534655000074],
              [107.12537476300002, 20.80853807600009],
              [107.12656307600002, 20.808238232000065],
              [107.12721322599999, 20.807631696000058],
              [107.1279767180001, 20.805807387000108],
            ],
          ],
          [
            [
              [107.41519326200006, 20.784071957],
              [107.41437286100003, 20.779153998000055],
              [107.41387291500006, 20.776281313000041],
              [107.41328437600004, 20.774684551000085],
              [107.41261134500003, 20.773007794000087],
              [107.41151452100004, 20.77132990100008],
              [107.41016128500009, 20.770369160000079],
              [107.40872208600011, 20.769806983000102],
              [107.40804378100003, 20.769884930000067],
              [107.40744878300009, 20.770441659000127],
              [107.40736161100007, 20.771239026000018],
              [107.40718748800006, 20.77275401000005],
              [107.40649802000003, 20.776500890000094],
              [107.40606864300003, 20.778334211000079],
              [107.40555764000003, 20.779130435000056],
              [107.40470905300006, 20.779447184000063],
              [107.40352238900003, 20.779443981000064],
              [107.40089574600003, 20.779117831000079],
              [107.39954005600003, 20.778954630000079],
              [107.39911526300007, 20.779272510000091],
              [107.39885901400001, 20.77990988400007],
              [107.39877032000004, 20.781185802000032],
              [107.39850718800002, 20.784056413000052],
              [107.398242818, 20.787325801000108],
              [107.39806074100004, 20.791392981000101],
              [107.39796687100008, 20.79434376800009],
              [107.39783296300001, 20.796536736000078],
              [107.39782558200002, 20.79892943400008],
              [107.398158025, 20.801083786000071],
              [107.39899935000007, 20.803159746000063],
              [107.40085430100007, 20.806434807000059],
              [107.40271224200005, 20.808752763000093],
              [107.40364281300005, 20.809393329000066],
              [107.4047436650001, 20.809795071000089],
              [107.40584598800008, 20.809718290000134],
              [107.40784049800008, 20.808965967000056],
              [107.4095388810001, 20.808013452000026],
              [107.41336067600004, 20.805710708000014],
              [107.4165034860001, 20.803645368000012],
              [107.41777887900002, 20.802372630000043],
              [107.41931188800004, 20.799983963000024],
              [107.41982501300004, 20.79846992500007],
              [107.41965856000003, 20.797432647000043],
              [107.41869413700007, 20.795232688000098],
              [107.41747876400007, 20.792537763000048],
              [107.41640184500004, 20.789010028000106],
              [107.41519326200006, 20.784071957],
            ],
          ],
          [
            [
              [107.17165473800004, 20.806314352000044],
              [107.17120877900001, 20.806312678000083],
              [107.17081789000008, 20.806468554000013],
              [107.17014628000003, 20.807095385000096],
              [107.169529735, 20.807879756000069],
              [107.16863333200007, 20.808925298000069],
              [107.16796081200003, 20.809761899000101],
              [107.16779156100006, 20.810233279000023],
              [107.16779044300006, 20.810495503000048],
              [107.168012081, 20.810811014000038],
              [107.16856865700005, 20.811022892000075],
              [107.16934910700006, 20.811025829000052],
              [107.17035342900002, 20.81081982500006],
              [107.17158229600008, 20.810247535000045],
              [107.17236585600008, 20.80951622700011],
              [107.17292797900005, 20.808416970000017],
              [107.17287535100004, 20.80768253300009],
              [107.17259951500006, 20.80699969700008],
              [107.17209980800001, 20.806525812000039],
              [107.17165473800004, 20.806314352000044],
            ],
          ],
          [
            [
              [107.18525764900005, 20.806102740000121],
              [107.18475594100002, 20.806100888000053],
              [107.18425291900006, 20.806413708],
              [107.1834676600001, 20.807564619000104],
              [107.18273682500002, 20.809030403000058],
              [107.18267668300011, 20.810079095000056],
              [107.18272848100004, 20.81102331400011],
              [107.18317203700006, 20.811601858000103],
              [107.18378459100003, 20.811761457000074],
              [107.18567954100006, 20.811873349000095],
              [107.18690684100008, 20.81166809100008],
              [107.18774499800007, 20.81119915900009],
              [107.18802721900006, 20.810361066000027],
              [107.18775569200007, 20.808629353000093],
              [107.18709133600007, 20.807525543000068],
              [107.18620333800006, 20.806578249000026],
              [107.18525764900005, 20.806102740000121],
            ],
          ],
          [
            [
              [107.12039289100002, 20.807370045000084],
              [107.11969248200008, 20.807201978000123],
              [107.11899094500002, 20.807281822000064],
              [107.11811263600001, 20.807691500000125],
              [107.11529938900003, 20.809580893000131],
              [107.11450532800005, 20.810734627000024],
              [107.11380041000008, 20.811558165000044],
              [107.11371014200007, 20.812136260000088],
              [107.11388393500006, 20.812467507000051],
              [107.1151079900001, 20.813133506],
              [107.11659799200001, 20.813139472000053],
              [107.11958470600004, 20.811663949],
              [107.12195822200005, 20.810103301000105],
              [107.12196231100003, 20.809194307000034],
              [107.12144053200002, 20.808283229000054],
              [107.12039289100002, 20.807370045000084],
            ],
          ],
          [
            [
              [107.12682438300007, 20.810936851000058],
              [107.1262623690001, 20.810893853000053],
              [107.12569907700004, 20.811136270000041],
              [107.12517848400003, 20.811501177000125],
              [107.12495841000003, 20.812356557000022],
              [107.12486643700007, 20.813579421000036],
              [107.12490365400004, 20.814925107000093],
              [107.12520181500001, 20.815945633000062],
              [107.12584722900004, 20.81668211300007],
              [107.12623590400004, 20.816805974000104],
              [107.12675487700008, 20.816808027],
              [107.12744974700011, 20.816158398000084],
              [107.12827581000008, 20.815183083000122],
              [107.12880077500007, 20.813839616000074],
              [107.12867413000008, 20.813145964000057],
              [107.12833307100001, 20.812043720000069],
              [107.127773604, 20.811429896000085],
              [107.12734279300005, 20.811061224000049],
              [107.12682438300007, 20.810936851000058],
            ],
          ],
          [
            [
              [107.16973107300004, 20.812967754000056],
              [107.16833851700002, 20.812700286000037],
              [107.16644245800008, 20.812850468000121],
              [107.16471273100004, 20.81321105300011],
              [107.16392912800005, 20.813942330000131],
              [107.16347775700009, 20.815199306000039],
              [107.163190027, 20.817296032000066],
              [107.16312640500007, 20.819131376000044],
              [107.163121233, 20.820337585000104],
              [107.16328421000006, 20.821334661],
              [107.16383765500002, 20.822280768000034],
              [107.16439381000004, 20.822597534000039],
              [107.16495130300007, 20.822599643000039],
              [107.16578800100011, 20.822497922000061],
              [107.16740585500003, 20.822241805000097],
              [107.16947104800002, 20.821672689000081],
              [107.17042100900002, 20.821151813000057],
              [107.17109379000009, 20.820262775000039],
              [107.17176923500001, 20.818744400000085],
              [107.17233583600009, 20.816596267000072],
              [107.17245177600009, 20.8155477970001],
              [107.17212107900008, 20.814654985000033],
              [107.17123312100006, 20.813707641000079],
              [107.16973107300004, 20.812967754000056],
            ],
          ],
          [
            [
              [107.17627800400004, 20.820387060000108],
              [107.17538602100004, 20.820383731000014],
              [107.17477256000009, 20.820433885000078],
              [107.17404649300005, 20.82074584100004],
              [107.17197754100002, 20.822206550000111],
              [107.17130632500007, 20.822728478000037],
              [107.17024351400008, 20.823563602000036],
              [107.16951563400006, 20.824295097000075],
              [107.16917934500005, 20.824713384000049],
              [107.16917822900004, 20.824975602000073],
              [107.16934391900007, 20.825343340000032],
              [107.16995583500008, 20.825660309000057],
              [107.17079143000002, 20.825820778000015],
              [107.17179493600001, 20.82582454500006],
              [107.1738032910001, 20.825517400000045],
              [107.17603575200006, 20.824948852000077],
              [107.17804563700001, 20.824274569000067],
              [107.17905178200003, 20.82364897399999],
              [107.17927721200003, 20.82307292000003],
              [107.17905685600002, 20.822442762000023],
              [107.178780757, 20.821812395000094],
              [107.17839249300002, 20.82133895],
              [107.17778168000011, 20.820759782000039],
              [107.17711380200009, 20.820495070000078],
              [107.17627800400004, 20.820387060000108],
            ],
          ],
          [
            [
              [107.253411654, 20.82243482300008],
              [107.25257419300009, 20.82243199000002],
              [107.25165178600005, 20.822744682000049],
              [107.25118906200005, 20.823295783000084],
              [107.25097757300006, 20.823847726000011],
              [107.25101702600003, 20.824479482000058],
              [107.25164043400009, 20.825705358000057],
              [107.25239264100007, 20.826102665000064],
              [107.25331265600008, 20.826421584000073],
              [107.25440136500001, 20.826425263000111],
              [107.255617515, 20.825955653000072],
              [107.25612360600005, 20.825009932000071],
              [107.25616788100007, 20.824378468000049],
              [107.25604497500011, 20.823667477000107],
              [107.25546040300006, 20.823231273000076],
              [107.25449928500002, 20.822714833000063],
              [107.253411654, 20.82243482300008],
            ],
          ],
          [
            [
              [107.27162412900003, 20.824129638000066],
              [107.27055372700002, 20.823316525000024],
              [107.27019567100005, 20.82338280400009],
              [107.26926334900001, 20.823919443000079],
              [107.26854343000004, 20.825063973000013],
              [107.26854064900007, 20.825806079000039],
              [107.26875255400003, 20.826548894000041],
              [107.26946591100007, 20.827158437000023],
              [107.2706802110001, 20.827769640000056],
              [107.27196708700008, 20.828111212000074],
              [107.27253959800001, 20.828113099000056],
              [107.27304254600006, 20.827575037000123],
              [107.273046064, 20.826630533000042],
              [107.2724783460001, 20.825346829000054],
              [107.27162412900003, 20.824129638000066],
            ],
          ],
          [
            [
              [107.1786640600001, 20.826464143000045],
              [107.17812331300004, 20.826243276000064],
              [107.17746541900004, 20.82627729900004],
              [107.17676791400007, 20.826530028000079],
              [107.17575856500005, 20.827328728000083],
              [107.17509635000005, 20.828384038000088],
              [107.175053808, 20.829295761000118],
              [107.17508925900009, 20.83006186500009],
              [107.17528087100004, 20.830500288000039],
              [107.175627085, 20.830975752000121],
              [107.17639907000002, 20.831416340000075],
              [107.17717305700002, 20.831382753000049],
              [107.17833718600006, 20.830584627000093],
              [107.1793102980001, 20.829202195000015],
              [107.17954612700008, 20.828327662000042],
              [107.179548427, 20.827780543000074],
              [107.17947303500007, 20.827306080000071],
              [107.17901089800003, 20.826793707000043],
              [107.1786640600001, 20.826464143000045],
            ],
          ],
          [
            [
              [107.22223018200002, 20.830780320000116],
              [107.22078063300007, 20.830775209000052],
              [107.21994330000007, 20.831034472000098],
              [107.218993423, 20.831555563000045],
              [107.21837846700001, 20.83197293400012],
              [107.21804164000011, 20.832548625000058],
              [107.21803974900004, 20.833020619000116],
              [107.21837216700001, 20.833546233000085],
              [107.21892759300006, 20.834072635000034],
              [107.21987392800003, 20.83444308900004],
              [107.22109965900009, 20.834657189000083],
              [107.22221513400011, 20.834556230000075],
              [107.22344316400002, 20.834193452000079],
              [107.22411428900004, 20.83367136500005],
              [107.22445110000011, 20.833095666000112],
              [107.22428675900001, 20.832360878000067],
              [107.22395474500007, 20.831730383000036],
              [107.22334397400007, 20.831098905000097],
              [107.22223018200002, 20.830780320000116],
            ],
          ],
          [
            [
              [107.31067012500006, 20.828991470000076],
              [107.31016171099999, 20.828910134000097],
              [107.30965272400007, 20.828988292000084],
              [107.30888826800006, 20.829384669000106],
              [107.30795225700007, 20.830338779],
              [107.3076102910001, 20.831135242000087],
              [107.30752126000002, 20.832331255000014],
              [107.30768800500005, 20.833129310000054],
              [107.30827839000004, 20.834008440000048],
              [107.30903919500003, 20.8346488480001],
              [107.31005521600007, 20.835050790000054],
              [107.31090335400008, 20.834973682000069],
              [107.31175234400007, 20.834657321000094],
              [107.31209458000005, 20.833781100000067],
              [107.31201597700003, 20.832026295],
              [107.31168246400006, 20.830430196000101],
              [107.31134557900008, 20.82979111500007],
              [107.31067012500006, 20.828991470000076],
            ],
          ],
          [
            [
              [107.13870054300003, 20.829588609000012],
              [107.13747484400004, 20.829253293000072],
              [107.13677323500008, 20.829333182000106],
              [107.13615600300007, 20.830157109000069],
              [107.13588754600006, 20.831395578000041],
              [107.13588205800005, 20.832635063000012],
              [107.13648578200009, 20.834868534000073],
              [107.13692150200008, 20.835448673000108],
              [107.13770822900007, 20.835947552000086],
              [107.13867319300007, 20.835786050000046],
              [107.13946429800006, 20.835293328000049],
              [107.14017064900007, 20.834139208000032],
              [107.14009210000005, 20.832073060000056],
              [107.13939777000007, 20.830500307000044],
              [107.13870054300003, 20.829588609000012],
            ],
          ],
          [
            [
              [107.25798430599998, 20.835418290000085],
              [107.2566963780001, 20.835346498],
              [107.25597918199999, 20.835748864000081],
              [107.25583424100009, 20.836220627000031],
              [107.25590297700009, 20.836962961000083],
              [107.25640086300007, 20.837774199000059],
              [107.25754259500003, 20.838655070000108],
              [107.25890006200008, 20.839266809],
              [107.25961573800006, 20.83926920900003],
              [107.26069078200001, 20.838868032000057],
              [107.26176709700007, 20.83812952400006],
              [107.26212773400007, 20.837388629000131],
              [107.26213054000003, 20.836646537000014],
              [107.26170266700007, 20.836240326000066],
              [107.26034444600005, 20.835830990000126],
              [107.25798430599998, 20.835418290000085],
            ],
          ],
          [
            [
              [107.08279110700005, 20.830620922000072],
              [107.08171990400005, 20.830426960000104],
              [107.08031158900005, 20.830673770000097],
              [107.07829724000001, 20.831549723000094],
              [107.07492784800012, 20.833760036000015],
              [107.07331458200012, 20.834827118000106],
              [107.071695349, 20.836165252000072],
              [107.07083308100006, 20.83707718400003],
              [107.07042832900005, 20.837643986000032],
              [107.07042622300006, 20.838086152000109],
              [107.07109119100001, 20.839162790000017],
              [107.07222764100007, 20.839736071000026],
              [107.07577433500008, 20.840761611000055],
              [107.076845887, 20.840892431000043],
              [107.07765149300005, 20.840579962000035],
              [107.0800748450001, 20.838252888000049],
              [107.08404138900002, 20.835490017000069],
              [107.08552117500007, 20.834295974000035],
              [107.08572486700004, 20.833728309000051],
              [107.08546009500007, 20.833032365000022],
              [107.08372651500008, 20.831193317],
              [107.08279110700005, 20.830620922000072],
            ],
          ],
          [
            [
              [107.21279328200009, 20.834417960000096],
              [107.21229256000005, 20.834153957000098],
              [107.2115115930001, 20.834256051000111],
              [107.20955599100003, 20.835297931000071],
              [107.20815939100002, 20.835974699000111],
              [107.20637208200003, 20.836754934000012],
              [107.20508696500009, 20.837432082000085],
              [107.20262736400007, 20.838996522000031],
              [107.20189977100009, 20.839675652],
              [107.20167438900009, 20.840251714000054],
              [107.20172757300006, 20.840881227000096],
              [107.20244916900006, 20.841670480000026],
              [107.20344934300005, 20.842513179000029],
              [107.20417244900001, 20.842935335000057],
              [107.20467339700008, 20.843146910000065],
              [107.20534309800006, 20.842991996000038],
              [107.20729987500005, 20.841687945000054],
              [107.21004227800003, 20.839128053000074],
              [107.21228028300001, 20.837195642000061],
              [107.21328745200003, 20.836307691000044],
              [107.21362366600006, 20.835889335000068],
              [107.21351342, 20.835574289000114],
              [107.21323719200005, 20.834943978000098],
              [107.21279328200009, 20.834417960000096],
            ],
          ],
          [
            [
              [107.11788464600006, 20.840710047000051],
              [107.12040186200005, 20.840265601000048],
              [107.12254946100003, 20.840383923000068],
              [107.12342227700003, 20.840008384000086],
              [107.12517669600007, 20.8372991620001],
              [107.125784573, 20.836227727000086],
              [107.12565367500009, 20.835532364000109],
              [107.12465139600003, 20.834896726000096],
              [107.12223540400007, 20.834358552000062],
              [107.12016474900007, 20.834247218000087],
              [107.1179700050001, 20.834152291000052],
              [107.11533736800007, 20.834859608000045],
              [107.11385832500008, 20.835927522000119],
              [107.11222821100003, 20.838529569000031],
              [107.11108827600003, 20.84090658600006],
              [107.11115154800002, 20.841728009000079],
              [107.11181935000003, 20.842236028000016],
              [107.1142944100001, 20.84319347200001],
              [107.11509853300002, 20.843196696000099],
              [107.11590523200006, 20.842631427000043],
              [107.11714583000004, 20.841086090000083],
              [107.11788464600006, 20.840710047000051],
            ],
          ],
          [
            [
              [107.16131187900004, 20.837181362000123],
              [107.16085333600002, 20.83696304500009],
              [107.16020179100011, 20.837104948000018],
              [107.15935755000007, 20.83753487700006],
              [107.15889449700003, 20.838363292000039],
              [107.15850474500012, 20.83994997600005],
              [107.15876811400005, 20.841033821000018],
              [107.15918464500007, 20.842118239000037],
              [107.15948864000008, 20.842660820000077],
              [107.15998439500007, 20.843131935000066],
              [107.16067177400001, 20.843567676000063],
              [107.16151385800005, 20.84364306900008],
              [107.16216542400005, 20.843501163000063],
              [107.16285637100002, 20.843106740000025],
              [107.16304983000003, 20.842638244000078],
              [107.16305246200008, 20.842024654000021],
              [107.16282581500006, 20.841301899000086],
              [107.16210977000006, 20.838628183000104],
              [107.161768873, 20.837760609],
              [107.16131187900004, 20.837181362000123],
            ],
          ],
          [
            [
              [107.191583888, 20.839952798000105],
              [107.19102634500005, 20.839950756000022],
              [107.19052433300008, 20.840001361000034],
              [107.18974246000005, 20.840313154000079],
              [107.18923913900005, 20.84067841200001],
              [107.18890221100007, 20.841254051000035],
              [107.18867723400011, 20.841725210000028],
              [107.18861907700008, 20.842301871000046],
              [107.18867309100003, 20.842721619000045],
              [107.18911630100011, 20.8434050010001],
              [107.18995067200004, 20.843880047000063],
              [107.19123153700006, 20.844251845000095],
              [107.19206766200006, 20.844307350000065],
              [107.19296062200004, 20.844100846000032],
              [107.19351991100004, 20.84368334100013],
              [107.19396812200009, 20.843160549000039],
              [107.19408202200002, 20.842584091000035],
              [107.19408462100006, 20.841954781000105],
              [107.1938639790001, 20.8413771],
              [107.19330925000003, 20.840693318],
              [107.19264192300005, 20.840271329000082],
              [107.191583888, 20.839952798000105],
            ],
          ],
          [
            [
              [107.17264357400006, 20.836054313000126],
              [107.17320777100011, 20.834483110000065],
              [107.17332261600002, 20.83369688500003],
              [107.17299031900006, 20.833171203000056],
              [107.17226886400005, 20.832381834000024],
              [107.17126710500003, 20.831958529000083],
              [107.16981887800003, 20.831638424000062],
              [107.167868223, 20.831473745000025],
              [107.16669854900005, 20.83120711400008],
              [107.16625432500005, 20.830785889000047],
              [107.1658674180001, 20.829997768000027],
              [107.16542544100004, 20.829052100000034],
              [107.16503742900008, 20.828526191000037],
              [107.16464806200003, 20.828314935000058],
              [107.16375604300002, 20.828311558000046],
              [107.16264033900008, 20.828464668],
              [107.16163546800007, 20.828775522000065],
              [107.16057371000007, 20.829348375000031],
              [107.16029382600009, 20.82960953100006],
              [107.16023671700006, 20.829923980000054],
              [107.16090168300011, 20.83087049700012],
              [107.16145514400007, 20.831816595000063],
              [107.16156371699999, 20.83249878300002],
              [107.16150549200006, 20.833075445000034],
              [107.1609457050001, 20.833597757000085],
              [107.15949433000002, 20.834011783000058],
              [107.15776372400009, 20.834529635000095],
              [107.15670169700006, 20.835154900000049],
              [107.15619742100007, 20.835729855000103],
              [107.15619606100006, 20.836044512000029],
              [107.15636128200005, 20.836517143000052],
              [107.15714002200008, 20.836939661000109],
              [107.15775308700009, 20.836994445000109],
              [107.15898125200002, 20.836632018000053],
              [107.16054483800008, 20.836061087000068],
              [107.16188314500005, 20.836013735000058],
              [107.16316570300003, 20.83596615300003],
              [107.16411239100002, 20.836231957000081],
              [107.16538911000005, 20.837547872000073],
              [107.16599747300003, 20.838703928000065],
              [107.16649388900007, 20.839964447000092],
              [107.16654651100006, 20.840698848000017],
              [107.16648784100002, 20.841380393000094],
              [107.16603978500007, 20.841850687000104],
              [107.16536914900009, 20.842215258000074],
              [107.16481025400003, 20.842527805000088],
              [107.16453013100003, 20.842841405000101],
              [107.16452833500006, 20.843260938000086],
              [107.16491525400002, 20.84404905400001],
              [107.16535995400004, 20.844365394000071],
              [107.16591729400005, 20.844419935000055],
              [107.16708973200008, 20.844057266000071],
              [107.16859893500006, 20.843171420000047],
              [107.16966252900005, 20.842178998000094],
              [107.17039291800009, 20.840870667000019],
              [107.17168485600004, 20.83862045300009],
              [107.17264357400006, 20.836054313000126],
            ],
          ],
          [
            [
              [107.15532653000005, 20.839834109000051],
              [107.15365412100005, 20.839775260000067],
              [107.15248259500002, 20.839928102000016],
              [107.15170066200007, 20.840239761000042],
              [107.15125211700008, 20.840814914000113],
              [107.15119407800007, 20.841339122000058],
              [107.15124709900002, 20.841968646000097],
              [107.15168789700007, 20.843176525000025],
              [107.15218559800005, 20.84412241400004],
              [107.15318419199998, 20.845279991000076],
              [107.15412954800004, 20.845860486000056],
              [107.15468597100008, 20.846124830000079],
              [107.1553550510001, 20.846127390000021],
              [107.15591306500008, 20.846024635000049],
              [107.15630517900011, 20.845606595],
              [107.156312434, 20.843928449000067],
              [107.15637317100006, 20.842774934000104],
              [107.1568278240001, 20.840783829000117],
              [107.15666259800003, 20.840311202000095],
              [107.15616171900005, 20.840099518000073],
              [107.15532653000005, 20.839834109000051],
            ],
          ],
          [
            [
              [107.38862439200007, 20.844767707000052],
              [107.38759526100009, 20.844657030000043],
              [107.38587861600007, 20.844921821000106],
              [107.38450325600004, 20.845780573000106],
              [107.38369931500006, 20.846802652000029],
              [107.38369710100005, 20.847503492000016],
              [107.38420981100002, 20.848151851000104],
              [107.38489460300011, 20.848638965000085],
              [107.38649554100004, 20.848805150000068],
              [107.38826974100003, 20.84843269100007],
              [107.38924474600006, 20.847572812000067],
              [107.389477067, 20.846441319000064],
              [107.38925082000003, 20.845632021000071],
              [107.38862439200007, 20.844767707000052],
            ],
          ],
          [
            [
              [107.36076530400008, 20.832731089000056],
              [107.36025659800001, 20.832729615000019],
              [107.35940691000005, 20.833285422000088],
              [107.35838608600005, 20.834319241000095],
              [107.35685415400003, 20.836069346000038],
              [107.35583225000003, 20.837422153000063],
              [107.35498094700003, 20.83845645300002],
              [107.35446906000004, 20.83941198400003],
              [107.35446325700011, 20.841166505000075],
              [107.35462571800004, 20.84332027300011],
              [107.35529217000003, 20.846911034000115],
              [107.35571033700008, 20.84866677200008],
              [107.35604845200007, 20.848986768000088],
              [107.35672626400003, 20.849148244000034],
              [107.35766002700009, 20.848831961000066],
              [107.35859640800004, 20.847718169000103],
              [107.35894238600007, 20.845645642000044],
              [107.35946107299999, 20.842616588000062],
              [107.35980781700009, 20.840304796000098],
              [107.36083076400008, 20.838632965000059],
              [107.36151454499999, 20.836960145000113],
              [107.36185759500005, 20.835764855000072],
              [107.36186202800005, 20.834409070000071],
              [107.36152602500005, 20.833451064000116],
              [107.36076530400008, 20.832731089000056],
            ],
          ],
          [
            [
              [107.05273627300002, 20.847228893000036],
              [107.05232438700004, 20.847227126000064],
              [107.05149914900007, 20.847524888000102],
              [107.05071882800007, 20.847995017000095],
              [107.05035082000009, 20.848380837000043],
              [107.04993683500003, 20.848809489000075],
              [107.04984362800005, 20.849153442000024],
              [107.0498421610001, 20.849454740000084],
              [107.0499324350001, 20.849713392000076],
              [107.05052571400007, 20.850060297000091],
              [107.05162117500004, 20.85066761100007],
              [107.05253294100007, 20.851403268000098],
              [107.05335298300005, 20.852181576000106],
              [107.05394627600003, 20.852528469000028],
              [107.05426664000007, 20.852529841000056],
              [107.05486369800009, 20.852101958000091],
              [107.05569374000002, 20.850814199000034],
              [107.05592611000006, 20.850083449000053],
              [107.05592819300006, 20.849653017000058],
              [107.05556456699999, 20.849134942000092],
              [107.05479051200007, 20.848313796000092],
              [107.05401520300005, 20.847750906000059],
              [107.05333060300008, 20.847360570000092],
              [107.05273627300002, 20.847228893000036],
            ],
          ],
          [
            [
              [107.25094912900001, 20.843675791000038],
              [107.25061520600009, 20.843517333000072],
              [107.25000149000002, 20.843620139000052],
              [107.24882920000002, 20.843983248000072],
              [107.24760075100008, 20.844451058000054],
              [107.24575715500004, 20.845388734000011],
              [107.24396767500012, 20.846746134000057],
              [107.24273655200004, 20.847895655000045],
              [107.24228825700007, 20.848470985000041],
              [107.24214682900001, 20.848994920000052],
              [107.24245186500011, 20.849415506000035],
              [107.24339750600011, 20.849995615000068],
              [107.24451102700004, 20.850418970000106],
              [107.24629264600001, 20.851106803000086],
              [107.2476278, 20.851897997],
              [107.24879609300004, 20.852583718000069],
              [107.24979833700009, 20.852954222000086],
              [107.25085835000004, 20.852800498000065],
              [107.25247795100003, 20.852124234000065],
              [107.25314845700008, 20.851759404000077],
              [107.25359592500007, 20.851393827000031],
              [107.25399003600008, 20.850398750000032],
              [107.25388072900009, 20.849821514000055],
              [107.25332556500008, 20.849190326000056],
              [107.25226898900006, 20.848452560000027],
              [107.25149100300007, 20.847768168000066],
              [107.25110271200001, 20.847242426],
              [107.25105018100001, 20.846403166000101],
              [107.25122328700004, 20.844882909000084],
              [107.25117034500002, 20.844148533000073],
              [107.25094912900001, 20.843675791000038],
            ],
          ],
          [
            [
              [107.27160587300003, 20.846012824000049],
              [107.27058838300005, 20.846009465000037],
              [107.26931562800007, 20.846244515000123],
              [107.26846593000002, 20.846720205000047],
              [107.268293951, 20.847357649000031],
              [107.26871402500004, 20.848395802000041],
              [107.27006472800005, 20.849995285000134],
              [107.27183971000002, 20.851516411000048],
              [107.27302294800003, 20.852557063000084],
              [107.27437638400002, 20.853438775000122],
              [107.27496994000002, 20.853440725000013],
              [107.27598953900009, 20.852885816000111],
              [107.27769130900003, 20.85129637200005],
              [107.27871354300007, 20.850023702000087],
              [107.27888577600001, 20.849306508000076],
              [107.27846418100003, 20.848667129000106],
              [107.27660260600001, 20.847624269000065],
              [107.27363906100004, 20.846498025000045],
              [107.27160587300003, 20.846012824000049],
            ],
          ],
          [
            [
              [107.18366103700002, 20.841287201000021],
              [107.1821558730001, 20.84122918700011],
              [107.18065004900002, 20.841328495000063],
              [107.17903138300004, 20.841742024],
              [107.17746803600009, 20.842260645000096],
              [107.17696513000004, 20.842520986000054],
              [107.17638875100005, 20.842915656000024],
              [107.17543949000003, 20.843417751000089],
              [107.17465595100003, 20.843896529000091],
              [107.17325869400005, 20.844904771000117],
              [107.17194029800004, 20.846128136000083],
              [107.17114719600006, 20.847061761000063],
              [107.17075288300003, 20.848004244000059],
              [107.17046785800008, 20.849471567000052],
              [107.17035166400005, 20.85057243100001],
              [107.17028720700004, 20.852617433000106],
              [107.17039426100011, 20.853666686000061],
              [107.17083787800004, 20.854245216000052],
              [107.17144944200002, 20.854667044000053],
              [107.17222940000008, 20.854827300000075],
              [107.17273167700006, 20.854724294000079],
              [107.17351407900006, 20.854307691000074],
              [107.17524770000006, 20.853108000000105],
              [107.17731893600009, 20.85117536200007],
              [107.17877350000001, 20.850027042000093],
              [107.18000214800006, 20.849559619000054],
              [107.18134142300003, 20.84930237600009],
              [107.18212378500002, 20.848885739000075],
              [107.1827409520001, 20.848226214000043],
              [107.183226039, 20.847722176000083],
              [107.18376543200004, 20.847066579000014],
              [107.18457503000006, 20.845956727000022],
              [107.18538482900001, 20.844796287000023],
              [107.18560064300001, 20.844126289000094],
              [107.18565837199999, 20.843654518000051],
              [107.18549373000005, 20.843024593000067],
              [107.18505141000004, 20.842131427000062],
              [107.18455202000011, 20.841552708000052],
              [107.18366103700002, 20.841287201000021],
            ],
          ],
          [
            [
              [107.34804047000007, 20.82212654900006],
              [107.34838065700006, 20.821808539000038],
              [107.34905890000005, 20.821810539000026],
              [107.34964970300008, 20.822609817000078],
              [107.35015413400008, 20.823887376000094],
              [107.35031600300006, 20.826200711000098],
              [107.35005183200005, 20.82915081600003],
              [107.34969887900007, 20.833296933000064],
              [107.34969170400009, 20.835450241000061],
              [107.35019404400001, 20.837365773000016],
              [107.35112484400001, 20.837926777000071],
              [107.351803143, 20.837928769000058],
              [107.35273738900003, 20.837452999000035],
              [107.35477965500007, 20.835225906000062],
              [107.35631211500004, 20.833316310000072],
              [107.35843752000004, 20.831567935000095],
              [107.36200810700007, 20.828627411000063],
              [107.36523791100004, 20.826164354000106],
              [107.36770175800007, 20.824576350000079],
              [107.3683805270001, 20.824418786000095],
              [107.3689312220001, 20.824539989000087],
              [107.37020037000003, 20.825341154000029],
              [107.37248511100007, 20.826703467000037],
              [107.37637720400005, 20.829186855000081],
              [107.37781572100006, 20.830068197000081],
              [107.37849323200007, 20.830309367000055],
              [107.37951089500007, 20.830232472000084],
              [107.38044478300007, 20.829836327000081],
              [107.381209348, 20.829359955000101],
              [107.38121263500001, 20.828323174000026],
              [107.38087628500007, 20.827444936000106],
              [107.38011704600004, 20.826246506000068],
              [107.37935958200008, 20.824489798000023],
              [107.37894050300004, 20.822973291000046],
              [107.37877601300002, 20.821377738000102],
              [107.37865887300009, 20.818227104000094],
              [107.37867943700012, 20.811766996000088],
              [107.37907239500004, 20.808673124000073],
              [107.37975845100004, 20.805977984],
              [107.38089975200006, 20.802148504000058],
              [107.38168323000005, 20.80021077200005],
              [107.38211036900003, 20.799175120000065],
              [107.38202913100007, 20.798058301000118],
              [107.38160932000005, 20.796780999000042],
              [107.38110298400008, 20.796061764000036],
              [107.380002754, 20.795500373000039],
              [107.37890152600002, 20.795258011000058],
              [107.37805307900007, 20.795494889000032],
              [107.37609691900005, 20.79752154100003],
              [107.3740559080001, 20.800212859000041],
              [107.37171350500009, 20.802974268000085],
              [107.36882200500003, 20.805876977000089],
              [107.36678460500012, 20.806748459000076],
              [107.36398499600003, 20.80737846500007],
              [107.35813192600007, 20.808478112000017],
              [107.35219639500012, 20.808859548000044],
              [107.34914450400002, 20.808850572000011],
              [107.34799231400008, 20.808475198000039],
              [107.34766082200011, 20.808023974],
              [107.34678349300007, 20.807089467000047],
              [107.34584952600007, 20.805491083000049],
              [107.34517321300007, 20.804930783000053],
              [107.34458007100002, 20.804849262000054],
              [107.3435194740001, 20.805125254000068],
              [107.34266986700007, 20.805681021000041],
              [107.34207269300003, 20.806795831000088],
              [107.34164236500004, 20.808708698000089],
              [107.34114556900006, 20.812109172000071],
              [107.34064008700007, 20.815541059000076],
              [107.34004649700007, 20.82063368700009],
              [107.3395939050001, 20.825331474000031],
              [107.33923533800001, 20.831072655000057],
              [107.33880195600004, 20.833862713000073],
              [107.33760413600005, 20.837049220000026],
              [107.33555242300011, 20.841987682000074],
              [107.33367209000005, 20.846368355000067],
              [107.33213057200005, 20.850829743000077],
              [107.33203731400006, 20.853301729000059],
              [107.332372671, 20.854419247000067],
              [107.33355761000008, 20.855060822000034],
              [107.33432076600008, 20.855063124000047],
              [107.33550979000005, 20.854508453000072],
              [107.33695698100007, 20.852838047000063],
              [107.33857563000005, 20.850609894000094],
              [107.34002492400006, 20.848301452000051],
              [107.3427926080001, 20.844760795000106],
              [107.34398388600007, 20.842206172000118],
              [107.34450142400009, 20.841081887000087],
              [107.34483385800006, 20.838401483],
              [107.34535836300002, 20.833697636000089],
              [107.34581051900004, 20.829776322],
              [107.34675728700003, 20.825552177000084],
              [107.34752939200007, 20.822842827000088],
              [107.34804047000007, 20.82212654900006],
            ],
          ],
          [
            [
              [107.28288829900006, 20.844614226000054],
              [107.28348387800006, 20.844057898000031],
              [107.28399319800009, 20.843900048000116],
              [107.285518536, 20.844144237000073],
              [107.28737983399999, 20.845266772000045],
              [107.28881575300005, 20.846786678000036],
              [107.2897438090001, 20.848065682000083],
              [107.29033647800003, 20.848306843000053],
              [107.29093030800004, 20.848228995000092],
              [107.29245915800007, 20.84751614],
              [107.293648832, 20.84680218900008],
              [107.29407481000007, 20.846245292],
              [107.29407682900006, 20.845687040000094],
              [107.29306425200004, 20.844328023000088],
              [107.29086696000005, 20.842327189000095],
              [107.28807561400001, 20.840483915000085],
              [107.285198655, 20.838879582000033],
              [107.28240536000007, 20.837594486000022],
              [107.27994948200009, 20.836788965000075],
              [107.27605344600005, 20.835659690000071],
              [107.27368210100005, 20.834934129000082],
              [107.27130988900005, 20.834447801000074],
              [107.26969836500004, 20.834601982000045],
              [107.2685098740001, 20.834996813000117],
              [107.26740436000003, 20.83587042500001],
              [107.26689145300004, 20.836985259000059],
              [107.266459422, 20.83913713500003],
              [107.26645252800006, 20.840971410000094],
              [107.26695467400003, 20.842727623000101],
              [107.26745891500011, 20.843925567],
              [107.26838891000004, 20.844646412000053],
              [107.269406094, 20.844729535000091],
              [107.271611826, 20.844417818000061],
              [107.27305295400004, 20.844502313000035],
              [107.27466248200007, 20.844906364000067],
              [107.27788067300007, 20.845953676000036],
              [107.27932063600011, 20.846357138000116],
              [107.28058985200005, 20.847079036000068],
              [107.28118045100007, 20.847878473000044],
              [107.28143159400007, 20.848756550000047],
              [107.28134240300005, 20.849952527000056],
              [107.28057574000005, 20.850907042000074],
              [107.27895819400005, 20.852656275000037],
              [107.27810613200008, 20.853769997000079],
              [107.27793388300003, 20.854487186000092],
              [107.27818620800004, 20.855046262000073],
              [107.27852391300009, 20.855446118000117],
              [107.27911747500008, 20.855448056000014],
              [107.28030636300005, 20.85497342700009],
              [107.28141103700005, 20.854339028000091],
              [107.28328090300005, 20.853148845000113],
              [107.28455807200002, 20.851717479000072],
              [107.28498700900008, 20.8503631130001],
              [107.28473789500006, 20.848926784000099],
              [107.28423177000012, 20.848207386000084],
              [107.283219258, 20.846848339000076],
              [107.28271549000007, 20.845490928000032],
              [107.28288829900006, 20.844614226000054],
            ],
          ],
          [
            [
              [107.26831938000007, 20.850551578000065],
              [107.26787430300001, 20.850287892],
              [107.2670371450001, 20.850494882000078],
              [107.26603173500006, 20.85096352100004],
              [107.26474791700005, 20.851326349000132],
              [107.26430047000008, 20.851691957000092],
              [107.26413181100006, 20.852058485000043],
              [107.26423956800001, 20.853055250000097],
              [107.26473507400006, 20.85473504600008],
              [107.26523434000006, 20.855418457000049],
              [107.26573518800004, 20.855682331000125],
              [107.26668308700003, 20.855685481000116],
              [107.26813479800006, 20.855165878000022],
              [107.26914061800007, 20.854592347000086],
              [107.26942216900009, 20.853859085000082],
              [107.26925960500006, 20.852599941000079],
              [107.26881805900003, 20.85139230599999],
              [107.26831938000007, 20.850551578000065],
            ],
          ],
          [
            [
              [107.34417945100009, 20.848393584000036],
              [107.34350218900001, 20.848072561000031],
              [107.34265373900006, 20.848229547000052],
              [107.34171860900005, 20.848944518000053],
              [107.34086557400008, 20.850457232000075],
              [107.34035088200004, 20.852210212000074],
              [107.340261502, 20.853565704000054],
              [107.34025772800005, 20.854682196000063],
              [107.34059367200005, 20.855640193000013],
              [107.34110056300007, 20.856199956000054],
              [107.34169332000009, 20.856440976],
              [107.34254207600006, 20.856204252000055],
              [107.3441580090001, 20.854773558000069],
              [107.34509476300006, 20.85358009],
              [107.34552167900003, 20.85270409499999],
              [107.34577953400006, 20.851668106000076],
              [107.34561448800008, 20.850311854000033],
              [107.34519320800004, 20.849513104000025],
              [107.34417945100009, 20.848393584000036],
            ],
          ],
          [
            [
              [107.11747519600002, 20.851101974000038],
              [107.11687236300006, 20.851036396000033],
              [107.11526319300006, 20.851219459000077],
              [107.11281277100008, 20.852188693000073],
              [107.1098888990001, 20.854103477000052],
              [107.1083420960001, 20.855297383000071],
              [107.10813759100006, 20.856054539000098],
              [107.10860380499999, 20.856688076000026],
              [107.10960729700007, 20.857071110000014],
              [107.11161832700002, 20.856952883000091],
              [107.11416747300008, 20.856394635000086],
              [107.11638582800003, 20.854887550000086],
              [107.11753421400002, 20.852870852000116],
              [107.11780769400009, 20.851671794000112],
              [107.11747519600002, 20.851101974000038],
            ],
          ],
          [
            [
              [107.41245868000001, 20.84990005700007],
              [107.41165795600001, 20.849897925000072],
              [107.410513403, 20.850110521000076],
              [107.409423938, 20.851024091000077],
              [107.40890721800005, 20.851669643000022],
              [107.40879038100007, 20.852477994000118],
              [107.40884577800004, 20.853071158000077],
              [107.40970012100003, 20.854259483000078],
              [107.41152518900005, 20.855989488000048],
              [107.4128376300001, 20.857017285],
              [107.4137511540001, 20.85755881700004],
              [107.41432328900001, 20.85750641900006],
              [107.41501158300012, 20.85686131500006],
              [107.41427967700005, 20.852977813000081],
              [107.41388400500009, 20.851413352000044],
              [107.41320059000003, 20.850441141000076],
              [107.41245868000001, 20.84990005700007],
            ],
          ],
          [
            [
              [107.04477189000006, 20.852677713000034],
              [107.04411407200008, 20.852674867000061],
              [107.04369097299998, 20.852717222000102],
              [107.04307775800005, 20.853200691000048],
              [107.04231902400007, 20.854611574000081],
              [107.04156006200006, 20.856066645000034],
              [107.04108300700003, 20.857522934000094],
              [107.04126683500004, 20.85836339100009],
              [107.04192229500011, 20.858852357000046],
              [107.04361344700006, 20.858948068000061],
              [107.04530697900002, 20.858557664000088],
              [107.04737965700009, 20.857505988000113],
              [107.04775793400005, 20.857021497000083],
              [107.04776203100002, 20.856181855000067],
              [107.04720744800005, 20.85478079800005],
              [107.0465723790001, 20.853615889000118],
              [107.04580410100003, 20.85299151800006],
              [107.04477189000006, 20.852677713000034],
            ],
          ],
          [
            [
              [107.10238716500005, 20.845956302000076],
              [107.10320174900005, 20.843685618000052],
              [107.10320958600008, 20.841980150000069],
              [107.10288006200007, 20.840778649000043],
              [107.10238518600008, 20.839102714000113],
              [107.10279189100007, 20.838093697000112],
              [107.10427071100003, 20.837089020000029],
              [107.10708968200004, 20.836089764000043],
              [107.10890408200005, 20.834960080000094],
              [107.10937716800004, 20.834077642000089],
              [107.10938696800004, 20.831929985000031],
              [107.10886014700006, 20.829906487000116],
              [107.10812914800006, 20.828577009000071],
              [107.10739584900006, 20.827752861000093],
              [107.10699468100007, 20.827561738],
              [107.106391645, 20.827559298000061],
              [107.10451319000002, 20.828057031000036],
              [107.10108079900006, 20.829530961000039],
              [107.09867178400005, 20.830623146000079],
              [107.09598511000006, 20.832001855000023],
              [107.09443869000009, 20.833132538000051],
              [107.09372088100012, 20.834148917000036],
              [107.09371636300001, 20.835121747000059],
              [107.09417926400003, 20.835796349000098],
              [107.09611576000003, 20.83725713299999],
              [107.0971996370001, 20.838162623000045],
              [107.09762931400003, 20.838704325],
              [107.09762697800002, 20.839209650000079],
              [107.0966157070001, 20.840532019000065],
              [107.09513183200006, 20.842610452000088],
              [107.09438534400003, 20.84462871600001],
              [107.09384515200001, 20.845510831000077],
              [107.09337519700003, 20.845698408000054],
              [107.09304014000001, 20.845697032000118],
              [107.09250552000002, 20.845379002000094],
              [107.09230595800003, 20.84506234900001],
              [107.09117912600004, 20.842404714000025],
              [107.09032124400002, 20.839558681000092],
              [107.08986072600003, 20.837724933000068],
              [107.08959652400002, 20.836902670000065],
              [107.08912923800008, 20.836521737000076],
              [107.0883921540001, 20.836518695000095],
              [107.08677080200007, 20.836876674000045],
              [107.08469960100004, 20.838019438000096],
              [107.08269986500004, 20.839667432000027],
              [107.08188832800005, 20.841243229000028],
              [107.08152820800009, 20.842680620000031],
              [107.08152018100004, 20.844386086],
              [107.08179898400002, 20.845611550000065],
              [107.08251406300003, 20.846790536000093],
              [107.08389886000003, 20.848676877000024],
              [107.08522992300004, 20.85064054000005],
              [107.08624096300011, 20.852301436000076],
              [107.08777430500007, 20.854013247000047],
              [107.09004246500005, 20.856233394000064],
              [107.09181056500006, 20.857914550000075],
              [107.0938827570001, 20.859060033000084],
              [107.09595760700009, 20.85963702100004],
              [107.09756715600004, 20.859390945000108],
              [107.09857589300002, 20.858637078000086],
              [107.09864553600009, 20.858068885000058],
              [107.09817993400003, 20.857308996000064],
              [107.09711032300008, 20.856736139000098],
              [107.09657712900011, 20.856102312000054],
              [107.09651274400002, 20.855533560000069],
              [107.09651625300003, 20.854775583000063],
              [107.09766309100007, 20.853137969],
              [107.10076347000009, 20.849297503000038],
              [107.10238716500005, 20.845956302000076],
            ],
          ],
          [
            [
              [107.28338918900003, 20.855268852000016],
              [107.28281685600011, 20.855199536000057],
              [107.28209938400008, 20.855669432000035],
              [107.28130909200006, 20.856476407000102],
              [107.28130660800011, 20.857151018000053],
              [107.28180365800003, 20.858232021000056],
              [107.28301595800006, 20.859450259000042],
              [107.28480309600005, 20.860063212000043],
              [107.28558992900005, 20.860200683000052],
              [107.286234592, 20.860067843000074],
              [107.28666552800004, 20.859664469000094],
              [107.28673981500006, 20.858922626],
              [107.28652805300004, 20.858112408000103],
              [107.28574468400006, 20.857030490000028],
              [107.28474611100003, 20.856082797000042],
              [107.28338918900003, 20.855268852000016],
            ],
          ],
          [
            [
              [107.14809918700007, 20.856408895000108],
              [107.14744628500004, 20.856406375000077],
              [107.14706497400003, 20.856507477],
              [107.14651798100007, 20.857172044000016],
              [107.145751762, 20.85819473400008],
              [107.14509346200006, 20.859422977000094],
              [107.14487021000006, 20.860704179000052],
              [107.145354733, 20.861885562000012],
              [107.14600428600002, 20.862657313000099],
              [107.14654704400007, 20.862967104000063],
              [107.14747223900007, 20.862919390000023],
              [107.14894579700004, 20.861899429000097],
              [107.15009420700002, 20.860570501],
              [107.15031475500007, 20.859904673000074],
              [107.15031698800007, 20.859391848000094],
              [107.14977713900008, 20.858415398000126],
              [107.14874985100008, 20.856924240000026],
              [107.14809918700007, 20.856408895000108],
            ],
          ],
          [
            [
              [107.13615651500002, 20.859414212000083],
              [107.1354951170001, 20.859411624000046],
              [107.13372993500006, 20.859737174000067],
              [107.13156698900008, 20.860268950000105],
              [107.12927229900001, 20.860675505000081],
              [107.12803621000006, 20.861003094000097],
              [107.12670913500003, 20.861953709000055],
              [107.125912294, 20.862657053000078],
              [107.12586633600002, 20.863072460000076],
              [107.12634913700001, 20.863573081000105],
              [107.1278887010001, 20.864410340000106],
              [107.12885746600006, 20.864705070000063],
              [107.13013619100008, 20.864710110000125],
              [107.13269843500007, 20.863639662000111],
              [107.13521987900006, 20.861820960000088],
              [107.13685777100005, 20.860372805000083],
              [107.13699188700005, 20.859957739000023],
              [107.13668489000004, 20.859582507000063],
              [107.13615651500002, 20.859414212000083],
            ],
          ],
          [
            [
              [107.06672462200004, 20.857781202000055],
              [107.06610768600005, 20.857584891000066],
              [107.06528325100005, 20.857710539000074],
              [107.06397452700004, 20.858608932000067],
              [107.06259527900005, 20.859894421000021],
              [107.0619743060001, 20.860537457000113],
              [107.06162793800004, 20.861181649000038],
              [107.06162452300002, 20.861891873],
              [107.06168541400008, 20.86350629800009],
              [107.06208957600003, 20.865122168000113],
              [107.06263477600004, 20.865963850000036],
              [107.06324988500003, 20.866547551000011],
              [107.0643468100001, 20.86687503400011],
              [107.06592649500007, 20.8667525870001],
              [107.06750926400011, 20.865984484000052],
              [107.06930106200005, 20.864571582000032],
              [107.07006117800006, 20.86354172300009],
              [107.07040875200003, 20.862639249000026],
              [107.07041121300006, 20.86212273200001],
              [107.07000329500006, 20.861281642000051],
              [107.06911727900003, 20.859922006000083],
              [107.06761401600008, 20.858430624000057],
              [107.06672462200004, 20.857781202000055],
            ],
          ],
          [
            [
              [107.21946038300008, 20.858186707000037],
              [107.21844587700008, 20.858137677000066],
              [107.2176228280001, 20.858543749000091],
              [107.216798125, 20.859358798000116],
              [107.21640810800005, 20.860220836000032],
              [107.21650274100006, 20.860721050000031],
              [107.21717537700003, 20.861677738000047],
              [107.21784838300002, 20.862543532000096],
              [107.21784565100006, 20.863225164000099],
              [107.21740567400008, 20.864496004000088],
              [107.21667503300002, 20.865947584000111],
              [107.21667266200009, 20.866538331000051],
              [107.21696076800004, 20.866993775000076],
              [107.21739473900006, 20.867222529000081],
              [107.217829803, 20.867178629000094],
              [107.218363341, 20.866680645000102],
              [107.22020878100001, 20.864369594000017],
              [107.22127799300002, 20.862828309000037],
              [107.22176480600007, 20.861921165000027],
              [107.22177133100007, 20.860285242000117],
              [107.22162836900006, 20.859784869000109],
              [107.22138967500011, 20.859056931000097],
              [107.22090831, 20.858600806000105],
              [107.21946038300008, 20.858186707000037],
            ],
          ],
          [
            [
              [107.30183427000003, 20.86105265800002],
              [107.30120611900001, 20.861050672000125],
              [107.30043219700011, 20.86127543000007],
              [107.29946433900005, 20.86168135500008],
              [107.298447181, 20.862359768000083],
              [107.29776743900007, 20.863266466000049],
              [107.29737793599999, 20.864083192000038],
              [107.29732683800003, 20.864855551000083],
              [107.29756335900007, 20.866265030000086],
              [107.29799497900007, 20.867175254000081],
              [107.29862135300002, 20.867677106000123],
              [107.29900710300005, 20.867905546000109],
              [107.29973208299999, 20.867862404000093],
              [107.30036286300006, 20.867137326000048],
              [107.30186860300003, 20.864960846000088],
              [107.302793514, 20.863055173000063],
              [107.30298954800006, 20.862283267000059],
              [107.30294350400005, 20.861646921000073],
              [107.302558574, 20.861191272000035],
              [107.30183427000003, 20.86105265800002],
            ],
          ],
          [
            [
              [107.22749596600006, 20.860600747000113],
              [107.22710959400007, 20.86055394400006],
              [107.22657736600009, 20.860733857000113],
              [107.22531709900008, 20.861729185000044],
              [107.22313549200005, 20.863539240000058],
              [107.22182450800003, 20.865125122000094],
              [107.22099798900004, 20.866394594000056],
              [107.22084994400004, 20.867166601000072],
              [107.22099254700002, 20.867757858000054],
              [107.22147484100006, 20.867986765000076],
              [107.22205451500005, 20.868034258000101],
              [107.22340895000012, 20.86767547600008],
              [107.22544149200007, 20.866910094000076],
              [107.22815409599998, 20.865238215000126],
              [107.22960819400004, 20.864107219000068],
              [107.22989972500008, 20.863699254000068],
              [107.22990151800006, 20.863244835000039],
              [107.229517652, 20.862561855000102],
              [107.22841097200009, 20.861376472000082],
              [107.22749596600006, 20.860600747000113],
            ],
          ],
          [
            [
              [107.08421174100008, 20.863441670000071],
              [107.08334081200005, 20.863374890000053],
              [107.08246811200004, 20.863687090000035],
              [107.08132524700008, 20.864440311000095],
              [107.08065060300004, 20.865384974],
              [107.080176717, 20.866393627000065],
              [107.08037389900005, 20.867215583000039],
              [107.08117514600009, 20.867850562000086],
              [107.08217954200006, 20.868044227000034],
              [107.08472802799999, 20.867675824000017],
              [107.08607283800006, 20.866733926000066],
              [107.08627803100006, 20.865850473000066],
              [107.08588155000004, 20.864648716000112],
              [107.08501417000004, 20.863823986000085],
              [107.08421174100008, 20.863441670000071],
            ],
          ],
          [
            [
              [107.26574338500009, 20.859981345000023],
              [107.26419717100005, 20.859976199000037],
              [107.26361683500008, 20.860110590000055],
              [107.26293813100006, 20.860699086000075],
              [107.26211224200003, 20.861877839000087],
              [107.26211035200005, 20.862377709000057],
              [107.26254350600001, 20.862833580000114],
              [107.26326727500003, 20.863108654000051],
              [107.26485943300005, 20.863750158000101],
              [107.26577494400004, 20.864434844000087],
              [107.26620777000005, 20.864981597000032],
              [107.26620657300005, 20.865299692000058],
              [107.26586645700006, 20.865798435000023],
              [107.26484744800004, 20.866931105000042],
              [107.26445848500001, 20.867566005000079],
              [107.26465022200006, 20.867975624000046],
              [107.26532535300005, 20.868341409000024],
              [107.266340266, 20.868299341000096],
              [107.26827412700005, 20.8680331],
              [107.26948317600008, 20.867764455000071],
              [107.27016085500001, 20.867448598000045],
              [107.27054827000009, 20.867222663000092],
              [107.27108183400001, 20.86667911100006],
              [107.27200467100008, 20.865409762000105],
              [107.27287951800008, 20.864049366000081],
              [107.27302566200009, 20.863731747000088],
              [107.27312399700006, 20.863277647000089],
              [107.27307771200005, 20.862732178000073],
              [107.27279169200008, 20.861686053000113],
              [107.27245599500003, 20.861003304000022],
              [107.27187785600006, 20.860546964000015],
              [107.2713953410001, 20.860363606000121],
              [107.26951192700005, 20.86008472500005],
              [107.26574338500009, 20.859981345000023],
            ],
          ],
          [
            [
              [107.3206473610001, 20.864426427000105],
              [107.31993183600005, 20.864356767000025],
              [107.31871432900003, 20.864555397000025],
              [107.31735225000004, 20.865158334000085],
              [107.31663458800008, 20.865695801000058],
              [107.31663175300011, 20.866505324000073],
              [107.31691522600003, 20.867315733000069],
              [107.31791448400003, 20.86812834200007],
              [107.31898721200005, 20.868401498000111],
              [107.32034766400008, 20.868270766000037],
              [107.32128099600004, 20.867464106000085],
              [107.32157011900006, 20.866655472000062],
              [107.32157293300003, 20.865845949000047],
              [107.32128967800004, 20.864968088000069],
              [107.3206473610001, 20.864426427000105],
            ],
          ],
          [
            [
              [107.16607882100001, 20.863400695000053],
              [107.16553537300007, 20.863244791000035],
              [107.16357572000003, 20.863442507000052],
              [107.16194120900005, 20.863949132000045],
              [107.16025116700011, 20.864711949000103],
              [107.15932286400005, 20.865477652000038],
              [107.15893911900008, 20.8661428610001],
              [107.158882276, 20.866706750000041],
              [107.15904307700006, 20.867271468000048],
              [107.159475942, 20.867837220000048],
              [107.15996387400006, 20.86824933000004],
              [107.16077895500008, 20.868508842000018],
              [107.16219410100011, 20.868411651000059],
              [107.16437431400001, 20.867548116000108],
              [107.16601257500012, 20.866169684000013],
              [107.16672451900006, 20.865095445000037],
              [107.16672670900006, 20.864582633000062],
              [107.16645794500002, 20.86381238300001],
              [107.16607882100001, 20.863400695000053],
            ],
          ],
          [
            [
              [107.28017751600004, 20.863618848000073],
              [107.27853464, 20.863613487000073],
              [107.2778573240001, 20.863838481000037],
              [107.27722748100003, 20.864290853000078],
              [107.27649880200005, 20.865333647000028],
              [107.27630282000008, 20.86606008400009],
              [107.27654223300009, 20.86665162300001],
              [107.27731266900005, 20.86738122400007],
              [107.27871163, 20.868021991000063],
              [107.2795803920001, 20.868297483000028],
              [107.2808362370001, 20.868437908000104],
              [107.28175401000001, 20.868531776000054],
              [107.28305901100006, 20.868445134000076],
              [107.28402710400006, 20.867993845000044],
              [107.28436767699999, 20.867358754000072],
              [107.28432286000002, 20.866404315000061],
              [107.28345676000004, 20.865401771000066],
              [107.28205931700005, 20.864352054000079],
              [107.28017751600004, 20.863618848000073],
            ],
          ],
          [
            [
              [107.257711887, 20.862726511000048],
              [107.25693877400008, 20.862723912000014],
              [107.25645401700002, 20.863131263000056],
              [107.25601602500002, 20.86394776300002],
              [107.255427512, 20.866217913000092],
              [107.25532358400002, 20.868126149000062],
              [107.25551392500005, 20.868899308],
              [107.25599609900006, 20.869173588000045],
              [107.25773532000008, 20.869270322000055],
              [107.25865342200011, 20.86927340600004],
              [107.25923430600002, 20.869002699000113],
              [107.25967058200007, 20.868640624000086],
              [107.25992767700002, 20.868161363000048],
              [107.26025559800007, 20.867279312000072],
              [107.26021072700009, 20.86637031],
              [107.25997257000009, 20.865460667000058],
              [107.25968609900011, 20.864550852],
              [107.25915716800004, 20.863867438000035],
              [107.25819404900002, 20.863000790000093],
              [107.257711887, 20.862726511000048],
            ],
          ],
          [
            [
              [107.24025770700011, 20.865439190000053],
              [107.23987114500001, 20.865437860000036],
              [107.23890367200008, 20.865707186000023],
              [107.23783849800004, 20.866248827000078],
              [107.23725651700003, 20.866792122000085],
              [107.23681914700009, 20.867426807000108],
              [107.23681719200002, 20.867926672000081],
              [107.23705487800005, 20.868927226],
              [107.23758304500005, 20.869792458000092],
              [107.23782358800005, 20.870065934000067],
              [107.23820998100005, 20.870112717000033],
              [107.23893461900006, 20.870160657],
              [107.23975697400007, 20.869936274000089],
              [107.24116076900005, 20.869304909000071],
              [107.24213125700005, 20.868263068000111],
              [107.242521, 20.867446436000044],
              [107.24252364600001, 20.866764808000049],
              [107.24233212300007, 20.866309729000051],
              [107.24199528900007, 20.865945033000074],
              [107.241368546, 20.865579342000018],
              [107.24078905200003, 20.86548645900006],
              [107.24025770700011, 20.865439190000053],
            ],
          ],
          [
            [
              [107.02511711700008, 20.865381730000095],
              [107.02443057600003, 20.865378698000114],
              [107.02360316300005, 20.866085276000042],
              [107.02304778600011, 20.867309578000089],
              [107.02262939000003, 20.868599049000025],
              [107.02269351700001, 20.869503253000055],
              [107.02310221800008, 20.870150725000066],
              [107.02392512800004, 20.870348062000055],
              [107.02502458900005, 20.870159219000058],
              [107.02647184600008, 20.8690679750001],
              [107.02750779100009, 20.867845784000046],
              [107.02764993700004, 20.86687792],
              [107.02724250600008, 20.865972202000101],
              [107.02614661500009, 20.865450838000044],
              [107.02511711700008, 20.865381730000095],
            ],
          ],
          [
            [
              [107.19429656500009, 20.866627327000039],
              [107.19301668200006, 20.866622654000096],
              [107.19235073600004, 20.866716722000064],
              [107.19173499000007, 20.867052235000116],
              [107.19137401700004, 20.867678175000037],
              [107.19126982500003, 20.868112051000054],
              [107.19126642500002, 20.868932311000066],
              [107.19177558900004, 20.869609694000069],
              [107.19299971900008, 20.870723944000069],
              [107.19381707100003, 20.871161184000094],
              [107.19463621800004, 20.871164172000078],
              [107.1953045560001, 20.870491091000076],
              [107.19525714600007, 20.869574155000059],
              [107.19485394700011, 20.868028644000077],
              [107.19460154700002, 20.867159208000082],
              [107.19429656500009, 20.866627327000039],
            ],
          ],
          [
            [
              [107.21460712800007, 20.863486348000038],
              [107.21426889600006, 20.863485145000112],
              [107.21354244800008, 20.863891542000076],
              [107.21256981100008, 20.865433131000088],
              [107.21159772000003, 20.866838382000097],
              [107.21081980300005, 20.868017112000103],
              [107.21047862100008, 20.86874297000012],
              [107.21028367700011, 20.869151253000062],
              [107.21037811500001, 20.869696902],
              [107.21095632099998, 20.870107947000079],
              [107.21249967000006, 20.87084053900012],
              [107.213512409, 20.871344007000033],
              [107.21423613900002, 20.871619238000072],
              [107.21486487200008, 20.87148515100008],
              [107.21530140800004, 20.871077715000027],
              [107.21564514400006, 20.869715669000044],
              [107.21536617300008, 20.866988134000053],
              [107.21508575700005, 20.86462411400003],
              [107.21499222300005, 20.863851258000032],
              [107.21460712800007, 20.863486348000038],
            ],
          ],
          [
            [
              [106.89479889500008, 20.853935436000093],
              [106.89492772200008, 20.853402479000046],
              [106.89493745900009, 20.851683132000076],
              [106.89487925700007, 20.850852792000026],
              [106.89450622400003, 20.850080159000051],
              [106.892816345, 20.848589460000078],
              [106.89162530800004, 20.847872],
              [106.88936770100003, 20.846674865000026],
              [106.88804952800002, 20.846193916000018],
              [106.8873579940001, 20.846131140000068],
              [106.88452248400006, 20.847006183000083],
              [106.87979386300005, 20.848938809000025],
              [106.87871946500006, 20.849822700000061],
              [106.87814826100005, 20.850709142000042],
              [106.87807888300009, 20.851835292000054],
              [106.87826314900005, 20.852606992000041],
              [106.87876329000005, 20.853143135],
              [106.87932666800003, 20.853620306000039],
              [106.88089919100003, 20.853628280000081],
              [106.88177776300006, 20.853549967000085],
              [106.88490416800002, 20.85726517100008],
              [106.88603031200003, 20.858338061000033],
              [106.88741111900005, 20.85887862],
              [106.8901135620001, 20.859307241000025],
              [106.89086602600001, 20.859726045000038],
              [106.89106054800003, 20.86017278800005],
              [106.89127135800007, 20.860350610000111],
              [106.8909208430001, 20.861149245000021],
              [106.88984811500008, 20.861736733000043],
              [106.88631673800009, 20.863260457000088],
              [106.8851800620001, 20.864025473000055],
              [106.88473397100006, 20.865031107000043],
              [106.88485199000004, 20.866395334000032],
              [106.88534743400001, 20.867761445],
              [106.88632853800001, 20.872212975000082],
              [106.88676043200005, 20.87369733900006],
              [106.88688454900009, 20.873994398000036],
              [106.88745037300001, 20.87405653900003],
              [106.88801654500006, 20.874059390000085],
              [106.88864696700008, 20.873825411],
              [106.8900363230001, 20.872883806000075],
              [106.89073369199998, 20.871938709000034],
              [106.89178898900008, 20.868890699000083],
              [106.89281667300003, 20.865160715000016],
              [106.89370641000001, 20.861920724000079],
              [106.89383578800006, 20.861695996000051],
              [106.89466878000006, 20.860249056000058],
              [106.89555646300005, 20.859008436000082],
              [106.89600215000007, 20.858062051000026],
              [106.89600416300006, 20.857706329000081],
              [106.89575792500003, 20.856756474000093],
              [106.89451064200003, 20.854852981000072],
              [106.8941551720001, 20.854432958000046],
              [106.89479889500008, 20.853935436000093],
            ],
          ],
          [
            [
              [107.14691465100003, 20.872224945000092],
              [107.14841850500002, 20.870512807000097],
              [107.15016953300004, 20.868263148000032],
              [107.15055487400004, 20.867238985000114],
              [107.15055710700004, 20.866726175000046],
              [107.14968965700004, 20.866004881000052],
              [107.14833408000005, 20.864922732000046],
              [107.14792700800004, 20.864690395000096],
              [107.14700246800001, 20.864584262000015],
              [107.14656538400008, 20.86499283000007],
              [107.14590639300009, 20.866374901000114],
              [107.14502570500005, 20.868679189000083],
              [107.14442155200007, 20.869958896000092],
              [107.14365753200009, 20.870468761],
              [107.14246023200006, 20.870515392000044],
              [107.14126473800009, 20.870151781000068],
              [107.13871188700007, 20.869116199000089],
              [107.13778755800007, 20.868958743000029],
              [107.13718857200007, 20.869058967000065],
              [107.13680610600004, 20.869416449000063],
              [107.13680429100006, 20.869826695000079],
              [107.1370203490001, 20.8701865150001],
              [107.13783246100004, 20.871112764000088],
              [107.13940612400005, 20.872093255000031],
              [107.14184838800006, 20.873538645000075],
              [107.14315227900003, 20.874005244000045],
              [107.14407708699999, 20.874060106000059],
              [107.14527709400006, 20.873398091000084],
              [107.14691465100003, 20.872224945000092],
            ],
          ],
          [
            [
              [107.26444906300003, 20.870065308000015],
              [107.26328969300002, 20.86997055300008],
              [107.26135545200003, 20.870327639000052],
              [107.25913025200003, 20.870956369000027],
              [107.25724328400005, 20.871586223000094],
              [107.25651620300002, 20.872174526],
              [107.25617656000007, 20.872536919000062],
              [107.25617517400005, 20.872900459000029],
              [107.25641384100007, 20.873673774000068],
              [107.25689464400007, 20.874311581000079],
              [107.25785833200004, 20.875041890000112],
              [107.25872727799999, 20.875272020000054],
              [107.25983852200002, 20.87532118700004],
              [107.26099896400011, 20.875143301000065],
              [107.263225064, 20.874287347000028],
              [107.26467885500003, 20.873201577000074],
              [107.265455774, 20.872204437000086],
              [107.26565316100009, 20.871114484000053],
              [107.26541326300004, 20.870659268000047],
              [107.26512453400005, 20.870340211000027],
              [107.26444906300003, 20.870065308000015],
            ],
          ],
          [
            [
              [107.11710242, 20.86991790900008],
              [107.11650563400009, 20.869915522000056],
              [107.11579940700001, 20.870117227000051],
              [107.11492809500004, 20.870829593000018],
              [107.11421885400006, 20.871696002000114],
              [107.11383349300007, 20.872921634000093],
              [107.11377436700006, 20.873995175000104],
              [107.11415066700002, 20.874763668000092],
              [107.11479963900007, 20.875226460000107],
              [107.1156663360001, 20.875536724000106],
              [107.11642637000001, 20.875437502000068],
              [107.11740666800003, 20.874623306000025],
              [107.11795430400005, 20.873500584000034],
              [107.11812191400006, 20.872427474000062],
              [107.11817940700011, 20.871711849000093],
              [107.11818264300003, 20.870996008000105],
              [107.11780540899998, 20.870432043000072],
              [107.11710242, 20.86991790900008],
            ],
          ],
          [
            [
              [107.29885610000008, 20.869586432000098],
              [107.29846969300009, 20.869539764000052],
              [107.29784118800004, 20.869628649000035],
              [107.29692143700007, 20.870080152000092],
              [107.29629014300005, 20.870941538000039],
              [107.29604444500006, 20.872076813],
              [107.29599152600012, 20.873349023000017],
              [107.29618039400003, 20.874576552000065],
              [107.29699827100002, 20.875578887000046],
              [107.29745660100011, 20.875784837000118],
              [107.297891185, 20.875877100000118],
              [107.29851987400006, 20.875742775000013],
              [107.29919802300005, 20.875290513000074],
              [107.29970941300007, 20.874178794000017],
              [107.29990661200006, 20.87308881400007],
              [107.30000717100002, 20.871998517000101],
              [107.29972083000004, 20.870997882],
              [107.29933768400005, 20.870042380000072],
              [107.29885610000008, 20.869586432000098],
            ],
          ],
          [
            [
              [107.04395786800004, 20.866464877000013],
              [107.04306725000006, 20.866073620000058],
              [107.04121230400007, 20.866323842000064],
              [107.04011065600004, 20.866964723000066],
              [107.03887009200007, 20.867927818000084],
              [107.03763174500001, 20.868438957000102],
              [107.03673667800004, 20.868951578000058],
              [107.03597764100002, 20.869723063000031],
              [107.03541944900012, 20.871528472000108],
              [107.03520071000004, 20.874110139000024],
              [107.03519624000003, 20.875014032000117],
              [107.03594797300005, 20.875727538],
              [107.03786594200002, 20.876639811000075],
              [107.03910083400007, 20.876838883000026],
              [107.04109288100004, 20.876653843000078],
              [107.04274163500004, 20.876467298000101],
              [107.0436376800001, 20.875760966000101],
              [107.0440562700001, 20.874406906000061],
              [107.04434038600007, 20.872471167000036],
              [107.04496967200001, 20.870149525000059],
              [107.04532053300008, 20.868601458000043],
              [107.04491271000009, 20.867760331000085],
              [107.04395786800004, 20.866464877000013],
            ],
          ],
          [
            [
              [107.0161744700001, 20.868828657000016],
              [107.01548823300007, 20.868761032000101],
              [107.01418345700003, 20.868819779000077],
              [107.012809035, 20.869071906000066],
              [107.01239514200003, 20.869457454000084],
              [107.01232321800003, 20.8701027870001],
              [107.01232027700006, 20.87068387100004],
              [107.01273025200005, 20.871073101],
              [107.01368621500011, 20.872110422000034],
              [107.01436626600005, 20.873404769000011],
              [107.01497602900007, 20.875021628000034],
              [107.01524349000007, 20.876443258000066],
              [107.01565250600008, 20.877026163000068],
              [107.01640710500007, 20.877158656000105],
              [107.01730096200005, 20.876904372000027],
              [107.01833636600001, 20.875811368000093],
              [107.01965092000006, 20.873815684000085],
              [107.01972347000003, 20.87304121400004],
              [107.01958972500006, 20.872330403000113],
              [107.01863470400009, 20.871099414000049],
              [107.01767936, 20.869932974000079],
              [107.01699605600004, 20.869284280000052],
              [107.0161744700001, 20.868828657000016],
            ],
          ],
          [
            [
              [107.30451210299999, 20.872943679000052],
              [107.30315208100009, 20.872939393000053],
              [107.30222107000006, 20.873071376000048],
              [107.30186219900003, 20.873340086000077],
              [107.30164553000004, 20.873879080000087],
              [107.301356314, 20.874687678000022],
              [107.30185302700008, 20.875903525000084],
              [107.30292288300004, 20.876986256000052],
              [107.30406746000006, 20.877192243000039],
              [107.30485533800007, 20.877059811],
              [107.30607677700004, 20.875781913000054],
              [107.30608013600006, 20.874837490000075],
              [107.30594009600001, 20.873960072000038],
              [107.30565569800002, 20.873419500000075],
              [107.30451210299999, 20.872943679000052],
            ],
          ],
          [
            [
              [107.06317286100006, 20.868290512000073],
              [107.06234961000007, 20.868157885000116],
              [107.06070094500008, 20.868344579000016],
              [107.05877547600001, 20.868982047000095],
              [107.05801746100009, 20.869559903000031],
              [107.05697856800002, 20.871427881000045],
              [107.055798602, 20.874070028000077],
              [107.05517411100001, 20.875423230000045],
              [107.0563291230001, 20.877946207000072],
              [107.05701227600005, 20.878659345000102],
              [107.05783681500006, 20.878533732000015],
              [107.05942406800003, 20.876861804000058],
              [107.06032191100007, 20.875768019000063],
              [107.06115016400003, 20.874867630000082],
              [107.061907569, 20.874418887000076],
              [107.06479180500007, 20.874301986000084],
              [107.06582322000006, 20.873983522000024],
              [107.06603135400005, 20.873532441000094],
              [107.06576229100008, 20.87236912500012],
              [107.06474295500003, 20.87016958100012],
              [107.06440370000011, 20.869328782000096],
              [107.06378889900004, 20.868680522000105],
              [107.06317286100006, 20.868290512000073],
            ],
          ],
          [
            [
              [107.01248797900008, 20.864679965000029],
              [107.01138950800005, 20.864675050000066],
              [107.00925893500008, 20.86511747200008],
              [107.00712703900007, 20.865818124000057],
              [107.00602461500007, 20.866587966000068],
              [107.00419389900003, 20.868807254000018],
              [107.00315548400009, 20.870481287000061],
              [107.00239432799999, 20.871640034000016],
              [107.00218307600001, 20.872672128000055],
              [107.00218010400003, 20.873253209],
              [107.00224678000004, 20.873640898000097],
              [107.00251975800006, 20.873964960000087],
              [107.0035861050001, 20.874610626000042],
              [107.00505207100008, 20.875573219000017],
              [107.00604068800007, 20.876540519000031],
              [107.00734121800012, 20.877699189000062],
              [107.00823148000006, 20.878155147000044],
              [107.01028863500009, 20.878680889000023],
              [107.01179880100008, 20.878752215000063],
              [107.01241739300011, 20.878625851],
              [107.01317655700004, 20.877854467000034],
              [107.01338808300004, 20.876757806000064],
              [107.01318798700001, 20.875594730000024],
              [107.01216760200003, 20.873717777000067],
              [107.01080427200003, 20.871774703000057],
              [107.01033121000006, 20.870287569000112],
              [107.01054503600008, 20.868738943000054],
              [107.01179096700005, 20.866742975000108],
              [107.01268740600005, 20.865972184000015],
              [107.01289728800002, 20.865198329000023],
              [107.01248797900008, 20.864679965000029],
            ],
          ],
          [
            [
              [107.18710916600004, 20.871316005000025],
              [107.18535358800008, 20.871309536000027],
              [107.18453775600008, 20.871483800000057],
              [107.18283744000009, 20.873250263000017],
              [107.18145134800007, 20.874840600000063],
              [107.18094676600006, 20.875547821000048],
              [107.18088158600008, 20.876138498000074],
              [107.18181591000004, 20.877619241000019],
              [107.18294007900005, 20.878687035],
              [107.18381641800009, 20.879044819000022],
              [107.184506637, 20.878929187000047],
              [107.18507366700001, 20.878281283],
              [107.18646320400006, 20.875863672000072],
              [107.18772731000011, 20.873445573000105],
              [107.18785614900003, 20.872618770000081],
              [107.18760854800006, 20.871849664000038],
              [107.18742168700005, 20.871553520000049],
              [107.18710916600004, 20.871316005000025],
            ],
          ],
          [
            [
              [107.13630686100004, 20.871568342000032],
              [107.13576273200005, 20.871566214000033],
              [107.1348896320001, 20.872126888000061],
              [107.13461415000003, 20.872895035000028],
              [107.13460550700003, 20.874843709000054],
              [107.13453790100004, 20.877817758000013],
              [107.13458640100005, 20.87915126100004],
              [107.13501990400005, 20.879563215000104],
              [107.13550918600002, 20.879667692000048],
              [107.13670881200007, 20.879108290000126],
              [107.13900056400007, 20.877681360000011],
              [107.13976621700009, 20.876812561000065],
              [107.14004144800003, 20.876095688000085],
              [107.13988250400003, 20.875120723000059],
              [107.13912569200002, 20.873989586000086],
              [107.13809749800004, 20.872703529000077],
              [107.13685008200012, 20.871775592000041],
              [107.13630686100004, 20.871568342000032],
            ],
          ],
          [
            [
              [107.23297714500008, 20.87368453000007],
              [107.23244578000008, 20.873637244000065],
              [107.23167208700001, 20.87377088500002],
              [107.23094581700005, 20.874131893000047],
              [107.23026661700007, 20.874811156000113],
              [107.22939196300008, 20.876035040000126],
              [107.228903524, 20.877351148000045],
              [107.22890083000004, 20.878032764000039],
              [107.229188084, 20.878715392000032],
              [107.23000671100006, 20.879445311000033],
              [107.23077845700006, 20.879811532000048],
              [107.23111618900002, 20.879949038000056],
              [107.23184087100003, 20.879997],
              [107.23242182500005, 20.879726368000014],
              [107.23310140200005, 20.878956224000063],
              [107.23417022600003, 20.877505793000047],
              [107.23499830900006, 20.875827316000084],
              [107.23524259600002, 20.875146534000066],
              [107.23524402100006, 20.874783005],
              [107.23490755100002, 20.874327414000057],
              [107.2343289250001, 20.874007315000043],
              [107.23370144800005, 20.873823376000097],
              [107.23297714500008, 20.87368453000007],
            ],
          ],
          [
            [
              [107.28030849600005, 20.876049971000029],
              [107.27950426900003, 20.87597843000006],
              [107.27788464000004, 20.876410969000055],
              [107.27693031500004, 20.877372776000058],
              [107.27648895100003, 20.878060571000034],
              [107.27633910600007, 20.879025016000071],
              [107.27662908500008, 20.87971519600007],
              [107.27699374800005, 20.879992085000062],
              [107.27765094700007, 20.880269930000047],
              [107.27823553000003, 20.880409688000064],
              [107.27904028400009, 20.88034339700009],
              [107.28057894100004, 20.879590248000035],
              [107.28138825100004, 20.878283341000078],
              [107.281465197, 20.877249736000088],
              [107.2811754480001, 20.876490623000073],
              [107.28030849600005, 20.876049971000029],
            ],
          ],
          [
            [
              [106.93538638400001, 20.87515182300006],
              [106.93430912200004, 20.874435761000051],
              [106.9336281920001, 20.874811605000062],
              [106.93241537700004, 20.875895751000058],
              [106.93218835600008, 20.876218413000025],
              [106.93217029500001, 20.876573984000046],
              [106.93225933200009, 20.876842832000087],
              [106.93335344900002, 20.879075511000075],
              [106.933800852, 20.880025492000073],
              [106.93403294000002, 20.880180695000035],
              [106.93430242300012, 20.880264872000076],
              [106.93550802500003, 20.880507645000087],
              [106.93601089200007, 20.880510068000113],
              [106.93641422000005, 20.88032244300004],
              [106.93788390000006, 20.878244294000048],
              [106.93797769300001, 20.877874064000054],
              [106.93790710200003, 20.877568350000075],
              [106.93773794200007, 20.877343160000095],
              [106.93628688000008, 20.876009214000035],
              [106.93538638400001, 20.87515182300006],
            ],
          ],
          [
            [
              [107.16808908400007, 20.876445233000048],
              [107.16746207700007, 20.87644287100003],
              [107.16677135200011, 20.876676630000098],
              [107.16645633400007, 20.877029986000117],
              [107.16626570200002, 20.877620184000101],
              [107.16626090400007, 20.87874289000009],
              [107.16694356300006, 20.880400012000067],
              [107.16769145500004, 20.881466469000109],
              [107.16900468800007, 20.882298678000041],
              [107.16994598100005, 20.882124947000058],
              [107.17070218400001, 20.881241431000049],
              [107.17108367800004, 20.880001953000082],
              [107.17102473400006, 20.879115364000086],
              [107.17052738900003, 20.878108955000037],
              [107.16890268600007, 20.876802841000064],
              [107.16808908400007, 20.876445233000048],
            ],
          ],
          [
            [
              [106.965213261, 20.877401793000061],
              [106.96483865500009, 20.87725853000002],
              [106.96442463800003, 20.877468842000013],
              [106.96310598700009, 20.878382419000019],
              [106.96205064300007, 20.879191094000049],
              [106.96061936400004, 20.880104126],
              [106.95918977000007, 20.880698772000095],
              [106.95836246300009, 20.880977868000095],
              [106.95791012900006, 20.881329493000052],
              [106.95794558600005, 20.881718786000114],
              [106.95820683400009, 20.882003014000091],
              [106.95918146600009, 20.882255237],
              [106.96387339600008, 20.882312672000047],
              [106.96439910200004, 20.882279760000074],
              [106.96466279000002, 20.882104122000023],
              [106.96504867300007, 20.880124923000039],
              [106.96539512900004, 20.878499275000095],
              [106.9653990680001, 20.877756414000089],
              [106.965213261, 20.877401793000061],
            ],
          ],
          [
            [
              [107.21652568100009, 20.873058787000076],
              [107.21594581100008, 20.873056729000083],
              [107.21502623000002, 20.873416998000046],
              [107.21425087900008, 20.873959549000084],
              [107.21357107200006, 20.874775082000063],
              [107.21298826400006, 20.875500084000095],
              [107.21269411300005, 20.876544197000065],
              [107.21293004600004, 20.877953731000076],
              [107.21321540300002, 20.879090795000096],
              [107.21369333000004, 20.88041030400003],
              [107.21446248800001, 20.881412753000106],
              [107.21523348100003, 20.881960786000064],
              [107.21615018100002, 20.882327580000037],
              [107.21648863800004, 20.882283332000014],
              [107.2171666350001, 20.881922205000066],
              [107.21804084600005, 20.880834709000062],
              [107.21867269900011, 20.879928111000098],
              [107.21867524700005, 20.87929193700004],
              [107.21863184100002, 20.87806485600003],
              [107.21844545900004, 20.876337410000097],
              [107.21820821400004, 20.87524596800003],
              [107.21753625500004, 20.874107535000071],
              [107.217007446, 20.873424031000098],
              [107.21652568100009, 20.873058787000076],
            ],
          ],
          [
            [
              [107.03771174000006, 20.880061089],
              [107.03771587900005, 20.879221758000099],
              [107.03751308700006, 20.878575230000074],
              [107.03682841200008, 20.878184853000015],
              [107.03614246400008, 20.87805273],
              [107.03359696200005, 20.879074645000095],
              [107.03270311500007, 20.87932899400009],
              [107.03187983800008, 20.879196256000029],
              [107.03078642700002, 20.878158424000048],
              [107.02935261700006, 20.876537998000089],
              [107.0286682870001, 20.876083030000103],
              [107.02763905300003, 20.875949370000058],
              [107.02715555100008, 20.876528332000085],
              [107.02708174200009, 20.877561047000093],
              [107.02734511000004, 20.879821988000025],
              [107.02801787100002, 20.882601233000031],
              [107.02835667900007, 20.88350662400007],
              [107.02897237400003, 20.883961285000026],
              [107.02958935700011, 20.884157691000027],
              [107.0313058840001, 20.884165225000025],
              [107.03308947200006, 20.884495855000026],
              [107.03459906100009, 20.884696152000082],
              [107.03556127100003, 20.884506661000032],
              [107.03694501500004, 20.882382074000105],
              [107.03771174000006, 20.880061089],
            ],
          ],
          [
            [
              [106.97026133800006, 20.875784302000078],
              [106.96967126500009, 20.875781550000028],
              [106.96896082600001, 20.876223122000056],
              [106.96730066000005, 20.877716882000108],
              [106.96706197900002, 20.878216274000039],
              [106.96706050700007, 20.878494322000037],
              [106.96758422300007, 20.879887055000076],
              [106.96890939600011, 20.885954845000043],
              [106.96952739400008, 20.886263587000094],
              [106.97005848900007, 20.886266064000033],
              [106.97053233300001, 20.885934609000053],
              [106.97118731300003, 20.884825442000121],
              [106.97227146500002, 20.880659668000057],
              [106.97239505700003, 20.879603628000076],
              [106.972077977, 20.878184061000034],
              [106.97108393100009, 20.8764554750001],
              [106.97055608100003, 20.875841289000086],
              [106.97026133800006, 20.875784302000078],
            ],
          ],
          [
            [
              [107.20673193100004, 20.87963057899999],
              [107.20648160200003, 20.879511499],
              [107.20547788600003, 20.879626072000065],
              [107.20384546200005, 20.880152018000061],
              [107.20277759200006, 20.880620890000046],
              [107.20258755000006, 20.881092928000022],
              [107.20301054000007, 20.88499442800007],
              [107.20356836100004, 20.886591878000083],
              [107.20381797300008, 20.886888227],
              [107.20444477300008, 20.886949576000099],
              [107.20494857700001, 20.886419581000034],
              [107.20564602700009, 20.884531201000108],
              [107.20640832700009, 20.882111233000074],
              [107.20685252600008, 20.880812831000028],
              [107.20685516900002, 20.880162846000069],
              [107.20673193100004, 20.87963057899999],
            ],
          ],
          [
            [
              [107.20103151900001, 20.880592069000059],
              [107.20151735500006, 20.879957643000054],
              [107.20219556800005, 20.87955112200008],
              [107.20388949200004, 20.878921049],
              [107.20611497100006, 20.878292875000071],
              [107.20713197900001, 20.877751233000104],
              [107.20800401300005, 20.877209057000101],
              [107.20950625900008, 20.876169278000042],
              [107.21052472700006, 20.875264080000107],
              [107.2114707760001, 20.874335906000049],
              [107.21171477600004, 20.873746038],
              [107.21171697900004, 20.873200738],
              [107.21147793800006, 20.872563695000053],
              [107.21080399600008, 20.871925097000066],
              [107.20966908100006, 20.87176198700007],
              [107.20826848600008, 20.871575198000066],
              [107.206868817, 20.87116119800001],
              [107.20595329700009, 20.870521716000034],
              [107.20556876000008, 20.870020464000035],
              [107.20566872600008, 20.869202866000066],
              [107.20620488800004, 20.868068727000022],
              [107.20606233100006, 20.867477461000036],
              [107.20485912200007, 20.866291631000067],
              [107.20375108700006, 20.865469668000088],
              [107.20283318900005, 20.865420915000051],
              [107.20201043900001, 20.865736043000105],
              [107.20186436400009, 20.866008172000058],
              [107.20205579200002, 20.866463287000059],
              [107.20282576300005, 20.867238597000082],
              [107.20345040800004, 20.868104252000116],
              [107.20354464100002, 20.868695345000084],
              [107.20344465700001, 20.869512953000054],
              [107.20314879400004, 20.870966036000112],
              [107.20299992300008, 20.87191978600007],
              [107.20256280100006, 20.872463518000117],
              [107.20212753600001, 20.872552827000042],
              [107.2014523230001, 20.87223229199999],
              [107.2006321580001, 20.871911230000073],
              [107.19927932900008, 20.871860888000079],
              [107.19753936600009, 20.871945456000063],
              [107.19381595800012, 20.872568088000065],
              [107.19231665700005, 20.872880706000082],
              [107.19178285700012, 20.873424061],
              [107.19112569900001, 20.874580423000033],
              [107.19054206400004, 20.875487127],
              [107.18893891000009, 20.877526137000046],
              [107.18738689000001, 20.878883684000101],
              [107.18569120600004, 20.879922595],
              [107.184723397, 20.880237117],
              [107.18361213500006, 20.880187572000075],
              [107.18206615300005, 20.880090972000076],
              [107.18129220900005, 20.880269866000042],
              [107.18095260300001, 20.880586699000055],
              [107.18080515200006, 20.881176894000042],
              [107.18080076400005, 20.882222031000097],
              [107.18094440100005, 20.882540654000081],
              [107.1815225830001, 20.88295176700008],
              [107.18243516100003, 20.883616143000069],
              [107.18397756900001, 20.884576115000094],
              [107.18537905800007, 20.885238090000065],
              [107.18619831600009, 20.885786407000047],
              [107.18725617800004, 20.886183271000021],
              [107.187911657, 20.886391851000049],
              [107.18873057000008, 20.886755651000044],
              [107.18965887500006, 20.887119858000069],
              [107.18998619000006, 20.887327218000081],
              [107.19049172500004, 20.887619832000048],
              [107.190974231, 20.887803359000046],
              [107.19131250500001, 20.887804597000056],
              [107.19203890000007, 20.887443728000044],
              [107.19378369800002, 20.886223191000042],
              [107.19572158200005, 20.885048789000038],
              [107.19719839500003, 20.88434981400011],
              [107.19908717300007, 20.883356962000029],
              [107.20025031700004, 20.882543227000028],
              [107.20073598400008, 20.881954253000053],
              [107.20098039800004, 20.881273507000039],
              [107.20103151900001, 20.880592069000059],
            ],
          ],
          [
            [
              [107.20037673700001, 20.885044],
              [107.19975042900006, 20.884864461000063],
              [107.19833825600001, 20.885184341000063],
              [107.19673578500003, 20.886035329000038],
              [107.19610727600008, 20.886387582000054],
              [107.19598016700004, 20.886800749000045],
              [107.19703860300004, 20.888636391000048],
              [107.19847304500007, 20.890532477000079],
              [107.19903739500003, 20.890534524000067],
              [107.200674034, 20.889004123000099],
              [107.20180756800001, 20.887826418000088],
              [107.20187413800001, 20.886881213000038],
              [107.20118898300007, 20.885756028000024],
              [107.20037673700001, 20.885044],
            ],
          ],
          [
            [
              [106.99298616200005, 20.886904365000092],
              [106.99284882800005, 20.885894589000117],
              [106.99239121800011, 20.885094752000022],
              [106.99177669100007, 20.884874377000081],
              [106.99062347100005, 20.884651549000033],
              [106.98854603800002, 20.884569522000085],
              [106.98770178700006, 20.884130513000088],
              [106.98739659800003, 20.883621457000068],
              [106.98732641800009, 20.882315723000119],
              [106.98733244100006, 20.881155376000038],
              [106.987567821, 20.880286174000062],
              [106.98818683300006, 20.879636302000023],
              [106.989036336, 20.879060006000124],
              [106.99050032900007, 20.878704089000074],
              [106.9926550390001, 20.878713926],
              [106.99365730000007, 20.878355873000132],
              [106.99412238800009, 20.877705281000125],
              [106.99474885200006, 20.87560494700007],
              [106.99507011, 20.87299554100008],
              [106.99499876200004, 20.871907358000016],
              [106.99469469300001, 20.871180731000024],
              [106.99408058100001, 20.870887834000129],
              [106.99277280200008, 20.870809349000027],
              [106.99015648500004, 20.870797403000019],
              [106.98754129700002, 20.870567854000029],
              [106.98315512800011, 20.87054770500005],
              [106.98045883200004, 20.871115478000043],
              [106.97822232900009, 20.872047952000017],
              [106.97767949000011, 20.872843208000091],
              [106.97767303000002, 20.874076094000046],
              [106.97789628800011, 20.875527605000066],
              [106.97843267800008, 20.875965226000034],
              [106.98019955100008, 20.876553579000046],
              [106.9818883450001, 20.877359131000084],
              [106.9834202330001, 20.878744133000012],
              [106.98380083700006, 20.879543643000083],
              [106.98364428400008, 20.88005059200011],
              [106.98325838800012, 20.880266380000108],
              [106.9824884650001, 20.880335359000043],
              [106.98056650600006, 20.879963885000038],
              [106.97995049200007, 20.880033565000033],
              [106.97933296000005, 20.880393325000043],
              [106.97886553900001, 20.881479017000082],
              [106.97636951599999, 20.887849467000038],
              [106.97628723100011, 20.888864403000085],
              [106.97643657100012, 20.889735363000028],
              [106.97735778900005, 20.890174759000054],
              [106.97951223500004, 20.890257246000132],
              [106.98059116900006, 20.889972135000079],
              [106.98090127500005, 20.889538437000077],
              [106.981138957, 20.888234134000072],
              [106.98183687500001, 20.887222034000082],
              [106.98276263000005, 20.886791168000052],
              [106.98391697699999, 20.886796481000047],
              [106.98483933000001, 20.887018283000103],
              [106.98522223000008, 20.887382659000053],
              [106.98521845700003, 20.888107866000013],
              [106.98506001500007, 20.888977407000105],
              [106.98505624200001, 20.889702612000072],
              [106.98520600900007, 20.89050104500005],
              [106.98589523300006, 20.891156907000052],
              [106.98781544100005, 20.891890934000031],
              [106.98873818900002, 20.892040205000036],
              [106.98966282500005, 20.891826868000059],
              [106.99120799700009, 20.89067358100003],
              [106.99257139500001, 20.889022722000036],
              [106.99291118100007, 20.887830240000049],
              [106.99298616200005, 20.886904365000092],
            ],
          ],
          [
            [
              [106.95364426100005, 20.88458509000013],
              [106.95187976100007, 20.884021504000124],
              [106.95076657700008, 20.884077903000083],
              [106.94938921800006, 20.884564893000054],
              [106.94833816500005, 20.885238496000014],
              [106.9482700450001, 20.885731699000054],
              [106.948529568, 20.886164770000093],
              [106.94905227300008, 20.886352333000069],
              [106.95095069200003, 20.886361357000013],
              [106.95186650400005, 20.886489089000101],
              [106.95251914000005, 20.886862327000074],
              [106.95277834300001, 20.887357082000065],
              [106.95290530100002, 20.888097977000086],
              [106.95290099700003, 20.888899941000091],
              [106.95216863500009, 20.891179021000106],
              [106.95196760900006, 20.892041739000078],
              [106.95183336000005, 20.892658007000087],
              [106.95202710700002, 20.893152448000052],
              [106.9524843610001, 20.89333969000009],
              [106.952811694, 20.893341242000041],
              [106.95504675700002, 20.891624495000102],
              [106.95853119900002, 20.888864865000031],
              [106.95905787000007, 20.888312131000042],
              [106.95919142500003, 20.887819235000023],
              [106.95899701800002, 20.887448168000056],
              [106.95749597700006, 20.886577420000037],
              [106.95364426100005, 20.88458509000013],
            ],
          ],
          [
            [
              [106.996347, 20.887485922000067],
              [106.99578242700004, 20.887352424000127],
              [106.99462882400007, 20.887739461000088],
              [106.99434882700008, 20.888134507000089],
              [106.99424479900009, 20.888460515000062],
              [106.99428691300002, 20.889424511000065],
              [106.994470108, 20.890760986000068],
              [106.99454124400002, 20.891133726000085],
              [106.99461074500009, 20.891381965000022],
              [106.99474808000008, 20.891753931000068],
              [106.99505550200011, 20.892423663000059],
              [106.996472156, 20.894399479000114],
              [106.99677196200003, 20.894761348000124],
              [106.99704062900005, 20.894871352000074],
              [106.99747219500006, 20.894959700000101],
              [106.99786860600008, 20.894892798000072],
              [106.99807525000003, 20.894726740000102],
              [106.99820395100002, 20.894373580000014],
              [106.99816732600002, 20.893267228000042],
              [106.99762701900008, 20.890477825000083],
              [106.99683645300003, 20.888276790000049],
              [106.996347, 20.887485922000067],
            ],
          ],
          [
            [
              [107.10669590800005, 20.891434901000046],
              [107.10523227200009, 20.891428977000089],
              [107.10445592300007, 20.891750358000039],
              [107.1039356200001, 20.892559556000023],
              [107.10375261800006, 20.89491161000003],
              [107.10409365500003, 20.895643166000077],
              [107.10486592500007, 20.896214216000075],
              [107.10632922500008, 20.896301271000084],
              [107.10762403200002, 20.895576333000051],
              [107.10823080100003, 20.894686339000046],
              [107.10823858900005, 20.892982627000031],
              [107.10815730899999, 20.891927587000048],
              [107.10772868900006, 20.891520206000088],
              [107.10669590800005, 20.891434901000046],
            ],
          ],
          [
            [
              [107.34189134000005, 20.889713175000054],
              [107.34397456600001, 20.888174389000099],
              [107.34508835500006, 20.887496081000023],
              [107.34651617600008, 20.886841406000123],
              [107.34772629800001, 20.886254257000033],
              [107.34859722100003, 20.885938740000043],
              [107.34903169100002, 20.886076340000059],
              [107.34936860900009, 20.886486299000047],
              [107.34975127500005, 20.887668895000019],
              [107.350372249, 20.889851886000073],
              [107.35099744700004, 20.891064275000112],
              [107.35160717700003, 20.891466131000058],
              [107.3521381250001, 20.891812766000051],
              [107.35280413800004, 20.891851006000103],
              [107.35345851100001, 20.891860330000078],
              [107.35502530900007, 20.891444040000096],
              [107.35641080800004, 20.890551172000116],
              [107.35733170500002, 20.889735915000074],
              [107.35859148, 20.888739879000084],
              [107.35989822100001, 20.8881529370001],
              [107.36062341200007, 20.888064158000041],
              [107.3617347630001, 20.88811281100007],
              [107.36279719000007, 20.888343086000113],
              [107.36448653600004, 20.888984125000043],
              [107.367429423, 20.890537560000055],
              [107.36865947100009, 20.891245408000088],
              [107.36957635600001, 20.891656990000037],
              [107.37112018700003, 20.892479326000043],
              [107.37194101199999, 20.892708858000027],
              [107.37310115400007, 20.892621274000064],
              [107.37411704400009, 20.8923060710001],
              [107.37571431300009, 20.891538104000063],
              [107.37653892400002, 20.890586178000078],
              [107.37687952600004, 20.889860080000041],
              [107.37693177700001, 20.888633333000058],
              [107.37688983100001, 20.886633806000084],
              [107.37732897300002, 20.885317254000043],
              [107.37834829300007, 20.883911453000081],
              [107.379899745, 20.882325369000029],
              [107.382078812, 20.880922796000064],
              [107.38333725300004, 20.880290133000031],
              [107.38391757700001, 20.88015542200008],
              [107.38430402500009, 20.88020194900011],
              [107.38454493000003, 20.880429821000043],
              [107.38468861100003, 20.880839201000079],
              [107.384638991, 20.881248033000062],
              [107.38444426500006, 20.881701901000049],
              [107.38332906700006, 20.882880268000022],
              [107.38109993600001, 20.884828005000067],
              [107.38032369300001, 20.885780092000061],
              [107.38002998900004, 20.886960729000045],
              [107.38002522400009, 20.888460269000042],
              [107.38045525800005, 20.890006465000077],
              [107.38103286400006, 20.89073513400006],
              [107.38209432900004, 20.891283396000034],
              [107.38344646800002, 20.891605259000109],
              [107.38417137400003, 20.891607281000098],
              [107.38484837700001, 20.891472840000077],
              [107.38518810000005, 20.891019383000042],
              [107.38509244700003, 20.890701032000081],
              [107.38465980400004, 20.889972778000043],
              [107.38449288200007, 20.889267973000081],
              [107.38459211700004, 20.888450310000017],
              [107.38483632800008, 20.887633059000073],
              [107.38537091700007, 20.886680287000026],
              [107.38634100700006, 20.885546953000045],
              [107.38726189900004, 20.884686135000052],
              [107.38813260800005, 20.884415900000072],
              [107.38866389800005, 20.884508251000078],
              [107.3892430920001, 20.884737061000088],
              [107.38986961500007, 20.885284089000052],
              [107.39049628300005, 20.885785666000075],
              [107.39083442200004, 20.885832039],
              [107.39156000600005, 20.885606839000026],
              [107.39223869100006, 20.884927083],
              [107.39262798000003, 20.884064778000067],
              [107.39277677299999, 20.8828382720001],
              [107.39278285400006, 20.880884312000077],
              [107.39283513400002, 20.879612094000066],
              [107.39264495600001, 20.878611858000085],
              [107.39255085300005, 20.877793646000065],
              [107.39223767000007, 20.877497413000064],
              [107.39151282600005, 20.877495417000077],
              [107.39039982400006, 20.877992201000062],
              [107.38817337500005, 20.879122090000024],
              [107.38739905400011, 20.879483475000079],
              [107.38681944900003, 20.879390977000064],
              [107.38571029700009, 20.878660832000051],
              [107.38474568300003, 20.878067405000067],
              [107.3837310320001, 20.87801913600007],
              [107.38286077600004, 20.878153037000047],
              [107.38131384800003, 20.878330471000069],
              [107.37904133700003, 20.878733066000073],
              [107.37652763200006, 20.87899863600002],
              [107.37464242200005, 20.879175077000056],
              [107.37290349200005, 20.87894293600003],
              [107.37184198300005, 20.878440067000078],
              [107.37107085700003, 20.877801690000105],
              [107.37083055800004, 20.877392024000052],
              [107.37088034300007, 20.876937752000032],
              [107.37122022000004, 20.876438857000053],
              [107.37252684100005, 20.87585183700007],
              [107.37453436300007, 20.875198616000112],
              [107.37753313600004, 20.874343688000074],
              [107.37937112400006, 20.873803564000056],
              [107.38200980200007, 20.872872697000041],
              [107.38393204700004, 20.871947155000022],
              [107.38630411700011, 20.870590497000038],
              [107.38820309000002, 20.869155458000051],
              [107.38919169600011, 20.867597759000134],
              [107.38927671900004, 20.866446924000037],
              [107.38891655400008, 20.865559688000076],
              [107.38843407000005, 20.865331146000088],
              [107.38716361399999, 20.865213253000015],
              [107.38589631600003, 20.865619473000052],
              [107.384555242, 20.866126859000111],
              [107.38259725600008, 20.867349210000086],
              [107.38109262600003, 20.868296769000061],
              [107.38019090400012, 20.868361381000078],
              [107.37917253500005, 20.867872798000064],
              [107.37852836400005, 20.866647156000084],
              [107.37812346000003, 20.865955373000041],
              [107.37760150300001, 20.865217465000093],
              [107.37697698400002, 20.864477170000036],
              [107.375418041, 20.863317959000049],
              [107.3721870480001, 20.861309317000028],
              [107.36983684600007, 20.860253211000042],
              [107.36832678900001, 20.859662366000094],
              [107.36673285900011, 20.859476025000042],
              [107.36455864800007, 20.859424339000029],
              [107.36342293600006, 20.859489231000069],
              [107.36250428500009, 20.859668348000056],
              [107.36056780600009, 20.860798828000071],
              [107.35824324500004, 20.862382586000102],
              [107.35756438, 20.863107694000057],
              [107.35751456700005, 20.863561973000074],
              [107.3578005950001, 20.864744317000117],
              [107.35779924900007, 20.865153294000024],
              [107.35750768400001, 20.865652318000016],
              [107.35678107700002, 20.866195517000016],
              [107.35557170800007, 20.866600972000079],
              [107.35421768300003, 20.866915114000051],
              [107.35305737400003, 20.867093483000062],
              [107.35262249100003, 20.867092209000049],
              [107.35214063900004, 20.866681816000018],
              [107.35161258700005, 20.865635091000101],
              [107.35084429900007, 20.864178666000029],
              [107.35007481100008, 20.86308578200002],
              [107.34964098800008, 20.862766405000066],
              [107.34925488500001, 20.862628943000061],
              [107.34886817600008, 20.862673245000082],
              [107.34780904800002, 20.863179553000052],
              [107.34684969900005, 20.86429183000007],
              [107.34556920800006, 20.866175156000018],
              [107.34460207800007, 20.868076147000103],
              [107.34398905300006, 20.869497897000052],
              [107.34340414600007, 20.870995756000084],
              [107.34306792300005, 20.872783481000035],
              [107.34244084100003, 20.875521968000044],
              [107.34181355800008, 20.877157837000127],
              [107.3413252340001, 20.878464758000078],
              [107.34064271800003, 20.880234938000086],
              [107.34015271300007, 20.882232905000109],
              [107.34000036300007, 20.884413624000061],
              [107.33984847000004, 20.886458026000106],
              [107.33931381200003, 20.887365241000033],
              [107.33863493399998, 20.888044823000129],
              [107.33781247200005, 20.888315006000099],
              [107.33747434100006, 20.888268552000078],
              [107.33718546800006, 20.887949599000066],
              [107.33677878900006, 20.886744190000073],
              [107.33639759400006, 20.885152606000098],
              [107.33582418400005, 20.88324235100005],
              [107.33568756600002, 20.880788103000022],
              [107.33593723100006, 20.87842589600006],
              [107.3359011300001, 20.874835889000032],
              [107.33571123800002, 20.873835596000042],
              [107.33542192500006, 20.873652954000121],
              [107.334891, 20.873469593000046],
              [107.33421448100007, 20.873467555000055],
              [107.33344070300009, 20.873646984000068],
              [107.33220622900009, 20.87430216300001],
              [107.33070464300002, 20.875342789000051],
              [107.32920194700003, 20.876701483000112],
              [107.32818324900003, 20.87783442500006],
              [107.32730653200005, 20.879831186000075],
              [107.32720659100005, 20.880785154000044],
              [107.32744492400005, 20.881740150000013],
              [107.32826128000009, 20.883242202000041],
              [107.32869260300008, 20.884288659000028],
              [107.32888231200005, 20.885334381000042],
              [107.32858719300006, 20.886833043000053],
              [107.32795363700004, 20.888376111000092],
              [107.32751494800007, 20.889465354000066],
              [107.32736668000005, 20.890419159000118],
              [107.327408893, 20.892191480000029],
              [107.32750100700007, 20.893509572000113],
              [107.32764222900003, 20.894600580000052],
              [107.32812283600001, 20.895374526000055],
              [107.32879677400008, 20.896149066000099],
              [107.32932728600009, 20.896468760000083],
              [107.32981041200001, 20.896515665000059],
              [107.33034233100005, 20.896426391000134],
              [107.332471255, 20.895705791000104],
              [107.33440669300009, 20.895030026000057],
              [107.33564130000009, 20.894374856000027],
              [107.33733723700009, 20.893062176000043],
              [107.33932358100003, 20.891614022000034],
              [107.34189134000005, 20.889713175000054],
            ],
          ],
          [
            [
              [106.97379029300001, 20.890690868000036],
              [106.97326375800006, 20.890532555000085],
              [106.97284746400007, 20.89078394700001],
              [106.97164192800007, 20.892178731000044],
              [106.97038776300005, 20.893795165000114],
              [106.969701431, 20.894789421000048],
              [106.96961791600003, 20.895161075000068],
              [106.96972708700008, 20.895488595000074],
              [106.97047027200003, 20.896170462],
              [106.97110514600007, 20.896590550000035],
              [106.97162955600002, 20.896584500000067],
              [106.97194248400007, 20.896449376000078],
              [106.97225326800003, 20.896149893000079],
              [106.97256103300001, 20.89562028900005],
              [106.97303826700005, 20.894372778000033],
              [106.97339985200004, 20.893249310000073],
              [106.97394023600008, 20.891461760000048],
              [106.97393421600002, 20.891001543000073],
              [106.97379029300001, 20.890690868000036],
            ],
          ],
          [
            [
              [107.01032944700009, 20.884169074000077],
              [107.00957810300011, 20.883390927000086],
              [107.00889182700007, 20.883323284000085],
              [107.00779063400009, 20.88383486000005],
              [107.00648443000001, 20.884151813000074],
              [107.00552317200007, 20.884147488000067],
              [107.00483821200005, 20.883821578000017],
              [107.00388553500001, 20.88213860300003],
              [107.00361715000001, 20.880910664000041],
              [107.00286519500006, 20.88026161800007],
              [107.00211192500008, 20.879870833000069],
              [107.00101204600003, 20.880124123000066],
              [107.00025348600005, 20.880766335000089],
              [106.99942459700003, 20.881731050000063],
              [106.99921530500004, 20.882375753000112],
              [106.99934699600001, 20.883473946000031],
              [106.99954205200007, 20.88560546100009],
              [106.99959449100007, 20.888769335000035],
              [106.99978955400003, 20.890900831000089],
              [106.99998761000008, 20.89245125800003],
              [107.00074026400009, 20.892971166000059],
              [107.00204092200006, 20.89375181300008],
              [107.00313426800003, 20.894789762000073],
              [107.00395099800001, 20.896213838000044],
              [107.00456568800007, 20.896862245000044],
              [107.0053883530001, 20.89712419600005],
              [107.00648700600004, 20.8971291390001],
              [107.00779395400009, 20.896683077000056],
              [107.00827789099999, 20.896039614000102],
              [107.00883442700004, 20.894621720000117],
              [107.00981015600003, 20.8917853],
              [107.01064754000004, 20.889141938000044],
              [107.01093495500001, 20.886625228000085],
              [107.01094084700006, 20.885463099000035],
              [107.01032944700009, 20.884169074000077],
            ],
          ],
          [
            [
              [106.96398336000003, 20.885374111000132],
              [106.96345966000004, 20.885371654000018],
              [106.96254187700004, 20.885614105000094],
              [106.9618191600001, 20.886104236000058],
              [106.96082965900004, 20.887518480000011],
              [106.96056451900003, 20.888134141000059],
              [106.96069249300005, 20.888689960000036],
              [106.96166854000008, 20.889804991000105],
              [106.96217410200008, 20.890633496000085],
              [106.96269857000004, 20.893079433000047],
              [106.96275387400009, 20.894992092000031],
              [106.96274600200005, 20.89647261300005],
              [106.96313486100007, 20.897214720000079],
              [106.96352537000006, 20.897648380000078],
              [106.96391783700003, 20.897711912000091],
              [106.96437676100007, 20.897590691000097],
              [106.96477446700003, 20.896667201000071],
              [106.96524680300011, 20.894016734000061],
              [106.96593105100007, 20.890155152000119],
              [106.96612858200007, 20.888221945000041],
              [106.9660849840001, 20.887593359000078],
              [106.96593807500003, 20.887110618000101],
              [106.96548376600001, 20.886368197000046],
              [106.96463634300005, 20.885685624000061],
              [106.96398336000003, 20.885374111000132],
            ],
          ],
          [
            [
              [107.46887539900004, 20.889852461000125],
              [107.46768404400005, 20.889849593000065],
              [107.46623543100002, 20.89056779800007],
              [107.46529672900006, 20.891527795000094],
              [107.46299314200006, 20.893687310000075],
              [107.46179602100004, 20.895769301000122],
              [107.46145164200006, 20.897211849000108],
              [107.46144965000008, 20.897933545000043],
              [107.46204423700007, 20.898335931000034],
              [107.46323520200011, 20.89849921000004],
              [107.46485320300005, 20.898102189000056],
              [107.4669008640001, 20.896182617000036],
              [107.46886688400005, 20.892979813000068],
              [107.46955224300009, 20.891297488000049],
              [107.46946954, 20.890415214000058],
              [107.46887539900004, 20.889852461000125],
            ],
          ],
          [
            [
              [107.15917265500005, 20.891356920000078],
              [107.15805341099998, 20.891352657000084],
              [107.15650298100005, 20.891509006000049],
              [107.15452066600008, 20.891988218000087],
              [107.15348399000001, 20.892795555000099],
              [107.1524416660001, 20.89490095700004],
              [107.1521766700001, 20.896441424000045],
              [107.15234322600003, 20.897740150000033],
              [107.15285594400009, 20.89863454700005],
              [107.153542977, 20.899042834000078],
              [107.15414673300005, 20.898801755000115],
              [107.15475188400002, 20.898236164000039],
              [107.15527515300006, 20.896696687000116],
              [107.15631184300004, 20.895889346000018],
              [107.15794910100006, 20.895571069000063],
              [107.16027549500008, 20.89517426800009],
              [107.16139685300004, 20.894691749000046],
              [107.16191657500006, 20.893963539000104],
              [107.16183257400004, 20.893476441000033],
              [107.16149062700008, 20.892907223000101],
              [107.16097753600008, 20.892093968000047],
              [107.1602047590001, 20.891604241000024],
              [107.15917265500005, 20.891356920000078],
            ],
          ],
          [
            [
              [107.34241224000006, 20.900052413000125],
              [107.34376591200007, 20.8999201130001],
              [107.34555377600003, 20.900016303000065],
              [107.34661747600011, 20.899883127000059],
              [107.34811779000007, 20.899251398000018],
              [107.35015199700007, 20.897939627000106],
              [107.35131474800009, 20.897079686],
              [107.35199345300003, 20.896445514000028],
              [107.35238354700006, 20.895401523000068],
              [107.35224082800006, 20.894719500000036],
              [107.35180724200008, 20.894309266000022],
              [107.35145562700002, 20.894150769000049],
              [107.35104434700001, 20.89372291100009],
              [107.35001125900007, 20.892908390000017],
              [107.34866045400003, 20.891057396000079],
              [107.348388576, 20.89052763600003],
              [107.34800393800006, 20.889935779],
              [107.34713664600008, 20.889160718000085],
              [107.34646145199999, 20.88874975600001],
              [107.34607484300005, 20.888748612000093],
              [107.34534857600001, 20.889155426000094],
              [107.34389497200009, 20.890287138000119],
              [107.34229544300008, 20.891691040000104],
              [107.34127751100006, 20.892596821],
              [107.34069482700008, 20.893413008000024],
              [107.33972181800003, 20.895318602000046],
              [107.33860272800007, 20.897541831000055],
              [107.33758119800011, 20.899492697000085],
              [107.33738433800008, 20.900537225000093],
              [107.33767215000009, 20.901174249000043],
              [107.33825055400007, 20.901630387000033],
              [107.3387821840001, 20.901631979000079],
              [107.33984727100002, 20.901089882000079],
              [107.3408153940001, 20.90063837600011],
              [107.34241224000006, 20.900052413000125],
            ],
          ],
          [
            [
              [107.37040278100008, 20.897634749000105],
              [107.36774472000008, 20.897627158000098],
              [107.36479536100002, 20.898027656000103],
              [107.36252184400011, 20.898657270000065],
              [107.36145652600007, 20.899290355000041],
              [107.36087360900007, 20.900197472000045],
              [107.36087212000004, 20.900651864000075],
              [107.361256967, 20.901198260000072],
              [107.3618357230001, 20.901563451000094],
              [107.36260810100006, 20.901838321000099],
              [107.36420238300008, 20.90202467000006],
              [107.36574907100007, 20.901983677000047],
              [107.36826235100008, 20.901945440000077],
              [107.37096968500001, 20.901680524000099],
              [107.37275918600001, 20.901276655000075],
              [107.37411444000006, 20.900644340000071],
              [107.37459962700005, 20.900054992000072],
              [107.37479469500003, 20.899510261000032],
              [107.37474854500003, 20.898828530000024],
              [107.37421853500005, 20.898327193000021],
              [107.37296359800004, 20.897823788000025],
              [107.37040278100008, 20.897634749000105],
            ],
          ],
          [
            [
              [107.01328881200008, 20.896384871000073],
              [107.01219082600001, 20.896250837000082],
              [107.011022858, 20.896374738000034],
              [107.01012726500007, 20.896951799000085],
              [107.00971199700008, 20.897595564000056],
              [107.009639071, 20.898434562000112],
              [107.00963448200005, 20.899338419000024],
              [107.00990423100009, 20.900308068000051],
              [107.01051436900012, 20.901860307000049],
              [107.01105781300005, 20.903024865000042],
              [107.01140017700004, 20.903220086000118],
              [107.01239536900006, 20.903321386000059],
              [107.01335899800003, 20.902873751000058],
              [107.01380942, 20.902068730000067],
              [107.01415699700007, 20.901230971000118],
              [107.0142305570001, 20.90026285200004],
              [107.0142357770001, 20.899229872000099],
              [107.01403630500002, 20.897937724000123],
              [107.01376751800008, 20.896774390000061],
              [107.01328881200008, 20.896384871000073],
            ],
          ],
          [
            [
              [107.16215292000007, 20.89907565700004],
              [107.16137802, 20.899072716000063],
              [107.1606010350001, 20.899556550000042],
              [107.16008129400009, 20.900284741000071],
              [107.16007849900004, 20.90093376500004],
              [107.16067351700006, 20.902720886000026],
              [107.16213061900001, 20.904267880000035],
              [107.16350615900004, 20.904759864000063],
              [107.16488448500004, 20.90460281900004],
              [107.16591978900009, 20.904119959000056],
              [107.16592672600004, 20.902497391000097],
              [107.16498794600008, 20.900546733000077],
              [107.16413146400001, 20.899488805000118],
              [107.16344371100003, 20.899242810000104],
              [107.16215292000007, 20.89907565700004],
            ],
          ],
          [
            [
              [106.96830075400003, 20.899078690000024],
              [106.96759068600007, 20.898835981000019],
              [106.96687909800008, 20.89888053300006],
              [106.96611517300002, 20.899212107000054],
              [106.96560427400003, 20.899736385],
              [106.96534743700009, 20.900261842000042],
              [106.96513708500001, 20.90160144500004],
              [106.96497423500003, 20.903563679000037],
              [106.96497220200004, 20.903946693000108],
              [106.96532534400004, 20.90442713500007],
              [106.96598360800006, 20.904861117000095],
              [106.96740302600008, 20.905490168000092],
              [106.96826606399999, 20.905637833000057],
              [106.96902823900008, 20.905641391000081],
              [106.97025176500007, 20.904881053000054],
              [106.97086402500001, 20.904405128000022],
              [106.97107105700009, 20.903687915000098],
              [106.97077149700009, 20.902681083000019],
              [106.96926108300008, 20.900040746000045],
              [106.96830075400003, 20.899078690000024],
            ],
          ],
          [
            [
              [107.44127463900003, 20.900557517],
              [107.44214554000006, 20.900218919000046],
              [107.44337716000008, 20.900494667000125],
              [107.44562330000004, 20.900909272000057],
              [107.44750814100011, 20.900913993000088],
              [107.44881360600004, 20.900712780000049],
              [107.44990254900007, 20.900170212000099],
              [107.45026694400012, 20.899489521000092],
              [107.45012367800007, 20.898875723000018],
              [107.44875151300005, 20.897031975000054],
              [107.44687212100007, 20.895118785000122],
              [107.44549865700003, 20.89375213200011],
              [107.4444861090001, 20.892931664000081],
              [107.44311130600002, 20.892042110000105],
              [107.44021696400009, 20.890194446000088],
              [107.43674198100007, 20.888617905000025],
              [107.43203632700002, 20.886492859000057],
              [107.43015421399998, 20.885601910000062],
              [107.42906769000005, 20.885326456000094],
              [107.42769042100005, 20.885322892000069],
              [107.42696493700006, 20.885525500000043],
              [107.42616556100009, 20.886205039000046],
              [107.42500233400004, 20.887360762000107],
              [107.42398447200003, 20.888380529000102],
              [107.4228937080001, 20.889536417000087],
              [107.42202221600012, 20.890079431000075],
              [107.42100634700009, 20.890417575000072],
              [107.4193372500001, 20.891026644000107],
              [107.41716010300007, 20.891838846000077],
              [107.41541787900007, 20.892652168000048],
              [107.41403830700006, 20.893398279000053],
              [107.41382021100004, 20.893602185000049],
              [107.41403625900007, 20.89407988800005],
              [107.41490472500007, 20.894559310000105],
              [107.41613544600003, 20.895107854000017],
              [107.41772883300006, 20.895589179000048],
              [107.41946742200003, 20.896002715000073],
              [107.421349992, 20.896757424000079],
              [107.42344904400004, 20.89785347400008],
              [107.42815527800006, 20.899842348000064],
              [107.43083376000007, 20.901144304000049],
              [107.43278752900008, 20.902376207000103],
              [107.43560988200002, 20.904087424000025],
              [107.438289051, 20.905184809000055],
              [107.44046157600008, 20.906008230000062],
              [107.44132075400003, 20.906190025000093],
              [107.44205569700009, 20.906284900000038],
              [107.44263605700004, 20.906150050000072],
              [107.44307219500007, 20.905742187000072],
              [107.443001452, 20.905128574000017],
              [107.44235192200009, 20.904104545000024],
              [107.44141280400001, 20.902943464000053],
              [107.44090748600011, 20.902192423000088],
              [107.44090983000004, 20.901374517000093],
              [107.44127463900003, 20.900557517],
            ],
          ],
          [
            [
              [107.32124033500004, 20.873314446000087],
              [107.32003322200009, 20.873038089000048],
              [107.31935639700004, 20.873126885000111],
              [107.31848500800007, 20.873578615],
              [107.31683806900004, 20.874709581000062],
              [107.31431810400008, 20.87674665200003],
              [107.31194289500012, 20.87882958900007],
              [107.30951818900004, 20.88123042000003],
              [107.30753011700004, 20.883132735000039],
              [107.30464273100006, 20.886509047000068],
              [107.30250909400009, 20.888547162000037],
              [107.29884831200006, 20.891920908000117],
              [107.29748910100004, 20.893597890000045],
              [107.29563970700005, 20.897181794000019],
              [107.29345045700011, 20.90117354100007],
              [107.29058498500007, 20.905026746000118],
              [107.28936861500006, 20.907249371000077],
              [107.28936613200003, 20.907930955000076],
              [107.28965463500001, 20.908340845000062],
              [107.29013761600001, 20.908433274000068],
              [107.29086323500009, 20.908253842000057],
              [107.29270393400006, 20.907123759000079],
              [107.29684726100005, 20.904069803000077],
              [107.29810678200008, 20.903255896000061],
              [107.3005299480001, 20.901400544000026],
              [107.30290587800005, 20.899226929000037],
              [107.30523358300009, 20.897007690000088],
              [107.30697985700007, 20.895195566000076],
              [107.3088028550001, 20.892179476000102],
              [107.31011523200006, 20.890047861000127],
              [107.31157271800004, 20.887871244000067],
              [107.31414486200005, 20.884789257000058],
              [107.31594038000001, 20.88265908200011],
              [107.31933499600005, 20.879261464000095],
              [107.32127458400004, 20.87735888200006],
              [107.32200338500003, 20.87622507200004],
              [107.32229663500004, 20.87527169300003],
              [107.32225115700001, 20.874453604000017],
              [107.32191510600009, 20.873816385000055],
              [107.32124033500004, 20.873314446000087],
            ],
          ],
          [
            [
              [107.00895902700003, 20.903887054000059],
              [107.00792933200002, 20.903817869000108],
              [107.00696601400007, 20.904200918000093],
              [107.00634504400003, 20.904779191000067],
              [107.00599577400008, 20.905939739000075],
              [107.00598919400008, 20.907230951000066],
              [107.00605292800002, 20.908199665000012],
              [107.006529347, 20.90904111800009],
              [107.00700806100008, 20.90943063499999],
              [107.00810513300009, 20.909758374000106],
              [107.00879183900005, 20.909761456000098],
              [107.00968749500001, 20.909184413000048],
              [107.01031208100008, 20.90789597400002],
              [107.01052562000007, 20.906412002000074],
              [107.01053086000006, 20.905379033000045],
              [107.0103284580001, 20.904667946000096],
              [107.00978172700007, 20.90414899400006],
              [107.00895902700003, 20.903887054000059],
            ],
          ],
          [
            [
              [107.32461714600002, 20.902906857000048],
              [107.32398917500002, 20.902814059],
              [107.32340890100006, 20.90290316],
              [107.32248921899999, 20.903309307000129],
              [107.32118052400007, 20.904395841000053],
              [107.319676746, 20.905981605000079],
              [107.31822095300006, 20.907658379000083],
              [107.31734670300003, 20.908882546000115],
              [107.31729535400004, 20.909745739000037],
              [107.31748740200004, 20.910109846000054],
              [107.31811491700009, 20.910338974000098],
              [107.31898488500003, 20.910341657000053],
              [107.32024261300009, 20.910027455000076],
              [107.32179270100001, 20.909032554000092],
              [107.32329584600004, 20.907628543],
              [107.32470514700002, 20.905406315000086],
              [107.32528967200007, 20.90409035],
              [107.32529202900008, 20.903408761],
              [107.324954975, 20.903044214000133],
              [107.32461714600002, 20.902906857000048],
            ],
          ],
          [
            [
              [106.94772525200005, 20.908010211000061],
              [106.94716058000006, 20.907910699000084],
              [106.94515385200002, 20.9084336700001],
              [106.94278638400007, 20.909148548000097],
              [106.94072614400004, 20.910058523000075],
              [106.94020943500001, 20.910588586000053],
              [106.94020706800008, 20.911024286000043],
              [106.94056489100002, 20.911364893000069],
              [106.94123206700007, 20.911513331],
              [106.94236300200004, 20.91142191800008],
              [106.94503930200004, 20.910611702000011],
              [106.94730270400001, 20.910138367000016],
              [106.94781834800003, 20.909801933000068],
              [106.94807761099999, 20.909367455000094],
              [106.94818271900007, 20.908932234000055],
              [106.94808335400002, 20.908302397000078],
              [106.94772525200005, 20.908010211000061],
            ],
          ],
          [
            [
              [107.10732082400006, 20.905391842000043],
              [107.10671810400002, 20.905389406000019],
              [107.10602817200004, 20.90562999600003],
              [107.10542248400003, 20.906276584000061],
              [107.10490103000004, 20.907329150000109],
              [107.10489246800005, 20.909195079000042],
              [107.10505425300002, 20.91146734700002],
              [107.10565251900005, 20.912443310000036],
              [107.10642561200007, 20.912852074000021],
              [107.10771941600009, 20.912370538000054],
              [107.1088476840001, 20.910428],
              [107.10893860100006, 20.909373694000074],
              [107.10868844900007, 20.90758785200002],
              [107.10817739400005, 20.906368852000057],
              [107.10766337700008, 20.905798867000058],
              [107.10732082400006, 20.905391842000043],
            ],
          ],
          [
            [
              [107.15324277800003, 20.908696095000053],
              [107.15221094500001, 20.908367620000057],
              [107.15109053500004, 20.908606706000029],
              [107.14983973600002, 20.909129233],
              [107.14841389, 20.910300113000076],
              [107.14694549800001, 20.9113491190001],
              [107.14668364000001, 20.912159389000081],
              [107.14693769300007, 20.913133909000067],
              [107.14908679900007, 20.91395347600011],
              [107.15106723200006, 20.913961095000033],
              [107.15201757700001, 20.913234594000052],
              [107.15322904400006, 20.911860057000098],
              [107.15366695600005, 20.910158040000049],
              [107.15358578500008, 20.909021927000104],
              [107.15324277800003, 20.908696095000053],
            ],
          ],
          [
            [
              [107.26069503400001, 20.9098591690001],
              [107.25967303, 20.90985574700008],
              [107.25846388200006, 20.910201854000029],
              [107.25688142800004, 20.910984395000028],
              [107.25604190200004, 20.91185696900002],
              [107.25603923100007, 20.912557283000055],
              [107.25640753300009, 20.913433911000112],
              [107.25733364900006, 20.914224880000098],
              [107.25788977400006, 20.91457690900009],
              [107.25909829400005, 20.914405889000065],
              [107.26114597900002, 20.913449808000095],
              [107.26226554500002, 20.912228],
              [107.26217528700001, 20.911527376000016],
              [107.26162180300004, 20.910475050000073],
              [107.26069503400001, 20.9098591690001],
            ],
          ],
          [
            [
              [107.12554138800004, 20.908123008000068],
              [107.12463209500004, 20.907904989000059],
              [107.12406270600009, 20.908009941000039],
              [107.12337712200012, 20.908650477],
              [107.12291717800008, 20.9097207330001],
              [107.123024689, 20.911114866000077],
              [107.12471701700005, 20.914337812000028],
              [107.12585150700004, 20.915092751000053],
              [107.12710507200011, 20.914668875000061],
              [107.12790779900008, 20.913278347000031],
              [107.12814591800007, 20.910920717000089],
              [107.12656158800007, 20.908984708000041],
              [107.12554138800004, 20.908123008000068],
            ],
          ],
          [
            [
              [107.01948177200011, 20.900641314],
              [107.01810909200003, 20.900506100000094],
              [107.01700716699999, 20.901146822000079],
              [107.01631366300008, 20.902499550000094],
              [107.01541381200005, 20.903915915000049],
              [107.01430958400007, 20.905008549000037],
              [107.01320664100004, 20.905842940000063],
              [107.01251538200003, 20.906743718000072],
              [107.01230414600006, 20.907775764000021],
              [107.01229662700005, 20.909260655000047],
              [107.01256901800004, 20.909713808000063],
              [107.01345943800008, 20.910169721000038],
              [107.01407453899999, 20.910753517000025],
              [107.01427500300005, 20.91185196600005],
              [107.014266847, 20.913465959000099],
              [107.01480838900005, 20.915017847000072],
              [107.01535482800006, 20.915601337000034],
              [107.01617889900002, 20.915605008000071],
              [107.01721060100003, 20.915286792000032],
              [107.01893259700005, 20.914261468000106],
              [107.02031057200003, 20.913363718000078],
              [107.020795482, 20.91252657200009],
              [107.02086771700002, 20.911816720000061],
              [107.02087484700007, 20.910396396000053],
              [107.02108668900011, 20.909235215000074],
              [107.02192109100005, 20.907172934000059],
              [107.02261619000009, 20.905497393000068],
              [107.02268971000005, 20.904529287000081],
              [107.02235445900003, 20.902913742000081],
              [107.02194666400004, 20.902072619000123],
              [107.02112653800003, 20.901294239000023],
              [107.02030480700007, 20.900838655000072],
              [107.01948177200011, 20.900641314],
            ],
          ],
          [
            [
              [107.32141994200008, 20.884198287000096],
              [107.32055024800006, 20.884150164000026],
              [107.31934196100009, 20.884191893000015],
              [107.31798791400006, 20.884460357000108],
              [107.31687468500004, 20.884956774000059],
              [107.31580897500007, 20.885680532],
              [107.31469350900005, 20.886813105],
              [107.31328550800001, 20.888671805000044],
              [107.31187634800003, 20.890848582000032],
              [107.31061214700006, 20.893025794000053],
              [107.30934889300006, 20.89493035100007],
              [107.30767311000005, 20.897310720000029],
              [107.30699167500008, 20.898671789000019],
              [107.30698957700008, 20.899262505000046],
              [107.30727761000001, 20.899808693000033],
              [107.30804749600003, 20.900765347000046],
              [107.30847923200005, 20.901675500000039],
              [107.30847665400002, 20.902402529000064],
              [107.30731158500008, 20.903852949000076],
              [107.306146013, 20.90543968300009],
              [107.30507707500001, 20.907026711000043],
              [107.30449306300002, 20.908160865000092],
              [107.30448966100009, 20.909115083000081],
              [107.305016607, 20.910434482000092],
              [107.306265146, 20.912710367000017],
              [107.30703426100004, 20.91389419100009],
              [107.30794999400005, 20.914624092000089],
              [107.30920374400004, 20.915445913000049],
              [107.30992777400009, 20.91572081000006],
              [107.31060426900011, 20.915768360000087],
              [107.31108808000008, 20.915633556000031],
              [107.3115509300001, 20.915387928000051],
              [107.31189037400003, 20.915070913000108],
              [107.31279660800004, 20.914004031000033],
              [107.31385315400007, 20.911321675000046],
              [107.31532148100005, 20.907325138000132],
              [107.31637112600004, 20.904540045000083],
              [107.31710164700009, 20.902951920000042],
              [107.31737832600007, 20.901362378000066],
              [107.31737991600002, 20.900907978000035],
              [107.31725601800008, 20.90027143400004],
              [107.31709422200001, 20.899438004],
              [107.31692374500003, 20.898286455000026],
              [107.31726730900003, 20.897045217000084],
              [107.31868263200008, 20.893096272000022],
              [107.3199990360001, 20.889783160000071],
              [107.32126295300006, 20.887651322000075],
              [107.32170082300004, 20.886875900000042],
              [107.32204232500006, 20.885881519000101],
              [107.32204564100006, 20.884927265000016],
              [107.32175742000001, 20.884426523000066],
              [107.32141994200008, 20.884198287000096],
            ],
          ],
          [
            [
              [107.50200453100007, 20.895152468000063],
              [107.50098000100012, 20.89505379400007],
              [107.49887908000001, 20.895097215000114],
              [107.49650143900001, 20.895319103000084],
              [107.49133963900005, 20.895698424000031],
              [107.48442752100004, 20.896040221000064],
              [107.47886323299998, 20.896212733000041],
              [107.47820090400008, 20.896277873000102],
              [107.47753427599999, 20.896469002000018],
              [107.47717456300006, 20.896853569000129],
              [107.47707066800008, 20.897383278000078],
              [107.47727291600003, 20.898395480000076],
              [107.47760451700003, 20.899607113000052],
              [107.47862635400001, 20.900717598000092],
              [107.48062798600007, 20.901967963000075],
              [107.48303361800008, 20.902985321000052],
              [107.48534503500011, 20.903692945000074],
              [107.48765287500001, 20.904498874000055],
              [107.49144649200009, 20.906040509000064],
              [107.49431191200004, 20.907636917000083],
              [107.49871378700001, 20.909574001000038],
              [107.50086351600007, 20.910542396000054],
              [107.50408827800007, 20.911946768000078],
              [107.5073648040001, 20.913158497000062],
              [107.50936089000007, 20.914126462000034],
              [107.51212564800002, 20.91509610500006],
              [107.51355916300005, 20.915629196000026],
              [107.51473706500009, 20.915920843],
              [107.51535200200007, 20.915922193000114],
              [107.51566018300008, 20.915633812000053],
              [107.51551979200011, 20.915029085000114],
              [107.51484590000004, 20.913367760000057],
              [107.513270709, 20.911154827000097],
              [107.511935255, 20.909266370000076],
              [107.51147873400005, 20.908286347000079],
              [107.51096908900007, 20.907177153000056],
              [107.51015205000006, 20.905167416000033],
              [107.509596371, 20.903737640000131],
              [107.50903551000005, 20.902628328],
              [107.50785638500001, 20.901115465000061],
              [107.5066274050001, 20.89924179800002],
              [107.5051943170001, 20.897594088000027],
              [107.50413981700008, 20.896580785000118],
              [107.50314554000005, 20.895848257000083],
              [107.50246519100007, 20.895346211000017],
              [107.50200453100007, 20.895152468000063],
            ],
          ],
          [
            [
              [106.94497915500004, 20.912233237000066],
              [106.94459548900009, 20.911916724000079],
              [106.94395291300003, 20.912010366000096],
              [106.94333475800005, 20.912297890000083],
              [106.942817828, 20.912828065000078],
              [106.94203842300008, 20.914421950000062],
              [106.9417778370001, 20.915098478000083],
              [106.94187823600006, 20.915534667000109],
              [106.94249221300007, 20.91602173400009],
              [106.9440287190001, 20.916948916000067],
              [106.94541214700007, 20.917681708000124],
              [106.94638634100004, 20.918073652000047],
              [106.94684853000008, 20.918124274000057],
              [106.94751886800003, 20.917691753000064],
              [106.94752121900005, 20.917256058000078],
              [106.94685767800004, 20.916429883000106],
              [106.94583603000007, 20.915311528000032],
              [106.94507131200002, 20.914194392000063],
              [106.94491978300002, 20.913709541000088],
              [106.94492292600009, 20.913128607000075],
              [106.94497915500004, 20.912233237000066],
            ],
          ],
          [
            [
              [107.30297709000008, 20.912970387000129],
              [107.30179556300003, 20.912409098000047],
              [107.300545841, 20.912529047000113],
              [107.29962265500009, 20.913269524],
              [107.29902711200002, 20.914320798000041],
              [107.29895489700009, 20.916117128000074],
              [107.29940537400006, 20.91884435700004],
              [107.30019041800008, 20.919961939000054],
              [107.30097702600007, 20.920645881000063],
              [107.30156747500007, 20.921019439000062],
              [107.30202775400005, 20.921020894],
              [107.30248980500009, 20.920526761000069],
              [107.30282345100008, 20.9191649200001],
              [107.30282943000003, 20.91749228900008],
              [107.3034280680001, 20.915573721000058],
              [107.30379434300002, 20.914273921000067],
              [107.30369859400001, 20.913468259000112],
              [107.30297709000008, 20.912970387000129],
            ],
          ],
          [
            [
              [107.30756914000007, 20.91595844200009],
              [107.30704312400007, 20.915956792000046],
              [107.30605595000002, 20.916201496000042],
              [107.30506591300008, 20.917251533000055],
              [107.30453525500005, 20.918550812000092],
              [107.30453150000008, 20.919603945000098],
              [107.30485651100007, 20.920658120000013],
              [107.3053807750001, 20.921155361000032],
              [107.30643240400008, 20.921282564000073],
              [107.30794629300001, 20.920853661000116],
              [107.30893590300009, 20.919927516000044],
              [107.30933480500005, 20.918689768000043],
              [107.30900999300009, 20.917573656000066],
              [107.30842172700009, 20.916580608000054],
              [107.30756914000007, 20.91595844200009],
            ],
          ],
          [
            [
              [107.52092506000005, 20.918488245000063],
              [107.52031403800004, 20.918486920000042],
              [107.51970253400006, 20.918677069000054],
              [107.51936212600005, 20.91905928500006],
              [107.51936039600008, 20.919761363000063],
              [107.51990118100007, 20.920719927000064],
              [107.52051018100009, 20.921550978000077],
              [107.52172912500005, 20.922830120000043],
              [107.52247408800005, 20.923597638000047],
              [107.52416832600008, 20.924877799000093],
              [107.52532144600011, 20.92532705000005],
              [107.52606828400008, 20.925328653000093],
              [107.52681605100004, 20.924947304000071],
              [107.52702129000005, 20.924309496000056],
              [107.52695602500008, 20.923224326000067],
              [107.52634807700005, 20.921946524000035],
              [107.52540051500007, 20.92073180800007],
              [107.52431640700007, 20.919835918000018],
              [107.5222137690001, 20.919001641000087],
              [107.52092506000005, 20.918488245000063],
            ],
          ],
          [
            [
              [107.34404450500003, 20.915374812000032],
              [107.34120348500005, 20.915280009000092],
              [107.33716831100006, 20.915958782000033],
              [107.33359020600003, 20.916984275000061],
              [107.32891159600007, 20.918179085000062],
              [107.32313153900003, 20.919802195000067],
              [107.31952752200007, 20.921299353000045],
              [107.31716596600009, 20.922029054000021],
              [107.31516845500001, 20.922341212],
              [107.313241498, 20.922939716000052],
              [107.31083544500005, 20.923736479000077],
              [107.30945902200003, 20.924163951000097],
              [107.30881557400008, 20.924680065000075],
              [107.30881343200008, 20.925284533000088],
              [107.30981741400011, 20.926496627000098],
              [107.31192133700002, 20.92771214200004],
              [107.31420982200009, 20.928582789000046],
              [107.31714178000004, 20.928937279000067],
              [107.32218706000003, 20.927830219000043],
              [107.32766846500007, 20.926385979000095],
              [107.3340419190001, 20.924718054000024],
              [107.33922069900005, 20.923233838000051],
              [107.34255619100007, 20.921846998000056],
              [107.34567836600003, 20.920129173000035],
              [107.34669035100009, 20.91900955800012],
              [107.34751873500007, 20.917975753000029],
              [107.34752162300003, 20.917112214000078],
              [107.34724896600004, 20.916420569000046],
              [107.34578507900007, 20.915639039000041],
              [107.34404450500003, 20.915374812000032],
            ],
          ],
          [
            [
              [107.10590341600002, 20.92810378800003],
              [107.10523347800002, 20.928101076000068],
              [107.10462338499998, 20.928328110000024],
              [107.10407103600002, 20.929243913000036],
              [107.10394474800006, 20.930218810000056],
              [107.10393974000009, 20.931308954000102],
              [107.10429936600009, 20.932572710000038],
              [107.10484225300007, 20.933722449000044],
              [107.10520636500009, 20.9340108150001],
              [107.10557153100009, 20.934069663000088],
              [107.10612126700002, 20.933727627000032],
              [107.10704246100003, 20.932067426000067],
              [107.10814661700009, 20.930350567000033],
              [107.10839312700006, 20.929720415000119],
              [107.1082734190001, 20.92926091200011],
              [107.10784866500003, 20.928914935000087],
              [107.10693746700005, 20.928394854000132],
              [107.10590341600002, 20.92810378800003],
            ],
          ],
          [
            [
              [107.13027211900004, 20.928495451000018],
              [107.12820998300005, 20.92827136800004],
              [107.12729208200004, 20.928483701000019],
              [107.12694532900012, 20.929130217000044],
              [107.12699901600004, 20.929940279000014],
              [107.12745410600003, 20.930697934000086],
              [107.12882445200009, 20.931837125000108],
              [107.1313386090001, 20.933520714000053],
              [107.13305455100004, 20.93428330600009],
              [107.13397151500007, 20.934286901000029],
              [107.13471774400008, 20.934019871000054],
              [107.13512130500008, 20.9334815610001],
              [107.13506734400005, 20.93272548700007],
              [107.13489852600003, 20.932022962000119],
              [107.13375785600007, 20.930776727000122],
              [107.13244575200004, 20.929421829000106],
              [107.13027211900004, 20.928495451000018],
            ],
          ],
          [
            [
              [107.07785846100003, 20.93418526100006],
              [107.07700605500003, 20.934124324000081],
              [107.076273558, 20.934465523000064],
              [107.07578467700004, 20.93480773900005],
              [107.07523190200004, 20.935780837],
              [107.07528954800001, 20.936469596000059],
              [107.07589398200003, 20.937447532000064],
              [107.07722877200007, 20.938543263000042],
              [107.0779580280001, 20.938890565000086],
              [107.07887135599999, 20.938951752],
              [107.07948286600005, 20.938437909000029],
              [107.07991327500005, 20.937579051000021],
              [107.079915982, 20.937005301000063],
              [107.07973813300003, 20.935971775000084],
              [107.07877014100006, 20.93459070200003],
              [107.07785846100003, 20.93418526100006],
            ],
          ],
          [
            [
              [107.19761986800009, 20.935137797],
              [107.19649683300007, 20.935089626000014],
              [107.19490525000001, 20.935172014000081],
              [107.19270489200002, 20.93534033800006],
              [107.19204840900007, 20.935646562000088],
              [107.19171897399998, 20.936086243000048],
              [107.1917151370001, 20.937012088000095],
              [107.1917568260001, 20.938246712000108],
              [107.19212630400003, 20.939438451000036],
              [107.19291645700012, 20.940763985000018],
              [107.19347661800002, 20.941118741000061],
              [107.19441357300009, 20.940901718000049],
              [107.19685162700003, 20.939852469000094],
              [107.19835272500006, 20.939020244000069],
              [107.1989168850001, 20.938405056000065],
              [107.19896567300006, 20.937920259000094],
              [107.19882870700009, 20.937082085000021],
              [107.19869084500006, 20.936464339000068],
              [107.19803962499999, 20.935492026000091],
              [107.19761986800009, 20.935137797],
            ],
          ],
          [
            [
              [106.98927535300005, 20.916603259000063],
              [106.98854871500006, 20.916325545000092],
              [106.98767289799999, 20.916733121000064],
              [106.984006107, 20.921792455000073],
              [106.98063897800002, 20.925206763000048],
              [106.9774152040001, 20.929033244000017],
              [106.97491654000004, 20.933411783000011],
              [106.97432537400006, 20.935055325000107],
              [106.97432032400008, 20.936015638000072],
              [106.97606065900001, 20.937395599000027],
              [106.98041805800004, 20.939610780000081],
              [106.9830358360001, 20.940308793000014],
              [106.98841922300001, 20.941293821000073],
              [106.99060512000004, 20.941029439000047],
              [106.99265318500002, 20.939255331000069],
              [106.99412151300001, 20.936929802000037],
              [106.9944240950001, 20.934736138000076],
              [106.99414203200003, 20.932951373000016],
              [106.99298765300009, 20.930888256000024],
              [106.99067115, 20.928271044000013],
              [106.98995582500005, 20.925798319000052],
              [106.989830811, 20.921819162000062],
              [106.98999773900005, 20.917704116000024],
              [106.98927535300005, 20.916603259000063],
            ],
          ],
          [
            [
              [107.09749127700007, 20.929676156000099],
              [107.09645697700002, 20.929442414000029],
              [107.09523782600004, 20.929666940000047],
              [107.09365032700006, 20.93052108600002],
              [107.09291573700003, 20.931321353000065],
              [107.09285055800005, 20.932239122000063],
              [107.09290826500003, 20.932927881000026],
              [107.09411490500004, 20.93540004100003],
              [107.09513699800004, 20.938273063000068],
              [107.09549578200007, 20.939708940000031],
              [107.09591575000009, 20.941087686000067],
              [107.09627774500002, 20.941835065000092],
              [107.09682458600005, 20.942124179000032],
              [107.09743498700003, 20.941839795000035],
              [107.09902681200002, 20.940067622000043],
              [107.10122873700004, 20.938068413000011],
              [107.10294283500011, 20.936181952000062],
              [107.10334904700007, 20.935104031000051],
              [107.10329856000006, 20.934258926000027],
              [107.10275463400009, 20.933338693000088],
              [107.10168177600005, 20.932275201000024],
              [107.09913224900004, 20.930428756000104],
              [107.09749127700007, 20.929676156000099],
            ],
          ],
          [
            [
              [107.27553696700004, 20.936782786000038],
              [107.27462643200002, 20.936779799000057],
              [107.27288069000008, 20.936917018000045],
              [107.27105586800009, 20.937911738000103],
              [107.27044509200009, 20.938910443000047],
              [107.27006168800007, 20.939981384000106],
              [107.26960320000006, 20.940837631000072],
              [107.26967559400001, 20.941767109000061],
              [107.27020381400011, 20.942555136000074],
              [107.27065803500003, 20.942842555000048],
              [107.27194934100002, 20.942489407000053],
              [107.27400210600005, 20.941423967],
              [107.27598322200001, 20.939214580000076],
              [107.27636818300007, 20.937714755000059],
              [107.27614160900006, 20.937428091000051],
              [107.27553696700004, 20.936782786000038],
            ],
          ],
          [
            [
              [107.28796979800005, 20.935321673000118],
              [107.28650379600008, 20.935144242],
              [107.28485162000007, 20.9357433650001],
              [107.28296662100007, 20.937334774000057],
              [107.28158273500004, 20.939748142000077],
              [107.28157637300005, 20.941475152000066],
              [107.28230557200008, 20.94260009100006],
              [107.2830379640001, 20.942861524000108],
              [107.28441583500006, 20.942088823000034],
              [107.28698758900005, 20.940715519],
              [107.28868799800006, 20.939468893000068],
              [107.28960780600005, 20.938608334],
              [107.28988626400007, 20.937659355000093],
              [107.29007273700006, 20.936796425000104],
              [107.2895258880001, 20.935931146000094],
              [107.28796979800005, 20.935321673000118],
            ],
          ],
          [
            [
              [107.39421712600007, 20.937688632000025],
              [107.39343307600004, 20.937316643000067],
              [107.39258206400005, 20.937437588000037],
              [107.391533779, 20.937866183000061],
              [107.39022241000004, 20.938725535000025],
              [107.389041128, 20.939831792000071],
              [107.38890833500002, 20.940447825000064],
              [107.38923279700005, 20.941311684000041],
              [107.39073317300002, 20.942795186000041],
              [107.39197337100005, 20.943784833000031],
              [107.3924968470001, 20.943786273000065],
              [107.39348048700005, 20.94311093700005],
              [107.39479684400007, 20.940648953],
              [107.39486667500007, 20.939231421000045],
              [107.39473830000003, 20.938429745000064],
              [107.39421712600007, 20.937688632000025],
            ],
          ],
          [
            [
              [107.29496948700003, 20.938841386000014],
              [107.29396146900011, 20.938751812000064],
              [107.29111699500004, 20.939519876000112],
              [107.28955583700009, 20.940292024000051],
              [107.28799150600007, 20.941927672000112],
              [107.28725279900006, 20.943393270000051],
              [107.28697243500004, 20.944860331000115],
              [107.28706062600006, 20.945810479000038],
              [107.28760938400006, 20.946157658000075],
              [107.28834337200004, 20.945987314000014],
              [107.29063950400005, 20.944785788000075],
              [107.29247476000002, 20.944273562000056],
              [107.29458782500006, 20.942985050000026],
              [107.29587617099999, 20.941607535000095],
              [107.29642897300008, 20.940832127000064],
              [107.29634042700003, 20.939968329000088],
              [107.29588427700004, 20.939362417000048],
              [107.29496948700003, 20.938841386000014],
            ],
          ],
          [
            [
              [107.082894729, 20.938222593000063],
              [107.08222502800001, 20.938162435000059],
              [107.08136909000007, 20.938847394000071],
              [107.08081606100005, 20.939877867000064],
              [107.08074811800003, 20.941369375000107],
              [107.08110274700006, 20.943665894000048],
              [107.08151694600005, 20.946249528000038],
              [107.08175410700004, 20.947627535],
              [107.08229878300007, 20.948375680000098],
              [107.08290653600008, 20.948665078000062],
              [107.08369998000011, 20.948324117000091],
              [107.08449611500006, 20.947409404000084],
              [107.08486588600002, 20.946492930000105],
              [107.08536176700002, 20.944658950000061],
              [107.08537064700005, 20.942765580000078],
              [107.08501300800003, 20.941100188000036],
              [107.08404764800009, 20.939145393000032],
              [107.082894729, 20.938222593000063],
            ],
          ],
          [
            [
              [107.38030612100006, 20.939445969000033],
              [107.379233103, 20.939190070000087],
              [107.3784936480001, 20.939567322000038],
              [107.37808934500005, 20.940071968000048],
              [107.37848800700009, 20.941337540000049],
              [107.3797569490001, 20.943300998000055],
              [107.38182949800007, 20.94583568200008],
              [107.38397282100004, 20.947232548000017],
              [107.38578038300003, 20.948691677000042],
              [107.38651548300001, 20.949705269000063],
              [107.38704905000002, 20.950781510000027],
              [107.38745095900005, 20.951035516000083],
              [107.38825677300011, 20.950911307000105],
              [107.39020654500005, 20.949841927000087],
              [107.390679327, 20.948894911000068],
              [107.39068604300012, 20.946745392000118],
              [107.39055576300008, 20.945480593000099],
              [107.39022394200008, 20.944278457000017],
              [107.38982283400001, 20.943771571000092],
              [107.38794657500003, 20.942818052000064],
              [107.38627109000004, 20.942054739000056],
              [107.38452850500001, 20.941291225000036],
              [107.38265190500007, 20.940464091000074],
              [107.38030612100006, 20.939445969000033],
            ],
          ],
          [
            [
              [107.33056471100008, 20.948078261000092],
              [107.32912222900009, 20.947892642000099],
              [107.32584994600006, 20.948245163000045],
              [107.32440338600001, 20.949237574000087],
              [107.32411225300008, 20.949961650000049],
              [107.32478287700006, 20.95077928200002],
              [107.32766569100002, 20.951784895000088],
              [107.33141659800003, 20.952158752000024],
              [107.33315021800004, 20.951620270000056],
              [107.3340182580001, 20.950988550000105],
              [107.33440618800005, 20.950083510000091],
              [107.33412004200008, 20.94935768300008],
              [107.33325633800003, 20.948720739000045],
              [107.33056471100008, 20.948078261000092],
            ],
          ],
          [
            [
              [107.25977968900003, 20.950025808000113],
              [107.25697308200007, 20.949730473000017],
              [107.25507572200011, 20.949795563000073],
              [107.25446756800007, 20.950079428000024],
              [107.25393282900005, 20.951006848000105],
              [107.254006245, 20.951650404000063],
              [107.2543832100001, 20.952294984000019],
              [107.25589844100006, 20.952943406000109],
              [107.25749065800008, 20.953306153000106],
              [107.25931243500001, 20.953169304000063],
              [107.26037590600006, 20.95288695100006],
              [107.26098515300004, 20.952317159000074],
              [107.26091251700007, 20.951459170000049],
              [107.26061168900004, 20.950743379000066],
              [107.25977968900003, 20.950025808000113],
            ],
          ],
          [
            [
              [107.30197488600002, 20.950147235000124],
              [107.30143548899999, 20.950145531000075],
              [107.30053452200005, 20.950693114000082],
              [107.30021743900001, 20.951369562000096],
              [107.30021455200004, 20.952174023000047],
              [107.30048015300008, 20.953318062000022],
              [107.30119587400006, 20.954294150000052],
              [107.30213756600008, 20.954932237],
              [107.30321502600006, 20.9553167010001],
              [107.30451861000009, 20.955320807000085],
              [107.30559879000002, 20.9549431390001],
              [107.30623097100002, 20.95414065800005],
              [107.30636868400003, 20.953336623000048],
              [107.30610094000009, 20.952785352000092],
              [107.30565279000005, 20.952402878000065],
              [107.30466599400003, 20.951807007000056],
              [107.3029170240001, 20.950658296000043],
              [107.30197488600002, 20.950147235000124],
            ],
          ],
          [
            [
              [107.28665794500004, 20.955653117000047],
              [107.28473532200003, 20.954956106000019],
              [107.28317622800009, 20.955123751000123],
              [107.28134116400004, 20.955549548000093],
              [107.28033116400005, 20.955978008000017],
              [107.2799625480001, 20.956494898000066],
              [107.27995999800007, 20.957185686000024],
              [107.28078220900007, 20.957965511000062],
              [107.28261189700002, 20.95900764500005],
              [107.28453423500009, 20.959791022000026],
              [107.28700727200008, 20.960403454000073],
              [107.28856576500007, 20.960408476000048],
              [107.28939211500006, 20.960065743000108],
              [107.28985395400007, 20.95911738700007],
              [107.28985584100005, 20.958599295000013],
              [107.28967563800006, 20.957735225000107],
              [107.28857931100003, 20.956695510000053],
              [107.28665794500004, 20.955653117000047],
            ],
          ],
          [
            [
              [107.1938426240001, 20.948813403000074],
              [107.19225166800001, 20.948719424000025],
              [107.19131449100009, 20.948980524000049],
              [107.19056197400009, 20.949859520000032],
              [107.190462134, 20.951358126000038],
              [107.19101867300003, 20.952594615000031],
              [107.19269743800002, 20.954099728000038],
              [107.19428189000003, 20.955780819000047],
              [107.1960506840001, 20.958167965000115],
              [107.19819179700006, 20.961085475000019],
              [107.19903034000008, 20.962058425000052],
              [107.19987286100007, 20.962061477000034],
              [107.2006246710001, 20.961358810000071],
              [107.20100345900009, 20.960302100000035],
              [107.20045334200005, 20.957478544000097],
              [107.19962133600004, 20.954918478000103],
              [107.19841309400007, 20.952797907000061],
              [107.19701802500002, 20.950588474000043],
              [107.19571369500002, 20.949084748000054],
              [107.1938426240001, 20.948813403000074],
            ],
          ],
          [
            [
              [107.36922049400005, 20.937944060000021],
              [107.36721256600001, 20.935951391000081],
              [107.36538432800008, 20.934391807000097],
              [107.36199981300008, 20.932223240000077],
              [107.3587978530001, 20.930314203000044],
              [107.35641862300005, 20.929098348000075],
              [107.35385581700011, 20.927968276000094],
              [107.349369607, 20.926400740000069],
              [107.34643762800006, 20.926046677000087],
              [107.34304678800007, 20.925863916000061],
              [107.33813988700007, 20.926755985000064],
              [107.33612042000004, 20.927613470000047],
              [107.33520145700003, 20.928301534000049],
              [107.33446433600005, 20.929421908000023],
              [107.33354535800005, 20.930109964000017],
              [107.33244541600007, 20.930106644000098],
              [107.33098059800002, 20.92958409200012],
              [107.32896462600011, 20.929405274000068],
              [107.32575497600001, 20.929827269000079],
              [107.32126053400003, 20.930677038000042],
              [107.31786450300007, 20.931961873000127],
              [107.31474401700007, 20.933074804000015],
              [107.31134758700006, 20.934445888000042],
              [107.30969304800003, 20.935736019000025],
              [107.30831164800004, 20.937545096000051],
              [107.30803205400009, 20.938839499000039],
              [107.30903304200004, 20.940915075000085],
              [107.31085844900009, 20.943165913000072],
              [107.31186558300001, 20.943514462000046],
              [107.31315015200008, 20.943173048000041],
              [107.31489670500008, 20.941796846000038],
              [107.31609080700012, 20.941109732000115],
              [107.31719111300004, 20.941026789000091],
              [107.32030509200011, 20.941813548000084],
              [107.32283477600008, 20.943055809000022],
              [107.32625603000008, 20.943990560000088],
              [107.32927931100004, 20.944517858000019],
              [107.33276154400004, 20.944873808000075],
              [107.33551161200002, 20.944882095000047],
              [107.33688783000011, 20.944540820000057],
              [107.337347935, 20.944024100000021],
              [107.33753479000009, 20.942988440000043],
              [107.33717280800008, 20.941605731000031],
              [107.33672151900004, 20.939531936000044],
              [107.3368190620001, 20.937805193000081],
              [107.33746335900004, 20.937029953000064],
              [107.33829069600002, 20.936341614000042],
              [107.33939125900004, 20.936172205000055],
              [107.34177338300005, 20.936524727000091],
              [107.34387848500006, 20.937480854000064],
              [107.34451781900002, 20.938173568000096],
              [107.34469911800001, 20.938778576000061],
              [107.34469273500011, 20.940678290000037],
              [107.34523926200002, 20.941716134000018],
              [107.34615333100007, 20.942496007000067],
              [107.34780162100003, 20.943018980000083],
              [107.35036832900003, 20.943026540000083],
              [107.35155944200008, 20.9432027390001],
              [107.35357357400002, 20.943985805000054],
              [107.35718865500009, 20.945766544000044],
              [107.36240618600004, 20.948113132000046],
              [107.36505929400001, 20.949761431000091],
              [107.36652155000012, 20.951147232],
              [107.367250165, 20.952617265000036],
              [107.36870853500005, 20.955211932000047],
              [107.37053143000007, 20.958498410000104],
              [107.37263274300003, 20.960749457000105],
              [107.37482992800001, 20.961705520000073],
              [107.37767136500001, 20.961886234000104],
              [107.37950161800011, 20.962927559000022],
              [107.38178698400002, 20.965006324000065],
              [107.3831115400001, 20.966521113000077],
              [107.38402724000011, 20.966869063],
              [107.38512821700007, 20.966613084000052],
              [107.38696750300002, 20.96480488400001],
              [107.3881652840001, 20.962908537000047],
              [107.38789404400003, 20.961698913000056],
              [107.38643400200002, 20.959536140000075],
              [107.38442449400002, 20.95719912400007],
              [107.38332904100007, 20.95572813600004],
              [107.38278664500004, 20.953308826000061],
              [107.38178563000008, 20.9509745760001],
              [107.38032354100008, 20.949502532000075],
              [107.37712497900002, 20.946384912000049],
              [107.37538822700003, 20.944825695000091],
              [107.37358288400004, 20.942830564000033],
              [107.37179906700007, 20.940575919000089],
              [107.36922049400005, 20.937944060000021],
            ],
          ],
          [
            [
              [107.20620374600004, 20.950021446000022],
              [107.20469644700003, 20.949999018000121],
              [107.20350152, 20.950844198000063],
              [107.20306661799999, 20.952604192000109],
              [107.20299312000004, 20.954489793000072],
              [107.20354469500003, 20.956960660000057],
              [107.2058652420001, 20.961818569000101],
              [107.20735446400003, 20.963940075000039],
              [107.2095933570001, 20.965887903000088],
              [107.21127559000011, 20.966599296000062],
              [107.21445747700004, 20.966875138000063],
              [107.21707980200003, 20.966619933000082],
              [107.21779692800003, 20.96602993300003],
              [107.218208847, 20.965213163000037],
              [107.21840597300006, 20.962745007000102],
              [107.21813008100006, 20.961509610000029],
              [107.21728968499998, 20.960977591000059],
              [107.21532663100001, 20.960265241000094],
              [107.21430008900006, 20.959468035000036],
              [107.21327568100004, 20.95814177699999],
              [107.21201803700008, 20.956349592000031],
              [107.21095250600011, 20.953901135000038],
              [107.2103018710001, 20.952752541000038],
              [107.20891266600009, 20.95144196100005],
              [107.20620374600004, 20.950021446000022],
            ],
          ],
          [
            [
              [107.55149744000009, 20.943212379],
              [107.55214617000003, 20.9430105900001],
              [107.55355072400008, 20.943013432000065],
              [107.55517056000006, 20.943372134000079],
              [107.55592674600011, 20.943424438000058],
              [107.55652155900003, 20.943171745000107],
              [107.55668466400002, 20.94271508000007],
              [107.55679454800006, 20.941902862000106],
              [107.55679962500002, 20.939668667000078],
              [107.55701951200011, 20.937993449000111],
              [107.55734720700005, 20.936419991000044],
              [107.55762018100006, 20.935151093000037],
              [107.55762144800008, 20.934592539],
              [107.55735262400005, 20.934033443000111],
              [107.555411185, 20.9326077630001],
              [107.5532001440001, 20.930978408000023],
              [107.55115061000008, 20.929603240000105],
              [107.54915595000006, 20.927872708000095],
              [107.54764582300008, 20.92685405400001],
              [107.54562296400005, 20.92565660700005],
              [107.54378878100007, 20.924637256],
              [107.54163062800004, 20.923566432000129],
              [107.54012130100003, 20.922243048000055],
              [107.53861270600008, 20.920614972000081],
              [107.53764540700007, 20.919561002000094],
              [107.53611730100002, 20.916435739000057],
              [107.53342396600002, 20.913286673000094],
              [107.53120570600007, 20.910812150000076],
              [107.52993710000008, 20.909836496000089],
              [107.52834431300002, 20.909020882000014],
              [107.52775735100003, 20.908735012000079],
              [107.52603106800011, 20.907868056000019],
              [107.52398110100005, 20.906848038000092],
              [107.52311827900006, 20.906287595],
              [107.52209518000005, 20.905015877000018],
              [107.52091105800005, 20.903337559000065],
              [107.51994249600003, 20.90186282500008],
              [107.51902783000001, 20.900438969000042],
              [107.51781920700002, 20.897770336000043],
              [107.5171213620001, 20.896042260000058],
              [107.51693919300004, 20.89468464400008],
              [107.51710599500007, 20.892755327000053],
              [107.51724157300001, 20.891116739000012],
              [107.51703081600007, 20.888983445000072],
              [107.51671018100009, 20.887611644000081],
              [107.51605669000003, 20.885979075000094],
              [107.51548100600009, 20.884790742000092],
              [107.51416951200011, 20.8833534890001],
              [107.51248908900007, 20.882212171000077],
              [107.51065037600009, 20.881268337000058],
              [107.50860078100006, 20.880521858000023],
              [107.50607804300004, 20.87967539000006],
              [107.50476490600002, 20.878930519000072],
              [107.50318964400006, 20.877838828000073],
              [107.50130057000008, 20.876053940000091],
              [107.49854598300007, 20.87335247500004],
              [107.49681556600007, 20.87127105000004],
              [107.49518960700001, 20.869486637000101],
              [107.49388054800005, 20.86720827500006],
              [107.4929912700001, 20.865326589000063],
              [107.49234016900006, 20.862876592000035],
              [107.49187504500003, 20.859709767000112],
              [107.49181594500003, 20.855399328000068],
              [107.49145886400005, 20.851144474000094],
              [107.4908025070001, 20.848892600000021],
              [107.48985650900008, 20.845833037000091],
              [107.48894984400002, 20.843647703000023],
              [107.48753831400003, 20.840429114000081],
              [107.48618941400007, 20.837611496000093],
              [107.48542990300011, 20.836230889000085],
              [107.48332203200005, 20.832236236000057],
              [107.48149247300002, 20.828076687000063],
              [107.47950537600009, 20.823867259000025],
              [107.47833018800003, 20.820871673000063],
              [107.47723040600007, 20.819187154000041],
              [107.47591923700003, 20.817897873000035],
              [107.47465960100007, 20.817004443000094],
              [107.47297917900003, 20.816159475000084],
              [107.47082471400003, 20.815610177000018],
              [107.46929950600006, 20.815705446],
              [107.46866688600002, 20.816297545000047],
              [107.46787489200004, 20.817482884000071],
              [107.46713546900006, 20.818668336000101],
              [107.46676547900009, 20.819359998000039],
              [107.46681590800009, 20.820151614000082],
              [107.46728566200005, 20.821438917000101],
              [107.46801767800004, 20.822974190000117],
              [107.46969370200007, 20.825451616000024],
              [107.47100179700003, 20.827878665000107],
              [107.47257032600005, 20.831246201000027],
              [107.47356535600005, 20.832782059000095],
              [107.47487474600003, 20.834763868000024],
              [107.47686464800009, 20.837934465000082],
              [107.47866836000003, 20.841920791000042],
              [107.47971239800009, 20.844841767000055],
              [107.48062913800008, 20.847909729000058],
              [107.48119578800006, 20.850069205000032],
              [107.48189013700005, 20.852049128000068],
              [107.48258497700009, 20.853849200000063],
              [107.48353217000003, 20.856429178000013],
              [107.48430413100004, 20.858603994000099],
              [107.48519225100007, 20.860881454000065],
              [107.48639441700007, 20.863753217000088],
              [107.48838258800008, 20.867764453000071],
              [107.48969145800001, 20.870092305000028],
              [107.49026694100012, 20.871280778000042],
              [107.49037032400005, 20.871973514000075],
              [107.4903677400001, 20.872962787000034],
              [107.48999799700006, 20.873555499000048],
              [107.48889069100002, 20.874591677000076],
              [107.48588780400007, 20.876365406000019],
              [107.48241081100007, 20.878385298000076],
              [107.47616891899999, 20.881610440000117],
              [107.47479901400007, 20.882448063000055],
              [107.47427099099998, 20.883188751000056],
              [107.47416286100001, 20.884276685000103],
              [107.47421252700003, 20.885364991000088],
              [107.47473576200008, 20.886404954000071],
              [107.47573308400008, 20.887198732000023],
              [107.47683629700006, 20.887745440000046],
              [107.47773042100005, 20.887797015000054],
              [107.47909929400006, 20.887355084000077],
              [107.48189133900007, 20.88582830000005],
              [107.4844196720001, 20.884548180000095],
              [107.48670176000009, 20.883238828000025],
              [107.48860001100003, 20.882279660000066],
              [107.49034327600003, 20.881801908000064],
              [107.49362209700007, 20.881905803000045],
              [107.49843699100002, 20.882398570000049],
              [107.50135631000008, 20.882838792000058],
              [107.5040692370001, 20.883856638000076],
              [107.50616722200004, 20.884921260000056],
              [107.50785473600006, 20.88622584000008],
              [107.50877360000005, 20.887576874000047],
              [107.50943495800006, 20.889457281000112],
              [107.50958479900002, 20.890999310000069],
              [107.50993958600007, 20.892541781000062],
              [107.51057105300001, 20.896132409000053],
              [107.51087330100006, 20.8982046940001],
              [107.51198912000009, 20.90278398],
              [107.51305857100003, 20.90543606500006],
              [107.51452618900005, 20.907995405000079],
              [107.51553324700006, 20.909612066000079],
              [107.51583955800001, 20.910289871000117],
              [107.51689112900006, 20.912672338000071],
              [107.51742598400008, 20.914806259000059],
              [107.51780182, 20.915721106000085],
              [107.51844873800006, 20.916230315000028],
              [107.51925844500009, 20.916435191000105],
              [107.52093350500004, 20.916184940000093],
              [107.52325821400009, 20.915326710000045],
              [107.52493412600003, 20.914720969000108],
              [107.52601476100008, 20.914570952000041],
              [107.5271484300001, 20.914827280000026],
              [107.52909059800004, 20.915796240000084],
              [107.53022267900009, 20.916712675000056],
              [107.5304376440001, 20.917170144000075],
              [107.5305018820001, 20.918253605000103],
              [107.53049932100006, 20.919317645000035],
              [107.53075427100002, 20.92026835700009],
              [107.53161266400008, 20.922707566000028],
              [107.53236620700008, 20.923826297000048],
              [107.53398292400009, 20.925403860000102],
              [107.53495304100005, 20.926319915000043],
              [107.53595035600006, 20.927159862000089],
              [107.53681294400012, 20.92787257100008],
              [107.53735119100006, 20.928686146000075],
              [107.53783493600012, 20.929702732000074],
              [107.53799494600004, 20.93056629600003],
              [107.53804619500002, 20.931734298000038],
              [107.53753155600006, 20.933420388000101],
              [107.53739495900004, 20.933760495000087],
              [107.53685336299999, 20.934403966000033],
              [107.53626339600004, 20.935040966000074],
              [107.53587640400008, 20.935538108000081],
              [107.53576739400003, 20.93594409300001],
              [107.53576485600009, 20.937010424000043],
              [107.53603194100006, 20.938280426000055],
              [107.53635280500006, 20.939652095000035],
              [107.53761440400008, 20.942980652000109],
              [107.53874296900003, 20.945471091000023],
              [107.54014023600008, 20.948571392000034],
              [107.54116247900002, 20.950358327000096],
              [107.54242769300008, 20.952206672000081],
              [107.54452893400003, 20.954648273000132],
              [107.54781560400001, 20.958463227000031],
              [107.549269958, 20.960344909000028],
              [107.55120940800006, 20.962735311000102],
              [107.552286451, 20.964260762000016],
              [107.55255530200004, 20.964819836000089],
              [107.552769557, 20.965632677000109],
              [107.55268613200012, 20.966673402000069],
              [107.55246849600005, 20.967333042000021],
              [107.55246721400003, 20.967891566000048],
              [107.55284401600004, 20.96850162700008],
              [107.55359890300005, 20.969163231000017],
              [107.55457014300003, 20.969723716000118],
              [107.55527252000007, 20.969725130000057],
              [107.55657002200005, 20.969372308000089],
              [107.55748978000003, 20.968815626000037],
              [107.55803190800003, 20.968004307000051],
              [107.55824986100004, 20.967192348000104],
              [107.55808904100004, 20.966633499000054],
              [107.55776682800004, 20.965769667000075],
              [107.55647431900012, 20.963939164000053],
              [107.55297489200004, 20.958549894],
              [107.55173673100008, 20.956617897000029],
              [107.55052922, 20.953718688000045],
              [107.54974588200011, 20.951986661000085],
              [107.54924024100002, 20.949834175000071],
              [107.54919114000005, 20.947701455000086],
              [107.54939789500001, 20.946931249000045],
              [107.5499798000001, 20.945392713000018],
              [107.55073938500007, 20.943972502000108],
              [107.55149744000009, 20.943212379],
            ],
          ],
          [
            [
              [107.44712136800008, 20.949980454000091],
              [107.44649744300007, 20.948388620000102],
              [107.4453899910001, 20.946841001000038],
              [107.44346350100004, 20.944337150000081],
              [107.44211374700009, 20.943016081000017],
              [107.43972726900007, 20.94080636200006],
              [107.43817698400005, 20.939608450000065],
              [107.43692253500009, 20.938787385000076],
              [107.43470882200006, 20.93774929000007],
              [107.43369530000005, 20.937201455000043],
              [107.43311682200006, 20.936654721000103],
              [107.43253928300007, 20.935789927000044],
              [107.43128740000004, 20.934105526000032],
              [107.42964817800004, 20.932647305000081],
              [107.42776823000001, 20.930870371000054],
              [107.425623466, 20.928751953000102],
              [107.42417714300007, 20.927475927000039],
              [107.42268330500011, 20.925927134000055],
              [107.42128616200006, 20.924378589000092],
              [107.42008224000008, 20.922875966000021],
              [107.41853946100008, 20.921554200000081],
              [107.41747801, 20.920915266000058],
              [107.41658794400007, 20.920489737000111],
              [107.41467790100005, 20.919817345000077],
              [107.41175650600009, 20.91887809399999],
              [107.40977633500003, 20.918372993000034],
              [107.40768986100005, 20.917468978000016],
              [107.4062379910001, 20.916694396000054],
              [107.40427575400001, 20.915268391000019],
              [107.40287758200006, 20.91412864800013],
              [107.40186343600007, 20.913853269000079],
              [107.400799, 20.914213896000078],
              [107.3996866510001, 20.914438073000063],
              [107.39857501000004, 20.91443505000008],
              [107.39765724500005, 20.914250790000018],
              [107.39630577000004, 20.913656393000046],
              [107.39423029500009, 20.912741939000057],
              [107.39210679500007, 20.911736447000081],
              [107.39015212300008, 20.910845002000045],
              [107.38778786200004, 20.9095661630001],
              [107.38542278300011, 20.908559932000067],
              [107.384071088, 20.908056330000058],
              [107.38209138600007, 20.907460088000015],
              [107.38020864900008, 20.906773222000119],
              [107.37847033600005, 20.9062685090001],
              [107.37639358000007, 20.905808255000032],
              [107.37378563100003, 20.905210171000107],
              [107.37233675200005, 20.904887982],
              [107.36815892000006, 20.90403537100007],
              [107.36685460400005, 20.903849883000092],
              [107.36516335600001, 20.903754153000072],
              [107.36361723700006, 20.90361338000006],
              [107.35897877300006, 20.903236452000051],
              [107.3557890110001, 20.903227174000079],
              [107.35395292700007, 20.903085497000085],
              [107.35211594100004, 20.903216433000082],
              [107.34979429900005, 20.903754892000045],
              [107.34747234000005, 20.90438420300007],
              [107.34539185600003, 20.905059643000087],
              [107.34338348000004, 20.905848881000018],
              [107.34297075600008, 20.906415647000109],
              [107.34323511500003, 20.906848110000098],
              [107.3438146280001, 20.906986147000062],
              [107.34487789900005, 20.906989303000053],
              [107.34777758800008, 20.907043329000082],
              [107.34990383800006, 20.907140473000041],
              [107.35294716500007, 20.907603798000096],
              [107.35734242900003, 20.908479980000024],
              [107.361303202, 20.909218494000115],
              [107.36391145300007, 20.909725855000083],
              [107.368572558, 20.910625295000074],
              [107.37069753800004, 20.911131193000095],
              [107.37315851800001, 20.912365049000044],
              [107.37663348200003, 20.913919801000077],
              [107.38044641100004, 20.915611774000098],
              [107.38266684000007, 20.916526759000043],
              [107.384236446, 20.916917376000029],
              [107.38616948400008, 20.91701363],
              [107.38810267600006, 20.917064430000032],
              [107.390761002, 20.917071776000014],
              [107.39254876400005, 20.917258458],
              [107.39501093600002, 20.918173980000084],
              [107.39834244900007, 20.919319048000062],
              [107.40068474200007, 20.919938835],
              [107.40604178800002, 20.922588738000059],
              [107.4086941300001, 20.924595134000064],
              [107.41096189900007, 20.925918897000081],
              [107.41178099300006, 20.926784396000109],
              [107.41240566100007, 20.928012886000054],
              [107.41331707000006, 20.93033263600006],
              [107.41374923600003, 20.931287976000057],
              [107.4150918600001, 20.934443459000107],
              [107.41600335700011, 20.93715265100009],
              [107.41696183900009, 20.939926852000035],
              [107.41752559200003, 20.941300164000062],
              [107.417824626, 20.942430890000097],
              [107.41781906500006, 20.944293786000046],
              [107.41776557400007, 20.946020236000074],
              [107.41785859500004, 20.947247270000076],
              [107.41824125400009, 20.948611369000055],
              [107.41867442300006, 20.949248619000024],
              [107.41934944700003, 20.949841068000104],
              [107.42017084000005, 20.949979529000103],
              [107.42070286300002, 20.949890055000076],
              [107.42225045700008, 20.949666922000084],
              [107.42326576900007, 20.949624139],
              [107.42394200700006, 20.949807652000096],
              [107.42485928400002, 20.950218965000111],
              [107.42630565600005, 20.951540375000022],
              [107.42799108400007, 20.953771114000027],
              [107.42900321300004, 20.954818757000027],
              [107.43167702600005, 20.957983441000039],
              [107.43350839400004, 20.959941875000077],
              [107.43510071700007, 20.9609909650001],
              [107.43760976500002, 20.962678482000015],
              [107.43934716600006, 20.963727906000074],
              [107.44103532300004, 20.96509523700005],
              [107.44233765000006, 20.966143533000015],
              [107.44462856100006, 20.968080290000032],
              [107.44670291700001, 20.969675714000054],
              [107.44776512600006, 20.97017815800006],
              [107.44897390900007, 20.970135741000014],
              [107.45037672200004, 20.969866624000041],
              [107.45153843800007, 20.969369722000053],
              [107.45245919200005, 20.968599616000105],
              [107.45275105100004, 20.967964250000115],
              [107.45246455100005, 20.966691359000045],
              [107.45155249599999, 20.964371908000068],
              [107.45054442100007, 20.96182502500001],
              [107.44934249000001, 20.959459390000035],
              [107.44859797400005, 20.957753700000033],
              [107.44850335100003, 20.957026494000083],
              [107.44851041800008, 20.954527537000089],
              [107.44817626100006, 20.953027320000039],
              [107.44712136800008, 20.949980454000091],
            ],
          ],
          [
            [
              [107.31359643100006, 20.959624155000078],
              [107.31259195899999, 20.958498496000018],
              [107.31158505100008, 20.958063617],
              [107.30974971500009, 20.958575981000038],
              [107.30690654800004, 20.958912484000081],
              [107.30452298200001, 20.958904996000079],
              [107.30406398800004, 20.959076247000041],
              [107.30286819100004, 20.96019501500006],
              [107.30148407900001, 20.96269475900003],
              [107.300104581, 20.963899277000039],
              [107.2991847000001, 20.964759844000099],
              [107.29890624800007, 20.965708793000104],
              [107.29908556800007, 20.966831886000037],
              [107.30081911900005, 20.969168772000032],
              [107.30264562300002, 20.971160533000074],
              [107.30420019700003, 20.972287947000012],
              [107.30511639900007, 20.972463522000062],
              [107.30621966100006, 20.971603517000048],
              [107.30833752600006, 20.969019734000049],
              [107.31073403600001, 20.965400599000091],
              [107.31257521700012, 20.963247629000037],
              [107.31395554500001, 20.96178398799999],
              [107.31441787600008, 20.960662889000048],
              [107.31359643100006, 20.959624155000078],
            ],
          ],
          [
            [
              [107.22652419700003, 20.969386533000112],
              [107.22568269400004, 20.969119068000097],
              [107.22465114500005, 20.969556313000034],
              [107.22352246300001, 20.970874931000068],
              [107.22243864600004, 20.972678654],
              [107.22271598700007, 20.973561346000032],
              [107.22486048200005, 20.975773171000078],
              [107.22598322300004, 20.975953446000069],
              [107.22870032200007, 20.975433913000074],
              [107.23038897100007, 20.97455808600008],
              [107.23095381800009, 20.973766503000043],
              [107.23086333000006, 20.972972648000088],
              [107.23021252300003, 20.971824148000096],
              [107.22745898000002, 20.969742487000076],
              [107.22652419700003, 20.969386533000112],
            ],
          ],
          [
            [
              [107.4445666340001, 20.933373203000102],
              [107.44250958900007, 20.932946504000057],
              [107.44161446099999, 20.933028549000099],
              [107.4410758320001, 20.933617317000049],
              [107.44089467800003, 20.934375589000084],
              [107.44151820700002, 20.935388811000074],
              [107.44455198800003, 20.938515688000066],
              [107.44856838400008, 20.942319387000019],
              [107.4514689750001, 20.945150742000074],
              [107.453790881, 20.946926835000035],
              [107.4546830030001, 20.947940663000075],
              [107.45539473700002, 20.94945986],
              [107.45551770100008, 20.951988172000121],
              [107.45551063900011, 20.954517191000029],
              [107.45571952200007, 20.955906967000089],
              [107.45723515300006, 20.95801821400007],
              [107.45912295900003, 20.960512444000031],
              [107.46045662500006, 20.963719107000053],
              [107.46161365100005, 20.966082324000098],
              [107.46473541900006, 20.970051966000099],
              [107.46723284200003, 20.973261353000083],
              [107.46892682300003, 20.975710078000098],
              [107.471071147, 20.977148294000081],
              [107.47375255700008, 20.978587756000074],
              [107.47455792000001, 20.978673971000106],
              [107.47581196500003, 20.978339751000071],
              [107.47715642500008, 20.977668559000051],
              [107.47832366100002, 20.976322545000059],
              [107.47886522400006, 20.974637865000112],
              [107.47932109200003, 20.971519895000057],
              [107.47959500900005, 20.969497359000044],
              [107.47950820800006, 20.968485566000084],
              [107.47915198600008, 20.967810340000078],
              [107.47772067700004, 20.965565768000083],
              [107.47482303400001, 20.961386064000109],
              [107.47361945000009, 20.959289811000033],
              [107.47206664400002, 20.956462715000065],
              [107.47010291300009, 20.954519096000098],
              [107.46498251600008, 20.949693718000084],
              [107.46064019400006, 20.945889885000035],
              [107.45698076100007, 20.942340219000052],
              [107.45260599600003, 20.938620091000089],
              [107.44894493900007, 20.935744677000031],
              [107.44698021400004, 20.934306598000063],
              [107.4445666340001, 20.933373203000102],
            ],
          ],
          [
            [
              [107.7884384880001, 20.973683855000047],
              [107.78773315000009, 20.973517072000014],
              [107.78643958700007, 20.973626564000099],
              [107.78502801000008, 20.9741235320001],
              [107.78385135100007, 20.974842205000051],
              [107.78314478100005, 20.975782816000077],
              [107.78279072800007, 20.976945253000068],
              [107.78278974100007, 20.977831175000091],
              [107.78302386800009, 20.978772703000104],
              [107.78377566900011, 20.97925693800002],
              [107.78419932200009, 20.979272175000041],
              [107.78490486600002, 20.979162138000085],
              [107.78655195900005, 20.978388551000116],
              [107.78849415200008, 20.976563187000075],
              [107.78914208000005, 20.97545639800007],
              [107.789025753, 20.974293500000037],
              [107.7884384880001, 20.973683855000047],
            ],
          ],
          [
            [
              [107.37627032100009, 20.976033667000046],
              [107.37540549000003, 20.97566874500005],
              [107.37444299200007, 20.97575664500004],
              [107.37338340300005, 20.976116115000117],
              [107.37251420300008, 20.97711046700006],
              [107.37183744900004, 20.978105362000043],
              [107.37183569700008, 20.978649071000085],
              [107.37212231899998, 20.979284221000057],
              [107.37250603700002, 20.979647780000029],
              [107.37414037000008, 20.980105514000066],
              [107.37596889900001, 20.980020062000015],
              [107.37722095200004, 20.97966111500007],
              [107.37789623800006, 20.97911930300009],
              [107.37809186400008, 20.978123048000086],
              [107.37780522499999, 20.97748790500011],
              [107.37713456600001, 20.976579820000026],
              [107.37627032100009, 20.976033667000046],
            ],
          ],
          [
            [
              [107.28239256000009, 20.975394345],
              [107.28080263600008, 20.974948321000042],
              [107.28005302700004, 20.975122231000043],
              [107.27995582500009, 20.976091792000062],
              [107.28022724700007, 20.978649638000043],
              [107.28022236500001, 20.979972190000048],
              [107.28068819900002, 20.980590899000084],
              [107.28115598600009, 20.980680585000044],
              [107.28237373900004, 20.980508203000078],
              [107.28509430600008, 20.979018121000067],
              [107.28622002700004, 20.978404557000104],
              [107.28640889300004, 20.977964313000093],
              [107.28612963400012, 20.97752255700005],
              [107.28491611700008, 20.976548750000035],
              [107.28239256000009, 20.975394345],
            ],
          ],
          [
            [
              [107.40455649200005, 20.977017874000033],
              [107.40157418900003, 20.976828576000095],
              [107.40012863900007, 20.977549617000065],
              [107.39954880100005, 20.978363614],
              [107.39983411800003, 20.979451816000072],
              [107.40050517000005, 20.980269215000114],
              [107.40127328300009, 20.980815008000064],
              [107.40233145700007, 20.980908494000069],
              [107.40396725600007, 20.980912911000082],
              [107.40570037900002, 20.980555100000053],
              [107.40637504700007, 20.980194435000051],
              [107.40676241500006, 20.979379906000069],
              [107.40628433700002, 20.978381809],
              [107.40590166300007, 20.977655831000064],
              [107.40455649200005, 20.977017874000033],
            ],
          ],
          [
            [
              [107.44185324300003, 20.976496265000044],
              [107.4409664610001, 20.976452258000045],
              [107.44012306300004, 20.976742476000076],
              [107.4379467640001, 20.977906324000095],
              [107.43625861700005, 20.97894611400006],
              [107.43505861300005, 20.979861851000067],
              [107.43452476500002, 20.980445169000106],
              [107.43443498500005, 20.9808208110001],
              [107.43465562800002, 20.981197248000072],
              [107.43540842900005, 20.981575035000063],
              [107.43771238800008, 20.982290886000051],
              [107.439573873, 20.982671485000104],
              [107.44010591300005, 20.982714589000025],
              [107.44063843300006, 20.982590650000077],
              [107.44139483100003, 20.981715542000053],
              [107.44224303600004, 20.979754800000094],
              [107.44255703900009, 20.978502693000053],
              [107.44264859200011, 20.97750059900001],
              [107.44238395300006, 20.976998768000051],
              [107.44185324300003, 20.976496265000044],
            ],
          ],
          [
            [
              [107.26896945100003, 20.978822929000081],
              [107.26842064400009, 20.978475733000046],
              [107.26621923300011, 20.978727476000088],
              [107.26465864000005, 20.979240361000073],
              [107.26364781800007, 20.979841426000064],
              [107.26290878500004, 20.98130684200008],
              [107.26244447600007, 20.982859518000026],
              [107.26326639900005, 20.983725723000113],
              [107.26427462600006, 20.983815421000074],
              [107.26739231900009, 20.983739437000089],
              [107.26858522500004, 20.983484349000037],
              [107.26923188600007, 20.982191294000074],
              [107.26923900600003, 20.980291711000021],
              [107.26896945100003, 20.978822929000081],
            ],
          ],
          [
            [
              [107.32058358400006, 20.980282807],
              [107.31911691300003, 20.980191944000026],
              [107.31719091800002, 20.980358702000053],
              [107.31498833400012, 20.980956306000031],
              [107.31388537099998, 20.981730002000099],
              [107.31369955900007, 20.982420183],
              [107.31424694900005, 20.983198998000049],
              [107.316077659, 20.98406812400011],
              [107.31827755700004, 20.98424761800004],
              [107.31974576500002, 20.983906754000081],
              [107.32149022300003, 20.983221350000036],
              [107.32240979300008, 20.982447056000098],
              [107.32250328400009, 20.981929274000066],
              [107.32232201400011, 20.981324291000099],
              [107.32168230700002, 20.980717910000024],
              [107.32058358400006, 20.980282807],
            ],
          ],
          [
            [
              [107.38135593800006, 20.98048830500003],
              [107.38058615500005, 20.980486149000029],
              [107.3796227710001, 20.980845925000047],
              [107.37894689700003, 20.981568973],
              [107.3789434330001, 20.982656384],
              [107.37932601600005, 20.98338241200004],
              [107.38009351500006, 20.984109517000071],
              [107.38143979500006, 20.984385143000047],
              [107.38317127000006, 20.984571215000081],
              [107.38442391200006, 20.984030998000058],
              [107.38490789500005, 20.983126160000062],
              [107.38471831300008, 20.982219446],
              [107.38385573000004, 20.981129621000072],
              [107.3824138140001, 20.980672497000086],
              [107.38135593800006, 20.98048830500003],
            ],
          ],
          [
            [
              [107.47008920300001, 20.978392846000112],
              [107.46919443000006, 20.977943975000102],
              [107.46882509700005, 20.978091996],
              [107.46840237600007, 20.978488061000085],
              [107.467872218, 20.979628415000029],
              [107.46760462500006, 20.98111683500008],
              [107.46828383400003, 20.983302433000084],
              [107.46875440900007, 20.984693347000061],
              [107.46922754900004, 20.985141202000079],
              [107.46964906700002, 20.985191846000092],
              [107.47059872500007, 20.984846678000046],
              [107.47223665600006, 20.983361539],
              [107.47318791900008, 20.982420735000048],
              [107.47318926000001, 20.981924384000102],
              [107.47261298800002, 20.980632487000058],
              [107.47161440900001, 20.979587750000036],
              [107.47008920300001, 20.978392846000112],
            ],
          ],
          [
            [
              [107.80603207000007, 20.978828288000081],
              [107.80496462100004, 20.978492149000068],
              [107.80407462500003, 20.978658957000015],
              [107.80282751300004, 20.979998655000109],
              [107.80220335500009, 20.981255108000063],
              [107.80220250500003, 20.982093107000118],
              [107.802735813, 20.982680186000024],
              [107.80415863600004, 20.983603251000105],
              [107.80549190800005, 20.985112826000126],
              [107.80620229700003, 20.986621836000019],
              [107.80718047900005, 20.987293091000048],
              [107.80815924600003, 20.987377743000124],
              [107.80887138900007, 20.987126957000065],
              [107.80985154500007, 20.985787012000046],
              [107.81020912600009, 20.984111329000044],
              [107.80967684700002, 20.982434867000066],
              [107.80852186500002, 20.980590266000029],
              [107.80789980700007, 20.979751724000018],
              [107.80603207000007, 20.978828288000081],
            ],
          ],
          [
            [
              [107.25427974800007, 20.982266788000103],
              [107.25325123300006, 20.98191063900012],
              [107.25119049900007, 20.982168180000116],
              [107.24922338200004, 20.98242601000004],
              [107.24753543, 20.98312563400011],
              [107.2459383610001, 20.984530916000033],
              [107.24499664500007, 20.985938422000046],
              [107.24471269600012, 20.986730985000079],
              [107.24489721500002, 20.987436973000051],
              [107.24545693200005, 20.987967896000015],
              [107.24592403900007, 20.988234],
              [107.24667304800005, 20.988236554000039],
              [107.24911210100007, 20.987010484000024],
              [107.25286052200006, 20.986141480000065],
              [107.25389614500004, 20.984646093000059],
              [107.25446328600006, 20.983237283000022],
              [107.25427974800007, 20.982266788000103],
            ],
          ],
          [
            [
              [107.28662452200003, 20.989846502000105],
              [107.28983399600001, 20.989770498000034],
              [107.29285945500006, 20.989866536000079],
              [107.29469356800011, 20.989786056000078],
              [107.29551971000008, 20.989529655000091],
              [107.29552283000007, 20.988666218000063],
              [107.29506719200003, 20.987887657000037],
              [107.29204834800001, 20.985978412000073],
              [107.28994423400007, 20.984676485000087],
              [107.28811426600001, 20.983634447000021],
              [107.28719897400003, 20.983199770000027],
              [107.28582367000011, 20.983195330000015],
              [107.28389475400003, 20.9841388930001],
              [107.282515948, 20.985084225000072],
              [107.28131668500006, 20.987066268000106],
              [107.2802985430001, 20.989653309000019],
              [107.28001868900004, 20.990947566000081],
              [107.28001645700004, 20.991551977000086],
              [107.28056469400011, 20.992071822000113],
              [107.28184932800008, 20.991816974000074],
              [107.28359462500006, 20.990959184000069],
              [107.28497378300005, 20.989927517000062],
              [107.28662452200003, 20.989846502000105],
            ],
          ],
          [
            [
              [107.23890720300001, 20.986887409000055],
              [107.23815853400006, 20.986796657000092],
              [107.23745547600006, 20.987014639],
              [107.23539086400004, 20.988241885000036],
              [107.23435578200011, 20.989560842000095],
              [107.23360190600008, 20.990792603000052],
              [107.23359913300003, 20.991497947000056],
              [107.23378500300004, 20.991851263000072],
              [107.23481284300009, 20.99238384000008],
              [107.23691930800003, 20.992435226000069],
              [107.23888655100008, 20.992177500000061],
              [107.2394503830001, 20.991650424000063],
              [107.23964279600003, 20.990328551000026],
              [107.239650017, 20.98847701800004],
              [107.23937326600003, 20.987418034000079],
              [107.23890720300001, 20.986887409000055],
            ],
          ],
          [
            [
              [107.30826155700007, 20.990433341000077],
              [107.30642805800007, 20.990341257000097],
              [107.30386042000008, 20.990419525000092],
              [107.30312535200009, 20.990848931000095],
              [107.30275735900003, 20.991193154000065],
              [107.30321210900007, 20.992230717000027],
              [107.30558931700004, 20.994137775000077],
              [107.30650470200001, 20.994572368000064],
              [107.30751423100006, 20.994316499000028],
              [107.30990128700003, 20.993460524000085],
              [107.31100403700006, 20.99277321000006],
              [107.31109816400003, 20.992082748000129],
              [107.31064306700003, 20.991131538000026],
              [107.30981877200004, 20.990869931000056],
              [107.30826155700007, 20.990433341000077],
            ],
          ],
          [
            [
              [107.34878413500005, 20.991937956000051],
              [107.34731851900003, 20.99150190300012],
              [107.34658470100005, 20.991586083000065],
              [107.34584943400006, 20.992101978000029],
              [107.34575514000008, 20.992878792000091],
              [107.34648288600008, 20.994607833000067],
              [107.34776079700002, 20.996338481000087],
              [107.34904133600001, 20.997292038000026],
              [107.34940753000004, 20.997465801000104],
              [107.35069297300006, 20.996951525],
              [107.35198327800008, 20.994969397000048],
              [107.35207841800005, 20.993933552000051],
              [107.35125605700003, 20.9930677],
              [107.34878413500005, 20.991937956000051],
            ],
          ],
          [
            [
              [107.42439201500008, 20.994103066000044],
              [107.42359833800005, 20.993976381000046],
              [107.42267170700003, 20.994057041000055],
              [107.42139019700005, 20.994801417000097],
              [107.42059318600009, 20.995796297000076],
              [107.42032578400008, 20.996709485000054],
              [107.42032393200002, 20.997332587000095],
              [107.42063162400007, 20.997707251000094],
              [107.42164428100001, 20.998374548000065],
              [107.42252533900009, 20.998792255000076],
              [107.42331940300006, 20.998794328000066],
              [107.42446895700004, 20.997924977000082],
              [107.4253550390001, 20.996639538000039],
              [107.42540380900004, 20.995061128000089],
              [107.42500837700001, 20.994520077000089],
              [107.42439201500008, 20.994103066000044],
            ],
          ],
          [
            [
              [107.38130026300007, 21.002824306],
              [107.38056670100003, 21.002822252],
              [107.37826960400004, 21.004283625000042],
              [107.37716593200003, 21.005316633000071],
              [107.3770700920001, 21.00661149600009],
              [107.37761532200008, 21.008167189000027],
              [107.37816386600008, 21.00868678700003],
              [107.37981445000005, 21.008691423000059],
              [107.38192489800005, 21.008265625],
              [107.38293767900009, 21.006973325000011],
              [107.3827594790001, 21.005332321000125],
              [107.3821227980001, 21.003690032000044],
              [107.38175793200001, 21.003084616000045],
              [107.38130026300007, 21.002824306],
            ],
          ],
          [
            [
              [107.44630738500007, 21.004982557000048],
              [107.44566550500006, 21.004980949000092],
              [107.44410345200005, 21.006099476000095],
              [107.44336592200004, 21.007479096000026],
              [107.4415267570001, 21.009287638],
              [107.44097432900006, 21.010063312000057],
              [107.44143084800007, 21.01075520000002],
              [107.44197932200007, 21.011360982000049],
              [107.44381308300009, 21.011451938000022],
              [107.44518932600002, 21.011196380000015],
              [107.445925629, 21.010248467000011],
              [107.44648269700009, 21.007832285000028],
              [107.44685340800004, 21.00645173900002],
              [107.44685512299999, 21.005847350000103],
              [107.44630738500007, 21.004982557000048],
            ],
          ],
          [
            [
              [107.40455393100002, 21.009368175000098],
              [107.40349559100012, 21.009274704000092],
              [107.40233990800007, 21.009543426000029],
              [107.40185761800004, 21.009904582000068],
              [107.40147016000004, 21.010719061000046],
              [107.40137058600007, 21.011806161000024],
              [107.40194523400001, 21.012713858000076],
              [107.40232740600001, 21.013621033000085],
              [107.40338438200007, 21.01416757],
              [107.40425053400006, 21.014169905000109],
              [107.40540679500006, 21.013719953000098],
              [107.40627571500002, 21.012816147000102],
              [107.40666342300004, 21.011911046000087],
              [107.40637857300001, 21.010641678000042],
              [107.40561032800012, 21.010095929],
              [107.40455393100002, 21.009368175000098],
            ],
          ],
          [
            [
              [107.45529039600009, 21.011143625],
              [107.454867408, 21.01106305600009],
              [107.45427447400006, 21.011220636000068],
              [107.45376528200002, 21.011696515000011],
              [107.45325475900006, 21.012649517000078],
              [107.4526584700001, 21.013999905000077],
              [107.45214792600005, 21.014952900000061],
              [107.45197685600002, 21.015588652000083],
              [107.451975514, 21.016065773000037],
              [107.45273506800005, 21.016862870000082],
              [107.45400228700001, 21.017740734000064],
              [107.45501646400007, 21.018299884000058],
              [107.45560853100004, 21.018460392000101],
              [107.45628591100004, 21.018382534000054],
              [107.45721899400006, 21.017669138000095],
              [107.45781327200002, 21.017034423000069],
              [107.4581533960001, 21.016478608000085],
              [107.45823981100006, 21.015842654000046],
              [107.45824135800009, 21.015286008000089],
              [107.45739979800001, 21.013534466000024],
              [107.45647271300004, 21.012100799000066],
              [107.45588221900003, 21.011383641000059],
              [107.45529039600009, 21.011143625],
            ],
          ],
          [
            [
              [107.75049475600005, 21.014272726000094],
              [107.75261747300002, 21.013398093000113],
              [107.75448417600009, 21.013639365000088],
              [107.75643475500002, 21.014677997000049],
              [107.75770702800004, 21.015237498000069],
              [107.75838614400001, 21.015078784000082],
              [107.75906604800004, 21.014282233000081],
              [107.75957759000009, 21.012369295000077],
              [107.76055581700003, 21.010496704000033],
              [107.76259582600007, 21.007788087],
              [107.76463538300007, 21.005398365000083],
              [107.76786387800011, 21.002212558000011],
              [107.77151598900005, 20.999505518000113],
              [107.77482830400001, 20.99703724699999],
              [107.7794987610001, 20.994091888000099],
              [107.78272513800006, 20.992420714000026],
              [107.78399893100003, 20.991544913000034],
              [107.78433958400005, 20.990428995000073],
              [107.78434064200005, 20.989472207000048],
              [107.78366367100008, 20.987797164000099],
              [107.78290150200009, 20.986440968000061],
              [107.78120697000003, 20.984206776000036],
              [107.78010506900007, 20.983169147000069],
              [107.77900246600007, 20.982769380000029],
              [107.77849308500001, 20.983008072000061],
              [107.77806811200003, 20.983645515000049],
              [107.77815177700002, 20.98468212500002],
              [107.77916755700005, 20.986835925000058],
              [107.77950507300008, 20.988510646000066],
              [107.77958893800009, 20.989387793000077],
              [107.77890929500008, 20.990104704000068],
              [107.77780556500007, 20.990661723000059],
              [107.77610784000009, 20.991218135000089],
              [107.774919423, 20.991615590000023],
              [107.772711257, 20.993287702000082],
              [107.77084266400001, 20.994800668000032],
              [107.76668119800004, 20.997746378000045],
              [107.76379431800011, 20.999178480000054],
              [107.76226620000006, 20.99973496000009],
              [107.761248, 20.999654128000067],
              [107.76031534800002, 20.999015275000055],
              [107.75916938500006, 20.997884489000015],
              [107.75836609200003, 20.997019865000063],
              [107.75785814300011, 20.996062540000082],
              [107.75785992600008, 20.994627367000085],
              [107.75845747100004, 20.991757678000056],
              [107.75930876900006, 20.989526105000067],
              [107.75990509900001, 20.987613171000064],
              [107.75999093600009, 20.986815935],
              [107.75973687200003, 20.986417003000035],
              [107.75829547700005, 20.985538364000078],
              [107.75719339199999, 20.984739834],
              [107.75702419100003, 20.984340981000038],
              [107.75702488600008, 20.983782851000043],
              [107.75736498600007, 20.983225092000076],
              [107.75838437300004, 20.98226940800005],
              [107.75863784200004, 20.98211375300005],
              [107.76084688900004, 20.980757150000073],
              [107.76271514300008, 20.979483428000023],
              [107.76432842400007, 20.978528348000047],
              [107.76492247000002, 20.978449252000075],
              [107.76543127000011, 20.978688987000012],
              [107.76551535500005, 20.979326950000043],
              [107.76551449200005, 20.980044551000084],
              [107.76517365300001, 20.981240186000072],
              [107.76449244500004, 20.983232801],
              [107.76415140100008, 20.98458789300004],
              [107.76436173900007, 20.986063176000066],
              [107.76520882500009, 20.987260072000069],
              [107.7663104690001, 20.98845722300009],
              [107.7671584350001, 20.988936518000045],
              [107.76749783900003, 20.988936875000071],
              [107.76783734700005, 20.988857495000076],
              [107.76817742400003, 20.988299725000026],
              [107.76817827800002, 20.987582134000071],
              [107.76792467100009, 20.98678453900007],
              [107.76784048000005, 20.986226321000068],
              [107.76818064800003, 20.985588821000078],
              [107.76869033200011, 20.985110952000063],
              [107.76945417900006, 20.984952285000077],
              [107.77038773300004, 20.984793793000023],
              [107.77327268300009, 20.984796771000092],
              [107.77403653500005, 20.984638088000018],
              [107.774376218, 20.984399233000062],
              [107.77463123100006, 20.984000827000045],
              [107.77471682100003, 20.983363055000034],
              [107.774632798, 20.982645371000075],
              [107.77421001900011, 20.981369208000089],
              [107.77353390400003, 20.979056239000023],
              [107.77362014400006, 20.977860316000104],
              [107.77396027800005, 20.977222798000028],
              [107.77463998600007, 20.976426149000105],
              [107.77582922800005, 20.975231352],
              [107.77761247300008, 20.973957405000121],
              [107.77803743600006, 20.973319953000043],
              [107.77803807100003, 20.972761812000051],
              [107.77761529200004, 20.971485635000064],
              [107.77672565200007, 20.970408311000099],
              [107.77477577700002, 20.969050836000044],
              [107.77350357100001, 20.96865085899999],
              [107.77240059800005, 20.968649726000073],
              [107.77061857800004, 20.968887092000088],
              [107.76917584800005, 20.969204527000059],
              [107.76824255700009, 20.969203551000071],
              [107.76730974200009, 20.96880389400004],
              [107.76646206400004, 20.968165124000038],
              [107.76629323100011, 20.967447330000063],
              [107.76663318000007, 20.966969273000032],
              [107.76731240600004, 20.96657131100006],
              [107.7680761820001, 20.966412644000073],
              [107.76850078100006, 20.966094151000014],
              [107.76850144400002, 20.965536003000103],
              [107.76816301900008, 20.964738290000092],
              [107.76697703400001, 20.963222063000053],
              [107.76468870200003, 20.961226243000027],
              [107.76180692500007, 20.958910811000031],
              [107.75994162000009, 20.957951955000098],
              [107.75799120400011, 20.95723220000005],
              [107.75714280300008, 20.957231269000062],
              [107.75569993800006, 20.957708095000079],
              [107.75340886200003, 20.958024494000057],
              [107.75145795100008, 20.957703365000043],
              [107.74959250400011, 20.956903891000067],
              [107.74866050600008, 20.955946],
              [107.74789840500009, 20.95482881600006],
              [107.7471374400001, 20.95283451500007],
              [107.74586841000007, 20.950121994000078],
              [107.74485225000002, 20.948685549000068],
              [107.74324206000003, 20.947407880000043],
              [107.741546832, 20.946289573000065],
              [107.7387484650001, 20.94540915700005],
              [107.73730671600001, 20.945088534000078],
              [107.73671243700004, 20.945406777000031],
              [107.73671125300002, 20.946283892000039],
              [107.73764271800009, 20.947560805000087],
              [107.74234428100007, 20.952709403000114],
              [107.74488398200008, 20.956858671000056],
              [107.74683187000004, 20.959492216000086],
              [107.74894979800004, 20.961886715000055],
              [107.75089911600003, 20.963483634000021],
              [107.75378162700005, 20.965161313000102],
              [107.7584453260001, 20.96731931100004],
              [107.76014082400006, 20.968437450000096],
              [107.76098780700006, 20.969634397000014],
              [107.76115602700004, 20.970830597000045],
              [107.76106883500002, 20.97274413800001],
              [107.76059899900005, 20.975334996000058],
              [107.75898335100004, 20.978203660000091],
              [107.75657139900005, 20.981129754000079],
              [107.75609411000008, 20.981708769000115],
              [107.7518467400001, 20.98553123700011],
              [107.74692057200009, 20.989193351000068],
              [107.74250525000008, 20.991420755000064],
              [107.73851474900003, 20.993170170000042],
              [107.73681581400007, 20.994523597000025],
              [107.73630528200002, 20.995559505000116],
              [107.73630388000007, 20.996596009000079],
              [107.73676807000002, 20.998470240000074],
              [107.73693605900007, 20.999746145000039],
              [107.73685012200006, 21.0005433460001],
              [107.73532051000007, 21.002136141000079],
              [107.73141370800002, 21.004523345000067],
              [107.72996975800001, 21.005478351000036],
              [107.72979893600009, 21.006275438000088],
              [107.72996732800007, 21.007232407000039],
              [107.73157486100004, 21.010742475000121],
              [107.73386125800003, 21.01433306200002],
              [107.73674190700008, 21.017844580000045],
              [107.74013196599999, 21.021276907000086],
              [107.74377800700005, 21.023752734000119],
              [107.74606810500008, 21.024791843000031],
              [107.74708643000005, 21.024872732000063],
              [107.74768123400005, 21.024315318000042],
              [107.74751283400008, 21.023278667000064],
              [107.74675037200004, 21.02224132600012],
              [107.74615785700004, 21.021044730000057],
              [107.74615931100007, 21.019928535000076],
              [107.74684092800007, 21.017856375000044],
              [107.74820094700007, 21.016183624000107],
              [107.75049475600005, 21.014272726000094],
            ],
          ],
          [
            [
              [107.44876884700007, 21.018177369000043],
              [107.44835618300003, 21.01817633900005],
              [107.44734474800001, 21.019123560000082],
              [107.44605500700006, 21.021192491000114],
              [107.44568548000004, 21.022141306000016],
              [107.44563717000003, 21.023004592000035],
              [107.44604752300006, 21.023825844000044],
              [107.447098834, 21.024994062000069],
              [107.44874516200005, 21.026552282000033],
              [107.44970602100007, 21.027288561000113],
              [107.45030163100006, 21.027462719000042],
              [107.45076102200005, 21.027161670000091],
              [107.45099211600008, 21.026514701000032],
              [107.45072113700004, 21.025046262000032],
              [107.45044905800009, 21.023966334000121],
              [107.44999564300005, 21.022152070000018],
              [107.44981930100002, 21.019647767000023],
              [107.44968344500006, 21.019043050000086],
              [107.44940942900004, 21.01865383800012],
              [107.44876884700007, 21.018177369000043],
            ],
          ],
          [
            [
              [107.43802098800003, 21.011975140000061],
              [107.43666740600003, 21.011733127000106],
              [107.43573565800003, 21.011969319000023],
              [107.43480297500004, 21.01252359000005],
              [107.43386868000002, 21.013634504000045],
              [107.43352733600001, 21.014587891000055],
              [107.43318645200006, 21.015382235000054],
              [107.43318483300001, 21.015938881000103],
              [107.43377548800002, 21.016576560000111],
              [107.43521190300004, 21.017454974000046],
              [107.43707063900008, 21.018652526000011],
              [107.43816711800004, 21.020007174000099],
              [107.43850272200009, 21.021041802000084],
              [107.43951138400007, 21.023509501000071],
              [107.43992776700006, 21.025896162],
              [107.44060084300006, 21.02732922300001],
              [107.44245788600006, 21.029162867000117],
              [107.44296532300002, 21.02932319300006],
              [107.44355854800008, 21.02908612500007],
              [107.44432492900008, 21.027497652000093],
              [107.44466918800005, 21.025510520000076],
              [107.44416878900003, 21.022885091000099],
              [107.44366657800005, 21.02089580700008],
              [107.44223920200008, 21.016836641000047],
              [107.44156569400005, 21.015562600000059],
              [107.44004896900005, 21.013173117000058],
              [107.43903577600005, 21.012295802000033],
              [107.43802098800003, 21.011975140000061],
            ],
          ],
          [
            [
              [107.46035334900004, 21.023990729],
              [107.45897787400001, 21.023944205000063],
              [107.45787691100006, 21.024114186000098],
              [107.45741705700001, 21.024587927000127],
              [107.45714025200003, 21.025191621000069],
              [107.45713796800001, 21.026011837000077],
              [107.45768245000004, 21.028085316000045],
              [107.45823042299999, 21.028906886000073],
              [107.45864215100003, 21.029253246000046],
              [107.45919204800006, 21.029384105000034],
              [107.46015571000004, 21.029127442000025],
              [107.46112079200005, 21.028352741000056],
              [107.46139782800005, 21.027662703000068],
              [107.46135340400011, 21.027144566000096],
              [107.46135614800006, 21.0261516670001],
              [107.46126659400002, 21.025374391000092],
              [107.46081080600007, 21.024380380000096],
              [107.46035334900004, 21.023990729],
            ],
          ],
          [
            [
              [107.48886349, 21.027909933000011],
              [107.48835573100007, 21.027855743000053],
              [107.48773442000001, 21.028066359000071],
              [107.48688583200004, 21.028859590000067],
              [107.48586808200012, 21.029599409000085],
              [107.48513221600008, 21.03044590100005],
              [107.48456592000012, 21.031186763000065],
              [107.484225354, 21.031928148000034],
              [107.484224232, 21.032352251000042],
              [107.4845614330001, 21.032883163000093],
              [107.48512451300006, 21.033361595000066],
              [107.485688436, 21.0335219440001],
              [107.48630920800012, 21.033523387000038],
              [107.48715628400004, 21.033313301000064],
              [107.48823019900007, 21.032679633000036],
              [107.48975753000006, 21.031304835000064],
              [107.49032422300006, 21.030404923000035],
              [107.49043903000005, 21.029663006000106],
              [107.49015852400002, 21.029026199],
              [107.48948285600008, 21.028441495000024],
              [107.48886349, 21.027909933000011],
            ],
          ],
          [
            [
              [107.537831328, 21.024977581000122],
              [107.53719414800004, 21.024921823000042],
              [107.53667246400003, 21.025029580000073],
              [107.536440197, 21.025246802000105],
              [107.53632225100002, 21.026117389000106],
              [107.53625913300003, 21.028294336000016],
              [107.536315, 21.029165283000047],
              [107.53631189200004, 21.030471518000034],
              [107.53642492200007, 21.031669137],
              [107.53665318600004, 21.033139133000041],
              [107.53682493500004, 21.034010316],
              [107.53728586100007, 21.035099807000087],
              [107.53751723400003, 21.035263563000051],
              [107.53792255800009, 21.035373268000065],
              [107.53856027800001, 21.03521131800008],
              [107.53943027300005, 21.034832146000056],
              [107.54099721100002, 21.033746877000056],
              [107.54227493600004, 21.032443300000025],
              [107.54285765000003, 21.031029410000102],
              [107.54285982000003, 21.030104163000104],
              [107.54234156600006, 21.028742424000058],
              [107.54164849100009, 21.027815734000029],
              [107.53921948600001, 21.025960171000065],
              [107.537831328, 21.024977581000122],
            ],
          ],
          [
            [
              [107.42264214700006, 21.031736166000044],
              [107.42069636900001, 21.031333479000033],
              [107.42001893, 21.031411226000074],
              [107.41976403400005, 21.031728632000046],
              [107.41976308700006, 21.032046704000074],
              [107.41993072700008, 21.03260377900007],
              [107.42043461700003, 21.03395692800003],
              [107.42102339600007, 21.035230772000091],
              [107.42178196400005, 21.036346021000085],
              [107.42254289700003, 21.036666090000011],
              [107.42372802900003, 21.03666918200009],
              [107.4247443300001, 21.036512792000025],
              [107.42584622200005, 21.036038539000046],
              [107.42660950000005, 21.03556341000008],
              [107.42694974500006, 21.035007660000105],
              [107.42686696200005, 21.034371291000028],
              [107.42627674300006, 21.033574567000102],
              [107.42467164700004, 21.032457128000104],
              [107.42264214700006, 21.031736166000044],
            ],
          ],
          [
            [
              [107.82097576600009, 21.033183146000042],
              [107.8202642520001, 21.032596015000045],
              [107.81910692000008, 21.032846456000073],
              [107.81848355600002, 21.033181114000051],
              [107.81821528100002, 21.034521580000032],
              [107.81754113100003, 21.037031250000027],
              [107.81732159400008, 21.038375304000034],
              [107.818566952, 21.039214250000079],
              [107.81990163500002, 21.039718095000026],
              [107.82123702700002, 21.039467806000097],
              [107.82292936699999, 21.038212287000043],
              [107.823205, 21.037904426000061],
              [107.8231089060001, 21.036536576000039],
              [107.822576, 21.035279263000106],
              [107.82168706300004, 21.034021649000053],
              [107.82097576600009, 21.033183146000042],
            ],
          ],
          [
            [
              [107.49567496300003, 21.034445565000048],
              [107.4952799160001, 21.034444663000059],
              [107.49477132300004, 21.034708559000045],
              [107.49465666800006, 21.035397431000021],
              [107.49459830300002, 21.036139454000043],
              [107.49453981100007, 21.036934473000102],
              [107.49453719900004, 21.037941669000034],
              [107.49464842, 21.038578047000023],
              [107.49526701200001, 21.039427624000062],
              [107.49594314700001, 21.039853248000014],
              [107.49673243100003, 21.040173107000015],
              [107.49780417600007, 21.040387584000086],
              [107.49949726400008, 21.040391423000045],
              [107.50023120200007, 21.040287063000029],
              [107.50102198700003, 21.040023802000093],
              [107.50158729800009, 21.039654001000052],
              [107.50192699800004, 21.039230685000021],
              [107.50195738500007, 21.038382589000015],
              [107.50164821200006, 21.037904795000095],
              [107.50067883000006, 21.037304407000065],
              [107.49966408300003, 21.036878030000047],
              [107.49882984300011, 21.03657316900005],
              [107.49730923500006, 21.035350471000044],
              [107.49663325000009, 21.034871833000054],
              [107.49567496300003, 21.034445565000048],
            ],
          ],
          [
            [
              [107.48704027700003, 21.037155544000093],
              [107.4865956200001, 21.037112642000096],
              [107.48592757500008, 21.037446060000065],
              [107.48499189600001, 21.038071933000069],
              [107.48445628800002, 21.038782483000062],
              [107.48432074400009, 21.039577714000046],
              [107.48436367600009, 21.04016399200006],
              [107.48485182700006, 21.040583838000067],
              [107.48600637200011, 21.041298320000031],
              [107.48702826500002, 21.041719391000058],
              [107.48765095800012, 21.041720834000088],
              [107.48823027700008, 21.041303478000103],
              [107.48858874300008, 21.040299423000071],
              [107.488592039, 21.039043316000061],
              [107.48846101700008, 21.038121864000026],
              [107.48828399500009, 21.037786486000073],
              [107.48775125100006, 21.037408422000055],
              [107.48737117300006, 21.037244260000065],
              [107.48704027700003, 21.037155544000093],
            ],
          ],
          [
            [
              [107.37325265600008, 21.034116297000068],
              [107.37241490900004, 21.033982413],
              [107.37038678600001, 21.034765716000067],
              [107.36961654900004, 21.035355314000093],
              [107.36863462800007, 21.036601873000023],
              [107.36856094300001, 21.037785252000063],
              [107.36918107400007, 21.040417227000049],
              [107.37001268200005, 21.04245799800006],
              [107.37029101800007, 21.042787562000065],
              [107.37091988100006, 21.042723594],
              [107.37217952100011, 21.042003873000056],
              [107.37337056900006, 21.040889419000038],
              [107.37421341300008, 21.039445198000095],
              [107.37442803100006, 21.037867687000045],
              [107.374016119, 21.035630839000063],
              [107.37360020100004, 21.034643325000069],
              [107.37325265600008, 21.034116297000068],
            ],
          ],
          [
            [
              [107.54447458100006, 21.033318657000066],
              [107.54406938500001, 21.033154549000074],
              [107.54348948800001, 21.033371059000046],
              [107.54296663600007, 21.033968662000021],
              [107.54173033300002, 21.035160028000099],
              [107.54018001900009, 21.036303217000096],
              [107.53965702000006, 21.036955242000133],
              [107.53913310800006, 21.037988239000072],
              [107.53889787600005, 21.039457252000112],
              [107.53883594300008, 21.041144324000079],
              [107.5390648690001, 21.042342162000068],
              [107.53952635100009, 21.043213933000082],
              [107.54004641200004, 21.043813698000058],
              [107.54102989300006, 21.044468847000083],
              [107.54184032700006, 21.044797075000062],
              [107.54294072600007, 21.045017054000056],
              [107.54386793500005, 21.044964545000063],
              [107.54444773900006, 21.044802462000064],
              [107.54491229100003, 21.044368015000089],
              [107.545320686, 21.043171499000032],
              [107.5454389830001, 21.042137654000022],
              [107.54520873800006, 21.041484073000035],
              [107.54485012800005, 21.040490490000074],
              [107.54450477200007, 21.039343073000083],
              [107.54438718200002, 21.037875540000073],
              [107.54466543700005, 21.036925847000099],
              [107.54510657300004, 21.03560586800004],
              [107.54510809699998, 21.034952757],
              [107.5449365640001, 21.033972725000091],
              [107.54447458100006, 21.033318657000066],
            ],
          ],
          [
            [
              [107.55462276600004, 21.029475049000013],
              [107.55410144300006, 21.02941957400013],
              [107.55363769500008, 21.029527487000088],
              [107.55323150100003, 21.029798807000063],
              [107.55299849800012, 21.030342599000029],
              [107.55293918600009, 21.030941175000081],
              [107.55328332700006, 21.032465814000112],
              [107.55397311100002, 21.03486195900004],
              [107.554723091, 21.036278557000095],
              [107.55501093000001, 21.037095521000104],
              [107.55524070600009, 21.037966800000071],
              [107.55535398100005, 21.039109971000066],
              [107.55506129100009, 21.040415602000095],
              [107.55459490500004, 21.041666457000119],
              [107.55418607300005, 21.043080695000072],
              [107.55412626300009, 21.043896959000016],
              [107.55424003200008, 21.044822418000116],
              [107.554701827, 21.045585299],
              [107.55499104600005, 21.04580358300003],
              [107.55528063900003, 21.045858597000056],
              [107.55568623400011, 21.045859412000119],
              [107.55667188200003, 21.04558925700006],
              [107.55783283900007, 21.044666353000082],
              [107.55858978800009, 21.043035106000026],
              [107.55911533400005, 21.041240111000015],
              [107.55946655400007, 21.039662466000038],
              [107.55970224600003, 21.03792130500004],
              [107.559589069, 21.036723711000072],
              [107.55889785500008, 21.034926271000032],
              [107.55826360600001, 21.033564340000055],
              [107.55682056600008, 21.031166695000088],
              [107.55618498100007, 21.030403442000086],
              [107.55543340000006, 21.029694388000038],
              [107.55462276600004, 21.029475049000013],
            ],
          ],
          [
            [
              [107.54345348500004, 21.009702004000097],
              [107.543165426, 21.008815496000111],
              [107.542369949, 21.007791625000038],
              [107.54142989700003, 21.006563021000076],
              [107.54103184500005, 21.006187378000085],
              [107.54074331200006, 21.005505299000049],
              [107.54060017500004, 21.004687228000037],
              [107.540603065, 21.003460562000065],
              [107.54075037600003, 21.00250679000002],
              [107.54104291600007, 21.001485170000045],
              [107.54177046500006, 21.000532598000099],
              [107.54344267100004, 20.998832340000014],
              [107.54526054100008, 20.996859758000049],
              [107.54700568800006, 20.994955171000029],
              [107.54773397400001, 20.993661823000039],
              [107.54773555500005, 20.992980332000052],
              [107.54751939600001, 20.992366543000074],
              [107.54686901800002, 20.991342967000023],
              [107.54502489600003, 20.989056154000082],
              [107.54394018500008, 20.987690920000098],
              [107.54293910000011, 20.986404846000092],
              [107.54151751600008, 20.984640383000027],
              [107.53995065100003, 20.984742507000043],
              [107.53860533500009, 20.985793507000047],
              [107.53726939900005, 20.986995555000121],
              [107.53639908600007, 20.986993731000055],
              [107.53574699900003, 20.986719760000128],
              [107.5348784760001, 20.985968284000123],
              [107.53430055600008, 20.985012961000038],
              [107.53430202100002, 20.984399609],
              [107.53452073300008, 20.983923011000094],
              [107.53655547700004, 20.982223522000076],
              [107.53800821600007, 20.981272451000073],
              [107.53837164800004, 20.980932457],
              [107.53830073900011, 20.9802508000001],
              [107.53786785500009, 20.97929578100004],
              [107.53707382100004, 20.977726640000057],
              [107.53594602300006, 20.97472286900004],
              [107.53461282900007, 20.970715516000027],
              [107.53283415000007, 20.965864114000077],
              [107.53150133300001, 20.961751300000039],
              [107.53071637000008, 20.958905137],
              [107.52915263300011, 20.954401737000069],
              [107.52864718300003, 20.953514657000127],
              [107.52850143200004, 20.953169918000114],
              [107.52610371600008, 20.946925122000067],
              [107.52477115000005, 20.942875750000042],
              [107.52308189700007, 20.938319754000112],
              [107.52130323799999, 20.933763508000069],
              [107.51982383600006, 20.931123224000103],
              [107.51801268000004, 20.92871725400007],
              [107.51541065200004, 20.925440022000075],
              [107.51302350100006, 20.923253749000104],
              [107.50911661500001, 20.919973544000037],
              [107.50737882600002, 20.919083623000034],
              [107.50621881700005, 20.919081033000083],
              [107.50455096200008, 20.919213611000131],
              [107.50331792200011, 20.919415320000034],
              [107.50186808500005, 20.919343900000072],
              [107.50005678700002, 20.918862699000051],
              [107.49661538400002, 20.917934757000097],
              [107.49096571000011, 20.915877074000022],
              [107.48748770100011, 20.915119295000025],
              [107.48611022000009, 20.915116096000119],
              [107.48502165400008, 20.915522508],
              [107.483859142, 20.91647401000003],
              [107.48247712100004, 20.918174726000061],
              [107.48167780500007, 20.918854430000074],
              [107.48095226200009, 20.919057204000069],
              [107.48037243400007, 20.91898768500009],
              [107.47993888900008, 20.918441399000095],
              [107.4799412510001, 20.917555349000061],
              [107.48023724200007, 20.915306827000087],
              [107.48024196400011, 20.913534725000105],
              [107.48017182800008, 20.912648497000049],
              [107.47981078400005, 20.912102385000075],
              [107.47908726700005, 20.911555408000112],
              [107.47843478300007, 20.911553869000073],
              [107.47763566200003, 20.91216541000005],
              [107.47632594300011, 20.913934426000047],
              [107.47530784400007, 20.915090706000058],
              [107.47465498100004, 20.915225467000106],
              [107.47414804300003, 20.915019792000052],
              [107.4736422, 20.914405162],
              [107.47255877200007, 20.912903089000068],
              [107.47132907300002, 20.91187777300005],
              [107.46926650300007, 20.910543729000075],
              [107.46557323900002, 20.909035328000058],
              [107.46021393300006, 20.907045686000103],
              [107.45789557500008, 20.906494742000078],
              [107.45528590800008, 20.906420157000035],
              [107.45296546800002, 20.906618905000052],
              [107.4511523, 20.90688704600003],
              [107.44948374300006, 20.907291847000039],
              [107.44890280600005, 20.907631194000054],
              [107.44875646600011, 20.908107947000062],
              [107.44904471700006, 20.908722099000073],
              [107.4504181290001, 20.910156853000039],
              [107.45186366500008, 20.911728097000015],
              [107.45207943400007, 20.912342061000096],
              [107.4520057870001, 20.912750827000082],
              [107.45135235100004, 20.913089998000075],
              [107.45011910200006, 20.913359567],
              [107.44779857500008, 20.91355825],
              [107.44435479600006, 20.913583698000068],
              [107.44261580200006, 20.913238526000022],
              [107.44000742100008, 20.912686659000101],
              [107.43609448600006, 20.911995121000082],
              [107.43261736400004, 20.911031996000062],
              [107.42906976600005, 20.909387045000116],
              [107.42516055200008, 20.907468454000089],
              [107.42306118500001, 20.906440594000038],
              [107.42146687800007, 20.906231944000098],
              [107.41914762400003, 20.90602137700008],
              [107.41625024200002, 20.905195833000079],
              [107.4145853090001, 20.904373527000075],
              [107.41103798300006, 20.9027282690001],
              [107.4086489740001, 20.901631325000025],
              [107.40698471500006, 20.900604467000058],
              [107.40590125500003, 20.899306516000046],
              [107.40510860800001, 20.897736697000099],
              [107.40482508700008, 20.895622967000094],
              [107.40439201300003, 20.895008354000041],
              [107.40374125100001, 20.894461311000079],
              [107.40156881100003, 20.893705672000081],
              [107.39780221500004, 20.892741185000126],
              [107.39519403300008, 20.892256929000027],
              [107.39330991200006, 20.892047286],
              [107.39272998400003, 20.892045692000046],
              [107.39236689800001, 20.892249171000095],
              [107.39229313500005, 20.892657940000078],
              [107.39221745900008, 20.893680142000015],
              [107.39220132, 20.898860290000073],
              [107.39212202200009, 20.901041186000043],
              [107.39269900600004, 20.901997011000084],
              [107.39363910100012, 20.902749357000069],
              [107.39508708400011, 20.903366764000083],
              [107.39610158800011, 20.903505855000049],
              [107.39776897100005, 20.90351040700007],
              [107.40012557600008, 20.903346420000091],
              [107.40193814500005, 20.903283176000095],
              [107.40309723400003, 20.903558951],
              [107.40534083000003, 20.904791875000051],
              [107.41004354300003, 20.907939805000098],
              [107.412432842, 20.908968557000037],
              [107.41402450000007, 20.910063333000025],
              [107.41489057300005, 20.911360646000077],
              [107.41640589300002, 20.913750202000081],
              [107.41748889300001, 20.915252548000041],
              [107.41871914200001, 20.916005526000077],
              [107.42125521100007, 20.916489287000047],
              [107.42306770400002, 20.916494027000084],
              [107.42560458300005, 20.916705115000056],
              [107.42785167200007, 20.916847258000089],
              [107.43002547700007, 20.917261832000023],
              [107.431546779, 20.917674697000074],
              [107.43256059000005, 20.918086249000019],
              [107.432994804, 20.918359997000024],
              [107.43292091700002, 20.918836908000046],
              [107.43197523500002, 20.919925010000014],
              [107.43139244200003, 20.920877720000064],
              [107.43142699800005, 20.921457146000044],
              [107.43207753000004, 20.922140394000088],
              [107.43366960400006, 20.923166841000103],
              [107.4355518990001, 20.924125862000054],
              [107.439316338, 20.926112004000075],
              [107.44228902900002, 20.928694641000064],
              [107.44537761000005, 20.931253029000096],
              [107.45062751800002, 20.934111667000096],
              [107.45420006600003, 20.936755262000069],
              [107.45877928400002, 20.939506635000079],
              [107.46369370000012, 20.942469497],
              [107.46838420799999, 20.945537083000062],
              [107.4705056130001, 20.947122990000096],
              [107.47459878900003, 20.949542068000056],
              [107.47726304300002, 20.952137771000068],
              [107.47823274100004, 20.953139240000063],
              [107.47937679100002, 20.955057006000018],
              [107.47981545300009, 20.956306993000062],
              [107.48034142100008, 20.957836356000122],
              [107.48077306900009, 20.959132286000056],
              [107.48149587100004, 20.960019979000052],
              [107.48222048000012, 20.960226132000059],
              [107.48316336800006, 20.960160187000021],
              [107.48367134500009, 20.960025072000029],
              [107.48450317300008, 20.959476121000087],
              [107.48530318500011, 20.958591998000117],
              [107.48635344500005, 20.957654509000086],
              [107.48745023300003, 20.956966958000045],
              [107.48850169700007, 20.956969394000069],
              [107.48904358700005, 20.957720343000027],
              [107.48904144400005, 20.958538175000015],
              [107.48867636900005, 20.959491479000022],
              [107.48736661100003, 20.961192273000059],
              [107.48663713900007, 20.962826253000074],
              [107.48663319200001, 20.964325611000056],
              [107.48691644500009, 20.966916070000075],
              [107.48730786000006, 20.969745299000039],
              [107.48780797300005, 20.972608836000092],
              [107.48845654900006, 20.974177831000063],
              [107.4893952190001, 20.975747490000082],
              [107.49040660400003, 20.977249159000074],
              [107.49069366400008, 20.978408387000066],
              [107.49061829300005, 20.979498638000038],
              [107.49018137700006, 20.980179140000033],
              [107.48952741400004, 20.980654684000029],
              [107.48822109900004, 20.980992417],
              [107.48669809800006, 20.980988886000063],
              [107.48575690700008, 20.980373338000021],
              [107.4849629280001, 20.978940321000024],
              [107.48424059100003, 20.977848220000084],
              [107.48236079500001, 20.975662978000017],
              [107.48141964400003, 20.975047406000066],
              [107.48091162600009, 20.975182513000057],
              [107.48047523000005, 20.975658554000098],
              [107.47945428600005, 20.977768841000085],
              [107.47785057500005, 20.980831864000038],
              [107.47624847100009, 20.983281499000036],
              [107.475663506, 20.98505203200007],
              [107.47573383200006, 20.98587],
              [107.47616770800008, 20.986348086000106],
              [107.47674754200008, 20.986485756000036],
              [107.47798065900005, 20.986420523000056],
              [107.47936083700003, 20.985605969000076],
              [107.47980476800009, 20.985281753000073],
              [107.48077147300005, 20.984721901000022],
              [107.48145666000008, 20.984723510000066],
              [107.482457294, 20.985023668000032],
              [107.48403467400004, 20.986466774000064],
              [107.48520114200001, 20.987963158000063],
              [107.48580732300007, 20.988755973000117],
              [107.48790396900009, 20.991282389000077],
              [107.48916775600003, 20.993363870000081],
              [107.4898878700001, 20.995341866000047],
              [107.49046561200004, 20.996297289000054],
              [107.49205770100005, 20.997663934000066],
              [107.49401176300007, 20.999303990000051],
              [107.49487859900003, 21.000668955],
              [107.49617658000005, 21.003602305000065],
              [107.49848779500005, 21.00742385100007],
              [107.50079979000003, 21.010972767000077],
              [107.5034025400001, 21.014317856],
              [107.50557118800003, 21.017253038000035],
              [107.50672919500001, 21.018277825],
              [107.50781656900008, 21.018552828000026],
              [107.50861482900007, 21.018418314000087],
              [107.50912396000008, 21.017874268000099],
              [107.509127221, 21.016579485000086],
              [107.50860959700007, 21.014373431000074],
              [107.50720463500008, 21.01066079600011],
              [107.50615242600007, 21.008327138000112],
              [107.50566913000004, 21.007235586000036],
              [107.50538228400004, 21.005940132000056],
              [107.50466076100001, 21.00443925200004],
              [107.5032888910001, 21.001982837000099],
              [107.50242217400006, 21.000549760000098],
              [107.502423392, 21.000072726000063],
              [107.50271386000004, 20.999937077000119],
              [107.50329392700007, 21.000006537],
              [107.50561353500007, 21.000556921000069],
              [107.5077877410001, 21.001243257000013],
              [107.50872927300003, 21.001790543000084],
              [107.50887261800007, 21.002472346000012],
              [107.50857716800004, 21.004584294000047],
              [107.50857218599999, 21.006560579000052],
              [107.50885905400001, 21.007856028000035],
              [107.50936542199999, 21.00840232900012],
              [107.51052408100003, 21.00915453],
              [107.511610214, 21.009906554000054],
              [107.51215192000006, 21.010827740000067],
              [107.51337667300007, 21.014169658000093],
              [107.51366240300007, 21.015942116000105],
              [107.51380373600004, 21.017441653000013],
              [107.51423453899999, 21.01921441900005],
              [107.51524498600001, 21.021261030000105],
              [107.51669018800004, 21.023513021],
              [107.51755830200001, 21.02446896200005],
              [107.51835623300005, 21.024470702000109],
              [107.51915535000009, 21.023995414000041],
              [107.52002683500007, 21.023588433000029],
              [107.52046206800003, 21.023589378000018],
              [107.52147594800007, 21.024273034000096],
              [107.52299395200008, 21.026456986000049],
              [107.52642998300007, 21.032058667000086],
              [107.52872445300002, 21.036060355000075],
              [107.53147177400005, 21.039371846000066],
              [107.53393228400006, 21.041898383000031],
              [107.53459953900003, 21.042052826000109],
              [107.53510736900003, 21.04205389400002],
              [107.53532549300006, 21.041849926000019],
              [107.53511175800004, 21.040214018000114],
              [107.53489850400007, 21.038373669000073],
              [107.53472682700009, 21.034318700000121],
              [107.53473365900003, 21.0314566200001],
              [107.53444739900006, 21.029820531],
              [107.53409103600006, 21.027162105000038],
              [107.53344192600008, 21.025593381000093],
              [107.53250217100005, 21.024228479000051],
              [107.53134497700005, 21.022794964000099],
              [107.53120152900007, 21.022113199],
              [107.53120529900012, 21.020545839000079],
              [107.53157553000003, 21.017411875000036],
              [107.5323804720001, 21.014483259000102],
              [107.53296745000009, 21.01169046600004],
              [107.53333421000005, 21.009987545000094],
              [107.53369769400011, 21.009647574000034],
              [107.53406052500002, 21.009580194000051],
              [107.53464080400008, 21.009581416000067],
              [107.53529214500006, 21.010196116000124],
              [107.53615818300003, 21.012037920000104],
              [107.5374568710001, 21.014970973000047],
              [107.53875703900003, 21.017290684000045],
              [107.54063449600002, 21.020906362000069],
              [107.54225997100005, 21.023737801000038],
              [107.544212008, 21.026535820000092],
              [107.54602043700007, 21.028720195000083],
              [107.547902407, 21.030495837],
              [107.54877023200004, 21.031656073000079],
              [107.54920234000008, 21.033019855000035],
              [107.54919966100005, 21.034178318000052],
              [107.54854376700004, 21.03547173200004],
              [107.54810613700006, 21.036493007000075],
              [107.54781279100004, 21.037855297000085],
              [107.54766343200004, 21.03969489300006],
              [107.54773154900008, 21.041603070000022],
              [107.54801620000009, 21.043988687000059],
              [107.54844896200009, 21.045079867000105],
              [107.54910000200005, 21.045898924000106],
              [107.54953512700008, 21.045967952000041],
              [107.55026123800003, 21.045696854000084],
              [107.551497528, 21.044404635000099],
              [107.55258980400005, 21.042635112000063],
              [107.55306393600003, 21.0415116940001],
              [107.5533569290001, 21.04028569000009],
              [107.55350467300008, 21.039127532000101],
              [107.55314570000004, 21.037491340000081],
              [107.55220809800009, 21.035104384000114],
              [107.55156037400005, 21.032854288000102],
              [107.55077009500003, 21.029513569000088],
              [107.549977493, 21.027195010000099],
              [107.54932730000003, 21.02603520400006],
              [107.54791615800006, 21.024567186000098],
              [107.54603457300009, 21.022655239000088],
              [107.54444364400007, 21.020539435000082],
              [107.54377040200006, 21.0185365990001],
              [107.54351420500009, 21.016488300000091],
              [107.54351900100005, 21.014440533000119],
              [107.54359536100006, 21.01106524700009],
              [107.54345348500004, 21.009702004000097],
            ],
          ],
          [
            [
              [107.85910997300007, 21.04087774800005],
              [107.85946722300005, 21.039202129000053],
              [107.85920097700004, 21.038112667000064],
              [107.85848945200006, 21.037358075000064],
              [107.8577781060001, 21.036352108000052],
              [107.85751175000007, 21.035430217000069],
              [107.85751248600005, 21.034424703000099],
              [107.85786986900004, 21.032581488000091],
              [107.85849358900005, 21.031660164000066],
              [107.86009588900002, 21.031409808000134],
              [107.86178743300009, 21.030824316000093],
              [107.86241095100007, 21.030154354000082],
              [107.86241160200004, 21.029232626000024],
              [107.86170014700006, 21.028394248000026],
              [107.86072157000009, 21.027723289000022],
              [107.85716136900004, 21.027721018000101],
              [107.85547052100003, 21.027384737000077],
              [107.85306814300003, 21.026377626000055],
              [107.85071078000003, 21.024742058000086],
              [107.84743041900003, 21.022038576000043],
              [107.84546301700006, 21.020381144000041],
              [107.84297237900003, 21.018619689000047],
              [107.84128265900003, 21.017026380000026],
              [107.84025144300006, 21.015529701000091],
              [107.84025236700005, 21.014402369000074],
              [107.84016082600002, 21.01370855000004],
              [107.84004002700006, 21.012919495000034],
              [107.83932915100004, 21.011578246000084],
              [107.83763980100007, 21.009649700000033],
              [107.83675046200003, 21.008894881000018],
              [107.83559350300004, 21.008894024000121],
              [107.83461433300002, 21.009144678000077],
              [107.8339023560001, 21.009144145000036],
              [107.83287965600002, 21.008263512],
              [107.83243611500009, 21.006587249000042],
              [107.83243741200002, 21.005078905000026],
              [107.83306231200004, 21.002816858000095],
              [107.833330227, 21.001727692000124],
              [107.83324187500006, 21.000973443000071],
              [107.83270842500006, 21.000386464000087],
              [107.83226353400002, 21.000302328000053],
              [107.8311061900001, 21.0008042320001],
              [107.82977115900005, 21.000970798000118],
              [107.82772446500007, 21.000801623000065],
              [107.82603420100003, 21.000129915000088],
              [107.82425511300008, 20.999290533000092],
              [107.82087587800008, 20.996606290000038],
              [107.818653985, 20.993503935000035],
              [107.81794403200006, 20.991408389000107],
              [107.81794567600011, 20.989648606000074],
              [107.81883617700007, 20.98897895],
              [107.82008269600009, 20.988225770000035],
              [107.82088442400007, 20.987304629000022],
              [107.82146372100004, 20.986341403],
              [107.82119760600006, 20.985419393000093],
              [107.82021946200004, 20.984664406000064],
              [107.81870702000003, 20.984327965000112],
              [107.81683864600008, 20.98399122800005],
              [107.81541471900003, 20.984157647000053],
              [107.81372384100011, 20.984323822000036],
              [107.81203215200003, 20.98532798800008],
              [107.81114149100006, 20.986165223000071],
              [107.81051639000002, 20.988427279000099],
              [107.80944682400008, 20.990186138000055],
              [107.80677568500005, 20.991692202000053],
              [107.8039263690001, 20.993365662000052],
              [107.80330226100007, 20.994538291000083],
              [107.80321208300006, 20.995711383000071],
              [107.803744765, 20.996968834000107],
              [107.80587963200007, 20.997892497000059],
              [107.80819258500004, 20.998732493000105],
              [107.80925925700009, 20.999990379000089],
              [107.80979198000003, 21.001247807000119],
              [107.80934570300006, 21.002588179],
              [107.80800997100003, 21.003424994000049],
              [107.80649674900006, 21.003758860000055],
              [107.80516166700008, 21.003925285000058],
              [107.80471627600009, 21.00434387600005],
              [107.80516016000004, 21.00543363200007],
              [107.80693839200009, 21.007111123000044],
              [107.80911816800005, 21.007741494000065],
              [107.81054193700001, 21.007910306],
              [107.81152057500006, 21.008246335000088],
              [107.8131216190001, 21.009169454000087],
              [107.81418862000001, 21.010175911000047],
              [107.81480952900006, 21.01235512600006],
              [107.81596263100008, 21.016462073000049],
              [107.81715952400008, 21.02136508800001],
              [107.818491984, 21.024131403000098],
              [107.82062536800004, 21.027065939000074],
              [107.82222515700008, 21.029581046000075],
              [107.82311453400003, 21.030335897],
              [107.82400444700004, 21.030504197000077],
              [107.82827675300003, 21.030507557000085],
              [107.82872149100005, 21.030843072000046],
              [107.82925486500005, 21.031597630000043],
              [107.82943141, 21.033273625000071],
              [107.82934064700004, 21.035284587000014],
              [107.83031747600003, 21.037882904000057],
              [107.83236152500005, 21.04157132400006],
              [107.8337844280001, 21.043080643000067],
              [107.83591959000002, 21.044422910000016],
              [107.836898445, 21.044758797],
              [107.83814490200011, 21.044424550000059],
              [107.83921374600008, 21.043587407000075],
              [107.83957075800005, 21.042414588000021],
              [107.84037290100007, 21.04115828500008],
              [107.84170874300003, 21.040321327000107],
              [107.84446861400002, 21.039652954000054],
              [107.84660480200003, 21.039738231000094],
              [107.84811739500003, 21.040493409000049],
              [107.84989652000007, 21.041919087000061],
              [107.85087429700005, 21.043679372000042],
              [107.851585314, 21.045104305000081],
              [107.85247499300007, 21.045691442000077],
              [107.8530088250001, 21.046026960000042],
              [107.85429973500005, 21.045734548],
              [107.85750568500002, 21.043725641000051],
              [107.85848549700009, 21.042804563000047],
              [107.85910997300007, 21.04087774800005],
            ],
          ],
          [
            [
              [107.43978987300001, 21.044423714000082],
              [107.43868980000012, 21.044261890000101],
              [107.4378425570001, 21.044498294000036],
              [107.4375872140001, 21.044974746000022],
              [107.43783842800011, 21.045929596000065],
              [107.43851201600006, 21.047203584000052],
              [107.43927027300001, 21.048477791000053],
              [107.44045091200009, 21.05007111700008],
              [107.44129636200005, 21.050470840000024],
              [107.44231181100005, 21.050632436000072],
              [107.44307373200012, 21.050634356000053],
              [107.44366725900002, 21.050317785000061],
              [107.44451678700005, 21.049286196000061],
              [107.44468882700008, 21.048332422000016],
              [107.44435337000003, 21.047218339000025],
              [107.44300294400003, 21.04578363100007],
              [107.44139696800002, 21.044904890000012],
              [107.43978987300001, 21.044423714000082],
            ],
          ],
          [
            [
              [107.77761300500003, 21.04722086400006],
              [107.777171, 21.044539092000036],
              [107.7777072800001, 21.042612424000048],
              [107.77851019400001, 21.041021190000016],
              [107.77975701100006, 21.04043588899999],
              [107.78091472200001, 21.039934286000062],
              [107.78109330600005, 21.039431709000041],
              [107.78064910200005, 21.038677142000047],
              [107.77833665200004, 21.037082777000116],
              [107.77584593500011, 21.035739586000091],
              [107.77255378200002, 21.034730710000105],
              [107.76863782200012, 21.034391478000103],
              [107.76498919500003, 21.033801078000103],
              [107.76249738700008, 21.033463246000039],
              [107.76134028300001, 21.033462],
              [107.76080593300003, 21.033712797000078],
              [107.76053839200009, 21.034131472000084],
              [107.76080439100008, 21.034969686000068],
              [107.76160454100003, 21.035724691000013],
              [107.7625824080001, 21.036731256000074],
              [107.76320394500001, 21.03798880600003],
              [107.76364665700005, 21.0399165030001],
              [107.76373364000005, 21.04159243200003],
              [107.76337576700011, 21.043100303000095],
              [107.76275155100006, 21.044021342000093],
              [107.76248370400002, 21.044691390000111],
              [107.76266111500004, 21.045194330000072],
              [107.76337261300003, 21.045697841000077],
              [107.76435155200002, 21.045866464000085],
              [107.76568715800005, 21.04553272],
              [107.76737920800007, 21.044864181000023],
              [107.76871429500005, 21.044949365000051],
              [107.77013781700006, 21.045537391000032],
              [107.77164985500011, 21.046544454000049],
              [107.77253823300006, 21.048053611000036],
              [107.77351476700005, 21.050316964000082],
              [107.77431473900006, 21.05132327100003],
              [107.77529352300004, 21.051659427000047],
              [107.77707355900007, 21.051912605000034],
              [107.77778596800005, 21.051661945000077],
              [107.77778749700008, 21.050321299000082],
              [107.77761300500003, 21.04722086400006],
            ],
          ],
          [
            [
              [107.5224832050001, 21.046497751000089],
              [107.52196198000004, 21.04638777900005],
              [107.52132420900003, 21.046549678000034],
              [107.52056880900005, 21.047418835000094],
              [107.51987120300006, 21.048342556000051],
              [107.51981178400004, 21.048941096000107],
              [107.52027426700002, 21.049377500000062],
              [107.52160470900006, 21.05030560400003],
              [107.52287627600002, 21.051614547000064],
              [107.52333797300003, 21.052377494000041],
              [107.52380019700009, 21.052922732000035],
              [107.52414773700006, 21.052977902000066],
              [107.52461169, 21.052815633000051],
              [107.52525107900004, 21.052000634000123],
              [107.52577588800004, 21.050641142000082],
              [107.52600991800004, 21.049716422000039],
              [107.52595329700006, 21.049172046000116],
              [107.52560669000007, 21.048735903000093],
              [107.52416089300009, 21.047589872000039],
              [107.5224832050001, 21.046497751000089],
            ],
          ],
          [
            [
              [107.46822183500005, 21.05176549200003],
              [107.46715492400006, 21.051762923000084],
              [107.46626457200003, 21.052221074000066],
              [107.46550700300006, 21.052888769000063],
              [107.46523797300009, 21.053725019000069],
              [107.46528105200009, 21.054227270000055],
              [107.46568011900007, 21.054604838000031],
              [107.466612078, 21.055192917000021],
              [107.4675889480001, 21.055613724000111],
              [107.46874513700001, 21.055490971000076],
              [107.469680071, 21.054991069000017],
              [107.47026026700004, 21.054155561000073],
              [107.47026186000009, 21.053569734000099],
              [107.47008529100005, 21.053109014000079],
              [107.46959822600007, 21.052396480000098],
              [107.4691101290001, 21.052060547000018],
              [107.46822183500005, 21.05176549200003],
            ],
          ],
          [
            [
              [107.37524850200001, 21.043393408],
              [107.37385253900005, 21.0430606890001],
              [107.37203494400003, 21.043515822000103],
              [107.371264889, 21.044039669000099],
              [107.37084259900006, 21.045024779000073],
              [107.37055745300009, 21.046799340000085],
              [107.37047890100007, 21.049495030000017],
              [107.37068123500003, 21.051731237000034],
              [107.37165049900003, 21.054429885000033],
              [107.37290301800002, 21.055945768000051],
              [107.37366952300002, 21.056539711000077],
              [107.37464854900001, 21.056213718000016],
              [107.37583883700009, 21.055362292],
              [107.37703204800005, 21.053590318000111],
              [107.37752690200006, 21.051750612000127],
              [107.37754181300005, 21.047082121000038],
              [107.37719696700002, 21.045700315000104],
              [107.37615382800004, 21.044250779000073],
              [107.37524850200001, 21.043393408],
            ],
          ],
          [
            [
              [107.43146819300009, 21.053476133000082],
              [107.43040934500004, 21.053473408000031],
              [107.42973473400009, 21.053743493000084],
              [107.42925211000004, 21.054195292000074],
              [107.42905640400001, 21.055282077000115],
              [107.429919302, 21.056462201000059],
              [107.4327061180001, 21.05810030500006],
              [107.43482202400003, 21.058739965000051],
              [107.43588064700003, 21.058833278000044],
              [107.43665205000009, 21.058382212000112],
              [107.43684719300008, 21.057476636000061],
              [107.43694606900009, 21.056570813000071],
              [107.436756164, 21.055664254000114],
              [107.43570019100011, 21.054664874000117],
              [107.43444960400002, 21.054389857000011],
              [107.43291129000002, 21.053751661000049],
              [107.43146819300009, 21.053476133000082],
            ],
          ],
          [
            [
              [107.53799414800005, 21.054041315000035],
              [107.53729880700008, 21.054039861],
              [107.5361965500001, 21.054581802000079],
              [107.53538362800006, 21.055287612000129],
              [107.53491888900007, 21.055776452000075],
              [107.53474349100004, 21.05642917600008],
              [107.53468398500009, 21.057082142000105],
              [107.53491356400008, 21.058007835000048],
              [107.53554876600006, 21.058934379000057],
              [107.53653179000003, 21.059807226000068],
              [107.53716843800004, 21.060135108000019],
              [107.53769021600004, 21.060027347000073],
              [107.5385034060001, 21.059212691000084],
              [107.53931891600007, 21.057418387000034],
              [107.53955480900005, 21.055677309000131],
              [107.53932432000008, 21.055132580000091],
              [107.53863089800008, 21.054314774000105],
              [107.53799414800005, 21.054041315000035],
            ],
          ],
          [
            [
              [107.86161920200003, 21.054131115000096],
              [107.86089357900002, 21.054081873000108],
              [107.86027130600006, 21.054471792000037],
              [107.85970069100009, 21.055105684000083],
              [107.85969998900003, 21.056081469000084],
              [107.86042376700006, 21.058716520000061],
              [107.86135497000001, 21.061254115000061],
              [107.86182103000003, 21.061888653000068],
              [107.86259838400004, 21.062133086000046],
              [107.86379072000003, 21.061938670000032],
              [107.8644133490001, 21.061060858000054],
              [107.86446582300002, 21.060133908000097],
              [107.86467492900007, 21.057597022000074],
              [107.86441681200004, 21.056084411000064],
              [107.86379542600005, 21.055205826000112],
              [107.86275932000004, 21.054424564000037],
              [107.86161920200003, 21.054131115000096],
            ],
          ],
          [
            [
              [107.502591072, 21.058630977000092],
              [107.50133967000002, 21.058628158000126],
              [107.49979878699999, 21.058896499000035],
              [107.4989317270001, 21.059166357000066],
              [107.49844948400008, 21.059527694000032],
              [107.49806164000006, 21.060614093000041],
              [107.49854132200004, 21.061249430000117],
              [107.49930979500003, 21.06188541900007],
              [107.50113717100007, 21.062523796000043],
              [107.50258112700007, 21.062527048000042],
              [107.50392997700004, 21.062077040000112],
              [107.50412457200005, 21.06126201900009],
              [107.50412803000008, 21.059902920000084],
              [107.50364903500005, 21.058995784000075],
              [107.502591072, 21.058630977000092],
            ],
          ],
          [
            [
              [107.38004987600009, 21.057152152000036],
              [107.37950937400004, 21.057072326000089],
              [107.37896724900003, 21.057501494000071],
              [107.37792359400001, 21.058673151000029],
              [107.37725442500007, 21.059806704000025],
              [107.37716847800006, 21.060667828000042],
              [107.37716710200004, 21.061098503000061],
              [107.37754010200008, 21.061530240000081],
              [107.37853568100003, 21.062394403000084],
              [107.37994774800006, 21.063103111000103],
              [107.38061342900008, 21.063065827000038],
              [107.38103052000004, 21.062714618000108],
              [107.381282341, 21.062010577000123],
              [107.38136876600005, 21.060992836000111],
              [107.38108256800008, 21.059425922000052],
              [107.38071255000003, 21.058054529000088],
              [107.38038152399999, 21.057505461000048],
              [107.38004987600009, 21.057152152000036],
            ],
          ],
          [
            [
              [107.5137001790001, 21.068116268000026],
              [107.51455133200008, 21.067380256000057],
              [107.51520679800005, 21.06652082700009],
              [107.51520893900005, 21.065659963000094],
              [107.51481924300005, 21.064736755000091],
              [107.51384266100007, 21.063381815000071],
              [107.51319104100007, 21.06270397800003],
              [107.51253820400005, 21.062518070000102],
              [107.51168783500003, 21.062946636000035],
              [107.51070587000005, 21.063743836000093],
              [107.50965856800009, 21.06454089500005],
              [107.50828476000001, 21.0652757390001],
              [107.50678106100003, 21.065702821000087],
              [107.50527765900007, 21.066006917000024],
              [107.50475407, 21.066374682000074],
              [107.50429550500007, 21.066865586000105],
              [107.50416389800003, 21.067234232000054],
              [107.504685302, 21.067727317000084],
              [107.50559963300007, 21.067852348000123],
              [107.50769041300005, 21.067795519000043],
              [107.51121787300005, 21.067987811000052],
              [107.51272004100008, 21.06817560300005],
              [107.5137001790001, 21.068116268000026],
            ],
          ],
          [
            [
              [107.48890978200009, 21.063220723000065],
              [107.48785135700008, 21.063037066000057],
              [107.48717679200004, 21.063307321000124],
              [107.48635687400007, 21.063939659000042],
              [107.48668974300007, 21.065480744000098],
              [107.48735978800009, 21.066931992],
              [107.48880068600003, 21.068113202000035],
              [107.49024443700003, 21.068207134000083],
              [107.49120804900008, 21.067846937000034],
              [107.49188451500001, 21.066851825000029],
              [107.49198338500003, 21.065855384000095],
              [107.49112054200003, 21.0644943120001],
              [107.48996773500005, 21.063585593000056],
              [107.48890978200009, 21.063220723000065],
            ],
          ],
          [
            [
              [107.57349451100008, 21.063310304000105],
              [107.5731470580001, 21.06320079000001],
              [107.5724514450001, 21.063308290000023],
              [107.57094383200007, 21.063740759000012],
              [107.56960982700009, 21.064282408000068],
              [107.56891288400007, 21.064988555000049],
              [107.56862169300004, 21.065641070000041],
              [107.56832857000003, 21.067164344000041],
              [107.56832603800009, 21.068307235000105],
              [107.56849880400009, 21.068797379000067],
              [107.56901950400001, 21.069179359000053],
              [107.56983008900002, 21.069507472000026],
              [107.57052512500006, 21.069672094000055],
              [107.57133690200008, 21.069455976000114],
              [107.57232372300011, 21.068695958000113],
              [107.572905244, 21.067771889000042],
              [107.57348855500003, 21.066031466000098],
              [107.57378068300004, 21.064943559000056],
              [107.57389847600011, 21.064073018000059],
              [107.57384160800011, 21.063583089000112],
              [107.57349451100008, 21.063310304000105],
            ],
          ],
          [
            [
              [107.44077257700005, 21.064916352000026],
              [107.43990618900004, 21.064914160000022],
              [107.43903902900006, 21.065183780000041],
              [107.43865239900005, 21.065726434000084],
              [107.43855378800006, 21.066541636000032],
              [107.43893649700001, 21.067358058000082],
              [107.44028084200005, 21.068539339000033],
              [107.44249004800005, 21.070266431000029],
              [107.44354691700001, 21.07099393],
              [107.44450907900004, 21.071177565000099],
              [107.44518450400005, 21.070635621000022],
              [107.44528412200003, 21.069458001000015],
              [107.44509546500007, 21.068098447000061],
              [107.44423423200004, 21.066284166000045],
              [107.44308111500001, 21.06555642200005],
              [107.44154243400007, 21.065008898000045],
              [107.44077257700005, 21.064916352000026],
            ],
          ],
          [
            [
              [107.55660215600004, 21.050759511000102],
              [107.55538569500001, 21.050593792000072],
              [107.55445846200004, 21.050646358000066],
              [107.55283452700004, 21.051296169000096],
              [107.55150017200005, 21.052000995000064],
              [107.54918012300001, 21.05297591800008],
              [107.5477297260001, 21.053734897000083],
              [107.54691684900007, 21.054440759000045],
              [107.54668367300005, 21.055038943000071],
              [107.54621745200002, 21.056180898000051],
              [107.54604069600006, 21.05743229600003],
              [107.54597957900006, 21.058792773000064],
              [107.54603575200009, 21.05955483000006],
              [107.54638179000003, 21.060263050000039],
              [107.54794156100004, 21.062334347000089],
              [107.55221581800002, 21.068384047000066],
              [107.55342826200007, 21.070345732000106],
              [107.55435359700006, 21.071163946000034],
              [107.555106338, 21.071437574000029],
              [107.55574391400008, 21.07138443500007],
              [107.55632440600006, 21.070950217000117],
              [107.55638372000008, 21.070351680000066],
              [107.55644377699998, 21.069426610000086],
              [107.55598737600006, 21.06626914700005],
              [107.55524248900007, 21.062566845000049],
              [107.55461115100006, 21.059898806],
              [107.55444017100004, 21.058646706000061],
              [107.55467457100006, 21.057504272000038],
              [107.55554711700005, 21.056036578000104],
              [107.55670913200001, 21.05467830200007],
              [107.55821954500001, 21.052994161000079],
              [107.55862675700004, 21.052287455000048],
              [107.5584545240001, 21.05157959200001],
              [107.55810772600009, 21.05119792400005],
              [107.557239302, 21.050869633000069],
              [107.55660215600004, 21.050759511000102],
            ],
          ],
          [
            [
              [107.53045311200006, 21.057399759000091],
              [107.53063023000006, 21.056039529000081],
              [107.53080800100003, 21.054407177000108],
              [107.53075149600005, 21.053808385000039],
              [107.53040461800003, 21.053481106000049],
              [107.52994105200005, 21.053480121000057],
              [107.52924558200007, 21.053533064000085],
              [107.52808628200006, 21.053693860000067],
              [107.52681056000003, 21.054072108000099],
              [107.52570721400005, 21.055049391000047],
              [107.52437035400001, 21.056733669000046],
              [107.52367419600006, 21.057058721000118],
              [107.52309473800007, 21.057057472000018],
              [107.52263169700004, 21.056838776000042],
              [107.52228496000004, 21.056457055000074],
              [107.52194008500001, 21.055313399000042],
              [107.5217694510001, 21.054006842000028],
              [107.52130910300012, 21.052699655000048],
              [107.51986423000002, 21.051172629000092],
              [107.51812912900009, 21.049862658000059],
              [107.51720362600007, 21.04891166500007],
              [107.51621083900008, 21.047784904000096],
              [107.51570224700001, 21.047136107000064],
              [107.51529745300009, 21.046808674000062],
              [107.51437117700002, 21.046480086000052],
              [107.51211234900008, 21.04609414300009],
              [107.51025872400001, 21.045872345000035],
              [107.50869509400005, 21.04554232],
              [107.507131735, 21.045103438000098],
              [107.50582962400009, 21.044474648000033],
              [107.50432547900007, 21.043546041000027],
              [107.50276297900011, 21.04278058000002],
              [107.50143143400007, 21.042342177000073],
              [107.49905637600003, 21.042119106000087],
              [107.49650764700009, 21.041841192],
              [107.494539043, 21.041292439000081],
              [107.49349708500003, 21.040909081000066],
              [107.49297575000006, 21.040853463000083],
              [107.49198904400005, 21.041504298000021],
              [107.49140792800002, 21.042156071000036],
              [107.49134842300006, 21.042754618000089],
              [107.49152139900008, 21.043081565000051],
              [107.49195390600008, 21.04387173300006],
              [107.49305070400004, 21.045452585000035],
              [107.49426382200005, 21.046870425000108],
              [107.49530355800002, 21.048124573000045],
              [107.49767405500005, 21.050143694000042],
              [107.49969790799999, 21.051781024000071],
              [107.50212810100007, 21.053147133000039],
              [107.50426846800006, 21.054566976000011],
              [107.50698763300008, 21.05626020600004],
              [107.50930328200009, 21.05713614600004],
              [107.51141674600008, 21.057766705000077],
              [107.51315364000008, 21.058369195000104],
              [107.5141950500001, 21.059024575000059],
              [107.51552471800007, 21.060279248000043],
              [107.51668001800009, 21.061751215000065],
              [107.5185297670001, 21.063605663000118],
              [107.52026485900005, 21.064970022000026],
              [107.52194162600006, 21.066497502000026],
              [107.52350251800006, 21.068024725],
              [107.52402141, 21.069114307000092],
              [107.52424975600007, 21.070529795000056],
              [107.52407191600001, 21.072162107000025],
              [107.52377936700005, 21.073304355000033],
              [107.52395189200011, 21.073848950000063],
              [107.52441430100001, 21.074339747000082],
              [107.52505163900007, 21.074395546000076],
              [107.526269149, 21.074180467000048],
              [107.52853069100007, 21.073586646000116],
              [107.53003873700005, 21.073045632000031],
              [107.53090944100006, 21.072448827000031],
              [107.53120076000002, 21.071796369000047],
              [107.53114451100006, 21.07108875400003],
              [107.53097289500008, 21.070163200000103],
              [107.53097446700012, 21.069510126000068],
              [107.53120887800006, 21.068422159000107],
              [107.53179021000003, 21.067661469000051],
              [107.53329988700006, 21.066412919000044],
              [107.53446107800004, 21.065490170000075],
              [107.53498456000008, 21.064674915000033],
              [107.53498689800001, 21.063695299000074],
              [107.53464142500003, 21.06276937200002],
              [107.53397704000004, 21.06192440200001],
              [107.53328375600006, 21.061052158000045],
              [107.53172270300003, 21.059579417000052],
              [107.53068280300005, 21.058271034000065],
              [107.53045311200006, 21.057399759000091],
            ],
          ],
          [
            [
              [107.77091320699999, 21.067407342000074],
              [107.77029004500004, 21.067406695000024],
              [107.76913165700003, 21.068327159000063],
              [107.76806187800007, 21.069582858000047],
              [107.76806108000008, 21.070253165000082],
              [107.76814941000002, 21.070839774000127],
              [107.76886050900006, 21.071762184000065],
              [107.77143732600011, 21.07595423],
              [107.77241622900002, 21.076290385],
              [107.77384144900007, 21.07562155500009],
              [107.77446589800005, 21.074532956000077],
              [107.77446745, 21.073192361000089],
              [107.77420270000003, 21.07118118600004],
              [107.77313664500011, 21.069252972000108],
              [107.7716250030001, 21.067743228000069],
              [107.77091320699999, 21.067407342000074],
            ],
          ],
          [
            [
              [107.51336384600006, 21.07198939800012],
              [107.51317076300008, 21.070820661000056],
              [107.51278015300008, 21.070266399000026],
              [107.51238907500006, 21.069896593000067],
              [107.51153959800003, 21.069956211000118],
              [107.51081909300009, 21.070692497000017],
              [107.51009810900004, 21.071613245],
              [107.50957451400006, 21.071981028000053],
              [107.50872549200007, 21.071856160000024],
              [107.50644069200003, 21.071113196000013],
              [107.50448289300004, 21.0702479650001],
              [107.50278691200005, 21.069198828000069],
              [107.50174316500002, 21.068581574000063],
              [107.50030586000003, 21.068578332000065],
              [107.49821427800006, 21.068942541000091],
              [107.49710220200006, 21.069493418000015],
              [107.49605433700006, 21.070474880000042],
              [107.49506988900005, 21.072194349000114],
              [107.49487213300003, 21.07287029000009],
              [107.49500168, 21.073301011000112],
              [107.49643696300009, 21.074103649000087],
              [107.49859124600009, 21.074784925000053],
              [107.50244313800002, 21.075900436000069],
              [107.50492335200008, 21.076889830000042],
              [107.50629491800004, 21.077077370000019],
              [107.508647447, 21.07689813400011],
              [107.51100011600006, 21.076657391000083],
              [107.51198137500008, 21.076167645000062],
              [107.51270328000004, 21.074877958000023],
              [107.51309882500007, 21.073464565000052],
              [107.51336384600006, 21.07198939800012],
            ],
          ],
          [
            [
              [107.37971308500002, 21.073370724000107],
              [107.37837353100011, 21.073282902000031],
              [107.37721155, 21.073531832000114],
              [107.37640443700008, 21.074538344000082],
              [107.37604310500004, 21.075798316000061],
              [107.37612947300008, 21.076723282000032],
              [107.37701920500005, 21.077818639000064],
              [107.37871124600008, 21.079420639000098],
              [107.37987245800005, 21.079423898000101],
              [107.3812147240001, 21.078671067000073],
              [107.38139683500006, 21.077578729000024],
              [107.38086731500005, 21.075559675000122],
              [107.3801586160001, 21.073708241000055],
              [107.37971308500002, 21.073370724000107],
            ],
          ],
          [
            [
              [107.37589704700011, 21.065625874000112],
              [107.3747375360001, 21.065118195000117],
              [107.37295064900005, 21.065281268000021],
              [107.3721451640001, 21.065783387000089],
              [107.37008650900003, 21.067122604000055],
              [107.36838320100006, 21.069051277000113],
              [107.36721631900005, 21.070813345000069],
              [107.36604804700002, 21.072995718000072],
              [107.36577379000006, 21.074928444000115],
              [107.36621383200006, 21.076947289000088],
              [107.36692541500005, 21.077874039000029],
              [107.36861982400009, 21.078719542],
              [107.37076142600009, 21.079398166000104],
              [107.37201114600002, 21.079653914000041],
              [107.37308520100007, 21.07898444000007],
              [107.37389397000003, 21.077473549000025],
              [107.37453029500008, 21.074028664000096],
              [107.37606275600005, 21.069661547000074],
              [107.37669660600001, 21.066973201000039],
              [107.37660970200001, 21.066216350000033],
              [107.37589704700011, 21.065625874000112],
            ],
          ],
          [
            [
              [107.35942116500007, 21.078188698000041],
              [107.35710125300005, 21.077425376000036],
              [107.35549315600005, 21.077504765000029],
              [107.35397410900003, 21.077668462000076],
              [107.35263203300011, 21.078337060000059],
              [107.35245058900001, 21.079177188000031],
              [107.35351829399998, 21.080441284000081],
              [107.35547980800001, 21.081539852000027],
              [107.35913881600007, 21.082559265],
              [107.36083516300009, 21.082816368000046],
              [107.36181830000008, 21.082651071000043],
              [107.36235782100005, 21.081559786000078],
              [107.36227207600005, 21.080466697000055],
              [107.36147063400009, 21.079707796000058],
              [107.35942116500007, 21.078188698000041],
            ],
          ],
          [
            [
              [107.57316556000004, 21.077124292000079],
              [107.57264328300008, 21.076938818000102],
              [107.57179352400003, 21.077121640000129],
              [107.5711385430001, 21.077858240000054],
              [107.57054754600006, 21.079209855000066],
              [107.57021910500005, 21.080008575000072],
              [107.57015228000003, 21.080684819000069],
              [107.57028091300002, 21.081607407000021],
              [107.57158386100008, 21.083331614000045],
              [107.57295297600004, 21.084687011000099],
              [107.57340953400006, 21.085056827000059],
              [107.57380128700007, 21.085180554000083],
              [107.57458601300006, 21.08487463000008],
              [107.57491430900005, 21.084137392000102],
              [107.57511367600003, 21.082600561000042],
              [107.57524662400002, 21.081555507000083],
              [107.5751189060001, 21.080202505000045],
              [107.57472930000002, 21.079094953000066],
              [107.57381756200007, 21.077740445000082],
              [107.57316556000004, 21.077124292000079],
            ],
          ],
          [
            [
              [107.49052778600003, 21.086093105000089],
              [107.49013569800009, 21.086039471],
              [107.48845321000006, 21.086615623000071],
              [107.48783504700006, 21.087299699000091],
              [107.48760842600007, 21.08824832400007],
              [107.48749371800007, 21.089249945000077],
              [107.48760412700005, 21.089882965000051],
              [107.48827444300009, 21.090675481000076],
              [107.48911371100009, 21.091151993000068],
              [107.49079347900008, 21.091630447000043],
              [107.49264273700004, 21.091581966000028],
              [107.49365203600007, 21.091320618000076],
              [107.49404577900005, 21.090741487000031],
              [107.49410304300008, 21.090267045000033],
              [107.49360107500011, 21.089369472000016],
              [107.49253905000008, 21.088365151000055],
              [107.49103070600006, 21.08662156900003],
              [107.49052778600003, 21.086093105000089],
            ],
          ],
          [
            [
              [107.58111964800003, 21.08494858100007],
              [107.58040119499999, 21.084824247000086],
              [107.57961661100009, 21.085068710000066],
              [107.57935419800006, 21.085560118000046],
              [107.57902418900002, 21.087096696000081],
              [107.57901940600007, 21.089310263000094],
              [107.57921263700007, 21.090601877000026],
              [107.57986377500004, 21.091648407000015],
              [107.58071187900003, 21.092264897000042],
              [107.58162624200001, 21.092451088000082],
              [107.58260648100003, 21.092391450000036],
              [107.58312972000007, 21.092146487000015],
              [107.58352321200003, 21.091470867000069],
              [107.58352452600005, 21.090855988],
              [107.58333310700002, 21.088703548000076],
              [107.58268380400003, 21.086796188000086],
              [107.58183744200008, 21.085380364000095],
              [107.58111964800003, 21.08494858100007],
            ],
          ],
          [
            [
              [107.38662217500004, 21.091800161000052],
              [107.38555075500004, 21.091629063000106],
              [107.38429827500005, 21.092214029000012],
              [107.382595155, 21.094058665000048],
              [107.38196744200012, 21.09481348300006],
              [107.38241222600006, 21.095403171],
              [107.38392819800008, 21.096248024000097],
              [107.38767616700004, 21.097519366000128],
              [107.38936899400004, 21.098953110000096],
              [107.39025838500008, 21.100216494000087],
              [107.39105896400007, 21.101311514000095],
              [107.39172831500011, 21.101523515000054],
              [107.39253493600006, 21.10068510400005],
              [107.39441696000009, 21.098756836000021],
              [107.39486727500004, 21.097581192000092],
              [107.39469253400006, 21.09631977800008],
              [107.39424848400009, 21.095477928000058],
              [107.39170766100008, 21.093831730000097],
              [107.38858505500009, 21.092562168000129],
              [107.38662217500004, 21.091800161000052],
            ],
          ],
          [
            [
              [107.58260371400007, 21.09368267500011],
              [107.58188496400001, 21.093681318000087],
              [107.58123024000002, 21.094294958000056],
              [107.58116330500008, 21.095032679000077],
              [107.58161713100006, 21.096693698000109],
              [107.58219992900005, 21.099154288000044],
              [107.58291356600003, 21.101553623000058],
              [107.58369532100006, 21.102661849000093],
              [107.58493566600001, 21.103217560000061],
              [107.58637362900006, 21.103035796000121],
              [107.58709370700005, 21.102422272000076],
              [107.58729312700002, 21.10082398800008],
              [107.58677416500008, 21.099039901000083],
              [107.58540720800003, 21.09657785500012],
              [107.58384321100004, 21.094607328000059],
              [107.58260371400007, 21.09368267500011],
            ],
          ],
          [
            [
              [107.59774718000008, 21.109689537000072],
              [107.59688938400008, 21.109563762000057],
              [107.59583279400006, 21.109810261000028],
              [107.59510506900006, 21.110616292000103],
              [107.59497086800006, 21.111671833000045],
              [107.59523205300009, 21.113038623000065],
              [107.59595579300002, 21.114157830000032],
              [107.59687897400002, 21.114594252000082],
              [107.59813295700003, 21.114658635000083],
              [107.59912329600009, 21.114536220000076],
              [107.59965261800001, 21.11391613500011],
              [107.59978629500006, 21.113109010000031],
              [107.59945830400009, 21.112114743000099],
              [107.59873519100003, 21.110685017000016],
              [107.59774718000008, 21.109689537000072],
            ],
          ],
          [
            [
              [107.80317365399999, 21.103391760000086],
              [107.80183441600012, 21.103138478000091],
              [107.80094089600009, 21.103641848000088],
              [107.80022572000007, 21.104397451000075],
              [107.79995705100004, 21.105153465000015],
              [107.80049181500006, 21.106162279000081],
              [107.80522107900002, 21.109863698000098],
              [107.80968236100003, 21.113816844000063],
              [107.81191326800004, 21.115583311000115],
              [107.81414478600003, 21.116761564000086],
              [107.81593050500008, 21.117183181000065],
              [107.81727025200009, 21.117016247000016],
              [107.81816409600006, 21.116176716000012],
              [107.81950579000005, 21.113909104000058],
              [107.81986479100009, 21.111976787000053],
              [107.81986556900006, 21.111136512000073],
              [107.81870532900007, 21.110379325000039],
              [107.8177867160001, 21.110280564000035],
              [107.81512936400006, 21.109972157000101],
              [107.81117164700007, 21.108284709000102],
              [107.80817154199998, 21.106337128000071],
              [107.80522670800005, 21.10423386300004],
              [107.80317365399999, 21.103391760000086],
            ],
          ],
          [
            [
              [107.57886910000006, 21.113505012000111],
              [107.57939758800009, 21.110862134000016],
              [107.5793998450001, 21.109816879000043],
              [107.57868502400002, 21.107970940000072],
              [107.57728657100004, 21.104986191000116],
              [107.57553031400003, 21.10129363300004],
              [107.57338233700003, 21.097477313000113],
              [107.57168965900004, 21.094645619000026],
              [107.57071524100007, 21.092061253000068],
              [107.56990465700005, 21.089261988000096],
              [107.56958380400008, 21.086617373000038],
              [107.56893340900008, 21.085263361],
              [107.56834727700007, 21.084401377000091],
              [107.56684657000008, 21.083476119000096],
              [107.56573666100007, 21.083105008000032],
              [107.56508273600006, 21.083349677000029],
              [107.56442730600001, 21.084270720000028],
              [107.56351038100009, 21.0852527260001],
              [107.55984743100005, 21.087028628000049],
              [107.55749231300005, 21.088315180000095],
              [107.55605430200009, 21.088558250000041],
              [107.55246066500003, 21.088551009000064],
              [107.54971785400002, 21.087930558000068],
              [107.54749648300007, 21.087864544000112],
              [107.54481630400009, 21.088412439000059],
              [107.5416787340001, 21.088959345000099],
              [107.54083005200005, 21.088650152000099],
              [107.54050452000011, 21.088157572000021],
              [107.54070184300009, 21.087604587],
              [107.54155240100005, 21.087114438000043],
              [107.54285974800003, 21.086871191000078],
              [107.54560567700003, 21.08613899100007],
              [107.54743599300004, 21.085773819000053],
              [107.54972240100004, 21.08596294700007],
              [107.55240055500012, 21.086275826000112],
              [107.55547200500006, 21.08603606700008],
              [107.55710657500003, 21.085547440000063],
              [107.55906935300007, 21.084383077],
              [107.56031299000003, 21.08340173800007],
              [107.56051107500008, 21.082479796000086],
              [107.56044740500005, 21.081741807000043],
              [107.5597975160001, 21.080203294000022],
              [107.55869209600003, 21.077864507000044],
              [107.55777990700005, 21.07675587700006],
              [107.55689963600005, 21.075985503000091],
              [107.55494184500006, 21.074997744000079],
              [107.55220033700007, 21.073885396000058],
              [107.54874006500003, 21.072833039000024],
              [107.54638976900007, 21.072090356000039],
              [107.54482176600001, 21.072087135000039],
              [107.54410382500002, 21.071778206000054],
              [107.54384450700006, 21.070916810000107],
              [107.5434553850001, 21.069686210000071],
              [107.54247742400001, 21.068823326000086],
              [107.54091176500006, 21.067836241000037],
              [107.53875915800003, 21.066417481000066],
              [107.53751960800005, 21.065677009000083],
              [107.5366700110001, 21.065798216000076],
              [107.53581836200007, 21.066780275000063],
              [107.53466984900007, 21.068960763000085],
              [107.53368412100005, 21.071356789],
              [107.53333314900006, 21.073418678000074],
              [107.53235919400002, 21.074874531000098],
              [107.53100880700003, 21.075949874000074],
              [107.52976370000003, 21.076557856000107],
              [107.52733219500003, 21.077369284000014],
              [107.52412746800007, 21.078715176000081],
              [107.52327660300001, 21.079328237000091],
              [107.52333998100002, 21.080127732000072],
              [107.52372901700005, 21.080495548000044],
              [107.52455965600004, 21.081422256000067],
              [107.5257014630001, 21.083010294000104],
              [107.52643876800009, 21.084660871000068],
              [107.52730827000009, 21.087389914000099],
              [107.52809532800011, 21.088542724],
              [107.528251794, 21.088838886000083],
              [107.52923023900004, 21.089517345000083],
              [107.53092668700008, 21.090504754000072],
              [107.53138391900006, 21.090567213],
              [107.53184203700005, 21.090260742000041],
              [107.53275899800006, 21.089340365000098],
              [107.53341269000006, 21.089218765000069],
              [107.53406607700003, 21.089220142],
              [107.53550280100006, 21.089530605000029],
              [107.53733054900005, 21.090272290000108],
              [107.53850461700002, 21.091135575000038],
              [107.53928695100009, 21.09187505800012],
              [107.53947963000005, 21.093289679000101],
              [107.53934676400007, 21.094211712000096],
              [107.53901948000008, 21.094456980000054],
              [107.53784422300001, 21.094085608000057],
              [107.53614653700004, 21.093590146000096],
              [107.53438292500005, 21.093340491000042],
              [107.53301033300008, 21.093522067000059],
              [107.53268260000004, 21.093951784000083],
              [107.53300546900003, 21.09555114100008],
              [107.53358971100005, 21.097151044000029],
              [107.53411068200009, 21.097889987000059],
              [107.53541532400003, 21.098815033000037],
              [107.53665551600005, 21.099371018000035],
              [107.53786383800011, 21.099588753000106],
              [107.54099985200006, 21.099779740000102],
              [107.54204389000003, 21.100396776000082],
              [107.54262966500009, 21.10138177500005],
              [107.54275544900001, 21.103472582000023],
              [107.54300918100004, 21.106731889000052],
              [107.5435926740001, 21.108700645000056],
              [107.54476357600008, 21.110978032000013],
              [107.54691257200008, 21.114179698000065],
              [107.54867197300007, 21.116335283000076],
              [107.55062773600007, 21.118368270000062],
              [107.55252002900005, 21.119601802000076],
              [107.55415277700006, 21.120035498000078],
              [107.55487161400002, 21.120036945000017],
              [107.555526097, 21.119607861000041],
              [107.555592706, 21.119054639000097],
              [107.554159672, 21.117022743000021],
              [107.55344450100007, 21.115422683000062],
              [107.55324986500004, 21.114807443000046],
              [107.55357731500004, 21.114500675000066],
              [107.55429683600008, 21.114194697000066],
              [107.55530993500005, 21.114104509000065],
              [107.55616001200005, 21.113860275000015],
              [107.55851293900004, 21.113680520000067],
              [107.56119328900003, 21.113193969000044],
              [107.56256571700008, 21.113135209000063],
              [107.56347947100001, 21.113628897000055],
              [107.564066494, 21.114121937000085],
              [107.56478284700003, 21.115230086000025],
              [107.56536891800002, 21.116153513000043],
              [107.56647819400006, 21.116893510000075],
              [107.56765296100009, 21.117572150000093],
              [107.57039502700005, 21.118745702000027],
              [107.57450838800003, 21.120413746000018],
              [107.57640138700008, 21.121401132000095],
              [107.57731549200004, 21.121771792000033],
              [107.57790364000003, 21.121772915000037],
              [107.57816530700009, 21.121650440000025],
              [107.57842803799998, 21.12103610000009],
              [107.57846237500007, 21.120267602000098],
              [107.57859587300001, 21.118976675000042],
              [107.57853651300007, 21.116209737000077],
              [107.57886910000006, 21.113505012000111],
            ],
          ],
          [
            [
              [107.59773155900004, 21.046974021000061],
              [107.59772766200007, 21.0436091770001],
              [107.59752959000012, 21.040832964000074],
              [107.59709145500001, 21.037624163000054],
              [107.5962348150001, 21.03503706000005],
              [107.59481058900003, 21.033234324000105],
              [107.59339788100003, 21.031687260000083],
              [107.59060409100006, 21.029574646000079],
              [107.58791367100012, 21.027268164000112],
              [107.58656573700006, 21.025265180000119],
              [107.58570325900011, 21.02208101100009],
              [107.58494008500003, 21.017623962000087],
              [107.58452824800005, 21.013268929000084],
              [107.58336829300001, 21.007762609000032],
              [107.58147619300004, 21.002817461000056],
              [107.58067101700003, 20.998221100000066],
              [107.57885103500007, 20.991803962000063],
              [107.57702742400009, 20.987109837000069],
              [107.57510412200003, 20.981458178000075],
              [107.57347983600002, 20.978870371000099],
              [107.57053277000007, 20.975609869000067],
              [107.56717706600006, 20.972922885000052],
              [107.56442944200009, 20.971672994000109],
              [107.56300438600005, 20.971191532000063],
              [107.56198508500009, 20.971476702000032],
              [107.56157632400001, 20.972050276000061],
              [107.5612670620001, 20.973677076000016],
              [107.56126146200008, 20.976166041000077],
              [107.56176284200009, 20.979709012000036],
              [107.56236740500002, 20.982677801000094],
              [107.56297670800006, 20.983540562000066],
              [107.56348564000008, 20.983733025],
              [107.56511603500006, 20.983544781000063],
              [107.5665420280001, 20.983643316000041],
              [107.5680684230001, 20.984412124000059],
              [107.56959440800006, 20.98537238700002],
              [107.57132161700005, 20.987481758000023],
              [107.57253802800008, 20.990260212000052],
              [107.57253551000005, 20.991408930000063],
              [107.57182111700008, 20.991981912000043],
              [107.56957855800007, 20.992551922000047],
              [107.56692677000005, 20.9938869160001],
              [107.56580462100001, 20.994554800000031],
              [107.56447528900007, 20.99675389],
              [107.5636544700001, 20.999336883000105],
              [107.56365104200009, 21.000868487000091],
              [107.56446245700008, 21.002497415000043],
              [107.56588433400003, 21.004510446000069],
              [107.56689954900001, 21.00613976500005],
              [107.56852189500005, 21.00968473800009],
              [107.56943104900004, 21.013228301000098],
              [107.57033686100003, 21.018303398000079],
              [107.57134797500002, 21.021847100000102],
              [107.57286902500002, 21.02520030800008],
              [107.57489939600006, 21.028650203000062],
              [107.57652640300003, 21.030184861000031],
              [107.578459337, 21.031624360000123],
              [107.58094866099999, 21.032347120000061],
              [107.58232959800003, 21.032493200000069],
              [107.58432822700011, 21.032808140000014],
              [107.58674270900005, 21.032903595],
              [107.58777305300003, 21.033290163000018],
              [107.58828580300009, 21.033997621000111],
              [107.58886465500002, 21.036210456000056],
              [107.58957250800006, 21.038796204000043],
              [107.59013133800002, 21.04148079200003],
              [107.590205059, 21.04279781000011],
              [107.58949052900003, 21.043370801],
              [107.58857278700003, 21.043656248000083],
              [107.587757557, 21.04365473000005],
              [107.58673932300003, 21.043269952000095],
              [107.58470390800002, 21.042021800000029],
              [107.58317699600008, 21.041253170000026],
              [107.58215838400008, 21.041059808000014],
              [107.58154635100007, 21.041345805000056],
              [107.58144321100006, 21.041919928000041],
              [107.58174378500006, 21.044313467000016],
              [107.58214545500007, 21.04709006300007],
              [107.58275279200006, 21.049005573000024],
              [107.58427587400004, 21.051592829000043],
              [107.5850868580001, 21.053604414000034],
              [107.58518510300004, 21.055327505000051],
              [107.58518286200004, 21.056380388000036],
              [107.58461673800009, 21.059011528000049],
              [107.58369421200005, 21.061498417000024],
              [107.58348404600008, 21.064465211000091],
              [107.58398728300006, 21.067433332000093],
              [107.58540617000004, 21.071168867],
              [107.58763945900009, 21.075384464000102],
              [107.59058570600006, 21.079888467000082],
              [107.59363500700002, 21.083914025000091],
              [107.59663455300004, 21.087413013000052],
              [107.59856702900007, 21.089426479000025],
              [107.60338247200006, 21.094186725000071],
              [107.60711120700003, 21.098247209000043],
              [107.60958293200008, 21.101395716000049],
              [107.61396883900007, 21.10770154800008],
              [107.61575131200001, 21.110513153000099],
              [107.61900305800003, 21.115782693000092],
              [107.62184962200011, 21.119807268000024],
              [107.62418786900004, 21.123160976000044],
              [107.62662960600001, 21.125749167000052],
              [107.62774955800003, 21.126516702000053],
              [107.62856513600011, 21.126518073000078],
              [107.62958533300005, 21.126136959000071],
              [107.62968928500004, 21.125084371000113],
              [107.62918228700002, 21.123647931],
              [107.62826915600004, 21.121349458000083],
              [107.62633937000007, 21.117613644000066],
              [107.62379967700008, 21.113015385000011],
              [107.62177451700002, 21.108193842000041],
              [107.61862824900003, 21.101029774000054],
              [107.61684960900003, 21.096302224000048],
              [107.612485631, 21.084215544000081],
              [107.61005203300007, 21.078779847000106],
              [107.60822570200006, 21.0746609320001],
              [107.60763332500007, 21.07241995400004],
              [107.60737061300009, 21.070682524000084],
              [107.60696950000002, 21.067427512000059],
              [107.60615997400001, 21.064554609000119],
              [107.6051466480001, 21.061681326000055],
              [107.60255673300004, 21.057321600000108],
              [107.60133811400001, 21.055213636000047],
              [107.59991510800006, 21.053392445000085],
              [107.59788127100009, 21.051282972000074],
              [107.59727180000002, 21.050324681000099],
              [107.59739226600004, 21.048659319000059],
              [107.59773155900004, 21.046974021000061],
            ],
          ],
          [
            [
              [107.60594366000007, 21.119426744000066],
              [107.60522544700005, 21.119118037000106],
              [107.60398318300003, 21.119423243000043],
              [107.60254437300009, 21.119974028000051],
              [107.60143167200003, 21.120832815000057],
              [107.60103805600006, 21.121569921000024],
              [107.60123134000007, 21.122922931000062],
              [107.60181635000006, 21.124461094000054],
              [107.60298903000002, 21.126246243000054],
              [107.60442452400004, 21.127355531000092],
              [107.60605745200006, 21.12778882900006],
              [107.60743046500006, 21.127483857000051],
              [107.60867400000011, 21.126563794000042],
              [107.60932937200006, 21.125642693000124],
              [107.60939657100002, 21.124720543000116],
              [107.60887598400004, 21.123612903000044],
              [107.60776898500009, 21.121643442000071],
              [107.60685706700005, 21.120166185000052],
              [107.60594366000007, 21.119426744000066],
            ],
          ],
          [
            [
              [107.79270526300007, 21.130512974000034],
              [107.79183013100005, 21.130512152000058],
              [107.79117334200006, 21.13092318700005],
              [107.79095419800005, 21.131266032000084],
              [107.79102668200001, 21.131677754000044],
              [107.79168185800005, 21.132776118000059],
              [107.79284680800006, 21.134561057000042],
              [107.79393965100007, 21.135591209000012],
              [107.79452279800009, 21.135866182000115],
              [107.79539824300005, 21.135592562000035],
              [107.79598268600009, 21.134632572],
              [107.79620268700009, 21.133466421000065],
              [107.79620397900001, 21.132231457000024],
              [107.79569435100004, 21.131407681000077],
              [107.79489271900006, 21.130858056000079],
              [107.79270526300007, 21.130512974000034],
            ],
          ],
          [
            [
              [107.58112884600007, 21.136475479000083],
              [107.58033825800003, 21.134680243000048],
              [107.57827883000007, 21.1318362250001],
              [107.57574347000008, 21.128692293000114],
              [107.57383946300011, 21.12749279300008],
              [107.57066507100009, 21.125991851000059],
              [107.56860257500006, 21.124642507000054],
              [107.56590723900007, 21.122096048000039],
              [107.56384582100003, 21.120298211000055],
              [107.56225871599999, 21.119547661000034],
              [107.56003341399999, 21.119991698],
              [107.55621694100005, 21.121478896000042],
              [107.55494385600005, 21.122373229000054],
              [107.5543045730001, 21.124016242000017],
              [107.55414158100011, 21.125809686000082],
              [107.55413679000006, 21.12790241200004],
              [107.554926095, 21.130146205000067],
              [107.55777823600009, 21.133589950000072],
              [107.56078972200004, 21.136884481],
              [107.56348686100009, 21.138683551000078],
              [107.56507446000009, 21.139284586000073],
              [107.5660278240001, 21.139286458000051],
              [107.56666473000004, 21.138689797000062],
              [107.56650949200008, 21.137045234000063],
              [107.56745323200009, 21.135836682000075],
              [107.56873828500001, 21.135106369000042],
              [107.57016830400002, 21.135109151],
              [107.57191445299999, 21.135859932000045],
              [107.57333955700005, 21.138104858000069],
              [107.57492620300005, 21.139154249000036],
              [107.57699020500003, 21.13990559500003],
              [107.57857914100005, 21.139908627000104],
              [107.58016969600003, 21.139164260000065],
              [107.58096642200005, 21.138119432000032],
              [107.58112884600007, 21.136475479000083],
            ],
          ],
          [
            [
              [107.84815614200004, 21.135020908000079],
              [107.84708476100003, 21.13451603400005],
              [107.84610177200005, 21.135187537],
              [107.84449277200007, 21.13686688400005],
              [107.84377703700008, 21.13837879800009],
              [107.84422186800003, 21.140563706000037],
              [107.84529240800006, 21.142160890000028],
              [107.84618511800002, 21.142749665000068],
              [107.84734644800001, 21.142498409000062],
              [107.84868784400007, 21.140398758000067],
              [107.85003005200011, 21.137206801000019],
              [107.85029890400006, 21.136030657000049],
              [107.8494062770001, 21.135357859000067],
              [107.84815614200004, 21.135020908000079],
            ],
          ],
          [
            [
              [107.64236701600007, 21.133414010000081],
              [107.64158313200004, 21.133412742000068],
              [107.64110022000006, 21.133695601000021],
              [107.64079705200001, 21.134602744000027],
              [107.6407948550001, 21.135794016000098],
              [107.64121287600005, 21.138007067000061],
              [107.64217434300011, 21.139823888000109],
              [107.64428196600005, 21.141415641],
              [107.64657156800004, 21.142440394000062],
              [107.64849957900006, 21.143351095000092],
              [107.64928421700006, 21.142955251000025],
              [107.64928636200005, 21.141763994000051],
              [107.6485656130001, 21.140174496000071],
              [107.64555728300006, 21.136482431000033],
              [107.64417338600003, 21.134835115000072],
              [107.64308997700002, 21.133755547000099],
              [107.64236701600007, 21.133414010000081],
            ],
          ],
          [
            [
              [107.50923474700002, 21.12459571500009],
              [107.50834214800005, 21.124257504000084],
              [107.50735850900003, 21.124591546000104],
              [107.50628402500003, 21.125513796000028],
              [107.50511677, 21.127780763000104],
              [107.50493486900004, 21.12904122300003],
              [107.50502208600007, 21.129882004000052],
              [107.50600234400008, 21.130892884000026],
              [107.50805352000006, 21.132410497000095],
              [107.51215491400005, 21.135865918],
              [107.51530039900007, 21.137991168000077],
              [107.51822072600002, 21.13991393900001],
              [107.52080779500002, 21.141600677000035],
              [107.52219764000009, 21.142646863],
              [107.52398885400007, 21.143869706000103],
              [107.5254824240001, 21.144529296000059],
              [107.52823222000006, 21.14477692300003],
              [107.52986690000004, 21.14510126000004],
              [107.53187741300005, 21.145827171000064],
              [107.5337512400001, 21.146923847000032],
              [107.53491325800005, 21.146758184000092],
              [107.5360774760001, 21.145667908000028],
              [107.5366174050001, 21.144071980000056],
              [107.53626219300003, 21.143146620000053],
              [107.53537166800005, 21.14188390600011],
              [107.53296398300006, 21.139861488000022],
              [107.52939661400004, 21.137080059000098],
              [107.52591664700006, 21.135139324000107],
              [107.52145598100002, 21.132355828000101],
              [107.51904669100006, 21.131089744000064],
              [107.51387227800004, 21.127968287000073],
              [107.51137543500003, 21.126029451000058],
              [107.50923474700002, 21.12459571500009],
            ],
          ],
          [
            [
              [107.42988656400004, 21.143108274000078],
              [107.42899426500003, 21.142685685000018],
              [107.42729606100002, 21.142849415000065],
              [107.42452487700007, 21.143262505000045],
              [107.42380906000008, 21.143596861000091],
              [107.42353900900011, 21.144268606000068],
              [107.42353527700007, 21.145529438000032],
              [107.42371298500004, 21.14586611900009],
              [107.42460578400009, 21.146120611000093],
              [107.42612406700005, 21.14637672100006],
              [107.42782032700006, 21.146885449000052],
              [107.42897997000009, 21.147560888000072],
              [107.4298732770001, 21.147647250000112],
              [107.43076805800006, 21.147229269000078],
              [107.43130811700007, 21.145885769000117],
              [107.431043, 21.144876423000092],
              [107.42988656400004, 21.143108274000078],
            ],
          ],
          [
            [
              [107.86847172200008, 21.140117315000047],
              [107.86757884300002, 21.13969667500006],
              [107.86650686700004, 21.13994810100008],
              [107.86552391800006, 21.14061968400005],
              [107.864808157, 21.14238372700008],
              [107.86346627400009, 21.145407700000085],
              [107.86310752500006, 21.147508032000047],
              [107.86337513300001, 21.148012323000032],
              [107.86489322400011, 21.148517388000073],
              [107.86632246900007, 21.148350212000096],
              [107.86793080600009, 21.147510963000023],
              [107.86918236200009, 21.145915289000072],
              [107.86989826500007, 21.143899174000055],
              [107.87007785200009, 21.142470907000096],
              [107.869721419, 21.141210358000073],
              [107.86847172200008, 21.140117315000047],
            ],
          ],
          [
            [
              [107.520296618, 21.145549560000099],
              [107.51949919300003, 21.145547830000041],
              [107.51850147100006, 21.145920726000078],
              [107.51750166400004, 21.147137532000038],
              [107.51730044500009, 21.147887229000069],
              [107.51769730900006, 21.148638228000038],
              [107.51879286400002, 21.149015684000091],
              [107.52058619300007, 21.149394648000097],
              [107.52208139500007, 21.149397882000095],
              [107.52297921700001, 21.149118514000072],
              [107.52357913, 21.148369673000019],
              [107.52387931900006, 21.147901481000098],
              [107.52388069400008, 21.147338881000088],
              [107.52368270900004, 21.146775851000044],
              [107.52278720700011, 21.14611755100011],
              [107.52159175100006, 21.145833662000065],
              [107.520296618, 21.145549560000099],
            ],
          ],
          [
            [
              [107.58111438000003, 21.143201938000097],
              [107.58000274900007, 21.142900878000056],
              [107.57936587000005, 21.143497574000044],
              [107.57856749600006, 21.145289767000094],
              [107.57856135100009, 21.148129793000066],
              [107.57871734700004, 21.149475368000111],
              [107.57966721200002, 21.151121399000061],
              [107.58061870200005, 21.152020049000072],
              [107.58474351300001, 21.155166779000112],
              [107.586330995, 21.155917123000023],
              [107.58760257200005, 21.155770019000016],
              [107.58839869400002, 21.155024139000119],
              [107.58840153700008, 21.153678879000012],
              [107.58776907800006, 21.152182963000051],
              [107.58618670300005, 21.14904103300006],
              [107.58365130200006, 21.145747822000068],
              [107.58111438000003, 21.143201938000097],
            ],
          ],
          [
            [
              [107.40188350900004, 21.155126747000082],
              [107.40189756000007, 21.150551856],
              [107.40199282900004, 21.148857687000074],
              [107.40272191500006, 21.146063835000106],
              [107.40353870800004, 21.14403270800009],
              [107.40390336800002, 21.142593411000021],
              [107.40390673900004, 21.141492021000104],
              [107.40381823300008, 21.140983452000036],
              [107.40300743000006, 21.141065984000114],
              [107.40129289300003, 21.142162751000093],
              [107.40048130100003, 21.142499438000058],
              [107.40012183500004, 21.14224429900009],
              [107.39940526700008, 21.140971518000029],
              [107.398690539, 21.139105676000014],
              [107.39869524700003, 21.137580668000041],
              [107.39888007400006, 21.136056156000024],
              [107.39879184500003, 21.13546285100005],
              [107.39807295400007, 21.134952551000104],
              [107.39717342500006, 21.13461121400011],
              [107.39627179900008, 21.134947644000057],
              [107.39572909000003, 21.13570867600005],
              [107.39527537500005, 21.136808846000044],
              [107.39382257500002, 21.140617401000036],
              [107.39291325600001, 21.143410755000055],
              [107.39209159500007, 21.146966823000035],
              [107.39180924100006, 21.150863248000107],
              [107.39198725700004, 21.151541504000093],
              [107.39261665500007, 21.151882120000032],
              [107.39414882200001, 21.151547432000051],
              [107.39513980600003, 21.151465426000101],
              [107.39576973500002, 21.151636581000055],
              [107.39603756000005, 21.152399805000051],
              [107.39549164600001, 21.154177456000056],
              [107.39521697100002, 21.155616957000056],
              [107.39521223500007, 21.15714190900011],
              [107.39566047600003, 21.157820902000068],
              [107.39647004000005, 21.158161986000046],
              [107.39791087100006, 21.158250637],
              [107.40016388500005, 21.157833154000016],
              [107.40097554100007, 21.157496468000048],
              [107.40160809800008, 21.156820420000066],
              [107.40188350900004, 21.155126747000082],
            ],
          ],
          [
            [
              [107.85381344700005, 21.15388770900006],
              [107.85220613500012, 21.153214469000048],
              [107.85104487600005, 21.153297712],
              [107.85015119800009, 21.153885259000035],
              [107.85006142800003, 21.15447333900002],
              [107.85006071700006, 21.155397564000019],
              [107.85059575000001, 21.156574214000031],
              [107.85202391700005, 21.157919500000055],
              [107.85461308300005, 21.159601623000057],
              [107.85702389100004, 21.160947513000039],
              [107.85863133500007, 21.161536689000023],
              [107.86032854100009, 21.161537766000023],
              [107.86193689400002, 21.160866612000092],
              [107.8623843550001, 21.159690612000048],
              [107.86220659800004, 21.158430200000033],
              [107.86113559000006, 21.157169225000061],
              [107.85952853800006, 21.156075946000016],
              [107.8568496880001, 21.154897941000073],
              [107.85381344700005, 21.15388770900006],
            ],
          ],
          [
            [
              [107.39898489700002, 21.160456279000073],
              [107.39754378800006, 21.160452361000104],
              [107.39565180000002, 21.160616635000018],
              [107.39528967600009, 21.161208685000062],
              [107.39510428100002, 21.162902578000065],
              [107.39527783700008, 21.16502103400002],
              [107.39563418400007, 21.16629279199999],
              [107.39653149300007, 21.167396593000106],
              [107.39738599900008, 21.167780158000049],
              [107.39828672900008, 21.167782608000074],
              [107.39954879900006, 21.167447157000119],
              [107.40054246000003, 21.166517948000063],
              [107.401176621, 21.165333595000035],
              [107.40104372200003, 21.164613118000105],
              [107.40077819700008, 21.16308744500008],
              [107.40051138200003, 21.161985371000114],
              [107.39997356500002, 21.161136718000058],
              [107.39898489700002, 21.160456279000073],
            ],
          ],
          [
            [
              [107.86515529400003, 21.162060864000054],
              [107.86399404600006, 21.162060154000081],
              [107.86158138500009, 21.163234932000115],
              [107.85997331400009, 21.163485972000018],
              [107.85934778200003, 21.163821655000078],
              [107.85907955500004, 21.164157565000075],
              [107.85898980700007, 21.164745638000056],
              [107.85943577900011, 21.165670140000081],
              [107.86059632600004, 21.166679102000074],
              [107.86256072500004, 21.167856595000067],
              [107.86461477400007, 21.168614027000032],
              [107.86541874600007, 21.168614517000073],
              [107.86622312200011, 21.16802687800012],
              [107.86631359900004, 21.166346551000089],
              [107.86631556000003, 21.163489893000076],
              [107.86586967699999, 21.162397370000093],
              [107.86515529400003, 21.162060864000054],
            ],
          ],
          [
            [
              [107.61662837400011, 21.172961229000094],
              [107.61588987300011, 21.172844155000092],
              [107.6152123530001, 21.173016678000067],
              [107.61465750800002, 21.173421007000037],
              [107.61459468500007, 21.174057788000056],
              [107.61459227800005, 21.175273675000113],
              [107.61569387200007, 21.178575835000046],
              [107.61710230500006, 21.182399591000056],
              [107.61845115600002, 21.185238942000062],
              [107.61998507600002, 21.18790489400002],
              [107.62213437200005, 21.190745569000043],
              [107.62348514500007, 21.192658492000049],
              [107.624284066, 21.193412517000084],
              [107.62471492700004, 21.19347115200005],
              [107.62545441200004, 21.193125018000011],
              [107.62570179100007, 21.192546462000017],
              [107.62570357200005, 21.191620102000016],
              [107.62527661500006, 21.189535061000072],
              [107.62429512700004, 21.187680662000027],
              [107.62220751200006, 21.184782203000019],
              [107.62079557900003, 21.18269544600011],
              [107.62012346100002, 21.180088852],
              [107.61945090800006, 21.177713847000042],
              [107.61840876600007, 21.175453986000022],
              [107.61730440300009, 21.173541402000048],
              [107.61662837400011, 21.172961229000094],
            ],
          ],
          [
            [
              [107.43443771500002, 21.18795594700007],
              [107.43606974600004, 21.184317315],
              [107.43634440500004, 21.182793122000106],
              [107.43634808399999, 21.18152238200004],
              [107.43563211700008, 21.179910935000024],
              [107.43500401800007, 21.179062163000047],
              [107.43410398400003, 21.178805705000059],
              [107.43212300500008, 21.178546482000016],
              [107.43041152500004, 21.178542084000092],
              [107.42753003300001, 21.178195778000131],
              [107.4250083570001, 21.178019814000045],
              [107.424017, 21.178186664000087],
              [107.42284398600007, 21.178861353000045],
              [107.421939181, 21.180214459000091],
              [107.42057615600001, 21.18419258600008],
              [107.42002910500005, 21.186393779000042],
              [107.42002557200001, 21.187579796000122],
              [107.4203828780001, 21.188597323000025],
              [107.42056228900002, 21.188851937000038],
              [107.42146235200006, 21.189108437000037],
              [107.42326978000004, 21.187164693000057],
              [107.42399193800001, 21.186658273000077],
              [107.42498183700003, 21.186999714000088],
              [107.42569999100003, 21.187848737000081],
              [107.42605757700005, 21.188781533000075],
              [107.42605433100005, 21.189882834000073],
              [107.4254184910001, 21.191660213000048],
              [107.42469431600004, 21.192844338000057],
              [107.42397138600009, 21.193604897000014],
              [107.42405946700009, 21.194282843000067],
              [107.42486823100005, 21.194962672000095],
              [107.42801773700005, 21.196156841000047],
              [107.42882801400003, 21.196328355000027],
              [107.42991004300004, 21.195992291000017],
              [107.43199066600005, 21.193032613000092],
              [107.43443771500002, 21.18795594700007],
            ],
          ],
          [
            [
              [108.01787475300007, 21.195910505000093],
              [108.01718206000005, 21.195666297000045],
              [108.01363215700005, 21.19672502100007],
              [108.00930294200005, 21.197539466000045],
              [108.00869686000009, 21.198028016000059],
              [108.00869689200009, 21.198760811000028],
              [108.00921643300009, 21.199330738000107],
              [108.01129460900009, 21.201121913000073],
              [108.01337285600009, 21.203401575000044],
              [108.01415216300009, 21.203564372000102],
              [108.01536436000002, 21.20307576200009],
              [108.01783286700007, 21.201214084000064],
              [108.01973179400008, 21.199540616000064],
              [108.01980340600005, 21.199057494000058],
              [108.01973169500006, 21.198563568000047],
              [108.01865412700008, 21.197050341000079],
              [108.01787475300007, 21.195910505000093],
            ],
          ],
          [
            [
              [108.01259399800004, 21.209996670000031],
              [108.01190124200009, 21.209589606000101],
              [108.01120851100006, 21.209589642000104],
              [108.0100828870001, 21.210566735000036],
              [108.008870675, 21.212032336000064],
              [108.008870734, 21.213335048000111],
              [108.00904396400003, 21.214393495000117],
              [108.00947695100007, 21.214841277000041],
              [108.01025629800006, 21.214963374000014],
              [108.0114685990001, 21.214881893000026],
              [108.01337359500006, 21.21414902000005],
              [108.01403877000003, 21.213161464000052],
              [108.01395210400005, 21.21210301400005],
              [108.01337338200004, 21.211055074000029],
              [108.01259399800004, 21.209996670000031],
            ],
          ],
          [
            [
              [107.65953856400003, 21.140977689000096],
              [107.65603198800005, 21.139189856000051],
              [107.65470189200005, 21.139242172000117],
              [107.65372328400008, 21.140018518000048],
              [107.65236680000002, 21.141925713000106],
              [107.65213875800006, 21.143339662000038],
              [107.6523225870001, 21.145638196000057],
              [107.65261949700006, 21.147760113000032],
              [107.65283870800008, 21.151296189000043],
              [107.65365822200002, 21.155469605000029],
              [107.6544792760001, 21.158794440000058],
              [107.65560269500006, 21.161200448],
              [107.65713907300008, 21.16392528100004],
              [107.65803776400001, 21.165906636000017],
              [107.65818539600006, 21.167462534000059],
              [107.65788181100008, 21.169088449000029],
              [107.65720068500012, 21.171633038000067],
              [107.65569252100003, 21.174176328],
              [107.65546496500004, 21.175307358000062],
              [107.65591357700004, 21.176722283000068],
              [107.65770907100008, 21.181816264000105],
              [107.66028692300003, 21.191047911000013],
              [107.66118638600003, 21.192675609000041],
              [107.66238552100003, 21.194940144],
              [107.66414698800003, 21.198160078000058],
              [107.66489612800011, 21.199787513000068],
              [107.66594580800009, 21.201556806],
              [107.66684367300005, 21.204174366000039],
              [107.66744100600009, 21.20665003900006],
              [107.667964974, 21.208064986000075],
              [107.668339637, 21.208843331000082],
              [107.66931528000001, 21.209976107000017],
              [107.67081720700001, 21.211180365000089],
              [107.67246920300001, 21.21259696500006],
              [107.67396998600002, 21.214508266000017],
              [107.67517046500005, 21.216136284000072],
              [107.67584644900002, 21.216632222000072],
              [107.67674873400007, 21.216704246000084],
              [107.67885470400006, 21.216495189000014],
              [107.68005872300003, 21.216001985000062],
              [107.68081189, 21.215296011000092],
              [107.68073866100006, 21.214093885000104],
              [107.67991401900005, 21.212537140000087],
              [107.67751187300007, 21.209988199000037],
              [107.674884424, 21.207297471000075],
              [107.67330837300008, 21.205456746],
              [107.67270941400008, 21.203900284],
              [107.67271286200001, 21.201849747000075],
              [107.67256878400008, 21.19810201600005],
              [107.67167003500006, 21.195979416000021],
              [107.67084260200008, 21.193217072000081],
              [107.67069676300008, 21.190529893000054],
              [107.67085702700011, 21.187634473000024],
              [107.67047735200005, 21.185461333000077],
              [107.66913790000007, 21.182581250000077],
              [107.66756487100012, 21.179114059000078],
              [107.66583150600005, 21.174805064000068],
              [107.66418830200007, 21.168509205000063],
              [107.66363084800005, 21.166316859000034],
              [107.66295972100002, 21.163133768000094],
              [107.66240769800001, 21.16046844600001],
              [107.66248581800008, 21.158771445000113],
              [107.66377007300011, 21.155167002000042],
              [107.6639996310001, 21.15283378200008],
              [107.6640791990001, 21.15028818000004],
              [107.66423528000006, 21.146964826000044],
              [107.66386160600004, 21.145691386000109],
              [107.66219595000007, 21.143451141000114],
              [107.65953856400003, 21.140977689000096],
            ],
          ],
          [
            [
              [107.5704322290001, 21.207430820000091],
              [107.56739582900005, 21.206248250000037],
              [107.56587595600006, 21.206413366000021],
              [107.56524915400007, 21.206916419000073],
              [107.56497987900008, 21.207420173000102],
              [107.56515770000004, 21.207840755000085],
              [107.56720996000003, 21.209441661000113],
              [107.57439718000006, 21.213195649000035],
              [107.58060267900007, 21.216359207000046],
              [107.58417510500006, 21.217794710000071],
              [107.58524758100006, 21.217880770000086],
              [107.58605225200003, 21.217798228],
              [107.58650007800004, 21.21737884000003],
              [107.58641265700001, 21.216454177000088],
              [107.58364687600005, 21.214011673000051],
              [107.58070130500005, 21.212073053000054],
              [107.57516548600005, 21.209288980000096],
              [107.5704322290001, 21.207430820000091],
            ],
          ],
          [
            [
              [107.58130381100007, 21.224395141000052],
              [107.57935894900001, 21.22422519200002],
              [107.57794387900007, 21.224388762000054],
              [107.57758948700005, 21.22472061000007],
              [107.57758714100004, 21.225801315000048],
              [107.57820337900004, 21.22704944900002],
              [107.579527528, 21.228049527000081],
              [107.58385731300005, 21.229387797000051],
              [107.587656796, 21.230641857000073],
              [107.59065964900005, 21.232310032000079],
              [107.59295568800003, 21.233727470000119],
              [107.59498804800006, 21.23439623600003],
              [107.596491251, 21.23439898000008],
              [107.59746494900001, 21.233901974000077],
              [107.59826282200004, 21.232905864000081],
              [107.59835295700003, 21.232074727000061],
              [107.59782397100005, 21.231325600000069],
              [107.59588126000007, 21.230075113000076],
              [107.59340806000007, 21.228823632000093],
              [107.59049417900012, 21.226906261000074],
              [107.58837419100004, 21.225904758000077],
              [107.58660667800007, 21.225485815000056],
              [107.5848389870001, 21.225149983000065],
              [107.58333638900002, 21.224897762000069],
              [107.58130381100007, 21.224395141000052],
            ],
          ],
          [
            [
              [107.68471325000003, 21.220887406000074],
              [107.68343572500002, 21.220319935000106],
              [107.68170584700006, 21.220458863000054],
              [107.67990053100009, 21.220739084000058],
              [107.67952336400005, 21.221445601000077],
              [107.67959705400003, 21.222364881000111],
              [107.68027248000004, 21.223214334000048],
              [107.68222383800003, 21.225621135000061],
              [107.68477597200007, 21.228594404000134],
              [107.68695316400009, 21.230930749],
              [107.68853001300008, 21.232488479000011],
              [107.68965630700011, 21.233621331000037],
              [107.6911580930001, 21.235108227000055],
              [107.69258648000007, 21.235463733000067],
              [107.69386512300008, 21.235394803000048],
              [107.694843163, 21.235184038000021],
              [107.69514499300003, 21.23454811800007],
              [107.69499601700005, 21.233628751000118],
              [107.69409574900007, 21.232213420000079],
              [107.69229481300002, 21.229665556000029],
              [107.68914227500002, 21.225843065000035],
              [107.68621444500008, 21.222657181000045],
              [107.68471325000003, 21.220887406000074],
            ],
          ],
          [
            [
              [107.62993840400004, 21.233711537000133],
              [107.62847312500004, 21.233583830000043],
              [107.62660576800008, 21.234707959000062],
              [107.62593673500007, 21.236209852000073],
              [107.62646604100007, 21.238089519000077],
              [107.62792943700001, 21.239219234000117],
              [107.63431823000006, 21.24261164600005],
              [107.63684805700004, 21.243492562000107],
              [107.63791417300004, 21.243369057000017],
              [107.63858174300003, 21.242618646000011],
              [107.63858290600004, 21.241992396000065],
              [107.63791906600001, 21.240738806000081],
              [107.63584927500008, 21.238494220000078],
              [107.63438725100008, 21.236613039000041],
              [107.63327371000005, 21.235460512000067],
              [107.63193611600005, 21.234090623000057],
              [107.62993840400004, 21.233711537000133],
            ],
          ],
          [
            [
              [107.37137771200001, 21.232309543000035],
              [107.37019999400009, 21.230843471000057],
              [107.36876730100003, 21.231248954000037],
              [107.36770780600003, 21.231714007000058],
              [107.367456201, 21.232532413000094],
              [107.36745277900008, 21.233585563000098],
              [107.36800719600002, 21.235342414000066],
              [107.36881074300007, 21.237041455000046],
              [107.36936347500007, 21.239324863000064],
              [107.36953842900007, 21.242952865000042],
              [107.36953122700001, 21.245176141000051],
              [107.36990219500005, 21.245937798000014],
              [107.37064811100004, 21.246232455000055],
              [107.37145721600011, 21.246234753000067],
              [107.37376361500007, 21.245129638000023],
              [107.37532483300002, 21.243495835000111],
              [107.37532821300003, 21.242442703000016],
              [107.37465037000008, 21.24033449100002],
              [107.37372321500001, 21.238342591000055],
              [107.372489628, 21.234887089000054],
              [107.37137771200001, 21.232309543000035],
            ],
          ],
          [
            [
              [107.97016984100001, 21.230190841000059],
              [107.96659517600001, 21.230100343000032],
              [107.96540507400005, 21.23012154900001],
              [107.96230857900008, 21.230103717000048],
              [107.96099333700005, 21.23018941400003],
              [107.95910155400003, 21.230242161000042],
              [107.9568760650001, 21.230618409],
              [107.95382322700002, 21.231401378000037],
              [107.95083334200007, 21.23271234100006],
              [107.94943801800008, 21.233461518000048],
              [107.94713165700004, 21.234528443000045],
              [107.94571034300003, 21.235863949000056],
              [107.94499933900009, 21.237772121000106],
              [107.94530345000003, 21.239108039000065],
              [107.94682557500002, 21.240348838000052],
              [107.95098630800003, 21.243116880000024],
              [107.95636507000009, 21.246362155000043],
              [107.96065657300008, 21.248397677000064],
              [107.96623901800004, 21.249543587000019],
              [107.96915371100005, 21.249799276000125],
              [107.97108225000002, 21.249990366000034],
              [107.97290939800003, 21.249322689000039],
              [107.97362007800004, 21.248177786000078],
              [107.97534615400012, 21.245645004000032],
              [107.97656442300011, 21.241785231000065],
              [107.97727536700005, 21.237873208000032],
              [107.97788478400003, 21.234056558000077],
              [107.97768195400002, 21.232625271000046],
              [107.976870175, 21.231193908000101],
              [107.97590158500006, 21.230510083000098],
              [107.97433295100004, 21.230334862000078],
              [107.97016984100001, 21.230190841000059],
            ],
          ],
          [
            [
              [107.43348325400009, 21.249128822000074],
              [107.43373798500004, 21.246969176000086],
              [107.43343219600004, 21.245333573000025],
              [107.43304363800004, 21.243907248000049],
              [107.43253003700001, 21.242428630000099],
              [107.43100674, 21.23974806299999],
              [107.43019254800005, 21.238114442000011],
              [107.42988389600001, 21.237471391],
              [107.42771838800006, 21.234663194000092],
              [107.42326040900006, 21.230038977000021],
              [107.42010193300004, 21.227052916000041],
              [107.41759171000008, 21.225382243000098],
              [107.4123201620001, 21.222974373000092],
              [107.40500045100002, 21.220210530000116],
              [107.40314117000001, 21.218979351000122],
              [107.40115967700002, 21.217105535000066],
              [107.39961021900007, 21.216108715000104],
              [107.39818227900005, 21.215988059000068],
              [107.39669042500007, 21.216451121000098],
              [107.39501062400009, 21.217439152000047],
              [107.39320315500001, 21.219536234000103],
              [107.39162130200005, 21.220826847000041],
              [107.38885184400006, 21.220867216],
              [107.38736323900008, 21.220279206000022],
              [107.38680578800005, 21.219810552000062],
              [107.3866218790001, 21.219050979000095],
              [107.38675288800002, 21.216890931000073],
              [107.38676079800005, 21.214380197000082],
              [107.38664303700008, 21.212336222000069],
              [107.38646079200001, 21.211051127000054],
              [107.38578120800001, 21.209939829000035],
              [107.38476849500006, 21.209352658000036],
              [107.38209553300005, 21.208467550000073],
              [107.38004411000006, 21.207818188000083],
              [107.37874090900004, 21.206702812000074],
              [107.37787385500008, 21.205413120000038],
              [107.37688367900003, 21.203713494000098],
              [107.37644997600003, 21.203127149000075],
              [107.37582904400008, 21.202715817000062],
              [107.37545569500001, 21.202714764000063],
              [107.37483194800009, 21.203181102000102],
              [107.37376753900004, 21.205226010000011],
              [107.37307458, 21.207857068000102],
              [107.37313379300009, 21.208793417000038],
              [107.37370427000005, 21.210233280000068],
              [107.37483455400005, 21.212812319000115],
              [107.37558022500011, 21.215166396000114],
              [107.37619280900007, 21.217795662000057],
              [107.37658269200008, 21.219978729000033],
              [107.37729698500004, 21.222061190000119],
              [107.377971712, 21.224690594000073],
              [107.37873702399999, 21.227415545000028],
              [107.37916463000002, 21.229932644000101],
              [107.380060771, 21.231895211000037],
              [107.38092739000004, 21.233360366000028],
              [107.38241674900007, 21.234710225000072],
              [107.38403152000009, 21.235767879],
              [107.38626756400001, 21.237178295],
              [107.38682583800002, 21.237764931000044],
              [107.38713406800012, 21.238701917000036],
              [107.38725283400001, 21.240516002000028],
              [107.38799250100001, 21.242799866000084],
              [107.38885941100006, 21.244206442000021],
              [107.39093604500002, 21.246874266000049],
              [107.39466037000005, 21.250102385000069],
              [107.39677507300006, 21.250576208000012],
              [107.39932744700003, 21.250407628000097],
              [107.40100990300007, 21.249768605000071],
              [107.40581004900004, 21.247265716000108],
              [107.409721163, 21.250614002000034],
              [107.41176501000008, 21.252546184000032],
              [107.414555132, 21.254188387000013],
              [107.417719553, 21.255306059000034],
              [107.425915338, 21.25637839900012],
              [107.4274055380001, 21.25655741300001],
              [107.42902185300008, 21.256094496000074],
              [107.43026583100007, 21.255513849000081],
              [107.43144869800007, 21.254582716000044],
              [107.43241408600009, 21.25368020600007],
              [107.43322783800001, 21.251522010000109],
              [107.43348325400009, 21.249128822000074],
            ],
          ],
          [
            [
              [107.63236167600009, 21.250172709000111],
              [107.62891682400007, 21.248088777000085],
              [107.62640791700005, 21.246627087000022],
              [107.62379946000006, 21.245524793000037],
              [107.62077972100005, 21.243969706000065],
              [107.61778606600006, 21.242250888000044],
              [107.61611408700001, 21.24099093100007],
              [107.61433115000003, 21.239373294000075],
              [107.61316613300008, 21.237950469000033],
              [107.61309858900009, 21.237369119000071],
              [107.61337658600009, 21.236257959000056],
              [107.61364350800005, 21.235427130000062],
              [107.61355639800004, 21.234761940000091],
              [107.61267315300006, 21.23426161900008],
              [107.61161289700009, 21.233844117000089],
              [107.60931405100004, 21.23375693800007],
              [107.60692611500008, 21.234002106000013],
              [107.60524369600006, 21.23516292000005],
              [107.60347081300009, 21.237321119000093],
              [107.60293669600004, 21.239065871000037],
              [107.602138661, 21.240145111000075],
              [107.60169584000005, 21.240476831000073],
              [107.60089015400008, 21.240223163000046],
              [107.59841671900007, 21.239138443000094],
              [107.59566200800005, 21.237782426000074],
              [107.59334552000004, 21.237224415000085],
              [107.591888157, 21.236801297000071],
              [107.59100338600007, 21.237049049000056],
              [107.58976280700008, 21.238293698000099],
              [107.58958420100005, 21.239124649000104],
              [107.59002440400006, 21.240039888000069],
              [107.5920536830001, 21.242204976000068],
              [107.59863502900001, 21.245844750000039],
              [107.60583207100007, 21.248801575000087],
              [107.60923777600003, 21.249756733000069],
              [107.61068941800006, 21.249640650000053],
              [107.61237430300011, 21.248898332000095],
              [107.61327777000008, 21.249030614000027],
              [107.61361528800003, 21.249642027000078],
              [107.61361297700002, 21.250805808000095],
              [107.613786707, 21.252385525000058],
              [107.61449251300004, 21.253218026000106],
              [107.61661177700007, 21.254801112000088],
              [107.61899671100005, 21.256218378000113],
              [107.62474057300003, 21.258472595000022],
              [107.63083801000005, 21.260893496000044],
              [107.63488679100003, 21.262298663000045],
              [107.63700895600002, 21.262468403000057],
              [107.63928426800008, 21.262057102000043],
              [107.64043645900003, 21.261846058000074],
              [107.64106104400001, 21.261302670000056],
              [107.64109749400009, 21.260411605000051],
              [107.64030280100006, 21.259745307000074],
              [107.638181898, 21.258910591000109],
              [107.63774127200004, 21.258078617000109],
              [107.63836247700002, 21.256915862000092],
              [107.63889569900007, 21.255503584000031],
              [107.63863194500001, 21.25467188700005],
              [107.63766149900002, 21.253423402000045],
              [107.63554161100004, 21.252089889000025],
              [107.63236167600009, 21.250172709000111],
            ],
          ],
          [
            [
              [107.67395247399999, 21.257803974000034],
              [107.67296818199999, 21.257555703],
              [107.67250823100004, 21.257801843000031],
              [107.67178486100005, 21.258541257000019],
              [107.67178340400002, 21.259405154],
              [107.67210946199999, 21.260701473000054],
              [107.67309274100009, 21.261566822000063],
              [107.67453618600005, 21.262062592000049],
              [107.67611093800005, 21.262558554000051],
              [107.67735827000006, 21.262560375000113],
              [107.67808122500001, 21.262067774000037],
              [107.67834463200005, 21.261574512000081],
              [107.67821456600004, 21.260833841000078],
              [107.6776905020001, 21.260154304000075],
              [107.67651016400005, 21.259350402000088],
              [107.674673985, 21.258175278000024],
              [107.67395247399999, 21.257803974000034],
            ],
          ],
          [
            [
              [107.54430222100009, 21.258041243000037],
              [107.54346806800001, 21.257352123000125],
              [107.54284101600008, 21.257449029000064],
              [107.54116787000002, 21.258132970000077],
              [107.53949355000005, 21.259307889000034],
              [107.53875946300005, 21.260484753000064],
              [107.53802489100012, 21.261858007000086],
              [107.53760163300009, 21.264115705000016],
              [107.53759720000008, 21.265981461000116],
              [107.53832480100006, 21.26755415500012],
              [107.53895048300005, 21.268046445],
              [107.53978582600007, 21.268244585000048],
              [107.54124918700009, 21.267953021],
              [107.54334216200007, 21.266484378000072],
              [107.54501835700002, 21.26452385800004],
              [107.54648557200009, 21.262562891000059],
              [107.54690712300007, 21.260992573000067],
              [107.54690872300003, 21.260305178000038],
              [107.54576228800002, 21.259124438000043],
              [107.54430222100009, 21.258041243000037],
            ],
          ],
          [
            [
              [107.68667388100008, 21.266646356000059],
              [107.68444216300007, 21.266396363000069],
              [107.68358840500004, 21.266580263],
              [107.68286524600006, 21.26719629100009],
              [107.68279849300005, 21.267874962000022],
              [107.68325714800008, 21.268430973000108],
              [107.68470017000001, 21.269235201000036],
              [107.6871281790001, 21.26991740900008],
              [107.68981910300008, 21.270414841000026],
              [107.69152458400005, 21.27134280000007],
              [107.69277167900003, 21.271529650000076],
              [107.69356056900003, 21.270851980000046],
              [107.69356183200004, 21.270049816000025],
              [107.69290678400002, 21.269123320000105],
              [107.69192347600001, 21.268196374000055],
              [107.69061192300003, 21.267268954000059],
              [107.68890570300007, 21.266834621],
              [107.68667388100008, 21.266646356000059],
            ],
          ],
          [
            [
              [107.64603883500011, 21.268047065000047],
              [107.64468099900003, 21.267848497000053],
              [107.64311332400008, 21.26814056700006],
              [107.64081376100006, 21.268726041],
              [107.63966342400001, 21.269313354000104],
              [107.63882688000007, 21.269704787000073],
              [107.63882615100002, 21.270097573000065],
              [107.63955513200003, 21.271375326000097],
              [107.64184985, 21.273441189000053],
              [107.64383243000007, 21.274819149000024],
              [107.64539837800005, 21.27550903200008],
              [107.64644389300005, 21.275117921000053],
              [107.64707326600008, 21.273744172000072],
              [107.6472859620001, 21.271682371000011],
              [107.64718593100008, 21.269227268000087],
              [107.64656103200006, 21.268146096000073],
              [107.64603883500011, 21.268047065000047],
            ],
          ],
          [
            [
              [107.46317370200008, 21.271741109000075],
              [107.46388922800004, 21.271658802000012],
              [107.46495963700005, 21.272585803000062],
              [107.46629805600004, 21.273597484000014],
              [107.46737078700008, 21.273684107000079],
              [107.46960725700002, 21.273269280000036],
              [107.47166695200009, 21.272097687],
              [107.47539179100008, 21.269757204000058],
              [107.47859030200006, 21.266392242000087],
              [107.48072182800011, 21.26304298800008],
              [107.482428412, 21.260105619000043],
              [107.48404197600011, 21.258512638000049],
              [107.48587802600005, 21.257298336000119],
              [107.48883327100006, 21.25545630200012],
              [107.4946572370001, 21.250595333],
              [107.50146669100006, 21.244811945000031],
              [107.50702354200011, 21.239277625000064],
              [107.51114278100006, 21.236513357000049],
              [107.51145683200011, 21.236051820000057],
              [107.51181694400006, 21.235044106000075],
              [107.51172923800009, 21.234371570000029],
              [107.510303777, 21.232435428000059],
              [107.50744826099999, 21.23041205600007],
              [107.50700342800005, 21.22957063299999],
              [107.507630878, 21.228899680000076],
              [107.50861398200007, 21.228985903000108],
              [107.51120426199999, 21.229832078000044],
              [107.51415045900005, 21.231351342000067],
              [107.51522317800007, 21.231353693000088],
              [107.517191096, 21.23085373100011],
              [107.52005646000003, 21.228926964000024],
              [107.52372916600007, 21.225825273000076],
              [107.52597070600008, 21.223056619000054],
              [107.52866420900001, 21.218187763000081],
              [107.5297451810001, 21.214744197000087],
              [107.53113724399999, 21.212015661000102],
              [107.53275485299999, 21.208405080000055],
              [107.533475353, 21.206137336],
              [107.5333883910001, 21.205128594000122],
              [107.53294349400005, 21.204287187000048],
              [107.53223026400011, 21.203529252000038],
              [107.53008938200007, 21.201759724000091],
              [107.52982266200003, 21.201170819000126],
              [107.52982388000004, 21.200666533000053],
              [107.53027199000007, 21.200163205000067],
              [107.53165949300003, 21.19928364700003],
              [107.53371803100001, 21.198111319000112],
              [107.53470320100008, 21.197272904000016],
              [107.53470541100009, 21.196348386000082],
              [107.53417257100006, 21.194918439000077],
              [107.53265881100003, 21.192561880000049],
              [107.53230513500009, 21.190964202000103],
              [107.5328432040001, 21.190208901000048],
              [107.53355841000004, 21.190126357000061],
              [107.53427240000005, 21.190548105000047],
              [107.53730415899999, 21.193496177000078],
              [107.53988992000006, 21.1961070770001],
              [107.54185266200001, 21.197624014000027],
              [107.54390718300002, 21.198132547000093],
              [107.54739334000007, 21.197971599000098],
              [107.54944865700003, 21.198143895000051],
              [107.55185955600003, 21.199157360000065],
              [107.55721675400008, 21.201605532000123],
              [107.56123432000005, 21.203630669000084],
              [107.56302062100006, 21.204222529000027],
              [107.56489762700002, 21.204226226000067],
              [107.56588138200006, 21.203976015000059],
              [107.56704520800008, 21.203137824000073],
              [107.56767367300003, 21.201878335000046],
              [107.56861300800003, 21.201501952000079],
              [107.56959544300005, 21.201840059000077],
              [107.57066578600006, 21.20285070200012],
              [107.57110916600007, 21.204448460000066],
              [107.5713758310001, 21.205121353000052],
              [107.57289329200007, 21.206048804000055],
              [107.57494835000003, 21.206388951000044],
              [107.57673618000007, 21.206308326000034],
              [107.57798753100005, 21.20631071500005],
              [107.57977481500004, 21.20648221500003],
              [107.58111429000012, 21.207073078000072],
              [107.58222968700002, 21.207957680000078],
              [107.58356683300009, 21.2096411240001],
              [107.58579750100009, 21.211494328000043],
              [107.5888312540001, 21.214021362000054],
              [107.59124026000003, 21.216126966000012],
              [107.59382774600007, 21.218400951000049],
              [107.59570227100009, 21.219665053000099],
              [107.59695266400007, 21.22017160500009],
              [107.59847192400011, 21.220342453000015],
              [107.60052786800004, 21.220346172000056],
              [107.60624858900006, 21.220440468000042],
              [107.60763403000003, 21.220484947000081],
              [107.6087974380001, 21.219814647000042],
              [107.60924725300002, 21.21838668300002],
              [107.6091602210001, 21.217209895000089],
              [107.60809350100004, 21.214266417000083],
              [107.60604646600004, 21.209892388000107],
              [107.60462349000008, 21.206359889000034],
              [107.60355380700003, 21.204929184000051],
              [107.60203535000007, 21.204422175000026],
              [107.59846286400007, 21.203070969000045],
              [107.59658813300003, 21.201974946000043],
              [107.59493730700004, 21.200669191000095],
              [107.59431532800009, 21.198903041000072],
              [107.59307244700001, 21.194866447000038],
              [107.59209634600008, 21.191502702000072],
              [107.59093870100004, 21.18948338700006],
              [107.58870931200005, 21.187125868000045],
              [107.58478368000004, 21.183924643000026],
              [107.58308993700007, 21.181904255000106],
              [107.58188856600005, 21.179506547000045],
              [107.58081812200004, 21.178579963000033],
              [107.57885453200001, 21.177399530000059],
              [107.57635293400006, 21.177058547000094],
              [107.57385059800005, 21.177053743000052],
              [107.57206377500009, 21.176798141000042],
              [107.5713512340001, 21.175704098000026],
              [107.57090586900003, 21.175030825000093],
              [107.57072973300002, 21.173853761000046],
              [107.57073325400005, 21.172256786000077],
              [107.57011269800005, 21.169986179000055],
              [107.56886678200007, 21.16763028900008],
              [107.56815466600004, 21.166368117000012],
              [107.56690506700002, 21.16569325400004],
              [107.56431521000006, 21.164931692],
              [107.56324417600008, 21.16434121499999],
              [107.56270931700006, 21.163751783000073],
              [107.562621646, 21.162995138000085],
              [107.56271271400007, 21.162238836000121],
              [107.56271441300004, 21.161482357000061],
              [107.56235829000006, 21.160893283000078],
              [107.56093058900007, 21.159965867],
              [107.55571073599999, 21.156551266000086],
              [107.54973492400009, 21.151664038000014],
              [107.54473858100005, 21.148375668000071],
              [107.54126004100002, 21.145678712000034],
              [107.54018918200005, 21.145088104000109],
              [107.53920608100006, 21.145170109000055],
              [107.53839970800004, 21.146093040000032],
              [107.53687568200007, 21.148191243],
              [107.53571085200005, 21.149533684000055],
              [107.53499378900005, 21.150456784000049],
              [107.53490262800005, 21.151213089000095],
              [107.53507974100006, 21.151885899000078],
              [107.5362395760001, 21.152644828000078],
              [107.53766650700004, 21.153824570000076],
              [107.53855690200008, 21.155171297000038],
              [107.53900011900006, 21.156685201000066],
              [107.53890816600001, 21.157777709000044],
              [107.53819149500005, 21.158532702000073],
              [107.53649144500007, 21.159453740000075],
              [107.53497130900006, 21.159870817000034],
              [107.53354153100007, 21.159867808000051],
              [107.53211337900002, 21.159192365000123],
              [107.52988240100009, 21.15792682500004],
              [107.52734023000006, 21.156030197000049],
              [107.52537821600001, 21.154428967000079],
              [107.5232389650001, 21.152238945000065],
              [107.52234724500003, 21.151480535000111],
              [107.51957883700004, 21.15080209500011],
              [107.51573816500004, 21.15012128500009],
              [107.51296937800002, 21.149610885000044],
              [107.51189857400007, 21.149020135000015],
              [107.51181069600008, 21.148431558000077],
              [107.51270677700006, 21.147424866],
              [107.51395816800003, 21.147259509],
              [107.51556739400004, 21.1469268210001],
              [107.51664195300005, 21.146004559000083],
              [107.5168229500001, 21.145080342000085],
              [107.51664521500005, 21.143728380000049],
              [107.51575669500008, 21.141709084000034],
              [107.51468976299999, 21.140536648000108],
              [107.51365879000005, 21.139712091],
              [107.51170089700005, 21.138070556000073],
              [107.51011862400006, 21.136886433000065],
              [107.50652991300004, 21.134256368000059],
              [107.50492418600005, 21.133244089000058],
              [107.50340611600004, 21.132904453000023],
              [107.50188719100004, 21.132901039],
              [107.50099456700008, 21.132562803],
              [107.50081652199999, 21.132310227000062],
              [107.50090759100007, 21.131637973000039],
              [107.50162409100002, 21.130967126000044],
              [107.50225253200006, 21.129791730000044],
              [107.502255109, 21.128783045000077],
              [107.50216748400004, 21.128110384000109],
              [107.50163377000003, 21.127184541000062],
              [107.50181483100009, 21.126260307000067],
              [107.50432249100001, 21.123912296000036],
              [107.50450332200005, 21.12307211600006],
              [107.50414766000002, 21.122398848000046],
              [107.5027220210001, 21.120882580000085],
              [107.5003132090001, 21.119532205],
              [107.49665537600005, 21.117506486000082],
              [107.49233031000004, 21.114428419000127],
              [107.48938627200008, 21.112824500000023],
              [107.48635472100007, 21.110547849000035],
              [107.48118272100005, 21.106921163000081],
              [107.47708023899999, 21.104305597000028],
              [107.47351477300003, 21.101354980000053],
              [107.47155306800008, 21.100005310000114],
              [107.47021717600003, 21.098488998000064],
              [107.46950732500001, 21.096721986000055],
              [107.46911025500002, 21.094913681],
              [107.46947352900006, 21.092728917000066],
              [107.47001362800007, 21.091217076000035],
              [107.46983611900004, 21.090796335000036],
              [107.46876462300003, 21.090625630000126],
              [107.4672455680001, 21.090790111000054],
              [107.4656364840001, 21.091206545000048],
              [107.46456430600003, 21.091288010000042],
              [107.46358214700004, 21.091117506000053],
              [107.46233384900005, 21.090273844000059],
              [107.45965817600009, 21.088754172000087],
              [107.45858670900007, 21.088583427000081],
              [107.45733563900004, 21.088748487000061],
              [107.45572397600009, 21.090089554000087],
              [107.45473736200007, 21.091516196],
              [107.45374930900003, 21.093447217000083],
              [107.45285293600003, 21.094537820000077],
              [107.45231695800001, 21.094536493000057],
              [107.45115683900012, 21.094113304000039],
              [107.45017775300002, 21.092849922000042],
              [107.44857837900003, 21.089819664000053],
              [107.44795927200005, 21.087632457],
              [107.44707172000007, 21.085612704000042],
              [107.44537977200005, 21.083759051000058],
              [107.44261884700005, 21.080893916000051],
              [107.43949828300005, 21.078868471000064],
              [107.43566393600005, 21.076588957000105],
              [107.43160821700012, 21.073678303000065],
              [107.42772251100004, 21.07108239700004],
              [107.42748778500007, 21.070953141000089],
              [107.42544893800005, 21.070043721000076],
              [107.42340458100009, 21.06894936300003],
              [107.42037398400007, 21.066839771000012],
              [107.41752182900007, 21.064814670000054],
              [107.41636372500008, 21.063802816000084],
              [107.41618686100001, 21.063213872000027],
              [107.41654815200008, 21.061869747000017],
              [107.41637379700003, 21.060440133000036],
              [107.41584244900012, 21.058925512000123],
              [107.41531990900005, 21.058397913000078],
              [107.41450676700001, 21.057576891000096],
              [107.41308154100003, 21.056312093000074],
              [107.411346409, 21.054163735000131],
              [107.40992275500007, 21.052394499000023],
              [107.40840675700004, 21.05163382500006],
              [107.40323107799999, 21.05019074100003],
              [107.39841090600005, 21.049336990000086],
              [107.39350277800003, 21.048062546000068],
              [107.39037930600006, 21.047297328000077],
              [107.38868506600002, 21.046451957000031],
              [107.38699296500008, 21.044934009000116],
              [107.38530008599999, 21.0436682630001],
              [107.38271469100003, 21.042231864000094],
              [107.38155476800007, 21.041892346000068],
              [107.38043696700005, 21.042351600000053],
              [107.37962972300005, 21.043442257000052],
              [107.37935483900004, 21.045627304000014],
              [107.37952489400007, 21.048318010000067],
              [107.38031771400004, 21.051767075000072],
              [107.38111083900003, 21.055132046000047],
              [107.38226257900001, 21.058077658000052],
              [107.38341727900008, 21.060098511000014],
              [107.384219258, 21.060689225000125],
              [107.38560298200009, 21.060903238000073],
              [107.38676302500006, 21.061242733000093],
              [107.38819075600007, 21.061667024000116],
              [107.38961638800008, 21.062763840000095],
              [107.38970280500003, 21.063688821000071],
              [107.38925411800003, 21.064360120000103],
              [107.38827136000008, 21.064441475000081],
              [107.3875568280001, 21.064439497000095],
              [107.38586112600005, 21.064014460000102],
              [107.38460989500008, 21.064263185000073],
              [107.38398388000007, 21.0645136360001],
              [107.38380285000001, 21.065269739000044],
              [107.38442382100006, 21.066616540000012],
              [107.3854023490001, 21.067880269000035],
              [107.38557859500003, 21.068637353000064],
              [107.38477366900004, 21.068971387000076],
              [107.38263000300005, 21.068965413000029],
              [107.38048741500005, 21.06862315100004],
              [107.37879276399998, 21.067861803000106],
              [107.37834617600006, 21.067860549],
              [107.37798756200007, 21.068279873000058],
              [107.37771557700003, 21.069540103000058],
              [107.37771155000006, 21.070801093000036],
              [107.37797870600005, 21.071054045],
              [107.37860287299999, 21.071392060000079],
              [107.38056604200007, 21.07198603600002],
              [107.38163442300001, 21.073081883],
              [107.38252097300008, 21.075186001000112],
              [107.3827830820001, 21.077036177000075],
              [107.38286734300006, 21.07863365500009],
              [107.38223941300006, 21.079472560000042],
              [107.38080435100007, 21.081317970000057],
              [107.38004067700004, 21.082702896000072],
              [107.38012705799999, 21.083627850000042],
              [107.38065927400005, 21.084806245000017],
              [107.38261939700006, 21.08640894600002],
              [107.38582907000006, 21.088351358000068],
              [107.39046545200007, 21.091138277000084],
              [107.39474464600002, 21.093924118000103],
              [107.396127044, 21.094642438000029],
              [107.39692712200012, 21.095905560000091],
              [107.39692035600009, 21.098091176000096],
              [107.39584185500003, 21.100189785000012],
              [107.3954808610001, 21.10136567000005],
              [107.39601191500006, 21.102964298000067],
              [107.39752280600003, 21.105490266000082],
              [107.39823100700009, 21.107593729000129],
              [107.39822347600008, 21.110031474000031],
              [107.39786014000005, 21.111963886000048],
              [107.39785130600002, 21.114821909000092],
              [107.39820244100012, 21.116840304000029],
              [107.39998227200005, 21.119114749000097],
              [107.40256596800003, 21.121475406000023],
              [107.40395014400008, 21.121689291000045],
              [107.4064517620001, 21.121696016000065],
              [107.40895311700004, 21.121786766000028],
              [107.41002447700004, 21.122041804000077],
              [107.41100547400004, 21.122632836000044],
              [107.41162707000004, 21.123895370000056],
              [107.41260275700003, 21.126251608000054],
              [107.41340484400004, 21.126926203],
              [107.41402950400004, 21.127180035000045],
              [107.41760560100008, 21.126432948000051],
              [107.42037530900009, 21.126440221000031],
              [107.42555710700003, 21.126537788000022],
              [107.4288621530001, 21.126798520000101],
              [107.43028922700006, 21.127642789000056],
              [107.43108941600009, 21.12898977800004],
              [107.43117483300006, 21.130334925000049],
              [107.43062744300006, 21.134200168000028],
              [107.43013158800007, 21.136790879000067],
              [107.43007781900006, 21.13882188300007],
              [107.430254312, 21.139578850000085],
              [107.43141318400006, 21.140506453000093],
              [107.43266117200002, 21.141518337000065],
              [107.43377379800006, 21.142992170000063],
              [107.43421592900006, 21.144590365000049],
              [107.43403257600008, 21.146186956000079],
              [107.43429746700005, 21.147280354000131],
              [107.43536827000001, 21.147787423000075],
              [107.43724545800008, 21.147540038000031],
              [107.43903353100005, 21.147208360000036],
              [107.44037240299998, 21.14771607600003],
              [107.44170984500006, 21.148728122000101],
              [107.44215398100005, 21.14965385000005],
              [107.44232955800001, 21.150747007000078],
              [107.44179028400006, 21.151838359000052],
              [107.44053518000004, 21.153264122000067],
              [107.43910303200002, 21.154101040000036],
              [107.43740401800005, 21.154516998000084],
              [107.43445518900005, 21.154509482],
              [107.43347175100003, 21.154675082000018],
              [107.43284477400002, 21.155177796000046],
              [107.432663124, 21.156185985000064],
              [107.43310428700009, 21.158120355000065],
              [107.43349992900005, 21.160348796000058],
              [107.433584153, 21.162114133000017],
              [107.4333104400001, 21.164046652000046],
              [107.43259260300003, 21.165053453000034],
              [107.43160862900007, 21.165387144000114],
              [107.43053724700006, 21.165048174000042],
              [107.42982652900005, 21.163617439000056],
              [107.42894251600009, 21.160337083000066],
              [107.42814813700009, 21.156972879000065],
              [107.42779440600005, 21.155711147000119],
              [107.42717087200006, 21.15503709900004],
              [107.4260990600001, 21.154866216000109],
              [107.42520448200007, 21.155200107000027],
              [107.42341257900004, 21.15679247700006],
              [107.42161715200008, 21.159561573000119],
              [107.42035984900005, 21.161659626000073],
              [107.41937565600007, 21.16385713699999],
              [107.41878450200001, 21.165761766000024],
              [107.41814691300002, 21.168132224000047],
              [107.41796119300001, 21.169995544000031],
              [107.41804974900006, 21.170504093000098],
              [107.4184087850001, 21.170928628000048],
              [107.41957924100004, 21.171101138000026],
              [107.42065862500009, 21.17161227200004],
              [107.42137745900006, 21.172207181000054],
              [107.42173347600003, 21.173648318000104],
              [107.42227167400006, 21.174412187000065],
              [107.42326175900008, 21.17466892000008],
              [107.426682896, 21.175270834000059],
              [107.43028521100007, 21.175534287000097],
              [107.43163833300012, 21.174860027000086],
              [107.43272221200002, 21.173846193000045],
              [107.43471321800007, 21.170631995000022],
              [107.43688357100007, 21.167672382000099],
              [107.4391415000001, 21.165560136000039],
              [107.4413979130001, 21.163956176000021],
              [107.44365162500006, 21.163284101],
              [107.44509227400006, 21.163457158000121],
              [107.44644092600001, 21.164307728000061],
              [107.44814676000001, 21.166260523000076],
              [107.44850465300006, 21.167108612000085],
              [107.44805164300006, 21.168039385],
              [107.44723713800008, 21.16939285400008],
              [107.44705289900001, 21.170832607000101],
              [107.44786068500007, 21.171851243],
              [107.44920726100001, 21.173464242000058],
              [107.44911430800005, 21.174480626000047],
              [107.44703485500003, 21.177186402000103],
              [107.44351168000003, 21.180735698000049],
              [107.44314869500002, 21.181666665000073],
              [107.44323489900003, 21.183022352000066],
              [107.44359135000005, 21.184378707000029],
              [107.44390481600004, 21.185014866000081],
              [107.44471361100008, 21.185694624000107],
              [107.44750252600009, 21.186972351000044],
              [107.44966304900007, 21.187486025000091],
              [107.45191581400003, 21.187237469000038],
              [107.45444000700007, 21.186565979000079],
              [107.4567847280001, 21.185639865000013],
              [107.45858753200005, 21.185220701000063],
              [107.46137910000002, 21.185566380000061],
              [107.46317885700006, 21.18624847800001],
              [107.46462039100005, 21.186167257000044],
              [107.46561268400009, 21.185661354000018],
              [107.46633588700007, 21.18473121600006],
              [107.46660960400007, 21.183461132000048],
              [107.46760373200007, 21.182277491000079],
              [107.469317093, 21.181603875000029],
              [107.47102906200007, 21.18143853800003],
              [107.47301034200004, 21.181612699000048],
              [107.474992548, 21.181447975000097],
              [107.47661556900009, 21.180858800000024],
              [107.47747188400007, 21.180649031000073],
              [107.47855192600008, 21.180990449000078],
              [107.47917975800007, 21.182008528000026],
              [107.47953781600003, 21.182856536000116],
              [107.47926576000008, 21.183533625000095],
              [107.47773189800009, 21.184461896000073],
              [107.47601808400003, 21.18530500700011],
              [107.47457336000005, 21.186572322000075],
              [107.47339749300001, 21.188348564000044],
              [107.47294363500004, 21.189618217000117],
              [107.47312219900006, 21.190211652000102],
              [107.47492226800006, 21.19080893900005],
              [107.47699304800005, 21.19123741700006],
              [107.47735225100011, 21.191661842000094],
              [107.47699009000007, 21.192338706000058],
              [107.47617750799999, 21.193014512000019],
              [107.47357540100008, 21.194614521000062],
              [107.47133756000001, 21.195869921000018],
              [107.4693698790001, 21.196369497000056],
              [107.46767124000006, 21.196533522000038],
              [107.46534419600007, 21.197704582000121],
              [107.46212007400004, 21.200050121000032],
              [107.45880696400009, 21.202227284000053],
              [107.45719459700008, 21.203484053000032],
              [107.45549095000007, 21.20541295200011],
              [107.45477189800009, 21.206839985000059],
              [107.4546796930001, 21.207848324000089],
              [107.45530419699999, 21.208270094000078],
              [107.45566149200006, 21.208355013000087],
              [107.45691449600008, 21.207769767000073],
              [107.45951197000007, 21.20584304900008],
              [107.46103285900006, 21.205342473000051],
              [107.46210521200004, 21.205429122000027],
              [107.46335447000003, 21.20618858100007],
              [107.46397690400002, 21.20736674700003],
              [107.46397320200006, 21.208711486000098],
              [107.46325419900008, 21.210138537000134],
              [107.46179422800007, 21.212951281000066],
              [107.46159183500002, 21.21407506700006],
              [107.46131628000005, 21.215975022000052],
              [107.46113354700003, 21.217403353000108],
              [107.46050457600003, 21.21857845699999],
              [107.45898031200004, 21.220255635000029],
              [107.45593358300002, 21.22293760900007],
              [107.45494652300006, 21.224279893000109],
              [107.45270113200009, 21.228056337000019],
              [107.45117246100008, 21.231246212000073],
              [107.45009379700009, 21.233344619000057],
              [107.44969351, 21.236192231000039],
              [107.44996766500005, 21.23728107700007],
              [107.45035887400009, 21.239905251000032],
              [107.45055996200003, 21.242380342000011],
              [107.45055118400009, 21.245489855000073],
              [107.45063726900003, 21.246666648000101],
              [107.45152819800002, 21.247761389000082],
              [107.45250971700005, 21.248436145000049],
              [107.45402883600008, 21.248692020000078],
              [107.456800754, 21.248530757000061],
              [107.45796367800003, 21.248281483000042],
              [107.46001898, 21.248622663000027],
              [107.46032967700005, 21.249421809000069],
              [107.45983912500004, 21.249901891],
              [107.45894102600008, 21.25046893000011],
              [107.45661350900005, 21.251555756000059],
              [107.45535904700009, 21.252561163000024],
              [107.45454923100004, 21.25440805500007],
              [107.45445441100003, 21.25634073400002],
              [107.45404371600006, 21.259323123000051],
              [107.45340984600001, 21.262178895000083],
              [107.45278044700007, 21.263437919000069],
              [107.45224210200003, 21.264108897000078],
              [107.45179245800004, 21.26503220900009],
              [107.45170114800004, 21.265704290000095],
              [107.45223382200005, 21.267050221000083],
              [107.45312225500003, 21.269069328000079],
              [107.45499070400007, 21.272351407000123],
              [107.45614649100006, 21.274707295],
              [107.45659074800004, 21.275716824000042],
              [107.4570810000001, 21.276264266000041],
              [107.45815421, 21.276182854000055],
              [107.46012621600008, 21.274422894000011],
              [107.46191913400001, 21.272746518000041],
              [107.46317370200008, 21.271741109000075],
            ],
          ],
          [
            [
              [107.40622784600006, 21.274524013000118],
              [107.40426133300006, 21.274518733000036],
              [107.40047826700004, 21.274935047000021],
              [107.39805486600008, 21.275923687000017],
              [107.397598848, 21.276633318000023],
              [107.39805046800006, 21.27734541600006],
              [107.40092157700005, 21.278348419000089],
              [107.40424958000007, 21.278357396000033],
              [107.40621873500001, 21.277509636000055],
              [107.40697726700007, 21.276800807000114],
              [107.40698160000008, 21.275379076000078],
              [107.40622784600006, 21.274524013000118],
            ],
          ],
          [
            [
              [107.58482049499999, 21.265780431000088],
              [107.58586858700002, 21.264211223000103],
              [107.58597515500004, 21.263229434000053],
              [107.58493167700004, 21.262638284000076],
              [107.58179642700006, 21.263123385000071],
              [107.57907905700003, 21.263609226000121],
              [107.57719895700008, 21.263409249000098],
              [107.57636530700002, 21.262425667000073],
              [107.57500994100005, 21.261146485000012],
              [107.57427928400008, 21.260850477000034],
              [107.57229496400004, 21.260552059000034],
              [107.56978676799999, 21.260940003000073],
              [107.56801029899999, 21.261132942000046],
              [107.566756631, 21.261130493000024],
              [107.56571256600003, 21.260833844000082],
              [107.56352239900011, 21.259160154000064],
              [107.56185395500007, 21.257782059000085],
              [107.56049827300008, 21.256699175000016],
              [107.55903769600012, 21.255812479000014],
              [107.55726261400005, 21.255416131000082],
              [107.55548573500003, 21.255805377000044],
              [107.55349834100008, 21.256881579],
              [107.55297373100002, 21.257862519000078],
              [107.55307572500003, 21.25894292100012],
              [107.55349225800003, 21.25953295300009],
              [107.55526557500011, 21.260714912000097],
              [107.55703825200005, 21.262191457000057],
              [107.55724518700004, 21.263075662000027],
              [107.55682550500003, 21.263860406000035],
              [107.55588344299998, 21.264644110000042],
              [107.5543163350001, 21.264640962000044],
              [107.55285459900003, 21.264245225000074],
              [107.55139264200002, 21.263947666000057],
              [107.55066132100009, 21.263946183000115],
              [107.54877919800006, 21.26462975],
              [107.54584865000007, 21.266882321000047],
              [107.54501055100009, 21.26786258300006],
              [107.54500802500006, 21.268942755000083],
              [107.54542386600009, 21.26982739],
              [107.546987825, 21.271205358000067],
              [107.54938739700003, 21.27268321800004],
              [107.55418800900004, 21.275049659000089],
              [107.55752823600001, 21.276431108000082],
              [107.56160032700005, 21.277617571000107],
              [107.56442043400006, 21.278015917000026],
              [107.56703160000011, 21.27841381899999],
              [107.56859969, 21.278024097000078],
              [107.57027464500003, 21.27655441100007],
              [107.57404325600001, 21.273222992000029],
              [107.58053025700011, 21.268914686000073],
              [107.58345938400008, 21.267152648000028],
              [107.58482049499999, 21.265780431000088],
            ],
          ],
          [
            [
              [107.38455176000006, 21.27394984500004],
              [107.38003156800008, 21.271859384],
              [107.37855015000008, 21.274229923000028],
              [107.37959627200003, 21.276310702000067],
              [107.3814852630001, 21.278195933000084],
              [107.38316593200007, 21.279387949000039],
              [107.38463857100005, 21.279787824],
              [107.38590283900004, 21.279494499000059],
              [107.38664352700003, 21.278309222000047],
              [107.38633331600003, 21.276527361000049],
              [107.38455176000006, 21.27394984500004],
            ],
          ],
          [
            [
              [107.37357898500008, 21.281438869000091],
              [107.37611804000001, 21.277587209000082],
              [107.37359554900011, 21.276293811000087],
              [107.37201928100008, 21.275398840000022],
              [107.37064877500009, 21.275988622000064],
              [107.36874835500006, 21.277665273000054],
              [107.36747989200009, 21.279244763000094],
              [107.36726579000006, 21.280332534000124],
              [107.36747506700004, 21.280728910000057],
              [107.36821105400001, 21.281027844],
              [107.36989582200006, 21.280933699000038],
              [107.3709482950001, 21.281035635000123],
              [107.37357898500008, 21.281438869000091],
            ],
          ],
          [
            [
              [107.54446909600006, 21.276011851000121],
              [107.54373772900001, 21.276010346000085],
              [107.54154064000005, 21.277282362000079],
              [107.53965766000003, 21.27826042],
              [107.53913315400007, 21.279143097000116],
              [107.53934049100008, 21.279830904],
              [107.54069714300006, 21.280521087000089],
              [107.54299414800005, 21.281213212000068],
              [107.54435173400002, 21.281510594000075],
              [107.54612864100005, 21.281219657000072],
              [107.54738472200006, 21.280240269000039],
              [107.54822354700011, 21.278965435000075],
              [107.54791169300006, 21.27827742200008],
              [107.54551206400001, 21.276799569000012],
              [107.54446909600006, 21.276011851000121],
            ],
          ],
          [
            [
              [107.55240146700001, 21.27956308900005],
              [107.55125238400007, 21.279462568000085],
              [107.55010240400003, 21.279754830000016],
              [107.54873957500007, 21.281715975000012],
              [107.54842407800008, 21.282599085000072],
              [107.54873547500009, 21.283483480000065],
              [107.54915227500003, 21.283975310000081],
              [107.54988276300007, 21.284369574000017],
              [107.55165877300003, 21.284471370000091],
              [107.55458637200005, 21.28359351800011],
              [107.55657471300007, 21.282222774000026],
              [107.55668098200007, 21.281437419000056],
              [107.55532427600008, 21.280747335000072],
              [107.55386264200003, 21.280253411000061],
              [107.55240146700001, 21.27956308900005],
            ],
          ],
          [
            [
              [107.65251021700007, 21.271494227000076],
              [107.65021346600003, 21.270508637000063],
              [107.64948176600005, 21.270703883000053],
              [107.64926980200002, 21.272372896000036],
              [107.64916107900008, 21.27472945800006],
              [107.64915789200008, 21.276496986000026],
              [107.65009468100006, 21.278462395000105],
              [107.65228634500002, 21.279840588000098],
              [107.6566701030001, 21.282400519000078],
              [107.66001049699999, 21.284173190000018],
              [107.66199416800008, 21.28505997400012],
              [107.66356145200008, 21.285062360000047],
              [107.6655486930001, 21.283887030000059],
              [107.66690935100006, 21.282514348000078],
              [107.66680688000011, 21.281335849000094],
              [107.66586839100009, 21.280254283000133],
              [107.66419937900011, 21.278680619000042],
              [107.66106817400002, 21.276810128000086],
              [107.65898045100003, 21.275726762000069],
              [107.65438855300006, 21.272773737000072],
              [107.65251021700007, 21.271494227000076],
            ],
          ],
          [
            [
              [107.38118084000004, 21.281359696000024],
              [107.38024950100008, 21.281197860000042],
              [107.37931614100003, 21.281672942000093],
              [107.37820953400005, 21.283341751000059],
              [107.37719168900003, 21.283736970000078],
              [107.37558008300009, 21.284369357000088],
              [107.37430834900002, 21.284684230000025],
              [107.37311953100007, 21.285556629],
              [107.37235221500001, 21.287067137000101],
              [107.37192323900004, 21.288737828000045],
              [107.37200384400003, 21.290011884000037],
              [107.37259455300007, 21.290730082000039],
              [107.37335597400001, 21.291050695000102],
              [107.37462724800012, 21.290895059000015],
              [107.3768329430001, 21.289945908000085],
              [107.37997684099999, 21.28700899600009],
              [107.38201961900008, 21.283989339000087],
              [107.382446982, 21.282796305000048],
              [107.38211116000011, 21.281839988000058],
              [107.38118084000004, 21.281359696000024],
            ],
          ],
          [
            [
              [107.48707787800004, 21.289507133000107],
              [107.48612988000011, 21.289299223000043],
              [107.48488784500002, 21.289913467000062],
              [107.48422952800009, 21.290529072000105],
              [107.48291197100005, 21.292103105000045],
              [107.48217793700007, 21.293747064000087],
              [107.48195447100005, 21.295460774000041],
              [107.48238953100008, 21.296490334000048],
              [107.48289921400006, 21.296902944000081],
              [107.48414056300001, 21.296562992000112],
              [107.48655192000004, 21.295265777000068],
              [107.487794525, 21.294445820000085],
              [107.48838023800006, 21.293692902000046],
              [107.48845663000007, 21.292390255],
              [107.48802353200003, 21.290606439000022],
              [107.48773329400009, 21.289988639000065],
              [107.48707787800004, 21.289507133000107],
            ],
          ],
          [
            [
              [107.79254519500006, 21.295293779000076],
              [107.79094715400001, 21.294165135000043],
              [107.788815239, 21.293787396000049],
              [107.78428351000012, 21.294283985000014],
              [107.77988560300004, 21.294279659000011],
              [107.77602105600002, 21.294025307000105],
              [107.77428912800008, 21.29352259200008],
              [107.76802769700008, 21.29163753300007],
              [107.76323308100004, 21.289127663000059],
              [107.75883823100003, 21.286743363000049],
              [107.75617482200001, 21.285237557000066],
              [107.75404418000002, 21.283982790000074],
              [107.75257860100008, 21.28373067600009],
              [107.75044576000009, 21.28422924800013],
              [107.74951114000004, 21.285605845000077],
              [107.74870897100001, 21.287608778000063],
              [107.74923961300004, 21.289487983000029],
              [107.75230123800004, 21.292246703000025],
              [107.75642990000006, 21.294380348000082],
              [107.76375611100005, 21.297394001000036],
              [107.76815335200008, 21.298024838000096],
              [107.77468327400001, 21.298407291000032],
              [107.78281209200006, 21.299166838000083],
              [107.78867653000007, 21.298796796000104],
              [107.79187616300004, 21.297797921000061],
              [107.79320981700008, 21.296922502000101],
              [107.79307734600005, 21.296170947000093],
              [107.79254519500006, 21.295293779000076],
            ],
          ],
          [
            [
              [107.69797356700012, 21.295275043000039],
              [107.69609457300008, 21.294094154000028],
              [107.69431808200008, 21.294189903000046],
              [107.69196508400009, 21.293706964000059],
              [107.69044568300005, 21.293019754000042],
              [107.68885195500008, 21.292268107000019],
              [107.68707504700005, 21.29056503200006],
              [107.68627596900009, 21.289695844000086],
              [107.68556110500002, 21.288236309000027],
              [107.68518750700011, 21.28656708100003],
              [107.68289297400005, 21.284010743000081],
              [107.68028392700009, 21.282141279000122],
              [107.67600548600004, 21.278894603000076],
              [107.67078720400011, 21.275450039000084],
              [107.66682065900009, 21.273283778000085],
              [107.66055775700006, 21.269935553000074],
              [107.65565201800005, 21.267276644000077],
              [107.65283310600006, 21.266192064000045],
              [107.65126615100007, 21.266091404000065],
              [107.65074237000009, 21.266876167000071],
              [107.65094956000009, 21.267858465000074],
              [107.65251388900002, 21.269432094000074],
              [107.65689707000004, 21.272188460000052],
              [107.66216785800006, 21.27528976100006],
              [107.6667606070001, 21.27784982200005],
              [107.66832632600006, 21.278735939000093],
              [107.66936981000006, 21.279523070000025],
              [107.66989005100004, 21.28080039000011],
              [107.66999303900003, 21.281684305000056],
              [107.66889387200004, 21.282910102000052],
              [107.66753273100004, 21.28457738500007],
              [107.66679981800006, 21.285460041000093],
              [107.6667981360001, 21.286441987000067],
              [107.66752628600004, 21.288490037000059],
              [107.67011385100008, 21.290929520000113],
              [107.67449527300005, 21.293390130000134],
              [107.67971604200009, 21.295656186000045],
              [107.68302934400006, 21.296830827000065],
              [107.68799025200011, 21.298359860000105],
              [107.69190610400008, 21.299685373000088],
              [107.695247732, 21.301064685000078],
              [107.69660492200003, 21.301852088000125],
              [107.69754507000002, 21.302049756000052],
              [107.69869511200004, 21.301658549000102],
              [107.69921941000008, 21.300480956000101],
              [107.699535161, 21.299008501000039],
              [107.69953713200007, 21.297732001000021],
              [107.69922533100004, 21.296651456000056],
              [107.69797356700012, 21.295275043000039],
            ],
          ],
          [
            [
              [107.3849363730001, 21.296820503000014],
              [107.38658748600002, 21.296592451000059],
              [107.38906305200004, 21.296599292000096],
              [107.39087919500002, 21.296371663000087],
              [107.39211964700007, 21.295522103000053],
              [107.39320061400008, 21.292888601000115],
              [107.39453006100003, 21.289945590000059],
              [107.39453488700008, 21.288394735000082],
              [107.39305880200007, 21.285444022],
              [107.39223656700007, 21.2845112360001],
              [107.39108136500005, 21.284508063000104],
              [107.38913084600004, 21.285521750000044],
              [107.38785762400003, 21.286314379000082],
              [107.38581817900004, 21.288299102000074],
              [107.38131457200009, 21.292585734000014],
              [107.37958055000007, 21.294324191000058],
              [107.37767544100009, 21.296567592000045],
              [107.37651247300005, 21.298968142000071],
              [107.37567931200005, 21.301447142000107],
              [107.37500967900006, 21.304391845000033],
              [107.37450307700006, 21.307957324000078],
              [107.37466388700004, 21.309275978000031],
              [107.37540460900004, 21.309898389000104],
              [107.37722139900009, 21.309515807000068],
              [107.3799491450001, 21.308127715000055],
              [107.38061205100006, 21.307276619000071],
              [107.38029035600009, 21.30463930700008],
              [107.38004921900003, 21.302622547000013],
              [107.38063278900009, 21.30076317],
              [107.38270269800002, 21.298597765000068],
              [107.3849363730001, 21.296820503000014],
            ],
          ],
          [
            [
              [107.65562442200009, 21.306471752000085],
              [107.65502845000006, 21.306427737],
              [107.65447795000009, 21.306599211000062],
              [107.65383536700003, 21.30698595000009],
              [107.65319216700001, 21.30771735900003],
              [107.65282413800003, 21.308406109000089],
              [107.65282244700006, 21.309353920000085],
              [107.6531417030001, 21.31030224200012],
              [107.65341580800003, 21.310862751000045],
              [107.65414834400008, 21.31146704400004],
              [107.65446899700005, 21.31163987500004],
              [107.65511113600007, 21.311511632000091],
              [107.65557017700004, 21.311210763000062],
              [107.65625969200006, 21.310220936000057],
              [107.65662847200011, 21.30910135500006],
              [107.65663014500008, 21.308153544000064],
              [107.65658558300004, 21.307421061000028],
              [107.65612822800003, 21.30677411100006],
              [107.65562442200009, 21.306471752000085],
            ],
          ],
          [
            [
              [107.81009446400002, 21.346604987000092],
              [107.80831804300003, 21.34621072300007],
              [107.80674987500004, 21.34660209500003],
              [107.80528681400004, 21.346404448000023],
              [107.80267555400007, 21.344732997000015],
              [107.80079585000007, 21.34316036300001],
              [107.80006615700009, 21.341294187000059],
              [107.79965062300009, 21.338839178000057],
              [107.79745847800007, 21.336284346000056],
              [107.79401257400006, 21.33343377100001],
              [107.78863370100008, 21.330237627000059],
              [107.77891989200006, 21.325318765000034],
              [107.77578604100003, 21.324137359000069],
              [107.77296437500007, 21.324134486000013],
              [107.77139619900008, 21.324623810000084],
              [107.76961935900006, 21.324818353000104],
              [107.76753016400004, 21.324030663000123],
              [107.76011615800006, 21.319211512],
              [107.75238863599999, 21.314686297000037],
              [107.74925688900004, 21.312129836000082],
              [107.74774488600008, 21.309624251000066],
              [107.74649551, 21.306087951000038],
              [107.74493076900004, 21.304024133000077],
              [107.74378184100001, 21.303630043000062],
              [107.74242248700007, 21.304315803000051],
              [107.73980826200004, 21.30568743600012],
              [107.73792666500005, 21.306176170000057],
              [107.73573276400006, 21.305780802000029],
              [107.73124240700005, 21.303615185000076],
              [107.73009309, 21.3035155970001],
              [107.72925658200006, 21.303907340000094],
              [107.72925535200005, 21.304791065000117],
              [107.72998546500007, 21.305773871000078],
              [107.7313405240001, 21.308230295000016],
              [107.73154748700009, 21.309703405000043],
              [107.73102364100008, 21.310684677000054],
              [107.72924633200002, 21.311271659000042],
              [107.72767912100002, 21.311073359000062],
              [107.72465003700003, 21.310087708000047],
              [107.72015983500003, 21.307823693000039],
              [107.71650557400002, 21.305658840000063],
              [107.71044843900005, 21.303196209000049],
              [107.70606113100004, 21.302208511000039],
              [107.70397139000006, 21.302107537000033],
              [107.70271625700005, 21.302891394000056],
              [107.70240187600005, 21.303480127000093],
              [107.70250516500008, 21.304265793000084],
              [107.7039666090001, 21.305249667000048],
              [107.70678548400011, 21.306922673000095],
              [107.71231976600008, 21.309679271000093],
              [107.71701876700003, 21.312041903000086],
              [107.72401510400007, 21.315683756000055],
              [107.73059354200011, 21.319423054000097],
              [107.74197559300003, 21.326113424000056],
              [107.74932120800004, 21.329514189000065],
              [107.755240859, 21.331823341000074],
              [107.76068845900008, 21.333581311000046],
              [107.76349431400007, 21.334188793000038],
              [107.76474798000005, 21.334582870000048],
              [107.76652374499999, 21.335370241000092],
              [107.770700757, 21.338320186000104],
              [107.77952623300006, 21.343582054000088],
              [107.78474903000009, 21.346336362000073],
              [107.78934474900002, 21.349188112000036],
              [107.79435827400007, 21.352432880000016],
              [107.79989538500008, 21.354892533000047],
              [107.807104741, 21.357648035000039],
              [107.81170266200003, 21.358830177000065],
              [107.81379297400005, 21.359028315000025],
              [107.81745274000008, 21.357558619000102],
              [107.81802840200002, 21.356724544000102],
              [107.81876208700007, 21.354565125000043],
              [107.8185542320001, 21.353288568000025],
              [107.81636149000003, 21.350930363000131],
              [107.81270610600009, 21.347981786000055],
              [107.81009446400002, 21.346604987000092],
            ],
          ],
          [
            [
              [107.85556796200009, 21.359556315000106],
              [107.85503470200003, 21.359555967000077],
              [107.854168015, 21.359743240000029],
              [107.85310087800008, 21.360556490000079],
              [107.85256699800004, 21.361370098000073],
              [107.85256666600002, 21.361808378000099],
              [107.85296600300011, 21.362622604000059],
              [107.85416501000002, 21.363750401000026],
              [107.85496444500008, 21.364377044],
              [107.855764267, 21.364502790000103],
              [107.85703110600011, 21.364065333000056],
              [107.85756492900003, 21.363314334000094],
              [107.85769885000011, 21.362500469000075],
              [107.85749965300005, 21.361435932000106],
              [107.85676719600011, 21.360371058000013],
              [107.85596766800003, 21.359869641],
              [107.85556796200009, 21.359556315000106],
            ],
          ],
          [
            [
              [107.86958860400003, 21.363910774000061],
              [107.86844424700003, 21.363868838000052],
              [107.86743159700009, 21.364322020000067],
              [107.86650674100012, 21.365105264000036],
              [107.86593392000006, 21.366012469000047],
              [107.86584548500012, 21.366589951000044],
              [107.86619721500008, 21.367167699000063],
              [107.86676910600008, 21.367621818000067],
              [107.86760517700006, 21.367952341000063],
              [107.86945365200006, 21.368242200000061],
              [107.87095013900006, 21.368325586000125],
              [107.871478462, 21.368119632000045],
              [107.87174266300009, 21.367954774000083],
              [107.87209506500002, 21.367542454000073],
              [107.87218347200006, 21.36696497100008],
              [107.87209601500011, 21.366098622000074],
              [107.87165646000007, 21.365190808000101],
              [107.8706886690001, 21.36436519500009],
              [107.86958860400003, 21.363910774000061],
            ],
          ],
          [
            [
              [107.75143971100009, 21.362158068000078],
              [107.74872252300005, 21.361762280000015],
              [107.74516810700004, 21.362150945000025],
              [107.74265764600007, 21.363522583000091],
              [107.74139992100001, 21.366073836000062],
              [107.74097657500008, 21.37000057100007],
              [107.7445451250001, 21.37205496000005],
              [107.750468332, 21.373774122],
              [107.75531126200005, 21.373779522000099],
              [107.75790814700007, 21.371983308000075],
              [107.75937524000004, 21.369039506000043],
              [107.76000557300006, 21.366487486],
              [107.75938009900008, 21.365112276000083],
              [107.75708243800005, 21.363538864000105],
              [107.75143971100009, 21.362158068000078],
            ],
          ],
          [
            [
              [107.76441485600003, 21.384117247000042],
              [107.76316151400006, 21.38395538200006],
              [107.76156592400011, 21.38411420100006],
              [107.76036844700006, 21.384862076000033],
              [107.75968351100006, 21.385824545000084],
              [107.75939749000003, 21.386733928000098],
              [107.75939662900004, 21.387429584000103],
              [107.76002260600005, 21.388072394000091],
              [107.76099106400007, 21.388233978000045],
              [107.76264394500005, 21.387861173000111],
              [107.76452583400005, 21.386578907000064],
              [107.7653248930001, 21.385456007000059],
              [107.76504096800005, 21.384653034000053],
              [107.76441485600003, 21.384117247000042],
            ],
          ],
          [
            [
              [108.00188982700007, 21.398479999000038],
              [108.00188981900007, 21.397628939000057],
              [108.00098155600006, 21.396158942000021],
              [107.99899994200005, 21.393064182000018],
              [107.997059662, 21.391013868000108],
              [107.99499554900011, 21.389930657],
              [107.99416990300008, 21.389930637000099],
              [107.99317909500009, 21.390626938000068],
              [107.99194055600002, 21.392096914000014],
              [107.99045436500006, 21.392096855000098],
              [107.98797740400002, 21.391709881000054],
              [107.98417953700002, 21.389698036000127],
              [107.98095969000006, 21.387763540000023],
              [107.97452000700007, 21.385209680000017],
              [107.96948404800003, 21.382733183000099],
              [107.96457222900005, 21.37928944300009],
              [107.96151806500004, 21.376348828000054],
              [107.95747367700007, 21.371705771000023],
              [107.953677107, 21.367217412000095],
              [107.95219181000003, 21.364354293000076],
              [107.95062406400008, 21.361259015000122],
              [107.947859065, 21.359285345000067],
              [107.94455737900003, 21.357891807000051],
              [107.94084277200008, 21.357194486000083],
              [107.93729304, 21.357270876000101],
              [107.93481640400007, 21.35757966400007],
              [107.93011027800007, 21.359512575000039],
              [107.92812842100005, 21.361136790000046],
              [107.92573352500004, 21.363379833000096],
              [107.923504542, 21.36345644900004],
              [107.92061519100012, 21.363378058000038],
              [107.91578633900008, 21.362099611000041],
              [107.91108168300011, 21.360163437000068],
              [107.90868791900002, 21.359620847000116],
              [107.90043268800008, 21.359617291000085],
              [107.89556227200006, 21.359305549000048],
              [107.89093939300002, 21.359225947000127],
              [107.88165334200005, 21.357480265000014],
              [107.87620588100008, 21.356007181000081],
              [107.86316581200001, 21.351821276000038],
              [107.85862747100002, 21.349265052000099],
              [107.85549226100005, 21.347096518000086],
              [107.85078975600007, 21.343611495000033],
              [107.84633438300003, 21.340977660000071],
              [107.84394133800001, 21.340047464000023],
              [107.84286782900008, 21.340588340000096],
              [107.84220647400008, 21.341825895000085],
              [107.84129645900001, 21.344301277000085],
              [107.8405514570001, 21.346854149000102],
              [107.84088030900004, 21.348479271000095],
              [107.84203359400007, 21.351420347000079],
              [107.8435174100001, 21.35397476600005],
              [107.84500190200001, 21.355729700000033],
              [107.8462117, 21.356916948000034],
              [107.84714663600009, 21.357742927000075],
              [107.84797162100006, 21.358414066000094],
              [107.84863180100005, 21.358724010000074],
              [107.84929221400007, 21.358724460000111],
              [107.84989775600008, 21.358518543000038],
              [107.850833892, 21.357797017000024],
              [107.85121988200005, 21.356817202000045],
              [107.85094574100002, 21.355475862000084],
              [107.8494879830001, 21.354544607000037],
              [107.8468558590001, 21.352758688000058],
              [107.84487825100007, 21.350337147000076],
              [107.84473704300002, 21.349010220000061],
              [107.84506260300006, 21.348611262000091],
              [107.84544823400007, 21.348095692000065],
              [107.84634017800006, 21.348204895000045],
              [107.84723956500005, 21.348455059000045],
              [107.84817477800007, 21.348868374000048],
              [107.85037195700005, 21.350188203000094],
              [107.85526797800006, 21.352667450000062],
              [107.86038392800005, 21.355610962000071],
              [107.86285939300005, 21.35710841500007],
              [107.86368471900005, 21.357366839000044],
              [107.86456533800008, 21.357264215000079],
              [107.86654703100008, 21.356594836000028],
              [107.86808805000004, 21.356492594000095],
              [107.87017893100003, 21.357112824000076],
              [107.87284752400002, 21.357965483000015],
              [107.87438815100005, 21.35848219100005],
              [107.8747730690001, 21.358998229000022],
              [107.87499250400005, 21.360081587000046],
              [107.87499121100002, 21.362093307000052],
              [107.87510012400007, 21.36389874800004],
              [107.87559406300008, 21.366065473000084],
              [107.87674888300006, 21.367561989000095],
              [107.87845426300004, 21.368800896000018],
              [107.87971977300003, 21.369368983000037],
              [107.88004968400006, 21.369884981],
              [107.87971910300006, 21.370452197000041],
              [107.87812219200001, 21.371740869000085],
              [107.87459784599999, 21.374679033000099],
              [107.87349652500008, 21.375503705000121],
              [107.87256062400009, 21.37586423600002],
              [107.87173513000009, 21.375709023000056],
              [107.87046977400011, 21.374882990000096],
              [107.86788392000003, 21.373437189000107],
              [107.86524349000005, 21.371372342000058],
              [107.86348290200004, 21.370468578000064],
              [107.85910834300002, 21.369150505000079],
              [107.85712704600004, 21.369071863000052],
              [107.85572335700007, 21.369380449000097],
              [107.853988687, 21.370694639000121],
              [107.85126223700009, 21.373400851000035],
              [107.85035385700007, 21.373709733000013],
              [107.84936327700009, 21.373554315000028],
              [107.84465999400004, 21.370378811000101],
              [107.84119463500011, 21.367900428000027],
              [107.83855413500004, 21.366351065000089],
              [107.83632612600007, 21.365188834000023],
              [107.83343697400004, 21.364877193],
              [107.82988712800005, 21.364874496000041],
              [107.8275754690001, 21.365027458000085],
              [107.82699717500003, 21.365491245],
              [107.82699676200008, 21.365955482000039],
              [107.82765644500004, 21.366807094000073],
              [107.82971931200011, 21.36796928200004],
              [107.83578451700004, 21.371107444000081],
              [107.83933242200004, 21.37358594800007],
              [107.84312910700004, 21.374826621000054],
              [107.84700869400004, 21.375680418000087],
              [107.85088807800004, 21.37684363700005],
              [107.85336411, 21.377851116],
              [107.85674822100006, 21.379013900000032],
              [107.86071023300005, 21.380254366000081],
              [107.86818648400002, 21.381136913000098],
              [107.87270204500007, 21.382322600000059],
              [107.87476584400012, 21.382710627],
              [107.87891299900006, 21.383398565000086],
              [107.88163732000005, 21.383786886000067],
              [107.88502219700008, 21.384098155000061],
              [107.8878294690001, 21.38386747800007],
              [107.89278360300003, 21.383173592000034],
              [107.89934760500009, 21.38259639000006],
              [107.901246497, 21.382674616000052],
              [107.90603457200004, 21.38375988600005],
              [107.90917180400001, 21.384070681000097],
              [107.91528104100011, 21.385001543000023],
              [107.91734481200007, 21.385698658000059],
              [107.92254544200006, 21.387866900000049],
              [107.93158524000008, 21.390693865000095],
              [107.93472239100002, 21.391700613000083],
              [107.93918063100004, 21.392707682],
              [107.94760193900005, 21.39441195600007],
              [107.95585812100006, 21.396734824000063],
              [107.96456883100004, 21.397935595000028],
              [107.970100836, 21.398477990000053],
              [107.97381644200003, 21.398401090000085],
              [107.98058708900005, 21.398324417000048],
              [107.98496324400003, 21.398247387000069],
              [107.98942191100008, 21.398943964000061],
              [107.99396319900004, 21.399253604000101],
              [107.99792651400006, 21.399098946],
              [108.00089900300006, 21.399021584000089],
              [108.00155955200009, 21.398944211000078],
              [108.00188982700007, 21.398479999000038],
            ],
          ],
          [
            [
              [107.94975431800006, 21.453670312000028],
              [107.94949488800009, 21.45334544300006],
              [107.94854329700004, 21.453426422000099],
              [107.94741844300006, 21.454400604000057],
              [107.94681263100003, 21.45537490300002],
              [107.94672574700012, 21.456755348000023],
              [107.94689836000001, 21.45821704800008],
              [107.94776296700005, 21.460003714000031],
              [107.94880080000003, 21.461059596],
              [107.94983879000003, 21.46154705],
              [107.95122301300007, 21.461303750000035],
              [107.95390517200005, 21.459842673000118],
              [107.95451088600007, 21.45919317700011],
              [107.95503014600004, 21.458300043000023],
              [107.95477085200008, 21.457325552000036],
              [107.95399239000007, 21.456838172000033],
              [107.95295433600006, 21.456675550000078],
              [107.95234884300005, 21.456431811000073],
              [107.95182991800002, 21.455944478000077],
              [107.95079216900004, 21.454563792000059],
              [107.94975431800006, 21.453670312000028],
            ],
          ],
          [
            [
              [108.09523396600005, 21.473967564000063],
              [108.09459758900007, 21.473601182000031],
              [108.09293371900007, 21.473647713000062],
              [108.09185709700007, 21.473693998000073],
              [108.09068258200006, 21.473694481000024],
              [108.09019324800008, 21.473786344000075],
              [108.08985084300008, 21.474153137000052],
              [108.0888237340001, 21.475436846000036],
              [108.08755215200004, 21.477270622000063],
              [108.08681847800008, 21.478187548000079],
              [108.08681870400002, 21.478691697000052],
              [108.08686776800008, 21.478966666000062],
              [108.08765094400005, 21.479287182000029],
              [108.08960865300003, 21.479561387000068],
              [108.0908321580001, 21.479606716000085],
              [108.09171303300006, 21.479514691000112],
              [108.09352307299999, 21.478001497000051],
              [108.09464766800005, 21.475938589000073],
              [108.095234213, 21.474471717000121],
              [108.09523396600005, 21.473967564000063],
            ],
          ],
          [
            [
              [108.05677061300003, 21.500524284000022],
              [108.05573677300001, 21.500300682000081],
              [108.05359002900011, 21.501495169000059],
              [108.05255660200005, 21.502838614000019],
              [108.05247736800007, 21.50388334400013],
              [108.0527955140001, 21.504107141000084],
              [108.05414761300005, 21.504853032000071],
              [108.05534052900001, 21.505076599000091],
              [108.05629471500008, 21.50477787600007],
              [108.05740771000006, 21.503658261000069],
              [108.05764585800006, 21.502240373000078],
              [108.05764561400012, 21.50141953100011],
              [108.05677061300003, 21.500524284000022],
            ],
          ],
          [
            [
              [107.87001603000004, 21.50334462600005],
              [107.86896845600006, 21.502934312000029],
              [107.86774580600006, 21.503179405000061],
              [107.8659117400001, 21.503669941000055],
              [107.86433962900006, 21.504160623000068],
              [107.86364059600004, 21.504815709000091],
              [107.86337822800009, 21.505389123000015],
              [107.86337753600003, 21.506372393000014],
              [107.86416188900009, 21.508585236000052],
              [107.86477212200003, 21.510060505000084],
              [107.86547059200009, 21.510224807],
              [107.86660641900002, 21.509324168000084],
              [107.86931493400004, 21.507113431000043],
              [107.87045116200008, 21.50555724500007],
              [107.87062656300003, 21.504410196000023],
              [107.87001603000004, 21.50334462600005],
            ],
          ],
          [
            [
              [108.03782258600006, 21.504255497000102],
              [108.03695224100008, 21.50415377900012],
              [108.03651711600006, 21.504357580000111],
              [108.03602775500006, 21.505376298000023],
              [108.03575596700004, 21.50644591000006],
              [108.03521234700001, 21.508279535000071],
              [108.03504942900003, 21.509807510000108],
              [108.03510404800002, 21.511029856000086],
              [108.03521294700006, 21.511590079000072],
              [108.03537621700006, 21.512048438000072],
              [108.03570262800008, 21.512201181000101],
              [108.03640979300005, 21.512150134000052],
              [108.03711677300002, 21.511182315],
              [108.03782382600008, 21.510621955000083],
              [108.03945569700001, 21.510417943000078],
              [108.04146838700009, 21.510417576000052],
              [108.04271946400004, 21.510213611000047],
              [108.04348094100008, 21.509856949000024],
              [108.04380719400007, 21.50929663400003],
              [108.04369829300003, 21.50878734500008],
              [108.04233813100002, 21.507667114000029],
              [108.03994438300003, 21.506139602000118],
              [108.03891066300012, 21.505121150000051],
              [108.03782258600006, 21.504255497000102],
            ],
          ],
          [
            [
              [107.85848721100005, 21.506779023],
              [107.85805060500007, 21.506778744000066],
              [107.85691523700011, 21.50702383100009],
              [107.85534227199999, 21.508579660000038],
              [107.854468, 21.509972046000044],
              [107.85429262200009, 21.510955193000122],
              [107.85490297000005, 21.512184668000067],
              [107.85542643100011, 21.512840516000061],
              [107.85629955800006, 21.513004959000078],
              [107.85874554400002, 21.511777457000036],
              [107.860929712, 21.510303945000061],
              [107.86136702700003, 21.509320955000085],
              [107.8611927960001, 21.508747275000047],
              [107.86066938600004, 21.508009498000021],
              [107.8597093130001, 21.507353381000094],
              [107.85901108600005, 21.506861295000057],
              [107.85848721100005, 21.506779023],
            ],
          ],
          [
            [
              [108.07066587800006, 21.511562289],
              [108.06778263200006, 21.51100294900008],
              [108.06681759200006, 21.511073494000051],
              [108.06562094, 21.511328510000013],
              [108.064315623, 21.511990995000119],
              [108.06279282500003, 21.513010055000059],
              [108.06175956400007, 21.513927104000082],
              [108.06116143200005, 21.514691241000072],
              [108.06083527400007, 21.515455293000052],
              [108.06094428100002, 21.516117361000028],
              [108.06154281500008, 21.516575577000104],
              [108.06241320300006, 21.516626265000035],
              [108.0650242290001, 21.516268997000054],
              [108.06763505800002, 21.515402393000024],
              [108.07024558700002, 21.513771793000025],
              [108.07100676600002, 21.512701997000057],
              [108.07100659900001, 21.512243613000059],
              [108.07066587800006, 21.511562289],
            ],
          ],
          [
            [
              [107.48223016700004, 21.600482536000065],
              [107.48072555800007, 21.59944829600002],
              [107.48376711300001, 21.599975111000091],
              [107.485484431, 21.599979098000063],
              [107.48746628000002, 21.599860073000073],
              [107.48909714900005, 21.599245792000055],
              [107.49081770300003, 21.598013653000066],
              [107.49236103000001, 21.597193121000124],
              [107.49456461500007, 21.596456495000012],
              [107.49685528600007, 21.596090867000051],
              [107.49844068800006, 21.596012051000102],
              [107.5023152640001, 21.596144383000038],
              [107.50530929700003, 21.596233479000126],
              [107.50729087700003, 21.596196689000102],
              [107.51079237700002, 21.595854209],
              [107.51378794800007, 21.595325141000018],
              [107.51572642000005, 21.594917348000116],
              [107.51731322900008, 21.594261552000127],
              [107.51881340500003, 21.59302870500008],
              [107.51987221600007, 21.592206934000032],
              [107.52088647800005, 21.59159106700006],
              [107.52207604600007, 21.591305210000122],
              [107.52370553000007, 21.591185101000043],
              [107.52502647600002, 21.591187931],
              [107.52777841500009, 21.59121440200007],
              [107.52940688700009, 21.591506291000059],
              [107.53011030000002, 21.591961021000117],
              [107.53090039400003, 21.592992773000041],
              [107.53182208300008, 21.594230822000071],
              [107.5326134850001, 21.594726934000086],
              [107.533757538, 21.595058966000025],
              [107.53476989600009, 21.59522589900007],
              [107.53547481400007, 21.595062567000056],
              [107.53609274100003, 21.594445804000095],
              [107.53675667100008, 21.593005072000082],
              [107.53742864100008, 21.591895107000077],
              [107.53829494500008, 21.591030419000035],
              [107.53906782300008, 21.590512125000046],
              [107.54014913900002, 21.59008112100009],
              [107.54098245700004, 21.590347069000067],
              [107.54175290200001, 21.590912890000077],
              [107.54302739500005, 21.592517922000098],
              [107.54461397200005, 21.594755514000049],
              [107.54531076700006, 21.595885383],
              [107.54664174700005, 21.599149307000062],
              [107.54760162300006, 21.60125015800007],
              [107.54875516100003, 21.603057996000082],
              [107.54969248700004, 21.604504283000026],
              [107.55284190400005, 21.6082762180001],
              [107.55452815300004, 21.610284822000025],
              [107.55619709200001, 21.612224665000035],
              [107.55728727000006, 21.613355012000035],
              [107.5588303530001, 21.614214623000102],
              [107.56038941900005, 21.614136506000037],
              [107.56333545100009, 21.613492681000054],
              [107.56610770200004, 21.613092082000037],
              [107.5672333800001, 21.613175490000103],
              [107.56861687900009, 21.61415264500004],
              [107.56965307900003, 21.61553515000012],
              [107.57086232800008, 21.616999191000019],
              [107.57247875100006, 21.618319612000022],
              [107.57454617100005, 21.61872925400008],
              [107.57699023900004, 21.618754522000039],
              [107.57848788900006, 21.61859256900005],
              [107.57945726400006, 21.618347196000087],
              [107.58047174100005, 21.617607491000079],
              [107.58285465500005, 21.615345887],
              [107.5857246410001, 21.611807901000098],
              [107.58819662800009, 21.609010754000032],
              [107.58947662400004, 21.607612252000052],
              [107.59008719200001, 21.60678083],
              [107.59228155800012, 21.606757539000029],
              [107.59372212200003, 21.606742237],
              [107.59938051300006, 21.607106293000108],
              [107.60271202900002, 21.607642946000013],
              [107.61163759900009, 21.609309671000076],
              [107.61993683000003, 21.609795728000059],
              [107.6292444420001, 21.609103927000014],
              [107.63968249900007, 21.609121115000015],
              [107.64754259800002, 21.609074780000093],
              [107.65131454200008, 21.60955243100009],
              [107.65671972500012, 21.610975933000098],
              [107.66061440300004, 21.613222454000081],
              [107.66337934000002, 21.614287965000045],
              [107.66551690300005, 21.61452704000002],
              [107.667655692, 21.614058563000071],
              [107.67143257300003, 21.611705733000086],
              [107.67472614100005, 21.609589674000063],
              [107.67602240300002, 21.61055682500006],
              [107.67856091400009, 21.612062061000081],
              [107.68190220900006, 21.61338072300012],
              [107.6867139660001, 21.615139366000058],
              [107.69119146500003, 21.616897433000034],
              [107.69506829500007, 21.61796637800002],
              [107.70268918500003, 21.619540809000078],
              [107.70863802000009, 21.621363036000041],
              [107.71198041300006, 21.622180725000106],
              [107.71385201400003, 21.622746213000084],
              [107.71438616200007, 21.623309968000015],
              [107.7151196500001, 21.624749870000045],
              [107.71638759600003, 21.626565816000017],
              [107.71782297500005, 21.628225553000036],
              [107.71942588000006, 21.629666522000065],
              [107.72082953800003, 21.630168792000056],
              [107.72337016900011, 21.630609914000054],
              [107.72651277900006, 21.630989180000078],
              [107.72898620400008, 21.631680399000075],
              [107.73199336400002, 21.633310662000049],
              [107.7345985370001, 21.635503455000027],
              [107.73751516500002, 21.63785591400007],
              [107.73793875800007, 21.63819757],
              [107.74041097700008, 21.639889635000117],
              [107.74261648100004, 21.641018301000067],
              [107.74732912100006, 21.642806702000073],
              [107.74953493200005, 21.643747612000119],
              [107.75093925100006, 21.643874315000033],
              [107.75227724700002, 21.643563006000122],
              [107.75448475200005, 21.643190094000083],
              [107.75749433700003, 21.64319340100009],
              [107.76110514600001, 21.643760361000105],
              [107.76591905300005, 21.644954145000028],
              [107.76966271400009, 21.6463343940001],
              [107.777183382, 21.649313686000049],
              [107.77778474500012, 21.649814771000116],
              [107.77825213300007, 21.650503391000072],
              [107.77838511100005, 21.651191685000093],
              [107.77831729500004, 21.652004899000055],
              [107.7781817430001, 21.653568759000038],
              [107.77878240900006, 21.654695428000089],
              [107.78058656900004, 21.656198649000125],
              [107.78252543800005, 21.656888701000106],
              [107.78433103800006, 21.657140704000085],
              [107.78627068700006, 21.657142580000063],
              [107.78874569000001, 21.65689470500007],
              [107.79162271800004, 21.655959037000017],
              [107.79369705300007, 21.655085141000093],
              [107.79490115900005, 21.654898578000115],
              [107.7971077400001, 21.655463639000061],
              [107.80275761800003, 21.657251669000033],
              [107.80489709800004, 21.658066832000067],
              [107.80747243000005, 21.658865680000083],
              [107.80884014300005, 21.658653681000125],
              [107.81100561400009, 21.658389072000084],
              [107.81341811300004, 21.658050528000047],
              [107.81597769000007, 21.657527930000064],
              [107.82100766700006, 21.65597248600006],
              [107.82419956800001, 21.654749271000107],
              [107.82568166500008, 21.654004319000109],
              [107.82676528700003, 21.652832689000064],
              [107.82859059100007, 21.650542432000108],
              [107.82921859800007, 21.649103963000101],
              [107.83047440900006, 21.646440175000059],
              [107.83132986800004, 21.645481518000054],
              [107.83229913800004, 21.644682817000046],
              [107.8348923020001, 21.643752100000043],
              [107.83888146100003, 21.642635821000056],
              [107.84053385700007, 21.642477128000067],
              [107.84161615000008, 21.642744382000068],
              [107.84252726700007, 21.643331281000044],
              [107.84298224500006, 21.644344217000096],
              [107.84320876300005, 21.646049820000087],
              [107.84315032400011, 21.647861809000091],
              [107.84309222900001, 21.64924743300007],
              [107.84337654600009, 21.649940469000036],
              [107.84423068400005, 21.650580604000069],
              [107.84656559500007, 21.65202118800007],
              [107.85026771700005, 21.6538357250001],
              [107.85305860900004, 21.655169948],
              [107.854425646, 21.65575708500009],
              [107.85545125400003, 21.65575775500006],
              [107.85676199300003, 21.655438840000052],
              [107.85804464900012, 21.654560312000015],
              [107.86140771700011, 21.65264383700007],
              [107.86391557900005, 21.651419616000034],
              [107.86485409000008, 21.651002293000055],
              [107.86806885500005, 21.649448777000124],
              [107.87128032600003, 21.64690487600005],
              [107.87418967000005, 21.643401044000043],
              [107.87556678800004, 21.640997433000031],
              [107.87630979900005, 21.638136348],
              [107.87637518300005, 21.635518238000095],
              [107.87620576500007, 21.633173104000036],
              [107.87620630900003, 21.63232035900009],
              [107.87689050300006, 21.631468001000087],
              [107.87779431300005, 21.631208388000097],
              [107.878636332, 21.630524722000111],
              [107.88209656200004, 21.629646653000023],
              [107.88423540900008, 21.628561012000077],
              [107.88563797700004, 21.627395484000097],
              [107.88655030700004, 21.626010231000059],
              [107.88751999400007, 21.623932133000068],
              [107.88843318300005, 21.621001230000033],
              [107.8887762680001, 21.618762898000021],
              [107.88883396700007, 21.617483783000054],
              [107.88866371100002, 21.616364435000079],
              [107.88815141000001, 21.615671299000013],
              [107.88684167400008, 21.614817871000099],
              [107.88487674300004, 21.614150633000051],
              [107.88288322100003, 21.613669907000066],
              [107.88191499500003, 21.613349605000032],
              [107.88128869000008, 21.612816284000047],
              [107.88089048400008, 21.611909997000019],
              [107.88134751100006, 21.609778301000034],
              [107.88248778100008, 21.608179941000039],
              [107.88374131000006, 21.607647617000048],
              [107.88550723400007, 21.607595230000065],
              [107.88795669200005, 21.607596480000076],
              [107.88949472200004, 21.607597251000051],
              [107.89009307900007, 21.607197817000042],
              [107.89072025100009, 21.606185443000037],
              [107.89123405900006, 21.604160324000077],
              [107.89203258400005, 21.602295241000036],
              [107.89317300500008, 21.600163813000073],
              [107.89499726100004, 21.59744638800003],
              [107.89636543100008, 21.595368339000039],
              [107.89686426700005, 21.593812232000076],
              [107.89665999200002, 21.592576982000097],
              [107.89661838200006, 21.59125508000006],
              [107.89676687700009, 21.59025168400008],
              [107.89676716000011, 21.589718683000036],
              [107.89728021300002, 21.588919404000073],
              [107.89813495900003, 21.588226894000059],
              [107.899502129, 21.587907713000028],
              [107.90178052100005, 21.587802129000089],
              [107.90622327100002, 21.587804050000067],
              [107.90813140400007, 21.587724897],
              [107.91001107900007, 21.587619072000038],
              [107.91217564400003, 21.587300126000031],
              [107.91382754600002, 21.587034276000061],
              [107.91479581100002, 21.587087947000057],
              [107.91593473900011, 21.587621395000056],
              [107.91804152700007, 21.589221200000082],
              [107.91946518800009, 21.589967931000054],
              [107.92077508500003, 21.590341511000055],
              [107.92174339000003, 21.59034185400008],
              [107.92242696800002, 21.590182193000103],
              [107.92276882200005, 21.589915807000089],
              [107.92322483100004, 21.589063152000065],
              [107.92373846400008, 21.586504889000047],
              [107.9242518340001, 21.584586225000102],
              [107.92487890800004, 21.583200608000119],
              [107.9259047840001, 21.581495312000065],
              [107.92767128300005, 21.579203921],
              [107.9286968590001, 21.578191527000087],
              [107.93029198400009, 21.577179297],
              [107.93211487000011, 21.576273729000036],
              [107.93513390900006, 21.57499537900005],
              [107.93692831900003, 21.57395650900007],
              [107.93903612600008, 21.572358027000057],
              [107.94023252900004, 21.571132398000081],
              [107.940688353, 21.570492893000015],
              [107.94080252600003, 21.56964007900006],
              [107.94040421400003, 21.568467312000017],
              [107.94029075300006, 21.567028112000038],
              [107.94063301400004, 21.565215904000112],
              [107.94143101600008, 21.562977383000081],
              [107.94239991200004, 21.560525675000086],
              [107.94459403300004, 21.555169208000088],
              [107.946075885, 21.55090523800007],
              [107.94721561500006, 21.548027067000049],
              [107.94778558100006, 21.546161528000063],
              [107.949067719, 21.542936871000059],
              [107.95026416400006, 21.54059171300009],
              [107.95146037600009, 21.539099424000071],
              [107.95282725900003, 21.538140212000108],
              [107.9537953920001, 21.537767280000075],
              [107.954877342, 21.537714200000032],
              [107.95641479500003, 21.537821112000088],
              [107.95874937500002, 21.538354620000085],
              [107.96114105000002, 21.538141823000068],
              [107.96233692600002, 21.537875508000056],
              [107.96341897500011, 21.537236014000079],
              [107.96415944200001, 21.536170019000075],
              [107.96661679100002, 21.537146092000015],
              [107.96883461000003, 21.538059975000046],
              [107.97105243300004, 21.539015352000042],
              [107.97331457700007, 21.540385966000116],
              [107.97460090600001, 21.541258145000072],
              [107.97544362500001, 21.542213311000054],
              [107.97570969400009, 21.542960787000084],
              [107.97619748000004, 21.544372687000113],
              [107.97650792800005, 21.544995594000049],
              [107.97721766200007, 21.545410911000126],
              [107.977661246, 21.54561858699999],
              [107.97952436200006, 21.546034009000081],
              [107.98258524400009, 21.546532565000028],
              [107.98666644600009, 21.546948099000041],
              [107.99039278900003, 21.547363533000038],
              [107.99946471000004, 21.548173469000083],
              [108.00303582600003, 21.54865098800007],
              [108.00645169100004, 21.548900061000076],
              [108.00915776600003, 21.548941491000036],
              [108.01381576400006, 21.549148870000053],
              [108.017764, 21.549439261000103],
              [108.02157913300007, 21.549480449000043],
              [108.02410776000004, 21.549480188000111],
              [108.02623710500009, 21.549230792000081],
              [108.028477342, 21.548960607000112],
              [108.03105025800008, 21.548503495000126],
              [108.0327359030001, 21.547921909000053],
              [108.03440796900007, 21.546929621000018],
              [108.03554746400002, 21.546506245000074],
              [108.03529590500008, 21.546329705000034],
              [108.03458083200007, 21.545680966000091],
              [108.03402471900006, 21.545606690000071],
              [108.03327747800006, 21.545638322000023],
              [108.03116492900006, 21.546330324000081],
              [108.02703406800003, 21.547223205000066],
              [108.0233797650001, 21.547521070000109],
              [108.02075816500009, 21.547521330000038],
              [108.01694485700006, 21.54662931500004],
              [108.01090719700009, 21.54543989400004],
              [108.00733231000007, 21.544919502000056],
              [108.00518738500006, 21.544547759000032],
              [108.00336022500005, 21.543581078000074],
              [108.00216860200004, 21.542837473000098],
              [108.00129475100003, 21.542093850000029],
              [108.00097698300003, 21.540904050000087],
              [108.00050034600011, 21.538896251000047],
              [108.00057977600002, 21.537334616000066],
              [108.00137415100002, 21.535698618000062],
              [108.00240681800003, 21.534583158000054],
              [108.00423386500003, 21.533988218000033],
              [108.00629920000003, 21.533467620000032],
              [108.00757018400003, 21.533467580000028],
              [108.00963557000004, 21.534062413000044],
              [108.01050938000004, 21.534136738000065],
              [108.01146259800004, 21.533764870000113],
              [108.01313068000003, 21.532500594000048],
              [108.01487820500003, 21.531533747000076],
              [108.01646685900002, 21.530789998000088],
              [108.01757895400003, 21.53078991299999],
              [108.01829390900012, 21.531161669000106],
              [108.01924723100004, 21.532053962000042],
              [108.01972384100003, 21.532053920000038],
              [108.02035931000003, 21.531830769000045],
              [108.02099468900012, 21.530789616000057],
              [108.0215505310001, 21.528930454000061],
              [108.02186803600009, 21.526922579000107],
              [108.02182822500006, 21.526067379000047],
              [108.02143099400007, 21.52547249600012],
              [108.02047778900007, 21.525398220000014],
              [108.01936571500008, 21.525398320000107],
              [108.01618844000008, 21.525993497000059],
              [108.01293169700008, 21.526588623000094],
              [108.00935727000011, 21.52852228400004],
              [108.00737142600011, 21.529042912000037],
              [108.00554442800002, 21.529042965000045],
              [108.00387629400002, 21.528745542000038],
              [108.00308193600003, 21.528373730000091],
              [108.00292306000003, 21.527927551000033],
              [108.00371736300011, 21.52554786500005],
              [108.0053853860001, 21.52287068300005],
              [108.00641794900008, 21.52108588600008],
              [108.00641790400009, 21.519747308000078],
              [108.00586184100008, 21.51818564300001],
              [108.004590901, 21.515805956000023],
              [108.00304199, 21.513612168000051],
              [108.00224768000001, 21.511901746000014],
              [108.002088805, 21.510191310000067],
              [108.0021682120001, 21.5078859260001],
              [108.00280359599999, 21.505877997000056],
              [108.00399493000006, 21.503944419000028],
              [108.00733061800003, 21.500077192000063],
              [108.00963377000006, 21.497771678000085],
              [108.01018957500006, 21.494945633000029],
              [108.01054680100003, 21.491859291000104],
              [108.01102313200001, 21.488512634000045],
              [108.01142016000009, 21.48747143800005],
              [108.015470287, 21.485165715000051],
              [108.018567363, 21.48330621500012],
              [108.01975859300006, 21.483157370000058],
              [108.02094986700004, 21.483529116000085],
              [108.02539747600008, 21.485834136000022],
              [108.03103646400008, 21.488138885000026],
              [108.03762868400001, 21.490815181000045],
              [108.04501540400003, 21.49401168500011],
              [108.05009885600001, 21.496092929],
              [108.05264035600005, 21.496166710000075],
              [108.05542007700009, 21.496091664000083],
              [108.06161482500008, 21.495718192000091],
              [108.06550616300009, 21.494824645000051],
              [108.06677669000005, 21.49422931],
              [108.067411517, 21.492667366000084],
              [108.06764923000004, 21.491105536000084],
              [108.06693345700006, 21.488205328000021],
              [108.06621818800002, 21.486718144000072],
              [108.06542386700004, 21.486272156000027],
              [108.06399435500006, 21.486272573000065],
              [108.06097652000003, 21.486347794000068],
              [108.05660860500002, 21.48649769300004],
              [108.0523200850001, 21.486573116000059],
              [108.04668131000003, 21.485979410000056],
              [108.03981149700004, 21.484902379000026],
              [108.03449036200004, 21.483713346000052],
              [108.02972521100004, 21.482524100000063],
              [108.02567480600001, 21.481037192000052],
              [108.02122734500009, 21.479104015000061],
              [108.01495338700003, 21.47620403900001],
              [108.00875901700007, 21.473155131000077],
              [108.004430998, 21.470068814000065],
              [107.99911049900005, 21.465903986000079],
              [107.99339323600005, 21.460474640000093],
              [107.98676320100002, 21.454189726000017],
              [107.98446069800011, 21.451437718000044],
              [107.9808086730001, 21.446156785000078],
              [107.97668062100004, 21.439536929],
              [107.97485475300005, 21.437305430000016],
              [107.972155501, 21.435520063000091],
              [107.97017069100009, 21.434850409000106],
              [107.9680271010001, 21.434180722000029],
              [107.96667737700007, 21.434106145000072],
              [107.96564518800001, 21.434329111000046],
              [107.96449379300005, 21.435184266000107],
              [107.96417606800006, 21.435927979],
              [107.96417593100004, 21.43667175200008],
              [107.96449327600001, 21.4380105810001],
              [107.96623947000006, 21.441209038000061],
              [107.9683031050001, 21.445746278000044],
              [107.97195467700008, 21.451696789000074],
              [107.97453477400003, 21.455601761000054],
              [107.97651943400002, 21.45939505700003],
              [107.97786920100006, 21.460659555000028],
              [107.98112465600003, 21.462965427000121],
              [107.98723881400011, 21.466610153000062],
              [107.9874770000001, 21.467353889000051],
              [107.9874769140001, 21.468692603000058],
              [107.98636499200011, 21.471146820000087],
              [107.98636488800001, 21.472634270000057],
              [107.98719863800007, 21.473787077000047],
              [107.98966026400009, 21.47668769700006],
              [107.98981899100008, 21.478621360000091],
              [107.98934228600007, 21.482562999],
              [107.98878623500006, 21.485017202000023],
              [107.98791256600008, 21.486355819000025],
              [107.98505339300003, 21.488289267000127],
              [107.98306784400006, 21.489404677000046],
              [107.98100292700009, 21.489701981000039],
              [107.97973222100002, 21.489776239000058],
              [107.97822331900005, 21.489329884000071],
              [107.97544385400003, 21.487842189000084],
              [107.97393494700006, 21.487618915000084],
              [107.97290244000007, 21.48813937400007],
              [107.97067839000009, 21.490444557000018],
              [107.968255748, 21.492638121000063],
              [107.96436393800005, 21.493901805000078],
              [107.96293432800007, 21.494124679000045],
              [107.96261668700008, 21.493901517000054],
              [107.9618226040001, 21.493306434000111],
              [107.96102876900005, 21.491447061000102],
              [107.9607116760001, 21.488546582000097],
              [107.96071232300008, 21.485348670000093],
              [107.96071293900003, 21.48229947300004],
              [107.9598399500001, 21.479473225000056],
              [107.95864911800007, 21.477688097000055],
              [107.95666410600006, 21.476200282000057],
              [107.95261437500008, 21.474786395000045],
              [107.94967637200008, 21.473744546000084],
              [107.94761192200008, 21.472777228000069],
              [107.94419783500004, 21.47061960200007],
              [107.93959311000006, 21.467048514000098],
              [107.93554394100005, 21.464890558000072],
              [107.93363826800004, 21.464518129000076],
              [107.9310176290001, 21.464963570000073],
              [107.92839659400003, 21.466450196000061],
              [107.92728439500011, 21.467714178000016],
              [107.92704563800007, 21.469127176000043],
              [107.92704527500004, 21.470094023000016],
              [107.92760056700007, 21.47165602100003],
              [107.92910818400003, 21.47492887100011],
              [107.92990210100007, 21.475524100000065],
              [107.93037841300006, 21.475970482000051],
              [107.93133135000002, 21.476045151],
              [107.93395217900007, 21.475450971000051],
              [107.93764370000007, 21.479133419],
              [107.940739846, 21.482480968000047],
              [107.94235273300005, 21.484633552000076],
              [107.94256543900006, 21.485753747000032],
              [107.94184999500001, 21.488059037000085],
              [107.94073773700009, 21.489397398000065],
              [107.94026101600008, 21.490066601000102],
              [107.94049908700006, 21.490661625000129],
              [107.94224613300003, 21.491257037000096],
              [107.94431097600007, 21.491480675000041],
              [107.94661418200003, 21.491332501000038],
              [107.95050595099998, 21.490440973000076],
              [107.95177664500005, 21.490515623000036],
              [107.9554290980001, 21.494086118000055],
              [107.95574662899999, 21.494755497000106],
              [107.95574652800008, 21.495201714000075],
              [107.95471386300008, 21.496019568000065],
              [107.95221169899999, 21.497617966000071],
              [107.94816056100008, 21.499922483000056],
              [107.94402990800009, 21.502226896000117],
              [107.93918440400009, 21.504307923000084],
              [107.93608642900008, 21.505571300000078],
              [107.93370364100011, 21.505644969000045],
              [107.93179895700008, 21.501331060000069],
              [107.9313225440001, 21.500959070000114],
              [107.92751017300006, 21.501106594000063],
              [107.92298259300007, 21.502220587000039],
              [107.92202936100007, 21.502592096000051],
              [107.92194964200004, 21.503335742000026],
              [107.92163056300002, 21.506756550000091],
              [107.92123132600003, 21.511962104000034],
              [107.92075408900007, 21.513598],
              [107.91988012900006, 21.514192620000117],
              [107.91777487200004, 21.51512143000005],
              [107.9172979460001, 21.515939277000129],
              [107.91729728100005, 21.51750096300011],
              [107.91697782100007, 21.521590961000037],
              [107.91737390200008, 21.524119532000086],
              [107.91737352200005, 21.525011917000114],
              [107.91689650600001, 21.525978478000063],
              [107.91582384700008, 21.526684539000129],
              [107.91463233700009, 21.526684083000092],
              [107.91320276100001, 21.526162973000048],
              [107.90970834400008, 21.524748646000091],
              [107.90704674100003, 21.523614045],
              [107.90129003100006, 21.521621723000095],
              [107.89986018300004, 21.521769805000091],
              [107.89890653000002, 21.522661762000084],
              [107.89779342600011, 21.524669112000083],
              [107.89723558600005, 21.528089634000125],
              [107.89691713600008, 21.529428054000022],
              [107.89596368100007, 21.529873788000131],
              [107.89358053900006, 21.530021387000083],
              [107.89196182100005, 21.529992542000095],
              [107.88929108200006, 21.529944924000063],
              [107.88754373100008, 21.529572231000046],
              [107.88643237300005, 21.528307462000029],
              [107.88583760100005, 21.526633948000025],
              [107.88583843100011, 21.525221018000096],
              [107.88623712000006, 21.522618439000048],
              [107.88727129300001, 21.519941814],
              [107.88758996700008, 21.518305930000011],
              [107.88687561300004, 21.517413171000044],
              [107.88349866900006, 21.515175428000077],
              [107.88695884700009, 21.510868959],
              [107.88783336400006, 21.509456432000015],
              [107.8876748540001, 21.50886141200008],
              [107.88616640800007, 21.507745138000089],
              [107.88270493000002, 21.507496498000123],
              [107.87933590600007, 21.507518425000072],
              [107.87905747100002, 21.508224762000069],
              [107.87905603600009, 21.51053014300004],
              [107.87889620700001, 21.512091759000015],
              [107.87849865100006, 21.512760845000045],
              [107.87595681100007, 21.512982539000092],
              [107.8698406820001, 21.513202136000054],
              [107.86690178800004, 21.513274759000112],
              [107.86610735500003, 21.513497373000057],
              [107.86547130500007, 21.514389390000055],
              [107.86475468600003, 21.516917411000058],
              [107.86300355700008, 21.522121937000094],
              [107.86220602400009, 21.526657717000127],
              [107.86141019500005, 21.528739433000062],
              [107.86045660800002, 21.52925938],
              [107.85855016800011, 21.529258173000088],
              [107.85600869600006, 21.528661628000023],
              [107.85394428600004, 21.527470439000048],
              [107.85207857900005, 21.526167818000097],
              [107.84953958600002, 21.522447847000059],
              [107.84731818100008, 21.519025514000042],
              [107.84525653400001, 21.514562124000044],
              [107.84351281100007, 21.509950159000098],
              [107.84335587500006, 21.507570295],
              [107.84335695500006, 21.506231684000099],
              [107.84288107000003, 21.505413300000065],
              [107.83978501800001, 21.503551892000061],
              [107.83827633100006, 21.503104587000088],
              [107.83748183500005, 21.503401480000051],
              [107.83684494700002, 21.50518583900007],
              [107.83549263900004, 21.507638971000098],
              [107.83358495600002, 21.509347994000017],
              [107.83195576700005, 21.510425080000047],
              [107.830921464, 21.51243220000012],
              [107.83076046900001, 21.514886176000033],
              [107.83123497900002, 21.517266259000053],
              [107.8323448780001, 21.519721178000047],
              [107.83290051000006, 21.520167793000056],
              [107.83377419000006, 21.520242806000041],
              [107.83496591300008, 21.519946241000106],
              [107.83711111300002, 21.519278531000019],
              [107.837825804, 21.519502153000047],
              [107.83854000200003, 21.520320692],
              [107.83901511300007, 21.522105818000092],
              [107.839014127, 21.52329566000013],
              [107.83789983100009, 21.525971995000056],
              [107.83710500200004, 21.526566325000118],
              [107.83405798000008, 21.527092242000023],
              [107.83378972600009, 21.527138541000035],
              [107.83277551200011, 21.526972108000031],
              [107.831584838, 21.526004469000078],
              [107.83031552700005, 21.524144377000098],
              [107.82753862100006, 21.520498332000045],
              [107.82587242400004, 21.518414788000051],
              [107.82341129600007, 21.517074249000068],
              [107.82118788000005, 21.516403178000054],
              [107.81824967500003, 21.515657125000054],
              [107.81499378500011, 21.514910776000114],
              [107.81078448900008, 21.514461025000031],
              [107.80335838100009, 21.513971178000091],
              [107.80097560300003, 21.513894686000064],
              [107.799705284, 21.513372974000063],
              [107.79859490800004, 21.511810270000083],
              [107.79803899200006, 21.511735397000017],
              [107.79756226800005, 21.511883697000123],
              [107.79525690300008, 21.513740748000117],
              [107.79144234300003, 21.515596356000088],
              [107.78723014400003, 21.51782334200005],
              [107.78214436600005, 21.519826285000043],
              [107.77975999700004, 21.521088144000089],
              [107.77745350100007, 21.523688635000049],
              [107.77602165700007, 21.525471955000071],
              [107.77506837400007, 21.525545356000109],
              [107.77467138200009, 21.525396223000115],
              [107.77419555800006, 21.524726460000032],
              [107.77316509800006, 21.522866270000037],
              [107.77197528000004, 21.521452109000094],
              [107.76995174800003, 21.51977679000003],
              [107.7665391670001, 21.51731914700008],
              [107.76173386000006, 21.514973180000041],
              [107.76058549900009, 21.514412522000015],
              [107.75574379600005, 21.511655673000085],
              [107.7510609990001, 21.508973251],
              [107.74622074200001, 21.505323762000025],
              [107.74495170000009, 21.50398368900008],
              [107.74527027800006, 21.503314740000064],
              [107.74598529000006, 21.503166824000083],
              [107.74940052800011, 21.503170708000049],
              [107.75348664900008, 21.502491640000088],
              [107.757538106, 21.501826789000056],
              [107.75933029300006, 21.501768712000043],
              [107.76206954800004, 21.502478163000127],
              [107.76572134800003, 21.503895039000028],
              [107.76786420900005, 21.505235910000039],
              [107.77000674700005, 21.506874227000061],
              [107.77103910800002, 21.507024031000107],
              [107.77286642000004, 21.506579711000114],
              [107.77636387900006, 21.504203496000059],
              [107.77906634300011, 21.502421374000043],
              [107.78343648500002, 21.500789584000032],
              [107.78764722000008, 21.499603755000059],
              [107.79122220100002, 21.498714732000046],
              [107.79360510600002, 21.498493855000063],
              [107.79432030000004, 21.498122674000086],
              [107.79495669100002, 21.497156465000074],
              [107.79463979800009, 21.496412489000072],
              [107.79376742500003, 21.495221771000047],
              [107.7936888670001, 21.494403643000034],
              [107.79440562000008, 21.492545077],
              [107.79528127600005, 21.490612278000043],
              [107.79715054100009, 21.487825118],
              [107.79945627100008, 21.485298634000038],
              [107.800887147, 21.48396124800005],
              [107.80104674200005, 21.483217691000078],
              [107.80096838700005, 21.482176425000077],
              [107.80049365700003, 21.480465469000087],
              [107.79906664900008, 21.478084301000067],
              [107.79740034600006, 21.476744102000019],
              [107.79462237700007, 21.475328488000045],
              [107.79335185300002, 21.475252936000096],
              [107.79247727900004, 21.476218947000092],
              [107.78600866200004, 21.472977624000045],
              [107.78243704500002, 21.471263591000067],
              [107.78331206100003, 21.469925748000037],
              [107.78371101600001, 21.468215560000111],
              [107.78323672000002, 21.466281404000043],
              [107.78173186800004, 21.462784392000067],
              [107.78022565000002, 21.460551696000074],
              [107.77895582000011, 21.459955450000031],
              [107.77712972800001, 21.45973050000007],
              [107.77522354100003, 21.460100447000038],
              [107.77363480200003, 21.460619441000084],
              [107.77268183700005, 21.460692840000029],
              [107.77196761200003, 21.460320236000108],
              [107.77093690200009, 21.458980448000098],
              [107.77006536900009, 21.457343319000088],
              [107.76955174600006, 21.455223114000077],
              [107.769316008, 21.453140381000054],
              [107.76923960100008, 21.450611562000077],
              [107.76868741300004, 21.447561597000053],
              [107.76821420400006, 21.444883586000074],
              [107.767580331, 21.443767280000081],
              [107.76567634500003, 21.442426510000075],
              [107.76369262900005, 21.44138313300008],
              [107.76274066200006, 21.440712736000123],
              [107.76155050000008, 21.44004206900005],
              [107.75972452400008, 21.439891344000017],
              [107.75945173900007, 21.439773421000027],
              [107.75914187200001, 21.43989071200005],
              [107.75638979100003, 21.439887705000068],
              [107.75321291299998, 21.440627960000121],
              [107.75082933700003, 21.441889688000067],
              [107.74892241, 21.442928807000072],
              [107.74773142300003, 21.442927456000049],
              [107.74614420900008, 21.442330633000047],
              [107.74606567900005, 21.44166115400008],
              [107.74646491300005, 21.439950956000061],
              [107.74765887500007, 21.437646641000093],
              [107.74932996700005, 21.434747840000057],
              [107.75076167400003, 21.432741267000033],
              [107.75334499200008, 21.430401256000039],
              [107.75485573400002, 21.42861786400001],
              [107.75628901700004, 21.425272429000053],
              [107.75628994900003, 21.424528642000062],
              [107.75629069500009, 21.423933621000032],
              [107.75605308300005, 21.423487085000126],
              [107.755815098, 21.423338065000053],
              [107.75486239400004, 21.423337014000055],
              [107.75168636000006, 21.423630996000043],
              [107.75097212100003, 21.423407058000073],
              [107.75057735000006, 21.421695903000071],
              [107.75057973600008, 21.41983643100005],
              [107.75070211600007, 21.417270476000013],
              [107.7502279760001, 21.415559213000087],
              [107.74888203100002, 21.412731262000051],
              [107.747298347, 21.409605485000057],
              [107.74603089400003, 21.407521379000016],
              [107.74579399600005, 21.406554161000102],
              [107.74579477400002, 21.405959112000069],
              [107.74627302600007, 21.404472040000044],
              [107.74635444300006, 21.402910129000041],
              [107.74611726800006, 21.402166040000026],
              [107.74524473800008, 21.401644366000113],
              [107.74429205100003, 21.401717651000048],
              [107.74206765200002, 21.402979564000098],
              [107.74032023600003, 21.403721332000103],
              [107.73435821700011, 21.404883694000084],
              [107.73047790900004, 21.402817012000064],
              [107.72809693800004, 21.402442196000045],
              [107.72738238600007, 21.4025157],
              [107.72698463800002, 21.403110256000076],
              [107.72698380200003, 21.403705308000021],
              [107.72729914700002, 21.405267699000063],
              [107.72888329500009, 21.407798595000123],
              [107.7259667770001, 21.410524971000108],
              [107.72395782800005, 21.410321458000055],
              [107.72181503900003, 21.409872495000037],
              [107.72030659200001, 21.409944978000084],
              [107.71895660500005, 21.410240794000046],
              [107.71597684900006, 21.412133680000075],
              [107.71248052900006, 21.414360575000032],
              [107.710971822, 21.414581754000039],
              [107.71009888700009, 21.414357473000045],
              [107.70978233300009, 21.413687646000042],
              [107.70986394600001, 21.412200145000071],
              [107.71066113200007, 21.409969785],
              [107.71137880900002, 21.40781368200004],
              [107.71138057600008, 21.406623586000073],
              [107.71122324200003, 21.405656433000075],
              [107.7101131950001, 21.404762407000085],
              [107.707970276, 21.404462069000104],
              [107.70662075700007, 21.404460287000049],
              [107.70526919800007, 21.405797360000101],
              [107.7035177490001, 21.409067776000064],
              [107.70280089000002, 21.410628805000087],
              [107.70176772400008, 21.41137122400005],
              [107.70033865100005, 21.411443677000094],
              [107.69843430000005, 21.410846050000117],
              [107.69613325600008, 21.410099100000046],
              [107.69406944700003, 21.409947509000027],
              [107.69248160800001, 21.410019693000045],
              [107.69089293900001, 21.410612521000068],
              [107.68858645600005, 21.413286983000113],
              [107.68731517200011, 21.413954617000016],
              [107.68656105100007, 21.413916348000051],
              [107.68552998200002, 21.413319855000076],
              [107.6846584080001, 21.412277290000056],
              [107.68458034400004, 21.411458993000032],
              [107.68490042400006, 21.40989746200006],
              [107.68649150400009, 21.407817060000056],
              [107.68673109500001, 21.406924834000037],
              [107.68665291300002, 21.406180911000035],
              [107.68578076500008, 21.40551025300006],
              [107.68300425300002, 21.404316197000028],
              [107.68022668400008, 21.40379153600005],
              [107.67943210800004, 21.40423667800011],
              [107.67792074100005, 21.406094015000043],
              [107.676805899, 21.408175057],
              [107.67640512600006, 21.410480271000054],
              [107.67584483500006, 21.413231524000054],
              [107.67465067000003, 21.415238033000072],
              [107.67377652500008, 21.415757400000054],
              [107.67282373200004, 21.415830376000052],
              [107.67163442000003, 21.414936065000042],
              [107.66854297200004, 21.412179401000039],
              [107.66608620000008, 21.409721160000039],
              [107.66252172100012, 21.406887947000094],
              [107.65980764000008, 21.404291281],
              [107.65974534700008, 21.403984191000092],
              [107.6595083710001, 21.403314401],
              [107.65939066200005, 21.402533207000118],
              [107.659315975, 21.39985535000006],
              [107.65932027700008, 21.397400749],
              [107.6601965880001, 21.395616920000023],
              [107.66146796500006, 21.394875047000106],
              [107.663850258, 21.394357993000042],
              [107.66805941600009, 21.393174217000066],
              [107.670680686, 21.392136764000021],
              [107.67147623800001, 21.391096588000025],
              [107.67187563900011, 21.389609511000081],
              [107.671958159, 21.387750052000065],
              [107.67116778100007, 21.38574052500006],
              [107.67045516100008, 21.384698098000051],
              [107.66894892600007, 21.383580090000095],
              [107.66672730600001, 21.383056079000085],
              [107.66394918500001, 21.383051885],
              [107.66212368100004, 21.382974725000011],
              [107.65823522800009, 21.382448073000049],
              [107.65406837700003, 21.382255643000022],
              [107.65121182100003, 21.381730474000094],
              [107.64660931500006, 21.381053731000051],
              [107.643513861, 21.380974390000063],
              [107.64176707900008, 21.381269103000108],
              [107.63898648800004, 21.382603513000028],
              [107.63741040200003, 21.38285103500008],
              [107.63684364500003, 21.382451243000041],
              [107.63565498100009, 21.381407909000046],
              [107.63446771900009, 21.379620730000056],
              [107.63276617900007, 21.376977262000096],
              [107.6321352750001, 21.374819050000049],
              [107.63213851200001, 21.373108196000068],
              [107.63396489200005, 21.372664906000068],
              [107.63491803600004, 21.372294549000067],
              [107.635718427, 21.36872535400007],
              [107.63794935600004, 21.364117060000076],
              [107.63898569100007, 21.361663987000057],
              [107.63859037200004, 21.360845092000069],
              [107.63382982200005, 21.360093404000047],
              [107.63038087400007, 21.358265197000037],
              [107.62966942300008, 21.356776254000032],
              [107.62975162500004, 21.355288642000048],
              [107.63078532300004, 21.354248942000083],
              [107.63221625300005, 21.352986721],
              [107.63245800300007, 21.351053031000063],
              [107.63238313500008, 21.348672476],
              [107.63206808200005, 21.347407349000044],
              [107.63064072400007, 21.346809861000082],
              [107.62849870800002, 21.346434344],
              [107.62794390000005, 21.346061467000062],
              [107.6275483850001, 21.345391299000127],
              [107.62755023700007, 21.344424249000049],
              [107.62735704200007, 21.34170870600007],
              [107.62704346900006, 21.339699659000111],
              [107.62633382600005, 21.337317985000048],
              [107.62546334000007, 21.336051886000092],
              [107.62443257400004, 21.335603798000058],
              [107.62300417300007, 21.335601372000035],
              [107.62078104900007, 21.336192703000023],
              [107.61927198100008, 21.336859630000049],
              [107.61609553000008, 21.337969996000083],
              [107.61291800600007, 21.33960104300003],
              [107.6112501690001, 21.340267634000028],
              [107.61029741000004, 21.340249566000047],
              [107.61003125300005, 21.340304407000055],
              [107.60982203200005, 21.340116344000037],
              [107.60966421100005, 21.339669725000121],
              [107.60966630100005, 21.338628276000101],
              [107.61014572600001, 21.336992532000032],
              [107.611419735, 21.334837461000063],
              [107.61261420700006, 21.332756617000115],
              [107.61357164000006, 21.330154607000026],
              [107.61381294600004, 21.328518425000098],
              [107.613736257, 21.327179242000049],
              [107.61342313300003, 21.325021350000029],
              [107.61223345400001, 21.324721703000101],
              [107.61120188600009, 21.324719895000044],
              [107.61017032800001, 21.324718082000068],
              [107.60842459900007, 21.324715004000083],
              [107.60739378200005, 21.324341219000061],
              [107.60652347800007, 21.3230750170001],
              [107.60648553600004, 21.322219434000097],
              [107.60648658900004, 21.321698698000091],
              [107.60640828700005, 21.321177811000069],
              [107.60577500300005, 21.320432759000063],
              [107.60292130800011, 21.319014211000109],
              [107.59974980000005, 21.317818227000096],
              [107.59792555900002, 21.31744295900004],
              [107.59752974300004, 21.316995882000086],
              [107.59745223700003, 21.316103033000019],
              [107.59769289300004, 21.314838784000045],
              [107.59801428, 21.312905146000013],
              [107.59801674000002, 21.311714861000027],
              [107.59786020000003, 21.310673064000071],
              [107.597068753, 21.30970451300005],
              [107.59604035500008, 21.308214767000067],
              [107.59604143600009, 21.307694009000052],
              [107.59604220900006, 21.307322039],
              [107.59667866200006, 21.306504861000072],
              [107.59802903800005, 21.305763379],
              [107.60017284000006, 21.305023313000056],
              [107.60239764700003, 21.303465038000091],
              [107.603510563, 21.302425513000053],
              [107.60374979800008, 21.301830781000049],
              [107.60375146500004, 21.301012438000022],
              [107.60343698800004, 21.29959837500008],
              [107.60225189000005, 21.297141208000077],
              [107.60193620200003, 21.296322298000092],
              [107.60106377000005, 21.296171937000082],
              [107.59828474100007, 21.297208445000045],
              [107.59550459000008, 21.29876568300002],
              [107.59407366500008, 21.300102182000025],
              [107.59399075700006, 21.301813110000097],
              [107.59359139800006, 21.303077083000055],
              [107.59271754100004, 21.303596242000111],
              [107.5908128560001, 21.303815916000076],
              [107.5881945460001, 21.30381106400003],
              [107.58478297100008, 21.303730301000101],
              [107.58081584300002, 21.303722830000105],
              [107.57799796800002, 21.304275436000083],
              [107.57529722700011, 21.30568376399999],
              [107.57251693400011, 21.307166305000088],
              [107.56981710000009, 21.308128196],
              [107.56767380900004, 21.308570389000089],
              [107.56719641400007, 21.309164610000053],
              [107.5681436040001, 21.311398259000036],
              [107.56916949900007, 21.31392963100005],
              [107.57035870800006, 21.314378302000044],
              [107.57131038600002, 21.3146033250001],
              [107.57178548000007, 21.315050604000071],
              [107.57178384200003, 21.315794525000072],
              [107.57043165400006, 21.317279761000037],
              [107.56788771400007, 21.319432199000055],
              [107.56542474400003, 21.320840843000077],
              [107.56272485300011, 21.321728232000048],
              [107.55859645300002, 21.322687147000075],
              [107.55677122800009, 21.322757895000066],
              [107.55423287100011, 21.32238084100004],
              [107.55090119000005, 21.321927758000037],
              [107.54927460200003, 21.32188725400006],
              [107.54760669800004, 21.322553386000017],
              [107.54426736100005, 21.325373428000113],
              [107.54100570500002, 21.328863091000102],
              [107.53917671400004, 21.330495906000024],
              [107.53735033800008, 21.33101283000007],
              [107.53576219700004, 21.331455856000048],
              [107.53496724000007, 21.332049319000035],
              [107.533611615, 21.334798917000072],
              [107.53376818700008, 21.335691935000064],
              [107.53498437100008, 21.337860103000047],
              [107.53487205700002, 21.338669857000077],
              [107.53399717000011, 21.339486311000044],
              [107.53304380600009, 21.339930645000038],
              [107.53105933600003, 21.340149624000034],
              [107.52708965800012, 21.340885079000046],
              [107.52423114200003, 21.341548469000045],
              [107.52362332800001, 21.341357097000113],
              [107.52296378700007, 21.340578675000096],
              [107.52288643200006, 21.33976022600006],
              [107.52364388600003, 21.338311250000075],
              [107.52460052700005, 21.33652794300005],
              [107.52492140500004, 21.335115209000044],
              [107.52524444800012, 21.332809801000074],
              [107.52509210500004, 21.33020579],
              [107.52430420600004, 21.327897971000098],
              [107.52328009700004, 21.324845720000063],
              [107.52241143400002, 21.323132829000087],
              [107.51987662800008, 21.32134194000006],
              [107.518053061, 21.320742849000048],
              [107.51535443700001, 21.321034533000024],
              [107.51396814000006, 21.320101583000017],
              [107.51143550700003, 21.317492279000035],
              [107.51247169600006, 21.315634741000089],
              [107.51247337400011, 21.314965205000114],
              [107.511683072, 21.313698784000046],
              [107.50986260100009, 21.311909325000045],
              [107.50780281300011, 21.310640062000097],
              [107.50558208200005, 21.310263147000086],
              [107.50375638600006, 21.310556641000034],
              [107.50278364200004, 21.31022144300001],
              [107.50257019700005, 21.308991714000044],
              [107.50281128000002, 21.307801956000013],
              [107.50344830700006, 21.306910653000074],
              [107.504482432, 21.305871454000069],
              [107.50773946300011, 21.304316427000103],
              [107.51044292100006, 21.302016193000028],
              [107.51092178100006, 21.300901324000066],
              [107.51068581800008, 21.300082464000077],
              [107.50997287300007, 21.299634515000051],
              [107.50886209300009, 21.299632056000029],
              [107.50632205400007, 21.300072782000079],
              [107.50227413400007, 21.300658877000075],
              [107.4974320100001, 21.301540687000028],
              [107.494510152, 21.302188898000018],
              [107.49199390600009, 21.302755788000027],
              [107.48691168800005, 21.304380753000096],
              [107.48404982300005, 21.306457148000035],
              [107.48007051400005, 21.310985875000107],
              [107.478798994, 21.311726817000043],
              [107.47768854500005, 21.311575414000039],
              [107.47440384300006, 21.308554699000034],
              [107.47083435300003, 21.308174230000084],
              [107.46908858200001, 21.308244447000035],
              [107.46845260300006, 21.308689288000068],
              [107.46525877900002, 21.315972156000029],
              [107.46367121200004, 21.316191498000059],
              [107.45811833300004, 21.315657233000081],
              [107.45518269300007, 21.31557563900008],
              [107.45423051800006, 21.315573296000068],
              [107.45295782400009, 21.316686047000033],
              [107.45200125300008, 21.318245939000043],
              [107.44921331100007, 21.32203303900004],
              [107.44674563200009, 21.324779389000078],
              [107.44579257400001, 21.325074576000077],
              [107.444841, 21.324849017000069],
              [107.44428703100004, 21.32432689200003],
              [107.44417044600009, 21.323471084000097],
              [107.44496798000009, 21.32205963600002],
              [107.44711931000009, 21.318940538000049],
              [107.44839691400003, 21.316116793],
              [107.44864338800008, 21.313141685000048],
              [107.44849038900003, 21.311132680000085],
              [107.44817617000005, 21.310015987000064],
              [107.44786047700005, 21.30942004800005],
              [107.447147219, 21.309120701000062],
              [107.446195086, 21.309118323000042],
              [107.44365605800007, 21.309111961000049],
              [107.44000684000011, 21.308879584000096],
              [107.43302690300008, 21.308043493000106],
              [107.42898384700004, 21.306842806000056],
              [107.42795412600003, 21.306244993000064],
              [107.42763806300007, 21.305797809000104],
              [107.42764003300006, 21.305128271000036],
              [107.42871345800006, 21.30434989100003],
              [107.43220997100008, 21.302499016000098],
              [107.43642306500007, 21.299757168000077],
              [107.44143051100005, 21.296645243000079],
              [107.44596198500008, 21.293457612000033],
              [107.44906165300004, 21.291531046000053],
              [107.45001580300003, 21.29078945200007],
              [107.45065449600003, 21.28937750900004],
              [107.45153517000007, 21.286552625000077],
              [107.45153747700007, 21.285734262000041],
              [107.45114248200008, 21.285138105],
              [107.4486071310001, 21.283941459000033],
              [107.44670501500011, 21.283267138000014],
              [107.44495966100001, 21.283262771000096],
              [107.44353100900003, 21.283482379000063],
              [107.44043440900005, 21.284367348000011],
              [107.43586910700006, 21.285583310000078],
              [107.42999460500002, 21.286833014000095],
              [107.42546940400005, 21.287862881000038],
              [107.42189795800005, 21.288299962000053],
              [107.419673211, 21.289410097000072],
              [107.41871828600003, 21.29037474200004],
              [107.41799846599999, 21.292307153000053],
              [107.41703460100008, 21.29624759700004],
              [107.41663456000002, 21.297362482],
              [107.41512351900005, 21.298548819000104],
              [107.41353536500002, 21.2989909940001],
              [107.41274144100005, 21.299058655000103],
              [107.41115853000008, 21.297868754000056],
              [107.41100210100011, 21.297124389000111],
              [107.41100367800006, 21.296603623000095],
              [107.41164243900006, 21.295266202000015],
              [107.41339754400006, 21.292071827000122],
              [107.41539372800003, 21.287836518000077],
              [107.417628279, 21.283452982000114],
              [107.41978494500009, 21.278548400000119],
              [107.42050669000004, 21.275946365000031],
              [107.42051444500007, 21.27334244000005],
              [107.42044109300012, 21.271333484000095],
              [107.41996822800007, 21.270290657000054],
              [107.41941492500004, 21.269619622000043],
              [107.41878051500004, 21.269543559000056],
              [107.41806589100005, 21.269764880000075],
              [107.41687149900008, 21.271249715000096],
              [107.4148793210001, 21.274220401000044],
              [107.41232771700001, 21.278528739000087],
              [107.41073070800003, 21.281946795000103],
              [107.40969643500009, 21.282911202000051],
              [107.40818705800007, 21.283576753000055],
              [107.40723346400004, 21.284094976000034],
              [107.40635783300007, 21.285059798000106],
              [107.40587682300006, 21.286695239000061],
              [107.40551310700008, 21.28888896200003],
              [107.40484051800011, 21.291239441000087],
              [107.404639516, 21.293442474000109],
              [107.40417661300006, 21.295646221000105],
              [107.40327920400007, 21.29727620900006],
              [107.402510885, 21.299315744000054],
              [107.40130488300005, 21.302068637000012],
              [107.39732754300003, 21.306163772],
              [107.39478184400008, 21.308314264000039],
              [107.39271401100004, 21.309870875000044],
              [107.39184144800006, 21.309794087000085],
              [107.39073481500006, 21.30845195900006],
              [107.39002211500008, 21.308003631000091],
              [107.38859251700009, 21.308446057000104],
              [107.38739977200008, 21.309261090000042],
              [107.38421656300005, 21.312228018000035],
              [107.38166898400002, 21.314899087000057],
              [107.37920400200008, 21.316528840000082],
              [107.37634343300002, 21.317785481000051],
              [107.3748353440001, 21.317930017000016],
              [107.37444051900003, 21.317333757000064],
              [107.37397304700008, 21.314654293000054],
              [107.37279675300002, 21.310336140000082],
              [107.37240602900002, 21.308475187000028],
              [107.37129881700005, 21.307356130000073],
              [107.37082323200005, 21.307205991000089],
              [107.36971193800012, 21.307351621000059],
              [107.36804282000004, 21.308239600000078],
              [107.36613443400006, 21.309498842],
              [107.36398750400005, 21.310906178],
              [107.36263716700006, 21.311348652000014],
              [107.36184396000007, 21.311271975000068],
              [107.36144772300005, 21.311122045],
              [107.36137008200004, 21.310601071000065],
              [107.36145260700002, 21.309634185000093],
              [107.36240937400004, 21.308223458000072],
              [107.36384511200001, 21.305921363000024],
              [107.36579496600004, 21.304104285000044],
              [107.36747229300009, 21.300686921000086],
              [107.36859634700001, 21.296598385000017],
              [107.3693981870001, 21.293996815000057],
              [107.36948644200002, 21.291244416000055],
              [107.36965500000012, 21.288194636000043],
              [107.36870924600002, 21.286257626000094],
              [107.36736634800012, 21.284468266000097],
              [107.36665451700001, 21.283796663000132],
              [107.36594075000002, 21.283720224000021],
              [107.36435382100008, 21.283790073000119],
              [107.363478953, 21.284457129000067],
              [107.36188591700008, 21.286386868000037],
              [107.36069222700007, 21.287499384000071],
              [107.35989861900005, 21.287571489000086],
              [107.35918557700003, 21.287271839000077],
              [107.35879110700007, 21.286601122],
              [107.35871667100004, 21.28511296900006],
              [107.36007711200007, 21.28154583500006],
              [107.36215466000002, 21.277013558000043],
              [107.36343077800009, 21.274934077000026],
              [107.36438590300007, 21.273969645000079],
              [107.36581772700006, 21.272783367000066],
              [107.3670333150001, 21.271268213000027],
              [107.36753117100008, 21.270370308000075],
              [107.36833340100002, 21.267619825000047],
              [107.3677011930001, 21.266874024000067],
              [107.36572116200006, 21.265901173000046],
              [107.36421760000009, 21.264780862000073],
              [107.36335036200009, 21.263141577000063],
              [107.36260419200009, 21.260870223000069],
              [107.3624540810001, 21.258265775000048],
              [107.36262782900009, 21.253653430000071],
              [107.363198916, 21.248818975000106],
              [107.36436715500002, 21.243279372000053],
              [107.36437833300006, 21.23985688300003],
              [107.36510334500011, 21.236436422000082],
              [107.36574708200007, 21.233610929000065],
              [107.36631196800001, 21.230636395000069],
              [107.36687804600004, 21.227289815000105],
              [107.36672450600003, 21.225726881000107],
              [107.36637257300005, 21.224200573000118],
              [107.36592965199999, 21.222971407000024],
              [107.36527217400004, 21.221146816000044],
              [107.36465106600008, 21.217052728000048],
              [107.36386796000002, 21.213999828000048],
              [107.36339821400006, 21.21213831900004],
              [107.36340235100005, 21.210873424000098],
              [107.3637261370001, 21.208865372000041],
              [107.36429001000005, 21.206188336000054],
              [107.36429365500007, 21.205072241000074],
              [107.36354283100003, 21.20428880700009],
              [107.36259291700004, 21.203765219000026],
              [107.36140412100009, 21.20353856900001],
              [107.35997693700004, 21.20346004],
              [107.35902752700009, 21.202787630000053],
              [107.35736956300005, 21.200550588000056],
              [107.3568193780001, 21.199060823000067],
              [107.35682060700002, 21.198688793000109],
              [107.35705974100009, 21.198317445000022],
              [107.35809258900009, 21.197725174000048],
              [107.36047450400009, 21.196839173000015],
              [107.36222029000004, 21.196472166000113],
              [107.36277660700006, 21.196101724000101],
              [107.36277831200002, 21.195580867000082],
              [107.36278050400003, 21.1949112020001],
              [107.36270436700002, 21.19394366500007],
              [107.36191529600002, 21.192750854000053],
              [107.36136339000009, 21.191781941000087],
              [107.36113159800001, 21.189921043000126],
              [107.36110452700008, 21.186088871000038],
              [107.36127629900008, 21.182071234000063],
              [107.36129118800008, 21.177532229000029],
              [107.36130070800006, 21.174630222000076],
              [107.36123166500003, 21.171504747000114],
              [107.36116507600012, 21.167635138000037],
              [107.36117459600011, 21.164733082000083],
              [107.36094332600007, 21.162723273000061],
              [107.36062790400008, 21.16220148000005],
              [107.35999582300011, 21.161529934],
              [107.35841356500001, 21.160483566000018],
              [107.35667349400009, 21.159213496000092],
              [107.3558865700001, 21.157425288000105],
              [107.35557633200008, 21.155340806000119],
              [107.35557879400002, 21.154596672000103],
              [107.35597643100007, 21.154225768000053],
              [107.3583534110001, 21.154679148000085],
              [107.35972176000007, 21.154994701000085],
              [107.36890685600012, 21.156621531000042],
              [107.36968297800004, 21.157092945000052],
              [107.37039387500006, 21.15791351900009],
              [107.37039098800008, 21.158806465000076],
              [107.37006927900002, 21.160219406000103],
              [107.36950923300004, 21.161780481000037],
              [107.36871277300006, 21.162894398],
              [107.36815415700002, 21.164008999000124],
              [107.36815270900007, 21.164455463000117],
              [107.36902220000007, 21.165276484000017],
              [107.37107738100003, 21.167217053000087],
              [107.37297499600001, 21.168859501],
              [107.37432121300003, 21.169384196000074],
              [107.37638268700006, 21.169390015000111],
              [107.37876202700002, 21.169173469000043],
              [107.37908084000001, 21.168653478000095],
              [107.37904511400008, 21.167425578000021],
              [107.378731773, 21.166234107000022],
              [107.37794175600001, 21.165338936000033],
              [107.3761212790001, 21.164366455000035],
              [107.37572604500008, 21.16399327600007],
              [107.37564866300008, 21.163397754000087],
              [107.37620985700006, 21.161464605000013],
              [107.37668913900011, 21.160349765000049],
              [107.37756221600004, 21.160054569000053],
              [107.37883075700002, 21.160058133000078],
              [107.37954431100009, 21.160060135000066],
              [107.38041667200005, 21.159988166000062],
              [107.38113117300003, 21.159692511000024],
              [107.38176732700008, 21.159098990000032],
              [107.38240726300009, 21.15731485000012],
              [107.38288909400008, 21.15538144300011],
              [107.38353206000008, 21.152629930000089],
              [107.384411443, 21.150325538000025],
              [107.384932991, 21.148355002000052],
              [107.38533525600006, 21.146495755000046],
              [107.38605978700005, 21.143000264000108],
              [107.38742060700011, 21.138836769000058],
              [107.38862187600003, 21.134970462000076],
              [107.38878859800006, 21.132366355000087],
              [107.388916733, 21.12942724],
              [107.38828747499998, 21.127862746000048],
              [107.38741760100004, 21.12719059100003],
              [107.38622854100007, 21.127187296000024],
              [107.38472311100003, 21.126959862000035],
              [107.38377444900006, 21.126138628000035],
              [107.38259342500004, 21.12360513900002],
              [107.38102057600003, 21.119656596000077],
              [107.38039164000003, 21.118017637],
              [107.38039732100006, 21.116231611000025],
              [107.38064293400004, 21.113776478000098],
              [107.380967791, 21.111321567000019],
              [107.38089255199999, 21.110056223000036],
              [107.38014534300008, 21.10823085],
              [107.37785337600006, 21.106140664000073],
              [107.37643214000006, 21.104425005000046],
              [107.37343337700001, 21.100323414000052],
              [107.37201126300003, 21.098905392000056],
              [107.37082286600003, 21.09875317300007],
              [107.36971082600003, 21.099494209000014],
              [107.36724751300005, 21.101422111000097],
              [107.36594448200006, 21.101391288000038],
              [107.36532645000008, 21.100460471000112],
              [107.3651140420001, 21.099406643000052],
              [107.36511719500001, 21.098439187000025],
              [107.36647233300005, 21.096061599000038],
              [107.369176978, 21.093018059000102],
              [107.37116559700004, 21.090791072000044],
              [107.37291401500003, 21.08930759100005],
              [107.3747804560001, 21.088084910000049],
              [107.37502012600004, 21.087490208000048],
              [107.3750220360001, 21.086894842000078],
              [107.37478618700004, 21.086298794000058],
              [107.37407505500008, 21.085626979000068],
              [107.37265088600002, 21.084878726000063],
              [107.36995748000001, 21.084498956000083],
              [107.36631183100008, 21.08448854100002],
              [107.36274446800006, 21.084775984000089],
              [107.36044612300006, 21.084769351000077],
              [107.35806951100005, 21.0844647750001],
              [107.35197543200005, 21.081916630000116],
              [107.34920559300011, 21.080717733000043],
              [107.3490498240001, 21.079898617000126],
              [107.3491335460001, 21.078559249000058],
              [107.35040900900003, 21.076330293000112],
              [107.35184219000004, 21.074325058000021],
              [107.35295513300011, 21.073286386000056],
              [107.35660596800003, 21.071659700000019],
              [107.35922335400005, 21.070997486000032],
              [107.36243495200003, 21.070374154000056],
              [107.36338883500008, 21.069483805000019],
              [107.36466673600003, 21.066436075000091],
              [107.36475132500001, 21.064798962000083],
              [107.36459962800008, 21.062714622000023],
              [107.36436821100003, 21.060778897000084],
              [107.36429964500005, 21.057503972000099],
              [107.36412011900011, 21.051809844000054],
              [107.36412789100004, 21.049428208000087],
              [107.36397791300007, 21.046822826000074],
              [107.36358707400005, 21.045184302000038],
              [107.36271984500007, 21.043842112],
              [107.36185214100004, 21.042648761000038],
              [107.36151924100002, 21.04120707600012],
              [107.36202157800003, 21.039299994000068],
              [107.36250233600001, 21.037663961000064],
              [107.36282049100004, 21.037292734000076],
              [107.36401143200008, 21.036551874000061],
              [107.36512211200005, 21.035406959000014],
              [107.36710975700002, 21.034030196000053],
              [107.36905999200009, 21.03124467500006],
              [107.37009746000004, 21.028940323000086],
              [107.37066096400004, 21.026188038],
              [107.37066481000009, 21.024997166000055],
              [107.36964158100008, 21.022910218000042],
              [107.36640628700007, 21.018881727000057],
              [107.36357066100003, 21.013812269000098],
              [107.36207685100007, 21.010309685000017],
              [107.36057771400002, 21.008444558000058],
              [107.35820913800006, 21.005981432000098],
              [107.35493216800009, 21.002734081000085],
              [107.35406247500006, 21.002210510000019],
              [107.35311184300011, 21.002207730000052],
              [107.35208050100003, 21.002651308000075],
              [107.35048866400004, 21.004879615000057],
              [107.34993188600006, 21.005547864000015],
              [107.34906095100004, 21.005396436000098],
              [107.34700445300007, 21.004422754000096],
              [107.34336607000003, 21.002700029000017],
              [107.34083204500006, 21.002394756000072],
              [107.33988292400004, 21.001945325000101],
              [107.33941264800004, 21.000455265000092],
              [107.33862829100006, 20.998145491000031],
              [107.33784015100004, 20.99695220200006],
              [107.33546794300005, 20.995679709000022],
              [107.33178856100008, 20.994440461000075],
              [107.32561070500007, 20.994123964],
              [107.32426402300003, 20.994119851000015],
              [107.32362874800005, 20.994564513000036],
              [107.32306828900003, 20.996274769000053],
              [107.32005284300003, 20.997754181000069],
              [107.31624516200002, 20.99923110800006],
              [107.31299353800009, 21.000263086000089],
              [107.31077436300006, 21.000553912000086],
              [107.30617966400004, 21.000539539000052],
              [107.3044371120001, 21.000459630000101],
              [107.30348888200007, 20.999786744000026],
              [107.3026209380001, 20.998816381000026],
              [107.30167671200005, 20.997027001000035],
              [107.30054422000005, 20.992520174000084],
              [107.29373086900003, 20.992721813000067],
              [107.293487821, 20.994209712000078],
              [107.29316097800005, 20.996962715000045],
              [107.29203570800011, 21.001425099000045],
              [107.28681023700003, 21.000589545000103],
              [107.28348495500005, 21.000057758000068],
              [107.2734283500001, 20.998908441000097],
              [107.27175336100007, 21.001954680000075],
              [107.26688123000008, 21.001975789000049],
              [107.26387117100008, 21.00189136200008],
              [107.2637972970001, 21.000476883000104],
              [107.26380404300004, 20.998690510000095],
              [107.26372735000008, 20.998020352000069],
              [107.26262252500007, 20.996900172000018],
              [107.26080306800006, 20.996224190000042],
              [107.2580318820001, 20.995842746000015],
              [107.25722865700008, 20.998742948000078],
              [107.252871656, 20.998728266000022],
              [107.25097013300005, 20.998796265000053],
              [107.25033496200008, 20.999166280000026],
              [107.25009416100005, 20.999984223000112],
              [107.25009015200007, 21.001026277000086],
              [107.24992827700001, 21.001918919000047],
              [107.24945181100001, 21.002215034000038],
              [107.24858010800001, 21.00228650600009],
              [107.24770869200003, 21.002283543000111],
              [107.24652299500008, 21.001609614000039],
              [107.24549688700009, 21.000638481000067],
              [107.2450247420001, 20.999818110000042],
              [107.24439676900001, 20.99832729300006],
              [107.24432100800004, 20.997433838000035],
              [107.24384800900008, 20.996836750000014],
              [107.24313563100002, 20.996685444000015],
              [107.24170943100006, 20.996754990000085],
              [107.23948931900003, 20.997268401000021],
              [107.23782313600002, 20.997932557000084],
              [107.23695174000004, 20.997929551000105],
              [107.2364778880001, 20.997555755000086],
              [107.2356117460001, 20.996212952000093],
              [107.234349244, 20.994943213000013],
              [107.23193797400006, 20.993706689000113],
              [107.22940718200002, 20.992655809000027],
              [107.22560926300009, 20.991526020000073],
              [107.22378790900004, 20.991370763000106],
              [107.22331203200009, 20.991517960000024],
              [107.22275366000008, 20.992483646000082],
              [107.22235342800009, 20.99352431100003],
              [107.22171822000003, 20.993894247000085],
              [107.22029233100008, 20.993889222000028],
              [107.21870920100011, 20.993585895000066],
              [107.21609986000004, 20.992385716000058],
              [107.21420258600008, 20.991411331000087],
              [107.21381873700003, 20.990925748000052],
              [107.21357397500003, 20.990143715],
              [107.21365979000002, 20.988506463000078],
              [107.21421728600006, 20.987764096000113],
              [107.21501062600007, 20.987469175000051],
              [107.21770388300006, 20.987478723000038],
              [107.22063507500012, 20.987414642000068],
              [107.22412165700003, 20.98712916700007],
              [107.22729078300006, 20.986991398000043],
              [107.22855819400012, 20.986995825000051],
              [107.22935238300001, 20.986477558000068],
              [107.22943424900004, 20.985807926000085],
              [107.2282714080001, 20.98344388600006],
              [107.22628736600007, 20.980241687000053],
              [107.22450896500001, 20.978415027],
              [107.2236170300001, 20.977662729000102],
              [107.222526223, 20.976909718000059],
              [107.22028634200004, 20.97557817600002],
              [107.21776537100008, 20.973896229000033],
              [107.21574456600004, 20.972398552000101],
              [107.21329904700011, 20.969933456000064],
              [107.21132400700003, 20.968660983000028],
              [107.20745018200006, 20.966860636000014],
              [107.20365442100005, 20.965358247000054],
              [107.19962018400003, 20.964078239000017],
              [107.19795966000004, 20.963402278000039],
              [107.19693368900006, 20.962505295000078],
              [107.19551662900005, 20.960415879000038],
              [107.19335165000007, 20.957244361000079],
              [107.19193219900004, 20.955750407000018],
              [107.18893496100003, 20.952761871000099],
              [107.18838265900007, 20.952238695000069],
              [107.18696606500001, 20.950741311000073],
              [107.18628415900008, 20.95009393900007],
              [107.18609286500006, 20.949912332000054],
              [107.18499126500004, 20.949540519000017],
              [107.18380403100004, 20.949386950000104],
              [107.18166143000005, 20.94968305400009],
              [107.18047126600007, 20.950199712000114],
              [107.17888417500002, 20.950938217000022],
              [107.17721974200006, 20.951229785000081],
              [107.17516026600001, 20.951296547000098],
              [107.17405368400003, 20.95077133700007],
              [107.17389624100005, 20.950547430000107],
              [107.1738978180001, 20.950175238000043],
              [107.17421807100004, 20.949357607000074],
              [107.175572307, 20.947501675000076],
              [107.17756290900003, 20.944978137000092],
              [107.17899502100005, 20.943420223000061],
              [107.17982907700005, 20.942827796000074],
              [107.18066031300008, 20.942905320000094],
              [107.1814494600001, 20.94357820900008],
              [107.18287092600011, 20.944551186000027],
              [107.1836610120001, 20.945000748],
              [107.18453154800002, 20.945152843000066],
              [107.18619902500005, 20.944116834000056],
              [107.18826275800009, 20.943007818000048],
              [107.18913638400008, 20.942415501000063],
              [107.19001649800008, 20.940259966000063],
              [107.1902584040001, 20.939218679000057],
              [107.18971023400005, 20.937727858000073],
              [107.18856766700002, 20.936346498000091],
              [107.18746052200007, 20.935970222000044],
              [107.18627076800004, 20.936412492000045],
              [107.18372881500004, 20.938264138000036],
              [107.18261667700003, 20.939078879000036],
              [107.18190393300003, 20.939076241000087],
              [107.18040331300004, 20.938102947000026],
              [107.17866765000005, 20.936533246000089],
              [107.17692886400006, 20.935707921000102],
              [107.17534533000006, 20.935627575000026],
              [107.17439407000003, 20.935847347000092],
              [107.17383594000003, 20.936738558000023],
              [107.17295597900006, 20.938819610000081],
              [107.17215741100007, 20.940379870000037],
              [107.17088745600006, 20.941045077000098],
              [107.16977873400008, 20.94104091400002],
              [107.16843591400009, 20.940217015000059],
              [107.16757017800003, 20.938948269000065],
              [107.1674152850001, 20.938128833000036],
              [107.16741973400011, 20.93708668200005],
              [107.16750274600005, 20.936193699000057],
              [107.16758479400009, 20.935524044000076],
              [107.167638455, 20.934620569000018],
              [107.16700893300005, 20.933884657000057],
              [107.16411182400006, 20.932831044000096],
              [107.16293053900003, 20.931838603000053],
              [107.16201575000009, 20.929912895000058],
              [107.1608059420001, 20.928809881000099],
              [107.15787788900012, 20.927670896000095],
              [107.15479726000004, 20.925872512000062],
              [107.15329494700009, 20.92534567100008],
              [107.15242387700005, 20.925342331000074],
              [107.15218339400005, 20.926011383000095],
              [107.15217595100005, 20.927723524],
              [107.15247783000007, 20.931149015000102],
              [107.15309514500008, 20.932553674000062],
              [107.15440151300007, 20.933872117000085],
              [107.15555176200003, 20.934510647000067],
              [107.15695133600011, 20.935238122000044],
              [107.15869099700006, 20.935840275000125],
              [107.16011501400004, 20.936520895000044],
              [107.16116253400003, 20.937286341000046],
              [107.162579378, 20.939301617000083],
              [107.16359836500004, 20.941762006000083],
              [107.16374909500007, 20.943549142000073],
              [107.16374494500008, 20.944516852000028],
              [107.16287155200003, 20.945034627000055],
              [107.16204662500007, 20.944823003000046],
              [107.16059052400003, 20.943900579000015],
              [107.1589435220001, 20.942106344000067],
              [107.15699026400004, 20.94065750900009],
              [107.15493495300007, 20.93951439100001],
              [107.15303572100004, 20.939451341000037],
              [107.15041097800002, 20.939617029000068],
              [107.14961761800002, 20.939451248000026],
              [107.14918304100004, 20.938540556000085],
              [107.14848202600004, 20.936314374000098],
              [107.14739389900006, 20.934451679000077],
              [107.14655377700009, 20.932971751000103],
              [107.14599182300006, 20.932337344000103],
              [107.14547325900004, 20.932277166000098],
              [107.14414012500005, 20.932994700000052],
              [107.14296402600009, 20.933844681000032],
              [107.142355432, 20.935293728000111],
              [107.14227330800004, 20.936861711000084],
              [107.14270243900003, 20.938880587000064],
              [107.14316999500008, 20.940303527000111],
              [107.14328637100002, 20.941349213000038],
              [107.14201467300005, 20.942386440000057],
              [107.13995001700002, 20.943643904000098],
              [107.13916941400001, 20.943579350000086],
              [107.1387765370001, 20.943392391000067],
              [107.13855058100005, 20.942583404000104],
              [107.13811245600003, 20.940494734000126],
              [107.13700819600007, 20.939530867000045],
              [107.13566406500004, 20.939129408000042],
              [107.13256890700011, 20.938698084000059],
              [107.12730947400006, 20.938286751000042],
              [107.12316005300001, 20.938155533000021],
              [107.12188811500002, 20.93818045400004],
              [107.11919671900009, 20.938090496000058],
              [107.11562085400007, 20.938374258000088],
              [107.11094437600012, 20.93924877700006],
              [107.10745303400007, 20.940723514000048],
              [107.10403468400004, 20.943538420000074],
              [107.10156693500005, 20.946282687000028],
              [107.09886255800011, 20.94880262100007],
              [107.09576453600002, 20.950799832000122],
              [107.09425561300004, 20.951686929000068],
              [107.09223379600009, 20.95216248600007],
              [107.09049107000004, 20.952229760000037],
              [107.08835269800005, 20.952220949000022],
              [107.08566237800002, 20.951688758000039],
              [107.08328920900004, 20.951083409000049],
              [107.08044297800009, 20.950029436000058],
              [107.07949822700007, 20.94883446500004],
              [107.07887202600006, 20.94726862300007],
              [107.07784704900007, 20.946296634000113],
              [107.07650176100005, 20.946067696000092],
              [107.07507551900008, 20.946210611000105],
              [107.07356902200004, 20.946576493000087],
              [107.07142291500001, 20.948205169000019],
              [107.06887508900006, 20.951023147000036],
              [107.06612260500005, 20.955217340000054],
              [107.06523927100005, 20.957744516000076],
              [107.06522892300009, 20.959903188000069],
              [107.06587333100003, 20.960948053000017],
              [107.06404783200006, 20.961713284],
              [107.06200922000005, 20.962560452000019],
              [107.06202340300007, 20.962486851000058],
              [107.06209637500007, 20.962108249],
              [107.06174653000008, 20.961443159000034],
              [107.05921199700006, 20.959906091000043],
              [107.05590308100003, 20.957901157000066],
              [107.05230955800006, 20.956359465000048],
              [107.04561625200003, 20.953410764000054],
              [107.04026136000007, 20.951131279],
              [107.03684344100007, 20.949822352000069],
              [107.03331917000006, 20.948612446000055],
              [107.03021657900004, 20.947802512000102],
              [107.02759309300004, 20.94766931],
              [107.02537931300002, 20.947969251000039],
              [107.022128686, 20.948530024000078],
              [107.01741466200005, 20.949924839000019],
              [107.013738566, 20.950837530000044],
              [107.00981795800006, 20.9521392380001],
              [107.00568511300006, 20.953993414000038],
              [107.0026231250001, 20.954665550000051],
              [107.00072977900003, 20.95465699700005],
              [106.99767481600003, 20.953957229000061],
              [106.99316134600006, 20.953662340000086],
              [106.98995652200001, 20.953784906000102],
              [106.98805891400004, 20.954599341000076],
              [106.98615701100006, 20.956236852000032],
              [106.98498757400003, 20.957054597000102],
              [106.98352899700004, 20.957459444000023],
              [106.98251019600004, 20.957317575000012],
              [106.98062327900006, 20.956074207000071],
              [106.97903125900004, 20.954146257000069],
              [106.97845660300001, 20.952634558000049],
              [106.97714943200003, 20.951942575000082],
              [106.97481919800006, 20.951931777000077],
              [106.96913560800009, 20.952591266000105],
              [106.96534095300009, 20.954082585000045],
              [106.9641699850001, 20.955174588000069],
              [106.962852642, 20.956403076000107],
              [106.960812167, 20.956667862000074],
              [106.95352631700007, 20.957319414000075],
              [106.94900691100005, 20.958121070000054],
              [106.94755047200007, 20.958114139000102],
              [106.94594912200009, 20.957969327000022],
              [106.94507896700004, 20.957279253000038],
              [106.94349249100004, 20.954390769000049],
              [106.94233181500002, 20.953562100000056],
              [106.940444445, 20.952455568000069],
              [106.93811718600008, 20.951895651000108],
              [106.93636950800008, 20.95188724700003],
              [106.93476747800005, 20.951879531000102],
              [106.93272325900001, 20.952829978000054],
              [106.92936072200007, 20.955145874],
              [106.92472769100007, 20.958224719000015],
              [106.92282524200004, 20.960132785000063],
              [106.92104255400007, 20.959329133],
              [106.92027598900006, 20.958983552000099],
              [106.91957934800006, 20.958669490000048],
              [106.91672918300004, 20.957487545000042],
              [106.9167202090001, 20.95738113700012],
              [106.91670629900003, 20.957102560000074],
              [106.91661495700001, 20.955269356000095],
              [106.91547306900003, 20.954337665000097],
              [106.91416475300005, 20.95386819900007],
              [106.91274895700006, 20.954319555000019],
              [106.90775296600005, 20.95591220199999],
              [106.90628644200004, 20.956144571000031],
              [106.90387459900005, 20.955826572000099],
              [106.90334125200006, 20.955358230000073],
              [106.90289762600004, 20.953983887000049],
              [106.90367939000006, 20.953507734000084],
              [106.90696611800004, 20.95182618000004],
              [106.91255627300011, 20.948458075000076],
              [106.92270123100008, 20.938033599000057],
              [106.92670297300008, 20.931557853000093],
              [106.92708274300006, 20.927359522000089],
              [106.92712738700001, 20.926865973000105],
              [106.92697664800008, 20.91804053700006],
              [106.92640669700008, 20.912968205000126],
              [106.92322759400002, 20.911262872000066],
              [106.91525865900006, 20.910848360000053],
              [106.90808474600006, 20.910813020000063],
              [106.90470550500005, 20.909294187000071],
              [106.90152982200001, 20.9070252530001],
              [106.89894352900008, 20.906261312000041],
              [106.89694340000005, 20.907565687000051],
              [106.89750619200008, 20.913764687000125],
              [106.89783277400001, 20.917085182000058],
              [106.89631120300007, 20.917410313000047],
              [106.89201430700007, 20.918803108000091],
              [106.8902907130001, 20.920208755000047],
              [106.88727233100012, 20.923022170000038],
              [106.88640184500012, 20.925240244000065],
              [106.88574579900002, 20.927459379000091],
              [106.88594293799999, 20.930490971000012],
              [106.88636723800002, 20.931301264000091],
              [106.88731544900004, 20.931604343000032],
              [106.88560495900001, 20.932967635000033],
              [106.88461173500006, 20.933544206000043],
              [106.88324795500003, 20.934002587000045],
              [106.88201057800005, 20.933996330000056],
              [106.88095455000007, 20.933990982000061],
              [106.88122615800008, 20.933560413000052],
              [106.88123244100007, 20.932465076000106],
              [106.8806552830001, 20.931731902000102],
              [106.87757153300002, 20.928795248000043],
              [106.87529043900003, 20.928912652000072],
              [106.87378786400005, 20.928601302000068],
              [106.87271861800004, 20.927684767000088],
              [106.87186661400007, 20.926364410000083],
              [106.87166108400002, 20.924743650000011],
              [106.87168685100002, 20.920289554000071],
              [106.87142191200005, 20.910367322000042],
              [106.87132442900005, 20.908645833000072],
              [106.869934734, 20.907423910000134],
              [106.86489859700005, 20.905069694000034],
              [106.85997001900002, 20.902715899000057],
              [106.85707394900002, 20.901891045000106],
              [106.85428058200009, 20.901876575000067],
              [106.8526672400001, 20.902171913000075],
              [106.84937760000005, 20.903804331000053],
              [106.84531127200005, 20.906974295000055],
              [106.84007854099998, 20.903964370000061],
              [106.84112250000003, 20.899031056000084],
              [106.84075343400005, 20.895925379000069],
              [106.83980423400003, 20.89263406900006],
              [106.83981617700003, 20.890625806000074],
              [106.84021994100004, 20.887889301000051],
              [106.84277323900001, 20.882060236000122],
              [106.845901086, 20.877329570000121],
              [106.84942376500005, 20.87132281600006],
              [106.85411069000007, 20.864956831000114],
              [106.857613653, 20.862236240000072],
              [106.86091854400007, 20.860244888000068],
              [106.86267260900007, 20.858428091000022],
              [106.86404347600008, 20.855878963000052],
              [106.86660729900002, 20.848040931000078],
              [106.86750795800003, 20.843899897000107],
              [106.86929483100008, 20.838937317000017],
              [106.86981389800005, 20.83787289600005],
              [106.86990346300009, 20.836922522000023],
              [106.86956954400007, 20.836524639000054],
              [106.86898301300005, 20.836204685000048],
              [106.86780673000005, 20.836119423000099],
              [106.86650148600006, 20.836014820000059],
              [106.86555540900012, 20.835694573000026],
              [106.86489149000008, 20.83479756900006],
              [106.86479558100005, 20.833568398000054],
              [106.86505141800008, 20.832935826000032],
              [106.86564208600001, 20.832542675000099],
              [106.86057081100007, 20.829886797000086],
              [106.85234154400007, 20.825954365000051],
              [106.84783611300006, 20.824091122000077],
              [106.84745078800006, 20.823248066000041],
              [106.84600704000006, 20.822241776000091],
              [106.84178366400002, 20.819854209000077],
              [106.84027109100002, 20.821055280000067],
              [106.83428900600003, 20.829458435000078],
              [106.83076049700003, 20.834861952000061],
              [106.82995893900005, 20.836877713000042],
              [106.82983405500002, 20.838897042000056],
              [106.83015072800004, 20.842513437000051],
              [106.83067705200006, 20.848788775000045],
              [106.83066877099999, 20.850170811000091],
              [106.82998695200007, 20.851017705000082],
              [106.82840538200003, 20.851434576000116],
              [106.82558186700001, 20.852057459000051],
              [106.8209498680001, 20.853308535000089],
              [106.81946649700011, 20.853953829000105],
              [106.81713519700003, 20.852814926000107],
              [106.81342845500002, 20.851003999000014],
              [106.81174773900005, 20.850025999000096],
              [106.80945148000004, 20.849704159000026],
              [106.80671559500007, 20.849689393000048],
              [106.79883689500011, 20.849543526000055],
              [106.79697522300008, 20.849739701000125],
              [106.79521788900007, 20.850761685000037],
              [106.79423169100001, 20.850962621000058],
              [106.79335748700007, 20.850751528000089],
              [106.7924851920001, 20.850230979000095],
              [106.78714464400008, 20.846694391000042],
              [106.78452851700007, 20.845029495000041],
              [106.78277952400005, 20.844710386000109],
              [106.78085300400008, 20.84528445100009],
              [106.77753145100004, 20.841340597000091],
              [106.77611495300009, 20.839658643000099],
              [106.77288258600007, 20.835820362000042],
              [106.78105689000007, 20.826462892000031],
              [106.78335080500007, 20.824325563000023],
              [106.78635479100011, 20.822595240000027],
              [106.78849773000005, 20.821800762000109],
              [106.79135412700003, 20.820875794000045],
              [106.79691684400004, 20.8202343],
              [106.80289804300004, 20.821073058000117],
              [106.80432249600004, 20.821215151000061],
              [106.80617535200004, 20.821225177000088],
              [106.80746139100005, 20.820694628000069],
              [106.80775054900008, 20.82002431600003],
              [106.80733282700007, 20.818409560000092],
              [106.80408107700005, 20.814091950000076],
              [106.80081956500001, 20.811386740000067],
              [106.79897340800002, 20.810301691000085],
              [106.79584211800002, 20.809612750000014],
              [106.79142401800004, 20.809588609000059],
              [106.7858640840001, 20.809826857000054],
              [106.77915894800006, 20.810864898000055],
              [106.77615422700005, 20.812729527000059],
              [106.77400964900006, 20.813792640000095],
              [106.7730128430001, 20.813652727000061],
              [106.77244698, 20.812977699000079],
              [106.77245965600008, 20.81096212900011],
              [106.77147046800012, 20.809612856000022],
              [106.77005120300001, 20.808664313000058],
              [106.76933860600012, 20.808660342000081],
              [106.76776751500006, 20.809189088000046],
              [106.76547445700005, 20.811191935000025],
              [106.76335155300009, 20.813197032000105],
              [106.76109541700002, 20.81178521800009],
              [106.75805986500004, 20.815875818000059],
              [106.75618814100007, 20.818826966000081],
              [106.75338434400007, 20.822643901000056],
              [106.75234965000007, 20.825077065000031],
              [106.75204664700006, 20.828995169000031],
              [106.75199638100007, 20.834154448000049],
              [106.7518927240001, 20.83857596800005],
              [106.751961232, 20.843122373000078],
              [106.75174157300003, 20.847721116000081],
              [106.75222524, 20.85060125600009],
              [106.75200642600001, 20.851346633000034],
              [106.75191798800006, 20.851636807000062],
              [106.75175504, 20.851973066000085],
              [106.75159224000002, 20.852286073000045],
              [106.75018541500006, 20.85310323900007],
              [106.74809216600006, 20.853728666000038],
              [106.73978881100004, 20.856804103000037],
              [106.73281388700008, 20.860092422000065],
              [106.72254048000003, 20.867329689000066],
              [106.72138539100004, 20.868309983000088],
              [106.72159806100004, 20.868675383000031],
              [106.72253542800007, 20.869271123000118],
              [106.72347860300003, 20.86898142200004],
              [106.72505248400005, 20.868203414000064],
              [106.72745593900005, 20.868512438000053],
              [106.72891367200005, 20.869504694000085],
              [106.73006216200004, 20.869806463000096],
              [106.73173228300003, 20.870307994000115],
              [106.73391257100003, 20.872780152000111],
              [106.73869037200002, 20.877923533000065],
              [106.74233379800003, 20.880600695000062],
              [106.74410989799999, 20.880905958000092],
              [106.74881386, 20.881326190000046],
              [106.752134993, 20.881563030000017],
              [106.75214184000002, 20.882153246000087],
              [106.75237526700006, 20.884650265000104],
              [106.75342200700007, 20.886754420000031],
              [106.75509772500008, 20.890197940000022],
              [106.76155658700007, 20.900513599000092],
              [106.76658509800002, 20.910843758000027],
              [106.76806822500008, 20.915250397000037],
              [106.76853355600012, 20.920052090000048],
              [106.76809193300008, 20.941901578000085],
              [106.76801296200003, 20.945149845000088],
              [106.76788144600008, 20.950559094000099],
              [106.76785455600007, 20.95481486500006],
              [106.76734355600007, 20.961709480000046],
              [106.76666463800004, 20.970510856000054],
              [106.7664669910001, 20.972700740000057],
              [106.76609505900007, 20.975210649000076],
              [106.76600703100007, 20.975936974000042],
              [106.76590328600001, 20.976318121000048],
              [106.76536889100002, 20.978281408000043],
              [106.76442374700007, 20.979890380000121],
              [106.76190733100005, 20.983545016000079],
              [106.75703940400007, 20.989387617000062],
              [106.75311743400002, 20.993474433000081],
              [106.75014347700005, 20.995512084000012],
              [106.74560734300007, 20.998127807000074],
              [106.74029569900006, 21.000152025000048],
              [106.73748780100009, 21.000576245000069],
              [106.73452690900007, 21.000559316000086],
              [106.73203639000005, 21.000104806000053],
              [106.72923708400008, 20.999208264000032],
              [106.7262810070001, 20.998457519000091],
              [106.72410028000004, 20.99829819600005],
              [106.72191859400002, 20.998285594000073],
              [106.72084672400005, 20.99835145900002],
              [106.71973594499998, 20.998419710000071],
              [106.71270885700005, 21.00058008000012],
              [106.70896295500009, 21.001438702000108],
              [106.70568648400007, 21.002006518000101],
              [106.70225512800002, 21.002426630000045],
              [106.69679099800008, 21.003861933000053],
              [106.69164044400007, 21.005005454000077],
              [106.68586250600002, 21.006732105000125],
              [106.68070485100004, 21.008902536000029],
              [106.67429633300004, 21.01165230800008],
              [106.66507736400003, 21.015118637000093],
              [106.66004235900007, 21.016970028000067],
              [106.65609278100004, 21.018422194000053],
              [106.65582293600001, 21.018454778000084],
              [106.64987199400012, 21.020453926000116],
              [106.64676789100005, 21.020556689],
              [106.64457398600008, 21.01993453500004],
              [106.64251363200005, 21.018704461000048],
              [106.63943419500006, 21.015276711000027],
              [106.63724717200003, 21.013680571000037],
              [106.63479392800009, 21.013178455000059],
              [106.63040208900006, 21.012542577000112],
              [106.62652317600002, 21.012518514000078],
              [106.62367606200009, 21.012866036000055],
              [106.62069361400005, 21.01406489500004],
              [106.61588105500003, 21.018052358000091],
              [106.60985597800004, 21.022247934000049],
              [106.60963744300008, 21.022400104000027],
              [106.60768816000009, 21.024575033000033],
              [106.60676997800006, 21.026395374000018],
              [106.60623099000006, 21.029435520000028],
              [106.60556876700005, 21.031622681000073],
              [106.60439191900012, 21.033441368000091],
              [106.60152262100003, 21.036831961000054],
              [106.59800840500006, 21.039974937000046],
              [106.59345952700009, 21.043111252000109],
              [106.58488216300005, 21.048899954000078],
              [106.58189006800011, 21.051315524000103],
              [106.57784909000006, 21.055672082000051],
              [106.57511722800007, 21.057845740000047],
              [106.57317287300005, 21.058441883000079],
              [106.56993968500005, 21.058420999000035],
              [106.56334579400007, 21.058134794000068],
              [106.55998598900008, 21.057747731000021],
              [106.55766351300012, 21.057002182000062],
              [106.55573354800003, 21.055650490000041],
              [106.55380540600002, 21.054055320000131],
              [106.55199757900002, 21.053678286000022],
              [106.55018523100007, 21.053909881],
              [106.54694305400004, 21.055105968000106],
              [106.54383743500003, 21.05532901],
              [106.54138208500009, 21.0550693590001],
              [106.53803477200006, 21.053918443000086],
              [106.53560155200009, 21.05350187100008],
              [106.53446291700003, 21.050882948000016],
              [106.53344612000009, 21.049612835000083],
              [106.53210963400009, 21.048860799000032],
              [106.53029556200001, 21.048625807000043],
              [106.52784600400001, 21.048906777000106],
              [106.52618477600005, 21.049341607000116],
              [106.5248370690001, 21.050075789000019],
              [106.52372450700005, 21.05103447300003],
              [106.52324857500003, 21.051328558000115],
              [106.52238073300008, 21.05124845000006],
              [106.52104202200007, 21.050793598000084],
              [106.51718834800008, 21.04883557000008],
              [106.51569743800007, 21.047636502000074],
              [106.51459560700005, 21.047183206000049],
              [106.51364656700012, 21.047399782000028],
              [106.51309055100003, 21.047841941000023],
              [106.5129292610001, 21.048286753000056],
              [106.51346770600006, 21.05014828500007],
              [106.51424696400005, 21.05149121500007],
              [106.51542041300007, 21.052911096000045],
              [106.51699087400007, 21.054036369000073],
              [106.51738165700007, 21.054559201000075],
              [106.51737769900011, 21.055079385000042],
              [106.51632421600007, 21.058639457000083],
              [106.51583636900003, 21.060494064000054],
              [106.51520079500003, 21.06101000400011],
              [106.51425226500005, 21.061152263000068],
              [106.50597270800006, 21.059758813000059],
              [106.49759293200002, 21.057127030000089],
              [106.49644434600002, 21.059773957000068],
              [106.49444654800001, 21.062813298000016],
              [106.49175078700007, 21.064918698000042],
              [106.48905702500012, 21.066758606000043],
              [106.48563819600004, 21.068529924000053],
              [106.48539994500004, 21.068653364000056],
              [106.480997164, 21.069490821000066],
              [106.47577312500005, 21.070251281000104],
              [106.47267063100003, 21.070229878000113],
              [106.47070048200008, 21.069685327000087],
              [106.46662234200008, 21.068197008000041],
              [106.4588798410001, 21.066417641000079],
              [106.45479343000002, 21.065990921000029],
              [106.45343467800005, 21.066119574000105],
              [106.45334600100009, 21.069432933000073],
              [106.45291798600002, 21.070055669000084],
              [106.45078375900009, 21.07173589900011],
              [106.44431591300007, 21.075862617000048],
              [106.43974504300006, 21.078195935000018],
              [106.438853431, 21.079413025000093],
              [106.43887109400004, 21.080708027000036],
              [106.43910601700003, 21.081562932000075],
              [106.44058657700005, 21.084923629000052],
              [106.44281994800011, 21.089277268000025],
              [106.44334878400007, 21.090244962000071],
              [106.44402578500004, 21.090984175000038],
              [106.44795620300007, 21.093582379000075],
              [106.44863359900009, 21.094275672000059],
              [106.44887273000006, 21.094874090000104],
              [106.44891676600007, 21.095471140000122],
              [106.44876604000004, 21.096020920000043],
              [106.44787670900007, 21.097437670000119],
              [106.44728698200004, 21.097984366000055],
              [106.44633614300007, 21.098462943000037],
              [106.44361194900009, 21.099781362000058],
              [106.44009390700006, 21.103404495000078],
              [106.43787379800004, 21.106307140000077],
              [106.43760268200008, 21.107885974000062],
              [106.43772312600001, 21.108981178000086],
              [106.43861696000006, 21.110325044000078],
              [106.44028323100008, 21.112039123000059],
              [106.44066311299999, 21.113014559000121],
              [106.44004853600003, 21.114889181000059],
              [106.43928589500008, 21.117647247],
              [106.44013500900007, 21.118196475000062],
              [106.44101688200004, 21.118574694000088],
              [106.44216931600009, 21.118866243000056],
              [106.44397402800011, 21.119209603000044],
              [106.44502831800003, 21.119217016000036],
              [106.44693834000006, 21.118947010000078],
              [106.45020537900011, 21.118497534000099],
              [106.45186512200003, 21.118131264000084],
              [106.45402839700006, 21.117579528000086],
              [106.45709757300007, 21.116750667000069],
              [106.45875654100011, 21.116478798000045],
              [106.46001088600006, 21.116582010000059],
              [106.46962772900005, 21.119483037000109],
              [106.47052658800008, 21.120103343],
              [106.47087208700006, 21.120861536000035],
              [106.47096100800005, 21.122326528000102],
              [106.47135709100004, 21.123037829000069],
              [106.47190416300002, 21.123702933000033],
              [106.47320357600006, 21.124467713000072],
              [106.47615389600001, 21.125999650000082],
              [106.47808000800003, 21.126886822000031],
              [106.47978186900008, 21.127559842000032],
              [106.481680877, 21.128706578000056],
              [106.48360519500005, 21.130685035000063],
              [106.48289960000002, 21.132209577000083],
              [106.48240423000007, 21.133034166000094],
              [106.48107122900009, 21.134680970000105],
              [106.48079604200007, 21.135484063000028],
              [106.48088736900007, 21.136312663000112],
              [106.48194293700001, 21.138895826000059],
              [106.48250887400012, 21.141567606000052],
              [106.48249740600005, 21.143039474000069],
              [106.48228820600006, 21.144785960000064],
              [106.48189059200003, 21.145611192000047],
              [106.48090838100003, 21.146156433000037],
              [106.47885190000005, 21.146510281000097],
              [106.47826084100008, 21.147058198000046],
              [106.47815801800002, 21.147701451000067],
              [106.47881956500004, 21.150649851000125],
              [106.48053318700008, 21.156641269000076],
              [106.48109485100009, 21.159864911000078],
              [106.4814803030001, 21.160603507000083],
              [106.48293294200003, 21.162453334000105],
              [106.48564841300001, 21.165415709000079],
              [106.48710327100011, 21.166989533000091],
              [106.48729315600011, 21.167726764000072],
              [106.48738595600005, 21.168371344000022],
              [106.48708111500005, 21.169841131000041],
              [106.48598382700004, 21.172593388000038],
              [106.48528631800006, 21.174244454000046],
              [106.48528059300003, 21.174980348000091],
              [106.48595092500007, 21.176824754000023],
              [106.48720096500003, 21.179593020000098],
              [106.48787204600004, 21.181345418000049],
              [106.4881604710001, 21.181991320000023],
              [106.48883942200005, 21.182731880000105],
              [106.49049503700009, 21.183663075000055],
              [106.4931252210001, 21.18506084600007],
              [106.49400053400005, 21.185710730000018],
              [106.49429040900006, 21.186172645000042],
              [106.49438181800008, 21.187001167000027],
              [106.49416414100008, 21.18985134800004],
              [106.49386002800006, 21.191229113000084],
              [106.49333750400008, 21.195549004000021],
              [106.49252648900004, 21.199222985000077],
              [106.4927150020001, 21.200144141000088],
              [106.49329763900008, 21.200700028000071],
              [106.49485988600001, 21.201078606000046],
              [106.49759595400009, 21.201465142000053],
              [106.49856846600008, 21.20220763000011],
              [106.49914545200005, 21.203499358000052],
              [106.49912843200005, 21.2057069190001],
              [106.49866763000003, 21.214718380000086],
              [106.49863215800012, 21.219317363000108],
              [106.49919075700006, 21.22300050300003],
              [106.49952394500009, 21.225476055000044],
              [106.50251632300007, 21.224094630000053],
              [106.50625270400003, 21.222893398000053],
              [106.50960561400008, 21.223091209000074],
              [106.51146372500008, 21.223804557000037],
              [106.51313015600002, 21.225217439000055],
              [106.51517331500006, 21.226107209000041],
              [106.51740684800004, 21.226472594000079],
              [106.52094883500008, 21.226321080000062],
              [106.52300267700011, 21.225809160000075],
              [106.524687801, 21.224769122000083],
              [106.52656189900009, 21.223379909000073],
              [106.52787560300007, 21.222162166000125],
              [106.52976157300006, 21.219196089000022],
              [106.53146113400008, 21.216228745000087],
              [106.53431165600003, 21.208888605000098],
              [106.53678009, 21.202772346000032],
              [106.53848597800001, 21.198928811000073],
              [106.53943863500005, 21.196131585000089],
              [106.54019836700012, 21.194209175000097],
              [106.54226232400001, 21.192295350000023],
              [106.54449814, 21.19231006299999],
              [106.54617238200005, 21.192671512000061],
              [106.54970199500005, 21.194096423000097],
              [106.55342187700009, 21.194996869000079],
              [106.55677825000006, 21.194668341000074],
              [106.55995476200007, 21.193462488000044],
              [106.56145438500008, 21.192245686],
              [106.56333437200001, 21.189980024000121],
              [106.56408351500001, 21.189459222000018],
              [106.56520140300005, 21.189466471000081],
              [106.566503036, 21.18982534400002],
              [106.57003402300003, 21.191074732000104],
              [106.57356378100006, 21.192499275000017],
              [106.57709870300006, 21.193222910000117],
              [106.57896189200004, 21.193234879000045],
              [106.58120411500002, 21.192373156000066],
              [106.58457057700011, 21.190642501000042],
              [106.58663150700002, 21.189078682000059],
              [106.58813982300003, 21.186635193000122],
              [106.58870891500004, 21.185237049000079],
              [106.58853023400009, 21.184184566000042],
              [106.58760503300002, 21.183302554000072],
              [106.58649734700002, 21.181893693000028],
              [106.58650752300007, 21.180491964000048],
              [106.58707153200001, 21.179794667000081],
              [106.58800688000004, 21.179274964000072],
              [106.58967270900003, 21.179510628000031],
              [106.59605727000009, 21.180266654000057],
              [106.60168325100008, 21.180731522000059],
              [106.60366206100004, 21.180600933000093],
              [106.60472907000003, 21.180321490000061],
              [106.60564701700005, 21.179611839000053],
              [106.60641896100006, 21.178042745000077],
              [106.60721238600007, 21.173468922],
              [106.60737168600011, 21.172468305000081],
              [106.60783216700001, 21.171898850000034],
              [106.60920133800006, 21.171907458000035],
              [106.614522847, 21.172370103000034],
              [106.62578051700004, 21.172440290000068],
              [106.63003716200006, 21.172895947000114],
              [106.63140734800001, 21.172761332000078],
              [106.63277853600005, 21.172483631000105],
              [106.63446301400006, 21.170920057000053],
              [106.63568907800008, 21.169639809000081],
              [106.63614546000009, 21.169642622000048],
              [106.636903096, 21.170076560000069],
              [106.63765674100009, 21.17108282300002],
              [106.63825625600003, 21.172374309000119],
              [106.63855150500004, 21.173663919000063],
              [106.6385435010001, 21.174808578000089],
              [106.63837836500002, 21.176667699000085],
              [106.63730041000009, 21.178521204000056],
              [106.63683898800011, 21.17923379900013],
              [106.6371362510001, 21.180237236000018],
              [106.63865062200007, 21.181248161],
              [106.63971558499999, 21.181254709000093],
              [106.6423059200001, 21.18069826100006],
              [106.64383227200008, 21.179992185000081],
              [106.64754569300003, 21.176545049000062],
              [106.6493016900001, 21.175625701000023],
              [106.65074843700008, 21.175419892000043],
              [106.65424750700004, 21.175441195000033],
              [106.65473431900008, 21.175375544000104],
              [106.655770316, 21.17523582600009],
              [106.65744624800003, 21.174888273000114],
              [106.65942840900007, 21.174256398000054],
              [106.6629367940001, 21.172918278000097],
              [106.66613791800003, 21.172007526000058],
              [106.66948821500003, 21.17152688200008],
              [106.67154295400005, 21.171396132],
              [106.67458555800008, 21.171414365000096],
              [106.67587817500001, 21.17149364400008],
              [106.67709522100007, 21.171500922000064],
              [106.67763010600001, 21.171146401000037],
              [106.67816595900003, 21.17064879200008],
              [106.678781761, 21.169579304000052],
              [106.67962477600003, 21.168654254000074],
              [106.68000703100003, 21.168370356000118],
              [106.68122405700009, 21.1683776120001],
              [106.68479712900006, 21.168685051000047],
              [106.68760860700007, 21.169131011000083],
              [106.69003736700003, 21.169932388000042],
              [106.69208630900002, 21.170659951000026],
              [106.69368078900004, 21.171098628],
              [106.69451798400004, 21.171032026000077],
              [106.69528054900007, 21.170750346000133],
              [106.69619907900004, 21.169897222000067],
              [106.69727116100002, 21.16883037000008],
              [106.69841595000005, 21.168264751],
              [106.70016542300004, 21.16827503300005],
              [106.70183740000009, 21.168499482000058],
              [106.70358162600007, 21.169296694000117],
              [106.70449440100002, 21.169302039000023],
              [106.70495173600003, 21.169161623000043],
              [106.70564012300005, 21.168593296],
              [106.70786307200004, 21.166030679000066],
              [106.71086553000004, 21.163151905000085],
              [106.71262121000008, 21.161987752000044],
              [106.71350523500004, 21.161401561000034],
              [106.71801621300007, 21.160544888000032],
              [106.72045882600003, 21.160205878000049],
              [106.72233597300009, 21.160216724000058],
              [106.72439966800003, 21.160405197000017],
              [106.72871019200008, 21.161489428000024],
              [106.73058619100006, 21.16167676400007],
              [106.73190020700007, 21.161684297000072],
              [106.73340422500007, 21.161339774000076],
              [106.73604255800005, 21.159765718000045],
              [106.74112339500007, 21.157852395000099],
              [106.74713586500008, 21.157003661000125],
              [106.75239518000002, 21.156503657000059],
              [106.75558626600009, 21.156521624000035],
              [106.75914827200003, 21.157247911000084],
              [106.76308909400004, 21.157446541000091],
              [106.7649684560001, 21.15710389900007],
              [106.76629026300006, 21.155875269000113],
              [106.76761541500009, 21.154116928000022],
              [106.76912154400001, 21.153419022],
              [106.77025002800004, 21.153072155000082],
              [106.77719852900003, 21.15258092100003],
              [106.78283526700004, 21.151729135000075],
              [106.7875345300001, 21.150695489000078],
              [106.78960257600006, 21.150177089000088],
              [106.79129192600004, 21.15018632300005],
              [106.7931667820001, 21.150549706],
              [106.79578920000009, 21.151446855000074],
              [106.799528095, 21.153939189000056],
              [106.80195961100002, 21.155364942000077],
              [106.80383563099998, 21.155551659000068],
              [106.80965465100006, 21.155583036000088],
              [106.81284142100003, 21.15630644],
              [106.81696678000004, 21.157034817000039],
              [106.81846847900005, 21.157042840000081],
              [106.82072529100007, 21.156348593000104],
              [106.827311138, 21.153735013000038],
              [106.83031978000007, 21.152868068000075],
              [106.8312572560001, 21.153049600000081],
              [106.83162843400008, 21.153757849000066],
              [106.83162313700009, 21.154640678000099],
              [106.82954241100006, 21.157278256000019],
              [106.82595246400003, 21.161143793000022],
              [106.82462353800003, 21.163608717000045],
              [106.82442302900004, 21.165726477000035],
              [106.82430363200002, 21.18550119800009],
              [106.82466738500001, 21.187445317000098],
              [106.82560076300007, 21.188333087000011],
              [106.82635066400012, 21.188513623000027],
              [106.82728938200006, 21.188518599000083],
              [106.82973320600003, 21.188001845000045],
              [106.83536968400001, 21.187325326000028],
              [106.83949999400008, 21.187347020000047],
              [106.84212732100004, 21.187537339000073],
              [106.84381593900004, 21.187722734000047],
              [106.84662578700009, 21.188796758000095],
              [106.8503754230001, 21.189699069000056],
              [106.85300383300006, 21.18971270400003],
              [106.85469664400006, 21.189191793000091],
              [106.855640545, 21.188313861000019],
              [106.85621206700003, 21.186904321],
              [106.85661244200003, 21.182668878000044],
              [106.85719741300002, 21.178964053000101],
              [106.85795455700006, 21.177908579000082],
              [106.85889942299998, 21.17685405900005],
              [106.86209394600007, 21.176340808000035],
              [106.86942262399999, 21.175142406000091],
              [106.87197250700004, 21.175294895],
              [106.87356554900003, 21.176473314000077],
              [106.87465864400006, 21.17870039700005],
              [106.87558914900005, 21.180117639000073],
              [106.87764099900009, 21.18242337700007],
              [106.87932249300005, 21.183844410000049],
              [106.88138457000004, 21.184384522000066],
              [106.88326194900003, 21.184394002000047],
              [106.88608610800003, 21.182995731000084],
              [106.88947847700004, 21.180717456000053],
              [106.89079462900006, 21.180370924000066],
              [106.89173329900009, 21.180375626000014],
              [106.89791755000002, 21.182348705000017],
              [106.90466713600007, 21.183971282000066],
              [106.91310943500004, 21.185072268000035],
              [106.91461135400006, 21.185079638000026],
              [106.91555201400008, 21.184731129000056],
              [106.91724854200004, 21.183503494],
              [106.92103167100005, 21.178401622000109],
              [106.92574935100004, 21.174010441000043],
              [106.9302703550001, 21.171207284000012],
              [106.93234115300002, 21.170157879000051],
              [106.93346749900005, 21.170163310000056],
              [106.934965441, 21.170876790000115],
              [106.93889517000007, 21.173191031000016],
              [106.94113830600004, 21.174967432000088],
              [106.94299930300009, 21.177977926000047],
              [106.94429524800003, 21.181338824000036],
              [106.94538818800004, 21.187523726],
              [106.94556827900003, 21.188937073000069],
              [106.94650221500002, 21.189824322000028],
              [106.94819096200004, 21.190008911000021],
              [106.95213073700006, 21.190557281000011],
              [106.95344116000004, 21.191269722000072],
              [106.95418739700006, 21.192156056000044],
              [106.95437040100009, 21.193039717000062],
              [106.95434290200004, 21.198159802000035],
              [106.95450505300005, 21.202927620000089],
              [106.95448703800007, 21.206282104000081],
              [106.95504083900006, 21.208050277000012],
              [106.95597491899998, 21.208937457000054],
              [106.95784592200008, 21.210182158000102],
              [106.95972073200002, 21.210720641000066],
              [106.96084260200004, 21.21160867800009],
              [106.96233061100006, 21.214263964000054],
              [106.96474811900006, 21.218689084000083],
              [106.96641463500002, 21.223110657000085],
              [106.967157306, 21.224703073000036],
              [106.96790560700001, 21.225236214000098],
              [106.96884356900009, 21.225417132000054],
              [106.97072134800001, 21.225425870000066],
              [106.97259820400006, 21.225611144000119],
              [106.97522153000004, 21.226682594000046],
              [106.97634356200007, 21.227570538000059],
              [106.97690226200011, 21.228455863000036],
              [106.97707983700005, 21.230398719000029],
              [106.97590855400004, 21.238867569000064],
              [106.97589647800002, 21.24116260000006],
              [106.97718969099999, 21.245229100000081],
              [106.97885301100004, 21.250356528000047],
              [106.98071717100001, 21.253013245000126],
              [106.981847499, 21.254894030000045],
              [106.98220668100006, 21.255491674000027],
              [106.98355468800004, 21.258101823000075],
              [106.98354085700005, 21.26074985200006],
              [106.98287151500008, 21.263041788000066],
              [106.98153878900004, 21.26647815000009],
              [106.98096840300011, 21.267799556000085],
              [106.98030825700002, 21.268326130000048],
              [106.97965043100002, 21.268411367000084],
              [106.97824178100009, 21.26840487300008],
              [106.97673457500008, 21.269280594000058],
              [106.97550816200004, 21.270334147000099],
              [106.97390236800005, 21.272092075000067],
              [106.97314176, 21.273853898000091],
              [106.97294555000011, 21.275441808000025],
              [106.97321704700002, 21.277384946000041],
              [106.9733007090001, 21.279327206000076],
              [106.97319794900002, 21.281003790000064],
              [106.97262698800004, 21.282413403000078],
              [106.97177474900005, 21.283733441000052],
              [106.97130002500003, 21.284702168000088],
              [106.97129675800002, 21.28532001400005],
              [106.97195045700002, 21.28602917500011],
              [106.97494236300004, 21.288602742000059],
              [106.97821981400006, 21.290471446000048],
              [106.98149595100006, 21.292604898],
              [106.98505576500007, 21.294386523000064],
              [106.9871193070001, 21.294925556000074],
              [106.9882463770001, 21.294930707000056],
              [106.98890475700003, 21.294757187000087],
              [106.99003320000006, 21.294497545000098],
              [106.99059673400005, 21.294500114000044],
              [106.99153551000011, 21.294592653000066],
              [106.993505144, 21.295131190000035],
              [106.99678698900003, 21.296205252000085],
              [106.99781968400004, 21.296298189000048],
              [106.99847760600002, 21.296212902],
              [106.99894904300004, 21.295861981000108],
              [107.00000007500005, 21.294518128000028],
              [107.00083935700003, 21.29419814900011],
              [107.0027304610001, 21.294348901000056],
              [107.00499738900008, 21.294999127000033],
              [107.00839726700011, 21.29608109400003],
              [107.01735059600007, 21.300363571000069],
              [107.02165389100006, 21.30281583100011],
              [107.02766598000002, 21.308790135000066],
              [107.03080659600001, 21.313670259000084],
              [107.03222636900006, 21.317461384000055],
              [107.03221157399999, 21.320435163000084],
              [107.03104852600003, 21.322863216000073],
              [107.02959771700009, 21.325289992000052],
              [107.03735863600006, 21.326945923000018],
              [107.04368097400004, 21.32859537300007],
              [107.04741635500002, 21.329692845000103],
              [107.04999417600007, 21.332137009000093],
              [107.05256153700003, 21.336743823000056],
              [107.05569404100005, 21.34351568000001],
              [107.05798044900004, 21.346769461000022],
              [107.06009470000001, 21.348348178],
              [107.06212126100009, 21.349090252000025],
              [107.06493428800005, 21.348662050000122],
              [107.06821858900001, 21.347648999000057],
              [107.07196784600006, 21.347371335000084],
              [107.0741547910001, 21.347233780000074],
              [107.07587255300002, 21.347240951000046],
              [107.07727242300004, 21.34842037300011],
              [107.08021658800001, 21.353273637000122],
              [107.08254799100008, 21.355630451000081],
              [107.08582267300005, 21.356670855000104],
              [107.09034398400004, 21.358303121000063],
              [107.091745401, 21.35918904400009],
              [107.09220911900009, 21.360217808000037],
              [107.09313861200008, 21.361835256000106],
              [107.093433863, 21.365503830000065],
              [107.09449566700002, 21.372256044000082],
              [107.09526701000006, 21.37431288200009],
              [107.0961993330001, 21.375343522],
              [107.09838180400003, 21.37623255900003],
              [107.10353518700001, 21.376400158000038],
              [107.10619098800001, 21.376264189000068],
              [107.11025377500009, 21.375840467],
              [107.11588437100002, 21.374102701000105],
              [107.12182988100008, 21.371779268000068],
              [107.12495744800005, 21.370911484000118],
              [107.12777420700004, 21.370115742000095],
              [107.13254032300004, 21.369107641000085],
              [107.1395774170001, 21.367081369000026],
              [107.14457893500003, 21.366220569000035],
              [107.14894735399999, 21.367264238000061],
              [107.15347008900001, 21.368748491000034],
              [107.15659427600005, 21.36861371400007],
              [107.15925564200008, 21.367156894000061],
              [107.16441892100008, 21.364976022000093],
              [107.16738938, 21.364253727000104],
              [107.16941775900005, 21.364701425000106],
              [107.17720537800002, 21.369718037000048],
              [107.18078575100006, 21.372518449000033],
              [107.18436314100003, 21.376052238000028],
              [107.18732388500004, 21.377676717000057],
              [107.19013094000005, 21.378713820000073],
              [107.19325161000005, 21.37945864800006],
              [107.19653395300003, 21.378883810000012],
              [107.19809580600003, 21.378889467000036],
              [107.20043557900009, 21.379631375000052],
              [107.20261557700007, 21.381252812000092],
              [107.20618873300003, 21.385959684000078],
              [107.20805645900003, 21.387579931000019],
              [107.21226892300005, 21.388768443000039],
              [107.21469082100006, 21.388557008000042],
              [107.21782177500005, 21.386807824000087],
              [107.22315458700004, 21.381252413000063],
              [107.22754475400011, 21.377013750000096],
              [107.23365733900005, 21.371607395000055],
              [107.23773470100008, 21.367367368000068],
              [107.23899272600008, 21.365171296000085],
              [107.24570256900004, 21.366661169000075],
              [107.24944853100001, 21.367260660000028],
              [107.25116421700004, 21.367853236000123],
              [107.252719228, 21.369618797],
              [107.25457481300006, 21.374465872000044],
              [107.25720805700007, 21.380195636000018],
              [107.25828793900007, 21.383719794000022],
              [107.25874364900002, 21.387095147000053],
              [107.25888362500005, 21.39134954399999],
              [107.25918484300011, 21.394284279000075],
              [107.26120259900009, 21.397664778000092],
              [107.26291080300011, 21.400310791],
              [107.2602486530001, 21.40206213400004],
              [107.25743024300006, 21.403812917000039],
              [107.25633174800004, 21.405129381000073],
              [107.25522762000007, 21.407912644000056],
              [107.2547438070001, 21.411871427000051],
              [107.25480597000009, 21.41235243900006],
              [107.25519895900001, 21.415393301000037],
              [107.25550128300006, 21.418034561000084],
              [107.25471287800011, 21.419938742000078],
              [107.251729471, 21.423889018000075],
              [107.25063591400003, 21.423885324000125],
              [107.24766825900006, 21.423728600000032],
              [107.24594526300005, 21.424896173],
              [107.244921554, 21.427019519000012],
              [107.24444147500006, 21.429951418000122],
              [107.24442779100008, 21.433471603000065],
              [107.243624955, 21.439042520000037],
              [107.24266982700003, 21.443586144000015],
              [107.24093402300011, 21.447980387000065],
              [107.23935668800007, 21.451788434000015],
              [107.23856224100007, 21.455159127000023],
              [107.23698307400005, 21.459407123000048],
              [107.2365091340001, 21.460725507000063],
              [107.23665904600007, 21.462339372000102],
              [107.23821180700003, 21.464838071000081],
              [107.23968905700005, 21.466676485000043],
              [107.24015152000003, 21.468291402000084],
              [107.23967413700005, 21.470489767000075],
              [107.23794205000009, 21.473857129000073],
              [107.24090129500007, 21.476360576000083],
              [107.24323555100003, 21.47886185],
              [107.24416516700003, 21.480918301000074],
              [107.24447199500001, 21.482385979000092],
              [107.24430945400006, 21.483998720000038],
              [107.24195337900008, 21.487070581000097],
              [107.23991093300002, 21.489850153000042],
              [107.23661589600005, 21.49321202600003],
              [107.23520073800003, 21.495407054000012],
              [107.23503694500006, 21.497313056000067],
              [107.23471572200003, 21.499511825000091],
              [107.23532815400009, 21.502740414000066],
              [107.23602135000007, 21.505309311000076],
              [107.2360109680001, 21.507949085000085],
              [107.23490307600008, 21.511465005000026],
              [107.233797458, 21.514394288000105],
              [107.23221479800006, 21.519375039000096],
              [107.23064310200004, 21.521569392000025],
              [107.229545505, 21.522445498000039],
              [107.227356699, 21.522584534000089],
              [107.22438814100005, 21.522280872000096],
              [107.22298142300009, 21.522275949000047],
              [107.22172748900009, 21.523151468],
              [107.22015093400003, 21.526518919000104],
              [107.21716814200005, 21.529734735000083],
              [107.21434333900001, 21.532511098000029],
              [107.21105547400002, 21.533819282000088],
              [107.206509286, 21.537029339000036],
              [107.20368303100003, 21.540098830000076],
              [107.20266005900002, 21.541781610000044],
              [107.20249952000006, 21.542807559000018],
              [107.20358833400009, 21.54413128700012],
              [107.20717110400005, 21.547223693000106],
              [107.21168894000003, 21.551052559000091],
              [107.21511859700011, 21.553411028000099],
              [107.21870938200003, 21.554596851000071],
              [107.22261463300003, 21.555343789000055],
              [107.2269918210001, 21.55535908000008],
              [107.23090175500005, 21.554932730000061],
              [107.23763363500007, 21.552463015000086],
              [107.241706691, 21.550277297000072],
              [107.24342796800005, 21.54984324100004],
              [107.24467684500009, 21.550287426000025],
              [107.24514068800001, 21.551608812000019],
              [107.24574320700003, 21.557476604000044],
              [107.24683126000008, 21.559093374000057],
              [107.249482648, 21.560715418000065],
              [107.25385094300003, 21.563076417000012],
              [107.25994238700008, 21.564563227000022],
              [107.26259731000002, 21.565305265000042],
              [107.265879807, 21.565462793000023],
              [107.27002354700002, 21.565256502000096],
              [107.27170022800001, 21.565136221],
              [107.27393303800002, 21.564976045000073],
              [107.27658856600002, 21.565571281000118],
              [107.27924083600007, 21.567046316000038],
              [107.28110712200004, 21.569691889000083],
              [107.28187905500005, 21.572333896000103],
              [107.28202292400005, 21.575707042000047],
              [107.28232803000007, 21.577760957000081],
              [107.28341704300003, 21.579230848000108],
              [107.28591104700006, 21.581291792000101],
              [107.28746808300011, 21.583056432000092],
              [107.28870760500007, 21.58613976000003],
              [107.29056669300007, 21.590838007000102],
              [107.29149734500007, 21.592893851000028],
              [107.29227539200005, 21.593922761000073],
              [107.29477287500001, 21.595103773000091],
              [107.30070875000007, 21.596735515000098],
              [107.30445827900007, 21.597627089000056],
              [107.30680266500006, 21.597927686000062],
              [107.30805460800009, 21.597638328000102],
              [107.30947073000002, 21.595149983000091],
              [107.31025878900003, 21.593392847000104],
              [107.31182548900009, 21.592517909],
              [107.31307479600007, 21.592961675000041],
              [107.31557136900004, 21.594435718000057],
              [107.31760146300006, 21.595175128000044],
              [107.32072918700003, 21.59503809300007],
              [107.32636577900011, 21.592855779000075],
              [107.32957694900006, 21.591179218],
              [107.33239541200003, 21.590014642000099],
              [107.33599310600007, 21.589585527000033],
              [107.33849280700004, 21.59017952700006],
              [107.34176832900008, 21.592535403000035],
              [107.34285837100003, 21.593858327000071],
              [107.34504097200005, 21.59577100800005],
              [107.34714737700007, 21.596921598000037],
              [107.34995942500004, 21.597663006000097],
              [107.35300755900009, 21.598140486000084],
              [107.35613189300005, 21.599029360000088],
              [107.35941213200003, 21.600065266000087],
              [107.36052196800003, 21.600674227000027],
              [107.36203239600012, 21.600359993000048],
              [107.36303688300009, 21.600834581000058],
              [107.36529467400004, 21.602609929000046],
              [107.36667643400006, 21.603085576000055],
              [107.36768286600001, 21.602970520000042],
              [107.36995067400007, 21.60167979],
              [107.37297335000005, 21.600273242000064],
              [107.37637362500001, 21.598749784000049],
              [107.37952083400009, 21.597697268],
              [107.38078024100002, 21.597111153000093],
              [107.38153436900008, 21.597231182000073],
              [107.38215938300002, 21.598412185000093],
              [107.38353628600007, 21.600420753000016],
              [107.38642117800002, 21.602787254000063],
              [107.38955616200006, 21.605626093000026],
              [107.39180907900004, 21.60905208700008],
              [107.39306371900003, 21.60999891000008],
              [107.396078663, 21.611068443000114],
              [107.39645520800006, 21.611305314000084],
              [107.39582310300008, 21.612364903000092],
              [107.39405286300007, 21.615426069000065],
              [107.39304049200005, 21.617427981000056],
              [107.39341557200001, 21.618136537000062],
              [107.39492071900003, 21.61943778300008],
              [107.39718158100004, 21.620387305000044],
              [107.40220643500004, 21.62228762900007],
              [107.40547315800005, 21.623357688000056],
              [107.40616325900004, 21.623890186000061],
              [107.40615965100001, 21.625069381000017],
              [107.40590162300006, 21.627191251000077],
              [107.40728149800002, 21.628374136000069],
              [107.410418991, 21.630622956000067],
              [107.41355762600008, 21.63251797],
              [107.41481289000002, 21.633346712000012],
              [107.41518772500004, 21.634173128],
              [107.41543679200004, 21.63499921100005],
              [107.41480471300005, 21.636058808000058],
              [107.41404506400008, 21.63770765200006],
              [107.4141665760001, 21.639122981000099],
              [107.41541867900006, 21.641012953000072],
              [107.41717605900007, 21.642196739000049],
              [107.42106964300008, 21.644093586000054],
              [107.42345424700002, 21.645868529000012],
              [107.42546084500006, 21.647878293000034],
              [107.42847169400004, 21.650598116000097],
              [107.42983006900008, 21.651229749000045],
              [107.43181863000004, 21.652292250000116],
              [107.43478329000001, 21.653641646000032],
              [107.43668615800007, 21.654620882000053],
              [107.43870913400012, 21.655517673000091],
              [107.44122255100007, 21.656349406000075],
              [107.44599854000009, 21.657776331],
              [107.45039688300002, 21.659320128000026],
              [107.45315966000001, 21.66097772300013],
              [107.45654980200003, 21.663226375000107],
              [107.45969138500007, 21.664413158000073],
              [107.46220631400007, 21.664772997000092],
              [107.46434483500008, 21.664778166000076],
              [107.46887573900007, 21.663963667000097],
              [107.471896751, 21.663263401000066],
              [107.47517187300009, 21.661620397000014],
              [107.47806924800003, 21.660094365000049],
              [107.47996088000004, 21.658330112],
              [107.48210814200007, 21.655033559000088],
              [107.48400784000003, 21.650203492000038],
              [107.48416751800002, 21.649770429000093],
              [107.48552821200008, 21.6460799910001],
              [107.48679790200005, 21.641602119000055],
              [107.48806005900003, 21.639954198000041],
              [107.49051849100005, 21.637778385000047],
              [107.492156069, 21.636838797000024],
              [107.49530357400002, 21.635666797000042],
              [107.4946786830001, 21.634132441000098],
              [107.49230405900009, 21.628349003000032],
              [107.49059829700008, 21.623494104000059],
              [107.49194281400004, 21.622014080000085],
              [107.49380362400008, 21.619956968000054],
              [107.49456034400008, 21.61881951],
              [107.49514355200002, 21.617518917000034],
              [107.49543712200006, 21.616109168],
              [107.49543951900009, 21.615186971000085],
              [107.49526755000007, 21.614427122],
              [107.49405364000008, 21.613013935000055],
              [107.4923762060001, 21.611491173],
              [107.4918565230001, 21.610676278000085],
              [107.49171719600011, 21.608533180000045],
              [107.49131404400006, 21.607501546000037],
              [107.49073711200002, 21.606415267000045],
              [107.48969636600006, 21.60532792000005],
              [107.48767103000009, 21.603858553000066],
              [107.48541452800008, 21.602117392000061],
              [107.48396699700004, 21.601408799000055],
              [107.48223016700004, 21.600482536000065],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 49, code: "AD01", ten_tinh: "Quảng Trị" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.07452696600006, 17.108500746000033],
              [107.08319774400012, 17.103782634000098],
              [107.09343909500008, 17.09939498100006],
              [107.09895685300009, 17.096284024000049],
              [107.10825003800002, 17.090270628000056],
              [107.11208504299999, 17.08661200900012],
              [107.11299170500004, 17.084778521000118],
              [107.11312369700005, 17.080348124000096],
              [107.11223579700004, 17.07796689400007],
              [107.110229211, 17.07460840000001],
              [107.10934330800006, 17.071794871000101],
              [107.10969949200006, 17.067473450000122],
              [107.11097232900003, 17.059049037000037],
              [107.11190338900006, 17.051703913000082],
              [107.1129279360001, 17.048574006000102],
              [107.11293513700008, 17.046952937000093],
              [107.11215536000003, 17.04554477700011],
              [107.10980693400006, 17.043373635000059],
              [107.10824360300003, 17.041421857000039],
              [107.10746821900008, 17.039041033000046],
              [107.10793233100003, 17.035692667000113],
              [107.10825412400001, 17.034246492000094],
              [107.10942188600009, 17.028998236000078],
              [107.1101627780001, 17.026515588],
              [107.11017963100008, 17.022733058000128],
              [107.11007506000004, 17.0210034360001],
              [107.10929684100005, 17.019271034000027],
              [107.108178933, 17.018185671000104],
              [107.10689480800008, 17.016613274000036],
              [107.10606292200008, 17.014340261000079],
              [107.105791434, 17.012285716000086],
              [107.10764027300011, 17.013103935000103],
              [107.10921060600002, 17.01343463900011],
              [107.11050388299999, 17.012953643000102],
              [107.11163427300009, 17.011229103000055],
              [107.11435940500006, 17.004323507000052],
              [107.11687737300012, 16.999794669000053],
              [107.12196792100005, 16.991007298000049],
              [107.12730633400005, 16.983193465000035],
              [107.13194090300001, 16.975808943000104],
              [107.13608943000007, 16.970421776000052],
              [107.14224352000006, 16.961908277000056],
              [107.14875908500005, 16.954044466000084],
              [107.15609124700009, 16.945373057000076],
              [107.16582137400012, 16.933414280000122],
              [107.17007673900004, 16.928891419000088],
              [107.17694972500001, 16.922270949000051],
              [107.18616019400012, 16.913335383000039],
              [107.1885848690001, 16.910372358000103],
              [107.18906659800008, 16.909185302000118],
              [107.189183633, 16.90799687400002],
              [107.18904687500005, 16.907131746000026],
              [107.1879322350001, 16.905290208000068],
              [107.19051196100003, 16.905570110000042],
              [107.19236408100006, 16.905414931000081],
              [107.19511606000003, 16.904722712000094],
              [107.19730913700005, 16.903487972000072],
              [107.20007101400003, 16.900309895000106],
              [107.20491934799999, 16.89443750800006],
              [107.21137223300011, 16.887057758000076],
              [107.21835460700008, 16.880436269000128],
              [107.22789912200003, 16.871175768000057],
              [107.23364033200005, 16.866116473000133],
              [107.2445331160001, 16.855941300000055],
              [107.25010378400005, 16.851259225000078],
              [107.25941688300003, 16.843131297000099],
              [107.26723702300004, 16.836619091000046],
              [107.28279319800001, 16.823161191000047],
              [107.29536713700006, 16.811962115000064],
              [107.30636129500004, 16.803135009000059],
              [107.30888018900001, 16.80101907200013],
              [107.31187277800008, 16.798505118000051],
              [107.32488810000007, 16.788494562],
              [107.32966602100007, 16.78499677600005],
              [107.33413647100011, 16.781173680000094],
              [107.33621824400005, 16.779018433000111],
              [107.33846924700005, 16.776539434000078],
              [107.34038165600001, 16.774707875000061],
              [107.34291094800002, 16.772824133000071],
              [107.34967978800009, 16.768953651000103],
              [107.35293761, 16.767126033000054],
              [107.35658939200007, 16.764813168000089],
              [107.36041188100006, 16.761690111000057],
              [107.37139666800007, 16.754048460000078],
              [107.37740799800008, 16.750012754000032],
              [107.38400970900007, 16.745330057],
              [107.38619085200006, 16.74369454700004],
              [107.38389771500005, 16.741734618000059],
              [107.38198681600002, 16.740691960000028],
              [107.37909577000001, 16.739601430000036],
              [107.37546137600003, 16.737809571000106],
              [107.37250699300002, 16.73654221500005],
              [107.36844859700004, 16.735110024000029],
              [107.36529562800011, 16.73014778400006],
              [107.36429581300007, 16.728502952000035],
              [107.36210386100008, 16.724505662000055],
              [107.35953891700004, 16.720039367000084],
              [107.35606880900008, 16.714134090000066],
              [107.35422984100006, 16.710716941000037],
              [107.35275978900002, 16.71178010000007],
              [107.35024866800008, 16.708758282000069],
              [107.35044904500005, 16.707886879000085],
              [107.35181673500006, 16.706578849000039],
              [107.35232383500001, 16.706168174000076],
              [107.35306201200007, 16.705619895000012],
              [107.3537869420001, 16.705174363000097],
              [107.355881283, 16.703843605000124],
              [107.35592798000005, 16.70266001300007],
              [107.35579178100006, 16.701537649000016],
              [107.35535102300001, 16.700537071],
              [107.35466727600001, 16.699827661000107],
              [107.35389517600008, 16.699785095000053],
              [107.35165253400007, 16.699432050000063],
              [107.3456459610001, 16.694957761000047],
              [107.33973077100006, 16.689353898000014],
              [107.33452043500009, 16.684297002000022],
              [107.33184860400007, 16.680610261000069],
              [107.32960340600003, 16.676107360000088],
              [107.32933046400009, 16.673245474000083],
              [107.32976520300009, 16.669977068000016],
              [107.32874941100008, 16.66748750900009],
              [107.32719741600009, 16.666937713000074],
              [107.32465525000006, 16.666793515000045],
              [107.32225342000002, 16.666922211000035],
              [107.32041939100006, 16.666235228000033],
              [107.31788327600002, 16.664319842000097],
              [107.31718141800012, 16.663023335000027],
              [107.31718536900009, 16.661865297000041],
              [107.31768533300007, 16.660232],
              [107.31973835200003, 16.658807961000029],
              [107.32080282500009, 16.657312680000032],
              [107.32109040600008, 16.655814936000098],
              [107.32053049700002, 16.65431451800012],
              [107.31933290900005, 16.653425174000077],
              [107.31735822500009, 16.652601477000047],
              [107.314801104, 16.651724827000081],
              [107.31395736600008, 16.65063221000009],
              [107.31184640800004, 16.648377498000052],
              [107.30624838400004, 16.643625162000127],
              [107.30450733300005, 16.641661063000065],
              [107.30566383800007, 16.639093256000059],
              [107.30715201500001, 16.637599399000088],
              [107.30842431800004, 16.637262880000044],
              [107.30913049700003, 16.637265147000058],
              [107.30983527600007, 16.637676132000045],
              [107.31046849200007, 16.638359370000089],
              [107.3107479120001, 16.639245831000068],
              [107.31074110800006, 16.641221308000098],
              [107.31123146100005, 16.642380935000034],
              [107.31207655400007, 16.643064844000044],
              [107.31327733000003, 16.643000552000053],
              [107.31424993100003, 16.642543835000097],
              [107.31511811400001, 16.641643999000074],
              [107.31549771699999, 16.640251389000049],
              [107.31546305200004, 16.638869180000079],
              [107.31493760800001, 16.637641334000094],
              [107.31344357200003, 16.635712166000083],
              [107.31151084900007, 16.633015224000047],
              [107.31018158000003, 16.629400562000015],
              [107.30983390300005, 16.627832668000053],
              [107.30983719100006, 16.626878983000012],
              [107.31005256300003, 16.625857862000082],
              [107.31076222100003, 16.624838313000097],
              [107.31246046100003, 16.623821930000112],
              [107.31659563600006, 16.62257485600005],
              [107.31857581900003, 16.621695561000124],
              [107.31985054800008, 16.620609653000074],
              [107.32070228300003, 16.61931804300005],
              [107.32148455200009, 16.617685600000073],
              [107.32269393200005, 16.615032683000038],
              [107.3243980070001, 16.61224506800005],
              [107.325958746, 16.610070085000039],
              [107.32651212600005, 16.609619259000041],
              [107.32402091600008, 16.604095226000126],
              [107.32361156900004, 16.60364417800011],
              [107.32010708500007, 16.600526406000071],
              [107.31847521800006, 16.599177708000035],
              [107.31609670200007, 16.597316564000053],
              [107.31269925500007, 16.594685657000063],
              [107.30787830300007, 16.591224272000105],
              [107.30469074800003, 16.589241784000116],
              [107.30197827200004, 16.587579435000066],
              [107.29798158400011, 16.585475591000076],
              [107.29476172500006, 16.583590914000098],
              [107.29130672000011, 16.581741323000095],
              [107.28988173200005, 16.58078062500001],
              [107.28797562100009, 16.578979003000072],
              [107.28586839700009, 16.577244669],
              [107.28471498900004, 16.576476218000025],
              [107.28376888700009, 16.576161138000067],
              [107.2820158980001, 16.575919998000096],
              [107.28103899500009, 16.575669319000049],
              [107.28000163900006, 16.575272158000054],
              [107.27882349300009, 16.574876543000094],
              [107.27740906700004, 16.574347331000091],
              [107.27646506900001, 16.573903736000069],
              [107.27575732100007, 16.573593738000092],
              [107.27533225900007, 16.573371429000083],
              [107.27498253500008, 16.573132910000062],
              [107.27443875000003, 16.572683198000071],
              [107.27232634900005, 16.57049310200005],
              [107.2699417470001, 16.568045591000043],
              [107.26926262000003, 16.567532249000024],
              [107.26858960600003, 16.567539578],
              [107.26812077300006, 16.567739961000072],
              [107.26712494100009, 16.568922483000037],
              [107.26561855400007, 16.570161534000057],
              [107.26481852900004, 16.570821168000101],
              [107.26408858700003, 16.571224432000072],
              [107.26309313300001, 16.571633977000118],
              [107.26087199900009, 16.572407873000117],
              [107.25859123500003, 16.573083564000065],
              [107.25591193100011, 16.574219203000062],
              [107.25290440400005, 16.576074400000081],
              [107.25003367800011, 16.578123354000063],
              [107.24804018000002, 16.580358091000043],
              [107.24678702100007, 16.582584816000079],
              [107.24639903200003, 16.58395597400002],
              [107.24642548400008, 16.586233972000031],
              [107.24603296400002, 16.587214613000079],
              [107.24543170500002, 16.587611668000065],
              [107.24449162400003, 16.587817104000102],
              [107.24348045600004, 16.587697826000088],
              [107.24256835900007, 16.587414730000113],
              [107.23209238900006, 16.583817017000065],
              [107.23094136300003, 16.583243515000042],
              [107.22965854900012, 16.582480600000068],
              [107.22782339500006, 16.58138917300009],
              [107.22692915800006, 16.580849250000092],
              [107.22586094400005, 16.580498856000055],
              [107.22531368000007, 16.580405132000031],
              [107.22328405800009, 16.58025380200003],
              [107.221015897, 16.580104999000064],
              [107.21820968400007, 16.579846564],
              [107.21480909200008, 16.579767430000025],
              [107.21313926600004, 16.579785197000071],
              [107.21127918700006, 16.579840621000038],
              [107.20676946000003, 16.57988848500009],
              [107.20515476500002, 16.579970701000057],
              [107.20421687500004, 16.580371207000063],
              [107.20354971100009, 16.580899040000041],
              [107.20248608500002, 16.582082016000044],
              [107.20142170900006, 16.583199914000076],
              [107.20041573900005, 16.583536023000086],
              [107.19974262300003, 16.583543137000056],
              [107.19888571200002, 16.583483913000038],
              [107.19739968800008, 16.583043941000049],
              [107.19603090700006, 16.582736105000073],
              [107.19467800900003, 16.582287722000011],
              [107.19312153500003, 16.581785053000075],
              [107.19197749800006, 16.581476936000072],
              [107.19036905400003, 16.581006596000059],
              [107.18860548100012, 16.580940382000087],
              [107.18343013, 16.580994745000055],
              [107.17459352400002, 16.580081719000091],
              [107.17088765000008, 16.579804541000073],
              [107.16823783300003, 16.579267436000045],
              [107.16661800800003, 16.578893726000032],
              [107.16499598400003, 16.578324731000023],
              [107.16130754500003, 16.57657289400003],
              [107.15752292800006, 16.575245113000037],
              [107.15590312800006, 16.574871322000106],
              [107.15489347500001, 16.574881773000079],
              [107.15408865000003, 16.575150484000019],
              [107.15308478800002, 16.575681646000092],
              [107.15214461200003, 16.575886661000091],
              [107.15167344100004, 16.575891535000054],
              [107.15059357400004, 16.575642307000038],
              [107.14957097000007, 16.575245445000064],
              [107.14863289800009, 16.574881384000051],
              [107.14545776200001, 16.57387257000007],
              [107.14228482200011, 16.573058973000073],
              [107.13985877900011, 16.572823534000044],
              [107.139048168, 16.572571477000061],
              [107.13816666900007, 16.571994645000117],
              [107.13552077200009, 16.570133962000085],
              [107.13392716700004, 16.569076174000053],
              [107.13291250900002, 16.568630883000068],
              [107.13034474100002, 16.567745786000017],
              [107.12784501400004, 16.56692507000006],
              [107.12595180500007, 16.566163223000082],
              [107.12479827800011, 16.565328722000046],
              [107.12350516100005, 16.564039932000085],
              [107.12261660900008, 16.562812121000015],
              [107.12138511600006, 16.561001908000073],
              [107.12116609400005, 16.559441773000039],
              [107.12100370900004, 16.556904579000076],
              [107.12050479000004, 16.554370816000038],
              [107.1196864610001, 16.553402651000049],
              [107.11866413300007, 16.552983981000047],
              [107.11728417800003, 16.552662688000019],
              [107.11562813200005, 16.552415445000079],
              [107.11454913600004, 16.552231089000102],
              [107.11256102200012, 16.552082130000123],
              [107.11016185600003, 16.552072225000103],
              [107.10745618300007, 16.552192151000078],
              [107.10584300800009, 16.552403751000085],
              [107.10443314100006, 16.552743487000043],
              [107.10241964699999, 16.553284608000048],
              [107.10060381600003, 16.553433119000083],
              [107.09925560000003, 16.553251399000054],
              [107.09180942600005, 16.551804566000072],
              [107.09009689700012, 16.551065121000086],
              [107.08919414000003, 16.550292819000042],
              [107.08748980900006, 16.548291794000122],
              [107.08613252500002, 16.547263789000063],
              [107.08528808700005, 16.546979276000059],
              [107.08414392900006, 16.546990718000117],
              [107.08228281900008, 16.547105150000085],
              [107.08024240500001, 16.547224985000049],
              [107.07733463700004, 16.547269873000033],
              [107.07608174600001, 16.547224671000023],
              [107.07548358700009, 16.547057593000055],
              [107.0727668000001, 16.545466831000063],
              [107.070723824, 16.544680147000079],
              [107.06829973400006, 16.544544487000046],
              [107.06561661400011, 16.545417396000062],
              [107.06226248400004, 16.546492212000082],
              [107.06044733900005, 16.546705458000055],
              [107.059571, 16.546583907000034],
              [107.05889312000006, 16.54613490900001],
              [107.05851638300007, 16.545520160000123],
              [107.05851016600009, 16.544934306000059],
              [107.05863027300008, 16.543566006000084],
              [107.05941234200003, 16.541149552000078],
              [107.05986411800002, 16.539322270000056],
              [107.05985236800004, 16.538215679000068],
              [107.05970463800006, 16.536980220000075],
              [107.05845731500007, 16.533607290000063],
              [107.05694499700007, 16.530627550000077],
              [107.0557441760001, 16.528458498000106],
              [107.05498598300008, 16.526773338000034],
              [107.05477238800009, 16.525668732000028],
              [107.05476068600009, 16.524562119000038],
              [107.05488838200009, 16.52390985500007],
              [107.05534637600006, 16.52266842300012],
              [107.056734788, 16.520311109000119],
              [107.05812937300001, 16.518539643000096],
              [107.060722599, 16.51611463600009],
              [107.06333137000011, 16.514061452000114],
              [107.06573385600004, 16.512149782000037],
              [107.06834439600003, 16.510821933000038],
              [107.06960703900005, 16.510489532000072],
              [107.07067653700008, 16.510132860000049],
              [107.07295971800008, 16.509518218000082],
              [107.07322659300004, 16.509464700000095],
              [107.07444297300005, 16.509306604000074],
              [107.07503917600009, 16.509300686000117],
              [107.07633123300005, 16.509211541000113],
              [107.07709160300008, 16.50910844400002],
              [107.07788601500009, 16.508901814000062],
              [107.07815378000004, 16.508768955000086],
              [107.07886114100003, 16.508332123000088],
              [107.07951187200005, 16.507293286000021],
              [107.07983787900011, 16.50631354000005],
              [107.08036644100007, 16.505396873000052],
              [107.08116556600001, 16.504607712000038],
              [107.08243847199999, 16.50407423300004],
              [107.08425317000004, 16.503860835000062],
              [107.08586880200005, 16.503909818000025],
              [107.087558, 16.504543955000095],
              [107.08911263200004, 16.505179404000089],
              [107.09106821100002, 16.505550454000058],
              [107.09268314700006, 16.505534285000053],
              [107.09310427800008, 16.505352729000045],
              [107.09341981700008, 16.505201403000044],
              [107.09375275400001, 16.504872569000014],
              [107.09374925300007, 16.504547107000086],
              [107.09347099300005, 16.503703590000015],
              [107.09336199600008, 16.502956026000071],
              [107.09342157700004, 16.502239331000098],
              [107.09348396400006, 16.501783006],
              [107.09418361400003, 16.500381539000038],
              [107.09529272700006, 16.498178621000115],
              [107.09552560200005, 16.497657178000047],
              [107.09598078700006, 16.495633848000054],
              [107.09622868000005, 16.494203925000051],
              [107.0964214210001, 16.49335569400003],
              [107.09675082700008, 16.492701391000075],
              [107.09741665700002, 16.492043720000105],
              [107.09795492000006, 16.492038312000098],
              [107.09842802400004, 16.492228870000055],
              [107.09965528200006, 16.493713837000087],
              [107.10061764300011, 16.495592063000061],
              [107.101365507, 16.496300643000069],
              [107.10237478500001, 16.496290497000118],
              [107.10358311200007, 16.496017942000037],
              [107.10498974900001, 16.495417882000126],
              [107.10686174600009, 16.494292343000083],
              [107.10993847600002, 16.492568734000109],
              [107.11408672100006, 16.490378556000081],
              [107.11719660300007, 16.488621962000046],
              [107.1189983400001, 16.487236631000087],
              [107.12166122900007, 16.484605679000097],
              [107.12292322800005, 16.483095594000119],
              [107.12378296700004, 16.48171979500006],
              [107.12490611700007, 16.479820525000029],
              [107.12775571700003, 16.475820545000097],
              [107.12906686900007, 16.473435808000133],
              [107.12962626700008, 16.472290881000085],
              [107.1302792540001, 16.470592739],
              [107.1314009660001, 16.467678662000118],
              [107.13198436300006, 16.465654655000073],
              [107.13334939400002, 16.461279143000063],
              [107.13418831600006, 16.458015662000044],
              [107.1342370670001, 16.456322607000025],
              [107.13416196300003, 16.455607284000081],
              [107.13378094100003, 16.454602131000051],
              [107.132551592, 16.45292208500004],
              [107.13085424200007, 16.45150716800012],
              [107.12935799800009, 16.450025104000062],
              [107.12867558200003, 16.44911525900001],
              [107.12828543600007, 16.448382238000114],
              [107.12776569800005, 16.447445820000084],
              [107.12737689400004, 16.446835618000044],
              [107.12677537900001, 16.446186618000077],
              [107.12605048800006, 16.445502167000114],
              [107.10919269900005, 16.442228643000064],
              [107.09711845400004, 16.440364261000084],
              [107.08454469800009, 16.439745069000097],
              [107.07632525, 16.438585660000093],
              [107.0626785800001, 16.433508280000098],
              [107.05830862200003, 16.432558432000107],
              [107.0552104560001, 16.430603249000072],
              [107.05262000900008, 16.428146665000028],
              [107.04599129400006, 16.41964865000007],
              [107.04468062600006, 16.416931268000035],
              [107.04387246900008, 16.413216192000043],
              [107.041507751, 16.407778864000107],
              [107.03891519900007, 16.405073882000032],
              [107.03838171600007, 16.403093478000038],
              [107.03912507600003, 16.400604292000054],
              [107.039599106, 16.396876742000096],
              [107.03957842900006, 16.394891361000049],
              [107.03800391600008, 16.391431870000069],
              [107.03541424300003, 16.388974977000089],
              [107.03410912000005, 16.386753822000074],
              [107.03408593600008, 16.384520268000102],
              [107.03428565900001, 16.379057974000055],
              [107.03417264800007, 16.375958850000046],
              [107.03328069200008, 16.374935892000053],
              [107.03225542000007, 16.374330609000062],
              [107.0298061, 16.373391829000106],
              [107.02711784600007, 16.372000562000011],
              [107.02475811600004, 16.370722376000032],
              [107.02267608900007, 16.369549657000078],
              [107.01989730000003, 16.367715672000124],
              [107.01760246100004, 16.366000011000011],
              [107.01681538300002, 16.365130313000087],
              [107.01620129800001, 16.364067562000081],
              [107.01523104100002, 16.362039670000073],
              [107.01490671700002, 16.361656665000091],
              [107.01399477100006, 16.360803310000094],
              [107.01338616600006, 16.360341439000067],
              [107.01290190600005, 16.360229143000105],
              [107.01253961, 16.360232604000124],
              [107.01193699600006, 16.360355284000057],
              [107.01151791100003, 16.360710056000102],
              [107.01080050700008, 16.361418438],
              [107.00960723200002, 16.362832906000065],
              [107.009069165, 16.363364194000063],
              [107.00846833100012, 16.363662228000031],
              [107.00768395100005, 16.363728173000069],
              [107.00605001700004, 16.363392974000043],
              [107.00392943800009, 16.362711636000071],
              [107.00313909800006, 16.362193],
              [107.00173658200002, 16.360861720000038],
              [107.00057917700009, 16.359878862000073],
              [106.99942477800005, 16.359188289000045],
              [106.99803003600002, 16.358616904000108],
              [106.99639794000008, 16.358457004000023],
              [106.99404185, 16.358362398000011],
              [106.99246894800007, 16.358084970000064],
              [106.99104552800006, 16.357659971000068],
              [106.99019484600011, 16.357141856000098],
              [106.98958215000005, 16.35627070700005],
              [106.98840707600007, 16.35353408600011],
              [106.98722849800008, 16.350446719000011],
              [106.98623345300008, 16.347591459000022],
              [106.98579071500004, 16.345607915000038],
              [106.98571379600007, 16.343971701000015],
              [106.98573305100007, 16.342889966000129],
              [106.98602372000005, 16.3417764470001],
              [106.98655884400009, 16.340952940000086],
              [106.98778400400003, 16.339596815000057],
              [106.99113014500004, 16.336115981000084],
              [106.99841561500004, 16.331250303000019],
              [107.00578300100007, 16.324727101000036],
              [107.0121145510001, 16.317220749000057],
              [107.01614158600005, 16.309984464000046],
              [107.01698634200004, 16.303680335000067],
              [107.01503776900002, 16.303968912000052],
              [107.01360649600008, 16.304213965000081],
              [107.01333048600007, 16.304486565000076],
              [107.01313491400005, 16.304835548000085],
              [107.01306156900003, 16.305453334000042],
              [107.01330997900006, 16.306376588000134],
              [107.01368100400003, 16.307607235000084],
              [107.013689286, 16.308417080000126],
              [107.01345782900003, 16.309152071000099],
              [107.01280762800006, 16.309833208000057],
              [107.01162233500004, 16.310770139000041],
              [107.01015748, 16.311632574000079],
              [107.00831525300006, 16.312595025000043],
              [107.00684956900002, 16.313380314000092],
              [107.00562087900006, 16.313970514000076],
              [107.00359212300006, 16.314221181000029],
              [107.00246199900009, 16.314035584000038],
              [107.00175661200008, 16.313891476000016],
              [106.99955679400006, 16.312986694000116],
              [106.99774550900003, 16.311936908000035],
              [106.99605764200007, 16.310848723000056],
              [106.994591525, 16.309673511000078],
              [106.9934828020001, 16.309052304000105],
              [106.992683959, 16.308828422000055],
              [106.99181008100008, 16.308680516000074],
              [106.99093690800004, 16.308558843000043],
              [106.99026558000001, 16.308500223000074],
              [106.98909590900004, 16.308340793000028],
              [106.98842327500007, 16.308152276000065],
              [106.987173974, 16.30787983700008],
              [106.98582872200008, 16.307502788000058],
              [106.9839815190001, 16.306866227000057],
              [106.98166198100002, 16.305923784000065],
              [106.97982462400006, 16.305401064000066],
              [106.97730909300002, 16.304768955000078],
              [106.97419302000003, 16.304299368000038],
              [106.97217649200007, 16.303863541000041],
              [106.96878979000007, 16.303170781000098],
              [106.9676446460001, 16.303014627000053],
              [106.96540409000008, 16.303154811000105],
              [106.96420518300008, 16.304024366000114],
              [106.96292766800008, 16.306426552000097],
              [106.96230841000008, 16.308469130000077],
              [106.96204859900008, 16.310434942000107],
              [106.961914538, 16.31344553100007],
              [106.96206634100004, 16.316160891000031],
              [106.96255200500008, 16.321045417000057],
              [106.96385093800008, 16.324751904000045],
              [106.96448752100008, 16.329032675000029],
              [106.96449676500005, 16.329958250000054],
              [106.96458180200007, 16.330497411000074],
              [106.96379105000001, 16.339067056000097],
              [106.96369821700006, 16.339742872000059],
              [106.96386370000008, 16.340358433000098],
              [106.96435402300006, 16.341588064000057],
              [106.96494445000005, 16.344860906000044],
              [106.96545176400011, 16.347787404000066],
              [106.96545948000004, 16.348558702000027],
              [106.96517325800006, 16.349814860000045],
              [106.96486462400003, 16.350820520000028],
              [106.96420518200009, 16.352600829000075],
              [106.96319684900006, 16.355348611000053],
              [106.96226431600007, 16.357709993000036],
              [106.96163389400004, 16.358410105000054],
              [106.96108120100007, 16.35891665000004],
              [106.9602078930001, 16.359233334000045],
              [106.95921045400009, 16.359088338000035],
              [106.95820878600007, 16.358519122000104],
              [106.95748904900003, 16.358255820000075],
              [106.95593545, 16.358270260000026],
              [106.95502038200006, 16.358394466000092],
              [106.95251839700008, 16.359189045000022],
              [106.95053022900007, 16.359792960000071],
              [106.948122885, 16.360594662000061],
              [106.94571487700007, 16.361331378000088],
              [106.94324104400002, 16.362198598000077],
              [106.94023241000005, 16.36326556900007],
              [106.93723414700004, 16.364528858000057],
              [106.93556399900005, 16.365258675000049],
              [106.93301577200006, 16.366254776000041],
              [106.93141205100007, 16.366919009000114],
              [106.92965340400004, 16.367615041000057],
              [106.92782765500004, 16.368326027000087],
              [106.92673368500004, 16.368780547000128],
              [106.92586407300003, 16.369048318000075],
              [106.92468025400005, 16.369639211000028],
              [106.92337787900003, 16.370274467000101],
              [106.921640799, 16.371236741000047],
              [106.92045714100004, 16.371890662000062],
              [106.9191042800001, 16.372609815000061],
              [106.91714824000007, 16.374266840000118],
              [106.91544867500005, 16.375670825000022],
              [106.91450305000006, 16.376759385000113],
              [106.913674696, 16.37818787000009],
              [106.91297748500006, 16.379665978000091],
              [106.91258660000004, 16.380440928000084],
              [106.911916445, 16.381179841000026],
              [106.91080990500002, 16.382115572000089],
              [106.90902566300011, 16.383018879000041],
              [106.90674731800006, 16.384331625000037],
              [106.90540467400008, 16.385578024000047],
              [106.90346925400009, 16.387331185000065],
              [106.90157363300008, 16.389083978000052],
              [106.89951261500009, 16.390221131000082],
              [106.89761058800011, 16.391318270000085],
              [106.896150538, 16.392797098000052],
              [106.89564114900004, 16.393688801000025],
              [106.89517682600008, 16.395120077000058],
              [106.89481162900006, 16.398169267000107],
              [106.89502738900006, 16.399661235000096],
              [106.89523023500006, 16.402698583000053],
              [106.8933984650001, 16.404894286000093],
              [106.89174464600005, 16.406953397000024],
              [106.89060584900007, 16.408699301000063],
              [106.89017907000002, 16.409898809000097],
              [106.89026324100004, 16.41036089600005],
              [106.89070827700002, 16.411051148000055],
              [106.89292565900007, 16.413692505000107],
              [106.89359865200007, 16.415287081000059],
              [106.893978954, 16.417520711000044],
              [106.89463334800006, 16.419250448000078],
              [106.89628551000007, 16.421125447000051],
              [106.89765913000012, 16.423041537000046],
              [106.89819403000006, 16.424772336000082],
              [106.89820863500003, 16.426276423],
              [106.89789880500005, 16.427204903000089],
              [106.89744325800002, 16.427452020000111],
              [106.89655781800003, 16.427632630000041],
              [106.89444104100005, 16.427859248000054],
              [106.89084832800006, 16.427318990000025],
              [106.88987737700009, 16.427132877000084],
              [106.88953457700006, 16.426990993000068],
              [106.88794119800004, 16.425771204000036],
              [106.88608460200007, 16.423357984000027],
              [106.88539932900004, 16.422554176000048],
              [106.88488015300007, 16.422443136000098],
              [106.88424254900008, 16.422448854000038],
              [106.88360605800008, 16.422570273000048],
              [106.88241536600006, 16.423082369000049],
              [106.87838699800008, 16.424834831000098],
              [106.87520926900007, 16.425943215000061],
              [106.87250860200007, 16.426931582000037],
              [106.87092930200005, 16.428488483000066],
              [106.87001866400001, 16.430088724000043],
              [106.86989376400008, 16.431064141000014],
              [106.86974148900001, 16.433474643000068],
              [106.86977746800005, 16.435152120000041],
              [106.86994685200003, 16.436192005000102],
              [106.87030995900005, 16.436651625000117],
              [106.87103434200003, 16.437377995000091],
              [106.87275916200011, 16.438519699000054],
              [106.87404706600009, 16.439819588000134],
              [106.87497334400008, 16.440814130000092],
              [106.87639270700001, 16.443347062000058],
              [106.87746759000004, 16.445285231000106],
              [106.87823672800009, 16.446512588000047],
              [106.87956161000002, 16.44750353500006],
              [106.88100459200004, 16.448339129000097],
              [106.88388649000009, 16.449586060000065],
              [106.88608753000005, 16.450491939000052],
              [106.88649018800007, 16.450912586000044],
              [106.88653433200007, 16.451355745000029],
              [106.88646226100006, 16.452147076000045],
              [106.8855641980001, 16.454083650000051],
              [106.88483820100006, 16.455266566000134],
              [106.88444820100004, 16.45615719000002],
              [106.88441728000005, 16.45708314500007],
              [106.88454579700003, 16.458007669],
              [106.8853668080001, 16.460468754000068],
              [106.88565513200007, 16.461430404000055],
              [106.88554339400005, 16.462241379000098],
              [106.88499394100008, 16.463133435000096],
              [106.88389540700004, 16.464956111000092],
              [106.882027222, 16.467576358000088],
              [106.88033527500008, 16.46869774200006],
              [106.87807651000006, 16.46973242800005],
              [106.877272938, 16.469941711000047],
              [106.87599598700005, 16.470484683000031],
              [106.8753858470001, 16.471376342000084],
              [106.87503811400002, 16.472447579000082],
              [106.87493540900005, 16.473122962],
              [106.87494937700005, 16.474574278000098],
              [106.87523580700012, 16.476411990000095],
              [106.87565595700008, 16.478645286000045],
              [106.87595728300005, 16.480956791000075],
              [106.87642438000006, 16.483922492000055],
              [106.87703310900007, 16.485035567000047],
              [106.87775846300003, 16.48583902700009],
              [106.8789022, 16.486619431000094],
              [106.88054853500006, 16.487838863],
              [106.88436659100003, 16.491005800000025],
              [106.88481891300006, 16.492428814000078],
              [106.88531447600003, 16.494198562000051],
              [106.88555746600007, 16.496645545000099],
              [106.88581609200006, 16.498648834000029],
              [106.88591152100005, 16.500267904000054],
              [106.887136771, 16.513544126000035],
              [106.88714463500003, 16.514354013000073],
              [106.88722137800008, 16.515611222000089],
              [106.8868728090001, 16.517388798000084],
              [106.88629823200007, 16.519201521000056],
              [106.88551174300002, 16.520713475000015],
              [106.88472368400009, 16.521684837000095],
              [106.8833796700001, 16.522892627000104],
              [106.88250698500009, 16.523363334000059],
              [106.88059988800003, 16.524074776000042],
              [106.87924799100008, 16.5244726540001],
              [106.87843433100009, 16.524778742000109],
              [106.87752208900011, 16.525270764000055],
              [106.87661607000011, 16.526407796000072],
              [106.87588065200001, 16.52794647400011],
              [106.87527645300003, 16.530255280000091],
              [106.87453524000007, 16.531959021000105],
              [106.87356784100005, 16.532951235000048],
              [106.87281397400002, 16.533343702000103],
              [106.87182047600007, 16.533699753000072],
              [106.87010888600003, 16.534023658000052],
              [106.86720663300005, 16.534936759000104],
              [106.86529935300007, 16.535648073000075],
              [106.86354230000009, 16.536604366000063],
              [106.85185126900009, 16.543320614000031],
              [106.8493964940001, 16.544892249000043],
              [106.84477088300009, 16.54703530500008],
              [106.84242912900005, 16.548213122],
              [106.83908857600005, 16.549206878000078],
              [106.83630336000009, 16.549887128000051],
              [106.83343727099999, 16.550452345000096],
              [106.83233771900007, 16.550532952000104],
              [106.83164385800002, 16.550583816000049],
              [106.83024812800009, 16.550596081],
              [106.82952813300003, 16.550370976000025],
              [106.82916669100005, 16.550104155000064],
              [106.82900426200003, 16.549797009000052],
              [106.828392131, 16.548849640000093],
              [106.82802983500005, 16.547351016000086],
              [106.82792323500011, 16.545800040000103],
              [106.82793197400012, 16.544257375000093],
              [106.827739417, 16.541517416000048],
              [106.82733445300006, 16.539763985],
              [106.82680517700007, 16.538611497000133],
              [106.82603447800007, 16.537229697000022],
              [106.82493826500004, 16.535156465000078],
              [106.82288508900007, 16.533091599000024],
              [106.82259401100006, 16.531821298000082],
              [106.82248546200005, 16.530877261000043],
              [106.8224151900001, 16.527637921000071],
              [106.823634607, 16.525853004000091],
              [106.82446577800003, 16.525190033000037],
              [106.82606818200001, 16.52338874400003],
              [106.82614478400004, 16.521395421000037],
              [106.82554254800004, 16.518835725000081],
              [106.82513332900002, 16.517720744000108],
              [106.82404440100007, 16.516593097000062],
              [106.82327167100009, 16.515856269000025],
              [106.82179109900007, 16.514239981000053],
              [106.82061993300006, 16.511340745000069],
              [106.82019486600007, 16.510457435000077],
              [106.81925609400001, 16.508127139000095],
              [106.81855049899998, 16.506689012000031],
              [106.81721421400009, 16.503709840000091],
              [106.8127905690001, 16.489748475000084],
              [106.811536781, 16.487830781000042],
              [106.81046403000006, 16.486085070000023],
              [106.80937118000006, 16.484320246000017],
              [106.80803774300004, 16.48240321400003],
              [106.806097569, 16.480038285000106],
              [106.80476800300004, 16.477443182000059],
              [106.80424850500005, 16.476338050000017],
              [106.80390320500007, 16.475050804000091],
              [106.80332622000003, 16.472744152000104],
              [106.80297021100006, 16.471164808000083],
              [106.802235228, 16.469281139000103],
              [106.80117279400011, 16.466474571000056],
              [106.80060227200006, 16.465129479000048],
              [106.79999973100004, 16.464633223000114],
              [106.79964526000002, 16.465099125000023],
              [106.79877968900007, 16.466340856],
              [106.79710993200004, 16.468997325000068],
              [106.7955371300001, 16.471363684000018],
              [106.79380802300004, 16.474078516000112],
              [106.79305878700004, 16.474972074000057],
              [106.79206858900008, 16.475674846000032],
              [106.79083636100009, 16.476071111000046],
              [106.79027826800011, 16.476075884000082],
              [106.78915890800003, 16.475738321000044],
              [106.78719780800004, 16.474906488000052],
              [106.78509667900002, 16.474017981000074],
              [106.78169348200007, 16.472426960000064],
              [106.77949256000005, 16.47151996300007],
              [106.77809524600008, 16.471300397000022],
              [106.77657905000004, 16.471158987000045],
              [106.773429182, 16.471108543000042],
              [106.77119234300002, 16.470300098000031],
              [106.76999289900007, 16.469241256000089],
              [106.76898903100006, 16.468133764000115],
              [106.76883687100008, 16.467370020000068],
              [106.76896086300005, 16.46680691100002],
              [106.76963073400005, 16.46594255100009],
              [106.7711408050001, 16.464626712000019],
              [106.77208598700007, 16.463805641000114],
              [106.77265604900006, 16.462642769000091],
              [106.77316703000007, 16.460852852000052],
              [106.77343082300004, 16.459804107000053],
              [106.77287398600006, 16.459087936000039],
              [106.77120868600004, 16.454514071000048],
              [106.77115484900003, 16.451788777000061],
              [106.77174556800006, 16.447500283000061],
              [106.77211920100007, 16.444096175000041],
              [106.77199731300001, 16.440961736000048],
              [106.77117329500004, 16.437278275000011],
              [106.76988679500009, 16.434239354000098],
              [106.76792827700004, 16.431503146000104],
              [106.764561461, 16.428350082000073],
              [106.75999004900004, 16.42607564700009],
              [106.75646799300003, 16.425237809000045],
              [106.75255565800006, 16.424772342000082],
              [106.74733425800005, 16.424946368000093],
              [106.74528853400008, 16.424934445000083],
              [106.74437532100011, 16.424315992000068],
              [106.74374642300003, 16.423358564000075],
              [106.74375325700007, 16.422268597000041],
              [106.74411963200001, 16.420090732000055],
              [106.74406190300002, 16.418046632000127],
              [106.74350911800011, 16.416204019000077],
              [106.742111166, 16.414152091000034],
              [106.74057087600001, 16.41230368300004],
              [106.73902547900003, 16.411272749000091],
              [106.73775580500006, 16.4112653010001],
              [106.73676527000008, 16.411736369000081],
              [106.73626463900004, 16.412823429000056],
              [106.73611281400009, 16.414525671000121],
              [106.73574035300001, 16.417657256000062],
              [106.73530593200003, 16.41942595500003],
              [106.7340164070001, 16.422552140000057],
              [106.73322279400007, 16.425340604000027],
              [106.73305543400008, 16.429495252000059],
              [106.7335999790001, 16.432632214000087],
              [106.734354454, 16.436042917000023],
              [106.73437401900003, 16.438529600000074],
              [106.73337818300004, 16.439818112000104],
              [106.73224467900005, 16.440560807000089],
              [106.7308324310001, 16.440756859000061],
              [106.72808626000007, 16.439923137000086],
              [106.72478092600005, 16.438268563000058],
              [106.72267407700008, 16.436757316000069],
              [106.72246895700007, 16.43573421500006],
              [106.72311081800007, 16.434648031000073],
              [106.72452953500003, 16.433430193000028],
              [106.72707868600001, 16.431946537000016],
              [106.72842768100007, 16.430592010000034],
              [106.72864795700009, 16.429230814000029],
              [106.72806434600002, 16.427957614000022],
              [106.72647755900002, 16.426629211000062],
              [106.72492735500001, 16.426347526000114],
              [106.72337367400006, 16.426610811000053],
              [106.72153086600008, 16.42796237400006],
              [106.71911977100007, 16.429923660000064],
              [106.71691851600011, 16.432158672000071],
              [106.71493496200003, 16.433441207000051],
              [106.71281378500002, 16.434177907000077],
              [106.7076223240001, 16.435134593],
              [106.70373790000004, 16.435792473000085],
              [106.70027231100009, 16.43713406600007],
              [106.69658971000004, 16.439291783000073],
              [106.69446841200002, 16.440028292000086],
              [106.69206802700009, 16.440286211000021],
              [106.69030478100004, 16.440207362000066],
              [106.68815643000002, 16.439683330000051],
              [106.68660438300003, 16.439673859000067],
              [106.68554260400002, 16.440212375000037],
              [106.68462056100002, 16.440956121000028],
              [106.68419056900007, 16.441975366000072],
              [106.68418117300008, 16.44340593400004],
              [106.68494602700004, 16.445113736000039],
              [106.68599667300002, 16.446278280000023],
              [106.68972287600003, 16.448276634000081],
              [106.69098699700001, 16.449169961000095],
              [106.69112142000003, 16.450192651000073],
              [106.69076110200005, 16.451348580000058],
              [106.68820429800007, 16.453921757000067],
              [106.68476171600007, 16.457068548000017],
              [106.68313271100001, 16.458012324000038],
              [106.68200251300003, 16.458209782000033],
              [106.68122865600003, 16.457864410000056],
              [106.68003643300007, 16.456767107000033],
              [106.67814632700001, 16.454507380000024],
              [106.67681437900011, 16.453204810000074],
              [106.67540559100007, 16.452855520000043],
              [106.67434550600002, 16.453121490000022],
              [106.67349302000009, 16.45400185700003],
              [106.672634198, 16.455835932000085],
              [106.67196038200004, 16.461690514000082],
              [106.67228606900007, 16.465780012000053],
              [106.6728071950001, 16.472323203000094],
              [106.67275544000009, 16.474809436000015],
              [106.67232395200008, 16.47603301],
              [106.67097427100006, 16.477387171000132],
              [106.66963226300004, 16.477583256000102],
              [106.66822150500012, 16.477506399000049],
              [106.6663213580001, 16.476745261000048],
              [106.66224933500007, 16.473654370000098],
              [106.66070022400007, 16.473167863000079],
              [106.65956990900006, 16.473365194000067],
              [106.65878828700005, 16.474177820000065],
              [106.65842681700008, 16.475469933000042],
              [106.65868442800007, 16.479150266000083],
              [106.65957710200004, 16.482834553000103],
              [106.66089911600008, 16.485635891000072],
              [106.66128107200007, 16.486557952000069],
              [106.66134298400004, 16.487852700000083],
              [106.66105298000011, 16.489009008000011],
              [106.66005505400005, 16.490501536000046],
              [106.65835190300001, 16.491921533000031],
              [106.65799177700005, 16.493009279000056],
              [106.65798401700003, 16.49416734200004],
              [106.65875112300009, 16.495534615000018],
              [106.65997579000008, 16.497075034000105],
              [106.66144495700004, 16.498991661000062],
              [106.66248801600001, 16.501314367000042],
              [106.66303938100006, 16.503293394000096],
              [106.66303301400008, 16.504247095000046],
              [106.66267197200004, 16.505471089000068],
              [106.6618192120001, 16.506351398000071],
              [106.65948307800008, 16.507426846000058],
              [106.65573367100009, 16.5087659410001],
              [106.65318072400007, 16.510589327000069],
              [106.651969073, 16.512352960000065],
              [106.65146680400009, 16.513576049],
              [106.65113615200009, 16.515515512000079],
              [106.65112422300008, 16.517286656000074],
              [106.65061734600006, 16.519190952000073],
              [106.6494766540001, 16.520886894000064],
              [106.64713523200008, 16.522711532000045],
              [106.64642347200007, 16.523592673000032],
              [106.64577722500007, 16.525223574000059],
              [106.64547418400005, 16.528287240000061],
              [106.64546915400007, 16.53424803200005],
              [106.64557759900008, 16.539085500000091],
              [106.64570632100006, 16.540925646000026],
              [106.64633143600001, 16.542428300000012],
              [106.64762549700005, 16.544173582000084],
              [106.65001066100005, 16.546368512000065],
              [106.65470688900004, 16.55130280500012],
              [106.6599653210001, 16.556649229000087],
              [106.66302432300006, 16.558405391000086],
              [106.665914387, 16.559036438000071],
              [106.66866102700007, 16.560007171],
              [106.66999499000003, 16.561105382000015],
              [106.67041129600003, 16.562197927000085],
              [106.67033301600003, 16.56335553500012],
              [106.66982846800002, 16.564919248000095],
              [106.66812248800009, 16.566679903000114],
              [106.66379137600003, 16.57039983100006],
              [106.66155197800001, 16.572736165],
              [106.66083783500008, 16.573957930000027],
              [106.66061829800003, 16.575114661000079],
              [106.66060690400006, 16.576817662000032],
              [106.66123133200004, 16.57845649100009],
              [106.66214134900011, 16.5796202430001],
              [106.664031537, 16.582016282000019],
              [106.66479948400004, 16.583315377000041],
              [106.66479266900009, 16.584337174000026],
              [106.66440005700012, 16.584981909000085],
              [106.66347727800006, 16.585725529000058],
              [106.6624182940001, 16.585718951000047],
              [106.66079635000003, 16.585436376000118],
              [106.65847389600006, 16.584331949000038],
              [106.65565728900008, 16.583224412000064],
              [106.65445758300011, 16.58314879800011],
              [106.65346967600007, 16.583074502],
              [106.65226492700005, 16.583748184000058],
              [106.65141316500009, 16.584424079000115],
              [106.65012172600009, 16.587481511000043],
              [106.64840020200003, 16.591489953000057],
              [106.64718525700005, 16.593662242000029],
              [106.64597858900004, 16.594608375000064],
              [106.64456331600009, 16.595076323000058],
              [106.64272767199999, 16.595064758000078],
              [106.6396631960001, 16.594057638000059],
              [106.63649355600003, 16.59294762100005],
              [106.63444936500009, 16.592457809000024],
              [106.63134665300007, 16.591893123000112],
              [106.63015016600009, 16.591340531000043],
              [106.62945115700008, 16.590314243000122],
              [106.62939408300007, 16.588338320000062],
              [106.62961893600001, 16.586432310000099],
              [106.62941880200006, 16.584727966000031],
              [106.62914386700005, 16.583636248000033],
              [106.62801990900005, 16.582811611000103],
              [106.62639615900008, 16.582801257000042],
              [106.62497906200007, 16.583541567000012],
              [106.62383825400009, 16.58516922000004],
              [106.6229784240001, 16.587003037000066],
              [106.622313967, 16.591222407000124],
              [106.62193376100008, 16.595171089000083],
              [106.622274116, 16.597012578000125],
              [106.62317929600002, 16.598857678000023],
              [106.62408590700007, 16.600498419000061],
              [106.62414901800005, 16.601588777000039],
              [106.62371883300007, 16.602539744000033],
              [106.62251011500007, 16.603758222000039],
              [106.61995989100009, 16.604968097000047],
              [106.614616219, 16.606807143000069],
              [106.60903076300006, 16.607861091000053],
              [106.60585348299999, 16.607840540000041],
              [106.59985289400008, 16.607665355000023],
              [106.59717271000001, 16.607239172000021],
              [106.59453143700006, 16.606302288000037],
              [106.59164714500005, 16.604784750000064],
              [106.5881341730001, 16.602309323000064],
              [106.58672497200011, 16.601891341000027],
              [106.58545553900007, 16.601678640000017],
              [106.5841121330001, 16.601942300000069],
              [106.58233737400008, 16.603293076000099],
              [106.58126672200004, 16.604920959000054],
              [106.58039917000012, 16.607776394000062],
              [106.58017623200006, 16.609341740000083],
              [106.58044465400006, 16.611319061000081],
              [106.58106805000004, 16.613026230000024],
              [106.58289228100007, 16.614673186000047],
              [106.58627044300007, 16.616262207000077],
              [106.58809811000003, 16.617432277000077],
              [106.58908232900004, 16.618051827000087],
              [106.58936092500008, 16.618598632000037],
              [106.58928503400001, 16.619347486],
              [106.58889215500001, 16.622327512000098],
              [106.58853401000005, 16.624284082000074],
              [106.58797699300005, 16.625542061000061],
              [106.58710745100005, 16.626556164000036],
              [106.58273849100004, 16.629186510000068],
              [106.57951142900004, 16.631130971000104],
              [106.57714771900004, 16.632728602000043],
              [106.57349820700006, 16.635388853000066],
              [106.57010966399999, 16.637776181000064],
              [106.56726562900005, 16.639928585000071],
              [106.56489792300007, 16.64198042600011],
              [106.56372741300004, 16.643278219000109],
              [106.56270328600003, 16.645224616000043],
              [106.56255930100011, 16.646866428000081],
              [106.56253284100005, 16.647745610000086],
              [106.5628533340001, 16.648622236000051],
              [106.56492107600012, 16.652035109000082],
              [106.56537724400002, 16.653324123000068],
              [106.56491863700009, 16.656307406000082],
              [106.56435821800008, 16.660476198000126],
              [106.56320352400012, 16.664649473000011],
              [106.56284668100008, 16.666447241000078],
              [106.56240622200005, 16.667096792000088],
              [106.56146990800002, 16.668407956000038],
              [106.55965099700008, 16.670266095000038],
              [106.55744683900005, 16.67329840100011],
              [106.55649227000002, 16.675290950000132],
              [106.55540175800003, 16.678243057000053],
              [106.55417539500009, 16.682775858000106],
              [106.55302677, 16.687738885000059],
              [106.55054890700005, 16.69371711700007],
              [106.54978415000002, 16.695625635000063],
              [106.54971449100003, 16.696200582000117],
              [106.54996171100005, 16.699214462000086],
              [106.55022168000006, 16.703807898],
              [106.55034027900011, 16.713895327000024],
              [106.55036000200005, 16.716336482000123],
              [106.55027435700008, 16.717690280000049],
              [106.55009641300009, 16.720503027000078],
              [106.55031554100002, 16.733820808000118],
              [106.550436715, 16.753386143000078],
              [106.54980786100002, 16.77209544700009],
              [106.54970520100005, 16.791518799000116],
              [106.54915198600003, 16.810371090000054],
              [106.54911379600006, 16.814823126000086],
              [106.54907503200005, 16.819203364000131],
              [106.54883061900009, 16.830298649000049],
              [106.5487889610001, 16.83431988400006],
              [106.54880529600003, 16.836328698000088],
              [106.54882224000001, 16.838412362],
              [106.54879227100011, 16.843869548000079],
              [106.54851756100003, 16.851231334000083],
              [106.54854794300005, 16.854964820000085],
              [106.54874577300008, 16.860994703000109],
              [106.54834522100006, 16.866598273000122],
              [106.5479195070001, 16.869114558000128],
              [106.54431495000001, 16.869393057000082],
              [106.53882602100008, 16.871157582000059],
              [106.53682749200001, 16.872321414000076],
              [106.53616459200005, 16.873116207000024],
              [106.53374242900001, 16.877083476000095],
              [106.53117549200007, 16.881554421000097],
              [106.52898381800001, 16.884976869000035],
              [106.52831095600003, 16.886027560000102],
              [106.52720406100011, 16.887112854000108],
              [106.52497640500007, 16.887560272000087],
              [106.52141579900008, 16.888735585000077],
              [106.51748559200006, 16.890200808000074],
              [106.51660001100007, 16.891069002000059],
              [106.5150042020001, 16.894587176000094],
              [106.51397369300001, 16.896865712000064],
              [106.51361524800011, 16.897566246000025],
              [106.51361879700005, 16.898011184000069],
              [106.5139715870001, 16.898898527000128],
              [106.51501751500004, 16.900003205000047],
              [106.51695307400006, 16.902013565000026],
              [106.51753895700003, 16.902808417000067],
              [106.51779300900009, 16.903447340000085],
              [106.51779878000004, 16.904168204000044],
              [106.51731054900006, 16.905373329000014],
              [106.51624791600005, 16.90738370600009],
              [106.51489835600005, 16.909836768000019],
              [106.51449499800006, 16.911281557],
              [106.51451291200004, 16.913524231000032],
              [106.51477016300001, 16.914563629000057],
              [106.51502614800005, 16.915442843000058],
              [106.51578383100012, 16.916798926000084],
              [106.51620513500008, 16.917596807000024],
              [106.5164608900001, 16.918447411000059],
              [106.5165790180001, 16.922060841000075],
              [106.51751258700007, 16.923851088000049],
              [106.51857884100011, 16.92489421500003],
              [106.52257342800003, 16.926671587],
              [106.52493381200003, 16.927588255000089],
              [106.52816163800001, 16.926960195000085],
              [106.53083247900003, 16.926353243000044],
              [106.53362397100007, 16.925627952000077],
              [106.53569241200006, 16.925729825000069],
              [106.53849912300004, 16.92688264800006],
              [106.54117661400005, 16.927097256000053],
              [106.54506452200005, 16.926598338000076],
              [106.54579710200008, 16.926944969000068],
              [106.54604896700005, 16.927999597000017],
              [106.54643011100006, 16.929992380000058],
              [106.54642828400009, 16.93721200800011],
              [106.54674829700009, 16.946600956000069],
              [106.54682512000005, 16.955991733000083],
              [106.54660412200006, 16.966147802000094],
              [106.54679113300008, 16.974129022000014],
              [106.54747250200006, 16.983045624000056],
              [106.547544714, 16.991849435000091],
              [106.54747121800004, 16.997719563000132],
              [106.54763542200004, 16.998980101000072],
              [106.548242638, 16.998559280000066],
              [106.54857190000007, 16.998118812000129],
              [106.54916750200006, 16.996946395000109],
              [106.54993749800009, 16.995823741000038],
              [106.55043209900006, 16.995250629000047],
              [106.5510196230001, 16.994939586000065],
              [106.55351165800005, 16.994395045000132],
              [106.55636679300004, 16.993847691000049],
              [106.55772554400002, 16.993486939000043],
              [106.55898880600009, 16.992557556000065],
              [106.55966311500001, 16.991764081000049],
              [106.56046957600003, 16.990487818000055],
              [106.56082398000005, 16.989434013000078],
              [106.56122774800006, 16.988861566000054],
              [106.56177058000003, 16.988638429000069],
              [106.56258754700005, 16.98863216600008],
              [106.56467823400001, 16.988966491000035],
              [106.56677111500007, 16.989563544000049],
              [106.56877215600008, 16.990029897000092],
              [106.5706798640001, 16.990190372000043],
              [106.57222302300005, 16.990178471000036],
              [106.57533183500007, 16.990132564000056],
              [106.57755579600004, 16.990115352000053],
              [106.57937346000007, 16.990364034000024],
              [106.58110109500011, 16.990700996000015],
              [106.58250846400003, 16.990733873000075],
              [106.58436785000004, 16.990544229000101],
              [106.58608996600006, 16.990224262000098],
              [106.58758330400008, 16.989687092000061],
              [106.58930023700003, 16.988753998000035],
              [106.59056477200002, 16.98799961000006],
              [106.59135293800006, 16.987270835000068],
              [106.59193556600012, 16.986390375000049],
              [106.59233590000011, 16.985423765000093],
              [106.59250820800007, 16.984327536000073],
              [106.59250044700005, 16.983407888],
              [106.59213108200004, 16.98266625700002],
              [106.59130787300005, 16.98192817400006],
              [106.58911909400005, 16.98071897600002],
              [106.58679529400007, 16.979642197000096],
              [106.58579132100004, 16.978993083000127],
              [106.5851683230001, 16.978388435000099],
              [106.58481573800006, 16.977697905000078],
              [106.58440414300003, 16.976162372000125],
              [106.58435000800007, 16.974629182000101],
              [106.58448575300011, 16.973816736000025],
              [106.58485614400003, 16.973022290000102],
              [106.58593539300006, 16.971831422000072],
              [106.588098648, 16.97001898400012],
              [106.58890447100006, 16.968698842000052],
              [106.59100825900003, 16.965222612000019],
              [106.59138642500007, 16.964321856000097],
              [106.59142517599999, 16.963533238000124],
              [106.59127907000007, 16.96235190200008],
              [106.59113186600005, 16.961039198],
              [106.59121784300007, 16.960469185000093],
              [106.59144030900009, 16.959941908000076],
              [106.59193615400008, 16.95954387600009],
              [106.59320382500007, 16.959183617000036],
              [106.59533109000004, 16.958510075000085],
              [106.59713921300008, 16.957663816000057],
              [106.59822015200008, 16.956691860000106],
              [106.59835295700006, 16.956296659000088],
              [106.598394259, 16.955814596000081],
              [106.59829201800008, 16.954457737000084],
              [106.5982407210001, 16.953757418000045],
              [106.598109693, 16.951678175000048],
              [106.59795727500008, 16.949752370000049],
              [106.59795024200004, 16.948920324000127],
              [106.59805685300006, 16.948109269000078],
              [106.59859285, 16.947097787000054],
              [106.59998580400006, 16.945466448000076],
              [106.60205416100004, 16.943216678000091],
              [106.60367392200004, 16.941583545000057],
              [106.604620106, 16.940787804000124],
              [106.60561425500001, 16.940298232000117],
              [106.60688361700005, 16.940156872000053],
              [106.60974032400007, 16.93991539600006],
              [106.614051594, 16.939968964000052],
              [106.61682127900011, 16.940166036000015],
              [106.61850048500004, 16.940196524000051],
              [106.6192257270001, 16.940103183000055],
              [106.62026669400008, 16.939788373000027],
              [106.621035416, 16.939475709000092],
              [106.62169165100002, 16.939273422000056],
              [106.62241762300005, 16.93926765800002],
              [106.62332546900004, 16.939304245000116],
              [106.62450781900007, 16.939601399000104],
              [106.62542092800004, 16.940251053000033],
              [106.6262470720001, 16.941339348000014],
              [106.62679608100004, 16.941860521000059],
              [106.62734358300004, 16.942206509000087],
              [106.62793494800003, 16.94237698400007],
              [106.628887804, 16.942369386000067],
              [106.63065439000007, 16.942004930000017],
              [106.63346152600008, 16.941281780000047],
              [106.63622515700003, 16.940777913000105],
              [106.63903338700004, 16.940186075000071],
              [106.64123266900002, 16.940015136000049],
              [106.64254773800012, 16.939916971000102],
              [106.64395126300008, 16.939555327000022],
              [106.64756587000009, 16.937774424000018],
              [106.64856178200007, 16.937503617000083],
              [106.65019518800004, 16.937490428000054],
              [106.65291715700002, 16.937424635000028],
              [106.65514041100002, 16.937406631000052],
              [106.65700028, 16.937347751000061],
              [106.65824936600005, 16.937490894000092],
              [106.65884188800004, 16.937792643000101],
              [106.65961975500001, 16.938530825000072],
              [106.66053796800004, 16.939749596000105],
              [106.66196683300005, 16.942278030000054],
              [106.66298544900005, 16.944590835000014],
              [106.6637729880001, 16.946423760000059],
              [106.66427941600008, 16.94725172200009],
              [106.66492006000007, 16.947859622000024],
              [106.66528576300004, 16.948163194000095],
              [106.66627471600006, 16.948597174000035],
              [106.66876997200002, 16.94933469500004],
              [106.67068295700003, 16.949908127000057],
              [106.67290830000003, 16.95061903100008],
              [106.67477488300003, 16.951304418000035],
              [106.67727944600004, 16.952291111000051],
              [106.67873768400008, 16.952979827000014],
              [106.67951374800008, 16.953498961000065],
              [106.68111357100003, 16.954799605000034],
              [106.68367412400005, 16.956968180000054],
              [106.68458829500001, 16.957705129000097],
              [106.68535656600008, 16.957348439000071],
              [106.6866899, 16.95674621000007],
              [106.68768622500001, 16.956519003000011],
              [106.69027266800005, 16.95649759400002],
              [106.6908163950001, 16.956405505000035],
              [106.69131436300009, 16.956270003000014],
              [106.69180761100004, 16.955609008000039],
              [106.69225118000006, 16.954466694000111],
              [106.69273542400008, 16.952798527000112],
              [106.69335615700001, 16.951173021000088],
              [106.69397924300004, 16.949810255000038],
              [106.69474079700009, 16.948709093000055],
              [106.69586377200001, 16.947429753000073],
              [106.69698907100003, 16.946413157000073],
              [106.69775453500004, 16.945749891000084],
              [106.69890034300008, 16.944492256000032],
              [106.69965871100004, 16.943040754000016],
              [106.70028487800006, 16.942028295000085],
              [106.70219287900001, 16.942275169000084],
              [106.70487300900008, 16.942603155000043],
              [106.70632768900001, 16.942897559000066],
              [106.70792047700007, 16.943409754000079],
              [106.70973933800011, 16.943832470000061],
              [106.71060141400002, 16.943825246000088],
              [106.71155345300011, 16.943729686000083],
              [106.71304638, 16.943235444000045],
              [106.71408320299999, 16.942482256000076],
              [106.71639902000001, 16.940163671000086],
              [106.71810724100004, 16.938397580000064],
              [106.71913963400003, 16.937162687000026],
              [106.71981223900008, 16.936281171000097],
              [106.71989978900004, 16.935930091],
              [106.71987870200007, 16.933609236000088],
              [106.72032364300003, 16.932642039000086],
              [106.721086579, 16.931715960000112],
              [106.722033368, 16.931051086000082],
              [106.72352616400006, 16.930556778000039],
              [106.72556577600007, 16.930320604000123],
              [106.72805747200007, 16.929905409000057],
              [106.73014084100006, 16.929493649000094],
              [106.73206240900006, 16.928754784000063],
              [106.73459221300004, 16.927550923000112],
              [106.73775231100004, 16.925816156000018],
              [106.73973601300003, 16.924441716000068],
              [106.74054618900001, 16.923734133000053],
              [106.74117649600002, 16.923203247000096],
              [106.74153782200008, 16.923025003000092],
              [106.74190035500006, 16.922978116000117],
              [106.74239939500004, 16.922973862000035],
              [106.74335773900002, 16.923578797000083],
              [106.74550451600001, 16.925137003000131],
              [106.7471028790001, 16.926261960000133],
              [106.74796889900009, 16.926692477000046],
              [106.74876384300003, 16.926795158000104],
              [106.74953509699999, 16.926788557000094],
              [106.75089328700001, 16.926470376000054],
              [106.75197967400001, 16.926198319000015],
              [106.75279669000003, 16.926235105000039],
              [106.76041171200002, 16.927666963000028],
              [106.76329570700008, 16.928203029000066],
              [106.76443356099999, 16.928587343000068],
              [106.76630508300003, 16.929797358000087],
              [106.76855999200009, 16.93076317700006],
              [106.77029175900003, 16.931580228000072],
              [106.770297902, 16.932237054000062],
              [106.76981604700009, 16.934080491000088],
              [106.76955488800007, 16.935265140000055],
              [106.76866837400009, 16.93750622100012],
              [106.76809125400003, 16.938868770000056],
              [106.76791591300008, 16.93952717000009],
              [106.767927775, 16.940797038000085],
              [106.76829850500003, 16.941625875000099],
              [106.76956155800009, 16.943257139000067],
              [106.77107110200002, 16.944557809000038],
              [106.77257780900008, 16.945551955000049],
              [106.77417668800004, 16.946698552000058],
              [106.77334070200006, 16.949486606000086],
              [106.77268392400006, 16.952032237000125],
              [106.77051485300009, 16.96026207700001],
              [106.770427791, 16.960656953000083],
              [106.77034279100008, 16.961270781000067],
              [106.77034976300003, 16.962015186000109],
              [106.77076799200009, 16.963062551000085],
              [106.77141064800006, 16.963845239000094],
              [106.77218819500008, 16.964495367000062],
              [106.77357606800007, 16.96489932300009],
              [106.77507636200005, 16.965192821000038],
              [106.77634978000006, 16.965488267000062],
              [106.7769437860001, 16.965921008000073],
              [106.7781363500001, 16.967268167000071],
              [106.77887269100009, 16.968356540000059],
              [106.77933635700001, 16.969403491],
              [106.77943824900008, 16.970584984000055],
              [106.77921754700003, 16.971243790000031],
              [106.77828318399999, 16.973222564000068],
              [106.77775306800001, 16.974759905000077],
              [106.77761135900009, 16.976578504000095],
              [106.77761919800002, 16.977410471],
              [106.77925865300006, 16.982826346000124],
              [106.77953588499999, 16.983349426000061],
              [106.78068816200002, 16.985222397000129],
              [106.78142501400006, 16.986354533000011],
              [106.78231884600004, 16.987288240000083],
              [106.78304784200004, 16.98758840600005],
              [106.78413906, 16.987797816000061],
              [106.78527277600001, 16.987700298000071],
              [106.78740196600006, 16.987287496000114],
              [106.7890340230001, 16.987098014000068],
              [106.79030469900007, 16.987086850000118],
              [106.79130598300006, 16.987384588000062],
              [106.79263243900002, 16.988467703000062],
              [106.79391560600006, 16.989770150000091],
              [106.79515008000006, 16.990722683000037],
              [106.79610769400006, 16.991195959000024],
              [106.79710943200008, 16.991537448000123],
              [106.79902048000011, 16.992046079000112],
              [106.80052186500008, 16.992426927000089],
              [106.80154484000008, 16.992614938000102],
              [106.8022289260001, 16.992959218000081],
              [106.80287097800002, 16.993654191000118],
              [106.80324491200005, 16.994789458000078],
              [106.80357767500004, 16.996362986000065],
              [106.80395162700009, 16.99749825000012],
              [106.80427727300003, 16.998327391000068],
              [106.80501473200012, 16.999503219000097],
              [106.80532633900005, 16.999993986000028],
              [106.80653732500006, 17.00150726],
              [106.80693853800004, 17.002136886000052],
              [106.80710144700002, 17.002641981000096],
              [106.80708457600005, 17.003629889000123],
              [106.80696184100002, 17.004568075000037],
              [106.80650078300006, 17.005939841000028],
              [106.80598679200007, 17.00728673400009],
              [106.80585847400002, 17.007642446000126],
              [106.8043435220001, 17.011758899000107],
              [106.80350128300003, 17.014603046000062],
              [106.80309578300006, 17.016278230000111],
              [106.80270716200005, 17.016965500000047],
              [106.80218657500006, 17.017628630000026],
              [106.80179964700002, 17.01849317900006],
              [106.80146757700007, 17.019585184000086],
              [106.80134191700007, 17.020219470000072],
              [106.80134530400008, 17.020574023],
              [106.80156091000006, 17.021078650000021],
              [106.8020399700001, 17.021580957000104],
              [106.80235902900003, 17.021882059000056],
              [106.80586137000009, 17.024434348000028],
              [106.80689881200004, 17.025463539],
              [106.80777771100001, 17.026443485000087],
              [106.80849754600007, 17.027323533000072],
              [106.80889906100008, 17.027978470000079],
              [106.80906443599999, 17.02873680500003],
              [106.80909565500008, 17.029243062000084],
              [106.80902245700007, 17.029851566000076],
              [106.80855747500006, 17.030818136000022],
              [106.80830059200011, 17.03150424400004],
              [106.80807052600008, 17.032240778000052],
              [106.80802416300007, 17.032899699000033],
              [106.80808950700006, 17.034216118000067],
              [106.80841492300007, 17.035175650000056],
              [106.80868155800002, 17.03550253400001],
              [106.80902674500007, 17.035778050000069],
              [106.81471314700009, 17.040716756000052],
              [106.81564094900003, 17.041290997000132],
              [106.81664583200005, 17.041661924],
              [106.81722646700004, 17.04175802700005],
              [106.81772657400003, 17.041702908000019],
              [106.81861824, 17.041264367000061],
              [106.82002875900008, 17.039985386000112],
              [106.82086721900001, 17.039496674000084],
              [106.8221533390001, 17.038978598000121],
              [106.83131695400007, 17.035654397000052],
              [106.83189485300001, 17.035471903000051],
              [106.83284209700005, 17.035336723000057],
              [106.83379181200011, 17.03545479400006],
              [106.83505965600003, 17.035772597000062],
              [106.83603571500005, 17.035890410000043],
              [106.83935862200011, 17.036189608000107],
              [106.8403868770001, 17.036256279000028],
              [106.84101846500003, 17.036174578000015],
              [106.84180736700003, 17.036015473000084],
              [106.84230571900005, 17.035783008000038],
              [106.84287963900002, 17.035195300000083],
              [106.84336827800004, 17.03458497700003],
              [106.84356576800003, 17.034356301000031],
              [106.84384432100008, 17.034060804000092],
              [106.84424964600004, 17.033872900000091],
              [106.84493648400003, 17.033481797000057],
              [106.84652324500004, 17.033062077000061],
              [106.847926853, 17.033049324000068],
              [106.84877033599999, 17.033176756000046],
              [106.850764908, 17.033747431],
              [106.85367932600008, 17.03476670900006],
              [106.85587932900003, 17.036014326000078],
              [106.85765511800005, 17.037733734000057],
              [106.8583199980001, 17.038569163000084],
              [106.85895391100006, 17.039434942000042],
              [106.85940083600008, 17.04036254300005],
              [106.85968817500006, 17.040960986000094],
              [106.86089112800002, 17.042452681000064],
              [106.86379587300011, 17.045281202000062],
              [106.86703362300004, 17.048572437000061],
              [106.86921103500008, 17.050566032000077],
              [106.87191024300003, 17.053140834000132],
              [106.87247994100001, 17.053826824000048],
              [106.87305174700006, 17.054723174000053],
              [106.873279607, 17.055622688000049],
              [106.87323944500005, 17.056299269000071],
              [106.87296642600003, 17.0571433040001],
              [106.87244416100006, 17.058079791000047],
              [106.87114497800005, 17.059504307000047],
              [106.869657864, 17.060900497000105],
              [106.86403169300009, 17.065896138000092],
              [106.86009917700005, 17.068261426000042],
              [106.85785845000001, 17.069363910000042],
              [106.85655283300008, 17.070157273000049],
              [106.8561195070001, 17.070612044000107],
              [106.85551760400004, 17.071413990000025],
              [106.85527486900003, 17.072167562000025],
              [106.85524954700006, 17.072768879000044],
              [106.85528883500002, 17.073579983000094],
              [106.85582995000004, 17.074536770000037],
              [106.85693603600006, 17.075698746000079],
              [106.85941510500002, 17.078125443000133],
              [106.86108263000006, 17.079131985000103],
              [106.86290326000007, 17.079806506000047],
              [106.86519147500003, 17.080326444000079],
              [106.87295947300007, 17.0815171],
              [106.87474467600008, 17.08177108000006],
              [106.87599495800005, 17.081699412000084],
              [106.87749419800011, 17.081505208000088],
              [106.87874476200003, 17.081463571000107],
              [106.87968442500005, 17.081605125000095],
              [106.885463277, 17.082468098000092],
              [106.887311622, 17.083623968000069],
              [106.88785803700009, 17.084110348000081],
              [106.88834515800006, 17.084683988000023],
              [106.8886859400001, 17.085663725000089],
              [106.88884799700001, 17.08681858200007],
              [106.88906351800009, 17.087308024000066],
              [106.88946040500005, 17.087882503000081],
              [106.89372557500009, 17.091673155000109],
              [106.894906388, 17.092413751],
              [106.89623673500003, 17.093066213000085],
              [106.8968719710001, 17.093407169000052],
              [106.90109178800002, 17.095665895000067],
              [106.90178573800006, 17.095861747000107],
              [106.90404838700005, 17.097304557000115],
              [106.90539282400007, 17.098313559000111],
              [106.90869240000006, 17.100527098000029],
              [106.91078991100005, 17.101863767000047],
              [106.91210103, 17.102715315000069],
              [106.91422789800004, 17.10363181900005],
              [106.91644178300007, 17.104838128000033],
              [106.92101038400007, 17.107303861000034],
              [106.9228247400001, 17.108082695000078],
              [106.92340956600007, 17.108277142000091],
              [106.92382622600009, 17.108313182000053],
              [106.92424166500008, 17.108229236000035],
              [106.92457302400005, 17.10806608300004],
              [106.92486194100007, 17.107823331000112],
              [106.92512193100001, 17.107588963000083],
              [106.92545942700004, 17.107509288000067],
              [106.9257211820001, 17.107595923000041],
              [106.93440564300005, 17.110852698000123],
              [106.93741790900006, 17.113057890000057],
              [106.93996402000005, 17.115425135000081],
              [106.9660442520001, 17.140792365000031],
              [106.96794447800004, 17.142640255000074],
              [106.980126688, 17.15528038300009],
              [106.98956204500007, 17.165070406000012],
              [106.99276082600005, 17.162709829],
              [107.00882925800005, 17.151112788000056],
              [107.02326211600007, 17.140479731000099],
              [107.03853127200001, 17.130822020000025],
              [107.053854599, 17.121271502000113],
              [107.06219197399999, 17.116012443],
              [107.07452696600006, 17.108500746000033],
            ],
          ],
          [
            [
              [107.34345675300007, 17.151934828000037],
              [107.34060260600008, 17.150275610000065],
              [107.33659663900009, 17.151033582000075],
              [107.33395993900007, 17.152786021000033],
              [107.33097635000003, 17.155637699000081],
              [107.32947048100004, 17.161244778000118],
              [107.32969076300006, 17.163776257000052],
              [107.33185424000001, 17.166753876000129],
              [107.33608237300008, 17.168087329000059],
              [107.33928610900004, 17.167877084000068],
              [107.34146358, 17.166673359000036],
              [107.3452529680001, 17.162283518],
              [107.34640551600006, 17.159646187000099],
              [107.34641308300007, 17.157335491000048],
              [107.34573650700004, 17.154362507000087],
              [107.34345675300007, 17.151934828000037],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 50, code: "AD01", ten_tinh: "Sóc Trăng" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.90627204000002, 9.932544093000038],
              [105.90918679800004, 9.932246965000056],
              [105.91470853900003, 9.933030436000044],
              [105.91930338200007, 9.931306781000069],
              [105.93146418300005, 9.921348992000032],
              [105.94712709000007, 9.909955069000096],
              [105.95527588699998, 9.904798802000057],
              [105.95917855200004, 9.902506265000087],
              [105.96398965600012, 9.896332706000075],
              [105.96811442299999, 9.891415827000037],
              [105.97138163000005, 9.888155400000096],
              [105.97551359400006, 9.885747975000035],
              [105.97897722200004, 9.884494060000025],
              [105.97912098600003, 9.884277838000076],
              [105.98319702800003, 9.879109555000108],
              [105.98493461700011, 9.876862564000087],
              [105.98823627399999, 9.872592821000035],
              [105.99167332600007, 9.868590289000066],
              [105.99442051400008, 9.864589750000084],
              [105.99544697400002, 9.86184905900007],
              [105.99744356900001, 9.856767059000047],
              [106.00408542700005, 9.848192148000033],
              [106.01198692300004, 9.838130484000105],
              [106.01840653600007, 9.832293678000069],
              [106.03290206700004, 9.817477835000064],
              [106.04230231400004, 9.809350182000019],
              [106.05044026900005, 9.802024708000088],
              [106.05576424400002, 9.795449266000043],
              [106.061714173, 9.786989698000069],
              [106.06347028300009, 9.784730015000063],
              [106.06846761000003, 9.778299380000098],
              [106.07181681600012, 9.775554290000075],
              [106.07293822100009, 9.774635135000052],
              [106.07592016100008, 9.772686575000121],
              [106.08401286700007, 9.769638098000131],
              [106.09181492900009, 9.765620822000097],
              [106.10053339299999, 9.759185715000028],
              [106.10190402800006, 9.758173985000072],
              [106.109706057, 9.751974761000069],
              [106.11141768700004, 9.75061470600007],
              [106.11816196000002, 9.742241371000125],
              [106.11897122200001, 9.741236582000033],
              [106.12509185900009, 9.734156361000055],
              [106.13026759200005, 9.728168736000063],
              [106.13059015100001, 9.727795565000095],
              [106.13099712300001, 9.727425482000028],
              [106.13448171600002, 9.724256728000034],
              [106.14204607100001, 9.71737752100008],
              [106.15120670000007, 9.707993477000086],
              [106.16534154700005, 9.691805511000061],
              [106.17278297100006, 9.684023760000036],
              [106.17845968100009, 9.678555416000021],
              [106.179081099, 9.677956784000051],
              [106.19139327100005, 9.666907128000089],
              [106.19849211400006, 9.660038645000045],
              [106.21062964100007, 9.648703903000031],
              [106.21165487700007, 9.64782855600008],
              [106.22786620900007, 9.633985846000124],
              [106.24504444400006, 9.619438406000123],
              [106.2612572880001, 9.608258607000051],
              [106.262923089, 9.607345623000095],
              [106.27014249800003, 9.603388617000073],
              [106.27340827800006, 9.60159854800011],
              [106.28613219600008, 9.594707874000095],
              [106.29392275600003, 9.589432081000025],
              [106.30062562400005, 9.585129737000029],
              [106.30187852600007, 9.584214585000071],
              [106.29049562300007, 9.574975537000084],
              [106.28883550900011, 9.567433875000029],
              [106.28348361900005, 9.555032840000076],
              [106.27699957500002, 9.540776208000066],
              [106.27104999300006, 9.53135313000004],
              [106.26786013000002, 9.526639807000057],
              [106.26446231000007, 9.521072592000033],
              [106.25720427600004, 9.513768949000044],
              [106.25378529600003, 9.510757933000134],
              [106.24907244800009, 9.508020087000089],
              [106.24464068200004, 9.505994622000101],
              [106.23964227600001, 9.503112162000081],
              [106.23749094400009, 9.502952021000064],
              [106.23556589100006, 9.503821694000079],
              [106.21876459300009, 9.499412355000024],
              [106.20405846600002, 9.495510336000022],
              [106.20427781800005, 9.492527144000105],
              [106.20381048200002, 9.488466521000037],
              [106.20195456700003, 9.480210572000123],
              [106.20002140900003, 9.47153107000007],
              [106.19791637700004, 9.466116466000061],
              [106.19466119200008, 9.460975992000085],
              [106.18642970600007, 9.450680116000052],
              [106.17720612900011, 9.439097421000041],
              [106.17473208100004, 9.434957723000091],
              [106.17289863300005, 9.431391634000084],
              [106.17178249600006, 9.427831837000065],
              [106.17036437400006, 9.425973416000131],
              [106.16778734800009, 9.425382836000027],
              [106.16378597100004, 9.423643730000018],
              [106.16050570000004, 9.421484889000096],
              [106.15922356700001, 9.420479608000052],
              [106.15765455400005, 9.415500918000051],
              [106.15622894300004, 9.410977081000025],
              [106.16033927200004, 9.416007752000065],
              [106.16093522800006, 9.416444816000078],
              [106.16140694600001, 9.416412763],
              [106.16291003600003, 9.415139714000096],
              [106.1642247190001, 9.414476534000114],
              [106.16861181700008, 9.41332013900009],
              [106.17040003000008, 9.412992288000053],
              [106.17306349500009, 9.412791102000105],
              [106.17923139700008, 9.412844913000116],
              [106.18225114200001, 9.412019208000098],
              [106.18295095300009, 9.411211053000022],
              [106.18486361100007, 9.409215799000046],
              [106.18750577000002, 9.40397132400008],
              [106.18755238600005, 9.402924047000026],
              [106.18655180700004, 9.396495098000022],
              [106.18512681500009, 9.391293864000081],
              [106.18489532500003, 9.387178486000012],
              [106.18648106900005, 9.383082222000015],
              [106.18680232500007, 9.38033639800002],
              [106.18634086600008, 9.378744626000033],
              [106.18169974400004, 9.371629729000029],
              [106.17973533200004, 9.369492165000091],
              [106.17815417600004, 9.368155118000042],
              [106.16327567200008, 9.355572384000064],
              [106.15807220600006, 9.352672391000015],
              [106.15061103600009, 9.349891827000077],
              [106.14201321100003, 9.344758686000086],
              [106.14077014000004, 9.344426103000012],
              [106.13975398400007, 9.344429359000104],
              [106.13839983800003, 9.344658088000111],
              [106.13791809400004, 9.344611775000098],
              [106.13704440000006, 9.344678764000046],
              [106.13523688600007, 9.344349736000089],
              [106.13414060600005, 9.343714124000078],
              [106.13257986400005, 9.342213859000113],
              [106.13015900600004, 9.340080061000043],
              [106.12875147800001, 9.339050507000122],
              [106.12702486600006, 9.338487455000076],
              [106.12576553800005, 9.338476166000119],
              [106.12229810400004, 9.338914404000052],
              [106.12009141500006, 9.339207483000054],
              [106.11639140200008, 9.33925239700004],
              [106.11056625800003, 9.339278046000031],
              [106.10772988600009, 9.339565293000083],
              [106.10677747800007, 9.340417172000047],
              [106.10645186500004, 9.34158763700008],
              [106.10565615300001, 9.342519162000061],
              [106.10455421200005, 9.342509171000033],
              [106.10266661900002, 9.34233559000006],
              [106.09929003100007, 9.341444403000041],
              [106.08901465100004, 9.337517555000117],
              [106.06342868800002, 9.329537604000036],
              [106.04734167, 9.324381474000097],
              [106.02364774600002, 9.316414496000023],
              [106.00772089900002, 9.311178722000067],
              [105.99454006400003, 9.306906823000103],
              [105.98450218200003, 9.303212011000104],
              [105.97713178000002, 9.300403019000035],
              [105.97179834100007, 9.29855222100011],
              [105.95500626500007, 9.293538848000081],
              [105.94449370800004, 9.290228660000109],
              [105.92966858300007, 9.285389096000072],
              [105.91304308900008, 9.279670144000106],
              [105.89092993200008, 9.272017366000094],
              [105.87995546400003, 9.267917210000041],
              [105.87921359000002, 9.267629546000043],
              [105.87415568000003, 9.26566820700012],
              [105.86710364700008, 9.262780545000025],
              [105.85825198300009, 9.25893561800004],
              [105.83662824500007, 9.250030942000096],
              [105.82690982800004, 9.246410446000118],
              [105.82561764000006, 9.245834439000065],
              [105.82483955500007, 9.248425843000073],
              [105.82074915200012, 9.26208930100003],
              [105.81898763000002, 9.267737150000064],
              [105.81871295800003, 9.268925808000093],
              [105.81818511700007, 9.271387932000062],
              [105.81767813800003, 9.273637852000059],
              [105.82411691500005, 9.27818727200007],
              [105.82393812300009, 9.278735799000069],
              [105.82099494000002, 9.295205428000129],
              [105.81676665900002, 9.314719563000027],
              [105.81643859500006, 9.316161439000046],
              [105.81621424200006, 9.317664851000094],
              [105.81587658800001, 9.319436149000103],
              [105.81494454500007, 9.3239263670001],
              [105.81388882400003, 9.328684507000061],
              [105.8135406590001, 9.330394074000113],
              [105.81270487100004, 9.33432883700007],
              [105.81072532600004, 9.340398939000048],
              [105.81286609400006, 9.341587738000088],
              [105.81559666200003, 9.343104005000045],
              [105.81836400100012, 9.344640635000088],
              [105.82048006700006, 9.345815594000049],
              [105.82232706700002, 9.346842932000053],
              [105.8226049080001, 9.346985824000063],
              [105.82456468800007, 9.34791885700008],
              [105.82647980900008, 9.348821554000073],
              [105.82729995799998, 9.349208127000084],
              [105.82780783700008, 9.349437875000087],
              [105.82889512800008, 9.349899875000032],
              [105.8303187610001, 9.350492415000121],
              [105.83301053500006, 9.351612744000091],
              [105.83507445900003, 9.352251452000091],
              [105.83694127500004, 9.352829144000017],
              [105.83872301500003, 9.353380490000072],
              [105.840783765, 9.354018152000075],
              [105.84160093000003, 9.35427464800007],
              [105.84412676900011, 9.355082404000113],
              [105.84574009700002, 9.355600667000095],
              [105.84694423100008, 9.35598942700002],
              [105.84726693700006, 9.356113165000044],
              [105.84854755100005, 9.356700982000097],
              [105.84883590300011, 9.356886278000063],
              [105.84908330100006, 9.35705608400008],
              [105.84945557800003, 9.357351383],
              [105.84994470800009, 9.357739338000036],
              [105.85014789800003, 9.357931324000022],
              [105.85100051900005, 9.358838574000037],
              [105.85205782300005, 9.359963608000042],
              [105.85228569499999, 9.360206077000031],
              [105.85342654100006, 9.361017272000092],
              [105.85445722500008, 9.361370476],
              [105.85543461000003, 9.361323410000109],
              [105.85635342900011, 9.361103033000083],
              [105.85681717800003, 9.360994959000022],
              [105.85720136500002, 9.360662586000055],
              [105.85820159300009, 9.363581707],
              [105.85867358700006, 9.365325956000079],
              [105.85865335400007, 9.367273906000049],
              [105.85835525200011, 9.369010277000099],
              [105.85728559100006, 9.370877910000084],
              [105.855299976, 9.37329276600005],
              [105.852962088, 9.375912720000111],
              [105.85147153400004, 9.377845594000076],
              [105.85110117000004, 9.379789992000019],
              [105.85129960400012, 9.380905294000018],
              [105.85199176600001, 9.381677771000073],
              [105.85442703800007, 9.383163941000115],
              [105.85512283800009, 9.383588564000077],
              [105.85518417100009, 9.384424138000023],
              [105.85454534600008, 9.385252539000087],
              [105.84911282900001, 9.389162754000056],
              [105.84685850100001, 9.390461568000051],
              [105.84664191500012, 9.391085564000077],
              [105.84668286800004, 9.393869202000092],
              [105.84659322300008, 9.395746964000027],
              [105.84551822700004, 9.398101644000077],
              [105.84303659400007, 9.401306152000098],
              [105.84031446299998, 9.404124292000036],
              [105.83927856700006, 9.404568982000059],
              [105.83784705800011, 9.404497259000076],
              [105.83613868500007, 9.403511860000078],
              [105.83443511300007, 9.402071097000063],
              [105.83352993200005, 9.400980148000125],
              [105.83029197800002, 9.392920259000091],
              [105.82967086800005, 9.392059953000052],
              [105.82915833900009, 9.391770018000043],
              [105.82807061100007, 9.391701789000083],
              [105.82675075400002, 9.391915773000024],
              [105.82455786300004, 9.39342990000007],
              [105.82311441500006, 9.39449642900003],
              [105.82150511900005, 9.394991972000087],
              [105.82047345000004, 9.395038149000088],
              [105.81927715800001, 9.394399503],
              [105.81900038400002, 9.39348583300008],
              [105.81913361200003, 9.391722592000027],
              [105.81973591800003, 9.388939625000068],
              [105.81975156900009, 9.387459784000017],
              [105.81953570600008, 9.386205222000042],
              [105.81808620400005, 9.382433193000049],
              [105.81725656500006, 9.379635331000101],
              [105.81671814600001, 9.37638514200003],
              [105.816458287, 9.373877873000057],
              [105.81624365600001, 9.372509515000081],
              [105.81573601800002, 9.371764232000055],
              [105.8149974670001, 9.371187299000015],
              [105.81419682400012, 9.371065082000117],
              [105.8128216260001, 9.371107615000083],
              [105.81093155000005, 9.371087830000045],
              [105.807713871, 9.372021779000049],
              [105.80132658300006, 9.374743842000031],
              [105.79637955800011, 9.376684009000067],
              [105.7924676910001, 9.378236556],
              [105.79073962600002, 9.379129047000044],
              [105.78998648400005, 9.37991800500013],
              [105.7882942950001, 9.382803152000106],
              [105.78742535400006, 9.383704722000095],
              [105.78667523800009, 9.384209089000082],
              [105.78558633300005, 9.384254477000107],
              [105.78348160200008, 9.383950794000027],
              [105.78220985900001, 9.385894351000077],
              [105.780351706, 9.388302156000082],
              [105.77691394200005, 9.392446379000051],
              [105.775251633, 9.39484577900007],
              [105.78266103600004, 9.401517733000018],
              [105.78240555800004, 9.401764864000127],
              [105.78053358200005, 9.403321795000073],
              [105.77912820600002, 9.403868364000088],
              [105.77784440800012, 9.403847882000081],
              [105.77752395699999, 9.403702221000019],
              [105.77660660000002, 9.403504931000038],
              [105.77380321400005, 9.407254359000115],
              [105.7731938900001, 9.408069296000042],
              [105.77207247900003, 9.40972738],
              [105.77141966200003, 9.410761788000064],
              [105.77014142100003, 9.412349463000068],
              [105.76551832500006, 9.410975844000101],
              [105.765116096, 9.411055564000035],
              [105.76465395200005, 9.411062172000049],
              [105.76420950100004, 9.410796319000079],
              [105.76304388100004, 9.410907245000109],
              [105.76133260800003, 9.411309118000053],
              [105.75975133000003, 9.411732876000121],
              [105.75771353300009, 9.411452003000063],
              [105.75640883500004, 9.411127176000099],
              [105.75156028200007, 9.409469154000048],
              [105.74716612900002, 9.408196041000048],
              [105.74691981100007, 9.408118015000076],
              [105.74524961499999, 9.407536234000078],
              [105.74416047700002, 9.406643818000047],
              [105.74368403500004, 9.406309628000107],
              [105.74279990400007, 9.405459138000081],
              [105.74141242100002, 9.40334179800004],
              [105.740960171, 9.401701746000056],
              [105.7402636260001, 9.400900003000066],
              [105.73937184900004, 9.400750207000018],
              [105.7380523190001, 9.401016243000091],
              [105.73669023400002, 9.401662387000085],
              [105.73573161700001, 9.402117129000052],
              [105.73582797600008, 9.396555591000054],
              [105.73538454200008, 9.395970671000063],
              [105.73427858100005, 9.395753290000107],
              [105.73029726300007, 9.395029250000063],
              [105.72911821800002, 9.395104915000017],
              [105.72794086300004, 9.395986086000095],
              [105.72690967700008, 9.396281133000082],
              [105.72580356100005, 9.395990481000103],
              [105.725118213, 9.395171912000054],
              [105.72464768900007, 9.393860844000111],
              [105.72435085700002, 9.393926112000093],
              [105.72274906600009, 9.39404259900005],
              [105.72073174700006, 9.393711715000029],
              [105.71752079300003, 9.391227442000092],
              [105.71678521900002, 9.391155862000032],
              [105.71605701100007, 9.391262588000069],
              [105.71508764000006, 9.391724385000087],
              [105.71411238000012, 9.392354960000125],
              [105.71358453800005, 9.392768138000028],
              [105.71336990100005, 9.393124144000085],
              [105.71312951900006, 9.394208584000106],
              [105.71295807200008, 9.394550663000077],
              [105.712610286, 9.394933811000071],
              [105.71229897100008, 9.395202096000064],
              [105.71186570000003, 9.395442248000039],
              [105.70847733600007, 9.398762452000129],
              [105.70480159500005, 9.397934904000048],
              [105.70447220000007, 9.39667697000006],
              [105.70571321100007, 9.394133016000071],
              [105.70573294700002, 9.393730257000053],
              [105.70535043400008, 9.391183861000039],
              [105.7052917980001, 9.390363229000085],
              [105.70521799900004, 9.389075652000132],
              [105.705195008, 9.3882690940001],
              [105.70433796000006, 9.382923859000057],
              [105.7040614700001, 9.381199397000016],
              [105.70389887500005, 9.380005807000112],
              [105.70375707300005, 9.379597469000077],
              [105.70342170400001, 9.379219484000066],
              [105.70261380500004, 9.378904575000123],
              [105.70158987600006, 9.378625599000133],
              [105.70086513200005, 9.378557804000119],
              [105.69997344700008, 9.378628799000042],
              [105.69853857000001, 9.378839358000056],
              [105.69722950200006, 9.379243533000102],
              [105.69564962800007, 9.380119059000068],
              [105.69435356500004, 9.380550002000108],
              [105.69179129400004, 9.381007848000065],
              [105.69070917700004, 9.381066557000038],
              [105.68937029200002, 9.380899358000059],
              [105.68837311500002, 9.380703193000082],
              [105.686182882, 9.380016792000044],
              [105.68181723300009, 9.385612595000035],
              [105.680331521, 9.38511325300002],
              [105.67736212400007, 9.385118952000047],
              [105.67533163900001, 9.385743966000069],
              [105.67178187900002, 9.391129467000068],
              [105.67084255400002, 9.39131388500005],
              [105.66913921400004, 9.391032014000087],
              [105.66675319600006, 9.390876126000068],
              [105.66439275300007, 9.39064607400004],
              [105.66290821500007, 9.390026953000062],
              [105.662224565, 9.389971628000103],
              [105.65937421000005, 9.388759979000037],
              [105.6588327150001, 9.388562874000071],
              [105.65739279400007, 9.387688185000085],
              [105.65459937700004, 9.386448079000063],
              [105.654029929, 9.386534045000071],
              [105.65220840100001, 9.38718835700003],
              [105.64846617000009, 9.388780148000107],
              [105.64832423900006, 9.389035134000066],
              [105.64824038100005, 9.389884338000075],
              [105.64810502800005, 9.393677064000093],
              [105.64735180300008, 9.394499204000082],
              [105.64539034300006, 9.396512237000108],
              [105.64319555500001, 9.395582273000079],
              [105.6422264170001, 9.395159500000093],
              [105.64114398400008, 9.395076553000068],
              [105.64054598400007, 9.395134247000046],
              [105.63761418000004, 9.396101815000078],
              [105.63727267200004, 9.396243944000025],
              [105.63534639400008, 9.397847321000084],
              [105.63789956600004, 9.401541519000121],
              [105.63844147200011, 9.402231145000071],
              [105.63900871400006, 9.403288019000032],
              [105.639351526, 9.403853445],
              [105.63960628300003, 9.405057371000057],
              [105.63955734600006, 9.40578162300003],
              [105.639496482, 9.406682471000048],
              [105.63900241000007, 9.407618245000025],
              [105.63679975200007, 9.410197708000021],
              [105.63492085100005, 9.410710527000086],
              [105.63150470400009, 9.411678938000099],
              [105.62757646700007, 9.412987840000129],
              [105.62315848800009, 9.414084797000031],
              [105.62210201500008, 9.414397808000084],
              [105.61876585800009, 9.415747821000052],
              [105.61835974100006, 9.415811387000071],
              [105.61666246100005, 9.414125019000073],
              [105.61531998000008, 9.41197639600002],
              [105.61515497200001, 9.411283280000047],
              [105.6152806100001, 9.409853805000077],
              [105.61522629600006, 9.409124201000017],
              [105.61512312200004, 9.408644976000067],
              [105.61457516200009, 9.408371894000121],
              [105.61411693500001, 9.408143530000055],
              [105.61310479100011, 9.407921568000075],
              [105.61081486800001, 9.409224753000077],
              [105.60371889600006, 9.415222869000049],
              [105.60347020600008, 9.415534620000086],
              [105.60159161000007, 9.417706287],
              [105.60048759400004, 9.418846649000033],
              [105.60086309500011, 9.419667830000032],
              [105.601143119, 9.420415159000049],
              [105.60134034500007, 9.421316856000049],
              [105.60099917100008, 9.421630891000097],
              [105.59950643700003, 9.423161757000019],
              [105.59750131200005, 9.42489158700006],
              [105.59623786600004, 9.423124838000073],
              [105.59473920700003, 9.421216979000045],
              [105.594056161, 9.42154360900007],
              [105.59337332200008, 9.421997595000063],
              [105.59246277300007, 9.422536864000096],
              [105.59136019000005, 9.423217967000051],
              [105.58911211500003, 9.424481186000032],
              [105.586829693, 9.425814466000036],
              [105.58636757800005, 9.42616040400006],
              [105.57872421100011, 9.412384008000084],
              [105.57712957600006, 9.413998221000062],
              [105.57647072100008, 9.414613008000037],
              [105.55364618600001, 9.440339550000052],
              [105.54307186000004, 9.452078610000035],
              [105.54213455800001, 9.471296861000054],
              [105.54229963900009, 9.473307339000046],
              [105.54239465500005, 9.474464501000044],
              [105.54342136200009, 9.478391283000052],
              [105.54499591399998, 9.48589708300007],
              [105.54719993, 9.508154373000098],
              [105.54746005500003, 9.511279758000056],
              [105.54869365300004, 9.522539228000058],
              [105.54957470700003, 9.530580296000045],
              [105.55095365500009, 9.541814047000051],
              [105.55237120300004, 9.55013233700006],
              [105.55271529900008, 9.552920456],
              [105.55340235200001, 9.556974436000052],
              [105.55383477099998, 9.561366725000118],
              [105.55333094100004, 9.565422605000061],
              [105.55316545400004, 9.568295208000064],
              [105.55274407600007, 9.570745818000058],
              [105.54859082000007, 9.58012973900013],
              [105.54545308300007, 9.586301770000103],
              [105.54458026400005, 9.587162551000093],
              [105.54688786000003, 9.58983481000004],
              [105.55211503200007, 9.596020097000045],
              [105.55569660600003, 9.597790078000033],
              [105.55633487900008, 9.599201615000117],
              [105.55607065900007, 9.602473493000028],
              [105.55648636100008, 9.604896684000062],
              [105.55890965000005, 9.606683780000045],
              [105.56111598800007, 9.608028613000029],
              [105.56356535100005, 9.607617456000115],
              [105.5658158840001, 9.605225314000075],
              [105.56805339000005, 9.603932253000023],
              [105.57157218300006, 9.607051016000055],
              [105.57264431200012, 9.610361220000117],
              [105.57298575900003, 9.614176815000073],
              [105.57369317500005, 9.615649841000094],
              [105.57429996700006, 9.616145162000043],
              [105.57691591000005, 9.615734456000078],
              [105.58261491300006, 9.612895053000068],
              [105.58711302500009, 9.608330120000106],
              [105.59099300300005, 9.604619548000047],
              [105.59220476800003, 9.603985860000107],
              [105.59337525100007, 9.603897785000102],
              [105.59399006600005, 9.604276026000134],
              [105.5947379670001, 9.605670680000065],
              [105.59540087700006, 9.608891429],
              [105.59620807300007, 9.610664777000098],
              [105.59853688600002, 9.613022638000055],
              [105.601364168, 9.616331256000075],
              [105.60349255100007, 9.619505833000106],
              [105.6048788140001, 9.620781796000076],
              [105.60621460600005, 9.620923093000043],
              [105.60844993400008, 9.620381627000095],
              [105.61010776300003, 9.620211560000056],
              [105.61577440100011, 9.620339233000051],
              [105.61763088500005, 9.620370308000046],
              [105.620212877, 9.620657531000097],
              [105.62828917300006, 9.621694322000083],
              [105.63621931300001, 9.624029482000116],
              [105.63952185200006, 9.625644003000032],
              [105.64471446900009, 9.626132698000054],
              [105.64515435100007, 9.626174092000015],
              [105.64764275200008, 9.635337107000018],
              [105.65138072300002, 9.636910330000069],
              [105.66028303800002, 9.640656754000075],
              [105.66441700100003, 9.642498122000108],
              [105.66767503100004, 9.634000705000021],
              [105.68386603000005, 9.648550873000071],
              [105.69279395800005, 9.658523978000043],
              [105.70041693899999, 9.663994053000112],
              [105.75501442200004, 9.698316530000016],
              [105.77571616400007, 9.711316766000076],
              [105.77592564, 9.711464808000068],
              [105.77670246700005, 9.711149372000081],
              [105.77795298800004, 9.710816415000066],
              [105.78980286000005, 9.717863690000071],
              [105.79542710300008, 9.721208129000035],
              [105.79614588300005, 9.72163554700005],
              [105.7970396040001, 9.722166987000056],
              [105.8192621530001, 9.735525375000085],
              [105.83443187000006, 9.744637800000076],
              [105.84123306700005, 9.749818794000118],
              [105.84165822400006, 9.75325659900008],
              [105.84281369300001, 9.764940873000059],
              [105.84332873300008, 9.766385468000037],
              [105.84643903200003, 9.774634937000124],
              [105.85136239200007, 9.802372114000056],
              [105.85296493000006, 9.806518132000084],
              [105.84697317300008, 9.808039857000049],
              [105.84848844900004, 9.815132737000097],
              [105.85052986600002, 9.823555955000083],
              [105.85518029100004, 9.844088262000115],
              [105.85698215800009, 9.848879540000064],
              [105.85917968800001, 9.854602367000085],
              [105.86154944100007, 9.853798820000035],
              [105.86479676500008, 9.87014386100009],
              [105.86508414500005, 9.871590279000129],
              [105.8651822950001, 9.872084277000058],
              [105.8673690140001, 9.881157859000032],
              [105.86637048400009, 9.885291074000067],
              [105.86677716600008, 9.885957983000091],
              [105.86726048300009, 9.886748258000109],
              [105.86744357100005, 9.887038287000037],
              [105.86850001600007, 9.888711771000123],
              [105.86977792400002, 9.890822398000053],
              [105.86701070300003, 9.89238411800004],
              [105.87198464600009, 9.901294105000067],
              [105.8716466130001, 9.907872985000042],
              [105.87293943800003, 9.915549837000091],
              [105.87330340700004, 9.916978166000055],
              [105.8803480870001, 9.924910074000069],
              [105.88450102200008, 9.928918962000031],
              [105.88621456200011, 9.929897055000046],
              [105.88792739000007, 9.930607156000091],
              [105.88958828300009, 9.930317112000074],
              [105.89042975299999, 9.93197399100003],
              [105.89223703300004, 9.934157698000055],
              [105.89537092100008, 9.939078380000053],
              [105.89594228100002, 9.938696031000037],
              [105.89643587500009, 9.938327217000074],
              [105.89706026400006, 9.937856606000127],
              [105.89766161400009, 9.937403356000106],
              [105.89798667900007, 9.937158346000084],
              [105.89857564000003, 9.936714430000036],
              [105.89881126700008, 9.936536830000085],
              [105.89913588700003, 9.936292153000091],
              [105.89952033900005, 9.936002376000095],
              [105.90114435300003, 9.934778279000065],
              [105.90458844700007, 9.932715698000026],
              [105.90627204000002, 9.932544093000038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 51, code: "AD01", ten_tinh: "Sơn La" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [103.55201390400001, 22.023793759000029],
              [103.55384293900008, 22.023732486000114],
              [103.55633208000006, 22.024141582000119],
              [103.55874207600007, 22.024162804000099],
              [103.56666023000011, 22.021909345000076],
              [103.56867178500001, 22.020223448000117],
              [103.56984142100006, 22.019614210000071],
              [103.571340338, 22.019317559000037],
              [103.57547597500006, 22.018983661000036],
              [103.57986857500006, 22.018346375000064],
              [103.58244930100008, 22.017904133000066],
              [103.58695744500005, 22.015852418000037],
              [103.590712991, 22.014258695000066],
              [103.59167136200008, 22.014034528000082],
              [103.59480444000005, 22.01339357600007],
              [103.59961300800011, 22.013391152000047],
              [103.60646608400003, 22.006211957000055],
              [103.61490581500004, 21.999785397000068],
              [103.61923986500008, 21.997363617000062],
              [103.62171280900006, 21.996924785000083],
              [103.6227159150001, 21.996573091000116],
              [103.62452737900006, 21.99568013800004],
              [103.62837434200004, 21.992784296000067],
              [103.63241176000005, 21.990263549000041],
              [103.63608532100007, 21.992842765000105],
              [103.6416075440001, 21.995086689000054],
              [103.64389119200004, 21.995317108000023],
              [103.646980001, 21.995145396000026],
              [103.64825399299998, 21.994914952000052],
              [103.64966848400005, 21.993967608],
              [103.65023434200006, 21.993142951000074],
              [103.65031639700001, 21.99170526300005],
              [103.65002490000005, 21.986963811000045],
              [103.65020121100007, 21.985760097000103],
              [103.65133949300011, 21.984923399000067],
              [103.65481688400006, 21.982948422000078],
              [103.65637940800011, 21.982050340000022],
              [103.65673158000003, 21.981335741000052],
              [103.65690067300008, 21.980992626000081],
              [103.65745319000004, 21.979360446000037],
              [103.658302855, 21.976917415000052],
              [103.65896686100005, 21.975290631000099],
              [103.659370849, 21.974006671],
              [103.65938541600005, 21.973356936000066],
              [103.65892350100002, 21.971769284000061],
              [103.65785570400004, 21.968359014000072],
              [103.65779060000008, 21.966825522000107],
              [103.65825960700008, 21.964768491000086],
              [103.65967091600004, 21.961731554000075],
              [103.66220527000004, 21.957416408000086],
              [103.66280674800007, 21.956104828],
              [103.66283273800009, 21.954944531000109],
              [103.66290844600005, 21.953785209000017],
              [103.66332391800005, 21.953003961000036],
              [103.66424806100004, 21.951721860000092],
              [103.66509045500001, 21.951009737000057],
              [103.66698614400011, 21.949407150000063],
              [103.66976038500005, 21.947696726000032],
              [103.67572564100006, 21.946768010000106],
              [103.68087921400006, 21.945428695000047],
              [103.68468504300009, 21.944295290000056],
              [103.68637298000004, 21.943329710000015],
              [103.68710392100003, 21.941823518],
              [103.68712387800008, 21.9408833190001],
              [103.68703052600009, 21.938374112000055],
              [103.68777509000006, 21.933977353000067],
              [103.68762742600009, 21.932790425000093],
              [103.687302129, 21.931762570000046],
              [103.68612954600006, 21.930811162000019],
              [103.68566027500005, 21.929548335000071],
              [103.68518893300003, 21.928378341000077],
              [103.68491647900005, 21.927212192000042],
              [103.68448763800009, 21.9263680500001],
              [103.68312554000002, 21.924995025],
              [103.68187895600008, 21.924018921000062],
              [103.68135173000007, 21.923126427000028],
              [103.68132996500005, 21.921872259000082],
              [103.68402190200003, 21.92159945700007],
              [103.68623942000009, 21.920295860000039],
              [103.68835103900001, 21.914811],
              [103.69271606400005, 21.914222249000126],
              [103.69598053700005, 21.912799499000087],
              [103.69958240600009, 21.90961865400007],
              [103.70139037100003, 21.906588825000114],
              [103.70316673900001, 21.903860177000034],
              [103.70373106700006, 21.903081659000101],
              [103.70379210600007, 21.902572035000034],
              [103.70381372500007, 21.90159728700003],
              [103.70333073200007, 21.898708880000051],
              [103.70273466400005, 21.894192956000055],
              [103.70248489800008, 21.888638843000052],
              [103.70245887100006, 21.887570264000097],
              [103.70263477500005, 21.886366276000047],
              [103.7032000840001, 21.885541320000087],
              [103.70415276500006, 21.885188213],
              [103.70609135500005, 21.885225650000081],
              [103.70807759899999, 21.885356866000016],
              [103.71050093100004, 21.885960872000076],
              [103.71274876000004, 21.886631126000019],
              [103.71309671500005, 21.886637834000041],
              [103.71340214900002, 21.8863186620001],
              [103.71328692300007, 21.884783984000045],
              [103.71331979100003, 21.883298588000066],
              [103.71380207700005, 21.881728984000048],
              [103.714668649, 21.880770480000052],
              [103.71566482600008, 21.880696797],
              [103.72088810100007, 21.880611610000052],
              [103.72101519100002, 21.879360211000076],
              [103.72046335200011, 21.875077231000084],
              [103.72005190300007, 21.873443954000038],
              [103.71925308200007, 21.871338816000026],
              [103.71496978400005, 21.866031867000046],
              [103.711041499, 21.861544334000094],
              [103.70757680300004, 21.857460379000045],
              [103.70583665900003, 21.852968469000089],
              [103.70516936600006, 21.849426051000087],
              [103.70367099100008, 21.844125987000048],
              [103.70284261700002, 21.843366905000039],
              [103.70042602100008, 21.842484236000104],
              [103.69781581400011, 21.841365570000093],
              [103.69590137900008, 21.84026035100004],
              [103.69449217, 21.838793361000072],
              [103.6935776980001, 21.835199574000086],
              [103.69272326500001, 21.831142494000041],
              [103.69372830500004, 21.828421819000084],
              [103.69483063500003, 21.825795905000064],
              [103.69579231700003, 21.825024989000049],
              [103.70014229300006, 21.823901638],
              [103.7031806940001, 21.823635281000087],
              [103.70574855300002, 21.823290125000128],
              [103.70799804500007, 21.822729801000037],
              [103.70950830900006, 21.82187652700005],
              [103.7112327450001, 21.820330717000047],
              [103.71180809500001, 21.819041387000127],
              [103.71317500900005, 21.815677324000049],
              [103.71385690300006, 21.81406492900004],
              [103.71561171400005, 21.812264204000087],
              [103.71586837100006, 21.811897587000125],
              [103.72006223700004, 21.808820143000048],
              [103.72203080500003, 21.807464694000075],
              [103.72262910900007, 21.807383319000095],
              [103.72450597000001, 21.807930315000057],
              [103.72968431300011, 21.809794768000046],
              [103.73331953400006, 21.81063091500004],
              [103.73679568199999, 21.810790501],
              [103.74142283700004, 21.810600534000102],
              [103.74402600400005, 21.809767937000046],
              [103.74541625600006, 21.809841001000052],
              [103.74936586600009, 21.811077675000057],
              [103.75144261200006, 21.811581829000112],
              [103.75303468800007, 21.811519365],
              [103.75504156000002, 21.810675257000064],
              [103.75645316800004, 21.809773317000044],
              [103.75723101400008, 21.808301929000073],
              [103.75741259300001, 21.806819164000046],
              [103.75692090800001, 21.804301750000029],
              [103.754782608, 21.79980216900006],
              [103.75254307800007, 21.794255560000053],
              [103.75191159400005, 21.791317384000038],
              [103.75136423800005, 21.789077494000075],
              [103.75164233200006, 21.785459975000073],
              [103.75168099400005, 21.783695728000026],
              [103.75180786800007, 21.781875119000027],
              [103.75262820100001, 21.780729615000013],
              [103.754622614, 21.779304636000063],
              [103.75557518700003, 21.778928034000074],
              [103.7561211650001, 21.778961683000105],
              [103.75646384700011, 21.779200466000056],
              [103.75775797600008, 21.781384968000062],
              [103.75829939000002, 21.781627541000056],
              [103.76013353500008, 21.781848328000116],
              [103.76227489400006, 21.781656933000093],
              [103.76340286900005, 21.781213972000032],
              [103.76514475500002, 21.780538856000042],
              [103.76647894100009, 21.779930078000028],
              [103.76752450000001, 21.779783007000027],
              [103.76820857000003, 21.779823866000037],
              [103.76986834700006, 21.780189439000083],
              [103.77099288500001, 21.780516983],
              [103.77188819000001, 21.780702088000048],
              [103.77283161600009, 21.780743265000076],
              [103.77491822600008, 21.780782945000077],
              [103.77606442400005, 21.780642168000064],
              [103.77671887100003, 21.780259802000046],
              [103.77695052800001, 21.779892630000042],
              [103.77721512400005, 21.779243739],
              [103.77776542500003, 21.777222510000094],
              [103.77867716500006, 21.775013310000091],
              [103.77892258200002, 21.774683996000107],
              [103.77923245400008, 21.774385514000102],
              [103.77994312400008, 21.77370229600006],
              [103.78036379100007, 21.772641990000082],
              [103.78044378700012, 21.771250067000025],
              [103.78014353100005, 21.769061312000112],
              [103.78006742200006, 21.767991554000062],
              [103.78020719300008, 21.766136283000037],
              [103.78057615200007, 21.765167867000024],
              [103.78132939100003, 21.764810584000124],
              [103.78336816200006, 21.764756385000084],
              [103.78709292600004, 21.764873479000087],
              [103.78759777400008, 21.764511465000069],
              [103.78822112200004, 21.763269167000132],
              [103.78943426600006, 21.760040756000087],
              [103.79081078500009, 21.758441123000068],
              [103.79369985500003, 21.756986235000042],
              [103.79509846800002, 21.754365109000062],
              [103.79827386200003, 21.750012471000076],
              [103.79890413600005, 21.748445101000065],
              [103.80118102800006, 21.743123155000085],
              [103.80191563000008, 21.741325473000117],
              [103.80327743600006, 21.74063650100004],
              [103.80746083600006, 21.740403014],
              [103.8087922940001, 21.740524067000067],
              [103.81071356300008, 21.74069872900013],
              [103.8113965200001, 21.740645086000043],
              [103.81254497200008, 21.740388034000091],
              [103.8177191500001, 21.737791367000092],
              [103.82062903600007, 21.73649905300001],
              [103.82235214700009, 21.736090180000112],
              [103.82354422900005, 21.736112594000097],
              [103.82547135699998, 21.73661331700005],
              [103.82772320900006, 21.738188492000084],
              [103.83107037100007, 21.744289874000074],
              [103.83497608400002, 21.738278137000044],
              [103.83269545300006, 21.735727025000081],
              [103.83121681100002, 21.730543220000072],
              [103.83280963200009, 21.725788610000052],
              [103.83347208000001, 21.721550688000058],
              [103.83519680500007, 21.717588146000111],
              [103.83578000800001, 21.715880340000027],
              [103.8363747500001, 21.712477215],
              [103.83648820700007, 21.709506360000127],
              [103.83666709500005, 21.70811611200012],
              [103.8376585190001, 21.705904935000042],
              [103.83856994400001, 21.703947741000015],
              [103.83963375200001, 21.702992147000089],
              [103.84074124000009, 21.702316076000017],
              [103.84312996000001, 21.702128533000042],
              [103.84665391800009, 21.702287363000039],
              [103.848037506, 21.70031572300006],
              [103.8488052980001, 21.696938931000084],
              [103.84978610000007, 21.69405386900003],
              [103.85065414200004, 21.692955183000073],
              [103.85135929600003, 21.69250381500003],
              [103.85285204200008, 21.692392327000043],
              [103.85404087300005, 21.692553889000088],
              [103.85887373000006, 21.694223498000028],
              [103.8652258210001, 21.689882217000012],
              [103.86897133600012, 21.687791774000061],
              [103.87077680500002, 21.686989160000088],
              [103.87307092600003, 21.686567258000089],
              [103.87451099100008, 21.686594013000082],
              [103.87742695800003, 21.687298523000017],
              [103.87982172400011, 21.689154697000035],
              [103.87976741300005, 21.696085183000058],
              [103.87974910800006, 21.698420972000051],
              [103.87989486300003, 21.706762074],
              [103.88008301800005, 21.709599196000084],
              [103.88095261800007, 21.70959210000003],
              [103.88810082900008, 21.702849480000097],
              [103.89585831900004, 21.700159293000084],
              [103.89833273600006, 21.699438516000043],
              [103.90009245400009, 21.698449024000062],
              [103.90104778200008, 21.697909216000077],
              [103.90262256100007, 21.696265929000091],
              [103.90382420400003, 21.695823558000079],
              [103.90526532200008, 21.695803674000032],
              [103.90977165000007, 21.696490609000115],
              [103.91283980800009, 21.697058083000087],
              [103.9145875880001, 21.697810269000058],
              [103.92011913400002, 21.701721143000086],
              [103.92821308500004, 21.69717786100005],
              [103.93240695300003, 21.695001699000024],
              [103.93574773400005, 21.692043348000119],
              [103.93589078800007, 21.687586349000028],
              [103.93429872800004, 21.679404374000022],
              [103.93422856200002, 21.673270956000046],
              [103.93428890900003, 21.671576420000079],
              [103.93474746500006, 21.671027349000035],
              [103.93560034000009, 21.670624855000042],
              [103.94801568200009, 21.670758834000111],
              [103.95188866800008, 21.670829470000093],
              [103.95767477700009, 21.670865187000032],
              [103.96011070900003, 21.670770154000074],
              [103.96260016100004, 21.670490236000091],
              [103.9644021830001, 21.669826155000038],
              [103.96618309400002, 21.665398723000081],
              [103.96792092600001, 21.665430286000031],
              [103.97283389500008, 21.671628406000117],
              [103.97714472200008, 21.674540333],
              [103.98086589900004, 21.679067026],
              [103.98122927700007, 21.678262975000081],
              [103.98581986500002, 21.675268654000043],
              [103.98712508400011, 21.675170361000085],
              [103.98958510100007, 21.67594605200005],
              [103.99237332700002, 21.676605765000097],
              [103.9936096420001, 21.676689013000058],
              [103.99537458500002, 21.676416159000041],
              [103.99747148700006, 21.675844586],
              [103.99938050600009, 21.674903986000068],
              [104.00260600399999, 21.673316749000016],
              [104.00399514700008, 21.672305796000032],
              [104.00420937000004, 21.671395588000038],
              [104.00380028200004, 21.666992196000123],
              [104.01169093200011, 21.668787824000013],
              [104.01428998400003, 21.669139181000041],
              [104.0166986560001, 21.669243342000044],
              [104.01865390000006, 21.669217464000035],
              [104.02053776300005, 21.669494991000079],
              [104.02189931700009, 21.669824078000133],
              [104.02293145900005, 21.670330064000076],
              [104.02363669300004, 21.670891143000048],
              [104.02427055500009, 21.671755621000074],
              [104.02464013700002, 21.672798182000093],
              [104.0286741030001, 21.671832693000056],
              [104.03040048600005, 21.671213188000046],
              [104.03135483200005, 21.670696009000068],
              [104.03252489300004, 21.669346475000047],
              [104.03360274500005, 21.66764686600002],
              [104.03550728400008, 21.66620590400008],
              [104.04031566500001, 21.664247632000055],
              [104.04194168500008, 21.663672692000091],
              [104.04298831100009, 21.662889979000077],
              [104.04442353700009, 21.660708874000086],
              [104.04606702300001, 21.659274767000092],
              [104.04798628300009, 21.658321729000107],
              [104.05127222, 21.657938852000044],
              [104.054739485, 21.657187498000049],
              [104.06004151500011, 21.655969243000058],
              [104.06341694200006, 21.654844462000078],
              [104.06778068000008, 21.653353868000025],
              [104.07072976100001, 21.652430474000091],
              [104.07284926500006, 21.652003405000109],
              [104.07493269900004, 21.651517604000055],
              [104.07738241300005, 21.650724666000087],
              [104.08020122500005, 21.648869705],
              [104.08218579500003, 21.647127737000105],
              [104.08549209400003, 21.644491522000031],
              [104.08763348400002, 21.643588499000096],
              [104.09117108500007, 21.64302363200008],
              [104.09543572700005, 21.64581643500005],
              [104.09844048200006, 21.647669480000076],
              [104.09986675500004, 21.648368169000065],
              [104.10104423400003, 21.647854593000112],
              [104.10303611900008, 21.646356470000057],
              [104.10471804500007, 21.646072394],
              [104.10703419800004, 21.646356920000095],
              [104.11356404200004, 21.647655978000024],
              [104.11564333600003, 21.648609897000078],
              [104.12075318100005, 21.652624837000097],
              [104.12499789900008, 21.653337733000022],
              [104.13165871100009, 21.654313324],
              [104.13506268800009, 21.654848785000077],
              [104.14213695900003, 21.655018261000066],
              [104.14550987800003, 21.655049101000046],
              [104.14837985300008, 21.655311220000058],
              [104.15082647200011, 21.655761387],
              [104.15489621100004, 21.656540356000065],
              [104.15790285400003, 21.656453038000031],
              [104.15967603300001, 21.65594945900002],
              [104.16308188600009, 21.653882956000103],
              [104.16712506900004, 21.651606733000058],
              [104.17127138400009, 21.649761933],
              [104.17514952300007, 21.649550026000064],
              [104.17986088100008, 21.649271098000078],
              [104.18418675200006, 21.649020284000109],
              [104.18810597300006, 21.648611418000115],
              [104.19213530400003, 21.648285680000079],
              [104.19384398700001, 21.649162815000089],
              [104.19771648100006, 21.653038600000052],
              [104.2011360000001, 21.656592966000062],
              [104.20293492100009, 21.658574890000089],
              [104.207419762, 21.663540997000027],
              [104.20859821600007, 21.664873483000058],
              [104.20943403400004, 21.666583372000048],
              [104.21024050400007, 21.668525029000115],
              [104.21100635000002, 21.670640188000064],
              [104.21266605600002, 21.672131825000029],
              [104.2152250340001, 21.673360039000045],
              [104.21672734100005, 21.673374025000044],
              [104.22093815800012, 21.672679221000024],
              [104.22377122800005, 21.672588047000119],
              [104.22730759000008, 21.672729450000091],
              [104.23054467000004, 21.672296644000077],
              [104.23306984900009, 21.671433632000081],
              [104.23495852400004, 21.670733995000099],
              [104.23692214800005, 21.670651145000086],
              [104.23902933200006, 21.670849436000061],
              [104.2427526590001, 21.672236416000068],
              [104.25148296100009, 21.67220975],
              [104.25304792300008, 21.671550956000104],
              [104.25383685100007, 21.670565489000104],
              [104.25398216900003, 21.666898015000058],
              [104.25499622600009, 21.665184670000045],
              [104.25645589500007, 21.66418726800012],
              [104.26018258500004, 21.664110694],
              [104.26241251100006, 21.664380517000033],
              [104.26517073000011, 21.665145437],
              [104.26709372500005, 21.665678704000065],
              [104.26979762900005, 21.665840285000023],
              [104.272911107, 21.664719571000113],
              [104.27524050300009, 21.663690192000026],
              [104.27813554300008, 21.662948998000076],
              [104.280394147, 21.662371312000062],
              [104.28425159200005, 21.661948111000036],
              [104.2864615870001, 21.661961856000019],
              [104.28790576300005, 21.661114967000024],
              [104.29213341500008, 21.656911692000058],
              [104.29460648, 21.654200469000081],
              [104.29618732700004, 21.653344184000019],
              [104.29963805200001, 21.653401718000076],
              [104.30291525300001, 21.654095074000068],
              [104.30698273800002, 21.655672579000026],
              [104.31158338000009, 21.657293699000043],
              [104.31368652500002, 21.65770026600002],
              [104.31621467000004, 21.656650523000032],
              [104.31971068800001, 21.654978022000115],
              [104.32190107100007, 21.654712350000068],
              [104.32499679300005, 21.655158485000037],
              [104.32847799400005, 21.655576104000126],
              [104.33406992600008, 21.655343305000052],
              [104.33660398200006, 21.654630221000026],
              [104.33777620500005, 21.653046840000023],
              [104.3382801700001, 21.648711509000051],
              [104.33835997600004, 21.647772085000035],
              [104.33835123000003, 21.644287703000131],
              [104.33755927500005, 21.642172471],
              [104.33422750700007, 21.639771416000109],
              [104.33232363200001, 21.638683077000032],
              [104.32925737300006, 21.638005225000079],
              [104.32870356200007, 21.637090157000038],
              [104.32661613000002, 21.632584099000063],
              [104.32487066200011, 21.63034846500009],
              [104.32186829100002, 21.628928234000085],
              [104.3207130820001, 21.628305137000048],
              [104.31992859500011, 21.627119064000055],
              [104.31787319500005, 21.622903739000066],
              [104.31656911800006, 21.620315264000034],
              [104.31506062700004, 21.618687396000077],
              [104.31201723700011, 21.61682495600008],
              [104.30778182400006, 21.614327060000079],
              [104.30585504200006, 21.612506315000068],
              [104.30407229000008, 21.60964260500009],
              [104.300494633, 21.60716709700008],
              [104.29649203600003, 21.604847040000053],
              [104.29129686500006, 21.603877547000039],
              [104.287546584, 21.602653350000089],
              [104.28573423600005, 21.600706538000033],
              [104.28560443300006, 21.599705460000074],
              [104.28591380500009, 21.599106649000085],
              [104.28790243900005, 21.597676404000055],
              [104.2893887060001, 21.59590089700005],
              [104.29062305200009, 21.592367260000067],
              [104.29134435800009, 21.590346628000034],
              [104.29186070800009, 21.586708027000064],
              [104.29207366500003, 21.58530612000007],
              [104.29303402500008, 21.581814282000074],
              [104.29546203000007, 21.572957253000077],
              [104.29537340000012, 21.572398223],
              [104.29018621200001, 21.571057063000062],
              [104.28733344400004, 21.569661930000095],
              [104.28611403300005, 21.568526407000022],
              [104.28289381700006, 21.562989791000042],
              [104.28051367300003, 21.560254996000069],
              [104.28024315400003, 21.557532306000034],
              [104.27878106900002, 21.556113864000089],
              [104.27615204600002, 21.553421280000059],
              [104.27533515000002, 21.552013631000122],
              [104.27521211500004, 21.550664084000104],
              [104.27667406000003, 21.548202746000108],
              [104.2778706120001, 21.54534197800011],
              [104.27948990800006, 21.541140783000046],
              [104.28111482200003, 21.53793865100004],
              [104.28258940400009, 21.536104730000091],
              [104.28747809800011, 21.532236963000081],
              [104.29082356600007, 21.527297769000064],
              [104.29175572400001, 21.525268809000053],
              [104.29106911100004, 21.522236974000108],
              [104.29093315200008, 21.520259842000122],
              [104.29100938300007, 21.518867096000079],
              [104.29165887400008, 21.516043431000043],
              [104.29214709800007, 21.513867601000044],
              [104.29296841800007, 21.51244082000003],
              [104.29083297800005, 21.503413485000067],
              [104.28672908900003, 21.498721154000044],
              [104.28304752000007, 21.49401255],
              [104.28250123000008, 21.491116460000015],
              [104.28330674400011, 21.488893595000036],
              [104.28406420500008, 21.488209237000078],
              [104.28393048700012, 21.487417007000076],
              [104.28166362200005, 21.486589013000042],
              [104.28130089700007, 21.486095000000113],
              [104.28116319000003, 21.485511810000091],
              [104.28113314300003, 21.484488957000011],
              [104.28147257700006, 21.482310531000067],
              [104.28171696700011, 21.480560365000123],
              [104.28175928800006, 21.477052522000051],
              [104.28155722700004, 21.475294854000104],
              [104.28102446300002, 21.474612087000096],
              [104.28019941300005, 21.473645589000064],
              [104.28073859000008, 21.471400762000044],
              [104.28457946300009, 21.465807215000062],
              [104.28676895000005, 21.463531904],
              [104.2912469510001, 21.460284076000043],
              [104.29178747600007, 21.459909723000067],
              [104.29508685800003, 21.458617171000057],
              [104.29858549800005, 21.458582656000047],
              [104.30008877100009, 21.457155476000089],
              [104.30110728100007, 21.454477055000076],
              [104.30030078200005, 21.452534969000055],
              [104.29866150900003, 21.44938229600006],
              [104.29826955300011, 21.446517633],
              [104.29787650600009, 21.444361667000052],
              [104.29702446300003, 21.440908375000056],
              [104.296665547, 21.439566248000027],
              [104.30088471900004, 21.438149513000049],
              [104.30142315800001, 21.437229010000088],
              [104.30174676600005, 21.434562133000057],
              [104.30567996000005, 21.433175379000069],
              [104.30998885900006, 21.43224793100007],
              [104.31219553000003, 21.431645626000062],
              [104.31642014100008, 21.42992658100011],
              [104.31790780600006, 21.42865],
              [104.31855264500004, 21.426034867000077],
              [104.32011696400006, 21.422017490000123],
              [104.32047084300004, 21.421012520000104],
              [104.32160405400001, 21.418800496000046],
              [104.32403846500009, 21.415982618000037],
              [104.32851902500008, 21.410537833000106],
              [104.33061151000001, 21.409398949000021],
              [104.33317452100007, 21.408953365000016],
              [104.33755916400004, 21.409234994000045],
              [104.34180829100006, 21.412105360000041],
              [104.34216228800005, 21.413063962000045],
              [104.34214174100003, 21.414155804000053],
              [104.34086256500007, 21.416888389000022],
              [104.34078061800004, 21.418606638000078],
              [104.34183845000008, 21.41972789400004],
              [104.34341226000001, 21.420450991000095],
              [104.34519799100005, 21.420457203000076],
              [104.34839374900005, 21.418023425000051],
              [104.35270828199999, 21.414783051000086],
              [104.3541877910001, 21.412599785000097],
              [104.35583953, 21.409826750000072],
              [104.35870439000007, 21.407828876000053],
              [104.36108853000007, 21.403022840000041],
              [104.36591362000006, 21.400301761000051],
              [104.36796663600002, 21.399266443],
              [104.36900881200009, 21.399237037000063],
              [104.37313586600007, 21.400687298000065],
              [104.37639829300008, 21.402622973000085],
              [104.38137710700001, 21.403622229000071],
              [104.38430924800005, 21.403321503000058],
              [104.38819379900012, 21.401816245000035],
              [104.39167674, 21.3998977030001],
              [104.39321056600008, 21.398098445000088],
              [104.39565679000006, 21.393234880000108],
              [104.39676130600006, 21.391846896000018],
              [104.39892640700005, 21.391440538000062],
              [104.40331862200001, 21.38995485600006],
              [104.40607638800003, 21.389011934000024],
              [104.40869712700007, 21.388101620000022],
              [104.41085838400009, 21.386556383000062],
              [104.41412744100003, 21.382089291000028],
              [104.4178412950001, 21.377699028000038],
              [104.41916742200004, 21.37503630900008],
              [104.41925339900006, 21.371714442000034],
              [104.41931946100001, 21.370146836000089],
              [104.41845621200008, 21.365856769000047],
              [104.41794147900004, 21.363512841000087],
              [104.41812188000004, 21.361795999000051],
              [104.41905959100009, 21.360695632000045],
              [104.426714383, 21.355683129000091],
              [104.42967776200007, 21.354987215000065],
              [104.43315935300009, 21.355101390000094],
              [104.44030607500007, 21.357493850000075],
              [104.44497371300008, 21.357836045000056],
              [104.44803324500002, 21.357304105000097],
              [104.452474006, 21.355132568],
              [104.45618218400004, 21.354378492000059],
              [104.46395893100001, 21.352817809000065],
              [104.46603691100009, 21.352397777000043],
              [104.46963064500007, 21.352478303000041],
              [104.47439480400008, 21.35364645300006],
              [104.47744233700008, 21.355821401000071],
              [104.47987274700009, 21.358532633000046],
              [104.48131379500003, 21.360426341000057],
              [104.48205280399999, 21.363436021000034],
              [104.482808639, 21.366201931000091],
              [104.48534932700008, 21.368310571000038],
              [104.49297254000004, 21.373904246000031],
              [104.49478141700004, 21.374664908000085],
              [104.49683859300009, 21.374720674000081],
              [104.4986142760001, 21.375260001000029],
              [104.50075221800007, 21.376339535000085],
              [104.50305876700004, 21.378386131000049],
              [104.50479441500003, 21.381155727000056],
              [104.50702811300002, 21.384502502000124],
              [104.50878013400003, 21.386365975000032],
              [104.50964376899999, 21.387297514000103],
              [104.5115289780001, 21.388651800000069],
              [104.51249731700003, 21.389968406000023],
              [104.51262189700003, 21.391318210000065],
              [104.51296637900002, 21.396308292000029],
              [104.51649630500005, 21.399303447000051],
              [104.51818107700009, 21.402164981000027],
              [104.51945919700006, 21.405464179000063],
              [104.52087121300011, 21.407749500000058],
              [104.52307501600009, 21.410026532000082],
              [104.52639355400005, 21.413076232],
              [104.52814088500003, 21.414532725000065],
              [104.53038980600003, 21.414986214000109],
              [104.53656469700007, 21.415036297000078],
              [104.53958747300007, 21.414514179000044],
              [104.5444711690001, 21.412996597000067],
              [104.54864531400007, 21.410739848000034],
              [104.55056070500009, 21.410421096000043],
              [104.55391213300001, 21.410240843000047],
              [104.55747204500004, 21.410191589000057],
              [104.56175539700007, 21.410606634000118],
              [104.56713432100005, 21.412885964000061],
              [104.56909191500003, 21.412985976000073],
              [104.5730645730001, 21.412036588000127],
              [104.57715527000008, 21.40931127100005],
              [104.57802996900006, 21.409638488000034],
              [104.57892529100008, 21.410907139000109],
              [104.57933058200003, 21.414654653000028],
              [104.57960230500004, 21.416831548000026],
              [104.57986574299998, 21.417484724000069],
              [104.58019644500008, 21.418304671000051],
              [104.58135477700009, 21.420135058000064],
              [104.58201849600003, 21.421179351000049],
              [104.58402484300004, 21.42274393400001],
              [104.58728004200007, 21.423816505000026],
              [104.59032356600001, 21.422817666000071],
              [104.59456952500005, 21.420431400000069],
              [104.59848327100005, 21.41930639300007],
              [104.60359568800001, 21.417002949000086],
              [104.60578943800006, 21.414945184000089],
              [104.60829558100004, 21.409906134000067],
              [104.6088503500001, 21.408671409000043],
              [104.6110938580001, 21.405173568000087],
              [104.61292645800003, 21.403191503000052],
              [104.61450021800005, 21.402541643000106],
              [104.61658891300002, 21.401481013000101],
              [104.61815227800005, 21.400655265000076],
              [104.61906984000004, 21.400032053000118],
              [104.62045604200006, 21.398763398000042],
              [104.62302130800003, 21.396711056000051],
              [104.62433082000004, 21.396277861],
              [104.62711572000006, 21.395855488000045],
              [104.62974639900003, 21.394315307000063],
              [104.63163927900007, 21.392415296000067],
              [104.63314080000005, 21.390915994000089],
              [104.6368835220001, 21.388160960000043],
              [104.63820836000012, 21.387553622000063],
              [104.64452912600005, 21.384128758000109],
              [104.64633972500012, 21.38410968200013],
              [104.64835404100006, 21.384500350000032],
              [104.65009864400008, 21.38543303700002],
              [104.65066846000008, 21.385464885000083],
              [104.65362549100004, 21.383673703000028],
              [104.657866224, 21.381495159000075],
              [104.66025537300007, 21.380996685000042],
              [104.6619991420001, 21.380534939000114],
              [104.6655264390001, 21.379460956000074],
              [104.66887591900004, 21.377907842000084],
              [104.67022298300003, 21.377440066000105],
              [104.67349988100008, 21.376501618000084],
              [104.67810978100005, 21.376640483000031],
              [104.68167639100008, 21.377611855000019],
              [104.68370868500003, 21.37912955800001],
              [104.68530013500006, 21.381779349000048],
              [104.68656387400009, 21.384017538000052],
              [104.6873679990001, 21.386306932000117],
              [104.68665443300003, 21.391071763000099],
              [104.68647407200004, 21.392196123000048],
              [104.6865961460001, 21.395218912000047],
              [104.68692187000005, 21.396478643000094],
              [104.68814535300002, 21.401086427000045],
              [104.68961406400004, 21.401491788000016],
              [104.69381491899999, 21.400927067000097],
              [104.69537339200006, 21.401159453000048],
              [104.69742411500002, 21.403072283000043],
              [104.69909747, 21.404572805000029],
              [104.70137299700008, 21.406395983000067],
              [104.70273591300004, 21.406462740000087],
              [104.70478325900007, 21.405656627],
              [104.70718045500008, 21.402497063000055],
              [104.70869131700003, 21.401148470000031],
              [104.71648491200006, 21.401508057000029],
              [104.72360909700004, 21.401857475000071],
              [104.72630593900007, 21.403709866000042],
              [104.73166472500007, 21.407286211],
              [104.73233965100005, 21.406970834000028],
              [104.73356337200005, 21.404990437000038],
              [104.73382828300004, 21.402507906000068],
              [104.73290972900006, 21.399612884000071],
              [104.73217383000009, 21.396918057000036],
              [104.73150150400006, 21.392655571000049],
              [104.73162793900005, 21.38734748800006],
              [104.73285750000008, 21.382799257000059],
              [104.73392948600005, 21.380247189000023],
              [104.73646577700003, 21.378355743000022],
              [104.73809929800009, 21.376323158000062],
              [104.73941233800011, 21.373437618000054],
              [104.74145606200001, 21.370597666],
              [104.74287233200005, 21.368945267000058],
              [104.74368715100005, 21.368411125000094],
              [104.74622, 21.368192698000051],
              [104.7473042230001, 21.36711637100008],
              [104.74747783300008, 21.365631612000065],
              [104.74710530500008, 21.364185307000035],
              [104.74536392800006, 21.363044250000129],
              [104.74541243000007, 21.361627355000046],
              [104.74652106500005, 21.356867911000066],
              [104.74739178500006, 21.352988049000118],
              [104.74815135500003, 21.352046367000099],
              [104.74935415000007, 21.352029159],
              [104.75436513400008, 21.353357557000052],
              [104.75531018300003, 21.353185481000111],
              [104.75531294000008, 21.350048142000055],
              [104.756555044, 21.348416296000039],
              [104.758743881, 21.347251467000028],
              [104.76070981400004, 21.346815421000095],
              [104.76453597600008, 21.347824153000069],
              [104.76677687400003, 21.347252625000031],
              [104.76715016000001, 21.345677755000025],
              [104.76678318800002, 21.340908169],
              [104.76685558600006, 21.335807976000076],
              [104.76764403700011, 21.330880862000079],
              [104.77568469100007, 21.326640393000048],
              [104.77646482800003, 21.326686603000056],
              [104.77840434200002, 21.327830372],
              [104.78251342600001, 21.329702869000045],
              [104.78459219900006, 21.330674295000037],
              [104.78814153200001, 21.333421679000075],
              [104.79079038200007, 21.335156619000095],
              [104.79356250100001, 21.336184477000053],
              [104.79825253700002, 21.332905680000103],
              [104.8003669810001, 21.331716101],
              [104.80226730300004, 21.331257514000065],
              [104.80485159500007, 21.331294799000133],
              [104.80920285200003, 21.332355230000033],
              [104.8116661830001, 21.334421546000023],
              [104.81278690100001, 21.333257427000071],
              [104.81534526000004, 21.324695626000079],
              [104.82475937100003, 21.317521861000088],
              [104.8282961140001, 21.315383757000106],
              [104.83364372100007, 21.314678546000017],
              [104.84009271400004, 21.312894529000026],
              [104.84081584700002, 21.312005878000036],
              [104.84167605600004, 21.310376545000054],
              [104.84161934600006, 21.308734141000052],
              [104.84088267600004, 21.297623166000079],
              [104.84056998200001, 21.291917154000117],
              [104.84052897400008, 21.291168803000105],
              [104.83978944100008, 21.285373358000115],
              [104.83941941200003, 21.283215472000094],
              [104.83933254500003, 21.282708886000101],
              [104.84140214800003, 21.275898050000116],
              [104.84144655600007, 21.273162516000085],
              [104.83919430700004, 21.268127072000098],
              [104.83908604300007, 21.264529369000051],
              [104.84082932600006, 21.262404355000086],
              [104.84328154900001, 21.260250348000028],
              [104.84723244200006, 21.258195815000036],
              [104.84875016300005, 21.257122924000079],
              [104.84893581000004, 21.255952885000085],
              [104.84863412400004, 21.253994125000105],
              [104.84845881700001, 21.249379053000084],
              [104.85007513600007, 21.242209488000086],
              [104.8507796960001, 21.23987409600003],
              [104.85320254300002, 21.234357673000126],
              [104.84666688400007, 21.231371957000086],
              [104.84263864300002, 21.227952716000061],
              [104.84273718200009, 21.227015925],
              [104.84409691300006, 21.225393480000051],
              [104.84628348200006, 21.224173707000112],
              [104.84946730500005, 21.223124479000077],
              [104.85239608600008, 21.22238430400003],
              [104.85484008900006, 21.220699007000029],
              [104.85712499400003, 21.218542315000022],
              [104.86042030200011, 21.213155282000073],
              [104.86213572400001, 21.210130404000083],
              [104.86233007600002, 21.2084130920001],
              [104.86171524600005, 21.205198788000068],
              [104.85931177100004, 21.199222572000039],
              [104.85811069700006, 21.193615179000069],
              [104.85612274300006, 21.187722898000061],
              [104.84955716300003, 21.18153085200003],
              [104.85624834400005, 21.177364701000023],
              [104.85729714400006, 21.174330208000036],
              [104.85569809800009, 21.170163434000067],
              [104.85362084300009, 21.164660573000035],
              [104.85142192600004, 21.158960430000029],
              [104.85058032500004, 21.154335126000134],
              [104.85162656100002, 21.15145689200002],
              [104.85616952400002, 21.148706546000092],
              [104.86793351500002, 21.145002809000069],
              [104.86972648000005, 21.142291406000076],
              [104.8746209350001, 21.13837270300003],
              [104.87799482700009, 21.135761730000091],
              [104.8816779680001, 21.134640749000063],
              [104.89486633900002, 21.130330026000088],
              [104.89509397200001, 21.12650163800005],
              [104.90056795100006, 21.122746795000054],
              [104.90719514100003, 21.119789882000092],
              [104.90950069300001, 21.118844641000045],
              [104.91087275100003, 21.116361512000111],
              [104.91148169500005, 21.114727872000039],
              [104.91141317500006, 21.113203169000094],
              [104.90641700300004, 21.113718733000027],
              [104.90509087000001, 21.113309200000074],
              [104.90220496100005, 21.111470259000065],
              [104.89695400500008, 21.109089833000013],
              [104.89140113100009, 21.107369715000068],
              [104.88602239700003, 21.105182710000037],
              [104.87734192300007, 21.101150396],
              [104.87323257900006, 21.09772976900004],
              [104.87009032200004, 21.093736234000104],
              [104.86994264300009, 21.09256113900004],
              [104.86995771699999, 21.091622952000044],
              [104.87125435700008, 21.088669667000111],
              [104.87128072500003, 21.087027826000067],
              [104.87096275200003, 21.086084909000036],
              [104.86924822, 21.083949214000071],
              [104.86770024700009, 21.081815848000041],
              [104.86687696100009, 21.078637011000097],
              [104.86649839900012, 21.076285576000053],
              [104.8644082000001, 21.076803367000082],
              [104.85576084100005, 21.080849397000101],
              [104.85359768500004, 21.079026076000048],
              [104.85075191100003, 21.077386691000029],
              [104.85156144600003, 21.07553320600006],
              [104.85210972700003, 21.071191158000069],
              [104.85306469400007, 21.068126670000083],
              [104.85619997900005, 21.060338356000052],
              [104.85715431400006, 21.057784721000061],
              [104.85758892500004, 21.054565787000087],
              [104.858119856, 21.052254982000115],
              [104.85935518000008, 21.04911955900004],
              [104.86333910900002, 21.040829951000092],
              [104.86386545800005, 21.038797226000035],
              [104.86456418400005, 21.033701104],
              [104.86546643700009, 21.030806062000096],
              [104.86599256700001, 21.029117831000029],
              [104.87455635900008, 21.025984756000057],
              [104.8755377050001, 21.025403781000037],
              [104.87920908300002, 21.022288156000087],
              [104.88094303400005, 21.019282005000051],
              [104.8829552400001, 21.015790929000033],
              [104.88477280300003, 21.014056818000078],
              [104.88694998300005, 21.011956248],
              [104.88851779300009, 21.010218579000117],
              [104.88894578100006, 21.009481592000064],
              [104.88921854800007, 21.008038529000125],
              [104.88976283100007, 21.005230581000056],
              [104.89022146000011, 21.001267780000084],
              [104.89071599000007, 20.998967480000097],
              [104.89112540400002, 20.996783286000031],
              [104.8928428840001, 20.992173313000073],
              [104.89506264000009, 20.988684905000099],
              [104.89890017099999, 20.981601742000052],
              [104.90227972200003, 20.978442326000035],
              [104.90583801700009, 20.97581330400002],
              [104.91475821000009, 20.969641646000078],
              [104.91915506300008, 20.966593886000062],
              [104.92161381100004, 20.963734135000053],
              [104.92326659200009, 20.961840840000079],
              [104.92465478500003, 20.959572311000052],
              [104.92625449600004, 20.955761914000078],
              [104.92758852800003, 20.952964616000088],
              [104.93094687500007, 20.947125345000117],
              [104.93236151300006, 20.94448554800001],
              [104.93405727100003, 20.941165207],
              [104.93549701700006, 20.93692219900003],
              [104.93709287700003, 20.93465638400005],
              [104.93929155900005, 20.932418460000065],
              [104.94256985600005, 20.930312773000104],
              [104.94847356200002, 20.927930431000071],
              [104.94933271400005, 20.927531634000026],
              [104.95097941800006, 20.9259899400001],
              [104.95543916800003, 20.920145695000066],
              [104.95901946000005, 20.915990554000082],
              [104.96120427700008, 20.914612610000027],
              [104.964461348, 20.913836046000078],
              [104.96820596400003, 20.913697286000051],
              [104.96908186200004, 20.913664819000033],
              [104.97108153700002, 20.912147337000064],
              [104.97378474100005, 20.909524800000057],
              [104.9754052800001, 20.906965618000051],
              [104.97556885600005, 20.904464691000065],
              [104.97668716900003, 20.895386392000056],
              [104.97857595800008, 20.890268908000031],
              [104.98204307400006, 20.886639690000038],
              [104.98760151800008, 20.880907251000025],
              [104.98940547900003, 20.877216056000059],
              [104.99007151400008, 20.873137745000037],
              [104.99057824100008, 20.871030502000032],
              [104.99127149600005, 20.868147486000055],
              [104.99257265100007, 20.866033478000112],
              [104.99558769500004, 20.86200660600008],
              [104.99855862400001, 20.858135529000066],
              [104.99893041100005, 20.856947582000096],
              [104.99854167300003, 20.855182118000037],
              [104.99674461000002, 20.853045474000083],
              [104.99563996600003, 20.849158030000048],
              [104.99573273900006, 20.847183961000049],
              [104.99640643800002, 20.846645509000084],
              [105.000000083, 20.847027742000087],
              [105.00248175200008, 20.846745201000068],
              [105.00339892100003, 20.84632931600003],
              [105.00408844700006, 20.84591033],
              [105.00443978200008, 20.845272591000082],
              [105.00445128700005, 20.844523156000072],
              [105.00324197100009, 20.84172253600007],
              [105.00233207100004, 20.839984215000037],
              [105.00191363100005, 20.838214114000039],
              [105.001834667, 20.836991661000056],
              [105.00197480400006, 20.835280185000023],
              [105.00212721500006, 20.834419292000028],
              [105.00199783100001, 20.83378128],
              [105.00106627900001, 20.832725111000094],
              [105.0000000850001, 20.831439829000061],
              [104.99970266200009, 20.830989518000109],
              [104.99930441200009, 20.829923677000025],
              [104.99976465400006, 20.827035295000051],
              [105.00012009900007, 20.82555368200012],
              [104.99964405300003, 20.824060768000052],
              [104.99655782900003, 20.819246515000067],
              [104.99584562300006, 20.816889790000026],
              [104.99594321800006, 20.815952294000084],
              [104.99678550200012, 20.815259639000061],
              [105.00000008600009, 20.815261988000074],
              [105.00132295400003, 20.814697104000054],
              [105.00361978700009, 20.813076644000091],
              [105.00699069700002, 20.810114755000072],
              [105.008775665, 20.80819582500002],
              [105.01003544400007, 20.806852698000085],
              [105.01087181400007, 20.806183926000088],
              [105.01149425600006, 20.80599803800007],
              [105.01335117100004, 20.806120321000066],
              [105.01530687500004, 20.806535400000122],
              [105.01685555200001, 20.806556333000081],
              [105.01902814, 20.806294212000061],
              [105.02058422900006, 20.805829436000067],
              [105.02183353800004, 20.805166195000076],
              [105.02267134200008, 20.804400235000031],
              [105.02269801100007, 20.802651772000047],
              [105.02210078400003, 20.801186351000034],
              [105.01996977200011, 20.798728648000072],
              [105.01619738300009, 20.795568635000095],
              [105.01366693700007, 20.79223101700007],
              [105.01256700500009, 20.789884317000059],
              [105.01176342500004, 20.788416043000076],
              [105.01136386600011, 20.787536196000111],
              [105.01085365000009, 20.787140655000066],
              [105.01033747600009, 20.787133664000109],
              [105.00950713800005, 20.787413893000029],
              [105.00606761500003, 20.789504775000097],
              [105.00481984300009, 20.790070808000038],
              [105.00388923300009, 20.790155334000104],
              [105.00358101100008, 20.790053995000065],
              [105.00317701100005, 20.789465550000052],
              [105.00332051700011, 20.78684419300005],
              [105.00335779600009, 20.784415695000021],
              [105.00317816600005, 20.782664367000066],
              [105.00197518400009, 20.78031617700011],
              [105.00000008800001, 20.776381769000096],
              [104.99942673099999, 20.77328192100012],
              [104.99877426000009, 20.769752225000012],
              [104.99773092000001, 20.767312570000037],
              [104.9949829550001, 20.763539156000022],
              [104.99172011600005, 20.759465252],
              [104.99019326400003, 20.756040897000091],
              [104.98973555400008, 20.753374411],
              [104.98999757000009, 20.752556433000088],
              [104.99032198500001, 20.752136482000068],
              [104.99154946000006, 20.752318634000041],
              [104.99344161200003, 20.75213765400008],
              [104.99630466800004, 20.751680406000084],
              [105.00000007600009, 20.750294054000037],
              [105.00632510900002, 20.745592203000029],
              [105.00935484200005, 20.743179950000098],
              [105.01046354100004, 20.742334308000032],
              [105.01082521300003, 20.742133487000025],
              [105.01140277600004, 20.74195356300006],
              [105.01174994300007, 20.741898288000101],
              [105.01239473800004, 20.741823330000031],
              [105.01208103800012, 20.741597475000084],
              [105.01159734000007, 20.741122108000098],
              [105.0112220660001, 20.74066840600004],
              [105.01008574500005, 20.739425922000088],
              [105.00883599100003, 20.73821406200009],
              [105.00710888300003, 20.736631130000127],
              [105.005489772, 20.734920618000082],
              [105.00398657100007, 20.733353794000031],
              [105.00241469800004, 20.731531915000097],
              [104.99995064299999, 20.730116796000075],
              [104.99588906900003, 20.723958348000068],
              [104.993487429, 20.723377913],
              [104.98101915400008, 20.723364158000024],
              [104.97688059300005, 20.724911540000065],
              [104.97308867000001, 20.728224130000058],
              [104.96812094300002, 20.732302945000036],
              [104.96140280500006, 20.7366705190001],
              [104.95512799200003, 20.73924434200007],
              [104.947110077, 20.741637389000097],
              [104.941177178, 20.743589582000062],
              [104.93607856400004, 20.746668164000056],
              [104.92885810700008, 20.749198744000097],
              [104.92513187800006, 20.749577190000053],
              [104.92075555200007, 20.749662878000038],
              [104.91666082900012, 20.749683944000097],
              [104.91285858400002, 20.750266515000071],
              [104.91081665300001, 20.750590036000105],
              [104.90532878500007, 20.752615938000041],
              [104.89906821600005, 20.75552085700005],
              [104.89798547200007, 20.756297851000035],
              [104.89394659600003, 20.759331631000052],
              [104.89204621900009, 20.760546976000072],
              [104.88590482899998, 20.764421412000011],
              [104.87953961200002, 20.767979534000069],
              [104.87558214300005, 20.768490798000094],
              [104.872911926, 20.768443204000064],
              [104.86840071700004, 20.76589506500008],
              [104.86605034800009, 20.764052354000029],
              [104.86155503800002, 20.760526256000041],
              [104.86062730400009, 20.759407881000058],
              [104.85694559700002, 20.75506189],
              [104.85364384400003, 20.754877918000055],
              [104.84860369000009, 20.754874463000036],
              [104.84332504000005, 20.754838140000054],
              [104.84124549400003, 20.752979446],
              [104.83584730100006, 20.748139007000077],
              [104.83521630000007, 20.746036919000055],
              [104.83522458600004, 20.743601677000086],
              [104.83512102400009, 20.739091314000021],
              [104.83511320100007, 20.735726648000075],
              [104.83614651000002, 20.73480251200008],
              [104.84087490200001, 20.733491177000118],
              [104.84303583100011, 20.732231050000038],
              [104.84237979700001, 20.729747120000042],
              [104.84206634, 20.728568935000077],
              [104.84021858100007, 20.725246338000055],
              [104.83788461200004, 20.721153827000101],
              [104.83778193000008, 20.718511494000055],
              [104.83801858900003, 20.717370509000062],
              [104.83860434100009, 20.714561964000033],
              [104.83959523200009, 20.713040537],
              [104.84247649900001, 20.708983511000056],
              [104.84412757500012, 20.707109600000081],
              [104.84580666500004, 20.704786130000038],
              [104.84625176600009, 20.7042447480001],
              [104.84669465600007, 20.703840268000114],
              [104.84489128100006, 20.702924393000011],
              [104.839592012, 20.701039010000066],
              [104.83419715500003, 20.700570409000107],
              [104.83338260800005, 20.69890568300012],
              [104.83387545700006, 20.69670218600006],
              [104.83617577000005, 20.693869257000053],
              [104.83862788000008, 20.689356064000016],
              [104.83886505700008, 20.685613162000038],
              [104.83864777200003, 20.681707221000067],
              [104.83865359200007, 20.680064008000087],
              [104.837628651, 20.677281126000047],
              [104.83765726500006, 20.674229665000077],
              [104.83717798300002, 20.672393624000037],
              [104.83606889100008, 20.668396560000076],
              [104.83571941400004, 20.666885158000078],
              [104.83560782800004, 20.664154435000093],
              [104.83541489200007, 20.660033515000094],
              [104.83786391600002, 20.658894815000025],
              [104.84106439100006, 20.655605047000059],
              [104.84277607000011, 20.653810117000134],
              [104.84521873600006, 20.651771296000021],
              [104.84100860400007, 20.650703479000043],
              [104.83918761800003, 20.650266541000036],
              [104.83335698000006, 20.646651575000071],
              [104.83074545500007, 20.645009791000035],
              [104.82608152100009, 20.64375907900002],
              [104.81845800200001, 20.640567843000035],
              [104.81629144800007, 20.63967574200003],
              [104.81128853300002, 20.637840023000109],
              [104.80813917800003, 20.637718842000119],
              [104.80762008100007, 20.633920307000061],
              [104.80669959300012, 20.633775045000036],
              [104.80547434800008, 20.632762761000031],
              [104.80486236600007, 20.631751157000089],
              [104.80471144300002, 20.62987358200008],
              [104.80471329100008, 20.628429439000051],
              [104.80379582300003, 20.626550994000056],
              [104.79981580000005, 20.62192513400003],
              [104.79668114100004, 20.619580826000039],
              [104.79617713699999, 20.615388231000061],
              [104.79699119800003, 20.613873975],
              [104.79930039700004, 20.609837947000109],
              [104.79990357100003, 20.608516259000083],
              [104.79992278700011, 20.607342616000054],
              [104.79977717900005, 20.606088320000104],
              [104.79881768200006, 20.603804800000077],
              [104.79778798000004, 20.600737622000054],
              [104.797487819, 20.598776706000073],
              [104.79755434500008, 20.597251550000095],
              [104.79891519400005, 20.595314739000067],
              [104.79960265900009, 20.593915988000063],
              [104.80013041500005, 20.5921236],
              [104.80077194900011, 20.588454521000088],
              [104.80087420700005, 20.587282056000063],
              [104.80039257900007, 20.586257634000034],
              [104.79804032100006, 20.585287794000081],
              [104.79779164800009, 20.585185266000039],
              [104.79453477800008, 20.583824603000075],
              [104.79204470700002, 20.583710119000017],
              [104.78922117100008, 20.583669010000079],
              [104.78413997300004, 20.584534107000074],
              [104.78198079400008, 20.584502597000039],
              [104.78049241700003, 20.584089545000062],
              [104.77901564900006, 20.582972263000073],
              [104.77800145200008, 20.581509550000106],
              [104.77738998300002, 20.578291758000056],
              [104.77633365500002, 20.576867540000073],
              [104.77493355600008, 20.576142664000052],
              [104.77368922500007, 20.57604619300006],
              [104.77153013200004, 20.57601458000002],
              [104.76877423700009, 20.576913377000047],
              [104.76759482600004, 20.577913533000114],
              [104.7660392170001, 20.581569174000066],
              [104.76541388600006, 20.584220991],
              [104.76498053600008, 20.58531032900008],
              [104.76281487300004, 20.585669864000074],
              [104.75990567200003, 20.585783652000075],
              [104.75849778500007, 20.585528155000077],
              [104.757442854, 20.584025621000102],
              [104.75483866500009, 20.58077844200001],
              [104.75317071000002, 20.578679857000012],
              [104.74604856600004, 20.577400853000057],
              [104.7386178460001, 20.574708270000116],
              [104.73683479900009, 20.577029842000094],
              [104.72989446500006, 20.584753496000104],
              [104.72610000900008, 20.590606121000029],
              [104.72353644400006, 20.594872535000071],
              [104.72233438799999, 20.597202558000035],
              [104.72134733200004, 20.601570607000049],
              [104.72038255100006, 20.60460848500005],
              [104.71225653400006, 20.606170092000028],
              [104.70514948500008, 20.60395090300009],
              [104.70265921000001, 20.603835413000034],
              [104.69941881100003, 20.603865182000014],
              [104.69517384000008, 20.604349424000112],
              [104.69318320700003, 20.604163058000054],
              [104.69058910200008, 20.60036752000007],
              [104.68904425100007, 20.598387741000046],
              [104.68730947600005, 20.597813839000075],
              [104.68198759600008, 20.598125127000074],
              [104.68131124000007, 20.598819314000046],
              [104.68175508200002, 20.602034773000085],
              [104.68169530400006, 20.605555695000085],
              [104.68135081000005, 20.608720125000076],
              [104.68179734100006, 20.611779050000045],
              [104.68217409800006, 20.614054302000092],
              [104.68289107700005, 20.615865104000086],
              [104.68279340700005, 20.61672450700005],
              [104.68194555400007, 20.617729143000041],
              [104.68076811, 20.618572290000081],
              [104.67650414500005, 20.620151529000097],
              [104.67139618000002, 20.622500551000122],
              [104.66886701200011, 20.624653646000098],
              [104.65967999100006, 20.625023351000046],
              [104.65861903000004, 20.62876375600009],
              [104.656229, 20.63248397600006],
              [104.65494284100006, 20.634812248000053],
              [104.65307385100006, 20.637209913000021],
              [104.64960505400011, 20.640835386000056],
              [104.64368677400007, 20.646771256000115],
              [104.63895001600011, 20.651629334000084],
              [104.6357488350001, 20.65422583700007],
              [104.63645117800003, 20.656080557000053],
              [104.63710379100003, 20.65798061400006],
              [104.63756252600007, 20.66119155100008],
              [104.63783769300009, 20.665114212000013],
              [104.63776337000007, 20.66658826700003],
              [104.63668720500002, 20.669383917000062],
              [104.63577450100009, 20.671213961000134],
              [104.63492264100003, 20.672353440000087],
              [104.63350990200003, 20.673369112000017],
              [104.63213076700005, 20.673855149000083],
              [104.629918784, 20.67437454700007],
              [104.62707610300006, 20.674561588000095],
              [104.6236532620001, 20.674324827000042],
              [104.6185383100001, 20.674269515000084],
              [104.61353412800004, 20.674884232000053],
              [104.61049965100005, 20.674837621000016],
              [104.60785991700004, 20.674612638000056],
              [104.60449805900006, 20.673685033000041],
              [104.60300730700008, 20.673546826000063],
              [104.60104798300006, 20.673608837000046],
              [104.59937751300005, 20.673951882],
              [104.59794612500009, 20.674621292000062],
              [104.59591135200004, 20.676203368000046],
              [104.59517318500008, 20.676422468000055],
              [104.59350990400003, 20.676350684000063],
              [104.59131307899999, 20.675994054000043],
              [104.59018415900002, 20.676160997000089],
              [104.58929111300003, 20.676838671000027],
              [104.58839080700005, 20.677931123000093],
              [104.588074605, 20.679216997000083],
              [104.58727539400005, 20.680126605000027],
              [104.58593697900005, 20.681073966000071],
              [104.58423187200002, 20.681992584000049],
              [104.58342940900006, 20.683086523000057],
              [104.58306663600007, 20.684233362],
              [104.58299995300005, 20.685246498000076],
              [104.58301826700006, 20.686998513000084],
              [104.58329087500006, 20.68960727400011],
              [104.58368787900004, 20.692102701000081],
              [104.58358389900005, 20.698047685000098],
              [104.58264487800008, 20.699946177000058],
              [104.58183910500001, 20.701224414000038],
              [104.58043462400008, 20.703138721000066],
              [104.57863690000001, 20.705139116000026],
              [104.57629986700009, 20.707177222000084],
              [104.57310329800001, 20.709363276000126],
              [104.56730548200009, 20.713283535000038],
              [104.56625803100012, 20.714373541000072],
              [104.56550266700006, 20.715560287000031],
              [104.56508997300001, 20.716752364000079],
              [104.5645393230001, 20.717435230000092],
              [104.56296388300005, 20.717917752000048],
              [104.5597464460001, 20.718489900000044],
              [104.55687994500008, 20.719517719000088],
              [104.55352927200005, 20.72080659800006],
              [104.55155848300005, 20.721553944000078],
              [104.55016317800006, 20.721736908000118],
              [104.54864608700004, 20.721426499000103],
              [104.5450936620001, 20.720592759000041],
              [104.54205866400007, 20.71990561300003],
              [104.53995569900006, 20.719631707000104],
              [104.53735212800002, 20.71926322000008],
              [104.53357202000008, 20.718564215000065],
              [104.52761757500008, 20.71333084000004],
              [104.526797673, 20.712626488000026],
              [104.52641917200003, 20.711882981000059],
              [104.52638497200005, 20.711052703000107],
              [104.52637208100005, 20.709024241000051],
              [104.52615027500008, 20.707730037000083],
              [104.52559565100003, 20.705877428000093],
              [104.52362886800009, 20.703587683000087],
              [104.52198836900007, 20.702225011000053],
              [104.52106324400009, 20.701933836000116],
              [104.51930352200006, 20.701767785000058],
              [104.51832453200007, 20.701752336000112],
              [104.51787740600011, 20.702114059000017],
              [104.51757385600004, 20.702662432000096],
              [104.51690463100009, 20.704495762000086],
              [104.51620453500007, 20.705314456000053],
              [104.51533515400007, 20.706015226000126],
              [104.51389830700003, 20.706960565000095],
              [104.51137136000003, 20.70862621200007],
              [104.50959181000005, 20.709566091000028],
              [104.50653538800002, 20.71071623100007],
              [104.50338762100007, 20.711496106000112],
              [104.49965488900006, 20.712128363000112],
              [104.4974828110001, 20.713108022000078],
              [104.49447024300005, 20.714535260000041],
              [104.49367458900004, 20.715214063000076],
              [104.49286734400005, 20.716538032000109],
              [104.49229492300002, 20.71841888200003],
              [104.4916378250001, 20.719560837000103],
              [104.49064135800006, 20.720513011000016],
              [104.48949138600004, 20.721831496000043],
              [104.48875462000009, 20.7233178930001],
              [104.48740139400005, 20.727767643000035],
              [104.48597672300012, 20.730741160000044],
              [104.48553530000001, 20.732140041000058],
              [104.48554113300005, 20.73453708800006],
              [104.48549717700006, 20.736979423000079],
              [104.48563828500009, 20.741383722000073],
              [104.48657850200009, 20.743565141000083],
              [104.48699426700003, 20.744954591000031],
              [104.48697105300005, 20.746244862000118],
              [104.48650802300003, 20.747482030000072],
              [104.48545413100004, 20.748894168000028],
              [104.48382347100004, 20.751057657000068],
              [104.48201020200003, 20.753840481000019],
              [104.48123756500004, 20.755948472000085],
              [104.47999596200007, 20.760975903000123],
              [104.4802581820001, 20.762731625000086],
              [104.48071211200008, 20.764720863],
              [104.48160674000003, 20.766717119000063],
              [104.48222005100007, 20.768017493000102],
              [104.48206069300002, 20.768706342000073],
              [104.4815635570001, 20.769113256000082],
              [104.48013440000007, 20.769597480000087],
              [104.47665689900002, 20.770947819000085],
              [104.46993993200007, 20.774020866000065],
              [104.46909581500005, 20.774652660000115],
              [104.46858613400006, 20.775750714000104],
              [104.46791786600002, 20.777491529000081],
              [104.46773678200005, 20.779378395000059],
              [104.46780636700002, 20.780946636000053],
              [104.46599700700007, 20.780779396000071],
              [104.46347968700006, 20.780485574000096],
              [104.45960527900003, 20.780746102000066],
              [104.45725057700007, 20.780938778000021],
              [104.45425341700006, 20.781443750000058],
              [104.45105365500008, 20.782314169000045],
              [104.44986413600007, 20.78307859600006],
              [104.44916431500008, 20.7838508950001],
              [104.44850507700006, 20.785084768000047],
              [104.44753245900006, 20.788733385000043],
              [104.44699997600006, 20.79107546900012],
              [104.44692584100005, 20.792457016000029],
              [104.44726981100008, 20.795089731000083],
              [104.44721362100006, 20.798176910000073],
              [104.44684816600002, 20.799415480000057],
              [104.44639733900001, 20.7999613060001],
              [104.44556058400006, 20.800178287000023],
              [104.44330777400009, 20.80014200100004],
              [104.43890264000004, 20.799932708000043],
              [104.43792652800009, 20.799732601000095],
              [104.43739706600007, 20.799217057000071],
              [104.43623936800003, 20.798230464000063],
              [104.43516699900003, 20.797936602000085],
              [104.43332839200005, 20.79802213300006],
              [104.43112037200005, 20.798216899000096],
              [104.42964695800001, 20.798423535000055],
              [104.42901705200011, 20.798044619000052],
              [104.42674313000008, 20.796486827000123],
              [104.42499698300001, 20.795536729000027],
              [104.42348389500006, 20.795235678000076],
              [104.42245547000005, 20.795219013000029],
              [104.41984301700008, 20.796098475000058],
              [104.41806815900004, 20.796714949000084],
              [104.41749862500008, 20.797051392000029],
              [104.41694892400008, 20.797641652000017],
              [104.41619148600002, 20.798873803000092],
              [104.41520021000007, 20.800123070000076],
              [104.41438611900003, 20.801149022000054],
              [104.41368777600007, 20.801829035000097],
              [104.41299705900003, 20.802094350000019],
              [104.41177185700009, 20.802120532000053],
              [104.41078728800008, 20.802381072000031],
              [104.40973932300005, 20.803424111000091],
              [104.40800179300008, 20.805999945000053],
              [104.40669874700009, 20.807591892000055],
              [104.406187781, 20.808735824000024],
              [104.40545798500001, 20.811120623000079],
              [104.40475279600003, 20.812169206000064],
              [104.40375882800002, 20.812936540000045],
              [104.40024916400007, 20.814630747000074],
              [104.39770514300008, 20.815764540000089],
              [104.39650926500008, 20.816851175000025],
              [104.39595519100006, 20.817671742000066],
              [104.39583510900007, 20.818868098000017],
              [104.39576145200002, 20.820203488000018],
              [104.39510686000003, 20.821160676000083],
              [104.39430872400007, 20.821931154000062],
              [104.39192875800006, 20.823459304000025],
              [104.38678114200012, 20.826278720000069],
              [104.38454714800011, 20.827855262000035],
              [104.382226266, 20.828831192000052],
              [104.37793243700001, 20.830512159000044],
              [104.37724669200003, 20.830500909000094],
              [104.37661849500009, 20.830029716000098],
              [104.37595157600006, 20.829004823000027],
              [104.37576849000001, 20.828310489000046],
              [104.37612504700003, 20.827578919000089],
              [104.37766311400001, 20.826544116],
              [104.37801880700005, 20.825858620000034],
              [104.37802906800002, 20.825305724000117],
              [104.37691256400009, 20.822107260000067],
              [104.37623541400011, 20.821635247000088],
              [104.37567001400004, 20.821741193000065],
              [104.37368945000004, 20.822860898000073],
              [104.37062188500008, 20.824469700000044],
              [104.36884574600002, 20.825131829000028],
              [104.36780945000007, 20.825529580000079],
              [104.36705845500011, 20.826392917000099],
              [104.36634614000005, 20.827809950000109],
              [104.36587613600007, 20.829369225000072],
              [104.36518872300003, 20.830763617000066],
              [104.36081909300006, 20.836498778000042],
              [104.35971568600007, 20.83786323100005],
              [104.35681447800002, 20.839728031000071],
              [104.35502955600003, 20.840850779000064],
              [104.35189878700007, 20.8431956350001],
              [104.35021176900003, 20.844319947000045],
              [104.34720042200009, 20.845514522000116],
              [104.34526843100011, 20.846634740000077],
              [104.344764771, 20.847363806000097],
              [104.34454984300007, 20.848374167000117],
              [104.34470445900007, 20.849275431000081],
              [104.34560498700006, 20.850903386000041],
              [104.34573814500007, 20.851642986000037],
              [104.34548113200003, 20.85228394800005],
              [104.34483912300001, 20.852549847000112],
              [104.34380953900009, 20.852578884000117],
              [104.34303006900009, 20.852335542000056],
              [104.34034963500008, 20.851553749000026],
              [104.33951686300001, 20.851539946000038],
              [104.33872616400005, 20.85189552700006],
              [104.33802824100003, 20.85252917800009],
              [104.33515206200009, 20.856951895000094],
              [104.3345425680001, 20.858093945000086],
              [104.33437916200009, 20.858966883000015],
              [104.33455087800009, 20.860260157000084],
              [104.33453529800005, 20.861089456000045],
              [104.334183727, 20.861544484000127],
              [104.33364311600012, 20.861627680000083],
              [104.33276390900002, 20.861474821000044],
              [104.33218037000006, 20.861234698000075],
              [104.33155478200008, 20.860625182],
              [104.33055981200009, 20.858811272000111],
              [104.32956399500003, 20.857043429000029],
              [104.32903466400002, 20.856527682000078],
              [104.3282508610001, 20.856514651000069],
              [104.32751171200005, 20.856732794],
              [104.326418367, 20.857544171000072],
              [104.32466733500007, 20.859450671000076],
              [104.3243114040001, 20.860136045000033],
              [104.32425199700008, 20.860688101000058],
              [104.32463175200004, 20.861339628000053],
              [104.32745532000008, 20.864935252000102],
              [104.33058400200005, 20.869250214000076],
              [104.33301672100006, 20.872793143000123],
              [104.33368616200003, 20.87367987800004],
              [104.33362418100006, 20.874370131000127],
              [104.33336796400009, 20.874964989000055],
              [104.33276966200006, 20.875508078000045],
              [104.33078656400002, 20.876719450000088],
              [104.330187392, 20.877308605000074],
              [104.33007726900004, 20.877951964000104],
              [104.33007793300001, 20.879219320000075],
              [104.33002073100008, 20.882259980000036],
              [104.32990108000003, 20.883410112000078],
              [104.32958977600003, 20.884326633000057],
              [104.32584032400004, 20.889540972000106],
              [104.3251378950001, 20.890404893],
              [104.31968574200009, 20.893632183000044],
              [104.31850372499999, 20.893935071000058],
              [104.31732432200002, 20.894099743000091],
              [104.31673113600004, 20.894366360000035],
              [104.31662617800006, 20.894733284000019],
              [104.316565, 20.895377435000029],
              [104.31664386000003, 20.896392603],
              [104.3166479170001, 20.897475639000085],
              [104.31648961800005, 20.898072080000048],
              [104.31599354800004, 20.898386401000032],
              [104.31098636500008, 20.901390434000078],
              [104.31063465600002, 20.901845404000063],
              [104.31057694100005, 20.902305274000099],
              [104.31060851400008, 20.903227470000019],
              [104.31059282900003, 20.904056709000059],
              [104.31043799200009, 20.904468869000056],
              [104.30989028000008, 20.904920553000039],
              [104.30870033200003, 20.905638007000071],
              [104.30775800100004, 20.90622134100002],
              [104.3056654550001, 20.906716313000025],
              [104.30434071400001, 20.906786327000049],
              [104.30305535900006, 20.907363893000053],
              [104.30191087900003, 20.908266407000028],
              [104.30070340700004, 20.909905180000088],
              [104.29995965500007, 20.910353552000061],
              [104.29917476400004, 20.910386482000028],
              [104.29858937200001, 20.910238424000035],
              [104.29742647000008, 20.909527693000115],
              [104.2965496990001, 20.909236503],
              [104.29537106100001, 20.909354982000039],
              [104.29423703800006, 20.909704627000011],
              [104.29343989200001, 20.910382497000057],
              [104.29207570100002, 20.912525503],
              [104.29161366700006, 20.913623738000098],
              [104.29162459300008, 20.914338199000056],
              [104.29214347200012, 20.915406809000011],
              [104.29251185100004, 20.916657217000086],
              [104.29259146100006, 20.917626286000065],
              [104.29217316900009, 20.919001735],
              [104.28982699800012, 20.923800973000084],
              [104.28917592100008, 20.924527352000055],
              [104.28873312000003, 20.924612076000045],
              [104.28790794100006, 20.924183471000021],
              [104.28705956900004, 20.92368535700011],
              [104.28593247600003, 20.923666412000053],
              [104.284997, 20.923881094000052],
              [104.28290808600008, 20.925458823000028],
              [104.28200316200008, 20.926641726000028],
              [104.28178694400005, 20.927697967000114],
              [104.28148412700003, 20.928153687000069],
              [104.28020647100001, 20.928316511000041],
              [104.278786197, 20.928246509000111],
              [104.27761445700004, 20.927996362000023],
              [104.27659765800011, 20.927334089000027],
              [104.27568327800005, 20.926443119000027],
              [104.27406991100004, 20.923651004000106],
              [104.27320720700007, 20.92262264100011],
              [104.27259773500001, 20.922451073000033],
              [104.27151700400003, 20.922571085000108],
              [104.27028838300006, 20.92273467100005],
              [104.26906239400006, 20.922760056000108],
              [104.26779622400001, 20.922323926000082],
              [104.26439859700008, 20.920561463000098],
              [104.26099750800006, 20.918983210000071],
              [104.25959408500002, 20.9180377960001],
              [104.25789097500005, 20.914829250000096],
              [104.25752282200006, 20.913578773000019],
              [104.25773473700009, 20.91275287000007],
              [104.25819078200009, 20.911977188000016],
              [104.25898894000004, 20.911253375000079],
              [104.26017556199999, 20.91072047300004],
              [104.26156701800001, 20.909730200000084],
              [104.26182350000001, 20.90913545799999],
              [104.26181003500005, 20.908559187],
              [104.26133151200007, 20.907952006000038],
              [104.2601232910001, 20.907055967000062],
              [104.25756429700003, 20.905031043000029],
              [104.25699132900004, 20.904237918000042],
              [104.25705535900002, 20.90345558500006],
              [104.25703995200007, 20.901704136000099],
              [104.2566656490001, 20.900776119000049],
              [104.25551179100002, 20.899604467000096],
              [104.25487924200009, 20.899363324000127],
              [104.25426543600001, 20.899422043000101],
              [104.25327042300002, 20.90018860100011],
              [104.25203130500009, 20.900904915000048],
              [104.25030662000002, 20.901382570000045],
              [104.24779793900011, 20.901846895000098],
              [104.24612932900007, 20.90195679900004],
              [104.24568212700011, 20.902271783000081],
              [104.24537305800003, 20.903049950000074],
              [104.24530189600009, 20.90420083],
              [104.24484493100002, 20.905022567000039],
              [104.24388999200006, 20.906250592000042],
              [104.24279424700008, 20.907153625000063],
              [104.24098666, 20.908113665000094],
              [104.23969489200006, 20.909013345000105],
              [104.23835055500003, 20.910096459000016],
              [104.23684676100004, 20.911822006000058],
              [104.23562538300006, 20.914151429000057],
              [104.23338923400004, 20.918260756000073],
              [104.23317543000012, 20.919178770000087],
              [104.23336165300006, 20.919688847000103],
              [104.23394079700003, 20.920159544000057],
              [104.23539489500007, 20.921013811000044],
              [104.23761448900005, 20.922848763000076],
              [104.23891005600004, 20.924299372000021],
              [104.23968176600003, 20.926224912000087],
              [104.24024051900008, 20.927755115000046],
              [104.24040739300003, 20.931813151000078],
              [104.24023906400005, 20.93291623800004],
              [104.23943897000002, 20.933732088000042],
              [104.23820135600002, 20.934356164000079],
              [104.234118671, 20.935069995000077],
              [104.23006803100004, 20.935392607000033],
              [104.22884107600004, 20.935463829000064],
              [104.22819418900008, 20.935959684000061],
              [104.22778786900003, 20.936690041000091],
              [104.22686206800009, 20.938932213000072],
              [104.22640761900004, 20.939615667000041],
              [104.22537131600011, 20.939966615000039],
              [104.22350812100004, 20.939980862000063],
              [104.21890225100006, 20.939856006000035],
              [104.21625581100004, 20.939810702000017],
              [104.21479898400003, 20.939094534000091],
              [104.213772502, 20.938938702000073],
              [104.2127860750001, 20.939244362000053],
              [104.21203932500003, 20.939830616000066],
              [104.21163473600005, 20.940468819000024],
              [104.21150807700005, 20.941941234000083],
              [104.21148749300004, 20.943000742],
              [104.2109848740001, 20.943637259000077],
              [104.20999217000012, 20.944265351000091],
              [104.20809322100003, 20.944854860000106],
              [104.20345137000001, 20.94657229100001],
              [104.20201753200004, 20.947192783000013],
              [104.2011852590001, 20.947132392000078],
              [104.19747128700001, 20.946515545000111],
              [104.19521774300009, 20.946430669000016],
              [104.19330457400005, 20.94648987600003],
              [104.19226817500001, 20.946840665000096],
              [104.19152042400005, 20.947472912000094],
              [104.19083764800004, 20.948544033000083],
              [104.19021622200006, 20.950238291000019],
              [104.1896198970001, 20.955665464],
              [104.18935953300004, 20.956444340000054],
              [104.188464713, 20.957074021000018],
              [104.18728030600005, 20.957468303000049],
              [104.18607725300008, 20.957562747000047],
              [104.18470941000005, 20.95730873600008],
              [104.17885140500005, 20.956009436000045],
              [104.17763604100003, 20.955481533000061],
              [104.17661221200004, 20.955187347000056],
              [104.1758271190001, 20.955219836000083],
              [104.173859421, 20.955554416000055],
              [104.17143197400006, 20.955581493000075],
              [104.16672230800003, 20.955730244000037],
              [104.15626009200008, 20.956700507000022],
              [104.15459276900006, 20.956717582000103],
              [104.14679545600009, 20.954323827000017],
              [104.14474972700002, 20.95364302500009],
              [104.14180015100004, 20.951564005000108],
              [104.13654989900004, 20.949306479000107],
              [104.13498705300005, 20.949002670000105],
              [104.13208997700006, 20.949228455000046],
              [104.129679346, 20.949647060000039],
              [104.12853747500003, 20.950364340000057],
              [104.12783578300005, 20.951135412],
              [104.12698547600004, 20.953217176000024],
              [104.1261628610001, 20.95513813500007],
              [104.12518312800005, 20.957563206000081],
              [104.12496040200008, 20.958466580000042],
              [104.12480982800007, 20.959077301000036],
              [104.12470337900005, 20.963199576000058],
              [104.12462323400008, 20.964764871000071],
              [104.12441619300009, 20.965314192000051],
              [104.12336949500008, 20.966171348],
              [104.12212214200008, 20.967255364000081],
              [104.12121697500007, 20.968391461000024],
              [104.11965259300004, 20.970621882],
              [104.11894711500001, 20.971577157000084],
              [104.11815552700007, 20.971931888000128],
              [104.11669993300006, 20.97239014200003],
              [104.11453589800007, 20.972720739000025],
              [104.11261141600009, 20.973331997000066],
              [104.11173677900011, 20.973781476000042],
              [104.11087748000006, 20.974223068000072],
              [104.10982791700009, 20.97521833600009],
              [104.10828725800006, 20.976251024000092],
              [104.10481558700005, 20.97821732000007],
              [104.10298353399999, 20.97910660300002],
              [104.10209662000005, 20.97932135500011],
              [104.10141405300007, 20.979124996000031],
              [104.10078880800003, 20.978514943000093],
              [104.10031430000004, 20.977723237000042],
              [104.09931443300007, 20.976231076000126],
              [104.09820824800003, 20.975151736000086],
              [104.09488424200008, 20.972190042000079],
              [104.09253325600007, 20.969614133000022],
              [104.09120516200005, 20.967378818000078],
              [104.09069001600005, 20.966171635000116],
              [104.09006392200003, 20.965607606000077],
              [104.08870354000001, 20.964984496000035],
              [104.08197574100005, 20.963068203000113],
              [104.07980714700004, 20.962407666000082],
              [104.07874275200007, 20.961697583000038],
              [104.078220286, 20.960858871000099],
              [104.07793919000002, 20.960208766000044],
              [104.07754986500005, 20.960063614000113],
              [104.0772058470001, 20.960103582000045],
              [104.07630972100003, 20.960778872000049],
              [104.07536178400002, 20.961591480000052],
              [104.07378317500006, 20.962070321000056],
              [104.07220826100004, 20.962364892],
              [104.07063984100003, 20.962337009000095],
              [104.06966515200003, 20.962043201000121],
              [104.06854899500006, 20.961470380000065],
              [104.0679538790001, 20.960934126000019],
              [104.06729695900002, 20.960342185000066],
              [104.06502231700003, 20.957652078000066],
              [104.06420863900011, 20.956669906000066],
              [104.06363351600004, 20.956014531000022],
              [104.06295200900006, 20.955771988000023],
              [104.06158061700009, 20.955701475000055],
              [104.05991330400008, 20.955717827000072],
              [104.05840140100003, 20.955322218000028],
              [104.05680265800001, 20.954372084000013],
              [104.05510311800006, 20.953558381000015],
              [104.05266749300007, 20.952777591000071],
              [104.049642836, 20.952032328000044],
              [104.04915926700005, 20.951701119000084],
              [104.04906964300001, 20.951284793000099],
              [104.04937490500005, 20.950737273000094],
              [104.0498790030001, 20.950055072000048],
              [104.05131170500007, 20.94830656300006],
              [104.05498818600006, 20.943487622000042],
              [104.05510203900008, 20.942706272000052],
              [104.05497086200005, 20.941920538000055],
              [104.05464644400003, 20.940993115000062],
              [104.05349097500009, 20.938737520000039],
              [104.05216075000007, 20.936640064000045],
              [104.05106064799999, 20.935284032000112],
              [104.04951204500003, 20.934288621000086],
              [104.047134095, 20.933094036000099],
              [104.04621793500004, 20.932340324000087],
              [104.04589264000005, 20.931458942000077],
              [104.04576269100005, 20.929405947000035],
              [104.04643852700009, 20.925086269000111],
              [104.04683664900008, 20.922374496000096],
              [104.04686023500008, 20.920001638000052],
              [104.04689198200005, 20.918435380000041],
              [104.04682759000005, 20.91677522300008],
              [104.04660219200005, 20.915803433000058],
              [104.04559931800003, 20.914495143000075],
              [104.04083145400006, 20.910308284000045],
              [104.03948003300006, 20.909270213000042],
              [104.03894384300003, 20.909122346000061],
              [104.03825691700003, 20.909156115000101],
              [104.03668144000005, 20.909496519000115],
              [104.03574764100006, 20.909618017000042],
              [104.03496832800006, 20.909373611000071],
              [104.03395151400005, 20.908756263000058],
              [104.03293939100006, 20.907908565000085],
              [104.03082748200002, 20.90570467100008],
              [104.02885699300005, 20.903779792000066],
              [104.02774129900011, 20.903206719000075],
              [104.02656627900005, 20.903139498000108],
              [104.02558444300008, 20.903214002000055],
              [104.02420404300008, 20.903603927000077],
              [104.02219754300008, 20.904650788000097],
              [104.01972223700008, 20.905850486000062],
              [104.01815051700007, 20.906006494000096],
              [104.01138305300003, 20.906160913000079],
              [104.00743063900003, 20.906481227000114],
              [104.004833703, 20.906434275000052],
              [104.00297269300003, 20.906354521000111],
              [104.00175056000009, 20.906194149000079],
              [104.00043420700005, 20.905847730000104],
              [103.99853837900005, 20.905076050000027],
              [103.99599708500001, 20.904707402000078],
              [103.99152404800002, 20.90531754600002],
              [103.9906844440001, 20.905624907000053],
              [103.99013125300006, 20.906306133000015],
              [103.98960906300002, 20.907863519],
              [103.98904259900004, 20.90918968400004],
              [103.98848655000005, 20.910009105000071],
              [103.98744334300007, 20.910681420000095],
              [103.9848782880001, 20.91146432900004],
              [103.98342852500005, 20.911645337],
              [103.98196137600007, 20.911480402000038],
              [103.97949353100007, 20.911112914000093],
              [103.97626619100004, 20.910731556000073],
              [103.97523624700008, 20.910758882000025],
              [103.97447057200006, 20.911044478000029],
              [103.97389280800003, 20.911725211000132],
              [103.97388600300009, 20.9130752330001],
              [103.9738022280001, 20.913806015000077],
              [103.973561714, 20.914288084000084],
              [103.97334432800004, 20.914676540000073],
              [103.97300809600002, 20.9149806],
              [103.97266196700006, 20.915187804000098],
              [103.97231473800011, 20.915319130000043],
              [103.96995298900001, 20.914798657000055],
              [103.9689213230001, 20.912129957000047],
              [103.96828008300007, 20.909952300000079],
              [103.96768499100003, 20.907913722000067],
              [103.96721881500009, 20.906753100000053],
              [103.96591585800012, 20.905761539000075],
              [103.95839429000003, 20.89972517900005],
              [103.9567484560001, 20.89872726800008],
              [103.95558017400008, 20.898337197000046],
              [103.95337438000008, 20.898342887000076],
              [103.95175749300006, 20.898313259000112],
              [103.94973705500004, 20.897654071000105],
              [103.94390156000003, 20.895427061000049],
              [103.94229045800004, 20.895120954000035],
              [103.94165255200002, 20.895155323000033],
              [103.94095511200004, 20.895695532000055],
              [103.94019907400011, 20.896695514000015],
              [103.93762739600004, 20.901325892000067],
              [103.93667342200007, 20.902414382000067],
              [103.93558011700003, 20.903131630000082],
              [103.92833227500003, 20.905164148000033],
              [103.92729945800002, 20.905329444000031],
              [103.92651934500005, 20.905130724000017],
              [103.92589006400003, 20.904750438000086],
              [103.9243452310001, 20.903615898000091],
              [103.92305497700002, 20.903200367000082],
              [103.91881435400006, 20.902062101000048],
              [103.916662339, 20.901837984000068],
              [103.91489457200001, 20.901989645000093],
              [103.90845840300004, 20.903875221000057],
              [103.90545794500011, 20.904372642000091],
              [103.90132470800008, 20.905125520000034],
              [103.89816558600008, 20.906172923],
              [103.89603443000004, 20.907285460000047],
              [103.89509181900004, 20.90782096100012],
              [103.89450384200006, 20.907810035000104],
              [103.89382367500009, 20.907520889000075],
              [103.89315030800007, 20.906909267000096],
              [103.89200151000006, 20.905597528],
              [103.88870681500003, 20.90260982300007],
              [103.8864801380001, 20.901277981000099],
              [103.885072838, 20.900606578000058],
              [103.88380378400007, 20.90035250699999],
              [103.88179977599999, 20.900084727000134],
              [103.88048659600005, 20.899599386000119],
              [103.879570265, 20.898891004000042],
              [103.87865880700008, 20.897952290000084],
              [103.877946249, 20.896879023000011],
              [103.87755266900004, 20.895788665000033],
              [103.87725156600008, 20.893801355],
              [103.87702801300006, 20.892783294000061],
              [103.87669965400008, 20.892085875000085],
              [103.875976424, 20.891519333000105],
              [103.87467013700009, 20.890711477000053],
              [103.87292389100003, 20.889849303000041],
              [103.870328929, 20.887404306000064],
              [103.86905840900003, 20.886067085],
              [103.86807932200003, 20.883698352000032],
              [103.86765887700005, 20.882722662000042],
              [103.86689354700006, 20.881832687000035],
              [103.86414398400002, 20.879753396000034],
              [103.86048124300001, 20.876827369000047],
              [103.85845884500002, 20.875130308000053],
              [103.85637672700004, 20.873939068000077],
              [103.85565656000006, 20.873234243000027],
              [103.85143902200005, 20.867601482000083],
              [103.850862917, 20.86703759600006],
              [103.8503176900001, 20.866174724000075],
              [103.84947118600005, 20.86449964100003],
              [103.84882065300005, 20.862828234000027],
              [103.8480946720001, 20.862399794000019],
              [103.84706790700005, 20.862288313000121],
              [103.84318124300009, 20.862998686000097],
              [103.84036933600004, 20.863867504000041],
              [103.83774548900001, 20.865108538000051],
              [103.83622433000009, 20.86634729000005],
              [103.83476433300008, 20.868163279000029],
              [103.83345521600003, 20.869797752000082],
              [103.83110549100005, 20.871965623000044],
              [103.82896841600005, 20.873353983000072],
              [103.82754378000004, 20.873511428000043],
              [103.82289864100002, 20.873008825000028],
              [103.82133887000001, 20.872610615000028],
              [103.82003194800006, 20.871848484000033],
              [103.81858892500003, 20.870576789000062],
              [103.81587194100003, 20.868151799000053],
              [103.81447896000006, 20.866834934000074],
              [103.81326117500001, 20.866489235000074],
              [103.81198749200006, 20.866465084000119],
              [103.80972515000011, 20.866836959000068],
              [103.80864444500003, 20.866954717000041],
              [103.80820949500001, 20.866669938000101],
              [103.80802245500008, 20.86625158800004],
              [103.80803829300007, 20.865514473000069],
              [103.80835398600007, 20.864506525000067],
              [103.80988990500005, 20.861447739000113],
              [103.80991068300008, 20.860480269000092],
              [103.8098379250001, 20.857022231000052],
              [103.80948859500006, 20.855033775000017],
              [103.80945247100001, 20.854433930000127],
              [103.80901853800007, 20.854103073000104],
              [103.80828376900003, 20.854089126000105],
              [103.80671328700004, 20.854197578000111],
              [103.80470094400006, 20.85434370800003],
              [103.8026435850001, 20.854304611000082],
              [103.80089104300004, 20.853764307000056],
              [103.79944134599999, 20.852814950000017],
              [103.7985344460001, 20.851691557000059],
              [103.79830341600008, 20.851041905000045],
              [103.79840318400008, 20.849822433000043],
              [103.79890652700007, 20.846928364000036],
              [103.79959983000006, 20.844314429000029],
              [103.79978334300006, 20.843739163000038],
              [103.800834696, 20.840443308000097],
              [103.80196242100004, 20.83585571200004],
              [103.80245478300002, 20.83346835600004],
              [103.80232865200006, 20.832498050000048],
              [103.80135778600003, 20.830935548000085],
              [103.79878533400007, 20.827521969000081],
              [103.796977752, 20.824998634000025],
              [103.79563417700003, 20.823682492000017],
              [103.79141774800004, 20.820398789000102],
              [103.78863380200008, 20.817718461000112],
              [103.78584595300008, 20.815222385000084],
              [103.77949394300005, 20.811021865000079],
              [103.77409368000006, 20.807000675000076],
              [103.77342402900008, 20.806250367000075],
              [103.77339002100011, 20.805558323000106],
              [103.7734059850001, 20.804821140000037],
              [103.77372077000004, 20.803859221000032],
              [103.77532671400004, 20.800963044000028],
              [103.77680171500005, 20.798456140000084],
              [103.77711149000001, 20.797724569000039],
              [103.77698152000009, 20.796938496000017],
              [103.77631986800006, 20.795819587000075],
              [103.77566420800008, 20.794424233000086],
              [103.77564315700002, 20.793133205000025],
              [103.77576302600008, 20.792121429000062],
              [103.77640355600005, 20.790820012000033],
              [103.77790650800006, 20.789281567000018],
              [103.77986308500003, 20.787152565000078],
              [103.78197748800001, 20.784519514000088],
              [103.78451350800006, 20.781640960000033],
              [103.78601533100006, 20.780148517],
              [103.78632504200002, 20.779416911000048],
              [103.78643689100004, 20.778773715000028],
              [103.78640880900011, 20.777805195],
              [103.78592291400005, 20.77535288600005],
              [103.78509316500005, 20.771810755000075],
              [103.78479220000003, 20.769868997000081],
              [103.78392338700007, 20.764735788000088],
              [103.78347854800003, 20.762652976000062],
              [103.78342241900002, 20.760715865000101],
              [103.7836960810001, 20.759384310000058],
              [103.78426251000008, 20.758104437000036],
              [103.78568313800011, 20.755826746000047],
              [103.7868370620001, 20.754558078000059],
              [103.78982928300006, 20.752079876],
              [103.79033378500006, 20.751398051000081],
              [103.79064143700002, 20.750758560000101],
              [103.79050847300003, 20.750110673000052],
              [103.78993984600005, 20.74922397900005],
              [103.78777350800004, 20.747477043000103],
              [103.78597996700006, 20.746613059000119],
              [103.78471502800002, 20.74622012000011],
              [103.78198335500005, 20.745706948000105],
              [103.78037771700008, 20.745215278000018],
              [103.77897589100007, 20.744358714000022],
              [103.77758105400005, 20.743179585000071],
              [103.77488634300002, 20.740961425000123],
              [103.76982979300006, 20.736992330000078],
              [103.76871481500008, 20.736463871000055],
              [103.76778466400003, 20.736446032000089],
              [103.76704434700007, 20.736708421000039],
              [103.7656985780001, 20.737788944000087],
              [103.76414697800006, 20.739326491000028],
              [103.76355250900008, 20.739637760000029],
              [103.76296504700005, 20.739626480000069],
              [103.761559328, 20.738954109000041],
              [103.757595021, 20.736573031000113],
              [103.75347794100006, 20.734465514000085],
              [103.75167965900005, 20.733831619000043],
              [103.75036090100008, 20.733667926000088],
              [103.74834575500006, 20.73399789100003],
              [103.74278076600004, 20.735411826000046],
              [103.73826689100004, 20.735785667000073],
              [103.732737023, 20.735586593000029],
              [103.73117853400001, 20.735187659000061],
              [103.73045630800003, 20.734620509000123],
              [103.72895528000001, 20.731595059000085],
              [103.72839405200001, 20.730385631000026],
              [103.72842126700004, 20.729141499000022],
              [103.72868518200004, 20.728270738],
              [103.72967070300004, 20.725754376000083],
              [103.72968481000005, 20.725109268000082],
              [103.72926036900002, 20.724363467000018],
              [103.72754448200007, 20.722209687000067],
              [103.72756464400004, 20.721288095000112],
              [103.72792241600007, 20.720603535000045],
              [103.7299838160001, 20.718154076000062],
              [103.73220106300006, 20.715292702000099],
              [103.73321731200004, 20.713606675000037],
              [103.73511597900001, 20.709632697000032],
              [103.73513208000001, 20.70889540500005],
              [103.73499730600005, 20.70833960100007],
              [103.73419236700006, 20.707079336000064],
              [103.73329257000005, 20.705678920000103],
              [103.73237001700001, 20.70307945100005],
              [103.73230132100009, 20.701741211000076],
              [103.7328777590001, 20.695528751000047],
              [103.73289487300009, 20.694745358000063],
              [103.73319961400009, 20.69424414700007],
              [103.73360324300009, 20.693698734000066],
              [103.73474405600007, 20.693029279000093],
              [103.73647821500002, 20.692094677000036],
              [103.73821437800005, 20.691067898000078],
              [103.73964881100008, 20.690404088000044],
              [103.74020427300012, 20.689631095000038],
              [103.74431752000001, 20.680628448000071],
              [103.74458029400007, 20.679803680000042],
              [103.74454240400004, 20.679295822000121],
              [103.74411799600001, 20.678550005000048],
              [103.74286764200004, 20.677511619000107],
              [103.74094420300005, 20.675907011000035],
              [103.73452415, 20.669466877000083],
              [103.73302417400004, 20.668654115000074],
              [103.7284401430001, 20.667827727000088],
              [103.72346363700009, 20.667039738000078],
              [103.72155306600003, 20.667094886000115],
              [103.718944236, 20.667735806000032],
              [103.71553621300011, 20.669098821000098],
              [103.71337687700006, 20.669333483000059],
              [103.7123501830001, 20.669267419000015],
              [103.71147436400003, 20.669019875000046],
              [103.710075402, 20.668070607000018],
              [103.70852762100002, 20.6672106380001],
              [103.70633962500008, 20.666522610000101],
              [103.70127449100002, 20.665317457000043],
              [103.697525641, 20.664368384000028],
              [103.69366981000009, 20.663832092000064],
              [103.68995978700008, 20.663344689000063],
              [103.68600917400005, 20.662668097000036],
              [103.68302814900005, 20.662425353000025],
              [103.68184756700006, 20.662678856000035],
              [103.68085356400006, 20.663350944000044],
              [103.67885942200009, 20.664971608000116],
              [103.67378215800002, 20.668744750000108],
              [103.67287882100005, 20.669741296000055],
              [103.67256478500011, 20.670657195000075],
              [103.67259432000006, 20.671533737000033],
              [103.67286243100003, 20.672691571000087],
              [103.67288483100002, 20.673890688000093],
              [103.67266662, 20.674900666000084],
              [103.67154094400003, 20.677091506000075],
              [103.67084651100004, 20.679705726000108],
              [103.67087707000007, 20.680536170000074],
              [103.67139601400002, 20.681422304000115],
              [103.67215864600007, 20.682359314000017],
              [103.67213414200009, 20.683465282000043],
              [103.67177215900006, 20.684334124000081],
              [103.67106041900006, 20.685518812000048],
              [103.66990920600003, 20.686648773000101],
              [103.668753903, 20.687963040000042],
              [103.66765967700006, 20.690938171000091],
              [103.66637373500006, 20.693725106000024],
              [103.66619520200004, 20.69515074500012],
              [103.66625935300002, 20.696673348000068],
              [103.66644988600005, 20.699120453000127],
              [103.66628056100008, 20.700131346000106],
              [103.66587263100007, 20.700860950000077],
              [103.6650313490001, 20.701259324000084],
              [103.66375773900006, 20.701280396000051],
              [103.66220068800001, 20.700834878000052],
              [103.65934977600003, 20.699119158000109],
              [103.65511166700003, 20.697030322000032],
              [103.65403283400011, 20.697101279000037],
              [103.65214216400008, 20.698447057000102],
              [103.65004747800005, 20.700157603000058],
              [103.64816496600008, 20.701134693000078],
              [103.64649465900007, 20.701378344000076],
              [103.645309684, 20.701815964000048],
              [103.64409799300002, 20.703451676000029],
              [103.64255353300004, 20.706832631000083],
              [103.64129858500003, 20.710403677000052],
              [103.63771578400006, 20.717340082000121],
              [103.63598766100007, 20.720164097000101],
              [103.63467375300007, 20.721982087000065],
              [103.62741857100009, 20.725293116000017],
              [103.62613739100004, 20.72593897400008],
              [103.62504393500008, 20.72665488600007],
              [103.62391287600011, 20.726862950000054],
              [103.62229747100012, 20.726830903000071],
              [103.62033835700004, 20.726838116000046],
              [103.619199032, 20.727414788000061],
              [103.6163642810001, 20.72934074900008],
              [103.61262763000011, 20.732170715000017],
              [103.61144653600009, 20.732423822000086],
              [103.61007481600009, 20.732442637000048],
              [103.60758234200001, 20.732208629000048],
              [103.60640643400006, 20.732231319000057],
              [103.60615544700008, 20.732502909000061],
              [103.60614404200003, 20.733009773000077],
              [103.60649730400003, 20.734722471000033],
              [103.60641206100009, 20.736334228000075],
              [103.6060924220001, 20.737480328000046],
              [103.60461672800004, 20.739940259],
              [103.60135263300006, 20.743516981000099],
              [103.59800704, 20.745726562000044],
              [103.59692229600005, 20.746164516000043],
              [103.595019624, 20.746301440000089],
              [103.58480142800001, 20.746248842000092],
              [103.57716976500005, 20.746780580000035],
              [103.57189061300006, 20.747281543],
              [103.5650209180001, 20.748191260000048],
              [103.56228400600004, 20.748228158000082],
              [103.56024124200005, 20.7478193910001],
              [103.55839757200008, 20.746558320000119],
              [103.55695421000003, 20.74524291200008],
              [103.55371252400005, 20.742724324000058],
              [103.54983012600006, 20.745863215000071],
              [103.54825778400009, 20.746854540000115],
              [103.5450719990001, 20.749095149000048],
              [103.53850859199999, 20.753387942000053],
              [103.53534155600005, 20.75479901100001],
              [103.53387702100011, 20.754584988000069],
              [103.53095639000004, 20.753788291000049],
              [103.52512788100006, 20.751641870000093],
              [103.51985526900009, 20.7508895060001],
              [103.51750108200008, 20.751026093000036],
              [103.51433604900004, 20.752344715000071],
              [103.50791854500008, 20.754519060000028],
              [103.50533877500007, 20.755941656000054],
              [103.50372116500007, 20.758121399000103],
              [103.50136287800008, 20.762683075000062],
              [103.49997733500001, 20.767541118000032],
              [103.4986644200001, 20.773744125000078],
              [103.49284216400011, 20.782054210000034],
              [103.48953911700002, 20.785452779000124],
              [103.48767735000007, 20.786281229000011],
              [103.48498395100006, 20.785884222000028],
              [103.48347120700004, 20.78553541200003],
              [103.48245045800005, 20.785832381000091],
              [103.48209427600007, 20.78661972200004],
              [103.4802912600001, 20.791509548000064],
              [103.47958253300008, 20.792925371000059],
              [103.47716632400009, 20.795100829000113],
              [103.47662279800007, 20.796678948000121],
              [103.47656757700003, 20.799061685000098],
              [103.47826734300006, 20.805930230000058],
              [103.47971401500004, 20.809138303000019],
              [103.47969194000007, 20.810091386000096],
              [103.47915942200007, 20.811192929000107],
              [103.47744076000006, 20.81242909500007],
              [103.47521899900008, 20.813496019000063],
              [103.47383445800001, 20.81489791500006],
              [103.47139580600003, 20.818026314000093],
              [103.46616932200004, 20.824752551000088],
              [103.46183888700003, 20.829272199000037],
              [103.46030737100006, 20.829717462000104],
              [103.45879434000001, 20.829368516],
              [103.45413812900006, 20.82609434000009],
              [103.45288043500008, 20.825299530000052],
              [103.45027659700003, 20.823961172000068],
              [103.44833635300007, 20.823462353000096],
              [103.44599738500008, 20.823322357000059],
              [103.44502620000002, 20.823118793],
              [103.44416113300011, 20.822550349000046],
              [103.44231089800006, 20.818199182000093],
              [103.43973184800009, 20.811630508000079],
              [103.43893972900001, 20.807943460000011],
              [103.43890436000001, 20.805281474000111],
              [103.43900102500002, 20.800430307000092],
              [103.43873863000007, 20.799038109],
              [103.43774577800008, 20.797353460000068],
              [103.43587187200011, 20.795511909000105],
              [103.43456159100005, 20.794791423000092],
              [103.43352989200004, 20.794770081000102],
              [103.43233133200008, 20.795577346000101],
              [103.42992445500006, 20.79760769800005],
              [103.42888948600006, 20.79772494300007],
              [103.42480484300003, 20.795837503000044],
              [103.42304595000007, 20.795385001000085],
              [103.42113642300009, 20.795068045000065],
              [103.41936128000003, 20.79530856500007],
              [103.41606668800002, 20.797459021000094],
              [103.41398044900006, 20.798386434000093],
              [103.41265395000009, 20.79835887000003],
              [103.41047571200008, 20.796926814000116],
              [103.40731472000002, 20.793394092000113],
              [103.40690844400005, 20.791860174000121],
              [103.40638414800001, 20.789075669000034],
              [103.40509360599999, 20.787523322000112],
              [103.40204432900003, 20.785518287000038],
              [103.3986905510001, 20.783922900000015],
              [103.39765890400008, 20.783901390000011],
              [103.39646354000004, 20.784569873000081],
              [103.394953785, 20.786063862000056],
              [103.39301167900007, 20.78713278700004],
              [103.39109246400008, 20.787231397000113],
              [103.38846254700007, 20.786205696000067],
              [103.38670382400005, 20.785752897000087],
              [103.38463723700004, 20.785848370000082],
              [103.38152260200008, 20.786615308000123],
              [103.38033371900012, 20.787006472000066],
              [103.37957713700004, 20.787822715000104],
              [103.37910218100009, 20.789199581000069],
              [103.37757911700001, 20.79124789399999],
              [103.37516187100003, 20.793693488000109],
              [103.36985543900009, 20.799822793000097],
              [103.36333989000012, 20.807174613000079],
              [103.36199357500001, 20.807978384000059],
              [103.36036233600004, 20.808360136000111],
              [103.35888837700003, 20.808329152000034],
              [103.35653334500005, 20.808140951],
              [103.35493179400005, 20.807275191000052],
              [103.35349414400011, 20.805719494000115],
              [103.35190252000005, 20.804437883000034],
              [103.35029771700005, 20.803710699000085],
              [103.34882379600005, 20.803679647000095],
              [103.34703857200003, 20.804335409000082],
              [103.34508279900004, 20.805958303000068],
              [103.34072565900004, 20.809333307000074],
              [103.33518918400007, 20.812683283000055],
              [103.3335611680001, 20.81292623000008],
              [103.33252937500001, 20.812904417000055],
              [103.33031508100002, 20.812996261000102],
              [103.32927997100009, 20.813113036000082],
              [103.32748795800005, 20.814045845000081],
              [103.3250797630001, 20.816074994000111],
              [103.32058462500005, 20.819030610000056],
              [103.31819957400006, 20.820089438000089],
              [103.31419636900004, 20.820975227000105],
              [103.30930537700002, 20.82198075400008],
              [103.30620986300005, 20.821914960000051],
              [103.30357325400011, 20.821165507000039],
              [103.30108073400002, 20.820557769000025],
              [103.29930186400001, 20.82093592500005],
              [103.29498705100005, 20.822508101000011],
              [103.29289662700002, 20.823572933],
              [103.29168727600006, 20.824795217000066],
              [103.29091345300006, 20.826304116000038],
              [103.29088335100006, 20.827551541000041],
              [103.29256532500006, 20.831192900000062],
              [103.29310817000002, 20.833145889],
              [103.29322215400003, 20.834535042000098],
              [103.29304464700002, 20.835779299000109],
              [103.29243492000009, 20.836598323000111],
              [103.29032426900008, 20.838494709000074],
              [103.2873424460001, 20.839817795000037],
              [103.28556673100007, 20.84005722300013],
              [103.28322478800001, 20.839313852000082],
              [103.28250444800001, 20.83860511000006],
              [103.28253795700003, 20.837219111000046],
              [103.28226658500004, 20.836242611000074],
              [103.28095995900003, 20.835382662000065],
              [103.27731149000005, 20.833779256000092],
              [103.26857757900011, 20.829016050000071],
              [103.2640818830001, 20.82586883099999],
              [103.26233991700003, 20.824722062000056],
              [103.26043709700006, 20.824126521000075],
              [103.25630973200008, 20.82403787400002],
              [103.25467141200005, 20.824696034000034],
              [103.25360922500001, 20.825921276000066],
              [103.25178638000008, 20.828100862000021],
              [103.24894849300003, 20.829565220000035],
              [103.24596315800002, 20.831026356000045],
              [103.24340649100004, 20.833051384],
              [103.241459709, 20.834257494000049],
              [103.23952642799999, 20.834909177000057],
              [103.23644089300005, 20.8344266060001],
              [103.23511079000004, 20.834536574000047],
              [103.23391116600008, 20.83534272000005],
              [103.232238818, 20.837386697000056],
              [103.23175931600011, 20.838901734],
              [103.23155092900001, 20.841393323000098],
              [103.23123238400004, 20.842357133000085],
              [103.23003269500008, 20.843163248000081],
              [103.22854493799998, 20.843685785000062],
              [103.22662847400005, 20.843644361000102],
              [103.22529830200003, 20.843754268000048],
              [103.22484247400007, 20.844299093000096],
              [103.224805163, 20.845823662000029],
              [103.2248203, 20.851232107000058],
              [103.22573697500009, 20.85596666100011],
              [103.22599791700002, 20.85735898600003],
              [103.22634864800008, 20.86111058500002],
              [103.22660960200008, 20.862502888000023],
              [103.23073951700012, 20.868554752000087],
              [103.23215632700001, 20.870942654000046],
              [103.23270545200006, 20.87261848100006],
              [103.23267835700004, 20.873727208000048],
              [103.23205809600006, 20.874961789000054],
              [103.23025149600005, 20.876448078000109],
              [103.22575691400006, 20.879262881000038],
              [103.22439596300006, 20.880620087000068],
              [103.22392986700005, 20.88158064700005],
              [103.22359764400001, 20.883098747000069],
              [103.22266203300005, 20.885158430000047],
              [103.21435664300003, 20.889896260000015],
              [103.2123081820001, 20.893180939000011],
              [103.21422058200005, 20.895441761000107],
              [103.2248234680001, 20.896411028000017],
              [103.23312945800006, 20.894740974000037],
              [103.23942336900008, 20.894876720000077],
              [103.24688838300001, 20.895407388000109],
              [103.24963297400008, 20.895836329000069],
              [103.25896607100003, 20.900475553000049],
              [103.26757563200007, 20.902509629000043],
              [103.27547021700011, 20.901568968000014],
              [103.28091499600004, 20.904274579000059],
              [103.28593970900003, 20.908080718000029],
              [103.28464332600007, 20.912861534000093],
              [103.27570179700008, 20.924506593000025],
              [103.27281335400002, 20.929992906000017],
              [103.27269685100006, 20.9347987250001],
              [103.27567429400003, 20.941889879000094],
              [103.28182663600006, 20.947939147000035],
              [103.287728816, 20.948065103000069],
              [103.29324644300002, 20.947812848000069],
              [103.29593836200007, 20.950459195000093],
              [103.29533971900001, 20.958953134000069],
              [103.29508101900009, 20.969673280000031],
              [103.29358811800003, 20.982585990000047],
              [103.287974553, 20.996131231000078],
              [103.28726658200003, 20.996422294000091],
              [103.28585822300003, 20.99700129700004],
              [103.28475046800004, 20.997995845000084],
              [103.2837516960001, 21.000883613],
              [103.28338593700002, 21.003203062000075],
              [103.28436007200007, 21.007732867000058],
              [103.28538349600011, 21.010227376000046],
              [103.28896032300003, 21.015976206000083],
              [103.28977455300007, 21.017520774000076],
              [103.29036545300002, 21.01869696400005],
              [103.29045345600011, 21.021462357000061],
              [103.29041832400004, 21.022916097000085],
              [103.29008772800005, 21.023781739000036],
              [103.28898335600006, 21.024630891000037],
              [103.28709815800005, 21.025754295000084],
              [103.28583899600005, 21.02660012900008],
              [103.28504410300009, 21.02745585800001],
              [103.28342615800007, 21.030330280000072],
              [103.28144230400005, 21.035523999000105],
              [103.28019725600006, 21.038369716000105],
              [103.27980310100006, 21.039270570000035],
              [103.27883575300004, 21.040849796000046],
              [103.27692567400001, 21.042990658000079],
              [103.27502965400006, 21.044550019000049],
              [103.27423111500008, 21.045551059000012],
              [103.27287302300007, 21.050467060000067],
              [103.27264065700003, 21.053661802000079],
              [103.272869095, 21.057011818000056],
              [103.27381505500003, 21.062704178000047],
              [103.27465063900007, 21.064803363000095],
              [103.28093997500007, 21.065894301000029],
              [103.28691725500009, 21.06306347000011],
              [103.29481826000003, 21.062122357000042],
              [103.30073282100003, 21.061878340000106],
              [103.30187830000003, 21.063381847000073],
              [103.30178917300003, 21.067077836000081],
              [103.29651912700007, 21.073252151000062],
              [103.28993348299998, 21.084944941000039],
              [103.28615715200007, 21.094478602000066],
              [103.28922690900005, 21.097871931000078],
              [103.29748740900007, 21.098417485000098],
              [103.31210186300002, 21.09687888600007],
              [103.32836212400008, 21.092415940000109],
              [103.34389594300008, 21.085348072000087],
              [103.35317280700008, 21.07629877400008],
              [103.36271936300002, 21.072431583000117],
              [103.37348182800011, 21.067110272],
              [103.37910790900003, 21.062420704000125],
              [103.3881633340001, 21.06260975200005],
              [103.40189973600006, 21.064744741000041],
              [103.41566253800006, 21.065770065000052],
              [103.42072880500002, 21.068093735000026],
              [103.42182701000003, 21.069993925000041],
              [103.42224291800008, 21.070713545000075],
              [103.42009179500008, 21.078434339000026],
              [103.41018905600006, 21.097457067000036],
              [103.4039247310001, 21.112487118000089],
              [103.40365435700002, 21.123943793000066],
              [103.399699512, 21.141239479000085],
              [103.39551609800007, 21.151505004000093],
              [103.401293599, 21.157171093000038],
              [103.40742176400008, 21.164692860000045],
              [103.41371581300011, 21.165193126000048],
              [103.41693679100007, 21.162302077000028],
              [103.42248638400005, 21.160938060000056],
              [103.43061115600004, 21.167391257000112],
              [103.432827359, 21.173722199000089],
              [103.43487905100002, 21.187074135000024],
              [103.43503038800003, 21.197428954000053],
              [103.43164432, 21.207340913000024],
              [103.43028878300001, 21.214706770000099],
              [103.43110654, 21.230250399000013],
              [103.43242261400009, 21.24136783000008],
              [103.42949918200006, 21.248331234000069],
              [103.4293256, 21.255720968000077],
              [103.43499448299998, 21.266188390000096],
              [103.438255758, 21.278454128000043],
              [103.43726815000008, 21.286935643000056],
              [103.43346910700008, 21.297576888000066],
              [103.42841344700001, 21.308468849000036],
              [103.42599140500008, 21.313686374000085],
              [103.41797637600011, 21.319065096000053],
              [103.41584096700008, 21.320086303],
              [103.41279034100009, 21.321545123000085],
              [103.40924218100005, 21.321471619000043],
              [103.39631102000003, 21.317876641000041],
              [103.3847292840001, 21.323919226000051],
              [103.37671270300009, 21.329296359000068],
              [103.37075493200008, 21.331020030000047],
              [103.36790714200001, 21.334656665000026],
              [103.36898435700004, 21.339114544000012],
              [103.37934006700006, 21.351527820000094],
              [103.38539460900009, 21.362372398000048],
              [103.38526309000012, 21.367913578000049],
              [103.38124078700011, 21.37115609900011],
              [103.3749050050001, 21.372132726000089],
              [103.36499367400009, 21.37414316899999],
              [103.36012929700004, 21.37958519000005],
              [103.35552664700006, 21.390576256000045],
              [103.34391279700004, 21.397724109000116],
              [103.33709296300003, 21.402385195000036],
              [103.33454923900007, 21.409723053000015],
              [103.33462355200008, 21.411464522000045],
              [103.33505437300002, 21.421559719000079],
              [103.34049681800012, 21.425000209000089],
              [103.35467042000001, 21.426406343000082],
              [103.36681031100002, 21.430355945000109],
              [103.37737283300001, 21.434271958000025],
              [103.38953997700006, 21.437112162000105],
              [103.39109013100006, 21.437145938000057],
              [103.39558279300009, 21.434201107000028],
              [103.39901116300007, 21.432615093000074],
              [103.40153250900005, 21.431976909000028],
              [103.40553716000002, 21.430955141000041],
              [103.40821884900004, 21.429767796000121],
              [103.40927966500006, 21.428546866000083],
              [103.409636409, 21.427424602000045],
              [103.40981657800006, 21.427660324],
              [103.41069416000002, 21.427954694000029],
              [103.41231175700003, 21.4281262800001],
              [103.41498035000011, 21.42749097800003],
              [103.41674855100004, 21.427527543000032],
              [103.41910290200006, 21.42771431200012],
              [103.42204633300005, 21.428546136000115],
              [103.424375008, 21.429204198000029],
              [103.42861248900005, 21.430810725000079],
              [103.43154980800009, 21.431285577000025],
              [103.43479158300008, 21.431352359000044],
              [103.43839363900004, 21.431771765000107],
              [103.44000481400012, 21.432219212000092],
              [103.44116745400004, 21.43293362000005],
              [103.44421335800008, 21.435067717000052],
              [103.44770151500003, 21.437873917000069],
              [103.44636183800003, 21.441381573000037],
              [103.44629464900007, 21.444248773000041],
              [103.44869542500003, 21.450752324000085],
              [103.45314358500009, 21.457058718000077],
              [103.45403584000012, 21.462574958000054],
              [103.45155435500006, 21.470412329000062],
              [103.45114251100009, 21.477096869000079],
              [103.45022574299999, 21.483531934000055],
              [103.44853757800011, 21.490190115000104],
              [103.44842002400006, 21.495207271000091],
              [103.44977806600008, 21.502644889000067],
              [103.45772864100003, 21.512129650000041],
              [103.46425426100004, 21.517043526000101],
              [103.47081921000002, 21.520284869000058],
              [103.47973760800004, 21.521183831000101],
              [103.48402752600006, 21.523422322000037],
              [103.48898910100007, 21.529737573000048],
              [103.49555489200003, 21.53297810000003],
              [103.49692661900011, 21.533412643000105],
              [103.50127258000006, 21.534743822000024],
              [103.50404077500005, 21.535191252000097],
              [103.50759909400003, 21.534434671000021],
              [103.51085075600005, 21.534178238000031],
              [103.51399559300002, 21.534287893000048],
              [103.51482803700006, 21.534442828000074],
              [103.51520745600001, 21.535048980000049],
              [103.51518195100003, 21.536153352000028],
              [103.51520560400004, 21.537258716000096],
              [103.51557972600008, 21.538094940000093],
              [103.51962313300007, 21.544023261000063],
              [103.51989366000006, 21.545087555000109],
              [103.51882481600009, 21.550912522000054],
              [103.51729086100009, 21.55557715200009],
              [103.51758951900011, 21.562879716000083],
              [103.51784265300009, 21.56506419599999],
              [103.51795922400005, 21.565294942000079],
              [103.52141422100001, 21.567652448],
              [103.52494087100004, 21.566158434000023],
              [103.52883640000005, 21.565730593000062],
              [103.52920692500007, 21.572067736000022],
              [103.52916666200011, 21.573816203],
              [103.53019618800002, 21.580373676000072],
              [103.52944036500001, 21.582222789000035],
              [103.52737575300007, 21.584298697000023],
              [103.52719857200006, 21.585584049000062],
              [103.52862167300005, 21.587868320000055],
              [103.53154999000003, 21.591011463000065],
              [103.53482393700003, 21.593033719000054],
              [103.536052629, 21.595221935],
              [103.53660931900005, 21.598823600000024],
              [103.53700108900004, 21.601040990000087],
              [103.53786502200003, 21.601978983000066],
              [103.54068529600005, 21.603416594000088],
              [103.54157039200001, 21.603434375000049],
              [103.54324542500005, 21.603329930000115],
              [103.54432721800003, 21.603351650000043],
              [103.54471849000008, 21.603451564000054],
              [103.54607948300006, 21.604169343000109],
              [103.546552207, 21.605007385000086],
              [103.546919196, 21.606165517000072],
              [103.54685375100009, 21.609018106000022],
              [103.54718741600007, 21.609485113000027],
              [103.55214902199999, 21.614095562000024],
              [103.55333289000006, 21.617180276000077],
              [103.556258396, 21.623147734],
              [103.55709318800007, 21.624850446000018],
              [103.55746418100003, 21.627987848000082],
              [103.55674812500003, 21.63025194],
              [103.54883260000004, 21.634281971000036],
              [103.54868253800007, 21.636534349000058],
              [103.54883704800005, 21.638378577000061],
              [103.54879821100003, 21.63899917400007],
              [103.54788338800002, 21.64026962100003],
              [103.54643599700007, 21.641161145000076],
              [103.54202710200005, 21.642453489000069],
              [103.54056380900005, 21.644035076000101],
              [103.54039511000012, 21.644952236000044],
              [103.54034647400009, 21.64706852000009],
              [103.54068310800005, 21.648479117000093],
              [103.54562382800002, 21.651892243000056],
              [103.55214008200005, 21.653127525000038],
              [103.5551001540001, 21.656017444000049],
              [103.55852793800003, 21.667500498000081],
              [103.55945078900001, 21.669106832000026],
              [103.56458490600008, 21.67270736000004],
              [103.56640439100008, 21.678151609000018],
              [103.57128241300009, 21.682207044],
              [103.58149448500004, 21.686529607000082],
              [103.58586958800001, 21.68242843000003],
              [103.58907122600006, 21.677981656000078],
              [103.59008538800003, 21.676667094000024],
              [103.59172856000002, 21.675825248000088],
              [103.59338328300007, 21.676640504000133],
              [103.59457546300003, 21.678321048],
              [103.59527677900006, 21.679945808000056],
              [103.59596975200002, 21.68193858900009],
              [103.59613833400002, 21.683184586000067],
              [103.59604417800004, 21.684080186000095],
              [103.59537751700005, 21.685263586000048],
              [103.59390806700006, 21.68712141000011],
              [103.58995070100009, 21.693417106000098],
              [103.58414763700009, 21.702943576000067],
              [103.58305687100008, 21.705453114000051],
              [103.58440613800008, 21.708885575000103],
              [103.58126038500004, 21.71409249200007],
              [103.57617443800004, 21.72342557400011],
              [103.57486361400002, 21.725861566000049],
              [103.57343222700007, 21.730343039000033],
              [103.57216834100004, 21.733953419000059],
              [103.57019039200007, 21.737572547000106],
              [103.5705491950001, 21.741261242000043],
              [103.56620609600002, 21.743889739000011],
              [103.56159985400009, 21.748330609],
              [103.5596667070001, 21.751053090000113],
              [103.55930012300007, 21.752012148000063],
              [103.55850039500007, 21.757909475000048],
              [103.55524823500009, 21.764470954],
              [103.5512989760001, 21.768142309000012],
              [103.54185577200009, 21.774119284000015],
              [103.53912801700008, 21.778182996000069],
              [103.53974457500003, 21.783487195000092],
              [103.54126323600009, 21.787037849000065],
              [103.54426454700008, 21.796784163000012],
              [103.54564335600004, 21.798928456000063],
              [103.54530363, 21.800854243000067],
              [103.53977460500002, 21.806794177000107],
              [103.53807555800005, 21.812143636000059],
              [103.53570582900008, 21.814534739000102],
              [103.5353464130001, 21.815171704000043],
              [103.5353818680001, 21.815770582000127],
              [103.53882373800005, 21.819221612000028],
              [103.54003908300005, 21.822052757000066],
              [103.53997452300004, 21.824858195000019],
              [103.53855853800003, 21.828602755000048],
              [103.53519659700004, 21.83129595200004],
              [103.53120121900005, 21.832619014000088],
              [103.52599631200007, 21.83619522100004],
              [103.52793455800003, 21.841847533000092],
              [103.52735731400006, 21.845516758000102],
              [103.52443945900004, 21.850289087000085],
              [103.52146266500003, 21.855474238000028],
              [103.52136198300005, 21.856095291000074],
              [103.52019871800007, 21.863270305000079],
              [103.51690217700008, 21.865228244000079],
              [103.51044574200003, 21.867490420000109],
              [103.50964299300004, 21.868118331000019],
              [103.50726280900001, 21.873039124000023],
              [103.50535966000011, 21.874380896000019],
              [103.50151672800011, 21.874395213000049],
              [103.49894552100008, 21.874803261000061],
              [103.49549583700012, 21.875128822000086],
              [103.49250302300003, 21.876723913000085],
              [103.48970926800007, 21.882462326000059],
              [103.486863951, 21.890407184000082],
              [103.48465433300007, 21.896433181000049],
              [103.48399910000002, 21.899179332000074],
              [103.48538001700007, 21.903346521000074],
              [103.48532215900008, 21.905828821000028],
              [103.484410005, 21.906914067000024],
              [103.48081277100009, 21.90904852700006],
              [103.47836521300007, 21.912585985000099],
              [103.47772915600004, 21.914504619000041],
              [103.47854480400011, 21.917556488000045],
              [103.47992573400006, 21.921723562000039],
              [103.48043306200002, 21.925320980000059],
              [103.48121018600001, 21.930027565000017],
              [103.48138986600006, 21.934997897000038],
              [103.48219922400006, 21.938325435000117],
              [103.48507733500006, 21.941694931000107],
              [103.49494394100002, 21.949779158000027],
              [103.49822569200003, 21.952765027000076],
              [103.50388311000006, 21.956076890000098],
              [103.50376364500003, 21.958000316000081],
              [103.50283231000007, 21.960478833000074],
              [103.50173260400003, 21.965502199000042],
              [103.50087473800002, 21.967166713],
              [103.50000007600003, 21.968094427000025],
              [103.4975287300001, 21.969399730000113],
              [103.49495658100008, 21.971102827000074],
              [103.49616714300006, 21.972429211000041],
              [103.49827614000003, 21.979422616000122],
              [103.49916994600009, 21.980222645000055],
              [103.50210345000008, 21.982193383000045],
              [103.50626218200009, 21.98432783100008],
              [103.50694767800005, 21.985280005000085],
              [103.50759031800001, 21.986474582000071],
              [103.50885499200004, 21.988950176000088],
              [103.5104906560001, 21.990686057000097],
              [103.51194827000009, 21.992070813000097],
              [103.51200178199998, 21.992975456000075],
              [103.51175748900002, 21.993874092000084],
              [103.51052323900004, 21.996507753000039],
              [103.5103113230001, 21.997615545000123],
              [103.51018272000002, 21.998342740000076],
              [103.51020786600006, 21.998864533000081],
              [103.51038594600004, 21.999215646],
              [103.5113516640001, 22.000000179000075],
              [103.51202484300003, 22.000657014000069],
              [103.51349166900008, 22.003108684000061],
              [103.51389656800009, 22.005117696000106],
              [103.51388832800002, 22.007908178000065],
              [103.51334161699999, 22.009582075000097],
              [103.51123592600004, 22.012427041000038],
              [103.51404785300008, 22.014394546000027],
              [103.51775011100007, 22.017989834000048],
              [103.51975101600007, 22.021376119000102],
              [103.52139211400005, 22.02340411200003],
              [103.52229819700005, 22.024186581000023],
              [103.52353989500004, 22.024662360000043],
              [103.53337502900006, 22.027555698000079],
              [103.53678835900003, 22.028620871000015],
              [103.53944787200008, 22.029450761000113],
              [103.54259160900004, 22.030872513000062],
              [103.54392051100004, 22.030961759000078],
              [103.54530244400007, 22.029928734000045],
              [103.5480796680001, 22.02654642800006],
              [103.55034396700003, 22.024553292000078],
              [103.55201390400001, 22.023793759000029],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 52, code: "AD01", ten_tinh: "Tây Ninh" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.11000472000011, 11.746261658000011],
              [106.11602090100007, 11.745892092000075],
              [106.11731613300003, 11.745991444000124],
              [106.12149197200003, 11.746784978000056],
              [106.12526289900003, 11.747035635000101],
              [106.13378628700005, 11.746116966000029],
              [106.137999076, 11.744684265000059],
              [106.14722463900003, 11.739957051000081],
              [106.15071737700001, 11.738084587000051],
              [106.15188419100009, 11.738079886000017],
              [106.15386099100004, 11.738602893000037],
              [106.15935672700002, 11.74353641200012],
              [106.16611558200006, 11.749969086000077],
              [106.16697445700007, 11.750223994000029],
              [106.16935096600005, 11.750929303000122],
              [106.18803308700004, 11.753772499000091],
              [106.19187708800003, 11.750128179000095],
              [106.19579663700006, 11.743209208000048],
              [106.20518857000009, 11.735736191000077],
              [106.21315945200004, 11.731808716000012],
              [106.22087525200003, 11.731156422000042],
              [106.23379016200005, 11.729065621000066],
              [106.23980056300005, 11.728420178000041],
              [106.25325440900008, 11.726503179000057],
              [106.2563903650001, 11.725339014000012],
              [106.25871615900007, 11.723647423000026],
              [106.26659243800002, 11.718922545000064],
              [106.27051814300003, 11.713861057000051],
              [106.27051571700009, 11.713330115000085],
              [106.27015229500005, 11.712358305000057],
              [106.26772038500009, 11.710422246000094],
              [106.26708454300005, 11.708743710000123],
              [106.26734529100005, 11.706884202000099],
              [106.29834813800008, 11.67736605700007],
              [106.29933145600009, 11.676565193000064],
              [106.3012153710001, 11.676468163000029],
              [106.31450114100006, 11.677646535000095],
              [106.32638832700005, 11.686617952000054],
              [106.32917423400006, 11.687489968000085],
              [106.33055969100003, 11.687227139000099],
              [106.33159191900009, 11.686416877000026],
              [106.33328624000004, 11.684196728000094],
              [106.33552273000008, 11.682770484000036],
              [106.33776684200004, 11.682937017000048],
              [106.34127525500007, 11.684778943000127],
              [106.34658346200007, 11.687674261000057],
              [106.352079836, 11.692338399000056],
              [106.36304928900007, 11.696799442000037],
              [106.37059737300004, 11.698887206000029],
              [106.37906124300011, 11.701663822000084],
              [106.38180801800009, 11.701289196000085],
              [106.3923880680001, 11.695862060000104],
              [106.40161044700004, 11.691835145000072],
              [106.40518583700002, 11.689074516000096],
              [106.40982717400009, 11.684184891000092],
              [106.41742929300008, 11.679280616000119],
              [106.43192899100004, 11.672218159000101],
              [106.43767081500003, 11.672100993000088],
              [106.44702979500008, 11.673093709000069],
              [106.45060612300004, 11.673752966000082],
              [106.45072292900002, 11.670542856000042],
              [106.45088535600006, 11.66607895200009],
              [106.45200389900006, 11.661227974000086],
              [106.45301951900007, 11.657444615000133],
              [106.45317173800005, 11.656877571000022],
              [106.45318975800004, 11.654493682000043],
              [106.456373259, 11.646002732000047],
              [106.45794951200004, 11.643800574000094],
              [106.46196320500005, 11.640083678000044],
              [106.46372541600002, 11.636180020000085],
              [106.46461479600006, 11.633121415000057],
              [106.46481745100004, 11.629206397000045],
              [106.46426181200007, 11.624789366000064],
              [106.46472435900006, 11.620876293000036],
              [106.46655156400007, 11.617469697000081],
              [106.46847528500008, 11.615099794000104],
              [106.47247170300008, 11.61359631700005],
              [106.47733561100009, 11.61192875700001],
              [106.48151525700005, 11.609064228000053],
              [106.48413677500007, 11.606018132000083],
              [106.48467418400004, 11.60363813300007],
              [106.48364730100008, 11.601927974000057],
              [106.48244970300001, 11.5998760460001],
              [106.48125340200006, 11.597653844000087],
              [106.48162642000003, 11.594080768000124],
              [106.48254708700009, 11.591691332000041],
              [106.48339290600008, 11.58949611400004],
              [106.48555969900012, 11.58481764500001],
              [106.48657959800005, 11.583227500000069],
              [106.48780647800004, 11.576302989000096],
              [106.48795517800008, 11.571726833000094],
              [106.48808256500006, 11.570758359000076],
              [106.48670432900006, 11.566357420000093],
              [106.48344701400002, 11.561711858000121],
              [106.47973336700007, 11.555214141000112],
              [106.47741332200003, 11.551037519000127],
              [106.47109167100002, 11.547525176000038],
              [106.46546856900008, 11.544942151000013],
              [106.45936500600001, 11.543742067000016],
              [106.45371771900002, 11.544393967000042],
              [106.446877425, 11.547347926000027],
              [106.44100019300008, 11.547304484000074],
              [106.43702308300008, 11.544732880000106],
              [106.43211989200002, 11.54030551800007],
              [106.430036059, 11.536130205000104],
              [106.42907010200001, 11.531849106],
              [106.42655504000001, 11.522585080000079],
              [106.42506981300005, 11.517027719000071],
              [106.42300060100008, 11.511003839000098],
              [106.42115497200005, 11.506483743000027],
              [106.42142208000004, 11.502326142000106],
              [106.42191887000011, 11.498863551000047],
              [106.42335604400009, 11.495223601000058],
              [106.42027179700008, 11.492017986000034],
              [106.41768134400006, 11.488975010000056],
              [106.41525291200003, 11.484875011000016],
              [106.41191616900008, 11.478954069000034],
              [106.40918753700001, 11.473944755000074],
              [106.40583937800008, 11.469535428000109],
              [106.40155834700008, 11.466479526000038],
              [106.39712370700006, 11.463422378000041],
              [106.39146411600004, 11.459751112000037],
              [106.3864101020001, 11.457293702000102],
              [106.38089024900009, 11.455437276000067],
              [106.37505825000001, 11.454182980000025],
              [106.37121752900002, 11.453850944000086],
              [106.36907140000008, 11.453078479000032],
              [106.36709468500004, 11.450342123000022],
              [106.36574012900005, 11.44670358200006],
              [106.36470272000001, 11.441858177000073],
              [106.364293495, 11.435354857000045],
              [106.36294875500002, 11.430507126000043],
              [106.35985619200002, 11.415695135000091],
              [106.35687960400003, 11.401436816000031],
              [106.355525562, 11.397798442000083],
              [106.34433020500006, 11.375793061000115],
              [106.32824513800003, 11.350725936000037],
              [106.32612124600006, 11.347383785000085],
              [106.32491803700007, 11.344200103000011],
              [106.32478170400007, 11.342082960000075],
              [106.32617928800006, 11.34028032600005],
              [106.3297339420001, 11.337739095000108],
              [106.33699417000005, 11.332959961000098],
              [106.34024742300002, 11.329660473000034],
              [106.34195693800007, 11.327255646000099],
              [106.342716083, 11.324647829000066],
              [106.34276407900006, 11.324317520000092],
              [106.3429190630001, 11.322275504000066],
              [106.341113487, 11.31757585500006],
              [106.33946160600001, 11.312877442000067],
              [106.33728320100008, 11.306697545000064],
              [106.33469503100007, 11.301261465000055],
              [106.33390586900006, 11.300195637000064],
              [106.33347985300007, 11.298453430000064],
              [106.333773068, 11.296227893000042],
              [106.33902149800011, 11.286814654000031],
              [106.34069135300007, 11.284436996000034],
              [106.3421301220001, 11.283415940000017],
              [106.34306407000001, 11.283586313000081],
              [106.34366440700003, 11.2841887500001],
              [106.343880853, 11.285662157000063],
              [106.34409188600006, 11.288042758000037],
              [106.34488475000002, 11.290335576000119],
              [106.34579327300006, 11.291228083000069],
              [106.34712493400011, 11.291714530000084],
              [106.35015486000006, 11.291249282000047],
              [106.35253154000007, 11.289909470000035],
              [106.35325418700009, 11.288991396000101],
              [106.3539326480001, 11.287515909000055],
              [106.35441083400005, 11.286311216000032],
              [106.35439547200011, 11.284693997000071],
              [106.35262023100003, 11.278879736000123],
              [106.34974834800001, 11.266577222000061],
              [106.34923004700002, 11.264823983000037],
              [106.3492760140001, 11.263748061000099],
              [106.34999146300005, 11.263219428000058],
              [106.35552160200002, 11.263385054000084],
              [106.35964348700001, 11.26407367700004],
              [106.36096547900006, 11.263866648000052],
              [106.36232475900007, 11.262437351000097],
              [106.36238975200004, 11.261188141000032],
              [106.36152263500006, 11.259551330000049],
              [106.35955708800006, 11.257688610000026],
              [106.35750404300009, 11.256450036000041],
              [106.35092751900005, 11.253355812000086],
              [106.35008082800007, 11.252615658000041],
              [106.34948449500011, 11.251524285000068],
              [106.34960676300001, 11.250003870000034],
              [106.35194202800005, 11.246903518000105],
              [106.35461102400009, 11.243359969],
              [106.35785468900006, 11.24015245],
              [106.35950684600007, 11.239434758000042],
              [106.36120447700003, 11.239271371000113],
              [106.3626308170001, 11.24023039800006],
              [106.36446878600002, 11.242527719000071],
              [106.36665511299999, 11.244283400000119],
              [106.36785682300004, 11.244767203],
              [106.369039195, 11.24480354400008],
              [106.37014275700008, 11.244377429000084],
              [106.37325927800002, 11.23983751300006],
              [106.37551222000009, 11.236092991000122],
              [106.37686498800011, 11.231974104000118],
              [106.3777525, 11.228136226000094],
              [106.37800689700001, 11.223791567000061],
              [106.37803097500003, 11.220749134000039],
              [106.37840117100002, 11.219149181000086],
              [106.38251487000005, 11.198753439000074],
              [106.38254465700003, 11.198210765000026],
              [106.38266563600008, 11.195016436000047],
              [106.38287036800006, 11.192968784000088],
              [106.38376736200011, 11.190802478000082],
              [106.38509572100007, 11.188653369000077],
              [106.38663255100003, 11.186613913000086],
              [106.40853151400009, 11.174475239000079],
              [106.41273707600007, 11.173825696000071],
              [106.41579487400003, 11.173060999000127],
              [106.42240986900006, 11.171754747000049],
              [106.42644217, 11.170630205000085],
              [106.42778414300001, 11.170083650000072],
              [106.429224581, 11.168681885000085],
              [106.43102924600002, 11.166386402000049],
              [106.43280702100003, 11.164009227000092],
              [106.43433465100004, 11.161983318000066],
              [106.43534010300002, 11.159926345000047],
              [106.43539504100002, 11.157716055000042],
              [106.43454689400002, 11.155791550000059],
              [106.43252919300005, 11.153603428000023],
              [106.43082345000005, 11.151558432000018],
              [106.43009830000008, 11.149147884000053],
              [106.43021733700007, 11.14747564800007],
              [106.43118890100008, 11.145525882000038],
              [106.43284930600007, 11.143359404000106],
              [106.4377221670001, 11.138832265000074],
              [106.44136777600002, 11.137344008000037],
              [106.44441011600004, 11.136581465000093],
              [106.44572060100003, 11.136677531000048],
              [106.43670099700007, 11.117512704000097],
              [106.43487166800006, 11.113625330000064],
              [106.42764201700007, 11.093814189000016],
              [106.42396401800004, 11.092197004000058],
              [106.42389357600007, 11.087301954000049],
              [106.42366336000003, 11.071302735000097],
              [106.42255920200006, 11.064936130000101],
              [106.420986923, 11.059474378000099],
              [106.42011001400002, 11.053563739000081],
              [106.42015199800002, 11.048114198000023],
              [106.42044729600012, 11.03971464800013],
              [106.41656077900006, 11.035371012],
              [106.41197393500008, 11.032157398000029],
              [106.40877415300008, 11.028500057000111],
              [106.40742073600011, 11.024629632000059],
              [106.40675727100009, 11.020991505000042],
              [106.40701429000005, 11.01758744600003],
              [106.40866163400001, 11.013285678000083],
              [106.41053769100007, 11.00921272200006],
              [106.40870215300008, 11.008063507000097],
              [106.40502583100002, 11.00644619700004],
              [106.39882251300003, 11.003674269000017],
              [106.39353260100003, 11.00204445500008],
              [106.38869492800006, 11.001553290000031],
              [106.38132140000006, 11.001042492000044],
              [106.37532391600006, 11.001450326000038],
              [106.36864924500006, 11.000036242000098],
              [106.35645284000007, 10.997216457000112],
              [106.35460087900005, 10.992752926000014],
              [106.34951886100002, 10.999571600000111],
              [106.343323658, 10.995750987000095],
              [106.34167692100009, 10.999825083000035],
              [106.33980877200005, 11.002762135000042],
              [106.33609256600006, 11.006138683000087],
              [106.32868570000005, 11.009712919000057],
              [106.32196650800002, 11.013746547000023],
              [106.31501462600009, 11.018005183000044],
              [106.307837519, 11.021580594000023],
              [106.30090577100006, 11.023341208000124],
              [106.29489614900011, 11.025109084000027],
              [106.29166641300004, 11.025309926000125],
              [106.28752390300004, 11.024367928000077],
              [106.28430367000004, 11.023433382000023],
              [106.28040511500006, 11.020903752000065],
              [106.27765751400001, 11.01861056700004],
              [106.27168559200007, 11.024440648000054],
              [106.26942438200004, 11.017822359000105],
              [106.26912429300008, 11.015829375000051],
              [106.26954685700004, 11.014417394000107],
              [106.270806656, 11.01217186800003],
              [106.27591606500008, 11.008877414000027],
              [106.28035469400007, 11.005981776000072],
              [106.28254337000006, 11.002056653000112],
              [106.28475141700005, 10.995806341000057],
              [106.28487942000008, 10.992774330000097],
              [106.28387243400007, 10.990440777000019],
              [106.28199555800003, 10.987796816000104],
              [106.28006062300007, 10.985961172000101],
              [106.27821733400006, 10.985440565000102],
              [106.27483614900007, 10.984705129000092],
              [106.27320071800003, 10.983882895000095],
              [106.27155704800001, 10.982076940000061],
              [106.2614826560001, 10.984918517000073],
              [106.25042105700004, 10.987622054000033],
              [106.24342536300003, 10.987919275000111],
              [106.23732562300009, 10.987008839000099],
              [106.23327396700006, 10.984683206000055],
              [106.22748002500001, 10.982056361000046],
              [106.22110462600008, 10.979424452000062],
              [106.21646422400001, 10.977952869000072],
              [106.20659702500009, 10.975577307000071],
              [106.20370158500006, 10.974120337000047],
              [106.20332815600008, 10.973651084000034],
              [106.19948937900007, 10.969810864000083],
              [106.186024241, 10.965887679000017],
              [106.18287609900003, 10.964862950000052],
              [106.17694173400008, 10.963829881000018],
              [106.1660635480001, 10.964150123000048],
              [106.16276561100008, 10.969884019000096],
              [106.16182820900005, 10.971546568000077],
              [106.16151966100001, 10.973103036000074],
              [106.16137226200007, 10.975643918000076],
              [106.16159457700009, 10.978857328000034],
              [106.16146945900003, 10.980387175000049],
              [106.16100094799999, 10.981270291000021],
              [106.16006047300007, 10.982155213000054],
              [106.15896165900001, 10.982781515000093],
              [106.16103514300008, 10.98220574000006],
              [106.18494811100004, 10.972811733000041],
              [106.2014992200001, 10.976411237000059],
              [106.20150720000004, 10.978384308000065],
              [106.19386465000008, 10.992226159000039],
              [106.19108014400008, 11.008867577000013],
              [106.18724875200006, 11.048908507000109],
              [106.18213218500003, 11.057938971000068],
              [106.18023811700007, 11.061281814000077],
              [106.17252231300009, 11.074898301000056],
              [106.15351821600011, 11.101749622000055],
              [106.14610861500006, 11.103469167000044],
              [106.13552532600005, 11.095898594000085],
              [106.1266679550001, 11.091985544000059],
              [106.12206705700001, 11.087584537000071],
              [106.111110271, 11.076826612000088],
              [106.10515733900004, 11.070519386000031],
              [106.10262657900009, 11.070496553000096],
              [106.09912071200009, 11.073279001000101],
              [106.09807596700006, 11.083588137000076],
              [106.08511738200008, 11.101232549000082],
              [106.08461635900009, 11.101914674000033],
              [106.0776938380001, 11.097786446000056],
              [106.07452998300008, 11.097757485000058],
              [106.06393868400002, 11.113607744000118],
              [106.05053649400001, 11.12536659300005],
              [106.02509277800004, 11.138575553000061],
              [106.01871244100006, 11.143831637000099],
              [106.01672900300008, 11.152568989000054],
              [106.013915502, 11.181625810000105],
              [106.0130353640001, 11.186551679000088],
              [106.01291139300005, 11.18724550600003],
              [106.010041437, 11.189407496000035],
              [105.97926752500007, 11.196306904000062],
              [105.964694584, 11.197417484000114],
              [105.95327098400001, 11.200121065000078],
              [105.93353820100006, 11.210561284000063],
              [105.92041020699999, 11.225131004000042],
              [105.90883782800003, 11.242217748000092],
              [105.90492345700008, 11.253438174000062],
              [105.90613882200009, 11.258454509000069],
              [105.90922217100004, 11.266617133000077],
              [105.909155157, 11.273184635000087],
              [105.90718815500006, 11.279733305000137],
              [105.90145617400007, 11.282803942],
              [105.88938088400008, 11.286749203000033],
              [105.87987742400006, 11.286966404000063],
              [105.87072635300007, 11.283746182000074],
              [105.86599585300009, 11.281821580000083],
              [105.86313606900008, 11.282730876000089],
              [105.86086388800001, 11.288024995000077],
              [105.86210122300007, 11.290852583000087],
              [105.86805306900004, 11.297168624000074],
              [105.86857813900005, 11.302003746000098],
              [105.86863114100008, 11.302491802000068],
              [105.86729272400001, 11.30935952900005],
              [105.86435757800007, 11.317462272000077],
              [105.86495190800004, 11.321221805000013],
              [105.870924227, 11.325661444000088],
              [105.87432069500001, 11.334141289000053],
              [105.88073620100006, 11.357040614000089],
              [105.88127594900004, 11.366117515000072],
              [105.88029966400009, 11.36861016900008],
              [105.87137397500001, 11.373837874000079],
              [105.86935162900001, 11.378684577000085],
              [105.86812450000008, 11.381625378000047],
              [105.87123419500007, 11.387287602000116],
              [105.87500046500003, 11.390766703000031],
              [105.87527511800002, 11.394836171000032],
              [105.87235531000002, 11.40137598000006],
              [105.87581796799999, 11.403600748000107],
              [105.88588953900003, 11.410271573000051],
              [105.88503283800003, 11.414273820000085],
              [105.88494067600004, 11.416623414000069],
              [105.88594975400004, 11.419540433000106],
              [105.88730169600008, 11.422802890000037],
              [105.88918558100008, 11.424873720000049],
              [105.89056050300006, 11.425913476000103],
              [105.89297457500005, 11.426963632000041],
              [105.89798474900009, 11.428210703000017],
              [105.89953118500006, 11.429423108000057],
              [105.9008939230001, 11.431659648000013],
              [105.90154288600006, 11.43594102900005],
              [105.90184898900007, 11.439877009000019],
              [105.901824463, 11.442270729000102],
              [105.901280333, 11.444659285000023],
              [105.89900062100003, 11.447372516000074],
              [105.89620122200004, 11.45008053800005],
              [105.89521387800006, 11.450587739000094],
              [105.89358903900009, 11.451422410000054],
              [105.89046774900002, 11.451733146],
              [105.88656512400007, 11.451560165000084],
              [105.88364306900006, 11.45219978400007],
              [105.88022009700008, 11.454366169000084],
              [105.87619899500008, 11.457916658000043],
              [105.87321882900005, 11.461306603000041],
              [105.87274685400008, 11.462698200000075],
              [105.87317858600002, 11.465181098000075],
              [105.87647033300006, 11.469139071000088],
              [105.88088537200005, 11.478519299000121],
              [105.88294324800006, 11.484801678000077],
              [105.88312395600009, 11.490137552000055],
              [105.88189668300006, 11.494763542],
              [105.87832748200006, 11.497742528000019],
              [105.87758141300006, 11.501445705],
              [105.87729264800006, 11.506545044000037],
              [105.87750209300007, 11.510246307000036],
              [105.87835410600005, 11.512453158000111],
              [105.88151819900001, 11.516690425000052],
              [105.88374621600006, 11.521221187000029],
              [105.88438439600002, 11.527953462000083],
              [105.883877521, 11.532962469000017],
              [105.88119526100012, 11.539749780000095],
              [105.87565697000008, 11.550133214000089],
              [105.8730573170001, 11.551704448000065],
              [105.870239305, 11.554216373000068],
              [105.86370158300005, 11.560873693000049],
              [105.86024197100006, 11.566463340000064],
              [105.85919451000009, 11.567004128000045],
              [105.85719866200006, 11.566274828000099],
              [105.85451018900002, 11.564528457000021],
              [105.85076187000003, 11.561938899000078],
              [105.84723250800005, 11.56097509100009],
              [105.84344524400004, 11.562096016000021],
              [105.83422390400008, 11.563856862000053],
              [105.82572501700005, 11.5642331400001],
              [105.82214244900011, 11.565836322000054],
              [105.81795720100004, 11.567526087000067],
              [105.81472337100007, 11.57017098900006],
              [105.81402007000005, 11.575993267000049],
              [105.81556894800005, 11.581051175000121],
              [105.81505786300004, 11.583881915000036],
              [105.81277361400004, 11.587639610000087],
              [105.80779194400006, 11.597214468000059],
              [105.811748994, 11.600663624000072],
              [105.81284080700001, 11.603503979000072],
              [105.81434977500007, 11.610716565000077],
              [105.80918119900008, 11.619424406000077],
              [105.81042986700001, 11.621314882000087],
              [105.82218260200005, 11.619872187000089],
              [105.82567168200009, 11.619908244000053],
              [105.8297520080001, 11.624018231000031],
              [105.83310533800008, 11.636882422000026],
              [105.83107602700005, 11.648752613000022],
              [105.83422849700003, 11.650662612000049],
              [105.84680934300012, 11.661118245000067],
              [105.85625483900004, 11.668099092000082],
              [105.87679264399999, 11.676443419000025],
              [105.88153561100003, 11.678055859000034],
              [105.88664118300005, 11.675290738000042],
              [105.922420829, 11.651551123000024],
              [105.93740682000005, 11.644188260000108],
              [105.94759579700012, 11.640532755000075],
              [105.94826523100008, 11.640539278000082],
              [105.952354153, 11.640579076000089],
              [105.96807241800008, 11.655125786000029],
              [105.97683775700003, 11.667101462000049],
              [105.97863933900005, 11.677445530000073],
              [105.97687552500005, 11.681674232000104],
              [105.97408897200006, 11.688354584000125],
              [105.97713289100002, 11.694820067000043],
              [105.977242195, 11.695052229000067],
              [105.99642716000002, 11.707970682000022],
              [106.0055137630001, 11.719948934000078],
              [106.00921840300003, 11.730624364000073],
              [106.00674073500002, 11.756889518000067],
              [106.011068768, 11.768823152000072],
              [106.02083622000001, 11.776426412000072],
              [106.02160742100006, 11.776616559000084],
              [106.03667193800003, 11.780330235000045],
              [106.04492841300006, 11.780094039000042],
              [106.05953962200002, 11.779290200000057],
              [106.06971543400007, 11.777505834000033],
              [106.07833035700001, 11.772890037000126],
              [106.09431270600008, 11.761142467000061],
              [106.09673220500008, 11.75850796100004],
              [106.10200900500006, 11.752761939000054],
              [106.10617576800011, 11.74841799400008],
              [106.10857173000002, 11.747068670000081],
              [106.11000472000011, 11.746261658000011],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 53, code: "AD01", ten_tinh: "Thái Bình" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.60477447300006, 20.27758392],
              [106.60446510500005, 20.27758195800002],
              [106.60415469400003, 20.277726346000065],
              [106.60389305400005, 20.278261362000102],
              [106.60388818600009, 20.278944363000036],
              [106.60408435700008, 20.280360454000053],
              [106.60463625600008, 20.282510619000057],
              [106.60564737099999, 20.285346714000063],
              [106.60630625100006, 20.286960878000038],
              [106.60752783900006, 20.289212829000022],
              [106.60798845700006, 20.289703619000043],
              [106.60834872700003, 20.289803472000045],
              [106.608710027, 20.289756975000017],
              [106.60917653800004, 20.289418413000071],
              [106.60938695000004, 20.288834294000054],
              [106.60913813700007, 20.287564248000045],
              [106.607318929, 20.28233246900006],
              [106.60589981200009, 20.278859536000127],
              [106.60533991900004, 20.277831447000061],
              [106.60477447300006, 20.27758392],
            ],
          ],
          [
            [
              [106.59743291100004, 20.291279902000078],
              [106.59851004100008, 20.291184925000039],
              [106.59936825400004, 20.291597754000087],
              [106.60065193700002, 20.292726170000016],
              [106.60150870700004, 20.293342662000043],
              [106.60204691800007, 20.293346082000063],
              [106.60269422000007, 20.293146512000071],
              [106.60323896700002, 20.292233405000012],
              [106.6031422130001, 20.290705177000042],
              [106.60252398300003, 20.286831283000062],
              [106.60181559200002, 20.280512578000028],
              [106.6018294030001, 20.278577656000074],
              [106.60205121400007, 20.277662490000111],
              [106.6027020970001, 20.276953724000087],
              [106.60388968700005, 20.27645204800006],
              [106.60421877100001, 20.275970384000082],
              [106.6040180840001, 20.275188500000048],
              [106.60273769200003, 20.271963627000034],
              [106.5987023860001, 20.264299640000068],
              [106.59572391500004, 20.25939207800009],
              [106.59337668100008, 20.256525410000044],
              [106.59145341000001, 20.254578027000036],
              [106.58994892300005, 20.254262849000071],
              [106.58908796000006, 20.254257329000055],
              [106.58865600600011, 20.25445825000007],
              [106.58843783700009, 20.254864235000092],
              [106.58621137500008, 20.258811719000036],
              [106.58385888100003, 20.262982447000063],
              [106.5826617430001, 20.264807945000115],
              [106.57917339700003, 20.270896105000077],
              [106.57818034200002, 20.274250531000071],
              [106.57848142600007, 20.276505164000099],
              [106.57899706700002, 20.280366379000014],
              [106.57909878800008, 20.281181776000068],
              [106.580054213, 20.283021106000106],
              [106.58271564000003, 20.287111942000102],
              [106.5848485380001, 20.289875388000041],
              [106.58614665900005, 20.290842720000057],
              [106.58882678800002, 20.292008270000039],
              [106.58999313600007, 20.292857618000056],
              [106.5904164880001, 20.293984764000058],
              [106.59113262800003, 20.299183199000126],
              [106.59166425500005, 20.300103151000123],
              [106.59252468800001, 20.300210496000126],
              [106.59392408300008, 20.300219443000067],
              [106.59479035300006, 20.299512108000073],
              [106.59544280500005, 20.298599716000076],
              [106.59567196100009, 20.296666218000055],
              [106.59568656400006, 20.294629506000028],
              [106.59591645300007, 20.29259416100011],
              [106.59646049900006, 20.291782906000044],
              [106.59743291100004, 20.291279902000078],
            ],
          ],
          [
            [
              [106.59714998200008, 20.417922430000068],
              [106.59658264400004, 20.417774087000048],
              [106.59617832200007, 20.417784890000057],
              [106.59582398700006, 20.417951153000047],
              [106.59544474600003, 20.418656157000022],
              [106.59541822400006, 20.41914606800006],
              [106.59552228600006, 20.419535885000073],
              [106.59626251100011, 20.420672472000057],
              [106.59759785800001, 20.422308049000023],
              [106.59952735900006, 20.424584059000047],
              [106.602052568, 20.427288278000063],
              [106.60598967000007, 20.431416208000108],
              [106.60710218000001, 20.432838062000066],
              [106.60783899000005, 20.43446976000007],
              [106.60827191900007, 20.436736186000104],
              [106.60840802000006, 20.438647024000112],
              [106.60824726600008, 20.440202295000098],
              [106.60808802900004, 20.441545337000022],
              [106.6077816170001, 20.442533762],
              [106.60664638000004, 20.44429506700007],
              [106.60581152300007, 20.445916796000049],
              [106.60588131900008, 20.446624630000088],
              [106.60617616700004, 20.447263141000072],
              [106.60677390800004, 20.447408409000012],
              [106.607673049, 20.447272609000052],
              [106.60865155100004, 20.446500662000041],
              [106.60970992700004, 20.445021821000076],
              [106.61047091800003, 20.443258141000072],
              [106.61115906000003, 20.441211030000069],
              [106.61118817900005, 20.437108293000072],
              [106.61097973100003, 20.434843289000071],
              [106.61032023500009, 20.432858392000064],
              [106.60906156000004, 20.430940452000087],
              [106.60624139600003, 20.427597790000092],
              [106.60141442200005, 20.422332321000077],
              [106.59896565000003, 20.419416329000036],
              [106.59770209500002, 20.418205676000056],
              [106.59714998200008, 20.417922430000068],
            ],
          ],
          [
            [
              [106.6030832430001, 20.466672063000054],
              [106.60248232000004, 20.46658704700004],
              [106.60196557800001, 20.466746177000061],
              [106.60161837800005, 20.467231216000044],
              [106.60152606100004, 20.468123903000013],
              [106.60160212600005, 20.469504891000057],
              [106.60219145400004, 20.471213958000064],
              [106.60260081300007, 20.474058762000077],
              [106.60249922500002, 20.476250660000069],
              [106.60197085200008, 20.47803382799999],
              [106.60031309900006, 20.48151511800009],
              [106.59926500600002, 20.483863389000057],
              [106.59908266000012, 20.485323904000019],
              [106.59907684400008, 20.486135914000059],
              [106.59941362000006, 20.487112506000038],
              [106.60069050400001, 20.488825901000048],
              [106.601114405, 20.489640636000047],
              [106.60110917900002, 20.490371435000029],
              [106.59972018200003, 20.49231149700006],
              [106.59770743800001, 20.497333288000071],
              [106.59744320700001, 20.498224839000031],
              [106.59769637800008, 20.498876072000094],
              [106.59812551600007, 20.498960004000111],
              [106.59950294800008, 20.498643953000069],
              [106.60071255100007, 20.497758406000074],
              [106.60279608500007, 20.494848321000077],
              [106.60401955800003, 20.492013967000041],
              [106.60420361100009, 20.490309860000089],
              [106.60448054500009, 20.487631880000023],
              [106.6048596270001, 20.482680819000088],
              [106.60531362700009, 20.479273096000036],
              [106.60559517000011, 20.475945457000044],
              [106.60561890000012, 20.472616178000088],
              [106.60512646300006, 20.469364819000099],
              [106.60436467000008, 20.467735868000055],
              [106.60368185800007, 20.467081889000035],
              [106.6030832430001, 20.466672063000054],
            ],
          ],
          [
            [
              [106.40804383400005, 20.703893878000109],
              [106.40477839400006, 20.699402471000013],
              [106.40018570300002, 20.694214788000039],
              [106.3992130140001, 20.691708595000065],
              [106.39922709300001, 20.689990536000025],
              [106.40006238500003, 20.689059417000074],
              [106.40205633300008, 20.688136690000114],
              [106.40404388200002, 20.687994877000015],
              [106.40653691800007, 20.686763325000079],
              [106.41353322200004, 20.681346830000088],
              [106.41886263500001, 20.677323919000031],
              [106.42136931600002, 20.674374077000031],
              [106.42309057400007, 20.671043108000028],
              [106.42367296300003, 20.671584216000035],
              [106.424286753, 20.67212876300006],
              [106.42488095600005, 20.672262652000128],
              [106.42522419600003, 20.672286710000073],
              [106.42549927800006, 20.672245459000038],
              [106.42674357800007, 20.671260418000067],
              [106.42985548800007, 20.66864655600007],
              [106.43160739700008, 20.667168146000044],
              [106.43485774500007, 20.664403919000037],
              [106.43653693500009, 20.662949864000076],
              [106.43732445800006, 20.662282318000088],
              [106.43993432600008, 20.659427053000101],
              [106.44081236500008, 20.658417725000078],
              [106.44136799800005, 20.657643774000078],
              [106.4421317210001, 20.656612031000066],
              [106.44229472700007, 20.656267462000066],
              [106.44229782700006, 20.65587855800004],
              [106.44218784400002, 20.655315989000123],
              [106.442033108, 20.654623465000022],
              [106.44183103700007, 20.654125069000081],
              [106.441592927, 20.653883818000018],
              [106.44386199900008, 20.650916304000063],
              [106.44986322300004, 20.644253680000091],
              [106.45251969000002, 20.640961955000044],
              [106.45321396400008, 20.639403589000068],
              [106.45322852000004, 20.637564582000053],
              [106.4528716240001, 20.633424059000035],
              [106.451443974, 20.629184034000048],
              [106.44875301100011, 20.624567255000059],
              [106.448183896, 20.622632141000082],
              [106.4481904670001, 20.621804568000087],
              [106.448684063, 20.620980421000112],
              [106.44995616500009, 20.620253696000113],
              [106.45385817900009, 20.619545428000038],
              [106.45530491000007, 20.619203277000061],
              [106.45820102700006, 20.618518313000052],
              [106.46279998500009, 20.615883620000105],
              [106.46562419800009, 20.612723156000087],
              [106.46576951600012, 20.612507232000077],
              [106.466635486, 20.611220420000095],
              [106.46752939300006, 20.609019605000078],
              [106.46784321900002, 20.60626299300008],
              [106.46816639500003, 20.602310946000024],
              [106.46836983800006, 20.601208831000051],
              [106.46915764600006, 20.600110772000058],
              [106.47013735000012, 20.599381878000052],
              [106.47218551300003, 20.599028243000106],
              [106.47656825800001, 20.599058571000036],
              [106.47909335800004, 20.599995614000122],
              [106.48141654300007, 20.601850841000058],
              [106.4847940320001, 20.605920332000061],
              [106.48777992800007, 20.610262941000101],
              [106.48921250200002, 20.613951133000086],
              [106.49007064900006, 20.616347932000068],
              [106.490939466, 20.617365423000052],
              [106.49171869600008, 20.617370755000053],
              [106.50073452000007, 20.616650594000063],
              [106.50580581900002, 20.61585739300007],
              [106.51049237100008, 20.614417759000055],
              [106.51254481700009, 20.613512028000081],
              [106.51391822700003, 20.612233861000014],
              [106.51539041500007, 20.610772437000065],
              [106.51628097900004, 20.608939237000087],
              [106.51678194100002, 20.607103416000072],
              [106.51729125400006, 20.604164124000057],
              [106.51758762300007, 20.603614360000044],
              [106.51856368900009, 20.603345037000054],
              [106.52090259699999, 20.60317681800008],
              [106.52156463500006, 20.603235649000062],
              [106.525380071, 20.603574655000045],
              [106.5290728410001, 20.604702845000041],
              [106.53470120800003, 20.607499116000035],
              [106.53731996400008, 20.608987861000024],
              [106.53867187800003, 20.610560130000088],
              [106.53962251900008, 20.613693034000043],
              [106.54008892100003, 20.616454878000035],
              [106.53985839600006, 20.621235176000049],
              [106.53984603000001, 20.622890327000043],
              [106.54051619600003, 20.624458044000079],
              [106.54274284900006, 20.62631191100008],
              [106.54579376300008, 20.628676940000013],
              [106.54774059800005, 20.628873676000033],
              [106.55085701800006, 20.628986135000012],
              [106.55426975500008, 20.628548749000061],
              [106.55719678900005, 20.627924227000079],
              [106.55856526200003, 20.627289483000055],
              [106.56072383000003, 20.625188572000042],
              [106.56298381200006, 20.622536545000081],
              [106.56445234400006, 20.621534578000045],
              [106.56533102100011, 20.62126442100007],
              [106.56669470000006, 20.621273290000097],
              [106.56854136599999, 20.621837036000024],
              [106.57601751900005, 20.625195907000112],
              [106.5835893280001, 20.628830983000057],
              [106.584751587, 20.629758009000021],
              [106.58706682100006, 20.632899365000057],
              [106.58976648400005, 20.636778790000051],
              [106.592421491, 20.638650147000078],
              [106.59442183400004, 20.639659120000076],
              [106.59724224399999, 20.640320759000105],
              [106.60035887400002, 20.640432517000114],
              [106.60385616500011, 20.634702830000059],
              [106.60586946400007, 20.632100411000032],
              [106.60798830200005, 20.630230871000066],
              [106.61032649400008, 20.628676518000063],
              [106.6138820690001, 20.627339003000067],
              [106.61835907800007, 20.626024972000053],
              [106.62064884100008, 20.626335361000066],
              [106.6233048870001, 20.627527397000023],
              [106.62380004100004, 20.626607867000054],
              [106.62552009300006, 20.625234658000032],
              [106.62687523700002, 20.62362851200001],
              [106.62798847500002, 20.621674859000017],
              [106.62849815900009, 20.618679481000065],
              [106.628770805, 20.614644628000086],
              [106.62866321399999, 20.612568019000044],
              [106.62638601000003, 20.606325993000048],
              [106.62433621000008, 20.6025072730001],
              [106.62155115500003, 20.599029906000062],
              [106.61791688700008, 20.594739841000106],
              [106.61050620800002, 20.589041904000013],
              [106.60831504800009, 20.587990072000117],
              [106.60575666200006, 20.587051230000064],
              [106.60381230700003, 20.585654903000083],
              [106.60150898400006, 20.583218270000089],
              [106.59473558400006, 20.573948295000029],
              [106.59243002000008, 20.571857514000058],
              [106.58636017400003, 20.566743813000073],
              [106.58088703900007, 20.563594491000089],
              [106.57930509500001, 20.562883681000073],
              [106.57723501900006, 20.561956195000072],
              [106.57309877200004, 20.55973801600004],
              [106.57237192700003, 20.558925924000079],
              [106.57226813800006, 20.557989861000081],
              [106.57299516900008, 20.557199864000083],
              [106.57430820900001, 20.556710282000076],
              [106.57716929500003, 20.556887786000019],
              [106.57902595300001, 20.556263693000076],
              [106.58021491400007, 20.554840195000068],
              [106.58107992500004, 20.552264565000122],
              [106.58153242600009, 20.549218697000079],
              [106.58262901000005, 20.546380210000059],
              [106.58393633400004, 20.544152832000059],
              [106.58394074900005, 20.543543098000058],
              [106.58265700100006, 20.54251857400002],
              [106.57857736700001, 20.54127277100006],
              [106.57751180200005, 20.539843109000074],
              [106.57645809000007, 20.536787467000025],
              [106.57479147900003, 20.529256177000029],
              [106.57417709400009, 20.524983766000027],
              [106.57484480300009, 20.521939179000093],
              [106.57661195000003, 20.515649493000062],
              [106.58213227600007, 20.496171595000057],
              [106.585213239, 20.486637690000094],
              [106.58718898000005, 20.481162026],
              [106.58741730800003, 20.479334029000107],
              [106.58724090400008, 20.477408480000037],
              [106.58666501700003, 20.476649424000115],
              [106.58550382100006, 20.476435967000036],
              [106.58518446000002, 20.474412456000032],
              [106.58481593600007, 20.471846632],
              [106.58562020800009, 20.470847557000035],
              [106.58552186800004, 20.466076746000091],
              [106.58540264800004, 20.464193008000017],
              [106.58528524500009, 20.462058213000134],
              [106.58568826800004, 20.461433138000103],
              [106.587558755, 20.459938747000095],
              [106.58996594900005, 20.457694587000042],
              [106.59157342300009, 20.455821868000079],
              [106.59278420099999, 20.453695540000091],
              [106.59494544100002, 20.44868797],
              [106.59657080000004, 20.44430460800006],
              [106.59724928800004, 20.442300368000055],
              [106.59725738800009, 20.441170595000102],
              [106.59608366300006, 20.438150247000038],
              [106.594511524, 20.435127327000124],
              [106.59148126400001, 20.43171845400007],
              [106.59082444900011, 20.430709953000111],
              [106.59069887100003, 20.429704839000081],
              [106.59083800900008, 20.428826961000098],
              [106.5913728510001, 20.428328231000044],
              [106.59363417500006, 20.427840531000108],
              [106.59523145600006, 20.427348574000078],
              [106.59523506000009, 20.426846444000098],
              [106.59497395100007, 20.426217082000072],
              [106.59353745600005, 20.422818355000061],
              [106.59197362700007, 20.418665549000089],
              [106.58910451300008, 20.411365822000072],
              [106.58741530500004, 20.406207765000062],
              [106.58494128000005, 20.399412557000041],
              [106.58285486400008, 20.39412628000008],
              [106.58207998600001, 20.391108213000031],
              [106.58144155200003, 20.387588818000069],
              [106.579384512, 20.378285098000056],
              [106.57875164700005, 20.374012387000029],
              [106.57783684400003, 20.37199769900004],
              [106.57638187300009, 20.371235004000084],
              [106.57479322900008, 20.370596978000052],
              [106.57320002000007, 20.370586657000089],
              [106.57142227100006, 20.371247945000093],
              [106.5696773450001, 20.373062967000074],
              [106.56859885800004, 20.374819334000094],
              [106.56838431400006, 20.37683322],
              [106.56888674100006, 20.380992981000112],
              [106.56880310100007, 20.383322586000041],
              [106.56845492400004, 20.383989456000059],
              [106.56752272600008, 20.384360036000082],
              [106.56632774300007, 20.38435225700006],
              [106.56540381900001, 20.383592952000114],
              [106.56475743500008, 20.38120335100011],
              [106.56437205200008, 20.377811525000041],
              [106.56426045600008, 20.374923198000069],
              [106.56507365600004, 20.372035564000065],
              [106.56729115300001, 20.368424135000041],
              [106.56884254900004, 20.367294081000061],
              [106.57017295600008, 20.366926074000077],
              [106.57203165900003, 20.366938139000098],
              [106.57415773600009, 20.366700822],
              [106.57522442200006, 20.366079977000062],
              [106.57629384100008, 20.365082500000128],
              [106.57710045600003, 20.36370666400007],
              [106.57751699500002, 20.361198346],
              [106.57794809600009, 20.356681279],
              [106.57851103100003, 20.352290598000074],
              [106.57865746000007, 20.350408258000115],
              [106.57814468000001, 20.347893888000087],
              [106.57775828200002, 20.346259200000105],
              [106.57803016200003, 20.34538208],
              [106.57869573900004, 20.345135273000011],
              [106.58108615600003, 20.345025138000047],
              [106.58215179300001, 20.344529792000095],
              [106.58255912900005, 20.343276882000083],
              [106.58269612500001, 20.341153762000097],
              [106.58258847500008, 20.337626140000076],
              [106.58254285700012, 20.334728714000057],
              [106.58282744300004, 20.332211295000043],
              [106.58351471200007, 20.329255590000095],
              [106.58399856900004, 20.326802416000064],
              [106.58668769400005, 20.320083905000082],
              [106.58699760200003, 20.319475796000042],
              [106.58724146400004, 20.31861038800011],
              [106.58742115399998, 20.317230796],
              [106.58743806100006, 20.314886846000043],
              [106.58735522200004, 20.31170843900005],
              [106.58731876200004, 20.310900671000098],
              [106.58717776900001, 20.310351288000028],
              [106.586751581, 20.309148735000093],
              [106.58675875500006, 20.308154647],
              [106.58771277600005, 20.306515305000069],
              [106.58786365300008, 20.305693542000114],
              [106.58806792800003, 20.304234423000089],
              [106.58796268000003, 20.300556753000087],
              [106.58751773900008, 20.29831029400011],
              [106.586419769, 20.295894091000029],
              [106.58492821600004, 20.2937458540001],
              [106.58183462400005, 20.289855982000034],
              [106.57948944400007, 20.286683769000021],
              [106.57703226700008, 20.284121845000058],
              [106.57489287300007, 20.282274839000088],
              [106.57242616400008, 20.281036738000054],
              [106.56952300300003, 20.280610511000049],
              [106.56715429500007, 20.28069694],
              [106.56463031700004, 20.282188277000031],
              [106.56329673900009, 20.280000890000061],
              [106.55899878800003, 20.284315351000082],
              [106.55526860600003, 20.288053474000023],
              [106.54932976200007, 20.293723125000085],
              [106.54311533000006, 20.299520482000055],
              [106.53842167500002, 20.303641090000021],
              [106.53593471600004, 20.306089637000113],
              [106.53316661100007, 20.309444449000047],
              [106.53094991500006, 20.312413687000053],
              [106.52887225200001, 20.315124339000064],
              [106.52735483800005, 20.316281837000091],
              [106.52700893800004, 20.316483122000051],
              [106.52625275700008, 20.316923152000044],
              [106.52487735200006, 20.317432897000032],
              [106.52391730800011, 20.317426460000028],
              [106.5228210810001, 20.317289364000054],
              [106.52131635900002, 20.31676030600007],
              [106.51899364400005, 20.315577031000132],
              [106.51462653200011, 20.312693323000097],
              [106.51162311700003, 20.310856678],
              [106.50984811899998, 20.309806741000088],
              [106.50724534100002, 20.309399875000089],
              [106.50203087700004, 20.309753665000052],
              [106.49777939600008, 20.309724682000052],
              [106.49600050800007, 20.309193563000072],
              [106.49422825700006, 20.308276558000045],
              [106.49403362500004, 20.308098550000061],
              [106.49245273600006, 20.306833955000059],
              [106.49000218200007, 20.304481813000038],
              [106.48872736000003, 20.302963914000124],
              [106.48660474200008, 20.30043647800008],
              [106.48388207000005, 20.297822886000105],
              [106.48101831100007, 20.295727244000084],
              [106.47787731700006, 20.294018876000038],
              [106.47405072800008, 20.292305704000043],
              [106.47076562700005, 20.291504434],
              [106.46747853200003, 20.290962590000028],
              [106.46240672200005, 20.290667705000054],
              [106.45746796400007, 20.290892627000098],
              [106.45266427800007, 20.291377869000101],
              [106.44936695400001, 20.292133119000063],
              [106.44400644500004, 20.293652199000078],
              [106.44015431900007, 20.295181853000074],
              [106.43367997800004, 20.29876865900011],
              [106.43126370000006, 20.300431689000042],
              [106.43050816000007, 20.300951686000076],
              [106.42497988500007, 20.306231568000044],
              [106.42234805900009, 20.309456240000131],
              [106.41944082200007, 20.312808631000046],
              [106.41528338500007, 20.318098062000089],
              [106.41181360400003, 20.323132860000023],
              [106.40959070900006, 20.326619734000047],
              [106.40668087799999, 20.330231326000089],
              [106.40474374400003, 20.33229308700006],
              [106.39880450500004, 20.337309668000088],
              [106.39245878200009, 20.341674405],
              [106.38818633400008, 20.344108118000023],
              [106.38570981800007, 20.344998110000034],
              [106.38296323200011, 20.345367156000101],
              [106.38000408700005, 20.345438667000067],
              [106.37884709300006, 20.345466612000074],
              [106.37528070000012, 20.345440315000118],
              [106.37184824300007, 20.345804149000024],
              [106.36868687300006, 20.34655914500005],
              [106.36400792400008, 20.348340694000022],
              [106.35904586300003, 20.35115789000006],
              [106.3551799630001, 20.354112901000043],
              [106.35227636300002, 20.356815561000104],
              [106.35033402000006, 20.359395655000064],
              [106.34783525299999, 20.36287966700003],
              [106.34394041200005, 20.36920720500008],
              [106.34255477200006, 20.371925347000094],
              [106.34156870400001, 20.373859612000089],
              [106.3399015360001, 20.376311895000043],
              [106.33865471500005, 20.377729501000097],
              [106.33796435300003, 20.378243210000058],
              [106.3360436720001, 20.378228701000097],
              [106.33481225, 20.377830200000076],
              [106.33290262100009, 20.376518466000078],
              [106.32936335500008, 20.373248402000051],
              [106.32433291900006, 20.367891241000088],
              [106.31957044200001, 20.363314354000025],
              [106.31847861900009, 20.36231499200003],
              [106.31671194500011, 20.360697892000076],
              [106.31371187600004, 20.358599214000073],
              [106.31071076200006, 20.356630214000049],
              [106.30784911800008, 20.354402780000044],
              [106.30240262100008, 20.349690441000057],
              [106.29709693200003, 20.344589814000088],
              [106.29341941300004, 20.341577416000064],
              [106.29181903200006, 20.340470549000052],
              [106.28905617900007, 20.338559622000041],
              [106.28564639900003, 20.336327567000097],
              [106.27991522300003, 20.332909692],
              [106.27595815800001, 20.330543458000072],
              [106.27213146800003, 20.328956595000044],
              [106.26775278700011, 20.32775452700006],
              [106.26528628400004, 20.327475629000077],
              [106.26322894800008, 20.32745940300007],
              [106.26102984800011, 20.327960986000093],
              [106.25868780900004, 20.329110114000045],
              [106.25661313300006, 20.331039749000098],
              [106.25483600900002, 20.333002037000099],
              [106.25439663700008, 20.333487190000099],
              [106.25286356900007, 20.336199502000071],
              [106.25146067300008, 20.339691244000058],
              [106.25060986900012, 20.342798138000084],
              [106.25017401800002, 20.345519115000066],
              [106.25029032100005, 20.347855263],
              [106.25081929600003, 20.350064956000047],
              [106.25324209300004, 20.355273533000059],
              [106.25622182000011, 20.359578338000084],
              [106.25988526800009, 20.364147947000077],
              [106.26383132400009, 20.367811578000072],
              [106.26969123700009, 20.372268538000071],
              [106.273508447, 20.375022831000052],
              [106.280453816, 20.380914983000054],
              [106.28262387100006, 20.383785929000098],
              [106.28383701800007, 20.386260218000096],
              [106.2840875360001, 20.388986428000067],
              [106.28406820400002, 20.39119163100009],
              [106.28363384800005, 20.393782765000076],
              [106.28279129500005, 20.395981526000106],
              [106.28098599400008, 20.398432214000103],
              [106.27821906300002, 20.401005087000087],
              [106.27559726900007, 20.402670984000082],
              [106.27215560800006, 20.403941238000108],
              [106.26582903800009, 20.405577900000083],
              [106.26239302000002, 20.406199427000082],
              [106.25840813700009, 20.406816569000071],
              [106.24029626200003, 20.406672567000058],
              [106.23466475800011, 20.407276104000083],
              [106.22875645400011, 20.408136720000059],
              [106.22243056400001, 20.409642419000114],
              [106.21898371300001, 20.411430688000017],
              [106.21732295900009, 20.412973918000077],
              [106.21455143500002, 20.415935069000035],
              [106.21322907100003, 20.417696063000079],
              [106.21129268900003, 20.420299914000047],
              [106.20926476900011, 20.423805125000079],
              [106.20827926500002, 20.426521234000106],
              [106.20798937000009, 20.428205232000082],
              [106.20810324900002, 20.42938464800006],
              [106.20851453200004, 20.430803892000071],
              [106.21176058300003, 20.436019018000096],
              [106.21582312500007, 20.442018973000039],
              [106.2177148860001, 20.445277203000067],
              [106.22054380400004, 20.451137274000075],
              [106.2221482840001, 20.45581995500001],
              [106.22361088000007, 20.461020307000055],
              [106.22400143000004, 20.463358297000056],
              [106.22355670600011, 20.466986653000063],
              [106.22243510400003, 20.469571842000086],
              [106.22145663800008, 20.471509618000105],
              [106.21937772300002, 20.473697907000066],
              [106.21758036500007, 20.47511018600003],
              [106.21109480800001, 20.478819206000054],
              [106.20814613600001, 20.47972138100009],
              [106.20696507600007, 20.480082725000059],
              [106.20256791100006, 20.480565735000056],
              [106.19323405800004, 20.480489381000041],
              [106.18966162900004, 20.480849176000056],
              [106.187045224, 20.481735627000127],
              [106.18363298000003, 20.483660999000094],
              [106.18179304500003, 20.48558361100001],
              [106.18067441400011, 20.48777942600006],
              [106.17913911500008, 20.49049060400003],
              [106.17717504200003, 20.495014124000122],
              [106.17701842700004, 20.497088141000042],
              [106.17695919500002, 20.503443237000063],
              [106.17758879100006, 20.509544569000049],
              [106.17807751300009, 20.516033783000061],
              [106.17796246800008, 20.517959221000034],
              [106.17789914400008, 20.518873422000091],
              [106.17732459800006, 20.523161172000037],
              [106.17588635100007, 20.530153118000079],
              [106.17305680400008, 20.539078926000087],
              [106.170661986, 20.545543956000053],
              [106.16829129900003, 20.549415166000081],
              [106.16508568200001, 20.554446644],
              [106.15938377600004, 20.562051100000112],
              [106.15632850000001, 20.565656982000036],
              [106.15452862700005, 20.567198236000102],
              [106.15259875100006, 20.56796023600009],
              [106.14943293600004, 20.568711836000105],
              [106.14333418600009, 20.569724884000081],
              [106.14296760800005, 20.569824690000083],
              [106.13980543700006, 20.570187109000045],
              [106.13718637500008, 20.571202532000129],
              [106.13497926200007, 20.572221400000053],
              [106.13249003400009, 20.574016014000037],
              [106.13137902000008, 20.575303520000077],
              [106.12956644400003, 20.578141351000042],
              [106.128023528, 20.581500212000044],
              [106.12757798600009, 20.584998060000089],
              [106.12756059600007, 20.586813567000029],
              [106.1277992560001, 20.590576569000071],
              [106.12817651100005, 20.59421102700005],
              [106.12896083500002, 20.59836768300007],
              [106.12933068000007, 20.602780168000031],
              [106.12930461800005, 20.60550334400002],
              [106.12859179500003, 20.608220689000056],
              [106.12774780700002, 20.610288487000084],
              [106.12482855100006, 20.613894834000043],
              [106.12219636700006, 20.616206739000106],
              [106.11855738800006, 20.618132504000108],
              [106.121934987, 20.620431637000088],
              [106.12402866000005, 20.621863014000041],
              [106.12546042400002, 20.622922276000018],
              [106.12661493000007, 20.623979163000072],
              [106.12738327900006, 20.624823358000107],
              [106.12765353300003, 20.625558599000016],
              [106.12781137300004, 20.626449951000048],
              [106.12768892600012, 20.627653048000113],
              [106.12673632200008, 20.629500248000099],
              [106.12539539200006, 20.630848001000054],
              [106.12296802900005, 20.632700506000035],
              [106.11847071300008, 20.636073976000091],
              [106.11661724300002, 20.637195413000065],
              [106.11526885000009, 20.637384592000082],
              [106.11229376900005, 20.637225352000051],
              [106.110308221, 20.637342139000033],
              [106.10916853600003, 20.637934447000106],
              [106.10837670300005, 20.639198714000081],
              [106.10822655000007, 20.64006708600008],
              [106.10817135500011, 20.642106989000105],
              [106.10886061400004, 20.644119813000117],
              [106.11083071000006, 20.645608431000092],
              [106.11280600500007, 20.646561899000027],
              [106.11690999600009, 20.647265943000015],
              [106.12016872300006, 20.64742754400006],
              [106.12285406900003, 20.648253170000075],
              [106.12553045500005, 20.650015247000027],
              [106.12597566300012, 20.650476053000055],
              [106.12917986700002, 20.653792422],
              [106.13239907300002, 20.658101034000076],
              [106.13479345900006, 20.659726784000028],
              [106.13719553800004, 20.660549831000097],
              [106.14244027000002, 20.660594066000023],
              [106.14528415000005, 20.659681464000101],
              [106.14899497000005, 20.657036816000037],
              [106.15658203100003, 20.649206641000092],
              [106.16014823900005, 20.646828147000029],
              [106.1625640940001, 20.646179316000079],
              [106.16539887900008, 20.646202917000075],
              [106.16695048100003, 20.647018581000061],
              [106.1680655860001, 20.649034739000079],
              [106.16804802700007, 20.650907690000061],
              [106.16843688900009, 20.654790885000104],
              [106.16955205400008, 20.656807011000033],
              [106.17095324300001, 20.658557921000039],
              [106.17264796700009, 20.659240919000062],
              [106.17447174600008, 20.659256021000068],
              [106.17775965599999, 20.658346676000058],
              [106.18190725500006, 20.656151290000047],
              [106.19086738100006, 20.651229849000075],
              [106.20141546900001, 20.644894075000053],
              [106.20482825600003, 20.643717745000068],
              [106.21618193000009, 20.642204343000081],
              [106.22199312500005, 20.642251272000053],
              [106.2235437150001, 20.643200325000066],
              [106.22352912000005, 20.644805725000111],
              [106.22294635000006, 20.646540344000108],
              [106.21737090900007, 20.651713253000104],
              [106.21579584900005, 20.653439813000048],
              [106.21521298400003, 20.655174379000037],
              [106.2161905510001, 20.656787777000126],
              [106.21845240700002, 20.657475018000056],
              [106.22567181500006, 20.658603538000087],
              [106.22892597100009, 20.659298641000042],
              [106.23089955700006, 20.660518596000088],
              [106.23176688700005, 20.6617414400001],
              [106.23299919000006, 20.663478800000057],
              [106.23552052600004, 20.666843710000116],
              [106.23732062500002, 20.668571630000088],
              [106.23819094400001, 20.669407034000109],
              [106.24101899300005, 20.671519347000086],
              [106.24199309100001, 20.67224691600007],
              [106.24552263100001, 20.673880463000046],
              [106.25230675900009, 20.676208700000039],
              [106.26078625300002, 20.679219018000069],
              [106.26389316700005, 20.680581353000079],
              [106.26671184600004, 20.682476507000032],
              [106.26755176700007, 20.683687176000106],
              [106.26765557300006, 20.685548698000034],
              [106.26738033800011, 20.687030447000062],
              [106.26637726700007, 20.688226620000094],
              [106.26410299900002, 20.688877661000049],
              [106.25927802500011, 20.689374775000054],
              [106.25530365500008, 20.689878513000082],
              [106.25260042400005, 20.690927384000091],
              [106.25031769100009, 20.69251469800006],
              [106.24788594700007, 20.69490348300009],
              [106.24702089100008, 20.696502009000106],
              [106.24743541800009, 20.697709339000077],
              [106.25208653099999, 20.700823227000065],
              [106.25702731500004, 20.703270379000038],
              [106.2600625310001, 20.704525688000075],
              [106.26154798000007, 20.704809120000078],
              [106.26255588900008, 20.704817060000025],
              [106.26390097500008, 20.704691792000048],
              [106.26491290500005, 20.704246882000099],
              [106.26573566900001, 20.70348346600008],
              [106.26670642400003, 20.702268346000082],
              [106.26976898800004, 20.69764514300001],
              [106.27110594300008, 20.695897709000107],
              [106.27295167200003, 20.694184908000054],
              [106.27486586200004, 20.693300482000062],
              [106.276774996, 20.692988353000082],
              [106.27911375500007, 20.693088375000102],
              [106.28248725500004, 20.693768773000087],
              [106.28879321500008, 20.696025393000106],
              [106.29078113000007, 20.69661313600006],
              [106.291819488, 20.696784707000049],
              [106.29286213500004, 20.696465729000032],
              [106.29443395500006, 20.695087910000076],
              [106.29819260000001, 20.691355806000011],
              [106.29958607200011, 20.690548910000039],
              [106.30079982700008, 20.690476497000084],
              [106.30244113500008, 20.691061479000084],
              [106.3073523680001, 20.694288006000058],
              [106.30832073900011, 20.694796955000072],
              [106.31571987100008, 20.698685489000113],
              [106.31986009300009, 20.700924655000016],
              [106.32243996700002, 20.703233638000107],
              [106.32622113000006, 20.706941642000039],
              [106.33043572100009, 20.710652849000063],
              [106.33319614300004, 20.712145423000017],
              [106.33544571000002, 20.712571208000071],
              [106.33717884400005, 20.71258427700009],
              [106.33882879100005, 20.712187904000068],
              [106.34005240300004, 20.710970688000074],
              [106.342507896, 20.707555168000091],
              [106.34439695400008, 20.704503278000082],
              [106.34605651000007, 20.702962251000123],
              [106.34744916700002, 20.702236826000053],
              [106.35020229300008, 20.701516301000119],
              [106.35256851300002, 20.701457488000052],
              [106.35473273700003, 20.701718929],
              [106.35748917900003, 20.702600920000066],
              [106.35921874700004, 20.703503544000057],
              [106.36284372800003, 20.70586737100006],
              [106.36780000600004, 20.709333028000017],
              [106.37102380300007, 20.710976648000035],
              [106.3739193120001, 20.712094269000133],
              [106.37993921800009, 20.712647813000018],
              [106.38091261300008, 20.712744627000035],
              [106.38212381800003, 20.714665806000099],
              [106.38321027900002, 20.715706897000082],
              [106.38446586200008, 20.71666975200008],
              [106.38831956200009, 20.719241011000108],
              [106.39091179900008, 20.721564590000071],
              [106.39392333100002, 20.724129587000029],
              [106.39559889200011, 20.725254362],
              [106.39652157200007, 20.725737895000051],
              [106.39753760400002, 20.725819048000105],
              [106.39806836800007, 20.727260989000037],
              [106.3990049070001, 20.729311748000079],
              [106.39995017400007, 20.730299712000111],
              [106.40253606400006, 20.732035371],
              [106.40507669000004, 20.733103138000075],
              [106.40570145100004, 20.730222116000085],
              [106.4066295910001, 20.725127144000055],
              [106.40699237700001, 20.724126147],
              [106.40770907400007, 20.723211343000081],
              [106.41225395400005, 20.720316874000041],
              [106.41423487200009, 20.719107742000098],
              [106.41428075000007, 20.718303535000082],
              [106.41450322300008, 20.716537896000098],
              [106.41441072100008, 20.715113626000026],
              [106.41415733900007, 20.714277272000103],
              [106.41379950400004, 20.713489252],
              [106.41297659700007, 20.712305171000089],
              [106.41148291500012, 20.710428985000107],
              [106.40978313400008, 20.708305858000116],
              [106.40875295700002, 20.707022066000036],
              [106.40834593500009, 20.705890049000075],
              [106.40831001100005, 20.704171090000045],
              [106.40804383400005, 20.703893878000109],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 54, code: "AD01", ten_tinh: "Thái Nguyên" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.70163885000002, 22.026339863000111],
              [105.70384839200005, 22.024675089000119],
              [105.70643130600007, 22.023635878000022],
              [105.70938972400006, 22.023044602000041],
              [105.71284461000002, 22.022918232000066],
              [105.71561308700002, 22.023556019000075],
              [105.7182182920001, 22.024192088000035],
              [105.71985105000002, 22.02420881600009],
              [105.72148738400006, 22.023920887000067],
              [105.72461109500006, 22.022124770000048],
              [105.72675869600002, 22.020013946000105],
              [105.72907846000003, 22.01714315500007],
              [105.73006164600011, 22.016848496000115],
              [105.73152928400003, 22.017015799000106],
              [105.73510695100002, 22.018270955000041],
              [105.73853206600009, 22.018610449000064],
              [105.74098645800004, 22.018178349000017],
              [105.7462341630001, 22.016251102000069],
              [105.74969646000004, 22.013391642],
              [105.75036545600005, 22.012027327000091],
              [105.75076632400001, 22.005632961000018],
              [105.75128795800001, 22.002896033000049],
              [105.75196044500005, 22.00122702700007],
              [105.75294675500004, 22.000000165000074],
              [105.75333167000008, 21.998946325000098],
              [105.75386772000012, 21.995148036000067],
              [105.754103641, 21.992986221000073],
              [105.75446108600001, 21.992465173000042],
              [105.75651720500005, 21.990911950000047],
              [105.75878865600005, 21.98896736800009],
              [105.76077442600004, 21.987413378000028],
              [105.76148471, 21.9867647],
              [105.76163283300009, 21.986110367000045],
              [105.76166846500011, 21.983028337000029],
              [105.76153929800009, 21.982043296000064],
              [105.76092812500005, 21.980200814000028],
              [105.75969442100003, 21.97749948000007],
              [105.75925704200007, 21.975822685000068],
              [105.75846091700002, 21.971748455000025],
              [105.75848065500006, 21.970043458000085],
              [105.75889693800003, 21.968853286000112],
              [105.76242047300008, 21.965027102000079],
              [105.76508654400004, 21.962157650000066],
              [105.76613697800009, 21.960763945000039],
              [105.76748867800002, 21.957705710000042],
              [105.76854110300006, 21.956136477000051],
              [105.76958541500005, 21.955269274000067],
              [105.77128683100004, 21.95449642800007],
              [105.77252452200004, 21.953744094000079],
              [105.77306142200005, 21.952768122000066],
              [105.77418736500005, 21.946277953000056],
              [105.77561969300008, 21.935987952000069],
              [105.77564223300007, 21.934025425000044],
              [105.77567663100008, 21.933043304000051],
              [105.7753892420001, 21.9322086990001],
              [105.77451539, 21.930721315000042],
              [105.772273908, 21.927649177000049],
              [105.76857018200006, 21.922621579000086],
              [105.76437071600006, 21.917681309000066],
              [105.76300642100006, 21.915819244000097],
              [105.76282413500007, 21.915343276000065],
              [105.76346647500003, 21.914522510000012],
              [105.76678010300002, 21.912546802000051],
              [105.76856685400006, 21.91126479900003],
              [105.76933434800006, 21.910563434],
              [105.76972897100002, 21.909267453000055],
              [105.7698350810001, 21.900050650000047],
              [105.77036031500003, 21.898401407],
              [105.77202852100004, 21.8964090730001],
              [105.77662889300009, 21.892555165000083],
              [105.77816365900007, 21.891152326000096],
              [105.77868066900004, 21.890212044000101],
              [105.77856090400007, 21.889619941000049],
              [105.7773009150001, 21.889134630000036],
              [105.77237536100004, 21.888258088000079],
              [105.77048270700004, 21.887766398000075],
              [105.76935474000005, 21.886809637000049],
              [105.76892245600008, 21.885696886000083],
              [105.76861728200005, 21.8849113200001],
              [105.76845730500006, 21.883197702000068],
              [105.76878358400005, 21.882315342000069],
              [105.77022461800009, 21.880706117000059],
              [105.77087715000008, 21.87894136300006],
              [105.77089073400005, 21.877760641000066],
              [105.77058824700006, 21.876576740000075],
              [105.76950068900005, 21.87494215000001],
              [105.768794751, 21.873649867000019],
              [105.76779804200004, 21.871825267000041],
              [105.76672074099999, 21.869305085000065],
              [105.76658482700002, 21.867384779000076],
              [105.76661034500009, 21.865170872000036],
              [105.76775053200001, 21.862230079000078],
              [105.76776753500009, 21.860754127000078],
              [105.76763161700003, 21.858833790000084],
              [105.76593253400006, 21.855421610000022],
              [105.76315637700003, 21.849489090000063],
              [105.75973812000009, 21.844435707000095],
              [105.75867701100005, 21.842583237000035],
              [105.75714602100003, 21.838676453000083],
              [105.75650224600005, 21.835935476000046],
              [105.75618401700009, 21.834249502000077],
              [105.75608484500006, 21.83309159500002],
              [105.75608971500003, 21.832670954000029],
              [105.75632094000005, 21.832147415000058],
              [105.75745511500003, 21.831422648000043],
              [105.75848159400005, 21.83027608700003],
              [105.75917279500004, 21.828915794000103],
              [105.75975870900001, 21.826923392000097],
              [105.75978058800005, 21.825030463000061],
              [105.75934492700006, 21.823763972000073],
              [105.75801730100008, 21.821752261000071],
              [105.75757921700007, 21.82069608500008],
              [105.75758651600006, 21.820065108],
              [105.75827040000007, 21.819335787000057],
              [105.76349059300006, 21.815707190000033],
              [105.76598502800005, 21.814154620000089],
              [105.76813328000006, 21.813334787000116],
              [105.77128967900011, 21.812945738000074],
              [105.77557523100003, 21.812252426],
              [105.77693192900003, 21.811740114000067],
              [105.77897478600003, 21.810288048000089],
              [105.78181630600004, 21.807897326000081],
              [105.78375014300009, 21.806128585000103],
              [105.78499421900008, 21.805615087000071],
              [105.78825580800003, 21.805857918000093],
              [105.7944461390001, 21.80591942300012],
              [105.79627888700004, 21.806038417000103],
              [105.79747906200004, 21.806475392000024],
              [105.79848366400003, 21.807221587000036],
              [105.79881296300002, 21.807961099000025],
              [105.80105131400003, 21.819026819000072],
              [105.80236113600004, 21.827611532000013],
              [105.80256122200008, 21.829822155000045],
              [105.80287746200004, 21.831718399000088],
              [105.80331104100003, 21.833195106000055],
              [105.80496739500003, 21.836051126000108],
              [105.80985269900003, 21.842094087000063],
              [105.81280679600003, 21.844699848000104],
              [105.81470988800002, 21.845665096000033],
              [105.81695191100007, 21.846528485000057],
              [105.81837759100009, 21.846820564000069],
              [105.81964779600003, 21.847080783000102],
              [105.82415082000001, 21.847124892000025],
              [105.84218050100002, 21.84572303700012],
              [105.84725222, 21.845246339000113],
              [105.84995284000006, 21.845377647000049],
              [105.85714485100004, 21.846603946000087],
              [105.85860367900005, 21.847038676000082],
              [105.85960873699999, 21.847784537000067],
              [105.86182545, 21.850960942000071],
              [105.86236629700007, 21.852964355000012],
              [105.86234425800011, 21.854962340000036],
              [105.86175235200007, 21.857585850000035],
              [105.86105016100007, 21.85999795100004],
              [105.8609282940001, 21.860838111000099],
              [105.86103623300006, 21.861259815],
              [105.861596838, 21.861475548000087],
              [105.86227233700002, 21.861482042000091],
              [105.86464703600004, 21.860558351000044],
              [105.86713892300007, 21.859215091000102],
              [105.86929534900005, 21.857658251000075],
              [105.87087842100004, 21.857042403000015],
              [105.87189281600004, 21.856946945000018],
              [105.873016326, 21.857168038000019],
              [105.8787464860001, 21.858274441000077],
              [105.88000955700007, 21.858824919000064],
              [105.88119607500001, 21.860081875000056],
              [105.88630431200006, 21.868600826000062],
              [105.887861134, 21.872601614000061],
              [105.89087413500008, 21.877612657000078],
              [105.89232756500007, 21.878872046000097],
              [105.89616127700002, 21.882022339000073],
              [105.89826808599999, 21.884533437000094],
              [105.90497818700007, 21.89306668400004],
              [105.90828290800005, 21.895838024000014],
              [105.91133843400007, 21.896987746000015],
              [105.91453674800003, 21.897266858000108],
              [105.91854736300004, 21.896432537000088],
              [105.922691329, 21.895599379000075],
              [105.92469327100008, 21.895493509000019],
              [105.92762749400003, 21.895520865000059],
              [105.92988952800005, 21.896040155000037],
              [105.93174347900006, 21.897302957000072],
              [105.9362384360001, 21.901081362000063],
              [105.93783101000005, 21.901843463000056],
              [105.94115753800006, 21.902621598000046],
              [105.94421599900006, 21.903521768000097],
              [105.94634215000004, 21.90428872500005],
              [105.94991969200001, 21.90656369100008],
              [105.95429103900004, 21.909468656000094],
              [105.95666567400005, 21.911981521000087],
              [105.95810666199999, 21.91448578799999],
              [105.95846875300002, 21.918101088000071],
              [105.9587079730001, 21.920718832000126],
              [105.95882561800006, 21.922214506000067],
              [105.95934737400006, 21.923340242000044],
              [105.96014249500004, 21.923845733000036],
              [105.96147381200007, 21.924107026000073],
              [105.96363335000004, 21.924204380000042],
              [105.96494074500004, 21.924263315000033],
              [105.96746999700005, 21.924784634],
              [105.96839853100005, 21.925291312000091],
              [105.968658792, 21.925916426000036],
              [105.96865226100009, 21.926539111000039],
              [105.96808994200009, 21.92927403900002],
              [105.96793825200007, 21.931016318000026],
              [105.96791734600004, 21.933008881000049],
              [105.96855168700003, 21.936128333000049],
              [105.97012249800005, 21.939007216000036],
              [105.97142387000008, 21.940306915000107],
              [105.97302345600009, 21.940898025000074],
              [105.974630287, 21.940797354],
              [105.99170572900009, 21.937954165000036],
              [105.994185444, 21.93705406800008],
              [105.99592409100006, 21.936147278000107],
              [105.99778861200005, 21.935010989000055],
              [105.99878500500007, 21.934212806000097],
              [105.99990131500007, 21.933761608000069],
              [106.00088341200006, 21.934346974],
              [106.0027217300001, 21.935747177000039],
              [106.00480827800008, 21.937034274000041],
              [106.00824751400008, 21.938909995000074],
              [106.01021180700006, 21.940080635000037],
              [106.01193948400001, 21.941515473],
              [106.013601045, 21.943592147000054],
              [106.01521561500002, 21.946512262000084],
              [106.01595604800008, 21.948766674000098],
              [106.01658359200005, 21.953709696000075],
              [106.01688001000005, 21.954709882000053],
              [106.01714231700001, 21.955325091000063],
              [106.01907959200007, 21.95871048700004],
              [106.02167501300005, 21.962672513000122],
              [106.02333951300001, 21.965631124000048],
              [106.02550439300008, 21.967009018000013],
              [106.02792062700007, 21.967483334000093],
              [106.02853855800004, 21.967232996000071],
              [106.03010859500009, 21.966596928000023],
              [106.03594156900007, 21.964384019000072],
              [106.03861592400003, 21.963275361000086],
              [106.04300767700005, 21.959917296000064],
              [106.04691742300007, 21.956328443000039],
              [106.04887452400008, 21.954307546000074],
              [106.05010994800007, 21.951827406000035],
              [106.05062826300005, 21.948435144000108],
              [106.05138406300006, 21.94549784800008],
              [106.05236140200002, 21.944600575],
              [106.05430695200005, 21.943711773000096],
              [106.05673658800001, 21.942827181],
              [106.05940372000006, 21.942397546000066],
              [106.06665629400008, 21.943366503000036],
              [106.07101102400006, 21.943630753000051],
              [106.07609417900009, 21.943674761000054],
              [106.08457955700007, 21.942389232],
              [106.09209884300004, 21.94086863800004],
              [106.09647135600008, 21.939320880000054],
              [106.09678380700008, 21.93920705000005],
              [106.10133012000001, 21.937550723000051],
              [106.10328202700005, 21.93598216200003],
              [106.10621315100009, 21.933289609],
              [106.10986588900008, 21.931056058000117],
              [106.11229511199998, 21.930170837000063],
              [106.11471990500007, 21.929738474000082],
              [106.11763096900006, 21.929083738000088],
              [106.12273132000009, 21.927315126000032],
              [106.12637718400003, 21.925760621000023],
              [106.12832658200003, 21.924418240000065],
              [106.12979844600005, 21.922392442000046],
              [106.13031091600008, 21.919452695000082],
              [106.13032840200002, 21.917641104000026],
              [106.129615454, 21.916276310000079],
              [106.12648889000003, 21.914211785000056],
              [106.12553615300007, 21.912618481000031],
              [106.12555587400001, 21.910580415000069],
              [106.12724754000001, 21.907315333],
              [106.1277755830001, 21.90629615800006],
              [106.12802853600006, 21.905165919000069],
              [106.12804385400008, 21.903580741],
              [106.12709991400006, 21.901081581000078],
              [106.12613628400008, 21.900620518000032],
              [106.12276142500009, 21.899233217000088],
              [106.1160052370001, 21.897137812],
              [106.11383828900004, 21.895987069000086],
              [106.11264816100007, 21.893938675000065],
              [106.11194866600002, 21.891214998000038],
              [106.11197956200007, 21.888044546000081],
              [106.11298941500006, 21.883749976000018],
              [106.11422576300004, 21.881042668000106],
              [106.11448977200003, 21.878780065000036],
              [106.11451401400008, 21.876288935000062],
              [106.1135792100001, 21.872883717000043],
              [106.11047559200007, 21.8685541030001],
              [106.10856863500004, 21.865593529000101],
              [106.10762955400006, 21.862641160000074],
              [106.10765830500003, 21.859697007000101],
              [106.108899003, 21.856536654000088],
              [106.11209064000003, 21.851807381000029],
              [106.11479385000005, 21.847526875000064],
              [106.11578140100005, 21.845496757000049],
              [106.11579681400008, 21.84391139800006],
              [106.11484231700007, 21.8425443040001],
              [106.10694270800006, 21.833870117000039],
              [106.10526256400004, 21.83249677500001],
              [106.10308975300003, 21.832025240000071],
              [106.10042866700003, 21.832002521000057],
              [106.0987419210001, 21.83130859000002],
              [106.09634501800011, 21.829023022000094],
              [106.09396153500006, 21.82537849100008],
              [106.0930161860001, 21.823105300000023],
              [106.09304069700008, 21.820613915000116],
              [106.09525119200002, 21.817235207000074],
              [106.09917486000003, 21.811832490000072],
              [106.10333354900004, 21.807111201000048],
              [106.10481142700012, 21.804405620000097],
              [106.1048756380001, 21.797837142],
              [106.10443617000006, 21.793302980000107],
              [106.10445388600002, 21.7914909610001],
              [106.10471125400008, 21.789907493],
              [106.10594049000009, 21.787879267000051],
              [106.10741376300004, 21.785626571000094],
              [106.10985663300002, 21.78315557900001],
              [106.11107253199999, 21.782486340000062],
              [106.11469594300004, 21.782970155000037],
              [106.1192824020001, 21.783915121000064],
              [106.1267624430001, 21.785790483000063],
              [106.13207460500008, 21.786741284000044],
              [106.13473942200004, 21.786310616000023],
              [106.135961788, 21.784961728000063],
              [106.13671783400004, 21.781796711000112],
              [106.13795538500003, 21.778862234000052],
              [106.13902502000006, 21.777632767000021],
              [106.13991190800009, 21.77661333700005],
              [106.144556843, 21.771441993000096],
              [106.14652621500005, 21.76783392800008],
              [106.14656951700009, 21.763303633000049],
              [106.14754982400011, 21.761952596000086],
              [106.14998540100001, 21.760160611000053],
              [106.15630306300005, 21.757041613000091],
              [106.16066662000004, 21.755945084000047],
              [106.163812444, 21.755744564000068],
              [106.16599741100005, 21.754856461000124],
              [106.16868310500004, 21.752160154000052],
              [106.17867207200008, 21.744313353000067],
              [106.18062140800008, 21.742743524000026],
              [106.18268718400003, 21.741880556000034],
              [106.185308193, 21.741901977000033],
              [106.19068691400005, 21.742900782000071],
              [106.19243680900007, 21.742642180000082],
              [106.19288508800003, 21.741418040000049],
              [106.19319409800002, 21.739510654000057],
              [106.19408426600005, 21.737744404000026],
              [106.19543689700005, 21.735841486000048],
              [106.19731174600001, 21.735178587000092],
              [106.21106043100009, 21.728604949000015],
              [106.21398000900007, 21.72780989300005],
              [106.21750915600005, 21.728543624000096],
              [106.21941849900001, 21.731277505000079],
              [106.2215738570001, 21.733560226000037],
              [106.22302254500005, 21.733798372000109],
              [106.2244733200001, 21.73380997],
              [106.22617416900005, 21.732917365000048],
              [106.23103690700006, 21.730011025000096],
              [106.23371929000004, 21.72754034600004],
              [106.23495295600006, 21.724831561000087],
              [106.23595305600003, 21.721214701000058],
              [106.23598395800011, 21.717816670000012],
              [106.23552101899999, 21.715547470000018],
              [106.23385542800003, 21.712589030000018],
              [106.23243371000007, 21.709405946000082],
              [106.23173732800009, 21.706228609000085],
              [106.23086201000007, 21.700578094],
              [106.23057028800008, 21.699907396000029],
              [106.22791516900004, 21.697129168000018],
              [106.22634976900011, 21.695696368000128],
              [106.224605211, 21.694317820000016],
              [106.22277056300008, 21.693022089000102],
              [106.22243529100005, 21.692334179000028],
              [106.22246850100004, 21.688709418000052],
              [106.22419178300008, 21.685324718000039],
              [106.22469183100006, 21.683516177000058],
              [106.22375393800009, 21.680336728000071],
              [106.22015918800003, 21.676909412],
              [106.21678582900006, 21.673997207000092],
              [106.21307602000006, 21.671635756000015],
              [106.20978087500001, 21.669277575000123],
              [106.20607123100011, 21.666915995000032],
              [106.20463461499999, 21.665349943000045],
              [106.20423083700007, 21.664180840000121],
              [106.20466697100009, 21.66185268900005],
              [106.20447946200004, 21.659713801000088],
              [106.2036665410001, 21.657958458000067],
              [106.20161357700005, 21.655804444000104],
              [106.19893705000001, 21.65383966300007],
              [106.19336784200001, 21.650879766000038],
              [106.19151328500004, 21.649698807000028],
              [106.18987145600008, 21.647936627000064],
              [106.18926779700006, 21.645988578000086],
              [106.18932463200005, 21.64532442700002],
              [106.18950043000002, 21.643270095000041],
              [106.19015845100003, 21.639389173000012],
              [106.19277036000004, 21.626002549000042],
              [106.19403008600008, 21.624263921000072],
              [106.19549703100006, 21.622526956000065],
              [106.19551870600007, 21.620195284000054],
              [106.1942879420001, 21.618825020000095],
              [106.19018854100003, 21.613933559000117],
              [106.18322095200004, 21.605520647],
              [106.18304070100005, 21.605302993000031],
              [106.179046881, 21.607171117000057],
              [106.17362134200008, 21.609911377000032],
              [106.17089778100005, 21.612420612000079],
              [106.16924689100006, 21.615698135000017],
              [106.16542412100004, 21.620223499000076],
              [106.15996682900006, 21.626254179000078],
              [106.15752967800002, 21.626993436000049],
              [106.14890192400007, 21.62565588300005],
              [106.14189423000005, 21.624331546000072],
              [106.13920403200004, 21.623296368000041],
              [106.13759381400004, 21.622270226000047],
              [106.13411073900006, 21.619456207],
              [106.1311579800001, 21.617659232],
              [106.12738556900007, 21.616867951000081],
              [106.12279590400001, 21.616829189000072],
              [106.11657899600006, 21.617536026000025],
              [106.1087174890001, 21.620760301000082],
              [106.10140312500002, 21.623229485000103],
              [106.09815333600007, 21.624214309000052],
              [106.09518345000001, 21.624188848000081],
              [106.09087107500007, 21.623392323000076],
              [106.08494631500004, 21.621822332000114],
              [106.07713926800005, 21.619476392000081],
              [106.07553192100002, 21.618196648000065],
              [106.07232482100005, 21.614877693000075],
              [106.070432657, 21.612707958000051],
              [106.06832051600003, 21.610285891000025],
              [106.06592609300006, 21.6067207],
              [106.06431640100001, 21.605693987000052],
              [106.06189178000004, 21.605166493000105],
              [106.05704004500006, 21.604364577000098],
              [106.05542788300008, 21.603590947000036],
              [106.05409076700002, 21.602313375000023],
              [106.05358130100005, 21.599270846000081],
              [106.05044352700006, 21.589116324000109],
              [106.04833999400009, 21.583527906000107],
              [106.04728063, 21.58149315100006],
              [106.04433434300003, 21.579188579000075],
              [106.03816184000001, 21.575589545000092],
              [106.03444889400004, 21.573921623000025],
              [106.03290874200005, 21.57318219600004],
              [106.03124121500011, 21.571377026000071],
              [106.0316478930001, 21.570266825000047],
              [106.03166336600003, 21.568740357000046],
              [106.03168372600004, 21.566731837000042],
              [106.03160703, 21.565847327000043],
              [106.0312701170001, 21.565281899000063],
              [106.02939797800009, 21.564060058000045],
              [106.02906108300002, 21.563494625000068],
              [106.0295847730001, 21.562535094000076],
              [106.03131165700007, 21.561184487000062],
              [106.03225789700011, 21.560791138000113],
              [106.032856635, 21.560876793000091],
              [106.03353778000005, 21.56128457100008],
              [106.034474248, 21.561855310000055],
              [106.03601272600011, 21.562190326000064],
              [106.03970224100001, 21.561580159000115],
              [106.04270813900004, 21.560803209000042],
              [106.04537464200004, 21.559701824000044],
              [106.04658263900008, 21.55882862],
              [106.04736398900006, 21.557790952000033],
              [106.04815906700003, 21.555387462000027],
              [106.04920218400011, 21.553869990000059],
              [106.05135709400005, 21.552522970000076],
              [106.05364712100008, 21.551908268000105],
              [106.05247221800003, 21.550431877000072],
              [106.05147012500008, 21.548937001000112],
              [106.05005503900006, 21.54744426300006],
              [106.04947077300002, 21.546698973000034],
              [106.04937540800005, 21.546374313000079],
              [106.049379125, 21.546004267000015],
              [106.04993080400008, 21.54508391500007],
              [106.05058247800008, 21.544025669000085],
              [106.05159740300005, 21.541166461000039],
              [106.0518569270001, 21.53987344800008],
              [106.05186203000008, 21.539364631000076],
              [106.05142709000008, 21.538481865000044],
              [106.05070282400001, 21.536948902000042],
              [106.04987901900006, 21.535507590000066],
              [106.04934131300007, 21.535040254000037],
              [106.048802671, 21.534665432000018],
              [106.04816402500009, 21.534428509000044],
              [106.04786866500008, 21.534379653000087],
              [106.04737654800005, 21.534282799000067],
              [106.04708210900002, 21.534141427000094],
              [106.04688721300005, 21.533908406000087],
              [106.04519739900003, 21.530331437000072],
              [106.04395090200011, 21.526804590000026],
              [106.04362162100004, 21.525228794000029],
              [106.04314536400007, 21.523559180000085],
              [106.04252774900004, 21.521240640000094],
              [106.04200128200009, 21.51966308500004],
              [106.04161154000008, 21.519197032000115],
              [106.04085908300009, 21.518621706000118],
              [106.04014463900003, 21.517981260000063],
              [106.03985369400003, 21.517703070000046],
              [106.03970513200008, 21.51756102100002],
              [106.03961166400008, 21.517051303000031],
              [106.03961726600001, 21.51649620900011],
              [106.03976984300002, 21.516034932000046],
              [106.04016844100005, 21.515622096000087],
              [106.04086097700007, 21.515396900000013],
              [106.04224557800005, 21.514992765000059],
              [106.04298880300009, 21.514629223],
              [106.04328880100003, 21.514215502000056],
              [106.04334229000003, 21.513799615000018],
              [106.04356836800008, 21.510933328000093],
              [106.04358700300001, 21.509082979000112],
              [106.04345355300008, 21.507647647000013],
              [106.04321498500005, 21.506859074000047],
              [106.04253553400005, 21.505789024000066],
              [106.04200584500002, 21.504535243000063],
              [106.04171892600009, 21.503653708000044],
              [106.04153759000009, 21.502079148000064],
              [106.04150368700004, 21.500552155000015],
              [106.04136185400003, 21.499949474000069],
              [106.04116980700003, 21.499438878000099],
              [106.04019648000006, 21.498181160000037],
              [106.03749911400007, 21.494340541000057],
              [106.03662302500007, 21.493222447000093],
              [106.036335679, 21.492387144000077],
              [106.03634409500008, 21.491554458000017],
              [106.03694403700007, 21.490727016000029],
              [106.03874476400003, 21.48815213000006],
              [106.04033428300009, 21.486963295000102],
              [106.04291335800005, 21.485413061000088],
              [106.04529616000004, 21.483768531000088],
              [106.046391747, 21.482667822000053],
              [106.04729574100001, 21.481010241000043],
              [106.04730596900006, 21.479992497000033],
              [106.04623922500008, 21.478225010000074],
              [106.04506835100001, 21.477011787000052],
              [106.04301679100008, 21.475143067000069],
              [106.041840361, 21.474484965000059],
              [106.04085456100006, 21.474476259000049],
              [106.03986783800005, 21.474560065000055],
              [106.03937867700007, 21.474185615000074],
              [106.03918711700004, 21.473628735000091],
              [106.03909974200009, 21.472517581000083],
              [106.03911281400011, 21.471222243000021],
              [106.039622503, 21.469561173000073],
              [106.04183222600004, 21.465509253000015],
              [106.04263013800008, 21.464590961000063],
              [106.04305356100011, 21.464292927000095],
              [106.04431801500002, 21.463402917000082],
              [106.04620301200002, 21.462216594000068],
              [106.04728615200003, 21.461863745000095],
              [106.04818938000005, 21.461946370000092],
              [106.0487824260001, 21.461982527000067],
              [106.04922821500003, 21.461909089000031],
              [106.04938354700001, 21.46121420700009],
              [106.04929366300003, 21.460316024000022],
              [106.04911172099999, 21.458736243000054],
              [106.04898793700005, 21.457930593000093],
              [106.04883272200009, 21.457276377000056],
              [106.04920568800003, 21.456417998000084],
              [106.049488657, 21.455784333000061],
              [106.0496080020001, 21.454312868000081],
              [106.04878624000006, 21.452833128000037],
              [106.04620661700005, 21.449374524000042],
              [106.04561806900003, 21.447830140000065],
              [106.0454934150001, 21.447503035000089],
              [106.04468653700005, 21.444550852000077],
              [106.04379574600006, 21.442496848000033],
              [106.04285560600007, 21.442332635000035],
              [106.04185523500009, 21.442154934000079],
              [106.04095813900003, 21.441929909000024],
              [106.03998613700006, 21.441487098000067],
              [106.03922091800007, 21.440925502000049],
              [106.03765401700011, 21.439596925000082],
              [106.03693946900005, 21.439108135000048],
              [106.03622004100004, 21.439101765000053],
              [106.03425952100008, 21.439856336000048],
              [106.03333259600004, 21.440041102000059],
              [106.03251038900004, 21.440033807000077],
              [106.03185378100008, 21.439780448000079],
              [106.03097463500008, 21.439441217000073],
              [106.02995274700008, 21.438853182000095],
              [106.02720526700003, 21.436126941000033],
              [106.02527512200002, 21.433890394000073],
              [106.02477107400007, 21.432920964000068],
              [106.02447256500005, 21.431953363000034],
              [106.02447943000001, 21.431277964000017],
              [106.02520666100006, 21.430512487],
              [106.02779843000009, 21.428316179000092],
              [106.02894160699999, 21.427071911000084],
              [106.02988022000002, 21.42572932100002],
              [106.03062109600005, 21.42361300200006],
              [106.03094992300007, 21.421589510000061],
              [106.03098216800008, 21.418405419000038],
              [106.03120137900001, 21.417056413000068],
              [106.03215652400007, 21.414073476000084],
              [106.03290416500003, 21.411281682000016],
              [106.033119449, 21.41031861400009],
              [106.03312335400005, 21.409932657000041],
              [106.0327172070001, 21.409446563000053],
              [106.03118168400006, 21.408853941000046],
              [106.030158989, 21.408362365000052],
              [106.02924100399999, 21.407678717000067],
              [106.02843851500002, 21.405741610000106],
              [106.02622880800007, 21.40070398800011],
              [106.0248196580001, 21.39779643100006],
              [106.02390763600003, 21.396533799000039],
              [106.02285178500007, 21.395819148000058],
              [106.0207297960001, 21.395871203000105],
              [106.01870739700009, 21.396746867000083],
              [106.01816846700008, 21.397635780000087],
              [106.01815429600006, 21.39902590000009],
              [106.01792212200004, 21.401009882000018],
              [106.01684423500004, 21.40278767900012],
              [106.01609436100007, 21.403575393000018],
              [106.01439280300005, 21.404155969],
              [106.01311916000002, 21.404343160000039],
              [106.01121173900002, 21.404326056000045],
              [106.00982908500002, 21.404810159000043],
              [106.00865631500005, 21.405494734000015],
              [106.0065206670001, 21.407064370000036],
              [106.00557179600003, 21.407250399000063],
              [106.00079833400012, 21.407012836000035],
              [105.99836513900006, 21.406593674000085],
              [105.99540519000003, 21.405871812000044],
              [105.9936037210001, 21.405855515000027],
              [105.99232800600006, 21.406241170000051],
              [105.99136198000006, 21.407424044000045],
              [105.98931877900003, 21.410285264],
              [105.98898957200007, 21.411374589000062],
              [105.98897108300004, 21.41316182900006],
              [105.98854205200007, 21.413654442000052],
              [105.98620345600001, 21.414328316000116],
              [105.9854626740001, 21.41422228100005],
              [105.98514887700007, 21.413822229000075],
              [105.98431448100004, 21.412523736000111],
              [105.98368588500009, 21.412051624000036],
              [105.98291418100008, 21.412204950000024],
              [105.98055976000006, 21.413026769000076],
              [105.97694011500006, 21.414636021],
              [105.97644948200005, 21.414996493000068],
              [105.97496235800003, 21.417537522000082],
              [105.97377111200005, 21.419716295000057],
              [105.97298645500005, 21.420256538000082],
              [105.97191252700001, 21.420520428000067],
              [105.97083955800005, 21.42069308800005],
              [105.96995464400001, 21.421506104000088],
              [105.96657439100002, 21.428135264000048],
              [105.96408462300008, 21.433403984000066],
              [105.96279004500008, 21.436129081000033],
              [105.96150691200005, 21.437759486000019],
              [105.9611135690001, 21.438120795000067],
              [105.95866242300006, 21.437648565000075],
              [105.95879364000008, 21.435410334000068],
              [105.95836384500005, 21.433139897000061],
              [105.95650549500003, 21.422923507000036],
              [105.95459367500003, 21.417806163000108],
              [105.95298283900007, 21.412833207000048],
              [105.95106225400005, 21.408565633000087],
              [105.94957437400004, 21.40628528700006],
              [105.9480775480001, 21.404854810000096],
              [105.94397096700004, 21.402817124000073],
              [105.93919569500008, 21.401230842000096],
              [105.93841763500011, 21.399872552000062],
              [105.93823858200003, 21.398800331000075],
              [105.93863606900005, 21.396975717000096],
              [105.94001760600005, 21.395005253000043],
              [105.94201878800008, 21.39162387600004],
              [105.94249777100001, 21.389219994000094],
              [105.94153860600005, 21.379861025000046],
              [105.94041252900004, 21.376499927000033],
              [105.93791562300004, 21.373005925000044],
              [105.93616929100006, 21.372258983000059],
              [105.93480501900007, 21.372246274000069],
              [105.91566009600007, 21.376268522000075],
              [105.91097463200001, 21.376955091000035],
              [105.91009061000004, 21.376946756000059],
              [105.90902560900003, 21.376936711000027],
              [105.90786014000005, 21.37656036700006],
              [105.90651156300002, 21.375086255000042],
              [105.90327134100008, 21.366988540000072],
              [105.90118252800008, 21.363162],
              [105.89946011300003, 21.360222852],
              [105.89734600800008, 21.357462629000068],
              [105.8940626880001, 21.354691264000088],
              [105.89404835800008, 21.354426239000098],
              [105.89380640600004, 21.354043207000117],
              [105.89320466900008, 21.352521580000072],
              [105.89416678300002, 21.351490823000049],
              [105.89483035600003, 21.351210510000037],
              [105.89580621900004, 21.350976025000087],
              [105.8978132820001, 21.351376897000037],
              [105.90301889500006, 21.353654562000109],
              [105.90507907600008, 21.353674048000038],
              [105.90587625700005, 21.353235917000021],
              [105.90651815500004, 21.352499200000082],
              [105.90652934800008, 21.351459413000029],
              [105.90434272900008, 21.348467606000021],
              [105.90151588800003, 21.346063932000092],
              [105.89772867100004, 21.344542447000059],
              [105.89424559600006, 21.344212261000095],
              [105.89186696500008, 21.344338204000032],
              [105.88772098100004, 21.346675642000079],
              [105.88644514800009, 21.347406267000046],
              [105.88517899800004, 21.347245626000074],
              [105.88490846200004, 21.347060569000043],
              [105.88407778700005, 21.346492331],
              [105.87970379800008, 21.34065673200007],
              [105.87908451600001, 21.339313765000064],
              [105.87893738900009, 21.338272439000058],
              [105.88039108300011, 21.335760821000079],
              [105.88359262600005, 21.332820202000043],
              [105.88502880900005, 21.331598517000124],
              [105.88724999400006, 21.33006969100002],
              [105.89195379700006, 21.328306137000119],
              [105.89502475200004, 21.327552115000081],
              [105.89260373300002, 21.327049856000087],
              [105.89176736100009, 21.326867253000081],
              [105.88985024600001, 21.326402539000085],
              [105.88774701100007, 21.326399504000015],
              [105.88458550100005, 21.326794571000015],
              [105.88087422700002, 21.327296520000068],
              [105.87588608000004, 21.327832596000107],
              [105.86677916800005, 21.32874005200005],
              [105.86601804800006, 21.329016997000046],
              [105.86503052900005, 21.329332588000049],
              [105.86311577300009, 21.33097892500011],
              [105.85665921100011, 21.33901823500004],
              [105.85527279200005, 21.340994678000058],
              [105.85326107300006, 21.344670653],
              [105.85129887800005, 21.348916258000102],
              [105.85112927300005, 21.34928322600009],
              [105.85063088100003, 21.351171092000065],
              [105.84982444700009, 21.357446675000077],
              [105.85035541800006, 21.360215178000082],
              [105.85102702300003, 21.362197006000102],
              [105.85227715000008, 21.364181445000071],
              [105.85464479900006, 21.365915010000066],
              [105.85636359900008, 21.366565853000097],
              [105.85696010900004, 21.367172341000092],
              [105.85750846600003, 21.367813883000061],
              [105.85761914000004, 21.368971733000116],
              [105.85744028100007, 21.374002005000037],
              [105.85693321900004, 21.375211714000031],
              [105.85598255800008, 21.376596807000055],
              [105.85462507500002, 21.377445095000077],
              [105.85363770200004, 21.377435535000089],
              [105.85253074300002, 21.377077781000061],
              [105.84933588600009, 21.375774350000043],
              [105.84748714500006, 21.375525047000018],
              [105.84691726900007, 21.375761927000077],
              [105.84636804700001, 21.37626607400005],
              [105.84514650600002, 21.378317471000052],
              [105.84502102400003, 21.378631111000068],
              [105.83651307200005, 21.378158385000035],
              [105.83534112300006, 21.377594833000032],
              [105.83360267100002, 21.375001332000032],
              [105.83066927600005, 21.373933306000126],
              [105.82965304500009, 21.375121054000076],
              [105.82191936600003, 21.379903522000099],
              [105.81728544700005, 21.382197120000065],
              [105.81304146000009, 21.383954651000089],
              [105.81111548900007, 21.384475448000018],
              [105.80092164500005, 21.385980300000092],
              [105.79880485900006, 21.385984408000077],
              [105.79832718200005, 21.385974320000045],
              [105.79250161000006, 21.38163621400011],
              [105.78890976900004, 21.378396912000049],
              [105.78673514200007, 21.37484697000005],
              [105.78502755900008, 21.371095489000069],
              [105.78446083600009, 21.368994467000043],
              [105.78408814300008, 21.366483299000016],
              [105.78313460500004, 21.360819527000082],
              [105.78297838800009, 21.360337701],
              [105.78217998600012, 21.36066134600005],
              [105.78115580000011, 21.360938197000046],
              [105.77778350600005, 21.361191509000044],
              [105.77502375700007, 21.361450921000099],
              [105.77430802100008, 21.361539429000054],
              [105.7735846140001, 21.362297767000094],
              [105.77295342800009, 21.363918327000064],
              [105.77241884900009, 21.36601835],
              [105.77240679000008, 21.36707092900005],
              [105.77249354400006, 21.368411589000047],
              [105.77238267400003, 21.369176071000062],
              [105.77165484500003, 21.370317141000058],
              [105.76772575800001, 21.374584040000052],
              [105.76638199500012, 21.37600597500002],
              [105.76494490700004, 21.376661371000068],
              [105.76145920700003, 21.377870266000087],
              [105.75838186000004, 21.379083234000085],
              [105.75786363300007, 21.379747873],
              [105.75795800700007, 21.380418708],
              [105.75805570000006, 21.380802491000047],
              [105.75885706800003, 21.382150353000014],
              [105.76325270700001, 21.390616036000054],
              [105.76346135400004, 21.391729116000043],
              [105.76316473300001, 21.393655075000062],
              [105.76243277700011, 21.397634163000042],
              [105.76157542700005, 21.400583202000064],
              [105.76056222600006, 21.402185698000054],
              [105.75361654900003, 21.411591550000075],
              [105.75102197600003, 21.414234544000088],
              [105.74714544500009, 21.416864482000086],
              [105.74586046300008, 21.417118340000037],
              [105.74358156300009, 21.417095157000077],
              [105.74002853300001, 21.416391646000044],
              [105.73804381500001, 21.415570615000057],
              [105.736348638, 21.414352130000047],
              [105.73465503700002, 21.413000185],
              [105.731828267, 21.411102758000041],
              [105.72927393500004, 21.410275811000098],
              [105.72770723700008, 21.410259758000016],
              [105.72595539600007, 21.411080610000077],
              [105.72436500200003, 21.412272805000043],
              [105.7232547720001, 21.413105041000065],
              [105.7195727380001, 21.415803378000042],
              [105.71932227200006, 21.416408847000042],
              [105.71939536100007, 21.417093644000126],
              [105.7195513410001, 21.417627284000048],
              [105.72082595600004, 21.419616511000058],
              [105.72193035800002, 21.422288014000031],
              [105.72285475700004, 21.426477723000033],
              [105.72314806500006, 21.429140854000117],
              [105.72269350400002, 21.433316347000094],
              [105.72244125300003, 21.434073787000059],
              [105.72195186800012, 21.434296770000039],
              [105.72165006200001, 21.434334701000068],
              [105.72040110000006, 21.433969647000062],
              [105.71840444500006, 21.433244677000026],
              [105.71504094400002, 21.431748130000102],
              [105.71354229800002, 21.431081287],
              [105.71203862700004, 21.431065775000043],
              [105.71107123200002, 21.431530711000057],
              [105.71078002500008, 21.43152240100008],
              [105.70951451000009, 21.432565991],
              [105.7063458100001, 21.435585777000107],
              [105.70481715100001, 21.437683244000105],
              [105.70328291000007, 21.440250264000049],
              [105.70252548300002, 21.44071202700006],
              [105.70189752900002, 21.440822926000088],
              [105.69976721600005, 21.440800836000037],
              [105.69601341400006, 21.440292243000059],
              [105.69438156600005, 21.440510094000054],
              [105.68997889500011, 21.441873116000032],
              [105.68649603100005, 21.443248481000055],
              [105.68574377400007, 21.443764826000056],
              [105.68483614500012, 21.444387810000087],
              [105.68344140000009, 21.446300993000108],
              [105.68107103700007, 21.449489120000109],
              [105.67646111900004, 21.456380673000105],
              [105.67438180500002, 21.458158072000089],
              [105.672450428, 21.459037405000103],
              [105.66667004800011, 21.460518820000019],
              [105.66433321500003, 21.46087976000004],
              [105.66241258500001, 21.46085952200005],
              [105.65924682200009, 21.460421184000019],
              [105.65555605100006, 21.4597522810001],
              [105.65030519600002, 21.458331891000078],
              [105.64439480600002, 21.465093221000053],
              [105.64359374200009, 21.466449509000093],
              [105.6433492190001, 21.468126664000039],
              [105.64288436500001, 21.469486515000114],
              [105.64174448300011, 21.471049149000088],
              [105.64061095400002, 21.472086922000067],
              [105.637672681, 21.474050343000073],
              [105.63428088900012, 21.476428787000046],
              [105.63077310500006, 21.479120883000036],
              [105.62963947100005, 21.480158594],
              [105.62940250500007, 21.481205874000061],
              [105.62938713600005, 21.48246549000001],
              [105.63044012500004, 21.48804073000008],
              [105.63108321200005, 21.490462138000055],
              [105.63294653100012, 21.493946357000127],
              [105.63204084600005, 21.494671544000084],
              [105.63045876400007, 21.495704443000108],
              [105.62955818500006, 21.496009753000052],
              [105.62742202300007, 21.49651181000003],
              [105.62472410200004, 21.497112809000015],
              [105.62257892400001, 21.498349572000031],
              [105.61398761500006, 21.504136086000081],
              [105.61003323400007, 21.506612963000023],
              [105.60957709600008, 21.507237910000043],
              [105.60944561600004, 21.50881115],
              [105.60963494500007, 21.511647549000124],
              [105.60949959700007, 21.513535649000026],
              [105.60881148100006, 21.51478794100008],
              [105.60767622900002, 21.515930433000015],
              [105.60563248500003, 21.518007884000042],
              [105.60505643000005, 21.519261364000016],
              [105.60504090000011, 21.520520883000046],
              [105.60524182300003, 21.522412590000073],
              [105.60521464800009, 21.524616744000099],
              [105.60452258100003, 21.526183871000093],
              [105.60155719400007, 21.530245795],
              [105.59929031200009, 21.532215754000013],
              [105.59725929100009, 21.533243484000053],
              [105.59467157700003, 21.533950248000089],
              [105.59104653200004, 21.534487822000067],
              [105.58499568700005, 21.53527037000007],
              [105.58317128200008, 21.536240204000109],
              [105.58209838200004, 21.53750099600007],
              [105.58162246800001, 21.539333835000015],
              [105.58084627700001, 21.541022002000076],
              [105.57900946200009, 21.542981364000099],
              [105.57350960200003, 21.547697097000103],
              [105.56861663300002, 21.552764592000081],
              [105.56591222600008, 21.555286786000046],
              [105.56408348100003, 21.557087397000082],
              [105.56224816400005, 21.559408097000073],
              [105.55961418800007, 21.563573414000103],
              [105.55705142000004, 21.568486952000043],
              [105.55487207900011, 21.571421213000086],
              [105.55234842600002, 21.573214019000098],
              [105.55025464000006, 21.573971184000037],
              [105.54747275800001, 21.574200547000096],
              [105.54357942200002, 21.574417568000023],
              [105.54019975600008, 21.575002261000087],
              [105.54023236600003, 21.575272429000059],
              [105.54010725000006, 21.57582383900003],
              [105.53651470199999, 21.579653551],
              [105.53387960400002, 21.58249880700005],
              [105.53268998800009, 21.583148931000018],
              [105.53209797800004, 21.583252905000094],
              [105.529500754, 21.583113454000014],
              [105.52618917600009, 21.583408245000065],
              [105.52464798000003, 21.583833285000061],
              [105.52416986300007, 21.584270184000061],
              [105.52368327600004, 21.58537033100005],
              [105.5230687000001, 21.587242952000096],
              [105.52209692200006, 21.58933268600007],
              [105.52029118600005, 21.591965874000074],
              [105.51307341700006, 21.602056205000061],
              [105.50791573400008, 21.608078803000105],
              [105.50430916500001, 21.612902594000083],
              [105.50311498900005, 21.613884133000028],
              [105.50180835300003, 21.614422192000049],
              [105.49931605500007, 21.615278547000024],
              [105.49762017600007, 21.616207924],
              [105.49522279900005, 21.619331377000073],
              [105.49520789400006, 21.620486394000068],
              [105.49552425400003, 21.622065198000051],
              [105.49702911300002, 21.627227911000091],
              [105.49800129500008, 21.630179271000124],
              [105.49832447000009, 21.631233060000099],
              [105.49807833800006, 21.632910505000069],
              [105.4973724380001, 21.635422871000017],
              [105.49632574400009, 21.638246415000047],
              [105.49529256200005, 21.640019972000069],
              [105.49461099800004, 21.64064235600005],
              [105.49269071900005, 21.641565784000072],
              [105.49031331499999, 21.643114117000017],
              [105.48906779500005, 21.64394012200006],
              [105.48755429400006, 21.648123446000064],
              [105.48429714700004, 21.656907402000058],
              [105.48138190700006, 21.665275013000063],
              [105.48020484100002, 21.669461940000097],
              [105.47801057000001, 21.674162307000074],
              [105.47788466500006, 21.675210930000063],
              [105.47787238200003, 21.676155844000085],
              [105.47841184800008, 21.677842050000073],
              [105.47928948000003, 21.679427081000121],
              [105.48006712799999, 21.680065934000048],
              [105.48095976800003, 21.680496075000022],
              [105.48409222900005, 21.681371620000078],
              [105.48475763700003, 21.682009182000073],
              [105.48512110200009, 21.682778777000067],
              [105.48558636600004, 21.684422161000057],
              [105.4863117040001, 21.688525630000029],
              [105.48589537700012, 21.690614036000078],
              [105.48520128800007, 21.69160723000001],
              [105.48284005300009, 21.693582589000115],
              [105.47726061000003, 21.696067414000098],
              [105.47588768700004, 21.696870856000047],
              [105.47568597200004, 21.697414579000096],
              [105.47567650000006, 21.698142508000018],
              [105.47629602600007, 21.698794150000111],
              [105.47809736199999, 21.700347575000123],
              [105.48251969500005, 21.703463649000106],
              [105.48387794300008, 21.704075180000082],
              [105.48615127200006, 21.704356417000078],
              [105.48742177700002, 21.704711443000058],
              [105.48823475100005, 21.705231615000017],
              [105.48868346700006, 21.705747674000087],
              [105.48912447100004, 21.706859783000084],
              [105.48982546200004, 21.708996783000067],
              [105.49193292300006, 21.715067125000061],
              [105.4924529240001, 21.717116879000017],
              [105.49277144200008, 21.718023504000072],
              [105.49404181400003, 21.719791037000036],
              [105.49785775800009, 21.724730897000107],
              [105.50199951100004, 21.729492938000035],
              [105.50301843500006, 21.73071346600004],
              [105.50404591700007, 21.731269098],
              [105.50643040100002, 21.731900408000016],
              [105.50766307100005, 21.732392922000088],
              [105.50974666500005, 21.733877968000037],
              [105.51225380100001, 21.735972569000062],
              [105.51342878000005, 21.736842568000014],
              [105.5139016840001, 21.737805513000104],
              [105.51425966200006, 21.739321585000049],
              [105.51440524000003, 21.740583278000081],
              [105.51418314600004, 21.741084816000097],
              [105.51347263100004, 21.741832895000087],
              [105.511507765, 21.743168869000051],
              [105.51016052200001, 21.744045836000048],
              [105.50938002300009, 21.745375163000077],
              [105.50849607700005, 21.74732776900008],
              [105.50779837100005, 21.749639268000109],
              [105.50759378300009, 21.750707425000115],
              [105.50768117600003, 21.751332837000078],
              [105.50795940400005, 21.751960387000047],
              [105.51060019600006, 21.754398496000071],
              [105.51230287500007, 21.755577211000073],
              [105.51458152300005, 21.756494748000051],
              [105.51581513200007, 21.757043762000094],
              [105.5167590610001, 21.757857135000066],
              [105.51797784500005, 21.759565607000027],
              [105.52013377, 21.762622566000097],
              [105.52257375699999, 21.765861058],
              [105.52359951400004, 21.767745717000061],
              [105.52368696600008, 21.768371102000025],
              [105.52367671500004, 21.769173802000097],
              [105.52289962600004, 21.770235554000102],
              [105.52087969700007, 21.771461844000051],
              [105.51857573300003, 21.772506538000066],
              [105.5175460020001, 21.773149481000061],
              [105.51473199900006, 21.77510397600004],
              [105.51406487100012, 21.775344762000067],
              [105.51313533900003, 21.775334363000098],
              [105.51115145500002, 21.774691550000021],
              [105.50639338400006, 21.772900505000067],
              [105.50176498800009, 21.771359059000069],
              [105.49990597500002, 21.771338153000031],
              [105.49789975500002, 21.772432673000083],
              [105.49505349800006, 21.776868989000064],
              [105.49329513200007, 21.779331590000069],
              [105.49326946000008, 21.781317225000052],
              [105.493645374, 21.783059147000117],
              [105.49476196200001, 21.789153571000064],
              [105.49526269600005, 21.791517457000076],
              [105.49563544100008, 21.793507545000068],
              [105.49562422000004, 21.79437623200009],
              [105.49520978500003, 21.795612735000077],
              [105.49438022600005, 21.797028156000039],
              [105.49329051100004, 21.799125850000053],
              [105.49176766500007, 21.805006724000094],
              [105.49161077100005, 21.806866671000066],
              [105.49236430000005, 21.810226243000038],
              [105.49283390300009, 21.812780202000056],
              [105.49286508100001, 21.818429856000066],
              [105.49225571100004, 21.825241018000071],
              [105.49222922200006, 21.827288307000089],
              [105.49221791000011, 21.828162583000044],
              [105.49271517500004, 21.830018777000056],
              [105.49342722900008, 21.831390386000038],
              [105.49414810700003, 21.832080301000097],
              [105.49643137100003, 21.832885210000086],
              [105.50361158100003, 21.833939930000042],
              [105.50603344300004, 21.834110408000029],
              [105.50694675100002, 21.834043166000058],
              [105.50836066600003, 21.833749050000058],
              [105.50961467100005, 21.832988189000076],
              [105.51045996100001, 21.831757799000059],
              [105.51299377800004, 21.828221541000033],
              [105.51358028500007, 21.827763152000031],
              [105.51424375400008, 21.827770571000116],
              [105.51498719100005, 21.828011356000054],
              [105.51580561200008, 21.828872914000016],
              [105.51810099600002, 21.830990821000015],
              [105.51941606700002, 21.831935388000097],
              [105.52427268400005, 21.834856687000048],
              [105.5254229290001, 21.835721891000048],
              [105.5260745680001, 21.836659026000049],
              [105.52623154000004, 21.837358192000075],
              [105.52629173700004, 21.839141136000073],
              [105.52619198200006, 21.840457357000084],
              [105.5264180560001, 21.842242135000056],
              [105.52678628400011, 21.845888232000021],
              [105.526996561, 21.848912633000062],
              [105.52655835700008, 21.852334250000105],
              [105.52611152300005, 21.854666662000085],
              [105.52451948300005, 21.859583384000111],
              [105.52066337200003, 21.867591254000018],
              [105.5173613370001, 21.87573496400006],
              [105.516924321, 21.877288272000058],
              [105.51719592800001, 21.880505045000028],
              [105.51818702000003, 21.88454137500004],
              [105.51866361600003, 21.885325773000105],
              [105.5203111280001, 21.886967227000028],
              [105.52251724600002, 21.88842011800007],
              [105.52521663500008, 21.889359089000045],
              [105.53346966600003, 21.890943984000089],
              [105.53478795300003, 21.891153367000094],
              [105.53693739800006, 21.891354361000111],
              [105.53886204800006, 21.891489711000034],
              [105.54089608400005, 21.891935128000028],
              [105.54197715700005, 21.892721817000016],
              [105.54288031599999, 21.893643034000029],
              [105.54383436100007, 21.895362152000075],
              [105.54465323900006, 21.897193667000089],
              [105.54464241300005, 21.898047838000068],
              [105.54457371700005, 21.899584788000048],
              [105.54330206200008, 21.903671280000125],
              [105.54323096300006, 21.904467816000036],
              [105.54334426500002, 21.905152480000112],
              [105.54400571700003, 21.905900154000065],
              [105.54491255500001, 21.906536627000058],
              [105.54660716400008, 21.907580452],
              [105.54964277100007, 21.908695977000022],
              [105.550371001, 21.908988757000088],
              [105.55091268200006, 21.909564232],
              [105.55120753600004, 21.910364789000077],
              [105.55191130600009, 21.912593610000016],
              [105.55212692300007, 21.913882825000108],
              [105.55209852700004, 21.916129868000041],
              [105.551884493, 21.918957507000059],
              [105.55151320900011, 21.920118706000032],
              [105.54988975100007, 21.921599059000037],
              [105.54934333100007, 21.92250861900007],
              [105.54906116800008, 21.923670788000038],
              [105.54881337000009, 21.929161483000122],
              [105.54894636700008, 21.932741958000079],
              [105.54959386700001, 21.940729603000086],
              [105.54954179400005, 21.944845022000067],
              [105.54917659500003, 21.945828829000021],
              [105.54801364900001, 21.947133128000118],
              [105.54756547000005, 21.947704420000044],
              [105.54755608900007, 21.948445185000054],
              [105.54781019400005, 21.949271179],
              [105.54959229400006, 21.953098429000036],
              [105.55124978700007, 21.955613282000016],
              [105.55377108600004, 21.958964590000015],
              [105.55516060700009, 21.958154707000062],
              [105.55865668800007, 21.957144284000037],
              [105.56056927900005, 21.956955503000053],
              [105.56211132200009, 21.956979164000053],
              [105.57201186600001, 21.958444082000113],
              [105.5727943750001, 21.958767251000033],
              [105.57290278800011, 21.959083075000052],
              [105.57289753100011, 21.959502536000031],
              [105.57091074000003, 21.96505519],
              [105.57077032500005, 21.966911950000068],
              [105.57076484000004, 21.967349138000088],
              [105.57134625100009, 21.967683420000036],
              [105.57216599100006, 21.967692364000065],
              [105.57334115100005, 21.967377245000108],
              [105.57452452100009, 21.966406345000067],
              [105.57571061699998, 21.965216842000054],
              [105.57665429100008, 21.964680566000091],
              [105.57735690300004, 21.964688216000106],
              [105.5778212220001, 21.96502119900012],
              [105.57850744300002, 21.966340406000114],
              [105.57847737300003, 21.968744916000027],
              [105.578111022, 21.969943348000065],
              [105.57665922200005, 21.973644086000022],
              [105.57605178700005, 21.975386422000028],
              [105.57591688700009, 21.976805976000065],
              [105.57636892000001, 21.978122599000116],
              [105.57717912000007, 21.978896579000029],
              [105.57996249200006, 21.981113030000095],
              [105.58007550900001, 21.981442186000066],
              [105.58006594600002, 21.982207235000125],
              [105.57958929500009, 21.982857896000048],
              [105.57852569000002, 21.98361147800005],
              [105.57781342800007, 21.984368879000016],
              [105.57768673900006, 21.985132649000057],
              [105.57778881600007, 21.98633614000007],
              [105.57984500100011, 21.990512157000097],
              [105.58086765900001, 21.993037303000037],
              [105.5828095860001, 21.996993383000074],
              [105.58360625900011, 21.998860207000078],
              [105.58398177400009, 22.000000162000077],
              [105.5839560540001, 22.001927689000041],
              [105.58422515500007, 22.003454154000046],
              [105.58563891200006, 22.005620373000042],
              [105.58839503200002, 22.007980361000087],
              [105.5910741380001, 22.008815947000038],
              [105.59633303400001, 22.01075478800005],
              [105.59757379700008, 22.011395514000043],
              [105.59861470800006, 22.012661409000067],
              [105.59936971700009, 22.013744974000105],
              [105.59984549100002, 22.014108574000076],
              [105.60032459000004, 22.014203358000103],
              [105.60099690000007, 22.014210604000084],
              [105.60571967800004, 22.012917168000087],
              [105.60812408400002, 22.012674163000057],
              [105.61406882200004, 22.013544516000039],
              [105.61540570100007, 22.014186170000102],
              [105.61663331000003, 22.015902060000059],
              [105.61766664, 22.017795096000015],
              [105.61871102500008, 22.018792060000081],
              [105.61966708100005, 22.019160750000118],
              [105.62859171900004, 22.01988331400004],
              [105.63166302200005, 22.020095245000078],
              [105.63244899500008, 22.020381657000065],
              [105.63388582800012, 22.021240851000101],
              [105.63584474800011, 22.0237934],
              [105.63717057200003, 22.026339200000052],
              [105.63806579800011, 22.027108211],
              [105.63896824500009, 22.0272865680001],
              [105.63978229400003, 22.027295199000015],
              [105.64495335400005, 22.026084099000087],
              [105.64802863300012, 22.02611660700002],
              [105.65055917500007, 22.026312111000031],
              [105.65326038800001, 22.027353283000117],
              [105.65829701900002, 22.029769235000082],
              [105.65963950400007, 22.030964811000061],
              [105.66006123600006, 22.031857627000036],
              [105.66039050000003, 22.036124355000105],
              [105.66083001800007, 22.039148766000061],
              [105.66109737800008, 22.040661459000077],
              [105.66184291600004, 22.042001536000022],
              [105.6659655, 22.04755138900012],
              [105.66700421200005, 22.048272790000034],
              [105.66814134900008, 22.048728783000016],
              [105.66937904600007, 22.048741741000107],
              [105.67042953600004, 22.04848628200002],
              [105.6727209220001, 22.047977358000104],
              [105.68038114400011, 22.044415833000031],
              [105.68870433200007, 22.041127368000076],
              [105.69167895900009, 22.03920425500003],
              [105.69331328200003, 22.037888940000094],
              [105.69457471900004, 22.035599507000114],
              [105.69613547100005, 22.032766734000027],
              [105.698264721, 22.029857754000126],
              [105.70163885000002, 22.026339863000111],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 55, code: "AD01", ten_tinh: "Thanh Hóa" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.82263783000006, 19.306948303000034],
              [105.82083727800003, 19.306412296000097],
              [105.81988402700003, 19.306660787000048],
              [105.81822049800006, 19.309740082000104],
              [105.81805759800008, 19.312189373000074],
              [105.81915389300011, 19.314386439000053],
              [105.81913601600004, 19.316002009000051],
              [105.81714220100011, 19.323252931000034],
              [105.81541130300006, 19.325954282000076],
              [105.81364600300002, 19.330631282000077],
              [105.81298575100001, 19.332916199000039],
              [105.81297102800004, 19.334242729000017],
              [105.81334256600005, 19.335090682],
              [105.81497176200003, 19.337036666000081],
              [105.820745716, 19.343124560000113],
              [105.82163320800008, 19.343254001000076],
              [105.82227074700006, 19.343019144],
              [105.82330119500006, 19.341702749000063],
              [105.823854365, 19.337607663000078],
              [105.82377267900009, 19.333506241000094],
              [105.82341575200005, 19.331331772000034],
              [105.82242933500004, 19.328668599000039],
              [105.82127779800007, 19.326012812000037],
              [105.82247048900007, 19.322094253000088],
              [105.82354390800008, 19.319532236000022],
              [105.82500020300004, 19.316773351000016],
              [105.82530876500007, 19.313422566000057],
              [105.82537016000001, 19.309803069000075],
              [105.82448246400001, 19.308319037000032],
              [105.82263783000006, 19.306948303000034],
            ],
          ],
          [
            [
              [105.90302536800006, 19.342339056000043],
              [105.90234895600005, 19.342274103000086],
              [105.90092833700001, 19.342728182000087],
              [105.89969112400004, 19.343300936000048],
              [105.89851419800002, 19.343991195000051],
              [105.89677333700004, 19.345611441000088],
              [105.896150343, 19.346307002000081],
              [105.89608317400004, 19.346832530000043],
              [105.8960172540001, 19.347241138000101],
              [105.89636346800006, 19.349407607000032],
              [105.89691150600001, 19.349968283000059],
              [105.89758951100006, 19.349887103000093],
              [105.89883051200007, 19.34896361600007],
              [105.900634182, 19.347227040000092],
              [105.90350843400006, 19.343220641000066],
              [105.9035743380001, 19.3428120290001],
              [105.90302536800006, 19.342339056000043],
            ],
          ],
          [
            [
              [105.87671699000003, 19.351359649000074],
              [105.87640324200001, 19.351236911000044],
              [105.87570821100006, 19.351409704000041],
              [105.87526069700007, 19.352003818000085],
              [105.87487103000007, 19.353077270000085],
              [105.87486070300011, 19.354034705000075],
              [105.87516542900001, 19.354995209000059],
              [105.875600037, 19.355597890000098],
              [105.8759780940001, 19.355601562000047],
              [105.87654841400004, 19.355307875000079],
              [105.87693162900004, 19.354832819000123],
              [105.87725311900007, 19.354237481000069],
              [105.87738752400001, 19.353460783000017],
              [105.877334827, 19.352502727000065],
              [105.87702944900005, 19.351602067000059],
              [105.87671699000003, 19.351359649000074],
            ],
          ],
          [
            [
              [105.93358941800003, 19.347666271000016],
              [105.93310555400005, 19.347661699000085],
              [105.93223073800006, 19.348021089000063],
              [105.928238865, 19.350281201000108],
              [105.92784500099999, 19.350920869],
              [105.92790697900006, 19.354230403000102],
              [105.92817411800004, 19.356438883000045],
              [105.92874415200005, 19.357455343000126],
              [105.92941580600002, 19.358013190000101],
              [105.92999550700003, 19.358110593000077],
              [105.93086652100007, 19.358118835000049],
              [105.93228299100011, 19.357241297000094],
              [105.93359463400002, 19.356398247000058],
              [105.93418107700005, 19.355852298000094],
              [105.93428652400007, 19.355026065000033],
              [105.93391290800005, 19.353735732000118],
              [105.933732854, 19.352447216000087],
              [105.93365535700006, 19.35060818500007],
              [105.93387010400008, 19.348588076000084],
              [105.933877814, 19.347852830000086],
              [105.93358941800003, 19.347666271000016],
            ],
          ],
          [
            [
              [105.87193189800007, 19.356819005000055],
              [105.87155383900006, 19.356815327000021],
              [105.87034888600002, 19.357521746000117],
              [105.86946027600003, 19.358111558000068],
              [105.86939208600006, 19.358589659000103],
              [105.86963960200003, 19.359010994000059],
              [105.87032818800007, 19.359436624000104],
              [105.87164689800002, 19.359868375000119],
              [105.87221335, 19.359933736000109],
              [105.87259270800004, 19.359817735000014],
              [105.8729746490001, 19.359462377000099],
              [105.873106491, 19.358925044000053],
              [105.87305058300008, 19.358266194000073],
              [105.87280629100003, 19.357545660000049],
              [105.87237103200007, 19.357002810000075],
              [105.87193189800007, 19.356819005000055],
            ],
          ],
          [
            [
              [105.90804386400006, 19.367709992000037],
              [105.90723795600009, 19.367625667000077],
              [105.90666998200008, 19.367926703000116],
              [105.90601483000005, 19.368839828000084],
              [105.90526921700008, 19.370671487000024],
              [105.90501986800007, 19.371358646000125],
              [105.90517225300006, 19.372202883000078],
              [105.90581440000008, 19.372515493],
              [105.90662114200003, 19.372523215000022],
              [105.90727142900002, 19.372069746000072],
              [105.90800969900008, 19.370927561000073],
              [105.90875366100009, 19.369249117000017],
              [105.90868274800007, 19.368329035],
              [105.90804386400006, 19.367709992000037],
            ],
          ],
          [
            [
              [105.93203219900006, 19.381678139000066],
              [105.93441830700004, 19.379384372000082],
              [105.93569004400011, 19.378109609000127],
              [105.93599004000006, 19.377193317000071],
              [105.93590384600003, 19.37618146600002],
              [105.93562407500006, 19.37516778700008],
              [105.93467162600004, 19.373688193000049],
              [105.93362047100003, 19.372391489000051],
              [105.93295936200003, 19.370822720000099],
              [105.9322130570001, 19.368150178000043],
              [105.93156549200008, 19.365294738000038],
              [105.9311889790001, 19.364280126000018],
              [105.92946912100008, 19.36214982900006],
              [105.92639439200005, 19.360006682000105],
              [105.92523594200004, 19.359719948000098],
              [105.92426718600009, 19.3598026720001],
              [105.9231948610001, 19.360527811000061],
              [105.9213356920001, 19.36244034600012],
              [105.91819986900008, 19.366087070000049],
              [105.91662802700004, 19.368278034000049],
              [105.91565435400004, 19.368820239000058],
              [105.9146836030001, 19.369086722000084],
              [105.91283886600006, 19.369620608000027],
              [105.9120558270001, 19.370440352000081],
              [105.91175378200008, 19.371540428000067],
              [105.91172650500003, 19.374113733000087],
              [105.91249923400002, 19.38340425600007],
              [105.91288152900003, 19.383867462000111],
              [105.91345935100006, 19.384148706000111],
              [105.91394427800003, 19.384061417000083],
              [105.91452989500009, 19.383607442000091],
              [105.91600898399999, 19.381048010000065],
              [105.91698950900012, 19.379862488000029],
              [105.91737860900007, 19.37968236500005],
              [105.91834455300005, 19.379875387000119],
              [105.91920983800006, 19.38043509400007],
              [105.92064615200006, 19.381919345000128],
              [105.92391765300003, 19.383788653000074],
              [105.92622709500007, 19.385097328000086],
              [105.92709725200004, 19.385197480000024],
              [105.92806903200005, 19.384839042000031],
              [105.93203219900006, 19.381678139000066],
            ],
          ],
          [
            [
              [106.01023969800006, 19.912345398000042],
              [106.0088359750001, 19.911999740000041],
              [106.0068995230001, 19.912148628000104],
              [106.00443541300005, 19.91229270200003],
              [106.00301815700003, 19.913278586000068],
              [106.00283041600004, 19.914442125000065],
              [106.00334623800009, 19.915612063000054],
              [106.00509837900002, 19.916293848000066],
              [106.00791094500002, 19.916485857000055],
              [106.00950071700002, 19.915834427000064],
              [106.01056280400006, 19.915178202000035],
              [106.01093144900001, 19.913516907000073],
              [106.01023969800006, 19.912345398000042],
            ],
          ],
          [
            [
              [104.91036886600008, 20.662641808000082],
              [104.91482898700008, 20.660415303000065],
              [104.91869749300005, 20.657495712000085],
              [104.92252619200012, 20.654458101000017],
              [104.93020307700009, 20.648441606000034],
              [104.93392440500006, 20.646928259000049],
              [104.94187353800007, 20.64471051900005],
              [104.94433211300002, 20.644235934000044],
              [104.94709490700008, 20.640243986000058],
              [104.949412624, 20.636813190000055],
              [104.95450900500006, 20.632344712000084],
              [104.95794605300006, 20.629046635000044],
              [104.95886194000008, 20.623581186000095],
              [104.95622601100011, 20.618125380000052],
              [104.956254648, 20.616286676000058],
              [104.95747108500009, 20.612860038000044],
              [104.95942244700008, 20.60891525200006],
              [104.96679460100006, 20.604947255000106],
              [104.96954954100001, 20.602735136000035],
              [104.97143918100004, 20.598730650000086],
              [104.97393647500007, 20.595712734000045],
              [104.97471590500008, 20.5949995060001],
              [104.97664593800008, 20.595084691000039],
              [104.98022802300008, 20.594429431],
              [104.98141127800001, 20.593115179000065],
              [104.98276786100008, 20.591333723000012],
              [104.98315923500004, 20.587504206000062],
              [104.98302091000005, 20.585702270000091],
              [104.982635875, 20.58374041600004],
              [104.97954540200008, 20.579471857000087],
              [104.97849606100004, 20.577500882000074],
              [104.97851057400008, 20.576561907000013],
              [104.97960947900006, 20.575324743000053],
              [104.98131944500005, 20.574184],
              [104.98230809700006, 20.574706256000042],
              [104.98511912300003, 20.576896992000023],
              [104.98602657100005, 20.577300719000036],
              [104.99138769600009, 20.578410951000059],
              [104.99337654400006, 20.578712014000097],
              [104.99495468200007, 20.578713965000084],
              [104.99579354200003, 20.57817755500011],
              [104.99847828900003, 20.576433614000052],
              [104.99994363500004, 20.575148321000114],
              [105.00301603700005, 20.573957592000092],
              [105.00548812900004, 20.572632230000032],
              [105.00811684600004, 20.570493573000093],
              [105.01031542200005, 20.568332350000063],
              [105.01628689900012, 20.564353041000061],
              [105.018482836, 20.562208387000069],
              [105.01994953100004, 20.560597439000091],
              [105.02008697100004, 20.559669747000072],
              [105.02670208700007, 20.559921893000066],
              [105.03033996500008, 20.559833859000065],
              [105.03281023700001, 20.560004210000038],
              [105.03919264700006, 20.561186698000085],
              [105.03992189200001, 20.561059404000034],
              [105.04065320200003, 20.560795065000015],
              [105.04241913200005, 20.559447962000021],
              [105.04373631100005, 20.558917304000076],
              [105.04489975900003, 20.558932907000035],
              [105.04995057400005, 20.561605112000066],
              [105.05182261000004, 20.562863884000123],
              [105.05223824900006, 20.564240252000054],
              [105.05289725400006, 20.568772698000082],
              [105.05346041600002, 20.57001393700002],
              [105.05403464600002, 20.570933971000024],
              [105.057952562, 20.573611024000101],
              [105.05998380800003, 20.57501956000003],
              [105.06349534700004, 20.57548079900009],
              [105.0708157820001, 20.576130772000042],
              [105.07637323100002, 20.577033410000034],
              [105.07827871200003, 20.577058676000078],
              [105.08018832000002, 20.576807694000095],
              [105.08402605200004, 20.575062640000041],
              [105.08698635100009, 20.573167778000112],
              [105.09233486200006, 20.568403374000077],
              [105.09292525200006, 20.568134873000062],
              [105.09408753300004, 20.568840921000039],
              [105.09873267000006, 20.571941276000061],
              [105.10105733600003, 20.573353286],
              [105.10222582000007, 20.573644937000068],
              [105.10325183600006, 20.573658423000026],
              [105.10399697500007, 20.572839356000117],
              [105.10655412199999, 20.56845234900004],
              [105.10953860700002, 20.564899760000088],
              [105.11138802800009, 20.563749769000061],
              [105.11652598600004, 20.563264466000049],
              [105.11863995000007, 20.562919484000105],
              [105.11890739799999, 20.56287583900005],
              [105.12005576800007, 20.562481745000035],
              [105.12300738800003, 20.561138848000034],
              [105.12744083500004, 20.558710052000073],
              [105.12907127500003, 20.55748798100003],
              [105.13205922600008, 20.553658787000103],
              [105.13521386300005, 20.548450219000088],
              [105.13640450000007, 20.547222360000106],
              [105.13803276600005, 20.546138324000118],
              [105.13935982800008, 20.545602960000053],
              [105.143764473, 20.545107495000089],
              [105.14904639700006, 20.544761443000056],
              [105.15022283300004, 20.54450036100004],
              [105.15155588800003, 20.543550553000053],
              [105.15752134800006, 20.537463009000057],
              [105.15901290500001, 20.53568629800013],
              [105.15991522500006, 20.533159067000014],
              [105.16025043400001, 20.530262219000051],
              [105.15983689200007, 20.528460916000043],
              [105.15900580900005, 20.525134547000064],
              [105.15931292800006, 20.524171446000047],
              [105.16004762300001, 20.524042764000058],
              [105.16093485900005, 20.523501585000048],
              [105.16255878400004, 20.522693584000073],
              [105.16418670100009, 20.521609315000063],
              [105.16522645500005, 20.520655620000113],
              [105.16673582400006, 20.517635658000088],
              [105.16688835800007, 20.51722315700006],
              [105.16763101500005, 20.516541933],
              [105.16851220600005, 20.516415105000085],
              [105.16939138700005, 20.516426401000039],
              [105.17391389300005, 20.517865995000051],
              [105.17870760000002, 20.520828645000044],
              [105.18095996500004, 20.522169924000089],
              [105.18213224200005, 20.522184918000086],
              [105.183605555, 20.52165114200006],
              [105.1862690180001, 20.519889185000025],
              [105.18848290900006, 20.518812216000093],
              [105.190682886, 20.51870212300004],
              [105.19317395100006, 20.518733869000094],
              [105.1988788350001, 20.519497235000102],
              [105.20005308400002, 20.51937400600012],
              [105.20181741600007, 20.518981958000012],
              [105.20358766500007, 20.518175510000077],
              [105.20860131400003, 20.516028621000082],
              [105.20993192, 20.515216549000037],
              [105.21171785700007, 20.513305003000056],
              [105.21632710100005, 20.508666012],
              [105.21928117500005, 20.507045455000124],
              [105.22075427300007, 20.506511419000084],
              [105.22325106200006, 20.506128432000104],
              [105.22662307000009, 20.506032748000088],
              [105.22780504300002, 20.50535684300003],
              [105.22840093000011, 20.504673559000075],
              [105.22855919600006, 20.503846611000128],
              [105.22772704900004, 20.500520388000076],
              [105.22788531500008, 20.49969343700004],
              [105.22863357400009, 20.498597591000056],
              [105.231595214, 20.49642430300009],
              [105.23336904500003, 20.495341331000105],
              [105.23528351300007, 20.49467457300009],
              [105.23631106600001, 20.494549308000032],
              [105.2377859740001, 20.493877013000095],
              [105.2391182580001, 20.492926604000061],
              [105.24224373600008, 20.489511787000048],
              [105.24269690900009, 20.488550344000075],
              [105.24263824900004, 20.48751340900008],
              [105.23875853000001, 20.482076573000093],
              [105.23776225400009, 20.479991667000071],
              [105.23792436200009, 20.478888418000096],
              [105.23838337100005, 20.477512557000033],
              [105.23898303600002, 20.476552941000122],
              [105.2400241350001, 20.47546069100008],
              [105.24297361800006, 20.474116004000017],
              [105.2568279180001, 20.468347976000075],
              [105.25919313700004, 20.466857629000046],
              [105.26039024700006, 20.465076390000014],
              [105.26102687600006, 20.463711693000072],
              [105.26250494200005, 20.460543262000016],
              [105.26462340200001, 20.455733789000071],
              [105.26478726900007, 20.454492338000115],
              [105.26409734500001, 20.451444105000057],
              [105.26213163900007, 20.445340318000135],
              [105.2612817670001, 20.443257226000085],
              [105.25677717700007, 20.435394610000031],
              [105.26194038900006, 20.432833624000054],
              [105.268868798, 20.429741728000025],
              [105.27062639300003, 20.429763519000055],
              [105.27193689500011, 20.430332443000054],
              [105.27396626800007, 20.431877453000077],
              [105.27513802100003, 20.431891955000125],
              [105.27602067500004, 20.431626534000102],
              [105.27691486100005, 20.430532225000064],
              [105.27841988900005, 20.427649234000086],
              [105.27871858200007, 20.427238420000023],
              [105.28005597400006, 20.425873225000132],
              [105.28611848500003, 20.421802856000056],
              [105.29025191800008, 20.419504830000079],
              [105.29451834200005, 20.418175559000048],
              [105.29598483900006, 20.418055399000053],
              [105.29758824300004, 20.418627776000072],
              [105.29917641300007, 20.420305338000055],
              [105.30047931200005, 20.421426687000107],
              [105.30179746400005, 20.421442843000115],
              [105.30268574700008, 20.420762871000072],
              [105.30581657100005, 20.416794179000064],
              [105.30833676000003, 20.414614230000083],
              [105.31351581700009, 20.410808599000063],
              [105.320164855, 20.406606206000077],
              [105.32562504600006, 20.403632729000073],
              [105.33306114700008, 20.40075214200003],
              [105.33615907000008, 20.399131535000059],
              [105.33926821900012, 20.396681944000058],
              [105.34357318900007, 20.392450429000064],
              [105.34490239500008, 20.391637393000025],
              [105.34960154000005, 20.390726766000085],
              [105.35091948000012, 20.390742629000066],
              [105.35193894700009, 20.391169428000033],
              [105.35382210700003, 20.392712030000034],
              [105.35498988700002, 20.393002419000084],
              [105.35645802400008, 20.392743696000082],
              [105.36248627000006, 20.391019682000078],
              [105.36395810400006, 20.390484597000032],
              [105.36484603000008, 20.389804334000061],
              [105.36634015600006, 20.387611354000065],
              [105.36723177700003, 20.386654775000054],
              [105.36856084500005, 20.38584159],
              [105.39114636800005, 20.37802688],
              [105.40290838500007, 20.37457326400007],
              [105.40583874900005, 20.374469635000111],
              [105.40788323600002, 20.374908262000076],
              [105.40948479500003, 20.37561801],
              [105.41151103300007, 20.37743819500006],
              [105.41467530900005, 20.38037416500007],
              [105.41860043300001, 20.384015883000117],
              [105.42294616100004, 20.387659445000047],
              [105.42382295900002, 20.387807876000075],
              [105.42440871399999, 20.387814730000017],
              [105.42778583600008, 20.387163330000121],
              [105.43778150300005, 20.384378138000066],
              [105.45146108600002, 20.379838837000094],
              [105.45396479100006, 20.378762369000114],
              [105.45720599800009, 20.377279850000015],
              [105.45882748400005, 20.376469492000112],
              [105.46000250400002, 20.376206692000039],
              [105.46307929000004, 20.376103991000072],
              [105.4720873310001, 20.37600035200002],
              [105.47384447500009, 20.37602052200009],
              [105.47546052700004, 20.375624520000102],
              [105.4782640290001, 20.37399848700003],
              [105.48431193000002, 20.370613169000059],
              [105.49610896700008, 20.364253134000073],
              [105.50199925500002, 20.361694586000034],
              [105.50537215700004, 20.361318289000074],
              [105.51211088800008, 20.361118191000038],
              [105.51840861300008, 20.361051099000079],
              [105.52001570000007, 20.361345577000108],
              [105.52088721500007, 20.361908126000024],
              [105.52117305300001, 20.362464076000016],
              [105.52187716100009, 20.364682922000021],
              [105.52243662100001, 20.366761941000085],
              [105.5226845610001, 20.367155251000035],
              [105.52401580100012, 20.369266977000052],
              [105.52531266100004, 20.370939733000078],
              [105.52691461300006, 20.371648640000025],
              [105.52895934200005, 20.372086145000075],
              [105.53218419400007, 20.371845959000012],
              [105.53659958300008, 20.370099064000065],
              [105.537938141, 20.369090445000012],
              [105.538225884, 20.368873627000106],
              [105.53941290800007, 20.367643267000091],
              [105.54113842700011, 20.363458801000078],
              [105.54157534100003, 20.359362711000095],
              [105.54257656800009, 20.354411470000052],
              [105.54411218600005, 20.35177015900009],
              [105.547927746, 20.347027453000074],
              [105.54812661900012, 20.346143511000037],
              [105.54779767600007, 20.342417963000067],
              [105.54786647700008, 20.336924488000108],
              [105.54846084200005, 20.334449824000075],
              [105.54922521100006, 20.333394932000104],
              [105.55093060500003, 20.332173268000098],
              [105.55489575800001, 20.330444994000096],
              [105.55773207200005, 20.328881371000037],
              [105.56170592500004, 20.326444123000087],
              [105.56247239800004, 20.325211955],
              [105.56230229000009, 20.323792166000132],
              [105.56195105100007, 20.321838666000055],
              [105.56142751500001, 20.318642569000112],
              [105.56238391400009, 20.317235257000021],
              [105.56314152500008, 20.31671192400006],
              [105.56389251800003, 20.316720239000041],
              [105.56631783000006, 20.317987749000018],
              [105.56762547300008, 20.318533930000093],
              [105.56837647400005, 20.31854223000007],
              [105.56938980400005, 20.318447885000076],
              [105.57641617300001, 20.313756842000075],
              [105.57905994800009, 20.312545264000015],
              [105.58112732700008, 20.312390754000106],
              [105.58431461000001, 20.312780249000092],
              [105.59030063800004, 20.314618278000026],
              [105.59198817800005, 20.314813996000055],
              [105.59424114000004, 20.314838638000055],
              [105.59612948600005, 20.313973078000025],
              [105.59878400700003, 20.31187518200008],
              [105.60107167000004, 20.30906428400003],
              [105.60660930300003, 20.301503191000059],
              [105.60833786700005, 20.298331607000094],
              [105.60952265700011, 20.293558895000082],
              [105.61029951200008, 20.291440386000126],
              [105.61524940400001, 20.285822229000061],
              [105.61714600099999, 20.284247556000068],
              [105.61884838800007, 20.283202510000109],
              [105.62092403600005, 20.282338720000048],
              [105.63485997000006, 20.278766785000087],
              [105.64257752400002, 20.277077128000087],
              [105.64616533300006, 20.275343059000051],
              [105.64871749500001, 20.273863735000042],
              [105.65308786900005, 20.269479162000081],
              [105.66409728400005, 20.259315748000084],
              [105.66638761800002, 20.256149506000028],
              [105.66696968800004, 20.254560395000084],
              [105.66719536600006, 20.251372206000084],
              [105.66777108600009, 20.250314787000079],
              [105.66853237500001, 20.249436574000065],
              [105.66909964700005, 20.249088083000096],
              [105.67116839100007, 20.248755484000114],
              [105.67436323300009, 20.248434775000042],
              [105.67549354300009, 20.248092210000031],
              [105.67681573600007, 20.247397154000076],
              [105.67720791300009, 20.245983253000063],
              [105.67742501000002, 20.243503956],
              [105.67839910900007, 20.24050087300013],
              [105.68011338100007, 20.238391871000069],
              [105.68258459500007, 20.235759044000098],
              [105.68485341300004, 20.234364872000029],
              [105.69126763300007, 20.231596150000094],
              [105.69221849900005, 20.230542576000055],
              [105.692416603, 20.229658357000076],
              [105.69243956300004, 20.227708733000057],
              [105.69226859900002, 20.226288858000082],
              [105.69155135600009, 20.223445150000074],
              [105.69155553300008, 20.223090669000051],
              [105.69212477500011, 20.222564861000066],
              [105.69401597000009, 20.221343866000026],
              [105.70147733400007, 20.217256305000127],
              [105.707717621, 20.213244381],
              [105.71849832300009, 20.206088646000083],
              [105.73551737200006, 20.194919495000093],
              [105.73817083200004, 20.192642313000064],
              [105.73893976100007, 20.191054809000072],
              [105.73951900500003, 20.18964260500011],
              [105.73916010600001, 20.188220762000064],
              [105.73899292700003, 20.186446345000064],
              [105.74012889800005, 20.185571664000079],
              [105.74446058100008, 20.184197983000018],
              [105.75218566100003, 20.181440760000058],
              [105.75820979800002, 20.179729554000048],
              [105.77062089100011, 20.177373947000099],
              [105.77212787100009, 20.176857409000078],
              [105.77364289600003, 20.175631849000105],
              [105.77534954000002, 20.174053669000088],
              [105.77667087900001, 20.173357941000077],
              [105.78025158900007, 20.171975941000127],
              [105.78851651400002, 20.171172868000028],
              [105.79077184400002, 20.170840995000013],
              [105.79199630699999, 20.170410109000066],
              [105.79374711600008, 20.169172805000102],
              [105.79521160500011, 20.168137821000087],
              [105.80182759300004, 20.163772203000093],
              [105.81296546700003, 20.157678634000042],
              [105.81994012600006, 20.154734208000043],
              [105.82747539100002, 20.151972366000059],
              [105.82860880300009, 20.151274459000042],
              [105.82993176200006, 20.150401134000084],
              [105.83069389800005, 20.149344982000095],
              [105.83184688800006, 20.146874455000052],
              [105.8331795990001, 20.145114805000027],
              [105.83544433900008, 20.143896161],
              [105.83826594100005, 20.143214807000028],
              [105.84221498000002, 20.142367210000067],
              [105.84522606800003, 20.141510349000043],
              [105.84711163400007, 20.140642424000085],
              [105.85466925000003, 20.135752450000055],
              [105.85759274400007, 20.133821828000094],
              [105.85853844100006, 20.133197290000027],
              [105.86506904200004, 20.128230570000127],
              [105.86847229700008, 20.125781631000095],
              [105.8724304720001, 20.124047144000109],
              [105.87565071200001, 20.123076385000083],
              [105.88870077700003, 20.120061007000075],
              [105.89203527200004, 20.119045968000115],
              [105.89440328500005, 20.117759863000103],
              [105.89747902700007, 20.115040800000102],
              [105.90558408500003, 20.108181437000013],
              [105.90739384300011, 20.107282500000068],
              [105.91016721799998, 20.106916246000047],
              [105.91488040700004, 20.10643748500005],
              [105.91807192100006, 20.105813327000099],
              [105.91992016900004, 20.104961624000062],
              [105.92169681100005, 20.103491735000119],
              [105.92324201000008, 20.101412205000102],
              [105.92549883200003, 20.097507001000011],
              [105.92783240300011, 20.092882610000103],
              [105.92909503500005, 20.091323888000097],
              [105.93017705100004, 20.090488671000095],
              [105.93244538900005, 20.088737722000083],
              [105.93774352300002, 20.085253574000106],
              [105.94221081600007, 20.081761513000096],
              [105.95339133500002, 20.07178755900005],
              [105.9589764020001, 20.067258392000028],
              [105.96008657300004, 20.067006918000097],
              [105.96465753700005, 20.06678753700006],
              [105.96894349300003, 20.067350760000032],
              [105.97055686000002, 20.067666720000062],
              [105.97806246300011, 20.069136437000076],
              [105.98386858400001, 20.069975125000013],
              [105.98732533300003, 20.069997593000011],
              [105.99000210900007, 20.069724687000075],
              [106.009064494, 20.066775412000126],
              [106.01095934200001, 20.066049040000067],
              [106.01207081800004, 20.065018151000032],
              [106.01381891300009, 20.063249474000067],
              [106.01508763000008, 20.062219979000062],
              [106.01651059700005, 20.06148929200009],
              [106.01981931400007, 20.060924311000058],
              [106.02281747000008, 20.060893469000085],
              [106.02485329500003, 20.060872503000034],
              [106.02752710700003, 20.060844938000066],
              [106.03334465900006, 20.061045805000077],
              [106.03631141600005, 20.061815882000111],
              [106.04245608000005, 20.063928127000082],
              [106.0456854550001, 20.064339839000041],
              [106.0568505090001, 20.06609833400006],
              [106.06801952400008, 20.06747335000005],
              [106.069906847, 20.067362285000101],
              [106.07153602100007, 20.066100087000024],
              [106.07331369000008, 20.063435004000048],
              [106.07244216599999, 20.056151184000015],
              [106.06743003300006, 20.045256587],
              [106.06388817500002, 20.037149181000032],
              [106.06340120400006, 20.032425422000074],
              [106.06142719000006, 20.026037243000069],
              [106.05928369300007, 20.019942571000101],
              [106.05704543100006, 20.015959634000069],
              [106.05304464600006, 20.008588010000047],
              [106.05022777200006, 20.005087952000089],
              [106.04678272300002, 20.003319793000074],
              [106.04393910600001, 20.002522247000073],
              [106.03762549800005, 20.002465921000031],
              [106.03690542900007, 20.002459485000124],
              [106.03507344800002, 20.00203435500012],
              [106.03169240900004, 20.000593081000062],
              [106.02863548400006, 19.997856788000057],
              [106.02537512400005, 19.993451611000076],
              [106.0238750760001, 19.989270582000081],
              [106.02361143200004, 19.988163560000061],
              [106.02302963100004, 19.985720544000074],
              [106.02305955700004, 19.982749829000035],
              [106.02318011400006, 19.970910530000104],
              [106.022605137, 19.970617129000075],
              [106.02169916, 19.96981635200008],
              [106.01856808000011, 19.967457125000095],
              [106.01241908700008, 19.963638545000073],
              [106.00993791400005, 19.962440656000076],
              [106.00751261700002, 19.968053921000092],
              [106.00426395800008, 19.968790954000056],
              [106.00359751000009, 19.96913331300005],
              [106.00174545500006, 19.970161736000058],
              [106.00011506500007, 19.971122478000055],
              [105.9993756530001, 19.971394484000093],
              [105.99863908200008, 19.971387772000071],
              [105.99790321100004, 19.971311379000056],
              [105.99761285400008, 19.970890623000059],
              [105.99754417200008, 19.970402203000056],
              [105.99845647900007, 19.967623123000067],
              [105.99861663000009, 19.966490698000072],
              [105.99886950900004, 19.963236728000041],
              [105.9986210810001, 19.958704901000068],
              [105.99818982000004, 19.957655673000119],
              [105.99609232600004, 19.953873494000085],
              [105.99412796400004, 19.951486216000092],
              [105.99288802400012, 19.950290216000077],
              [105.99149581900004, 19.94958062000007],
              [105.9812408450001, 19.944050975000046],
              [105.97812698500003, 19.942454350000013],
              [105.9752734690001, 19.940616173000066],
              [105.96773485700002, 19.936016807],
              [105.96137197200008, 19.931706831],
              [105.95940028600009, 19.930085678000076],
              [105.9576495580001, 19.928466559000043],
              [105.95604976900007, 19.926500380000078],
              [105.952782461, 19.92200982800005],
              [105.951159402, 19.918754123000056],
              [105.95016413500008, 19.915330030000078],
              [105.94802787400008, 19.908341052000097],
              [105.94704967100004, 19.900180066000047],
              [105.94669877700005, 19.898118884000034],
              [105.946484454, 19.897489657000023],
              [105.9463524980001, 19.896024897000061],
              [105.94596906900009, 19.891892834000124],
              [105.94529035100005, 19.891650538000057],
              [105.94492367900008, 19.891507713000053],
              [105.94307340600002, 19.891426469000081],
              [105.93870610800008, 19.891178691],
              [105.93843531600005, 19.891163325000072],
              [105.93553507399999, 19.891136061000033],
              [105.93240280800008, 19.891106572000083],
              [105.92950488300002, 19.890859637000077],
              [105.92556983600005, 19.889943995000081],
              [105.92245036900005, 19.88870656600001],
              [105.91979380900005, 19.887583295000063],
              [105.91760363600012, 19.886244801000075],
              [105.91552717000009, 19.885126978000045],
              [105.91402375100004, 19.884673444000086],
              [105.9132128790001, 19.884555927000044],
              [105.91251451300005, 19.88476890900008],
              [105.91192630800005, 19.885531982000067],
              [105.91097839900004, 19.887389746000039],
              [105.91011660500006, 19.888267656000025],
              [105.90957579800008, 19.888364689000056],
              [105.90888208300005, 19.888138456000078],
              [105.90862692200008, 19.887933047000047],
              [105.90819188800006, 19.887582825000024],
              [105.90797156500004, 19.886482618000031],
              [105.90798443200003, 19.885274824000106],
              [105.90870503000006, 19.882975662000035],
              [105.90999861100006, 19.881340815000037],
              [105.91163317200004, 19.880368069000021],
              [105.91314355300004, 19.880162813000091],
              [105.91603896900007, 19.880629580000075],
              [105.92192842200002, 19.88321109200006],
              [105.92550602200001, 19.885001916000085],
              [105.92850944, 19.886238203000062],
              [105.930710101, 19.886588405000083],
              [105.93337826500006, 19.886613549000032],
              [105.94022383600007, 19.886568094],
              [105.94543841400005, 19.887166031000092],
              [105.9512733280001, 19.888193002000065],
              [105.95510389000006, 19.889287802000055],
              [105.959052473, 19.890891851000077],
              [105.96080188400006, 19.891394629000025],
              [105.96162283400005, 19.891389744000065],
              [105.96210257000004, 19.89126686900002],
              [105.96280082500007, 19.891061438000079],
              [105.96348455599998, 19.890518735000029],
              [105.9634891110001, 19.890079543000105],
              [105.96345425200005, 19.889585077000049],
              [105.96068112500002, 19.885855253000081],
              [105.95856773400004, 19.883406310000048],
              [105.94947887800005, 19.87243419100011],
              [105.94320243300005, 19.862492257000049],
              [105.94116060400003, 19.858080542000067],
              [105.93809802700004, 19.851462883000082],
              [105.93240427200003, 19.83587024800002],
              [105.93060416100012, 19.830582001000074],
              [105.92984094500012, 19.825962413000028],
              [105.9291020740001, 19.819036806000057],
              [105.92888439700002, 19.811627203000093],
              [105.92927332100005, 19.809220946000039],
              [105.93022565200006, 19.805098607000012],
              [105.93209395400001, 19.800296325000133],
              [105.93394587700004, 19.797043110000075],
              [105.93463196900005, 19.795716706000107],
              [105.93464249200012, 19.794715596000053],
              [105.93433799600007, 19.793511258000059],
              [105.93181036400009, 19.790636942000091],
              [105.93192191200008, 19.785776902000052],
              [105.9319801450001, 19.783239621000078],
              [105.93129814900007, 19.780858915000096],
              [105.93050602400008, 19.779445698000053],
              [105.93077820000005, 19.779025600000111],
              [105.93062244000004, 19.778247656000048],
              [105.93002729900009, 19.777672631000073],
              [105.925705726, 19.772869407000115],
              [105.92143627500008, 19.768325388000015],
              [105.914424658, 19.759562158000051],
              [105.90855506, 19.751378925000026],
              [105.90635724800005, 19.747371904000133],
              [105.90217492100004, 19.739877458000073],
              [105.898867083, 19.732391233000079],
              [105.89722599500001, 19.727457540000067],
              [105.89648549300003, 19.725172627000106],
              [105.89574057500003, 19.723301809000034],
              [105.89498568500001, 19.722362729000054],
              [105.89389858000004, 19.721834601000054],
              [105.89160402400007, 19.721812544],
              [105.88732712100006, 19.723220887000089],
              [105.88567812200003, 19.724136829000116],
              [105.88479284900006, 19.725163651000074],
              [105.88389531700005, 19.727329267000115],
              [105.88212360800001, 19.729486422000072],
              [105.87948216100004, 19.731220995000065],
              [105.87827572500005, 19.731623473000056],
              [105.87734452000009, 19.731612902000073],
              [105.87609703500009, 19.730981155000023],
              [105.87089917900006, 19.726582208000117],
              [105.86440613000009, 19.720720975000106],
              [105.86267816500002, 19.718840451000027],
              [105.85955237300003, 19.714875506000062],
              [105.85589058100001, 19.709973406000088],
              [105.85320208800005, 19.706012602000023],
              [105.84741563700001, 19.695601835000076],
              [105.84352737000007, 19.686503739000024],
              [105.8376783090001, 19.671950094000046],
              [105.83438318100004, 19.663634003000126],
              [105.83116864000007, 19.652988779000054],
              [105.82853516200005, 19.6441612120001],
              [105.82512571900003, 19.631442735000078],
              [105.82315775800001, 19.62313693300004],
              [105.8224954570001, 19.618833005000091],
              [105.82036679500001, 19.602556505000024],
              [105.81903675400009, 19.594466115000039],
              [105.81799534700008, 19.589899370000104],
              [105.817150543, 19.587302094000059],
              [105.81607588700007, 19.585738057000057],
              [105.81326229700007, 19.583431772000012],
              [105.81087745000009, 19.581854617000083],
              [105.80891669900004, 19.58142079100007],
              [105.80749613200001, 19.581510135000087],
              [105.80672835600008, 19.581813107000109],
              [105.80628699400006, 19.582222914000091],
              [105.80606169900008, 19.582841985000066],
              [105.80627314100008, 19.583465439000044],
              [105.81068300700008, 19.589515736000074],
              [105.81245068800006, 19.592588251000095],
              [105.81451841299999, 19.598149009000032],
              [105.81610002100003, 19.603238885000081],
              [105.81640463200009, 19.605312968000099],
              [105.81649429200004, 19.607074252000075],
              [105.81625520700007, 19.60893580200009],
              [105.81535169200001, 19.611619140000066],
              [105.8131576210001, 19.617846590000099],
              [105.81231569800008, 19.61945872900008],
              [105.81150500400005, 19.620684370000063],
              [105.81109598400006, 19.621103361000088],
              [105.8106199270001, 19.621526297000088],
              [105.80944863000009, 19.621915293000121],
              [105.80671495200004, 19.622198595000029],
              [105.80538982700008, 19.62219670100005],
              [105.80431350800002, 19.62207098600004],
              [105.80311683900004, 19.621644790000033],
              [105.80113025100007, 19.62069435500009],
              [105.79823721700006, 19.618489287000074],
              [105.795644103, 19.615977984000033],
              [105.79359802800009, 19.613625177000046],
              [105.79272357500004, 19.612445374000039],
              [105.79226951700004, 19.611635741000022],
              [105.79205110900008, 19.610462543000025],
              [105.791927673, 19.606517339000092],
              [105.79163145500009, 19.603718439000062],
              [105.79099835200002, 19.601744551000074],
              [105.78992266200001, 19.600283950000104],
              [105.78873546700001, 19.59902933700004],
              [105.78753896300005, 19.598603036000029],
              [105.78622745500007, 19.59869334200004],
              [105.78491127300006, 19.59919780400012],
              [105.78048152000009, 19.604641268000123],
              [105.77849254700001, 19.606692161000083],
              [105.77717045200005, 19.607714275000099],
              [105.77661976300004, 19.608122892000068],
              [105.77576184700003, 19.608017856000082],
              [105.775521138, 19.60789402300005],
              [105.77531638300012, 19.607488350000054],
              [105.77521420100001, 19.606865993000078],
              [105.77533277600006, 19.606038774000112],
              [105.77623212000003, 19.603769753000044],
              [105.77801789100009, 19.600370626000085],
              [105.77927210400003, 19.598742759000039],
              [105.78023453600008, 19.597493598000071],
              [105.78266133900006, 19.595343552000074],
              [105.78442826600006, 19.593601022000051],
              [105.78529080400003, 19.592303157000046],
              [105.78587690700002, 19.591026811000042],
              [105.78621728900004, 19.58989115600005],
              [105.78666337900007, 19.58906722400009],
              [105.787407419, 19.589014219000056],
              [105.78786097800005, 19.589389989000061],
              [105.7913278770001, 19.593215599000047],
              [105.795001015, 19.597641368000041],
              [105.79658612800006, 19.599851686000044],
              [105.79734108800008, 19.600979996000056],
              [105.79775817000004, 19.602744591000132],
              [105.79793021000006, 19.606888422000068],
              [105.79845650900009, 19.608654101000056],
              [105.79888172700001, 19.60969389600011],
              [105.79974376700002, 19.610738068000089],
              [105.799952854, 19.610917982000053],
              [105.80198632700004, 19.612667714000054],
              [105.80328983600006, 19.613302094000048],
              [105.80459938000006, 19.613479551000076],
              [105.80595170800004, 19.613346726000103],
              [105.80641806700007, 19.613058651000067],
              [105.80716189900006, 19.612114659000035],
              [105.80791387900003, 19.610438879000114],
              [105.80928936000007, 19.606795213000083],
              [105.80935407599999, 19.605879060000042],
              [105.80930550200006, 19.605345642000046],
              [105.80856763500003, 19.60295654600008],
              [105.8060930250001, 19.599618085000081],
              [105.80404718500002, 19.5970087460001],
              [105.80243702400001, 19.594507318000076],
              [105.80094651000006, 19.591075067000041],
              [105.80052478400007, 19.589724630000127],
              [105.80011580900008, 19.587235212000031],
              [105.80023888800004, 19.585993790000074],
              [105.80069185500004, 19.584548570000045],
              [105.80169536400003, 19.582694654000043],
              [105.80237014900008, 19.580940982000072],
              [105.80310933600005, 19.578307731000066],
              [105.80335198500005, 19.576135490000098],
              [105.80315095600011, 19.574580128000015],
              [105.80315904800007, 19.573855315000088],
              [105.803276329, 19.573131597000071],
              [105.80427629000005, 19.571588273000096],
              [105.80582214900006, 19.570050412000036],
              [105.80692433500005, 19.569129441000129],
              [105.80747253500002, 19.568927818000056],
              [105.80812761400003, 19.568934375000069],
              [105.80877923200003, 19.569251569000109],
              [105.80888033800005, 19.569977478000041],
              [105.80842516700005, 19.57162983000007],
              [105.80774816100008, 19.573590628000126],
              [105.807520563, 19.574416804000094],
              [105.80773316300008, 19.574936714000032],
              [105.80816758500006, 19.575148173000031],
              [105.810025997, 19.574959653000064],
              [105.81112243200002, 19.574556383000093],
              [105.81211313700001, 19.573841388000091],
              [105.81300616500002, 19.572089847000115],
              [105.81324753700008, 19.5700211280001],
              [105.81272351100007, 19.568048313000119],
              [105.81219603200003, 19.566386132000076],
              [105.81103654500002, 19.562646474000097],
              [105.80915092100005, 19.555482102000084],
              [105.80725077500006, 19.545779892000056],
              [105.80627473500003, 19.535581279000077],
              [105.80473386100007, 19.520208535000094],
              [105.80455434600006, 19.508393230000109],
              [105.803754016, 19.496423862000078],
              [105.8039216010001, 19.488377391000085],
              [105.80423201900004, 19.474499106000032],
              [105.80480565100007, 19.462461560000044],
              [105.80587242200005, 19.452821445],
              [105.8069228680001, 19.444637791000027],
              [105.80812654700003, 19.439915407000093],
              [105.80950578100003, 19.436651507000043],
              [105.81068910800005, 19.433749822000031],
              [105.81090926500006, 19.431202674000048],
              [105.81093355200002, 19.4290177560001],
              [105.81056206100003, 19.427921465000075],
              [105.81018451100005, 19.427371391000037],
              [105.80884586100004, 19.426993792000054],
              [105.80788273900002, 19.427348343000084],
              [105.80555663000003, 19.429510192000073],
              [105.803814034, 19.430949487000056],
              [105.80285088400007, 19.431304005000079],
              [105.80169998500006, 19.431292445000011],
              [105.79921652100009, 19.430356999000061],
              [105.79579235100002, 19.427773179000063],
              [105.79261250600008, 19.423533004000056],
              [105.79163683700008, 19.420084303000081],
              [105.79070439200004, 19.412784482000056],
              [105.79058882300006, 19.410169754000073],
              [105.79079188100008, 19.407071076000101],
              [105.79063919400005, 19.40562644800012],
              [105.78997628400001, 19.40449735600005],
              [105.78849283700001, 19.403270754000076],
              [105.78781245500002, 19.40296773400005],
              [105.786789019, 19.403344431000043],
              [105.78606607900004, 19.405432497000064],
              [105.785508458, 19.408459812000075],
              [105.78580933100002, 19.409983881000059],
              [105.78651054600004, 19.412054313000034],
              [105.78758557200008, 19.414092932000045],
              [105.78777585600002, 19.415505999000082],
              [105.7870328920001, 19.417149139000053],
              [105.78600941500005, 19.417964115000075],
              [105.78573242100006, 19.418032827000076],
              [105.78441102800005, 19.418360605000103],
              [105.77968253000006, 19.41894762600009],
              [105.7774534000001, 19.418565146000063],
              [105.77500854600005, 19.417642266000087],
              [105.77278170100001, 19.416264975000082],
              [105.77213306000007, 19.414934604000052],
              [105.77253254300008, 19.414472879000044],
              [105.77650751300008, 19.412503110000046],
              [105.77840959800008, 19.41087176000007],
              [105.77987545600004, 19.409373529000092],
              [105.78165428000005, 19.405815094000012],
              [105.78195952600008, 19.404442625000051],
              [105.78226785500001, 19.402795072000067],
              [105.78237815600008, 19.399229815000041],
              [105.78227487100006, 19.398486724000108],
              [105.78291681500006, 19.386467366000112],
              [105.78490406400009, 19.374942519000093],
              [105.78799302500005, 19.363027770000052],
              [105.79112559200007, 19.35472118400012],
              [105.79457759900005, 19.348021224000078],
              [105.7976160100001, 19.34278129000004],
              [105.79999307800004, 19.339958758000094],
              [105.80368539300011, 19.336035569000032],
              [105.80750923000002, 19.331989861000039],
              [105.80973016000011, 19.328782991000089],
              [105.81329952300004, 19.325933175000017],
              [105.81482827500005, 19.32100818700011],
              [105.81570326800006, 19.318795290000068],
              [105.81549963900008, 19.317985380000025],
              [105.8138101120001, 19.316958660000068],
              [105.81055823100006, 19.316784235000029],
              [105.80744932100005, 19.316275897000068],
              [105.80544652600007, 19.315683166000049],
              [105.80318965700012, 19.316044348000091],
              [105.80150541700003, 19.31772751000009],
              [105.80149190000006, 19.318939184000072],
              [105.80297064100003, 19.321480048000069],
              [105.80311086700009, 19.32311241300004],
              [105.80202761800007, 19.324919217000058],
              [105.7998971330001, 19.325301732000085],
              [105.79755855400005, 19.325278190000091],
              [105.79437184600008, 19.325044096000084],
              [105.79331564100008, 19.324427544000052],
              [105.79332468600003, 19.3236197720001],
              [105.79499774300008, 19.321108381000052],
              [105.796364258, 19.317995340000046],
              [105.79703138000004, 19.315376479000079],
              [105.79781915800002, 19.312695946000069],
              [105.798097915, 19.312053782000049],
              [105.79923170900003, 19.311467377000021],
              [105.80312446700006, 19.311846351000028],
              [105.80612013800003, 19.311102453000025],
              [105.80850765900004, 19.310094435000025],
              [105.80964810900008, 19.308838261000091],
              [105.80987191200003, 19.307830649000032],
              [105.80820496000004, 19.304784393000048],
              [105.80384246400004, 19.297342639000092],
              [105.80221360900001, 19.292512587000132],
              [105.80194438500011, 19.293236963000112],
              [105.79957030100006, 19.301759308000044],
              [105.79871676100004, 19.303004159000103],
              [105.79727624600005, 19.303103601000068],
              [105.7950076430001, 19.30216914200011],
              [105.79166602300002, 19.300654071000075],
              [105.78760997400006, 19.298675923000097],
              [105.78425182000004, 19.298641916000037],
              [105.77981298600011, 19.298710836000055],
              [105.77670755300002, 19.297539780000058],
              [105.77301922800004, 19.294881394000107],
              [105.77099971700009, 19.293151552000062],
              [105.76956442699999, 19.292795068000054],
              [105.76620508400003, 19.292874722000072],
              [105.76367491100004, 19.2938744100001],
              [105.76233626700012, 19.295569965000055],
              [105.76133669500011, 19.299092178000073],
              [105.759741299, 19.302266426000081],
              [105.75392464000004, 19.307448410000028],
              [105.75224419200008, 19.307545090000119],
              [105.75057935600009, 19.306274529000063],
              [105.74858335100005, 19.302493664000053],
              [105.74678821600003, 19.302133320000081],
              [105.74569836799999, 19.303033681000059],
              [105.7443438600001, 19.306096339000064],
              [105.74150042200003, 19.313473645000023],
              [105.74085891500006, 19.317113345000081],
              [105.74068933600006, 19.321441595000088],
              [105.74019586800009, 19.324163034000016],
              [105.73506359200007, 19.320357871000034],
              [105.73076255700002, 19.318831967000079],
              [105.72668702200005, 19.318561737000017],
              [105.72299139500007, 19.319084030000063],
              [105.72067920000006, 19.319410773000101],
              [105.72233462700008, 19.311109819000102],
              [105.72010862700002, 19.310635511000029],
              [105.71614654400003, 19.306829164000035],
              [105.71270005900006, 19.30405835600002],
              [105.71066774900004, 19.303467343000058],
              [105.70754807400002, 19.303548596000027],
              [105.70045853500007, 19.304613644000042],
              [105.69481083900004, 19.305465766000026],
              [105.69277723400009, 19.304988524000066],
              [105.69099419100009, 19.303602303000034],
              [105.68909257400004, 19.302100870000061],
              [105.68717762600005, 19.301738769000032],
              [105.68501209900002, 19.302285596000068],
              [105.682461297, 19.304993372000112],
              [105.67645139400003, 19.316210523000073],
              [105.67594744300007, 19.318256231000049],
              [105.67603514300005, 19.320991914],
              [105.675536542, 19.322581874000022],
              [105.67397049500002, 19.323134963000129],
              [105.67277639400007, 19.322666464000093],
              [105.6683787600001, 19.319201186000079],
              [105.66409988300009, 19.315851032000083],
              [105.66265920600007, 19.31594958500007],
              [105.66192602600007, 19.317081221000088],
              [105.66189764100004, 19.319473827000074],
              [105.66246267500007, 19.324372782000054],
              [105.66270002100008, 19.326377984000054],
              [105.66396776600007, 19.330601954000045],
              [105.6662705750001, 19.338478355000085],
              [105.66637824300004, 19.339503645],
              [105.66588424300002, 19.340750106000115],
              [105.66418957300004, 19.342211327000044],
              [105.66130096200004, 19.343318393000075],
              [105.65998049700003, 19.343531851000058],
              [105.65914191700003, 19.343522877000026],
              [105.65687524600006, 19.34270205100006],
              [105.65603667000005, 19.342693064000024],
              [105.6548319390001, 19.343249117000113],
              [105.65362177200006, 19.34426027300011],
              [105.65288537000006, 19.345731681000089],
              [105.65188495800007, 19.349248520000103],
              [105.65073376800004, 19.35133504000008],
              [105.64969055200005, 19.352411141000033],
              [105.64836457200003, 19.3530796410001],
              [105.64620540700008, 19.353283996000044],
              [105.64260451900003, 19.353814175000124],
              [105.64127306700001, 19.354937734000096],
              [105.63844803500004, 19.360710628000085],
              [105.63819204100008, 19.362073340000038],
              [105.63828184600004, 19.364577713000038],
              [105.63826957100009, 19.365601693000123],
              [105.63801767100004, 19.366623084000075],
              [105.63704827400004, 19.367522943000012],
              [105.63584332800008, 19.368078876000091],
              [105.63536408500003, 19.368073695000106],
              [105.63393181100011, 19.36760304200007],
              [105.63071742400007, 19.365861393000024],
              [105.62964187400007, 19.365622152000036],
              [105.62768107600002, 19.369242186000065],
              [105.62729834000005, 19.371172468000083],
              [105.62751056700003, 19.373450565000013],
              [105.62793228700011, 19.378234293000048],
              [105.62779739800004, 19.379484511000022],
              [105.62694496100004, 19.380613148000059],
              [105.62491978300007, 19.382170925000075],
              [105.62467292400007, 19.382274252000094],
              [105.62356395000006, 19.382738426000046],
              [105.62140443400008, 19.382942538000059],
              [105.61972694200003, 19.382924291000059],
              [105.61841442000005, 19.382454847000119],
              [105.61579903200001, 19.380719530000064],
              [105.61070338200001, 19.375998573000075],
              [105.610352209, 19.375311999000111],
              [105.61038257000003, 19.37280896300004],
              [105.61015535900006, 19.371782366000012],
              [105.60944890200005, 19.370750540000081],
              [105.60802081500002, 19.369938397000027],
              [105.60586833600009, 19.369573472000035],
              [105.60370617800007, 19.370004960000117],
              [105.59972880300005, 19.371895784000067],
              [105.59888871800008, 19.372000353000104],
              [105.59697307900004, 19.371865527000054],
              [105.59388926200005, 19.370986508000065],
              [105.59052721600005, 19.37077199400008],
              [105.5900757420001, 19.370944814000076],
              [105.58827354500001, 19.371634680000049],
              [105.58467671000004, 19.375322607],
              [105.58106231600003, 19.380430292000113],
              [105.58029070400005, 19.382374296000116],
              [105.5802601840001, 19.384859008000085],
              [105.58078605300008, 19.387704869000096],
              [105.58057079500004, 19.390010021000045],
              [105.57999041600007, 19.391601118000068],
              [105.57733423500012, 19.394766741000069],
              [105.57655312300005, 19.395491262000064],
              [105.57355203900008, 19.398274835000073],
              [105.56884820500008, 19.400707618000098],
              [105.56695479900002, 19.402639063000038],
              [105.56199353800012, 19.410748773000108],
              [105.55609935400004, 19.41867039300007],
              [105.54642139600007, 19.430099271000053],
              [105.54110018400006, 19.436961789000051],
              [105.53860090000003, 19.4424359550001],
              [105.53448722100011, 19.45332433500004],
              [105.53300318000007, 19.456394655000061],
              [105.53035204800008, 19.459027085000088],
              [105.52430831600007, 19.463750974000057],
              [105.52186183100007, 19.464965700000022],
              [105.51886551600003, 19.46528673300012],
              [105.50528270300001, 19.464033962000116],
              [105.50130650199999, 19.463667066000042],
              [105.49869317400004, 19.463282232],
              [105.49646737500008, 19.461836877000046],
              [105.48407308800006, 19.451222878000074],
              [105.47815349300001, 19.446184986000056],
              [105.47684469200009, 19.446169899000054],
              [105.47552906700011, 19.446687206000043],
              [105.47364568800008, 19.447730413000031],
              [105.46627383300003, 19.453857360000093],
              [105.46437891000001, 19.455787808000125],
              [105.46325245700007, 19.456129731000082],
              [105.46176347100005, 19.455580001000072],
              [105.46139637900006, 19.455043267000072],
              [105.45957237500005, 19.451472298000013],
              [105.45828644700005, 19.449682459000073],
              [105.45625266000008, 19.447883917000027],
              [105.45365110400003, 19.446611217000061],
              [105.44396545100004, 19.443658425000088],
              [105.44284133700003, 19.443822783000094],
              [105.44057003700004, 19.445926133000043],
              [105.43696677000005, 19.449788780000077],
              [105.43280706600005, 19.453289838000032],
              [105.42902812400011, 19.45626281100008],
              [105.42658810200001, 19.456944097000047],
              [105.42290627100003, 19.457511622000112],
              [105.41038159300005, 19.458254614000033],
              [105.409130293, 19.458239804000051],
              [105.4078809490001, 19.458076534000043],
              [105.40510269, 19.45522263000008],
              [105.40233039700003, 19.451923329000032],
              [105.40109284500005, 19.450869322000038],
              [105.39843785800011, 19.450540829000126],
              [105.39483657900003, 19.450794933000012],
              [105.39326465500008, 19.451370106000084],
              [105.39168092400006, 19.452835972000045],
              [105.38865798700004, 19.456660221],
              [105.38737123600006, 19.459317374000037],
              [105.38734365900009, 19.461395644000042],
              [105.38871989100002, 19.463787623000073],
              [105.39026241400011, 19.465439204000035],
              [105.39024864000002, 19.466478337000034],
              [105.38992399600008, 19.467365296000061],
              [105.38880543500008, 19.469133602000085],
              [105.38610883600006, 19.471922350000078],
              [105.38356214200003, 19.475132377000115],
              [105.38260831700006, 19.476334606000108],
              [105.37844635000005, 19.483411300000064],
              [105.37476526500004, 19.489602830000052],
              [105.37237908300004, 19.492543532000091],
              [105.3661945210001, 19.498704782000061],
              [105.35636539400005, 19.508236522000018],
              [105.3577475550001, 19.510183243000064],
              [105.35990207200011, 19.512881575000037],
              [105.36065051100005, 19.515414472000089],
              [105.36122261000004, 19.519429857000056],
              [105.36103231600001, 19.521951416000057],
              [105.36019226800005, 19.526246667000088],
              [105.35857780600006, 19.529938706000038],
              [105.35363744800003, 19.536559735000125],
              [105.35220321900002, 19.538472367000011],
              [105.35062846300011, 19.539195613000082],
              [105.34905570400009, 19.53977040900012],
              [105.34498330800002, 19.540017982000087],
              [105.33980648100007, 19.539645008000051],
              [105.33761612900003, 19.538029568000056],
              [105.33423563200009, 19.535703814],
              [105.33308349000006, 19.53484397200009],
              [105.33146497100009, 19.532814272000017],
              [105.32942125300011, 19.52812486900006],
              [105.32550889500004, 19.520651963000098],
              [105.32404670000003, 19.516981436000066],
              [105.32374543600005, 19.516390902000055],
              [105.32358077300003, 19.514775341000053],
              [105.32349650700007, 19.513678133000042],
              [105.32331398000007, 19.512343162000079],
              [105.3229269280001, 19.511291281000084],
              [105.32204215600008, 19.509947717000031],
              [105.32075352000004, 19.508789591000038],
              [105.31885305500001, 19.507674084000115],
              [105.31666371000004, 19.507025976000044],
              [105.31416349800003, 19.506424135000074],
              [105.31230023400005, 19.505198228000076],
              [105.30901191600003, 19.501601043000072],
              [105.30732547700002, 19.499721140000048],
              [105.30662801900004, 19.49860585100005],
              [105.30557745000007, 19.496679728000018],
              [105.30441039000006, 19.494497229],
              [105.30279907000005, 19.492157533000039],
              [105.30014945600003, 19.4891052],
              [105.29907733300004, 19.488358377000026],
              [105.29770809500009, 19.486727557000094],
              [105.29649750000002, 19.484805242000022],
              [105.29605386700008, 19.483332561000033],
              [105.29577690900008, 19.480981606000022],
              [105.29531927700006, 19.479825143000085],
              [105.29390397400002, 19.477891461000048],
              [105.29141904000002, 19.475954053000066],
              [105.29107134900003, 19.477482095000106],
              [105.28827246800009, 19.488368832000091],
              [105.28737106500003, 19.489996764000047],
              [105.28681801400009, 19.491167816000129],
              [105.28589153700008, 19.492109772000084],
              [105.28330256500001, 19.493306368000063],
              [105.28189036800005, 19.493659326000042],
              [105.28100749600002, 19.493624111000059],
              [105.27918367400009, 19.493608030000061],
              [105.27690751500006, 19.493059660000078],
              [105.27628595200005, 19.493117256000048],
              [105.27564540800009, 19.493195966000073],
              [105.27509020700006, 19.493709112000076],
              [105.274891946, 19.494833447000111],
              [105.27495808700006, 19.496654473000063],
              [105.27601573900009, 19.49944127800007],
              [105.27731630500004, 19.503790301000024],
              [105.27740395200001, 19.505475850000039],
              [105.27716168400005, 19.506946727000063],
              [105.27669606000006, 19.508520106000034],
              [105.27596928900006, 19.510252585000032],
              [105.27542612400006, 19.51208374500002],
              [105.27440815100003, 19.515014893000071],
              [105.27371038400003, 19.517322301000029],
              [105.27298908400003, 19.518767275000037],
              [105.2725230180001, 19.51948271100008],
              [105.26742230300005, 19.527658799000044],
              [105.26627165700006, 19.530030196000055],
              [105.26639662000009, 19.531092091000076],
              [105.26948086700003, 19.538161285000029],
              [105.27079739400006, 19.542486747000076],
              [105.27183328400005, 19.547200412000088],
              [105.27180432500006, 19.549289267000077],
              [105.27136607400004, 19.55111186600007],
              [105.26992450700004, 19.555794614],
              [105.269904583, 19.557230688000082],
              [105.27057105600008, 19.558805890000116],
              [105.27198462400005, 19.561108543000039],
              [105.27362006300011, 19.562304085000015],
              [105.27649975500007, 19.563123359000123],
              [105.27786717400006, 19.563793229000034],
              [105.27868040200011, 19.564717350000116],
              [105.27933794500007, 19.566945265000065],
              [105.27956992, 19.570081893000065],
              [105.27908836700003, 19.575037653000038],
              [105.27893447100008, 19.576210886000034],
              [105.27667779400007, 19.580099975000032],
              [105.27314410000007, 19.586715103000074],
              [105.27159181200007, 19.589437737000104],
              [105.27158276000003, 19.590090472000021],
              [105.27253554500008, 19.590885755],
              [105.27582502300002, 19.591971246000114],
              [105.27815449900004, 19.592783621000095],
              [105.27951851000009, 19.593714549000119],
              [105.28032646400007, 19.595030270000088],
              [105.28091473800006, 19.597248204000017],
              [105.28167205800003, 19.602177585000021],
              [105.28165969900006, 19.603071972000038],
              [105.28117882500007, 19.60373690600008],
              [105.278200321, 19.605936255000067],
              [105.275711954, 19.606799858000024],
              [105.271928159, 19.607535480000045],
              [105.27050864500006, 19.607853246000047],
              [105.27003081500004, 19.608294563000058],
              [105.26978414600006, 19.609074201000084],
              [105.26964451800006, 19.610637890000056],
              [105.26925509700008, 19.61320480500008],
              [105.26850580700008, 19.616577118000045],
              [105.26630567500003, 19.621777809000029],
              [105.26485341400004, 19.624443229000029],
              [105.26413037300007, 19.625552340000048],
              [105.26209312500001, 19.627874991000027],
              [105.26029323000003, 19.63008876600005],
              [105.25991455100004, 19.631873042000066],
              [105.25984758000001, 19.636680177000088],
              [105.25946889400004, 19.63846443700001],
              [105.25886057800005, 19.639798575000086],
              [105.25549162600002, 19.644564327000055],
              [105.25260203200003, 19.648776946000091],
              [105.25031760700003, 19.651878999000068],
              [105.24624533200006, 19.656300278000046],
              [105.24433645300003, 19.657841622000127],
              [105.24374522000005, 19.657945993000055],
              [105.24256586700008, 19.657931155000071],
              [105.24103428200002, 19.657800069000061],
              [105.23963004700009, 19.656999717000097],
              [105.23905450800007, 19.655986166000083],
              [105.23839400100005, 19.652623532000035],
              [105.23772723600004, 19.649708043000125],
              [105.23657149500006, 19.648016293000033],
              [105.23529313299998, 19.646658430000038],
              [105.23376635800005, 19.646191917000074],
              [105.23281979300006, 19.64640358200009],
              [105.23044077000004, 19.647827077],
              [105.22776784000004, 19.649373794],
              [105.22553931200004, 19.649976931000076],
              [105.2225398570001, 19.650149369000047],
              [105.22086473500006, 19.650864691000081],
              [105.2151436, 19.655106178000011],
              [105.21368143100008, 19.656455478000098],
              [105.21311309100004, 19.657395245000053],
              [105.21061173200012, 19.661572284000073],
              [105.20880755900005, 19.66354852200007],
              [105.20734976600005, 19.664582152000065],
              [105.204330629, 19.666121973000102],
              [105.2014239260001, 19.667557967000079],
              [105.1919055920001, 19.673328543000054],
              [105.18529821800007, 19.677347351000037],
              [105.182943181, 19.679000602000052],
              [105.18102606900001, 19.68108033900009],
              [105.180127521, 19.681805315000119],
              [105.17957101900005, 19.681903370000065],
              [105.17879403000006, 19.681893369000122],
              [105.17668958200002, 19.681550612000095],
              [105.17203219000005, 19.681174906000095],
              [105.17047519100001, 19.681365249000038],
              [105.16891363600004, 19.681871178000065],
              [105.16543931300008, 19.684141083000029],
              [105.16285747400006, 19.686106837000047],
              [105.16217173500007, 19.687465800000048],
              [105.16203947200006, 19.688937141000018],
              [105.16221592400009, 19.692095963000078],
              [105.16237113500006, 19.696727546000133],
              [105.16277720600007, 19.699363233000078],
              [105.16277113400011, 19.699784017000077],
              [105.161887419, 19.700489467000089],
              [105.15881401000007, 19.702612220000049],
              [105.15437695500003, 19.706969886000088],
              [105.15226060900008, 19.708654414000115],
              [105.15167843100005, 19.70945780600006],
              [105.15166407300011, 19.710448790000079],
              [105.15212769000001, 19.711265769000086],
              [105.15362537000003, 19.712907128],
              [105.15747909900003, 19.716020722000046],
              [105.15878932200008, 19.717479390000037],
              [105.15989638400002, 19.719836467000015],
              [105.16052158500005, 19.722637814000073],
              [105.16031971500004, 19.723446141000075],
              [105.15945098600011, 19.72433593300007],
              [105.15820845200007, 19.724770352000043],
              [105.15392988000008, 19.724714844000069],
              [105.15316663500008, 19.724885137000037],
              [105.15191494599999, 19.725950128000044],
              [105.15008752600005, 19.727368052000124],
              [105.14898948200005, 19.72880416200011],
              [105.14824571200006, 19.729776909000037],
              [105.14726612900002, 19.731746447000106],
              [105.14630092200009, 19.732725020000075],
              [105.14390028600006, 19.734315620000054],
              [105.13804377200007, 19.73811368800007],
              [105.13592694100008, 19.739797993000074],
              [105.1345747230001, 19.74122197300003],
              [105.13288754100004, 19.742808686000046],
              [105.13200474300001, 19.744248603000081],
              [105.13199547900004, 19.744883501000032],
              [105.13235989800009, 19.74615830700008],
              [105.13413263400003, 19.749356555000119],
              [105.13431088200004, 19.750266049000054],
              [105.13379514800009, 19.752799356000047],
              [105.13347088799999, 19.755335148000071],
              [105.13276364000009, 19.757865932000037],
              [105.132173295, 19.758946793000028],
              [105.13090610900005, 19.760472376000045],
              [105.13065924300005, 19.760917170000077],
              [105.13067323200006, 19.761596999000105],
              [105.13137280000004, 19.762552404000111],
              [105.13221505500009, 19.76350967200009],
              [105.13447433100006, 19.76532690900008],
              [105.13503956000002, 19.765969298000115],
              [105.13522104500002, 19.766658061000086],
              [105.13520922300003, 19.767468977000121],
              [105.13470441, 19.768949335000038],
              [105.1315560100001, 19.774995133000047],
              [105.12986962000009, 19.777537433000077],
              [105.12913446500002, 19.779014745000012],
              [105.1291107710001, 19.78063654099999],
              [105.12976302000007, 19.784835514000051],
              [105.12986820800006, 19.787405215000042],
              [105.12940853300005, 19.789561983000056],
              [105.12821777600001, 19.792836019000056],
              [105.12655186100002, 19.799552595000058],
              [105.12575993600005, 19.804224811000012],
              [105.12597436500003, 19.806054962000097],
              [105.1264399670001, 19.807203146000063],
              [105.12750513100012, 19.808587605000035],
              [105.12917152100006, 19.810094128000074],
              [105.12964550300002, 19.810671370000051],
              [105.12963549300008, 19.811356489000076],
              [105.12911979500005, 19.813633898000127],
              [105.12835292000001, 19.816593257000115],
              [105.12809005000003, 19.818074506000102],
              [105.12806668000005, 19.81967309700012],
              [105.12870841500003, 19.825277618000122],
              [105.12952750500003, 19.827001421000112],
              [105.13025278800009, 19.827909806000044],
              [105.13131255900002, 19.828429003000011],
              [105.13629576100004, 19.830717544],
              [105.13904831900007, 19.832269431000071],
              [105.14126291400007, 19.83411746000003],
              [105.14315298100004, 19.836264435000125],
              [105.14377566200005, 19.837485312000069],
              [105.14408407300007, 19.838297836000059],
              [105.14427841900007, 19.839614191000088],
              [105.14434988500004, 19.842040647000125],
              [105.14472536200003, 19.845582748000098],
              [105.14558310300004, 19.852668272000074],
              [105.14618823400009, 19.855101628000057],
              [105.14681980200004, 19.855716217000108],
              [105.14777011000007, 19.856436005000063],
              [105.14915466600004, 19.856656115000071],
              [105.15043543500005, 19.856672754000023],
              [105.15610825600002, 19.85563468900002],
              [105.15867124400008, 19.85556683],
              [105.16144184100008, 19.855905885000077],
              [105.16709126300006, 19.856484211000058],
              [105.17509024600012, 19.856991590000028],
              [105.17983612300004, 19.857949666000074],
              [105.18246396200004, 19.859068041000057],
              [105.18368918200008, 19.859667793000121],
              [105.18473219500005, 19.860682357000044],
              [105.18532119200003, 19.862151632000021],
              [105.18580744800002, 19.863243905000068],
              [105.18589832200009, 19.866910451000038],
              [105.18573256800005, 19.868469505000071],
              [105.18550680500002, 19.869213259000063],
              [105.18470069200002, 19.870424706000023],
              [105.18368241200005, 19.871429785000046],
              [105.18142003700009, 19.874183689000091],
              [105.18104598900005, 19.875264903000108],
              [105.18103527300005, 19.876011409000057],
              [105.18158447100002, 19.87771537800009],
              [105.18171715300008, 19.878463714000105],
              [105.18156304300003, 19.879208375000076],
              [105.18090513300004, 19.880082316000092],
              [105.17982094200003, 19.880679271000098],
              [105.17837630900006, 19.881407337000034],
              [105.17685608200003, 19.882405917000057],
              [105.17620105900002, 19.883076249000098],
              [105.17590647200007, 19.883615467000126],
              [105.17574257600009, 19.885038752000114],
              [105.17478457400006, 19.891813960000079],
              [105.17453334600006, 19.894322098000124],
              [105.17421139200006, 19.896761451000074],
              [105.17381284200003, 19.899539173000043],
              [105.17327085700005, 19.902315056000042],
              [105.17264837400006, 19.905700739000039],
              [105.17204675200001, 19.912616094000057],
              [105.17174428900003, 19.913698171000057],
              [105.17086326300006, 19.915112155000081],
              [105.16889487300008, 19.917326579000068],
              [105.16758160600006, 19.918870714000022],
              [105.16642348900007, 19.919602367000074],
              [105.16534197500002, 19.919995630000109],
              [105.16376044400008, 19.920246690000099],
              [105.16117302000004, 19.920620477000028],
              [105.16041708400012, 19.920969984000081],
              [105.15696165500003, 19.922945384000101],
              [105.15520967900002, 19.923666948000026],
              [105.15475957000008, 19.923714275000037],
              [105.15386319600007, 19.923543169000091],
              [105.152469033, 19.922833983000118],
              [105.15141032900007, 19.922235475000072],
              [105.15096022300004, 19.922282795000079],
              [105.14766454700003, 19.923599634000055],
              [105.14698945700005, 19.922551635000026],
              [105.14590312300004, 19.921831453000038],
              [105.14543744600006, 19.921660791000036],
              [105.14435823300002, 19.921646752000029],
              [105.14146015300004, 19.922161356000068],
              [105.13719558500006, 19.924042769000039],
              [105.13624133800008, 19.924524872000049],
              [105.13583402800003, 19.924599960000045],
              [105.13522889300006, 19.924592063000098],
              [105.13497621600001, 19.924500605000084],
              [105.13369155700005, 19.923477607000081],
              [105.133092076, 19.922386999000082],
              [105.12998598600004, 19.923645442000023],
              [105.12506954300004, 19.925991483000068],
              [105.12282645900007, 19.926966417000088],
              [105.12100044000007, 19.928448956000096],
              [105.117765406, 19.9319216210001],
              [105.11668949700007, 19.932911809000053],
              [105.11561652800002, 19.933701164000084],
              [105.11411907700003, 19.934484933000093],
              [105.1051533780001, 19.937881981000061],
              [105.10419533800005, 19.938070200000091],
              [105.10364885100005, 19.938068373000114],
              [105.10241016400005, 19.937895429000015],
              [105.10009825300007, 19.937551705000089],
              [105.09877453000006, 19.93753421400006],
              [105.09744616800002, 19.93782984700001],
              [105.09561561800008, 19.938510323000088],
              [105.09253013800006, 19.940113736000065],
              [105.09178204500006, 19.940338725000025],
              [105.09095471000009, 19.940327763000099],
              [105.08923006100011, 19.939443616000041],
              [105.08717449300003, 19.938555072000064],
              [105.08519703200002, 19.937980730000056],
              [105.07933811900003, 19.936884968000079],
              [105.07265195600004, 19.935778025000062],
              [105.06052082400005, 19.933580137000014],
              [105.05647521700007, 19.932977825000094],
              [105.04671416600006, 19.932768418000087],
              [105.04464825400001, 19.932584014000021],
              [105.04406324800001, 19.93296762800005],
              [105.04397461800005, 19.933357935000103],
              [105.04360950400003, 19.935623693000082],
              [105.04333419400008, 19.937420853],
              [105.04298203600007, 19.938825494000056],
              [105.04170210700005, 19.941392101000055],
              [105.04111235000001, 19.94208883200006],
              [105.03969051400006, 19.943087544],
              [105.03851371500011, 19.943494316000084],
              [105.03832695800003, 19.943178811000095],
              [105.03684552500002, 19.941009866000044],
              [105.03546684400008, 19.940394322000095],
              [105.03458196300005, 19.940501756000103],
              [105.03394037400008, 19.941209403000038],
              [105.03341231000009, 19.942754292000053],
              [105.03300317900008, 19.944778325000101],
              [105.03236157000001, 19.945485964000028],
              [105.03134870100004, 19.945711039000088],
              [105.02978651800002, 19.945675781000105],
              [105.02697990000006, 19.946360880000036],
              [105.02464904600004, 19.947268883000021],
              [105.02281339800003, 19.948261862000024],
              [105.02069087200009, 19.949992571000053],
              [105.01871322200006, 19.951810726000119],
              [105.015757197, 19.953846189000046],
              [105.01440977900006, 19.954289120000098],
              [105.01306587400006, 19.954501455000084],
              [105.00929012900012, 19.954334738000057],
              [105.00709850300007, 19.954189566000125],
              [105.00308963100011, 19.953327685000133],
              [105.00223664900005, 19.953316039000057],
              [105.0012530110001, 19.953879170000022],
              [105.000000082, 19.955357935000073],
              [104.998526204, 19.960852158000094],
              [104.99788592400004, 19.962279500000065],
              [104.99288475200005, 19.965774695000071],
              [104.98417815400006, 19.970734760000127],
              [104.97963114900007, 19.97391673300006],
              [104.97808879500009, 19.975491142000031],
              [104.97614881200008, 19.977326008000063],
              [104.97541227500005, 19.977688182000094],
              [104.97400606700006, 19.977721992000049],
              [104.97305286400007, 19.977549287000059],
              [104.96953099700008, 19.976224201000022],
              [104.96779736800008, 19.975615195000081],
              [104.96514869000004, 19.976004105000108],
              [104.9639020880001, 19.976625128000066],
              [104.96308545200003, 19.978528594000089],
              [104.96241769500003, 19.981710611000025],
              [104.9625528490001, 19.987509893000066],
              [104.96213791900006, 19.988887018000057],
              [104.96121536400001, 19.990363485],
              [104.95995959700012, 19.991569399000042],
              [104.95764767200005, 19.993797816000033],
              [104.95668615800008, 19.99415679400007],
              [104.9560123410001, 19.994094270000048],
              [104.95506485000004, 19.993549269000077],
              [104.95378664900007, 19.992574180000041],
              [104.95227945400006, 19.99186183700008],
              [104.948137407, 19.990634196000109],
              [104.94460885000009, 19.98973413900007],
              [104.9359226290001, 19.989639690000068],
              [104.93395414100003, 19.989665407000068],
              [104.92606768900005, 19.990565697000036],
              [104.92296230300005, 19.991373190000061],
              [104.92210984000005, 19.991946304000052],
              [104.92152095100008, 19.993640027000126],
              [104.92086915700006, 19.995758359000071],
              [104.91948063100001, 19.998238650000118],
              [104.919024183, 19.99865774000007],
              [104.91857524700006, 19.998598256000029],
              [104.91779149000006, 19.998374510000083],
              [104.91447787400007, 19.996882512000091],
              [104.91368658800005, 19.99756353300004],
              [104.912206634, 19.998604012000065],
              [104.91140952400012, 19.999654084000085],
              [104.91090640000006, 20.000616005],
              [104.91059776300001, 20.001626806000061],
              [104.91033935400003, 20.002546020000089],
              [104.91043037400001, 20.002962578000094],
              [104.91208169000005, 20.007364064000029],
              [104.91666011100006, 20.016339054000106],
              [104.91689604600003, 20.016849927000102],
              [104.92043468400009, 20.019875700000078],
              [104.92317903100005, 20.022221226000077],
              [104.92399167400005, 20.023293858],
              [104.92465654700008, 20.024456695000126],
              [104.92524733000002, 20.027233433000013],
              [104.92795047000004, 20.030662612000057],
              [104.931060792, 20.034581901000067],
              [104.932005507, 20.035837371000028],
              [104.93435916900006, 20.038965150000095],
              [104.936160896, 20.04076668900003],
              [104.93731663500006, 20.041751750000088],
              [104.94780640800006, 20.048126626000062],
              [104.94907131800001, 20.048374905000088],
              [104.95126534400001, 20.04849764400003],
              [104.95548987000004, 20.049848123000032],
              [104.95838928700005, 20.0516877270001],
              [104.95944262400006, 20.052994208000108],
              [104.9601063690001, 20.054249148000025],
              [104.9604017020001, 20.055660482000093],
              [104.96100666400007, 20.057560551000094],
              [104.96162665300002, 20.058491904000064],
              [104.96419042600006, 20.059957653000104],
              [104.96923333900004, 20.06205738900011],
              [104.97053788800007, 20.062905878000059],
              [104.97163731100008, 20.064397474000103],
              [104.97179490500007, 20.065253205000118],
              [104.97132513900002, 20.067230687000048],
              [104.97114955900005, 20.069119935000046],
              [104.97283689400004, 20.075210336000012],
              [104.97347874200004, 20.077895164000026],
              [104.97427908100009, 20.079797828000068],
              [104.97879544900012, 20.086019315000044],
              [104.97951391300006, 20.086905801000029],
              [104.98207606100004, 20.088509659000039],
              [104.98569693300004, 20.09109689400006],
              [104.98704346100008, 20.092407190000117],
              [104.98852435900005, 20.094503645000117],
              [104.99048411800001, 20.095614700000027],
              [104.98029394000011, 20.098058535000085],
              [104.97557579300009, 20.098570353000063],
              [104.97068289300007, 20.099333407000053],
              [104.96515055, 20.100410482000086],
              [104.96299044000007, 20.101211090000071],
              [104.96151068100002, 20.102205626000021],
              [104.96022182200005, 20.103479611000097],
              [104.95954690000008, 20.104508337000127],
              [104.95889386900005, 20.105698838000038],
              [104.958832188, 20.106528431000115],
              [104.95886525400007, 20.107543874000022],
              [104.95946536400008, 20.109766701000083],
              [104.95963268300007, 20.111568307000113],
              [104.95957028800007, 20.112444014000097],
              [104.95907223400006, 20.113083024000034],
              [104.95752523900005, 20.113684438000057],
              [104.95345153800005, 20.11510433000003],
              [104.95202688600004, 20.115684335000061],
              [104.95118429100005, 20.116503083000033],
              [104.95063308800009, 20.117418148000077],
              [104.950128535, 20.118472255000071],
              [104.94917969400009, 20.119843137000082],
              [104.94813610400007, 20.121028159000076],
              [104.94625632500006, 20.122616768000064],
              [104.94524896500012, 20.12304105200009],
              [104.94289761500008, 20.123561972000026],
              [104.94148151000006, 20.123919272000101],
              [104.93804557000007, 20.124786199000063],
              [104.93524698700006, 20.125808291000077],
              [104.93376905600007, 20.126664239000021],
              [104.93252650000004, 20.128077076000039],
              [104.93157676800006, 20.129493991000032],
              [104.93072825200002, 20.130681641000066],
              [104.92951547200003, 20.131748857000083],
              [104.92699297300004, 20.133835781000094],
              [104.92465913500003, 20.136340513000036],
              [104.92112410100002, 20.140719889000103],
              [104.92020913800008, 20.141468278000026],
              [104.91990395000005, 20.142248282000082],
              [104.91985757100012, 20.145200186000075],
              [104.92018259300001, 20.146265812000053],
              [104.92114290100007, 20.147294198000054],
              [104.927998217, 20.15068865000007],
              [104.92954725500009, 20.151540690000047],
              [104.92963837500008, 20.151957157000041],
              [104.92670521800004, 20.158420908000082],
              [104.92610351900008, 20.159427419000053],
              [104.92558697600006, 20.15967392200011],
              [104.92402994000003, 20.159329204000095],
              [104.91965174700005, 20.158299108000044],
              [104.91113668400001, 20.156426575000097],
              [104.90999571100011, 20.156018411000076],
              [104.90918040300006, 20.155084291000058],
              [104.90716585200001, 20.152795435000122],
              [104.90561909600009, 20.151804867000052],
              [104.90387564600009, 20.150903791000104],
              [104.90023651700008, 20.149468496000097],
              [104.89727497500009, 20.14841178000006],
              [104.89540042800006, 20.14808544400006],
              [104.89368978500001, 20.148199678000097],
              [104.88695655900005, 20.147919881000043],
              [104.88495816600009, 20.147707039000103],
              [104.87385096100007, 20.146165315000083],
              [104.87263948000002, 20.145594471000017],
              [104.87009415600009, 20.144474101000043],
              [104.86742203700007, 20.143651763000037],
              [104.86503838400002, 20.143110303000086],
              [104.862744241, 20.143077570000045],
              [104.86093231300001, 20.143420772000106],
              [104.85818557200011, 20.144211937000108],
              [104.857002257, 20.1449331540001],
              [104.8565052650001, 20.145479658000021],
              [104.85550757400006, 20.146803268000021],
              [104.85379874800006, 20.148324289000108],
              [104.852171711, 20.148864379000116],
              [104.84913560400005, 20.149872164000016],
              [104.84707654800005, 20.150396247000032],
              [104.84546645500005, 20.15032700900008],
              [104.84308575300007, 20.149600828000032],
              [104.84133294900008, 20.149298843000096],
              [104.84025237500002, 20.149698519000125],
              [104.83941511700002, 20.150147816000086],
              [104.83871463000006, 20.151198813000093],
              [104.83568957800006, 20.155607154000066],
              [104.83473753200006, 20.157115840000102],
              [104.83414877300002, 20.157291899000107],
              [104.83327159300009, 20.157186999000132],
              [104.83176653700009, 20.156657845000048],
              [104.83069783400003, 20.156319510000031],
              [104.82932580200003, 20.156622653000063],
              [104.82799586500003, 20.157341598000045],
              [104.82335793900008, 20.160319401000045],
              [104.82206705200011, 20.161638599000042],
              [104.82160976400012, 20.162739170000066],
              [104.82125618200007, 20.164971498000028],
              [104.82043171300008, 20.167635269000073],
              [104.81951034900004, 20.17025148000009],
              [104.81855734900006, 20.171806190000027],
              [104.81757050100003, 20.172437762000058],
              [104.81609770800003, 20.172923884000049],
              [104.81384079900008, 20.173583176000065],
              [104.81145545500007, 20.174632693000127],
              [104.80804862700009, 20.176935986000011],
              [104.80493916200004, 20.178966747000082],
              [104.80223373700009, 20.180172976000051],
              [104.800460394, 20.181115943000094],
              [104.79951848800005, 20.181978729000072],
              [104.79854200100009, 20.183463790000111],
              [104.794293399, 20.187334315000079],
              [104.7936032060001, 20.187875933000043],
              [104.79203420000005, 20.188588615000036],
              [104.79063544500004, 20.189161071000058],
              [104.78935321500008, 20.18992658600007],
              [104.78669998500001, 20.19240196800007],
              [104.78523607300005, 20.193592347000063],
              [104.78442837500009, 20.193937212],
              [104.78261735400008, 20.194187507000017],
              [104.78060876300007, 20.19457328000005],
              [104.77943167900003, 20.194878964000026],
              [104.77790979700005, 20.19536410000002],
              [104.77652982400008, 20.196128103000085],
              [104.7752452170001, 20.197031880000083],
              [104.77356012400004, 20.198529471000093],
              [104.77175712500008, 20.199771601000101],
              [104.76993463200009, 20.200713579000059],
              [104.76777718500003, 20.201235439000072],
              [104.76689756100006, 20.20126864100007],
              [104.76519704700006, 20.200736191000068],
              [104.76413431000006, 20.200028597000049],
              [104.76306167800004, 20.199920549000083],
              [104.76144739800006, 20.200081297000061],
              [104.75973089500005, 20.200517314000074],
              [104.75737658700002, 20.201128438000104],
              [104.75588321500001, 20.201360125000086],
              [104.75490898900006, 20.201207365000059],
              [104.75364869700005, 20.200635188000057],
              [104.75224423300001, 20.199922490000063],
              [104.75078176800007, 20.199762490000062],
              [104.74770272900004, 20.199901496000017],
              [104.74103771700004, 20.199825873000066],
              [104.73966831100006, 20.199943965000067],
              [104.73804633400007, 20.200565742],
              [104.73396232100002, 20.202396472000125],
              [104.73189780000008, 20.203196146000032],
              [104.7306763780001, 20.203224114000037],
              [104.72902092300008, 20.20292271600006],
              [104.7263245720001, 20.202075300000018],
              [104.72340429000005, 20.201478238000092],
              [104.72130711199999, 20.201308583000085],
              [104.71959668500006, 20.201375331000101],
              [104.71837141, 20.201633834000084],
              [104.71718724400009, 20.20235425],
              [104.71644094200006, 20.20317345700002],
              [104.71543582700004, 20.204865278000028],
              [104.71433999800004, 20.206140557000118],
              [104.71374556899998, 20.206639102000068],
              [104.71267060800004, 20.206669167000065],
              [104.71118642900007, 20.206347126000068],
              [104.70982160600003, 20.206188316000073],
              [104.70840175900005, 20.20639771700008],
              [104.70673546100004, 20.206741811000128],
              [104.70545665600009, 20.20727621600011],
              [104.70427085600004, 20.208088792000112],
              [104.70317959100007, 20.209087304000036],
              [104.70267191900008, 20.210232945000058],
              [104.70181778600002, 20.21165016900008],
              [104.70092412900006, 20.212513238000085],
              [104.69915000700009, 20.213455336000052],
              [104.69642409300005, 20.214360069000037],
              [104.69048079700008, 20.216346550000083],
              [104.68625966700006, 20.217574615000089],
              [104.68381195600004, 20.217906755000037],
              [104.68139607200007, 20.217801114000089],
              [104.67754403300003, 20.217420042000086],
              [104.6736384810001, 20.217314866],
              [104.67104740200003, 20.217460172000031],
              [104.66976842900007, 20.21799437],
              [104.66715772800006, 20.219292583000126],
              [104.66641351600005, 20.219973247000041],
              [104.66630486800003, 20.220617409000049],
              [104.66628289800008, 20.221908704000043],
              [104.6660739380001, 20.224142818000068],
              [104.66517709400006, 20.228050199000066],
              [104.66462502700003, 20.22891828400013],
              [104.6633924490001, 20.229591521000053],
              [104.6621622100001, 20.230126407000078],
              [104.66180939000003, 20.230766853000134],
              [104.66099506200007, 20.232691908000078],
              [104.65984752900007, 20.234104477000066],
              [104.65876297100004, 20.234687676000092],
              [104.65710263100007, 20.234662445000048],
              [104.65573686700003, 20.234549417000025],
              [104.65335190700006, 20.234051851000068],
              [104.65132966400007, 20.233767363000062],
              [104.64966775500005, 20.233834317000095],
              [104.64726545800006, 20.23435125000006],
              [104.64329020600005, 20.23544382900004],
              [104.64123840700009, 20.235458636000111],
              [104.64011839600006, 20.23525700800004],
              [104.63852034000008, 20.234448402000012],
              [104.63564445400007, 20.232674592000066],
              [104.63361566200008, 20.231351935000049],
              [104.63162858700007, 20.230445054000064],
              [104.63036212200009, 20.230241140000068],
              [104.62933505900001, 20.230317657000093],
              [104.62795505500006, 20.23103456900008],
              [104.62617088500005, 20.232529463000049],
              [104.62373277900011, 20.235121396000096],
              [104.62170197600004, 20.236750847000096],
              [104.61989209400012, 20.23831447700006],
              [104.61341988000001, 20.24255098900003],
              [104.6122294890001, 20.243593608000054],
              [104.611429775, 20.244642247000044],
              [104.611165654, 20.245791381000089],
              [104.61116893400001, 20.247013826000071],
              [104.61117948100008, 20.249228131000052],
              [104.61119561300009, 20.251119623000058],
              [104.61087386700011, 20.252775266000086],
              [104.61016539500011, 20.254194307000084],
              [104.60936722300006, 20.255150686000079],
              [104.60763552500003, 20.256415555000018],
              [104.60748181600012, 20.256828337000066],
              [104.60742419100005, 20.257334843000052],
              [104.60780053200001, 20.258170941000039],
              [104.60856038200009, 20.259428101000054],
              [104.6095845680001, 20.262349900000039],
              [104.60971272700004, 20.263412798000054],
              [104.60981456000003, 20.265997475000042],
              [104.6097594890001, 20.269179381000058],
              [104.60958502100004, 20.270791120000098],
              [104.60880705600007, 20.27231058900005],
              [104.60897329000007, 20.275606242000066],
              [104.60914284500006, 20.277583788000086],
              [104.60948650400007, 20.279726491000091],
              [104.60978824100008, 20.280546315000066],
              [104.61000716500007, 20.28088099600005],
              [104.61050080200006, 20.281151832000077],
              [104.61122751300006, 20.281378002000046],
              [104.6127973310001, 20.281711012000066],
              [104.61330738300002, 20.282053816000101],
              [104.61384196500002, 20.282701905000074],
              [104.61453677900005, 20.284021544000019],
              [104.61451804000009, 20.291600645000059],
              [104.61503794600003, 20.293084671000102],
              [104.61859668400005, 20.296779611000112],
              [104.61920025299999, 20.297381944000037],
              [104.61959883500002, 20.298043347000046],
              [104.618910579, 20.297620107000022],
              [104.61815010700005, 20.297495662000117],
              [104.61737490300004, 20.297564924000078],
              [104.61676674600008, 20.29792090100004],
              [104.61571046000007, 20.299325362000076],
              [104.615224251, 20.300089121000028],
              [104.61502083800004, 20.303614862000074],
              [104.61394576400008, 20.30927159600013],
              [104.61360879500009, 20.311803221000076],
              [104.61360969800008, 20.31316388700013],
              [104.61495868100005, 20.317105153000092],
              [104.61580776100004, 20.318870902000086],
              [104.61743774500009, 20.320694766000088],
              [104.61795683000005, 20.321763578000059],
              [104.61816273900003, 20.322573900000052],
              [104.61814760800011, 20.323450001000062],
              [104.61778890100001, 20.32441307300008],
              [104.61631152399998, 20.327895698000106],
              [104.61614583400004, 20.329000099],
              [104.6161723700001, 20.330291941000041],
              [104.61650732900002, 20.332118931000039],
              [104.61689606300003, 20.335076740000076],
              [104.61707317000003, 20.336140274000059],
              [104.61864648500008, 20.338424439000043],
              [104.61971929000003, 20.341392725000063],
              [104.61979233500004, 20.342823624000061],
              [104.61950728300008, 20.343764737000036],
              [104.61885697200009, 20.344631063000037],
              [104.61766200600007, 20.34590410600012],
              [104.61686827800004, 20.346583728000041],
              [104.615544243, 20.346840104000115],
              [104.61445813100002, 20.347469095000022],
              [104.61391188300003, 20.347968030000096],
              [104.61350504400012, 20.348884197000046],
              [104.61338657900009, 20.350081531000086],
              [104.61326493200006, 20.351463294000013],
              [104.61340842900003, 20.353056682000044],
              [104.61374111500002, 20.356428629000064],
              [104.61379576900002, 20.358919994000054],
              [104.61400818900009, 20.360768084000114],
              [104.61438396000001, 20.361650159000085],
              [104.61488468400006, 20.362372724000096],
              [104.61902497100004, 20.366033751000053],
              [104.61989421400003, 20.366646664000051],
              [104.63018083200009, 20.371047424000018],
              [104.63682765900009, 20.373826271000084],
              [104.63996835000007, 20.376096848000103],
              [104.64469652700005, 20.379730357000092],
              [104.64713558600006, 20.38141123500008],
              [104.65795030400004, 20.385684950000012],
              [104.66874917000003, 20.390916674000081],
              [104.67178303000006, 20.393348203000095],
              [104.67519434300004, 20.39608211400008],
              [104.67690349900006, 20.398025536000119],
              [104.67973668700009, 20.402177440000102],
              [104.68130079500001, 20.404118621000045],
              [104.6821670410001, 20.404405618000077],
              [104.68406326100005, 20.403886302],
              [104.68588082400007, 20.403075833000095],
              [104.6872294660001, 20.402489955000114],
              [104.69017601900001, 20.401704155000019],
              [104.69665712200006, 20.400049024000033],
              [104.6975399840001, 20.399877792000076],
              [104.69895735800004, 20.399899048000059],
              [104.70110473300008, 20.400115710000044],
              [104.70315359400006, 20.400376988000076],
              [104.70393559300001, 20.400388697000068],
              [104.70530875100012, 20.400132541000104],
              [104.70712332700006, 20.399790746000065],
              [104.71005659400002, 20.399788485000059],
              [104.71180059199999, 20.4007368890001],
              [104.7135880650001, 20.402008747000089],
              [104.71445389300006, 20.402851784000028],
              [104.71569609600007, 20.406029355000044],
              [104.71627119900006, 20.407226894000097],
              [104.716499148, 20.407701550000112],
              [104.71643943900003, 20.408346302000076],
              [104.71623696200007, 20.408758332000062],
              [104.71539832100004, 20.409206992000058],
              [104.71422294100005, 20.409327807000111],
              [104.71167513100009, 20.409658707000041],
              [104.71000322400005, 20.410233252000062],
              [104.70876888500005, 20.410952669000082],
              [104.703282868, 20.41592038200006],
              [104.70063088200004, 20.419523859000051],
              [104.69937233300001, 20.421672465000107],
              [104.69787396500001, 20.424993415000081],
              [104.69600853900005, 20.428331888000081],
              [104.69562040500008, 20.429318742000113],
              [104.69539548100003, 20.429890625],
              [104.69454891400007, 20.430800230000031],
              [104.69317085100008, 20.431332925000056],
              [104.69008033000009, 20.43193211600007],
              [104.6885595040001, 20.43223205800011],
              [104.68771756800007, 20.432865008000078],
              [104.68701915900002, 20.433684580000119],
              [104.68607928700006, 20.435768690000074],
              [104.68486545500008, 20.438148405000064],
              [104.68428944400003, 20.440399373000062],
              [104.68405796600005, 20.442517173000049],
              [104.68345883500004, 20.443245990000044],
              [104.68232351900008, 20.443874492000091],
              [104.67983954000007, 20.444736296000073],
              [104.67654304300008, 20.44593167500004],
              [104.67486288700006, 20.446966949000078],
              [104.67352571700009, 20.447961273000104],
              [104.67114150400009, 20.450138739000053],
              [104.67000453900008, 20.450859383000086],
              [104.668334513, 20.451295268000088],
              [104.66452647800006, 20.45236742900007],
              [104.66305119900002, 20.452852341000046],
              [104.66269718500004, 20.453538689000084],
              [104.66238733800007, 20.454502392000059],
              [104.66148071100011, 20.458915586000082],
              [104.66145946900004, 20.460160332000044],
              [104.66193577900002, 20.460905372000049],
              [104.66277995200005, 20.461586814000029],
              [104.668495589, 20.4648090640001],
              [104.66897507700007, 20.465369673000033],
              [104.66895859200004, 20.466337805000109],
              [104.66884276000005, 20.467396658000055],
              [104.66793287000007, 20.469135193000021],
              [104.66690819900003, 20.470433909000093],
              [104.66582000500006, 20.471155249000091],
              [104.65587438000004, 20.475062404000077],
              [104.6548444650001, 20.475231222000012],
              [104.65242542000004, 20.475125302000038],
              [104.64688317900004, 20.473196486000056],
              [104.64591480800004, 20.472628391000036],
              [104.64451826500004, 20.471362075000066],
              [104.64321674500009, 20.47024316700012],
              [104.64215340500004, 20.469527624000072],
              [104.63131298700007, 20.46431269100006],
              [104.62985420399998, 20.463829241000028],
              [104.62819749500004, 20.463481092000087],
              [104.62629394600002, 20.463267484000092],
              [104.62468692600001, 20.462873953000035],
              [104.62381804600005, 20.462215049000051],
              [104.61996722400004, 20.457244856000045],
              [104.61871763000002, 20.455980598000068],
              [104.61567278000007, 20.451818395000117],
              [104.61150404900012, 20.447546071000119],
              [104.611270797, 20.446896880000054],
              [104.61142942800006, 20.446207600000044],
              [104.61258813300002, 20.444242486000071],
              [104.61348004600009, 20.443564470000105],
              [104.61559407100003, 20.442905244000094],
              [104.6164803870001, 20.44254994100001],
              [104.61663262899999, 20.442229477000048],
              [104.61674155400004, 20.441585544000063],
              [104.61653951100001, 20.440544845000105],
              [104.61636365500003, 20.436576218000098],
              [104.61590352600007, 20.43490899600009],
              [104.6137892560001, 20.431348639000113],
              [104.61261269400009, 20.42870193200006],
              [104.6108039010001, 20.42586101700001],
              [104.60931575600006, 20.424270160000013],
              [104.60670338800001, 20.422661958000084],
              [104.60381862400007, 20.421257047000019],
              [104.59897632500007, 20.418553655000068],
              [104.59718062700009, 20.417788049000052],
              [104.59572228500004, 20.417304340000086],
              [104.59275031100007, 20.416704962000054],
              [104.58770223700002, 20.416004211000072],
              [104.58277196100002, 20.415558822000079],
              [104.57827112900003, 20.415719509000049],
              [104.57459476600005, 20.41626186000007],
              [104.57217954200006, 20.417377205000072],
              [104.57102644600003, 20.417612891000125],
              [104.56614262100007, 20.417306197000059],
              [104.56340704200004, 20.417171287000087],
              [104.55458469200006, 20.414243327000037],
              [104.55376107500004, 20.413815378000066],
              [104.55226211300001, 20.412869568000055],
              [104.54999576000007, 20.411081608000089],
              [104.54864183700005, 20.410230275000082],
              [104.546744598, 20.409693231000055],
              [104.54449953200006, 20.409473541000089],
              [104.54278884500009, 20.409446687000084],
              [104.54099792500006, 20.409810556000082],
              [104.53888481200001, 20.410422990000129],
              [104.53594567200001, 20.4107457100001],
              [104.53241673900006, 20.411243568000081],
              [104.53157765700008, 20.411691535000017],
              [104.53067744100012, 20.412830287000091],
              [104.52943015300004, 20.414240269000047],
              [104.52775931700008, 20.41472121100005],
              [104.52673124700001, 20.414797229000037],
              [104.52600462800002, 20.41441683500009],
              [104.52552402800005, 20.413948079000122],
              [104.52539377200002, 20.413023669000019],
              [104.52570884500007, 20.41042300600013],
              [104.52634528900006, 20.407619871000101],
              [104.52648068700006, 20.405500587000077],
              [104.52646536100005, 20.40360950700002],
              [104.52600086900007, 20.400858152000097],
              [104.52568576500002, 20.399331277000066],
              [104.52498457500009, 20.397521591000078],
              [104.52280110900008, 20.393843749000062],
              [104.52108185500003, 20.391556765000104],
              [104.52052724600006, 20.390901202000045],
              [104.51839563599999, 20.388989172000024],
              [104.51682114100001, 20.388360685000073],
              [104.51464501300009, 20.387452986000106],
              [104.51261255000006, 20.386685412000105],
              [104.51127691100002, 20.386259326000108],
              [104.50935761400004, 20.386174216000086],
              [104.50604939600004, 20.386084184000097],
              [104.50266071600007, 20.385592239000069],
              [104.49554709000007, 20.384280005000029],
              [104.49437751600003, 20.3840769010001],
              [104.49341002900009, 20.383508061000022],
              [104.49278710800006, 20.382806345000049],
              [104.49226771900007, 20.381783433000052],
              [104.49160751600007, 20.380435447000075],
              [104.49031367800005, 20.37898510900007],
              [104.48724319600009, 20.377114397000092],
              [104.48388867800001, 20.37609231900008],
              [104.47991384400002, 20.374230106000098],
              [104.47771289300009, 20.37218491000008],
              [104.47291773799999, 20.368478465000095],
              [104.47046261500007, 20.367464155000015],
              [104.46792885700003, 20.367117108000073],
              [104.463164535, 20.367327153000048],
              [104.458987565, 20.369094694000097],
              [104.45509647500009, 20.37142],
              [104.45364595700008, 20.373211465000018],
              [104.45218094400009, 20.375168478000113],
              [104.45152559900005, 20.376264803000055],
              [104.45085102900001, 20.378421576000072],
              [104.45034561300011, 20.379335824000044],
              [104.44552467200002, 20.381010589000056],
              [104.44459966100004, 20.380515844000065],
              [104.44280464700003, 20.378958577000091],
              [104.44160336100002, 20.378175034],
              [104.437945891, 20.377083176000085],
              [104.43604087300012, 20.377006227000024],
              [104.43456895500002, 20.377305230000076],
              [104.43201856100004, 20.377771230000086],
              [104.42833157900006, 20.378910554000115],
              [104.4251117430001, 20.37989599200003],
              [104.42354878600001, 20.379824490000061],
              [104.42218888700002, 20.379341183000044],
              [104.42074391100002, 20.378164692000041],
              [104.41977751100008, 20.377549414000029],
              [104.41899646400003, 20.377490594000044],
              [104.41751775700007, 20.378158336000048],
              [104.41568261400002, 20.379604302000054],
              [104.41054312600004, 20.383971134000056],
              [104.40844491500006, 20.386427378000072],
              [104.40569269800008, 20.389887541000107],
              [104.40452716600001, 20.392128358000058],
              [104.40392744200003, 20.394170866000124],
              [104.40390626400006, 20.395323496000103],
              [104.40401264000006, 20.396510577000093],
              [104.40345317300005, 20.401357667000042],
              [104.40305737900007, 20.402942293000059],
              [104.40303111000003, 20.404371538000099],
              [104.40336958000006, 20.407236408000081],
              [104.40398042200006, 20.412573021000036],
              [104.40407790600005, 20.413078166000084],
              [104.40438219100008, 20.414654890000072],
              [104.40560642500004, 20.417211345000027],
              [104.40717792300003, 20.419496752000029],
              [104.410227159, 20.422521049000061],
              [104.41170691900004, 20.424482082000047],
              [104.41248715300003, 20.427261818000098],
              [104.40958460100003, 20.428229077000104],
              [104.40613331200002, 20.429786861000117],
              [104.40318886700003, 20.431698746000038],
              [104.40076568200008, 20.433180999000022],
              [104.39637698400006, 20.435184470000053],
              [104.39539084500008, 20.435629494000104],
              [104.39409866000001, 20.436761245000042],
              [104.39063114200007, 20.440509022000072],
              [104.38894186400002, 20.441957037000066],
              [104.38494903100005, 20.443690052000107],
              [104.38361977500003, 20.444175505000125],
              [104.38195685200002, 20.444194311000082],
              [104.38044143000003, 20.444169409000068],
              [104.37941912000009, 20.443922016000023],
              [104.378164338, 20.443025186000071],
              [104.37690102100002, 20.44258935800007],
              [104.37631441800008, 20.442579706000075],
              [104.37545253600008, 20.442911396000071],
              [104.37480422300008, 20.443592463000115],
              [104.37474509599998, 20.444144877000078],
              [104.37506934400008, 20.445118649000094],
              [104.37621867600005, 20.446428805000046],
              [104.37737569700005, 20.447324033000044],
              [104.37941437900005, 20.44814153],
              [104.38013657200004, 20.448752905000056],
              [104.38022070000007, 20.449492129000113],
              [104.38000383300007, 20.450641458000085],
              [104.37940879300004, 20.452407114000067],
              [104.37867745000001, 20.45493142200003],
              [104.37711264800004, 20.460208906000062],
              [104.37642917400007, 20.461465820000058],
              [104.37553294200006, 20.462327247000097],
              [104.3737953360001, 20.463728199000016],
              [104.37289395400003, 20.464866218000033],
              [104.3726349730001, 20.465645905000059],
              [104.37256556600005, 20.466751501000054],
              [104.37259223200003, 20.467950914000085],
              [104.37291224500004, 20.469155155000074],
              [104.37441855600005, 20.471001462000068],
              [104.37768170800004, 20.47437535300007],
              [104.37883463400004, 20.475501035000043],
              [104.38009474600004, 20.47612122600011],
              [104.38315707800011, 20.477139918000105],
              [104.38704466700007, 20.478494896000129],
              [104.38913090700002, 20.47940526400005],
              [104.38951184900002, 20.479964866000074],
              [104.38947759700002, 20.48049459000012],
              [104.38847503200003, 20.48181544400007],
              [104.38667482400002, 20.483953236000026],
              [104.38536778700006, 20.485868545000052],
              [104.38514409400003, 20.487386600000065],
              [104.38511597900001, 20.488907862000083],
              [104.38547476900003, 20.490666031000067],
              [104.38775039500011, 20.489273863000079],
              [104.38893495600007, 20.488693817000048],
              [104.38962375800004, 20.488474543000024],
              [104.39182491100009, 20.48846449100008],
              [104.394365787, 20.488598327000048],
              [104.39803747000003, 20.489742039000092],
              [104.40154272700008, 20.490629353000053],
              [104.40320180500004, 20.490840894000058],
              [104.40501097000006, 20.490870426000107],
              [104.40995627600006, 20.490582171000057],
              [104.41315687500003, 20.490749593000054],
              [104.41646409100011, 20.491771772000078],
              [104.41908594500006, 20.492828885000058],
              [104.42053682000002, 20.493728594000068],
              [104.42144815000002, 20.494711759000062],
              [104.42206358100005, 20.495828452000083],
              [104.42262674700001, 20.497128736000022],
              [104.42339731300009, 20.497786812000115],
              [104.42509863500008, 20.498367767000047],
              [104.42744873300011, 20.499581748000125],
              [104.429767258, 20.501187138000084],
              [104.4318885430001, 20.502881527000035],
              [104.43308664600002, 20.504238158000106],
              [104.43369629000003, 20.505677485],
              [104.43472754700008, 20.508138084000024],
              [104.43558340000001, 20.509489152000079],
              [104.43640464200011, 20.510055767000068],
              [104.43708673700006, 20.510205134000081],
              [104.43821147100005, 20.510223320000073],
              [104.43949045900008, 20.509828997000042],
              [104.44050509900011, 20.509176778000082],
              [104.44208670700004, 20.508280103000054],
              [104.44272326200004, 20.508244266000105],
              [104.44359844200008, 20.508535066],
              [104.44591385399998, 20.510324651000111],
              [104.44702351500004, 20.511172548000012],
              [104.4482865840001, 20.511654022000059],
              [104.44989197600006, 20.512141004000028],
              [104.45546659200008, 20.513591029000096],
              [104.45911840499998, 20.514525856000084],
              [104.46140764700004, 20.515069855000064],
              [104.464828326, 20.51526310000007],
              [104.46692364800001, 20.515711701000058],
              [104.47112505000008, 20.51736984600003],
              [104.4724337980001, 20.51803634500002],
              [104.47330242500003, 20.518695800000053],
              [104.47430613000003, 20.520002952000112],
              [104.47506532700005, 20.521306186000032],
              [104.47573669200008, 20.522054681000057],
              [104.47661033100007, 20.522437536000027],
              [104.47952634100002, 20.523498572000065],
              [104.48097275400006, 20.5246744250001],
              [104.48154302000009, 20.525605729000063],
              [104.48188783700002, 20.526833144000101],
              [104.48191767100009, 20.527894136000043],
              [104.48188532600008, 20.529691899000021],
              [104.48201130800003, 20.530846648000086],
              [104.48296043100004, 20.532475636000044],
              [104.48511650100008, 20.534999933000094],
              [104.48692043900004, 20.535255977000055],
              [104.48731238500005, 20.53531160700004],
              [104.489814999, 20.536250615000107],
              [104.49034223300001, 20.536858432000123],
              [104.49052793500002, 20.537414696000056],
              [104.49051138800006, 20.538336620000038],
              [104.49052306800004, 20.540411703000046],
              [104.49059523200008, 20.541842225000018],
              [104.49102467000007, 20.542448478000082],
              [104.49218195700008, 20.543389074000103],
              [104.49530235600008, 20.545352224000091],
              [104.49685922900002, 20.545838061000055],
              [104.49856944100003, 20.545957456000075],
              [104.49974495000004, 20.545883915000026],
              [104.50200472600005, 20.545366497000117],
              [104.50421888500003, 20.544663894000067],
              [104.50544328600007, 20.544591098000083],
              [104.50666027400005, 20.544933150000055],
              [104.50821223200003, 20.545695484000071],
              [104.50961089200003, 20.546824236000113],
              [104.51136857300006, 20.548396690000104],
              [104.51188603800007, 20.549557592000056],
              [104.51255682700005, 20.550352042000064],
              [104.51333530400008, 20.550594900000085],
              [104.51426708900007, 20.55047131400007],
              [104.51618943300006, 20.549671766000088],
              [104.51726795200004, 20.54955048700009],
              [104.51892601300008, 20.549853335000101],
              [104.52087017800001, 20.550575655000088],
              [104.52270586300003, 20.551895650000063],
              [104.52460716900003, 20.553654687000034],
              [104.526169678, 20.556584101000027],
              [104.52630413900002, 20.55727783300005],
              [104.52589972100004, 20.558009181000081],
              [104.52320183100002, 20.561148046000085],
              [104.52235306500005, 20.562102909000131],
              [104.52219401600004, 20.562792005000041],
              [104.52207979200008, 20.563712344000074],
              [104.52223500200006, 20.56461388200006],
              [104.52451385300004, 20.571289209000021],
              [104.52536731700005, 20.572824179000101],
              [104.52608715000011, 20.573619331000081],
              [104.52722484400009, 20.574305803000037],
              [104.53163219900007, 20.576864936000035],
              [104.53249474600003, 20.577892838000089],
              [104.532922748, 20.578591160000045],
              [104.53304742800002, 20.579837978000093],
              [104.53307672900004, 20.580944984000027],
              [104.53281367100004, 20.583361399000033],
              [104.53281761700001, 20.585897274000061],
              [104.53312736500006, 20.587746360000025],
              [104.533885583, 20.589141441000081],
              [104.53431606700009, 20.589701469000055],
              [104.53523199000003, 20.590702780000036],
              [104.53638806300002, 20.59175],
              [104.53686644700008, 20.59213902100003],
              [104.53744980900002, 20.593738807000058],
              [104.53852359800005, 20.596660272000108],
              [104.53937402800008, 20.598379501000075],
              [104.53989423900003, 20.599401963000027],
              [104.54061667700005, 20.600058769000015],
              [104.54260300099999, 20.601196430000087],
              [104.54722314600008, 20.602836389000068],
              [104.54839328, 20.603085233000051],
              [104.55144824600008, 20.603294406000124],
              [104.55232483600004, 20.603538637000085],
              [104.55348440500006, 20.604386643000083],
              [104.55439205300011, 20.60564564600007],
              [104.55529807900002, 20.606996814000041],
              [104.55592275000002, 20.607652033000079],
              [104.55713615900005, 20.608224229000079],
              [104.55913239900011, 20.608808637000031],
              [104.56069561400004, 20.608971342000082],
              [104.56566206500001, 20.609025714000047],
              [104.57106339600007, 20.608003293000095],
              [104.57331643800006, 20.60790001000008],
              [104.57634744500002, 20.608085413000055],
              [104.57761307300004, 20.608473895000131],
              [104.57876956400005, 20.609506122000099],
              [104.58021478100009, 20.61081944600005],
              [104.58128472800007, 20.611204869000055],
              [104.58319288200003, 20.611234450000012],
              [104.58402545200008, 20.611201253000019],
              [104.58545239700003, 20.610762323000117],
              [104.58668845100007, 20.610043803000089],
              [104.58766859700008, 20.609966770000106],
              [104.58883641200006, 20.610353672000059],
              [104.58998337600011, 20.610532768000041],
              [104.5946507200001, 20.60949842800008],
              [104.59593244900002, 20.608964981000085],
              [104.59667598900008, 20.608423209000115],
              [104.59781814200008, 20.60747265800007],
              [104.59880950000003, 20.606750300000073],
              [104.5994479500001, 20.606621836000016],
              [104.60008158800004, 20.606769921000101],
              [104.60109701300003, 20.607477134000085],
              [104.60200577400008, 20.608689840000061],
              [104.60320250200007, 20.610229699000101],
              [104.60503054900006, 20.61346204200003],
              [104.60581989000009, 20.615917663000083],
              [104.60634209600005, 20.61684776200012],
              [104.60696456900004, 20.617641100000036],
              [104.60822312100008, 20.61844421200005],
              [104.60884960600004, 20.619007081000085],
              [104.60922584100008, 20.619888825000118],
              [104.60993819400005, 20.621144551000015],
              [104.61094893300002, 20.622128248000045],
              [104.612038275, 20.622813479000079],
              [104.61378698200009, 20.623577977000096],
              [104.61563436600008, 20.624297887000061],
              [104.61992431100003, 20.62528574],
              [104.62284662500007, 20.626114256000072],
              [104.62406034500003, 20.626686068000041],
              [104.62473265100006, 20.627433999000019],
              [104.62664634400004, 20.631405014000045],
              [104.62789393000006, 20.632853257000058],
              [104.6286577700001, 20.633971375000023],
              [104.6290775030001, 20.635176439000077],
              [104.62924699700001, 20.636700383000047],
              [104.6293928640001, 20.641013097000013],
              [104.62964752000002, 20.643275949000014],
              [104.6303986950001, 20.645131468000066],
              [104.63232959900007, 20.6495405430001],
              [104.63306469200006, 20.650911735000044],
              [104.63445291800008, 20.652730847],
              [104.6357488350001, 20.65422583700007],
              [104.63895001600011, 20.651629334000084],
              [104.64368677400007, 20.646771256000115],
              [104.64960505400011, 20.640835386000056],
              [104.65307385100006, 20.637209913000021],
              [104.65494284100006, 20.634812248000053],
              [104.656229, 20.63248397600006],
              [104.65861903000004, 20.62876375600009],
              [104.65967999100006, 20.625023351000046],
              [104.66886701200011, 20.624653646000098],
              [104.67139618000002, 20.622500551000122],
              [104.67650414500005, 20.620151529000097],
              [104.68076811, 20.618572290000081],
              [104.68194555400007, 20.617729143000041],
              [104.68279340700005, 20.61672450700005],
              [104.68289107700005, 20.615865104000086],
              [104.68217409800006, 20.614054302000092],
              [104.68179734100006, 20.611779050000045],
              [104.68135081000005, 20.608720125000076],
              [104.68169530400006, 20.605555695000085],
              [104.68175508200002, 20.602034773000085],
              [104.68131124000007, 20.598819314000046],
              [104.68198759600008, 20.598125127000074],
              [104.68730947600005, 20.597813839000075],
              [104.68904425100007, 20.598387741000046],
              [104.69058910200008, 20.60036752000007],
              [104.69318320700003, 20.604163058000054],
              [104.69517384000008, 20.604349424000112],
              [104.69941881100003, 20.603865182000014],
              [104.70265921000001, 20.603835413000034],
              [104.70514948500008, 20.60395090300009],
              [104.71225653400006, 20.606170092000028],
              [104.72038255100006, 20.60460848500005],
              [104.72134733200004, 20.601570607000049],
              [104.72233438799999, 20.597202558000035],
              [104.72353644400006, 20.594872535000071],
              [104.72610000900008, 20.590606121000029],
              [104.72989446500006, 20.584753496000104],
              [104.73683479900009, 20.577029842000094],
              [104.7386178460001, 20.574708270000116],
              [104.74604856600004, 20.577400853000057],
              [104.75317071000002, 20.578679857000012],
              [104.75483866500009, 20.58077844200001],
              [104.757442854, 20.584025621000102],
              [104.75849778500007, 20.585528155000077],
              [104.75990567200003, 20.585783652000075],
              [104.76281487300004, 20.585669864000074],
              [104.76498053600008, 20.58531032900008],
              [104.76541388600006, 20.584220991],
              [104.7660392170001, 20.581569174000066],
              [104.76759482600004, 20.577913533000114],
              [104.76877423700009, 20.576913377000047],
              [104.77153013200004, 20.57601458000002],
              [104.77368922500007, 20.57604619300006],
              [104.77493355600008, 20.576142664000052],
              [104.77633365500002, 20.576867540000073],
              [104.77738998300002, 20.578291758000056],
              [104.77800145200008, 20.581509550000106],
              [104.77901564900006, 20.582972263000073],
              [104.78049241700003, 20.584089545000062],
              [104.78198079400008, 20.584502597000039],
              [104.78413997300004, 20.584534107000074],
              [104.78922117100008, 20.583669010000079],
              [104.79204470700002, 20.583710119000017],
              [104.79453477800008, 20.583824603000075],
              [104.79779164800009, 20.585185266000039],
              [104.79804032100006, 20.585287794000081],
              [104.80039257900007, 20.586257634000034],
              [104.80087420700005, 20.587282056000063],
              [104.80077194900011, 20.588454521000088],
              [104.80013041500005, 20.5921236],
              [104.79960265900009, 20.593915988000063],
              [104.79891519400005, 20.595314739000067],
              [104.79755434500008, 20.597251550000095],
              [104.797487819, 20.598776706000073],
              [104.79778798000004, 20.600737622000054],
              [104.79881768200006, 20.603804800000077],
              [104.79977717900005, 20.606088320000104],
              [104.79992278700011, 20.607342616000054],
              [104.79990357100003, 20.608516259000083],
              [104.79930039700004, 20.609837947000109],
              [104.79699119800003, 20.613873975],
              [104.79617713699999, 20.615388231000061],
              [104.79668114100004, 20.619580826000039],
              [104.79981580000005, 20.62192513400003],
              [104.80379582300003, 20.626550994000056],
              [104.80471329100008, 20.628429439000051],
              [104.80471144300002, 20.62987358200008],
              [104.80486236600007, 20.631751157000089],
              [104.80547434800008, 20.632762761000031],
              [104.80669959300012, 20.633775045000036],
              [104.80762008100007, 20.633920307000061],
              [104.80813917800003, 20.637718842000119],
              [104.81128853300002, 20.637840023000109],
              [104.81629144800007, 20.63967574200003],
              [104.81845800200001, 20.640567843000035],
              [104.82608152100009, 20.64375907900002],
              [104.83074545500007, 20.645009791000035],
              [104.83335698000006, 20.646651575000071],
              [104.83918761800003, 20.650266541000036],
              [104.84100860400007, 20.650703479000043],
              [104.84521873600006, 20.651771296000021],
              [104.84915822000006, 20.659330359000094],
              [104.851424509, 20.663079819000082],
              [104.85709718600003, 20.661713024000058],
              [104.86270562900009, 20.661753750000052],
              [104.86879876300003, 20.662662015000066],
              [104.87665708100008, 20.663614672000016],
              [104.88093413600002, 20.666374969000053],
              [104.885454205, 20.67084061100006],
              [104.88723736200011, 20.671061416000036],
              [104.89609875000011, 20.670364645000028],
              [104.90693474000007, 20.664354398000029],
              [104.91036886600008, 20.662641808000082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 56, code: "AD01", ten_tinh: "Thừa Thiên Huế" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [107.42811561200006, 16.710972491000042],
              [107.43533383400009, 16.705425120000058],
              [107.44291701900002, 16.699500137000101],
              [107.45367194400004, 16.691529308000064],
              [107.45945658200002, 16.687112362000086],
              [107.47111014300003, 16.677899897000081],
              [107.47762534000006, 16.672403371000129],
              [107.48922130300011, 16.663135702000083],
              [107.49523010600007, 16.658069770000061],
              [107.49811926600003, 16.655835698000132],
              [107.50693595700005, 16.649017545000078],
              [107.51316683200011, 16.644438002000058],
              [107.526722222, 16.634470100000073],
              [107.53053893200006, 16.631614118000115],
              [107.54616823400004, 16.620460500000078],
              [107.56157040400007, 16.609899737000063],
              [107.56426348300005, 16.608067696000091],
              [107.56697611300004, 16.606208546000097],
              [107.58179551800008, 16.596050557000105],
              [107.59040587200009, 16.59044647500005],
              [107.59511709, 16.587591026000048],
              [107.59831338100004, 16.585921625000118],
              [107.60240595900009, 16.584199792000028],
              [107.60574259100012, 16.58231436900008],
              [107.61067977600007, 16.578269993000028],
              [107.6121069110001, 16.576865491000078],
              [107.612600495, 16.575527319000024],
              [107.61830788800007, 16.57369627800005],
              [107.62117444300003, 16.572776562000065],
              [107.62410003700006, 16.571837855000112],
              [107.62535245000004, 16.572610318000081],
              [107.62763371100004, 16.572614276000053],
              [107.63584993100005, 16.56954720700012],
              [107.64928392000012, 16.563075191000017],
              [107.65057267400007, 16.562529025000039],
              [107.65365568200008, 16.560113117000071],
              [107.65502668800011, 16.558574747000065],
              [107.65534386900005, 16.557057432000107],
              [107.65850868500007, 16.556001875000078],
              [107.66079745700009, 16.555238467000066],
              [107.66173504400004, 16.556091041000087],
              [107.66273718400001, 16.556092613000125],
              [107.66593444700004, 16.554344710000102],
              [107.67314606600006, 16.548976222000086],
              [107.67922812300004, 16.54475426500008],
              [107.68637442500008, 16.540654564000064],
              [107.69060633000001, 16.537789573000069],
              [107.70113897100001, 16.530369762000063],
              [107.70696937000005, 16.52614664400005],
              [107.71515061100008, 16.52002247300004],
              [107.72518126600002, 16.511996342000131],
              [107.73449017800002, 16.504603511000049],
              [107.74470621200001, 16.497302011000059],
              [107.75908983600006, 16.486226883000121],
              [107.76460431600006, 16.482304042000081],
              [107.77221872200002, 16.475995684000019],
              [107.78130527100008, 16.468449420000027],
              [107.79430747000004, 16.45743090400007],
              [107.80126240800004, 16.451392867000081],
              [107.81670586900002, 16.43801780400009],
              [107.83195954400001, 16.424581001000057],
              [107.84370388200007, 16.414190868000041],
              [107.84714858500007, 16.41114303200002],
              [107.86196003900007, 16.397764158000072],
              [107.87714496200006, 16.384444888000083],
              [107.88487754700002, 16.377648908000083],
              [107.88832075800006, 16.375111959000051],
              [107.89414251100001, 16.371246304000039],
              [107.8975538920001, 16.369343878000016],
              [107.90062069900009, 16.368136384000117],
              [107.90400030700003, 16.366989424000074],
              [107.90669171200005, 16.365539889000047],
              [107.91270106700007, 16.360464870000065],
              [107.91471699800007, 16.358742538000023],
              [107.92108795000007, 16.355088339000098],
              [107.92290332800009, 16.352973350000077],
              [107.92584564400008, 16.348924427000085],
              [107.92935088800003, 16.345056997000107],
              [107.93110339700009, 16.343304595000063],
              [107.933012117, 16.342066027000079],
              [107.93520224100003, 16.3412204400001],
              [107.93845583500011, 16.340858715000103],
              [107.94095844600005, 16.341040757000066],
              [107.94227215800004, 16.341706031000044],
              [107.94302267300006, 16.342733838000086],
              [107.94302241500003, 16.34364054200006],
              [107.94302152200005, 16.346783791000071],
              [107.94377200900006, 16.347992936000111],
              [107.94477307600002, 16.348114089000099],
              [107.94602458900002, 16.347630832000078],
              [107.94840293400006, 16.344790402000072],
              [107.951094, 16.342191803000091],
              [107.95256486199999, 16.339925362000059],
              [107.954192366, 16.336540674000055],
              [107.95876065800006, 16.331584922000083],
              [107.9637666250001, 16.326870932000048],
              [107.97036768700005, 16.3219454810001],
              [107.97271399300011, 16.319981267000024],
              [107.97427816400004, 16.318651612000053],
              [107.97527918100008, 16.318228599000051],
              [107.977781609, 16.317805742000054],
              [107.98216091400008, 16.316294959000018],
              [107.98973065000004, 16.314784264000082],
              [107.99755048000003, 16.314784495000097],
              [108.00258643900005, 16.314331139000068],
              [108.005319818, 16.314582647000094],
              [108.00880556500002, 16.315092851000124],
              [108.01324209200001, 16.317031834000062],
              [108.01810133900008, 16.320297516000082],
              [108.0220100600001, 16.323665250000104],
              [108.02338355300006, 16.325910503000074],
              [108.02401761800003, 16.328053766000039],
              [108.02327835200006, 16.32968686300007],
              [108.02063772200006, 16.332340782000095],
              [108.017310298, 16.33412718400011],
              [108.01509202900004, 16.33606655500008],
              [108.01424706300003, 16.338107878000052],
              [108.0146698070001, 16.340557368000042],
              [108.01572645400005, 16.343517126000059],
              [108.01762817000011, 16.345047926000063],
              [108.02026929600005, 16.345251820000058],
              [108.02185387600007, 16.344435159000078],
              [108.025128255, 16.33984195700009],
              [108.02713520000006, 16.338004575000028],
              [108.02967046100004, 16.337289797000039],
              [108.03178318500005, 16.336779178000072],
              [108.03273371100001, 16.33535014900005],
              [108.03273339400008, 16.333410946000093],
              [108.03357803200005, 16.330655104000066],
              [108.03621813600009, 16.326163883000078],
              [108.0393859590001, 16.320039512000051],
              [108.03991361400006, 16.317487823000043],
              [108.03991328900003, 16.315854813000016],
              [108.03875107400006, 16.314324070000019],
              [108.03695511100005, 16.312793430000028],
              [108.036585164, 16.31141563200007],
              [108.0370072580001, 16.309170156000086],
              [108.03806300400007, 16.306516312000063],
              [108.04091355900002, 16.300187824000112],
              [108.04267516600002, 16.29748726000004],
              [108.04830427100011, 16.288857184000079],
              [108.05870251500008, 16.272473277000095],
              [108.06598568500007, 16.262581341],
              [108.07880893700005, 16.244154331000082],
              [108.08345205100008, 16.236283129000114],
              [108.08587897500007, 16.232607828000042],
              [108.08703939300007, 16.230259862000104],
              [108.08693319400008, 16.228830992],
              [108.08640492900007, 16.228014683000055],
              [108.08482114400003, 16.227913249000011],
              [108.08270970200003, 16.228424398000115],
              [108.08059866000008, 16.229956179000027],
              [108.07912157800007, 16.232712488000089],
              [108.07521811200004, 16.24118529200009],
              [108.07080340300007, 16.247301456000059],
              [108.06889805200007, 16.250236695000098],
              [108.06717087700008, 16.253001955000101],
              [108.06632625900009, 16.253461509000111],
              [108.06579826400011, 16.253410639000073],
              [108.06490053600007, 16.25290058800006],
              [108.06410833800007, 16.252186372000033],
              [108.06262998900006, 16.252135775000021],
              [108.06167983600005, 16.252799471000046],
              [108.0612049150001, 16.25366715800007],
              [108.06014947900007, 16.255402558000043],
              [108.05941066300005, 16.256627540000054],
              [108.05898897600007, 16.259026178000099],
              [108.05798631500006, 16.260863616000073],
              [108.05605971199999, 16.262981969000109],
              [108.05468712000003, 16.263798840000021],
              [108.05088571300008, 16.26476938700003],
              [108.04655622500007, 16.265586886000079],
              [108.04502496700012, 16.265485147000014],
              [108.04312392700002, 16.264566956000067],
              [108.04053634500008, 16.262883393000038],
              [108.03887299400003, 16.262194763000082],
              [108.03586329600004, 16.261735996000048],
              [108.03480723400006, 16.261378943000082],
              [108.03364541700003, 16.260103319000052],
              [108.03237788800006, 16.258011187000051],
              [108.0320607940001, 16.256123037000059],
              [108.03264075700004, 16.251121783000052],
              [108.03287773700002, 16.24742189500008],
              [108.033827495, 16.244002572000049],
              [108.03651889700002, 16.237572044000089],
              [108.03760027800006, 16.23280031300007],
              [108.03844455200002, 16.230707828000043],
              [108.04118907200008, 16.227492283000089],
              [108.04636174900006, 16.223816880000093],
              [108.0499508720001, 16.221366508000031],
              [108.05480644700003, 16.217486840000014],
              [108.05670677600007, 16.217282216000051],
              [108.05976899600003, 16.218914433000094],
              [108.06335957500002, 16.221873299000045],
              [108.06716121900007, 16.224219647000027],
              [108.07138501700005, 16.225851329000115],
              [108.07402451500005, 16.225748371000037],
              [108.07888085400005, 16.224725990000088],
              [108.08320869400002, 16.222376856000054],
              [108.08521444300007, 16.22176367800003],
              [108.08700934000007, 16.221865024000067],
              [108.08964964400005, 16.223701114000022],
              [108.09144520200002, 16.225231337000068],
              [108.09313464200008, 16.225536807000118],
              [108.09556281500009, 16.22512748000009],
              [108.09925724800006, 16.223288630000084],
              [108.10654004600011, 16.219100463000032],
              [108.11171176400009, 16.21624000500006],
              [108.11561656600006, 16.213584237000056],
              [108.11778064800005, 16.213225833000067],
              [108.11957584000008, 16.213939299000032],
              [108.12263864500001, 16.215774751000112],
              [108.12506753100007, 16.216794005000068],
              [108.13013547300008, 16.217199257000026],
              [108.13256353700008, 16.216891577000055],
              [108.13615210600008, 16.21535835100012],
              [108.13807793200009, 16.214004755000119],
              [108.13939737400001, 16.213646674000067],
              [108.14055880700001, 16.213799015000063],
              [108.141298335, 16.214512988000074],
              [108.14193250400007, 16.215533216000019],
              [108.14230412900008, 16.218492868000034],
              [108.14304444500006, 16.220278520000072],
              [108.14452342500005, 16.22150231500008],
              [108.14563230100003, 16.221909828000044],
              [108.14684645800004, 16.221909001000064],
              [108.14806032300002, 16.221499907000059],
              [108.15027641100006, 16.220069453000093],
              [108.15280846900005, 16.21766913000009],
              [108.15423320600007, 16.21695365500004],
              [108.15687198500009, 16.216135221000094],
              [108.15898242100009, 16.214755795000087],
              [108.16257008700002, 16.212405604000054],
              [108.16515560500008, 16.211127822000023],
              [108.16753028400009, 16.210258436000032],
              [108.16890273800004, 16.210257361000032],
              [108.17043406100001, 16.210868536000064],
              [108.17233574400007, 16.212449035000098],
              [108.17370861100005, 16.212907224000084],
              [108.17645386, 16.213262216000054],
              [108.177932051, 16.213414095000097],
              [108.1790410410001, 16.213923502000057],
              [108.17967503400007, 16.21453536500006],
              [108.17993966300003, 16.215300635000048],
              [108.18025735100011, 16.216372056000061],
              [108.18115512600005, 16.216779563000024],
              [108.18236911300005, 16.216625438000065],
              [108.18558745000006, 16.214734471000014],
              [108.1880407140001, 16.213277904000023],
              [108.190731951, 16.212305910000069],
              [108.19227690900003, 16.211582421000067],
              [108.19244818400009, 16.211386363000102],
              [108.18959793200005, 16.210130737000036],
              [108.18368422800006, 16.208648540000056],
              [108.17623147100008, 16.206776057000113],
              [108.17110222400005, 16.20464028100006],
              [108.16761919300008, 16.204016782000075],
              [108.16513396700006, 16.204436457000071],
              [108.15703700700007, 16.206869226],
              [108.15501196300002, 16.206479297000072],
              [108.15144641400006, 16.203820305000072],
              [108.14739521800007, 16.201396424000066],
              [108.14318280800005, 16.199911946000078],
              [108.13994174900003, 16.197643945],
              [108.13491741800007, 16.192872047000058],
              [108.13022015600008, 16.18954960600005],
              [108.12700894400004, 16.186570937000063],
              [108.12386131000001, 16.184504607000072],
              [108.12033656100004, 16.183046738000058],
              [108.11744161100006, 16.182440053000043],
              [108.11410654100007, 16.182441850000103],
              [108.11165277900008, 16.183051426000091],
              [108.10919913400002, 16.183904283000047],
              [108.10599095000005, 16.185913228000089],
              [108.10209026300006, 16.187435819000129],
              [108.09881819000006, 16.187741497000019],
              [108.09699302900003, 16.187194872000084],
              [108.09472684800002, 16.185492684000025],
              [108.09264905400005, 16.182877974000043],
              [108.09145288300003, 16.181601093000083],
              [108.08994253100012, 16.181297583000017],
              [108.08770512400008, 16.181839279000073],
              [108.08481464600003, 16.182953297000054],
              [108.08136096800008, 16.183725134000056],
              [108.07843842900003, 16.183918842000097],
              [108.07544931800007, 16.183727291000061],
              [108.07153017200008, 16.183214964000037],
              [108.06814273900002, 16.183601316000114],
              [108.06442334000012, 16.184501382000072],
              [108.06249700700008, 16.18430933100008],
              [108.06103542200002, 16.183410823000074],
              [108.05897562700002, 16.181099885],
              [108.05691625400007, 16.180073104000044],
              [108.05512288100002, 16.180137776000066],
              [108.05246643600005, 16.181807871000053],
              [108.04728632100009, 16.185340552000049],
              [108.04031264500003, 16.189322914000037],
              [108.03598578500005, 16.19166872000006],
              [108.03449038600004, 16.192236867000084],
              [108.0321937800001, 16.192546995000079],
              [108.029576703, 16.192857133000068],
              [108.02658581700007, 16.193735197000066],
              [108.02295393800004, 16.194303518000105],
              [108.01873452100007, 16.194975092000071],
              [108.01569006400005, 16.194872082000074],
              [108.01376724100004, 16.194614074000043],
              [108.01221835200005, 16.195182069000055],
              [108.01072291900003, 16.196885872000074],
              [108.00858654400002, 16.199209215000018],
              [108.00590015800006, 16.203357196000034],
              [108.0049452870001, 16.204741691],
              [108.003632301, 16.206087728000092],
              [108.00180204400009, 16.206856898000055],
              [107.99937494100008, 16.207702973000067],
              [107.99666928900004, 16.208933585000104],
              [107.99300866800012, 16.210087222000077],
              [107.99109874300009, 16.21101014100006],
              [107.98906937500004, 16.213125203000089],
              [107.98739812500006, 16.214394208000016],
              [107.98612484600004, 16.214432590000079],
              [107.98449351800006, 16.213586411000058],
              [107.98201314400009, 16.211799156000062],
              [107.97966665700002, 16.209573588000033],
              [107.97855998100002, 16.207305334000033],
              [107.97789605700007, 16.205251093000058],
              [107.97727638000003, 16.203624809000061],
              [107.97608106800008, 16.202469203000099],
              [107.974620116, 16.201399151000118],
              [107.97276065700007, 16.200671402000033],
              [107.97081266400009, 16.199900837000044],
              [107.96939610900003, 16.198317207000024],
              [107.96793543600006, 16.195920454000131],
              [107.96629750900007, 16.194636345000021],
              [107.96328709400004, 16.193565966],
              [107.95961261700003, 16.192452625000087],
              [107.95642524600001, 16.191039753000069],
              [107.95434461900007, 16.190183418000089],
              [107.95257368500009, 16.190268627000123],
              [107.95035991500006, 16.190824464000016],
              [107.94797592400008, 16.191959486000052],
              [107.94518675300006, 16.193773136000097],
              [107.94245134000003, 16.194964700000099],
              [107.93864346400009, 16.195637541000082],
              [107.93478198300008, 16.196154787000069],
              [107.93140297000009, 16.197190502000055],
              [107.92818462900007, 16.198744601000037],
              [107.92518040700003, 16.201231812000039],
              [107.92228342500005, 16.203615347000074],
              [107.91820522100002, 16.207671476000129],
              [107.91548235900007, 16.210374161000104],
              [107.91317497100002, 16.213734644000063],
              [107.91067898500009, 16.215962370000092],
              [107.90962029700007, 16.216400377000092],
              [107.90829745000005, 16.215815226000082],
              [107.90693673700008, 16.215376192000086],
              [107.9050466100001, 16.215302290000096],
              [107.90387488700006, 16.21493640300011],
              [107.90277905600007, 16.213985945000076],
              [107.90157044400007, 16.2117931820001],
              [107.90043701700009, 16.210477328000117],
              [107.89782955800008, 16.208722322000121],
              [107.89476856700003, 16.206893996000105],
              [107.89314348200004, 16.206162453000061],
              [107.89170703100002, 16.206161734000091],
              [107.88985431900004, 16.206964608000085],
              [107.88887141700006, 16.20707371500005],
              [107.88807776500003, 16.206744465000078],
              [107.88788943200008, 16.205538647000047],
              [107.88765712100005, 16.203775644000103],
              [107.88643649200003, 16.202151540000017],
              [107.88532999000006, 16.201265434000064],
              [107.88342156900006, 16.200895439000092],
              [107.88029179100002, 16.200266466000102],
              [107.87697159300011, 16.198936303],
              [107.87414772800005, 16.197532589000026],
              [107.87330861700008, 16.196462093],
              [107.87323295500003, 16.195392036000023],
              [107.87437929600003, 16.193474069000047],
              [107.87564030000009, 16.191297894000023],
              [107.87591036200004, 16.190353914000077],
              [107.87541472099998, 16.189431207000027],
              [107.87411743300002, 16.188618714000121],
              [107.87026331000008, 16.186956063000046],
              [107.86667624300003, 16.185588677000062],
              [107.86560807700002, 16.18470249100001],
              [107.86564687100012, 16.183780094000078],
              [107.86667825400011, 16.182563138000106],
              [107.86847329000003, 16.180903877000041],
              [107.87011564700003, 16.17920761700011],
              [107.87165411800005, 16.176527292000031],
              [107.87298174700003, 16.173748580000066],
              [107.87409015, 16.171277142000037],
              [107.87432021900003, 16.16958001400004],
              [107.87423472200005, 16.166283841000073],
              [107.87378246500002, 16.163209979000023],
              [107.87315935400007, 16.161014190000067],
              [107.8719112890001, 16.159586427000058],
              [107.87015197700002, 16.15865231500004],
              [107.8673705210001, 16.158156639000062],
              [107.86396448400009, 16.157825197000086],
              [107.86135370800008, 16.156835584000092],
              [107.85908388500005, 16.155407083000028],
              [107.85783586800008, 16.154089004000035],
              [107.85732646500003, 16.151948130000093],
              [107.8567616720001, 16.147886206000095],
              [107.85613993500009, 16.144098682000063],
              [107.855914678, 16.141573783000048],
              [107.856143241, 16.139488284000066],
              [107.85705235000009, 16.138281400000039],
              [107.85790461300007, 16.137184251000036],
              [107.85801907100004, 16.135867067000028],
              [107.85716920800007, 16.13361618600004],
              [107.85495854700012, 16.12927873100007],
              [107.85331644900006, 16.126113412000095],
              [107.85312859000005, 16.124007191000089],
              [107.8537935480001, 16.121168997],
              [107.8545063920001, 16.117552504000088],
              [107.85427115500009, 16.11549203100013],
              [107.85237875200001, 16.113476215000055],
              [107.84953980800003, 16.11100188100005],
              [107.84622769800009, 16.108252454000116],
              [107.84130624600002, 16.105135513000057],
              [107.83700011900007, 16.102385205000047],
              [107.83363978300012, 16.099695032000035],
              [107.83102720900006, 16.096756132000053],
              [107.829215682, 16.094266528000034],
              [107.82706640800006, 16.092225325000072],
              [107.82517045400004, 16.089939569000045],
              [107.82390745400009, 16.087327987000052],
              [107.82285450700002, 16.085695533000077],
              [107.82074745200002, 16.083776667000024],
              [107.81998969400003, 16.082226021000068],
              [107.819859455, 16.080601697000048],
              [107.82024453400007, 16.078471143000073],
              [107.82091655800006, 16.076804058000072],
              [107.82163620800002, 16.075414946000066],
              [107.8225474720001, 16.073979674000057],
              [107.82302780700005, 16.07240507500007],
              [107.82288506700004, 16.071339518000102],
              [107.82197607600007, 16.070227],
              [107.81991886300003, 16.067770134000092],
              [107.81867583300003, 16.065360261000095],
              [107.81772043100003, 16.062672689000031],
              [107.81672917800009, 16.058448806000044],
              [107.81543433200005, 16.056189611000079],
              [107.81362051700009, 16.054180846000051],
              [107.81120018600002, 16.053593303000113],
              [107.80851993400003, 16.053590929000094],
              [107.80653112100006, 16.053840042000012],
              [107.80506056300007, 16.054591410000093],
              [107.80332931300009, 16.056680656000118],
              [107.80029976300006, 16.060106819000097],
              [107.7969252760001, 16.062529009000045],
              [107.79121580700003, 16.065283418000128],
              [107.7877548220001, 16.067538117000076],
              [107.78509931100004, 16.068177436000042],
              [107.78126898200001, 16.06836794000008],
              [107.77504361100006, 16.068194238000011],
              [107.77210521900004, 16.067755846000061],
              [107.77248435500005, 16.064938118000072],
              [107.77253443600003, 16.062210940000021],
              [107.77183187900008, 16.060164772000093],
              [107.77042197000003, 16.058301497000024],
              [107.76840601100011, 16.055173744000051],
              [107.76630815500009, 16.05306170500009],
              [107.76396691100008, 16.051887053000065],
              [107.76009106000004, 16.050554381000026],
              [107.75621678900011, 16.047971411000091],
              [107.75121182500006, 16.045308925000064],
              [107.74418739700008, 16.042800201000063],
              [107.73522680100004, 16.038569789000064],
              [107.72804358600007, 16.034341268000048],
              [107.72489741700008, 16.031446087000042],
              [107.72377231400006, 16.027303237000098],
              [107.72466817500003, 16.02191274000004],
              [107.72507896400001, 16.016834179000035],
              [107.72419255700002, 16.011912342000031],
              [107.72160595000005, 16.008369249000062],
              [107.716479597, 16.004282001000099],
              [107.71099691300005, 16.000784107000051],
              [107.7068851610001, 15.998074550000039],
              [107.70378797400008, 15.996546242000063],
              [107.70089260000006, 15.996001433000105],
              [107.69667530400008, 15.996044671000041],
              [107.69194960100005, 15.996283758000105],
              [107.68671487000009, 15.997111957000063],
              [107.68173406300009, 15.997989562000024],
              [107.67751474800004, 15.999261510000016],
              [107.67630535800006, 16.000494622000083],
              [107.67415471800003, 16.00386419800008],
              [107.67039039500006, 16.010085232000023],
              [107.66797025700001, 16.014146201000031],
              [107.66447906700006, 16.017167672000099],
              [107.66045318800012, 16.019150443000015],
              [107.65935653500006, 16.019395348000032],
              [107.65660793000011, 16.020009155000103],
              [107.65222764600006, 16.020088587000103],
              [107.64856097000008, 16.021033928000072],
              [107.64498135900001, 16.023276590000087],
              [107.63900384800006, 16.027218616000113],
              [107.63374205600007, 16.030214566000019],
              [107.62959810600005, 16.031594317000049],
              [107.62497796400004, 16.032048585000069],
              [107.62131366000008, 16.032427383000055],
              [107.61932082800004, 16.033348438000061],
              [107.61812266100003, 16.035195452000025],
              [107.61795706700009, 16.038508209000035],
              [107.62033710200011, 16.043443482000086],
              [107.62287858900007, 16.047300341000081],
              [107.62382474300001, 16.050288825000081],
              [107.62310882300002, 16.054905499000057],
              [107.62174582700004, 16.058836889000091],
              [107.61808984100006, 16.06495746700007],
              [107.60948036100004, 16.061893549000047],
              [107.60215639800005, 16.059763540000027],
              [107.59925508300006, 16.058529314000047],
              [107.59544531600005, 16.057819986000062],
              [107.589215338, 16.057340025000052],
              [107.58322748299999, 16.056801845000024],
              [107.57669643700007, 16.055677271000057],
              [107.57228138400006, 16.055200389000035],
              [107.56732290900005, 16.054254200000081],
              [107.56405867500007, 16.053135800000092],
              [107.56013071700005, 16.05125528100011],
              [107.55397009400004, 16.046912407000043],
              [107.55173610500003, 16.045035246000069],
              [107.55107475700002, 16.043219873000055],
              [107.55186544300007, 16.041290499000112],
              [107.55380904000003, 16.037725082000073],
              [107.55750877300008, 16.033227019000051],
              [107.55866240600005, 16.031239857000031],
              [107.55887411900008, 16.028882106000083],
              [107.55882938800002, 16.024959216000042],
              [107.55862097100001, 16.022687691000037],
              [107.55702599200002, 16.02015522700011],
              [107.55452558700009, 16.016949874000105],
              [107.550691183, 16.013896546000069],
              [107.54701414800004, 16.01208225900006],
              [107.54456138800005, 16.011509298000078],
              [107.54114647300003, 16.01176010700005],
              [107.53826373000007, 16.012579778000102],
              [107.53500432900003, 16.013956295000042],
              [107.52864871900006, 16.016014375000061],
              [107.52421487000002, 16.017290622000054],
              [107.52163039200005, 16.017213434000062],
              [107.51830933300008, 16.016348650000097],
              [107.5173328170001, 16.016278957000019],
              [107.51520829, 16.016127327000085],
              [107.51143950300009, 16.017190480000032],
              [107.50818620400003, 16.018826310000108],
              [107.50574325800005, 16.021249870000084],
              [107.50388662500009, 16.025460913000074],
              [107.50150781300009, 16.031742668000071],
              [107.49906230200007, 16.035094942000057],
              [107.49484365500007, 16.038657364000116],
              [107.49195843500009, 16.040579606000094],
              [107.48878046000004, 16.041429416000064],
              [107.48656441400001, 16.041567015000076],
              [107.48332211100006, 16.038701395000118],
              [107.4812595220001, 16.03662448200005],
              [107.47816099300006, 16.035330935000047],
              [107.47380924300006, 16.033176928000081],
              [107.47027109200006, 16.030596168000066],
              [107.46540490900004, 16.02599312400006],
              [107.46432031900009, 16.026998447000018],
              [107.46312995100001, 16.028324119000011],
              [107.46233259400003, 16.028900624000109],
              [107.46138724000006, 16.029336988000061],
              [107.45802367300011, 16.02937557300006],
              [107.45700432300006, 16.029741847000075],
              [107.45638905000004, 16.030817085000045],
              [107.45578828400008, 16.033358239000073],
              [107.45484845100003, 16.0349504920001],
              [107.45419814000005, 16.035864985000089],
              [107.45256119200005, 16.037238436000045],
              [107.44986272300007, 16.038427118000072],
              [107.44665782100002, 16.039161441000083],
              [107.44532397900005, 16.039920896000034],
              [107.445045292, 16.040668306000057],
              [107.44506118400007, 16.041970540000072],
              [107.44529048000004, 16.045037890000025],
              [107.44486534300003, 16.049508134000057],
              [107.4445270520001, 16.05323317400007],
              [107.44453727800007, 16.054070316000058],
              [107.44464342600006, 16.054906365000043],
              [107.445330852, 16.05620092600013],
              [107.44649908400002, 16.057583010000087],
              [107.4470849090001, 16.058413580000064],
              [107.44781322100005, 16.059311560000111],
              [107.44895484600009, 16.061004590000039],
              [107.449538847, 16.062572356000082],
              [107.44988592200012, 16.063819249000041],
              [107.45034638600004, 16.06509594000007],
              [107.45127819900003, 16.067346427000118],
              [107.45222963600006, 16.068976271000054],
              [107.45315743500001, 16.069787086000083],
              [107.45402297200012, 16.070334152000051],
              [107.45621066200003, 16.071189600000046],
              [107.4590131910001, 16.072649757000065],
              [107.45940381700009, 16.073203432000049],
              [107.45941296800004, 16.073947555000068],
              [107.45932503900005, 16.07459976100003],
              [107.45839538300005, 16.077029189000044],
              [107.45689919400007, 16.080209344000096],
              [107.454989858, 16.082785849000025],
              [107.45395160800007, 16.084193199000119],
              [107.45284218100002, 16.085837624000106],
              [107.45028359800007, 16.088471773],
              [107.44826515100004, 16.09052495500007],
              [107.44659405200007, 16.091030575000076],
              [107.44563448900007, 16.090867895000116],
              [107.44453931700005, 16.089560874000064],
              [107.44340122000003, 16.086736502000051],
              [107.44300605300002, 16.085810728000105],
              [107.44173943800003, 16.084243706000031],
              [107.43990512200004, 16.08333436300002],
              [107.43593244800006, 16.082153261000087],
              [107.43293671700008, 16.08155072600006],
              [107.42969760500004, 16.080380642000058],
              [107.42757553000006, 16.079474438000126],
              [107.42565439500008, 16.07931018300004],
              [107.42162361000008, 16.079262834000119],
              [107.41912906100001, 16.079291068000053],
              [107.41740880700004, 16.079868716000064],
              [107.41626871800004, 16.080811904000122],
              [107.41427633400004, 16.082695029000057],
              [107.41170935200009, 16.084677649000056],
              [107.410694839, 16.084987864000055],
              [107.40893247800003, 16.085174120000097],
              [107.40720317800002, 16.085007534000084],
              [107.40594577300007, 16.084184407000102],
              [107.40360834900012, 16.081326727000089],
              [107.40185335700005, 16.079020660000062],
              [107.40165253300003, 16.078278668000046],
              [107.40173059300001, 16.076789288],
              [107.40188731700002, 16.074818508000099],
              [107.40188114200004, 16.073345430000074],
              [107.40183431600006, 16.071665046000035],
              [107.401632808, 16.069823917000022],
              [107.40126625000005, 16.067437101000067],
              [107.40043502600003, 16.065593152000091],
              [107.39928825300008, 16.064168426000066],
              [107.39773022400004, 16.063775147000037],
              [107.39674106500009, 16.063717771000086],
              [107.39547198000007, 16.063868890000066],
              [107.39392132200001, 16.064091613000109],
              [107.39187322300002, 16.064046054000066],
              [107.39095301300009, 16.063850966000089],
              [107.38932522000005, 16.063526849000098],
              [107.38784720900006, 16.063268985000079],
              [107.38698376600003, 16.063278614000076],
              [107.38575097500008, 16.064501782000029],
              [107.38310344200005, 16.067787431000099],
              [107.37988237700009, 16.07126549300002],
              [107.37703945800004, 16.074274150000086],
              [107.37608442300007, 16.07465689800005],
              [107.37560359800008, 16.074569216000079],
              [107.373670331, 16.073381242000018],
              [107.372021616, 16.07191100200005],
              [107.37123751600005, 16.070524188000057],
              [107.37024501500008, 16.067744185000073],
              [107.36839128200009, 16.065159784000024],
              [107.36635561600006, 16.063414658000049],
              [107.36451955100003, 16.062318542000042],
              [107.36240337900009, 16.061876746000081],
              [107.36086832700008, 16.061893680000061],
              [107.35916259900009, 16.06235537600007],
              [107.35733110600006, 16.062717852000041],
              [107.35507278900008, 16.062811170000124],
              [107.35094668600001, 16.062700641000035],
              [107.34605367100008, 16.062754334000125],
              [107.34480424900009, 16.062581948000073],
              [107.34364858700005, 16.062222468000087],
              [107.34238937000003, 16.061212866000041],
              [107.3416000820001, 16.059360796000099],
              [107.34043465200004, 16.058164088000112],
              [107.33821282400005, 16.056885873000049],
              [107.33561052600004, 16.055890890000057],
              [107.33320879000009, 16.055637971000088],
              [107.33206076900005, 16.055929576000061],
              [107.33148947700003, 16.056307940000103],
              [107.32972592800004, 16.058080069000091],
              [107.32858493400005, 16.059462377000067],
              [107.32794528900006, 16.060951773000021],
              [107.32767260600005, 16.062257239000118],
              [107.32772671300009, 16.066908449000024],
              [107.32751968200002, 16.067726521000033],
              [107.32654896499999, 16.069104081000063],
              [107.32501923300008, 16.069585886000048],
              [107.32334997500007, 16.070119237000121],
              [107.31951642800004, 16.070532982000074],
              [107.31760178899999, 16.070925869000071],
              [107.31579062900006, 16.071968896000044],
              [107.31408294100008, 16.073662026000065],
              [107.30568959000007, 16.082265550000052],
              [107.30436983700012, 16.08432659100005],
              [107.30249469600005, 16.088161310000082],
              [107.30080067200004, 16.09106369700006],
              [107.29790273700007, 16.095472351000062],
              [107.29578760200003, 16.097444160000052],
              [107.29380018700009, 16.099884480000078],
              [107.29276703700005, 16.10184936500012],
              [107.2922654110001, 16.104134172000073],
              [107.29171098600007, 16.106000874000067],
              [107.29002416700003, 16.109554405000011],
              [107.28805864900002, 16.111529469000097],
              [107.28664871800008, 16.112659161000039],
              [107.28383384000006, 16.114713175000084],
              [107.28289244000005, 16.116787848000058],
              [107.28255020700007, 16.119031281000048],
              [107.28217376500001, 16.119686567000088],
              [107.28159792800005, 16.119692724000068],
              [107.28053583700003, 16.119145858000117],
              [107.2773485380001, 16.117412165000111],
              [107.27580659400006, 16.116870391000049],
              [107.27244756800005, 16.116906189000076],
              [107.27014633100006, 16.117116766],
              [107.26833766500008, 16.118438531000031],
              [107.26634971699998, 16.120878664000045],
              [107.26522972500004, 16.123681724000072],
              [107.26312043100005, 16.128123470000055],
              [107.26257836400005, 16.131106461000044],
              [107.26167560300004, 16.136140150000067],
              [107.26111660900007, 16.137634702000092],
              [107.26025592200008, 16.137922946000046],
              [107.25929608600008, 16.137933118000092],
              [107.25631215600001, 16.137220418000098],
              [107.25390624900007, 16.136687633000065],
              [107.25271014600006, 16.137025924000078],
              [107.25108893600003, 16.137973442000053],
              [107.24804580300004, 16.140517628000062],
              [107.2460521360001, 16.142492494000038],
              [107.24530207800004, 16.144082069000106],
              [107.24475028800003, 16.146227793],
              [107.24399811900005, 16.147631318000052],
              [107.24304766200002, 16.148478687000086],
              [107.24180188900007, 16.148677883000047],
              [107.23969220000004, 16.148886152000053],
              [107.23747919699998, 16.148444205000075],
              [107.23419986000007, 16.147083015000071],
              [107.22966436800007, 16.144990593000053],
              [107.22619003300011, 16.143352187000026],
              [107.2235858770001, 16.142262892000048],
              [107.21964102400003, 16.141466645000065],
              [107.21769125800009, 16.141495129000113],
              [107.21684519000007, 16.141640856],
              [107.21557660200008, 16.141733613000042],
              [107.21430826500003, 16.142020656000078],
              [107.2125957200001, 16.142423796000038],
              [107.2108761800001, 16.143185974000041],
              [107.20830511100009, 16.145073473000068],
              [107.20630064700006, 16.146117697000051],
              [107.20476788600007, 16.14641268700003],
              [107.20332801900004, 16.146427588000023],
              [107.20140509900001, 16.146168336000066],
              [107.19822813300004, 16.145363756000016],
              [107.19601828100008, 16.145200453000093],
              [107.19384772800008, 16.145658351000058],
              [107.19245424000007, 16.146080633000089],
              [107.19085522300001, 16.1471144550001],
              [107.1876916750001, 16.14982670600007],
              [107.18544936200001, 16.151492879000081],
              [107.18436137200005, 16.152137927000076],
              [107.18291720200003, 16.153099935000093],
              [107.18142536600004, 16.153910329000084],
              [107.179283286, 16.155560528000109],
              [107.17604685100009, 16.158105792000029],
              [107.17433311300005, 16.159425902000102],
              [107.17367229600009, 16.160456132000078],
              [107.17254469800005, 16.162700686000072],
              [107.17122201400012, 16.164668096000057],
              [107.16951630500003, 16.166732456000062],
              [107.16837036400008, 16.167302389000056],
              [107.16539423500004, 16.167332691000077],
              [107.16280516800003, 16.167638151000041],
              [107.15945310800006, 16.16841655100005],
              [107.15764114000007, 16.169551465000076],
              [107.15612523200005, 16.171427713000064],
              [107.15565932900006, 16.17273505400005],
              [107.15567042500008, 16.173758425000081],
              [107.15598269000007, 16.175988324000024],
              [107.15584826600005, 16.176873615000083],
              [107.15538437200004, 16.178367027000014],
              [107.15427362500006, 16.182193101000038],
              [107.15371572400008, 16.183873545000083],
              [107.15286572900001, 16.185184780000036],
              [107.15086759100006, 16.186879797000039],
              [107.14896543500006, 16.18857384100005],
              [107.14773435200007, 16.190168039000064],
              [107.14727439800004, 16.19203358400005],
              [107.14732785700002, 16.193722223000059],
              [107.14798339800008, 16.196433008000085],
              [107.14811506300005, 16.198613611000034],
              [107.14768359600005, 16.200655715000067],
              [107.14498912000005, 16.204596385000066],
              [107.14327583200004, 16.209767235000072],
              [107.14283789600005, 16.21330810400012],
              [107.1428256680001, 16.216169404000077],
              [107.14393689000011, 16.219988955000098],
              [107.14645760600004, 16.223814111000046],
              [107.15137048700011, 16.228602467000108],
              [107.15290710700006, 16.231878626000103],
              [107.15289962600006, 16.23364990200011],
              [107.15201420900007, 16.234702383000062],
              [107.15038576200007, 16.236467247000071],
              [107.14974685000006, 16.237554753000083],
              [107.14953021300008, 16.238780184000042],
              [107.14987538500003, 16.240484722000033],
              [107.15155418100002, 16.243488947000095],
              [107.15361131800006, 16.248708764000064],
              [107.15444445700007, 16.251709630000022],
              [107.15485585900009, 16.254436316000032],
              [107.1547057060001, 16.256615787000058],
              [107.15406301700004, 16.25858893300007],
              [107.15108968900006, 16.26164291200007],
              [107.15027207600008, 16.263110905000119],
              [107.149533605, 16.265068308000075],
              [107.14895205600003, 16.268280950000054],
              [107.14740692500007, 16.269584324000071],
              [107.14595328800006, 16.27079536300009],
              [107.14417771200006, 16.271787583000027],
              [107.14228074700004, 16.272859046000043],
              [107.13860665900006, 16.275228229000049],
              [107.13539918300006, 16.277308260000019],
              [107.13289320900003, 16.279096779000035],
              [107.12776553000003, 16.282845551000058],
              [107.12246025100011, 16.28648223800004],
              [107.11939828900006, 16.288475270000013],
              [107.11741628100003, 16.289860240000031],
              [107.11368404300005, 16.292343391000053],
              [107.11012789000006, 16.29482473800001],
              [107.10598920800001, 16.297766869000085],
              [107.10237776800008, 16.300589927000075],
              [107.09849799100004, 16.303045880000063],
              [107.09645815700003, 16.304545004000111],
              [107.09383158700004, 16.306106819000107],
              [107.0912667150001, 16.307952380000046],
              [107.09011044800009, 16.308844329000038],
              [107.08835361800003, 16.310199522000076],
              [107.08555853400004, 16.312502349000098],
              [107.08257453900006, 16.313612528000121],
              [107.08028674100004, 16.314409900000015],
              [107.0785667910001, 16.313763288000068],
              [107.0764949360001, 16.312235381000036],
              [107.07418791100008, 16.31003080100006],
              [107.07221778200005, 16.307647086000095],
              [107.07092311500006, 16.305711359000071],
              [107.06899209200006, 16.304678981000094],
              [107.06614875, 16.304524108000074],
              [107.06427375000001, 16.304802230000078],
              [107.06151578800008, 16.305173331000056],
              [107.05952185700004, 16.305477188000062],
              [107.0570418720001, 16.305911946000059],
              [107.05506646900008, 16.306316919000082],
              [107.05330863000003, 16.306732176000068],
              [107.05202420000008, 16.307484120000112],
              [107.05108683100005, 16.307720742000065],
              [107.05032270100007, 16.307671287000055],
              [107.04985046700007, 16.307448344000086],
              [107.04914255900003, 16.307011792000111],
              [107.04860582700003, 16.306462364000126],
              [107.04807059100007, 16.305600334000033],
              [107.046856738, 16.304040097000083],
              [107.04498606700002, 16.301918],
              [107.04335066000007, 16.301098498000052],
              [107.04220811700003, 16.300931151000064],
              [107.0397763460001, 16.301514405000091],
              [107.03817337600006, 16.302977830000117],
              [107.03676851500009, 16.304086335000086],
              [107.03504387600003, 16.305311998000072],
              [107.03364081100003, 16.306040986000077],
              [107.0322385370001, 16.306603944000116],
              [107.03032923900003, 16.307923609000063],
              [107.02889847500005, 16.309292859000024],
              [107.02802968900002, 16.310574640000119],
              [107.02700615600006, 16.311741498000032],
              [107.02637174900006, 16.312017562000044],
              [107.02583470100004, 16.312080557000016],
              [107.0248757360001, 16.311781217000117],
              [107.02395580300001, 16.311404358000026],
              [107.02299010100003, 16.310449412000061],
              [107.02205869800007, 16.308954196000066],
              [107.02055910200006, 16.306423074000072],
              [107.01970934800002, 16.305119895000075],
              [107.01874173900006, 16.303972110000053],
              [107.01782264000005, 16.30367234800012],
              [107.01698634200004, 16.303680335000067],
              [107.01614158600005, 16.309984464000046],
              [107.0121145510001, 16.317220749000057],
              [107.00578300100007, 16.324727101000036],
              [106.99841561500004, 16.331250303000019],
              [106.99113014500004, 16.336115981000084],
              [106.98778400400003, 16.339596815000057],
              [106.98655884400009, 16.340952940000086],
              [106.98602372000005, 16.3417764470001],
              [106.98573305100007, 16.342889966000129],
              [106.98571379600007, 16.343971701000015],
              [106.98579071500004, 16.345607915000038],
              [106.98623345300008, 16.347591459000022],
              [106.98722849800008, 16.350446719000011],
              [106.98840707600007, 16.35353408600011],
              [106.98958215000005, 16.35627070700005],
              [106.99019484600011, 16.357141856000098],
              [106.99104552800006, 16.357659971000068],
              [106.99246894800007, 16.358084970000064],
              [106.99404185, 16.358362398000011],
              [106.99639794000008, 16.358457004000023],
              [106.99803003600002, 16.358616904000108],
              [106.99942477800005, 16.359188289000045],
              [107.00057917700009, 16.359878862000073],
              [107.00173658200002, 16.360861720000038],
              [107.00313909800006, 16.362193],
              [107.00392943800009, 16.362711636000071],
              [107.00605001700004, 16.363392974000043],
              [107.00768395100005, 16.363728173000069],
              [107.00846833100012, 16.363662228000031],
              [107.009069165, 16.363364194000063],
              [107.00960723200002, 16.362832906000065],
              [107.01080050700008, 16.361418438],
              [107.01151791100003, 16.360710056000102],
              [107.01193699600006, 16.360355284000057],
              [107.01253961, 16.360232604000124],
              [107.01290190600005, 16.360229143000105],
              [107.01338616600006, 16.360341439000067],
              [107.01399477100006, 16.360803310000094],
              [107.01490671700002, 16.361656665000091],
              [107.01523104100002, 16.362039670000073],
              [107.01620129800001, 16.364067562000081],
              [107.01681538300002, 16.365130313000087],
              [107.01760246100004, 16.366000011000011],
              [107.01989730000003, 16.367715672000124],
              [107.02267608900007, 16.369549657000078],
              [107.02475811600004, 16.370722376000032],
              [107.02711784600007, 16.372000562000011],
              [107.0298061, 16.373391829000106],
              [107.03225542000007, 16.374330609000062],
              [107.03328069200008, 16.374935892000053],
              [107.03417264800007, 16.375958850000046],
              [107.03428565900001, 16.379057974000055],
              [107.03408593600008, 16.384520268000102],
              [107.03410912000005, 16.386753822000074],
              [107.03541424300003, 16.388974977000089],
              [107.03800391600008, 16.391431870000069],
              [107.03957842900006, 16.394891361000049],
              [107.039599106, 16.396876742000096],
              [107.03912507600003, 16.400604292000054],
              [107.03838171600007, 16.403093478000038],
              [107.03891519900007, 16.405073882000032],
              [107.041507751, 16.407778864000107],
              [107.04387246900008, 16.413216192000043],
              [107.04468062600006, 16.416931268000035],
              [107.04599129400006, 16.41964865000007],
              [107.05262000900008, 16.428146665000028],
              [107.0552104560001, 16.430603249000072],
              [107.05830862200003, 16.432558432000107],
              [107.0626785800001, 16.433508280000098],
              [107.07632525, 16.438585660000093],
              [107.08454469800009, 16.439745069000097],
              [107.09711845400004, 16.440364261000084],
              [107.10919269900005, 16.442228643000064],
              [107.12605048800006, 16.445502167000114],
              [107.12677537900001, 16.446186618000077],
              [107.12737689400004, 16.446835618000044],
              [107.12776569800005, 16.447445820000084],
              [107.12828543600007, 16.448382238000114],
              [107.12867558200003, 16.44911525900001],
              [107.12935799800009, 16.450025104000062],
              [107.13085424200007, 16.45150716800012],
              [107.132551592, 16.45292208500004],
              [107.13378094100003, 16.454602131000051],
              [107.13416196300003, 16.455607284000081],
              [107.1342370670001, 16.456322607000025],
              [107.13418831600006, 16.458015662000044],
              [107.13334939400002, 16.461279143000063],
              [107.13198436300006, 16.465654655000073],
              [107.1314009660001, 16.467678662000118],
              [107.1302792540001, 16.470592739],
              [107.12962626700008, 16.472290881000085],
              [107.12906686900007, 16.473435808000133],
              [107.12775571700003, 16.475820545000097],
              [107.12490611700007, 16.479820525000029],
              [107.12378296700004, 16.48171979500006],
              [107.12292322800005, 16.483095594000119],
              [107.12166122900007, 16.484605679000097],
              [107.1189983400001, 16.487236631000087],
              [107.11719660300007, 16.488621962000046],
              [107.11408672100006, 16.490378556000081],
              [107.10993847600002, 16.492568734000109],
              [107.10686174600009, 16.494292343000083],
              [107.10498974900001, 16.495417882000126],
              [107.10358311200007, 16.496017942000037],
              [107.10237478500001, 16.496290497000118],
              [107.101365507, 16.496300643000069],
              [107.10061764300011, 16.495592063000061],
              [107.09965528200006, 16.493713837000087],
              [107.09842802400004, 16.492228870000055],
              [107.09795492000006, 16.492038312000098],
              [107.09741665700002, 16.492043720000105],
              [107.09675082700008, 16.492701391000075],
              [107.0964214210001, 16.49335569400003],
              [107.09622868000005, 16.494203925000051],
              [107.09598078700006, 16.495633848000054],
              [107.09552560200005, 16.497657178000047],
              [107.09529272700006, 16.498178621000115],
              [107.09418361400003, 16.500381539000038],
              [107.09348396400006, 16.501783006],
              [107.09342157700004, 16.502239331000098],
              [107.09336199600008, 16.502956026000071],
              [107.09347099300005, 16.503703590000015],
              [107.09374925300007, 16.504547107000086],
              [107.09375275400001, 16.504872569000014],
              [107.09341981700008, 16.505201403000044],
              [107.09310427800008, 16.505352729000045],
              [107.09268314700006, 16.505534285000053],
              [107.09106821100002, 16.505550454000058],
              [107.08911263200004, 16.505179404000089],
              [107.087558, 16.504543955000095],
              [107.08586880200005, 16.503909818000025],
              [107.08425317000004, 16.503860835000062],
              [107.08243847199999, 16.50407423300004],
              [107.08116556600001, 16.504607712000038],
              [107.08036644100007, 16.505396873000052],
              [107.07983787900011, 16.50631354000005],
              [107.07951187200005, 16.507293286000021],
              [107.07886114100003, 16.508332123000088],
              [107.07815378000004, 16.508768955000086],
              [107.07788601500009, 16.508901814000062],
              [107.07709160300008, 16.50910844400002],
              [107.07633123300005, 16.509211541000113],
              [107.07503917600009, 16.509300686000117],
              [107.07444297300005, 16.509306604000074],
              [107.07322659300004, 16.509464700000095],
              [107.07295971800008, 16.509518218000082],
              [107.07067653700008, 16.510132860000049],
              [107.06960703900005, 16.510489532000072],
              [107.06834439600003, 16.510821933000038],
              [107.06573385600004, 16.512149782000037],
              [107.06333137000011, 16.514061452000114],
              [107.060722599, 16.51611463600009],
              [107.05812937300001, 16.518539643000096],
              [107.056734788, 16.520311109000119],
              [107.05534637600006, 16.52266842300012],
              [107.05488838200009, 16.52390985500007],
              [107.05476068600009, 16.524562119000038],
              [107.05477238800009, 16.525668732000028],
              [107.05498598300008, 16.526773338000034],
              [107.0557441760001, 16.528458498000106],
              [107.05694499700007, 16.530627550000077],
              [107.05845731500007, 16.533607290000063],
              [107.05970463800006, 16.536980220000075],
              [107.05985236800004, 16.538215679000068],
              [107.05986411800002, 16.539322270000056],
              [107.05941234200003, 16.541149552000078],
              [107.05863027300008, 16.543566006000084],
              [107.05851016600009, 16.544934306000059],
              [107.05851638300007, 16.545520160000123],
              [107.05889312000006, 16.54613490900001],
              [107.059571, 16.546583907000034],
              [107.06044733900005, 16.546705458000055],
              [107.06226248400004, 16.546492212000082],
              [107.06561661400011, 16.545417396000062],
              [107.06829973400006, 16.544544487000046],
              [107.070723824, 16.544680147000079],
              [107.0727668000001, 16.545466831000063],
              [107.07548358700009, 16.547057593000055],
              [107.07608174600001, 16.547224671000023],
              [107.07733463700004, 16.547269873000033],
              [107.08024240500001, 16.547224985000049],
              [107.08228281900008, 16.547105150000085],
              [107.08414392900006, 16.546990718000117],
              [107.08528808700005, 16.546979276000059],
              [107.08613252500002, 16.547263789000063],
              [107.08748980900006, 16.548291794000122],
              [107.08919414000003, 16.550292819000042],
              [107.09009689700012, 16.551065121000086],
              [107.09180942600005, 16.551804566000072],
              [107.09925560000003, 16.553251399000054],
              [107.10060381600003, 16.553433119000083],
              [107.10241964699999, 16.553284608000048],
              [107.10443314100006, 16.552743487000043],
              [107.10584300800009, 16.552403751000085],
              [107.10745618300007, 16.552192151000078],
              [107.11016185600003, 16.552072225000103],
              [107.11256102200012, 16.552082130000123],
              [107.11454913600004, 16.552231089000102],
              [107.11562813200005, 16.552415445000079],
              [107.11728417800003, 16.552662688000019],
              [107.11866413300007, 16.552983981000047],
              [107.1196864610001, 16.553402651000049],
              [107.12050479000004, 16.554370816000038],
              [107.12100370900004, 16.556904579000076],
              [107.12116609400005, 16.559441773000039],
              [107.12138511600006, 16.561001908000073],
              [107.12261660900008, 16.562812121000015],
              [107.12350516100005, 16.564039932000085],
              [107.12479827800011, 16.565328722000046],
              [107.12595180500007, 16.566163223000082],
              [107.12784501400004, 16.56692507000006],
              [107.13034474100002, 16.567745786000017],
              [107.13291250900002, 16.568630883000068],
              [107.13392716700004, 16.569076174000053],
              [107.13552077200009, 16.570133962000085],
              [107.13816666900007, 16.571994645000117],
              [107.139048168, 16.572571477000061],
              [107.13985877900011, 16.572823534000044],
              [107.14228482200011, 16.573058973000073],
              [107.14545776200001, 16.57387257000007],
              [107.14863289800009, 16.574881384000051],
              [107.14957097000007, 16.575245445000064],
              [107.15059357400004, 16.575642307000038],
              [107.15167344100004, 16.575891535000054],
              [107.15214461200003, 16.575886661000091],
              [107.15308478800002, 16.575681646000092],
              [107.15408865000003, 16.575150484000019],
              [107.15489347500001, 16.574881773000079],
              [107.15590312800006, 16.574871322000106],
              [107.15752292800006, 16.575245113000037],
              [107.16130754500003, 16.57657289400003],
              [107.16499598400003, 16.578324731000023],
              [107.16661800800003, 16.578893726000032],
              [107.16823783300003, 16.579267436000045],
              [107.17088765000008, 16.579804541000073],
              [107.17459352400002, 16.580081719000091],
              [107.18343013, 16.580994745000055],
              [107.18860548100012, 16.580940382000087],
              [107.19036905400003, 16.581006596000059],
              [107.19197749800006, 16.581476936000072],
              [107.19312153500003, 16.581785053000075],
              [107.19467800900003, 16.582287722000011],
              [107.19603090700006, 16.582736105000073],
              [107.19739968800008, 16.583043941000049],
              [107.19888571200002, 16.583483913000038],
              [107.19974262300003, 16.583543137000056],
              [107.20041573900005, 16.583536023000086],
              [107.20142170900006, 16.583199914000076],
              [107.20248608500002, 16.582082016000044],
              [107.20354971100009, 16.580899040000041],
              [107.20421687500004, 16.580371207000063],
              [107.20515476500002, 16.579970701000057],
              [107.20676946000003, 16.57988848500009],
              [107.21127918700006, 16.579840621000038],
              [107.21313926600004, 16.579785197000071],
              [107.21480909200008, 16.579767430000025],
              [107.21820968400007, 16.579846564],
              [107.221015897, 16.580104999000064],
              [107.22328405800009, 16.58025380200003],
              [107.22531368000007, 16.580405132000031],
              [107.22586094400005, 16.580498856000055],
              [107.22692915800006, 16.580849250000092],
              [107.22782339500006, 16.58138917300009],
              [107.22965854900012, 16.582480600000068],
              [107.23094136300003, 16.583243515000042],
              [107.23209238900006, 16.583817017000065],
              [107.24256835900007, 16.587414730000113],
              [107.24348045600004, 16.587697826000088],
              [107.24449162400003, 16.587817104000102],
              [107.24543170500002, 16.587611668000065],
              [107.24603296400002, 16.587214613000079],
              [107.24642548400008, 16.586233972000031],
              [107.24639903200003, 16.58395597400002],
              [107.24678702100007, 16.582584816000079],
              [107.24804018000002, 16.580358091000043],
              [107.25003367800011, 16.578123354000063],
              [107.25290440400005, 16.576074400000081],
              [107.25591193100011, 16.574219203000062],
              [107.25859123500003, 16.573083564000065],
              [107.26087199900009, 16.572407873000117],
              [107.26309313300001, 16.571633977000118],
              [107.26408858700003, 16.571224432000072],
              [107.26481852900004, 16.570821168000101],
              [107.26561855400007, 16.570161534000057],
              [107.26712494100009, 16.568922483000037],
              [107.26812077300006, 16.567739961000072],
              [107.26858960600003, 16.567539578],
              [107.26926262000003, 16.567532249000024],
              [107.2699417470001, 16.568045591000043],
              [107.27232634900005, 16.57049310200005],
              [107.27443875000003, 16.572683198000071],
              [107.27498253500008, 16.573132910000062],
              [107.27533225900007, 16.573371429000083],
              [107.27575732100007, 16.573593738000092],
              [107.27646506900001, 16.573903736000069],
              [107.27740906700004, 16.574347331000091],
              [107.27882349300009, 16.574876543000094],
              [107.28000163900006, 16.575272158000054],
              [107.28103899500009, 16.575669319000049],
              [107.2820158980001, 16.575919998000096],
              [107.28376888700009, 16.576161138000067],
              [107.28471498900004, 16.576476218000025],
              [107.28586839700009, 16.577244669],
              [107.28797562100009, 16.578979003000072],
              [107.28988173200005, 16.58078062500001],
              [107.29130672000011, 16.581741323000095],
              [107.29476172500006, 16.583590914000098],
              [107.29798158400011, 16.585475591000076],
              [107.30197827200004, 16.587579435000066],
              [107.30469074800003, 16.589241784000116],
              [107.30787830300007, 16.591224272000105],
              [107.31269925500007, 16.594685657000063],
              [107.31609670200007, 16.597316564000053],
              [107.31847521800006, 16.599177708000035],
              [107.32010708500007, 16.600526406000071],
              [107.32361156900004, 16.60364417800011],
              [107.32402091600008, 16.604095226000126],
              [107.32651212600005, 16.609619259000041],
              [107.325958746, 16.610070085000039],
              [107.3243980070001, 16.61224506800005],
              [107.32269393200005, 16.615032683000038],
              [107.32148455200009, 16.617685600000073],
              [107.32070228300003, 16.61931804300005],
              [107.31985054800008, 16.620609653000074],
              [107.31857581900003, 16.621695561000124],
              [107.31659563600006, 16.62257485600005],
              [107.31246046100003, 16.623821930000112],
              [107.31076222100003, 16.624838313000097],
              [107.31005256300003, 16.625857862000082],
              [107.30983719100006, 16.626878983000012],
              [107.30983390300005, 16.627832668000053],
              [107.31018158000003, 16.629400562000015],
              [107.31151084900007, 16.633015224000047],
              [107.31344357200003, 16.635712166000083],
              [107.31493760800001, 16.637641334000094],
              [107.31546305200004, 16.638869180000079],
              [107.31549771699999, 16.640251389000049],
              [107.31511811400001, 16.641643999000074],
              [107.31424993100003, 16.642543835000097],
              [107.31327733000003, 16.643000552000053],
              [107.31207655400007, 16.643064844000044],
              [107.31123146100005, 16.642380935000034],
              [107.31074110800006, 16.641221308000098],
              [107.3107479120001, 16.639245831000068],
              [107.31046849200007, 16.638359370000089],
              [107.30983527600007, 16.637676132000045],
              [107.30913049700003, 16.637265147000058],
              [107.30842431800004, 16.637262880000044],
              [107.30715201500001, 16.637599399000088],
              [107.30566383800007, 16.639093256000059],
              [107.30450733300005, 16.641661063000065],
              [107.30624838400004, 16.643625162000127],
              [107.31184640800004, 16.648377498000052],
              [107.31395736600008, 16.65063221000009],
              [107.314801104, 16.651724827000081],
              [107.31735822500009, 16.652601477000047],
              [107.31933290900005, 16.653425174000077],
              [107.32053049700002, 16.65431451800012],
              [107.32109040600008, 16.655814936000098],
              [107.32080282500009, 16.657312680000032],
              [107.31973835200003, 16.658807961000029],
              [107.31768533300007, 16.660232],
              [107.31718536900009, 16.661865297000041],
              [107.31718141800012, 16.663023335000027],
              [107.31788327600002, 16.664319842000097],
              [107.32041939100006, 16.666235228000033],
              [107.32225342000002, 16.666922211000035],
              [107.32465525000006, 16.666793515000045],
              [107.32719741600009, 16.666937713000074],
              [107.32874941100008, 16.66748750900009],
              [107.32976520300009, 16.669977068000016],
              [107.32933046400009, 16.673245474000083],
              [107.32960340600003, 16.676107360000088],
              [107.33184860400007, 16.680610261000069],
              [107.33452043500009, 16.684297002000022],
              [107.33973077100006, 16.689353898000014],
              [107.3456459610001, 16.694957761000047],
              [107.35165253400007, 16.699432050000063],
              [107.35389517600008, 16.699785095000053],
              [107.35466727600001, 16.699827661000107],
              [107.35535102300001, 16.700537071],
              [107.35579178100006, 16.701537649000016],
              [107.35592798000005, 16.70266001300007],
              [107.355881283, 16.703843605000124],
              [107.3537869420001, 16.705174363000097],
              [107.35306201200007, 16.705619895000012],
              [107.35232383500001, 16.706168174000076],
              [107.35181673500006, 16.706578849000039],
              [107.35044904500005, 16.707886879000085],
              [107.35024866800008, 16.708758282000069],
              [107.35275978900002, 16.71178010000007],
              [107.35422984100006, 16.710716941000037],
              [107.35606880900008, 16.714134090000066],
              [107.35953891700004, 16.720039367000084],
              [107.36210386100008, 16.724505662000055],
              [107.36429581300007, 16.728502952000035],
              [107.36529562800011, 16.73014778400006],
              [107.36844859700004, 16.735110024000029],
              [107.37250699300002, 16.73654221500005],
              [107.37546137600003, 16.737809571000106],
              [107.37909577000001, 16.739601430000036],
              [107.38198681600002, 16.740691960000028],
              [107.38389771500005, 16.741734618000059],
              [107.38619085200006, 16.74369454700004],
              [107.39047146500003, 16.740484624000104],
              [107.39496614500004, 16.737200702000088],
              [107.406904422, 16.728479013000097],
              [107.41156832100006, 16.724654782000044],
              [107.42171052800008, 16.716251586000027],
              [107.42811561200006, 16.710972491000042],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 57, code: "AD01", ten_tinh: "Tiền Giang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.284967667, 10.588056122000109],
              [106.29440410200007, 10.578008808000037],
              [106.30882165200008, 10.566398829000077],
              [106.31798948500003, 10.571081016000059],
              [106.32216006500005, 10.567483773000051],
              [106.33379310800005, 10.551465813000059],
              [106.33772564500001, 10.548774122000014],
              [106.34662159000003, 10.545370509000039],
              [106.34616553100004, 10.529100226000041],
              [106.35023359600009, 10.526576800000063],
              [106.35194475900002, 10.525515325000077],
              [106.35587293000005, 10.523277170000076],
              [106.35907840300007, 10.525117450000019],
              [106.36787068600003, 10.51860602800012],
              [106.36710220400005, 10.499541081000084],
              [106.36714386100002, 10.499022946000112],
              [106.36786781500007, 10.490017773000114],
              [106.37989376800007, 10.481489413000043],
              [106.38817262000001, 10.476793309000064],
              [106.38936447900004, 10.476117196000077],
              [106.39802634200005, 10.485939466000092],
              [106.40140685400007, 10.494813785000099],
              [106.40696400400006, 10.49257885400001],
              [106.41524860100003, 10.489246672000084],
              [106.41564069300006, 10.483426397000082],
              [106.42140391200005, 10.480608895000019],
              [106.4299890030001, 10.473889740000073],
              [106.43615341700007, 10.468309873000068],
              [106.43926255900001, 10.464100917000072],
              [106.43983076800004, 10.461860794000087],
              [106.43995476400009, 10.461425085000096],
              [106.44031546200009, 10.460157668000123],
              [106.44153383200009, 10.458526547000078],
              [106.44372091500004, 10.456924672000055],
              [106.44987797600001, 10.453712003000069],
              [106.45221728400004, 10.45195560600005],
              [106.44979863900002, 10.451616259000039],
              [106.4463515690001, 10.451363885000118],
              [106.44314474700006, 10.449752007000066],
              [106.43926718000004, 10.44563953800006],
              [106.43446841700009, 10.441747011000045],
              [106.42645293300009, 10.43760342600004],
              [106.42048766900008, 10.435970519000113],
              [106.41634770400003, 10.436166158000047],
              [106.41290094400003, 10.435913241000081],
              [106.41223051800007, 10.433412648000038],
              [106.41247443500001, 10.431599582000038],
              [106.41874621200006, 10.423253061000091],
              [106.42038855800006, 10.418955097000014],
              [106.42295206900006, 10.414437218000037],
              [106.42526474700001, 10.412639741000083],
              [106.43995435600006, 10.41569868500005],
              [106.44294277500005, 10.415720895000023],
              [106.44685756000004, 10.414842496000086],
              [106.45008098500003, 10.414185769000104],
              [106.4540127760001, 10.411038802000048],
              [106.45702667600007, 10.407658167000109],
              [106.45818793900006, 10.406078724000077],
              [106.45567132300002, 10.404472234000115],
              [106.45246003500006, 10.403541146000082],
              [106.44946494700004, 10.404426434000056],
              [106.44739444500006, 10.404637963000061],
              [106.44532907700005, 10.404168954000069],
              [106.44534623700005, 10.401900551000052],
              [106.44674427500009, 10.399415534000054],
              [106.45020757900002, 10.397399502000047],
              [106.45643840800007, 10.396297210000059],
              [106.45940330300007, 10.399712934000059],
              [106.46143180300004, 10.401744671000033],
              [106.46346729400005, 10.402845594000086],
              [106.47245547600008, 10.399187856000045],
              [106.47828217600008, 10.397833951000022],
              [106.49479584500004, 10.416183012000067],
              [106.50419868100008, 10.422533196000037],
              [106.50857711500007, 10.425822477000056],
              [106.51108588199999, 10.426771188000126],
              [106.51281306200008, 10.42709374400008],
              [106.51662923, 10.426983240000085],
              [106.51909742000002, 10.427758809000041],
              [106.52782505800006, 10.43447335800005],
              [106.53551881800009, 10.422095216000036],
              [106.53876000400004, 10.426500406000104],
              [106.54094346400004, 10.428997982000075],
              [106.54282133400007, 10.430252228000112],
              [106.54503107000002, 10.430668725000112],
              [106.54675300400004, 10.430124364000013],
              [106.55558695399999, 10.425996388000057],
              [106.55668100700009, 10.424456541000014],
              [106.55641991200008, 10.419330804000102],
              [106.55597225100007, 10.415914522000056],
              [106.55613927600004, 10.414519368000084],
              [106.55740458300002, 10.413442061000032],
              [106.559447237, 10.413611246000086],
              [106.56353145400007, 10.414104699000058],
              [106.56899327900008, 10.414598608000068],
              [106.57625587500004, 10.415587777000022],
              [106.58253045500007, 10.417647179000065],
              [106.58487691300006, 10.419369622000117],
              [106.58596033800009, 10.421859280000055],
              [106.58558044900009, 10.425119436000061],
              [106.58532191600003, 10.427338089000047],
              [106.58509558800003, 10.42746086500008],
              [106.58307478000008, 10.429907469000097],
              [106.58247395800001, 10.431532483000082],
              [106.5824600450001, 10.433549327000067],
              [106.58295083200004, 10.436753215000037],
              [106.58621339000007, 10.437859004000048],
              [106.58664047400005, 10.43791450100011],
              [106.58728689500006, 10.437996104000112],
              [106.58778236100002, 10.437980416000057],
              [106.58804032800006, 10.437972248000092],
              [106.58883972100003, 10.437719973000091],
              [106.58985093300004, 10.437396199000124],
              [106.59112408500003, 10.43707111900005],
              [106.59214493400003, 10.436923441000088],
              [106.59391751600006, 10.436939768000018],
              [106.59563055900004, 10.437266736000071],
              [106.59843057400008, 10.438427039000063],
              [106.60046207800004, 10.439557620000082],
              [106.60284508500003, 10.441122585000072],
              [106.60561556700003, 10.443323060000063],
              [106.60677294300004, 10.444256672000039],
              [106.60834995400005, 10.445288833000086],
              [106.6099436530001, 10.446169908000067],
              [106.61383100800005, 10.448196904],
              [106.61645706300008, 10.449458559000034],
              [106.61922110200007, 10.450383979000042],
              [106.62175734700004, 10.451159544000086],
              [106.62382183700007, 10.451887163000071],
              [106.62431254700007, 10.452220188000092],
              [106.62560848700011, 10.452918151000022],
              [106.6268364970001, 10.453985521000051],
              [106.62861070200009, 10.455972757000074],
              [106.6297701180001, 10.457275322000024],
              [106.63054499100008, 10.458512755000092],
              [106.63106540400005, 10.459465444000047],
              [106.63104418500008, 10.459720070000065],
              [106.63194642900004, 10.462682679000055],
              [106.6329519600001, 10.46794503600008],
              [106.63323981000011, 10.469553997000068],
              [106.63397331800005, 10.472888559000102],
              [106.63457766000005, 10.47489850600005],
              [106.63518112100007, 10.476740710000064],
              [106.63601189800006, 10.478632066000058],
              [106.63727921700011, 10.480504400000077],
              [106.63784090900005, 10.481038322000112],
              [106.63850756800005, 10.481605254000035],
              [106.63931447700003, 10.482272111000055],
              [106.64173185400003, 10.483635224000041],
              [106.64384943800007, 10.484446278000091],
              [106.64559787700006, 10.484806305000037],
              [106.64661118000006, 10.484868158],
              [106.6475541140001, 10.484829722000109],
              [106.64849685800003, 10.484757740000104],
              [106.64940378300003, 10.48451818700009],
              [106.6502752480001, 10.48417814700011],
              [106.65076266100002, 10.483873665000052],
              [106.65215433000004, 10.482826371000085],
              [106.65410039500004, 10.480937430000024],
              [106.65534982700004, 10.479421158000067],
              [106.65569644200011, 10.478916089000021],
              [106.65628523800007, 10.477973611000023],
              [106.65680453300004, 10.477098592000099],
              [106.65752875500002, 10.475350191000119],
              [106.65815861000009, 10.472277036000042],
              [106.65848917000008, 10.468752502000099],
              [106.65855090600004, 10.463937671000066],
              [106.65839960300005, 10.461757679000081],
              [106.65786718000004, 10.453574104000028],
              [106.65792813100002, 10.451896253000113],
              [106.65792332400007, 10.450990408000036],
              [106.65798784100008, 10.44998355800004],
              [106.65832748400003, 10.448170062000093],
              [106.65917417200009, 10.446471362000052],
              [106.66052539000007, 10.444417739000043],
              [106.66101165100007, 10.443911956000116],
              [106.66201859600008, 10.442799548],
              [106.66363572300003, 10.441499430000077],
              [106.66582990300003, 10.440347274000048],
              [106.66722455600005, 10.43990384800013],
              [106.66809675900001, 10.439731532000083],
              [106.66970185100003, 10.439454736000096],
              [106.67127326200003, 10.439412967000104],
              [106.67410301300004, 10.439565900000057],
              [106.67635733100003, 10.439872804000048],
              [106.67859480300007, 10.440297196000079],
              [106.68058926600007, 10.440991265000042],
              [106.68216589800005, 10.441922369000077],
              [106.68356847200002, 10.442955038000077],
              [106.68500777100009, 10.444323006000021],
              [106.6875552770001, 10.447178089000085],
              [106.68965997300008, 10.450327047000128],
              [106.689879166, 10.450654989000084],
              [106.69173389499998, 10.454553746000082],
              [106.69321676400003, 10.457498274000086],
              [106.69514088200006, 10.461295978000072],
              [106.697008931, 10.464406182000106],
              [106.69970464700002, 10.468770068000042],
              [106.70153311200005, 10.471008155000037],
              [106.70282676900005, 10.472381978000021],
              [106.7038696000001, 10.473528666000041],
              [106.7060808630001, 10.475496260000037],
              [106.71108205900001, 10.479847654000094],
              [106.71526046300005, 10.483817558000029],
              [106.71787585200009, 10.486202235000073],
              [106.72061250900003, 10.488401717000071],
              [106.72610712500008, 10.492446944000021],
              [106.73147166400004, 10.496022038000062],
              [106.73366293400008, 10.497731385000096],
              [106.73862194500005, 10.490286728000074],
              [106.74926973000007, 10.470912707000045],
              [106.75345155400004, 10.4629408530001],
              [106.753577815, 10.46270015100008],
              [106.75402252500002, 10.462026586000126],
              [106.76349364500003, 10.447680376000077],
              [106.77427848900001, 10.435680223000075],
              [106.77644747600007, 10.433397447000024],
              [106.7831043680001, 10.426390917000015],
              [106.79121817500007, 10.418626430000087],
              [106.79189400500003, 10.417987146000128],
              [106.79835712400002, 10.409981101000108],
              [106.80105168100003, 10.404951082000123],
              [106.80247160000002, 10.40208027800009],
              [106.80879555700004, 10.38353375500005],
              [106.81935788500009, 10.349666406000056],
              [106.82320869200002, 10.337316623000079],
              [106.82566220200007, 10.329447384000011],
              [106.79743628400004, 10.316697207000034],
              [106.78087405500004, 10.307301438000048],
              [106.78068396300003, 10.302312562000091],
              [106.78070972200004, 10.297969497000013],
              [106.77886293600008, 10.291443988000102],
              [106.77637489700001, 10.28708633300006],
              [106.77356903500002, 10.283347271000041],
              [106.76760626300005, 10.27833126200006],
              [106.76830736500006, 10.260815997000082],
              [106.76904745800002, 10.242911364000038],
              [106.76964967000001, 10.242531607000059],
              [106.77265179000007, 10.239602314000072],
              [106.77534429400001, 10.235895694000073],
              [106.77931262200008, 10.228939465000051],
              [106.77963873400006, 10.226925107000094],
              [106.77965069900007, 10.224908935000075],
              [106.77934576600006, 10.223356202000032],
              [106.77841627000008, 10.22117946500005],
              [106.77669778500008, 10.219618494000125],
              [106.7735151410001, 10.217239668000031],
              [106.7725438030001, 10.191497608000065],
              [106.76480476100006, 10.191284722000125],
              [106.75389804500003, 10.191631779000064],
              [106.74670519200006, 10.192895728000016],
              [106.73843353100008, 10.19639988500005],
              [106.72682874700011, 10.202228216],
              [106.71421378900004, 10.209647389000052],
              [106.71306291100004, 10.210480040000023],
              [106.703273665, 10.217561997000056],
              [106.69270769300009, 10.227564879000093],
              [106.68604366400008, 10.232789634000115],
              [106.6786444680001, 10.236576362000081],
              [106.67181790100005, 10.238629928000085],
              [106.66681285200009, 10.239317450000039],
              [106.66527614900005, 10.239528516000094],
              [106.65393221100007, 10.240163088000108],
              [106.64230239500006, 10.241807930000045],
              [106.63083138900006, 10.244345469000036],
              [106.61629054800007, 10.247561120000091],
              [106.60887614400006, 10.248607139000041],
              [106.59317273800008, 10.250414619000113],
              [106.58852301200002, 10.251590807000087],
              [106.57334940599999, 10.257648070000068],
              [106.552136854, 10.263661553000068],
              [106.53499234400005, 10.26864525500004],
              [106.53121639100009, 10.27010492300011],
              [106.52664811900011, 10.273226365000097],
              [106.52201631200003, 10.278294534000041],
              [106.51854288400006, 10.282203757000017],
              [106.51738299100001, 10.283074277000102],
              [106.51462460900004, 10.284384830000091],
              [106.50169717700005, 10.289203080000052],
              [106.49676352100005, 10.29210960200002],
              [106.48806271000009, 10.298493822000053],
              [106.47984858600009, 10.30425488700004],
              [106.47384597700001, 10.307103976000052],
              [106.47239013600009, 10.307793388000073],
              [106.46948177800004, 10.30823917500001],
              [106.46045897900011, 10.308135880000078],
              [106.45143481200004, 10.30774397000007],
              [106.44721476000011, 10.307762885000036],
              [106.44474289300004, 10.308206495000059],
              [106.42507837400005, 10.320189322000049],
              [106.40476089400002, 10.33296699800008],
              [106.39956155600007, 10.335786548000046],
              [106.39779184700009, 10.336746202000045],
              [106.39678015600006, 10.337056053000103],
              [106.39652631300001, 10.337133793000051],
              [106.39110606200008, 10.338793783000087],
              [106.37961488700009, 10.340285059000019],
              [106.37081260200009, 10.340971478000059],
              [106.36732464700006, 10.340382016000136],
              [106.36498665700006, 10.339986857000037],
              [106.35711947300007, 10.338145594000101],
              [106.35077081400004, 10.336038404000101],
              [106.326877278, 10.328105831000032],
              [106.30924109300005, 10.321833305],
              [106.30253879600008, 10.319985885000092],
              [106.29933704300002, 10.319998788000094],
              [106.29701144500007, 10.320729112000036],
              [106.28883999000004, 10.327395644000068],
              [106.28830811900008, 10.327829538000087],
              [106.2858404080001, 10.329425535000068],
              [106.28387475100008, 10.32921706700005],
              [106.25925670000004, 10.323834589000034],
              [106.24949823000006, 10.321997932000036],
              [106.23370141700008, 10.320544593000045],
              [106.21899482000005, 10.31872585200002],
              [106.20894387500003, 10.316456437000097],
              [106.20463992300007, 10.315213985000058],
              [106.20354247600005, 10.314897162000046],
              [106.20253309200011, 10.314605761000093],
              [106.19035716700003, 10.308378110000136],
              [106.18160787200007, 10.303795768000066],
              [106.16913356800009, 10.297782297000067],
              [106.16527897900002, 10.295923911000047],
              [106.15638533800011, 10.291485529000076],
              [106.14836872000009, 10.288053181000032],
              [106.14122801300009, 10.285338555000044],
              [106.14069423300006, 10.285241328000081],
              [106.13656805600007, 10.284489713000065],
              [106.13401832700006, 10.284473644000068],
              [106.12933725199998, 10.284444055000018],
              [106.12312081500008, 10.284741777000049],
              [106.11335873800006, 10.285219231000029],
              [106.10593529000002, 10.284812041000089],
              [106.10258563200007, 10.284102425000084],
              [106.09413059300002, 10.279949123000074],
              [106.08990438900003, 10.278232897000093],
              [106.08633726700002, 10.277740139000089],
              [106.08138987300003, 10.277900884000067],
              [106.07804422600006, 10.278344651000014],
              [106.07368329500009, 10.279801194000092],
              [106.06772634100007, 10.282705002000101],
              [106.05188940000005, 10.290543998000066],
              [106.04801958400004, 10.292418742000018],
              [106.03270893000004, 10.299835057000093],
              [106.026827064, 10.304221844000059],
              [106.01127480700009, 10.315819414000059],
              [106.00980492000006, 10.316992309000026],
              [106.00199023400006, 10.323227841000103],
              [105.99507179300006, 10.328747731000119],
              [105.99206205300008, 10.330606532000084],
              [105.9896244560001, 10.331752181000107],
              [105.98431174500008, 10.331910699000078],
              [105.97829253400005, 10.330374604000079],
              [105.97807454200004, 10.330238024000058],
              [105.97151174500007, 10.325547276000078],
              [105.96522422300004, 10.320049210000091],
              [105.95351458, 10.309808667000107],
              [105.93696258000006, 10.296625636000021],
              [105.92889849900004, 10.288681554000066],
              [105.92485924600004, 10.285840093000063],
              [105.923928141, 10.285185076000046],
              [105.92232418, 10.2840639780001],
              [105.90926961900006, 10.278280070000088],
              [105.90366401800006, 10.276161616000044],
              [105.89920991000011, 10.275035801000064],
              [105.895908848, 10.275471754000074],
              [105.89217104000009, 10.276922649000129],
              [105.88416004000001, 10.284325090000033],
              [105.87620792700002, 10.290393419000042],
              [105.8720502290001, 10.292681061000051],
              [105.86373037200005, 10.295691162000063],
              [105.85454536, 10.297422863000081],
              [105.83904190100003, 10.299028473000059],
              [105.83652926700006, 10.299106124000089],
              [105.83381676900007, 10.305088767000113],
              [105.83281497100005, 10.306371850000049],
              [105.82948274700003, 10.307895478000082],
              [105.82506536500001, 10.30809904200005],
              [105.82234112600003, 10.309671033000088],
              [105.8200480200001, 10.311384192000082],
              [105.81976414100006, 10.312665409000131],
              [105.81991141200008, 10.314087809000048],
              [105.82063162000007, 10.314939646000097],
              [105.82782228200004, 10.319047351000064],
              [105.82753619900008, 10.319474913000091],
              [105.82567273200009, 10.32076018100012],
              [105.81744074900011, 10.323017271000088],
              [105.81519723000011, 10.323632348000087],
              [105.81738470900009, 10.336574071000102],
              [105.81690470000008, 10.345325355000055],
              [105.81539734000006, 10.355566365000078],
              [105.81518272900007, 10.357889011000053],
              [105.81469746100007, 10.363140698000082],
              [105.81420114800002, 10.368511689000036],
              [105.81402711600002, 10.371632128000028],
              [105.8148320670001, 10.371593679000044],
              [105.83424224000001, 10.370665609000083],
              [105.84282152800003, 10.367037126000065],
              [105.85780868900008, 10.408793992000119],
              [105.85793796800004, 10.409154119000073],
              [105.89137737000006, 10.444533157000089],
              [105.89843273100011, 10.451995785000088],
              [105.93653416200004, 10.492092594000011],
              [105.93979404900003, 10.495421754000043],
              [105.94006767500011, 10.49570725200004],
              [105.94095900800005, 10.511429909000112],
              [105.94102263000008, 10.537294439000087],
              [105.94152549100004, 10.536998327000012],
              [105.96568787700004, 10.535410725000103],
              [105.99863687600008, 10.53336980800008],
              [105.99961008100004, 10.533271011000076],
              [106.08214708600006, 10.524875625000083],
              [106.082651846, 10.524846812000098],
              [106.10054156700005, 10.523676991000029],
              [106.10061378299999, 10.528548203000108],
              [106.10061761000003, 10.529626296000048],
              [106.10061172000007, 10.531809477000118],
              [106.100590389, 10.533480610000099],
              [106.10053883900009, 10.534316314000057],
              [106.10045404600001, 10.537308347000083],
              [106.10032397100008, 10.539060714000035],
              [106.10029504100008, 10.542429889000092],
              [106.1002458840001, 10.543939396000022],
              [106.10024220100006, 10.546742482000047],
              [106.10024861800005, 10.548548277000068],
              [106.10013502400008, 10.551305855000058],
              [106.10012954900006, 10.553400162000058],
              [106.10009688000011, 10.55507133000013],
              [106.10003487100005, 10.55680100800007],
              [106.09998526300004, 10.558822626000095],
              [106.09994630000008, 10.56064205700009],
              [106.09991789600005, 10.562236858000061],
              [106.0998798140001, 10.564303337000068],
              [106.09989828600003, 10.566302267000081],
              [106.09985259200006, 10.569424420000038],
              [106.09968715000005, 10.576599840000076],
              [106.126726741, 10.577753438000046],
              [106.13151270000004, 10.577973728000066],
              [106.1441844930001, 10.578556460000053],
              [106.14559944800003, 10.578621481000104],
              [106.15872721200007, 10.579224283000064],
              [106.19643817300003, 10.580951314000052],
              [106.19812181100006, 10.581154520000078],
              [106.20331618100006, 10.580203252000063],
              [106.284967667, 10.588056122000109],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 58, code: "AD01", ten_tinh: "TP. Hồ Chí Minh" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.96860319700009, 10.453527913000114],
              [106.96735359700008, 10.452794348000081],
              [106.9660247990001, 10.453151505000056],
              [106.96579960100011, 10.453950554000089],
              [106.96623717799999, 10.454898340000083],
              [106.96711671400001, 10.45592105500006],
              [106.96902363300006, 10.457894463000093],
              [106.97034115700009, 10.459792214000084],
              [106.97172988700007, 10.462199517000046],
              [106.97194268800008, 10.463873630000096],
              [106.97185950300005, 10.465764528000053],
              [106.97111350400004, 10.467506684000048],
              [106.970883219, 10.469324127000055],
              [106.97153765400006, 10.471145915000067],
              [106.97278120700001, 10.47311608200001],
              [106.97388131100008, 10.474285359000122],
              [106.97483688000001, 10.474871983000076],
              [106.97594316900006, 10.4748046430001],
              [106.97638881000007, 10.474152121000111],
              [106.97639207100008, 10.473497451000034],
              [106.97580949700001, 10.472039737000037],
              [106.97471047400003, 10.470652245000077],
              [106.97412683000006, 10.469412762000017],
              [106.97413191400004, 10.468394383000133],
              [106.97435853900005, 10.467304354000094],
              [106.97495669400007, 10.465634200000105],
              [106.97533620500012, 10.463453782000085],
              [106.97505147000001, 10.461415614000112],
              [106.97395359900003, 10.459809918000039],
              [106.97219369800007, 10.457910022000048],
              [106.970947702, 10.45644907800005],
              [106.97021959100007, 10.454626969000101],
              [106.96860319700009, 10.453527913000114],
            ],
          ],
          [
            [
              [106.47384385900001, 11.143313669000053],
              [106.47672187400011, 11.14313259000008],
              [106.48111691800005, 11.143599008000125],
              [106.48551928400006, 11.144403179000049],
              [106.49013748600008, 11.144436399000044],
              [106.49375472000006, 11.14354550500005],
              [106.49904696400006, 11.14162761100008],
              [106.50401520300009, 11.138606480000044],
              [106.50645497400008, 11.136589825000074],
              [106.50735569400008, 11.13564989400002],
              [106.51178774400006, 11.131009298000015],
              [106.51640551500005, 11.12666749600004],
              [106.52048140700005, 11.124109839000072],
              [106.52485068300007, 11.121822552000092],
              [106.52529671400004, 11.120956494000021],
              [106.52594491400001, 11.119483551000096],
              [106.52596991000004, 11.116006974000035],
              [106.52557389800005, 11.109919923000076],
              [106.52384542000003, 11.105670528000109],
              [106.52165683800004, 11.104242764000015],
              [106.51638935500004, 11.102901890000092],
              [106.51611914400007, 11.102721106000056],
              [106.51489762900007, 11.101450975000125],
              [106.51447361700008, 11.099729020000025],
              [106.51580479000008, 11.096730244000062],
              [106.518890012, 11.092597888000098],
              [106.52166143200007, 11.091614682000015],
              [106.52384367000006, 11.091630020000041],
              [106.52630864300009, 11.0927932760001],
              [106.52863330600005, 11.093239302000056],
              [106.53052665200003, 11.092966058000096],
              [106.53184421300007, 11.091829298],
              [106.53214235200005, 11.090828673000084],
              [106.53142519800004, 11.089391210000075],
              [106.52940901300011, 11.086512235000077],
              [106.52623832500004, 11.082335959000121],
              [106.52349799500006, 11.079022144000115],
              [106.52249825900003, 11.076436756000072],
              [106.52259957600006, 11.074017414000091],
              [106.52276020000004, 11.073178301000029],
              [106.53098449900008, 11.060633617000091],
              [106.54071554800001, 11.052444840000051],
              [106.54513499800001, 11.049216340000088],
              [106.54954508000004, 11.047291341000063],
              [106.56186668400008, 11.045637825000119],
              [106.56428457600003, 11.045654358000066],
              [106.56625526600001, 11.046754185000028],
              [106.56774992900006, 11.053065387000059],
              [106.56949179400003, 11.055467293000019],
              [106.57124732400007, 11.055913789000014],
              [106.57257078400005, 11.055270948000027],
              [106.57367743600005, 11.054192077000023],
              [106.57655769500006, 11.050952458000028],
              [106.57772464800007, 11.048044333000105],
              [106.57947248800009, 11.042715733000106],
              [106.58072877400012, 11.039309703000109],
              [106.58118718700004, 11.038912675000033],
              [106.58280615000005, 11.0375104860001],
              [106.58478531800003, 11.037319757000128],
              [106.58676351500002, 11.037333071000075],
              [106.58917384700005, 11.038435626000085],
              [106.59024298200005, 11.042788263000068],
              [106.59142149200004, 11.044586352000072],
              [106.59285979000003, 11.045847640000071],
              [106.59472899000005, 11.046729242000096],
              [106.59652704300005, 11.046946493000041],
              [106.59923426600005, 11.045890227000131],
              [106.6010060830001, 11.043946559000071],
              [106.60123625800006, 11.042427180000034],
              [106.60125247300006, 11.04004979200006],
              [106.59748349100005, 11.035268424000042],
              [106.59385224600007, 11.029341613000019],
              [106.59387905900007, 11.025430948000102],
              [106.5945696900001, 11.020872953],
              [106.59761081800011, 11.016649204000116],
              [106.59980107400006, 11.015374745000093],
              [106.61330265600003, 11.013393121000091],
              [106.61594728100009, 11.012324238000113],
              [106.61771864900001, 11.01038052200005],
              [106.61905044700008, 11.008433918000014],
              [106.61929800800004, 11.004307566000103],
              [106.61844382100006, 11.000608514000108],
              [106.61474896200006, 10.994500922000048],
              [106.61416271500005, 10.991835578000051],
              [106.61610707000008, 10.988643920000117],
              [106.61942523500008, 10.985406913000071],
              [106.62390103400004, 10.98157992400006],
              [106.62765044100003, 10.979540531000074],
              [106.63177732800008, 10.978535577000057],
              [106.63507049900007, 10.978991501000033],
              [106.64076785400002, 10.981418229],
              [106.64318213000011, 10.981868341000032],
              [106.6447203160001, 10.981878268000054],
              [106.64604163400001, 10.981452282000069],
              [106.64737011000005, 10.979940056000091],
              [106.64805367100004, 10.976251161000048],
              [106.64882019700005, 10.959962354000117],
              [106.64881794200004, 10.948508872000049],
              [106.64881692000004, 10.943337833000031],
              [106.64862584600004, 10.938991782000061],
              [106.64842156500002, 10.935717134000098],
              [106.64953861400008, 10.932900211000083],
              [106.65298338900007, 10.928271717000102],
              [106.65657832800008, 10.92544838800009],
              [106.6659358580001, 10.92163488500004],
              [106.66889830600007, 10.921801655000072],
              [106.67135925300003, 10.922777706000096],
              [106.67783289900009, 10.924519330000047],
              [106.68112949400009, 10.924322824000042],
              [106.68333745299999, 10.922598791000036],
              [106.68822731200001, 10.913722797000068],
              [106.69246885300004, 10.903104938000039],
              [106.69250207500004, 10.89789167000008],
              [106.69229904300002, 10.895283680000121],
              [106.68998821100006, 10.890279117000111],
              [106.68948245700004, 10.889183806000078],
              [106.68687315700006, 10.885040255000076],
              [106.68732910700004, 10.882436436000107],
              [106.69174430700005, 10.87681622000005],
              [106.69677289000001, 10.870837602000092],
              [106.70032401300001, 10.868423471000098],
              [106.70467698900002, 10.867785092000036],
              [106.70631064000001, 10.867783254000061],
              [106.70853335600006, 10.867777948000066],
              [106.71048930299999, 10.866927066000097],
              [106.71419584700007, 10.872502611000099],
              [106.71119975800006, 10.879132233000098],
              [106.71096389900001, 10.880757033000064],
              [106.71210201200002, 10.88424714600009],
              [106.71297155400002, 10.889360446000039],
              [106.71364144400012, 10.892769888000052],
              [106.71454461800006, 10.895726725000097],
              [106.71637660200003, 10.897554131000041],
              [106.71938340400004, 10.895756283000056],
              [106.72954291800006, 10.892412629000068],
              [106.73417362200004, 10.88880823600009],
              [106.73506578600006, 10.887766398000041],
              [106.73533407400005, 10.887453100000053],
              [106.74135535300007, 10.882494820000053],
              [106.74297514100007, 10.881369421000018],
              [106.743909253, 10.879331839000093],
              [106.74416467, 10.875247053000068],
              [106.74464629100005, 10.871844685000019],
              [106.74652825300006, 10.865499501000018],
              [106.76032117600008, 10.870575692],
              [106.7593680520001, 10.875791445000081],
              [106.75818721700008, 10.880551841000051],
              [106.75725323200008, 10.88258949000007],
              [106.76113583700007, 10.888287933000116],
              [106.76754642800007, 10.895136319000096],
              [106.77079404100002, 10.891522988000089],
              [106.7754334950001, 10.886328590000092],
              [106.77867812700006, 10.883169206000074],
              [106.77985040200008, 10.879770736000081],
              [106.77987065100007, 10.876365588000068],
              [106.77828355300002, 10.872043050000089],
              [106.77806397700006, 10.870225642],
              [106.77918819600009, 10.86996401600002],
              [106.78567760200012, 10.868453686000111],
              [106.80568441400001, 10.874924844000054],
              [106.8127767710001, 10.88359173500004],
              [106.82033317600005, 10.891807066000057],
              [106.82583585100006, 10.896605378000055],
              [106.83157568100006, 10.900269779000025],
              [106.83687921400004, 10.903151501000073],
              [106.83708424500003, 10.902995610000053],
              [106.83870053500002, 10.89937744800009],
              [106.8403450870001, 10.892949511000081],
              [106.84133921300003, 10.888452761000076],
              [106.84202167700009, 10.885585789000094],
              [106.84273375100003, 10.884458815000107],
              [106.84581686000004, 10.88122190800007],
              [106.84888063400005, 10.877928951000102],
              [106.84910398000005, 10.87732884300001],
              [106.84909383700003, 10.87568228900002],
              [106.8483218370001, 10.873217016000057],
              [106.84680727800001, 10.869989027000047],
              [106.84561449100008, 10.866777798],
              [106.84518885500003, 10.865246009000094],
              [106.84508518100007, 10.863768418000051],
              [106.84597236200003, 10.860357595000012],
              [106.84888661900001, 10.854314967000088],
              [106.85361723800001, 10.84524884800002],
              [106.85697499000001, 10.84060689100008],
              [106.86231069300007, 10.834624441000072],
              [106.86745798900007, 10.82911104600006],
              [106.87031416900004, 10.826051560000048],
              [106.87093648600002, 10.825384922000046],
              [106.87561694900003, 10.820585114000012],
              [106.877681846, 10.818027823000074],
              [106.87975316800006, 10.813468373000122],
              [106.88049509000007, 10.811106237000077],
              [106.88056236600005, 10.809758614000129],
              [106.87883533000006, 10.805802329000079],
              [106.87703801300003, 10.802707178000047],
              [106.87555407600007, 10.80133155],
              [106.86878873900004, 10.796245593],
              [106.864055385, 10.792438311000028],
              [106.86321555200006, 10.791208408000051],
              [106.86282805500005, 10.789713827000112],
              [106.86302331800005, 10.787635677000116],
              [106.86376973100008, 10.785984573000105],
              [106.86861092400008, 10.77970576500009],
              [106.86980967600005, 10.778338735000135],
              [106.87050788000009, 10.777542505000069],
              [106.87071162900007, 10.777110593000126],
              [106.872561499, 10.773189048000107],
              [106.87288967600007, 10.77120366500006],
              [106.87255508700009, 10.769091297000076],
              [106.87095579600002, 10.767379498000105],
              [106.86830065900007, 10.766197976000045],
              [106.86394754400004, 10.765700255000075],
              [106.86111478600006, 10.766390891000109],
              [106.8559799180001, 10.771043421000035],
              [106.85203244500006, 10.775033893000073],
              [106.85008860400008, 10.775737827000052],
              [106.8480869840001, 10.776273707000071],
              [106.84388728800008, 10.776149032000058],
              [106.84107658100005, 10.775192119000019],
              [106.83956592800011, 10.774677794000096],
              [106.83468195700004, 10.770870834000055],
              [106.830987689, 10.766738664000018],
              [106.82723285600009, 10.761951854000083],
              [106.82601591000004, 10.760873738000052],
              [106.82525759800009, 10.760578810000075],
              [106.824848715, 10.760644732000022],
              [106.82333029400004, 10.760889530000027],
              [106.82223560900007, 10.761270208000083],
              [106.82144555200007, 10.761985898000058],
              [106.82096167800009, 10.763298579000134],
              [106.82116541500008, 10.765729924000043],
              [106.82332262100005, 10.772191560000087],
              [106.824284963, 10.774955247000049],
              [106.82427630600007, 10.776658081000077],
              [106.82398179800005, 10.778007069000049],
              [106.82278055200004, 10.779548512000046],
              [106.82138835500004, 10.780791696000056],
              [106.8201062520001, 10.781472864000106],
              [106.81897253700001, 10.781666637000063],
              [106.81719416800003, 10.781602228000063],
              [106.81507197900004, 10.780978469000056],
              [106.81138786900003, 10.778530054000067],
              [106.80775822000001, 10.775293592000066],
              [106.80619658100002, 10.773901071000045],
              [106.80448141200004, 10.771740465000066],
              [106.80302929300007, 10.769278922000057],
              [106.80177975900006, 10.765952306000058],
              [106.80154949800001, 10.76533927600005],
              [106.80055222700001, 10.763024770000039],
              [106.79967467100005, 10.761757433000072],
              [106.796807841, 10.759921551000049],
              [106.79138303900004, 10.757295776000129],
              [106.78364834300008, 10.754234098000024],
              [106.77532343600006, 10.750483215000093],
              [106.77103412499999, 10.747925393000022],
              [106.77070994100008, 10.747642856000093],
              [106.76823972700006, 10.745489984000043],
              [106.76493889900006, 10.741862320000093],
              [106.76454704100006, 10.741431656000076],
              [106.76172266900005, 10.737068946000059],
              [106.75396844699999, 10.72721383200008],
              [106.75087230600005, 10.721580060000056],
              [106.74994822200007, 10.718740934000069],
              [106.74978446800002, 10.716162590000076],
              [106.74961557000007, 10.713503259000044],
              [106.75018029600008, 10.706464132000091],
              [106.75045622900009, 10.70563744300008],
              [106.75274580600008, 10.698777539000035],
              [106.7539418210001, 10.696300755],
              [106.75942626500003, 10.689832742000059],
              [106.76521907100006, 10.684410846000068],
              [106.77165681400008, 10.678481300000085],
              [106.77187554200006, 10.678345221000106],
              [106.77387485500002, 10.67710137000004],
              [106.77463949200005, 10.676696433000108],
              [106.77658935000008, 10.675663811000023],
              [106.78315269700002, 10.67278216600007],
              [106.78594272600003, 10.6713441030001],
              [106.79053557500008, 10.667799917000041],
              [106.79633222200007, 10.663200899000101],
              [106.80081643300011, 10.660517996000072],
              [106.80586710800003, 10.657719492000071],
              [106.8075189470001, 10.655689045000114],
              [106.80885590700005, 10.65156465300001],
              [106.81145013700004, 10.642642689000057],
              [106.81561327500006, 10.636892703000099],
              [106.82080000600004, 10.63162326700011],
              [106.823735368, 10.629285929000075],
              [106.82705499300003, 10.627994206000045],
              [106.83117504000005, 10.627783085000074],
              [106.83146312700008, 10.627847620000084],
              [106.83397690900006, 10.628440365000101],
              [106.83594699, 10.629102473000083],
              [106.83739179300008, 10.630441271000011],
              [106.83770142200004, 10.631637025000094],
              [106.83847955600002, 10.635300001000035],
              [106.83924786100009, 10.637316385000071],
              [106.84076923200011, 10.638804399],
              [106.84455787000006, 10.640054549000054],
              [106.84728198500002, 10.640337907000051],
              [106.85083566900008, 10.640242102000029],
              [106.85366621300007, 10.639364651000081],
              [106.85706092100001, 10.637997316000012],
              [106.858194301, 10.637840916000039],
              [106.85940601700007, 10.638133122000058],
              [106.86178936400009, 10.640061637000107],
              [106.86609323400003, 10.643482347000075],
              [106.868830141, 10.645861125000076],
              [106.87058538700011, 10.648470267000041],
              [106.87233061000009, 10.649432846000105],
              [106.87860623300001, 10.649245593000066],
              [106.88007923300003, 10.648974795000042],
              [106.88038085100004, 10.648753087000083],
              [106.88120848900003, 10.648144719000106],
              [106.88225293700003, 10.64581823700008],
              [106.88358651000006, 10.641319480000087],
              [106.89419559000007, 10.632648358000017],
              [106.89678792900007, 10.629975692000036],
              [106.90099921800008, 10.626207971000044],
              [106.90302549700004, 10.623725802000104],
              [106.90422001000012, 10.621248679000049],
              [106.90607275500003, 10.615175008000042],
              [106.90782617600007, 10.611384707000047],
              [106.9091017960001, 10.6098052240001],
              [106.91045673700003, 10.608824010000093],
              [106.91151432900004, 10.608667894000055],
              [106.91333221600009, 10.609180750000034],
              [106.91613954500011, 10.610735387000057],
              [106.91742723300007, 10.611101764000084],
              [106.91810772600007, 10.611097611],
              [106.91939121900003, 10.610790404000079],
              [106.92134631900007, 10.609057043000101],
              [106.92442523400008, 10.605670221000095],
              [106.92977556700002, 10.602793375000095],
              [106.93325656100009, 10.601387352000112],
              [106.93418753500002, 10.601007462000032],
              [106.94560527800007, 10.598264362000085],
              [106.94884195200008, 10.596744706000026],
              [106.95755683900005, 10.594548339000108],
              [106.95964468300005, 10.593892659000035],
              [106.96486061200005, 10.591682145000012],
              [106.966944756, 10.590455161000099],
              [106.97045332800005, 10.586362875000075],
              [106.97195351600001, 10.584068381000032],
              [106.97353220400007, 10.582773123000065],
              [106.97577257700007, 10.582397661000085],
              [106.97759056000004, 10.582751369000041],
              [106.97888689700009, 10.582275484000101],
              [106.98206191500007, 10.580033727000069],
              [106.9807440480001, 10.575105265000049],
              [106.97801114500001, 10.567954804000033],
              [106.9775881130001, 10.56599929800006],
              [106.97765281500003, 10.564447138000064],
              [106.97861174200006, 10.562667708000093],
              [106.98002084700003, 10.561254903000082],
              [106.98402841300006, 10.557682908000031],
              [106.9858063560001, 10.555602752000047],
              [106.98624515200007, 10.554196034000094],
              [106.9863345700001, 10.550685571000072],
              [106.98587491800008, 10.548841144000052],
              [106.9843595400001, 10.545303813000116],
              [106.98344265800009, 10.541984408000092],
              [106.98306099200005, 10.540656728000018],
              [106.98304396800006, 10.537996693000105],
              [106.98347755600005, 10.535777190000049],
              [106.98487707700012, 10.532886618000068],
              [106.98598750300008, 10.531475683000034],
              [106.98762128300007, 10.530209261000055],
              [106.99022851600006, 10.529453966000089],
              [106.99291510500004, 10.529437068000023],
              [106.99874937500003, 10.531469279000037],
              [107.00069208500001, 10.531826473000104],
              [107.00322560000004, 10.53121932000005],
              [107.00530848000005, 10.530171658000052],
              [107.01145401000007, 10.525016540000108],
              [107.01103126900009, 10.524341144000092],
              [107.01066497900005, 10.52347544300005],
              [107.00912448800005, 10.517657053000027],
              [107.00748470600008, 10.515063353000096],
              [107.00548628900003, 10.512781930000068],
              [107.00336929000011, 10.510749238000104],
              [107.00210022500008, 10.509703216000064],
              [107.00035069300002, 10.508722219000076],
              [107.00000001500004, 10.508585538000135],
              [106.99912851600006, 10.508102574000054],
              [106.99799565800002, 10.507172073000069],
              [106.99715443600005, 10.505477912000048],
              [106.99643071100003, 10.503607182000072],
              [106.99612139000001, 10.501616656000044],
              [106.99591674200005, 10.500000205000058],
              [106.99246503000009, 10.472732898000102],
              [106.99031480800008, 10.472594817000045],
              [106.97068750100006, 10.480786536000062],
              [106.96923299600006, 10.478146548000032],
              [106.96733895400011, 10.473680315000065],
              [106.96545382500001, 10.467458411000051],
              [106.96492330700008, 10.464079396000068],
              [106.96421897900007, 10.463515486000039],
              [106.96342104500005, 10.463396696000069],
              [106.96183599200008, 10.464263296000096],
              [106.95396521800006, 10.447049293000086],
              [106.94611458700011, 10.429646597],
              [106.95189423000004, 10.425642510000086],
              [106.96099571100009, 10.420539413000094],
              [106.96612753300006, 10.419283535000099],
              [106.96896161100008, 10.418765294000119],
              [106.97186341700009, 10.418180814000076],
              [106.97292961300003, 10.417130604000045],
              [106.97314363800007, 10.415406104000089],
              [106.97296978200006, 10.413022132000014],
              [106.97166227600007, 10.410524993000079],
              [106.9692602810001, 10.407946787000098],
              [106.96425408800005, 10.406088978000037],
              [106.95164707000001, 10.401260177],
              [106.91124668900002, 10.383456509000053],
              [106.90290624900004, 10.380113502000119],
              [106.89557324100011, 10.378384213000034],
              [106.88843210800006, 10.377654291000036],
              [106.879914533, 10.377797116000041],
              [106.87785156400001, 10.37792032700011],
              [106.876807942, 10.377982650000115],
              [106.8707677690001, 10.361502868000104],
              [106.86420650100007, 10.343598658000095],
              [106.82991450200002, 10.331776135000046],
              [106.82988668300003, 10.331375761000048],
              [106.82566220200007, 10.329447384000011],
              [106.82320869200002, 10.337316623000079],
              [106.81935788500009, 10.349666406000056],
              [106.80879555700004, 10.38353375500005],
              [106.80247160000002, 10.40208027800009],
              [106.80105168100003, 10.404951082000123],
              [106.79835712400002, 10.409981101000108],
              [106.79189400500003, 10.417987146000128],
              [106.79121817500007, 10.418626430000087],
              [106.7831043680001, 10.426390917000015],
              [106.77644747600007, 10.433397447000024],
              [106.77427848900001, 10.435680223000075],
              [106.76349364500003, 10.447680376000077],
              [106.75402252500002, 10.462026586000126],
              [106.753577815, 10.46270015100008],
              [106.75345155400004, 10.4629408530001],
              [106.74926973000007, 10.470912707000045],
              [106.73862194500005, 10.490286728000074],
              [106.73366293400008, 10.497731385000096],
              [106.73466925000011, 10.498427668000064],
              [106.73529531700004, 10.499263320000106],
              [106.73541724700002, 10.499650276000059],
              [106.73553321300001, 10.50002252700004],
              [106.73598818800008, 10.501944585000087],
              [106.73666715200004, 10.504108655],
              [106.73785982400005, 10.506421808000075],
              [106.73985051000008, 10.510282585000128],
              [106.74271207000007, 10.51474541500008],
              [106.74437820300008, 10.517343788000042],
              [106.74587508900004, 10.520912565000094],
              [106.74676418500005, 10.525120245000073],
              [106.74692932300005, 10.526885160000086],
              [106.74672223800005, 10.528675712000089],
              [106.74664071900006, 10.528984868000089],
              [106.74587168400011, 10.53190132800008],
              [106.74425358900007, 10.541926865000038],
              [106.74340299800004, 10.547196702000059],
              [106.74222358800006, 10.553965860000057],
              [106.74139928900007, 10.560484893000053],
              [106.74129605300006, 10.563029697000024],
              [106.74112702500001, 10.567196206000061],
              [106.74119851300007, 10.569826699000105],
              [106.741573418, 10.572115067000086],
              [106.74403146400007, 10.577240777000084],
              [106.74055271000002, 10.581758225000025],
              [106.73707799700001, 10.585594917000039],
              [106.73499897900004, 10.58694389600001],
              [106.73339687, 10.585572728000068],
              [106.73225631800004, 10.583977420000055],
              [106.73134865300008, 10.581929686000089],
              [106.72836340700007, 10.581003953000055],
              [106.72675013600009, 10.581448000000114],
              [106.72558994800002, 10.583029371000039],
              [106.72373389800002, 10.585514174000108],
              [106.72140493700005, 10.590038349000073],
              [106.71953038300003, 10.595472993000053],
              [106.71858741000008, 10.599097974000047],
              [106.71837983100005, 10.602742612000069],
              [106.7183290140001, 10.603634837000085],
              [106.72013721400006, 10.608865135000121],
              [106.72264711100007, 10.612284320000052],
              [106.72515849000003, 10.615476578000125],
              [106.72697963800007, 10.618664626000028],
              [106.72742438300001, 10.621163547000021],
              [106.72896774300008, 10.632065615000029],
              [106.72779322800007, 10.635916380000053],
              [106.72524210700001, 10.639077992000077],
              [106.72153176600007, 10.64359416200009],
              [106.71759820000003, 10.64697422700007],
              [106.71079984000002, 10.651125353000078],
              [106.70078962300008, 10.654212455000065],
              [106.69703404900011, 10.654458243000063],
              [106.68338539900006, 10.656931349000052],
              [106.68341230400006, 10.656624180000039],
              [106.68348619600005, 10.655780560000048],
              [106.68299936400008, 10.652857122000102],
              [106.68113653600005, 10.650135620000077],
              [106.67760621300008, 10.647795546000081],
              [106.67698396600011, 10.647475498000068],
              [106.672086576, 10.644985214000078],
              [106.66942460100009, 10.642723260000063],
              [106.66649779500005, 10.640726100000018],
              [106.66567542000004, 10.640891910000059],
              [106.66534821100005, 10.640975001000095],
              [106.66377962500003, 10.641418883000052],
              [106.65917252500005, 10.642070314000039],
              [106.65159302400005, 10.63975238100001],
              [106.64701732100009, 10.63563807700003],
              [106.64381354000004, 10.632894181000017],
              [106.64107158300001, 10.629926302000035],
              [106.63878272800008, 10.62600659400008],
              [106.63745706400009, 10.626354573000095],
              [106.63348253500004, 10.62733159800011],
              [106.633156903, 10.627355427000039],
              [106.63072575300002, 10.627573586000059],
              [106.6277382910001, 10.627797136000082],
              [106.62817123700005, 10.631884773000076],
              [106.62811059800001, 10.640961806000069],
              [106.62763820400008, 10.642774217000104],
              [106.61998582300002, 10.651347738000092],
              [106.61605197600005, 10.654499013000061],
              [106.60404422100004, 10.660320014000122],
              [106.60313881600008, 10.658961186000047],
              [106.60063058500003, 10.654623680000073],
              [106.60025793400011, 10.652981082000055],
              [106.59996026200008, 10.651668964000025],
              [106.59968716600005, 10.651662427000106],
              [106.59788741199999, 10.651882072000072],
              [106.59418957700009, 10.65412675000008],
              [106.59119300100005, 10.654787477000037],
              [106.5877392350001, 10.654991211000018],
              [106.582899868, 10.655866360000042],
              [106.56060730600007, 10.651175810000078],
              [106.55854398800005, 10.650026939000055],
              [106.55554734900009, 10.650687162000059],
              [106.55091247500012, 10.655194048000014],
              [106.55025884200003, 10.655904333000075],
              [106.5469694650001, 10.659478716000057],
              [106.54786760000007, 10.662662159000112],
              [106.55106255600001, 10.666542326000082],
              [106.55194319000005, 10.672222100000093],
              [106.55168101000002, 10.676759288000069],
              [106.54979315700005, 10.683327846000077],
              [106.54740928700008, 10.688454404000057],
              [106.53592978600007, 10.6870465620001],
              [106.53378828800007, 10.686854074000072],
              [106.53316736500004, 10.688323422000055],
              [106.51817048500007, 10.724637833000104],
              [106.50761584500003, 10.718692206000034],
              [106.48736219100005, 10.729022450000068],
              [106.48057393000006, 10.737582970000046],
              [106.47265714400007, 10.747565614000097],
              [106.46369449900003, 10.758865679000083],
              [106.49897278000006, 10.786469270000064],
              [106.50038701700001, 10.789667398000089],
              [106.50803605900006, 10.806963159000114],
              [106.51161578000004, 10.821516444000066],
              [106.52503140500002, 10.874175659000061],
              [106.534068711, 10.902945432000076],
              [106.49446814700011, 10.921724442000079],
              [106.493630635, 10.921795167000068],
              [106.45144130700005, 10.942792288000101],
              [106.416587866, 10.969686858000037],
              [106.40934935100007, 10.97517213000012],
              [106.37126385000003, 10.970392207000133],
              [106.35460087900005, 10.992752926000014],
              [106.35645284000007, 10.997216457000112],
              [106.36864924500006, 11.000036242000098],
              [106.37532391600006, 11.001450326000038],
              [106.38132140000006, 11.001042492000044],
              [106.38869492800006, 11.001553290000031],
              [106.39353260100003, 11.00204445500008],
              [106.39882251300003, 11.003674269000017],
              [106.40502583100002, 11.00644619700004],
              [106.40870215300008, 11.008063507000097],
              [106.41053769100007, 11.00921272200006],
              [106.40866163400001, 11.013285678000083],
              [106.40701429000005, 11.01758744600003],
              [106.40675727100009, 11.020991505000042],
              [106.40742073600011, 11.024629632000059],
              [106.40877415300008, 11.028500057000111],
              [106.41197393500008, 11.032157398000029],
              [106.41656077900006, 11.035371012],
              [106.42044729600012, 11.03971464800013],
              [106.42015199800002, 11.048114198000023],
              [106.42011001400002, 11.053563739000081],
              [106.420986923, 11.059474378000099],
              [106.42255920200006, 11.064936130000101],
              [106.42366336000003, 11.071302735000097],
              [106.42389357600007, 11.087301954000049],
              [106.42396401800004, 11.092197004000058],
              [106.42764201700007, 11.093814189000016],
              [106.43487166800006, 11.113625330000064],
              [106.43670099700007, 11.117512704000097],
              [106.44572060100003, 11.136677531000048],
              [106.44770705200007, 11.136823134000016],
              [106.45082047400011, 11.137848497000034],
              [106.45253977400007, 11.139518076000064],
              [106.45422766900002, 11.141703564000059],
              [106.45515457900007, 11.14542917000003],
              [106.45445458800006, 11.147555690000031],
              [106.45321796600005, 11.149021080000042],
              [106.45048511600007, 11.151761649000045],
              [106.44946787500001, 11.153131417000049],
              [106.44937697600004, 11.154244471000128],
              [106.44945260800009, 11.156312119000054],
              [106.4497425110001, 11.157971224000017],
              [106.45090163100008, 11.159456545000078],
              [106.45257393600006, 11.160541088000018],
              [106.45463435800011, 11.160746412000043],
              [106.45785888900009, 11.160735113000085],
              [106.460609085, 11.160661478000032],
              [106.46255574200003, 11.160105989000078],
              [106.46639351200005, 11.158063556000018],
              [106.46793279100005, 11.156367962000056],
              [106.4688254430001, 11.154636003000105],
              [106.46852095500005, 11.152972306000029],
              [106.46677944600002, 11.15056922400006],
              [106.46591114100002, 11.149041742000064],
              [106.46623816000007, 11.147405205000014],
              [106.46845310500002, 11.145568606000021],
              [106.4698215220001, 11.144479610000067],
              [106.47199783200004, 11.143870704000046],
              [106.47384385900001, 11.143313669000053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 59, code: "AD01", ten_tinh: "Trà Vinh" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.33031001700007, 10.011512098000084],
              [106.35479128900008, 10.000000159000065],
              [106.36465978200002, 9.989589392000118],
              [106.37295867300001, 9.982371741000049],
              [106.37339594900004, 9.981991415000023],
              [106.37572364900009, 9.980688608000053],
              [106.37986125900004, 9.978372743000106],
              [106.38640289500005, 9.974691853000104],
              [106.39678420900003, 9.968849787000076],
              [106.39881303300004, 9.967299047000021],
              [106.40221953700008, 9.964695261000054],
              [106.41046208900003, 9.958394554000092],
              [106.42434111600006, 9.950584700000052],
              [106.43460594300011, 9.9444912980001],
              [106.44735277700008, 9.939521185],
              [106.45838656600006, 9.935125235000085],
              [106.468653551, 9.929787109000097],
              [106.46889725300009, 9.92962314700012],
              [106.47976809800009, 9.922308767000041],
              [106.49001805600005, 9.913378323000098],
              [106.49703416000006, 9.905785376000063],
              [106.50556566500003, 9.896295119000074],
              [106.51826415600009, 9.88149276400012],
              [106.52388875300007, 9.875220832000052],
              [106.52660478600008, 9.872192044000093],
              [106.53694331300008, 9.862428374000041],
              [106.55004047300008, 9.85197127600008],
              [106.55444910000008, 9.847945544000057],
              [106.56294003100007, 9.840191436000099],
              [106.57014789900005, 9.833541705000036],
              [106.57734114400004, 9.82386734000009],
              [106.58193095900003, 9.81696526800002],
              [106.58755476300011, 9.808507655000119],
              [106.59620724100006, 9.800222477000096],
              [106.58237678800006, 9.778890507000069],
              [106.56063822600005, 9.745352019000018],
              [106.56151340600007, 9.744737964000104],
              [106.56424789200003, 9.742819315000071],
              [106.56672195200008, 9.738289643000096],
              [106.56892437300006, 9.73148483700008],
              [106.56943726900005, 9.719695846000052],
              [106.56849316100009, 9.700509351000084],
              [106.56866049500007, 9.697100786000055],
              [106.568953702, 9.696250365000124],
              [106.57024941200009, 9.695833010000053],
              [106.57196717000002, 9.696697167000055],
              [106.57470124100011, 9.696005461000111],
              [106.576436685, 9.694312428],
              [106.57717352800003, 9.691618112000098],
              [106.57795055500003, 9.683099297000107],
              [106.57779494800012, 9.674432204000103],
              [106.57641662900002, 9.666041061000017],
              [106.57247998200009, 9.653796978000083],
              [106.56521370700008, 9.635137781000045],
              [106.55955526000005, 9.622882320000057],
              [106.552244333, 9.61089964900008],
              [106.55206392300008, 9.610636782000091],
              [106.54499600100006, 9.600337873000074],
              [106.53631277600005, 9.589766021000033],
              [106.532967318, 9.585765394000044],
              [106.52698747200003, 9.578763361000052],
              [106.51471017200001, 9.569159943000026],
              [106.50471318500011, 9.561986874000093],
              [106.49843030900011, 9.557396745000089],
              [106.47860427700007, 9.549867718000103],
              [106.4611394680001, 9.543490537000119],
              [106.44438159300009, 9.53867955600005],
              [106.43049068200004, 9.534456919000077],
              [106.41953929000005, 9.530681708000087],
              [106.41452909, 9.528939450000083],
              [106.41051595400005, 9.52819885500009],
              [106.40735409800001, 9.528742906000074],
              [106.40633955900009, 9.530013426000037],
              [106.4071941120001, 9.530872104000119],
              [106.4099024170001, 9.533307191000072],
              [106.41089614200007, 9.534735031000084],
              [106.41108378700005, 9.535842292000039],
              [106.41102871500001, 9.536156328000088],
              [106.41016317499999, 9.536717865000098],
              [106.4081537970001, 9.536702580000076],
              [106.40629394000004, 9.536889442000083],
              [106.40499069600003, 9.537388631000086],
              [106.40426646100006, 9.538235286000061],
              [106.40370500500006, 9.539301598000092],
              [106.40346693500008, 9.539397625000044],
              [106.40311053000008, 9.539220671000058],
              [106.39963231200005, 9.537079902000032],
              [106.39648226000001, 9.534438249000042],
              [106.39326240300005, 9.532043775000075],
              [106.38939503299999, 9.531019831],
              [106.38251706700005, 9.529546469000126],
              [106.37850627800002, 9.528521218000028],
              [106.37620769700008, 9.528787441000118],
              [106.37433183500004, 9.530051109000047],
              [106.37402461500002, 9.532605231000078],
              [106.37443054300003, 9.535733025000058],
              [106.37584677400005, 9.538158531000105],
              [106.37827445500002, 9.539739699000014],
              [106.37898763900006, 9.54031335700005],
              [106.37898093600003, 9.541165481000034],
              [106.37057196100007, 9.55175254800009],
              [106.36745665100005, 9.555421140000052],
              [106.35979024100007, 9.562747009000104],
              [106.35111503100002, 9.570490868000102],
              [106.34141711700008, 9.580356988000103],
              [106.33312006000006, 9.585546584000058],
              [106.32806431600007, 9.589341409000072],
              [106.3228798440001, 9.591288518000061],
              [106.31375966600008, 9.593856657000098],
              [106.30187852600007, 9.584214585000071],
              [106.30062562400005, 9.585129737000029],
              [106.29392275600003, 9.589432081000025],
              [106.28613219600008, 9.594707874000095],
              [106.27340827800006, 9.60159854800011],
              [106.27014249800003, 9.603388617000073],
              [106.262923089, 9.607345623000095],
              [106.2612572880001, 9.608258607000051],
              [106.24504444400006, 9.619438406000123],
              [106.22786620900007, 9.633985846000124],
              [106.21165487700007, 9.64782855600008],
              [106.21062964100007, 9.648703903000031],
              [106.19849211400006, 9.660038645000045],
              [106.19139327100005, 9.666907128000089],
              [106.179081099, 9.677956784000051],
              [106.17845968100009, 9.678555416000021],
              [106.17278297100006, 9.684023760000036],
              [106.16534154700005, 9.691805511000061],
              [106.15120670000007, 9.707993477000086],
              [106.14204607100001, 9.71737752100008],
              [106.13448171600002, 9.724256728000034],
              [106.13099712300001, 9.727425482000028],
              [106.13059015100001, 9.727795565000095],
              [106.13026759200005, 9.728168736000063],
              [106.12509185900009, 9.734156361000055],
              [106.11897122200001, 9.741236582000033],
              [106.11816196000002, 9.742241371000125],
              [106.11141768700004, 9.75061470600007],
              [106.109706057, 9.751974761000069],
              [106.10190402800006, 9.758173985000072],
              [106.10053339299999, 9.759185715000028],
              [106.09181492900009, 9.765620822000097],
              [106.08401286700007, 9.769638098000131],
              [106.07592016100008, 9.772686575000121],
              [106.07293822100009, 9.774635135000052],
              [106.07181681600012, 9.775554290000075],
              [106.06846761000003, 9.778299380000098],
              [106.06347028300009, 9.784730015000063],
              [106.061714173, 9.786989698000069],
              [106.05576424400002, 9.795449266000043],
              [106.05044026900005, 9.802024708000088],
              [106.04230231400004, 9.809350182000019],
              [106.03290206700004, 9.817477835000064],
              [106.01840653600007, 9.832293678000069],
              [106.01198692300004, 9.838130484000105],
              [106.00408542700005, 9.848192148000033],
              [105.99744356900001, 9.856767059000047],
              [105.99544697400002, 9.86184905900007],
              [105.99442051400008, 9.864589750000084],
              [105.99167332600007, 9.868590289000066],
              [105.98823627399999, 9.872592821000035],
              [105.98493461700011, 9.876862564000087],
              [105.98319702800003, 9.879109555000108],
              [105.97912098600003, 9.884277838000076],
              [105.97897722200004, 9.884494060000025],
              [105.97779531200004, 9.886459846000049],
              [105.97456416000003, 9.891778006000072],
              [105.97259806300006, 9.895013875000112],
              [105.97129504900008, 9.8966142520001],
              [105.95791060800012, 9.91178785700013],
              [105.95220120700006, 9.919276560000066],
              [105.951877467, 9.920577046000021],
              [105.95237386800004, 9.922362522000048],
              [105.95598008100008, 9.923651825000055],
              [105.96219632600007, 9.921522258000069],
              [105.97101948400011, 9.914999078000085],
              [105.97692027600002, 9.910639555000065],
              [105.98329010500001, 9.903321597000046],
              [105.98748106000004, 9.904213787000058],
              [105.99418880100009, 9.905612045000021],
              [105.99663577300007, 9.907413986000078],
              [106.00018783000006, 9.910782673000094],
              [106.00709622800012, 9.914627932000107],
              [106.01200139900007, 9.917120061000071],
              [106.01310872000003, 9.918464487000021],
              [106.01375509300004, 9.921138449000109],
              [106.01431313800003, 9.922932473000044],
              [106.01701508600004, 9.931618478000102],
              [106.01835956100008, 9.93398529400009],
              [106.02458443000008, 9.93039601300003],
              [106.02880022500003, 9.928568544000081],
              [106.03266811200004, 9.927998315000062],
              [106.03527286300009, 9.928478812000028],
              [106.03668198100006, 9.929102573000124],
              [106.03738885400001, 9.930147246000052],
              [106.0377463560001, 9.931960682000083],
              [106.03859615400007, 9.933702813000076],
              [106.03972584800002, 9.934955538000077],
              [106.04099583200012, 9.936068225000037],
              [106.04362655700001, 9.938556981000078],
              [106.04519314700003, 9.939513644],
              [106.04787045000005, 9.940652934000037],
              [106.0509412320001, 9.942597428000076],
              [106.05210073700003, 9.943369223000074],
              [106.05219534500004, 9.943617077000065],
              [106.05207443200007, 9.944920206000061],
              [106.0520027970001, 9.946967602000088],
              [106.05200640100004, 9.948093496000075],
              [106.07246471900008, 9.944384116000025],
              [106.08337031500011, 9.96228650700006],
              [106.11107368400009, 9.939600667000102],
              [106.11125985600003, 9.939776999000037],
              [106.11399203200006, 9.942647496000044],
              [106.11685118500002, 9.945727531000069],
              [106.11781943600002, 9.946762805000018],
              [106.11808020200002, 9.947059247000121],
              [106.11941879700001, 9.948621511000065],
              [106.12198420500009, 9.951606527000044],
              [106.12379950500009, 9.95387802600005],
              [106.12486373700004, 9.955209635000093],
              [106.1250576370001, 9.955467272000094],
              [106.125755263, 9.956410775000089],
              [106.12768337200001, 9.959120249000096],
              [106.1299730540001, 9.962165129000054],
              [106.13004931600003, 9.962435027000092],
              [106.13035684000006, 9.965271946000074],
              [106.13084817800004, 9.970081032000076],
              [106.13127534100003, 9.974741561000132],
              [106.13167447000011, 9.979002447000072],
              [106.13177689300005, 9.980317025000128],
              [106.1318106790001, 9.981547916000098],
              [106.13178789100007, 9.98205854600007],
              [106.13169629500004, 9.982417861000046],
              [106.13153607100003, 9.982860225000055],
              [106.12965436200003, 9.9896256740001],
              [106.12932196300005, 9.990869596000083],
              [106.12921960800003, 9.991207897],
              [106.12918444400005, 9.991766833000069],
              [106.12919117300007, 9.992158457000054],
              [106.12934427300004, 9.99274678400006],
              [106.12969922900001, 9.993852828000092],
              [106.13055972700006, 9.996441790000075],
              [106.13191128800007, 9.99990552100005],
              [106.13182077500004, 10.000596405000104],
              [106.13312762500007, 10.025123374000076],
              [106.1332321240001, 10.025375981000105],
              [106.14869796500007, 10.018209116000063],
              [106.18741508500005, 10.026439808000108],
              [106.19132661200004, 10.026137791000076],
              [106.19320771900009, 10.024810701000051],
              [106.19485599800004, 10.020962849000091],
              [106.19659507300004, 10.016444140000134],
              [106.20054312500012, 10.011944424000061],
              [106.20088473800006, 10.011555067000087],
              [106.20108957700003, 10.011400299000071],
              [106.20134828200004, 10.011162667000038],
              [106.20427588600006, 10.008092997000071],
              [106.20595167199998, 10.006389010000037],
              [106.21103761300007, 10.005006280000115],
              [106.21157186700005, 10.00486643000009],
              [106.21221688200004, 10.004705680000113],
              [106.21240016200001, 10.004885044000032],
              [106.21594407100005, 10.010185569000017],
              [106.21763669700003, 10.012759261000069],
              [106.21916965900003, 10.015090123000013],
              [106.224908048, 10.019372639000055],
              [106.22777810300008, 10.02157983700007],
              [106.22895896900008, 10.022597322000058],
              [106.23013119000008, 10.024622221000088],
              [106.23010952600006, 10.02714078200002],
              [106.22990636500003, 10.031001156000039],
              [106.22986302900009, 10.036038344000085],
              [106.2296463170001, 10.042186572000043],
              [106.22101176600003, 10.045102624],
              [106.22573156400003, 10.063506597000059],
              [106.22749320100003, 10.070527862000043],
              [106.22829206900005, 10.073832244000068],
              [106.23153199300008, 10.078458331000116],
              [106.23944849800006, 10.082757634000037],
              [106.24063833300009, 10.082445109000025],
              [106.256899015, 10.075317844000063],
              [106.27385717300007, 10.060833040000093],
              [106.28751437200005, 10.051509868000073],
              [106.29645436700007, 10.038683043000061],
              [106.30296653300003, 10.030870871],
              [106.30890199800007, 10.02435864200004],
              [106.31869907400004, 10.017871029000068],
              [106.3191979580001, 10.017540652],
              [106.32510931100009, 10.014272404000033],
              [106.32891775900008, 10.012166669000065],
              [106.33031001700007, 10.011512098000084],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 60, code: "AD01", ten_tinh: "Tuyên Quang" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.21744103700011, 22.662338636000086],
              [105.21968031900012, 22.662272011000084],
              [105.22546494100001, 22.663288602000087],
              [105.22881639900002, 22.663708062000062],
              [105.22983635300004, 22.663531745000043],
              [105.23188576700005, 22.662517870000087],
              [105.23660570300005, 22.659741929000084],
              [105.24030964400001, 22.656858848000105],
              [105.24103791200005, 22.655734097000035],
              [105.24167257500007, 22.65404132200004],
              [105.24236101900001, 22.651928329],
              [105.24309043700005, 22.65046820600007],
              [105.24409538200007, 22.65124485300003],
              [105.244976003, 22.652011063000096],
              [105.24544097200003, 22.652415675],
              [105.24581725800007, 22.652696824000081],
              [105.24624904400009, 22.653019543000049],
              [105.24779331100007, 22.654066299000064],
              [105.24885113800011, 22.654026586000064],
              [105.25071958900006, 22.653637660000037],
              [105.25415591800008, 22.652491161000029],
              [105.26380006600002, 22.649984905000053],
              [105.26738898200004, 22.649328929000134],
              [105.27459148600011, 22.646267443000042],
              [105.27877997500009, 22.643169063000101],
              [105.28203147100001, 22.639709263],
              [105.28396930400002, 22.635883494],
              [105.28614221800004, 22.628736087000064],
              [105.28599082300005, 22.626109665000094],
              [105.2852990590001, 22.621726918000014],
              [105.28515261900006, 22.618750565000042],
              [105.28573487900009, 22.617532803000095],
              [105.2885796130001, 22.616167507000014],
              [105.29914354700001, 22.615070517000021],
              [105.30876814600002, 22.613786755000028],
              [105.309907911, 22.613100561000095],
              [105.31029434500009, 22.612405300000127],
              [105.31071250200004, 22.609435699000088],
              [105.31110380700009, 22.608390531000033],
              [105.3124489410001, 22.606481929000033],
              [105.31434926000004, 22.605279904000049],
              [105.31529332300008, 22.605116262000102],
              [105.31623251200007, 22.605302518000059],
              [105.31640621800005, 22.606354484000057],
              [105.31639989500007, 22.606808549000057],
              [105.31639403800003, 22.607229236000045],
              [105.31520802700004, 22.611239511000036],
              [105.31478992500008, 22.614209109000072],
              [105.31511541300002, 22.617887529000043],
              [105.31616990800005, 22.623324399000033],
              [105.31746395800009, 22.625089659000075],
              [105.31915418600005, 22.625459875000061],
              [105.32178128500009, 22.62619124600009],
              [105.32335467600006, 22.627449694000035],
              [105.32391250400012, 22.629498443000081],
              [105.32387847900006, 22.631948516000122],
              [105.32438907700002, 22.637400081000081],
              [105.32496206100009, 22.638359881],
              [105.32714713300005, 22.639338951000099],
              [105.33636687000003, 22.640401905000118],
              [105.343542423, 22.640895656000026],
              [105.3536640830001, 22.640334989000088],
              [105.35879910400007, 22.639987287000096],
              [105.35983057900006, 22.639591067000083],
              [105.36057267299999, 22.638919156],
              [105.36221271400007, 22.636896472000068],
              [105.36359679600011, 22.635752175000064],
              [105.3710273180001, 22.633460787000011],
              [105.38133353500011, 22.628956103000064],
              [105.38290025300006, 22.628842282000058],
              [105.38446517700005, 22.628860592000066],
              [105.38659378400004, 22.629281957000025],
              [105.3887188300001, 22.629967592000085],
              [105.39212785799999, 22.630403852000121],
              [105.39341004400003, 22.630286640000108],
              [105.39541428800004, 22.629384869000098],
              [105.39929292700006, 22.626654630000061],
              [105.40159409800003, 22.624831117],
              [105.40405172000008, 22.621952098000087],
              [105.40754419900003, 22.616177417000038],
              [105.40928858400004, 22.613422175000061],
              [105.41014921500002, 22.612903479000046],
              [105.411004507, 22.612781218000052],
              [105.41628740400003, 22.611388511000108],
              [105.42396700400005, 22.60994855200007],
              [105.42864375100001, 22.609607811000032],
              [105.42978324800006, 22.609094819000042],
              [105.43296068100007, 22.606881680000065],
              [105.43389483300008, 22.608258549000126],
              [105.43516814900002, 22.609651035000056],
              [105.43573749600004, 22.610013149000082],
              [105.43659506700004, 22.610289667000046],
              [105.43774203400008, 22.610391712000052],
              [105.43836395200003, 22.610398838000023],
              [105.43884294500005, 22.610359877000086],
              [105.43947016700007, 22.609967029000089],
              [105.439761339, 22.609659230000112],
              [105.43991194700003, 22.609127584000085],
              [105.43992906600002, 22.607838792000031],
              [105.44009264800006, 22.606329436000024],
              [105.44028931900007, 22.605931662000067],
              [105.44048332800006, 22.605733866000044],
              [105.44086573600008, 22.605760463000109],
              [105.44134058700011, 22.606032594000069],
              [105.44642927500007, 22.609715484000056],
              [105.447830439, 22.610620767000086],
              [105.44851914400004, 22.610880778000109],
              [105.44887652100005, 22.610802099000082],
              [105.44951422200005, 22.610639486000039],
              [105.45002528500007, 22.610466223000088],
              [105.45047962500009, 22.610227520000059],
              [105.45216712600009, 22.609509405000061],
              [105.4527630060001, 22.609262117000029],
              [105.45311412400011, 22.609220056000098],
              [105.45418550900011, 22.609211078000065],
              [105.4558224350001, 22.60952613900011],
              [105.45750270100004, 22.610011066000091],
              [105.45822638700005, 22.610442765000101],
              [105.45880998100003, 22.611000345000051],
              [105.46164609200011, 22.613348494],
              [105.463431998, 22.614989908000013],
              [105.46508670900005, 22.616073966],
              [105.47310417900003, 22.62011033200011],
              [105.47493250200006, 22.62105162200001],
              [105.47592349100006, 22.621062811000044],
              [105.47819374900006, 22.620693871000071],
              [105.48217312100007, 22.619555039000083],
              [105.48387193800002, 22.619574162000063],
              [105.48528417700008, 22.619853089000053],
              [105.48906197100011, 22.623315047000055],
              [105.49228215300009, 22.626113064000016],
              [105.49395365800011, 22.628236092000087],
              [105.49588443900008, 22.632203218000058],
              [105.49629736800004, 22.634995205000052],
              [105.51092172500002, 22.637160307000045],
              [105.51466652100007, 22.637201956000027],
              [105.51966557000006, 22.636793655000083],
              [105.52978435800001, 22.634619764000071],
              [105.52787053700011, 22.627734357000051],
              [105.52747548300002, 22.62242385200004],
              [105.5274957190001, 22.620846564000097],
              [105.52844216400007, 22.619279510000112],
              [105.5337603790001, 22.613888597000106],
              [105.54564355300002, 22.602115981000011],
              [105.54705098400002, 22.600697264000047],
              [105.54721629200003, 22.599838578000053],
              [105.54692400900009, 22.598544618000105],
              [105.54573850000006, 22.594659336000063],
              [105.54546449500003, 22.59193137000004],
              [105.54549736200008, 22.589350177000078],
              [105.54568275200006, 22.58691406500013],
              [105.54617315100005, 22.584768132000121],
              [105.54822152600006, 22.581491903000114],
              [105.55135198800008, 22.578084075000056],
              [105.55602566300007, 22.574693048000057],
              [105.55837878500009, 22.571706841000072],
              [105.56089885800006, 22.567718427000024],
              [105.56202629500008, 22.564001630000071],
              [105.562819663, 22.562289147000044],
              [105.56360033200011, 22.561580516000035],
              [105.56499104400007, 22.561452243000076],
              [105.57070093200008, 22.561514378000069],
              [105.57364206700007, 22.560829183000038],
              [105.57659579800004, 22.559140083000081],
              [105.58019374800001, 22.555306465000058],
              [105.58348113500006, 22.551612833000064],
              [105.58615694200003, 22.548919392000052],
              [105.58886570400007, 22.544832042000081],
              [105.58960002300007, 22.542781654000073],
              [105.5903518150001, 22.539331779000094],
              [105.59162473100008, 22.53671084000004],
              [105.59307679300007, 22.533927126000016],
              [105.59354738500009, 22.531709172000092],
              [105.59597844600007, 22.521443419000065],
              [105.59599486500009, 22.520126212000058],
              [105.5956642150001, 22.518228917000016],
              [105.59462713600008, 22.516159329000018],
              [105.59131321500007, 22.512006748000069],
              [105.59079625500001, 22.51084843800006],
              [105.59044230800004, 22.509876153000086],
              [105.59045064700011, 22.509208623000099],
              [105.59120086600009, 22.499452716000064],
              [105.59232371200001, 22.495792840000028],
              [105.59342881800006, 22.493551469000025],
              [105.59497951300003, 22.49156525500009],
              [105.59571205600005, 22.490404762000082],
              [105.59571933700003, 22.48982065400007],
              [105.59547558100003, 22.489353942],
              [105.59465277700008, 22.488974619000054],
              [105.59107541700011, 22.487934600000067],
              [105.58795517800004, 22.486231805000045],
              [105.57567625300007, 22.477669721000026],
              [105.566272973, 22.470308462000062],
              [105.55851677000004, 22.463306147000047],
              [105.55771652600001, 22.462416963000109],
              [105.55718992700004, 22.461279214000029],
              [105.55578205299999, 22.454974913000036],
              [105.55528796300005, 22.447730463000056],
              [105.5549836750001, 22.443268597],
              [105.55446417800002, 22.43999446],
              [105.55423980900011, 22.4392678],
              [105.55385514000008, 22.438901481000073],
              [105.54918502200012, 22.438777856000058],
              [105.54778370400007, 22.438762472000029],
              [105.547240593, 22.438611663000074],
              [105.54708949200001, 22.438247906000086],
              [105.54709317400005, 22.437958266000102],
              [105.54797437000005, 22.436012578000053],
              [105.55122592400005, 22.43134087200005],
              [105.55202277100008, 22.429901177000119],
              [105.55203287500002, 22.429104642000119],
              [105.55166477500003, 22.427434901000076],
              [105.55029571000009, 22.424885100000047],
              [105.54915654700007, 22.422627490000124],
              [105.54893772600008, 22.421466320000057],
              [105.54863108400011, 22.420787171000086],
              [105.549005483, 22.418915788000056],
              [105.54948305000008, 22.415822371000083],
              [105.54993264800007, 22.414930315000078],
              [105.55082356400007, 22.413798473000043],
              [105.55241354300009, 22.412837376000105],
              [105.55382511600007, 22.412118946000078],
              [105.55681526300003, 22.411336247000065],
              [105.55713721300003, 22.41134711800008],
              [105.55818273000003, 22.411764621000074],
              [105.56182819800003, 22.415797874000098],
              [105.56595632100009, 22.419392591000076],
              [105.56726083700002, 22.419961444000066],
              [105.56845032100003, 22.420196248000046],
              [105.56916708900009, 22.420093129000037],
              [105.56940973300001, 22.419762992000081],
              [105.56941811399999, 22.419097524000087],
              [105.56742620000003, 22.416302652000027],
              [105.56287303500005, 22.408598935000065],
              [105.56030065000003, 22.404577366000034],
              [105.55912109100004, 22.403566101000116],
              [105.55769608600008, 22.403106798000039],
              [105.55519393300008, 22.402968474000048],
              [105.54502237600005, 22.405851976000065],
              [105.53796016100004, 22.4079928],
              [105.53580570000004, 22.408634606000085],
              [105.53401739100005, 22.40861486000005],
              [105.53223475500008, 22.40815144900008],
              [105.52083520000012, 22.404475383000118],
              [105.51941599000003, 22.403572172000082],
              [105.51752705300005, 22.402109080000088],
              [105.51706588500008, 22.400883707000041],
              [105.51696521400001, 22.399440474000087],
              [105.51523481600002, 22.385665492000051],
              [105.51477941399999, 22.383996390000057],
              [105.51339324300005, 22.380541946000051],
              [105.512350424, 22.378200667000044],
              [105.51208433900011, 22.376438763000102],
              [105.51211729500008, 22.373881303000061],
              [105.51286480800012, 22.372004906000065],
              [105.51404625100008, 22.370133334000016],
              [105.51494184800002, 22.367989313000081],
              [105.51483011500001, 22.365430196000077],
              [105.51335000400012, 22.356797606],
              [105.51285469200008, 22.350329902000077],
              [105.51200772600008, 22.348704905000091],
              [105.51086457000008, 22.347615117000075],
              [105.50798068300003, 22.346909773000064],
              [105.50076321800007, 22.345752013000016],
              [105.49817040100004, 22.344915169000053],
              [105.49572745800006, 22.343676088000031],
              [105.49458792600009, 22.342316977000017],
              [105.49460539000003, 22.340970849000108],
              [105.49609874200004, 22.337352519000042],
              [105.49641765900006, 22.335067322000057],
              [105.49630816200005, 22.331357071000113],
              [105.49573325400009, 22.326446589000071],
              [105.49545447600012, 22.323322694000055],
              [105.49514681200006, 22.322427588000068],
              [105.4943680100001, 22.320932746000018],
              [105.49294111600008, 22.320173672000095],
              [105.49007772600004, 22.319398431000081],
              [105.48784121200001, 22.31952186200008],
              [105.48496429800008, 22.319786673000053],
              [105.48353165200002, 22.319473296000055],
              [105.48083954300003, 22.317808205000041],
              [105.47864571500006, 22.314662590000097],
              [105.47726163500003, 22.310634423000053],
              [105.47713114000007, 22.308403748000046],
              [105.47715248800006, 22.30676924200008],
              [105.47749692400005, 22.304841152000066],
              [105.47928356300008, 22.302483505000033],
              [105.48443330100011, 22.299272086000066],
              [105.48829260700005, 22.297086332000049],
              [105.48958481000003, 22.295911940000046],
              [105.4895963960001, 22.295020367000085],
              [105.48880809000006, 22.294268408000036],
              [105.47340732300006, 22.28800113000009],
              [105.46806918400009, 22.286539363000109],
              [105.46198220000004, 22.285749227000018],
              [105.45966449500003, 22.285362367000069],
              [105.45918420700008, 22.285086533000076],
              [105.4589954090001, 22.284723897000092],
              [105.45890812700006, 22.284001950000039],
              [105.45998797000006, 22.282842678000044],
              [105.46155163500003, 22.281688914000064],
              [105.46505651500003, 22.28010659600006],
              [105.46827813000004, 22.277980287000076],
              [105.47033614500006, 22.276020956000053],
              [105.47073382200001, 22.275214377000019],
              [105.47073854000006, 22.274853939000039],
              [105.47055091000007, 22.274401204000057],
              [105.46491401700005, 22.268839730000067],
              [105.46233345100006, 22.266377049000049],
              [105.460887986, 22.265909982000039],
              [105.45982127400003, 22.266078085000096],
              [105.45602158200006, 22.268017489000066],
              [105.454266847, 22.268988826000054],
              [105.45329330600001, 22.269428324],
              [105.45232570000006, 22.269417273000066],
              [105.45184546300003, 22.269141414000067],
              [105.45051817100007, 22.267053410000059],
              [105.44975242400004, 22.266413791000069],
              [105.44821141500009, 22.265855425000055],
              [105.43674258699998, 22.262299190000064],
              [105.43510609200003, 22.261649504000047],
              [105.43453393200012, 22.261012052000062],
              [105.43425203100006, 22.260377936000083],
              [105.43501594100003, 22.256105636000086],
              [105.43571544200009, 22.252193345000038],
              [105.43634855200007, 22.248235053000087],
              [105.43788892700007, 22.240671549000091],
              [105.43923173200005, 22.23479573900002],
              [105.43946462900006, 22.233129214000066],
              [105.43937094400003, 22.232244446000045],
              [105.43905867500004, 22.231946295000071],
              [105.43822074700007, 22.231543917000081],
              [105.43611944400003, 22.231028814000098],
              [105.43497317400005, 22.230033746000096],
              [105.43414569900006, 22.228845961000054],
              [105.4339518670001, 22.227567266000037],
              [105.43386342500006, 22.22628978900012],
              [105.43329888800007, 22.221177404000066],
              [105.43310376500008, 22.219996873000092],
              [105.43269266200009, 22.219206605000075],
              [105.43227895300006, 22.218612696000051],
              [105.4314410970001, 22.218210282000058],
              [105.43007633000006, 22.217801790000102],
              [105.42987209200004, 22.217308481000053],
              [105.42990081200003, 22.215148594000041],
              [105.43098986700006, 22.212509959000037],
              [105.43398223000003, 22.209402257000121],
              [105.43782661800006, 22.205616929000094],
              [105.43921341800005, 22.204182569000075],
              [105.44293547400008, 22.200332697000036],
              [105.44552210000006, 22.196348678000085],
              [105.44733454900009, 22.193418187000091],
              [105.44926426600006, 22.191197317000011],
              [105.45075504800005, 22.190115168000091],
              [105.45231677800004, 22.189238836000058],
              [105.45320880000003, 22.18876753800005],
              [105.45372544600005, 22.188773440000098],
              [105.45423847900005, 22.189054440000078],
              [105.45533199600003, 22.190098679000059],
              [105.45635715100011, 22.190729436000112],
              [105.45679819000001, 22.190872033000097],
              [105.45738865500002, 22.19087876900003],
              [105.45820595400005, 22.190475387000049],
              [105.45881269000007, 22.189244201000058],
              [105.46177061800003, 22.183224896000105],
              [105.46200376600004, 22.182333352000057],
              [105.46193718900004, 22.181782316000032],
              [105.46179319100003, 22.181505532000045],
              [105.46084818900009, 22.180394210000109],
              [105.46012190400003, 22.179491731000041],
              [105.45990953300006, 22.178801461000127],
              [105.45977548000006, 22.177768158000077],
              [105.45967589100007, 22.175248494000051],
              [105.45980155400008, 22.173936361000109],
              [105.460588296, 22.171823409000076],
              [105.46269315000006, 22.168411877000068],
              [105.4661146880001, 22.163802725],
              [105.46820216900007, 22.161704490000119],
              [105.4694669940001, 22.162255203000122],
              [105.47335438600004, 22.164244477000047],
              [105.47465389500009, 22.164623925000093],
              [105.47596135400009, 22.16439559100003],
              [105.48657977000008, 22.160382104000043],
              [105.48998503400001, 22.159326316000083],
              [105.49104263100003, 22.158244055000061],
              [105.49106628900003, 22.156420708000013],
              [105.49122351700008, 22.154355698000039],
              [105.491112027, 22.152895534000017],
              [105.49073022700003, 22.152161779000053],
              [105.49008443800007, 22.151668202000074],
              [105.484369691, 22.149780123000085],
              [105.4808800640001, 22.147309160000091],
              [105.47393443300007, 22.139814262000087],
              [105.47329666800006, 22.138712817000084],
              [105.47317896, 22.137738845000051],
              [105.47373871700005, 22.134827279000021],
              [105.47417597599998, 22.131306390000113],
              [105.47419979000009, 22.129482946000053],
              [105.47382604900008, 22.128141307000071],
              [105.47279393500003, 22.127278527],
              [105.47006342400006, 22.126639627000074],
              [105.46863535400003, 22.126137076000063],
              [105.46798970700007, 22.125643408000073],
              [105.46426223900004, 22.121467193000029],
              [105.46273253300004, 22.118774927000025],
              [105.46198046300007, 22.116456259000117],
              [105.46162276200005, 22.112817050000011],
              [105.46176454200005, 22.109514543000053],
              [105.46228317500007, 22.10750125],
              [105.46330354300002, 22.104759411000074],
              [105.46409936400006, 22.104125989000067],
              [105.46695489200008, 22.104066700000047],
              [105.4678419260001, 22.103984999000126],
              [105.46873135000007, 22.103719758000025],
              [105.46993044800007, 22.102356642000039],
              [105.47183889100006, 22.099441249000051],
              [105.472442614, 22.098438481000038],
              [105.47245220700009, 22.097704329000045],
              [105.46896425600006, 22.093442652000036],
              [105.46692988500004, 22.090941337000025],
              [105.46635620400011, 22.089649827000102],
              [105.46639721600012, 22.088242091000062],
              [105.46693008700007, 22.085362320000087],
              [105.4669572870001, 22.083284815000056],
              [105.46636110900006, 22.081546511000028],
              [105.46538759400008, 22.080265652],
              [105.46195841900003, 22.077456160000075],
              [105.46135321700004, 22.076410335000048],
              [105.46136230399999, 22.075717822000037],
              [105.46199324200006, 22.074801529000077],
              [105.46738009200008, 22.069899063000072],
              [105.46889241200007, 22.067838378000104],
              [105.47143096600006, 22.06301880900012],
              [105.47495791700003, 22.058325808000042],
              [105.47784188, 22.055472480000034],
              [105.48392664200003, 22.050196318000111],
              [105.48607707100005, 22.047717990000045],
              [105.48766268900006, 22.045233248000073],
              [105.48755055500001, 22.042992779000059],
              [105.48744527300002, 22.040225493000065],
              [105.48748459800007, 22.037196384000076],
              [105.48810575800005, 22.032856624000097],
              [105.48927451200011, 22.029840210000096],
              [105.49002736800006, 22.028477043000102],
              [105.49057586900005, 22.027483879000087],
              [105.49130736700009, 22.025516285],
              [105.49151243900009, 22.022193511000054],
              [105.49184354100004, 22.016828536000062],
              [105.49202054800003, 22.014064299000054],
              [105.49275198100007, 22.012096640000053],
              [105.49418240700003, 22.010663756000071],
              [105.49453448300002, 22.010636955000074],
              [105.49870483900003, 22.010319436000088],
              [105.50096349300003, 22.010344802000056],
              [105.50294831100004, 22.009708441000072],
              [105.50366602800008, 22.008794403000117],
              [105.50369320400003, 22.006687063000108],
              [105.503338677, 22.000000158000077],
              [105.50312003300003, 21.994178793000074],
              [105.50335559400007, 21.993342451000061],
              [105.50415165800004, 21.9926172730001],
              [105.50866939700009, 21.990885040000038],
              [105.51478040500007, 21.987597392000069],
              [105.52146108400004, 21.983686656000053],
              [105.52271185700005, 21.982546953000082],
              [105.52362687000009, 21.98129863200009],
              [105.52513049000007, 21.977959341000108],
              [105.52926382300011, 21.971083401000065],
              [105.53018275200003, 21.969520434000067],
              [105.53097994400008, 21.968690256000031],
              [105.53157914400001, 21.96842995800008],
              [105.53380488600004, 21.96746305100001],
              [105.53663244900005, 21.966026065000044],
              [105.53832577200009, 21.965415536000066],
              [105.54057552400008, 21.965230648000045],
              [105.54350073000009, 21.964948328000041],
              [105.54665329400007, 21.964458713000035],
              [105.54913437200001, 21.96375189700008],
              [105.55049056900005, 21.963137559000053],
              [105.55185737400006, 21.961684296000072],
              [105.55377108600004, 21.958964590000015],
              [105.55124978700007, 21.955613282000016],
              [105.54959229400006, 21.953098429000036],
              [105.54781019400005, 21.949271179],
              [105.54755608900007, 21.948445185000054],
              [105.54756547000005, 21.947704420000044],
              [105.54801364900001, 21.947133128000118],
              [105.54917659500003, 21.945828829000021],
              [105.54954179400005, 21.944845022000067],
              [105.54959386700001, 21.940729603000086],
              [105.54894636700008, 21.932741958000079],
              [105.54881337000009, 21.929161483000122],
              [105.54906116800008, 21.923670788000038],
              [105.54934333100007, 21.92250861900007],
              [105.54988975100007, 21.921599059000037],
              [105.55151320900011, 21.920118706000032],
              [105.551884493, 21.918957507000059],
              [105.55209852700004, 21.916129868000041],
              [105.55212692300007, 21.913882825000108],
              [105.55191130600009, 21.912593610000016],
              [105.55120753600004, 21.910364789000077],
              [105.55091268200006, 21.909564232],
              [105.550371001, 21.908988757000088],
              [105.54964277100007, 21.908695977000022],
              [105.54660716400008, 21.907580452],
              [105.54491255500001, 21.906536627000058],
              [105.54400571700003, 21.905900154000065],
              [105.54334426500002, 21.905152480000112],
              [105.54323096300006, 21.904467816000036],
              [105.54330206200008, 21.903671280000125],
              [105.54457371700005, 21.899584788000048],
              [105.54464241300005, 21.898047838000068],
              [105.54465323900006, 21.897193667000089],
              [105.54383436100007, 21.895362152000075],
              [105.54288031599999, 21.893643034000029],
              [105.54197715700005, 21.892721817000016],
              [105.54089608400005, 21.891935128000028],
              [105.53886204800006, 21.891489711000034],
              [105.53693739800006, 21.891354361000111],
              [105.53478795300003, 21.891153367000094],
              [105.53346966600003, 21.890943984000089],
              [105.52521663500008, 21.889359089000045],
              [105.52251724600002, 21.88842011800007],
              [105.5203111280001, 21.886967227000028],
              [105.51866361600003, 21.885325773000105],
              [105.51818702000003, 21.88454137500004],
              [105.51719592800001, 21.880505045000028],
              [105.516924321, 21.877288272000058],
              [105.5173613370001, 21.87573496400006],
              [105.52066337200003, 21.867591254000018],
              [105.52451948300005, 21.859583384000111],
              [105.52611152300005, 21.854666662000085],
              [105.52655835700008, 21.852334250000105],
              [105.526996561, 21.848912633000062],
              [105.52678628400011, 21.845888232000021],
              [105.5264180560001, 21.842242135000056],
              [105.52619198200006, 21.840457357000084],
              [105.52629173700004, 21.839141136000073],
              [105.52623154000004, 21.837358192000075],
              [105.5260745680001, 21.836659026000049],
              [105.5254229290001, 21.835721891000048],
              [105.52427268400005, 21.834856687000048],
              [105.51941606700002, 21.831935388000097],
              [105.51810099600002, 21.830990821000015],
              [105.51580561200008, 21.828872914000016],
              [105.51498719100005, 21.828011356000054],
              [105.51424375400008, 21.827770571000116],
              [105.51358028500007, 21.827763152000031],
              [105.51299377800004, 21.828221541000033],
              [105.51045996100001, 21.831757799000059],
              [105.50961467100005, 21.832988189000076],
              [105.50836066600003, 21.833749050000058],
              [105.50694675100002, 21.834043166000058],
              [105.50603344300004, 21.834110408000029],
              [105.50361158100003, 21.833939930000042],
              [105.49643137100003, 21.832885210000086],
              [105.49414810700003, 21.832080301000097],
              [105.49342722900008, 21.831390386000038],
              [105.49271517500004, 21.830018777000056],
              [105.49221791000011, 21.828162583000044],
              [105.49222922200006, 21.827288307000089],
              [105.49225571100004, 21.825241018000071],
              [105.49286508100001, 21.818429856000066],
              [105.49283390300009, 21.812780202000056],
              [105.49236430000005, 21.810226243000038],
              [105.49161077100005, 21.806866671000066],
              [105.49176766500007, 21.805006724000094],
              [105.49329051100004, 21.799125850000053],
              [105.49438022600005, 21.797028156000039],
              [105.49520978500003, 21.795612735000077],
              [105.49562422000004, 21.79437623200009],
              [105.49563544100008, 21.793507545000068],
              [105.49526269600005, 21.791517457000076],
              [105.49476196200001, 21.789153571000064],
              [105.493645374, 21.783059147000117],
              [105.49326946000008, 21.781317225000052],
              [105.49329513200007, 21.779331590000069],
              [105.49505349800006, 21.776868989000064],
              [105.49789975500002, 21.772432673000083],
              [105.49990597500002, 21.771338153000031],
              [105.50176498800009, 21.771359059000069],
              [105.50639338400006, 21.772900505000067],
              [105.51115145500002, 21.774691550000021],
              [105.51313533900003, 21.775334363000098],
              [105.51406487100012, 21.775344762000067],
              [105.51473199900006, 21.77510397600004],
              [105.5175460020001, 21.773149481000061],
              [105.51857573300003, 21.772506538000066],
              [105.52087969700007, 21.771461844000051],
              [105.52289962600004, 21.770235554000102],
              [105.52367671500004, 21.769173802000097],
              [105.52368696600008, 21.768371102000025],
              [105.52359951400004, 21.767745717000061],
              [105.52257375699999, 21.765861058],
              [105.52013377, 21.762622566000097],
              [105.51797784500005, 21.759565607000027],
              [105.5167590610001, 21.757857135000066],
              [105.51581513200007, 21.757043762000094],
              [105.51458152300005, 21.756494748000051],
              [105.51230287500007, 21.755577211000073],
              [105.51060019600006, 21.754398496000071],
              [105.50795940400005, 21.751960387000047],
              [105.50768117600003, 21.751332837000078],
              [105.50759378300009, 21.750707425000115],
              [105.50779837100005, 21.749639268000109],
              [105.50849607700005, 21.74732776900008],
              [105.50938002300009, 21.745375163000077],
              [105.51016052200001, 21.744045836000048],
              [105.511507765, 21.743168869000051],
              [105.51347263100004, 21.741832895000087],
              [105.51418314600004, 21.741084816000097],
              [105.51440524000003, 21.740583278000081],
              [105.51425966200006, 21.739321585000049],
              [105.5139016840001, 21.737805513000104],
              [105.51342878000005, 21.736842568000014],
              [105.51225380100001, 21.735972569000062],
              [105.50974666500005, 21.733877968000037],
              [105.50766307100005, 21.732392922000088],
              [105.50643040100002, 21.731900408000016],
              [105.50404591700007, 21.731269098],
              [105.50301843500006, 21.73071346600004],
              [105.50199951100004, 21.729492938000035],
              [105.49785775800009, 21.724730897000107],
              [105.49404181400003, 21.719791037000036],
              [105.49277144200008, 21.718023504000072],
              [105.4924529240001, 21.717116879000017],
              [105.49193292300006, 21.715067125000061],
              [105.48982546200004, 21.708996783000067],
              [105.48912447100004, 21.706859783000084],
              [105.48868346700006, 21.705747674000087],
              [105.48823475100005, 21.705231615000017],
              [105.48742177700002, 21.704711443000058],
              [105.48615127200006, 21.704356417000078],
              [105.48387794300008, 21.704075180000082],
              [105.48251969500005, 21.703463649000106],
              [105.47809736199999, 21.700347575000123],
              [105.47629602600007, 21.698794150000111],
              [105.47567650000006, 21.698142508000018],
              [105.47568597200004, 21.697414579000096],
              [105.47588768700004, 21.696870856000047],
              [105.47726061000003, 21.696067414000098],
              [105.48284005300009, 21.693582589000115],
              [105.48520128800007, 21.69160723000001],
              [105.48589537700012, 21.690614036000078],
              [105.4863117040001, 21.688525630000029],
              [105.48558636600004, 21.684422161000057],
              [105.48512110200009, 21.682778777000067],
              [105.48475763700003, 21.682009182000073],
              [105.48409222900005, 21.681371620000078],
              [105.48095976800003, 21.680496075000022],
              [105.48006712799999, 21.680065934000048],
              [105.47928948000003, 21.679427081000121],
              [105.47841184800008, 21.677842050000073],
              [105.47787238200003, 21.676155844000085],
              [105.47788466500006, 21.675210930000063],
              [105.47801057000001, 21.674162307000074],
              [105.48020484100002, 21.669461940000097],
              [105.48138190700006, 21.665275013000063],
              [105.48429714700004, 21.656907402000058],
              [105.48755429400006, 21.648123446000064],
              [105.48906779500005, 21.64394012200006],
              [105.49031331499999, 21.643114117000017],
              [105.49269071900005, 21.641565784000072],
              [105.49461099800004, 21.64064235600005],
              [105.49529256200005, 21.640019972000069],
              [105.49632574400009, 21.638246415000047],
              [105.4973724380001, 21.635422871000017],
              [105.49807833800006, 21.632910505000069],
              [105.49832447000009, 21.631233060000099],
              [105.49800129500008, 21.630179271000124],
              [105.49702911300002, 21.627227911000091],
              [105.49552425400003, 21.622065198000051],
              [105.49520789400006, 21.620486394000068],
              [105.49522279900005, 21.619331377000073],
              [105.49762017600007, 21.616207924],
              [105.49931605500007, 21.615278547000024],
              [105.50180835300003, 21.614422192000049],
              [105.50311498900005, 21.613884133000028],
              [105.50430916500001, 21.612902594000083],
              [105.50791573400008, 21.608078803000105],
              [105.51307341700006, 21.602056205000061],
              [105.52029118600005, 21.591965874000074],
              [105.52209692200006, 21.58933268600007],
              [105.5230687000001, 21.587242952000096],
              [105.52368327600004, 21.58537033100005],
              [105.52416986300007, 21.584270184000061],
              [105.52464798000003, 21.583833285000061],
              [105.52618917600009, 21.583408245000065],
              [105.529500754, 21.583113454000014],
              [105.53209797800004, 21.583252905000094],
              [105.53268998800009, 21.583148931000018],
              [105.53387960400002, 21.58249880700005],
              [105.53651470199999, 21.579653551],
              [105.54010725000006, 21.57582383900003],
              [105.54023236600003, 21.575272429000059],
              [105.54019975600008, 21.575002261000087],
              [105.54012545600006, 21.57438676500005],
              [105.53712458600009, 21.568825478000072],
              [105.53514897500006, 21.566260638000045],
              [105.53164644300007, 21.563015421000031],
              [105.52955145100002, 21.560559736000059],
              [105.52850678400009, 21.559110796000077],
              [105.52793596400002, 21.557556556000087],
              [105.52705166100003, 21.552792469000082],
              [105.52589769100004, 21.54919816300005],
              [105.52542884300006, 21.548139436000021],
              [105.52447678700007, 21.547150560000091],
              [105.52368357600007, 21.54631396300006],
              [105.52328840700005, 21.545782796000076],
              [105.52297457700003, 21.545177293000073],
              [105.52227135500002, 21.543589178000033],
              [105.52096644100006, 21.53875853800011],
              [105.52041919300007, 21.537548398000084],
              [105.51955143100012, 21.5362594260001],
              [105.51835834500008, 21.535267806000036],
              [105.51755751500008, 21.535033095000067],
              [105.51594719000008, 21.535240824000113],
              [105.51433108400002, 21.535899998000026],
              [105.51303645900002, 21.536562754000066],
              [105.51198872900002, 21.536776759000098],
              [105.51102426100006, 21.536765942],
              [105.50982542800008, 21.536225733000066],
              [105.50841320300003, 21.535058811000063],
              [105.5041150370001, 21.53039168400008],
              [105.50273419300008, 21.529067473000026],
              [105.50101759100006, 21.528278334000014],
              [105.49650887100005, 21.526212037000022],
              [105.49566978100007, 21.525975295000066],
              [105.49537543300006, 21.52725111700002],
              [105.49412722600007, 21.52951931800002],
              [105.49350611000003, 21.52950390000008],
              [105.49313701700004, 21.529430440000041],
              [105.49225530300001, 21.528935476000029],
              [105.49145296700006, 21.52802567800007],
              [105.49045460200006, 21.52510433300003],
              [105.48974598100004, 21.522671260000067],
              [105.48917192800006, 21.521279010000072],
              [105.48851851799999, 21.520301592000024],
              [105.48675879600006, 21.519034476000073],
              [105.48536188700002, 21.518325775000058],
              [105.48451825100004, 21.51827921800011],
              [105.48322687400012, 21.518551662],
              [105.47946568900005, 21.520116645000087],
              [105.47563865400004, 21.522025323],
              [105.47254960100007, 21.523770087000038],
              [105.47181079200004, 21.523991334000044],
              [105.47138455200003, 21.523756812],
              [105.47065994100004, 21.522887287000088],
              [105.46946425000004, 21.520519544000116],
              [105.46880098100006, 21.519650716000079],
              [105.46801208300006, 21.5190101190001],
              [105.46557064700002, 21.518120977000073],
              [105.46181354600003, 21.517015798000024],
              [105.46107700600001, 21.517064784000084],
              [105.46027464300006, 21.517457530000073],
              [105.45872596, 21.51864556500005],
              [105.45798489600006, 21.519038994000091],
              [105.45718552500009, 21.519202086000085],
              [105.45669499000009, 21.519196462000068],
              [105.45596369500007, 21.518843572],
              [105.45468958700008, 21.517795441000054],
              [105.45444807800007, 21.517505578000048],
              [105.45420808500002, 21.517100903000049],
              [105.45427541400008, 21.516642331000035],
              [105.454665128, 21.514981682000034],
              [105.45455829800005, 21.513774678000082],
              [105.45420620500005, 21.512564857000079],
              [105.45354077300006, 21.5118682],
              [105.45311533900011, 21.51157622200008],
              [105.45182923500002, 21.511446612000015],
              [105.44771891000001, 21.511571615000058],
              [105.43896229600006, 21.510609417000026],
              [105.43816671900005, 21.510485391000067],
              [105.435120721, 21.508957308000021],
              [105.43347587500006, 21.508134427000059],
              [105.43207022700003, 21.50777364700005],
              [105.42944135000002, 21.507169005000023],
              [105.42848288700003, 21.506866512000045],
              [105.4277977510001, 21.506478371000078],
              [105.42659138700003, 21.50558991800002],
              [105.42491095500007, 21.503707426000048],
              [105.42363350700006, 21.502057717000064],
              [105.42295645600009, 21.501061327000045],
              [105.42244382400004, 21.499914756000038],
              [105.42209106600008, 21.498960141000012],
              [105.42185354200005, 21.498501133000048],
              [105.42165408900007, 21.498232668000036],
              [105.42131872900003, 21.49823581900003],
              [105.41946308300007, 21.501172793000123],
              [105.41784290400004, 21.503929426000035],
              [105.41734056400004, 21.505064185000045],
              [105.41665970000001, 21.50737302100012],
              [105.41647127000006, 21.508356038],
              [105.41617175500005, 21.508899885000062],
              [105.4156377250001, 21.509495734000069],
              [105.41173942400005, 21.51251537100007],
              [105.41020592400008, 21.51353741100008],
              [105.40944318200005, 21.513747436000052],
              [105.40815514400005, 21.513896586000044],
              [105.40089662300007, 21.514632667000022],
              [105.39996291600008, 21.514512256],
              [105.39914830900008, 21.514229041000107],
              [105.39700468600009, 21.51280383900005],
              [105.39520617400005, 21.511606488000012],
              [105.39451187700007, 21.51133695],
              [105.39402341800007, 21.511331213000062],
              [105.38849763800002, 21.512246427000022],
              [105.387865225, 21.512565713000065],
              [105.38737061200001, 21.513017307000034],
              [105.38679654300003, 21.514186783000078],
              [105.38620753200007, 21.51646694700009],
              [105.38603895500006, 21.51862136800009],
              [105.38567773100002, 21.519531948000022],
              [105.385040012, 21.520243234000077],
              [105.38447646500001, 21.520628665000082],
              [105.38081954800003, 21.522676566000065],
              [105.37983640400005, 21.523122376000089],
              [105.37878524100003, 21.523436676000074],
              [105.37595589600005, 21.523631938000065],
              [105.37398626600006, 21.523837117000078],
              [105.37274813200003, 21.524193602000082],
              [105.37053273800004, 21.525157069000045],
              [105.36960342700007, 21.525475963000048],
              [105.36885307000007, 21.525590774000044],
              [105.36766286800008, 21.525659130000108],
              [105.36691418700005, 21.525650246000083],
              [105.36577193000004, 21.52543050200002],
              [105.36370987700005, 21.524828672000048],
              [105.36301027800008, 21.524449213000096],
              [105.36244391400001, 21.523988861000106],
              [105.36188204300007, 21.523198648000097],
              [105.36106377600004, 21.521828043000113],
              [105.35989095800008, 21.520618174000013],
              [105.35896782300009, 21.520231924000122],
              [105.35667827, 21.519589861000021],
              [105.35299044700005, 21.519161638000028],
              [105.35085667600006, 21.519136168000053],
              [105.34830523300008, 21.519643636000026],
              [105.34666072099999, 21.519854526000067],
              [105.34559383200009, 21.51984176300008],
              [105.34461216600005, 21.519599462000102],
              [105.34297712000003, 21.519118788000036],
              [105.34191864700003, 21.518491303000076],
              [105.33723035800008, 21.513209253000106],
              [105.336585423, 21.512356142000044],
              [105.33586159500007, 21.511271543000099],
              [105.33546919000003, 21.509960354000057],
              [105.33548499900009, 21.508807776000083],
              [105.33616362800009, 21.50720204],
              [105.33750086000008, 21.505450510000031],
              [105.33767130100006, 21.504991438000062],
              [105.33751033800006, 21.504758956000011],
              [105.33718313800009, 21.504678172000077],
              [105.3324161780001, 21.505158862000059],
              [105.33027095600006, 21.505978424000105],
              [105.3275481000001, 21.50702156500008],
              [105.32581632600008, 21.507615504000022],
              [105.32466533200005, 21.507755328000044],
              [105.3229419080001, 21.507734554000102],
              [105.32283512900005, 21.509237680000034],
              [105.32259161000007, 21.510405884000058],
              [105.32143417100005, 21.511882456000109],
              [105.31959455900008, 21.513350778000095],
              [105.31753636300004, 21.514177639000117],
              [105.31577380000004, 21.51432317700008],
              [105.31270141400003, 21.513901688000111],
              [105.30556809200002, 21.510356596000065],
              [105.30311664800004, 21.509558225000092],
              [105.30106223200005, 21.509725387000067],
              [105.29951925300001, 21.510214463000032],
              [105.29665675300005, 21.512572689000109],
              [105.2952639760001, 21.514265060000071],
              [105.29414021300002, 21.516302564000078],
              [105.29356164800002, 21.518517636000034],
              [105.29316085100008, 21.521076738000119],
              [105.29311679000007, 21.524238440000062],
              [105.29353504500004, 21.526978434000021],
              [105.2945791320001, 21.530666156000052],
              [105.29473787100008, 21.532377383000068],
              [105.29435015500007, 21.53399648],
              [105.29276035400007, 21.536711929000063],
              [105.29173724700006, 21.538066855000082],
              [105.28961758700009, 21.539493850000021],
              [105.28713755500007, 21.540574564000089],
              [105.28474877800004, 21.541656366000069],
              [105.28016893900008, 21.542950771000058],
              [105.27187803400003, 21.543495153000066],
              [105.26811454100003, 21.544368540000036],
              [105.26772282500005, 21.544459437000093],
              [105.26633082100007, 21.54527308500009],
              [105.26502310200004, 21.547103162000077],
              [105.26460531500003, 21.548759581000084],
              [105.26469091100009, 21.549683723000079],
              [105.26513237500002, 21.550391428000104],
              [105.26608558800002, 21.551570353000038],
              [105.27223709400008, 21.557481894000126],
              [105.27276752900009, 21.559174315000092],
              [105.27275114000007, 21.560341274000017],
              [105.27232280500006, 21.561243798000085],
              [105.27133124500008, 21.562787805000113],
              [105.26964518400003, 21.564452952000046],
              [105.26684903100005, 21.56623409500007],
              [105.26139508300007, 21.569797958000066],
              [105.25508955600006, 21.574777628000064],
              [105.25016728300002, 21.579903900000104],
              [105.24733579400004, 21.584148208],
              [105.24647879000001, 21.585953069000041],
              [105.24601569000006, 21.588482376000059],
              [105.24576858400005, 21.591127145000044],
              [105.24561928900003, 21.594263450000106],
              [105.24518069000007, 21.595630946000099],
              [105.24357697199999, 21.597866571000068],
              [105.24208775300005, 21.59941713500011],
              [105.24218836000003, 21.599712583000041],
              [105.24310912, 21.601293078000069],
              [105.2445552030001, 21.602782021000074],
              [105.24569531200011, 21.603678760000022],
              [105.24809839800008, 21.60419889200007],
              [105.24882633500009, 21.604600177000052],
              [105.24902617500001, 21.605289110000037],
              [105.24901367000004, 21.606171541000037],
              [105.24888248000012, 21.608033147000057],
              [105.2482231820001, 21.610182402000078],
              [105.24724672200004, 21.612523833000097],
              [105.24530903800003, 21.616128148000065],
              [105.24456436300005, 21.616903411000088],
              [105.24403623300006, 21.617191045000084],
              [105.24350949400008, 21.617380631000046],
              [105.24151426900002, 21.617650036000043],
              [105.23699845100003, 21.61828031800006],
              [105.23185106100003, 21.619098759000011],
              [105.23100991700007, 21.619284395000093],
              [105.23037416700004, 21.619766777000038],
              [105.22930525200006, 21.621224377000111],
              [105.226314985, 21.625109520000052],
              [105.22448999900008, 21.628126617000106],
              [105.22341428900008, 21.630546608000053],
              [105.2233985400001, 21.63164752700002],
              [105.22407929000012, 21.632878160000054],
              [105.22532777100012, 21.633679538000102],
              [105.22854268100011, 21.635888993000037],
              [105.23158658900007, 21.639253202000042],
              [105.23431528400008, 21.643047267000078],
              [105.23551274200004, 21.645809910000047],
              [105.23548184700006, 21.647978768000087],
              [105.23462410100005, 21.652041528000062],
              [105.23243638800005, 21.659786199000038],
              [105.22219909400008, 21.66062801200006],
              [105.20746060300007, 21.66227826500009],
              [105.20410998200003, 21.663337166000041],
              [105.20213511100006, 21.664229838],
              [105.19204310600003, 21.670158249000067],
              [105.19044451900001, 21.672156619000035],
              [105.19042326600002, 21.673624397000069],
              [105.19077576600006, 21.676381455000104],
              [105.19076248400002, 21.677298811000064],
              [105.18957212800004, 21.678201282000131],
              [105.18780385800009, 21.678362399000051],
              [105.18643061100008, 21.678345006000121],
              [105.18191316800004, 21.678654741000081],
              [105.17973919700002, 21.679728175000079],
              [105.17833925900001, 21.681545438000072],
              [105.17772402400009, 21.683372659],
              [105.17748513200004, 21.686305673000028],
              [105.17696715400008, 21.687669473000071],
              [105.17593864900007, 21.688075170000062],
              [105.17456265200008, 21.688241176000034],
              [105.16513086500004, 21.689130251000051],
              [105.163852949, 21.689297433000029],
              [105.16277793100005, 21.689008453000014],
              [105.16093293200007, 21.687700367000048],
              [105.15831664400005, 21.68546487600009],
              [105.15609681400005, 21.682959168000068],
              [105.15550960900005, 21.682859894000025],
              [105.15511455600006, 21.683038333000049],
              [105.15489838300002, 21.683735891000119],
              [105.15501760200002, 21.685843347000052],
              [105.15407991900007, 21.688358436000051],
              [105.15316280900004, 21.689469834000086],
              [105.15088647600007, 21.691125374000109],
              [105.14920229400008, 21.693350054000057],
              [105.14832547300009, 21.695786148000096],
              [105.1481085990001, 21.699668388000056],
              [105.14529361000005, 21.701124635000021],
              [105.144089525, 21.701358644000024],
              [105.14288911200006, 21.701343207000079],
              [105.13665159900009, 21.699142249000047],
              [105.13253533000002, 21.697841713000088],
              [105.13120156100007, 21.697824491000084],
              [105.13026054600002, 21.698311313000126],
              [105.12877125400007, 21.699788990000087],
              [105.12564997300009, 21.703366165000126],
              [105.12416615400008, 21.704469648000035],
              [105.12228586000002, 21.705318489000014],
              [105.11974422500005, 21.705784519000112],
              [105.11600953500003, 21.705736062000099],
              [105.11400695100008, 21.705834796000097],
              [105.11226741000011, 21.706186408000057],
              [105.10677456700003, 21.70773657900007],
              [105.10248023300008, 21.709426989000043],
              [105.10166873900008, 21.71016485500008],
              [105.10124622600003, 21.711656235000106],
              [105.10120708700006, 21.714275274],
              [105.10077709500011, 21.716265506000099],
              [105.09982845500005, 21.717251038000107],
              [105.09861862900004, 21.717858942000042],
              [105.09687335200005, 21.718584573000044],
              [105.09043881200003, 21.720620961000044],
              [105.08923266800007, 21.720979379000127],
              [105.0880340090001, 21.720838931000053],
              [105.085092671, 21.719549306000104],
              [105.08311414500008, 21.72287893700009],
              [105.08169857700001, 21.724875304000065],
              [105.07486616600002, 21.734510212000124],
              [105.07367859599999, 21.736167891000058],
              [105.07212418200008, 21.737680148000038],
              [105.06961551100008, 21.739504631],
              [105.06682613800004, 21.741683306000056],
              [105.06493609700006, 21.742823882000017],
              [105.06286130200007, 21.743744085000039],
              [105.06095829900001, 21.74453154800009],
              [105.05517058100003, 21.746391844000087],
              [105.05481924400002, 21.746478326000052],
              [105.05476273400004, 21.747235046000043],
              [105.05485287800003, 21.748218449000056],
              [105.05535724000002, 21.749170994000089],
              [105.05608616700003, 21.749939498000082],
              [105.05640352600008, 21.750093117000091],
              [105.05696320100003, 21.75013905900007],
              [105.05913164800003, 21.749382196000063],
              [105.05977088700008, 21.749390655000056],
              [105.06040560800002, 21.749697864000069],
              [105.06068373300012, 21.750145393000061],
              [105.06545141200007, 21.751622309000048],
              [105.06698612000004, 21.752254561000051],
              [105.06744458300005, 21.752599547000081],
              [105.06784832400005, 21.753211504000092],
              [105.06823546500003, 21.754093147000027],
              [105.06851316700009, 21.754764429000062],
              [105.06929339300007, 21.756243535000053],
              [105.07051896900005, 21.757613559000077],
              [105.07072707600004, 21.757835258000036],
              [105.07111559700003, 21.758056299000117],
              [105.07152781400004, 21.75813444],
              [105.07181623600009, 21.758182098000042],
              [105.07216108500003, 21.758336791000055],
              [105.07236451400007, 21.758532372000069],
              [105.07265099900006, 21.758921949000062],
              [105.07276747300001, 21.759180685000068],
              [105.07281396400005, 21.759518874000072],
              [105.07275558500001, 21.759968206000032],
              [105.07255771200008, 21.760544292000091],
              [105.07221056600012, 21.761587744000011],
              [105.07205642500007, 21.762376861000021],
              [105.07227089900005, 21.763007792000089],
              [105.07265651299998, 21.763431606000069],
              [105.07497098300009, 21.765922166000081],
              [105.07727920300002, 21.76883132800009],
              [105.07815298700011, 21.770308381000092],
              [105.07858672600003, 21.771256232],
              [105.07870106000007, 21.771981745000076],
              [105.07856002400004, 21.772764980000048],
              [105.07816147700001, 21.773484443000065],
              [105.07607135400009, 21.774966741000057],
              [105.07169264400008, 21.778230613000105],
              [105.07103654600006, 21.778886271000083],
              [105.07069709200005, 21.779968835000126],
              [105.07054871900002, 21.781235091],
              [105.07072703400003, 21.782264083000058],
              [105.07129117600007, 21.78341894000004],
              [105.07485912700007, 21.78678739700004],
              [105.07553343200007, 21.787068026000078],
              [105.07662906300004, 21.787263613],
              [105.07759546600008, 21.787457495000062],
              [105.07804318400008, 21.787765327000038],
              [105.07879757600006, 21.789164217000057],
              [105.08160687700007, 21.795723184000067],
              [105.08209924000003, 21.797360151000063],
              [105.08239595300007, 21.799115323000073],
              [105.08275909300001, 21.800750581000017],
              [105.08413156300006, 21.804029558000067],
              [105.08532475600002, 21.806339953000098],
              [105.08646425000006, 21.807924971000055],
              [105.08780487000006, 21.809029515000056],
              [105.09023673800002, 21.810631433000083],
              [105.09393005900006, 21.812159236000106],
              [105.09689073000007, 21.812982948000048],
              [105.09772624700001, 21.813295798000084],
              [105.09804391600009, 21.81366226400003],
              [105.09803306900008, 21.814386768000112],
              [105.09768918400005, 21.815771171000087],
              [105.09695305499999, 21.817452385000102],
              [105.09609490900004, 21.818648909000068],
              [105.09517846500006, 21.81942196199999],
              [105.09257283100003, 21.820776780000095],
              [105.09126682500006, 21.821665494000086],
              [105.09047868400005, 21.822500580000082],
              [105.08980890100005, 21.824061846000028],
              [105.08893708000004, 21.826163929000053],
              [105.08794506400002, 21.827660581000075],
              [105.086828333, 21.828853660000021],
              [105.08604377400009, 21.82944722200002],
              [105.08532928200003, 21.829679401000043],
              [105.08261319300001, 21.829901746000054],
              [105.07901072000001, 21.830151090000079],
              [105.07678097100009, 21.830616201000019],
              [105.07539333000003, 21.831389014000102],
              [105.06937, 21.837737034000071],
              [105.0669331350001, 21.840923964000055],
              [105.06278265400005, 21.846351612000078],
              [105.06062401700007, 21.849091713000043],
              [105.05934309300004, 21.852088764000044],
              [105.05767408500006, 21.857029723000032],
              [105.056972595, 21.858555838000093],
              [105.05642364800006, 21.859153682000084],
              [105.05577133600009, 21.85944759100007],
              [105.05220433700001, 21.859702890000051],
              [105.05068841100008, 21.859985344000066],
              [105.04752554500004, 21.862061222000051],
              [105.04337256500006, 21.865334093000044],
              [105.042599959, 21.86643315600012],
              [105.04125060300008, 21.869944918000073],
              [105.04044100500006, 21.873463833000081],
              [105.04014037000006, 21.879006438000111],
              [105.03999782300008, 21.884803204000036],
              [105.03976648400003, 21.885808582],
              [105.0386606210001, 21.887508231000119],
              [105.03491876600003, 21.892097230000026],
              [105.03339329800011, 21.892984487000078],
              [105.03069444, 21.893265999],
              [105.02859011000007, 21.893237897000084],
              [105.02591153500003, 21.892795452000065],
              [105.02446028400004, 21.892776042000058],
              [105.02314478700001, 21.893368408000043],
              [105.02174629700002, 21.894637377000059],
              [105.0208671980001, 21.89516779700007],
              [105.02006691700007, 21.895292625000096],
              [105.01897951000007, 21.895210280000029],
              [105.01774801200004, 21.89505822400006],
              [105.01716646600006, 21.895118200000049],
              [105.01665330300006, 21.895450187000073],
              [105.01275205400003, 21.898989800000024],
              [105.0119381640001, 21.899995462],
              [105.01170897700007, 21.900737890000066],
              [105.01169957000008, 21.901347714000075],
              [105.01254375700003, 21.907677192000016],
              [105.01300167300008, 21.908563993000115],
              [105.01578992600012, 21.911243364000121],
              [105.01740741200011, 21.913393279000012],
              [105.01908770100005, 21.916571432000076],
              [105.01954002400008, 21.917825058000062],
              [105.02053526300008, 21.924663269000085],
              [105.02052510500005, 21.925323600000098],
              [105.02028143500004, 21.925834027000057],
              [105.01980651000011, 21.926047821000068],
              [105.01822231000003, 21.926833836000093],
              [105.01734540000004, 21.927629313000097],
              [105.01669859500008, 21.928794801000073],
              [105.016531242, 21.929453019000086],
              [105.01682187400007, 21.930997991000112],
              [105.01733473000004, 21.933426547000053],
              [105.01762988400004, 21.93467802900004],
              [105.0176188300001, 21.93508519700007],
              [105.01756731900011, 21.935677931000086],
              [105.01743472200006, 21.936032212],
              [105.01713448200009, 21.936265562000031],
              [105.01670772700004, 21.93645765400003],
              [105.01632456400004, 21.936571199000099],
              [105.00914256200008, 21.937859441000015],
              [105.00837988700003, 21.937849187000054],
              [105.00736542600004, 21.937677292000046],
              [105.00323757700008, 21.936039257000047],
              [105.00206038600003, 21.93542996399999],
              [105.00155746100008, 21.935067128000085],
              [105.00000008700009, 21.933570629000073],
              [104.99852690600005, 21.931220131000103],
              [104.99671018500001, 21.929712072000115],
              [104.99528859700008, 21.929692877000036],
              [104.98764006600007, 21.932088046000061],
              [104.98728614900008, 21.933332543000091],
              [104.98712553700004, 21.93424781600006],
              [104.98651890700003, 21.93560600799999],
              [104.9858371440001, 21.936416617000098],
              [104.98540930000006, 21.937035465000051],
              [104.98532020300006, 21.937385618000071],
              [104.98517470900001, 21.9386719600001],
              [104.98502314600007, 21.940348622],
              [104.98500309300002, 21.941636667000083],
              [104.9856750440001, 21.944144309000087],
              [104.98699125300003, 21.945567554000078],
              [104.98742446200008, 21.948637999000105],
              [104.98786317900004, 21.950010307000056],
              [104.98888976600009, 21.95123441],
              [104.99116478500011, 21.952865784000061],
              [104.9913974900001, 21.954040096000057],
              [104.9927125830001, 21.959581825000015],
              [104.9933416640001, 21.962166847000027],
              [104.99353318700005, 21.964648349000093],
              [104.99298771200004, 21.967451697000051],
              [104.99185977900007, 21.970051980000079],
              [104.99097789700002, 21.971621078000055],
              [104.99063363600004, 21.972241015000101],
              [104.99054149900006, 21.972786299000099],
              [104.99061665000005, 21.97333383500002],
              [104.99194762100005, 21.976513833000055],
              [104.99325438900007, 21.978561413000094],
              [104.99652570100002, 21.984753846000068],
              [104.99712883900003, 21.986323445],
              [104.99787262000009, 21.988265771000037],
              [104.99789568500006, 21.989476199000087],
              [104.99771627700004, 21.990254494000091],
              [104.99609727700002, 21.992145415000053],
              [104.99430972800009, 21.994112101000063],
              [104.99239970200006, 21.994535209000077],
              [104.98947010100005, 21.994612715000095],
              [104.98925672800007, 21.994883072000079],
              [104.98933431200001, 21.995274484000049],
              [104.99006896400005, 21.996455488000066],
              [104.99068482700001, 21.997205485000038],
              [104.990969116, 21.997755823000098],
              [104.99005617900005, 21.999968492000029],
              [104.9893001770001, 22.00063808],
              [104.98870630400009, 22.001277361000056],
              [104.98827229100006, 22.002001901000057],
              [104.98799816800003, 22.002662510000093],
              [104.987975283, 22.003152659000058],
              [104.988066567, 22.004026404000051],
              [104.98919693100001, 22.006807539000036],
              [104.99148068000005, 22.011453416000037],
              [104.99330785800007, 22.013797691000022],
              [104.99431281500009, 22.015417336],
              [104.994404151, 22.016014039000083],
              [104.99440413700009, 22.016440261000092],
              [104.99417569800002, 22.016866464000096],
              [104.99362747900004, 22.017420528000017],
              [104.99248532300004, 22.018187658000073],
              [104.99109185700003, 22.019487521000066],
              [104.990909075, 22.020425191000115],
              [104.990909012, 22.021277618000035],
              [104.99273590300008, 22.031677341000133],
              [104.99300990500009, 22.034064146000084],
              [104.99520276000008, 22.041224612000043],
              [104.99593376500007, 22.043526185000047],
              [104.99556817900003, 22.044890052000099],
              [104.99474568600002, 22.045401494000046],
              [104.9927351850001, 22.0457423860001],
              [104.98880555500004, 22.045912647000065],
              [104.98652090700001, 22.045741994000068],
              [104.98313972800003, 22.044633526000013],
              [104.97893610400003, 22.043951090000036],
              [104.97189967800003, 22.042586160000077],
              [104.96970679800005, 22.04096615800006],
              [104.96861064700003, 22.039005374000098],
              [104.96687525300007, 22.035254345000062],
              [104.96559663600006, 22.032355833000047],
              [104.963495288, 22.031076738000088],
              [104.96148513400004, 22.030564822000095],
              [104.96029717900007, 22.030735024000059],
              [104.94686301500008, 22.035504808],
              [104.94421235800006, 22.03712354],
              [104.94201821200005, 22.039594810000018],
              [104.939823377, 22.043515180000028],
              [104.9390001510001, 22.045304973000057],
              [104.93918193600004, 22.047606583000068],
              [104.93954555100001, 22.052124591000052],
              [104.93899644200005, 22.053914470000088],
              [104.93725913600011, 22.055874394000071],
              [104.93415051300011, 22.058600929000072],
              [104.92866538100004, 22.061496872000049],
              [104.92601461500001, 22.062007148000085],
              [104.92391267600009, 22.061665209000033],
              [104.92007469100005, 22.060469990000087],
              [104.91705893300011, 22.059957039000011],
              [104.91614492000006, 22.060041815000098],
              [104.90051241900008, 22.065573803000049],
              [104.89813396600002, 22.068470582000096],
              [104.89529840600005, 22.071367034000033],
              [104.892646364, 22.073155395000029],
              [104.889721174, 22.073494418000017],
              [104.88761928800008, 22.072981538000036],
              [104.88423818100003, 22.071956274000023],
              [104.88131272200009, 22.072636112000055],
              [104.880137414, 22.07342945300006],
              [104.87765444600009, 22.075105440000087],
              [104.87353766000008, 22.079108686000026],
              [104.87207336000007, 22.081068115000058],
              [104.86273316800006, 22.098108951000128],
              [104.86291361900007, 22.100581118000036],
              [104.86364023100006, 22.105525741000065],
              [104.86381980600001, 22.108935570000106],
              [104.86354290200009, 22.111663077000095],
              [104.86262556600005, 22.114816248000047],
              [104.85969210600007, 22.122570759000034],
              [104.85840850900009, 22.126064542],
              [104.85831443300003, 22.128706953000062],
              [104.85848942100003, 22.136591970000019],
              [104.85885438900003, 22.137402074000079],
              [104.85963054300004, 22.138553501000047],
              [104.86150193100006, 22.141879513000085],
              [104.86177513700002, 22.143030507000013],
              [104.86127059100002, 22.144649666000049],
              [104.86039912700004, 22.147376648000083],
              [104.8573297770001, 22.153042543000097],
              [104.85682570600005, 22.154107622000119],
              [104.85604733300003, 22.155087205000079],
              [104.85449103900005, 22.156577551000019],
              [104.85320899100003, 22.158153357000018],
              [104.85284199600004, 22.159261167000111],
              [104.852840402, 22.160795505000053],
              [104.849303487, 22.175538991000018],
              [104.84785674900012, 22.181184859000069],
              [104.84726062800009, 22.182612096000028],
              [104.84696181300005, 22.184018287000029],
              [104.84700712900008, 22.18619764500005],
              [104.84735119900003, 22.186234969000026],
              [104.84789979000003, 22.186469884000104],
              [104.84868819900007, 22.186992705000108],
              [104.85156720900008, 22.189339426000032],
              [104.85312037400007, 22.191216104000056],
              [104.85353037600007, 22.192793397000088],
              [104.85370987800007, 22.196117883000085],
              [104.85402831500005, 22.197822945000034],
              [104.85485029100002, 22.199144889000074],
              [104.86303416100002, 22.203158223000052],
              [104.86369702700006, 22.203521043000052],
              [104.86488647000006, 22.203394170000124],
              [104.86671664500005, 22.202926868000091],
              [104.87023947400004, 22.202247792000037],
              [104.87307587000006, 22.201866460000105],
              [104.87399125400005, 22.201270499000088],
              [104.87472407300001, 22.200205572000087],
              [104.87467935000004, 22.199054804000085],
              [104.87390270500001, 22.197988715000072],
              [104.87275982400003, 22.197263293000091],
              [104.87253180900008, 22.196495964000107],
              [104.87285291100009, 22.195473341000046],
              [104.8744105910001, 22.192661648000112],
              [104.87633478600006, 22.189083074000045],
              [104.87775419899999, 22.18733674100006],
              [104.87894381300001, 22.186826183],
              [104.88022462200003, 22.186656654000011],
              [104.88191676300006, 22.1868283680001],
              [104.88379182400004, 22.187085432000053],
              [104.88617029000001, 22.186959262000094],
              [104.88781715200003, 22.186576823000067],
              [104.88941877100004, 22.185555043000079],
              [104.89102062900005, 22.184192293000038],
              [104.89179825600009, 22.184064954000071],
              [104.89239271300008, 22.184235827000087],
              [104.89344404300002, 22.185046295000085],
              [104.89470049300006, 22.186815825000039],
              [104.89506506000002, 22.188606088000107],
              [104.89510855800008, 22.191632109000103],
              [104.89583620700006, 22.197300973000011],
              [104.89807259600009, 22.204121519000083],
              [104.89875791900005, 22.205272682000029],
              [104.90153718500005, 22.212646606000064],
              [104.90197494500003, 22.213709053000038],
              [104.90315807500005, 22.214935351000072],
              [104.90372810600005, 22.214956116000018],
              [104.90453968900005, 22.214466358],
              [104.9067333560001, 22.21275179700006],
              [104.90804923100009, 22.212180600000053],
              [104.90980292900007, 22.212671819000015],
              [104.91085439700002, 22.21414311500007],
              [104.91181784400007, 22.216186298000103],
              [104.91190426600011, 22.218229003000097],
              [104.91155248100004, 22.21978123800001],
              [104.91041075700008, 22.222231807000092],
              [104.91027809700003, 22.223947581000033],
              [104.91036082300003, 22.231791433000048],
              [104.91040392400005, 22.232976194000017],
              [104.91053361300004, 22.235958537000045],
              [104.91075194800007, 22.237470226000063],
              [104.91132158500004, 22.238287595000102],
              [104.91197904799999, 22.23894160200004],
              [104.912768199, 22.239432269000048],
              [104.91459880200003, 22.240209447000048],
              [104.91583757000004, 22.240639054000063],
              [104.91615535200003, 22.240955827000072],
              [104.91627056300007, 22.241270744000104],
              [104.9157761700001, 22.241661927000052],
              [104.91319816600006, 22.244186548000044],
              [104.90994845400012, 22.247741517000108],
              [104.90737819500008, 22.250836286000016],
              [104.90639342300004, 22.252949876000017],
              [104.90577836500005, 22.256292254000073],
              [104.90523421400005, 22.258788974000062],
              [104.90436151800006, 22.26081157800008],
              [104.90157918000006, 22.263209003000028],
              [104.8960202260001, 22.267793748000116],
              [104.89413233400003, 22.269159221000031],
              [104.89217467800006, 22.270329398000044],
              [104.890915949, 22.271369843000066],
              [104.89035630100007, 22.272085355000023],
              [104.89000606000005, 22.273126407000099],
              [104.89021496000001, 22.274102763000059],
              [104.89070366400003, 22.274753908000022],
              [104.89248466500004, 22.276219409000042],
              [104.89388173300009, 22.277131463000011],
              [104.89492991300003, 22.277327391000057],
              [104.89625778600004, 22.277328247000035],
              [104.89828501699999, 22.276678714000028],
              [104.90066171400008, 22.275964295000072],
              [104.9025488720001, 22.27570511900003],
              [104.90345736700003, 22.275770741000045],
              [104.90436546400011, 22.276422088000025],
              [104.90615991800009, 22.278261059000037],
              [104.90727518700008, 22.279820945000047],
              [104.90868595000011, 22.282350177000062],
              [104.90904344900011, 22.283388418000079],
              [104.909252694, 22.284039349000025],
              [104.90925064100001, 22.287228297000091],
              [104.90970409100005, 22.288562712000015],
              [104.91061183900011, 22.289929900000068],
              [104.91151981600009, 22.290971698000114],
              [104.91242813500003, 22.291492833000071],
              [104.91333669500005, 22.291623484000041],
              [104.91452491700005, 22.291624117000097],
              [104.91606288300002, 22.291169348000039],
              [104.91795055800007, 22.290324267000102],
              [104.91878929400011, 22.290324689000052],
              [104.91955787200001, 22.290780641000119],
              [104.91990700800004, 22.291382109000054],
              [104.91988905400007, 22.292315272000081],
              [104.91982318400002, 22.293260461000038],
              [104.9164479990001, 22.304188374000056],
              [104.91596536200004, 22.305620021000024],
              [104.91559948800005, 22.306300360000094],
              [104.91000313700002, 22.312740332000125],
              [104.90930351800004, 22.313585985000103],
              [104.90853417600007, 22.314171287000036],
              [104.90511511000005, 22.315272046000075],
              [104.901573129, 22.316463073000037],
              [104.90020588400003, 22.316802356000039],
              [104.89912973800007, 22.316931622000077],
              [104.89703268200003, 22.3166699820001],
              [104.89577434400009, 22.316669184000126],
              [104.89514501900007, 22.316864020000082],
              [104.89239759200004, 22.320189848000012],
              [104.89054617000005, 22.322791887000015],
              [104.88975628300007, 22.324279615000094],
              [104.88975576800003, 22.324930415000033],
              [104.8900347470001, 22.325776653000062],
              [104.89101281600003, 22.326688435],
              [104.89331875500004, 22.328251899000051],
              [104.89471610300011, 22.329489342000031],
              [104.89534465200006, 22.330400873000045],
              [104.89555361900004, 22.33144230100006],
              [104.89555279300006, 22.332548666000029],
              [104.89474595600009, 22.336290262000077],
              [104.89460494200006, 22.337852105000074],
              [104.89467354200005, 22.339609308000071],
              [104.89502250500006, 22.340455577000014],
              [104.89741969400006, 22.350893846000105],
              [104.90094642500001, 22.351045244000112],
              [104.90421652400005, 22.350945063000083],
              [104.90667497700007, 22.350292865000121],
              [104.9075534250001, 22.349394617000051],
              [104.90843265700002, 22.347270783000091],
              [104.90896053700008, 22.345473582000075],
              [104.90948913400004, 22.34253250400009],
              [104.91221515899998, 22.334853745000061],
              [104.91300595700005, 22.333546882000022],
              [104.91458656300009, 22.332485556000044],
              [104.91695668000008, 22.33215998100011],
              [104.92213559400004, 22.331754024000091],
              [104.92538299700006, 22.332245779000104],
              [104.929595556, 22.333473234000053],
              [104.93547610300001, 22.334374440000104],
              [104.93714384700007, 22.334375092000066],
              [104.93793411500009, 22.333721748000102],
              [104.93933913400008, 22.332333302000059],
              [104.94127140700007, 22.329392638000023],
              [104.94381799400003, 22.326452155000084],
              [104.94706675400006, 22.323185043000038],
              [104.95005155200002, 22.32138846700007],
              [104.95329917400005, 22.320735805000069],
              [104.95689789600002, 22.319919750000047],
              [104.96163729400011, 22.320002645000066],
              [104.96602558400011, 22.320248735000092],
              [104.96909730900006, 22.320821287000037],
              [104.97278322500004, 22.322537756000123],
              [104.97734672900006, 22.325398133000093],
              [104.97822434900012, 22.325929329000083],
              [104.97901428300001, 22.325929438000088],
              [104.98002367000004, 22.325766150000078],
              [104.98147196400006, 22.325153545000106],
              [104.98261306600006, 22.324418321000024],
              [104.99095170700006, 22.316575287000084],
              [104.99178553000004, 22.315717437000067],
              [104.99218052700004, 22.314696138000031],
              [104.99257563600005, 22.311346237000059],
              [104.9927951170001, 22.30975300100004],
              [104.99376072200005, 22.30448305],
              [104.99384850300004, 22.303829412000105],
              [104.99406792700006, 22.303420892000055],
              [104.99476999600007, 22.302889826000083],
              [104.99604246600011, 22.302399636000111],
              [104.99740270300003, 22.301991134000062],
              [105.00000008800001, 22.301389169000082],
              [105.00326154000003, 22.299752490000024],
              [105.00470126800005, 22.298450158],
              [105.00562862200003, 22.296900625000049],
              [105.00591644700005, 22.294982048000023],
              [105.00493645300006, 22.291604600000078],
              [105.00244561000002, 22.285803731000087],
              [105.00194820500008, 22.284595491000033],
              [105.00195752600004, 22.283994837000073],
              [105.00274650600005, 22.28304420000002],
              [105.00510034500012, 22.281033162000071],
              [105.00589674500002, 22.279601975000048],
              [105.00604622900003, 22.278282259000015],
              [105.00583662800001, 22.275155352000105],
              [105.00640662900001, 22.271678421000018],
              [105.007202971, 22.270247202000078],
              [105.00806248900007, 22.26935859400001],
              [105.00942390000009, 22.268474603000051],
              [105.01104319800004, 22.267603448],
              [105.01207705500005, 22.267497147000093],
              [105.01355563200009, 22.268289650000028],
              [105.01520665300009, 22.269993981000091],
              [105.01696631000003, 22.273021485000029],
              [105.019236484, 22.276416254000075],
              [105.02280422100006, 22.279347676000057],
              [105.02714613000005, 22.282289341000094],
              [105.03341597300003, 22.285737169000086],
              [105.0400914840001, 22.287988633000033],
              [105.04459226900008, 22.289009541000034],
              [105.04586404900006, 22.290227929000039],
              [105.04658866900006, 22.291439423],
              [105.04717224000004, 22.293348980000083],
              [105.04735610700006, 22.294682688000123],
              [105.04712713800004, 22.300457613000013],
              [105.04621001400008, 22.30284529500004],
              [105.04349397800007, 22.306803069000033],
              [105.04223007000003, 22.30844279800008],
              [105.04202069600002, 22.30963619900006],
              [105.04260427700008, 22.311545696000046],
              [105.04380640600007, 22.313083039000055],
              [105.04482175600006, 22.313476842000043],
              [105.04665407000006, 22.313881448000039],
              [105.05584180000005, 22.314193061000061],
              [105.05686298400009, 22.314206535000025],
              [105.05746990000003, 22.314594891000091],
              [105.05766254700008, 22.315358132000092],
              [105.05756695900007, 22.321632584000014],
              [105.05849551800007, 22.327730279000058],
              [105.05863605800006, 22.331915844000072],
              [105.05779297200003, 22.333616232000075],
              [105.05490147800006, 22.335669926],
              [105.04996730600001, 22.337696570000084],
              [105.04871558900001, 22.33939150100008],
              [105.04721015600006, 22.344315846000114],
              [105.04428928800006, 22.348270587000037],
              [105.04077878100003, 22.350696154],
              [105.03920242900008, 22.351776043000093],
              [105.03917999300009, 22.3532392380001],
              [105.04020814700007, 22.354553749000061],
              [105.04245164800007, 22.356372197000063],
              [105.04487734000004, 22.35770519400004],
              [105.04661674400008, 22.358216045000034],
              [105.05447766900008, 22.358319937000104],
              [105.06042942200003, 22.357585377000078],
              [105.07621771200003, 22.353402345000092],
              [105.07866081800003, 22.353596946000032],
              [105.08143609500006, 22.354934136000089],
              [105.08332570400009, 22.357072749000025],
              [105.08399078800005, 22.358852416000019],
              [105.08466918900004, 22.360667667000108],
              [105.08464709700009, 22.362130839000116],
              [105.0840837420001, 22.364725173000096],
              [105.08013476000012, 22.371665533000048],
              [105.07544098900007, 22.378104238000063],
              [105.07147807200009, 22.382788064000088],
              [105.07071286900002, 22.383892313000061],
              [105.07068539800005, 22.385702681000019],
              [105.07139990600011, 22.387940630000088],
              [105.0734974420001, 22.394422860000063],
              [105.07447866500011, 22.395631429000048],
              [105.0757494580001, 22.39657807200004],
              [105.07745861700003, 22.39686616700007],
              [105.08060749700007, 22.396375944000013],
              [105.08532877000009, 22.395773361000067],
              [105.08746624000001, 22.396066972000114],
              [105.08888186600008, 22.39688257600001],
              [105.09069377, 22.399829008000083],
              [105.09347312200001, 22.404913664000055],
              [105.09805278700003, 22.413741486000035],
              [105.09855001600005, 22.41866340600005],
              [105.09822450900003, 22.42131615300007],
              [105.09481253100007, 22.42977403499999],
              [105.09220027900002, 22.4325297990001],
              [105.08917560700004, 22.434217375000017],
              [105.08699762300006, 22.436580184000029],
              [105.08625996900004, 22.439044529000093],
              [105.08624668200009, 22.439924463000068],
              [105.08650749200009, 22.440556517000054],
              [105.08838632400003, 22.441461127000117],
              [105.09603887500002, 22.444955443000048],
              [105.11413237900005, 22.455373848000093],
              [105.11559471700008, 22.457027170000018],
              [105.11599081700008, 22.457660903000054],
              [105.11595511800006, 22.460049206000043],
              [105.11539753900011, 22.461173515000077],
              [105.11307716100006, 22.462652218000031],
              [105.11033995100007, 22.464879834000051],
              [105.1092378860001, 22.466248521000054],
              [105.10877963900002, 22.469762801000037],
              [105.10871747500002, 22.473910778000047],
              [105.10918471400005, 22.478819892000068],
              [105.11005376000001, 22.483985592000089],
              [105.11003304700007, 22.485368216],
              [105.10946407000007, 22.487246611000074],
              [105.10717698400002, 22.490137670000095],
              [105.10670760699999, 22.49073098100007],
              [105.10614989500003, 22.49185519400001],
              [105.10621000700003, 22.493275818000043],
              [105.1061957740001, 22.494224555000031],
              [105.10684996700004, 22.495972703000078],
              [105.10867337400002, 22.499159355000039],
              [105.11201096200003, 22.503472602000052],
              [105.11649437300009, 22.510805364000049],
              [105.12206967000007, 22.524794025000091],
              [105.12501358900002, 22.532738991000123],
              [105.12547441100006, 22.536065851000053],
              [105.12542727200005, 22.539228016000031],
              [105.12449655200005, 22.544592703000085],
              [105.12342632600006, 22.547899769000068],
              [105.11924426400006, 22.554487527000063],
              [105.11884555800003, 22.556001174000073],
              [105.11881082300009, 22.558325595000014],
              [105.119172699, 22.562011276000057],
              [105.11911770600008, 22.565691545],
              [105.11770490800006, 22.56954802700006],
              [105.11589253200003, 22.572236905000054],
              [105.11365594200007, 22.575404613000046],
              [105.11363999100008, 22.576469932000087],
              [105.11383685000003, 22.577247394000018],
              [105.11454905000008, 22.578418967000054],
              [105.11887021100003, 22.582252429000071],
              [105.12185097900002, 22.585099883000034],
              [105.12309020000005, 22.585890740000103],
              [105.12568303600008, 22.586795864000031],
              [105.12920101200011, 22.588584603000058],
              [105.13115839700006, 22.590159529000076],
              [105.13197498800012, 22.591332360000038],
              [105.13196490100009, 22.592010261000084],
              [105.13185346800002, 22.59249314300007],
              [105.13047676200006, 22.593928405000071],
              [105.12889012500005, 22.595457813000053],
              [105.12790669000003, 22.597609211000069],
              [105.12604336500006, 22.604479473000083],
              [105.12602071800005, 22.605998227000065],
              [105.12613428700003, 22.606817639],
              [105.12836487200011, 22.609066433000088],
              [105.13047844900011, 22.610729451000019],
              [105.1346025350001, 22.612535060000113],
              [105.13684196200003, 22.614199618000086],
              [105.13770496200002, 22.615379146000066],
              [105.13818373200012, 22.617021133000037],
              [105.13872757500008, 22.622753548000048],
              [105.139323468, 22.62498122700007],
              [105.14069481700005, 22.625816685000096],
              [105.14195079700005, 22.625949589000079],
              [105.14308620800003, 22.625730403000063],
              [105.14524682100009, 22.624239019000036],
              [105.14970236100007, 22.620673652000086],
              [105.15108925800007, 22.620457638000069],
              [105.15422484300007, 22.621081794000105],
              [105.15721743300006, 22.622872545000035],
              [105.15770845, 22.623696699000014],
              [105.15769811100003, 22.624397638000104],
              [105.15678842199999, 22.626372431000071],
              [105.15253650000008, 22.634701759000016],
              [105.14676281100003, 22.646585910000013],
              [105.14429740700005, 22.652403222000125],
              [105.14259319600009, 22.654071091000013],
              [105.14061295700003, 22.655475475000038],
              [105.1389125470001, 22.656883409],
              [105.13818199900005, 22.658953594000053],
              [105.13855355000007, 22.662207599000084],
              [105.13781718700007, 22.664667583000053],
              [105.13699566700004, 22.665674421000048],
              [105.134767874, 22.6667753],
              [105.130200147, 22.669078211000034],
              [105.12885516400009, 22.670293013],
              [105.12884904400002, 22.670703627000051],
              [105.12894736099999, 22.671526264000086],
              [105.13002842, 22.673182883000017],
              [105.13230726500008, 22.676086896000044],
              [105.13371088500004, 22.678363635000039],
              [105.13636494300007, 22.685789823000043],
              [105.13744004300004, 22.687856954000019],
              [105.13961470400002, 22.690348797000048],
              [105.14187915300008, 22.692385205000051],
              [105.14528948800007, 22.695451961000046],
              [105.14649051800006, 22.696493938000017],
              [105.14698307700004, 22.696601744000056],
              [105.15725607800005, 22.693616575000064],
              [105.16593519400007, 22.690313983000095],
              [105.16867782400007, 22.688568157000063],
              [105.16997576300008, 22.687249154000085],
              [105.17192809000005, 22.684899753000117],
              [105.17434884800007, 22.683298151000116],
              [105.19054785600011, 22.679347485000086],
              [105.19663688900006, 22.67823681800003],
              [105.20239551400002, 22.677863762000072],
              [105.20384001200009, 22.677436671000081],
              [105.20529311300005, 22.676416152000023],
              [105.20675480400011, 22.674802182000064],
              [105.20772571900007, 22.672798862000043],
              [105.20931459100008, 22.668472830000042],
              [105.21014618900006, 22.667255015000087],
              [105.21210905500001, 22.665201013000043],
              [105.21529392900001, 22.663067731000105],
              [105.21744103700011, 22.662338636000086],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 61, code: "AD01", ten_tinh: "Vĩnh Long" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [106.02713459700004, 10.286297203000048],
              [106.02695133700007, 10.274616883000041],
              [106.03334563700005, 10.271856614000095],
              [106.03276297300008, 10.258373894000082],
              [106.03273700700005, 10.257773016000014],
              [106.03271628800012, 10.257293575000046],
              [106.03358192000006, 10.254983487000045],
              [106.0360147050001, 10.251585209000067],
              [106.03647915300006, 10.250936424000122],
              [106.04112578500003, 10.247893199000123],
              [106.04614059100001, 10.246218704000041],
              [106.05384993399998, 10.245328562000021],
              [106.07130851700008, 10.244406275000093],
              [106.08003871100006, 10.244233190000072],
              [106.09044002200002, 10.243405343000054],
              [106.10076476100005, 10.241495888000072],
              [106.11035578200003, 10.23785816800009],
              [106.12139300200002, 10.231763720000052],
              [106.12690534100003, 10.226985933000064],
              [106.12994879000004, 10.223728829000043],
              [106.13292087500011, 10.220547972000114],
              [106.13918592000006, 10.209704568000076],
              [106.14101886600011, 10.206531929000027],
              [106.14594543200005, 10.200890720000098],
              [106.14826496600004, 10.198575315000101],
              [106.15677857900005, 10.193955462000035],
              [106.15837060200005, 10.193093448000033],
              [106.16147917000004, 10.191606827000042],
              [106.16387205500006, 10.189507362000059],
              [106.17342430100011, 10.175918156000117],
              [106.17375095300005, 10.175673480000034],
              [106.17419976200006, 10.175337300000107],
              [106.17458494200004, 10.175048774000038],
              [106.17589368100005, 10.174899878000064],
              [106.17778485300003, 10.174893083000123],
              [106.18215492800005, 10.176463547000123],
              [106.18564792800004, 10.176883517000055],
              [106.18710267500006, 10.176878249000064],
              [106.18956934100004, 10.175138867000031],
              [106.19784146600006, 10.169773235000077],
              [106.20219211900005, 10.166152264000059],
              [106.21139167200005, 10.156096395000079],
              [106.21758125100006, 10.1496022680001],
              [106.21867361200003, 10.148456141000038],
              [106.22573552900009, 10.14104625100005],
              [106.22877473800003, 10.136997283000058],
              [106.23166566400003, 10.132227846000051],
              [106.23932702700004, 10.119797855000099],
              [106.246126858, 10.110399202000051],
              [106.25264381700001, 10.103164504000082],
              [106.26306753200004, 10.090867814000058],
              [106.27203339199998, 10.077855490000063],
              [106.27430865000004, 10.074502849000043],
              [106.27752778500012, 10.069759188000125],
              [106.28751437200005, 10.051509868000073],
              [106.27385717300007, 10.060833040000093],
              [106.256899015, 10.075317844000063],
              [106.24063833300009, 10.082445109000025],
              [106.23944849800006, 10.082757634000037],
              [106.23153199300008, 10.078458331000116],
              [106.22829206900005, 10.073832244000068],
              [106.22749320100003, 10.070527862000043],
              [106.22573156400003, 10.063506597000059],
              [106.22101176600003, 10.045102624],
              [106.2296463170001, 10.042186572000043],
              [106.22986302900009, 10.036038344000085],
              [106.22990636500003, 10.031001156000039],
              [106.23010952600006, 10.02714078200002],
              [106.23013119000008, 10.024622221000088],
              [106.22895896900008, 10.022597322000058],
              [106.22777810300008, 10.02157983700007],
              [106.224908048, 10.019372639000055],
              [106.21916965900003, 10.015090123000013],
              [106.21763669700003, 10.012759261000069],
              [106.21594407100005, 10.010185569000017],
              [106.21240016200001, 10.004885044000032],
              [106.21221688200004, 10.004705680000113],
              [106.21157186700005, 10.00486643000009],
              [106.21103761300007, 10.005006280000115],
              [106.20595167199998, 10.006389010000037],
              [106.20427588600006, 10.008092997000071],
              [106.20134828200004, 10.011162667000038],
              [106.20108957700003, 10.011400299000071],
              [106.20088473800006, 10.011555067000087],
              [106.20054312500012, 10.011944424000061],
              [106.19659507300004, 10.016444140000134],
              [106.19485599800004, 10.020962849000091],
              [106.19320771900009, 10.024810701000051],
              [106.19132661200004, 10.026137791000076],
              [106.18741508500005, 10.026439808000108],
              [106.14869796500007, 10.018209116000063],
              [106.1332321240001, 10.025375981000105],
              [106.13312762500007, 10.025123374000076],
              [106.13182077500004, 10.000596405000104],
              [106.13191128800007, 9.99990552100005],
              [106.13055972700006, 9.996441790000075],
              [106.12969922900001, 9.993852828000092],
              [106.12934427300004, 9.99274678400006],
              [106.12919117300007, 9.992158457000054],
              [106.12918444400005, 9.991766833000069],
              [106.12921960800003, 9.991207897],
              [106.12932196300005, 9.990869596000083],
              [106.12965436200003, 9.9896256740001],
              [106.13153607100003, 9.982860225000055],
              [106.13169629500004, 9.982417861000046],
              [106.13178789100007, 9.98205854600007],
              [106.1318106790001, 9.981547916000098],
              [106.13177689300005, 9.980317025000128],
              [106.13167447000011, 9.979002447000072],
              [106.13127534100003, 9.974741561000132],
              [106.13084817800004, 9.970081032000076],
              [106.13035684000006, 9.965271946000074],
              [106.13004931600003, 9.962435027000092],
              [106.1299730540001, 9.962165129000054],
              [106.12768337200001, 9.959120249000096],
              [106.125755263, 9.956410775000089],
              [106.1250576370001, 9.955467272000094],
              [106.12486373700004, 9.955209635000093],
              [106.12379950500009, 9.95387802600005],
              [106.12198420500009, 9.951606527000044],
              [106.11941879700001, 9.948621511000065],
              [106.11808020200002, 9.947059247000121],
              [106.11781943600002, 9.946762805000018],
              [106.11685118500002, 9.945727531000069],
              [106.11399203200006, 9.942647496000044],
              [106.11125985600003, 9.939776999000037],
              [106.11107368400009, 9.939600667000102],
              [106.08337031500011, 9.96228650700006],
              [106.07246471900008, 9.944384116000025],
              [106.05200640100004, 9.948093496000075],
              [106.0520027970001, 9.946967602000088],
              [106.05207443200007, 9.944920206000061],
              [106.05219534500004, 9.943617077000065],
              [106.05210073700003, 9.943369223000074],
              [106.0509412320001, 9.942597428000076],
              [106.04787045000005, 9.940652934000037],
              [106.04519314700003, 9.939513644],
              [106.04362655700001, 9.938556981000078],
              [106.04099583200012, 9.936068225000037],
              [106.03972584800002, 9.934955538000077],
              [106.03859615400007, 9.933702813000076],
              [106.0377463560001, 9.931960682000083],
              [106.03738885400001, 9.930147246000052],
              [106.03668198100006, 9.929102573000124],
              [106.03527286300009, 9.928478812000028],
              [106.03266811200004, 9.927998315000062],
              [106.02880022500003, 9.928568544000081],
              [106.02458443000008, 9.93039601300003],
              [106.01835956100008, 9.93398529400009],
              [106.01701508600004, 9.931618478000102],
              [106.01431313800003, 9.922932473000044],
              [106.01375509300004, 9.921138449000109],
              [106.01310872000003, 9.918464487000021],
              [106.01200139900007, 9.917120061000071],
              [106.00709622800012, 9.914627932000107],
              [106.00018783000006, 9.910782673000094],
              [105.99663577300007, 9.907413986000078],
              [105.99418880100009, 9.905612045000021],
              [105.98748106000004, 9.904213787000058],
              [105.98329010500001, 9.903321597000046],
              [105.97692027600002, 9.910639555000065],
              [105.97101948400011, 9.914999078000085],
              [105.96219632600007, 9.921522258000069],
              [105.95598008100008, 9.923651825000055],
              [105.95237386800004, 9.922362522000048],
              [105.951877467, 9.920577046000021],
              [105.95220120700006, 9.919276560000066],
              [105.95791060800012, 9.91178785700013],
              [105.97129504900008, 9.8966142520001],
              [105.97259806300006, 9.895013875000112],
              [105.97456416000003, 9.891778006000072],
              [105.97779531200004, 9.886459846000049],
              [105.97897722200004, 9.884494060000025],
              [105.97551359400006, 9.885747975000035],
              [105.97138163000005, 9.888155400000096],
              [105.96811442299999, 9.891415827000037],
              [105.96398965600012, 9.896332706000075],
              [105.95917855200004, 9.902506265000087],
              [105.95527588699998, 9.904798802000057],
              [105.94712709000007, 9.909955069000096],
              [105.93146418300005, 9.921348992000032],
              [105.91930338200007, 9.931306781000069],
              [105.91470853900003, 9.933030436000044],
              [105.90918679800004, 9.932246965000056],
              [105.90627204000002, 9.932544093000038],
              [105.90458844700007, 9.932715698000026],
              [105.90114435300003, 9.934778279000065],
              [105.89952033900005, 9.936002376000095],
              [105.89913588700003, 9.936292153000091],
              [105.89881126700008, 9.936536830000085],
              [105.89857564000003, 9.936714430000036],
              [105.89798667900007, 9.937158346000084],
              [105.89766161400009, 9.937403356000106],
              [105.89706026400006, 9.937856606000127],
              [105.89643587500009, 9.938327217000074],
              [105.89594228100002, 9.938696031000037],
              [105.89537092100008, 9.939078380000053],
              [105.88210298000008, 9.947956731000128],
              [105.88170108700004, 9.948248474000108],
              [105.87754855600011, 9.951537366000094],
              [105.87480740000005, 9.953731387000085],
              [105.86455129100003, 9.961972860000044],
              [105.86414304800007, 9.96232275600004],
              [105.85899340100005, 9.96703343200007],
              [105.85678899600001, 9.969059641000031],
              [105.84290347900006, 9.983684874000074],
              [105.84258099200011, 9.984050861000069],
              [105.83531311800006, 9.992975654000079],
              [105.83372063200002, 9.994951629000058],
              [105.82963589200008, 10.000019902000099],
              [105.82328523700008, 10.008007337],
              [105.82153643300006, 10.011060255000091],
              [105.81259379000008, 10.026670188000073],
              [105.80467352100005, 10.038977343000075],
              [105.80154339000006, 10.043351964000101],
              [105.80020281200008, 10.045225514000043],
              [105.79745381900008, 10.049067308000035],
              [105.79469143000006, 10.052927668000054],
              [105.79299854900005, 10.055265477000129],
              [105.79255960900005, 10.0558716360001],
              [105.78614905900007, 10.064723955000064],
              [105.7828529010001, 10.068213338000119],
              [105.78052313400008, 10.069866168000095],
              [105.77674785900007, 10.072540579000043],
              [105.76418732600007, 10.079316137000125],
              [105.76016761800001, 10.08270459300009],
              [105.75233154400011, 10.089091627000089],
              [105.75109662400007, 10.090098130000056],
              [105.74810539700012, 10.091743329000023],
              [105.742741107, 10.094417840000068],
              [105.732320945, 10.099253944000084],
              [105.72788682800011, 10.102335761000079],
              [105.72304798800005, 10.106339520000061],
              [105.7144847600001, 10.113424326000063],
              [105.70932862600007, 10.117121896000112],
              [105.70646433700006, 10.118924038],
              [105.70509040800006, 10.119788505000125],
              [105.68189089200006, 10.134383222000041],
              [105.68084312000005, 10.13504828000009],
              [105.68385096700004, 10.139281296000123],
              [105.6824206040001, 10.142489905000039],
              [105.68812973500005, 10.150726243000106],
              [105.6904608400001, 10.154029134],
              [105.69131890100005, 10.155388393000029],
              [105.69469150900002, 10.160417922000063],
              [105.69649744800006, 10.162920917000038],
              [105.69931489200012, 10.167322642000086],
              [105.69991418700005, 10.168487529000101],
              [105.70372391700006, 10.174053232000089],
              [105.70947128100006, 10.182304412000052],
              [105.71098215100002, 10.184504756000122],
              [105.71334668900009, 10.187805039000073],
              [105.71364212900002, 10.188160864000027],
              [105.72093426600003, 10.198838879000059],
              [105.72316820600004, 10.20220416500006],
              [105.72387631500001, 10.203145410000046],
              [105.725989951, 10.206036679000084],
              [105.72848656200009, 10.204899883000083],
              [105.7302676260001, 10.204072799000039],
              [105.73712116500006, 10.201358208000054],
              [105.74573272400008, 10.197848297000077],
              [105.74615587200002, 10.198402978000052],
              [105.74633467200006, 10.198212263000082],
              [105.74694699000001, 10.197559135000043],
              [105.74878861900007, 10.195300005000083],
              [105.74905025900004, 10.194928224000053],
              [105.75090090200004, 10.192529875000032],
              [105.75162040900007, 10.19151675500005],
              [105.75259239800002, 10.190233958000048],
              [105.7543021800001, 10.18775239800007],
              [105.75548674300005, 10.186064368000112],
              [105.75711942700005, 10.184188076000032],
              [105.75752498700002, 10.183663664000074],
              [105.76294597100012, 10.179523707000014],
              [105.77308929500006, 10.19058201500007],
              [105.77342870300006, 10.190148091000049],
              [105.77402520300004, 10.189357278000076],
              [105.77517475800003, 10.187830371000038],
              [105.77892587100007, 10.183022419000041],
              [105.78002903200004, 10.181608404000107],
              [105.79685304700003, 10.159780581000087],
              [105.79749804700002, 10.158996422000131],
              [105.79955032800009, 10.156479626000079],
              [105.80112930100009, 10.155979176000061],
              [105.80401271700001, 10.15497775200007],
              [105.80688750800002, 10.153940581000052],
              [105.81208073800009, 10.152128440000039],
              [105.81439900100006, 10.151255328],
              [105.816502332, 10.150514563000089],
              [105.82107640500001, 10.150058354000089],
              [105.82365898100009, 10.149925611000031],
              [105.82447232200006, 10.150002487000085],
              [105.82566607700007, 10.150040036000084],
              [105.82609886900008, 10.150050158000033],
              [105.828331695, 10.150371563000069],
              [105.82912888600006, 10.15036954900008],
              [105.82947973600004, 10.150400235000044],
              [105.82979885500006, 10.150494127000085],
              [105.83389726400006, 10.150815195000094],
              [105.83667156100002, 10.150839705000079],
              [105.84190145400002, 10.150952589],
              [105.84478739200006, 10.150986908000091],
              [105.84697077000006, 10.150981276000069],
              [105.84885433500003, 10.158258543],
              [105.84963393800005, 10.161147359000021],
              [105.86193042100007, 10.155951465000078],
              [105.86450175, 10.154864694000041],
              [105.86678773300005, 10.155115581000105],
              [105.86880257200005, 10.155336689000103],
              [105.87241576200005, 10.167303539000015],
              [105.86821519400004, 10.169840569000053],
              [105.87499925000003, 10.183362822000051],
              [105.87449176100004, 10.190391520000029],
              [105.88442708700009, 10.190246993000061],
              [105.88732846400008, 10.190204698000025],
              [105.89356216000009, 10.186954530000046],
              [105.89743620600008, 10.192702306000088],
              [105.91157692200009, 10.193498656000076],
              [105.91601900900011, 10.206503649000085],
              [105.91787734600004, 10.211943825000079],
              [105.9173359820001, 10.213793240000067],
              [105.91622473800007, 10.214756462000095],
              [105.91482657700003, 10.217016621000051],
              [105.91328734400004, 10.220765313000067],
              [105.91293534800005, 10.221700311000069],
              [105.9116048330001, 10.225234489000099],
              [105.91083476700007, 10.226964819],
              [105.91034796400001, 10.227468319000097],
              [105.90955495200004, 10.228288531000105],
              [105.90554244400005, 10.231035991000057],
              [105.90143298900003, 10.233783681000117],
              [105.90029680100007, 10.234530900000037],
              [105.9001039930001, 10.234867471000085],
              [105.90005650900008, 10.2352036460001],
              [105.90020434800006, 10.245549193000068],
              [105.88875311, 10.245137436000016],
              [105.88312275599999, 10.255103829000054],
              [105.87520414200002, 10.265432577000054],
              [105.87613641100006, 10.265550545000044],
              [105.879059881, 10.266017917000079],
              [105.87947278400006, 10.266107243000098],
              [105.87979130100004, 10.266218725000085],
              [105.88010916200005, 10.266346020000048],
              [105.88116146500008, 10.266805813000078],
              [105.88246362400008, 10.267647983000037],
              [105.88373195700004, 10.268708790000057],
              [105.88496984500006, 10.26965979000005],
              [105.88587687000005, 10.270546785000077],
              [105.88627660499999, 10.270896067000111],
              [105.88690424600006, 10.271530085000078],
              [105.888051015, 10.272771511000121],
              [105.89115065500005, 10.275938766000079],
              [105.89217104000009, 10.276922649000129],
              [105.895908848, 10.275471754000074],
              [105.89920991000011, 10.275035801000064],
              [105.90366401800006, 10.276161616000044],
              [105.90926961900006, 10.278280070000088],
              [105.92232418, 10.2840639780001],
              [105.923928141, 10.285185076000046],
              [105.92485924600004, 10.285840093000063],
              [105.92889849900004, 10.288681554000066],
              [105.93696258000006, 10.296625636000021],
              [105.95351458, 10.309808667000107],
              [105.96522422300004, 10.320049210000091],
              [105.97151174500007, 10.325547276000078],
              [105.97807454200004, 10.330238024000058],
              [105.97829253400005, 10.330374604000079],
              [105.98431174500008, 10.331910699000078],
              [105.9896244560001, 10.331752181000107],
              [105.99206205300008, 10.330606532000084],
              [105.99507179300006, 10.328747731000119],
              [106.00199023400006, 10.323227841000103],
              [106.00980492000006, 10.316992309000026],
              [106.01127480700009, 10.315819414000059],
              [106.026827064, 10.304221844000059],
              [106.03270893000004, 10.299835057000093],
              [106.02816639700006, 10.290331771000092],
              [106.02713459700004, 10.286297203000048],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 62, code: "AD01", ten_tinh: "Vĩnh Phúc" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.58499568700005, 21.53527037000007],
              [105.59104653200004, 21.534487822000067],
              [105.59467157700003, 21.533950248000089],
              [105.59725929100009, 21.533243484000053],
              [105.59929031200009, 21.532215754000013],
              [105.60155719400007, 21.530245795],
              [105.60452258100003, 21.526183871000093],
              [105.60521464800009, 21.524616744000099],
              [105.60524182300003, 21.522412590000073],
              [105.60504090000011, 21.520520883000046],
              [105.60505643000005, 21.519261364000016],
              [105.60563248500003, 21.518007884000042],
              [105.60767622900002, 21.515930433000015],
              [105.60881148100006, 21.51478794100008],
              [105.60949959700007, 21.513535649000026],
              [105.60963494500007, 21.511647549000124],
              [105.60944561600004, 21.50881115],
              [105.60957709600008, 21.507237910000043],
              [105.61003323400007, 21.506612963000023],
              [105.61398761500006, 21.504136086000081],
              [105.62257892400001, 21.498349572000031],
              [105.62472410200004, 21.497112809000015],
              [105.62742202300007, 21.49651181000003],
              [105.62955818500006, 21.496009753000052],
              [105.63045876400007, 21.495704443000108],
              [105.63204084600005, 21.494671544000084],
              [105.63294653100012, 21.493946357000127],
              [105.63108321200005, 21.490462138000055],
              [105.63044012500004, 21.48804073000008],
              [105.62938713600005, 21.48246549000001],
              [105.62940250500007, 21.481205874000061],
              [105.62963947100005, 21.480158594],
              [105.63077310500006, 21.479120883000036],
              [105.63428088900012, 21.476428787000046],
              [105.637672681, 21.474050343000073],
              [105.64061095400002, 21.472086922000067],
              [105.64174448300011, 21.471049149000088],
              [105.64288436500001, 21.469486515000114],
              [105.6433492190001, 21.468126664000039],
              [105.64359374200009, 21.466449509000093],
              [105.64439480600002, 21.465093221000053],
              [105.65030519600002, 21.458331891000078],
              [105.65555605100006, 21.4597522810001],
              [105.65924682200009, 21.460421184000019],
              [105.66241258500001, 21.46085952200005],
              [105.66433321500003, 21.46087976000004],
              [105.66667004800011, 21.460518820000019],
              [105.672450428, 21.459037405000103],
              [105.67438180500002, 21.458158072000089],
              [105.67646111900004, 21.456380673000105],
              [105.68107103700007, 21.449489120000109],
              [105.68344140000009, 21.446300993000108],
              [105.68483614500012, 21.444387810000087],
              [105.68574377400007, 21.443764826000056],
              [105.68649603100005, 21.443248481000055],
              [105.68997889500011, 21.441873116000032],
              [105.69438156600005, 21.440510094000054],
              [105.69601341400006, 21.440292243000059],
              [105.69976721600005, 21.440800836000037],
              [105.70189752900002, 21.440822926000088],
              [105.70252548300002, 21.44071202700006],
              [105.70328291000007, 21.440250264000049],
              [105.70481715100001, 21.437683244000105],
              [105.7063458100001, 21.435585777000107],
              [105.70951451000009, 21.432565991],
              [105.71078002500008, 21.43152240100008],
              [105.71107123200002, 21.431530711000057],
              [105.71203862700004, 21.431065775000043],
              [105.71354229800002, 21.431081287],
              [105.71504094400002, 21.431748130000102],
              [105.71840444500006, 21.433244677000026],
              [105.72040110000006, 21.433969647000062],
              [105.72165006200001, 21.434334701000068],
              [105.72195186800012, 21.434296770000039],
              [105.72244125300003, 21.434073787000059],
              [105.72269350400002, 21.433316347000094],
              [105.72314806500006, 21.429140854000117],
              [105.72285475700004, 21.426477723000033],
              [105.72193035800002, 21.422288014000031],
              [105.72082595600004, 21.419616511000058],
              [105.7195513410001, 21.417627284000048],
              [105.71939536100007, 21.417093644000126],
              [105.71932227200006, 21.416408847000042],
              [105.7195727380001, 21.415803378000042],
              [105.7232547720001, 21.413105041000065],
              [105.72436500200003, 21.412272805000043],
              [105.72595539600007, 21.411080610000077],
              [105.72770723700008, 21.410259758000016],
              [105.72927393500004, 21.410275811000098],
              [105.731828267, 21.411102758000041],
              [105.73465503700002, 21.413000185],
              [105.736348638, 21.414352130000047],
              [105.73804381500001, 21.415570615000057],
              [105.74002853300001, 21.416391646000044],
              [105.74358156300009, 21.417095157000077],
              [105.74586046300008, 21.417118340000037],
              [105.74714544500009, 21.416864482000086],
              [105.75102197600003, 21.414234544000088],
              [105.75361654900003, 21.411591550000075],
              [105.76056222600006, 21.402185698000054],
              [105.76157542700005, 21.400583202000064],
              [105.76243277700011, 21.397634163000042],
              [105.76316473300001, 21.393655075000062],
              [105.76346135400004, 21.391729116000043],
              [105.76325270700001, 21.390616036000054],
              [105.75885706800003, 21.382150353000014],
              [105.75805570000006, 21.380802491000047],
              [105.75795800700007, 21.380418708],
              [105.75786363300007, 21.379747873],
              [105.75838186000004, 21.379083234000085],
              [105.76145920700003, 21.377870266000087],
              [105.76494490700004, 21.376661371000068],
              [105.76638199500012, 21.37600597500002],
              [105.76772575800001, 21.374584040000052],
              [105.77165484500003, 21.370317141000058],
              [105.77238267400003, 21.369176071000062],
              [105.77249354400006, 21.368411589000047],
              [105.77240679000008, 21.36707092900005],
              [105.77241884900009, 21.36601835],
              [105.77295342800009, 21.363918327000064],
              [105.7735846140001, 21.362297767000094],
              [105.77430802100008, 21.361539429000054],
              [105.77502375700007, 21.361450921000099],
              [105.77778350600005, 21.361191509000044],
              [105.78115580000011, 21.360938197000046],
              [105.78217998600012, 21.36066134600005],
              [105.78297838800009, 21.360337701],
              [105.78410578400012, 21.358663002000085],
              [105.78473936000005, 21.356119178000107],
              [105.78651850000008, 21.352524205000023],
              [105.78744865000004, 21.350479170000099],
              [105.7883844080001, 21.347938317000015],
              [105.78854520500008, 21.347089849000064],
              [105.78832818500007, 21.346237617000071],
              [105.78765778900002, 21.345380848000062],
              [105.78637888400007, 21.344872200000076],
              [105.78428992700007, 21.344377382000076],
              [105.7822334900001, 21.343909833000026],
              [105.78095460800006, 21.343401152000041],
              [105.77900296000006, 21.34224816900003],
              [105.77503633600004, 21.338878891000057],
              [105.77459352000008, 21.337953519000052],
              [105.77163771000004, 21.332114874000041],
              [105.77086587400004, 21.330229811000031],
              [105.77043608900009, 21.328171081000043],
              [105.76985601100006, 21.326039999000024],
              [105.76911346900008, 21.324899050000035],
              [105.76696225400008, 21.321406113000037],
              [105.76509623100004, 21.319403698000094],
              [105.76315225900005, 21.317613012000081],
              [105.76083871100012, 21.315110135000026],
              [105.76002315900004, 21.313755864000058],
              [105.75928643300003, 21.312119020000075],
              [105.75870162800004, 21.310412846000034],
              [105.75849702400008, 21.30849799800005],
              [105.75873590700003, 21.307437748000076],
              [105.75960475700006, 21.30439056500002],
              [105.75338043600003, 21.300067471000084],
              [105.74709492900008, 21.294848574000099],
              [105.74618185900003, 21.294175064000065],
              [105.74574405600005, 21.293869631000106],
              [105.74445256000004, 21.294708471000057],
              [105.74412529600005, 21.294191090000055],
              [105.74350389900006, 21.293253090000071],
              [105.74039110000007, 21.28907116],
              [105.740004877, 21.288724340000087],
              [105.73967450900003, 21.288555663000075],
              [105.73903061600004, 21.288408722000085],
              [105.73712005000006, 21.288520218000073],
              [105.73588042100008, 21.288601606000057],
              [105.73534051600008, 21.288426691000062],
              [105.734180275, 21.287229047],
              [105.73351517200004, 21.28616896000004],
              [105.73303484000009, 21.284761069000076],
              [105.73313897, 21.283576350000111],
              [105.73351402300003, 21.282394400000015],
              [105.73344541300004, 21.280530318000054],
              [105.73167578600008, 21.277378312000025],
              [105.72940197600006, 21.274629207000118],
              [105.72598064300004, 21.270527147000081],
              [105.72447731900009, 21.26929161],
              [105.72155815800005, 21.267554995000104],
              [105.72112535400004, 21.265027047000018],
              [105.7213162080001, 21.263540005000081],
              [105.72163516300003, 21.262788047000036],
              [105.72189213000006, 21.262441493000047],
              [105.72614401300005, 21.251423571000046],
              [105.72656977800003, 21.249534631000074],
              [105.72658304700005, 21.248398775000062],
              [105.726197099, 21.246880158000089],
              [105.72352273200006, 21.244126278000053],
              [105.72353661500006, 21.242939441000082],
              [105.72455852800006, 21.242119062000064],
              [105.72607682200001, 21.242134659000023],
              [105.72897581900006, 21.243113997000052],
              [105.73099883800002, 21.243253430000038],
              [105.73226824500007, 21.242910345000077],
              [105.73444957400008, 21.240321311000088],
              [105.73548523700006, 21.238314031000094],
              [105.73549628500008, 21.237364551000049],
              [105.73461615800009, 21.236880751000072],
              [105.73335094200004, 21.23686780200007],
              [105.7326077060001, 21.237127258000044],
              [105.73149146900002, 21.236556797],
              [105.73095797700002, 21.235614917000056],
              [105.73052152300005, 21.23408170900003],
              [105.72504514500008, 21.229084475],
              [105.72192460900008, 21.226100763000133],
              [105.71783017400006, 21.230421216000117],
              [105.71661716000006, 21.228146425000105],
              [105.71563510900008, 21.226774234000075],
              [105.71527718800003, 21.226344889000082],
              [105.714553354, 21.226167156000116],
              [105.70829903100007, 21.225591735000116],
              [105.70666289300004, 21.225830183000127],
              [105.70313482700007, 21.22740512200005],
              [105.70253468700007, 21.22464778500008],
              [105.70046532800009, 21.220508641],
              [105.69627906200002, 21.225866882000055],
              [105.69451626600009, 21.224767597000046],
              [105.69708276900008, 21.214919173000091],
              [105.6970001, 21.214237258000068],
              [105.69655252500009, 21.21372182100005],
              [105.69618963300007, 21.213718044000096],
              [105.69527332700002, 21.21447468200008],
              [105.69371084700006, 21.216161033000077],
              [105.68784076300003, 21.221463035000099],
              [105.68405613200005, 21.226871797000065],
              [105.68329787800012, 21.229587997000095],
              [105.68328785500009, 21.230430072000047],
              [105.68328065400001, 21.231035017000096],
              [105.68363547400007, 21.231719757000086],
              [105.68444092000001, 21.232664595000102],
              [105.68579279600004, 21.233444889000097],
              [105.68623937600005, 21.23404545500005],
              [105.68632302800003, 21.234642227000045],
              [105.686396609, 21.235315800000087],
              [105.68575395600003, 21.235364461000117],
              [105.68175770100004, 21.23607083500012],
              [105.67936328700009, 21.235478081000011],
              [105.67819584100003, 21.233788979000053],
              [105.67761783600008, 21.232099504000068],
              [105.67707026200007, 21.230369594000109],
              [105.67557338500004, 21.228614354000094],
              [105.67405098500009, 21.228378235],
              [105.67254859600004, 21.228847880000053],
              [105.67121709900002, 21.229889355000076],
              [105.66979223000007, 21.231305195000068],
              [105.66848208700003, 21.233063962000109],
              [105.66759146500006, 21.23452879000007],
              [105.66579054500008, 21.239096236000066],
              [105.66508037000003, 21.240071551000113],
              [105.6642073900001, 21.240062343000062],
              [105.66316571000006, 21.239559951000061],
              [105.66197163100003, 21.237716514000034],
              [105.65976115700001, 21.236152272000105],
              [105.65624567900002, 21.235784896],
              [105.65360239700007, 21.236182807000056],
              [105.65201130100004, 21.237803946000078],
              [105.6502515120001, 21.238931877000056],
              [105.64902934100006, 21.238918904000045],
              [105.64416247500007, 21.237065367000071],
              [105.64251852200003, 21.235749226000067],
              [105.64228558800002, 21.233441757000016],
              [105.64321407700002, 21.228865167000066],
              [105.64462463900003, 21.22773355700005],
              [105.64707478100007, 21.227268198],
              [105.64882063400002, 21.227286745000029],
              [105.65160214000012, 21.228299089000032],
              [105.65264965600002, 21.228310197000063],
              [105.65459181800004, 21.226528941000119],
              [105.65636724600003, 21.224090675000085],
              [105.65690676600009, 21.222785949000041],
              [105.65629932300001, 21.221587101000061],
              [105.65518463000006, 21.220802051000121],
              [105.65292892200003, 21.219631514000078],
              [105.65222428800008, 21.219368640000091],
              [105.64840967200003, 21.217945490000112],
              [105.64545177000008, 21.217095020000087],
              [105.64218264500003, 21.216988281000049],
              [105.64072267300003, 21.217383154000032],
              [105.63714100200006, 21.217599348000071],
              [105.63546178000004, 21.217338585000078],
              [105.63518951000007, 21.217344590000039],
              [105.63456071100009, 21.216218153000099],
              [105.63460726800012, 21.215357721000046],
              [105.63545066100001, 21.213758620000078],
              [105.63719234200005, 21.21086073100011],
              [105.63739593300002, 21.209835554000023],
              [105.63725164100008, 21.208909814000073],
              [105.63686025400003, 21.208529370000036],
              [105.63523477000008, 21.20823882600007],
              [105.63219715700011, 21.208883186],
              [105.628862762, 21.210201154000075],
              [105.62611195700003, 21.210983861000088],
              [105.62394807300005, 21.210960646000032],
              [105.6196269480001, 21.210372766000063],
              [105.61844564500007, 21.210189222000061],
              [105.61731837600004, 21.208889332000069],
              [105.61554748000007, 21.206579215000062],
              [105.61479423200009, 21.205124113000103],
              [105.61467756100004, 21.204158195000034],
              [105.61507781000003, 21.202956685000096],
              [105.61745118600003, 21.19803833600011],
              [105.61811027400002, 21.19669694400006],
              [105.61951718900008, 21.195751929000025],
              [105.62169908100005, 21.194286327],
              [105.62330396500005, 21.192814497000079],
              [105.62639417500003, 21.187838968000072],
              [105.62699753200003, 21.185679506],
              [105.62701073000005, 21.184596674000119],
              [105.62673544900011, 21.183510756000068],
              [105.62534260000007, 21.179434648000047],
              [105.62521321800001, 21.178214907000026],
              [105.6252346800001, 21.176455292000092],
              [105.62544112800003, 21.173837036000087],
              [105.62304025800007, 21.174446252000045],
              [105.61963427700009, 21.175682575000017],
              [105.61731942700004, 21.176421420000047],
              [105.61541765700008, 21.176655520000054],
              [105.61392741400005, 21.176512164000094],
              [105.61068194500011, 21.175713396000084],
              [105.600693295, 21.171786554000072],
              [105.5909636750001, 21.168880393000045],
              [105.58524078100008, 21.167172522000044],
              [105.57170318500005, 21.163079996000086],
              [105.57210033600005, 21.158691866000098],
              [105.57219059000006, 21.157694614],
              [105.55991446100005, 21.153615127000066],
              [105.55684129700006, 21.153261553000036],
              [105.55220952000008, 21.153675646000103],
              [105.549726773, 21.15411321400007],
              [105.54532153900001, 21.154889532000084],
              [105.54475739000006, 21.154988943000049],
              [105.54384316400008, 21.155082677000081],
              [105.54272339600007, 21.155197478000076],
              [105.54187269300012, 21.155284691000098],
              [105.54106439600011, 21.155367553000112],
              [105.53975457600009, 21.155501821000122],
              [105.53940023500009, 21.15553983500007],
              [105.5391287260001, 21.155558787000039],
              [105.52838359000002, 21.156202512],
              [105.51983473500012, 21.15678300900008],
              [105.51499510800008, 21.157111488000076],
              [105.51465698300008, 21.157134433000024],
              [105.51249649200003, 21.157379840000075],
              [105.51214113000002, 21.157561353000084],
              [105.51153540100007, 21.157664136000065],
              [105.51019779500007, 21.157891101000104],
              [105.50823270000011, 21.158224522000069],
              [105.50647888500004, 21.158522079000086],
              [105.50469888600001, 21.158824063000026],
              [105.50358132300001, 21.159013654000077],
              [105.500706576, 21.159501318000103],
              [105.50005146600006, 21.159615301000123],
              [105.49782571700003, 21.160186772000067],
              [105.49166183300011, 21.161769240000083],
              [105.48351767300002, 21.165583391],
              [105.4828111620001, 21.16591379500008],
              [105.48183707800007, 21.166371301000098],
              [105.48103809000003, 21.167002558000014],
              [105.47973230300008, 21.168034205000104],
              [105.47844946200007, 21.169047850000041],
              [105.47548087400004, 21.171392939],
              [105.471990546, 21.174150222000065],
              [105.47012764400004, 21.175619764000061],
              [105.46824585100003, 21.177108286000035],
              [105.467459655, 21.177729308000082],
              [105.46689486000008, 21.17824657600007],
              [105.46909614300006, 21.180628514000063],
              [105.46645574399999, 21.190548961000033],
              [105.46463509300007, 21.201787856000109],
              [105.46142927200003, 21.212225106000027],
              [105.45768025200007, 21.223177756000041],
              [105.45678679800005, 21.225787734000079],
              [105.4524947140001, 21.233855420000047],
              [105.44909103700003, 21.238782310000111],
              [105.44666218500006, 21.24229791100003],
              [105.44113370800005, 21.247628017000061],
              [105.44095729600011, 21.261043388000097],
              [105.43880303800003, 21.265815535000065],
              [105.44712022700003, 21.26583769300003],
              [105.44757946600002, 21.272315180000057],
              [105.44759843800009, 21.273043136000069],
              [105.44840956400004, 21.280699971000061],
              [105.44955883100002, 21.288647513000036],
              [105.45091106400008, 21.293070985000014],
              [105.45112601300009, 21.294130519000106],
              [105.44919931600002, 21.295306898],
              [105.44861529900001, 21.295658608000068],
              [105.447492325, 21.296584601000035],
              [105.44740672300003, 21.297275344000077],
              [105.44750683500004, 21.297884743000047],
              [105.447819807, 21.298750014000056],
              [105.44810369000005, 21.299299189000109],
              [105.4483469730001, 21.299769820000058],
              [105.4490402820001, 21.300487416000102],
              [105.44995110100005, 21.301106145000048],
              [105.45166731900012, 21.302139615000023],
              [105.45212629800008, 21.302488022000077],
              [105.45295184900009, 21.303117432000107],
              [105.45380267600005, 21.304191622000076],
              [105.45445956100005, 21.305713454000049],
              [105.45479983100005, 21.307784075000043],
              [105.4547705410001, 21.310022677000084],
              [105.45400887500006, 21.312080619000092],
              [105.45119083600004, 21.316870490000014],
              [105.445400089, 21.324209349000078],
              [105.44462555400004, 21.324863278000095],
              [105.44057032800006, 21.328286898000037],
              [105.43574263400004, 21.332192119000034],
              [105.43083002, 21.334808461000065],
              [105.4213580620001, 21.33899502600007],
              [105.40977455000009, 21.345265694000126],
              [105.40254617100004, 21.349868190000088],
              [105.39832768500005, 21.353724601000046],
              [105.39544854700003, 21.357127949000088],
              [105.39340260800005, 21.360541043000048],
              [105.39151906899998, 21.364268475000081],
              [105.39029793000002, 21.368316132000054],
              [105.39027277100007, 21.370190611000069],
              [105.39035482900002, 21.371387108000036],
              [105.39041217600005, 21.372223252000119],
              [105.39069520700012, 21.375976102000038],
              [105.39065726299999, 21.378330204000036],
              [105.38987267400009, 21.385988363000052],
              [105.39026997000011, 21.390252659000097],
              [105.39180636600004, 21.394317362000059],
              [105.39487648700005, 21.40265957000009],
              [105.39659756200007, 21.409920916000047],
              [105.39655193200011, 21.413327927000033],
              [105.39559721700007, 21.41672424800003],
              [105.39095179000003, 21.424123536000117],
              [105.38702221200006, 21.428975489000024],
              [105.38242778500006, 21.432541697000126],
              [105.37808345500005, 21.434407042000103],
              [105.37380810200007, 21.43536801500003],
              [105.37221705900005, 21.435349163000069],
              [105.36825387500005, 21.434237338000095],
              [105.36294433900012, 21.431831639000087],
              [105.35743226400004, 21.427613179000012],
              [105.35507912500003, 21.425135996000044],
              [105.35229324100001, 21.421056344000085],
              [105.35094845800003, 21.419655976000037],
              [105.34970572300006, 21.419108699000056],
              [105.34845717600004, 21.418987286000046],
              [105.34720717800009, 21.418972331000049],
              [105.34447264400005, 21.419472017000096],
              [105.34286718, 21.420517627],
              [105.34125151400008, 21.422308497000039],
              [105.34008890399998, 21.424211269000089],
              [105.33947991100004, 21.427185507000068],
              [105.33945659400007, 21.428888957000055],
              [105.34104906900002, 21.437107201000046],
              [105.34177178400004, 21.442439924000034],
              [105.34150807300009, 21.445098767000026],
              [105.34078246300008, 21.448284464],
              [105.33834832000012, 21.455868492],
              [105.33501260700008, 21.467008509000074],
              [105.33250436500002, 21.47581578000004],
              [105.33079746100002, 21.484206648000011],
              [105.32939386500006, 21.487064508000039],
              [105.32789119500006, 21.488856437000067],
              [105.32397047400012, 21.492855080000069],
              [105.32235255500009, 21.494752032000086],
              [105.32130301500004, 21.49665582100004],
              [105.32082332600004, 21.498460023000021],
              [105.32079690000008, 21.500376162000023],
              [105.322322442, 21.505185698000062],
              [105.3229419080001, 21.507734554000102],
              [105.32466533200005, 21.507755328000044],
              [105.32581632600008, 21.507615504000022],
              [105.3275481000001, 21.50702156500008],
              [105.33027095600006, 21.505978424000105],
              [105.3324161780001, 21.505158862000059],
              [105.33718313800009, 21.504678172000077],
              [105.33751033800006, 21.504758956000011],
              [105.33767130100006, 21.504991438000062],
              [105.33750086000008, 21.505450510000031],
              [105.33616362800009, 21.50720204],
              [105.33548499900009, 21.508807776000083],
              [105.33546919000003, 21.509960354000057],
              [105.33586159500007, 21.511271543000099],
              [105.336585423, 21.512356142000044],
              [105.33723035800008, 21.513209253000106],
              [105.34191864700003, 21.518491303000076],
              [105.34297712000003, 21.519118788000036],
              [105.34461216600005, 21.519599462000102],
              [105.34559383200009, 21.51984176300008],
              [105.34666072099999, 21.519854526000067],
              [105.34830523300008, 21.519643636000026],
              [105.35085667600006, 21.519136168000053],
              [105.35299044700005, 21.519161638000028],
              [105.35667827, 21.519589861000021],
              [105.35896782300009, 21.520231924000122],
              [105.35989095800008, 21.520618174000013],
              [105.36106377600004, 21.521828043000113],
              [105.36188204300007, 21.523198648000097],
              [105.36244391400001, 21.523988861000106],
              [105.36301027800008, 21.524449213000096],
              [105.36370987700005, 21.524828672000048],
              [105.36577193000004, 21.52543050200002],
              [105.36691418700005, 21.525650246000083],
              [105.36766286800008, 21.525659130000108],
              [105.36885307000007, 21.525590774000044],
              [105.36960342700007, 21.525475963000048],
              [105.37053273800004, 21.525157069000045],
              [105.37274813200003, 21.524193602000082],
              [105.37398626600006, 21.523837117000078],
              [105.37595589600005, 21.523631938000065],
              [105.37878524100003, 21.523436676000074],
              [105.37983640400005, 21.523122376000089],
              [105.38081954800003, 21.522676566000065],
              [105.38447646500001, 21.520628665000082],
              [105.385040012, 21.520243234000077],
              [105.38567773100002, 21.519531948000022],
              [105.38603895500006, 21.51862136800009],
              [105.38620753200007, 21.51646694700009],
              [105.38679654300003, 21.514186783000078],
              [105.38737061200001, 21.513017307000034],
              [105.387865225, 21.512565713000065],
              [105.38849763800002, 21.512246427000022],
              [105.39402341800007, 21.511331213000062],
              [105.39451187700007, 21.51133695],
              [105.39520617400005, 21.511606488000012],
              [105.39700468600009, 21.51280383900005],
              [105.39914830900008, 21.514229041000107],
              [105.39996291600008, 21.514512256],
              [105.40089662300007, 21.514632667000022],
              [105.40815514400005, 21.513896586000044],
              [105.40944318200005, 21.513747436000052],
              [105.41020592400008, 21.51353741100008],
              [105.41173942400005, 21.51251537100007],
              [105.4156377250001, 21.509495734000069],
              [105.41617175500005, 21.508899885000062],
              [105.41647127000006, 21.508356038],
              [105.41665970000001, 21.50737302100012],
              [105.41734056400004, 21.505064185000045],
              [105.41784290400004, 21.503929426000035],
              [105.41946308300007, 21.501172793000123],
              [105.42131872900003, 21.49823581900003],
              [105.42165408900007, 21.498232668000036],
              [105.42185354200005, 21.498501133000048],
              [105.42209106600008, 21.498960141000012],
              [105.42244382400004, 21.499914756000038],
              [105.42295645600009, 21.501061327000045],
              [105.42363350700006, 21.502057717000064],
              [105.42491095500007, 21.503707426000048],
              [105.42659138700003, 21.50558991800002],
              [105.4277977510001, 21.506478371000078],
              [105.42848288700003, 21.506866512000045],
              [105.42944135000002, 21.507169005000023],
              [105.43207022700003, 21.50777364700005],
              [105.43347587500006, 21.508134427000059],
              [105.435120721, 21.508957308000021],
              [105.43816671900005, 21.510485391000067],
              [105.43896229600006, 21.510609417000026],
              [105.44771891000001, 21.511571615000058],
              [105.45182923500002, 21.511446612000015],
              [105.45311533900011, 21.51157622200008],
              [105.45354077300006, 21.5118682],
              [105.45420620500005, 21.512564857000079],
              [105.45455829800005, 21.513774678000082],
              [105.454665128, 21.514981682000034],
              [105.45427541400008, 21.516642331000035],
              [105.45420808500002, 21.517100903000049],
              [105.45444807800007, 21.517505578000048],
              [105.45468958700008, 21.517795441000054],
              [105.45596369500007, 21.518843572],
              [105.45669499000009, 21.519196462000068],
              [105.45718552500009, 21.519202086000085],
              [105.45798489600006, 21.519038994000091],
              [105.45872596, 21.51864556500005],
              [105.46027464300006, 21.517457530000073],
              [105.46107700600001, 21.517064784000084],
              [105.46181354600003, 21.517015798000024],
              [105.46557064700002, 21.518120977000073],
              [105.46801208300006, 21.5190101190001],
              [105.46880098100006, 21.519650716000079],
              [105.46946425000004, 21.520519544000116],
              [105.47065994100004, 21.522887287000088],
              [105.47138455200003, 21.523756812],
              [105.47181079200004, 21.523991334000044],
              [105.47254960100007, 21.523770087000038],
              [105.47563865400004, 21.522025323],
              [105.47946568900005, 21.520116645000087],
              [105.48322687400012, 21.518551662],
              [105.48451825100004, 21.51827921800011],
              [105.48536188700002, 21.518325775000058],
              [105.48675879600006, 21.519034476000073],
              [105.48851851799999, 21.520301592000024],
              [105.48917192800006, 21.521279010000072],
              [105.48974598100004, 21.522671260000067],
              [105.49045460200006, 21.52510433300003],
              [105.49145296700006, 21.52802567800007],
              [105.49225530300001, 21.528935476000029],
              [105.49313701700004, 21.529430440000041],
              [105.49350611000003, 21.52950390000008],
              [105.49412722600007, 21.52951931800002],
              [105.49537543300006, 21.52725111700002],
              [105.49566978100007, 21.525975295000066],
              [105.49650887100005, 21.526212037000022],
              [105.50101759100006, 21.528278334000014],
              [105.50273419300008, 21.529067473000026],
              [105.5041150370001, 21.53039168400008],
              [105.50841320300003, 21.535058811000063],
              [105.50982542800008, 21.536225733000066],
              [105.51102426100006, 21.536765942],
              [105.51198872900002, 21.536776759000098],
              [105.51303645900002, 21.536562754000066],
              [105.51433108400002, 21.535899998000026],
              [105.51594719000008, 21.535240824000113],
              [105.51755751500008, 21.535033095000067],
              [105.51835834500008, 21.535267806000036],
              [105.51955143100012, 21.5362594260001],
              [105.52041919300007, 21.537548398000084],
              [105.52096644100006, 21.53875853800011],
              [105.52227135500002, 21.543589178000033],
              [105.52297457700003, 21.545177293000073],
              [105.52328840700005, 21.545782796000076],
              [105.52368357600007, 21.54631396300006],
              [105.52447678700007, 21.547150560000091],
              [105.52542884300006, 21.548139436000021],
              [105.52589769100004, 21.54919816300005],
              [105.52705166100003, 21.552792469000082],
              [105.52793596400002, 21.557556556000087],
              [105.52850678400009, 21.559110796000077],
              [105.52955145100002, 21.560559736000059],
              [105.53164644300007, 21.563015421000031],
              [105.53514897500006, 21.566260638000045],
              [105.53712458600009, 21.568825478000072],
              [105.54012545600006, 21.57438676500005],
              [105.54019975600008, 21.575002261000087],
              [105.54357942200002, 21.574417568000023],
              [105.54747275800001, 21.574200547000096],
              [105.55025464000006, 21.573971184000037],
              [105.55234842600002, 21.573214019000098],
              [105.55487207900011, 21.571421213000086],
              [105.55705142000004, 21.568486952000043],
              [105.55961418800007, 21.563573414000103],
              [105.56224816400005, 21.559408097000073],
              [105.56408348100003, 21.557087397000082],
              [105.56591222600008, 21.555286786000046],
              [105.56861663300002, 21.552764592000081],
              [105.57350960200003, 21.547697097000103],
              [105.57900946200009, 21.542981364000099],
              [105.58084627700001, 21.541022002000076],
              [105.58162246800001, 21.539333835000015],
              [105.58209838200004, 21.53750099600007],
              [105.58317128200008, 21.536240204000109],
              [105.58499568700005, 21.53527037000007],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { gid: 63, code: "AD01", ten_tinh: "Yên Bái" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.60799944800004, 22.290967909000067],
              [104.60905616700008, 22.29089478800006],
              [104.61125047600009, 22.290900055000051],
              [104.62520727700007, 22.288842550000076],
              [104.62795684800003, 22.287570321000061],
              [104.63061641600008, 22.285701174000039],
              [104.63419415500007, 22.282725973000069],
              [104.63721990500005, 22.280772310000074],
              [104.65892919000002, 22.274426541000111],
              [104.66103459700005, 22.274345719000088],
              [104.66213154700004, 22.274944660000081],
              [104.66277082400009, 22.275542649],
              [104.66359073899999, 22.277163869000027],
              [104.66441269900005, 22.277932708000044],
              [104.66505303700009, 22.278104506000048],
              [104.66605989900009, 22.278106582000042],
              [104.66734197400004, 22.277853504000063],
              [104.66844238800005, 22.27700337300007],
              [104.67004847800007, 22.275174039000056],
              [104.67463865600007, 22.269301897000027],
              [104.67693331000004, 22.266493636000106],
              [104.68270712100011, 22.263095499000052],
              [104.68591254300003, 22.262164116000079],
              [104.68746844500006, 22.26216710900006],
              [104.68948101500006, 22.26259715900003],
              [104.69259131800001, 22.263284970000093],
              [104.69753068900005, 22.264658045000104],
              [104.70183254000008, 22.264580758000108],
              [104.7027483200001, 22.26432672200005],
              [104.70920525900002, 22.263146760000026],
              [104.71482983000007, 22.262024615000087],
              [104.71986058000003, 22.259492829000038],
              [104.72097054800004, 22.258602773000021],
              [104.72169444800006, 22.257195927000062],
              [104.72209827600008, 22.256583013000061],
              [104.72228401200009, 22.255219519000043],
              [104.728087031, 22.235880241000046],
              [104.72809656900007, 22.230936434000093],
              [104.7273744180001, 22.225820906000045],
              [104.72637106700003, 22.224199702000114],
              [104.72299130500008, 22.221295878000099],
              [104.7189737620001, 22.217112301000071],
              [104.71897817200004, 22.214896108000026],
              [104.72025959600009, 22.20880347400005],
              [104.72137953100012, 22.203478272000062],
              [104.72229925100001, 22.201007922000123],
              [104.72413258100001, 22.199135782000035],
              [104.72752154100002, 22.197010514000048],
              [104.7297177360001, 22.196673218000029],
              [104.73365288100008, 22.195912545000063],
              [104.73548488100005, 22.194636958000032],
              [104.73649353900002, 22.193359999000073],
              [104.73695411400011, 22.191655969000031],
              [104.73695884500006, 22.189098799000107],
              [104.73778482100002, 22.187651081000048],
              [104.73888441300005, 22.186629975000116],
              [104.74034816100001, 22.186547073],
              [104.74162848600007, 22.18671959100007],
              [104.74455367900008, 22.18783231300003],
              [104.74647465300004, 22.187835313000015],
              [104.74986000100009, 22.18741435400009],
              [104.75269737100002, 22.186481049000051],
              [104.75388818100005, 22.18554522900007],
              [104.7547142550001, 22.183926932000105],
              [104.75499147700003, 22.182307803000079],
              [104.75472016700006, 22.180517375000086],
              [104.75408537100003, 22.177362572000092],
              [104.75445582400003, 22.174720710000081],
              [104.75601325500003, 22.173273972000018],
              [104.75949244000003, 22.171233412000021],
              [104.7742252140001, 22.166907286000104],
              [104.77614618300005, 22.16673945700007],
              [104.77760864800008, 22.167338131000044],
              [104.77843060100005, 22.168106398000106],
              [104.7785202040001, 22.169299873000092],
              [104.77796818200008, 22.171344864],
              [104.77796684800006, 22.172197254000103],
              [104.77860617700003, 22.172794794000069],
              [104.77970309800001, 22.173222473000109],
              [104.78153190200007, 22.173565902000099],
              [104.78473283700006, 22.17382588100012],
              [104.78948935100007, 22.173661610000025],
              [104.79159307900005, 22.173664314000071],
              [104.79369743100008, 22.173240787000026],
              [104.7961688510001, 22.171965305000093],
              [104.79799913000002, 22.171285664],
              [104.79955354900002, 22.171628537000032],
              [104.81107498800003, 22.174029003000051],
              [104.81655534700006, 22.179831572000097],
              [104.8170109860001, 22.181153298000119],
              [104.81710075100007, 22.182474609000025],
              [104.81696213700006, 22.18356125100005],
              [104.81638904100006, 22.184626102000045],
              [104.81544963600005, 22.186010171000078],
              [104.81538058000002, 22.186351046000041],
              [104.81549453100004, 22.186649522000046],
              [104.81572296800002, 22.186841563000122],
              [104.81606600700007, 22.18684195700007],
              [104.81666070400007, 22.186757388000089],
              [104.81748433800001, 22.18648127900007],
              [104.81896021000009, 22.185832974000071],
              [104.82225511800004, 22.184387536000116],
              [104.82584826800007, 22.18211125800007],
              [104.82667263600007, 22.181195813000087],
              [104.82752048500005, 22.179768954000068],
              [104.82788704100008, 22.179215287000083],
              [104.82823045400008, 22.178874694000058],
              [104.8286878910001, 22.178811249000049],
              [104.82925941800005, 22.178939702000022],
              [104.83561426000004, 22.180949350000034],
              [104.83716927500009, 22.180950917000075],
              [104.83815232200007, 22.1811863080001],
              [104.83904362600002, 22.181677315000044],
              [104.83982028200006, 22.182423929],
              [104.84130521600009, 22.183746586000019],
              [104.84391044199999, 22.185347342000036],
              [104.84515614300004, 22.185913246000055],
              [104.84591055300011, 22.186148375000059],
              [104.84673525900004, 22.186168145000096],
              [104.84700712900008, 22.18619764500005],
              [104.84696181300005, 22.184018287000029],
              [104.84726062800009, 22.182612096000028],
              [104.84785674900012, 22.181184859000069],
              [104.849303487, 22.175538991000018],
              [104.852840402, 22.160795505000053],
              [104.85284199600004, 22.159261167000111],
              [104.85320899100003, 22.158153357000018],
              [104.85449103900005, 22.156577551000019],
              [104.85604733300003, 22.155087205000079],
              [104.85682570600005, 22.154107622000119],
              [104.8573297770001, 22.153042543000097],
              [104.86039912700004, 22.147376648000083],
              [104.86127059100002, 22.144649666000049],
              [104.86177513700002, 22.143030507000013],
              [104.86150193100006, 22.141879513000085],
              [104.85963054300004, 22.138553501000047],
              [104.85885438900003, 22.137402074000079],
              [104.85848942100003, 22.136591970000019],
              [104.85831443300003, 22.128706953000062],
              [104.85840850900009, 22.126064542],
              [104.85969210600007, 22.122570759000034],
              [104.86262556600005, 22.114816248000047],
              [104.86354290200009, 22.111663077000095],
              [104.86381980600001, 22.108935570000106],
              [104.86364023100006, 22.105525741000065],
              [104.86291361900007, 22.100581118000036],
              [104.86273316800006, 22.098108951000128],
              [104.87207336000007, 22.081068115000058],
              [104.87353766000008, 22.079108686000026],
              [104.87765444600009, 22.075105440000087],
              [104.880137414, 22.07342945300006],
              [104.88131272200009, 22.072636112000055],
              [104.88423818100003, 22.071956274000023],
              [104.88761928800008, 22.072981538000036],
              [104.889721174, 22.073494418000017],
              [104.892646364, 22.073155395000029],
              [104.89529840600005, 22.071367034000033],
              [104.89813396600002, 22.068470582000096],
              [104.90051241900008, 22.065573803000049],
              [104.91614492000006, 22.060041815000098],
              [104.91705893300011, 22.059957039000011],
              [104.92007469100005, 22.060469990000087],
              [104.92391267600009, 22.061665209000033],
              [104.92601461500001, 22.062007148000085],
              [104.92866538100004, 22.061496872000049],
              [104.93415051300011, 22.058600929000072],
              [104.93725913600011, 22.055874394000071],
              [104.93899644200005, 22.053914470000088],
              [104.93954555100001, 22.052124591000052],
              [104.93918193600004, 22.047606583000068],
              [104.9390001510001, 22.045304973000057],
              [104.939823377, 22.043515180000028],
              [104.94201821200005, 22.039594810000018],
              [104.94421235800006, 22.03712354],
              [104.94686301500008, 22.035504808],
              [104.96029717900007, 22.030735024000059],
              [104.96148513400004, 22.030564822000095],
              [104.963495288, 22.031076738000088],
              [104.96559663600006, 22.032355833000047],
              [104.96687525300007, 22.035254345000062],
              [104.96861064700003, 22.039005374000098],
              [104.96970679800005, 22.04096615800006],
              [104.97189967800003, 22.042586160000077],
              [104.97893610400003, 22.043951090000036],
              [104.98313972800003, 22.044633526000013],
              [104.98652090700001, 22.045741994000068],
              [104.98880555500004, 22.045912647000065],
              [104.9927351850001, 22.0457423860001],
              [104.99474568600002, 22.045401494000046],
              [104.99556817900003, 22.044890052000099],
              [104.99593376500007, 22.043526185000047],
              [104.99520276000008, 22.041224612000043],
              [104.99300990500009, 22.034064146000084],
              [104.99273590300008, 22.031677341000133],
              [104.990909012, 22.021277618000035],
              [104.990909075, 22.020425191000115],
              [104.99109185700003, 22.019487521000066],
              [104.99248532300004, 22.018187658000073],
              [104.99362747900004, 22.017420528000017],
              [104.99417569800002, 22.016866464000096],
              [104.99440413700009, 22.016440261000092],
              [104.994404151, 22.016014039000083],
              [104.99431281500009, 22.015417336],
              [104.99330785800007, 22.013797691000022],
              [104.99148068000005, 22.011453416000037],
              [104.98919693100001, 22.006807539000036],
              [104.988066567, 22.004026404000051],
              [104.987975283, 22.003152659000058],
              [104.98799816800003, 22.002662510000093],
              [104.98827229100006, 22.002001901000057],
              [104.98870630400009, 22.001277361000056],
              [104.9893001770001, 22.00063808],
              [104.99005617900005, 21.999968492000029],
              [104.990969116, 21.997755823000098],
              [104.99068482700001, 21.997205485000038],
              [104.99006896400005, 21.996455488000066],
              [104.98933431200001, 21.995274484000049],
              [104.98925672800007, 21.994883072000079],
              [104.98947010100005, 21.994612715000095],
              [104.99239970200006, 21.994535209000077],
              [104.99430972800009, 21.994112101000063],
              [104.99609727700002, 21.992145415000053],
              [104.99771627700004, 21.990254494000091],
              [104.99789568500006, 21.989476199000087],
              [104.99787262000009, 21.988265771000037],
              [104.99712883900003, 21.986323445],
              [104.99652570100002, 21.984753846000068],
              [104.99325438900007, 21.978561413000094],
              [104.99194762100005, 21.976513833000055],
              [104.99061665000005, 21.97333383500002],
              [104.99054149900006, 21.972786299000099],
              [104.99063363600004, 21.972241015000101],
              [104.99097789700002, 21.971621078000055],
              [104.99185977900007, 21.970051980000079],
              [104.99298771200004, 21.967451697000051],
              [104.99353318700005, 21.964648349000093],
              [104.9933416640001, 21.962166847000027],
              [104.9927125830001, 21.959581825000015],
              [104.9913974900001, 21.954040096000057],
              [104.99116478500011, 21.952865784000061],
              [104.98888976600009, 21.95123441],
              [104.98786317900004, 21.950010307000056],
              [104.98742446200008, 21.948637999000105],
              [104.98699125300003, 21.945567554000078],
              [104.9856750440001, 21.944144309000087],
              [104.98500309300002, 21.941636667000083],
              [104.98502314600007, 21.940348622],
              [104.98517470900001, 21.9386719600001],
              [104.98532020300006, 21.937385618000071],
              [104.98540930000006, 21.937035465000051],
              [104.9858371440001, 21.936416617000098],
              [104.98651890700003, 21.93560600799999],
              [104.98712553700004, 21.93424781600006],
              [104.98728614900008, 21.933332543000091],
              [104.98764006600007, 21.932088046000061],
              [104.99528859700008, 21.929692877000036],
              [104.99671018500001, 21.929712072000115],
              [104.99852690600005, 21.931220131000103],
              [105.00000008700009, 21.933570629000073],
              [105.00155746100008, 21.935067128000085],
              [105.00206038600003, 21.93542996399999],
              [105.00323757700008, 21.936039257000047],
              [105.00736542600004, 21.937677292000046],
              [105.00837988700003, 21.937849187000054],
              [105.00914256200008, 21.937859441000015],
              [105.01632456400004, 21.936571199000099],
              [105.01670772700004, 21.93645765400003],
              [105.01713448200009, 21.936265562000031],
              [105.01743472200006, 21.936032212],
              [105.01756731900011, 21.935677931000086],
              [105.0176188300001, 21.93508519700007],
              [105.01762988400004, 21.93467802900004],
              [105.01733473000004, 21.933426547000053],
              [105.01682187400007, 21.930997991000112],
              [105.016531242, 21.929453019000086],
              [105.01669859500008, 21.928794801000073],
              [105.01734540000004, 21.927629313000097],
              [105.01822231000003, 21.926833836000093],
              [105.01980651000011, 21.926047821000068],
              [105.02028143500004, 21.925834027000057],
              [105.02052510500005, 21.925323600000098],
              [105.02053526300008, 21.924663269000085],
              [105.01954002400008, 21.917825058000062],
              [105.01908770100005, 21.916571432000076],
              [105.01740741200011, 21.913393279000012],
              [105.01578992600012, 21.911243364000121],
              [105.01300167300008, 21.908563993000115],
              [105.01254375700003, 21.907677192000016],
              [105.01169957000008, 21.901347714000075],
              [105.01170897700007, 21.900737890000066],
              [105.0119381640001, 21.899995462],
              [105.01275205400003, 21.898989800000024],
              [105.01665330300006, 21.895450187000073],
              [105.01716646600006, 21.895118200000049],
              [105.01774801200004, 21.89505822400006],
              [105.01897951000007, 21.895210280000029],
              [105.02006691700007, 21.895292625000096],
              [105.0208671980001, 21.89516779700007],
              [105.02174629700002, 21.894637377000059],
              [105.02314478700001, 21.893368408000043],
              [105.02446028400004, 21.892776042000058],
              [105.02591153500003, 21.892795452000065],
              [105.02859011000007, 21.893237897000084],
              [105.03069444, 21.893265999],
              [105.03339329800011, 21.892984487000078],
              [105.03491876600003, 21.892097230000026],
              [105.0386606210001, 21.887508231000119],
              [105.03976648400003, 21.885808582],
              [105.03999782300008, 21.884803204000036],
              [105.04014037000006, 21.879006438000111],
              [105.04044100500006, 21.873463833000081],
              [105.04125060300008, 21.869944918000073],
              [105.042599959, 21.86643315600012],
              [105.04337256500006, 21.865334093000044],
              [105.04752554500004, 21.862061222000051],
              [105.05068841100008, 21.859985344000066],
              [105.05220433700001, 21.859702890000051],
              [105.05577133600009, 21.85944759100007],
              [105.05642364800006, 21.859153682000084],
              [105.056972595, 21.858555838000093],
              [105.05767408500006, 21.857029723000032],
              [105.05934309300004, 21.852088764000044],
              [105.06062401700007, 21.849091713000043],
              [105.06278265400005, 21.846351612000078],
              [105.0669331350001, 21.840923964000055],
              [105.06937, 21.837737034000071],
              [105.07539333000003, 21.831389014000102],
              [105.07678097100009, 21.830616201000019],
              [105.07901072000001, 21.830151090000079],
              [105.08261319300001, 21.829901746000054],
              [105.08532928200003, 21.829679401000043],
              [105.08604377400009, 21.82944722200002],
              [105.086828333, 21.828853660000021],
              [105.08794506400002, 21.827660581000075],
              [105.08893708000004, 21.826163929000053],
              [105.08980890100005, 21.824061846000028],
              [105.09047868400005, 21.822500580000082],
              [105.09126682500006, 21.821665494000086],
              [105.09257283100003, 21.820776780000095],
              [105.09517846500006, 21.81942196199999],
              [105.09609490900004, 21.818648909000068],
              [105.09695305499999, 21.817452385000102],
              [105.09768918400005, 21.815771171000087],
              [105.09803306900008, 21.814386768000112],
              [105.09804391600009, 21.81366226400003],
              [105.09772624700001, 21.813295798000084],
              [105.09689073000007, 21.812982948000048],
              [105.09393005900006, 21.812159236000106],
              [105.09023673800002, 21.810631433000083],
              [105.08780487000006, 21.809029515000056],
              [105.08646425000006, 21.807924971000055],
              [105.08532475600002, 21.806339953000098],
              [105.08413156300006, 21.804029558000067],
              [105.08275909300001, 21.800750581000017],
              [105.08239595300007, 21.799115323000073],
              [105.08209924000003, 21.797360151000063],
              [105.08160687700007, 21.795723184000067],
              [105.07879757600006, 21.789164217000057],
              [105.07804318400008, 21.787765327000038],
              [105.07759546600008, 21.787457495000062],
              [105.07662906300004, 21.787263613],
              [105.07553343200007, 21.787068026000078],
              [105.07485912700007, 21.78678739700004],
              [105.07129117600007, 21.78341894000004],
              [105.07072703400003, 21.782264083000058],
              [105.07054871900002, 21.781235091],
              [105.07069709200005, 21.779968835000126],
              [105.07103654600006, 21.778886271000083],
              [105.07169264400008, 21.778230613000105],
              [105.07607135400009, 21.774966741000057],
              [105.07816147700001, 21.773484443000065],
              [105.07856002400004, 21.772764980000048],
              [105.07870106000007, 21.771981745000076],
              [105.07858672600003, 21.771256232],
              [105.07815298700011, 21.770308381000092],
              [105.07727920300002, 21.76883132800009],
              [105.07497098300009, 21.765922166000081],
              [105.07265651299998, 21.763431606000069],
              [105.07227089900005, 21.763007792000089],
              [105.07205642500007, 21.762376861000021],
              [105.07221056600012, 21.761587744000011],
              [105.07255771200008, 21.760544292000091],
              [105.07275558500001, 21.759968206000032],
              [105.07281396400005, 21.759518874000072],
              [105.07276747300001, 21.759180685000068],
              [105.07265099900006, 21.758921949000062],
              [105.07236451400007, 21.758532372000069],
              [105.07216108500003, 21.758336791000055],
              [105.07181623600009, 21.758182098000042],
              [105.07152781400004, 21.75813444],
              [105.07111559700003, 21.758056299000117],
              [105.07072707600004, 21.757835258000036],
              [105.07051896900005, 21.757613559000077],
              [105.06929339300007, 21.756243535000053],
              [105.06851316700009, 21.754764429000062],
              [105.06823546500003, 21.754093147000027],
              [105.06784832400005, 21.753211504000092],
              [105.06744458300005, 21.752599547000081],
              [105.06698612000004, 21.752254561000051],
              [105.06545141200007, 21.751622309000048],
              [105.06068373300012, 21.750145393000061],
              [105.06040560800002, 21.749697864000069],
              [105.05977088700008, 21.749390655000056],
              [105.05913164800003, 21.749382196000063],
              [105.05696320100003, 21.75013905900007],
              [105.05640352600008, 21.750093117000091],
              [105.05608616700003, 21.749939498000082],
              [105.05535724000002, 21.749170994000089],
              [105.05485287800003, 21.748218449000056],
              [105.05476273400004, 21.747235046000043],
              [105.05481924400002, 21.746478326000052],
              [105.05517058100003, 21.746391844000087],
              [105.06095829900001, 21.74453154800009],
              [105.06286130200007, 21.743744085000039],
              [105.06493609700006, 21.742823882000017],
              [105.06682613800004, 21.741683306000056],
              [105.06961551100008, 21.739504631],
              [105.07212418200008, 21.737680148000038],
              [105.07367859599999, 21.736167891000058],
              [105.07486616600002, 21.734510212000124],
              [105.08169857700001, 21.724875304000065],
              [105.08311414500008, 21.72287893700009],
              [105.085092671, 21.719549306000104],
              [105.0871055690001, 21.716161675000123],
              [105.08852566200001, 21.713596862000095],
              [105.08871228800008, 21.713135274000095],
              [105.08875231900002, 21.711939229000073],
              [105.08796028600004, 21.710207983000096],
              [105.08611277400003, 21.708508356000067],
              [105.08495505300007, 21.706645427000026],
              [105.08458396400002, 21.705039186000086],
              [105.0845969210001, 21.704177069000075],
              [105.08554114800008, 21.70271126400003],
              [105.08739811400007, 21.701873330000062],
              [105.09214542400011, 21.701565978000033],
              [105.09347365600004, 21.700844262000096],
              [105.0941483460001, 21.699688971000072],
              [105.09605785200009, 21.694965188000111],
              [105.097335345, 21.688534630000063],
              [105.09742609300005, 21.686936522000089],
              [105.09640035100009, 21.683220907000056],
              [105.09390573100009, 21.683077082000054],
              [105.09033015700001, 21.683364256000104],
              [105.08872214400002, 21.683426674000117],
              [105.08738317900007, 21.683409119000082],
              [105.08310351100009, 21.683018973000046],
              [105.079711473, 21.682974398000084],
              [105.07658219100009, 21.683267210000068],
              [105.06878467700008, 21.685251829000052],
              [105.06600735500005, 21.685883124000057],
              [105.06405247500004, 21.687265316000023],
              [105.06005619300007, 21.690631966000048],
              [105.05928107000005, 21.690981654000026],
              [105.05899244500006, 21.690977832000065],
              [105.05861033400006, 21.690792802000125],
              [105.05823096100004, 21.690427828000033],
              [105.05796280300005, 21.689074468000058],
              [105.05740329900003, 21.687897226000082],
              [105.05645214600003, 21.687164718000048],
              [105.05510935100003, 21.686876960000042],
              [105.05279629300006, 21.687116236000037],
              [105.04921612000004, 21.688418514000048],
              [105.0444690730001, 21.690515084000062],
              [105.04242812500007, 21.691837722000074],
              [105.03676418300005, 21.697203895000072],
              [105.03455960600007, 21.69929243700004],
              [105.03141680000009, 21.701724234000075],
              [105.03049515300003, 21.701819472000047],
              [105.03003516300008, 21.701813322000071],
              [105.02901168800004, 21.701046747000063],
              [105.02629951200008, 21.697891339000108],
              [105.02424934200009, 21.696573210000025],
              [105.02287599000005, 21.696124579000028],
              [105.02126603300007, 21.696102994000022],
              [105.01953447900007, 21.696509994000031],
              [105.01861947100012, 21.696175046000029],
              [105.01794601700009, 21.695090439000083],
              [105.01773586600011, 21.693796939],
              [105.0177722330001, 21.691431171000097],
              [105.01745370600003, 21.689705974000084],
              [105.0170003480001, 21.68926966100004],
              [105.01631369600003, 21.689045317000044],
              [105.01435552400001, 21.689234124000031],
              [105.00979709200006, 21.689523739000016],
              [105.00249636200004, 21.689623589000107],
              [105.00000008100008, 21.689359932000052],
              [104.9975837640001, 21.688647804000105],
              [104.99705472800011, 21.687525841000088],
              [104.99731741800008, 21.686600380000073],
              [104.99918761900001, 21.685313429000082],
              [104.99950066900007, 21.684342187000105],
              [104.99917602600003, 21.682851377000034],
              [104.99861278800006, 21.68073024300012],
              [104.99812192400003, 21.680352003000088],
              [104.99646262000005, 21.680050864000044],
              [104.99438624300002, 21.68022018900011],
              [104.98977362200003, 21.678183539000081],
              [104.98650584000009, 21.676710839000087],
              [104.98178468, 21.675276406000066],
              [104.97845041600007, 21.674092994000112],
              [104.97812516100008, 21.673449861000016],
              [104.98390484400001, 21.669092276000065],
              [104.9839488370001, 21.667060585000094],
              [104.98410734800007, 21.663253630000057],
              [104.98375514100006, 21.661948172000045],
              [104.97932361300012, 21.661075015],
              [104.97671996400007, 21.660830539000031],
              [104.97432677900004, 21.659140791000105],
              [104.97336911900003, 21.658571417000068],
              [104.97248254200012, 21.658211629000057],
              [104.97180009300006, 21.657836972000055],
              [104.97010043000003, 21.656954403000036],
              [104.96699300400005, 21.654786738],
              [104.96578919600009, 21.653957361000039],
              [104.96499946600002, 21.653644616000101],
              [104.96388553100006, 21.653420350000104],
              [104.96321593900008, 21.653364736000036],
              [104.96177017300009, 21.653716584000016],
              [104.96041570200002, 21.654580671000097],
              [104.95888642100005, 21.657091444000095],
              [104.95527237600007, 21.661106562000043],
              [104.9520320120001, 21.66187503900003],
              [104.9511323660001, 21.660654914000062],
              [104.95028184400007, 21.659872356000065],
              [104.94870870400003, 21.658837150000032],
              [104.94759436900002, 21.658201225000084],
              [104.94538407600001, 21.656919098000046],
              [104.94385544500001, 21.65597924500009],
              [104.94067283500003, 21.653933519000113],
              [104.93712387500001, 21.652796973000044],
              [104.93610036400005, 21.652469178000011],
              [104.93629458500006, 21.651949877000028],
              [104.93632443500005, 21.650059117000083],
              [104.93568049300005, 21.648159044000082],
              [104.93407543200003, 21.646155636000053],
              [104.9316643490001, 21.643375592000069],
              [104.92975210100008, 21.642538634000097],
              [104.92561892600004, 21.642121213000017],
              [104.9191842860001, 21.641131447000106],
              [104.91574639200005, 21.63937262000006],
              [104.91221090300007, 21.637080599000043],
              [104.90647797900002, 21.633003223000102],
              [104.90665691400008, 21.632717071000052],
              [104.90336359900009, 21.628900780000038],
              [104.90180227900004, 21.62709144600008],
              [104.9016366430001, 21.623838474000046],
              [104.90276592400002, 21.620464071000086],
              [104.90377659200007, 21.613137974000018],
              [104.90385068100007, 21.610049594000103],
              [104.89898205400002, 21.610213924000021],
              [104.892184802, 21.609886573000026],
              [104.88904470600008, 21.610632373],
              [104.88672479800006, 21.611319936000051],
              [104.89053800300009, 21.605752011000057],
              [104.89106639700002, 21.603761713000047],
              [104.89080883000003, 21.599693013000021],
              [104.89148483200009, 21.594684954000073],
              [104.89310025800009, 21.588365904000014],
              [104.89399683800011, 21.585079832000083],
              [104.89565796400005, 21.580550010000096],
              [104.89612478600004, 21.577745698000058],
              [104.89615003400009, 21.57616639400004],
              [104.89569677600007, 21.573465348000106],
              [104.89540362900006, 21.568513165000077],
              [104.89590228800002, 21.565267856000055],
              [104.89478158900002, 21.563951259000099],
              [104.88888549300005, 21.560151748000052],
              [104.88332792800003, 21.558470823000064],
              [104.87798071100008, 21.557605803000079],
              [104.87441493800002, 21.557090988000112],
              [104.87158611800008, 21.557051125000015],
              [104.87075141000003, 21.556481805000068],
              [104.86953167100009, 21.555163660000076],
              [104.86719749800005, 21.552157120000089],
              [104.86642894400006, 21.550566543000024],
              [104.8662683690001, 21.549751173000061],
              [104.86661694000007, 21.543530057000027],
              [104.86735935700003, 21.542123405000076],
              [104.87163828700005, 21.539378447000068],
              [104.87387935300002, 21.537940717000126],
              [104.87735326300003, 21.534740066000069],
              [104.87942471800001, 21.531072416000093],
              [104.88132710700003, 21.526963832000057],
              [104.88266795000004, 21.52382307200007],
              [104.88265333200007, 21.522450721000027],
              [104.88264815000009, 21.521964193000095],
              [104.88115974900003, 21.520386693000049],
              [104.88046637400005, 21.509480751000076],
              [104.88371905100006, 21.500070543000049],
              [104.88502718600003, 21.495860419000046],
              [104.88569077800004, 21.494708051000089],
              [104.88927809900011, 21.489159056000062],
              [104.89181886000001, 21.485430773000047],
              [104.89215590600001, 21.482972680000053],
              [104.8921300750001, 21.481485334000091],
              [104.89205250300007, 21.478579963000044],
              [104.894237054, 21.472290801000057],
              [104.89595195500004, 21.468992253000074],
              [104.90247334600005, 21.459859179000063],
              [104.90926355500002, 21.449381868000025],
              [104.91073573300005, 21.441037541000085],
              [104.91071862800007, 21.438992542000079],
              [104.9105311970001, 21.435179226000045],
              [104.90989007399999, 21.433381107000038],
              [104.90434556500007, 21.42953948400006],
              [104.90459130900007, 21.4265686520001],
              [104.90561871600008, 21.424352289000034],
              [104.90604801800006, 21.422313458000097],
              [104.90649801700008, 21.418973651000073],
              [104.90654090599999, 21.41627878000012],
              [104.90587619500006, 21.414410546000084],
              [104.90461047400005, 21.412905710000096],
              [104.90098069100002, 21.410345383000084],
              [104.89980829400008, 21.409213605000055],
              [104.89876325500003, 21.406317572000063],
              [104.89614190200004, 21.399634972000037],
              [104.89308178800006, 21.394038283000057],
              [104.88887353600003, 21.394339439000106],
              [104.88579692000012, 21.394435663000074],
              [104.88402482200004, 21.393597437],
              [104.88157455400008, 21.391750412000121],
              [104.88098706400005, 21.390498918000041],
              [104.88038886700012, 21.389142691000096],
              [104.87996487600003, 21.385430242000055],
              [104.88013525200009, 21.384084820000027],
              [104.88313402100006, 21.380327547],
              [104.88227642600006, 21.375818837000082],
              [104.88197947900001, 21.374234435000083],
              [104.88238916500006, 21.373426841000054],
              [104.88414954600006, 21.371116106000088],
              [104.89241809100004, 21.364969323000068],
              [104.89607462600007, 21.358804100000036],
              [104.89806487000007, 21.355276344000096],
              [104.89874789100001, 21.35364752400006],
              [104.89895842600002, 21.352883566000088],
              [104.89861291100003, 21.351228725000034],
              [104.897007703, 21.348498829000029],
              [104.89597819600009, 21.34682951500011],
              [104.89538848200004, 21.345873298000036],
              [104.89458273900009, 21.345095092000044],
              [104.88836753700009, 21.343834280000081],
              [104.88443793600003, 21.343058616000029],
              [104.88173541400006, 21.343067083000022],
              [104.88023888700005, 21.343627008000077],
              [104.87902496900007, 21.344341973000077],
              [104.8734680850001, 21.347633406000099],
              [104.87169757700006, 21.349037664000022],
              [104.86770141300009, 21.353931252000088],
              [104.86724572100005, 21.355284322000045],
              [104.86630728300003, 21.358129498],
              [104.864608816, 21.361207916000069],
              [104.8630502020001, 21.364067520000063],
              [104.86128230600011, 21.366064290000075],
              [104.86007380700003, 21.366430610000094],
              [104.85272551600008, 21.36544328800003],
              [104.85261383300008, 21.362385752000087],
              [104.85267609100008, 21.357773633000043],
              [104.85274302700002, 21.353637959000068],
              [104.85246604700011, 21.353134372000056],
              [104.85081201900003, 21.352692557000097],
              [104.84686091600005, 21.353240597000053],
              [104.84443130300011, 21.353229248],
              [104.84347512000004, 21.352564925000102],
              [104.84311800900007, 21.3516534870001],
              [104.84333366300007, 21.350587539000095],
              [104.84401373200011, 21.349167979000057],
              [104.84445321300005, 21.34805872900003],
              [104.84626573200009, 21.344075652000072],
              [104.84791084800005, 21.340473610000018],
              [104.84784553300007, 21.339914922000069],
              [104.84622318800004, 21.339055188000074],
              [104.84505017000006, 21.338771226000027],
              [104.84067809400007, 21.337779317000027],
              [104.83498305600004, 21.338104742000041],
              [104.82741727600003, 21.339123685000061],
              [104.82316743100003, 21.339759995000037],
              [104.81916078000008, 21.339167987000081],
              [104.81645942200004, 21.338350642000044],
              [104.8116661830001, 21.334421546000023],
              [104.80920285200003, 21.332355230000033],
              [104.80485159500007, 21.331294799000133],
              [104.80226730300004, 21.331257514000065],
              [104.8003669810001, 21.331716101],
              [104.79825253700002, 21.332905680000103],
              [104.79356250100001, 21.336184477000053],
              [104.79079038200007, 21.335156619000095],
              [104.78814153200001, 21.333421679000075],
              [104.78459219900006, 21.330674295000037],
              [104.78251342600001, 21.329702869000045],
              [104.77840434200002, 21.327830372],
              [104.77646482800003, 21.326686603000056],
              [104.77568469100007, 21.326640393000048],
              [104.76764403700011, 21.330880862000079],
              [104.76685558600006, 21.335807976000076],
              [104.76678318800002, 21.340908169],
              [104.76715016000001, 21.345677755000025],
              [104.76677687400003, 21.347252625000031],
              [104.76453597600008, 21.347824153000069],
              [104.76070981400004, 21.346815421000095],
              [104.758743881, 21.347251467000028],
              [104.756555044, 21.348416296000039],
              [104.75531294000008, 21.350048142000055],
              [104.75531018300003, 21.353185481000111],
              [104.75436513400008, 21.353357557000052],
              [104.74935415000007, 21.352029159],
              [104.74815135500003, 21.352046367000099],
              [104.74739178500006, 21.352988049000118],
              [104.74652106500005, 21.356867911000066],
              [104.74541243000007, 21.361627355000046],
              [104.74536392800006, 21.363044250000129],
              [104.74710530500008, 21.364185307000035],
              [104.74747783300008, 21.365631612000065],
              [104.7473042230001, 21.36711637100008],
              [104.74622, 21.368192698000051],
              [104.74368715100005, 21.368411125000094],
              [104.74287233200005, 21.368945267000058],
              [104.74145606200001, 21.370597666],
              [104.73941233800011, 21.373437618000054],
              [104.73809929800009, 21.376323158000062],
              [104.73646577700003, 21.378355743000022],
              [104.73392948600005, 21.380247189000023],
              [104.73285750000008, 21.382799257000059],
              [104.73162793900005, 21.38734748800006],
              [104.73150150400006, 21.392655571000049],
              [104.73217383000009, 21.396918057000036],
              [104.73290972900006, 21.399612884000071],
              [104.73382828300004, 21.402507906000068],
              [104.73356337200005, 21.404990437000038],
              [104.73233965100005, 21.406970834000028],
              [104.73166472500007, 21.407286211],
              [104.72630593900007, 21.403709866000042],
              [104.72360909700004, 21.401857475000071],
              [104.71648491200006, 21.401508057000029],
              [104.70869131700003, 21.401148470000031],
              [104.70718045500008, 21.402497063000055],
              [104.70478325900007, 21.405656627],
              [104.70273591300004, 21.406462740000087],
              [104.70137299700008, 21.406395983000067],
              [104.69909747, 21.404572805000029],
              [104.69742411500002, 21.403072283000043],
              [104.69537339200006, 21.401159453000048],
              [104.69381491899999, 21.400927067000097],
              [104.68961406400004, 21.401491788000016],
              [104.68814535300002, 21.401086427000045],
              [104.68692187000005, 21.396478643000094],
              [104.6865961460001, 21.395218912000047],
              [104.68647407200004, 21.392196123000048],
              [104.68665443300003, 21.391071763000099],
              [104.6873679990001, 21.386306932000117],
              [104.68656387400009, 21.384017538000052],
              [104.68530013500006, 21.381779349000048],
              [104.68370868500003, 21.37912955800001],
              [104.68167639100008, 21.377611855000019],
              [104.67810978100005, 21.376640483000031],
              [104.67349988100008, 21.376501618000084],
              [104.67022298300003, 21.377440066000105],
              [104.66887591900004, 21.377907842000084],
              [104.6655264390001, 21.379460956000074],
              [104.6619991420001, 21.380534939000114],
              [104.66025537300007, 21.380996685000042],
              [104.657866224, 21.381495159000075],
              [104.65362549100004, 21.383673703000028],
              [104.65066846000008, 21.385464885000083],
              [104.65009864400008, 21.38543303700002],
              [104.64835404100006, 21.384500350000032],
              [104.64633972500012, 21.38410968200013],
              [104.64452912600005, 21.384128758000109],
              [104.63820836000012, 21.387553622000063],
              [104.6368835220001, 21.388160960000043],
              [104.63314080000005, 21.390915994000089],
              [104.63163927900007, 21.392415296000067],
              [104.62974639900003, 21.394315307000063],
              [104.62711572000006, 21.395855488000045],
              [104.62433082000004, 21.396277861],
              [104.62302130800003, 21.396711056000051],
              [104.62045604200006, 21.398763398000042],
              [104.61906984000004, 21.400032053000118],
              [104.61815227800005, 21.400655265000076],
              [104.61658891300002, 21.401481013000101],
              [104.61450021800005, 21.402541643000106],
              [104.61292645800003, 21.403191503000052],
              [104.6110938580001, 21.405173568000087],
              [104.6088503500001, 21.408671409000043],
              [104.60829558100004, 21.409906134000067],
              [104.60578943800006, 21.414945184000089],
              [104.60359568800001, 21.417002949000086],
              [104.59848327100005, 21.41930639300007],
              [104.59456952500005, 21.420431400000069],
              [104.59032356600001, 21.422817666000071],
              [104.58728004200007, 21.423816505000026],
              [104.58402484300004, 21.42274393400001],
              [104.58201849600003, 21.421179351000049],
              [104.58135477700009, 21.420135058000064],
              [104.58019644500008, 21.418304671000051],
              [104.57986574299998, 21.417484724000069],
              [104.57960230500004, 21.416831548000026],
              [104.57933058200003, 21.414654653000028],
              [104.57892529100008, 21.410907139000109],
              [104.57802996900006, 21.409638488000034],
              [104.57715527000008, 21.40931127100005],
              [104.5730645730001, 21.412036588000127],
              [104.56909191500003, 21.412985976000073],
              [104.56713432100005, 21.412885964000061],
              [104.56175539700007, 21.410606634000118],
              [104.55747204500004, 21.410191589000057],
              [104.55391213300001, 21.410240843000047],
              [104.55056070500009, 21.410421096000043],
              [104.54864531400007, 21.410739848000034],
              [104.5444711690001, 21.412996597000067],
              [104.53958747300007, 21.414514179000044],
              [104.53656469700007, 21.415036297000078],
              [104.53038980600003, 21.414986214000109],
              [104.52814088500003, 21.414532725000065],
              [104.52639355400005, 21.413076232],
              [104.52307501600009, 21.410026532000082],
              [104.52087121300011, 21.407749500000058],
              [104.51945919700006, 21.405464179000063],
              [104.51818107700009, 21.402164981000027],
              [104.51649630500005, 21.399303447000051],
              [104.51296637900002, 21.396308292000029],
              [104.51262189700003, 21.391318210000065],
              [104.51249731700003, 21.389968406000023],
              [104.5115289780001, 21.388651800000069],
              [104.50964376899999, 21.387297514000103],
              [104.50878013400003, 21.386365975000032],
              [104.50702811300002, 21.384502502000124],
              [104.50479441500003, 21.381155727000056],
              [104.50305876700004, 21.378386131000049],
              [104.50075221800007, 21.376339535000085],
              [104.4986142760001, 21.375260001000029],
              [104.49683859300009, 21.374720674000081],
              [104.49478141700004, 21.374664908000085],
              [104.49297254000004, 21.373904246000031],
              [104.48534932700008, 21.368310571000038],
              [104.482808639, 21.366201931000091],
              [104.48205280399999, 21.363436021000034],
              [104.48131379500003, 21.360426341000057],
              [104.47987274700009, 21.358532633000046],
              [104.47744233700008, 21.355821401000071],
              [104.47439480400008, 21.35364645300006],
              [104.46963064500007, 21.352478303000041],
              [104.46603691100009, 21.352397777000043],
              [104.46395893100001, 21.352817809000065],
              [104.45618218400004, 21.354378492000059],
              [104.452474006, 21.355132568],
              [104.44803324500002, 21.357304105000097],
              [104.44497371300008, 21.357836045000056],
              [104.44030607500007, 21.357493850000075],
              [104.43315935300009, 21.355101390000094],
              [104.42967776200007, 21.354987215000065],
              [104.426714383, 21.355683129000091],
              [104.41905959100009, 21.360695632000045],
              [104.41812188000004, 21.361795999000051],
              [104.41794147900004, 21.363512841000087],
              [104.41845621200008, 21.365856769000047],
              [104.41931946100001, 21.370146836000089],
              [104.41925339900006, 21.371714442000034],
              [104.41916742200004, 21.37503630900008],
              [104.4178412950001, 21.377699028000038],
              [104.41412744100003, 21.382089291000028],
              [104.41085838400009, 21.386556383000062],
              [104.40869712700007, 21.388101620000022],
              [104.40607638800003, 21.389011934000024],
              [104.40331862200001, 21.38995485600006],
              [104.39892640700005, 21.391440538000062],
              [104.39676130600006, 21.391846896000018],
              [104.39565679000006, 21.393234880000108],
              [104.39321056600008, 21.398098445000088],
              [104.39167674, 21.3998977030001],
              [104.38819379900012, 21.401816245000035],
              [104.38430924800005, 21.403321503000058],
              [104.38137710700001, 21.403622229000071],
              [104.37639829300008, 21.402622973000085],
              [104.37313586600007, 21.400687298000065],
              [104.36900881200009, 21.399237037000063],
              [104.36796663600002, 21.399266443],
              [104.36591362000006, 21.400301761000051],
              [104.36108853000007, 21.403022840000041],
              [104.35870439000007, 21.407828876000053],
              [104.35583953, 21.409826750000072],
              [104.3541877910001, 21.412599785000097],
              [104.35270828199999, 21.414783051000086],
              [104.34839374900005, 21.418023425000051],
              [104.34519799100005, 21.420457203000076],
              [104.34341226000001, 21.420450991000095],
              [104.34183845000008, 21.41972789400004],
              [104.34078061800004, 21.418606638000078],
              [104.34086256500007, 21.416888389000022],
              [104.34214174100003, 21.414155804000053],
              [104.34216228800005, 21.413063962000045],
              [104.34180829100006, 21.412105360000041],
              [104.33755916400004, 21.409234994000045],
              [104.33317452100007, 21.408953365000016],
              [104.33061151000001, 21.409398949000021],
              [104.32851902500008, 21.410537833000106],
              [104.32403846500009, 21.415982618000037],
              [104.32160405400001, 21.418800496000046],
              [104.32047084300004, 21.421012520000104],
              [104.32011696400006, 21.422017490000123],
              [104.31855264500004, 21.426034867000077],
              [104.31790780600006, 21.42865],
              [104.31642014100008, 21.42992658100011],
              [104.31219553000003, 21.431645626000062],
              [104.30998885900006, 21.43224793100007],
              [104.30567996000005, 21.433175379000069],
              [104.30174676600005, 21.434562133000057],
              [104.30142315800001, 21.437229010000088],
              [104.30088471900004, 21.438149513000049],
              [104.296665547, 21.439566248000027],
              [104.29702446300003, 21.440908375000056],
              [104.29787650600009, 21.444361667000052],
              [104.29826955300011, 21.446517633],
              [104.29866150900003, 21.44938229600006],
              [104.30030078200005, 21.452534969000055],
              [104.30110728100007, 21.454477055000076],
              [104.30008877100009, 21.457155476000089],
              [104.29858549800005, 21.458582656000047],
              [104.29508685800003, 21.458617171000057],
              [104.29178747600007, 21.459909723000067],
              [104.2912469510001, 21.460284076000043],
              [104.28676895000005, 21.463531904],
              [104.28457946300009, 21.465807215000062],
              [104.28073859000008, 21.471400762000044],
              [104.28019941300005, 21.473645589000064],
              [104.28102446300002, 21.474612087000096],
              [104.28155722700004, 21.475294854000104],
              [104.28175928800006, 21.477052522000051],
              [104.28171696700011, 21.480560365000123],
              [104.28147257700006, 21.482310531000067],
              [104.28113314300003, 21.484488957000011],
              [104.28116319000003, 21.485511810000091],
              [104.28130089700007, 21.486095000000113],
              [104.28166362200005, 21.486589013000042],
              [104.28393048700012, 21.487417007000076],
              [104.28406420500008, 21.488209237000078],
              [104.28330674400011, 21.488893595000036],
              [104.28250123000008, 21.491116460000015],
              [104.28304752000007, 21.49401255],
              [104.28672908900003, 21.498721154000044],
              [104.29083297800005, 21.503413485000067],
              [104.29296841800007, 21.51244082000003],
              [104.29214709800007, 21.513867601000044],
              [104.29165887400008, 21.516043431000043],
              [104.29100938300007, 21.518867096000079],
              [104.29093315200008, 21.520259842000122],
              [104.29106911100004, 21.522236974000108],
              [104.29175572400001, 21.525268809000053],
              [104.29082356600007, 21.527297769000064],
              [104.28747809800011, 21.532236963000081],
              [104.28258940400009, 21.536104730000091],
              [104.28111482200003, 21.53793865100004],
              [104.27948990800006, 21.541140783000046],
              [104.2778706120001, 21.54534197800011],
              [104.27667406000003, 21.548202746000108],
              [104.27521211500004, 21.550664084000104],
              [104.27533515000002, 21.552013631000122],
              [104.27615204600002, 21.553421280000059],
              [104.27878106900002, 21.556113864000089],
              [104.28024315400003, 21.557532306000034],
              [104.28051367300003, 21.560254996000069],
              [104.28289381700006, 21.562989791000042],
              [104.28611403300005, 21.568526407000022],
              [104.28733344400004, 21.569661930000095],
              [104.29018621200001, 21.571057063000062],
              [104.29537340000012, 21.572398223],
              [104.29546203000007, 21.572957253000077],
              [104.29303402500008, 21.581814282000074],
              [104.29207366500003, 21.58530612000007],
              [104.29186070800009, 21.586708027000064],
              [104.29134435800009, 21.590346628000034],
              [104.29062305200009, 21.592367260000067],
              [104.2893887060001, 21.59590089700005],
              [104.28790243900005, 21.597676404000055],
              [104.28591380500009, 21.599106649000085],
              [104.28560443300006, 21.599705460000074],
              [104.28573423600005, 21.600706538000033],
              [104.287546584, 21.602653350000089],
              [104.29129686500006, 21.603877547000039],
              [104.29649203600003, 21.604847040000053],
              [104.300494633, 21.60716709700008],
              [104.30407229000008, 21.60964260500009],
              [104.30585504200006, 21.612506315000068],
              [104.30778182400006, 21.614327060000079],
              [104.31201723700011, 21.61682495600008],
              [104.31506062700004, 21.618687396000077],
              [104.31656911800006, 21.620315264000034],
              [104.31787319500005, 21.622903739000066],
              [104.31992859500011, 21.627119064000055],
              [104.3207130820001, 21.628305137000048],
              [104.32186829100002, 21.628928234000085],
              [104.32487066200011, 21.63034846500009],
              [104.32661613000002, 21.632584099000063],
              [104.32870356200007, 21.637090157000038],
              [104.32925737300006, 21.638005225000079],
              [104.33232363200001, 21.638683077000032],
              [104.33422750700007, 21.639771416000109],
              [104.33755927500005, 21.642172471],
              [104.33835123000003, 21.644287703000131],
              [104.33835997600004, 21.647772085000035],
              [104.3382801700001, 21.648711509000051],
              [104.33777620500005, 21.653046840000023],
              [104.33660398200006, 21.654630221000026],
              [104.33406992600008, 21.655343305000052],
              [104.32847799400005, 21.655576104000126],
              [104.32499679300005, 21.655158485000037],
              [104.32190107100007, 21.654712350000068],
              [104.31971068800001, 21.654978022000115],
              [104.31621467000004, 21.656650523000032],
              [104.31368652500002, 21.65770026600002],
              [104.31158338000009, 21.657293699000043],
              [104.30698273800002, 21.655672579000026],
              [104.30291525300001, 21.654095074000068],
              [104.29963805200001, 21.653401718000076],
              [104.29618732700004, 21.653344184000019],
              [104.29460648, 21.654200469000081],
              [104.29213341500008, 21.656911692000058],
              [104.28790576300005, 21.661114967000024],
              [104.2864615870001, 21.661961856000019],
              [104.28425159200005, 21.661948111000036],
              [104.280394147, 21.662371312000062],
              [104.27813554300008, 21.662948998000076],
              [104.27524050300009, 21.663690192000026],
              [104.272911107, 21.664719571000113],
              [104.26979762900005, 21.665840285000023],
              [104.26709372500005, 21.665678704000065],
              [104.26517073000011, 21.665145437],
              [104.26241251100006, 21.664380517000033],
              [104.26018258500004, 21.664110694],
              [104.25645589500007, 21.66418726800012],
              [104.25499622600009, 21.665184670000045],
              [104.25398216900003, 21.666898015000058],
              [104.25383685100007, 21.670565489000104],
              [104.25304792300008, 21.671550956000104],
              [104.25148296100009, 21.67220975],
              [104.2427526590001, 21.672236416000068],
              [104.23902933200006, 21.670849436000061],
              [104.23692214800005, 21.670651145000086],
              [104.23495852400004, 21.670733995000099],
              [104.23306984900009, 21.671433632000081],
              [104.23054467000004, 21.672296644000077],
              [104.22730759000008, 21.672729450000091],
              [104.22377122800005, 21.672588047000119],
              [104.22093815800012, 21.672679221000024],
              [104.21672734100005, 21.673374025000044],
              [104.2152250340001, 21.673360039000045],
              [104.21266605600002, 21.672131825000029],
              [104.21100635000002, 21.670640188000064],
              [104.21024050400007, 21.668525029000115],
              [104.20943403400004, 21.666583372000048],
              [104.20859821600007, 21.664873483000058],
              [104.207419762, 21.663540997000027],
              [104.20293492100009, 21.658574890000089],
              [104.2011360000001, 21.656592966000062],
              [104.19771648100006, 21.653038600000052],
              [104.19384398700001, 21.649162815000089],
              [104.19213530400003, 21.648285680000079],
              [104.18810597300006, 21.648611418000115],
              [104.18418675200006, 21.649020284000109],
              [104.17986088100008, 21.649271098000078],
              [104.17514952300007, 21.649550026000064],
              [104.17127138400009, 21.649761933],
              [104.16712506900004, 21.651606733000058],
              [104.16308188600009, 21.653882956000103],
              [104.15967603300001, 21.65594945900002],
              [104.15790285400003, 21.656453038000031],
              [104.15489621100004, 21.656540356000065],
              [104.15082647200011, 21.655761387],
              [104.14837985300008, 21.655311220000058],
              [104.14550987800003, 21.655049101000046],
              [104.14213695900003, 21.655018261000066],
              [104.13506268800009, 21.654848785000077],
              [104.13165871100009, 21.654313324],
              [104.12499789900008, 21.653337733000022],
              [104.12075318100005, 21.652624837000097],
              [104.11564333600003, 21.648609897000078],
              [104.11356404200004, 21.647655978000024],
              [104.10703419800004, 21.646356920000095],
              [104.10471804500007, 21.646072394],
              [104.10303611900008, 21.646356470000057],
              [104.10104423400003, 21.647854593000112],
              [104.09986675500004, 21.648368169000065],
              [104.09844048200006, 21.647669480000076],
              [104.09543572700005, 21.64581643500005],
              [104.09117108500007, 21.64302363200008],
              [104.08763348400002, 21.643588499000096],
              [104.08549209400003, 21.644491522000031],
              [104.08218579500003, 21.647127737000105],
              [104.08020122500005, 21.648869705],
              [104.07738241300005, 21.650724666000087],
              [104.07493269900004, 21.651517604000055],
              [104.07284926500006, 21.652003405000109],
              [104.07072976100001, 21.652430474000091],
              [104.06778068000008, 21.653353868000025],
              [104.06341694200006, 21.654844462000078],
              [104.06004151500011, 21.655969243000058],
              [104.054739485, 21.657187498000049],
              [104.05127222, 21.657938852000044],
              [104.04798628300009, 21.658321729000107],
              [104.04606702300001, 21.659274767000092],
              [104.04442353700009, 21.660708874000086],
              [104.04298831100009, 21.662889979000077],
              [104.04194168500008, 21.663672692000091],
              [104.04031566500001, 21.664247632000055],
              [104.03550728400008, 21.66620590400008],
              [104.03360274500005, 21.66764686600002],
              [104.03252489300004, 21.669346475000047],
              [104.03135483200005, 21.670696009000068],
              [104.03040048600005, 21.671213188000046],
              [104.0286741030001, 21.671832693000056],
              [104.02464013700002, 21.672798182000093],
              [104.02427055500009, 21.671755621000074],
              [104.02363669300004, 21.670891143000048],
              [104.02293145900005, 21.670330064000076],
              [104.02189931700009, 21.669824078000133],
              [104.02053776300005, 21.669494991000079],
              [104.01865390000006, 21.669217464000035],
              [104.0166986560001, 21.669243342000044],
              [104.01428998400003, 21.669139181000041],
              [104.01169093200011, 21.668787824000013],
              [104.00380028200004, 21.666992196000123],
              [104.00420937000004, 21.671395588000038],
              [104.00399514700008, 21.672305796000032],
              [104.00260600399999, 21.673316749000016],
              [103.99938050600009, 21.674903986000068],
              [103.99747148700006, 21.675844586],
              [103.99537458500002, 21.676416159000041],
              [103.9936096420001, 21.676689013000058],
              [103.99237332700002, 21.676605765000097],
              [103.98958510100007, 21.67594605200005],
              [103.98712508400011, 21.675170361000085],
              [103.98581986500002, 21.675268654000043],
              [103.98122927700007, 21.678262975000081],
              [103.98086589900004, 21.679067026],
              [103.97714472200008, 21.674540333],
              [103.97283389500008, 21.671628406000117],
              [103.96792092600001, 21.665430286000031],
              [103.96618309400002, 21.665398723000081],
              [103.9644021830001, 21.669826155000038],
              [103.96260016100004, 21.670490236000091],
              [103.96011070900003, 21.670770154000074],
              [103.95767477700009, 21.670865187000032],
              [103.95188866800008, 21.670829470000093],
              [103.94801568200009, 21.670758834000111],
              [103.93560034000009, 21.670624855000042],
              [103.93474746500006, 21.671027349000035],
              [103.93428890900003, 21.671576420000079],
              [103.93422856200002, 21.673270956000046],
              [103.93429872800004, 21.679404374000022],
              [103.93589078800007, 21.687586349000028],
              [103.93574773400005, 21.692043348000119],
              [103.93240695300003, 21.695001699000024],
              [103.92821308500004, 21.69717786100005],
              [103.92011913400002, 21.701721143000086],
              [103.92095363700005, 21.704426118000072],
              [103.92021581600002, 21.707680970000084],
              [103.91967200100007, 21.708783620000105],
              [103.91776653400005, 21.710973880000047],
              [103.91065042800004, 21.716892914000049],
              [103.91108040400009, 21.717665755000048],
              [103.91463714400004, 21.721833974000049],
              [103.91535430800005, 21.723098850000078],
              [103.9167419720001, 21.726079724000066],
              [103.91759177299998, 21.728111930000082],
              [103.91757128900007, 21.729085074000039],
              [103.91560850700003, 21.733986130000105],
              [103.91422763499999, 21.735977298000094],
              [103.91473495100004, 21.736612459000071],
              [103.916426132, 21.737547523000075],
              [103.92187886300005, 21.740011913000053],
              [103.92939481500011, 21.743417918000048],
              [103.93672873400003, 21.744873332000033],
              [103.94591874500004, 21.744728305000116],
              [103.95074166000003, 21.741756705000093],
              [103.95147508600006, 21.742256813000083],
              [103.95188387300004, 21.747618461000059],
              [103.95004179300004, 21.757458727000056],
              [103.95702630700001, 21.757864060000109],
              [103.96097345900009, 21.757657707000085],
              [103.96167775600001, 21.757774807000096],
              [103.96258950700005, 21.760433636000087],
              [103.96499850800006, 21.769794715000046],
              [103.96497970400002, 21.770698284000019],
              [103.96444030700006, 21.771592399000014],
              [103.96267595900009, 21.772359963000021],
              [103.9610122870001, 21.773650822000064],
              [103.957559793, 21.778663799000064],
              [103.95364240100005, 21.788118112000099],
              [103.94969768900008, 21.789958311000035],
              [103.94923690100005, 21.790645205000111],
              [103.94922426200007, 21.791250084000069],
              [103.9492064030001, 21.792104755000082],
              [103.94953908700009, 21.793988090000127],
              [103.95007893900001, 21.796640010000075],
              [103.94985031600001, 21.80045988100003],
              [103.94764094500007, 21.802922605000049],
              [103.93703080000006, 21.813887953000055],
              [103.93370095600007, 21.820084376000089],
              [103.923922179, 21.826823009000051],
              [103.91626250100005, 21.830089123000015],
              [103.91570074500001, 21.832025474000091],
              [103.91877989900006, 21.834237254000044],
              [103.91836828400002, 21.837879668000056],
              [103.91084035100003, 21.845458337000068],
              [103.9040225680001, 21.851103140000113],
              [103.89830014700001, 21.855968402000059],
              [103.88803610300005, 21.866137323000068],
              [103.88720738000005, 21.875966867000059],
              [103.88679253000001, 21.880886983000011],
              [103.885867101, 21.894668891],
              [103.88467632700005, 21.906916221000117],
              [103.88671651800011, 21.912515034000087],
              [103.89035696000005, 21.921653708000029],
              [103.89646591300004, 21.921488428000124],
              [103.90817116000008, 21.924275977000015],
              [103.91014112500008, 21.926189005000118],
              [103.91704068200004, 21.932675971000037],
              [103.92115786000008, 21.93163933000006],
              [103.92956727100008, 21.924772763000085],
              [103.93311942800008, 21.922265802000048],
              [103.93654454300007, 21.920486331000077],
              [103.94185364200004, 21.919401550000117],
              [103.9510440820001, 21.928535955000058],
              [103.95723434200008, 21.935182488000038],
              [103.96112015500006, 21.939875432000022],
              [103.96220301000004, 21.94865314700003],
              [103.96662646200011, 21.9564268560001],
              [103.96805780600006, 21.958942101000019],
              [103.97590106100002, 21.96137778500011],
              [103.98072923000009, 21.965565882000071],
              [103.98290270200008, 21.967940781000017],
              [103.98706972800005, 21.966112357000078],
              [103.99298435700005, 21.959161602000066],
              [103.99516162599998, 21.95010040500005],
              [103.99473234200008, 21.949210491000045],
              [103.99096716300004, 21.943663781000033],
              [103.98972624600003, 21.941134107000067],
              [103.9895638780001, 21.939366773000032],
              [103.99051846900004, 21.936505182000055],
              [103.99361317400003, 21.93117477300008],
              [103.99784645000008, 21.926096913000045],
              [104.00921693000004, 21.919800511000084],
              [104.02626804400006, 21.917784066000046],
              [104.03578591100003, 21.916885910000069],
              [104.04245939100011, 21.916447526000034],
              [104.04795478000005, 21.915245078000019],
              [104.05748978400001, 21.911049470000066],
              [104.06506381400008, 21.907886746000123],
              [104.07127178100009, 21.905860488000073],
              [104.07516267900007, 21.905279087000018],
              [104.07805174500001, 21.905051440000101],
              [104.08002716800003, 21.905736351000101],
              [104.08757449400011, 21.916177598000083],
              [104.08980333100006, 21.91663469100007],
              [104.09218602200011, 21.916862294000069],
              [104.09518029500006, 21.916357675000064],
              [104.10059087600008, 21.914409501000073],
              [104.10791668200005, 21.91119455900008],
              [104.11289946500003, 21.910724483000088],
              [104.1182911060001, 21.909704217000048],
              [104.12124384800006, 21.9087806100001],
              [104.1262636690001, 21.906453505000023],
              [104.13264266100005, 21.900806615000064],
              [104.138371073, 21.897841431000039],
              [104.14088074500009, 21.896677676000117],
              [104.14407897300003, 21.895897317000035],
              [104.15025252000004, 21.895539900000038],
              [104.15648219500008, 21.894858241000037],
              [104.15998044900007, 21.893989958000049],
              [104.16906288500006, 21.892382041000054],
              [104.17774198400008, 21.89104542200004],
              [104.18382504000007, 21.890221175000057],
              [104.19015385300004, 21.889540273000115],
              [104.19393187100009, 21.889604958000049],
              [104.19631435200004, 21.889831457000049],
              [104.20302772300005, 21.892360810000078],
              [104.20808195100008, 21.893282873000075],
              [104.21174792000008, 21.893995431000057],
              [104.21686902600005, 21.896590084000067],
              [104.22471093500009, 21.899927319000057],
              [104.228241504, 21.902494673000028],
              [104.22916350600008, 21.906225035000126],
              [104.22918525600001, 21.915326382000089],
              [104.22951976100006, 21.921136159000106],
              [104.22592184500009, 21.927111355000051],
              [104.21689388200008, 21.936058639000052],
              [104.20919815500005, 21.940431467000018],
              [104.2065632850001, 21.944170669000052],
              [104.20655328400007, 21.944681236000108],
              [104.20793532400002, 21.947769198000074],
              [104.21152081200003, 21.950105360000101],
              [104.21515537700003, 21.949935061000048],
              [104.22070943900012, 21.948265118000073],
              [104.23052802100008, 21.944717249000064],
              [104.24007141300004, 21.94123392700007],
              [104.24632089100002, 21.93702137600004],
              [104.25098274300009, 21.933873013000046],
              [104.25538255200007, 21.932693459000063],
              [104.26436525200008, 21.933726662000105],
              [104.27179978600006, 21.936381866000062],
              [104.28190393400004, 21.941194034000056],
              [104.28469183000006, 21.937177915000028],
              [104.29096249700011, 21.929203497000046],
              [104.29744623400002, 21.926966699000069],
              [104.31208638000005, 21.92212556300008],
              [104.32298432300007, 21.919194997000076],
              [104.32396496900003, 21.913383901000124],
              [104.32670651300003, 21.907810777000059],
              [104.3298352730001, 21.901477790000087],
              [104.33231912200009, 21.896364563000056],
              [104.33790035500002, 21.886542687000073],
              [104.34153779500008, 21.88403065100006],
              [104.35110029900008, 21.877426082000092],
              [104.35388309400003, 21.877518154000072],
              [104.3745681290001, 21.89213517400006],
              [104.38415232000003, 21.900835085000026],
              [104.38543067400011, 21.912278450000052],
              [104.3939311550001, 21.920495649000046],
              [104.4011361920001, 21.920844488000043],
              [104.41733594000003, 21.932342354000056],
              [104.42502243700008, 21.936273310000054],
              [104.43062197300003, 21.948202837000075],
              [104.42538190000008, 21.957218800000078],
              [104.42644600300009, 21.961553730000013],
              [104.42675476900007, 21.966387195000081],
              [104.42768946200005, 21.96965213500004],
              [104.42792791300005, 21.972905875000045],
              [104.42777021000006, 21.976060374000014],
              [104.42632544700007, 21.981561944000113],
              [104.42505294200004, 21.985812719000108],
              [104.42070174200008, 21.995120743000115],
              [104.41950367200008, 21.998026250000088],
              [104.41916916900001, 21.999970714000128],
              [104.41937356400005, 22.000718865000032],
              [104.41955262600004, 22.001614514000067],
              [104.41954977100006, 22.002317733000076],
              [104.41928134900006, 22.003733892000099],
              [104.41877281000008, 22.005223793000091],
              [104.41808364600008, 22.006201603000086],
              [104.416981273, 22.007689394000082],
              [104.41559938800006, 22.010497381000071],
              [104.41545675000006, 22.011860707000075],
              [104.41496389500006, 22.020638647000013],
              [104.41468086800009, 22.022811239000092],
              [104.41393555600006, 22.02630340400011],
              [104.41222975600004, 22.03004783100004],
              [104.41020826200004, 22.032768221000062],
              [104.40883413100002, 22.033615664000102],
              [104.40704932600005, 22.034333748],
              [104.40599496800004, 22.035182319000057],
              [104.40199456800006, 22.041262373000059],
              [104.39900552300003, 22.045811735000065],
              [104.38604753000008, 22.062726198000071],
              [104.38549623500003, 22.063406043],
              [104.38357052400004, 22.064890476000087],
              [104.38063684200004, 22.06696774000001],
              [104.37889677400004, 22.067770907000025],
              [104.378345618, 22.068408103000081],
              [104.37802218600007, 22.069216651000097],
              [104.37801715600008, 22.070367353],
              [104.37819454200012, 22.071603991000096],
              [104.37819117900001, 22.072371118000063],
              [104.37804548500003, 22.074331053000051],
              [104.37771083500006, 22.077696712000083],
              [104.37709448600005, 22.082766057000036],
              [104.37693399699999, 22.08809285600006],
              [104.37719701100009, 22.090651009000069],
              [104.37773912100009, 22.092102128000057],
              [104.37810233400003, 22.092657562000092],
              [104.37878568000008, 22.093171590000082],
              [104.37978915800007, 22.093644218000108],
              [104.38275650400001, 22.094465210000088],
              [104.38686527700006, 22.095588782000064],
              [104.38754866500003, 22.096102771000048],
              [104.38795653300011, 22.096914061000028],
              [104.38817587800006, 22.099045850000017],
              [104.38834050900009, 22.103265776000036],
              [104.38851875400009, 22.10433192200005],
              [104.39428124800003, 22.114326271],
              [104.39450800100005, 22.114753305000079],
              [104.3950101020001, 22.114925649000035],
              [104.39628770900003, 22.115484417000083],
              [104.39802235800003, 22.11606619100008],
              [104.39932382800006, 22.116390622000011],
              [104.401386148, 22.116794633000048],
              [104.40211536000002, 22.116982194000023],
              [104.40287196600011, 22.117186792000076],
              [104.40473689200005, 22.118200349000091],
              [104.40162706600003, 22.128866154],
              [104.40092123600007, 22.130138409000018],
              [104.39944413900008, 22.132371713000062],
              [104.39730549400005, 22.135336603000063],
              [104.39662563400002, 22.136279107000064],
              [104.3946128470001, 22.138945692000078],
              [104.39425931900007, 22.139257042000082],
              [104.39721528300004, 22.140226815000027],
              [104.39794427200005, 22.140826170000061],
              [104.39844322600004, 22.141765628000073],
              [104.39986546900012, 22.146011435000013],
              [104.40039662700011, 22.150147422000096],
              [104.39761946, 22.158064280000112],
              [104.39720483400006, 22.158787261000064],
              [104.39619557100005, 22.159550671000083],
              [104.39433943100003, 22.160524026000061],
              [104.39246240799999, 22.161028469000051],
              [104.39136485900003, 22.161045692000059],
              [104.39031350099999, 22.160956523000053],
              [104.38985594000006, 22.161018737000049],
              [104.38960325000002, 22.161294813000062],
              [104.38928048200006, 22.161911571000111],
              [104.38805916200008, 22.166754877000102],
              [104.38391123000007, 22.174154892000075],
              [104.38234965700009, 22.175683247000052],
              [104.38234593100006, 22.176535606000058],
              [104.38270806800008, 22.177389359000088],
              [104.38343867200005, 22.177647827000065],
              [104.38581603400011, 22.177827280000081],
              [104.38993346100008, 22.177501799000062],
              [104.39322513000003, 22.177769796000035],
              [104.39477639000005, 22.178627939000073],
              [104.39605000900008, 22.180252174000081],
              [104.39804196900005, 22.185032841000037],
              [104.40067653100007, 22.189304408000062],
              [104.40276962100006, 22.191869221],
              [104.41371018100006, 22.200688412000069],
              [104.41526253500007, 22.201375888000108],
              [104.41764139800009, 22.201299174000063],
              [104.42304334200003, 22.200210336000033],
              [104.42652481100004, 22.198944072000067],
              [104.43101969000007, 22.195891296000077],
              [104.43358927900005, 22.193854550000047],
              [104.43552119900002, 22.191133651000072],
              [104.43708749000004, 22.188326250000046],
              [104.43819160300009, 22.186710547000033],
              [104.43974967500003, 22.185948779000064],
              [104.44249483300007, 22.185702488000025],
              [104.44395738900002, 22.185963194000102],
              [104.44651492300007, 22.186909517000061],
              [104.44825290200006, 22.186915415000016],
              [104.45136462700006, 22.186499738000087],
              [104.45209805800008, 22.186076008000022],
              [104.45292460600007, 22.18522641800007],
              [104.45393638800003, 22.183780763000094],
              [104.45449047200005, 22.182418813000112],
              [104.45468164300006, 22.180288510000068],
              [104.45496721900005, 22.177391369000077],
              [104.45525770400012, 22.173215684000077],
              [104.45572257600003, 22.171256759000087],
              [104.45650640300005, 22.169597222000093],
              [104.45815761000007, 22.168324157000093],
              [104.45962260400005, 22.167902828000052],
              [104.46218480200012, 22.167570337000072],
              [104.46410772500006, 22.166980005000077],
              [104.46694844700001, 22.165540275000062],
              [104.46997585600012, 22.163078237],
              [104.47117054200002, 22.161547823000127],
              [104.47897793000004, 22.152452453000038],
              [104.48192120100003, 22.147858954000107],
              [104.48385062100006, 22.1453931550001],
              [104.48550129700004, 22.144119786000083],
              [104.49512526100008, 22.137927421000114],
              [104.50611907500004, 22.131994352000014],
              [104.51280811800007, 22.127922987000126],
              [104.51793893500005, 22.124869610000083],
              [104.5198653960001, 22.123000029000117],
              [104.53573482400006, 22.10727664700007],
              [104.54238041000005, 22.101882686000081],
              [104.54348073000006, 22.100862885000083],
              [104.54499501000002, 22.099034460000055],
              [104.54757018800011, 22.094182944000096],
              [104.54867014399998, 22.093248363000036],
              [104.54981442400008, 22.09274007500008],
              [104.55159873300001, 22.092190916000028],
              [104.55439017300004, 22.091133056000075],
              [104.55608423100006, 22.090200032000062],
              [104.55942617800008, 22.08846165300011],
              [104.56043288100007, 22.088080786000127],
              [104.5614381340001, 22.088168719000031],
              [104.56285198800003, 22.089152753000093],
              [104.56430801600007, 22.091287639000079],
              [104.56972370000007, 22.099016170000048],
              [104.57008743900006, 22.099656431000092],
              [104.57008615000004, 22.100082630000095],
              [104.56962454000004, 22.101573110000047],
              [104.56948443600008, 22.102552988000035],
              [104.56939107400008, 22.103192047000064],
              [104.56943458500004, 22.10391670200007],
              [104.56984300500008, 22.104898036000087],
              [104.57048000600003, 22.105879957000063],
              [104.57389283100005, 22.111045895000103],
              [104.57462232000002, 22.11168709600004],
              [104.57544387300005, 22.112115434000046],
              [104.57740814400003, 22.11258935500009],
              [104.58028745000007, 22.112809878000036],
              [104.58197808600006, 22.113069924000058],
              [104.5823887490001, 22.113326695000076],
              [104.58238787000003, 22.11362503900007],
              [104.58169978300003, 22.114433053000049],
              [104.58005017400006, 22.115750024000036],
              [104.57867533400008, 22.116897222000098],
              [104.57704386700001, 22.119769842000011],
              [104.5769934460001, 22.121346647000095],
              [104.57904587600009, 22.138357207000055],
              [104.57904258800009, 22.13946531300008],
              [104.57945155400009, 22.140318768],
              [104.58091013300007, 22.141856830000073],
              [104.58309829700002, 22.144078663000052],
              [104.58346169700008, 22.144889362000072],
              [104.58345956000007, 22.145613883000067],
              [104.58299919200007, 22.146678212000026],
              [104.58184979600001, 22.148806255000061],
              [104.58051152800005, 22.152936923000063],
              [104.5796802310001, 22.155705055000041],
              [104.57963198200009, 22.156557341000038],
              [104.57976777300006, 22.15702650100004],
              [104.58063507900005, 22.157540167],
              [104.58186871900007, 22.157884295000052],
              [104.58438213400011, 22.158444760000059],
              [104.58607121000006, 22.159429309000075],
              [104.58684672100001, 22.160070568000023],
              [104.58698142100006, 22.160923306000058],
              [104.58702465900008, 22.161775804000072],
              [104.58642795800009, 22.162541439000094],
              [104.58385695200009, 22.165987085000083],
              [104.58279849500008, 22.168243217000061],
              [104.58251819400007, 22.170245621],
              [104.58237533800006, 22.172163140000031],
              [104.58220909600007, 22.174229697000044],
              [104.58155804100004, 22.178107155000056],
              [104.58371022500003, 22.178745995000071],
              [104.58623983900007, 22.180093880000037],
              [104.58738879000001, 22.181008977000083],
              [104.58863030700006, 22.182632497000029],
              [104.589226282, 22.182331819000112],
              [104.5898362250001, 22.181887720000049],
              [104.590358866, 22.181564933000075],
              [104.59157616800009, 22.181567985000058],
              [104.59227130700005, 22.181731775000024],
              [104.59461525500009, 22.183034037000127],
              [104.59617840200012, 22.183726636000053],
              [104.59717776900003, 22.183931677000054],
              [104.59804739600004, 22.183893305000076],
              [104.59856980400009, 22.183651519000058],
              [104.59939768500003, 22.183005355000063],
              [104.59979057100003, 22.182439145000018],
              [104.5999663090001, 22.18179136400007],
              [104.60007780200007, 22.180799074000035],
              [104.60003994600005, 22.178813843000086],
              [104.60004200900005, 22.17808461900006],
              [104.60017392300004, 22.177558273000031],
              [104.60074102800007, 22.176870955000091],
              [104.60152492300008, 22.17638672],
              [104.60235115700006, 22.176307711000028],
              [104.60322061800005, 22.17630983200003],
              [104.61289355100007, 22.176312836000101],
              [104.61345860500009, 22.17635468400001],
              [104.61397951200003, 22.176639519000041],
              [104.61502011800007, 22.177654796000112],
              [104.61601705400004, 22.178750995000129],
              [104.616494281, 22.179116742000105],
              [104.61697239100005, 22.179158369000085],
              [104.61727671300008, 22.179159085000052],
              [104.61766842300008, 22.178997961000043],
              [104.61830104800002, 22.178168926000104],
              [104.61956605500006, 22.176591875],
              [104.621178818, 22.175015633000058],
              [104.622137283, 22.174248109000061],
              [104.62339994700002, 22.173521797000092],
              [104.62414181000003, 22.173174195000023],
              [104.62319910900001, 22.177607985000058],
              [104.62310434000007, 22.178843726000075],
              [104.62280462500006, 22.179759347000072],
              [104.62221882000009, 22.180748887000057],
              [104.6190288170001, 22.184540921000107],
              [104.61732423800002, 22.189097179000058],
              [104.61646992000003, 22.192540928000071],
              [104.61448675700008, 22.198545538000047],
              [104.613649556, 22.203615230000118],
              [104.6135476800001, 22.207408084000058],
              [104.61400267400009, 22.208304163000037],
              [104.61673850600005, 22.211549672000075],
              [104.61737662700008, 22.212403549000022],
              [104.61915769500011, 22.213515822000126],
              [104.62271889200007, 22.216123871],
              [104.62678142200011, 22.219500145000026],
              [104.62696328900006, 22.219926748000063],
              [104.62682525800001, 22.220224764000115],
              [104.62645837200003, 22.220564876000104],
              [104.62522159700011, 22.221158695000028],
              [104.61976954000002, 22.224129370000064],
              [104.61853190600002, 22.225021470000073],
              [104.61514366600008, 22.226036337000018],
              [104.61244375400005, 22.22628564300004],
              [104.61033984500007, 22.226067524000115],
              [104.60800923400005, 22.22512429500005],
              [104.6043557510001, 22.222814035000034],
              [104.59617605700001, 22.219554897000116],
              [104.59539872300009, 22.219425111000035],
              [104.59361347000008, 22.219804236000051],
              [104.59150699000006, 22.220523478000054],
              [104.58962923600002, 22.221243278000102],
              [104.58646488400004, 22.223877637000022],
              [104.58486041600007, 22.224981632000059],
              [104.58430854100006, 22.225960457000049],
              [104.58282578000004, 22.232306850000057],
              [104.58259562900008, 22.232775068000073],
              [104.58259437500007, 22.233201261000076],
              [104.58272986200009, 22.23379826700009],
              [104.58425336600003, 22.244669937000069],
              [104.5846547360001, 22.248208321],
              [104.58500687600011, 22.251757821000062],
              [104.58456328400008, 22.259811008000071],
              [104.58418726300006, 22.263176923000039],
              [104.58454844600001, 22.26483996200006],
              [104.58554968099999, 22.266717740000082],
              [104.58700808400005, 22.268767153000013],
              [104.58796562600004, 22.269962898000095],
              [104.58892442100006, 22.270732467000087],
              [104.589975618, 22.271203938000014],
              [104.59180284800001, 22.272359235000039],
              [104.59260131700009, 22.273192302000041],
              [104.59478943400003, 22.276181075000068],
              [104.599891187, 22.284632202000054],
              [104.60207867200008, 22.287961822000035],
              [104.60440847600002, 22.289544401000057],
              [104.60651080600006, 22.290657597000063],
              [104.60799944800004, 22.290967909000067],
            ],
          ],
        ],
      },
    },
  ],
};
