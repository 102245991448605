import styled from "styled-components";

export const RadarWrapper = styled.div`
  width: 800px;
  @media only screen and (max-width: 1300px) {
    width: 700px;
  }
`;
export const RadarSigleWrapper = styled.div`
  width: 800px;
  @media only screen and (max-width: 1300px) {
    width: 700px;
  }
  .g2-tooltip-list {
    margin-left: -18px !important;
  }
`;
export const TitleChart = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12x;
`;
