import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,

  plugins: {
    legend: {
      position: "bottom",
      display: true,
      labels: {
        boxHeight: 0.2,
      },
    },
    title: {
      display: true,
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      borderColor: "rgba(0, 0, 0, 0,0.4)",
      borderWidth: 0.1,
    },
  },
  style: {
    strokewidth: 10,
  },
  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value <= 100 && value >= 0 ? value + "%" : "";
        },
      },
      min: -10,
      max: 110,
      type: "linear",
      display: true,
      position: "right",

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
    x: {
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
};

export function LinePoint({ dataST, dataSM, time, hiddenCaesarean, department, passLevelST, passLevelSM }) {
  const { t } = useTranslation();
  const labels = time.slice(0, 8).reverse();

  const position = (value) => {
    if (!value && value !== 0) return "0";
    if (value === 100) {
      return "4";
    }
    if (value < 10) return "18";
    return "12";
  };

  const renderCircle = (innerText, size = 30, color = "red") => {
    const svg_encoded = encodeURIComponent(`
    <svg width="49" height="52" viewBox="0 0 49 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="49" height="52" rx="24.5" fill="${color}"/>
      <text font-family="Arial, Helvetica, sans-serif" x="${position(
        innerText
      )}" y="33" font-size="1.5em" font-weight="bold" fill="white">${innerText}</text>
    </svg>
    `);
    const svg_uri = `data:image/svg+xml;charset=UTF-8,${svg_encoded}`;
    const illu = new Image(size, size);
    illu.src = svg_uri;
    return illu;
  };

  function difference(a, b) {
    return Math.abs(a - b);
  }

  function findMin(a, b) {
    if (a > b) {
      return b;
    }
    return a;
  }

  const dataSMFormat = useMemo(() => {
    let sum = 0;
    const listNull = [];
    const format = dataSM
      ?.map((element, index) => {
        if (element !== null) {
          sum++;
        }
        if (sum !== 0 && element === null) {
          let space = 0;
          let nextData = [];
          let leftData = null;
          let min = 0;
          let max = [];
          for (let i = 0; i < dataSM?.length; i++) {
            if (index > i && dataSM[i] !== null) {
              leftData = dataSM[i];
              min = i;
            }
            if (index < i && dataSM[i] !== null) {
              nextData.push(dataSM[i]);
              max.push(i);
            }
            if (dataSM[i] === null) {
              space += 1;
            }
          }
          if (nextData?.length > 0 && space !== 0 && leftData !== null) {
            const TU = (difference(leftData, nextData[0]) * (index - min)) / (max[0] - min);
            listNull.push(index);
            return TU + findMin(leftData, nextData[0]);
          }
        }
        return element;
      })
      .slice(0, 8)
      .reverse();
    return {
      format,
      listNull,
    };
  }, [dataSM]);

  const dataSTFormat = useMemo(() => {
    let sum = 0;
    const listNull = [];
    const format = dataST
      ?.map((element, index) => {
        if (element !== null) {
          sum++;
        }
        if (sum !== 0 && element === null) {
          let nextData = [];
          let leftData = null;
          let min = 0;
          let max = [];
          for (let i = 0; i < dataST?.length; i++) {
            if (index > i && dataST[i] !== null) {
              leftData = dataST[i];
              min = i;
            }
            if (index < i && dataST[i] !== null) {
              nextData.push(dataST[i]);
              max.push(i);
            }
          }
          if (nextData?.length > 0 && leftData !== null) {
            const TU = (difference(leftData, nextData[0]) * (index - min)) / (max[0] - min);
            listNull.push(index);
            return TU + findMin(leftData, nextData[0]);
          }
        }
        return element;
      })
      .slice(0, 8)
      .reverse();

    return {
      format,
      listNull,
    };
  }, [dataST]);

  const rateCaesarean = passLevelSM === null ? passLevelSM : passLevelSM || 75;
  const rateNormal = passLevelST === null ? passLevelST : passLevelST || 0;

  const dataSK = {
    labels,

    datasets: [
      {
        label: t("chart.vaginalDelievery"),
        data: dataSTFormat?.format || [],
        borderColor: "#CFAC00",
        backgroundColor: "rgb(207, 172, 0,0.5)",
        pointStyle: dataSTFormat?.format?.map((element) => renderCircle(element, 25, `rgb(207, 172, 0,0.5)`)),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataSTFormat?.listNull.find((elementFind) => elementFind === element?.index);
          return checked ? 0 : 6;
        },
        pointHoverRadius: (element) => {
          const checked = dataSTFormat?.listNull.find((elementFind) => elementFind === element?.index);
          return checked ? 0 : 6;
        },
        datalabels: { display: false },
      },
      {
        label: t("chart.CSection"),
        data: dataSMFormat?.format || [],
        borderColor: "#0089CF",
        backgroundColor: "rgb(0, 137, 207,0.5)",
        pointStyle: dataSMFormat?.format?.map((element) => renderCircle(element, 25, `rgb(0, 137, 207,0.5)`)),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataSMFormat?.listNull.find((elementFind) => elementFind === element?.index);
          return checked ? 0 : 6;
        },
        pointHoverRadius: (element) => {
          const checked = dataSMFormat?.listNull.find((elementFind) => elementFind === element?.index);
          return checked ? 0 : 6;
        },
        datalabels: { display: false },
      },
      {
        label: t("chart.normalRate"),
        fill: false,
        backgroundColor: "rgb(207, 172, 0,0.5)",
        borderColor: "#CFAC00",
        borderDash: [3.5, 3.5],
        data: labels.map(() => faker.datatype.number({ min: rateNormal, max: rateNormal })),
        borderWidth: 2,
        pointStyle: "hidden",
        pointRadius: 0,
        pointHoverRadius: 0,
        datalabels: { display: false },
      },
      {
        label: t("chart.caesareanRate"),
        fill: false,
        backgroundColor: "rgb(0, 137, 207,0.5)",
        borderColor: "#0089CF",
        borderDash: [3, 3],
        borderWidth: 2,
        data: labels.map(() => faker.datatype.number({ min: rateCaesarean, max: rateCaesarean })),
        pointStyle: "hidden",
        pointRadius: 0,
        datalabels: { display: false },

        pointHoverRadius: 0,
      },
    ],
  };

  if (hiddenCaesarean) {
    dataSK.datasets.pop();
    dataSK.datasets.pop();
  }

  const dataNK = {
    labels,
    datasets: [
      {
        label: t("chart.parameter"),
        data: dataSTFormat?.format || [],
        borderColor: "#0089CF",
        backgroundColor: "rgb(0, 137, 207,0.5)",
        pointStyle: dataSTFormat?.format?.map((element) => renderCircle(element, 25, `rgb(0, 137, 207,0.5)`)),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataSTFormat?.listNull.find((elementFind) => elementFind === element?.index);
          return checked ? 0 : 6;
        },
        pointHoverRadius: (element) => {
          const checked = dataSTFormat?.listNull.find((elementFind) => elementFind === element?.index);
          return checked ? 0 : 6;
        },
        datalabels: { display: false },
      },
      {
        label: t("chart.passLevel"),
        fill: false,
        backgroundColor: "rgb(0, 137, 207,0.5)",
        borderColor: "rgb(0, 137, 207,0.5)",
        borderDash: [3, 3],
        borderWidth: 2,
        data: labels.map(() => faker.datatype.number({ min: rateNormal, max: rateNormal })),
        pointStyle: "hidden",
        pointRadius: 0,
        pointHoverRadius: 0,
        datalabels: { display: false },
      },
    ],
  };

  return <Line options={options} data={department === "NK" ? dataNK : dataSK} />;
}
