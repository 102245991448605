import { InboxOutlined } from "@ant-design/icons";
import { Input, message, Select, Spin } from "antd";
import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { linkApi } from "../../../../common/ngok";
import { DraggerFile } from "../../../../container/FormInputData/styled";
import {
  ButtonCancel,
  ButtonSave,
  ButtonWrapper,
  SelectedCity,
} from "../../../UserTable/styled";
import { FormAddWrapper, SelectModalAdd, TitleAdd } from "./style";

const FormAddHostpital = ({ setIsOpen, getDataAwarded, setSelected }) => {
  const { t } = useTranslation();
  const citiesData = useSelector((state) => state.data.citiesData);
  const token = useSelector((state) => state?.auth?.token);

  const [listHostpital, setListHostpital] = useState([]);
  const [hostpital, setHostpital] = useState(null);

  const [yearFile, setYearFile] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const listCity = useMemo(() => {
    return citiesData?.filter((element) => element?.code !== -1) || [];
  }, [citiesData]);

  const getHostPital = async (code) => {
    setIsLoading(true);
    const myHeaders = new Headers({
      Authorization: "Token " + token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${linkApi}/dm/data/province?code=${code}&info=hospitals`, {
      method: "POST",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        const reDataCity = data?.hospitals;
        setListHostpital(reDataCity || []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUpdate = async (year, code) => {
    const myHeaders = new Headers({
      Authorization: "Token " + token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${linkApi}/dm/data/hospital?code=${code}`, {
      method: "POST",
      headers: myHeaders,
      body: new URLSearchParams({
        last_awarded_year: moment(year).format("YYYY"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        message.success(t("document.updateSuccess"));
        getDataAwarded((res) => {
          if (res) {
            setSelected(res[0]);
          }
        });
      })
      .catch(() => {
        message.warning(t("document.updateError"));
      })
      .finally(() => {});
  };

  const handleUpload = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + token);
    myHeaders.append(
      "Cookie",
      "csrftoken=iBZFVxTK55EuqJtf8E8fQsPM7FPB9T9fvcwZd0p84fHQooETm1i99ycSF1NITwZn"
    );

    var formdata = new FormData();
    formdata.append("docfile", fileList[0]);
    formdata.append("docname", `COE Certificate of ${hostpital.name}`);
    formdata.append(
      "docnote",
      `Chứng nhận Bệnh viện Thực hành Nuôi con bằng sữa mẹ Xuất sắc của ${hostpital.name}`
    );
    formdata.append("doctype", "coe_cert");
    formdata.append("is_public", "True");
    formdata.append("hospital_code", hostpital.code);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${linkApi}/dm/data/docs`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        onUpdate(yearFile, hostpital.code);
      })
      .catch((error) => message.success("errrrr"));
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  return (
    <FormAddWrapper>
      <TitleAdd isTop>{t("userManagement.city")}</TitleAdd>
      <SelectedCity
        onChange={(e) => {
          getHostPital(listCity[e].code);
          setHostpital(null);
        }}
      >
        {listCity?.map((element, index) => {
          return (
            <Select.Option key={String(index)}>
              {index + 1}. {element?.name}
            </Select.Option>
          );
        })}
      </SelectedCity>

      <TitleAdd>{t("filter.hospital")}</TitleAdd>
      <SelectModalAdd
        // defaultValue={hostpital?.name || t("common.none")}
        value={hostpital?.name}
        onChange={(e) => {
          setHostpital(listHostpital[e]);
        }}
      >
        {isLoading ? (
          <Select.Option>
            <Spin />
          </Select.Option>
        ) : (
          <>
            {listHostpital
              ?.filter((element) => element?.code !== -1)
              ?.map((element, index) => {
                return (
                  <Select.Option key={String(index)}>
                    {index + 1}. {element?.name}
                  </Select.Option>
                );
              })}
          </>
        )}
      </SelectModalAdd>

      <TitleAdd>{t("common.awardYear")}</TitleAdd>
      <Input onChange={(e) => setYearFile(e.target.value)} />

      <DraggerFile
        height={130}
        style={{ width: 450, marginTop: 40 }}
        maxCount={2}
        value={fileList}
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t("document.clickDrag")}</p>
      </DraggerFile>
      <ButtonWrapper>
        <ButtonCancel onClick={() => setIsOpen(false)}>
          {t("userManagement.cancel")}
        </ButtonCancel>
        <ButtonSave onClick={handleUpload}>
          {t("userManagement.save")}
        </ButtonSave>
      </ButtonWrapper>
    </FormAddWrapper>
  );
};

export default FormAddHostpital;
