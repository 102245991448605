// API URL
export const linkApi = process.env.REACT_APP_API || "https://dev.api.coe.bmte.vn";

export const QUARTER_LENGTH = 26; //  27 Quarters therefore max is 27

export const SCREEN_DEFAULT = {
  1: "/dashboard",
  2: "/survey_urls",
  3: "/raw_data",
  4: "/documents",
  6: "/exportData",
  7: "/user_management",
};

export const screenReverst = {
  dashboard: 1,
  survey_urls: 2,
  raw_data: 3,
  documents: 4,
  user_management: 5,
};
