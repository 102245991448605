import styled from "styled-components";

export const ChartLinkWrapper = styled.div`
  .certificate {
    padding-top: 40px;
  }
`;
export const TitleChart = styled.div`
  color: #0089cf;
  font-weight: bold;
  font-size: 24px;
`;
