import styled from "styled-components";
import { rem } from "../helpers/rem/px-to-rem";

export const MenuIconWrapper = styled.img`
  position: ${(props) => (props?.collapse ? "unset" : "absolute")};
  width: ${rem(24)} !important;
  height: 24px !important;
  z-index: 2;
  left: ${rem(20)};
  bottom: ${rem(10)};
`;
export const ButtonLogout = styled.div`
  cursor: pointer;
  width: 100%;
  padding-left: ${(props) => (props?.collapse ? rem(20) : rem(40))};
  margin-bottom: ${rem(30)};
  font-size: 16px;
  display: flex;
  align-items: center;
  img {
    width: ${rem(25)};
    height: auto;
    margin-right: ${rem(6)};
  }
  :hover {
    color: #1890ff;
  }
`;

export const SiderbarWrapper = styled.div`
  background-color: white;
  width: ${(props) => (props?.collapse ? rem(60) : rem(222))};
  @media only screen and (max-width: 1300px) {
    width: ${rem(200)};
  }
  transition: all 0.2s linear;
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: 500;
  flex-direction: column;
  padding-top: 1em;
  border-right: 1px solid #ecf0f1;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  background-color: #ecf0f1;
  .ant-menu-item {
    width: fit-content;
    :hover {
      background-color: rgb(24, 144, 255, 0.08);
    }
  }

  .logo {
    width: 100%;
    height: ${rem(100)};
    padding-bottom: ${rem(20)};
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    background: #ecf0f1;
    border-bottom: 1px solid #bdc3c7;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-logo {
      display: ${(props) => (props?.collapse ? "none" : "unset")};
      width: ${rem(100)};
      height: auto;
      padding: ${rem(10)};
    }
  }
  .title {
    border-top: 1px solid #dfe6e9;
    padding: 0.5em;
    width: 100%;
    font-size: ${rem(22)};
    cursor: pointer;
  }
  .ant-menu {
    background: transparent;
  }

  .site-layout-background {
    background: #ecf0f1 !important;

    .ant-menu-inline {
      border-right: none;
    }
  }
  img {
    width: ${rem(16)};
    height: ${rem(16)};
  }
  .report {
    width: 100%;
    padding: 0.9em 3.5em;
    /* border-bottom: 1px solid #dfe6e9; */
    cursor: default;
  }
  .ant-menu-item-selected {
    width: ${(props) => (props?.collapse ? rem(60) : rem(180))};
    background: #0089cf !important;
    color: white !important;
    @media only screen and (max-width: 1300px) {
    width: ${rem(160)};
  }
  }
  .ant-menu-item {
    width: ${(props) => (props?.collapse ? rem(60) : rem(180))};
    @media only screen and (max-width: 1300px) {
    width: ${rem(160)};
  }
    padding-left: 0.3rem !important;
  }
  .report:hover {
    background-color: #dfe6e9;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
