/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import Dashboard from "./dashboard/index";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Suspense } from "react";
import LoginPage from "./pages/login";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./rootStore";
import { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import StyleGlobal from "./styles";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const RootRouter = function () {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.auth?.token);
  const { t } = useTranslation();

  // function wsConnectionCOE(url) {
  //   coeSocket = new WebSocket(url);

  //   coeSocket.onmessage = function (e) {
  //     const data = JSON.parse(e.data);
  //     const message = data.message.data
  //       ? JSON.parse(data.message.data)
  //       : data.message + "\n";
  //     // YOUR CODE HERE TO HANDLE MESSAGE...
  //     // Em lấy message server gửi về ở đây rồi xử lý tiếp nhé
  //     if (message) {
  //       dispatch(storeSetDashboardData(message));
  //     }
  //   };

  //   coeSocket.onclose = function (e) {
  //     if (coeSocket.readyState == 3) {
  //       coeSocket = null;
  //       setTimeout(function () {
  //         wsConnectionCOE(url);
  //       }, 5000);
  //     }
  //   };
  // }

  useEffect(() => {
    if (token) {
    } else {
      if (location?.pathname === "/") {
        return;
      }
      navigate("/login");
    }
  }, [token]);
  return (
    <div>
      <Helmet>
        <title>{t("common.titlePage")}</title>
      </Helmet>
      <Suspense>
        {token ? (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/survey_urls" element={<Dashboard />} />
            <Route path="/raw_data" element={<Dashboard />} />
            <Route path="/documents" element={<Dashboard />} />
            <Route path="/user_management" element={<Dashboard />} />
            <Route path="/exportData" element={<Dashboard />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LoginPage />} />
          </Routes>
        )}
      </Suspense>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <StyleGlobal />
          <RootRouter />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
