export const listFormReport = [
  "Xếp hạng BV",
  "Q.Tổng hợp",
  "1.a Da kề ST",
  "1.b Da kề SM",
  "1.c Bú sớm",
  "2. Bú Hoàn toàn",
  "3. NCBSM",
  "4. Không cách ly",
  "5. Nghị định 100",
  "5.a TV SD",
  "5.b TVSD Sinh non",
  "5.c QC-TT Trong BV",
];

export const listDocument = [
  "Quyết định số 5913",
  "Báo cáo mẫu - tỉnh Quảng Ngãi",
];
