import { createSlice } from "@reduxjs/toolkit";
import { EDepartment } from "../../common/const";

const initialState = {
  tab: 1,
  currentDepartment: EDepartment.OBSTETRIC,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    storeSetTab: (state, { payload }) => {
      state.tab = payload;
    },
    storeSetCurrentDepartment: (state, { payload }) => {
      state.currentDepartment = payload;
    },
  },
});

export const { storeSetTab, storeSetCurrentDepartment } = documentSlice.actions;

export default documentSlice.reducer;
