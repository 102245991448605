import { Select } from "antd";
import styled from "styled-components";
import { rem } from "../../../../helpers/rem/px-to-rem";

export const FormAddWrapper = styled.div``;
export const TitleAdd = styled.div`
  margin-top: ${(props) => (props?.isTop ? "0.313rem" : "1.2rem")};
  font-weight: 500;
  margin-bottom: ${rem(8)};
`;
export const SelectModalAdd = styled(Select)`
  width: 100%;
`;
