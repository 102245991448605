import React from "react";
import { Radio, Space } from "antd";
import { useState } from "react";
import { WrapperButton } from "./RadioButtonStyle";
import { useTranslation } from "react-i18next";
const RadioButtonCustom = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <WrapperButton>
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value={1}>{t("document.coe")}</Radio>
          <Radio value={2}>{t("document.otherDocument")}</Radio>
        </Space>
      </Radio.Group>
    </WrapperButton>
  );
};
export default RadioButtonCustom;
