/* eslint-disable eqeqeq */
import { Select, Tag, Tooltip } from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { removeVietnameseTones } from "../../helpers/convertVie";
import { dataJeo } from "./data";
import {
  BornWrapper,
  BothWrapper,
  CityName,
  ColorGroup,
  Content,
  CountryWrapper,
  HeaderTableWrapper,
  TextBlack,
  VietNamChartWrapper,
  WrapperName,
} from "./styled";

const listColor = ["#bdc3c7", "#b2dbf0", "#99cfeb", "#4cacdd", "#32a0d8", "#0089cf", "#00679c"];
const VietNamChart = ({ countryData }) => {
  const { t } = useTranslation();
  const vietnam = [dataJeo];
  const citiesData = useSelector((state) => state.data.citiesData);
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter);

  const [content, setContent] = useState("");
  const [selected, setSelected] = useState("");
  const [selectCity, setSelectCity] = useState("");

  const selectedCountryRef = useRef();

  const getCityDataSelected = async (cityName) => {
    const find = citiesData?.find((element) =>
      removeVietnameseTones(cityName?.toLowerCase())?.replaceAll(" ", "").includes(element?.code_name)
    );
    if (find) {
      const dataCity = countryData[currentQuarter]?.data?.map_data?.data?.find(
        (element) => element?.province_code === find?.code
      );
      setSelectCity(dataCity);
    }
  };

  useEffect(() => {
    if (selectCity?.province_name && currentQuarter) {
      getCityDataSelected(selectCity.province_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuarter]);

  const returnNumberSelected = (value) => {
    if (value === "NaN" || !value || isNaN(value)) return "-";

    return `${value.toFixed(2) || 0}%`;
  };

  const getmedium = (arr) => {
    let sum = 0;
    let number = 0;
    arr.forEach((element) => {
      if (element !== "N/A" && element !== "NaN") {
        sum += element;
        number++;
      }
    });
    return sum / number;
  };

  const getCityData = (cityName) => {
    if (!cityName) {
      return 0;
    }

    const find =
      citiesData?.find((element) =>
        removeVietnameseTones(cityName?.toLowerCase())?.replaceAll(" ", "").includes(element?.code_name)
      ) || null;
    if (find) {
      if (!countryData || !countryData[currentQuarter] || !countryData[currentQuarter]?.data?.map_data?.data) {
        return 0;
      }
      const dataCity =
        countryData[currentQuarter]?.data?.map_data?.data?.find((element) => element?.province_code === find?.code) ||
        null;
      if (!dataCity) {
        return 0;
      }
      if (selected == 0) {
        return getmedium([dataCity?.SK_4_ST, dataCity?.SK_4_SM, dataCity?.SK_5_ST, dataCity?.SK_5_SM, dataCity?.NK_4]);
      }
      if (selected == 1) {
        return returnNumber(dataCity?.SK_4_ST);
      }
      if (selected == 2) {
        return returnNumber(dataCity?.SK_4_SM);
      }
      if (selected == 3) {
        return returnNumber(dataCity?.SK_5_ST);
      }
      if (selected == 4) {
        return returnNumber(dataCity?.SK_5_SM);
      }
      if (selected == 5) {
        return returnNumber(dataCity?.NK_4);
      }
    } else {
      return null;
    }
  };

  const returnNumber = (value) => {
    return value;
  };

  const checkColor = (number) => {
    if (number === null) {
      return "#fff";
    }
    if (number === 0 || number === "NaN") {
      return listColor[0];
    }
    if (number < 25) {
      return listColor[1];
    }
    if (number >= 25 && number < 40) {
      return listColor[2];
    }
    if (number >= 40 && number < 55) {
      return listColor[3];
    }
    if (number >= 55 && number < 70) {
      return listColor[4];
    }
    if (number >= 70 && number < 85) {
      return listColor[5];
    }
    if (number >= 85 && number <= 100) {
      return listColor[6];
    }
    return listColor[0];
  };

  const selectData = useMemo(() => {
    const list = [
      t("common.afterBirth") + t("common.vaginalDelievery2"),
      t("common.afterBirth") + t("common.CSection2"),
      t("common.hospitalStay") + t("common.vaginalDelievery2"),
      t("common.hospitalStay") + t("common.CSection2"),
      t("common.exclusivelyBreastfed") + t("common.vaginalDelievery2"),
    ];
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, listColor]);

  return (
    <VietNamChartWrapper>
      <div>
        <ColorGroup>
          <Tag color="#fff">
            <TextBlack>{t("overview.notParticipate")}</TextBlack>
          </Tag>

          <Tag color="#bdc3c7">{t("overview.noDataYet")}</Tag>
          <Tag color="#b2dbf0">10-25</Tag>
          <Tag color="#99cfeb">25-40</Tag>
          <Tag color="#4cacdd">40-55</Tag>
          <Tag color="#32a0d8">55-70</Tag>
          <Tag color="#0089cf">70-85</Tag>
          <Tag color="#00679c">85-100</Tag>
        </ColorGroup>
        <ComposableMap
          data-tip=""
          projection="geoMercator"
          projectionConfig={{
            scale: 1950,
            center: [105, 15],
          }}
          style={{
            marginTop: "30px",
            width: "750px",
            height: "auto",
          }}
        >
          {vietnam.map((geoUrl) => (
            <Geographies key={geoUrl} geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Tooltip placement="left" title={content}>
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onClick={() => {
                        getCityDataSelected(geo?.properties?.ten_tinh);
                        // react.js Scroll to bottom of the page
                        if (selectedCountryRef.current)
                          selectedCountryRef.current?.scrollIntoView({ behavior: "smooth" });
                      }}
                      onMouseEnter={() => {
                        if (geo?.properties?.ten_tinh) {
                          setContent(geo?.properties?.ten_tinh);
                        }
                      }}
                      // onMouseLeave={() => {
                      //   setContent("");
                      // }}
                      style={{
                        default: {
                          // fill: listColor[Math.floor(geo?.properties?.gid / 10)],
                          fill: checkColor(getCityData(geo?.properties?.ten_tinh)),
                          stroke: "#7f8c8d",
                          strokeWidth: 0.5,
                          outline: "none",
                        },
                        hover: {
                          fill: "#e6dfd9",
                          stroke: "#212529",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                      }}
                    />
                  </Tooltip>
                ))
              }
            </Geographies>
          ))}
        </ComposableMap>
      </div>
      <CountryWrapper ref={selectedCountryRef}>
        <Select
          defaultValue={t("common.letSelect")}
          className="select"
          onChange={(e) => {
            setSelected(Number(e));
          }}
        >
          {selectData?.map((element, index) => {
            return <Select.Option key={String(index)}>{element}</Select.Option>;
          })}
        </Select>
      </CountryWrapper>
      <CountryWrapper>
        <WrapperName>
          {t("userManagement.city")}: <CityName>{selectCity?.province_name}</CityName>
        </WrapperName>
        <HeaderTableWrapper>
          <Content>
            <b>{t("overview.index")}</b>
          </Content>
          <BornWrapper>
            <b>{t("common.vaginalDelievery")}</b>
          </BornWrapper>
          <BornWrapper>
            <b>{t("common.CSection")}</b>
          </BornWrapper>
        </HeaderTableWrapper>
        <HeaderTableWrapper>
          <Content>{t("common.afterBirth")}</Content>
          <BornWrapper>{returnNumberSelected(selectCity?.SK_4_ST)}</BornWrapper>
          <BornWrapper>{returnNumberSelected(selectCity?.SK_4_SM)}</BornWrapper>
        </HeaderTableWrapper>
        <HeaderTableWrapper>
          <Content>{t("common.hospitalStay")}</Content>
          <BornWrapper>{returnNumberSelected(selectCity?.SK_5_ST)}</BornWrapper>
          <BornWrapper>{returnNumberSelected(selectCity?.SK_5_SM)}</BornWrapper>
        </HeaderTableWrapper>
        <HeaderTableWrapper borderbottom>
          <Content>{t("common.exclusivelyBreastfed")} </Content>
          <BothWrapper>{returnNumberSelected(selectCity?.NK_4)}</BothWrapper>
        </HeaderTableWrapper>
      </CountryWrapper>
    </VietNamChartWrapper>
  );
};

export default VietNamChart;
