import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Input from "antd/lib/input/Input";
import { Button } from "antd/lib/radio";
import { LeftContent, LoginWrapper, RightContent } from "./styled";
import { LoadingOutlined } from "@ant-design/icons";

import { linkApi } from "../../common/ngok";
import MenuLanguage from "../menuLanguage";
import { storeSetToken } from "../../store/auth-reducer";
import { useTranslation } from "react-i18next";
import {
  storeSetCitiesData,
  storeSetCurrentQuarter,
  storeSetDashboardData,
  storeSetHostpitalData,
  storeSethospitalSelected,
} from "../../store/data-reducer";

import background1 from "../../assets/brand/img_1.JPG";

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");

  const login = async () => {
    setIsLoading(true);
    setError("");
    fetch(`${linkApi}/user/login-with-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({
        username: userName,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "successful") {
          dispatch(storeSetToken(data?.user?.token || null));
          localStorage.setItem("user", JSON.stringify(data?.user));
          localStorage.setItem("token", JSON.stringify(data?.user?.token));
          navigate("/dashboard");
        } else {
          setError(t("login.errorPassword"));
        }
      })
      .catch(() => setError(t("login.errorPassword")))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    dispatch(storeSetDashboardData(null));
    dispatch(storeSetCurrentQuarter(7));
    dispatch(storeSetCitiesData());
    dispatch(storeSetHostpitalData([]));
    dispatch(storeSethospitalSelected(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        login();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, password]);

  return (
    <LoginWrapper>
      <MenuLanguage />

      <LeftContent imgBg={background1}></LeftContent>
      <RightContent>
        <div className="login">
          <h1>{t("common.welcome")}</h1>
          <Input
            disabled={isLoading}
            className="input"
            placeholder={t("common.username")}
            onChange={(e) => {
              if (e.key === "Enter") {
                login();
                return;
              }
              setUserName(e?.target?.value);
            }}
          />
          <Input
            disabled={isLoading}
            className="input"
            type="password"
            placeholder={t("common.password")}
            onChange={(e) => {
              if (e.key === "Enter") {
                login();
                return;
              }
              setPassword(e?.target?.value);
            }}
          />
          <div className="error">{error}</div>

          <Button
            type="primary"
            className="btn-login"
            onClick={login}
            disabled={isLoading}
          >
            {isLoading ? <LoadingOutlined /> : t("common.login")}
          </Button>
          <div className="btn-signUp">Để đăng ký tài khoản, hãy liên hệ tới admin bmte.vn</div>
        </div>
      </RightContent>
    </LoginWrapper>
  );
};

export default LoginPage;
