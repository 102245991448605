import React, { useEffect } from "react";
import { listFormReport } from "../dashboard/report-form/fakeData";
import { ButtonLogout, SiderbarWrapper } from "./styled";

import logoCoe from "../assets/logo/logo_coe_blue.png";
import logoCoeEn from "../assets/logo/logo_coe_blue_en.png";

import link from "../assets/icon/link.svg";
import overview from "../assets/icon/overview.svg";
import document from "../assets/icon/document.svg";
import database from "../assets/icon/database.svg";
import userIcon from "../assets/icon/user.svg";
import logoutIcon from "../assets/icon/logout.png";

import overviewWhite from "../assets/icon/overview-white.svg";
import linkWhite from "../assets/icon/link-white.svg";
import documentWhite from "../assets/icon/document-white.svg";
import databaseWhite from "../assets/icon/database-white.svg";
import userIconWhite from "../assets/icon/user-white.svg";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { storeSetToken } from "../store/auth-reducer";
import { screenReverst, SCREEN_DEFAULT } from "../common/ngok";
import { useTranslation } from "react-i18next";
import { Layout, Menu, Modal } from "antd";
import {
  storeSetCitiesData,
  storeSetCitySelected,
  storeSethospitalSelected,
  storeSetHostpitalData,
} from "../store/data-reducer";

const { Sider } = Layout;

const AppSidebar = ({ screen, setScreen, setTitle }) => {
  const { t } = useTranslation();

  const language = useSelector((state) => state?.auth?.language);

  const location = useLocation();
  const patch = location?.pathname.replace("/", "") || "dashboard";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      content: t("common.contactAdmin"),
    });
  };

  const setBar = [
    {
      label: t("screen.surveyResults"),
      icon: overview,
    },
    { label: t("screen.surveyLink"), icon: link },
    { label: t("screen.rowData"), icon: database },
    { label: t("screen.regulations"), icon: document },
    { label: t("screen.userManager"), icon: userIcon },
  ];

  const checkScreen = (screenProps) => {
    let newData = [...setBar];
    if (screenProps === SCREEN_DEFAULT[1] || screen === "/") {
      newData[0].icon = overviewWhite;
    }
    if (screenProps === SCREEN_DEFAULT[2]) {
      newData[1].icon = linkWhite;
    }
    if (screenProps === SCREEN_DEFAULT[3]) {
      newData[2].icon = databaseWhite;
    }
    if (screenProps === SCREEN_DEFAULT[4]) {
      newData[3].icon = documentWhite;
    }
    if (screenProps === SCREEN_DEFAULT[7]) {
      newData[4].icon = userIconWhite;
    }
    return newData;
  };

  const items2 = checkScreen(`/${patch}`);

  const logout = () => {
    localStorage.removeItem("user");
    dispatch(storeSetToken(null));
    dispatch(storeSetCitiesData([]));
    dispatch(storeSetHostpitalData([]));
    dispatch(storeSethospitalSelected(null));
    dispatch(storeSetCitySelected(null));
    navigate("/apps");
  };

  useEffect(() => {
    setTitle(listFormReport[0]);
  }, [setTitle]);
  return (
    <SiderbarWrapper>
      <div>
        <div className="logo">
          <img
            className="img-logo"
            src={language === "vi" ? logoCoe : logoCoeEn}
            alt=""
          />
        </div>

        <Sider
          className="site-layout-background"
          collapsedWidth="0"
          collapsed={false}
          width="100%"
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[`${screenReverst[`${patch}`]}`]}
            selectedKeys={[`${screenReverst[`${patch}`]}`]}
            onSelect={(e) => {
              if (
                (Number(e.key) === 3 || Number(e.key) === 5) &&
                user?.is_superuser === "False"
              ) {
                showConfirm();
                return;
              }
              if (Number(e.key) === 5) {
                setScreen(Number(5));
                navigate(`${SCREEN_DEFAULT[7]}`);
                return;
              }
              setScreen(Number(e.key));
              navigate(`${SCREEN_DEFAULT[e.key]}`);
              setTitle(items2[Number(e.key) - 1]);
            }}
            items={items2.map((element, index) => ({
              key: String(index + 1),
              icon: <img src={element.icon} alt="" />,
              label: element.label,
            }))}
          />
        </Sider>
      </div>
      <ButtonLogout onClick={logout}>
        <img src={logoutIcon} alt="" />
        <span>{t("screen.logout")}</span>
      </ButtonLogout>
    </SiderbarWrapper>
  );
};

export default AppSidebar;
