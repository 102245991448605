import React from "react";
import { useTranslation } from "react-i18next";
import ModalNormal from "../../../common/modal";
import FormAddHostpital from "./FormAddHostpital";

const ModalAdd = ({ isOpen, setIsOpen, getDataAwarded, setSelected }) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalNormal
        visible={isOpen}
        setVisible={setIsOpen}
        width={500}
        title={t("common.updateData")}
        onCancel={() => {
          //   setModalData(false);
        }}
      >
        <FormAddHostpital
          setIsOpen={setIsOpen}
          getDataAwarded={getDataAwarded}
          setSelected={setSelected}
        />
      </ModalNormal>
    </>
  );
};

export default ModalAdd;
