import { Select } from 'antd';
import styled from 'styled-components';

export const BtnUpdateData = styled.div`
  cursor: pointer;
  border: none;
  background-color: #0089cf !important;
  border-radius: 7px;
  color: white !important;
  transition: box-shadow 0.3s ease-in-out;
  transition: all ease 0.2s;
  padding: 4px 12px;
  margin-left: 12px;
  :active {
    transition: all 0.1s ease-out;
    box-shadow: inset 0px 0px 3px 3px rgba(0, 0, 0, 0.25) !important;
  }
`;
export const ListHospitalWrapper = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 90%;
`;
export const TitleListHospital = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin: 8px 0px;
`;
export const SelectHospitals = styled(Select)`
  width: 217px;
`;
export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;
export const IconAdd = styled.img`
  width: 24px;
  height: auto;
  cursor: pointer;
  margin-left: 10px;
`;
export const FileView = styled.div`
  min-width: 800px;
  margin-left: 20px;
  margin-top: 50px;
  min-height: 600px;
`;
export const UpdateFile = styled.div`
  text-align: center;
  font-size: 24px;
  margin-top: 140px;
  font-weight: 700;
  max-width: 500px;
`;
