import styled from "styled-components";

export const ImageCustom = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 40px;
`;
export const IconLogo = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 40px;
  margin-right: 0.5rem;
`;
export const TextWrapper = styled.div``;
export const QuanterHeader = styled.div`
  text-align: left;
  line-height: 17px;
`;
export const TitleHeader = styled.div`
  text-align: left;
  font-size: 1.25rem;
  line-height: 20px;
  font-weight: 700;
`;
export const HeaderExportWrapper = styled.div`
  width: 50rem;
  text-align: center;
  color: #ffffff;
  background-color: #0089cf;
  display: flex;
  align-items: center;
  padding: 0 2.25rem;
  height: 150px;
  margin-bottom: 2rem;
  .title {
    font-size: 24px;
    font-weight: 700;
  }
  .last-update {
    font-size: 14px;
  }
  .hospital {
    margin-top: 8px;
    font-weight: bold;
    font-size: 20px;
    font-style: italic;
  }
`;
