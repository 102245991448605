import React from "react";
import UserTable from "../../components/UserTable/UserTable";
import { LeftContainer, TitleManagerUser, UserManagerWrapper } from "./styled";

const UserManager = ({ modalData, setModalData }) => {
  return (
    <UserManagerWrapper>
      <LeftContainer>
        <TitleManagerUser>
          {/* {t("userManagement.userAdministration")} */}
        </TitleManagerUser>
        <UserTable modalData={modalData} setModalData={setModalData} />
      </LeftContainer>
    </UserManagerWrapper>
  );
};

export default UserManager;
