import React from "react";
import {
  HeaderExportWrapper,
  IconLogo,
  ImageCustom,
  QuanterHeader,
  TextWrapper,
  TitleHeader,
} from "./styled";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import imgLogo from "../../../assets/logo/logo_coe_white.png";
import imgLogoEN from "../../../assets/logo/logo_coe_white_en.png";

const HeaderExport = ({ department }) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state?.auth?.language);

  const dashboardData =
    useSelector((state) => state?.data?.dashboardData) || null;
  const currentQuarter =
    useSelector((state) => state?.data?.currentQuarter) || null;
  const hospitalSelected = useSelector(
    (state) => state?.data?.hospitalSelected
  );
  return (
    <HeaderExportWrapper>
      {/* <div className="title">{t("exportData.title")}</div>
      <div className="last-update">
        {t("exportData.updateTo")} {dashboardData[currentQuarter]?.time}
      </div>
      <div className="hospital">
        {hospitalSelected?.name} {t("exportData.hospitalEn")}
      </div> */}
      <IconLogo>
        <ImageCustom src={language === "vi" ? imgLogo : imgLogoEN} alt="logo" />
      </IconLogo>
      <TextWrapper>
        <TitleHeader>{t("exportData.title")}</TitleHeader>
        <QuanterHeader>{dashboardData[currentQuarter]?.time}</QuanterHeader>
        <QuanterHeader>
          {department === "SK"
            ? t("exportData.obstetric")
            : t("exportData.pediatric")}
        </QuanterHeader>
        <TitleHeader>
          {hospitalSelected?.name} {t("exportData.hospitalEn")}
        </TitleHeader>
      </TextWrapper>
    </HeaderExportWrapper>
  );
};

export default HeaderExport;
