import styled from "styled-components";
import { rem } from "../../helpers/rem/px-to-rem";

export const TitleGroupChart = styled.div`
  margin-bottom: 20px;
  color: #00a8ff;
  font-weight: 700;
  font-size: 20px;
`;
export const ExportContainer = styled.div`
  background-color: #808285;
  min-height: 100%;
  padding-bottom: 2rem;
`;
export const Pagecurrent = styled.div`
  background-color: #3498db;
  position: absolute;
  right: 0px;
  bottom: 10px;
  width: 150px;
  color: white;
  padding: 3px 20px;
`;
export const ExportWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .page {
    /* padding: ${rem(0)} ${rem(50)}; */
    border-radius: ${rem(4)};
    width: ${rem(800)};
    background-color: white;
    min-height: 1050px;
    padding-bottom: 40px;
    position: relative;
    /* overflow: hidden; */
    /* margin-bottom: 1rem; */
    /* box-shadow: 3px 3px 3px 3px #dcdde1; */
  }
`;

export const Padding50 = styled.div`
  padding: ${rem(0)} ${rem(50)};
`;

export const ObstetricTitle = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
`;
export const SpaceHeight = styled.div`
  height: 3rem;
  background-color: transparent;
`;
export const NodataExport = styled.div`
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
  color: white;
`;
