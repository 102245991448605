export const rowData = [
  {
    _id: "318830221",
    hosp_hospname: "5.Trung tâm Y tế huyện Hồng Dân",
    formhub_uuid: "d7814b94b7e34f8abe71083d0763c149",
    start: "2022-08-09T05:10:17.237000Z",
    end: "2022-08-09T05:15:54.526000Z",
    today: "2022-08-09T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:hA1UpK2PzQ98Hzr5",
    dov: "2022-08-09T00:00:00Z",
    hosp_province: "95",
    hosp_operator: 953,
    hosp_hospital: 1,
    hosp_criteria: 1,
  },
  {
    _id: "318872907",
    hosp_hospname: "2.Bệnh viện đa khoa tỉnh Bạc Liêu",
    formhub_uuid: "d7814b94b7e34f8abe71083d0763c149",
    start: "2022-08-09T08:03:20.229000Z",
    end: "2022-08-09T08:06:30.070000Z",
    today: "2022-08-09T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:hA1UpK2PzQ98Hzr5",
    dov: "2022-08-09T00:00:00Z",
    hosp_province: "95",
    hosp_operator: 953,
    hosp_hospital: 1,
    hosp_criteria: 2,
  },
  {
    _id: "318874417",
    hosp_hospname: "5.Trung tâm Y tế huyện Hồng Dân",
    formhub_uuid: "d7814b94b7e34f8abe71083d0763c149",
    start: "2022-08-09T08:06:30.131000Z",
    end: "2022-08-09T08:11:36.165000Z",
    today: "2022-08-09T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:hA1UpK2PzQ98Hzr5",
    dov: "2022-08-09T00:00:00Z",
    hosp_province: "95",
    hosp_operator: 952,
    hosp_hospital: 1,
    hosp_criteria: 1,
  },
  {
    _id: "318872064",
    hosp_hospname: "2.Bệnh viện đa khoa tỉnh Bạc Liêu",
    formhub_uuid: "d7814b94b7e34f8abe71083d0763c149",
    start: "2022-08-09T07:56:19.632000Z",
    end: "2022-08-09T08:03:20.170000Z",
    today: "2022-08-09T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:hA1UpK2PzQ98Hzr5",
    dov: "2022-08-09T00:00:00Z",
    hosp_province: "95",
    hosp_operator: 953,
    hosp_hospital: 1,
    hosp_criteria: 2,
  },
  {
    _id: "322129664",
    hosp_hospname: "8.Trung tâm Y tế TP Mỹ Tho",
    formhub_uuid: "f45acfee8d2342b98fba8c6b34a6a42f",
    start: "2022-08-17T10:50:06.814000Z",
    end: "2022-08-17T10:50:24.863000Z",
    today: "2022-08-17T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:hA1UpK2PzQ98Hzr5",
    dov: "2022-08-17T00:00:00Z",
    hosp_province: "82",
    hosp_operator: 823,
    hosp_hospital: 1,
    hosp_criteria: 2,
  },
  {
    _id: "322131325",
    hosp_hospname: "13.Bệnh viện Nguyễn Tri Phương",
    formhub_uuid: "e2f72341c1e0463eaa8c7183ff7909c1",
    start: "2022-08-17T10:53:28.977000Z",
    end: "2022-08-17T10:53:45.716000Z",
    today: "2022-08-17T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:hA1UpK2PzQ98Hzr5",
    dov: "2022-08-17T00:00:00Z",
    hosp_province: "79",
    hosp_operator: 793,
    hosp_hospital: 1,
    hosp_criteria: 1,
  },
  {
    _id: "322280898",
    hosp_hospname: "148.Phòng khám đa khoa Phương Nam",
    formhub_uuid: "e399b2cf4e064d65b122ca7383b3d840",
    start: "2022-08-17T08:45:13.022000Z",
    end: "2022-08-17T15:40:44.562000Z",
    today: "2022-08-17T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:Rp848wQ32qtGYdbQ",
    dov: "2022-08-17T00:00:00Z",
    hosp_province: "96",
    hosp_operator: 963,
    hosp_hospital: 1,
    hosp_criteria: 2,
  },
  {
    _id: "322281448",
    hosp_hospname: "1.Bệnh viện đa khoa Cà Mau",
    formhub_uuid: "e399b2cf4e064d65b122ca7383b3d840",
    start: "2022-08-17T15:40:44.759000Z",
    end: "2022-08-17T15:42:07.887000Z",
    today: "2022-08-17T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:Rp848wQ32qtGYdbQ",
    dov: "2022-08-17T00:00:00Z",
    hosp_province: "96",
    hosp_operator: 963,
    hosp_hospital: 1,
    hosp_criteria: 1,
  },
  {
    _id: "322285767",
    hosp_hospname: "29.Bệnh viện Y học cổ truyền tỉnh Cà Mau",
    formhub_uuid: "e399b2cf4e064d65b122ca7383b3d840",
    start: "2022-08-17T15:42:08.045000Z",
    end: "2022-08-17T15:53:42.538000Z",
    today: "2022-08-17T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:Rp848wQ32qtGYdbQ",
    dov: "2022-08-17T00:00:00Z",
    hosp_province: "96",
    hosp_operator: 962,
    hosp_hospital: 1,
    hosp_criteria: 1,
  },
  {
    _id: "322286501",
    hosp_hospname: "21.Phòng khám đa khoa khu vực Phú Tân",
    formhub_uuid: "e399b2cf4e064d65b122ca7383b3d840",
    start: "2022-08-17T15:53:42.653000Z",
    end: "2022-08-17T15:55:44.617000Z",
    today: "2022-08-17T00:00:00Z",
    device_id: "ee.humanitarianresponse.info:Rp848wQ32qtGYdbQ",
    dov: "2022-08-17T00:00:00Z",
    hosp_province: "96",
    hosp_operator: 962,
    hosp_hospital: 1,
    hosp_criteria: 2,
  },
];
