/* eslint-disable jsx-a11y/iframe-has-title */
import { message, Select } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sendGet } from "../../../api/axios";
import { linkApi } from "../../../common/ngok";
import ItemHospital from "./ItemHospital/ItemHospital";
import ModalAdd from "./ModalAdd/ModalAdd";
import {
  BtnUpdateData,
  FileView,
  ListHospitalWrapper,
  SelectHospitals,
  SelectWrapper,
  TitleListHospital,
  UpdateFile,
} from "./styled";

const ListHospital = () => {
  const { t } = useTranslation();
  const [hospital, setHospital] = useState(null);
  const [hospitalDefault, setHospitalDefault] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const token = useSelector((state) => state?.auth?.token);
  const getDataAwarded = async (callback) => {
    const response = await sendGet("/dm/data/hospital?info=awarded");
    if (response?.status === "successful") {
      setHospital(response?.hospitals);
      setHospitalDefault(response?.hospitals);
      setIsOpen(false);
      if (callback) {
        callback(response?.hospitals);
      }
    }
  };

  useEffect(() => {
    getDataAwarded();
  }, []);

  const onUpdate = async (year, code) => {
    const myHeaders = new Headers({
      Authorization: "Token " + token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${linkApi}/dm/data/hospital?code=${code}`, {
      method: "POST",
      headers: myHeaders,
      body: new URLSearchParams({
        last_awarded_year: year !== "None" ? moment(year).format("YYYY") : year,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (year !== "None") {
          setHospital(
            hospital?.map((element) => {
              if (data?.hospital?.code === element.code) {
                return data?.hospital;
              }
              return element;
            })
          );
        } else {
          setHospital(hospital?.filter((element) => code !== element.code));
          setSelected(null);
        }
        message.success(t("document.updateSuccess"));
      })
      .catch(() => {
        message.warning(t("document.updateError"));
      })
      .finally(() => {});
  };

  const onDelete = (code) => {
    onUpdate("None", code);
  };

  return (
    <ListHospitalWrapper>
      <div>
        <TitleListHospital>{t("document.awardedHospitals")}</TitleListHospital>
        <SelectWrapper>
          <SelectHospitals
            showSearch
            placeholder={t("document.searchHostpital")}
            optionFilterProp="children"
            onSelect={(e) => {
              setSelected(hospitalDefault[e]);
              setHospital([hospitalDefault[e]]);
            }}
            filterOption={(input, option) => option?.children?.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA?.children
                .toLowerCase()
                .localeCompare(optionB?.children?.toLowerCase())
            }
          >
            {hospitalDefault?.map((element, index) => (
              <Select.Option value={index}>{element?.name}</Select.Option>
            ))}
          </SelectHospitals>
          {hospital?.length === 1 && (
            <BtnUpdateData onClick={() => setHospital(hospitalDefault)}>
              {t("document.deleteSearch")}
            </BtnUpdateData>
          )}
          <BtnUpdateData onClick={() => setIsOpen(true)}>
            {t("common.updateData")}
          </BtnUpdateData>
        </SelectWrapper>
        {hospital?.map((element) => (
          <ItemHospital
            item={element}
            onUpdate={onUpdate}
            onDelete={onDelete}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
        <ModalAdd
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          getDataAwarded={getDataAwarded}
          setSelected={setSelected}
        />
      </div>
      <FileView>
        {selected &&
          selected.last_coe_cert &&
          selected.last_coe_cert !== "None" && (
            <object
              data={`${linkApi}/media/${selected?.last_coe_cert}`}
              type="application/pdf"
              height="800px"
              width="800px"
            >
              <iframe
                src={`https://docs.google.com/viewer?url=${linkApi}/media/${selected?.last_coe_cert}&embedded=true`}
                height="800px"
                width="800px"
              />
            </object>
          )}
        {selected &&
          (!selected.last_coe_cert || selected.last_coe_cert === "None") && (
            <UpdateFile>{t("common.updateDocument")}</UpdateFile>
          )}
      </FileView>
    </ListHospitalWrapper>
  );
};

export default ListHospital;
