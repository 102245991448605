import styled from "styled-components";

export const VietNamChartWrapper = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
  height: 750px;
`;
export const ContentWrapper = styled.div`
  text-align: center;
  height: 30px;
`;
export const CityName = styled.div`
  color: green;
  margin-left: 10px;
`;
export const WrapperName = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
`;
export const ColorGroup = styled.div`
  margin-left: 130px;
  .ant-tag {
    min-width: 40px;
    text-align: center;
    /* border: 1px solid black; */
  }
`;
export const CountryWrapper = styled.div`
  margin-bottom: 10px;
  margin-right: -40px;
  .select {
    width: 500px;
  }
`;
export const HeaderTableWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #bdc3c7;
  border-bottom: ${(props) =>
    props?.borderbottom ? "1px solid #bdc3c7" : "none"};
`;
export const Content = styled.div`
  width: 60%;
  padding-left: 12px;
`;
export const BornWrapper = styled.div`
  width: 20%;
  border-left: 1px solid #bdc3c7;
  text-align: center;
`;
export const BothWrapper = styled.div`
  width: 40%;
  border-left: 1px solid #bdc3c7;
  text-align: center;
`;
export const TextBlack = styled.div`
  color: black;
  padding: 0 8px;
  border: 1px solid #ecf0f1;
`;
