import { Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingWrapper } from "./loadingStyle";

const Loading = () => {
  const { t } = useTranslation();
  return (
    <LoadingWrapper>
      <Spin tip={t("document.waitLoading")} size="large" />
    </LoadingWrapper>
  );
};

export default Loading;
