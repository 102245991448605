import styled from "styled-components";

export const BornWrapper = styled.div`
  padding: 10px;
  .font-bold {
    font-weight: 500;
    font-size: 16px !important;
    @media only screen and (max-width: 1300px) {
      font-size: 12px !important;
    }
    text-transform: uppercase;
  }
  .sticky {
    position: sticky;
    top: 109px;
    background-color: #ececed;
    border-top: 1px solid #ced6e0 !important;
    border-bottom: 1px solid #ced6e0;
    div {
      padding: 10px 0;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ced6e0;
    border-top: none;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .stt {
      width: 4%;
      font-size: 20px;
      font-weight: 500;
      @media only screen and (max-width: 1300px) {
        font-size: 12px;
      }
    }
    .w-10 {
      width: 10%;
      display: flex;
      justify-content: center;
      font-size: 20px;
      @media only screen and (max-width: 1300px) {
        font-size: 12px;
      }
      img {
        margin-top: 10px;
        width: fit-content;
        height: fit-content;
      }
    }
    .w-20 {
      width: 20%;
      font-size: 20px;
      @media only screen and (max-width: 1300px) {
        font-size: 12px;
      }
    }
    .criteria {
      width: 15%;
      font-size: 20px;
      padding-left: 10px;
      display: flex;
      justify-content: flex-start;
      @media only screen and (max-width: 1300px) {
        font-size: 12px;
        width: 12%;
      }
    }
    .chart {
      width: 60%;
      @media only screen and (max-width: 1300px) {
        width: 70%;
      }
      height: auto;
      .container-chart {
        min-width: 700px !important;
        @media only screen and (max-width: 1300px) {
          min-width: 450px !important;
        }
        width: 100%;
        height: 100%;
        /* border: 1px solid #ced6e0; */
        border-right: none;
      }
    }
    .border-none {
      border-right: none;
    }
    .pass {
      width: 4%;
      @media only screen and (max-width: 1300px) {
        img{
          width: 20px;
        }
      }
    }
  }
`;
