import { Segmented } from "antd";
import styled from "styled-components";
import { rem } from "../../helpers/rem/px-to-rem";

export const SegmentedCustom = styled(Segmented)`
  /* .select-quarter {
    width: ${rem(160)};
  } */
  border-radius: 12px;
  color: #727272;
  background-color: #ececed;
  /* border-radius: 10px; */
  .ant-segmented-item-label {
    font-size: 14px !important;
  }
  .ant-segmented-item {
    margin: 2px;
  }
  .ant-segmented-item-selected {
    border-radius: 10px;
    background-color: #0089cf !important;
    color: white;
    font-weight: 600;
  }
  .ant-segmented-thumb {
    border-radius: 10px;
    background-color: #0089cf !important;
  }
`;
export const BtnExportCity = styled.div`
  cursor: pointer;
  border: 1px solid rgba(254, 166, 40, 0.5);
  border-radius: 4px;
  padding: 4px 8px;
  background-color: rgba(254, 166, 40, 0.5);
  :hover {
    border: 1px solid rgba(254, 166, 40, 1);
  }
`;
export const FilterWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 1em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${rem(60)};
  background-color: #ecf0f1;
  .back {
    margin-right: ${rem(20)};
    cursor: pointer;
    height: fit-content;
    font-size: 500;
    padding: ${rem(4)} ${rem(12)};
    background-color: transparent;
    border-radius: 7px;
    color: #0089cf;
    border: 1px solid #0089cf;
    :hover {
      box-shadow: 1px 1px 1px 1px #c8d6e5;
    }
  }
  .adress {
    display: flex;
    align-items: center;
    .export {
      padding: 5px 10px;
      width: 110px;
      background-color: white;
      border-radius: px;
      margin-right: 1em;
    }
    .select-city {
      margin-left: 1em;
      width: ${rem(160)};
    }
    .hostpital {
      margin-left: 2em;
    }
    .select-hostpital {
      margin-left: 1em;
      width: ${rem(250)};
      @media only screen and (max-width: 1300px) {
        margin-left: 0.5rem;
        width: ${rem(200)};
      }
    }
    .datePicker {
      margin-left: ${rem(40)};
    }
  }
  .fil-other {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    .input-n {
      width: ${rem(110)};
      border-radius: 2;
      margin-right: 1em;
    }
    .a-style {
      background-color: white;
      height: ${rem(30)};
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${rem(60)};
      border-radius: 4px;
    }
    .information {
      margin-right: 1em;
      margin-left: 0.5em;
    }
    .select-information {
      width: ${rem(708)};
    }
  }
  .export {
    cursor: pointer;
    border: 1px solid #dfe6e9;
    border-radius: 7px;
    padding: 4px 8px;
    background-color: #0089cf;
    color: white;
    :hover {
      border: 1px solid #00b894;
    }
  }
  /* box-shadow: 0 1px 2px -1px gray; */
  .select-quarter {
    margin-left: 1em;
    width: ${rem(120)};
  }
`;
