/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Spin } from "antd";
import { LinkHeader, SurveyLinkWrapper } from "./styled";

import ChartLink from "./chart";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { sendGet } from "../../api/axios";
import { linkApi, QUARTER_LENGTH } from "../../common/ngok";
import { getListQuanter } from "../../helpers/getListQuanter";

const SurveyLink = () => {
  const { t } = useTranslation();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const citySelected = useSelector((state) => state.data.citySelected);
  const listQuanter = getListQuanter();
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter);

  const [dataTableChart, setDatableChart] = useState(null);
  const [linkUrl, setLineUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getDataDashboard = async (code) => {
    setIsLoading(true);
    let newcode = user?.province_code;
    if (
      user?.is_superuser === "True" &&
      citySelected &&
      citySelected?.code !== -1
    ) {
      newcode = citySelected?.code;
    }
    try {
      const data = await sendGet(
        `dm/data/province/survey_progress?province_code=${newcode}`
      );
      if (data?.status === "successful") {
        setLineUrl(data?.survey_url);
        const dataClone = [...data?.data[currentQuarter]?.data];
        if (dataClone?.length > 0) {
          const chartData = [];
          chartData.push([
            "hospital_name",
            t("surveyLink.underMonth"),
            t("surveyLink.sectionMothers"),
            t("surveyLink.normalMother"),
            { role: "style" },
          ]);
          dataClone.forEach((element) => {
            const item = [];
            item.push(element.hospital_name);
            item.push(element.no_D1TT);
            item.push(element.no_SM);
            item.push(element.no_ST);
            item.push("stroke-width: 4");
            chartData.push(item);
          });
          setDatableChart(chartData || []);
        } else {
          setDatableChart([]);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const indicesState = async (data) => {
    const timeString = listQuanter[currentQuarter]?.split("/");
    let newcode = user?.province_code;
    if (
      user?.is_superuser === "True" &&
      citySelected &&
      citySelected?.code !== -1
    ) {
      newcode = citySelected?.code;
    }
    const response = await sendGet(
      `/dm/data/province/survey_progress/export?province_code=${newcode}&year=${timeString[1]}&quarter=${timeString[0][1]}&type=xlsx&data=${data}`
    );
    if (response?.status === "successful") {
      window.open(`${linkApi}/${response?.data?.url}`);
    }
  };

  useEffect(() => {
    if (!citySelected) return;

    getDataDashboard(citySelected?.code);
  }, [citySelected, currentQuarter]);

  return (
    <SurveyLinkWrapper>
      <div className="container">
        {(!citySelected || citySelected?.code === -1) && (
          <h3>{t("surveyLink.letSelectCity")}</h3>
        )}
        {citySelected?.code !== -1 && (
          <div className="link-selected">
            {!isLoading && dataTableChart?.length === 0 ? (
              <h3>{t("surveyLink.cityNotData")}</h3>
            ) : (
              <div />
            )}
            {isLoading ? (
              <div className="loading-wrapper">
                <Spin size="large" />
              </div>
            ) : (
              <>
                {dataTableChart?.length === 0 && (
                  <LinkHeader>
                    <Button
                      className="link"
                      onClick={() => window.open(linkUrl)}
                    >
                      {t("surveyLink.link")}
                    </Button>
                  </LinkHeader>
                )}

                {dataTableChart?.length > 0 && (
                  <LinkHeader>
                    <Button
                      className="link"
                      onClick={() => indicesState("calls_stats")}
                    >
                      {t("surveyLink.interviews")}
                    </Button>
                    <Button
                      className="link"
                      onClick={() => indicesState("indices_stats")}
                    >
                      {t("surveyLink.summaryReport", {
                        name: citySelected?.name,
                      })}
                    </Button>
                    <Button
                      className="link"
                      onClick={() => window.open(linkUrl)}
                    >
                      {t("surveyLink.link")}
                    </Button>
                  </LinkHeader>
                )}

                {dataTableChart?.length > 0 && (
                  <div className="chart">
                    <ChartLink
                      dataTableChart={dataTableChart}
                      selected={citySelected}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </SurveyLinkWrapper>
  );
};

export default SurveyLink;
