import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function DropdownExport({ exportReportV1, exportReportV2 }) {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={exportReportV1}>
        {t("filter.reportV1")}
      </Menu.Item>
      <Menu.Item key="2" onClick={exportReportV2}>
        {t("filter.reportV2")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button className="export">
        {t("filter.exportReport")} <DownOutlined />
      </Button>
    </Dropdown>
  );
}

export default DropdownExport;
